import axios from 'axios';
import {
    getIsApiPending,
    getVehicleDetailsForm,
} from '../../../selectors/roaCarInsurance/vehicleDetails';
import {
    apiTransition,
} from '../../../actions/roaCarInsurance/vehicleDetails';
import { calculateVehicleDetailsSuccess } from "../../../actions/roaCarInsurance/vehicleDetails";

export const vehicleDetailsNgService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransition.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateVehicleDetailsSuccess(resp.data));
                if (resp.data.code) {
                    store.dispatch(apiTransition.failure(resp.data.code));
                } else {
                    store.dispatch(apiTransition.success());
                }
            } catch (e) {
                store.dispatch(apiTransition.failure(e.message));
            }
        }
    })();
    return next(action);
};

const vehicleDetailsPayload = (formData) => ({
    registration_number: formData.registrationNumber.value,
    chassis_number: formData.chassisNumber.value,
    engine_number: formData.engineNumber.value,
    cover_start_date: formData.coverDate.value,
    identification_type: formData.identificationMeans.value,
    identification: formData.identificationMeansData.value,
});

const calculate = async (state) => {
    const formData = getVehicleDetailsForm(state);
    try {
        const url = '/om-api/roa-car-insurance/nigeria/vehicle-details';
        return await axios.post(url, vehicleDetailsPayload(formData));
    } catch (e) {
        throw e;
    }
};
