import { createSelector } from "reselect";
import { FORM_STATUS } from '../../reducers/status';

export const getSave2gether = (state => {
    return state.publicWeb.save2gether
});

const makeGetField = fieldId => createSelector(
    getSave2gether,
    save2gether => save2gether[fieldId]
);

export const getFormBlocked = makeGetField('formBlocked');
export const getShowThankYou = makeGetField('showThankYou');
export const getShowError = makeGetField('showError');
export const getName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getBusinessName = makeGetField('businessName');
export const getPhoneNumber = makeGetField('phoneNumber');
export const getBusinessNumber = makeGetField('businessNumber');
export const getEmailAddress = makeGetField('emailAddress');
export const getBusinessRegistered = makeGetField('businessRegistered');
export const getNumberOfEmployees = makeGetField('numberOfEmployees');
export const getBusinessDuration = makeGetField('businessDuration');
export const getBusinessManagement = makeGetField('businessManagement');
export const getBusinessRegisteredAs = makeGetField('businessRegisteredAs');
export const getHasEmployeeRetirement = makeGetField('hasEmployeeRetirement');
export const getToEmailAddress = makeGetField('toEmailAddress');
export const getFormValid = createSelector(
    getSave2gether,
    save2gether => save2gether.formStatus === FORM_STATUS.VALID,
);
