/* eslint-disable */

// LEVEL ANNUITY RATES
const levelAnnuityRates = {};

levelAnnuityRates.male = [
    { "Age": 40, "AnnuityRate": 9.47 }, 
    { "Age": 41, "AnnuityRate": 9.46 }, 
    { "Age": 42, "AnnuityRate": 9.45 }, 
    { "Age": 43, "AnnuityRate": 9.43 }, 
    { "Age": 44, "AnnuityRate": 9.42 }, 
    { "Age": 45, "AnnuityRate": 9.40 }, 
    { "Age": 46, "AnnuityRate": 9.39 }, 
    { "Age": 47, "AnnuityRate": 9.37 }, 
    { "Age": 48, "AnnuityRate": 9.35 }, 
    { "Age": 49, "AnnuityRate": 9.33 }, 
    { "Age": 50, "AnnuityRate": 9.31 }, 
    { "Age": 51, "AnnuityRate": 9.30 }, 
    { "Age": 52, "AnnuityRate": 9.28 }, 
    { "Age": 53, "AnnuityRate": 9.25 }, 
    { "Age": 54, "AnnuityRate": 9.23 }, 
    { "Age": 55, "AnnuityRate": 9.21 }, 
    { "Age": 56, "AnnuityRate": 9.18 }, 
    { "Age": 57, "AnnuityRate": 9.16 }, 
    { "Age": 58, "AnnuityRate": 9.13 }, 
    { "Age": 59, "AnnuityRate": 9.10 }, 
    { "Age": 60, "AnnuityRate": 9.07 }, 
    { "Age": 61, "AnnuityRate": 9.03 }, 
    { "Age": 62, "AnnuityRate": 8.99 }, 
    { "Age": 63, "AnnuityRate": 8.96 }, 
    { "Age": 64, "AnnuityRate": 8.91 }, 
    { "Age": 65, "AnnuityRate": 8.87 }, 
    { "Age": 66, "AnnuityRate": 8.82 }, 
    { "Age": 67, "AnnuityRate": 8.78 }, 
    { "Age": 68, "AnnuityRate": 8.73 }, 
    { "Age": 69, "AnnuityRate": 8.68 }, 
    { "Age": 70, "AnnuityRate": 8.62 }, 
    { "Age": 71, "AnnuityRate": 8.57 }, 
    { "Age": 72, "AnnuityRate": 8.51 }, 
    { "Age": 73, "AnnuityRate": 8.45 }, 
    { "Age": 74, "AnnuityRate": 8.39 }, 
    { "Age": 75, "AnnuityRate": 8.33 }, 
    { "Age": 76, "AnnuityRate": 8.27 }, 
    { "Age": 77, "AnnuityRate": 8.21 }, 
    { "Age": 78, "AnnuityRate": 8.15 }, 
    { "Age": 79, "AnnuityRate": 8.09 }, 
    { "Age": 80, "AnnuityRate": 8.04 }, 
    { "Age": 81, "AnnuityRate": 7.99 }, 
    { "Age": 82, "AnnuityRate": 7.94 }, 
    { "Age": 83, "AnnuityRate": 7.89 }, 
    { "Age": 84, "AnnuityRate": 7.85 }, 
    { "Age": 85, "AnnuityRate": 7.81 }, 
    { "Age": 86, "AnnuityRate": 7.78 }, 
    { "Age": 87, "AnnuityRate": 7.75 }, 
    { "Age": 88, "AnnuityRate": 7.72 }, 
    { "Age": 89, "AnnuityRate": 7.70 }, 
    { "Age": 90, "AnnuityRate": 7.68 }, 
    { "Age": 91, "AnnuityRate": 7.67 }, 
    { "Age": 92, "AnnuityRate": 7.65 }, 
    { "Age": 93, "AnnuityRate": 7.64 }, 
    { "Age": 94, "AnnuityRate": 7.63 }, 
    { "Age": 95, "AnnuityRate": 7.62 }, 
    { "Age": 96, "AnnuityRate": 7.61 }, 
    { "Age": 97, "AnnuityRate": 7.61 }, 
    { "Age": 98, "AnnuityRate": 7.61 }, 
    { "Age": 99, "AnnuityRate": 7.60 }, 
    { "Age": 100, "AnnuityRate": 7.60 }, 
];

levelAnnuityRates.female = [
    { "Age": 40, "AnnuityRate": 9.61 }, 
    { "Age": 41, "AnnuityRate": 9.61 }, 
    { "Age": 42, "AnnuityRate": 9.60 }, 
    { "Age": 43, "AnnuityRate": 9.59 }, 
    { "Age": 44, "AnnuityRate": 9.59 }, 
    { "Age": 45, "AnnuityRate": 9.58 }, 
    { "Age": 46, "AnnuityRate": 9.57 }, 
    { "Age": 47, "AnnuityRate": 9.56 }, 
    { "Age": 48, "AnnuityRate": 9.55 }, 
    { "Age": 49, "AnnuityRate": 9.54 }, 
    { "Age": 50, "AnnuityRate": 9.52 }, 
    { "Age": 51, "AnnuityRate": 9.51 }, 
    { "Age": 52, "AnnuityRate": 9.50 }, 
    { "Age": 53, "AnnuityRate": 9.48 }, 
    { "Age": 54, "AnnuityRate": 9.46 }, 
    { "Age": 55, "AnnuityRate": 9.44 }, 
    { "Age": 56, "AnnuityRate": 9.42 }, 
    { "Age": 57, "AnnuityRate": 9.40 }, 
    { "Age": 58, "AnnuityRate": 9.37 }, 
    { "Age": 59, "AnnuityRate": 9.34 }, 
    { "Age": 60, "AnnuityRate": 9.31 }, 
    { "Age": 61, "AnnuityRate": 9.28 }, 
    { "Age": 62, "AnnuityRate": 9.25 }, 
    { "Age": 63, "AnnuityRate": 9.21 }, 
    { "Age": 64, "AnnuityRate": 9.18 }, 
    { "Age": 65, "AnnuityRate": 9.14 }, 
    { "Age": 66, "AnnuityRate": 9.09 }, 
    { "Age": 67, "AnnuityRate": 9.05 }, 
    { "Age": 68, "AnnuityRate": 9.00 }, 
    { "Age": 69, "AnnuityRate": 8.95 }, 
    { "Age": 70, "AnnuityRate": 8.90 }, 
    { "Age": 71, "AnnuityRate": 8.85 }, 
    { "Age": 72, "AnnuityRate": 8.79 }, 
    { "Age": 73, "AnnuityRate": 8.73 }, 
    { "Age": 74, "AnnuityRate": 8.67 }, 
    { "Age": 75, "AnnuityRate": 8.60 }, 
    { "Age": 76, "AnnuityRate": 8.54 }, 
    { "Age": 77, "AnnuityRate": 8.47 }, 
    { "Age": 78, "AnnuityRate": 8.40 }, 
    { "Age": 79, "AnnuityRate": 8.33 }, 
    { "Age": 80, "AnnuityRate": 8.27 }, 
    { "Age": 81, "AnnuityRate": 8.20 }, 
    { "Age": 82, "AnnuityRate": 8.14 }, 
    { "Age": 83, "AnnuityRate": 8.07 }, 
    { "Age": 84, "AnnuityRate": 8.01 }, 
    { "Age": 85, "AnnuityRate": 7.96 }, 
    { "Age": 86, "AnnuityRate": 7.91 }, 
    { "Age": 87, "AnnuityRate": 7.86 }, 
    { "Age": 88, "AnnuityRate": 7.82 }, 
    { "Age": 89, "AnnuityRate": 7.78 }, 
    { "Age": 90, "AnnuityRate": 7.75 }, 
    { "Age": 91, "AnnuityRate": 7.72 }, 
    { "Age": 92, "AnnuityRate": 7.70 }, 
    { "Age": 93, "AnnuityRate": 7.67 }, 
    { "Age": 94, "AnnuityRate": 7.66 }, 
    { "Age": 95, "AnnuityRate": 7.64 }, 
    { "Age": 96, "AnnuityRate": 7.63 }, 
    { "Age": 97, "AnnuityRate": 7.62 }, 
    { "Age": 98, "AnnuityRate": 7.61 }, 
    { "Age": 99, "AnnuityRate": 7.61 }, 
    { "Age": 100, "AnnuityRate": 7.60 }, 
];

// Key "Age" is female's age
const levelAnnuityJointRates = [
    {"Age": 40, "40": 9.78, "41": 9.78, "42": 9.78, "43": 9.77, "44": 9.77, "45": 9.77, "46": 9.77, "47": 9.77, "48": 9.76, "49": 9.76, "50": 9.76, "51": 9.76, "52": 9.75, "53": 9.75, "54": 9.75, "55": 9.75, "56": 9.74, "57": 9.74, "58": 9.74, "59": 9.73, "60": 9.73, "61": 9.73, "62": 9.72, "63": 9.72, "64": 9.72, "65": 9.71, "66": 9.71, "67": 9.7, "68": 9.7, "69": 9.69, "70": 9.69, "71": 9.69, "72": 9.68, "73": 9.68, "74": 9.67, "75": 9.67, "76": 9.66, "77": 9.66, "78": 9.65, "79": 9.65, "80": 9.64, "81": 9.64, "82": 9.64, "83": 9.63, "84": 9.63, "85": 9.63, "86": 9.63, "87": 9.62, "88": 9.62, "89": 9.62, "90": 9.62, "91": 9.62, "92": 9.62, "93": 9.62, "94": 9.62, "95": 9.62, "96": 9.62, "97": 9.62, "98": 9.62, "99": 9.62, "100": 9.62, }, 
    {"Age": 41, "40": 9.78, "41": 9.78, "42": 9.77, "43": 9.77, "44": 9.77, "45": 9.77, "46": 9.77, "47": 9.76, "48": 9.76, "49": 9.76, "50": 9.76, "51": 9.75, "52": 9.75, "53": 9.75, "54": 9.75, "55": 9.74, "56": 9.74, "57": 9.74, "58": 9.73, "59": 9.73, "60": 9.73, "61": 9.72, "62": 9.72, "63": 9.72, "64": 9.71, "65": 9.71, "66": 9.7, "67": 9.7, "68": 9.69, "69": 9.69, "70": 9.69, "71": 9.68, "72": 9.68, "73": 9.67, "74": 9.67, "75": 9.66, "76": 9.66, "77": 9.65, "78": 9.65, "79": 9.64, "80": 9.64, "81": 9.64, "82": 9.63, "83": 9.63, "84": 9.63, "85": 9.62, "86": 9.62, "87": 9.62, "88": 9.62, "89": 9.62, "90": 9.61, "91": 9.61, "92": 9.61, "93": 9.61, "94": 9.61, "95": 9.61, "96": 9.61, "97": 9.61, "98": 9.61, "99": 9.61, "100": 9.61, }, 
    {"Age": 42, "40": 9.78, "41": 9.77, "42": 9.77, "43": 9.77, "44": 9.77, "45": 9.77, "46": 9.76, "47": 9.76, "48": 9.76, "49": 9.76, "50": 9.75, "51": 9.75, "52": 9.75, "53": 9.75, "54": 9.74, "55": 9.74, "56": 9.74, "57": 9.73, "58": 9.73, "59": 9.73, "60": 9.72, "61": 9.72, "62": 9.72, "63": 9.71, "64": 9.71, "65": 9.7, "66": 9.7, "67": 9.69, "68": 9.69, "69": 9.69, "70": 9.68, "71": 9.68, "72": 9.67, "73": 9.67, "74": 9.66, "75": 9.66, "76": 9.65, "77": 9.65, "78": 9.64, "79": 9.64, "80": 9.63, "81": 9.63, "82": 9.63, "83": 9.62, "84": 9.62, "85": 9.62, "86": 9.61, "87": 9.61, "88": 9.61, "89": 9.61, "90": 9.61, "91": 9.61, "92": 9.61, "93": 9.61, "94": 9.6, "95": 9.6, "96": 9.6, "97": 9.6, "98": 9.6, "99": 9.6, "100": 9.6, }, 
    {"Age": 43, "40": 9.77, "41": 9.77, "42": 9.77, "43": 9.77, "44": 9.77, "45": 9.76, "46": 9.76, "47": 9.76, "48": 9.76, "49": 9.75, "50": 9.75, "51": 9.75, "52": 9.75, "53": 9.74, "54": 9.74, "55": 9.74, "56": 9.73, "57": 9.73, "58": 9.73, "59": 9.72, "60": 9.72, "61": 9.72, "62": 9.71, "63": 9.71, "64": 9.7, "65": 9.7, "66": 9.69, "67": 9.69, "68": 9.69, "69": 9.68, "70": 9.68, "71": 9.67, "72": 9.67, "73": 9.66, "74": 9.65, "75": 9.65, "76": 9.64, "77": 9.64, "78": 9.64, "79": 9.63, "80": 9.63, "81": 9.62, "82": 9.62, "83": 9.62, "84": 9.61, "85": 9.61, "86": 9.61, "87": 9.61, "88": 9.6, "89": 9.6, "90": 9.6, "91": 9.6, "92": 9.6, "93": 9.6, "94": 9.6, "95": 9.6, "96": 9.6, "97": 9.6, "98": 9.6, "99": 9.6, "100": 9.6, }, 
    {"Age": 44, "40": 9.77, "41": 9.77, "42": 9.77, "43": 9.77, "44": 9.76, "45": 9.76, "46": 9.76, "47": 9.76, "48": 9.75, "49": 9.75, "50": 9.75, "51": 9.75, "52": 9.74, "53": 9.74, "54": 9.74, "55": 9.73, "56": 9.73, "57": 9.73, "58": 9.72, "59": 9.72, "60": 9.72, "61": 9.71, "62": 9.71, "63": 9.7, "64": 9.7, "65": 9.69, "66": 9.69, "67": 9.68, "68": 9.68, "69": 9.67, "70": 9.67, "71": 9.66, "72": 9.66, "73": 9.65, "74": 9.65, "75": 9.64, "76": 9.64, "77": 9.63, "78": 9.63, "79": 9.62, "80": 9.62, "81": 9.62, "82": 9.61, "83": 9.61, "84": 9.61, "85": 9.6, "86": 9.6, "87": 9.6, "88": 9.6, "89": 9.59, "90": 9.59, "91": 9.59, "92": 9.59, "93": 9.59, "94": 9.59, "95": 9.59, "96": 9.59, "97": 9.59, "98": 9.59, "99": 9.59, "100": 9.59, }, 
    {"Age": 45, "40": 9.77, "41": 9.77, "42": 9.77, "43": 9.76, "44": 9.76, "45": 9.76, "46": 9.76, "47": 9.75, "48": 9.75, "49": 9.75, "50": 9.75, "51": 9.74, "52": 9.74, "53": 9.74, "54": 9.73, "55": 9.73, "56": 9.73, "57": 9.72, "58": 9.72, "59": 9.72, "60": 9.71, "61": 9.71, "62": 9.7, "63": 9.7, "64": 9.69, "65": 9.69, "66": 9.68, "67": 9.68, "68": 9.67, "69": 9.67, "70": 9.66, "71": 9.66, "72": 9.65, "73": 9.65, "74": 9.64, "75": 9.64, "76": 9.63, "77": 9.63, "78": 9.62, "79": 9.62, "80": 9.61, "81": 9.61, "82": 9.6, "83": 9.6, "84": 9.6, "85": 9.59, "86": 9.59, "87": 9.59, "88": 9.59, "89": 9.59, "90": 9.59, "91": 9.58, "92": 9.58, "93": 9.58, "94": 9.58, "95": 9.58, "96": 9.58, "97": 9.58, "98": 9.58, "99": 9.58, "100": 9.58, }, 
    {"Age": 46, "40": 9.77, "41": 9.77, "42": 9.76, "43": 9.76, "44": 9.76, "45": 9.76, "46": 9.75, "47": 9.75, "48": 9.75, "49": 9.74, "50": 9.74, "51": 9.74, "52": 9.74, "53": 9.73, "54": 9.73, "55": 9.73, "56": 9.72, "57": 9.72, "58": 9.72, "59": 9.71, "60": 9.71, "61": 9.7, "62": 9.7, "63": 9.69, "64": 9.69, "65": 9.68, "66": 9.68, "67": 9.67, "68": 9.67, "69": 9.66, "70": 9.66, "71": 9.65, "72": 9.65, "73": 9.64, "74": 9.63, "75": 9.63, "76": 9.62, "77": 9.62, "78": 9.61, "79": 9.61, "80": 9.6, "81": 9.6, "82": 9.6, "83": 9.59, "84": 9.59, "85": 9.59, "86": 9.58, "87": 9.58, "88": 9.58, "89": 9.58, "90": 9.58, "91": 9.57, "92": 9.57, "93": 9.57, "94": 9.57, "95": 9.57, "96": 9.57, "97": 9.57, "98": 9.57, "99": 9.57, "100": 9.57, }, 
    {"Age": 47, "40": 9.76, "41": 9.76, "42": 9.76, "43": 9.76, "44": 9.76, "45": 9.75, "46": 9.75, "47": 9.75, "48": 9.74, "49": 9.74, "50": 9.74, "51": 9.74, "52": 9.73, "53": 9.73, "54": 9.73, "55": 9.72, "56": 9.72, "57": 9.71, "58": 9.71, "59": 9.71, "60": 9.7, "61": 9.7, "62": 9.69, "63": 9.69, "64": 9.68, "65": 9.68, "66": 9.67, "67": 9.67, "68": 9.66, "69": 9.66, "70": 9.65, "71": 9.64, "72": 9.64, "73": 9.63, "74": 9.63, "75": 9.62, "76": 9.62, "77": 9.61, "78": 9.6, "79": 9.6, "80": 9.6, "81": 9.59, "82": 9.59, "83": 9.58, "84": 9.58, "85": 9.58, "86": 9.57, "87": 9.57, "88": 9.57, "89": 9.57, "90": 9.57, "91": 9.57, "92": 9.56, "93": 9.56, "94": 9.56, "95": 9.56, "96": 9.56, "97": 9.56, "98": 9.56, "99": 9.56, "100": 9.56, }, 
    {"Age": 48, "40": 9.76, "41": 9.76, "42": 9.76, "43": 9.75, "44": 9.75, "45": 9.75, "46": 9.75, "47": 9.74, "48": 9.74, "49": 9.74, "50": 9.73, "51": 9.73, "52": 9.73, "53": 9.72, "54": 9.72, "55": 9.72, "56": 9.71, "57": 9.71, "58": 9.7, "59": 9.7, "60": 9.7, "61": 9.69, "62": 9.69, "63": 9.68, "64": 9.68, "65": 9.67, "66": 9.66, "67": 9.66, "68": 9.65, "69": 9.65, "70": 9.64, "71": 9.64, "72": 9.63, "73": 9.62, "74": 9.62, "75": 9.61, "76": 9.61, "77": 9.6, "78": 9.6, "79": 9.59, "80": 9.59, "81": 9.58, "82": 9.58, "83": 9.57, "84": 9.57, "85": 9.57, "86": 9.56, "87": 9.56, "88": 9.56, "89": 9.56, "90": 9.56, "91": 9.55, "92": 9.55, "93": 9.55, "94": 9.55, "95": 9.55, "96": 9.55, "97": 9.55, "98": 9.55, "99": 9.55, "100": 9.55, }, 
    {"Age": 49, "40": 9.76, "41": 9.76, "42": 9.75, "43": 9.75, "44": 9.75, "45": 9.75, "46": 9.74, "47": 9.74, "48": 9.74, "49": 9.73, "50": 9.73, "51": 9.73, "52": 9.72, "53": 9.72, "54": 9.72, "55": 9.71, "56": 9.71, "57": 9.7, "58": 9.7, "59": 9.69, "60": 9.69, "61": 9.68, "62": 9.68, "63": 9.67, "64": 9.67, "65": 9.66, "66": 9.66, "67": 9.65, "68": 9.65, "69": 9.64, "70": 9.63, "71": 9.63, "72": 9.62, "73": 9.62, "74": 9.61, "75": 9.6, "76": 9.6, "77": 9.59, "78": 9.59, "79": 9.58, "80": 9.58, "81": 9.57, "82": 9.57, "83": 9.56, "84": 9.56, "85": 9.56, "86": 9.55, "87": 9.55, "88": 9.55, "89": 9.55, "90": 9.54, "91": 9.54, "92": 9.54, "93": 9.54, "94": 9.54, "95": 9.54, "96": 9.54, "97": 9.54, "98": 9.54, "99": 9.54, "100": 9.54, }, 
    {"Age": 50, "40": 9.76, "41": 9.75, "42": 9.75, "43": 9.75, "44": 9.75, "45": 9.74, "46": 9.74, "47": 9.74, "48": 9.73, "49": 9.73, "50": 9.73, "51": 9.72, "52": 9.72, "53": 9.71, "54": 9.71, "55": 9.71, "56": 9.7, "57": 9.7, "58": 9.69, "59": 9.69, "60": 9.68, "61": 9.68, "62": 9.67, "63": 9.67, "64": 9.66, "65": 9.66, "66": 9.65, "67": 9.64, "68": 9.64, "69": 9.63, "70": 9.62, "71": 9.62, "72": 9.61, "73": 9.61, "74": 9.6, "75": 9.59, "76": 9.59, "77": 9.58, "78": 9.57, "79": 9.57, "80": 9.56, "81": 9.56, "82": 9.55, "83": 9.55, "84": 9.55, "85": 9.54, "86": 9.54, "87": 9.54, "88": 9.54, "89": 9.53, "90": 9.53, "91": 9.53, "92": 9.53, "93": 9.53, "94": 9.53, "95": 9.53, "96": 9.53, "97": 9.53, "98": 9.53, "99": 9.53, "100": 9.53, }, 
    {"Age": 51, "40": 9.75, "41": 9.75, "42": 9.75, "43": 9.74, "44": 9.74, "45": 9.74, "46": 9.73, "47": 9.73, "48": 9.73, "49": 9.72, "50": 9.72, "51": 9.72, "52": 9.71, "53": 9.71, "54": 9.7, "55": 9.7, "56": 9.7, "57": 9.69, "58": 9.69, "59": 9.68, "60": 9.68, "61": 9.67, "62": 9.67, "63": 9.66, "64": 9.65, "65": 9.65, "66": 9.64, "67": 9.63, "68": 9.63, "69": 9.62, "70": 9.62, "71": 9.61, "72": 9.6, "73": 9.59, "74": 9.59, "75": 9.58, "76": 9.57, "77": 9.57, "78": 9.56, "79": 9.56, "80": 9.55, "81": 9.55, "82": 9.54, "83": 9.54, "84": 9.53, "85": 9.53, "86": 9.53, "87": 9.52, "88": 9.52, "89": 9.52, "90": 9.52, "91": 9.52, "92": 9.52, "93": 9.51, "94": 9.51, "95": 9.51, "96": 9.51, "97": 9.51, "98": 9.51, "99": 9.51, "100": 9.51, }, 
    {"Age": 52, "40": 9.75, "41": 9.75, "42": 9.74, "43": 9.74, "44": 9.74, "45": 9.73, "46": 9.73, "47": 9.73, "48": 9.72, "49": 9.72, "50": 9.72, "51": 9.71, "52": 9.71, "53": 9.7, "54": 9.7, "55": 9.69, "56": 9.69, "57": 9.68, "58": 9.68, "59": 9.67, "60": 9.67, "61": 9.66, "62": 9.66, "63": 9.65, "64": 9.64, "65": 9.64, "66": 9.63, "67": 9.62, "68": 9.62, "69": 9.61, "70": 9.6, "71": 9.6, "72": 9.59, "73": 9.58, "74": 9.58, "75": 9.57, "76": 9.56, "77": 9.56, "78": 9.55, "79": 9.54, "80": 9.54, "81": 9.53, "82": 9.53, "83": 9.52, "84": 9.52, "85": 9.52, "86": 9.51, "87": 9.51, "88": 9.51, "89": 9.5, "90": 9.5, "91": 9.5, "92": 9.5, "93": 9.5, "94": 9.5, "95": 9.5, "96": 9.5, "97": 9.5, "98": 9.5, "99": 9.5, "100": 9.5, }, 
    {"Age": 53, "40": 9.74, "41": 9.74, "42": 9.74, "43": 9.74, "44": 9.73, "45": 9.73, "46": 9.73, "47": 9.72, "48": 9.72, "49": 9.71, "50": 9.71, "51": 9.71, "52": 9.7, "53": 9.7, "54": 9.69, "55": 9.69, "56": 9.68, "57": 9.68, "58": 9.67, "59": 9.67, "60": 9.66, "61": 9.65, "62": 9.65, "63": 9.64, "64": 9.64, "65": 9.63, "66": 9.62, "67": 9.61, "68": 9.61, "69": 9.6, "70": 9.59, "71": 9.59, "72": 9.58, "73": 9.57, "74": 9.56, "75": 9.56, "76": 9.55, "77": 9.54, "78": 9.54, "79": 9.53, "80": 9.52, "81": 9.52, "82": 9.51, "83": 9.51, "84": 9.5, "85": 9.5, "86": 9.5, "87": 9.49, "88": 9.49, "89": 9.49, "90": 9.49, "91": 9.49, "92": 9.48, "93": 9.48, "94": 9.48, "95": 9.48, "96": 9.48, "97": 9.48, "98": 9.48, "99": 9.48, "100": 9.48, }, 
    {"Age": 54, "40": 9.74, "41": 9.74, "42": 9.73, "43": 9.73, "44": 9.73, "45": 9.72, "46": 9.72, "47": 9.72, "48": 9.71, "49": 9.71, "50": 9.7, "51": 9.7, "52": 9.7, "53": 9.69, "54": 9.69, "55": 9.68, "56": 9.68, "57": 9.67, "58": 9.66, "59": 9.66, "60": 9.65, "61": 9.65, "62": 9.64, "63": 9.63, "64": 9.63, "65": 9.62, "66": 9.61, "67": 9.6, "68": 9.6, "69": 9.59, "70": 9.58, "71": 9.57, "72": 9.56, "73": 9.56, "74": 9.55, "75": 9.54, "76": 9.53, "77": 9.53, "78": 9.52, "79": 9.51, "80": 9.51, "81": 9.5, "82": 9.5, "83": 9.49, "84": 9.49, "85": 9.48, "86": 9.48, "87": 9.48, "88": 9.47, "89": 9.47, "90": 9.47, "91": 9.47, "92": 9.47, "93": 9.47, "94": 9.46, "95": 9.46, "96": 9.46, "97": 9.46, "98": 9.46, "99": 9.46, "100": 9.46, }, 
    {"Age": 55, "40": 9.74, "41": 9.73, "42": 9.73, "43": 9.73, "44": 9.72, "45": 9.72, "46": 9.72, "47": 9.71, "48": 9.71, "49": 9.7, "50": 9.7, "51": 9.69, "52": 9.69, "53": 9.68, "54": 9.68, "55": 9.67, "56": 9.67, "57": 9.66, "58": 9.66, "59": 9.65, "60": 9.64, "61": 9.64, "62": 9.63, "63": 9.62, "64": 9.61, "65": 9.61, "66": 9.6, "67": 9.59, "68": 9.58, "69": 9.58, "70": 9.57, "71": 9.56, "72": 9.55, "73": 9.54, "74": 9.53, "75": 9.53, "76": 9.52, "77": 9.51, "78": 9.5, "79": 9.5, "80": 9.49, "81": 9.48, "82": 9.48, "83": 9.47, "84": 9.47, "85": 9.46, "86": 9.46, "87": 9.46, "88": 9.45, "89": 9.45, "90": 9.45, "91": 9.45, "92": 9.45, "93": 9.45, "94": 9.44, "95": 9.44, "96": 9.44, "97": 9.44, "98": 9.44, "99": 9.44, "100": 9.44, }, 
    {"Age": 56, "40": 9.73, "41": 9.73, "42": 9.72, "43": 9.72, "44": 9.72, "45": 9.71, "46": 9.71, "47": 9.7, "48": 9.7, "49": 9.7, "50": 9.69, "51": 9.69, "52": 9.68, "53": 9.68, "54": 9.67, "55": 9.66, "56": 9.66, "57": 9.65, "58": 9.65, "59": 9.64, "60": 9.63, "61": 9.63, "62": 9.62, "63": 9.61, "64": 9.6, "65": 9.59, "66": 9.59, "67": 9.58, "68": 9.57, "69": 9.56, "70": 9.55, "71": 9.54, "72": 9.54, "73": 9.53, "74": 9.52, "75": 9.51, "76": 9.5, "77": 9.49, "78": 9.49, "79": 9.48, "80": 9.47, "81": 9.46, "82": 9.46, "83": 9.45, "84": 9.45, "85": 9.44, "86": 9.44, "87": 9.44, "88": 9.43, "89": 9.43, "90": 9.43, "91": 9.43, "92": 9.43, "93": 9.42, "94": 9.42, "95": 9.42, "96": 9.42, "97": 9.42, "98": 9.42, "99": 9.42, "100": 9.42, }, 
    {"Age": 57, "40": 9.73, "41": 9.72, "42": 9.72, "43": 9.72, "44": 9.71, "45": 9.71, "46": 9.7, "47": 9.7, "48": 9.69, "49": 9.69, "50": 9.68, "51": 9.68, "52": 9.67, "53": 9.67, "54": 9.66, "55": 9.66, "56": 9.65, "57": 9.64, "58": 9.64, "59": 9.63, "60": 9.62, "61": 9.61, "62": 9.61, "63": 9.6, "64": 9.59, "65": 9.58, "66": 9.57, "67": 9.56, "68": 9.56, "69": 9.55, "70": 9.54, "71": 9.53, "72": 9.52, "73": 9.51, "74": 9.5, "75": 9.49, "76": 9.48, "77": 9.47, "78": 9.47, "79": 9.46, "80": 9.45, "81": 9.44, "82": 9.44, "83": 9.43, "84": 9.43, "85": 9.42, "86": 9.42, "87": 9.41, "88": 9.41, "89": 9.41, "90": 9.41, "91": 9.4, "92": 9.4, "93": 9.4, "94": 9.4, "95": 9.4, "96": 9.4, "97": 9.4, "98": 9.4, "99": 9.4, "100": 9.4, }, 
    {"Age": 58, "40": 9.72, "41": 9.72, "42": 9.71, "43": 9.71, "44": 9.71, "45": 9.7, "46": 9.7, "47": 9.69, "48": 9.69, "49": 9.68, "50": 9.68, "51": 9.67, "52": 9.67, "53": 9.66, "54": 9.65, "55": 9.65, "56": 9.64, "57": 9.63, "58": 9.63, "59": 9.62, "60": 9.61, "61": 9.6, "62": 9.59, "63": 9.59, "64": 9.58, "65": 9.57, "66": 9.56, "67": 9.55, "68": 9.54, "69": 9.53, "70": 9.52, "71": 9.51, "72": 9.5, "73": 9.49, "74": 9.48, "75": 9.47, "76": 9.46, "77": 9.45, "78": 9.44, "79": 9.44, "80": 9.43, "81": 9.42, "82": 9.41, "83": 9.41, "84": 9.4, "85": 9.4, "86": 9.39, "87": 9.39, "88": 9.39, "89": 9.38, "90": 9.38, "91": 9.38, "92": 9.38, "93": 9.38, "94": 9.38, "95": 9.37, "96": 9.37, "97": 9.37, "98": 9.37, "99": 9.37, "100": 9.37, }, 
    {"Age": 59, "40": 9.72, "41": 9.71, "42": 9.71, "43": 9.7, "44": 9.7, "45": 9.69, "46": 9.69, "47": 9.68, "48": 9.68, "49": 9.67, "50": 9.67, "51": 9.66, "52": 9.66, "53": 9.65, "54": 9.64, "55": 9.64, "56": 9.63, "57": 9.62, "58": 9.62, "59": 9.61, "60": 9.6, "61": 9.59, "62": 9.58, "63": 9.57, "64": 9.56, "65": 9.55, "66": 9.54, "67": 9.53, "68": 9.52, "69": 9.51, "70": 9.5, "71": 9.49, "72": 9.48, "73": 9.47, "74": 9.46, "75": 9.45, "76": 9.44, "77": 9.43, "78": 9.42, "79": 9.41, "80": 9.4, "81": 9.4, "82": 9.39, "83": 9.38, "84": 9.38, "85": 9.37, "86": 9.37, "87": 9.36, "88": 9.36, "89": 9.36, "90": 9.35, "91": 9.35, "92": 9.35, "93": 9.35, "94": 9.35, "95": 9.35, "96": 9.35, "97": 9.35, "98": 9.34, "99": 9.34, "100": 9.34, }, 
    {"Age": 60, "40": 9.71, "41": 9.71, "42": 9.7, "43": 9.7, "44": 9.69, "45": 9.69, "46": 9.68, "47": 9.68, "48": 9.67, "49": 9.67, "50": 9.66, "51": 9.65, "52": 9.65, "53": 9.64, "54": 9.63, "55": 9.63, "56": 9.62, "57": 9.61, "58": 9.6, "59": 9.6, "60": 9.59, "61": 9.58, "62": 9.57, "63": 9.56, "64": 9.55, "65": 9.54, "66": 9.53, "67": 9.52, "68": 9.51, "69": 9.5, "70": 9.48, "71": 9.47, "72": 9.46, "73": 9.45, "74": 9.44, "75": 9.43, "76": 9.42, "77": 9.41, "78": 9.4, "79": 9.39, "80": 9.38, "81": 9.37, "82": 9.36, "83": 9.36, "84": 9.35, "85": 9.34, "86": 9.34, "87": 9.34, "88": 9.33, "89": 9.33, "90": 9.33, "91": 9.32, "92": 9.32, "93": 9.32, "94": 9.32, "95": 9.32, "96": 9.32, "97": 9.32, "98": 9.32, "99": 9.32, "100": 9.31, }, 
    {"Age": 61, "40": 9.7, "41": 9.7, "42": 9.7, "43": 9.69, "44": 9.69, "45": 9.68, "46": 9.68, "47": 9.67, "48": 9.66, "49": 9.66, "50": 9.65, "51": 9.64, "52": 9.64, "53": 9.63, "54": 9.62, "55": 9.62, "56": 9.61, "57": 9.6, "58": 9.59, "59": 9.58, "60": 9.57, "61": 9.56, "62": 9.55, "63": 9.54, "64": 9.53, "65": 9.52, "66": 9.51, "67": 9.5, "68": 9.49, "69": 9.48, "70": 9.46, "71": 9.45, "72": 9.44, "73": 9.43, "74": 9.42, "75": 9.4, "76": 9.39, "77": 9.38, "78": 9.37, "79": 9.36, "80": 9.35, "81": 9.34, "82": 9.34, "83": 9.33, "84": 9.32, "85": 9.32, "86": 9.31, "87": 9.31, "88": 9.3, "89": 9.3, "90": 9.3, "91": 9.29, "92": 9.29, "93": 9.29, "94": 9.29, "95": 9.29, "96": 9.29, "97": 9.28, "98": 9.28, "99": 9.28, "100": 9.28, }, 
    {"Age": 62, "40": 9.7, "41": 9.69, "42": 9.69, "43": 9.68, "44": 9.68, "45": 9.67, "46": 9.67, "47": 9.66, "48": 9.65, "49": 9.65, "50": 9.64, "51": 9.64, "52": 9.63, "53": 9.62, "54": 9.61, "55": 9.61, "56": 9.6, "57": 9.59, "58": 9.58, "59": 9.57, "60": 9.56, "61": 9.55, "62": 9.54, "63": 9.53, "64": 9.52, "65": 9.5, "66": 9.49, "67": 9.48, "68": 9.47, "69": 9.46, "70": 9.44, "71": 9.43, "72": 9.42, "73": 9.4, "74": 9.39, "75": 9.38, "76": 9.37, "77": 9.36, "78": 9.34, "79": 9.33, "80": 9.32, "81": 9.31, "82": 9.31, "83": 9.3, "84": 9.29, "85": 9.28, "86": 9.28, "87": 9.27, "88": 9.27, "89": 9.27, "90": 9.26, "91": 9.26, "92": 9.26, "93": 9.26, "94": 9.25, "95": 9.25, "96": 9.25, "97": 9.25, "98": 9.25, "99": 9.25, "100": 9.25, }, 
    {"Age": 63, "40": 9.69, "41": 9.69, "42": 9.68, "43": 9.68, "44": 9.67, "45": 9.67, "46": 9.66, "47": 9.65, "48": 9.65, "49": 9.64, "50": 9.63, "51": 9.63, "52": 9.62, "53": 9.61, "54": 9.6, "55": 9.59, "56": 9.58, "57": 9.58, "58": 9.57, "59": 9.56, "60": 9.55, "61": 9.53, "62": 9.52, "63": 9.51, "64": 9.5, "65": 9.49, "66": 9.47, "67": 9.46, "68": 9.45, "69": 9.43, "70": 9.42, "71": 9.41, "72": 9.39, "73": 9.38, "74": 9.37, "75": 9.35, "76": 9.34, "77": 9.33, "78": 9.32, "79": 9.3, "80": 9.29, "81": 9.28, "82": 9.27, "83": 9.27, "84": 9.26, "85": 9.25, "86": 9.24, "87": 9.24, "88": 9.24, "89": 9.23, "90": 9.23, "91": 9.23, "92": 9.22, "93": 9.22, "94": 9.22, "95": 9.22, "96": 9.22, "97": 9.22, "98": 9.22, "99": 9.21, "100": 9.21, }, 
    {"Age": 64, "40": 9.68, "41": 9.68, "42": 9.67, "43": 9.67, "44": 9.66, "45": 9.66, "46": 9.65, "47": 9.64, "48": 9.64, "49": 9.63, "50": 9.62, "51": 9.61, "52": 9.61, "53": 9.6, "54": 9.59, "55": 9.58, "56": 9.57, "57": 9.56, "58": 9.55, "59": 9.54, "60": 9.53, "61": 9.52, "62": 9.51, "63": 9.49, "64": 9.48, "65": 9.47, "66": 9.45, "67": 9.44, "68": 9.43, "69": 9.41, "70": 9.4, "71": 9.38, "72": 9.37, "73": 9.35, "74": 9.34, "75": 9.33, "76": 9.31, "77": 9.3, "78": 9.28, "79": 9.27, "80": 9.26, "81": 9.25, "82": 9.24, "83": 9.23, "84": 9.22, "85": 9.22, "86": 9.21, "87": 9.2, "88": 9.2, "89": 9.19, "90": 9.19, "91": 9.19, "92": 9.19, "93": 9.18, "94": 9.18, "95": 9.18, "96": 9.18, "97": 9.18, "98": 9.18, "99": 9.18, "100": 9.18, }, 
    {"Age": 65, "40": 9.68, "41": 9.67, "42": 9.67, "43": 9.66, "44": 9.65, "45": 9.65, "46": 9.64, "47": 9.63, "48": 9.63, "49": 9.62, "50": 9.61, "51": 9.6, "52": 9.6, "53": 9.59, "54": 9.58, "55": 9.57, "56": 9.56, "57": 9.55, "58": 9.54, "59": 9.53, "60": 9.51, "61": 9.5, "62": 9.49, "63": 9.48, "64": 9.46, "65": 9.45, "66": 9.43, "67": 9.42, "68": 9.4, "69": 9.39, "70": 9.37, "71": 9.36, "72": 9.34, "73": 9.33, "74": 9.31, "75": 9.3, "76": 9.28, "77": 9.27, "78": 9.25, "79": 9.24, "80": 9.23, "81": 9.22, "82": 9.21, "83": 9.2, "84": 9.19, "85": 9.18, "86": 9.17, "87": 9.17, "88": 9.16, "89": 9.16, "90": 9.15, "91": 9.15, "92": 9.15, "93": 9.14, "94": 9.14, "95": 9.14, "96": 9.14, "97": 9.14, "98": 9.14, "99": 9.14, "100": 9.14, }, 
    {"Age": 66, "40": 9.67, "41": 9.66, "42": 9.66, "43": 9.65, "44": 9.65, "45": 9.64, "46": 9.63, "47": 9.63, "48": 9.62, "49": 9.61, "50": 9.6, "51": 9.59, "52": 9.58, "53": 9.58, "54": 9.57, "55": 9.56, "56": 9.55, "57": 9.53, "58": 9.52, "59": 9.51, "60": 9.5, "61": 9.49, "62": 9.47, "63": 9.46, "64": 9.44, "65": 9.43, "66": 9.41, "67": 9.4, "68": 9.38, "69": 9.36, "70": 9.35, "71": 9.33, "72": 9.32, "73": 9.3, "74": 9.28, "75": 9.26, "76": 9.25, "77": 9.23, "78": 9.22, "79": 9.2, "80": 9.19, "81": 9.18, "82": 9.17, "83": 9.16, "84": 9.15, "85": 9.14, "86": 9.13, "87": 9.13, "88": 9.12, "89": 9.12, "90": 9.11, "91": 9.11, "92": 9.1, "93": 9.1, "94": 9.1, "95": 9.1, "96": 9.1, "97": 9.1, "98": 9.1, "99": 9.1, "100": 9.09, }, 
    {"Age": 67, "40": 9.66, "41": 9.66, "42": 9.65, "43": 9.64, "44": 9.64, "45": 9.63, "46": 9.62, "47": 9.62, "48": 9.61, "49": 9.6, "50": 9.59, "51": 9.58, "52": 9.57, "53": 9.56, "54": 9.55, "55": 9.54, "56": 9.53, "57": 9.52, "58": 9.51, "59": 9.5, "60": 9.48, "61": 9.47, "62": 9.45, "63": 9.44, "64": 9.42, "65": 9.41, "66": 9.39, "67": 9.37, "68": 9.36, "69": 9.34, "70": 9.32, "71": 9.3, "72": 9.29, "73": 9.27, "74": 9.25, "75": 9.23, "76": 9.22, "77": 9.2, "78": 9.18, "79": 9.17, "80": 9.15, "81": 9.14, "82": 9.13, "83": 9.12, "84": 9.11, "85": 9.1, "86": 9.09, "87": 9.08, "88": 9.08, "89": 9.07, "90": 9.07, "91": 9.06, "92": 9.06, "93": 9.06, "94": 9.06, "95": 9.05, "96": 9.05, "97": 9.05, "98": 9.05, "99": 9.05, "100": 9.05, }, 
    {"Age": 68, "40": 9.65, "41": 9.65, "42": 9.64, "43": 9.64, "44": 9.63, "45": 9.62, "46": 9.61, "47": 9.61, "48": 9.6, "49": 9.59, "50": 9.58, "51": 9.57, "52": 9.56, "53": 9.55, "54": 9.54, "55": 9.53, "56": 9.52, "57": 9.5, "58": 9.49, "59": 9.48, "60": 9.46, "61": 9.45, "62": 9.43, "63": 9.42, "64": 9.4, "65": 9.38, "66": 9.37, "67": 9.35, "68": 9.33, "69": 9.31, "70": 9.29, "71": 9.28, "72": 9.26, "73": 9.24, "74": 9.22, "75": 9.2, "76": 9.18, "77": 9.16, "78": 9.15, "79": 9.13, "80": 9.12, "81": 9.1, "82": 9.09, "83": 9.08, "84": 9.07, "85": 9.06, "86": 9.05, "87": 9.04, "88": 9.03, "89": 9.03, "90": 9.02, "91": 9.02, "92": 9.02, "93": 9.01, "94": 9.01, "95": 9.01, "96": 9.01, "97": 9.01, "98": 9, "99": 9, "100": 9, }, 
    {"Age": 69, "40": 9.65, "41": 9.64, "42": 9.63, "43": 9.63, "44": 9.62, "45": 9.61, "46": 9.6, "47": 9.59, "48": 9.59, "49": 9.58, "50": 9.57, "51": 9.56, "52": 9.55, "53": 9.54, "54": 9.53, "55": 9.51, "56": 9.5, "57": 9.49, "58": 9.48, "59": 9.46, "60": 9.45, "61": 9.43, "62": 9.41, "63": 9.4, "64": 9.38, "65": 9.36, "66": 9.34, "67": 9.32, "68": 9.31, "69": 9.29, "70": 9.27, "71": 9.25, "72": 9.23, "73": 9.21, "74": 9.18, "75": 9.16, "76": 9.14, "77": 9.13, "78": 9.11, "79": 9.09, "80": 9.07, "81": 9.06, "82": 9.04, "83": 9.03, "84": 9.02, "85": 9.01, "86": 9, "87": 8.99, "88": 8.99, "89": 8.98, "90": 8.97, "91": 8.97, "92": 8.97, "93": 8.96, "94": 8.96, "95": 8.96, "96": 8.96, "97": 8.96, "98": 8.96, "99": 8.95, "100": 8.95, }, 
    {"Age": 70, "40": 9.64, "41": 9.63, "42": 9.62, "43": 9.62, "44": 9.61, "45": 9.6, "46": 9.59, "47": 9.58, "48": 9.57, "49": 9.57, "50": 9.56, "51": 9.55, "52": 9.53, "53": 9.52, "54": 9.51, "55": 9.5, "56": 9.49, "57": 9.47, "58": 9.46, "59": 9.44, "60": 9.43, "61": 9.41, "62": 9.39, "63": 9.38, "64": 9.36, "65": 9.34, "66": 9.32, "67": 9.3, "68": 9.28, "69": 9.26, "70": 9.24, "71": 9.22, "72": 9.19, "73": 9.17, "74": 9.15, "75": 9.13, "76": 9.11, "77": 9.09, "78": 9.07, "79": 9.05, "80": 9.03, "81": 9.01, "82": 9, "83": 8.99, "84": 8.97, "85": 8.96, "86": 8.95, "87": 8.94, "88": 8.94, "89": 8.93, "90": 8.92, "91": 8.92, "92": 8.92, "93": 8.91, "94": 8.91, "95": 8.91, "96": 8.91, "97": 8.91, "98": 8.9, "99": 8.9, "100": 8.9, }, 
    {"Age": 71, "40": 9.63, "41": 9.62, "42": 9.62, "43": 9.61, "44": 9.6, "45": 9.59, "46": 9.58, "47": 9.57, "48": 9.56, "49": 9.55, "50": 9.54, "51": 9.53, "52": 9.52, "53": 9.51, "54": 9.5, "55": 9.48, "56": 9.47, "57": 9.46, "58": 9.44, "59": 9.43, "60": 9.41, "61": 9.39, "62": 9.37, "63": 9.36, "64": 9.34, "65": 9.31, "66": 9.29, "67": 9.27, "68": 9.25, "69": 9.23, "70": 9.21, "71": 9.18, "72": 9.16, "73": 9.14, "74": 9.11, "75": 9.09, "76": 9.07, "77": 9.05, "78": 9.02, "79": 9, "80": 8.99, "81": 8.97, "82": 8.95, "83": 8.94, "84": 8.92, "85": 8.91, "86": 8.9, "87": 8.89, "88": 8.88, "89": 8.88, "90": 8.87, "91": 8.87, "92": 8.86, "93": 8.86, "94": 8.86, "95": 8.85, "96": 8.85, "97": 8.85, "98": 8.85, "99": 8.85, "100": 8.85, }, 
    {"Age": 72, "40": 9.62, "41": 9.61, "42": 9.61, "43": 9.6, "44": 9.59, "45": 9.58, "46": 9.57, "47": 9.56, "48": 9.55, "49": 9.54, "50": 9.53, "51": 9.52, "52": 9.51, "53": 9.5, "54": 9.48, "55": 9.47, "56": 9.46, "57": 9.44, "58": 9.43, "59": 9.41, "60": 9.39, "61": 9.37, "62": 9.35, "63": 9.33, "64": 9.31, "65": 9.29, "66": 9.27, "67": 9.25, "68": 9.22, "69": 9.2, "70": 9.18, "71": 9.15, "72": 9.13, "73": 9.1, "74": 9.08, "75": 9.05, "76": 9.03, "77": 9, "78": 8.98, "79": 8.96, "80": 8.94, "81": 8.92, "82": 8.9, "83": 8.89, "84": 8.87, "85": 8.86, "86": 8.85, "87": 8.84, "88": 8.83, "89": 8.82, "90": 8.82, "91": 8.81, "92": 8.81, "93": 8.8, "94": 8.8, "95": 8.8, "96": 8.8, "97": 8.79, "98": 8.79, "99": 8.79, "100": 8.79, }, 
    {"Age": 73, "40": 9.61, "41": 9.6, "42": 9.6, "43": 9.59, "44": 9.58, "45": 9.57, "46": 9.56, "47": 9.55, "48": 9.54, "49": 9.53, "50": 9.52, "51": 9.51, "52": 9.49, "53": 9.48, "54": 9.47, "55": 9.45, "56": 9.44, "57": 9.42, "58": 9.41, "59": 9.39, "60": 9.37, "61": 9.35, "62": 9.33, "63": 9.31, "64": 9.29, "65": 9.26, "66": 9.24, "67": 9.22, "68": 9.19, "69": 9.17, "70": 9.14, "71": 9.12, "72": 9.09, "73": 9.06, "74": 9.04, "75": 9.01, "76": 8.98, "77": 8.96, "78": 8.93, "79": 8.91, "80": 8.89, "81": 8.87, "82": 8.85, "83": 8.83, "84": 8.82, "85": 8.81, "86": 8.79, "87": 8.78, "88": 8.77, "89": 8.77, "90": 8.76, "91": 8.75, "92": 8.75, "93": 8.74, "94": 8.74, "95": 8.74, "96": 8.74, "97": 8.73, "98": 8.73, "99": 8.73, "100": 8.73, }, 
    {"Age": 74, "40": 9.6, "41": 9.59, "42": 9.59, "43": 9.58, "44": 9.57, "45": 9.56, "46": 9.55, "47": 9.54, "48": 9.53, "49": 9.52, "50": 9.5, "51": 9.49, "52": 9.48, "53": 9.47, "54": 9.45, "55": 9.44, "56": 9.42, "57": 9.41, "58": 9.39, "59": 9.37, "60": 9.35, "61": 9.33, "62": 9.31, "63": 9.29, "64": 9.26, "65": 9.24, "66": 9.21, "67": 9.19, "68": 9.16, "69": 9.14, "70": 9.11, "71": 9.08, "72": 9.05, "73": 9.03, "74": 9, "75": 8.97, "76": 8.94, "77": 8.91, "78": 8.89, "79": 8.86, "80": 8.84, "81": 8.82, "82": 8.8, "83": 8.78, "84": 8.76, "85": 8.75, "86": 8.74, "87": 8.72, "88": 8.71, "89": 8.71, "90": 8.7, "91": 8.69, "92": 8.69, "93": 8.68, "94": 8.68, "95": 8.68, "96": 8.67, "97": 8.67, "98": 8.67, "99": 8.67, "100": 8.67, }, 
    {"Age": 75, "40": 9.59, "41": 9.59, "42": 9.58, "43": 9.57, "44": 9.56, "45": 9.55, "46": 9.54, "47": 9.53, "48": 9.52, "49": 9.5, "50": 9.49, "51": 9.48, "52": 9.47, "53": 9.45, "54": 9.44, "55": 9.42, "56": 9.41, "57": 9.39, "58": 9.37, "59": 9.35, "60": 9.33, "61": 9.31, "62": 9.29, "63": 9.26, "64": 9.24, "65": 9.21, "66": 9.19, "67": 9.16, "68": 9.13, "69": 9.11, "70": 9.08, "71": 9.05, "72": 9.02, "73": 8.99, "74": 8.96, "75": 8.93, "76": 8.9, "77": 8.87, "78": 8.84, "79": 8.81, "80": 8.79, "81": 8.77, "82": 8.74, "83": 8.72, "84": 8.71, "85": 8.69, "86": 8.68, "87": 8.66, "88": 8.65, "89": 8.64, "90": 8.64, "91": 8.63, "92": 8.62, "93": 8.62, "94": 8.62, "95": 8.61, "96": 8.61, "97": 8.61, "98": 8.61, "99": 8.61, "100": 8.6, }, 
    {"Age": 76, "40": 9.58, "41": 9.58, "42": 9.57, "43": 9.56, "44": 9.55, "45": 9.54, "46": 9.53, "47": 9.51, "48": 9.5, "49": 9.49, "50": 9.48, "51": 9.46, "52": 9.45, "53": 9.44, "54": 9.42, "55": 9.41, "56": 9.39, "57": 9.37, "58": 9.35, "59": 9.33, "60": 9.31, "61": 9.29, "62": 9.27, "63": 9.24, "64": 9.21, "65": 9.19, "66": 9.16, "67": 9.13, "68": 9.1, "69": 9.07, "70": 9.04, "71": 9.01, "72": 8.98, "73": 8.95, "74": 8.92, "75": 8.88, "76": 8.85, "77": 8.82, "78": 8.79, "79": 8.76, "80": 8.74, "81": 8.71, "82": 8.69, "83": 8.67, "84": 8.65, "85": 8.63, "86": 8.62, "87": 8.6, "88": 8.59, "89": 8.58, "90": 8.57, "91": 8.57, "92": 8.56, "93": 8.55, "94": 8.55, "95": 8.55, "96": 8.54, "97": 8.54, "98": 8.54, "99": 8.54, "100": 8.54, }, 
    {"Age": 77, "40": 9.57, "41": 9.57, "42": 9.56, "43": 9.55, "44": 9.54, "45": 9.53, "46": 9.52, "47": 9.5, "48": 9.49, "49": 9.48, "50": 9.46, "51": 9.45, "52": 9.44, "53": 9.42, "54": 9.41, "55": 9.39, "56": 9.37, "57": 9.35, "58": 9.33, "59": 9.31, "60": 9.29, "61": 9.27, "62": 9.24, "63": 9.22, "64": 9.19, "65": 9.16, "66": 9.13, "67": 9.1, "68": 9.07, "69": 9.04, "70": 9.01, "71": 8.98, "72": 8.94, "73": 8.91, "74": 8.87, "75": 8.84, "76": 8.81, "77": 8.77, "78": 8.74, "79": 8.71, "80": 8.68, "81": 8.66, "82": 8.63, "83": 8.61, "84": 8.59, "85": 8.57, "86": 8.56, "87": 8.54, "88": 8.53, "89": 8.52, "90": 8.51, "91": 8.5, "92": 8.49, "93": 8.49, "94": 8.48, "95": 8.48, "96": 8.48, "97": 8.48, "98": 8.47, "99": 8.47, "100": 8.47, }, 
    {"Age": 78, "40": 9.57, "41": 9.56, "42": 9.55, "43": 9.54, "44": 9.53, "45": 9.52, "46": 9.5, "47": 9.49, "48": 9.48, "49": 9.47, "50": 9.45, "51": 9.44, "52": 9.42, "53": 9.41, "54": 9.39, "55": 9.37, "56": 9.36, "57": 9.34, "58": 9.32, "59": 9.3, "60": 9.27, "61": 9.25, "62": 9.22, "63": 9.19, "64": 9.17, "65": 9.14, "66": 9.11, "67": 9.07, "68": 9.04, "69": 9.01, "70": 8.98, "71": 8.94, "72": 8.91, "73": 8.87, "74": 8.83, "75": 8.8, "76": 8.76, "77": 8.73, "78": 8.69, "79": 8.66, "80": 8.63, "81": 8.6, "82": 8.58, "83": 8.55, "84": 8.53, "85": 8.51, "86": 8.49, "87": 8.48, "88": 8.46, "89": 8.45, "90": 8.44, "91": 8.44, "92": 8.43, "93": 8.42, "94": 8.42, "95": 8.41, "96": 8.41, "97": 8.41, "98": 8.41, "99": 8.4, "100": 8.4, }, 
    {"Age": 79, "40": 9.56, "41": 9.55, "42": 9.54, "43": 9.53, "44": 9.52, "45": 9.51, "46": 9.49, "47": 9.48, "48": 9.47, "49": 9.45, "50": 9.44, "51": 9.42, "52": 9.41, "53": 9.39, "54": 9.38, "55": 9.36, "56": 9.34, "57": 9.32, "58": 9.3, "59": 9.28, "60": 9.25, "61": 9.23, "62": 9.2, "63": 9.17, "64": 9.14, "65": 9.11, "66": 9.08, "67": 9.05, "68": 9.01, "69": 8.98, "70": 8.94, "71": 8.91, "72": 8.87, "73": 8.83, "74": 8.79, "75": 8.75, "76": 8.72, "77": 8.68, "78": 8.64, "79": 8.61, "80": 8.58, "81": 8.55, "82": 8.52, "83": 8.5, "84": 8.47, "85": 8.45, "86": 8.43, "87": 8.42, "88": 8.4, "89": 8.39, "90": 8.38, "91": 8.37, "92": 8.36, "93": 8.36, "94": 8.35, "95": 8.35, "96": 8.34, "97": 8.34, "98": 8.34, "99": 8.34, "100": 8.34, }, 
    {"Age": 80, "40": 9.55, "41": 9.54, "42": 9.53, "43": 9.52, "44": 9.51, "45": 9.49, "46": 9.48, "47": 9.47, "48": 9.46, "49": 9.44, "50": 9.43, "51": 9.41, "52": 9.4, "53": 9.38, "54": 9.36, "55": 9.34, "56": 9.32, "57": 9.3, "58": 9.28, "59": 9.26, "60": 9.23, "61": 9.21, "62": 9.18, "63": 9.15, "64": 9.12, "65": 9.09, "66": 9.05, "67": 9.02, "68": 8.99, "69": 8.95, "70": 8.91, "71": 8.87, "72": 8.83, "73": 8.79, "74": 8.75, "75": 8.71, "76": 8.67, "77": 8.63, "78": 8.6, "79": 8.56, "80": 8.53, "81": 8.5, "82": 8.47, "83": 8.44, "84": 8.41, "85": 8.39, "86": 8.37, "87": 8.35, "88": 8.34, "89": 8.33, "90": 8.32, "91": 8.31, "92": 8.3, "93": 8.29, "94": 8.29, "95": 8.28, "96": 8.28, "97": 8.28, "98": 8.27, "99": 8.27, "100": 8.27, }, 
    {"Age": 81, "40": 9.54, "41": 9.53, "42": 9.52, "43": 9.51, "44": 9.5, "45": 9.48, "46": 9.47, "47": 9.46, "48": 9.44, "49": 9.43, "50": 9.41, "51": 9.4, "52": 9.38, "53": 9.37, "54": 9.35, "55": 9.33, "56": 9.31, "57": 9.29, "58": 9.27, "59": 9.24, "60": 9.22, "61": 9.19, "62": 9.16, "63": 9.13, "64": 9.1, "65": 9.06, "66": 9.03, "67": 8.99, "68": 8.96, "69": 8.92, "70": 8.88, "71": 8.84, "72": 8.8, "73": 8.76, "74": 8.72, "75": 8.67, "76": 8.63, "77": 8.59, "78": 8.55, "79": 8.51, "80": 8.48, "81": 8.44, "82": 8.41, "83": 8.38, "84": 8.36, "85": 8.33, "86": 8.31, "87": 8.29, "88": 8.28, "89": 8.26, "90": 8.25, "91": 8.24, "92": 8.23, "93": 8.23, "94": 8.22, "95": 8.22, "96": 8.21, "97": 8.21, "98": 8.21, "99": 8.21, "100": 8.2, }, 
    {"Age": 82, "40": 9.53, "41": 9.52, "42": 9.51, "43": 9.5, "44": 9.49, "45": 9.48, "46": 9.46, "47": 9.45, "48": 9.43, "49": 9.42, "50": 9.4, "51": 9.39, "52": 9.37, "53": 9.35, "54": 9.33, "55": 9.32, "56": 9.29, "57": 9.27, "58": 9.25, "59": 9.23, "60": 9.2, "61": 9.17, "62": 9.14, "63": 9.11, "64": 9.08, "65": 9.04, "66": 9.01, "67": 8.97, "68": 8.93, "69": 8.89, "70": 8.85, "71": 8.81, "72": 8.77, "73": 8.72, "74": 8.68, "75": 8.63, "76": 8.59, "77": 8.55, "78": 8.51, "79": 8.47, "80": 8.43, "81": 8.39, "82": 8.36, "83": 8.33, "84": 8.3, "85": 8.28, "86": 8.26, "87": 8.24, "88": 8.22, "89": 8.2, "90": 8.19, "91": 8.18, "92": 8.17, "93": 8.17, "94": 8.16, "95": 8.15, "96": 8.15, "97": 8.15, "98": 8.14, "99": 8.14, "100": 8.14, }, 
    {"Age": 83, "40": 9.52, "41": 9.51, "42": 9.5, "43": 9.49, "44": 9.48, "45": 9.47, "46": 9.45, "47": 9.44, "48": 9.42, "49": 9.41, "50": 9.39, "51": 9.38, "52": 9.36, "53": 9.34, "54": 9.32, "55": 9.3, "56": 9.28, "57": 9.26, "58": 9.24, "59": 9.21, "60": 9.18, "61": 9.15, "62": 9.12, "63": 9.09, "64": 9.06, "65": 9.02, "66": 8.98, "67": 8.95, "68": 8.91, "69": 8.87, "70": 8.82, "71": 8.78, "72": 8.74, "73": 8.69, "74": 8.64, "75": 8.6, "76": 8.55, "77": 8.51, "78": 8.46, "79": 8.42, "80": 8.38, "81": 8.35, "82": 8.31, "83": 8.28, "84": 8.25, "85": 8.22, "86": 8.2, "87": 8.18, "88": 8.16, "89": 8.15, "90": 8.13, "91": 8.12, "92": 8.11, "93": 8.11, "94": 8.1, "95": 8.09, "96": 8.09, "97": 8.09, "98": 8.08, "99": 8.08, "100": 8.08, }, 
    {"Age": 84, "40": 9.52, "41": 9.51, "42": 9.5, "43": 9.48, "44": 9.47, "45": 9.46, "46": 9.44, "47": 9.43, "48": 9.41, "49": 9.4, "50": 9.38, "51": 9.37, "52": 9.35, "53": 9.33, "54": 9.31, "55": 9.29, "56": 9.27, "57": 9.25, "58": 9.22, "59": 9.2, "60": 9.17, "61": 9.14, "62": 9.11, "63": 9.07, "64": 9.04, "65": 9, "66": 8.96, "67": 8.92, "68": 8.88, "69": 8.84, "70": 8.8, "71": 8.75, "72": 8.71, "73": 8.66, "74": 8.61, "75": 8.56, "76": 8.52, "77": 8.47, "78": 8.42, "79": 8.38, "80": 8.34, "81": 8.3, "82": 8.27, "83": 8.23, "84": 8.2, "85": 8.17, "86": 8.15, "87": 8.13, "88": 8.11, "89": 8.09, "90": 8.08, "91": 8.07, "92": 8.06, "93": 8.05, "94": 8.04, "95": 8.04, "96": 8.03, "97": 8.03, "98": 8.02, "99": 8.02, "100": 8.02, }, 
    {"Age": 85, "40": 9.51, "41": 9.5, "42": 9.49, "43": 9.48, "44": 9.46, "45": 9.45, "46": 9.44, "47": 9.42, "48": 9.41, "49": 9.39, "50": 9.37, "51": 9.36, "52": 9.34, "53": 9.32, "54": 9.3, "55": 9.28, "56": 9.26, "57": 9.23, "58": 9.21, "59": 9.18, "60": 9.15, "61": 9.12, "62": 9.09, "63": 9.06, "64": 9.02, "65": 8.98, "66": 8.94, "67": 8.9, "68": 8.86, "69": 8.82, "70": 8.77, "71": 8.73, "72": 8.68, "73": 8.63, "74": 8.58, "75": 8.53, "76": 8.48, "77": 8.43, "78": 8.39, "79": 8.34, "80": 8.3, "81": 8.26, "82": 8.22, "83": 8.19, "84": 8.15, "85": 8.13, "86": 8.1, "87": 8.08, "88": 8.06, "89": 8.04, "90": 8.03, "91": 8.01, "92": 8, "93": 7.99, "94": 7.99, "95": 7.98, "96": 7.98, "97": 7.97, "98": 7.97, "99": 7.97, "100": 7.96, }, 
    {"Age": 86, "40": 9.5, "41": 9.49, "42": 9.48, "43": 9.47, "44": 9.46, "45": 9.44, "46": 9.43, "47": 9.41, "48": 9.4, "49": 9.38, "50": 9.36, "51": 9.35, "52": 9.33, "53": 9.31, "54": 9.29, "55": 9.27, "56": 9.25, "57": 9.22, "58": 9.2, "59": 9.17, "60": 9.14, "61": 9.11, "62": 9.08, "63": 9.04, "64": 9, "65": 8.97, "66": 8.93, "67": 8.88, "68": 8.84, "69": 8.8, "70": 8.75, "71": 8.7, "72": 8.65, "73": 8.6, "74": 8.55, "75": 8.5, "76": 8.45, "77": 8.4, "78": 8.35, "79": 8.31, "80": 8.26, "81": 8.22, "82": 8.18, "83": 8.15, "84": 8.11, "85": 8.08, "86": 8.05, "87": 8.03, "88": 8.01, "89": 7.99, "90": 7.98, "91": 7.96, "92": 7.95, "93": 7.94, "94": 7.94, "95": 7.93, "96": 7.93, "97": 7.92, "98": 7.92, "99": 7.92, "100": 7.91, }, 
    {"Age": 87, "40": 9.5, "41": 9.49, "42": 9.48, "43": 9.46, "44": 9.45, "45": 9.44, "46": 9.42, "47": 9.41, "48": 9.39, "49": 9.37, "50": 9.36, "51": 9.34, "52": 9.32, "53": 9.3, "54": 9.28, "55": 9.26, "56": 9.24, "57": 9.21, "58": 9.19, "59": 9.16, "60": 9.13, "61": 9.1, "62": 9.06, "63": 9.03, "64": 8.99, "65": 8.95, "66": 8.91, "67": 8.87, "68": 8.82, "69": 8.78, "70": 8.73, "71": 8.68, "72": 8.63, "73": 8.58, "74": 8.53, "75": 8.48, "76": 8.42, "77": 8.37, "78": 8.32, "79": 8.27, "80": 8.23, "81": 8.19, "82": 8.14, "83": 8.11, "84": 8.07, "85": 8.04, "86": 8.01, "87": 7.99, "88": 7.97, "89": 7.95, "90": 7.93, "91": 7.92, "92": 7.91, "93": 7.9, "94": 7.89, "95": 7.89, "96": 7.88, "97": 7.88, "98": 7.87, "99": 7.87, "100": 7.87, }, 
    {"Age": 88, "40": 9.49, "41": 9.48, "42": 9.47, "43": 9.46, "44": 9.45, "45": 9.43, "46": 9.42, "47": 9.4, "48": 9.38, "49": 9.37, "50": 9.35, "51": 9.33, "52": 9.31, "53": 9.29, "54": 9.27, "55": 9.25, "56": 9.23, "57": 9.2, "58": 9.18, "59": 9.15, "60": 9.12, "61": 9.09, "62": 9.05, "63": 9.02, "64": 8.98, "65": 8.94, "66": 8.9, "67": 8.85, "68": 8.81, "69": 8.76, "70": 8.71, "71": 8.66, "72": 8.61, "73": 8.56, "74": 8.51, "75": 8.45, "76": 8.4, "77": 8.35, "78": 8.29, "79": 8.24, "80": 8.2, "81": 8.15, "82": 8.11, "83": 8.07, "84": 8.04, "85": 8.01, "86": 7.98, "87": 7.95, "88": 7.93, "89": 7.91, "90": 7.89, "91": 7.88, "92": 7.87, "93": 7.86, "94": 7.85, "95": 7.84, "96": 7.84, "97": 7.83, "98": 7.83, "99": 7.83, "100": 7.82, }, 
    {"Age": 89, "40": 9.49, "41": 9.48, "42": 9.47, "43": 9.45, "44": 9.44, "45": 9.43, "46": 9.41, "47": 9.39, "48": 9.38, "49": 9.36, "50": 9.34, "51": 9.33, "52": 9.31, "53": 9.29, "54": 9.27, "55": 9.24, "56": 9.22, "57": 9.2, "58": 9.17, "59": 9.14, "60": 9.11, "61": 9.08, "62": 9.04, "63": 9.01, "64": 8.97, "65": 8.93, "66": 8.88, "67": 8.84, "68": 8.79, "69": 8.75, "70": 8.7, "71": 8.65, "72": 8.6, "73": 8.54, "74": 8.49, "75": 8.43, "76": 8.38, "77": 8.32, "78": 8.27, "79": 8.22, "80": 8.17, "81": 8.13, "82": 8.08, "83": 8.04, "84": 8.01, "85": 7.97, "86": 7.94, "87": 7.92, "88": 7.89, "89": 7.88, "90": 7.86, "91": 7.84, "92": 7.83, "93": 7.82, "94": 7.81, "95": 7.81, "96": 7.8, "97": 7.8, "98": 7.79, "99": 7.79, "100": 7.79, }, 
    {"Age": 90, "40": 9.49, "41": 9.47, "42": 9.46, "43": 9.45, "44": 9.44, "45": 9.42, "46": 9.41, "47": 9.39, "48": 9.37, "49": 9.36, "50": 9.34, "51": 9.32, "52": 9.3, "53": 9.28, "54": 9.26, "55": 9.24, "56": 9.21, "57": 9.19, "58": 9.16, "59": 9.13, "60": 9.1, "61": 9.07, "62": 9.03, "63": 9, "64": 8.96, "65": 8.92, "66": 8.87, "67": 8.83, "68": 8.78, "69": 8.73, "70": 8.69, "71": 8.63, "72": 8.58, "73": 8.52, "74": 8.47, "75": 8.41, "76": 8.36, "77": 8.3, "78": 8.25, "79": 8.2, "80": 8.15, "81": 8.1, "82": 8.06, "83": 8.02, "84": 7.98, "85": 7.95, "86": 7.91, "87": 7.89, "88": 7.86, "89": 7.84, "90": 7.83, "91": 7.81, "92": 7.8, "93": 7.79, "94": 7.78, "95": 7.77, "96": 7.77, "97": 7.76, "98": 7.76, "99": 7.76, "100": 7.75, }, 
    {"Age": 91, "40": 9.48, "41": 9.47, "42": 9.46, "43": 9.45, "44": 9.43, "45": 9.42, "46": 9.4, "47": 9.39, "48": 9.37, "49": 9.35, "50": 9.33, "51": 9.32, "52": 9.3, "53": 9.28, "54": 9.25, "55": 9.23, "56": 9.21, "57": 9.18, "58": 9.16, "59": 9.13, "60": 9.1, "61": 9.06, "62": 9.03, "63": 8.99, "64": 8.95, "65": 8.91, "66": 8.86, "67": 8.82, "68": 8.77, "69": 8.72, "70": 8.67, "71": 8.62, "72": 8.57, "73": 8.51, "74": 8.45, "75": 8.4, "76": 8.34, "77": 8.28, "78": 8.23, "79": 8.18, "80": 8.13, "81": 8.08, "82": 8.04, "83": 7.99, "84": 7.96, "85": 7.92, "86": 7.89, "87": 7.86, "88": 7.84, "89": 7.82, "90": 7.8, "91": 7.79, "92": 7.77, "93": 7.76, "94": 7.75, "95": 7.75, "96": 7.74, "97": 7.74, "98": 7.73, "99": 7.73, "100": 7.73, }, 
    {"Age": 92, "40": 9.48, "41": 9.47, "42": 9.46, "43": 9.44, "44": 9.43, "45": 9.41, "46": 9.4, "47": 9.38, "48": 9.37, "49": 9.35, "50": 9.33, "51": 9.31, "52": 9.29, "53": 9.27, "54": 9.25, "55": 9.23, "56": 9.2, "57": 9.18, "58": 9.15, "59": 9.12, "60": 9.09, "61": 9.06, "62": 9.02, "63": 8.98, "64": 8.94, "65": 8.9, "66": 8.86, "67": 8.81, "68": 8.76, "69": 8.71, "70": 8.66, "71": 8.61, "72": 8.56, "73": 8.5, "74": 8.44, "75": 8.38, "76": 8.33, "77": 8.27, "78": 8.21, "79": 8.16, "80": 8.11, "81": 8.06, "82": 8.02, "83": 7.97, "84": 7.94, "85": 7.9, "86": 7.87, "87": 7.84, "88": 7.82, "89": 7.8, "90": 7.78, "91": 7.76, "92": 7.75, "93": 7.74, "94": 7.73, "95": 7.72, "96": 7.72, "97": 7.71, "98": 7.71, "99": 7.7, "100": 7.7, }, 
    {"Age": 93, "40": 9.48, "41": 9.47, "42": 9.45, "43": 9.44, "44": 9.43, "45": 9.41, "46": 9.4, "47": 9.38, "48": 9.36, "49": 9.35, "50": 9.33, "51": 9.31, "52": 9.29, "53": 9.27, "54": 9.25, "55": 9.22, "56": 9.2, "57": 9.17, "58": 9.15, "59": 9.12, "60": 9.09, "61": 9.05, "62": 9.02, "63": 8.98, "64": 8.94, "65": 8.89, "66": 8.85, "67": 8.8, "68": 8.76, "69": 8.71, "70": 8.66, "71": 8.6, "72": 8.55, "73": 8.49, "74": 8.43, "75": 8.37, "76": 8.31, "77": 8.26, "78": 8.2, "79": 8.15, "80": 8.1, "81": 8.05, "82": 8, "83": 7.96, "84": 7.92, "85": 7.88, "86": 7.85, "87": 7.82, "88": 7.8, "89": 7.78, "90": 7.76, "91": 7.74, "92": 7.73, "93": 7.72, "94": 7.71, "95": 7.7, "96": 7.7, "97": 7.69, "98": 7.69, "99": 7.68, "100": 7.68, }, 
    {"Age": 94, "40": 9.48, "41": 9.46, "42": 9.45, "43": 9.44, "44": 9.43, "45": 9.41, "46": 9.39, "47": 9.38, "48": 9.36, "49": 9.34, "50": 9.32, "51": 9.31, "52": 9.29, "53": 9.26, "54": 9.24, "55": 9.22, "56": 9.2, "57": 9.17, "58": 9.14, "59": 9.11, "60": 9.08, "61": 9.05, "62": 9.01, "63": 8.97, "64": 8.93, "65": 8.89, "66": 8.84, "67": 8.8, "68": 8.75, "69": 8.7, "70": 8.65, "71": 8.59, "72": 8.54, "73": 8.48, "74": 8.42, "75": 8.36, "76": 8.3, "77": 8.25, "78": 8.19, "79": 8.14, "80": 8.08, "81": 8.03, "82": 7.99, "83": 7.94, "84": 7.9, "85": 7.87, "86": 7.84, "87": 7.81, "88": 7.78, "89": 7.76, "90": 7.74, "91": 7.73, "92": 7.71, "93": 7.7, "94": 7.69, "95": 7.69, "96": 7.68, "97": 7.67, "98": 7.67, "99": 7.67, "100": 7.66, }, 
    {"Age": 95, "40": 9.47, "41": 9.46, "42": 9.45, "43": 9.44, "44": 9.42, "45": 9.41, "46": 9.39, "47": 9.38, "48": 9.36, "49": 9.34, "50": 9.32, "51": 9.3, "52": 9.28, "53": 9.26, "54": 9.24, "55": 9.22, "56": 9.19, "57": 9.17, "58": 9.14, "59": 9.11, "60": 9.08, "61": 9.04, "62": 9.01, "63": 8.97, "64": 8.93, "65": 8.88, "66": 8.84, "67": 8.79, "68": 8.74, "69": 8.7, "70": 8.64, "71": 8.59, "72": 8.53, "73": 8.47, "74": 8.42, "75": 8.36, "76": 8.3, "77": 8.24, "78": 8.18, "79": 8.13, "80": 8.07, "81": 8.02, "82": 7.98, "83": 7.93, "84": 7.89, "85": 7.86, "86": 7.82, "87": 7.79, "88": 7.77, "89": 7.75, "90": 7.73, "91": 7.71, "92": 7.7, "93": 7.69, "94": 7.68, "95": 7.67, "96": 7.67, "97": 7.66, "98": 7.66, "99": 7.65, "100": 7.65, }, 
    {"Age": 96, "40": 9.47, "41": 9.46, "42": 9.45, "43": 9.44, "44": 9.42, "45": 9.41, "46": 9.39, "47": 9.37, "48": 9.36, "49": 9.34, "50": 9.32, "51": 9.3, "52": 9.28, "53": 9.26, "54": 9.24, "55": 9.21, "56": 9.19, "57": 9.16, "58": 9.14, "59": 9.11, "60": 9.08, "61": 9.04, "62": 9, "63": 8.96, "64": 8.92, "65": 8.88, "66": 8.84, "67": 8.79, "68": 8.74, "69": 8.69, "70": 8.64, "71": 8.58, "72": 8.53, "73": 8.47, "74": 8.41, "75": 8.35, "76": 8.29, "77": 8.23, "78": 8.17, "79": 8.12, "80": 8.07, "81": 8.02, "82": 7.97, "83": 7.92, "84": 7.88, "85": 7.85, "86": 7.81, "87": 7.78, "88": 7.76, "89": 7.74, "90": 7.72, "91": 7.7, "92": 7.69, "93": 7.68, "94": 7.67, "95": 7.66, "96": 7.65, "97": 7.65, "98": 7.64, "99": 7.64, "100": 7.64, }, 
    {"Age": 97, "40": 9.47, "41": 9.46, "42": 9.45, "43": 9.44, "44": 9.42, "45": 9.41, "46": 9.39, "47": 9.37, "48": 9.36, "49": 9.34, "50": 9.32, "51": 9.3, "52": 9.28, "53": 9.26, "54": 9.24, "55": 9.21, "56": 9.19, "57": 9.16, "58": 9.14, "59": 9.11, "60": 9.07, "61": 9.04, "62": 9, "63": 8.96, "64": 8.92, "65": 8.88, "66": 8.83, "67": 8.79, "68": 8.74, "69": 8.69, "70": 8.63, "71": 8.58, "72": 8.52, "73": 8.46, "74": 8.41, "75": 8.34, "76": 8.28, "77": 8.23, "78": 8.17, "79": 8.11, "80": 8.06, "81": 8.01, "82": 7.96, "83": 7.92, "84": 7.88, "85": 7.84, "86": 7.81, "87": 7.78, "88": 7.75, "89": 7.73, "90": 7.71, "91": 7.69, "92": 7.68, "93": 7.67, "94": 7.66, "95": 7.65, "96": 7.64, "97": 7.64, "98": 7.64, "99": 7.63, "100": 7.63, }, 
    {"Age": 98, "40": 9.47, "41": 9.46, "42": 9.45, "43": 9.43, "44": 9.42, "45": 9.4, "46": 9.39, "47": 9.37, "48": 9.35, "49": 9.34, "50": 9.32, "51": 9.3, "52": 9.28, "53": 9.26, "54": 9.24, "55": 9.21, "56": 9.19, "57": 9.16, "58": 9.13, "59": 9.1, "60": 9.07, "61": 9.04, "62": 9, "63": 8.96, "64": 8.92, "65": 8.88, "66": 8.83, "67": 8.78, "68": 8.74, "69": 8.68, "70": 8.63, "71": 8.58, "72": 8.52, "73": 8.46, "74": 8.4, "75": 8.34, "76": 8.28, "77": 8.22, "78": 8.16, "79": 8.11, "80": 8.05, "81": 8, "82": 7.96, "83": 7.91, "84": 7.87, "85": 7.83, "86": 7.8, "87": 7.77, "88": 7.74, "89": 7.72, "90": 7.7, "91": 7.69, "92": 7.67, "93": 7.66, "94": 7.65, "95": 7.64, "96": 7.64, "97": 7.63, "98": 7.63, "99": 7.62, "100": 7.62, }, 
    {"Age": 99, "40": 9.47, "41": 9.46, "42": 9.45, "43": 9.43, "44": 9.42, "45": 9.4, "46": 9.39, "47": 9.37, "48": 9.35, "49": 9.34, "50": 9.32, "51": 9.3, "52": 9.28, "53": 9.26, "54": 9.23, "55": 9.21, "56": 9.19, "57": 9.16, "58": 9.13, "59": 9.1, "60": 9.07, "61": 9.04, "62": 9, "63": 8.96, "64": 8.92, "65": 8.87, "66": 8.83, "67": 8.78, "68": 8.73, "69": 8.68, "70": 8.63, "71": 8.57, "72": 8.52, "73": 8.46, "74": 8.4, "75": 8.34, "76": 8.28, "77": 8.22, "78": 8.16, "79": 8.1, "80": 8.05, "81": 8, "82": 7.95, "83": 7.91, "84": 7.87, "85": 7.83, "86": 7.79, "87": 7.76, "88": 7.74, "89": 7.72, "90": 7.7, "91": 7.68, "92": 7.67, "93": 7.66, "94": 7.65, "95": 7.64, "96": 7.63, "97": 7.63, "98": 7.62, "99": 7.62, "100": 7.61, }, 
    {"Age": 100, "40": 9.47, "41": 9.46, "42": 9.45, "43": 9.43, "44": 9.42, "45": 9.4, "46": 9.39, "47": 9.37, "48": 9.35, "49": 9.33, "50": 9.32, "51": 9.3, "52": 9.28, "53": 9.26, "54": 9.23, "55": 9.21, "56": 9.19, "57": 9.16, "58": 9.13, "59": 9.1, "60": 9.07, "61": 9.03, "62": 9, "63": 8.96, "64": 8.92, "65": 8.87, "66": 8.83, "67": 8.78, "68": 8.73, "69": 8.68, "70": 8.63, "71": 8.57, "72": 8.51, "73": 8.46, "74": 8.4, "75": 8.34, "76": 8.27, "77": 8.21, "78": 8.16, "79": 8.1, "80": 8.05, "81": 8, "82": 7.95, "83": 7.9, "84": 7.86, "85": 7.82, "86": 7.79, "87": 7.76, "88": 7.73, "89": 7.71, "90": 7.69, "91": 7.68, "92": 7.66, "93": 7.65, "94": 7.64, "95": 7.63, "96": 7.63, "97": 7.62, "98": 7.62, "99": 7.61, "100": 7.61, }, 
];

// INFLATION LINKED ANNUITY RATES

const inflationLinkedAnnuityRates = {};

inflationLinkedAnnuityRates.male = [
    { "Age": 40, "AnnuityRate": 18.54 }, 
    { "Age": 41, "AnnuityRate": 18.43 }, 
    { "Age": 42, "AnnuityRate": 18.31 }, 
    { "Age": 43, "AnnuityRate": 18.19 }, 
    { "Age": 44, "AnnuityRate": 18.07 }, 
    { "Age": 45, "AnnuityRate": 17.93 }, 
    { "Age": 46, "AnnuityRate": 17.80 }, 
    { "Age": 47, "AnnuityRate": 17.66 }, 
    { "Age": 48, "AnnuityRate": 17.52 }, 
    { "Age": 49, "AnnuityRate": 17.38 }, 
    { "Age": 50, "AnnuityRate": 17.23 }, 
    { "Age": 51, "AnnuityRate": 17.08 }, 
    { "Age": 52, "AnnuityRate": 16.93 }, 
    { "Age": 53, "AnnuityRate": 16.77 }, 
    { "Age": 54, "AnnuityRate": 16.61 }, 
    { "Age": 55, "AnnuityRate": 16.45 }, 
    { "Age": 56, "AnnuityRate": 16.28 }, 
    { "Age": 57, "AnnuityRate": 16.11 }, 
    { "Age": 58, "AnnuityRate": 15.93 }, 
    { "Age": 59, "AnnuityRate": 15.74 }, 
    { "Age": 60, "AnnuityRate": 15.55 }, 
    { "Age": 61, "AnnuityRate": 15.35 }, 
    { "Age": 62, "AnnuityRate": 15.14 }, 
    { "Age": 63, "AnnuityRate": 14.93 }, 
    { "Age": 64, "AnnuityRate": 14.71 }, 
    { "Age": 65, "AnnuityRate": 14.49 }, 
    { "Age": 66, "AnnuityRate": 14.27 }, 
    { "Age": 67, "AnnuityRate": 14.05 }, 
    { "Age": 68, "AnnuityRate": 13.82 }, 
    { "Age": 69, "AnnuityRate": 13.60 }, 
    { "Age": 70, "AnnuityRate": 13.37 }, 
    { "Age": 71, "AnnuityRate": 13.13 }, 
    { "Age": 72, "AnnuityRate": 12.90 }, 
    { "Age": 73, "AnnuityRate": 12.66 }, 
    { "Age": 74, "AnnuityRate": 12.43 }, 
    { "Age": 75, "AnnuityRate": 12.20 }, 
    { "Age": 76, "AnnuityRate": 11.97 }, 
    { "Age": 77, "AnnuityRate": 11.76 }, 
    { "Age": 78, "AnnuityRate": 11.55 }, 
    { "Age": 79, "AnnuityRate": 11.36 }, 
    { "Age": 80, "AnnuityRate": 11.18 }, 
    { "Age": 81, "AnnuityRate": 11.01 }, 
    { "Age": 82, "AnnuityRate": 10.85 }, 
    { "Age": 83, "AnnuityRate": 10.71 }, 
    { "Age": 84, "AnnuityRate": 10.58 }, 
    { "Age": 85, "AnnuityRate": 10.47 }, 
    { "Age": 86, "AnnuityRate": 10.36 }, 
    { "Age": 87, "AnnuityRate": 10.28 }, 
    { "Age": 88, "AnnuityRate": 10.20 }, 
    { "Age": 89, "AnnuityRate": 10.13 }, 
    { "Age": 90, "AnnuityRate": 10.08 }, 
    { "Age": 91, "AnnuityRate": 10.03 }, 
    { "Age": 92, "AnnuityRate": 10.00 }, 
    { "Age": 93, "AnnuityRate": 9.96 }, 
    { "Age": 94, "AnnuityRate": 9.94 }, 
    { "Age": 95, "AnnuityRate": 9.92 }, 
    { "Age": 96, "AnnuityRate": 9.90 }, 
    { "Age": 97, "AnnuityRate": 9.88 }, 
    { "Age": 98, "AnnuityRate": 9.87 }, 
    { "Age": 99, "AnnuityRate": 9.86 }, 
    { "Age": 100, "AnnuityRate": 9.86 }, 
]

inflationLinkedAnnuityRates.female = [
    { "Age": 40, "AnnuityRate": 19.72 }, 
    { "Age": 41, "AnnuityRate": 19.63 }, 
    { "Age": 42, "AnnuityRate": 19.53 }, 
    { "Age": 43, "AnnuityRate": 19.43 }, 
    { "Age": 44, "AnnuityRate": 19.33 }, 
    { "Age": 45, "AnnuityRate": 19.22 }, 
    { "Age": 46, "AnnuityRate": 19.11 }, 
    { "Age": 47, "AnnuityRate": 18.99 }, 
    { "Age": 48, "AnnuityRate": 18.86 }, 
    { "Age": 49, "AnnuityRate": 18.73 }, 
    { "Age": 50, "AnnuityRate": 18.60 }, 
    { "Age": 51, "AnnuityRate": 18.45 }, 
    { "Age": 52, "AnnuityRate": 18.31 }, 
    { "Age": 53, "AnnuityRate": 18.15 }, 
    { "Age": 54, "AnnuityRate": 17.99 }, 
    { "Age": 55, "AnnuityRate": 17.82 }, 
    { "Age": 56, "AnnuityRate": 17.65 }, 
    { "Age": 57, "AnnuityRate": 17.46 }, 
    { "Age": 58, "AnnuityRate": 17.27 }, 
    { "Age": 59, "AnnuityRate": 17.08 }, 
    { "Age": 60, "AnnuityRate": 16.87 }, 
    { "Age": 61, "AnnuityRate": 16.66 }, 
    { "Age": 62, "AnnuityRate": 16.45 }, 
    { "Age": 63, "AnnuityRate": 16.23 }, 
    { "Age": 64, "AnnuityRate": 16.00 }, 
    { "Age": 65, "AnnuityRate": 15.77 }, 
    { "Age": 66, "AnnuityRate": 15.53 }, 
    { "Age": 67, "AnnuityRate": 15.29 }, 
    { "Age": 68, "AnnuityRate": 15.05 }, 
    { "Age": 69, "AnnuityRate": 14.80 }, 
    { "Age": 70, "AnnuityRate": 14.54 }, 
    { "Age": 71, "AnnuityRate": 14.28 }, 
    { "Age": 72, "AnnuityRate": 14.02 }, 
    { "Age": 73, "AnnuityRate": 13.75 }, 
    { "Age": 74, "AnnuityRate": 13.49 }, 
    { "Age": 75, "AnnuityRate": 13.22 }, 
    { "Age": 76, "AnnuityRate": 12.95 }, 
    { "Age": 77, "AnnuityRate": 12.69 }, 
    { "Age": 78, "AnnuityRate": 12.43 }, 
    { "Age": 79, "AnnuityRate": 12.18 }, 
    { "Age": 80, "AnnuityRate": 11.94 }, 
    { "Age": 81, "AnnuityRate": 11.71 }, 
    { "Age": 82, "AnnuityRate": 11.49 }, 
    { "Age": 83, "AnnuityRate": 11.28 }, 
    { "Age": 84, "AnnuityRate": 11.09 }, 
    { "Age": 85, "AnnuityRate": 10.91 }, 
    { "Age": 86, "AnnuityRate": 10.75 }, 
    { "Age": 87, "AnnuityRate": 10.60 }, 
    { "Age": 88, "AnnuityRate": 10.47 }, 
    { "Age": 89, "AnnuityRate": 10.36 }, 
    { "Age": 90, "AnnuityRate": 10.27 }, 
    { "Age": 91, "AnnuityRate": 10.18 }, 
    { "Age": 92, "AnnuityRate": 10.11 }, 
    { "Age": 93, "AnnuityRate": 10.06 }, 
    { "Age": 94, "AnnuityRate": 10.01 }, 
    { "Age": 95, "AnnuityRate": 9.97 }, 
    { "Age": 96, "AnnuityRate": 9.94 }, 
    { "Age": 97, "AnnuityRate": 9.91 }, 
    { "Age": 98, "AnnuityRate": 9.89 }, 
    { "Age": 99, "AnnuityRate": 9.88 }, 
    { "Age": 100, "AnnuityRate": 9.87 }, 
]

const inflationLinkedJointAnnuityRates = [
    {"Age": 40, "40":  20.90 , "41":  20.86 , "42":  20.83 , "43":  20.80 , "44":  20.76 , "45":  20.73 , "46":  20.70 , "47":  20.67 , "48":  20.63 , "49":  20.60 , "50":  20.57 , "51":  20.54 , "52":  20.51 , "53":  20.48 , "54":  20.45 , "55":  20.42 , "56":  20.39 , "57":  20.36 , "58":  20.33 , "59":  20.30 , "60":  20.27 , "61":  20.25 , "62":  20.22 , "63":  20.19 , "64":  20.16 , "65":  20.14 , "66":  20.11 , "67":  20.08 , "68":  20.06 , "69":  20.04 , "70":  20.01 , "71":  19.99 , "72":  19.97 , "73":  19.94 , "74":  19.92 , "75":  19.90 , "76":  19.88 , "77":  19.87 , "78":  19.85 , "79":  19.83 , "80":  19.82 , "81":  19.81 , "82":  19.79 , "83":  19.78 , "84":  19.77 , "85":  19.77 , "86":  19.76 , "87":  19.75 , "88":  19.75 , "89":  19.74 , "90":  19.74 , "91":  19.74 , "92":  19.73 , "93":  19.73 , "94":  19.73 , "95":  19.73 , "96":  19.73 , "97":  19.73 , "98":  19.72 , "99":  19.72 , "100":  19.72 , }, 
    {"Age": 41, "40":  20.85 , "41":  20.81 , "42":  20.78 , "43":  20.74 , "44":  20.71 , "45":  20.67 , "46":  20.64 , "47":  20.60 , "48":  20.57 , "49":  20.54 , "50":  20.50 , "51":  20.47 , "52":  20.44 , "53":  20.41 , "54":  20.38 , "55":  20.35 , "56":  20.32 , "57":  20.29 , "58":  20.26 , "59":  20.23 , "60":  20.20 , "61":  20.17 , "62":  20.14 , "63":  20.11 , "64":  20.08 , "65":  20.05 , "66":  20.03 , "67":  20.00 , "68":  19.98 , "69":  19.95 , "70":  19.93 , "71":  19.90 , "72":  19.88 , "73":  19.86 , "74":  19.84 , "75":  19.81 , "76":  19.80 , "77":  19.78 , "78":  19.76 , "79":  19.74 , "80":  19.73 , "81":  19.72 , "82":  19.70 , "83":  19.69 , "84":  19.68 , "85":  19.67 , "86":  19.67 , "87":  19.66 , "88":  19.66 , "89":  19.65 , "90":  19.65 , "91":  19.64 , "92":  19.64 , "93":  19.64 , "94":  19.64 , "95":  19.64 , "96":  19.63 , "97":  19.63 , "98":  19.63 , "99":  19.63 , "100":  19.63 , }, 
    {"Age": 42, "40":  20.80 , "41":  20.76 , "42":  20.72 , "43":  20.69 , "44":  20.65 , "45":  20.61 , "46":  20.58 , "47":  20.54 , "48":  20.51 , "49":  20.47 , "50":  20.44 , "51":  20.40 , "52":  20.37 , "53":  20.34 , "54":  20.31 , "55":  20.27 , "56":  20.24 , "57":  20.21 , "58":  20.18 , "59":  20.15 , "60":  20.12 , "61":  20.09 , "62":  20.06 , "63":  20.03 , "64":  20.00 , "65":  19.97 , "66":  19.94 , "67":  19.92 , "68":  19.89 , "69":  19.86 , "70":  19.84 , "71":  19.81 , "72":  19.79 , "73":  19.77 , "74":  19.74 , "75":  19.72 , "76":  19.70 , "77":  19.68 , "78":  19.67 , "79":  19.65 , "80":  19.63 , "81":  19.62 , "82":  19.61 , "83":  19.60 , "84":  19.59 , "85":  19.58 , "86":  19.57 , "87":  19.56 , "88":  19.56 , "89":  19.55 , "90":  19.55 , "91":  19.55 , "92":  19.54 , "93":  19.54 , "94":  19.54 , "95":  19.54 , "96":  19.54 , "97":  19.54 , "98":  19.54 , "99":  19.54 , "100":  19.54 , }, 
    {"Age": 43, "40":  20.75 , "41":  20.71 , "42":  20.67 , "43":  20.63 , "44":  20.59 , "45":  20.55 , "46":  20.52 , "47":  20.48 , "48":  20.44 , "49":  20.41 , "50":  20.37 , "51":  20.34 , "52":  20.30 , "53":  20.27 , "54":  20.23 , "55":  20.20 , "56":  20.16 , "57":  20.13 , "58":  20.10 , "59":  20.07 , "60":  20.04 , "61":  20.00 , "62":  19.97 , "63":  19.94 , "64":  19.91 , "65":  19.88 , "66":  19.85 , "67":  19.83 , "68":  19.80 , "69":  19.77 , "70":  19.75 , "71":  19.72 , "72":  19.70 , "73":  19.67 , "74":  19.65 , "75":  19.63 , "76":  19.61 , "77":  19.59 , "78":  19.57 , "79":  19.55 , "80":  19.54 , "81":  19.52 , "82":  19.51 , "83":  19.50 , "84":  19.49 , "85":  19.48 , "86":  19.47 , "87":  19.47 , "88":  19.46 , "89":  19.45 , "90":  19.45 , "91":  19.45 , "92":  19.44 , "93":  19.44 , "94":  19.44 , "95":  19.44 , "96":  19.44 , "97":  19.44 , "98":  19.44 , "99":  19.44 , "100":  19.44 , }, 
    {"Age": 44, "40":  20.69 , "41":  20.65 , "42":  20.61 , "43":  20.57 , "44":  20.53 , "45":  20.49 , "46":  20.45 , "47":  20.41 , "48":  20.38 , "49":  20.34 , "50":  20.30 , "51":  20.26 , "52":  20.23 , "53":  20.19 , "54":  20.16 , "55":  20.12 , "56":  20.09 , "57":  20.05 , "58":  20.02 , "59":  19.98 , "60":  19.95 , "61":  19.92 , "62":  19.89 , "63":  19.85 , "64":  19.82 , "65":  19.79 , "66":  19.76 , "67":  19.73 , "68":  19.70 , "69":  19.68 , "70":  19.65 , "71":  19.62 , "72":  19.60 , "73":  19.57 , "74":  19.55 , "75":  19.53 , "76":  19.51 , "77":  19.49 , "78":  19.47 , "79":  19.45 , "80":  19.43 , "81":  19.42 , "82":  19.41 , "83":  19.40 , "84":  19.39 , "85":  19.38 , "86":  19.37 , "87":  19.36 , "88":  19.36 , "89":  19.35 , "90":  19.35 , "91":  19.34 , "92":  19.34 , "93":  19.34 , "94":  19.34 , "95":  19.33 , "96":  19.33 , "97":  19.33 , "98":  19.33 , "99":  19.33 , "100":  19.33 , }, 
    {"Age": 45, "40":  20.64 , "41":  20.60 , "42":  20.56 , "43":  20.51 , "44":  20.47 , "45":  20.43 , "46":  20.39 , "47":  20.35 , "48":  20.31 , "49":  20.27 , "50":  20.23 , "51":  20.19 , "52":  20.15 , "53":  20.11 , "54":  20.08 , "55":  20.04 , "56":  20.00 , "57":  19.97 , "58":  19.93 , "59":  19.90 , "60":  19.86 , "61":  19.83 , "62":  19.79 , "63":  19.76 , "64":  19.73 , "65":  19.70 , "66":  19.67 , "67":  19.64 , "68":  19.61 , "69":  19.58 , "70":  19.55 , "71":  19.52 , "72":  19.50 , "73":  19.47 , "74":  19.45 , "75":  19.42 , "76":  19.40 , "77":  19.38 , "78":  19.36 , "79":  19.34 , "80":  19.33 , "81":  19.31 , "82":  19.30 , "83":  19.29 , "84":  19.28 , "85":  19.27 , "86":  19.26 , "87":  19.25 , "88":  19.25 , "89":  19.24 , "90":  19.24 , "91":  19.23 , "92":  19.23 , "93":  19.23 , "94":  19.23 , "95":  19.23 , "96":  19.22 , "97":  19.22 , "98":  19.22 , "99":  19.22 , "100":  19.22 , }, 
    {"Age": 46, "40":  20.59 , "41":  20.54 , "42":  20.50 , "43":  20.46 , "44":  20.41 , "45":  20.37 , "46":  20.32 , "47":  20.28 , "48":  20.24 , "49":  20.20 , "50":  20.16 , "51":  20.12 , "52":  20.08 , "53":  20.04 , "54":  20.00 , "55":  19.96 , "56":  19.92 , "57":  19.88 , "58":  19.85 , "59":  19.81 , "60":  19.77 , "61":  19.74 , "62":  19.70 , "63":  19.67 , "64":  19.63 , "65":  19.60 , "66":  19.57 , "67":  19.54 , "68":  19.51 , "69":  19.48 , "70":  19.45 , "71":  19.42 , "72":  19.39 , "73":  19.37 , "74":  19.34 , "75":  19.32 , "76":  19.29 , "77":  19.27 , "78":  19.25 , "79":  19.23 , "80":  19.22 , "81":  19.20 , "82":  19.19 , "83":  19.18 , "84":  19.16 , "85":  19.15 , "86":  19.15 , "87":  19.14 , "88":  19.13 , "89":  19.13 , "90":  19.12 , "91":  19.12 , "92":  19.12 , "93":  19.11 , "94":  19.11 , "95":  19.11 , "96":  19.11 , "97":  19.11 , "98":  19.11 , "99":  19.11 , "100":  19.11 , }, 
    {"Age": 47, "40":  20.53 , "41":  20.49 , "42":  20.44 , "43":  20.40 , "44":  20.35 , "45":  20.30 , "46":  20.26 , "47":  20.21 , "48":  20.17 , "49":  20.13 , "50":  20.08 , "51":  20.04 , "52":  20.00 , "53":  19.96 , "54":  19.91 , "55":  19.87 , "56":  19.83 , "57":  19.79 , "58":  19.76 , "59":  19.72 , "60":  19.68 , "61":  19.64 , "62":  19.60 , "63":  19.57 , "64":  19.53 , "65":  19.50 , "66":  19.46 , "67":  19.43 , "68":  19.40 , "69":  19.37 , "70":  19.34 , "71":  19.31 , "72":  19.28 , "73":  19.26 , "74":  19.23 , "75":  19.20 , "76":  19.18 , "77":  19.16 , "78":  19.14 , "79":  19.12 , "80":  19.10 , "81":  19.09 , "82":  19.07 , "83":  19.06 , "84":  19.05 , "85":  19.04 , "86":  19.03 , "87":  19.02 , "88":  19.01 , "89":  19.01 , "90":  19.01 , "91":  19.00 , "92":  19.00 , "93":  19.00 , "94":  18.99 , "95":  18.99 , "96":  18.99 , "97":  18.99 , "98":  18.99 , "99":  18.99 , "100":  18.99 , }, 
    {"Age": 48, "40":  20.48 , "41":  20.43 , "42":  20.38 , "43":  20.33 , "44":  20.29 , "45":  20.24 , "46":  20.19 , "47":  20.14 , "48":  20.10 , "49":  20.05 , "50":  20.01 , "51":  19.96 , "52":  19.92 , "53":  19.87 , "54":  19.83 , "55":  19.79 , "56":  19.75 , "57":  19.70 , "58":  19.66 , "59":  19.62 , "60":  19.58 , "61":  19.54 , "62":  19.51 , "63":  19.47 , "64":  19.43 , "65":  19.39 , "66":  19.36 , "67":  19.32 , "68":  19.29 , "69":  19.26 , "70":  19.23 , "71":  19.20 , "72":  19.17 , "73":  19.14 , "74":  19.11 , "75":  19.09 , "76":  19.06 , "77":  19.04 , "78":  19.02 , "79":  19.00 , "80":  18.98 , "81":  18.96 , "82":  18.95 , "83":  18.94 , "84":  18.92 , "85":  18.91 , "86":  18.91 , "87":  18.90 , "88":  18.89 , "89":  18.89 , "90":  18.88 , "91":  18.88 , "92":  18.87 , "93":  18.87 , "94":  18.87 , "95":  18.87 , "96":  18.87 , "97":  18.87 , "98":  18.86 , "99":  18.86 , "100":  18.86 , }, 
    {"Age": 49, "40":  20.42 , "41":  20.37 , "42":  20.32 , "43":  20.27 , "44":  20.22 , "45":  20.17 , "46":  20.12 , "47":  20.07 , "48":  20.03 , "49":  19.98 , "50":  19.93 , "51":  19.88 , "52":  19.84 , "53":  19.79 , "54":  19.74 , "55":  19.70 , "56":  19.66 , "57":  19.61 , "58":  19.57 , "59":  19.53 , "60":  19.48 , "61":  19.44 , "62":  19.40 , "63":  19.36 , "64":  19.32 , "65":  19.29 , "66":  19.25 , "67":  19.21 , "68":  19.18 , "69":  19.15 , "70":  19.11 , "71":  19.08 , "72":  19.05 , "73":  19.02 , "74":  18.99 , "75":  18.97 , "76":  18.94 , "77":  18.92 , "78":  18.89 , "79":  18.87 , "80":  18.85 , "81":  18.84 , "82":  18.82 , "83":  18.81 , "84":  18.80 , "85":  18.79 , "86":  18.78 , "87":  18.77 , "88":  18.76 , "89":  18.76 , "90":  18.75 , "91":  18.75 , "92":  18.74 , "93":  18.74 , "94":  18.74 , "95":  18.74 , "96":  18.74 , "97":  18.74 , "98":  18.73 , "99":  18.73 , "100":  18.73 , }, 
    {"Age": 50, "40":  20.37 , "41":  20.31 , "42":  20.26 , "43":  20.21 , "44":  20.16 , "45":  20.11 , "46":  20.05 , "47":  20.00 , "48":  19.95 , "49":  19.90 , "50":  19.85 , "51":  19.80 , "52":  19.75 , "53":  19.70 , "54":  19.66 , "55":  19.61 , "56":  19.56 , "57":  19.52 , "58":  19.47 , "59":  19.43 , "60":  19.38 , "61":  19.34 , "62":  19.30 , "63":  19.25 , "64":  19.21 , "65":  19.17 , "66":  19.14 , "67":  19.10 , "68":  19.06 , "69":  19.03 , "70":  18.99 , "71":  18.96 , "72":  18.93 , "73":  18.90 , "74":  18.87 , "75":  18.84 , "76":  18.81 , "77":  18.79 , "78":  18.76 , "79":  18.74 , "80":  18.72 , "81":  18.71 , "82":  18.69 , "83":  18.68 , "84":  18.66 , "85":  18.65 , "86":  18.64 , "87":  18.63 , "88":  18.63 , "89":  18.62 , "90":  18.62 , "91":  18.61 , "92":  18.61 , "93":  18.61 , "94":  18.60 , "95":  18.60 , "96":  18.60 , "97":  18.60 , "98":  18.60 , "99":  18.60 , "100":  18.60 , }, 
    {"Age": 51, "40":  20.31 , "41":  20.26 , "42":  20.20 , "43":  20.15 , "44":  20.09 , "45":  20.04 , "46":  19.99 , "47":  19.93 , "48":  19.88 , "49":  19.82 , "50":  19.77 , "51":  19.72 , "52":  19.67 , "53":  19.62 , "54":  19.57 , "55":  19.52 , "56":  19.47 , "57":  19.42 , "58":  19.37 , "59":  19.33 , "60":  19.28 , "61":  19.23 , "62":  19.19 , "63":  19.14 , "64":  19.10 , "65":  19.06 , "66":  19.02 , "67":  18.98 , "68":  18.94 , "69":  18.91 , "70":  18.87 , "71":  18.83 , "72":  18.80 , "73":  18.77 , "74":  18.74 , "75":  18.71 , "76":  18.68 , "77":  18.65 , "78":  18.63 , "79":  18.61 , "80":  18.59 , "81":  18.57 , "82":  18.55 , "83":  18.54 , "84":  18.52 , "85":  18.51 , "86":  18.50 , "87":  18.49 , "88":  18.49 , "89":  18.48 , "90":  18.48 , "91":  18.47 , "92":  18.47 , "93":  18.47 , "94":  18.46 , "95":  18.46 , "96":  18.46 , "97":  18.46 , "98":  18.46 , "99":  18.46 , "100":  18.46 , }, 
    {"Age": 52, "40":  20.25 , "41":  20.20 , "42":  20.14 , "43":  20.09 , "44":  20.03 , "45":  19.97 , "46":  19.92 , "47":  19.86 , "48":  19.80 , "49":  19.75 , "50":  19.69 , "51":  19.64 , "52":  19.58 , "53":  19.53 , "54":  19.48 , "55":  19.42 , "56":  19.37 , "57":  19.32 , "58":  19.27 , "59":  19.22 , "60":  19.17 , "61":  19.12 , "62":  19.08 , "63":  19.03 , "64":  18.98 , "65":  18.94 , "66":  18.90 , "67":  18.86 , "68":  18.82 , "69":  18.78 , "70":  18.74 , "71":  18.70 , "72":  18.67 , "73":  18.63 , "74":  18.60 , "75":  18.57 , "76":  18.54 , "77":  18.51 , "78":  18.49 , "79":  18.47 , "80":  18.44 , "81":  18.43 , "82":  18.41 , "83":  18.39 , "84":  18.38 , "85":  18.37 , "86":  18.36 , "87":  18.35 , "88":  18.34 , "89":  18.33 , "90":  18.33 , "91":  18.32 , "92":  18.32 , "93":  18.32 , "94":  18.31 , "95":  18.31 , "96":  18.31 , "97":  18.31 , "98":  18.31 , "99":  18.31 , "100":  18.31 , }, 
    {"Age": 53, "40":  20.20 , "41":  20.14 , "42":  20.08 , "43":  20.02 , "44":  19.96 , "45":  19.90 , "46":  19.84 , "47":  19.79 , "48":  19.73 , "49":  19.67 , "50":  19.61 , "51":  19.55 , "52":  19.50 , "53":  19.44 , "54":  19.38 , "55":  19.33 , "56":  19.28 , "57":  19.22 , "58":  19.17 , "59":  19.12 , "60":  19.06 , "61":  19.01 , "62":  18.96 , "63":  18.91 , "64":  18.87 , "65":  18.82 , "66":  18.77 , "67":  18.73 , "68":  18.69 , "69":  18.65 , "70":  18.61 , "71":  18.57 , "72":  18.53 , "73":  18.50 , "74":  18.46 , "75":  18.43 , "76":  18.40 , "77":  18.37 , "78":  18.34 , "79":  18.32 , "80":  18.30 , "81":  18.28 , "82":  18.26 , "83":  18.24 , "84":  18.23 , "85":  18.22 , "86":  18.20 , "87":  18.20 , "88":  18.19 , "89":  18.18 , "90":  18.17 , "91":  18.17 , "92":  18.17 , "93":  18.16 , "94":  18.16 , "95":  18.16 , "96":  18.16 , "97":  18.16 , "98":  18.15 , "99":  18.15 , "100":  18.15 , }, 
    {"Age": 54, "40":  20.14 , "41":  20.08 , "42":  20.02 , "43":  19.96 , "44":  19.90 , "45":  19.84 , "46":  19.77 , "47":  19.71 , "48":  19.65 , "49":  19.59 , "50":  19.53 , "51":  19.47 , "52":  19.41 , "53":  19.35 , "54":  19.29 , "55":  19.23 , "56":  19.18 , "57":  19.12 , "58":  19.06 , "59":  19.01 , "60":  18.95 , "61":  18.90 , "62":  18.85 , "63":  18.79 , "64":  18.74 , "65":  18.69 , "66":  18.65 , "67":  18.60 , "68":  18.56 , "69":  18.51 , "70":  18.47 , "71":  18.43 , "72":  18.39 , "73":  18.35 , "74":  18.32 , "75":  18.28 , "76":  18.25 , "77":  18.22 , "78":  18.19 , "79":  18.17 , "80":  18.14 , "81":  18.12 , "82":  18.10 , "83":  18.09 , "84":  18.07 , "85":  18.06 , "86":  18.05 , "87":  18.04 , "88":  18.03 , "89":  18.02 , "90":  18.01 , "91":  18.01 , "92":  18.01 , "93":  18.00 , "94":  18.00 , "95":  18.00 , "96":  18.00 , "97":  17.99 , "98":  17.99 , "99":  17.99 , "100":  17.99 , }, 
    {"Age": 55, "40":  20.08 , "41":  20.02 , "42":  19.96 , "43":  19.89 , "44":  19.83 , "45":  19.77 , "46":  19.70 , "47":  19.64 , "48":  19.57 , "49":  19.51 , "50":  19.45 , "51":  19.38 , "52":  19.32 , "53":  19.26 , "54":  19.20 , "55":  19.14 , "56":  19.07 , "57":  19.01 , "58":  18.96 , "59":  18.90 , "60":  18.84 , "61":  18.78 , "62":  18.73 , "63":  18.67 , "64":  18.62 , "65":  18.56 , "66":  18.51 , "67":  18.47 , "68":  18.42 , "69":  18.37 , "70":  18.33 , "71":  18.29 , "72":  18.24 , "73":  18.20 , "74":  18.17 , "75":  18.13 , "76":  18.10 , "77":  18.06 , "78":  18.03 , "79":  18.01 , "80":  17.98 , "81":  17.96 , "82":  17.94 , "83":  17.92 , "84":  17.91 , "85":  17.89 , "86":  17.88 , "87":  17.87 , "88":  17.86 , "89":  17.85 , "90":  17.85 , "91":  17.84 , "92":  17.84 , "93":  17.84 , "94":  17.83 , "95":  17.83 , "96":  17.83 , "97":  17.83 , "98":  17.83 , "99":  17.82 , "100":  17.82 , }, 
    {"Age": 56, "40":  20.02 , "41":  19.96 , "42":  19.89 , "43":  19.83 , "44":  19.76 , "45":  19.70 , "46":  19.63 , "47":  19.56 , "48":  19.50 , "49":  19.43 , "50":  19.36 , "51":  19.30 , "52":  19.23 , "53":  19.17 , "54":  19.10 , "55":  19.04 , "56":  18.97 , "57":  18.91 , "58":  18.85 , "59":  18.79 , "60":  18.72 , "61":  18.66 , "62":  18.60 , "63":  18.55 , "64":  18.49 , "65":  18.43 , "66":  18.38 , "67":  18.33 , "68":  18.28 , "69":  18.23 , "70":  18.18 , "71":  18.14 , "72":  18.09 , "73":  18.05 , "74":  18.01 , "75":  17.97 , "76":  17.94 , "77":  17.90 , "78":  17.87 , "79":  17.84 , "80":  17.82 , "81":  17.79 , "82":  17.77 , "83":  17.75 , "84":  17.74 , "85":  17.72 , "86":  17.71 , "87":  17.70 , "88":  17.69 , "89":  17.68 , "90":  17.67 , "91":  17.67 , "92":  17.66 , "93":  17.66 , "94":  17.66 , "95":  17.66 , "96":  17.65 , "97":  17.65 , "98":  17.65 , "99":  17.65 , "100":  17.65 , }, 
    {"Age": 57, "40":  19.96 , "41":  19.90 , "42":  19.83 , "43":  19.76 , "44":  19.70 , "45":  19.63 , "46":  19.56 , "47":  19.49 , "48":  19.42 , "49":  19.35 , "50":  19.28 , "51":  19.21 , "52":  19.14 , "53":  19.07 , "54":  19.00 , "55":  18.94 , "56":  18.87 , "57":  18.80 , "58":  18.74 , "59":  18.67 , "60":  18.61 , "61":  18.54 , "62":  18.48 , "63":  18.42 , "64":  18.36 , "65":  18.30 , "66":  18.24 , "67":  18.19 , "68":  18.13 , "69":  18.08 , "70":  18.03 , "71":  17.98 , "72":  17.94 , "73":  17.89 , "74":  17.85 , "75":  17.81 , "76":  17.77 , "77":  17.73 , "78":  17.70 , "79":  17.67 , "80":  17.64 , "81":  17.62 , "82":  17.60 , "83":  17.58 , "84":  17.56 , "85":  17.54 , "86":  17.53 , "87":  17.52 , "88":  17.51 , "89":  17.50 , "90":  17.49 , "91":  17.49 , "92":  17.48 , "93":  17.48 , "94":  17.48 , "95":  17.47 , "96":  17.47 , "97":  17.47 , "98":  17.47 , "99":  17.47 , "100":  17.47 , }, 
    {"Age": 58, "40":  19.91 , "41":  19.84 , "42":  19.77 , "43":  19.70 , "44":  19.63 , "45":  19.56 , "46":  19.49 , "47":  19.41 , "48":  19.34 , "49":  19.27 , "50":  19.19 , "51":  19.12 , "52":  19.05 , "53":  18.98 , "54":  18.91 , "55":  18.84 , "56":  18.76 , "57":  18.69 , "58":  18.63 , "59":  18.56 , "60":  18.49 , "61":  18.42 , "62":  18.35 , "63":  18.29 , "64":  18.22 , "65":  18.16 , "66":  18.10 , "67":  18.04 , "68":  17.99 , "69":  17.93 , "70":  17.88 , "71":  17.83 , "72":  17.78 , "73":  17.73 , "74":  17.68 , "75":  17.64 , "76":  17.60 , "77":  17.56 , "78":  17.53 , "79":  17.49 , "80":  17.46 , "81":  17.44 , "82":  17.41 , "83":  17.39 , "84":  17.37 , "85":  17.36 , "86":  17.34 , "87":  17.33 , "88":  17.32 , "89":  17.31 , "90":  17.30 , "91":  17.30 , "92":  17.29 , "93":  17.29 , "94":  17.29 , "95":  17.28 , "96":  17.28 , "97":  17.28 , "98":  17.28 , "99":  17.28 , "100":  17.28 , }, 
    {"Age": 59, "40":  19.85 , "41":  19.78 , "42":  19.71 , "43":  19.64 , "44":  19.56 , "45":  19.49 , "46":  19.41 , "47":  19.34 , "48":  19.26 , "49":  19.19 , "50":  19.11 , "51":  19.04 , "52":  18.96 , "53":  18.88 , "54":  18.81 , "55":  18.73 , "56":  18.66 , "57":  18.59 , "58":  18.51 , "59":  18.44 , "60":  18.37 , "61":  18.30 , "62":  18.22 , "63":  18.15 , "64":  18.09 , "65":  18.02 , "66":  17.96 , "67":  17.89 , "68":  17.83 , "69":  17.78 , "70":  17.72 , "71":  17.67 , "72":  17.61 , "73":  17.56 , "74":  17.51 , "75":  17.47 , "76":  17.42 , "77":  17.38 , "78":  17.34 , "79":  17.31 , "80":  17.28 , "81":  17.25 , "82":  17.23 , "83":  17.20 , "84":  17.18 , "85":  17.17 , "86":  17.15 , "87":  17.14 , "88":  17.13 , "89":  17.12 , "90":  17.11 , "91":  17.10 , "92":  17.10 , "93":  17.09 , "94":  17.09 , "95":  17.09 , "96":  17.08 , "97":  17.08 , "98":  17.08 , "99":  17.08 , "100":  17.08 , }, 
    {"Age": 60, "40":  19.79 , "41":  19.72 , "42":  19.65 , "43":  19.57 , "44":  19.50 , "45":  19.42 , "46":  19.34 , "47":  19.26 , "48":  19.18 , "49":  19.11 , "50":  19.03 , "51":  18.95 , "52":  18.87 , "53":  18.79 , "54":  18.71 , "55":  18.63 , "56":  18.55 , "57":  18.48 , "58":  18.40 , "59":  18.32 , "60":  18.25 , "61":  18.17 , "62":  18.10 , "63":  18.02 , "64":  17.95 , "65":  17.88 , "66":  17.81 , "67":  17.74 , "68":  17.68 , "69":  17.62 , "70":  17.56 , "71":  17.50 , "72":  17.44 , "73":  17.39 , "74":  17.34 , "75":  17.29 , "76":  17.24 , "77":  17.20 , "78":  17.16 , "79":  17.12 , "80":  17.09 , "81":  17.06 , "82":  17.03 , "83":  17.01 , "84":  16.99 , "85":  16.97 , "86":  16.95 , "87":  16.94 , "88":  16.93 , "89":  16.92 , "90":  16.91 , "91":  16.90 , "92":  16.89 , "93":  16.89 , "94":  16.89 , "95":  16.88 , "96":  16.88 , "97":  16.88 , "98":  16.88 , "99":  16.88 , "100":  16.88 , }, 
    {"Age": 61, "40":  19.73 , "41":  19.66 , "42":  19.58 , "43":  19.51 , "44":  19.43 , "45":  19.35 , "46":  19.27 , "47":  19.19 , "48":  19.11 , "49":  19.02 , "50":  18.94 , "51":  18.86 , "52":  18.78 , "53":  18.70 , "54":  18.61 , "55":  18.53 , "56":  18.45 , "57":  18.37 , "58":  18.29 , "59":  18.21 , "60":  18.13 , "61":  18.04 , "62":  17.97 , "63":  17.89 , "64":  17.81 , "65":  17.74 , "66":  17.66 , "67":  17.59 , "68":  17.53 , "69":  17.46 , "70":  17.40 , "71":  17.33 , "72":  17.27 , "73":  17.21 , "74":  17.16 , "75":  17.11 , "76":  17.06 , "77":  17.01 , "78":  16.97 , "79":  16.93 , "80":  16.89 , "81":  16.86 , "82":  16.83 , "83":  16.81 , "84":  16.78 , "85":  16.76 , "86":  16.75 , "87":  16.73 , "88":  16.72 , "89":  16.71 , "90":  16.70 , "91":  16.69 , "92":  16.69 , "93":  16.68 , "94":  16.68 , "95":  16.67 , "96":  16.67 , "97":  16.67 , "98":  16.67 , "99":  16.67 , "100":  16.67 , }, 
    {"Age": 62, "40":  19.68 , "41":  19.60 , "42":  19.52 , "43":  19.44 , "44":  19.36 , "45":  19.28 , "46":  19.20 , "47":  19.11 , "48":  19.03 , "49":  18.94 , "50":  18.86 , "51":  18.77 , "52":  18.69 , "53":  18.60 , "54":  18.52 , "55":  18.43 , "56":  18.35 , "57":  18.26 , "58":  18.17 , "59":  18.09 , "60":  18.00 , "61":  17.92 , "62":  17.83 , "63":  17.75 , "64":  17.67 , "65":  17.59 , "66":  17.51 , "67":  17.44 , "68":  17.37 , "69":  17.30 , "70":  17.23 , "71":  17.16 , "72":  17.10 , "73":  17.04 , "74":  16.98 , "75":  16.92 , "76":  16.87 , "77":  16.82 , "78":  16.77 , "79":  16.73 , "80":  16.69 , "81":  16.66 , "82":  16.63 , "83":  16.60 , "84":  16.58 , "85":  16.55 , "86":  16.54 , "87":  16.52 , "88":  16.51 , "89":  16.50 , "90":  16.49 , "91":  16.48 , "92":  16.47 , "93":  16.47 , "94":  16.46 , "95":  16.46 , "96":  16.46 , "97":  16.45 , "98":  16.45 , "99":  16.45 , "100":  16.45 , }, 
    {"Age": 63, "40":  19.62 , "41":  19.54 , "42":  19.46 , "43":  19.38 , "44":  19.30 , "45":  19.21 , "46":  19.13 , "47":  19.04 , "48":  18.95 , "49":  18.87 , "50":  18.78 , "51":  18.69 , "52":  18.60 , "53":  18.51 , "54":  18.42 , "55":  18.33 , "56":  18.24 , "57":  18.15 , "58":  18.06 , "59":  17.97 , "60":  17.88 , "61":  17.79 , "62":  17.70 , "63":  17.62 , "64":  17.53 , "65":  17.45 , "66":  17.36 , "67":  17.29 , "68":  17.21 , "69":  17.13 , "70":  17.06 , "71":  16.99 , "72":  16.92 , "73":  16.86 , "74":  16.79 , "75":  16.73 , "76":  16.68 , "77":  16.62 , "78":  16.57 , "79":  16.53 , "80":  16.49 , "81":  16.45 , "82":  16.42 , "83":  16.39 , "84":  16.36 , "85":  16.34 , "86":  16.32 , "87":  16.30 , "88":  16.29 , "89":  16.28 , "90":  16.27 , "91":  16.26 , "92":  16.25 , "93":  16.25 , "94":  16.24 , "95":  16.24 , "96":  16.24 , "97":  16.23 , "98":  16.23 , "99":  16.23 , "100":  16.23 , }, 
    {"Age": 64, "40":  19.56 , "41":  19.48 , "42":  19.40 , "43":  19.32 , "44":  19.23 , "45":  19.15 , "46":  19.06 , "47":  18.97 , "48":  18.88 , "49":  18.79 , "50":  18.70 , "51":  18.61 , "52":  18.51 , "53":  18.42 , "54":  18.33 , "55":  18.23 , "56":  18.14 , "57":  18.04 , "58":  17.95 , "59":  17.86 , "60":  17.76 , "61":  17.67 , "62":  17.57 , "63":  17.48 , "64":  17.39 , "65":  17.30 , "66":  17.21 , "67":  17.13 , "68":  17.05 , "69":  16.97 , "70":  16.89 , "71":  16.82 , "72":  16.74 , "73":  16.67 , "74":  16.61 , "75":  16.54 , "76":  16.48 , "77":  16.42 , "78":  16.37 , "79":  16.32 , "80":  16.28 , "81":  16.24 , "82":  16.21 , "83":  16.17 , "84":  16.15 , "85":  16.12 , "86":  16.10 , "87":  16.08 , "88":  16.07 , "89":  16.05 , "90":  16.04 , "91":  16.03 , "92":  16.03 , "93":  16.02 , "94":  16.02 , "95":  16.01 , "96":  16.01 , "97":  16.01 , "98":  16.00 , "99":  16.00 , "100":  16.00 , }, 
    {"Age": 65, "40":  19.51 , "41":  19.43 , "42":  19.34 , "43":  19.26 , "44":  19.17 , "45":  19.08 , "46":  18.99 , "47":  18.90 , "48":  18.80 , "49":  18.71 , "50":  18.62 , "51":  18.52 , "52":  18.43 , "53":  18.33 , "54":  18.23 , "55":  18.14 , "56":  18.04 , "57":  17.94 , "58":  17.84 , "59":  17.74 , "60":  17.64 , "61":  17.54 , "62":  17.45 , "63":  17.35 , "64":  17.25 , "65":  17.16 , "66":  17.06 , "67":  16.98 , "68":  16.89 , "69":  16.81 , "70":  16.72 , "71":  16.64 , "72":  16.56 , "73":  16.49 , "74":  16.42 , "75":  16.35 , "76":  16.28 , "77":  16.22 , "78":  16.17 , "79":  16.12 , "80":  16.07 , "81":  16.03 , "82":  15.99 , "83":  15.96 , "84":  15.93 , "85":  15.90 , "86":  15.88 , "87":  15.86 , "88":  15.84 , "89":  15.83 , "90":  15.82 , "91":  15.81 , "92":  15.80 , "93":  15.79 , "94":  15.79 , "95":  15.78 , "96":  15.78 , "97":  15.78 , "98":  15.77 , "99":  15.77 , "100":  15.77 , }, 
    {"Age": 66, "40":  19.45 , "41":  19.37 , "42":  19.29 , "43":  19.20 , "44":  19.11 , "45":  19.02 , "46":  18.92 , "47":  18.83 , "48":  18.73 , "49":  18.64 , "50":  18.54 , "51":  18.44 , "52":  18.34 , "53":  18.24 , "54":  18.14 , "55":  18.04 , "56":  17.94 , "57":  17.84 , "58":  17.73 , "59":  17.63 , "60":  17.53 , "61":  17.42 , "62":  17.32 , "63":  17.21 , "64":  17.11 , "65":  17.01 , "66":  16.92 , "67":  16.82 , "68":  16.73 , "69":  16.64 , "70":  16.55 , "71":  16.47 , "72":  16.38 , "73":  16.30 , "74":  16.23 , "75":  16.15 , "76":  16.08 , "77":  16.02 , "78":  15.96 , "79":  15.91 , "80":  15.86 , "81":  15.81 , "82":  15.77 , "83":  15.73 , "84":  15.70 , "85":  15.67 , "86":  15.65 , "87":  15.63 , "88":  15.61 , "89":  15.59 , "90":  15.58 , "91":  15.57 , "92":  15.56 , "93":  15.56 , "94":  15.55 , "95":  15.55 , "96":  15.54 , "97":  15.54 , "98":  15.54 , "99":  15.54 , "100":  15.53 , }, 
    {"Age": 67, "40":  19.40 , "41":  19.32 , "42":  19.23 , "43":  19.14 , "44":  19.05 , "45":  18.95 , "46":  18.86 , "47":  18.76 , "48":  18.66 , "49":  18.56 , "50":  18.46 , "51":  18.36 , "52":  18.26 , "53":  18.15 , "54":  18.05 , "55":  17.94 , "56":  17.84 , "57":  17.73 , "58":  17.63 , "59":  17.52 , "60":  17.41 , "61":  17.30 , "62":  17.19 , "63":  17.08 , "64":  16.97 , "65":  16.87 , "66":  16.77 , "67":  16.67 , "68":  16.57 , "69":  16.48 , "70":  16.38 , "71":  16.29 , "72":  16.20 , "73":  16.12 , "74":  16.03 , "75":  15.96 , "76":  15.88 , "77":  15.81 , "78":  15.75 , "79":  15.69 , "80":  15.64 , "81":  15.59 , "82":  15.55 , "83":  15.51 , "84":  15.47 , "85":  15.44 , "86":  15.42 , "87":  15.39 , "88":  15.37 , "89":  15.36 , "90":  15.35 , "91":  15.33 , "92":  15.33 , "93":  15.32 , "94":  15.31 , "95":  15.31 , "96":  15.30 , "97":  15.30 , "98":  15.30 , "99":  15.30 , "100":  15.29 , }, 
    {"Age": 68, "40":  19.35 , "41":  19.26 , "42":  19.17 , "43":  19.08 , "44":  18.99 , "45":  18.89 , "46":  18.79 , "47":  18.69 , "48":  18.59 , "49":  18.49 , "50":  18.38 , "51":  18.28 , "52":  18.18 , "53":  18.07 , "54":  17.96 , "55":  17.85 , "56":  17.74 , "57":  17.63 , "58":  17.52 , "59":  17.41 , "60":  17.29 , "61":  17.18 , "62":  17.07 , "63":  16.95 , "64":  16.84 , "65":  16.73 , "66":  16.62 , "67":  16.51 , "68":  16.41 , "69":  16.31 , "70":  16.21 , "71":  16.11 , "72":  16.02 , "73":  15.93 , "74":  15.84 , "75":  15.76 , "76":  15.68 , "77":  15.61 , "78":  15.54 , "79":  15.47 , "80":  15.42 , "81":  15.37 , "82":  15.32 , "83":  15.28 , "84":  15.24 , "85":  15.21 , "86":  15.18 , "87":  15.16 , "88":  15.14 , "89":  15.12 , "90":  15.10 , "91":  15.09 , "92":  15.08 , "93":  15.07 , "94":  15.07 , "95":  15.06 , "96":  15.06 , "97":  15.06 , "98":  15.05 , "99":  15.05 , "100":  15.05 , }, 
    {"Age": 69, "40":  19.30 , "41":  19.21 , "42":  19.12 , "43":  19.02 , "44":  18.93 , "45":  18.83 , "46":  18.73 , "47":  18.62 , "48":  18.52 , "49":  18.42 , "50":  18.31 , "51":  18.20 , "52":  18.09 , "53":  17.98 , "54":  17.87 , "55":  17.76 , "56":  17.65 , "57":  17.53 , "58":  17.42 , "59":  17.30 , "60":  17.18 , "61":  17.06 , "62":  16.94 , "63":  16.82 , "64":  16.70 , "65":  16.59 , "66":  16.47 , "67":  16.36 , "68":  16.25 , "69":  16.15 , "70":  16.04 , "71":  15.94 , "72":  15.84 , "73":  15.74 , "74":  15.65 , "75":  15.56 , "76":  15.47 , "77":  15.40 , "78":  15.32 , "79":  15.26 , "80":  15.20 , "81":  15.14 , "82":  15.09 , "83":  15.04 , "84":  15.00 , "85":  14.97 , "86":  14.94 , "87":  14.91 , "88":  14.89 , "89":  14.87 , "90":  14.86 , "91":  14.85 , "92":  14.84 , "93":  14.83 , "94":  14.82 , "95":  14.81 , "96":  14.81 , "97":  14.81 , "98":  14.80 , "99":  14.80 , "100":  14.80 , }, 
    {"Age": 70, "40":  19.25 , "41":  19.16 , "42":  19.06 , "43":  18.97 , "44":  18.87 , "45":  18.77 , "46":  18.66 , "47":  18.56 , "48":  18.45 , "49":  18.35 , "50":  18.24 , "51":  18.13 , "52":  18.02 , "53":  17.90 , "54":  17.79 , "55":  17.67 , "56":  17.55 , "57":  17.43 , "58":  17.31 , "59":  17.19 , "60":  17.07 , "61":  16.95 , "62":  16.82 , "63":  16.70 , "64":  16.57 , "65":  16.45 , "66":  16.33 , "67":  16.21 , "68":  16.10 , "69":  15.98 , "70":  15.87 , "71":  15.76 , "72":  15.66 , "73":  15.55 , "74":  15.45 , "75":  15.36 , "76":  15.27 , "77":  15.18 , "78":  15.11 , "79":  15.04 , "80":  14.97 , "81":  14.91 , "82":  14.86 , "83":  14.81 , "84":  14.77 , "85":  14.73 , "86":  14.70 , "87":  14.67 , "88":  14.65 , "89":  14.63 , "90":  14.61 , "91":  14.60 , "92":  14.58 , "93":  14.58 , "94":  14.57 , "95":  14.56 , "96":  14.56 , "97":  14.55 , "98":  14.55 , "99":  14.55 , "100":  14.55 , }, 
    {"Age": 71, "40":  19.20 , "41":  19.10 , "42":  19.01 , "43":  18.91 , "44":  18.81 , "45":  18.71 , "46":  18.60 , "47":  18.50 , "48":  18.39 , "49":  18.28 , "50":  18.17 , "51":  18.05 , "52":  17.94 , "53":  17.82 , "54":  17.70 , "55":  17.58 , "56":  17.46 , "57":  17.34 , "58":  17.21 , "59":  17.09 , "60":  16.96 , "61":  16.83 , "62":  16.70 , "63":  16.57 , "64":  16.44 , "65":  16.31 , "66":  16.19 , "67":  16.06 , "68":  15.94 , "69":  15.82 , "70":  15.70 , "71":  15.59 , "72":  15.47 , "73":  15.36 , "74":  15.26 , "75":  15.16 , "76":  15.06 , "77":  14.97 , "78":  14.89 , "79":  14.81 , "80":  14.74 , "81":  14.68 , "82":  14.62 , "83":  14.57 , "84":  14.52 , "85":  14.48 , "86":  14.45 , "87":  14.42 , "88":  14.39 , "89":  14.37 , "90":  14.36 , "91":  14.34 , "92":  14.33 , "93":  14.32 , "94":  14.31 , "95":  14.30 , "96":  14.30 , "97":  14.30 , "98":  14.29 , "99":  14.29 , "100":  14.29 , }, 
    {"Age": 72, "40":  19.15 , "41":  19.05 , "42":  18.96 , "43":  18.86 , "44":  18.75 , "45":  18.65 , "46":  18.54 , "47":  18.43 , "48":  18.32 , "49":  18.21 , "50":  18.09 , "51":  17.98 , "52":  17.86 , "53":  17.74 , "54":  17.62 , "55":  17.50 , "56":  17.37 , "57":  17.25 , "58":  17.12 , "59":  16.99 , "60":  16.85 , "61":  16.72 , "62":  16.58 , "63":  16.45 , "64":  16.31 , "65":  16.18 , "66":  16.04 , "67":  15.91 , "68":  15.79 , "69":  15.66 , "70":  15.54 , "71":  15.41 , "72":  15.29 , "73":  15.18 , "74":  15.06 , "75":  14.96 , "76":  14.85 , "77":  14.76 , "78":  14.67 , "79":  14.59 , "80":  14.51 , "81":  14.45 , "82":  14.38 , "83":  14.33 , "84":  14.28 , "85":  14.24 , "86":  14.20 , "87":  14.17 , "88":  14.14 , "89":  14.12 , "90":  14.10 , "91":  14.08 , "92":  14.07 , "93":  14.06 , "94":  14.05 , "95":  14.04 , "96":  14.04 , "97":  14.03 , "98":  14.03 , "99":  14.03 , "100":  14.02 , }, 
    {"Age": 73, "40":  19.10 , "41":  19.00 , "42":  18.91 , "43":  18.80 , "44":  18.70 , "45":  18.59 , "46":  18.48 , "47":  18.37 , "48":  18.26 , "49":  18.14 , "50":  18.03 , "51":  17.91 , "52":  17.79 , "53":  17.67 , "54":  17.54 , "55":  17.41 , "56":  17.28 , "57":  17.15 , "58":  17.02 , "59":  16.89 , "60":  16.75 , "61":  16.61 , "62":  16.47 , "63":  16.33 , "64":  16.18 , "65":  16.04 , "66":  15.90 , "67":  15.77 , "68":  15.63 , "69":  15.50 , "70":  15.37 , "71":  15.24 , "72":  15.12 , "73":  14.99 , "74":  14.87 , "75":  14.76 , "76":  14.65 , "77":  14.55 , "78":  14.45 , "79":  14.36 , "80":  14.28 , "81":  14.21 , "82":  14.15 , "83":  14.09 , "84":  14.03 , "85":  13.99 , "86":  13.95 , "87":  13.91 , "88":  13.88 , "89":  13.86 , "90":  13.84 , "91":  13.82 , "92":  13.81 , "93":  13.80 , "94":  13.79 , "95":  13.78 , "96":  13.77 , "97":  13.77 , "98":  13.76 , "99":  13.76 , "100":  13.76 , }, 
    {"Age": 74, "40":  19.05 , "41":  18.96 , "42":  18.86 , "43":  18.75 , "44":  18.65 , "45":  18.54 , "46":  18.43 , "47":  18.31 , "48":  18.20 , "49":  18.08 , "50":  17.96 , "51":  17.84 , "52":  17.72 , "53":  17.59 , "54":  17.46 , "55":  17.33 , "56":  17.20 , "57":  17.07 , "58":  16.93 , "59":  16.79 , "60":  16.65 , "61":  16.50 , "62":  16.36 , "63":  16.21 , "64":  16.06 , "65":  15.91 , "66":  15.77 , "67":  15.63 , "68":  15.49 , "69":  15.35 , "70":  15.21 , "71":  15.07 , "72":  14.94 , "73":  14.81 , "74":  14.68 , "75":  14.56 , "76":  14.44 , "77":  14.33 , "78":  14.23 , "79":  14.14 , "80":  14.06 , "81":  13.98 , "82":  13.91 , "83":  13.84 , "84":  13.79 , "85":  13.74 , "86":  13.69 , "87":  13.66 , "88":  13.63 , "89":  13.60 , "90":  13.58 , "91":  13.56 , "92":  13.54 , "93":  13.53 , "94":  13.52 , "95":  13.51 , "96":  13.51 , "97":  13.50 , "98":  13.50 , "99":  13.50 , "100":  13.49 , }, 
    {"Age": 75, "40":  19.01 , "41":  18.91 , "42":  18.81 , "43":  18.70 , "44":  18.60 , "45":  18.48 , "46":  18.37 , "47":  18.25 , "48":  18.14 , "49":  18.02 , "50":  17.89 , "51":  17.77 , "52":  17.65 , "53":  17.52 , "54":  17.39 , "55":  17.25 , "56":  17.12 , "57":  16.98 , "58":  16.84 , "59":  16.70 , "60":  16.55 , "61":  16.40 , "62":  16.25 , "63":  16.09 , "64":  15.94 , "65":  15.79 , "66":  15.64 , "67":  15.49 , "68":  15.34 , "69":  15.20 , "70":  15.05 , "71":  14.91 , "72":  14.77 , "73":  14.63 , "74":  14.49 , "75":  14.36 , "76":  14.24 , "77":  14.13 , "78":  14.02 , "79":  13.92 , "80":  13.83 , "81":  13.75 , "82":  13.67 , "83":  13.60 , "84":  13.54 , "85":  13.49 , "86":  13.44 , "87":  13.40 , "88":  13.37 , "89":  13.34 , "90":  13.32 , "91":  13.30 , "92":  13.28 , "93":  13.27 , "94":  13.26 , "95":  13.25 , "96":  13.24 , "97":  13.24 , "98":  13.23 , "99":  13.23 , "100":  13.23 , }, 
    {"Age": 76, "40":  18.96 , "41":  18.87 , "42":  18.76 , "43":  18.66 , "44":  18.55 , "45":  18.43 , "46":  18.32 , "47":  18.20 , "48":  18.08 , "49":  17.96 , "50":  17.83 , "51":  17.71 , "52":  17.58 , "53":  17.45 , "54":  17.31 , "55":  17.18 , "56":  17.04 , "57":  16.90 , "58":  16.75 , "59":  16.61 , "60":  16.45 , "61":  16.30 , "62":  16.14 , "63":  15.98 , "64":  15.83 , "65":  15.67 , "66":  15.51 , "67":  15.36 , "68":  15.20 , "69":  15.05 , "70":  14.90 , "71":  14.75 , "72":  14.60 , "73":  14.45 , "74":  14.31 , "75":  14.17 , "76":  14.04 , "77":  13.92 , "78":  13.81 , "79":  13.70 , "80":  13.61 , "81":  13.52 , "82":  13.44 , "83":  13.36 , "84":  13.30 , "85":  13.24 , "86":  13.19 , "87":  13.15 , "88":  13.11 , "89":  13.08 , "90":  13.06 , "91":  13.04 , "92":  13.02 , "93":  13.01 , "94":  12.99 , "95":  12.98 , "96":  12.98 , "97":  12.97 , "98":  12.97 , "99":  12.96 , "100":  12.96 , }, 
    {"Age": 77, "40":  18.92 , "41":  18.82 , "42":  18.72 , "43":  18.61 , "44":  18.50 , "45":  18.38 , "46":  18.27 , "47":  18.15 , "48":  18.02 , "49":  17.90 , "50":  17.77 , "51":  17.65 , "52":  17.51 , "53":  17.38 , "54":  17.24 , "55":  17.11 , "56":  16.96 , "57":  16.82 , "58":  16.67 , "59":  16.52 , "60":  16.36 , "61":  16.21 , "62":  16.04 , "63":  15.88 , "64":  15.72 , "65":  15.55 , "66":  15.39 , "67":  15.23 , "68":  15.07 , "69":  14.91 , "70":  14.75 , "71":  14.59 , "72":  14.43 , "73":  14.28 , "74":  14.13 , "75":  13.99 , "76":  13.85 , "77":  13.72 , "78":  13.60 , "79":  13.49 , "80":  13.39 , "81":  13.29 , "82":  13.21 , "83":  13.13 , "84":  13.06 , "85":  13.00 , "86":  12.95 , "87":  12.90 , "88":  12.86 , "89":  12.83 , "90":  12.80 , "91":  12.78 , "92":  12.76 , "93":  12.75 , "94":  12.73 , "95":  12.72 , "96":  12.72 , "97":  12.71 , "98":  12.70 , "99":  12.70 , "100":  12.70 , }, 
    {"Age": 78, "40":  18.88 , "41":  18.78 , "42":  18.68 , "43":  18.57 , "44":  18.45 , "45":  18.34 , "46":  18.22 , "47":  18.09 , "48":  17.97 , "49":  17.84 , "50":  17.72 , "51":  17.59 , "52":  17.45 , "53":  17.32 , "54":  17.18 , "55":  17.04 , "56":  16.89 , "57":  16.74 , "58":  16.59 , "59":  16.44 , "60":  16.28 , "61":  16.12 , "62":  15.95 , "63":  15.78 , "64":  15.61 , "65":  15.44 , "66":  15.27 , "67":  15.10 , "68":  14.94 , "69":  14.77 , "70":  14.61 , "71":  14.44 , "72":  14.28 , "73":  14.12 , "74":  13.96 , "75":  13.81 , "76":  13.67 , "77":  13.53 , "78":  13.40 , "79":  13.28 , "80":  13.17 , "81":  13.07 , "82":  12.98 , "83":  12.90 , "84":  12.83 , "85":  12.76 , "86":  12.71 , "87":  12.66 , "88":  12.62 , "89":  12.58 , "90":  12.55 , "91":  12.53 , "92":  12.51 , "93":  12.49 , "94":  12.48 , "95":  12.47 , "96":  12.46 , "97":  12.45 , "98":  12.45 , "99":  12.44 , "100":  12.44 , }, 
    {"Age": 79, "40":  18.85 , "41":  18.74 , "42":  18.64 , "43":  18.52 , "44":  18.41 , "45":  18.29 , "46":  18.17 , "47":  18.05 , "48":  17.92 , "49":  17.79 , "50":  17.66 , "51":  17.53 , "52":  17.40 , "53":  17.26 , "54":  17.12 , "55":  16.97 , "56":  16.82 , "57":  16.67 , "58":  16.52 , "59":  16.36 , "60":  16.20 , "61":  16.03 , "62":  15.86 , "63":  15.69 , "64":  15.51 , "65":  15.33 , "66":  15.16 , "67":  14.99 , "68":  14.82 , "69":  14.64 , "70":  14.47 , "71":  14.30 , "72":  14.13 , "73":  13.96 , "74":  13.80 , "75":  13.64 , "76":  13.49 , "77":  13.34 , "78":  13.21 , "79":  13.08 , "80":  12.97 , "81":  12.86 , "82":  12.77 , "83":  12.68 , "84":  12.60 , "85":  12.53 , "86":  12.47 , "87":  12.42 , "88":  12.38 , "89":  12.34 , "90":  12.31 , "91":  12.28 , "92":  12.26 , "93":  12.25 , "94":  12.23 , "95":  12.22 , "96":  12.21 , "97":  12.20 , "98":  12.20 , "99":  12.19 , "100":  12.19 , }, 
    {"Age": 80, "40":  18.81 , "41":  18.71 , "42":  18.60 , "43":  18.49 , "44":  18.37 , "45":  18.25 , "46":  18.13 , "47":  18.00 , "48":  17.87 , "49":  17.74 , "50":  17.61 , "51":  17.48 , "52":  17.34 , "53":  17.20 , "54":  17.06 , "55":  16.91 , "56":  16.76 , "57":  16.61 , "58":  16.45 , "59":  16.29 , "60":  16.12 , "61":  15.95 , "62":  15.77 , "63":  15.60 , "64":  15.42 , "65":  15.24 , "66":  15.06 , "67":  14.88 , "68":  14.70 , "69":  14.52 , "70":  14.34 , "71":  14.17 , "72":  13.99 , "73":  13.81 , "74":  13.64 , "75":  13.48 , "76":  13.32 , "77":  13.17 , "78":  13.03 , "79":  12.90 , "80":  12.77 , "81":  12.66 , "82":  12.56 , "83":  12.47 , "84":  12.39 , "85":  12.31 , "86":  12.25 , "87":  12.20 , "88":  12.15 , "89":  12.11 , "90":  12.08 , "91":  12.05 , "92":  12.03 , "93":  12.01 , "94":  11.99 , "95":  11.98 , "96":  11.97 , "97":  11.96 , "98":  11.96 , "99":  11.95 , "100":  11.95 , }, 
    {"Age": 81, "40":  18.78 , "41":  18.67 , "42":  18.56 , "43":  18.45 , "44":  18.33 , "45":  18.21 , "46":  18.09 , "47":  17.96 , "48":  17.83 , "49":  17.70 , "50":  17.57 , "51":  17.43 , "52":  17.29 , "53":  17.15 , "54":  17.00 , "55":  16.85 , "56":  16.70 , "57":  16.54 , "58":  16.38 , "59":  16.22 , "60":  16.05 , "61":  15.87 , "62":  15.70 , "63":  15.51 , "64":  15.33 , "65":  15.14 , "66":  14.96 , "67":  14.78 , "68":  14.59 , "69":  14.41 , "70":  14.22 , "71":  14.04 , "72":  13.86 , "73":  13.67 , "74":  13.50 , "75":  13.32 , "76":  13.16 , "77":  13.00 , "78":  12.85 , "79":  12.72 , "80":  12.59 , "81":  12.47 , "82":  12.36 , "83":  12.27 , "84":  12.18 , "85":  12.10 , "86":  12.04 , "87":  11.98 , "88":  11.93 , "89":  11.89 , "90":  11.85 , "91":  11.82 , "92":  11.80 , "93":  11.78 , "94":  11.77 , "95":  11.75 , "96":  11.74 , "97":  11.73 , "98":  11.73 , "99":  11.72 , "100":  11.72 , }, 
    {"Age": 82, "40":  18.75 , "41":  18.64 , "42":  18.53 , "43":  18.42 , "44":  18.30 , "45":  18.17 , "46":  18.05 , "47":  17.92 , "48":  17.79 , "49":  17.66 , "50":  17.52 , "51":  17.38 , "52":  17.24 , "53":  17.10 , "54":  16.95 , "55":  16.80 , "56":  16.65 , "57":  16.49 , "58":  16.32 , "59":  16.16 , "60":  15.98 , "61":  15.81 , "62":  15.62 , "63":  15.44 , "64":  15.25 , "65":  15.06 , "66":  14.87 , "67":  14.68 , "68":  14.49 , "69":  14.30 , "70":  14.11 , "71":  13.92 , "72":  13.73 , "73":  13.54 , "74":  13.36 , "75":  13.18 , "76":  13.01 , "77":  12.84 , "78":  12.69 , "79":  12.55 , "80":  12.41 , "81":  12.29 , "82":  12.18 , "83":  12.08 , "84":  11.99 , "85":  11.91 , "86":  11.84 , "87":  11.77 , "88":  11.72 , "89":  11.68 , "90":  11.64 , "91":  11.61 , "92":  11.59 , "93":  11.57 , "94":  11.55 , "95":  11.53 , "96":  11.52 , "97":  11.51 , "98":  11.51 , "99":  11.50 , "100":  11.50 , }, 
    {"Age": 83, "40":  18.72 , "41":  18.61 , "42":  18.50 , "43":  18.38 , "44":  18.27 , "45":  18.14 , "46":  18.01 , "47":  17.88 , "48":  17.75 , "49":  17.62 , "50":  17.48 , "51":  17.34 , "52":  17.20 , "53":  17.05 , "54":  16.90 , "55":  16.75 , "56":  16.59 , "57":  16.43 , "58":  16.27 , "59":  16.10 , "60":  15.92 , "61":  15.74 , "62":  15.56 , "63":  15.37 , "64":  15.17 , "65":  14.98 , "66":  14.79 , "67":  14.59 , "68":  14.40 , "69":  14.21 , "70":  14.01 , "71":  13.82 , "72":  13.62 , "73":  13.42 , "74":  13.23 , "75":  13.05 , "76":  12.87 , "77":  12.70 , "78":  12.54 , "79":  12.39 , "80":  12.25 , "81":  12.12 , "82":  12.01 , "83":  11.90 , "84":  11.80 , "85":  11.72 , "86":  11.65 , "87":  11.58 , "88":  11.53 , "89":  11.48 , "90":  11.44 , "91":  11.41 , "92":  11.38 , "93":  11.36 , "94":  11.34 , "95":  11.33 , "96":  11.32 , "97":  11.31 , "98":  11.30 , "99":  11.29 , "100":  11.29 , }, 
    {"Age": 84, "40":  18.69 , "41":  18.59 , "42":  18.47 , "43":  18.36 , "44":  18.24 , "45":  18.11 , "46":  17.98 , "47":  17.85 , "48":  17.72 , "49":  17.58 , "50":  17.45 , "51":  17.30 , "52":  17.16 , "53":  17.01 , "54":  16.86 , "55":  16.71 , "56":  16.55 , "57":  16.39 , "58":  16.22 , "59":  16.05 , "60":  15.87 , "61":  15.68 , "62":  15.50 , "63":  15.30 , "64":  15.11 , "65":  14.91 , "66":  14.71 , "67":  14.51 , "68":  14.32 , "69":  14.12 , "70":  13.92 , "71":  13.72 , "72":  13.51 , "73":  13.32 , "74":  13.12 , "75":  12.93 , "76":  12.74 , "77":  12.57 , "78":  12.40 , "79":  12.25 , "80":  12.10 , "81":  11.97 , "82":  11.85 , "83":  11.74 , "84":  11.64 , "85":  11.55 , "86":  11.47 , "87":  11.40 , "88":  11.35 , "89":  11.30 , "90":  11.26 , "91":  11.22 , "92":  11.20 , "93":  11.17 , "94":  11.15 , "95":  11.14 , "96":  11.13 , "97":  11.12 , "98":  11.11 , "99":  11.10 , "100":  11.10 , }, 
    {"Age": 85, "40":  18.67 , "41":  18.56 , "42":  18.45 , "43":  18.33 , "44":  18.21 , "45":  18.08 , "46":  17.95 , "47":  17.82 , "48":  17.69 , "49":  17.55 , "50":  17.41 , "51":  17.27 , "52":  17.13 , "53":  16.98 , "54":  16.82 , "55":  16.67 , "56":  16.51 , "57":  16.34 , "58":  16.17 , "59":  16.00 , "60":  15.82 , "61":  15.63 , "62":  15.44 , "63":  15.24 , "64":  15.05 , "65":  14.84 , "66":  14.64 , "67":  14.44 , "68":  14.24 , "69":  14.04 , "70":  13.83 , "71":  13.63 , "72":  13.42 , "73":  13.22 , "74":  13.01 , "75":  12.82 , "76":  12.63 , "77":  12.45 , "78":  12.27 , "79":  12.11 , "80":  11.96 , "81":  11.83 , "82":  11.70 , "83":  11.59 , "84":  11.48 , "85":  11.39 , "86":  11.31 , "87":  11.24 , "88":  11.18 , "89":  11.13 , "90":  11.09 , "91":  11.05 , "92":  11.02 , "93":  11.00 , "94":  10.98 , "95":  10.96 , "96":  10.95 , "97":  10.94 , "98":  10.93 , "99":  10.93 , "100":  10.92 , }, 
    {"Age": 86, "40":  18.65 , "41":  18.54 , "42":  18.43 , "43":  18.31 , "44":  18.19 , "45":  18.06 , "46":  17.93 , "47":  17.80 , "48":  17.66 , "49":  17.52 , "50":  17.38 , "51":  17.24 , "52":  17.09 , "53":  16.94 , "54":  16.79 , "55":  16.63 , "56":  16.47 , "57":  16.30 , "58":  16.13 , "59":  15.96 , "60":  15.78 , "61":  15.59 , "62":  15.39 , "63":  15.19 , "64":  14.99 , "65":  14.79 , "66":  14.58 , "67":  14.38 , "68":  14.17 , "69":  13.97 , "70":  13.76 , "71":  13.55 , "72":  13.34 , "73":  13.13 , "74":  12.92 , "75":  12.72 , "76":  12.52 , "77":  12.34 , "78":  12.16 , "79":  12.00 , "80":  11.84 , "81":  11.70 , "82":  11.57 , "83":  11.45 , "84":  11.34 , "85":  11.25 , "86":  11.17 , "87":  11.09 , "88":  11.03 , "89":  10.98 , "90":  10.94 , "91":  10.90 , "92":  10.87 , "93":  10.84 , "94":  10.82 , "95":  10.81 , "96":  10.79 , "97":  10.78 , "98":  10.77 , "99":  10.77 , "100":  10.76 , }, 
    {"Age": 87, "40":  18.63 , "41":  18.52 , "42":  18.41 , "43":  18.29 , "44":  18.17 , "45":  18.04 , "46":  17.91 , "47":  17.77 , "48":  17.64 , "49":  17.50 , "50":  17.36 , "51":  17.21 , "52":  17.07 , "53":  16.92 , "54":  16.76 , "55":  16.60 , "56":  16.44 , "57":  16.27 , "58":  16.10 , "59":  15.92 , "60":  15.74 , "61":  15.55 , "62":  15.35 , "63":  15.15 , "64":  14.94 , "65":  14.74 , "66":  14.53 , "67":  14.32 , "68":  14.11 , "69":  13.90 , "70":  13.69 , "71":  13.48 , "72":  13.26 , "73":  13.05 , "74":  12.84 , "75":  12.63 , "76":  12.43 , "77":  12.24 , "78":  12.06 , "79":  11.89 , "80":  11.73 , "81":  11.59 , "82":  11.45 , "83":  11.33 , "84":  11.22 , "85":  11.12 , "86":  11.04 , "87":  10.96 , "88":  10.90 , "89":  10.84 , "90":  10.80 , "91":  10.76 , "92":  10.73 , "93":  10.70 , "94":  10.68 , "95":  10.66 , "96":  10.65 , "97":  10.64 , "98":  10.63 , "99":  10.62 , "100":  10.62 , }, 
    {"Age": 88, "40":  18.62 , "41":  18.51 , "42":  18.39 , "43":  18.27 , "44":  18.15 , "45":  18.02 , "46":  17.89 , "47":  17.75 , "48":  17.62 , "49":  17.48 , "50":  17.33 , "51":  17.19 , "52":  17.04 , "53":  16.89 , "54":  16.73 , "55":  16.57 , "56":  16.41 , "57":  16.24 , "58":  16.07 , "59":  15.89 , "60":  15.70 , "61":  15.51 , "62":  15.31 , "63":  15.11 , "64":  14.90 , "65":  14.69 , "66":  14.48 , "67":  14.27 , "68":  14.06 , "69":  13.85 , "70":  13.63 , "71":  13.42 , "72":  13.20 , "73":  12.98 , "74":  12.77 , "75":  12.56 , "76":  12.35 , "77":  12.16 , "78":  11.97 , "79":  11.80 , "80":  11.64 , "81":  11.49 , "82":  11.35 , "83":  11.22 , "84":  11.11 , "85":  11.01 , "86":  10.92 , "87":  10.84 , "88":  10.78 , "89":  10.72 , "90":  10.68 , "91":  10.64 , "92":  10.60 , "93":  10.58 , "94":  10.56 , "95":  10.54 , "96":  10.52 , "97":  10.51 , "98":  10.50 , "99":  10.49 , "100":  10.49 , }, 
    {"Age": 89, "40":  18.60 , "41":  18.49 , "42":  18.38 , "43":  18.26 , "44":  18.13 , "45":  18.00 , "46":  17.87 , "47":  17.74 , "48":  17.60 , "49":  17.46 , "50":  17.32 , "51":  17.17 , "52":  17.02 , "53":  16.87 , "54":  16.71 , "55":  16.55 , "56":  16.39 , "57":  16.22 , "58":  16.04 , "59":  15.86 , "60":  15.67 , "61":  15.48 , "62":  15.28 , "63":  15.08 , "64":  14.87 , "65":  14.66 , "66":  14.44 , "67":  14.23 , "68":  14.02 , "69":  13.80 , "70":  13.58 , "71":  13.36 , "72":  13.14 , "73":  12.92 , "74":  12.70 , "75":  12.49 , "76":  12.28 , "77":  12.08 , "78":  11.90 , "79":  11.72 , "80":  11.55 , "81":  11.40 , "82":  11.26 , "83":  11.13 , "84":  11.02 , "85":  10.91 , "86":  10.82 , "87":  10.74 , "88":  10.68 , "89":  10.62 , "90":  10.57 , "91":  10.53 , "92":  10.50 , "93":  10.47 , "94":  10.45 , "95":  10.43 , "96":  10.41 , "97":  10.40 , "98":  10.39 , "99":  10.38 , "100":  10.38 , }, 
    {"Age": 90, "40":  18.59 , "41":  18.48 , "42":  18.36 , "43":  18.24 , "44":  18.12 , "45":  17.99 , "46":  17.86 , "47":  17.72 , "48":  17.58 , "49":  17.44 , "50":  17.30 , "51":  17.15 , "52":  17.00 , "53":  16.85 , "54":  16.69 , "55":  16.53 , "56":  16.37 , "57":  16.20 , "58":  16.02 , "59":  15.84 , "60":  15.65 , "61":  15.46 , "62":  15.25 , "63":  15.05 , "64":  14.84 , "65":  14.62 , "66":  14.41 , "67":  14.20 , "68":  13.98 , "69":  13.76 , "70":  13.54 , "71":  13.32 , "72":  13.10 , "73":  12.87 , "74":  12.65 , "75":  12.43 , "76":  12.22 , "77":  12.02 , "78":  11.83 , "79":  11.65 , "80":  11.48 , "81":  11.33 , "82":  11.18 , "83":  11.05 , "84":  10.93 , "85":  10.83 , "86":  10.74 , "87":  10.66 , "88":  10.59 , "89":  10.53 , "90":  10.48 , "91":  10.44 , "92":  10.40 , "93":  10.38 , "94":  10.35 , "95":  10.33 , "96":  10.32 , "97":  10.31 , "98":  10.30 , "99":  10.29 , "100":  10.28 , }, 
    {"Age": 91, "40":  18.58 , "41":  18.47 , "42":  18.35 , "43":  18.23 , "44":  18.11 , "45":  17.98 , "46":  17.85 , "47":  17.71 , "48":  17.57 , "49":  17.43 , "50":  17.29 , "51":  17.14 , "52":  16.99 , "53":  16.84 , "54":  16.68 , "55":  16.51 , "56":  16.35 , "57":  16.18 , "58":  16.00 , "59":  15.82 , "60":  15.63 , "61":  15.43 , "62":  15.23 , "63":  15.02 , "64":  14.81 , "65":  14.60 , "66":  14.38 , "67":  14.17 , "68":  13.95 , "69":  13.73 , "70":  13.51 , "71":  13.28 , "72":  13.06 , "73":  12.83 , "74":  12.61 , "75":  12.39 , "76":  12.17 , "77":  11.97 , "78":  11.78 , "79":  11.59 , "80":  11.42 , "81":  11.27 , "82":  11.12 , "83":  10.99 , "84":  10.87 , "85":  10.76 , "86":  10.66 , "87":  10.58 , "88":  10.51 , "89":  10.45 , "90":  10.40 , "91":  10.36 , "92":  10.33 , "93":  10.30 , "94":  10.27 , "95":  10.25 , "96":  10.24 , "97":  10.22 , "98":  10.21 , "99":  10.21 , "100":  10.20 , }, 
    {"Age": 92, "40":  18.57 , "41":  18.46 , "42":  18.35 , "43":  18.23 , "44":  18.10 , "45":  17.97 , "46":  17.84 , "47":  17.70 , "48":  17.56 , "49":  17.42 , "50":  17.27 , "51":  17.13 , "52":  16.98 , "53":  16.82 , "54":  16.66 , "55":  16.50 , "56":  16.33 , "57":  16.16 , "58":  15.99 , "59":  15.80 , "60":  15.61 , "61":  15.42 , "62":  15.21 , "63":  15.00 , "64":  14.79 , "65":  14.58 , "66":  14.36 , "67":  14.14 , "68":  13.92 , "69":  13.70 , "70":  13.48 , "71":  13.25 , "72":  13.02 , "73":  12.79 , "74":  12.57 , "75":  12.35 , "76":  12.13 , "77":  11.93 , "78":  11.73 , "79":  11.54 , "80":  11.37 , "81":  11.21 , "82":  11.07 , "83":  10.93 , "84":  10.81 , "85":  10.70 , "86":  10.60 , "87":  10.52 , "88":  10.45 , "89":  10.39 , "90":  10.34 , "91":  10.29 , "92":  10.26 , "93":  10.23 , "94":  10.20 , "95":  10.18 , "96":  10.17 , "97":  10.16 , "98":  10.15 , "99":  10.14 , "100":  10.13 , }, 
    {"Age": 93, "40":  18.57 , "41":  18.46 , "42":  18.34 , "43":  18.22 , "44":  18.09 , "45":  17.96 , "46":  17.83 , "47":  17.69 , "48":  17.55 , "49":  17.41 , "50":  17.26 , "51":  17.12 , "52":  16.97 , "53":  16.81 , "54":  16.65 , "55":  16.49 , "56":  16.32 , "57":  16.15 , "58":  15.97 , "59":  15.79 , "60":  15.60 , "61":  15.40 , "62":  15.20 , "63":  14.99 , "64":  14.77 , "65":  14.56 , "66":  14.34 , "67":  14.12 , "68":  13.90 , "69":  13.68 , "70":  13.45 , "71":  13.23 , "72":  13.00 , "73":  12.77 , "74":  12.54 , "75":  12.31 , "76":  12.10 , "77":  11.89 , "78":  11.69 , "79":  11.51 , "80":  11.33 , "81":  11.17 , "82":  11.02 , "83":  10.88 , "84":  10.76 , "85":  10.65 , "86":  10.55 , "87":  10.47 , "88":  10.40 , "89":  10.33 , "90":  10.28 , "91":  10.24 , "92":  10.20 , "93":  10.17 , "94":  10.15 , "95":  10.13 , "96":  10.11 , "97":  10.10 , "98":  10.09 , "99":  10.08 , "100":  10.07 , }, 
    {"Age": 94, "40":  18.56 , "41":  18.45 , "42":  18.33 , "43":  18.21 , "44":  18.09 , "45":  17.96 , "46":  17.82 , "47":  17.68 , "48":  17.54 , "49":  17.40 , "50":  17.26 , "51":  17.11 , "52":  16.96 , "53":  16.80 , "54":  16.64 , "55":  16.48 , "56":  16.31 , "57":  16.14 , "58":  15.96 , "59":  15.78 , "60":  15.59 , "61":  15.39 , "62":  15.18 , "63":  14.97 , "64":  14.76 , "65":  14.54 , "66":  14.32 , "67":  14.10 , "68":  13.88 , "69":  13.66 , "70":  13.43 , "71":  13.20 , "72":  12.97 , "73":  12.74 , "74":  12.51 , "75":  12.29 , "76":  12.07 , "77":  11.86 , "78":  11.66 , "79":  11.47 , "80":  11.30 , "81":  11.13 , "82":  10.98 , "83":  10.85 , "84":  10.72 , "85":  10.61 , "86":  10.51 , "87":  10.43 , "88":  10.35 , "89":  10.29 , "90":  10.24 , "91":  10.19 , "92":  10.16 , "93":  10.13 , "94":  10.10 , "95":  10.08 , "96":  10.07 , "97":  10.05 , "98":  10.04 , "99":  10.03 , "100":  10.03 , }, 
    {"Age": 95, "40":  18.56 , "41":  18.45 , "42":  18.33 , "43":  18.21 , "44":  18.08 , "45":  17.95 , "46":  17.82 , "47":  17.68 , "48":  17.54 , "49":  17.40 , "50":  17.25 , "51":  17.10 , "52":  16.95 , "53":  16.80 , "54":  16.64 , "55":  16.47 , "56":  16.30 , "57":  16.13 , "58":  15.95 , "59":  15.77 , "60":  15.58 , "61":  15.38 , "62":  15.17 , "63":  14.96 , "64":  14.75 , "65":  14.53 , "66":  14.31 , "67":  14.09 , "68":  13.87 , "69":  13.65 , "70":  13.42 , "71":  13.19 , "72":  12.96 , "73":  12.72 , "74":  12.49 , "75":  12.27 , "76":  12.05 , "77":  11.84 , "78":  11.64 , "79":  11.45 , "80":  11.27 , "81":  11.11 , "82":  10.95 , "83":  10.81 , "84":  10.69 , "85":  10.58 , "86":  10.48 , "87":  10.39 , "88":  10.32 , "89":  10.26 , "90":  10.20 , "91":  10.16 , "92":  10.12 , "93":  10.09 , "94":  10.06 , "95":  10.04 , "96":  10.03 , "97":  10.01 , "98":  10.00 , "99":  9.99 , "100":  9.99 , }, 
    {"Age": 96, "40":  18.55 , "41":  18.44 , "42":  18.33 , "43":  18.20 , "44":  18.08 , "45":  17.95 , "46":  17.81 , "47":  17.67 , "48":  17.53 , "49":  17.39 , "50":  17.25 , "51":  17.10 , "52":  16.95 , "53":  16.79 , "54":  16.63 , "55":  16.47 , "56":  16.30 , "57":  16.13 , "58":  15.95 , "59":  15.76 , "60":  15.57 , "61":  15.37 , "62":  15.17 , "63":  14.95 , "64":  14.74 , "65":  14.52 , "66":  14.30 , "67":  14.08 , "68":  13.86 , "69":  13.63 , "70":  13.41 , "71":  13.17 , "72":  12.94 , "73":  12.71 , "74":  12.48 , "75":  12.25 , "76":  12.03 , "77":  11.82 , "78":  11.62 , "79":  11.43 , "80":  11.25 , "81":  11.08 , "82":  10.93 , "83":  10.79 , "84":  10.66 , "85":  10.55 , "86":  10.45 , "87":  10.36 , "88":  10.29 , "89":  10.23 , "90":  10.17 , "91":  10.13 , "92":  10.09 , "93":  10.06 , "94":  10.03 , "95":  10.01 , "96":  10.00 , "97":  9.98 , "98":  9.97 , "99":  9.96 , "100":  9.96 , }, 
    {"Age": 97, "40":  18.55 , "41":  18.44 , "42":  18.32 , "43":  18.20 , "44":  18.07 , "45":  17.94 , "46":  17.81 , "47":  17.67 , "48":  17.53 , "49":  17.39 , "50":  17.24 , "51":  17.09 , "52":  16.94 , "53":  16.79 , "54":  16.63 , "55":  16.46 , "56":  16.29 , "57":  16.12 , "58":  15.94 , "59":  15.76 , "60":  15.57 , "61":  15.37 , "62":  15.16 , "63":  14.95 , "64":  14.73 , "65":  14.51 , "66":  14.29 , "67":  14.07 , "68":  13.85 , "69":  13.62 , "70":  13.40 , "71":  13.16 , "72":  12.93 , "73":  12.70 , "74":  12.46 , "75":  12.24 , "76":  12.02 , "77":  11.80 , "78":  11.60 , "79":  11.41 , "80":  11.23 , "81":  11.06 , "82":  10.91 , "83":  10.77 , "84":  10.64 , "85":  10.53 , "86":  10.43 , "87":  10.34 , "88":  10.27 , "89":  10.20 , "90":  10.15 , "91":  10.10 , "92":  10.07 , "93":  10.04 , "94":  10.01 , "95":  9.99 , "96":  9.97 , "97":  9.96 , "98":  9.95 , "99":  9.94 , "100":  9.93 , }, 
    {"Age": 98, "40":  18.55 , "41":  18.44 , "42":  18.32 , "43":  18.20 , "44":  18.07 , "45":  17.94 , "46":  17.81 , "47":  17.67 , "48":  17.53 , "49":  17.38 , "50":  17.24 , "51":  17.09 , "52":  16.94 , "53":  16.78 , "54":  16.62 , "55":  16.46 , "56":  16.29 , "57":  16.12 , "58":  15.94 , "59":  15.75 , "60":  15.56 , "61":  15.36 , "62":  15.15 , "63":  14.94 , "64":  14.73 , "65":  14.51 , "66":  14.29 , "67":  14.07 , "68":  13.84 , "69":  13.62 , "70":  13.39 , "71":  13.16 , "72":  12.92 , "73":  12.69 , "74":  12.45 , "75":  12.23 , "76":  12.00 , "77":  11.79 , "78":  11.59 , "79":  11.40 , "80":  11.22 , "81":  11.05 , "82":  10.90 , "83":  10.75 , "84":  10.63 , "85":  10.51 , "86":  10.41 , "87":  10.32 , "88":  10.25 , "89":  10.19 , "90":  10.13 , "91":  10.09 , "92":  10.05 , "93":  10.02 , "94":  9.99 , "95":  9.97 , "96":  9.95 , "97":  9.94 , "98":  9.93 , "99":  9.92 , "100":  9.91 , }, 
    {"Age": 99, "40":  18.55 , "41":  18.44 , "42":  18.32 , "43":  18.20 , "44":  18.07 , "45":  17.94 , "46":  17.80 , "47":  17.67 , "48":  17.53 , "49":  17.38 , "50":  17.24 , "51":  17.09 , "52":  16.94 , "53":  16.78 , "54":  16.62 , "55":  16.46 , "56":  16.29 , "57":  16.11 , "58":  15.94 , "59":  15.75 , "60":  15.56 , "61":  15.36 , "62":  15.15 , "63":  14.94 , "64":  14.72 , "65":  14.50 , "66":  14.28 , "67":  14.06 , "68":  13.84 , "69":  13.61 , "70":  13.38 , "71":  13.15 , "72":  12.91 , "73":  12.68 , "74":  12.45 , "75":  12.22 , "76":  11.99 , "77":  11.78 , "78":  11.58 , "79":  11.38 , "80":  11.21 , "81":  11.04 , "82":  10.88 , "83":  10.74 , "84":  10.61 , "85":  10.50 , "86":  10.40 , "87":  10.31 , "88":  10.23 , "89":  10.17 , "90":  10.12 , "91":  10.07 , "92":  10.03 , "93":  10.00 , "94":  9.98 , "95":  9.95 , "96":  9.94 , "97":  9.92 , "98":  9.91 , "99":  9.90 , "100":  9.90 , }, 
    {"Age": 100, "40":  18.55 , "41":  18.43 , "42":  18.32 , "43":  18.19 , "44":  18.07 , "45":  17.94 , "46":  17.80 , "47":  17.66 , "48":  17.52 , "49":  17.38 , "50":  17.23 , "51":  17.09 , "52":  16.93 , "53":  16.78 , "54":  16.62 , "55":  16.45 , "56":  16.28 , "57":  16.11 , "58":  15.93 , "59":  15.75 , "60":  15.55 , "61":  15.35 , "62":  15.15 , "63":  14.94 , "64":  14.72 , "65":  14.50 , "66":  14.28 , "67":  14.06 , "68":  13.83 , "69":  13.61 , "70":  13.38 , "71":  13.14 , "72":  12.91 , "73":  12.67 , "74":  12.44 , "75":  12.21 , "76":  11.99 , "77":  11.77 , "78":  11.57 , "79":  11.38 , "80":  11.20 , "81":  11.03 , "82":  10.87 , "83":  10.73 , "84":  10.60 , "85":  10.49 , "86":  10.39 , "87":  10.30 , "88":  10.22 , "89":  10.16 , "90":  10.10 , "91":  10.06 , "92":  10.02 , "93":  9.99 , "94":  9.96 , "95":  9.94 , "96":  9.92 , "97":  9.91 , "98":  9.90 , "99":  9.89 , "100":  9.88 , }, 
];

// LIVING ANNUITY

// LIVING ANNUITY MINIMUM %
const livingAnnuityMin = 2.5;
// LIVING ANNUITY MAXIMUM %
const livingAnnuityMax = 17.5;

export {
    levelAnnuityRates,
    levelAnnuityJointRates,
    inflationLinkedAnnuityRates,
    inflationLinkedJointAnnuityRates,
    livingAnnuityMin,
    livingAnnuityMax,
}
