// At retirement calculator
import { FIELD_STATUS } from "../../reducers/status";

export const ABOUT_YOU_SCROLL_CLASS = {
    PERSONAL_SECTION: 'about-1-section',
    RETIREMENT_WORRIES: 'about-2-anchor',
    INCOME_SECTION: 'about-3-anchor',
    CONTINUE_BUTTON: 'page-bottom',
};

export const FINANCIAL_ADVICE_SCROLL_CLASS = {
    REWARDS_SECTION: 'rewards-anchor',
    SUCCESS_SECTION: 'success-anchor',
    REWARDS_SECTION: "rewards-anchor",
    SUCCESS_SECTION: "success-anchor",
};

export const ARC_ALL_PAGES = {
  PAGE_TOP: "page-top",
};

// RA optimal
export const RA_OPTIMAL_INVESTMENT_SETUP = {
  TYPE_OF_INVESTOR: "typeOfInvestor",
};

export const RA_OPTIMAL_INVESTMENT_SETUP_SCROLL_ID = [
    'yearlyIncrease',
    'typeOfInvestor'
];


export const RA_OPTIMAL_PAYMENT_DETAILS_SCROLL_SECTIONS = {
  LEGAL_SECTION: "legal-anchor",
  ACCOUNT_DETAILS_SECTION: "account-details-anchor",
};

export const RA_OPTIMAL_PAYMENT_DETAILS_SCROLL_ID = [
  "bankName",
  "accountHolderName",
  "accountNumber",
  "accountType",
  "debitOrderDay",
  "startMonth",

  "sourceOfIncome",
  "sourceOfIncomeOther",
  "sourceOfFunds",
  "employmentPosition",
  "employmentIndustry",
  "employmentIndustryOther",
];

export const RA_OPTIMAL_PERSONAL_DETAILS_SCROLL_ID = [
  "title",
  "name",
  "middleName",
  "surname",
  "id",
  "countryOfBirth",
  "nationality",
  "maritalStatus",
  "incomeTax",

  "mobile",
  "email",

  "complex",
  "streetAddress",
  "suburb",
  "city",
  "postalCode",
  "country",

  "postalAddressSame",
  "postalAddressMethod",

  "poBox",
  "poPostOffice",
  "poPostalCode",
  "poCountry",

  "privateBag",
  "privatePostOffice",
  "privatePostalCode",
  "privateCountry",

  "postalComplex",
  "postalStreetAddress",
  "postalSuburb",
  "postalCity",
  "postalPostalCode",
  "postalCountry",
];

export const EASIPLUS_PERSONAL_DETAILS_SCROLL_ID = [
  "title",
  "firstNames",
  "surname",
  "id",

  "mobileNumber",
  "email",

  "complex",
  "streetAddress",
  "suburb",
  "city",
  "postalCode",
  "country",

  "postalAddressSame",
  "postalAddressMethod",

  "poBox",
  "poPostOffice",
  "poPostalCode",
  "poCountry",

  "privateBag",
  "privatePostOffice",
  "privatePostalCode",
  "privateCountry",

  "postalComplex",
  "postalStreetAddress",
  "postalSuburb",
  "postalCity",
  "postalPostalCode",
  "postalCountry",
];
export const DLP_PERSONAL_DETAILS_SCROLL_ID = [
  "title",
  "firstNames",
  "surname",
  "gender",
  "id",
  "idExpiry",
  "education",

  "mobileNumber",
  "email",

  "streetAddress",
  "city",
  "district",
  "country",
  
  "postalAddress",
  "postalCity",
  "postalDistrict",
  "postalCountry",
  
  "loanAmount",
  "loanType",
  "bank",
  "loanTerm",

  "employmentType",
  "occupation",
  "sourceOfIncome",
  "sourceOfIncomeText",

];

export const EFP_REPLACEMENT_POLICY_SCROLL_CLASS = {
  CONTINUE_BUTTON_ANCHOR: "continue-button-anchor",
};

export const EFP_PAYMENT_DETAILS_SCROLL_ID = [
  "bankName",
  "accountNumber",
  "accountType",
  "debitOrderDay",
  "paymentAuthorization",
];

export const EFP_PAYMENT_DETAILS_SCROLL_CLASS = {
  PAYMENT_DETAILS: "heading-anchor",
};

export const EFP_LIVES_COVERED_SCROLL_ID = ["title", "firstName", "surname", "dateOfBirth"];

export const EFP_CONFIRM_DETAILS = {
  DECLARATIONS_CHECKBOX: "declarations-checkbox",
};

export const TFSA_REVIEW = {
  TERMS_AND_CONDITIONS_CHECKBOX: "terms-and-conditions-checkbox",
};

export const TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_FORM_ID = [
  "onBehalfOfChild",
  "hasOtherTaxFreePlan",
  "legalGuardian",
  "savingFor",
  "yourGoal",
];

export const TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID = {
  ON_BEHALF_OF_CHILD: "onBehalfOfChild",
  HAS_OTHER_TAX_FREE_PLAN: "hasOtherTaxFreePlan",
  LEGAL_GUARDIAN: "legalGuardian",
  ALTERNATIVE_SOLUTIONS: "alternativeSolutions",
  SAVING_FOR: "savingFor",
  YOUR_GOAL: "yourGoal",
};

export const TFSA_PAYMENT_DETAILS_SCROLL_ID = [
  "bankName",
  "accountNumber",
  "accountType",
  "debitOrderDay",
  "month",
  "monthToSkip",
  "lumpSumDay",
  "lumpSumMonth",
  "paymentAuthorization",
  "sourceOfIncome",
  "sourceOfIncomeOther",
  "sourceOfFunds",
  "employmentPosition",
  "employmentIndustry",
  "employmentIndustryOther",
];

export const TFSA_BENEFICIARIES_SCROLL_ID = [
  "title",
  "firstName",
  "surname",
  "dateOfBirth",
  "gender",
  "mobile",
  "email",
];

export const TFSA_INVESTMENT_SETUP_CONTRIBUTION_SCROLL_ID = [
  "contributionType",
  "lumpSumContributionValue",
  "regularContributionValue",
  "premiumEscalation",
  "ownPercentage",
];

export const TFSA_INVESTMENT_SETUP_CONTRIBUTION_PAGE_ANCHORS = {
  LUMP_SUM_CONTRIBUTION: "lumpSumContributionValue",
  REGULAR_CONTRIBUTION: "regularContributionValue",
  PREMIUM_ESCALATION: "premiumEscalation",
};

export const TFSA_BENEFICIARIES_SIDE_DRAWER_ID = "beneficiaries-side-drawer";
export const TFSA_PAYMENT_DETAILS_LEGAL_DETAILS_ID = "legal-section";
export const TFSA_PERSONAL_DETAILS_SCROLL_ID = [
  "minorTitle",
  "minorFirstNames",
  "minorSurname",
  "minorId",
  "title",
  "firstNames",
  "surname",
  "id",
  "countryOfBirth",
  "nationality",
  "maritalStatus",
  "marriedInCOP",
  "spouseTitle",
  "spouseFirstNames",
  "spouseSurname",
  "spouseIdType",
  "spouseIdNumber",
  "spousePassportNumber",
  "email",
  "contactNumber",
  "complex",
  "streetAddress",
  "suburb",
  "city",
  "postalCode",
  "country",
  "postalAddressSame",
  "postalAddressMethod",
  "postalComplex",
  "postalStreetAddress",
  "postalSuburb",
  "postalCity",
  "postalPostalCode",
  "postalCountry",
  "poBoxNumber",
  "poPostOffice",
  "poPostalCode",
  "poPostalCountry",
  "privateBag",
  "privatePostOffice",
  "privatePostalCode",
  "privatePostalCountry",
];

export const TFSA_BENEFICIARIES_SPLIT_ANCHOR_IDS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const TFSA_VIEW_MORE_FUNDS_ANCHOR_ID = "view-more-funds-tabs";
export const formFieldValid = (field) => {
  return field?.status === FIELD_STATUS.VALID;
};

export const TFSA_FUND_SPLIT_REGULAR_CARD_ID_PREFIX = "fund-split-regular-card-";
export const TFSA_FUND_SPLIT_LUMP_SUM_CARD_ID_PREFIX = "fund-split-lump-sum-card-";

export const TFSA_FUND_SPLIT_TOTAL_ID = ["regularContributionTotal", "lumpSumContributionTotal"];
