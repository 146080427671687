export const PRICE_GRAPH_PUBLIC_WEB = 'publicWeb/priceGraph';
export const PRICE_GRAPH_SET_VALUE = PRICE_GRAPH_PUBLIC_WEB + '/setValue/';
export const PRICE_GRAPH_SET_VALID = PRICE_GRAPH_PUBLIC_WEB + '/setValid/';

export const PRICE_GRAPH_FORM_VALID = PRICE_GRAPH_PUBLIC_WEB + '/formTransition/valid';
export const PRICE_GRAPH_FORM_INVALID = PRICE_GRAPH_PUBLIC_WEB + '/formTransition/invalid';
export const formTransitionPriceGraph = {
    formValid: () => ({ type: PRICE_GRAPH_FORM_VALID }),
    formInvalid: () => ({ type: PRICE_GRAPH_FORM_INVALID }),
};

export const PRICE_GRAPH_API_PENDING_TRANSITION = `${PRICE_GRAPH_PUBLIC_WEB}/apiTransition/pending`;
export const PRICE_GRAPH_API_SUCCESS_TRANSITION = `${PRICE_GRAPH_PUBLIC_WEB}/apiTransition/success`;
export const PRICE_GRAPH_API_FAILURE_TRANSITION = `${PRICE_GRAPH_PUBLIC_WEB}/apiTransition/failure`;
export const apiTransitionPriceGraph = {
    pending: () => ({ type: PRICE_GRAPH_API_PENDING_TRANSITION }),
    success: () => ({ type: PRICE_GRAPH_API_SUCCESS_TRANSITION }),
    failure: () => ({ type: PRICE_GRAPH_API_FAILURE_TRANSITION }),
};

export const PRICE_GRAPH_FETCH_FUNDS_LIST = PRICE_GRAPH_PUBLIC_WEB + '/fetch/fundsList';
export const PRICE_GRAPH_FETCH_FUND_DATA = PRICE_GRAPH_PUBLIC_WEB + '/fetch/fundData';

export const getFundsListAction = () => ({type: PRICE_GRAPH_FETCH_FUNDS_LIST});
export const getFundDataAction = () => ({type: PRICE_GRAPH_FETCH_FUND_DATA});

export const PRICE_GRAPH_FUND_VALUE = PRICE_GRAPH_SET_VALUE + 'fund';
export const PRICE_GRAPH_START_DATE = PRICE_GRAPH_SET_VALUE + 'startDate'
export const PRICE_GRAPH_START_DATE_VALID = PRICE_GRAPH_SET_VALID + 'startDate';
export const PRICE_GRAPH_END_DATE = PRICE_GRAPH_SET_VALUE + 'endDate'
export const PRICE_GRAPH_END_DATE_VALID = PRICE_GRAPH_SET_VALID + 'endDate';
export const PRICE_GRAPH_SET_FUNDS_LIST = PRICE_GRAPH_SET_VALUE + 'fundsList';
export const PRICE_GRAPH_SET_FUND_DATA = PRICE_GRAPH_SET_VALUE + 'fundData';

export const setFundValue = value => ({type: PRICE_GRAPH_FUND_VALUE, value});
export const setFundsList = value => ({type: PRICE_GRAPH_SET_FUNDS_LIST, value});
export const setFundData = value => ({type: PRICE_GRAPH_SET_FUND_DATA, value});
export const setStartDateValue = value => ({type: PRICE_GRAPH_START_DATE, value});
export const setStartDateValid = error => ({type: PRICE_GRAPH_START_DATE_VALID, error});
export const setEndDateValue = value => ({type: PRICE_GRAPH_END_DATE, value});
export const setEndDateValid = error => ({type: PRICE_GRAPH_END_DATE_VALID, error});