import { createSelector } from "reselect";
import { getRoaQuoteTools } from './index';

import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../reducers/status";

export const getAfyaImaraSeniorQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.afyaImaraSeniorQuoteTool
);

export const getAfyaImaraSeniorQuoteToolForm = createSelector(
    getAfyaImaraSeniorQuoteTool,
    afyaImaraSeniorQuoteTool => afyaImaraSeniorQuoteTool.form
);

const makeGetFieldForm = fieldId => createSelector(
    getAfyaImaraSeniorQuoteToolForm,
    form => form[fieldId]
);

export const getRoaCmbObject = createSelector(
    getAfyaImaraSeniorQuoteTool,
    afyaImaraSeniorQuoteTool => afyaImaraSeniorQuoteTool.roaCmbObject
)

export const getSelectedForm = createSelector(
    getAfyaImaraSeniorQuoteTool,
    afyaImaraSeniorQuoteTool => afyaImaraSeniorQuoteTool.selectedForm
)

export const getQuotationSummary = createSelector(
    getAfyaImaraSeniorQuoteTool,
    afyaImaraSeniorQuoteTool => afyaImaraSeniorQuoteTool.quotationSummary
);

export const getShowError = createSelector(
    getAfyaImaraSeniorQuoteTool,
    afyaImaraSeniorQuoteTool => afyaImaraSeniorQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getAfyaImaraSeniorQuoteTool,
    afyaImaraSeniorQuoteTool => afyaImaraSeniorQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getCalculateDisabled = createSelector(
    getAfyaImaraSeniorQuoteTool,
    afyaImaraSeniorQuoteTool => {
        return afyaImaraSeniorQuoteTool.formStatus === FORM_STATUS.INVALID ||
            afyaImaraSeniorQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);

export const getInpatientCoverLimitValue = makeGetFieldForm('inpatientCoverLimit');
export const getOutpatientCoverLimitValue = makeGetFieldForm('outpatientCoverLimit');
