export const MVEST_QUOTE_TOOL = 'roaQuoteTools/mvestQuoteTool/';
export const MVEST_QUOTE_TOOL_SET_VALUE = MVEST_QUOTE_TOOL + 'setValue/';
export const MVEST_QUOTE_TOOL_SET_VALID = MVEST_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_MVEST_QUOTE_TOOL =
    'roaQuoteTools/mvestQuoteTool/transition/formStateValid';
export const FORM_INVALID_MVEST_QUOTE_TOOL =
    'roaQuoteTools/mvestQuoteTool/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/mvestQuoteTool/state/reinitialize';

export const transitionMvestTool = {
    formValid: () => ({ type: FORM_VALID_MVEST_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_MVEST_QUOTE_TOOL }),
};

export const WHICH_FORM_TO_DISPLAY =
    MVEST_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';
export const SET_DISPLAY_RESULT =
    MVEST_QUOTE_TOOL_SET_VALUE + 'setDisplayResult';

//api status
export const API_PENDING_TRANSITION_MVEST_QUOTE_TOOL = `${MVEST_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_MVEST_QUOTE_TOOL = `${MVEST_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_MVEST_QUOTE_TOOL = `${MVEST_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionMvestQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_MVEST_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_MVEST_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_MVEST_QUOTE_TOOL }),
};

///othre
export const PAYMENT_TERM_SET_VALUE =
    MVEST_QUOTE_TOOL_SET_VALUE + 'paymentTerm';
export const PAYMENT_TERM_SET_VALID =
    MVEST_QUOTE_TOOL_SET_VALID + 'paymentTerm';
export const PREMIUM_AMOUNT_SET_VALUE = MVEST_QUOTE_TOOL_SET_VALUE + 'amount';
export const PREMIUM_AMOUNT_SET_VALID = MVEST_QUOTE_TOOL_SET_VALID + 'amount';
export const PAYMENT_FREQUENCY_SET_VALUE =
    MVEST_QUOTE_TOOL_SET_VALUE + 'paymentFrequency';
export const PAYMENT_FREQUENCY_SET_VALID =
    MVEST_QUOTE_TOOL_SET_VALID + 'paymentFrequency';
//api part 2
export const COLLECT_MVEST_PREMIUM_DATA =
    MVEST_QUOTE_TOOL_SET_VALUE + 'collectMvestPremiumData';
export const CALCULATE_MVEST_PREMIUM_DATA =
    MVEST_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_MVEST_PREMIUM_DATA_FAILURE =
    MVEST_QUOTE_TOOL_SET_VALUE + 'failure';

//form data
export const setPaymentTermValue = value => ({
    type: PAYMENT_TERM_SET_VALUE,
    value,
});
export const setPaymentTermValid = error => ({
    type: PAYMENT_TERM_SET_VALID,
    error,
});
export const setPremiumAmountValue = value => ({
    type: PREMIUM_AMOUNT_SET_VALUE,
    value,
});
export const setPremiumAmountValid = error => ({
    type: PREMIUM_AMOUNT_SET_VALID,
    error,
});
export const setPaymentFrequencyValue = value => ({
    type: PAYMENT_FREQUENCY_SET_VALUE,
    value,
});
export const setPaymentFrequencyValid = error => ({
    type: PAYMENT_FREQUENCY_SET_VALID,
    error,
});
export const setMvestQuoteToInitial = () => ({ type: REINITIALIZE });
export const setWhichFormToDisplay = value => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});
////api
export const collectMvestPremiumData = () => ({
    type: COLLECT_MVEST_PREMIUM_DATA,
});
export const calculateMvestPremiumData = value => ({
    type: CALCULATE_MVEST_PREMIUM_DATA,
    value,
});
export const collectMvestPremiumDataFailure = error => ({
    type: CALCULATE_MVEST_PREMIUM_DATA_FAILURE,
    error,
});

export const setShowMvestQuoteToolResult = value => ({
    type: SET_DISPLAY_RESULT,
    value,
});
