import {
    RETIREMENT_CALCULATOR_FORM_SET_VALUE,
    RETIREMENT_CALCULATOR_FORM_SET_VALID
} from "../../../actions/retirementCalculator/retirementCalculatorForm"
import {retirmentCalculatorFormRules} from "./retirmentCalculatorForm"

export const retirmentCalculatorRules = (store, next, action) => {

    if (action.type.startsWith(RETIREMENT_CALCULATOR_FORM_SET_VALUE) ||
        action.type.startsWith(RETIREMENT_CALCULATOR_FORM_SET_VALID))
        return retirmentCalculatorFormRules(store, next, action);
}
