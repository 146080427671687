import { transitionTravelInsuranceTravelPlanForm } from '../../../actions/roaTravelInsurance/travelPlan';
import { getTravelPlanForm } from '../../../selectors/roaTravelInsurance/travelPlan';

import { FIELD_STATUS } from '../../../reducers/status';

export const travelPlanFormRules = (store, next, action) => {
    const result = next(action);
    const travelPlanForm = getTravelPlanForm(store.getState());
    const travelPlanFormValid = validateTravelPlanForm(travelPlanForm);
    const formAction = travelPlanFormValid
        ? transitionTravelInsuranceTravelPlanForm.formValid()
        : transitionTravelInsuranceTravelPlanForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateTravelPlanForm = formFields => {
    return (
        formFields.travelPlan &&
        formFields.travelPlan.status === FIELD_STATUS.VALID
    );
};
