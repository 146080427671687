import axios from "axios";
import {
    getIDVerificationPayload
} from "../../../selectors/customerVerificationService/idVerification";
import {
    sendIDVerificationCall,
    successIDVerificationCall,
    failureIDVerificationCall,
    setIdentityToken,
    setReferenceGuid,
    setFirstNameValue,
    setLastNameValue,
    setOpenGoodToGoModal,
} from "../../../actions/customerVerificationService/idVerification";
import { navigateNextIDVerification } from "../../../actions/customerVerificationService/routing";

export const identityVerificationService = (store, next, action) => {
    const result = next(action);

    store.dispatch(sendIDVerificationCall());
    const idVerificationPayload = getIDVerificationPayload(store.getState());

    (async () => {
        const idVerificationResponse = await submitVerification(idVerificationPayload);

        if (idVerificationResponse && (idVerificationResponse.status === 200)) {
            store.dispatch(successIDVerificationCall());
            store.dispatch(setIdentityToken(idVerificationResponse.data.identityToken));
            store.dispatch(setReferenceGuid(idVerificationResponse.data.reference));

            // Display good to go modal for 2.5 seconds
            // and the route to id verification
            store.dispatch(setOpenGoodToGoModal(true));
            setTimeout(() => { 
               store.dispatch(navigateNextIDVerification(idVerificationResponse.data.dhaImageExists)); 
            }, 2500);
                
            if(idVerificationResponse.data.firstName){
                store.dispatch(setFirstNameValue(idVerificationResponse.data.firstName));
            }

            if(idVerificationResponse.data.surname){
                store.dispatch(setLastNameValue(idVerificationResponse.data.surname));
            }

        } else {
            store.dispatch(failureIDVerificationCall(idVerificationResponse.data.errors));
        }
    })();

    return result;
};

export const submitVerification = async body => {
    try {
        const response = await axios.post('/om-api/customer-verification-service/initiate', 
        body, { headers: { 'Content-Type': 'application/json' } });

        return response;
        
    } catch (error) {
        return {
            data: {
                error
            }
        }
    }
};
