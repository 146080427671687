import {validationIsEqual} from '../status';
import {
    setCompanyNameValid,
    setEmailValid,
    setFirstNameValid,
    setNumberEmployeesValid,
    setPhoneNumberValid,
    setSurnameValid,
    setModularField1Valid,
    setModularField2Valid,
    setModularField3Valid,
    setModularField4Valid,
    setModularField5Valid,
    setModularField6Valid,
    setModularField7Valid,
    setModularField8Valid,
    setIndustriesListValid,
    SIGNUP_COMPANY_NAME_VALUE,
    SIGNUP_EMAIL_VALUE,
    SIGNUP_NAME_VALUE,
    SIGNUP_NUMBER_EMPLOYEES_VALUE,
    SIGNUP_PHONE_NUMBER_VALUE,
    SIGNUP_SURNAME_VALUE,
    SIGNUP_MODULAR_FIELD_1_VALUE,
    SIGNUP_MODULAR_FIELD_2_VALUE,
    SIGNUP_MODULAR_FIELD_3_VALUE,
    SIGNUP_MODULAR_FIELD_4_VALUE,
    SIGNUP_MODULAR_FIELD_5_VALUE,
    SIGNUP_MODULAR_FIELD_6_VALUE,
    SIGNUP_MODULAR_FIELD_7_VALUE,
    SIGNUP_MODULAR_FIELD_8_VALUE,
    SIGNUP_SELECTED_INDUSTRIES_LIST_VALUE,
} from '../../../actions/publicWeb/signupAndDownload';
import {
    validateAlphaNumericString,
    validateCellphoneNumber,
    validateEmail,
    validateFirstName,
    validateNumber,
    validateSurname,
    validateSingleSelection
} from '@om/validation';
import { validateGenericString } from '../../../../validation/src/validators/string-validator';
import { validateGenericNumber } from '../../../../validation/src/validators/number-validator';

let prevValidationAction = null;
export const signupAndDownloadValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
        case SIGNUP_NAME_VALUE:
            error = validateFirstName(action.value);
            return setFirstNameValid(error);
        case SIGNUP_SURNAME_VALUE:
            error = validateSurname(action.value);
            return setSurnameValid(error);
        case SIGNUP_NUMBER_EMPLOYEES_VALUE:
            error = validateNumber(action.value, {min: 1, max: 99999999})
            return setNumberEmployeesValid(error);
        case SIGNUP_COMPANY_NAME_VALUE:
            error = validateAlphaNumericString(action.value, {min: 2, max: 64, subject: 'Company Name'});
            return setCompanyNameValid(error);
        case SIGNUP_EMAIL_VALUE:
            error = validateEmail(action.value);
            return setEmailValid(error);
        case SIGNUP_PHONE_NUMBER_VALUE:
            error = validateCellphoneNumber(action.value);
            return setPhoneNumberValid(error);
        case SIGNUP_MODULAR_FIELD_1_VALUE:
            error = validateGenericString(action.value);
            return setModularField1Valid(error);
        case SIGNUP_MODULAR_FIELD_2_VALUE:
            error = validateGenericString(action.value);
            return setModularField2Valid(error);
        case SIGNUP_MODULAR_FIELD_3_VALUE:
            error = validateGenericString(action.value);
            return setModularField3Valid(error);
        case SIGNUP_MODULAR_FIELD_4_VALUE:
            error = validateGenericNumber(action.value);
            return setModularField4Valid(error);
        case SIGNUP_MODULAR_FIELD_5_VALUE:
            error = validateGenericNumber(action.value);
            return setModularField5Valid(error);
        case SIGNUP_MODULAR_FIELD_6_VALUE:
            error = validateAlphaNumericString(action.value);
            return setModularField6Valid(error);
        case SIGNUP_MODULAR_FIELD_7_VALUE:
            error = validateAlphaNumericString(action.value);
            return setModularField7Valid(error);
        case SIGNUP_MODULAR_FIELD_8_VALUE:
            error = validateEmail(action.value);
            return setModularField8Valid(error);
        case SIGNUP_SELECTED_INDUSTRIES_LIST_VALUE:
            error = validateSingleSelection(action.value);
            return setIndustriesListValid(error);
        default:
            return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
