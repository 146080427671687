import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { DO_RESET_APPLICATION_FLOW } from '../../actions/digitalOnboarding/types';
import stateInitialiser from './initState';
import routing from './routing';
import lookupData from './lookupData';
import personalDetails, { buildStateWithMaskedPrivateUserInformation, hydrateStateFromPersistStore } from './personalDetails';
import applicationNow from './applicationNow';
import employmentDetails from './employmentDetails';
import affordabilityDetails from './affordabilityDetails';
import quoteDetails from './quoteDetails';
import otp from "./otp";
import addressDetails from './addressDetails';
import persistedAt from './persistedAt';
import storage from 'redux-persist/lib/storage';
import { createTransform } from 'redux-persist';
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import documentStore from './documentStore';

const maskingConfig = {
    inbound: buildStateWithMaskedPrivateUserInformation,
    outbound: hydrateStateFromPersistStore,
    whitelist: ['personalDetails']
};

const routingBlacklistFilter = createBlacklistFilter(
    'routing',
    ['pendingPageRequests']
);

const digitalOnboardingPersistConfig = {
    key: 'digitalOnboarding',
    storage,
    transforms: [
        createTransform(maskingConfig.inbound, maskingConfig.outbound, { whitelist: maskingConfig.whitelist }),
        routingBlacklistFilter
    ]
};

const flowReducers = persistCombineReducers(digitalOnboardingPersistConfig,{
    routing,
    lookupData,
    personalDetails,
    applicationNow,
    employmentDetails,
    affordabilityDetails,
    quoteDetails,
    otp,
    addressDetails,
    persistedAt,
    documentStore
});

export const digitalOnboardingInitState = persistCombineReducers(digitalOnboardingPersistConfig, stateInitialiser());

export const digitalOnboarding = (state, action) => {
    if (action.type === DO_RESET_APPLICATION_FLOW) {
        return digitalOnboardingInitState(state,action);
    }

    return flowReducers(state, action);
};

