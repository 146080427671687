import { routingRules } from "./routing";
import { quoteFormRules, quoteSaveDeleteClearRules } from "./quote";
import { callMeBackRules } from "./callMeBack";
import { replacementPolicyRules } from "./replacementPolicy";
import { changePostalAddressMethod, personalDetailsRules } from "./personalDetails";
import { livesCoveredRules, livesCoveredUpdateFormRules } from "./livesCovered";
import { beneficiariesFormRules, beneficiarySaveDeleteRules, selectedExistingBenificiary } from "./beneficiaries";
import { beneficiariesSplitFormRules } from "./beneficiariesSplit";
import { paymentDetailsRules } from "./paymentDetails";
import { confirmDetailsRules } from "./confirmDetails";
import {
    QUOTE_SET_VALUE,
    QUOTE_SET_VALID,
    SAVE_TRANSITION_QUOTE,
    DELETE_TRANSITION_QUOTE,
    CLEAR_ALL_TRANSITION_QUOTE,
} from "../../../actions/easiplusFuneralPlan/quote";
import {
    REPLACEMENT_POLICY_SET_VALID,
    REPLACEMENT_POLICY_SET_VALUE,
    FORM_VALID_TRANSITION_REPLACEMENT_POLICY,
} from "../../../actions/easiplusFuneralPlan/replacementPolicy";
import { EFP_ROUTING_SET_VALUE } from "../../../actions/easiplusFuneralPlan/routing";
import {
    PAYMENT_DETAILS_SET_VALID,
    PAYMENT_DETAILS_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    FORM_VALID_TRANSITION_PAYMENT_DETAILS,
} from "../../../actions/easiplusFuneralPlan/paymentDetails";
import {
    PERSONAL_DETAILS_SET_VALID,
    PERSONAL_DETAILS_CLEAR_VALUE,
    POSTAL_ADDRESS_METHOD_VALUE,
    POSTAL_ADDRESS_SAME_VALUE,
} from "../../../actions/easiplusFuneralPlan/personalDetails";
import {
    TOGGLE_DECLARATIONS_CONFIRM_DETAILS,
    RESET_VALUE_DECLARATION,
    EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS,
} from "../../../actions/easiplusFuneralPlan/confirmDetails";
import {
    LIVES_COVERED_SET_VALUE,
    LIVES_COVERED_SET_VALID,
    PUSH_COVER_MEMBER_LIVES_COVERED,
    DELETE_COVER_MEMBER_LIVES_COVERED,
    MEMBERS_UPDATED_LIVES_COVERED,
    MODAL_CLEAR_VALUE,
} from "../../../actions/easiplusFuneralPlan/livesCovered";
import { CALL_ME_BACK_SET_VALID, CALL_ME_BACK_SET_VALUE } from "../../../actions/easiplusFuneralPlan/callMeBack";
import {
    SELECTED_EXISTING_BENEFICIARY_SET_VALUE,
    BENEFICIARIES_SET_VALUE,
    BENEFICIARIES_SET_VALID,
    SAVE_TRANSITION_BENEFICIARIES,
    DELETE_TRANSITION_BENEFICIARIES,
} from "../../../actions/easiplusFuneralPlan/beneficiaries"
import {
    EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT,
    FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT,
    SPLIT_PERCENTAGE_SET_VALID,
    SPLIT_PERCENTAGE_SET_VALUE,
} from "../../../actions/easiplusFuneralPlan/beneficiariesSplit"

export const easiplusFuneralPlanRules = (store, next, action) => {
    // EFP Replacement Policy
    if (action.type.startsWith(REPLACEMENT_POLICY_SET_VALID) ||
        action.type.startsWith(REPLACEMENT_POLICY_SET_VALUE))
        return replacementPolicyRules(store, next, action);

    // General quoteRules
    if (action.type.startsWith(QUOTE_SET_VALUE) ||
        action.type.startsWith(QUOTE_SET_VALID))
        return quoteFormRules(store, next, action);

    // quote save/delete rules
    if (action.type.startsWith(SAVE_TRANSITION_QUOTE) ||
        action.type.startsWith(DELETE_TRANSITION_QUOTE) ||
        action.type.startsWith(CLEAR_ALL_TRANSITION_QUOTE))
        return quoteSaveDeleteClearRules(store, next, action);

    // EFP Call me back
    if (action.type.startsWith(CALL_ME_BACK_SET_VALID) ||
        action.type.startsWith(CALL_ME_BACK_SET_VALUE))
        return callMeBackRules(store, next, action);

    // Personal details
    if (action.type.startsWith(POSTAL_ADDRESS_METHOD_VALUE))
        return changePostalAddressMethod(store, next, action);

    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALID) ||
        action.type.startsWith(PERSONAL_DETAILS_CLEAR_VALUE) ||
        action.type === POSTAL_ADDRESS_METHOD_VALUE ||
        action.type === POSTAL_ADDRESS_SAME_VALUE)
        return personalDetailsRules(store, next, action);

    // EFP Payment Details
    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALID) ||
        action.type.startsWith(PAYMENT_DETAILS_SET_VALUE) ||
        action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS)
        return paymentDetailsRules(store, next, action);

    // EFP Confirm details
    if (action.type.startsWith(TOGGLE_DECLARATIONS_CONFIRM_DETAILS) ||
        action.type.startsWith(RESET_VALUE_DECLARATION)||
        action.type.startsWith(EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS))
        return confirmDetailsRules(store, next,action);

    // EFP Beneficiaries
    if (action.type.startsWith(BENEFICIARIES_SET_VALUE) ||
        action.type.startsWith(BENEFICIARIES_SET_VALID))
        return beneficiariesFormRules(store, next, action);

    if (action.type === SAVE_TRANSITION_BENEFICIARIES ||
        action.type === DELETE_TRANSITION_BENEFICIARIES)
        return beneficiarySaveDeleteRules(store, next, action);

    // Beneficiaries split
    if (action.type === FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT ||
        action.type === EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT ||
        action.type === EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT ||
        action.type === EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT ||
        action.type === SPLIT_PERCENTAGE_SET_VALUE ||
        action.type === SPLIT_PERCENTAGE_SET_VALID)
        return beneficiariesSplitFormRules(store, next, action);

    // EFP Lives Covered
    if (action.type.startsWith(LIVES_COVERED_SET_VALUE) ||
        action.type.startsWith(LIVES_COVERED_SET_VALID) ||
        action.type === MODAL_CLEAR_VALUE ||
        action.type === PUSH_COVER_MEMBER_LIVES_COVERED ||
        action.type === DELETE_COVER_MEMBER_LIVES_COVERED)
        return livesCoveredRules(store, next, action);

    // quote save/delete rules
    if (action.type === MEMBERS_UPDATED_LIVES_COVERED)
        return livesCoveredUpdateFormRules(store, next, action);

    // Trigger routing transitions
    if (action.type.startsWith(EFP_ROUTING_SET_VALUE) ||
        action.type === FORM_VALID_TRANSITION_REPLACEMENT_POLICY ||
        action.type === EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS ||
        action.type === FORM_VALID_TRANSITION_PAYMENT_DETAILS)
        return routingRules(store, next, action);

    return null;
};
