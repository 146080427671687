import axios from 'axios'; 

import {
    calculateAccidentalDisabilityPremiumData,
    apiTransitionAccidentalDisabilityQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/accidentalDisabilityQuoteTool";

import {
    getAccidentalDisabilityQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/accidentalDisabilityQuoteTool';

export const accidentalDisabilityQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionAccidentalDisabilityQuoteTool.pending());

            try {
                const response = await calculate(state);
                const premiumAmount = response.data.total_premium
                store.dispatch(calculateAccidentalDisabilityPremiumData(premiumAmount));
                store.dispatch(apiTransitionAccidentalDisabilityQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateAccidentalDisabilityPremiumData(null));
                store.dispatch(apiTransitionAccidentalDisabilityQuoteTool.failure());
            }
        }
    })();
    return result;
}
const getAccidentalDisabilityPayload = (formDetails) => {
    return {
        'dob': formDetails.candidateDob.value || '',
        'gender': formDetails.genderInput.value || '',
        'smoke_status': formDetails.smokerInput.value || '',
        'txt_acc_dis': formDetails.coverAmount.value || '',
        'txt_acc_dis_term': formDetails.coverYearsValue.value || '',

    }
}
const calculate = async(state) => {
    const accidentalDisabilityFormDetails = getAccidentalDisabilityQuoteToolForm(state)

    try{
        const url = '/om-api/roa-quote-tools/accidental-disability-quote';
        const result = await axios.post(url, getAccidentalDisabilityPayload(accidentalDisabilityFormDetails))
        return result;
    } catch (error) {
        return null;
    }
}
