import axios from 'axios';
import {getIsApiPending, getAlterBenefitIdValue} from "../../../selectors/roaCarInsurance/details";
import { getVehicleValuation } from '../../../selectors/roaCarInsurance/quotation';
import {getPremiumCalculation} from '@om/redux/selectors/roaCarInsurance/product';

import {
	setAlterBenefitValue,
    setAlterBenefitValid
} from "../../../actions/roaCarInsurance/details";


export const alterBenefitService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();
	(async () => {
		if(!getIsApiPending(state)) {
			try {
				const resp = await alterBenefit(state)
                store.dispatch(setAlterBenefitValue(resp.data))
			} catch (e) {
				store.dispatch(setAlterBenefitValid(e))
				
			}
		}
	})()
	return result
}

const alterBenefit = async (state) => {
	const benefit = getAlterBenefitIdValue(state)
    const vehicleValuation = getVehicleValuation(state)
	const premiumCalculation = getPremiumCalculation(state)
	const payload = {
		  ...benefit,
          basic_premium: premiumCalculation.comprehensive_basic_premium,
		  training_levy: premiumCalculation.comprehensive_training_levy,
		  phcf: premiumCalculation.comprehensive_phcf,
		  stamp_duty: premiumCalculation.comprehensive_stamp_duty,
		  session_id: vehicleValuation.value.session_id
	}
	try {
		const url = '/om-api/roa-car-insurance/alter-benefit-limit'
		return await axios.post(url, payload)
	} catch (e) {
		console.error(e)
		throw e
	}
}
