import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/roaCarInsurance/types';
import { getRoaCarInsuranceState } from './index';

const getRouting = createSelector(
    getRoaCarInsuranceState,
    (state) => state.routing
);
export const getActivePageIndex = createSelector(
    getRouting,
    (routing) => routing.activePageIndex
);
export const getDoCarInsuranceNavigation = createSelector(
    getRouting,
    (routing) => routing.doNavigation
);
export const getDoExitCarInsuranceNavigation = createSelector(
    getRouting,
    (routing) => routing.doExitNavigation
);

export const getLandingPage = createSelector(getRouting, routing => routing.landingPage);

const makeGetIsPage = (pageIndex) =>
    createSelector(
        getRouting,
        (routing) => routing.activePageIndex === pageIndex
    );
export const getIsQuotationPage = makeGetIsPage(PAGE_INDICES.QUOTATION);
export const getIsAboutYouPage = makeGetIsPage(PAGE_INDICES.ABOUT_YOU);
export const getIsProductsPage = makeGetIsPage(PAGE_INDICES.PRODUCTS);
export const getIsDetailsPage = makeGetIsPage(PAGE_INDICES.DETAILS);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getIsDropoffPage = createSelector(
	getRouting,
	routing => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU,
);

export const getProgressBarIndex = createSelector(
    getIsQuotationPage,
    getIsAboutYouPage,
    getIsProductsPage,
    getIsDetailsPage,
    getIsPaymentPage,
    (
        isQuotationPage,
        isAboutYouPage,
        isProductPage,
        isDetailsPage,
        isPaymentPage
    ) => {
        if (isQuotationPage) return 0;
        if (isAboutYouPage) return 1;
        if (isProductPage) return 2;
        if (isDetailsPage) return 3;
        if (isPaymentPage) return 4;
    }
);
