// Transitions
export const TOGGLE_SPEAK_TO_AN_ADVISER = 'atRetirement/speakToAnAdviser/transition/toggleSpeakToAnAdviser';

export const toggleSpeakToAnAdviser = () => ({ type: TOGGLE_SPEAK_TO_AN_ADVISER });

export const FORM_VALID_TRANSITION_SPEAK_TO_AN_ADVISER = 'atRetirement/speakToAnAdviser/transition/formValid';
export const FORM_INVALID_TRANSITION_SPEAK_TO_AN_ADVISER = 'atRetirement/speakToAnAdviser/transition/formInvalid';

export const transitionSpeakToAnAdviser = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_SPEAK_TO_AN_ADVISER }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_SPEAK_TO_AN_ADVISER }),
};

// Events
export const SPEAK_TO_AN_ADVISER_SET_VALUE = 'atRetirement/speakToAnAdviser/setValue/';
export const SPEAK_TO_AN_ADVISER_SET_VALID = 'atRetirement/speakToAnAdviser/setValid/';
export const EMAIL_SEND = 'atRetirement/speakToAnAdviser/email/';

export const REASONS_SET_VALUE = SPEAK_TO_AN_ADVISER_SET_VALUE + 'reasons';
export const REASONS_SET_VALID = SPEAK_TO_AN_ADVISER_SET_VALID + 'reasons';

export const MORE_HELP_SET_VALUE = SPEAK_TO_AN_ADVISER_SET_VALUE + 'moreHelp';
export const MORE_HELP_SET_VALID = SPEAK_TO_AN_ADVISER_SET_VALID + 'moreHelp';

export const CONTACT_NUMBER_SET_VALUE = SPEAK_TO_AN_ADVISER_SET_VALUE + 'contactNumber';
export const CONTACT_NUMBER_SET_VALID = SPEAK_TO_AN_ADVISER_SET_VALID + 'contactNumber';

export const EMAIL_ADDRESS_SET_VALUE = SPEAK_TO_AN_ADVISER_SET_VALUE + 'emailAddress';
export const EMAIL_ADDRESS_SET_VALID = SPEAK_TO_AN_ADVISER_SET_VALID + 'emailAddress';

export const SPEAK_TO_AN_ADVISER_SEND = EMAIL_SEND + 'send';
export const SPEAK_TO_AN_ADVISER_SEND_SUCCESS = EMAIL_SEND + 'success';
export const SPEAK_TO_AN_ADVISER_SEND_FAILURE = EMAIL_SEND + 'failure';

export const setReasonsValue = value => ({ type: REASONS_SET_VALUE, value });
export const setReasonsValid = error => ({ type: REASONS_SET_VALID, error });

export const setMoreHelpValue = value => ({ type: MORE_HELP_SET_VALUE, value });
export const setMoreHelpValid = error => ({ type: MORE_HELP_SET_VALID, error });

export const setContactNumberValue = value => ({ type: CONTACT_NUMBER_SET_VALUE, value });
export const setContactNumberValid = error => ({ type: CONTACT_NUMBER_SET_VALID, error });

export const setEmailAddressValue = value => ({ type: EMAIL_ADDRESS_SET_VALUE, value });
export const setEmailAddressValid = error => ({ type: EMAIL_ADDRESS_SET_VALID, error });

export const sendSpeakToAnAdviser = () => ({ type: SPEAK_TO_AN_ADVISER_SEND });
export const sendSpeakToAnAdviserSuccess = value => ({ type: SPEAK_TO_AN_ADVISER_SEND_SUCCESS, value });
export const sendSpeakToAnAdviserFailure = error => ({ type: SPEAK_TO_AN_ADVISER_SEND_FAILURE, error });
