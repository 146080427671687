export const UG_MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM = 'ugMotorInsurance/employmentDetailsForm/';

export const MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE =
    UG_MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM + 'setValue/';
export const MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID =
    UG_MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM =
    'ugMotorInsurance/employmentDetailsForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE__EMPLOYMENT_DETAILS_FORM =
    'ugMotorInsurance/employmentDetailsForm/transition/formInValid';

export const REINITIALIZE = 'ugMotorInsurance/employmentDetailsForm/state/reinitialize';

export const transitionMotorInsuranceEmploymentDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE__EMPLOYMENT_DETAILS_FORM,
    }),
};

export const EMPLOYMENT_STATUS_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'employmentStatus';
export const EMPLOYMENT_STATUS_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'employmentStatus';

export const EMPLOYER_NAME_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'employerName';
export const EMPLOYER_NAME_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'employerName';

export const SCHEME_NAME_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'schemeName';
export const SCHEME_NAME_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'schemeName';

export const OCCUPATION_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'occupation';
export const OCCUPATION_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'occupation';

export const INDUSTRY_OR_SECTOR_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'industryOrSector';
export const INDUSTRY_OR_SECTOR_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'industryOrSector';

export const CONTRACT_END_DATE_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'contractEndDate';
export const CONTRACT_END_DATE_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'contractEndDate';

export const EMPLOYMENT_NUMBER_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'employeeNumber';
export const EMPLOYMENT_NUMBER_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'employeeNumber';

export const SALARY_RANGE_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'salaryRange';
export const SALARY_RANGE_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'salaryRange';

export const EMPLOYER_POSTAL_ADDRESS_SET_VALUE =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'employerPostalAddress';
export const EMPLOYER_POSTAL_ADDRESS_SET_VALID =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'employerPostalAddress';

export const EMPLOYER_TOWN_OR_CITY_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'employerTownOrCity';
export const EMPLOYER_TOWN_OR_CITY_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'employerTownOrCity';

export const EMPLOYER_PHONE_NUMBER_SET_VALUE =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'employerPhoneNumber';
export const EMPLOYER_PHONE_NUMBER_SET_VALID =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'employerPhoneNumber';

export const PERSONAL_DATA_TERMS_SET_VALUE = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'personalDataTerms';
export const PERSONAL_DATA_TERMS_SET_VALID = MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'personalDataTerms';

export const PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'personalDataProcessingConsent';
export const PERSONAL_DATA_PROCESSING_CONSENT_SET_VALID =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'personalDataProcessingConsent';

export const CHILD_DATA_PROCESSING_CONSENT_SET_VALUE =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'childDataProcessingConsent';
export const CHILD_DATA_PROCESSING_CONSENT_SET_VALID =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'childDataProcessingConsent';

export const MARKETING_USE_CONSENT_SET_VALUE =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE + 'marketingUseConsent';
export const MARKETING_USE_CONSENT_SET_VALID =
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID + 'marketingUseConsent';

export const setEmploymentStatusValue = (value) => ({
    type: EMPLOYMENT_STATUS_SET_VALUE,
    value,
});
export const setEmploymentStatusValid = (error) => ({
    type: EMPLOYMENT_STATUS_SET_VALID,
    error,
});

export const setEmployerNameValue = (value) => ({
    type: EMPLOYER_NAME_SET_VALUE,
    value,
});
export const setEmployerNameValid = (error) => ({
    type: EMPLOYER_NAME_SET_VALID,
    error,
});

export const setSchemeNameValue = (value) => ({
    type: SCHEME_NAME_SET_VALUE,
    value,
});
export const setSchemeNameValid = (error) => ({
    type: SCHEME_NAME_SET_VALID,
    error,
});

export const setOccupationValue = (value) => ({
    type: OCCUPATION_SET_VALUE,
    value,
});
export const setOccupationValid = (error) => ({
    type: OCCUPATION_SET_VALID,
    error,
});

export const setIndustryOrSectorValue = (value) => ({
    type: INDUSTRY_OR_SECTOR_SET_VALUE,
    value,
});
export const setIndustryOrSectorValid = (error) => ({
    type: INDUSTRY_OR_SECTOR_SET_VALID,
    error,
});

export const setContractEndDateValue = (value) => ({
    type: CONTRACT_END_DATE_SET_VALUE,
    value,
});
export const setContractEndDateValid = (error) => ({
    type: CONTRACT_END_DATE_SET_VALID,
    error,
});

export const setEmploymentNumberValue = (value) => ({
    type: EMPLOYMENT_NUMBER_SET_VALUE,
    value,
});
export const setEmploymentNumberValid = (error) => ({
    type: EMPLOYMENT_NUMBER_SET_VALID,
    error,
});

export const setSalaryRangeValue = (value) => ({
    type: SALARY_RANGE_SET_VALUE,
    value,
});
export const setSalaryRangeValid = (error) => ({
    type: SALARY_RANGE_SET_VALID,
    error,
});

export const setEmployerPostalAddressValue = (value) => ({
    type: EMPLOYER_POSTAL_ADDRESS_SET_VALUE,
    value,
});
export const setEmployerPostalAddressValid = (error) => ({
    type: EMPLOYER_POSTAL_ADDRESS_SET_VALID,
    error,
});

export const setEmployerTownOrCityValue = (value) => ({
    type: EMPLOYER_TOWN_OR_CITY_SET_VALUE,
    value,
});
export const setEmployerTownOrCityValid = (error) => ({
    type: EMPLOYER_TOWN_OR_CITY_SET_VALID,
    error,
});

export const setEmployerPhoneNumberValue = (value) => ({
    type: EMPLOYER_PHONE_NUMBER_SET_VALUE,
    value,
});
export const setEmployerPhoneNumberValid = (error) => ({
    type: EMPLOYER_PHONE_NUMBER_SET_VALID,
    error,
});

export const setPersonalDataTermsValue = (value) => ({ type: PERSONAL_DATA_TERMS_SET_VALUE, value });
export const setPersonalDataTermsValid = (error) => ({ type: PERSONAL_DATA_TERMS_SET_VALID, error });

export const setPersonalDataProcessingConsentValue = (value) => ({
    type: PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE,
    value,
});
export const setPersonalDataProcessingConsentValid = (error) => ({
    type: PERSONAL_DATA_PROCESSING_CONSENT_SET_VALID,
    error,
});

export const setChildDataProcessingConsentValue = (value) => ({
    type: CHILD_DATA_PROCESSING_CONSENT_SET_VALUE,
    value,
});
export const setChildDataProcessingConsentValid = (error) => ({
    type: CHILD_DATA_PROCESSING_CONSENT_SET_VALID,
    error,
});

export const setMarketingUseConsentValue = (value) => ({
    type: MARKETING_USE_CONSENT_SET_VALUE,
    value,
});
export const setMarketingUseConsentValid = (error) => ({
    type: MARKETING_USE_CONSENT_SET_VALID,
    error,
});
