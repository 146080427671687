import { createSelector } from "reselect";
import { getRoaQuoteTools } from './index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../reducers/status";

export const getAccidentalDisabilityQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.accidentalDisabilityQuoteTool
);

export const getAccidentalDisabilityQuoteToolForm = createSelector(
    getAccidentalDisabilityQuoteTool,
    accidentalDisabilityQuoteTool => accidentalDisabilityQuoteTool.form
);

const makeGetFieldForm = fieldId => createSelector(
    getAccidentalDisabilityQuoteToolForm,
    form => form[fieldId]
);

export const getAccidentalDisabilityQuoteToolFormValid = createSelector(
    getAccidentalDisabilityQuoteTool,
    accidentalDisabilityQuoteTool => accidentalDisabilityQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getSelectedForm = createSelector(
    getAccidentalDisabilityQuoteTool,
    accidentalDisabilityQuoteTool => accidentalDisabilityQuoteTool.selectedForm
)

export const getRoaCmbObject = createSelector(
    getAccidentalDisabilityQuoteTool,
    accidentalDisabilityQuoteTool => accidentalDisabilityQuoteTool.roaCmbObject
)

export const getIsApiPending = createSelector(
    getAccidentalDisabilityQuoteTool,
    accidentalDisabilityQuoteTool => accidentalDisabilityQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
)
export const getAccidentalDisabilityPremiumAmount = createSelector(
    getAccidentalDisabilityQuoteTool,
    accidentalDisabilityQuoteTool => accidentalDisabilityQuoteTool.accidentalDisabilityPremiumAmount
);

export const getShowError = createSelector(
    getAccidentalDisabilityQuoteTool,
    accidentalDisabilityQuoteTool => accidentalDisabilityQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getCoverAmountValue = makeGetFieldForm('coverAmount');
export const getCoverYearsValue = makeGetFieldForm('coverYearsValue');
export const getGenderValue = makeGetFieldForm('genderInput');
export const getSmokingValue = makeGetFieldForm('smokerInput');
export const getCandidateDOB = makeGetFieldForm('candidateDob');
