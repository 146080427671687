import { FIELD_STATUS, FORM_STATUS } from "../status";

import {
    CUSTOMER_FEEDBACK_SET_VALUE,
    CUSTOMER_QUERY_PARAMETER_SET_VALUE,
    CUSTOMER_FEEDBACK_SET_VALID,
    RESET_CUSTOMER_FEEDBACK_FORM,
    API_PENDING_TRANSITION_SUBMIT_FEEDBACK,
    API_SUCCESS_TRANSITION_SUBMIT_FEEDBACK,
    API_FAILURE_TRANSITION_SUBMIT_FEEDBACK,
    FORM_VALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION,
    FORM_INVALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION
} from "../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm"

import { API_CALL_STATUS } from '../status';

const customerSatisfactionSurveyModel = {
    customerFeedback: {value: null, error: null, status: null},
    customerQueryParams: {}
}

export const initialState = {
    formStatus: FORM_STATUS.INVALID,
    form: customerSatisfactionSurveyModel,
    apiCallStatus: API_CALL_STATUS.IDLE,
}

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch(action.type) {
        // Form Validation
         case FORM_VALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case API_SUCCESS_TRANSITION_SUBMIT_FEEDBACK:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_PENDING_TRANSITION_SUBMIT_FEEDBACK:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };

        case API_FAILURE_TRANSITION_SUBMIT_FEEDBACK:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case CUSTOMER_QUERY_PARAMETER_SET_VALUE:
            return ({
                ...state,
                form: {
                    ...state.form,
                    customerQueryParams: action.value
                },
            });

        case CUSTOMER_FEEDBACK_SET_VALUE:
            return updateFormField('customerFeedback', field => ({
                ...field,
                value: action.value,
            }));

        case CUSTOMER_FEEDBACK_SET_VALID:
            return updateFormField('customerFeedback', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RESET_CUSTOMER_FEEDBACK_FORM: 
            return initialState;
        

        default:
            return state;
    }
}
