import { validateAlphaNumericStringLenient } from  "@om/validation";
import { config } from '@om/validation/src/config';

import {
    setCustomerFeedbackValid,
    CUSTOMER_FEEDBACK_SET_VALUE
} from "../../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm"

import { validationIsEqual } from "../status";

const DIRTY_DEFAULT_EMPTY_ERROR_MESSAGE = "You'll need to enter a customer feedback."
const CLEAN_DEFAULT_EMPTY_ERROR_MESSAGE = "You'll need to provide customer feedback to continue."

let prevValidationAction = null;

export const customerSatisfactionSurveyFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case CUSTOMER_FEEDBACK_SET_VALUE:
                error = validateAlphaNumericStringLenient(
                    action.value, 
                    {
                        min: 2,
                        max: 2000,
                        subject: 'customer feedback',
                        required: true,
                        pronoun: config.PRONOUN.YOUR,
                        retainSubjectCase: false
                    }
                );
                    
                const errorMessage = error === DIRTY_DEFAULT_EMPTY_ERROR_MESSAGE ? CLEAN_DEFAULT_EMPTY_ERROR_MESSAGE : error;
                return setCustomerFeedbackValid(errorMessage);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
}
