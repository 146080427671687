import { BANKING_DETAILS_SET_PREMIUM_FREQUENCY_VALUE, DELETE_HOME_CONTENT_VALUE, DELETE_HOME_OWNER_VALUE, HOME_DETAILS_SET_VALUE_WITHOUT_VALIDATION, SYNC_HOME_PREMIUM_FREQUENCY_VALUE } from '../../actions/allsureInsurance/homeDetails';
import {
    ADD_NEW_HOME_OWNER_VALUE,
    EDIT_HOME_OWNER_VALUE,
    FORM_INVALID_TRANSITION_HOME_DETAILS,
    FORM_VALID_TRANSITION_HOME_DETAILS,
    HOME_DETAILS_SET_VALID,
    HOME_DETAILS_SET_VALUE,
    UPDATE_HOME_DETAILS_AFTER_EDIT,
} from '../../actions/allsureInsurance/homeDetails';

import { FIELD_STATUS, FORM_STATUS } from '../status';
const intialFormDetails = {
    address: { value: null, error: null, status: null },
    otherAddress: { value: null, error: null, status: null },
    numberOfBedrooms: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    numberOfBathrooms: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    guiserSituated: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    
    homeOccupied: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    razorWire: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    
    homehired: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    inRetirement: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    premiumPaid: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    roofMaterialDetails: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    mainWallConstructedOff: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    homeDetailsType: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    insuredDetails: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    },
    insuredWithOM: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    },
    houseValue: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    
    accidentalDamage: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    },
    accidentalDamageAmount:{
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    powerSurge: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    },
    powerSurgeCover:{
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    policyNumber:{
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    }
    // domesticWorker: {
    //     value: null,
    //     error: null,
    //     status: FORM_STATUS.VALID,
    //     displayValue:null,
    // },
    // householdLoss: {
    //     value: null,
    //     error: null,
    //     status: null,
    //     displayValue:null,
    // },
    // claimAmount: {
    //     value: null,
    //     error: null,
    //     status: null,
    //     displayValue:null,
    // },
    // claimedCount: {
    //     value: null,
    //     error: null,
    //     status: null,
    //     displayValue:null,
    // },
    // domesticEmployees:{
    //     value: null,
    //     error: null,
    //     status: null,
    //     displayValue:null,
    // }
};
export const ADDITIONAL_QUOTE = 'additionalQoute';
export const EDIT_QUOTE ='editQuote_';
export const INITIAL_EDIT_KEY='initial_0'
export const initHomeDetails = {
    formStatus: FORM_STATUS.INVALID,
    paymentFrequency:null,
    homeDetailsList: [],
    homeContentList:[],
    editHomeDetailKey: INITIAL_EDIT_KEY,
    form: intialFormDetails,
};

export default (state = initHomeDetails, action) => {
    // const trimField = (value) => (value ? value.trim() : value);
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRANSITION_HOME_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_HOME_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case HOME_DETAILS_SET_VALUE:
            if(action.value.fieldType==='householdLoss'){
                return {
                    ...state,
                    form:{
                        ...state.form,
                        householdLoss:{...state.form.householdLoss,value:action.value.value,displayValue:action.value?.displayValue??action.value.value},
                        claimAmount: {value: null, error: null, status: null,displayValue:null},
                        claimedCount: {value: null, error: null, status: null,displayValue:null},
                    }
                }
            }else if(action.value.fieldType==='address'){
                return {
                    ...state,
                    form:{
                        ...state.form,
                        address:{...state.form.address,value:action.value.value,displayValue:action.value?.displayValue??action.value.value},
                        otherAddress: {value: null, error: null, status: null,displayValue:null}
                    }
                }
            } else if(action.value.fieldType==="insuredDetails"){
                return {
                    ...state,
                    form:{
                        ...state.form,
                        insuredDetails:{...state.form.insuredDetails,value:action.value.value,displayValue:action.value?.displayValue??action.value.value},
                        insuredWithOM: {value: null, error: null, status: null,displayValue:null}
                    }
                }
            }
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
            }));

        case HOME_DETAILS_SET_VALUE_WITHOUT_VALIDATION:   
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
                status: null,
                error: null,
            }));

        case HOME_DETAILS_SET_VALID:
            const { fieldType, error } = action;
            return updateFormField(fieldType, (field) => ({
                ...field,
                error: error,
                status: mapError(error),
            }));
            
        case ADD_NEW_HOME_OWNER_VALUE:
            return { ...state, form: {
                ...intialFormDetails,
                premiumPaid: state.form.premiumPaid
            }, editHomeDetailKey: ADDITIONAL_QUOTE };

        case EDIT_HOME_OWNER_VALUE:
            return {
                ...state,
                form: state.homeDetailsList[+action.value.value],
                editHomeDetailKey: EDIT_QUOTE + action.value.value,
            };


        case DELETE_HOME_OWNER_VALUE:
            state.homeDetailsList.splice(action.value, 1);
            const modifieArray=state.homeDetailsList
            return { ...state, homeDetailsList:[...modifieArray] };

                 
        case UPDATE_HOME_DETAILS_AFTER_EDIT:
            const details=[...state.homeDetailsList]
        
            state.editHomeDetailKey === ADDITIONAL_QUOTE
                ? details.push(state.form)
                : details.splice(state.editHomeDetailKey.split('_')[1], 1, state.form);
            return { ...state, homeDetailsList:details , editHomeDetailKey: INITIAL_EDIT_KEY };

        
        case SYNC_HOME_PREMIUM_FREQUENCY_VALUE:
            return {
                ...state,
                paymentFrequency:action.value
            }
        default:
            return state;
    }
};
