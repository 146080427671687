import { createSelector } from "reselect";
import { getCovidSelfAssessment } from "./index";
import { FORM_STATUS } from "../../reducers/status";

export const getTravelHistory = createSelector(
    getCovidSelfAssessment,
    covidSelfAssessment => covidSelfAssessment.travelHistory
);

export const getTravelHistoryFormIsValid = createSelector(
    getTravelHistory,
    travelHistory => travelHistory.formStatus === FORM_STATUS.VALID,
);

export const getTravelHistoryForm = createSelector(
    getTravelHistory,
    travelHistory => travelHistory.form,
);

const makeGetField = fieldId => createSelector(
    getTravelHistoryForm,
    travelHistoryForm => travelHistoryForm[fieldId]
);

export const getTravelledRecently = makeGetField('travelledRecently');
export const getRecentCovidContact = makeGetField('recentCovidContact');
