import axios from "axios";
import { sendConfirmDetailsSubmitApplicationSuccess, sendConfirmDetailsSubmitApplicationFailure } from "../../../actions/easiplusFuneralPlan/confirmDetails";
import { getEfpSubmission} from "../../../selectors/easiplusFuneralPlan/submission";
import { setRequestId } from "../../../actions/easiplusFuneralPlan/sessionCamVariables";

export const submitEfpApplicationService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const payload = getEfpSubmission(state);

    (async () => {
        const response = await submitApplication(payload);
        if (response && (response.status === 200) && response.data.isValid) {
            store.dispatch(sendConfirmDetailsSubmitApplicationSuccess(response.data.application && response.data.application.policyNumber));
        } else {
            store.dispatch(sendConfirmDetailsSubmitApplicationFailure());
        }

        if (response && 'data' in response && 'requestId' in response.data) {
            store.dispatch(setRequestId(response.data.requestId));
        }
    })();

    return result;
};

export const submitApplication = async payload => {
    const url = '/om-api/easiplus-funeral-plan/submit-application';
    const config = {headers: {'Content-Type': 'application/json'}};
    try {
        const response = await axios.post(url, payload, config)
        return response;
    } catch (error) {
        return null;
    }
};
