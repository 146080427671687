// Transitions
export const FORM_VALID_TRANSITION_INSURANCE_TYPE = 'allsureInsurance/insuranceType/transition/formValid';
export const FORM_INVALID_TRANSITION_INSURANCE_TYPE = 'allsureInsurance/insuranceType/transition/formInvalid';

export const PERSONAL_DETAILS_FORM_SET_VALUE = 'allsureInsurance/insuranceType/transition/formValue';
export const setPersonalDetailFormValues = () => ({
    type: PERSONAL_DETAILS_FORM_SET_VALUE,
});

export const transitionDeceasedDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_INSURANCE_TYPE }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_INSURANCE_TYPE }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_INSURANCE_TYPE = 'allsureInsurance/insuranceType/transition/resetState';
export const resetPersonalDetails = () => ({
    type: RESET_STATE_TRANSITION_INSURANCE_TYPE,
});

// Events

export const ALLSURE_INSURANCE_INSURANCE_TYPE = 'allsureInsurance/insuranceType/';
export const INSURANCE_TYPE_SET_VALUE = ALLSURE_INSURANCE_INSURANCE_TYPE + 'setValue/';
export const INSURANCE_TYPE_SET_VALID = ALLSURE_INSURANCE_INSURANCE_TYPE + 'setValid/';

export const TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE = INSURANCE_TYPE_SET_VALUE + '/toogle/privacyNotice';

export const tooglePrivacyNotice = (value) => ({ type: TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE, value });
export const setInsuranceTypeValue = (value) => ({ type: INSURANCE_TYPE_SET_VALUE, value });
export const setInsuranceTypeValid = (error, fieldType) => ({ type: INSURANCE_TYPE_SET_VALID, error, fieldType });
