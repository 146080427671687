import {
    SET_JHBSHAREPRICE,
    SET_LDNSHAREPRICE,
    SET_JHBSHAREPRICEFETCHING,
    SET_LDNSHAREPRICEFETCHING,
    SET_INDICATORS, SET_COMMODITIES_TOGGLE, SET_CURRENCIES_TOGGLE, SET_OFFSHORE_TOGGLE, SET_LOCAL_TOGGLE
} from '../../actions/publicWeb/sharePrice'

export const initialState = {
    jhbSharePrice: {
        lastSuccessfulCall: null,
        lastAttempt: null,
        sharePrice: null
    },
    ldnSharePrice: {
        lastSuccessfulCall: null,
        lastAttempt: null,
        sharePrice: null
    },
    indicators: {
        lastSuccessfulCall: null,
        lastAttempt: null,
        reutersUpdateDate: '',
        indicators: null
    },
    jhbSharePriceFetching: false,
    ldnSharePriceFetching: false,
    commoditiesExpanded: false,
    currenciesExpanded: false,
    localExpanded: false,
    offshoreExpanded: false,
};

export default function (state = initialState, action) {
    const updateSharePriceField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    switch (action.type) {
        case SET_JHBSHAREPRICE:
            return updateSharePriceField('jhbSharePrice', field => ({
                ...field,
                lastSuccessfulCall: action?.value?.lastSuccessfulCall,
                lastAttempt: action?.value?.lastAttempt,
                sharePrice: action?.value?.sharePrice
            }));
        case SET_LDNSHAREPRICE:
            return updateSharePriceField('ldnSharePrice', field => ({
                ...field,
                lastSuccessfulCall: action?.value?.lastSuccessfulCall,
                lastAttempt: action?.value?.lastAttempt,
                sharePrice: action?.value?.sharePrice
            }));
        case SET_JHBSHAREPRICEFETCHING:
            return {
                ...state,
                jhbSharePriceFetching: action.value.detail
            };
        case SET_LDNSHAREPRICEFETCHING:
            return {
                ...state,
                ldnSharePriceFetching: action.value.detail
            };
        case SET_INDICATORS:
            return updateSharePriceField('indicators', field => ({
                ...field,
                lastSuccessfulCall: action?.value?.lastSuccessfulCall,
                lastAttempt: action?.value?.lastAttempt,
                sharePrice: action?.value?.sharePrice,
                reutersUpdateDate: action?.value?.reutersUpdateDate,
                indicators: action?.value?.indicators
            }));
        case SET_COMMODITIES_TOGGLE:
            return {
                ...state,
                commoditiesExpanded: action.value
            };
        case SET_CURRENCIES_TOGGLE:
            return {
                ...state,
                currenciesExpanded: action.value
            };
        case SET_OFFSHORE_TOGGLE:
            return {
                ...state,
                offshoreExpanded: action.value
            };
        case SET_LOCAL_TOGGLE:
            return {
                ...state,
                localExpanded: action.value
            };
        default:
            return state;
    }
}
