import { combineReducers } from 'redux';
import routing from './routing';
import quote from './quote';
import personalDetails from './personalDetails';
import payments from './payment';
import carDetails from './carDetails';
import documents from './Documents'
import declarations from './declarations';
import nextOfKin from './nextOfKin';
import payloadCollection from './payloadCollection';
import	agent from './agent';
import buildingDetails from './buildingDetails';

export const domesticInsurance = combineReducers({
	routing,
	quote,
	personalDetails,
	payments,
	carDetails,
	documents,
	declarations,
	nextOfKin,
	payloadCollection,
	agent,
    buildingDetails
});
