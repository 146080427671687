import { onFieldChangeStateComparison } from '../onFieldChangeStateComparison';
import { simpleFieldTracking } from '../formTracking';
import {
    ON_BEHALF_OF_CHILD_SET_VALUE,
    LEGAL_GUARDIAN_SET_VALUE,
    HAS_OTHER_TAX_FREE_PLAN_SET_VALUE,
    SAVING_FOR_SET_VALUE,
    YOUR_GOAL_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/investmentSetupInitial';

import {
    getOnBehalfOfChild,
    getLegalGuardian,
    getHasOtherTaxFreePlan,
    getSavingFor,
    getYourGoal,
} from '../../../selectors/taxFreeSavingsAccount/investmentSetupInitial';

export const investmentSetupInitialFieldTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case ON_BEHALF_OF_CHILD_SET_VALUE:
                sendError = false;
                return getOnBehalfOfChild;
            case LEGAL_GUARDIAN_SET_VALUE:
                sendError = false;
                return getLegalGuardian;
            case HAS_OTHER_TAX_FREE_PLAN_SET_VALUE:
                sendError = false;
                return getHasOtherTaxFreePlan;
            case SAVING_FOR_SET_VALUE:
                sendError = false;
                return getSavingFor;
            case YOUR_GOAL_SET_VALUE:
                return getYourGoal;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);
    if (field) simpleFieldTracking({ action, field, sendValue, sendError });

    return result;
};