import { APPLICATION_NOW_SUCCESS, APPLICATION_NOW_FAIL, PAGE_INDEXES, SET_CAN_PROCEED, RETURN_TO_PRODUCT_PAGE, OFFER_QUOTE_NOT_AVAILABLE, OFFER_QUOTE_FAIL, MOVE_TO_PREVIOUS_PAGE, MOVE_TO_NEXT_PAGE, MOVE_TO_FAILURE_PAGE, OTP_SEND_SUCCESS, OTP_SEND_FAIL, OTP_RESEND_SUCCESS, OTP_VERIFY_FAIL, SKIP_TO_PAGE, OTP_VERIFY_SUCCESS, OTP_NOT_VALID, ADD_ADDRESS_REQUEST_SUCCESS, ADD_ADDRESS_REQUEST_FAIL, FILE_UPLOAD_REQUEST, FILE_API_UPLOAD_FAILED, PageIndexRef, MAKE_APPLICATION_REQUEST_FAIL, ACCEPT_OFFER_REQUEST_FAIL, SEND_PAGE_REQUESTS, RESET_PAGE_REQUESTS, DO_PAGE_REQUEST, DO_SUCCESSFUL_PAGE_REQUEST, LOOKUP_DATA_REQUEST, OTP_RESEND_REQUEST, FILE_UPLOAD_ERROR, FILE_UPLOAD_COMPLETE, DOCUMENT_UPLOAD_SKIP, APPLICATION_UTM, OFFER_QUOTE_DECLINED } from "../../actions/digitalOnboarding/types";
import { LOOKUP_DATA_FAIL, LOOKUP_DATA_SUCCESS } from "../../actions/digitalOnboarding/types";

const isASteppedPage = page => page.stepTitle;
const getStepMetadata = (steps, index) => steps.find(s => s.index === index);

const initialiseStepMetadata = (s, idx) =>({
    ...s,
    isComplete: false,
    isActive: idx === 0
});

const updateSteps = (steps, currentPage, newPage) => {
    if (currentPage === newPage) return [steps, currentPage];

    const newSteps = [...steps];

    const newPageMeta = getStepMetadata(newSteps, newPage);
    const jumpToStep = newPageMeta ? newPage : currentPage;

    let activeIndex = 0;
    newSteps.forEach((s, idx) => {
        s.isComplete = s.index < jumpToStep;
        s.isActive = s.index === jumpToStep;
        activeIndex = s.isActive ? idx : activeIndex;
    });
    return [newSteps, activeIndex + 1];
}

const getUpdatedStepperForNewPage = (currentSteps, activePage, nextPageId) => {
    let newStepDetails = {};
    if (nextPageId !== null) {
        const [steps, activeStep] = updateSteps(currentSteps, activePage, nextPageId);
        newStepDetails = {
            activePageIndex: nextPageId,
            isStepperVisible: nextPageId > 0 && nextPageId !== PageIndexRef.SUCCESS_PAGE,
            steps,
            activeStep
        };
    }
    return newStepDetails;
}

export const initRouting = {
    activePageIndex: 0,
    isFetching: false,
    canProceed: true,
    isStepperVisible: false,
    isFooterVisible: true,
    steps: PAGE_INDEXES.filter(isASteppedPage).map(initialiseStepMetadata),
    activeStep: 0,
    returnToProductPage: false,
    apiError: false,
    allowNavigation: false,
    documentsUploadSkip: false,
};

export default (state = initRouting, action) => {
    const stateBuilder = state => values => {
        const pendingPageRequests = action.type.startsWith(DO_SUCCESSFUL_PAGE_REQUEST)
            ? state.pendingPageRequests?.slice(1)
            : state.pendingPageRequests;

        return {
            ...state,
            pendingPageRequests,
            apiError: false,
            isFetching: false,
            returnToProductPage: false,
            allowNavigation: true,
            isFooterVisible: state.activePageIndex !== PageIndexRef.SUCCESS_PAGE,
            ...values
        };
    }

    const buildState = stateBuilder(state);

    switch (action.type) {
        case MOVE_TO_NEXT_PAGE: {
            let nextId = state.documentsUploadSkip ? 8 : PAGE_INDEXES[state.activePageIndex].nextPageId;
            const stepper = getUpdatedStepperForNewPage(state.steps, state.activePageIndex, nextId);
            return buildState(stepper);
        }
        case MOVE_TO_PREVIOUS_PAGE: {
            const stepper = getUpdatedStepperForNewPage(state.steps, state.activePageIndex, PAGE_INDEXES[state.activePageIndex].previousPageId);
            return buildState(stepper);
        }
        case MOVE_TO_FAILURE_PAGE: {
            const stepper = getUpdatedStepperForNewPage(state.steps, state.activePageIndex, PAGE_INDEXES[state.activePageIndex].jumpToPageIdOnFailure);
            return buildState(stepper);
        }
        case SKIP_TO_PAGE: {
            const stepper = getUpdatedStepperForNewPage(state.steps, state.activePageIndex, PAGE_INDEXES[state.activePageIndex].skipToPageId);
            return buildState({ ...stepper, pendingPageRequests: [] });
        }

        case APPLICATION_NOW_SUCCESS:
            return buildState({ canProceed: action.data?.proceedWithApplication });

        case OTP_RESEND_REQUEST:
        case LOOKUP_DATA_REQUEST:
            return buildState({ isFetching: true });

        case LOOKUP_DATA_SUCCESS:
            return buildState({ isFetching: false });

        case APPLICATION_NOW_FAIL:
        case OFFER_QUOTE_FAIL:
        case LOOKUP_DATA_FAIL:
        case OTP_SEND_FAIL:
        case OTP_VERIFY_FAIL:
        case ADD_ADDRESS_REQUEST_FAIL:
        case FILE_API_UPLOAD_FAILED:
        case MAKE_APPLICATION_REQUEST_FAIL:
        case ACCEPT_OFFER_REQUEST_FAIL:
            return buildState({ canProceed: false, apiError: true });

        case OTP_RESEND_SUCCESS:
        case OTP_NOT_VALID:
        case OFFER_QUOTE_DECLINED:
            return buildState({ canProceed: false });

        case OFFER_QUOTE_NOT_AVAILABLE:
        case OTP_SEND_SUCCESS:
        case OTP_VERIFY_SUCCESS:
        case ADD_ADDRESS_REQUEST_SUCCESS:
        case FILE_UPLOAD_COMPLETE:
            return buildState({ canProceed: true });

        case SET_CAN_PROCEED:
            return buildState({ canProceed: action.canProceed });

        case RETURN_TO_PRODUCT_PAGE:
            return buildState({ returnToProductPage: true, allowNavigation: false });

        case SEND_PAGE_REQUESTS:
            return buildState({ pendingPageRequests: action.requests });

        case RESET_PAGE_REQUESTS:
            return buildState({ pendingPageRequests: [], apiError: state.apiError });

        case FILE_UPLOAD_ERROR:
            return buildState({ canProceed: false });

        case DOCUMENT_UPLOAD_SKIP:
            return buildState({ documentsUploadSkip: action.boolean });

        default: {
            if (action.type.startsWith(DO_PAGE_REQUEST)) {
                return buildState({ isFetching: true });
            }

            break;
        }
    }

    return state;
}
