export const PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM = 'personalAccident/nextOfKinForm/';

export const PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM + 'setValue/';
export const PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM + 'setValid/';

export const FORM_VALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM = 'personalAccident/nextOfKinForm/transition/formValid';
export const FORM_INVALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM = 'personalAccident/nextOfKinForm/transition/formInValid';

export const REINITIALIZE = 'personalAccident/nextOfKinForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_NEXT_OF_KIN_FORM = 'personalAccident/nextOfKinForm/transition/dropOffLead';
export const dropOffLeadNextOfKin = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_NEXT_OF_KIN_FORM,
    value,
});

export const transitionPersonalAccidentNextOfKinForm = {
    formValid: () => ({
        type: FORM_VALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_NEXT_OF_KIN_FORM = `${PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_NEXT_OF_KIN_FORM = `${PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_NEXT_OF_KIN_FORM = `${PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionNextOfKinForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_NEXT_OF_KIN_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_NEXT_OF_KIN_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_NEXT_OF_KIN_FORM,
    }),
};

//calculated Data
export const COLLECT_NEXT_OF_KIN_FORM_DATA = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'collectNextOfKinData';
export const SUBMIT_NEXT_OF_KIN_SUCCESS = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'success';
export const SUBMIT_NEXT_OF_KIN_FAILURE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'failure';

export const collectNextOfKinData = () => ({
    type: COLLECT_NEXT_OF_KIN_FORM_DATA,
});
export const submitNextOfKinSuccess = (value) => ({
    type: SUBMIT_NEXT_OF_KIN_SUCCESS,
    value,
});
export const submitNextOfKinFailure = (error) => ({
    type: SUBMIT_NEXT_OF_KIN_FAILURE,
    error,
});

// Form Values

export const FIRST_NAME_SET_VALUE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID + 'firstName';

export const LAST_NAME_SET_VALUE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'lastName';
export const LAST_NAME_SET_VALID = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID + 'lastName';

export const RELATIONSHIP_SET_VALUE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'relationship';
export const RELATIONSHIP_SET_VALID = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID + 'relationship';
export const ID_NUMBER_SET_VALUE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID + 'idNumber';
export const EMAIL_SET_VALUE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID + 'email';
export const ADDRESS_SET_VALUE = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE + 'address';
export const ADDRESS_SET_VALID = PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID + 'address';

// action creators

export const setIdNumberValue = (value) => ({
    type: ID_NUMBER_SET_VALUE,
    value,
});

export const setIdNumberValid = (error) => ({
    type: ID_NUMBER_SET_VALID,
    error,
});

export const setAddressValue = (value) => ({
    type: ADDRESS_SET_VALUE,
    value,
});

export const setAddressValid = (error) => ({
    type: ADDRESS_SET_VALID,
    error,
});

export const setFirstNameValue = (value) => ({
    type: FIRST_NAME_SET_VALUE,
    value,
});
export const setFirstNameValid = (error) => ({
    type: FIRST_NAME_SET_VALID,
    error,
});

export const setLastNameValue = (value) => ({
    type: LAST_NAME_SET_VALUE,
    value,
});
export const setLastNameValid = (error) => ({
    type: LAST_NAME_SET_VALID,
    error,
});

export const setRelationshipValue = (value) => ({
    type: RELATIONSHIP_SET_VALUE,
    value,
});
export const setRelationshipValid = (error) => ({
    type: RELATIONSHIP_SET_VALID,
    error,
});

export const setEmailValue = (value) => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = (error) => ({
    type: EMAIL_SET_VALID,
    error,
});
