import axios from 'axios';
import {
	getIsApiPending,
	getQuotationForm, getVehicleValuation,
} from '../../../selectors/roaCarInsurance/quotation';
import {
	apiTransition,
	calculateRevaluationSuccess,
} from '../../../actions/roaCarInsurance/quotation';

export const varianceCheckerService = (store, next, action) => {
	(async () => {
		if (!getIsApiPending(store.getState())) {
			store.dispatch(apiTransition.pending());
			try {
				const resp = await reCalculate(store.getState());
				store.dispatch(calculateRevaluationSuccess(resp.data));
				store.dispatch(apiTransition.success());
			} catch (e) {
				console.error(e);
				store.dispatch(apiTransition.failure(e.message));
			}
		}
	})();
	return next(action);
};


const varianceCheckerPayload = (state) => {
	const formData = getQuotationForm(state);
	const vehicleValuation = getVehicleValuation(state)
	return {
		si: formData.varianceValue.value,
		session_id: vehicleValuation.value.session_id,
	}
};

const reCalculate = async (state) => {
	try {
		const url = '/om-api/roa-car-insurance/variance-checker';
		return await axios.post(url, varianceCheckerPayload(state));
	} catch (e) {
		console.error(e);
		throw e;
	}
};
