import {
    CALL_ME_BACK_OBJECT_DETAILS,
    FORM_SUCCESS_TRANSITION_CALL_ME_BACK,
    setPrivacyPolicyURLValue,
    setActiveEntry,
    setProductNameValue,
    setToEmailValue,
    setEmailCampaignCode,
    transitionCallMeBack,
} from '../../../actions/publicWeb/callMeBack';
import {
    TFSA_PAGE_URLS,
    PERSONAL_SOLUTIONS_URL,
    TFSA_TOP_UP_CMB_CAMPAIGN,
    PRODUCT_DETAIL_PAGE,
} from '../../../actions/taxFreeSavingsAccount/types';
import {
    getFirstName,
    getSurname,
    getEmail,
    getProductName,
    getPhoneNumber,
    getPostalCode,
    getAdditionalNumber,
    getLocationComments,
    getEnabledAdditionalFields,
    getEmailCampaignCode,
} from '../../../selectors/publicWeb/callMeBack';
import { FIELD_STATUS } from "../../../reducers/status";
import { getIsErrorModalOpen } from '../../../selectors/taxFreeSavingsAccount/referenceData';

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const extraFieldValid = (enabledAdditionalFields, key, value) => {
    return !enabledAdditionalFields.includes(key) || isValid(value);
};

const validateCmbForm = cmbState => {
    const firstName = getFirstName(cmbState);
    const surname = getSurname(cmbState);
    const phoneNumber = getPhoneNumber(cmbState);
    const productName = getProductName(cmbState);
    const email = getEmail(cmbState);
    const postalCode = getPostalCode(cmbState);
    const additionalNumber = getAdditionalNumber(cmbState);
    const locationComments = getLocationComments(cmbState);

    const standardFieldsValid =
        isValid(firstName) &&
        isValid(surname) &&
        isValid(phoneNumber);

    if (productName.value === 'find-an-adviser') {
        return isValid(email) && standardFieldsValid;
    }

    const enabledAdditionalFields = getEnabledAdditionalFields(cmbState);
    const extraFieldsValid =
        extraFieldValid(enabledAdditionalFields, 'Additional Number', additionalNumber) &&
        extraFieldValid(enabledAdditionalFields, 'Email Address', email);

    //Postal code is not a compulsory field, so just checking that if there is a postal code that it be valid
    const postalCodeValid = postalCode.value ? isValid(postalCode) : true;

    return standardFieldsValid && extraFieldsValid && postalCodeValid;
};


export const callMeBackFormRules = (store, next, action) => {
    const result = next(action);

    const cmbValid = validateCmbForm(store.getState());

    // Form valid
    store.dispatch(cmbValid
        ? transitionCallMeBack.formValid()
        : transitionCallMeBack.formInvalid());

    if (action.type === CALL_ME_BACK_OBJECT_DETAILS) {
        const cmbDetails = action.value;
        if (cmbDetails) {
            store.dispatch(setPrivacyPolicyURLValue(cmbDetails.privacy_policy_url));
            store.dispatch(setActiveEntry(cmbDetails));
            store.dispatch(setProductNameValue(cmbDetails.call_me_back_product));
            store.dispatch(setToEmailValue(cmbDetails.call_me_back_email));
            store.dispatch(setEmailCampaignCode(cmbDetails.call_me_back_email_campaign_code));
        }
    }

    // if CMB submitted for Top up TFSA application, redirect user after 2s
    if (action.type === FORM_SUCCESS_TRANSITION_CALL_ME_BACK) {
        const campaignType = getEmailCampaignCode(store.getState());
        const isContributionPage = window.location.pathname === TFSA_PAGE_URLS.INVESTMENT_SETUP_CONTRIBUTION;
        if (campaignType === TFSA_TOP_UP_CMB_CAMPAIGN) {
            setTimeout(()=> {
                location.href = PERSONAL_SOLUTIONS_URL;
            }, 2000);
        } else if (isContributionPage && getIsErrorModalOpen(store.getState())) {
            setTimeout(()=> {
                location.href = PRODUCT_DETAIL_PAGE;
            }, 2000);
        }
    }

    return result;
};
