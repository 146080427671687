import { transitionMvestTool } from '../../../actions/roaQuoteTools/mvestQuoteTool';
import {
    getMvestQuoteToolForm,
    getSelectedForm,
} from '../../../selectors/roaQuoteTools/mvestQuoteTool';
import { FIELD_STATUS } from '../../../reducers/status';

export const mvestQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const mvestQuoteToolForm = getMvestQuoteToolForm(store.getState());

    const selectedForm = getSelectedForm(store.getState());

    const mvestQuoteToolValid = validateMvestQuoteToolFormDetails(
        mvestQuoteToolForm,
        selectedForm,
    );

    const formAction = mvestQuoteToolValid
        ? transitionMvestTool.formValid()
        : transitionMvestTool.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateMvestQuoteToolFormDetails = (formFields, selectedForm) => {
    if (selectedForm === 'form-one') {
        const { paymentTerm, paymentFrequency, premiumAmount } = formFields;

        return (
            paymentTerm &&
            paymentTerm.status === FIELD_STATUS.VALID &&
            paymentFrequency &&
            paymentFrequency.status === FIELD_STATUS.VALID &&
            premiumAmount &&
            premiumAmount.status === FIELD_STATUS.VALID
        );
    }
    return true;
};
