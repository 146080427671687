import { createSelector } from 'reselect';
import { getPersonalLoansCalculator } from './index';
import { getLoanAmountOptions, getRepaymentDurationOptions } from './referenceData';

import { OPTIONAL_FIELDS, validAmountBorrowedDetails, validRepaymentDurationDetails } from '../../middleware/rulesEngine/personalLoansCalculator/calculator';


export const getCalculator = createSelector(
    getPersonalLoansCalculator,
    personalLoansCalculator => personalLoansCalculator.calculator
)

export const getCalculatorForm = createSelector(
    getCalculator,
    calculator => calculator.form
)

export const getCalculationResults = createSelector(
    getCalculator,
    calculator => calculator.calculationResults
)

export const getMinInstallment = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.minInstallment : null
)

export const getMaxInstallment = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.maxInstallment : null
)

export const getMinInterestRate = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.minInterestRate : null
)

export const getMaxInterestRate = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.maxInterestRate : null
)

export const getMinCreditLife = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.minCreditLife : null
)

export const getMaxCreditLife = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.maxCreditLife : null
)

export const getServiceFee = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.serviceFee : null
)

export const getInitiationFee = createSelector(
    getCalculationResults,
    calculationResults => calculationResults.results ? calculationResults.results.initiationFee : null
)

const makeField = (fieldId) => createSelector(
    getCalculatorForm,
    calculatorForm => calculatorForm[fieldId]
)

export const getAmountBorrowed = makeField('amountBorrowed');
export const getOtherAmountBorrowed = makeField('otherAmountBorrowed');
export const getRepaymentDuration = makeField('repaymentDuration');
export const getOtherRepaymentDuration = makeField('otherRepaymentDuration');

export const getOwnAmountMethod = createSelector(
    getLoanAmountOptions,
    (loanAmountOptions) => loanAmountOptions ? loanAmountOptions.find((option) => option.description === OPTIONAL_FIELDS.ownAmount) : ""
)

export const getOwnRepaymentDurationMethod = createSelector(
    getRepaymentDurationOptions,
    (repaymentDurationOptions) => repaymentDurationOptions ? repaymentDurationOptions.find((option) => option.description === OPTIONAL_FIELDS.ownNumberOfMonths) : ""
)

export const getRepaymentTerm = createSelector(
    getRepaymentDuration,
    getOtherRepaymentDuration,
    getRepaymentDurationOptions,
    (repaymentDuration, otherRepaymentDuration, repaymentDurationOptions) => {
        if (otherRepaymentDuration.value !== null) {
            return otherRepaymentDuration.value;
        }
        return getReferenceDataValue(repaymentDurationOptions, repaymentDuration.value);
    }
)

export const getCalculateButtonDisabled = createSelector(
    getCalculator,
    getRepaymentDuration,
    getOtherRepaymentDuration,
    getRepaymentDurationOptions,
    (calculator, repaymentDuration, getOtherRepaymentDuration, getRepaymentDurationOptions) => {
        if (calculator.showLoanRepaymentDurationForm && validRepaymentDurationDetails(
            repaymentDuration,
            getOtherRepaymentDuration,
            getRepaymentDurationOptions
        )) {
            return false;
        }
        return true;
    }
)

export const getNavigationButtonDisabled = createSelector(
    getCalculator,
    getAmountBorrowed,
    getOtherAmountBorrowed,
    getLoanAmountOptions,
    (calculator, amountBorrowed, otherAmountBorrowed, loanAmountOptions) => {
        if (!calculator.showLoanRepaymentDurationForm && validAmountBorrowedDetails(
            amountBorrowed,
            otherAmountBorrowed,
            loanAmountOptions
        )) {
            return false;
        } else if (calculator.showLoanRepaymentDurationForm) {
            return false;
        }
        return true;
    }
)

export const getNavigationButtonText = createSelector(
    getCalculator,
    (calculator) => calculator.showLoanRepaymentDurationForm ? "Back" : "Next"
)

export const getRepaymentDurationHintText = createSelector(
    getAmountBorrowed,
    getOtherAmountBorrowed,
    getLoanAmountOptions,
    getRepaymentDurationOptions,
    getOwnAmountMethod,
    (amountBorrowed, otherAmountBorrowed, loanAmountOptions, repaymentDurationOptions, ownAmountMethod) => {
        let value = 0;
        if (amountBorrowed.value === ownAmountMethod.value) {
            value = otherAmountBorrowed.value;
        } else {
            loanAmountOptions.forEach((amountOptions) => {
                if (amountOptions.code === amountBorrowed.value) {
                    value = amountOptions.value
                }
            })
        }

        if (repaymentDurationOptions.length > 1) {
            if (value >= 3000 && value <= 8000) {
                const availableDurationsLessThan12 = repaymentDurationOptions.filter(
                    (duration) => duration.value < 12
                );
                return buildWhispertext(
                    repaymentDurationOptions[
                        repaymentDurationOptions.length - 4
                    ].value,
                    repaymentDurationOptions[
                        repaymentDurationOptions.length - 2
                    ].value,
                    availableDurationsLessThan12
                );
            } else {
                return buildWhispertext(repaymentDurationOptions[0].value,
                    repaymentDurationOptions[repaymentDurationOptions.length - 2].value)
            }
        }
    }
)

export const getInterestRateText = createSelector(
    getMinInterestRate,
    getMaxInterestRate,
    (minInterestRate, maxInterestRate) => {
        if (minInterestRate === maxInterestRate) {
            return `${maxInterestRate}%`;
        }
        return `${minInterestRate}% - ${maxInterestRate}%`;
    }
)

export const getMinTotalInstallment = createSelector(
    getMinInstallment,
    getServiceFee,
    getMinCreditLife,
    (minInstallment, serviceFee, minCreditLife) => {
        return parseFloat(minInstallment + serviceFee + minCreditLife).toFixed(2);
    }
)

export const getMaxTotalInstallment = createSelector(
    getMaxInstallment,
    getServiceFee,
    getMaxCreditLife,
    (maxInstallment, serviceFee, maxCreditLife) => {
        return parseFloat(maxInstallment + serviceFee + maxCreditLife).toFixed(2);
    }
)

export const getMinTotalRepayment = createSelector(
    getMinTotalInstallment,
    getRepaymentDuration,
    getOtherRepaymentDuration,
    getRepaymentDurationOptions,
    (minTotalInstallment, repaymentDuration, otherRepaymentDuration, repaymentDurationOptions) => {
        if (otherRepaymentDuration.value === null) {
            const duration = getReferenceDataValue(repaymentDurationOptions, repaymentDuration.value);
            return parseFloat(duration * minTotalInstallment).toFixed(2);
        }
        return parseFloat(otherRepaymentDuration.value * minTotalInstallment).toFixed(2);
    }

)

export const getMaxTotalRepayment = createSelector(
    getMaxTotalInstallment,
    getRepaymentDuration,
    getOtherRepaymentDuration,
    getRepaymentDurationOptions,
    (maxTotalInstallment, repaymentDuration, otherRepaymentDuration, repaymentDurationOptions) => {
        if (otherRepaymentDuration.value === null) {
            const duration = getReferenceDataValue(repaymentDurationOptions, repaymentDuration.value);
            return parseFloat(duration * maxTotalInstallment).toFixed(2);
        }
        return parseFloat(otherRepaymentDuration.value * maxTotalInstallment).toFixed(2);
    }

)

const getReferenceDataValue = (referenceArray, value) => {
    if (referenceArray && value) {
        const data = referenceArray.find((data) => data.code === value);
        return data.value;
    }
    return " ";
}

const buildWhispertext = (periodMinTerm, periodMaxTerm, dropDownDurationValues = []) => {
    if (dropDownDurationValues.length === 0) {
        return `Select a period between ${periodMinTerm} Months and ${periodMaxTerm} Months`;
    }
    return `Select a period of either ${dropDownDurationValues.map(
        (duration) => duration.description
    )} or between ${periodMinTerm} Months and ${periodMaxTerm} Months `;
}