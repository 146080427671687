import {
    BACK_TRANSITION_REPAYMENT_DURATION_DETAILS,
    CONTINUE_TRANSITION_LOAN_AMOUNT_DETAILS,
    PERSONAL_LOANS_CALCULATOR_SET_VALID,
    PERSONAL_LOANS_CALCULATOR_SET_VALUE,
    RESET_CALCULATOR_FORM,
} from "../../../actions/personalLoansCalculator/calculator";
import {personalLoansCalculatorContinueRules, personalLoansCalculatorRulesEngine} from "./calculator";

export const personalLoansCalculatorRules = (store, next, action) => {
    if (action.type.startsWith(PERSONAL_LOANS_CALCULATOR_SET_VALUE) ||
        action.type.startsWith(PERSONAL_LOANS_CALCULATOR_SET_VALID)) {
        return personalLoansCalculatorRulesEngine(store, next, action);
    }

    if (action.type === CONTINUE_TRANSITION_LOAN_AMOUNT_DETAILS ||
        action.type === BACK_TRANSITION_REPAYMENT_DURATION_DETAILS ||
        action.type === RESET_CALCULATOR_FORM) {
        return personalLoansCalculatorContinueRules(store, next, action);
    }

    return null;
};
