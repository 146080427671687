import { validationIsEqual } from '../status';
import { validateDateOfBirth, validateName } from '@om/validation';
import {
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    DOB_SET_VALUE,
    STREET_ADDRESS_SET_VALUE,
    CITY_SET_VALUE,
    SUBURB_SET_VALUE,
    setFirstNameValid,
    setLastNameValid,
    setDOBValid,
    setCityValid,
    setSuburbValid,
    setStreetAddressValid,
} from '../../../actions/personalAccident/childDetails';

let prevValidationAction = null;
export const childDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });

                //console.log(setFirstNameValid(action.index, error));

                return setFirstNameValid(action.index, error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });
                return setLastNameValid(action.index, error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 0, max: 18 });
                return setDOBValid(action.index, error);

            case STREET_ADDRESS_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Street Address',
                });
                return setStreetAddressValid(action.index, error);

            case CITY_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Street Address',
                });
                return setCityValid(action.index, error);

            case SUBURB_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Suburb',
                });
                return setSuburbValid(action.index, error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
