import { config } from '../config/index.js';
import { validateNumber } from './number-validator.js';
import { mapToDateParts } from '../helpers/validation-date-helper.js';
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { getAge } from './date-of-birth-validator.js';

export function validateCoverYears(
	value,
	{
		min,
		max,
		termExpiry,
		dateValue,
		required = true,
		pronoun = config.PRONOUN.YOUR,
	} = {}
) {
	const date = mapToDateParts(dateValue);
	const age = getAge(date);
	const termExpiryValue = Number(age) + Number(value);
	const maxYearsCovered = Number(termExpiry) - age;

	if (termExpiryValue > termExpiry && required) {
		return OmValidationMessageHelper.getPolicyExpireMsg(
			maxYearsCovered,
			pronoun
		);
	}
	return validateNumber(value, { min, max });
}
