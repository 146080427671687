import { atRetirementPersistedAt } from './atRetirement/atRetirmentPersistedAt';
import { raOptimalPersistedAt } from './raOptimal/raOptimalPersistedAt';
import { cvsPersistedAt } from "./customerVerificationService/cvsPersistedAt";
import { easiplusFuneralPlanPersistedAt } from "./easiplusFuneralPlan/easiplusFuneralPlanPersistedAt";
import { isArcAction } from "./atRetirement";
import { isCvsAction } from "./customerVerificationService";
import { isRaAction } from "./raOptimal";
import { isEfpAction } from "./easiplusFuneralPlan";
import { isTfsaAction } from "./taxFreeSavingsAccount";
import { taxFreeSavingsAccountPersistedAt } from "./taxFreeSavingsAccount/taxFreeSavingsAccountPersistedAt";
import { isFinancialEducation } from './financialEducation';
import { financialEducationPeristedAt } from './financialEducation/journey';
import { isQuiz } from './quizAssessment';
import { quizPeristedAt } from './quizAssessment/quiz';
import { isDigitalOnboarding } from './digitalOnboarding';
import { digitalOnboardingPersistedAt } from './digitalOnboarding/digitalOnboardingPersistedAt';

export const persistedAt = store => next => action => {
    if (isRaAction(action))
        return raOptimalPersistedAt(store, next, action);
    if (isArcAction(action))
        return atRetirementPersistedAt(store, next, action);
    if (isCvsAction(action))
        return cvsPersistedAt(action);
    if (isEfpAction(action))
        return easiplusFuneralPlanPersistedAt(store, next, action);
    if (isTfsaAction(action))
        return taxFreeSavingsAccountPersistedAt(store, next, action);
    if (isFinancialEducation(action))
        return financialEducationPeristedAt(store, next, action);
    if (isQuiz(action))
        return quizPeristedAt(store, next, action);
    if (isDigitalOnboarding(action)){
        return digitalOnboardingPersistedAt(store, next, action);
    }

    return next(action);
};
