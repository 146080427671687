import { transitionShortTermGoalCalculator } from '../../../actions/investmentCalculator/shortTermGoal';

import { getShortTermGoalCalculatorForm } from '../../../selectors/investmentCalculator/shortTermGoalCalculator';

import {FIELD_STATUS} from "../../../reducers/status";
import { formFieldValid } from '../status';

export const shortTermGoalFormRules = (store, next, action) => {
    const result = next(action);
    const shortTermGoalCalculatorForm = getShortTermGoalCalculatorForm(store.getState());
    const shortTermGoalCalculatorValid = validateFormDetails(shortTermGoalCalculatorForm);

    const formAction = shortTermGoalCalculatorValid
        ? transitionShortTermGoalCalculator.formValid()
        : transitionShortTermGoalCalculator.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = (formFields) =>
    formFieldValid(formFields?.amountOnceOff) &&
    formFieldValid(formFields?.amountMonthly) &&
    formFieldValid(formFields?.investmentTenure) &&
    formFieldValid(formFields?.potentialLossPercentage)
