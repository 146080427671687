import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

const defaultOptions = {
    required: true,
    min: null,
    subject: null,
    subjectPlural: null,
};

const validationMessage = ({ min, subject, subjectPlural }) => {
    if (!min && !subject)
        return OmValidationMessageHelper.getSelectSubjectMsg('');

    if (min && !subject)
        return OmValidationMessageHelper.getSelectMinMsg(min);

    if (!min && subject)
        return OmValidationMessageHelper.getSelectSubjectMsg(subject);

    return OmValidationMessageHelper.getSelectMinSubjectMsg(min, subject, subjectPlural || (subject + 's'));
};

export function validateSelection(selection, options = defaultOptions) {
    // if no selection has been made
    if (!selection) {
        return options.required
            ? validationMessage(options)
            : null;
    }

    // if a single item has been selected
    if (!Array.isArray(selection)) {
        if (options.min === 1) {
            return;
        }

        return validationMessage(options);
    }

    const nSelected = selection.length;
    if (options.min <= nSelected) {
        return;
    }

    return validationMessage(options);
}

export function validateSingleSelection(selection, subject) {
    // if no selection has been made
    if (!selection) {
        return OmValidationMessageHelper.getSelectSubjectMsg(subject);
    }

    return null;
}
