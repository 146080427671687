import { PAGE_INDICES } from '../../../actions/taxFreeSavingsAccount/types';
import {
    // navigateToBeneficiaries,
    navigateToPaymentDetails,
    navigateToPersonalDetails,
    navigateToAuxiliaryDetails,
    navigateToReview,
    navigateToThankYou,
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
    navigateToInvestmentSetupContribution,
    navigateToSelectAFund,
    navigateToAdvancedFundPicker,
    navigateToFundSplit,
    // navigateToBeneficiariesSplit,
    navigateToInvestmentSetupInitial,
    navigateToLandingPage,
} from "../../../actions/taxFreeSavingsAccount/routing";
import { submitApplication } from "../../../actions/taxFreeSavingsAccount/review";
import { setAdvancedFundIsSelected } from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";
import { getActivePageIndex } from "../../../selectors/taxFreeSavingsAccount/routing";
import { performPaymentDetailsValidation } from './paymentDetails';
import {
    getSelectAFundFormIsValid
} from "../../../selectors/taxFreeSavingsAccount/selectAFund";
// import { getNumberOfBeneficiaries } from '../../../selectors/taxFreeSavingsAccount/beneficiaries';
// import { getIsBeneficiarySplitValid } from '../../../selectors/taxFreeSavingsAccount/beneficiariesSplit';
// import { performBeneficiariesSplitValidation } from './beneficiariesSplit';
import { getIsPaymentDetailsValid } from "../../../selectors/taxFreeSavingsAccount/paymentDetails";
import { getInvestmentSetupInitialFormIsValid } from "../../../selectors/taxFreeSavingsAccount/investmentSetupInitial";
import { performInvestmentSetupInitialValidation } from "./investmentSetupInitial";
import { getIsInvestmentSetupContributionValid } from "../../../selectors/taxFreeSavingsAccount/investmentSetupContribution";
import { performInvestmentSetupContributionValidation } from './investmentSetupContribution';
import { performPersonalDetailsValidation } from "./personalDetails";
import { getIsPersonalDetailsValid } from "../../../selectors/taxFreeSavingsAccount/personalDetails";
import { getSelectedFundList, getAdvancedFundPickerFormIsValid, getAdvancedFundsAreSelected} from "../../../selectors/taxFreeSavingsAccount/advancedFundPicker";
import { performSelectAFundValidation } from "./selectAFund";
import { performAdvancedFundPickerValidation } from "./advancedFundPicker";
import { performReviewValidation } from "./review";
import { getConfirmDetailsValid } from "../../../selectors/taxFreeSavingsAccount/review"
import { formInitTransitionFundSplit} from "../../../actions/taxFreeSavingsAccount/fundSplit";
import { getIsAuxiliaryDetailsValid } from "../../../selectors/taxFreeSavingsAccount/auxiliaryDetails"
import { performAuxiliaryDetailsValidation } from "./auxiliaryDetails"
import {
    getIsFundSplitValid,
    getIsEvenSplit
} from "../../../selectors/taxFreeSavingsAccount/fundSplit";
import {performFundSplitValidation} from "./fundSplit";

export const routingRules = (store, next, action) => {
    if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS) {
        return next(action);
    }

    const result = next(action);
    const state = store.getState();
    const activePageIndex = getActivePageIndex(state);

    if (action.type === NAVIGATE_NEXT) {
        onNavigateNext(store, state, activePageIndex);
        return result;
    }

    onNavigatePrevious(store, state, activePageIndex);

    return result;
};

const onNavigateNext = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.INVESTMENT_SETUP_INITIAL:
            if (getInvestmentSetupInitialFormIsValid(state)) {
                store.dispatch(navigateToPersonalDetails());
            } else {
                performInvestmentSetupInitialValidation(store);
            }
            break;

        case PAGE_INDICES.PERSONAL_DETAILS:
            if (getIsPersonalDetailsValid(state)) {
                store.dispatch(navigateToInvestmentSetupContribution());
            } else {
                performPersonalDetailsValidation(store);
            }
            break;

        case PAGE_INDICES.INVESTMENT_SETUP_CONTRIBUTION:
            if (getIsInvestmentSetupContributionValid(state)) {
                store.dispatch(navigateToSelectAFund());
            } else {
                performInvestmentSetupContributionValidation(store);
            }
            break;

        case PAGE_INDICES.SELECT_A_FUND:
            if (getSelectAFundFormIsValid(state)) {
                store.dispatch(navigateToAuxiliaryDetails());
            } else if (getSelectedFundList(state).length > 0) {
                store.dispatch(navigateToAdvancedFundPicker());
            } else {
                performSelectAFundValidation(store);
            }
            break;

        case PAGE_INDICES.ADVANCED_FUND_PICKER:
            const advancedSelectedFunds = getSelectedFundList(state);

            if (getAdvancedFundPickerFormIsValid(state)) {
                store.dispatch(setAdvancedFundIsSelected(true));

                // If there is more than one fund go to fund split page
                if (advancedSelectedFunds.length > 1) {
                    const isEvenSplit = getIsEvenSplit(state);
                    store.dispatch(navigateToFundSplit());
                    store.dispatch(formInitTransitionFundSplit(advancedSelectedFunds, isEvenSplit.regular, isEvenSplit.lumpSum))
                } else {
                    // If there is only 1 go straight to personal details
                    store.dispatch(navigateToAuxiliaryDetails());
                    // If there is only 1 fund force even split
                    store.dispatch(formInitTransitionFundSplit(advancedSelectedFunds, true, true))
                }
            } else {
                performAdvancedFundPickerValidation(store);
            }

            break;

        case PAGE_INDICES.FUND_SPLIT:
            if (getIsFundSplitValid(state)) {
                store.dispatch(navigateToAuxiliaryDetails());
            } else {
                performFundSplitValidation(store);
            }
            break;

        case PAGE_INDICES.AUXILIARY_DETAILS:
            if (getIsAuxiliaryDetailsValid(state)) {
                store.dispatch(navigateToPaymentDetails());
            } else {
                performAuxiliaryDetailsValidation(store);
            }
            break;

        // case PAGE_INDICES.BENEFICIARIES:
        //     const numberOfBeneficiaries = getNumberOfBeneficiaries(state);
        //     if (numberOfBeneficiaries > 1) {
        //         store.dispatch(navigateToBeneficiariesSplit());
        //     } else {
        //         store.dispatch(navigateToPaymentDetails());
        //     }
        //     break;
        //
        // case PAGE_INDICES.BENEFICIARIES_SPLIT:
        //     if (getIsBeneficiarySplitValid(state)) {
        //         store.dispatch(navigateToPaymentDetails());
        //     } else {
        //         performBeneficiariesSplitValidation(store);
        //     }
        //     break;

        case PAGE_INDICES.PAYMENT_DETAILS:
            if (getIsPaymentDetailsValid(state)) {
                store.dispatch(navigateToReview());
            } else {
                performPaymentDetailsValidation(store);
            }

            break;

        case PAGE_INDICES.REVIEW:
            if (getConfirmDetailsValid(state)) {
                store.dispatch(submitApplication());
            } else {
                performReviewValidation(store);
            }            
            break;

        case PAGE_INDICES.THANK_YOU:
            break;
    }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.INVESTMENT_SETUP_INITIAL:
            store.dispatch(navigateToLandingPage());
            break;

        case PAGE_INDICES.INVESTMENT_SETUP_CONTRIBUTION:
            store.dispatch(navigateToPersonalDetails());
            break;

        case PAGE_INDICES.PERSONAL_DETAILS:
            store.dispatch(navigateToInvestmentSetupInitial());
            break;

        case PAGE_INDICES.SELECT_A_FUND:
            store.dispatch(navigateToInvestmentSetupContribution());
            break;

        case PAGE_INDICES.ADVANCED_FUND_PICKER:
            store.dispatch(navigateToSelectAFund());
            break;

        case PAGE_INDICES.FUND_SPLIT:
            store.dispatch(navigateToAdvancedFundPicker());
            break;

        case PAGE_INDICES.AUXILIARY_DETAILS:
            const advancedSelectedFunds = getSelectedFundList(state);
            if (advancedSelectedFunds.length === 0) {
                store.dispatch(navigateToSelectAFund());
                break;
            }

            if (advancedSelectedFunds.length > 1) {
                store.dispatch(navigateToFundSplit());
                break;
            }

            store.dispatch(navigateToAdvancedFundPicker());

            break;

        // case PAGE_INDICES.BENEFICIARIES:
        //     store.dispatch(navigateToAuxiliaryDetails());
        //     break;
        //
        // case PAGE_INDICES.BENEFICIARIES_SPLIT:
        //     store.dispatch(navigateToBeneficiaries());
        //     break;

        case PAGE_INDICES.PAYMENT_DETAILS:
            // const numberOfBeneficiaries = getNumberOfBeneficiaries(state);
            // if (numberOfBeneficiaries > 1) {
            //     store.dispatch(navigateToBeneficiariesSplit());
            // } else {
            //     store.dispatch(navigateToBeneficiaries());
            // }
            store.dispatch(navigateToAuxiliaryDetails());
            break;

        case PAGE_INDICES.REVIEW:
            store.dispatch(navigateToPaymentDetails());
            break;

        case PAGE_INDICES.THANK_YOU:
            break;
    }
};

