import { SET_VALUE_ROUTING } from '../../../actions/roaCarInsurance/routing';
import { routingRules } from './routing';
import {
    QUOTATION_SET_VALID,
    QUOTATION_SET_VALUE,
    QUOTATION_SET_NG_VALUE,
    QUOTATION_SET_NG_VALID
} from '../../../actions/roaCarInsurance/quotation';
import { quotationFormRules, quotationFormRulesNg } from './quotation';
import {
    ABOUT_YOU_SET_VALID,
    ABOUT_YOU_SET_VALUE,
} from '../../../actions/roaCarInsurance/aboutYou';
import { aboutYouFormRules} from './aboutYou';

import {
   DETAILS_SET_VALID,
   DETAILS_SET_VALUE
} from '../../../actions/roaCarInsurance/details';

import { carDetailsFormRules } from './carDetails';
import { CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE,
	CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/roaCarInsurance/agentReferral';
import {agentModalFormRules} from "./agentReferral";
import { VEHICLE_DETAILS_SET_VALID, VEHICLE_DETAILS_SET_VALUE } from "../../../actions/roaCarInsurance/vehicleDetails";
import { vehicleDetailsFormRules } from "./vehicleDetails";

export const roaCarInsuranceRules = (store, next, action) => {
    if (action.type.startsWith(SET_VALUE_ROUTING)) {
        return routingRules(store, next, action);
    }
    if (
        action.type.startsWith(QUOTATION_SET_VALUE) ||
        action.type.startsWith(QUOTATION_SET_VALID)
    ) {
        return quotationFormRules(store, next, action);
    }
    if (
        action.type.startsWith(ABOUT_YOU_SET_VALUE) ||
        action.type.startsWith(ABOUT_YOU_SET_VALID)
    ) {
        return aboutYouFormRules(store, next, action);
    }

    if (
        action.type.startsWith(DETAILS_SET_VALUE) ||
        action.type.startsWith(DETAILS_SET_VALID)
    ) {
        return carDetailsFormRules(store, next, action);
    }
	if (
		action.type.startsWith(
			CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE,
		) ||
		action.type.startsWith(CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID)
	) {
		return agentModalFormRules(store, next, action);
	}

    if (
        action.type.startsWith(QUOTATION_SET_NG_VALUE) ||
        action.type.startsWith(QUOTATION_SET_NG_VALID)
    ) {
        return quotationFormRulesNg(store, next, action);
    }

    if (
        action.type.startsWith(VEHICLE_DETAILS_SET_VALUE) ||
        action.type.startsWith(VEHICLE_DETAILS_SET_VALID)
    ) {
        return vehicleDetailsFormRules(store, next, action);
    }

};
