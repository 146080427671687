import axios from 'axios';
import { getPaymentsDetails } from '../../../selectors/roaCarInsurance/payments';
import { getVehicleValuation } from '../../../selectors/roaCarInsurance/quotation';
import { getIsApiPaymentStatusPending } from '../../../selectors/roaCarInsurance/payments';
import {
	setMpesaPaymentsStatusValue,
    setMpesaPaymentsStatusValid,
	apiTransitionPaymentsStatus,
} from "../../../actions/roaCarInsurance/payments";


export const carInsuranceMpesaPaymentStatusService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();
	const paymentsResponse = getPaymentsDetails(state);
	(async () => {
		if(!getIsApiPaymentStatusPending && paymentsResponse?.mpesaPaymentsStatus?.value?.status !== '0') {
            store.dispatch(apiTransitionPaymentsStatus.pending());
			try {
				const resp = await paymentsStatus(state);
				if (
                    resp?.data?.message ===
                    'The service request is processed successfully.'
                ) {
                    store.dispatch(setMpesaPaymentsStatusValue(resp.data));
                    store.dispatch(apiTransitionPaymentsStatus.success());
                } else if (
                    resp?.data.message ===
                    'The balance is insufficient for the transaction'
                ) {
                    store.dispatch(setMpesaPaymentsStatusValue(resp.data));
                    store.dispatch(apiTransitionPaymentsStatus.failure());
                } else if (resp?.data.message === 'Request cancelled by user') {
                    store.dispatch(setMpesaPaymentsStatusValue(resp.data));
                    store.dispatch(apiTransitionPaymentsStatus.failure());
                }
			} catch (e) {
				store.dispatch(setMpesaPaymentsStatusValid(e));
                store.dispatch(apiTransitionPaymentsStatus.failure())
			}
		}
	})()
	return result
}

const paymentsStatus = async (state) => {
	const paymentsResponse = getPaymentsDetails(state);
	const vehicleValuation = getVehicleValuation(state);
	const payload = {

		    session_id: vehicleValuation.value.session_id,
		    checkout_request_id: paymentsResponse.mpesaPayments.value.CheckoutRequestID
	}
	try {
		const url = '/om-api/roa-car-insurance/mpesa-check-stk-status'
		return await axios.post(url, payload)
	} catch (e) {
		console.error(e)
		throw e
	}
}
