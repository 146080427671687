//constants

// Transitions
export const FORM_VALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR =
  "allsureInsurance/additionalHomeMotorDetails/transition/formValid";
export const FORM_INVALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR =
  "allsureInsurance/additionalHomeMotorDetails/transition/formInvalid";

export const RESET_STATE_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR =
  "allsureInsurance/additionalHomeMotorDetails/transition/resetState";

export const ALLSURE_INSURANCE_PURCHASE_ADDITIONAL_HOME_MOTOR =
  "allsureInsurance/additionalHomeMotorDetails/";
export const PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE =
ALLSURE_INSURANCE_PURCHASE_ADDITIONAL_HOME_MOTOR+"setValue/";
export const PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALID =
ALLSURE_INSURANCE_PURCHASE_ADDITIONAL_HOME_MOTOR +"setValid/";
export const PURCHASE_ADDITIONAL_HOME_MOTOR_EDIT =
ALLSURE_INSURANCE_PURCHASE_ADDITIONAL_HOME_MOTOR +"setEdit";

export const SYNCH_ADDITIONAL_HOME_MOTOR_DETAILS_FORM= ALLSURE_INSURANCE_PURCHASE_ADDITIONAL_HOME_MOTOR+"synchHomeMotorForm"

//action

export const transitionAdditionalHomeMotorDetails = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR }),
};

export const resetAdditionalHomeMotorDetails = () => ({
  type: RESET_STATE_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR,
});

export const setAdditionalHomeMotorDetailsFieldValue =(value)=>({type: PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE, value})
export const setAdditionalHomeMotorDetailsFieldValid =(error,value)=>({type: PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALID, error, value})
export const setAdditionalHomeMotorDetailsEdit =(value)=>({type: PURCHASE_ADDITIONAL_HOME_MOTOR_EDIT, value})



export const PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE_WITHOUT_VALIDATION =
ALLSURE_INSURANCE_PURCHASE_ADDITIONAL_HOME_MOTOR+"/withoutValidation/setValue";
export const setAdditionalDetailsFieldValueWithoutValiations =(value)=>({type: PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE_WITHOUT_VALIDATION, value})

export const synchAdditionalHomeMotorDetailsForm = (value) => ({
    type: SYNCH_ADDITIONAL_HOME_MOTOR_DETAILS_FORM,
    value
  });