import {fireGtmEvent} from "../gtmEvents";
import {COLLECT_USER_DATA} from '../../../actions/publicWeb/unclaimedBenefits';

const submissionGtmEvent = submissionData => {
    fireGtmEvent(
        'save_submission',
        {
            submissionData
        }
    );
};

export const unclaimedBenefitsTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState().publicWeb.unclaimedBenefits;
    switch (action.type) {
        case COLLECT_USER_DATA:
            submissionGtmEvent(state.form);
            break;
    }

    return result;
};
