import { transitionDoctorsPiPersonalDetails } from "../../../actions/doctorsPi/personalDetails";
import { FIELD_STATUS } from "../../../reducers/status";
import { getPersonalDetailsForm } from "../../../selectors/doctorsPi/personalDetails";

export const personalDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const personalDetailsFormValid = validatePersonalDetailsFormDetails(personalDetailsForm);
    const formAction = personalDetailsFormValid
        ? transitionDoctorsPiPersonalDetails.formValid()
        : transitionDoctorsPiPersonalDetails.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
    return (
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.surname &&
        formFields.surname.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.mobileNumber &&
        formFields.mobileNumber.status === FIELD_STATUS.VALID &&
        formFields.idPassport &&
        formFields.idPassport.status === FIELD_STATUS.VALID &&
        formFields.kraPinNumber &&
        formFields.kraPinNumber.status === FIELD_STATUS.VALID &&
        formFields.city &&
        formFields.city.status === FIELD_STATUS.VALID
    );
};

