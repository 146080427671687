import {
    setTaxResult,
    transitionAdvancedCalculator,
    RESULTS_VIEW_VALUE,
    REINITIALIZE_STATE_ADVANCED_CALCULATOR,
    PRODUCT_RECOMMENDATION_SET_VALUE
} from "../../../actions/incomeTaxCalculator/advancedCalculator";
import {
    getAdvancedCalculatorForm
} from "../../../selectors/incomeTaxCalculator/advancedCalculator";
import {transitionScrolling} from "../../../actions/incomeTaxCalculator/scrolling";
import {FIELD_STATUS} from "../../../reducers/status";

export const advancedCalculatorFormRules = (store, next, action) => {
    const result = next(action);
    
    const advancedCalculatorForm = getAdvancedCalculatorForm(store.getState());
    const advancedCalculatorValid = validateAdvancedCalculatorDetails(advancedCalculatorForm);
    const formAction = advancedCalculatorValid
        ? transitionAdvancedCalculator.formValid()
        : transitionAdvancedCalculator.formInvalid()

    store.dispatch(formAction);

    if (action.type === RESULTS_VIEW_VALUE) {
        store.dispatch(setTaxResult(action.value));
        store.dispatch(transitionScrolling.scrollToClass('basic-result-card-mobile'));
    }

    if (action.type === REINITIALIZE_STATE_ADVANCED_CALCULATOR) {
        store.dispatch(transitionScrolling.scrollToClass('age-section'));
    }

    if (action.type === PRODUCT_RECOMMENDATION_SET_VALUE) {
        store.dispatch(transitionScrolling.scrollToClass('component-table-mobile'));
    }

    return result;
};

const validateAdvancedCalculatorDetails = formFields => (
    formFields.age && formFields.age.status === FIELD_STATUS.VALID &&
    formFields.incomeFrequency && formFields.incomeFrequency.status === FIELD_STATUS.VALID &&
    formFields.incomeValue && formFields.incomeValue.status === FIELD_STATUS.VALID &&
    validateAdvancedFields(formFields, 'otherIncome') && validateAdvancedFields(formFields, 'pensionFund') &&
    validateAdvancedFields(formFields, 'medicalAid') && validateAdvancedFields(formFields, 'retirementAnnuity') &&
    validateAdvancedFields(formFields, 'providentFund')
);

const validateAdvancedFields = (formFields, field) => {
    if (!formFields[field].toggleStatus) {
        return true;
    }
    if (field !== 'retirementAnnuity') {
        return Object.keys(formFields[field]).slice(0, 2)
        .filter(key => formFields[field][key].status === FIELD_STATUS.ERROR).length === 0;
    }
    return  formFields[field].status !== FIELD_STATUS.ERROR;
}
