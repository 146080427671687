import httpStatusCode from '../../../../api-server/constants/httpStatusCode';
import {
    setAllsureApplicationApiStatus,
    setAllsureApplicationQuoteDetails,
} from '../../../actions/allsureInsurance/referenceData';
import { API_CALL_STATUS } from '../../../reducers/status';
import {  getGlobalPaymentFrequency, getHomeDetailsObject, getHomeDetailsRequestBody } from '../../../selectors/allsureInsurance/homeDetails';
import { getCallMeBackPayload, getEmailSubjectLabel, getMotorDetailsObject, getMotorDetailsRequestBody } from '../../../selectors/allsureInsurance/motorDetails';
import { getPersonalDetailsForm } from '../../../selectors/allsureInsurance/personalDetails';
import { getPurchasePersonalDetailsForm } from '../../../selectors/allsureInsurance/purchasePersonalDetails';
import { getAllsureReferenceNumber, getQuoteTotalAmountMonthly } from '../../../selectors/allsureInsurance/referenceData';
import { sendEmailNotification } from './quotingMailService';
import { getRequiredDocuments } from '../../../selectors/allsureInsurance/uploadDocuments';
import { navigateNext } from '../../../actions/allsureInsurance/routing';
import { getPurchaseDetails } from '../../../selectors/allsureInsurance/purchaseAdditionalHomeMotorDetails';

export const purchaseService = (store, next, action) => {
    const state=store.getState();
    (async () => {
        try{
        store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.PENDING));
        const {requiredDocs,submittedDocs}=getRequiredDocuments(state)
        const {fullname,mobileNumber,email}= getPurchasePersonalDetailsForm(state)
        const productLabel=getEmailSubjectLabel(state)
        const monthlyQuoteAllproducts = getQuoteTotalAmountMonthly(state)
        const referenceNumber=getAllsureReferenceNumber(state)
        const premiumFrequency = getGlobalPaymentFrequency(state) 


        const {motorMaped,homeContentMaped,homeMaped} = getCallMeBackPayload('user')(state)
        const purchaseDetails=getPurchaseDetails(state) 
        const purchaseSummary = [...homeMaped,...homeContentMaped,...motorMaped,...purchaseDetails]
        const userDataFields = [
            {
                fullName: fullname.value,
                policyName: `${productLabel}`,
                uploadedDocuments: submittedDocs.length ? submittedDocs?.map((item)=>item?.label) : null,
                referenceNumber: referenceNumber,
                requiredDocuments: requiredDocs.length ? requiredDocs?.map((item)=>item?.label) : null,
                premiumFrequency,
                premium:`P ${premiumFrequency==='Monthly'? +((monthlyQuoteAllproducts/12)?.toFixed(2))+6 : monthlyQuoteAllproducts}`
              },
              ...purchaseSummary


        ]

        const sampleRequestBody = {
            firstName:fullname?.value.split(' ')[0],
            lastName:fullname?.value.split(' ')[1],
            contactNumber:mobileNumber?.value,
            subject:`Web: ${productLabel} : ${fullname?.value} - REF: ${referenceNumber}`,
            userDataFields,
            templateType:"ALLSURE_PURCHASE_CUSTOMER",
            monthlyPremium: premiumFrequency==='Monthly' ? +((monthlyQuoteAllproducts)/12)?.toFixed(2)+6  : null,
            yearlyPremium:premiumFrequency!=='Monthly'?(monthlyQuoteAllproducts):null,
            email:email?.value
        }
        const response = await sendEmailNotification(sampleRequestBody);
        if (response && response.status === httpStatusCode.OK) {
            // store.dispatch(setAllsureApplicationQuoteDetails(response.data));
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.SUCCESS));
            store.dispatch(navigateNext())
        } else {
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
        }
    }
        catch(error){
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
        }
    })();
    return next(action);
};
