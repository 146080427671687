import { FIELD_STATUS } from "../status";
import { SET_VALUE_FUND_DATA } from "../../actions/taxFreeSavingsAccount/fundData";

export const initFundData = {
    data: {},
    fundDataStatus: FIELD_STATUS.UNTOUCHED
};

export default (state = initFundData, action) => {
    switch (action.type) {
        case SET_VALUE_FUND_DATA:
            return {...state, data: action.value, fundDataStatus: FIELD_STATUS.VALID};
        default:
            return state;
    }
};
