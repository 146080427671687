import { PAGE_INDICES } from "../../../actions/easiplusFuneralPlan/types";
import {
    navigateToReplacementPolicy,
    navigateToPersonalDetails,
    navigateToLivesCovered,
    navigateToBeneficiaries,
    navigateToPaymentDetails,
    navigateToConfirmation,
    navigateToThankYou,
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
} from "../../../actions/easiplusFuneralPlan/routing";

import { sendConfirmDetailsSubmitApplication } from "../../../actions/easiplusFuneralPlan/confirmDetails";


import { performPersonalDetailsValidation } from "./personalDetails";
import { performLivesCoveredValidation, livesCoveredUpdatePersonalValueRules } from "./livesCovered";
import { determineBeneficiaryNavigation } from "./beneficiaries";
import { performBeneficiariesSplitValidation } from "./beneficiariesSplit";
import { performPaymentDetailsValidation } from "./paymentDetails";
import { performConfirmDetailsValidation } from "./confirmDetails";

import { getActivePageIndex } from "../../../selectors/easiplusFuneralPlan/routing";
import { getReplacementPolicyFormIsValid } from "../../../selectors/easiplusFuneralPlan/replacementPolicy";
import { getPersonalDetailsValid } from "../../../selectors/easiplusFuneralPlan/personalDetails";
import { getLivesCoveredFormComplete, getCoverMembersForms } from "../../../selectors/easiplusFuneralPlan/livesCovered";
import { getFormStatusIsValid as getBeneficiariesSplitIsValid } from "../../../selectors/easiplusFuneralPlan/beneficiariesSplit";
import { getPaymentDetailsValid } from "../../../selectors/easiplusFuneralPlan/paymentDetails";
import { getConfirmDetailsValid, getSkipConfirmationPage } from "../../../selectors/easiplusFuneralPlan/confirmDetails"


export const routingRules = (store, next, action) => {
    if(action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS){
        return next(action);
    }

    const result = next(action);
    const state = store.getState();
    const activePageIndex = getActivePageIndex(state);

    if (action.type === NAVIGATE_NEXT) {
        onNavigateNext(store, state, activePageIndex);
        return result;
    }

    onNavigatePrevious(store, state, activePageIndex);

    return result;
};

const onNavigateNext = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.REPLACEMENT_POLICY:
            !getReplacementPolicyFormIsValid(state) ? null : store.dispatch(navigateToPersonalDetails());
            break;

        case PAGE_INDICES.PERSONAL_DETAILS:
            !getPersonalDetailsValid(state) ? performPersonalDetailsValidation(store) : performLivesCoveredNavigation(store, state);
            break;

        case PAGE_INDICES.LIVES_COVERED:
            !getLivesCoveredFormComplete(state) ? performLivesCoveredValidation(store) : store.dispatch(navigateToBeneficiaries());
            break;

        case PAGE_INDICES.BENEFICIARIES:
            determineBeneficiaryNavigation(store, state, true);
            break;

        case PAGE_INDICES.BENEFICIARIES_SPLIT:
            !getBeneficiariesSplitIsValid(state) ? performBeneficiariesSplitValidation(store) : store.dispatch(navigateToPaymentDetails());
            break;

        case PAGE_INDICES.PAYMENT_DETAILS:
            performPaymentDetailsNavigation(store, state);
            break;

        case PAGE_INDICES.CONFIRMATION:
            !getConfirmDetailsValid(state) ? performConfirmDetailsValidation(store) : performThankYouNavigation(store);
            break;

        case PAGE_INDICES.THANK_YOU:
            break;
    }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.REPLACEMENT_POLICY:
            break;

        case PAGE_INDICES.PERSONAL_DETAILS:
            store.dispatch(navigateToReplacementPolicy());
            break;

        case PAGE_INDICES.LIVES_COVERED:
            store.dispatch(navigateToPersonalDetails());
            break;

        case PAGE_INDICES.BENEFICIARIES:
            store.dispatch(navigateToLivesCovered());
            break;

        case PAGE_INDICES.BENEFICIARIES_SPLIT:
            store.dispatch(navigateToBeneficiaries());
            break;

        case PAGE_INDICES.PAYMENT_DETAILS:
            determineBeneficiaryNavigation(store, state, false);
            break;

        case PAGE_INDICES.CONFIRMATION:
            store.dispatch(navigateToPaymentDetails());
            break;

        case PAGE_INDICES.THANK_YOU:
            store.dispatch(navigateToConfirmation());
            break;
    }
};

const performLivesCoveredNavigation = (store, state) => {
    const livesCoveredForm = getCoverMembersForms(state);
    if ('myself' in livesCoveredForm) {
        livesCoveredUpdatePersonalValueRules(store);
    }

    store.dispatch(navigateToLivesCovered());
}

const performPaymentDetailsNavigation = (store, state) => {
    const skipConfirmationPage = getSkipConfirmationPage(state);
    
    if(skipConfirmationPage){
        const paymentDetailsValid = getPaymentDetailsValid(state);
        const confirmDetailsValid = getConfirmDetailsValid(state);

        // Handle payment details valid
        if(!paymentDetailsValid)
            performPaymentDetailsValidation(store);
        
        // Handle confirm details valid
        if(!confirmDetailsValid)
            performConfirmDetailsValidation(store);

        // Handle success
        if(paymentDetailsValid && confirmDetailsValid)
            performThankYouNavigation(store);

    } else {
        !getPaymentDetailsValid(state) ? performPaymentDetailsValidation(store) : store.dispatch(navigateToConfirmation());
    }
}

const performThankYouNavigation = (store) => {
    store.dispatch(sendConfirmDetailsSubmitApplication());
    store.dispatch(navigateToThankYou())
}