import {
    validateNumber,
    validateName,
    validateAge
} from "@om/validation";
import {
    AMOUNT_BORROWED_SET_VALUE,
    setAmountBorrowedValid,
    OTHER_AMOUNT_BORROWED_SET_VALUE,
    setOtherAmountBorrowedValid,
    REPAYMENT_DURATION_SET_VALUE,
    setRepaymentDurationValid,
    OTHER_REPAYMENT_DURATION_SET_VALUE,
    setOtherRepaymentDurationValid
} from "../../../actions/personalLoansCalculator/calculator";
import { getRepaymentDurationOptions, getLoanAmountOptions } from "../../../selectors/personalLoansCalculator/referenceData";
import { getAmountBorrowed, getOtherAmountBorrowed } from "../../../selectors/personalLoansCalculator/calculator";
import { ownAmountMethod } from "../../rulesEngine/personalLoansCalculator/calculator";

export const personalLoansCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AMOUNT_BORROWED_SET_VALUE:
                error = action.value !== null && action.value !== undefined ? null : 'Please select an option.';
                return setAmountBorrowedValid(error);
            case OTHER_AMOUNT_BORROWED_SET_VALUE:
                error = null;
                error = validateNumber(action.value, { subject: 'loan amount', decimalPoints: 0 })
                if (error === null) {
                    if (action.value < 2000) {
                        error = "Please enter a loan amount that is greater than R1999";
                    } else if (action.value > 250000) {
                        error = "Please enter a loan amount that is less than R250 001"
                    }
                }
                return setOtherAmountBorrowedValid(error);
            case REPAYMENT_DURATION_SET_VALUE:
                error = action.value !== null && action.value !== undefined ? null : 'Please select an option.';
                return setRepaymentDurationValid(error);
            case OTHER_REPAYMENT_DURATION_SET_VALUE:
                error = null;
                error = validateNumber(action.value, { subject: 'repayment term', decimalPoints: 0 });

                const state = store.getState();

                const amountBorrowed = getAmountBorrowed(state);
                const otherAmountBorrowed = getOtherAmountBorrowed(state);
                const loanAmountOptions = getLoanAmountOptions(state);
                const repaymentDurationOptions = getRepaymentDurationOptions(state);

                error = validateOtherRepaymentDurationBasedOnLoanAmount(action, error, amountBorrowed, otherAmountBorrowed, loanAmountOptions, repaymentDurationOptions);

                return setOtherRepaymentDurationValid(error);
            default:
                return null;
        }
    })();

    store.dispatch(validationAction);
    return next(action);
}

const validateOtherRepaymentDurationBasedOnLoanAmount = (action, error, amountBorrowed, otherAmountBorrowed, loanAmountOptions, repaymentDurationOptions) => {
    let value = 0;

    if (amountBorrowed.value === ownAmountMethod(loanAmountOptions)) {
        value = otherAmountBorrowed.value;
    } else {
        loanAmountOptions.forEach(amountOptions => {
            if (amountOptions.code === amountBorrowed.value) {
                value = amountOptions.value
            }
        })
    }

    if (value >= 3000 && value <= 8000) {
        const availableDurationsLessThan12 = repaymentDurationOptions.filter(duration => duration.value < 12);
        if (error === null && !validDropDownDurationValue(availableDurationsLessThan12, action.value) &&
            invalidDurationPeriod(repaymentDurationOptions[repaymentDurationOptions.length - 4].value,
                repaymentDurationOptions[repaymentDurationOptions.length - 2].value,
                action.value)) {
            return buildErrorMessage(repaymentDurationOptions[0].value, repaymentDurationOptions[repaymentDurationOptions.length - 2].value, availableDurationsLessThan12);
        }
    } else {
        if (error === null && invalidDurationPeriod(repaymentDurationOptions[0].value,
            repaymentDurationOptions[repaymentDurationOptions.length - 2].value,
            action.value)) {
            return buildErrorMessage(repaymentDurationOptions[0].value, repaymentDurationOptions[repaymentDurationOptions.length - 2].value);
        }
    }
    
    return error;
}

const invalidDurationPeriod = (periodMinTerm, periodMaxTerm, value) => {
    return value < periodMinTerm || value > periodMaxTerm
}

const validDropDownDurationValue = (availableDurationsLessThan12, value) => {
    return availableDurationsLessThan12.some(duration => duration.value == value);
}

const buildErrorMessage = (periodMinTerm, periodMaxTerm, dropDownDurationValues = []) => {
    if (dropDownDurationValues.length === 0) {
        return `Please try again. Select a period between ${periodMinTerm} Months and ${periodMaxTerm} Months`;
    }
    return `Please try again. Select a period of either ${dropDownDurationValues.map(duration => duration.description)} or between ${periodMinTerm} Months and ${periodMaxTerm} Months `
}