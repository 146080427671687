import { getPayloadData, getIsApiPending } from '../../../selectors/healthInsuranceRW/payloadCollection';
import { setExpiredAuth } from '../../../actions/healthInsuranceRW/quote';
import {
    apiTransitionOrderConfirmation,
    confirmOrderSuccess,
    confirmOrderFailure
} from '../../../actions/healthInsuranceRW/payments';
import { getAccessToken, getIsConfirmOrderAPiPending } from '../../../selectors/healthInsuranceRW/payments';
import axios from 'axios';

export const orderConfirmationService = (store, next, action) => {
    (async () => {
        if (!getIsConfirmOrderAPiPending(store.getState())) {
            store.dispatch(apiTransitionOrderConfirmation.pending());
            try {
                const resp = await confirmInsuranceOrder(store.getState());
                store.dispatch(confirmOrderSuccess(resp.data));
                store.dispatch(apiTransitionOrderConfirmation.success());
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
                    store.dispatch(setExpiredAuth(true));
                }
                store.dispatch(apiTransitionOrderConfirmation.failure());
                store.dispatch(confirmOrderFailure(e));
            }
        }
    })();
    return next(action);
};


const confirmOrderPayload = (state) => {
    
    const payloadData = getPayloadData(state);
    const customerId = payloadData?.id
    const payload = {
       customerId
    };

    // Change payload here
    return {
        ...payload,
    };
};

const confirmInsuranceOrder = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/health-insurance-rw/order/confirmation';
        const response = await axios.post(url, confirmOrderPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
        return response
    } catch (e) {
        if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
            store.dispatch(setExpiredAuth(true));
        }
        console.error(e);
        throw e;
    }
};
