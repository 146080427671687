// // import { UG_MOTOR_QOUTE_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/quote';
// import { quoteFormValidation } from './quote';

// import { MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/personalDetails';
// import { personalDetailsValidation } from './personalDetails';

// import { MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/carDetails';
// import { carDetailsValidation } from './carsDetails';

import { HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/healthInsuranceRW/HealthAgentModalRw';
import { HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE, SET_SHARE_EMAIL_VALUE } from '../../../actions/healthInsuranceRW/quote';
import { LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE } from '../../../actions/healthInsuranceRW/personalDetails';
import { LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE as LIVES_COVERED_PERSONAL_DETAILS_BENEFICIARIES_FORM_SET_VALUE } from '../../../actions/healthInsuranceRW/personalDetailsBeneficiaries';
import { agentModalFormValidation } from './healthAgentModal';
import { personalDetailsValidation } from './personalDetails';
import { healthQuoteFormValidation } from './quote';
import { personalDetailsBeneficiariesValidation } from './personalDetailsBeneficiaries';
import { nextOfKinValidation } from './nextOfKin';
import { HEALTH_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE } from '../../../actions/healthInsuranceRW/nextOfKin';
import { MEDICAL_HISTORY_FORM_SET_VALUE } from '../../../actions/healthInsuranceRW/medicalHistory';
import { medicalHistoryValidation } from './medicalhistory';
import { HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE, ID_UPLOAD_SET_VALUE } from '../../../actions/healthInsuranceRW/Documents';
import { documentsUploadValidation } from './documents';
import { paymentsFormValidation } from './payments';
import { PAYMENTS_SET_VALUE } from '../../../actions/healthInsuranceRW/payments';
import { shareQuoteValidation } from './shareQuote';

// import { MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/Documents';
// import { documentsUploadValidation } from './documents';
// import { MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/declarations';
// import { declarationsFormValidation } from './declarations';

// import { MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/nextOfKin';
// import { nextOfKinValidation } from './nextOfKin';

// import { PAYMENTS_SET_VALUE } from '../../../actions/ugMotorInsurance/payments'
// import { paymentsFormValidation } from './payments';

// import { SET_SHARE_EMAIL_VALUE } from '../../../actions/ugMotorInsurance/quote';
// import { shareQuoteValidation } from './shareQuote';


export const healthInsuranceValidation = (store, next, action) => {
	if (action.type.startsWith(HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE)) {
		return healthQuoteFormValidation(store, next, action);
	}
	if (action.type.startsWith(LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE)) {
		return personalDetailsValidation(store, next, action);
	}

	if (action.type.startsWith(LIVES_COVERED_PERSONAL_DETAILS_BENEFICIARIES_FORM_SET_VALUE)) {
		return personalDetailsBeneficiariesValidation(store, next, action);
	}
	if(action.type.startsWith(PAYMENTS_SET_VALUE)){
		return paymentsFormValidation(store, next , action)
	}
	if (action.type.startsWith(HEALTH_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE)) {
		return nextOfKinValidation(store, next, action);
	}
	if (action.type.startsWith(MEDICAL_HISTORY_FORM_SET_VALUE)) {
		return medicalHistoryValidation(store, next, action);
	}
	if (action.type.startsWith(SET_SHARE_EMAIL_VALUE)) {
		return shareQuoteValidation(store, next, action);
	}
	if (action.type.startsWith(HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE)) return agentModalFormValidation(store, next, action);
	if (action.type.startsWith(HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE)) return documentsUploadValidation(store, next, action);

	return null;
};
