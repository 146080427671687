

export const DIGITAL_SAVINGS_QOUTE_FORM = 'digitalSavings/quoteForm/';

export const DIGITAL_SAVINGS_FORM_SET_VALUE = DIGITAL_SAVINGS_QOUTE_FORM + 'setValue/';
export const DIGITAL_SAVINGS_FORM_SET_VALID = DIGITAL_SAVINGS_QOUTE_FORM + 'setValid/';
export const DIGITAL_SAVINGS_SHARE_FORM_SET_VALUE = DIGITAL_SAVINGS_QOUTE_FORM + 'setValue2/';
export const DIGITAL_SAVINGS_SHARE_FORM_SET_VALID = DIGITAL_SAVINGS_QOUTE_FORM + 'setValid2/';

export const DIGITAL_SAVINGS_ADD_COVER = DIGITAL_SAVINGS_QOUTE_FORM + 'addCover/';	
export const FORM_VALID_DIGITAL_SAVINGS_QUOTE_FORM = 'digitalSavings/quoteForm/transition/formValid';
export const FORM_INVALID_DIGITAL_SAVINGS_QUOTE_FORM = 'digitalSavings/quoteForm/transition/formInValid';
export const FORM_VALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM = 'digitalSavings/shareQuoteForm/transition/formValid';
export const FORM_INVALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM = 'digitalSavings/shareQuoteForm/transition/formInValid';
export const DIGITAL_SAVINGS_SHARE_QUOTE_VALUE = DIGITAL_SAVINGS_QOUTE_FORM + 'shareQuote';

export const TOKEN_IS_EXPIRED = 'digitalSavings/expiredAuth';


export const DIGITAL_SAVINGS_SIDEBAR_OPEN_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'sidebarOpen';

export const REINITIALIZE = DIGITAL_SAVINGS_QOUTE_FORM + '/state/reinitialize';

// VALIDATE QUOTE FORM

export const transitionDigitalSavingsQuoteForm = { 
		formValid: () => ({
			type: FORM_VALID_DIGITAL_SAVINGS_QUOTE_FORM,
		}),
		formInvalid: () => ({
			type: FORM_INVALID_DIGITAL_SAVINGS_QUOTE_FORM,
		}),
};

export const transitionShareQuoteForm = {
	formInvalid: () => ({ 
		type: FORM_INVALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM,
	}),
	formValid: () => ({
		type: FORM_VALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM,
	}),
}

// API Calls actions
export const API_PENDING_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM = `${DIGITAL_SAVINGS_FORM_SET_VALID}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM = `${DIGITAL_SAVINGS_FORM_SET_VALID}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM = `${DIGITAL_SAVINGS_FORM_SET_VALID}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${DIGITAL_SAVINGS_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${DIGITAL_SAVINGS_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${DIGITAL_SAVINGS_SHARE_QUOTE_VALUE}/apiTransition/failure`;


export const apiTransitionDigitalSavingsQuoteForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM,
	}),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};


export const COLLECT_SHARE_QUOTE_FORM_DATA = DIGITAL_SAVINGS_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const COLLECT_QUOTE_FORM_DATA = DIGITAL_SAVINGS_FORM_SET_VALUE + 'collectQuoteFormData';
export const SHARE_DIGITAL_SAVINGS_QUOTE_SUCCESS = DIGITAL_SAVINGS_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_DIGITAL_SAVINGS_QUOTE_FAILURE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'shareQuoteFailure';
export const DIGITAL_SAVINGS_PREMIUM_SUCCESS = DIGITAL_SAVINGS_FORM_SET_VALUE + 'calculatePremiumSuccess';

export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

export const collectQuoteFormData = () => ({
	type: COLLECT_QUOTE_FORM_DATA,
})


export const shareQuoteSuccess = (value) => ({
    type: SHARE_DIGITAL_SAVINGS_QUOTE_SUCCESS,
    value,
})

export const shareQuoteFailure = (error) => ({
    type: SHARE_DIGITAL_SAVINGS_QUOTE_FAILURE,
    error,
})


export const addCover = (value) => ({
	type: DIGITAL_SAVINGS_ADD_COVER,
	value,
})

export const calculateDigitalSavingsPremiumSuccess = (value) => ({
	type: DIGITAL_SAVINGS_PREMIUM_SUCCESS,
	value,
});


export const SET_SHARE_EMAIL_VALUE = DIGITAL_SAVINGS_SHARE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = DIGITAL_SAVINGS_SHARE_FORM_SET_VALID + 'shareEmailValid';

// Form Values
export const ATTITUDE_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'attitude';
export const ATTITUDE_SET_VALID = DIGITAL_SAVINGS_FORM_SET_VALID + 'attitude';

export const SAVING_CATEGORY_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'savingCagetory';
export const SAVING_CATEGORY_SET_VALID = DIGITAL_SAVINGS_FORM_SET_VALID + 'savingCagetory';

export const DESIRED_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'desired';
export const DESIRED_SET_VALID = DIGITAL_SAVINGS_FORM_SET_VALID + 'desired';


export const FREQUENCY_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'frequency';
export const FREQUENCY_SET_VALID = DIGITAL_SAVINGS_FORM_SET_VALID + 'frequency';

export const LUMPSUM_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'lumpsum';
export const LUMPSUM_SET_VALID = DIGITAL_SAVINGS_FORM_SET_VALID + 'lumpsum';

export const START_DATE_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'startDate';
export const START_DATE_SET_VALID = DIGITAL_SAVINGS_FORM_SET_VALID + 'startDate';

export const DURATION_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'duration';
export const DURATION_SET_VALID = DIGITAL_SAVINGS_FORM_SET_VALID + 'duration';

export const IS_ON_ATTITUDE_SET_VALUE = DIGITAL_SAVINGS_FORM_SET_VALUE + 'isOnAttitude';


export const setShareEmailValue = value => ({
	type: SET_SHARE_EMAIL_VALUE,
	value,
});

export const setShareEmailValid = error => ({
	type: SET_SHARE_EMAIL_VALID,
	error,
});

export const setAttitudeValue = (value) => ({
	type: ATTITUDE_SET_VALUE,
	value,
});

export const setAttitudeValid = (error) => ({
	type: ATTITUDE_SET_VALID,
	error,
});


export const setSavingCategoryValue = (value) => ({
	type: SAVING_CATEGORY_SET_VALUE,
	value,
});

export const setSavingCategoryValid = (error) => ({
	type: SAVING_CATEGORY_SET_VALID,
	error,
});


export const setDesiredValue = (value) => ({
	type: DESIRED_SET_VALUE,
	value,
});

export const setFrequencyValue = (value) => ({
	type: FREQUENCY_SET_VALUE,
	value,
});

export const setFrequencyValid = (error) => ({
	type: FREQUENCY_SET_VALID,
	error,
});


export const setDesiredValid = (error) => ({
	type: DESIRED_SET_VALID,
	error,
});

export const setLumpsumValue = (value) => ({
	type: LUMPSUM_SET_VALUE,
	value,
});

export const setLumpsumValid = (error) => ({
	type: LUMPSUM_SET_VALID,
	error,
});

export const setStartDateValue = (value) => ({
	type: START_DATE_SET_VALUE,
	value,
});

export const setStartDateValid = (error) => ({
	type: START_DATE_SET_VALID,
	error,
});


export const setDurationValue = (value) => ({
	type: DURATION_SET_VALUE,
	value,
});

export const setDurationValid = (error) => ({
	type: DURATION_SET_VALID,
	error,
});

export const setIsOnAttitudeValue = (value) => ({
	type: IS_ON_ATTITUDE_SET_VALUE,
	value,
});


export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value
});

export const reinitial = () => ({
    type: REINITIALIZE,
});

export const setSideBarOpen = (value) => ({ type: DIGITAL_SAVINGS_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: DIGITAL_SAVINGS_SHARE_QUOTE_VALUE, value });
