import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from "../../../reducers/status";
import { AVS_RESPONSE } from "../../constants";
import { transitionScrolling } from "../../../actions/easiplusFuneralPlan/scrolling";
import { EFP_PAYMENT_DETAILS_SCROLL_ID } from "../../rulesEngine/status";
import {
    getPaymentAuthorization,
    getPaymentAuthorizationValid,
    getPaymentDetailsForm,
} from "../../../selectors/easiplusFuneralPlan/paymentDetails";
import {
    transitionPaymentDetails,
    setBankNameValue,
    setAccountNumberValue,
    setAccountTypeValue,
    setDebitOrderDayValue,
    setPaymentAuthorizationInvalid,
    setAccountValidationReset,
    setAccountValidationStatus,
} from "../../../actions/easiplusFuneralPlan/paymentDetails";
import {
    PAYMENT_DETAILS_SET_VALUE,
    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS
} from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { ACCOUNT_VALIDATION_STATUS } from "../../../actions/easiplusFuneralPlan/types";

export const paymentDetailsRules = (store, next, action) => {
    const result = next(action);
    const paymentAuthorizationValid = getPaymentAuthorizationValid(store.getState());

    const validationAction = PAYMENT_DETAILS_SET_VALUE + 'accountValidation'
    const settingValues = action.type.includes(BANK_NAME_SET_VALUE) ||
        action.type.includes(ACCOUNT_NUMBER_SET_VALUE) ||
        action.type.includes(ACCOUNT_TYPE_SET_VALUE);
    const authAction = action.type.includes(validationAction);

    // Ensures that when checkbox is untoggled the validation status modal is not displayed
    if(action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS) {
        const paymentAuthorization = getPaymentAuthorization(store.getState());
        if(!paymentAuthorization.value) {
            store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.IDLE));
        }
    }

    if (settingValues && !authAction && paymentAuthorizationValid) {
        store.dispatch(setPaymentAuthorizationInvalid(TOGGLE_STATUS.NO));
        store.dispatch(setAccountValidationReset());
    }

    const isValid = isPaymentDetailsValid(store, next, action);
    store.dispatch(isValid
        ? transitionPaymentDetails.formValid()
        : transitionPaymentDetails.formInvalid());

    return result;
};

export const performPaymentDetailsValidation = store => {
    const state = store.getState();
    const paymentDetailsForm = getPaymentDetailsForm(state);
    const paymentAuthorized = getPaymentAuthorization(state);

    store.dispatch(setBankNameValue(paymentDetailsForm.bankName.value));
    store.dispatch(setAccountNumberValue(paymentDetailsForm.accountNumber.value));
    store.dispatch(setAccountTypeValue(paymentDetailsForm.accountType.value));
    store.dispatch(setDebitOrderDayValue(paymentDetailsForm.debitOrderDay.value));
    store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.IDLE));
    if (paymentAuthorized.value !== TOGGLE_STATUS.YES)
        store.dispatch(setPaymentAuthorizationInvalid(FORM_STATUS.INVALID));

    setScrollError(store);
};

export const setScrollError = store => {
    const keyArray = EFP_PAYMENT_DETAILS_SCROLL_ID;
    const paymentDetailsForm = getPaymentDetailsForm(store.getState());

    for (let i = 0; i < keyArray.length; i++) {
        if (keyArray[i] in paymentDetailsForm && paymentDetailsForm[keyArray[i]].error) {
            store.dispatch(transitionScrolling.scrollToId(keyArray[i]));
            break;
        }
    }
};

// Personal Information
const isPaymentDetailsValid = (store) => {
    const formFields = getPaymentDetailsForm(store.getState());
    const paymentAuthorizationValid = getPaymentAuthorizationValid(store.getState());

    return paymentAuthorizationValid &&
        formFields.bankName && formFields.bankName.status === FIELD_STATUS.VALID &&
        formFields.accountNumber && formFields.accountNumber.status === FIELD_STATUS.VALID &&
        formFields.accountType && formFields.accountType.status === FIELD_STATUS.VALID &&
        formFields.debitOrderDay && formFields.debitOrderDay.status === FIELD_STATUS.VALID;
};
