import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    ID_UPLOAD_RESET_VALUE,
    ID_UPLOAD_SET_VALUE,
    ID_UPLOAD_SET_VALID,
    KRA_PIN_UPLOAD_SET_VALUE,
    KRA_PIN_UPLOAD_RESET_VALUE,
    KRA_PIN_UPLOAD_SET_VALID,
    FORM_VALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM,
    FORM_INVALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM,
} from '../../actions/personalPensionPlan/documents';

export const initDocuments = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    personalDetailsReferenceData: null,
    apiCallStatus: null,
    form: {
        idUpload: { value: null, error: null, status: null },
        kraPinUpload: { value: null, error: null, status: null },
    },
};

export default (state = initDocuments, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // ID UPLOAD
        case ID_UPLOAD_SET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: action.value,
            }));
        case ID_UPLOAD_SET_VALID:
            return updateFormField('idUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case ID_UPLOAD_RESET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: null,
            }));

        // KRA PIN UPLOAD
        case KRA_PIN_UPLOAD_SET_VALUE:
            return updateFormField('kraPinUpload', (field) => ({
                ...field,
                value: action.value,
            }));
        case KRA_PIN_UPLOAD_SET_VALID:
            return updateFormField('kraPinUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case KRA_PIN_UPLOAD_RESET_VALUE:
            return updateFormField('kraPinUpload', (field) => ({
                ...field,
                value: null,
            }));

        // Reset the state
        case REINITIALIZE:
            return initDocuments;
        default:
            return state;
    }
};
