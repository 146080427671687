import {
    transitionCustomerSatisfactionSurveyForm
} from '../../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm'

import {
    getCustomerSatisfactionSubmitPageForm
} from '../../../selectors/customerSatisfactionSurvey/customerSatisfactionSubmitForm'
import {FIELD_STATUS} from "../../../reducers/status";

export const customerSatisfactionSurveyFormRules = (store, next, action) => {
    const result = next(action);

    const customerSatisfactionSurveyForm = getCustomerSatisfactionSubmitPageForm(store.getState());
    const customerSatisfactionSurveyValid = validateFormDetails(customerSatisfactionSurveyForm);
    const formAction = customerSatisfactionSurveyValid
        ? transitionCustomerSatisfactionSurveyForm.formValid()
        : transitionCustomerSatisfactionSurveyForm.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFields.customerFeedback && formFields.customerFeedback.status === FIELD_STATUS.VALID
);
