const landlinePrefixes = [
    '010',
    '011',
    '012',
    '013',
    '014',
    '015',
    '016',
    '017',
    '018',
    '021',
    '022',
    '023',
    '024',
    '027',
    '028',
    '031',
    '032',
    '033',
    '034',
    '035',
    '036',
    '039',
    '040',
    '041',
    '042',
    '043',
    '044',
    '045',
    '046',
    '047',
    '048',
    '049',
    '051',
    '053',
    '054',
    '056',
    '057',
    '058',
];

const branchCodes = [
    '632005',
    '430000',
    '800000',
    '462005',
    '470010',
    '250655',
    '254005',
    '580105',
    '198765',
    '462005',
    '460005',
    '051001',
    '678910',
    '679000',
];

const cellNumberPrefixes = [
    '060',
    '061',
    '062',
    '063',
    '064',
    '065',
    '066',
    '067',
    '068',
    '069',
    '070',
    '071',
    '072',
    '073',
    '074',
    '076',
    '077',
    '078',
    '079',
    '081',
    '082',
    '083',
    '084',
    '085',
    '086',
    '087',
];

const botswanaCellNumberPrefixes = [
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
];

const southSudanCellNumberPrefixes = ['091', '092', '095'];

const rwandaCellNumberPrefixes = ['073', '078'];

const rwandaLandlinePrefixes = ['073', '078'];

const tanzaniaCellNumberPrefixes = ['07', '06', '02'];

const ugandaCellNumberPrefixes = [
    '07',
    '041',
    '031',
    '039',
];

const kenyaCellNumberPrefixes = [
    '070',
    '071',
    '072',
    '073',
    '074',
    '075',
    '076',
    '077',
    '078',
    '079',
    '010',
    '011'
]

const malawiCellNumberPrefixes = ['111', '31', '77', '88', '99'];

const zimbabweCellNumberPrefixes = ['077', '071', '073'];

const eswatiniCellNumberPrefixes = ['076'];

const namibiaCellNumberPrefixes = ['060', '081', '085'];

const ghanaCellNumberPrefixes = [
    '233',
    '020',
    '023',
    '024',
    '054',
    '055',
    '059',
    '027',
    '057',
    '026',
    '056',
    '028',
    '020',
    '050' 
]

const streetPostalCodes = [
    '6670',
    '9301',
    '7300',
    '5241',
    '2192',
    '6242',
    '0884',
    '6270',
    '3370',
    '1034',
    '3610',
    '1360',
    '1429',
    '5401',
    '1501',
    '2571',
    '4110',
    '6001',
    '6045',
    '4001',
    '0712',
    '6105',
    '5760',
    '6282',
    '5170',
    '1431',
    '7798',
    '7490',
    '5092',
    '1055',
    '2190',
    '5850',
    '2280',
    '0534',
    '4309',
    '0699',
    '1739',
    '2760',
    '7287',
    '7806',
    '0601',
    '0182',
    '8660',
    '7460',
    '2577',
    '2091',
    '0122',
    '1401',
    '1449',
    '9323',
    '6695',
    '1448',
    '2195',
    '4240',
    '8301',
    '2245',
    '7405',
    '2194',
    '6229',
    '2090',
    '6185',
    '5700',
    '6135',
    '7764',
    '9750',
    '0081',
    '5052',
    '1685',
    '3201',
    '1682',
    '6241',
    '9490',
    '0909',
    '1619',
    '4037',
    '1709',
    '7945',
    '1150',
    '5319',
    '5209',
    '7785',
    '1491',
    '1451',
    '4931',
    '2940',
    '2092',
    '2786',
    '5247',
    '7550',
    '4180',
    '0362',
    '7580',
    '4126',
    '4399',
    '5616',
    '3801',
    '5605',
    '4930',
    '0157',
    '0149',
    '1949',
    '2490',
    '6960',
    '1724',
    '7646',
    '2375',
    '6210',
    '7140',
    '1827',
    '8550',
    '0183',
    '0082',
    '1459',
    '6529',
    '1911',
    '7441',
    '0900',
    '1541',
    '3900',
    '0850',
    '0464',
    '7560',
    '6059',
    '5201',
    '6300',
    '0083',
    '7570',
    '1939',
    '7784',
    '2745',
    '8345',
    '4092',
    '7353',
    '2001',
    '9602',
    '7780',
    '6720',
    '6281',
    '4091',
    '6715',
    '1300',
    '8814',
    '6506',
    '4200',
    '2059',
    '6332',
    '2732',
    '4051',
    '2196',
    '3629',
    '5880',
    '7349',
    '0749',
    '7354',
    '0008',
    '0089',
    '7130',
    '1759',
    '4052',
    '0885',
    '6850',
    '7500',
    '7800',
    '1754',
    '3216',
    '6220',
    '2430',
    '0407',
    '1190',
    '0181',
    '2531',
    '0611',
    '9701',
    '1559',
    '2742',
    '0746',
    '8005',
    '2410',
    '5740',
    '5601',
    '7600',
    '0337',
    '0269',
    '1510',
    '0338',
    '1507',
    '7750',
    '5881',
    '6201',
    '1519',
    '9786',
    '8375',
    '5882',
    '4339',
    '6750',
    '1426',
    '8476',
    '6166',
    '0186',
    '6520',
    '6025',
    '6070',
    '6970',
    '1686',
    '9585',
    '5321',
    '9459',
    '5780',
    '2007',
    '8423',
    '0190',
    '1779',
    '0480',
    '1100',
    '7530',
    '2094',
    '7493',
    '6835',
    '4094',
    '2162',
    '2198',
    '8801',
    '7655',
    '6571',
    '1049',
    '9764',
    '0002',
    '6280',
    '9970',
    '6525',
    '1441',
    '8240',
    '9880',
    '7230',
    '6120',
    '6660',
    '6900',
    '5660',
    '1020',
    '2309',
    '2310',
    '0270',
    '9992',
    '2191',
    '1984',
    '7160',
    '6020',
    '7100',
    '3100',
    '2755',
    '1618',
    '0261',
    '7708',
    '5103',
    '4800',
    '2952',
    '0292',
    '9777',
    '2660',
    '9306',
    '9364',
    '6651',
    '7220',
    '0784',
    '2188',
    '7357',
    '0187',
    '1496',
    '9870',
    '9869',
    '1497',
    '7395',
    '2499',
    '2740',
    '4449',
    '8890',
    '6200',
    '0790',
    '2726',
    '2741',
    '8943',
    '9702',
    '8420',
    '1983',
    '0309',
    '0300',
    '0308',
    '4935',
    '9932',
    '8730',
    '6625',
    '9795',
    '4700',
    '0957',
    '0927',
    '8570',
    '6730',
    '2835',
    '9770',
    '0299',
    '0323',
    '8340',
    '7234',
    '2093',
    '7185',
    '9660',
    '2210',
    '9781',
    '1875',
    '1863',
    '2844',
    '9400',
    '9324',
    '8915',
    '8620',
    '7280',
    '9499',
    '2330',
    '6265',
    '4068',
    '9745',
    '0250',
    '8782',
    '2021',
    '1024',
    '0184',
    '3300',
    '1929',
    '4093',
    '1428',
    '2867',
    '9744',
    '9670',
    '9780',
    '6705',
    '4319',
    '1280',
    '1813',
    '6190',
    '1609',
    '4960',
    '4961',
    '0923',
    '6330',
    '5455',
    '9730',
    '8190',
    '2355',
    '8306',
    '3720',
    '6312',
    '9307',
    '8001',
    '0084',
    '7975',
    '1684',
    '8925',
    '1185',
    '8587',
    '2351',
    '1370',
    '5310',
    '3680',
    '4720',
    '3880',
    '6139',
    '1550',
    '1809',
    '0310',
    '9974',
    '1520',
    '0748',
    '1242',
    '1961',
    '2332',
    '2680',
    '0745',
    '5275',
    '7340',
    '2197',
    '8135',
    '7700',
    '9707',
    '5024',
    '0037',
    '1666',
    '1036',
    '9735',
    '1513',
    '1946',
    '8611',
    '3607',
    '5380',
    '5054',
    '3360',
    '2725',
    '3310',
    '2302',
    '8601',
    '1632',
    '4390',
    '8271',
    '9430',
    '5820',
    '9850',
    '2429',
    '7375',
    '1687',
    '9759',
    '4170',
    '1000',
    '0877',
    '0870',
    '8405',
    '3080',
    '7320',
    '7345',
    '4480',
    '7000',
    '9928',
    '1884',
    '6875',
    '9600',
    '9348',
    '5604',
    '2517',
    '3224',
    '1044',
    '2770',
    '8377',
    '0715',
    '1932',
    '1030',
    '0347',
    '1811',
    '2260',
    '8602',
    '9940',
    '4743',
    '9986',
    '8463',
    '8800',
    '1862',
    '1804',
    '1852',
    '2189',
    '0626',
    '2120',
    '0721',
    '1434',
    '9775',
    '5671',
    '3968',
    '1475',
    '8445',
    '2868',
    '2746',
    '0773',
    '2744',
    '1818',
    '4404',
    '2500',
    '1874',
    '5435',
    '8151',
    '6445',
    '1380',
    '1841',
    '1200',
    '1801',
    '0835',
    '3936',
    '2343',
    '3180',
    '3000',
    '2336',
    '3082',
    '5000',
    '0319',
    '0812',
    '6628',
    '0955',
    '0975',
    '7941',
    '9300',
    '7200',
    '8149',
    '1458',
    '9908',
    '9535',
    '2266',
    '0022',
    '1872',
    '1021',
    '5360',
    '1601',
    '7342',
    '7284',
    '0960',
    '5100',
    '5460',
    '5070',
    '0555',
    '0920',
    '1192',
    '1039',
    '3963',
    '2285',
    '2513',
    '1861',
    '1195',
    '3105',
    '0458',
    '3609',
    '2311',
    '2312',
    '5050',
    '3500',
    '0556',
    '1830',
    '6125',
    '2778',
    '1170',
    '3815',
    '3887',
    '1626',
    '8940',
    '9431',
    '5950',
    '9760',
    '3381',
    '9890',
    '5326',
    '0191',
    '2170',
    '9978',
    '1740',
    '0968',
    '1828',
    '4810',
    '0198',
    '2570',
    '2515',
    '4105',
    '0969',
    '2193',
    '5720',
    '9725',
    '9830',
    '7690',
    '0838',
    '0766',
    '0208',
    '0228',
    '0961',
    '8461',
    '4249',
    '8613',
    '1693',
    '8860',
    '9922',
    '8220',
    '2845',
    '0826',
    '0537',
    '9480',
    '7380',
    '4133',
    '4027',
    '3800',
    '4066',
    '0185',
    '1834',
    '2930',
    '2359',
    '7323',
    '6250',
    '3990',
    '0560',
    '2619',
    '0631',
    '5257',
    '2882',
    '6696',
    '8120',
    '1270',
    '1833',
    '0895',
    '6390',
    '3290',
    '2539',
    '8770',
    '2415',
    '7233',
    '3250',
    '1821',
    '8365',
    '0470',
    '8850',
    '8811',
    '2850',
    '7925',
    '0628',
    '2420',
    '5685',
    '3304',
    '1247',
    '0973',
    '5641',
    '3699',
    '0985',
    '6350',
    '7005',
    '4680',
    '4730',
    '2600',
    '0216',
    '7202',
    '0193',
    '6665',
    '9650',
    '1095',
    '9445',
    '2865',
    '9756',
    '9482',
    '4220',
    '0169',
    '1045',
    '0640',
    '9740',
    '5930',
    '4811',
    '9944',
    '9479',
    '7355',
    '8750',
    '8490',
    '2956',
    '0260',
    '9358',
    '8530',
    '5330',
    '5606',
    '3219',
    '3670',
    '3268',
    '6170',
    '6600',
    '9866',
    '5445',
    '2957',
    '1575',
    '2780',
    '1947',
    '9950',
    '3276',
    '4260',
    '1868',
    '1035',
    '8710',
    '9742',
    '1085',
    '0189',
    '4932',
    '9463',
    '7455',
    '6203',
    '6410',
    '2574',
    '2573',
    '2739',
    '9763',
    '1662',
    '1289',
    '1245',
    '6205',
    '8870',
    '2849',
    '7302',
    '1379',
    '0035',
    '6211',
    '0320',
    '1829',
    '1214',
    '6580',
    '6400',
    '8446',
    '2846',
    '5260',
    '4920',
    '5670',
    '1120',
    '1617',
    '2225',
    '8900',
    '4980',
    '6191',
    '9860',
    '0774',
    '2640',
    '8589',
    '5860',
    '5920',
    '3340',
    '0933',
    '2551',
    '2562',
    '1432',
    '5264',
    '8330',
    '1240',
    '7201',
    '2661',
    '2270',
    '6932',
    '7625',
    '8145',
    '7195',
    '6255',
    '9749',
    '9500',
    '0709',
    '1340',
    '4950',
    '0389',
    '9540',
    '0632',
    '0348',
    '6605',
    '2271',
    '2718',
    '8460',
    '0940',
    '3973',
    '5900',
    '1098',
    '6130',
    '1357',
    '1073',
    '4359',
    '1022',
    '9755',
    '1405',
    '7365',
    '6655',
    '5410',
    '7390',
    '8130',
    '7680',
    '6463',
    '2265',
    '2650',
    '2610',
    '0737',
    '0316',
    '0449',
    '0486',
    '1257',
    '2880',
    '2884',
    '8283',
    '8592',
    '2839',
    '0857',
    '1822',
    '0497',
    '2521',
    '0374',
    '0724',
    '0263',
    '0264',
    '0267',
    '2503',
    '0929',
    '5160',
    '4740',
    '1291',
    '8410',
    '0536',
    '9630',
    '1372',
    '2337',
    '6370',
    '8185',
    '2768',
    '2370',
    '6435',
    '9982',
    '0322',
    '1392',
    '9410',
    '4820',
    '0742',
    '8165',
    '6460',
    '0313',
    '0197',
    '5480',
    '2951',
    '5101',
    '0192',
    '9913',
    '2840',
    '2735',
    '8575',
    '2737',
    '0359',
    '0402',
    '0404',
    '0489',
    '9420',
    '0728',
    '1390',
    '0982',
    '1320',
    '9640',
    '9450',
    '0432',
    '4276',
    '2847',
    '4490',
    '0967',
    '0727',
    '0339',
    '0610',
    '9917',
    '4470',
    '8474',
    '0934',
    '0400',
    '0450',
    '4252',
    '4275',
    '0284',
    '1623',
    '9610',
    '9747',
    '1067',
    '8910',
    '5425',
    '0941',
    '1060',
    '0942',
    '0743',
    '0943',
    '1123',
    '0944',
    '0832',
    '0989',
    '8170',
    '0600',
    '1908',
    '0990',
    '0418',
    '0988',
    '0475',
    '9904',
    '1203',
    '9720',
    '1201',
    '5219',
    '5225',
    '1359',
    '1614',
    '0880',
    '3901',
    '3835',
    '2970',
    '3291',
    '0904',
    '1447',
    '4812',
    '4934',
    '0976',
    '5041',
    '1692',
    '1050',
    '1331',
    '2790',
    '8593',
    '2885',
    '0351',
    '0274',
    '0510',
    '2736',
    '0837',
    '0891',
    '0422',
    '0314',
    '2854',
    '2848',
    '0751',
    '9868',
    '9595',
    '0373',
    '1029',
    '0827',
    '5500',
    '0331',
    '6830',
    '4004',
    '6810',
    '9682',
    '0325',
    '0825',
    '7310',
    '0925',
    '0810',
    '5290',
    '2315',
    '9783',
    '8614',
    '0196',
    '0268',
    '0377',
    '2870',
    '8701',
    '4735',
    '4302',
    '5090',
    '2519',
    '0736',
    '0412',
    '2335',
    '5080',
    '3935',
    '3867',
    '0991',
    '0946',
    '0978',
    '0947',
    '0817',
    '0948',
    '0992',
    '6995',
    '0956',
    '0949',
    '8265',
    '1391',
    '7270',
    '2095',
    '3170',
    '6973',
    '0429',
    '4032',
    '1209',
    '4990',
    '5140',
    '3380',
    '5221',
    '8930',
    '6286',
    '8180',
    '5320',
    '3950',
    '9302',
    '8583',
    '0360',
    '9797',
    '5180',
    '2891',
    '0932',
    '0871',
    '0993',
    '1635',
    '0854',
    '2230',
    '8270',
    '8450',
    '0557',
    '7250',
    '8290',
    '1995',
    '2356',
    '2471',
    '9798',
    '6331',
    '8566',
    '4182',
    '6335',
    '9800',
    '8221',
    '1565',
    '5640',
    '8891',
    '2890',
    '4184',
    '2465',
    '8309',
    '9810',
    '0391',
    '9423',
    '5620',
    '1389',
    '0321',
    '0816',
    '8795',
    '0994',
    '9651',
    '1358',
    '0335',
    '2233',
    '2380',
    '1430',
    '6500',
    '0304',
    '7681',
    '9874',
    '8565',
    '4295',
    '8280',
    '5120',
    '1151',
    '6930',
    '1819',
    '8581',
    '1096',
    '4235',
    '9840',
    '5382',
    '0266',
    '0483',
    '6740',
    '9789',
    '0376',
    '9520',
    '0811',
    '4285',
    '1683',
    '1460',
    '6845',
    '1001',
    '0617',
    '1003',
    '0387',
    '8595',
    '8650',
    '1026',
    '6576',
    '9787',
    '6140',
    '3780',
    '7090',
    '5805',
    '0152',
    '2200',
    '1097',
    '9966',
    '2053',
    '1226',
    '8300',
    '2358',
    '0039',
    '6466',
    '1375',
    '9958',
    '1918',
    '1502',
    '1260',
    '0995',
    '2431',
    '2552',
    '4392',
    '0986',
    '0353',
    '7382',
    '0928',
    '0318',
    '0125',
    '1207',
    '6880',
    '3233',
    '0417',
    '6172',
    '4420',
    '0752',
    '6573',
    '0744',
    '0276',
    '0425',
    '1124',
    '1642',
    '8447',
    '0741',
    '0383',
    '2773',
    '0958',
    '4430',
    '1928',
    '0945',
    '2352',
    '1835',
    '4265',
    '0873',
    '0830',
    '0970',
    '0921',
    '0472',
    '1102',
    '1374',
    '1206',
    '1433',
    '4277',
    '4230',
    '9356',
    '5171',
    '7210',
    '3373',
    '1133',
    '8244',
    '0630',
    '9762',
    '9392',
    '9515',
    '6674',
    '6308',
    '0602',
    '7285',
    '8765',
    '2169',
    '4491',
    '1760',
    '1725',
    '2886',
    '6920',
    '0405',
    '0370',
    '8809',
    '5130',
    '0474',
    '5021',
    '1516',
    '5370',
    '8584',
    '8580',
    '0240',
    '6602',
    '0158',
    '0311',
    '9835',
    '0950',
    '3838',
    '5311',
    '6375',
    '3850',
    '1365',
    '0419',
    '2434',
    '0621',
    '1341',
    '2284',
    '6538',
    '2300',
    '0890',
    '0987',
    '2753',
    '0972',
    '0996',
    '0979',
    '0959',
    '0977',
    '0938',
    '0983',
    '2710',
    '0901',
    '0333',
    '2630',
    '6820',
    '9820',
    '9773',
    '085O',
    '5470',
    '8390',
    '4031',
    '4225',
    '1943',
    '1346',
    '2980',
    '4270',
    '2621',
    '2922',
    '0530',
    '0935',
    '8553',
    '8771',
    '8922',
    '1086',
    '8622',
    '9401',
    '0971',
    '7070',
    '2615',
    '6848',
    '9312',
    '0924',
    '2491',
    '2470',
    '0930',
    '8615',
    '8780',
    '8160',
    '0952',
    '0931',
    '6547',
    '2480',
    '5099',
    '0905',
    '8826',
    '9680',
    '1734',
    '6560',
    '2572',
    '8920',
    '0180',
    '5040',
    '8510',
    '1638',
    '1438',
    '1620',
    '0356',
    '6603',
    '0317',
    '8138',
    '0279',
    '0981',
    '0980',
    '1281',
    '4439',
    '5608',
];

const boxPostalCodes = [
    '4126',
    '5210',
    '0608',
    '0884',
    '6270',
    '3370',
    '1039',
    '1360',
    '5400',
    '3352',
    '1506',
    '4100',
    '6000',
    '6055',
    '0712',
    '6105',
    '5760',
    '6282',
    '5170',
    '5092',
    '1070',
    '2013',
    '2280',
    '0534',
    '4310',
    '0700',
    '8893',
    '1368',
    '2760',
    '5435',
    '7630',
    '7287',
    '4251',
    '2157',
    '0118',
    '2520',
    '2577',
    '1410',
    '9323',
    '6695',
    '1450',
    '1456',
    '1448',
    '4240',
    '5070',
    '8290',
    '2014',
    '2077',
    '2090',
    '2073',
    '6185',
    '6005',
    '5700',
    '6135',
    '9750',
    '0005',
    '5052',
    '1685',
    '9490',
    '0909',
    '1737',
    '1710',
    '0512',
    '1150',
    '1495',
    '1451',
    '1421',
    '3900',
    '1785',
    '4931',
    '3385',
    '2955',
    '2786',
    '5252',
    '4172',
    '0362',
    '4820',
    '3855',
    '4125',
    '4080',
    '5711',
    '5616',
    '3801',
    '4344',
    '0109',
    '0158',
    '0046',
    '0780',
    '2490',
    '1725',
    '2375',
    '4960',
    '1829',
    '8550',
    '5090',
    '0030',
    '4230',
    '3080',
    '1402',
    '0066',
    '0129',
    '1711',
    '1540',
    '0739',
    '1519',
    '1876',
    '0850',
    '6120',
    '0464',
    '3904',
    '2954',
    '9305',
    '0007',
    '1937',
    '7353',
    '9602',
    '7280',
    '1051',
    '1097',
    '3227',
    '3213',
    '3204',
    '6715',
    '3605',
    '1300',
    '5413',
    '8814',
    '6667',
    '2059',
    '3624',
    '6332',
    '1630',
    '2732',
    '7760',
    '2031',
    '4301',
    '0267',
    '1465',
    '0749',
    '0514',
    '7354',
    '0006',
    '0089',
    '0008',
    '2006',
    '8874',
    '3626',
    '1760',
    '7325',
    '4005',
    '0885',
    '2940',
    '7800',
    '4101',
    '6490',
    '1750',
    '3200',
    '7271',
    '3850',
    '0407',
    '0488',
    '0641',
    '0716',
    '5160',
    '5015',
    '1190',
    '2711',
    '5138',
    '2526',
    '2531',
    '9338',
    '0611',
    '1578',
    '2742',
    '2781',
    '0746',
    '0336',
    '2410',
    '5740',
    '3275',
    '2942',
    '4420',
    '1037',
    '0782',
    '0619',
    '2842',
    '2843',
    '9493',
    '4829',
    '0432',
    '3261',
    '2164',
    '0800',
    '1035',
    '5341',
    '8576',
    '4295',
    '5380',
    '0349',
    '0337',
    '0269',
    '1510',
    '6480',
    '0271',
    '0338',
    '1684',
    '2765',
    '7000',
    '1507',
    '1460',
    '9786',
    '8375',
    '2148',
    '5882',
    '2945',
    '6750',
    '1462',
    '1461',
    '1202',
    '2855',
    '5100',
    '2061',
    '0633',
    '0357',
    '8476',
    '9307',
    '0107',
    '2716',
    '6166',
    '1398',
    '0039',
    '6034',
    '3966',
    '4052',
    '3770',
    '4084',
    '4142',
    '3950',
    '5109',
    '6011',
    '5205',
    '8315',
    '6970',
    '5780',
    '2008',
    '0408',
    '1940',
    '5016',
    '0255',
    '2554',
    '1772',
    '0481',
    '0480',
    '1100',
    '2043',
    '7507',
    '5073',
    '7441',
    '6837',
    '4006',
    '0142',
    '4400',
    '7535',
    '8720',
    '0713',
    '1220',
    '5411',
    '2010',
    '1500',
    '1520',
    '1527',
    '1509',
    '1502',
    '1503',
    '1504',
    '2011',
    '9764',
    '4007',
    '7232',
    '1712',
    '9970',
    '0707',
    '7460',
    '1442',
    '8240',
    '3350',
    '2012',
    '7864',
    '5660',
    '4139',
    '4743',
    '3371',
    '2310',
    '2309',
    '0270',
    '6003',
    '6058',
    '9700',
    '9992',
    '7141',
    '1907',
    '3993',
    '3953',
    '3310',
    '1521',
    '3100',
    '1194',
    '5605',
    '5325',
    '4693',
    '2755',
    '2793',
    '7448',
    '5120',
    '0436',
    '1621',
    '1618',
    '3604',
    '0261',
    '2015',
    '4008',
    '3252',
    '3203',
    '8200',
    '5103',
    '3171',
    '4800',
    '2952',
    '5080',
    '7581',
    '1032',
    '5414',
    '1739',
    '2123',
    '6531',
    '2021',
    '0292',
    '9777',
    '8470',
    '2250',
    '8930',
    '0931',
    '0080',
    '0465',
    '9300',
    '9329',
    '2660',
    '9306',
    '9301',
    '8810',
    '9364',
    '0699',
    '3102',
    '7449',
    '7443',
    '7444',
    '7436',
    '2153',
    '7105',
    '1620',
    '0096',
    '9869',
    '9870',
    '1499',
    '1494',
    '6212',
    '4036',
    '8800',
    '4450',
    '4963',
    '2504',
    '6030',
    '0043',
    '4253',
    '0790',
    '2741',
    '8943',
    '0561',
    '9951',
    '9966',
    '9702',
    '2625',
    '2500',
    '2713',
    '1901',
    '0308',
    '0309',
    '1916',
    '2662',
    '0388',
    '5042',
    '7806',
    '6189',
    '1478',
    '1446',
    '0474',
    '0445',
    '4935',
    '9932',
    '0736',
    '3319',
    '0009',
    '8612',
    '1622',
    '1021',
    '1900',
    '4270',
    '8735',
    '4990',
    '5830',
    '4700',
    '0957',
    '0927',
    '0771',
    '6730',
    '5410',
    '7763',
    '8925',
    '0334',
    '0201',
    '2016',
    '1913',
    '2872',
    '9770',
    '0323',
    '1331',
    '0301',
    '8340',
    '2528',
    '2154',
    '0409',
    '2730',
    '3211',
    '1053',
    '7185',
    '6857',
    '3660',
    '7406',
    '9660',
    '8582',
    '9785',
    '2210',
    '2018',
    '9781',
    '9792',
    '3903',
    '7530',
    '1270',
    '0728',
    '0954',
    '1865',
    '2017',
    '1452',
    '1454',
    '7561',
    '5209',
    '4202',
    '2881',
    '1545',
    '2844',
    '9400',
    '9324',
    '9471',
    '8915',
    '6507',
    '5418',
    '8620',
    '1740',
    '1623',
    '6858',
    '8730',
    '1542',
    '6570',
    '1505',
    '4409',
    '2330',
    '7769',
    '6623',
    '7382',
    '0250',
    '8782',
    '2019',
    '4156',
    '2020',
    '8624',
    '0240',
    '1020',
    '9473',
    '0011',
    '0075',
    '3228',
    '2026',
    '3300',
    '3234',
    '2152',
    '2074',
    '2060',
    '2066',
    '3893',
    '5223',
    '5086',
    '6742',
    '8251',
    '4401',
    '5420',
    '4340',
    '0284',
    '1484',
    '3960',
    '4220',
    '2867',
    '4810',
    '0531',
    '8535',
    '8260',
    '0120',
    '9670',
    '9783',
    '3244',
    '4813',
    '0960',
    '5115',
    '0897',
    '4380',
    '9744',
    '5688',
    '1280',
    '1811',
    '6190',
    '0469',
    '3938',
    '1435',
    '1286',
    '0923',
    '3781',
    '5181',
    '2524',
    '5000',
    '5455',
    '0890',
    '7230',
    '7905',
    '4993',
    '6660',
    '3217',
    '8190',
    '5206',
    '5207',
    '8306',
    '4158',
    '3720',
    '8040',
    '0119',
    '6186',
    '6175',
    '5144',
    '8003',
    '8004',
    '6313',
    '8000',
    '7525',
    '7562',
    '3316',
    '7948',
    '7787',
    '0616',
    '2173',
    '1185',
    '8247',
    '0421',
    '1759',
    '3202',
    '0300',
    '4200',
    '8587',
    '1570',
    '1370',
    '5310',
    '3342',
    '3823',
    '3680',
    '3616',
    '6063',
    '4968',
    '4720',
    '3340',
    '5058',
    '0130',
    '0149',
    '6350',
    '3266',
    '6006',
    '3920',
    '0199',
    '7446',
    '6835',
    '3866',
    '3980',
    '0310',
    '4781',
    '2301',
    '2055',
    '3231',
    '4030',
    '6062',
    '0756',
    '3603',
    '7442',
    '4138',
    '5358',
    '1809',
    '1744',
    '4147',
    '1624',
    '1242',
    '0709',
    '8631',
    '1551',
    '1949',
    '2630',
    '1963',
    '2332',
    '2680',
    '0656',
    '0745',
    '1250',
    '5040',
    '1463',
    '5275',
    '0086',
    '7340',
    '2049',
    '4157',
    '1351',
    '4170',
    '5436',
    '8135',
    '7740',
    '7735',
    '9800',
    '6023',
    '9707',
    '3215',
    '5024',
    '6302',
    '1668',
    '1666',
    '1735',
    '6035',
    '3602',
    '2022',
    '1036',
    '3700',
    '4221',
    '9735',
    '7611',
    '1513',
    '1523',
    '0014',
    '1050',
    '1033',
    '0772',
    '6100',
    '5082',
    '5054',
    '8608',
    '6311',
    '3360',
    '9795',
    '6454',
    '2725',
    '6018',
    '2302',
    '4276',
    '2385',
    '4390',
    '8017',
    '2064',
    '8271',
    '9431',
    '4013',
    '7848',
    '1715',
    '0010',
    '0100',
    '5820',
    '3236',
    '3000',
    '0824',
    '3254',
    '0016',
    '1246',
    '9850',
    '0178',
    '3107',
    '4051',
    '0074',
    '2087',
    '2430',
    '1361',
    '4345',
    '1687',
    '9759',
    '0654',
    '0845',
    '5880',
    '2024',
    '3220',
    '4019',
    '7770',
    '0562',
    '3263',
    '3655',
    '2118',
    '2115',
    '7979',
    '1287',
    '7750',
    '9334',
    '5643',
    '2025',
    '1307',
    '1515',
    '4961',
    '1000',
    '5180',
    '4930',
    '3235',
    '1255',
    '8860',
    '3880',
    '6501',
    '1215',
    '8477',
    '1728',
    '1936',
    '2174',
    '4014',
    '9754',
    '1873',
    '1543',
    '1552',
    '1544',
    '5132',
    '0280',
    '0877',
    '6510',
    '7305',
    '9310',
    '8405',
    '9705',
    '4089',
    '0018',
    '5118',
    '3265',
    '7345',
    '4480',
    '0019',
    '7350',
    '3600',
    '2320',
    '4338',
    '1474',
    '4449',
    '9928',
    '1884',
    '6875',
    '8940',
    '7321',
    '7220',
    '0257',
    '6238',
    '0430',
    '6650',
    '1002',
    '6853',
    '0251',
    '6012',
    '9348',
    '5604',
    '1914',
    '2764',
    '4681',
    '2517',
    '3224',
    '0852',
    '8765',
    '2859',
    '1044',
    '1034',
    '2114',
    '2770',
    '7102',
    '1403',
    '1404',
    '8377',
    '0715',
    '1932',
    '0160',
    '0124',
    '7601',
    '1030',
    '3837',
    '0347',
    '2876',
    '0035',
    '1569',
    '0441',
    '4405',
    '6219',
    '1832',
    '1821',
    '1813',
    '1812',
    '2260',
    '9940',
    '4470',
    '8301',
    '8305',
    '7394',
    '0457',
    '8463',
    '0476',
    '7613',
    '5130',
    '1042',
    '0163',
    '8801',
    '1864',
    '1804',
    '2069',
    '0807',
    '2191',
    '2089',
    '2189',
    '2560',
    '2556',
    '9680',
    '0415',
    '9872',
    '0721',
    '9782',
    '8609',
    '1434',
    '0442',
    '0424',
    '0722',
    '5671',
    '8445',
    '2868',
    '1405',
    '9740',
    '0447',
    '8575',
    '9876',
    '2386',
    '2746',
    '8325',
    '0919',
    '0805',
    '8604',
    '8478',
    '9986',
    '8450',
    '8655',
    '3274',
    '4770',
    '1356',
    '3807',
    '4404',
    '2578',
    '3889',
    '6028',
    '3237',
    '4135',
    '2028',
    '1723',
    '1041',
    '0017',
    '0759',
    '0188',
    '8151',
    '0157',
    '4455',
    '2576',
    '4015',
    '3206',
    '2165',
    '1612',
    '6017',
    '1112',
    '3331',
    '1380',
    '3312',
    '6741',
    '1747',
    '3379',
    '1129',
    '0617',
    '1805',
    '5417',
    '2211',
    '1200',
    '9311',
    '8588',
    '8294',
    '1800',
    '4192',
    '1497',
    '0835',
    '9752',
    '1411',
    '3936',
    '9766',
    '1110',
    '2343',
    '4821',
    '3182',
    '1369',
    '5216',
    '2336',
    '1590',
    '7438',
    '1508',
    '1610',
    '4000',
    '4016',
    '7551',
    '4003',
    '3082',
    '7491',
    '4737',
    '2149',
    '0319',
    '0812',
    '2857',
    '7365',
    '4247',
    '8805',
    '6628',
    '0955',
    '0975',
    '0833',
    '0079',
    '2040',
    '2509',
    '4018',
    '1568',
    '5200',
    '2507',
    '2103',
    '1558',
    '3241',
    '1904',
    '8149',
    '3185',
    '1690',
    '1686',
    '1632',
    '1514',
    '1628',
    '3242',
    '0144',
    '0140',
    '1577',
    '1613',
    '1455',
    '4260',
    '9908',
    '2128',
    '3219',
    '6330',
    '9535',
    '7407',
    '1625',
    '5609',
    '3010',
    '1415',
    '7335',
    '2266',
    '1059',
    '7103',
    '0701',
    '9466',
    '0401',
    '0021',
    '3972',
    '5050',
    '3226',
    '5362',
    '9312',
    '7571',
    '1872',
    '0818',
    '0831',
    '1028',
    '3859',
    '8284',
    '3857',
    '1301',
    '6191',
    '4103',
    '3383',
    '2920',
    '3020',
    '8110',
    '0485',
    '1406',
    '2508',
    '1208',
    '5480',
    '7342',
    '3017',
    '2900',
    '0032',
    '0047',
    '0153',
    '0171',
    '0166',
    '0718',
    '6109',
    '7180',
    '1635',
    '7284',
    '5460',
    '0555',
    '1641',
    '0532',
    '7791',
    '1407',
    '7607',
    '7480',
    '1418',
    '0922',
    '0920',
    '4735',
    '1192',
    '4185',
    '1337',
    '4189',
    '1072',
    '4209',
    '4828',
    '3977',
    '3963',
    '4312',
    '3306',
    '2285',
    '2513',
    '1252',
    '4342',
    '0704',
    '4105',
    '2389',
    '1195',
    '1309',
    '3894',
    '0363',
    '2029',
    '3343',
    '4151',
    '3105',
    '3805',
    '4275',
    '3910',
    '3084',
    '0458',
    '3885',
    '3286',
    '2311',
    '2312',
    '1574',
    '3867',
    '4031',
    '3500',
    '2959',
    '3935',
    '0556',
    '3302',
    '1826',
    '6125',
    '2778',
    '0307',
    '1170',
    '3989',
    '2388',
    '4804',
    '5164',
    '3905',
    '7475',
    '0023',
    '0200',
    '0048',
    '0165',
    '2350',
    '3815',
    '3879',
    '4403',
    '3291',
    '4406',
    '3887',
    '5376',
    '3934',
    '3233',
    '2480',
    '1626',
    '3740',
    '4431',
    '2380',
    '9430',
    '5950',
    '1985',
    '1980',
    '1984',
    '3003',
    '3625',
    '9760',
    '2023',
    '9313',
    '3381',
    '3942',
    '5326',
    '5321',
    '3101',
    '4035',
    '3865',
    '3976',
    '3821',
    '4349',
    '9788',
    '0191',
    '0710',
    '2030',
    '2130',
    '1512',
    '4225',
    '7646',
    '9331',
    '1518',
    '4776',
    '0787',
    '9325',
    '7131',
    '9978',
    '3238',
    '1746',
    '3875',
    '2160',
    '2339',
    '2342',
    '2941',
    '2953',
    '2048',
    '0968',
    '9317',
    '9730',
    '0106',
    '3113',
    '1828',
    '7110',
    '7552',
    '7974',
    '5883',
    '1408',
    '6021',
    '0198',
    '9745',
    '7439',
    '2572',
    '2535',
    '4773',
    '1449',
    '8320',
    '1708',
    '1716',
    '2515',
    '2514',
    '0907',
    '4136',
    '0969',
    '2032',
    '1464',
    '2033',
    '4772',
    '5720',
    '5701',
    '5405',
    '5013',
    '5602',
    '3308',
    '9725',
    '0024',
    '2086',
    '9830',
    '4706',
    '7690',
    '6960',
    '9476',
    '9483',
    '2573',
    '6526',
    '1682',
    '8861',
    '8466',
    '6446',
    '0937',
    '0410',
    '0838',
    '0634',
    '0448',
    '0649',
    '0751',
    '0626',
    '0753',
    '0467',
    '0705',
    '0658',
    '0438',
    '0732',
    '8480',
    '0773',
    '1364',
    '0643',
    '0221',
    '0209',
    '0208',
    '0211',
    '0747',
    '0961',
    '9775',
    '0277',
    '9315',
    '0757',
    '0214',
    '1064',
    '0213',
    '0793',
    '0714',
    '0615',
    '0781',
    '8330',
    '8345',
    '2052',
    '0848',
    '8479',
    '4249',
    '1151',
    '1130',
    '0743',
    '6241',
    '0726',
    '2714',
    '6360',
    '0740',
    '8552',
    '8613',
    '0789',
    '8223',
    '0803',
    '1068',
    '0775',
    '1689',
    '6546',
    '2047',
    '9922',
    '8822',
    '8220',
    '0042',
    '0060',
    '2845',
    '0720',
    '8625',
    '8567',
    '4278',
    '7766',
    '4321',
    '4320',
    '3814',
    '0537',
    '3941',
    '1562',
    '9468',
    '0299',
    '3377',
    '1546',
    '2738',
    '6016',
    '0112',
    '8815',
    '7234',
    '6530',
    '2351',
    '6539',
    '6536',
    '6543',
    '3710',
    '4341',
    '2729',
    '1400',
    '2771',
    '0031',
    '3218',
    '3937',
    '3800',
    '0826',
    '0869',
    '9360',
    '1061',
    '1935',
    '1638',
    '3288',
    '9435',
    '9436',
    '1819',
    '4022',
    '2930',
    '6234',
    '4456',
    '1786',
    '4452',
    '6142',
    '2359',
    '8627',
    '3247',
    '2058',
    '4083',
    '7702',
    '3103',
    '7323',
    '2159',
    '1401',
    '5821',
    '4265',
    '5241',
    '1961',
    '4110',
    '0631',
    '5256',
    '7459',
    '2882',
    '2878',
    '7151',
    '1419',
    '1363',
    '6821',
    '6856',
    '6696',
    '6027',
    '5055',
    '6024',
    '6280',
    '8120',
    '7550',
    '7160',
    '1409',
    '6140',
    '6143',
    '6148',
    '2051',
    '6300',
    '1764',
    '7888',
    '0895',
    '6525',
    '8568',
    '8051',
    '6255',
    '6057',
    '6390',
    '3290',
    '1458',
    '5208',
    '1767',
    '2516',
    '2034',
    '0752',
    '1616',
    '1609',
    '7140',
    '2213',
    '2415',
    '2035',
    '3207',
    '7233',
    '3250',
    '4023',
    '8365',
    '0470',
    '8850',
    '0027',
    '6675',
    '8811',
    '7680',
    '2850',
    '7937',
    '0628',
    '3180',
    '2420',
    '8751',
    '5685',
    '7751',
    '0472',
    '7753',
    '0953',
    '1247',
    '5003',
    '5014',
    '6144',
    '4778',
    '0973',
    '6467',
    '5361',
    '0730',
    '5272',
    '3610',
    '5702',
    '5483',
    '6811',
    '5425',
    '1726',
    '5641',
    '6863',
    '0400',
    '0985',
    '9463',
    '0443',
    '7005',
    '7782',
    '8191',
    '3248',
    '5481',
    '4680',
    '6604',
    '1260',
    '9880',
    '2600',
    '0216',
    '6520',
    '3184',
    '8534',
    '8570',
    '0028',
    '0829',
    '3081',
    '7202',
    '3317',
    '5730',
    '6851',
    '3881',
    '0193',
    '8510',
    '1330',
    '1438',
    '6665',
    '6511',
    '9650',
    '7785',
    '1790',
    '7135',
    '1733',
    '1771',
    '2036',
    '3264',
    '1903',
    '1095',
    '1962',
    '9445',
    '0172',
    '2865',
    '4779',
    '6505',
    '0490',
    '0169',
    '3258',
    '7200',
    '1788',
    '7308',
    '6615',
    '9756',
    '9482',
    '8100',
    '8611',
    '9505',
    '0173',
    '9332',
    '0131',
    '6855',
    '4284',
    '2037',
    '0067',
    '5284',
    '4024',
    '2038',
    '3650',
    '5119',
    '1789',
    '3245',
    '3256',
    '4822',
    '3836',
    '1431',
    '3939',
    '1045',
    '1498',
    '0375',
    '3145',
    '5005',
    '0640',
    '4688',
    '6538',
    '5930',
    '5721',
    '3243',
    '3011',
    '9776',
    '2841',
    '1782',
    '2432',
    '8505',
    '4811',
    '5212',
    '1412',
    '8222',
    '9944',
    '9479',
    '7355',
    '8750',
    '3705',
    '1730',
    '6583',
    '8490',
    '2041',
    '7872',
    '0725',
    '0875',
    '7405',
    '7450',
    '1348',
    '0902',
    '7645',
    '8629',
    '6013',
    '9580',
    '1541',
    '1611',
    '2070',
    '2956',
    '0758',
    '4053',
    '8024',
    '3304',
    '5446',
    '7609',
    '0260',
    '5222',
    '2525',
    '2539',
    '9878',
    '9358',
    '8532',
    '1853',
    '2951',
    '4021',
    '4459',
    '5330',
    '5606',
    '3386',
    '4150',
    '4155',
    '7752',
    '3216',
    '1333',
    '1343',
    '2931',
    '1472',
    '2267',
    '3670',
    '0717',
    '2042',
    '9460',
    '9480',
    '5320',
    '6230',
    '1947',
    '9701',
    '5445',
    '2944',
    '2957',
    '3968',
    '1870',
    '3915',
    '0087',
    '2383',
    '3623',
    '2792',
    '0386',
    '0062',
    '0133',
    '1667',
    '3005',
    '1600',
    '4088',
    '5145',
    '4050',
    '4115',
    '2382',
    '2762',
    '0810',
    '4730',
    '0164',
    '2744',
    '9974',
    '9950',
    '3276',
    '3970',
    '4242',
    '1808',
    '1868',
    '1780',
    '2950',
    '4026',
    '8710',
    '3354',
    '1492',
    '3273',
    '9742',
    '1085',
    '6265',
    '6506',
    '0189',
    '4932',
    '1469',
    '1483',
    '1627',
    '1639',
    '6203',
    '1908',
    '2000',
    '6001',
    '6504',
    '4982',
    '3305',
    '2044',
    '6410',
    '2574',
    '2739',
    '9763',
    '3969',
    '2470',
    '1700',
    '0748',
    '1289',
    '1295',
    '6015',
    '1245',
    '6205',
    '9309',
    '1758',
    '6400',
    '1755',
    '8485',
    '8870',
    '2849',
    '7302',
    '7990',
    '1379',
    '5363',
    '8481',
    '8603',
    '0340',
    '0068',
    '8261',
    '8241',
    '1371',
    '0320',
    '2626',
    '2629',
    '2619',
    '1820',
    '8806',
    '1214',
    '6580',
    '1204',
    '3299',
    '8819',
    '7533',
    '8446',
    '0517',
    '4780',
    '1432',
    '1445',
    '7348',
    '2846',
    '7615',
    '4453',
    '0454',
    '5260',
    '4920',
    '5670',
    '0206',
    '1120',
    '4183',
    '2054',
    '1619',
    '1617',
    '2225',
    '2068',
    '2100',
    '8900',
    '7745',
    '1745',
    '2101',
    '4980',
    '4204',
    '9303',
    '7790',
    '6920',
    '7440',
    '9860',
    '0478',
    '8771',
    '5329',
    '0774',
    '2640',
    '1132',
    '0484',
    '0760',
    '8623',
    '0444',
    '8589',
    '0974',
    '5705',
    '3221',
    '5704',
    '5920',
    '7783',
    '7792',
    '4731',
    '1367',
    '0933',
    '8569',
    '0804',
    '2562',
    '1489',
    '2883',
    '2232',
    '2053',
    '5264',
    '1241',
    '0382',
    '9784',
    '2056',
    '5099',
    '8300',
    '8303',
    '4407',
    '5600',
    '6139',
    '3002',
    '1240',
    '6070',
    '4782',
    '4783',
    '6188',
    '2270',
    '6932',
    '3609',
    '6707',
    '7625',
    '1019',
    '8145',
    '7358',
    '7628',
    '6351',
    '6310',
    '6172',
    '7195',
    '6236',
    '8282',
    '6059',
    '2570',
    '2151',
    '1871',
    '3387',
    '2836',
    '7283',
    '1637',
    '5824',
    '0204',
    '7303',
    '8205',
    '6840',
    '1837',
    '3640',
    '8008',
    '9327',
    '9749',
    '1413',
    '1763',
    '2523',
    '9499',
    '0361',
    '2553',
    '8146',
    '0629',
    '7605',
    '2848',
    '5122',
    '8249',
    '0515',
    '8590',
    '5359',
    '0159',
    '8242',
    '5322',
    '1340',
    '4950',
    '5051',
    '5129',
    '5800',
    '7976',
    '0389',
    '5140',
    '0498',
    '8391',
    '2774',
    '0719',
    '9540',
    '7312',
    '0632',
    '6014',
    '3973',
    '0187',
    '0348',
    '5004',
    '8246',
    '8204',
    '6836',
    '6171',
    '7569',
    '7572',
    '2747',
    '0341',
    '8760',
    '1946',
    '6009',
    '6430',
    '9706',
    '6605',
    '3268',
    '1080',
    '2271',
    '3307',
    '0215',
    '0350',
    '9501',
    '9502',
    '9500',
    '6281',
    '1741',
    '1742',
    '1601',
    '6633',
    '6306',
    '5703',
    '8292',
    '7579',
    '5002',
    '2748',
    '0839',
    '8460',
    '8462',
    '0940',
    '5607',
    '3170',
    '5686',
    '3820',
    '3886',
    '4491',
    '3285',
    '1098',
    '3835',
    '3891',
    '1025',
    '3122',
    '3620',
    '3892',
    '3083',
    '3825',
    '1357',
    '4690',
    '4684',
    '3612',
    '6004',
    '0070',
    '0387',
    '1073',
    '3954',
    '0482',
    '1341',
    '4682',
    '4258',
    '6202',
    '3961',
    '4360',
    '4643',
    '4691',
    '4689',
    '1022',
    '3751',
    '3983',
    '3940',
    '4361',
    '3986',
    '2272',
    '3607',
    '9772',
    '3622',
    '6242',
    '6606',
    '5142',
    '4286',
    '3992',
    '1563',
    '1576',
    '1571',
    '1867',
    '3982',
    '4032',
    '5259',
    '1433',
    '2163',
    '7608',
    '4159',
    '0184',
    '7691',
    '6655',
    '9755',
    '0055',
    '3375',
    '1065',
    '0026',
    '4785',
    '6900',
    '1525',
    '1981',
    '1516',
    '1818',
    '5174',
    '1193',
    '0906',
    '8130',
    '8820',
    '1414',
    '0783',
    '4027',
    '3303',
    '7456',
    '7357',
    '7375',
    '9330',
    '1046',
    '3114',
    '2102',
    '6632',
    '7779',
    '1748',
    '5332',
    '5083',
    '6463',
    '0037',
    '4028',
    '7465',
    '1824',
    '1825',
    '1553',
    '2265',
    '2650',
    '0141',
    '0190',
    '0423',
    '0411',
    '0737',
    '0316',
    '8818',
    '6950',
    '0486',
    '0741',
    '0403',
    '0435',
    '0413',
    '1257',
    '1222',
    '0210',
    '4740',
    '2880',
    '8122',
    '0499',
    '2884',
    '1784',
    '8283',
    '2763',
    '8592',
    '1838',
    '2183',
    '1835',
    '9503',
    '0864',
    '2958',
    '7786',
    '0761',
    '0857',
    '1822',
    '0702',
    '1424',
    '2753',
    '8207',
    '1038',
    '0497',
    '1273',
    '0374',
    '0762',
    '0724',
    '5319',
    '0870',
    '0263',
    '0264',
    '0217',
    '2503',
    '2610',
    '0849',
    '0929',
    '1781',
    '2740',
    '3270',
    '1281',
    '1291',
    '0613',
    '8410',
    '0536',
    '2065',
    '2104',
    '4082',
    '9630',
    '7765',
    '5881',
    '3652',
    '2105',
    '2436',
    '3260',
    '1372',
    '2357',
    '6503',
    '2057',
    '2873',
    '2337',
    '1081',
    '6370',
    '8185',
    '2759',
    '2062',
    '2768',
    '8376',
    '8630',
    '2761',
    '4085',
    '8593',
    '3330',
    '5010',
    '2370',
    '2749',
    '2081',
    '0025',
    '4111',
    '7805',
    '1915',
    '8809',
    '3284',
    '6435',
    '8610',
    '3150',
    '4145',
    '1302',
    '6985',
    '5161',
    '9982',
    '1365',
    '5124',
    '5056',
    '1743',
    '0322',
    '8795',
    '1392',
    '6452',
    '1905',
    '3183',
    '1217',
    '5019',
    '1753',
    '9585',
    '0177',
    '0380',
    '5131',
    '8165',
    '5093',
    '4787',
    '0903',
    '7143',
    '2106',
    '7603',
    '0040',
    '0176',
    '1373',
    '0871',
    '1152',
    '0346',
    '0623',
    '0792',
    '5662',
    '0898',
    '3838',
    '0354',
    '0313',
    '0939',
    '0894',
    '0819',
    '0364',
    '3822',
    '0867',
    '0197',
    '1126',
    '0196',
    '0727',
    '2871',
    '7134',
    '5619',
    '5280',
    '5412',
    '5101',
    '0795',
    '0799',
    '8626',
    '2772',
    '2779',
    '0192',
    '9913',
    '2840',
    '8537',
    '3750',
    '0460',
    '1258',
    '5075',
    '9820',
    '0886',
    '1285',
    '2791',
    '4750',
    '2067',
    '1791',
    '0150',
    '0466',
    '4207',
    '0798',
    '0731',
    '1334',
    '2769',
    '8577',
    '3160',
    '4802',
    '0846',
    '0847',
    '3888',
    '2745',
    '3138',
    '3951',
    '6170',
    '5112',
    '0090',
    '0116',
    '0122',
    '1253',
    '0796',
    '0365',
    '5057',
    '7622',
    '7404',
    '0402',
    '1062',
    '9333',
    '0404',
    '0489',
    '9421',
    '0763',
    '2750',
    '0394',
    '0936',
    '0777',
    '1251',
    '0318',
    '2712',
    '0273',
    '0494',
    '0984',
    '3987',
    '0381',
    '0841',
    '0501',
    '1007',
    '6026',
    '1353',
    '0982',
    '0604',
    '0806',
    '1256',
    '1320',
    '3267',
    '7299',
    '0496',
    '4055',
    '2007',
    '0896',
    '0962',
    '9640',
    '0801',
    '0879',
    '0101',
    '0265',
    '7347',
    '0351',
    '2847',
    '1354',
    '8423',
    '5110',
    '6850',
    '4490',
    '5089',
    '0170',
    '7767',
    '0967',
    '1127',
    '3135',
    '4790',
    '6145',
    '9486',
    '1071',
    '8561',
    '0788',
    '8585',
    '4256',
    '0396',
    '7794',
    '8482',
    '1362',
    '9748',
    '3019',
    '3985',
    '3974',
    '0366',
    '0397',
    '0610',
    '0827',
    '9917',
    '1430',
    '1475',
    '0823',
    '8474',
    '3199',
    '9868',
    '0378',
    '2862',
    '0431',
    '0450',
    '4252',
    '8873',
    '7447',
    '2715',
    '4280',
    '0778',
    '1490',
    '1554',
    '4281',
    '4056',
    '4137',
    '1284',
    '0605',
    '6141',
    '2063',
    '1321',
    '0620',
    '9790',
    '0461',
    '0487',
    '0161',
    '0434',
    '9610',
    '9747',
    '2107',
    '6146',
    '0636',
    '1067',
    '8910',
    '2623',
    '0941',
    '2757',
    '9877',
    '1396',
    '1060',
    '0822',
    '0942',
    '0943',
    '1395',
    '1123',
    '0785',
    '0821',
    '0944',
    '1342',
    '9414',
    '5133',
    '0832',
    '7070',
    '0989',
    '0646',
    '3808',
    '2767',
    '0479',
    '1205',
    '0990',
    '2275',
    '0733',
    '0418',
    '1274',
    '0988',
    '7602',
    '0820',
    '0475',
    '6634',
    '6901',
    '0642',
    '0367',
    '8605',
    '2575',
    '9904',
    '7489',
    '0764',
    '8594',
    '1203',
    '9485',
    '0412',
    '0992',
    '0285',
    '9720',
    '0998',
    '9768',
    '5706',
    '3112',
    '1283',
    '1481',
    '4057',
    '2108',
    '3208',
    '4058',
    '2624',
    '1344',
    '3214',
    '1335',
    '1355',
    '3232',
    '7626',
    '0899',
    '0449',
    '3222',
    '5687',
    '0917',
    '5664',
    '1219',
    '4454',
    '6708',
    '4254',
    '5219',
    '5225',
    '4216',
    '5183',
    '1359',
    '0518',
    '1614',
    '1851',
    '3901',
    '1090',
    '4047',
    '4246',
    '0541',
    '7437',
    '6676',
    '9440',
    '6365',
    '2076',
    '7101',
    '7071',
    '2109',
    '2970',
    '0102',
    '0063',
    '0077',
    '9732',
    '1823',
    '4059',
    '4104',
    '9433',
    '6940',
    '0900',
    '1995',
    '9873',
    '1447',
    '0103',
    '1960',
    '3925',
    '5404',
    '4160',
    '4812',
    '4827',
    '4934',
    '0976',
    '0878',
    '5186',
    '5041',
    '5116',
    '3890',
    '1055',
    '1382',
    '2172',
    '5884',
    '5900',
    '1079',
    '8193',
    '5810',
    '1692',
    '1089',
    '1816',
    '2527',
    '2756',
    '2775',
    '7553',
    '8010',
    '6447',
    '7995',
    '7435',
    '1549',
    '6440',
    '6121',
    '5113',
    '7789',
    '4964',
    '5008',
    '4803',
    '4814',
    '5088',
    '3212',
    '3007',
    '3965',
    '5094',
    '3975',
    '3991',
    '2735',
    '0765',
    '0738',
    '0194',
    '0207',
    '0779',
    '0502',
    '0828',
    '0865',
    '8591',
    '2858',
    '2885',
    '2736',
    '0503',
    '0379',
    '0784',
    '1128',
    '5139',
    '5661',
    '4060',
    '8700',
    '1524',
    '1526',
    '1645',
    '9746',
    '0274',
    '0275',
    '2766',
    '0510',
    '8597',
    '0837',
    '8598',
    '0356',
    '0315',
    '0440',
    '0504',
    '0891',
    '6002',
    '0786',
    '0766',
    '0459',
    '2728',
    '0372',
    '0735',
    '0422',
    '0328',
    '0873',
    '0314',
    '0860',
    '1766',
    '1074',
    '0203',
    '0371',
    '2854',
    '2869',
    '0861',
    '0811',
    '0600',
    '0657',
    '0862',
    '9780',
    '2838',
    '5026',
    '0868',
    '1437',
    '8586',
    '6537',
    '0697',
    '2751',
    '8628',
    '8680',
    '0373',
    '1029',
    '5500',
    '3653',
    '0331',
    '0881',
    '2110',
    '2783',
    '0358',
    '1057',
    '4146',
    '6720',
    '0151',
    '0152',
    '4061',
    '7463',
    '4132',
    '2737',
    '1752',
    '0105',
    '9682',
    '0767',
    '0059',
    '0866',
    '0325',
    '5288',
    '0825',
    '0560',
    '2387',
    '7310',
    '4087',
    '0925',
    '0768',
    '0167',
    '0044',
    '0097',
    '9462',
    '5292',
    '2315',
    '1860',
    '1154',
    '8614',
    '0433',
    '4226',
    '5870',
    '2758',
    '6232',
    '0393',
    '6500',
    '0473',
    '0477',
    '6213',
    '6211',
    '0268',
    '0377',
    '2777',
    '0910',
    '2111',
    '1075',
    '1877',
    '2789',
    '2870',
    '8701',
    '4300',
    '1087',
    '7705',
    '0390',
    '3988',
    '1082',
    '5644',
    '0999',
    '0468',
    '9472',
    '1069',
    '3309',
    '2533',
    '1426',
    '3225',
    '5331',
    '5018',
    '4991',
    '2335',
    '3701',
    '4261',
    '5106',
    '5006',
    '3952',
    '2384',
    '0456',
    '0212',
    '4683',
    '0462',
    '5147',
    '5127',
    '5148',
    '5163',
    '5136',
    '3279',
    '4186',
    '0997',
    '3251',
    '0991',
    '1366',
    '0876',
    '0946',
    '7950',
    '0978',
    '0947',
    '0817',
    '0948',
    '0703',
    '1775',
    '4250',
    '1378',
    '6995',
    '4062',
    '0809',
    '0956',
    '7451',
    '1911',
    '0949',
    '2627',
    '5125',
    '3120',
    '0791',
    '8606',
    '5258',
    '1803',
    '4738',
    '1352',
    '4744',
    '8265',
    '1869',
    '1391',
    '3380',
    '0904',
    '7270',
    '1052',
    '3296',
    '3293',
    '6602',
    '3278',
    '6180',
    '2946',
    '5265',
    '5107',
    '5043',
    '5403',
    '5184',
    '3151',
    '4962',
    '5091',
    '3271',
    '6838',
    '3803',
    '3996',
    '3817',
    '5201',
    '8862',
    '0648',
    '6031',
    '6973',
    '6173',
    '6200',
    '2245',
    '0429',
    '3230',
    '2529',
    '3782',
    '2112',
    '7725',
    '0049',
    '4161',
    '8420',
    '2113',
    '5722',
    '5085',
    '5708',
    '1209',
    '3111',
    '5415',
    '4741',
    '5137',
    '5081',
    '3378',
    '1058',
    '0734',
    '0399',
    '5221',
    '3116',
    '3783',
    '3856',
    '3873',
    '2874',
    '6286',
    '6976',
    '8180',
    '2146',
    '0156',
    '0155',
    '0883',
    '3024',
    '5162',
    '4788',
    '3246',
    '0639',
    '0398',
    '0840',
    '6704',
    '5141',
    '3741',
    '5095',
    '4824',
    '0858',
    '3816',
    '3223',
    '5402',
    '5017',
    '4255',
    '8813',
    '5007',
    '6462',
    '8280',
    '3272',
    '3874',
    '6544',
    '5173',
    '7144',
    '3137',
    '7793',
    '4933',
    '2620',
    '7606',
    '2522',
    '1756',
    '6335',
    '1304',
    '9302',
    '6331',
    '1631',
    '8583',
    '4063',
    '6056',
    '7623',
    '2162',
    '0360',
    '4064',
    '2116',
    '1511',
    '4162',
    '4065',
    '2155',
    '9797',
    '2117',
    '3280',
    '8252',
    '5020',
    '5035',
    '3651',
    '0842',
    '3882',
    '3690',
    '3806',
    '1345',
    '3318',
    '4816',
    '5365',
    '5150',
    '4742',
    '5114',
    '0776',
    '0711',
    '5123',
    '4736',
    '2891',
    '3830',
    '3282',
    '2355',
    '1248',
    '0706',
    '8201',
    '0915',
    '0932',
    '9492',
    '9661',
    '5204',
    '7755',
    '7756',
    '4751',
    '5185',
    '3004',
    '5096',
    '1249',
    '3802',
    '0993',
    '3298',
    '2502',
    '7935',
    '2187',
    '4227',
    '9481',
    '0205',
    '0854',
    '2230',
    '4188',
    '1122',
    '8270',
    '2171',
    '5850',
    '5084',
    '7537',
    '4235',
    '0175',
    '1665',
    '2158',
    '5827',
    '0110',
    '4348',
    '7201',
    '8892',
    '0557',
    '3979',
    '9987',
    '8851',
    '2119',
    '8752',
    '8807',
    '6870',
    '3205',
    '4153',
    '1806',
    '2003',
    '9484',
    '5097',
    '2356',
    '7808',
    '2866',
    '6303',
    '6620',
    '2471',
    '6065',
    '6859',
    '0606',
    '1444',
    '4067',
    '6301',
    '7420',
    '7620',
    '7621',
    '9470',
    '6534',
    '4244',
    '1076',
    '0892',
    '8115',
    '4184',
    '4392',
    '1488',
    '9767',
    '9762',
    '8566',
    '0414',
    '7506',
    '1718',
    '9465',
    '4182',
    '1910',
    '6861',
    '2120',
    '2121',
    '2122',
    '8007',
    '7499',
    '7501',
    '7503',
    '1393',
    '7381',
    '6130',
    '8221',
    '0814',
    '3611',
    '1565',
    '1934',
    '7221',
    '5860',
    '5640',
    '5220',
    '1290',
    '8891',
    '2890',
    '9322',
    '1160',
    '3106',
    '8335',
    '2465',
    '0020',
    '6220',
    '8309',
    '1566',
    '8770',
    '9810',
    '0391',
    '9595',
    '9450',
    '5620',
    '1390',
    '0652',
    '0816',
    '5128',
    '3994',
    '7304',
    '4154',
    '0029',
    '7781',
    '0994',
    '9651',
    '1358',
    '2511',
    '0635',
    '0335',
    '1077',
    '2233',
    '1427',
    '9447',
    '1694',
    '0311',
    '3320',
    '9866',
    '9867',
    '8607',
    '0420',
    '0045',
    '8621',
    '0750',
    '7320',
    '1048',
    '3617',
    '7430',
    '0834',
    '0516',
    '2852',
    '7130',
    '4243',
    '8346',
    '0278',
    '0602',
    '2435',
    '0304',
    '6600',
    '8350',
    '7801',
    '7681',
    '9874',
    '8890',
    '4069',
    '8565',
    '0645',
    '0882',
    '8619',
    '1736',
    '6029',
    '6810',
    '3804',
    '8426',
    '2521',
    '0419',
    '0001',
    '0004',
    '0071',
    '0078',
    '0117',
    '1416',
    '1417',
    '6930',
    '6931',
    '1774',
    '7196',
    '2530',
    '4113',
    '1817',
    '4430',
    '1815',
    '1831',
    '1814',
    '0305',
    '7281',
    '2889',
    '8581',
    '1096',
    '9840',
    '5327',
    '5188',
    '5645',
    '3978',
    '3872',
    '5059',
    '5384',
    '5104',
    '5111',
    '5382',
    '3964',
    '0058',
    '4078',
    '4364',
    '3277',
    '4070',
    '0121',
    '5211',
    '9774',
    '5105',
    '4187',
    '1688',
    '0266',
    '5672',
    '0145',
    '1709',
    '0483',
    '2124',
    '2839',
    '6739',
    '9789',
    '1482',
    '0638',
    '2754',
    '0376',
    '0729',
    '9521',
    '5611',
    '0863',
    '0495',
    '0195',
    '9664',
    '0797',
    '4285',
    '2147',
    '2125',
    '2167',
    '1776',
    '1457',
    '1683',
    '2156',
    '0286',
    '0513',
    '1751',
    '0416',
    '0637',
    '0253',
    '1443',
    '7504',
    '1477',
    '6845',
    '1001',
    '2009',
    '0053',
    '3295',
    '4071',
    '1931',
    '0252',
    '8105',
    '6215',
    '4801',
    '0426',
    '1003',
    '1466',
    '9488',
    '8595',
    '4181',
    '9326',
    '1439',
    '4090',
    '4148',
    '2534',
    '1004',
    '1026',
    '7965',
    '7352',
    '1049',
    '6576',
    '0405',
    '0344',
    '8050',
    '9787',
    '7707',
    '1153',
    '3780',
    '7090',
    '0303',
    '2168',
    '1701',
    '2099',
    '5805',
    '7306',
    '7307',
    '9469',
    '6450',
    '0179',
    '2200',
    '8202',
    '2231',
    '0174',
    '1084',
    '3930',
    '1564',
    '4017',
    '6670',
    '3239',
    '1226',
    '4399',
    '2538',
    '7250',
    '7400',
    '2175',
    '6705',
    '2358',
    '4034',
    '5087',
    '9328',
    '0580',
    '8012',
    '1131',
    '1223',
    '4752',
    '5364',
    '7701',
    '1423',
    '6541',
    '0500',
    '0384',
    '1375',
    '8253',
    '2743',
    '0154',
    '8745',
    '3351',
    '2129',
    '1066',
    '3016',
    '6176',
    '4171',
    '3301',
    '2075',
    '4072',
    '5437',
    '9958',
    '0723',
    '1732',
    '6499',
    '1468',
    '0406',
    '1938',
    '1501',
    '2496',
    '9432',
    '0995',
    '5680',
    '2431',
    '2552',
    '7395',
    '2341',
    '6150',
    '8731',
    '4391',
    '0986',
    '3818',
    '8121',
    '8289',
    '0708',
    '0353',
    '6854',
    '2131',
    '4206',
    '7532',
    '4687',
    '8491',
    '5134',
    '7582',
    '3615',
    '6812',
    '0928',
    '0125',
    '5149',
    '4039',
    '2132',
    '7975',
    '5253',
    '1207',
    '6060',
    '1336',
    '2780',
    '4180',
    '3209',
    '4241',
    '8060',
    '4094',
    '0417',
    '4141',
    '1982',
    '1983',
    '6573',
    '7977',
    '0355',
    '0744',
    '0276',
    '1047',
    '2787',
    '0369',
    '0425',
    '0651',
    '0802',
    '8599',
    '1124',
    '0794',
    '1063',
    '5213',
    '1567',
    '0609',
    '2788',
    '0505',
    '3281',
    '1054',
    '0754',
    '1394',
    '0836',
    '2145',
    '9600',
    '0808',
    '0385',
    '0815',
    '8465',
    '0220',
    '0813',
    '5377',
    '4791',
    '1642',
    '8808',
    '8447',
    '0742',
    '2782',
    '4774',
    '0383',
    '2773',
    '0471',
    '3249',
    '5750',
    '4215',
    '0958',
    '4381',
    '0219',
    '8596',
    '4079',
    '1933',
    '1282',
    '5187',
    '0945',
    '2352',
    '9704',
    '5709',
    '1008',
    '0830',
    '0970',
    '3967',
    '1216',
    '6131',
    '1332',
    '3871',
    '5172',
    '6061',
    '5707',
    '4753',
    '3139',
    '4362',
    '4992',
    '1349',
    '0054',
    '5247',
    '0127',
    '0081',
    '0128',
    '7670',
    '3313',
    '6535',
    '0921',
    '9778',
    '5135',
    '7133',
    '4152',
    '3022',
    '6206',
    '5610',
    '0306',
    '1102',
    '2960',
    '0218',
    '0232',
    '9663',
    '0644',
    '2851',
    '1350',
    '9703',
    '0506',
    '3229',
    '2895',
    '6572',
    '4074',
    '3210',
    '8257',
    '1227',
    '0964',
    '1773',
    '1023',
    '7137',
    '7129',
    '3374',
    '7583',
    '1374',
    '1212',
    '1944',
    '0258',
    '1206',
    '7500',
    '0108',
    '0168',
    '2136',
    '7624',
    '4277',
    '2135',
    '8484',
    '5375',
    '7880',
    '2082',
    '4122',
    '2134',
    '2861',
    '2137',
    '1560',
    '1425',
    '9487',
    '6385',
    '3006',
    '5171',
    '4642',
    '6312',
    '7390',
    '7946',
    '5328',
    '1906',
    '1043',
    '4686',
    '4025',
    '3613',
    '2433',
    '7210',
    '2078',
    '1133',
    '7947',
    '7945',
    '1347',
    '0533',
    '1213',
    '8244',
    '8650',
    '7599',
    '3283',
    '0630',
    '9464',
    '9515',
    '6250',
    '2550',
    '2551',
    '6674',
    '1056',
    '5501',
    '6308',
    '7252',
    '7139',
    '7788',
    '8816',
    '7285',
    '0455',
    '6451',
    '9475',
    '5182',
    '8483',
    '6019',
    '6107',
    '7985',
    '4458',
    '2161',
    '6115',
    '2072',
    '0132',
    '6008',
    '1470',
    '2886',
    '7762',
    '0612',
    '0507',
    '1787',
    '6743',
    '0428',
    '2887',
    '1254',
    '1754',
    '3115',
    '4710',
    '0370',
    '0051',
    '6209',
    '2835',
    '0622',
    '0036',
    '6740',
    '9883',
    '1698',
    '0332',
    '1420',
    '5108',
    '8560',
    '3023',
    '5021',
    '5614',
    '0859',
    '5370',
    '1749',
    '1040',
    '0660',
    '0912',
    '8584',
    '8580',
    '3315',
    '5214',
    '9769',
    '2004',
    '0012',
    '1634',
    '1640',
    '9758',
    '9771',
    '0143',
    '1807',
    '1271',
    '9478',
    '9474',
    '4343',
    '0918',
    '2860',
    '4106',
    '6032',
    '2334',
    '1522',
    '6033',
    '5072',
    '1384',
    '0061',
    '4134',
    '0126',
    '1218',
    '0041',
    '6545',
    '4363',
    '1440',
    '9410',
    '0769',
    '0950',
    '2785',
    '6307',
    '6375',
    '0856',
    '7485',
    '3760',
    '3621',
    '0913',
    '1941',
    '4457',
    '2276',
    '3287',
    '2434',
    '0056',
    '9489',
    '4786',
    '0603',
    '2532',
    '4784',
    '0439',
    '8616',
    '0287',
    '1397',
    '0889',
    '2877',
    '0492',
    '1765',
    '0345',
    '7966',
    '4771',
    '0690',
    '0621',
    '5121',
    '1303',
    '1377',
    '1111',
    '0755',
    '2329',
    '8618',
    '0134',
    '6880',
    '0329',
    '0508',
    '8147',
    '1547',
    '0618',
    '2300',
    '2139',
    '1548',
    '1121',
    '4789',
    '5416',
    '8617',
    '9875',
    '2752',
    '5663',
    '0951',
    '0659',
    '5613',
    '0987',
    '0916',
    '0972',
    '0996',
    '0979',
    '0959',
    '0977',
    '0491',
    '0938',
    '0983',
    '2710',
    '0901',
    '0963',
    '4826',
    '1078',
    '9885',
    '0914',
    '0453',
    '6309',
    '0333',
    '4451',
    '4492',
    '0437',
    '6820',
    '2140',
    '0893',
    '6411',
    '9467',
    '3255',
    '0452',
    '0493',
    '5191',
    '7536',
    '7505',
    '9773',
    '5190',
    '5323',
    '5470',
    '0052',
    '2921',
    '8390',
    '3981',
    '4075',
    '4120',
    '4350',
    '4193',
    '4098',
    '4054',
    '3742',
    '4091',
    '3730',
    '4144',
    '3259',
    '3262',
    '3297',
    '4201',
    '3257',
    '7539',
    '7612',
    '6460',
    '0003',
    '0148',
    '9477',
    '1942',
    '5117',
    '9321',
    '4041',
    '3884',
    '4402',
    '5379',
    '0624',
    '1346',
    '2784',
    '2980',
    '0770',
    '3269',
    '1945',
    '2621',
    '2922',
    '1948',
    '0650',
    '0530',
    '2425',
    '1795',
    '0935',
    '5419',
    '0137',
    '8553',
    '1027',
    '7978',
    '1485',
    '3372',
    '6231',
    '1629',
    '7540',
    '9945',
    '8467',
    '8170',
    '6690',
    '8922',
    '6201',
    '3984',
    '0139',
    '9798',
    '2717',
    '1930',
    '1086',
    '8622',
    '9401',
    '9882',
    '6671',
    '2727',
    '1453',
    '0971',
    '0926',
    '5102',
    '2615',
    '9520',
    '1479',
    '0607',
    '6848',
    '5217',
    '0539',
    '0924',
    '8018',
    '0342',
    '2491',
    '6631',
    '7604',
    '8824',
    '6624',
    '2226',
    '0930',
    '0451',
    '1005',
    '0259',
    '8615',
    '8780',
    '1486',
    '1473',
    '9835',
    '2141',
    '7380',
    '8160',
    '8161',
    '2776',
    '8600',
    '0844',
    '5143',
    '3858',
    '1691',
    '0952',
    '7171',
    '6547',
    '1422',
    '7570',
    '7656',
    '3631',
    '4493',
    '0050',
    '2333',
    '9890',
    '4140',
    '8530',
    '4010',
    '3902',
    '8002',
    '0324',
    '0065',
    '4339',
    '0905',
    '3388',
    '4073',
    '0135',
    '7614',
    '2497',
    '3325',
    '8826',
    '1517',
    '1572',
    '7538',
    '7654',
    '5012',
    '1714',
    '9438',
    '2495',
    '3384',
    '9437',
    '2144',
    '7379',
    '5612',
    '2353',
    '1211',
    '5218',
    '7301',
    '7433',
    '0146',
    '2501',
    '1734',
    '2142',
    '3608',
    '9765',
    '7802',
    '3630',
    '3634',
    '3635',
    '3633',
    '4685',
    '4102',
    '6666',
    '5360',
    '1717',
    '5189',
    '9380',
    '0057',
    '0312',
    '6560',
    '2571',
    '8920',
    '0095',
    '6445',
    '9320',
    '5079',
    '1731',
    '2005',
    '9420',
    '1471',
    '1459',
    '1729',
    '6304',
    '1467',
    '0330',
    '2888',
    '5825',
    '2632',
    '1794',
    '2050',
    '6603',
    '7322',
    '5011',
    '6830',
    '6237',
    '0138',
    '0033',
    '1101',
    '0317',
    '4163',
    '0076',
    '2080',
    '0072',
    '7915',
    '4099',
    '6849',
    '6864',
    '6862',
    '8468',
    '4775',
    '8138',
    '7282',
    '0647',
    '3641',
    '7824',
    '0279',
    '0981',
    '0872',
    '0843',
    '0980',
    '0202',
    '4011',
    '2143',
    '7425',
    '7351',
    '4825',
    '9571',
    '3109',
    '3382',
    '5381',
    '4418',
    '4422',
    '4777',
    '5615',
    '0302',
    '1024',
    '5146',
    '6656',
    '1288',
    '1878',
    '1436',
    '2622',
    '3341',
    '6187',
    '1912',
    '2340',
    '6852',
    '3614',
    '1866',
    '5608',
    '6204',
];

const nigeriaCellNumberPrefixes = [
    '07025',
    '07026',
    '0701',
    '0703',
    '0704',
    '0705',
    '0706',
    '0708',
    '0802',
    '0803',
    '0805',
    '0806',
    '0807',
    '0808',
    '0809',
    '0810',
    '0811',
    '0812',
    '0813',
    '0814',
    '0815',
    '0816',
    '0817',
    '0818',
    '0901',
    '0902',
    '0903',
    '0905',
    '0906',
    '0907',
    '0908',
    '0909'
]

const exports = {
    landlinePrefixes,
    rwandaLandlinePrefixes,
    branchCodes,
    cellNumberPrefixes,
    botswanaCellNumberPrefixes,
    southSudanCellNumberPrefixes,
    rwandaCellNumberPrefixes,
    tanzaniaCellNumberPrefixes,
    ugandaCellNumberPrefixes,
    malawiCellNumberPrefixes,
    zimbabweCellNumberPrefixes,
    eswatiniCellNumberPrefixes,
    namibiaCellNumberPrefixes,
    kenyaCellNumberPrefixes,
    ghanaCellNumberPrefixes,
    streetPostalCodes,
    boxPostalCodes,
    nigeriaCellNumberPrefixes,
};

export default exports;
