import { COMPLAINT_FORM_SEND_INITIATE, COMPLAINT_FORM_SET_VALUE, TOGGLE_DRAWER } from '../../../actions/customerComplaint/complaintArbitration'
import { complaintArbitrationFieldTracking, complaintAritrationTransitionTracking } from './complaintArbitration'

export const customerComplaintTracking = (store, next, action) => {
    if (action.type === TOGGLE_DRAWER || action.type === COMPLAINT_FORM_SEND_INITIATE) {
        return complaintAritrationTransitionTracking(store, next, action);
    }
    
    if (action.type.startsWith(COMPLAINT_FORM_SET_VALUE)) {
        return complaintArbitrationFieldTracking(store, next, action);
    }
}
