import {
    PHONE_NUMBER_SET_VALUE,
    setPhoneNumberValid,
    GENDER_SET_VALUE,
    setGenderValid,
    DOB_SET_VALUE,
    setDOBValid,
    INTEREST_RATE_SET_VALUE,
    setInterestRateValid,
    DESIRED_MONTHLY_CONTRIBUTION_SET_VALUE,
    setDesiredMonthlyContributionValid,
    LUMPSUM_AMOUNT_SET_VALUE,
    setLumpsumAmountValid,
    DESIRED_RETIREMENT_AGE_SET_VALUE,
    setDesiredRetirementAgeValid,
    SET_IS_LUMPSUM_PREPAID,
    setIsLumpsumPrepaidValid,
    PREPAID_MODE_OF_PAYMENT_VALUE,
    setPrepaidModeOfPaymentValid,
    PREPAID_TRANSACTION_CODE_VALUE,
    setPrepaidTransactionCodeValid,
    CONTRIBUTION_MODE_SET_VALUE,
    setContributionModeValid,
} from '../../../actions/personalPensionPlan/quote';
import { getDobValue } from '../../../selectors/personalPensionPlan/quote';
import {
    validateDateOfBirth,
    validateSingleSelection,
    validateNumber,
    validateContactNumber,
    validateAlphaNumericString,
} from '@om/validation';

import { validationIsEqual } from '../status';
let prevValidationAction = null;

export const quoteFormValidation = (store, next, action) => {
    //const quoteForm = getQuotationForm(store.getState());
    const validationAction = (() => {
        let error;

        const dob = getDobValue(store.getState());

        function calculateNextAge(birthdate) {
            const [day, month, year] = birthdate.split('/').map(Number);

            const birthDate = new Date(year, month - 1, day);

            const today = new Date();

            let age = today.getFullYear() - birthDate.getFullYear();

            const currentYearBirthday = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
            if (today < currentYearBirthday) {
                age--;
            }

            return age + 1;
        }

        switch (action.type) {
            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, 'Phone Number');
                return setPhoneNumberValid(error);

            case GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Gender',
                });
                return setGenderValid(error);

            case CONTRIBUTION_MODE_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Contribution Mode',
                });
                return setContributionModeValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 18, max: 65 });

                return setDOBValid(error);

            case INTEREST_RATE_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Interest Rate',
                });
                return setInterestRateValid(error);

            case DESIRED_MONTHLY_CONTRIBUTION_SET_VALUE:
                error = validateNumber(action.value, { min: 500, subject: 'Desired Monthly Contribution' });
                return setDesiredMonthlyContributionValid(error);

            case LUMPSUM_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, { min: 1000, subject: 'Lumpsum Amount' });
                return setLumpsumAmountValid(error);

            case DESIRED_RETIREMENT_AGE_SET_VALUE:
                const dob = getDobValue(store.getState());

                const dobError = validateDateOfBirth(dob.value);

                if (dobError === null) {
                    const nextAge = calculateNextAge(dob.value);

                    const minRetirementAge = Math.min(nextAge, 65);

                    error = validateNumber(action.value, {
                        min: minRetirementAge,
                        max: 65,
                        subject: 'Desired Retirement Age',
                    });
                    return setDesiredRetirementAgeValid(error);
                } else {
                    return setDesiredRetirementAgeValid('The date of birth you entered is not valid');
                }

            case SET_IS_LUMPSUM_PREPAID:
                error = validateSingleSelection(action.value, {
                    subject: 'Have you deposited the lumpsum',
                });
                return setIsLumpsumPrepaidValid(error);

            case PREPAID_MODE_OF_PAYMENT_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Prepaid Mode of Payment',
                });
                return setPrepaidModeOfPaymentValid(error);

            case PREPAID_TRANSACTION_CODE_VALUE:
                error = validateAlphaNumericString(action.value, { min: 6, subject: 'Prepaid Transaction Code' });
                return setPrepaidTransactionCodeValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
