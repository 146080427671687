import { transitionDoctorsPiDeclarations } from "../../../actions/doctorsPi/documents";
import { FIELD_STATUS } from "../../../reducers/status";
import { getDeclarationsFrom } from "../../../selectors/doctorsPi/documents";

export const declarationsFormRules = (store, next, action) => {
    const result = next(action);
    const declarationsForm = getDeclarationsFrom(store.getState());
    const declarationsFormValid = validateDeclarations(declarationsForm);
    const formAction = declarationsFormValid
        ? transitionDoctorsPiDeclarations.formValid()
        : transitionDoctorsPiDeclarations.formInvalid()
    store.dispatch(formAction);
    return result
}

const validateDeclarations = (formFields) => {
    return (
        formFields.decOne &&
        formFields.decOne.status === FIELD_STATUS.VALID &&
        formFields.decTwo &&
        formFields.decTwo.status === FIELD_STATUS.VALID &&
        formFields.decThree &&
        formFields.decThree.status === FIELD_STATUS.VALID &&
        formFields.decFour &&
        formFields.decFour.status === FIELD_STATUS.VALID &&
        formFields.decFive &&
        formFields.decFive.status === FIELD_STATUS.VALID &&
        formFields.decSix &&
        formFields.decSix.status === FIELD_STATUS.VALID
    );
};