import * as ActionTypes from './types';

export function moveToFailurePage() {
    return {
        type: ActionTypes.MOVE_TO_FAILURE_PAGE
    };
}

export function moveToNextPage() {
    return {
        type: ActionTypes.MOVE_TO_NEXT_PAGE
    }
}

export function skipToPage() {
    return {
        type: ActionTypes.SKIP_TO_PAGE
    }
}

export function moveToPreviousPage() {
    return {
        type: ActionTypes.MOVE_TO_PREVIOUS_PAGE
    }
}

export function navigateNext(){
    return {
        type: ActionTypes.NAVIGATE_NEXT
    }
}

export function navigateBack(){
    return {
        type: ActionTypes.NAVIGATE_BACK
    }
}

export function setReturnToProduct(){
    return {
        type: ActionTypes.RETURN_TO_PRODUCT_PAGE
    }
}

export function schedulePageRequests(requestTypes) {
    return {
        type: ActionTypes.SEND_PAGE_REQUESTS,
        requests: requestTypes
    };
}

export function makePageRequest(requestType) {
    return {
        type: requestType
    };
}

export function resetPageRequests() {
    return {
        type: ActionTypes.RESET_PAGE_REQUESTS
    };
}

export function requestFail(requestType){
    return {
        type: requestType
    };
}

export function setResponse(requestType,data){
    return {
        type: requestType,
        data: data,
    };
}

export function resetApplicationNowResponse(){
    return{
        type: ActionTypes.RESET_APPLICATION_NOW
    }
}

export function resetApplicationFlow() {
    return {
        type: ActionTypes.DO_RESET_APPLICATION_FLOW
    };
}

export function setCanProceed(canProceed){
    return {
        type: ActionTypes.SET_CAN_PROCEED,
        canProceed: canProceed
    }
}

export function setDocumentUploadSkip(boolean){
    return {
        type: ActionTypes.DOCUMENT_UPLOAD_SKIP,
        boolean
    }
}