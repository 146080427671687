import { atRetirementRules } from './atRetirement/index';
import { raOptimalRules } from './raOptimal/index';
import { publicWebRules } from './publicWeb/index';
import { easiplusFuneralPlanRules } from './easiplusFuneralPlan/index';
import { customerVerificationServiceRules } from './customerVerificationService';
import { covidAssessmentRules } from './covidSelfAssessment';
import { incomeTaxCalculatorRules } from './incomeTaxCalculator';
import { fundCentreRules } from './fundCentre';
import { personalLoansCalculatorRules } from './personalLoansCalculator';
import { savingsCalculatorRules } from './savingsCalculator';
import { roaQuoteToolsRules } from './roaQuoteTools';
import { taxFreeSavingsAccountRules } from './taxFreeSavingsAccount';
import { retirmentCalculatorRules } from './retirmentCalculator';
import { customerComplaintRules } from './customerComplaint';
import { budgetBenchmarkRules } from './budgetBenchmarkCalculator';
import { roaCarInsuranceRules } from './roaCarInsurance';
import { priceTableRules } from './priceTable';
import { roaTravelInsuranceRules } from './roaTravelInsurance';
import { pastPerfomanceCalculatorRules } from './pastPerfomanceCalculator';
import { educationCalculatorRules } from './educationCalculator';
import { customerSatisfactionSurveyRules } from './customerSatisfactionSurvey';
import { investmentCalculatorRules } from './investmentCalculator';
import { makeAClaimRules } from './makeAClaim';
import { ugMotorInsuranceRules } from './ugMotorInsurance';
import { domesticInsuranceRules } from './domesticInsurance';
import { biasharaFlexiRules } from './biasharaFlexi';
import { digiLifeLoanProtectionRules } from './digiLifeLoanProtection';
import { travelInsuranceRules } from './travel';
import { doctorsPiRules } from './doctorsPi';
import { personalAccidentRules } from './personalAccident';
import { personalPensionPlanRules } from './personalPensionPlan';
import { allsureInsuranceRules } from './allsureInsurance';
import { savingsPotCalculatorRules } from './savingsPotCalculator';
import { digitalSavingsRules } from './digitalSavings';
import { healthInsuranceRules } from './healthInsuranceRW';
import { rwMotorInsuranceRules } from './motorPrivateRW';

export const rulesEngine = (store) => (next) => (action) => {
    const executedRules =
        rwMotorInsuranceRules(store, next, action) ||
        healthInsuranceRules(store, next, action) ||
        personalPensionPlanRules(store, next, action) ||
        digitalSavingsRules(store, next, action) ||
        domesticInsuranceRules(store, next, action) ||
        travelInsuranceRules(store, next, action) ||
        atRetirementRules(store, next, action) ||
        raOptimalRules(store, next, action) ||
        covidAssessmentRules(store, next, action) ||
        incomeTaxCalculatorRules(store, next, action) ||
        allsureInsuranceRules(store, next, action) ||
        easiplusFuneralPlanRules(store, next, action) ||
        fundCentreRules(store, next, action) ||
        publicWebRules(store, next, action) ||
        personalLoansCalculatorRules(store, next, action) ||
        savingsCalculatorRules(store, next, action) ||
        customerVerificationServiceRules(store, next, action) ||
        roaQuoteToolsRules(store, next, action) ||
        taxFreeSavingsAccountRules(store, next, action) ||
        retirmentCalculatorRules(store, next, action) ||
        priceTableRules(store, next, action) ||
        customerComplaintRules(store, next, action) ||
        budgetBenchmarkRules(store, next, action) ||
        roaTravelInsuranceRules(store, next, action) ||
        pastPerfomanceCalculatorRules(store, next, action) ||
        educationCalculatorRules(store, next, action) ||
        roaCarInsuranceRules(store, next, action) ||
        customerSatisfactionSurveyRules(store, next, action) ||
        investmentCalculatorRules(store, next, action) ||
        makeAClaimRules(store, next, action) ||
        ugMotorInsuranceRules(store, next, action) ||
        biasharaFlexiRules(store, next, action) ||
        doctorsPiRules(store, next, action) ||
        digiLifeLoanProtectionRules(store, next, action) ||
        personalAccidentRules(store, next, action) ||
        makeAClaimRules(store, next, action) ||
        ugMotorInsuranceRules(store, next, action) ||
        savingsPotCalculatorRules(store, next, action);

    if (!executedRules) {
        return next(action);
    }

    return executedRules;
};
