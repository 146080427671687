import {
    SET_PLATFORM_OPTIONS,
    SET_USER_TYPES,
    setUserTypesValue,
    setPlatformOptionsValue,
    setUserTypeValue,
    setLearnerPlatformRepresentativeValue,
    CUSTOMER_SEND,
} from '../../../actions/ltd/ltd';

import axios from 'axios';
import {
    getCheckboxValue,
    getDownloadsValue,
    getEmailValue,
    getOrganisationIdValue,
    getOrganisationValue,
    getUserType,
} from '../../../selectors/ltd/ltd';

export const ltd = (store, next, action) => {
    switch (action.type) {
        case SET_PLATFORM_OPTIONS:
            (async () => {
                try {
                    const response = await axios.get('/om-api/ltd/organisation/status/Approved')
                    store.dispatch(setPlatformOptionsValue(response.data));
                } catch (e) {
                    // What now?
                }
            })();
            return next(action);
        case SET_USER_TYPES:
            (async () => {
                try {
                    const response = await axios.get('/om-api/ltd/customer/userTypes')
                    store.dispatch(
                        setLearnerPlatformRepresentativeValue({
                            id: response.data[0].id.toString(),
                            description: response.data[0].description,
                        })
                    );
                    store.dispatch(setUserTypeValue, response.data[0].id);
                    store.dispatch(setUserTypesValue(response.data));
                } catch (e) {
                    // What now?
                }
            })();
            return next(action);
        case CUSTOMER_SEND:
            (async () => {
                let customer = {};

                const state = store.getState();
                const userType = getUserType(state);
                const organisationId = getOrganisationIdValue(state);
                const email = getEmailValue(state);
                const organisation = getOrganisationValue(state);
                const checkbox = getCheckboxValue(state);
                const downloads = getDownloadsValue(state);

                try {
                    if (organisationId === -1) {
                        const statuses = await axios.get('/om-api/ltd/organisation/statuses');

                        const newStatus = statuses.data.map((status) => {
                            return {
                                id: status.id,
                                status: status.description.toLowerCase(),
                            };
                        });

                        const pendingId = newStatus
                            .map((element) => {
                                return element.status.indexOf('pending') >= 0 ? element.id : '';
                            })
                            .filter(Number)[0];

                        const organisationToSave = {
                            name: organisation,
                            isPartner: false,
                            statusId: pendingId,
                        };

                        const response = await axios.post('/om-api/ltd/organisation', organisationToSave)
                        customer = {
                            email: email.value,
                            termsAccepted: checkbox,
                            customerDownloads: downloads,
                            userType: userType,
                            organization: response.data[0].id,
                        };
                        axios.post('/om-api/ltd/customer', customer);
                    } else {
                        customer = {
                            email: email.value,
                            termsAccepted: checkbox,
                            customerDownloads: downloads,
                            userTypeId: userType,
                            organisationId: organisationId,
                        };
                        axios.post('/om-api/ltd/customer', customer);
                    }
                } catch (e) {
                    // What now?
                }
            })();
            return next(action);
        default:
            return null;
    }
};
