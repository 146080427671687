import { validationIsEqual } from '../status';
import { validateName } from '@om/validation';
import {
    DRIVER_HEALTH_DECLARATION_SET_VALUE,
    VEHICLE_CONDITION_DECLARATION_SET_VALUE,
    DRIVER_LICENSE_DECLARATION_SET_VALUE,
    AVERAGE_CLAUSE_CONSENT_SET_VALUE,
    FULL_VALUE_DECLARATION_SET_VALUE,
    MARKETING_CONSENT_SET_VALUE,
    PRIVACY_POLICY_CONFIRMATION_SET_VALUE,
    ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALUE,
    NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALUE,
    FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALUE,
    FINAL_DECLARATION_AGREEMENT_SET_VALUE,
    setDriverHealthDeclarationValid,
    setVehicleConditionDeclarationValid,
    setDriverLicenseDeclarationValid,
    setAverageClauseConsentValid,
    setFullValueDeclarationValid,
    setMarketingConsentValid,
    setPrivacyPolicyConfirmationValid,
    setAdviceAcknowledgementIndemnityValid,
    setNoPremiumRefundAcknowledgementValid,
    setFullPremiumSettlementConsentValid,
    setFinalDeclarationAgreementValid,
} from '../../../actions/motorPrivateRW/declarations';

let prevValidationAction = null;

export const declarationsFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case DRIVER_HEALTH_DECLARATION_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Driver Health Declaration',
                    required: true,
                });
                return setDriverHealthDeclarationValid(error);

            case VEHICLE_CONDITION_DECLARATION_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Vehicle Condition Declaration',
                    required: true,
                });
                return setVehicleConditionDeclarationValid(error);

            case DRIVER_LICENSE_DECLARATION_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Driver License Declaration',
                    required: true,
                });
                return setDriverLicenseDeclarationValid(error);

            case AVERAGE_CLAUSE_CONSENT_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Average Clause Consent',
                    required: true,
                });
                return setAverageClauseConsentValid(error);

            case FULL_VALUE_DECLARATION_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Full Value Declaration',
                    required: true,
                });
                return setFullValueDeclarationValid(error);

            case MARKETING_CONSENT_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Marketing Consent',
                    required: false, // Usually marketing consent is optional
                });
                return setMarketingConsentValid(error);

            case PRIVACY_POLICY_CONFIRMATION_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Privacy Policy Confirmation',
                    required: true,
                });
                return setPrivacyPolicyConfirmationValid(error);

            case ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Advice Acknowledgement Indemnity',
                    required: true,
                });
                return setAdviceAcknowledgementIndemnityValid(error);

            case NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'No Premium Refund Acknowledgement',
                    required: true,
                });
                return setNoPremiumRefundAcknowledgementValid(error);

            case FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Full Premium Settlement Consent',
                    required: true,
                });
                return setFullPremiumSettlementConsentValid(error);

            case FINAL_DECLARATION_AGREEMENT_SET_VALUE:
                error = validateName(action.value.toString(), {
                    subject: 'Final Declaration Agreement',
                    required: true,
                });
                return setFinalDeclarationAgreementValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
