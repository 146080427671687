import { getIsValuersApiPending } from '../../../selectors/ugMotorInsurance/quote';
import { apiTransitionValuers, setValuers } from '../../../actions/ugMotorInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';

export const getValuersService = (store, next, action) => {
    (async () => {
        if (!getIsValuersApiPending(store.getState())) {
            store.dispatch(apiTransitionValuers.pending());
            try {
                const resp = await getValuers(store.getState());
                store.dispatch(setValuers(resp.data.carValuers));
                store.dispatch(apiTransitionValuers.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionValuers.failure());
            }
        }
    })();
    return next(action);
};

const getValuers = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ug-motor-insurance/valuers';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
