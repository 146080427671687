import { BACK_TRANSITION_CAPTURE_DETAILS, CONTINUE_TRANSITION_CAPTURE_DETAILS } from "../../../actions/raOptimal/captureDetails";
import { BACK_TRANSITION_PERSONAL_DETAIL, CONTINUE_TRANSITION_PERSONAL_DETAILS } from "../../../actions/raOptimal/personalDetails";
import { SEND_SUBMIT_RA_OPTIMAL } from "../../../actions/raOptimal/review";
import { REFERENCE_DATA_GET_VALUE } from "../../../actions/raOptimal/referenceData";
import { dropOffLeadsService } from "./dropOffLead";
import { submitApplicationService } from "./submitApplication";
import { referenceDataService } from "./referenceData";
import { TRIGGER_ACCOUNT_VALIDATION_FROM_CHECKBOX_PAYMENT_DETAILS } from "../../../actions/raOptimal/paymentDetails";
import { performAccountValidation } from "./accountValidation";

export const raOptimalServices = (store, next, action) => {
    if (action.type === CONTINUE_TRANSITION_CAPTURE_DETAILS ||
        action.type === BACK_TRANSITION_CAPTURE_DETAILS ||
        action.type === CONTINUE_TRANSITION_PERSONAL_DETAILS ||
        action.type === BACK_TRANSITION_PERSONAL_DETAIL)
        return dropOffLeadsService(store, next, action);

    if (action.type === REFERENCE_DATA_GET_VALUE)
        return referenceDataService(store, next, action);

    if (action.type === SEND_SUBMIT_RA_OPTIMAL)
        return submitApplicationService(store, next, action);

    if (action.type === TRIGGER_ACCOUNT_VALIDATION_FROM_CHECKBOX_PAYMENT_DETAILS)
        return performAccountValidation(store, next, action);

    return null;
}