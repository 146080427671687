import {
    getPaymentDetailsValid,
    getPaymentAuthorizationValid,
    getPaymentAuthorizationFalse,
    getPaymentDetailsForm,
    getSourceOfIncomeOtherRequired,
    getEmploymentIndustryRequired,
    getEmploymentIndustryOtherRequired,
    getEmploymentPosition,
    getSourceOfIncome,
    getEmploymentIndustry,
    getAccountValidationComplete
} from "../../../selectors/raOptimal/paymentDetails";
import {FIELD_STATUS, FORM_STATUS} from "../../../reducers/status";
import {
    transitionPaymentDetails,
    setBankNameValue,
    setAccountHolderNameValue,
    setAccountNumberValue,
    setAccountTypeValue,
    setDebitOrderDayValue,
    setStartMonthValue,
    clearStartMonth,
    setPaymentAuthorizationInvalid,
    setSourceOfIncomeValue,
    setSourceOfIncomeOtherValue,
    clearSourceOfIncomeOther,
    setSourceOfFundsValue,
    setEmploymentPositionValue,
    setEmploymentIndustryValue,
    clearEmploymentIndustry,
    setEmploymentIndustryOtherValue,
    clearEmploymentIndustryOther,
    setAccountValidationReset,
    setAccountHolderNameEdited,
    EMPLOYMENT_POSITION_SET_VALUE,
    SOURCE_OF_INCOME_SET_VALUE,
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    ACCOUNT_HOLDER_NAME_SET_VALUE,
    ACCOUNT_HOLDER_NAME_PRESET_VALUE,
    triggerAccountValidation,
} from "../../../actions/raOptimal/paymentDetails";
import { transitionScrolling } from "../../../actions/raOptimal/scrolling";
import { RA_OPTIMAL_PAYMENT_DETAILS_SCROLL_ID, RA_OPTIMAL_PAYMENT_DETAILS_SCROLL_SECTIONS } from "../status";

export const paymentDetailsRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const updateBankDetailsActions = [ACCOUNT_HOLDER_NAME_PRESET_VALUE,
        ACCOUNT_HOLDER_NAME_SET_VALUE,
        BANK_NAME_SET_VALUE,
        ACCOUNT_NUMBER_SET_VALUE,
        ACCOUNT_TYPE_SET_VALUE];

    if (action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS &&
        getPaymentAuthorizationValid(store.getState())) {
            store.dispatch(triggerAccountValidation());
    }

    if (action.type === DEBIT_ORDER_DAY_SET_VALUE) {
        store.dispatch(clearStartMonth());
    }

    if (action.type === ACCOUNT_HOLDER_NAME_SET_VALUE) {
        store.dispatch(setAccountHolderNameEdited());
    }

    if (getAccountValidationComplete(state)) {
        setScrollToSection(store, RA_OPTIMAL_PAYMENT_DETAILS_SCROLL_SECTIONS.LEGAL_SECTION);
    }

    // Trigger account validation reset
    if ((updateBankDetailsActions.includes(action.type) && getPaymentAuthorizationValid(state)) ||
        (action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS && getPaymentAuthorizationFalse(state))) {
        // Clear payment auth checkbox and account validation state
        store.dispatch(setAccountValidationReset());
    }


    if (action.type === SOURCE_OF_INCOME_SET_VALUE) {
        const sourceOfIncomeValue = getSourceOfIncome(state).value;
        const sourceOfIncomeOtherRequired = sourceOfIncomeValue === 'OtherInvestmentIncome';

        store.dispatch(sourceOfIncomeOtherRequired
            ? transitionPaymentDetails.sourceOfIncomeOtherRequired()
            : transitionPaymentDetails.sourceOfIncomeOtherNotRequired());

        if (!sourceOfIncomeOtherRequired) {
            store.dispatch(clearSourceOfIncomeOther());
        }
    }

    if (action.type === EMPLOYMENT_POSITION_SET_VALUE) {
        const employmentPositionValue = getEmploymentPosition(state).value;
        const employmentIndustryNotRequired = employmentPositionValue === 'RetiredOrUnemployed';

        store.dispatch(employmentIndustryNotRequired
            ? transitionPaymentDetails.employmentIndustryNotRequired()
            : transitionPaymentDetails.employmentIndustryRequired());

        if (employmentIndustryNotRequired) {
            store.dispatch(transitionPaymentDetails.employmentIndustryOtherNotRequired());
            store.dispatch(clearEmploymentIndustry());
            store.dispatch(clearEmploymentIndustryOther());
        }
    }

    if (action.type === EMPLOYMENT_INDUSTRY_SET_VALUE) {
        const employmentIndustryValue = getEmploymentIndustry(state).value;

        const employmentIndustryOtherRequired =
            employmentIndustryValue === 'OtherServices' ||
            employmentIndustryValue === 'PublicSectorOther';
        store.dispatch(employmentIndustryOtherRequired
            ? transitionPaymentDetails.employmentIndustryOtherRequired()
            : transitionPaymentDetails.employmentIndustryOtherNotRequired());

        if (!employmentIndustryOtherRequired) {
            store.dispatch(clearEmploymentIndustryOther(null));
        }
    }

    // Payment Details
    const paymentDetailsValid = validatePaymentDetails(store);
    const paymentDetailsFormValid = validatePaymentDetailsFormSection(store);

    store.dispatch(paymentDetailsFormValid
        ? transitionPaymentDetails.paymentDetailsFormValid()
        : transitionPaymentDetails.paymentDetailsFormInvalid());

    store.dispatch(paymentDetailsValid
        ? transitionPaymentDetails.formValid()
        : transitionPaymentDetails.formInvalid());

    return result;
};

export const validatePaymentDetailsForm = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const paymentDetailsFormValid = getPaymentDetailsValid(state);
    const paymentAuthorizationValid = getPaymentAuthorizationValid(state);
    const sourceOfIncomeOtherRequired = getSourceOfIncomeOtherRequired(state);
    const employmentIndustryRequired = getEmploymentIndustryRequired(state);
    const employmentIndustryOtherRequired = getEmploymentIndustryOtherRequired(state);

    if (!paymentDetailsFormValid) {
        const paymentDetailsForm = getPaymentDetailsForm(state);

        if (paymentDetailsForm.bankName.status !== FORM_STATUS.VALID) store.dispatch(setBankNameValue(paymentDetailsForm.bankName.value));
        if (paymentDetailsForm.accountHolderName.status !== FORM_STATUS.VALID) store.dispatch(setAccountHolderNameValue(paymentDetailsForm.accountHolderName.value));
        if (paymentDetailsForm.accountNumber.status !== FORM_STATUS.VALID) store.dispatch(setAccountNumberValue(paymentDetailsForm.accountNumber.value));
        if (paymentDetailsForm.accountType.status !== FORM_STATUS.VALID) store.dispatch(setAccountTypeValue(paymentDetailsForm.accountType.value));
        if (paymentDetailsForm.debitOrderDay.status !== FORM_STATUS.VALID) store.dispatch(setDebitOrderDayValue(paymentDetailsForm.debitOrderDay.value));
        if (paymentDetailsForm.startMonth.status !== FORM_STATUS.VALID) store.dispatch(setStartMonthValue(paymentDetailsForm.startMonth.value));

        if (!paymentAuthorizationValid) store.dispatch(setPaymentAuthorizationInvalid(FORM_STATUS.INVALID));

        if (getAccountValidationComplete(state)){
            if (paymentDetailsForm.sourceOfIncome.status !== FORM_STATUS.VALID) store.dispatch(setSourceOfIncomeValue(paymentDetailsForm.sourceOfIncome.value));
            if (paymentDetailsForm.sourceOfFunds.status !== FORM_STATUS.VALID) store.dispatch(setSourceOfFundsValue(paymentDetailsForm.sourceOfFunds.value));
            if (paymentDetailsForm.employmentPosition.status !== FORM_STATUS.VALID) store.dispatch(setEmploymentPositionValue(paymentDetailsForm.employmentPosition.value));

            if (sourceOfIncomeOtherRequired && paymentDetailsForm.sourceOfIncomeOther.status !== FORM_STATUS.VALID)
                store.dispatch(setSourceOfIncomeOtherValue(paymentDetailsForm.sourceOfIncomeOther.value));
            if (employmentIndustryRequired && paymentDetailsForm.employmentIndustry.status !== FORM_STATUS.VALID)
                store.dispatch(setEmploymentIndustryValue(paymentDetailsForm.employmentIndustry.value));
            if (employmentIndustryOtherRequired && paymentDetailsForm.employmentIndustryOther.status !== FORM_STATUS.VALID)
                store.dispatch(setEmploymentIndustryOtherValue(paymentDetailsForm.employmentIndustryOther.value));
        }
    }

    setScrollError(store);
    return result;
};

const setScrollToSection = (store, section) => {
    store.dispatch(transitionScrolling.scrollToId(section));
}

const setScrollError = store => {
    const keyArray = RA_OPTIMAL_PAYMENT_DETAILS_SCROLL_ID;
    const paymentDetailsForm = getPaymentDetailsForm(store.getState());

    for (let i = 0; i < keyArray.length; i++) {
        if (keyArray[i] in paymentDetailsForm && paymentDetailsForm[keyArray[i]].error) {
            store.dispatch(transitionScrolling.scrollToId(keyArray[i]));
            break;
        }
    }
}

// Personal Information
const validatePaymentDetails = store => {
    const formFields = getPaymentDetailsForm(store.getState());
    const paymentAuthorizationValid = getPaymentAuthorizationValid(store.getState());

    const sourceOfIncomeOtherRequired = getSourceOfIncomeOtherRequired(store.getState());
    const employmentIndustryRequired = getEmploymentIndustryRequired(store.getState());
    const employmentIndustryOtherRequired = getEmploymentIndustryOtherRequired(store.getState());

    const sourceOfIncomeValid = !sourceOfIncomeOtherRequired
        ? formFields.sourceOfIncome && formFields.sourceOfIncome.status === FIELD_STATUS.VALID
        : formFields.sourceOfIncome && formFields.sourceOfIncome.status === FIELD_STATUS.VALID &&
          formFields.sourceOfIncomeOther && formFields.sourceOfIncomeOther.status === FIELD_STATUS.VALID;

    const employmentIndustryValid = !employmentIndustryRequired
        ? true : !employmentIndustryOtherRequired
            ? formFields.employmentIndustry && formFields.employmentIndustry.status === FIELD_STATUS.VALID
            : formFields.employmentIndustry && formFields.employmentIndustry.status === FIELD_STATUS.VALID &&
              formFields.employmentIndustryOther && formFields.employmentIndustryOther.status === FIELD_STATUS.VALID;

    return paymentAuthorizationValid &&
        formFields.bankName && formFields.bankName.status === FIELD_STATUS.VALID &&
        formFields.accountHolderName && formFields.accountHolderName.status === FIELD_STATUS.VALID &&
        formFields.accountNumber && formFields.accountNumber.status === FIELD_STATUS.VALID &&
        formFields.accountType && formFields.accountType.status === FIELD_STATUS.VALID &&
        formFields.debitOrderDay && formFields.debitOrderDay.status === FIELD_STATUS.VALID &&
        formFields.startMonth && formFields.startMonth.status === FIELD_STATUS.VALID &&
        sourceOfIncomeValid &&
        formFields.sourceOfFunds && formFields.sourceOfFunds.status === FIELD_STATUS.VALID &&
        formFields.employmentPosition && formFields.employmentPosition.status === FIELD_STATUS.VALID &&
        employmentIndustryValid;
};

// Personal Information
const validatePaymentDetailsFormSection = store => {
    const formFields = getPaymentDetailsForm(store.getState());
    const paymentAuthorizationValid = getPaymentAuthorizationValid(store.getState());

    return paymentAuthorizationValid &&
        formFields.bankName && formFields.bankName.status === FIELD_STATUS.VALID &&
        formFields.accountHolderName && formFields.accountHolderName.status === FIELD_STATUS.VALID &&
        formFields.accountNumber && formFields.accountNumber.status === FIELD_STATUS.VALID &&
        formFields.accountType && formFields.accountType.status === FIELD_STATUS.VALID &&
        formFields.debitOrderDay && formFields.debitOrderDay.status === FIELD_STATUS.VALID &&
        formFields.startMonth && formFields.startMonth.status === FIELD_STATUS.VALID;
};
