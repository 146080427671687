import { createSelector } from "reselect";
import { FORM_STATUS, FIELD_STATUS } from "../../reducers/status";
import { getEasiplusFuneralPlan } from "./index";
import {
    ALL_TITLES,
    COVER_CATEGORIES,
    COVER_CATEGORY_MEMBERS,
    EFP_RELATIONSHIP_GENDERS, 
    FEMALE, 
    FEMALE_TITLES, 
    MALE, 
    MALE_TITLES
} from "../../actions/easiplusFuneralPlan/types";
import { CalcEngine } from "../../functions/easiplusFuneralPlan/CalcEngine";
import { getQuoteReferenceData } from "./referenceData"
import { 
    getQuoteReferenceCoverMembers,
    getQuoteReferenceImmediateFamilyCoverMembers
} from "./referenceData";
import { coverMember } from "../../reducers/easiplusFuneralPlan/livesCovered";

export const getLivesCovered = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan.livesCovered
);

export const getLivesCoveredFormValid = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.formStatus === FORM_STATUS.VALID,
);

export const getLivesCoveredFormComplete = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.formComplete === FORM_STATUS.VALID,
);

export const getEditCoverMember = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.editCoverMember,
);

export const getAddCoverMember = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.addCoverMember,
);

export const getReferenceCoverMembers = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.referenceCoverCategoryMembers
);

export const getRemoveCoverMember = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.removeCoverMember
);

export const getCoverMembersForms = createSelector(
    getLivesCovered,
    getReferenceCoverMembers,
    (livesCovered, referenceCoverMembers) => {
        let coverMembersDetails = livesCovered.coverMembers;
        const codes = Object.keys(livesCovered.coverMembers);
        const keys = Object.keys(referenceCoverMembers);

        codes.map((memberCode) => {
            const hasDetails = coverMembersDetails[memberCode].firstName.status === FIELD_STATUS.VALID;
            const key = keys.find(key => referenceCoverMembers[key].code === memberCode);
            coverMembersDetails[memberCode] = {
                ...coverMembersDetails[memberCode],
                fullName: coverMembersDetails[memberCode].firstName.value + ' ' + coverMembersDetails[memberCode].surname.value,
                key,
                hasDetails,
                displayFirstLabelSet: (hasDetails && referenceCoverMembers[key].displayCoverAmount) ? true : false,
                displaySecondLabelSet: (hasDetails && !referenceCoverMembers[key].displayCoverAmount) ? true : false,
                canRemove: (hasDetails && referenceCoverMembers[key].canRemove) ? true : false,
                description: referenceCoverMembers[key].description,
                label: referenceCoverMembers[key].label,
            }
        });

        return coverMembersDetails;
    }
);

export const getReferenceCoverCategories = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.referenceCoverCategories
);

export const getCoverMemberCodes = createSelector(
    getCoverMembersForms, 
    coverMembers => Object.keys(coverMembers)
);

export const getCoverCategoryKeys = createSelector(
    getReferenceCoverCategories,
    referenceCoverCategories => Object.keys(referenceCoverCategories)
);

export const getDirectFamilyCovered = createSelector(
    getCoverCategoryKeys,
    coverCategories => coverCategories.includes(COVER_CATEGORIES.DIRECT_FAMILY.key)
);

export const getPopulatedCoverMembersForms = createSelector(
    getCoverMembersForms,
    coverMembers => {
        const coverMemberKeys = Object.keys(coverMembers);
        const filteredKeys = coverMemberKeys.filter(member => coverMembers[member].firstName.status === FIELD_STATUS.VALID);
        let filteredObject = {};
        filteredKeys.map(key => filteredObject[key] = coverMembers[key]);
        return filteredObject;
    },
);

export const getModalValue = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.popupModal,
);
// Cover member information
const makeIsCompleteSelector = (category) => createSelector(
    getLivesCovered,
    livesCovered => {
        const referenceLength = Object.keys(livesCovered.referenceCoverCategoryMembers).length;
        const stateLength = Object.keys(livesCovered.coverMembers).length;

        if (referenceLength >= stateLength) {
            if (category in livesCovered.referenceCoverCategories) {
                const keys = Object.keys(livesCovered.referenceCoverCategoryMembers);
                const familyMemberKeys = keys.filter(key => {
                    return livesCovered.referenceCoverCategoryMembers[key].category === category;
                });

                const familyMemberCodes = familyMemberKeys.map(key => livesCovered.referenceCoverCategoryMembers[key].code);
                const isComplete = familyMemberCodes.map(code => {
                    return livesCovered.coverMembers[code] 
                                ? livesCovered.coverMembers[code].firstName.status === FIELD_STATUS.VALID 
                                : false;
                });

                return !isComplete.includes(false);
            } else return true;
        } else return false;
    }
);
export const getIsParentsAndInLawsComplete = makeIsCompleteSelector(COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key);
export const getIsExtendedFamilyComplete = makeIsCompleteSelector(COVER_CATEGORIES.EXTENDED_FAMILY.key);

export const getIsLivesCoveredPageComplete = createSelector(
    getIsParentsAndInLawsComplete, 
    getIsExtendedFamilyComplete, 
    (parentsComplete, extendedComplete) => parentsComplete && extendedComplete
)

export const getLivesCoveredKeysForSummary = createSelector(
    getCoverMembersForms,
    members => {
        const keys = Object.keys(members);
        return keys.filter(key => {
            return members[key].firstName.value || members[key].surname.value;
        });
    }
);

export const getTotalPremium = createSelector(
    getCoverMembersForms,
    members => {
        if(!members)
            return;
        if( 'myself' in members ){
            const quoteMembers = Object.keys(members)
                .filter(member => !(member.startsWith('child') || (member.startsWith("spouse"))))

            return quoteMembers
                .map(key => members[key].monthlyPremium.value)
                .reduce((a, b) => a + b, 0);
        }
        return Object.keys(members)
            .map(key => members[key].monthlyPremium.value)
            .reduce((a, b) => a + b, 0);
    }
);

export const getLivesCoveredForm = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.form,
);

export const getMaxChildrenAdded = createSelector(
    getLivesCovered,
    livesCovered => livesCovered.maxChildrenAdded === true,
)

// Cover member information
const makeGetField = (fieldId) => createSelector(
    getLivesCovered,
    livesCovered => livesCovered.form[fieldId]
);
export const getTitle = makeGetField('title');
export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getDateOfBirth = makeGetField('dateOfBirth');
export const getAgeValue = makeGetField('age');
export const getCoverAmount = makeGetField('coverAmount');
export const getMonthlyPremium = makeGetField('monthlyPremium');

export const getTitleField = () => { return 'title'; };
export const getFirstNameField = () => { return 'firstName'; };
export const getSurnameField = () => { return 'surname'; };
export const getDateOfBirthField = () => { return 'dateOfBirth'; };
export const getCoverAmountField = () => { return 'coverAmount'; };
export const getMonthlyPremiumField = () => { return 'monthlyPremium'; };

export const getDobError = createSelector(
    getDateOfBirth, 
    getAgeValue,
    (dob, age) => dob.error || age.error
);

export const getIsSpouseAdded = createSelector(
    getLivesCovered,
    livesCovered => {
        const spousePresent = COVER_CATEGORY_MEMBERS.SPOUSE.code in livesCovered.coverMembers;
        return spousePresent && livesCovered.coverMembers[COVER_CATEGORY_MEMBERS.SPOUSE.code].firstName.status === FIELD_STATUS.VALID;
    }
);

export const getIsChildAdded = createSelector(
    getLivesCovered,
    livesCovered => {
        const codes = Object.keys(livesCovered.coverMembers);
        const childCodes = codes.filter(code => {
            return code.startsWith('child') && livesCovered.coverMembers[code].firstName.status === FIELD_STATUS.VALID
        });
        return childCodes.length > 0;
    }
);

export const getSliderSteps = createSelector(
    getAddCoverMember,
    getEditCoverMember,
    getReferenceCoverMembers,
    (addCoverMember, editCoverMember, referenceCoverMembers) => {
        const coverMember = addCoverMember || editCoverMember;
        if (coverMember) {
            const keys = Object.keys(referenceCoverMembers);
            const key = keys.find(key => referenceCoverMembers[key].code === coverMember);
            const category = referenceCoverMembers[key].category;

            if (category === COVER_CATEGORIES.EXTENDED_FAMILY.key) {
                return { minLabel: "R10 000", maxLabel: "R30 000", sliderSteps: [ 10000,15000,20000,25000,30000 ]};
            } else if (category === COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key) {
                return { minLabel: "R10 000", maxLabel: "R50 000", sliderSteps: [ 10000,15000,20000,25000,30000,40000,50000 ]};
            }
        }

        return { minLabel: "R10 000", maxLabel: "R70 000", sliderSteps: [ 10000,15000,20000,25000,30000,40000,50000,60000,70000 ]};
    }
);

export const getHintText = createSelector(
    getAddCoverMember,
    getEditCoverMember,
    (addCoverMember, editCoverMember) => {
        const coverMember = addCoverMember || editCoverMember;
        if (coverMember) {
            if (coverMember === COVER_CATEGORY_MEMBERS.MYSELF.code) return "Age: (maximum 65)";
            if (coverMember.startsWith('child')) return "Age: (maximum 21)";
            else return "Age: (maximum 84)";
        }
    }
)
// Calculations
export const getPremiumFromCoverAmount = createSelector(
    getLivesCovered,
    getQuoteReferenceData,
    getAddCoverMember,
    getEditCoverMember,
    getIsSpouseAdded,
    getIsChildAdded,
    getReferenceCoverMembers,
    (livesCovered, quoteReferenceData, addCoverMember, editCoverMember, addPartner, addChild, referenceCoverMembers) => {
        let coverMember = addCoverMember || editCoverMember;
        let dateOfBirth = livesCovered.form.dateOfBirth;
        const coverAmount = livesCovered.form.coverAmount;

        if (coverMember && coverAmount.value) {
            if (coverMember === COVER_CATEGORY_MEMBERS.SPOUSE.code) {
                addPartner = true;
                dateOfBirth = livesCovered.coverMembers.myself.dateOfBirth;
            }

            if (coverMember.startsWith('child')) {
                addChild = true;
                dateOfBirth = livesCovered.coverMembers.myself.dateOfBirth;
            }

            if (dateOfBirth.status === FORM_STATUS.VALID) {
                let age = livesCovered.form.age;
                if (coverMember === COVER_CATEGORY_MEMBERS.SPOUSE.code) age = livesCovered.coverMembers.myself.age;
                if (coverMember.startsWith('child')) age = livesCovered.coverMembers.myself.age;

                if (age.status === FIELD_STATUS.VALID) {
                    const keys = Object.keys(referenceCoverMembers);
                    const key = keys.find(key => referenceCoverMembers[key].code === coverMember);
                    const selectedCategory = referenceCoverMembers[key].category;
                    const categoryName = COVER_CATEGORIES[selectedCategory].code;
                    const coverCategory = CalcEngine.mapCoverCategoryForProductCode(categoryName, addPartner, addChild);
                    const premiumValue = CalcEngine.easiplusLookupPremiumValue(quoteReferenceData, coverCategory, age.value, coverAmount.value);
                    return premiumValue ? premiumValue : null;
                }
            }
        }
    }
);

export const getDirectFamilyPremium = createSelector(
    getLivesCovered,
    getQuoteReferenceData,
    getIsSpouseAdded,
    getIsChildAdded,
    getReferenceCoverMembers,
    (livesCovered, quoteReferenceData, addPartner, addChild, referenceCoverMembers) => {
        if ('myself' in livesCovered.coverMembers && livesCovered.coverMembers.myself.coverAmount) {
            const coverAmount = livesCovered.coverMembers.myself.coverAmount;
            const age = livesCovered.coverMembers.myself.age;
            const categoryName = COVER_CATEGORIES[referenceCoverMembers.MYSELF.category].code;

            const coverCategory = CalcEngine.mapCoverCategoryForProductCode(categoryName, addPartner, addChild);
            const premiumValue = CalcEngine.easiplusLookupPremiumValue(quoteReferenceData, coverCategory, age.value, coverAmount.value);
            return premiumValue ? premiumValue : null;
        }
    }
);

// Submission
export const getTitlesForRelationshipTypes = createSelector(
    getAddCoverMember,
    (currentCoverMemberRelationshipType) => {
        var genderForTitle = EFP_RELATIONSHIP_GENDERS[currentCoverMemberRelationshipType];
        if (!genderForTitle && currentCoverMemberRelationshipType) {
            const relationshipKeys = Object.keys(EFP_RELATIONSHIP_GENDERS);
            const correlatingKey = relationshipKeys.filter(key => currentCoverMemberRelationshipType.startsWith(key));
            if (correlatingKey) genderForTitle = EFP_RELATIONSHIP_GENDERS[correlatingKey];
        }

        switch (genderForTitle) {
            case MALE: return MALE_TITLES;
            case FEMALE: return FEMALE_TITLES;
            default: return ALL_TITLES;
        }
    }
);

// Split covered members into Immediate Family Members and the rest
const isImmediateFamilyMember = memberKey => memberKey.match(/child_(\d)|spouse|myself/);
const getProductCodeFormImmediateFamilyMember = memberKey => {
    const childData = memberKey.match(/child_(\d)/);

    if (childData) {
        return {
            relationshipTypeCode: 'Child',
            relationshipTypeDescription: 'My Child',
            childNumber: +childData[1],
            isOverTwentyOne: true,
            isDoBValid: true,
        }
    }

    return {
        relationshipTypeCode: 'Spouse',
        relationshipTypeDescription: 'My spouse/partner',
    };
}

export const getImmediateFamilySplitCoveredMembers = createSelector(
    getCoverMembersForms,
    coveredMembers => Object.entries(coveredMembers)
        .reduce((acc, [memberKey, memberValue]) => {
            const memberGroup = isImmediateFamilyMember(memberKey) ? 'immediateMembers' : 'otherMembers';
            if (memberValue.age.value == null) {
                return acc;
            }

            const newMemberKey = memberKey === 'grandchild' ? 'grandchildren' : memberKey;

            return {
                ...acc,
                [memberGroup]: {
                    ...acc[memberGroup],
                    [newMemberKey]: memberValue,
                }
            };
        }, {immediateMembers: {}, otherMembers: {}}),
);
export const getMainMemberSubmission = createSelector(
    getQuoteReferenceImmediateFamilyCoverMembers,
    getImmediateFamilySplitCoveredMembers,
    (quoteReferenceImmediateFamilyCoverMembers, splitCoveredMembers) => {
        const {immediateMembers} = splitCoveredMembers;

        if (!immediateMembers || !immediateMembers.myself) {
            return null;
        }

        const {myself, ...otherImmediateMembers} = immediateMembers;
        const familyKeys = Object.keys(immediateMembers);
        const hasChild = !!familyKeys.find(key => key.startsWith('child'));
        const memberProductCode = Object.keys(immediateMembers).includes('spouse')
            ? 'Family'
            : hasChild ? 'SingleFamily' : 'Individual';

        return {
            ...quoteReferenceImmediateFamilyCoverMembers.find(member => member.productCode === memberProductCode),
            age: +myself.age.value,
            firstName: myself.firstName.value,
            surname: myself.surname.value,
            dateOfBirth: myself.dateOfBirth.value,
            coverAmount: +myself.coverAmount.value,
            totalPremium: +myself.monthlyPremium.value,
            title: {
                code: myself.title.value
            },
            gender: {
                code: null,
                description: null,
                display: false
            },
            relatedCoveredMembers: Object.entries(otherImmediateMembers)
                .filter(([_, memberValue]) => memberValue.age.value != null)
                .map(([memberKey, memberValue]) => {
                    return {
                        ...getProductCodeFormImmediateFamilyMember(memberKey),
                        firstName: memberValue.firstName.value,
                        surname: memberValue.surname.value,
                        dateOfBirth: memberValue.dateOfBirth.value,
                        title: {code: memberValue.title.value},
                        age: +memberValue.age.value,
                        isMinAgeValid: true,
                        isMaxAgeValid: true,
                    };
                }),
        };
    }
);
export const getLivesCoveredSubmission = createSelector(
    getQuoteReferenceCoverMembers,
    getMainMemberSubmission,
    getImmediateFamilySplitCoveredMembers,
    (quoteReferenceCoverMembers, mainMemberSubmission, splitCoveredMembers) => {
        const findCoverMember = memberKey => {
            const hasMultipleOfMemberType = (memberKey || '').match(/(.*)_(\d)/);
            const memberDesc = hasMultipleOfMemberType ? hasMultipleOfMemberType[1] : memberKey;
            return  quoteReferenceCoverMembers.find(member => {
                return member.relationshipTypeCode.toLowerCase() === memberDesc.toLowerCase()
            });
        };

        const otherMemberValues = Object.entries(splitCoveredMembers.otherMembers)
            .map(([memberKey, memberValue]) => ({
                ...findCoverMember(memberKey),
                age: +memberValue.age.value,
                firstName: memberValue.firstName.value,
                surname: memberValue.surname.value,
                dateOfBirth: memberValue.dateOfBirth.value,
                title: {code: memberValue.title.value},
                totalPremium: +memberValue.monthlyPremium.value,
                gender: {
                    code: null,
                    description: null,
                    display: false
                },
                isSelected: false,
                coverAmount: +memberValue.coverAmount.value,
                initials: '',
                saIdNumber: '',
                maidenName: '',
            }));

        return [...(mainMemberSubmission ? [mainMemberSubmission] : []), ...otherMemberValues];
    }
);

export const getQuoteSummaryCategories = createSelector(
    getReferenceCoverCategories,
    getReferenceCoverMembers,
    getCoverMemberCodes,
    (referenceCoverCategories, referenceCoverMembers, coverMemberCodes) => {
        let memberCodes = [];

        if (!referenceCoverCategories) {
            return;
        }

        const coverMemberCategories = Object.keys(referenceCoverCategories);
        const refCoverMemberKeys = Object.keys(referenceCoverMembers);
        coverMemberCategories.map(category => {
            if (category === COVER_CATEGORY_MEMBERS.MYSELF.category) {
                memberCodes.push(COVER_CATEGORY_MEMBERS.MYSELF.code);
            } else {
                const memberCodesByCategory = coverMemberCodes.filter(code => {
                    const key = refCoverMemberKeys.find(member => referenceCoverMembers[member].code === code);
                    return referenceCoverMembers[key].category === category;
                });
                memberCodes = [...memberCodes, ...memberCodesByCategory];
            }
        });

        const descriptionArray = memberCodes.map(code => {
            const key = refCoverMemberKeys.find(member => referenceCoverMembers[member].code === code);
            return referenceCoverMembers[key].description;
        });
        return JSON.stringify(descriptionArray);
    }
);

export const getQuoteSummaryPremiums = createSelector(
    getCoverMembersForms,
    getReferenceCoverCategories,
    getReferenceCoverMembers,
    getMonthlyPremiumField,
    (coverMembers, referenceCoverCategories, referenceCoverMembers, monthlyPremiumField) => {
        let memberCodes = [];
        const coverMemberCategories = Object.keys(referenceCoverCategories);
        const refCoverMemberCodes = Object.keys(coverMembers);
        const refCoverMemberKeys = Object.keys(referenceCoverMembers);

        coverMemberCategories.map(category => {
            if (category === COVER_CATEGORY_MEMBERS.MYSELF.category) {
                memberCodes.push(COVER_CATEGORY_MEMBERS.MYSELF.code);
            } else {
                const memberCodesByCategory = refCoverMemberCodes.filter(code => {
                    const key = refCoverMemberKeys.find(member => referenceCoverMembers[member].code === code);
                    return referenceCoverMembers[key].category === category;
                });
                memberCodes = [...memberCodes, ...memberCodesByCategory];
            }
        });

        const coverArray = memberCodes.map(code => {
            return Number(coverMembers[code][monthlyPremiumField].value);
        });
        return JSON.stringify(coverArray);
    }
);

const makeNrCodesByCategory = (category) => createSelector(
    getCoverMemberCodes,
    getReferenceCoverMembers,
    (coverMemberCodes, referenceCoverMembers) => {
        return coverMemberCodes.filter(code => {
            const referenceCoverMemberKeys = Object.keys(referenceCoverMembers);
            const key = referenceCoverMemberKeys.find(member => referenceCoverMembers[member].code === code);
            return referenceCoverMembers[key].category === category;
        }).length;
    }
);

export const getViewMoreCategories = createSelector(
    getLivesCovered, 
    livesCovered => livesCovered.viewMoreCategories
);

export const makeIsViewMoreTrue = (category) => createSelector(
    getLivesCovered, 
    livesCovered => livesCovered.viewMoreCategories.includes(category)
);
export const getIsViewMoreTrueDirectFamily = makeIsViewMoreTrue(COVER_CATEGORIES.DIRECT_FAMILY.key)
export const getIsViewMoreTrueParentsAndInLaws = makeIsViewMoreTrue(COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key);
export const getIsViewMoreTrueExtendedFamily = makeIsViewMoreTrue(COVER_CATEGORIES.EXTENDED_FAMILY.key);

const makeGetCodesByCategory = (category) => createSelector(
    getCoverMemberCodes,
    getReferenceCoverMembers,
    makeIsViewMoreTrue(category),
    (coverMemberCodes, referenceCoverMembers, viewMore) => {
        const allCodes = coverMemberCodes.filter(code => {
            const referenceCoverMemberKeys = Object.keys(referenceCoverMembers);
            const key = referenceCoverMemberKeys.find(member => referenceCoverMembers[member].code === code);
            return referenceCoverMembers[key].category === category;
        });

        return viewMore ? allCodes : allCodes.slice(0,3);
    }
);
export const getDirectFamilyCodes = makeGetCodesByCategory(COVER_CATEGORIES.DIRECT_FAMILY.key);
export const getParentsAndInLawsCodes = makeGetCodesByCategory(COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key);
export const getExtendedFamilyCodes = makeGetCodesByCategory(COVER_CATEGORIES.EXTENDED_FAMILY.key);

export const makeGetViewMoreText = (category) => createSelector(
    makeIsViewMoreTrue(category),
    makeNrCodesByCategory(category),
    (viewMore, numberOfCodes) => {
        if (numberOfCodes > 3) {
            return viewMore ? "View less members" : "View more members";
        } else return null;
    }
);
export const getDirectFamilyViewMoreText = makeGetViewMoreText(COVER_CATEGORIES.DIRECT_FAMILY.key);
export const getParentsAndInLawsViewMoreText = makeGetViewMoreText(COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key);
export const getExtendedFamilyViewMoreText = makeGetViewMoreText(COVER_CATEGORIES.EXTENDED_FAMILY.key);

export const getAddOrEditCoverMember = createSelector(
    getAddCoverMember, 
    getEditCoverMember,
    (addCoverMember, editCoverMember) => addCoverMember || editCoverMember
);

export const getIsEditMode = createSelector(
    getEditCoverMember,
    editCoverMember => editCoverMember && editCoverMember === COVER_CATEGORY_MEMBERS.MYSELF.code
);

export const getEditCoverCategory = createSelector(
    getAddOrEditCoverMember, 
    getReferenceCoverMembers,
    (coverMember, referenceCoverMembers) => {
        const keys = Object.keys(referenceCoverMembers);
        const key = keys.find(member => referenceCoverMembers[member].code === coverMember);
        return coverMember ? referenceCoverMembers[key].category : null;
    }
);

export const getMemberDescription = createSelector(
    getAddOrEditCoverMember,
    getReferenceCoverMembers,
    (coverMember, referenceCoverMembers) => {
        const keys = Object.keys(referenceCoverMembers);
        const key = keys.find(member => referenceCoverMembers[member].code === coverMember);
        return coverMember ? referenceCoverMembers[key].label : null;
    }
);

export const getMemberHasCaption = createSelector(
    getAddOrEditCoverMember,
    coverMember => coverMember === COVER_CATEGORY_MEMBERS.SPOUSE.code
);

export const getCoverCategories = createSelector(
    getReferenceCoverCategories,
    getDirectFamilyViewMoreText,
    getParentsAndInLawsViewMoreText,
    getExtendedFamilyViewMoreText,
    getDirectFamilyCodes,
    getParentsAndInLawsCodes,
    getExtendedFamilyCodes,
    getIsParentsAndInLawsComplete,
    getIsExtendedFamilyComplete,
    (referenceCoverCategories, directViewMore, parentsViewMore, extendedViewMore, directCodes, parentsCodes, extendedCodes,
        parentsComplete, extendedComplete) => {
        let coverCategoriesObject = {};

        if (directCodes.length) {
            coverCategoriesObject[referenceCoverCategories.DIRECT_FAMILY.key] = {
                ...referenceCoverCategories.DIRECT_FAMILY,
                isComplete: true,
                viewMoreText: directViewMore,
                codes: directCodes
            };
        }

        if (parentsCodes.length) {
            coverCategoriesObject[referenceCoverCategories.PARENTS_AND_IN_LAWS.key] = {
                ...referenceCoverCategories.PARENTS_AND_IN_LAWS,
                isComplete: parentsComplete,
                viewMoreText: parentsViewMore,
                codes: parentsCodes
            }
        }

        if (extendedCodes.length) {
            coverCategoriesObject[referenceCoverCategories.EXTENDED_FAMILY.key] = {
                ...referenceCoverCategories.EXTENDED_FAMILY,
                isComplete: extendedComplete,
                viewMoreText: extendedViewMore,
                codes: extendedCodes
            }
        }

        return coverCategoriesObject;
    }
);