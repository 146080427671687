export const TRAVEL_INSURANCE_FORM = 'travelInsurance/quoteForm/';
export const UG_MOTOR_QOUTE_FORM = 'travelInsurance/quoteForm/';
export const TRAVEL_INSURANCE_FORM_SET_VALUE = TRAVEL_INSURANCE_FORM+ 'setValue';
export const TRAVEL_INSURANCE_FORM_SET_VALID = TRAVEL_INSURANCE_FORM+ 'setValid'
export const REINITIALIZE = TRAVEL_INSURANCE_FORM + '/state/reinitialize';
export const FORM_VALID_TRAVEL_INSURANCE_FORM = TRAVEL_INSURANCE_FORM+ 'formValid'
export const FORM_INVALID_TRAVEL_INSURANCE_FORM = TRAVEL_INSURANCE_FORM + 'formInValid';
export const FORM_VALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM = TRAVEL_INSURANCE_FORM + 'shareFormValid';
export const FORM_INVALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM = TRAVEL_INSURANCE_FORM + 'shareFormValid';

export const SET_SHARE_EMAIL_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'shareEmailValid';
export const transitionTravelInsuranceQuoteForm = {
    formValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_FORM,
    }),
};

export const transitionShareQuoteForm = {
    formInvalid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM,
    }),
    formValid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_TRAVEL_INSURANCE_QUOTE_FORM = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_TRAVEL_INSURANCE_QUOTE_FORM = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_TRAVEL_INSURANCE_QUOTE_FORM = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/shareQuote/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/shareQuote/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/shareQuote/apiTransition/failure`;
export const API_PRODUCTS_PENDING_TRANSITION = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/apiTransition/products/pending`;
export const API_PRODUCTS_SUCCESS_TRANSITION = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/apiTransition/products/success`;
export const API_PRODUCTS_FAILURE_TRANSITION = `${TRAVEL_INSURANCE_FORM_SET_VALUE}/apiTransition/products/failure`;

export const apiTransitionTravelInsuranceQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_TRAVEL_INSURANCE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_TRAVEL_INSURANCE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_TRAVEL_INSURANCE_QUOTE_FORM,
    }),
};

export const apiTransitionProducts = {
    pending: () => ({ 
        type:  API_PRODUCTS_PENDING_TRANSITION
    }),
    success: () => ({
        type: API_PRODUCTS_SUCCESS_TRANSITION,
    }),
    failure: () => ({
        type: API_PRODUCTS_FAILURE_TRANSITION,
    }),
}

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};

// //calculated Data
export const COLLECT_TRAVEL_INSURANCE_QUOTE_FORM_DATA = TRAVEL_INSURANCE_FORM_SET_VALUE + 'collectTravelInsuranceQuoteData';
export const TRAVEL_INSURANCE_ADD_COVER = TRAVEL_INSURANCE_FORM_SET_VALUE + 'addCover'
// export const COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceQuoteData';
// export const COLLECT_KE_THIRD_PARTY_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceThirdPartyQuoteData';
// export const SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'success';
// export const SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_TRAVEL_INSURANCE_PREMIUM_SUCCESS = TRAVEL_INSURANCE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_TRAVEL_INSURANCE_PREMIUM_FAILURE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'failure';
export const COLLECT_SHARE_QUOTE_FORM_DATA = TRAVEL_INSURANCE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS = TRAVEL_INSURANCE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_MOTOR_INSURANCE_QUOTE_FAILURE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'shareQuoteFailure';
export const TRIGGER_FETCH_ALL_PRODUCTS = TRAVEL_INSURANCE_FORM_SET_VALUE + 'fetchAllProducts';
export const FETCH_ALL_PRODUCTS_SUCCESS = TRAVEL_INSURANCE_FORM + 'fetchAllProducts/success';
export const FETCH_ALL_PRODUCTS_FAILURE = TRAVEL_INSURANCE_FORM + 'fetchAllProducts/failure';
export const FETCH_ALL_PRODUCTS_PENDING = TRAVEL_INSURANCE_FORM + 'fetchAllProducts/pending';

export const collectTravelInsuranceQuoteData = () => ({
    type: COLLECT_TRAVEL_INSURANCE_QUOTE_FORM_DATA,
});

// export const collectMotorInsuranceKeQuoteData = () => ({ type: COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA });
// export const collectThirdPartyQuoteData = () => ({ type: COLLECT_KE_THIRD_PARTY_DATA});
export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

// export const submitMotorInsuranceQuoteSuccess = () => ({
// 	type: SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS,
// 	value,
// });

export const shareQuoteSuccess = (value) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    value,
})

export const shareQuoteFailure = (error) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    error,
})

export const calculateTravelInsurancePremiumSuccess = (value) => ({
    type: CALCULATE_TRAVEL_INSURANCE_PREMIUM_SUCCESS,
    value,
});

export const triggerFetchAllProducts = () => ({
    type: TRIGGER_FETCH_ALL_PRODUCTS,
});

export const fetchAllProductsSuccess = (value) => ({
    type: FETCH_ALL_PRODUCTS_SUCCESS,
    value
});

export const fetchAllProductsFailure = (error) => ({
    type: FETCH_ALL_PRODUCTS_FAILURE,
    error,
});
export const fetchAllProductsPending = () => ({
    type: FETCH_ALL_PRODUCTS_PENDING,
})

// export const submitMotorInsuranceQuoteFailure = () => ({
// 	type: SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE,
// 	error,
// });

export const addCover = (value) => ({
	type: TRAVEL_INSURANCE_ADD_COVER,
	value,
})

// Form values
export const SET_TRIP_TYPE_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'tripTypeValue';
export const SET_TRIP_TYPE_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'tripTypeValid';
export const SET_COVER_FOR_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'coverForValue';
export const SET_COVER_FOR_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'coverForValid';

export const SET_DEPARTURE_COUNRTY_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'depatureCountryValue';
export const SET_DEPARTURE_COUNRTY_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'depatureCountryValid';
export const SET_DESTINATION_COUNRTY_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'destinationCountryValue';
export const SET_DESTINATION_COUNRTY_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'destinationCountryValid';
export const SET_DEPARTURE_DATE_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'depatureDateValue';
export const SET_DEPARTURE_DATE_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'depatureDateValid';

export const SET_RETURN_DATE_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'returnDateValue';
export const SET_RETURN_DATE_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'returnDateValid';

export const SET_DOB_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'DOBValue';
export const SET_DOB_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'DOBValid';

export const SET_NO_OF_TRAVELLERS_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'noOfTravellersValue';
export const SET_NO_OF_TRAVELLERS_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'noOfTravellersValid';


export const SET_SELECTED_COVER_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'selectTravelCover';
export const SET_SELECTED_COVER_VALID = TRAVEL_INSURANCE_FORM_SET_VALID + 'selectTravelCover';

export const UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE = TRAVEL_INSURANCE_FORM_SET_VALUE + 'sidebarOpen';
export const UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE = UG_MOTOR_QOUTE_FORM + 'shareQuote'; 

// action creators
export const setTripTypeValue = (value) => ({
    type: SET_TRIP_TYPE_VALUE,
    value,
});

export const setTripTypeValid = (error) => ({
    type: SET_TRIP_TYPE_VALID,
    error,
});

export const setCoverForValue = (value) => ({
    type: SET_COVER_FOR_VALUE,
    value,
});

export const setCoverForValid = (error) => ({
    type: SET_COVER_FOR_VALID,
    error,
});

export const setDepartureCountryValue = (value) => ({
    type: SET_DEPARTURE_COUNRTY_VALUE,
    value,
});

export const setDepartureCountryValid = (error) => ({
    type: SET_DEPARTURE_COUNRTY_VALID,
    error,
});

export const setDestinationCountryValue = (value) => ({
    type: SET_DESTINATION_COUNRTY_VALUE,
    value,
});

export const setDestinationCountryValid = (error) => ({
    type: SET_DESTINATION_COUNRTY_VALID,
    error,
});

export const setDepatureDateValue = (value) => ({
    type: SET_DEPARTURE_DATE_VALUE,
    value,
});

export const setDepatureDateValid = (error) => ({
    type: SET_DEPARTURE_DATE_VALID,
    error,
});

export const setDobDateValue = (value) => ({
    type: SET_DOB_VALUE,
    value,
});

export const setDobDateValid = (error) => ({
    type: SET_DOB_VALID,
    error,
});

export const setReturnDateValue = (value) => ({
    type: SET_RETURN_DATE_VALUE,
    value,
});

export const setReturnDateValid = (error) => ({
    type: SET_RETURN_DATE_VALID,
    error,
});

export const setNoOfTravellersValue = (value) => ({
    type: SET_NO_OF_TRAVELLERS_VALUE,
    value,
});

export const setNoOfTravellersValid = (error) => ({
    type: SET_NO_OF_TRAVELLERS_VALID,
    error,
});



export const initializeQuoteForm = () => ({
	type: REINITIALIZE,
})

export const setShareEmailValue = value => ({
	type: SET_SHARE_EMAIL_VALUE,
	value,
});

export const setShareEmailValid = error => ({
	type: SET_SHARE_EMAIL_VALID,
	error,
});

export const setSideBarOpen = (value) => ({ type: UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE, value });

export const setSelectedCoverValue = (value) => ({type: SET_SELECTED_COVER_VALUE, value});
export const setSelectedCoverValid = error => ({type: SET_SELECTED_COVER_VALID, error})


