import { 
    SPLIT_PERCENTAGE_SET_VALUE,
    EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT,
    INVALID_CONTINUE_TRANSITION_BENEFICIARIES_SPLIT
} from "../../../actions/raOptimal/beneficiariesSplit";
import {getBeneficiariesSplit} from "../../../selectors/raOptimal/beneficiarySplit";
import {simpleFieldTracking} from "../formTracking";
import {transitionTracking} from "../transitionTracking";

export const beneficiarySplitFieldsTracking = (store, next, action) => {
    const result = next(action);
    let sendValue = true;
    let sendError = true;
    const field = (() => {
        switch (action.type) {
            case SPLIT_PERCENTAGE_SET_VALUE:
                sendError = false;
                return {status: null, error: null, value: action.value};
            default: return null;
        }
    })();

    if (field) {
        simpleFieldTracking({action, field, sendValue, sendError});
    }

    return result;
};

export const beneficiarySplitTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const trackingActions = [
        EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT,
        INVALID_CONTINUE_TRANSITION_BENEFICIARIES_SPLIT
    ];
    const beneficiarieSplitForm = getBeneficiariesSplit(state).form;
    // capture relevant tracking data -- i.e. remove value from form fields
    const trackingData = Object.keys(beneficiarieSplitForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: beneficiarieSplitForm[key].status,
                error: beneficiarieSplitForm[key].error,
            }
        }), {});

    if (trackingActions.includes(action.type))
        transitionTracking(action.type, trackingData);

    return result;
};
