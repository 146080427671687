// Transitions
export const FORM_VALID_TRANSITION_MOTOR_DETAILS =
  "allsureInsurance/motorDetails/transition/formValid";
export const FORM_INVALID_TRANSITION_MOTOR_DETAILS =
  "allsureInsurance/motorDetails/transition/formInvalid";


export const transitionMotorDetails = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_MOTOR_DETAILS }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_MOTOR_DETAILS }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_MOTOR_DETAILS =
  "allsureInsurance/motorDetails/transition/resetState";
export const resetPersonalDetails = () => ({
  type: RESET_STATE_TRANSITION_MOTOR_DETAILS,
});

// Events


export const ALLSURE_INSURANCE_MOTOR_DETAILS =
  "allsureInsurance/motorDetails/";
export const MOTOR_DETAILS_SET_VALUE =
ALLSURE_INSURANCE_MOTOR_DETAILS+"setValue/";
export const MOTOR_DETAILS_SET_VALID =
ALLSURE_INSURANCE_MOTOR_DETAILS +"setValid/";


// export const TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE =
// ALLSURE_INSURANCE_INSURANCE_TYPE+"toogle/privacyNotice";

// export const  tooglePrivacyNotice=(value)=>({type: TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE, value})
export const setMotorDetailsValue =(value)=>({type: MOTOR_DETAILS_SET_VALUE, value})
export const setMotorDetailsFieldValid =(error,fieldType)=>({type: MOTOR_DETAILS_SET_VALID, error, fieldType})

export const MOTOR_DETAILS_SET_VALUE_WITHOUT_VALIDATIONS =
ALLSURE_INSURANCE_MOTOR_DETAILS+"withoutValidation/setValue";
export const setMotorDetailsValueWithoutValidation =(value)=>({type: MOTOR_DETAILS_SET_VALUE_WITHOUT_VALIDATIONS, value})
//add motor details forms
export const ADD_ADDITINAL_MOTOR_VALUE = ALLSURE_INSURANCE_MOTOR_DETAILS + 'addAdditionalMotorValue';
export const EDIT_MOTOR_DETAILS_VALUE = ALLSURE_INSURANCE_MOTOR_DETAILS + 'editMotorDetailsValue';
export const DELETE_MOTOR_DETAILS_VALUE =ALLSURE_INSURANCE_MOTOR_DETAILS +'deleteMotorDetailsValue'

export const UPDATE_MOTOR_DETAILS_FORMS =ALLSURE_INSURANCE_MOTOR_DETAILS+'updateMotorDetailsForm'

export const updateMotorDetailsForms=()=>({type: UPDATE_MOTOR_DETAILS_FORMS})

export const addAdditionalMotorValue = () => ({ type: ADD_ADDITINAL_MOTOR_VALUE }); 
export const editMotorDetailsValue = (value) => ({ type: EDIT_MOTOR_DETAILS_VALUE ,value}); 
export const deletetMotorDetailsValue = (value) => ({ type: DELETE_MOTOR_DETAILS_VALUE ,value}); 

//API's Actions motor 

export const HOME_MOTOR_QUOTE_API = 'allsureProduct/motor/motorApi/';

export const HOME_MOTOR_API_SEND = HOME_MOTOR_QUOTE_API + 'send';
export const HOME_MOTOR_API_STATUS = HOME_MOTOR_QUOTE_API + 'setApiStatus';

export const sendContentApiSend = () => ({ type: HOME_MOTOR_API_SEND });
export const sendContentQuoteApiStatus = value => ({ type: HOME_MOTOR_API_SEND, value });