import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

export function validateFile(
    value,
    {
        maxInputSize,
        maxInputSizeName,
        minInputSize,
        minInputSizeName,
        validFileTypes,
        subject = 'file',
        retainSubjectCase,
        required = true,
    } = {}) {
    if (!value && required) {
        return OmValidationMessageHelper.getUploadRequiredMessage();
    }

    if (!value && !required) {
        return null;
    }

    if (value && value.length > 1) {
        return OmValidationMessageHelper.getOneFileAtATimeMessage(retainSubjectCase);;
    }

    const uploadType = value.type.toLowerCase();
    const validFileFormats = validFileTypes.map(fileType => fileType.FORMAT);
    if (!validFileFormats.some(type => uploadType === type)) {
        return OmValidationMessageHelper.getIncorrectFormatMessage();;
    }

    if (value.size > maxInputSize)  {
        return OmValidationMessageHelper.getMaxFileSizeMessage(maxInputSizeName, retainSubjectCase);
    }
    if (minInputSize && value.size <minInputSize)  {
        return OmValidationMessageHelper.getFileSizeRangeMessage(maxInputSizeName,minInputSizeName,retainSubjectCase);
    }

    return null;
}
