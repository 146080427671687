import { getIsApiPending, getQuoteForm, getPremiumCalculation } from '../../../selectors/domesticInsurance/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure
} from '../../../actions/domesticInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);
    const premium = getPremiumCalculation(state);

    const payload = {
        email: quotation.shareEmail.value,
        buildingRate: premium.value.buildingRate || 0,
        contentsRate: premium.value.contentsRate || 0,
        risksRate: premium.value.risksRate || 0,
        wiibaRate: premium.value.wiibaRate || 0,
        ownerRate: premium.value.ownerRate || 0,
        total_base_premium: premium.value.total_base_premium || 0,
        lta_discount: premium.value.lta_discount || 0,
        ncd_discount: premium.value.ncd_discount || 0,
        eq_loading: premium.value.eq_loading || 0,
        levies: premium.value.levies || 0,
        premium: premium.value.premium || 0,
        buildingSum: parseInt(quotation.buildingSum.value) || 0,
        contentsSum: parseInt(quotation.contentsSum.value) || 0,
        risksSum: quotation.risksSum.value || 0,
        ownersSum: quotation.ownersSum.value || 0,
        countryCode: 'KE',
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/domestic-insurance/share-quote/domestic-insurance';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
