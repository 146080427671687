import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS, VALIDATION_CALL_STATUS } from "../status";
import {
    FORM_VALID_TRANSITION_ID_VERIFICATION,
    FORM_INVALID_TRANSITION_ID_VERIFICATION,
    ID_SET_VALUE,
    ID_SET_VALID,
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    SEND_ID_VERIFICATION_CALL,
    SUCCESS_ID_VERIFICATION_CALL,
    FAILURE_ID_VERIFICATION_CALL,
    IDENTITY_TOKEN_SET_VALUE,
    REFERENCE_GUID_SET_VALUE,
    OPEN_GOOD_TO_GO_MODAL_SET_VALUE,
    ID_VERIFICATION_RESET_PAGE
} from "../../actions/customerVerificationService/idVerification";

export const initIdVerification = {
    formStatus: FORM_STATUS.INVALID,
    verificationCallError: null,
    verificationCallSuccess: true,
    waitingForVerificationCall: false,
    identityToken: null,
    referenceGuid: null,
    firstName: null,
    lastName: null,
    openGoodToGoModal: false,
    form: {
        idNumber: { value: null, error: null, status: null },
    },
};

export default (state = initIdVerification, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_ID_VERIFICATION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_ID_VERIFICATION:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case ID_SET_VALUE:
            return updateFormField('idNumber', field => ({
                ...field,
                value: action.value,
            }));
        case ID_SET_VALID:
            return updateFormField('idNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }))
       
        case SEND_ID_VERIFICATION_CALL:
            return {
                ...state,
                verificationCallError: null,
                verificationCallSuccess: false,
                waitingForVerificationCall: true,
            };
        case SUCCESS_ID_VERIFICATION_CALL: {
            return {
                ...state,
                verificationCallError: null,
                verificationCallSuccess: true,
                waitingForVerificationCall: false,
            }
        }
        case FAILURE_ID_VERIFICATION_CALL: {
            return {
                ...state,
                verificationCallError: {
                    error: true,
                    errorObject: action.error,
                },
                verificationCallSuccess: false,
                waitingForVerificationCall: false,
            }
        }

        case IDENTITY_TOKEN_SET_VALUE:
            return { ...state, identityToken: action.value };

        case REFERENCE_GUID_SET_VALUE:
            return { ...state, referenceGuid: action.value };
        case FIRST_NAME_SET_VALUE:
            return {...state, firstName: action.value };
        case LAST_NAME_SET_VALUE:
            return { ...state, lastName: action.value };
        case OPEN_GOOD_TO_GO_MODAL_SET_VALUE:
            return { ...state, openGoodToGoModal: action.value };

        case ID_VERIFICATION_RESET_PAGE:
            return { ...initIdVerification };

        default:
            return state;
    }
}