export const FORM_TYPE = {
    COMPLAINT: 'complaint',
    ARBITRATION: 'arbitration',
};

export const OPEN_DRAWER = {
    CUSTOMER_COMPLAINT: 'customerComplaint',
};

export const IDENTITY_TYPE = {
    SA_ID: 'South African ID',
    PASSPORT: 'Passport',
};
