import { FIELD_STATUS, FORM_STATUS } from "../status";
import {
    SAVINGS_GOAL_SET_VALUE,
    SAVINGS_GOAL_SET_VALID,
    CURRENT_SAVINGS_GOAL_SET_VALUE,
    CURRENT_SAVINGS_GOAL_SET_VALID,
    EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE,
    EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID,
    ANNUAL_INCREASE_IN_SAVINGS_SET_VALUE,
    ANNUAL_INCREASE_IN_SAVINGS_SET_VALID,
    EXPECTED_SAVINGS_GROWTH_SET_VALUE,
    EXPECTED_SAVINGS_GROWTH_SET_VALID,
    FORM_VALID_REACH_SAVINGS_GOAL,
    FORM_INVALID_REACH_SAVINGS_GOAL,
    TOGGLE_SHOW_PRODUCT_OFFERING,
    REACH_SAVINGS_GOAL_SHOW_RESULT_SUMMARY,
    REACH_SAVINGS_GOAL_OPEN_MODAL,
    REACH_SAVINGS_GOAL_CLOSE_MODAL,
    RESET_CALCULATOR_FORM
} from '../../actions/savingsCalculator/whenWillYouReachYourSavingsGoal';

const whenWillYouReachYourSavingsGoalForm = {
    savingsGoal : { value: null, error: null, status: null },
    currentSavingsGoal : { value: null, error: null, status: null },
    expectedMonthlyContributions : { value: null, error: null, status: null },
    annualIncreaseInSavings : { value: null, error: null, status: null },
    expectedSavingsGrowth : { value: null, error: null, status: null },
}
const initialState = {
    formStatus: FORM_STATUS.INVALID,
    showProductOffering: false,
    whenWillYouReachYourGoalDate: null,
    form: whenWillYouReachYourSavingsGoalForm,
    openModal: false,
    showResultSummary: false
}


export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_REACH_SAVINGS_GOAL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_REACH_SAVINGS_GOAL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        // savingsGoal
        case SAVINGS_GOAL_SET_VALUE:
            return updateFormField('savingsGoal', field => ({
                ...field,
                value: action.value,
            }));

        case SAVINGS_GOAL_SET_VALID:
            return updateFormField('savingsGoal', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // current Savings Goal
        case CURRENT_SAVINGS_GOAL_SET_VALUE:
            return updateFormField('currentSavingsGoal', field => ({
                ...field,
                value: action.value,
            }));
        case CURRENT_SAVINGS_GOAL_SET_VALID:
            return updateFormField('currentSavingsGoal', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // expected Monthly Contributions
        case EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE:
            return updateFormField('expectedMonthlyContributions', field => ({
                ...field,
                value: action.value,
            }));
        case EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID:
            return updateFormField('expectedMonthlyContributions', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // annual Increase in Savings
        case ANNUAL_INCREASE_IN_SAVINGS_SET_VALUE:
            return updateFormField('annualIncreaseInSavings', field => ({
                ...field,
                value: action.value,
            }));
        case ANNUAL_INCREASE_IN_SAVINGS_SET_VALID:
            return updateFormField('annualIncreaseInSavings', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // expectedGrowth
        case EXPECTED_SAVINGS_GROWTH_SET_VALUE:
            return updateFormField('expectedSavingsGrowth', field => ({
                ...field,
                value: action.value,
            }));
        case EXPECTED_SAVINGS_GROWTH_SET_VALID:
            return updateFormField('expectedSavingsGrowth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case TOGGLE_SHOW_PRODUCT_OFFERING:
            return {
                ...state,
                showProductOffering: !state.showProductOffering,
            };

        case REACH_SAVINGS_GOAL_OPEN_MODAL:
            return{
                ...state,
                openModal: true,
            }

        case REACH_SAVINGS_GOAL_CLOSE_MODAL:
            return{
                ...state,
                openModal: false,
            }

        // Show result summary
        case REACH_SAVINGS_GOAL_SHOW_RESULT_SUMMARY:
            return {
                ...state,
                showResultSummary: true
            };

                // reinitialise state
        case RESET_CALCULATOR_FORM:
            return initialState;

        default:
            return state;
    }

}