import axios from 'axios';
import {
    getAboutYouForm,
} from '../../../selectors/roaTravelInsurance/aboutYou';
import { getIsGenerateCertApiPending } from '../../../selectors/roaTravelInsurance/payments';
import { getSelectedPlan} from '../../../selectors/roaTravelInsurance/plans';
import {
    getProducts,
    getTravelDetailsForm,
} from '../../../selectors/roaTravelInsurance/travelDetails';

import {
    submitTravelInsuranceGenerateCertificateSuccess,
    submitTravelInsuranceGenerateCertificateFailure,
    apiTransitionGenerateCertificate
} from '../../../actions/roaTravelInsurance/addTraveller';


const quoteCodeGenerator = (state) => {
    const selectedPlan = getSelectedPlan(state);
    const products = getProducts(state)
    const selectedPlanDetails = products.filter(
        (plan) => plan.name === selectedPlan.value
    )[0];
    const code =
        selectedPlanDetails?.quote_code;
    return code;
};

export const generateTravelCertService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    (async () => {
        if (!getIsGenerateCertApiPending(state)) {
            store.dispatch(apiTransitionGenerateCertificate.pending());
            try {
                const response = await generateCert(state);
                const cert = response?.data?.data?.travel_insurance_create_policy;
                if (cert) {
                    store.dispatch(
                        submitTravelInsuranceGenerateCertificateSuccess(
                            cert
                        )
                    );
                    store.dispatch(apiTransitionGenerateCertificate.success());
                    return;
                } else {
                    const e = response?.data?.errors[0];
                    const error = {
                        name: 'Error',
                        ...e,
                    };
                    store.dispatch(
                        submitTravelInsuranceGenerateCertificateFailure({
                            error,
                        })
                    );
                    store.dispatch(apiTransitionGenerateCertificate.failure());
                }
            } catch (e) {
                const error = {
                    massage: e.message,
                    name: e.name,
                };
                store.dispatch(
                    submitTravelInsuranceGenerateCertificateFailure({ error })
                );
                store.dispatch(apiTransitionGenerateCertificate.failure());
            }
        }
    })();
    return result;
};

const getPayload = (formDetails) => {

    const birthDate = formDetails.DOB.value
        .split('/')
        .reverse()
        .join('-');
    return {
        variables: {
            birth_date: birthDate,
            subscription_country: 'KE',
            email: formDetails.email.value,
            first_name: formDetails.firstName.value,
            last_name: formDetails.surname.value,
            title: formDetails.title.value.toUpperCase(),
            quote_code: formDetails.quote_code,
        },
    };
};

const generateCert = async (state) => {
    const travelDetailsForm = getTravelDetailsForm(state);
    const aboutDetails = getAboutYouForm(state);
    const q_code = {quote_code: quoteCodeGenerator(state)};
    const details = {
        ...travelDetailsForm,
        ...aboutDetails,
        ...q_code
    };
    try {
        const url = '/om-api/roa-travel-insurance/generate-cert';
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const result = await axios.post(url, getPayload(details), config);
        return result;
    } catch (error) {
        throw error;
    }
};
