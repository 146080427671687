import { leadValidPersonalDetails } from '../../../actions/taxFreeSavingsAccount/personalDetails';
import {
    getFirstNames,
    getSurname,
    getContactNumber,
    getLeadDetails,
    getAreDropoffDetailsValid,
} from '../../../selectors/taxFreeSavingsAccount/personalDetails';
import axios from "axios";

export const dropOffLeadsService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const dropOffDetailsValid = getAreDropoffDetailsValid(state);
    if (!dropOffDetailsValid) return result;

    const newLeadDetails = buildPayload(state);
    const previousLeadDetails = getLeadDetails(state);
    const leadDetailsChanged = JSON.stringify(previousLeadDetails) !== JSON.stringify(newLeadDetails);

    if (!leadDetailsChanged) return result;

    (async () => {
        if (await submitDropOffLead(newLeadDetails)){
            store.dispatch(leadValidPersonalDetails(newLeadDetails))
        }
    })();

    return result;
}

export const submitDropOffLead = async payload => {
    try {
        const url = '/om-api/tax-free-savings-account/drop-off-leads';
        const config = {headers: {'Content-Type': 'application/json'}};
        const response = await axios.post(url, payload, config);
        return response.status === 200;
    } catch (error) {
        return false;
    }
};

export const buildPayload = state => ({
    firstname: getFirstNames(state).value,
    lastName: getSurname(state).value,
    cellphoneNumber: getContactNumber(state).value,
    projectCode: "ILMs_TFSAIA"
});
