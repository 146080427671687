import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from "../status";

import {
    FORM_VALID_GENERATE_REGULAR_INCOME_TRANSITION,
    FORM_INVALID_GENERATE_REGULAR_INCOME_TRANSITION,
    ONCE_OFF_SET_VALUE,
    ONCE_OFF_SET_VALID,
    MONTHLY_AMOUNT_SET_VALUE,
    MONTHLY_AMOUNT_SET_VALID,
    MONEY_MARKET_FUND_PERCENT_SET_VALUE,
    MONEY_MARKET_FUND_SPLIT_SET_VALUE,
    MONEY_MARKET_FUND_PERCENT_SET_VALID,
    BOND_FUND_PERCENT_SET_VALUE,
    BOND_FUND_SPLIT_SET_VALUE,
    BOND_FUND_PERCENT_SET_VALID,
    EVEN_SPLIT_SELECT_FUND_SPLIT,
    EVEN_SPLIT_DESELECT_FUND_SPLIT,
    EVEN_SPLIT_DISCARD_FUND_SPLIT,
    REINITIALIZE_STATE_GENERATE_REGULAR_INCOME_CALCULATOR,
    RESULTS_SET_VALUE_FAILURE,
    RESULTS_SET_VALUE,
    CALCULATE_GENERATE_REGULAR_INCOME,
    API_PENDING_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR,
    API_SUCCESS_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR,
    API_FAILURE_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR,
} from '../../actions/investmentCalculator/generateRegularIncome'

const generateRegularIncomeForm = {
    amountOnceOff : {value: null, error: null, status: null},
    amountMonthly : {value:null, error: null, status:null},
    moneyMarketFundPercentage : {value: 50, error: null, status: "valid"},
    bondFundPercentage : {value: 50, error: null, status: "valid"},
    moneyMarketFundSplit: {value: 50, error: null, status: "valid"},
    bondFundSplit : {value: 50, error: null, status: "valid"},
}
export const initialState = {
  formStatus: FORM_STATUS.INVALID,
  showRecommendation: false,
  apiCallStatus: API_CALL_STATUS.IDLE,
  form: generateRegularIncomeForm,
  isEvenSplit: true,
  splitError: null,
  totalPercentage: '100',
  result: {},
};

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) =>{
        return {
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
        }
    };
    switch(action.type) {
        // Form Validation
        case FORM_VALID_GENERATE_REGULAR_INCOME_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_GENERATE_REGULAR_INCOME_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.INVALID };


        // Api call
        case API_PENDING_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        // amount once off
        case ONCE_OFF_SET_VALUE:
            return updateFormField('amountOnceOff', field => ({
                ...field,
                value: action.value,
            }));
        case ONCE_OFF_SET_VALID:
            return updateFormField('amountOnceOff', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // amount monthly
        case MONTHLY_AMOUNT_SET_VALUE:
            return updateFormField('amountMonthly', field => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_AMOUNT_SET_VALID:
            return updateFormField('amountMonthly', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // amount split into money market fund
        case MONEY_MARKET_FUND_PERCENT_SET_VALUE:
            return updateFormField('moneyMarketFundPercentage', field => ({
                ...field,
                value: action.value,
            }));
        case MONEY_MARKET_FUND_SPLIT_SET_VALUE:
            return updateFormField('moneyMarketFundSplit', field => ({
                ...field,
                value: action.value,
            }));
        case MONEY_MARKET_FUND_PERCENT_SET_VALID:
            return {...state, splitError: action.error}

        // amount split into bond fund
        case BOND_FUND_PERCENT_SET_VALUE:
            return updateFormField('bondFundPercentage', field => ({
                ...field,
                value: action.value,
            }));
        case BOND_FUND_SPLIT_SET_VALUE:
            return updateFormField('bondFundSplit', field => ({
                ...field,
                value: action.value,
            }));
        case BOND_FUND_PERCENT_SET_VALID:
            return {...state, splitError: action.error}

        //split checkbox event
        case EVEN_SPLIT_SELECT_FUND_SPLIT:
            let modifiedState = {
                ...state,
                isEvenSplit: true,
                totalPercentage: '100',
                splitError: null
            };
            let form = modifiedState.form;
            let modifiedForm = {
                ...form,
                moneyMarketFundPercentage : {value: 50, error: null, status: "valid"},
                bondFundPercentage : {value: 50, error: null, status: "valid"},
            }
            return {
                ...modifiedState,
                form: modifiedForm
            };


        case EVEN_SPLIT_DESELECT_FUND_SPLIT:
            return {
                ...state,
                isEvenSplit: false
            };
        case EVEN_SPLIT_DISCARD_FUND_SPLIT:
            return {
                ...state,
                totalPercentage: '100',
            };

        // Get investment Result
        case RESULTS_SET_VALUE:
            return {
                ...state,
                data: action.value
            }

        case RESULTS_SET_VALUE_FAILURE:
            return {
                ...state,
                statstatus: API_CALL_STATUS.FAILURE,
                error: action.error
            }

        // Set calculation results value
        case CALCULATE_GENERATE_REGULAR_INCOME:
            return {
                ...state,
                result: action.value
            }

        // reinitialise state
        case REINITIALIZE_STATE_GENERATE_REGULAR_INCOME_CALCULATOR:
            return initialState;

        default:
            return state;
    }
}
