import axios from 'axios';
import {
    getIsApiPending,
} from '../../../selectors/roaCarInsurance/quotation';
import {
    apiTransition,
    calculateCarModelsSuccess
} from '../../../actions/roaCarInsurance/quotation';

export const carModelsService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransition.pending());
            try {
                const resp = await fetchCarModels();
                store.dispatch(calculateCarModelsSuccess(resp.data));
                if (resp.data.code) {
                    store.dispatch(apiTransition.failure(resp.data.code));
                } else {
                    store.dispatch(apiTransition.success());
                }
            } catch (e) {
                store.dispatch(apiTransition.failure(e.message));
            }
        }
    })();
    return next(action);
};

const fetchCarModels = async () => {
    try {
        const url = '/om-api/roa-car-insurance/nigeria/car-models'
        return await axios.get(url);
    } catch (e) {
        throw e;
    }
};
