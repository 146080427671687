import {
    transitionHowMuchYouNeedToSaveCalculator,
} from "../../../actions/savingsCalculator/howMuchYouNeedToSaveCalculator"

import {
    gethowMuchYouNeedToSaveCalculatorForm,
} from "../../../selectors/savingsCalculator/howMuchYouNeedToSaveCalculator"
import {transitionScrolling} from "../../../actions/savingsCalculator/scrolling"
import {FIELD_STATUS} from "../../../reducers/status";

export const howMuchYouNeedToSaveCalculateFormRules = (store, next, action) => {
    const result = next(action);

    const howMuchYouNeedToSaveCalculatorForm = gethowMuchYouNeedToSaveCalculatorForm(store.getState());
    const howMuchYouNeedToSaveCalculatorValid = validateFormDetails(howMuchYouNeedToSaveCalculatorForm);
    const formAction = howMuchYouNeedToSaveCalculatorValid
        ? transitionHowMuchYouNeedToSaveCalculator.formValid()
        : transitionHowMuchYouNeedToSaveCalculator.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFields.savingsGoal && formFields.savingsGoal.status === FIELD_STATUS.VALID &&
    formFields.achieveGoalDate && formFields.achieveGoalDate.status === FIELD_STATUS.VALID &&
    formFields.currentSavings && formFields.currentSavings.status === FIELD_STATUS.VALID
);