// Events
export const EFP_APPLICATION_REFERENCE_DATA_SET_VALUE = 'easiplusFuneralPlan/applicationReferenceData/setValue/';
export const EFP_APPLICATION_REFERENCE_DATA_GET_VALUE = 'easiplusFuneralPlan/applicationReferenceData/getValue/';
export const EFP_QUOTE_REFERENCE_DATA_SET_VALUE = 'easiplusFuneralPlan/quoteReferenceData/setValue/';
export const EFP_QUOTE_REFERENCE_DATA_GET_VALUE = 'easiplusFuneralPlan/quoteReferenceData/getValue/';

export const setApplicationReferenceData = value => ({ type: EFP_APPLICATION_REFERENCE_DATA_SET_VALUE, value });
export const getApplicationReferenceData = () => ({ type: EFP_APPLICATION_REFERENCE_DATA_GET_VALUE });
export const setQuoteReferenceData = value => ({ type: EFP_QUOTE_REFERENCE_DATA_SET_VALUE, value });
export const getQuoteReferenceData = () => ({ type: EFP_QUOTE_REFERENCE_DATA_GET_VALUE });

export const RESET_REFERENCE_DATA = 'easiplusFuneralPlan/referenceData/setValue/resetState';
export const resetReferenceData = () => ({ type: RESET_REFERENCE_DATA });
