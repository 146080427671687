import { validateAccountNumber, validateName, validateSingleSelection } from "@om/validation";
import {
    setBankNameValid,
    setDebitOrderDayValid, 
    setAccountTypeValid,
    setAccountNumberValid,

    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    BRANCH_CODE_SET_VALUE,
    setBranchCodeValid,
    PAYMENT_AUTHORIZATION_SET_VALUE,
    setPaymentAuthorizationValid,
    ACCOUNT_HOLDER_NAME_SET_VALUE,
    setAccountHolderValid,
} from "../../../actions/digiLifeLoanProtection/paymentDetails";

import { validationIsEqual } from "../status";
import { TOGGLE_STATUS } from "../../../reducers/status";

let prevValidationAction = null;
export const paymentDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case BANK_NAME_SET_VALUE:
                error = validateSingleSelection(action.value.id, 'bank name');
                return setBankNameValid(error);

            case BRANCH_CODE_SET_VALUE:
                error = validateSingleSelection(action.value.id, 'branch code');
                return setBranchCodeValid(error);

            case ACCOUNT_NUMBER_SET_VALUE:
                error = validateAccountNumber(action.value,{min:7,max:13});
                return setAccountNumberValid(error);
            case ACCOUNT_HOLDER_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 3,
                    max: 66,
                    subject: "Account holder name",
                  });
                return setAccountHolderValid(error);
    
            case ACCOUNT_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value.id, 'account type');
                return setAccountTypeValid(error);

            case DEBIT_ORDER_DAY_SET_VALUE:
                error = validateSingleSelection(action.value, 'debit order start date');
                return setDebitOrderDayValid(error);

            case PAYMENT_AUTHORIZATION_SET_VALUE:
                error = action.value == TOGGLE_STATUS.NO ? 'error' : null
                return setPaymentAuthorizationValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
