import { MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE } from "../../../actions/motorPrivateRW/carDetails";
import { MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE } from "../../../actions/motorPrivateRW/declarations";
import { MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE } from "../../../actions/motorPrivateRW/Documents";
import { MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE } from "../../../actions/motorPrivateRW/motorAgentModal";
import { PAYMENTS_SET_VALUE } from "../../../actions/motorPrivateRW/payments";
import { MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE } from "../../../actions/motorPrivateRW/personalDetails"
import { RW_MOTOR_QOUTE_FORM_SET_VALUE, SET_SHARE_EMAIL_VALUE } from "../../../actions/motorPrivateRW/quote";
import { carDetailsValidation } from "./carDetails";
import { declarationsFormValidation } from "./declarations";
import { documentsValidation } from "./documents";
import { agentModalFormValidation } from "./motorAgentModel";
import { paymentsFormValidation } from "./payment";
import { personalDetailsValidation } from "./personalDetails"
import { quoteValidation } from "./quote";
import { shareQuoteValidation } from "./shareQuote";

export const rwMotorInsuranceValidation = (store, next, action) => {
    if(action.type.startsWith(MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE)){
        return personalDetailsValidation(store, next, action);
    }

    if(action.type.startsWith(RW_MOTOR_QOUTE_FORM_SET_VALUE)){
        return quoteValidation(store, next, action);
    }

    if(action.type.startsWith(MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE)){
        return carDetailsValidation(store, next, action);
    }

    if(action.type.startsWith(MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE)){
        return carDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE)) {
		return shareQuoteValidation(store, next, action);
	}

    if (action.type.startsWith(MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE)) {
		return documentsValidation(store, next, action);
	}

    if (action.type.startsWith(MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE)) {
		return declarationsFormValidation(store, next, action);
	}

    if (action.type.startsWith(PAYMENTS_SET_VALUE)) {
		return paymentsFormValidation(store, next, action);
	}

    if (action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE)) {
        return agentModalFormValidation(store, next, action)
    }


    return null
}

