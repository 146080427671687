import { createSelector } from "reselect";
import { getAtRetirement } from "./index";
import {OPEN_DRAWER} from "../../actions/atRetirement/types";

const makeGetField = fieldId => createSelector(
    getSolutionsForm,
    solutionsForm => solutionsForm[fieldId]
);

export const getSolutions = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.solutions
);

export const getSolutionsForm = createSelector(
    getSolutions,
    solutions => solutions.form,
);

export const getSolutionsOpenDrawer = createSelector(
    getSolutions,
    solutions => solutions.openDrawer
);

export const getIsGuaranteedOpen = createSelector(
    getSolutions,
    solutions => solutions.openDrawer == OPEN_DRAWER.GUARANTEED_DRAWER,
);

export const getIsLivingOpen = createSelector(
    getSolutions,
    solutions => solutions.openDrawer == OPEN_DRAWER.LIVING_DRAWER,
)

export const getIsCompositeOpen = createSelector(
    getSolutions,
    solutions => solutions.openDrawer == OPEN_DRAWER.COMPOSITE_DRAWER,
)

export const getSolutionsProductChosen = makeGetField('solutionsProductChosen');