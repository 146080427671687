import { getIsApiPending, getQuoteForm, getProducts } from '../../../selectors/travel/quote';
import { apiTransitionTravelInsuranceQuoteForm, calculateTravelInsurancePremiumSuccess } from '../../../actions/travel/quote';
import { getAccessToken } from '../../../selectors/travel/payments';
import axios from 'axios';


export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionTravelInsuranceQuoteForm.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateTravelInsurancePremiumSuccess(resp.data));
                store.dispatch(apiTransitionTravelInsuranceQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionTravelInsuranceQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

function convertDateFormat(dateString) {
    // Split the date string into day, month, and year
    var parts = dateString.split('/');

    // Rearrange the parts into the desired format
    var formattedDate = parts[2] + '-' + parts[1].padStart(2, '0') + '-' + parts[0].padStart(2, '0');

    return formattedDate;
}

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);
    const returnDate = convertDateFormat(quotation.returnDate.value);
    const departureDate = convertDateFormat(quotation.departureDate.value);
    const dateOfBirth = convertDateFormat(quotation.dob.value);
    const products = getProducts(state)
    const destinationId = quotation.destinationCountry.value
    const selectedProduct = products.products.find((product) => product?.ID == destinationId);
    const productSuffix = selectedProduct.PRODUCT_SUFFIX;
    const productId = `${selectedProduct.PRODUCT_ID}`;

    const payload = {
        returnDate,
        departureDate,
        productSuffix,
        dateOfBirth,
        productId,
    };
    // Change payload here
    return {
        ...payload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-travel-insurance/compute-premium';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e
    }
};
