import { validateAlphaNumericStringAllowingOnlyNumbers, validateAlphaString } from '@om/validation';
import {
    SET_LIMIT_INDEMNITY_VALUE,
    SET_SPECIALTIES_VALUE,
    setLimitIndemnityValid,
    setSpecialtiesValid,
} from '../../../actions/doctorsPi/quote';
import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const quoteValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SET_LIMIT_INDEMNITY_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'Limit Indmenity value',
                    required: true,
                });
                return setLimitIndemnityValid(error);

            case SET_SPECIALTIES_VALUE:
                error = validateAlphaString(action.value, {
                    subject: 'Specialization',
                    required: true,
                });
                return setSpecialtiesValid(error);
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
