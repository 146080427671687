// Transitions
export const FORM_VALID_TRANSITION_PERSONAL_DETAILS = 'raOptimal/personalDetails/transition/formValid';
export const FORM_INVALID_TRANSITION_PERSONAL_DETAILS = 'raOptimal/personalDetails/transition/formInvalid';
export const PERSONAL_DETAILS_FORM_SET_VALUE = 'raOptimal/personalDetails/transition/formValue';

export const setPersonalDetailFormValues = () => ({ type: PERSONAL_DETAILS_FORM_SET_VALUE });

export const transitionPersonalDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_PERSONAL_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_PERSONAL_DETAILS }),
};

export const INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS = 'raOptimal/personalDetails/transition/invalidContinue';
export const CONTINUE_TRANSITION_PERSONAL_DETAILS = 'raOptimal/personalDetails/transition/validContinue';
export const BACK_TRANSITION_PERSONAL_DETAIL = 'raOptimal/personalDetails/transition/back';
export const invalidContinuePersonalDetails = () => ({ type: INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS });
export const continuePersonalDetails = () => ({ type: CONTINUE_TRANSITION_PERSONAL_DETAILS });
export const backPersonalDetails = () => ({ type: BACK_TRANSITION_PERSONAL_DETAIL });

// Events
export const PERSONAL_DETAILS_CLEAR_VALUE = 'raOptimal/personalDetails/clearValue/';
export const PERSONAL_DETAILS_SET_VALUE = 'raOptimal/personalDetails/setValue/';
export const PERSONAL_DETAILS_SET_VALID = 'raOptimal/personalDetails/setValid/';

export const POSTAL_ADDRESS_METHOD_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalAddressMethod';
export const POSTAL_ADDRESS_METHOD_VALID = PERSONAL_DETAILS_SET_VALID + 'postalAddressMethod';
export const POSTAL_ADDRESS_SAME_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalAddressSame';
export const POSTAL_ADDRESS_SAME_VALID = PERSONAL_DETAILS_SET_VALID + 'postalAddressSame';

export const TITLE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'title';
export const TITLE_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'title';
export const MIDDLE_NAME_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'middleName';
export const MIDDLE_NAME_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'middleName';
export const EMAIL_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'email';
export const EMAIL_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'email';
export const MARITAL_STATUS_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'maritalStatus';
export const MARITAL_STATUS_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'maritalStatus';
export const COUNTRY_OF_BIRTH_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'countryOfBirth';
export const COUNTRY_OF_BIRTH_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'countryOfBirth';
export const NATIONALITY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'nationality';
export const NATIONALITY_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'nationality';
export const ID_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'id';
export const ID_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'id';
export const INCOME_TAX_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'incomeTax';
export const INCOME_TAX_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'incomeTax';
export const COMPLEX_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'complex';
export const COMPLEX_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'complex';
export const STREET_ADDRESS_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'streetAddress';
export const STREET_ADDRESS_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'streetAddress';
export const SUBURB_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'suburb';
export const SUBURB_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'suburb';
export const CITY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'city';
export const CITY_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'city';
export const POSTAL_CODE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalCode';
export const POSTAL_CODE_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'postalCode';
export const COUNTRY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'country';
export const COUNTRY_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'country';

export const PO_BOX_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'poBox';
export const PO_BOX_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'poBox';
export const PO_POST_OFFICE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'poPostOffice';
export const PO_POST_OFFICE_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'poPostOffice';
export const PO_POSTAL_CODE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'poPostalCode';
export const PO_POSTAL_CODE_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'poPostalCode';
export const PO_COUNTRY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'poCountry';
export const PO_COUNTRY_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'poCountry';

export const PRIVATE_BAG_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'privateBag';
export const PRIVATE_BAG_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'privateBag';
export const PRIVATE_POST_OFFICE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'privatePostOffice';
export const PRIVATE_POST_OFFICE_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'privatePostOffice';
export const PRIVATE_POSTAL_CODE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'privatePostalCode';
export const PRIVATE_POSTAL_CODE_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'privatePostalCode';
export const PRIVATE_COUNTRY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'privateCountry';
export const PRIVATE_COUNTRY_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'privateCountry';

export const POSTAL_COMPLEX_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalComplex';
export const POSTAL_COMPLEX_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'postalComplex';
export const POSTAL_STREET_ADDRESS_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalStreetAddress';
export const POSTAL_STREET_ADDRESS_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'postalStreetAddress';
export const POSTAL_SUBURB_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalSuburb';
export const POSTAL_SUBURB_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'postalSuburb';
export const POSTAL_CITY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalCity';
export const POSTAL_CITY_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'postalCity';
export const POSTAL_POSTAL_CODE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalPostalCode';
export const POSTAL_POSTAL_CODE_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'postalPostalCode';
export const POSTAL_COUNTRY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'postalCountry';
export const POSTAL_COUNTRY_SET_VALID = PERSONAL_DETAILS_SET_VALID + 'postalCountry';

export const PO_BOX_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'poBox';
export const PO_POST_OFFICE_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'poPostOffice';
export const PO_POSTAL_CODE_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'poPostalCode';
export const PO_COUNTRY_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'poCountry';

export const PRIVATE_BAG_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'privateBag';
export const PRIVATE_POST_OFFICE_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'privatePostOffice';
export const PRIVATE_POSTAL_CODE_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'privatePostalCode';
export const PRIVATE_COUNTRY_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'privateCountry';

export const POSTAL_COMPLEX_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'postalComplex';
export const POSTAL_STREET_ADDRESS_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'postalStreetAddress';
export const POSTAL_SUBURB_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'postalSuburb';
export const POSTAL_CITY_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'postalCity';
export const POSTAL_POSTAL_CODE_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'postalPostalCode';
export const POSTAL_COUNTRY_CLEAR_VALUE = PERSONAL_DETAILS_CLEAR_VALUE + 'postalCountry';

export const GCS_STREET_ADDRESS_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'gCSStreetAddressValue';
export const GCS_POSTAL_ADDRESS_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + 'gCSPostalAddressValue';

export const setPostalAddressMethodValue = value => ({ type: POSTAL_ADDRESS_METHOD_VALUE, value });
export const setPostalAddressMethodValid = error => ({ type: POSTAL_ADDRESS_METHOD_VALID, error });
export const setPostalAddressSameValue = value => ({ type: POSTAL_ADDRESS_SAME_VALUE, value });
export const setPostalAddressSameValid = error => ({ type: POSTAL_ADDRESS_SAME_VALID, error });
export const setTitleValue = value => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = error => ({ type: TITLE_SET_VALID, error });
export const setMiddleNameValue = value => ({ type: MIDDLE_NAME_SET_VALUE, value });
export const setMiddleNameValid = error => ({ type: MIDDLE_NAME_SET_VALID, error });

export const setEmailValue = value => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = error => ({ type: EMAIL_SET_VALID, error });
export const setMaritalStatusValue = value => ({ type: MARITAL_STATUS_SET_VALUE, value });
export const setMaritalStatusValid = error => ({ type: MARITAL_STATUS_SET_VALID, error });
export const setCountryOfBirthValue = value => ({ type: COUNTRY_OF_BIRTH_SET_VALUE, value });
export const setCountryOfBirthValid = error => ({ type: COUNTRY_OF_BIRTH_SET_VALID, error });
export const setNationalityValue = value => ({ type: NATIONALITY_SET_VALUE, value });
export const setNationalityValid = error => ({ type: NATIONALITY_SET_VALID, error });
export const setIdValue = value => ({ type: ID_SET_VALUE, value });
export const setIdValid = error => ({ type: ID_SET_VALID, error });
export const setIncomeTaxValue = value => ({ type: INCOME_TAX_SET_VALUE, value });
export const setIncomeTaxValid = error => ({ type: INCOME_TAX_SET_VALID, error });

export const setComplexValue = value => ({ type: COMPLEX_SET_VALUE, value });
export const setComplexValid = error => ({ type: COMPLEX_SET_VALID, error });
export const setStreetAddressValue = value => ({ type: STREET_ADDRESS_SET_VALUE, value });
export const setStreetAddressValid = error => ({ type: STREET_ADDRESS_SET_VALID, error });
export const setSuburbValue = value => ({ type: SUBURB_SET_VALUE, value });
export const setSuburbValid = error => ({ type: SUBURB_SET_VALID, error });
export const setCityValue = value => ({ type: CITY_SET_VALUE, value });
export const setCityValid = error => ({ type: CITY_SET_VALID, error });
export const setPostalCodeValue = value => ({ type: POSTAL_CODE_SET_VALUE, value });
export const setPostalCodeValid = error => ({ type: POSTAL_CODE_SET_VALID, error });
export const setCountryValue = value => ({ type: COUNTRY_SET_VALUE, value });
export const setCountryValid = error => ({ type: COUNTRY_SET_VALID, error });

export const setPoBoxValue = value => ({ type: PO_BOX_SET_VALUE, value });
export const setPoBoxValid = error => ({ type: PO_BOX_SET_VALID, error });
export const setPoPostOfficeValue = value => ({ type: PO_POST_OFFICE_SET_VALUE, value });
export const setPoPostOfficeValid = error => ({ type: PO_POST_OFFICE_SET_VALID, error });
export const setPoPostalCodeValue = value => ({ type: PO_POSTAL_CODE_SET_VALUE, value });
export const setPoPostalCodeValid = error => ({ type: PO_POSTAL_CODE_SET_VALID, error });
export const setPoCountryValue = value => ({ type: PO_COUNTRY_SET_VALUE, value });
export const setPoCountryValid = error => ({ type: PO_COUNTRY_SET_VALID, error });

export const setPrivateBagValue = value => ({ type: PRIVATE_BAG_SET_VALUE, value });
export const setPrivateBagValid = error => ({ type: PRIVATE_BAG_SET_VALID, error });
export const setPrivatePostOfficeValue = value => ({ type: PRIVATE_POST_OFFICE_SET_VALUE, value });
export const setPrivatePostOfficeValid = error => ({ type: PRIVATE_POST_OFFICE_SET_VALID, error });
export const setPrivatePostalCodeValue = value => ({ type: PRIVATE_POSTAL_CODE_SET_VALUE, value });
export const setPrivatePostalCodeValid = error => ({ type: PRIVATE_POSTAL_CODE_SET_VALID, error });
export const setPrivateCountryValue = value => ({ type: PRIVATE_COUNTRY_SET_VALUE, value });
export const setPrivateCountryValid = error => ({ type: PRIVATE_COUNTRY_SET_VALID, error });

export const setPostalComplexValue = value => ({ type: POSTAL_COMPLEX_SET_VALUE, value });
export const setPostalComplexValid = error => ({ type: POSTAL_COMPLEX_SET_VALID, error });
export const setPostalStreetAddressValue = value => ({ type: POSTAL_STREET_ADDRESS_SET_VALUE, value });
export const setPostalStreetAddressValid = error => ({ type: POSTAL_STREET_ADDRESS_SET_VALID, error });
export const setPostalSuburbValue = value => ({ type: POSTAL_SUBURB_SET_VALUE, value });
export const setPostalSuburbValid = error => ({ type: POSTAL_SUBURB_SET_VALID, error });
export const setPostalCityValue = value => ({ type: POSTAL_CITY_SET_VALUE, value });
export const setPostalCityValid = error => ({ type: POSTAL_CITY_SET_VALID, error });
export const setPostalPostalCodeValue = value => ({ type: POSTAL_POSTAL_CODE_SET_VALUE, value });
export const setPostalPostalCodeValid = error => ({ type: POSTAL_POSTAL_CODE_SET_VALID, error });
export const setPostalCountryValue = value => ({ type: POSTAL_COUNTRY_SET_VALUE, value });
export const setPostalCountryValid = error => ({ type: POSTAL_COUNTRY_SET_VALID, error });

export const setGCSStreetAddressValue = value => ({ type: GCS_STREET_ADDRESS_SET_VALUE, value });
export const setGCSPostalAddressValue = value => ({ type: GCS_POSTAL_ADDRESS_SET_VALUE, value });

export const clearPoBoxValue = () => ({ type: PO_BOX_CLEAR_VALUE });
export const clearPoPostOfficeValue = () => ({ type: PO_POST_OFFICE_CLEAR_VALUE });
export const clearPoPostalCodeValue = () => ({ type: PO_POSTAL_CODE_CLEAR_VALUE });
export const clearPoCountryValue = () => ({ type: PO_COUNTRY_CLEAR_VALUE });

export const clearPrivateBagValue = () => ({ type: PRIVATE_BAG_CLEAR_VALUE });
export const clearPrivatePostOfficeValue = () => ({ type: PRIVATE_POST_OFFICE_CLEAR_VALUE });
export const clearPrivatePostalCodeValue = () => ({ type: PRIVATE_POSTAL_CODE_CLEAR_VALUE });
export const clearPrivateCountryValue = () => ({ type: PRIVATE_COUNTRY_CLEAR_VALUE });

export const clearPostalComplexValue = () => ({ type: POSTAL_COMPLEX_CLEAR_VALUE });
export const clearPostalStreetAddressValue = () => ({ type: POSTAL_STREET_ADDRESS_CLEAR_VALUE });
export const clearPostalSuburbValue = () => ({ type: POSTAL_SUBURB_CLEAR_VALUE });
export const clearPostalCityValue = () => ({ type: POSTAL_CITY_CLEAR_VALUE });
export const clearPostalPostalCodeValue = () => ({ type: POSTAL_POSTAL_CODE_CLEAR_VALUE });
export const clearPostalCountryValue = () => ({ type: POSTAL_COUNTRY_CLEAR_VALUE });

export const RESET_PERSONAL_DETAILS = PERSONAL_DETAILS_SET_VALUE + 'resetState';
export const resetPersonalDetails = () => ({type: RESET_PERSONAL_DETAILS});