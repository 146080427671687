import {
    getFundSplitFormLumpSum,
    getFundSplitFormRegular,
    getIsEvenSplit,
} from "../../../selectors/taxFreeSavingsAccount/fundSplit";
import { getSelectedFundList } from "../../../selectors/taxFreeSavingsAccount/advancedFundPicker";
import { toggleFundSelection } from "../../../actions/taxFreeSavingsAccount/advancedFundPicker"
import {
    SPLIT_PERCENTAGE_SET_VALUE,
    DELETE_FUND_FUND_SPLIT,
    deselectEvenSplit,
    setSplitPercentageValue,
    formInitTransitionFundSplit,
    selectEvenSplit,
} from "../../../actions/taxFreeSavingsAccount/fundSplit";
import {FIELD_STATUS} from "../../../reducers/status";
import {scrollToId} from "../../../actions/taxFreeSavingsAccount/scrolling";
import {
    TFSA_FUND_SPLIT_LUMP_SUM_CARD_ID_PREFIX,
    TFSA_FUND_SPLIT_REGULAR_CARD_ID_PREFIX,
} from "../status";
import {FUND_SPLIT_TYPE, FUND_SPLIT_FORM} from "../../../actions/taxFreeSavingsAccount/types";

export const fundSplitRules = (store, next, action) => {
    const result = next(action);
    
    if(action.type === SPLIT_PERCENTAGE_SET_VALUE) {
        fundSplitValueChange(store, action);
    }

    if(action.type === DELETE_FUND_FUND_SPLIT) {
        deleteFund(store, action);
    }

    return result;
};

export const fundSplitValueChange = (store, action) =>{
    const state = store.getState();
    const evenSplit = getIsEvenSplit(state);
   
    // if even split is active for regular and user changes a split field, deselect even split
    if (action.fundType === FUND_SPLIT_FORM.REGULAR && evenSplit.regular) {
        store.dispatch(deselectEvenSplit(FUND_SPLIT_TYPE.REGULAR));
    } 

    // if even split is active for lumpsum and user changes a split field, deselect even split
    if (action.fundType === FUND_SPLIT_FORM.LUMP_SUM && evenSplit.lumpSum) {
        store.dispatch(deselectEvenSplit(FUND_SPLIT_TYPE.LUMP_SUM));
    }
}

export const deleteFund = (store, action) => {
    const state = store.getState();

    const advancedSelectedFunds = getSelectedFundList(state);

    // get updated fund list
    const updatedFunds = advancedSelectedFunds.filter(fund => fund !== action.id);
    
    // Removes the fund from the advanced fund picker (previous page)
    store.dispatch(toggleFundSelection(action.id, false));

    // Reset the funds with the removed fund
    store.dispatch(formInitTransitionFundSplit(updatedFunds));

    // Deselects the split for both regular and lumpsum if more than one fund
    if(updatedFunds.length > 1){
        store.dispatch(deselectEvenSplit(FUND_SPLIT_TYPE.REGULAR));
        store.dispatch(deselectEvenSplit(FUND_SPLIT_TYPE.LUMP_SUM));
    }

    // Sets even split if only one fund
    if(updatedFunds.length === 1){
        store.dispatch(selectEvenSplit(FUND_SPLIT_TYPE.REGULAR));
        store.dispatch(selectEvenSplit(FUND_SPLIT_TYPE.LUMP_SUM));
    }

}

export const performFundSplitValidation = (store) => {

    //Get state
    const state = store.getState();
    const regularForm = getFundSplitFormRegular(state);
    const lumpSumForm = getFundSplitFormLumpSum(state);

    // Trigger validation for all regular investment fields
    regularForm.forEach((fund) => 
        store.dispatch(setSplitPercentageValue(fund.value, fund.id, fund.contribution, FUND_SPLIT_FORM.REGULAR)));

    // Trigger validation for all lumpsum investment fields
    lumpSumForm.forEach((fund) => 
        store.dispatch(setSplitPercentageValue(fund.value, fund.id, fund.contribution, FUND_SPLIT_FORM.LUMP_SUM)));
    
    // Find first error index for regular
    const regularFundsErrorIndex = regularForm.findIndex(x => x.status === FIELD_STATUS.ERROR);
    if(regularFundsErrorIndex !== -1) {
        store.dispatch(scrollToId(TFSA_FUND_SPLIT_REGULAR_CARD_ID_PREFIX+regularFundsErrorIndex));
        return;
    }

    // Find first error index for lump sum
    const lumpSumFundsErrorIndex = lumpSumForm.findIndex(x => x.status === FIELD_STATUS.ERROR);
    if(lumpSumFundsErrorIndex !== -1) {
        store.dispatch(scrollToId(TFSA_FUND_SPLIT_LUMP_SUM_CARD_ID_PREFIX+lumpSumFundsErrorIndex));
        return;
    } 
   
};
