import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    FIRST_NAME_SET_VALUE,
    FIRST_NAME_SET_VALID,
    LAST_NAME_SET_VALUE,
    LAST_NAME_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    STREET_ADDRESS_SET_VALUE,
    STREET_ADDRESS_SET_VALID,
    SUBURB_SET_VALUE,
    SUBURB_SET_VALID,
    CITY_SET_VALUE,
    CITY_SET_VALID,
    POSTAL_ADDRESS_SET_VALUE,
    POSTAL_ADDRESS_SET_VALID,
    REINITIALIZE,
    REINITIALIZE_SINGLE,
    FORM_VALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM,
    FORM_INVALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM,
    API_PENDING_TRANSITION_CHILD_DETAILS_FORM,
    API_SUCCESS_TRANSITION_CHILD_DETAILS_FORM,
    API_FAILURE_TRANSITION_CHILD_DETAILS_FORM,
    ADD_CHILD,
    REMOVE_CHILD,
    SELECTED_CHILD_FORM_INDEX_SET_VALUE,
} from '../../actions/personalAccident/childDetails';

export const initChildDetails = {
    formStatus: [],
    apiCallStatus: null,
    form: [],
    selectedChildFormIndex: null,
};

export default (state = initChildDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update, index) => ({
        ...state,
        form: state.form.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    [fieldId]: update(item[fieldId]),
                };
            } else {
                return item;
            }
        }),
    });

    switch (action.type) {
        case REINITIALIZE:
            return initChildDetails;

        case SELECTED_CHILD_FORM_INDEX_SET_VALUE:
            return { ...state, selectedChildFormIndex: action.value };

        case REINITIALIZE_SINGLE:
            const indexToEdit = action.index;

            if (indexToEdit >= 0 && indexToEdit < state.form.length) {
                const updatedForm = [...state.form];
                updatedForm[indexToEdit] = {
                    firstName: { value: null, error: null, status: null },
                    lastName: { value: null, error: null, status: null },
                    dob: { value: null, error: null, status: null },
                    streetAddress: { value: null, error: null, status: null },
                    suburb: { value: null, error: null, status: null },
                    city: { value: null, error: null, status: null },
                    postalAddress: { value: null, error: null, status: null },
                };

                const updatedFormStatus = [...formStatus];

                updatedFormStatus[indexToEdit] = FORM_STATUS.INVALID;

                return {
                    ...state,
                    formStatus: updatedFormStatus,
                    form: updatedForm,
                };
            }

            return state;

        case ADD_CHILD:
            return {
                ...state,
                formStatus: [...state.formStatus, FORM_STATUS.INVALID],
                form: [
                    ...state.form,
                    {
                        firstName: { value: null, error: null, status: null },
                        lastName: { value: null, error: null, status: null },
                        dob: { value: null, error: null, status: null },
                        streetAddress: { value: null, error: null, status: null },
                        suburb: { value: null, error: null, status: null },
                        city: { value: null, error: null, status: null },
                        postalAddress: { value: null, error: null, status: null },
                    },
                ],
            };

        case REMOVE_CHILD:
            const indexToRemove = action.index;

            if (indexToRemove >= 0 && indexToRemove < state.form.length) {
                const updatedForm = state.form.filter((_, idx) => idx !== indexToRemove);

                const updatedFormStatus = state.formStatus.filter((_, idx) => idx !== indexToRemove);

                return {
                    ...state,
                    formStatus: updatedFormStatus,
                    form: updatedForm,
                };
            }

            return state;

        case FORM_VALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM:
            const validIndex = action.index;

            if (validIndex >= 0 && validIndex < state.formStatus.length) {
                const updatedFormStatus = [...state.formStatus];
                updatedFormStatus[validIndex] = FORM_STATUS.VALID;

                return {
                    ...state,
                    formStatus: updatedFormStatus,
                };
            }

            return state;

        case FORM_INVALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM:
            const index = action.index;

            if (index >= 0 && index < state.formStatus.length) {
                const updatedFormStatus = [...state.formStatus];
                updatedFormStatus[index] = FORM_STATUS.INVALID;

                return {
                    ...state,
                    formStatus: updatedFormStatus,
                };
            }

            return state;

        case API_PENDING_TRANSITION_CHILD_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_CHILD_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_CHILD_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        case FIRST_NAME_SET_VALUE:
            return updateFormField(
                'firstName',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index,
            );

        case FIRST_NAME_SET_VALID:
            return updateFormField(
                'firstName',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index,
            );

        case LAST_NAME_SET_VALUE:
            return updateFormField(
                'lastName',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index,
            );

        case LAST_NAME_SET_VALID:
            return updateFormField(
                'lastName',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index,
            );

        case DOB_SET_VALUE:
            return updateFormField(
                'dob',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index,
            );

        case DOB_SET_VALID:
            return updateFormField(
                'dob',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index,
            );

        case STREET_ADDRESS_SET_VALUE:
            return updateFormField(
                'streetAddress',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index,
            );

        case STREET_ADDRESS_SET_VALID:
            return updateFormField(
                'streetAddress',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index,
            );

        case CITY_SET_VALUE:
            return updateFormField(
                'city',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index,
            );

        case CITY_SET_VALID:
            return updateFormField(
                'city',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index,
            );

        case SUBURB_SET_VALUE:
            return updateFormField(
                'suburb',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index,
            );

        case SUBURB_SET_VALID:
            return updateFormField(
                'suburb',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index,
            );

        case POSTAL_ADDRESS_SET_VALUE:
            return updateFormField(
                'postalAddress',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index,
            );

        case POSTAL_ADDRESS_SET_VALID:
            return updateFormField(
                'postalAddress',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index,
            );

        default:
            return state;
    }
};
