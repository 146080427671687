import {
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateAlphaString,
    validateNumber,
    validateNumericString,
    validateSelection,
} from '@om/validation';
import {
    BENEFIT_AMOUNT_SET_VALUE,
    NO_EMPLOYEES_SET_VALUE,
    setBenefitAmountValid,
    setEmployeeNoValid,
} from '../../../actions/biasharaFlexi/coverDetails';
import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const coverDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case NO_EMPLOYEES_SET_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Employees number',
                    required: true,
                    min: 1,
                });
                return setEmployeeNoValid(error);

            case BENEFIT_AMOUNT_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value.value, {
                    subject: 'Benefit amount',
                    required: true,
                });
                return setBenefitAmountValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
