import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";
import { simpleFieldTracking } from "../formTracking";
import {
  TITLE_SET_VALUE,
  FIRST_NAMES_SET_VALUE,
  SURNAME_SET_VALUE,
  EMAIL_SET_VALUE,
  CONTACT_NUMBER_SET_VALUE,
  CONTACT_FOR_FEEDBACK_SET_VALUE,
  CONTACT_METHOD_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/personalDetails";
import {
  getTitle,
  getFirstNames,
  getSurname,
  getContactNumber,
  getEmail,
  getContactMethod,
  getContactForFeedback,
} from "../../../selectors/taxFreeSavingsAccount/personalDetails";

export const personalDetailsTracking = (store, next, action) => {
  const prevState = store.getState();
  const result = next(action);
  const currentState = store.getState();
  const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
  let sendValue = true;
  let sendError = true;
  const fieldSelector = (() => {
    switch (action.type) {
      case TITLE_SET_VALUE:
        sendError = false;
        return getTitle;
      case FIRST_NAMES_SET_VALUE:
        sendValue = false;
        return getFirstNames;
      case SURNAME_SET_VALUE:
        sendValue = false;
        return getSurname;
      case EMAIL_SET_VALUE:
        sendValue = false;
        return getEmail;
      case CONTACT_NUMBER_SET_VALUE:
        sendValue = false;
        return getContactNumber;
      case CONTACT_FOR_FEEDBACK_SET_VALUE:
        sendValue = false;
        return getContactForFeedback;
      case CONTACT_METHOD_SET_VALUE:
        sendValue = false;
        return getContactMethod;
      default:
        return null;
    }
  })();

  const field = onFieldChange(fieldSelector);
  if (field) simpleFieldTracking({ action, field, sendValue, sendError });

  return result;
};
