import emojiaware from 'emoji-aware';
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

export function validateEmail(
    value,
    {
        min = config.EMAIL.MIN,
        max = config.EMAIL.MAX,
        required = true,
        subject = 'Email address',
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    // Ampersand has been removed
    const regEx = new RegExp(/^(([^ /*'`!#%|$^<>=()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (required && !value ) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    if (min && (value.length < min)) {
        return OmValidationMessageHelper.getStringTooShortMsg(subject, min, pronoun, retainSubjectCase);
    }

    if (max && (value.length > max)) {
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    const invalidMessage = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    if (!regEx.test(value)) {
        return invalidMessage;
    }

    const [, local, , , , domain] = value.match(regEx);
    const domainWithoutExtension = domain.split('.');
    if (domainWithoutExtension.length > 0) {
        if (local.length > 64 || domainWithoutExtension[0].length > 63) {
            return invalidMessage;
        }
    }

    const emoji = emojiaware.onlyEmoji(value);
    if (emoji.length >= 1 ) {
        return invalidMessage;
    }

    return null;
}


export function validateEmailAddress(
    value,
    {
        min = config.EMAIL.MIN,
        max = config.EMAIL.MAX,
        required = true,
        subject = 'Email address',
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    // Ampersand has been removed
    const regEx = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

    if (required && !value ) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    if (min && (value.length < min)) {
        return OmValidationMessageHelper.getStringTooShortMsg(subject, min, pronoun, retainSubjectCase);
    }

    if (max && (value.length > max)) {
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    const invalidMessage = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    if (!regEx.test(value)) {
        return invalidMessage;
    }

    const emoji = emojiaware.onlyEmoji(value);
    if (emoji.length >= 1 ) {
        return invalidMessage;
    }

    return null;
}