import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    TITLE_SET_VALUE,
    TITLE_SET_VALID,
    FIRST_NAME_SET_VALUE,
    FIRST_NAME_SET_VALID,
    OTHER_NAMES_SET_VALUE,
    OTHER_NAMES_SET_VALID,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    FORM_VALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM,
    FORM_INVALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM,
    API_PENDING_TRANSITION_ABOUT_YOU_FORM,
    API_SUCCESS_TRANSITION_ABOUT_YOU_FORM,
    API_FAILURE_TRANSITION_ABOUT_YOU_FORM,
    COLLECT_ABOUT_YOU_FORM_DATA,
    SUBMIT_ABOUT_YOU_SUCCESS,
    SUBMIT_ABOUT_YOU_FAILURE,
} from '../../actions/roaTravelInsurance/aboutYou';

export const initialState = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    aboutYouRefernceData: null,
    apiCallStatus: null,
    form: {
        DOB: { value: null, error: null, status: null },
        title: { value: null, error: null, status: null },
        firstName: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        phoneNumber: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
    },
};

export default (state = initialState, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case DOB_SET_VALUE:
            return updateFormField('DOB', field => ({
                ...field,
                value: action.value,
            }));

        case DOB_SET_VALID:
            return updateFormField('DOB', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TITLE_SET_VALUE:
            return updateFormField('title', field => ({
                ...field,
                value: action.value,
            }));

        case TITLE_SET_VALID:
            return updateFormField('title', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', field => ({
                ...field,
                value: action.value,
            }));

        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case OTHER_NAMES_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: action.value,
            }));

        case OTHER_NAMES_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', field => ({
                ...field,
                value: action.value,
            }));

        case PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMAIL_SET_VALUE:
            return updateFormField('email', field => ({
                ...field,
                value: action.value,
            }));

        case EMAIL_SET_VALID:
            return updateFormField('email', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        //    API Calls
        case API_PENDING_TRANSITION_ABOUT_YOU_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_ABOUT_YOU_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_ABOUT_YOU_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_ABOUT_YOU_FORM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case SUBMIT_ABOUT_YOU_SUCCESS:
            return { ...state, aboutYouRefernceData: action.value };
        case SUBMIT_ABOUT_YOU_FAILURE:
            return { ...state, aboutYouRefernceData: action.error };

        // Reset the state
        case REINITIALIZE:
            return initialState;
        default:
            return state;
    }
};
