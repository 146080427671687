import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    CALL_ME_BACK_ACTIVE_ENTRY_VALUE,
    CALL_ME_BACK_ADDITIONAL_NUMBER_VALID,
    CALL_ME_BACK_ADDITIONAL_NUMBER_VALUE,
    CALL_ME_BACK_CITY_VALUE,
    CALL_ME_BACK_CITY_VALID,
    CALL_ME_BACK_LOCATION_COMMENTS_VALUE,
    CALL_ME_BACK_LOCATION_COMMENTS_VALID,
    CALL_ME_BACK_ADVISER_DATA_VALUE,
    CALL_ME_BACK_EMAIL_VALID,
    CALL_ME_BACK_EMAIL_VALUE,
    CALL_ME_BACK_NAME_VALID,
    CALL_ME_BACK_NAME_VALUE,
    CALL_ME_BACK_PHONE_NUMBER_VALID,
    CALL_ME_BACK_PHONE_NUMBER_VALUE,
    CALL_ME_BACK_POSTAL_CODE_VALID,
    CALL_ME_BACK_POSTAL_CODE_VALUE,
    CALL_ME_BACK_PRIVACY_POLICY_URL_VALUE,
    CALL_ME_BACK_PRODUCT_NAME_VALUE,
    CALL_ME_BACK_RESET_STORE,
    CALL_ME_BACK_SIDEBAR_OPEN_VALUE,
    CALL_ME_BACK_SURNAME_VALID,
    CALL_ME_BACK_SURNAME_VALUE,
    CALL_ME_BACK_TO_EMAIL_VALUE,
    FORM_VALID_TRANSITION_CALL_ME_BACK,
    FORM_INVALID_TRANSITION_CALL_ME_BACK,
    FORM_SUCCESS_TRANSITION_CALL_ME_BACK,
    FORM_SUBMITTING_TRANSITION_CALL_ME_BACK,
    FORM_ERROR_TRANSITION_CALL_ME_BACK,
    CALL_ME_BACK_EMAIL_CAMPAIGN_CODE_VALUE,
    CALL_ME_BACK_LEAD_SYSTEM_VALUE,
    CALL_ME_BACK_ENVIRONMENT_NAME_VALUE,
    CALL_ME_BACK_LOCATION_VALUE,
    CALL_ME_BACK_LOCATION_VALID,
    CALL_ME_BACK_STATE_VALUE,
    CALL_ME_BACK_STATE_VALID,
    CALL_ME_BACK_NG_HOUSE_TYPE_VALUE,
    CALL_ME_BACK_NG_HOUSE_TYPE_VALID,
    CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALUE,
    CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALID,
    CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALUE,
    CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALID,
    CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALUE,
    CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALID,
    CALL_ME_BACK_TYPE_OF_ORGANISATION_VALUE,
    CALL_ME_BACK_TYPE_OF_ORGANISATION_VALID,
    CALL_ME_BACK_PREPOPULATED_FORM_VALUE
} from "../../actions/publicWeb/callMeBack";

const DEFAULT_PRIVACY_POLICY_URL = '/privacy-policy';

const initCallMeBack = {
    firstName: { value: null, error: null, status: null },
    surname: { value: null, error: null, status: null },
    phoneNumber: { value: null, error: null, status: null },
    productName: { value: null, error: null, status: null },
    email: { value: null, error: null, status: null },
    leadSystem: 'za',
    emailCampaignCode: null,
    privacyPolicyUrl: DEFAULT_PRIVACY_POLICY_URL,
    postalCode: { value: null, error: null, status: null },
    locationComments: { value: null, error: null, status: null },
    additionalNumber: { value: null, error: null, status: null },
    city: { value: null, error: null, status: null },
    location: { value: null, error: null, status:null },
    ngLocation: { value: 'Abia', error: null, status: 'valid' },
    typeOfHouseValue: { value: null, error: null, status: 'valid' },
    insureProductType: { value: null, error: null, status: 'valid' },
    insureProductItem: { value: null, error: null, status: null },
    propertyLocation: { value: null, error: null, status: null },
    typeOfOrganisation: { value: null, error: null, status: 'valid' },
    state: { value: '20', error: null, status: 'valid' },
    toEmail: { value: null, error: null, status: null },
    activeEntry: {},
    adviserData: {},
    sidebarOpen: false,
    formStatus: FORM_STATUS.INVALID,
    cmbSubmitStatus: API_CALL_STATUS.IDLE,
    environmentName: null,
    isPrepopulatedForm: false,
};

export default (state = initCallMeBack, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    switch (action.type) {
        case CALL_ME_BACK_NAME_VALUE:
            return updateFormField('firstName', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_NAME_VALID:
            return updateFormField('firstName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_SURNAME_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_SURNAME_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_PHONE_NUMBER_VALUE:
            return updateFormField('phoneNumber', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_PHONE_NUMBER_VALID:
            return updateFormField('phoneNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_PRODUCT_NAME_VALUE:
            return updateFormField('productName', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_EMAIL_VALUE:
            return updateFormField('email', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_EMAIL_VALID:
            return updateFormField('email', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_PRIVACY_POLICY_URL_VALUE:
            let newPrivacyPolicyUrl = DEFAULT_PRIVACY_POLICY_URL
            if (action.value) {
                newPrivacyPolicyUrl = action.value;
            }
            return {
                ...state,
                privacyPolicyUrl: newPrivacyPolicyUrl,
            };

        case CALL_ME_BACK_POSTAL_CODE_VALUE:
            return updateFormField('postalCode', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_POSTAL_CODE_VALID:
            return updateFormField('postalCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_ADDITIONAL_NUMBER_VALUE:
            return updateFormField('additionalNumber', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_ADDITIONAL_NUMBER_VALID:
            return updateFormField('additionalNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_CITY_VALUE:
            return updateFormField('city', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_CITY_VALID:
            return updateFormField('city', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_LOCATION_COMMENTS_VALUE:
            return updateFormField('locationComments', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_LOCATION_COMMENTS_VALID:
            return updateFormField('locationComments', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_LOCATION_VALUE:
            return updateFormField('location', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_LOCATION_VALID:
            return updateFormField('location', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_STATE_VALUE:
            return updateFormField('state', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_STATE_VALID:
            return updateFormField('state', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_NG_HOUSE_TYPE_VALUE:
            return updateFormField('typeOfHouseValue', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_NG_HOUSE_TYPE_VALID:
            return updateFormField('typeOfHouseValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALUE:
            return updateFormField('insureProductType', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALID:
            return updateFormField('insureProductType', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALUE:
            return updateFormField('insureProductItem', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALID:
            return updateFormField('insureProductItem', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALUE:
            return updateFormField('propertyLocation', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALID:
            return updateFormField('propertyLocation', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_TYPE_OF_ORGANISATION_VALUE:
            return updateFormField('typeOfOrganisation', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_TYPE_OF_ORGANISATION_VALID:
            return updateFormField('typeOfOrganisation', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CALL_ME_BACK_TO_EMAIL_VALUE:
            return updateFormField('toEmail', field => ({
                ...field,
                value: action.value,
            }));
        case CALL_ME_BACK_EMAIL_CAMPAIGN_CODE_VALUE:
            return {
                ...state,
                emailCampaignCode: action.value
            };
        case CALL_ME_BACK_ACTIVE_ENTRY_VALUE:
            return {
                ...state,
                activeEntry: action.value
            };
        case CALL_ME_BACK_ADVISER_DATA_VALUE:
            return {
                ...state,
                adviserData: action.value
            };
        case CALL_ME_BACK_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value
            };
        case FORM_VALID_TRANSITION_CALL_ME_BACK:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            };

        case FORM_INVALID_TRANSITION_CALL_ME_BACK:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID
            };
        case FORM_SUCCESS_TRANSITION_CALL_ME_BACK:
            return {
                ...state,
                cmbSubmitStatus: API_CALL_STATUS.SUCCESS
            };
        case FORM_SUBMITTING_TRANSITION_CALL_ME_BACK:
            return {
                ...state,
                cmbSubmitStatus: API_CALL_STATUS.PENDING
            };
        case FORM_ERROR_TRANSITION_CALL_ME_BACK:
            return {
                ...state,
                cmbSubmitStatus: API_CALL_STATUS.FAILURE
            };
        case CALL_ME_BACK_LEAD_SYSTEM_VALUE:
            return {
                ...state,
                leadSystem: action.value,
            };
        case CALL_ME_BACK_ENVIRONMENT_NAME_VALUE:
            return {
                ...state,
                environmentName: action.value,
            };
        case CALL_ME_BACK_PREPOPULATED_FORM_VALUE:
            return {
                ...state,
                isPrepopulatedForm: action.value
            };
        case CALL_ME_BACK_RESET_STORE:
            return initCallMeBack;
        default:
            return state;
    }
}
