import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    FORM_VALID_SHORT_TERM_GOAL_TRANSITION,
    FORM_INVALID_SHORT_TERM_GOAL_TRANSITION,
    ONCE_OFF_SET_VALUE,
    ONCE_OFF_SET_VALID,
    MONTHLY_AMOUNT_SET_VALUE,
    MONTHLY_AMOUNT_SET_VALID,
    INVESTMENT_TENURE_SET_VALUE,
    INVESTMENT_TENURE_SET_VALID,
    POTENTIAL_LOSS_PERCENTAGE_SET_VALUE,
    POTENTIAL_LOSS_PERCENTAGE_SET_VALID,
    REINITIALIZE_STATE_SHORT_TERM_GOAL_CALCULATOR,
    RESULTS_SET_VALUE_FAILURE,
    RESULTS_SET_VALUE,
    CALCULATE_SHORT_TERM_GOAL,
    API_PENDING_TRANSITION_SHORT_TERM_GOAL_CALCULATOR,
    API_SUCCESS_TRANSITION_SHORT_TERM_GOAL_CALCULATOR,
    API_FAILURE_TRANSITION_SHORT_TERM_GOAL_CALCULATOR,
} from '../../actions/investmentCalculator/shortTermGoal';

const shortTermGoalCalculatorForm = {
    amountOnceOff: { value: null, error: null, status: null },
    amountMonthly: { value: null, error: null, status: null },
    investmentTenure: { value: '9', error: null, status: 'valid' },
    potentialLossPercentage: { value: '0', error: null, status: 'valid' },
};
export const initialState = {
    formStatus: FORM_STATUS.INVALID,
    showRecommendationCard: false,
    apiCallStatus: API_CALL_STATUS.IDLE,
    form: shortTermGoalCalculatorForm,
    result: {},
};

export default (state = initialState, action) => {
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => {
        return {
            ...state,
            form: {
                ...state.form,
                [fieldId]: update(state.form[fieldId]),
            },
        };
    };

    switch (action.type) {
        // Form Validation
        case FORM_VALID_SHORT_TERM_GOAL_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_SHORT_TERM_GOAL_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Api call
        case API_PENDING_TRANSITION_SHORT_TERM_GOAL_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_SHORT_TERM_GOAL_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_SHORT_TERM_GOAL_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        // amount once off
        case ONCE_OFF_SET_VALUE:
            return updateFormField('amountOnceOff', (field) => ({
                ...field,
                value: action.value,
            }));
        case ONCE_OFF_SET_VALID:
            return updateFormField('amountOnceOff', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // amount monthly
        case MONTHLY_AMOUNT_SET_VALUE:
            return updateFormField('amountMonthly', (field) => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_AMOUNT_SET_VALID:
            return updateFormField('amountMonthly', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // investment tenure
        case INVESTMENT_TENURE_SET_VALUE:
            return updateFormField('investmentTenure', (field) => ({
                ...field,
                value: action.value,
            }));
        case INVESTMENT_TENURE_SET_VALID:
            return updateFormField('investmentTenure', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // potential loss percentage
        case POTENTIAL_LOSS_PERCENTAGE_SET_VALUE:
            return updateFormField('potentialLossPercentage', (field) => ({
                ...field,
                value: action.value,
            }));
        case POTENTIAL_LOSS_PERCENTAGE_SET_VALID:
            return updateFormField('potentialLossPercentage', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Get investment Result
        case RESULTS_SET_VALUE:
            return {
                ...state,
                data: action.value,
            };

        case RESULTS_SET_VALUE_FAILURE:
            return {
                ...state,
                status: API_CALL_STATUS.FAILURE,
                error: action.error,
            };

        // Set calculation results value
        case CALCULATE_SHORT_TERM_GOAL:
            return {
                ...state,
                result: action.value,
            };

        // reinitialise state
        case REINITIALIZE_STATE_SHORT_TERM_GOAL_CALCULATOR:
            return initialState;

        default:
            return state;
    }
};
