import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";
import {simpleFieldTracking} from "../formTracking";
import {
    SPLIT_PERCENTAGE_SET_VALUE,
    EVEN_SPLIT_TOGGLE_VALUE,
} from '../../../actions/taxFreeSavingsAccount/beneficiariesSplit';
import {
    getIsEvenSplit
} from '../../../selectors/taxFreeSavingsAccount/beneficiariesSplit';
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const beneficiariesSplitTracking = (store, next, action) => {
    const result = next(action);
    let sendValue = true;
    let sendError = true;
    const field = (() => {
        switch (action.type) {
            case SPLIT_PERCENTAGE_SET_VALUE:
                return {status: null, error: null, value: action.value};
            default:
                return null;
        }
    })();

    if (field) {
        simpleFieldTracking({action, field, sendValue, sendError});
    }

    return result;
};

export const beneficiariesSplitOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case EVEN_SPLIT_TOGGLE_VALUE:
                otherEventIndex = findOtherEventIndex('TOGGLE_CHECKBOX');
                return getIsEvenSplit(state) ? 'isEvenSplitChecked' : 'isEvenSplitUnchecked';
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};