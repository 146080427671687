import { getDigiLifeLoanProtection } from "./index";
import { createSelector } from "reselect";
import { FIELD_STATUS } from "../../reducers/status";

export const getReferenceData = createSelector(
  getDigiLifeLoanProtection,
  (digiLifeLoanProtection) => digiLifeLoanProtection.referenceData
);
export const getReferenceDataApiStatus = createSelector(
  getReferenceData,
  (digiLifeLoanProtection) => digiLifeLoanProtection.apiStatus
);

export const getApplicationReferenceData = createSelector(
  getReferenceData,
  (referenceData) => referenceData.applicationData
);
export const getQuoteReferenceData = createSelector(
  getReferenceData,
  (referenceData) => referenceData.quoteData
);

// Field selectors
const makeGetField = (fieldId) =>
  createSelector(getApplicationReferenceData, (data) => data[fieldId]);

export const getReferenceBanks = () => [
  {
    code: "29",
    description: "ABSA Bank of Botswana",
  },
  {
    code: "55",
    description: "Access Bank of Botswana",
  },
  {
    code: "11",
    description: "Bank of Baroda (Botswana)",
  },
  {
    code: "91",
    description: "Bank of Botswana",
  },
  {
    code: "45",
    description: "Bank of India ",
  },
  {
    code: "28",
    description: "First National Bank of Botswana ",
  },
  {
    code: "06",
    description: "Stanbic Bank Botswana  ",
  },
  {
    code: "80",
    description: "First Capital Bank  ",
  },
];
export const getReferenceMaritalStatuses = makeGetField("maritalStatuses");
export const getReferenceDebitOrderDates = makeGetField("debitOrderDates");
// export const getReferenceTitles = makeGetField("titles");
export const getReferenceBankData = makeGetField("bankData");
export const getReferenceBranchCodesData = makeGetField("branchCodesData");
export const getReferenceRelationshipTypes = makeGetField("relationshipTypes");

// Calculated "Selectors"
export const getReferencePostalMethods = () => {
  return [
    {
      description: "Street Address",
      code: "StreetAddress",
    },
    {
      description: "PO Box",
      code: "POBox",
    },
    {
      description: "Private Bag",
      code: "PrivateBag",
    },
  ];
};
export const getReferenceAccountTypes = ()=>(
  [
    {
      description: "Savings",
      code: "01",
    },
    {
      description: "Current",
      code: "02",
    },
  ]
)

export const getReferenceGenders = () => {
  return [
    { code: "01", description: "Male" },
    { code: "02", description: "Female" },
  ];
};
export const getReferenceTitles = () => {
  return  [
    {
      code: "01",
      description: "Mr",
      displayOrder: 1,
    },
    {
      code: "02",
      description: "Mrs",
      displayOrder: 2,
    },
    {
      code: "03",
      description: "Miss",
      displayOrder: 3,
    },
  ]
}
export const getReferencePaymentFrequency = () => {
  return  [
    { name: "monthly", description: "Monthly", code: "12" },
    { name: "one_time", description: "Once-off", code: "99" },
  ];
};
export const getReferenceSalaryRange =()=>[
  { name: "lessThan 20000", description: "Less than 20000", code: "01" },
  { name: "20000-35000", description: "20000-35000", code: "02" },
  { name: "35000-50000", description: "35000-50000", code: "03" },
  { name: ">50000", description: "Greater than 50000", code: "04" },
];
export const getReferenceEmploymentType = () => {
  return [
    { code: "01", description: "Employed" },
    { code: "02", description: "Self Employed" },
  ];
};
export const getReferenceLoanType = () => {
  return [
    { name: "vehicleLoan", description: "Motor Vehicle Loan", code: "01" },
    { name: "mortgageLoan", description: "Mortgage Loan", code: "02" },
    { name: "personalLoan", description: "Personal Loan", code: "03" },
  ];
};
export const getReferenceSourceOfIncome = () => {
  return [
    { code: "01", description: "Salary" },
    { code: "02", description: "Business or Self-Employment" },
    { code: "03", description: "Investments" },
    { code: "04", description: "Gifts or Inheritance" },
    { code: "05", description: "Loan" },
    { code: "06", description: "Other" },
  ];
};

export const getReferenceEducationEducation = () => [
  { name: "<IGCSE_BGCSE", description: "less than IGCSE/BGCSE", code: "01" },
  { name: "IGCSE_BGCSE", description: "IGCSE/BGCSE", code: "02" },
  { name: "diploma_Degree", description: "Diploma/Degree", code: "03" },
  { name: "postgraduate", description: "Postgraduate", code: "04" },
  { name: "professional", description: "Professional", code: "05" },
];
export const getReferenceDistricts=()=>[
  {
    code:"01",
    description:"Central"
  },
  {
    code:"02",
    description:"Chobe"
  },
  {
    code:"03",
    description:"Ghanzi"
  },
  {
    code:"04",
    description:"Kgalagadi"
  },
  {
    code:"05",
    description:"Kgatleng"
  },
  {
    code:"06",
    description:"Kweneng"
  },
  {
    code:"07",
    description:"North-East"
  },
  {
    code:"08",
    description:"North-West/Ngamiland"
  },
  {
    code:"09",
    description:"South-East"
  },
  {
    code:"10",
    description:"Southern"
  },
]
export const getReferenceDebitDay=()=>(
  [
      {code: 1, description: '1'},
      {code: 2, description: '2'},
      {code: 3, description: '3'},
      {code: 4, description: '4'},
      {code: 5, description: '5'},
      {code: 6, description: '6'},
      {code: 7, description: '7'},
      {code: 8, description: '8'},
      {code: 9, description: '9'},
      {code: 10, description: '10'},
      {code: 11, description: '11'},
      {code: 12, description: '12'},
      {code: 13, description: '13'},
      {code: 14, description: '14'},
      {code: 15, description: '15'},
      {code: 16, description: '16'},
      {code: 17, description: '17'},
      {code: 18, description: '18'},
      {code: 19, description: '19'},
      {code: 20, description: '20'},
      {code: 21, description: '21'},
      {code: 22, description: '22'},
      {code: 23, description: '23'},
      {code: 24, description: '24'},
      {code: 25, description: '25'},
      {code: 26, description: '26'},
      {code: 27, description: '27'},
      {code: 28, description: '28'},
      {code: 29, description: '29'},
      {code: 30, description: '30'},
      {code: 31, description: '31'},
  ]
)
export const getReferenceNationalities = () => {
  return [{ description: "Botswana", code: "Botswana" }];
};

// Quote
export const getQuoteReferenceCoverMembers = createSelector(
  getQuoteReferenceData,
  (quoteReferenceData) => {
    if (!quoteReferenceData || !quoteReferenceData.plans) {
      return [];
    }

    return quoteReferenceData.plans.flatMap((plan) => plan.coveredMembers);
  }
);

export const getQuoteReferenceImmediateFamilyCoverMembers = createSelector(
  getQuoteReferenceData,
  (quoteReferenceData) => {
    if (!quoteReferenceData || !quoteReferenceData.plans) {
      return [];
    }

    return quoteReferenceData.plans[0].coveredMembers;
  }
);

// Validates the application reference data
export const getIsApplicationReferenceDataValid = createSelector(
  getApplicationReferenceData,
  (applicationReferenceData) => {
    return (
      applicationReferenceData &&
      Object.keys(applicationReferenceData).length !== 0 &&
      applicationReferenceData.isValid &&
      applicationReferenceData.relationshipTypes !== 0 &&
      applicationReferenceData.banks !== 0 &&
      applicationReferenceData.bankAccountTypes !== 0 &&
      applicationReferenceData.debitOrderDates !== 0 &&
      applicationReferenceData.titles !== 0
    );
  }
);

// Validates the quote reference data
export const getIsQuoteReferenceDataValid = createSelector(
  getQuoteReferenceData,
  (quoteReferenceData) => {
    return (
      quoteReferenceData &&
      Object.keys(quoteReferenceData).length !== 0 &&
      quoteReferenceData.isValid &&
      quoteReferenceData.plans.length !== 0
    );
  }
);

// If there is quote and application reference data the set to true
// This is used to send user to Call me back if we dont have reference data
export const getIsReferenceDataValid = createSelector(
  getIsApplicationReferenceDataValid,
  getIsQuoteReferenceDataValid,
  (isApplicationReferenceDataValid, isQuoteReferenceDataValid) => {
    return isApplicationReferenceDataValid && isQuoteReferenceDataValid;
  }
);
