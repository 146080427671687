import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from "../status";

import {
    FORM_VALID_TRANSITION_DROPOFF_DETAILS,
    FORM_INVALID_TRANSITION_DROPOFF_DETAILS,
    TOGGLE_INCLUDE_CAPTURE_DETAILS_CAPTURE_DETAILS,
    NAME_SET_VALUE,
    NAME_SET_VALID,
    SURNAME_SET_VALUE,
    SURNAME_SET_VALID,
    MOBILE_SET_VALUE,
    MOBILE_SET_VALID,
    DROP_OFF_LEAD_TRANSITION_CAPTURE_DETAILS,
    DISPLAY_NAME_SET_VALUE,
    RESET_CAPTURE_DETAILS
} from "../../actions/raOptimal/captureDetails";


/* We use displayValue in the name and surname fields to account for trimming the string, 
the value will be the trimmed version while displayValue will be the user input, 
Implemented in the reducer bellow. Value also gets trimmed prior to validation */
export const initCaptureDetails = {
    includeDropoffDetails: TOGGLE_STATUS.YES,
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    displayName: null,
    form: {
        name: { displayValue: null, value: null, error: null, status: null },
        surname: { displayValue: null, value: null, error: null, status: null },
        mobile: { value: null, error: null, status: null }
    },
};

export default (state = initCaptureDetails, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_DROPOFF_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_DROPOFF_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Include Form validation
        case TOGGLE_INCLUDE_CAPTURE_DETAILS_CAPTURE_DETAILS:
            return { ...state, includeDropoffDetails: TOGGLE_STATUS.NO };
            
        // Name
        case NAME_SET_VALUE:
            return updateFormField('name', field => ({
                ...field,
                displayValue: action.value,
                value: action.value ? action.value.trim() : action.value,
            }));
        case NAME_SET_VALID:
            return updateFormField('name', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Surame
        case SURNAME_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                displayValue: action.value,
                value: action.value ? action.value.trim() : action.value,
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Mobile Number
        case MOBILE_SET_VALUE:
            return updateFormField('mobile', field => ({
                ...field,
                value: action.value,
            }));
        case MOBILE_SET_VALID:
            return updateFormField('mobile', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DISPLAY_NAME_SET_VALUE: 
            return { ...state, displayName: action.value };

        case DROP_OFF_LEAD_TRANSITION_CAPTURE_DETAILS:
            return {...state, dropOffDetails: action.dropOffDetails};

        case RESET_CAPTURE_DETAILS: 
            return { ...initCaptureDetails };

        default:
            return state;
    }
};
