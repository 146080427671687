export const onFieldChangeStateComparison = (prevState, currentState) => selector => {
    if (!selector) {
        return;
    }

    const prevField = selector(prevState);
    const currentField = selector(currentState);

    return (prevField.value !== currentField.value) && currentField;
};

export const onItemChangeStateComparison = (prevState, currentState) => selector => {
    if (!selector) {
        return;
    }

    const prevField = selector(prevState);
    const currentField = selector(currentState);

    return (prevField !== currentField) && currentField;
};
