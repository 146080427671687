import {
    FORM_VALID_TRANSITION_CALCULATOR,
    FORM_INVALID_TRANSITION_CALCULATOR,
    RESET_CALCULATOR_FORM,
    AMOUNT_BORROWED_SET_VALUE,
    AMOUNT_BORROWED_SET_VALID,
    OTHER_AMOUNT_BORROWED_SET_VALUE,
    OTHER_AMOUNT_BORROWED_SET_VALID,
    OTHER_AMOUNT_BORROWED_CLEAR_VALUE,
    REPAYMENT_DURATION_SET_VALUE,
    REPAYMENT_DURATION_SET_VALID,
    REPAYMENT_DURATION_CLEAR_VALUE,
    OTHER_REPAYMENT_DURATION_SET_VALUE,
    OTHER_REPAYMENT_DURATION_SET_VALID,
    OTHER_REPAYMENT_DURATION_CLEAR_VALUE,
    CONTINUE_TRANSITION_LOAN_AMOUNT_DETAILS,
    BACK_TRANSITION_REPAYMENT_DURATION_DETAILS,
    CALCULATE_TRANSITION_LOAN_RESULTS,
    CALCULATE_LOAN_REPAYMENT,
    CALCULATE_LOAN_REPAYMENT_SUCCESS,
    CALCULATE_LOAN_REPAYMENT_FAILURE,

} from "../../actions/personalLoansCalculator/calculator";

import { FORM_STATUS, TOGGLE_STATUS, FIELD_STATUS, API_CALL_STATUS } from '../status';

const calculatorForm = {
    amountBorrowed: { value: null, error: null, status: null },
    otherAmountBorrowed: { value: null, error: null, status: null },
    repaymentDuration: { value: null, error: null, status: null },
    otherRepaymentDuration: { value: null, error: null, status: null }
}

const initPersonalLoansCalculator = {
    formStatus: FORM_STATUS.INVALID,
    showLoanRepaymentDurationForm: TOGGLE_STATUS.NO,
    showResultsSummaryCard: TOGGLE_STATUS.NO,
    calculationResults: {
        status: API_CALL_STATUS.IDLE,
        error: null,
        results: null
    },
    form: calculatorForm
}

export default (state = initPersonalLoansCalculator, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRANSITION_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        case FORM_INVALID_TRANSITION_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID
            }
        case CONTINUE_TRANSITION_LOAN_AMOUNT_DETAILS:
            return {
                ...state,
                showLoanRepaymentDurationForm: TOGGLE_STATUS.YES
            }
        case BACK_TRANSITION_REPAYMENT_DURATION_DETAILS:
            return {
                ...state,
                showLoanRepaymentDurationForm: TOGGLE_STATUS.NO,
                showResultsSummaryCard: TOGGLE_STATUS.NO
            }
        case CALCULATE_TRANSITION_LOAN_RESULTS:
            return {
                ...state,
                showResultsSummaryCard: TOGGLE_STATUS.YES
            }
        case CALCULATE_LOAN_REPAYMENT:
            return {
                ...state,
                calculationResults: {
                    ...state.calculationResults,
                    status: API_CALL_STATUS.PENDING
                }
            }
        case CALCULATE_LOAN_REPAYMENT_SUCCESS:
            return {
                ...state,
                calculationResults: {
                    ...state.calculationResults,
                    status: API_CALL_STATUS.SUCCESS,
                    results: action.value
                }
            }
        case CALCULATE_LOAN_REPAYMENT_FAILURE:
            return {
                ...state,
                calculationResults: {
                    ...state.calculationResults,
                    status: API_CALL_STATUS.FAILURE,
                    error: action.error
                }
            }
        case RESET_CALCULATOR_FORM:
            return {
                formStatus: FORM_STATUS.INVALID,
                showLoanRepaymentDurationForm: TOGGLE_STATUS.NO,
                showResultsSummaryCard: TOGGLE_STATUS.NO,
                calculationResults: {
                    status: API_CALL_STATUS.IDLE,
                    error: null,
                    results: null
                },
                form: calculatorForm
            }
        case AMOUNT_BORROWED_SET_VALUE:
            return updateFormField('amountBorrowed', (field) => ({
                ...field,
                value: action.value
            }));
        case AMOUNT_BORROWED_SET_VALID:
            return updateFormField('amountBorrowed', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }));
        case OTHER_AMOUNT_BORROWED_SET_VALUE:
            return updateFormField('otherAmountBorrowed', (field) => ({
                ...field,
                value: action.value
            }));
        case OTHER_AMOUNT_BORROWED_SET_VALID:
            return updateFormField('otherAmountBorrowed', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }));
        case OTHER_AMOUNT_BORROWED_CLEAR_VALUE:
            return updateFormField('otherAmountBorrowed', (field) => ({
                ...field,
                value: null,
                error: null,
                status: null
            }));
        case REPAYMENT_DURATION_SET_VALUE:
            return updateFormField('repaymentDuration', (field) => ({
                ...field,
                value: action.value
            }));
        case REPAYMENT_DURATION_SET_VALID:
            return updateFormField('repaymentDuration', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }));
        case REPAYMENT_DURATION_CLEAR_VALUE:
            return updateFormField('repaymentDuration', (field) => ({
                ...field,
                value: null,
                error: null,
                status: null
            }));
        case OTHER_REPAYMENT_DURATION_SET_VALUE:
            return updateFormField('otherRepaymentDuration', (field) => ({
                ...field,
                value: action.value
            }));
        case OTHER_REPAYMENT_DURATION_SET_VALID:
            return updateFormField('otherRepaymentDuration', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }));
        case OTHER_REPAYMENT_DURATION_CLEAR_VALUE:
            return updateFormField('otherRepaymentDuration', (field) => ({
                ...field,
                value: null,
                error: null,
                status: null
            }));
        default:
            return state;
    }
}