// Transitions
export const TOGGLE_CALL_ME_BACK = 'customerVerificationService/callMeBack/transition/toggleCallMeBack';
export const toggleCallMeBack = () => ({ type: TOGGLE_CALL_ME_BACK });

export const FORM_VALID_TRANSITION_CALL_ME_BACK = 'customerVerificationService/callMeBack/transition/formValid';
export const FORM_INVALID_TRANSITION_CALL_ME_BACK = 'customerVerificationService/callMeBack/transition/formInvalid';

export const transitionCallMeBack = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CALL_ME_BACK }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CALL_ME_BACK }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_CALL_ME_BACK = 'customerVerificationService/callMeBack/transition/resetState';
export const resetCallMeBack = () => ({ type: RESET_STATE_TRANSITION_CALL_ME_BACK });

// Events
export const CALL_ME_BACK_SET_VALUE = 'customerVerificationService/callMeBack/setValue/';
export const CALL_ME_BACK_SET_VALID = 'customerVerificationService/callMeBack/setValid/';
export const EMAIL_SEND = 'customerVerificationService/callMeBack/email/';

// Values
export const NAME_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'name';
export const NAME_SET_VALID = CALL_ME_BACK_SET_VALID + 'name';
export const SURNAME_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = CALL_ME_BACK_SET_VALID + 'surname';
export const CONTACT_NUMBER_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'contactNumber';
export const CONTACT_NUMBER_SET_VALID = CALL_ME_BACK_SET_VALID + 'contactNumber';

export const REDIRECT_URL_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'redirectUrl';
export const GTM_CONTEXT_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'gtmContext';
export const PRODUCT_NAME_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'productName';

export const CALL_ME_BACK_SEND = EMAIL_SEND + 'send';
export const CALL_ME_BACK_SEND_SUCCESS = EMAIL_SEND + 'success';
export const CALL_ME_BACK_SEND_FAILURE = EMAIL_SEND + 'failure';

export const setNameValue = value => ({ type: NAME_SET_VALUE, value });
export const setNameValid = error => ({ type: NAME_SET_VALID, error });
export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });
export const setContactNumberValue = value => ({ type: CONTACT_NUMBER_SET_VALUE, value });
export const setContactNumberValid = error => ({ type: CONTACT_NUMBER_SET_VALID, error });

export const setRedirectUrl = value => ({ type: REDIRECT_URL_SET_VALUE, value });
export const setGtmContext = value => ({ type: GTM_CONTEXT_SET_VALUE, value });
export const setProductName = value => ({ type: PRODUCT_NAME_SET_VALUE, value });

export const sendCallMeBack = () => ({ type: CALL_ME_BACK_SEND });
export const sendCallMeBackSuccess = () => ({ type: CALL_ME_BACK_SEND_SUCCESS });
export const sendCallMeBackFailure = () => ({ type: CALL_ME_BACK_SEND_FAILURE });

export const SET_SOURCE_VALUES_FROM_PARENT_APP = CALL_ME_BACK_SET_VALUE + 'sourceValuesFromParentApp';
export const setSourceValuesFromParentApp = callMeBackSource => ({ type: SET_SOURCE_VALUES_FROM_PARENT_APP, callMeBackSource });
