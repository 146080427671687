import {
    ARTICLE_CARD_FILTER_SET_CARD_FILTER,
    ARTICLE_CARD_FILTER_SET_CARDS,
    ARTICLE_CARD_FILTER_SET_CURRENT_PAGE,
    ARTICLE_CARD_FILTER_TOGGLE_SELECTED_PRIMARY_FILTER_ITEM,
    ARTICLE_CARD_FILTER_TOGGLE_ALL_PRIMARY_FILTER_ITEM,
    ARTICLE_CARD_FILTER_TOGGLE_SELECTED_SECONDARY_FILTER_ITEM,
    ARTICLE_CARD_FILTER_TOGGLE_ALL_SECONDARY_FILTER_ITEM,
    ARTICLE_CARD_FILTER_FILTER_PRIMARY_BY_QUERY_PARAM,
    ARTICLE_CARD_FILTER_FILTER_SECONDARY_BY_QUERY_PARAM,
} from '../../actions/publicWeb/articleCardFilter';

const initialState = {
    cards: [],
    currentPage: 1,
    primaryFilters: [],
    selectedPrimaryFilters: [],
    secondaryFilters: [],
    selectedSecondaryFilters: [],
    cardsPerPage: 9,
};

const removeItemFromArray = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
};

const toggleSelectItem = (selectedItems, selectedItem, isChecked) => {
    if (selectedItem) {
        if (isChecked) {
            if (!selectedItems.includes(selectedItem)) {
                selectedItems.push(selectedItem);
            }
        } else {
            if (selectedItems.includes(selectedItem)) {
                removeItemFromArray(selectedItems, selectedItem);
            }
        }
    }
};

const getQueryMap = filters => {
    if (filters.length > 0) {
        let queryMapItemArray = filters.map((filter) => {
            if (filter.query_key && filter.query_key.trim() !== '') {
                return { [filter.query_key]: filter.uid };
            }
        })
        .filter(item => item);
        if (queryMapItemArray && queryMapItemArray.length > 0) {
            return queryMapItemArray.reduce((queryMap, queryMapItem) => {
                return { ...queryMap, ...queryMapItem }
            });
        }
    }

    return {};
};

export default function (state = initialState, action) {
    let selectedPrimaryFilters, selectedSecondaryFilters, queryKeyMap;

    switch (action.type) {
        case ARTICLE_CARD_FILTER_SET_CARDS:
            return {
                ...state,
                cards: action.cards
            };
        case ARTICLE_CARD_FILTER_SET_CARD_FILTER:
            return {
                ...state,
                primaryFilters: action.primaryFilters,
                secondaryFilters: action.secondaryFilters,
                cardsPerPage: action.cardsPerPage,
            };
        case ARTICLE_CARD_FILTER_SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.value,
            };
        case ARTICLE_CARD_FILTER_TOGGLE_SELECTED_PRIMARY_FILTER_ITEM:
            selectedPrimaryFilters = [...state.selectedPrimaryFilters];
            toggleSelectItem(selectedPrimaryFilters, action.selectedPrimaryFilterItem, action.isChecked);

            return {
                ...state,
                currentPage: 1,
                selectedPrimaryFilters,
            };
        case ARTICLE_CARD_FILTER_TOGGLE_ALL_PRIMARY_FILTER_ITEM:
            selectedPrimaryFilters = [...state.selectedPrimaryFilters];
            if (action.isChecked) {
                selectedPrimaryFilters = [];
            }

            return {
                ...state,
                currentPage: 1,
                selectedPrimaryFilters,
            };
        case ARTICLE_CARD_FILTER_TOGGLE_SELECTED_SECONDARY_FILTER_ITEM:
            selectedSecondaryFilters = [...state.selectedSecondaryFilters];
            toggleSelectItem(selectedSecondaryFilters, action.selectedSecondaryFilterItem, action.isChecked);

            return {
                ...state,
                currentPage: 1,
                selectedSecondaryFilters,
            };
        case ARTICLE_CARD_FILTER_TOGGLE_ALL_SECONDARY_FILTER_ITEM:
            selectedSecondaryFilters = [...state.selectedSecondaryFilters];
            if (action.isChecked) {
                selectedSecondaryFilters = [];
            }

            return {
                ...state,
                currentPage: 1,
                selectedSecondaryFilters,
            };
        case ARTICLE_CARD_FILTER_FILTER_PRIMARY_BY_QUERY_PARAM:
            let primaryPrefilterQueryId = action.primaryQueryParam;
            queryKeyMap = getQueryMap(state.primaryFilters);
            selectedPrimaryFilters = [...state.selectedPrimaryFilters];

            if (primaryPrefilterQueryId) {
                if (queryKeyMap[primaryPrefilterQueryId]) {
                    // Prefilter by the Content Stack query_key found in the Article Card Filter Item
                    primaryPrefilterQueryId = queryKeyMap[primaryPrefilterQueryId];
                }

                toggleSelectItem(selectedPrimaryFilters, primaryPrefilterQueryId, true);
            }

            return {
                ...state,
                currentPage: 1,
                selectedPrimaryFilters,
            };
        case ARTICLE_CARD_FILTER_FILTER_SECONDARY_BY_QUERY_PARAM:
            let secondaryPrefilterQueryId = action.secondaryQueryParam;
            queryKeyMap = getQueryMap(state.secondaryFilters);
            selectedSecondaryFilters = [...state.selectedSecondaryFilters];

            if (secondaryPrefilterQueryId) {
                if (queryKeyMap[secondaryPrefilterQueryId]) {
                    // Prefilter by the Content Stack query_key found in the Article Card Filter Item
                    secondaryPrefilterQueryId = queryKeyMap[secondaryPrefilterQueryId];
                }

                toggleSelectItem(selectedSecondaryFilters, secondaryPrefilterQueryId, true);
            }

            return {
                ...state,
                currentPage: 1,
                selectedSecondaryFilters,
            };
            return state;
        default:
            return state;
    }
}

