
export const HOME_URL = '/';
export const CVS_APP_URL_PREFIX = '/customer-verification-service';
export const PAGE_INDICES = {
    CONSENT: 0,
    ID_VERIFICATION: 1,
    SELFIE: 2,
    KBA_INTERLUDE: 3,
    KBA: 4,
};
export const PAGES = [
    {
        URL: `${CVS_APP_URL_PREFIX}/consent/`,
        URL_PART: 'consent',
        TITLE: 'Smart Application',
    },
    {
        URL: `${CVS_APP_URL_PREFIX}/id-verification/`,
        URL_PART: 'id-verification',
        TITLE: 'ID Verification',
    },
    {
        URL: `${CVS_APP_URL_PREFIX}/selfie/`,
        URL_PART: 'selfie',
        TITLE: 'Selfie',
    },
    {
        URL: `${CVS_APP_URL_PREFIX}/kba-interlude/`,
        URL_PART: 'kba-interlude',
        TITLE: 'KBA Interlude',
    },
    {
        URL: `${CVS_APP_URL_PREFIX}/knowledge-based-authentication/`,
        URL_PART: 'knowledge-based-authentication',
        TITLE: 'Verification',
    },
];

export const CVS_PAGE_URLS = {
    CONSENT: PAGES[PAGE_INDICES.CONSENT].URL,
    ID_VERIFICATION: PAGES[PAGE_INDICES.ID_VERIFICATION].URL,
    SELFIE: PAGES[PAGE_INDICES.SELFIE].URL,
    KBA_INTERLUDE: PAGES[PAGE_INDICES.KBA_INTERLUDE].URL,
    KBA: PAGES[PAGE_INDICES.KBA].URL,
};

export const CVS_MODALS = {
    LOADING: 'LOADING',
    VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
    VERIFICATION_FAILED: 'VERIFICATION_FAILED',
    INITIATION_FAILED: 'INITIATION_FAILED'
};

export const PARENT_APPS = [
    "RAOPTIMAL",
];

export const SERVICE_PROVIDERS = {
    CONTACTABLE: 1,
    IIDENTIFII: 2
};

export const SERVICE_PROVIDER_NAMES_FOR_TRACKING = [
    "CONTACTABLE", 
    "IIDENTIFII"
];

export const IFRAME_SOURCES = {
    contactableUrl: "https://registration-oldmutual-oemwebapp-liveness.kyc.business",
    iidentifiiUrl: "https://alphaweb.iidentifii.com",
};

export const IFRAME_SOURCES_SLUGS = {
    contactable: "/Onboarding?key=",
    iidentifii: "?token=",
};

export const IIDENTIFII_SKIP_EVENTS = ['App initialised', 'Submit started', 'Liveness capture ok', 'Submit ok', 'Liveness capture started'];

export const IFRAME_SUCCESS_EVENTS = {
    contactable: true,
    iidentifii: 'SUCCESS'
};

export const CONTACTABLE_CODE = 1;
export const IIDENTIFII_CODE = 2;

export const CONTACTABLE_CAMERA_FAILURE_MESSAGE = 'This application requires access to your camera/microphone. Please allow access to this feature and if permissions are blocked, please unblock and refresh the page';

// Timeout timer delays
export const IIDENTIFII_RETRIEVE_LIVENESS_DELAY = 5000;
export const TRANSITION_BETWEEN_PAGE_DELAY = 1500;
