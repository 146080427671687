import { transitionCriticalIllnessTool } from '../../../actions/roaQuoteTools/criticalIllnessQuoteTool';
import { getcriticalIllnessQuoteToolForm } from '../../../selectors/roaQuoteTools/criticalIllnessQuoteTool';
import { FIELD_STATUS } from '../../../reducers/status';

export const criticalIllnessQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const criticalIllnessQuoteToolForm = getcriticalIllnessQuoteToolForm(
        store.getState()
    );

    const criticalIllnessQuoteToolValid = validateCriticalIllnessQuoteToolFormDetails(
        criticalIllnessQuoteToolForm
    );

    const formAction = criticalIllnessQuoteToolValid
        ? transitionCriticalIllnessTool.formValid()
        : transitionCriticalIllnessTool.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateCriticalIllnessQuoteToolFormDetails = (formFields) => {
    return (
        formFields.DateOfBirth.status &&
        formFields.coveredupValue.status &&
        formFields.yearValue.status === FIELD_STATUS.VALID);
};
