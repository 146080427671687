import { parse as parseQueryString } from 'query-string';

export function getQueryParam(location, key) {
    if (!location || !location.search || !key) {
        return null;
    }

    const queryParams = parseQueryString(location.search);
    return queryParams[key];
}

// DEPRECATED - DO NOT USE - we want to get rid of this
export function createActionHandler(dispatch, action) {
    if (!dispatch || !action) {
        return () => {};
    }

    return (value) => {
        dispatch(action(value));
    };
}

export function isNullOrEmpty(val) {
    return val === null || val === undefined || (typeof val === 'string' && val.trim() === '');
}

export function getTrimmedValue(val) {
    if (isNullOrEmpty(val)) {
        return null;
    }

    return val.trim();
}

export function applyThousandSeperator(val, spaces = true) {
    const regx = /(\d+)(\d{3})/;
    const numberString = (val.toFixed ? val.toFixed(2) : val) + '';

    //seperate with spaces(default) or commas
    const thousandSep = spaces ? ' ' : ',';

    const splitNumber = numberString.split('.');
    let mainNumber = splitNumber[0];
    const remainder = splitNumber.length > 1 ? '.' + splitNumber[1] : '';

    while (regx.test(mainNumber)) {
        mainNumber = mainNumber.replace(regx, '$1' + thousandSep + '$2');
    }
    return mainNumber + remainder;
}

export function roundCurrency(value) {
    if (value !== null) {
        return applyThousandSeperator(Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100);
    }
    return null;
}

export function calculateSplit(percentageSplit, value) {
    if (value !== null && percentageSplit !== null) {
        return ((percentageSplit / 100) * value).toFixed(2);
    }

    return null;
}

export function createFormButtonActionHandler(event, action) {
    event.preventDefault();
    return action;
}

export function convertDateFormat(dateString) {
    try {
        // Split the date string into year, month, and day
        var parts = dateString.split('-');
        var year = parts[0];
        var month = parts[1];
        var day = parts[2];

        // Reformat the date string in the desired format
        var formattedDate = day + '/' + month + '/' + year;

        return formattedDate;
    } catch (error) {
        // Handle the case where the input string is not in the expected format
        return 'Invalid date format. Please use yyyy-mm-dd.';
    }
}
