import { EFP_COVER_CATEGORIES } from "./types";
// Form transitions
export const FORM_VALID_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/formValid';
export const FORM_INVALID_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/formInvalid';

export const transitionQuote = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_QUOTE }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_QUOTE }),
};

// Transitions - navigation
export const CONTINUE_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/validContinue';
export const BACK_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/back';
export const continueQuote = () => ({ type: CONTINUE_TRANSITION_QUOTE });
export const backQuote = () => ({ type: BACK_TRANSITION_QUOTE });

// Transition - reset state
export const RESET_STATE_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/resetState';
export const resetQuote = () => ({ type: RESET_STATE_TRANSITION_QUOTE });

export const ADD_NEW_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/addCover';
export const DELETE_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/deleteCover';
export const EDIT_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/editCover';
export const SAVE_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/saveCover';
export const EXPAND_MEMBERS_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/expandMembers';
export const COLLAPSE_MEMBERS_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/collapseMembers';
export const CANCEL_EDIT_OF_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/cancelEditCover';
export const CLEAR_ALL_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/clearAll';
export const BUY_NOW_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/buyNow';

export const addNewCover = () => ({ type: ADD_NEW_TRANSITION_QUOTE });
export const editCover = (coverId, coverCategory, sideDrawer) => ({ type: EDIT_TRANSITION_QUOTE, coverId, coverCategory, sideDrawer });
export const deleteCover = (coverId, coverCategory) => ({ type: DELETE_TRANSITION_QUOTE, coverId, coverCategory });
export const saveCover = (coverId, coverCategory) => ({ type: SAVE_TRANSITION_QUOTE, coverId, coverCategory });
export const cancelEditCover = () => ({ type: CANCEL_EDIT_OF_TRANSITION_QUOTE });
export const expandMembers = coverCategory => ({ type: EXPAND_MEMBERS_TRANSITION_QUOTE, coverCategory });
export const collapseMembers = () => ({ type: COLLAPSE_MEMBERS_TRANSITION_QUOTE });
export const buyNow = () => ({ type: BUY_NOW_TRANSITION_QUOTE });

export const clearAll = coverCategory => ({ type: CLEAR_ALL_TRANSITION_QUOTE, coverCategory })
export const clearAllDirectFamily = () => clearAll(EFP_COVER_CATEGORIES.DIRECT_FAMILY);
export const clearAllParentsAndInLaws = () => clearAll(EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS);
export const clearAllExtendedFamily = () => clearAll(EFP_COVER_CATEGORIES.EXTENDED_FAMILY);
export const clearAllLivesCovered = () => clearAll();

export const CLOSE_ADD_COVER_TRANSITION_QUOTE = 'easiplusFuneralPlan/quote/transition/toggleAddCover';
export const closeAddCover = () => ({ type: CLOSE_ADD_COVER_TRANSITION_QUOTE });


// transitions - submission
export const EFP_QUOTE_CREATE_QUOTE = 'easiplusFuneralPlan/quote/createQuote/';
export const EFP_QUOTE_CREATE_QUOTE_SEND = EFP_QUOTE_CREATE_QUOTE + 'send';
export const EFP_QUOTE_CREATE_QUOTE_SEND_SUCCESS = EFP_QUOTE_CREATE_QUOTE + 'success';
export const EFP_QUOTE_CREATE_QUOTE_SEND_FAILURE = EFP_QUOTE_CREATE_QUOTE + 'failure';
export const EFP_QUOTE_CREATE_QUOTE_CANCEL = EFP_QUOTE_CREATE_QUOTE + 'cancel';
export const sendQuoteCreateQuote = () => ({ type: EFP_QUOTE_CREATE_QUOTE_SEND });
export const sendQuoteCreateQuoteFailure = () => ({ type: EFP_QUOTE_CREATE_QUOTE_SEND_FAILURE });
export const sendQuoteCreateQuoteSuccess = value => ({ type: EFP_QUOTE_CREATE_QUOTE_SEND_SUCCESS, value });
export const sendQuoteCreateCancel = () => ({ type: EFP_QUOTE_CREATE_QUOTE_CANCEL });

// Progressive disclosure
export const PROGRESSIVE_AGE_CLEAR_QUOTE = 'easiplusFuneralPlan/quote/progressiveClear/age';
export const PROGRESSIVE_MEMBER_CLEAR_QUOTE = 'easiplusFuneralPlan/quote/progressiveClear/member';
export const PROGRESSIVE_CATEGORY_CLEAR_QUOTE = 'easiplusFuneralPlan/quote/progressiveClear/category';

export const progressiveDisclosureAgeClear = () => ({ type: PROGRESSIVE_AGE_CLEAR_QUOTE });
export const progressiveDisclosureMemberClear = () => ({ type: PROGRESSIVE_MEMBER_CLEAR_QUOTE });
export const progressiveDisclosureCategoryClear = () => ({ type: PROGRESSIVE_CATEGORY_CLEAR_QUOTE });

export const CONFIRM_DELETE_MEMBER_CLEAR = 'easiplusFuneralPlan/quote/confirmDelete/clear';
export const CONFIRM_DELETE_MEMBER_SET = 'easiplusFuneralPlan/quote/confirmDelete/set';

export const clearConfirmDeleteMember = () => ({ type: CONFIRM_DELETE_MEMBER_CLEAR })
export const setConfirmDeleteMember = ({ id, relationship, category }) => ({
    type: CONFIRM_DELETE_MEMBER_SET,
    id,
    relationship,
    category,
});

// Form fields
export const QUOTE_SET_VALUE = 'easiplusFuneralPlan/quote/setValue/';
export const QUOTE_SET_VALID = 'easiplusFuneralPlan/quote/setValid/';

export const SIDEDRAWER_SET_VALUE = QUOTE_SET_VALUE + 'sideDrawer';
export const SIDEDRAWER_SET_VALID = QUOTE_SET_VALID + 'sideDrawer';
export const SIDEDRAWER_CLOSED_SET_VALUE = QUOTE_SET_VALUE + 'closeSideDrawer';

export const COVER_CATEGORY_SET_VALUE = QUOTE_SET_VALUE + 'coverCategory';
export const COVER_CATEGORY_SET_VALID = QUOTE_SET_VALID + 'coverCategory';
export const COVER_MEMBER_SET_VALUE = QUOTE_SET_VALUE + 'coverMember';
export const COVER_MEMBER_SET_VALID = QUOTE_SET_VALID + 'coverMember';
export const AGE_SET_VALUE = QUOTE_SET_VALUE + 'age';
export const AGE_SET_VALID = QUOTE_SET_VALID + 'age';
export const COVER_AMOUNT_SET_VALUE = QUOTE_SET_VALUE + 'coverAmount';
export const COVER_AMOUNT_SET_VALID = QUOTE_SET_VALID + 'coverAmount';
export const MONTHLY_PREMIUM_SET_VALUE = QUOTE_SET_VALUE + 'monthlyPremium';
export const MONTHLY_PREMIUM_SET_VALID = QUOTE_SET_VALID + 'monthlyPremium';
export const ADD_PARTNER_SET_VALUE = QUOTE_SET_VALUE + 'addPartner';
export const ADD_PARTNER_SET_VALID = QUOTE_SET_VALID + 'addPartner';
export const ADD_CHILD_SET_VALUE = QUOTE_SET_VALUE + 'addChild';
export const ADD_CHILD_SET_VALID = QUOTE_SET_VALID + 'addChild';

export const NEW_COVER_DEFAULT_SET_VALUE = QUOTE_SET_VALUE + 'newCoverDefault';
export const NEW_COVER_MYSELF_SET_VALUE = QUOTE_SET_VALUE + 'newCoverMyself';
export const NEW_COVER_PARENTS_SET_VALUE = QUOTE_SET_VALUE + 'newCoverParents';
export const NEW_COVER_EXTENDED_SET_VALUE = QUOTE_SET_VALUE + 'newCoverExtended';
export const SHOW_AGE_VALIDATION_SET_VALUE = QUOTE_SET_VALUE + 'showingAgeValidation';
export const WAITING_FOR_CREATE_QUOTE_SET_VALUE = QUOTE_SET_VALUE + 'waitingForCreateQuote';

export const setCoverCategoryValue = value => ({ type: COVER_CATEGORY_SET_VALUE, value });
export const setCoverCategoryValid = error => ({ type: COVER_CATEGORY_SET_VALID, error });
export const setCoverMemberValue = value => ({ type: COVER_MEMBER_SET_VALUE, value });
export const setCoverMemberValid = error => ({ type: COVER_MEMBER_SET_VALID, error });
export const setAgeValue = value => ({ type: AGE_SET_VALUE, value });
export const setAgeValid = error => ({ type: AGE_SET_VALID, error });
export const setCoverAmountValue = value => ({ type: COVER_AMOUNT_SET_VALUE, value });
export const setCoverAmountValid = error => ({ type: COVER_AMOUNT_SET_VALID, error });
export const setMonthlyPremiumValue = value => ({ type: MONTHLY_PREMIUM_SET_VALUE, value });
export const setMonthlyPremiumValid = error => ({ type: MONTHLY_PREMIUM_SET_VALID, error });
export const setAddPartnerValue = value => ({ type: ADD_PARTNER_SET_VALUE, value });
export const setAddPartnerValid = error => ({ type: ADD_PARTNER_SET_VALID, error });
export const setAddChildValue = value => ({ type: ADD_CHILD_SET_VALUE, value });
export const setAddChildValid = error => ({ type: ADD_CHILD_SET_VALID, error });
export const setSideDrawerOpen = value => ({ type: SIDEDRAWER_SET_VALUE, value });
export const setSideDrawerClosed = () => ({ type: SIDEDRAWER_CLOSED_SET_VALUE });
export const setShowingAgeValidation = value => ({ type: SHOW_AGE_VALIDATION_SET_VALUE, value });

export const setNewCoverDefault = () => ({ type: NEW_COVER_DEFAULT_SET_VALUE });
export const setNewCoverMyself = () => ({ type: NEW_COVER_MYSELF_SET_VALUE });
export const setNewCoverParents = () => ({ type: NEW_COVER_PARENTS_SET_VALUE });
export const setNewCoverExtendedFamily = () => ({ type: NEW_COVER_EXTENDED_SET_VALUE });


export const setWaitingForCreateQuote = value => ({ type: WAITING_FOR_CREATE_QUOTE_SET_VALUE, value });
