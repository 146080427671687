import { createSelector } from 'reselect';
import { API_CALL_STATUS } from '../../reducers/status';

class APICallSelector {
    static getErrorStatus(selector) {
        return createSelector(
            selector,
            (slice) => slice.apiCallStatus === API_CALL_STATUS.FAILURE
        );
    }

    static getPendingStatus(selector) {
        return createSelector(
            selector,
            (slice) => slice.apiCallStatus === API_CALL_STATUS.PENDING
        );
    }
}

export { APICallSelector };
