import { validationIsEqual } from '../status';
import { validateBase64File } from '@om/validation';
import {
    ID_UPLOAD_SET_VALUE,
    LICENSE_UPLOAD_SET_VALUE,
    LOG_BOOK_UPLOAD_SET_VALUE,
    VALUATION_REPORT_SET_VALUE,
    INSPECTION_PHOTO_SET_VALUE,
    setIdUploadValid,
    setLicenseUploadValid,
    setLogBookUploadValid,
    setValuationReportUploadValid,
    setInspectionPhotoValid,
} from '../../../actions/ugMotorInsurance/Documents';

let prevValidationAction = null;
export const documentsUploadValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ID_UPLOAD_SET_VALUE:
                error = validateBase64File(action.value, {
                    subject: 'ID',
                    required: true,
                });
                return setIdUploadValid(error);

            case LICENSE_UPLOAD_SET_VALUE:
                error = validateBase64File(action.value, {
                    subject: 'License',
                    required: true,
                });
                return setLicenseUploadValid(error);

            case LOG_BOOK_UPLOAD_SET_VALUE:
                error = validateBase64File(action.value, {
                    subject: 'Logbook',
                    required: true,
                });
                return setLogBookUploadValid(error);

            case VALUATION_REPORT_SET_VALUE:
                error = validateBase64File(action.value, { subject: 'Valuation Report', required: false });
                return setValuationReportUploadValid(error);

            case INSPECTION_PHOTO_SET_VALUE:
                error = validateBase64File(action.value, { subject: 'Inspection Photo', required: false });
                return setInspectionPhotoValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
