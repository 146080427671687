import { createSelector } from 'reselect';
import { getTaxFreeSavingsAccount } from './index';
import { displayFundName, convertAmfRateToPercString } from '../../functions/taxFreeSavingsAccount/selectAFund';
import { getFundReferenceData } from './fundData';
import {
    RECOMMENDED_MINIMUM_TERMS,
    FUND_OPTIONS,
    DEFAULT_FUND_OPTION_CODES,
} from '../../actions/taxFreeSavingsAccount/types';
import { FORM_STATUS } from '../../reducers/status';

export const getSelectAFund = createSelector(getTaxFreeSavingsAccount, (tfsa) => tfsa.selectAFund);
export const getSelectAFundForm = createSelector(getSelectAFund, (selectAFund) => selectAFund.form);

const makeGetField = (fieldId) => createSelector(getSelectAFundForm, (selectAFundForm) => selectAFundForm[fieldId]);

export const getSelectedFund = makeGetField('selectedFund');

export const getDefaultFunds = createSelector(getFundReferenceData, (fundData) => {
    const listCategoryFund = (category) => ({
        fundCode: fundData.defaultFunds[category].fundDetails.code,
        fundName: displayFundName(fundData.defaultFunds[category].fundDetails.name),
        fees: convertAmfRateToPercString(fundData.defaultFunds[category].fundAmfRate),
        factSheetUrl: fundData.defaultFunds[category].fundDetails.url,
        investmentInfo: [
            { label: 'Recommended min term', value: RECOMMENDED_MINIMUM_TERMS[category] },
            {
                label: 'Past performance',
                value: [
                    { label: '1yr', value: fundData.defaultFunds[category].performances.year1 },
                    { label: '3yr', value: fundData.defaultFunds[category].performances.year3 },
                    { label: '5yr', value: fundData.defaultFunds[category].performances.year5 },
                ],
            },
        ],
    });

    return fundData.defaultFunds
        ? {
              lowRisk: listCategoryFund('lowRisk'),
              mediumRisk: listCategoryFund('mediumRisk'),
              highRisk: listCategoryFund('highRisk'),
          }
        : {};
});

export const getDefaultModerateFund = createSelector(getFundReferenceData, (fundData) => {
    const moderateFund = fundData.mediumRisk.filter(
        (fund) => fund.fundDetails.code === DEFAULT_FUND_OPTION_CODES.MODERATE_INVESTOR
    )[0];
    return moderateFund
        ? {
              fundCode: moderateFund.fundDetails.code,
              fundName: displayFundName(moderateFund.fundDetails.name),
              factSheetUrl: moderateFund.fundDetails.url,
              fees: convertAmfRateToPercString(moderateFund.fundAmfRate),
              investmentInfo: [
                  { label: 'Recommended min term', value: RECOMMENDED_MINIMUM_TERMS.mediumRisk },
                  {
                      label: 'Past performance',
                      value: [
                          { label: '1yr', value: moderateFund.performances.year1 },
                          { label: '3yr', value: moderateFund.performances.year3 },
                          { label: '5yr', value: moderateFund.performances.year5 },
                      ],
                  },
              ],
          }
        : {};
});

export const getLowRiskDefaultFund = createSelector(getDefaultFunds, (defaultFunds) => {
    return defaultFunds.lowRisk ?? {};
});

export const getMediumRiskDefaultFund = createSelector(getDefaultFunds, (defaultFunds) => {
    return defaultFunds.mediumRisk ?? {};
});

export const getHighRiskDefaultFund = createSelector(getDefaultFunds, (defaultFunds) => {
    return defaultFunds.highRisk ?? {};
});

export const getFundRiskProfile = createSelector(getSelectedFund, (selectedFund) => {
    switch (selectedFund.value) {
        case FUND_OPTIONS.CAUTIOUS_INVESTOR.toString():
            return 'Cautious Investor';
        case FUND_OPTIONS.COMFORTABLE_INVESTOR.toString():
            return 'Comfortable Investor';
        case FUND_OPTIONS.ADVENTUROUS_INVESTOR.toString():
            return 'Adventurous Investor';
        default:
            return null;
    }
});

export const getFundName = createSelector(getSelectedFund, getFundReferenceData, (selectedFund, fundReferenceData) => {
    const funds = fundReferenceData.defaultFunds;
    const moderateFund = fundReferenceData.mediumRisk.filter(
        (fund) => fund.fundDetails.code === DEFAULT_FUND_OPTION_CODES.MODERATE_INVESTOR
    )[0];

    switch (selectedFund.value) {
        case FUND_OPTIONS.CAUTIOUS_INVESTOR.toString():
            return displayFundName(funds['lowRisk'].fundDetails.name);
        case FUND_OPTIONS.COMFORTABLE_INVESTOR.toString():
            return displayFundName(funds['mediumRisk'].fundDetails.name);
        case FUND_OPTIONS.ADVENTUROUS_INVESTOR.toString():
            return displayFundName(funds['highRisk'].fundDetails.name);
        case FUND_OPTIONS.MODERATE_INVESTOR.toString():
            return displayFundName(moderateFund.fundDetails.name);
        default:
            return null;
    }
});

export const getFundCode = createSelector(getSelectedFund, getFundReferenceData, (selectedFund, fundReferenceData) => {
    const funds = fundReferenceData.defaultFunds;
    const moderateFund = fundReferenceData.mediumRisk.filter(
        (fund) => fund.fundDetails.code === DEFAULT_FUND_OPTION_CODES.MODERATE_INVESTOR
    )[0];

    switch (selectedFund.value) {
        case FUND_OPTIONS.CAUTIOUS_INVESTOR.toString():
            return funds['lowRisk'].fundDetails.code;
        case FUND_OPTIONS.COMFORTABLE_INVESTOR.toString():
            return funds['mediumRisk'].fundDetails.code;
        case FUND_OPTIONS.ADVENTUROUS_INVESTOR.toString():
            return funds['highRisk'].fundDetails.code;
        case FUND_OPTIONS.MODERATE_INVESTOR.toString():
            return moderateFund.fundDetails.code;
        default:
            return null;
    }
});

export const getFundDictionary = createSelector(getFundReferenceData, (fundReferenceData) => {
    let fundDictionary = [];

    for (const prop in fundReferenceData) {
        if (Array.isArray(fundReferenceData[prop])) {
            fundDictionary.push(...fundReferenceData[prop].flatMap((item) => item.fundDetails));
        }
    }

    return fundDictionary;
});

export const getSelectAFundFormIsValid = createSelector(
    getSelectedFund,
    (selectedFund) => selectedFund.status === FORM_STATUS.VALID
);

export const getSelectedFundNameWithFee = createSelector(
    getSelectedFund,
    getLowRiskDefaultFund,
    getMediumRiskDefaultFund,
    getHighRiskDefaultFund,
    getDefaultModerateFund,
    (selectedFund, lowRiskFund, mediumRiskFund, highRiskFund, moderateDefaultFund) => {
        switch (selectedFund.value) {
            case FUND_OPTIONS.CAUTIOUS_INVESTOR.toString():
                return {
                    code: lowRiskFund.code,
                    name: displayFundName(lowRiskFund.fundName),
                    fees: `${lowRiskFund.fees}% yearly`,
                };
            case FUND_OPTIONS.COMFORTABLE_INVESTOR.toString():
                return {
                    fundcodeCode: mediumRiskFund.code,
                    name: displayFundName(mediumRiskFund.fundName),
                    fees: `${mediumRiskFund.fees}% yearly`,
                };
            case FUND_OPTIONS.ADVENTUROUS_INVESTOR.toString():
                return {
                    code: highRiskFund.code,
                    name: displayFundName(highRiskFund.fundName),
                    fees: `${highRiskFund.fees}% yearly`,
                };
            case FUND_OPTIONS.MODERATE_INVESTOR.toString():
                return {
                    code: moderateDefaultFund.code,
                    name: displayFundName(moderateDefaultFund.fundName),
                    fees: `${moderateDefaultFund.fees}% yearly`,
                };
            default:
                return null;
        }
    }
);
