
import {
    formTransitionPriceGraph,
} from '../../../actions/publicWeb/priceGraph';

import {
    getStartDate,
    getEndDate,
} from '../../../selectors/publicWeb/priceGraph';

import { FIELD_STATUS } from "../../../reducers/status";

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validatePriceGraph = state => {
    const startDate = getStartDate(state);
    const endDate = getEndDate(state);

    let priceGraphValid = isValid(startDate) && isValid(endDate) 

    return priceGraphValid;
};

export const priceGraphRules = (store, next, action) => {
    const result = next(action);

    // Form valid
    const priceGraphValid = validatePriceGraph(store.getState());
    store.dispatch(priceGraphValid
        ? formTransitionPriceGraph.formValid()
        : formTransitionPriceGraph.formInvalid());

    return result;
};
