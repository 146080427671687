export const APP_URL_PREFIX = '/personal/insure/personal-accident';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QUOTE: 0,
    LIVES_COVERED: 1,
    UPLOAD_DOCUMENTS: 2,
    PAYMENT: 3,
};

export const PAGES = [
    {
        URL: `${APP_URL_PREFIX}/get-quote`,
        URL_PART: 'get-quote',
        TITLE: 'Get Quote',
    },
    {
        URL: `${APP_URL_PREFIX}/lives-covered`,
        URL_PART: 'lives-covered',
        TITLE: 'Lives Covered',
    },
    {
        URL: `${APP_URL_PREFIX}/upload-documents`,
        URL_PART: 'upload-documents',
        TITLE: 'Upload Documents',
    },
    {
        URL: `${APP_URL_PREFIX}/payment`,
        URL_PART: 'payment',
        TITLE: 'Payment',
    },
];

export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const PERSONAL_ACCIDENT_URLS = {
    QUOTE: PAGES[PAGE_INDICES.QUOTE].URL,
    LIVES_COVERED: PAGES[PAGE_INDICES.LIVES_COVERED].URL,
    UPLOAD_DOCUMENTS: PAGES[PAGE_INDICES.UPLOAD_DOCUMENTS].URL,
    PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL,
};
