import axios from 'axios';
import { getAge } from './../../utils/dateHelpers';
import{ mapToDateParts} from '@om/validation/src/helpers/validation-date-helper';


import {
    submitTravelDetailsSuccess,
    submitTravelDetailsFailure,
    apiTransitionTravelDetailsForm,
} from '../../../actions/roaTravelInsurance/travelDetails';

import { navigateToPlans } from '../../../actions/roaTravelInsurance/routing';

import {
    getTravelDetailsForm,
    getIsApiPending,
} from '../../../selectors/roaTravelInsurance/travelDetails';

import { getTravelPlanForm } from '../../../selectors/roaTravelInsurance/travelPlan';
import { getAboutYouForm } from '../../../selectors/roaTravelInsurance/aboutYou';

export const travelDetailsService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (!getIsApiPending(state)) {
            store.dispatch(apiTransitionTravelDetailsForm.pending());

            try {
                const response = await getQuotationData(state);
                const quotation = response?.data?.data?.travel_insurance;
                if (quotation) {
                    store.dispatch(submitTravelDetailsSuccess(quotation));
                    store.dispatch(apiTransitionTravelDetailsForm.success());
                    store.dispatch(navigateToPlans());
                    return;
                } else {
                    const e = response?.data?.errors[0];
                    const error = {
                        name: 'Error',
                        ...e,
                    };
                    store.dispatch(submitTravelDetailsFailure({ error }));
                    store.dispatch(apiTransitionTravelDetailsForm.failure());
                }
            } catch (e) {
                const error = {
                    message: e.message,
                    name: e.name,
                };
                store.dispatch(submitTravelDetailsFailure({ error }));
                store.dispatch(apiTransitionTravelDetailsForm.failure());
            }
        }
    })();
    return result;
};

const getPayload = formDetails => {
    const oldestTravellerdate = mapToDateParts(formDetails.DOB.value);
    const travellerAge = getAge(oldestTravellerdate);
    const today = new Date();
    const bookingDate = today
        .toLocaleDateString('en-KE')
        .split('/')
        .reverse()
        .join('-');

    const startDate = formDetails.departureDate.value
        .split('/')
        .reverse()
        .join('-');

    const endDate = formDetails.returnDate.value
        .split('/')
        .reverse()
        .join('-');
    return {
        variables: {
            trip_plan: formDetails.travelPlan.value,
            origin_country: formDetails.from.value,
            destination_area: formDetails.to.value,
            adults: Number(formDetails.adults.value),
            children: Number(formDetails.kids.value),
            seniors: Number(formDetails.seniors.value),
            oldest_traveler_age: travellerAge,
            start_date: startDate,
            end_date: endDate,
            booking_date: bookingDate,
        },
    };
};

const getQuotationData = async state => {
    const travelDetailsForm = getTravelDetailsForm(state);
    const tripPlan = getTravelPlanForm(state);
    const aboutDetails = getAboutYouForm(state);

    const details = {
        ...travelDetailsForm,
        ...tripPlan,
        ...aboutDetails,
    };
    try {
        const url = '/om-api/roa-travel-insurance/travel-details';
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const result = await axios.post(
            url,
            getPayload(details),
            config,
        );
        return result;
    } catch (error) {
        throw error;
    }
};
