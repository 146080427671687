// Must correspond with reference data codes
export const BANK_ACCOUNT_TYPES = [
    { CODE: 'ChequeOrCurrent', NAME: 'Cheque', TYPE: 1 },
    { CODE: 'Savings', NAME: 'Savings', TYPE: 2},
    { CODE: 'Transmission', NAME: 'Transmission', TYPE: 3},
    { CODE: 'Bond', NAME: 'Bond', TYPE: 4},
    { CODE: 'SubscriptionShare', NAME:'Subscription', TYPE: 5},
];

// Copied from API constants
export const HTTP_STATUS_CODES = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    TEMPORARY_REDIRECT: 307,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PRECONDITION_FAILED: 412,
    UNSUPPORTED_MEDIA_TYPE: 415,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
};

export const CDV_RESPONSE = {
    SUCCESS: 'Success',
    FAIL: 'Account failed check digit verification'
};

export const AVS_RESPONSE = {
    SUCCESS: 'Success',
    FAIL: 'AVS failed'
};

export const ACCOUNT_VALIDATION_MODALS = {
    SUCCESSFUL: 'Succesful',
    SERVER_ERROR: 'Server Error',
    ERROR: 'Error'
};

export const SUPPORTED_BANKS = [
    {bankName: 'FNB', universalBranchCode: '254005', verifictionFormattedName: 'FNB'},
    {bankName: 'ABSA', universalBranchCode: '632005', verifictionFormattedName: 'ABSA'},
    {bankName: 'StandardBank', universalBranchCode: '051001', verifictionFormattedName: 'STANDARD'},
    {bankName: 'Nedbank', universalBranchCode: '198765', verifictionFormattedName: 'NEDBANK'},
    {bankName: 'Capitec', universalBranchCode: '470010', verifictionFormattedName: 'CAPITEC'},
    {bankName: 'AfricanBank', universalBranchCode: '430000', verifictionFormattedName: 'AFRICAN'},
];

export const UNSUPPORTED_BANK_STATUS = 'Unsupported Bank';
