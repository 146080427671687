import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    RESET_SAVE2GETHER,
    FORM_BLOCKED_SET_VALUE,
    SHOW_THANK_YOU_SET_VAULE,
    SHOW_ERROR_SET_VAULE,
    NAME_SET_VALUE,
    NAME_SET_VALID,
    SURNAME_SET_VALUE,
    SURNAME_SET_VALID,
    BUSINESS_NAME_SET_VALUE,
    BUSINESS_NAME_SET_VALID,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    BUSINESS_NUMBER_SET_VALUE,
    BUSINESS_NUMBER_SET_VALID,
    EMAIL_ADDRESS_SET_VALUE,
    EMAIL_ADDRESS_SET_VALID,
    BUSINESS_REGISTERED_SET_VALUE,
    BUSINESS_REGISTERED_SET_VALID,
    NUMBER_OF_EMPLOYEES_SET_VALUE,
    NUMBER_OF_EMPLOYEES_SET_VALID,
    BUSINESS_DURATION_SET_VALUE,
    BUSINESS_DURATION_SET_VALID,
    BUSINESS_MANAGEMENT_SET_VALUE,
    BUSINESS_MANAGEMENT_SET_VALID,
    BUSINESS_REGISTERED_AD_SET_VALUE,
    BUSINESS_REGISTERED_AD_SET_VALID,
    HAS_EMPLOYEE_RETIREMENT_SET_VALUE,
    HAS_EMPLOYEE_RETIREMENT_SET_VALID,
    FORM_VALID_TRANSITION_SAVE2GETHER,
    FORM_INVALID_TRANSITION_SAVE2GETHER,
} from '../../actions/publicWeb/save2gether';

const defaultSave2getherState = {
    firstName: {
        value: '',
        error: null,
        status: null,
    },
    surname: {
        value: '',
        error: null,
        status: null,
    },
    businessName: {
        value: '',
        error: null,
        status: null,
    },
    phoneNumber: {
        value: '',
        error: null,
        status: null,
    },
    businessNumber: {
        value: '',
        error: null,
        status: null,
    },
    emailAddress: {
        value: '',
        error: null,
        status: null,
    },
    businessRegistered:{
        value: '',
        error: null,
        status: null,
    },
    numberOfEmployees:{
        value: '',
        error: null,
        status: null,
    },
    businessDuration:{
        value: '',
        error: null,
        status: null,
    },
    businessManagement:{
        value: '',
        error: null,
        status: null,
    },
    businessRegisteredAs:{
        value: '',
        error: null,
        status: null,
    },
    hasEmployeeRetirement: {
        value: '',
        error: null,
        status: null,
    },
    formBlocked: false,
    showThankYou: false,
    showError: false,
    formStatus: FORM_STATUS.INVALID,
};

const initSave2gether = {
    ...defaultSave2getherState,
    formBlocked: false,
};

if(process.env && process.env.GRIDSOME_ENVIRONMENT_NAME) {
    const environment = process.env.GRIDSOME_ENVIRONMENT_NAME.toLowerCase()
    if (environment === 'preprod' || environment === 'prod') {
        initSave2gether.toEmailAddress = 'save2gether@oldmutual.com';
    }
}
initSave2gether.toEmailAddress = 'npwtestemail2@gmail.com';

export default (state = initSave2gether, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId]),
    });

    switch (action.type) {
        case RESET_SAVE2GETHER:
            return {
                ...state,
                ...defaultSave2getherState,
            };

        case FORM_BLOCKED_SET_VALUE:
            return {
                ...state,
                formBlocked: action.value,
            };

        case SHOW_THANK_YOU_SET_VAULE:
            return {
                ...state,
                showThankYou: action.value,
            };

        case SHOW_ERROR_SET_VAULE:
            return {
                ...state,
                showError: action.value,
            };

        case NAME_SET_VALUE:
            return updateFormField('firstName', field => ({
                ...field,
                value: action.value,
            }));

        case NAME_SET_VALID:
            return updateFormField('firstName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SURNAME_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: action.value,
            }));

        case SURNAME_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUSINESS_NAME_SET_VALUE:
            return updateFormField('businessName', field => ({
                ...field,
                value: action.value,
            }));

        case BUSINESS_NAME_SET_VALID:
            return updateFormField('businessName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', field => ({
                ...field,
                value: action.value,
            }));

        case PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUSINESS_NUMBER_SET_VALUE:
            return updateFormField('businessNumber', field => ({
                ...field,
                value: action.value,
            }));

        case BUSINESS_NUMBER_SET_VALID:
            return updateFormField('businessNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_ADDRESS_SET_VALUE:
            return updateFormField('emailAddress', field => ({
                ...field,
                value: action.value,
            }));

        case EMAIL_ADDRESS_SET_VALID:
            return updateFormField('emailAddress', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUSINESS_REGISTERED_SET_VALUE:
            return updateFormField('businessRegistered', field => ({
                ...field,
                value: action.value,
            }));

        case BUSINESS_REGISTERED_SET_VALID:
            return updateFormField('businessRegistered', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NUMBER_OF_EMPLOYEES_SET_VALUE:
            return updateFormField('numberOfEmployees', field => ({
                ...field,
                value: action.value,
            }));

        case NUMBER_OF_EMPLOYEES_SET_VALID:
            return updateFormField('numberOfEmployees', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUSINESS_DURATION_SET_VALUE:
            return updateFormField('businessDuration', field => ({
                ...field,
                value: action.value,
            }));

        case BUSINESS_DURATION_SET_VALID:
            return updateFormField('businessDuration', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUSINESS_MANAGEMENT_SET_VALUE:
            return updateFormField('businessManagement', field => ({
                ...field,
                value: action.value,
            }));

        case BUSINESS_MANAGEMENT_SET_VALID:
            return updateFormField('businessManagement', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUSINESS_REGISTERED_AD_SET_VALUE:
            return updateFormField('businessRegisteredAs', field => ({
                ...field,
                value: action.value,
            }));

        case BUSINESS_REGISTERED_AD_SET_VALID:
            return updateFormField('businessRegisteredAs', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case HAS_EMPLOYEE_RETIREMENT_SET_VALUE:
            return updateFormField('hasEmployeeRetirement', field => ({
                ...field,
                value: action.value,
            }));

        case HAS_EMPLOYEE_RETIREMENT_SET_VALID:
            return updateFormField('hasEmployeeRetirement', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FORM_VALID_TRANSITION_SAVE2GETHER:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            };

        case FORM_INVALID_TRANSITION_SAVE2GETHER:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID
            };

        default:
            return state;
    }
}
