import {
  setAccountHolderValue,
  setAccountNumberValue,
  setAccountTypeValue,
  setBankNameValue,
  setBranchCodeValue,
  setDebitOrderDayValue,
  setPaymentAuthorizationValue,
  transitionPaymentDetails,
} from "../../../actions/digiLifeLoanProtection/paymentDetails";
import { FIELD_STATUS } from "../../../reducers/status";
import { getPaymentDetailsForm } from "../../../selectors/digiLifeLoanProtection/paymentDetails";

export const paymentDetailsRules = (store, next, action) => {
  const result = next(action);
  const isValid = isPaymentDetailsValid(store);

  const { formInvalid, formValid } = transitionPaymentDetails;

  store.dispatch(isValid ? formValid() : formInvalid());

  return result;
};

const isPaymentDetailsValid = (store) => {
  const state = store.getState();
  const isValid = (field) => field && field.status === FIELD_STATUS.VALID;

  const {
    bankName,
    branchCode,
    accountNumber,
    accountType,
    accountHolder,
    debitOrderDay,
    paymentAuthorization,
  } = getPaymentDetailsForm(state);

  const paymentDetailsFormFields = [
    bankName,
    branchCode,
    accountNumber,
    accountType,
    debitOrderDay,
    paymentAuthorization,
    accountHolder
  ];

  const paymentDetailsValid = paymentDetailsFormFields.every(isValid);

  return paymentDetailsValid;
};

export const performPaymentDetailsValidation = (store) => {
  const state = store.getState();
  const {
    bankName,
    branchCode,
    accountNumber,
    accountType,
    debitOrderDay,
    accountHolder,
    paymentAuthorization,
  } = getPaymentDetailsForm(state);

  store.dispatch(
    setBankNameValue({ id: bankName.value, value: bankName.displayValue })
  );
  store.dispatch(
    setBranchCodeValue({ id: branchCode.value, value: branchCode.displayValue })
  );
  store.dispatch(setAccountNumberValue(accountNumber.value));
  store.dispatch(
    setAccountTypeValue({
      id: accountType.value,
      value: accountType.displayValue,
    })
  );
  store.dispatch(setDebitOrderDayValue(debitOrderDay.value));
  store.dispatch(setAccountHolderValue(accountHolder.value));
  store.dispatch(setPaymentAuthorizationValue(paymentAuthorization.value));
  //   store.dispatch(setBankNameValue({id:bankName.value,value:bankName.displayValue}));
  //   store.dispatch(setBankNameValue({id:bankName.value,value:bankName.displayValue}));
};
