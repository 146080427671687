import {
    transitionSpeakToAnAdviser,
    REASONS_SET_VALUE,
    MORE_HELP_SET_VALUE,
    CONTACT_NUMBER_SET_VALUE,
    EMAIL_ADDRESS_SET_VALUE
} from "../../../actions/atRetirement/speakToAnAdviser";
import {getSpeakToAnAdviserForm} from "../../../selectors/atRetirement/speakToAnAdviser";
import {FIELD_STATUS} from "../../../reducers/status";
import {CONTACT_INTERESTS} from "../../../actions/atRetirement/types";
import {
    setReasonsValue,
    setContactNumberAdvisorValue,
    setClientEmailValue,
    setShortDescriptionFindOutMoreValue
} from "../../../actions/atRetirement/financialAdvice";

export const speakToAnAdviserRules = (store, next, action) => {
    const result = next(action);
    const speakToAnAdviserForm = getSpeakToAnAdviserForm(store.getState());
    const formIsValid = formValid(speakToAnAdviserForm);

    store.dispatch(formIsValid
        ? transitionSpeakToAnAdviser.formValid()
        : transitionSpeakToAnAdviser.formInvalid());

    setFinancialAdviceValues(store, action);

    return result;
};

const setFinancialAdviceValues = (store, action) => {
    switch (action.type) {
        case REASONS_SET_VALUE:
            return store.dispatch(setReasonsValue(action.value));
        case MORE_HELP_SET_VALUE:
            return store.dispatch(setShortDescriptionFindOutMoreValue(action.value));
        case CONTACT_NUMBER_SET_VALUE:
            return store.dispatch(setContactNumberAdvisorValue(action.value));
        case EMAIL_ADDRESS_SET_VALUE:
            return store.dispatch(setClientEmailValue(action.value));
    }
};

const formValid = formFields => {
    // If something else has not been selected exclude more help from validation
    if (formFields.reasons.value && !formFields.reasons.value.includes(CONTACT_INTERESTS.SOMETHING_ELSE)) {
        const {moreHelp, ...otherFields} = formFields;
        return Object.values(otherFields).every(field =>
            field.status === FIELD_STATUS.VALID
        );
    }

    return Object.values(formFields).every(field =>
        field.status === FIELD_STATUS.VALID
    );
};
