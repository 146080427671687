import { getIsApiPending, getQuoteForm, getSelectedCoverOption } from '../../../selectors/personalAccident/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure,
} from '../../../actions/personalAccident/quote';
import { getAccessToken } from '../../../selectors/personalAccident/payments';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);
    const selectedCoverOption = getSelectedCoverOption(state);

    const payload = {
        email: quotation.shareEmail.value,
        deathBenefit: parseInt(selectedCoverOption.value.accidental_death.replace(/,/g, '')) || 0,
        disabilityBenefit: parseInt(selectedCoverOption.value.total_disability.replace(/,/g, '')) || 0,
        hospitalBenefit: parseInt(selectedCoverOption.value.hospital_cash.replace(/,/g, '')) || 0,
        artificialBenefit: parseInt(selectedCoverOption.value.artificial_appliances.replace(/,/g, '')) || 0,
        medicalBenefit: parseInt(selectedCoverOption.value.medical_expenses.replace(/,/g, '')) || 0,
        funeralBenefit: parseInt(selectedCoverOption.value.funeral_expenses.replace(/,/g, '')) || 0,
        annualPremium: selectedCoverOption.value.cover_premium || 0,
        countryCode: 'KE',
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);

    try {
        const url = '/om-api/personal-accident/ke/share-quote';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
