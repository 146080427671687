import thunkMiddleware from 'redux-thunk';
import {validation} from './validation';
import {rulesEngine} from './rulesEngine';
import {services} from './services';
import {tracking} from './tracking';
import {persistedAt} from './persistedAt'

export const orderedMiddleware = [
    thunkMiddleware,
    validation,
    tracking,
    rulesEngine,
    services,
    persistedAt,
];
