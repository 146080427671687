import { combineReducers } from 'redux';
import calculator from './calculator';
import referenceData from './referenceData';
import scrolling from './scrolling';

export const personalLoansCalculator = combineReducers({
    referenceData,
    calculator,
    scrolling
});
