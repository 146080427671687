import { transitionTravelInsuranceQuoteForm } from '../../../actions/travel/quote';
import { getQuoteForm } from '../../../selectors/travel/quote';
import { FIELD_STATUS } from '../../../reducers/status';

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getQuoteForm(store.getState());
    const quoteFormValid = validateQuoteForm(quoteForm);
    const formAction = quoteFormValid
        ? transitionTravelInsuranceQuoteForm.formValid()
        : transitionTravelInsuranceQuoteForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateQuoteForm = (formFields) => {
    return (
        formFields?.tripType?.status === FIELD_STATUS.VALID &&
        formFields?.coverFor?.status === FIELD_STATUS.VALID &&
        formFields?.departureCountry?.status === FIELD_STATUS.VALID &&
        formFields?.destinationCountry?.status === FIELD_STATUS.VALID &&
        formFields?.departureDate?.status === FIELD_STATUS.VALID &&
        formFields?.returnDate?.status === FIELD_STATUS.VALID &&
        formFields?.noOfTravellers?.status === FIELD_STATUS.VALID &&
        formFields?.dob?.status === FIELD_STATUS.VALID
    );

};
