import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from "../status";

import {
    FORM_VALID_EMERGENCY_FUND_TRANSITION,
    FORM_INVALID_EMERGENCY_FUND_TRANSITION,
    ONCE_OFF_SET_VALUE,
    ONCE_OFF_SET_VALID,
    MONTHLY_AMOUNT_SET_VALUE,
    MONTHLY_AMOUNT_SET_VALID,
    REINITIALIZE_STATE_EMERGENCY_FUND_CALCULATOR,
    RESULTS_SET_VALUE_FAILURE,
    RESULTS_SET_VALUE,
    CALCULATE_EMERGENCY_FUND,
    API_PENDING_TRANSITION_EMERGENCY_FUND_CALCULATOR,
    API_SUCCESS_TRANSITION_EMERGENCY_FUND_CALCULATOR,
    API_FAILURE_TRANSITION_EMERGENCY_FUND_CALCULATOR,
} from '../../actions/investmentCalculator/emergencyFund'

const emergencyFundForm = {
    amountOnceOff : {value: null, error: null, status: null},
    amountMonthly : {value:null, error: null, status:null},
}
export const initialState = {
  formStatus: FORM_STATUS.INVALID,
  showRecommendation: false,
  apiCallStatus: API_CALL_STATUS.IDLE,
  form: emergencyFundForm,
  result: {},
};

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) =>{
        return {
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
        }
    };
    switch(action.type) {
        // Form Validation
        case FORM_VALID_EMERGENCY_FUND_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_EMERGENCY_FUND_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.INVALID };


        // Api call
        case API_PENDING_TRANSITION_EMERGENCY_FUND_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_EMERGENCY_FUND_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_EMERGENCY_FUND_CALCULATOR:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        // amount once off
        case ONCE_OFF_SET_VALUE:
            return updateFormField('amountOnceOff', field => ({
                ...field,
                value: action.value,
            }));
        case ONCE_OFF_SET_VALID:
            return updateFormField('amountOnceOff', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // amount monthly
        case MONTHLY_AMOUNT_SET_VALUE:
            return updateFormField('amountMonthly', field => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_AMOUNT_SET_VALID:
            return updateFormField('amountMonthly', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RESULTS_SET_VALUE_FAILURE:
            return {
                ...state,
                statstatus: API_CALL_STATUS.FAILURE,
                error: action.error
            }

        // Set calculation results value
        case CALCULATE_EMERGENCY_FUND:
            return {
                ...state,
                result: action.value
            }

        // reinitialise state
        case REINITIALIZE_STATE_EMERGENCY_FUND_CALCULATOR:
            return initialState;

        default:
            return state;
    }
}
