// Events - form input action creator types
export const INVESTMENT_SETUP_INITIAL_SET_VALUE = 'taxFreeSavingsAccount/investmentSetupInitial/setValue/';
export const INVESTMENT_SETUP_INITIAL_SET_VALID = 'taxFreeSavingsAccount/investmentSetupInitial/setValid/';

export const ON_BEHALF_OF_CHILD_SET_VALUE = INVESTMENT_SETUP_INITIAL_SET_VALUE + 'onBehalfOfChild';
export const ON_BEHALF_OF_CHILD_SET_VALID = INVESTMENT_SETUP_INITIAL_SET_VALID + 'onBehalfOfChild';

export const HAS_OTHER_TAX_FREE_PLAN_SET_VALUE = INVESTMENT_SETUP_INITIAL_SET_VALUE + 'hasOtherTaxFreePlan';
export const HAS_OTHER_TAX_FREE_PLAN_SET_VALID = INVESTMENT_SETUP_INITIAL_SET_VALID + 'hasOtherTaxFreePlan';

export const LEGAL_GUARDIAN_SET_VALUE = INVESTMENT_SETUP_INITIAL_SET_VALUE + 'legalGuardian';
export const LEGAL_GUARDIAN_SET_VALID = INVESTMENT_SETUP_INITIAL_SET_VALID + 'legalGuardian';

export const SAVING_FOR_SET_VALUE = INVESTMENT_SETUP_INITIAL_SET_VALUE + 'savingFor';
export const SAVING_FOR_SET_VALID = INVESTMENT_SETUP_INITIAL_SET_VALID + 'savingFor';

export const YOUR_GOAL_SET_VALUE = INVESTMENT_SETUP_INITIAL_SET_VALUE + 'yourGoal';
export const YOUR_GOAL_SET_VALID = INVESTMENT_SETUP_INITIAL_SET_VALID + 'yourGoal';

// Events - form input action creators
export const setOnBehalfOfChildValue = value => ({ type: ON_BEHALF_OF_CHILD_SET_VALUE, value });
export const setOnBehalfOfChildValid = error => ({ type: ON_BEHALF_OF_CHILD_SET_VALID, error });

export const setHasOtherTaxFreePlanValue = value => ({ type: HAS_OTHER_TAX_FREE_PLAN_SET_VALUE, value });
export const setHasOtherTaxFreePlanValid = error => ({ type: HAS_OTHER_TAX_FREE_PLAN_SET_VALID, error });

export const setLegalGuardianValue = value => ({ type: LEGAL_GUARDIAN_SET_VALUE, value });
export const setLegalGuardianValid = error => ({ type: LEGAL_GUARDIAN_SET_VALID, error });

export const setSavingForValue = value => ({ type: SAVING_FOR_SET_VALUE, value });
export const setSavingForValid = error => ({ type: SAVING_FOR_SET_VALID, error });

export const setYourGoalValue = value => ({ type: YOUR_GOAL_SET_VALUE, value });
export const setYourGoalValid = error => ({ type: YOUR_GOAL_SET_VALID, error });

export const RESET_INVESTMENT_SETUP_INITIAL = INVESTMENT_SETUP_INITIAL_SET_VALUE + 'resetState';
export const resetInvestmentSetupInitial = () => ({ type: RESET_INVESTMENT_SETUP_INITIAL });

