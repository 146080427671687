import {
	WHAT_TO_CALCULATE_SET_VALUE,
} from '../../actions/savingsCalculator/savingsCalculator';

const initialState = {
    selectedCalculator: {value: null, error: null, status: null},
    formReferences: [
        {
            value: 'howMuchToSave',
            label: "How much do you need to save?",
        },
        /* {
            value: "whenYouWillReachYourSavingsGoal",
            label: "How long will it take to save?",
        }, */
        {
            value: "theFutureValueOfYourSavings",
            label: "Future value of saving"
        }

    ]
};

export default (state = initialState, action) => {

	switch (action.type) {
		case WHAT_TO_CALCULATE_SET_VALUE:
            return{
                ...state,
                selectedCalculator: {
                    ...state.selectedCalculator,
                    value: action.value,
                },
            }
		default:
			return state;
	}
};
