import {FIELD_STATUS, FORM_STATUS, API_CALL_STATUS} from "../status";

import {
    CONTACT_NUMBER_ADVISOR_SET_VALID,
    CONTACT_NUMBER_ADVISOR_SET_VALUE,
    CLIENT_EMAIL_SET_VALID,
    CLIENT_EMAIL_SET_VALUE,
    FORM_INVALID_TRANSITION_FIN_ADVICE,
    FORM_VALID_TRANSITION_FIN_ADVICE,
    REASONS_SET_VALUE,
    REASONS_SET_VALID,
    SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALID,
    SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALUE,
    FIND_OUT_MORE_SEND,
    FIND_OUT_MORE_SEND_SUCCESS,
    FIND_OUT_MORE_SEND_FAILURE,
    SPEAK_TO_ADVISOR_SEND,
    SPEAK_TO_ADVISOR_SEND_SUCCESS,
    SPEAK_TO_ADVISOR_SEND_FAILURE,
    REWARDS_CLICKED_SET_VALUE
} from "../../actions/atRetirement/financialAdvice";

export const initFinancialAdvice = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        reasons: { value: null, error: null, status: null },
        shortDescription: { value: null, error: null, status: null },
        contactNumber: { value: null, error: null, status: null },
        emailAddress: { value: null, error: null, status: null },
    },
    sendFindOutMoreStatus: API_CALL_STATUS.IDLE,
    sendSpeakToAdvisorStatus: API_CALL_STATUS.IDLE,
    rewardsClicked: false,
};

export default (state = initFinancialAdvice, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    const getReasons = (reasonName) => {
        let reasons = state.form.reasons.value
                ? state.form.reasons.value
                : [];

        // if check box clicked and value exists then remove
        if(reasons.includes(reasonName)) {
            reasons = reasons.filter(reason => reason !== reasonName);
        } else {
            reasons.push(reasonName);
        }
        return reasons;
    }

    switch (action.type) {
        case FORM_VALID_TRANSITION_FIN_ADVICE:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_FIN_ADVICE:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case REASONS_SET_VALUE:
            return updateFormField('reasons', field => ({
                ...field,
                value: getReasons(action.value),
            }));
        case REASONS_SET_VALID:
            return updateFormField('reasons', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALUE:
            return updateFormField('shortDescription', field => ({
                ...field,
                value: action.value,
            }));
        case SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALID:
            return updateFormField('shortDescription', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CONTACT_NUMBER_ADVISOR_SET_VALUE:
            return updateFormField('contactNumber', field => ({
                ...field,
                value: action.value,
            }));
        case CONTACT_NUMBER_ADVISOR_SET_VALID:
            return updateFormField('contactNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CLIENT_EMAIL_SET_VALUE:
            return updateFormField('emailAddress', field => ({
                ...field,
                value: action.value,
            }));
        case CLIENT_EMAIL_SET_VALID:
            return updateFormField('emailAddress', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FIND_OUT_MORE_SEND:
            return { ...state, sendFindOutMoreStatus: API_CALL_STATUS.PENDING };
        case FIND_OUT_MORE_SEND_SUCCESS:
            return { ...state, sendFindOutMoreStatus: API_CALL_STATUS.SUCCESS,};
        case FIND_OUT_MORE_SEND_FAILURE:
            return { ...state, sendFindOutMoreStatus: API_CALL_STATUS.FAILURE };

        case SPEAK_TO_ADVISOR_SEND:
            return { ...state, sendSpeakToAdvisorStatus: API_CALL_STATUS.PENDING };
        case SPEAK_TO_ADVISOR_SEND_SUCCESS:
            return { ...state, sendSpeakToAdvisorStatus: API_CALL_STATUS.SUCCESS };
        case SPEAK_TO_ADVISOR_SEND_FAILURE:
            return { ...state, sendSpeakToAdvisorStatus: API_CALL_STATUS.FAILURE };

        case REWARDS_CLICKED_SET_VALUE:
            return {...state, rewardsClicked: true};

        default:
            return state;
    }
};
