export const REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_FORM_VALID = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/formValid';
export const REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_FORM_INVALID = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/formInvalid';

export const REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALUE = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/setValue';
export const REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALID = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/setValid';

export const EXPECTED_ANNUAL_GROWTH_SET_VALUE = `${REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALUE}/expectedAnnualGrowth`;
export const EXPECTED_ANNUAL_GROWTH_SET_VALID = `${REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALID}/expectedAnnualGrowth`;
export const setExpectedAnnualGrowthValue = value => ({type: EXPECTED_ANNUAL_GROWTH_SET_VALUE, value})
export const setExpectedAnnualGrowthValid = error => ({type: EXPECTED_ANNUAL_GROWTH_SET_VALID, error})

export const ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE = `${REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALUE}/annualIncreaseInContribution`;
export const ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID = `${REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALID}/annualIncreaseInContribution`;
export const setAnnualIncreaseInContributionValue = value => ({type: ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE, value})
export const setAnnualIncreaseInContributionValid = error => ({type: ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID, error})

export const INFLATION_SET_VALUE = REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALUE + '/inflation';
export const INFLATION_SET_VALID = REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALID + '/inflation';
export const setInflationValue = value => ({type: INFLATION_SET_VALUE, value})
export const setInflationValid = error => ({type: INFLATION_SET_VALID, error})