import { transitionPaymentsForm } from '../../../actions/doctorsPi/payments';
import {
    getPaymentsForm,
  
} from '../../../selectors/doctorsPi/payments';
import { FIELD_STATUS } from '../../../reducers/status';

export const paymentsFormRules = (store, next, action) => {
    const result = next(action);
    const paymentsForm = getPaymentsForm(store.getState());
    console.log(paymentsForm, "KKKKK")
    const paymentsFormValid = paymentsForm?.networkProvider?.value === "mpesa" ? validatePaymentsFormDetails(paymentsForm) : validatePaymentsBankFormDetails(paymentsForm);
    const paymentsFormAction = paymentsFormValid ? transitionPaymentsForm.formValid() : transitionPaymentsForm.formInvalid();
    store.dispatch(paymentsFormAction);

    return result;
};

const validatePaymentsFormDetails = (formFields) => {
    return (
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.terms &&
        formFields.terms.status === FIELD_STATUS.VALID
        
    );
};

const validatePaymentsBankFormDetails = (formFields) => {
    return (
        formFields.terms &&
        formFields.terms.status === FIELD_STATUS.VALID
        
    );
};

