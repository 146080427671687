export const DEATH_TERM_COVER_QUOTE_TOOL =
    'roaQuoteTools/deathTermCoverQuoteTool/';
export const DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE =
    DEATH_TERM_COVER_QUOTE_TOOL + 'setValue/';
export const DEATH_TERM_COVER_QUOTE_TOOL_SET_VALID =
    DEATH_TERM_COVER_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_DEATH_TERM_COVER_QUOTE_TOOL =
    'roaQuoteTools/deathTermCoverQuoteTool/transition/formStateValid';
export const FORM_INVALID_DEATH_TERM_COVER_QUOTE_TOOL =
    'roaQuoteTools/deathTermCoverQuoteTool/transition/formInvalid';
export const REINITIALIZE =
    'roaQuoteTools/deathTermCoverQuoteTool/state/reinitialize';

export const WHICH_FORM_TO_DISPLAY =
    DEATH_TERM_COVER_QUOTE_TOOL + 'whichFormToDisplay';

export const setToDeathTermCoverQuoteInitial = () => ({
    type: REINITIALIZE,
});

// API Calls actions
export const API_PENDING_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL = `${DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL = `${DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL = `${DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionDeathTermCoverQuoteTool = {
    pending: () => ({
        type: API_PENDING_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL,
    }),
};

export const transitionDeathTermCoverToolForm = {
    formValid: () => ({ type: FORM_VALID_DEATH_TERM_COVER_QUOTE_TOOL }),
    formInvalid: () => ({
        type: FORM_INVALID_DEATH_TERM_COVER_QUOTE_TOOL,
    }),
};

export const GENDER_SET_VALUE =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'genderValue';

export const CANDIDATE_DOB_SET_VALUE =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'candidateDOB';
export const CANDIDATE_DOB_SET_VALID =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALID + 'candidateDOB';

export const SMOKER_COVERED_SET_VALUE =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'smokerCoveredValue';

export const COVER_AMOUNT_SET_VALUE =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'coverAmount';
export const COVER_AMOUNT_SET_VALID =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALID + 'coverAmount';

export const YEARS_COVERED_SET_VALUE =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'numberOfYearsCovered';
export const YEARS_COVERED_SET_VALID =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALID + 'numberOfYearsCovered';

export const DISABILITY_COVERED_SET_VALUE =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'disabilityCoveredValue';

export const setGenderValue = (value) => ({ type: GENDER_SET_VALUE, value });

export const setCandidateDOBValue = (value) => ({
    type: CANDIDATE_DOB_SET_VALUE,
    value,
});
export const setCandidateDOBValid = (error) => ({
    type: CANDIDATE_DOB_SET_VALID,
    error,
});

export const setSmokerCoveredValue = (value) => ({
    type: SMOKER_COVERED_SET_VALUE,
    value,
});

export const setCoverAmountValue = (value) => ({
    type: COVER_AMOUNT_SET_VALUE,
    value,
});
export const setCoverAmountValid = (error) => ({
    type: COVER_AMOUNT_SET_VALID,
    error,
});

export const setNumberOfYearsCoveredValue = (value) => ({
    type: YEARS_COVERED_SET_VALUE,
    value,
});
export const setNumberOfYearsCoveredValid = (error) => ({
    type: YEARS_COVERED_SET_VALID,
    error,
});

export const setDisabilityCoveredValue = (value) => ({
    type: DISABILITY_COVERED_SET_VALUE,
    value,
});

export const setWhichFormToDisplay = (value) => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});

// Premium calculated Data
export const COLLECT_DEATH_TERM_COVER_PREMIUM_DATA =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'collectDeathTermCoverPremiumData';
export const CALCULATE_DEATH_TERM_COVER_SUCCESS =
    DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE + 'success';

export const calculateDeathTermCoverSuccess = (value) => ({
    type: CALCULATE_DEATH_TERM_COVER_SUCCESS,
    value,
});
export const collectDeathTermCoverPremiumData = () => ({
    type: COLLECT_DEATH_TERM_COVER_PREMIUM_DATA,
});
