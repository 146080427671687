import {
    FUNERAL_CLAIM_API_FAILURE_TRANSITION,
    FUNERAL_CLAIM_API_PENDING_TRANSITION,
    FUNERAL_CLAIM_API_SUCCESS_TRANSITION,
    FUNERAL_CLAIM_FORM_INVALID_TRANSITION,
    FUNERAL_CLAIM_FORM_VALID_TRANSITION,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALID,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE,
    FUNERAL_CLAIM_SET_BANK_OTHER_VALID,
    FUNERAL_CLAIM_SET_BANK_OTHER_VALUE,
    FUNERAL_CLAIM_SET_BANK_VALID,
    FUNERAL_CLAIM_SET_BANK_VALUE,
    FUNERAL_CLAIM_SET_BRANCH_CODE_VALID,
    FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE,
    FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALID,
    FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE,
    FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID,
    FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE,
    FUNERAL_CLAIM_SET_DATE_OF_DEATH_FUNERAL_VALID,
    FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALID,
    FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE,
    FUNERAL_CLAIM_SET_DECEASED_ID_VALID,
    FUNERAL_CLAIM_SET_DECEASED_ID_VALUE,
    FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID,
    FUNERAL_CLAIM_SET_EMAIL_VALID,
    FUNERAL_CLAIM_SET_EMAIL_VALUE,
    FUNERAL_CLAIM_SET_FIRST_NAME_VALID,
    FUNERAL_CLAIM_SET_FIRST_NAME_VALUE,
    FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID,
    FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE,
    FUNERAL_CLAIM_SET_IS_STILLBORN_VALUE,
    FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER,
    FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALID,
    FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE,
    FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALID,
    FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE,
    FUNERAL_CLAIM_SET_REQUESTER_ID_VALID,
    FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE,
    FUNERAL_CLAIM_SET_SURNAME_VALID,
    FUNERAL_CLAIM_SET_SURNAME_VALUE,
    FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS,
    FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER,
    FUNERAL_CLAIM_RECAPTCHA_VALUE,
    FUNERAL_CLAIM_SET_DECEASED_DHA_VERIFICATION,
    FUNERAL_CLAIM_SET_BANK_CDV_VERIFICATION,
    FUNERAL_CLAIM_SET_IS_USER_BANK_ACCOUNT_HOLDER_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALID,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALID,
    FUNERAL_CLAIM_DHA_API_PENDING,
    FUNERAL_CLAIM_DHA_API_SUCCESS,
    FUNERAL_CLAIM_DHA_API_FAILURE,
    FUNERAL_CLAIM_CDV_API_PENDING,
    FUNERAL_CLAIM_CDV_API_SUCCESS,
    FUNERAL_CLAIM_CDV_API_FAILURE,
    FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_PENDING,
    FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_SUCCESS,
    FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_FAILURE,
    FUNERAL_CLAIM_PRESUBMIT_API_PENDING,
    FUNERAL_CLAIM_PRESUBMIT_API_SUCCESS,
    FUNERAL_CLAIM_PRESUBMIT_API_FAILURE,
    FUNERAL_CLAIM_SET_PRESUBMIT,
    FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALUE,
    FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALID,
    FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALUE,
    FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALID,
    FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALUE,
    FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALID,
    FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALUE,
    FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALID,
} from '../../actions/publicWeb/funeralClaimV2';

import {
    API_CALL_STATUS,
    FIELD_STATUS,
    FORM_STATUS,
    TOGGLE_STATUS,
} from '../status';

const initialFuneralClaim = {
    formStatus: FORM_STATUS.INVALID,
    allApiSubmissionsStatus: API_CALL_STATUS.IDLE,
    apiCallStatus: API_CALL_STATUS.IDLE,
    firstName: { value: null, error: null, status: null },
    surname: { value: null, error: null, status: null },
    requesterIdNumber: { value: null, error: null, status: null },
    cellphoneNumber: { value: null, error: null, status: null },
    email: { value: null, error: null, status: FIELD_STATUS.VALID },
    isUserPolicyHolder: null,
    isUserBankAccountHolder: null,
    isStillborn: TOGGLE_STATUS.NO,
    dateOfDeath: { value: null, error: null, status: null },
    deceasedIdNumber: { value: null, error: null, status: null },
    deceasedVerifyDha: { data: null },
    apiCallDhaStatus: API_CALL_STATUS.IDLE,
    cdvBankAccountVerification: { data: null },
    apiCallCdvStatus: API_CALL_STATUS.IDLE,
    apiCallPreSubmitStatus: API_CALL_STATUS.IDLE,
    preSubmit: { data: null },
    causeOfDeath: { value: null, error: null, status: null },
    reasonForUnnaturalDeath: { value: null, error: null, status: null },
    reasonForDeath: { value: null, error: null, status: FIELD_STATUS.VALID },
    funeralDate: { value: null, error: null, status: FIELD_STATUS.VALID },
    bank: { value: null, error: null, status: null },
    bankOther: { value: null, error: null, status: null },
    branchCode: { value: null, error: null, status: FIELD_STATUS.VALID },
    bankAccountNumber: { value: null, error: null, status: null },
    bankAccountType: { value: null, error: null, status: null },
    bankAccountHolderIdNumber: { value: null, error: null, status: null },
    bankAccountHolderName: { value: null, error: null, status: null },
    noticeOfDeathDocument: { value: null, error: null, status: FIELD_STATUS.VALID },
    proofOfBankDocument: { value: null, error: null, status: FIELD_STATUS.VALID },
    agreeToTerms: false,
    sidebarOpen: false,
    recaptcha: {value: null, error: null, status: null},
};

const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);

const validUpdateInternal = (field, error) => ({
    ...field,
    error: error,
    status: mapError(error),
});

let prevCauseOfDeathValue, prevCauseOfDeathValid;
export default (state = initialFuneralClaim, action) => {
    const validUpdate = (field) => validUpdateInternal(field, action.error);

    const valueUpdate = (field) => ({
        ...field,
        value: action.value,
    });

    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId]),
    });

    switch (action.type) {
        // Form Transition
        case FUNERAL_CLAIM_FORM_VALID_TRANSITION:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FUNERAL_CLAIM_FORM_INVALID_TRANSITION:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };
        // ALL API Submissions Status
        case FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_PENDING:
            return {
                ...state,
                allApiSubmissionsStatus: API_CALL_STATUS.PENDING,
            };
        case FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_SUCCESS:
            return {
                ...state,
                allApiSubmissionsStatus: API_CALL_STATUS.SUCCESS,
            };
        case FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_FAILURE:
            return {
                ...state,
                allApiSubmissionsStatus: API_CALL_STATUS.FAILURE
            };
        // API Transition
        case FUNERAL_CLAIM_API_PENDING_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case FUNERAL_CLAIM_API_SUCCESS_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case FUNERAL_CLAIM_API_FAILURE_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        // API - DHA
        case FUNERAL_CLAIM_DHA_API_PENDING:
            return {
                ...state,
                apiCallDhaStatus: API_CALL_STATUS.PENDING
            };
        case FUNERAL_CLAIM_DHA_API_SUCCESS:
            return {
                ...state,
                apiCallDhaStatus: API_CALL_STATUS.SUCCESS
            };
        case FUNERAL_CLAIM_DHA_API_FAILURE:
            return {
                ...state,
                apiCallDhaStatus: API_CALL_STATUS.FAILURE
            };
        // API - CDV
        case FUNERAL_CLAIM_CDV_API_PENDING:
            return {
                ...state,
                apiCallCdvStatus: API_CALL_STATUS.PENDING
            };
        case FUNERAL_CLAIM_CDV_API_SUCCESS:
            return {
                ...state,
                apiCallCdvStatus: API_CALL_STATUS.SUCCESS
            };
        case FUNERAL_CLAIM_CDV_API_FAILURE:
            return {
                ...state,
                apiCallCdvStatus: API_CALL_STATUS.FAILURE
            };
        // API - PRESUBMIT
        case FUNERAL_CLAIM_PRESUBMIT_API_PENDING:
            return {
                ...state,
                apiCallPreSubmitStatus: API_CALL_STATUS.PENDING,
            };
        case FUNERAL_CLAIM_PRESUBMIT_API_SUCCESS:
            return {
                ...state,
                apiCallPreSubmitStatus: API_CALL_STATUS.SUCCESS,
            };
        case FUNERAL_CLAIM_PRESUBMIT_API_FAILURE:
            return {
                ...state,
                apiCallPreSubmitStatus: API_CALL_STATUS.FAILURE,
            };
        // Form fields
        case FUNERAL_CLAIM_SET_FIRST_NAME_VALUE:
            return updateFormField('firstName', valueUpdate);
        case FUNERAL_CLAIM_SET_FIRST_NAME_VALID:
            return updateFormField('firstName', validUpdate);

        case FUNERAL_CLAIM_SET_SURNAME_VALUE:
            return updateFormField('surname', valueUpdate);
        case FUNERAL_CLAIM_SET_SURNAME_VALID:
            return updateFormField('surname', validUpdate);

        case FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE:
            return updateFormField('requesterIdNumber', valueUpdate);
        case FUNERAL_CLAIM_SET_REQUESTER_ID_VALID:
            return updateFormField('requesterIdNumber', validUpdate);

        case FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE:
            return updateFormField('cellphoneNumber', valueUpdate);
        case FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID:
            return updateFormField('cellphoneNumber', validUpdate);

        case FUNERAL_CLAIM_SET_EMAIL_VALUE:
            return updateFormField('email', valueUpdate);
        case FUNERAL_CLAIM_SET_EMAIL_VALID:
            return updateFormField('email', validUpdate);

        case FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER:
            return {
                ...state,
                isUserPolicyHolder: action.value,
            };

        case FUNERAL_CLAIM_SET_IS_STILLBORN_VALUE:
            // Restores previous cause of death when toggling isStillborn to no
            prevCauseOfDeathValue =
                state.isStillborn !== TOGGLE_STATUS.YES
                    ? state.causeOfDeath.value
                    : prevCauseOfDeathValue;
            prevCauseOfDeathValid =
                state.isStillborn !== TOGGLE_STATUS.YES
                    ? state.causeOfDeath.status
                    : prevCauseOfDeathValid;

            return {
                ...state,
                isStillborn:
                    state.isStillborn !== TOGGLE_STATUS.YES
                        ? TOGGLE_STATUS.YES
                        : TOGGLE_STATUS.NO,
                causeOfDeath: {
                    ...state.causeOfDeath,
                    value:
                        state.isStillborn !== TOGGLE_STATUS.YES
                            ? 'NATURAL'
                            : prevCauseOfDeathValue,
                    status:
                        state.isStillborn !== TOGGLE_STATUS.YES
                            ? FIELD_STATUS.VALID
                            : prevCauseOfDeathValid,
                },
            };

        case FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE:
            return updateFormField('dateOfDeath', valueUpdate);
        case FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALID:
            return updateFormField('dateOfDeath', validUpdate);

        case FUNERAL_CLAIM_SET_DATE_OF_DEATH_FUNERAL_VALID:
            return {
                ...state,
                dateOfDeath: validUpdateInternal(
                    state.dateOfDeath,
                    action.dateOfDeathError
                ),
                funeralDate: validUpdateInternal(
                    state.funeralDate,
                    action.dateOfFuneralError
                ),
            };

        case FUNERAL_CLAIM_SET_DECEASED_ID_VALUE:
            return updateFormField('deceasedIdNumber', valueUpdate);
        case FUNERAL_CLAIM_SET_DECEASED_ID_VALID:
            return updateFormField('deceasedIdNumber', validUpdate);

        case FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID:
            return {
                ...state,
                requesterIdNumber: validUpdateInternal(
                    state.requesterIdNumber,
                    action.requesterError
                ),
                deceasedIdNumber: validUpdateInternal(
                    state.deceasedIdNumber,
                    action.deceasedError
                ),
            };
            
        case FUNERAL_CLAIM_SET_DECEASED_DHA_VERIFICATION:
            return {
                ...state,
                deceasedVerifyDha: {
                    data: action.value,
                }
            };
        
        case FUNERAL_CLAIM_SET_BANK_CDV_VERIFICATION:
            return {
                ...state,
                cdvBankAccountVerification: {
                    data: action.value
                }
            };
        
        case FUNERAL_CLAIM_SET_IS_USER_BANK_ACCOUNT_HOLDER_VALUE:
            return {
                ...state,
                isUserBankAccountHolder: action.value,
            };
        case FUNERAL_CLAIM_SET_PRESUBMIT:
            return {
                ...state,
                preSubmit: {
                    data: action.value,
                },
            };
        case FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE:
            return updateFormField('causeOfDeath', valueUpdate);
        case FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALID:
            return updateFormField('causeOfDeath', validUpdate);

        case FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE:
            return updateFormField('reasonForUnnaturalDeath', valueUpdate);
        case FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALID:
            return updateFormField('reasonForUnnaturalDeath', validUpdate);

        case FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE:
            return updateFormField('reasonForDeath', valueUpdate);
        case FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALID:
            return updateFormField('reasonForDeath', validUpdate);

        case FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE:
            return updateFormField('funeralDate', valueUpdate);
        case FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID:
            return updateFormField('funeralDate', validUpdate);

        case FUNERAL_CLAIM_SET_BANK_VALUE:
            return updateFormField('bank', valueUpdate);
        case FUNERAL_CLAIM_SET_BANK_VALID:
            return updateFormField('bank', validUpdate);

        case FUNERAL_CLAIM_SET_BANK_OTHER_VALUE:
            return updateFormField('bankOther', valueUpdate);
        case FUNERAL_CLAIM_SET_BANK_OTHER_VALID:
            return updateFormField('bankOther', validUpdate);

        case FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE:
            return updateFormField('branchCode', valueUpdate);
        case FUNERAL_CLAIM_SET_BRANCH_CODE_VALID:
            return updateFormField('branchCode', validUpdate);

        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE:
            return updateFormField('bankAccountNumber', valueUpdate);
        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID:
            return updateFormField('bankAccountNumber', validUpdate);

        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE:
            return updateFormField('bankAccountType', valueUpdate);
        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALID:
            return updateFormField('bankAccountType', validUpdate);

        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALUE:
            return updateFormField('bankAccountHolderIdNumber', valueUpdate);
        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALID:
            return updateFormField('bankAccountHolderIdNumber', validUpdate);

        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALUE:
            return updateFormField('bankAccountHolderName', valueUpdate);
        case FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALID:
            return updateFormField('bankAccountHolderName', validUpdate);
        case FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALUE:
            return {
                ...state,
                noticeOfDeathDocument: {
                    ...state.noticeOfDeathDocument,
                    value: {
                        documentName: action.value.filename,
                        file: action.value.data,
                        documentClassification: action.value.documentClassification
                    },
                },
            };
        case FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALID:
            return {
                ...state,
                noticeOfDeathDocument: {
                    ...state.noticeOfDeathDocument,
                    error: action.error,
                    status: mapError(action.error),
                },
            };

        case FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALUE:
            return {
                ...state,
                noticeOfDeathDocument: {
                    ...state.noticeOfDeathDocument,
                    value: null,
                },
            };

        case FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALID:
            return {
                ...state,
                noticeOfDeathDocument: {
                    ...state.noticeOfDeathDocument,
                    error: action.error,
                    status: mapError(action.error),
                },
            };

        case FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALUE:
            return {
                ...state,
                proofOfBankDocument: {
                    ...state.proofOfBankDocument,
                    value: {
                        documentName: action.value.filename,
                        file: action.value.data,
                        documentClassification: action.value.documentClassification
                    },
                },
            };

        case FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALID:
            return {
                ...state,
                proofOfBankDocument: {
                    ...state.proofOfBankDocument,
                    error: action.error,
                    status: mapError(action.error),
                },
            };
            
        case FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALUE:
            return {
                ...state,
                proofOfBankDocument: {
                    ...state.proofOfBankDocument,
                    value: null,
                },
            };
        case FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALID:
            return {
                ...state,
                proofOfBankDocument: {
                    ...state.proofOfBankDocument,
                    error: action.error,
                    status: mapError(action.error),
                },
            };

        case FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS:
            return {
                ...state,
                agreeToTerms: !state.agreeToTerms,
            };
        case FUNERAL_CLAIM_RECAPTCHA_VALUE:
            return updateFormField('recaptcha', valueUpdate);

        case FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        default:
            return state;
    }
};
