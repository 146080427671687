import { createSelector } from "reselect";
import { getTaxFreeSavingsAccount } from "./index";
import { FIELD_STATUS, FORM_STATUS } from "../../reducers/status";

export const getPersonalDetails = createSelector(getTaxFreeSavingsAccount, (tfsa) => tfsa.personalDetails);
export const getPersonalDetailsForm = createSelector(getPersonalDetails, (personalDetails) => personalDetails.form);
export const getPersonalDetailsValid = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.formStatus === FORM_STATUS.VALID
);

export const getLeadDetails = createSelector(getPersonalDetails, (personalDetails) => personalDetails.leadDetails);

export const getDropOffDetails = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.dropOffDetails
);

const makeGetField = (fieldId) =>
  createSelector(getPersonalDetailsForm, (personalDetailsForm) => personalDetailsForm[fieldId]);

// Persons Details
export const getTitle = makeGetField("title");
export const getFirstNames = makeGetField("firstNames");
export const getSurname = makeGetField("surname");
export const getContactForFeedback = makeGetField("contactForFeedback");
export const getContactMethod = makeGetField("contactMethod");

// Contact
export const getContactNumber = makeGetField("contactNumber");
export const getEmail = makeGetField("email");

export const getInitials = createSelector(getFirstNames, (firstNames) => {
  const nameArray = firstNames.value.split(" ");
  return nameArray
    .map((name) => name[0])
    .join("")
    .toUpperCase();
});

export const getAreDropoffDetailsValid = createSelector(
  getFirstNames,
  getSurname,
  getContactNumber,
  getContactForFeedback,
  (firstNames, surname, contactNumber, contactForFeedback) => {
      return (
          firstNames.status === FIELD_STATUS.VALID &&
          surname.status === FIELD_STATUS.VALID &&
          contactNumber.status === FIELD_STATUS.VALID &&
          contactForFeedback.value === 'yes'
      );
  }
);

export const getPersonsDetailsCompleted = createSelector(
  getTitle,
  getFirstNames,
  getSurname,
  getContactNumber,
  getEmail,
  getContactForFeedback,
  getContactMethod,
  (title, firstName, surname, contactNumber, email, contactForFeedback, contactMethod) => {
    return (
      title.status === FIELD_STATUS.VALID &&
      firstName.status === FIELD_STATUS.VALID &&
      surname.status === FIELD_STATUS.VALID &&
      contactNumber.status === FIELD_STATUS.VALID &&
      email.status === FIELD_STATUS.VALID &&
      contactForFeedback.status === FIELD_STATUS.VALID &&
      (contactForFeedback.value === 'yes' ? contactMethod.status === FIELD_STATUS.VALID : true)
  );
  }
);

export const getIsPersonalDetailsValid = createSelector(getPersonsDetailsCompleted, (personsDetails) => {
  return personsDetails;
});
