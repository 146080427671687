import { SCHEDULE_FILE_FOR_UPLOAD } from '../../../actions/digitalOnboarding/types';
import { setValidationResults } from '../../../actions/digitalOnboarding/documentStore';
import { getMaxFileSize, getMaxFileErrorMessage, getAcceptableFileExtensions } from '../../../selectors/digitalOnboarding/documentUpload';

const runValidations = (state, action) => {

    const validateFileSize = (sectionName, file) => {
        const maxFileSize = getMaxFileSize(state).value;
        return file.size > maxFileSize
            ? [{ sectionName, errorMessage: getMaxFileErrorMessage(state) }]
            : []
    };

    const validateFileExtension = (sectionName, file) => {
        const extractFileExtension = file => `.${file.name.split('.').pop()}`;
        const accepts = getAcceptableFileExtensions(state);

        const extension = extractFileExtension(file);
        return accepts.includes(extension)
            ? []
            : [{ sectionName, errorMessage: `Please upload a valid document type, e.g. ${accepts.join(', ')}` }]
    };

    const noValidationRequired = () => {
        return [];
    }

    switch (action.type) {
        case SCHEDULE_FILE_FOR_UPLOAD:
            return [ ...validateFileSize(action.sectionName, action.file), ...validateFileExtension(action.sectionName, action.file) ];
    }

    return noValidationRequired;
}

export const documentStoreValidation = (store, next, action) => {
    const result = next(action);

    const state = store.getState();
    const validations = runValidations(state, action);
    store.dispatch(setValidationResults(validations));

    return result;
}