import { getIsFormValid } from "../../../selectors/customerVerificationService/callMeBack"
import { transitionCallMeBack } from "../../../actions/customerVerificationService/callMeBack"

export const callMeBackRules = (store, next, action) => {
    const result = next(action);

    const callMeBackFormIsValid = getIsFormValid(store.getState());
    store.dispatch(callMeBackFormIsValid ? 
            transitionCallMeBack.formValid() :
            transitionCallMeBack.formInvalid()
    );
    
    return result;
};
