// Transitions - validation
export const FORM_VALID_TRANSITION_FUND_SPLIT =
	'shortTermGoal/fundSplit/transition/formValid';
export const FORM_INVALID_TRANSITION_FUND_SPLIT =
	'shortTermGoal/fundSplit/transition/formInvalid';

export const transitionFundSplit = {
	formValid: () => ({ type: FORM_VALID_TRANSITION_FUND_SPLIT }),
	formInvalid: () => ({ type: FORM_INVALID_TRANSITION_FUND_SPLIT }),
};

// Transitions - form initialisation
export const FORM_INIT_TRANSITION_FUND_SPLIT =
	'shortTermGoal/fundSplit/transition/initForm';
export const formInitTransitionFundSplit = () => ({
	type: FORM_INIT_TRANSITION_FUND_SPLIT,
});

// Transition - reset state
export const RESET_STATE_TRANSITION_FUND_SPLIT =
	'shortTermGoal/fundSplit/transition/resetState';
export const resetFundSplit = () => ({
	type: RESET_STATE_TRANSITION_FUND_SPLIT,
});

export const EVEN_SPLIT_SELECT_FUND_SPLIT =
	'shortTermGoal/fundSplit/transition/selectEvenSplit';
export const EVEN_SPLIT_DESELECT_FUND_SPLIT =
	'shortTermGoal/fundSplit/transition/deselectEvenSplit';
export const selectEvenSplit = () => ({ type: EVEN_SPLIT_SELECT_FUND_SPLIT });
export const deselectEvenSplit = () => ({
	type: EVEN_SPLIT_DESELECT_FUND_SPLIT,
});

export const EVEN_SPLIT_DISCARD_FUND_SPLIT =
	'shortTermGoal/fundSplit/transition/discardEvenSplit';
export const discardEvenSplit = () => ({ type: EVEN_SPLIT_DISCARD_FUND_SPLIT });

// Events - form input
export const FUND_SPLIT_SET_VALUE = 'shortTermGoal/fundSplit/setValue/';
export const FUND_SPLIT_SET_VALID = 'shortTermGoal/fundSplit/setValid/';

export const EQUITY_FUND_SET_VALUE = FUND_SPLIT_SET_VALUE + 'equityFund';
export const EQUITY_FUND_SET_VALID = FUND_SPLIT_SET_VALID + 'equityFund';
export const BOND_FUND_SET_VALUE = FUND_SPLIT_SET_VALUE + 'balancedFund';
export const BOND_FUND_SET_VALID = FUND_SPLIT_SET_VALID + 'balancedFund';

export const EVEN_FUND_SPLIT_SELECT =
	FUND_SPLIT_SET_VALID + 'evenSplitFundsSelect';
export const EVEN_FUND_SPLIT_DESELECT =
	FUND_SPLIT_SET_VALID + 'evenSplitFundsDeselect';
export const setEquityFundValue = (value) => ({
	type: EQUITY_FUND_SET_VALUE,
	value,
});
export const setBalancedFundValue = (value) => ({
	type: BOND_FUND_SET_VALUE,
	value,
});

export const SPLIT_PERCENTAGE_SET_VALUE =
	FUND_SPLIT_SET_VALUE + 'splitPercentage';
export const SPLIT_PERCENTAGE_SET_VALID =
	FUND_SPLIT_SET_VALID + 'splitPercentage';
export const setSplitPercentageValue = (value) => ({
	type: SPLIT_PERCENTAGE_SET_VALUE,
	value,
});
export const setSplitPercentageValid = (error) => ({
	type: SPLIT_PERCENTAGE_SET_VALID,
	error,
});

export const TOTAL_PERCENTAGE_SET_VALUE =
	'totalPercentage' + FUND_SPLIT_SET_VALUE;
export const TOTAL_PERCENTAGE_SET_VALID =
	'totalPercentage' + FUND_SPLIT_SET_VALID;
export const setTotalPercentageValue = (value) => ({
	type: TOTAL_PERCENTAGE_SET_VALUE,
	value,
});
export const setTotalPercentageValid = (error) => ({
	type: TOTAL_PERCENTAGE_SET_VALID,
	error,
});

export const EVEN_SPLIT_FUND =
	'shortTermGoal/fund/transition/evenSplitSingleBeneficiary';
export const evenSplitFund = () => ({ type: EVEN_SPLIT_FUND });
