const FUND_SPLIT_TRANSITION = 'taxFreeSavingsAccount/fundSplit/transition/';

// Transitions - validation
export const VALIDATE_FORM_TRANSITION_FUND_SPLIT = FUND_SPLIT_TRANSITION + 'validateForm';
export const INVALIDATE_FORM_TRANSITION_FUND_SPLIT = FUND_SPLIT_TRANSITION + 'invalidateForm';
export const validateForm = fundType => ({ type: VALIDATE_FORM_TRANSITION_FUND_SPLIT, fundType });
export const invalidateForm = fundType => ({ type: INVALIDATE_FORM_TRANSITION_FUND_SPLIT, fundType });

// Transitions - form initialisation
export const FORM_INIT_TRANSITION_FUND_SPLIT = 'taxFreeSavingsAccount/fundSplit/transition/initForm';
export const formInitTransitionFundSplit = (funds, isEvenSplitRegular, isEventSplitLumpSum) => ({ type: FORM_INIT_TRANSITION_FUND_SPLIT, funds, isEvenSplitRegular, isEventSplitLumpSum });

export const EVEN_SPLIT_SELECT_FUND_SPLIT = 'taxFreeSavingsAccount/fundSplit/transition/selectEvenSplit';
export const EVEN_SPLIT_DESELECT_FUND_SPLIT = 'taxFreeSavingsAccount/fundSplit/transition/deselectEvenSplit';
export const selectEvenSplit = fundType => ({ type: EVEN_SPLIT_SELECT_FUND_SPLIT, fundType });
export const deselectEvenSplit = fundType => ({ type: EVEN_SPLIT_DESELECT_FUND_SPLIT, fundType });

export const EVEN_SPLIT_DISCARD_FUND_SPLIT = 'taxFreeSavingsAccount/fundSplit/transition/discardEvenSplit';
export const discardEvenSplit = fundType => ({ type: EVEN_SPLIT_DISCARD_FUND_SPLIT, fundType });

export const DELETE_FUND_FUND_SPLIT = 'taxFreeSavingsAccount/fundSplit/transition/deleteFund';
export const deleteFund = id => ({ type: DELETE_FUND_FUND_SPLIT, id });

// Events - form input
export const FUND_SPLIT_SET_VALUE = 'taxFreeSavingsAccount/fundSplit/setValue/';
export const FUND_SPLIT_SET_VALID = 'taxFreeSavingsAccount/fundSplit/setValid/';

export const SPLIT_PERCENTAGE_SET_VALUE = FUND_SPLIT_SET_VALUE + 'splitPercentage';
export const SPLIT_PERCENTAGE_SET_VALID = FUND_SPLIT_SET_VALID + 'splitPercentage';
export const setSplitPercentageValue = (value, fundId, contribution, fundType) => ({
    type: SPLIT_PERCENTAGE_SET_VALUE,
    value,
    fundId,
    fundType,
    contribution,
});
export const setSplitPercentageValid = (error, fundId, fundType) => ({
    type: SPLIT_PERCENTAGE_SET_VALID,
    error,
    fundId,
    fundType,
});

export const RESET_FUND_SPLIT = 'taxFreeSavingsAccount/fundSplit/setValue/resetState';
export const resetFundSplit = () => ({ type: RESET_FUND_SPLIT });