import { transitionShareQuoteForm } from '../../../actions/motorPrivateRW/quote';
import { FIELD_STATUS } from '../../../reducers/status';
import { getQuoteForm } from '../../../selectors/motorPrivateRW/quote';

export const shareQuoteFormRules = (store, next, action) => {
	const result = next(action);
	const shareQuoteForm = getQuoteForm(store.getState());
	const quoteFormValid = validateShareQuoteFormDetails(shareQuoteForm);
	const formAction = quoteFormValid ? transitionShareQuoteForm.formValid() : transitionShareQuoteForm.formInvalid();
	store.dispatch(formAction);

	return result;
};

const validateShareQuoteFormDetails = (formFields) => {
	return (
		formFields.shareEmail && formFields.shareEmail.status === FIELD_STATUS.VALID
	);
};
