import {
    TITLE_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    DATE_OF_BIRTH_SET_VALUE,
    AGE_SET_VALUE,
    COVER_AMOUNT_SET_VALUE,
    MODAL_SET_VALUE,
    MODAL_CLEAR_VALUE,
    SET_EDIT_COVER_MEMBER_LIVES_COVERED,
    SET_ADD_COVER_MEMBER_LIVES_COVERED,
    PUSH_COVER_MEMBER_LIVES_COVERED,
    ADD_NEW_COVER_MEMBER_LIVES_COVERED,
    DELETE_COVER_MEMBER_LIVES_COVERED,
    SAVE_COVER_MEMBER_LIVES_COVERED,
    CANCEL_COVER_MEMBER_LIVES_COVERED
} from "../../../actions/easiplusFuneralPlan/livesCovered";
import {
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getCoverAmount,
    getAgeValue,
} from "../../../selectors/easiplusFuneralPlan/livesCovered";
import {simpleFieldTracking} from "../formTracking";
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const livesCoveredFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type){
            case TITLE_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getTitle;
            case FIRST_NAME_SET_VALUE:
                sendValue = false;
                return getFirstName;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurname;
            case DATE_OF_BIRTH_SET_VALUE:
                return getDateOfBirth;
            case AGE_SET_VALUE:
                return getAgeValue;
            case COVER_AMOUNT_SET_VALUE:
                return getCoverAmount;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if(field)
        simpleFieldTracking({action, field, sendValue, sendError});

    return result;
}

export const livesCoveredOtherTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case MODAL_SET_VALUE:
                otherEventIndex = findOtherEventIndex('POPUP');
                if (action.value)
                    return "livesCoveredRemoveModalShow";
                return "livesCoveredModalShow";
            case MODAL_CLEAR_VALUE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "livesCoveredModalClosed";
            case SET_EDIT_COVER_MEMBER_LIVES_COVERED:
                otherEventIndex = findOtherEventIndex('OPEN_DRAWER');
                return "livesCoveredEditSideDrawer";
            case SET_ADD_COVER_MEMBER_LIVES_COVERED:
                otherEventIndex = findOtherEventIndex('OPEN_DRAWER');
                return "livesCoveredAddDetailsSideDrawer";
            case PUSH_COVER_MEMBER_LIVES_COVERED:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "livesCoveredSaveNewMember";
            case ADD_NEW_COVER_MEMBER_LIVES_COVERED:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "livesCoveredAddNewMember";
            case DELETE_COVER_MEMBER_LIVES_COVERED:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "livesCoveredMemberDeleted";
            case SAVE_COVER_MEMBER_LIVES_COVERED:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "livesCoveredSaveMember";
            case CANCEL_COVER_MEMBER_LIVES_COVERED:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "livesCoveredCancelEditMember";
            default: return null;
        }
    })();

    if(field)
        otherEventTracking(action.type, otherEventIndex, field);

    return result;
}