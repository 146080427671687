import * as ActionTypes from "./types";

export function storeValue(key, value) {
    return {
        type: ActionTypes.STORE_AFFORDABILITY_DETAIL,
        key: key,
        value: value,
        isValid: false,
        errorMessage: ''
    };
}

export function setValidationResults(validatedFields) {
    return {
        type: ActionTypes.STORE_AFFORDABILITY_DETAIL_VALIDATION,
        fields: validatedFields
    };
}
