import {validationIsEqual} from "../status";

import {
    validateDate,
} from "@om/validation";

import { PRICE_GRAPH_END_DATE, PRICE_GRAPH_START_DATE, setEndDateValid, setStartDateValid } from "../../../actions/publicWeb/priceGraph";
import { getEndDate, getStartDate } from "../../../selectors/publicWeb/priceGraph";

let prevValidationAction = null;
export const priceGraphValidation = (store, next, action) => {
    const state = store.getState();

    const validationAction = (() => {
        let error
        let today = new Date().toLocaleDateString('en-ZA').split('/').reverse().join('/');
        let startDate = getStartDate(state);
        let endDate = getEndDate(state);
        switch (action.type) {
            case PRICE_GRAPH_END_DATE:
                error = validateDate(action.value, {min: startDate.value, max: today});
                if(startDate.error) {
                    const secondError = validateDate(action.value, {min: startDate.value, max: action.value});
                    store.dispatch(setStartDateValid(secondError));
                }
                return setEndDateValid(error);
            case PRICE_GRAPH_START_DATE:
                error = validateDate(action.value, {max: endDate.value});
                if(endDate.error) {
                    const secondError = validateDate(action.value, {min: action.value, max: endDate.value});
                    store.dispatch(setEndDateValid(secondError));
                }
                return setStartDateValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
