import axios from "axios";
import {HTTP_STATUS_CODES} from "../../constants";
import { getIsFundDataValid } from "../../../selectors/taxFreeSavingsAccount/fundData";
import { setFundData } from "../../../actions/taxFreeSavingsAccount/fundData";
import { setRequestId } from "../../../actions/taxFreeSavingsAccount/sessionCamVariables";

export const fundDataService = (store, next, action) => {
    const result = next(action);

    // If fund data exists dont reload
    if (getIsFundDataValid(store.getState())) return;

    (async () => {
        const response = await fetchFundData();
        if (response && validateFundDataResponse(response) && response.status === HTTP_STATUS_CODES.OK) {
            store.dispatch(setFundData(response.data));
        }

        if (response && 'data' in response && 'requestId' in response.data) {
            store.dispatch(setRequestId(response.data.requestId));
        }
    })();

    return result;
};

export const fetchFundData = async () => {
    try {    
        // Even though the service accepts contribution values above 0, it is not required here, so we pass 0  
        return await axios.get(`/om-api/tax-free-savings-account/fund-data?regularContribution=0&lumpSumContribution=0`, 
            {headers: {'Content-Type': 'application/json'}});
    } catch (error) {
        return null;
    }
};

export const validateFundDataResponse = (fundDataResponse) => {

    try {
        return fundDataResponse && 
            fundDataResponse.data &&
            Object.keys(fundDataResponse.data).length !== 0 &&
            fundDataResponse.data.lowRisk && fundDataResponse.data.lowRisk.length > 0 &&
            fundDataResponse.data.mediumRisk && fundDataResponse.data.mediumRisk.length > 0 && 
            fundDataResponse.data.highRisk && fundDataResponse.data.highRisk.length > 0 &&
            fundDataResponse.data.defaultFunds && 
            fundDataResponse.data.defaultFunds.lowRisk && 
            fundDataResponse.data.defaultFunds.mediumRisk &&
            fundDataResponse.data.defaultFunds.highRisk
            ? true : false;

    } catch (error) {
        return false;
    }
}
