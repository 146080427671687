import { transitionDoctorsPiUploadDocuments } from "../../../actions/doctorsPi/documents";
import { FIELD_STATUS } from "../../../reducers/status";
import { getUploadDocumentsForm } from "../../../selectors/doctorsPi/documents";

export const uploadDocumentsFormRules = (store, next, action) => {
    const result = next(action);
    const uploadDocumentsForm = getUploadDocumentsForm(store.getState());
    const uploadDocumentsFormValid = validateUploadDocuments(uploadDocumentsForm);
    const formAction = uploadDocumentsFormValid
        ? transitionDoctorsPiUploadDocuments.formValid()
        : transitionDoctorsPiUploadDocuments.formInvalid()
    store.dispatch(formAction);
    return result
}

const validateUploadDocuments = (formFields) => {
    return (
        formFields.currentLicense &&
        formFields.currentLicense.status === FIELD_STATUS.VALID &&
        formFields.resume &&
        formFields.resume.status === FIELD_STATUS.VALID &&
        formFields.nationalId &&
        formFields.nationalId.status === FIELD_STATUS.VALID &&
        formFields.academicCertificates &&
        formFields.academicCertificates.status === FIELD_STATUS.VALID &&
        formFields.kraPin &&
        formFields.kraPin.status === FIELD_STATUS.VALID
    );
};