import { SET_VALUE_ROUTING } from '../../../actions/personalAccident/routing';
import { routingRules } from './routing';

import {
    PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE,
    PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
} from '../../../actions/personalAccident/quote';
import { quoteFormRules } from './quote';

import {
    PERSONAL_ACCIDENT_PERSONAL_DETAILS_FORM_SET_VALUE,
    PERSONAL_ACCIDENT_PERSONAL_DETAILS_FORM_SET_VALID,
} from '../../../actions/personalAccident/personalDetails';
import { personalDetailsFormRules } from './personalDetails';

import {
    PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE,
    PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID,
} from '../../../actions/personalAccident/childDetails';
import { childDetailsFormRules } from './childDetails';

import {
    PERSONAL_ACCIDENT_SPOUSE_DETAILS_FORM_SET_VALUE,
    PERSONAL_ACCIDENT_SPOUSE_DETAILS_FORM_SET_VALID,
} from '../../../actions/personalAccident/spouseDetails';
import { spouseDetailsFormRules } from './spouseDetails';

import {
    PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID,
    PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE,
} from '../../../actions/personalAccident/nextOfKin';
import { nextOfKinFormRules } from './nextOfKin';

import {
    PERSONAL_ACCIDENT_DOCUMENTS_FORM_SET_VALID,
    PERSONAL_ACCIDENT_DOCUMENTS_FORM_SET_VALUE,
} from '../../../actions/personalAccident/Documents';
import { documentsFormRules } from './documents';

import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from '../../../actions/personalAccident/payments';
import { paymentsFormRules } from './payments';

import {
    PERSONAL_ACCIDENT_AGENT_MODAL_DETAILS_SET_VALUE,
    PERSONAL_ACCIDENT_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/personalAccident/agentModal';
import { agentModalFormRules } from './agentModal';

import {
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE,
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID,
} from '../../../actions/personalAccident/declarations';
import { declarationsFormRules } from './declarations';

export const personalAccidentRules = (store, next, action) => {
    if (action.type.startsWith(SET_VALUE_ROUTING)) {
        return routingRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID)
    ) {
        return quoteFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_PERSONAL_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_PERSONAL_DETAILS_FORM_SET_VALID)
    ) {
        return personalDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID)
    ) {
        return childDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_SPOUSE_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_SPOUSE_DETAILS_FORM_SET_VALID)
    ) {
        return spouseDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALID)
    ) {
        return nextOfKinFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_DOCUMENTS_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_DOCUMENTS_FORM_SET_VALID)
    ) {
        return documentsFormRules(store, next, action);
    }

    if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
        return paymentsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_AGENT_MODAL_DETAILS_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_AGENT_MODAL_DETAILS_SET_VALID)
    ) {
        return agentModalFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID)
    ) {
        return agentModalFormRules(store, next, action);
    }

    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
        return declarationsFormRules(store, next, action);
    }

    return null;
};
