import { createSelector } from 'reselect';
import { FORM_STATUS } from '../../reducers/status';
import { ga_freq_pmt, ga_pv_pmt, ls_fv, ls_pv, round } from '../../../tvm-calcs/src/index';
import { formatCurrency } from '../currency';
import { assumptions } from '../../reducers/educationCalculator/calculator';

export const getEducationCalculator = (state) => {
    return state.educationCalculator.calculator;
};

export const getEducationCalculatorAssumption = (state) => {
    return state.educationCalculator.calculator.assumptions;
};

const makeGetField = (fieldId) =>
    createSelector(getEducationCalculator, (educationCalculator) => educationCalculator[fieldId]);

const makeGetFieldAssumption = (fieldId) =>
    createSelector(getEducationCalculatorAssumption, (assumption) => assumption[fieldId]);

export const getCostOfEducation = makeGetField('costOfEducation');
export const getDurationOfStudies = makeGetField('durationOfStudies');
export const getNumberOfYears = makeGetField('numberOfYears');
export const getContributingDuringStudies = makeGetField('contributingDuringStudies');
export const getSavingAlready = makeGetField('savingAlready');
export const getHowMuchSavedAlready = makeGetField('howMuchSavedAlready');
export const getMonthlyContribution = makeGetField('monthlyContribution');
export const getResultCalculated = makeGetField('resultCalculated');
export const getShowProductOffering = makeGetField('showProductOffering');
export const getShowAssumptions = makeGetField('showAssumptions');
export const getResults = makeGetField('result');

export const getAnnualIncreaseInContributions = makeGetFieldAssumption('annualIncreaseInContributions');
export const getExpectedGrowthOfSavings = makeGetFieldAssumption('expectedGrowthOfSavings');
export const getExpectedAnnualIncreaseInEducationCost = makeGetFieldAssumption('expectedAnnualIncreaseInEducationCost');

export const getFormStatus = makeGetField('formStatus');
export const getCalculateButtonDisabled = createSelector(
    getFormStatus,
    (formStatus) => formStatus === FORM_STATUS.INVALID
);

//calculations

function safeParseInt(value, exp) {
    try {
        return parseInt(typeof value == 'string' ? value.replace(/\s/g, '') : value, exp);
    } catch (e) {
        return 0;
    }
}

export const getExpectedGrowthOfSavingsValue = createSelector(getExpectedGrowthOfSavings, (expectedGrowthOfSavings) => {
    if (expectedGrowthOfSavings.value) {
        return safeParseInt(expectedGrowthOfSavings.value, 10) / 100;
    } else {
        return safeParseInt(assumptions.expectedGrowthOfSavings.value, 10) / 100;
    }
});

export const getAnnualIncreaseInContributionsValue = createSelector(
    getAnnualIncreaseInContributions,
    (annualIncreaseInContributions) => {
        if (annualIncreaseInContributions.value) {
            return safeParseInt(annualIncreaseInContributions.value, 10) / 100;
        } else {
            return safeParseInt(assumptions.annualIncreaseInContributions.value, 10) / 100;
        }
    }
);

export const getExpectedAnnualIncreaseInEducationCostValue = createSelector(
    getExpectedAnnualIncreaseInEducationCost,
    (expectedIncreaseInEducationCost) => {
        if (expectedIncreaseInEducationCost.value) {
            return safeParseInt(expectedIncreaseInEducationCost.value, 10) / 100;
        } else {
            return safeParseInt(assumptions.expectedAnnualIncreaseInEducationCost.value, 10) / 100;
        }
    }
);

export const getEstimatedFutureEducationCost = createSelector(
    getExpectedGrowthOfSavingsValue, //r
    getExpectedAnnualIncreaseInEducationCostValue, //ed_g
    getDurationOfStudies, //ed_n
    getNumberOfYears, //sav_n
    getCostOfEducation, //an_ed_cost
    (r, ed_g, ed_n, sav_n, an_ed_cost) => {
        return round(
            ls_pv(r, sav_n.value - 1, ga_pv_pmt(r, ed_g, ed_n.value, ls_fv(ed_g, sav_n.value, an_ed_cost.value))),
            2
        );
    }
);

export const getFormattedEstimatedFutureEducationCost = createSelector(
    getEstimatedFutureEducationCost,
    (estimatedFutureCostOfSaving) => {
        return formatCurrency(estimatedFutureCostOfSaving, 'R');
    }
);

export const getAmountSavedAlready = createSelector(
    getSavingAlready,
    getHowMuchSavedAlready,
    (isSavingAlready, amountAlreadySaved) => {
        if (isSavingAlready && amountAlreadySaved.value) {
            return safeParseInt(amountAlreadySaved.value);
        } else {
            return 0;
        }
    }
);

export const getWhatYouWillNeedToSaveValue = createSelector(
    getContributingDuringStudies,
    getExpectedGrowthOfSavingsValue, //r
    getAnnualIncreaseInContributionsValue, //g
    getDurationOfStudies, //ed_n
    getNumberOfYears, //sav_n
    getEstimatedFutureEducationCost, //exp_cost
    getAmountSavedAlready, //pv
    (isContributingDuringStudiesValue, r, g, durationOfStudies, numberOfYears, exp_cost, pv) => {
        const ed_n = safeParseInt(durationOfStudies.value, 10);
        const sav_n = safeParseInt(numberOfYears.value, 10);

        let isContributingDuringStudies = false;
        isContributingDuringStudies =
            isContributingDuringStudiesValue === true || isContributingDuringStudiesValue === 'true';

        if (isContributingDuringStudies) {
            return round(ga_freq_pmt(r, 12, g, sav_n + ed_n - 1, exp_cost - pv), 2);
        } else {
            return round(ga_freq_pmt(r, 12, g, sav_n, exp_cost - pv), 2);
        }
    }
);

export const getRawWhatYouWillNeedToSaveValue = createSelector(
    getWhatYouWillNeedToSaveValue,
    (whatYouNeedToSave) => whatYouNeedToSave
);

export const getFormattedWhatYouWillNeedToSaveValue = createSelector(
    getWhatYouWillNeedToSaveValue,
    (whatYouNeedToSave) => {
        if (whatYouNeedToSave < 0) {
            return formatCurrency(0, 'R');
        } else {
            return formatCurrency(whatYouNeedToSave, 'R');
        }
    }
);

export const getAdditionalMonthlyValue = createSelector(
    getSavingAlready,
    getMonthlyContribution,
    getWhatYouWillNeedToSaveValue,
    (isSavingAlready, monthlyContribution, whatYouNeedToSave) => {
        if (isSavingAlready) {
            const monthlyContributionValue = monthlyContribution.value ? monthlyContribution.value : 0;
            return whatYouNeedToSave - safeParseInt(monthlyContributionValue, 10);
        } else {
            return whatYouNeedToSave;
        }
    }
);

export const getFormattedAdditionalMonthlyValue = createSelector(getAdditionalMonthlyValue, (additionalMonthlyValue) =>
    formatCurrency(additionalMonthlyValue, 'R')
);

export const getUnformattedLumpSumInvestment = createSelector(
    getAmountSavedAlready,
    getEstimatedFutureEducationCost,
    (pv, exp_cost) => {
        return exp_cost - pv;
    }
);

export const getLumpSumInvestment = createSelector(
    getAmountSavedAlready,
    getEstimatedFutureEducationCost,
    (pv, exp_cost) => {
        return formatCurrency(exp_cost - pv, 'R');
    }
);
