import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

function validFirstAndLastCharacterName(strName) {
    const lastChar = strName[strName.length - 1];
    const firstChar = strName[0];
    const validFirstAndLastCharRegex = new RegExp(/[a-zA-ZÀ-ž0-9]/);

    if (!validFirstAndLastCharRegex.test(lastChar) || !validFirstAndLastCharRegex.test(firstChar)) {
        return false;
    }
    return true;
}

function validSpacingAroundSpecialCharactersInName(strName) {
    // Check if there is a space before or after the allowed special characters
    const spacingRegex = new RegExp(/ -|- | '|' | !|! | \/|\/ /);
    if (spacingRegex.test(strName)) {
        return false;
    }
    return true;
}

function containsAlpha(str) {
    const containsAlpha = new RegExp('[a-zA-ZÀ-ÖØ-öø-ÿ]+');
    if(containsAlpha.test(str)) {
        return true;
    }
    return false;
}

function hasConsecutiveSpaces(value) {
    const spacingLimitRegex = new RegExp(/\s{2,}/);
    if (spacingLimitRegex.test(value)) {
        return true;
    }

    return false;
}

const alpha = 'alpha';
const alphanumeric = 'alphanumeric';
const alphanumericAllowingOnlyNumbers = 'alphanumericAllowingOnlyNumbers';
const alphanumericLenient = 'alphanumericLenient';
const name = 'name';
const initials = 'initials';
const numeric = 'numeric';
const generic = "generic"

export const StringValidationType = {
    alpha,
    alphanumeric,
    alphanumericAllowingOnlyNumbers,
    alphanumericLenient: alphanumericLenient,
    initials,
    name,
    numeric,
    generic
};

function validationRegex() {
    /**
     * alpha AND ! – ' / \ . ,
     * numeric: only numbers
     * alpha-numeric: alpha AND 0-9, but not only numbers
     * alpha-numeric, accepting only numbers: alpha AND 0-9, only numbers allowed
     * name field AND with ! – ' /
     */
    const asRegexStr = strArr => `^[${strArr.join('')}]*$`;
    const strings = 'a-zA-ZÀ-ÖØ-öø-ÿ\\s+';
    const alphaSpecial = '\\\\\/\'!/\\.,-';
    const numbers = '0-9';
    const nameSpecial = '\'!/-';
    const newLine = '\\n';
    const textAreaSpecial = '\\:\\?\\&\\(\\)\\[\\]@%;';

    return {
        [alpha]: asRegexStr([strings, alphaSpecial]),
        [alphanumeric]: asRegexStr([strings, numbers, alphaSpecial]),
        [alphanumericAllowingOnlyNumbers]: asRegexStr([strings, numbers, alphaSpecial]),
        [alphanumericLenient]: asRegexStr([newLine, strings, numbers, textAreaSpecial, alphaSpecial]),
        [name]: asRegexStr([strings, nameSpecial]),
        [initials]: asRegexStr([strings, nameSpecial]),
        [numeric]: asRegexStr([numbers]),
        [generic]: asRegexStr([strings, alphaSpecial]),
    };
}

function validateStringInternal(
    valueRaw,
    {
        min,
        max,
        subject,
        required,
        pronoun,
        retainSubjectCase
    },
    validationType
) {
    const value = valueRaw && (typeof valueRaw === 'string') && valueRaw.trim();

    if (!value && !required) {
        return null;
    }

    if(required && (!value || value.trim() === '') && subject === "Generic"){
        return OmValidationMessageHelper.getGenericInvalidCharacterMessage();
    }


    if (required && (!value || value.trim() === '')) {
        if (validationType === StringValidationType.initials) {
            return OmValidationMessageHelper.getInitialsRequiredMessage(subject, pronoun, retainSubjectCase);
        }

        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
        
    }

    const validationRegExe = validationRegex();
    const regEx = new RegExp((() => {
        switch (validationType) {
        case StringValidationType.alpha: return validationRegExe.alpha;
        case StringValidationType.numeric: return validationRegExe.numeric;
        case StringValidationType.name: return validationRegExe.name;
        case StringValidationType.initials: return validationRegExe.initials;
        case StringValidationType.alphanumeric: return validationRegExe.alphanumeric;
        case StringValidationType.alphanumericLenient: return validationRegExe.alphanumericLenient;
        case StringValidationType.generic: return validationRegExe.generic;
        default: return validationRegExe.alphanumericAllowingOnlyNumbers;
        }
    })());

    // alphanumeric validation does not allow only numbers
    if (validationType === StringValidationType.alphanumeric &&
        (new RegExp(/^[\d\s]+$/)).test(value)) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    if (validationType === StringValidationType.generic &&
        (!regEx.test(value) || !validFirstAndLastCharacterName(valueRaw))) {
        return OmValidationMessageHelper.getGenericInvalidCharacterMessage();
    }

    if (validationType === StringValidationType.initials &&
        (!regEx.test(value) || (RegExp(/\s/).test(valueRaw)))) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    if (validationType === StringValidationType.alphanumericLenient) {
        if (!containsAlpha(value)) {
            return OmValidationMessageHelper.getAlphaRequired(subject, retainSubjectCase);
        }
        if (!regEx.test(value)) {
            return OmValidationMessageHelper.getInvalidSymbolMessage(subject, retainSubjectCase);
        }
    } 

    if (!regEx.test(value)) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }  

    if (validationType !== StringValidationType.alphanumericLenient &&
        hasConsecutiveSpaces(valueRaw)) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    // name validation has additional first/last character and special character validation
    if (validationType === StringValidationType.name &&
        (!validFirstAndLastCharacterName(valueRaw) ||
        !validSpacingAroundSpecialCharactersInName(value))) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    if (min && (value.length < min)) {
        return OmValidationMessageHelper.getStringTooShortMsg(subject, min, pronoun, retainSubjectCase);
    }

    if (max && (value.length > max)) {
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    return null;
}

// ------ LIBRARY FUNCTIONS
export function validateAlphaString(
    valueRaw,
    {
        min,
        max,
        subject = 'string',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.alpha
    );
}

export function validateAlphaNumericString(
    valueRaw,
    {
        min,
        max,
        subject = 'alpha-numeric-string',
        required,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.alphanumeric
    );
}

export function validateAlphaNumericStringLenient(
    valueRaw,
    {
        min,
        max,
        subject = 'alpha-numeric-string-with-new-line',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.alphanumericLenient
    );
}

export function validateAlphaNumericStringAllowingOnlyNumbers(
    valueRaw,
    {
        min,
        max,
        subject = 'alpha-numeric-allow-only-numbers',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.alphanumericAllowingOnlyNumbers
    );
}

//Alphanumeric only numbers without space
export function validateAlphaNumericAllowingOnlyNumbersWithoutSpace(
    valueRaw,
    {
        min ,
        max ,
        subject = 'alpha-numeric-allow-only-numbers-without-space',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    if (!valueRaw){
       return OmValidationMessageHelper.getInitialsRequiredMessage(subject)
    }
    return valueRaw.includes(" ")?OmValidationMessageHelper.getInvalidMessage(subject):validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.alphanumericAllowingOnlyNumbers
    );
}

export function validateNumericString(
    valueRaw,
    {
        min,
        max,
        subject = 'numeric-string',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.numeric
    );
}
export function validateFullName(
    valueRaw,
    {
        min = config.NAME.MIN,
        max = config.NAME.MAX,
        subject = 'Full name',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    if (!valueRaw){
       return OmValidationMessageHelper.getInitialsRequiredMessage(subject)
    }
    return valueRaw.split(" ").length>1?validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.name
    ):OmValidationMessageHelper.getInvalidMessage(subject)
}

export function validateName(
    valueRaw,
    {
        min = config.NAME.MIN,
        max = config.NAME.MAX,
        subject = 'Name',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.name
    );
}

export function validateFirstName(
    valueRaw,
    {
        min = config.FIRST_NAME.MIN,
        max = config.FIRST_NAME.MAX,
        subject = 'First name',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.name
    );
}

export function validateGenericString(
    valueRaw,
    {
        min = config.FIRST_NAME.MIN,
        max = config.FIRST_NAME.MAX,
        subject = 'Generic',
        required = true,
        pronoun = '',
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.generic
    );
}

export function validateSurname(
    valueRaw,
    {
        min = config.SURNAME.MIN,
        max = config.SURNAME.MAX,
        subject = 'Surname',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.name
    );
}

export function validateInitials(
    valueRaw,
    {
        min,
        max,
        subject = 'Initials',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateStringInternal(
        valueRaw,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        StringValidationType.initials
    );
}
