import axios from 'axios';
import {getRetrieveLivenessBodyObj} from "../../../selectors/customerVerificationService/selfie";
import {
    VERIFICATION_STATUS_SET_VALUE,
    setValidationPassedValue,
    setServiceProviderValue,
    setIsValidValue,
    setMessagesValue,
    setOpenSuccessfullyVerifiedModal,
    setOpenSomethingLooksWrongModal,
    setErrorsValue,
    setOpenVerificationModal,
    setOpenCmbModal
} from "../../../actions/customerVerificationService/selfie";
import {navigateBackToParentApp} from "../../../actions/customerVerificationService/routing";
import {TRANSITION_BETWEEN_PAGE_DELAY} from "../../../actions/customerVerificationService/types";

export const selfieService = (store, next, action) => {
    const result = next(action);

    if (action.type === VERIFICATION_STATUS_SET_VALUE && action.value) {
        const retrieveLivenessPayload = getRetrieveLivenessBodyObj(store.getState());

        (async () => {
            // Open the verification modal
            store.dispatch(setOpenVerificationModal(true));
            const retrieveLivenessResponse = await retrieveLiveness(retrieveLivenessPayload);
            // close the verification modal
            store.dispatch(setOpenVerificationModal(false));

            if (retrieveLivenessResponse && (retrieveLivenessResponse.status === 200)) {
                store.dispatch(setServiceProviderValue(retrieveLivenessResponse.data.serviceProvider));
                store.dispatch(setIsValidValue(retrieveLivenessResponse.data.isValid));
                store.dispatch(setMessagesValue(retrieveLivenessResponse.data.messages));
                store.dispatch(setErrorsValue(retrieveLivenessResponse.data.errors));

                setSelfieValidationPassed(store, retrieveLivenessResponse.data.validationPassed);
            } else {
                store.dispatch(setOpenCmbModal(true));
            }
        })();
    }

    return result;
}

export const retrieveLiveness = async body => {
    try {
        const response = await axios.post('/om-api/customer-verification-service/retrieve-liveness', body, { headers: { 'Content-Type': 'application/json' }});
        return response;
    } catch (error) {
        return null;
    }
};

export const setSelfieValidationPassed = (store, selfieIsValid) => {
    store.dispatch(setValidationPassedValue(selfieIsValid));

    if (selfieIsValid) {
        // SUCCESS: Open success modal.
        store.dispatch(setOpenSuccessfullyVerifiedModal(true));

        // Display success modal for 2.5 seconds and then route back to parent app.
        setTimeout(() => {
            store.dispatch(navigateBackToParentApp());
        }, TRANSITION_BETWEEN_PAGE_DELAY);

        return;
    }

    // FAILURE: If service provider fails selfie authentication open Call Me Back or Continue to KBA modal.
    store.dispatch(setOpenSomethingLooksWrongModal(true));
};
