import { createSelector } from "reselect";
import { getBiasharaFlexiState } from './index';
import { FORM_STATUS } from "../../reducers/status";

export const getUploadDocuments = createSelector(
    getBiasharaFlexiState,
    state => state.uploadDocuments
);

const makeGetUploadDocumentsField = fieldName => createSelector(getUploadDocuments, uploads => uploads[fieldName]);

export const getUploadDocumentsForm = makeGetUploadDocumentsField("form");

export const getUploadDocumentsFormValid = createSelector(
    getUploadDocuments, 
    uploads => uploads.formStatus === FORM_STATUS.VALID,
)

const makeGetFieldForm = (fieldId) => createSelector(getUploadDocumentsForm, (form) => form[fieldId]);

export const getIncorporationCertificateValue = makeGetFieldForm('incorporationCertificate');
export const getKraCertificateValue = makeGetFieldForm('kraCertificate');


