import axios from 'axios';
import { sendCallMeBackSuccess, sendCallMeBackFailure } from '../../../actions/customerVerificationService/callMeBack';
import { getSubmissionBody } from '../../../selectors/customerVerificationService/callMeBack';

const header = {
    'Content-Type': 'application/json',
};

export const callMeBackService = (store, next, action) => {
    const result = next(action);
    const cmbBody = getSubmissionBody(store.getState());

    (async () => {
        store.dispatch(await sendToLeads(cmbBody) ? sendCallMeBackSuccess() : sendCallMeBackFailure());
    })();

    return result;
};

export const sendToLeads = async bodyParams => {
    try {
        const response = await axios.post('/om-api/call-me-back/leads', bodyParams, { headers: header });
        return response.status === 200;
    } catch (error) {
        return false;
    }
};