import { validateDateOfBirth, validateNumber} from  "@om/validation";
import {
    setCandidateDOBValid,
    CANDIDATE_DOB_SET_VALUE,
    setMonthlyPremiumAmountValid,
    MONTHLY_PREMIUM_AMOUNT_SET_VALUE,
    setLumpsumAmountValid,
    LUMPSUM_AMOUNT_SET_VALUE
} from "../../../actions/roaQuoteTools/premierSavingsQuoteTool";

import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const premierSavingsQuoteToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case CANDIDATE_DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 19, max: 66 });
                return setCandidateDOBValid(error);
            case MONTHLY_PREMIUM_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, {min: 7000, subject: 'minimum monthly premium'});
                return setMonthlyPremiumAmountValid(error);
            case LUMPSUM_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, {min: 21000, subject: 'minimum lumpsum amount'});
                return setLumpsumAmountValid(error, action.paymentId);
                
            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
