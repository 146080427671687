import { FIELD_STATUS, FORM_STATUS } from '../status';
import {
    ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID,
    ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE,
    CONTRIBUTING_DURING_STUDIES_SET_VALUE,
    COST_OF_EDUCATION_SET_VALID,
    COST_OF_EDUCATION_SET_VALUE,
    DURATION_OF_STUDIES_SET_VALID,
    DURATION_OF_STUDIES_SET_VALUE,
    EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALID,
    EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALUE,
    EXPECTED_GROWTH_OF_SAVINGS_SET_VALID,
    EXPECTED_GROWTH_OF_SAVINGS_SET_VALUE,
    HOW_MUCH_SAVED_ALREADY_SET_VALID,
    HOW_MUCH_SAVED_ALREADY_SET_VALUE,
    MONTHLY_CONTRIBUTION_SET_VALID,
    MONTHLY_CONTRIBUTION_SET_VALUE,
    NUMBER_OF_YEARS_SET_VALID,
    NUMBER_OF_YEARS_SET_VALUE,
    RESULTS_CALCULATED_SET_VALUE,
    SAVING_ALREADY_SET_VALUE,
    PRODUCT_OFFERING_TOGGLE,
    SHOW_ASSUMPTIONS_SET_VALUE,
    FORM_VALID_TRANSITION_EDUCATION_CALCULATOR_FORM,
    FORM_INVALID_TRANSITION_EDUCATION_CALCULATOR_FORM,
    EDUCATION_CALCULATOR_CLEAR_VALUES,
    CALCULATE_TRANSITION_RESULTS,
    EXPECTED_CONTRIBUTIONS_AND_EDUCATION_COST_SET_VALID
} from '../../actions/educationCalculator/calculator';

export const assumptions = {
    annualIncreaseInContributions: {
        value: 9,
        error: null,
        status: FIELD_STATUS.VALID,
    },
    expectedGrowthOfSavings: {
        value: 8,
        error: null,
        status: FIELD_STATUS.VALID,
    },
    expectedAnnualIncreaseInEducationCost: {
        value: 9,
        error: null,
        status: FIELD_STATUS.VALID,
    },
};

export const educationCalculator = {
    formStatus: FORM_STATUS.INVALID,
    costOfEducation: { value: null, error: null, status: null },
    durationOfStudies: { value: null, error: null, status: null },
    numberOfYears: { value: null, error: null, status: null },
    contributingDuringStudies: false,
    savingAlready: false,
    howMuchSavedAlready: { value: null, error: null, status: null },
    monthlyContribution: { value: null, error: null, status: null },
    resultCalculated: false,
    showProductOffering: false,
    showAssumptions: false,
    assumptions: assumptions,
};

export default (state = educationCalculator, action) => {
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId]),
    });
    const validUpdateInternal = (field, error) => ({
        ...field,
        error: error,
        status: mapError(error),
    });
    const updateAssumptionsField = (fieldId, update) => ({
        ...state,
        assumptions: {
            ...state.assumptions,
            [fieldId]: update(state.assumptions[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRANSITION_EDUCATION_CALCULATOR_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_TRANSITION_EDUCATION_CALCULATOR_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
                resultCalculated: false,
                showProductOffering: false,
            };
        case EDUCATION_CALCULATOR_CLEAR_VALUES:
            return {
                ...educationCalculator
            };
        case CALCULATE_TRANSITION_RESULTS:
            return {
                ...state,
                resultCalculated: true
            };

        case COST_OF_EDUCATION_SET_VALUE:
            return updateField('costOfEducation', (field) => ({
                ...field,
                value: action.value,
            }));
        case COST_OF_EDUCATION_SET_VALID:
            return updateField('costOfEducation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DURATION_OF_STUDIES_SET_VALUE:
            return updateField('durationOfStudies', (field) => ({
                ...field,
                value: action.value,
            }));
        case DURATION_OF_STUDIES_SET_VALID:
            return updateField('durationOfStudies', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case NUMBER_OF_YEARS_SET_VALUE:
            return updateField('numberOfYears', (field) => ({
                ...field,
                value: action.value,
            }));
        case NUMBER_OF_YEARS_SET_VALID:
            return updateField('numberOfYears', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CONTRIBUTING_DURING_STUDIES_SET_VALUE:
            return {
                ...state,
                contributingDuringStudies: action.value,
            };
        case SAVING_ALREADY_SET_VALUE:
            return {
                ...state,
                savingAlready: action.value,
            };
        case HOW_MUCH_SAVED_ALREADY_SET_VALUE:
            return updateField('howMuchSavedAlready', (field) => ({
                ...field,
                value: action.value,
            }));
        case HOW_MUCH_SAVED_ALREADY_SET_VALID:
            return updateField('howMuchSavedAlready', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MONTHLY_CONTRIBUTION_SET_VALUE:
            return updateField('monthlyContribution', (field) => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_CONTRIBUTION_SET_VALID:
            return updateField('monthlyContribution', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case RESULTS_CALCULATED_SET_VALUE:
            return {
                ...state,
                resultCalculated: action.value,
            };
        case PRODUCT_OFFERING_TOGGLE:
            return {
                ...state,
                showProductOffering: !state.showProductOffering,
            };
        case SHOW_ASSUMPTIONS_SET_VALUE:
            return {
                ...state,
                showAssumptions: action.value,
            };
        case ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE:
            return updateAssumptionsField(
                'annualIncreaseInContributions',
                (field) => ({
                    ...field,
                    value: action.value,
                })
            );
        case ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID:
            return updateAssumptionsField(
                'annualIncreaseInContributions',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                })
            );
        case EXPECTED_GROWTH_OF_SAVINGS_SET_VALUE:
            return updateAssumptionsField(
                'expectedGrowthOfSavings',
                (field) => ({
                    ...field,
                    value: action.value,
                })
            );
        case EXPECTED_GROWTH_OF_SAVINGS_SET_VALID:
            return updateAssumptionsField(
                'expectedGrowthOfSavings',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                })
            );
        case EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALUE:
            return updateAssumptionsField(
                'expectedAnnualIncreaseInEducationCost',
                (field) => ({
                    ...field,
                    value: action.value,
                })
            );
        case EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALID:
            return updateAssumptionsField(
                'expectedAnnualIncreaseInEducationCost',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                })
            );
        case EXPECTED_CONTRIBUTIONS_AND_EDUCATION_COST_SET_VALID:
            return {
                ...state,
                assumptions: {
                    ...state.assumptions,
                    annualIncreaseInContributions:  validUpdateInternal(
                        state.assumptions.annualIncreaseInContributions,
                        action.contributionsError
                    ),
                    expectedAnnualIncreaseInEducationCost: validUpdateInternal(
                        state.assumptions.expectedAnnualIncreaseInEducationCost,
                        action.educationError
                    )
                }
            }
        default:
            return state;
    }
};
