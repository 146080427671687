export const APP_URL_PREFIX = '/roa-motor-car-insurance';

export const PAGE_INDICES = {
	LANDING_PAGE: -1,
	QUOTATION: 0,
	ABOUT_YOU: 1,
	PRODUCTS: 2,
	DETAILS: 3,
	PAYMENT: 4,
};

export const PAGES = [
	{
		URL: `${APP_URL_PREFIX}/quotation`,
		URL_PART: 'quotation',
		TITLE: 'Quotation',
	},
	{
		URL: `${APP_URL_PREFIX}/about-you`,
		URL_PART: 'about-you',
		TITLE: 'About You',
	},
	{
		URL: `${APP_URL_PREFIX}/products`,
		URL_PART: 'products',
		TITLE: 'Products',
	},
	{
		URL: `${APP_URL_PREFIX}/details`,
		URL_PART: 'details',
		TITLE: 'Details',
	},
	{
		URL: `${APP_URL_PREFIX}/payment`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	}
];

export const CAR_INSURANCE_URLS = {
	QUOTATION: PAGES[PAGE_INDICES.QUOTATION].URL,
	ABOUT_YOU: PAGES[PAGE_INDICES.ABOUT_YOU].URL,
	PRODUCTS: PAGES[PAGE_INDICES.PRODUCTS].URL,
	DETAILS: PAGES[PAGE_INDICES.DETAILS].URL,
	PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL
}

const SIZE_4_MB = 4194304;

export const DETAILS_PAGE_CONFIG = {
    MAX_INPUT_SIZE: SIZE_4_MB,
    MAX_INPUT_SIZE_NAME: '4MB',
    VALID_UPLOAD_TYPES: ['image/jpeg', 'image/png'],
};
