import { validateNumber } from '@om/validation';
import {
    setChildrenValid,
    CHILDREN_SET_VALUE,
} from '../../../actions/roaQuoteTools/afyaImaraJuniorQuoteTool';

import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const afyaImaraJuniorQuoteToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case CHILDREN_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 1,
                    max: 6,
                    subject: 'number of children',
                });
                return setChildrenValid(error);

            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
