import { TOGGLE_STATUS } from "../status";
import { SET_VALUE_REFERENCE_DATA, SET_ERROR_MODAL_VALUE } from "../../actions/taxFreeSavingsAccount/referenceData";

export const initReferenceData = {
    data: {},
    errorModal: TOGGLE_STATUS.NO
};

export default (state = initReferenceData, action) => {
    switch (action.type) {
        case SET_VALUE_REFERENCE_DATA:
            return {...state, data: action.value};

        case SET_ERROR_MODAL_VALUE:
            return {...state, errorModal: action.value};

        default:
            return state;
    }
};
