import { createSelector } from "reselect";
import {
  ACCOUNT_VALIDATION_STATUS,
  CONTRIBUTION_TYPE,
  PREMIUM_ESCALATION_OPTIONS,
} from "../../actions/taxFreeSavingsAccount/types";
import { AVS_RESPONSE, BANK_ACCOUNT_TYPES, SUPPORTED_BANKS } from "../../middleware/constants";
import { FIELD_STATUS, TOGGLE_STATUS, VALIDATION_CALL_STATUS } from "../../reducers/status";
import { getId } from "./auxiliaryDetails";
import { getTaxFreeSavingsAccount } from "./index";
import { getContributionType, getOwnPercentage, getPremiumEscalation } from "./investmentSetupContribution";
import { getIsLegalGuardian, getSavingForMinor } from "./investmentSetupInitial";
import { getContactNumber, getEmail, getInitials, getSurname } from "./personalDetails";
import {
  getReferenceAccountTypes,
  getReferenceBanks,
  getReferenceDebitOrderDates,
  getReferenceDebitOrderDays,
} from "./referenceData";

export const getPaymentDetails = createSelector(getTaxFreeSavingsAccount, (tfsa) => tfsa.paymentDetails);

export const getPaymentDetailsFormValidationEvent = createSelector(
  getPaymentDetails,
  (paymentDetails) => paymentDetails.formValidationEvent === TOGGLE_STATUS.YES
);

export const getAccountValidationStatus = createSelector(
  getPaymentDetails,
  (paymentDetails) => paymentDetails.accountValidationStatus
);

export const getIsAccountValidationLoading = createSelector(
  getPaymentDetails,
  (paymentDetails) => paymentDetails.accountValidation.status === VALIDATION_CALL_STATUS.PENDING
);

export const getIsAccountValidationSuccess = createSelector(getAccountValidationStatus, (accountValidation) => {
  if (accountValidation.status === VALIDATION_CALL_STATUS.SUCCESS) {
    return accountValidation.reason.data.verificationStatus === AVS_RESPONSE.SUCCESS;
  }
});

export const getIsAccountValidationInvalid = createSelector(
  getAccountValidationStatus,
  (accountValidation) => accountValidation.status === VALIDATION_CALL_STATUS.INVALID
);

export const getIsAccountValidationFailed = createSelector(
  getAccountValidationStatus,
  getIsAccountValidationSuccess,
  (accountValidation, isSuccess) => {
    if (accountValidation.status === VALIDATION_CALL_STATUS.SUCCESS) {
      return !isSuccess;
    }

    return accountValidation.status === VALIDATION_CALL_STATUS.FAILURE;
  }
);

export const getPaymentDetailsForm = createSelector(getPaymentDetails, (paymentDetails) => paymentDetails.form);

export const getUploadedFiles = createSelector(getPaymentDetails, (paymentDetails) => paymentDetails.uploadedFiles);

//return true if any uploaded files have errors
export const getUploadedFilesError = createSelector(getUploadedFiles, (uploadedFiles) =>
  Object.values(uploadedFiles).reduce((x, y) => x.status || y.status === FIELD_STATUS.ERROR)
);

// Identity document error message, file selector and status
export const getIdentityDocumentFileError = createSelector(
  getUploadedFiles,
  (uploadedFiles) => uploadedFiles.identityDocument.error
);

export const getUploadedIdentityDocumentFile = createSelector(getUploadedFiles, (uploadedFiles) => {
  if (uploadedFiles && uploadedFiles.identityDocument && uploadedFiles.identityDocument.value) {
    return uploadedFiles.identityDocument.value;
  }
});

export const getIdentityDocumentFileStatus = createSelector(
  getUploadedFiles,
  (uploadedFiles) => uploadedFiles.identityDocument.status
);

// Court mandate error message, file selector and status
export const getCourtMandateFileError = createSelector(
  getUploadedFiles,
  (uploadedFiles) => uploadedFiles.courtMandate.error
);

export const getUploadedCourtMandateFile = createSelector(getUploadedFiles, (uploadedFiles) => {
  if (uploadedFiles && uploadedFiles.courtMandate && uploadedFiles.courtMandate.value) {
    return uploadedFiles.courtMandate.value;
  }
});

export const getCourtMandateFileStatus = createSelector(
  getUploadedFiles,
  (uploadedFiles) => uploadedFiles.courtMandate.status
);

// Birth Certificate error message, file selector and status
export const getBirthCertificateFileError = createSelector(
  getUploadedFiles,
  (uploadedFiles) => uploadedFiles.birthCertificate.error
);

export const getUploadedBirthCertificateFile = createSelector(getUploadedFiles, (uploadedFiles) => {
  if (uploadedFiles && uploadedFiles.birthCertificate && uploadedFiles.birthCertificate.value) {
    return uploadedFiles.birthCertificate.value;
  }
});

export const getBirthCertificateFileStatus = createSelector(
  getUploadedFiles,
  (uploadedFiles) => uploadedFiles.birthCertificate.status
);

export const getSkipMonth = createSelector(
  getPaymentDetails,
  (paymentDetails) => paymentDetails.skipMonth === TOGGLE_STATUS.YES
);

const makeGetField = (fieldId) =>
  createSelector(getPaymentDetailsForm, (paymentDetailsForm) => paymentDetailsForm[fieldId]);
export const getBankName = makeGetField("bankName");
export const getAccountNumber = makeGetField("accountNumber");
export const getAccountType = makeGetField("accountType");
export const getDebitOrderDay = makeGetField("debitOrderDay");
export const getMonth = makeGetField("month");
export const getMonthToSkip = makeGetField("monthToSkip");
export const getPaymentAuthorization = makeGetField("paymentAuthorization");
export const getLumpSumDay = makeGetField("lumpSumDay");
export const getLumpSumMonth = makeGetField("lumpSumMonth");

export const getSourceOfIncome = makeGetField("sourceOfIncome");
export const getSourceOfIncomeOther = makeGetField("sourceOfIncomeOther");
export const getSourceOfFunds = makeGetField("sourceOfFunds");
export const getEmploymentPosition = makeGetField("employmentPosition");
export const getEmploymentIndustry = makeGetField("employmentIndustry");
export const getEmploymentIndustryOther = makeGetField("employmentIndustryOther");

export const getDebitOrderDayText = createSelector(
  getDebitOrderDay,
  getLumpSumDay,
  getReferenceDebitOrderDays,
  (debitOrderDay, lumpSumDay, debitOrderDays) => {
    const selectedDay = debitOrderDay.value ?? lumpSumDay.value;
    const debitOrderObject = debitOrderDays.filter((referenceEntry) => referenceEntry.description === selectedDay)[0];

    if (debitOrderDay.value) {
      return `${selectedDay}${debitOrderObject.code.substr(-2)} of every month`;
    }
    return `${selectedDay}${debitOrderObject.code.substr(-2)} of the month`;
  }
);

export const getIsSourceOfIncomeOtherRequired = createSelector(
  getSourceOfIncome,
  (sourceOfIncome) => sourceOfIncome.value === "OtherInvestmentIncome"
);
export const getIsEmploymentIndustryRequired = createSelector(
  getEmploymentPosition,
  (employmentPosition) => employmentPosition.value !== "RetiredOrUnemployed"
);
export const getIsEmploymentIndustryOtherRequired = createSelector(
  getEmploymentIndustry,
  (employmentIndustry) =>
    employmentIndustry.value === "OtherServices" || employmentIndustry.value === "PublicSectorOther"
);

export const getPaymentDetailsCompleted = createSelector(
  getBankName,
  getAccountNumber,
  getAccountType,
  (bankName, accountNumber, accountType) => {
    return (
      bankName.status === FIELD_STATUS.VALID &&
      accountNumber.status === FIELD_STATUS.VALID &&
      accountType.status === FIELD_STATUS.VALID
    );
  }
);

export const getLegalQuestionsCompleted = createSelector(
  getSourceOfIncome,
  getIsSourceOfIncomeOtherRequired,
  getSourceOfIncomeOther,
  getSourceOfFunds,
  getEmploymentPosition,
  getIsEmploymentIndustryRequired,
  getEmploymentIndustry,
  getIsEmploymentIndustryOtherRequired,
  getEmploymentIndustryOther,
  (
    sourceOfIncome,
    isSourceOfIncomeOtherRequired,
    sourceOfIncomeOther,
    sourceOfFunds,
    employmentPosition,
    isEmploymentIndustryRequired,
    employmentIndustry,
    isEmploymentIndustryOtherRequired,
    employmentIndustryOther
  ) => {
    let isValid = true;

    isValid =
      isValid &&
      sourceOfIncome.status === FIELD_STATUS.VALID &&
      sourceOfFunds.status === FIELD_STATUS.VALID &&
      employmentPosition.status === FIELD_STATUS.VALID;

    if (isSourceOfIncomeOtherRequired) {
      isValid = isValid && sourceOfIncomeOther.status === FIELD_STATUS.VALID;
    }

    if (isEmploymentIndustryRequired) {
      isValid = isValid && employmentIndustry.status === FIELD_STATUS.VALID;

      if (isEmploymentIndustryOtherRequired) {
        isValid = isValid && employmentIndustryOther.status === FIELD_STATUS.VALID;
      }
    }

    return isValid;
  }
);

export const getPaymentAuthorizationChecked = createSelector(
  getPaymentAuthorization,
  (paymentAuth) => paymentAuth.value === TOGGLE_STATUS.YES
);

export const getPaymentAuthorizationError = createSelector(getPaymentAuthorization, (paymentAuth) => paymentAuth.error);

export const getCalendarMonths = () => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
};

export const getBlurbText = createSelector(
  getOwnPercentage,
  getPremiumEscalation,
  (ownPercentage, premiumEscalation) => {
    switch (premiumEscalation.value) {
      case PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.key:
        return "amount with inflation";
      case PREMIUM_ESCALATION_OPTIONS.LINK_WITH_SALARY.key:
        return "amount with salary inflation";
      case PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.key:
        return "amount with the annual tax free limit";
      case PREMIUM_ESCALATION_OPTIONS.EDUCATION.key:
        return "amount with education inflation";
      case PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key:
        return `at a fixed rate of ${ownPercentage.value}%`;
    }
  }
);

export const getCheckboxChecked = createSelector(
  getPaymentDetails,
  (paymentDetails) => paymentDetails.displayLegalSection === TOGGLE_STATUS.YES
);

export const getIsPaymentDetailsValid = createSelector(
  getPaymentAuthorizationChecked,
  getPaymentDetailsCompleted,
  getContributionType,
  getDebitOrderDay,
  getMonth,
  getLumpSumDay,
  getLumpSumMonth,
  getSkipMonth,
  getMonthToSkip,
  getCheckboxChecked,
  getLegalQuestionsCompleted,
  getSavingForMinor,
  getIsLegalGuardian,
  getIdentityDocumentFileStatus,
  getCourtMandateFileStatus,
  getBirthCertificateFileStatus,
  getUploadedFilesError,
  (
    paymentAuthChecked,
    paymentDetailsCompleted,
    contributionType,
    debitOrderDay,
    month,
    lumpSumDay,
    lumpSumMonth,
    skipMonth,
    monthToSkip,
    checkboxChecked,
    legalSectionValid,
    onBehalfOfMinor,
    isLegalGuardian,
    identityDocumentFileStatus,
    courtMandateFileStatus,
    birthCertificateFileStatus,
    uploadedFileErrors
  ) => {
    if (!paymentAuthChecked) return false;
    if (!paymentDetailsCompleted) return false;
    if (uploadedFileErrors) return false;
    let isValid = true;

    // checks if fields valid when contribution type monthly or combination (i.e. monthly and lump sum contribution)
    if (
      contributionType.value === CONTRIBUTION_TYPE.MONTHLY ||
      contributionType.value === CONTRIBUTION_TYPE.COMBINATION
    ) {
      if (skipMonth) {
        isValid =
          isValid &&
          debitOrderDay.status === FIELD_STATUS.VALID &&
          month.status === FIELD_STATUS.VALID &&
          monthToSkip.status === FIELD_STATUS.VALID;
      } else {
        isValid = isValid && debitOrderDay.status === FIELD_STATUS.VALID && month.status === FIELD_STATUS.VALID;
      }
    }

    // checks if fields valid when contribution type lump sum
    if (contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM) {
      isValid = isValid && lumpSumDay.status === FIELD_STATUS.VALID && lumpSumMonth.status === FIELD_STATUS.VALID;
    }

    if (checkboxChecked) {
      isValid = isValid && legalSectionValid;
    }

    // checks if fields valid when investing on behalf of a minor
    // form fileds for documents to upload differ in the following cases:
    // - user is legal guardian
    // - user is the parent but has a differnet surname to the minor -> upload birth certificate
    // - user is the parent with the same surname as the minor -> upload identity document
    if (onBehalfOfMinor) {
      if (isLegalGuardian) {
        isValid =
          isValid && identityDocumentFileStatus === FIELD_STATUS.VALID && courtMandateFileStatus === FIELD_STATUS.VALID;
      } else {
        isValid =
          (isValid && identityDocumentFileStatus === FIELD_STATUS.VALID) ||
          (isValid && birthCertificateFileStatus === FIELD_STATUS.VALID);
      }
    }

    return isValid;
  }
);

// this value is set on mounted via contentstack
export const getIncludeAvs = createSelector(getPaymentDetails, (paymentDetails) => paymentDetails.includeAvs);

// this value is set on mounted via contentstack
export const getIncludeCdv = createSelector(getPaymentDetails, (paymentDetails) => paymentDetails.includeCdv);

export const getBankSupportedForTFSA = createSelector(getBankName, (bankName) => {
  return SUPPORTED_BANKS.some((bank) => bank.bankName === bankName.value);
});

export const getPayloadBankName = createSelector(getBankName, (bankName) => {
  const bank = SUPPORTED_BANKS.find((bank) => bank.bankName === bankName.value);
  return bank ? bank.verifictionFormattedName : "";
});

export const getIsContributionTypeMonthly = createSelector(
  getContributionType,
  (contributionType) => contributionType.value === CONTRIBUTION_TYPE.MONTHLY
);

export const getIsContributionTypeLumpSum = createSelector(
  getContributionType,
  (contributionType) => contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM
);

export const getIsContributionTypeCombination = createSelector(
  getContributionType,
  (contributionType) => contributionType.value === CONTRIBUTION_TYPE.COMBINATION
);

// Success modal must only be dispalyed in one of the following cases;
// 1. Account Validation passed and Include AVS is active and Bank Account is in list of supported banks
// 2. Account Validation passed and Include CDV is active
export const getDisplaySuccessModal = createSelector(
  getAccountValidationStatus,
  getBankSupportedForTFSA,
  getIncludeAvs,
  getIncludeCdv,
  (accountValidationStatus, bankSupported, includeAvs, includeCdv) => {
    if (accountValidationStatus !== ACCOUNT_VALIDATION_STATUS.PASSED) return false;
    if ((includeAvs && bankSupported) || includeCdv) return true;
  }
);

export const getConvertAccountType = createSelector(
  getAccountType,
  (accountType) => BANK_ACCOUNT_TYPES.find((bankAccountType) => bankAccountType.CODE === accountType.value).TYPE
);

export const getBankBranchCode = createSelector(
  getBankName,
  getReferenceBanks,
  (bankName, referenceBanks) => referenceBanks.find((bank) => bank.code === bankName.value).universalBranchCode
);

export const getBankNameDescription = createSelector(
  getBankName,
  getReferenceBanks,
  (bankName, referenceBanks) => referenceBanks.find((bank) => bank.code === bankName.value).description
);

export const getAvsPayload = createSelector(
  getContactNumber,
  getEmail,
  getSurname,
  getId,
  getInitials,
  getAccountNumber,
  getConvertAccountType,
  getPayloadBankName,
  getBankBranchCode,
  (contactNumber, email, surname, idNumber, initials, accountNumber, accountType, payloadBankName, branchCode) => {
    const cellphoneNumberCountryCode = "+27";
    const contactNumberValue = contactNumber.value;
    const mobileNumber = cellphoneNumberCountryCode + contactNumberValue.substr(1, contactNumberValue.length);
    const payloadAccountNumber = accountNumber.value.split(" ").join("");

    return {
      clientReference: "343434",
      id: idNumber.value,
      initials: initials,
      surnameConame: surname.value,
      emailAddress: email.value,
      cellularPhoneNumber: mobileNumber,
      bankName: payloadBankName,
      bankBranchCode: branchCode,
      bankAccountNumber: payloadAccountNumber,
      bankAccountType: accountType,
    };
  }
);

export const getCdvPayload = createSelector(
  getAccountNumber,
  getConvertAccountType,
  getBankBranchCode,
  (accountNumber, accountType, branchCode) => {
    const payloadAccountNumber = accountNumber.value.split(" ").join("");
    return {
      bankAccountNumber: payloadAccountNumber,
      bankAccountType: accountType,
      bankBranchCode: branchCode,
      clientReference: "None",
      policyAdminSystem: "VTG",
      systemIdentifier: "OMK",
    };
  }
);

export const getReferenceDebitOrderMonths = createSelector(
  getReferenceDebitOrderDates,
  getDebitOrderDay,
  (data, debitOrderDay) => {
    if (data && debitOrderDay && debitOrderDay.value) {
      return data.debitOrderDays.find((date) => date.day == debitOrderDay.value).months;
    }
    return [];
  }
);

export const getReferenceLumpSumMonths = createSelector(
  getReferenceDebitOrderDates,
  getLumpSumDay,
  (data, debitOrderDay) => {
    if (data && debitOrderDay && debitOrderDay.value) {
      return data.debitOrderDays.find((date) => date.day == debitOrderDay.value).months;
    }
    return [];
  }
);

export const getBankDataEntry = createSelector(getBankName, getReferenceBanks, (bankName, referenceBanks) => {
  return referenceBanks.find((ref) => ref.code === bankName.value);
});

export const getAccountTypeDataEntry = createSelector(
  getAccountType,
  getReferenceAccountTypes,
  (accountType, referenceAccountTypes) => {
    return referenceAccountTypes.find((ref) => ref.code === accountType.value);
  }
);
