import axios from 'axios';
import { setResponse, requestFail } from '../../../actions/digitalOnboarding/routing';
import { getApplicationNumber, getApplicationOutcome } from '../../../selectors/digitalOnboarding/application';
import { FILE_UPLOAD_COMPLETE, FILE_API_UPLOAD_FAILED, FINANCE_API_URL } from '../../../actions/digitalOnboarding/types';
import { setUploadFilesError } from '../../../actions/digitalOnboarding/documentStore';

export const uploadDocuments = async (store, next, action) => {
    const storeData = store.getState();
    const result = next(action);

    (async () => {
        try {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            const fileQueue = storeData.digitalOnboarding.documentStore.fileQueue;
            const url = `${FINANCE_API_URL}/applications/${applicationNumber}/documents`;

            const files = await fileQueue.reduce(async (f, x) => {
                return f.then(async last =>{
                    let encrypted = false;
                    let hasError = false;
                    if(x.file !== null){
                        const formData = generateFormDate(x.file);
                        await axios.post(`${url}/${x.key}`, formData).catch(error =>{
                            if(error.response.data.status === 422){
                                encrypted = true;
                            }else if(error.response.data.status === 400){
                                hasError = true
                            }else{
                                throw error;
                            }
                        });
                    }

                    last.push({ key: x.key, encrypted, hasError });

                    return last;
                });
            },Promise.resolve([]));

            if(files.some(x => x.encrypted || x.hasError)){
                store.dispatch(setUploadFilesError(files));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "uploadDocuments",
                    "eventOutcome": "FILE_UPLOAD_ERROR",
                    "page": window.location.pathname.split('/')[3]
                });
                return result;
            }else{
                store.dispatch(setResponse(FILE_UPLOAD_COMPLETE, null));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "uploadDocuments",
                    "eventOutcome": "FILE_UPLOAD_COMPLETE",
                    "page": window.location.pathname.split('/')[3]
                });
            }
        } catch (e) {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            store.dispatch(requestFail(FILE_API_UPLOAD_FAILED));
            dataLayer.push({
                "app": "omflending",
                "applicationNumber": applicationNumber,
                "applicationOutcome": applicationOutcome,
                "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                "event": "uploadDocuments",
                "eventOutcome": "FILE_API_UPLOAD_FAILED",
                "page": window.location.pathname.split('/')[3]
            });
        }
    })();
};

const generateFormDate = (fileUploadObj) =>{
    const formData = new FormData();
    formData.append('file',fileUploadObj);
    return formData;
}