import { BIASHARA_FLEXI_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/biasharaFlexi/agentModal';
import { BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE } from '../../../actions/biasharaFlexi/companyDetails';
import { BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE } from '../../../actions/biasharaFlexi/coverDetails';
import { BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE } from '../../../actions/biasharaFlexi/employeeDetails';
import { PAYMENTS_SET_VALUE } from '../../../actions/biasharaFlexi/payment';
import { BIASHARA_FLEXI_FORM_SET_VALUE, SET_SHARE_EMAIL_VALUE } from '../../../actions/biasharaFlexi/quote';
import { BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/biasharaFlexi/uploadDocuments';
import { agentValidation } from './agent';
import { companyDetailsValidation } from './companyDetails';
import { coverDetailsValidation } from './coverDetails';
import { employeeDetailsValidation } from './employeeDetails';
import { paymentsFormValidation } from './payment';
import { shareQuoteValidation } from './shareQuote';
import { uploadDocumentsValidation } from './uploadDocuments';

export const biasharaFlexiValidation = (store, next, action) => {

    if(action.type.startsWith(PAYMENTS_SET_VALUE)){
		return paymentsFormValidation(store, next , action)
	}

    if (action.type.startsWith(BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALUE)) {
        return uploadDocumentsValidation(store, next, action);
    }

    if (action.type.startsWith(BIASHARA_FLEXI_AGENT_MODAL_DETAILS_SET_VALUE)) {
        return agentValidation(store, next, action);
    }

    if (action.type.startsWith(BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE)) {
        return coverDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE)) {
        return companyDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE)) {
        return employeeDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(BIASHARA_FLEXI_FORM_SET_VALUE)) {
		return shareQuoteValidation(store, next, action);
	}
    return null;
};
