import { validationIsEqual } from '../status';
import { validateBase64File } from '@om/validation';
import { BIRTH_CERTIFICATE_SET_VALUE, ID_UPLOAD_SET_VALUE, MARRIAGE_CERTIFICATE_SET_VALUE, setBirthCertificateValid, setIdUploadValid, setMarriageCertificateValid } from '../../../actions/healthInsuranceRW/Documents';

let prevValidationAction = null;
export const documentsUploadValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ID_UPLOAD_SET_VALUE:
                error = validateBase64File(action.value.base64File, {
                    subject: 'ID',
                    required: true,
                });
                return setIdUploadValid(error);
            case MARRIAGE_CERTIFICATE_SET_VALUE:
                error = validateBase64File(action.value.base64File, {
                    subject: 'Marriage Certificate',
                    required: true,
                });
                return setMarriageCertificateValid(error);
            case BIRTH_CERTIFICATE_SET_VALUE:
                error = validateBase64File(action.value.base64File, {
                    subject: 'Birth Certificate',
                    required: true,
                });
                return setBirthCertificateValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
