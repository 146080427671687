export const LIVES_COVERED_PERSONAL_DETAILS_FORM = 'livesCovered/personalDetailsBeneficiariesForm/';

export const LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM + 'setValue';
export const LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID = LIVES_COVERED_PERSONAL_DETAILS_FORM + 'setValid';

export const FORM_VALID_LIVES_COVERED_PERSONAL_DETAILS_FORM = 'livesCovered/personalDetailsBeneficiariesForm/transition/formValid';
export const FORM_INVALID_LIVES_COVERED_PERSONAL_DETAILS_FORM = 'livesCovered/personalDetailsBeneficiariesForm/transition/formInValid';

export const REINITIALIZE = 'livesCovered/personalDetailsBeneficiariesForm/state/reinitialize';
export const REPOPULATE = 'livesCovered/personalDetailsBeneficiariesForm/state/repopulate';

export const transitionLivesCoveredPersonalDetails = {
    formValid: () => ({
        type: FORM_VALID_LIVES_COVERED_PERSONAL_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_LIVES_COVERED_PERSONAL_DETAILS_FORM,
    }),
};

export const FIRST_NAME_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "firstName";
export const FIRST_NAME_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "firstName";

export const SURNAME_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "surname";
export const SURNAME_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "surname";

export const DOB_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "dob";
export const DOB_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "dob";

export const EMAIL_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "email";
export const EMAIL_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "email";

export const MOBILE_NUMBER_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "mobileNumber";
export const MOBILE_NUMBER_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "mobileNumber";

export const ID_PASSPORT_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "idPassport";
export const ID_PASSPORT_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "idPassport";

export const TIN_NUMBER_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "tinNumber";
export const TIN_NUMBER_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "tinNumber";

export const CITY_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "city";
export const CITY_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "city";

export const STREET_ADDRESS_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "streetAddress";
export const STREET_ADDRESS_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "streetAddress";

export const BUILDING_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "building";
export const BUILDING_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "building";

export const PO_BOX_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "postalBox";
export const PO_BOX_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "postalBox";

export const POSTAL_CODE_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "postalCode";
export const POSTAL_CODE_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "postalCode";

export const RELATIONSHIP_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "relationship";
export const RELATIONSHIP_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "relationship";

export const BENEFICIARIES_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "beneficiaries";

export const SPLIT_PERCENTAGE_SET_VALUE = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE + "splitPercentage";
export const SPLIT_PERCENTAGE_SET_VALID = LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID + "splitPercentage";
// Action Creators

export const setFirstNameValue = value => ({
    type: FIRST_NAME_SET_VALUE,
    value
})

export const setFirstNameValid = error => ({
    type: FIRST_NAME_SET_VALID,
    error
})


export const setSurnameValue = value => ({
    type: SURNAME_SET_VALUE,
    value
})

export const setSurnameValid = error => ({
    type: SURNAME_SET_VALID,
    error
})


export const setDobValue = value => ({
    type: DOB_SET_VALUE,
    value
})

export const setDobValid = error => ({
    type: DOB_SET_VALID,
    error
})

export const setEmailValue = value => ({
    type: EMAIL_SET_VALUE,
    value
})

export const setEmailValid = error => ({
    type: EMAIL_SET_VALID,
    error
})

export const setMobileNumberValue = value => ({
    type: MOBILE_NUMBER_SET_VALUE,
    value
})

export const setMobileNumberValid = error => ({
    type: MOBILE_NUMBER_SET_VALID,
    error
})

export const setIdPassportValue = value => ({
    type: ID_PASSPORT_SET_VALUE,
    value
})

export const setIdPassportValid = error => ({
    type: ID_PASSPORT_SET_VALID,
    error
})

export const setTinNumberValue = value => ({
    type: TIN_NUMBER_SET_VALUE,
    value
})

export const setTinNumberValid = error => ({
    type: TIN_NUMBER_SET_VALID,
    error
})

export const setCityValue = value => ({
    type: CITY_SET_VALUE,
    value
})

export const setCityValid = error => ({
    type: CITY_SET_VALID,
    error
})

export const setStreetAddressValue = value => ({
    type: STREET_ADDRESS_SET_VALUE,
    value
})

export const setStreetAddressValid = error => ({
    type: STREET_ADDRESS_SET_VALID,
    error
})

export const setBuildingValue = value => ({
    type: BUILDING_SET_VALUE,
    value,
})

export const setBuildingValid = error => ({
    type: BUILDING_SET_VALID,
    error,
})

export const setPostalBoxValue = value => ({
    type: PO_BOX_SET_VALUE,
    value,
})

export const setPostalBoxValid = error => ({
    type: PO_BOX_SET_VALID,
    error
})

export const setPostalCodeValue = value => ({
    type: POSTAL_CODE_SET_VALUE,
    value,
});

export const setPostalCodeValid = error => ({
    type: POSTAL_CODE_SET_VALID,
    error
})

export const setRelationshipValue = value => ({
    type: RELATIONSHIP_SET_VALUE,
    value,
});

export const setRelationshipValid = error => ({
    type: RELATIONSHIP_SET_VALID,
    error
})

export const setBeneficiaries = value => ({
    type: BENEFICIARIES_SET_VALUE,
    value
})

export const setSplitPercentageValue = value => ({
    type: SPLIT_PERCENTAGE_SET_VALUE,
    value,
});

export const setSplitPercentageValid = error => ({
    type: SPLIT_PERCENTAGE_SET_VALID,
    error
})
export const reinitiarizeBeneficiariesForm = () => ({
    type: REINITIALIZE,
})

export const repopulateForm = (value) => ({
    type: REPOPULATE,
    value
})
