import {
    validateFirstName,
    validateSurname,
    validateDateOfBirth,
    validateEmail,
    validateCellphoneNumber,
    validateSingleSelection,
} from  "@om/validation";
import {
    DATE_OF_BIRTH_SET_VALUE,
    EMAIL_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    GENDER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    SURNAME_SET_VALUE,
    TITLE_SET_VALUE,
    setTitleValid,
    setFirstNameValid,
    setSurnameValid,
    setGenderValid,
    setMobileValid,
    setEmailValid,
    setDateOfBirthValid,
} from "../../../actions/raOptimal/beneficiaries";
import {
    getEmail,
    getMobileNumber
} from "../../../selectors/raOptimal/beneficiaries";

export const beneficiariesValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, 'Title');
                return setTitleValid(error);
            case FIRST_NAME_SET_VALUE:
                error = validateFirstName(action.value, {pronoun: 'their'});
                return setFirstNameValid(error);
            case SURNAME_SET_VALUE:
                error = validateSurname(action.value, {pronoun: 'their'});
                return setSurnameValid(error);
            case DATE_OF_BIRTH_SET_VALUE:
                error = validateDateOfBirth(action.value, {pronoun: 'their'});
                return setDateOfBirthValid(error);
            case GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, 'Gender');
                return setGenderValid(error);

            case MOBILE_NUMBER_SET_VALUE:
                if (!getEmail(store.getState()).value && !action.value) {
                    error = validateCellphoneNumber(action.value, {required: true, subject: 'one of these two fields'});
                } else {
                    error = validateCellphoneNumber(action.value, {required: false, pronoun: 'their'});
                }
                return setMobileValid(error);

            case EMAIL_SET_VALUE:
                if (!getMobileNumber(store.getState()).value && !action.value) {
                    error = validateEmail(action.value, {required: true, subject: 'one of these two fields'});
                } else {
                    error = validateEmail(action.value, {required: false, pronoun: 'their'});
                }
                return setEmailValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
