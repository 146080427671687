import { save2getherRules } from './save2gether';
import { callMeBackFormRules } from './callMeBackForm';
import { dividendCalculatorRules } from './dividendCalculator';
import { unclaimedBenefitsFormRules } from './unclaimedBenefits';
import { qualifyingCheckCallMeBackFormRules } from "./qualifyingCheckCallMeBack";
import { funeralClaimRules } from './funeralClaim';
import { funeralClaimV2Rules } from './funeralClaimV2';
import {asisaUnclaimedBenefitsFormRules} from './asisaUnclaimedBenefits';

import {priceGraphRules} from './priceGraph';

import { SAVE2GETHER_SET_VALID, SAVE2GETHER_SET_VALUE } from '../../../actions/publicWeb/save2gether';
import {
    UNCLAIMED_BENEFITS_SET_VALUE,
    UNCLAIMED_BENEFITS_SET_VALID
} from '../../../actions/publicWeb/unclaimedBenefits';
import {
    CALL_ME_BACK_OBJECT_DETAILS,
    CALL_ME_BACK_SET_VALUE,
    CALL_ME_BACK_SET_VALID,
    FORM_SUCCESS_TRANSITION_CALL_ME_BACK,
} from '../../../actions/publicWeb/callMeBack';
import {
    DIVIDEND_CALC_SHARES_SET_VALUE,
    DIVIDEND_CALC_SHARES_SET_VALID,
    DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE,
} from '../../../actions/publicWeb/dividendCalculator';
import {
    QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE,
    QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID,
    TOGGLE_CREDIT_CHECK_CHECKBOX,
} from "../../../actions/publicWeb/qualifyingCheckCallMeBack";

import {
    FUNERAL_CLAIM_SET_VALUE,
    FUNERAL_CLAIM_SET_VALID,
    FUNERAL_CLAIM_TOGGLE,
} from '../../../actions/publicWeb/funeralClaim';

import { 
    FUNERAL_CLAIM_V2_SET_VALID, 
    FUNERAL_CLAIM_V2_SET_VALUE, 
    FUNERAL_CLAIM_V2_TOGGLE } from '../../../actions/publicWeb/funeralClaimV2';

import {
    PRICE_GRAPH_SET_VALUE,
    PRICE_GRAPH_SET_VALID
} from '../../../actions/publicWeb/priceGraph'

import {
    ASISA_UNCLAIMED_BENEFITS_SET_VALUE,
    ASISA_UNCLAIMED_BENEFITS_SET_VALID,
    ASISA_UNCLAIMED_BENEFITS_FORM_TOGGLE
} from '../../../actions/publicWeb/asisaUnclaimedBenefits'


export const publicWebRules = (store, next, action) => {
    // Save2gether
    if (action.type.includes(SAVE2GETHER_SET_VALID) ||
        action.type.includes(SAVE2GETHER_SET_VALUE))
        return save2getherRules(store, next, action);

    // Call me back
    if (action.type === CALL_ME_BACK_OBJECT_DETAILS ||
        action.type.includes(CALL_ME_BACK_SET_VALUE) ||
        action.type.includes(CALL_ME_BACK_SET_VALID) ||
        action.type.includes(FORM_SUCCESS_TRANSITION_CALL_ME_BACK))
        return callMeBackFormRules(store, next, action);

    // Dividend Calculator
    if (action.type === DIVIDEND_CALC_SHARES_SET_VALUE ||
        action.type === DIVIDEND_CALC_SHARES_SET_VALID ||
        action.type === DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE)
        return dividendCalculatorRules(store, next, action);

    // Unclaimed benefits
    if (action.type.startsWith(UNCLAIMED_BENEFITS_SET_VALUE) ||
        action.type.startsWith(UNCLAIMED_BENEFITS_SET_VALID))
        return unclaimedBenefitsFormRules(store, next, action);

    // Funeral Claim
    if (action.type.startsWith(FUNERAL_CLAIM_SET_VALUE) ||
        action.type.startsWith(FUNERAL_CLAIM_SET_VALID) ||
        action.type.startsWith(FUNERAL_CLAIM_TOGGLE))
        return funeralClaimRules(store, next, action);

    // Funeral Claim V2
    if (action.type.startsWith(FUNERAL_CLAIM_V2_SET_VALUE) ||
        action.type.startsWith(FUNERAL_CLAIM_V2_SET_VALID) ||
        action.type.startsWith(FUNERAL_CLAIM_V2_TOGGLE))
        return funeralClaimV2Rules(store, next, action);

    if (action.type.startsWith(PRICE_GRAPH_SET_VALUE) ||
        action.type.startsWith(PRICE_GRAPH_SET_VALID))
        return priceGraphRules(store, next, action);   
        
    // Qualifying Check Call Me Back
    if (action.type.startsWith(QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE) ||
        action.type.startsWith(QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID) ||
        action.type === TOGGLE_CREDIT_CHECK_CHECKBOX) {
        return qualifyingCheckCallMeBackFormRules(store, next, action);
    }

    if(action.type.startsWith(ASISA_UNCLAIMED_BENEFITS_SET_VALUE) ||
       action.type.startsWith(ASISA_UNCLAIMED_BENEFITS_SET_VALID) ||
       action.type.startsWith(ASISA_UNCLAIMED_BENEFITS_FORM_TOGGLE)){
           return asisaUnclaimedBenefitsFormRules(store, next, action);
       }

    return null;
};