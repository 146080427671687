import { getDeathTermQuoteToolForm } from '../../../selectors/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import { transitionDeathTermCoverToolForm } from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import validate from '../../utils/formValidators';

export const deathTermQuoteToolFormRules = (store, next, action) => {
    const accidentalDeathCoverQuoteToolForm = getDeathTermQuoteToolForm(
        store.getState()
    );
    const isFormValid = validate(accidentalDeathCoverQuoteToolForm, [
        'candidateDOB',
        'coverAmount',
        'numberOfYearsCovered',
    ]);
    const formAction = isFormValid
        ? transitionDeathTermCoverToolForm.formValid()
        : transitionDeathTermCoverToolForm.formInvalid();

    store.dispatch(formAction);
    return next(action);
};
