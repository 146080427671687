import { transitionScrolling } from '../../../actions/fundCentre/scrolling';
import {
    setActiveFilterOption,
    SHOWHIDE_FILTER_CARD_SET_VALUE,
    CLEAR_FILTER_SELECTION_SET_VALUE
} from '../../../actions/fundCentre/fundTable';

export const fundTableRules = (store, next, action) => {
    const state = store.getState();
    const result = next(action);

    if (action.type === SHOWHIDE_FILTER_CARD_SET_VALUE && state.fundCentre.fundTable.showHideFilterCard === true) {
        store.dispatch(transitionScrolling.scrollToClass('breadcrumbs-link-list-wrapper'));
    } else if (action.type === SHOWHIDE_FILTER_CARD_SET_VALUE && state.fundCentre.fundTable.showHideFilterCard === false) {
        store.dispatch(transitionScrolling.scrollToClass(''));
    } else if (action.type === CLEAR_FILTER_SELECTION_SET_VALUE) {
        store.dispatch(setActiveFilterOption('riskProfiles'));
    }
    return result;
}
