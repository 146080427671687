import {
    TITLE_SET_VALUE,
    MIDDLE_NAME_SET_VALUE,
    EMAIL_SET_VALUE,
    MARITAL_STATUS_SET_VALUE,
    COUNTRY_OF_BIRTH_SET_VALUE,
    NATIONALITY_SET_VALUE,
    ID_SET_VALUE,
    INCOME_TAX_SET_VALUE,
    COMPLEX_SET_VALUE,
    STREET_ADDRESS_SET_VALUE,
    SUBURB_SET_VALUE,
    CITY_SET_VALUE,
    POSTAL_CODE_SET_VALUE,
    COUNTRY_SET_VALUE,
    POSTAL_ADDRESS_SAME_VALUE,
    POSTAL_ADDRESS_METHOD_VALUE,
    POSTAL_COMPLEX_SET_VALUE,
    POSTAL_STREET_ADDRESS_SET_VALUE,
    POSTAL_SUBURB_SET_VALUE,
    POSTAL_CITY_SET_VALUE,
    POSTAL_POSTAL_CODE_SET_VALUE,
    POSTAL_COUNTRY_SET_VALUE,
    PO_BOX_SET_VALUE,
    PO_POST_OFFICE_SET_VALUE,
    PO_POSTAL_CODE_SET_VALUE,
    PO_COUNTRY_SET_VALUE,
    PRIVATE_BAG_SET_VALUE,
    PRIVATE_POST_OFFICE_SET_VALUE,
    PRIVATE_POSTAL_CODE_SET_VALUE,
    PRIVATE_COUNTRY_SET_VALUE,
    INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS,
    CONTINUE_TRANSITION_PERSONAL_DETAILS,
    BACK_TRANSITION_PERSONAL_DETAIL,
} from "../../../actions/raOptimal/personalDetails"
import {
    NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    MOBILE_SET_VALUE,
} from "../../../actions/raOptimal/captureDetails";
import {
    getTitle,
    getName,
    getMiddleName,
    getSurname,
    getMobile,
    getEmail,
    getMaritalStatus,
    getCountryOfBirth,
    getNationality,
    getId,
    getIncomeTax,
    getComplex,
    getStreetAddress,
    getSuburb,
    getCity,
    getCountry,
    getPostalCode,
    getPostalAddressSame,
    getPostalAddressMethod,
    getPostalComplex,
    getPostalStreetAddress,
    getPostalSuburb,
    getPostalCity,
    getPostalPostalCode,
    getPostalCountry,
    getPoBox,
    getPoPostOffice,
    getPoPostalCode,
    getPoCountry,
    getPrivateBag,
    getPrivatePostOffice,
    getPrivatePostalCode,
    getPrivateCountry,
    getPersonalDetailsForm
} from "../../../selectors/raOptimal/personalDetails"
import {simpleFieldTracking} from "../formTracking";
import {transitionTracking} from "../transitionTracking";
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";

export const personalDetailsFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case TITLE_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getTitle;
            case NAME_SET_VALUE:
                sendValue = false;
                return getName;
            case MIDDLE_NAME_SET_VALUE:
                sendValue = false;
                return getMiddleName;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurname;
            case MOBILE_SET_VALUE:
                sendValue = false;
                return getMobile;
            case EMAIL_SET_VALUE:
                sendValue = false;
                return getEmail;
            case MARITAL_STATUS_SET_VALUE:
                sendError = false;
                return getMaritalStatus;
            case COUNTRY_OF_BIRTH_SET_VALUE:
                sendError = false;
                return getCountryOfBirth;
            case NATIONALITY_SET_VALUE:
                sendError = false;
                return getNationality;
            case ID_SET_VALUE:
                sendValue = false;
                return getId;
            case INCOME_TAX_SET_VALUE:
                sendValue = false;
                return getIncomeTax;
            case COMPLEX_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getComplex;
            case STREET_ADDRESS_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getStreetAddress;
            case SUBURB_SET_VALUE:
                sendValue = false;
                return getSuburb;
            case CITY_SET_VALUE:
                sendValue = false;
                return getCity;
            case COUNTRY_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getCountry;
            case POSTAL_CODE_SET_VALUE:
                sendValue = false;
                return getPostalCode;
            case POSTAL_ADDRESS_SAME_VALUE:
                sendValue = false;
                sendError = false;
                return getPostalAddressSame;
            case POSTAL_ADDRESS_METHOD_VALUE:
                sendValue = false;
                sendError = false;
                return getPostalAddressMethod;
            case POSTAL_COMPLEX_SET_VALUE:
                sendValue = false;
                sendError = false;
                if(!(getPostalAddressSame(currentState).value))
                    return getPostalComplex;
                return;
            case POSTAL_STREET_ADDRESS_SET_VALUE:
                sendValue = false;
                sendError = false;
                if(!(getPostalAddressSame(currentState).value))
                    return getPostalStreetAddress;
                return;
            case POSTAL_SUBURB_SET_VALUE:
                sendValue = false;
                if(!(getPostalAddressSame(currentState).value))
                    return getPostalSuburb;
                return;
            case POSTAL_CITY_SET_VALUE:
                sendValue = false;
                if(!(getPostalAddressSame(currentState).value))
                    return getPostalCity;
                return;
            case POSTAL_POSTAL_CODE_SET_VALUE:
                sendValue = false;
                if(!(getPostalAddressSame(currentState).value))
                    return getPostalPostalCode;
                return;
            case POSTAL_COUNTRY_SET_VALUE:
                sendValue = false;
                sendError = false;
                if(!(getPostalAddressSame(currentState).value))
                    return getPostalCountry;
                return;
            case PO_BOX_SET_VALUE:
                sendValue = false;
                return getPoBox;
            case PO_POST_OFFICE_SET_VALUE:
                sendValue = false;
                return getPoPostOffice;
            case PO_POSTAL_CODE_SET_VALUE:
                sendValue = false;
                return getPoPostalCode;
            case PO_COUNTRY_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getPoCountry;
            case PRIVATE_BAG_SET_VALUE:
                sendValue = false;
                return getPrivateBag;
            case PRIVATE_POST_OFFICE_SET_VALUE:
                sendValue = false;
                return getPrivatePostOffice;
            case PRIVATE_POSTAL_CODE_SET_VALUE:
                sendValue = false;
                return getPrivatePostalCode;
            case PRIVATE_COUNTRY_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getPrivateCountry;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector)

    if (field) {
        simpleFieldTracking({action, field, sendValue, sendError});
    }

    return result;
};

export const personalDetailsTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const trackingActions = [
        INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS,
        CONTINUE_TRANSITION_PERSONAL_DETAILS,
        BACK_TRANSITION_PERSONAL_DETAIL,
    ];
    const personalDetailsForm = getPersonalDetailsForm(state);
    // capture relevant tracking data -- i.e. remove value from form fields
    const trackingData = Object.keys(personalDetailsForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: personalDetailsForm[key].status,
                error: personalDetailsForm[key].error,
            }
        }), {});

    if (trackingActions.includes(action.type))
        transitionTracking(action.type, trackingData);

    return result;
}
