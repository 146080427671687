import { combineReducers } from 'redux';
import routing from './routing';
import agent from './agent';
import coverDetails from './coverDetails';
import companyDetails from './companyDetails';
import auth from './auth';
import employeeDetails from './employeeDetails';
import uploadDocuments from './uploadDocuments';
import payments from './payment';
import payloadCollection from './payloadCollection';

export const biasharaFlexi = combineReducers({
	routing,
	agent,
	coverDetails,
	companyDetails,
	auth,
	employeeDetails,
	uploadDocuments,
	payments,
	payloadCollection
});
