import { transitionMotorInsuranceNextOfKinForm } from '../../../actions/ugMotorInsurance/nextOfKin';
import { getNextOfKinForm } from '../../../selectors/ugMotorInsurance/nextOfKin';

import { FIELD_STATUS } from '../../../reducers/status';

export const nextOfKinFormRules = (store, next, action) => {
    const result = next(action);
    const nextOfKinForm = getNextOfKinForm(store.getState());
    const nextOfKinFormValid = validateNextOfKinFormDetails(nextOfKinForm);
    const formAction = nextOfKinFormValid
        ? transitionMotorInsuranceNextOfKinForm.formValid()
        : transitionMotorInsuranceNextOfKinForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateNextOfKinFormDetails = (formFields) => {
    const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

    const checks =
        formFields.relationship &&
        formFields.relationship.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.postalAddress &&
        formFields.postalAddress.status === FIELD_STATUS.VALID;
    if (gatsbyStack === 'ke') {
        return checks && formFields.idNumber && formFields.idNumber.status === FIELD_STATUS.VALID;
    }

    return checks;
};
