import { getActivePageIndex, isApiFetchInProgress } from '@om/redux/selectors/digitalOnboarding/routing';
import { moveToPreviousPage, setCanProceed } from "../../../actions/digitalOnboarding/routing";
import { MOVE_TO_NEXT_PAGE, MOVE_TO_PREVIOUS_PAGE, STORE_PERSONAL_DETAIL, STORE_EMPLOYMENT_DETAIL, STORE_AFFORDABILITY_DETAIL, STORE_EMPLOYMENT_DETAIL_VALIDATION, STORE_AFFORDABILITY_DETAIL_VALIDATION, PAGE_INDEXES, PageIndexRef, STORE_OTP_VALUE, LOOKUP_DATA_SUCCESS, STORE_ADDRESS_DETAIL, STORE_ADDRESS_DETAIL_VALIDATION, MOVE_TO_FAILURE_PAGE, SKIP_TO_PAGE, FILE_UPLOAD_VALIDATION } from '../../../actions/digitalOnboarding/types';

const validateRoute = storeValues => {
    return Object.keys(storeValues).every((x) => {
        return storeValues[x].isValid === true
    });
};

const shouldAllowPage = state => state.quoteAvailable === undefined || state.quoteAvailable;
const shouldApplyValidation = index => PAGE_INDEXES[index].requiredValues.length > 0;

const pageIndexToStore = state => {
    switch (getActivePageIndex(state)) {
        case PageIndexRef.PERSONAL_DETAILS_PAGE: return state.digitalOnboarding.personalDetails;
        case PageIndexRef.EMPLOYMENT_DETAILS_PAGE: return state.digitalOnboarding.employmentDetails;
        case PageIndexRef.AFFORDABILITY_DETAILS_PAGE: return state.digitalOnboarding.affordabilityDetails;
        case PageIndexRef.QUOTE_PAGE: return state.digitalOnboarding.quoteDetails;
        case PageIndexRef.OTP_PAGE: return state.digitalOnboarding.otp;
        case PageIndexRef.ADDRESS_DETAILS_PAGE: return state.digitalOnboarding.addressDetails;
        case PageIndexRef.DOCUMENTS_PAGE: return state.digitalOnboarding.documentStore;
    }
    return { storeValues: {} };
}

export const routingValidation = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    switch (action.type) {
        case MOVE_TO_PREVIOUS_PAGE:
            if (!shouldAllowPage(pageIndexToStore(state))) {
                store.dispatch(moveToPreviousPage());
                break;
            }
        case MOVE_TO_NEXT_PAGE:
        case LOOKUP_DATA_SUCCESS:
        case MOVE_TO_FAILURE_PAGE:
        case SKIP_TO_PAGE:
            if (!shouldApplyValidation(getActivePageIndex(state))) {
                store.dispatch(setCanProceed(true));
                break;
            }
        case STORE_AFFORDABILITY_DETAIL_VALIDATION:
        case STORE_EMPLOYMENT_DETAIL_VALIDATION:
        case STORE_ADDRESS_DETAIL_VALIDATION:
        case STORE_PERSONAL_DETAIL:
        case STORE_EMPLOYMENT_DETAIL:
        case STORE_AFFORDABILITY_DETAIL:
        case STORE_OTP_VALUE:
        case STORE_ADDRESS_DETAIL: {
            // If there is a api fetch happening then the validation and any stores
            // have been sent already so don't apply proceed code to that store

            if (!isApiFetchInProgress(state)) {
                const valuesValid = validateRoute(pageIndexToStore(state).storeValues);
                store.dispatch(setCanProceed(valuesValid));
            }
            break;
        }
        case FILE_UPLOAD_VALIDATION: {
            const fileQueue = pageIndexToStore(state).fileQueue;
            const anyFileError = fileQueue.some(x => x.hasError);
            store.dispatch(setCanProceed(!anyFileError));
        }
        default:
            break;
    }
    return result;
}