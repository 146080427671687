import { getQuoteForm, getPremiumCalculationUg } from '../../../selectors/ugMotorInsurance/quote';
import { getPersonalDetailsForm } from '../../../selectors/ugMotorInsurance/personalDetails';
import { getEmploymentDetailsForm } from '../../../selectors/ugMotorInsurance/employmentDetails';
import { getCarDetailsForm } from '../../../selectors/ugMotorInsurance/carDetails';
import { getDocumentsForm } from '../../../selectors/ugMotorInsurance/documents';
import { getDeclarationsForm } from '../../../selectors/ugMotorInsurance/declarations';
import { getNextOfKinForm } from '../../../selectors/ugMotorInsurance/nextOfKin';
import { getPaymentMode } from '../../../selectors/ugMotorInsurance/payments';
import { getIsApiPending } from '../../../selectors/ugMotorInsurance/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/ugMotorInsurance/payloadCollection';
import { getAgentForm } from '../../../selectors/ugMotorInsurance/travelAgentModal';
import { getAccessToken, getPaymentStatusData } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';
import { setBankDetailsSuccess, triggerConfirmOrder } from '../../../actions/ugMotorInsurance/payments';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

const trimBase64Prefix = (base64String) => {
    const commaIndex = base64String.indexOf(',');

    // Check if the comma is found in the base64 string
    if (commaIndex !== -1) {
        // If found, remove everything before the comma
        return base64String.substring(commaIndex + 1);
    } else {
        // If not found, return the original string
        return base64String;
    }
};

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await calculate(store.getState());

                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());

                const modeOfPayment = getPaymentMode(store.getState());

                if (modeOfPayment === 'Flexipay') {
                    store.dispatch(triggerConfirmOrder());
                } else {
                    store.dispatch(setBankDetailsSuccess(true));
                }
            } catch (e) {
                console.error(e);
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

const payloadCollection = (state) => {
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const employmentDetails = getEmploymentDetailsForm(state);
    const carDetails = getCarDetailsForm(state);
    const documents = getDocumentsForm(state);
    const declarations = getDeclarationsForm(state);
    const nextOfKin = getNextOfKinForm(state);
    const agent = getAgentForm(state);
    const premium = getPremiumCalculationUg(state);

    const paymentMode = getPaymentMode(state);

    const paymentsData = getPaymentStatusData(state);
    const premiumCalculationPayload = {
        countryCode: gatsbyStack.toUpperCase(),
        make: parseInt(quotation.carMake.value.id) || parseInt(quotation.carModel.value.id),
        model: parseInt(quotation.carModel.value.id),
        yearOfManufacture: quotation.yearOfManufacture.value,
        carValue: quotation.valuation.value || 0,
        violenceAndTerrorismCovered: quotation.violenceAndTerrorism.value,
        uncommonRisksCovered: quotation.uncommonRisk.value,
        excessProtectorOption: quotation.excessProtector.value,
        use: quotation.carUse.value,
        valuationDone: quotation.isCarValued.value === 'yes' ? true : false,
        alarmInstalled: quotation.hasAlarm.value === 'yes' ? true : false,
        annualPremium: premium.value.premium || premium.value.totalPremium,
        basePremiumRate: premium.value.basePremiumRate || 0,
        valuedBy: quotation.carValuer.value || 'N/A',
        coverStartDate: quotation.coverStartDate.value,
        excessProtectorFees: premium.value.excessProtectorFees || 0,
        uncommonRisksCoveredFees: premium.value.uncommonRisksCoveredFees || 0,
        violenceAndTerrorismFees: premium.value.violenceAndTerrorismFees || 0,
        trainingLevies: premium.value.trainingLevy || 0,
        vatCharge: premium.value.vatCharge || 0,
        fullName: personalDetails.middleName.value
            ? `${personalDetails.firstName.value} ${personalDetails.middleName.value} ${personalDetails.lastName.value}`
            : `${personalDetails.firstName.value} ${personalDetails.lastName.value}`,
        withinUG: quotation.outsideEa.value === 'within-ug' ? true : false,
        withinEA: quotation.outsideEa.value === 'within-ea' ? true : false,
        outsideEA: quotation.outsideEa.value === 'outside-ea' ? true : false,
    };

    const personalDetailsPayload = {
        firstName: personalDetails.firstName.value,
        lastName: personalDetails.lastName.value,
        emailAddress: personalDetails.email.value,
        dateOfBirth: personalDetails.dob.value,
        idNumber: personalDetails.idNumber.value,
        nationality: personalDetails.nationality.value,
        phoneNumber: personalDetails.phoneNumber.value,
        tinNumber: personalDetails.tinNumber.value,
        postalAddress: personalDetails.postalAddress.value,
        gender: personalDetails.gender.value,
        occupation:
            gatsbyStack === 'ke' ? personalDetails.occupation.value : employmentDetails.occupation.value || 'N/A',
        personalDataProcessingConsent: employmentDetails.personalDataProcessingConsent.value || false,
        childDataProcessingConsent: employmentDetails.childDataProcessingConsent?.value || false,
        commercialUseConsent: employmentDetails.marketingUseConsent?.value || false,
        maritalStatus: personalDetails.maritalStatus?.value || 'N/A',
        residentialStatus: personalDetails.residentialStatus?.value || 'N/A',
        townOrCity: personalDetails.townOrCity?.value || 'N/A',
        employmentStatus: employmentDetails.employmentStatus?.value || 'N/A',
        employerName: employmentDetails.employerName?.value || 'N/A',
        schemeName: employmentDetails.schemeName?.value || 'N/A',
        industryOrSector: employmentDetails.industryOrSector?.value || 'N/A',
        contractEndDate: employmentDetails.contractEndDate?.value || 'N/A',
        employmentNumber: employmentDetails.employmentNumber?.value || 'N/A',
        salaryRange: employmentDetails.salaryRange?.value || 'N/A',
        employerPostalAddress: employmentDetails.employerPostalAddress?.value || 'N/A',
        employerTownOrCity: employmentDetails.employerTownOrCity?.value || 'N/A',
        employerPhoneNumber: employmentDetails.employerPhoneNumber?.value || 'N/A',
    };

    const carDetailsPayload = {
        registrationNumber: carDetails.registrationNumber.value,
        chassisNumber: carDetails.chasisNumber.value,
        manufacturerModel: carDetails.manufacturerModel?.value || 'N/A',
        fuel: carDetails.fuel.value,
        bodyType: carDetails.bodyType.value,
        yearsWithLicense: carDetails.yearsWithLicense.value,
        sittingCapacity: carDetails.sittingCapacity.value,
        color: carDetails.color.value,
    };

    const documentsPayload = {
        nationalId: trimBase64Prefix(documents.idUpload.value.base64File),
        drivingLicense: trimBase64Prefix(documents.licenseUpload.value.base64File),
        logBook: trimBase64Prefix(documents.logbookUpload.value.base64File),
        inspectionReport: trimBase64Prefix(documents.valuationReportUpload.value.base64File),
        inspectionPhoto: trimBase64Prefix(documents.inspectionPhoto.value.base64File),
    };

    const declarationsPayload = {
        proposalDeclinedBefore: declarations.declineProposal.value,
        specialPremiumBefore: declarations.increasedPremiums.value,
        declinedForRenewalBefore: declarations.policyRejected.value,
        convictionHistory: declarations.convicted.value,
        healthIssues: declarations.defectVision.value,
        stickerCollectionPoint: declarations.stickerCollection.value || 'N/A',
        modeOfPayment: paymentMode.toUpperCase().trim(),
        sourceOfFunds: declarations.sourceOfFunds?.value || 'N/A',
    };

    const nextOfKinPayload = {
        //nextOfKinFirstName: nextOfKin.firstName.value,
        //nextOfKinLastName: nextOfKin.lastName.value,
        nextOfKinIdNumber: nextOfKin.idNumber.value || 'NA',
        nextOfKinEmail: nextOfKin.email.value || 'NA',
        nextOfKinRelationship: nextOfKin.relationship.value || 'NA',
        nextOfKinPostalAddress: nextOfKin.postalAddress.value || 'NA',
        nextOfKinNames: `${nextOfKin?.firstName?.value || 'NA'} ${nextOfKin?.lastName?.value || 'NA'}`,
    };

    const agentPayload = {
        agentCode: gatsbyStack === 'ke' ? agent.name?.value?.agentCode || 'N/A' : agent.agentCode?.value || 'N/A',
        agentName: gatsbyStack === 'ke' ? agent.name?.value?.name || 'N/A' : agent.name?.value || 'N/A',
        referredByAgent: gatsbyStack === 'ke' && agent.name?.value?.name ? true : agent.name?.value ? true : false,
    };

    const paymentPayload = {
        // remove hard coded value
        transactionRef: paymentsData?.CheckoutRequestID,
    };

    const regionMap = {
        'within-ea': 2,
        'outside-ea': 3,
    };

    let region = regionMap[quotation.outsideEa.value] || 1;

    const additionalFields = {
        idCardNumber: personalDetails.idCardNumber.value || 'N/A',
        alternativeAccomodation: quotation.alternativeAccomodation.value
            ? parseInt(quotation.alternativeAccomodationDays.value)
            : 0,
        firstApplication: quotation.firstTimePurchase.value,
        carHire: quotation.carHire.value ? parseInt(quotation.carHireDays.value) : 0,
        alarmDiscount: quotation.hasAlarm.value,
        trackerDiscount: quotation.trackingSystem.value,
        pvt: true,
        region,
        excessValue: quotation.excessAmount.value,
    };

    // const consentData = {
    //     personalDataProcessingConsent: true,
    //     childDataProcessingConsent: true,
    //     commercialUseConsent: true,
    //     employmentStatus: 'N/A',
    //     sourceOfFunds: 'N/A',
    //     maritalStatus: 'N/A',
    //     residentialStatus: 'N/A',
    //     townOrCity: 'N/A',
    //     manufacturerModel: 'N/A',
    // };

    return {
        ...premiumCalculationPayload,
        ...personalDetailsPayload,
        ...carDetailsPayload,
        ...documentsPayload,
        ...declarationsPayload,
        ...nextOfKinPayload,
        ...agentPayload,
        ...paymentPayload,
        ...additionalFields,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ug-motor-insurance/submit-payload/uganda';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
