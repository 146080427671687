import { createSelector } from 'reselect';
import { getRWMotorPrivateInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getQuote = createSelector(getRWMotorPrivateInsuranceState, (state) => {
    return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculationUg = createSelector(getQuote, (quote) => quote && quote.premiumCalculationUg);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.SUCCESS
);
export const getQuoteFormApiCallFailure = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.FAILURE
);
export const getIsApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.PENDING
);
export const getIsShareQuoteApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.shareQuoteAPIStatus === FORM_STATUS.PENDING
);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);
export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus);
export const getUgCmbObject = createSelector(getQuote, (quote) => quote && quote.ugCmbObject);
const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getIsExpiredAuth = createSelector(getQuote, (quote) => quote && quote.isTokenExpired);
export const getIsMakesApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.carMakesApiStatus === API_CALL_STATUS.PENDING
);
export const getIsModelsApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.carModelsApiStatus === API_CALL_STATUS.PENDING
);

export const getIsVehicleTypesApiPending = createSelector(getQuote, (quote) => quote && quote.vehicleTypesApiStatus === API_CALL_STATUS.PENDING);
export const getIsOccupantLimitsApiPending = createSelector(getQuote, (quote) => quote && quote.occupantLimitsApiStatus === API_CALL_STATUS.PENDING);

export const getIsMakesApiStatus = createSelector(getQuote, (quote) => quote && quote.carMakesApiStatus);
export const getIsModelsApiStatus = createSelector(getQuote, (quote) => quote && quote.carModelsApiStatus);
export const getIsVehicleTypesApiStatus = createSelector(getQuote, (quote) => quote && quote.vehicleTypesApiStatus);
export const getIsOccupantLimitsApiStatus = createSelector(getQuote, (quote) => quote && quote.occupantLimitsApiStatus);
export const getIsValuersApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.valuersApiCallStatus === API_CALL_STATUS.PENDING
);
export const getValuers = createSelector(getQuote, (quote) => quote && quote.valuers);

export const getFullNameValue = makeGetFormField('fullName');
export const getCarMakeValue = makeGetFormField('carMake');
export const getCarModelValue = makeGetFormField('carModel');
export const getYearOfManufactureValue = makeGetFormField('yearOfManufacture');
export const getSeatsNumberValue = makeGetFormField('seatsNumber');
export const getCoveredNumberValue = makeGetFormField('coveredNumber');
export const getSumInsured = makeGetFormField('sumInsured');
export const getCarCostValue = makeGetFormField('carValue');
export const getCarUseValue = makeGetFormField('carUse');
export const getCarReachValue = makeGetFormField('carReach');
export const getShareEmailValue = makeGetFormField('shareEmail');
export const getSelectedCover = makeGetFormField('selectedCover');
export const getVehicleTypeValue = makeGetFormField('vehicleType');
export const getCarMakesValue = makeGetFormField('carMakes');
export const getCarModelsValue = makeGetFormField('carModels');
export const getVehicleTypesValue = makeGetFormField('vehicleTypes') ;
export const getOccupantLimitsValue = makeGetFormField('occupantLimits');
export const getInsurancePaymentPlan = makeGetFormField('insurancePaymentPlan');
