export const UG_MOTOR_INSURANCE_DOCUMENTS_FORM = 'ugMotorInsurance/documentsForm/';

export const MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE = UG_MOTOR_INSURANCE_DOCUMENTS_FORM + 'setValue/';
export const MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID = UG_MOTOR_INSURANCE_DOCUMENTS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM = 'ugMotorInsurance/documentsForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM = 'ugMotorInsurance/documentsForm/transition/formInValid';

export const REINITIALIZE = 'ugMotorInsurance/documentsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM = 'ugMotorInsurance/documentsForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM,
    value,
});

export const transitionMotorInsuranceDocumentsForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_DOCUMENTS_FORM = `${MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DOCUMENTS_FORM = `${MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DOCUMENTS_FORM = `${MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDocumentsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_DOCUMENTS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_DOCUMENTS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_DOCUMENTS_FORM,
    }),
};

//calculated Data
export const COLLECT_DOCUMENTS_FORM_DATA = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DOCUMENTS_SUCCESS = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'success';
export const SUBMIT_DOCUMENTS_FAILURE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
    type: COLLECT_DOCUMENTS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
    type: SUBMIT_DOCUMENTS_SUCCESS,
    value,
});
export const submitDocumentsFailure = (error) => ({
    type: SUBMIT_DOCUMENTS_FAILURE,
    error,
});

// Form Values
export const ID_UPLOAD_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'idUpload';
export const ID_UPLOAD_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'idUpload';

export const LICENSE_UPLOAD_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'licenseUpload';
export const LICENSE_UPLOAD_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'licenseUpload';

export const LOG_BOOK_UPLOAD_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'logBookUpload';
export const LOG_BOOK_UPLOAD_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'logBookUpload';

export const VALUATION_REPORT_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'valuationReport';
export const VALUATION_REPORT_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'valuationReport';

export const INSPECTION_PHOTO_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'inspectionPhoto';
export const INSPECTION_PHOTO_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'inspectionPhoto';

// reset individual form value
export const ID_UPLOAD_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/idUpload';
export const LICENSE_UPLOAD_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/licenseUpload';
export const LOG_BOOK_UPLOAD_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/logBookUpload';
export const VALUATION_REPORT_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/valuationReport';
export const INSPECTION_PHOTO_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/inspectionPhoto';

// ID upload action creators
export const setIdUploadValue = (value) => ({
    type: ID_UPLOAD_SET_VALUE,
    value,
});

export const setIdUploadValid = (error) => ({
    type: ID_UPLOAD_SET_VALID,
    error,
});

export const resetIdUploadValue = () => ({
    type: ID_UPLOAD_RESET_VALUE,
});

// License upload action creators
export const setLicenseUploadValue = (value) => ({
    type: LICENSE_UPLOAD_SET_VALUE,
    value,
});

export const setLicenseUploadValid = (error) => ({
    type: LICENSE_UPLOAD_SET_VALID,
    error,
});

export const resetLisenceUploadValue = () => ({
    type: LICENSE_UPLOAD_RESET_VALUE,
});

// Logbook upload action creators
export const setLogBookUploadValue = (value) => ({
    type: LOG_BOOK_UPLOAD_SET_VALUE,
    value,
});

export const setLogBookUploadValid = (error) => ({
    type: LOG_BOOK_UPLOAD_SET_VALID,
    error,
});

export const resetLogBookUploadValue = () => ({
    type: LOG_BOOK_UPLOAD_RESET_VALUE,
});

// Inspection Photo action creators
export const setInspectionPhotoValue = (value) => ({
    type: INSPECTION_PHOTO_SET_VALUE,
    value,
});

export const setInspectionPhotoValid = (error) => ({
    type: INSPECTION_PHOTO_SET_VALID,
    error,
});

export const resetInspectionPhotoValue = () => ({
    type: INSPECTION_PHOTO_RESET_VALUE,
});

// Inspection Photo action creators
export const setValuationReportUploadValue = (value) => ({
    type: VALUATION_REPORT_SET_VALUE,
    value,
});

export const setValuationReportUploadValid = (error) => ({
    type: VALUATION_REPORT_SET_VALID,
    error,
});

export const resetValuationReportUploadValue = () => ({
    type: VALUATION_REPORT_RESET_VALUE,
});
