export const HEALTH_INSURANCE_RW_LOGIN_DETAILS = 'healthInsuranceRw/loginDetails/';
export const COLLECT_LOGIN_DETAILS = HEALTH_INSURANCE_RW_LOGIN_DETAILS + 'collectLoginDetails/';
export const LOGIN_SUCCESS = HEALTH_INSURANCE_RW_LOGIN_DETAILS + 'loginSuccess/';
export const LOGIN_FAILURE = HEALTH_INSURANCE_RW_LOGIN_DETAILS + 'loginFailure/';
export const API_PENDING_LOGIN = HEALTH_INSURANCE_RW_LOGIN_DETAILS + 'apiPendingLogin/';
export const API_SUCCESS_LOGIN = HEALTH_INSURANCE_RW_LOGIN_DETAILS + 'apiSuccessLogin/';
export const API_FAILURE_LOGIN = HEALTH_INSURANCE_RW_LOGIN_DETAILS + 'apiFailureLogin/';

export const collectLoginDetails = () => ({
    type: COLLECT_LOGIN_DETAILS,
});

export const loginSuccess = (data) => ({
    type: LOGIN_SUCCESS,
    data,
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    error,
});

export const apiPendingLogin = () => ({
    type: API_PENDING_LOGIN,
});

export const apiSuccessLogin = () => ({
    type: API_SUCCESS_LOGIN,
});

export const apiFailureLogin = () => ({
    type: API_FAILURE_LOGIN,
});

export const apiTransitionLogin = {
    pending: apiPendingLogin,
    success: apiSuccessLogin,
    failure: apiFailureLogin,
};

