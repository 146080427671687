import {FIELD_STATUS, FORM_STATUS, API_CALL_STATUS} from '../status';
import {
    TOGGLE_CALL_ME_BACK,
    FORM_VALID_TRANSITION_CALL_ME_BACK,
    FORM_INVALID_TRANSITION_CALL_ME_BACK,
    NAME_SET_VALID,
    NAME_SET_VALUE,
    SURNAME_SET_VALID,
    SURNAME_SET_VALUE,
    CONTACT_NUMBER_SET_VALID,
    CONTACT_NUMBER_SET_VALUE,
    PRODUCT_NAME_SET_VALUE,
    GTM_CONTEXT_SET_VALUE,
    CALL_ME_BACK_SEND,
    CALL_ME_BACK_SEND_SUCCESS,
    CALL_ME_BACK_SEND_FAILURE,
    RESET_STATE_TRANSITION_CALL_ME_BACK,
    REDIRECT_URL_SET_VALUE,
    SET_SOURCE_VALUES_FROM_PARENT_APP,
} from "../../actions/customerVerificationService/callMeBack";

const initParentAppSource = () => ({
    redirectUrl: null,
    productName: null,
    sourceId: null,
    campaignCode: null,
});
export const initCallMeBack = {
    formStatus: FORM_STATUS.INVALID,
    cmbStatus: API_CALL_STATUS.IDLE,
    openDrawer: false,
    gtmContextUrl: null,
    gtmContextGaId: null,
    form: {
        name: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        contactNumber: { value: null, error: null, status: null },
    },
    parentAppSource: initParentAppSource(),
};

export default (state = initCallMeBack, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRANSITION_CALL_ME_BACK:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_CALL_ME_BACK:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case TOGGLE_CALL_ME_BACK:
            return {
                ...state,
                openDrawer: !state.openDrawer ? true : false
            };

        case NAME_SET_VALUE:
            return updateFormField('name', field => ({
                ...field,
                value: action.value,
            }));
        case NAME_SET_VALID:
            return updateFormField('name', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SURNAME_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: action.value,
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CONTACT_NUMBER_SET_VALUE:
            return updateFormField('contactNumber', field => ({
                ...field,
                value: action.value,
            }));
        case CONTACT_NUMBER_SET_VALID:
            return updateFormField('contactNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CALL_ME_BACK_SEND:
            return { ...state, cmbStatus: API_CALL_STATUS.PENDING };
        case CALL_ME_BACK_SEND_SUCCESS:
            return { ...state, cmbStatus: API_CALL_STATUS.SUCCESS };
        case CALL_ME_BACK_SEND_FAILURE:
            return {  ...state, cmbStatus: API_CALL_STATUS.FAILURE };

        // cmb configuration
        case GTM_CONTEXT_SET_VALUE:
            return { 
                ...state, 
                gtmContextUrl: action.value.url,
                gtmContextGaId: action.value.gaId
            };
        case PRODUCT_NAME_SET_VALUE:
            return { ...state, productName: action.value };
        case REDIRECT_URL_SET_VALUE:
            return { ...state, redirectUrl: action.value };

        case RESET_STATE_TRANSITION_CALL_ME_BACK:
            return { ...initCallMeBack };

        case SET_SOURCE_VALUES_FROM_PARENT_APP:
            const source = action.callMeBackSource;
            return {
                ...state,
                parentAppSource: !source
                    ? initParentAppSource()
                    : {
                        redirectUrl: source.redirectUrl,
                        productName: source.productName,
                        sourceId: source.sourceId,
                        campaignCode: source.campaignCode,
                    },
            };

        default:
            return state;
    }
}
