import { validationIsEqual } from "../status";
import {
  validateAlphaNumericString,
  validateStreetWithNumber,
  validateEmail,
  validateName,
  validateContactNumber,
  validateSingleSelection,
  validateDate,
  validateNumber,
  validateLoanTerm,
  validatePassportNumber,
  validatePostalAddress,
  validateAlphaString,
} from "@om/validation";
import {
  CITY_SET_VALUE,
  COUNTRY_SET_VALUE,
  EMAIL_SET_VALUE,
  ID_SET_VALUE,
  POSTAL_ADDRESS_METHOD_VALUE,
  POSTAL_ADDRESS_SAME_VALUE,
  POSTAL_CITY_SET_VALUE,
  POSTAL_COUNTRY_SET_VALUE,
  POSTAL_STREET_ADDRESS_SET_VALUE,
  STREET_ADDRESS_SET_VALUE,
  TITLE_SET_VALUE,
  FIRST_NAMES_SET_VALUE,
  SURNAME_SET_VALUE,
  MOBILE_SET_VALUE,
  setCityValid,
  setCountryValid,
  setEmailValid,
  setIdValid,
  setPostalAddressMethodValid,
  setPostalAddressSameValid,
  setPostalCityValid,
  setPostalCountryValid,
  setPostalStreetAddressValid,
  setStreetAddressValid,
  setTitleValid,
  setFirstNamesValid,
  setSurnameValid,
  setMobileValid,
  ID_EXPIRY_SET_VALUE,
  setIdExpiryValid,
  LOAN_AMOUNT_SET_VALUE,
  setLoanAmountValid,
  LOAN_TYPE_SET_VALUE,
  setLoanTypeValid,
  BANK_SET_VALUE,
  setBankValid,
  LOAN_TERM_SET_VALUE,
  setLoanTermValid,
  OCCUPATION_SET_VALUE,
  setOccupationValid,
  INCOME_SOURCE_SET_VALUE,
  setIncomeSourceValid,
  PAYMENT_FREQUENCY_SET_VALUE,
  setPaymentFrequencyValid,
  EDUCATION_SET_VALUE,
  setEducationValid,
  setEmploymentTypeValid,
  GENDER_SET_VALUE,
  setGenderValid,
  EMOPLOYMENT_TYPE_SET_VALUE,
  POSTAL_DISTRICT_SET_VALUE,
  DISTRICT_SET_VALUE,
  setPostalDistrictValid,
  setDistrictValid,
  INCOME_SOURCE_TEXT_SET_VALUE,
  setIncomeSourceTextValid
} from "../../../actions/digiLifeLoanProtection/personalDetails";
import { validateBwIdNumber } from "@om/validation/src/validators/bw-id-number-validator";

let prevValidationAction = null;
export const personalDetailsValidation = (store, next, action) => {
  const {personalDetails}=store.getState().digiLifeLoanProtection
  const validationAction = (() => {
    const state=store.getState().roaQuoteTools.digiLifeQuoteTool.form
    const trimValue = (value) => (value ? value.trim() : value);

    const removeWhiteSpacesFromValue = (value) =>
      value ? value.replace(/ /g, "") : value;

    let error;
    switch (action.type) {
      // Personal details
      case TITLE_SET_VALUE:
        error = validateSingleSelection(action.value.value, "Title");
        return setTitleValid(error);

      case FIRST_NAMES_SET_VALUE:
        //value gets trimmed here for validation purposes, displayValue(untrimmed) is used in the front end,  if else condition is to account for undefined value when running setNameValue without a param.
        error = validateName(trimValue(action.value), {
          min: 2,
          max: 25,
          subject: "First name",
        });
        return setFirstNamesValid(error);

      case SURNAME_SET_VALUE:
        //value gets trimmed here for validation purposes, displayValue(untrimmed) is used in the front end,  if else condition is to account for undefined value when running setSurnameValue without a param.
        error = validateName(trimValue(action.value), {
          min: 3,
          max: 40,
          subject: "Surname",
        });
        return setSurnameValid(error);

      case GENDER_SET_VALUE:
        error = validateSingleSelection(action.value, "Gender");
        return setGenderValid(error);

      case ID_SET_VALUE:
        error =
          personalDetails.form.idType.value == "01"
            ? validateBwIdNumber(trimValue(action.value), {
                required: true,
                subject: "Botswana ID number",
                gender: state.gender.value == "01" ? "Male" : "Female",
              })
            : validatePassportNumber(trimValue(action.value), {
                required: true,
                subject: "Passport",
              });
        return setIdValid(error);

      case ID_EXPIRY_SET_VALUE:
        error = validateDate(action.value, {
          subject: "ID Expiry Date",
          required: true,
        });
        return setIdExpiryValid(error);

      case EDUCATION_SET_VALUE:
        error = validateSingleSelection(action.value.value, "education");
        return setEducationValid(error);

      // Contact details
      case MOBILE_SET_VALUE:
        error = validateContactNumber(
          removeWhiteSpacesFromValue(action.value),
          { countryCode: "bw",leadingZeroValidation:false }
        );
        return setMobileValid(error);

      case EMAIL_SET_VALUE:
        error = validateEmail(trimValue(action.value), {
          min: 10,
          max: 60,
          subject: "Email address",
          required: true,
        });
        return setEmailValid(error);

      // Address details
      case STREET_ADDRESS_SET_VALUE:
        error = validateStreetWithNumber(action.value, {
          min: 10,
          max: 75,
          subject: "Street address",
          required: true,
        });
        return setStreetAddressValid(error);

      case CITY_SET_VALUE:
        error = validateName(action.value, {
          min: 4,
          max: 75,
          subject: "City",
        });
        return setCityValid(error);
      case DISTRICT_SET_VALUE:
        error = validateSingleSelection(action.value.value, "district")
        return setDistrictValid(error);

      case COUNTRY_SET_VALUE:
        error = validateSingleSelection(action.value, "country");
        return setCountryValid(error);

      case POSTAL_ADDRESS_METHOD_VALUE:
        error = validateSingleSelection(action.value, "option");
        return setPostalAddressMethodValid(error);

      case POSTAL_ADDRESS_SAME_VALUE:
        error = validateSingleSelection(action.value, "option");
        return setPostalAddressSameValid(error);

      // Postal method - Street Address

      case POSTAL_STREET_ADDRESS_SET_VALUE:
        error = validatePostalAddress(action.value, {
          min: 10,
          max: 75,
          subject: "Postal address",
        });
        return setPostalStreetAddressValid(error);

      case POSTAL_CITY_SET_VALUE:
        error = validateName(action.value, {
          min: 4,
          max: 75,
          subject: "Postal City",
        });
        return setPostalCityValid(error);
      case POSTAL_DISTRICT_SET_VALUE:
        error = validateSingleSelection(action.value.value, "district");
        return setPostalDistrictValid(error);

      case POSTAL_COUNTRY_SET_VALUE:
        error = validateSingleSelection(action.value, "country");
        return setPostalCountryValid(error);

      // Loan Details

      case LOAN_AMOUNT_SET_VALUE:
        error = validateNumber(action.value, {
          min: 1000,
          max: 1000000,
          subject: "Price",
        });
        return setLoanAmountValid(error);

      case LOAN_TYPE_SET_VALUE:
        error = validateSingleSelection(action.value.value, "Loan Type");
        return setLoanTypeValid(error);

      case BANK_SET_VALUE:
        error = validateSingleSelection(action.value.value, "Bank");
        return setBankValid(error);

      case LOAN_TERM_SET_VALUE:
        error = validateNumber(action.value, {
          min: 12,
          max: 1000,
          subject: "Loan Term",
        });
        return setLoanTermValid(error);
      // employment Details
      case EMOPLOYMENT_TYPE_SET_VALUE:
        error = validateSingleSelection(action.value.value, "Employment Type");
        return setEmploymentTypeValid(error);

      case OCCUPATION_SET_VALUE:
        error = validateAlphaNumericString(action.value, {
          min: 2,
          max: 25,
          subject: "Occupation",
        });
        return setOccupationValid(error);

      case INCOME_SOURCE_SET_VALUE:
        error = validateSingleSelection(action.value.value, "Source Of Income");
        return setIncomeSourceValid(error);

      case INCOME_SOURCE_TEXT_SET_VALUE:
        error = validateAlphaNumericString(action.value, {
          min: 2,
          max: 25,
          subject: "Source of income",
        });
        return setIncomeSourceTextValid(error);

      case PAYMENT_FREQUENCY_SET_VALUE:
        error = validateSingleSelection(action.value.value, "Bank");
        return setPaymentFrequencyValid(error);

      default:
        return null;
    }
  })();

  if (
    !validationAction ||
    validationIsEqual(validationAction, prevValidationAction)
  ) {
    return next(action);
  }

  store.dispatch(validationAction);
  prevValidationAction = validationAction;
  return next(action);
};
