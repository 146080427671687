import { transitionDigitalSavingsDocumentsForm } from '../../../actions/digitalSavings/Documents';
import { getDocumentsForm } from '../../../selectors/digitalSavings/documents';

import { FIELD_STATUS } from '../../../reducers/status';

export const documentsFormRules = (store, next, action) => {
    const result = next(action);
    const documentsForm = getDocumentsForm(store.getState());
    const documentsFormValid = validateDocumentsFormDetails(documentsForm);
    const formAction = documentsFormValid
        ? transitionDigitalSavingsDocumentsForm.formValid()
        : transitionDigitalSavingsDocumentsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateDocumentsFormDetails = (formFields) => {
    return (
        formFields.idUpload &&
        formFields.idUpload.status === FIELD_STATUS.VALID &&
        formFields.kraPinUpload &&
        formFields.kraPinUpload.status === FIELD_STATUS.VALID
    );
};
