import {
    NAVIGATE_TO_REPLACEMENT_POLICY,
    NAVIGATE_TO_PERSONAL_DETAILS,
    NAVIGATE_TO_LIVES_COVERED,
    NAVIGATE_TO_BENEFICIARIES,
    NAVIGATE_TO_BENEFICIARIES_SPLIT,
    NAVIGATE_TO_PAYMENT_DETAILS,
    NAVIGATE_TO_CONFIRMATION,
    NAVIGATE_TO_THANK_YOU,
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE, NAVIGATE_INDEX
} from "../../actions/easiplusFuneralPlan/routing";
import {APP_NOT_INITIATED, PAGE_INDICES} from "../../actions/easiplusFuneralPlan/types";

export const initRouting = {
    activePageIndex: APP_NOT_INITIATED,
    doNavigation: false,
    doExitNavigation: false,
    parentAppUrl: null,
    callbackAction: null,
    parentApp: null
}

export default (state = initRouting, action) => {
    const setActivePage = pageIndex => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch(action.type){
        case NAVIGATE_INDEX:
            return {
                ...state,
                activePageIndex: action.value,
            };

        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };

        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };

        case NAVIGATE_TO_REPLACEMENT_POLICY: 
            return setActivePage(PAGE_INDICES.REPLACEMENT_POLICY);

        case NAVIGATE_TO_PERSONAL_DETAILS: 
            return setActivePage(PAGE_INDICES.PERSONAL_DETAILS);

        case NAVIGATE_TO_LIVES_COVERED: 
            return setActivePage(PAGE_INDICES.LIVES_COVERED);

        case NAVIGATE_TO_BENEFICIARIES: 
            return setActivePage(PAGE_INDICES.BENEFICIARIES);

        case NAVIGATE_TO_BENEFICIARIES_SPLIT: 
            return setActivePage(PAGE_INDICES.BENEFICIARIES_SPLIT);

        case NAVIGATE_TO_PAYMENT_DETAILS: 
            return setActivePage(PAGE_INDICES.PAYMENT_DETAILS);
            
        case NAVIGATE_TO_CONFIRMATION: 
            return setActivePage(PAGE_INDICES.CONFIRMATION);

        case NAVIGATE_TO_THANK_YOU: 
            return setActivePage(PAGE_INDICES.THANK_YOU);

        // TODO: not enabled as CVS is not implemented in EFP
        // case SET_NAVIGATION_TO_CVS_FROM_PARENT_APP:
        //     return {
        //         ...state,
        //         parentAppUrl: action.parentAppUrl,
        //         callbackAction: action.callbackAction,
        //         doExitNavigation: false,
        //         parentApp: action.parentApp
        //     };
        // case SET_NAVIGATION_BACK_TO_PARENT_APP:
        //     return {
        //         ...initRouting,
        //         doExitNavigation: true,
        //         parentAppUrl: state.parentAppUrl,
        //     };

        default:
            return state;
    }
};
