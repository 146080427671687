import { FIELD_STATUS, FORM_STATUS } from '../status';
import * as aboutYouActions from '../../actions/roaCarInsurance/aboutYou';

export const initAboutYou = {
    formStatus: FORM_STATUS.INVALID,
	dropOffDetails: null,
    form: {
        firstName: { value: null, error: null, status: null },
        lastName: { value: null, error: null, status: null },
        emailAddress: { value: null, error: null, status: null },
        phoneNumber: { value: null, error: null, status: null },
        stateOfOrigin: { value: null, error: null, status: null },
        localGovernmentArea: { value: null, error: null, status: null },
        residentialAddress: { value: null, error: null, status: null },
        agentCode: { value: null, error: null, status: null },
    },
};

export default (state = initAboutYou, action) => {
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case aboutYouActions.FORM_VALID_ABOUT_YOU:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case aboutYouActions.FORM_INVALID_ABOUT_YOU:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case aboutYouActions.FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));
        case aboutYouActions.FIRST_NAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case aboutYouActions.LAST_NAME_SET_VALUE:
            return updateFormField('lastName', (field) => ({
                ...field,
                value: action.value,
            }));
        case aboutYouActions.LAST_NAME_SET_VALID:
            return updateFormField('lastName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case aboutYouActions.EMAIL_ADDRESS_SET_VALUE:
            return updateFormField('emailAddress', (field) => ({
                ...field,
                value: action.value,
            }));
        case aboutYouActions.EMAIL_ADDRESS_SET_VALID:
            return updateFormField('emailAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case aboutYouActions.PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case aboutYouActions.PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

		case aboutYouActions.STATE_OF_ORIGIN_SET_VALUE:
			return updateFormField('stateOfOrigin', (field) => ({
				...field,
				value: action.value,
			}));
		case aboutYouActions.STATE_OF_ORIGIN_SET_VALID:
			return updateFormField('stateOfOrigin', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case aboutYouActions.LOCAL_AREA_SET_VALUE:
			return updateFormField('localGovernmentArea', (field) => ({
				...field,
				value: action.value,
			}));
		case aboutYouActions.LOCAL_AREA_SET_VALID:
			return updateFormField('localGovernmentArea', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case aboutYouActions.RESIDENTIAL_AREA_SET_VALUE:
			return updateFormField('residentialAddress', (field) => ({
				...field,
				value: action.value,
			}));
		case aboutYouActions.RESIDENTIAL_AREA_SET_VALID:
			return updateFormField('residentialAddress', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case aboutYouActions.AGENT_CODE_SET_VALUE:
			return updateFormField('agentCode', (field) => ({
				...field,
				value: action.value,
			}));
		case aboutYouActions.AGENT_CODE_SET_VALID:
			return updateFormField('agentCode', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
        default:
            return state;
    }
};
