import {
    HOW_MUCH_OWED_SET_VALUE,
    INTEREST_RATE_SET_VALUE,
    MONTHLY_REPAYMENT_SET_VALUE,
    setHowMuchOwedValid,
    setInterestRateValid,
    setMonthlyRepaymentValid
} from '../../../actions/debtRepaymentCalculators/debtRepaymentCalculator';
import {validateNumber} from '@om/validation';
import {an_pmt_pv} from '@om/tvm-calcs';


export const debtRepaymentCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
        case HOW_MUCH_OWED_SET_VALUE:
            error = validateNumber(action.value.howMuchOwed.value, {
                subject: 'repayment amount',
                decimalPoints: 2
            })
            if (error === null) {
                error = runHowMuchOwedValidation(error, action, store);
            }
            return setHowMuchOwedValid(error);
        case INTEREST_RATE_SET_VALUE:
            error = null;
            error = validateNumber(action.value.interestRate.value, {subject: 'interest rate', decimalPoints: 2})
            if (error === null) {
                error = runInterestRateValidation(error, action, store);
            }
            return setInterestRateValid(error);
        case MONTHLY_REPAYMENT_SET_VALUE:
            error = null;
            error = validateNumber(action.value.monthlyRepayment.value, {
                subject: 'monthly repayment',
                decimalPoints: 0
            })
            if (error === null) {
                error = runMonthlyRepaymentValidation(error, action);
            }
            return setMonthlyRepaymentValid(error);
        default:
            return null;
        }
    })();

    store.dispatch(validationAction);
    return next(action);
}

function runHowMuchOwedValidation(error, action, store) {
    // how much owed actions
    if (action.value.howMuchOwed.value < 1000) {
        store.dispatch(setMonthlyRepaymentValid(null));
        return 'Please enter an amount you owe that is greater than R1000.00';
    } else if (action.value.howMuchOwed.value > 10000000) {
        store.dispatch(setMonthlyRepaymentValid(null));
        return 'Please enter an amount you owe that is less than or equal to R10 000 000.00';
    }
    store.dispatch(setMonthlyRepaymentValid(runMonthlyRepaymentValidation(error, action)));
}

function runInterestRateValidation(error, action, store) {
    // interest rate actions
    if (action.value.interestRate.value < 1) {
        store.dispatch(setMonthlyRepaymentValid(null));
        return 'Oops, you need to enter an annual interest rate larger than 1%.';
    } else if (action.value.interestRate.value > 30) {
        store.dispatch(setMonthlyRepaymentValid(null));
        return 'Oops, you need to enter an annual interest rate smaller than or equal to 30%.';
    }
    store.dispatch(setMonthlyRepaymentValid(runMonthlyRepaymentValidation(error, action)));
}

function runMonthlyRepaymentValidation(error, action) {
    // monthly repayment actions
    let minimumMonthly = minimumMonthlyRepayment(action.value.interestRate.value, action.value.howMuchOwed.value);
    if (parseFloat(action.value.monthlyRepayment.value) > parseFloat(action.value.howMuchOwed.value)) {
        return 'Oops, you need to enter a monthly repayment amount less than the amount owed';
    }
    if (action.value.monthlyRepayment.value <= minimumMonthly) {
        return 'Oops, you need to enter a monthly repayment amount greater than ' + minimumMonthly;
    }
    if (action.value.monthlyRepayment.value > minimumMonthly) {
        return null;
    }
}

function minimumMonthlyRepayment(interestRate, howMuchOwed) {
    return Math.round(an_pmt_pv((interestRate / 100) / 12, 12 * 50, howMuchOwed));
}