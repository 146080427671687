import { PAGE_INDICES } from '../../actions/roaCarInsurance/types';
import {
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
	NAVIGATE_TO_QUOTATION,
	NAVIGATE_TO_ABOUT_YOU,
	NAVIGATE_TO_PRODUCTS,
	NAVIGATE_TO_DETAILS,
	NAVIGATE_TO_PAYMENT,
	SET_LANDING_PAGE,
	NAVIGATE_TO_LANDING_PAGE
} from '../../actions/roaCarInsurance/routing';

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
	landingPage: null
};

export default (state = initRouting, action) => {
    const setActivePage = (pageIndex) => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };

        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };
		case NAVIGATE_TO_QUOTATION:
			return setActivePage(PAGE_INDICES.QUOTATION)
		case NAVIGATE_TO_ABOUT_YOU:
			return setActivePage(PAGE_INDICES.ABOUT_YOU)
		case NAVIGATE_TO_PRODUCTS:
			return setActivePage(PAGE_INDICES.PRODUCTS)
		case NAVIGATE_TO_DETAILS:
			return setActivePage(PAGE_INDICES.DETAILS)
		case NAVIGATE_TO_PAYMENT:
			return setActivePage(PAGE_INDICES.PAYMENT)
		case SET_LANDING_PAGE:
			return { ...state, landingPage: action.value };
		case NAVIGATE_TO_LANDING_PAGE:
			return setActivePage(PAGE_INDICES.LANDING_PAGE);
        default:
        	return state
    }
};
