import {
    OCCUPATION_SET_VALID,
    OCCUPATION_SET_VALUE,
    GENDER_SET_VALID,
    GENDER_SET_VALUE,
    FIRST_NAME_SET_VALID,
    FIRST_NAME_SET_VALUE,
    EMAIL_SET_VALID,
    EMAIL_SET_VALUE,
    KRA_PIN_NUMBER_SET_VALID,
    KRA_PIN_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALID,
    MOBILE_NUMBER_SET_VALUE,
    NATIONALITY_SET_VALID,
    NATIONALITY_SET_VALUE,
    SOURCE_OF_FUNDS_SET_VALID,
    SOURCE_OF_FUNDS_SET_VALUE,
    REINITIALIZE,
    FULL_ADDRESS_SET_VALID,
    FULL_ADDRESS_SET_VALUE,
    FORM_VALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM,
    FORM_INVALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM,
    SURNAME_SET_VALUE,
    SURNAME_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    ID_PASSPORT_SET_VALUE,
    ID_PASSPORT_SET_VALID,
} from '../../actions/digitalSavings/personalDetails';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initPersonalDetails = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        firstName: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        dob: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        mobileNumber: { value: null, error: null, status: null },
        idPassport: { value: null, error: null, status: null },
        kraPinNumber: { value: null, error: null, status: null },
        gender: { value: null, error: null, status: null },
        fullAddress: { value: null, error: null, status: null },
        nationality: { value: null, error: null, status: null },
        sourceOfFunds: { value: null, error: null, status: null },
        occupation: { value: null, error: null, status: null },
    },
};

export default (state = initPersonalDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));

        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SURNAME_SET_VALUE:
            return updateFormField('surname', (field) => ({
                ...field,
                value: action.value,
            }));

        case SURNAME_SET_VALID:
            return updateFormField('surname', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DOB_SET_VALUE:
            return updateFormField('dob', (field) => ({
                ...field,
                value: action.value,
            }));

        case DOB_SET_VALID:
            return updateFormField('dob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MOBILE_NUMBER_SET_VALUE:
            return updateFormField('mobileNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case MOBILE_NUMBER_SET_VALID:
            return updateFormField('mobileNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_PASSPORT_SET_VALUE:
            return updateFormField('idPassport', (field) => ({
                ...field,
                value: action.value,
            }));
        case ID_PASSPORT_SET_VALID:
            return updateFormField('idPassport', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('email', (field) => ({
                ...field,
                value: action.value,
            }));
        case EMAIL_SET_VALID:
            return updateFormField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case KRA_PIN_NUMBER_SET_VALUE:
            return updateFormField('kraPinNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case KRA_PIN_NUMBER_SET_VALID:
            return updateFormField('kraPinNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case GENDER_SET_VALUE:
            console.log(action)
            return updateFormField('gender', (field) => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('gender', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FULL_ADDRESS_SET_VALUE:
            return updateFormField('fullAddress', (field) => ({
                ...field,
                value: action.value,
            }));
        case FULL_ADDRESS_SET_VALID:
            return updateFormField('fullAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case OCCUPATION_SET_VALUE:
            return updateFormField('occupation', (field) => ({
                ...field,
                value: action.value,
            }));
        case OCCUPATION_SET_VALID:
            return updateFormField('occupation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NATIONALITY_SET_VALUE:
            return updateFormField('nationality', (field) => ({
                ...field,
                value: action.value,
            }));
        case NATIONALITY_SET_VALID:
            return updateFormField('nationality', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SOURCE_OF_FUNDS_SET_VALUE:
            return updateFormField('sourceOfFunds', (field) => ({
                ...field,
                value: action.value,
            }));
        case SOURCE_OF_FUNDS_SET_VALID:
            return updateFormField('sourceOfFunds', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case REINITIALIZE:
            return initBiasharaFlexiCover;
        default:
            return state;
    }
};
