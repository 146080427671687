// General routing events
export const ROUTING_SET_VALUE = 'taxFreeSavingsAccount/routing/setValue/';

export const NAVIGATE_PREVIOUS = ROUTING_SET_VALUE + 'navigatePrevious';
export const NAVIGATE_NEXT = ROUTING_SET_VALUE + 'navigateNext';
export const NAVIGATE_INDEX = ROUTING_SET_VALUE + 'navigateIndex';
export const SET_NAVIGATION_DONE = ROUTING_SET_VALUE + 'navigationDone';
export const SET_LANDING_PAGE = ROUTING_SET_VALUE + 'landingPage';

export const navigatePrevious = () => ({ type: NAVIGATE_PREVIOUS });
export const navigateNext = () => ({ type: NAVIGATE_NEXT });
export const navigateIndex = value => ({ type: NAVIGATE_INDEX, value });
export const setNavigationDone = () => ({ type: SET_NAVIGATION_DONE });
export const setLandingPage = value => ({ type: SET_LANDING_PAGE, value });

// TODO: add cvs if and when greenlit
// export const SET_NAVIGATION_BACK_TO_PARENT_APP = ROUTING_SET_VALUE + 'navigateBackToParentApp';
// export const SET_NAVIGATION_TO_CVS_FROM_PARENT_APP = ROUTING_SET_VALUE + 'navigateToCVSFromParentApp';
// export const navigateBackToParentApp = () => ({ type: SET_NAVIGATION_BACK_TO_PARENT_APP });
// export const navigateToCVSFromParentApp = ({parentAppUrl, callbackAction, callMeBackSource}) => ({
//     type: SET_NAVIGATION_TO_CVS_FROM_PARENT_APP,
//     parentAppUrl,
//     callbackAction,
//     callMeBackSource,
// });

export const SET_ACTIVE_PAGE_INDEX = ROUTING_SET_VALUE + 'activePageIndex';
export const setActivePageIndex = value => ({ type: SET_ACTIVE_PAGE_INDEX, value });

// Per-page routing transitions
export const ROUTING_TRANSITION = 'taxFreeSavingsAccount/routing/transition/';

export const NAVIGATE_TO_LANDING_PAGE = ROUTING_TRANSITION + 'toLandingPage';
export const navigateToLandingPage = () => ({ type: NAVIGATE_TO_LANDING_PAGE });

export const NAVIGATE_TO_INVESTMENT_SETUP_INITIAL = ROUTING_TRANSITION + 'toInvestmentSetupInitial';
export const navigateToInvestmentSetupInitial = () => ({ type: NAVIGATE_TO_INVESTMENT_SETUP_INITIAL });

export const NAVIGATE_TO_INVESTMENT_SETUP_CONTRIBUTION = ROUTING_TRANSITION + 'toInvestmentSetupContribution';
export const navigateToInvestmentSetupContribution = () => ({ type: NAVIGATE_TO_INVESTMENT_SETUP_CONTRIBUTION });

export const NAVIGATE_TO_SELECT_A_FUND = ROUTING_TRANSITION + 'toSelectAFund';
export const navigateToSelectAFund = () => ({ type: NAVIGATE_TO_SELECT_A_FUND });

export const NAVIGATE_TO_ADVANCED_FUND_PICKER = ROUTING_TRANSITION + 'toAdvancedFundPicker';
export const navigateToAdvancedFundPicker = () => ({ type: NAVIGATE_TO_ADVANCED_FUND_PICKER });

export const NAVIGATE_TO_FUND_SPLIT = ROUTING_TRANSITION + 'toFundSplit';
export const navigateToFundSplit = () => ({ type: NAVIGATE_TO_FUND_SPLIT });

export const NAVIGATE_TO_PERSONAL_DETAILS = ROUTING_TRANSITION + 'toPersonalDetails';
export const navigateToPersonalDetails = () => ({ type: NAVIGATE_TO_PERSONAL_DETAILS });

export const NAVIGATE_TO_AUXILIARY_DETAILS = ROUTING_TRANSITION + 'toAuxiliaryDetails';
export const navigateToAuxiliaryDetails = () => ({ type: NAVIGATE_TO_AUXILIARY_DETAILS });

// export const NAVIGATE_TO_BENEFICIARIES = ROUTING_TRANSITION + 'toBeneficiaries';
// export const navigateToBeneficiaries = () => ({ type: NAVIGATE_TO_BENEFICIARIES });
//
// export const NAVIGATE_TO_BENEFICIARIES_SPLIT = ROUTING_TRANSITION + 'toBeneficiariesSplit';
// export const navigateToBeneficiariesSplit = () => ({ type: NAVIGATE_TO_BENEFICIARIES_SPLIT });

export const NAVIGATE_TO_PAYMENT_DETAILS = ROUTING_TRANSITION + 'toPaymentDetails';
export const navigateToPaymentDetails = () => ({ type: NAVIGATE_TO_PAYMENT_DETAILS });

export const NAVIGATE_TO_REVIEW = ROUTING_TRANSITION + 'toReview';
export const navigateToReview = () => ({ type: NAVIGATE_TO_REVIEW });

export const NAVIGATE_TO_THANK_YOU = ROUTING_TRANSITION + 'toThankYou';
export const navigateToThankYou = () => ({ type: NAVIGATE_TO_THANK_YOU });

export const RESET_ROUTING = ROUTING_SET_VALUE + 'resetState';
export const resetRouting = () => ({ type: RESET_ROUTING });