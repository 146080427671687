import axios from 'axios';
import {
    getPaymentsDetails,
    getPaymentsStatusDetails,
} from '../../../selectors/roaTravelInsurance/payments';

import {
    submitTravelInsurancePaymentsStatusSuccess,
    submitTravelInsurancePaymentsStatusFailure,
    apiTransitionPaymentsStatus,
} from '../../../actions/roaTravelInsurance/addTraveller';

export const travelInsuranceMpesaPaymentStatusService = (
    store,
    next,
    action
) => {
    const result = next(action);
    const state = store.getState();
    const paymentsStatusResponseValue = getPaymentsStatusDetails(state);
    (async () => {
        if (paymentsStatusResponseValue?.value?.status !== '0') {
            store.dispatch(apiTransitionPaymentsStatus.pending());
            try {
                const resp = await paymentsStatus(state);
                if (
                    resp?.data?.message ===
                    'The service request is processed successfully.'
                ) {
                    store.dispatch(
                        submitTravelInsurancePaymentsStatusSuccess(resp.data)
                    );
                    store.dispatch(apiTransitionPaymentsStatus.success());
                } else if (
                    resp?.data?.message ===
                    'The balance is insufficient for the transaction'
                ) {
                    store.dispatch(
                        submitTravelInsurancePaymentsStatusSuccess(resp.data)
                    );
                    store.dispatch(apiTransitionPaymentsStatus.failure());
                } else if (resp?.data?.message === 'Request cancelled by user') {
                    store.dispatch(
                        submitTravelInsurancePaymentsStatusSuccess(resp.data)
                    );
                    store.dispatch(apiTransitionPaymentsStatus.failure());
                }
            } catch (e) {
                store.dispatch(submitTravelInsurancePaymentsStatusFailure(e));
                store.dispatch(apiTransitionPaymentsStatus.failure());
            }
        }
    })();
    return result;
};

const paymentsStatus = async (state) => {
    const paymentsResponse = getPaymentsDetails(state);
    const payload = {
        checkout_request_id: paymentsResponse?.value?.CheckoutRequestID,
    };
    try {
        const url = '/om-api/roa-travel-insurance/mpesa-check-stk-status';
        return await axios.post(url, payload);
    } catch (e) {
        console.error(e);
        throw e;
    }
};
