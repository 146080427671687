import { createSelector } from 'reselect';
import { getRWMotorPrivateInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDocuments = createSelector(getRWMotorPrivateInsuranceState, (state) => state.documents);
export const getDocumentsForm = createSelector(getDocuments, (documents) => documents.form);

export const getDropOffDetails = createSelector(getDocuments, (documents) => documents.dropOffDetails);

export const getDocumentsFormValid = createSelector(getDocuments, (documents) => documents.formStatus === FORM_STATUS.VALID);

export const getIsApiPending = createSelector(getDocuments, (documents) => documents.formStatus === API_CALL_STATUS.PENDING);

const makeGetFieldForm = (fieldId) => createSelector(getDocumentsForm, (form) => form[fieldId]);


export const getIdUploadValue = makeGetFieldForm('idUpload');
export const getYellowCardUploadValue = makeGetFieldForm('yellowCard');
export const getLeftViewUploadValue = makeGetFieldForm('leftView');
export const getRightViewUploadValue = makeGetFieldForm('rightView');
export const getFrontViewUploadValue = makeGetFieldForm('frontView');
export const getBackViewUploadValue = makeGetFieldForm('backView');
export const getDashboardViewUploadValue = makeGetFieldForm('dashboardView');
