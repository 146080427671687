import { createSelector } from 'reselect';
import { getBiasharaFlexiState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getCompanyDetails = createSelector(
    getBiasharaFlexiState,
    state => state.companyDetails
);

const makeGetCompanyDetailsField = fieldName => 
    createSelector(getCompanyDetails, companyDetails => companyDetails[fieldName]);

export const getCompanyDetailsForm = makeGetCompanyDetailsField('form');

export const getCompanyDetailsFormValid = createSelector(
    getCompanyDetails, 
    companyDetails => companyDetails.formStatus === FORM_STATUS.VALID,
)

const makeGetFieldForm = (fieldId) => createSelector(getCompanyDetailsForm, (form) => form[fieldId]);

export const getCompanyNameValue = makeGetFieldForm('companyName');
export const getEmailValue = makeGetFieldForm('email');
export const getMobileNumberValue = makeGetFieldForm('mobileNumber');
export const getKraPinNumberValue = makeGetFieldForm('kraPinNumber');
export const getCityValue = makeGetFieldForm('city');
export const getStreetAddressValue = makeGetFieldForm('streetAddress');
export const getBuildingValue = makeGetFieldForm('building');
export const getPostalCodeValue = makeGetFieldForm('postalCode');
export const getPostalBoxValue = makeGetFieldForm('postalBox');
export const getFirstNameValue = makeGetFieldForm('firstName');
export const getSurnameValue = makeGetFieldForm('lastName');
export const getIdPassportValue = makeGetFieldForm('idNumber');

