export const APP_URL_PREFIX = '/personal/save-and-invest/personal-pension-plan';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QUOTE: 0,
    PERSONAL_DETAILS: 1,
    NEXT_OF_KIN: 2,
    BENEFICIARIES: 3,
    PAYMENT: 4,
};

export const PAGES = [
    {
        URL: `${APP_URL_PREFIX}/projections`,
        URL_PART: 'projections',
        TITLE: 'Projections',
    },
    {
        URL: `${APP_URL_PREFIX}/personal-details`,
        URL_PART: 'personal-details',
        TITLE: 'Personal Details',
    },
    {
        URL: `${APP_URL_PREFIX}/next-of-kin`,
        URL_PART: 'next-of-kin',
        TITLE: 'Next of Kin',
    },
    {
        URL: `${APP_URL_PREFIX}/beneficiaries`,
        URL_PART: 'beneficiaries',
        TITLE: 'Beneficiaries',
    },
    {
        URL: `${APP_URL_PREFIX}/complete`,
        URL_PART: 'complete',
        TITLE: 'Complete',
    },
];

export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const PERSONAL_PENSION_PLAN_URLS = {
    QUOTE: PAGES[PAGE_INDICES.QUOTE].URL,
    PERSONAL_DETAILS: PAGES[PAGE_INDICES.PERSONAL_DETAILS].URL,
    NEXT_OF_KIN: PAGES[PAGE_INDICES.NEXT_OF_KIN].URL,
    BENEFICIARIES: PAGES[PAGE_INDICES.BENEFICIARIES].URL,
    PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL,
};
