import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';
import {
    CALCULATE_DIGILIFE_PREMIUM_DATA_FAILURE,
    CALCULATE_DIGILIFE_PREMIUM_DATA_SUCCESS,
    WHICH_FORM_TO_DISPLAY,
    FORM_INVALID_DIGILIFE_QUOTE_TOOL,
    FORM_VALID_DIGILIFE_QUOTE_TOOL,
    API_FAILURE_TRANSITION_DIGILIFE_QUOTE_TOOL,
    API_PENDING_TRANSITION_DIGILIFE_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_DIGILIFE_QUOTE_TOOL,
    REINITIALIZE,
    // DIGILIFE_FULL_NAME_SET_VALID,
    // DIGILIFE_FULL_NAME_SET_VALUE,
    // DIGILIFE_CONTACT_SET_VALID,
    // DIGILIFE_CONTACT_SET_VALUE,
    // DIGILIFE_EMAIL_SET_VALID,
    // DIGILIFE_EMAIL_SET_VALUE,
    DIGILIFE_GENDER_SET_VALID,
    DIGILIFE_GENDER_SET_VALUE,
    DIGILIFE_DOB_SET_VALID,
    DIGILIFE_DOB_SET_VALUE,
    DIGILIFE_SALARY_SET_VALID,
    DIGILIFE_SALARY_SET_VALUE,
    DIGILIFE_LOAN_TYPE_SET_VALID,
    DIGILIFE_LOAN_TYPE_SET_VALUE,
    DIGILIFE_EDUCATION_SET_VALID,
    DIGILIFE_EDUCATION_SET_VALUE,
    DIGILIFE_LOAN_AMOUNT_SET_VALID,
    DIGILIFE_LOAN_AMOUNT_SET_VALUE,
    DIGILIFE_LOAN_TERM_SET_VALID,
    DIGILIFE_LOAN_TERM_SET_VALUE,
    DIGILIFE_PAYMENT_SET_VALID,
    DIGILIFE_PAYMENT_SET_VALUE,
    DIGILIFE_CASHBACK_SET_VALID,
    DIGILIFE_CASHBACK_SET_VALUE,
    DIGILIFE_DATE_SET_VALID,
    DIGILIFE_DATE_SET_VALUE,
    DIGILIFE_FORMATTED_DATE_SET_VALUE,
    CALCULATE_DIGILIFE_PREMIUM_DATA
} from "../../actions/roaQuoteTools/digiLifeQuoteTool";
const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    digiLifePremiumValue: '',
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_email',
        product: 'Digi Life',
        email: "lifeenquiries@oldmutual.co.bw",
        privacy_policy_url: '/about-old-mutual/privacy-policy/'
    },
    form: {
        // fullName: { value: null, error: null, status: null },  
        // contactNumber: { value: null, error: null, status: null },  
        // emailId: { value: null, error: null, status: null },  
        gender: { value: null, error: null, status: null },  
        DOB: { value: null, error: null, status: null },  
        salary: { value: null, error: null, status: null },  
        education: { value: null, error: null, status: null },  
        loanType: { value: null, error: null, status: null },  
        loanAmount: { value: null, error: null, status: null },  
        loanTerm: { value: null, error: null, status: null },  
        payment: { value: null, error: null, status: null },  
        cashBack: { value: '02', error: null, status: "valid" },  
        // date: { value: null, error: null, status: null },  
    },
    apiCallStatus: null
};

export default (state = initialeState, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_DIGILIFE_QUOTE_TOOL:
            return {...state, formStatus:FORM_STATUS.VALID};
        case FORM_INVALID_DIGILIFE_QUOTE_TOOL:
            return {...state, formStatus:FORM_STATUS.INVALID};
        case CALCULATE_DIGILIFE_PREMIUM_DATA_SUCCESS:
            return {...state, digiLifePremiumValue:action.value};
        case CALCULATE_DIGILIFE_PREMIUM_DATA_FAILURE:
            return {...state, digiLifePremiumValue:action.value};
        case API_FAILURE_TRANSITION_DIGILIFE_QUOTE_TOOL:
            return {...state, apiCallStatus:API_CALL_STATUS.FAILURE};
        case API_PENDING_TRANSITION_DIGILIFE_QUOTE_TOOL:
            return {...state, apiCallStatus:API_CALL_STATUS.PENDING};
        case API_SUCCESS_TRANSITION_DIGILIFE_QUOTE_TOOL:
            return {...state, apiCallStatus:API_CALL_STATUS.SUCCESS};
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value,
            };
        // case DIGILIFE_FULL_NAME_SET_VALUE:
        //     return updateFormField('fullName', field => ({
        //         ...field,
        //         value: action.value,
        //     }));
        // case DIGILIFE_FULL_NAME_SET_VALID:
        //     return updateFormField('fullName', field => ({
        //         ...field,
        //         error: action.error,
        //         status: mapError(action.error),
        //     }));
        // case DIGILIFE_CONTACT_SET_VALUE:
        //     return updateFormField('contactNumber', field => ({
        //         ...field,
        //         value: action.value,
        //     }));
        // case DIGILIFE_CONTACT_SET_VALID:
        //     return updateFormField('contactNumber', field => ({
        //         ...field,
        //         error: action.error,
        //         status: mapError(action.error),
        //     }));
        // case DIGILIFE_EMAIL_SET_VALUE:
        //     return updateFormField('emailId', field => ({
        //         ...field,
        //         value: action.value,
        //     }));
        // case DIGILIFE_EMAIL_SET_VALID:
        //     return updateFormField('emailId', field => ({
        //         ...field,
        //         error: action.error,
        //         status: mapError(action.error),
        //     }));
        case DIGILIFE_GENDER_SET_VALUE:
            return updateFormField('gender', field => ({
                ...field,
                value: action.value,
            }));    
        case DIGILIFE_GENDER_SET_VALID:
            return updateFormField('gender', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_DOB_SET_VALUE:
            return updateFormField('DOB', field => ({
                ...field,
                value: action.value,
            }));
        case DIGILIFE_DOB_SET_VALID:
            return updateFormField('DOB', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_SALARY_SET_VALUE:
            return updateFormField('salary', field => ({
                ...field,
                value: action.value,
            }));    
        case DIGILIFE_SALARY_SET_VALID:
            return updateFormField('salary', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_EDUCATION_SET_VALUE:
            return updateFormField('education', field => ({
                ...field,
                value: action.value,
            }));  
        case DIGILIFE_EDUCATION_SET_VALID:
            return updateFormField('education', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_LOAN_TYPE_SET_VALUE:
            return updateFormField('loanType', field => ({
                ...field,
                value: action.value,
            }));      
        case DIGILIFE_LOAN_TYPE_SET_VALID:
            return updateFormField('loanType', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_LOAN_AMOUNT_SET_VALUE:
            return updateFormField('loanAmount', field => ({
                ...field,
                value: action.value,
            }));
        case DIGILIFE_LOAN_AMOUNT_SET_VALID:
            return updateFormField('loanAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_LOAN_TERM_SET_VALUE:
            return updateFormField('loanTerm', field => ({
                ...field,
                value: action.value,
            }));
        case DIGILIFE_LOAN_TERM_SET_VALID:
            return updateFormField('loanTerm', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_PAYMENT_SET_VALUE:
            return updateFormField('payment', field => ({
                ...field,
                value: action.value,
            }));
        case DIGILIFE_PAYMENT_SET_VALID:
            return updateFormField('payment', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_CASHBACK_SET_VALUE:
            return updateFormField('cashBack', field => ({
                ...field,
                value: action.value,
            }));
        case DIGILIFE_CASHBACK_SET_VALID:
            return updateFormField('cashBack', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_DATE_SET_VALUE:
            return updateFormField('date', field => ({
                ...field,
                value: action.value,
            }));
        case DIGILIFE_DATE_SET_VALID:
            return updateFormField('date', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIGILIFE_FORMATTED_DATE_SET_VALUE:
            return updateFormField('formattedDate', field => ({
                ...field,
                value:action.value
            }))    
        
        case CALCULATE_DIGILIFE_PREMIUM_DATA:
            return {
                ...state,
                digiLifePremiumValue: action.value,
            };
        case CALCULATE_DIGILIFE_PREMIUM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case REINITIALIZE:
                return initialeState;

		default:
			return state;
	}
}