import { createSelector } from "reselect";
import { BENEFICIARY_DRAWER, FIELD_STATUS } from "../../reducers/status";
import { getTaxFreeSavingsAccount } from "./index";

export const getBeneficiaries = createSelector(getTaxFreeSavingsAccount, (tfsa) => tfsa && tfsa.beneficiaries);

// Edit beneficiary
export const getEditBeneficiary = createSelector(
  getBeneficiaries,
  (beneficiaries) => beneficiaries && beneficiaries.editBeneficiary
);
export const getEditBeneficiaryForm = createSelector(
  getEditBeneficiary,
  (editBeneficiary) => editBeneficiary && editBeneficiary.form
);

// Edit beneficiary form
const makeGetField = (fieldId) =>
  createSelector(getEditBeneficiaryForm, (editBeneficiaryForm) => editBeneficiaryForm && editBeneficiaryForm[fieldId]);
export const getTitle = makeGetField("title");
export const getFirstName = makeGetField("firstName");
export const getSurname = makeGetField("surname");
export const getDateOfBirth = makeGetField("dateOfBirth");
export const getGender = makeGetField("gender");
export const getMobileNumber = makeGetField("mobileNumber");
export const getEmail = makeGetField("email");

export const getEditId = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries && beneficiaries.editId);

export const getRemoveId = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries && beneficiaries.removeId);

// Drawers
export const getOpenDrawer = createSelector(
  getBeneficiaries,
  (beneficiaries) => beneficiaries && beneficiaries.openDrawer
);
export const getEditDrawerIsOpen = createSelector(
  getOpenDrawer,
  (openDrawer) => openDrawer === BENEFICIARY_DRAWER.EDIT
);

export const getIsBeneficiaryValid = createSelector(
  getTitle,
  getFirstName,
  getSurname,
  getDateOfBirth,
  getGender,
  getMobileNumber,
  getEmail,
  (title, name, surname, dateOfBirth, gender, mobileNumber, email) => {
    const mobileIsNullOrValid = !mobileNumber.value || mobileNumber.status === FIELD_STATUS.VALID;
    const emailIsNullOrValid = !email.value || email.status === FIELD_STATUS.VALID;
    const emailOrMobileIsSupplied = email.value || mobileNumber.value;

    return (
      mobileIsNullOrValid &&
      emailIsNullOrValid &&
      emailOrMobileIsSupplied &&
      title.status === FIELD_STATUS.VALID &&
      name.status === FIELD_STATUS.VALID &&
      surname.status === FIELD_STATUS.VALID &&
      dateOfBirth.status === FIELD_STATUS.VALID &&
      gender.status === FIELD_STATUS.VALID
    );
  }
);

export const getBeneficiaryList = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.beneficiaries);

export const getIsNewBeneficiary = createSelector(getBeneficiaryList, getEditId, (beneficiariesList, editId) => {
  const beneficiary = beneficiariesList.filter((beneficiary) => beneficiary.id === editId);
  return !beneficiary.length;
});

export const getNumberOfBeneficiaries = createSelector(getBeneficiaryList, (beneficiaries) => beneficiaries.length);

export const getDisplayBeneficiariesLandingPage = createSelector(
  getNumberOfBeneficiaries,
  (numberOfBeneficiaries) => numberOfBeneficiaries < 1
);

export const getAddedMaxBeneficiaries = createSelector(getNumberOfBeneficiaries, (numberOfBeneficiaries) => {
  const MAX_BENEFICIARIES = 10;
  return numberOfBeneficiaries === MAX_BENEFICIARIES && "maxAdded";
});

export const getNextBeneficiaryId = createSelector(getBeneficiaryList, (beneficiaries) => {
  const idArray = beneficiaries.map((beneficiary) => beneficiary.id);
  return idArray.length > 0 ? Math.max(...idArray) + 1 : 0;
});

export const getUpdatedBeneficiaryListAfterRemoving = createSelector(
  getBeneficiaryList,
  getRemoveId,
  (list, removeId) => list.filter((ben) => ben.id !== removeId)
);

export const getUpdatedBeneficiaryListAfterSaving = createSelector(
  getBeneficiaryList,
  getEditId,
  getIsNewBeneficiary,
  getEditBeneficiaryForm,
  (list, editId, isNewBeneficiary, form) => {
    if (isNewBeneficiary) {
      list.push({ id: editId, value: form });
    } else {
      const editedEntryIndex = list.findIndex((beneficiary) => beneficiary.id === editId);
      list[editedEntryIndex] = { ...list[editedEntryIndex], value: form };
    }

    return list;
  }
);

export const getIsGenderDisabledValue = createSelector(getTitle, (title) => {
  switch (title.value) {
    case "Mr":
      return true;
      break;

    case "Mrs":
    case "Ms":
    case "Miss":
      return true;
      break;

    default:
      return false;
      break;
  }
});
