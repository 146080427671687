import { getIsApiPending, getQuoteForm } from '../../../selectors/personalPensionPlan/quote';
import {
    apiTransitionPersonalPensionPlanQuoteForm,
    apiTransitionPrepaidLumpsumCheck,
    setPrepaidTransactionCodeValid,
} from '../../../actions/personalPensionPlan/quote';
import { getAccessToken } from '../../../selectors/personalPensionPlan/payments';
import axios from 'axios';
import { calculatePersonalPensionPlanPremiumSuccess } from '../../../actions/personalPensionPlan/quote';

const prepaidLumpsumCheckService = async (store) => {
    store.dispatch(setPrepaidTransactionCodeValid(null));

    store.dispatch(apiTransitionPrepaidLumpsumCheck.pending());
    try {
        const resp = await runTransactionCodeCheck(store.getState());

        if (resp.status === 200) {
            store.dispatch(setPrepaidTransactionCodeValid(null));

            return true;
        }

        store.dispatch(apiTransitionPrepaidLumpsumCheck.success());

        return true;
    } catch (e) {
        console.error(e);

        store.dispatch(setPrepaidTransactionCodeValid('Transaction Code or Lumpsum Amount is not valid'));
        store.dispatch(apiTransitionPrepaidLumpsumCheck.failure());

        return false;
    }
};

const prepaidLumpsumCheckPayload = (state) => {
    const quotation = getQuoteForm(state);

    const prepaidLumpsumCheckPayload = {
        transactionCode: quotation.prepaidTransactionCode.value,
        lumpSumValue: parseInt(quotation.lumpsumAmount.value),
        Success: true,
    };

    return {
        ...prepaidLumpsumCheckPayload,
    };
};

const runTransactionCodeCheck = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/personal-pension-plan/prepaid-lumpsum-check';
        return await axios.post(url, prepaidLumpsumCheckPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const premiumCalculationService = (store, next, action) => {
    const handleFailure = () => {
        store.dispatch(apiTransitionPersonalPensionPlanQuoteForm.failure());
        store.dispatch(calculatePersonalPensionPlanPremiumSuccess(null));
    };

    const handleSuccess = async (resp) => {
        store.dispatch(calculatePersonalPensionPlanPremiumSuccess(resp.data));
        store.dispatch(apiTransitionPersonalPensionPlanQuoteForm.success());
    };

    const performCalculation = async () => {
        let quotation = getQuoteForm(store.getState());

        if (
            quotation.contributionMode?.value &&
            quotation.contributionMode?.value !== 'monthly-contribution-only' &&
            quotation.isLumpsumPrepaid?.value === 'yes'
        ) {
            const isPrepaidLumpsumTransactionCodeValid = await prepaidLumpsumCheckService(store);

            if (!isPrepaidLumpsumTransactionCodeValid) {
                handleFailure();
                return;
            }
        }

        const resp = await calculate(store.getState());
        await handleSuccess(resp);
    };

    (async () => {
        if (getIsApiPending(store.getState())) {
            return next(action);
        }

        store.dispatch(apiTransitionPersonalPensionPlanQuoteForm.pending());

        try {
            await performCalculation();
        } catch (error) {
            console.error(error);
            handleFailure();
        }
    })();

    return next(action);
};

const getRetirementAgeDifference = (dob, retirementAge) => {
    const parseDate = (dateString) => {
        const parts = dateString.split('/');
        if (parts.length !== 3) {
            return null;
        }
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    };

    const birthDate = parseDate(dob);
    if (!birthDate || isNaN(birthDate.getTime())) {
        return NaN;
    }

    if (typeof retirementAge !== 'number' || isNaN(retirementAge)) {
        return NaN;
    }

    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    const ageDifference = Math.abs(age - retirementAge);

    return ageDifference;
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);

    const retirementYears = getRetirementAgeDifference(
        quotation.dob?.value,
        parseInt(quotation.desiredRetirementAge?.value)
    );

    const premiumCalculationPayload = {
        monthlySalary: 0,
        monthlyContributionRate: 0,
        monthlyContribution:
            quotation.contributionMode?.value !== 'lumpsum-only'
                ? parseInt(quotation.desiredMonthlyContribution?.value) || 0
                : 0,
        retirementYears,
        annualInterest: quotation.interestRate?.value ? parseInt(quotation.interestRate?.value) : 0,
        annualGrowthRate: 0,
        lumpSumValue:
            quotation.contributionMode?.value !== 'monthly-contribution-only'
                ? parseInt(quotation.lumpsumAmount?.value) || 0
                : 0,
        compoundingFrequency: quotation.frequencyOfContribution?.value || 1,
    };

    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/personal-pension-plan/premium-calculation';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
