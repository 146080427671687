
import axios from 'axios';
import { setInvalidOTP } from '../../../actions/digitalOnboarding/otp';
import { setResponse, requestFail } from '../../../actions/digitalOnboarding/routing';
import { getApplicationNumber, getApplicationOutcome } from '../../../selectors/digitalOnboarding/application';
import * as ActionTypes from '../../../actions/digitalOnboarding/types';

export const requestSendingOTP = async (store, next, action) => {
    const result = next(action);
    const storeData = store.getState();
    const successType = action.type === ActionTypes.OTP_SEND_REQUEST ? ActionTypes.OTP_SEND_SUCCESS : ActionTypes.OTP_RESEND_SUCCESS;

    (async () => {
        try {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            const url = `${ActionTypes.FINANCE_API_URL}/applications/${applicationNumber}/otp`;
            const returnData = await axios.post(url);
            
            if (ActionTypes.FINANCE_API_URL.includes("localhost")) {
                store.dispatch(setResponse(successType, returnData.data));
            } else {
                store.dispatch(setResponse(successType, returnData.data.data));

                if (successType.includes('OTP_RESEND_REQUEST')) {
                    dataLayer.push({
                        "app": "omflending",
                        "applicationNumber": applicationNumber,
                        "applicationOutcome": applicationOutcome,
                        "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                        "event": "requestSendingOTP",
                        "eventOutcome": "OTP_RESEND_SUCCESS",
                        "page": window.location.pathname.split('/')[3]
                    });
                } else {
                    dataLayer.push({
                        "app": "omflending",
                        "applicationNumber": applicationNumber,
                        "applicationOutcome": applicationOutcome,
                        "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                        "event": "requestSendingOTP",
                        "eventOutcome": "OTP_SEND_SUCCESS",
                        "page": window.location.pathname.split('/')[3]
                    });
                }
            }
        } catch (e) {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            store.dispatch(requestFail(ActionTypes.OTP_SEND_FAIL));

            if (successType.includes('OTP_RESEND_REQUEST')) {
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "requestSendingOTP",
                    "eventOutcome": "OTP_RESEND_FAIL",
                    "page": window.location.pathname.split('/')[3]
                });
            } else {
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "requestSendingOTP",
                    "eventOutcome": "OTP_SEND_FAIL",
                    "page": window.location.pathname.split('/')[3]
                });
            }
        }
    })();

    return result;
};

export const verifyOTP = async (store, next, action) => {
    const result = next(action);
    const storeData = store.getState();
    const storeValues = storeData.digitalOnboarding.otp.storeValues;

    (async () => {
        try {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            const url = `${ActionTypes.FINANCE_API_URL}/applications/${applicationNumber}/otp/verify`;
            const requestData = { otp: storeValues[ActionTypes.OTP_VALUE].value };
            const returnData = await axios.post(url, requestData);

            if (ActionTypes.FINANCE_API_URL.includes("localhost")) {
                store.dispatch(setResponse(ActionTypes.OTP_VERIFY_SUCCESS, returnData.data));
            } else {
                store.dispatch(setResponse(ActionTypes.OTP_VERIFY_SUCCESS, returnData.data.data));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "verifyOTP",
                    "eventOutcome": "OTP_VERIFY_SUCCESS",
                    "page": window.location.pathname.split('/')[3]
                });
            }
            
        } catch (e) {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            if(e.response?.status === 400){
                store.dispatch(setInvalidOTP());
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "verifyOTP",
                    "eventOutcome": "OTP_BAD_REQUEST",
                    "page": window.location.pathname.split('/')[3]
                });
            } else {
                store.dispatch(requestFail(ActionTypes.OTP_VERIFY_FAIL));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "verifyOTP",
                    "eventOutcome": "OTP_VERIFY_FAIL",
                    "page": window.location.pathname.split('/')[3]
                });
            }
        }
    })();

    return result;
};