import { PAGE_INDICES } from '../../actions/healthInsuranceRW/types';
import {
	SET_ACTIVE_PAGE_INDEX,
	SET_NAVIGATION_DONE,
    NAVIGATE_TO_HEALTH_QUOTE,
    NAVIGATE_TO_LIVES_COVERED,   
    NAVIGATE_TO_MEDICAL_HISTORY,
    NAVIGATE_TO_PAYMENT,
	SET_LANDING_PAGE,
	NAVIGATE_TO_LANDING_PAGE,
    NAVIGATE_TO_DOCUMENTS,
} from '../../actions/healthInsuranceRW/routing';

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    landingPage: null,
};

export default (state = initRouting, action) => {
    const setActivePage = pageIndex => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };
        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };
        case NAVIGATE_TO_HEALTH_QUOTE:
            return setActivePage(PAGE_INDICES.QOUTE);
        case NAVIGATE_TO_LIVES_COVERED:
            return setActivePage(PAGE_INDICES.LIVES_COVERED);
        case NAVIGATE_TO_MEDICAL_HISTORY:
            return setActivePage(PAGE_INDICES.MEDICAL_HISTORY);
        case NAVIGATE_TO_DOCUMENTS:
            return setActivePage(PAGE_INDICES.DOCUMENTS);
        case NAVIGATE_TO_PAYMENT:
            return setActivePage(PAGE_INDICES.PAYMENT);
        case SET_LANDING_PAGE:
            return { ...state, landingPage: action.value };
        case NAVIGATE_TO_LANDING_PAGE:
            return setActivePage(PAGE_INDICES.LANDING_PAGE);
        
        default:
            return state;
    }
};
