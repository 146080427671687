import { TRIGGER_AGENT_TYPE } from '../../../actions/doctorsPi/agent';
import { COLLECT_LOGIN_DETAILS } from '../../../actions/doctorsPi/auth';
import { COLLECT_PAYLOAD_DATA } from '../../../actions/doctorsPi/payloadCollection';
import { CHECK_PAYMENTS_STATUS, TRIGGER_CONFIRM_ORDER, TRIGGER_KE_PAYMENTS_STK_PUSH, TRIGGER_PAYMENTS_STK_PUSH } from '../../../actions/doctorsPi/payments';
import {
 	COLLECT_DOCTORS_PI_QUOTE_FORM_DATA,
    COLLECT_SHARE_QUOTE_FORM_DATA,
} from '../../../actions/doctorsPi/quote';
import { getAgentsService } from './agents';
import { loginService } from './login';
import { orderConfirmationService } from './orderConfirmation';
import { payloadSubmitService } from './payloadCollection';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { premiumCalculationService } from './quote';
import { shareQuoteService } from './shareQuote';

 export const doctorsPiCover = (store, next, action) => {
    
     if(action.type.startsWith(COLLECT_LOGIN_DETAILS)){
         return loginService(store, next, action);
     }
     if (action.type.startsWith(TRIGGER_KE_PAYMENTS_STK_PUSH)) {
         return paymentsStkService(store, next, action);
     }
     if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
         return paymentsStkStatusService(store, next, action);
     }
    if (action.type.startsWith(COLLECT_DOCTORS_PI_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {
        console.log("hsadfjhjdh")
        return payloadSubmitService(store, next, action);
    }
    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)){
        return orderConfirmationService(store, next, action)
    }

    if(action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)){
        return shareQuoteService(store, next, action);
    }

    if(action.type.startsWith(TRIGGER_AGENT_TYPE)){
        return getAgentsService(store, next, action)
    }

   
    return null;
};
