import {FIELD_STATUS, FORM_STATUS} from "../status";
import {BudgetTool} from "../../functions/AtRetirement/BudgetTool"

import {
    FORM_INVALID_TRANSITION_BUDGET_TOOL,
    FORM_VALID_TRANSITION_BUDGET_TOOL,
    DAY_TO_DAY_SPENDING_SET_VALID,
    DAY_TO_DAY_SPENDING_SET_VALUE,
    DAY_TO_DAY_SPENDING_SET_UNTOUCHED,
    DEBTS_SET_VALID,
    DEBTS_SET_VALUE,
    DEBTS_SET_UNTOUCHED,
    FIXED_MONTHLY_EXPENSES_SET_VALID,
    FIXED_MONTHLY_EXPENSES_SET_VALUE,
    FIXED_MONTHLY_EXPENSES_SET_UNTOUCHED,
    INSURANCE_SET_VALID,
    INSURANCE_SET_VALUE,
    INSURANCE_SET_UNTOUCHED,
    BUDGET_SET_VALUE,
} from "../../actions/atRetirement/budgetTool";

export const initBudgetTool = {
    budgetValue: null,
    formStatus: FORM_STATUS.INVALID,
    form: {
        fixedMonthlyExpenses: { value: null, error: null, status: null },
        dayToDaySpending: { value: null, error: null, status: null },
        insurance: { value: null, error: null, status: null },
        debts: { value: null, error: null, status: null },
    },
};

export default (state = initBudgetTool, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    const calculateBudget = () => {

        if(state.formStatus === FORM_STATUS.INVALID)
            return null;

        return BudgetTool.getBudget([state.form.fixedMonthlyExpenses.value,
            state.form.dayToDaySpending.value,
            state.form.insurance.value,
            state.form.debts.value], 100);
    }


    switch (action.type) {
        case FORM_VALID_TRANSITION_BUDGET_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_BUDGET_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case FIXED_MONTHLY_EXPENSES_SET_VALUE:
            return updateFormField('fixedMonthlyExpenses', field => ({
                ...field,
                value: action.value,
            }));
        case FIXED_MONTHLY_EXPENSES_SET_VALID:
            return updateFormField('fixedMonthlyExpenses', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case FIXED_MONTHLY_EXPENSES_SET_UNTOUCHED:
            return updateFormField('fixedMonthlyExpenses', field => ({
                ...field,
                error: null,
                status: FIELD_STATUS.UNTOUCHED,
            }));

        case DAY_TO_DAY_SPENDING_SET_VALUE:
            return updateFormField('dayToDaySpending', field => ({
                ...field,
                value: action.value,
            }));
        case DAY_TO_DAY_SPENDING_SET_VALID:
            return updateFormField('dayToDaySpending', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DAY_TO_DAY_SPENDING_SET_UNTOUCHED:
            return updateFormField('dayToDaySpending', field => ({
                ...field,
                error: null,
                status: FIELD_STATUS.UNTOUCHED,
        }));

        case INSURANCE_SET_VALUE:
            return updateFormField('insurance', field => ({
                ...field,
                value: action.value,
            }));
        case INSURANCE_SET_VALID:
            return updateFormField('insurance', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case INSURANCE_SET_UNTOUCHED:
            return updateFormField('insurance', field => ({
                ...field,
                error: null,
                status: FIELD_STATUS.UNTOUCHED,
            }));

        case DEBTS_SET_VALUE:
            return updateFormField('debts', field => ({
                ...field,
                value: action.value,
            }));
        case DEBTS_SET_VALID:
            return updateFormField('debts', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DEBTS_SET_UNTOUCHED:
            return updateFormField('debts', field => ({
                ...field,
                error: null,
                status: FIELD_STATUS.UNTOUCHED,
            }));

        case BUDGET_SET_VALUE:
            return { ...state, budgetValue: calculateBudget() };

        default:
            return state;
    }
};
