import {FORM_STATUS, API_CALL_STATUS, FIELD_STATUS} from "../status";
import {
    FORM_VALIDATE_TRANSITION_DETAILS,
    FORM_INVALIDATE_TRANSITION_DETAILS,
    LOG_BOOK_FILE_SET_VALUE,
    LOG_BOOK_FILE_SET_VALID,
    VEHICLE_REGISTRATION_NUMBER_SET_VALUE,
    VEHICLE_REGISTRATION_NUMBER_VALID,
    ID_SET_VALUE,
    ID_SET_VALID,
    KRA_PIN_SET_VALUE,
    KRA_PIN_SET_VALID,
    COVER_START_DATE_SET_VALUE,
    COVER_START_DATE_SET_VALID,
    EXTRA_BENEFIT_SET_VALID,
    EXTRA_BENEFIT_SET_VALUE,
    API_PENDING_TRANSITION_UPLOAD_LOGBOOK,
    API_SUCCESS_TRANSITION_UPLOAD_LOGBOOK,
    API_FAILURE_TRANSITION_UPLOAD_LOGBOOK,
    LOG_BOOK_DATA_SET_VALUE,
    LOG_BOOK_DATA_SET_VALID,
    COMPREHENSIVE_BENEFIT_EDIT_MODE_SET_VALUE,
    ALTER_BENEFIT_SET_VALUE,
    ALTER_BENEFIT_SET_VALID,
    ALTER_BENEFIT_SET_ID_VALUE,
    EXTRA_BENEFIT_SET_ID_VALUE,
    UPDATE_BENEFIT_VALUE,
    TOGGLE_EXTRA_BENEFIT_VALUE,
    ADD_EXTRA_BENEFIT_SET_VALUE,
    ADD_EXTRA_BENEFIT_SET_VALID,
    VALIDATE_ID_SET_VALID,
    VALIDATE_ID_SET_VALUE,
    API_PENDING_TRANSITION_VALIDATE_KRA,
    API_SUCCESS_TRANSITION_VALIDATE_KRA,
    API_FAILURE_TRANSITION_VALIDATE_KRA,
    API_PENDING_TRANSITION_VALIDATE_ID,
    API_SUCCESS_TRANSITION_VALIDATE_ID,
    API_FAILURE_TRANSITION_VALIDATE_ID,
    API_PENDING_TRANSITION_VALIDATE_COVER_DATE,
    API_SUCCESS_TRANSITION_VALIDATE_COVER_DATE,
    API_FAILURE_TRANSITION_VALIDATE_COVER_DATE,
    VALIDATE_KRA_SET_VALID,
    VALIDATE_KRA_SET_VALUE,
    VALIDATE_COVER_DATE_SET_VALUE,
    VALIDATE_COVER_DATE_SET_VALID,
    INITIALIZE_COMPREHENSIVE_COVER_TOTAL,
} from "../../actions/roaCarInsurance/details";

export const initDetails = {
    comprehensiveCoverTotal: null,
    extraPremium:  null,
    comprehensiveCoverAlterValue: null,
    formStatus: FORM_STATUS.INVALID,
    alterBenefitId: null,
    extraBenefitId: null,
    iprsResponse: {value: null, error: null, status: null},
    validateKraResponse: {value: null, error: null, status: null},
    validateCoverDateResponse: {value: null, error: null, status: null},
    logBookData: {value:null, error:null, status: null},
    kraApiCallStatus:null,
    coverStartDateApiCallStatus:null,
    form: {
        vehicleRegNo: {value: null, error: null, status: 'valid'},
        kraPinNumber: {value: null, error:null, status: null},
        coverStartDate:{value:null, error:null, status: null},
        id:{value: null, error:null, status: null},
        extraBenefits: {value: [], error:null, status: 'valid'},
        logBookFile: {value: null, error: null, status: 'valid'},
        
    },
    comprehensiveCoverBenefits: {
    benefits: [
        'Windscreen (upto 50,000)',
        'Vehicle Entertainment System (upto 30,000)',
        'Towing/ Recovery Expenses (upto 30,000)',
        'Medical Expenses (upto 30,000)',
    ],
    },
    comprehensiveBenefitValues: [
            50000,
            50000,
            30000,
            30000,
    ],


    comprehensiveCoverExtraBenefits: [
        {text:'Courtesy Car', id: "12", value: 3000, added: false},
        {text:'Excess Protector', id: "13", value: 2500, added: false},
        {text:'Political Violence and Terrorism', id: "15", value: 3000, added: false },
        {text:'Forced ATM withdrawal', id: "16", value: 1500, added: false},
        {text:'Personal Accident', id: "17", value: 250, added: false },
        {text:'Out of Station Accomodation', id: "18", value: 500, added: false },
        {text:'Loss of personal effects', id: "19", value: 1000, added: false},
    ],
    comprehensiveBenefitEditMode: 'no_edit',

};

export default (state = initDetails, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId])
        }
    });

    switch (action.type) {
        case FORM_VALIDATE_TRANSITION_DETAILS:
            return {...state, formStatus: FORM_STATUS.VALID};
        case FORM_INVALIDATE_TRANSITION_DETAILS:
            return {...state, formStatus: FORM_STATUS.INVALID};

        case LOG_BOOK_FILE_SET_VALUE:
            return updateFormField('logBookFile', field => ({
                ...field,
                 value: action.value,
                 error: null
            }));
    
        case LOG_BOOK_FILE_SET_VALID:
              return updateFormField('logBookFile', field => ({
                ...field,
                 error: action.error,
                 status: mapError(action.error)
            }));

        case VEHICLE_REGISTRATION_NUMBER_SET_VALUE:
             return updateFormField('vehicleRegNo', field => ({
                ...field,
                 value: action.value,

            }));
        case  VEHICLE_REGISTRATION_NUMBER_VALID:
            return updateFormField('vehicleRegNo', field => ({
                ...field,
                 error: action.error,
                 status: mapError(action.error)
            }));

        case  KRA_PIN_SET_VALUE:
              return updateFormField('kraPinNumber', field => ({
                ...field,
                 value: action.value,
            }));
        case   KRA_PIN_SET_VALID:
              return updateFormField('kraPinNumber', field => ({
                ...field,
                 error: action.error,
                 status: mapError(action.error)
            }));

        case  COVER_START_DATE_SET_VALUE:
              return updateFormField('coverStartDate', field => ({
                ...field,
                 value: action.value,
            }));
        case   COVER_START_DATE_SET_VALID:
              return updateFormField('coverStartDate', field => ({
                ...field,
                 error: action.error,
                 status: mapError(action.error)
            }));

        case  EXTRA_BENEFIT_SET_VALUE:
            return updateFormField('extraBenefits', field => ({
                ...field,
                 value: [...field.value, action.value],
            }));
         
        case TOGGLE_EXTRA_BENEFIT_VALUE:
            return {
                ...state, comprehensiveCoverExtraBenefits: state.comprehensiveCoverExtraBenefits.map(
                    (content, i) => i === action.value.indexValue ? { ...content, added: action.value.toggleValue}: content
                )
            }
        case COMPREHENSIVE_BENEFIT_EDIT_MODE_SET_VALUE:
            return {...state, comprehensiveBenefitEditMode: action.value}

         case LOG_BOOK_DATA_SET_VALUE:
            return {...state, logBookData:{...state.logBookData, value: action.value, error: null, status: 'valid'}}
         case LOG_BOOK_DATA_SET_VALID:
            return {...state, logBookData:{...state.logBookData, error: action.error}}

        case  UPDATE_BENEFIT_VALUE:
            return {
                ...state,
                comprehensiveBenefitValues: state.comprehensiveBenefitValues.map(
                (value, i) => i === action.value.indexValue ? action.value.limit_value: value
            )}
        case ALTER_BENEFIT_SET_VALUE:
            return {...state, comprehensiveCoverTotal: action.value.totalpremium, comprehensiveCoverAlterValue: action.value }
        case ALTER_BENEFIT_SET_VALID:
            return {...state,  comprehensiveCoverAlterValue: action.error}

        case INITIALIZE_COMPREHENSIVE_COVER_TOTAL:
            return {...state, comprehensiveCoverTotal: null}


        case ADD_EXTRA_BENEFIT_SET_VALUE:
            return {...state,  comprehensiveCoverTotal: action.value.totalpremium, extraPremium: action.value.extrapremium }
        case ALTER_BENEFIT_SET_ID_VALUE: 
            return {...state, alterBenefitId: action.value}
         case EXTRA_BENEFIT_SET_ID_VALUE: 
            return {...state, extraBenefitId: action.value}

        case VALIDATE_ID_SET_VALUE:
            return {...state, iprsResponse:{...state.iprsResponse, value: action.value, error: null, status: 'valid'}}

        case VALIDATE_ID_SET_VALID:
            return {...state, iprsResponse:{...state.iprsResponse, error: action.error}}

        case VALIDATE_KRA_SET_VALUE:
            return {...state, validateKraResponse:{...state.validateKraResponse, value: action.value, error: null, status: 'valid'}}

        case VALIDATE_KRA_SET_VALID:
            return {...state, validateKraResponse:{...state.validateKraResponse, error: action.error}}

         case VALIDATE_COVER_DATE_SET_VALUE:
            return {...state, validateCoverDateResponse:{...state.validateKraResponse, value: action.value, error: null}}

        case VALIDATE_COVER_DATE_SET_VALID:
            return {...state, validateCoverDateResponse:{...state.validateKraResponse, error: action.error}}

        case  ID_SET_VALUE:
            return updateFormField('id', field => ({
                ...field,
                 value: action.value,
            }));

        case  ID_SET_VALID:
             return updateFormField('id', field => ({
                ...field,
                 value: action.value,
                 status: mapError(action.error)
            }));
        case API_PENDING_TRANSITION_UPLOAD_LOGBOOK:
             return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };

         case API_SUCCESS_TRANSITION_UPLOAD_LOGBOOK:
	             return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };

         case API_FAILURE_TRANSITION_UPLOAD_LOGBOOK:
             return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        case API_PENDING_TRANSITION_VALIDATE_ID:
             return { ...state, iprsApiCallStatus: API_CALL_STATUS.PENDING };

         case API_SUCCESS_TRANSITION_VALIDATE_ID:
	             return { ...state, IprsApiCallStatus: API_CALL_STATUS.SUCCESS };

         case API_FAILURE_TRANSITION_VALIDATE_ID:
             return { ...state, iprsApiCallStatus: API_CALL_STATUS.FAILURE };

         case API_PENDING_TRANSITION_VALIDATE_KRA:
             return { ...state, kraApiCallStatus: API_CALL_STATUS.PENDING };

         case API_SUCCESS_TRANSITION_VALIDATE_KRA:
	             return { ...state, kraApiCallStatus: API_CALL_STATUS.SUCCESS };

         case API_FAILURE_TRANSITION_VALIDATE_KRA:
             return { ...state, kraApiCallStatus: API_CALL_STATUS.FAILURE };

         case API_PENDING_TRANSITION_VALIDATE_COVER_DATE:
             return { ...state, coverStartDateApiCallStatus: API_CALL_STATUS.PENDING };

         case API_SUCCESS_TRANSITION_VALIDATE_COVER_DATE:
	             return { ...state, coverStartDateApiCallStatus: API_CALL_STATUS.SUCCESS };

         case API_FAILURE_TRANSITION_VALIDATE_COVER_DATE:
             return { ...state, coverStartDateApiCallStatus: API_CALL_STATUS.FAILURE };
        default:
            return state;
    }
};