import {getEasiplusFuneralPlan} from "./index";
import {createSelector} from "reselect";
import {FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS} from "../../reducers/status";
import {ADDRESS_TYPES} from "../../actions/easiplusFuneralPlan/types";
import {getReferenceTitles, getReferencePostalMethods} from "./referenceData";
import {dateOfBirthFromIdNumber, getAgeFromIdNumber, getGenderFromIdNumber, mapGenderDataEntry} from "../../functions/easiplusFuneralPlan/Util";

export const getPersonalDetails = createSelector(
    getEasiplusFuneralPlan,
    easiplus => easiplus.personalDetails
);

export const getPersonalDetailsValid = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.formStatus === FORM_STATUS.VALID,
);
export const getPersonalDetailsForm = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.form,
);

export const getGcsStreetAddress = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.gCSStreetAddress,
);
export const getGcsPostalAddress = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.gCSPostalAddress,
);

export const getDropOffDetails = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.dropOffDetails,
);

const makeGetField = fieldId => createSelector(
    getPersonalDetailsForm,
    personalDetailsForm => personalDetailsForm[fieldId]
);

export const getPostalAddressMethod = makeGetField('postalAddressMethod');
export const getPostalAddressSame = makeGetField('postalAddressSame');

// Personal
export const getTitle = makeGetField('title');
export const getFirstNames = makeGetField('firstNames');
export const getSurname = makeGetField('surname');
export const getId = makeGetField('id');

export const getFullName = createSelector(
    getFirstNames,
    getSurname,
    (firstnames, surname) => firstnames.value + ' ' + surname.value
);

export const getInitials = createSelector(
    getFirstNames,
    (firstNames) => {
        const nameArray = firstNames.value.split(' ');
        return nameArray.map(name => name[0]).join('').toUpperCase();
    }
)

// Contact
export const getMobile = makeGetField('mobileNumber');
export const getEmail = makeGetField('email');

// Address
export const getComplex = makeGetField('complex');
export const getStreetAddress = makeGetField('streetAddress');
export const getSuburb = makeGetField('suburb');
export const getCity = makeGetField('city');
export const getPostalCode = makeGetField('postalCode');
export const getCountry = makeGetField('country');

export const getResedentialStreetAddress = createSelector(
    getComplex,
    getStreetAddress,
    getSuburb,
    getCity,
    getPostalCode,
    getCountry,
    (complex, streetAddress, suburb, city, postalCode, country) =>
        [complex.value,
        streetAddress.value,
        suburb.value,
        city.value,
        postalCode.value,
        country.value].filter(Boolean).join(', ')
)
// Postal Address
// PO Box
export const getPoBox = makeGetField('poBox');
export const getPoPostOffice = makeGetField('poPostOffice');
export const getPoPostalCode = makeGetField('poPostalCode');
export const getPoCountry = makeGetField('poCountry');
// Private bag
export const getPrivateBag = makeGetField('privateBag');
export const getPrivatePostOffice = makeGetField('privatePostOffice');
export const getPrivatePostalCode = makeGetField('privatePostalCode');
export const getPrivateCountry = makeGetField('privateCountry');
// Stress Address
export const getPostalComplex = makeGetField('postalComplex');
export const getPostalStreetAddress = makeGetField('postalStreetAddress');
export const getPostalSuburb = makeGetField('postalSuburb');
export const getPostalCity = makeGetField('postalCity');
export const getPostalPostalCode = makeGetField('postalPostalCode');
export const getPostalCountry = makeGetField('postalCountry');

export const getPostalAddress = createSelector(
    getPostalAddressMethod,
    getPoBox,
    getPoPostOffice,
    getPoPostalCode,
    getPoCountry,

    getPrivateBag,
    getPrivatePostOffice,
    getPrivatePostalCode,
    getPrivateCountry,

    getPostalComplex,
    getPostalStreetAddress,
    getPostalSuburb,
    getPostalCity,
    getPostalPostalCode,
    getPostalCountry,

    (postalAddressMethod,
    poBox,
    poPostOffice,
    poPostalCode,
    poCountry,

    privateBag,
    privatePostOffice,
    privatePostalCode,
    privateCountry,

    postalComplex,
    postalStreetAddress,
    postalSuburb,
    postalCity,
    postalPostalCode,
    postalCountry) => {
        if (postalAddressMethod.value === ADDRESS_TYPES.PO_BOX) {
            return [
                poBox.value,
                poPostOffice.value,
                poPostalCode.value,
                poCountry.value
            ].filter(Boolean).join(', ');
        }
        if (postalAddressMethod.value === ADDRESS_TYPES.PRIVATE_BAG) {
            return [
                privateBag.value,
                privatePostOffice.value,
                privatePostalCode.value,
                privateCountry.value,
            ].filter(Boolean).join(', ');
        }
        if (postalAddressMethod.value === ADDRESS_TYPES.STREET_ADDRESS) {
            return [
                postalComplex.value,
                postalStreetAddress.value,
                postalSuburb.value,
                postalCity.value,
                postalPostalCode.value,
                postalCountry.value,
            ].filter(Boolean).join(', ');
        }
    }
);

// Submission
export const getPostalAddressForSubmission = createSelector(
    getPostalAddressMethod,
    getPoBox,
    getPoPostOffice,
    getPoPostalCode,
    getPoCountry,

    getPrivateBag,
    getPrivatePostOffice,
    getPrivatePostalCode,
    getPrivateCountry,

    getPostalComplex,
    getPostalStreetAddress,
    getPostalSuburb,
    getPostalCity,
    getPostalPostalCode,
    getPostalCountry,

    (postalAddressMethod,
     poBox,
     poPostOffice,
     poPostalCode,
     poCountry,

     privateBag,
     privatePostOffice,
     privatePostalCode,
     privateCountry,

     postalComplex,
     postalStreetAddress,
     postalSuburb,
     postalCity,
     postalPostalCode,
     postalCountry) => {
        if (postalAddressMethod.value === ADDRESS_TYPES.PO_BOX) {
            return {
                street: poBox.value,
                suburb: poPostOffice.value,
                city: poCountry.value,
                postalCode: poPostalCode.value,
            };
        }
        if (postalAddressMethod.value === ADDRESS_TYPES.PRIVATE_BAG) {
            return {
                street: privateBag.value,
                suburb: privatePostOffice.value,
                city: privateCountry.value,
                postalCode: privatePostalCode.value,
            };
        }
        if (postalAddressMethod.value === ADDRESS_TYPES.STREET_ADDRESS) {
            const street = [postalComplex.value, postalStreetAddress.value]
                .filter(value => !!value)
                .join(', ')
            const city = [postalCity.value, postalCountry.value]
                .filter(value => !!value)
                .join(', ');

            return {
                street,
                city,
                suburb: postalSuburb.value,
                postalCode: postalPostalCode.value,
            };
        }

        return {};
    }
);


export const getContactInformation = createSelector(
    getMobile,
    getEmail,

    getComplex,
    getStreetAddress,
    getSuburb,
    getCity,
    getPostalCode,
    getCountry,

    getPostalAddressForSubmission,
    getPostalAddressSame,
    (
        mobile,
        email,

        complex,
        streetAddress,
        suburb,
        city,
        postalCode,
        country,

        postalAddress,
        postalAddressSame,
    ) => {
        const residentialAddress = {
            street: streetAddress.value,
            suburb: suburb.value,
            city: city.value,
            postalCode: postalCode.value,
        };

        return {
            cellphoneNumber: mobile.value,
            email: email.value,
            postalAddress: {
                ...(postalAddressSame.value ? residentialAddress : postalAddress),
                isPostalSameAsResidential: postalAddressSame.value,
            },
            residentialAddress: {
                ...residentialAddress,
                isPostalSameAsResidential: postalAddressSame.value,
            },
        };
    }
);

export const getTitleDataEntry = createSelector(
    getTitle,
    getReferenceTitles,
    (title, referenceTitles) => {
        return referenceTitles.find(ref => ref.code === title.value)
    }
);
export const getGenderDataEntry = createSelector(
    getId,
    idNumber => {
        const genderString = getGenderFromIdNumber(idNumber.value);
        return mapGenderDataEntry(genderString);
    }
);

export const getPersonalDetailsSubmission = createSelector(
    getContactInformation,
    getFirstNames,
    getSurname,
    getId,
    getTitleDataEntry,
    getGenderDataEntry,
    (contactInformation, firstName, surname, idNumber, titleDataEntry, genderDataEntry) => {
        const age = getAgeFromIdNumber(idNumber.value);
        const dateOfBirth = dateOfBirthFromIdNumber(idNumber.value);
        return {
            contactInformation,
            age,
            dateOfBirth,
            firstName: firstName.value,
            surname: surname.value,
            saIdNumber: idNumber.value,
            title: titleDataEntry,
            gender: genderDataEntry,
        }
    }
);

export const getAddressLabel = createSelector(
    getPostalAddressSame,
    (postalAddressSame) => {
        if(!postalAddressSame.value)
            return "Residential Address";

        return "Residential/Postal Address";
    }
);

export const getFormattedContactNumber = createSelector(
    getMobile,
    (mobileNumber) => {
        const number = mobileNumber.value;

        if(!number || number.length !== 10)
            return number;

        const splitnumber = number.match(/^(\d{1})(\d{2})(\d{3})(\d{4})$/);
        const dialingCode = [ splitnumber[1], splitnumber[2]].join('');

        return [
            dialingCode,
            splitnumber[3],
            splitnumber[4]
        ].join(' ');
    }
);

export const getStreetAddressPostalMethod = createSelector(
    getReferencePostalMethods,
    (referencePostalMethods) => {
        return referencePostalMethods
               ? referencePostalMethods.find(method => method.code === ADDRESS_TYPES.STREET_ADDRESS)
               : '' ;
    }
);

export const getPoBoxPostalMethod = createSelector(
    getReferencePostalMethods,
    (referencePostalMethods) => {
        return referencePostalMethods
               ? referencePostalMethods.find(method => method.code === ADDRESS_TYPES.PO_BOX)
               : '' ;
    }
);

export const getPrivateBagPostalMethod = createSelector(
    getReferencePostalMethods,
    (referencePostalMethods) => {
        return referencePostalMethods
               ? referencePostalMethods.find(method => method.code === ADDRESS_TYPES.PRIVATE_BAG)
               : '' ;
    }
);

export const getPreferredPostalMethod = createSelector(
    getPostalAddressMethod,
    getStreetAddressPostalMethod,
    getPoBoxPostalMethod,
    getPrivateBagPostalMethod,
    (postalAddressMethod, streetAddressPostalMethod, poBoxPostalMethod, privateBagPostalMethod) => {
        const postalMethods = [
            streetAddressPostalMethod.code,
            privateBagPostalMethod.code,
            poBoxPostalMethod.code
        ];
        return postalMethods.includes(postalAddressMethod.value)
            ? postalAddressMethod.value
            : null;
    }
);

export const getIsPostalAddressMethodStreetAddress = createSelector(
    getPostalAddressMethod,
    getStreetAddressPostalMethod,
    (postalAddressMethod, streetAddressPostalMethod) => {
        return postalAddressMethod.value === streetAddressPostalMethod.code;
    }
);

export const getIsPostalAddressMethodPoBox = createSelector(
    getPostalAddressMethod,
    getPoBoxPostalMethod,
    (postalAddressMethod, poBoxPostalMethod) => {
        return postalAddressMethod.value === poBoxPostalMethod.code;
    }
);

export const getIsPostalAddressMethodPrivateBag = createSelector(
    getPostalAddressMethod,
    getPrivateBagPostalMethod,
    (postalAddressMethod, privateBagPostalMethod) => {
        return postalAddressMethod.value === privateBagPostalMethod.code;
    }
);

export const getIsPostalAddressSameAsResidential = createSelector(
    getPostalAddressSame,
    postalAddressSame => {
        return postalAddressSame.value === TOGGLE_STATUS.YES;
    }
);
 
export const getAreDropoffDetailsValid = createSelector(
    getFirstNames,
    getSurname,
    getMobile,
    (firstNames, surname, mobile) => {
        return firstNames.status === FIELD_STATUS.VALID &&
        surname.status === FIELD_STATUS.VALID &&
        mobile.status === FIELD_STATUS.VALID;
    }
);
