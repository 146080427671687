import axios from 'axios';
import {
    getIsApiPending,
    getPaymentsForm,
    getIsApiPaymentStatusSuccess,
    getIsApiPaymentStatusFailure,
} from '../../../selectors/roaCarInsurance/payments';
import { getVehicleValuation } from '../../../selectors/roaCarInsurance/quotation';
import {getComprehensiveCoverTotalPremium} from '@om/redux/selectors/roaCarInsurance/details';
import {getSelectedProduct} from '@om/redux/selectors/roaCarInsurance/product';
import {getPremiumCalculation} from '@om/redux/selectors/roaCarInsurance/product';
import {
	setMpesaPaymentsCarInsuranceValue,
    setMpesaPaymentsCarInsuranceValid,
    apiTransitionPayments,
	apiTransitionPaymentsStatus,
	checkMpesaPaymentsStatus
} from "../../../actions/roaCarInsurance/payments";

const clearIntervalId = (intervalId) => {
	clearInterval(intervalId);
			intervalId = null;
}

const selectedCoverTotal = (state) => {
    const selectedProduct = getSelectedProduct(state);
    const compCoverTotal = getComprehensiveCoverTotalPremium(state);
    const premiumCalc =  getPremiumCalculation(state);
    const comprehensiveCoverTotal = selectedProduct === 'Comprehensive Cover' && compCoverTotal != null ? compCoverTotal: premiumCalc.comprehensive_total_premium;
    const tpoTotal = premiumCalc.tpo_total_premium;
    const tft_total = premiumCalc.tpft_total_premium;

    if(selectedProduct === 'Comprehensive Cover' ){
        return comprehensiveCoverTotal

    }
    if(selectedProduct === 'Third Party Fire & Theft' ){
        return tft_total

    }
    if(selectedProduct === 'Third Party Cover'){
        return tpoTotal

    }
}

let intervalId = null;
export const carInsuranceMpesaPaymentService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();
	(async () => {
		if (getIsApiPaymentStatusSuccess(state)) {
			clearIntervalId(intervalId)
			return;
		}

		if (getIsApiPaymentStatusFailure(state)) {
			clearIntervalId(intervalId)
			return;
		}

		clearInterval(intervalId);

		if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionPayments.pending());
			try {
				const resp = await payments(state);

				if(resp.data.errorMessage && resp.data.errorMessage=="Invalid Access Token"){
					throw resp.data.errorMessage
				}
                store.dispatch(setMpesaPaymentsCarInsuranceValue(resp.data))
                store.dispatch(apiTransitionPayments.success());
				intervalId = setInterval(
					() => store.dispatch(checkMpesaPaymentsStatus()),
					5000
				);
				setTimeout(() => {
					if (intervalId) {
						clearIntervalId(intervalId);
						store.dispatch(apiTransitionPaymentsStatus.failure());
						return;
					}
				}, 300000);

			} catch (e) {
				store.dispatch(setMpesaPaymentsCarInsuranceValid(e));
                store.dispatch(apiTransitionPayments.failure());
				clearIntervalId(intervalId);
			}
		}
	})();
	return result;
}

const payments = async (state) => {
	const form = getPaymentsForm(state);
	const vehicleValuation = getVehicleValuation(state);
	const payload = {
        phone_no: form.phoneNumber.value,
        amount: 1,//selectedCoverTotal(state),
        session_id: vehicleValuation.value.session_id,
        mpesa_acc_no: vehicleValuation.value.session_id
	};
	try {
		const url = '/om-api/roa-car-insurance/motor-insurance-make-payment';
		return await axios.post(url, payload);
	} catch (e) {
		throw e;
	}
};
