import { SIGNUP_SET_VALUE } from "../../../actions/publicWeb/signupAndDownload";
import { CALL_ME_BACK_SET_VALUE } from "../../../actions/publicWeb/callMeBack";
import { SAVE2GETHER_KEY } from '../../../actions/publicWeb/save2gether';
import { UNCLAIMED_BENEFITS_SET_VALUE } from '../../../actions/publicWeb/unclaimedBenefits';
import { DIVIDEND_CALC_SHARES_SET_VALUE } from '../../../actions/publicWeb/dividendCalculator';
import { QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE } from "../../../actions/publicWeb/qualifyingCheckCallMeBack";
import { FUNERAL_CLAIM_SET_VALUE } from '../../../actions/publicWeb/funeralClaim';
import { FUNERAL_CLAIM_V2_SET_VALUE } from '../../../actions/publicWeb/funeralClaimV2';

import { signupAndDownloadValidation } from "./signupAndDownload";
import { callMeBackValidation } from "./callMeBack";
import { save2getherValidation } from './save2gether';
import { unclaimedBenefitsValidation } from './unclaimedBenefits';
import { dividendCalculatorValidation } from './dividendCalculator';
import { qualifyingCheckCallMeBackValidation } from './qualifyingCheckCallMeBack';


import { funeralClaimValidation } from './funeralClaim';
import { priceGraphValidation} from './priceGraph';
import { PRICE_GRAPH_SET_VALUE } from "../../../actions/publicWeb/priceGraph";
import { funeralClaimV2Validation } from "./funeralClaimV2";
import { asisaUnclaimedBenefitsValidation } from "./asisaUnclaimedBenefits";
import { ASISA_UNCLAIMED_BENEFITS_SET_VALUE } from "../../../actions/publicWeb/asisaUnclaimedBenefits";

export const publicWebValidation = (store, next, action) => {
    if (action.type.startsWith(SAVE2GETHER_KEY))
        return save2getherValidation(store, next, action);

    if (action.type.startsWith(DIVIDEND_CALC_SHARES_SET_VALUE))
        return dividendCalculatorValidation(store, next, action);

    if (action.type.startsWith(CALL_ME_BACK_SET_VALUE))
        return callMeBackValidation(store, next, action);

    if (action.type.startsWith(UNCLAIMED_BENEFITS_SET_VALUE))
        return unclaimedBenefitsValidation(store, next, action);

    if (action.type.startsWith(SIGNUP_SET_VALUE))
        return signupAndDownloadValidation(store, next, action);

    if (action.type.startsWith(QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE)) {
        return qualifyingCheckCallMeBackValidation(store, next, action);
    }

    if (action.type.startsWith(FUNERAL_CLAIM_SET_VALUE))
        return funeralClaimValidation(store, next, action);
    
    if (action.type.startsWith(FUNERAL_CLAIM_V2_SET_VALUE))
        return funeralClaimV2Validation(store, next, action);
    
    if( action.type.startsWith(PRICE_GRAPH_SET_VALUE))
        return priceGraphValidation(store, next, action);

    if(action.type.startsWith(ASISA_UNCLAIMED_BENEFITS_SET_VALUE))
        return asisaUnclaimedBenefitsValidation(store, next, action);

    return null;
}
