import { SET_VALUE_ROUTING } from '../../../actions/travel/routing';
import { routingRules } from './routing';
import {
    TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE,
    TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID,
} from '../../../actions/travel/personalDetails';
import { travelDetailsFormRules } from './travelDetails';

import {
    BUILDING_DETAILS_FORM_SET_VALUE,
    BUILDING_DETAILS_FORM_SET_VALID,
} from '../../../actions/domesticInsurance/buildingDetails';
import { buildingDetailsFormRules } from './buildingDetails';
import {
    DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID,
    DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE,
} from '../../../actions/domesticInsurance/Documents';
import { documenetsFormRules } from './documents';

import {
    MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE,
    MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/travel/motorAgentModal';
import { agentModaleFormRules } from './agentModal';

import { TRAVEL_INSURANCE_FORM_SET_VALID, TRAVEL_INSURANCE_FORM_SET_VALUE } from '../../../actions/travel/quote';
import { quoteFormRules } from './quote';
import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from '../../../actions/travel/payments';
import { paymentsFormRules } from './payments';

export const travelInsuranceRules = (store, next, action) => {
    if (
        action.type.startsWith(TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID)
    ) {
        return travelDetailsFormRules(store, next, action);
    }
    if (action.type.startsWith(SET_VALUE_ROUTING)) {
        return routingRules(store, next, action);
    }

    if (
        action.type.startsWith(DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE) ||
        action.type.startsWith(DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID)
    ) {
        return documenetsFormRules(store, next, action);
    }

    if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
        return paymentsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(BUILDING_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(BUILDING_DETAILS_FORM_SET_VALID)
    ) {
        return buildingDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID)
    ) {
        return agentModaleFormRules(store, next, action);
    }

    if (
        action.type.startsWith(TRAVEL_INSURANCE_FORM_SET_VALUE) ||
        action.type.startsWith(TRAVEL_INSURANCE_FORM_SET_VALID)
    ) {
        return quoteFormRules(store, next, action);
    }

    // if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
    // 	return shareQuoteFormRules(store, next, action);
    // }

    return null;
};
