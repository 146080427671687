import { createSelector } from 'reselect';
import { getRetirementCalculator } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { formatCurrency } from '../currency'
import {
    ga_freq_fv,
    ls_fv,
    ls_pv,
    ga_freq_pmt,
    round,
    ea_to_freq,
} from '../../../tvm-calcs/src/index'

import {
    getRetirementAge,
    getNumberOfRetirementYears,
    getExpectedAnnualGrowth,
    getInflation,
    getExpectedAnnualSalaryIncrease,
    getReplacementRatio
} from './retirementAssumptionsModel'

const assumptionModelDefaultValues = {
    retirementAgeDefault: 60,
    numberOfRetirementYearsDefault: 20,
    expectedAnnualGrowthDefault: 8,
    inflationDefault: 5,
    expectedAnnualSalaryIncreaseDefault: 7,
    replacementRatioDefault: 75
};

export const getRetiremntCalculator = createSelector(
	getRetirementCalculator,
	(retirementCalculators) => retirementCalculators.retirementCalculatorForm
);

export const getRetiremntCalculatorAssumptionsModel = createSelector(
	getRetirementCalculator,
	(retirementCalculators) => retirementCalculators.retirementAssumptionsModel
);

export const getRetiremntCalculatorForm = createSelector(
	getRetiremntCalculator,
	(retirementCalculators) =>  retirementCalculators.form
);

export const getRetiremntCalculatorAssumptionsModelDefualts = createSelector(
    getRetiremntCalculatorAssumptionsModel,
    (retirementCalculatorAssumptionModel) => retirementCalculatorAssumptionModel.assumptionDefualts
);

export const getRetiremntCalculatorResults = createSelector(
	getRetiremntCalculator,
	(retirementCalculators) => retirementCalculators.calculationResults
);

export const getRetiremntCalculatorFormValid = createSelector(
	getRetiremntCalculator,
	(retirementCalculators) => retirementCalculators.formStatus === FORM_STATUS.VALID
);

export const getShowRecommendation = createSelector(
	getRetiremntCalculator,
	(retirementCalculators) => retirementCalculators.showRecommendation
);

const makeGetField = (fieldId) => createSelector(
    getRetiremntCalculatorForm,
    (calculatorForm) => calculatorForm[fieldId]
)

export const getAge = makeGetField('age');
export const getMonthlyIncome = makeGetField('monthlyIncome');
export const getContributionAmount = makeGetField('contributionAmount');
export const getAmountSaved = makeGetField('amountSaved');

export const getAssumptionsModal = createSelector(
    getRetiremntCalculator,
    retirementCalculators => retirementCalculators.openModal
);

export const getRetirementForm = createSelector(
    getRetiremntCalculator,
    retirementCalculators => retirementCalculators.form,
);

export const getEstimatedRetirementIncome = createSelector(
    getRetiremntCalculatorResults,
    retirementCalculators => formatCurrency(retirementCalculators.estimatedRetirementIncome, 'R'),
);

// assumptions model value gen
export const getRetirementAgeValue = createSelector(
    getRetirementAge,
    getAge,
    (retirementAge, currentAge) => {
        if(!retirementAge.value || retirementAge.value > 70 || retirementAge.value < currentAge.value)
        {
            return assumptionModelDefaultValues.retirementAgeDefault;
        }
        else{
            return retirementAge.value
        }
    }
)

export const getNumberOfYearsInRetirementValue = createSelector(
    getNumberOfRetirementYears,
        (numberOfRetirementYears) => {
            if(!numberOfRetirementYears.value ||numberOfRetirementYears.value === null || numberOfRetirementYears.value < 10 ||numberOfRetirementYears.value > 30)
            {
                return assumptionModelDefaultValues.numberOfRetirementYearsDefault;
            }
            else{
                return numberOfRetirementYears.value
            }
        }
    )

export const getExpectedAnnualGrowthValue = createSelector(
    getExpectedAnnualGrowth,
    (expectedAnnualGrowth) => {
            if(!expectedAnnualGrowth.value || expectedAnnualGrowth.value === null || expectedAnnualGrowth.value > 30)
        {
            return (assumptionModelDefaultValues.expectedAnnualGrowthDefault)/100;
        }
        else{
            return (expectedAnnualGrowth.value)/100
        }
    }
)

export const getInflationValue = createSelector(
    getInflation,
    (inflation) => {
        if(!inflation.value || inflation.value === null || inflation.value > 30)
        {
            return (assumptionModelDefaultValues.inflationDefault)/100;
        }
        else{
            return (inflation.value)/ 100
        }
    }
)

export const getExpectedAnnualSalaryIncreaseValue = createSelector(
    getExpectedAnnualSalaryIncrease,
    (expectedAnnualSalaryIncrease) => {
            if(!expectedAnnualSalaryIncrease.value || expectedAnnualSalaryIncrease.value === null || expectedAnnualSalaryIncrease.value > 30)
        {
            return (assumptionModelDefaultValues.expectedAnnualSalaryIncreaseDefault)/100;
        }
        else{
            return (expectedAnnualSalaryIncrease.value)/100
        }
    }
)

export const getReplacementRatioValue = createSelector(
    getReplacementRatio,
    (replacementRatio) => {
            if(!replacementRatio.value || replacementRatio.value === null || replacementRatio.value > 100 )
        {
            return (assumptionModelDefaultValues.replacementRatioDefault)/100;
        }
        else{
            return (replacementRatio.value)/100
        }
    }
)

// calculations

export const getSavingsRetirementAge = createSelector(
    getRetirementAgeValue,
    getAge,
    (retirementAgeValue, currentAge) => {return (retirementAgeValue - currentAge.value)}

)

export const getFutureValueSavings = createSelector(
    getSavingsRetirementAge,
    getExpectedAnnualGrowthValue,
    getExpectedAnnualSalaryIncreaseValue,
    getAmountSaved,
    getContributionAmount,
    (sav_n,expectedAnnualGrowth,expectedAnnualSalaryIncrease,amountSaved,contributionAmount) => {
       return round(
            ls_fv(
                ea_to_freq(
                    expectedAnnualGrowth,12)
                    ,sav_n*12 + 1,parseInt(amountSaved.value)) + 
            ga_freq_fv(expectedAnnualGrowth,12, expectedAnnualSalaryIncrease,sav_n,parseInt(contributionAmount.value)),2
            )
        }
); 

export const getRetirementFirstPayment = createSelector(
    getExpectedAnnualGrowthValue,
    getExpectedAnnualSalaryIncreaseValue,
    getNumberOfYearsInRetirementValue,
    getFutureValueSavings,
    (expectedAnnualGrowth, expectedAnnualSalaryIncreaseValue,numberOfYearsInRetirementValue,futureValueSavings) => {
        return round(
            ga_freq_pmt(expectedAnnualGrowth,12,expectedAnnualSalaryIncreaseValue,numberOfYearsInRetirementValue ,futureValueSavings),2
        )
    }
);

export const getWhatYouWillNeedValue = createSelector(
    getMonthlyIncome,
    getReplacementRatioValue,
    (monthlyIncome, replacementRatioValue) => {return (monthlyIncome.value * replacementRatioValue)}
)

export const getPaymnetPresentValue = createSelector(
    getSavingsRetirementAge,
    getInflationValue,
    getRetirementFirstPayment,
    (sav_n, inflation, retirementFirstPayment) => {
        return round(
        ls_pv(inflation,sav_n,retirementFirstPayment),2
    )}
)

export const getFormatedRetirementFirstPayment = createSelector(
    getRetirementFirstPayment,
    (retirementFirstPayment) => formatCurrency(retirementFirstPayment,'R')
)

export const getFormatedWhatYouWillNeedValue = createSelector(
    getWhatYouWillNeedValue,
    (whatYouWillNeedValue) => formatCurrency(whatYouWillNeedValue,'R')
)

export const getRawPaymentPresentValue = createSelector(
    getPaymnetPresentValue,
    (paymentPresentValue) => paymentPresentValue
)

export const getFormatedPaymnetPresentValue = createSelector(
    getPaymnetPresentValue,
    (paymnetPresentValue) => formatCurrency(paymnetPresentValue,'R')
)

export const getFormatedFutureValueSavings = createSelector(
    getFutureValueSavings,
    (futureValueSavings) => formatCurrency(futureValueSavings,'R')
)

export const getShortFallValue = createSelector(
    getWhatYouWillNeedValue,
    getPaymnetPresentValue,
    (formatedWhatYouWillNeed,formatedPaymentPresentValue) => {return (formatedWhatYouWillNeed - formatedPaymentPresentValue)}
)



export const getShowResultSummary = createSelector(
    getRetiremntCalculator,
    (retirementCalculators) => retirementCalculators.showResultSummary
    
);

export const getButtonDisabledValue = createSelector(
    getShowResultSummary,
    getRetiremntCalculatorFormValid,
    (showResultSummary, formValid) => {
        if(formValid === false && showResultSummary === false){
            return true;
        }
        if(formValid === false && showResultSummary === true){
            return false;
        }
        if(showResultSummary === true && formValid === true){
            return true;
        }
        if(formValid === true && showResultSummary === false){
            return false;
        }
    }
);

export const getShowProductOffering = createSelector(
    getRetiremntCalculator,
    (retirementCalculators) => retirementCalculators.showRecommendation
);
