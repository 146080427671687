import {
    NAVIGATE_PREVIOUS_CONSENT,
    NAVIGATE_NEXT_CONSENT,
    NAVIGATE_PREVIOUS_ID_VERIFICATION,
    NAVIGATE_NEXT_ID_VERIFICATION,
    NAVIGATE_PREVIOUS_SELFIE,
    NAVIGATE_NEXT_SELFIE,
    NAVIGATE_PREVIOUS_KBA_INTERLUDE,
    NAVIGATE_NEXT_KBA_INTERLUDE,
    NAVIGATE_PREVIOUS_KBA,
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
    SET_NAVIGATION_TO_CVS_FROM_PARENT_APP,
    SET_NAVIGATION_BACK_TO_PARENT_APP,
} from "../../actions/customerVerificationService/routing";
import {PAGE_INDICES} from "../../actions/customerVerificationService/types";
import {PARENT_APPS} from "../../actions/customerVerificationService/types";

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    parentAppUrl: null,
    callbackAction: null,
    parentApp: null
};

export default (state = initRouting, action) => {
    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return { 
                ...state,
                doNavigation: true,
                activePageIndex: action.value
            };

        case SET_NAVIGATION_DONE:
            return {...state, doNavigation: false};
        case NAVIGATE_PREVIOUS_CONSENT:
            return {
                ...initRouting,
                doExitNavigation: true,
                parentAppUrl: state.currentAppUrl,
            };
        case NAVIGATE_NEXT_CONSENT:
            return {
                ...state,
                activePageIndex: PAGE_INDICES.ID_VERIFICATION,
                doNavigation: true,
            };
        case NAVIGATE_PREVIOUS_ID_VERIFICATION:
            return {
                ...state,
                activePageIndex: PAGE_INDICES.CONSENT,
                doNavigation: true,
            };
        case NAVIGATE_NEXT_ID_VERIFICATION:
            if (action.imageExists) {
                return {
                    ...state,
                    activePageIndex: PAGE_INDICES.SELFIE,
                    doNavigation: true,
                };
            }
            return {
                ...state,
                activePageIndex: PAGE_INDICES.KBA_INTERLUDE,
                doNavigation: true,
            };

        case NAVIGATE_PREVIOUS_SELFIE:
            return {
                ...state,
                activePageIndex: PAGE_INDICES.CONSENT,
                doNavigation: true,
            };
        case NAVIGATE_NEXT_SELFIE:
            return {
                ...state,
                activePageIndex: PAGE_INDICES.KBA_INTERLUDE,
                doNavigation: true,
            };
        case NAVIGATE_PREVIOUS_KBA_INTERLUDE:
            return {
                ...state,
                activePageIndex: PAGE_INDICES.CONSENT,
                doNavigation: true,
            };
        case NAVIGATE_NEXT_KBA_INTERLUDE:
            return {
                ...state,
                activePageIndex: PAGE_INDICES.KBA,
                doNavigation: true,
            };
        case NAVIGATE_PREVIOUS_KBA:
            return {
                ...state,
                activePageIndex: PAGE_INDICES.CONSENT,
                doNavigation: true,
            };

        case SET_NAVIGATION_TO_CVS_FROM_PARENT_APP:
            return {
                ...state,
                currentAppUrl: action.currentAppUrl,
                parentAppUrl: action.parentAppUrl,
                callbackAction: action.callbackAction,
                doExitNavigation: false,
                parentApp: action.parentApp
            };
        case SET_NAVIGATION_BACK_TO_PARENT_APP:
            return {
                ...initRouting,
                doExitNavigation: true,
                parentAppUrl: state.parentAppUrl,
            };

        default:
            return state;
    }
};
