import {
    ONTHEMONEY_ENROLLEDCOURSES_FETCH,
    ONTHEMONEY_CALENDAREVENTS_FETCH,
    setOnTheMoneyEnrolledCourses,
    setOnTheMoneyCalendarEvents,
} from '../../../actions/publicWeb/onTheMoney';

import { getOnTheMoneyAuthToken } from '../../../selectors/publicWeb/onTheMoney';

const fetchEnrolledCourses = async (store) => {
    const state = store.getState();

    try {
        const res = await fetch(
            `/learning/webservice/rest/server.php?wstoken=${getOnTheMoneyAuthToken(
                state
            )}&moodlewsrestformat=json&wsfunction=core_course_get_enrolled_courses_by_timeline_classification&classification=all`
        );

        const data = await res.json();

        const courses = data.courses.map(
            ({
                id,
                courseimage,
                shortname,
                fullname,
                coursecategory,
                progress,
                viewurl,
            }) => {
                return {
                    uid: id,
                    image_alt: fullname,
                    image_title: fullname,
                    image_url: courseimage,
                    progress_card_category: coursecategory,
                    progress_card_title: shortname || fullname,
                    progress_percent: progress,
                    type: 'article',
                    progress_url: {
                        href: viewurl,
                    },
                };
            }
        );

        store.dispatch(setOnTheMoneyEnrolledCourses(courses));
    } catch (error) {
        store.dispatch(setOnTheMoneyEnrolledCourses([]));
    }
};

const fetchCalendarEvents = async (store) => {
    const state = store.getState();
    const timestamp = Math.floor(Date.now() / 1000);

    try {
        const res = await fetch(
            `/learning/webservice/rest/server.php?wstoken=${getOnTheMoneyAuthToken(
                state
            )}&moodlewsrestformat=json&wsfunction=local_onthemoney_get_calendar_events&options[timestart]=${timestamp}`
        );

        const data = await res.json();

        const events = data?.events.map(
            ({ id, name, timestart, location, courseid }) => {
                const eventDate = new Date(timestart * 1000);
                return {
                    uid: id,
                    event_url: {
                        href: `/learning/calendar/view.php?view=day&course=${
                            courseid ? courseid : 1
                        }&time=${timestart}#event_${id}`,
                    },
                    event_card_title: name,
                    event_date: eventDate.toDateString(),
                    location: location,
                };
            }
        );

        store.dispatch(setOnTheMoneyCalendarEvents(events));
    } catch (error) {
        store.dispatch(setOnTheMoneyCalendarEvents([]));
    }
};

export const onTheMoneyService = (store, next, action) => {
    const result = next(action);

    (async () => {
        if (action.type === ONTHEMONEY_ENROLLEDCOURSES_FETCH) {
            fetchEnrolledCourses(store);
        } else if (action.type === ONTHEMONEY_CALENDAREVENTS_FETCH) {
            fetchCalendarEvents(store);
        }
    })();

    return result;
};
