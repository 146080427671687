import { validateSingleSelection } from "@om/validation";
import { FUND_SET_VALUE, fundSetValid } from "../../../actions/taxFreeSavingsAccount/selectAFund";

let prevValidationAction = null;
export const selectAFundValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FUND_SET_VALUE:
                error = validateSingleSelection(action.value, 'fund');
                return fundSetValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
