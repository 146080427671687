import { COMPLAINT_FORM_SET_VALUE } from "../../../actions/customerComplaint/complaintArbitration"
import { complaintArbitrationValidation } from "./complaintArbitration"

export const customerComplaintValidation = (store, next, action) => {
    if (action.type.startsWith(COMPLAINT_FORM_SET_VALUE)) {
        return complaintArbitrationValidation(store, next, action);
    }

    return null;
}
