
import { personalDetailsValidation } from "./personalDetails";
import { PERSONAL_DETAILS_SET_VALUE } from "../../../actions/digiLifeLoanProtection/personalDetails";
import { PAYMENT_DETAILS_SET_VALUE } from "../../../actions/digiLifeLoanProtection/paymentDetails";
import { paymentDetailsValidation } from "./paymentDetails";
import { UPLOAD_DOCUMENTS_SET_VALUE } from "../../../actions/digiLifeLoanProtection/uploadDocuments";
import { uploadDocumentsValidation } from "./uploadDocuments";

export const dlpValidation = (store, next, action) => {

  if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE)){
      return paymentDetailsValidation(store, next, action);}

  if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE)) {

    return personalDetailsValidation(store, next, action);
  }
  if (action.type.startsWith(UPLOAD_DOCUMENTS_SET_VALUE)) {

    return uploadDocumentsValidation(store, next, action);
  }

  return null;
};
