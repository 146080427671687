import {PERSIST_TAX_FREE_SAVINGS_ACCOUNT_STORE} from '../../actions/taxFreeSavingsAccount/persistedAt'

export const initPersistedAt = (new Date()).getTime();

export default (state = initPersistedAt, action) => {
    switch (action.type){
        case PERSIST_TAX_FREE_SAVINGS_ACCOUNT_STORE:
            return (new Date()).getTime();
        default:
            return state;
    }
}