import {
    ADD_NEW_TRANSITION_BENEFICIARIES,
    EDIT_TRANSITION_BENEFICIARIES,
    DELETE_TRANSITION_BENEFICIARIES,
    SAVE_TRANSITION_BENEFICIARIES,
    CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES,
    TOGGLE_EDIT_TRANSITION_BENEFICIARIES,
    SELECTED_EXISTING_BENEFICIARY_SET_VALUE,
    SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE,
    TITLE_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    DATE_OF_BIRTH_SET_VALUE,
    RELATIONSHIP_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
} from "../../../actions/easiplusFuneralPlan/beneficiaries";
import {
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getRelationship,
    getMobileNumber,
    getEmail,
} from "../../../selectors/easiplusFuneralPlan/beneficiaries";
import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";
import { simpleFieldTracking } from "../formTracking";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const beneficiariesFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case TITLE_SET_VALUE:
                sendError = false;
                return getTitle;
            case FIRST_NAME_SET_VALUE:
                sendValue = false;
                return getFirstName;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurname;
            case DATE_OF_BIRTH_SET_VALUE: return getDateOfBirth;
            case RELATIONSHIP_SET_VALUE: return getRelationship;
            case MOBILE_NUMBER_SET_VALUE:
                sendValue = false;
                return getMobileNumber;
            case EMAIL_SET_VALUE:
                sendValue = false;
                return getEmail;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        simpleFieldTracking({ action, field, sendValue, sendError });
    }

    return result;
}

export const beneficiariesOtherTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case ADD_NEW_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("OPEN_DRAWER");
                return "addNewBeneficiaryToggle";
            case EDIT_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "benificiaryEditClicked";
            case DELETE_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "confirmDeleteClicked";
            case SAVE_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "saveBeneficiaryClicked";
            case CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("CLOSE_DRAWER");
                return "cancelEditBeneficiary";
            case TOGGLE_EDIT_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "editBeneficiaryToggle";
            case SELECTED_EXISTING_BENEFICIARY_SET_VALUE:
                otherEventIndex = findOtherEventIndex("SELECT");
                return "selectExistingBeneficiary";
            case SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                if (action.value === true) return "mustHaveBeneficiaryShowPopUp";
                return "mustHaveBeneficiaryHidePopUp";
            default: return null;
        }
    })();

    if (field)
        otherEventTracking(action.type, otherEventIndex, field);

    return result;
}