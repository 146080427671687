import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    EMPLOYMENT_STATUS_SET_VALUE,
    EMPLOYMENT_STATUS_SET_VALID,
    EMPLOYER_NAME_SET_VALUE,
    EMPLOYER_NAME_SET_VALID,
    SCHEME_NAME_SET_VALUE,
    SCHEME_NAME_SET_VALID,
    OCCUPATION_SET_VALUE,
    OCCUPATION_SET_VALID,
    INDUSTRY_OR_SECTOR_SET_VALUE,
    INDUSTRY_OR_SECTOR_SET_VALID,
    CONTRACT_END_DATE_SET_VALUE,
    CONTRACT_END_DATE_SET_VALID,
    EMPLOYMENT_NUMBER_SET_VALUE,
    EMPLOYMENT_NUMBER_SET_VALID,
    SALARY_RANGE_SET_VALUE,
    SALARY_RANGE_SET_VALID,
    EMPLOYER_POSTAL_ADDRESS_SET_VALUE,
    EMPLOYER_POSTAL_ADDRESS_SET_VALID,
    EMPLOYER_TOWN_OR_CITY_SET_VALUE,
    EMPLOYER_TOWN_OR_CITY_SET_VALID,
    EMPLOYER_PHONE_NUMBER_SET_VALUE,
    EMPLOYER_PHONE_NUMBER_SET_VALID,
    PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE,
    PERSONAL_DATA_PROCESSING_CONSENT_SET_VALID,
    CHILD_DATA_PROCESSING_CONSENT_SET_VALUE,
    CHILD_DATA_PROCESSING_CONSENT_SET_VALID,
    MARKETING_USE_CONSENT_SET_VALUE,
    MARKETING_USE_CONSENT_SET_VALID,
    FORM_VALID_MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM,
    FORM_INVALID_MOTOR_INSURANCE__EMPLOYMENT_DETAILS_FORM,
} from '../../actions/ugMotorInsurance/employmentDetails';

export const initEmploymentDetails = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        employmentStatus: { value: null, error: null, status: null },
        employerName: { value: null, error: null, status: null },
        schemeName: { value: null, error: null, status: null },
        occupation: { value: null, error: null, status: null },
        industryOrSector: { value: null, error: null, status: null },
        contractEndDate: { value: null, error: null, status: null },
        employmentNumber: { value: null, error: null, status: null },
        salaryRange: { value: null, error: null, status: null },
        employerPostalAddress: { value: null, error: null, status: null },
        employerTownOrCity: { value: null, error: null, status: null },
        employerPhoneNumber: { value: null, error: null, status: null },
        personalDataProcessingConsent: { value: false, error: null, status: null },
        childDataProcessingConsent: { value: false, error: null, status: null },
        marketingUseConsent: { value: false, error: null, status: null },
    },
};

export default (state = initEmploymentDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_MOTOR_INSURANCE__EMPLOYMENT_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case EMPLOYMENT_STATUS_SET_VALUE:
            return updateFormField('employmentStatus', (field) => ({ ...field, value: action.value }));

        case EMPLOYMENT_STATUS_SET_VALID:
            return updateFormField('employmentStatus', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMPLOYER_NAME_SET_VALUE:
            return updateFormField('employerName', (field) => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYER_NAME_SET_VALID:
            return updateFormField('employerName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SCHEME_NAME_SET_VALUE:
            return updateFormField('schemeName', (field) => ({
                ...field,
                value: action.value,
            }));
        case SCHEME_NAME_SET_VALID:
            return updateFormField('schemeName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case OCCUPATION_SET_VALUE:
            return updateFormField('occupation', (field) => ({
                ...field,
                value: action.value,
            }));
        case OCCUPATION_SET_VALID:
            return updateFormField('occupation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case INDUSTRY_OR_SECTOR_SET_VALUE:
            return updateFormField('industryOrSector', (field) => ({
                ...field,
                value: action.value,
            }));
        case INDUSTRY_OR_SECTOR_SET_VALID:
            return updateFormField('industryOrSector', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CONTRACT_END_DATE_SET_VALUE:
            return updateFormField('contractEndDate', (field) => ({
                ...field,
                value: action.value,
            }));

        case CONTRACT_END_DATE_SET_VALID:
            return updateFormField('contractEndDate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMPLOYMENT_NUMBER_SET_VALUE:
            return updateFormField('employmentNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMPLOYMENT_NUMBER_SET_VALID:
            return updateFormField('employmentNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SALARY_RANGE_SET_VALUE:
            return updateFormField('salaryRange', (field) => ({
                ...field,
                value: action.value,
            }));

        case SALARY_RANGE_SET_VALID:
            return updateFormField('salaryRange', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMPLOYER_POSTAL_ADDRESS_SET_VALUE:
            return updateFormField('employerPostalAddress', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMPLOYER_POSTAL_ADDRESS_SET_VALID:
            return updateFormField('employerPostalAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMPLOYER_TOWN_OR_CITY_SET_VALUE:
            return updateFormField('employerTownOrCity', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMPLOYER_TOWN_OR_CITY_SET_VALID:
            return updateFormField('employerTownOrCity', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMPLOYER_PHONE_NUMBER_SET_VALUE:
            return updateFormField('employerPhoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMPLOYER_PHONE_NUMBER_SET_VALID:
            return updateFormField('employerPhoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE:
            return updateFormField('personalDataProcessingConsent', (field) => ({
                ...field,
                value: action.value,
            }));

        case PERSONAL_DATA_PROCESSING_CONSENT_SET_VALID:
            return updateFormField('personalDataProcessingConsent', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CHILD_DATA_PROCESSING_CONSENT_SET_VALUE:
            return updateFormField('childDataProcessingConsent', (field) => ({
                ...field,
                value: action.value,
            }));

        case CHILD_DATA_PROCESSING_CONSENT_SET_VALID:
            return updateFormField('childDataProcessingConsent', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MARKETING_USE_CONSENT_SET_VALUE:
            return updateFormField('marketingUseConsent', (field) => ({
                ...field,
                value: action.value,
            }));

        case MARKETING_USE_CONSENT_SET_VALID:
            return updateFormField('marketingUseConsent', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case REINITIALIZE:
            return initEmploymentDetails;
        default:
            return state;
    }
};
