import { validationIsEqual } from '../status';
import { validateAlphaNumericString, validateName, validateNumber, validateNumericString } from '@om/validation';
import {
    REG_NUMBER_SET_VALUE,
    CHASIS_NUMBER_SET_VALUE,
    TIN_NUMBER_SET_VALUE,
    setChasisNoValid,
    setRegistrationNoValid,
    setTinNumberValid,
} from '../../../actions/motorPrivateRW/carDetails';

let prevValidationAction = null;

export const carDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case REG_NUMBER_SET_VALUE:
                error = validateAlphaNumericString(action.value, {
                    subject: 'Vehicle plate number',
                });s
                return setRegistrationNoValid(error);

            case CHASIS_NUMBER_SET_VALUE:
                error = validateAlphaNumericString(action.value, {
                    subject: 'Chassis number',
                });
                return setChasisNoValid(error);

            case TIN_NUMBER_SET_VALUE:
                error = validateNumericString(action.value, {
                    subject: 'TIN Number',
                    max: 9,
                    min: 9
                });
                return setTinNumberValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
