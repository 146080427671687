import * as OmValidationDateHelper from '../helpers/validation-date-helper.js';
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

export function validateDate(
    value,
    {
        min,
        max,
        subject = 'Date',
        required = true,
        retainSubjectCase
    } = {}
) {
    if (!value && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    const date = OmValidationDateHelper.mapToDateParts(value);

    if (!date) {
        return OmValidationMessageHelper.DATE_FORMAT_ERROR;
    }
    const regEx = new RegExp(/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}/);
if(!regEx.test(value)){
    return OmValidationMessageHelper.getGenericRequiredMessage('valid date');
}
    const validDay = validateDay(date);
    const validMonth = validateMonth(date);
    const validYear = validateYear(date);

    if (!validDay || !validMonth || !validYear) {
        return OmValidationMessageHelper.DATE_FORMAT_ERROR;
    }

    if (min) {
        const minDateParts = OmValidationDateHelper.mapToDateParts(min);
        if (!minDateParts) {
            return 'Min date parameter in invalid format.'
        }
        if (!validateMinimumDate(date, minDateParts)) {
            const firstDisallowedMinDate = OmValidationDateHelper.dateDaysInPast(
                OmValidationDateHelper.dateStringToNumberArray(min), 1,
            );
            const minDisplayString = OmValidationDateHelper.toDateString(firstDisallowedMinDate);
            return OmValidationMessageHelper.getDateTooEarlyMsg(minDisplayString, retainSubjectCase);
        }
    }

    if (max) {
        const maxDateParts = OmValidationDateHelper.mapToDateParts(max);
        if (!maxDateParts) {
            return 'Max date parameter in invalid format.'
        }
        if (!validateMaximumDate(date, maxDateParts)) {
            const firstDisallowedMaxDate = OmValidationDateHelper.dateDaysInFuture(
                OmValidationDateHelper.dateStringToNumberArray(max), 1,
            );
            const maxDisplayString = OmValidationDateHelper.toDateString(firstDisallowedMaxDate);
            return OmValidationMessageHelper.getDateTooLateMsg(maxDisplayString, retainSubjectCase);
        }
    }

    return null;
}

function validateMinimumDate(dateParts, minDateParts) {
    if (dateParts.year < minDateParts.year) {
        return false;
    } else if (dateParts.year === minDateParts.year) {
        if (dateParts.month < minDateParts.month) {
            return false;
        } else if (dateParts.month === minDateParts.month) {
            if (dateParts.day < minDateParts.day) {
                return false;
            }
        }
    }
    
    return true;
}

function validateMaximumDate(dateParts, maxDateParts) {
    if (dateParts.year > maxDateParts.year) {
        return false;
    } else if (dateParts.year === maxDateParts.year) {
        if (dateParts.month > maxDateParts.month) {
            return false;
        } else if (dateParts.month === maxDateParts.month) {
            if (dateParts.day > maxDateParts.day) {
                return false;
            }
        }
    }

    return true;
}

function validateDay(date) {
    const month = date.month;
    const day = date.day;
    const year = date.year;
    
    if (isNaN(day)) {
        return false;
    }

    // Change Feb days if it is leap year
    if (year % 4 === 0 && month === 2 && day === 29) {
        return true;
    }

    if (day > OmValidationDateHelper.DAYS_IN_MONTHS[month - 1] || day <= 0) {
        return false;
    }

    return true;
}

function validateMonth(date) {
    const month = date.month;

    if (isNaN(month)) {
        return false;
    }

    return month <= 12 && month > 0;
}

function validateYear(date) {
    const year = date.year;

    if (year < 1850) {
        return false;
    }

    if (isNaN(year)) {
        return false;
    }

    return year > 0;
}
