import {
    SCROLL_TO_CLASS,
} from"../../actions/atRetirement/scrolling";

export const initScrolling = {
    scrollToClass: null
};

export default (state = initScrolling, action) => {
    switch (action.type){
        case SCROLL_TO_CLASS:
            return { scrollToClass: action.value };
        default:
            return state;
    }
}