import {
    getShares,
    getDividendCalcSelectedCountry,
} from '../../../selectors/publicWeb/dividendCalculator';

import {
    CALCULATE_DIVIDENDS,
    setDividendsTotal,
} from "../../../actions/publicWeb/dividendCalculator";

// TECH DEBT - MIGRATE THIS CODE TO A SELECTOR, does not need to be proxied unless the data needs to be obfuscated
export const doDividendsCalculation = async store => {
    const state = store.getState();
    const selectedCountry = getDividendCalcSelectedCountry(state);
    const shares = getShares(state);

    if (!shares || !shares.value || selectedCountry === null) {
        store.dispatch(setDividendsTotal(null));
        return false;
    }

    const dividendValue = selectedCountry.share_price;
    const dividendsTotal = Math.floor((shares.value * dividendValue) * 100) / 100;
    const prefix = selectedCountry.currency_prefix ? `${selectedCountry.currency_prefix} ` : '';
    const suffix = selectedCountry.currency_suffix ? selectedCountry.currency_suffix : '';

    store.dispatch(setDividendsTotal(`${prefix}${dividendsTotal}${suffix}`));
    return true;
};

export const dividendCalculatorService = (store, next, action) => {
    const result = next(action);

    if (action.type === CALCULATE_DIVIDENDS) {
        doDividendsCalculation(store);
    }

    return result;
};