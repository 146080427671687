import { validationIsEqual } from '../status';
import {
    validateAlphaNumericString
} from '@om/validation';
import {
    TRANSACTION_CODE_SET_VALUE,
    setTrasactionCodeValid,
} from '../../../actions/roaTravelInsurance/manualMpesaPayments';

let prevValidationAction = null;

export const manualPayments = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TRANSACTION_CODE_SET_VALUE:
                error = validateAlphaNumericString(
                    action.value,
                    {
                        subject: 'Mpesa transaction code',
                        required: true,
                        min: 10,
                        max: 15,
                    },
                );
                return setTrasactionCodeValid(error);

            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
