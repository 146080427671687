import axios from 'axios';
import { setResponse, requestFail } from '../../../actions/digitalOnboarding/routing';
import { getApplicationNumber, getApplicationOutcome } from '../../../selectors/digitalOnboarding/application';
import * as ActionTypes from '../../../actions/digitalOnboarding/types';

function buildOfferRequestData(onboardingData) {
    const affordability = onboardingData.affordabilityDetails.storeValues;
    const employment = onboardingData.employmentDetails.storeValues;

    return {
        affordability: {
            grossIncome: affordability[ActionTypes.GROSS_INCOME].numeric,
            netIncome: affordability[ActionTypes.NET_INCOME].numeric,
            capitalRequired: affordability[ActionTypes.CAPITAL_NEEDED].numeric,
            groceries: affordability[ActionTypes.GROCERIES].numeric,
            utilities: affordability[ActionTypes.UTILITIES].numeric,
            savings: affordability[ActionTypes.INSURANCE_AND_SAVINGS].numeric,
            services: affordability[ActionTypes.COMMUNICATION_AND_SERVICES].numeric,
            transport: affordability[ActionTypes.TRANSPORT].numeric,
            support: affordability[ActionTypes.SUPPORT_TO_OTHERS].numeric,
            housing: affordability[ActionTypes.HOUSING].numeric,
            other: affordability[ActionTypes.OTHER].numeric
        },
        employment: {
            sector: employment[ActionTypes.SECTOR].value,
            status: employment[ActionTypes.EMPLOYMENT_STATUS].value,
            startDate: employment[ActionTypes.EMPLOYMENT_START_DATE].value,
            endDate: employment[ActionTypes.EMPLOYMENT_CONTRACT_END].value
        },
        declarations: {
            informationProvidedIsCorrect: affordability[ActionTypes.INFORMATION_CONFIRMATION].value,
            debtLiability: employment[ActionTypes.DEBT_LIABILITY].value,
            pendingRetrenchment: employment[ActionTypes.RETRENCHMENT].value,
            knownMedicalCondition: employment[ActionTypes.HAS_MEDICAL_CONDITIONS].value
        },
        bank: employment[ActionTypes.BANK_NAME].value
    };
}

export const createDigitalOfferForApplication = async (store, next, action) => {
    const storeData = store.getState();

    (async () => {
        try {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            const url = `${ActionTypes.FINANCE_API_URL}/applications/${applicationNumber}/offer`;
            const requestData = buildOfferRequestData(storeData.digitalOnboarding);
            const returnData = await axios.post(url, requestData);

            if (ActionTypes.FINANCE_API_URL.includes("localhost")) {
                store.dispatch(setResponse(ActionTypes.OFFER_QUOTE_SUCCESS, returnData.data));
            } else {
                store.dispatch(setResponse(ActionTypes.OFFER_QUOTE_SUCCESS, returnData.data.data));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "createDigitalOfferForApplication",
                    "eventOutcome": "OFFER_QUOTE_SUCCESS",
                    "page": window.location.pathname.split('/')[3]
                });
            }
        } catch (e) {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            if (e?.response?.status === 404 || e === ActionTypes.OFFER_QUOTE_NO_OFFER) {
                store.dispatch(setResponse(ActionTypes.OFFER_QUOTE_DECLINED, null));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "createDigitalOfferForApplication",
                    "eventOutcome": "OFFER_QUOTE_DECLINED",
                    "page": window.location.pathname.split('/')[3]
                });
                return;
            }
            store.dispatch(requestFail(ActionTypes.OFFER_QUOTE_FAIL));
            dataLayer.push({
                "app": "omflending",
                "applicationNumber": applicationNumber,
                "applicationOutcome": applicationOutcome,
                "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                "event": "createDigitalOfferForApplication",
                "eventOutcome": "OFFER_QUOTE_FAIL",
                "page": window.location.pathname.split('/')[3]
            });
        }
    })();

    return next(action);
};

function buildOfferAcceptanceData(quoteStore) {
    const round = number => +number.toFixed(2);

    return {
        offer: {
            instalment: round(quoteStore[ActionTypes.LOAN_INSTALMENT]),
            interestRate: round(quoteStore[ActionTypes.INTEREST_RATE]),
            loanAmount: round(quoteStore[ActionTypes.LOAN_AMOUNT]),
            loanTerm: round(quoteStore[ActionTypes.LOAN_TERM])
        },
        fees: {
            adminFee: round(quoteStore[ActionTypes.ADMIN_FEE]),
            creditLifeInsurance: round(quoteStore[ActionTypes.CREDIT_LIFE]),
            initiationFee: round(quoteStore[ActionTypes.INITIATION_FEE])
        }
    }
}

export const acceptDigitalQuoteOfferForApplication = async (store, next, action) => {
    const storeData = store.getState();

    (async () => {
        try {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            const url = `${ActionTypes.FINANCE_API_URL}/applications/${applicationNumber}/offer/accept`;
            const requestData = buildOfferAcceptanceData(storeData.digitalOnboarding.quoteDetails.storeValues);
            const returnData = await axios.post(url, requestData);
            
            if (ActionTypes.FINANCE_API_URL.includes("localhost")) {
                store.dispatch(setResponse(ActionTypes.ACCEPT_OFFER_REQUEST_SUCCESS, returnData.data));
            } else {
                store.dispatch(setResponse(ActionTypes.ACCEPT_OFFER_REQUEST_SUCCESS, returnData.data.data));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "acceptDigitalQuoteOfferForApplication",
                    "eventOutcome": "ACCEPT_OFFER_REQUEST_SUCCESS",
                    "page": window.location.pathname.split('/')[3]
                });
            }
        } catch (e) {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            store.dispatch(requestFail(ActionTypes.ACCEPT_OFFER_REQUEST_FAIL));
            dataLayer.push({
                "app": "omflending",
                "applicationNumber": applicationNumber,
                "applicationOutcome": applicationOutcome,
                "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                "event": "acceptDigitalQuoteOfferForApplication",
                "eventOutcome": "ACCEPT_OFFER_REQUEST_FAIL",
                "page": window.location.pathname.split('/')[3]
            });
        }
    })();

    return next(action);
};