import axios from 'axios';
import { apiTransitionAgents, setAgentsValue } from '../../../actions/digitalSavings/agentModal';
import { setExpiredAuth } from '../../../actions/digitalSavings/quote';
 
export const getIntermeiaryAgent = (store, next, action) => {
    (async() => {
        store.dispatch(apiTransitionAgents.pending());
        try {
            const state = store.getState();
            const token = state?.digitalSavings?.auth?.loginData?.accessToken
            const intermediaryType = state?.digitalSavings?.agent?.form?.intermediaryType?.value;
            const resp = await calculate(token, intermediaryType);
            store.dispatch(setAgentsValue(resp.data));
            store.dispatch(apiTransitionAgents.success());
        } catch (error) {
            if (error?.response?.data?.status === 401 || error?.response?.data?.status === 403) {
                store.dispatch(setExpiredAuth(true));
            }
            store.dispatch(apiTransitionAgents.failure());
        }
    })();
    return next(action);
}
 
const calculate = async (token, intermediaryType) => {
    try {
        const url = `/om-api/digital-savings/agents/kenya`;
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                intermediary_type: intermediaryType
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};