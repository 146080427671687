export const QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION = 'publicWeb/qualifyingCallMeBack/transition/';

export const TOGGLE_CAPTURE_DETAILS_SIDE_DRAWER = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'toggleSideDrawer';
export const sideDrawerToggle = () => ({ type: TOGGLE_CAPTURE_DETAILS_SIDE_DRAWER });

export const TOGGLE_CREDIT_CHECK_CHECKBOX = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'toggleCreditCheck';
export const creditCheckToggle = () => ({ type: TOGGLE_CREDIT_CHECK_CHECKBOX });
export const creditCheckToggleInvalid = (value) => ({ type: TOGGLE_CREDIT_CHECK_CHECKBOX, value });

export const QUALIFYING_CHECK_CALL_ME_BACK_RETRY = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'retry';
export const retryQualifyingCheck = () => ({ type: QUALIFYING_CHECK_CALL_ME_BACK_RETRY });

export const CONTINUE_TRANSITION_QUALIFYING_CHECK = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'checkQualifyingLoan';
export const checkQualifyingLoan = () => ({ type: CONTINUE_TRANSITION_QUALIFYING_CHECK });

export const RETURN_DATA_FROM_LEADS_QUALIFYING = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'checkQualifyingLoanResponse';
export const returnQualifyingData = (value) => ({ type: RETURN_DATA_FROM_LEADS_QUALIFYING, value });

export const FORM_VALID_TRANSITION_CAPTURE_DETAILS = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'formValid';
export const FORM_INVALID_TRANSITION_CAPTURE_DETAILS = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'formInvalid';

export const transitionQualifyingCheckCallMeBack = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CAPTURE_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CAPTURE_DETAILS })
}

export const QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_INITIAL = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'submitInitial';
export const QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_PENDING = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'submitPending';
export const QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_SUCCESS = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'submitSuccess';
export const QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_ERROR = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'submitError';
export const QUALIFYING_CHECK_CALL_ME_BACK_EMPLOYMENT_ERROR = QUALIFYING_CHECK_CALL_ME_BACK_TRANSITION + 'employmentError';

export const transitionQualifyingCheckSubmitStatus = {
    initial: () => ({ type: QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_INITIAL }),
    pending: () => ({ type: QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_PENDING }),
    success: () => ({ type: QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_SUCCESS }),
    error: () => ({ type: QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_ERROR }),
    employmentError: () => ({ type: QUALIFYING_CHECK_CALL_ME_BACK_EMPLOYMENT_ERROR }),
};

export const CLEAR_FORM = 'publicWeb/qualifyingCallMeBack/clear/form';
export const clearCaptureDetailsFormFields = () => ({ type: CLEAR_FORM });

export const QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE = 'publicWeb/qualifyingCallMeBack/setValue/';
export const QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID = 'publicWeb/qualifyingCallMeBack/setValid/';

const createSetValueAction = (actionName) => `${QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE}${actionName}`;
const createValidationAction = (actionName) => `${QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID}${actionName}`;

export const FIRSTNAME_SET_VALUE = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE + 'firstname';
export const FIRSTNAME_SET_VALID = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID + 'firstname';
export const SURNAME_SET_VALUE = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID + 'surname';
export const ID_NUMBER_SET_VALUE = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID + 'idNumber';
export const MOBILE_NUMBER_SET_VALUE = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE + 'mobileNumber';
export const MOBILE_NUMBER_SET_VALID = QUALIFYING_CHECK_CALL_ME_BACK_SET_VALID + 'mobileNumber';
export const QUALIFYING_TOGGLE_SIDE_DRAWER_WEB = `${QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE}openSideDrawerFromWeb`;
export const QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES = `${QUALIFYING_CHECK_CALL_ME_BACK_SET_VALUE}openSideDrawerFromLendingSales`;
export const EMPLOYMENT_TYPE_SET_VALUE = createSetValueAction('employmentType');
export const EMPLOYMENT_TYPE_SET_VALID = createValidationAction('employmentType');
export const PAYSLIP_AVAILABILITY_SET_VALUE = createSetValueAction('payslipAvailability');
export const PAYSLIP_AVAILABILITY_SET_VALID = createValidationAction('payslipAvailability');
export const WAGE_FREQUENCY_SET_VALUE = createSetValueAction('wageFrequency');
export const WAGE_FREQUENCY_SET_VALID = createValidationAction('wageFrequency');


export const setFirstnameValue = (value) => ({ type: FIRSTNAME_SET_VALUE, value });
export const setFirstnameValid = (error) => ({ type: FIRSTNAME_SET_VALID, error });

export const setEmploymentTypeValue = (value) => ({ type: EMPLOYMENT_TYPE_SET_VALUE, value: value.detail.id });
export const setEmploymentTypeValid = (error) => ({ type: EMPLOYMENT_TYPE_SET_VALID, error });
export const setPayslipAvailabilityValue = (value) => ({ type: PAYSLIP_AVAILABILITY_SET_VALUE, value: value.detail.id });
export const setPayslipAvailabilityValid = (error) => ({ type: EMPLOYMENT_TYPE_SET_VALID, error });
export const setWageFrequencyValue = (value) => ({ type: WAGE_FREQUENCY_SET_VALUE, value: value.detail.id });
export const setWageFrequencyValid = (error) => ({ type: WAGE_FREQUENCY_SET_VALID, error });

export const setSurnameValue = (value) => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = (error) => ({ type: SURNAME_SET_VALID, error });
export const setIdNumberValue = (value) => ({ type: ID_NUMBER_SET_VALUE, value });
export const setIdNumberValid = (error) => ({ type: ID_NUMBER_SET_VALID, error });
export const setMobileNumberValue = (value) => ({ type: MOBILE_NUMBER_SET_VALUE, value });
export const setMobileNumberValid = (error) => ({ type: MOBILE_NUMBER_SET_VALID, error });
export const toggleQualifyingSideDrawerFromWeb = value => ({ type: QUALIFYING_TOGGLE_SIDE_DRAWER_WEB, value });
export const toggleQualifyingSideDrawerFromLendingJourney = value => ({ type: QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES, value });
