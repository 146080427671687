import { transitionSavingsPotCalculatorForm } from '../../../actions/savingsPotCalculator/savingsPotCalculatorForm';
 
 import { getSavingsPotCalculatorForm } from '../../../selectors/savingsPotCalculator/savingsPotCalculatorForm';
 import { FIELD_STATUS } from '../../../reducers/status';
 
 export const savingsPotCalculatorFormRules = (store, next, action) => {
     const result = next(action);
 
     const savingsPotCalculatorForm = getSavingsPotCalculatorForm(store.getState());
     const savingsPotCalculatorValid = validateFormDetails(savingsPotCalculatorForm);
     const formAction = savingsPotCalculatorValid
         ? transitionSavingsPotCalculatorForm.formValid()
         : transitionSavingsPotCalculatorForm.formInvalid();
 
     store.dispatch(formAction);
 
     return result;
 };
 
 const validateFormDetails = (formFields) => {
     return (
         formFields.retirementAmount &&
         formFields.retirementAmount.status === FIELD_STATUS.VALID &&
         formFields.monthlyIncome &&
         formFields.monthlyIncome.status === FIELD_STATUS.VALID
     );
 };