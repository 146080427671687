import { validationIsEqual } from '../status';
import {
    validateDateOfBirth,
    validateEmail,
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateKraPinNumber,
    validateNumericString,
    validateAlphaNumericString,
} from '@om/validation';
import {
    GENDER_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    NATIONALITY_SET_VALUE,
    DOB_SET_VALUE,
    OCCUPATION_SET_VALUE,
    ID_PASSPORT_SET_VALUE,
    KRA_PIN_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    FULL_ADDRESS_SET_VALUE,
    setGenderValid,
    setNationalityValid,
    setOccupationValid,
    setIdPassportValid,
    setFullAddressValid,
    setFirstNameValid,
    setSurnameValid,
    setEmailValid,
    setMobileNumberValid,
    setDobValid,
    setKraPinNumberValid,
    SOURCE_OF_FUNDS_SET_VALUE,
    setSourceOfFundsValid,
} from '../../../actions/digitalSavings/personalDetails';
import { validateDateOfBirthDigitalSavings, getAge } from '@om/validation/src/validators/date-of-birth-validator-digital-savings';
import { getQuoteForm } from '../../../selectors/digitalSavings/quote';
import { mapToDateParts } from '@om/validation/src/helpers/validation-date-helper';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

let prevValidationAction = null;

export const personalDetailsValidation = (store, next, action) => {
    const quotation = getQuoteForm(store.getState());
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });
                return setFirstNameValid(error);

            case SURNAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });
                return setSurnameValid(error);

            case DOB_SET_VALUE:
                const date = mapToDateParts(action.value);
                const age = getAge(date);
                const yearsToNotGoAboveSavingValue = 75 - age;
                error = validateDateOfBirthDigitalSavings(action.value, { min: 18, max: 70, yearsToNotGoAboveSaving: quotation.duration.value, yearsToNotGoAboveSavingValue  });
                return setDobValid(error);

            case GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Gender',
                });
                return setGenderValid(error);

            case NATIONALITY_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setNationalityValid(error);

            case OCCUPATION_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setOccupationValid(error);
            case SOURCE_OF_FUNDS_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setSourceOfFundsValid(error);

            case ID_PASSPORT_SET_VALUE:
                error = validateNumericString(action.value, { min: 8, max: 15 });
                return setIdPassportValid(error);

            case KRA_PIN_NUMBER_SET_VALUE:
                error = validateKraPinNumber(action.value);
                return setKraPinNumberValid(error);

            case MOBILE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Phone Number',
                    countryCode: gatsbyStack,
                });
                return setMobileNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case FULL_ADDRESS_SET_VALUE:
                error = validateAlphaNumericString(action.value);
                return setFullAddressValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
