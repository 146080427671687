// actions
import { NAVIGATE_NEXT } from '../../../actions/roaTravelInsurance/routing';
import {
    ROA_APPLICATION_REFERENCE_DATA_GET_VALUE,
    ROA_COUNTRIES_REFERENCE_DATA_GET_VALUE,
} from '../../../actions/roaTravelInsurance/referenceData';
import { COLLECT_TRAVEL_DETAILS_DATA } from '../../../actions/roaTravelInsurance/travelDetails';

import {
    COLLECT_EXTRA_TRAVELLER_DETAILS_DATA,
    COLLECT_TRAVEL_INSURANCE_PAYMENTS,
    COLLECT_TRAVEL_INSURANCE_PAYMENTS_STATUS,
    COLLECT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER,
    COLLECT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE
} from '../../../actions/roaTravelInsurance/addTraveller';

import {COLLECT_TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENTS} from '../../../actions/roaTravelInsurance/manualMpesaPayments';

// selectors
import { getIsDropoffPage } from '../../../selectors/roaTravelInsurance/routing';

// same directory imports
import { dropOffLeadsService } from './dropOffLead';
import { travelDetailsService } from './travelDetails';
import { addExtratravellerDetailsService } from './addExtraTraveller';
import { travelInsuranceMpesaPaymentService } from './travelInsurancepayments';
import { travelInsuranceMpesaPaymentStatusService } from './checkMpesaPaymentsStatus';
import { generateAccountNumberService } from './generateAccountNo';
import {generateTravelCertService} from './generateCert';
import {titleReferenceDataService} from './titleReferenceData';
import {countriesReferenceDataService} from './countriesReferenceData';
import {travelInsuranceManualMpesaPaymentService} from './manualPayments'

// ROA Travel services
export const roaTravelInsuranceServices = (store, next, action) => {
    if (action.type === COLLECT_TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENTS) {
        return travelInsuranceManualMpesaPaymentService(store, next, action);
    }
    if (action.type === COLLECT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER) {
        return generateAccountNumberService(store, next, action);
    }
    if (action.type === COLLECT_TRAVEL_DETAILS_DATA) {
        return travelDetailsService(store, next, action);
    }
    if (action.type === COLLECT_TRAVEL_INSURANCE_PAYMENTS) {
        return travelInsuranceMpesaPaymentService(store, next, action);
    }
    if (action.type === COLLECT_TRAVEL_INSURANCE_PAYMENTS_STATUS) {
        return travelInsuranceMpesaPaymentStatusService(store, next, action);
    }
    if (action.type === COLLECT_EXTRA_TRAVELLER_DETAILS_DATA) {
        return addExtratravellerDetailsService(store, next, action);
    }

    if (action.type === COLLECT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE) {
        return generateTravelCertService(store, next, action);
    }

    if (action.type === ROA_APPLICATION_REFERENCE_DATA_GET_VALUE) {
        return titleReferenceDataService(store, next, action);
    }
    if (
        action.type === ROA_COUNTRIES_REFERENCE_DATA_GET_VALUE
    ) {
        return countriesReferenceDataService(store, next, action);
    }
    if (action.type === NAVIGATE_NEXT && getIsDropoffPage(store.getState())) {
        return dropOffLeadsService(store, next, action);
    }
   

    return null;
};
