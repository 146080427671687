// Transitions
export const ABOUT_YOU_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER = 'atRetirement/wizardFooter/transition/aboutYouContinueButtonDisabled';
export const SOLUTIONS_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER = 'atRetirement/wizardFooter/transition/solutionsContinueButtonDisabled';
export const CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER = 'atRetirement/wizardFooter/transition/visibleContinueButton';

export const transitionWizardFooter = {
    aboutYouContinueButtonDisabled: (value = true) => ({ type: ABOUT_YOU_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER, value: value }),
    solutionsContinueButtonDisabled: (value = true) => ({ type: SOLUTIONS_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER, value: value }),
    continueButtonVisible: (value = true) => ({ type: CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER, value: value }),
};

// Events
export const WIZARD_FOOTER_SET_VALUE = 'atRetirement/wizardFooter/setValue/';
export const STEP_INDEX_SET_VALUE = WIZARD_FOOTER_SET_VALUE + 'stepIndex';

export const setStepIndexValue = value => ({ type: STEP_INDEX_SET_VALUE, value });