import { createSelector } from 'reselect';
import { FORM_STATUS } from '../../reducers/status';

export const getLtd = (state) => {
    return state.ltd;
};

export const getComponents = createSelector(getLtd, (ltd) => ltd && ltd.components);

export const getRegistration = createSelector(
    getComponents,
    (components) => components && components.registration
);

export const getAssetDownload = createSelector(
    getComponents,
    (components) => components && components.assetDownload
);

export const getSearchAndFilter = createSelector(
    getComponents,
    (components) => components && components.searchAndFilter
);

const makeGetRegistration = (fieldId) =>
    createSelector(getRegistration, (registration) => registration && registration[fieldId]);

const makeGetAssetDownload = (fieldId) =>
    createSelector(getAssetDownload, (assetDownload) => assetDownload && assetDownload[fieldId]);

const makeGetSearchAndFilter = (fieldId) =>
    createSelector(
        getSearchAndFilter,
        (searchAndFilter) => searchAndFilter && searchAndFilter[fieldId]
    );

export const getUserType = makeGetRegistration('userType');
export const getLearnerPlatformRepresentativeValue = makeGetRegistration(
    'learnerPlatformRepresentative'
);
export const getOrganisationIdValue = makeGetRegistration('organisationId');
export const getEmailValue = makeGetRegistration('email');
export const getOrganisationValue = makeGetRegistration('organisation');
export const getCheckboxValue = makeGetRegistration('checkbox');
export const getContentDisplayedValue = makeGetRegistration('contentDisplayed');
export const getPlatformOptionsValue = makeGetRegistration('platformOptions');
export const getUserTypesValue = makeGetRegistration('userTypes');
export const getDownloadsValue = makeGetRegistration('downloads');

export const getIsButtonEnabled = createSelector(
    getUserType,
    getOrganisationIdValue,
    getEmailValue,
    getCheckboxValue,
    getOrganisationValue,
    (userType, organisationId, email, checkbox, organisation) => {
        return (
            userType &&
            organisationId &&
            email.status === FORM_STATUS.VALID &&
            checkbox &&
            (organisationId === -1 ? organisation.status === FORM_STATUS.VALID : true)
        );
    }
);

export const getIsLoadingLearnerPlatformOrUserTypes = createSelector(
    getPlatformOptionsValue,
    getUserTypesValue,
    (platformOptions, userTypes) =>
        !platformOptions ||
        platformOptions.length <= 0 ||
        !userTypes ||
        userTypes.length <= 0
);

export const getModalOpenValue = makeGetAssetDownload('modalOpen');
export const getIsToastVisible = makeGetAssetDownload('toastVisible');
export const getLanguageCheckboxesValue = makeGetAssetDownload('languageCheckboxes');
export const getDropdownValue = makeGetAssetDownload('dropdown');

export const getSomeLanguagesChosen = createSelector(
    getLanguageCheckboxesValue,
    (languageCheckboxes) => {
        return languageCheckboxes
            ? languageCheckboxes.some((language) => {
                  return language.checked;
              })
            : false;
    }
);

export const getAllLanguagesChosen = createSelector(
    getLanguageCheckboxesValue,
    (languageCheckboxes) => {
        return languageCheckboxes
            ? languageCheckboxes.every((language) => {
                  return language.checked;
              })
            : false;
    }
);

export const getLanguagesChosen = createSelector(
    getLanguageCheckboxesValue,
    (languageCheckboxes) => {
        return languageCheckboxes ? languageCheckboxes.filter((element) => element.checked) : [];
    }
);

export const getFilterVisibleValue = makeGetSearchAndFilter('filterVisible');
export const getActivePageNumberValue = makeGetSearchAndFilter('activePageNumber');
export const getSearchValue = makeGetSearchAndFilter('searchValue');
export const getTotalCardsVisibleValue = makeGetSearchAndFilter('totalCardsVisible');
export const getCheckboxesValue = makeGetSearchAndFilter('checkboxes');
export const getFilterCheckboxes = makeGetSearchAndFilter('filterCheckboxes');
export const getTopics = makeGetSearchAndFilter('topics');
export const getDisplayedTopics = makeGetSearchAndFilter('displayedTopics');

export const filter_headers = {
    EDUCATION_LEVEL: 'Education Level',
    CURRICULUM: 'Curriculum',
    SUBJECT: 'Subject',
    PREFERED_LANGUAGE: 'Prefered Language',
};
export const cardsPerPage = 9;

export const getFilters = createSelector(getTopics, (topics) => {
    const filters = [
        { filters: [], header: filter_headers.EDUCATION_LEVEL },
        { filters: [], header: filter_headers.CURRICULUM },
        { filters: [], header: filter_headers.SUBJECT },
        { filters: [], header: filter_headers.PREFERED_LANGUAGE },
    ];

    if (topics) {
        topics.forEach((item) => {
            filters[2].filters.push(item.header);
            item.cards.forEach((card) => {
                filters[0].filters.push(card.grade);
                filters[1].filters.push(card.card_header_curriculum);
                filters[3].filters.push(card.languages);
            });
        });
    }
    // To flatten out the langauges and then remove any duplicates in the list as well as sort them,
    // grades need to be sorted using a custom function
    filters[3].filters = filters[3].filters.flat();
    filters[0].filters = [
        ...new Set(
            filters[0].filters.sort(function(x, y) {
                const xGrade = parseInt(x.slice(5));
                const yGrade = parseInt(y.slice(5));
                if (xGrade < yGrade) {
                    return -1;
                }
                if (xGrade > yGrade) {
                    return 1;
                }
                return 0;
            })
        ),
    ];
    filters[1].filters = [...new Set(filters[1].filters.sort())];
    filters[2].filters = [...new Set(filters[2].filters.sort())];
    filters[3].filters = [...new Set(filters[3].filters.sort())];
    return filters;
});

export const calculateTotalCards = createSelector(getDisplayedTopics, (currentTopicsDisplayed) => {
    let total = 0;
    currentTopicsDisplayed.forEach((topic) => {
        if (topic && topic.cards) {
            topic.cards.forEach(() => {
                total++;
            });
        }
    });
    return total;
});

// This is used to split the cards up using cardsPerPage, so that only cardsPerPage cards are displayed at once
export const getSplitCards = createSelector(getDisplayedTopics, (currentTopicsDisplayed) => {
    const splitCards = [];
    let counter = 0;
    currentTopicsDisplayed.forEach((topic, outerIndex) => {
        if (!topic.cards) {
            return;
        }

        topic.cards.forEach((card) => {
            const splitIndex = Math.floor(counter++ / cardsPerPage);
            if (!splitCards[splitIndex]) {
                splitCards[splitIndex] = [];
            }
            if (!splitCards[splitIndex][outerIndex]) {
                splitCards[splitIndex][outerIndex] = {
                    header: null,
                    cards: [],
                };
            }

            splitCards[splitIndex][outerIndex].header = topic.header;
            splitCards[splitIndex][outerIndex].cards.push(card);
        });
    });
    return splitCards;
});

// This determines if the card should be added
// A card will only be added if it matches the criteria described below
// If the user selects multiple checkboxes from the same category, e.g education level, all cards matching those criteria will be shown
// However is a user also selects a checkbox from another category,
// then only cards that match the education level filter AND the new category filter will be shown
const addCard = (card, checkedBoxes) => {
    const allowCard = Object.entries(checkedBoxes).map(([key, item]) => {
        const boxName = item;
        const category = key;
        switch (category) {
            case filter_headers.EDUCATION_LEVEL:
                if (boxName.length !== 1) {
                    return boxName.some((item) => card.grade === item);
                }
                return card.grade === boxName[0];

            case filter_headers.CURRICULUM:
                if (boxName.length !== 1) {
                    return boxName.some((item) => card.card_header_curriculum === item);
                }
                return card.card_header_curriculum === boxName[0];

            case filter_headers.PREFERED_LANGUAGE:
                if (boxName.length !== 1) {
                    return card.languages.some((langauge) =>
                        boxName.some((item) => langauge === item)
                    );
                }
                return card.languages.some((langauge) => langauge === boxName[0]);

            default:
                return true;
        }
    });

    return allowCard.length !== 0 ? allowCard.every((val) => val) : true;
};

const addSubject = (header, checkedBoxes) => {
    if (checkedBoxes.length !== 1) {
        return checkedBoxes.some((item) => header === item);
    }
    return header === checkedBoxes[0];
};

const searchSection = (topics, searchValue) => {
    if (searchValue === '') {
        return topics;
    }
    let topicSearchGroup = [];
    topics.forEach((topic, outerIndex) => {
        if (topic.cards) {
            topic.cards.forEach((card) => {
                if (card.card_header_topic.toLowerCase() === searchValue.toLowerCase()) {
                    if (!topicSearchGroup[outerIndex]) {
                        topicSearchGroup[outerIndex] = {
                            header: null,
                            cards: [],
                        };
                    }
                    topicSearchGroup[outerIndex].cards.push(card);
                    topicSearchGroup[outerIndex].header = topic.header;
                }
            });
        }
    });
    return topicSearchGroup;
};

const filterSection = (checkedBoxes, topicGroup, topicSearchGroup) => {
    // The filters are grouped into arrays with indeces of the category,
    // So that the addCard function can parse them easily and match correctly

    const filterGroups = checkedBoxes.reduce((prev, item) => {
        const result = { ...prev };

        if (!result[item.category]) {
            result[item.category] = [];
        }
        result[item.category].push(item.boxName);
        return result;
    }, {});

    topicSearchGroup.forEach((topic, outerIndex) => {
        if (!topic) {
            return;
        }
        if (Object.keys(filterGroups).some((key) => key === filter_headers.SUBJECT)) {
            if (!addSubject(topic.header, filterGroups[filter_headers.SUBJECT])) {
                return;
            }
            const tempGroup = [];
            tempGroup[outerIndex] = topic;
            if (!tempGroup[outerIndex].cards) {
                return;
            }
            tempGroup[outerIndex].cards.forEach((card) => {
                if (!addCard(card, filterGroups)) {
                    return;
                }
                if (!topicGroup[outerIndex]) {
                    topicGroup[outerIndex] = {
                        header: null,
                        cards: [],
                    };
                }
                topicGroup[outerIndex].cards.push(card);
                topicGroup[outerIndex].header = tempGroup[outerIndex].header;
            });
        } else {
            if (!topic.cards) {
                return;
            }
            topic.cards.forEach((card) => {
                if (!addCard(card, filterGroups)) {
                    return;
                }
                if (!topicGroup[outerIndex]) {
                    topicGroup[outerIndex] = {
                        header: null,
                        cards: [],
                    };
                }
                topicGroup[outerIndex].cards.push(card);
                topicGroup[outerIndex].header = topic.header;
            });
        }
    });
    return { topicGroup, topicSearchGroup };
};

export const getFilteredTopics = createSelector(
    getTopics,
    getSearchValue,
    getCheckboxesValue,
    (topics, searchValue, checkboxes) => {
        let topicGroup = [];
        let topicSearchGroup = searchSection(topics, searchValue);

        // Filter Section
        const checkedBoxes = checkboxes.filter((checkbox) => checkbox.checked);

        if (checkedBoxes.length === 0) {
            topicGroup = topicSearchGroup;
        } else {
            const filtered = filterSection(checkedBoxes, topicGroup, topicSearchGroup);
            topicGroup = filtered.topicGroup;
            topicSearchGroup = filtered.topicSearchGroup;
        }
        return topicGroup;
    }
);
