import { getIsApiPending, getQuoteForm } from '../../../selectors/ugMotorInsurance/quote';
import { apiTransitionMotorInsuranceQuoteForm, calculateMotorInsurancePremiumSuccess } from '../../../actions/ugMotorInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';


export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionMotorInsuranceQuoteForm.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateMotorInsurancePremiumSuccess(resp.data));
                store.dispatch(apiTransitionMotorInsuranceQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionMotorInsuranceQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);

    const premiumCalculationPayload = {
        carValue: quotation.valuation.value,
        violenceAndTerrorismCovered: quotation.violenceAndTerrorism.value,
        uncommonRisksCovered: quotation.uncommonRisk.value,
        excessProtectorOption: quotation.excessProtector.value,
        use: quotation.carUse.value || 'private',
        carHire: quotation.courtesyCar.value,
        carHireLimit: quotation.courtesyCarAmount.value,
        personalAccident: quotation.personalAccident.value || false,
    };

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-motor-insurance/premium-calculation/kenya';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken
            },
        });
    } catch (e) {
        console.error(e);
        throw e
    }
};
