import { transitionPastPerformanceCalculator } from '../../../actions/pastPerformanceCalculator/pastPerformanceCalculator';
import { getInvestmentAmount, getInvestmentTerm, getFund } from '../../../selectors/pastPerformanceCalculator/pastPerformanceCalculator';
import { FIELD_STATUS } from '../../../reducers/status';

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validatePastPerfomanceCalculator = state => {
    const investmentAmount = getInvestmentAmount(state);
    const investmentTerm = getInvestmentTerm(state);
    const investmentFund = getFund(state);
    return isValid(investmentAmount) && investmentTerm.value  && investmentFund.value;
};

export const pastPerfomanceCalculatorRulesEngine = (store, next, action) => {
    const result = next(action);

    const pastPerfomanceCalculatorValid = validatePastPerfomanceCalculator(store.getState());
    store.dispatch(pastPerfomanceCalculatorValid
        ? transitionPastPerformanceCalculator.formValid()
        : transitionPastPerformanceCalculator.formInvalid());

    return result;
};
