import {fireGtmEvent} from "../gtmEvents";

import {
    PERFORM_QUERY_LOG,
    PERFORM_CLICK_LOG
} from "../../../actions/publicWeb/search";

import {
    getPage,
    getSearchResults,
    getSearchQuery,
    getSite
} from '../../../selectors/publicWeb/search';

let searchResultClickGtmEven = (state, title) => {
    fireGtmEvent(
        'search_result_submission',
        {
            search_context: {
                ls: "searchresult",
                ua: navigator.userAgent,
                sw: getSearchQuery(state),
                brl: navigator.language,
                hn: location.hostname,
                pn: getPage(state),
                qid: getSearchQueryId(state),
                rc: getSearchResultsCount(state),
                sz: screen.width +'x'+screen.height,
                title: title,
                site: getSite(state)
            }
        }
    );
};

function getSearchQueryId(state) {
    let results = getSearchResults(state);

    if(results.data) {
        return results.data.SearchQueryId;
    }
}

function getSearchResultsCount(state) {
    let results = getSearchResults(state);

    if(results.data) {
        return results.data.Results.Results.length;
    }
}

let searchClickGtmEvent = (state) => {
    fireGtmEvent(
        'search_click',
        {
            search_context: {
                sw: getSearchQuery(state),
                brl: navigator.language,
                hn: location.hostname,
                pn: getPage(state),
                qid: getSearchQueryId(state),
                rc: getSearchResultsCount(state),
                sz: screen.width +'x'+screen.height,
                ua: navigator.userAgent,
                site: getSite(state)
            }
        }
    );
};

export const searchCludoTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    switch (action.type) {
        case PERFORM_QUERY_LOG:
            searchClickGtmEvent(state);
            return;
        case PERFORM_CLICK_LOG:
            searchResultClickGtmEven(state, action.value);
            return;
    }

    return result;
}
