export const HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS =
    'healthInsuranceRw/healthAgentModalDetails/';


export const HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE =
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS + 'setValue/';
export const HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALID =
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS + 'setValid/';

export const MODAL_FORM_DISPLAY =
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE + 'modalFormDisplay';

export const REINITIALIZE =
    'healthInsuranceRw/healthAgentModalDetails/state/reinitialize';

export const FORM_VALID_HEALTH_INSURANCE_RW_AGENT_MODAL_FORM =
    'healthInsuranceRw/healthAgentModalDetails/transition/formValid';
export const FORM_INVALID_HEALTH_INSURANCE_RW_AGENT_MODAL_FORM =
    'healthInsuranceRw/healthAgentModalDetails/transition/formInValid';

export const API_PENDING_AGENTS = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS + 'api/pending'
export const API_SUCCESS_AGENTS = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS + 'api/sucesss';
export const API_FAILURE_AGENTS = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS + 'api/failure';
export const TRIGGER_FETCH_AGENTS = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS + 'trigger/agents';

export const SET_INTERMEDIARY_TYPE_VALUE = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE + 'intermediaryType';
export const SET_INTERMEDIARY_TYPE_VALID = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALID + 'intermediaryType';
export const transitionAgentModalForm = {
    formValid: () => ({
        type: FORM_VALID_HEALTH_INSURANCE_RW_AGENT_MODAL_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_HEALTH_INSURANCE_RW_AGENT_MODAL_FORM,
    }),
};

export const apiTransitionAgents = {
    pending: () => ({type: API_PENDING_AGENTS}),
    success: () => ({type: API_SUCCESS_AGENTS}),
    failure: () => ({type: API_FAILURE_AGENTS})
}

export const setMotorAgentModalDisplay = value => ({
    type: MODAL_FORM_DISPLAY,
    value,
});

export const setAgentModalToInitial = () => ({
	type: REINITIALIZE,
});

export const SET_AGENTS = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE + 'Allagents';

export const AGENT_NAME_SET_VALUE =
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE + 'name';
export const AGENT_NAME_SET_VALID =
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALID + 'name';

export const AGENT_CODE_SET_VALUE =
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE + 'phoneNumber';
export const AGENT_CODE_SET_VALID = HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALID + 'phoneNumber';

export const setNameValue = value => ({
    type: AGENT_NAME_SET_VALUE,
    value,
});
export const setNameValid = error => ({
    type: AGENT_NAME_SET_VALID,
    error,
});

export const setAgentCodeValue = (value) => ({
	type: AGENT_CODE_SET_VALUE,
	value,
});
export const setAgentCodeValid = (error) => ({
	type: AGENT_CODE_SET_VALID,
	error,
});

export const setIntermediaryTypeValue = (value) => ({
        type: SET_INTERMEDIARY_TYPE_VALUE,
        value
    }
);

export const setIntermediaryTypeValid = (error) => (
    {
        type: SET_INTERMEDIARY_TYPE_VALID,
        error
    }
);

export const setAllAgentsValue = (value) => ({
    type: SET_AGENTS,
    value
});

export const triggerFetchAgents = () => ({
    type: TRIGGER_FETCH_AGENTS,
});
