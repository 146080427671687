import {
    DIVIDENDS_TOTAL_SET_VALUE,
    DIVIDEND_CALC_SHARES_SET_VALUE,
    DIVIDEND_CALC_SHARES_SET_VALID,
    DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE,
    FORM_VALID_TRANSITION_DIVIDEND_CALCULATOR,
    FORM_INVALID_TRANSITION_DIVIDEND_CALCULATOR,
} from '../../actions/publicWeb/dividendCalculator'
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initialState = {
    dividendsTotal: null,
    shares:  {
        value: '',
        error: null,
        status: null,
    },
    formStatus: FORM_STATUS.INVALID,
    selectedCountry: null,
};

export default function (state = initialState, action) {

    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId]),
    });

    switch (action.type) {
        case DIVIDENDS_TOTAL_SET_VALUE:
            return {
                ...state,
                dividendsTotal: action.value
            };
        case DIVIDEND_CALC_SHARES_SET_VALUE:
            return updateFormField('shares', field => ({
                ...field,
                value: action.value,
            }));
        case DIVIDEND_CALC_SHARES_SET_VALID:
            return updateFormField('shares', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE:
            return {
                ...state,
                selectedCountry: action.value,
            };
        case FORM_VALID_TRANSITION_DIVIDEND_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            };

        case FORM_INVALID_TRANSITION_DIVIDEND_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID
            };
        default:
            return state;
    }
}
