import { SCROLL_TO_CLASS, SCROLL_TO_ID, CLEAR_SCROLL } from '../../actions/savingsPotCalculator/scrolling';
 
export const initScrolling = {
    scrollToClass: SCROLL_TO_CLASS,
    scrollToId: SCROLL_TO_ID,
};

export default (state = initScrolling, action) => {
    switch (action.type) {
        case SCROLL_TO_CLASS:
            return { scrollToClass: action.value };

        case SCROLL_TO_ID:
            return { scrollToId: action.value };

        case CLEAR_SCROLL:
            return { scrollToId: null };

        default:
            return state;
    }
};