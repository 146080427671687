import { transitionHealthInsuranceRwQuoteForm } from '../../../actions/healthInsuranceRW/quote';
import { getQuoteForm } from '../../../selectors/healthInsuranceRW/quote';

import { FIELD_STATUS } from '../../../reducers/status';

export const healthQuoteFormRules = (store, next, action) => {
    const result = next(action);
    const healthInsuranceQuoteForm = getQuoteForm(store.getState());
    let healthQuoteForm = false;
    if(healthInsuranceQuoteForm.isSpouseIncluded.value === true){
        healthQuoteForm = validateHealthQuteFormChecksSpouse(healthInsuranceQuoteForm);
    }else if(healthInsuranceQuoteForm.isChildrenIncluded.value === true) {
        healthQuoteForm = validateHealthQuteFormChecksChildren(healthInsuranceQuoteForm);
    }else {
        healthQuoteForm = validateHealthQuteForm(healthInsuranceQuoteForm);
    }
    const formAction = healthQuoteForm
        ? transitionHealthInsuranceRwQuoteForm.formValid()
        : transitionHealthInsuranceRwQuoteForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateHealthQuteForm = formFields => {
    return (
        formFields.selectedCover &&
        formFields.selectedCover.status === FIELD_STATUS.VALID &&
        formFields.age &&
        formFields.age.status === FIELD_STATUS.VALID,
        formFields.inpatientCover &&
        formFields.inpatientCover.status === FIELD_STATUS.VALID &&
        formFields.outpatientCover &&
        formFields.outpatientCover.status === FIELD_STATUS.VALID
        // formFields.isSpouseIncluded && (formFields.spouseAge && formFields.spouseAge.status === FIELD_STATUS.VALID) &&
        // formFields.isChildrenIncluded && (formFields.childrenAge && formFields.childrenAge.status === FIELD_STATUS.VALID)
    );
};

const validateHealthQuteFormChecksSpouse = formFields => {
    return (
        formFields.selectedCover &&
        formFields.selectedCover.status === FIELD_STATUS.VALID &&
        formFields.age &&
        formFields.age.status === FIELD_STATUS.VALID,
        formFields.inpatientCover &&
        formFields.inpatientCover.status === FIELD_STATUS.VALID &&
        formFields.outpatientCover &&
        formFields.outpatientCover.status === FIELD_STATUS.VALID &&
        formFields.spouseAge && 
        formFields.spouseAge.status === FIELD_STATUS.VALID
        // formFields.isChildrenIncluded && (formFields.childrenAge && formFields.childrenAge.status === FIELD_STATUS.VALID)
    );
};

const validateHealthQuteFormChecksChildren = formFields => {
    return (
        formFields.selectedCover &&
        formFields.selectedCover.status === FIELD_STATUS.VALID &&
        formFields.age &&
        formFields.age.status === FIELD_STATUS.VALID,
        formFields.inpatientCover &&
        formFields.inpatientCover.status === FIELD_STATUS.VALID &&
        formFields.outpatientCover &&
        formFields.outpatientCover.status === FIELD_STATUS.VALID &&
        formFields.childrenAge && 
        formFields.childrenAge.status === FIELD_STATUS.VALID
        // formFields.isChildrenIncluded && (formFields.childrenAge && formFields.childrenAge.status === FIELD_STATUS.VALID)
    );
};
