import axios from "axios";
import {
    getCountryOfBirth,
    getEmail,
    getGcsPostalAddress,
    getGcsStreetAddress,
    getId,
    getIncomeTax,
    getMaritalStatus,
    getMiddleName,
    getMobile,
    getName,
    getNationality,
    getSurname,
    getTitle,
} from "../../../selectors/raOptimal/personalDetails";
import {
    getAdventurousInvestorIsSelected,
    getChooseOwnPercentageIsSelected,
    getComfortableInvestorIsSelected,
    getLinkWithInflationIsSelected,
    getMonthlyInvestment,
    getMonthlyInvestmentDerivedTerm,
    getNoIncreaseIsSelected,
    getOwnPercentage,
} from "../../../selectors/raOptimal/investmentSetup";
import {
    getAccountHolderName,
    getAccountNumber,
    getAccountType,
    getBankName,
    getDebitOrderDay,
    getEmploymentIndustry,
    getEmploymentIndustryOther,
    getEmploymentPosition,
    getSourceOfFunds,
    getSourceOfIncome,
    getSourceOfIncomeOther,
    getStartMonth
} from "../../../selectors/raOptimal/paymentDetails";
import {
    getName as getCaptureDetailsName,
    getSurname as getCaptureDetailsSurname,
    getMobile as getCaptureDetailsMobile,
} from "../../../selectors/raOptimal/captureDetails";
import {
    getReferenceCountryDialingCodes,
    getReferenceFunds,
    getReferenceNationalities,
    getReferenceSourceOfFunds,
    getReferenceSourceOfIncomes
} from "../../../selectors/raOptimal/referenceData";
import { getBeneficiariesSplitForm } from "../../../selectors/raOptimal/beneficiarySplit";
import { getBeneficiaryList } from "../../../selectors/raOptimal/beneficiaries";
import { failureRaOptimalSubmission, successRaOptimalSubmission } from "../../../actions/raOptimal/review";
import { ANNUAL_PREMIUM_INCREASES, DEFAULT_COUNTRY, INVESTOR_TYPES } from "../../../reducers/raOptimal/constants";
import { setRequestId } from "../../../actions/raOptimal/sessionCamVariables";

export const submitApplicationService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const payload = buildPayload(state);

    (async () => {
        const response = await submitApplication(payload);
        if (response && (response.status === 200)) {
            store.dispatch(successRaOptimalSubmission({
                proposalNumber: response.data.proposalNumber,
                referenceNumber: response.data.applicationReferenceNumber,
            }));
        } else {
            store.dispatch(failureRaOptimalSubmission({error: response}));
        }
        
        if (response && 'data' in response && 'requestId' in response.data) {
            store.dispatch(setRequestId(response.data.requestId));
        }
    })();

    return result;
};

export const submitApplication = async payload => {
    const url = '/om-api/ra-optimal/submit-application';
    const config = {headers: {'Content-Type': 'application/json'}};

    try {
        const response = await axios.post(url, payload, config);
        return response;
    } catch (error) {
        return null;
    }
}

export const buildPayload = state => {
    const mapSelectors = mapSelectorsFromState(state);
    const identityNumber = getId(state).value;
    const cellphoneNumberCountryCode = getReferenceCountryDialingCodes(state)[0].code;

    const firstName = (getName(state) || getCaptureDetailsName(state)).value;
    const middleName = (getMiddleName(state) && getMiddleName(state).value) || '';
    const personalDetails = mapSelectors({
        cellphoneNumberCountryCode,
        cellphoneNumber: state => getMobile(state) || getCaptureDetailsMobile(state),
        surname: state => getSurname(state) || getCaptureDetailsSurname(state),
        genderCode: getGenderFromIdNumber(identityNumber),
        dateOfBirth: dateOfBirthFromIdNumber(identityNumber),
        initials: joinNameInitials([...firstName.split(' '), ...middleName.split(' ')]),
        firstname: firstName,
        email: getEmail,
        taxNumber: getIncomeTax,
        nationalityCode: getNationality,
        titleCode: getTitle,
        countryOfBirthCode: getCountryOfBirth,
        maritalStatusCode: getMaritalStatus,
        premiumTerm: state => Math.min(getMonthlyInvestmentDerivedTerm(state), 25),
    });

    const paymentDetail = mapSelectors({
        accountHolderName: getAccountHolderName,
        bankCode: getBankName,
        bankAccountTypeCode: getAccountType,
        paymentMethodCode: 'DebitOrder',
        debitOrderDate: state => getDebitOrderDay(state).value + ' ' + getStartMonth(state).value,
        accountNumber: state => (getAccountNumber(state).value || '').split(' ').join(''),
    });

    const employmentIndustry = getEmploymentIndustry(state).value;
    const employmentPosition = getEmploymentPosition(state).value;
    const fundMemberEmploymentDetail = mapSelectors({
        employmentIndustryCode: employmentPosition === 'RetiredOrUnemployed' ? 'None' : employmentIndustry,
        employmentPositionCode: getEmploymentPosition,
        employmentIndustryOther: getEmploymentIndustryOther,
        sourceOfIncomeOther: getSourceOfIncomeOther,
        sourceOfFunds: [getReferenceDisplayValue(getReferenceSourceOfFunds(state), getSourceOfFunds(state).value)],
        sourceOfIncomes: [getReferenceDisplayValue(getReferenceSourceOfIncomes(state), getSourceOfIncome(state).value)],
    });

    const nationalIdentity = mapSelectors({
        identityNumber,
        issueDate: null,
        expiryDate: null,
        issuedInCountryCode: DEFAULT_COUNTRY.ZA_CODE,
        nationalIdentityTypeCode: DEFAULT_COUNTRY.ZA_ID_TYPE_CODE,
    });

    const gcsStreetAddress = getGcsStreetAddress(state);
    const gcsPostalAddress = getGcsPostalAddress(state);
    const residentialAddress = {
        LineOne: gcsStreetAddress.addressLine1,
        LineTwo: gcsStreetAddress.addressLine2,
        LineThree: gcsStreetAddress.addressLine3,
        LineFour: gcsStreetAddress.addressLine4,
        countryCode: getCountryCode(gcsStreetAddress.addressCountry, state),
        postalCode: gcsStreetAddress.addressPostalCode,
        addressTypeCode: 'ResidentialAddress',
    };
    const postalAddress = {
        LineOne: gcsPostalAddress.addressLine1,
        LineTwo: gcsPostalAddress.addressLine2,
        LineThree: gcsPostalAddress.addressLine3,
        LineFour: gcsPostalAddress.addressLine4,
        countryCode: getCountryCode(gcsPostalAddress.addressCountry, state),
        postalCode: gcsPostalAddress.addressPostalCode,
        addressTypeCode: 'PostalAddress',
    };
    const addresses = [residentialAddress, postalAddress];

    const totalPremium = parseInt(getMonthlyInvestment(state).value);
    const {annualPremiumIncreaseCode, annualPremiumIncreasePercentageCode} = (() => {
        if (getLinkWithInflationIsSelected(state)) {
            return {
                annualPremiumIncreaseCode: ANNUAL_PREMIUM_INCREASES.LINK_WITH_INFLATION,
                annualPremiumIncreasePercentageCode: null,
            };
        }
        if (getNoIncreaseIsSelected(state)) {
            return {
                annualPremiumIncreaseCode: ANNUAL_PREMIUM_INCREASES.NO_INCREASE,
                annualPremiumIncreasePercentageCode: null,
            };
        }
        return {
            annualPremiumIncreaseCode: "Percentages",
            annualPremiumIncreasePercentageCode: getOwnPercentage(state).value,
        };
    })();
    const fund = (() => {
        const getFund = code => getReferenceFunds(state).find(fund => fund.riskProfileCode === code);

        if (getAdventurousInvestorIsSelected(state)) {
            return getFund(INVESTOR_TYPES.ADVENTUROUS);
        }
        return getFund(INVESTOR_TYPES.COMFORTABLE);
    })();
    const policy = {
        totalPremium,
        annualPremiumIncreasePercentageCode,
        annualPremiumIncreaseCode,
        investments: [
            {
                trustNumber: fund && fund.trustNumber,
                riskProfileCode: fund && fund.riskProfileCode,
                fundSharePercentage: 100,
                fundPremium: totalPremium,
            },
        ],
    };

    const splitForm = getBeneficiariesSplitForm(state);
    const beneficiariesList = getBeneficiaryList(state);
    const getBeneficiaries = () => Object.entries(splitForm)
        .filter(([_, split]) => split.beneficiaryId != null)
        .map(([_, split]) => {
            const {beneficiaryId, value} = split;
            const beneficiary = beneficiariesList.find(ben => ben.id === beneficiaryId).value;

            return {
                cellphoneNumberCountryCode,
                dateOfBirth: beneficiary.dateOfBirth.value,
                firstname: beneficiary.firstName.value,
                initials: joinNameInitials([...beneficiary.firstName.value.split(' ')]).slice(0,3),
                sharePercentage: parseInt(value),
                surname: beneficiary.surname.value,
                titleCode: beneficiary.title.value,
                email: beneficiary.email.value,
                cellphoneNumber: beneficiary.mobileNumber.value,
                genderCode: beneficiary.gender.value,
            }
        });

    return {
        hasAcceptedDisclaimer: true,
        hasAcceptedBankConditions: true,
        hasAcceptedReplacmentConditions: true,
        hasReplacementPolicy: false,
        correspondenceLanguageCode: 'English',
        fundMember: {
            ...personalDetails,
            paymentDetail,
            fundMemberEmploymentDetail,
            nationalIdentity,
            addresses,
        },
        policy,
        beneficiaries: beneficiariesList.length ? getBeneficiaries() : [],
    };
}

const getCountryCode = (countryValue, state) => {
    const refValue = getReferenceDisplayValue(getReferenceNationalities(state), countryValue, 'description');
    return refValue.code;
};

// Map an object of selectors to an object of slices of state.
// If the slice if state is an object with a 'value' attribute, return the 'object.value.'
const mapSelectorsFromState = state => selectorObject => {
    const entries = Object.entries(selectorObject);
    const getSliceOfState = selector => typeof selector === 'function'
        ? selector(state)
        : selector;

    return Object.fromEntries(entries.map(([key, selector]) => {
        const sliceOfState = getSliceOfState(selector);
        const hasValueAttribute = typeof sliceOfState === 'object' && !Array.isArray(sliceOfState);

        if (sliceOfState == null) return [key, null];

        return [key, hasValueAttribute ? sliceOfState.value : sliceOfState];
    }));
};

// Get the reference data entry from original reference data array and reference data code.
const getReferenceDisplayValue = (referenceArray, value, keyValue = 'code') => referenceArray
    .find(data => data[keyValue] === value);

// Make a string of initials from a list of first- and middle names.
const joinNameInitials = list => list
    .map(s => s.charAt(0))
    .join('');

const getGenderFromIdNumber = idNumber => {
    const maleRegex = new RegExp('\\d{6}[5-9]\\d{6}');
    return maleRegex.test(idNumber) ? 'Male' : 'Female';
};

const dateOfBirthFromIdNumber = idNumber => {
    const [_, year, month, day] = idNumber.match(/(\d\d)(\d\d)(\d\d).*/);
    const hypotheticalDoB = new Date(parseInt('20' + year), month - 1, day);
    const now = new Date();
    // check if birth date in this century by comparing it to today's year
    const century = now.getFullYear() - hypotheticalDoB.getFullYear() <= 0
        ? '19'
        : '20';

    return [day, month, century + year].join('/');
};