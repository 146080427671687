import {
    COLLECT_SHARE_QUOTE_FORM_DATA,
    COLLECT_PERSONAL_PENSION_PLAN_QUOTE_FORM_DATA,
} from '../../../actions/personalPensionPlan/quote';

import { shareQuoteService } from './shareQuote';
import { premiumCalculationService } from './premiumCalculation';

import {
    TRIGGER_PAYMENTS_STK_PUSH,
    TRIGGER_CONFIRM_ORDER,
    CHECK_PAYMENTS_STATUS,
} from '../../../actions/personalPensionPlan/payments';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { orderConfirmationService } from './orderConfirmation';

import { COLLECT_PAYLOAD_DATA } from '../../../actions/personalPensionPlan/payloadCollection';
import { payloadSubmitService } from './payloadCollection';

import { COLLECT_LOGIN_DETAILS } from '../../../actions/personalPensionPlan/auth';
import { loginService } from './login';

import { TRIGGER_AGENT_TYPE } from '../../../actions/personalPensionPlan/agentModal';
import { getIntermeiaryAgent } from './agent';

import { TRIGGER_GET_NATIONALITIES } from '../../../actions/personalPensionPlan/personalDetails';
import { getNationalityService } from './nationalities';

export const personalPensionPlanCover = (store, next, action) => {
    if (action.type.startsWith(TRIGGER_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }
    if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
        return paymentsStkStatusService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_PERSONAL_PENSION_PLAN_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {
        return payloadSubmitService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)) {
        return shareQuoteService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_LOGIN_DETAILS)) {
        return loginService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)) {
        return orderConfirmationService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_AGENT_TYPE)) {
        return getIntermeiaryAgent(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_NATIONALITIES)) {
        return getNationalityService(store, next, action);
    }

    return null;
};
