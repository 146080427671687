export const GENERATE_REGULAR_INCOME_CALCULATOR_TRANSITION = 'investmentCalculator/GenerateRegularIncome/transition/'

export const FORM_VALID_GENERATE_REGULAR_INCOME_TRANSITION = 'investmentCalculator/GenerateRegularIncome/formValid';
export const FORM_INVALID_GENERATE_REGULAR_INCOME_TRANSITION = 'investmentCalculator/GenerateRegularIncome/formInvalid';

export const transitionGenerateRegularIncomeCalculator = {
    formValid: () => ({ type: FORM_VALID_GENERATE_REGULAR_INCOME_TRANSITION }),
    formInvalid: () => ({ type: FORM_INVALID_GENERATE_REGULAR_INCOME_TRANSITION }),
};

export const GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE = 'investmentCalculator/GenerateRegularIncome/setValue/';
export const GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALID = 'investmentCalculator/GenerateRegularIncome/setValid/';

export const REINITIALIZE_STATE_GENERATE_REGULAR_INCOME_CALCULATOR = 'investmentCalculator/GenerateRegularIncome/reinitialize';

export const ONCE_OFF_SET_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'onceOff';
export const ONCE_OFF_SET_VALID = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALID + 'onceOff';
export const MONTHLY_AMOUNT_SET_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'monthlyAmount';
export const MONTHLY_AMOUNT_SET_VALID = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALID + 'monthlyAmount';
export const MONEY_MARKET_FUND_PERCENT_SET_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'moneyMarketFundPercent';
export const MONEY_MARKET_FUND_SPLIT_SET_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'moneyMarketFundSplitValue';
export const MONEY_MARKET_FUND_PERCENT_SET_VALID = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALID + 'moneyMarketFundPercent';
export const BOND_FUND_PERCENT_SET_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'bondFundPercent';
export const BOND_FUND_SPLIT_SET_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'bondFundSplit';
export const BOND_FUND_PERCENT_SET_VALID = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALID + 'bondFundPercent';
export const RESULTS_VIEW_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'viewResults';
export const EVEN_SPLIT_SELECT_FUND_SPLIT = GENERATE_REGULAR_INCOME_CALCULATOR_TRANSITION + 'selectEvenSplit';
export const EVEN_SPLIT_DESELECT_FUND_SPLIT = GENERATE_REGULAR_INCOME_CALCULATOR_TRANSITION + 'deselectEvenSplit';
export const EVEN_SPLIT_DISCARD_FUND_SPLIT = GENERATE_REGULAR_INCOME_CALCULATOR_TRANSITION + 'discardEvenSplit';

export const RESULTS_SET_VALUE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'setResults';
export const CALCULATE_GENERATE_REGULAR_INCOME = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'success';
export const RESULTS_SET_VALUE_FAILURE = GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR = `${GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR = `${GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR = `${GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE}/apiTransition/failure`;
export const apiTransitionGenerateRegularIncomeCalculator = {
    pending: () => ({ type: API_PENDING_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR }),
    success: () => ({ type: API_SUCCESS_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR }),
    failure: () => ({ type: API_FAILURE_TRANSITION_GENERATE_REGULAR_INCOME_CALCULATOR }),
};

export const getGenerateRegularIncomeResult = () => ({ type: RESULTS_SET_VALUE });
export const calculateGenerateRegularIncome = (value) => ({ type: CALCULATE_GENERATE_REGULAR_INCOME, value});
export const getGenerateRegularIncomeResultFailure = error => ({ type: RESULTS_SET_VALUE_FAILURE, error });

export const setGenerateRegularIncomeOnceOffValue = value => ({ type: ONCE_OFF_SET_VALUE, value });
export const setGenerateRegularIncomeOnceOffValid = error => ({ type: ONCE_OFF_SET_VALID, error });
export const setMonthlyValue = value => ({ type: MONTHLY_AMOUNT_SET_VALUE, value });
export const setMonthlyValid = error => ({ type: MONTHLY_AMOUNT_SET_VALID, error });
export const setMoneyMarketFundPercentValue = value => ({ type: MONEY_MARKET_FUND_PERCENT_SET_VALUE, value });
export const getMoneyMarketFundSplitValue = value => ({ type: MONEY_MARKET_FUND_SPLIT_VALUE, value });
export const setMoneyMarketFundPercentValid = error => ({ type: MONEY_MARKET_FUND_PERCENT_SET_VALID, error });
export const setBondFundPercentValue = value => ({ type: BOND_FUND_PERCENT_SET_VALUE, value });
export const setBondFundSplitValue = value => ({ type: BOND_FUND_SET_VALUE, value });
export const setBondFundPercentValid = error => ({ type: BOND_FUND_PERCENT_SET_VALID, error });
export const setViewGenerateRegularIncomeResult = value => ({ type: RESULTS_VIEW_VALUE, value });
export const reInitialize = () => ({ type: REINITIALIZE_STATE_GENERATE_REGULAR_INCOME_CALCULATOR });
export const selectEvenSplit = () => ({ type: EVEN_SPLIT_SELECT_FUND_SPLIT });
export const deselectEvenSplit = () => ({ type: EVEN_SPLIT_DESELECT_FUND_SPLIT });

