export const APP_URL_PREFIX = '/biashara-flexi';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QOUTE: 0,
	COMPANY_DETAILS: 1,
	EMPLOYEE_DETAILS: 2,
	UPLOAD_DOCUMENTS: 3,
	PAYMENT: 4,
};

export const PAGE_INDICES_PAY_NOW = {
    LANDING_PAGE: -1,
    QOUTE: 0,
	COMPANY_DETAILS: 1,
	PAYMENT: 2,
};

export const PAGES = [
	{
		URL: `${APP_URL_PREFIX}/get-quote`,
		URL_PART: 'get-quote',
		TITLE: 'Get Quote',
	},
	{
		URL: `${APP_URL_PREFIX}/company-details`,
		URL_PART: 'company-details',
		TITLE: 'Company Details',
	},
	{
		URL: `${APP_URL_PREFIX}/employee-details`,
		URL_PART: 'employee-details',
		TITLE: 'Employee Details',
	},
	{
		URL: `${APP_URL_PREFIX}/upload-documents`,
		URL_PART: 'upload-documents',
		TITLE: 'Upload Documents',
	},

	{
		URL: `${APP_URL_PREFIX}/payment`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	},
];

export const PAGES_PAY_NOW = [
	{
		URL: `${APP_URL_PREFIX}/get-quote`,
		URL_PART: 'get-quote',
		TITLE: 'Get Quote',
	},
	{
		URL: `${APP_URL_PREFIX}/company-details`,
		URL_PART: 'company-details',
		TITLE: 'Company Details',
	},
	{
		URL: `${APP_URL_PREFIX}/payment`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	},
];


export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const BIASHARA_FLEXI_URLS = {
	QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
	COMPANY_DETAILS: PAGES[PAGE_INDICES.COMPANY_DETAILS].URL,
	EMPLOYEE_DETAILS: PAGES[PAGE_INDICES.EMPLOYEE_DETAILS].URL,
	UPLOAD_DOCUMENTS: PAGES[PAGE_INDICES.UPLOAD_DOCUMENTS].URL,
	PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL
};

export const BIASHARA_FLEXI_PAY_NOW_URLS = {
	QOUTE: PAGES_PAY_NOW[PAGE_INDICES_PAY_NOW.QOUTE].URL,
	COMPANY_DETAILS: PAGES_PAY_NOW[PAGE_INDICES_PAY_NOW.COMPANY_DETAILS].URL,
	PAYMENT: PAGES_PAY_NOW[PAGE_INDICES_PAY_NOW.PAYMENT].URL
};
