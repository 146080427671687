import {REFERENCE_DATA_SET_VALUE} from '../../actions/savingsCalculator/savingsCalculator'

const initialCalculatorReferenceData = {
    calculatorOptions: [
        {option: 'howMuchYouNeedToSaveCalculator', optionValue: 'How much you need to save'},
        {option: 'whenYouWillReachYourSavingsGoal', optionValue: 'When you will reach your savings goal'},
        {option: 'theFutureValueOfYourSavings', optionValue: 'The future value of your savings'},
    ],

    resultCard: `The sooner you start saving, the sooner you can reach your goal.`
};

export default (state = initialCalculatorReferenceData, action) => {
    switch (action.type) {

        case REFERENCE_DATA_SET_VALUE:
            return { ...state, data: action.value };

        default:
            return state;
    }
};
