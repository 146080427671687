import {
    validateSingleSelection
} from  "@om/validation";
import {
    setAnswerValid,
    ANSWER_SET_VALUE
} from "../../../actions/customerVerificationService/knowledgeBasedAuthentication";

export const knowledgeBasedAuthenticationValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ANSWER_SET_VALUE:
                error = validateSingleSelection(action.value, 'answer');
                return setAnswerValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
