import * as productActions from '../../actions/roaCarInsurance/product';
import { API_CALL_STATUS, FORM_STATUS } from '../status';

export const initProduct = {
    premiumCalculation: null,
    status: FORM_STATUS.INVALID,
    selectedCover: null
};

export default (state = initProduct, action) => {
    switch (action.type) {
        //    API Call
        case productActions.API_PENDING_TRANSITION_PRODUCT:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case productActions.API_SUCCESS_TRANSITION_PRODUCT:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case productActions.API_FAILURE_TRANSITION_PRODUCT:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };
        //    Calculate premium data
        case productActions.CALCULATE_PREMIUM_DATA_SUCCESS:
            return { ...state, premiumCalculation: action.value };
        case productActions.COLLECT_PREMIUM_DATA:
            return { ...state, status: FORM_STATUS.VALID };
        case productActions.SELECTED_PRODUCT_SET_VALUE:
            return {...state, selectedCover: action.value}
        default:
            return state;
    }
};
