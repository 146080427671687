export const APP_URL_PREFIX = '/motor-private';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QOUTE: 0,
    PERSONAL_DETAILS: 1,
    CAR_DETAILS: 2,
    DOCUMENTS: 3,
    PAYMENT: 4,
};

export const PAGES = [
	{
		URL: `${APP_URL_PREFIX}/get-quote`,
		URL_PART: 'get-quote',
		TITLE: 'Get Quote',
	},
	{
		URL: `${APP_URL_PREFIX}/personal-details`,
		URL_PART: 'personal-details',
		TITLE: 'Personal Details',
	},
	{
		URL: `${APP_URL_PREFIX}/car-details`,
		URL_PART: 'car-details',
		TITLE: 'Car Details',
	},
	{
		URL: `${APP_URL_PREFIX}/documents`,
		URL_PART: 'documents',
		TITLE: 'Documents',
	},

	{
		URL: `${APP_URL_PREFIX}/payments`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	},
];

export const KE_PAGES = [
    {
        URL: `/motor-get-quote`,
        URL_PART: 'get-quote',
        TITLE: 'Get Quote',
    },
    {
        URL: `/personal-details`,
        URL_PART: 'personal-details',
        TITLE: 'Personal Details',
    },
    {
        URL: `/cardetails`,
        URL_PART: 'cardetails',
        TITLE: 'Car Details',
    },
    {
        URL: `/next-of-kin`,
        URL_PART: 'next-of-kin',
        TITLE: 'Next of Kin',
    },

    {
        URL: `/motor-insurance-ke/payments/`,
        URL_PART: 'motor-insurance-ke/payments/',
        TITLE: 'Payment',
    },
];

export const COVER_PLANS = {
    MOTOR_COMPREHENSIVE: { title: 'Motor Comprehensive', value: 'motorComprehensive' },
    THIRD_PARTY: { title: 'Third Party', value: 'ThirdParty' }
};


export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const MOTOR_INSURANCE_URLS = {
	QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
	PERSONAL_DETAILS: PAGES[PAGE_INDICES.PERSONAL_DETAILS].URL,
	CAR_DETAILS: PAGES[PAGE_INDICES.CAR_DETAILS].URL,
	DOCUMENTS: PAGES[PAGE_INDICES.DOCUMENTS].URL,
	PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL
};

export const KE_MOTOR_INSURANCE_URLS = {
    QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
    PERSONAL_DETAILS: PAGES[PAGE_INDICES.PERSONAL_DETAILS].URL,
    CAR_DETAILS: PAGES[PAGE_INDICES.CAR_DETAILS].URL,
    NEXT_OF_KIN: PAGES[PAGE_INDICES.DOCUMENTS].URL,
    PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL,
};

