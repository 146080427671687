// Transitions
export const FORM_VALID_TRANSITION_PERSONAL_DETAILS =
  "digiLifeLoanProtection/personalDetails/transition/formValid";
export const FORM_INVALID_TRANSITION_PERSONAL_DETAILS =
  "digiLifeLoanProtection/personalDetails/transition/formInvalid";
export const PERSONAL_DETAILS_FORM_SET_VALUE =
  "digiLifeLoanProtection/personalDetails/transition/formValue";
export const setPersonalDetailFormValues = () => ({
  type: PERSONAL_DETAILS_FORM_SET_VALUE,
});

export const transitionPersonalDetails = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_PERSONAL_DETAILS }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_PERSONAL_DETAILS }),
};

export const INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS =
  "digiLifeLoanProtection/personalDetails/transition/invalidContinue";
export const CONTINUE_TRANSITION_PERSONAL_DETAILS =
  "digiLifeLoanProtection/personalDetails/transition/validContinue";
export const BACK_TRANSITION_PERSONAL_DETAIL =
  "digiLifeLoanProtection/personalDetails/transition/back";
export const invalidContinuePersonalDetails = () => ({
  type: INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS,
});
export const continuePersonalDetails = () => ({
  type: CONTINUE_TRANSITION_PERSONAL_DETAILS,
});
export const backPersonalDetails = () => ({
  type: BACK_TRANSITION_PERSONAL_DETAIL,
});

// Transition - reset state
export const RESET_STATE_TRANSITION_PERSONAL_DETAILS =
  "digiLifeLoanProtection/personalDetails/transition/resetState";
export const resetPersonalDetails = () => ({
  type: RESET_STATE_TRANSITION_PERSONAL_DETAILS,
});

// Events
export const PERSONAL_DETAILS_CLEAR_VALUE =
  "digiLifeLoanProtection/personalDetails/clearValue/";
export const PERSONAL_DETAILS_SET_VALUE =
  "digiLifeLoanProtection/personalDetails/setValue/";
export const PERSONAL_DETAILS_SET_VALID =
  "digiLifeLoanProtection/personalDetails/setValid/";

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_PERSONAL_DETAILS =
  "digiLifeLoanProtection/personalDetails/transition/dropOffLead";
export const dropOffLeadPersonalDetails = (dropOffDetails) => ({
  type: DROP_OFF_LEAD_TRANSITION_PERSONAL_DETAILS,
  dropOffDetails,
});

// Events - Personal details
export const TITLE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "title";
export const TITLE_SET_VALID = PERSONAL_DETAILS_SET_VALID + "title";
export const FIRST_NAMES_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "firstNames";
export const FIRST_NAMES_SET_VALID = PERSONAL_DETAILS_SET_VALID + "firstNames";
export const SURNAME_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "surname";
export const SURNAME_SET_VALID = PERSONAL_DETAILS_SET_VALID + "surname";
export const GENDER_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "gender";
export const GENDER_SET_VALID = PERSONAL_DETAILS_SET_VALID + "gender";
export const ID_TYPE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "idType";
export const ID_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "id";
export const ID_SET_VALID = PERSONAL_DETAILS_SET_VALID + "id";
export const ID_EXPIRY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "idExpiry";
export const ID_EXPIRY_SET_VALID = PERSONAL_DETAILS_SET_VALID + "idExpiry";
export const EDUCATION_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "education";
export const EDUCATION_SET_VALID = PERSONAL_DETAILS_SET_VALID + "education";
export const setTitleValue = (value) => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = (error) => ({ type: TITLE_SET_VALID, error });
export const setFirstNamesValue = (value) => ({
  type: FIRST_NAMES_SET_VALUE,
  value,
});
export const setFirstNamesValid = (error) => ({
  type: FIRST_NAMES_SET_VALID,
  error,
});
export const setSurnameValue = (value) => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = (error) => ({ type: SURNAME_SET_VALID, error });
export const setGenderValue = (value) => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = (error) => ({ type: GENDER_SET_VALID, error });
export const setIdTypeValue = (value) => ({ type: ID_TYPE_SET_VALUE, value });
export const setIdValue = (value) => ({ type: ID_SET_VALUE, value });
export const setIdValid = (error) => ({ type: ID_SET_VALID, error });
export const setIdExpiryValue = (value) => ({
  type: ID_EXPIRY_SET_VALUE,
  value,
});
export const setIdExpiryValid = (error) => ({
  type: ID_EXPIRY_SET_VALID,
  error,
});
export const setEducationValue = (value) => ({
  type: EDUCATION_SET_VALUE,
  value,
});
export const setEducationValid = (error) => ({
  type: EDUCATION_SET_VALID,
  error,
});

// Events - Contact details
export const MOBILE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "mobile";
export const MOBILE_SET_VALID = PERSONAL_DETAILS_SET_VALID + "mobile";
export const EMAIL_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "email";
export const EMAIL_SET_VALID = PERSONAL_DETAILS_SET_VALID + "email";
export const setMobileValue = (value) => ({ type: MOBILE_SET_VALUE, value });
export const setMobileValid = (error) => ({ type: MOBILE_SET_VALID, error });
export const setEmailValue = (value) => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = (error) => ({ type: EMAIL_SET_VALID, error });

// Events - Address details

export const STREET_ADDRESS_SET_VALUE =
  PERSONAL_DETAILS_SET_VALUE + "streetAddress";
export const STREET_ADDRESS_SET_VALID =
  PERSONAL_DETAILS_SET_VALID + "streetAddress";

export const CITY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "city";
export const CITY_SET_VALID = PERSONAL_DETAILS_SET_VALID + "city";
export const DISTRICT_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "district";
export const DISTRICT_SET_VALID = PERSONAL_DETAILS_SET_VALID + "district";
export const COUNTRY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "country";
export const COUNTRY_SET_VALID = PERSONAL_DETAILS_SET_VALID + "country";
export const setComplexValue = (value) => ({ type: COMPLEX_SET_VALUE, value });
export const setComplexValid = (error) => ({ type: COMPLEX_SET_VALID, error });
export const setStreetAddressValue = (value) => ({
  type: STREET_ADDRESS_SET_VALUE,
  value,
});
export const setStreetAddressValid = (error) => ({
  type: STREET_ADDRESS_SET_VALID,
  error,
});
export const setDistrictValue = (value) => ({ type: DISTRICT_SET_VALUE, value });
export const setDistrictValid = (error) => ({ type: DISTRICT_SET_VALID, error });
export const setCityValue = (value) => ({ type: CITY_SET_VALUE, value });
export const setCityValid = (error) => ({ type: CITY_SET_VALID, error });

export const setCountryValue = (value) => ({ type: COUNTRY_SET_VALUE, value });
export const setCountryValid = (error) => ({ type: COUNTRY_SET_VALID, error });

//Postal address

// Events - Residential postal address

export const POSTAL_STREET_ADDRESS_SET_VALUE =
  PERSONAL_DETAILS_SET_VALUE + "postalStreetAddress";
export const POSTAL_STREET_ADDRESS_SET_VALID =
  PERSONAL_DETAILS_SET_VALID + "postalStreetAddress";

export const POSTAL_CITY_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "postalCity";
export const POSTAL_CITY_SET_VALID = PERSONAL_DETAILS_SET_VALID + "postalCity";

export const POSTAL_DISTRICT_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "postalDistrict";
export const POSTAL_DISTRICT_SET_VALID = PERSONAL_DETAILS_SET_VALID + "postalDistrict";

export const POSTAL_COUNTRY_SET_VALUE =
  PERSONAL_DETAILS_SET_VALUE + "postalCountry";
export const POSTAL_COUNTRY_SET_VALID =
  PERSONAL_DETAILS_SET_VALID + "postalCountry";

export const setPostalStreetAddressValue = (value) => ({
  type: POSTAL_STREET_ADDRESS_SET_VALUE,
  value,
});
export const setPostalStreetAddressValid = (error) => ({
  type: POSTAL_STREET_ADDRESS_SET_VALID,
  error,
});

export const setPostalCityValue = (value) => ({
  type: POSTAL_CITY_SET_VALUE,
  value,
});
export const setPostalCityValid = (error) => ({
  type: POSTAL_CITY_SET_VALID,
  error,
});

export const setPostalDistrictValue = (value) => ({
  type: POSTAL_DISTRICT_SET_VALUE,
  value,
});
export const setPostalDistrictValid = (error) => ({
  type: POSTAL_DISTRICT_SET_VALID,
  error,
});

export const setPostalCountryValue = (value) => ({
  type: POSTAL_COUNTRY_SET_VALUE,
  value,
});
export const setPostalCountryValid = (error) => ({
  type: POSTAL_COUNTRY_SET_VALID,
  error,
});

//Loan Details
export const LOAN_AMOUNT_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "loanAmount";
export const LOAN_AMOUNT_SET_VALID = PERSONAL_DETAILS_SET_VALID + "loanAmount";
export const LOAN_TYPE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "loanType";
export const LOAN_TYPE_SET_VALID = PERSONAL_DETAILS_SET_VALID + "loanType";
export const BANK_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "bank";
export const BANK_SET_VALID = PERSONAL_DETAILS_SET_VALID + "bank";
export const LOAN_TERM_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "loanTerm";
export const LOAN_TERM_SET_VALID = PERSONAL_DETAILS_SET_VALID + "loanTerm";
export const setLoanAmountValue = (value) => ({
  type: LOAN_AMOUNT_SET_VALUE,
  value,
});
export const setLoanAmountValid = (error) => ({
  type: LOAN_AMOUNT_SET_VALID,
  error,
});
export const setLoanTypeValue = (value) => ({
  type: LOAN_TYPE_SET_VALUE,
  value,
});
export const setLoanTypeValid = (error) => ({
  type: LOAN_TYPE_SET_VALID,
  error,
});
export const setBankValue = (value) => ({
  type: BANK_SET_VALUE,
  value,
});
export const setBankValid = (error) => ({
  type: BANK_SET_VALID,
  error,
});
export const setLoanTermValue = (value) => ({
  type: LOAN_TERM_SET_VALUE,
  value,
});
export const setLoanTermValid = (error) => ({
  type: LOAN_TERM_SET_VALID,
  error,
});

//Employement and Income Details
export const EMOPLOYMENT_TYPE_SET_VALUE =
  PERSONAL_DETAILS_SET_VALUE + "employmentType";
export const EMOPLOYMENT_TYPE_SET_VALID =
  PERSONAL_DETAILS_SET_VALID + "employmentType";
export const OCCUPATION_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "occupation";
export const OCCUPATION_SET_VALID = PERSONAL_DETAILS_SET_VALID + "occupation";
export const INCOME_SOURCE_SET_VALUE =
  PERSONAL_DETAILS_SET_VALUE + "incomeSource";
  export const INCOME_SOURCE_VALID = PERSONAL_DETAILS_SET_VALID + "incomeSource";
  
  export const INCOME_SOURCE_TEXT_SET_VALUE =
  PERSONAL_DETAILS_SET_VALUE + "incomeSourceText";
  export const INCOME_SOURCE_TEXT_VALID = PERSONAL_DETAILS_SET_VALID + "incomeSourceText";
  
export const setEmploymentTypeValue = (value) => ({
  type: EMOPLOYMENT_TYPE_SET_VALUE,
  value,
});
export const setEmploymentTypeValid = (error) => ({
  type: EMOPLOYMENT_TYPE_SET_VALID,
  error,
});
export const setOccupationValue = (value) => ({
  type: OCCUPATION_SET_VALUE,
  value,
});
export const setOccupationValid = (error) => ({
  type: OCCUPATION_SET_VALID,
  error,
});
export const setIncomeSourceValue = (value) => ({
  type: INCOME_SOURCE_SET_VALUE,
  value,
});
export const setIncomeSourceValid = (error) => ({
  type: INCOME_SOURCE_VALID,
  error,
});
export const setIncomeSourceTextValue = (value) => ({
  type: INCOME_SOURCE_TEXT_SET_VALUE,
  value,
});
export const setIncomeSourceTextValid = (error) => ({
  type: INCOME_SOURCE_TEXT_VALID,
  error,
});

//Payment Frequency
export const PAYMENT_FREQUENCY_SET_VALUE =
  PERSONAL_DETAILS_SET_VALUE + "paymentFrequency";
export const PAYMENT_FREQUENCY_SET_VALID =
  PERSONAL_DETAILS_SET_VALID + "paymentFrequency";
export const setPaymentFrequencyValue = (value) => ({
  type: PAYMENT_FREQUENCY_SET_VALUE,
  value,
});
export const setPaymentFrequencyValid = (error) => ({
  type: PAYMENT_FREQUENCY_SET_VALID,
  error,
});
