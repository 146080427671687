import { createSelector } from 'reselect';
import { getPersonalLoansCalculator } from './index';

export const getCalculator = createSelector(
    getPersonalLoansCalculator,
    personalLoansCalculator => personalLoansCalculator.referenceData
)

const makeField = (fieldId) => createSelector(
    getCalculator,
    referenceData => referenceData[fieldId]
)

export const getLoanAmountOptions = makeField('loanAmountOptions');
export const getRepaymentDurationObject = makeField('repaymentDurationOptions');

export const getRepaymentDurationOptions = createSelector(
    getRepaymentDurationObject,
    repaymentDurations => repaymentDurations.data
)