import axios from 'axios';

import {
    apiTransitionGenerateRegularIncomeCalculator,
    CALCULATE_GENERATE_REGULAR_INCOME,
} from '../../../actions/investmentCalculator/generateRegularIncome';

import {
    setViewInvestmentResult
} from '../../../actions/investmentCalculator/investmentCalculator'

import {
    getMonthlyAmountValue,
    getAmountOnceOffValue,
    getIsApiPending,
    getMoneyMarketFundPercentValue,
    getBondFundPercentValue,
} from '../../../selectors/investmentCalculator/generateRegularIncomeCalculator';
import { getSelectedGoal, } from '../../../selectors/investmentCalculator/investmentCalculator';


export const generateRegularIncomeCalculatorService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    if(action.type === CALCULATE_GENERATE_REGULAR_INCOME) {
        (async () => {
            if(!getIsApiPending(state)) {
                store.dispatch(apiTransitionGenerateRegularIncomeCalculator.pending());
                try {
                    const response = await calculate(state);
                    store.dispatch(setViewInvestmentResult(response?.data));
                    store.dispatch(apiTransitionGenerateRegularIncomeCalculator.success());
                } catch (e) {
                    store.dispatch(setViewInvestmentResult(null))
                    store.dispatch(apiTransitionGenerateRegularIncomeCalculator.failure())
                }
            }
        })();
    }
    return result
}

export const calculate = async(state) => {

    let data = {
        "initial_amount": getAmountOnceOffValue(state).value,
        "monthly_savings":  getMonthlyAmountValue(state).value,
        "goal": getSelectedGoal(state),
    }

    data = {
        ...data,
        "productitems": [
            {id: 23, percent_split:  getBondFundPercentValue(state).value}, // bond
            {id: 24,  percent_split: getMoneyMarketFundPercentValue(state).value} // money market
        ],
    }

    try {
        const url = '/om-api/investment-calculator/generate-regular-income'
        return  await axios.post(url, data)
    } catch (error) {
        return null
    }
}
