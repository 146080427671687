import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS, CALL_ME_BACK_TYPES } from "../status";
import { OPEN_DRAWER } from "../../actions/easiplusFuneralPlan/types";

import {
    TOGGLE_CALL_ME_BACK,
    FORM_VALID_TRANSITION_CALL_ME_BACK,
    FORM_INVALID_TRANSITION_CALL_ME_BACK,
    NAME_SET_VALUE,
    NAME_SET_VALID,
    SURNAME_SET_VALUE,
    SURNAME_SET_VALID,
    CONTACT_NUMBER_SET_VALUE,
    CONTACT_NUMBER_SET_VALID,
    CALL_ME_BACK_SEND,
    CALL_ME_BACK_SEND_SUCCESS,
    CALL_ME_BACK_SEND_FAILURE,
    GTM_CONTEXT_URL_SET_VALUE,
    GTM_CONTEXT_GA_ID_SET_VALUE,
    REPLACEMENT_PRODUCT_SET_VALUE,
    GENERIC_PRODUCT_SET_VALUE,
    QUOTE_PRODUCT_SET_VALUE,
    RESET_STATE_TRANSITION_CALL_ME_BACK
} from "../../actions/easiplusFuneralPlan/callMeBack";

export const initCallMeBack = {
    openDrawer: null,
    callMeBackType: null,
    sendCallMeBackStatusQuote: API_CALL_STATUS.IDLE,
    sendCallMeBackStatusReplacement: API_CALL_STATUS.IDLE,
    sendCallMeBackStatusGeneric: API_CALL_STATUS.IDLE,
    formStatus: FORM_STATUS.INVALID,
    form: {
        name: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        contactNumber: { displayValue: null, value: null, error: null, status: null },
    },
    gtmContextUrl: null,
    gtmContextGaId: null,
    replacementProduct: null,
    genericProduct: null,
    quoteProduct: null
};

export default (state = initCallMeBack, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    const removeWhiteSpacesFromValue = (value) => value ? value.replace(/ /g, '') : value;

    switch (action.type) {
        // Side drawer toggles
        case TOGGLE_CALL_ME_BACK:
            return {
                ...state,
                openDrawer: !state.openDrawer ? OPEN_DRAWER.CALL_ME_BACK : null,
                callMeBackType: action.value ? action.value : null,
            };

        // Set and Validate Values
        case NAME_SET_VALUE:
            return updateFormField('name', field => ({
                ...field,
                value: action.value,
            }));
        case NAME_SET_VALID:
            return updateFormField('name', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SURNAME_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: action.value,
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CONTACT_NUMBER_SET_VALUE:
            return updateFormField('contactNumber', field => ({
                ...field,
                value: removeWhiteSpacesFromValue(action.value),
                displayValue: action.value
            }));
        case CONTACT_NUMBER_SET_VALID:
            return updateFormField('contactNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CALL_ME_BACK_SEND:
            if (state.callMeBackType === CALL_ME_BACK_TYPES.QUOTE) {
                return {
                    ...state,
                    sendCallMeBackStatusQuote: API_CALL_STATUS.PENDING
                };
            } else if (state.callMeBackType === CALL_ME_BACK_TYPES.REPLACEMENT) {
                return {
                    ...state,
                    sendCallMeBackStatusReplacement: API_CALL_STATUS.PENDING
                };
            } else {
                return {
                    ...state,
                    sendCallMeBackStatusGeneric: API_CALL_STATUS.PENDING
                };
            }
        case CALL_ME_BACK_SEND_SUCCESS:
            if (state.callMeBackType === CALL_ME_BACK_TYPES.QUOTE) {
                return {
                    ...state,
                    sendCallMeBackStatusQuote: API_CALL_STATUS.SUCCESS
                };
            } else if (state.callMeBackType === CALL_ME_BACK_TYPES.REPLACEMENT) {
                return {
                    ...state,
                    sendCallMeBackStatusReplacement: API_CALL_STATUS.SUCCESS
                };
            } else {
                return {
                    ...state, sendCallMeBackStatusGeneric: API_CALL_STATUS.SUCCESS
                };
            }
        case CALL_ME_BACK_SEND_FAILURE:
            if (state.callMeBackType === CALL_ME_BACK_TYPES.QUOTE) {
                return {
                    ...state,
                    sendCallMeBackStatusQuote: API_CALL_STATUS.FAILURE
                };
            } else if (state.callMeBackType === CALL_ME_BACK_TYPES.REPLACEMENT) {
                return {
                    ...state, sendCallMeBackStatusReplacement: API_CALL_STATUS.FAILURE
                };
            } else {
                return {
                    ...state, sendCallMeBackStatusGeneric: API_CALL_STATUS.FAILURE
                };
            }

        // Form Validation
        case FORM_VALID_TRANSITION_CALL_ME_BACK:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_CALL_ME_BACK:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // cmb configuration
        case GTM_CONTEXT_URL_SET_VALUE:
            return { ...state, gtmContextUrl: action.value };
        case GTM_CONTEXT_GA_ID_SET_VALUE:
            return { ...state, gtmContextGaId: action.value };
        case REPLACEMENT_PRODUCT_SET_VALUE:
            return { ...state, replacementProduct: action.value };
        case GENERIC_PRODUCT_SET_VALUE:
            return { ...state, genericProduct: action.value };
        case QUOTE_PRODUCT_SET_VALUE:
            return { ...state, quoteProduct: action.value };
        case RESET_STATE_TRANSITION_CALL_ME_BACK:
            return { ...initCallMeBack };

        default:
            return state;
    }
};
