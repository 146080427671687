export const CUSTOMER_SATISFACTION_SURVEY_SET_VALUE = 'customerSatisfactionSurvey/setValue';
export const CUSTOMER_SATISFACTION_SURVEY_SET_VALID = 'customerSatisfactionSurvey/setValid';


export const FORM_VALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION = 'customerSatisfactionSurvey/formValid';
export const FORM_INVALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION = 'customerSatisfactionSurvey/formInvalid';

export const transitionCustomerSatisfactionSurveyForm = {
    formValid: () => ({ type: FORM_VALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION }),
    formInvalid: () => ({ type: FORM_INVALID_CUSTOMER_SATISFACTION_SURVEY_TRANSATION }),
};

export const CUSTOMER_FEEDBACK_SET_VALUE = `${CUSTOMER_SATISFACTION_SURVEY_SET_VALUE}/customerFeedback`
export const CUSTOMER_FEEDBACK_SET_VALID = `${CUSTOMER_SATISFACTION_SURVEY_SET_VALID}/customerFeedback`
export const setCustomerFeedbackValue = value => ({type: CUSTOMER_FEEDBACK_SET_VALUE, value})
export const setCustomerFeedbackValid = error => ({type: CUSTOMER_FEEDBACK_SET_VALID, error})

export const CUSTOMER_QUERY_PARAMETER_SET_VALUE = `${CUSTOMER_SATISFACTION_SURVEY_SET_VALUE}/queryParams`
export const setCustomerQueryParams = value => ({type: CUSTOMER_QUERY_PARAMETER_SET_VALUE, value})

export const RESET_CUSTOMER_FEEDBACK_FORM = 'customerSatisfactionSurvey/reset/form';
export const resetCustomerFeedbackForm = () => ({ type: RESET_CUSTOMER_FEEDBACK_FORM });

export const SUBMIT_CUSTOMER_FEEDBACK = `${CUSTOMER_SATISFACTION_SURVEY_SET_VALUE}/submitFeedback`
export const submitCustomerFeedback = () => ({type: SUBMIT_CUSTOMER_FEEDBACK})

export const API_PENDING_TRANSITION_SUBMIT_FEEDBACK = `${CUSTOMER_SATISFACTION_SURVEY_SET_VALUE}/pending`
export const API_SUCCESS_TRANSITION_SUBMIT_FEEDBACK = `${CUSTOMER_SATISFACTION_SURVEY_SET_VALUE}/success`
export const API_FAILURE_TRANSITION_SUBMIT_FEEDBACK = `${CUSTOMER_SATISFACTION_SURVEY_SET_VALUE}/failure`

export const apiTransitionCustomerSatisfactionSurvey = {
    pending: () => ({ type: API_PENDING_TRANSITION_SUBMIT_FEEDBACK }),
    success: () => ({ type: API_SUCCESS_TRANSITION_SUBMIT_FEEDBACK }),
    failure: () => ({ type: API_FAILURE_TRANSITION_SUBMIT_FEEDBACK }),
};
