import { FIELD_STATUS, FORM_STATUS } from "../status";
import {
    FORM_VALID_TRANSITION_BIOGRAPHIC_INFO,
    FORM_INVALID_TRANSITION_BIOGRAPHIC_INFO,
    AGE_SET_VALUE,
    AGE_SET_VALID,
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    POSTAL_CODE_SET_VALUE,
    POSTAL_CODE_SET_VALID,
    BIOGRAPHIC_INFO_RESET,
} from "../../actions/covidSelfAssessment/biographicInfo";


const initBiographicInfo = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        age: { value: null, error: null, status: null },
        gender: { value: null, error: null, status: null },
        postalCode: { value: null, error: null, status: null },
    },
};

export default (state = initBiographicInfo, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_BIOGRAPHIC_INFO:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_BIOGRAPHIC_INFO:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Reset
        case BIOGRAPHIC_INFO_RESET:
            return { ...initBiographicInfo };

        // Age
        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Gender
        case GENDER_SET_VALUE:
            return updateFormField('gender', field => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('gender', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Postal Code
        case POSTAL_CODE_SET_VALUE:
            return updateFormField('postalCode', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_CODE_SET_VALID:
            return updateFormField('postalCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
