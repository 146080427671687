import { validateNumber, validateAlphaString } from  "@om/validation";
import {
    setAgeValid,
    setIncomeFrequencyValid,
    setIncomeValueValid,
    setOtherIncomeValueValid,
    setOtherIncomeDeductionValid,
    setMedicalAidOwnContributionValid,
    setMedicalAidEmployerContributionValid,
    setMedicalAidDependentsValid,
    setPensionFundOwnContributionValid,
    setPensionFundEmployerContributionValid,
    setProvidentFundOwnContributionValid,
    setProvidentFundEmployerContributionValid,
    setRetirementAnnuityValid,
    AGE_SET_VALUE,
    INCOME_FREQUENCY_SET_VALUE,
    INCOME_VALUE_SET_VALUE,
    OTHER_INCOME_VALUE_SET_VALUE,
    OTHER_INCOME_DEDUCTION_SET_VALUE,
    MEDICAL_AID_OWN_CONTRIBUTION_SET_VALUE,
    MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALUE,
    MEDICAL_AID_DEPENDENTS_SET_VALUE,
    PENSION_FUND_OWN_CONTRIBUTION_SET_VALUE,
    PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE,
    PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALUE,
    PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE,
    RETIREMENT_ANNUITY_SET_VALUE
} from "../../../actions/incomeTaxCalculator/advancedCalculator";

import { validationIsEqual } from "../status";
import { getOtherIncomeValue } from "../../../selectors/incomeTaxCalculator/advancedCalculator";

let prevValidationAction = null;
export const advancedCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AGE_SET_VALUE:
                error = validateAlphaString(action.value, {subject: 'Age'});
                return setAgeValid(error);

            case INCOME_FREQUENCY_SET_VALUE:
                error = validateAlphaString(action.value, {subject: 'income frequency'});
                return setIncomeFrequencyValid(error);

            case INCOME_VALUE_SET_VALUE:
                error = validateNumber(action.value, {min: 0, max: 999999, subject: 'income'});
                return setIncomeValueValid(error);

            case OTHER_INCOME_VALUE_SET_VALUE:
                if (action.value && action.value.length > 0) {
                error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Other income'});
                    return setOtherIncomeValueValid(error);
                }
                return setOtherIncomeValueValid(null);

            case OTHER_INCOME_DEDUCTION_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    const otherIncome = getOtherIncomeValue(store.getState());
                    error = validateNumber(action.value, { min: 0, max: otherIncome.value, subject: 'Other income deduction' });
                    return setOtherIncomeDeductionValid(error);
                }
                return setOtherIncomeDeductionValid(null);

            case MEDICAL_AID_OWN_CONTRIBUTION_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Medical aid own contribution'});
                    return setMedicalAidOwnContributionValid(error);
                }
                return setMedicalAidOwnContributionValid(null);

            case MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Medical aid employer contribution'});
                    return setMedicalAidEmployerContributionValid(error);
                }
                return setMedicalAidEmployerContributionValid(null);

            case MEDICAL_AID_DEPENDENTS_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 99, subject: 'Medical aid dependents number'});
                    return setMedicalAidDependentsValid(error);
                }
                return setMedicalAidDependentsValid(null);

            case PENSION_FUND_OWN_CONTRIBUTION_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Pension fund own contribution'});
                    return setPensionFundOwnContributionValid(error);
                }
                return setPensionFundOwnContributionValid(null);

            case PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Pension fund employer contribution'});
                    return setPensionFundEmployerContributionValid(error);
                }
                return setPensionFundEmployerContributionValid(null);

            case PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Provident fund contribution'});
                    return setProvidentFundOwnContributionValid(error);
                }
                return setProvidentFundOwnContributionValid(null);

            case PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Provident fund contribution'});
                    return setProvidentFundEmployerContributionValid(error);
                }
                return setProvidentFundEmployerContributionValid(null);

            case RETIREMENT_ANNUITY_SET_VALUE:
                if (action.value && action.value.length > 0) {
                    error = validateNumber(action.value, {min: 0, max: 999999, subject: 'Retirement annuity contribution'});
                    return setRetirementAnnuityValid(error);
                }
                return setRetirementAnnuityValid(null);

            default:
                return null;
        }
    })();
    
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
