import {validationIsEqual} from "../status";
import {
    validateAlphaString,
    validateIdNumber,
    validateAlphaNumericString,
    validatePrivateBag,
    validateStreetAddress,
    validateStreetWithNumber,
    validateEmail,
    validatePostalCode,
    validatePoBoxNumber,
    validateName,
    validateContactNumber,
    validateSingleSelection
} from  "@om/validation";
import {
    CITY_SET_VALUE,
    COMPLEX_SET_VALUE,
    COUNTRY_SET_VALUE,
    EMAIL_SET_VALUE,
    ID_SET_VALUE,
    PO_BOX_SET_VALUE,
    PO_COUNTRY_SET_VALUE,
    PO_POST_OFFICE_SET_VALUE,
    PO_POSTAL_CODE_SET_VALUE,
    POSTAL_ADDRESS_METHOD_VALUE,
    POSTAL_ADDRESS_SAME_VALUE,
    POSTAL_CITY_SET_VALUE,
    POSTAL_CODE_SET_VALUE,
    POSTAL_COMPLEX_SET_VALUE,
    POSTAL_COUNTRY_SET_VALUE,
    POSTAL_POSTAL_CODE_SET_VALUE,
    POSTAL_STREET_ADDRESS_SET_VALUE,
    POSTAL_SUBURB_SET_VALUE,
    PRIVATE_BAG_SET_VALUE,
    PRIVATE_COUNTRY_SET_VALUE,
    PRIVATE_POST_OFFICE_SET_VALUE,
    PRIVATE_POSTAL_CODE_SET_VALUE,
    STREET_ADDRESS_SET_VALUE,
    SUBURB_SET_VALUE,
    TITLE_SET_VALUE,
    FIRST_NAMES_SET_VALUE,
    SURNAME_SET_VALUE,
    MOBILE_SET_VALUE,
    setCityValid,
    setComplexValid,
    setCountryValid,
    setEmailValid,
    setIdValid,
    setPoBoxValid,
    setPoCountryValid,
    setPoPostalCodeValid,
    setPoPostOfficeValid,
    setPostalAddressMethodValid,
    setPostalAddressSameValid,
    setPostalCityValid,
    setPostalCodeValid,
    setPostalComplexValid,
    setPostalCountryValid,
    setPostalPostalCodeValid,
    setPostalStreetAddressValid,
    setPostalSuburbValid,
    setPrivateBagValid,
    setPrivateCountryValid,
    setPrivatePostalCodeValid,
    setPrivatePostOfficeValid,
    setStreetAddressValid,
    setSuburbValid,
    setTitleValid,
    setFirstNamesValid,
    setSurnameValid,
    setMobileValid,
} from "../../../actions/easiplusFuneralPlan/personalDetails";

let prevValidationAction = null;
export const personalDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        const trimValue = (value) => value ? value.trim() : value ;

        const removeWhiteSpacesFromValue = (value) => value ? value.replace(/ /g, '') : value;
    
        let error;
        switch (action.type) {
            // Personal details
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, 'Title');
                return setTitleValid(error);

            case FIRST_NAMES_SET_VALUE:
                //value gets trimmed here for validation purposes, displayValue(untrimmed) is used in the front end,  if else condition is to account for undefined value when running setNameValue without a param.
                error = validateName(trimValue(action.value), {min: 2, max: 48, subject: 'First name'});
                return setFirstNamesValid(error);

            case SURNAME_SET_VALUE:
                //value gets trimmed here for validation purposes, displayValue(untrimmed) is used in the front end,  if else condition is to account for undefined value when running setSurnameValue without a param.
                error = validateName(trimValue(action.value), {min: 2, max: 64, subject: 'Surname'});
                return setSurnameValid(error);

            case ID_SET_VALUE:
                error = validateIdNumber(trimValue(action.value), {required: true, subject: 'South African ID number', checkAge: true, minAge: 18, maxAge: 65});
                return setIdValid(error);

            // Contact details
            case MOBILE_SET_VALUE:
                error = validateContactNumber(removeWhiteSpacesFromValue(action.value), 'contact number');
                return setMobileValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(trimValue(action.value), {min: 6, max: 200, subject: 'Email address', required: true});
                return setEmailValid(error);

            // Address details
            case STREET_ADDRESS_SET_VALUE:
                error = validateStreetWithNumber(action.value, { min: 2, max: 30, subject: 'Street address', required: true });
                return setStreetAddressValid(error);

            case COMPLEX_SET_VALUE:
                error = validateStreetAddress(action.value, {min: 2, max: 30, required: false, subject: 'Complex or building'});
                return setComplexValid(error);

            case SUBURB_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Suburb', required: false});
                return setSuburbValid(error);

            case CITY_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'City'});
                return setCityValid(error);

            case POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(trimValue(action.value));
                return setPostalCodeValid(error);

            case COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'country');
                return setCountryValid(error);

            case POSTAL_ADDRESS_METHOD_VALUE:
                error = validateSingleSelection(action.value, 'option');
                return setPostalAddressMethodValid(error);

            case POSTAL_ADDRESS_SAME_VALUE:
                error = validateSingleSelection(action.value, 'option');
                return setPostalAddressSameValid(error);

            // Postal method - Private Bag
            case PRIVATE_BAG_SET_VALUE:
                error = validatePrivateBag(trimValue(action.value), {min: 4, max: 8, subject: 'Private bag number'});
                return setPrivateBagValid(error);

            case PRIVATE_POST_OFFICE_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Post office'});
                return setPrivatePostOfficeValid(error);

            case PRIVATE_POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(trimValue(action.value));
                return setPrivatePostalCodeValid(error);

            case PRIVATE_COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'country');
                return setPrivateCountryValid(error);

            // Postal method - PO Box
            case PO_BOX_SET_VALUE:
                error = validatePoBoxNumber(trimValue(action.value));
                return setPoBoxValid(error);

            case PO_POST_OFFICE_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Post office'});
                return setPoPostOfficeValid(error);

            case PO_POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(trimValue(action.value));
                return setPoPostalCodeValid(error);

            case PO_COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'country');
                return setPoCountryValid(error);

            // Postal method - Street Address
            case POSTAL_COMPLEX_SET_VALUE:
                error = validateStreetAddress(action.value, {min: 2, max: 30, required: false, subject: 'Complex or building'});
                return setPostalComplexValid(error);

            case POSTAL_STREET_ADDRESS_SET_VALUE:
                error = validateStreetWithNumber(action.value, { min: 2, max: 30, subject: 'Street name' });
                return setPostalStreetAddressValid(error);

            case POSTAL_SUBURB_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Suburb', required: false});
                return setPostalSuburbValid(error);

            case POSTAL_CITY_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'City'});
                return setPostalCityValid(error);

            case POSTAL_POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(trimValue(action.value));
                return setPostalPostalCodeValid(error);

            case POSTAL_COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value,'country');
                return setPostalCountryValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
}