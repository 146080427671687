import { getAccidentalQuoteToolForm } from "../../../selectors/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool";
import { transitionAccidentalDeathCoverToolForm } from "../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool";
import validate from "../../utils/formValidators";

export const accidentalQuoteToolFormRules = (store, next, action) => {
	const accidentalDeathCoverQuoteToolForm = getAccidentalQuoteToolForm(store.getState());
	const isFormValid = validate(accidentalDeathCoverQuoteToolForm, ['candidateDOB', 'coverAmount', 'numberOfYearsCovered'])
	const formAction = isFormValid
		? transitionAccidentalDeathCoverToolForm.formValid()
		: transitionAccidentalDeathCoverToolForm.formInvalid()

	store.dispatch(formAction)
	return next(action)
}
