import { SET_VALUE_ROUTING } from '../../../actions/domesticInsurance/routing';
import { routingRules } from './routing';
import {
	DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE,
	DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID,
} from '../../../actions/domesticInsurance/personalDetails';
import { personalDetailsFormRules } from './personalDetails';

import { BUILDING_DETAILS_FORM_SET_VALUE, BUILDING_DETAILS_FORM_SET_VALID } from '../../../actions/domesticInsurance/buildingDetails';
import { buildingDetailsFormRules } from './buildingDetails';
import { DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID, DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/domesticInsurance/Documents';
import { documenetsFormRules } from './documents';


import {
	MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE,
	MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/domesticInsurance/motorAgentModal';
import {  agentModaleFormRules } from './agentModal';

import { DOMESTIC_INSURANCE_FORM_SET_VALID, DOMESTIC_INSURANCE_FORM_SET_VALUE  } from '../../../actions/domesticInsurance/quote';
import { quoteFormRules } from './quote';
import { shareQuoteFormRules } from './shareQuote';
import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from '../../../actions/domesticInsurance/payments';
import {paymentsFormRules } from './payments'

export const domesticInsuranceRules = (store, next, action) => {
	if (action.type.startsWith(SET_VALUE_ROUTING)) {
		return routingRules(store, next, action);
	}
	if (
		action.type.startsWith(DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE) ||
		action.type.startsWith(DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID)
	) {
		return documenetsFormRules(store, next, action);
	}

	if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
		return paymentsFormRules(store, next, action);
	}


	if (action.type.startsWith(DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE) || action.type.startsWith(DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID)) {
		return personalDetailsFormRules(store, next, action);
	}

	if (
        action.type.startsWith(BUILDING_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(BUILDING_DETAILS_FORM_SET_VALID)
    ) {
        return buildingDetailsFormRules(store, next, action);
    }

	if (
		action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE) ||
		action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID)
	) {
		return agentModaleFormRules(store, next, action);
	}

	if (
        action.type.startsWith(DOMESTIC_INSURANCE_FORM_SET_VALUE) ||
        action.type.startsWith(DOMESTIC_INSURANCE_FORM_SET_VALID)
    ) {
        return quoteFormRules(store, next, action);
    }

	// if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
	// 	return shareQuoteFormRules(store, next, action);
	// }

	return null;
};
