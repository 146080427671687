import { createSelector } from "reselect";
import { getAllsureInsurance } from ".";

export const getHomeContentsDetails=createSelector(getAllsureInsurance,(allsureInsurance)=>allsureInsurance.homeContents)

export const getHomeContentForm = createSelector(getHomeContentsDetails,(homeContentDetails)=>homeContentDetails.form)

export const getHomeContentList=createSelector(getHomeContentsDetails,(homeContentDetails)=>homeContentDetails?.homeContentList)

export const getEditHomeContentKey=createSelector(getHomeContentsDetails,(homeContentDetails)=>homeContentDetails.editHomeContentKey)

