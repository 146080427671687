import { createSelector } from 'reselect';
import { getSavingsCalculators } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { formatCurrency } from "../currency";

import {
    ga_freq_fv,
    ls_fv,
    ls_pv,
    ga_freq_pmt,
    round,
    ea_to_freq,
} from '../../../tvm-calcs/src/index'

import {
    getExpectedAnnualGrowth,
    getAnnualIncreaseInContribution,
    getInflation
} from './howMuchYouNeedToSaveAssumptionsModel'

const assumptionModelDefaultValues = {
    expectedAnnualGrowthDefault: 8,
    annualIncreaseInContributionDefault: 10,
    inflationDefault: 5,
};

const getWhenWillYouReachYourSavingsGoal = createSelector(
    getSavingsCalculators,
    savingsCalculators => savingsCalculators.whenWillYouReachYourSavingsGoal
)

export const getWhenWillYouReachYourSavingsGoalForm = createSelector(
	getWhenWillYouReachYourSavingsGoal,
	getWhenWillYouReachYourSavingsGoal => getWhenWillYouReachYourSavingsGoal.form
);

export const getWhenWillYouReachYourSavingsGoalFormValid = createSelector(
	getWhenWillYouReachYourSavingsGoal,
	(getWhenWillYouReachYourSavingsGoal) => getWhenWillYouReachYourSavingsGoal.formStatus === FORM_STATUS.VALID
);

export const getShowRecommendation = createSelector(
	getWhenWillYouReachYourSavingsGoal,
	(getWhenWillYouReachYourSavingsGoal) => getWhenWillYouReachYourSavingsGoal.showRecommendation
);

const makeGetField = (fieldId) => createSelector(getWhenWillYouReachYourSavingsGoalForm, (form) => form[fieldId]);

export const getSavingsGoal = makeGetField('savingsGoal');
export const getCurrentSavingsGoal = makeGetField('currentSavingsGoal');
export const getExpectedMonthlyContributions = makeGetField('expectedMonthlyContributions');
export const getAnnualIncreaseInSavings = makeGetField('annualIncreaseInSavings');
export const getExpectedSavingsGrowth = makeGetField('expectedSavingsGrowth');

export const getShowProductOffering = createSelector(
    getWhenWillYouReachYourSavingsGoal,
    getWhenWillYouReachYourSavingsGoal => getWhenWillYouReachYourSavingsGoal.showProductOffering
);

export const getAssumptionsModal = createSelector(
    getWhenWillYouReachYourSavingsGoal,
    getWhenWillYouReachYourSavingsGoal => getWhenWillYouReachYourSavingsGoal.openModal
);


export const getAnnualIncreaseInContributionValue = createSelector(
    getAnnualIncreaseInContribution,
        (annualIncreaseInContribution) => {
            if(!annualIncreaseInContribution.value ||annualIncreaseInContribution.value === null || annualIncreaseInContribution.value < 10)
            {
                return assumptionModelDefaultValues.annualIncreaseInContributionDefault;
            }
            else{
                return annualIncreaseInContribution.value
            }
        }
    )

export const getExpectedAnnualGrowthValue = createSelector(
    getExpectedAnnualGrowth,
    (expectedAnnualGrowth) => {
            if(!expectedAnnualGrowth.value || expectedAnnualGrowth.value === null || expectedAnnualGrowth.value > 30)
        {
            return (assumptionModelDefaultValues.expectedAnnualGrowthDefault)/100;
        }
        else{
            return (expectedAnnualGrowth.value)/100
        }
    }
)

export const getInflationValue = createSelector(
    getInflation,
    (inflation) => {
        if(!inflation.value || inflation.value > 30)
        {
            return (assumptionModelDefaultValues.inflationDefault)/100;
        }
        else{
            return (inflation.value)/ 100
        }
    }
)

export const getShowResultSummary = createSelector(
    getWhenWillYouReachYourSavingsGoal,
    (whenWillYouReachYourSavingsGoal) => whenWillYouReachYourSavingsGoal.showResultSummary
    
);

export const getButtonDisabledValue = createSelector(
    getShowResultSummary,
    getWhenWillYouReachYourSavingsGoalFormValid,
    (showResultSummary, formValid) => {
        if(formValid === false && showResultSummary === false){
            return true;
        }
        if(formValid === false && showResultSummary === true){
            return false;
        }
        if(showResultSummary === true && formValid === true){
            return true;
        }
        if(formValid === true && showResultSummary === false){
            return false;
        }
    }
);


