import {
    setMobileValue,
    setNameValue,
    setSurnameValue,
    transitionCaptureDetails,
    NAME_SET_VALUE,
    SURNAME_SET_VALUE
} from "../../../actions/raOptimal/captureDetails";
import {getCaptureDetailsForm, getMobile, getName, getSurname} from "../../../selectors/raOptimal/captureDetails";
import {FIELD_STATUS} from "../../../reducers/status";
import {getIsAccountHolderNameEdited} from "../../../selectors/raOptimal/paymentDetails";
import {presetAccountHolderNameValue} from "../../../actions/raOptimal/paymentDetails";

export const captureDetailsContinueRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    if (getName(state).status !== FIELD_STATUS.VALID) store.dispatch(setNameValue(getName(state).value));
    if (getSurname(state).status !== FIELD_STATUS.VALID) store.dispatch(setSurnameValue(getSurname(state).value));
    if (getMobile(state).status !== FIELD_STATUS.VALID) store.dispatch(setMobileValue(getMobile(state).value));

    return result;
};

export const captureDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const hasAccountHolderNameBeenEdited = getIsAccountHolderNameEdited(state);
    const firstName = getName(state).value;
    const surname = getSurname(state).value;
    if ((action.type === NAME_SET_VALUE || action.type === SURNAME_SET_VALUE) && !hasAccountHolderNameBeenEdited) {
        store.dispatch(presetAccountHolderNameValue(firstName + ' ' + surname));
    }

    const captureDetailsForm = getCaptureDetailsForm(store.getState());
    const captureDetailsValid = validateDropoffDetails(captureDetailsForm);
    const formAction = captureDetailsValid
        ? transitionCaptureDetails.formValid()
        : transitionCaptureDetails.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validateDropoffDetails = formFields => (
    formFields.name && formFields.name.status === FIELD_STATUS.VALID &&
    formFields.surname && formFields.surname.status === FIELD_STATUS.VALID &&
    formFields.mobile && formFields.mobile.status === FIELD_STATUS.VALID
);
