import { getIsNationalityApiPending } from '../../../selectors/personalPensionPlan/personalDetails';
import { apiTransitionNationalities, setNationalitiesList } from '../../../actions/personalPensionPlan/personalDetails';
import { getAccessToken } from '../../../selectors/personalPensionPlan/payments';
import axios from 'axios';

export const getNationalityService = (store, next, action) => {
    (async () => {
        if (!getIsNationalityApiPending(store.getState())) {
            store.dispatch(apiTransitionNationalities.pending());
            try {
                const resp = await getNationalities(store.getState());
                store.dispatch(setNationalitiesList(resp.data));
                store.dispatch(apiTransitionNationalities.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionNationalities.failure());
            }
        }
    })();
    return next(action);
};

const getNationalities = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ug-motor-insurance/nationalities';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
