import {
    IFRAME_LOADED_SET_VALUE,
    VERIFICATION_STATUS_SET_VALUE,
    OPEN_SOMETHING_LOOKS_WRONG_MODAL_SET_VALUE,
    OPEN_LOADING_MODAL_SET_VALUE,
    OPEN_VERIFICATION_MODAL_SET_VALUE,
    OPEN_SUCCESSFULLY_VERIFIED_MODAL_SET_VALUE,
    IIDENTIFII_REQUEST_ID_SET_VALUE,
    VERIFICATION_FAILED_COUNT_SET_VALUE,
    IFRAME_RESPONSE_DATA_SET_VALUE,
    VALIDATION_PASSED_SET_VALUE,
    SERVICE_PROVIDER_SET_VALUE,
    IS_VALID_SET_VALUE,
    MESSAGES_SET_VALUE,
    ERRORS_SET_VALUE,
    OPEN_CMB_MODAL_SET_VALUE
} from "../../actions/customerVerificationService/selfie";

export const initSelfie = {
    iframeLoaded: false,
    verificationStatus: false,
    verificationFailedCount: 0,
    openSomethingLooksWrongModal: false,
    openLoadingModal: true,
    openVerificationModal: false,
    openSuccessfullyVerifiedModal: false,
    iidentifiiRequestID: null,
    iframeResponseData: null,
    liveness: {
        validationPassed: false,
        serviceProvider: 0,
        isValid: false,
        messages: [],
        errors: []
    }
};

export default (state = initSelfie, action) => {
    switch(action.type){
        case IFRAME_LOADED_SET_VALUE:
            return { 
                ...state, 
                iframeLoaded: action.value,
            };
        case VERIFICATION_STATUS_SET_VALUE:
            return { 
                ...state, 
                verificationStatus: action.value,
            };

        case VERIFICATION_FAILED_COUNT_SET_VALUE:
            return {
                ...state,
                verificationFailedCount: action.value,
            };

        case OPEN_SOMETHING_LOOKS_WRONG_MODAL_SET_VALUE:
            return { 
                ...state, 
                openSomethingLooksWrongModal: action.value,
            };
        case OPEN_LOADING_MODAL_SET_VALUE:
            return { 
                ...state, 
                openLoadingModal: action.value,
            };
        case OPEN_VERIFICATION_MODAL_SET_VALUE:
            return { 
                ...state, 
                openVerificationModal: action.value,
            };
        case OPEN_SUCCESSFULLY_VERIFIED_MODAL_SET_VALUE:
            return { 
                ...state, 
                openSuccessfullyVerifiedModal: action.value,
            };
        case OPEN_CMB_MODAL_SET_VALUE:
            return { 
                ...state, 
                openCmbModal: action.value,
            };
        case IIDENTIFII_REQUEST_ID_SET_VALUE:
            return {
                ...state,
                iidentifiiRequestID: action.value,
            }
        case IFRAME_RESPONSE_DATA_SET_VALUE:
            return {
                ...state,
                iframeResponseData: action.value,
            }
        case VALIDATION_PASSED_SET_VALUE: 
            return {
                ...state,
                liveness: {
                    ...state.liveness,
                    validationPassed: action.value,
                },
            }
        case SERVICE_PROVIDER_SET_VALUE: 
            return {
                ...state,
                liveness: {
                    ...state.liveness,
                    serviceProvider: action.value,
                },
            }
        case IS_VALID_SET_VALUE: 
            return {
                ...state,
                liveness: {
                    ...state.liveness,
                    isValid: action.value,
                },

            }
        case MESSAGES_SET_VALUE: 
            return {
                ...state,
                liveness: {
                    ...state.liveness,
                    messages: action.value,
                },
            }
        case ERRORS_SET_VALUE: 
            return {
                ...state,
                liveness: {
                    ...state.liveness,
                    errors: action.value,
                },
            }
        
        default:
            return state;
    }
}