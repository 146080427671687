
export const todayAsValidationParam = () => {
    return toDateString(todayDateArray());
};

export const formatDateAsValidationParam = date => {
    return toDateString(dateToDateArray(date));
};

function todayDateArray() {
    return dateToDateArray(new Date());
}

function dateToDateArray(date) {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
}

function toDateString(dateArray) {
    const day = dateArray[DATE_INDEX].toString().length < 2 ? '0'
    + dateArray[DATE_INDEX].toString() : dateArray[DATE_INDEX];
    const month = dateArray[MONTH_INDEX].toString().length < 2 ? '0'
    + dateArray[MONTH_INDEX].toString() : dateArray[MONTH_INDEX];
    return `${day}/${month}/${dateArray[YEAR_INDEX]}`;
}


/** Index of date in date array */
const DATE_INDEX = 0;
/** Index of month in date array */
const MONTH_INDEX = 1;
/** Index of year in date array */
const YEAR_INDEX = 2;
