import { createSelector } from 'reselect';
import { getRoaQuoteTools } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDigiLifeQuoteTool = createSelector(
    getRoaQuoteTools,
    (roaQuoteTools) => roaQuoteTools.digiLifeQuoteTool
);

export const getDigiLifeQuoteToolForm = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) => digiLifeQuoteTool.form
);

const makeGetFieldForm = (fieldId) => createSelector(getDigiLifeQuoteToolForm, (form) => form[fieldId]);

export const getDigiLifeQuoteToolFormValid = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) => digiLifeQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getSelectedForm = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) => digiLifeQuoteTool && digiLifeQuoteTool.selectedForm
);

export const getDigiLifePremiumAmount = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) => digiLifeQuoteTool.digiLifePremiumValue
);

export const getRoaCmbObject = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) => digiLifeQuoteTool.roaCmbObject
);

export const getApiStatus = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) =>
        digiLifeQuoteTool.apiCallStatus
);

export const getIsApiPending = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) =>
        digiLifeQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING
);
export const getIsApiFailure = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) =>
        digiLifeQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE
);
export const getIsApiSuccess = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) =>
        digiLifeQuoteTool.apiCallStatus === API_CALL_STATUS.SUCCESS
);

export const getCalculateDisabled = createSelector(
    getDigiLifeQuoteTool,
    (digiLifeQuoteTool) => {
        return (
            digiLifeQuoteTool.formStatus === FORM_STATUS.INVALID ||
            digiLifeQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING
        );
    }
);

// export const getFullName = makeGetFieldForm('fullName');
// export const getContactNumber = makeGetFieldForm('contactNumber');
// export const getEmailId = makeGetFieldForm('emailId');
export const getGender = makeGetFieldForm('gender');
export const getDateOfBirth = makeGetFieldForm('DOB');
export const getSalary = makeGetFieldForm('salary');
export const getEducation = makeGetFieldForm('education');
export const getLoanType = makeGetFieldForm('loanType');
export const getLoanAmount = makeGetFieldForm('loanAmount');
export const getLoanTerm = makeGetFieldForm('loanTerm');
export const getPayment = makeGetFieldForm('payment');
export const getCashBack = makeGetFieldForm('cashBack');
export const getDate = makeGetFieldForm('date');
