import { RESULTS_VIEW_VALUE } from './emergencyFund';

export const INVESTMENT_CALCULATOR = 'investment/calculator/'
export const INVESTMENT_CALCULATOR_SET_VALUE = 'investment/calculator/setValue/';
export const WHAT_TO_CALCULATE_SET_VALUE = INVESTMENT_CALCULATOR_SET_VALUE + 'whatToCalculate';
export const RESULTS_SET_VALUE = INVESTMENT_CALCULATOR_SET_VALUE + 'setResults';
export const REINITIALIZE_STATE_INVESTMENT_CALCULATOR = 'investment/calculator/reinitialize';
export const SELECT_PCT_INVESTMENT_CALCULATOR = INVESTMENT_CALCULATOR + 'selectPct'
export const SELECT_YEARS_INVESTMENT_CALCULATOR = INVESTMENT_CALCULATOR + 'selectYears'

export const SET_VIEW_RECOMMENDATIONS_INVESTMENT_CALCULATOR = INVESTMENT_CALCULATOR + 'setViewRecommendation'

export const setSelectedForm = value => ({ type: WHAT_TO_CALCULATE_SET_VALUE, value });
export const setViewInvestmentResult = value => ({ type: RESULTS_VIEW_VALUE, value });
export const reInitialize = () => ({ type: REINITIALIZE_STATE_INVESTMENT_CALCULATOR });

export const setSelectedDrop = value => ({ type: SELECT_PCT_INVESTMENT_CALCULATOR, value });
export const setSelectedDuration = value => ({ type: SELECT_YEARS_INVESTMENT_CALCULATOR, value });
export const setShowRecommendation = () => ({ type: SET_VIEW_RECOMMENDATIONS_INVESTMENT_CALCULATOR }); 
