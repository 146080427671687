import { createSelector } from 'reselect';
import { getPersonalPensionPlanState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getQuote = createSelector(getPersonalPensionPlanState, (state) => {
    return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculation = createSelector(getQuote, (quote) => quote && quote.premiumCalculation);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.SUCCESS
);
export const getQuoteFormApiCallFailure = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.FAILURE
);
export const getIsApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.PENDING
);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);

export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus);
export const getUgCmbObject = createSelector(getQuote, (quote) => quote && quote.ugCmbObject);
const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getIsExpiredAuth = createSelector(getQuote, (quote) => quote && quote.isTokenExpired);

export const getMaturityYears = createSelector(getQuote, (quote) => quote && quote.maturityYears);

export const getPrepaidLumpsumCheckApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.prepaidLumpsumCheckApiStatus === API_CALL_STATUS.PENDING
);

export const getPrepaidLumpsumCheckApiSuccess = createSelector(
    getQuote,
    (quote) => quote && quote.prepaidLumpsumCheckApiStatus === API_CALL_STATUS.SUCCESS
);

//new values
export const getShareEmailValue = makeGetFormField('shareEmail');
export const getPhoneNumberValue = makeGetFormField('phoneNumber');
export const getGenderValue = makeGetFormField('gender');
export const getContributionModeValue = makeGetFormField('contributionMode');
export const getDobValue = makeGetFormField('dob');
export const getInterestRateValue = makeGetFormField('interestRate');
export const getDesiredMonthlyContributionValue = makeGetFormField('desiredMonthlyContribution');
export const getLumpsumAmountValue = makeGetFormField('lumpsumAmount');
export const getDesiredRetirementAgeValue = makeGetFormField('desiredRetirementAge');
export const getFrequencyOfContributionValue = makeGetFormField('frequencyOfContribution');
export const getIsLumpsumPrepaidValue = makeGetFormField('isLumpsumPrepaid');
export const getPrepaidTransactionCodeValue = makeGetFormField('prepaidTransactionCode');
export const getPrepaidModeOfPaymentValue = makeGetFormField('prepaidModeOfPayment');
