import axios from 'axios';
import {getIsCoverStartDateApiPending, getCarDetailsForm} from "../../../selectors/roaCarInsurance/details";
import {
	apiTransitionValidateCoverDate,
	setStartDateValue,
	setStartDateValid
} from "../../../actions/roaCarInsurance/details";

export const validateStartDateService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();
	(async () => {
		if(!getIsCoverStartDateApiPending(state)) {
			store.dispatch(
				apiTransitionValidateCoverDate.pending()
			)
			try {
				const resp = await validateCoverStartDate(state)
				store.dispatch(setStartDateValue(resp.data))
				store.dispatch(apiTransitionValidateCoverDate.success())

			} catch (e) {
				store.dispatch(setStartDateValid(e))
				store.dispatch(apiTransitionValidateCoverDate.failure())
			}
		}
	})()
	return result
}

const validateCoverStartDate = async (state) => {
	const carDetails = getCarDetailsForm(state);
    const startdate = carDetails.coverStartDate.value;
    const payload = {startdate, session_id:'TMSUXS'}
	try {
		const url = '/om-api/roa-car-insurance/validate-startdate'
		return await axios.post(url, payload)
	} catch (e) {
		console.error(e)
		throw e
	}
}