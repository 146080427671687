
export const UPLOAD_DOCUMENTS_SET_VALUE = 'digiLifeLoanProtection/uploadDocuments/setValue/';
export const UPLOAD_DOCUMENTS_SET_VALID = 'digiLifeLoanProtection/uploadDocuments/setValid/';

export const FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS = 'digiLifeLoanProtection/uploadDocuments/formValid';
export const FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS = 'digiLifeLoanProtection/uploadDocuments/formInvalid';

// export const ID_DOCUMENTS_SET_VALID = UPLOAD_DOCUMENTS_SET_VALID + 'idDocument'

export const setFormValid= () => ({ type: FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS })
  export const  setFormInvalid= () => ({ type: FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS })

//FOR ID DOCUMENT
export const ID_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'idDocument'
export const ID_DOCUMENTS_SET_VALID = UPLOAD_DOCUMENTS_SET_VALID + 'idDocument'
export const CLEAR_ID_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'clearIdDocument'
//FOR INCOME_PROOF DOCUMENT
export const INCOME_PROOF_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'incomeProofDocument'
export const INCOME_PROOF_DOCUMENTS_SET_VALID = UPLOAD_DOCUMENTS_SET_VALID + 'incomeProofDocument'
export const CLEAR_INCOME_PROOF_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'clearIncomeProofDocument'
//FOR ADDRESS_PROOF DOCUMENT
export const ADDRESS_PROOF_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'addressProofDocument'
export const ADDRESS_PROOF_DOCUMENTS_SET_VALID = UPLOAD_DOCUMENTS_SET_VALID + 'addressProofDocument'
export const CLEAR_ADDRESS_PROOF_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'clearAddressProofDocument'
//FOR LOAN LETTER DOCUMENT
export const LOAN_LETTER_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'loanLetterDocument'
export const LOAN_LETTER_DOCUMENTS_SET_VALID = UPLOAD_DOCUMENTS_SET_VALID + 'loanLetterDocument'
export const CLEAR_LOAN_LETTER_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'clearLoanLetterDocument'
//FOR BANK TATEMENT DOCUMENT
export const BANK_STATEMENT_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'bankStatementDocument'
export const BANK_STATEMENT_DOCUMENTS_SET_VALID = UPLOAD_DOCUMENTS_SET_VALID + 'bankStatementDocument'
export const CLEAR_BANK_STATEMENT_ID_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'clearBankStatementDocument'

//RESET DOCUMENTS
export const RESET_DOCUMENTS_SET_VALUE = UPLOAD_DOCUMENTS_SET_VALUE + 'reset'


export const setIdDocumentsValue = (fileName,fileContaint,file) => ({ type: ID_DOCUMENTS_SET_VALUE, fileName,fileContaint,file });
export const setIdDocumentsValid = error => ({ type: ID_DOCUMENTS_SET_VALID, error });

export const setIncomeProofDocumentsValue = (fileName,fileContaint,file) => ({ type: INCOME_PROOF_DOCUMENTS_SET_VALUE, fileName,fileContaint,file });
export const setIncomeProofDocumentsValid = error => ({ type: INCOME_PROOF_DOCUMENTS_SET_VALID, error });

export const setAddressProofDocumentsValue = (fileName,fileContaint,file) => ({ type: ADDRESS_PROOF_DOCUMENTS_SET_VALUE, fileName,fileContaint,file });
export const setAddressProofDocumentsValid = error => ({ type: ADDRESS_PROOF_DOCUMENTS_SET_VALID, error });

export const setloanLetterDocumentsValue = (fileName,fileContaint,file) => ({ type: LOAN_LETTER_DOCUMENTS_SET_VALUE, fileName,fileContaint,file });
export const setloanLetterDocumentsValid = error => ({ type: LOAN_LETTER_DOCUMENTS_SET_VALID, error });

export const setbankStatementDocumentsValue = (fileName,fileContaint,file) => ({ type: BANK_STATEMENT_DOCUMENTS_SET_VALUE, fileName,fileContaint,file });
export const setbankStatementDocumentsValid = error => ({ type: BANK_STATEMENT_DOCUMENTS_SET_VALID, error });

export const clearIdDocumentsValue = () => ({ type: CLEAR_ID_DOCUMENTS_SET_VALUE});
export const clearIncomeProofDocumentsValue = () => ({ type: CLEAR_INCOME_PROOF_DOCUMENTS_SET_VALUE});
export const clearAddressProofDocumentsValue = () => ({ type: CLEAR_ADDRESS_PROOF_DOCUMENTS_SET_VALUE});
export const clearLoanLetterDocumentsValue = () => ({ type: CLEAR_LOAN_LETTER_DOCUMENTS_SET_VALUE});
export const clearBankStatementDocumentsValue = () => ({ type: CLEAR_BANK_STATEMENT_ID_DOCUMENTS_SET_VALUE});

export const resetUploadDocuments=()=>({ type: RESET_DOCUMENTS_SET_VALUE});