import {
    paymentAuthorizationInvalid,
    resetMonthToSkipValue,
    setDisplayLegalSection,
    setMonthToSkipValue,
    setBankNameValue,
    setAccountNumberValue,
    setAccountTypeValue,
    setDebitOrderDayValue,
    setMonthValue,
    setLumpSumDayValue,
    setLumpSumMonthValue,
    TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    setAccountValidationReset,
    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    setSourceOfIncomeValue,
    setSourceOfIncomeOtherValue,
    setEmploymentIndustryValue,
    setEmploymentPositionValue,
    setSourceOfFundsValue,
    setEmploymentIndustryOtherValue,
    SOURCE_OF_INCOME_SET_VALUE,
    EMPLOYMENT_POSITION_SET_VALUE,
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    resetEmploymentIndustryOtherValue,
    resetEmploymentIndustryValue,
    resetSourceOfIncomeOtherValue,
    setIdentityDocumentValue,
    setBirthCertificateValue,
    setCourtMandateValue,
} from "../../../actions/taxFreeSavingsAccount/paymentDetails";
import {
    getCheckboxChecked,
    getSkipMonth,
    getPaymentDetailsForm,
    getPaymentAuthorizationChecked,
    getIsEmploymentIndustryRequired,
    getIsEmploymentIndustryOtherRequired,
    getIsSourceOfIncomeOtherRequired,
    getUploadedIdentityDocumentFile,
    getUploadedBirthCertificateFile,
    getUploadedCourtMandateFile,
} from "../../../selectors/taxFreeSavingsAccount/paymentDetails";
import {
    getContributionType
} from "../../../selectors/taxFreeSavingsAccount/investmentSetupContribution";
import {
    getSavingForMinor,
    getIsParent,
    getIsLegalGuardian,
} from "../../../selectors/taxFreeSavingsAccount/investmentSetupInitial";
import { getIsMinorSurnameSameParent } from "../../../selectors/taxFreeSavingsAccount/auxiliaryDetails";
import { scrollToError } from "../../../actions/taxFreeSavingsAccount/scrolling";
import { TFSA_PAYMENT_DETAILS_SCROLL_ID } from "../status";
import { ACCOUNT_VALIDATION_STATUS, CONTRIBUTION_TYPE } from "../../../actions/taxFreeSavingsAccount/types";

export const paymentDetailsRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const skipMonth = getSkipMonth(state);
    if (action.type === TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS && !skipMonth) {
        store.dispatch(resetMonthToSkipValue());
    }

    const isSourceOfIncomeOtherRequired = getIsSourceOfIncomeOtherRequired(state);
    if (action.type === SOURCE_OF_INCOME_SET_VALUE && !isSourceOfIncomeOtherRequired) {
        store.dispatch(resetSourceOfIncomeOtherValue());
    }

    const isEmploymentIndustryRequired = getIsEmploymentIndustryRequired(state);
    if (action.type === EMPLOYMENT_POSITION_SET_VALUE && !isEmploymentIndustryRequired) {
        store.dispatch(resetEmploymentIndustryValue());
        store.dispatch(resetEmploymentIndustryOtherValue());
    }

    const isEmploymentIndustryOtherRequired = getIsEmploymentIndustryOtherRequired(state);
    if (action.type === EMPLOYMENT_INDUSTRY_SET_VALUE && !isEmploymentIndustryOtherRequired) {
        store.dispatch(resetEmploymentIndustryOtherValue());
    }

    const displayLegalSection = getCheckboxChecked(state);
    if (action.type === ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS && !displayLegalSection 
         && (action.value === ACCOUNT_VALIDATION_STATUS.ERROR || action.value === ACCOUNT_VALIDATION_STATUS.PASSED)) {
        store.dispatch(setDisplayLegalSection());
    }

    const updatingBankDetails = action.type.includes(BANK_NAME_SET_VALUE) ||
        action.type.includes(ACCOUNT_NUMBER_SET_VALUE) ||
        action.type.includes(ACCOUNT_TYPE_SET_VALUE);
    const paymentAuthorizationChecked = getPaymentAuthorizationChecked(state);
    
    if (updatingBankDetails && paymentAuthorizationChecked) {
        store.dispatch(setAccountValidationReset());
    }

    if (action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS && !paymentAuthorizationChecked) {
        store.dispatch(setAccountValidationReset());
    }

    return result;
};

export const performPaymentDetailsValidation = (store) => {
    const state = store.getState();
    const paymentDetailsForm = getPaymentDetailsForm(state);
    const paymentAuthorizationChecked = getPaymentAuthorizationChecked(state);
    const displayLegalSection = getCheckboxChecked(state);
    const contributionTypeValue = getContributionType(state).value;

    const investingForMinor = getSavingForMinor(state);
    const isParent = getIsParent(state);
    const isLegalGuardian = getIsLegalGuardian(state);
    const parentMinorSameSurname = getIsMinorSurnameSameParent(state);
    const identityDocument = getUploadedIdentityDocumentFile(state);
    const birthCertificate = getUploadedBirthCertificateFile(state);
    const courtMandate = getUploadedCourtMandateFile(state);

    if (!paymentAuthorizationChecked) {
        store.dispatch(setBankNameValue(paymentDetailsForm.bankName.value));
        store.dispatch(setAccountNumberValue(paymentDetailsForm.accountNumber.value));
        store.dispatch(setAccountTypeValue(paymentDetailsForm.accountType.value));
    }

    if (contributionTypeValue === CONTRIBUTION_TYPE.MONTHLY || contributionTypeValue === CONTRIBUTION_TYPE.COMBINATION) {
        store.dispatch(setDebitOrderDayValue(paymentDetailsForm.debitOrderDay.value));
        store.dispatch(setMonthValue(paymentDetailsForm.month.value));

        const skipMonthSelected = getSkipMonth(state);
        if (skipMonthSelected) {
            store.dispatch(setMonthToSkipValue(paymentDetailsForm.monthToSkip.value));
        }
    }

    if (contributionTypeValue === CONTRIBUTION_TYPE.LUMP_SUM || contributionTypeValue === CONTRIBUTION_TYPE.COMBINATION) {
        store.dispatch(setLumpSumDayValue(paymentDetailsForm.lumpSumDay.value));
        store.dispatch(setLumpSumMonthValue(paymentDetailsForm.lumpSumMonth.value));
    }

    if (!paymentAuthorizationChecked) {
        store.dispatch(paymentAuthorizationInvalid());
    }

    if (displayLegalSection) {
        const sourceOfIncomeOtherRequired = getIsSourceOfIncomeOtherRequired(state);
        const employmentIndustryRequired = getIsEmploymentIndustryRequired(state);
        const employmentIndustryOtherRequired = getIsEmploymentIndustryOtherRequired(state);

        store.dispatch(setSourceOfIncomeValue(paymentDetailsForm.sourceOfIncome.value));
        store.dispatch(setSourceOfFundsValue(paymentDetailsForm.sourceOfFunds.value));
        store.dispatch(setEmploymentPositionValue(paymentDetailsForm.employmentPosition.value));

        if (sourceOfIncomeOtherRequired) {
            store.dispatch(setSourceOfIncomeOtherValue(paymentDetailsForm.sourceOfIncomeOther.value));
        }

        if (employmentIndustryRequired) {
            store.dispatch(setEmploymentIndustryValue(paymentDetailsForm.employmentIndustry.value));

            if (employmentIndustryOtherRequired) {
                store.dispatch(setEmploymentIndustryOtherValue(paymentDetailsForm.employmentIndustryOther.value));
            }
        }
    }

    if(investingForMinor && isParent && parentMinorSameSurname){
       store.dispatch(setIdentityDocumentValue(identityDocument));
    }

    if(investingForMinor && isParent && !parentMinorSameSurname){
        store.dispatch(setBirthCertificateValue(birthCertificate));
    }

    if(investingForMinor & isLegalGuardian ){
        store.dispatch(setIdentityDocumentValue(identityDocument));
        store.dispatch(setCourtMandateValue(courtMandate));
    }

    const form = getPaymentDetailsForm(store.getState())
    store.dispatch(scrollToError(TFSA_PAYMENT_DETAILS_SCROLL_ID, form));
};