import axios from "axios";
export const SAVINGS_POT_CALCULATOR_FORM_TRANSITION =
  "savingsPotCalculator/savingsPotCalculatorForm/transition/";

export const FORM_VALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION =
  "savingsPotCalculator/savingsPotCalculatorForm/formValid";
export const FORM_INVALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION =
  "savingsPotCalculator/savingsPotCalculatorForm/formInvalid";

export const SAVINGS_POT_CALCULATOR_FORM_SET_VALUE =
  "savingsPotCalculator/savingsPotCalculatorForm/setValue/";
export const SAVINGS_POT_CALCULATOR_FORM_SET_VALID =
  "savingsPotCalculator/savingsPotCalculatorForm/setValid/";
export const transitionSavingsPotCalculatorForm = {
  formValid: () => ({
    type: FORM_VALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION,
  }),
  formInvalid: () => ({
    type: FORM_INVALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION,
  }),
};

export const TOGGLE_SHOW_PRODUCT_OFFERING =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALUE + "toggle/showProductOffering";
export const toggleViewRecommendation = () => ({
  type: TOGGLE_SHOW_PRODUCT_OFFERING,
});
export const PRODUCT_RECOMMENDATION_SET_VALUE =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALUE + "productRecommendation";

export const RESET_CALCULATOR_FORM =
  "savingsPotCalculator/savingsPotCalculatorForm/reset/form";
export const resetCalculatorForm = () => ({ type: RESET_CALCULATOR_FORM });

export const REINITIALIZE_STATE_SAVINGS_POT_CALCULATOR_FORM =
  "savingsPotCalculator/savingsPotCalculatorForm/reinitialize";

export const RETIREMENT_AMOUNT_SET_VALUE =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALUE + "retirementAmount";
export const RETIREMENT_AMOUNT_SET_VALID =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALID + "retirementAmount";
export const MONTHLY_INCOME_SET_VALUE =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALUE + "monthlyIncome";
export const MONTHLY_INCOME_SET_VALID =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALID + "monthlyIncome";
export const SHOW_FIELD_DETAILS =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALUE + "showFieldDetails";
export const INPUT_DISABLED_SET_VALUE =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALUE + "inputDisabled";
export const DISPLAY_RESET_SET_VALUE =
  SAVINGS_POT_CALCULATOR_FORM_SET_VALUE + "displayReset";

export const SAVINGS_POT_CALCULATOR_SHOW_RESULTS_SUMMARY = `${SAVINGS_POT_CALCULATOR_FORM_SET_VALUE}/showResultsSummary`;
export const SAVINGS_POT_CALCULATOR_SHOW_LOADER = `${SAVINGS_POT_CALCULATOR_FORM_SET_VALUE}/showLoader`;

export const setShowResultsSummary = () => ({
  type: SAVINGS_POT_CALCULATOR_SHOW_RESULTS_SUMMARY,
});
export const setShowLoader = () => ({
  type: SAVINGS_POT_CALCULATOR_SHOW_LOADER,
});

export const setRetirementAmountValue = (value) => ({
  type: RETIREMENT_AMOUNT_SET_VALUE,
  value,
});
export const setRetirementAmountValid = (error) => ({
  type: RETIREMENT_AMOUNT_SET_VALID,
  error,
});

export const setMonthlyIncomeValue = (value) => ({
  type: MONTHLY_INCOME_SET_VALUE,
  value,
});
export const setMonthlyIncomeValid = (error) => ({
  type: MONTHLY_INCOME_SET_VALID,
  error,
});
export const reInitialize = () => ({
  type: REINITIALIZE_STATE_SAVINGS_POT_CALCULATOR_FORM,
});

export const setViewRecommendation = () => ({
  type: PRODUCT_RECOMMENDATION_SET_VALUE,
});
export const showFieldDetails = (value) => ({
  type: SHOW_FIELD_DETAILS,
  value,
});

export const toggleInputDisabled = () => ({ type: INPUT_DISABLED_SET_VALUE });
export const setDisplayReset = () => ({ type: DISPLAY_RESET_SET_VALUE });

export const apiCallSuccess = (response) => ({
  type: "API_CALL_SUCCESS",
  payload: response,
});

export const apiCallFailure = (error) => ({
  type: "API_CALL_FAILURE",
  payload: error,
});

export const submitForm = (formData) => async (dispatch) => {
  try {
    dispatch(setShowLoader());
    const { monthlyIncome, retirementAmount } = formData;
    const requestPayload = buildAPIRequest(
      monthlyIncome.value,
      retirementAmount.value
    );

    const response = await axios.post(
      "/om-api/savings-pot-calculator/",
      JSON.stringify(requestPayload),
      { headers: { "Content-Type": "application/json" } }
    );

    dispatch(apiCallSuccess(response.data));
    dispatch(setShowResultsSummary());
  } catch (error) {
    dispatch(apiCallFailure(error));
  }
};

const buildAPIRequest = (monthlyIncome, retirementAmount) => {
  return {
    retirementAmount,
    annualIncome: monthlyIncome * 12,
    withdrawalAmount: {
      amount: retirementAmount,
      isPercentage: false,
    },
  };
};
