export const RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_FORM_TRANSITION = 'retirementCalculator/retirementCalculatorAssumptionsModel/transition/'

export const RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_TRANSITION_FORM_VALID = 'retirementCalculator/retirementCalculatorAssumptionsModel/formValid';
export const RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_TRANSITION_FORM_INVALID = 'retirementCalculator/retirementCalculatorAssumptionsModel/formInvalid';

export const RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE = 'retirementCalculator/retirementCalculatorAssumptionsModel/setValue/';
export const RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALID = 'retirementCalculator/retirementCalculatorAssumptionsModel/setValid/';

export const transitionRetirementCalculatorAssumptionsModelForm = {
    formValid: () => ({ type: RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_TRANSITION_FORM_VALID }),
    formInvalid: () => ({ type: RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_TRANSITION_FORM_INVALID }),
};

export const RETIREMENT_AGE_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'retirementAge';
export const RETIREMENT_AGE_SET_VALID = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALID + 'retirementAge';
export const setRetirementAgeValue = value => ({type: RETIREMENT_AGE_SET_VALUE, value})
export const setRetirementAgeValid = error => ({type: RETIREMENT_AGE_SET_VALID, error})

export const NUMBER_OF_RETIREMENT_YEARS_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'numberOfRetiremntYears';
export const NUMBER_OF_RETIREMENT_YEARS_SET_VALID = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALID + 'numberOfRetiremntYears';
export const setNumberOfRetiremntYearsValue = value => ({type: NUMBER_OF_RETIREMENT_YEARS_SET_VALUE, value})
export const setNumberOfRetiremntYearsValid = error => ({type: NUMBER_OF_RETIREMENT_YEARS_SET_VALID, error})

export const EXPECTED_ANNUAL_GROWTH_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'expectedAnnualGrowth';
export const EXPECTED_ANNUAL_GROWTH_SET_VALID = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALID + 'expectedAnnualGrowth';
export const setExpectedAnnualGrowthValue = value => ({type: EXPECTED_ANNUAL_GROWTH_SET_VALUE, value})
export const setExpectedAnnualGrowthValid = error => ({type: EXPECTED_ANNUAL_GROWTH_SET_VALID, error})

export const INFLATION_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'inflation';
export const INFLATION_SET_VALID = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALID + 'inflation';
export const setInflationValue = value => ({type: INFLATION_SET_VALUE, value})
export const setInflationValid = error => ({type: INFLATION_SET_VALID, error})

export const EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'expectedAnnualSalaryIncrease';
export const EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALID = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALID + 'expectedAnnualSalaryIncrease';
export const setExpectedAnnualSalaryIncreaseValue = value => ({type: EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALUE, value})
export const setExpectedAnnualSalaryIncreaseValid = error => ({type: EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALID, error})

export const REPLACEMENT_RATIO_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'replacementRatio';
export const REPLACEMENT_RATIO_SET_VALID = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALID + 'replacementRatio';
export const setReplacementRatioValue = value => ({type: REPLACEMENT_RATIO_SET_VALUE, value})
export const setReplacementRatioValid = error => ({type: REPLACEMENT_RATIO_SET_VALID, error})

// updating default values

export const RETIREMENT_AGE_ASSUMPTIONS_DEFUALT_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'retirementAgeAssumptionDefualt';
export const setRetirementAgeAssumptionsDefualtValue = value => ({type: RETIREMENT_AGE_ASSUMPTIONS_DEFUALT_SET_VALUE, value})

export const NUMBER_OF_RETIREMENT_YEARS_ASSUMPTIONS_DEFUALT_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'numberOfRetiremntYearsAssumptionDefualt';
export const setNumberOfRetiremntYearsAssumptionsDefualtValue = value => ({type: NUMBER_OF_RETIREMENT_YEARS_ASSUMPTIONS_DEFUALT_SET_VALUE, value})

export const EXPECTED_ANNUAL_GROWTH_ASSUMPTIONS_DEFUALT_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'expectedAnnualGrowthAssumptionDefualt';
export const setExpectedAnnualGrowthAssumptionsDefualtValue = value => ({type: EXPECTED_ANNUAL_GROWTH_ASSUMPTIONS_DEFUALT_SET_VALUE, value})

export const INFLATION_ASSUMPTIONS_DEFUALT_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'inflationAssumptionDefualt';
export const setInflationAssumptionsDefualtValue = value => ({type: INFLATION_ASSUMPTIONS_DEFUALT_SET_VALUE, value})

export const EXPECTED_ANNUAL_SALARY_INCREASE_ASSUMPTIONS_DEFUALT_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'expectedAnnualSalaryIncreaseAssumptionDefualt';
export const setExpectedAnnualSalaryIncreaseAssumptionsDefualtValue = value => ({type: EXPECTED_ANNUAL_SALARY_INCREASE_ASSUMPTIONS_DEFUALT_SET_VALUE, value})

export const REPLACEMENT_RATIO_ASSUMPTIONS_DEFUALT_SET_VALUE = RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE + 'replacementRatioAssumptionDefualt';
export const setReplacementRatioAssumptionsDefualtValue = value => ({type: REPLACEMENT_RATIO_ASSUMPTIONS_DEFUALT_SET_VALUE, value})

export const REINITIALIZE_STATE_RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL = 'retirementCalculator/retirementCalculatorForm/reinitialize';
export const reInitializeAssumptionsModel = () => ({ type: REINITIALIZE_STATE_RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL });
