export const APP_URL_PREFIX = '/travel-insurance';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QOUTE: 0,
    TRAVEL_DETAILS: 1,
    // BUILDING_DETAILS: 2,
    // DOCUMENTS: 3,
    PAYMENT: 2,
};

export const PAGES = [
	{
		URL: `${APP_URL_PREFIX}/get-quote`,
		URL_PART: 'get-quote',
		TITLE: 'Get Quote',
	},
	{
		URL: `${APP_URL_PREFIX}/travel-details`,
		URL_PART: 'personal-details',
		TITLE: 'Personal Details',
	},
	// {
	// 	URL: `${APP_URL_PREFIX}/building-details`,
	// 	URL_PART: 'building-details',
	// 	TITLE: 'Building Details',
	// },
	// {
	// 	URL: `${APP_URL_PREFIX}/documents`,
	// 	URL_PART: 'documents',
	// 	TITLE: 'Documents Upload',
	// },

	{
		URL: `${APP_URL_PREFIX}/payments`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	},
];


export const COVER_PLANS = {
    BUSINESS_COVER: { title: 'Business Cover', value: 'Business Cover' },
    HOLIDAY_COVER: { title: 'Holiday Cover', value: 'Holiday Cover' },
    STUDENT_COVER: { title: 'Student Cover', value: 'Student Cover' },
};


export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const TRAVEL_INSURANCE_URLS = {
	QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
	TRAVEL_DETAILS: PAGES[PAGE_INDICES.TRAVEL_DETAILS].URL,
	// BUILDING_DETAILS: PAGES[PAGE_INDICES.BUILDING_DETAILS].URL,
	// DOCUMENTS: PAGES[PAGE_INDICES.DOCUMENTS].URL,
	PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL
};
