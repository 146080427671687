import {
    validateAlphaNumericString,
    validateEmail,
    validateContactNumber,
    validateSingleSelection
} from  "@om/validation";
import { getSpeakToAnAdviserForm } from "../../../selectors/atRetirement/speakToAnAdviser";
import {
    setReasonsValid,
    setMoreHelpValid,
    setContactNumberValid,
    setEmailAddressValid,
    REASONS_SET_VALUE,
    MORE_HELP_SET_VALUE,
    CONTACT_NUMBER_SET_VALUE,
    EMAIL_ADDRESS_SET_VALUE,
} from "../../../actions/atRetirement/speakToAnAdviser";
import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const speakToAnAdviserValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;

        const reasonsValid = () => {
            const speakToAnAdviserForm = getSpeakToAnAdviserForm(store.getState());
            const reasons = speakToAnAdviserForm.reasons.value;

            if(reasons && reasons.length == 1 && reasons.includes(action.value))
                return validateSingleSelection(null, 'Option');

            return null;
        }

        switch (action.type) {
            case REASONS_SET_VALUE:
                error = reasonsValid(null);
                return setReasonsValid(error);
            case MORE_HELP_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 3, max: 254, subject: 'Message'});
                return setMoreHelpValid(error);
            case CONTACT_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value);
                return setContactNumberValid(error);
            case EMAIL_ADDRESS_SET_VALUE:
                error = validateEmail(action.value);
                return setEmailAddressValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
