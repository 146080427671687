import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    ATTITUDE_SET_VALID,
    ATTITUDE_SET_VALUE,
    SAVING_CATEGORY_SET_VALID,
    SAVING_CATEGORY_SET_VALUE,
    DESIRED_SET_VALID,
    DESIRED_SET_VALUE,
    LUMPSUM_SET_VALID,
    LUMPSUM_SET_VALUE,
    START_DATE_SET_VALID,
    START_DATE_SET_VALUE,
    DURATION_SET_VALID,
    DURATION_SET_VALUE,
    FORM_VALID_DIGITAL_SAVINGS_QUOTE_FORM,
    FORM_INVALID_DIGITAL_SAVINGS_QUOTE_FORM,
    API_PENDING_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM,
    API_SUCCESS_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM,
    API_FAILURE_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    DIGITAL_SAVINGS_SHARE_QUOTE_VALUE,
    DIGITAL_SAVINGS_SIDEBAR_OPEN_VALUE,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    FORM_INVALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM,
    FORM_VALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM,
    SET_SHARE_EMAIL_VALID,
    SET_SHARE_EMAIL_VALUE,
    SHARE_DIGITAL_SAVINGS_QUOTE_FAILURE,
    SHARE_DIGITAL_SAVINGS_QUOTE_SUCCESS,
    TOKEN_IS_EXPIRED,
    COLLECT_DIGITAL_SAVINGS_FORM_DATA,
    // SUBMIT_DIGITAL_SAVINGS_SUCCESS,
    // SUBMIT_DIGITAL_SAVINGS_FAILURE,
    DIGITAL_SAVINGS_PREMIUM_SUCCESS,
    DIGITAL_SAVINGS_ADD_COVER,
    IS_ON_ATTITUDE_SET_VALUE,
    FREQUENCY_SET_VALUE,
    FREQUENCY_SET_VALID,
} from '../../actions/digitalSavings/quote';

export const initDigitalSavingsCover = {
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    addedToCover: false,
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    isTokenExpired: false,
    premiumCalculationStatus: null,
    premiumCalculation: null,
    keCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Digital Savings Kenya',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    quoteShared: null,
    sidebarOpen: false,
    isOnAttitude: true,
    form: {
        attitude: { value: null, error: null, status: null },
        saving_category: { value: null, error: null, status: null },
        desired: { value: null, error: null, status: null },
        lumpsum: { value: null, error: null, status: null },
        startdate: { value: null, error: null, status: null },
        duration: { value: null, error: null, status: null },
        shareEmail: { value: null, error: null, status: null },
        frequency: { value: null, error: null, status: null },
    },
};

export default (state = initDigitalSavingsCover, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_DIGITAL_SAVINGS_QUOTE_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_DIGITAL_SAVINGS_QUOTE_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case DIGITAL_SAVINGS_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        case IS_ON_ATTITUDE_SET_VALUE:
            return {
                ...state,
                isOnAttitude: action.value,
            };

        case ATTITUDE_SET_VALUE:
            return updateFormField('attitude', (field) => ({
                ...field,
                value: action.value,
            }));

        case ATTITUDE_SET_VALID:
            return updateFormField('attitude', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SAVING_CATEGORY_SET_VALUE:
            return updateFormField('saving_category', (field) => ({
                ...field,
                value: action.value,
            }));
        case SAVING_CATEGORY_SET_VALID:
            return updateFormField('saving_category', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DESIRED_SET_VALUE:
            return updateFormField('desired', (field) => ({
                ...field,
                value: parseInt(action.value),
            }));

        case DESIRED_SET_VALID:
            return updateFormField('desired', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FREQUENCY_SET_VALUE:
            return updateFormField('frequency', (field) => ({
                ...field,
                value: action.value,
            }));

        case FREQUENCY_SET_VALID:
            return updateFormField('frequency', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LUMPSUM_SET_VALUE:
            return updateFormField('lumpsum', (field) => ({
                ...field,
                value: parseInt(action.value),
            }));

        case LUMPSUM_SET_VALID:
            return updateFormField('lumpsum', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case START_DATE_SET_VALUE:
            return updateFormField('startdate', (field) => ({
                ...field,
                value: action.value,
            }));

        case START_DATE_SET_VALID:
            return updateFormField('startdate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DURATION_SET_VALUE:
            return updateFormField('duration', (field) => ({
                ...field,
                value: action.value,
            }));

        case DURATION_SET_VALID:
            return updateFormField('duration', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        //    API Calls
        case API_PENDING_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_DIGITAL_SAVINGS_QUOTE_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_DIGITAL_SAVINGS_FORM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        // case SUBMIT_DIGITAL_SAVINGS_SUCCESS:
        //     return { ...state, premiumCalculationStatus: action.value };
        // case SUBMIT_DIGITAL_SAVINGS_FAILURE:
        //     return { ...state, premiumCalculationStatus: action.error };

        case DIGITAL_SAVINGS_PREMIUM_SUCCESS:
            return { ...state, premiumCalculation: action.value };
        case DIGITAL_SAVINGS_ADD_COVER:
            return { ...state, addedToCover: action.value };

        case FORM_VALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_DIGITAL_SAVINGS_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case DIGITAL_SAVINGS_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_DIGITAL_SAVINGS_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_DIGITAL_SAVINGS_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };
        case TOKEN_IS_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.value,
            };
        // Reset the state
        case REINITIALIZE:
            return initDigitalSavingsCover;
        default:
            return state;
    }
};
