import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    CALCULATE_INPUT_SET_VALUE,
    CALCULATE_INPUT_SET_VALID,
    FEES_FREQUENCY_INPUT_SET_VALUE,
    FEES_FREQUENCY_INPUT_SET_VALID,
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    CHILD_DOB_SET_VALUE,
    CHILD_DOB_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    SCHOOL_START_YEAR_SET_VALUE,
    SCHOOL_START_YEAR_SET_VALID,
    ACCUMALATION_PERIOD_SET_VALUE,
    ACCUMALATION_PERIOD_SET_VALID,
    LIFE_COVERED_SET_VALUE,
    LIFE_COVERED_SET_VALID,
    TARGET_FEES_PER_YEAR_SET_VALUE,
    TARGET_FEES_PER_YEAR_SET_VALID,
    TARGET_SAVINGS_PER_YEAR_SET_VALUE,
    TARGET_SAVINGS_PER_YEAR_SET_VALID,
    SCHOOL_YEAR_SET_VALUE,
    SCHOOL_YEAR_VALID,
    PREMIUM_FREQUENCY_SET_VALUE,
    PREMIUM_FREQUENCY_SET_VALID,
    FORM_VALID_ELIMIKA_QUOTE_TOOL,
    FORM_INVALID_ELIMIKA_QUOTE_TOOL,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    COLLECT_ELIMIKA_PREMIUM_DATA,
    CALCULATE_ELIMIKA_PREMIUM_DATA,
    API_PENDING_TRANSITION_ELIMIKA_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_ELIMIKA_QUOTE_TOOL,
    API_FAILURE_TRANSITION_ELIMIKA_QUOTE_TOOL,
} from "../../actions/roaQuoteTools/elimikaQuoteTool";

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    elimikaPremiumAmount: null,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Elimika Education Plan',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address']
    },
    form: {
        calculateInput: { value: '1', error: null, status: 'valid' },
        feesFrequencyInput: { value: '12', error: null, status: 'valid' },
        genderValue: { value: 'M', error: null, status: 'valid' },
        childDateOfBirth: { value: null, error: null, status: null },
        dob: { value: null, error: null, status: null },
        schoolStartYear: { value: null, error: null, status: null },
        lifeCoveredValue: { value: 'No', error: null, status: null },
        targetFeesPerYear: { value: null, error: null, status: null },
        targetSavingsPerYear: { value: null, error: null, status: null },
        schoolYearValue: { value: null, error: null, status: null },
        premiumFrequencyValue: { value: '12', error: null, status: null },
        accumulationPeriod: { value: null, error: null, status: null },
    },
};

export default (state = initialeState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    
    switch (action.type) {
        // Form Validation
        case FORM_VALID_ELIMIKA_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_ELIMIKA_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        // Api call
        case API_PENDING_TRANSITION_ELIMIKA_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_ELIMIKA_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_ELIMIKA_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        // Calculate input
        case CALCULATE_INPUT_SET_VALUE:
            return updateFormField('calculateInput', field => ({
                ...field,
                value: action.value,
            }));
        case CALCULATE_INPUT_SET_VALID:
            return updateFormField('calculateInput', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Gender value input
        case GENDER_SET_VALUE:
            return updateFormField('genderValue', field => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('genderValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Child date of birth
        case CHILD_DOB_SET_VALUE:
            return updateFormField('childDateOfBirth', field => ({
                ...field,
                value: action.value,
            }));
        case CHILD_DOB_SET_VALID:
            return updateFormField('childDateOfBirth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Date of birth
        case DOB_SET_VALUE:
            return updateFormField('dob', field => ({
                ...field,
                value: action.value,
            }));
        case DOB_SET_VALID:
            return updateFormField('dob', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Initials
        case SCHOOL_START_YEAR_SET_VALUE:
            return updateFormField('schoolStartYear', field => ({
                ...field,
                value: action.value,
            }));
        case SCHOOL_START_YEAR_SET_VALID:
            return updateFormField('schoolStartYear', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Accumalation period
        case ACCUMALATION_PERIOD_SET_VALUE:
            return updateFormField('accumulationPeriod', field => ({
                ...field,
                value: action.value,
            }));
        case ACCUMALATION_PERIOD_SET_VALID:
            return updateFormField('accumulationPeriod', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));   
        // life covered input
        case LIFE_COVERED_SET_VALUE:
            return updateFormField('lifeCoveredValue', field => ({
                ...field,
                value: action.value,
            }));
        case LIFE_COVERED_SET_VALID:
            return updateFormField('lifeCoveredValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // target fees per year input
        case TARGET_FEES_PER_YEAR_SET_VALUE:
            return updateFormField('targetFeesPerYear', field => ({
                ...field,
                value: action.value,
            }));
        case TARGET_FEES_PER_YEAR_SET_VALID:
            return updateFormField('targetFeesPerYear', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // target fees per year input
        case TARGET_SAVINGS_PER_YEAR_SET_VALUE:
            return updateFormField('targetSavingsPerYear', field => ({
                ...field,
                value: action.value,
            }));
        case TARGET_SAVINGS_PER_YEAR_SET_VALID:
            return updateFormField('targetSavingsPerYear', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
            // School fees per year input
        case SCHOOL_YEAR_SET_VALUE:
            return updateFormField('schoolYearValue', field => ({
                ...field,
                value: action.value,
            }));
        case SCHOOL_YEAR_VALID:
            return updateFormField('schoolYearValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // premium frequency input
        case PREMIUM_FREQUENCY_SET_VALUE:
            return updateFormField('premiumFrequencyValue', field => ({
                ...field,
                value: action.value,
            }));
        case PREMIUM_FREQUENCY_SET_VALID:
            return updateFormField('premiumFrequencyValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Select Form
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value
            };
        // Calculate fees frequency input
        case FEES_FREQUENCY_INPUT_SET_VALUE:
            return updateFormField('feesFrequencyInput', field => ({
                ...field,
                value: action.value,
            }));
        case FEES_FREQUENCY_INPUT_SET_VALID:
            return updateFormField('feesFrequencyInput', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case REINITIALIZE:
            return initialeState
        case CALCULATE_ELIMIKA_PREMIUM_DATA:
            return {
                ...state,
                elimikaPremiumAmount: action.value
            }
        case COLLECT_ELIMIKA_PREMIUM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        default:
            return state;
    }
}
