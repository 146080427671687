import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
import {
    API_FAILURE_TRANSITION_DOCTORS_PI_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    API_PENDING_TRANSITION_DOCTORS_PI_QUOTE_FORM,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITION_DOCTORS_PI_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    CALCULATE_DOCTORS_PI_PREMIUM_SUCCESS,
    COLLECT_DOCTORS_PI_QUOTE_FORM_DATA,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    DOCTORS_PI_QUOTE_ADD_COVER,
    DOCTORS_PI_SHARE_QUOTE_VALUE,
    DOCTORS_PI_SIDEBAR_OPEN_VALUE,
    FORM_INVALID_DOCTORS_PI_QUOTE_FORM,
    FORM_INVALID_DOCTORS_PI_QUOTE_SHARE_FORM,
    FORM_VALID_DOCTORS_PI_QUOTE_FORM,
    FORM_VALID_DOCTORS_PI_QUOTE_SHARE_FORM,
    REINITIALIZE,
    SET_LIMIT_INDEMNITY_VALID,
    SET_LIMIT_INDEMNITY_VALUE,
    SET_SELECTED_COVER_VALID,
    SET_SELECTED_COVER_VALUE,
    SET_SHARE_EMAIL_VALID,
    SET_SHARE_EMAIL_VALUE,
    SET_SPECIALTIES_VALID,
    SET_SPECIALTIES_VALUE,
    SHARE_DOCTORS_PI_QUOTE_FAILURE,
    SHARE_DOCTORS_PI_QUOTE_SUCCESS,
    SUBMIT_DOCTORS_PI_QUOTE_FAILURE,
    SUBMIT_DOCTORS_PI_QUOTE_SUCCESS,
    TOKEN_IS_EXPIRED,
} from '../../actions/doctorsPi/quote';

export const initQuote = {
    sidebarOpen: false,
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    quoteReferenceData: null,
    addedCover: false,
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    quoteShared: null,
    isTokenExpired: false,
    ugCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Doctors Pi Application',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    premiumCalculation: {
        value: null,
        status: null,
        error: null,
    },
    form: {
        limitIndemnity: { value: null, error: null, status: null },
        specialty: { value: null, error: null, status: null },
        selectedCover: { value: 'category_A' , error: null, status: 'valid' },
        shareEmail: { value: null , error: null, status: null },
    },
};

export default (state = initQuote, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_DOCTORS_PI_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_DOCTORS_PI_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };

        case FORM_VALID_DOCTORS_PI_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_DOCTORS_PI_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case SET_LIMIT_INDEMNITY_VALUE:
            return updateFormField('limitIndemnity', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_LIMIT_INDEMNITY_VALID:
            return updateFormField('limitIndemnity', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
    
        case SET_SPECIALTIES_VALUE:
            return updateFormField('specialty', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SPECIALTIES_VALID:
            return updateFormField('specialty', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_SELECTED_COVER_VALUE:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_COVER_VALID:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        // Valuers

        // Sidebar open
        case DOCTORS_PI_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        // Share modal
        case DOCTORS_PI_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_DOCTORS_PI_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_DOCTORS_PI_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case TOKEN_IS_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.value,
            };

        case API_PENDING_TRANSITION_DOCTORS_PI_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_DOCTORS_PI_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_DOCTORS_PI_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        // Submit user data
        case COLLECT_DOCTORS_PI_QUOTE_FORM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case SUBMIT_DOCTORS_PI_QUOTE_SUCCESS:
            return {
                ...state,
                quoteReferenceData: action.value,
            };
        case DOCTORS_PI_QUOTE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };
        case SUBMIT_DOCTORS_PI_QUOTE_FAILURE:
            return {
                ...state,
                quoteReferenceData: action.error,
            };

        case CALCULATE_DOCTORS_PI_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumCalculation: {
                    ...state.premiumCalculation,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        // Reset state
        case REINITIALIZE:
            return initQuote;
        default:
            return state;
    }
};
