import { getAccessToken } from '../../../selectors/healthInsuranceRW/payments';
import axios from 'axios';
import { setCompareTableValue } from '../../../actions/healthInsuranceRW/quote';
import { getProducts, getQuoteForm } from '../../../selectors/healthInsuranceRW/quote';

export const getComparePlansTableService = (store, next, action) => {
    (async () => {
        try {
            const resp = await getComparePlansTable(store.getState());
            store.dispatch(setCompareTableValue(resp.data.data));
        } catch (e) {
            console.error(e);
        }
    })();
    return next(action);
};


const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);
    const product = getProducts(state);

    const premiumCalculationPayload = {
        countryCode: 'RW',
        productId: product[0].product_id || 'NA',
        age: parseInt(quotation.age.value) || 'NA',
        inPatient: {
            inpatientLimitId: parseInt(quotation.inpatientCover.value.id) || 0,
            maternityLimitId: 7,
        },
        outPatient: {
            present: quotation.outpatientCover.value ? true : false,
            outpatientLimitId: parseInt(quotation.outpatientCover.value?.id) || 0,
            option: 1,
        },
        familyMembers: {
            children: {
                count: quotation.childrenAge.value?.length || 0,
            },
            spouse: {
                age: parseInt(quotation.spouseAge.value) || 0,
            },
        },
    };

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const getComparePlansTable = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/health-insurance-rw/compare-plans';
        const payload = {}
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};

