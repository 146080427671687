
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { DAYS_IN_MONTHS, mapToDateParts } from '../helpers/validation-date-helper.js';
import {config} from "../config/index.js";

export function validateDateOfBirthNextBirthDay(
	value,
	{ min, max, subject = 'Date of birth', required = true, pronoun = config.PRONOUN.YOUR, retainSubjectCase } = {}
) {
	if (!value && required) {
		return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
	}

	if (!value && !required) {
		return null;
	}
	const date = mapToDateParts(value);

	if (!date) {
		return OmValidationMessageHelper.DATE_FORMAT_ERROR;
	}

	if (!validateDate(date)) {
		return OmValidationMessageHelper.DATE_FORMAT_ERROR;
	}

	if (isFutureDate(date)) {
		return OmValidationMessageHelper.getDateOfBirthNotAllowedMsg(pronoun, retainSubjectCase);
	}

	if (isYearEarlierThan1850(date)) {
		return OmValidationMessageHelper.DATE_OF_BIRTH_NOT_ALLOWED_ERROR;
	}

	const age = getAge(date);

	if (min && age < min) {
		return OmValidationMessageHelper.getTooYoungMsg(min, pronoun, retainSubjectCase);
	}

	if (max && age >= max) {
		return OmValidationMessageHelper.getTooOldMsgNextBday(max, pronoun, retainSubjectCase);
	}

	return null;
}

export function getAge(dob) {
    const dobDate = new Date(dob.year, dob.month - 1, dob.day);
    var diff_ms = Date.now() - dobDate.getTime();
    var age_date = new Date(diff_ms);

    //getUTCFullYear: Creates a JavaScript Date instance that represents a single moment in time.
    //Date objects use a Unix Time Stamp, an integer value that is the number of
    //milliseconds since 1 January 1970 UTC. Hence subtraction of 1970
    return Math.abs(age_date.getUTCFullYear() - 1970);
}


function isYearEarlierThan1850(date) {
    return date.year < 1850;
}

function isFutureDate(dob) {
    const dobObj = new Date(dob.year, dob.month - 1, dob.day);
    const today = new Date();
    return today.getTime() - dobObj.getTime() < 0;
}

function validateDate(date) {
    const validDay = validateDay(date);
    const validMonth = validateMonth(date);
    const validYear = validateYear(date);

    if (!validDay || !validMonth || !validYear) {
        return false;
    }

    return true;
}

function validateDay(date) {
    const month = date.month;
    const day = date.day;
    const year = date.year;

    if (isNaN(day)) {
        return false;
    }

    // Change Feb days if it is leap year
    if (year % 4 === 0 && month === 2 && day === 29) {
        return true;
    }

    if (day > DAYS_IN_MONTHS[month - 1] || day <= 0) {
        return false;
    }

    return true;
}

function validateMonth(date) {
    const month = date.month;

    if (isNaN(month)) {
        return false;
    }

    return month <= 12 && month > 0;
}

function validateYear(date) {
    const year = date.year;

    if (isNaN(year)) {
        return false;
    }

    return year > 0;
}
