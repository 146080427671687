import { initAdvancedFundPicker } from './advancedFundPicker';
import { initBeneficiaries } from './beneficiaries';
import { initBeneficiariesSplit } from './beneficiariesSplit';
import { initFundData } from './fundData';
import { initFundSplit } from './fundSplit';
import { initInvestmentSetupContribution } from './investmentSetupContribution';
import { initInvestmentSetupInitial } from './investmentSetupInitial';
import { initPaymentDetails } from './paymentDetails';
import { initPersistedAt } from './persistedAt';
import { initPersonalDetails } from './personalDetails';
import { initReferenceData } from './referenceData';
import { initReview } from './review';
import { initRouting } from './routing';
import { initScrolling } from './scrolling';
import { initSelectAFund } from './selectAFund';
import { initSessionCamVariables } from './sessionCamVariables';
import { initAuxiliaryDetails } from './auxiliaryDetails';

export default () => {
    return {
        personalDetails: initPersonalDetails,
        fundData: initFundData,
        referenceData: initReferenceData,
        paymentDetails: initPaymentDetails,
        scrolling: initScrolling,
        routing: initRouting,
        beneficiaries: initBeneficiaries,
        beneficiariesSplit: initBeneficiariesSplit,
        advancedFundPicker: initAdvancedFundPicker,
        fundSplit: initFundSplit,
        investmentSetupContribution: initInvestmentSetupContribution,
        investmentSetupInitial: initInvestmentSetupInitial,
        persistedAt: initPersistedAt,
        review: initReview,
        selectAFund: initSelectAFund,
        sessionCamVariables: initSessionCamVariables,
        auxiliaryDetails: initAuxiliaryDetails,
    }
};