import { API_CALL_STATUS, FIELD_STATUS } from '../../../reducers/status';
import { IDENTITY_TYPE } from '../../../actions/customerComplaint/type';
import {
    COMPLAINT_FORM_SEND_INITIATE,
    sendComplaint,
    setCellphoneNumberValue,
    setComplaintDescriptionValue,
    setComplaintRelationValue,
    setComplaintTitleValue,
    setEmailValue,
    setFirstNameValue,
    setIdentityTypeValue,
    setIdNumberValue,
    setPassportCountryValue,
    setPassportNumberValue,
    setPolicyNumberValue,
    setProductTypeValue,
    setSurnameValue,
    setTitleValue,
    transitionComplaintForm,
    toggleSendMeCopy,
} from '../../../actions/customerComplaint/complaintArbitration';
import {
    getForm,
    getSendStatus,
} from '../../../selectors/customerComplaint/complaintArbitration';

export const complaintArbitrationRules = (store, next, action) => {
    const result = next(action);

    const complaintForm = getForm(store.getState());
    const complaintFormIsValid = validateCustomerComplaintForm(complaintForm);
    const sendComplaintStatus = getSendStatus(store.getState());

    if (action.type === COMPLAINT_FORM_SEND_INITIATE) {
        complaintFormIsValid && sendComplaintStatus !== API_CALL_STATUS.PENDING
            ? store.dispatch(sendComplaint())
            : setValuesForValidation(store, complaintForm);
    } else {
        store.dispatch(
            complaintFormIsValid
                ? transitionComplaintForm.formValid()
                : transitionComplaintForm.formInvalid()
        );
    }

    return result;
};

const validateCustomerComplaintForm = (formFields) => {
    return (
        formFields.title.status === FIELD_STATUS.VALID &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.surname.status === FIELD_STATUS.VALID &&
        formFields.cellphoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email.status === FIELD_STATUS.VALID &&
        ((formFields.identityType.status === FIELD_STATUS.VALID &&
            formFields.identityType.value === IDENTITY_TYPE.SA_ID &&
            formFields.idNumber.status === FIELD_STATUS.VALID) ||
            (formFields.identityType.status === FIELD_STATUS.VALID &&
                formFields.identityType.value === IDENTITY_TYPE.PASSPORT &&
                formFields.passportNumber.status === FIELD_STATUS.VALID &&
                formFields.passportCountry.status === FIELD_STATUS.VALID)) &&
        formFields.policyNumber.status === FIELD_STATUS.VALID &&
        formFields.productType.status === FIELD_STATUS.VALID &&
        formFields.complaintRelation.status === FIELD_STATUS.VALID &&
        formFields.complaintTitle.status === FIELD_STATUS.VALID &&
        formFields.complaintDescription.status === FIELD_STATUS.VALID
    );
};

const setValuesForValidation = (store, complaintForm) => {
    store.dispatch(setTitleValue(complaintForm.title.value));
    store.dispatch(setFirstNameValue(complaintForm.firstName.value));
    store.dispatch(setSurnameValue(complaintForm.surname.value));
    store.dispatch(setCellphoneNumberValue(complaintForm.cellphoneNumber.value));
    store.dispatch(setEmailValue(complaintForm.email.value));
    store.dispatch(setIdentityTypeValue(complaintForm.identityType.value));
    store.dispatch(setIdNumberValue(complaintForm.idNumber.value));
    store.dispatch(setPassportCountryValue(complaintForm.passportCountry.value));
    store.dispatch(setPassportNumberValue(complaintForm.passportNumber.value));
    store.dispatch(setPolicyNumberValue(complaintForm.policyNumber.value));
    store.dispatch(setProductTypeValue(complaintForm.productType.value));
    store.dispatch(setComplaintRelationValue(complaintForm.complaintRelation.value));
    store.dispatch(setComplaintTitleValue(complaintForm.complaintTitle.value));
    store.dispatch(setComplaintDescriptionValue(complaintForm.complaintDescription.value));
    store.dispatch(toggleSendMeCopy(complaintForm.sendMeCopy));
};
