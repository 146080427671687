import { config } from '@om/validation/src/config';
import {validationIsEqual} from "../status";

import {
    validateFirstName,
    validateSurname,
    validateCellphoneNumber,
    validateEmail,
    validateIdNumber,
    validatePassportNumber,
    validateSingleSelection,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateAlphaNumericString,
} from '@om/validation';

import {
    setTitleValid,
    setCellphoneNumberValid,
    setComplaintDescriptionValid,
    setComplaintRelationValid,
    setComplaintTitleValid,
    setEmailValid,
    setFirstNameValid,
    setIdentityTypeValid,
    setIdNumberValid,
    setPassportCountryValid,
    setPassportNumberValid,
    setPolicyNumberValid,
    setProductTypeValid,
    setSurnameValid,
    TITLE_SET_VALUE,
    FIRSTNAME_SET_VALUE,
    SURNAME_SET_VALUE,
    CELLPHONE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    IDENTITY_TYPE_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    PASSPORT_NUMBER_SET_VALUE,
    PASSPORT_COUNTRY_SET_VALUE,
    POLICY_NUMBER_SET_VALUE,
    PRODUCT_TYPE_SET_VALUE,
    COMPLAINT_RELATION_SET_VALUE,
    COMPLAINT_DESCRIPTION_SET_VALUE,
    COMPLAINT_TITLE_SET_VALUE
} from '../../../actions/customerComplaint/complaintArbitration';
import { getPassportNumber, getPassportRequired, getPassportCountry } from '../../../selectors/customerComplaint/complaintArbitration';

let prevValidationAction = null;

export const complaintArbitrationValidation = (store, next, action) => {
    const state = store.getState();
    const passportRequired = getPassportRequired(state);

    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, 'title');
                return setTitleValid(error);
            case FIRSTNAME_SET_VALUE:
                error = validateFirstName(action.value);
                return setFirstNameValid(error);
            case SURNAME_SET_VALUE:
                error = validateSurname(action.value);
                return setSurnameValid(error);
            case CELLPHONE_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(action.value, {countryCode: action.countryCode});
                return setCellphoneNumberValid(error);
            case EMAIL_SET_VALUE:
                error = validateEmail(action.value);
                return setEmailValid(error);
            case IDENTITY_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value, 'identity type');
                return setIdentityTypeValid(error);
            case ID_NUMBER_SET_VALUE:
                error = validateIdNumber(action.value);
                return setIdNumberValid(error);
            case PASSPORT_NUMBER_SET_VALUE:
                error = validatePassportNumber(action.value, {required: passportRequired});
                const passportCountry = getPassportCountry(state);
                if(passportRequired && !error) {
                    const secondError = validateSingleSelection(passportCountry.value, 'country');
                    store.dispatch(setPassportCountryValid(secondError));
                }
                return setPassportNumberValid(error);
            case PASSPORT_COUNTRY_SET_VALUE:
                const passportNumber = getPassportNumber(state);
                error = validateSingleSelection(action.value, 'country');
                const secondError = validatePassportNumber(passportNumber.value);
                store.dispatch(setPassportNumberValid(secondError));
                return setPassportCountryValid(error);
            case POLICY_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(
                    action.value,
                    {
                        min: 2,
                        max: 64,
                        subject: 'policy number',
                        required: false,
                        pronoun: config.PRONOUN.YOUR,
                        retainSubjectCase: false,
                    }
                );
                return setPolicyNumberValid(error);
            case PRODUCT_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value, 'product type');
                return setProductTypeValid(error);
            case COMPLAINT_RELATION_SET_VALUE:
                error = validateSingleSelection(
                    action.value,
                    'complaint relation'
                );
                return setComplaintRelationValid(error);
            case COMPLAINT_TITLE_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(
                    action.value,
                    {
                        min: 2,
                        max: 64,
                        subject: 'complaint title',
                        required: true,
                        pronoun: config.PRONOUN.YOUR,
                        retainSubjectCase: false,
                    }
                );
                return setComplaintTitleValid(error);
            case COMPLAINT_DESCRIPTION_SET_VALUE:
                error = validateAlphaNumericString(
                    action.value,
                    {
                        min: 2,
                        max: 254,
                        subject: 'complaint description',
                        required: true,
                        pronoun: config.PRONOUN.YOUR,
                        retainSubjectCase: false,
                    }
                );
                return setComplaintDescriptionValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
