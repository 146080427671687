import {FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from "../status";
import {OPEN_DRAWER} from "../../actions/atRetirement/types";

import {
    TOGGLE_SPEAK_TO_AN_ADVISER,
    FORM_VALID_TRANSITION_SPEAK_TO_AN_ADVISER,
    FORM_INVALID_TRANSITION_SPEAK_TO_AN_ADVISER,
    SPEAK_TO_AN_ADVISER_SEND,
    SPEAK_TO_AN_ADVISER_SEND_SUCCESS,
    SPEAK_TO_AN_ADVISER_SEND_FAILURE,
    REASONS_SET_VALUE,
    REASONS_SET_VALID,
    MORE_HELP_SET_VALUE,
    MORE_HELP_SET_VALID,
    CONTACT_NUMBER_SET_VALUE,
    CONTACT_NUMBER_SET_VALID,
    EMAIL_ADDRESS_SET_VALUE,
    EMAIL_ADDRESS_SET_VALID,
} from "../../actions/atRetirement/speakToAnAdviser";

export const initSpeakToAnAdviser = {
    openDrawer: null,
    formStatus: FORM_STATUS.INVALID,
    sendSpeakToAnAdviserStatus: API_CALL_STATUS.IDLE,
    form: {
        reasons: { value: null, error: null, status: null },
        moreHelp: { value: null, error: null, status: null },
        contactNumber: { value: null, error: null, status: null },
        emailAddress: { value: null, error: null, status: null },
    },
};

export default (state = initSpeakToAnAdviser, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    const getReasons = (reasonName) => {
        let reasons = state.form.reasons.value
                ? state.form.reasons.value
                : [];

        // if check box clicked and value exists then remove
        if(reasons.includes(reasonName)) {
            reasons = reasons.filter(reason => reason !== reasonName);
        } else {
            reasons.push(reasonName);
        }
        return reasons;
    }

    switch (action.type) {
        // Side drawer toggle
        case TOGGLE_SPEAK_TO_AN_ADVISER:
            return {
                ...state,
                openDrawer: !state.openDrawer ? OPEN_DRAWER.SPEAK_TO_AN_ADVISER : null,
            };
        // Form Validation
        case FORM_VALID_TRANSITION_SPEAK_TO_AN_ADVISER:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_SPEAK_TO_AN_ADVISER:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case SPEAK_TO_AN_ADVISER_SEND:
            return { ...state, sendSpeakToAnAdviserStatus: API_CALL_STATUS.PENDING };
        case SPEAK_TO_AN_ADVISER_SEND_SUCCESS:
            return { ...state, sendSpeakToAnAdviserStatus: API_CALL_STATUS.SUCCESS };
        case SPEAK_TO_AN_ADVISER_SEND_FAILURE:
            return { ...state, sendSpeakToAnAdviserStatus: API_CALL_STATUS.FAILURE };

        case REASONS_SET_VALUE:
            return updateFormField('reasons', field => ({
                ...field,
                value: getReasons(action.value),
            }));
        case REASONS_SET_VALID:
            return updateFormField('reasons', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MORE_HELP_SET_VALUE:
            return updateFormField('moreHelp', field => ({
                ...field,
                value: action.value,
            }));
        case MORE_HELP_SET_VALID:
            return updateFormField('moreHelp', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CONTACT_NUMBER_SET_VALUE:
            return updateFormField('contactNumber', field => ({
                ...field,
                value: action.value,
            }));
        case CONTACT_NUMBER_SET_VALID:
            return updateFormField('contactNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_ADDRESS_SET_VALUE:
            return updateFormField('emailAddress', field => ({
                ...field,
                value: action.value,
            }));
        case EMAIL_ADDRESS_SET_VALID:
            return updateFormField('emailAddress', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
