export const ELIMIKA_QUOTE_TOOL = 'roaQuoteTools/elimikaQuoteTool/';
export const ELIMIKA_QUOTE_TOOL_SET_VALUE = ELIMIKA_QUOTE_TOOL + 'setValue/';
export const ELIMIKA_QUOTE_TOOL_SET_VALID = ELIMIKA_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_ELIMIKA_QUOTE_TOOL = 'roaQuoteTools/elimikaQuoteTool/transition/formStateValid';
export const FORM_INVALID_ELIMIKA_QUOTE_TOOL = 'roaQuoteTools/elimikaQuoteTool/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/elimikaQuoteTool/state/reinitialize';

export const transitionElimikaTool = {
    formValid: () => ({ type: FORM_VALID_ELIMIKA_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_ELIMIKA_QUOTE_TOOL }),
};

export const CALCULATE_INPUT_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'calculateInput';
export const CALCULATE_INPUT_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'calculateInput';
export const FEES_FREQUENCY_INPUT_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'feesFrequencyInput';
export const FEES_FREQUENCY_INPUT_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'feesFrequencyInput';

export const GENDER_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'genderValue';
export const GENDER_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'genderValue';
export const CHILD_DOB_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'childDateOfBirth';
export const CHILD_DOB_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'childDateOfBirth';
export const DOB_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'dob';
export const DOB_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'dob';
export const SCHOOL_START_YEAR_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'schoolStartYear';
export const SCHOOL_START_YEAR_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'schoolStartYear';
export const ACCUMALATION_PERIOD_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'accumulationPeriod';
export const ACCUMALATION_PERIOD_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'accumulationPeriod';
export const LIFE_COVERED_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'lifeCoveredValue';
export const LIFE_COVERED_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'lifeCoveredValue';
export const TARGET_FEES_PER_YEAR_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'targetFeesPerYear';
export const TARGET_FEES_PER_YEAR_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'targetFeesPerYear';
export const TARGET_SAVINGS_PER_YEAR_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'targetSavingsPerYear';
export const TARGET_SAVINGS_PER_YEAR_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'targetSavingsPerYear';
export const SCHOOL_YEAR_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'schoolYearValue';
export const SCHOOL_YEAR_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'schoolYearValue';
export const PREMIUM_FREQUENCY_SET_VALUE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'premiumFrequencyValue';
export const PREMIUM_FREQUENCY_SET_VALID = ELIMIKA_QUOTE_TOOL_SET_VALID + 'premiumFrequencyValue';
export const WHICH_FORM_TO_DISPLAY = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const COLLECT_ELIMIKA_PREMIUM_DATA = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'collectElimikaPremiumData';
export const CALCULATE_ELIMIKA_PREMIUM_DATA = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_ELIMIKA_PREMIUM_DATA_FAILURE = ELIMIKA_QUOTE_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_ELIMIKA_QUOTE_TOOL = `${ELIMIKA_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_ELIMIKA_QUOTE_TOOL = `${ELIMIKA_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_ELIMIKA_QUOTE_TOOL = `${ELIMIKA_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionElimikaQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_ELIMIKA_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_ELIMIKA_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_ELIMIKA_QUOTE_TOOL }),
};

export const collectElimikaPremiumData = () => ({ type: COLLECT_ELIMIKA_PREMIUM_DATA });
export const calculateElimikaPremiumData = (value) => ({ type: CALCULATE_ELIMIKA_PREMIUM_DATA, value});
export const collectElimikaPremiumDataFailure = error => ({ type: CALCULATE_ELIMIKA_PREMIUM_DATA_FAILURE, error });

export const setCalculateInputValue = value => ({ type: CALCULATE_INPUT_SET_VALUE, value });
export const setCalculateInputValid = error => ({ type: CALCULATE_INPUT_SET_VALID, error });
export const setGenderValue = value => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = error => ({ type: GENDER_SET_VALID, error });
export const setChildDOBValue = value => ({ type: CHILD_DOB_SET_VALUE, value });
export const setChildDOBValid = error => ({ type: CHILD_DOB_SET_VALID, error });
export const setDOBValue = value => ({ type: DOB_SET_VALUE, value });
export const setDOBValid = error => ({ type: DOB_SET_VALID, error });
export const setSchoolStartYearValue = value => ({ type: SCHOOL_START_YEAR_SET_VALUE, value });
export const setSchoolStartYearValid = error => ({ type: SCHOOL_START_YEAR_SET_VALID, error });
export const setAccumalationPeriodValue = value => ({ type: ACCUMALATION_PERIOD_SET_VALUE, value });
export const setAccumalationPeriodValid = error => ({ type: ACCUMALATION_PERIOD_SET_VALID, error });
export const setLifeCoveredInputValue = value => ({ type: LIFE_COVERED_SET_VALUE, value });
export const setLifeCoveredInputValid = error => ({ type: LIFE_COVERED_SET_VALID, error });
export const setTargetFeesPerYearValue = value => ({ type: TARGET_FEES_PER_YEAR_SET_VALUE, value });
export const setTargetFeesPerYearValid = error => ({ type: TARGET_FEES_PER_YEAR_SET_VALID, error });
export const setTargetSavingsPerYearValue = value => ({ type: TARGET_SAVINGS_PER_YEAR_SET_VALUE, value });
export const setTargetSavingsPerYearValid = error => ({ type: TARGET_SAVINGS_PER_YEAR_SET_VALID, error });
export const setSchoolYearValue = value => ({ type: SCHOOL_YEAR_SET_VALUE, value });
export const setSchoolYearValid = error => ({ type: SCHOOL_YEAR_VALID, error });
export const setPremiumFrequencyInputValue = value => ({ type: PREMIUM_FREQUENCY_SET_VALUE, value });
export const setPremiumFrequencyInputValid = error => ({ type: PREMIUM_FREQUENCY_SET_VALID, error });
export const setWhichFormToDisplay = value => ({ type: WHICH_FORM_TO_DISPLAY, value });
export const setFeesFrequencyInputValue = value => ({ type: FEES_FREQUENCY_INPUT_SET_VALUE, value });
export const setFeesFrequencyInputValid = error => ({ type: FEES_FREQUENCY_INPUT_SET_VALID, error });
export const setToElimikaQuoteInitial = () => ({ type: REINITIALIZE });

