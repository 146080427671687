import {
    validateDate
} from "@om/validation";

import {
    DATE_SET_VALUE,
    setDateValid,
} from "../../../actions/priceTable/table";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const priceTableValidationRules = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case DATE_SET_VALUE:
                error = validateDate(action.value)
                return setDateValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
}
