import { fundSetValue } from "../../../actions/taxFreeSavingsAccount/selectAFund";
import { getSelectAFundForm } from "../../../selectors/taxFreeSavingsAccount/selectAFund";
import { deselectAllFunds } from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";

export const selectAFundRules = (store, next, action) => {
    const result = next(action);
    store.dispatch(deselectAllFunds());
    
    return result;
};

export const performSelectAFundValidation = (store) => {
    const form = getSelectAFundForm(store.getState());
    store.dispatch(fundSetValue(form.selectedFund.value));
};