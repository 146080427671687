export const TRAVEL_INSURANCE_AGENT_MODAL_DETAILS =
    'roaTravelInsurance/travelAgentModalDetails/';

export const TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE =
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS + 'setValue/';
export const TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID =
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS + 'setValid/';

export const MODAL_FORM_DISPLAY =
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE + 'modalFormDisplay';

export const REINITIALIZE =
    'roaTravelInsurance/travelAgentModalDetails/state/reinitialize';

export const FORM_VALID_TRAVEL_INSURANCE_AGENT_MODAL_FORM =
    'roaTravelInsurance/travelAgentModalDetails/transition/formValid';
export const FORM_INVALID_TRAVEL_INSURANCE_AGENT_MODAL_FORM =
    'roaTravelInsurance/travelAgentModalDetails/transition/formInValid';

export const transitionAgentModalForm = {
    formValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_AGENT_MODAL_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_AGENT_MODAL_FORM,
    }),
};

export const setTravelAgentModalDisplay = value => ({
    type: MODAL_FORM_DISPLAY,
    value,
});

export const setAgentModalToInitial = () => ({
    type: REINITIALIZE,
});

export const AGENT_NAME_SET_VALUE =
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE + 'name';
export const AGENT_NAME_SET_VALID =
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID + 'name';

export const AGENT_PHONE_NUMBER_SET_VALUE =
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE + 'phoneNumber';
export const AGENT_PHONE_NUMBER_SET_VALID =
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID + 'phoneNumber';

export const setNameValue = value => ({
    type: AGENT_NAME_SET_VALUE,
    value,
});
export const setNameValid = error => ({
    type: AGENT_NAME_SET_VALID,
    error,
});

export const setPhoneNumberValue = value => ({
    type: AGENT_PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = error => ({
    type: AGENT_PHONE_NUMBER_SET_VALID,
    error,
});
