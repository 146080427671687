export const ROA_TRAVEL_INSURANCE_PLANS =
    'roaTravelInsurance/roaTravelPlans/Plans/';
export const ROA_TRAVEL_PLANS_FORM_SET_VALUE =
    ROA_TRAVEL_INSURANCE_PLANS + 'setValue/';
export const ROA_TRAVEL_PLANS_FORM_SET_VALID =
    ROA_TRAVEL_INSURANCE_PLANS + 'setValid/';

export const FORM_VALID_TRAVEL_INSURANCE_PLANS_FORM =
    'roaTravelInsurance/Plans/transition/formValid';
export const FORM_INVALID_TRAVEL_INSURANCE_PLANS_FORM =
    'roaTravelInsurance/Plans/transition/formInValid';

export const WHICH_FORM_TO_DISPLAY =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'whichFormToDisplay';

export const PLAN_SET_VALUE = ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'planSelected';
export const PLAN_SET_VALID = ROA_TRAVEL_PLANS_FORM_SET_VALID + 'planSelected';

export const NATIONAL_ID_NUMBER_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'nationalIdNumber';
export const NATIONAL_ID_NUMBER_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'nationalIdNumber';

export const KRA_PIN_NUMBER_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'krapin';
export const KRA_PIN_NUMBER_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'krapin';

export const PASSPORT_NUMBER_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'passportNumber';
export const PASSPORT_NUMBER_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'passportNumber';

export const NATIONALITY_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'nationality';
export const NATIONALITY_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'nationality';

export const COUNTRY_OF_RESIDENCE_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'countryOfResidence';
export const COUNTRY_OF_RESIDENCE_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'countryOfResidence';

export const EMERGENCY_CONTACT_MODAL_DISPLAY_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'emergencyContactModal';

export const EMERGENCY_CONTACT_FIRST_NAME_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'emergencyContactFirstName';
export const EMERGENCY_CONTACT_FIRST_NAME_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'emergencyContactFirstName';

export const EMERGENCY_CONTACT_LAST_NAME_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'emergencyContactLastName';
export const EMERGENCY_CONTACT_LAST_NAME_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'emergencyContactLastName';

export const EMERGENCY_CONTACT_EMAIL_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'emergencyContactEmail';
export const EMERGENCY_CONTACT_EMAIL_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'emergencyContactEmail';

export const EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALUE =
    ROA_TRAVEL_PLANS_FORM_SET_VALUE + 'emergencyContactPhoneNumber';
export const EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALID =
    ROA_TRAVEL_PLANS_FORM_SET_VALID + 'emergencyContactPhoneNumber';

export const REINITIALIZE = ROA_TRAVEL_INSURANCE_PLANS + '/state/reinitialize';

export const transitionTravelInsurancePlansForm = {
    formValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_PLANS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_PLANS_FORM,
    }),
};

export const setWhichFormToDisplay = value => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});

export const setPlanSelectedValue = value => ({
    type: PLAN_SET_VALUE,
    value,
});
export const setPlanSelectedValid = error => ({
    type: PLAN_SET_VALID,
    error,
});

export const setNationalIdNumberValue = value => ({
    type: NATIONAL_ID_NUMBER_SET_VALUE,
    value,
});
export const setNationalIdNumberValid = error => ({
    type: NATIONAL_ID_NUMBER_SET_VALID,
    error,
});

export const setPassportNumberValue = value => ({
    type: PASSPORT_NUMBER_SET_VALUE,
    value,
});
export const setPassportNumberValid = error => ({
    type: PASSPORT_NUMBER_SET_VALID,
    error,
});

export const setKRAPinValue = value => ({
    type: KRA_PIN_NUMBER_SET_VALUE,
    value,
});
export const setKRAPinValid = error => ({
    type: KRA_PIN_NUMBER_SET_VALID,
    error,
});

export const setNationalityValue = value => ({
    type: NATIONALITY_SET_VALUE,
    value,
});
export const setNationalityValid = error => ({
    type: NATIONALITY_SET_VALID,
    error,
});

export const setCountryOfResidenceValue = value => ({
    type: COUNTRY_OF_RESIDENCE_SET_VALUE,
    value,
});
export const setCountryOfResidenceValid = error => ({
    type: COUNTRY_OF_RESIDENCE_SET_VALID,
    error,
});

export const setEmergencyContactModalDisplayValue = value => ({
    type: EMERGENCY_CONTACT_MODAL_DISPLAY_SET_VALUE,
    value,
});

export const setEmergencyContactFirstNameValue = value => ({
    type: EMERGENCY_CONTACT_FIRST_NAME_SET_VALUE,
    value,
});
export const setEmergencyContactFirstNameValid = error => ({
    type: EMERGENCY_CONTACT_FIRST_NAME_SET_VALID,
    error,
});

export const setEmergencyContactLastNameValue = value => ({
    type: EMERGENCY_CONTACT_LAST_NAME_SET_VALUE,
    value,
});
export const setEmergencyContactLastNameValid = error => ({
    type: EMERGENCY_CONTACT_LAST_NAME_SET_VALID,
    error,
});

export const setEmergencyContactEmailValue = value => ({
    type: EMERGENCY_CONTACT_EMAIL_SET_VALUE,
    value,
});
export const setEmergencyContactEmailValid = error => ({
    type: EMERGENCY_CONTACT_EMAIL_SET_VALID,
    error,
});

export const setEmergencyContactPhoneNumberValue = value => ({
    type: EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALUE,
    value,
});
export const setEmergencyContactPhoneNumberValid = error => ({
    type: EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALID,
    error,
});

export const setToInitial = () => ({ type: REINITIALIZE });
