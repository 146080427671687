import { createSelector } from 'reselect';
import { getDigitalSavingsState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getPersonalDetails = createSelector(
    getDigitalSavingsState,
    state => state.personalDetails
);

const makeGetPersonalDetailsField = fieldName => 
    createSelector(getPersonalDetails, personalDetails => personalDetails[fieldName]);

export const getPersonalDetailsForm = makeGetPersonalDetailsField('form');

export const getPersonalDetailsFormValid = createSelector(
    getPersonalDetails, 
    personalDetails => personalDetails.formStatus === FORM_STATUS.VALID,
)

const makeGetFieldForm = (fieldId) => createSelector(getPersonalDetailsForm, (form) => form[fieldId]);

export const getFirstNameValue = makeGetFieldForm('firstName');
export const getSurnameValue = makeGetFieldForm('surname');
export const getDobValue = makeGetFieldForm('dob');
export const getEmailValue = makeGetFieldForm('email');
export const getMobileNumberValue = makeGetFieldForm('mobileNumber');
export const getIdPassportValue = makeGetFieldForm('idPassport');
export const getKraPinNumberValue = makeGetFieldForm('kraPinNumber');
export const getGenderValue = makeGetFieldForm('gender');
export const getFullAddressValue = makeGetFieldForm('fullAddress');
export const getNationalityValue = makeGetFieldForm('nationality');
export const getSourceOfFundsValue = makeGetFieldForm('sourceOfFunds');
export const getOccupationValue = makeGetFieldForm('occupation');
