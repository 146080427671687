import {RISK_LEVEL_SET_VALUE} from "../../../actions/covidSelfAssessment/result"
import {getRiskLevel} from "../../../selectors/covidSelfAssessment/result"
import {simpleFieldTracking} from "../formTracking";

export const resultFieldTracking = (store, next, action) => {
  const result = next(action);
  const state = store.getState();
  const field = (() => {
      switch (action.type) {
          case RISK_LEVEL_SET_VALUE: return getRiskLevel(state);
          default: return null;
      }
  })();

  if (field) {
      simpleFieldTracking({action, field: {value: field}});
  }

  return result;
};
