import { validateNumber } from '@om/validation';

import { validationIsEqual } from '../status';
import {
    AVERAGE_AGE_OF_EMPLOYEES_SET_VALUE,
    NUMBER_OF_EMPLOYEES_SET_VALUE,
    setAverageAgeOfEmployeesValid,
    setNumberOfEmployeesValid,
    setTotalSumAssuredValid,
    TOTAL_SUM_ASSURED_SET_VALUE,
} from '../../../actions/roaQuoteTools/groupLifeQuoteTool';

let prevValidationAction = null;

export const groupLifeQuoteToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case NUMBER_OF_EMPLOYEES_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 1,
                    max: 10000,
                    subject: 'minimum number of employees',
                });
                return setNumberOfEmployeesValid(error);
            case AVERAGE_AGE_OF_EMPLOYEES_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 19,
                    max: 65,
                    subject: 'minimum age of employee',
                });
                return setAverageAgeOfEmployeesValid(error, action.paymentId);
            case TOTAL_SUM_ASSURED_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 1,
                    max: 3000000,
                    subject: 'minimum total sum assured',
                });
                return setTotalSumAssuredValid(error, action.paymentId);

            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
