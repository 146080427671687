import {createSelector} from "reselect";
import {getRaOptimal} from "./index";
import {BENEFICIARY_DRAWER, FORM_STATUS} from "../../reducers/status";

export const getBeneficiaries = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.beneficiaries
);

// Drawers
export const getOpenDrawer = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries.openDrawer,
);
export const getEditDrawerIsOpen = createSelector(
    getOpenDrawer,
    openDrawer => openDrawer === BENEFICIARY_DRAWER.EDIT,
);
export const getReadMoreDrawerIsOpen = createSelector(
    getOpenDrawer,
    openDrawer => openDrawer === BENEFICIARY_DRAWER.READ_MORE,
);

// Edit beneficiary
export const getEditBeneficiary = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries.editBeneficiary,
);
export const getEditBeneficiaryFormIsValid = createSelector(
    getEditBeneficiary,
    editBeneficiary => editBeneficiary.formStatus === FORM_STATUS.VALID,
);
export const getEditBeneficiaryForm = createSelector(
    getEditBeneficiary,
    editBeneficiary => editBeneficiary.form,
);

// Edit beneficiary form
const makeGetField = fieldId => createSelector(
    getEditBeneficiaryForm,
    editBeneficiaryForm => editBeneficiaryForm[fieldId]
);
export const getTitle = makeGetField('title');
export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getDateOfBirth = makeGetField('dateOfBirth');
export const getGender = makeGetField('gender');
export const getMobileNumber = makeGetField('mobileNumber');
export const getEmail = makeGetField('email');

export const getBeneficiaryEditId = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries.editId,
);

// Beneficiaries
export const getBeneficiaryList = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries.beneficiaries,
);

export const getShowBeneficiarySplitScreen = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries.showBeneficiarySplitScreen,
);

export const getBeneficiaryListLength = createSelector(
    getBeneficiaryList,
    list => list && list.length
);

export const getRemoveId = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries.removeId
);

export const getAddedMaxBeneficiaries = createSelector(
    getBeneficiaryListLength,
    (nBeneficiaries) => {
        const MAX_BENEFICIARIES = 10;
        return nBeneficiaries === MAX_BENEFICIARIES && 'maxAdded';
    }
);
