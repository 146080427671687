import {createSelector} from "reselect";
import {getDigiLifeLoanProtection} from "./index";
import { FORM_STATUS } from "../../reducers/status";


export const getDocuments =  createSelector(
    getDigiLifeLoanProtection,
    digiLifeLoanProtection => digiLifeLoanProtection.uploadDocuments
);
export const getUploadDocumentsValid = createSelector(
    getDocuments,
    (uploadDocuments) => uploadDocuments.formStatus === FORM_STATUS.VALID)
    
export const getDocumentsForm = createSelector(
    getDocuments,
    uploadDocuments => uploadDocuments.form,
);

export const getCopyOfIDDocument = createSelector(
    getDocumentsForm,
    form => form.certifiedCopyOfId,
);