import { transitionMakeAClaim } from '../../../actions/makeAClaim';
import { getMakeAClaimForm } from '../../../selectors/makeAClaim';
import { FORM_STATUS } from "../../../reducers/status";

export const makeAClaimRulesEngine = (store, next, action) => {
    const result = next(action);

    const makeAClaimForm = getMakeAClaimForm(
        store.getState(),
    );

    const makeAClaimValid = validateMakeAClaimFormDetails(
        makeAClaimForm
    );

    const formAction = makeAClaimValid
        ? transitionMakeAClaim.formValid()
        : transitionMakeAClaim.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateMakeAClaimFormDetails = (formFields) => {
    const {
        firstName,
        surname,
        emailAddress,
        phoneNumber,
        policyNumber,
        selectedClaimOrRequest,
        isAuthorizeMakeAClaim
    } = formFields;
    let isValid = false;
    if (selectedClaimOrRequest && selectedClaimOrRequest.value === "Claim") {
        const { selectedClaimCategories, selectedClaimTypes } = formFields;
        isValid = selectedClaimTypes && selectedClaimTypes.value && selectedClaimCategories && selectedClaimCategories.value;
    } else {
        const { selectedRequest } = formFields
        isValid = selectedRequest && selectedRequest.value;
    }

    return isValid && firstName && firstName.value && firstName.status === FORM_STATUS.VALID &&
        surname && surname.value && surname.status === FORM_STATUS.VALID &&
        emailAddress && emailAddress.value &&
        policyNumber && policyNumber.value && policyNumber.status === FORM_STATUS.VALID &&
        phoneNumber && phoneNumber.value && phoneNumber.status === FORM_STATUS.VALID && isAuthorizeMakeAClaim && isAuthorizeMakeAClaim.value;
};
