import axios from 'axios';
import { PRICE_GRAPH_FETCH_FUNDS_LIST, PRICE_GRAPH_FETCH_FUND_DATA, setFundsList, setFundData, apiTransitionPriceGraph} from '../../../actions/publicWeb/priceGraph';
import { dontFireApi, getEndDate, getSelectedFund, getStartDate } from '../../../selectors/publicWeb/priceGraph';


const fetchFundsList = async store => {
    const state = store.getState();
    if(!dontFireApi(state)) {
        try {
            let date = "30/06/2023" // new Date().toLocaleDateString('en-ZA').split('/').reverse().join('/');
            let response = await axios.get(
                "/om-api/price-graph/get-price-graph-funds?date=" + date
            );
            store.dispatch(setFundsList(response.data));
        } catch (error) {
            console.log('problem fetching fund list');
            store.dispatch(apiTransitionPriceGraph.failure());
        }
    }    
}

const fetchFundData = async store => {
    const state = store.getState();
    if(!dontFireApi(state)) {
        store.dispatch(apiTransitionPriceGraph.pending());
        try {
            let start = getStartDate(state).value;
            let end = getEndDate(state).value;
            let fundId = getSelectedFund(state).value;
            let response 
            if(start && end ){
                response = await axios.get(
                    '/om-api/price-graph/get-price-graph?startDate='+ start +'&endDate=' + end + '&fundId=' + fundId
                );
            }
            else {
                response = await axios.get(
                    '/om-api/price-graph/get-price-graph?fundId=' + fundId
                );
            }
            store.dispatch(apiTransitionPriceGraph.success());
            store.dispatch(setFundData(response.data));
        }
        catch (error) {
            console.log('problem fetching fund data');
            store.dispatch(apiTransitionPriceGraph.failure());

        }
    } 
}

export const priceGraphService = (store, next, action) => {
    const result = next(action);

    (async () => {
        if (action.type === PRICE_GRAPH_FETCH_FUNDS_LIST) {
            fetchFundsList(store);
        }
        if(action.type === PRICE_GRAPH_FETCH_FUND_DATA) {
            fetchFundData(store);
        }
    })();

    return result;
};
