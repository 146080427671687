import {
    FETCH_FUND_DECLARATION_FUNDS,
    FETCH_FUND_DECLARATION_NAMES,
} from '../../../actions/fundDeclarations/fundDeclarations';
import { fundDataService } from './fundDeclaration';

export const fundDeclarationService = (store, next, action) => {
    if (
        action.type === FETCH_FUND_DECLARATION_FUNDS ||
        action.type === FETCH_FUND_DECLARATION_NAMES
    ) {
        return fundDataService(store, next, action);
    }

    return null;
};
