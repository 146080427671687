import { validateNumber } from '@om/validation';

import {
    CHILDREN_SET_VALUE,
    setChildrenValid,
} from '../../../actions/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const afyaImaraGertrudeJuniorQuoteToolValidation = (
    store,
    next,
    action,
) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case CHILDREN_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 1,
                    max: 6,
                    subject: 'number',
                });
                return setChildrenValid(error);
            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
