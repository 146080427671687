import { transitionAgentModalForm } from '../../../actions/roaTravelInsurance/travelAgentModal';
import { getTravelAgentForm } from '../../../selectors/roaTravelInsurance/travelAgentModal';

import { FIELD_STATUS } from '../../../reducers/status';

export const travelAgentModaleFormRules = (store, next, action) => {
    const result = next(action);
    const travelAgentModalForm = getTravelAgentForm(store.getState());
    const agentModalForm = validatetravelAgentModaleForm(travelAgentModalForm);
    const formAction = agentModalForm
        ? transitionAgentModalForm.formValid()
        : transitionAgentModalForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatetravelAgentModaleForm = formFields => {
    return (
        formFields.name &&
        formFields.name.status === FIELD_STATUS.VALID &&
        formFields.agentPhoneNumber &&
        formFields.agentPhoneNumber.status === FIELD_STATUS.VALID
    );
};
