import {
    setIsReplacementPolicyValid,
    IS_REPLACEMENT_POLICY_SET_VALUE
} from "../../../actions/easiplusFuneralPlan/replacementPolicy";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const replacementPolicyValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case IS_REPLACEMENT_POLICY_SET_VALUE:
                // This is a radio button, therefore if fired, it will be valid
                return store.dispatch(setIsReplacementPolicyValid());

            // TODO Add validation
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
