import { createSelector } from 'reselect';
import { getRetirementCalculator } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getRetiremntCalculatorAssumptionsModel = createSelector(
	getRetirementCalculator,
	(retirementCalculators) => retirementCalculators.retirementAssumptionsModel
);

export const getRetiremntCalculatorAssumptionsModelForm = createSelector(
    getRetiremntCalculatorAssumptionsModel,
    retirementCalculatorAssumptionModel => {
        return retirementCalculatorAssumptionModel.form}
);

export const getRetiremntCalculatorAssumptionsModelFormValid = createSelector(
	getRetiremntCalculatorAssumptionsModel,
	(retirementCalculatorAssumptionModel) => retirementCalculatorAssumptionModel.formStatus === FORM_STATUS.VALID
);

export const getRetiremntCalculatorAssumptionsModelDefaultValues = createSelector(
	getRetiremntCalculatorAssumptionsModel,
	(retirementCalculatorAssumptionModel) => retirementCalculatorAssumptionModel.assumptionDefaults
);



const makeGetField = (fieldId) => createSelector(
    getRetiremntCalculatorAssumptionsModelForm,
    (calculatorAssumptionsModel) => calculatorAssumptionsModel[fieldId]
);

export const getRetirementAge = makeGetField('retirementAge');
export const getNumberOfRetirementYears = makeGetField('numberOfRetirementYears');
export const getExpectedAnnualGrowth = makeGetField('expectedAnnualGrowth');
export const getInflation = makeGetField('inflation');
export const getExpectedAnnualSalaryIncrease = makeGetField('expectedAnnualSalaryIncrease');
export const getReplacementRatio = makeGetField('replacementRatio');

