import { createSelector } from "reselect";
import { getCustomerVerificationService } from "./index";
import { CVS_MODALS } from "../../actions/customerVerificationService/types";
import { getIDVerification } from "./idVerification";
import { getServiceProvider } from "./consent";

export const getKnowledgeBasedAuthentication = createSelector(
    getCustomerVerificationService,
    cvs => cvs.knowledgeBasedAuthentication
);

export const getReference = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.reference
);

export const getIdentityToken = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.identityToken
);

export const getActiveQuestion = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.questions ? kba.questions[kba.activeQuestion - 1].text : ''
);

export const getActiveQuestionNumber = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.activeQuestion
);

export const getTotalNumberOfQuestions = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.questions ? kba.questions.length : 0
);

export const getActiveOptions = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.questions ? kba.questions[kba.activeQuestion - 1].possibleAnswers : []
);

export const getAnswer = createSelector(
    getKnowledgeBasedAuthentication,
    kba => {
        const answerKey = 'answer_' + kba.activeQuestion;
        return kba.form[answerKey];
    }
);

export const getIsLoadingModalOpen = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.modal === CVS_MODALS.LOADING
);

export const getIsVerificationSuccessful = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.modal === CVS_MODALS.VERIFICATION_SUCCESS
);

export const getIsVerificationFailed = createSelector(
    getKnowledgeBasedAuthentication,
    kba => (kba.modal === CVS_MODALS.VERIFICATION_FAILED || kba.modal === CVS_MODALS.INITIATION_FAILED)
);

export const getAllQuestionsAnswered = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.activeQuestion === 5
);

export const getSubmissionCompleted = createSelector(
    getIsVerificationSuccessful,
    getIsVerificationFailed,
    (success, failed) => success || failed
);

export const getAnswersArray = createSelector(
    getKnowledgeBasedAuthentication,
    kba => {
        const formKeys = Object.keys(kba.form);
        const answerKeys = formKeys.filter(key => key.startsWith('answer_'));
    
        return answerKeys.map(answerKey => {
            return {
                questionId: kba.form[answerKey].questionId,
                answerId: kba.form[answerKey].value
            }
        });
    }
);

export const getTimeRemaining = createSelector(
    getKnowledgeBasedAuthentication,
    kba => kba.form.timeRemaining
);

export const getScoreKbaPayload = createSelector(
    getIDVerification,
    getAnswersArray,
    getServiceProvider,
    (idVerification, answers, serviceProvider) => {
        return {
            identityNumber: idVerification.form.idNumber.value,
            serviceProvider: serviceProvider,
            identityToken: idVerification.identityToken,
            reference: idVerification.referenceGuid,
            answers: answers
        };
    }
);

export const getInitiateKbaPayload = createSelector(
    getIDVerification,
    getServiceProvider,
    (idVerification, serviceProvider) => {
        return {
            identityNumber: idVerification.form.idNumber.value,
            serviceProvider: serviceProvider,
            identityToken: idVerification.identityToken,
            reference: idVerification.referenceGuid,
        };
    }
);
