const PAYMENT_DETAILS_TRANSITION = 'taxFreeSavingsAccount/paymentDetails/transition/';

// Transitions
export const TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS = PAYMENT_DETAILS_TRANSITION + 'togglePaymentAuthorization';
export const PAYMENT_AUTHORIZATION_INVALID_TRANSITION_PAYMENT_DETAILS = PAYMENT_DETAILS_TRANSITION + 'paymentAuthorizationInvalid';
export const RESET_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS = PAYMENT_DETAILS_TRANSITION + 'resetPaymentAuthorization';
export const togglePaymentAuthorization = () => ({ type: TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS });
export const paymentAuthorizationInvalid = () => ({ type: PAYMENT_AUTHORIZATION_INVALID_TRANSITION_PAYMENT_DETAILS });
export const paymentAuthorizationReset = () => ({ type: RESET_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS });

export const TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS = PAYMENT_DETAILS_TRANSITION + 'skipMonth';
export const toggleSkipMonth = () => ({ type: TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS });

export const TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS = 'taxFreeSavingsAccount/paymentDetails/transition/toggleIncludeAvs';
export const toggleIncludeAvs = () => ({ type: TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS });

export const TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS = 'taxFreeSavingsAccount/paymentDetails/transition/toggleIncludeCdv';
export const toggleIncludeCdv = () => ({ type: TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS });

export const SET_DISPLAY_LEGAL_SECTION = PAYMENT_DETAILS_TRANSITION + 'displayLegalSection';
export const setDisplayLegalSection = () => ({ type: SET_DISPLAY_LEGAL_SECTION });

// Events
export const PAYMENT_DETAILS_SET_VALUE = 'taxFreeSavingsAccount/paymentDetails/setValue/';
export const PAYMENT_DETAILS_SET_VALID = 'taxFreeSavingsAccount/paymentDetails/setValid/';
export const PAYMENT_DETAILS_RESET_VALUE = 'taxFreeSavingsAccount/paymentDetails/resetValue/';

//Account validation
export const ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS=  PAYMENT_DETAILS_SET_VALUE + 'accountValidation/status';
export const setAccountValidationStatus = (value) => ({ type: ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS, value });

export const ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS = PAYMENT_DETAILS_SET_VALUE + 'accountValidation/reset';
export const setAccountValidationReset = ( ) => ({ type: ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS });

export const BANK_NAME_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'bankName';
export const BANK_NAME_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'bankName';
export const ACCOUNT_NUMBER_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountNumber';
export const ACCOUNT_NUMBER_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'accountNumber';
export const ACCOUNT_TYPE_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountType';
export const ACCOUNT_TYPE_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'accountType';
export const DEBIT_ORDER_DAY_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'debitOrderDay';
export const DEBIT_ORDER_DAY_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'debitOrderDay';
export const MONTH_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'month';
export const MONTH_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'month';
export const MONTH_TO_SKIP_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'monthToSkip';
export const MONTH_TO_SKIP_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'monthToSkip';
export const MONTH_TO_SKIP_RESET_VALUE = PAYMENT_DETAILS_RESET_VALUE + 'monthToSkip';
export const PAYMENT_AUTHORIZATION_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'paymentAuthorization';
export const LUMP_SUM_DAY_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'lumpSumDay';
export const LUMP_SUM_DAY_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'lumpSumDay';
export const LUMP_SUM_MONTH_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'lumpSumMonth';
export const LUMP_SUM_MONTH_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'lumpSumMonth';

export const setBankNameValue = value => ({ type: BANK_NAME_SET_VALUE, value });
export const setBankNameValid = error => ({ type: BANK_NAME_SET_VALID, error });
export const setAccountNumberValue = value => ({ type: ACCOUNT_NUMBER_SET_VALUE, value });
export const setAccountNumberValid = error => ({ type: ACCOUNT_NUMBER_SET_VALID, error });
export const setAccountTypeValue = value => ({ type: ACCOUNT_TYPE_SET_VALUE, value });
export const setAccountTypeValid = error => ({ type: ACCOUNT_TYPE_SET_VALID, error });
export const setDebitOrderDayValue = value => ({ type: DEBIT_ORDER_DAY_SET_VALUE, value });
export const setDebitOrderDayValid = error => ({ type: DEBIT_ORDER_DAY_SET_VALID, error });
export const setMonthValue = value => ({ type: MONTH_SET_VALUE, value });
export const setMonthValid = error => ({ type: MONTH_SET_VALID, error });
export const setMonthToSkipValue = value => ({ type: MONTH_TO_SKIP_SET_VALUE, value });
export const setMonthToSkipValid = error => ({ type: MONTH_TO_SKIP_SET_VALID, error });
export const resetMonthToSkipValue = () => ({ type: MONTH_TO_SKIP_RESET_VALUE });
export const setPaymentAuthorizationValid = error => ({ type: PAYMENT_AUTHORIZATION_SET_VALID, error });
export const setLumpSumDayValue = value => ({ type: LUMP_SUM_DAY_SET_VALUE, value });
export const setLumpSumDayValid = error => ({ type: LUMP_SUM_DAY_SET_VALID, error });
export const setLumpSumMonthValue = value => ({ type: LUMP_SUM_MONTH_SET_VALUE, value });
export const setLumpSumMonthValid = error => ({ type: LUMP_SUM_MONTH_SET_VALID, error });

// Legal details
export const SOURCE_OF_INCOME_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'sourceOfIncome';
export const SOURCE_OF_INCOME_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'sourceOfIncome';
export const SOURCE_OF_INCOME_OTHER_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'sourceOfIncomeOther';
export const SOURCE_OF_INCOME_OTHER_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'sourceOfIncomeOther';
export const SOURCE_OF_INCOME_OTHER_RESET_VALUE = PAYMENT_DETAILS_RESET_VALUE + 'sourceOfIncomeOther';
export const SOURCE_OF_FUNDS_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'sourceOfFunds';
export const SOURCE_OF_FUNDS_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'sourceOfFunds';
export const EMPLOYMENT_POSITION_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'employmentPosition';
export const EMPLOYMENT_POSITION_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'employmentPosition';
export const EMPLOYMENT_INDUSTRY_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'employmentIndustry';
export const EMPLOYMENT_INDUSTRY_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'employmentIndustry';
export const EMPLOYMENT_INDUSTRY_RESET_VALUE = PAYMENT_DETAILS_RESET_VALUE + 'employmentIndustry';
export const EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'employmentIndustryOther';
export const EMPLOYMENT_INDUSTRY_OTHER_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'employmentIndustryOther';
export const EMPLOYMENT_INDUSTRY_OTHER_RESET_VALUE = PAYMENT_DETAILS_RESET_VALUE + 'employmentIndustryOther';

export const setSourceOfIncomeValue = value => ({ type: SOURCE_OF_INCOME_SET_VALUE, value });
export const setSourceOfIncomeValid = error => ({ type: SOURCE_OF_INCOME_SET_VALID, error });
export const setSourceOfIncomeOtherValue = value => ({ type: SOURCE_OF_INCOME_OTHER_SET_VALUE, value });
export const setSourceOfIncomeOtherValid = error => ({ type: SOURCE_OF_INCOME_OTHER_SET_VALID, error });
export const resetSourceOfIncomeOtherValue = () => ({ type: SOURCE_OF_INCOME_OTHER_RESET_VALUE });
export const setSourceOfFundsValue = value => ({ type: SOURCE_OF_FUNDS_SET_VALUE, value });
export const setSourceOfFundsValid = error => ({ type: SOURCE_OF_FUNDS_SET_VALID, error });
export const setEmploymentPositionValue = value => ({ type: EMPLOYMENT_POSITION_SET_VALUE, value });
export const setEmploymentPositionValid = error => ({ type: EMPLOYMENT_POSITION_SET_VALID, error });
export const setEmploymentIndustryValue = value => ({ type: EMPLOYMENT_INDUSTRY_SET_VALUE, value });
export const setEmploymentIndustryValid = error => ({ type: EMPLOYMENT_INDUSTRY_SET_VALID, error });
export const resetEmploymentIndustryValue = () => ({ type: EMPLOYMENT_INDUSTRY_RESET_VALUE });
export const setEmploymentIndustryOtherValue = value => ({ type: EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE, value });
export const setEmploymentIndustryOtherValid = error => ({ type: EMPLOYMENT_INDUSTRY_OTHER_SET_VALID, error });
export const resetEmploymentIndustryOtherValue = () => ({ type: EMPLOYMENT_INDUSTRY_OTHER_RESET_VALUE });

//File Upload
export const IDENTITY_DOCUMENT_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'identityDocument';
export const IDENTITY_DOCUMENT_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'identityDocument';
export const IDENTITY_DOCUMENT_REMOVE_VALUE = PAYMENT_DETAILS_SET_VALUE + 'removeIdentityDocument';
export const BIRTH_CERTIFICATE_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'birthCertificate';
export const BIRTH_CERTIFICATE_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'birthCertificate';
export const BIRTH_CERTIFICATE_REMOVE_VALUE = PAYMENT_DETAILS_SET_VALUE + 'removeBirthCertificate';
export const COURT_MANDATE_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'courtMandate';
export const COURT_MANDATE_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'courtMandate';
export const COURT_MANDATE_REMOVE_VALUE = PAYMENT_DETAILS_SET_VALUE + 'removeCourtMandate';

export const setIdentityDocumentValue = value => ({ type: IDENTITY_DOCUMENT_SET_VALUE, value });
export const setIdentityDocumentValid = error => ({ type: IDENTITY_DOCUMENT_SET_VALID, error });
export const removeIdentityDocumentValue = () => ({ type: IDENTITY_DOCUMENT_REMOVE_VALUE });
export const setBirthCertificateValue = value => ({ type: BIRTH_CERTIFICATE_SET_VALUE, value });
export const setBirthCertificateValid = error => ({ type: BIRTH_CERTIFICATE_SET_VALID, error });
export const removeBirthCertificateValue = () => ({ type: BIRTH_CERTIFICATE_REMOVE_VALUE });
export const setCourtMandateValue = value => ({ type: COURT_MANDATE_SET_VALUE, value });
export const setCourtMandateValid = error => ({ type: COURT_MANDATE_SET_VALID, error });
export const removeCourtMandateValue = () => ({ type: COURT_MANDATE_REMOVE_VALUE });

export const RESET_PAYMENT_DETAILS = PAYMENT_DETAILS_SET_VALUE + 'resetState';
export const resetPaymentDetails = () => ({ type: RESET_PAYMENT_DETAILS });