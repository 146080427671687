import { getIsVehicleTypesApiStatus } from '../../../selectors/motorPrivateRW/quote';
import { apiTransitionVehicleTypes, setVehicleTypesListValid, setVehicleTypesListValue } from '../../../actions/motorPrivateRW/quote';
import { getAccessToken } from '../../../selectors/motorPrivateRW/payments';
import axios from 'axios';

export const getVehicleTypesListService = (store, next, action) => {
    (async () => {
        if (!getIsVehicleTypesApiStatus(store.getState())) {
            store.dispatch(apiTransitionVehicleTypes.pending());
            try {
                const resp = await getVehicleTypes(store.getState());
                store.dispatch(setVehicleTypesListValue(resp.data));
                store.dispatch(apiTransitionVehicleTypes.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionVehicleTypes.failure());
                store.dispatch(setVehicleTypesListValid(e));
            }
        }
    })();
    return next(action);
};

const getVehicleTypes = async (state) => {
    const loginData = getAccessToken(state);

    try {
        const url = '/om-api/rw-motor-insurance/vehicle-types';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData?.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
