import {createSelector} from "reselect";
import {getTaxFreeSavingsAccount} from "./index";
import {FORM_STATUS, TOGGLE_STATUS} from "../../reducers/status";

export const getReview = createSelector(getTaxFreeSavingsAccount, tfsa => tfsa.review);

export const getSubmissionStatus = createSelector(
    getReview,
    confirmDetails => confirmDetails.submissionStatus,
);

export const getReferenceNumber = createSelector(
    getReview,
    confirmDetails => confirmDetails && confirmDetails.referenceNumber,
);

export const getTermsAndConditionsSideDrawerOpen = createSelector(
    getReview,
    confirmDetails => confirmDetails.termsAndConditionsSideDrawer === TOGGLE_STATUS.YES,
);

export const getTermsAndConditionsError = createSelector(
    getReview,
    confirmDetails => confirmDetails.termsAndConditionsValidationMessage,
);

export const getTermsAndConditionsValid = createSelector(
    getReview,
    confirmDetails => confirmDetails.termsAndConditions === TOGGLE_STATUS.YES,
);

export const getTermsAndConditionsInvalid = createSelector(
    getReview,
    confirmDetails => confirmDetails.termsAndConditions === FORM_STATUS.INVALID
);

export const getConfirmDetailsValid = createSelector(
    getTermsAndConditionsValid,
    (termsAndConditionsValue) => termsAndConditionsValue
);
