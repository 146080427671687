import { validateNumber, validatePercent, validateDate } from "@om/validation";
import {validationIsEqual} from "../status";
import { todayAsValidationParam } from '../date';
import {
    FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALUE,
    setExpectedEndDateValid,
    FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALUE,
    setCurrentSavingsValid,
    EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE,
    setExpectedMonthlyContributionValid,
    FUTURE_SAVINGS_ANNUAL_SAVINGS_INCREASE_SET_VALUE,
    setAnnualSavingsIncreaseValid,
    FUTURE_SAVINGS_EXPECTED_SAVINGS_GROWTH_SET_VALUE,
    setExpectedSavingsGrowthValid,
} from '../../../actions/savingsCalculator/theFutureValueOfYourSavings';

import {OmValidationDateHelper} from "@om/validation";

let prevValidationAction = null;

export const theFutureValueOfYourSavingsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            
            case FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALUE:
                error = validateDate(action.value, {subject: 'expected end date', min: todayAsValidationParam(), max: getDateInTwentyYears()});
                return setExpectedEndDateValid(error);

            case FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALUE:
                error = validateNumber(action.value, {subject: "current savings", min: 0, max: 10000000,decimalPoints: 2});
                return setCurrentSavingsValid(error);

            case EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE:
                error = validateNumber(action.value, {subject: 'expected monthly contribution', max: 10000000, decimalPoints: 2});
                return setExpectedMonthlyContributionValid(error);
                
            default:
                return null;
        }
    })();

    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};

const getDateInTwentyYears = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    return `${day}/${month + 1}/${year + 20}`
}