import { createSelector } from "reselect";
import { getEasiplusFuneralPlan } from "./index";
import { FORM_STATUS } from "../../reducers/status";
import { getActivePageIndex } from "./routing"
import { PAGE_INDICES } from "../../actions/easiplusFuneralPlan/types"

export const getReplacementPolicy = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan.replacementPolicy
);

export const getReplacementPolicyFormIsValid = createSelector(
    getReplacementPolicy,
    replacementPolicy => replacementPolicy.formStatus === FORM_STATUS.VALID,
);

export const getReplacementPolicyForm = createSelector(
    getReplacementPolicy,
    replacementPolicy => replacementPolicy.form,
);

const makeGetField = fieldId => createSelector(
    getReplacementPolicyForm,
    replacementPolicyForm => replacementPolicyForm[fieldId]
);

export const getIsReplacementPolicy = makeGetField('isReplacementPolicy');

export const getReplacementPolicyTrue = createSelector(
    getReplacementPolicy,
    replacementPolicy => replacementPolicy.form.isReplacementPolicy.value === true,
);

export const getIsReplacmentPolicyValue = createSelector(
    getIsReplacementPolicy,
    (isReplacementPolicy) => {
        if (isReplacementPolicy.value != null)
            return isReplacementPolicy.value ? 'true' : 'false';

        return null;
    }
);

// used by the application wizard footer component
export const getReplacementPolicyDisabled = createSelector(
    getActivePageIndex,
    getReplacementPolicyTrue,
    (activePageIndex, replacementPolicyTrue) => {
        return (replacementPolicyTrue && activePageIndex === PAGE_INDICES.REPLACEMENT_POLICY);
    }
);
