import axios from 'axios';
import { apiTransitionBiasharaFlexiCoverDetailsForm, premiumSuccess } from '../../../actions/biasharaFlexi/coverDetails';
import { setExpiredAuth } from '../../../actions/biasharaFlexi/quote';


export const calculatePremium = (store, next, action) => {
    (async() => {
        store.dispatch(apiTransitionBiasharaFlexiCoverDetailsForm.pending())
        try {
            const state = store.getState();
            const token = state?.biasharaFlexi?.auth?.loginData?.accessToken
            const payload = {
                employeesNumber: state?.biasharaFlexi?.coverDetails?.form?.employeesNumber?.value,
                benefitNumber: state?.biasharaFlexi?.coverDetails?.form?.benefitAmount?.value,
            }

            const resp = await calculate(token, payload);
            store.dispatch(premiumSuccess(resp.data));
            store.dispatch(apiTransitionBiasharaFlexiCoverDetailsForm.success())
        } catch (error) {
            if (error?.response?.data?.status === 401 || error?.response?.data?.status === 403) {
                store.dispatch(setExpiredAuth(true));
            }
            store.dispatch(apiTransitionBiasharaFlexiCoverDetailsForm.failure())
        }
    })();
    return next(action);
}

const calculate = async (token, payload) => {
    try {
        const url = `/om-api/biashara-flexi/premium/kenya`;
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                benefit_option: payload.benefitNumber,
                number_of_staff: payload.employeesNumber,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};