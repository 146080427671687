import { getQuoteForm, getPremiumCalculation, getProducts, getAddedCover } from '../../../selectors/healthInsuranceRW/quote';
import {
    getChildrenDetails,
    getLivesCoveredPersonalDetails,
    getMyDetails,
    getPersonalDetailsForm,
    getSpouseDetails,
} from '../../../selectors/healthInsuranceRW/personalDetails';
import { getDocumentsForm } from '../../../selectors/healthInsuranceRW/documents';
import { getPaymentsDetails, getPaymentsForm } from '../../../selectors/healthInsuranceRW/payments';
import { getIsApiPending } from '../../../selectors/healthInsuranceRW/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/healthInsuranceRW/payloadCollection';
import { getAccessToken, getPaymentStatusData } from '../../../selectors/healthInsuranceRW/payments';
import { getAgentForm } from '../../../selectors/healthInsuranceRW/healthAgentModal';
import axios from 'axios';
import { triggerConfirmOrder } from '../../../actions/healthInsuranceRW/payments';
import { getBeneficiaries } from '../../../selectors/healthInsuranceRW/personalDetailsBeneficiaries';
import { getNextOfKin, getNextOfKinForm } from '../../../selectors/healthInsuranceRW/nextOfKin';
import { getMedicalHistoryArray } from '../../../selectors/healthInsuranceRW/medicalHistory';

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        // if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await calculate(store.getState());
                const paymentForm = getPaymentsForm(store.getState());
                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());
                paymentForm.paymentType.value === 'mtn' && store.dispatch(triggerConfirmOrder());
            } catch (e) {
                console.error(e);
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        // }
    })();
    return next(action);
};

const payloadCollection = (state) => {
    const quotation = getQuoteForm(state);
    const product = getProducts(state);
    const personalDetails = getMyDetails(state);
    const spouseDetails = getSpouseDetails(state);
    const paymentForm = getPaymentsForm(state);
    const premium = getPremiumCalculation(state);
    const documents = getDocumentsForm(state);
    const children = getChildrenDetails(state);
    const beneficiaries = getBeneficiaries(state);
    const nextOfKin = getNextOfKinForm(state);
    const medicalHistory = getMedicalHistoryArray(state);
    const paymentData = getPaymentStatusData(state)

    const spouse = {
        firstName: spouseDetails?.form.firstName.value || "N/A",
        lastName: spouseDetails?.form.surname.value || "N/A",
        relationship: "spouse",
        dateOfBirth: spouseDetails?.form.dob.value || "N/A",
        postalAddress: spouseDetails?.form.postalBox.value || "N/A",
        postalCode: spouseDetails?.form.postalCode.value || "N/A",
        city: spouseDetails?.form.city.value || "N/A",
        phoneNumber: spouseDetails?.form.mobileNumber.value || "N/A",
        emailAddress: spouseDetails?.form.email.value || "N/A",
        birthCertificateDoc: 'N/A',
    };

    const livesCovered = children.map((item, idx) => {
        return {
                firstName: item.form.firstName.value || "N/A",
                lastName: item.form.surname.value || "N/A",
                relationship: "children",
                dateOfBirth: item.form.dob.value || "N/A",
                postalAddress: item.form.postalBox.value || "N/A",
                postalCode: item.form.postalCode.value || "N/A",
                city: item.form.city.value || "N/A",
                phoneNumber: item.form.mobileNumber.value || "N/A",
                emailAddress: item.form.email.value || "N/A",
                birthCertificateDoc: 'N/A',
        }
    });

    spouseDetails && livesCovered.push(spouse);
    const constructedBeneficiaries = beneficiaries.map((item, idx) => {
        return {
            firstName: item.firstName.value || "N/A",
            lastName: item.surname.value || "N/A",
            dateOfBirth: item.dob.value || "N/A",
            gender: "N/A",
            birthCertificate: "N/A",
            nhif: "N/A",
            splitPercentage: item.splitPercentage.value || "N/A"
        }
    })

    const constructedChronicalDiseases = medicalHistory?.length > 0 ? medicalHistory.map((item) => {
        return {
            disease: item.form.other.value ? item.form.other.value : item.disease || "N/A",
            relationship: item.form.relationship.value || "N/A",
            name: item.form.names.value || "N/A",
            idBirthCertificate: item.form.idPassport.value || "N/A",
            dateOfOnset: "N/A",
            diagnosis: "N/A",
            dateOfLastEpisode: "N/A",
            treatment: "N/A",
            additionalDetails: "N/A",
        }
    }) : []
    

    const checkAges = (agesArray, thresholdAge = 19) => {
        const result = {
            overAge: 0,
            underAge: 0
        };
    
        agesArray && agesArray.forEach(item => {
            if (item.value >= thresholdAge) {
                result.overAge++;
            } else {
                result.underAge++;
            }
        });
    
        return result;
    };


    const premiumCalculationPayload = {
        countryCode: 'RW',
        step: 'payment',
        productId: product[0].product_id || 'N/A',
        age: parseInt(quotation.age.value) || 'N/A',
        inPatient: {
            inpatientLimitId: parseInt(quotation.inpatientCover.value?.id) || 0,
        },
        outPatient: {
            present: quotation.outpatientCover.value ? true : false,
            outpatientLimitId: parseInt(quotation.outpatientCover.value?.id) || 0,
            option: quotation.outpatientCover.value ? 1 : 0
        },
        familyMembers: {
            children: {
                count: quotation.childrenAge.value?.length || 0,
                overAgeCount: checkAges(quotation.childrenAge.value)?.overAge || 0
            },
            spouse: {
                age: parseInt(quotation.spouseAge.value) || 0,
            },
        },
        optical: quotation.opticalCover.value,
        dental: quotation.dentalCover.value,
        maternityLimitId: parseInt(quotation.maternityCover.value?.id) || 0,
        firstName: personalDetails.form.firstName.value || 'N/A',
        lastName: personalDetails.form.surname.value || 'N/A',
        dateOfBirth: personalDetails.form.dob.value || 'N/A',
        phoneNumber: personalDetails.form.mobileNumber.value || 'N/A',
        IdNumber: personalDetails.form.idPassport.value || 'N/A',
        pin: personalDetails.form.tinNumber.value || 'N/A',
        streetAddress: personalDetails.form.streetAddress.value || 'N/A',
        surbub: personalDetails.form.city.value || 'N/A',
        city: personalDetails.form.city.value || 'N/A',
        postalAddress: personalDetails.form.postalBox.value || 'N/A',
        postalCode: personalDetails.form.postalCode.value || 'N/A',
        emailAddress: personalDetails.form.email.value || 'N/A',
        paidAmount: premium?.value?.data?.totalPremium?.toString() || 'N/A',
        transactionReference: paymentData?.data[0]?.trxRef || 'N/A',
        transactionStatus: paymentData?.data[0]?.status || 'N/A',
        modeOfPayment: paymentForm.paymentType.value === 'mtn' ? 'FDIMOMO' : 'BANK',
        gender: 'N/A',
        maritalStatus: quotation.spouseAge.value ? 'MARRIED' : 'SINGLE',
        IdNumberDoc: documents.idUpload.value?.base64File || 'N/A',
        marriageCertificate: documents.marriageCert.value?.base64File || 'N/A',
        birthCertificate: documents.birthCert.value?.base64File || 'N/A',
        PinDoc: 'N/A',
        livesCovered,
        beneficiaries: constructedBeneficiaries,
        nextOfKin: nextOfKin.firstName.value ? {
            firstName: nextOfKin.firstName.value || "N/A",
            lastName: nextOfKin.lastName.value || "N/A",
            IdNumber: nextOfKin.idNumber.value || "N/A",
            relationship: nextOfKin.relationship.value || "N/A",
            emailAddress: nextOfKin.email.value || "N/A",
            postalAddress: nextOfKin.postalAddress.value || "N/A",
        } : null,
        cronicalDiseases: constructedChronicalDiseases,
    };

    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/health-insurance-rw/submit-payload/rwanda';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
