export const APP_URL_PREFIX = '/travel-insurance-ke';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    TRAVEL_PLAN: 0,
    ABOUT_YOU: 1,
    TRAVEL_DETAILS: 2,
    PLANS: 3,
    PAYMENT: 4,
};

export const PAGES = [
    {
        URL: `${APP_URL_PREFIX}/travel-plan`,
        URL_PART: 'travel-plan',
        TITLE: 'Travel Plan',
    },
    {
        URL: `${APP_URL_PREFIX}/about-you`,
        URL_PART: 'about-you',
        TITLE: 'About You',
    },
    {
        URL: `${APP_URL_PREFIX}/travel-details`,
        URL_PART: 'travel-details',
        TITLE: 'Details',
    },
    {
        URL: `${APP_URL_PREFIX}/plans`,
        URL_PART: 'plans',
        TITLE: 'Plans',
    },

    {
        URL: `${APP_URL_PREFIX}/payment`,
        URL_PART: 'payment',
        TITLE: 'Payment',
    },
];

export const TRAVEL_PLANS = {
    SINGLE_TRIP: { title: 'Single Trip', value: 'singleTrip' },
    ANNUAL_MULTI_TRIP: { title: 'Annual Multi Trip', value: 'annualMultiTrip' },
};

export const TRAVEL_INSURANCE_PLANS = {
    HOLIDAY_TRAVEL_PLAN: 'Holiday Travel Plan',
    BUSINESS_TRAVEL_PLAN: 'Business Travel Plan',
};

export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const TRAVEL_INSURANCE_URLS = {
    TRAVEL_PLAN: PAGES[PAGE_INDICES.TRAVEL_PLAN].URL,
    ABOUT_YOU: PAGES[PAGE_INDICES.ABOUT_YOU].URL,
    TRAVEL_DETAILS: PAGES[PAGE_INDICES.TRAVEL_DETAILS].URL,
    PLANS: PAGES[PAGE_INDICES.PLANS].URL,
    PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL,
};
