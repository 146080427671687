import { PAGE_INDICES } from '../../actions/domesticInsurance/types';
import {
	SET_ACTIVE_PAGE_INDEX,
	SET_NAVIGATION_DONE,
    NAVIGATE_TO_DOMESTIC_QUOTE,
    NAVIGATE_TO_PERSONAL_DETAILS,
    NAVIGATE_TO_BUILDING_DETAILS,
    NAVIGATE_TO_DOCUMENTS,
    NAVIGATE_TO_PAYMENT,
	SET_LANDING_PAGE,
	NAVIGATE_TO_LANDING_PAGE,
} from '../../actions/domesticInsurance/routing';

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    landingPage: null,
};

export default (state = initRouting, action) => {
    const setActivePage = pageIndex => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };
        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };
        case NAVIGATE_TO_DOMESTIC_QUOTE:
            return setActivePage(PAGE_INDICES.QOUTE);
        case NAVIGATE_TO_PERSONAL_DETAILS:
            return setActivePage(PAGE_INDICES.PERSONAL_DETAILS);
        case NAVIGATE_TO_BUILDING_DETAILS:
            return setActivePage(PAGE_INDICES.BUILDING_DETAILS);
        case NAVIGATE_TO_DOCUMENTS:
            return setActivePage(PAGE_INDICES.DOCUMENTS);
        case NAVIGATE_TO_PAYMENT:
            return setActivePage(PAGE_INDICES.PAYMENT);
        case SET_LANDING_PAGE:
            return { ...state, landingPage: action.value };
        case NAVIGATE_TO_LANDING_PAGE:
            return setActivePage(PAGE_INDICES.LANDING_PAGE);

        default:
            return state;
    }
};
