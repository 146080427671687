import {
    AGE_SET_VALUE,
    GENDER_SET_VALUE,
    RETIREMENT_FOR_SET_VALUE,
    PARTNER_AGE_SET_VALUE,
    RETIREMENT_WORRIES_SET_VALUE,
    TOGGLE_BUDGET_TOOL,
} from "../../../actions/atRetirement/aboutYou"
import {TOGGLE_SPEAK_TO_AN_ADVISER} from "../../../actions/atRetirement/speakToAnAdviser";
import {
    getAge,
    getGender,
    getRetirementFor,
    getPartnerAge,
    getRetirementWorries,
    getOpenDrawer,
} from "../../../selectors/atRetirement/aboutYou"
import { getReferrerId } from '../../../selectors/atRetirement/intro'
import { simpleFieldTracking } from "../formTracking";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const aboutYouFieldTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const referrerId = getReferrerId(state);
    const field = (() => {
        switch (action.type) {
            case AGE_SET_VALUE: return getAge(state);
            case GENDER_SET_VALUE: return getGender(state);
            case RETIREMENT_FOR_SET_VALUE: return getRetirementFor(state);
            case PARTNER_AGE_SET_VALUE: return getPartnerAge(state);
            case RETIREMENT_WORRIES_SET_VALUE: return getRetirementWorries(state);
            default: return null;
        }
    })();

    if (field) {
        const sendValue = true;
        simpleFieldTracking({action, field, referrerId, sendValue});
    }

    return result;
}

export const aboutYouTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const trackingActions = [
        TOGGLE_BUDGET_TOOL,
        TOGGLE_SPEAK_TO_AN_ADVISER
    ];

    const openDrawer = getOpenDrawer(state);
    if (trackingActions[0].includes(action.type)) {
        if (openDrawer) {
            otherEventTracking(action.type, findOtherEventIndex("OPEN_DRAWER"), openDrawer);
        }
    } else if (trackingActions[1].includes(action.type)) {
        if (openDrawer) {
            otherEventTracking(action.type, findOtherEventIndex("OPEN_DRAWER"), openDrawer);
        }
    }

    return result;
};
