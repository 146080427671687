export const ROA_QUOTE_TOOL = 'roaQuoteTools/quoteTools/';
export const ROA_QUOTE_TOOL_SET_VALUE = ROA_QUOTE_TOOL + 'setValue/';


export const ROA_QUOTE_TOOL_SIDEBAR_OPEN_VALUE = ROA_QUOTE_TOOL_SET_VALUE + 'sidebarOpen';
export const ROA_QUOTE_TOOL_SELECTED_QUOTE_TOOL_VALUE = ROA_QUOTE_TOOL_SET_VALUE + 'selectedQuoteTool';
export const REINITIALIZE = 'roaQuoteTools/quoteTools/state/reinitialize';

export const setSideBarOpen = value => ({type: ROA_QUOTE_TOOL_SIDEBAR_OPEN_VALUE, value});
export const setSelectedQuoteTool = value => ({type: ROA_QUOTE_TOOL_SELECTED_QUOTE_TOOL_VALUE, value});
export const setToInitial = () => ({ type: REINITIALIZE });
