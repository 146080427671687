import { getIsApiPending, getQuoteForm } from '../../../selectors/digitalSavings/quote';
import {
    apiTransitionDigitalSavingsQuoteForm,
    calculateDigitalSavingsPremiumSuccess,
} from '../../../actions/digitalSavings/quote';
import { getAccessToken } from '../../../selectors/digitalSavings/payment';
import axios from 'axios';

export const premiumCalculationService = (store, next, action) => {
    (async () => {
        // if (!getIsApiPending(store.getState())) {
        store.dispatch(apiTransitionDigitalSavingsQuoteForm.pending());
        try {
            const resp = await calculate(store.getState());
            store.dispatch(calculateDigitalSavingsPremiumSuccess(resp.data));
            store.dispatch(apiTransitionDigitalSavingsQuoteForm.success());
        } catch (e) {
            console.error(e);
            store.dispatch(apiTransitionDigitalSavingsQuoteForm.failure());
        }
        // }
    })();
    return next(action);
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);

    const premiumCalculationPayload = {
        /*if no monthlyContribution set it to 0*/
        monthlyContribution: quotation.desired.value || 0,
        savingStartDate: quotation.startdate.value || 0,
        savingDuration: quotation.duration.value ? quotation.duration.value * 12 : 0,
        /*if no lumpsumContribution set it to 0*/
        lumpsumContribution: quotation.lumpsum.value || 0,
        frequency: quotation.frequency.value === 'monthly' ? 12 : quotation.frequency.value === 'quaterly' ? 4 : quotation.frequency.value === 'semiannual' ? 6 : 12,
        /*set isTimeInMonths to false if passing savingDuration as years i.e. 5yrs*/
        isTimeInMonths: true,
        isMonthly: quotation.saving_category.value === 'once-off' ? false : true,
        isYearly: quotation.frequency.value === 'yearly' ? true : false,
    };

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/digital-savings/premium';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
