export const SAVE2GETHER_KEY = 'publicWeb/save2gether';
const SET_VALID_KEY = 'setValid';
const SET_VALUE_KEY = 'setValue';

const getActionType = type => {
    return `${SAVE2GETHER_KEY}/${type}`;
}

export const SAVE2GETHER_SET_VALID = getActionType(SET_VALID_KEY);
export const SAVE2GETHER_SET_VALUE = getActionType(SET_VALUE_KEY);

export const FORM_VALID_TRANSITION_SAVE2GETHER = `${SAVE2GETHER_KEY}/transition/formValid`;
export const FORM_INVALID_TRANSITION_SAVE2GETHER = `${SAVE2GETHER_KEY}/transition/formInvalid`;

export const transitionSave2gether = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_SAVE2GETHER }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_SAVE2GETHER }),
};

const setValidActionType = type => {
    return getActionType(`${SET_VALID_KEY}/${type}`);
};
const setValueActionType = type => {
    return getActionType(`${SET_VALUE_KEY}/${type}`);
};

export const SUBMISSION_COMPLETE = getActionType('submission/complete');
export const submissionComplete = submissionStatus => ({ type: SUBMISSION_COMPLETE, submissionStatus });

export const SAVE2GETHER_SEND_EMAIL = getActionType('sendEmail');
export const sendEmail = () => ({ type: SAVE2GETHER_SEND_EMAIL });

export const RESET_SAVE2GETHER = getActionType('resetSave2getherStore');
export const FORM_BLOCKED_SET_VALUE = setValueActionType('formBlocked');
export const SHOW_THANK_YOU_SET_VAULE = setValueActionType('showThankYou');
export const SHOW_ERROR_SET_VAULE = setValueActionType('showError');
export const NAME_SET_VALUE = setValueActionType('name');
export const NAME_SET_VALID = setValidActionType('name');
export const SURNAME_SET_VALUE = setValueActionType('surname');
export const SURNAME_SET_VALID = setValidActionType('surname');
export const BUSINESS_NAME_SET_VALUE = setValueActionType('businessName');
export const BUSINESS_NAME_SET_VALID = setValidActionType('businessName');
export const PHONE_NUMBER_SET_VALUE = setValueActionType('phoneNumber');
export const PHONE_NUMBER_SET_VALID = setValidActionType('phoneNumber');
export const BUSINESS_NUMBER_SET_VALUE = setValueActionType('businessNumber');
export const BUSINESS_NUMBER_SET_VALID = setValidActionType('businessNumber');
export const EMAIL_ADDRESS_SET_VALUE = setValueActionType('emailAddress');
export const EMAIL_ADDRESS_SET_VALID = setValidActionType('emailAddress');
export const BUSINESS_REGISTERED_SET_VALUE = setValueActionType('businessRegistered');
export const BUSINESS_REGISTERED_SET_VALID = setValidActionType('businessRegistered');
export const NUMBER_OF_EMPLOYEES_SET_VALUE = setValueActionType('setValueActionTypenumberOfEmployees');
export const NUMBER_OF_EMPLOYEES_SET_VALID = setValidActionType('numberOfEmployees');
export const BUSINESS_DURATION_SET_VALUE = setValueActionType('businessDuration');
export const BUSINESS_DURATION_SET_VALID = setValidActionType('businessDuration');
export const BUSINESS_MANAGEMENT_SET_VALUE = setValueActionType('businessManagement');
export const BUSINESS_MANAGEMENT_SET_VALID = setValidActionType('businessManagement');
export const BUSINESS_REGISTERED_AD_SET_VALUE = setValueActionType('businessRegisteredAs');
export const BUSINESS_REGISTERED_AD_SET_VALID = setValidActionType('businessRegisteredAs');
export const HAS_EMPLOYEE_RETIREMENT_SET_VALUE = setValueActionType('hasEmployeeRetirement');
export const HAS_EMPLOYEE_RETIREMENT_SET_VALID = setValidActionType('hasEmployeeRetirement');

export const resetSave2getherStore = () => ({ type: RESET_SAVE2GETHER });
export const setFormBlocked = value => ({ type: FORM_BLOCKED_SET_VALUE, value });
export const setShowThankYou = value => ({ type: SHOW_THANK_YOU_SET_VAULE, value });
export const setShowError = value => ({ type: SHOW_ERROR_SET_VAULE, value });
export const setName = value => ({ type: NAME_SET_VALUE, value });
export const setNameValid = error => ({ type: NAME_SET_VALID, error });
export const setSurname = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });
export const setBusinessName = value => ({ type: BUSINESS_NAME_SET_VALUE, value });
export const setBusinessNameValid = error => ({ type: BUSINESS_NAME_SET_VALID, error });
export const setPhoneNumber = value => ({ type: PHONE_NUMBER_SET_VALUE, value });
export const setPhoneNumberValid = error => ({ type: PHONE_NUMBER_SET_VALID, error });
export const setBusinessNumber = value => ({ type: BUSINESS_NUMBER_SET_VALUE, value });
export const setBusinessNumberValid = error => ({ type: BUSINESS_NUMBER_SET_VALID, error });
export const setEmailAddress = value => ({ type: EMAIL_ADDRESS_SET_VALUE, value });
export const setEmailAddressValid = error => ({ type: EMAIL_ADDRESS_SET_VALID, error });
export const setBusinessRegistered = value => ({ type: BUSINESS_REGISTERED_SET_VALUE, value });
export const setBusinessRegisteredValid = error => ({ type: BUSINESS_REGISTERED_SET_VALID, error });
export const setNumberOfEmployees = value => ({ type: NUMBER_OF_EMPLOYEES_SET_VALUE, value });
export const setNumberOfEmployeesValid = error => ({ type: NUMBER_OF_EMPLOYEES_SET_VALID, error });
export const setBusinessDuration = value => ({ type: BUSINESS_DURATION_SET_VALUE, value });
export const setBusinessDurationValid = error => ({ type: BUSINESS_DURATION_SET_VALID, error });
export const setBusinessManagement = value => ({ type: BUSINESS_MANAGEMENT_SET_VALUE, value });
export const setBusinessManagementValid = error => ({ type: BUSINESS_MANAGEMENT_SET_VALID, error });
export const setBusinessRegisteredAs = value => ({ type: BUSINESS_REGISTERED_AD_SET_VALUE, value });
export const setBusinessRegisteredAsValid = error => ({ type: BUSINESS_REGISTERED_AD_SET_VALID, error });
export const setHasEmployeeRetirement = value => ({ type: HAS_EMPLOYEE_RETIREMENT_SET_VALUE, value });
export const setHasEmployeeRetirementValid = error => ({ type: HAS_EMPLOYEE_RETIREMENT_SET_VALID, error });
