import { validateNumber } from '@om/validation';
import {
    AGE_18child_SET_VALUE,
    set18childValid,
} from '../../../actions/roaQuoteTools/afyaImaraCountyQuoteTool';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const afyaImaraCountyToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        let state = store.getState();
        let displayingCountError = false;

        switch (action.type) {
            case AGE_18child_SET_VALUE:
                error = validateChildrenCount(action.value, state);
                if (displayingCountError) {
                    if (error == null) removeErrorDisplays();
                    return;
                }
                if (error && !displayingCountError) {
                    displayingCountError = true;
                    return set18childValid(error);
                }
                displayingCountError = false;
                error = validateNumber(action.value, {
                    min: 0,
                    max: 6,
                    pronoun: '18child Value',
                });
                return set18childValid(error);

            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};

function validateChildrenCount(value, state) {
    let { child_18, child_19_29, child_30_40, child_40_50 } =
        state.roaQuoteTools.afyaImaraCountyQuoteTool.form;
    let ageSum =
        Number(child_18.value) +
        Number(child_19_29.value) +
        Number(child_30_40.value) +
        Number(child_40_50.value);
    if (ageSum > 6 && Number(value) > 0)
        return 'The total number of dependants cannot exceed 6.';
    else return null;
}

function removeErrorDisplays() {
    set18childValid(null);
}
