import {
    DOB_SET_VALUE,
    EMPLOYMENT_DATE_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    ID_PASSPORT_SET_VALUE,
    KRA_PIN_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    setDoBValid,
    setEmployementDateValid,
    setFirstNameValid,
    setIdPassortNumberValid,
    setKraPinNumberValid,
    setMobileNumberValid,
    setSurnameValid,
    SURNAME_SET_VALUE,
} from '../../../actions/biasharaFlexi/employeeDetails';
import { validationIsEqual } from '../status';
import {
    validateName,
    validateContactNumber,
    validateKraPinNumber,
    validateDateOfBirth,
    validateDate,
    validatePassportNumber,
    validateSurname,
    validateNumericString,
} from '@om/validation';

let prevValidationAction = null;
export const employeeDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'First Name',
                    required: true,
                });
                return setFirstNameValid(error);

            case KRA_PIN_NUMBER_SET_VALUE:
                error = validateKraPinNumber(action.value, { subject: 'KRA Pin Number', required: true });
                return setKraPinNumberValid(error);

            case MOBILE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Mobile Number',
                    required: true,
                });
                return setMobileNumberValid(error);

            case SURNAME_SET_VALUE:
                error = validateSurname(action.value, {
                    subject: 'Surname',
                    required: true,
                });
                return setSurnameValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, {
                    subject: 'Date of birth',
                    required: true,
                    max: 65,
                    min: 18
                });
                return setDoBValid(error);

            case EMPLOYMENT_DATE_SET_VALUE:
                error = validateDate(action.value, { subject: 'Date of employment', required: true });
                return setEmployementDateValid(error);

            case ID_PASSPORT_SET_VALUE:
                const error1 = validateNumericString(action.value, { min: 8, max: 15, subject: "ID number", required: true });
                const error2 = validatePassportNumber(action.value, { subject: 'Passport number', required: true, });
                error = (!error1 && !error2) ? null : (!error2 ? null : error1);
                return setIdPassortNumberValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
