import { FIELD_STATUS, FORM_STATUS } from "../status";
import { taxBrackets, taxRebates, infoData } from "./referenceData"; // to be replaced by db fetch

import {
    AGE_SET_VALUE,
    AGE_SET_VALID,
    FORM_VALID_BASIC_CALCULATION,
    FORM_INVALID_BASIC_CALCULATION,
    INCOME_FREQUENCY_SET_VALUE,
    INCOME_FREQUENCY_SET_VALID,
    INCOME_VALUE_SET_VALUE,
    INCOME_VALUE_SET_VALID,
    REFERENCE_DATA_SET_VALUE,
    RESULTS_SET_VALUE,
    REINITIALIZE_STATE_BASIC_CALCULATOR,
    PRODUCT_RECOMMENDATION_SET_VALUE,
    SHOW_FIELD_DETAILS,
} from "../../actions/incomeTaxCalculator/basicCalculator";

const initialState = {
    formStatus: FORM_STATUS.INVALID,
    showRecommendation: false,
    ageGroups : [
        {
            value: 'categoryOne',
            description: 'Under 65',
        },
        {
            value: 'categoryTwo',
            description: 'Between 65 and 75',
        },
        {
            value: 'categoryThree',
            description: 'Over 75',
        },
    ],
    frequencyOptions: [
        {
            value: 1,
            description: 'Monthly',
        },
        { 
            value: 2,
            description: 'Yearly',
        },
    ],
    form: {
        age: { value: 'categoryOne', error: null, status: 'valid' },
        incomeFrequency: { value: 'Monthly', error: null, status: 'valid' },
        incomeValue: { value: null, error: null, status: null },
    },
    result: {
        monthlyIncome: null,
        taxValue: null,
    },
    referenceData : {
        taxBrackets,
        taxRebates,
        infoData,
    },
    showFiedDetailsStatus: {
        fieldDescription: null,
        title: null,
    }
};

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch(action.type) {
        // Form Validation
        case FORM_VALID_BASIC_CALCULATION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_BASIC_CALCULATION:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Age
        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Income frequency
        case INCOME_FREQUENCY_SET_VALUE:
            return updateFormField('incomeFrequency', field => ({
                ...field,
                value: action.value,
            }));
        case INCOME_FREQUENCY_SET_VALID:
            return updateFormField('incomeFrequency', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        
        // Income value
        case INCOME_VALUE_SET_VALUE:
            return updateFormField('incomeValue', field => ({
                ...field,
                value: action.value,
            }));
        case INCOME_VALUE_SET_VALID:
            return updateFormField('incomeValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Tax value
        case RESULTS_SET_VALUE:
            return { ...state, result: action.value };
        // Tax brackets and rebates
        case REFERENCE_DATA_SET_VALUE:
            return state;
        // reinitialise state
        case REINITIALIZE_STATE_BASIC_CALCULATOR:
            return initialState;

        // Get basic product recommendation
        case PRODUCT_RECOMMENDATION_SET_VALUE:
            return {
                ...state,
                showRecommendation: true
            };
        // Display the modal
        case SHOW_FIELD_DETAILS:
            return {
                ...state,
                showFiedDetailsStatus: {
                    fieldDescription: state.referenceData.infoData.resultCard,
                    title: action.value.title
                }
            }
        default:
            return state;
    }

};