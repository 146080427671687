import { validationIsEqual } from "../status";
import {
    validateAlphaNumericString,
    validateContactNumber,
    validateEmail, 
    validateSingleSelection
} from  "@om/validation";
import { getFinancialAdviceForm } from "../../../selectors/atRetirement/financialAdvice";
import {
    SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALUE,
    CONTACT_NUMBER_ADVISOR_SET_VALUE,
    CLIENT_EMAIL_SET_VALUE,
    REASONS_SET_VALUE,
    setShortDescriptionFindOutMoreValid,
    setContactNumberAdvisorValid,
    setClientEmailValid,
    setReasonsValid,
} from "../../../actions/atRetirement/financialAdvice";

let prevValidationAction = null;
export const financialAdviceValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;

        const reasonsValid = () => {
            const financialAdviceForm = getFinancialAdviceForm(store.getState());
            const reasons = financialAdviceForm.reasons.value;

            if(reasons && reasons.length == 1 && reasons.includes(action.value))
                return validateSingleSelection(null, 'Option');

            return null;
        }

        switch (action.type) {
            case REASONS_SET_VALUE:
                error = reasonsValid(null);
                return setReasonsValid(error);
            case CONTACT_NUMBER_ADVISOR_SET_VALUE:
                error = validateContactNumber(action.value);
                return setContactNumberAdvisorValid(error);
            case CLIENT_EMAIL_SET_VALUE:
                error = validateEmail(action.value);
                return setClientEmailValid(error);
            case SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALUE:
                error = validateAlphaNumericString(action.value, { min: 3, max: 254, subject: "Short Description" });
                return setShortDescriptionFindOutMoreValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};