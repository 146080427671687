import {
    KRA_PIN_SET_VALUE,
    ID_SET_VALUE,
    VEHICLE_REGISTRATION_NUMBER_SET_VALUE,
    COVER_START_DATE_SET_VALUE,
    setKraPinValid,
    setIDValid,
    setVehicleRegNumberValid,
    setCoverStartDateValid
} from '../../../actions/roaCarInsurance/details';
import { validationIsEqual } from '../status';
import {validateAlphaNumericStringAllowingOnlyNumbers, validateKeCarRegNumber, validateKraPinNumber, validateDate} from "@om/validation";

let prevValidationAction = null;
export const carDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        let today = new Date()
        let  validTodayDate = today.toLocaleDateString('en-KE').split('/').join('/');
        switch (action.type) {
            case VEHICLE_REGISTRATION_NUMBER_SET_VALUE:
                error = validateKeCarRegNumber(action.value, {subject: 'REG NUMBER', required: true})
                return setVehicleRegNumberValid(error);
            case KRA_PIN_SET_VALUE:
                error = validateKraPinNumber(action.value, {subject: 'KRA PIN', required: true})
                return setKraPinValid(error);
            case ID_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {subject: 'ID NUMBER', required: true, min: 6, max: 8})
                return setIDValid(error);
            case COVER_START_DATE_SET_VALUE:
                error = validateDate(action.value,{required: true, min: validTodayDate} )
                return setCoverStartDateValid(error);
            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }
    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
