import { BENEFICIARY_DRAWER, FIELD_STATUS, FORM_STATUS } from "../status";
import {
    ADD_NEW_TRANSITION_BENEFICIARIES,
    CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES,
    DATE_OF_BIRTH_SET_VALID,
    DATE_OF_BIRTH_SET_VALUE,
    DATE_OF_BIRTH_SET_UNTOUCHED,
    DELETE_TRANSITION_BENEFICIARIES,
    EDIT_TRANSITION_BENEFICIARIES,
    EMAIL_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_UNTOUCHED,
    FIRST_NAME_SET_VALID,
    FIRST_NAME_SET_VALUE,
    FIRST_NAME_SET_UNTOUCHED,
    FORM_INVALID_TRANSITION_BENEFICIARIES,
    FORM_VALID_TRANSITION_BENEFICIARIES,
    RELATIONSHIP_SET_VALID,
    RELATIONSHIP_SET_VALUE,
    RELATIONSHIP_SET_UNTOUCHED,
    MOBILE_NUMBER_SET_VALID,
    MOBILE_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_UNTOUCHED,
    SAVE_TRANSITION_BENEFICIARIES,
    SURNAME_SET_VALID,
    SURNAME_SET_VALUE,
    SURNAME_SET_UNTOUCHED,
    TITLE_SET_VALID,
    TITLE_SET_VALUE,
    TITLE_SET_UNTOUCHED,
    TOGGLE_EDIT_TRANSITION_BENEFICIARIES,
    SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE,
    SELECTED_EXISTING_BENEFICIARY_SET_VALUE,
    RESET_BENEFICAIRIES,
    UPDATE_BENEFICIARY_LIST,
    SET_CONFIRM_REMOVE_BENEFICIARY_ID
} from "../../actions/easiplusFuneralPlan/beneficiaries";

const newBeneficiary = () => ({
    selectedExistingBeneficiary: "NONE",
    title: { value: null, error: null, status: null },
    firstName: { displayValue: null, value: null, error: null, status: null },
    surname: { displayValue: null, value: null, error: null, status: null },
    dateOfBirth: { value: null, error: null, status: null },
    relationship: { value: null, error: null, status: null },
    mobileNumber: { displayValue: null, value: null, error: null, status: null },
    email: { displayValue: null, value: null, error: null, status: null },
});
export const newEditBeneficiary = () => ({
    form: newBeneficiary(),
    formStatus: FORM_STATUS.INVALID,
})
export const initBeneficiaries = {
    beneficiaries: [],
    beneficiariesIdIndex: 0,
    openDrawer: null,
    editId: null,
    editBeneficiary: newEditBeneficiary(),
    showMustHaveBeneficiary: false,
    confirmRemoveBeneficiaryId: null,
};

export default (state = initBeneficiaries, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        editBeneficiary: {
            ...state.editBeneficiary,
            form: {
                ...state.editBeneficiary.form,
                [fieldId]: update(state.editBeneficiary.form[fieldId]),
            },
        },
    });

    const trimField = (value) => value ? value.trim() : value ;
    const removeWhiteSpacesFromValue = (value) => value ? value.replace(/ /g, '') : value;

    switch (action.type) {
        // CRUD operations
        case ADD_NEW_TRANSITION_BENEFICIARIES:
            // replace edit beneficiary with new instance
            return state;
        case DELETE_TRANSITION_BENEFICIARIES:
            // use key to delete beneficiary from list
            const delId = state.confirmRemoveBeneficiaryId;
            const beneficiaries = state.beneficiaries.filter(ben => ben.id !== delId);
            return {
                ...state,
                confirmRemoveBeneficiaryId: null,
                beneficiaries,
            };
        case EDIT_TRANSITION_BENEFICIARIES:
            // use key to copy beneficiary from list to edit beneficiary
            const editId = action.beneficiaryId;
            const editForm = state.beneficiaries.find(ben => ben.id === editId);

            if (!editForm)
                return state;

            return {
                ...state,
                editId,
                editBeneficiary: {
                    form: Object.assign({}, editForm.value),
                    formStatus: FORM_STATUS.VALID,
                },
            };
        case CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES:
            // replace edit beneficiary with new instance
            return {
                ...state,
                editId: null,
                editBeneficiary: newEditBeneficiary(),
            };
        case SAVE_TRANSITION_BENEFICIARIES:
            if (state.editBeneficiary.formStatus === FORM_STATUS.INVALID)
                return state;
            /**
             * 1. copy edit beneficiary
             * 2. clear edit beneficiary instance
             * 3. if action.beneficiaryId,
             *      then updating:
             *          find beneficiary in list, and replace with (1)
             *      else adding new beneficiary to list:
             *          copy new ID from and increment beneficiariesIdIndex
             *          append (1) to list with new ID
             **/
            const isNewBeneficiary = action.beneficiaryId == null;
            const beneficiaryId = isNewBeneficiary
                ? state.beneficiariesIdIndex
                : action.beneficiaryId;
            const newBeneficiary = {
                id: beneficiaryId,
                value: state.editBeneficiary.form
            };

            if (isNewBeneficiary) {
                return {
                    ...state,
                    beneficiaries: [...state.beneficiaries, newBeneficiary],
                    beneficiariesIdIndex: state.beneficiariesIdIndex + 1,
                    editBeneficiary: newEditBeneficiary(),
                };
            }

            const prevList = state.beneficiaries;
            const index = prevList.findIndex(ben => ben.id === beneficiaryId)
            const edited = {
                id: beneficiaryId,
                value: state.editBeneficiary.form,
            };
            const newList = [
                ...prevList.slice(0, index),
                edited,
                ...prevList.slice(index + 1)
            ];

            return {
                ...state,
                beneficiaries: newList,
                editId: null,
                editBeneficiary: newEditBeneficiary(),
            };
        case RESET_BENEFICAIRIES:
            return { ...initBeneficiaries };
        case UPDATE_BENEFICIARY_LIST:
            const livesCoveredMembers = action.value;
            const memberKeys = Object.keys(livesCoveredMembers);
            const beneficiariesList = state.beneficiaries;
            if (beneficiariesList && beneficiariesList.length) {
                const updatedBeneficiaries = beneficiariesList.map(beneficiary => {
                    if (memberKeys.includes(beneficiary.value.selectedExistingBeneficiary)) {
                        const updatedDetails = livesCoveredMembers[beneficiary.value.selectedExistingBeneficiary];
                        return {
                            ...beneficiary,
                            value: {
                                ...beneficiary.value,
                                title: updatedDetails.title,
                                firstName: updatedDetails.firstName,
                                surname: updatedDetails.surname,
                                dateOfBirth: updatedDetails.dateOfBirth,
                            }
                        }
                    } else return beneficiary;
                });
                return { ...state, beneficiaries: updatedBeneficiaries };
            } else return { ...state };


        // Form Validation
        case FORM_VALID_TRANSITION_BENEFICIARIES:
            return {
                ...state,
                editBeneficiary: {
                    ...state.editBeneficiary,
                    formStatus: FORM_STATUS.VALID,
                },
            };
        case FORM_INVALID_TRANSITION_BENEFICIARIES:
            return {
                ...state,
                editBeneficiary: {
                    ...state.editBeneficiary,
                    formStatus: FORM_STATUS.INVALID,
                },
            };

        // Drawers
        case TOGGLE_EDIT_TRANSITION_BENEFICIARIES:
            return {
                ...state,
                openDrawer: state.openDrawer
                    ? null
                    : BENEFICIARY_DRAWER.EDIT,
            };

        // Form Fields
        case SELECTED_EXISTING_BENEFICIARY_SET_VALUE:
            return {
                ...state,
                editBeneficiary: {
                    ...state.editBeneficiary,
                    form: {
                        ...state.editBeneficiary.form,
                        selectedExistingBeneficiary: action.value
                    }
                }
            };

        case TITLE_SET_VALUE:
            return updateFormField('title', field => ({
                ...field,
                value: action.value,
            }));
        case TITLE_SET_VALID:
            return updateFormField('title', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case TITLE_SET_UNTOUCHED:
            return updateFormField('title', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', field => ({
                ...field,
                value: trimField(action.value),
                displayValue: action.value
            }));
        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case FIRST_NAME_SET_UNTOUCHED:
            return updateFormField('firstName', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case SURNAME_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: trimField(action.value),
                displayValue: action.value
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SURNAME_SET_UNTOUCHED:
            return updateFormField('surname', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case DATE_OF_BIRTH_SET_VALUE:
            return updateFormField('dateOfBirth', field => ({
                ...field,
                value: action.value,
            }));
        case DATE_OF_BIRTH_SET_VALID:
            return updateFormField('dateOfBirth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DATE_OF_BIRTH_SET_UNTOUCHED:
            return updateFormField('dateOfBirth', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case RELATIONSHIP_SET_VALUE:
            return updateFormField('relationship', field => ({
                ...field,
                value: action.value,
            }));
        case RELATIONSHIP_SET_VALID:
            return updateFormField('relationship', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case RELATIONSHIP_SET_UNTOUCHED:
            return updateFormField('relationship', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case MOBILE_NUMBER_SET_VALUE:
            return updateFormField('mobileNumber', field => ({
                ...field,
                value: removeWhiteSpacesFromValue(action.value),
                displayValue: action.value
            }));
        case MOBILE_NUMBER_SET_VALID:
            return updateFormField('mobileNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MOBILE_NUMBER_SET_UNTOUCHED:
            return updateFormField('mobileNumber', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('email', field => ({
                ...field,
                value: trimField(action.value),
                displayValue: action.value
            }));
        case EMAIL_SET_VALID:
            return updateFormField('email', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMAIL_SET_UNTOUCHED:
            return updateFormField('email', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE:
            return { ...state, showMustHaveBeneficiary: action.value };

        case SET_CONFIRM_REMOVE_BENEFICIARY_ID:
            return { ...state, confirmRemoveBeneficiaryId: action.value };

        default:
            return state;
    }
};
