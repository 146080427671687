import {
  RISK_LEVEL_SET_VALUE,
} from "../../actions/covidSelfAssessment/result";

const initResult = {
  riskLevel: null,
};

export default (state = initResult, action) => {
  switch (action.type) {
      case RISK_LEVEL_SET_VALUE:
          return { ...state, riskLevel: action.value };
      default:
          return state;
  }
};
