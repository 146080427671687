// Events
export const DLP_APPLICATION_REFERENCE_DATA =
  "digiLifeLoanProtection/";
export const DLP_APPLICATION_REFERENCE_DATA_SET_VALUE =
DLP_APPLICATION_REFERENCE_DATA+"applicationReferenceData/setValue/";
export const DLP_APPLICATION_REFERENCE_DATA_GET_VALUE =
DLP_APPLICATION_REFERENCE_DATA+ "applicationReferenceData/getValue/";
export const DLP_BANK_REFERENCE_DATA_GET_VALUE =
DLP_APPLICATION_REFERENCE_DATA+ "bankReferenceData/getValue/";
export const DLP_BRANCH_CODES_REFERENCE_DATA_GET_VALUE =
DLP_APPLICATION_REFERENCE_DATA+"branchCodeReferenceData/getValue/";
export const DLP_BANK_REFERENCE_DATA_SET_VALUE =
DLP_APPLICATION_REFERENCE_DATA+ "bankReferenceData/setValue/";
export const DLP_BRANCH_CODES_REFERENCE_DATA_SET_VALUE =
DLP_APPLICATION_REFERENCE_DATA+"branchCodeReferenceData/setValue/";

export const setApplicationReferenceData = (value) => ({
  type: DLP_APPLICATION_REFERENCE_DATA_SET_VALUE,
  value,
});
export const getApplicationReferenceData = () => ({
  type: DLP_APPLICATION_REFERENCE_DATA_GET_VALUE,
});
export const getBankReferenceData = () => ({  
  type: DLP_BANK_REFERENCE_DATA_GET_VALUE,
});
export const getBranchCodeReferenceData = (value) => ({
  type: DLP_BRANCH_CODES_REFERENCE_DATA_GET_VALUE,
  value
});
export const setBankReferenceData = (value) => ({  
  type: DLP_BANK_REFERENCE_DATA_SET_VALUE,
  value
});
export const setBranchCodeReferenceData = (value) => ({
  type: DLP_BRANCH_CODES_REFERENCE_DATA_SET_VALUE,
  value
});

// Branch Code Data
export const DLP_BRANCH_CODES_REFERENCE_DATA_API_STATUS = DLP_APPLICATION_REFERENCE_DATA + 'branchCodeReferenceData/setApiStatus';

export const setBranchCodesApiStatus = (value) => ({
    type: DLP_BRANCH_CODES_REFERENCE_DATA_API_STATUS,
    value
});


export const RESET_REFERENCE_DATA =
  "digiLifeLoanProtection/referenceData/setValue/resetState";
export const resetReferenceData = () => ({ type: RESET_REFERENCE_DATA });
