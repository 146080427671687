import {  validateNumber, validateCoverYears, validateDateOfBirthNextBirthDay} from  "@om/validation";
import {
    setCoverAmountValid,
    COVER_AMOUNT_SET_VALUE,
    setCoverYearsValid,
    COVER_YEARS_SET_VALUE,
    setCandidateDobValid,
    CANDIDATE_DOB_SET_VALUE
} from "../../../actions/roaQuoteTools/accidentalDisabilityQuoteTool";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const accidentalDisabilityQuoteToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            
            case CANDIDATE_DOB_SET_VALUE:
                error = validateDateOfBirthNextBirthDay(action.value, { min: 15, max: 64, pronoun: 'your' });
                return setCandidateDobValid(error);
            case COVER_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, { min: 200000, max: 15000000, pronoun: 'cover amout value' });
                return setCoverAmountValid(error);
            case COVER_YEARS_SET_VALUE:
                const {roaQuoteTools:{accidentalDisabilityQuoteTool:{form: {candidateDob}}}} = store.getState()
                error = validateCoverYears(action.value, { min: 10, max: 50, dateValue:candidateDob.value, termExpiry: 80 });
                return setCoverYearsValid(error);
            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
