import { transitionBiasharaFlexiEmployeeDetails } from "../../../actions/biasharaFlexi/employeeDetails";
import { FIELD_STATUS } from "../../../reducers/status";
import { getEmployeeDetailsForm } from "../../../selectors/biasharaFlexi/employeeDetails";

export const employeeDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const employeeDetailsForm = getEmployeeDetailsForm(store.getState());
    const employeeDetailsFormValid = validateEmployeeDetailsFormDetails(employeeDetailsForm);
    const formAction = employeeDetailsFormValid
        ? transitionBiasharaFlexiEmployeeDetails.formValid()
        : transitionBiasharaFlexiEmployeeDetails.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateEmployeeDetailsFormDetails = (formFields) => {
	return (
		formFields.firstName &&
		formFields.firstName.status === FIELD_STATUS.VALID &&
		formFields.surname &&
		formFields.surname.status === FIELD_STATUS.VALID &&
		formFields.dateOfBirth &&
		formFields.dateOfBirth.status === FIELD_STATUS.VALID &&
		formFields.employmentDate &&
		formFields.employmentDate.status === FIELD_STATUS.VALID &&
		formFields.mobileNumber &&
		formFields.mobileNumber.status === FIELD_STATUS.VALID &&
		formFields.idPassportNumber &&
		formFields.idPassportNumber.status === FIELD_STATUS.VALID &&
		formFields.kraPinNumber &&
		formFields.kraPinNumber.status === FIELD_STATUS.VALID
	);
};

