export const HEALTH_INSURANCE_RW_PAYLOAD_COLLECTION = 'healthInsuranceRw/collection/';

// API Calls actions
export const API_PENDING_TRANSITION_PAYLOAD_COLLECTION = `${HEALTH_INSURANCE_RW_PAYLOAD_COLLECTION}apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PAYLOAD_COLLECTION = `${HEALTH_INSURANCE_RW_PAYLOAD_COLLECTION}apiTransition/success`;
export const API_FAILURE_TRANSITION_PAYLOAD_COLLECTION = `${HEALTH_INSURANCE_RW_PAYLOAD_COLLECTION}apiTransition/failure`;

export const apiTransitionPayloadCollection = {
	pending: () => ({
		type: API_PENDING_TRANSITION_PAYLOAD_COLLECTION,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_PAYLOAD_COLLECTION,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_PAYLOAD_COLLECTION,
	}),
};

//calculated Data
export const COLLECT_PAYLOAD_DATA = HEALTH_INSURANCE_RW_PAYLOAD_COLLECTION + 'collectPayloadData';
export const SUBMIT_COLLECT_PAYLOAD_SUCCESS = HEALTH_INSURANCE_RW_PAYLOAD_COLLECTION + 'success';
export const SUBMIT_COLLECT_PAYLOAD_FAILURE = HEALTH_INSURANCE_RW_PAYLOAD_COLLECTION + 'failure';

export const collectPayloadData = () => ({
	type: COLLECT_PAYLOAD_DATA,
});
export const submitPayloadDataSuccess = (value) => ({
	type: SUBMIT_COLLECT_PAYLOAD_SUCCESS,
	value,
});
export const submitPayloadDataFailure = (error) => ({
	type: SUBMIT_COLLECT_PAYLOAD_FAILURE,
	error,
});
