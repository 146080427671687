import { isNullOrEmpty } from "../../../../site-builder/src/util";
import { asisaUnclaimedBenefitsTransitions } from "../../../actions/publicWeb/asisaUnclaimedBenefits";
import { FIELD_STATUS } from "../../../reducers/status";
import {
  getDateOfBirth,
  getFirstname,
  getRecaptcha,
  getSAIdNumber,
  getSurname,
  getGender,
  hasAcceptedTermsAndConditions,
  hasValidSaIdNumber,
} from "../../../selectors/publicWeb/asisaUnclaimedBenefits";

const isValid = (field) => {
  return field && field.status === FIELD_STATUS.VALID;
};

const validateAsisaUnclaimedBenefits = (state) => {
  const hasValidSaIdNumbers = hasValidSaIdNumber(state);
  const hasAcceptedTermsAndCondition = hasAcceptedTermsAndConditions(state);
  const saIdNumber = getSAIdNumber(state);
  const firstname = getFirstname(state);
  const surname = getSurname(state);
  const dateOfBirth = getDateOfBirth(state);
  const gender = getGender(state)
  const recaptcha = getRecaptcha(state).value;

  if (hasValidSaIdNumbers) {
    return isValid(saIdNumber) && hasAcceptedTermsAndCondition && !isNullOrEmpty(recaptcha);
  }

  return (
    isValid(firstname) &&
    isValid(surname) &&
    isValid(dateOfBirth) &&
    !isNullOrEmpty(gender.value) &&
    hasAcceptedTermsAndCondition &&
    !isNullOrEmpty(recaptcha)
  );
};
export const asisaUnclaimedBenefitsFormRules = (store, next, action) => {
  const result = next(action);
  const asisaUnclaimedBenefitsValid = validateAsisaUnclaimedBenefits(
    store.getState()
  );
  store.dispatch(
    asisaUnclaimedBenefitsValid
      ? asisaUnclaimedBenefitsTransitions.formValid()
      : asisaUnclaimedBenefitsTransitions.formInvalid()
  );
  return result;
};
