import {
    TOGGLE_DECLARATIONS_CONFIRM_DETAILS,
    DECLARATIONS_SIDEDRAWER_SET_VALUE,
    SIDE_DRAWER_CLOSED_SET_VALUE,
} from "../../../actions/easiplusFuneralPlan/confirmDetails";
import {
    getDeclarationsSideDrawerOpen,
    getDeclarationsValue
} from "../../../selectors/easiplusFuneralPlan/confirmDetails";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";
import {onItemChangeStateComparison} from "../onFieldChangeStateComparison"

export const confirmDetailsOtherTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;

    const fieldSelector = (() => {
        switch (action.type){
            case TOGGLE_DECLARATIONS_CONFIRM_DETAILS:
                otherEventIndex = findOtherEventIndex("TOGGLE_CHECKBOX");
                return toggleButtonTransitionTrackingHandler(prevState, state, getDeclarationsValue)
            case DECLARATIONS_SIDEDRAWER_SET_VALUE:
                otherEventIndex = findOtherEventIndex("OPEN_DRAWER");
                return getDeclarationsSideDrawerOpen(state) ? "declarationsSideDrawerOpen": null;
            case SIDE_DRAWER_CLOSED_SET_VALUE:
                otherEventIndex = findOtherEventIndex("CLOSE_DRAWER");
                return getDeclarationsSideDrawerOpen(state) ? "declarationsSideDrawerClosed": null;
            default: return null;
        }
    })();

    if(fieldSelector)
        otherEventTracking(action.type, otherEventIndex, fieldSelector);

    return result;
};

const toggleButtonTransitionTrackingHandler = (prevState, state, selector) => {
    const onFieldChange = onItemChangeStateComparison(prevState, state);
    const field = onFieldChange(selector);
    return field === true ? selector(state) : false;
};