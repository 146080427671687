import { ROA_TRAVEL_PLAN_SET_VALUE } from '../../../actions/roaTravelInsurance/travelPlan';
import { travelPlanFormValidation } from './travelPlan';

import { TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE } from '../../../actions/roaTravelInsurance/aboutYou';
import { aboutYouValidation } from './aboutYou';

import { TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE } from '../../../actions/roaTravelInsurance/travelDetails';
import { travelDetails } from './travelDetails';

import { TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/roaTravelInsurance/travelAgentModal';
import { agentModalFormValidation } from './travelAgentModal';

import { TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE } from '../../../actions/roaTravelInsurance/addTraveller';
import { addTravellerDetails } from './payments';
import { ROA_TRAVEL_PLANS_FORM_SET_VALUE } from '../../../actions/roaTravelInsurance/plans';
import { plans } from './plans';

import { TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE } from '../../../actions/roaTravelInsurance/manualMpesaPayments';
import { manualPayments } from './manualPayments';

export const roaTravelInsuranceValidation = (store, next, action) => {
    if(action.type.startsWith(TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE))
        return manualPayments(store, next, action);

    if (action.type.startsWith(ROA_TRAVEL_PLAN_SET_VALUE))
        return travelPlanFormValidation(store, next, action);

    if (action.type.startsWith(TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE))
        return aboutYouValidation(store, next, action);

    if (action.type.startsWith(TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE))
        return travelDetails(store, next, action);

    if (action.type.startsWith(TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE))
        return agentModalFormValidation(store, next, action);

    if (action.type.startsWith(TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE))
        return  addTravellerDetails(store, next, action);
    if (action.type.startsWith(ROA_TRAVEL_PLANS_FORM_SET_VALUE))
        return plans(store, next, action);

    return null;
};
