import { transitionPaymentsForm } from '../../../actions/personalPensionPlan/payments';
import { getPaymentsForm } from '../../../selectors/personalPensionPlan/payments';
import { FIELD_STATUS } from '../../../reducers/status';

export const paymentsFormRules = (store, next, action) => {
    const result = next(action);
    const paymentsForm = getPaymentsForm(store.getState());
    const paymentsFormValid = validatePaymentsFormDetails(paymentsForm);
    const paymentsFormAction = paymentsFormValid
        ? transitionPaymentsForm.formValid()
        : transitionPaymentsForm.formInvalid();
    store.dispatch(paymentsFormAction);

    return result;
};

const validatePaymentsFormDetails = (formFields) => {
    const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

    if (gatsbyStack === 'ug') {
        return (
            formFields.phoneNumber &&
            formFields.phoneNumber.status === FIELD_STATUS.VALID &&
            formFields.terms &&
            formFields.terms.status === FIELD_STATUS.VALID &&
            formFields.networkProvider &&
            formFields.networkProvider.status === FIELD_STATUS.VALID
        );
    }
    if (gatsbyStack === 'ke') {
        return (
            formFields.phoneNumber &&
            formFields.phoneNumber.status === FIELD_STATUS.VALID &&
            formFields.terms &&
            formFields.terms.status === FIELD_STATUS.VALID
        );
    }
};
