import { FIELD_STATUS, TOGGLE_STATUS } from "../status";
import {
    REGULAR_CONTRIBUTION_RESET_VALUE,
    REGULAR_CONTRIBUTION_SET_VALID,
    REGULAR_CONTRIBUTION_SET_VALUE,
    LUMP_SUM_CONTRIBUTION_RESET_VALUE,
    LUMP_SUM_CONTRIBUTION_SET_VALID,
    LUMP_SUM_CONTRIBUTION_SET_VALUE,
    CONTRIBUTION_SET_VALID,
    CONTRIBUTION_RESET_VALUE,
    OWN_PERCENTAGE_RESET_VALUE,
    OWN_PERCENTAGE_SET_VALID,
    OWN_PERCENTAGE_SET_VALUE,
    VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION,
    LIMIT_EXCEEDED_TRANSITION_INVESMENT_SETUP_CONTRIBUTION,
    CONTRIBUTION_SET_VALUE,
    PREMIUM_ESCALATION_RESET_VALUE,
    PREMIUM_ESCALATION_SET_VALID,
    PREMIUM_ESCALATION_SET_VALUE,
    RESET_INVESTMENT_SETUP_CONTRIBUTION
} from "../../actions/taxFreeSavingsAccount/investmentSetupContribution";

export const initInvestmentSetupContribution = {
    viewMoreExpanded: TOGGLE_STATUS.NO,
    displayLimitExceededModal: TOGGLE_STATUS.NO,
    form: {
        contributionType: { value: null, status: null, error: null },
        regularContributionValue: { value: null, error: null, status: null },
        lumpSumContributionValue: { value: null, error: null, status: null },
        premiumEscalation: { value: null, error: null, status: null },
        ownPercentage: { value: null, error: null, status: null },
    },
};

export default (state = initInvestmentSetupContribution, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case RESET_INVESTMENT_SETUP_CONTRIBUTION:
            return { ...initInvestmentSetupContribution };

        case VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION:
            return { ...state, viewMoreExpanded: state.viewMoreExpanded === TOGGLE_STATUS.YES ? TOGGLE_STATUS.NO : TOGGLE_STATUS.YES }

        case LIMIT_EXCEEDED_TRANSITION_INVESMENT_SETUP_CONTRIBUTION:
            return { ...state, displayLimitExceededModal: action.value }

        // Contribution
        case CONTRIBUTION_SET_VALUE:
            return updateFormField('contributionType', field => ({
                ...field,
                value: action.value,
            }));
        case CONTRIBUTION_SET_VALID:
            return updateFormField('contributionType', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CONTRIBUTION_RESET_VALUE:
            return updateFormField('contributionType', field => ({
                ...field,
                error: null,
                status: null,
                value: null,
            }));

        case REGULAR_CONTRIBUTION_SET_VALUE:
            return updateFormField('regularContributionValue', field => ({
                ...field,
                value: action.value,
            }));
        case REGULAR_CONTRIBUTION_SET_VALID:
            return updateFormField('regularContributionValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case REGULAR_CONTRIBUTION_RESET_VALUE:
            return updateFormField('regularContributionValue', field => ({
                ...field,
                error: null,
                status: null,
                value: null,
            }));

        case LUMP_SUM_CONTRIBUTION_SET_VALUE:
            return updateFormField('lumpSumContributionValue', field => ({
                ...field,
                value: action.value,
            }));
        case LUMP_SUM_CONTRIBUTION_SET_VALID:
            return updateFormField('lumpSumContributionValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case LUMP_SUM_CONTRIBUTION_RESET_VALUE:
            return updateFormField('lumpSumContributionValue', field => ({
                ...field,
                error: null,
                status: null,
                value: null,
            }));

        case PREMIUM_ESCALATION_SET_VALUE:
            return updateFormField('premiumEscalation', field => ({
                ...field,
                value: action.value,
            }));
        case PREMIUM_ESCALATION_SET_VALID:
            return updateFormField('premiumEscalation', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PREMIUM_ESCALATION_RESET_VALUE:
            return updateFormField('premiumEscalation', field => ({
                ...field,
                error: null,
                status: null,
                value: null,
            }));

        case OWN_PERCENTAGE_SET_VALUE:
            return updateFormField('ownPercentage', field => ({
                ...field,
                value: action.value,
            }));
        case OWN_PERCENTAGE_SET_VALID:
            return updateFormField('ownPercentage', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case OWN_PERCENTAGE_RESET_VALUE:
            return updateFormField('ownPercentage', field => ({
                ...field,
                error: null,
                status: null,
                value: null,
            }));
        default:
            return state;
    }
};
