import { createSelector } from "reselect";
import { getTaxFreeSavingsAccount } from "./index";
import { FORM_STATUS, FIELD_STATUS } from "../../reducers/status";
import { INVESTMENT_SETUP_INITIAL_SAVING_FOR, GUARDIAN_TYPES } from "../../actions/taxFreeSavingsAccount/types";

export const getInvestmentSetupInitial= createSelector(getTaxFreeSavingsAccount, tfsa => tfsa.investmentSetupInitial);

export const getInvestmentSetupInitialForm = createSelector(
    getInvestmentSetupInitial,
    investmentSetupInitial => investmentSetupInitial.form,
);

// Field selectors
const makeGetField = fieldId => createSelector(
    getInvestmentSetupInitialForm,
    investmentSetupInitialForm => investmentSetupInitialForm[fieldId]
);

export const getOnBehalfOfChild = makeGetField('onBehalfOfChild');
export const getHasOtherTaxFreePlan= makeGetField('hasOtherTaxFreePlan');
export const getLegalGuardian= makeGetField('legalGuardian');
export const getSavingFor = makeGetField('savingFor');
export const getYourGoal = makeGetField('yourGoal');

export const getSavingForMinor = createSelector(
    getOnBehalfOfChild,
    (behalfOfChild) => behalfOfChild.value === "true"
);

// Calculated Selectors
// Determines whether or not to show the other plan section of page
// If onBehalfOfChild is true, it will check if legalGuardian is also valid
export const getOtherPlanDisclosureIsValid = createSelector(
    getOnBehalfOfChild, getLegalGuardian,
    (onBehalfOfChild, legalGuardian) => {
        return onBehalfOfChild.status === FORM_STATUS.VALID && onBehalfOfChild.value === "true"
            ? legalGuardian.status === FORM_STATUS.VALID
            : onBehalfOfChild.status === FORM_STATUS.VALID
    }
);

// Determines whether or not to show the saving for section of page
export const getSavingForProgressiveDisclosureIsValid = createSelector(
    getOtherPlanDisclosureIsValid,
    getHasOtherTaxFreePlan,
    (otherPlanDisclosureIsValid, hasOtherTaxFreePlan) => otherPlanDisclosureIsValid &&
    hasOtherTaxFreePlan.status === FORM_STATUS.VALID &&
    hasOtherTaxFreePlan.value === "false"
);

// Determines whether or not to show alternative solutions section
export const getAlternativeSolutionsProgressiveDisclosureIsValid = createSelector(
    getOtherPlanDisclosureIsValid,
    getHasOtherTaxFreePlan,
    (otherPlanDisclosureIsValid, hasOtherTaxFreePlan) => otherPlanDisclosureIsValid &&
    hasOtherTaxFreePlan.status === FORM_STATUS.VALID &&
    hasOtherTaxFreePlan.value === "true"
);

export const getSavingForGeneral = createSelector(
    getSavingFor,
    savingFor => savingFor === INVESTMENT_SETUP_INITIAL_SAVING_FOR.GENERAL
);

export const getSavingForRetirement = createSelector(
    getSavingFor,
    savingFor => savingFor.value === INVESTMENT_SETUP_INITIAL_SAVING_FOR.RETIREMENT
);

export const getSavingForEducation = createSelector(
    getSavingFor,
    savingFor => savingFor.value === INVESTMENT_SETUP_INITIAL_SAVING_FOR.EDUCATION
);

export const getValidateYourGoal = createSelector(
    getInvestmentSetupInitialForm,
    form => {
        return form.yourGoal.value && form.yourGoal.value.length > 0
        ? form.yourGoal.status === FIELD_STATUS.VALID
        : true;
    }
);

export const getIsParent = createSelector(
    getLegalGuardian,
    (legalGuardian) => legalGuardian.value === GUARDIAN_TYPES.PARENT,
);

export const getIsLegalGuardian = createSelector(
    getLegalGuardian,
    (legalGuardian) => legalGuardian.value === GUARDIAN_TYPES.LEGAL_GUARDIAN,
);

export const getIsInvestmentSetupInitialFormValid = createSelector(
    getValidateYourGoal,
    getInvestmentSetupInitialForm,
    (validateGoal, form) => {
        // Validate on behalf of child
        if (form.onBehalfOfChild.value === "true" &&
            form.legalGuardian.status !== FIELD_STATUS.VALID) {
                return false;
        }

        return form.onBehalfOfChild.status === FIELD_STATUS.VALID &&
        form.hasOtherTaxFreePlan.status === FIELD_STATUS.VALID &&
        form.hasOtherTaxFreePlan.value === "false" &&
        form.savingFor.status === FIELD_STATUS.VALID &&
        validateGoal;
    }
);

export const getInvestmentSetupInitialFormIsValid = createSelector(
    getOnBehalfOfChild,
    getHasOtherTaxFreePlan,
    getLegalGuardian,
    getSavingFor,
    getYourGoal,
    (
        onBehalfOfChild,
        hasOtherTaxFreePlan,
        legalGuardian,
        savingFor,
        yourGoal
    ) => {
        let isValid = true;
        if (onBehalfOfChild.value === 'true') {
            isValid = isValid && legalGuardian.status === FORM_STATUS.VALID;
        }
        isValid = isValid && hasOtherTaxFreePlan.status === FORM_STATUS.VALID && 
                    onBehalfOfChild.status === FORM_STATUS.VALID &&
                    savingFor.status === FORM_STATUS.VALID;
        isValid = isValid && (yourGoal.status === FORM_STATUS.VALID || yourGoal.status === null);

        return isValid;
    }
);