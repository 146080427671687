export const PERSONAL_ACCIDENT_CHILD_DETAILS_FORM = 'personalAccident/childDetailsForm/';

export const PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM + 'setValue/';
export const PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM + 'setValid/';

export const FORM_VALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM = 'personalAccident/childDetailsForm/transition/formValid';
export const FORM_INVALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM =
    'personalAccident/childDetailsForm/transition/formInValid';

export const REINITIALIZE = 'personalAccident/childDetailsForm/state/reinitialize';
export const REINITIALIZE_SINGLE = 'personalAccident/childDetailsForm/state/reinitializeSingle';

export const ADD_CHILD = 'personalAccident/childDetailsForm/state/addChild';
export const REMOVE_CHILD = 'personalAccident/childDetailsForm/state/removeChild';

export const transitionPersonalAccidentChildDetailsForm = {
    formValid: (index) => ({
        type: FORM_VALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM,
        index,
    }),
    formInvalid: (index) => ({
        type: FORM_INVALID_PERSONAL_ACCIDENT_CHILD_DETAILS_FORM,
        index,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_CHILD_DETAILS_FORM = `${PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_CHILD_DETAILS_FORM = `${PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_CHILD_DETAILS_FORM = `${PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionChildDetailsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_CHILD_DETAILS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_CHILD_DETAILS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_CHILD_DETAILS_FORM,
    }),
};

export const SELECTED_CHILD_FORM_INDEX_SET_VALUE =
    PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'selectedChildFormIndex';

export const FIRST_NAME_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID + 'firstName';

export const LAST_NAME_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'lastName';
export const LAST_NAME_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID + 'lastName';

export const DOB_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID + 'DOB';

export const STREET_ADDRESS_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'streetAddress';
export const STREET_ADDRESS_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID + 'streetAddress';

export const SUBURB_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'suburb';
export const SUBURB_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID + 'suburb';

export const CITY_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'city';
export const CITY_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID + 'city';

export const POSTAL_ADDRESS_SET_VALUE = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE + 'postalAddress';
export const POSTAL_ADDRESS_SET_VALID = PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALID + 'postalAddress';

export const setSelectedChildFormIndex = (value) => ({
    type: SELECTED_CHILD_FORM_INDEX_SET_VALUE,
    value,
});

export const setFirstNameValue = (index, value) => ({
    type: FIRST_NAME_SET_VALUE,
    index,
    value,
});
export const setFirstNameValid = (index, error) => ({
    type: FIRST_NAME_SET_VALID,
    index,
    error,
});

export const setLastNameValue = (index, value) => ({
    type: LAST_NAME_SET_VALUE,
    index,
    value,
});
export const setLastNameValid = (index, error) => ({
    type: LAST_NAME_SET_VALID,
    index,
    error,
});

export const setDOBValue = (index, value) => ({
    type: DOB_SET_VALUE,
    index,
    value,
});
export const setDOBValid = (index, error) => ({
    type: DOB_SET_VALID,
    index,
    error,
});

export const setStreetAddressValue = (index, value) => ({
    type: STREET_ADDRESS_SET_VALUE,
    index,
    value,
});

export const setStreetAddressValid = (index, error) => ({
    type: STREET_ADDRESS_SET_VALID,
    index,
    error,
});

export const setSuburbValue = (index, value) => ({
    type: SUBURB_SET_VALUE,
    index,
    value,
});

export const setSuburbValid = (index, error) => ({
    type: SUBURB_SET_VALID,
    index,
    error,
});

export const setCityValue = (index, value) => ({
    type: CITY_SET_VALUE,
    index,
    value,
});

export const setCityValid = (index, error) => ({
    type: CITY_SET_VALID,
    index,
    error,
});

export const setPostalAddressValue = (index, value) => ({
    type: POSTAL_ADDRESS_SET_VALUE,
    index,
    value,
});

export const setPostalAddressValid = (index, error) => ({
    type: POSTAL_ADDRESS_SET_VALID,
    index,
    error,
});

export const initializeChildDetailsForms = () => ({
    type: REINITIALIZE,
});

export const initializeSingleChildDetailsForm = (index) => ({
    type: REINITIALIZE_SINGLE,
    index,
});

export const addChild = () => ({
    type: ADD_CHILD,
});

export const removeChild = (index) => ({
    type: REMOVE_CHILD,
    index,
});
