import { getQuoteForm } from '../../../selectors/personalPensionPlan/quote';
import { getPersonalDetailsForm } from '../../../selectors/personalPensionPlan/personalDetails';
import { getDocumentsForm } from '../../../selectors/personalPensionPlan/documents';
import { getBeneficiariesForms } from '../../../selectors/personalPensionPlan/beneficiaries';
import { getNextOfKinForm } from '../../../selectors/personalPensionPlan/nextOfKin';
import { getIsApiPending } from '../../../selectors/personalPensionPlan/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/personalPensionPlan/payloadCollection';
import { getAgentForm } from '../../../selectors/personalPensionPlan/agentModal';
import { getIsLumpsumPrepaidValue, getPremiumCalculation } from '../../../selectors/personalPensionPlan/quote';
import {
    getAccessToken,
    getPaymentStatusData,
    getPaymentsForm,
    getPaymentMode,
} from '../../../selectors/personalPensionPlan/payments';
import axios from 'axios';
import {
    triggerConfirmOrder,
    setBankDetailsSuccess,
    setPrepaidSubmitSuccess,
} from '../../../actions/personalPensionPlan/payments';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await makeSubmission(store.getState());

                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());

                const isPrepaidLumpsum = getIsLumpsumPrepaidValue(store.getState());

                if (isPrepaidLumpsum?.value === 'yes') {
                    store.dispatch(setPrepaidSubmitSuccess(true));
                    store.dispatch(triggerConfirmOrder());
                } else {
                    const modeOfPayment = getPaymentMode(store.getState());

                    if (modeOfPayment === 'Mpesa') {
                        store.dispatch(triggerConfirmOrder());
                    } else {
                        store.dispatch(setBankDetailsSuccess(true));
                    }
                }
            } catch (e) {
                console.error(e);
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

const createBeneficiaries = (beneficiariesDetails) => {
    const beneficiaries = [];

    for (let i = 0; i < beneficiariesDetails.length; i++) {
        const beneficiary = beneficiariesDetails[i];

        beneficiaries.push({
            firstName: beneficiary.firstName?.value ? beneficiary.firstName?.value : 'N/A',
            lastName: beneficiary.lastName?.value ? beneficiary.lastName?.value : 'N/A',
            idNumber: beneficiary.idNumber?.value ? beneficiary.idNumber?.value : 'N/A',
            relationship: beneficiary.relationship?.value ? beneficiary.relationship?.value : 'N/A',
            location: beneficiary.location?.value ? beneficiary.location?.value : 'N/A',
            emailAddress: beneficiary.email?.value ? beneficiary.email?.value : 'N/A',
            percentageSplit: beneficiary.percentageSplit?.value ? beneficiary.percentageSplit?.value : 0,
            phoneNumber: beneficiary.phoneNumber?.value ? beneficiary.phoneNumber?.value : 'N/A',
        });
    }

    return beneficiaries;
};

function calculateNextBirthdayAge(birthdate) {
    const [day, month, year] = birthdate.split('/').map(Number);

    const birthDate = new Date(year, month - 1, day);

    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    const currentYearBirthday = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
    if (today < currentYearBirthday) {
        age--;
    }

    return age + 1;
}

const payloadCollection = (state) => {
    const agent = getAgentForm(state);
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const documents = getDocumentsForm(state);
    const beneficiariesDetails = getBeneficiariesForms(state);
    const nextOfKin = getNextOfKinForm(state);
    const paymentsData = getPaymentStatusData(state);

    const paymentDetailsData = getPaymentsForm(state);

    const premiumCalculation = getPremiumCalculation(state);

    const agentPayload = {
        agentCode: gatsbyStack === 'ke' ? agent.name?.value?.agentCode || 'N/A' : agent.agentCode?.value || 'N/A',
        agentName: gatsbyStack === 'ke' ? agent.name?.value?.name || 'N/A' : agent.name?.value || 'N/A',
        referredByAgent: gatsbyStack === 'ke' && agent.name?.value?.name ? true : agent.name?.value ? true : false,
    };

    const beneficiaries = createBeneficiaries(beneficiariesDetails);

    const modeOfPayment = getPaymentMode(state);

    const paidAmount =
        quotation?.contributionMode?.value === 'monthly-contribution-only'
            ? quotation?.desiredMonthlyContribution?.value
            : quotation?.lumpsumAmount?.value;

    const payload = {
        countryCode: gatsbyStack.toUpperCase(),
        referrer: `${agentPayload.agentCode}`,
        plan: 'Personal Pension Plan(Segregated)',
        ageNextBirthDay: calculateNextBirthdayAge(personalDetails.dob.value),
        monthlySalary: 0,
        annualSalary: 0,
        monthlyPremium: premiumCalculation?.value?.monthPremium ? premiumCalculation?.value?.monthPremium : 0,
        futureValue: premiumCalculation?.value?.futureValue ? premiumCalculation?.value?.futureValue : 0,

        firstName: personalDetails.firstName.value,
        lastName: personalDetails.lastName.value,
        dateOfBirth: personalDetails.dob.value,
        gender: quotation.gender.value,
        occupation: personalDetails.occupation.value,
        idNumber: personalDetails.idNumber.value,
        kraPin: personalDetails.tinNumber.value,
        phoneNumber: personalDetails.phoneNumber.value,
        emailAddress: personalDetails.email.value,
        postalAddress: 'N/A',
        idNumberDoc: documents.idUpload.value.base64File,
        kraPinDoc: documents.kraPinUpload.value.base64File,
        transactionId:
            quotation.isLumpsumPrepaid?.value === 'yes'
                ? quotation.prepaidTransactionCode?.value
                    ? quotation.prepaidTransactionCode?.value
                    : 'N/A'
                : modeOfPayment === 'Mpesa'
                ? paymentsData?.CheckoutRequestID
                    ? paymentsData?.CheckoutRequestID
                    : 'N/A'
                : 'N/A',
        modeOfPayment,
        isLumpsumPrepaid: quotation.isLumpsumPrepaid?.value === 'yes' ? true : false,
        mpesaPhoneNumber:
            modeOfPayment === 'Mpesa'
                ? paymentDetailsData.phoneNumber?.value
                    ? paymentDetailsData.phoneNumber?.value
                    : 'N/A'
                : 'N/A',
        crmPulled: false,
        lumpsum: quotation.lumpsumAmount?.value ? quotation.lumpsumAmount?.value : '0',
        monthlyContribution: premiumCalculation?.value?.monthPremium
            ? premiumCalculation?.value?.monthPremium.toString()
            : '0',
        paidAmount: modeOfPayment === 'Mpesa' ? paidAmount : '0',
        nextOfKin: {
            firstName: nextOfKin.firstName?.value || 'N/A',
            lastName: nextOfKin.lastName?.value || 'N/A',
            idNumber: nextOfKin.idNumber?.value || 'N/A',
            relationship: nextOfKin.relationship?.value || 'N/A',
            phoneNumber: nextOfKin.phoneNumber?.value || 'N/A',
            emailAddress: nextOfKin.email?.value || 'N/A',
        },
        beneficiaries,
    };

    return {
        ...payload,
    };
};

const makeSubmission = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/personal-pension-plan/submit-payload';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
