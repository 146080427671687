import axios from "axios";
import {
    getApplicableRepaymentTermsSuccess
} from "../../../actions/personalLoansCalculator/referenceData";
import { continueLoanAmountDetails } from "../../../actions/personalLoansCalculator/calculator";
import { getAmountBorrowed, getOtherAmountBorrowed } from "../../../selectors/personalLoansCalculator/calculator";
import { getLoanAmountOptions } from "../../../selectors/personalLoansCalculator/referenceData";
import { ownAmountMethod } from "../../rulesEngine/personalLoansCalculator/calculator";

export const applicableRepaymentTermService = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    const amountBorrowed = getAmountBorrowed(state);
    const otherAmountBorrowed = getOtherAmountBorrowed(state);
    const loanAmountOptions = getLoanAmountOptions(state);

    let capital = 0;
    if (amountBorrowed.value === ownAmountMethod(loanAmountOptions)) {
        capital = otherAmountBorrowed.value;
    } else {
        loanAmountOptions.forEach(amountOptions => {
            if (amountOptions.code === amountBorrowed.value) {
                capital = amountOptions.value
            }
        })
    }

    (async () => {
        const response = await getRepaymentTerms(capital);
        if (response.status === 200) {
            store.dispatch(getApplicableRepaymentTermsSuccess(response.data));
            store.dispatch(continueLoanAmountDetails());
        }
    })();
    return result;
}

const applicableRepaymentTermRequestBody = (capital) => {
    return {
        capital
    }
}

const getRepaymentTerms = async (capital) => {
    try {
        const response = await axios.post('/om-api/personal-loans/retrieve-applicable-repayment-terms',
            applicableRepaymentTermRequestBody(capital),
            { headers: { 'Content-Type': 'application/json' } });
        return response;
    } catch (error) {
        return false;
    }
}
