import { atRetirementTracking } from './atRetirement/index';
import { easiplusFuneralPlanTracking } from './easiplusFuneralPlan/index';
import { raOptimalTracking } from './raOptimal/index'
import { cvsTracking } from "./customerVerificationService";
import { publicWebTracking } from './publicWeb/index'
import { covidSelfAssessmentTracking } from "./covidSelfAssessment";
import { incomeTaxCalculatorTracking } from "./incomeTaxCalculator";
import { taxFreeSavingsAccountTracking } from "./taxFreeSavingsAccount";
import { retirementCalculatorTracking } from "./retirementCalculator"
import { personalLoansFormTracking } from './personalLoans/personalLoans';
import { priceTableTracking } from './priceTable';
import { customerComplaintTracking } from './customerComplaint';
import { budgetBenchmarkTracking } from './budgetBenchmarkingCalculator';
import {savingsCalculatorTracking } from './savingsCalculator'
import { pastPerfomanceCalculatorTracking } from './pastPerfomanceCalculator';
import {educationCalculatorTracking} from './educationCalculator';
import { fundDeclarationTracking } from './fundDeclaration';
import { debtCalculatorTracking } from './debtCalculator';
import { customersSatisfactionTracking } from './customerSatisfactionSurvey'
import { savingsPotCalculatorTracking } from './savingsPotCalculator';

export const tracking = store => next => action => {
    const executedRules =
        atRetirementTracking(store, next, action) ||
        raOptimalTracking(store, next, action) ||
        covidSelfAssessmentTracking(store, next, action) ||
        easiplusFuneralPlanTracking(store, next, action) ||
        incomeTaxCalculatorTracking(store, next, action) ||
        publicWebTracking(store, next, action) ||
        cvsTracking(store, next, action) ||
        taxFreeSavingsAccountTracking(store, next, action) ||
        retirementCalculatorTracking(store, next, action) ||
        savingsPotCalculatorTracking(store, next, action) ||
        priceTableTracking(store, next, action) ||
        customerComplaintTracking(store, next, action) ||
        budgetBenchmarkTracking(store, next, action) ||
        savingsCalculatorTracking(store, next, action) ||
        pastPerfomanceCalculatorTracking(store, next, action) ||
        educationCalculatorTracking(store, next, action) ||
        fundDeclarationTracking(store, next, action) ||
        debtCalculatorTracking(store, next, action) ||
        customersSatisfactionTracking(store, next, action) ||
        personalLoansFormTracking(store, next, action);

    if (!executedRules)
        return next(action);

    return executedRules;
};
