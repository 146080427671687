import axios from 'axios';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataFailure,
    submitPayloadDataSuccess,
} from '../../../actions/digitalSavings/payloadCollection';
import { triggerConfirmOrder } from '../../../actions/digitalSavings/payment';
import { getPersonalDetailsForm } from '../../../selectors/digitalSavings/personalDetails';
import { getQuoteForm, getPremiumCalculation } from '../../../selectors/digitalSavings/quote';
import { getBeneficiariesForms } from '../../../selectors/digitalSavings/beneficiaries';
import { getIsApiPending, getPayloadCollection } from '../../../selectors/digitalSavings/payloadCollection';
import { getAccessToken, getPaymentsForm, getPaymentStatusData } from '../../../selectors/digitalSavings/payment';
import { getAgentForm } from '../../../selectors/digitalSavings/agentModal';
import { getDocumentsForm } from '../../../selectors/digitalSavings/documents';

export const payloadSubmitService = (store, next, action) => {
    console.log(payloadCollection(store.getState(), action));
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await calculate(store.getState(), action);
                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());
                if(action.step === 'paid'){
                    store.dispatch(triggerConfirmOrder());
                }
            } catch (e) {
                console.log(e, 'jdkfsfhksf')
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

const payloadCollection = (state, action) => {
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const beneficiaries = getBeneficiariesForms(state);
    const documents = getDocumentsForm(state);
    const premium = getPremiumCalculation(state);
    const payments = getPaymentsForm(state);
    const agent = getAgentForm(state);
    const paymentsData = getPaymentStatusData(state);
    const payload = getPayloadCollection(state);

    function convertDateFormat(dateString) {
        try {
            // Split the date string into year, month, and day
            var parts = dateString.split('/');
            var day = parts[0];
            var month = parts[1];
            var year = parts[2];

            // Reformat the date string in the desired format
            var formattedDate = year + '/' + month + '/' + day;

            return formattedDate;
        } catch (error) {
            // Handle the case where the input string is not in the expected format
            return 'Invalid date format. Please use dd/mm/yyyy.';
        }
    }

    const premiumCalculationPayload = {
        rowId: payload.payloadData ? payload.payloadData.quotation.userQuote.rowId : null,
        orderStatus: action.step,
        investorType: quotation.attitude.value === '2' ? 'Comfortable' : 'Adventurous',
        investmentPlan: quotation.saving_category.value  || null,
        monthlyContribution: quotation.desired.value || null,
        lumpsumContribution: quotation.lumpsum.value || null,
        savingStartDate: convertDateFormat(quotation.startdate.value) || null,
        savingDuration: quotation.duration.value ? quotation.duration.value * 12 : null,
        totalPrincipalAmount: premium?.premium?.totalPrincipalAmount || null,
        potentialSavings: premium?.premium?.potentialSavingsMax || null,
    };

    const personalDetailsPayload = {
        quoteEmail: personalDetails.email.value || null,
        firstName: personalDetails.firstName.value || null,
        lastName: personalDetails.surname.value || null,
        dateOfBirth: convertDateFormat(personalDetails.dob.value) || null,
        gender: personalDetails.gender.value || null,
        nationality: personalDetails.nationality.value || null,
        occupation: personalDetails.occupation.value || null,
        sourceOfFunds: personalDetails.sourceOfFunds.value || null,
        identificationNumber: personalDetails.idPassport.value || null,
        kraNumber: personalDetails.kraPinNumber.value || null,
        phoneNumber: personalDetails.mobileNumber.value || null,
        emailAddress: personalDetails.email.value || null,
        postalAddress: personalDetails.fullAddress.value || null,
    };

    function transformData(input) {
        return input?.map((item) => ({
            isBeneficiaryMinor: item.isMinor.value || null,
            firstName: item.isMinor.value ? item.firstNameMinor.value : item.firstName.value,
            lastName: item.isMinor.value ? item.lastNameMinor.value : item.lastName.value,
            identificationNumber: item.idNumber.value || null,
            relationship: item.relationship.value || null,
            location: item.isMinor.value ? item.locationMinor.value : item.location.value,
            phoneNumber: item.phoneNumber.value || null,
            emailAddress: item.email.value || null,
            percentageSplit: item.percentageSplit.value || null,
            guardianFirstName: item.isMinor.value ? item.firstName.value : null,
            guardianLastName: item.isMinor.value ? item.lastName.value : null,
            guardianIdentificationNumber: item.isMinor.value ? item.idNumber.value : null,
            guardianRelationship: item.isMinor.value ? item.relationship.value : null,
            guardianLocation: item.isMinor.value ? item.location.value : null,
            guardianPhoneNumber: item.isMinor.value ? item.phoneNumber.value : null,
            guardianEmailAddress: item.isMinor.value ? item.email.value : null,
        }));
    }

    const beneficiariesPayload = {
        beneficiaries: beneficiaries.length > 0 ? transformData(beneficiaries) : [],
        dataConsentStatus: (beneficiaries.childDataProcessingConsent || beneficiaries.marketingUseConsent || beneficiaries.personalDataProcessingConsen),
    };

    const documentsPayload = {
        identificationDoc: documents.idUpload.value?.base64File || null,
        kraDoc: documents.kraPinUpload.value?.base64File || null,
    };

    const agentPayload = {
        referrer: [
            {
                referrer:  quotation.saving_category.value === 'monthly' ? null : agent.intermediaryType?.value === 'AGENTS' ? 'Agent' : agent.intermediaryType?.value === null ? null : 'Broker',
                agentCode: quotation.saving_category.value === 'monthly' ? null : agent.agentCode?.value || null,
                name: 'N/A',
                phoneNumber: 'N/A',
                emailAddress: 'N/A',
            },
        ],
    };

    const paymentPayload = {
        countryCode: 'KE',
        paymentMethod:
            payments.networkProvider.value === 'mpesa'
                ? 'mpesa'
                : payments.networkProvider.value === 'bankTransfer'
                ? 'bank'
                : null,
        orderNumber: payload.payloadData?.responseObj?.orderNumber || null,
        paymentReference:
            typeof paymentsData?.CheckoutRequestID === 'undefined' ? null : paymentsData?.CheckoutRequestID,
        mpesaPaymentNumber: payments?.phoneNumber?.value,
        paymentTermsAcceptStatus: payments.terms.value,
        bankPaymentAccountNumber: null,
    };

    return {
        ...premiumCalculationPayload,
        ...personalDetailsPayload,
        ...beneficiariesPayload,
        ...documentsPayload,
        ...agentPayload,
        ...paymentPayload,
    };
};

const calculate = async (state, action) => {
    const loginData = getAccessToken(state)

    try {
        const url = '/om-api/digital-savings/submit-payload';
        return await axios.post(url, payloadCollection(state, action), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.log(e)
        throw e;
    }
};
