import {
    TITLE_SET_VALUE,
    FIRST_NAMES_SET_VALUE,
    SURNAME_SET_VALUE,
    ID_SET_VALUE,
    MOBILE_SET_VALUE,
    EMAIL_SET_VALUE,
    COMPLEX_SET_VALUE,
    STREET_ADDRESS_SET_VALUE,
    SUBURB_SET_VALUE,
    CITY_SET_VALUE,
    POSTAL_CODE_SET_VALUE,
    COUNTRY_SET_VALUE,
    POSTAL_ADDRESS_METHOD_VALUE,
    POSTAL_ADDRESS_SAME_VALUE,
    PO_BOX_SET_VALUE,
    PO_POST_OFFICE_SET_VALUE,
    PO_POSTAL_CODE_SET_VALUE,
    PO_COUNTRY_SET_VALUE,
    PRIVATE_BAG_SET_VALUE,
    PRIVATE_POST_OFFICE_SET_VALUE,
    PRIVATE_POSTAL_CODE_SET_VALUE,
    PRIVATE_COUNTRY_SET_VALUE,
    POSTAL_COMPLEX_SET_VALUE,
    POSTAL_STREET_ADDRESS_SET_VALUE,
    POSTAL_SUBURB_SET_VALUE,
    POSTAL_CITY_SET_VALUE,
    POSTAL_POSTAL_CODE_SET_VALUE,
    POSTAL_COUNTRY_SET_VALUE,
} from "../../../actions/easiplusFuneralPlan/personalDetails";
import {
    getTitle,
    getFirstNames,
    getSurname,
    getId,
    getMobile,
    getEmail,
    getComplex,
    getStreetAddress,
    getSuburb,
    getCity,
    getPostalCode,
    getCountry,
    getPoBox,
    getPoPostOffice,
    getPoPostalCode,
    getPoCountry,
    getPrivateBag,
    getPrivatePostOffice,
    getPrivatePostalCode,
    getPrivateCountry,
    getPostalComplex,
    getPostalStreetAddress,
    getPostalSuburb,
    getPostalCity,
    getPostalPostalCode,
    getPostalCountry,
    getPostalAddressMethod,
    getPostalAddressSame,
} from "../../../selectors/easiplusFuneralPlan/personalDetails";
import { simpleFieldTracking } from "../formTracking";
import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";

export const personalDetailsFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case TITLE_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getTitle;
            case FIRST_NAMES_SET_VALUE:
                sendValue = false;
                return getFirstNames;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurname;
            case ID_SET_VALUE:
                sendValue = false;
                return getId;
            case MOBILE_SET_VALUE:
                sendValue = false;
                return getMobile;
            case EMAIL_SET_VALUE:
                sendValue = false;
                return getEmail;
            case COMPLEX_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getComplex;
            case STREET_ADDRESS_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getStreetAddress;
            case SUBURB_SET_VALUE:
                sendValue = false;
                return getSuburb;
            case CITY_SET_VALUE:
                return getCity;
            case POSTAL_CODE_SET_VALUE:
                sendValue = false;
                return getPostalCode;
            case COUNTRY_SET_VALUE:
                sendError = false;
                return getCountry;
            case POSTAL_ADDRESS_METHOD_VALUE:
                sendValue = false;
                return getPostalAddressMethod;
            case POSTAL_ADDRESS_SAME_VALUE:
                return getPostalAddressSame;
            case PO_BOX_SET_VALUE:
                sendValue = false;
                return getPoBox;
            case PO_POST_OFFICE_SET_VALUE:
                sendValue = false;
                return getPoPostOffice;
            case PO_POSTAL_CODE_SET_VALUE:
                sendValue = false;
                return getPoPostalCode;
            case PO_COUNTRY_SET_VALUE:
                sendError = false;
                return getPoCountry;
            case PRIVATE_BAG_SET_VALUE:
                sendValue = false;
                return getPrivateBag;
            case PRIVATE_POST_OFFICE_SET_VALUE:
                sendValue = false;
                return getPrivatePostOffice;
            case PRIVATE_POSTAL_CODE_SET_VALUE:
                sendValue = false;
                return getPrivatePostalCode;
            case PRIVATE_COUNTRY_SET_VALUE:
                sendError = false;
                return getPrivateCountry;
            case POSTAL_COMPLEX_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getPostalComplex;
            case POSTAL_STREET_ADDRESS_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getPostalStreetAddress;
            case POSTAL_SUBURB_SET_VALUE:
                sendValue = false;
                return getPostalSuburb;
            case POSTAL_CITY_SET_VALUE:
                return getPostalCity;
            case POSTAL_POSTAL_CODE_SET_VALUE:
                sendValue = false;
                return getPostalPostalCode;
            case POSTAL_COUNTRY_SET_VALUE:
                sendError = false;
                return getPostalCountry;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if(field)
        simpleFieldTracking({action, field, sendValue, sendError});

    return result;
};
