import {
    FORM_INVALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR,
    FORM_VALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR,
    PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALID,
    PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE,
    PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE_WITHOUT_VALIDATION,
    SYNCH_ADDITIONAL_HOME_MOTOR_DETAILS_FORM,
} from '../../actions/allsureInsurance/purchaseAdditionalHomeandMotor';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const homeDetailsDefault = {
    burglarBarsDetails: { value: null, error: null, status: null },
    alarmSystemDetails: { value: null, error: null, status: null },
    serviceProviderDetails: { value: null, error: null, status: null },
    steelFenceDetails: { value: null, error: null, status: null },
    securityGateDetails: { value: null, error: null, status: null },
    valuationReportDetails: { value: null, error: null, status: null },
    valuationReportPopupmodel: { value: null, error: null, status: FORM_STATUS.VALID },
    valuationReportConfirmation: { value: null, error: null, status: null },
    householdLoss: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    claimAmount: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    claimedCount: {
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    similarAgreementDetails: { value: null, error: null, status: null },
    financialInstitution: { value: null, error: null, status: null },
};
export const homeContentDefault = {
    burglarBarsDetails: { value: null, error: null, status: null },
    alarmSystemDetails: { value: null, error: null, status: null },
    serviceProviderDetails: { value: null, error: null, status: null },
    steelFenceDetails: { value: null, error: null, status: null },
    securityGateDetails: { value: null, error: null, status: null },
    valuationReportDetails: { value: null, error: null, status: null },
    valuationReportPopupmodel: { value: null, error: null, status: FORM_STATUS.VALID },
    valuationReportConfirmation: { value: null, error: null, status: null },
    similarAgreementDetails: { value: null, error: null, status: null },
    agreementServiceProvider: { value: null, error: null, status: null },

};
export const motorDetailsDefault = {
    registrationNumberDetails: { value: null, error: null, status: null },
    chassisNumberDetails: { value: null, error: null, status: null },
    engineNumberDetails: { value: null, error: null, status: null },
    similarAgreementDetails: { value: null, error: null, status: null },
    motorServiceProvider: { value: null, error: null, status: null },
    // motorvaluationReportDetails: { value: null, error: null, status: null },
    // motorvaluationReportPopupmodel: { value: null, error: null, status: FORM_STATUS.VALID },
    // motorvaluationReportConfirmation: { value: null, error: null, status: null },
};
export const initPurchaseAdditionalHomeMotorDetails = {
    formStatus: FORM_STATUS.INVALID,
    isAdditionalProductFormsSynced: false,
    form: {
        homeDetailsList: {},
        homeContentList: {},
        motorDetailForm:{},
    },
};

const allDefaultValues={ 
    homeDetailsList: homeDetailsDefault,
    homeContentList: homeContentDefault,
    motorDetailForm: motorDetailsDefault,
}

export default (state = initPurchaseAdditionalHomeMotorDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    
    const updateFormField = (fieldId, productType, update) => {
        const formField = state.form[productType.split('_')[0]][productType];
        // update(state.form[productType][fieldId])
        return {
            ...state,
            form: {
                ...state.form,
                [productType.split('_')[0]]: {
                    ...state.form[productType.split('_')[0]],
                    [productType]: {
                        ...formField,
                        [fieldId]: update(state.form[productType.split('_')[0]][productType][fieldId]),
                    },
                },
            },
        };
        // form[productType]: {
        //     ...state.form,
        //     [fieldId]: update(state.form[fieldId]),
        // },
    };
    switch (action.type) {
        case FORM_VALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_PURCHASE_ADDITIONAL_HOME_MOTOR:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE:
            if(action.value.fieldType==='alarmSystemDetails'){
                const keyDynamic= action.value.productType
                const serviceProviderObj = action.value.value ==='Yes' ? { value: null, error: null, status: null } : { value: null, error: null, status: FIELD_STATUS.VALID }
                return {
                    ...state,
                    form:{
                        ...state.form,
                        [action.value.productType.split('_')[0]]: {
                            ...state.form[action.value.productType.split('_')[0]],
                            [action.value.productType]: {
                                ...state.form[action.value.productType.split('_')[0]][keyDynamic],
                                alarmSystemDetails:{value: action.value.value,displayValue: action.value.displayValue ?? action.value.value,error:null,status:FORM_STATUS.VALID},
                                serviceProviderDetails:{...serviceProviderObj}
                            },
                        },
                    }
                }
            }
            if(action.value.fieldType==='valuationReportDetails'){
                const keyDynamic= action.value.productType
                const valuationReportConfirmationObj = action.value.value ==='No' ? { value: null, error: null, status: null } : { value: null, error: null, status: FIELD_STATUS.VALID }
                return {
                    ...state,
                    form:{
                        ...state.form,
                        [action.value.productType.split('_')[0]]: {
                            ...state.form[action.value.productType.split('_')[0]],
                            [action.value.productType]: {
                                ...state.form[action.value.productType.split('_')[0]][keyDynamic],
                                valuationReportDetails:{value: action.value.value,displayValue: action.value.displayValue ?? action.value.value,error:null,status:FORM_STATUS.VALID},
                                valuationReportConfirmation:{...valuationReportConfirmationObj}
                            },
                        },
                    }
                }
            }
            if(action.value.fieldType==='motorvaluationReportDetails'){
                const keyDynamic= action.value.productType
                const valuationReportConfirmationObj = action.value.value ==='No' ? { value: null, error: null, status: null } : { value: null, error: null, status: FIELD_STATUS.VALID }
                return {
                    ...state,
                    form:{
                        ...state.form,
                        [action.value.productType.split('_')[0]]: {
                            ...state.form[action.value.productType.split('_')[0]],
                            [action.value.productType]: {
                                ...state.form[action.value.productType.split('_')[0]][keyDynamic],
                                motorvaluationReportDetails:{value: action.value.value,displayValue: action.value.displayValue ?? action.value.value,error:null,status:FORM_STATUS.VALID},
                                motorvaluationReportConfirmation:{...valuationReportConfirmationObj}
                            },
                        },
                    }
                }
            }
            return updateFormField(action.value.fieldType, action.value.productType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
            }));
        case PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE_WITHOUT_VALIDATION:   
            return updateFormField(action.value.fieldType, action.value.productType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
                error: null,
                status: null ,
            }));
        case PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALID:
            return updateFormField(action.value.fieldType, action.value.productType, (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SYNCH_ADDITIONAL_HOME_MOTOR_DETAILS_FORM:
            let details = {};
            let additinalDataSynced=true
            Object.values(action.value).every((element)=>element.length===0)
                ? (() => {
                    additinalDataSynced=false
                      details = { 
                      homeDetailsList: {},
                      homeContentList: {},
                      motorDetailForm: {},
                    };
                  })()
                : Object.keys(action.value).map((item) => {
                      if (action.value[item].length === 0) {
                          details = {
                              ...details,
                              [item]: {},
                          };
                      } else {
                          action.value[item].map((element, index) => {
                              details = {
                                  ...details,
                                  [item]: {
                                      ...details[item],
                                      [item + '_' + index]: allDefaultValues[item],
                                  },
                              };
                          });
                      }
                  });
            return { ...state, form: { ...details }, isAdditionalProductFormsSynced: additinalDataSynced };
        default:
            return state;
        // }
    }
};
