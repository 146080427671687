export const TRAVEL_INSURANCE_ADD_TRAVELLER ='roaTravelInsurance/addTraveller/';
export const TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER + 'setValue/';

export const TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER + 'setValid/';

export const WHICH_FORM_TO_DISPLAY = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'whichFormToDisplay';

export const REINITIALIZE ='roaTravelInsurance/addTraveller/state/reinitialize';

// Traveller details
export const TRAVEL_INSURANCE_ADDITIONAL_TRAVELLER_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'formOne/';
export const TRAVEL_INSURANCE_ADDITIONAL_TRAVELLER_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'formOne/';
export const FORM_VALID_ADD_TRAVELLER_FORM ='roaTravelInsurance/addTraveller/transition/formOneValid';
export const FORM_INVALID_ADD_TRAVELLER_FORM ='roaTravelInsurance/addTraveller/transition/formOneInValid';

// Payments details
export const TRAVEL_INSURANCE_PAYMENTS_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'formTwo/';
export const TRAVEL_INSURANCE_PAYMENTS_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'formTwo/';

export const FORM_VALID_TRAVEL_INSURANCE_PAYMENTS_FORM = 'roaTravelInsurance/payments/transition/formTwoValid';
export const FORM_INVALID_TRAVEL_INSURANCE_PAYMENTS_FORM = 'roaTravelInsurance/payments/transition/formTwoInValid';


// Valid transition
export const transitionTravelInsurancePaymentsPages = {
    formOneValid: () => ({
        type: FORM_VALID_ADD_TRAVELLER_FORM,
    }),
    formOneInvalid: () => ({
        type: FORM_INVALID_ADD_TRAVELLER_FORM,
    }),
    formTwoValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_PAYMENTS_FORM,
    }),
    formTwoInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_PAYMENTS_FORM,
    }),
};

export const setWhichFormToDisplay = value => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});

export const setToAddTravellerInitial = () => ({
    type: REINITIALIZE,
});

// Add extra traller API Calls actions
export const API_PENDING_TRANSITION_ADD_EXTRA_TRAVELLER_FORM = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_ADD_EXTRA_TRAVELLER_FORM  = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_ADD_EXTRA_TRAVELLER_FORM  = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/failure`;

export const apiTransitionExtraTravellerForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_ADD_EXTRA_TRAVELLER_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_ADD_EXTRA_TRAVELLER_FORM ,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_ADD_EXTRA_TRAVELLER_FORM ,
    }),
};

// Payments API Calls actions
export const API_PENDING_TRANSITION_PAYMENTS_FORM = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/pending`;
export const API_SUCCESS_TRANSITION_PAYMENTS_FORM  = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/success`;
export const API_FAILURE_TRANSITION_PAYMENTS_FORM  = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/failure`;

export const apiTransitionPaymentsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PAYMENTS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PAYMENTS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PAYMENTS_FORM ,
    }),
};

// Payments status API Calls actions
export const API_PENDING_TRANSITION_PAYMENTS_STATUS = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/status/pending`;
export const API_SUCCESS_TRANSITION_PAYMENTS_STATUS  = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/status/success`;
export const API_FAILURE_TRANSITION_PAYMENTS_STATUS  = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/status/failure`;
export const API_INITIALIZE_TRANSITION_PAYMENTS_STATUS = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/status/initialize`;
export const apiTransitionPaymentsStatus = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PAYMENTS_STATUS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PAYMENTS_STATUS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PAYMENTS_STATUS,
    }),
    initialize: () => ({
        type: API_INITIALIZE_TRANSITION_PAYMENTS_STATUS,
    }),
};


// generate account number API Calls actions
export const API_PENDING_TRANSITION_GENERATE_ACCOUNT_NUMBER = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/accountNumber/pending`;
export const API_SUCCESS_TRANSITION_GENERATE_ACCOUNT_NUMBER = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/accountNumber/success`;
export const API_FAILURE_TRANSITION_GENERATE_ACCOUNT_NUMBER = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/accountNumber/failure`;

export const apiTransitionGenerateAccountNumber = {
    pending: () => ({
        type: API_PENDING_TRANSITION_GENERATE_ACCOUNT_NUMBER,
    }),
    success: () => ({
        type:  API_SUCCESS_TRANSITION_GENERATE_ACCOUNT_NUMBER,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_GENERATE_ACCOUNT_NUMBER,
    }),
};

// generate certificate API Calls actions
export const API_PENDING_TRANSITION_GENERATE_CERTIFICATE = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/certificate/pending`;
export const API_SUCCESS_TRANSITION_GENERATE_CERTIFICATE = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/certificate/success`;
export const API_FAILURE_TRANSITION_GENERATE_CERTIFICATE = `${TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE}/apiTransition/payments/certificate/failure`;

export const apiTransitionGenerateCertificate = {
    pending: () => ({
        type: API_PENDING_TRANSITION_GENERATE_CERTIFICATE,
    }),
    success: () => ({
        type:  API_SUCCESS_TRANSITION_GENERATE_CERTIFICATE,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_GENERATE_CERTIFICATE,
    }),
};


// Traveller Details
export const FULL_NAME_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'fullName';
export const FULL_NAME_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'fullName';
export const PHONE_NUMBER_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'phoneNumber';
export const EMAIL_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'email';
export const EMAIL_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'email';
export const DOB_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'dob';
export const DOB_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'dob';

// add extra traveller calculated Data
export const COLLECT_EXTRA_TRAVELLER_DETAILS_DATA = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'collectExtraTravellerData'
export const SUBMIT_EXTRA_TRAVELLER_DETAILS_SUCCESS = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'success';
export const SUBMIT_EXTRA_TRAVELLER_DETAILS_FAILURE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'failure';

export const collectExtraTrallerData = () => ({
    type: COLLECT_EXTRA_TRAVELLER_DETAILS_DATA,
});

export const submitExtraTravellerSuccess = value => ({
    type: SUBMIT_EXTRA_TRAVELLER_DETAILS_SUCCESS,
    value,
});
export const submitExtraTravellerFailure = error => ({
    type: SUBMIT_EXTRA_TRAVELLER_DETAILS_FAILURE,
    error,
});


// Travel insurance payments calculated Data
export const COLLECT_TRAVEL_INSURANCE_PAYMENTS= TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'travelInsurancePayments'
export const SUBMIT_TRAVEL_INSURANCE_PAYMENTS_SUCCESS = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'payments/success';
export const SUBMIT_TRAVEL_INSURANCE_PAYMENTS_FAILURE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'payments/failure';

export const collectTravelInsurancePaymentsData = () => ({
    type: COLLECT_TRAVEL_INSURANCE_PAYMENTS,
});

export const submitTravelInsurancePaymentsSuccess = value => ({
    type: SUBMIT_TRAVEL_INSURANCE_PAYMENTS_SUCCESS,
    value,
});
export const submitTravelInsurancePaymentsFailure = error => ({
    type: SUBMIT_TRAVEL_INSURANCE_PAYMENTS_FAILURE,
    error,
});

// Travel insurance payments-status calculated Data
export const COLLECT_TRAVEL_INSURANCE_PAYMENTS_STATUS= TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'travelInsurancePayments/status'
export const SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_SUCCESS = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'payments/status/success';
export const SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_FAILURE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'payments/status/failure';

export const collectTravelInsurancePaymentsStatusData = () => ({
    type: COLLECT_TRAVEL_INSURANCE_PAYMENTS_STATUS,
});

export const submitTravelInsurancePaymentsStatusSuccess = value => ({
    type: SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_SUCCESS,
    value,
});
export const submitTravelInsurancePaymentsStatusFailure = error => ({
    type: SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_FAILURE,
    error,
});

// Travel insurance generate account number
export const COLLECT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER= TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'travelInsurancePayments/accountNumber'
export const SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_SUCCESS = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'payments/accountNumber/success';
export const SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_FAILURE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'payments/accountNumber/failure';

export const collectTravelInsuranceAccountNumberData = () => ({
    type: COLLECT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER,
});

export const submitTravelInsuranceAccountNumberSuccess = value => ({
    type: SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_SUCCESS,
    value,
});
export const submitTravelInsuranceAccountNumberFailure = error => ({
    type: SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_FAILURE,
    error,
});

// Travel insurance generate certificate
export const COLLECT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'travelInsurancePayments/certificate'
export const SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_SUCCESS = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'payments/certificate/success';
export const SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_FAILURE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE +  'payments/certificate/failure';

export const collectTravelInsuranceGenerateCertificateData = () => ({
    type: COLLECT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE,
});

export const submitTravelInsuranceGenerateCertificateSuccess = (value) => ({
    type: SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_SUCCESS,
    value,
});
export const submitTravelInsuranceGenerateCertificateFailure = (error) => ({
    type: SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_FAILURE,
    error,
});


// Trip Details
export const FROM_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'from';
export const FROM_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'from';
export const TO_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'to';
export const TO_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'to';
export const DEPARTURE_DATE_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'depatureDate';
export const DEPARTURE_DATE_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'depatureDate';
export const RETURN_DATE_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'returnDate';
export const RETURN_DATE_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'returnDate';

// Plan selected
export const TRAVEL_PLAN_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'travelPlan';
export const TRAVEL_PLAN_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'travelPlan';
export const COVER_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'cover';
export const COVER_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'cover';

// Identification document

export const IDENTIFICATION_DOCUMENT_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'idDocument';
export const IDENTIFICATION_DOCUMENT_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'idDocument';
export const ID_NUMBER_SET_VALUE = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID + 'idNumber';



export const setFullNameValue = value => ({
    type: FULL_NAME_SET_VALUE,
    value,
});
export const setFullNameValid = error => ({
    type: FULL_NAME_SET_VALID,
    error,
});

export const setPhoneNumberValue = value => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = error => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setEmailValue = value => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = error => ({
    type: EMAIL_SET_VALID,
    error,
});

export const setDobValue = value => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDobValid = error => ({
    type: DOB_SET_VALID,
    error,
});

//trip details

export const setFromValue = value => ({
    type: FROM_SET_VALUE,
    value,
});
export const setFromValid = error => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setToValue = value => ({
    type: TO_SET_VALUE,
    value,
});
export const setToValid = error => ({
    type: TO_SET_VALID,
    error,
});
export const setDepatureDateValue = value => ({
    type: DEPARTURE_DATE_SET_VALUE,
    value,
});
export const setDepartureDateValid = error => ({
    type: DEPARTURE_DATE_SET_VALID,
    error,
});
export const setReturnDateValue = value => ({
    type: RETURN_DATE_SET_VALUE,
    value,
});
export const setReturnDateValid = error => ({
    type: RETURN_DATE_SET_VALID,
    error,
});

//Plan Selected

export const setTravelPanValue = value => ({
    type: TRAVEL_PLAN_SET_VALUE,
    value,
});
export const setTravelPlanValid = error => ({
    type: TRAVEL_PLAN_SET_VALID,
    error,
});

export const setCoverValue = value => ({
    type: COVER_SET_VALUE,
    value,
});
export const setCoverValid = error => ({
    type: COVER_SET_VALID,
    error,
});

// Identification document

export const setIdDocumentValue = value => ({
    type: IDENTIFICATION_DOCUMENT_SET_VALUE,
    value,
});
export const setIdDocumentValid = error => ({
    type: IDENTIFICATION_DOCUMENT_SET_VALID,
    error,
});
export const setIdNumberValue = value => ({
    type: ID_NUMBER_SET_VALUE,
    value,
});
export const setIdNumberValid = error => ({
    type: ID_NUMBER_SET_VALID,
    error,
});

// payments form
export const PAYMENTS_PHONE_NUMBER_SET_VALUE = TRAVEL_INSURANCE_PAYMENTS_SET_VALUE + 'paymentsPhoneNumber';
export const PAYMENTS_PHONE_NUMBER_SET_VALID = TRAVEL_INSURANCE_PAYMENTS_SET_VALID + 'paymentsPhoneNumber';


export const setPaymentsPhoneNumberValue = value => ({
    type: PAYMENTS_PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPaymentsPhoneNumberValid = error => ({
    type: PAYMENTS_PHONE_NUMBER_SET_VALID,
    error,
});
