import { FIELD_STATUS } from '../status';
import {
    ACTIVE_PAGE_NUMBER_SET_VALUE,
    CONTENT_DISPLAYED_SET_VALUE,
    DISPLAYED_TOPICS_SET_VALUE,
    DOWNLOADS_SET_VALUE,
    DROPDOWN_SET_VALUE,
    EMAIL_SET_VALID,
    EMAIL_SET_VALUE,
    FILTER_VISIBLE_SET_VALUE,
    LANGUAGE_CHECKBOXES_SET_VALUE,
    LEARNER_PLATFORM_REPRESENTATIVE_SET_VALUE,
    MODAL_OPEN_SET_VALUE,
    TOAST_VISIBLE_SET_VALUE,
    ORGANISATION_ID_SET_VALUE,
    ORGANISATION_SET_VALID,
    ORGANISATION_SET_VALUE,
    PLATFORM_OPTIONS_SET_VALUE,
    REGISTRATION_CHECKBOX_SET_VALUE,
    SEARCH_VALUE_SET_VALUE,
    TOPICS_SET_VALUE,
    TOTAL_CARDS_VISIBLE_SET_VALUE,
    UPDATE_CHECKBOXES_SET_VALUE,
    UPDATE_FILTER_CHECKBOXES_SET_VALUE,
    USER_TYPES_SET_VALUE,
    USER_TYPE_SET_VALUE,
} from '../../actions/ltd/ltd';

export const initLtd = {
    registration: {
        userType: '1',
        learnerPlatformRepresentative: { id: '1', description: '' },
        organisationId: '',
        email: { value: '', status: null, error: null },
        organisation: { value: '', status: null, error: null },
        checkbox: false,
        contentDisplayed: 'Registration',
        platformOptions: [],
        userTypes: [],
        downloads: [],
    },
    assetDownload: { dropdown: false, languageCheckboxes: [], modalOpen: false, toastVisible: false },
    searchAndFilter: {
        filterVisible: 'hide',
        activePageNumber: 1,
        searchValue: '',
        totalCardsVisible: 1,
        checkboxes: [],
        filterCheckboxes: [],
        topics: [],
        displayedTopics: [],
    },
};

export default (state = initLtd, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateRegistrationField = (fieldId, update) => ({
        ...state,
        registration: {
            ...state.registration,
            [fieldId]: update(state.registration[fieldId]),
        },
    });

    const updateAssetDownloadField = (fieldId, update) => ({
        ...state,
        assetDownload: {
            ...state.assetDownload,
            [fieldId]: update(state.assetDownload[fieldId]),
        },
    });

    const updateSearchAndFilterField = (fieldId, update) => ({
        ...state,
        searchAndFilter: {
            ...state.searchAndFilter,
            [fieldId]: update(state.searchAndFilter[fieldId]),
        },
    });

    switch (action.type) {
        case USER_TYPE_SET_VALUE:
            return updateRegistrationField('userType', () => action.value);

        case LEARNER_PLATFORM_REPRESENTATIVE_SET_VALUE:
            return updateRegistrationField('learnerPlatformRepresentative', () => action.value);

        case ORGANISATION_ID_SET_VALUE:
            return updateRegistrationField('organisationId', () => action.value);

        case EMAIL_SET_VALUE:
            return updateRegistrationField('email', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMAIL_SET_VALID:
            return updateRegistrationField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ORGANISATION_SET_VALUE:
            return updateRegistrationField('organisation', (field) => ({
                ...field,
                value: action.value,
            }));

        case ORGANISATION_SET_VALID:
            return updateRegistrationField('organisation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case REGISTRATION_CHECKBOX_SET_VALUE:
            return updateRegistrationField('checkbox', () => action.value);

        case CONTENT_DISPLAYED_SET_VALUE:
            return updateRegistrationField('contentDisplayed', () => action.value);

        case PLATFORM_OPTIONS_SET_VALUE:
            return updateRegistrationField('platformOptions', () => action.value);

        case USER_TYPES_SET_VALUE:
            return updateRegistrationField('userTypes', () => action.value);

        case DOWNLOADS_SET_VALUE:
            return updateRegistrationField('downloads', () => action.value);

        case DROPDOWN_SET_VALUE:
            return updateAssetDownloadField('dropdown', () => action.value);

        case LANGUAGE_CHECKBOXES_SET_VALUE:
            return updateAssetDownloadField('languageCheckboxes', () => action.value);

        case MODAL_OPEN_SET_VALUE:
            return updateAssetDownloadField('modalOpen', () => action.value);

        case TOAST_VISIBLE_SET_VALUE:
            return updateAssetDownloadField('toastVisible', () => action.value);

        case FILTER_VISIBLE_SET_VALUE:
            return updateSearchAndFilterField('filterVisible', () => action.value);

        case ACTIVE_PAGE_NUMBER_SET_VALUE:
            return updateSearchAndFilterField('activePageNumber', () => action.value);

        case SEARCH_VALUE_SET_VALUE:
            return updateSearchAndFilterField('searchValue', () => action.value);

        case TOTAL_CARDS_VISIBLE_SET_VALUE:
            return updateSearchAndFilterField('totalCardsVisible', () => action.value);

        case UPDATE_CHECKBOXES_SET_VALUE:
            return updateSearchAndFilterField('checkboxes', () => action.value);

        case UPDATE_FILTER_CHECKBOXES_SET_VALUE:
            return updateSearchAndFilterField('filterCheckboxes', () => action.value);

        case TOPICS_SET_VALUE:
            return updateSearchAndFilterField('topics', () => action.value);
        
        case DISPLAYED_TOPICS_SET_VALUE:
            return updateSearchAndFilterField('displayedTopics', () => action.value);

        default:
            return state;
    }
};
