import {
    validateName,
    validateIdNumber,
    validateCellphoneNumber
} from "@om/validation";
import {
    FIRSTNAME_SET_VALUE,
    setFirstnameValid,
    SURNAME_SET_VALUE,
    setSurnameValid,
    ID_NUMBER_SET_VALUE,
    setIdNumberValid,
    MOBILE_NUMBER_SET_VALUE,
    setMobileNumberValid
} from "../../../actions/publicWeb/qualifyingCheckCallMeBack";
import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const qualifyingCheckCallMeBackValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIRSTNAME_SET_VALUE:
                error = validateName(action.value, { min: 2, max: 48, subject: 'name' });
                return setFirstnameValid(error);
            case SURNAME_SET_VALUE:
                error = validateName(action.value, { min: 2, max: 64, subject: 'surname' });
                return setSurnameValid(error);
            case ID_NUMBER_SET_VALUE:
                error = validateIdNumber(action.value, { minAge: 18, checkAge: true});
                return setIdNumberValid(error);
            case MOBILE_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(action.value, { subject: 'cellphone number' });
                return setMobileNumberValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
}
