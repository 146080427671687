

export const DIVIDEND_CALCULATOR_KEY = 'publicWeb/dividendCalculator';

export const CALCULATE_DIVIDENDS = `${DIVIDEND_CALCULATOR_KEY}/calculate/totalDividends`;
export const calculateDividends = () => ({ type: CALCULATE_DIVIDENDS });

export const FORM_VALID_TRANSITION_DIVIDEND_CALCULATOR = `${DIVIDEND_CALCULATOR_KEY}/transition/formValid`;
export const FORM_INVALID_TRANSITION_DIVIDEND_CALCULATOR = `${DIVIDEND_CALCULATOR_KEY}/transition/formInvalid`;
export const transitionDividendCalculator = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_DIVIDEND_CALCULATOR }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_DIVIDEND_CALCULATOR }),
};

export const DIVIDENDS_TOTAL_SET_VALUE = `${DIVIDEND_CALCULATOR_KEY}/setValue/dividendsTotal`;
export const DIVIDEND_CALC_SHARES_SET_VALUE = `${DIVIDEND_CALCULATOR_KEY}/setValue/shares`;
export const DIVIDEND_CALC_SHARES_SET_VALID = `${DIVIDEND_CALCULATOR_KEY}/setValid/shares`;
export const DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE = `${DIVIDEND_CALCULATOR_KEY}/setValue/selectedCountry`;

export const setDividendsTotal = value => ({ type: DIVIDENDS_TOTAL_SET_VALUE, value });
export const setShares = value => ({ type: DIVIDEND_CALC_SHARES_SET_VALUE, value });
export const setSharesValid = error => ({ type: DIVIDEND_CALC_SHARES_SET_VALID, error });
export const setDividendCalcSelectedCountry = value => ({ type: DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE, value });
