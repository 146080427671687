import {
    API_CALL_STATUS,
    FIELD_STATUS,
    FORM_STATUS,
    TOGGLE_STATUS,
} from '../status';

import {
    REINITIALIZE,
    WHICH_FORM_TO_DISPLAY,
    PLAN_SET_VALUE,
    PLAN_SET_VALID,
    NATIONAL_ID_NUMBER_SET_VALUE,
    NATIONAL_ID_NUMBER_SET_VALID,
    PASSPORT_NUMBER_SET_VALUE,
    PASSPORT_NUMBER_SET_VALID,
    KRA_PIN_NUMBER_SET_VALUE,
    KRA_PIN_NUMBER_SET_VALID,
    NATIONALITY_SET_VALUE,
    NATIONALITY_SET_VALID,
    COUNTRY_OF_RESIDENCE_SET_VALUE,
    COUNTRY_OF_RESIDENCE_SET_VALID,
    EMERGENCY_CONTACT_MODAL_DISPLAY_SET_VALUE,
    EMERGENCY_CONTACT_FIRST_NAME_SET_VALUE,
    EMERGENCY_CONTACT_FIRST_NAME_SET_VALID,
    EMERGENCY_CONTACT_LAST_NAME_SET_VALUE,
    EMERGENCY_CONTACT_LAST_NAME_SET_VALID,
    EMERGENCY_CONTACT_EMAIL_SET_VALUE,
    EMERGENCY_CONTACT_EMAIL_SET_VALID,
    EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALUE,
    EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALID,
    FORM_VALID_TRAVEL_INSURANCE_PLANS_FORM,
    FORM_INVALID_TRAVEL_INSURANCE_PLANS_FORM,
} from '../../actions/roaTravelInsurance/plans';

export const initialState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    displayEmergencyModal: TOGGLE_STATUS.UNTOUCHED,
    form: {
        planSelected: { value: null, error: null, status: null },
        nationalIdNumber: { value: null, error: null, status: null },
        passportNumber: { value: null, error: null, status: null },
        nationality: { value: null, error: null, status: null },
        krapin: { value: null, error: null, status: null },
        countryOfResidence: {
            value: 'Yes',
            error: null,
            status: 'valid',
        },
        emergencyContactFirstName: {
            value: null,
            error: null,
            status: null,
        },
        emergencyContactLastName: {
            value: null,
            error: null,
            status: null,
        },
        emergencyContactEmail: {
            value: null,
            error: null,
            status: null,
        },
        emergencyContactPhoneNumber: {
            value: null,
            error: null,
            status: null,
        },
    },
};

export default (state = initialState, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRAVEL_INSURANCE_PLANS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRAVEL_INSURANCE_PLANS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case WHICH_FORM_TO_DISPLAY:
            return { ...state, selectedForm: action.value };
        case EMERGENCY_CONTACT_MODAL_DISPLAY_SET_VALUE:
            return { ...state, displayEmergencyModal: action.value };
        case PLAN_SET_VALUE:
            return updateFormField('planSelected', field => ({
                ...field,
                value: action.value,
            }));
        case PLAN_SET_VALID:
            return updateFormField('planSelected', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NATIONAL_ID_NUMBER_SET_VALUE:
            return updateFormField('nationalIdNumber', field => ({
                ...field,
                value: action.value,
            }));
        case NATIONAL_ID_NUMBER_SET_VALID:
            return updateFormField('nationalIdNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PASSPORT_NUMBER_SET_VALUE:
            return updateFormField('passportNumber', field => ({
                ...field,
                value: action.value,
            }));
        case PASSPORT_NUMBER_SET_VALID:
            return updateFormField('passportNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case KRA_PIN_NUMBER_SET_VALUE:
            return updateFormField('krapin', field => ({
                ...field,
                value: action.value,
            }));
        case KRA_PIN_NUMBER_SET_VALID:
            return updateFormField('krapin', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NATIONALITY_SET_VALUE:
            return updateFormField('nationality', field => ({
                ...field,
                value: action.value,
            }));
        case NATIONALITY_SET_VALID:
            return updateFormField('nationality', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case COUNTRY_OF_RESIDENCE_SET_VALUE:
            return updateFormField('countryOfResidence', field => ({
                ...field,
                value: action.value,
            }));
        case COUNTRY_OF_RESIDENCE_SET_VALID:
            return updateFormField('countryOfResidence', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMERGENCY_CONTACT_FIRST_NAME_SET_VALUE:
            return updateFormField('emergencyContactFirstName', field => ({
                ...field,
                value: action.value,
            }));
        case EMERGENCY_CONTACT_FIRST_NAME_SET_VALID:
            return updateFormField('emergencyContactFirstName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMERGENCY_CONTACT_LAST_NAME_SET_VALUE:
            return updateFormField('emergencyContactLastName', field => ({
                ...field,
                value: action.value,
            }));
        case EMERGENCY_CONTACT_LAST_NAME_SET_VALID:
            return updateFormField('emergencyContactLastName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMERGENCY_CONTACT_EMAIL_SET_VALUE:
            return updateFormField('emergencyContactEmail', field => ({
                ...field,
                value: action.value,
            }));
        case EMERGENCY_CONTACT_EMAIL_SET_VALID:
            return updateFormField('emergencyContactEmail', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALUE:
            return updateFormField('emergencyContactPhoneNumber', field => ({
                ...field,
                value: action.value,
            }));
        case EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALID:
            return updateFormField('emergencyContactPhoneNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Reset the state
        case REINITIALIZE:
            return initialState;
        default:
            return state;
    }
};
