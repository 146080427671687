import { STORE_PERSONAL_DETAIL, FIRST_NAME, LAST_NAME, ID_NUMBER, CELL_NUMBER, EMAIL_ADDRESS, CREDIT_CONSENT, PERSONAL_CONSENT, RECAPTCHA, BLACK_BOX_DATA, APPLICATION_NOW_SUCCESS, MOVE_TO_PREVIOUS_PAGE } from '../../actions/digitalOnboarding/types';
import { validateID, validateCellNumber } from '../../functions/digitalOnboarding/validations';

const maskValue = '*';

const storeValues = {
    [FIRST_NAME]: { value: '', isValid: false, errorMessage: '' },
    [LAST_NAME]: { value: '', isValid: false, errorMessage: '' },
    [ID_NUMBER]: { value: '', isValid: false, errorMessage: '' },
    [CELL_NUMBER]: { value: '', isValid: false, errorMessage: '' },
    [EMAIL_ADDRESS]: { value: '', isValid: false, errorMessage: '' },
    [CREDIT_CONSENT]: { value: 0, isValid: false, errorMessage: '' },
    [PERSONAL_CONSENT]: { value: 0, isValid: false, errorMessage: '' },
    [RECAPTCHA]: { value: '', isValid: false, errorMessage: '' },
    [BLACK_BOX_DATA]: { value: '', isValid: false, errorMessage: '' }
};

const extractIDNumber = state => state.storeValues[ID_NUMBER].value;
const extractCellNumber = state => state.storeValues[CELL_NUMBER].value;

const maskIDNumber = idNumber => {
    return idNumber.replace(/(\d*)\d{7}/, '$1*******');
}

const maskCellPhoneNumber = phoneNumber => {
    return phoneNumber.replace(/\d{8}(\d*)/, '********$1');
}

const buildFormField = (value, isValid = false, errorMessage = '') => ({ value, isValid, errorMessage });

const buildIDNumberStoreValue = state => {
    const idNumber = extractIDNumber(state);
    if (idNumber.includes(maskValue)) {
        const validator = validateID(idNumber);
        return buildFormField(idNumber, validator.isValid, validator.errorMessage);
    }
    return state.storeValues[ID_NUMBER];
}

const buildCellPhoneNumberStoreValue = state => {
    const cellPhone = extractCellNumber(state);
    if (cellPhone.includes(maskValue)) {
        const validator = validateCellNumber(cellPhone);
        return buildFormField(cellPhone, validator.isValid, validator.errorMessage);
    }
    return state.storeValues[CELL_NUMBER];
}

// This is a transform of the inbound (to be stored) state going into
// react-persist. This masks the cell phone and ID numbers of the applicant.
export function buildStateWithMaskedPrivateUserInformation(inboundState) {
    const maskedId = maskIDNumber(extractIDNumber(inboundState));
    const maskedPhoneNumber = maskCellPhoneNumber(extractCellNumber(inboundState));

    return {
        ...inboundState,
        storeValues: {
            ...inboundState.storeValues,
            [ID_NUMBER]: buildFormField(maskedId),
            [CELL_NUMBER]: buildFormField(maskedPhoneNumber)
        }
    };
}

// This is a transform of the outbound (to be restored) state coming from
// react-persist. This will ensure that the cell phone and ID numbers are
// shown as errors if this is expected
export function hydrateStateFromPersistStore(outboundState) {
    const state = { ...outboundState };

    state.storeValues[ID_NUMBER] = buildIDNumberStoreValue(state);
    state.storeValues[CELL_NUMBER] = buildCellPhoneNumberStoreValue(state);

    return state;
}

export const initPersonalDetails = {
    storeValues: storeValues
}

export default (state = initPersonalDetails, action) => {
    const stateBuilder = state => value => ({
        ...state,
        storeValues: { ...state.storeValues, ...value }
    });

    const buildState = stateBuilder(state)

    switch (action.type) {
        case MOVE_TO_PREVIOUS_PAGE:
        case APPLICATION_NOW_SUCCESS: {
            const maskedState = buildStateWithMaskedPrivateUserInformation(state);
            return stateBuilder(maskedState)({
                [ID_NUMBER]: buildIDNumberStoreValue(maskedState),
                [CELL_NUMBER]: buildCellPhoneNumberStoreValue(maskedState)
            });
        }
        case STORE_PERSONAL_DETAIL: {
            const storeValueObj = buildFormField(action.value, action.isValid, action.errorMessage);
            return buildState({ [action.key]: storeValueObj });
        }
        default:
            break;
    }

    return state;
}
