import {
    validateNumber
} from '@om/validation';

import {
    PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE,
    setInvestmentAmountValid,
} from '../../../actions/pastPerformanceCalculator/pastPerformanceCalculator';

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const pastPerformanceCalculatorValidationRules = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE:
                error = validateNumber(action.value, { min: 1, max: 1000000000, subject: 'investment amount', decimalPoints: 2 });
                return setInvestmentAmountValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};

