import { initCallMeBack } from './callMeBack';
import { initReplacementPolicy } from './replacementPolicy';
import { initPersonalDetails } from './personalDetails';
import { initReferenceData } from './referenceData';
import { initPaymentDetails } from './paymentDetails';
import { initConfirmDetails } from './confirmDetails';
import { initScrolling } from './scrolling';
import { initRouting } from './routing';
import { initBeneficiaries } from './beneficiaries';
import { initLivesCovered } from './livesCovered';
import { initBeneficiariesSplit } from './beneficiariesSplit';
import { initQuote } from './quote';
import { initPersistedAt } from './persistedAt';
import { initSessionCamVariables } from './sessionCamVariables';

export default () => {
    return {
        callMeBack: initCallMeBack,
        replacementPolicy: initReplacementPolicy,
        personalDetails: initPersonalDetails,
        referenceData: initReferenceData,
        paymentDetails: initPaymentDetails,
        confirmDetails: initConfirmDetails,
        scrolling: initScrolling,
        routing: initRouting,
        beneficiaries: initBeneficiaries,
        livesCovered: initLivesCovered,
        beneficiariesSplit: initBeneficiariesSplit,
        quote: initQuote,
        persistedAt: initPersistedAt,
        sessionCamVariables: initSessionCamVariables,
    }
}