import { transitionGroupLifeTool } from '../../../actions/roaQuoteTools/groupLifeQuoteTool';
import { FIELD_STATUS } from '../../../reducers/status';
import {
    getGroupLifeQuoteToolForm,
    getSelectedForm,
} from '../../../selectors/roaQuoteTools/groupLifeQuoteTool';

export const groupLifeQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const groupLifeQuoteToolForm = getGroupLifeQuoteToolForm(store.getState());

    const selectedForm = getSelectedForm(store.getState());

    const groupLifeQuoteToolValid = validateGroupLifeToolFormDetails(
        groupLifeQuoteToolForm,
        selectedForm,
    );

    const formAction = groupLifeQuoteToolValid
        ? transitionGroupLifeTool.formValid()
        : transitionGroupLifeTool.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateGroupLifeToolFormDetails = (formFields, selectedForm) => {
    if (selectedForm === 'form-one') {
        const {
            numberOfEmployees,
            averageAgeOfEmployees,
            totalSumAssured,
            coverRanges,
        } = formFields;

        return (
            numberOfEmployees &&
            numberOfEmployees.status === FIELD_STATUS.VALID &&
            averageAgeOfEmployees &&
            averageAgeOfEmployees.status === FIELD_STATUS.VALID &&
            totalSumAssured &&
            totalSumAssured.status === FIELD_STATUS.VALID &&
            !coverRanges.filter(
                coverRange => !coverRange.code || !coverRange.coverMultiple,
            ).length
        );
    }

    return true;
};
