import {IS_REPLACEMENT_POLICY_SET_VALUE} from "../../../actions/easiplusFuneralPlan/replacementPolicy";
import {getIsReplacementPolicy} from "../../../selectors/easiplusFuneralPlan/replacementPolicy";
import { simpleFieldTracking } from "../formTracking";
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";

export const replacementPolicyFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case IS_REPLACEMENT_POLICY_SET_VALUE:
                return getIsReplacementPolicy;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);
    if(field)
        simpleFieldTracking({action, field, sendValue, sendError});

    return result;
}
