import { createSelector } from "reselect";
import { ADDRESS_TYPES, DEFAULT_COUNTRY, MARITAL_TYPES } from "../../actions/taxFreeSavingsAccount/types";
import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from "../../reducers/status";
import { getTaxFreeSavingsAccount } from "./index";
import { getSavingForMinor } from "./investmentSetupInitial";
import { getContactNumber, getEmail } from "./personalDetails";
import { getReferenceCountries, getReferenceNationalitiesList } from "./referenceData";

export const getAuxiliaryDetails = createSelector(getTaxFreeSavingsAccount, (tfsa) => tfsa.auxiliaryDetails);
export const getAuxiliaryDetailsForm = createSelector(getAuxiliaryDetails, (auxiliaryDetails) => auxiliaryDetails.form);
export const getAuxiliaryDetailsValid = createSelector(
  getAuxiliaryDetails,
  (auxiliaryDetails) => auxiliaryDetails.formStatus === FORM_STATUS.VALID
);

export const getLeadDetails = createSelector(getAuxiliaryDetails, (auxiliaryDetails) => auxiliaryDetails.leadDetails);

export const getGcsStreetAddress = createSelector(
  getAuxiliaryDetails,
  (auxiliaryDetails) => auxiliaryDetails.gCSStreetAddress
);
export const getGcsPostalAddress = createSelector(
  getAuxiliaryDetails,
  (auxiliaryDetails) => auxiliaryDetails.gCSPostalAddress
);
export const getDropOffDetails = createSelector(
  getAuxiliaryDetails,
  (auxiliaryDetails) => auxiliaryDetails.dropOffDetails
);

const makeGetField = (fieldId) =>
  createSelector(getAuxiliaryDetailsForm, (auxiliaryDetailsForm) => auxiliaryDetailsForm[fieldId]);

// Spouse details
export const getSurname = makeGetField("surname");
export const getMaritalStatus = makeGetField("maritalStatus");
export const getMarriedCommunityOfProperty = makeGetField("marriedInCommunityOfProperty");
export const getSpouseTitle = makeGetField("spouseTitle");
export const getSpouseFirstNames = makeGetField("spouseFirstNames");
export const getSpouseSurname = makeGetField("spouseSurname");
export const getSpouseIdentityType = makeGetField("spouseIdentityType");
export const getSpouseIdNumber = makeGetField("spouseIdNumber");
export const getSpousePassportNumber = makeGetField("spousePassportNumber");

// Minors Details
export const getMinorTitle = makeGetField("minorTitle");
export const getMinorFirstNames = makeGetField("minorFirstNames");
export const getMinorSurname = makeGetField("minorSurname");
export const getMinorId = makeGetField("minorId");

// Personal Details
export const getId = makeGetField("id");
export const getNationality = makeGetField("nationality");

// Address
export const getComplex = makeGetField("complex");
export const getStreetAddress = makeGetField("streetAddress");
export const getSuburb = makeGetField("suburb");
export const getCity = makeGetField("city");
export const getPostalCode = makeGetField("postalCode");
export const getCountry = makeGetField("country");

// Postal Address
export const getPostalAddressSame = makeGetField("postalAddressSame");
export const getPostalAddressMethod = makeGetField("postalAddressMethod");
// PO Box
export const getPoBox = makeGetField("poBox");
export const getPoPostOffice = makeGetField("poPostOffice");
export const getPoPostalCode = makeGetField("poPostalCode");
export const getPoCountry = makeGetField("poCountry");
// Private bag
export const getPrivateBag = makeGetField("privateBag");
export const getPrivatePostOffice = makeGetField("privatePostOffice");
export const getPrivatePostalCode = makeGetField("privatePostalCode");
export const getPrivateCountry = makeGetField("privateCountry");
// Stress Address
export const getPostalComplex = makeGetField("postalComplex");
export const getPostalStreetAddress = makeGetField("postalStreetAddress");
export const getPostalSuburb = makeGetField("postalSuburb");
export const getPostalCity = makeGetField("postalCity");
export const getPostalPostalCode = makeGetField("postalPostalCode");
export const getPostalCountry = makeGetField("postalCountry");

export const getIsPostalAddressSame = createSelector(
  getPostalAddressSame,
  (postalAddress) => postalAddress.value === TOGGLE_STATUS.YES
);

export const getIsPostalMethodStreet = createSelector(
  getPostalAddressMethod,
  (addressMethod) => addressMethod.value === ADDRESS_TYPES.STREET_ADDRESS
);

export const getIsPostalMethodPoBox = createSelector(
  getPostalAddressMethod,
  (addressMethod) => addressMethod.value === ADDRESS_TYPES.PO_BOX
);

export const getIsPostalMethodPrivateBag = createSelector(
  getPostalAddressMethod,
  (addressMethod) => addressMethod.value === ADDRESS_TYPES.PRIVATE_BAG
);

export const getIsPostalMethodNotSet = createSelector(
  getPostalAddressMethod,
  (addressMethod) => addressMethod.value === null
);

export const getIsMarriedInCommunityOfProperty = createSelector(
  getMaritalStatus,
  (maritalStatus) => maritalStatus.value === MARITAL_TYPES.MARRIED_COP
);

export const getSpouseHasIdNumber = createSelector(
  getSpouseIdentityType,
  (identityType) => identityType.value === true
);

export const getSpouseHasPassportNumber = createSelector(
  getSpouseIdentityType,
  (identityType) => identityType.value === false
);

export const getMinorDetailsCompleted = createSelector(
  getSavingForMinor,
  getMinorTitle,
  getMinorFirstNames,
  getMinorSurname,
  getMinorId,
  (savingForMinor, minorTitle, minorFirstNames, minorSurname, minorId) => {
    //if not investing for minor section is not shown
    if (!savingForMinor) return true;

    return (
      minorTitle.status == FIELD_STATUS.VALID &&
      minorFirstNames.status === FIELD_STATUS.VALID &&
      minorSurname.status === FIELD_STATUS.VALID &&
      minorId.status === FIELD_STATUS.VALID
    );
  }
);

export const getSpouseDetailsCompleted = createSelector(
  getIsMarriedInCommunityOfProperty,
  getSpouseTitle,
  getSpouseFirstNames,
  getSpouseSurname,
  getSpouseIdentityType,
  getSpouseIdNumber,
  getSpousePassportNumber,
  (
    isMarriedInCommunityOfProperty,
    spouseTitle,
    spouseFirstName,
    spouseSurname,
    spouseIdentityType,
    spouseIdNumber,
    spousePassportNumber
  ) => {
    // if not married in CommunityOfProperty section is not shown
    if (!isMarriedInCommunityOfProperty) return true;
    const spouseInputFields =
      spouseTitle.status === FIELD_STATUS.VALID &&
      spouseFirstName.status === FIELD_STATUS.VALID &&
      spouseSurname.status === FIELD_STATUS.VALID;

    //true SA ID selected for spouse
    if (spouseIdentityType.value) {
      return spouseInputFields && spouseIdNumber.status === FIELD_STATUS.VALID;
    }

    return spouseInputFields && spousePassportNumber.status === FIELD_STATUS.VALID;
  }
);

export const getIsMinorSurnameSameParent = createSelector(
  getMinorSurname,
  getSurname,
  (minorSurname, parentSurname) => {
    return minorSurname?.value === parentSurname?.value;
  }
);

export const getPersonalDetailsCompleted = createSelector(getId, getNationality, (id, nationality) => {
  return id.status === FIELD_STATUS.VALID, nationality.status === FIELD_STATUS.VALID;
});

export const getAddressDetailsCompleted = createSelector(
  getComplex,
  getStreetAddress,
  getSuburb,
  getCity,
  getPostalCode,
  getCountry,
  (complex, streetAddress, suburb, city, postalCode, country) => {
    return (
      complex.status === FIELD_STATUS.VALID &&
      streetAddress.status === FIELD_STATUS.VALID &&
      suburb.status === FIELD_STATUS.VALID &&
      city.status === FIELD_STATUS.VALID &&
      postalCode.status === FIELD_STATUS.VALID &&
      country.status === FIELD_STATUS.VALID
    );
  }
);

export const getPostalAddressCompleted = createSelector(
  getIsPostalAddressSame,
  getIsPostalMethodStreet,
  getIsPostalMethodPoBox,
  getIsPostalMethodPrivateBag,

  getPostalComplex,
  getPostalStreetAddress,
  getPostalSuburb,
  getPostalCity,
  getPostalPostalCode,
  getPostalCountry,

  getPoBox,
  getPoPostOffice,
  getPoPostalCode,
  getPoCountry,

  getPrivateBag,
  getPrivatePostOffice,
  getPrivatePostalCode,
  getPrivateCountry,

  (
    postalAddressSame,
    postalMethodStreet,
    postalMethodPoBox,
    postalMethodPrivateBag,
    postalComplex,
    postalStreetAddress,
    postalSuburb,
    postalCity,
    postalPostalCode,
    postalCountry,
    poBox,
    poPostOffice,
    poPostalCode,
    poCountry,
    privateBag,
    privatePostOffice,
    privatePostalCode,
    privateCountry
  ) => {
    //if postal address same subsequent options hidden, therefore complete
    if (postalAddressSame) return true;

    if (postalMethodStreet) {
      return (
        postalComplex.status === FIELD_STATUS.VALID &&
        postalStreetAddress.status === FIELD_STATUS.VALID &&
        postalSuburb.status === FIELD_STATUS.VALID &&
        postalCity.status === FIELD_STATUS.VALID &&
        postalPostalCode.status === FIELD_STATUS.VALID &&
        postalCountry.status === FIELD_STATUS.VALID
      );
    }

    if (postalMethodPoBox) {
      return (
        poBox.status === FIELD_STATUS.VALID &&
        poPostOffice.status === FIELD_STATUS.VALID &&
        poPostalCode.status === FIELD_STATUS.VALID &&
        poCountry.status === FIELD_STATUS.VALID
      );
    }

    if (postalMethodPrivateBag) {
      return (
        privateBag.status === FIELD_STATUS.VALID &&
        privatePostOffice.status === FIELD_STATUS.VALID &&
        privatePostalCode.status === FIELD_STATUS.VALID &&
        privateCountry.status === FIELD_STATUS.VALID
      );
    }

    return false;
  }
);

export const getIsAuxiliaryDetailsValid = createSelector(
  getMinorDetailsCompleted,
  getSpouseDetailsCompleted,
  getPersonalDetailsCompleted,
  getAddressDetailsCompleted,
  getPostalAddressCompleted,
  (minorDetails, personalDetails, spouseDetails, addressDetails, postalAddress) => {
    return minorDetails && spouseDetails && personalDetails && addressDetails && postalAddress;
  }
);

const getCountryDescription = createSelector(
  getReferenceCountries,
  getReferenceNationalitiesList,
  getCountry,
  (countries, nationalities, country) => {
    if (countries) {
      const countryElement =
        countries.find((index) => index.code === country.value) ??
        nationalities.find((index) => index.code === country.value);
      return countryElement.description;
    }
    return "";
  }
);

const getPostalCountryDescription = createSelector(
  getReferenceCountries,
  getPostalAddressMethod,
  getPostalCountry,
  getPrivateCountry,
  getPoCountry,
  (countries, postalAddressMethod, postalCountry, privateCountry, poCountry) => {
    let country;
    switch (postalAddressMethod.value) {
      case ADDRESS_TYPES.PRIVATE_BAG:
        country = privateCountry;
        break;
      case ADDRESS_TYPES.PO_BOX:
        country = poCountry;
        break;
      case ADDRESS_TYPES.STREET_ADDRESS:
        country = postalCountry;
        break;

      default:
        return "";
    }

    if (countries) {
      const countryElement = countries.find((index) => index.code === country.value);
      if (countryElement) {
        return countryElement.description;
      }
    }
    return country.value;
  }
);

export const getDisplayResidentialAddress = createSelector(
  getStreetAddress,
  getSuburb,
  getComplex,
  getCity,
  getPostalCode,
  getCountry,
  getCountryDescription,
  (streetAddress, suburb, complex, city, postalCode, country, countryDescription) => {
    if (!streetAddress.value && !suburb.value && !complex.value && !city.value && !postalCode.value && !country.value)
      return "";

    if (country.value === DEFAULT_COUNTRY.ZA_DESCRIPTION || country.value === DEFAULT_COUNTRY.ZA_CODE) {
      const addressArray = [complex.value, streetAddress.value, suburb.value, city.value].filter(Boolean).join(", ");
      return [addressArray, postalCode.value, countryDescription].filter(Boolean).join(" ");
    }

    const addressArray = [complex.value, streetAddress.value, city.value].filter(Boolean).join(", ");
    const addressArrayPostalCode = [addressArray, postalCode.value].filter(Boolean).join(" ");
    return [addressArrayPostalCode, countryDescription].filter(Boolean).join(", ");
  }
);

export const getDisplayPostalAddress = createSelector(
  getPostalAddressMethod,
  getIsPostalAddressSame,
  getPostalStreetAddress,
  getPostalSuburb,
  getPostalComplex,
  getPostalCity,
  getPostalPostalCode,
  getPostalCountry,

  getPrivateBag,
  getPrivatePostOffice,
  getPrivatePostalCode,
  getPrivateCountry,

  getPoBox,
  getPoPostOffice,
  getPoPostalCode,
  getPoCountry,
  getPostalCountryDescription,
  (
    postalAddressMethod,
    postalAddressSame,
    postalStreetAddress,
    postalSuburb,
    postalComplex,
    postalCity,
    postalPostalCode,
    postalCountry,

    privateBag,
    privatePostOffice,
    privatePostalCode,
    privateCountry,

    poBox,
    poPostOffice,
    poPostalCode,
    poCountry,
    countryDescription
  ) => {
    if (postalAddressSame) return "";

    let country;
    let postalCode;
    let addressArray;
    switch (postalAddressMethod.value) {
      case ADDRESS_TYPES.PRIVATE_BAG:
        country = privateCountry;
        postalCode = privatePostalCode;
        addressArray = [privateBag.value, privatePostOffice.value];
        break;
      case ADDRESS_TYPES.PO_BOX:
        country = poCountry;
        postalCode = poPostalCode;
        addressArray = [poBox.value, poPostOffice.value];
        break;
      case ADDRESS_TYPES.STREET_ADDRESS:
        country = postalCountry;
        postalCode = postalPostalCode;
        addressArray = [postalComplex.value, postalStreetAddress.value, postalSuburb.value, postalCity.value];
        break;

      default:
        break;
    }
    if (!postalCode.value && !country.value) return "";

    if (country.value === DEFAULT_COUNTRY.ZA_DESCRIPTION || country.value === DEFAULT_COUNTRY.ZA_CODE) {
      const addressString = addressArray.filter(Boolean).join(", ");
      return [addressString, postalCode.value, countryDescription].filter(Boolean).join(" ");
    }

    if (postalAddressMethod === ADDRESS_TYPES.STREET_ADDRESS) {
      addressArray = [postalComplex.value, postalStreetAddress.value, postalCity.value];
    }

    const addressString = addressArray.filter(Boolean).join(", ");
    const addressStringPostalCode = [addressString, postalCode.value].filter(Boolean).join(" ");
    return [addressStringPostalCode, countryDescription].filter(Boolean).join(", ");
  }
);

// Submission
export const getPostalAddressForSubmission = createSelector(
  getPostalAddressMethod,
  getPoBox,
  getPoPostOffice,
  getPoPostalCode,
  getPoCountry,

  getPrivateBag,
  getPrivatePostOffice,
  getPrivatePostalCode,
  getPrivateCountry,

  getPostalComplex,
  getPostalStreetAddress,
  getPostalSuburb,
  getPostalCity,
  getPostalPostalCode,
  getPostalCountry,

  (
    postalAddressMethod,
    poBox,
    poPostOffice,
    poPostalCode,
    poCountry,

    privateBag,
    privatePostOffice,
    privatePostalCode,
    privateCountry,

    postalComplex,
    postalStreetAddress,
    postalSuburb,
    postalCity,
    postalPostalCode,
    postalCountry
  ) => {
    if (postalAddressMethod.value === ADDRESS_TYPES.PO_BOX) {
      return {
        street: poBox.value,
        suburb: poPostOffice.value,
        city: poCountry.value,
        countryCode: poCountry.value,
        postalCode: poPostalCode.value,
      };
    }
    if (postalAddressMethod.value === ADDRESS_TYPES.PRIVATE_BAG) {
      return {
        street: privateBag.value,
        suburb: privatePostOffice.value,
        city: privateCountry.value,
        countryCode: privateCountry.value,
        postalCode: privatePostalCode.value,
      };
    }
    if (postalAddressMethod.value === ADDRESS_TYPES.STREET_ADDRESS) {
      const street = [postalComplex.value, postalStreetAddress.value].filter((value) => !!value).join(", ");

      return {
        street,
        city: postalCity.value,
        suburb: postalSuburb.value,
        countryCode: postalCountry.value,
        postalCode: postalPostalCode.value,
      };
    }

    return {};
  }
);

export const getSubmissionContactInformation = createSelector(
  getContactNumber,
  getEmail,
  getComplex,
  getStreetAddress,
  getSuburb,
  getCity,
  getPostalCode,
  getCountry,
  getPostalAddressForSubmission,
  getPostalAddressSame,
  (
    contactNumber,
    email,
    complex,
    streetAddress,
    suburb,
    city,
    postalCode,
    country,
    postalAddress,
    postalAddressSame
  ) => {
    const residentialAddress = {
      street: streetAddress.value,
      complex: complex?.value,
      suburb: suburb.value,
      city: city.value,
      countryCode: country.value,
      postalCode: postalCode.value,
    };

    return {
      cellphoneNumber: contactNumber.value,
      email: email.value,
      postalAddress: {
        ...(postalAddressSame.value ? residentialAddress : postalAddress),
        isPostalSameAsResidential: postalAddressSame.value,
      },
      residentialAddress: {
        ...residentialAddress,
        isPostalSameAsResidential: postalAddressSame.value,
      },
    };
  }
);
