import {combineReducers} from "redux";
import investmentSetup from "./investmentSetup";
import captureDetails from "./captureDetails";
import personalDetails from "./personalDetails";
import paymentDetails from "./paymentDetails";
import beneficiaries from './beneficiaries';
import beneficiariesSplit from './beneficiariesSplit';
import review from './review';
import scrolling from './scrolling';
import referenceData from './referenceData';
import persistedAt from './persistedAt';
import sessionCamVariables from './sessionCamVariables';
import routing from './routing';

export const raOptimal = combineReducers({
    personalDetails,
    captureDetails,
    investmentSetup,
    paymentDetails,
    beneficiaries,
    beneficiariesSplit,
    review,
    scrolling, 
    referenceData,
    persistedAt,
    sessionCamVariables,
    routing
});
