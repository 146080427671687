import {
  ALLSURE_BANK_REFERENCE_DATA_SET_VALUE,
  ALLSURE_APPLICATION_API_STATUS,
  ALLSURE_BRANCH_CODES_REFERENCE_DATA_SET_VALUE,
  RESET_REFERENCE_DATA,
  ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_SET_VALUE,
  ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_SET_VALUE,
  ALLSURE_APPLICATION_QUOTE_DETAILS,
  SET_ALLSURE_APPLICATION_REFERENCE_NUMBER,
  ALLSURE_APPLICATION_TOGGLE_TNC,
  ALLSURE_APPLICATION_RECAPTCHA_VALUE
} from "../../actions/allsureInsurance/referenceData";

export const initReferenceData = {
  apiStatus:null,
  quoteData:{},
  referenceNumber: null,
  termsandConditions: false,
  recaptchaValue:null,
  applicationData: {
    bankData: [],
    branchCodesData: [],
    vehicleMakeData:{},
    vehicleModelData: {},
  },
};

export default (state = initReferenceData, action) => {
  switch (action.type) {
      case ALLSURE_APPLICATION_API_STATUS:
      return {...state, apiStatus: action.value };

      case ALLSURE_APPLICATION_QUOTE_DETAILS:
      return{...state, quoteData: action.value };

    case ALLSURE_APPLICATION_TOGGLE_TNC:
      return{...state, termsandConditions:action.value}
      
    case ALLSURE_APPLICATION_RECAPTCHA_VALUE:
      return{...state, recaptchaValue: action.value}

      case ALLSURE_BANK_REFERENCE_DATA_SET_VALUE:
      return {...state,
        applicationData: { ...state.applicationData, bankData: action.value },
      };

      case SET_ALLSURE_APPLICATION_REFERENCE_NUMBER:
        return {...state, referenceNumber: action?.value }
   
      case ALLSURE_BRANCH_CODES_REFERENCE_DATA_SET_VALUE:
      return {
        ...state,
        applicationData: {
          ...state.applicationData,
          branchCodesData: action.value,
        },
      };
      case ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_SET_VALUE:
        return {
          ...state,
          applicationData: {
            ...state.applicationData,
            vehicleMakeData: action.value,
          },
        };
        case ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_SET_VALUE:
        return {
          ...state,
          applicationData: {
            ...state.applicationData,
            vehicleModelData: action.value,
          },
        };
    case RESET_REFERENCE_DATA:
      return { ...initReferenceData };
    default:
      return state;
  }
};
