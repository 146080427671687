import axios from 'axios';
import { setResponse, requestFail } from '../../../actions/digitalOnboarding/routing';
import { getApplicationNumber, getApplicationUtm } from '../../../selectors/digitalOnboarding/application';
import { useSelector } from 'react-redux';
import * as ActionTypes from '../../../actions/digitalOnboarding/types';

export const getApplicationNow = async (store, next, action) => {
    const result = next(action);
    const storeData = store.getState();

    (async () => {
        try {
            const url = `${ActionTypes.FINANCE_API_URL}/applications`;
            const storeValues = storeData.digitalOnboarding.personalDetails.storeValues;
            const utmValues = getApplicationUtm(storeData);

            const requestData = {
                firstName: storeValues[ActionTypes.FIRST_NAME].value,
                lastName: storeValues[ActionTypes.LAST_NAME].value,
                idNumber: storeValues[ActionTypes.ID_NUMBER].value,
                contactNumber: storeValues[ActionTypes.CELL_NUMBER].value,
                emailAddress: storeValues[ActionTypes.EMAIL_ADDRESS].value,
                creditCheckConsentProvided: storeValues[ActionTypes.CREDIT_CONSENT].value,
                personalInformationConsentProvided: storeValues[ActionTypes.PERSONAL_CONSENT].value,
                blackbox: storeValues[ActionTypes.BLACK_BOX_DATA].value,
                utmData: utmValues
            }

            const returnData = await axios.post(url, requestData);
 
            // if (returnData.data.data.applicationOutcome === "OMF_STAFF") {
            //     console.log("[applicationNow.js] Submit Staff data: ", JSON.parse(returnData.config.data))
            // }

            if (ActionTypes.FINANCE_API_URL.includes("localhost")) {
                store.dispatch(setResponse(ActionTypes.APPLICATION_NOW_SUCCESS, returnData.data));
            } else {
                store.dispatch(setResponse(ActionTypes.APPLICATION_NOW_SUCCESS, returnData.data.data));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": returnData.data.data.applicationNumber,
                    "applicationOutcome": returnData.data.data.applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "getApplicationNow",
                    "eventOutcome": "APPLICATION_NOW_SUCCESS",
                    "page": window.location.pathname.split('/')[3]
                });
            }
        } catch (e) {
            store.dispatch(requestFail(ActionTypes.APPLICATION_NOW_FAIL));
            dataLayer.push({
                "app": "omflending",
                "applicationNumber": "",
                "applicationOutcome": "",
                "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                "event": "getApplicationNow",
                "eventOutcome": "APPLICATION_NOW_FAIL",
                "page": window.location.pathname.split('/')[3]
            });
        }
    })();

    return result;
};

export const makeApplication = async (store, next, action) => {
    const result = next(action);
    const storeData = store.getState();

    (async () => {
        try {
            const applicationNumber = getApplicationNumber(storeData);
            const url = `${ActionTypes.FINANCE_API_URL}/applications/${applicationNumber}`;
            const returnData = await axios.post(url);

            if (ActionTypes.FINANCE_API_URL.includes("localhost")) {
                store.dispatch(setResponse(ActionTypes.MAKE_APPLICATION_REQUEST_SUCCESS, returnData.data));
            } else {
                store.dispatch(setResponse(ActionTypes.MAKE_APPLICATION_REQUEST_SUCCESS, returnData.data.data));
            }
        } catch (e) {
            store.dispatch(requestFail(ActionTypes.MAKE_APPLICATION_REQUEST_FAIL));
        }
    })();

    return result;
};