
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { DAYS_IN_MONTHS, mapToDateParts } from '../helpers/validation-date-helper.js';
import {config} from "../config/index.js";

export function validateDateOfBirth(
    value,
    {
        min,
        max,
        subject = 'Date of birth',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
        if (!value && required) {
            return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
        }

        if (!value && !required) {
            return null;
        }
        const date = mapToDateParts(value);

        if (!date) {
            return OmValidationMessageHelper.DATE_FORMAT_ERROR;
        }

        if (!validateDate(date)) {
            return OmValidationMessageHelper.DATE_FORMAT_ERROR;
        }

        if (isFutureDate(date)) {
            return OmValidationMessageHelper.getDateOfBirthNotAllowedMsg(pronoun, retainSubjectCase);
        }

        if(isYearEarlierThan1850(date)) {
            return OmValidationMessageHelper.DATE_OF_BIRTH_NOT_ALLOWED_ERROR;
        }

        const age = getAge(date);

        if (min && age < min) {
            return OmValidationMessageHelper.getTooYoungMsg(min, pronoun, retainSubjectCase);
        }

        if (max && age > max) {
            return OmValidationMessageHelper.getTooOldMsg(max, pronoun, retainSubjectCase);
        }

        return null;
}

export function getAge(dob) {
    const dobDate = new Date(dob.year, dob.month - 1, dob.day);
    var diff_ms = Date.now() - dobDate.getTime();
    var age_date = new Date(diff_ms);

    //getUTCFullYear: Creates a JavaScript Date instance that represents a single moment in time.
    //Date objects use a Unix Time Stamp, an integer value that is the number of
    //milliseconds since 1 January 1970 UTC. Hence subtraction of 1970
    return Math.abs(age_date.getUTCFullYear() - 1970);
}


function isYearEarlierThan1850(date) {
    return date.year < 1850;
}

function isFutureDate(dob) {
    const dobObj = new Date(dob.year, dob.month - 1, dob.day);
    const today = new Date();
    return today.getTime() - dobObj.getTime() < 0;
}

function validateDate(date) {
    const validDay = validateDay(date);
    const validMonth = validateMonth(date);
    const validYear = validateYear(date);

    if (!validDay || !validMonth || !validYear) {
        return false;
    }

    return true;
}

function validateDay(date) {
    const month = date.month;
    const day = date.day;
    const year = date.year;

    if (isNaN(day)) {
        return false;
    }

    // Change Feb days if it is leap year
    if (year % 4 === 0 && month === 2 && day === 29) {
        return true;
    }

    if (day > DAYS_IN_MONTHS[month - 1] || day <= 0) {
        return false;
    }

    return true;
}

function validateMonth(date) {
    const month = date.month;

    if (isNaN(month)) {
        return false;
    }

    return month <= 12 && month > 0;
}

function validateYear(date) {
    const year = date.year;

    if (isNaN(year)) {
        return false;
    }

    return year > 0;
}
export const validateDateOfBirthVTwo=(
    value,
    {
        min,
        max,
        subject = 'Date of birth',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
)=> {

    function mapDates(value) {
        const parts = value.split('/');
    
        try {
            if (parts[2]&& parts[0].length===2 && parts[1].length===2&& parts[2].length === 4) {
                return {
                    day: parseInt(parts[0], 10),
                    month: parseInt(parts[1], 10),
                    year: parseInt(parts[2], 10),
                };
            }
        } catch (e) {
            // Null will be returned on error
        }
    
        return null;
    }
        if (!value && required) {
            return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
        }

        if (!value && !required) {
            return null;
        }
        
        
        const date = mapDates(value);

        if (!date) {
            return OmValidationMessageHelper.DATE_FORMAT_ERROR;
        }

        if (!validateDate(date)) {
            return OmValidationMessageHelper.DATE_FORMAT_ERROR;
        }

        if (isFutureDate(date)) {
            return OmValidationMessageHelper.getDateOfBirthNotAllowedMsg(pronoun, retainSubjectCase);
        }

        if(isYearEarlierThan1850(date)) {
            return OmValidationMessageHelper.DATE_OF_BIRTH_NOT_ALLOWED_ERROR;
        }

        const age = getAccurateAge(date);

        if (min && age < min) {
            return OmValidationMessageHelper.getTooYoungMsg(min, pronoun, retainSubjectCase);
        }

        if (max && age > max) {
            return OmValidationMessageHelper.getTooOldMsg(max, pronoun, retainSubjectCase);
        }

        return null;
}

 function getAccurateAge(value) {
    {

        // Getting input from html input element
        let data =`${value.year}-${value.month}-${value.day}`
            
    
        // Convert input data to usable format
        // as day,month and year
        let dob = new Date(data);
        let day = dob.getDate();
        let month = dob.getMonth();
        let year = dob.getFullYear();
    
        // Getting current date and calculating the difference
        let now =new Date(formatted());
        let yearDiff = now.getFullYear() - year;
        let monthDiff = now.getMonth() - month;
        let dateDiff = now.getDate() - day;
    
        // Calculating the Age
        if (yearDiff < 0) console.log("invalid date");
        else if (monthDiff > 0) {
            console.log(yearDiff);
        } else if (monthDiff === 0 && dateDiff >= 0) {
            console.log(yearDiff);
        } else {
            yearDiff = yearDiff - 1;
            if (monthDiff <= 0)
                if (dateDiff > 0) monthDiff = 12 + monthDiff;
                else monthDiff = 11 - monthDiff;
        }
        if (dateDiff < 0) {
            dateDiff = 30 + dateDiff;
            monthDiff -= 1;
        }
    
        // Show calculated age as output
        // and invalid if wrong input is given
        if (yearDiff < 0)
            return 0
        else
           return yearDiff
    }
}
function formatted(date = new Date()) {
    return [
        date.getFullYear(),
        short(date.getMonth() + 1),
        short(date.getDate()),
    ].join("-");
}
function short(num) {
    return num.toString().padStart(2, "0");
}