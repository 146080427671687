/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@om/web-components/dist/web-components/web-components.css';
import '@mom-omds/design-tokens/build/css/variables.web-public.css';
import '@mom-omds/design-tokens/build/css/variables.font.css';
import '@mom-omds/design-system-components/dist/omds/omds.css';
import '@mom-omds/design-tokens/build/css/typography.css';

import { defineCustomElements } from '@om/web-components/loader';
import {defineCustomElements as defineElement} from '@mom-omds/design-system-components/loader';
import {defineCustomElements as defineIcons} from '@mom-omds/design-system-icons/loader';
defineElement();
defineCustomElements();
defineIcons();