import axios from 'axios';
import { setResponse, requestFail } from '../../../actions/digitalOnboarding/routing';
import { getApplicationNumber, getApplicationOutcome } from '../../../selectors/digitalOnboarding/application';
import * as ActionTypes from '../../../actions/digitalOnboarding/types';

const applicantHasDifferentPostalAddress = storeValues => storeValues[ActionTypes.POSTAL_SAME_AS_PHYSICAL].value === 'false';

const buildPhysicalAddress = address => ({
    complex: address[ActionTypes.COMPLEX_BUILDING].value,
    streetAddress: address[ActionTypes.STREET_ADDRESS].value,
    postalCode: address[ActionTypes.POSTAL_CODE_PHYSICAL].value,
    suburb: address[ActionTypes.SUBURB_PHYSICAL].value,
    city: address[ActionTypes.CITY_PHYSICAL].value,
    province: address[ActionTypes.PROVINCE_PHYSICAL].value,
    isRuralOrLowDensity: address[ActionTypes.RURAL_LOW_DENSITY].value
});

const buildPostalAddress = address => ({
    addressLine1: address[ActionTypes.ADDRESS_LINE_1].value,
    addressLine2: "",
    postalCode: address[ActionTypes.POSTAL_CODE_POSTAL].value,
    suburb: address[ActionTypes.SUBURB_POSTAL].value,
    city: address[ActionTypes.CITY_POSTAL].value,
    province: address[ActionTypes.PROVINCE_POSTAL].value
});

export const addAddress = async (store, next, action) => {
    const result = next(action);
    const storeData = store.getState();

    (async () => {
        try {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            const url = `${ActionTypes.FINANCE_API_URL}/applications/${applicationNumber}/address`;

            const storeValues = storeData.digitalOnboarding.addressDetails.storeValues;
            const requestData = {
                physicalAddress: buildPhysicalAddress(storeValues)
            };

            if (applicantHasDifferentPostalAddress(storeValues)) {
                requestData.postalAddress = buildPostalAddress(storeValues);
            }

            const returnData = await axios.post(url, requestData);

            if (ActionTypes.FINANCE_API_URL.includes("localhost")) {
                store.dispatch(setResponse(ActionTypes.ADD_ADDRESS_REQUEST_SUCCESS, returnData.data));
            } else {
                store.dispatch(setResponse(ActionTypes.ADD_ADDRESS_REQUEST_SUCCESS, returnData.data.data));
                dataLayer.push({
                    "app": "omflending",
                    "applicationNumber": applicationNumber,
                    "applicationOutcome": applicationOutcome,
                    "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                    "event": "addAddress",
                    "eventOutcome": "ADD_ADDRESS_REQUEST_SUCCESS",
                    "page": window.location.pathname.split('/')[3]
                });
            }
        } catch (e) {
            const applicationNumber = getApplicationNumber(storeData);
            const applicationOutcome = getApplicationOutcome(storeData);
            store.dispatch(requestFail(ActionTypes.ADD_ADDRESS_REQUEST_FAIL));
            dataLayer.push({
                "app": "omflending",
                "applicationNumber": applicationNumber,
                "applicationOutcome": applicationOutcome,
                "environment": process.env.GATSBY_ENVIRONMENT_NAME,
                "event": "addAddress",
                "eventOutcome": "ADD_ADDRESS_REQUEST_FAIL",
                "page": window.location.pathname.split('/')[3]
            });
        }
    })();

    return result;
};