import { transitionBudgetBenchmarkCalculator } from "../../../actions/budgetBenchmarkCalculator/calculator";
import { FIELD_STATUS } from "../../../reducers/status";
import { getNetMonthlyIncome } from "../../../selectors/budgetBenchmarkCalculator/calculator";

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validateBudgetBenchmark = state => {
    const netMonthlyIncome = getNetMonthlyIncome(state);

    return isValid(netMonthlyIncome);
}

export const budgetBenchmarkRulesEngine = (store, next, action) => {
    const result = next(action);

    const budgetBenchmarkValid = validateBudgetBenchmark(store.getState());
    store.dispatch(budgetBenchmarkValid
        ? transitionBudgetBenchmarkCalculator.formValid()
        : transitionBudgetBenchmarkCalculator.formInvalid());

    return result;
}
