import { BUILDING_SET_VALID, BUILDING_SET_VALUE, CITY_SET_VALID, CITY_SET_VALUE, COMPANY_NAME_SET_VALID, COMPANY_NAME_SET_VALUE, EMAIL_SET_VALID, EMAIL_SET_VALUE, FIRST_NAME_SET_VALID, FIRST_NAME_SET_VALUE, FORM_INVALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM, FORM_VALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM, ID_PASSPORT_SET_VALID, ID_PASSPORT_SET_VALUE, KRA_PIN_NUMBER_SET_VALID, KRA_PIN_NUMBER_SET_VALUE, MOBILE_NUMBER_SET_VALID, MOBILE_NUMBER_SET_VALUE, PO_BOX_SET_VALID, PO_BOX_SET_VALUE, POSTAL_CODE_SET_VALID, POSTAL_CODE_SET_VALUE, REINITIALIZE, STREET_ADDRESS_SET_VALID, STREET_ADDRESS_SET_VALUE, SURNAME_SET_VALID, SURNAME_SET_VALUE, } from "../../actions/biasharaFlexi/companyDetails";
import { FIELD_STATUS, FORM_STATUS } from "../status";

export const initCompanyDetails = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        companyName: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        mobileNumber: { value: null, error: null, status: null },
        kraPinNumber: { value: null, error: null, status: null },
        city: { value: null, error: null, status: null },
        streetAddress: { value: null, error: null, status: null },
        building: { value: null, error: null, status: null },
        postalBox: { value: null, error: null, status: null },
        postalCode: { value: null, error: null, status: null },
        firstName: { value: null, error: null, status: null },
        lastName: { value: null, error: null, status: null },
        idNumber: { value: null, error: null, status: null },
    }
}

export default (state = initCompanyDetails, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case COMPANY_NAME_SET_VALUE:
            return updateFormField('companyName', field => ({
                ...field,
                value: action.value,
            }));

        case COMPANY_NAME_SET_VALID:
            console.log("Validation triggered")
            return updateFormField('companyName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MOBILE_NUMBER_SET_VALUE:
            return updateFormField('mobileNumber', field => ({
                ...field,
                value: action.value,
            }));
        case MOBILE_NUMBER_SET_VALID:
            return updateFormField('mobileNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('email', field => ({
                ...field,
                value: action.value,
            }));
        case EMAIL_SET_VALID:
            return updateFormField('email', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case KRA_PIN_NUMBER_SET_VALUE:
            return updateFormField('kraPinNumber', field => ({
                ...field,
                value: action.value,
            }));
        case KRA_PIN_NUMBER_SET_VALID:
            return updateFormField('kraPinNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CITY_SET_VALUE:
            return updateFormField('city', field => ({
                ...field,
                value: action.value,
            }));
        case CITY_SET_VALID:
            return updateFormField('city', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case STREET_ADDRESS_SET_VALUE:
            return updateFormField('streetAddress', field => ({
                ...field,
                value: action.value,
            }));
        case STREET_ADDRESS_SET_VALID:
            return updateFormField('streetAddress', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        
        case BUILDING_SET_VALUE:
            return updateFormField('building', field => ({
                ...field,
                value: action.value,
            }));
        case BUILDING_SET_VALID:
            return updateFormField('building', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PO_BOX_SET_VALUE:
            return updateFormField('postalBox', field => ({
                ...field,
                value: action.value,
            }));
        case PO_BOX_SET_VALID:
            return updateFormField('postalBox', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case POSTAL_CODE_SET_VALUE:
            return updateFormField('postalCode', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_CODE_SET_VALID:
            return updateFormField('postalCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

            case FIRST_NAME_SET_VALUE:
                return updateFormField('firstName', (field) => ({
                    ...field,
                    value: action.value,
                }));
    
            case FIRST_NAME_SET_VALID:
                return updateFormField('firstName', (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));
            case SURNAME_SET_VALUE:
                return updateFormField('lastName', (field) => ({
                    ...field,
                    value: action.value,
                }));
            case SURNAME_SET_VALID:
                return updateFormField('lastName', (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));
            case ID_PASSPORT_SET_VALUE:
                return updateFormField('idNumber', (field) => ({
                    ...field,
                    value: action.value,
                }));
            case ID_PASSPORT_SET_VALID:
                return updateFormField('idNumber', (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));

        case REINITIALIZE:
            return initBiasharaFlexiCover;
        default:
            return state;
    }
};
