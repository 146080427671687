import { createSelector } from 'reselect';
import { getHealthInsuranceRwState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getPaymentsDetails = createSelector(getHealthInsuranceRwState, (state) => state?.payments);

export const getPaymentsForm = createSelector(getPaymentsDetails, (paymentsDetails) => paymentsDetails.form);

export const getApiCallStatus = createSelector(getPaymentsDetails, (paymentsDetails) => paymentsDetails.apiCallStatus);
export const getStkApiCallStatus = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails.stkApiCallStatus
);
export const getStatusApiCallStatus = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails.statusApiCallStatus
);

export const getStkData = createSelector(getPaymentsDetails, (paymentsDetails) => paymentsDetails.stkPushData);
export const getPaymentStatusData = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails.paymentStatus
);

const makeGetFieldForm = (fieldId) => createSelector(getPaymentsForm, (form) => form[fieldId]);

export const getPaymentsFormValid = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getPaymentsDetails,
    (paymentsDetails) =>
        paymentsDetails.apiCallStatus === API_CALL_STATUS.PENDING ||
        paymentsDetails.loginApiCallStatus === API_CALL_STATUS.PENDING ||
        paymentsDetails.stkApiCallStatus === API_CALL_STATUS.PENDING
);

export const getIsApiPaymentStatusPending = createSelector(
    getPaymentsDetails,
    (getPaymentsDetails) => getPaymentsDetails.statusApiCallStatus === API_CALL_STATUS.PENDING
);

export const getPhoneNumber = makeGetFieldForm('phoneNumber');
export const getTerms = makeGetFieldForm('terms');
export const getPaymentType = makeGetFieldForm('paymentType');

export const getIsApiPaymentStatusSuccess = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails.apiCallStatus === API_CALL_STATUS.SUCCESS
);

export const getIsApiPaymentStatusFailure = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails.apiCallStatus === API_CALL_STATUS.FAILURE
);

export const getAccessToken = createSelector(getPaymentsDetails, (paymentsDetails) => paymentsDetails?.loginData);
export const getApiLoginCallStatus = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails?.loginApiCallStatus
);

export const getConfirmOrderApiStatus = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails?.confirmOrderApiCallStatus
);

export const getIsConfirmOrderAPiPending = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails?.confirmOrderApiCallStatus === API_CALL_STATUS.PENDING
);

export const getConfirmOrderData = createSelector(
    getPaymentsDetails,
    (paymentsDetails) => paymentsDetails?.confirmOrderData
);
