import { COMPLAINT_FORM_SEND } from "../../../actions/customerComplaint/complaintArbitration"
import { complaintArbitrationService } from "./complaintArbitration"

export const customerComplaintService = (store, next, action) => {
    if (action.type === COMPLAINT_FORM_SEND) {
        return complaintArbitrationService(store, next, action);
    }

    return null;
}
