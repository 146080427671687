import {
    CALCULATE_TRANSITION_RESULTS,
} from '../../../actions/educationCalculator/calculator';
import { educationCalculatorTransitionTracking } from './educationCalculator';

export const educationCalculatorTracking = (store, next, action) => {
    if (action.type === CALCULATE_TRANSITION_RESULTS) {
        return educationCalculatorTransitionTracking(store, next, action);
    }
};
