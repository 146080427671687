import {
    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
} from "../../../actions/easiplusFuneralPlan/paymentDetails";

import {
    getBankName,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getPaymentAuthorization
} from "../../../selectors/easiplusFuneralPlan/paymentDetails";
import {simpleFieldTracking} from "../formTracking";
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";

export const paymentDetailsFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case BANK_NAME_SET_VALUE:
                sendError = false;
                return getBankName;
            case ACCOUNT_NUMBER_SET_VALUE:
                sendValue = false;
                return getAccountNumber;
            case ACCOUNT_TYPE_SET_VALUE:
                sendError = false;
                return getAccountType;
            case DEBIT_ORDER_DAY_SET_VALUE:
                return getDebitOrderDay;
            case TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS:
                return getPaymentAuthorization;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if(field)
        simpleFieldTracking({action, field, sendValue, sendError});

    return result;
}