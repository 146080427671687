import { validateSingleSelection } from "@om/validation";
import {
    setTermsAndConditionsDrawerValidationMessage,
    TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW
} from "../../../actions/taxFreeSavingsAccount/review";

export const reviewValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW:
                error = validateSingleSelection((action.error ? null : true), 'checkbox');
                return setTermsAndConditionsDrawerValidationMessage(error);
            default:
                return null;
        }
    })(); 

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
};
