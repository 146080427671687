import { skipToPage } from "../../../actions/digitalOnboarding/routing";
import { OTP_SEND_REQUEST } from '../../../actions/digitalOnboarding/types';

export const otpValidation = (store, next, action) => {
    if (action.type === OTP_SEND_REQUEST) {
        const state = store.getState();

        const otpValid = state.digitalOnboarding.otp.otpValidated;
        if (otpValid) {
            // If the OTP is valid, ignore this action to send the OTP and skip to the next page
            return store.dispatch(skipToPage());
        }
    }

    return next(action);
}
