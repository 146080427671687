import { validationIsEqual } from '../status';
import {
    validateEmail,
    validateKraPinNumber,
    validateSingleSelection,
    validatePassportNumber,
    validateName,
    validateContactNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
} from '@om/validation';
import {
    PLAN_SET_VALUE,
    NATIONAL_ID_NUMBER_SET_VALUE,
    PASSPORT_NUMBER_SET_VALUE,
    KRA_PIN_NUMBER_SET_VALUE,
    NATIONALITY_SET_VALUE,
    EMERGENCY_CONTACT_FIRST_NAME_SET_VALUE,
    EMERGENCY_CONTACT_LAST_NAME_SET_VALUE,
    EMERGENCY_CONTACT_EMAIL_SET_VALUE,
    EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALUE,
    setPlanSelectedValid,
    setNationalIdNumberValid,
    setPassportNumberValid,
    setKRAPinValid,
    setNationalityValid,
    setEmergencyContactFirstNameValid,
    setEmergencyContactLastNameValid,
    setEmergencyContactEmailValid,
    setEmergencyContactPhoneNumberValid,
} from '../../../actions/roaTravelInsurance/plans';

let prevValidationAction = null;

export const plans = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case PLAN_SET_VALUE:
                error = !action.value ? 'Please make a selection' : null;
                return setPlanSelectedValid(error);

            case NATIONAL_ID_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(
                    action.value,
                    {
                        subject: 'National ID Number',
                        required: true,
                        min: 6,
                        max: 8,
                    },
                );
                return setNationalIdNumberValid(error);

            case PASSPORT_NUMBER_SET_VALUE:
                error = validatePassportNumber(action.value, {
                    subject: 'Passport Number',
                    required: true,
                });
                return setPassportNumberValid(error);

            case KRA_PIN_NUMBER_SET_VALUE:
                error = validateKraPinNumber(action.value, {
                    subject: 'KRA Pin Number',
                    required: true,
                });
                return setKRAPinValid(error);

            case NATIONALITY_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Nationality',
                });
                return setNationalityValid(error);

            case EMERGENCY_CONTACT_FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });
                return setEmergencyContactFirstNameValid(error);
            case EMERGENCY_CONTACT_LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'Last Name',
                });
                return setEmergencyContactLastNameValid(error);

            case EMERGENCY_CONTACT_EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmergencyContactEmailValid(error);

            case EMERGENCY_CONTACT_PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Phone Number',
                    countryCode: 'ke',
                });
                return setEmergencyContactPhoneNumberValid(error);

            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
