import { DIGITAL_SAVINGS_AGENT_MODAL_DETAILS_SET_VALID, DIGITAL_SAVINGS_AGENT_MODAL_DETAILS_SET_VALUE } from "../../../actions/digitalSavings/agentModal";
import { DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID, DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE } from "../../../actions/digitalSavings/beneficiaries";
import { DIGITAL_SAVINGS_DOCUMENTS_FORM_SET_VALID, DIGITAL_SAVINGS_DOCUMENTS_FORM_SET_VALUE } from "../../../actions/digitalSavings/Documents";
import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from "../../../actions/digitalSavings/payment";
import { DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE, DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID } from "../../../actions/digitalSavings/personalDetails";
import { DIGITAL_SAVINGS_FORM_SET_VALID, DIGITAL_SAVINGS_FORM_SET_VALUE, SET_SHARE_EMAIL_VALID, SET_SHARE_EMAIL_VALUE } from "../../../actions/digitalSavings/quote";
import { SET_VALUE_ROUTING } from "../../../actions/digitalSavings/routing";
import { agentFormRules } from "./agent";
import { beneficiariesFormRules } from "./beneficiaries";
import { documentsFormRules } from "./documents";
import { paymentsFormRules } from "./payment";
import { personalDetailsFormRules } from "./personalDetails";
import { quoteFormRules } from "./quote";
import { routingRules } from "./routing";
import { shareQuoteFormRules } from "./shareQuote";

export const digitalSavingsRules = (store, next, action) => {
	if (action.type.startsWith(SET_VALUE_ROUTING)) {
		return routingRules(store, next, action);
	}

	if (action.type.startsWith(DIGITAL_SAVINGS_FORM_SET_VALUE) || action.type.startsWith(DIGITAL_SAVINGS_FORM_SET_VALID)) {
		return quoteFormRules(store, next, action);
	}

	if (action.type.startsWith(DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE) || action.type.startsWith(DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID)) {
		return personalDetailsFormRules(store, next, action);
	}

	if (action.type.startsWith(DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE) || action.type.startsWith(DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID)) {
		return beneficiariesFormRules(store, next, action);
	}
	

	if (action.type.startsWith(DIGITAL_SAVINGS_DOCUMENTS_FORM_SET_VALUE) || action.type.startsWith(DIGITAL_SAVINGS_DOCUMENTS_FORM_SET_VALID)) {
		return documentsFormRules(store, next, action);
	}

	if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
		return paymentsFormRules(store, next, action);
	}

	if(action.type.startsWith(DIGITAL_SAVINGS_AGENT_MODAL_DETAILS_SET_VALUE) || action.type.startsWith(DIGITAL_SAVINGS_AGENT_MODAL_DETAILS_SET_VALID)) {
        return agentFormRules(store, next, action)
    }

	if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
		return shareQuoteFormRules(store, next, action);
	}

	return null;
};
