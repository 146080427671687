import {BASIC_CALCULATOR_SET_VALUE} from "../../../actions/incomeTaxCalculator/basicCalculator";
import {basicCalculatorValidation} from "./basicCalculator";
import {ADVANCED_CALCULATOR_SET_VALUE} from "../../../actions/incomeTaxCalculator/advancedCalculator";
import {advancedCalculatorValidation} from "./advancedCalculator";

export const incomeTaxCalculatorValidation = (store, next, action) => {
    if (action.type.startsWith(BASIC_CALCULATOR_SET_VALUE))
        return basicCalculatorValidation(store, next, action);

    if (action.type.startsWith(ADVANCED_CALCULATOR_SET_VALUE))
        return advancedCalculatorValidation(store, next, action);

    return null;
};
