import {createSelector} from "reselect";
import {getEasiplusFuneralPlan} from "./index";
import {FORM_STATUS} from "../../reducers/status";

export const getBeneficiariesSplit = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan.beneficiariesSplit
);

// Beneficiary split
export const getFormStatusIsValid = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.formStatus === FORM_STATUS.VALID,
);
export const getIsEvenSplit = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.isEvenSplit,
);
export const getTotalPercentage = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.totalPercentage,
);
export const getSplitError = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.splitError,
);
export const getNFields = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.nFields,
);

// Form
export const getBeneficiariesSplitForm = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.form,
);

const makeGetField = fieldId => createSelector(
    getBeneficiariesSplitForm,
    beneficiariesSplitForm => beneficiariesSplitForm[fieldId]
);
export const getSplitField01 = makeGetField('split01');
export const getSplitField02 = makeGetField('split02');
export const getSplitField03 = makeGetField('split03');
export const getSplitField04 = makeGetField('split04');
export const getSplitField05 = makeGetField('split05');
export const getSplitField06 = makeGetField('split06');
export const getSplitField07 = makeGetField('split07');
export const getSplitField08 = makeGetField('split08');
export const getSplitField09 = makeGetField('split09');
export const getSplitField10 = makeGetField('split10');

export const getSplitFieldArray = createSelector(
    getSplitField01, getSplitField02, getSplitField03, getSplitField04, getSplitField05,
    getSplitField06, getSplitField07, getSplitField08, getSplitField09, getSplitField10,
    (...splitFieldArray) => {
        return splitFieldArray;
    }
);

export const getBeneficiaryListError = createSelector(
    getFormStatusIsValid,
    (formStatusIsValid) => formStatusIsValid ? 'valid' : 'error'
);