import { getIsApiPending, getQuoteForm, getEnableDownloadQuote } from '../../../selectors/ugMotorInsurance/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure,
    setShareEmailBase64Value,
} from '../../../actions/ugMotorInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));

                const enableDownloadQuote = getEnableDownloadQuote(store.getState());

                if (enableDownloadQuote) {
                    store.dispatch(setShareEmailBase64Value(resp.data.base64));
                }

                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);

    const regionMap = {
        'within-ea': 2,
        'outside-ea': 3,
    };

    let region = regionMap[quotation.outsideEa.value] || 1;

    const payload = {
        email: quotation.shareEmail.value,
        yom: quotation.yearOfManufacture.value,
        make: quotation.carMake.value.name,
        model: quotation.carModel.value.name,
        fullname: quotation.fullName.value,
        carValue: parseInt(quotation.valuation.value) || 0,
        alternativeAccomodation: quotation.alternativeAccomodation.value
            ? parseInt(quotation.alternativeAccomodationDays.value)
            : 0,
        firstApplication: quotation.firstTimePurchase.value === 'yes',
        carHire: quotation.carHire.value ? parseInt(quotation.carHireDays.value) : 0,
        carAlarmDiscount: quotation.hasAlarm.value === 'yes',
        trackerDiscount: quotation.trackingSystem.value === 'yes',
        pvt: quotation.violenceAndTerrorism.value,
        region,
        excessValue: parseInt(quotation.excessAmount.value) || 0,
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ug-motor-insurance/share-quote/uganda';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
