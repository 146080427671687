import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;
import {
    REINITIALIZE,
    SET_FULL_NAME_VALUE,
    SET_FULL_NAME_VALID,
    SET_CAR_MAKE_VALUE,
    SET_CAR_MODEL_VALUE,
    SET_CAR_MAKE_VALID,
    SET_CAR_MODEL_VALID,
    SET_YEAR_OF_MANUFACTURE_VALUE,
    SET_YEAR_OF_MANUFACTURE_VALID,
    SET_CAR_USE_VALUE,
    SET_CAR_USE_VALID,
    FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM,
    FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM,
    API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA,
    SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS,
    SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE,
    CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS,
    RW_MOTOR_QUOTE_ADD_COVER,
    RW_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE,
    RW_MOTOR_INSURANCE_SHARE_QUOTE_VALUE,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
    FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    SET_SELECTED_COVER_VALID,
    SET_SELECTED_COVER_VALUE,
    TOKEN_IS_EXPIRED,
    setExpiredAuth,
    SET_VEHICLE_TYPE_VALID,
    SET_VEHICLE_TYPE_VALUE,
    SET_CAR_MAKES_LIST_VALUE,
    SET_CAR_MAKES_LIST_VALID,
    SET_CAR_MODEL_LIST_VALUE,
    SET_CAR_MODEL_LIST_VALID,
    API_FAILURE_TRANSITIONS_CAR_MAKES,
    API_PENDING_TRANSITIONS_CAR_MAKES,
    API_SUCCESS_TRANSITIONS_CAR_MAKES,
    API_FAILURE_TRANSITIONS_CAR_MODELS,
    API_PENDING_TRANSITIONS_CAR_MODELS,
    API_SUCCESS_TRANSITIONS_CAR_MODELS,
    API_PENDING_TRANSITION_VALUERS,
    API_SUCCESS_TRANSITION_VALUERS,
    API_FAILURE_TRANSITION_VALUERS,
    SET_VALUERS_LIST_SUCCESS,
    SET_CAR_REACH_VALUE,
    SET_CAR_REACH_VALID,
    SET_NUMBER_SEATS_VALID,
    SET_NUMBER_SEATS_VALUE,
    SET_CAR_COST_VALUE,
    SET_CAR_COST_VALID,
    SET_NUMBER_COVERED_VALID,
    SET_NUMBER_COVERED_VALUE,
    SET_INSURANCE_PERIOD_VALUE,
    SET_INSURANCE_PERIOD_VALID,
    SET_COVERED_AMOUNT_VALUE,
    SET_COVERED_AMOUNT_VALID,
    SET_VEHICLE_TYPES_LIST_VALUE,
    SET_VEHICLE_TYPES_LIST_VALID,
    SET_OCCUPANTS_LIMITS_VALUE,
    SET_OCCUPANTS_LIMITS_VALID,
    API_PENDING_TRANSITIONS_VEHICLE_TYPES_FORM,
    API_SUCCESS_TRANSITIONS_VEHICLE_TYPES_FORM,
    API_FAILURE_TRANSITIONS_VEHICLE_TYPES_FORM,
    API_PENDING_TRANSITIONS_OCCUPANTS_LIMITS_FORM,
    API_SUCCESS_TRANSITIONS_OCCUPANTS_LIMITS_FORM,
    API_FAILURE_TRANSITIONS_OCCUPANTS_LIMITS_FORM,
} from '../../actions/motorPrivateRW/quote';

export const initQuote = {
    valuersApiCallStatus: null,
    sidebarOpen: false,
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    carMakesApiStatus: null,
    carModelsApiStatus: null,
    vehicleTypesApiStatus: null,
    occupantLimitsApiStatus: null,
    quoteReferenceData: null,
    addedCover: null,
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    quoteShared: null,
    isTokenExpired: false,
    valuers: null,
    ugCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Motor Insurance Rwanda',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    premiumCalculationUg: {
        value: null,
        status: null,
        error: null,
    },
    form: {
        fullName: { value: null, error: null, status: null },
        carMake: { value: null, error: null, status: null },
        carModel: { value: null, error: null, status: null },
        yearOfManufacture: { value: null, error: null, status: null },
        carUse: { value: 'private', error: null, status: 'valid' },
        carReach: { value: 'rwanda', error: null, status: null },
        seatsNumber: { value: null, error: null, status: null },
        coveredNumber: { value: null, error: null, status: null },
        sumInsured: { value: null, error: null, status: null },
        insurancePaymentPlan: { value: null, error: null, status: null },
        shareEmail: { value: null, error: null, status: null },
        carValue: { value: null, error: null, status: null },
        selectedCover: { value: 'motorComprehensive', error: null, status: null },
        vehicleType: { value: null, error: null, status: null },
        vehicleTypes: { value: null, error: null, status: null },
        carModels: { value: null, error: null, status: null },
        carMakes: { value: null, error: null, status: null },
        occupantLimits: { value: null, error: null, status: null },
    },
};

export default (state = initQuote, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };

        case FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case SET_FULL_NAME_VALUE:
            return updateFormField('fullName', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_FULL_NAME_VALID:
            return updateFormField('fullName', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_NUMBER_SEATS_VALUE:
            return updateFormField('seatsNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_NUMBER_SEATS_VALID:
            return updateFormField('seatsNumber', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_COVERED_AMOUNT_VALUE:
            return updateFormField('sumInsured', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_COVERED_AMOUNT_VALID:
            return updateFormField('sumInsured', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_MODEL_VALUE:
            return updateFormField('carModel', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MAKE_VALUE:
            return updateFormField('carMake', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CAR_MAKE_VALID:
            return updateFormField('carMake', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_CAR_MODEL_VALID:
            return updateFormField('carModel', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_YEAR_OF_MANUFACTURE_VALUE:
            return updateFormField('yearOfManufacture', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_YEAR_OF_MANUFACTURE_VALID:
            return updateFormField('yearOfManufacture', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_USE_VALUE:
            return updateFormField('carUse', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CAR_USE_VALID:
            return updateFormField('carUse', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_COST_VALUE:
            return updateFormField('carValue', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CAR_COST_VALID:
            return updateFormField('carValue', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_SELECTED_COVER_VALUE:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_COVER_VALID:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_REACH_VALUE:
            return updateFormField('carReach', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_REACH_VALID:
            return updateFormField('carReach', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_NUMBER_COVERED_VALUE:
            return updateFormField('coveredNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_NUMBER_COVERED_VALID:
            return updateFormField('coveredNumber', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_INSURANCE_PERIOD_VALUE:
            return updateFormField('insurancePaymentPlan', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_INSURANCE_PERIOD_VALID:
            return updateFormField('insurancePaymentPlan', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_VEHICLE_TYPE_VALUE:
            return updateFormField('vehicleType', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_VEHICLE_TYPE_VALID:
            return updateFormField('vehicleType', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_VEHICLE_TYPES_LIST_VALUE:
            return updateFormField('vehicleTypes', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_VEHICLE_TYPES_LIST_VALID:
            return updateFormField('vehicleTypes', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OCCUPANTS_LIMITS_VALUE:
            return updateFormField('occupantLimits', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_OCCUPANTS_LIMITS_VALID:
            return updateFormField('occupantLimits', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        // Valuers
        case SET_VALUERS_LIST_SUCCESS:
            return {
                ...state,
                valuers: action.value,
            };

        // Sidebar open
        case RW_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        // Share modal
        case RW_MOTOR_INSURANCE_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_MAKES_LIST_VALUE:
            return updateFormField('carMakes', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MAKES_LIST_VALID:
            return updateFormField('carMakes', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_MODEL_LIST_VALUE:
            return updateFormField('carModels', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MODEL_LIST_VALID:
            return updateFormField('carModels', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case TOKEN_IS_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.value,
            };

        // API Calls
        case API_PENDING_TRANSITION_VALUERS:
            return {
                ...state,
                valuersApiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_VALUERS:
            return {
                ...state,
                valuersApiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_VALUERS:
            return {
                ...state,
                valuersApiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_VEHICLE_TYPES_FORM:
            return {
                ...state,
                vehicleTypesApiStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITIONS_VEHICLE_TYPES_FORM:
            return {
                ...state,
                vehicleTypesApiStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITIONS_VEHICLE_TYPES_FORM:
            return {
                ...state,
                vehicleTypesApiStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_OCCUPANTS_LIMITS_FORM:
            return {
                ...state,
                occupantLimitsApiStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITIONS_OCCUPANTS_LIMITS_FORM:
            return {
                ...state,
                occupantLimitsApiStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITIONS_OCCUPANTS_LIMITS_FORM:
            return {
                ...state,
                occupantLimitsApiStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_CAR_MAKES:
            return {
                ...state,
                carMakesApiStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_CAR_MAKES:
            return {
                ...state,
                carMakesApiStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_CAR_MAKES:
            return {
                ...state,
                carMakesApiStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_CAR_MODELS:
            return {
                ...state,
                carModelsApiStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_CAR_MODELS:
            return {
                ...state,
                carModelsApiStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_CAR_MODELS:
            return {
                ...state,
                carModelsApiStatus: API_CALL_STATUS.FAILURE,
            };

        // Submit user data
        case COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteReferenceData: action.value,
            };
        case RW_MOTOR_QUOTE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };
        case SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteReferenceData: action.error,
            };

        case CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumCalculationUg: {
                    ...state.premiumCalculationUg,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        // Reset state
        case REINITIALIZE:
            return initQuote;
        default:
            return state;
    }
};
