import {findOtherEventIndex, otherEventTracking} from '../otherEventTracking';
import {FUNERAL_CLAIM_SUBMIT_FORM, FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER} from '../../../actions/publicWeb/funeralClaim';
import {getFuneralClaimsOpenDrawer} from '../../../selectors/publicWeb/funeralClaim';

export const funeralClaimTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const trackingActions = [
        FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER
    ];

    let openDrawer = getFuneralClaimsOpenDrawer(state);
    if (trackingActions.includes(action.type)){
        if(openDrawer){
            otherEventTracking(action.type, findOtherEventIndex("OPEN_DRAWER"), openDrawer);
        }
    }

    const field = (() => {
        switch (action.type) {
        case FUNERAL_CLAIM_SUBMIT_FORM:
            otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            return 'Funeral Claim';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}