import { validateNumber } from '@om/validation';

import {
    setAgeValid,
    setMonthlyIncomeValid,
    setContributionAmountValid,
    setAmountSavedValid,
    AGE_SET_VALUE,
    MONTHLY_INCOME_SET_VALUE,
    CONTRIBUTION_AMOUNT_SET_VALUE,
    AMOUNT_SAVED_SET_VALUE,
} from '../../../actions/retirementCalculator/retirementCalculatorForm';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const retirementCalculatorFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AGE_SET_VALUE:
                error = validateNumber(action.value, { subject: 'age', max: 70, inclusive: true });
                return setAgeValid(error);

            case MONTHLY_INCOME_SET_VALUE:
                error = validateNumber(action.value, { subject: 'monthly income', decimalPoints: 2, max: 999999999 });
                return setMonthlyIncomeValid(error);

            case CONTRIBUTION_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, {
                    subject: 'contribution amount',
                    decimalPoints: 2,
                    max: 999999999,
                });
                return setContributionAmountValid(error);

            case AMOUNT_SAVED_SET_VALUE:
                error = validateNumber(action.value, { subject: 'amount saved', decimalPoints: 2, max: 999999999 });
                return setAmountSavedValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
};
