// Transitions
export const FORM_VALID_TRANSITION_CLINICAL_CRITERIA = "covidSelfAssessment/clinicalCriteria/transition/formValid";
export const FORM_INVALID_TRANSITION_CLINICAL_CRITERIA = "covidSelfAssessment/clinicalCriteria/transition/formInvalid";

export const transitionClinicalCriteria = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_CLINICAL_CRITERIA }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CLINICAL_CRITERIA }),
};

// Events
export const CLINICAL_CRITERIA_RESET_FORM = "covidSelfAssessment/clinicalCriteria/resetForm/";
export const CLINICAL_CRITERIA_SET_VALUE = "covidSelfAssessment/clinicalCriteria/setValue/";
export const CLINICAL_CRITERIA_SET_VALID = "covidSelfAssessment/clinicalCriteria/setValid/";

export const COUGH_SET_VALUE = CLINICAL_CRITERIA_SET_VALUE + "cough";
export const COUGH_SET_VALID = CLINICAL_CRITERIA_SET_VALID + "cough";
export const SHORT_BREATH_SET_VALUE = CLINICAL_CRITERIA_SET_VALUE + "shortBreath";
export const SHORT_BREATH_SET_VALID = CLINICAL_CRITERIA_SET_VALID + "shortBreath";
export const HIGH_FEVER_SET_VALUE = CLINICAL_CRITERIA_SET_VALUE + "highFever";
export const HIGH_FEVER_SET_VALID = CLINICAL_CRITERIA_SET_VALID + "highFever";
export const SORE_THROAT_SET_VALUE = CLINICAL_CRITERIA_SET_VALUE + "soreThroat";
export const SORE_THROAT_SET_VALID = CLINICAL_CRITERIA_SET_VALID + "soreThroat";

export const resetClinicalCriteriaForm = () => ({ type: CLINICAL_CRITERIA_RESET_FORM });
export const setCoughValue = (value) => ({ type: COUGH_SET_VALUE, value });
export const setCoughValid = (error) => ({ type: COUGH_SET_VALID, error });
export const setShortBreathValue = (value) => ({ type: SHORT_BREATH_SET_VALUE, value });
export const setShortBreathValid = (error) => ({ type: SHORT_BREATH_SET_VALID, error });
export const setHighFeverValue = (value) => ({ type: HIGH_FEVER_SET_VALUE, value });
export const setHighFeverValid = (error) => ({ type: HIGH_FEVER_SET_VALID, error });
export const setSoreThroatValue = (value) => ({ type: SORE_THROAT_SET_VALUE, value });
export const setSoreThroatValid = (error) => ({ type: SORE_THROAT_SET_VALID, error });
