import { FIELD_STATUS, TOGGLE_STATUS } from "../status";
import {
  RESET_PERSONAL_DETAILS,
  TITLE_SET_VALUE,
  TITLE_SET_VALID,
  FIRST_NAMES_SET_VALUE,
  FIRST_NAMES_SET_VALID,
  SURNAME_SET_VALUE,
  SURNAME_SET_VALID,
  CONTACT_NUMBER_SET_VALUE,
  CONTACT_NUMBER_SET_VALID,
  EMAIL_SET_VALUE,
  EMAIL_SET_VALID,
  LEAD_VALID_TRANSITION_PERSONAL_DETAILS,
  CONTACT_FOR_FEEDBACK_SET_VALUE,
  CONTACT_FOR_FEEDBACK_SET_VALID,
  CONTACT_METHOD_SET_VALUE,
  CONTACT_METHOD_SET_VALID,
} from "../../actions/taxFreeSavingsAccount/personalDetails";

export const initCountry = "SouthAfrica";
export const initPersonalDetails = {
  leadDetails: null,
  form: {
    title: { value: null, error: null, status: null },
    firstNames: { value: null, error: null, status: null },
    surname: { value: null, error: null, status: null },
    email: { value: null, error: null, status: null },
    contactNumber: { value: null, error: null, status: null },
    contactForFeedback: { value: null, error: null, status: null },
    contactMethod: { value: null, error: null, status: null },
  },
};

export default (state = initPersonalDetails, action) => {
  const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
  const updateFormField = (fieldId, update) => ({
    ...state,
    form: {
      ...state.form,
      [fieldId]: update(state.form[fieldId]),
    },
  });

  switch (action.type) {
    case RESET_PERSONAL_DETAILS:
      return { ...initPersonalDetails };

    case LEAD_VALID_TRANSITION_PERSONAL_DETAILS:
      return { ...state, leadDetails: action.leadDetails };

    case TITLE_SET_VALUE:
      return updateFormField("title", (field) => ({
        ...field,
        value: action.value,
      }));
    case TITLE_SET_VALID:
      return updateFormField("title", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case FIRST_NAMES_SET_VALUE:
      return updateFormField("firstNames", (field) => ({
        ...field,
        value: action.value,
      }));
    case FIRST_NAMES_SET_VALID:
      return updateFormField("firstNames", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SURNAME_SET_VALUE:
      return updateFormField("surname", (field) => ({
        ...field,
        value: action.value,
      }));
    case SURNAME_SET_VALID:
      return updateFormField("surname", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case CONTACT_NUMBER_SET_VALUE:
      return updateFormField("contactNumber", (field) => ({
        ...field,
        value: action.value,
      }));
    case CONTACT_NUMBER_SET_VALID:
      return updateFormField("contactNumber", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case EMAIL_SET_VALUE:
      return updateFormField("email", (field) => ({
        ...field,
        value: action.value,
      }));
    case EMAIL_SET_VALID:
      return updateFormField("email", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case CONTACT_FOR_FEEDBACK_SET_VALUE:
      return updateFormField("contactForFeedback", (field) => ({
        ...field,
        value: action.value,
      }));
    case CONTACT_FOR_FEEDBACK_SET_VALID:
      return updateFormField("contactForFeedback", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case CONTACT_METHOD_SET_VALUE:
      return updateFormField("contactMethod", (field) => ({
        ...field,
        value: action.value,
      }));
    case CONTACT_METHOD_SET_VALID:
      return updateFormField("contactMethod", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    default:
      return state;
  }
};
