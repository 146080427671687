import {
    DATE_SET_VALUE,
    DATE_SET_VALID
} from "../../../actions/priceTable/table";
import { priceTableRulesEngine } from "./table";

export const priceTableRules = (store, next, action) => {
    if (action.type.startsWith(DATE_SET_VALUE) ||
        action.type.startsWith(DATE_SET_VALID)) {
        return priceTableRulesEngine(store, next, action);
    }
    return null;
};
