import { getIsApiPending, getQuoteForm, getProducts } from '../../../selectors/healthInsuranceRW/quote';
import {
    apiTransitionHealthInsuranceQuoteForm,
    calculateHealthInsurancePremiumSuccess,
    triggerCollectComparePlans,
} from '../../../actions/healthInsuranceRW/quote';
import { getAccessToken } from '../../../selectors/healthInsuranceRW/payments';
import axios from 'axios';

export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionHealthInsuranceQuoteForm.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateHealthInsurancePremiumSuccess(resp.data));
                store.dispatch(apiTransitionHealthInsuranceQuoteForm.success());
                store.dispatch(triggerCollectComparePlans());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionHealthInsuranceQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

const checkAges = (agesArray, thresholdAge = 19) => {
    const result = {
        overAge: 0,
        underAge: 0
    };

    agesArray && agesArray.forEach(item => {
        if (item.value >= thresholdAge) {
            result.overAge++;
        } else {
            result.underAge++;
        }
    });

    return result;
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);
    const product = getProducts(state);

    const premiumCalculationPayload = {
        countryCode: 'RW',
        productId: product[0].product_id || 'NA',
        age: parseInt(quotation.age.value) || 'NA',
        inPatient: {
            inpatientLimitId: parseInt(quotation.inpatientCover.value?.id) || 0,
        },
        outPatient: {
            present: quotation.outpatientCover.value ? true : false,
            outpatientLimitId: parseInt(quotation.outpatientCover.value?.id) || 0,
        },
        familyMembers: {
            children: {
                count: quotation.childrenAge.value?.length || 0,
                overAgeCount: checkAges(quotation.childrenAge.value)?.overAge || 0
            },
            spouse: {
                age: parseInt(quotation.spouseAge.value) || 0,
            },
        },
        optical: quotation.opticalCover.value,
        dental: quotation.dentalCover.value,
        maternityLimitId: parseInt(quotation.maternityCover.value?.id) || 0,
    };

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/health-insurance-rw/premium-calculation/rwanda';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
