import axios from 'axios';
import {
    getPaymentsForm,
    getIsPaymentsApiPending,
    getIsPaymentsApiSuccess,
    getIsPaymentsApiFailure,
    getIsPaymentsStatusApiFailure,
    getIsPaymentsStatusApiSuccess,
    getAccountNumberDetails,
  
} from '../../../selectors/roaTravelInsurance/payments';
import { getSelectedPlan} from '../../../selectors/roaTravelInsurance/plans';
import {getProducts} from '../../../selectors/roaTravelInsurance/travelDetails';
import {
    collectTravelInsurancePaymentsStatusData,
    submitTravelInsurancePaymentsFailure,
    submitTravelInsurancePaymentsSuccess,
    apiTransitionPaymentsForm,
    apiTransitionPaymentsStatus,
} from '../../../actions/roaTravelInsurance/addTraveller';

const selectedPlanTotal = (state) => {
    const selectedPlan = getSelectedPlan(state);
    const products = getProducts(state)
    const selectedPlanDetails = products.filter(
        (plan) => plan.name === selectedPlan.value
    )[0];
    const selectedPlanPremium =
        selectedPlanDetails?.prices?.price_after_discount_incl_tax;
    return selectedPlanPremium;
};

let intervalId = null;
export const travelInsuranceMpesaPaymentService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    (async () => {
        if (getIsPaymentsStatusApiSuccess(state)) {
            clearInterval(intervalId);
            intervalId = null;
            return; 
        }
        if (getIsPaymentsStatusApiFailure(state)) {
            clearInterval(intervalId);
            intervalId = null;
            return;
        } 
        clearInterval(intervalId);
        if (!getIsPaymentsApiPending(state)) {
            store.dispatch(apiTransitionPaymentsForm.pending());
            try {
                const resp = await payments(state);
                if (
                    resp.data.errorMessage &&
                    resp.data.errorMessage == 'Invalid Access Token'
                ) {
                    throw resp?.data?.errorMessage;
                }
                store.dispatch(submitTravelInsurancePaymentsSuccess(resp.data));
                store.dispatch(apiTransitionPaymentsForm.success());
                intervalId = setInterval(
                    () =>
                        store.dispatch(
                            collectTravelInsurancePaymentsStatusData()
                        ),
                    5000
                );
                setTimeout(() => {
                    if (intervalId) {
                        clearInterval(intervalId);
                        intervalId = null;
                        store.dispatch(apiTransitionPaymentsStatus.failure());
                        return;
                    }
                }, 300000);
            } catch (e) {
                store.dispatch(submitTravelInsurancePaymentsFailure(e));
                store.dispatch(apiTransitionPaymentsForm.failure());
                clearInterval(intervalId);
                intervalId = null;
            }
        }
    })();
    return result;
};

const payments = async (state) => {
    const form = getPaymentsForm(state);
    const accountNumber = getAccountNumberDetails(state);
    const payload = {
        phone_no: form.paymentsPhone.value,
        amount: 1,//selectedPlanTotal(state),
        mpesa_acc_no: accountNumber?.value?.account_no,
    };
    try {
        const url = '/om-api/roa-travel-insurance/motor-insurance-make-payment';
        return await axios.post(url, payload);
    } catch (e) {
        console.error(e);
        throw e;
    }
};
