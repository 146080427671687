import axios from 'axios';
import { apiTransitionAgents, setAgentsValue } from '../../../actions/personalAccident/agentModal';
import { setExpiredAuth } from '../../../actions/personalAccident/quote';
import { getAccessToken } from '../../../selectors/personalAccident/payments';

export const getIntermeiaryAgent = (store, next, action) => {
    (async () => {
        store.dispatch(apiTransitionAgents.pending());
        try {
            const state = store.getState();

            const loginData = getAccessToken(state);

            const intermediaryType = state?.personalAccident?.agent?.form?.intermediaryType?.value;

            const resp = await calculate(loginData.accessToken, intermediaryType);

            store.dispatch(setAgentsValue(resp.data));
            store.dispatch(apiTransitionAgents.success());
        } catch (error) {
            if (error?.response?.data?.status === 401 || error?.response?.data?.status === 403) {
                store.dispatch(setExpiredAuth(true));
            }
            store.dispatch(apiTransitionAgents.failure());
        }
    })();
    return next(action);
};

const calculate = async (accessToken, intermediaryType) => {
    try {
        const url = `/om-api/personal-accident/agents/kenya`;
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: accessToken,
                intermediaryType: intermediaryType,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
