import axios from 'axios';
import {
    submitExtraTravellerSuccess,
    submitExtraTravellerFailure,
    apiTransitionExtraTravellerForm,
} from '../../../actions/roaTravelInsurance/addTraveller';

import {
    getAddTravellerForm,
    getIsAddTravellerApiPending,
} from '../../../selectors/roaTravelInsurance/payments';


export const addExtratravellerDetailsService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (!getIsAddTravellerApiPending(state)) {
            store.dispatch(apiTransitionExtraTravellerForm.pending());
            try {
                const response = await addExtraTraveller(state);
                const addTravellerResponse = response?.data?.add_extra_traveller;
                if(addExtraTraveller){
                   store.dispatch(submitExtraTravellerSuccess(addTravellerResponse));
                   store.dispatch(apiTransitionExtraTravellerForm.success());
                }else{
                    store.dispatch(submitExtraTravellerFailure(response.data));
                    store.dispatch(apiTransitionExtraTravellerForm.failure());
                }
               
            } catch (e) {
                store.dispatch(submitExtraTravellerFailure(e));
                store.dispatch(apiTransitionExtraTravellerForm.failure());
            }
        }
    })();
    return result;
};

const getPayload = formDetails => {
    return {
        variables: {
            //get from quoation redux
            quote_code: 'yyeueyyryrrtryry',
            full_name: formDetails.fullName.value,
            date_of_birth: formDetails.dob.value,
            phone_number: formDetails.phone.value,
            identification_document_number: formDetails.idNumber.value,
            email_address: formDetails.email.value
    }};
};

const addExtraTraveller = async state => {
    const extraTravellerForm = getAddTravellerForm(state);
  
    try {
        const url = '/om-api/roa-travel-insurance/extra-traveller';
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const result = await axios.post(url, getPayload(extraTravellerForm), config);
        return result;
    } catch (error) {
        throw error;
    }
};