import {
  setTravelledRecentlyValid,
  setRecentCovidContactValid,
  TRAVELLED_RECENTLY_SET_VALUE,
  RECENT_COVID_CONTACT_SET_VALUE,
} from "../../../actions/covidSelfAssessment/travelHistory";
import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const travelHistoryValidation = (store, next, action) => {
  const validationAction = (() => {
    switch (action.type) {
      case TRAVELLED_RECENTLY_SET_VALUE:
        return setTravelledRecentlyValid(action.value ? null : 'Value required');

      case RECENT_COVID_CONTACT_SET_VALUE:
        return setRecentCovidContactValid(action.value ? null : 'Value required');

      default:
        return null;
    }
  })();

  if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
    return next(action);
  }

  store.dispatch(validationAction)
  prevValidationAction = validationAction;
  return next(action);
};
