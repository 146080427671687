import { onFieldChangeStateComparison } from '../onFieldChangeStateComparison';
import { simpleFieldTracking } from '../formTracking';
import {
    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    MONTH_SET_VALUE,
    MONTH_TO_SKIP_SET_VALUE,
    LUMP_SUM_DAY_SET_VALUE,
    LUMP_SUM_MONTH_SET_VALUE,
    SOURCE_OF_INCOME_SET_VALUE,
    SOURCE_OF_INCOME_OTHER_SET_VALUE,
    SOURCE_OF_FUNDS_SET_VALUE,
    EMPLOYMENT_POSITION_SET_VALUE,
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE,
    TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
} from '../../../actions/taxFreeSavingsAccount/paymentDetails';

import {
    getBankName,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getMonth,
    getMonthToSkip,
    getPaymentAuthorization,
    getLumpSumDay,
    getLumpSumMonth,
    getSourceOfIncome,
    getSourceOfIncomeOther,
    getSourceOfFunds,
    getEmploymentPosition,
    getEmploymentIndustry,
    getEmploymentIndustryOther,
    getSkipMonth,
} from '../../../selectors/taxFreeSavingsAccount/paymentDetails';
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";


export const paymentDetailsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case BANK_NAME_SET_VALUE:
                sendError = false;
                return getBankName;
            case ACCOUNT_NUMBER_SET_VALUE:
                sendValue = false;
                return getAccountNumber;
            case ACCOUNT_TYPE_SET_VALUE:
                sendError = false;
                return getAccountType;
            case DEBIT_ORDER_DAY_SET_VALUE:
                sendError = false;
                return getDebitOrderDay;
            case MONTH_SET_VALUE:
                sendError = false;
                return getMonth;
            case MONTH_TO_SKIP_SET_VALUE:
                sendError = false;
                return getMonthToSkip;
            case LUMP_SUM_DAY_SET_VALUE:
                sendError = false;
                return getLumpSumDay;
            case LUMP_SUM_MONTH_SET_VALUE:
                sendError = false;
                return getLumpSumMonth;
            case SOURCE_OF_INCOME_SET_VALUE:
                sendError = false;
                return getSourceOfIncome;
            case SOURCE_OF_INCOME_OTHER_SET_VALUE:
                sendError = false;
                return getSourceOfIncomeOther;
            case SOURCE_OF_FUNDS_SET_VALUE:
                sendError = false;
                return getSourceOfFunds;
            case EMPLOYMENT_POSITION_SET_VALUE:
                sendError = false;
                return getEmploymentPosition;
            case EMPLOYMENT_INDUSTRY_SET_VALUE:
                sendError = false;
                return getEmploymentIndustry;
            case EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE:
                return getEmploymentIndustryOther;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);
    if (field) simpleFieldTracking({ action, field, sendValue, sendError });

    return result;
};

export const paymentDetailsOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS:
                otherEventIndex = findOtherEventIndex('SELECT');
                return getSkipMonth(state) ? 'skipMonth' : 'dontSkipMonth';
            case TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS:
                otherEventIndex = findOtherEventIndex('TOGGLE_CHECKBOX');
                return getPaymentAuthorization(state).value ? 'paymentAuthorisedChecked' : 'paymentAuthorisedUnchecked';        
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};