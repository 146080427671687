const addThousandsSeparator = (input) => {
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCurrency = (number, prefix) => {
    let formattedNumber = 0;
    if (number) {
        formattedNumber = addThousandsSeparator(parseFloat(number).toFixed(2));
    }

    if (prefix) {
        return `${prefix} ${formattedNumber}`;
    }

    return formattedNumber;
};
