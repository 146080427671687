import {
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    EMAIL_ADDRESS_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    setFirstNameValid,
    setLastNameValid,
    setEmailAddressValid,
    setPhoneNumberValid,
} from '../../../actions/roaCarInsurance/aboutYou';
import { validationIsEqual } from '../status';
import {validateContactNumber, validateEmail, validateName} from "@om/validation";

let prevValidationAction = null;
export const aboutYouValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {subject: 'First Name', required: true});
                return setFirstNameValid(error);
            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {subject: 'Last Name', required: true});
                return setLastNameValid(error);
            case EMAIL_ADDRESS_SET_VALUE:
                error = validateEmail(action.value);
                return setEmailAddressValid(error);
            case PHONE_NUMBER_SET_VALUE:
                if(action.countryCode === 'KE'){
                    error = validateContactNumber(action.value, {subject: 'Phone Number', countryCode: 'ke'});
                    return setPhoneNumberValid(error);
                }
                if(action.countryCode === 'NG'){
                    error = validateContactNumber(action.value, {subject: 'Phone Number', countryCode: 'ng'});
                    return setPhoneNumberValid(error);
                }
               
            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }
    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
