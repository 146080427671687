import { 
    SET_BUILDING_VALUE,
    SET_BELONGINGS_VALUE,
    SET_COVER_START_DATE_VALUE,
    SET_ALL_RISK_VALUE,
    SET_PORTABLE_VALUE,
    SET_DOMESTIC_SERVANTS_VALUE,
    SET_DOMESTIC_SERVANTS_NO_VALUE,
    SET_OWNERS_LIABILITY_VALUE,
    SET_OWNERS_LIABILITY_AMOUNT_VALUE,
    SET_OCCUPIERS_LIABILITY_VALUE,
    SET_OCCUPIERS_LIABILITY_AMOUNT_VALUE,
    setBelongingsValid,
    setBuildingValid,
    setCoverStartDateValid,
    setAllRiskValid,
    setPortableItemsValid,
    setDomesticServantsValid,
    setDomesticServantsNoValid,
    setOccupiersLiabilityAmountValid,
    setOwnersLiabilityAmountValid,
    setOccupiersLiabilityValid,
    setOwnersLiabilityValid,
 } from '../../../actions/domesticInsurance/quote';

import {  validateSelection, validateNumber, validateDate } from '@om/validation';
import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const quoteFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        let today = new Date()
        let  validTodayDate = today.toLocaleDateString('en-NG').split('/').join('/');
        switch (action.type) {
            case SET_BUILDING_VALUE:
                error = validateNumber(action.value, { min: 1000000, subject: 'Building value' });
                return setBuildingValid(error);
            case SET_BELONGINGS_VALUE:
                error = validateNumber(action.value, { min: 10000, subject: 'Belongings VALUE' });
                return setBelongingsValid(error);
            case SET_COVER_START_DATE_VALUE:
                error = validateDate(action.value, { required: true, min: validTodayDate });
                return setCoverStartDateValid(error);
            case SET_ALL_RISK_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'All risk' });
                return setAllRiskValid(error);
            case SET_PORTABLE_VALUE:
                error = validateNumber(action.value, { min: 1000, subject: 'Portable items' });
                return setPortableItemsValid(error);
            case SET_DOMESTIC_SERVANTS_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Domestic Servants' });
                return setDomesticServantsValid(error);
            case SET_DOMESTIC_SERVANTS_NO_VALUE:
                error = validateNumber(action.value, { min: 1, subject: 'Domestic servants number' });
                return setDomesticServantsNoValid(error);
            case SET_OWNERS_LIABILITY_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Owners Liability' });
                return setOwnersLiabilityValid(error);
            case SET_OWNERS_LIABILITY_AMOUNT_VALUE:
                error = validateNumber(action.value, { min: 1000000, subject: 'Owners Liability' });
                return setOwnersLiabilityAmountValid(error);
            case SET_OCCUPIERS_LIABILITY_VALUE:
                error = validateSelection(action.value, {
                    required: true,
                    min: 1,
                    subject: 'Valuation',
                });
                return setOccupiersLiabilityValid(error);
            case SET_OCCUPIERS_LIABILITY_AMOUNT_VALUE:
                error = validateNumber(action.value, { min: 1000000, subject: 'Occupiers liability amount' });
                return setOccupiersLiabilityAmountValid(error);
            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
