const PAYMENTS_TRANSITION = 'roaCarInsurance/confirmPayments/transition/';

// Transitions
export const FORM_VALIDATE_TRANSITION_PAYMENTS = PAYMENTS_TRANSITION + 'validateForm';
export const FORM_INVALIDATE_TRANSITION_PAYMENTS = PAYMENTS_TRANSITION + 'invalidateForm';
export const validateForm = () => ({ type: FORM_VALIDATE_TRANSITION_PAYMENTS });
export const invalidateForm = () => ({ type: FORM_INVALIDATE_TRANSITION_PAYMENTS});


// Events
export const PAYMENTS_SET_VALUE = 'roaCarInsurance/payments/setValue/';
export const PAYMENTS_SET_VALID = 'roaCarInsurance/payments/setValid/';

export const MPESA_PAYMENTS_STATUS_SET_VALUE = 'roaCarInsurance/payments/status/setValue/';
export const MPESA_PAYMENTS_STATUS_SET_VALID =  'roaCarInsurance/payments/status/setValid/';

// API Call actions
 export const API_PENDING_TRANSITION_PAYMENTS = `${PAYMENTS_SET_VALUE}/apiTransition/pending`;
 export const API_SUCCESS_TRANSITION_PAYMENTS = `${PAYMENTS_SET_VALUE}/apiTransition/success`;
 export const API_FAILURE_TRANSITION_PAYMENTS = `${PAYMENTS_SET_VALUE}/apiTransition/failure`;
 export const API_INITIALIZE_TRANSITION_PAYMENTS = `${PAYMENTS_SET_VALUE}/apiTransition/initialize`;
 export const apiTransitionPayments = {
 	pending: () => ({
 		type: API_PENDING_TRANSITION_PAYMENTS
 	}),
	 	success: () => ({
 		type: API_SUCCESS_TRANSITION_PAYMENTS
 	}),
 	failure: () => ({
 		type: API_FAILURE_TRANSITION_PAYMENTS
 	}), 
	initialize: () => ({
		type: API_INITIALIZE_TRANSITION_PAYMENTS
	})
 }

 // API Call actions mpesa status
 export const API_PENDING_TRANSITION_PAYMENTS_STATUS = `${PAYMENTS_SET_VALUE}/apiTransition/mpesa/status/pending`;
 export const API_SUCCESS_TRANSITION_PAYMENTS_STATUS = `${PAYMENTS_SET_VALUE}/apiTransition/mpesa/status/success`;
 export const API_FAILURE_TRANSITION_PAYMENTS_STATUS = `${PAYMENTS_SET_VALUE}/apiTransition/mpesa/status/failure`;
 export const API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL = `${PAYMENTS_SET_VALUE}/apiTransition/mpesa/status/initial`;

 export const apiTransitionPaymentsStatus = {
 	pending: () => ({
 		type: API_PENDING_TRANSITION_PAYMENTS_STATUS
 	}),
	success: () => ({
 		type: API_SUCCESS_TRANSITION_PAYMENTS_STATUS
 	}),
 	failure: () => ({
 		type: API_FAILURE_TRANSITION_PAYMENTS_STATUS
 	}),
	initialize: () => ({
		type: API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL
})
 }

export const PHONE_NUMBER_SET_VALUE = PAYMENTS_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = PAYMENTS_SET_VALID + 'phoneNumber';
export const COMPUTE_MPESA_PAYMENTS = PAYMENTS_SET_VALUE + 'computeMpesaPayments';

export const EMAIL_SET_VALUE = PAYMENTS_SET_VALUE + 'email';
export const EMAIL_SET_VALID = PAYMENTS_SET_VALID + 'email';

export const MPESA_PAYMENTS_SET_VALUE = PAYMENTS_SET_VALUE + 'carInsurancePayments';
export const MPESA_PAYMENTS_SET_VALID = PAYMENTS_SET_VALID + 'carInsurancePayments';
export const CHECK_MPESA_PAYMENTS = PAYMENTS_TRANSITION + 'checkMpesaPayments';

export const computeMpesaPayments = () => ({type: COMPUTE_MPESA_PAYMENTS});
export const setPhoneNumberValue = (value) => ({type: PHONE_NUMBER_SET_VALUE, value});
export const setPhoneNumberValid = (error) => ({type: PHONE_NUMBER_SET_VALID, error});
export const setMpesaPaymentsCarInsuranceValue = (value) => ({type:MPESA_PAYMENTS_SET_VALUE, value });
export const setMpesaPaymentsCarInsuranceValid = (error) => ({type:MPESA_PAYMENTS_SET_VALID, error })

export const checkMpesaPaymentsStatus = () => ({type: CHECK_MPESA_PAYMENTS});
export const setMpesaPaymentsStatusValue = (value) => ({type:  MPESA_PAYMENTS_STATUS_SET_VALUE, value});
export const setMpesaPaymentsStatusValid = (error) => ({type: MPESA_PAYMENTS_STATUS_SET_VALID, error});

export const setEmailValue = (value) => ({type: EMAIL_SET_VALUE, value});
export const setEmailValid = (error) => ({type: EMAIL_SET_VALID, error});
