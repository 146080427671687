export const DIGITAL_SAVINGS_BENEFICIARIES_FORM = 'digitalSavings/beneficiariesForm/';

export const DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM + 'setValue/';
export const DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM + 'setValid/';

export const FORM_VALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM = 'digitalSavings/beneficiariesForm/transition/formValid';
export const FORM_INVALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM =
    'digitalSavings/beneficiariesForm/transition/formInValid';

export const REINITIALIZE = 'digitalSavings/beneficiariesForm/state/reinitialize';

export const REINITIALIZE_SINGLE = 'digitalSavings/beneficiariesForm/state/reinitializeSingle';

export const ADD_BENEFICIARY = 'digitalSavings/beneficiariesForm/state/addBeneficiary';
export const REMOVE_BENEFICIARY = 'digitalSavings/beneficiariesForm/state/removeBeneficiary';

export const transitionDigitalSavingsBeneficiariesForm = {
    formValid: (index) => ({
        type: FORM_VALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM,
        index,
    }),
    formInvalid: (index) => ({
        type: FORM_INVALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM,
        index,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_BENEFICIARIES_FORM = `${DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_BENEFICIARIES_FORM = `${DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_BENEFICIARIES_FORM = `${DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionBeneficiariesForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_BENEFICIARIES_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_BENEFICIARIES_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_BENEFICIARIES_FORM,
    }),
};

export const SELECTED_FORM_INDEX_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'selectedFormIndex';

export const FIRST_NAME_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'firstName';

export const LAST_NAME_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'lastName';
export const LAST_NAME_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'lastName';

export const ID_NUMBER_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'idNumber';

export const RELATIONSHIP_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'relationship';
export const RELATIONSHIP_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'relationship';

export const LOCATION_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'location';
export const LOCATION_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'location';

export const PHONE_NUMBER_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'phoneNumber';

export const EMAIL_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'email';

export const FIRST_NAME_MINOR_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'firstNameMinor';
export const FIRST_NAME_MINOR_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'firstNameMinor';

export const LAST_NAME_MINOR_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'lastNameMinor';
export const LAST_NAME_MINOR_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'lastNameMinor';

export const LOCATION_MINOR_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'locationMinor';
export const LOCATION_MINOR_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'locationMinor';

export const PERCENTAGE_SPLIT_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'percentageSplit';
export const PERCENTAGE_SPLIT_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'percentageSplit';

export const IS_MINOR_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'isMinor';
export const IS_MINOR_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'isMinor';

export const PERSONAL_DATA_TERMS_SET_VALUE = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'personalDataTerms';
export const PERSONAL_DATA_TERMS_SET_VALID = DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'personalDataTerms';

export const PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE =
    DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'personalDataProcessingConsent';
export const PERSONAL_DATA_PROCESSING_CONSENT_SET_VALID =
    DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'personalDataProcessingConsent';

export const CHILD_DATA_PROCESSING_CONSENT_SET_VALUE =
    DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'childDataProcessingConsent';
export const CHILD_DATA_PROCESSING_CONSENT_SET_VALID =
    DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'childDataProcessingConsent';

export const MARKETING_USE_CONSENT_SET_VALUE =
    DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE + 'marketingUseConsent';
export const MARKETING_USE_CONSENT_SET_VALID =
    DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALID + 'marketingUseConsent';

export const setSelectedFormIndexValue = (value) => ({
    type: SELECTED_FORM_INDEX_SET_VALUE,
    value,
});

export const setFirstNameValue = (index, value) => ({
    type: FIRST_NAME_SET_VALUE,
    index,
    value,
});
export const setFirstNameValid = (index, error) => ({
    type: FIRST_NAME_SET_VALID,
    index,
    error,
});

export const setLastNameValue = (index, value) => ({
    type: LAST_NAME_SET_VALUE,
    index,
    value,
});
export const setLastNameValid = (index, error) => ({
    type: LAST_NAME_SET_VALID,
    index,
    error,
});

export const setIdNumberValue = (index, value) => ({
    type: ID_NUMBER_SET_VALUE,
    index,
    value,
});
export const setIdNumberValid = (index, error) => ({
    type: ID_NUMBER_SET_VALID,
    index,
    error,
});

export const setRelationshipValue = (index, value) => ({
    type: RELATIONSHIP_SET_VALUE,
    index,
    value,
});
export const setRelationshipValid = (index, error) => ({
    type: RELATIONSHIP_SET_VALID,
    index,
    error,
});

export const setLocationValue = (index, value) => ({
    type: LOCATION_SET_VALUE,
    index,
    value,
});
export const setLocationValid = (index, error) => ({
    type: LOCATION_SET_VALID,
    index,
    error,
});

export const setFirstNameMinorValue = (index, value) => ({
    type: FIRST_NAME_MINOR_SET_VALUE,
    index,
    value,
});
export const setFirstNameMinorValid = (index, error) => ({
    type: FIRST_NAME_MINOR_SET_VALID,
    index,
    error,
});

export const setLastNameMinorValue = (index, value) => ({
    type: LAST_NAME_MINOR_SET_VALUE,
    index,
    value,
});
export const setLastNameMinorValid = (index, error) => ({
    type: LAST_NAME_MINOR_SET_VALID,
    index,
    error,
});

export const setLocationMinorValue = (index, value) => ({
    type: LOCATION_MINOR_SET_VALUE,
    index,
    value,
});
export const setLocationMinorValid = (index, error) => ({
    type: LOCATION_MINOR_SET_VALID,
    index,
    error,
});

export const setIsMinorValue = (index, value) => ({
    type: IS_MINOR_SET_VALUE,
    index,
    value,
});
export const setIsMinorValid = (index, error) => ({
    type: IS_MINOR_SET_VALID,
    index,
    error,
});

export const setPhoneNumberValue = (index, value) => ({
    type: PHONE_NUMBER_SET_VALUE,
    index,
    value,
});
export const setPhoneNumberValid = (index, error) => ({
    type: PHONE_NUMBER_SET_VALID,
    index,
    error,
});

export const setEmailValue = (index, value) => ({
    type: EMAIL_SET_VALUE,
    index,
    value,
});
export const setEmailValid = (index, error) => ({
    type: EMAIL_SET_VALID,
    index,
    error,
});

export const setPercentageSplitValue = (index, value) => ({
    type: PERCENTAGE_SPLIT_SET_VALUE,
    index,
    value,
});
export const setPercentageSplitValid = (index, error) => ({
    type: PERCENTAGE_SPLIT_SET_VALID,
    index,
    error,
});

export const initializeBeneficiariesForm = () => ({
    type: REINITIALIZE,
});

export const initializeBeneficiary = (index) => ({
    type: REINITIALIZE_SINGLE,
    index,
});

export const addBeneficiary = () => ({
    type: ADD_BENEFICIARY,
});

export const removeBeneficiary = (index) => ({
    type: REMOVE_BENEFICIARY,
    index,
});



export const setPersonalDataTermsValue = (value) => ({ type: PERSONAL_DATA_TERMS_SET_VALUE, value });
export const setPersonalDataTermsValid = (error) => ({ type: PERSONAL_DATA_TERMS_SET_VALID, error });

export const setPersonalDataProcessingConsentValue = (value) => ({
    type: PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE,
    value,
});
export const setPersonalDataProcessingConsentValid = (error) => ({
    type: PERSONAL_DATA_PROCESSING_CONSENT_SET_VALID,
    error,
});

export const setChildDataProcessingConsentValue = (value) => ({
    type: CHILD_DATA_PROCESSING_CONSENT_SET_VALUE,
    value,
});
export const setChildDataProcessingConsentValid = (error) => ({
    type: CHILD_DATA_PROCESSING_CONSENT_SET_VALID,
    error,
});

export const setMarketingUseConsentValue = (value) => ({
    type: MARKETING_USE_CONSENT_SET_VALUE,
    value,
});
export const setMarketingUseConsentValid = (error) => ({
    type: MARKETING_USE_CONSENT_SET_VALID,
    error,
});