import { createSelector } from 'reselect';
import { getDigitalOnboarding } from './index';

export const getDocumentStore = createSelector(
    getDigitalOnboarding,
    digitalOnboarding => digitalOnboarding.documentStore
);

export const getMaxFileSize = createSelector(
    getDocumentStore,
    documentStore => ({ value: documentStore.maxFileSize, human: documentStore.humanReadableMax })
);

export const getMaxFileSizeAsHuman = createSelector(
    getDocumentStore,
    documentStore => documentStore.maxFileSize
);

export const getMaxFileErrorMessage = createSelector(
    getDocumentStore,
    documentStore => documentStore.fileTooLargeErrorMessage
);

export const getAcceptableFileExtensions = createSelector(
    getDocumentStore,
    documentStore => documentStore.allowedFileExtensions
);

export const getFileQueue = createSelector(
    getDocumentStore,
    documentStore => documentStore.fileQueue
);
 
export const getDocumentModal = createSelector(
    getDocumentStore,
    documentStore => documentStore.documentsModal
);
