export const BASIC_CALCULATOR_SET_VALUE = 'incomeTaxCalculator/basicCalculator/setValue/';
export const BASIC_CALCULATOR_SET_VALID = 'incomeTaxCalculator/basicCalculator/setValid/';

export const AGE_SET_VALUE = BASIC_CALCULATOR_SET_VALUE + 'age';
export const AGE_SET_VALID = BASIC_CALCULATOR_SET_VALID + 'age';
export const INCOME_FREQUENCY_SET_VALUE = BASIC_CALCULATOR_SET_VALUE + 'incomeFrequency';
export const INCOME_FREQUENCY_SET_VALID = BASIC_CALCULATOR_SET_VALID + 'incomeFrequency';
export const INCOME_VALUE_SET_VALUE = BASIC_CALCULATOR_SET_VALUE + 'incomeValue';
export const INCOME_VALUE_SET_VALID = BASIC_CALCULATOR_SET_VALID + 'incomeValue';
export const PRODUCT_RECOMMENDATION_SET_VALUE =  BASIC_CALCULATOR_SET_VALUE + 'productRecommendation';
export const REFERENCE_DATA_SET_VALUE = BASIC_CALCULATOR_SET_VALUE + 'referenceData';
export const RESULTS_VIEW_VALUE = BASIC_CALCULATOR_SET_VALUE + 'viewResults';
export const RESULTS_SET_VALUE = BASIC_CALCULATOR_SET_VALUE + 'setResults';
export const SHOW_FIELD_DETAILS = BASIC_CALCULATOR_SET_VALUE + 'showFielDetails';

export const FORM_VALID_BASIC_CALCULATION = 'incomeTaxCalculator/basicCalculator/formValid';
export const FORM_INVALID_BASIC_CALCULATION = 'incomeTaxCalculator/basicCalculator/formInvalid';
export const REINITIALIZE_STATE_BASIC_CALCULATOR = 'incomeTaxCalculator/basicCalculator/reinitialize';

export const transitionBasicCalculator = {
    formValid: () => ({ type: FORM_VALID_BASIC_CALCULATION }),
    formInvalid: () => ({ type: FORM_INVALID_BASIC_CALCULATION }),
};

export const setAgeValue = value => ({ type: AGE_SET_VALUE, value });
export const setAgeValid = error => ({ type: AGE_SET_VALID, error });
export const setIncomeFrequencyValue = value => ({ type: INCOME_FREQUENCY_SET_VALUE, value });
export const setIncomeFrequencyValid = error => ({ type: INCOME_FREQUENCY_SET_VALID, error });
export const setIncomeValue = value => ({ type: INCOME_VALUE_SET_VALUE, value });
export const setIncomeValueValid = error => ({ type: INCOME_VALUE_SET_VALID, error });
export const setViewTaxResult = value => ({ type: RESULTS_VIEW_VALUE, value });
export const setTaxResult = value => ({ type: RESULTS_SET_VALUE, value });
export const setReferenceData = () => ({ type: REFERENCE_DATA_SET_VALUE });
export const reInitialize = () => ({ type: REINITIALIZE_STATE_BASIC_CALCULATOR });
export const setViewRecommendation = () => ({ type: PRODUCT_RECOMMENDATION_SET_VALUE })
export const showFielDetails = value => ({ type: SHOW_FIELD_DETAILS, value });
