import axios from 'axios';
import {getIsApiPending, getCarDetailsForm} from "../../../selectors/roaCarInsurance/details";
import { getVehicleValuation } from '../../../selectors/roaCarInsurance/quotation';
import {
	apiTransitionUploadLogbook,
	setLogBookDataValue,
	setLogBookDataValid
} from "../../../actions/roaCarInsurance/details";

export const uploadLogBookService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();
	(async () => {
		if(!getIsApiPending(state)) {
			store.dispatch(
				apiTransitionUploadLogbook.pending()
			)
			try {
				const resp = await uploadLogbook(state)
				store.dispatch(setLogBookDataValue(resp.data))
				store.dispatch(apiTransitionUploadLogbook.success())

			} catch (e) {
				store.dispatch(setLogBookDataValid(e.message))
				store.dispatch(apiTransitionUploadLogbook.failure())
			}
		}
	})()
	return result
}

const uploadLogbook = async (state) => {
	const carDetails = getCarDetailsForm(state)
    const logbookFile = carDetails.logBookFile.value;
	const vehicleValuation = getVehicleValuation(state)
    const formData = new FormData();
	formData.append('file', logbookFile);
	formData.append('session_id', vehicleValuation.value.session_id)
	try {
		const url = '/om-api/roa-car-insurance/submit-logbook'
		return await axios.post(url, formData)
	} catch (e) {
		console.error(e)
		throw e
	}
}
