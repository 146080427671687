import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
	REINITIALIZE,
  DECLINED_PROPOSAL_SET_VALUE,
	DECLINED_PROPOSAL_SET_VALID,
	DEFECT_VISION_SET_VALUE,
	DEFECT_VISION_SET_VALID,
	CONVICTED_SET_VALUE,
	CONVICTED_SET_VALID,
	INCREASED_PREMIUMS_SET_VALUE,
	INCREASED_PREMIUMS_SET_VALID,
	POLICY_REJECTED_SET_VALUE,
	POLICY_REJECTED_SET_VALID,
	STICKER_COLLECTION_SET_VALUE,
	STICKER_COLLECTION_SET_VALID,
  FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM,
  FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM
} from '../../actions/ugMotorInsurance/declarations';

export const initDeclaration = {
	formStatus: FORM_STATUS.VALID,
	dropOffDetails: null,
	declarationReferenceData: null,
	apiCallStatus: null,
	form: {
		declineProposal: {	value: false, status: FIELD_STATUS.VALID, error: null },
		increasedPremiums: { value: false, status: FIELD_STATUS.VALID, error: null },
		policyRejected: { value: false, status: FIELD_STATUS.VALID, error: null },
		defectVision: { value: false, status: FIELD_STATUS.VALID, error: null },
		convicted: { value: false, status: FIELD_STATUS.VALID, error: null },
		convicted: { value: false, status: FIELD_STATUS.VALID, error: null },
		stickerCollection: { value: null, status: FIELD_STATUS.INVALID, error: null },
	},
};

export default (state = initDeclaration, action) => {
	const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
	const updateFormField = (fieldId, update) => ({
		...state,
		form: {
			...state.form,
			[fieldId]: update(state.form[fieldId]),
		},
	});

	switch (action.type) {
		case FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM:
			return { ...state, formStatus: FORM_STATUS.VALID };
			
		case FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM:
			return { ...state, formStatus: FORM_STATUS.INVALID };

		case DECLINED_PROPOSAL_SET_VALUE:
			return updateFormField('declineProposal', (field) => ({ ...field, value: action.value }));

		case DECLINED_PROPOSAL_SET_VALID:
			return updateFormField('declineProposal', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		case INCREASED_PREMIUMS_SET_VALUE:
			return updateFormField('increasedPremiums', (field) => ({ ...field, value: action.value }));

		case INCREASED_PREMIUMS_SET_VALID:
			return updateFormField('increasedPremiums', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		case POLICY_REJECTED_SET_VALUE:
			return updateFormField('policyRejected', (field) => ({ ...field, value: action.value }));

		case POLICY_REJECTED_SET_VALID:
			return updateFormField('policyRejected', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		case DEFECT_VISION_SET_VALUE:
			return updateFormField('defectVision', (field) => ({ ...field, value: action.value }));

		case DEFECT_VISION_SET_VALID:
			return updateFormField('defectVision', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		case CONVICTED_SET_VALUE:
			return updateFormField('convicted', (field) => ({ ...field, value: action.value }));

		case CONVICTED_SET_VALID:
			return updateFormField('convicted', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		case STICKER_COLLECTION_SET_VALUE:
			return updateFormField('stickerCollection', (field) => ({ ...field, value: action.value }));

		case STICKER_COLLECTION_SET_VALID:
			return updateFormField('stickerCollection', (field) => ({	
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		// Reset the state
		case REINITIALIZE:
			return initDeclaration;
		default:
			return state;
	}
};
