import { createSelector } from 'reselect';
import { getInvestmentCalculators } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';
import { formatCurrency } from '../currency'

export const getGenerateRegularIncomeCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculators) => investmentCalculators.generateRegularIncomeCalculator
);

export const getGenerateRegularIncomeCalculatorForm = createSelector(
    getGenerateRegularIncomeCalculator,
    (generateRegularIncomeCalculator) => generateRegularIncomeCalculator.form
);

export const getEmergencyCalculatorFormValid = createSelector(
    getGenerateRegularIncomeCalculator,
    (getEmergencyCalculator) => getEmergencyCalculator.formStatus === FORM_STATUS.VALID
);

const makeGetField = (fieldId) => createSelector(getGenerateRegularIncomeCalculatorForm, (form) => form[fieldId]);

export const getAmountOnceOffValue = makeGetField('amountOnceOff');
export const getMonthlyAmountValue = makeGetField('amountMonthly');
export const getMoneyMarketFundPercentValue = makeGetField('moneyMarketFundPercentage');
export const getBondFundPercentValue = makeGetField('bondFundPercentage');

export const getMoneyMarketFundSplitValue = createSelector(
    [
        getMoneyMarketFundPercentValue,
        getGenerateRegularIncomeCalculatorForm
    ],
    (moneyMarketFundPercentage, generateRegularIncomeForm) => {
        const total =
            Number(generateRegularIncomeForm.amountOnceOff.value || 0) +
            Number(generateRegularIncomeForm.amountMonthly.value || 0);
        return Math.round(total * (Number(moneyMarketFundPercentage.value || 0) / 100));
    }
);

export const getBondFundSplitValue = createSelector(
    [
        getBondFundPercentValue,
        getGenerateRegularIncomeCalculatorForm
    ],
    (bondFundPercentage, generateRegularIncomeForm) => {
        const total =
            Number(generateRegularIncomeForm.amountOnceOff.value || 0) +
            Number(generateRegularIncomeForm.amountMonthly.value || 0);
        return Math.round(total * (Number(bondFundPercentage.value || 0) / 100));
    }
);

export const getGenerateRegularIncomeResults = createSelector(
    getGenerateRegularIncomeCalculatorForm,
    (generateRegularIncomeCalculatorForm) => generateRegularIncomeCalculatorForm.calculationResults
)

export const getAmountNeededToBeSaved = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => formatCurrency(generateRegularIncomeCalculator.amountNeededToBeSaved, 'KES')
)

export const getShowResult = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => generateRegularIncomeCalculator.apiCallStatus === API_CALL_STATUS.SUCCESS,
);

export const getShowError = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => generateRegularIncomeCalculator.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getTotalPercentage = createSelector(
    getGenerateRegularIncomeCalculator,
    (generateRegularIncomeCalculator) => generateRegularIncomeCalculator.totalPercentage
)

export const getSplitError = createSelector(
    getGenerateRegularIncomeCalculator,
    (generateRegularIncomeCalculator) => generateRegularIncomeCalculator.splitError
)

export const getIsEvenSplit = createSelector(
    getGenerateRegularIncomeCalculator,
    (generateRegularIncomeCalculator) => generateRegularIncomeCalculator.isEvenSplit
)

export const getIsApiPending = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => generateRegularIncomeCalculator.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getBtnText = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => {
        switch (generateRegularIncomeCalculator.apiCallStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'RECALCULATE';
            case API_CALL_STATUS.FAILURE:
                return 'TRY AGAIN';
            case API_CALL_STATUS.PENDING:
                return 'CALCULATING...';
            default:
                return 'CALCULATE';
        }
    }
);

export const getHasCalculateBtnIcon = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => {
        return generateRegularIncomeCalculator.apiCallStatus !== API_CALL_STATUS.PENDING;
    }
);

export const getCalculateDisabled = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => {
        return generateRegularIncomeCalculator.formStatus === FORM_STATUS.INVALID ||
            generateRegularIncomeCalculator.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);

export const getInputDisabled = createSelector(
    getGenerateRegularIncomeCalculator,
    generateRegularIncomeCalculator => {
        return generateRegularIncomeCalculator.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);
