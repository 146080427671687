// Transitions
export const FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS =
  "allsureInsurance/uploadDocuments/transition/formValid";
export const FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS =
  "allsureInsurance/uploadDocuments/transition/formInvalid";


export const transitionUploadDocuments = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_UPLOAD_DOCUMENTS =
  "allsureInsurance/uploadDocuments/transition/resetState";
export const reInitialiseDocuments = () => ({
  type: RESET_STATE_TRANSITION_UPLOAD_DOCUMENTS,
});

// Events


export const ALLSURE_INSURANCE_UPLOAD_DOCUMENTS =
  "allsureInsurance/uploadDocuments/";
export const UPLOAD_DOCUMENTS_SET_VALUE =
ALLSURE_INSURANCE_UPLOAD_DOCUMENTS+"setValue/";
export const UPLOAD_DOCUMENTS_SET_VALID =
ALLSURE_INSURANCE_UPLOAD_DOCUMENTS +"setValid/";

export const UPLOAD_DOCUMENTS_DECLARATION_SET_VALUE =
ALLSURE_INSURANCE_UPLOAD_DOCUMENTS+"setValue/declaration";
export const UPLOAD_DOCUMENTS_DECLARATION_SET_VALID =
ALLSURE_INSURANCE_UPLOAD_DOCUMENTS +"setValid/declaration";


// export const TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE =
// ALLSURE_INSURANCE_INSURANCE_TYPE+"toogle/privacyNotice";

// export const  tooglePrivacyNotice=(value)=>({type: TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE, value})
export const setDocumentValue =(value)=>({type: UPLOAD_DOCUMENTS_SET_VALUE, value})
export const setDocumentValid =(error,fileType)=>({type: UPLOAD_DOCUMENTS_SET_VALID, error, fileType})

export const setDocumentDeclarationValue =(value)=>({type: UPLOAD_DOCUMENTS_DECLARATION_SET_VALUE, value})
export const setDocumentDeclarationValid =(error,fieldType)=>({type: UPLOAD_DOCUMENTS_DECLARATION_SET_VALID, error, fieldType})

const SIZE_2_MB = 2097152;
const SIZE_1_kb = 1024;


export const UPLOAD_DOCUMENTS_CONFIG = {

  MIN_INPUT_SIZE: SIZE_1_kb,
  MIN_INPUT_SIZE_NAME: '1 kb',
  MAX_INPUT_SIZE: SIZE_2_MB,
  MAX_INPUT_SIZE_NAME: '2 mb',
  VALID_UPLOAD_TYPES: [
      { FORMAT: 'image/jpeg', FORMAT_NAME: 'JPG' },
      {FORMAT: 'image/png', FORMAT_NAME: 'PNG'},
      { FORMAT: 'application/pdf', FORMAT_NAME: 'PDF' },
  ],
};