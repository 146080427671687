import { validateAccountNumber, validateSingleSelection } from "@om/validation";
import {
    setBankNameValid,
    setDebitOrderDayValid, 
    setAccountTypeValid,
    setAccountNumberValid,

    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    setPaymentAuthorizationValid
} from "../../../actions/easiplusFuneralPlan/paymentDetails";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const paymentDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case BANK_NAME_SET_VALUE:
                error = validateSingleSelection(action.value, 'bank name');
                return setBankNameValid(error);

            case ACCOUNT_NUMBER_SET_VALUE:
                error = validateAccountNumber(action.value);
                return setAccountNumberValid(error);
    
            case ACCOUNT_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value, 'account type');
                return setAccountTypeValid(error);

            case DEBIT_ORDER_DAY_SET_VALUE:
                error = validateSingleSelection(action.value, 'debit order start date');
                return setDebitOrderDayValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
