import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';
import {
  REINITIALIZE,
  WHICH_FORM_TO_DISPLAY,
  FORM_VALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
  FORM_INVALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
  CHILDREN_SET_VALUE,
  CHILDREN_SET_VALID,
  INPATIENT_COVER_LIMIT_SET_VALUE,
  INPATIENT_COVER_LIMIT_SET_VALID,
  OUTPATIENT_COVER_LIMIT_SET_VALUE,
  OUTPATIENT_COVER_LIMIT_SET_VALID,
  API_PENDING_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
  API_SUCCESS_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
  API_FAILURE_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
  COLLECT_AFYA_IMARA_GERTRUDE_JUNIOR_DATA,
  CALCULATE_AFYA_IMARA_GERTRUDE_JUNIOR_DATA,
} from '../../actions/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool';

const initialeState = {
  selectedForm: 'form-one',
  formStatus: FORM_STATUS.INVALID,
  roaCmbObject: {
    __typename: 'Contentstack_call_me_back_ke',
    product: 'Afyaimara Family Junior',
    privacy_policy_url: '/privacy-policy/',
    extra_fields:['Email Address']
  },
  form: {
    panelId: { value: 1, error: null, status: 'valid' },
    numberOfChildren: { value: null, error: null, status: null },
    inpatientCoverLimitValue: {
      value: '500000',
      error: null,
      status: 'valid',
    },
    outpatientCoverLimitValue: {
      value: 'NO_OUTPATIENT_COVER',
      error: null,
      status: 'valid',
    },
  },
};

export default (state = initialeState, action) => {
  const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
  const updateFormField = (fieldId, update) => ({
    ...state,
    form: {
      ...state.form,
      [fieldId]: update(state.form[fieldId]),
    },
  });

  switch (action.type) {
    // Select Form
    case WHICH_FORM_TO_DISPLAY:
      return {
        ...state,
        selectedForm: action.value,
      };
    // Form Validation
    case FORM_VALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL:
      return { ...state, formStatus: FORM_STATUS.VALID };
    case FORM_INVALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL:
      return { ...state, formStatus: FORM_STATUS.INVALID };

    //Number of children
    case CHILDREN_SET_VALUE:
      return updateFormField('numberOfChildren', field => ({
        ...field,
        value: action.value,
      }));
    case CHILDREN_SET_VALID:
      return updateFormField('numberOfChildren', field => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Inpatient cover limit
    case INPATIENT_COVER_LIMIT_SET_VALUE:
      return updateFormField('inpatientCoverLimitValue', field => ({
        ...field,
        value: action.value,
      }));
    case INPATIENT_COVER_LIMIT_SET_VALID:
      return updateFormField('inpatientCoverLimitValue', field => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Outpatient cover limit
    case OUTPATIENT_COVER_LIMIT_SET_VALUE:
      return updateFormField('outpatientCoverLimitValue', field => ({
        ...field,
        value: action.value,
      }));
    case OUTPATIENT_COVER_LIMIT_SET_VALID:
      return updateFormField('outpatientCoverLimitValue', field => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    // Api call
    case API_PENDING_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL:
      return {
        ...state,
        apiCallStatus: API_CALL_STATUS.PENDING,
      };
    case API_SUCCESS_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL:
      return {
        ...state,
        apiCallStatus: API_CALL_STATUS.SUCCESS,
      };
    case API_FAILURE_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL:
      return {
        ...state,
        apiCallStatus: API_CALL_STATUS.FAILURE,
      };

    // Collect and calculate Calculate premiums
    case COLLECT_AFYA_IMARA_GERTRUDE_JUNIOR_DATA:
      return {
        ...state,
        formStatus: FORM_STATUS.VALID,
      };
    case CALCULATE_AFYA_IMARA_GERTRUDE_JUNIOR_DATA:
      return {
        ...state,
        afyaImaraGertrudeJuniorPremiumAmount: action.value,
      };

    // Reset the state
    case REINITIALIZE:
      return initialeState;
    default:
      return state;
  }
};
