export const PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL =
    'roaQuoteTools/physicalImpairmentCoverQuoteTool/';
export const PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL + 'setValue/';
export const PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALID =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL =
    'roaQuoteTools/physicalImpairmentCoverQuoteTool/transition/formStateValid';
export const FORM_INVALID_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL =
    'roaQuoteTools/physicalImpairmentCoverQuoteTool/transition/formInvalid';
export const REINITIALIZE =
    'roaQuoteTools/physicalImpairmentCoverQuoteTool/state/reinitialize';

export const transitionPhysicalImpairmentCoverQuoteTool = {
    formValid: () => ({
        type: FORM_VALID_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL,
    }),
};

export const WHICH_FORM_TO_DISPLAY =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const CANDIDATE_DOB_SET_VALUE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'candidateDOB';
export const CANDIDATE_DOB_SET_VALID =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALID + 'candidateDOB';

export const GENDER_SET_VALUE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'genderValue';

export const SMOKER_SET_VALUE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'smokerValue';

export const COVER_AMOUNT_SET_VALUE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'coverAmount';
export const COVER_AMOUNT_SET_VALID =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALID + 'coverAmount';

export const YEARS_COVERED_SET_VALUE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'numberOfYearsCovered';
export const YEARS_COVERED_SET_VALID =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALID + 'numberOfYearsCovered';

export const DISABILITY_SET_VALUE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'disabilityValue';

// API Calls actions
export const API_PENDING_TRANSITION_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL = `${PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL = `${PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL = `${PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;

export const apiTransitionPhysicalImpairmentCoverQuoteTool = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL,
    }),
};

// Premium calculated Data
export const COLLECT_PHYSICAL_IMPAIRMENT_COVER_PREMIUM_DATA =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE +
    'collectPhysicalImpairmentCoverData';
export const CALCULATE_PHYSICAL_IMPAIRMENT_COVER_SUCCESS =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_PHYSICAL_IMPAIRMENT_COVER_FAILURE =
    PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE + 'failure';

export const collectPhysicalImpairmentCoverData = () => ({
    type: COLLECT_PHYSICAL_IMPAIRMENT_COVER_PREMIUM_DATA,
});
export const calculatePhysicalImpairmentCoverData = value => ({
    type: CALCULATE_PHYSICAL_IMPAIRMENT_COVER_SUCCESS,
    value,
});
export const calculatePhysicalImpairmentCoverDataFailure = error => ({
    type: CALCULATE_PHYSICAL_IMPAIRMENT_COVER_FAILURE,
    error,
});

// Form values
export const setGenderValue = value => ({ type: GENDER_SET_VALUE, value });

export const setCandidateDOBValue = value => ({
    type: CANDIDATE_DOB_SET_VALUE,
    value,
});
export const setCandidateDOBValid = error => ({
    type: CANDIDATE_DOB_SET_VALID,
    error,
});

export const setSmokerValue = value => ({
    type: SMOKER_SET_VALUE,
    value,
});

export const setCoverAmountValue = value => ({
    type: COVER_AMOUNT_SET_VALUE,
    value,
});
export const setCoverAmountValid = error => ({
    type: COVER_AMOUNT_SET_VALID,
    error,
});

export const setNumberOfYearsCoveredValue = value => ({
    type: YEARS_COVERED_SET_VALUE,
    value,
});
export const setNumberOfYearsCoveredValid = error => ({
    type: YEARS_COVERED_SET_VALID,
    error,
});

export const setDisabilityValue = value => ({
    type: DISABILITY_SET_VALUE,
    value,
});

export const setWhichFormToDisplay = value => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});

export const setToPhysicalImpairmentQuoteInitial = () => ({
    type: REINITIALIZE,
});
