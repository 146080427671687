import { createSelector } from 'reselect';
import { getTaxFreeSavingsAccount } from './index';
import {
    ADVANCED_FUND_PICKER_CHECKBOX_ITEMS,
    COMPLIANCE_CHECKBOX_ITEMS,
    RISK_CHECKBOX_ITEMS,
    STATUS_CHECKBOX_ITEMS,
    FUND_OPTIONS_TO_CODE,
    RISK_NAMES_TO_RISK_CATEGORY,
} from '../../actions/taxFreeSavingsAccount/types';
import { displayFund } from '../../functions/taxFreeSavingsAccount/selectAFund';
import {
    filterFundData,
    sortFundData,
    searchFundByName,
    suggestSearchFunds,
} from '../../functions/taxFreeSavingsAccount/advancedFundPicker';
import { getFundDictionary, getSelectedFundNameWithFee } from './selectAFund';
import { displayFundName } from '../../functions/taxFreeSavingsAccount/selectAFund';
import { getFundReferenceData } from './fundData';
import { getLumpSumContribution, getRegularContribution } from './investmentSetupContribution';
import { splitPercentagesEvenly } from '../../functions/percentageSplit';

export const getAdvancedFundPicker = createSelector(getTaxFreeSavingsAccount, (tfsa) => tfsa.advancedFundPicker);
export const getAdvancedFundPickerForm = createSelector(
    getAdvancedFundPicker,
    (advancedFundPicker) => advancedFundPicker.form
);

export const getIsFilterOptionsSideDrawerOpen = createSelector(
    getAdvancedFundPicker,
    (advancedFundPicker) => advancedFundPicker.isFilterOptionsDrawerOpen
);

export const getDisplayMaxFundsModal = createSelector(
    getAdvancedFundPicker,
    (advancedFundPicker) => advancedFundPicker.maxFundsSelectedModalOpen
);

export const getAdvancedFundsAreSelected = createSelector(
    getAdvancedFundPicker,
    (advancedFundPicker) => advancedFundPicker.selectedAdvancedFunds
);

const makeGetField = (fieldId) =>
    createSelector(getAdvancedFundPickerForm, (advancedFundPicker) => advancedFundPicker[fieldId]);
export const getTempSearchQuery = makeGetField('tempSearchQuery');
export const getSelectedSearchQuery = makeGetField('selectedSearchQuery');
export const getSelectedFundFilters = makeGetField('selectedFundFilters');
export const getSelectedFunds = makeGetField('selectedFunds');
export const getSelectedFundsFeesMap = makeGetField('selectedFundsFeesMap');
export const getShowSearchResults = makeGetField('showSearchResults');

export const getSelectedFundList = createSelector(getSelectedFunds, (selectedFunds) =>
    Object.entries(selectedFunds.value).reduce((acc, [fundCode, isSelected]) => {
        if (!isSelected) {
            return acc;
        }

        if (Number.isNaN(+fundCode)) {
            return [...acc, fundCode];
        }

        return [...acc, FUND_OPTIONS_TO_CODE[+fundCode]];
    }, [])
);

export const getSelectedFundListWithFees = createSelector(
    getSelectedFundList,
    getSelectedFundsFeesMap,
    getSelectedFundNameWithFee,
    getFundDictionary,
    (selectedFundList, getSelectedFundsFeesMap, selectedFundNameWithFee, fundDictionary) => {
        if (selectedFundList?.length === 0) {
            return [selectedFundNameWithFee];
        }
        return selectedFundList.map((fundCode) => ({
            code: fundCode,
            name: displayFundName(fundDictionary.find((fund) => fund.code === fundCode)?.name) || fundCode,
            fees: `${getSelectedFundsFeesMap[fundCode]}% yearly`,
        }));
    }
);

export const getIsFilteredByRiskProfile = createSelector(getSelectedFundFilters, (selectedFundFilters) =>
    RISK_CHECKBOX_ITEMS.some((riskItem) => selectedFundFilters[riskItem.key])
);
export const getIsFilteredByCompliance = createSelector(getSelectedFundFilters, (selectedFundFilters) =>
    COMPLIANCE_CHECKBOX_ITEMS.some((complianceItem) => selectedFundFilters[complianceItem.key])
);
export const getIsFilteredByStatus = createSelector(getSelectedFundFilters, (selectedFundFilters) =>
    STATUS_CHECKBOX_ITEMS.some((statusItem) => selectedFundFilters[statusItem.key])
);

export const getNoFundFiltersSelected = createSelector(
    getIsFilteredByRiskProfile,
    getIsFilteredByCompliance,
    getIsFilteredByStatus,
    (isFilteredByRiskProfile, isFilteredByCompliance, isFilteredByStatus) =>
        !isFilteredByRiskProfile && !isFilteredByCompliance && !isFilteredByStatus
);

export const getIsShowingMoreFunds = createSelector(
    getAdvancedFundPicker,
    (advancedFundPicker) => advancedFundPicker.showMoreFunds
);

export const getShowTextSearchBar = createSelector(
    getAdvancedFundPicker,
    (advancedFundPicker) => advancedFundPicker.showTextSearchBar
);

export const getAllAdvancedFunds = createSelector(getFundReferenceData, (funds) => ({
    lowRisk: funds.lowRisk.map((fund) => displayFund(fund, 'lowRisk')),
    mediumRisk: funds.mediumRisk.map((fund) => displayFund(fund, 'mediumRisk')),
    highRisk: funds.highRisk.map((fund) => displayFund(fund, 'highRisk')),
}));

export const getSortedFundData = createSelector(getFundReferenceData, (funds) => sortFundData(funds));

export const getSuggestedSearchFunds = createSelector(getSortedFundData, getTempSearchQuery, suggestSearchFunds);

export const getSelectedFundFilterCarouselItems = createSelector(getSelectedFundFilters, (selectedFundFilters) =>
    ADVANCED_FUND_PICKER_CHECKBOX_ITEMS.reduce(
        (acc, item) => (selectedFundFilters[item.key] ? [...acc, item] : acc),
        []
    )
);

export const getAllFilteredResultFunds = createSelector(
    getSortedFundData,
    getSelectedFundFilters,
    getIsFilteredByRiskProfile,
    getIsFilteredByCompliance,
    getIsFilteredByStatus,
    filterFundData
);

export const getIsSearchOrFiltering = createSelector(
    getSelectedSearchQuery,
    getSelectedFundFilterCarouselItems,
    (searchQuery, fundFilterItems) => {
        return (searchQuery && searchQuery !== '') || (fundFilterItems && !!fundFilterItems.length);
    }
);

export const getSearchAndFilterResultFunds = createSelector(
    getAllFilteredResultFunds,
    getSelectedSearchQuery,
    (filteredResultFunds, selectedSearchQuery) => {
        let baseFundList = filteredResultFunds;

        if (selectedSearchQuery && selectedSearchQuery !== '') {
            baseFundList = filteredResultFunds.filter((fund) => searchFundByName(fund, selectedSearchQuery));
        }

        return baseFundList.map((fund) => {
            return displayFund(fund, RISK_NAMES_TO_RISK_CATEGORY[fund.riskProfile]);
        });
    }
);

export const getAdvancedFundPickerFormIsValid = createSelector(getSelectedFunds, (selectedFunds) =>
    Object.values(selectedFunds.value).includes(true)
);

export const getAreMaxFundsSelected = createSelector(
    getSelectedFundList,
    getRegularContribution,
    getLumpSumContribution,
    (funds, regularContribution, lumpSumContribution) => {
        let maxFundsExceeded = false;
        const fundsArray = [...funds];
        // Push a theoretical fund for the fund the user is trying to select, to determine
        // If adding this fund will exceed the max funds selected
        fundsArray.push('current');

        if (regularContribution.value) {
            // Create array of values to pass to splitPercentagesEvenly
            const valueArray = fundsArray.map(() => {
                value: null;
            });
            const regularSplit = splitPercentagesEvenly({ splitList: valueArray });
            // Get the lowest % returned, and check if this results in a value < 100
            const lowestPercentage = Math.min(...regularSplit.map((percentage) => percentage.value));
            maxFundsExceeded = maxFundsExceeded || (regularContribution.value * lowestPercentage) / 100 < 100;
        }

        if (lumpSumContribution.value) {
            // Create array of values to pass to splitPercentagesEvenly
            const valueArray = fundsArray.map(() => {
                value: null;
            });
            const lumpSumSplit = splitPercentagesEvenly({ splitList: valueArray });
            // Get the lowest % returned, and check if this results in a value < 100
            const lowestPercentage = Math.min(...lumpSumSplit.map((percentage) => percentage.value));
            maxFundsExceeded = maxFundsExceeded || (lumpSumContribution.value * lowestPercentage) / 100 < 100;
        }

        return maxFundsExceeded;
    }
);
