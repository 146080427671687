import { atRetirementServices } from './atRetirement/index';
import { raOptimalServices } from './raOptimal/index';
import { efpServices } from './easiplusFuneralPlan/index';
import { cvsServices } from './customerVerificationService';
import { personalLoansCalculatorService } from './personalLoansCalculator';
import { publicWebService } from './publicWeb';
import { taxFreeSavingsAccountServices } from './taxFreeSavingsAccount';
import { roaQuoteToolService } from './roaQuoteTools/';
import { priceTableService } from './priceTable';
import { customerComplaintService } from './customerComplaint';
import { roaCarInsuranceService } from './roaCarInsurance';
import { fundDeclarationService } from './fundDeclaration';
import { roaTravelInsuranceServices } from './roaTravelInsurance';
import { customerSatisfactionSurveyService } from './customerSatisfactionSurvey';
import { ugMotorInsuranceCover } from './ugMotorInsurance';
import { ltd } from './ltd';
import { investmentCalculatorService } from './investmentCalculator';
import { makeAClaimServices } from './makeAClaim';
import { digitalOnboardingService } from './digitalOnboarding';
import { keMotorInsuranceCover } from './keMotorInsurance';
import { domesticInsuranceCover } from './domesticInsurance';
import { dlpServices } from './digiLifeLoanProtection';
import { travelInsuranceCover } from './travel';
import { biasharaFlexiCover } from './biasharaFlexi';
import { doctorsPiCover } from './doctorsPi';
import { personalAccidentCover } from './personalAccident';
import { rwMotorInsuranceCover } from './motorPrivateRW';
import { personalPensionPlanCover } from './personalPensionPlan';
import { allsureInsuranceQuoteService } from './allsureInsurance';
import { digitalSavingsCover } from './digitalSavings';
import { healthInsuranceRWCover } from "./healthInsuranceRW";


export const services = (store) => (next) => (action) => {
    const executedRules =
        healthInsuranceRWCover(store, next, action) ||
        personalPensionPlanCover(store, next, action) ||
        digitalSavingsCover(store, next, action) ||
        doctorsPiCover(store, next, action) ||
        biasharaFlexiCover(store, next, action) ||
        domesticInsuranceCover(store, next, action) ||
        travelInsuranceCover(store, next, action) ||
        keMotorInsuranceCover(store, next, action) ||
        ugMotorInsuranceCover(store, next, action) ||
        cvsServices(store, next, action) ||
        atRetirementServices(store, next, action) ||
        raOptimalServices(store, next, action) ||
        efpServices(store, next, action) ||
        publicWebService(store, next, action) ||
        personalLoansCalculatorService(store, next, action) ||
        taxFreeSavingsAccountServices(store, next, action) ||
        roaQuoteToolService(store, next, action) ||
        priceTableService(store, next, action) ||
        customerComplaintService(store, next, action) ||
        roaCarInsuranceService(store, next, action) ||
        roaTravelInsuranceServices(store, next, action) ||
        fundDeclarationService(store, next, action) ||
        customerSatisfactionSurveyService(store, next, action) ||
        ltd(store, next, action) ||
        roaCarInsuranceService(store, next, action) ||
        investmentCalculatorService(store, next, action) ||
        makeAClaimServices(store, next, action) ||
        digitalOnboardingService(store, next, action) ||
        personalAccidentCover(store, next, action) ||
        dlpServices(store, next, action) ||
        rwMotorInsuranceCover(store, next, action);
        allsureInsuranceQuoteService(store, next, action);

    if (!executedRules) return next(action);

    return executedRules;
};
