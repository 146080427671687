import { createSelector } from "reselect";
import { getCustomerVerificationService } from "./index";
import { PAGES, PAGE_INDICES } from "../../actions/customerVerificationService/types";
import { getActiveQuestionNumber, getTotalNumberOfQuestions } from "./knowledgeBasedAuthentication";

export const getRouting = createSelector(getCustomerVerificationService, cvs => cvs.routing);
export const getActivePageIndex = createSelector(getRouting, routing => routing.activePageIndex);
export const getDoCvsNavigation = createSelector(getRouting, routing => routing.doNavigation);
export const getDoExitCvsNavigation = createSelector(getRouting, routing => routing.doExitNavigation);
export const getIsSelfiePage = createSelector(getRouting, routing => routing.activePageIndex === PAGE_INDICES.SELFIE);
export const getIsKbaQuestionPage = createSelector(getRouting, routing => routing.activePageIndex === PAGE_INDICES.KBA);
export const getParentAppUrl = createSelector(getRouting, routing => routing.parentAppUrl);
export const getParentApp = createSelector(getRouting, routing => routing.parentApp);
export const getCallbackAction = createSelector(getRouting, routing => routing.callbackAction);

export const getProgressBarSteps = createSelector(
    getIsKbaQuestionPage,
    getTotalNumberOfQuestions,
    (isKbaPage, totalQuestions) => {
        if (isKbaPage) {
            const array = [];
            for (var i = 0; i < totalQuestions; i++) {
                array.push(PAGES[PAGES.length - 1].TITLE)
            }
            return array.join('|');
        }

        return PAGES
                .filter(page => page.URL_PART !== 'kba-interlude')
                .map(page => page.TITLE)
                .join('|');
    }
);

export const getProgressBarIndex = createSelector(
    getIsKbaQuestionPage,
    getActiveQuestionNumber,
    getActivePageIndex,
    (isKbaPage, questionNumber, activePageIndex) => {
        if (isKbaPage) {
            return questionNumber - 1;
        }

        return activePageIndex - 1;
    }
);
