import { createSelector } from 'reselect';
import { getRoaCarInsuranceState } from './index';
import { FORM_STATUS } from '../../reducers/status';
import {APICallSelector} from "../roaQuoteTools/api";

export const getVehicleDetails = createSelector(
    getRoaCarInsuranceState,
    (state) => state.vehicleDetails
);

export const getVehicleDetailsForm = createSelector(
    getVehicleDetails,
    (state) => state.form
);

export const getVehicleDetailsFormIsValid = createSelector(
    getVehicleDetails,
    (state) => state.formStatus === FORM_STATUS.VALID
);

export const getVehicleDetailsData   = createSelector(
    getVehicleDetails,
    (state) => state.vehicleDetailsData
);

export const getFormStatus = createSelector(
    getVehicleDetails,
    (state) => state.formStatus === FORM_STATUS.VALID
);

// Form field selectors
const makeGetField = (fieldId) =>
    createSelector(getVehicleDetailsForm, (vehicleDetailsForm) => vehicleDetailsForm[fieldId]);

export const getRegistrationNumber = makeGetField('registrationNumber');
export const getChassisNumber = makeGetField('chassisNumber');
export const getEngineNumber = makeGetField('engineNumber');
export const getCoverDate = makeGetField('coverDate');
export const getIdentificationMeans = makeGetField('identificationMeans');
export const getIdentificationMeansData = makeGetField('identificationMeansData');

// API Calls Selectors
export const getIsApiPending = APICallSelector.getPendingStatus(getVehicleDetails);
