import { createSelector } from 'reselect';
import { getHealthInsuranceRwState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getPersonalDetails = createSelector(
    getHealthInsuranceRwState,
    state => state.personalDetails
);

export const getLivesCoveredPersonalDetails = createSelector(
    getHealthInsuranceRwState,
    state => state.livesCovered
);

const makeGetPersonalDetailsField = fieldName => 
    createSelector(getPersonalDetails, personalDetails => personalDetails[fieldName]);

export const getPersonalDetailsForm = makeGetPersonalDetailsField('form');

export const getPersonalDetailsFormValid = createSelector(
    getPersonalDetails, 
    personalDetails => personalDetails.formStatus === FORM_STATUS.VALID,
)

export const getMyDetails = createSelector(
    getLivesCoveredPersonalDetails, 
    livesCovered => livesCovered.myDetails,
)

export const getSpouseDetails = createSelector(
    getLivesCoveredPersonalDetails, 
    livesCovered => livesCovered.spouseDetails,
)

export const getChildrenDetails = createSelector(
    getLivesCoveredPersonalDetails, 
    livesCovered => livesCovered.childrenDetails,
)

export const getParentsDetails = createSelector(
    getLivesCoveredPersonalDetails, 
    livesCovered => livesCovered.parentsDetails,
)
export const getExtendedDetails = createSelector(
    getLivesCoveredPersonalDetails, 
    livesCovered => livesCovered.extendedDetails,
)

export const getLivesCoveredScreen = createSelector(
    getLivesCoveredPersonalDetails, 
    livesCovered => livesCovered.screen,
)



const makeGetFieldForm = (fieldId) => createSelector(getPersonalDetailsForm, (form) => form[fieldId]);

export const getFirstNameValue = makeGetFieldForm('firstName');
export const getSurnameValue = makeGetFieldForm('surname');
export const getDobValue = makeGetFieldForm('dob');
export const getEmailValue = makeGetFieldForm('email');
export const getMobileNumberValue = makeGetFieldForm('mobileNumber');
export const getIdPassportValue = makeGetFieldForm('idPassport');
export const getTinNumberValue = makeGetFieldForm('tinNumber');
export const getCityValue = makeGetFieldForm('city');
export const getStreetAddressValue = makeGetFieldForm('streetAddress');
export const getBuildingValue = makeGetFieldForm('building');
export const getPostalCodeValue = makeGetFieldForm('postalCode');
export const getPostalBoxValue = makeGetFieldForm('postalBox');
