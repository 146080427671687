export const EDUCATION_CALCULATOR_TRANSITION = 'education/calculator/transition/';

export const CALCULATE_TRANSITION_RESULTS = EDUCATION_CALCULATOR_TRANSITION + 'calculateResults';
export const calculateResults = () => ({ type: CALCULATE_TRANSITION_RESULTS });

export const FORM_VALID_TRANSITION_EDUCATION_CALCULATOR_FORM = EDUCATION_CALCULATOR_TRANSITION + 'formValid';
export const FORM_INVALID_TRANSITION_EDUCATION_CALCULATOR_FORM = EDUCATION_CALCULATOR_TRANSITION + 'formInvalid';

export const transitionEducationCalculatorForm = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_EDUCATION_CALCULATOR_FORM }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_EDUCATION_CALCULATOR_FORM }),
};

export const EDUCATION_CALCULATOR_CLEAR_VALUES = 'education/calculator/clearValue/';
export const clearValues = () => ({ type: EDUCATION_CALCULATOR_CLEAR_VALUES });

export const EDUCATION_CALCULATOR_SET_VALUE = 'education/calculator/setValue/';
export const EDUCATION_CALCULATOR_SET_VALID = 'education/calculator/setValid/';

// Form fields
export const COST_OF_EDUCATION_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'costOfEducation';
export const COST_OF_EDUCATION_SET_VALID = EDUCATION_CALCULATOR_SET_VALID + 'costOfEducation';
export const DURATION_OF_STUDIES_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'durationOfStudies';
export const DURATION_OF_STUDIES_SET_VALID = EDUCATION_CALCULATOR_SET_VALID + 'durationOfStudies';
export const NUMBER_OF_YEARS_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'numberOfYears';
export const NUMBER_OF_YEARS_SET_VALID = EDUCATION_CALCULATOR_SET_VALID + 'numberOfYears';
export const CONTRIBUTING_DURING_STUDIES_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'contributingDuringStudies';
export const SAVING_ALREADY_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'savingAlready';
export const HOW_MUCH_SAVED_ALREADY_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'howMuchSavedAlready';
export const HOW_MUCH_SAVED_ALREADY_SET_VALID = EDUCATION_CALCULATOR_SET_VALID + 'howMuchSavedAlready';
export const MONTHLY_CONTRIBUTION_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'monthlyContribution';
export const MONTHLY_CONTRIBUTION_SET_VALID = EDUCATION_CALCULATOR_SET_VALID + 'monthlyContribution';

export const RESULTS_CALCULATED_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'resultsCalculated';
export const PRODUCT_OFFERING_TOGGLE = EDUCATION_CALCULATOR_SET_VALUE + 'productOffering';
export const SHOW_ASSUMPTIONS_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'showAssumptions';
export const CALCULATE_BUTTON_DISABLED_SET_VALUE = EDUCATION_CALCULATOR_SET_VALUE + 'calculateButtonDisabled';

// Assumption modal
export const EDUCATION_CALCULATOR_MODAL_SET_VALUE = 'education/calculatorModal/setValue/';
export const EDUCATION_CALCULATOR_MODAL_SET_VALID = 'education/calculatorModal/setValid/';

export const ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE = EDUCATION_CALCULATOR_MODAL_SET_VALUE + 'annualIncreaseInContributions';
export const ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID = EDUCATION_CALCULATOR_MODAL_SET_VALID + 'annualIncreaseInContributions';
export const EXPECTED_GROWTH_OF_SAVINGS_SET_VALUE = EDUCATION_CALCULATOR_MODAL_SET_VALUE + 'expectedGrowthOfSavings';
export const EXPECTED_GROWTH_OF_SAVINGS_SET_VALID = EDUCATION_CALCULATOR_MODAL_SET_VALID + 'expectedGrowthOfSavings';
export const EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALUE = EDUCATION_CALCULATOR_MODAL_SET_VALUE + 'expectedAnnualIncreaseInEducationCost';
export const EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALID = EDUCATION_CALCULATOR_MODAL_SET_VALID + 'expectedAnnualIncreaseInEducationCost';

export const EXPECTED_CONTRIBUTIONS_AND_EDUCATION_COST_SET_VALID = EDUCATION_CALCULATOR_MODAL_SET_VALID + 'expectedContributionAndEducationCost';

// Form fields
export const setCostOfEducationValue = (value) => ({ type: COST_OF_EDUCATION_SET_VALUE, value });
export const setCostOfEducationValid = (error) => ({ type: COST_OF_EDUCATION_SET_VALID, error });
export const setDurationOfStudiesValue = (value) => ({ type: DURATION_OF_STUDIES_SET_VALUE, value });
export const setDurationOfStudiesValid = (error) => ({ type: DURATION_OF_STUDIES_SET_VALID, error });
export const setNumberOfYearsValue = (value) => ({ type: NUMBER_OF_YEARS_SET_VALUE, value });
export const setNumberOfYearsValid = (error) => ({ type: NUMBER_OF_YEARS_SET_VALID, error });
export const setHowMuchSavedAlreadyValue = (value) => ({ type: HOW_MUCH_SAVED_ALREADY_SET_VALUE, value });
export const setHowMuchSavedAlreadyValid = (error) => ({ type: HOW_MUCH_SAVED_ALREADY_SET_VALID, error });
export const setMonthlyContributionValue = (value) => ({ type: MONTHLY_CONTRIBUTION_SET_VALUE, value });
export const setMonthlyContributionValid = (error) => ({ type: MONTHLY_CONTRIBUTION_SET_VALID, error });

export const setContributingDuringStudiesValue = (value) => ({ type: CONTRIBUTING_DURING_STUDIES_SET_VALUE, value });
export const setSavingAlreadyValue = (value) => ({ type: SAVING_ALREADY_SET_VALUE, value });
export const setResultCalculatedValue = (value) => ({ type: RESULTS_CALCULATED_SET_VALUE, value });
export const toggleProductOfferingValue = () => ({ type: PRODUCT_OFFERING_TOGGLE });
export const setShowAssumptionsValue = (value) => ({ type: SHOW_ASSUMPTIONS_SET_VALUE, value });
export const setCalculateButtonDisabledValue = (value) => ({ type: CALCULATE_BUTTON_DISABLED_SET_VALUE, value });

// Assumption modal
export const setAnnualIncreaseInContributionsValue = (value) => ({ type: ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE, value });
export const setAnnualIncreaseInContributionsValid = (error) => ({ type: ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID, error });
export const setExpectedGrowthOfSavingsValue = (value) => ({ type: EXPECTED_GROWTH_OF_SAVINGS_SET_VALUE, value });
export const setExpectedGrowthOfSavingsValid = (error) => ({ type: EXPECTED_GROWTH_OF_SAVINGS_SET_VALID, error });
export const setExpectedAnnualIncreaseInEducationCostValue = (value) => ({ type: EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALUE, value });
export const setExpectedAnnualIncreaseInEducationCostValid = (error) => ({ type: EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALID, error });

export const setContributionsAndEducationCostValid = (contributionsError, educationError) => 
    ({ type: EXPECTED_CONTRIBUTIONS_AND_EDUCATION_COST_SET_VALID, contributionsError, educationError});
