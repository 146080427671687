import {PRICE_TABLE_SET_VALUE} from "../../../actions/priceTable/table";
import {priceTableValidationRules} from "./table";

export const priceTableValidation = (store, next, action) => {

    if (action.type.startsWith(PRICE_TABLE_SET_VALUE)) {
        return priceTableValidationRules(store, next, action);
    }

    return null;
};
