import axios from 'axios';
import { v4 as uuid } from 'uuid';

import {
	getNameValue,
	getAgentPhoneNumberValue,
} from '../../../selectors/roaCarInsurance/agentReferral';

import { dropOffLeadPersonalDetails } from '../../../actions/roaCarInsurance/aboutYou';
import {
	getDropOffDetails,
	getFirstName,
	getLastName,
	getEmailAddress,
	getPhoneNumber,
} from '../../../selectors/roaCarInsurance/aboutYou';


export const dropOffLeadsService = (store, next, action) => {
	const result = next(action);
	const state = store.getState();

	const newDropOffDetails = buildPayload(state);
	const previousDropOffDetails = getDropOffDetails(state);
	const dropOffDetailsChanged =
		JSON.stringify(previousDropOffDetails) !==
		JSON.stringify(newDropOffDetails);

	if (!dropOffDetailsChanged) return result;

	(async () => {
		if (await submitDropOffLead(newDropOffDetails)) {
			store.dispatch(dropOffLeadPersonalDetails(newDropOffDetails));
		}
	})();
	return result;
};

export const submitDropOffLead = async payload => {
	try {
		const url = '/om-api/roa-car-insurance/drop-off-leads';
		const config = { headers: { 'Content-Type': 'application/json' } };
		const response = await axios.post(url, payload, config);
		return response.status === 200;
	} catch (error) {
		console.log({error})
		return false;
	}
};

export const buildPayload = state => ({
	FIRST_NAME: getFirstName(state).value,
	SECOND_NAME: getLastName(state).value,
	PHONE_NUMBER: getPhoneNumber(state).value,
	EMAIL_ADDRESS: getEmailAddress(state).value,
	INTERMEDIARY_PHONE_NUMBER: getAgentPhoneNumberValue(state).value || '',
	INTEMEDIARY_CODE: getNameValue(state).value || '',
	SOURCE_SYSTEM_ID: uuid(),
	SOURCE_SYSTEM_NAME: '176830001',
	POLICY_TYPE: 'CarInsurance',
	env: ''
});
