import {
    SET_CAR_MAKE_VALUE,
    SET_CAR_MODEL_VALUE,
    SET_YEAR_OF_MANUFACTURE_VALUE,
    SET_COVER_START_DATE_VALUE,
    SET_IS_CAR_VALUED_VALUE,
    SET_CAR_VALUER_VALUE,
    SET_CAR_USE_VALUE,
    SET_VALUATION_VALUE,
    SET_HAS_ALARM_VALUE,
    SET_SHARE_EMAIL_VALUE,
    SET_EXCESS_PROTECTOR_VALUE,
    SET_UNCOMMON_RISK_VALUE,
    SET_IS_RARE_MODEL_VALUE,
    SET_OTHER_VALUER_VALUE,
    SET_VEHICLE_TYPE_VALUE,
    SET_FULL_NAME_VALUE,
    SET_OUTSIDE_EA_VALUE,
    SET_FIRST_TIME_PURCHASE_VALUE,
    EMAIL_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    setCarMakeValid,
    setCarModelValid,
    setYearOfManufactureValid,
    setCoverStartDateValid,
    setIsCarValuedValid,
    setCarValuerValid,
    setCarUseValid,
    setHasAlarmValid,
    setValuationValid,
    setExcessProtectorValid,
    setUncommonRiskValid,
    setShareEmailValid,
    setIsRareModelValid,
    setOtherValuerValid,
    setVehicleTypeValid,
    setFullNameValid,
    setOutsideEaValid,
    setFirstTimePurchaseValid,
    setEmailValid,
    setPhoneNumberValid,
    setIdNumberValid,
} from '../../../actions/ugMotorInsurance/quote';

import {
    validateYearOfManufacture,
    validateSelection,
    validateNumber,
    validateEmail,
    validateName,
    validateSingleSelection,
    validateContactNumber,
    validateNumericString,
} from '@om/validation';
import { getVehicleTypeValue, getSelectedCover } from '../../../selectors/ugMotorInsurance/quote';

import { validationIsEqual } from '../status';
import { validateUgandanId } from '@om/validation/src/validators/ugandan-id-validator';
const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;
let prevValidationAction = null;

const validateValuationMinValue = (vehicleType) => {
    if (gatsbyStack === 'ug') {
        return 5000000;
    }
    if (gatsbyStack === 'ke' && vehicleType === 'Car') {
        return 500000;
    }
    if (gatsbyStack === 'ke' && vehicleType === 'Motorcycle') {
        return 214285;
    }
};

export const quoteFormValidation = (store, next, action) => {
    const vehicleType = getVehicleTypeValue(store.getState());
    const selectedCover = getSelectedCover(store.getState());
    //const quoteForm = getQuotationForm(store.getState());
    const validationAction = (() => {
        let error;
        let today = new Date();
        //let validTodayDate = today.toLocaleDateString('en-NG').split('/').join('/');

        function formatDateToString(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        const validTodayDate = formatDateToString(today);

        function validateCoverDate(todayDate, coverDate) {
            const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

            if (!datePattern.test(coverDate)) {
                return 'Cover start date is not in valid format (dd/mm/yyyy)';
            }

            const [day1, month1, year1] = todayDate.split('/').map(Number);
            const [day2, month2, year2] = coverDate.split('/').map(Number);

            const date1 = new Date(year1, month1 - 1, day1);
            const date2 = new Date(year2, month2 - 1, day2);

            return date2 >= date1 ? null : `Enter date thats greater than or equal to ${todayDate}`;
        }

        function validatePassport(passport) {
            const passportRegex = /^[A-Z][A-Z0-9]{8,}$/;
            return passportRegex.test(passport);
        }

        switch (action.type) {
            case SET_FULL_NAME_VALUE:
                error = validateName(action.value, {
                    min: 4,
                    subject: 'Full Name',
                });
                return setFullNameValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Phone Number',
                    countryCode: gatsbyStack,
                });
                return setPhoneNumberValid(error);

            case ID_NUMBER_SET_VALUE:
                if (gatsbyStack === 'ug') {
                    error = validateUgandanId(action.value);

                    if (error !== null) {
                        const passportIsValid = validatePassport(action.value);
                        error = passportIsValid ? null : 'Enter valid national id/passport number';
                    }

                    return setIdNumberValid(error);
                } else {
                    error = validateNumericString(action.value, { min: 8, max: 15 });

                    return setIdNumberValid(error);
                }

            case SET_CAR_MAKE_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Car make' });
                return setCarMakeValid(error);
            case SET_CAR_MODEL_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Car model' });
                return setCarModelValid(error);
            case SET_YEAR_OF_MANUFACTURE_VALUE:
                error = validateYearOfManufacture(action.value, {
                    max: gatsbyStack === 'ke' && selectedCover.value === 'motorComprehensive' ? 15 : 200,
                    subject: 'Year of manufacture',
                });
                return setYearOfManufactureValid(error);
            case SET_COVER_START_DATE_VALUE:
                //error = validateDate(action.value, { required: true, min: validTodayDate });
                error = validateCoverDate(validTodayDate, action.value);
                return setCoverStartDateValid(error);
            case SET_IS_CAR_VALUED_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Is car valued' });
                return setIsCarValuedValid(error);
            case SET_CAR_VALUER_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Car valuer' });
                return setCarValuerValid(error);
            case SET_CAR_USE_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Car use' });
                return setCarUseValid(error);
            case SET_HAS_ALARM_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Has alarm' });
                return setHasAlarmValid(error);
            case SET_EXCESS_PROTECTOR_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Excess protector' });
                return setExcessProtectorValid(error);
            case SET_UNCOMMON_RISK_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Uncommon risk' });
                return setUncommonRiskValid(error);
            case SET_VALUATION_VALUE:
                error = validateNumber(action.value, {
                    required: true,
                    min: validateValuationMinValue(vehicleType.value),
                    subject: 'Valuation',
                });
                return setValuationValid(error);
            case SET_SHARE_EMAIL_VALUE:
                error = validateEmail(action.value, { min: 1, subject: 'Share email' });
                return setShareEmailValid(error);
            case SET_IS_RARE_MODEL_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Is car rare' });
                return setIsRareModelValid(error);

            case SET_OUTSIDE_EA_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Outisde EA',
                });
                return setOutsideEaValid(error);

            case SET_OTHER_VALUER_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Other valuer' });
                return setOtherValuerValid(error);

            case SET_VEHICLE_TYPE_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Vehicle type' });
                return setVehicleTypeValid(error);

            case SET_FIRST_TIME_PURCHASE_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'First Time Purchase' });
                return setFirstTimePurchaseValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
