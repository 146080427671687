import { validateAlphaNumericString } from '@om/validation';

import {
    DEC_ONE_COPY_SET_VALUE,
    setDecOneValid,
    DEC_TWO_COPY_SET_VALUE,
    setDecTwoValid,
    DEC_THREE_COPY_SET_VALUE,
    setDecThreeValid,
    DEC_FOUR_COPY_SET_VALUE,
    setDecFourValid,
    DEC_FIVE_COPY_SET_VALUE,
    setDecFiveValid,
    DEC_SIX_COPY_SET_VALUE,
    setDecSixValid,
} from '../../../actions/doctorsPi/documents';

import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const declarationsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case DEC_ONE_COPY_SET_VALUE:
                error = validateAlphaNumericString(action.value !== null ? action.value.toString() : action.value, {
                    subject: 'First Declaration',
                    required: true,
                });

                return setDecOneValid(error);

            case DEC_TWO_COPY_SET_VALUE:
                error = validateAlphaNumericString(action.value !== null ? action.value.toString() : action.value, {
                    subject: 'Second Declaration',
                    required: true,
                });
                return setDecTwoValid(error);
            case DEC_THREE_COPY_SET_VALUE:
                error = validateAlphaNumericString(action.value !== null ? action.value.toString() : "", {
                    subject: 'Third Declaration',
                    required: true,
                });
                return setDecThreeValid(error);

            case DEC_FOUR_COPY_SET_VALUE:
                error = validateAlphaNumericString(action.value !== null ? action.value.toString() : "", {
                    subject: 'Fourth Declaration',
                    required: true,
                });
                return setDecFourValid(error);
            case DEC_FIVE_COPY_SET_VALUE:
                error = validateAlphaNumericString(action.value !== null ? action.value.toString() : "", {
                    subject: 'Fifth Declaration',
                    required: true,
                });
                return setDecFiveValid(error);
            case DEC_SIX_COPY_SET_VALUE:
                error = validateAlphaNumericString(action.value !== null ? action.value.toString() : "", {
                    subject: 'Sixth Declaration',
                    required: true,
                });
                return setDecSixValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
