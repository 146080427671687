import {INSURANCE_TYPE_SET_VALUE,INSURANCE_TYPE_SET_VALID, FORM_INVALID_TRANSITION_INSURANCE_TYPE, FORM_VALID_TRANSITION_INSURANCE_TYPE, TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE } from '../../actions/allsureInsurance/insuranceType';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initInsuranceType = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        homeOwners: {
            value: 'secondary',
            error: null,
            status: null,
        },
        homeContent: {
            value: 'secondary',
            error: null,
            status: null,
        },
        motor: {
            value: 'secondary',
            error: null,
            status: null,
        },
        privacyNotice: {
            value: null,
            error: null,
            status: null,
        },
        

    },
};
// const initialAction={type:"",value:{fieldType:"",value:""}}
export default (state = initInsuranceType, action) => {
    const trimField = (value) => (value ? value.trim() : value);
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    // if ( CLAIMENT_DETAILS_SET_VALUE) {
        switch (action.type) {
            case FORM_VALID_TRANSITION_INSURANCE_TYPE:
                return { ...state, formStatus: FORM_STATUS.VALID };
            case FORM_INVALID_TRANSITION_INSURANCE_TYPE:
                return { ...state, formStatus: FORM_STATUS.INVALID };
            case INSURANCE_TYPE_SET_VALUE:
                return updateFormField(action.value.fieldType, (field) => ({
                    ...field,
                    value: action.value.value,
                }));

            case INSURANCE_TYPE_SET_VALID:
                const {fieldType,error}=action
                return  updateFormField(fieldType, (field) => ({
                    ...field,
                    error: error,
                    status: mapError(error),
                  }));
            case TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE:
                        return updateFormField(action.value.fieldType, (field) => ({
                            ...field,
                            value: action.value.value,
                        }));
            default:
                return state;
        // }
    } 
};
