import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    REG_NUMBER_SET_VALUE,
    REG_NUMBER_SET_VALID,
    CHASIS_NUMBER_SET_VALUE,
    CHASIS_NUMBER_SET_VALID,
    TIN_NUMBER_SET_VALUE,
    TIN_NUMBER_SET_VALID,
    FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    COLLECT_CAR_DETAILS_FORM_DATA,
    API_PENDING_TRANSITION_CAR_DETAILS_FORM,
    API_SUCCESS_TRANSITION_CAR_DETAILS_FORM,
    API_FAILURE_TRANSITION_CAR_DETAILS_FORM,
    SELECTED_FORM_SET_VALUE,
    SUBMIT_CAR_DETAILS_SUCCESS,
    SUBMIT_CAR_DETAILS_FAILURE,
} from '../../actions/motorPrivateRW/carDetails';

export const initCarDetails = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    carDetailsReferenceData: null,
    apiCallStatus: null,
    selectedForm: 'carDetails',
    form: {
        registrationNumber: { value: null, error: null, status: null },
        chasisNumber: { value: null, error: null, status: null },
        tinNumber: { value: null, error: null, status: null },
    },
};

export default (state = initCarDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case REG_NUMBER_SET_VALUE:
            return updateFormField('registrationNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case REG_NUMBER_SET_VALID:
            return updateFormField('registrationNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CHASIS_NUMBER_SET_VALUE:
            return updateFormField('chasisNumber', (field) => ({ ...field, value: action.value }));

        case CHASIS_NUMBER_SET_VALID:
            return updateFormField('chasisNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TIN_NUMBER_SET_VALUE:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case TIN_NUMBER_SET_VALID:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SELECTED_FORM_SET_VALUE:
            return { ...state, selectedForm: action.value };
        //    API Calls
        case API_PENDING_TRANSITION_CAR_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_CAR_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_CAR_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_CAR_DETAILS_FORM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case SUBMIT_CAR_DETAILS_SUCCESS:
            return { ...state, carDetailsReferenceData: action.value };
        case SUBMIT_CAR_DETAILS_FAILURE:
            return { ...state, carDetailsReferenceData: action.error };

        // Reset the state
        case REINITIALIZE:
            return initCarDetails;
        default:
            return state;
    }
};
