import {createSelector} from "reselect";
import {getPaymentSubmission} from "./paymentDetails";
import {getPersonalDetailsSubmission} from "./personalDetails";
import {getBeneficiariesSubmission} from "./beneficiaries";
import {getQuoteNumber, getQuoteSubmission} from "./quote";
import {getLivesCoveredSubmission} from "./livesCovered";

export const getEfpSubmission = createSelector(
    getQuoteNumber,
    getPaymentSubmission,
    getPersonalDetailsSubmission,
    getBeneficiariesSubmission,
    getLivesCoveredSubmission,
    (quoteNumber, paymentInformation, policyHolder, beneficiaries, coveredMembers) => {
        const totalPremium = coveredMembers.reduce((acc, mem) => {
            const amount = mem.totalPremium || 0;
            return amount + acc;
        }, 0);
        return {
            totalPremium,
            policyNumber: '',
            quoteNumber,
            paymentInformation,
            policyHolder,
            beneficiaries,
            coveredMembers,
        };
    }
);

export const getEfpQuote = createSelector(
    getQuoteSubmission,
    coveredMembers => {
        const totalPremium = coveredMembers.reduce((acc, member) => acc + member.totalPremium, 0);
        return {
            totalPremium,
            coveredMembers,
        };
    }
);
