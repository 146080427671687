import {CVS_ROUTING_SET_VALUE} from "../../../actions/customerVerificationService/routing";
import {routingRules} from "./routing";
import {ID_VERIFICATION_SET_VALID} from "../../../actions/customerVerificationService/idVerification";
import {idVerificationRules} from './idVerification';
import {CONSENT_PAGE_SET_VALUE} from "../../../actions/customerVerificationService/consent";
import {consentRules} from "./consent";
import {QUESTION_EXPIRED_PERFORM_ACTION} from "../../../actions/customerVerificationService/knowledgeBasedAuthentication";
import {kbaRules} from "./knowledgeBasedAuthentication";
import {CALL_ME_BACK_SET_VALUE} from "../../../actions/customerVerificationService/callMeBack"
import {callMeBackRules} from "./callMeBack";
import {CVS_SELFIE_SET_VALUE, IFRAME_VALID_TRANSITION_TRIGGER} from "../../../actions/customerVerificationService/selfie";
import {selfieRules} from "./selfie";

export const customerVerificationServiceRules = (store, next, action) => {
    if (action.type.startsWith(CVS_ROUTING_SET_VALUE)) {
        return routingRules(store, next, action);
    }

    if (action.type === QUESTION_EXPIRED_PERFORM_ACTION) {
        return kbaRules(store, next, action);
    }

    if (action.type.startsWith(ID_VERIFICATION_SET_VALID)) {
        return idVerificationRules(store, next, action);
    }

    if (action.type.startsWith(CONSENT_PAGE_SET_VALUE)) {
        return consentRules(store, next, action);
    }

    if (action.type.startsWith(CALL_ME_BACK_SET_VALUE)) {
        return callMeBackRules(store, next, action);
    }

    if (action.type.startsWith(CVS_SELFIE_SET_VALUE) ||
        action.type === IFRAME_VALID_TRANSITION_TRIGGER) {
        return selfieRules(store, next, action);
    }
    

    return null;
};
