import { getIsApiPending, getQuoteForm } from '../../../selectors/domesticInsurance/quote';
import {
    apiTransitionDomesticInsuranceQuoteForm,
    calculateDomesticInsurancePremiumSuccess,
} from '../../../actions/domesticInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';

export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionDomesticInsuranceQuoteForm.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateDomesticInsurancePremiumSuccess(resp.data));
                store.dispatch(apiTransitionDomesticInsuranceQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionDomesticInsuranceQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);

    const premiumCalculationPayload = {
        buildingOption:
            quotation?.selectedCover?.value === 'House and Contents' || quotation?.selectedCover?.value === 'House Only'
                ? true
                : false,
        buildingSum: quotation?.buildingSum?.value || 0,
        contentsOption:
            quotation?.selectedCover?.value === 'House and Contents' ||
            quotation?.selectedCover?.value === 'Contents Only'
                ? true
                : false,
        contentsSum: quotation?.contentsSum?.value || 0,
        allRisksOption: quotation?.allRisksOption?.value || false,
        risksSum: quotation?.risksSum?.value || 0,
        ownersOption: quotation?.ownersOption?.value || false,
        ownwersSum: quotation?.ownersSum?.value || 0,
        wiibaOption: quotation?.domesticServantsOption.value || false,
        totalEmployees: quotation?.domesticServantsNo.value || 0,
        ltaDiscount: false,
        ncdDiscount: false,
        eqLoading: quotation?.eq_loading.value || false,
        occupiersOption: quotation?.occupiersOption.value || false,
        occupiersSum: quotation?.occupiersAmount?.value || 0
    };

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/domestic-insurance/compute-premium';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
