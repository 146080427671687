import { combineReducers } from "redux";

import retirementCalculatorForm from './retirementCalculator'
import scrolling from './scrolling'
import retirementAssumptionsModel from './retirementAssumptionsModel'

export const retirementCalculator = combineReducers({
    retirementCalculatorForm,
    scrolling,
    retirementAssumptionsModel
})
