import axios from 'axios';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataFailure,
    submitPayloadDataSuccess,
} from '../../../actions/biasharaFlexi/payloadCollection';
import { triggerConfirmOrder } from '../../../actions/biasharaFlexi/payment';
import { getCompanyDetailsForm } from '../../../selectors/biasharaFlexi/companyDetails';
import {
    getBiasharaFlexiCoverDetailsForm,
    getBiasharaFlexiCoverDetailsReferenceData,
    getIsPayNow,
} from '../../../selectors/biasharaFlexi/coverDetails';
import { getEmployeeScvFile, getEmployeesList } from '../../../selectors/biasharaFlexi/employeeDetails';
import { getAccessToken, getPaymentsForm, getPaymentStatusData } from '../../../selectors/biasharaFlexi/payment';
import { getAgentForm } from '../../../selectors/biasharaFlexi/travelAgentModal';
import { getUploadDocumentsForm } from '../../../selectors/biasharaFlexi/uploadDocuments';

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        // if (!getIsApiPending(store.getState())) {
        store.dispatch(apiTransitionPayloadCollection.pending());
        try {
            const resp = await calculate(store.getState());

            store.dispatch(submitPayloadDataSuccess(resp.data));
            store.dispatch(apiTransitionPayloadCollection.success());
            store.dispatch(triggerConfirmOrder());
        } catch (e) {
            store.dispatch(submitPayloadDataFailure(e));
            store.dispatch(apiTransitionPayloadCollection.failure());
        }
        // }
    })();
    return next(action);
};

const payloadCollection = (state) => {
    const quotation = getBiasharaFlexiCoverDetailsForm(state);
    const companyDetails = getCompanyDetailsForm(state);
    const employees = getEmployeesList(state);
    const employeesCsv = getEmployeeScvFile(state);
    const documents = getUploadDocumentsForm(state);
    const premium = getBiasharaFlexiCoverDetailsReferenceData(state);
    const payments = getPaymentsForm(state);
    const isPayNow = getIsPayNow(state);
    const agent = getAgentForm(state);
    const paymentsData = getPaymentStatusData(state);

    const premiumCalculationPayload = {
        premium: premium.annual_premium || 'N/A',
        totalEmployees: quotation.employeesNumber.value,
        benefitAmount: quotation.benefitAmount.label.split(',').join(''),
        bandOption: quotation.benefitAmount.value,
    };

    const employeesDetailsPayload = {
        employees: employeesCsv ? 'N/A' : isPayNow ? 'N/A' : employees,
        employeeCsv: employeesCsv ? employeesCsv?.base64File : 'N/A',
    };

    const companyDetailsPayload = {
        companyName: companyDetails.companyName.value || 'N/A',
        companyEmail: companyDetails.email.value || 'N/A',
        companyPhoneNumber: companyDetails.mobileNumber.value || 'N/A',
        companyKraPin: companyDetails.kraPinNumber.value || 'N/A',
        companyCityAddress: companyDetails.city.value || 'N/A',
        companyStreetAddress: companyDetails.streetAddress.value || 'N/A',
        companyBuilding: companyDetails.building.value || 'N/A',
        companyPostalAddress: companyDetails.postalBox.value || 'N/A',
        companyPostalCode: companyDetails.postalCode.value || 'N/A',
        FirstName: companyDetails.firstName.value || 'N/A',
        LastName: companyDetails.lastName.value || 'N/A',
        IdNumber: companyDetails.idNumber.value || 'N/A',
    };

    const documentsPayload = {
        companyIncorporationCertificate: documents.incorporationCertificate.value?.base64File,
        companyKraCertificate: documents.kraCertificate.value?.base64File,
    };

    const agentPayload = {
        agentCode: agent.agentCode.value || 'N/A',
        referredByAgent: agent.intermediaryType.value === 'AGENTS' ? 'true' : 'false' || 'N/A',
    };

    const paymentPayload = {
        transactionRef: paymentsData?.CheckoutRequestID || 'N/A',
        modeOfPayment: payments?.networkProvider.value === 'mpesa' ? 'Mpesa' : 'Bank',
        transactionStatus: paymentsData?.message === 'Success' ? 'SUCCESS' : 'PENDING' || 'N/A',
        mpesaPhoneNumber: payments?.phoneNumber?.value || 'N/A',
        countryCode: 'KE',
    };

    return {
        ...premiumCalculationPayload,
        ...employeesDetailsPayload,
        ...companyDetailsPayload,
        ...documentsPayload,
        ...agentPayload,
        ...paymentPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/biashara-flexi/submit-payload/kenya';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        throw e;
    }
};
