export const FUND_DECLARATION_SET_VALUE = 'fundDeclarations/table/setValue/';
export const FUND_DECLARATION_CLEAR_VALUE = 'fundDeclarations/table/clearValue/';

export const FUNDS_DATA_SET_VALUE = FUND_DECLARATION_SET_VALUE + 'fundsData';
export const FUND_NAMES_SET_VALUE = FUND_DECLARATION_SET_VALUE + 'fundNames';
export const FUND_SELECTION_SET_VALUE = FUND_DECLARATION_SET_VALUE + 'fundSelection';
export const PAGINATION_SIZE_SET_VALUE = FUND_DECLARATION_SET_VALUE + 'paginationSize';
export const MODAL_TYPE_SET_VALUE = FUND_DECLARATION_SET_VALUE + 'modalType';
export const MODAL_TYPE_CLEAR_VALUE = FUND_DECLARATION_CLEAR_VALUE + 'modalType';

export const setFundsValue = (value) => ({ type: FUNDS_DATA_SET_VALUE, value });
export const setFundNames = (value) => ({ type: FUND_NAMES_SET_VALUE, value });
export const setSelectedFundValue = (value) => ({ type: FUND_SELECTION_SET_VALUE, value });
export const setPaginationSizeValue = (value) => ({ type: PAGINATION_SIZE_SET_VALUE, value });
export const setModalTypeValue = (value) => ({ type: MODAL_TYPE_SET_VALUE, value });
export const clearModalValue = () => ({ type: MODAL_TYPE_CLEAR_VALUE });

export const FETCH_FUND_DECLARATION_NAMES = 'fundDeclaration/table/getFunds/fundNames';
export const getFundNames = () => ({ type: FETCH_FUND_DECLARATION_NAMES });

export const FETCH_FUND_DECLARATION_FUNDS = 'fundDeclaration/table/getFunds/fundData';
export const getFunds = () => ({ type: FETCH_FUND_DECLARATION_FUNDS });

export const API_PENDING_TRANSITION_GET_FUNDS = FUND_DECLARATION_SET_VALUE + 'apiTransition/pending';
export const API_SUCCESS_TRANSITION_GET_FUNDS = FUND_DECLARATION_SET_VALUE + 'apiTransition/success';
export const API_FAILURE_TRANSITION_GET_FUNDS = FUND_DECLARATION_SET_VALUE + 'apiTransition/failure';

export const apiTransitionFundDeclarations = {
    pending: () => ({ type: API_PENDING_TRANSITION_GET_FUNDS }),
    success: () => ({ type: API_SUCCESS_TRANSITION_GET_FUNDS }),
    failure: () => ({ type: API_FAILURE_TRANSITION_GET_FUNDS }),
};

export const API_PENDING_TRANSITION_GET_FUND_NAMES = FUND_DECLARATION_SET_VALUE + 'apiNamesTransition/pending';
export const API_SUCCESS_TRANSITION_GET_FUND_NAMES = FUND_DECLARATION_SET_VALUE + 'apiNamesTransition/success';
export const API_FAILURE_TRANSITION_GET_FUND_NAMES = FUND_DECLARATION_SET_VALUE + 'apiNamesTransition/failure';

export const apiTransitionFundNames = {
    pending: () => ({ type: API_PENDING_TRANSITION_GET_FUND_NAMES }),
    success: () => ({ type: API_SUCCESS_TRANSITION_GET_FUND_NAMES }),
    failure: () => ({ type: API_FAILURE_TRANSITION_GET_FUND_NAMES }),
};
