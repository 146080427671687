import { createSelector } from 'reselect';
import { getIncomeTaxCalculator } from './index';
import { FORM_STATUS } from '../../reducers/status';

export function getCountryCodeFromEnv() {
    return (process.env.GATSBY_COUNTRY_SELECTOR || '').toUpperCase();
}

export const getCountryCodeSelector = createSelector(() => getCountryCodeFromEnv());

export const getBasicCalculator = createSelector(
    getIncomeTaxCalculator,
    (incomeTaxCalculator) => incomeTaxCalculator.basicCalculator
);

export const getBasicCalculatorFormValid = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.formStatus === FORM_STATUS.VALID
);

export const getBasicCalculatorForm = createSelector(getBasicCalculator, (basicCalculator) => basicCalculator.form);

export const getShowRecommendation = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.showRecommendation
);

export const getShowFiedDetailsStatus = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.showFiedDetailsStatus
);

export const getBasicCalculatorReferenceData = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.referenceData
);

export const getBasicCalculatorTaxBrackets = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.referenceData.taxBrackets
);

const getBasicCalculatorTaxRebates = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.referenceData.taxRebates
);

const makeGetField = (fieldId) => createSelector(getBasicCalculatorForm, (form) => form[fieldId]);

export const getTaxResult = createSelector(getBasicCalculator, (basicCalculator) => basicCalculator.result);

export const getAgeGroups = createSelector(getBasicCalculator, (basicCalculator) => basicCalculator.ageGroups);

export const getFrequencyOptions = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.frequencyOptions
);

export const getAgeValue = makeGetField('age');
export const getIncomeFrequencyValue = makeGetField('incomeFrequency');
export const getIncomeValue = makeGetField('incomeValue');

const getYearIncome = createSelector(getIncomeValue, getIncomeFrequencyValue, (incomeValue, incomeFrequency) =>
    incomeFrequency.value === 'Monthly' ? parseFloat(incomeValue.value) * 12 : parseFloat(incomeValue.value)
);

export function findTaxBracket(taxBrackets, annualIncome) {
    return taxBrackets.find((bracket) =>
        !bracket.max ? annualIncome >= bracket.min : annualIncome >= bracket.min && annualIncome <= bracket.max
    );
}

const getTaxBracket = createSelector(
    getCountryCodeSelector,
    getYearIncome,
    getBasicCalculatorTaxBrackets,
    (countryCode, yearIncome, taxBrackets) =>
        (taxBrackets[countryCode] ?? taxBrackets['ZA']).find((bracket) =>
            !bracket.max ? yearIncome >= bracket.min : yearIncome >= bracket.min && yearIncome <= bracket.max
        )
);

export const getCalculationInfo = createSelector(
    getBasicCalculator,
    (basicCalculator) => basicCalculator.referenceData.infoData.resultCard
);

export const getMonthIncome = createSelector(getIncomeValue, getIncomeFrequencyValue, (incomeValue, incomeFrequency) =>
    incomeFrequency.value === 'Yearly' ? parseFloat(incomeValue.value) / 12 : parseFloat(incomeValue.value)
);

export const getTaxValue = createSelector(
    getCountryCodeSelector,
    getYearIncome,
    getTaxBracket,
    getAgeValue,
    getBasicCalculatorTaxRebates,
    (countryCode, yearIncome, bracket, age, rebates) => {
        if (countryCode == 'ZA') {
            return yearIncome && bracket && yearIncome > rebates[age.value].threshold
                ? (bracket.baseTax - rebates[age.value].base + (yearIncome - bracket.threshold) * bracket.rate) / 12
                : 0;
        }

        return yearIncome && bracket ? ((yearIncome - bracket.threshold) * bracket.rate + bracket.baseTax) / 12 : 0;
    }
);
