import { transitionMotorInsuranceDeclarationsForm } from '../../../actions/ugMotorInsurance/declarations';
import { getDeclarationsForm } from '../../../selectors/ugMotorInsurance/declarations';

import { FIELD_STATUS } from '../../../reducers/status';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const declarationsFormRules = (store, next, action) => {
    const result = next(action);
    const declarationsForm = getDeclarationsForm(store.getState());
    const declarationsFormValid = validateDeclarationsFormDetails(declarationsForm);
    const formAction = declarationsFormValid
        ? transitionMotorInsuranceDeclarationsForm.formValid()
        : transitionMotorInsuranceDeclarationsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateDeclarationsFormDetails = (formFields) => {
    if (gatsbyStack === 'ke') {
        return true;
    } else {
        /*
        &&
            formFields.modeOfPayment &&
            formFields.modeOfPayment.status === FIELD_STATUS.VALID &&
            formFields.sourceOfFunds === FIELD_STATUS.VALID
        */
        return formFields.stickerCollection && formFields.stickerCollection.status === FIELD_STATUS.VALID;
    }
};
