import { getIsApiPending, getQuoteForm } from '../../../selectors/motorPrivateRW/quote';
import {
    apiTransitionMotorInsuranceQuoteForm,
    calculateMotorInsurancePremiumSuccess,
} from '../../../actions/motorPrivateRW/quote';
import { getAccessToken } from '../../../selectors/motorPrivateRW/payments';
import axios from 'axios';

export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionMotorInsuranceQuoteForm.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateMotorInsurancePremiumSuccess(resp.data));
                store.dispatch(apiTransitionMotorInsuranceQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionMotorInsuranceQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);
    const carYear = parseInt(quotation.yearOfManufacture.value);
    const currentYear = new Date().getFullYear();
    const carAge = currentYear - carYear;
    const premiumCalculationPayload = {
        vehicleType: quotation.vehicleType.value,
        value: quotation.carValue.value,
        age: carAge,
        seatCapacity: quotation.seatsNumber.value,
        numberOfOccupants: quotation.coveredNumber.value,
        sumInsuredPerOccupant: quotation.sumInsured.value,
        comprehensive: quotation.selectedCover.value === 'motorComprehensive' ? true : false,
        comesa: quotation.carReach.value === 'rwanda' ? false : true,
        paymentPlan: quotation.insurancePaymentPlan.value,
        yearOfManufacture: quotation.yearOfManufacture.value,
    };

    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/rw-motor-insurance/quote';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
