const initialPriceTableReferenceData = {
    investmentSeriesFunds: [
        {fundId: 'MMF', fundName: 'Money Market Fund A'},
        {fundId: 'IRA', fundName: 'Real Income Fund A'},
        {fundId: 'SGA', fundName: 'Stable Growth Fund A'},
        {fundId: 'MOA', fundName: 'Moderate Balanced Fund A'},
        {fundId: 'SNA', fundName: 'Balanced Fund A'},
        {fundId: 'FLA', fundName: 'Flexible Fund A'},
        {fundId: 'MSA', fundName: 'Maximum Return Fund of Funds A'},
        {fundId: 'INA', fundName: 'Investors; Fund A'},
        {fundId: 'EQA', fundName: 'Equity Fund A'},
        {fundId: 'GQA', fundName: 'Global Equity Fund A'},
    ]
}

export default (state = initialPriceTableReferenceData, action) => {
    switch (action.type) {
        default:
            return state;
    }

}
