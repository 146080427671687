export const HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_TRANSITION = 'savingsCalculator/howMuchYouNeedToSave/transition/'

export const FORM_VALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION = 'savingsCalculator/howMuchYouNeedToSave/formValid';
export const FORM_INVALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION = 'savingsCalculator/howMuchYouNeedToSave/formInvalid';

export const transitionHowMuchYouNeedToSaveCalculator = {
    formValid: () => ({ type: FORM_VALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION }),
    formInvalid: () => ({ type: FORM_INVALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION }),
};

export const HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE = 'savingsCalculator/howMuchYouNeedToSave/setValue/';
export const HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALID = 'savingsCalculator/howMuchYouNeedToSave/setValid/';

export const TOGGLE_SHOW_PRODUCT_OFFERING = `${HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE}/toggle/showProductOffering`;
export const toggleViewRecommendation = () => ({ type: TOGGLE_SHOW_PRODUCT_OFFERING });

export const REINITIALIZE_STATE_HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR = 'savingsCalculator/howMuchYouNeedToSave/reinitialize';

export const SAVINGS_GOAL_SET_VALUE = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'savingsGoal';
export const SAVINGS_GOAL_SET_VALID = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALID + 'savingsGoal';
export const setSavingsGoalValue = value => ({ type: SAVINGS_GOAL_SET_VALUE, value });
export const setSavingsGoalValid = error => ({ type: SAVINGS_GOAL_SET_VALID, error });

export const ACHIEVE_GOAL_DATE_SET_VALUE = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'achieveGoalDate';
export const ACHIEVE_GOAL_DATE_SET_VALID = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALID + 'achieveGoalDate';
export const setAchieveGoalDateValue = value => ({ type: ACHIEVE_GOAL_DATE_SET_VALUE, value });
export const setAchieveGoalDateValid = error => ({ type: ACHIEVE_GOAL_DATE_SET_VALID, error });

export const CURRENT_SAVINGS_SET_VALUE = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'currentSavings';
export const CURRENT_SAVINGS_SET_VALID = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALID + 'currentSavings';
export const setCurrentSavingsValue = value => ({ type: CURRENT_SAVINGS_SET_VALUE, value });
export const setCurrentSavingsValid = error => ({ type: CURRENT_SAVINGS_SET_VALID, error });

export const PRODUCT_RECOMMENDATION_SET_VALUE =  HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'productRecommendation';
export const RESULTS_VIEW_VALUE = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'viewResults';
export const SHOW_FIELD_DETAILS = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'showFielDetails';

export const RESULTS_SET_VALUE = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'setResults';
export const CALCULATE_HOW_MUCH_YOU_NEED_TO_SAVE = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'success';
export const RESULTS_SET_VALUE_FAILURE = HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE + 'failure';

export const HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SHOW_RESULT_SUMMARY = `${HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE}/showResultsSummary`;
export const setShowResultSummary = () => ({ type: HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SHOW_RESULT_SUMMARY });

export const HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_OPEN_MODAL = `${HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE}/openModal`;
export const setOpenModel = () => ({ type: HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_OPEN_MODAL});

export const HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_CLOSE_MODAL = `${HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE}/closeModal`;
export const setCloseModal = () => ({ type: HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_CLOSE_MODAL });

export const getSavingsResult = () => ({ type: RESULTS_SET_VALUE});
export const calculateHowMuchYouNeedToSave = (value) => ({ type: CALCULATE_HOW_MUCH_YOU_NEED_TO_SAVE, value});
export const getSavingsResultFailure = error => ({ type: RESULTS_SET_VALUE_FAILURE, error });

export const setViewRecommendation = () => ({ type: PRODUCT_RECOMMENDATION_SET_VALUE });
export const reInitialize = () => ({ type: REINITIALIZE_STATE_HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR });
export const showFielDetails = value => ({ type: SHOW_FIELD_DETAILS, value });

export const RESET_CALCULATOR_FORM = 'savingsCalculator/howMuchYouNeedToSave/reset/form';
export const resetCalculatorForm = () => ({ type: RESET_CALCULATOR_FORM });
