import axios from "axios";
import { BANK_ACCOUNT_TYPES } from "../../constants";
import { getReferenceBanks } from "../../../selectors/easiplusFuneralPlan/referenceData";
import { ACCOUNT_VALIDATION_STATUS } from "../../../actions/easiplusFuneralPlan/types";

import {
    getMobile,
    getEmail,
    getSurname,
    getId,
    getInitials,
} from "../../../selectors/easiplusFuneralPlan/personalDetails"
import {
    getIncludeCdv,
    getIncludeAvs,
    getBankName,
    getAccountNumber,
    getAccountType,
    getPaymentDetailsValid,
    getBankSupportedForEFP,
    getPayloadBankName
} from "../../../selectors/easiplusFuneralPlan/paymentDetails";
import {
    setAccountValidationStatus,
} from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { setRequestId } from "../../../actions/easiplusFuneralPlan/sessionCamVariables";

export const performAccountValidation = store => {
    const state = store.getState();

    // skip validation if payment details already passed
    if(getPaymentDetailsValid(state))
        return;

    // Supported banks go to AVS if toggled in ContentStack
    if(getBankSupportedForEFP(state) && getIncludeAvs(state)) {
        performAvsService(store);
        return;
    }
    
    // In all other scenarios go to CDV if it is toggled in ContentStack
    if(getIncludeCdv(state)) {
        performCdvService(store);
        return;
    }

    // If there is no verification setting mark as successful
    store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.PASSED));
   
    return;
}

export const performAvsService = store => {
    (async () => {
        // Set status as Pending
        store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.PENDING));
        const state = store.getState();

        const avsPayload = buildAvsPayload(state);
        const avsResponse = await submitAvs(avsPayload);

        if (avsResponse && avsResponse.status === 200 && avsResponse.data) {
            // If request was successful
            if(avsResponse.data.success) {
                // if AVS passed
                store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.PASSED));
            } else{
                // if AVS failed
                store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.FAILED));
            }
        } else {
            // If request was unsuccessful
            store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.ERROR));
        }

        if (avsResponse && 'data' in avsResponse && 'requestId' in avsResponse.data) {
            store.dispatch(setRequestId(avsResponse.data.requestId));
        }
    })();

    return;
}

export const performCdvService = store => {
    (async () => {
        // Set status as Pending
        store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.PENDING));
        const state = store.getState();

        const cdvPayload = buildCdvPayload(state);
        const cdvResponse = await submitCdv(cdvPayload);
        
        if (cdvResponse && cdvResponse.status === 200 && cdvResponse.data) {
            // If request was successful
            if(cdvResponse.data.success) {
                // if CDV passed
                store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.PASSED));
            } else{
                // if CDV failed
                store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.FAILED));
            }
        } else {
            // If request was unsuccessful
            store.dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.ERROR));
        }

        if (cdvResponse && 'data' in cdvResponse && 'requestId' in cdvResponse.data) {
            store.dispatch(setRequestId(cdvResponse.data.requestId));
        }
    })();

    return;
}

export const submitCdv = async payload => {
    try {
        const response = await axios.post('/om-api/check-digit-validation', payload, { headers: { 'Content-Type': 'application/json' } });
        return response;
    } catch (error) {
        return null;
    }
}

export const submitAvs = async payload => {
    try {
        const response = await axios.post('/om-api/account-verification-service', payload, { headers: { 'Content-Type': 'application/json' } });
        return response;
    } catch (error) {
        return null;
    }
}

export const buildCdvPayload = (state) => {
    
    const bankAccountNumber = getAccountNumber(state).value.split(" ").join("");
    const bankAccountType = convertAccountType(getAccountType(state).value);
    const bankBranchCode = getBranchCode(state, getBankName(state).value);

    return {
        bankAccountNumber: bankAccountNumber,
        bankAccountType: bankAccountType,
        bankBranchCode: bankBranchCode,
        clientReference: "None",
        policyAdminSystem: "VTG",
        systemIdentifier: "OMK"
    };
}

export const buildAvsPayload = (state) => {
    const initials = getInitials(state);

    const surname = getSurname(state).value;
    const idNumber = getId(state).value;

    // Extension applies to landlines which are accepted inputs
    const cellphoneNumberCountryCode = '+27';
    const mobileNumber = getMobile(state).value;
    const phoneNumberWithExt = cellphoneNumberCountryCode + mobileNumber.substr(1, mobileNumber.length);
    const emailAddress = getEmail(state).value;

    const bankAccountNumber = getAccountNumber(state).value.split(" ").join("");
    const bankAccountType = convertAccountType(getAccountType(state).value);

    const bankName = getBankName(state).value;
    const bankBranchCode = getBranchCode(state, bankName);

    const formattedBankName = getPayloadBankName(state);

    return {
        clientReference: "343434",
        id: idNumber,
        initials: initials,
        surnameConame: surname,
        emailAddress: emailAddress,
        cellularPhoneNumber: phoneNumberWithExt,
        bankName: formattedBankName,
        bankBranchCode: bankBranchCode,
        bankAccountNumber: bankAccountNumber,
        bankAccountType: bankAccountType,
    };
}

const convertAccountType = (accountType) => {
    return BANK_ACCOUNT_TYPES.find(bankAccountType => bankAccountType.CODE === accountType).TYPE;
}

const getBranchCode = (state, selectedBank) => {
    const banks = getReferenceBanks(state);
    return (banks.find(bank => bank.code === selectedBank).universalBranchCode);
}
