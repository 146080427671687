import {
    FIELD_STATUS,
    FORM_STATUS,
    TOGGLE_STATUS,
    API_CALL_STATUS,
} from '../status'

import {
    CLEAR_FORM,
    FIRSTNAME_SET_VALID,
    FIRSTNAME_SET_VALUE,
    FORM_INVALID_TRANSITION_CAPTURE_DETAILS,
    FORM_VALID_TRANSITION_CAPTURE_DETAILS,
    ID_NUMBER_SET_VALID,
    ID_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALID,
    MOBILE_NUMBER_SET_VALUE,
    QUALIFYING_TOGGLE_SIDE_DRAWER_WEB,
    QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES,
    RETURN_DATA_FROM_LEADS_QUALIFYING,
    SURNAME_SET_VALID,
    SURNAME_SET_VALUE,
    TOGGLE_CAPTURE_DETAILS_SIDE_DRAWER,
    TOGGLE_CREDIT_CHECK_CHECKBOX,
    QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_INITIAL,
    QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_PENDING,
    QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_SUCCESS,
    QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_ERROR,
    QUALIFYING_CHECK_CALL_ME_BACK_RETRY,
    EMPLOYMENT_TYPE_SET_VALUE,
    EMPLOYMENT_TYPE_SET_VALID,
    PAYSLIP_AVAILABILITY_SET_VALUE,
    PAYSLIP_AVAILABILITY_SET_VALID,
    WAGE_FREQUENCY_SET_VALUE,
    WAGE_FREQUENCY_SET_VALID,
    QUALIFYING_CHECK_CALL_ME_BACK_EMPLOYMENT_ERROR
} from '../../actions/publicWeb/qualifyingCheckCallMeBack';

const captureDetailsForm = {
    firstname: {value: null, error: null, status: null},
    surname: {value: null, error: null, status: null},
    idNumber: {value: null, error: null, status: null},
    mobileNumber: {value: null, error: null, status: null},
    employmentType: {value: null, error: null, status: null},
    payslipAvailability: {value: null, error: null, status: null},
    wageFrequency: {value: null, error: null, status: null},
    sidebarOpen: false,
    leadsResponse: '',
}

const initCaptureDetails = {
    formStatus: FORM_STATUS.INVALID,
    submitStatus: API_CALL_STATUS.IDLE,
    captureDetailsSideDrawer: TOGGLE_STATUS.UNTOUCHED,
    creditCheckConsent: TOGGLE_STATUS.UNTOUCHED,
    form: captureDetailsForm,
}

export default (state = initCaptureDetails, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
    case QUALIFYING_CHECK_CALL_ME_BACK_RETRY:
        return {
            ...state,
            submitStatus: API_CALL_STATUS.IDLE,
            creditCheckConsent: TOGGLE_STATUS.UNTOUCHED,
            formStatus: FORM_STATUS.INVALID,
        };
    case QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_INITIAL:
        return {
            ...state,
            submitStatus: API_CALL_STATUS.IDLE
        };
    case QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_PENDING:
        return {
            ...state,
            submitStatus: API_CALL_STATUS.PENDING
        };
    case QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_SUCCESS:
        return {
            ...state,
            submitStatus: API_CALL_STATUS.SUCCESS
        };
    case QUALIFYING_CHECK_CALL_ME_BACK_SUBMIT_ERROR:
        return {
            ...state,
            submitStatus: API_CALL_STATUS.FAILURE
        };
    case QUALIFYING_CHECK_CALL_ME_BACK_EMPLOYMENT_ERROR:
        return {
            ...state,
            submitStatus: 'EMPLOYMENT_VALIDATION_ERROR'
        };
    case FORM_VALID_TRANSITION_CAPTURE_DETAILS:
        return {
            ...state,
            formStatus: FORM_STATUS.VALID
        };
    case FORM_INVALID_TRANSITION_CAPTURE_DETAILS:
        return {
            ...state,
            formStatus: FORM_STATUS.INVALID
        };
    case TOGGLE_CAPTURE_DETAILS_SIDE_DRAWER:
        return {
            ...state,
            captureDetailsSideDrawer: state.captureDetailsSideDrawer !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO
        };
    case TOGGLE_CREDIT_CHECK_CHECKBOX:
        if (action.value !== undefined && action.value !== null) {
            return {
                ...state,
                creditCheckConsent: action.value
            };
        } else {
            return {
                ...state,
                creditCheckConsent: state.creditCheckConsent !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO
            };
        }
    case CLEAR_FORM:
        return {
            formStatus: FORM_STATUS.INVALID,
            captureDetailsSideDrawer: TOGGLE_STATUS.UNTOUCHED,
            creditCheckConsent: TOGGLE_STATUS.UNTOUCHED,
            submitStatus: API_CALL_STATUS.IDLE,
            form: captureDetailsForm,
        };
    case FIRSTNAME_SET_VALUE:
        return updateFormField('firstname', (field) => ({
            ...field,
            value: action.value
        }));

    case FIRSTNAME_SET_VALID:
        return updateFormField('firstname', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));


    case EMPLOYMENT_TYPE_SET_VALUE:
        return updateFormField('employmentType', (field) => ({
            ...field,
            value: action.value
        }));
    case EMPLOYMENT_TYPE_SET_VALID:
        return updateFormField('employmentType', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));

    case PAYSLIP_AVAILABILITY_SET_VALUE:
        return updateFormField('payslipAvailability', (field) => ({
            ...field,
            value: action.value
        }));
    case PAYSLIP_AVAILABILITY_SET_VALID:
        return updateFormField('payslipAvailability', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));

    case WAGE_FREQUENCY_SET_VALUE:
        return updateFormField('wageFrequency', (field) => ({
            ...field,
            value: action.value
        }));
    case WAGE_FREQUENCY_SET_VALID:
        return updateFormField('wageFrequency', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));

    case SURNAME_SET_VALUE:
        return updateFormField('surname', (field) => ({
            ...field,
            value: action.value
        }));

    case SURNAME_SET_VALID:
        return updateFormField('surname', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));
    case ID_NUMBER_SET_VALUE:
        return updateFormField('idNumber', (field) => ({
            ...field,
            value: action.value
        }));

    case ID_NUMBER_SET_VALID:
        return updateFormField('idNumber', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));
    case MOBILE_NUMBER_SET_VALUE:
        return updateFormField('mobileNumber', (field) => ({
            ...field,
            value: action.value
        }));

    case MOBILE_NUMBER_SET_VALID:
        return updateFormField('mobileNumber', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));

    case QUALIFYING_TOGGLE_SIDE_DRAWER_WEB:
        return {
            ...state,
            source: 'WEB',
            sidebarOpen: action.value
        };

    case QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES:
        return {
            ...state,
            source: 'DIGITALCMB',
            sidebarOpen: action.value
        };

    case RETURN_DATA_FROM_LEADS_QUALIFYING:
        return {
            ...state,
            leadsResponse: action.value
        };
    default:
        return state
    }
}