export const IFRAME_VALID_TRANSITION_TRIGGER = 'customerVerificationService/selfie/transition/iframeValid';

export const triggerIframeValid = () => ({ type: IFRAME_VALID_TRANSITION_TRIGGER });


// General selfie events
export const CVS_SELFIE_SET_VALUE = 'customerVerificationService/setValue/selfie/';
export const CVS_SELFIE_SET_VALID = 'customerVerificationService/setValid/selfie/';

export const IFRAME_LOADED_SET_VALUE = CVS_SELFIE_SET_VALUE + 'iframeLoaded';
export const IFRAME_PAGE_STATE_VALID_VALUE = CVS_SELFIE_SET_VALID + 'iframeLoaded';

export const VERIFICATION_STATUS_SET_VALUE = CVS_SELFIE_SET_VALUE + 'verificationStatus';
export const VERIFICATION_FAILED_COUNT_SET_VALUE = CVS_SELFIE_SET_VALUE + 'verificationFailedCount';

export const OPEN_SOMETHING_LOOKS_WRONG_MODAL_SET_VALUE = CVS_SELFIE_SET_VALUE + 'openSomethingLooksWrongModal';
export const OPEN_CMB_MODAL_SET_VALUE = CVS_SELFIE_SET_VALUE + 'openCmbModal';
export const OPEN_LOADING_MODAL_SET_VALUE = CVS_SELFIE_SET_VALUE + 'openLoadingModal';
export const OPEN_VERIFICATION_MODAL_SET_VALUE = CVS_SELFIE_SET_VALUE + 'openVerificationModal';

export const OPEN_SUCCESSFULLY_VERIFIED_MODAL_SET_VALUE = CVS_SELFIE_SET_VALUE + 'openSuccessfullyVerifiedModal';

export const IIDENTIFII_REQUEST_ID_SET_VALUE = CVS_SELFIE_SET_VALUE + 'iidentifiiRequestID';

export const IFRAME_RESPONSE_DATA_SET_VALUE = CVS_SELFIE_SET_VALUE + 'iframeResponseData';

// Actions for the liveness service call retrieved after succesful verification
export const VALIDATION_PASSED_SET_VALUE = CVS_SELFIE_SET_VALUE + 'validationPassed';
export const SERVICE_PROVIDER_SET_VALUE = CVS_SELFIE_SET_VALUE + 'serviceProvider';
export const IS_VALID_SET_VALUE = CVS_SELFIE_SET_VALUE + 'isValid';
export const MESSAGES_SET_VALUE = CVS_SELFIE_SET_VALUE + 'messages';
export const ERRORS_SET_VALUE = CVS_SELFIE_SET_VALUE + 'errors';

export const setIframeLoaded = value => ({ type: IFRAME_LOADED_SET_VALUE, value });
export const setIframePageStateValid = value => ({ type: IFRAME_PAGE_STATE_VALID_VALUE, value });

export const setVerificationStatus = value => ({ type: VERIFICATION_STATUS_SET_VALUE, value });
export const setVerificationFailedCount = value => ({ type: VERIFICATION_FAILED_COUNT_SET_VALUE, value });

export const setOpenSomethingLooksWrongModal = value => ({ type: OPEN_SOMETHING_LOOKS_WRONG_MODAL_SET_VALUE, value });
export const setOpenLoadingModal = value => ({ type: OPEN_LOADING_MODAL_SET_VALUE, value });
export const setOpenVerificationModal = value => ({ type: OPEN_VERIFICATION_MODAL_SET_VALUE, value });
export const setOpenCmbModal = value => ({ type: OPEN_CMB_MODAL_SET_VALUE, value });

export const setOpenSuccessfullyVerifiedModal = value => ({ type: OPEN_SUCCESSFULLY_VERIFIED_MODAL_SET_VALUE, value });
export const setIidentifiiRequestIdValue = value => ({ type: IIDENTIFII_REQUEST_ID_SET_VALUE, value });

export const setIframeResponseData = value => ({ type: IFRAME_RESPONSE_DATA_SET_VALUE, value });

export const setValidationPassedValue = value => ({ type: VALIDATION_PASSED_SET_VALUE, value });
export const setServiceProviderValue = value => ({ type: SERVICE_PROVIDER_SET_VALUE, value });
export const setIsValidValue = value => ({ type: IS_VALID_SET_VALUE, value });
export const setMessagesValue = value => ({ type: MESSAGES_SET_VALUE, value });
export const setErrorsValue = value => ({ type: ERRORS_SET_VALUE, value });