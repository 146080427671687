import {
	ELIMIKA_QUOTE_TOOL_SET_VALID,
	ELIMIKA_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/elimikaQuoteTool';
import { elimikaQuoteToolFormRules } from './elimikaQuoteTool';
import {
	PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID,
	PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/premierSavingsQuoteTool';
import { premierSavingsQuoteToolFormRules } from './premierSavingsQuoteTool';
import {
	ACCIDENTAL_DISABILITY_TOOL_SET_VALID,
	ACCIDENTAL_DISABILITY_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/accidentalDisabilityQuoteTool';
import { accidentalDisabilityQuoteToolFormRules } from './accidentalDisabilityQuoteTool';

import {
	AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALID,
	AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool.js';
import { afyaImaraGertrudeQuoteToolFormRules } from './afyaImaraGertrudeJuniorQuoteTool';
import {
	ACCIDENTAL_DEATH_COVER_QUOTE_TOOL_SET_VALUE,
	ACCIDENTAL_DEATH_COVER_QUOTE_TOOL_SET_VALID,
} from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool';
import { accidentalQuoteToolFormRules } from './accidentalDeathCoverQuoteTool';
import {
	DEATH_TERM_COVER_QUOTE_TOOL_SET_VALID,
	DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import { deathTermQuoteToolFormRules } from './deathTermCoverQuoteTool';

import {
	AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALID,
	AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/afyaImaraJuniorQuoteTool';
import { afyaImaraJuniorQuoteToolFormRules } from './afyaImaraJuniorQuoteTool';
import {
	WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE,
	WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID,
} from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';
import { wholeLifeCoverQuoteToolFormRules } from './wholeLifeCoverQuoteTool';
import {
	PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALID,
	PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/physicalImpairmentCoverQuoteTool';
import { physicalImpairmentCoverQuoteToolFormRules } from './physicalImpairmentCoverQuoteTool';
import {
    CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID,
    CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/criticalIllnessQuoteTool';
import { criticalIllnessQuoteToolFormRules } from './criticalIllnessQuoteTool';

import {
    AfyaImara_QUOTE_TOOL_SET_VALID,
    AfyaImara_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/afyaImaraQuoteTool';
import { afyaImaraQuoteToolFormRules } from './afyaImaraQuoteTool';

import {
    County_QUOTE_TOOL_SET_VALID,
    County_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/afyaImaraCountyQuoteTool';
import { afyaImaraCountyQuoteToolFormRules } from './afyaImaraCountyQuoteTool';

import {
	MVEST_QUOTE_TOOL_SET_VALID,
	MVEST_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/mvestQuoteTool';

import {
	GROUPLIFE_QUOTE_TOOL_SET_VALID,
	GROUPLIFE_QUOTE_TOOL_SET_VALUE,
} from '../../../actions/roaQuoteTools/groupLifeQuoteTool';

import { mvestQuoteToolFormRules } from './mvestQuoteTool';
import { groupLifeQuoteToolFormRules } from './groupLifeQuoteTool';

import {
	DIGILIFE_QUOTE_TOOL_SET_VALID,
	DIGILIFE_QUOTE_TOOL_SET_VALUE
} from '../../../actions/roaQuoteTools/digiLifeQuoteTool.js';
import { digiLifeQuoteToolFormRules } from './digiLifeQuoteTool.js';

export const roaQuoteToolsRules = (store, next, action) => {
	if (
		action.type.startsWith(ELIMIKA_QUOTE_TOOL_SET_VALUE) ||
		action.type.startsWith(ELIMIKA_QUOTE_TOOL_SET_VALID)
	)
		return elimikaQuoteToolFormRules(store, next, action);

	if (
		action.type.startsWith(PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE) ||
		action.type.startsWith(PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID)
	)
		return premierSavingsQuoteToolFormRules(store, next, action);

	if (
		action.type.startsWith(ACCIDENTAL_DISABILITY_TOOL_SET_VALID) ||
		action.type.startsWith(ACCIDENTAL_DISABILITY_TOOL_SET_VALUE)
	)
		return accidentalDisabilityQuoteToolFormRules(store, next, action);
	if (
		action.type.startsWith(AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE) ||
		action.type.startsWith(AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALID)
	)
		return afyaImaraJuniorQuoteToolFormRules(store, next, action);

	if (
		action.type.startsWith(
			AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE
		) ||
		action.type.startsWith(AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALID)
	)
		return afyaImaraGertrudeQuoteToolFormRules(store, next, action);

	if (
		action.type.startsWith(ACCIDENTAL_DEATH_COVER_QUOTE_TOOL_SET_VALUE) ||
		action.type.startsWith(ACCIDENTAL_DEATH_COVER_QUOTE_TOOL_SET_VALID)
	)
		return accidentalQuoteToolFormRules(store, next, action);
	if (
		action.type.startsWith(
			PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE
		) ||
		action.type.startsWith(PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALID)
	)
		return physicalImpairmentCoverQuoteToolFormRules(store, next, action);

	if (
		action.type.startsWith(WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE) ||
		action.type.startsWith(WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID)
	)
		return wholeLifeCoverQuoteToolFormRules(store, next, action);

	if (
		action.type.startsWith(DEATH_TERM_COVER_QUOTE_TOOL_SET_VALUE) ||
		action.type.startsWith(DEATH_TERM_COVER_QUOTE_TOOL_SET_VALID)
	)
		return deathTermQuoteToolFormRules(store, next, action);

    if (
		action.type.startsWith(AfyaImara_QUOTE_TOOL_SET_VALUE) ||
		action.type.startsWith(AfyaImara_QUOTE_TOOL_SET_VALID)
	)
		return afyaImaraQuoteToolFormRules(store, next, action);

    if (
        action.type.startsWith(CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE) ||
        action.type.startsWith(CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID)
    )
        return criticalIllnessQuoteToolFormRules(store, next, action);

    if (
        action.type.startsWith(County_QUOTE_TOOL_SET_VALID) ||
        action.type.startsWith(County_QUOTE_TOOL_SET_VALUE)
    )
        return afyaImaraCountyQuoteToolFormRules(store, next, action);

	if (
		action.type.startsWith(MVEST_QUOTE_TOOL_SET_VALID) ||
		action.type.startsWith(MVEST_QUOTE_TOOL_SET_VALUE)
	){

		return mvestQuoteToolFormRules(store, next, action);
	}
	
	if (
		action.type.startsWith(GROUPLIFE_QUOTE_TOOL_SET_VALID) ||
		action.type.startsWith(GROUPLIFE_QUOTE_TOOL_SET_VALUE)
	){
		return groupLifeQuoteToolFormRules(store, next, action);
	}

	if (
		action.type.startsWith(DIGILIFE_QUOTE_TOOL_SET_VALID) ||
		action.type.startsWith(DIGILIFE_QUOTE_TOOL_SET_VALUE)
	){
		return digiLifeQuoteToolFormRules(store, next, action);
	}
		
	return null;
};
