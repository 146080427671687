import { createSelector } from 'reselect';
import { getPastPerformanceCalculator } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { ea_to_freq, ls_fv, an_fv, round, } from '@om/tvm-calcs';
import { formatCurrency } from '../../selectors/currency';

const ONCE_OFF_INVESTMENT_TYPE = 'ONCE_OFF';
const SA_INFLATION_FUNDID = 'SA_Inflation';

export const getCalculator = createSelector(
    getPastPerformanceCalculator,
    pastPerformanceCalculator => pastPerformanceCalculator.calculator
);

const makeGetField = (fieldId) => createSelector(
    getPastPerformanceCalculator,
    pastPerformanceCalculator => pastPerformanceCalculator[fieldId],
);

export const getFund = makeGetField('fund');
export const getContributionFrequency = makeGetField('contributionFrequency');
export const getInvestmentAmount = makeGetField('investmentAmount');
export const getInvestmentTerm = makeGetField('investmentTerm');
export const getInvestmentTerms = makeGetField('investmentTerms');
export const getCalculationDate = makeGetField('referenceCalculationDate');
export const getShowResultsCard = makeGetField('showResultsCard');

export const getCalculatorEnabled = createSelector(
	getPastPerformanceCalculator,
	pastPerformanceCalculator => pastPerformanceCalculator.formStatus === FORM_STATUS.VALID
);

export const getFunds = createSelector(
	getPastPerformanceCalculator,
	pastPerformanceCalculator => pastPerformanceCalculator.referenceFundsData? 
        pastPerformanceCalculator.referenceFundsData.map(f => ({value: f.value, label: f.label})) : []
);

const fundPerformance = createSelector(
    getPastPerformanceCalculator,
    getFund,
    getContributionFrequency,
    getInvestmentTerm,
    (pastPerformanceCalculator, selectedFund, investmentType, investmentTerm) => {
        const selectFund = pastPerformanceCalculator.referenceFundsData.filter(fund => fund.value === selectedFund.value);

        if(investmentType === ONCE_OFF_INVESTMENT_TYPE){
            const selectOnceOffFundTerms = selectFund[0].onceOffInvestmentTerms.filter(term => 
                term.value === investmentTerm.value
            )[0];

            return selectOnceOffFundTerms? selectOnceOffFundTerms.fundPerformance : 0;

        } else {
            const selectMonthlyFundTerms = selectFund[0].monthlyInvestmentTerms.filter(term => 
                term.value === investmentTerm.value
            )[0];

            return selectMonthlyFundTerms? selectMonthlyFundTerms.fundPerformance : 0;
        }
    }
);

export const getInvestmentGrowth = createSelector(
    getContributionFrequency,
    getInvestmentAmount,
    getInvestmentTerm,
    fundPerformance,
    (investmentType, amount, term, performance) => {
        if(investmentType === ONCE_OFF_INVESTMENT_TYPE){
            return formatCurrency(
                round(
                    ls_fv( (performance), term.value, amount.value.replace(/\s+/g, '')), 2), 'R');
        } else {
            return formatCurrency(
                round(
                    ls_fv(
                        ea_to_freq(performance, 12), 1, 
                            an_fv(ea_to_freq(performance, 12), term.value*12, amount.value.replace(/\s+/g, ''))), 2), 'R');
        }
    }
);

export const getInflation = createSelector(
    getPastPerformanceCalculator,
    getContributionFrequency,
    getInvestmentTerm,
    (pastPerformanceCalculator, investmentType, investmentTerm) => {
        const inflationKey = `${SA_INFLATION_FUNDID}_${investmentType}_${investmentTerm.value}`;
        const selectInflation = pastPerformanceCalculator.referenceInflationData[inflationKey];     
        return selectInflation? round(selectInflation * 100, 2) : 0;
    }
);

export const getFundPerformance = createSelector(
    fundPerformance,
    (performance) => {        
        return performance? round(performance * 100, 2) : 0;
    }
);
