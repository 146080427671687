import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    FORM_VALID_BUILDING_DETAILS_FORM,
    FORM_INVALID_BUILDING_DETAILS_FORM,
    FINACIAL_INST_INTEREST_SET_VALUE,
    FINACIAL_INST_INTEREST_SET_VALID,
    FINACIAL_INSTITUTION_SET_VALUE,
    FINACIAL_INSTITUTION_SET_VALID,
    STREET_OF_PREMISE_SET_VALUE,
    STREET_OF_PREMISE_SET_VALID,
    TOWN_OF_PREMISE_SET_VALUE,
    TOWN_OF_PREMISE_SET_VALID,
    BUILDING_TYPE_SET_VALUE,
    BUILDING_TYPE_SET_VALID,
    USE_OF_PROPERTY_SET_VALUE,
    USE_OF_PROPERTY_SET_VALID,
    NATURE_OF_ROOF_SET_VALUE,
    NATURE_OF_ROOF_SET_VALID,
    NATURE_OF_WALL_SET_VALUE,
    NATURE_OF_WALL_SET_VALID,
    LOCATION_SET_VALUE,
    LOCATION_SET_VALID,
    LR_NUMBER_SET_VALUE,
    LR_NUMBER_SET_VALID,
    SECURITY_SET_VALUE,
    SECURITY_SET_VALID,
    PERIMETER_WALL_SET_VALUE,
    PERIMETER_WALL_SET_VALID,
    LAND_SCAPING_SET_VALUE,
    LAND_SCAPING_SET_VALID,
    SWIMMING_POOL_SET_VALUE,
    SWIMMING_POOL_SET_VALID,
    NO_OF_FLOORS_SET_VALUE,
    NO_OF_FLOORS_SET_VALID
} from '../../actions/domesticInsurance/buildingDetails';


export const initBuildingDetails = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    buildingDetailsReferenceData: null,
    apiCallStatus: null,
    form: {
        hasFinacialInterest: { value: null, error: null, status: null },
        finacialInstitution: { value: null, error: null, status: null },
        streetOfPremise: { value: null, error: null, status: null },
        townOfPremise: { value: null, error: null, status: null },
        buildingType: { value: null, error: null, status: null },
        useOfProperty: { value: null, error: null, status: null },
        roofNature: { value: null, error: null, status: null },
        wallNature: { value: null, error: null, status: null },
        location: { value: null, error: null, status: null },
        lrNumber: { value: null, error: null, status: null },
        security: { value: null, error: null, status: null },
        perimeterWall: { value: null, error: null, status: null },
        landScaping: { value: null, error: null, status: null },
        swimmingPool: { value: null, error: null, status: null },
        noOfFloors: { value: null, error: null, status: null },
    },
};

export default (state = initBuildingDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_BUILDING_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_BUILDING_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case FINACIAL_INST_INTEREST_SET_VALUE:
            return updateFormField('hasFinacialInterest', (field) => ({
                ...field,
                value: action.value,
            }));

        case FINACIAL_INST_INTEREST_SET_VALID:
            return updateFormField('hasFinacialInterest', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FINACIAL_INSTITUTION_SET_VALUE:
            return updateFormField('finacialInstitution', (field) => ({
                ...field,
                value: action.value,
            }));
        case FINACIAL_INSTITUTION_SET_VALID:
            return updateFormField('finacialInstitution', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case STREET_OF_PREMISE_SET_VALUE:
            return updateFormField('streetOfPremise', (field) => ({ ...field, value: action.value }));

        case STREET_OF_PREMISE_SET_VALID:
            return updateFormField('streetOfPremise', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TOWN_OF_PREMISE_SET_VALUE:
            return updateFormField('townOfPremise', (field) => ({
                ...field,
                value: action.value,
            }));

        case TOWN_OF_PREMISE_SET_VALID:
            return updateFormField('townOfPremise', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUILDING_TYPE_SET_VALUE:
            return updateFormField('buildingType', (field) => ({
                ...field,
                value: action.value,
            }));

        case BUILDING_TYPE_SET_VALID:
            return updateFormField('buildingType', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case USE_OF_PROPERTY_SET_VALUE:
            return updateFormField('useOfProperty', (field) => ({
                ...field,
                value: action.value,
            }));

        case USE_OF_PROPERTY_SET_VALID:
            return updateFormField('useOfProperty', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case NATURE_OF_ROOF_SET_VALUE:
            return updateFormField('roofNature', (field) => ({
                ...field,
                value: action.value,
            }));

        case NATURE_OF_ROOF_SET_VALID:
            return updateFormField('roofNature', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NATURE_OF_WALL_SET_VALUE:
            return updateFormField('wallNature', (field) => ({
                ...field,
                value: action.value,
            }));

        case NATURE_OF_WALL_SET_VALID:
            return updateFormField('wallNature', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LOCATION_SET_VALUE:
            return updateFormField('location', (field) => ({
                ...field,
                value: action.value,
            }));

        case LOCATION_SET_VALID:
            return updateFormField('location', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LR_NUMBER_SET_VALUE:
            return updateFormField('lrNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case LR_NUMBER_SET_VALID:
            return updateFormField('lrNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SECURITY_SET_VALUE:
            return updateFormField('security', (field) => ({
                ...field,
                value: action.value,
            }));

        case SECURITY_SET_VALID:
            return updateFormField('security', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PERIMETER_WALL_SET_VALUE:
            return updateFormField('perimeterWall', (field) => ({
                ...field,
                value: action.value,
            }));

        case PERIMETER_WALL_SET_VALID:
            return updateFormField('perimeterWall', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LAND_SCAPING_SET_VALUE:
            return updateFormField('landScaping', (field) => ({
                ...field,
                value: action.value,
            }));

        case LAND_SCAPING_SET_VALID:
            return updateFormField('landScaping', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SWIMMING_POOL_SET_VALUE:
            return updateFormField('swimmingPool', (field) => ({
                ...field,
                value: action.value,
            }));

        case SWIMMING_POOL_SET_VALID:
            return updateFormField('swimmingPool', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NO_OF_FLOORS_SET_VALUE:
            return updateFormField('noOfFloors', (field) => ({
                ...field,
                value: action.value,
            }));

        case NO_OF_FLOORS_SET_VALID:
            return updateFormField('noOfFloors', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // //    API Calls
        // case API_PENDING_TRANSITION_CAR_DETAILS_FORM:
        //     return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        // case API_SUCCESS_TRANSITION_CAR_DETAILS_FORM:
        //     return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        // case API_FAILURE_TRANSITION_CAR_DETAILS_FORM:
        //     return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // // Submit user data
        // case COLLECT_CAR_DETAILS_FORM_DATA:
        //     return { ...state, formStatus: FORM_STATUS.VALID };
        // case SUBMIT_CAR_DETAILS_SUCCESS:
        //     return { ...state, personalDetailsReferenceData: action.value };
        // case SUBMIT_CAR_DETAILS_FAILURE:
        //     return { ...state, personalDetailsReferenceData: action.error };

        // Reset the state
        case REINITIALIZE:
            return initBuildingDetails;
        default:
            return state;
    }
};
