import { validationIsEqual } from '../status';
import {
    validateDate,
    validateAlphaString,
    validateCellphoneNumber,
    validateEmail,
    validateDateOfBirth,
} from '@om/validation';
import {
    FULL_NAME_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    DOB_SET_VALUE,
    DEPARTURE_DATE_SET_VALUE,
    RETURN_DATE_SET_VALUE,
    IDENTIFICATION_DOCUMENT_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    PAYMENTS_PHONE_NUMBER_SET_VALUE,
    setFullNameValid,
    setPhoneNumberValid,
    setEmailValid,
    setDobValid,
    setDepartureDateValid,
    setReturnDateValid,
    setIdNumberValid,
    setIdDocumentValid,
    setPaymentsPhoneNumberValid
} from '../../../actions/roaTravelInsurance/addTraveller';

import {
    getDepartureDateValue,
    getReturnDateValue,
} from '../../../selectors/roaTravelInsurance/payments';


let prevValidationAction = null;

export const addTravellerDetails = (store, next, action) => {
    const state = store.getState();
    const validationAction = (() => {
        let error;
        let tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        let tomorrowReadable = tomorrow
            .toLocaleDateString('en-KE')
            .split('/')
            .join('/');

        let departureDate = getDepartureDateValue(state);
        let returnDate = getReturnDateValue(state);
        switch (action.type) {
            case FULL_NAME_SET_VALUE:
                error = validateAlphaString(action.value, {
                    subject: 'Full Name',
                });
                return setFullNameValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(action.value, {
                    subject: 'Phone Number',
                });
                return setPhoneNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    subject: 'Email',
                });
                return setEmailValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 15, max: 85 });
                return setDobValid(error);

            case PAYMENTS_PHONE_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(action.value, {
                    subject: 'Phone Number',
                });
                return setPaymentsPhoneNumberValid(error);

            case IDENTIFICATION_DOCUMENT_SET_VALUE:
                error = validateAlphaString(action.value, {
                    subject: 'number of kids',
                });
                return setIdDocumentValid(error);

            case RETURN_DATE_SET_VALUE:
                error = validateDate(action.value, {
                    min: departureDate.value,
                });
                if (departureDate.error) {
                    const secondError = validateDate(action.value, {
                        min: departureDate.value,
                        max: action.value,
                    });
                    store.dispatch(setDepartureDateValid(secondError));
                }
                return setReturnDateValid(error);
            case DEPARTURE_DATE_SET_VALUE:
                error = validateDate(action.value, {
                    min: tomorrowReadable,
                    max: returnDate.value,
                });
                if (returnDate.error) {
                    const secondError = validateDate(action.value, {
                        min: action.value,
                        max: returnDate.value,
                    });
                    store.dispatch(setReturnDateValid(secondError));
                }
                return setDepartureDateValid(error);

            case ID_NUMBER_SET_VALUE:
                error = validateAlphaString(action.value, {
                    min: 8,
                    max: 8,
                    subject: 'Identification Number',
                });
                return setIdNumberValid(error);

            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
