import {
    NET_MONTHLY_INCOME_SET_VALUE,
    NET_MONTHLY_INCOME_SET_VALID
} from "../../../actions/budgetBenchmarkCalculator/calculator";
import { budgetBenchmarkRulesEngine } from "./calculator";

export const budgetBenchmarkRules = (store, next, action) => {
    if (action.type.startsWith(NET_MONTHLY_INCOME_SET_VALUE) ||
        action.type.startsWith(NET_MONTHLY_INCOME_SET_VALID)) {
        return budgetBenchmarkRulesEngine(store, next, action);
    }
    return null;
};
