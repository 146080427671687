export const RETIREMENT_CALCULATOR_FORM_TRANSITION = 'retirementCalculator/retirementCalculatorForm/transition/'

export const FORM_VALID_RETIREMENT_CALCULATOR_FORM_TRANSITION = 'retirementCalculator/retirementCalculatorForm/formValid';
export const FORM_INVALID_RETIREMENT_CALCULATOR_FORM_TRANSITION = 'retirementCalculator/retirementCalculatorForm/formInvalid';

export const RETIREMENT_CALCULATOR_FORM_SET_VALUE = 'retirementCalculator/retirementCalculatorForm/setValue/';
export const RETIREMENT_CALCULATOR_FORM_SET_VALID = 'retirementCalculator/retirementCalculatorForm/setValid/';

export const transitionRetirementCalculatorForm = {
    formValid: () => ({ type: FORM_VALID_RETIREMENT_CALCULATOR_FORM_TRANSITION }),
    formInvalid: () => ({ type: FORM_INVALID_RETIREMENT_CALCULATOR_FORM_TRANSITION }),
};

export const TOGGLE_SHOW_PRODUCT_OFFERING = RETIREMENT_CALCULATOR_FORM_SET_VALUE + 'toggle/showProductOffering';
export const toggleViewRecommendation = () => ({ type: TOGGLE_SHOW_PRODUCT_OFFERING });

export const RESET_CALCULATOR_FORM = 'retirementCalculator/retirementCalculatorForm/reset/form';
export const resetCalculatorForm = () => ({ type: RESET_CALCULATOR_FORM });

export const REINITIALIZE_STATE_RETIREMENT_CALCULATOR_FORM = 'retirementCalculator/retirementCalculatorForm/reinitialize';

export const AGE_SET_VALUE = RETIREMENT_CALCULATOR_FORM_SET_VALUE + 'age';
export const AGE_SET_VALID = RETIREMENT_CALCULATOR_FORM_SET_VALID + 'age';

export const MONTHLY_INCOME_SET_VALUE = RETIREMENT_CALCULATOR_FORM_SET_VALUE + 'monthlyIncome';
export const MONTHLY_INCOME_SET_VALID = RETIREMENT_CALCULATOR_FORM_SET_VALID + 'monthlyIncome';

export const CONTRIBUTION_AMOUNT_SET_VALUE = RETIREMENT_CALCULATOR_FORM_SET_VALUE + 'contributionAmount';
export const CONTRIBUTION_AMOUNT_SET_VALID = RETIREMENT_CALCULATOR_FORM_SET_VALID + 'contributionAmount';

export const AMOUNT_SAVED_SET_VALUE = RETIREMENT_CALCULATOR_FORM_SET_VALUE + 'amountSaved'
export const AMOUNT_SAVED_SET_VALID = RETIREMENT_CALCULATOR_FORM_SET_VALID + 'amountSaved'

export const PRODUCT_RECOMMENDATION_SET_VALUE =  RETIREMENT_CALCULATOR_FORM_SET_VALUE + 'productRecommendation';
export const RESULTS_VIEW_VALUE = RETIREMENT_CALCULATOR_FORM_SET_VALUE + 'viewResults';

export const RETIREMENT_CALCULATOR_SHOW_RESULT_SUMMARY = `${RETIREMENT_CALCULATOR_FORM_SET_VALUE}/showResultsSummary`;
export const setShowResultSummary = () => ({ type: RETIREMENT_CALCULATOR_SHOW_RESULT_SUMMARY });

export const RETIREMENT_CALCULATOR_OPEN_MODAL = `${RETIREMENT_CALCULATOR_FORM_SET_VALUE}/openModal`;
export const setOpenModel = () => ({ type: RETIREMENT_CALCULATOR_OPEN_MODAL});

export const RETIREMENT_CALCULATOR_CLOSE_MODAL = `${RETIREMENT_CALCULATOR_FORM_SET_VALUE}/closeModal`;
export const setCloseModal = () => ({ type: RETIREMENT_CALCULATOR_CLOSE_MODAL });

export const ESTIMATED_RETIREMENT_INCOME_SET_VALUE = `${RETIREMENT_CALCULATOR_FORM_SET_VALUE}/estimatedRetirementIncome`;
export const setEstimatedRetirementIncome = value => ({ type: ESTIMATED_RETIREMENT_INCOME_SET_VALUE, value });
export const REQUIRED_INCOME_SET_VALUE = `${RETIREMENT_CALCULATOR_FORM_SET_VALUE}/requiredIncome`;
export const setRequiredIncome = value => ({ type: REQUIRED_INCOME_SET_VALUE, value });

export const setAgeValue = value => ({ type: AGE_SET_VALUE, value });
export const setAgeValid = error => ({ type: AGE_SET_VALID, error });

export const setMonthlyIncomeValue = value => ({ type: MONTHLY_INCOME_SET_VALUE, value });
export const setMonthlyIncomeValid = error => ({ type: MONTHLY_INCOME_SET_VALID, error });

export const setContributionAmountValue = value => ({ type: CONTRIBUTION_AMOUNT_SET_VALUE, value });
export const setContributionAmountValid = error => ({ type: CONTRIBUTION_AMOUNT_SET_VALID, error });

export const setAmountSavedValue = value => ({ type: AMOUNT_SAVED_SET_VALUE, value });
export const setAmountSavedValid = error => ({ type: AMOUNT_SAVED_SET_VALID, error });

export const setViewRecommendation = () => ({ type: PRODUCT_RECOMMENDATION_SET_VALUE });
export const reInitialize = () => ({ type: REINITIALIZE_STATE_RETIREMENT_CALCULATOR_FORM });
