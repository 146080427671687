//Transitions
export const FORM_VALID_TRANSITION_CONFIRM_DETAILS = 'digiLifeLoanProtection/confirmDetails/transition/formValid';
export const FORM_INVALID_TRANSITION_CONFIRM_DETAILS = 'digiLifeLoanProtection/confirmDetails/transition/formInvalid';

export const TOGGLE_DECLARATIONS_CONFIRM_DETAILS = 'digiLifeLoanProtection/confirmDetails/transition/toggleDeclarations';
export const TOGGLE_DECLARATIONS_CONFIRM_DETAILS_VALIDATE = 'digiLifeLoanProtection/confirmDetails/transition/toggleDeclarations/validate';

export const toggleDeclarations = (value) => ({ type: TOGGLE_DECLARATIONS_CONFIRM_DETAILS ,value});
export const validateDeclarations = () => ({ type: TOGGLE_DECLARATIONS_CONFIRM_DETAILS_VALIDATE });
export const setDeclarationsInvalid = error => ({ type: TOGGLE_DECLARATIONS_CONFIRM_DETAILS, error });
export const resetDeclaration = () => ({ type: RESET_VALUE_DECLARATION });

export const transitionConfirmDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CONFIRM_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CONFIRM_DETAILS })
};

// Transition - reset state
export const RESET_STATE_TRANSITION_CONFIRM_DETAILS = 'digiLifeLoanProtection/confirmDetails/transition/resetState';
export const resetConfirmDetails = () => ({ type: RESET_STATE_TRANSITION_CONFIRM_DETAILS });

export const CONFIRM_DETAILS_SET_VALUE = 'digiLifeLoanProtection/confirmDetails/setValue/';
export const CONFIRM_DETAILS_SET_VALID = 'digiLifeLoanProtection/confirmDetails/setValid/';

export const RESET_VALUE_DECLARATION = CONFIRM_DETAILS_SET_VALUE + 'declarations';


// Transitions - submission
export const DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION = 'digiLifeLoanProtection/confirmDetails/submitApplication/';

export const DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND = DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION + 'send';
export const DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_API_STATUS = DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION + 'setApiStatus';
export const DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_REFERENCE_ID = DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION + 'referenceId';

export const sendConfirmDetailsSubmitApplication = () => ({ type: DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND });
export const sendConfirmDetailsSubmitApplicationApiStatus = value => ({ type: DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_API_STATUS, value });
export const sendConfirmDetailsSubmitApplicationReferenceId = value => ({ type: DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_REFERENCE_ID, value });

