import { createSelector } from 'reselect';
import { getRoaCarInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getCarAgent = createSelector(
	getRoaCarInsuranceState,
	state => state.agentReferral,
);

const makeGetCarAgentField = fieldName =>
	createSelector(getCarAgent, state => state[fieldName]);

// get display modal
export const getDisplayForm = makeGetCarAgentField('displayModal');

// get form
export const getCarAgentForm = makeGetCarAgentField('form');

export const getCarAgentFormValid = createSelector(
	getCarAgent,
	state => state.formStatus === FORM_STATUS.VALID,
);

// get form fields
const makeGetFieldForm = fieldId =>
	createSelector(getCarAgentForm, form => form[fieldId]);

export const getNameValue = makeGetFieldForm('name');
export const getAgentPhoneNumberValue = makeGetFieldForm('agentPhoneNumber');
