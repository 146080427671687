export const OPEN_DRAWER = {
    BUDGET_TOOL: 'budgetTool',
    USE_OF_PERSONAL_INFORMATION: 'useOfPersonalInformation',
    SPEAK_TO_AN_ADVISER: 'speakToAnAdviser',
    GUARANTEED_DRAWER: 'guaranteedDrawer',
    LIVING_DRAWER: 'livingDrawer',
    COMPOSITE_DRAWER: 'compositeDrawer'
};

export const SOLUTIONS = {
    GUARANTEED_ANNUITY: 'GUARANTEED_ANNUITY',
    LIVING_ANNUITY: 'LIVING_ANNUITY',
    COMPOSITE_ANNUITY:'COMPOSITE_ANNUITY',
}

export const RETIREMENT_FOR = {
    MYSELF: 'MYSELF',
    MYSELF_AND_PARTNER: 'MYSELF_AND_PARTNER'
}

export const CONTACT_INTERESTS = {
    INCOME_OR_PRODUCTS: 'INCOME_OR_PRODUCTS',
    OTHER: 'OTHER',
    SOMETHING_ELSE: 'SOMETHING_ELSE',
}

export const RETIREMENT_WORRIES = {
    INCOME_FOR_REST_OF_LIFE: 'INCOME_FOR_REST_OF_LIFE',
    INHERITANCE_FOR_LOVED_ONES: 'INHERITANCE_FOR_LOVED_ONES',
    BOTH: 'BOTH',
}

export const GENDER = {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
}
