import {INTRO_SET_VALUE, INTRO_RENDER_TRACKING_VALUE} from "../../../actions/atRetirement/intro";
import {introTracking} from "./intro";
import {ABOUT_YOU_SET_VALUE, TOGGLE_BUDGET_TOOL, ABOUT_YOU_RENDER_TRACKING_VALUE} from "../../../actions/atRetirement/aboutYou";
import {TOGGLE_SPEAK_TO_AN_ADVISER} from "../../../actions/atRetirement/speakToAnAdviser";
import { FINANCIAL_ADVICE_RENDER_TRACKING_VALUE } from "../../../actions/atRetirement/financialAdvice";
import {aboutYouFieldTracking, aboutYouTransitionTracking} from "./aboutYou";
import {
    SOLUTIONS_SET_VALUE,
    GUARANTEED_DRAWER_OPEN_SOLUTIONS,
    LIVING_DRAWER_OPEN_SOLUTIONS,
    COMPOSITE_DRAWER_OPEN_SOLUTIONS,
    SOLUTIONS_PAGE_RENDER_TRACKING_VALUE,
} from "../../../actions/atRetirement/solutions";
import {solutionsFieldTracking, solutionsTransitionTracking} from "./solutions";
import {FIN_ADVICE_SEND, FIN_ADVICE_SET_VALUE} from "../../../actions/atRetirement/financialAdvice";
import {financialAdviceFieldTracking} from "./financialAdvice";
import {STEP_INDEX_SET_VALUE} from "../../../actions/atRetirement/wizardFooter";
import {arcPageTransitionTracking, arcRenderTransitionTracking} from "./arcPageTransitionTracking";

export const atRetirementTracking = (store, next, action) => {
    /**
     * At Retirement Calculator
     */
    if (action.type.startsWith(INTRO_SET_VALUE))
        return introTracking(store, next, action);
    if (action.type.startsWith(ABOUT_YOU_SET_VALUE))
        return aboutYouFieldTracking(store, next, action);
    if (action.type.startsWith(SOLUTIONS_SET_VALUE))
        return solutionsFieldTracking(store, next, action);

    if (action.type.startsWith(FIN_ADVICE_SEND) ||
        action.type.startsWith(FIN_ADVICE_SET_VALUE))
        return financialAdviceFieldTracking(store, next, action);

    // ARC transitions
    if (action.type === TOGGLE_BUDGET_TOOL ||
        action.type === TOGGLE_SPEAK_TO_AN_ADVISER)
        return aboutYouTransitionTracking(store, next, action);

    if (action.type === GUARANTEED_DRAWER_OPEN_SOLUTIONS ||
        action.type === LIVING_DRAWER_OPEN_SOLUTIONS ||
        action.type === COMPOSITE_DRAWER_OPEN_SOLUTIONS)
        return solutionsTransitionTracking(store, next, action);

    if (action.type === STEP_INDEX_SET_VALUE)
        return arcPageTransitionTracking(store, next, action);

    //ARC Render actions for tracking
    if (action.type === ABOUT_YOU_RENDER_TRACKING_VALUE ||
        action.type === INTRO_RENDER_TRACKING_VALUE ||
        action.type === FINANCIAL_ADVICE_RENDER_TRACKING_VALUE ||
        action.type === SOLUTIONS_PAGE_RENDER_TRACKING_VALUE){
        return arcRenderTransitionTracking(action);
    }


    return null;
};