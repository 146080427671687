import { transitionAfyaImaraTool } from '../../../actions/roaQuoteTools/afyaImaraQuoteTool';
import {
	getAfyaImaraQuoteToolForm,
	getSelectedForm,
} from '../../../selectors/roaQuoteTools/afyaImaraQuoteTool';
import { FIELD_STATUS } from '../../../reducers/status';

export const afyaImaraQuoteToolFormRules = (store, next, action) => {
	const result = next(action);

	const afyaImaraQuoteToolForm = getAfyaImaraQuoteToolForm(store.getState());
	const selectedForm = getSelectedForm(store.getState());
	const afyaImaraQuoteToolValid = validateAfyaImaraQuoteToolFormDetails(
		afyaImaraQuoteToolForm,
		selectedForm
	);
	const formAction = afyaImaraQuoteToolValid
		? transitionAfyaImaraTool.formValid()
		: transitionAfyaImaraTool.formInvalid();

	store.dispatch(formAction);

	return result;
};

const validateAfyaImaraQuoteToolFormDetails = (formFields, selectedForm) => {
	const { child_18, child_19_29, child_30_40, child_40_50 } = formFields;
	if (selectedForm === 'form-one') {
		return (
			child_18 && child_18.status === FIELD_STATUS.VALID &&
			child_19_29 && child_19_29.status === FIELD_STATUS.VALID &&
			child_30_40 && child_30_40.status === FIELD_STATUS.VALID &&
			child_40_50 && child_40_50.status === FIELD_STATUS.VALID
		);
	}
	return true;
};
