const REVIEW_TRANSITION = 'taxFreeSavingsAccount/review/transition/';

export const TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW = REVIEW_TRANSITION + 'toggleTermsAndConditions';
export const TERMS_AND_CONDITIONS_RESET_TRANSITION_REVIEW = REVIEW_TRANSITION + 'termsAndConditions';
export const toggleTermsAndConditions = () => ({ type: TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW });
export const resetTermsAndConditions = () => ({ type: TERMS_AND_CONDITIONS_RESET_TRANSITION_REVIEW });
export const setTermsAndConditionsInvalid = error => ({ type: TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW, error });

// Transitions - submission
export const REVIEW_SUBMIT_APPLICATION = 'taxFreeSavingsAccount/review/submitApplication/';

export const APPLICATION_SUBMISSION_TRANSITION_REVIEW = REVIEW_SUBMIT_APPLICATION + 'send';
export const APPLICATION_SUBMISSION_FAILURE_TRANSITION_REVIEW = REVIEW_SUBMIT_APPLICATION + 'failure';
export const ACCOUNT_VERIFICATION_FAILURE_TRANSITION_REVIEW = REVIEW_SUBMIT_APPLICATION + '/verification/failure';
export const APPLICATION_SUBMISSION_SUCCESS_TRANSITION_REVIEW = REVIEW_SUBMIT_APPLICATION + 'success';
export const submitApplication = () => ({ type: APPLICATION_SUBMISSION_TRANSITION_REVIEW });
export const setApplicationSubmissionFailure = () => ({ type: APPLICATION_SUBMISSION_FAILURE_TRANSITION_REVIEW });
export const setAccountVerificationFailure = () => ({ type: ACCOUNT_VERIFICATION_FAILURE_TRANSITION_REVIEW });
export const setApplicationSubmissionSuccess = value => ({ type: APPLICATION_SUBMISSION_SUCCESS_TRANSITION_REVIEW, value });

// Events
export const REVIEW_SET_VALUE = 'taxFreeSavingsAccount/review/setValue/';
export const REVIEW_SET_VALID = 'taxFreeSavingsAccount/review/setValid/';

export const TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE = REVIEW_SET_VALUE + 'termsAndConditionsSideDrawer';
export const TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALID = REVIEW_SET_VALID + 'termsAndConditionsSideDrawer';
export const SIDE_DRAWER_CLOSED_SET_VALUE = REVIEW_SET_VALUE + 'closeSideDrawer';
export const setTermsAndConditionsDrawer = value => ({ type: TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE, value });
export const setTermsAndConditionsDrawerValidationMessage = error => ({ type: TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALID, error });
export const setSideDrawersClosed = () => ({ type: SIDE_DRAWER_CLOSED_SET_VALUE });

export const RESET_REVIEW = REVIEW_SET_VALUE + 'resetState';
export const resetReview = () => ({ type: RESET_REVIEW });