import {  validateSingleSelection, validateContactNumber} from '@om/validation';
import { setPhoneNumberValid, PHONE_NUMBER_SET_VALUE, setTermsValid, TERMS_SET_VALUE, } from '../../../actions/motorPrivateRW/payments';
import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const paymentsFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case PHONE_NUMBER_SET_VALUE:
                
                error = validateContactNumber(action.value, {
                    subject: 'Payments Phone Number',
                    countryCode: 'rw',
                });
                return setPhoneNumberValid(error);

            case TERMS_SET_VALUE:
                error = validateSingleSelection(action.value, 'Terms');
                return setTermsValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
