import { routingRules } from "./routing";
import { ALLSURE_ROUTING_SET_VALUE } from "../../../actions/allsureInsurance/routing";


export const allsureInsuranceRules = (store, next, action) => {
  //Personal Details
//   if (action.type.startsWith(PERSONAL_DETAILS_SET_VALID) ){
//         return personalDetailsRules(store, next, action);}
//     // Upload Documents rules
//     if (action.type.startsWith(UPLOAD_DOCUMENTS_SET_VALUE))
//       return uploadDocumentsRules(store, next, action);
//     // Payment detail rules
//     if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE))
//       return paymentDetailsRules(store, next, action);
//     // Trigger routing transitions
   if (action.type.startsWith(ALLSURE_ROUTING_SET_VALUE))
        return routingRules(store, next, action);

  return null;
};
