import {
    PERSONAL_DETAILS_SET_VALUE,
    PERSONAL_DETAILS_SET_VALID,
    FORM_INVALID_TRANSITION_PERSONAL_DETAILS,
    FORM_VALID_TRANSITION_PERSONAL_DETAILS,
    PERSONAL_DETAILS_EDIT,
} from '../../actions/allsureInsurance/personalDetails';
import { FORM_INVALID_TRANSITION_PURCHASE_PERSONAL_DETAILS, FORM_VALID_TRANSITION_PURCHASE_PERSONAL_DETAILS, PURCHASE_PERSONAL_DETAILS_EDIT, PURCHASE_PERSONAL_DETAILS_SET_VALID, PURCHASE_PERSONAL_DETAILS_SET_VALUE } from '../../actions/allsureInsurance/purchasePersonalDetails';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initCountry = 'Botswana';
export const initPurchasePersonalDetails = {
    formStatus: FORM_STATUS.INVALID,
    isPurchasePersonalDetailsEdit:false,
    form: {
        title: { value: null, error: null, status: null },
        fullname: { value: null, error: null, status: null },
        
        nationality: { value: initCountry, error: null, status: FIELD_STATUS.VALID },
        //id or passport
        id: { value: null, error: null, status: null },
        // passportId: { value: null, error: null, status: null },

        dateOfBirth:{ value: null, error: null, status: null },
        placeOfBirth:{ value: null, error: null, status: null },

        //Residential Address
        streetAddress: { value: null, error: null, status: null },
        city: { value: null, error: null, status: null },
        country: { value: initCountry, error: null, status: FIELD_STATUS.VALID },

        residenceTimePeriod: { value: null, error: null, status: null  },

        //Previous Residential Address
        previousPostalAddress: { value: null, error: null, status: null  },
        previousPostalCity:{ value: null, error: null, status: null  },
        previousPostalCountry:{ value: null, error: null, status: null  },

        //Postal Address
        postalSuburb: { value: null, error: null, status: null },
        postalCity: { value: null, error: null, status: null },
        postalCountry: { value: initCountry, error: null, status: FIELD_STATUS.VALID },

        //Empoyment Details
        employerName: { value: null, error: null, status: null },
        workAddress: { value: null, error: null, status: null },

        //Contact Details
        countryCodePurchase: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
        mobileNumber: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        countryCodeWork: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
        telephoneWork: { value: null, error: null, status: FIELD_STATUS.VALID },
    },
};
export default (state = initPurchasePersonalDetails, action) => {
    const trimField = (value) => (value ? value.trim() : value);
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    // if ( CLAIMENT_DETAILS_SET_VALUE) {
    switch (action.type) {
        case FORM_VALID_TRANSITION_PURCHASE_PERSONAL_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_PURCHASE_PERSONAL_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case PURCHASE_PERSONAL_DETAILS_SET_VALUE:
            if(action.value.fieldType==='nationality'){
                return {
                    ...state,
                    form:{
                        ...state.form,
                        nationality:{...state.form.nationality,value:action.value.value},
                        id: {value: null, error: null, status: null},
                    }
                }
            }
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue:action.value.displayValue??action.value.value ,
                value: action.value.value ,
            }));

        case PURCHASE_PERSONAL_DETAILS_SET_VALID:
            const { fieldType, error } = action;
            return updateFormField(fieldType, (field) => ({
                ...field,
                error: error,
                status: mapError(error),
            }));
        case PURCHASE_PERSONAL_DETAILS_EDIT:
            return {...state, isPurchasePersonalDetailsEdit: action.value }
        default:
            return state;
        // }
    }
};
