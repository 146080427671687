
// Transitions
export const FORM_VALID_TRANSITION_PAYMENT_DETAILS = 'easiplusFuneralPlan/paymentDetails/transition/formValid';
export const FORM_INVALID_TRANSITION_PAYMENT_DETAILS = 'easiplusFuneralPlan/paymentDetails/transition/formInvalid';

export const PAYMENT_DETAILS_TRANSITION = 'easiplusFuneralPlan/paymentDetails/transition/';

export const TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS = PAYMENT_DETAILS_TRANSITION + 'togglePaymentAuthorization';
export const PAYMENT_DETAILS_FORM_SET_VALUE = PAYMENT_DETAILS_TRANSITION + 'formValue';
export const INVALID_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS = PAYMENT_DETAILS_TRANSITION + 'invalid';

export const TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS = 'easiplusFuneralPlan/paymentDetails/transition/toggleIncludeAvs';
export const toggleIncludeAvs = () => ({ type: TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS });

export const TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS = 'easiplusFuneralPlan/paymentDetails/transition/toggleIncludeCdv';
export const toggleIncludeCdv = () => ({ type: TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS });

export const TRIGGER_ACCOUNT_VALIDATION_FROM_CHECKBOX_PAYMENT_DETAILS = 'easiplusFuneralPlan/paymentDetails/transition/triggerAccountValidationFromCheckbox';
export const triggerAccountValidation = () => ({ type: TRIGGER_ACCOUNT_VALIDATION_FROM_CHECKBOX_PAYMENT_DETAILS });

export const togglePaymentAuthorization = () => ({ type: TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS });
export const setPaymentAuthorizationInvalid = value => ({ type: INVALID_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS, value });
export const setPaymentDetailsFormValues = () => ({ type: PAYMENT_DETAILS_FORM_SET_VALUE });

export const transitionPaymentDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_PAYMENT_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_PAYMENT_DETAILS })
};

// Transition - reset state
export const RESET_STATE_TRANSITION_PAYMENT_DETAILS = 'easiplusFuneralPlan/livesCovered/transition/paymentDetails';
export const resetPaymentDetails = () => ({ type: RESET_STATE_TRANSITION_PAYMENT_DETAILS });

// Events
export const PAYMENT_DETAILS_SET_VALUE = 'easiplusFuneralPlan/paymentDetails/setValue/';
export const PAYMENT_DETAILS_SET_VALID = 'easiplusFuneralPlan/paymentDetails/setValid/';

// Payment details
export const BANK_NAME_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'bankName';
export const BANK_NAME_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'bankName';
export const ACCOUNT_NUMBER_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountNumber';
export const ACCOUNT_NUMBER_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'accountNumber';
export const ACCOUNT_TYPE_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountType';
export const ACCOUNT_TYPE_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'accountType';
export const DEBIT_ORDER_DAY_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'debitOrderDay';
export const DEBIT_ORDER_DAY_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'debitOrderDay';
export const PAYMENT_AUTHORIZATION_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'paymentAuthorization';

//Account validation
export const ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS=  PAYMENT_DETAILS_SET_VALUE + 'accountValidation/status';
export const setAccountValidationStatus = value => ({ type: ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS, value });

export const ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS = PAYMENT_DETAILS_SET_VALUE + 'accountValidation/reset';
export const setAccountValidationReset = () => ({ type: ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS });

// Payment details
export const setBankNameValue = value => ({ type: BANK_NAME_SET_VALUE, value });
export const setBankNameValid = error => ({ type: BANK_NAME_SET_VALID, error });
export const setAccountNumberValue = value => ({ type: ACCOUNT_NUMBER_SET_VALUE, value });
export const setAccountNumberValid = error => ({ type: ACCOUNT_NUMBER_SET_VALID, error });
export const setAccountTypeValue = value => ({ type: ACCOUNT_TYPE_SET_VALUE, value });
export const setAccountTypeValid = error => ({ type: ACCOUNT_TYPE_SET_VALID, error });
export const setDebitOrderDayValue = value => ({ type: DEBIT_ORDER_DAY_SET_VALUE, value });
export const setDebitOrderDayValid = error => ({ type: DEBIT_ORDER_DAY_SET_VALID, error });
export const setPaymentAuthorizationValid = error => ({ type: PAYMENT_AUTHORIZATION_SET_VALID, error });
