import { combineReducers } from 'redux';
import roaQuoteTool from './roaQuoteTool';
import elimikaQuoteTool from './elimikaQuoteTool';
import premierSavingsQuoteTool from './premierSavingsQuoteTool';
import accidentalDisabilityQuoteTool from './accidentalDisabilityQuoteTool';
import afyaImaraSeniorQuoteTool from './afyaImaraSeniorQuoteTool';
import accidentalDeathCoverQuoteTool from './lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool';
import deathTermCoverQuoteTool from './lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import afyaImaraJuniorQuoteTool from './afyaImaraJuniorQuoteTool';
import afyaImaraGertrudeJuniorQuoteTool from './afyaImaraGertrudeJuniorQuoteTool';
import wholeLifeCoverQuoteTool from './lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';
import physicalImpairmentCoverQuoteTool from './physicalImpairmentCoverQuoteTool';
import AfyaImaraQuoteTool from './afyaImaraQuoteTool';
import criticalIllnessQuoteTool from './criticalIllnessQuoteTool';
import afyaImaraCountyQuoteTool from './afyaImaraCountyQuoteTool';
import groupLifeQuoteTool from './groupLifeQuoteTool';
import mvestQuoteTool from './mvestQuoteTool';
import digiLifeQuoteTool from './digiLifeQuoteTool';

export const roaQuoteTools = combineReducers({
    roaQuoteTool,
    elimikaQuoteTool,
    premierSavingsQuoteTool,
    accidentalDisabilityQuoteTool,
    afyaImaraSeniorQuoteTool,
    afyaImaraJuniorQuoteTool,
    afyaImaraGertrudeJuniorQuoteTool,
    accidentalDeathCoverQuoteTool,
    wholeLifeCoverQuoteTool,
    AfyaImaraQuoteTool,
    physicalImpairmentCoverQuoteTool,
	deathTermCoverQuoteTool,
    criticalIllnessQuoteTool,
    afyaImaraCountyQuoteTool,
    groupLifeQuoteTool,
    mvestQuoteTool,
    digiLifeQuoteTool
});
