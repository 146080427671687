export const DOMESTIC_INSURANCE_DOCUMENTS_FORM = 'domesticInsurance/documentsForm/';

export const DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE =  DOMESTIC_INSURANCE_DOCUMENTS_FORM + 'setValue/';
export const DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID =  DOMESTIC_INSURANCE_DOCUMENTS_FORM + 'setValid/';
export const FORM_VALID_DOMESTIC_INSURANCE_DOCUMENTS_FORM = 'domesticInsurance/documentsForm/transition/formValid';
export const FORM_INVALID_DOMESTIC_INSURANCE_DOCUMENTS_FORM = 'domesticInsurance/documentsForm/transition/formInValid';

export const REINITIALIZE = 'domesticInsurance/documentsForm/state/reinitialize';


// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM = 'domesticInsurance/documentsForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
	type: DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM,
	value,
});

export const transitionDomesticInsuranceDocumentsForm = {
    formValid: () => ({
        type: FORM_VALID_DOMESTIC_INSURANCE_DOCUMENTS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DOMESTIC_INSURANCE_DOCUMENTS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_DOCUMENTS_FORM = `${ DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DOCUMENTS_FORM = `${ DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DOCUMENTS_FORM = `${ DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDocumentsForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_DOCUMENTS_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_DOCUMENTS_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_DOCUMENTS_FORM,
	}),
};

//calculated Data
export const COLLECT_DOCUMENTS_FORM_DATA =  DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DOCUMENTS_SUCCESS =  DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'success';
export const SUBMIT_DOCUMENTS_FAILURE =  DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
	type: COLLECT_DOCUMENTS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
	type: SUBMIT_DOCUMENTS_SUCCESS,
	value,
});
export const submitDocumentsFailure = (error) => ({
	type: SUBMIT_DOCUMENTS_FAILURE,
	error,
});

// Form Values
export const ID_UPLOAD_SET_VALUE =  DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'idUpload';
export const ID_UPLOAD_SET_VALID =  DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'idUpload';

export const KRA_PIN_UPLOAD_SET_VALUE =  DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'kraPinUpload';
export const KRA_PIN_UPLOAD_SET_VALID = DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'kraPinUpload';


// reset individual form value
export const ID_UPLOAD_RESET_VALUE = DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/idUpload';
export const KRA_PIN_RESET_VALUE = DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'reset/kraUpload';




// ID upload action creators
export const setIdUploadValue = (value) => ({
	type: ID_UPLOAD_SET_VALUE,
	value,
});

export const setIdUploadValid = (error) => ({
	type: ID_UPLOAD_SET_VALID,
	error,
});

export const resetIdUploadValue = () => ({
	type: ID_UPLOAD_RESET_VALUE,
});

// License upload action creators
export const setKraUploadValue = (value) => ({
    type: KRA_PIN_UPLOAD_SET_VALUE,
    value,
});

export const setKraUploadValid = (error) => ({
    type: KRA_PIN_UPLOAD_SET_VALID,
    error,
});

export const resetKraUploadValue = () => ({
    type: KRA_PIN_RESET_VALUE,
});
