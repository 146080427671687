import { DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE } from "../../../actions/doctorsPi/agent";
import { DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE, DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE } from "../../../actions/doctorsPi/documents";
import { PAYMENTS_SET_VALUE } from "../../../actions/doctorsPi/payments";
import { DOCTORS_PI_PERSONAL_DETAILS_FORM_SET_VALUE } from "../../../actions/doctorsPi/personalDetails";
import { DOCTORS_PI_QOUTE_FORM_SET_VALUE, SET_SHARE_EMAIL_VALUE } from "../../../actions/doctorsPi/quote";
import { agentModalFormValidation } from "./agent";
import { declarationsValidation } from "./declarations";
import { uploadDocumentsValidation } from "./documents";
import { paymentsFormValidation } from "./payments";
import { personalDetailsValidation } from "./personalDetails";
import { quoteValidation } from "./quote";
import { shareQuoteValidation } from "./shareQuote";

export const doctorsPiValidation = (store, next, action) => {
	if (action.type.startsWith(DOCTORS_PI_QOUTE_FORM_SET_VALUE)) {
		return quoteValidation(store, next, action);
	}

	if (action.type.startsWith(DOCTORS_PI_PERSONAL_DETAILS_FORM_SET_VALUE)) {
		return personalDetailsValidation(store, next, action);
	}

	if (action.type.startsWith(DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE)) {
		return uploadDocumentsValidation(store, next, action);
	}

	if (action.type.startsWith(DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE)) {
		return declarationsValidation(store, next, action);
	}

	if (action.type.startsWith(PAYMENTS_SET_VALUE)) {
		return paymentsFormValidation(store, next, action);
	}
	
	if (action.type.startsWith(SET_SHARE_EMAIL_VALUE)) {
		return shareQuoteValidation(store, next, action);
	}
	if (action.type.startsWith(DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE)) return agentModalFormValidation(store, next, action);


	
	return null;
};
