import { validationIsEqual } from '../status';
import {
    validateDateOfBirth,
    validateEmail,
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateNumericString,
    validateAlphaString,
    validatePassportNumber,
} from '@om/validation';
import {
    GENDER_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    NATIONALITY_SET_VALUE,
    DOB_SET_VALUE,
    OCCUPATION_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    ADDRESS_SET_VALUE,
    setGenderValid,
    setNationalityValid,
    setOccupationValid,
    setIdNumberValid,
    setPostalAddressValid,
    setFirstNameValid,
    setLastNameValid,
    setEmailValid,
    setPhoneNumberValid,
    setDOBValid,
    setAddressValid,
    PASSPORT_SET_VALUE,
    setPassportValid,
} from '../../../actions/motorPrivateRW/personalDetails';
import { getPersonalDetailsForm } from '../../../selectors/motorPrivateRW/personalDetails';

let prevValidationAction = null;

export const personalDetailsValidation = (store, next, action) => {
    const personalDetails = getPersonalDetailsForm(store.getState());

    const validationAction = (() => {
        let error;
        switch (action.type) {
            case GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Gender',
                });
                return setGenderValid(error);

            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });
                return setFirstNameValid(error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });
                return setLastNameValid(error);

            case NATIONALITY_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setNationalityValid(error);

            case OCCUPATION_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setOccupationValid(error);

            case ID_NUMBER_SET_VALUE:
                error = validateNumericString(action.value, { min: 16, max: 16 });
                return setIdNumberValid(error);

            case PASSPORT_SET_VALUE:
                error = validatePassportNumber(action.value, {});
                return setPassportValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Phone Number',
                });
                return setPhoneNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 15, max: 85 });
                return setDOBValid(error);

            case ADDRESS_SET_VALUE:
                error = validateAlphaString(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Full address',
                });
                return setPostalAddressValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
