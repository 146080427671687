import {createSelector} from "reselect";
import {getEasiplusFuneralPlan} from "./index";
import {
    EFP_COVER_CATEGORIES,
    EFP_ADD_COVER_DRAWERS,
    EFP_MEMBER_AGE_RANGE
} from "../../actions/easiplusFuneralPlan/types";
import {CalcEngine} from "../../functions/easiplusFuneralPlan/CalcEngine";
import {getQuoteReferenceData} from "./referenceData"
import {FORM_STATUS, FIELD_STATUS} from "../../reducers/status";
import {getQuoteReferenceCoverMembers, getQuoteReferenceImmediateFamilyCoverMembers} from "./referenceData";
import {applyThousandSeperator} from '../../functions/easiplusFuneralPlan/Util'

export const getQuote =  createSelector(
    getEasiplusFuneralPlan,
    easiPlusFuneralPlan => easiPlusFuneralPlan.quote
);

// Drawers
export const getLivesCoveredDrawerIsOpen = createSelector(
    getQuote,
    quote => quote.sideDrawerOpen === EFP_ADD_COVER_DRAWERS.LIVES_COVERED,
);
export const getDirectFamilyDrawerIsOpen = createSelector(
    getQuote,
    quote => quote.sideDrawerOpen === EFP_ADD_COVER_DRAWERS.DIRECT_FAMILY,
);
export const getParentsDrawerIsOpen = createSelector(
    getQuote,
    quote => quote.sideDrawerOpen === EFP_ADD_COVER_DRAWERS.PARENTS_AND_IN_LAWS,
);
export const getExtendedFamilyDrawerIsOpen = createSelector(
    getQuote,
    quote => quote.sideDrawerOpen === EFP_ADD_COVER_DRAWERS.EXTENDED_FAMILY,
);
export const getSideDrawer = createSelector(
    getQuote,
    quote => quote.sideDrawerOpen,
);

// Cover categories
export const getAllCoverCategories = createSelector(
    getQuote,
    quote => quote.coverCategoryOptions
);
export const getSelectedCoverCategory = createSelector(
    getQuote,
    quote => quote.formCoverCategories,
);
export const getDirectFamilyIsSelected = createSelector(
    getQuote,
    quote => quote.formCoverCategories.value === EFP_COVER_CATEGORIES.DIRECT_FAMILY
);

// Dropdown options
export const getAllParentsCoverOptions = createSelector(
    getQuote,
    quote => quote.parentsCoverOptions,
);
export const getAllExtendedFamilyCoverOptions = createSelector(
    getQuote,
    quote => quote.extendedFamilyCoverOptions,
);

// Listing members
export const getLivesCovered = createSelector(
    getQuote,
    quote => quote.livesCovered,
);
export const getExpandedView = createSelector(
    getQuote,
    quote => quote.expandedView,
);

// Editing member
export const getEditId = createSelector(
    getQuote,
    quote => quote.editId,
);
export const getEditCover = createSelector(
    getQuote,
    quote => quote.editCover,
);
export const getEditCoverFormIsValid = createSelector(
    getEditCover,
    editCover => editCover && editCover.formStatus === FORM_STATUS.VALID,
);
export const getConfirmDelete = createSelector(
    getQuote,
    quote => quote.confirmDelete,
);
export const getEditCoverForm = createSelector(
    getEditCover,
    editCover => editCover && editCover.form,
);
// Editing member values
const defaultField = {value: null, status: FIELD_STATUS.UNTOUCHED, error: null};
const makeGetFieldWithDefault = fieldId => createSelector(
    getEditCoverForm,
    editCoverForm => {
        if (!editCoverForm) {
            return;
        }

        const field = editCoverForm[fieldId];
        return field ? field : defaultField;
    },
);
export const getAge = makeGetFieldWithDefault('age');
export const getCoverAmount = makeGetFieldWithDefault('coverAmount');
export const getMonthlyPremium = makeGetFieldWithDefault('monthlyPremium');
export const getAddPartner = makeGetFieldWithDefault('addPartner');
export const getAddChild = makeGetFieldWithDefault('addChild');
export const getRelationship = makeGetFieldWithDefault('relationship');

// Thousands separated cover amounts
const formattedThousands = num => (+num).toLocaleString().replace(',', ' ');
export const getDisplayCoverAmount = createSelector(
    getCoverAmount,
    coverAmount => {
        return (coverAmount && coverAmount.value)
            ? 'R' + formattedThousands(coverAmount.value)
            : 'R0';
    }
);
export const getDisplayMonthlyPremium = createSelector(
    getAge,
    getMonthlyPremium,
    (age, monthlyPremium) => {
        return (age && age.status === FORM_STATUS.VALID && monthlyPremium && monthlyPremium.value)
            ? 'R' + formattedThousands(monthlyPremium.value)
            : 'R0';
    }
);

// Return bool value for showingAgeValidation
export const getShowingAgeValidation = createSelector(
    getQuote,
    quote => quote.showingAgeValidation
);


// A list to display each member from each cover category
export const makeCoverDisplayList = coverCategory => createSelector(
    getLivesCovered,
    livesCovered => {
        const displayList = livesCovered[coverCategory];

        if (!displayList) {
            return [];
        }

        return livesCovered[coverCategory]
            .map(cover => ({
                id: cover.id,
                age: cover.value.age.value,
                coverAmount: cover.value.coverAmount.value,
                monthlyPremium: cover.value.monthlyPremium.value,
                relationship: (() => {
                    if (coverCategory !== EFP_COVER_CATEGORIES.DIRECT_FAMILY) {
                        return cover.value.relationship && cover.value.relationship.value;
                    }

                    if (cover.value.addPartner.value) {
                        return 'Me & My Family';
                    }

                    if (cover.value.addChild.value) {
                        return 'Me & My Children';
                    }

                    return 'Myself';
                })(),
            }));
    }
);

export const getDirectFamilyList =  makeCoverDisplayList(EFP_COVER_CATEGORIES.DIRECT_FAMILY);
export const getParentsAndInLawsList = makeCoverDisplayList(EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS);
export const getExtendedFamilyList = makeCoverDisplayList(EFP_COVER_CATEGORIES.EXTENDED_FAMILY);

const makeDisplayFamilyList = selector => createSelector(
    selector,
    list => list.map(element => ({
        ...element,
        coverAmount: `R${applyThousandSeperator(element.coverAmount)}`,
        monthlyPremium: `R${applyThousandSeperator(element.monthlyPremium)}`,
    }))
);

export const getDisplayDirectFamily = makeDisplayFamilyList(getDirectFamilyList);
export const getDisplayParentsAndInLaws = makeDisplayFamilyList(getParentsAndInLawsList);
export const getDisplayExtendedFamily = makeDisplayFamilyList(getExtendedFamilyList);

// A list with all cover categories and their members combined.
export const getQuotePageCoverList = createSelector(
    getDisplayDirectFamily,
    getDisplayParentsAndInLaws,
    getDisplayExtendedFamily,
    getExpandedView,
    (directFamily, parentsAndInLaws, extendedFamily, expandedView) => {
        const minCollapseAmount = 2;
        const coverListView = view => {
            const {displayList, category} = view;
            const isExpanded = expandedView === category;
            const isCollapsed =  displayList
                && displayList.length > minCollapseAmount
                && !isExpanded;
            const filteredDisplayList = isCollapsed
                ? displayList.slice(0, minCollapseAmount)
                : displayList;
            const expandButtonText = isExpanded ? 'VIEW LESS MEMBERS' : (isCollapsed ? 'VIEW MORE MEMBERS' : null);
            const listView = displayList && displayList.length && [{
                ...view,
                isExpanded,
                isCollapsed,
                expandButtonText,
                displayList: filteredDisplayList,
            }];

            return (listView || []);
        };

        const directFamilyCoverList = coverListView({
            headingText: 'Me & My Direct Family',
            displayList: directFamily,
            category: EFP_COVER_CATEGORIES.DIRECT_FAMILY,
        });
        const parentsAndInLawsCoverList = coverListView({
            headingText: 'My Parents & In-laws',
            displayList: parentsAndInLaws,
            category: EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS,
        });
        const extendedFamilyCoverList = coverListView({
            headingText: 'My Extended Family',
            displayList: extendedFamily,
            category: EFP_COVER_CATEGORIES.EXTENDED_FAMILY,
        });

        return [...directFamilyCoverList, ...parentsAndInLawsCoverList, ...extendedFamilyCoverList];
    }
);

// Returns true if no cover members have been added.
export const getIsOnInitPage = createSelector(
    getLivesCovered,
    livesCovered => {
        if (!livesCovered) {
            return true;
        }
        return !Object.values(livesCovered).flat().length;
    }
);

export const getEfpApplicationType = createSelector(
    getIsOnInitPage,
    isOnInitPage => {
        return isOnInitPage ? 'efp-quote-init-page' : 'efp-quote';
    }
);

// List to display each cover category. Includes 'disabled' property for dropdowns.
export const getDisplayCoverCategories = createSelector(
    getAllCoverCategories,
    getDisplayDirectFamily,
    getDisplayParentsAndInLaws,
    getDisplayExtendedFamily,
    (allCoverCategories, directFamily, parentsAndInLaws, extendedFamily) => {
        const displayAllCoverCategories = allCoverCategories.map(category => ({
            value: category,
            disabled: false,
        }))

        if (directFamily && directFamily.length === 1) {
            displayAllCoverCategories[0].disabled = true;
        }

        if (parentsAndInLaws && parentsAndInLaws.length === 4) {
            displayAllCoverCategories[1].disabled = true;
        }

        if (extendedFamily && extendedFamily.length === 10) {
            displayAllCoverCategories[2].disabled = true;
        }

        return displayAllCoverCategories;
    }
);

// Return true if the max amount of cover members have been added
export const getAddedMaxCoverMembers = createSelector(
    getDisplayDirectFamily,
    getDisplayParentsAndInLaws,
    getDisplayExtendedFamily,
    (directFamily, parentsAndInLaws, extendedFamily) => {
        const maxCoverMembersAdded = (directFamily && directFamily.length === 1)
            && (parentsAndInLaws && parentsAndInLaws.length === 4)
            && (extendedFamily && extendedFamily.length === 10);

        return maxCoverMembersAdded && 'maxAdded';
    }
)

// List to display each parent cover option.
export const makeGetAllCoveredRelationship = category => createSelector(
    getLivesCovered,
    livesCovered => {
        const relations = livesCovered[category];
        return relations
            ? relations.map(relation => relation.value.relationship.value)
            : [];
    }
);
export const getCoveredParents = makeGetAllCoveredRelationship(EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS);
// Include 'disabled' property for dropdowns.
export const getDisplayParentCoverOptions = createSelector(
    getCoveredParents,
    getAllParentsCoverOptions,
    (parentsCovered, parentOptions) => {
        return parentOptions.map(parent => ({
            value: parent,
            disabled: parentsCovered.includes(parent)
        }));
    }
);

export const getDisplayCoverMembers = createSelector(
    getSelectedCoverCategory,
    getDisplayParentCoverOptions,
    getAllExtendedFamilyCoverOptions,
    (coverCategory, parentOptions, extendedOptions) => {
        switch (coverCategory.value) {
            case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS:
                return parentOptions;
            case EFP_COVER_CATEGORIES.EXTENDED_FAMILY:
                return extendedOptions.map(member => ({value: member}));
            default:
                return [];
        }
    }
);

export const getQuoteSummary = createSelector(
    getDirectFamilyList,
    getParentsAndInLawsList,
    getExtendedFamilyList,
    (directFamily, parentsAndInLaws, extendedFamily) => {
        const categories = []
        const categoryAmounts = [];
        const sumMonthlyPremiums = category => category.reduce((acc, cover) => acc + (+cover.monthlyPremium), 0);

        if (directFamily.length) {
            categories.push('Me & My Direct Family');
            categoryAmounts.push(sumMonthlyPremiums(directFamily));
        }
        if (parentsAndInLaws.length) {
            categories.push('My Parents & In-laws');
            categoryAmounts.push(sumMonthlyPremiums(parentsAndInLaws));
        }
        if (extendedFamily.length) {
            categories.push('My Extended Family');
            categoryAmounts.push(sumMonthlyPremiums(extendedFamily));
        }

        return {
            categories: JSON.stringify(categories),
            categoryAmounts: JSON.stringify(categoryAmounts),
        }
    },
);

export const getEditCoverCategory = createSelector(
    getQuote,
    quote => quote.editCover && quote.editCover.coverCategory ? quote.editCover.coverCategory.value : quote.formCoverCategories.value
);

export const getEditCoverCategoryField = createSelector(
    getEditCover,
    editCover => editCover && editCover.coverCategory,
);

export const getAgeHintText = createSelector(
    getEditCoverCategory,
    coverCategory => {
        const {min, max} = (() => {
            switch (coverCategory) {
                case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS: return EFP_MEMBER_AGE_RANGE.PARENTS_AND_IN_LAWS;
                case EFP_COVER_CATEGORIES.EXTENDED_FAMILY: return EFP_MEMBER_AGE_RANGE.EXTENDED_FAMILY;
                default: return EFP_MEMBER_AGE_RANGE.DEFAULT;
            }
        })();
        if (min === 1 || min === 0)
            return `Age: (Maximum age of ${max})`;

        return `Age: (Minimum age of ${min} - Maximum ${max})`;
    }
);

export const getAgePlaceholderText = createSelector(
    getEditCoverCategory,
    getRelationship,
    (coverCategory, relationship) => {
        if (coverCategory === EFP_COVER_CATEGORIES.DIRECT_FAMILY) {
            return 'How old are you?';
        }

        const relationshipValue = relationship && relationship.value;
        if (!coverCategory || !relationshipValue) {
            return '';
        }

        return `How old is your ${relationshipValue.toLowerCase()}?`;
    }
);

// Edit form for cover member is valid
export const getCoverIsValid = createSelector(
    getEditCoverForm,
    formFields => {
        if (!formFields) {
            return false;
        }

        return Object.keys(formFields)
            .map(field => formFields[field].status === FIELD_STATUS.VALID)
            .reduce((acc, currentValue) => acc && currentValue);
    }
);

// Cover amount limits for slider.
export const getCoverAmountLimits = createSelector(
    getEditCoverCategory,
    coverCategory => {
        const defaultMin = {
            minValue: '10 000',
            minLabel: 'R10 000',
        };

        switch (coverCategory) {
            case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS:
                return {
                    ...defaultMin,
                    maxValue: '50000',
                    maxLabel: 'R50 000',
                };
            case EFP_COVER_CATEGORIES.EXTENDED_FAMILY:
                return {
                    ...defaultMin,
                    maxValue: '30000',
                    maxLabel: 'R30 000',
                };
            default:
                return {
                    ...defaultMin,
                    maxValue: '70000',
                    maxLabel: 'R70 000',
                };
        }
    }
);

// Cover amount limits for slider.
export const getSliderSteps = createSelector(
    getEditCoverCategory,
    coverCategory => {
        switch (coverCategory) {
            case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS:
                return [10000,15000,20000,25000,30000,40000,50000];
            case EFP_COVER_CATEGORIES.EXTENDED_FAMILY:
                return [10000,15000,20000,25000,30000];
            default:
                return [10000,15000,20000,25000,30000,40000,50000,60000,70000];
        }
    }
);

export const getPremiumFromCoverAmount = createSelector(
    getQuoteReferenceData,
    getEditCoverCategory,
    getAddPartner,
    getAddChild,
    getAge,
    getCoverAmount,
    (quoteReferenceData, selectedCoverCategory, addPartner, addChild, age, coverAmount) => {
        if (selectedCoverCategory && age.status === FORM_STATUS.VALID && coverAmount.value) {
            const coverCategory = CalcEngine.mapCoverCategoryForProductCode(selectedCoverCategory, addPartner.value, addChild.value);
            const premiumValue = CalcEngine.easiplusLookupPremiumValue(quoteReferenceData, coverCategory, age.value, coverAmount.value);
            return premiumValue ? premiumValue : null;
        }
    }
);

export const getRemoveMemberMessage = createSelector(
    getConfirmDelete,
    confirmDelete => {
        if (!confirmDelete || !(confirmDelete.id != null)) {
            return '';
        }

        const {relationship, category} = confirmDelete;
        const person = category === EFP_COVER_CATEGORIES.DIRECT_FAMILY
            ? 'yourself'
            : 'your ' + relationship.toLowerCase();

        return `Are you sure you want to remove ${person}?`;
    }
);

// Submission
export const getQuoteSubmission = createSelector(
    getQuoteReferenceCoverMembers,
    getQuoteReferenceImmediateFamilyCoverMembers,
    getLivesCovered,
    (quoteCoverMembers, quoteImmediateFamilyCoverMembers, livesCovered) => {
        const getCoverMember = (value, addPartner, addChild) => {
            if (value) {
                return quoteCoverMembers.find(member => member.relationshipTypeDescription === value);
            }

            const productCode = (!addPartner && !addChild)
                ? 'Individual'
                : (addChild ? 'SingleFamily': 'Family')
            return quoteImmediateFamilyCoverMembers.find(member => member.productCode === productCode);
        }

        return Object.values(livesCovered)
            .flatMap(coverList => coverList.map(coverItem => {
                const {age, coverAmount, monthlyPremium, relationship, addPartner, addChild} = coverItem.value;
                const coverMember = getCoverMember(
                    relationship && relationship.value,
                    addPartner && addPartner.value,
                    addChild && addChild.value
                );
                return {
                    ...coverMember,
                    age: +age.value,
                    coverAmount: +coverAmount.value,
                    totalPremium: +monthlyPremium.value,
                    dateOfBirth: "",
                };
            }));
    }
);
export const getQuoteNumber = createSelector(
    getQuote,
    quote => quote.quoteNumber,
);
export const getCreateQuoteError = createSelector(
    getQuote,
    quote => !!quote.createQuoteError
);
export const getCreateQuoteSuccess = createSelector(
    getQuote,
    quote => !!quote.createQuoteSuccess
);
export const getWaitingForCreateQuote = createSelector(
    getQuote,
    quote => !!quote.waitingForCreateQuote
);
