import { createSelector } from 'reselect';
import { getInvestmentCalculators } from './index';
import {
	getSelectedGoal,
	getShowShortTermGoalDropField,
	getShowShortTermGoalSplitForm,
} from './investmentCalculator';
import { getAmountOnceOffValue, getMonthlyAmountValue, getShortTermGoalCalculatorForm } from './shortTermGoalCalculator';

export const getShortTermGoalFundSplit = createSelector(
	getInvestmentCalculators,
	(investmentCalculators) => investmentCalculators.shortTermGoalFundSplit
);

export const getFundSplitForm = createSelector(
	getShortTermGoalFundSplit,
	(shortTermGoalFundSplit) => shortTermGoalFundSplit.form
);

export const getIsEvenSplit = createSelector(
	getShortTermGoalFundSplit,
	(fundSplit) => fundSplit.isEvenSplit
);

export const getEquityFundValue = createSelector(
	getFundSplitForm,
	(fundSplit) => fundSplit.equityFund
);

export const getBalancedFundValue = createSelector(
	getFundSplitForm,
	(fundSplit) => fundSplit.balancedFund
);

export const getSplitError = createSelector(
	getShortTermGoalFundSplit,
	(fundSplit) => fundSplit.splitError
);

export const getTotalPercentage = createSelector(
	getShortTermGoalFundSplit,
	(fundSplit) => fundSplit.totalPercentage
);

export const getShortTermGoalFundSplitForm = createSelector(
	getShortTermGoalFundSplit,
	(fundSplit) => fundSplit.form
);

export const getEquityFundSplitValue = createSelector(
	[
		getEquityFundValue,
		getShortTermGoalCalculatorForm,
		getShowShortTermGoalSplitForm,
	],
	(equityFund, shortTermGoal, showSplitForm) => {
		if (!showSplitForm) {
			return 0;
		}
		const total =
			Number(shortTermGoal.amountOnceOff.value || 0) +
			Number(shortTermGoal.amountMonthly.value || 0);
		return total * (Number(equityFund.value || 0) / 100);
	}
);

export const getBalancedFundSplitValue = createSelector(
	[
		getBalancedFundValue,
		getShortTermGoalCalculatorForm,
		getShowShortTermGoalSplitForm,
	],
	(balancedFund, shortTermGoal, showSplitForm) => {
		if (!showSplitForm) {
			return 0;
		}
		const total =
			Number(shortTermGoal.amountOnceOff.value || 0) +
			Number(shortTermGoal.amountMonthly.value || 0);
		return total * (Number(balancedFund.value || 0) / 100);
	}
);

export const getSTGDataWithSplit = createSelector(
	[
		getEquityFundValue,
		getBalancedFundValue,
		getShowShortTermGoalDropField,
		getAmountOnceOffValue,
		getMonthlyAmountValue,
		getSelectedGoal,
		getShortTermGoalCalculatorForm,
		getShowShortTermGoalSplitForm
	],
	(
		equityFundValue,
		balancedFundValue,
		sendDrop,
		initialAmount,
		monthlyAmount,
		goal,
		LTGCalculatorForm,
		showSplitForm
	) => {
		const { potentialLossPercentage: {value : drop}, investmentTenure: {value: tenure} } = LTGCalculatorForm;
		if (tenure === '12' && drop === '0') {
			return  {
				initial_amount: initialAmount.value,
				goal,
				monthly_savings: monthlyAmount.value,
				productitems: [{ id: 24 }]
			}; //mmf
		}
		if (sendDrop && drop === '0.05') {
			return  {
				initial_amount: initialAmount.value,
				goal,
				monthly_savings: monthlyAmount.value,
				productitems: [{ id: 21 }]
			}; //balanced
		}
		if(showSplitForm && drop === '0.1'){
			return {
				initial_amount: initialAmount.value,
				goal,
				monthly_savings: monthlyAmount.value,
				productitems: [
					{ id: 20, percent_split: equityFundValue.value }, //equity
					{ id: 21, percent_split: balancedFundValue.value }, //balanced
				],
			};
		}
		if (sendDrop && drop === '>0.1'){
			return {
				initial_amount: initialAmount.value,
				monthly_savings: monthlyAmount.value,
				productitems: [
					{id: 20}
				],
				goal
			}
		}
		else {
			return {
				productitems: [{id: 24}],
				initial_amount: initialAmount.value,
				monthly_savings:  monthlyAmount.value,
				goal,
			}
		}
	})
