import { createSelector } from 'reselect';
import { getPersonalPensionPlanState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getPersonalDetails = createSelector(getPersonalPensionPlanState, (state) => state.personalDetails);

export const getPersonalDetailsForm = createSelector(getPersonalDetails, (personalDetails) => personalDetails.form);

export const getSelectedForm = createSelector(getPersonalDetails, (personalDetails) => personalDetails.selectedForm);

export const getPersonalDetailsFormValid = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getIsNationalityApiPending = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.natiolalityApiCallStatus === API_CALL_STATUS.PENDING,
);

export const getNationalities = createSelector(getPersonalDetails, (personalDetails) => personalDetails.nationalities);

const makeGetFieldForm = (fieldId) => createSelector(getPersonalDetailsForm, (form) => form[fieldId]);

export const getFirstNameValue = makeGetFieldForm('firstName');
export const getLastNameValue = makeGetFieldForm('lastName');
export const getDOBValue = makeGetFieldForm('dob');
export const getGenderValue = makeGetFieldForm('gender');
export const getNationalityValue = makeGetFieldForm('nationality');
export const getOccupationValue = makeGetFieldForm('occupation');
export const getIdNumberValue = makeGetFieldForm('idNumber');
export const getTinNumberValue = makeGetFieldForm('tinNumber');
export const getEmailValue = makeGetFieldForm('email');
export const getPhoneNumberValue = makeGetFieldForm('phoneNumber');
export const getPostalAddressValue = makeGetFieldForm('postalAddress');
