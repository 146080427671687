import { validatePercent } from "@om/validation";
import {
  setSplitPercentageValid,
  setTotalPercentageValid,
  SPLIT_PERCENTAGE_SET_VALUE,
  TOTAL_PERCENTAGE_SET_VALUE,
} from "../../../actions/investmentCalculator/buildWealthFundSplit";

export const buildWealthFundSplitValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type){
            case SPLIT_PERCENTAGE_SET_VALUE:
                error = validatePercent(action.value);
                return setSplitPercentageValid(error);
            case TOTAL_PERCENTAGE_SET_VALUE:
                console.log(action.value)
                error  = action.value !== 100 ? 'Your total should be 100%' : null;
                return setTotalPercentageValid(error);
            default:
                return null;
        }
    })();

    store.dispatch(validationAction)
    return next(action);
};
