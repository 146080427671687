import {
    mapError,
} from '../formField.js';

import {
    FORM_INVALID_TRANSITION_CALCULATOR,
    FORM_VALID_TRANSITION_CALCULATOR,
    PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE,
    PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALID,
    PAST_PERFORMANCE_CALCULATOR_SET_CONTRIBUTION_FREQUENCY_VALUE,
    PAST_PERFORMANCE_CALCULATOR_SET_FUND_VALUE,
    PAST_PERFORMANCE_CALCULATOR_SET_INVESTEMENT_TERM_VALUE,
    PAST_PERFORMANCE_TRANSITION_CALCULATOR_RESULTS,
    PAST_PERFORMANCE_REFERENCE_DATA_SET_VALUE,
} from '../../actions/pastPerformanceCalculator/pastPerformanceCalculator';

import {
    FORM_STATUS,
    TOGGLE_STATUS
} from "../status";

//The default investment type or contribution frequency
const ONCE_OFF_INVESTMENT_TYPE = 'ONCE_OFF';

export const pastPerformanceInitialState = {
    investmentTerms: [],
    investmentAmount: { value: null, error: null, status: null },
    investmentTerm: { value: null, error: null, status: null },
    contributionFrequency: ONCE_OFF_INVESTMENT_TYPE,
    fund: { value: null, error: null, status: null },
    formStatus: FORM_STATUS.INVALID,
    showResultsCard: TOGGLE_STATUS.NO,
    referenceFundsData: [],
    referenceInflationData: {},
    referenceCalculationDate: '',
};

const setFundInvestmentTerms = (fundData, fundName, investmentType) => {
    let investTerms = [];
    if(investmentType === ONCE_OFF_INVESTMENT_TYPE){
        investTerms = fundData.filter(fund => fund.value === fundName)[0].onceOffInvestmentTerms;
    } else {
        investTerms = fundData.filter(fund => fund.value === fundName)[0].monthlyInvestmentTerms;
    }
    return investTerms.length > 0? investTerms.map(t => ({value: t.value,label: t.label})) : [{value: 0, label: 0}];
};

export const pastPerformanceCalculator = (state = pastPerformanceInitialState, action) => {
    switch (action.type) {
        case FORM_INVALID_TRANSITION_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };
        case FORM_VALID_TRANSITION_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE:
            return {
                ...state,
                investmentAmount: {
                    ...state.investmentAmount,
                    value: action.value,
                },
                showResultsCard: TOGGLE_STATUS.NO,
            };
        case PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALID:
            return {
                ...state,
                investmentAmount: {
                    ...state.investmentAmount,
                    error: action.error,
                    status: mapError(action.error)
                },
            };
        case PAST_PERFORMANCE_CALCULATOR_SET_CONTRIBUTION_FREQUENCY_VALUE:
            const termListFrequencyChange = setFundInvestmentTerms(state.referenceFundsData, state.fund.value, action.value);
            return {
                ...state,
                contributionFrequency: action.value,              
                investmentTerms: termListFrequencyChange,
                investmentTerm: {
                    ...state.investmentTerm,
                    value: termListFrequencyChange[0].value
                },
                showResultsCard: TOGGLE_STATUS.NO,
            };
        case PAST_PERFORMANCE_CALCULATOR_SET_FUND_VALUE:
            const termList = setFundInvestmentTerms(state.referenceFundsData, action.value, state.contributionFrequency);
            return {
                ...state,
                fund: {
                    ...state.fund,
                    value: action.value,
                },
                investmentTerms: termList,
                investmentTerm: {
                    ...state.investmentTerm,
                    value: termList[0].value
                },
                showResultsCard: TOGGLE_STATUS.NO,         
            };
        case PAST_PERFORMANCE_CALCULATOR_SET_INVESTEMENT_TERM_VALUE:
            return {
                ...state,
                investmentTerm: {
                    ...state.investmentTerm,
                    value: parseInt(action.value)
                },
                showResultsCard: TOGGLE_STATUS.NO,
            };
        case PAST_PERFORMANCE_TRANSITION_CALCULATOR_RESULTS:
            return {
                ...state,
                showResultsCard: TOGGLE_STATUS.YES,
            };
        case PAST_PERFORMANCE_REFERENCE_DATA_SET_VALUE:
            const fundsRefData = action.value[0].ReferenceFunds;
            const terms = setFundInvestmentTerms(fundsRefData, fundsRefData[0].value, ONCE_OFF_INVESTMENT_TYPE);
             return {
                ...state,
                referenceFundsData: fundsRefData,
                referenceInflationData: action.value[0].ReferenceInflation.reduce(
                    (map, obj) => ({...map, [obj.inflationType]: obj.value }),
                    {},
                ),
                fund: {
                    ...state.fund,
                    value: fundsRefData[0].value,
                },
                investmentTerms: terms,
                investmentTerm: {
                    ...state.investmentTerm,
                    value: terms[0].value
                },
                referenceCalculationDate: action.value[0].CalculationDate,           
            };
        default:
            return state;
    }
};
