import { validationIsEqual } from '../status';
import { validateName, validateNumber } from '@om/validation';
import {
    AGENT_NAME_SET_VALUE,
    AGENT_CODE_SET_VALUE,
    setNameValid,
    setAgentCodeValid,
} from '../../../actions/travel/motorAgentModal';

let prevValidationAction = null;

export const agentModalFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AGENT_NAME_SET_VALUE:
                error = validateName(action.value.name, {
                    min: 2,
                    max: 48,
                    subject: 'Name',
                });
                return setNameValid(error);

            case AGENT_CODE_SET_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Agent Code',
                });
                return setAgentCodeValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
