import { getRaOptimal } from "./index";
import { createSelector } from "reselect";
import { FORM_STATUS, TOGGLE_STATUS } from "../../reducers/status";
import {  SUPPORTED_BANKS } from "../../middleware/constants";
import { getReferenceDebitOrderDates } from "./referenceData";
import { ACCOUNT_VALIDATION_STATUS } from "../../actions/raOptimal/types";

export const getPaymentDetails = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.paymentDetails
);

export const getPaymentDetailsValid = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.formStatus === FORM_STATUS.VALID,
);

export const getPaymentDetailsFormPaymentDetailsValid = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.paymentDetailsFormStatus === FORM_STATUS.VALID,
);

export const getPaymentAuthorizationValid = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.paymentAuthorization === TOGGLE_STATUS.YES,
);

export const getPaymentAuthorizationFalse = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.paymentAuthorization === TOGGLE_STATUS.NO,
);

export const getPaymentAuthorizationInvalid = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.paymentAuthorization === FORM_STATUS.INVALID,
);

export const getPaymentAuthorization = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.paymentAuthorization,
);

export const getSourceOfIncomeOtherRequired = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.sourceOfIncomeOtherRequired === TOGGLE_STATUS.YES,
);

export const getEmploymentIndustryRequired = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.employmentIndustryRequired === TOGGLE_STATUS.YES,
);

export const getEmploymentIndustryOtherRequired = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.employmentIndustryOtherRequired === TOGGLE_STATUS.YES,
);

export const getIsAccountHolderNameEdited = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.accountHolderNameEdited === TOGGLE_STATUS.YES,
);

export const getPaymentDetailsForm = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.form,
);

const makeGetField = fieldId => createSelector(
    getPaymentDetailsForm,
    paymentDetailsForm => paymentDetailsForm[fieldId]
);

// Payment
export const getBankName = makeGetField('bankName');
export const getAccountHolderName = makeGetField('accountHolderName');
export const getAccountNumber = makeGetField('accountNumber');
export const getAccountType = makeGetField('accountType');
export const getDebitOrderDay = makeGetField('debitOrderDay');
export const getStartMonth = makeGetField('startMonth');

// Legal
export const getSourceOfIncome = makeGetField('sourceOfIncome');
export const getSourceOfIncomeOther = makeGetField('sourceOfIncomeOther');
export const getSourceOfFunds = makeGetField('sourceOfFunds');
export const getEmploymentPosition = makeGetField('employmentPosition');
export const getEmploymentIndustry = makeGetField('employmentIndustry');
export const getEmploymentIndustryOther = makeGetField('employmentIndustryOther');

export const getAccountValidationStatus = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.accountValidationStatus
)

export const getAccountValidationComplete = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.accountValidationComplete
)

// this value is set on mounted via contentstack
export const getIncludeAvs = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.includeAvs,
);

// this value is set on mounted via contentstack
export const getIncludeCdv = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.includeCdv,
);

export const getBankSupportedForRA = createSelector(
    getBankName,
    bankName => {
        return SUPPORTED_BANKS.some(bank => bank.bankName === bankName.value);
    }
);

export const getPayloadBankName = createSelector(
    getBankName,
    bankName =>  {
        const bank = SUPPORTED_BANKS.find(bank => bank.bankName === bankName.value);
        return bank ? bank.verifictionFormattedName : '';
    }
);

export const getPaymentDetailsCompleted = createSelector(
    getBankName,
    getAccountHolderName,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getStartMonth,
    (bankName, accountHolderName, accountNumber, accountType, debitOrderDay, startMonth) => {
        return (bankName.status === 'valid' &&
        accountHolderName.status === 'valid' &&
        accountNumber.status === 'valid' &&
        accountType.status === 'valid' &&
        debitOrderDay.status === 'valid' &&
        startMonth.status === 'valid')
    }
);

export const getReferenceMonths = createSelector(
    getDebitOrderDay,
    getReferenceDebitOrderDates,
    (debitOrderDay, referenceDebitOrderDates) => {
        if (debitOrderDay.value) {
            return referenceDebitOrderDates.debitOrderDays.find(date => date.day == debitOrderDay.value).months;
        }
        return [];
    }
);

export const getReferenceDates = createSelector(
    getReferenceDebitOrderDates,
    referenceDebitOrderDates => referenceDebitOrderDates ? referenceDebitOrderDates.debitOrderDays : []
);

// Success modal must only be dispalyed in one of the following cases;
// 1. Account Validation passed and Include AVS is active and Bank Account is in list of supported banks 
// 2. Account Validation passed and Include CDV is active
export const getDisplaySuccessModal = createSelector(
    getAccountValidationStatus,
    getBankSupportedForRA,
    getIncludeAvs,
    getIncludeCdv,
    (accountValidationStatus, bankSupported, includeAvs, includeCdv) => {
        if (accountValidationStatus !== ACCOUNT_VALIDATION_STATUS.PASSED) return false;
        if ((includeAvs && bankSupported) || includeCdv) return true;
    }
);
