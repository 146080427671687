import { createSelector } from 'reselect';
import { ID_NUMBER } from '../../actions/digitalOnboarding/types';
import { getDigitalOnboarding } from './index';

export const getPersonalDetails = createSelector(
    getDigitalOnboarding,
    digitalOnboarding => digitalOnboarding.personalDetails.storeValues
);

export const getIdNumber = createSelector(
    getPersonalDetails,
    personal => personal[ID_NUMBER].value
);
