import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from "../status";
import {
  CITY_SET_VALID,
  CITY_SET_VALUE,
  COUNTRY_SET_VALID,
  COUNTRY_SET_VALUE,
  EMAIL_SET_VALID,
  EMAIL_SET_VALUE,
  FIRST_NAMES_SET_VALID,
  FIRST_NAMES_SET_VALUE,
  FORM_INVALID_TRANSITION_PERSONAL_DETAILS,
  FORM_VALID_TRANSITION_PERSONAL_DETAILS,
  ID_SET_VALID,
  ID_SET_VALUE,
  MOBILE_SET_VALID,
  MOBILE_SET_VALUE,
  POSTAL_CITY_CLEAR_VALUE,
  POSTAL_CITY_SET_VALID,
  POSTAL_CITY_SET_VALUE,
  POSTAL_COUNTRY_CLEAR_VALUE,
  POSTAL_COUNTRY_SET_VALID,
  POSTAL_COUNTRY_SET_VALUE,
  POSTAL_STREET_ADDRESS_CLEAR_VALUE,
  POSTAL_STREET_ADDRESS_SET_VALID,
  POSTAL_STREET_ADDRESS_SET_VALUE,
  STREET_ADDRESS_SET_VALID,
  STREET_ADDRESS_SET_VALUE,
  SURNAME_SET_VALID,
  SURNAME_SET_VALUE,
  TITLE_SET_VALID,
  TITLE_SET_VALUE,
  RESET_STATE_TRANSITION_PERSONAL_DETAILS,
  LOAN_AMOUNT_SET_VALUE,
  LOAN_AMOUNT_SET_VALID,
  ID_EXPIRY_SET_VALUE,
  ID_EXPIRY_SET_VALID,
  EDUCATION_SET_VALUE,
  EDUCATION_SET_VALID,
  DISTRICT_SET_VALID,
  DISTRICT_SET_VALUE,
  LOAN_TYPE_SET_VALUE,
  LOAN_TYPE_SET_VALID,
  BANK_SET_VALUE,
  BANK_SET_VALID,
  LOAN_TERM_SET_VALUE,
  LOAN_TERM_SET_VALID,
  EMOPLOYMENT_TYPE_SET_VALUE,
  EMOPLOYMENT_TYPE_SET_VALID,
  OCCUPATION_SET_VALUE,
  OCCUPATION_SET_VALID,
  INCOME_SOURCE_VALID,
  INCOME_SOURCE_SET_VALUE,
  PAYMENT_FREQUENCY_SET_VALUE,
  PAYMENT_FREQUENCY_SET_VALID,
  GENDER_SET_VALUE,
  GENDER_SET_VALID,
  ID_TYPE_SET_VALUE,
  POSTAL_DISTRICT_SET_VALUE,
  POSTAL_DISTRICT_SET_VALID,
  INCOME_SOURCE_TEXT_SET_VALUE,
  INCOME_SOURCE_TEXT_VALID,
} from "../../actions/digiLifeLoanProtection/personalDetails";
import { getLoanType } from "../../selectors/roaQuoteTools/digiLifeQuoteTool";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

/* We use displayValue in the name and surname fields to account for trimming the string, 
the value will be the trimmed version while displayValue will be the user input, 
Implemented in the reducer bellow. Value also gets trimmed prior to validation */
export const initCountry = "Botswana";
export const initPersonalDetails = {
  formStatus: FORM_STATUS.INVALID,
  gCSStreetAddress: null,
  gCSPostalAddress: null,
  dropOffDetails: null,
  dlpCmbObject: {
    __typename: 'Contentstack_call_me_back_email',
    product: 'Digi Life',
    email: "lifeenquiries@oldmutual.co.bw",
    privacy_policy_url: '/about-old-mutual/privacy-policy/'
},
  form: {
    title: { value: null, error: null, status: null, displayValue:null },
    firstNames: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },
    surname: { displayValue: null, value: null, error: null, status: null },
    gender: { value: null, error: null, status: null, displayValue:null },
    idType: {
      displayValue: "Omang",
      value: "01",
      error: null,
      status: null,
    },
    id: { displayValue: null, value: null, error: null, status: null },
    idExpiry: { value: null, error: null, status: null },
    education: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },

    mobileNumber: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },
    email: { displayValue: null, value: null, error: null, status: null },

    streetAddress: { value: null, error: null, status: null },
    city: { value: null, error: null, status: null },
    district: { value: null, error: null, status: null, displayValue: null},
    country: { value: initCountry, error: null, status: FORM_STATUS.VALID },

    postalStreetAddress: { value: null, error: null, status: null },
    postalCity: { value: null, error: null, status: null },
    postalDistrict: { value: null, error: null, status: null ,displayValue: null},
    postalCountry: {
      value: initCountry,
      error: null,
      status: FORM_STATUS.VALID,
    },

    loanAmount: { value: null, error: null, status: null },
    loanType: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },
    bank: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },
    loanTerm: { value: null, error: null, status: null },

    employmentType: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },
    occupation: { value: null, error: null, status: null },
    sourceOfIncome: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },
    sourceOfIncomeText: { value: null, error: null, status: null },
    paymentFrequency: {
      displayValue: null,
      value: null,
      error: null,
      status: null,
    },
  },
};

export default (state = initPersonalDetails, action) => {
  const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
  const clearError = () => FIELD_STATUS.UNTOUCHED;
  const clearCountryError = () => FIELD_STATUS.VALID; // Has default value so can set to valid.
  const updateFormField = (fieldId, update) => ({
    ...state,
    form: {
      ...state.form,
      [fieldId]: update(state.form[fieldId]),
    },
  });
  const trimField = (value) => (value ? value.trim() : value);
  const removeWhiteSpacesFromValue = (value) =>
    value ? value.replace(/ /g, "") : value;

  switch (action.type) {
    // Form Validation
    case FORM_VALID_TRANSITION_PERSONAL_DETAILS:
      return { ...state, formStatus: FORM_STATUS.VALID };
    case FORM_INVALID_TRANSITION_PERSONAL_DETAILS:
      return { ...state, formStatus: FORM_STATUS.INVALID };

    // Title
    case TITLE_SET_VALUE:
      return updateFormField("title", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case TITLE_SET_VALID:
      return updateFormField("title", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // First names
    case FIRST_NAMES_SET_VALUE:
      return updateFormField("firstNames", (field) => ({
        ...field,
        displayValue: action.value,
        value: trimField(action.value),
      }));
    case FIRST_NAMES_SET_VALID:
      return updateFormField("firstNames", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // First names
    case SURNAME_SET_VALUE:
      return updateFormField("surname", (field) => ({
        ...field,
        displayValue: action.value,
        value: trimField(action.value),
      }));
    case SURNAME_SET_VALID:
      return updateFormField("surname", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case GENDER_SET_VALUE:
      return updateFormField("gender", (field) => ({
        ...field,
        displayValue: action.value?.value,
        value: action.value?.id,
      }));
    case GENDER_SET_VALID:
      return updateFormField("gender", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case ID_TYPE_SET_VALUE:
      return updateFormField("idType", (field) => ({
        ...field,
        displayValue: action.value?.value,
        value: action.value?.id,
      }));
    // ID Number
    case ID_SET_VALUE:
      return updateFormField("id", (field) => ({
        ...field,
        displayValue: action.value,
        value: action.value,
      }));
    case ID_SET_VALID:
      return updateFormField("id", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // ID Expiry
    case ID_EXPIRY_SET_VALUE:
      return updateFormField("idExpiry", (field) => ({
        ...field,
        displayValue: action.value,
        value: trimField(action.value),
      }));
    case ID_EXPIRY_SET_VALID:
      return updateFormField("idExpiry", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // ID Expiry
    case EDUCATION_SET_VALUE:
      return updateFormField("education", (field) => ({
        ...field,
        displayValue: action.value?.value,
        value: action.value?.id,
      }));
    case EDUCATION_SET_VALID:
      return updateFormField("education", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Mobile number
    case MOBILE_SET_VALUE:
      return updateFormField("mobileNumber", (field) => ({
        ...field,
        value: removeWhiteSpacesFromValue(action.value),
        displayValue: action.value,
      }));
    case MOBILE_SET_VALID:
      return updateFormField("mobileNumber", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Email
    case EMAIL_SET_VALUE:
      return updateFormField("email", (field) => ({
        ...field,
        displayValue: action.value,
        value: trimField(action.value),
      }));
    case EMAIL_SET_VALID:
      return updateFormField("email", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Address - street address
    case STREET_ADDRESS_SET_VALUE:
      return updateFormField("streetAddress", (field) => ({
        ...field,
        value: action.value,
      }));
    case STREET_ADDRESS_SET_VALID:
      return updateFormField("streetAddress", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Address - city
    case CITY_SET_VALUE:
      return updateFormField("city", (field) => ({
        ...field,
        value: action.value,
      }));
    case CITY_SET_VALID:
      return updateFormField("city", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Address - district
    case DISTRICT_SET_VALUE:
      return updateFormField("district", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case DISTRICT_SET_VALID:
      return updateFormField("district", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Address - country
    case COUNTRY_SET_VALUE:
      return updateFormField("country", (field) => ({
        ...field,
        value: action.value,
      }));
    case COUNTRY_SET_VALID:
      return updateFormField("country", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    //  Postal method - Street Address - street address
    case POSTAL_STREET_ADDRESS_SET_VALUE:
      return updateFormField("postalStreetAddress", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_STREET_ADDRESS_SET_VALID:
      return updateFormField("postalStreetAddress", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case POSTAL_STREET_ADDRESS_CLEAR_VALUE:
      return updateFormField("postalStreetAddress", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));

    // Postal method - Street Address - city
    case POSTAL_CITY_SET_VALUE:
      return updateFormField("postalCity", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_CITY_SET_VALID:
      return updateFormField("postalCity", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case POSTAL_CITY_CLEAR_VALUE:
      return updateFormField("postalCity", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    // Address - district
    case POSTAL_DISTRICT_SET_VALUE:
      return updateFormField("postalDistrict", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case POSTAL_DISTRICT_SET_VALID:
      return updateFormField("postalDistrict", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Postal method - Street Address - country
    case POSTAL_COUNTRY_SET_VALUE:
      return updateFormField("postalCountry", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_COUNTRY_SET_VALID:
      return updateFormField("postalCountry", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case POSTAL_COUNTRY_CLEAR_VALUE:
      return updateFormField("postalCountry", (field) => ({
        ...field,
        value: initCountry,
        error: null,
        status: clearCountryError(),
      }));

    // Loan details - Loan Amount
    case LOAN_AMOUNT_SET_VALUE:
      return updateFormField("loanAmount", (field) => ({
        ...field,
        value: action.value,
      }));
    case LOAN_AMOUNT_SET_VALID:
      return updateFormField("loanAmount", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Loan details - Loan Type
    case LOAN_TYPE_SET_VALUE:
      return updateFormField("loanType", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case LOAN_TYPE_SET_VALID:
      return updateFormField("loanType", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Loan details - Bank
    case BANK_SET_VALUE:
      return updateFormField("bank", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case BANK_SET_VALID:
      return updateFormField("bank", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Loan details - Loan Term
    case LOAN_TERM_SET_VALUE:
      return updateFormField("loanTerm", (field) => ({
        ...field,
        value: action.value,
      }));
    case LOAN_TERM_SET_VALID:
      return updateFormField("loanTerm", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    //Employment and Income Details - Employment Type
    case EMOPLOYMENT_TYPE_SET_VALUE:
      return updateFormField("employmentType", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case EMOPLOYMENT_TYPE_SET_VALID:
      return updateFormField("employmentType", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Employment and Income Details - Occupation
    case OCCUPATION_SET_VALUE:
      return updateFormField("occupation", (field) => ({
        ...field,
        value: action.value,
      }));
    case OCCUPATION_SET_VALID:
      return updateFormField("occupation", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    //Employment and Income Details - Sorce Of Income
    case INCOME_SOURCE_SET_VALUE:
      return updateFormField("sourceOfIncome", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case INCOME_SOURCE_VALID:
      return updateFormField("sourceOfIncome", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    //Employment and Income Details - Sorce Of Income Text
    case INCOME_SOURCE_TEXT_SET_VALUE:
      return updateFormField("sourceOfIncomeText", (field) => ({
        ...field,
        value: action.value,
      }));
    case INCOME_SOURCE_TEXT_VALID:
      return updateFormField("sourceOfIncomeText", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    //Payment Frequency
    case PAYMENT_FREQUENCY_SET_VALUE:
      return updateFormField("paymentFrequency", (field) => ({
        ...field,
        displayValue: action.value.value,
        value: action.value.id,
      }));
    case PAYMENT_FREQUENCY_SET_VALID:
      return updateFormField("paymentFrequency", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case RESET_STATE_TRANSITION_PERSONAL_DETAILS:
      return { ...initPersonalDetails };

    default:
      return state;
  }
};
