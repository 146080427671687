import { validateNumber } from '@om/validation';
import {
    setGenerateRegularIncomeOnceOffValid,
    setMonthlyValid,
    setMoneyMarketFundPercentValid,
    setBondFundPercentValid,
    MONEY_MARKET_FUND_PERCENT_SET_VALUE,
    MONTHLY_AMOUNT_SET_VALUE,
    ONCE_OFF_SET_VALUE,
    BOND_FUND_PERCENT_SET_VALUE,
} from '../../../actions/investmentCalculator/generateRegularIncome';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const generateRegularIncomeCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case MONTHLY_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 1000,
                    subject: 'monthly amount to save',
                    decimalPoints: 2,
                });
                return setMonthlyValid(error);

            case ONCE_OFF_SET_VALUE:
                error = validateNumber(action.value, { min: 1000, subject: 'initial value', decimalPoints: 2 });
                return setGenerateRegularIncomeOnceOffValid(error);

            case MONEY_MARKET_FUND_PERCENT_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 0,
                    max: 100,
                    subject: 'money market fund value',
                    decimalPoints: 2,
                });
                return setMoneyMarketFundPercentValid(error);

            case BOND_FUND_PERCENT_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 0,
                    max: 100,
                    subject: 'bond fund value',
                    decimalPoints: 2,
                });
                return setBondFundPercentValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
};
