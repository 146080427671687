import emojiaware from 'emoji-aware';
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

export function validateKeCarRegNumber(
    value,
    {
        min = config.KE_CAR_REG_NO,
        max = config.KE_CAR_REG_NO.MAX,
        required = true,
        subject = 'Car registration number',
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    // Ampersand has been removed
    const regEx = new RegExp(/^[K][a-zA-Z]{2}[0-9]{3}[a-zA-Z]{1}$/);

    if (required && !value ) { //&& value.trim() === '1') {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    if (min && (value.length < min)) {
        return OmValidationMessageHelper.getStringTooShortMsg(subject, min, pronoun, retainSubjectCase);
    }

    if (max && (value.length > max)) {
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    const invalidMessage = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    if (!regEx.test(value)) {
        return invalidMessage;
    }

    const emoji = emojiaware.onlyEmoji(value);
    if (emoji.length >= 1 ) {
        return invalidMessage;
    }

    return null;
}
