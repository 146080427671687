import { transitionTravelInsurancePaymentsPages } from '../../../actions/roaTravelInsurance/addTraveller';
import {
    getAddTravellerForm,
    getPaymentsForm,
    getSelectedForm,
} from '../../../selectors/roaTravelInsurance/payments';
import { FIELD_STATUS } from '../../../reducers/status';

export const addTravellerDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const selectedForm = getSelectedForm(store.getState());
    const addTravellerForm = getAddTravellerForm(store.getState());
    const paymentsForm = getPaymentsForm(store.getState());

    const addTravellerFormValid = validateTravelInsuranceFormDetails(
        addTravellerForm,
        selectedForm,
    );

    const paymentsFormValid = validateTravelInsuranceFormDetails(
        paymentsForm,
        selectedForm,
    );

    const addTravellerFormAction = addTravellerFormValid
        ? transitionTravelInsurancePaymentsPages.formOneValid()
        : transitionTravelInsurancePaymentsPages.formOneInvalid();

    const paymentsFormAction = paymentsFormValid
        ? transitionTravelInsurancePaymentsPages.formTwoValid()
        : transitionTravelInsurancePaymentsPages.formTwoInvalid();

    const formActions = {
        addTravellerFormAction,
        paymentsFormAction,
    };

    const formAction = getformAction(formActions, selectedForm);
    store.dispatch(formAction);

    return result;
};

const getformAction = (formActions, selectedForm) => {
    if (selectedForm === 'form-one') {
        return formActions.addTravellerFormAction;
    }
    if (selectedForm === 'form-two') {
        return formActions.paymentsFormAction;
    }
    return null;
};
const validateTravelInsuranceFormDetails = (formFields, selectedForm) => {
    if (selectedForm === 'form-one') {
        return (
            formFields.fullName &&
            formFields.fullName.status === FIELD_STATUS.VALID &&
            formFields.phone &&
            formFields.phone.status === FIELD_STATUS.VALID &&
            formFields.email &&
            formFields.email.status === FIELD_STATUS.VALID &&
            formFields.dob &&
            formFields.dob.status === FIELD_STATUS.VALID
        );
    }
    if (selectedForm === 'form-two') {
        return (
            (formFields.paymentsPhone &&
            formFields.paymentsPhone.status === FIELD_STATUS.VALID) ||
            (formFields.transactionCode && formFields.transactionCode.status === FIELD_STATUS.VALID)
        );
    }

    return true;
};
