// Transitions
export const CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER = 'covidSelfAssessment/wizardFooter/transition/disabledContinueButton';
export const CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER = 'covidSelfAssessment/wizardFooter/transition/visibleContinueButton';

export const transitionWizardFooter = {
    continueButtonDisabled: (value = true) => ({ type: CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER, value: value }),
    continueButtonVisible: (value = true) => ({ type: CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER, value: value }),
};

// Events
export const WIZARD_FOOTER_SET_VALUE = 'covidSelfAssessment/wizardFooter/setValue/';
export const STEP_INDEX_SET_VALUE = WIZARD_FOOTER_SET_VALUE + 'stepIndex';

export const setStepIndexValue = value => ({ type: STEP_INDEX_SET_VALUE, value });
