export const FUNERAL_CLAIM_PUBLIC_WEB = 'publicWeb/funeralClaimV2';
export const FUNERAL_CLAIM_SET_VALUE = `${FUNERAL_CLAIM_PUBLIC_WEB}/setValue`;
export const FUNERAL_CLAIM_SET_VALID = `${FUNERAL_CLAIM_PUBLIC_WEB}/setValid`;
export const FUNERAL_CLAIM_TOGGLE = `${FUNERAL_CLAIM_PUBLIC_WEB}/toggle`;

// Form Submit
export const FUNERAL_CLAIM_SUBMIT_FORM = `${FUNERAL_CLAIM_PUBLIC_WEB}/submit/form`;
export const submitForm = () => ({type: FUNERAL_CLAIM_SUBMIT_FORM});

// toggle side drawer
export const FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER = `${FUNERAL_CLAIM_SET_VALUE}/openSideDrawer`;
export const toggleFuneralClaimSideDrawer = value => ({ type: FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER, value });

// Duplicated for different reference in middleware
export const FUNERAL_CLAIM_V2_SET_VALUE = FUNERAL_CLAIM_SET_VALUE;
export const FUNERAL_CLAIM_V2_SET_VALID = FUNERAL_CLAIM_SET_VALID;
export const FUNERAL_CLAIM_V2_TOGGLE = FUNERAL_CLAIM_TOGGLE;
export const FUNERAL_CLAIM_V2_SUBMIT_FORM = FUNERAL_CLAIM_SUBMIT_FORM;
export const FUNERAL_CLAIM_V2_TOGGLE_SIDE_DRAWER = FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER

// Form Transition
export const FUNERAL_CLAIM_FORM_VALID_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/formTransition/valid`;
export const FUNERAL_CLAIM_FORM_INVALID_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/formTransition/invalid`;
export const formTransitionFuneralClaim = {
    formValid: () => ({ type: FUNERAL_CLAIM_FORM_VALID_TRANSITION }),
    formInvalid: () => ({ type: FUNERAL_CLAIM_FORM_INVALID_TRANSITION }),
};

// General Form Submission Progress
export const FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_PENDING = `${FUNERAL_CLAIM_PUBLIC_WEB}/allApiSubmissionStatus/pending`;
export const FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_SUCCESS = `${FUNERAL_CLAIM_PUBLIC_WEB}/allApiSubmissionStatus/success`;
export const FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_FAILURE = `${FUNERAL_CLAIM_PUBLIC_WEB}/allApiSubmissionStatus/failure`;
export const allApiSubmissionsStatus = {
    pending: () => ({ type: FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_PENDING }),
    success: () => ({ type: FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_SUCCESS }),
    failure: () => ({ type: FUNERAL_CLAIM_ALL_API_SUBMISSIONS_STATUS_FAILURE }),
};


// API Transition
export const FUNERAL_CLAIM_API_PENDING_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiTransition/pending`;
export const FUNERAL_CLAIM_API_SUCCESS_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiTransition/success`;
export const FUNERAL_CLAIM_API_FAILURE_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiTransition/failure`;
export const apiTransitionFuneralClaim = {
    pending: () => ({ type: FUNERAL_CLAIM_API_PENDING_TRANSITION }),
    success: () => ({ type: FUNERAL_CLAIM_API_SUCCESS_TRANSITION }),
    failure: () => ({ type: FUNERAL_CLAIM_API_FAILURE_TRANSITION }),
};

// First Name
export const FUNERAL_CLAIM_SET_FIRST_NAME_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/firstName`;
export const setFirstNameValue = value => ({type: FUNERAL_CLAIM_SET_FIRST_NAME_VALUE, value});
export const FUNERAL_CLAIM_SET_FIRST_NAME_VALID = `${FUNERAL_CLAIM_SET_VALID}/firstName`;
export const setFirstNameValid = error => ({type: FUNERAL_CLAIM_SET_FIRST_NAME_VALID, error});

// Surname
export const FUNERAL_CLAIM_SET_SURNAME_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/surname`;
export const setSurnameValue = value => ({type: FUNERAL_CLAIM_SET_SURNAME_VALUE, value});
export const FUNERAL_CLAIM_SET_SURNAME_VALID = `${FUNERAL_CLAIM_SET_VALID}/surname`;
export const setSurameValid = error => ({type: FUNERAL_CLAIM_SET_SURNAME_VALID, error});

// Requester ID Number
export const FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/requesterIdNumber`;
export const setRequesterIdValue = value => ({type: FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE, value});
export const FUNERAL_CLAIM_SET_REQUESTER_ID_VALID = `${FUNERAL_CLAIM_SET_VALID}/requesterIdNumber`;
export const setRequesterIdValid = error => ({type: FUNERAL_CLAIM_SET_REQUESTER_ID_VALID, error});

// Cellphone Number
export const FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/cellphoneNumber`;
export const setCellphoneNumberValue = (value, countryCode) => ({type: FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE, value, countryCode});
export const FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID = `${FUNERAL_CLAIM_SET_VALID}/cellphoneNumber`;
export const setCellphoneNumberValid = error => ({type: FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID, error});

// Email Address
export const FUNERAL_CLAIM_SET_EMAIL_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/email`;
export const setEmailValue = value => ({type: FUNERAL_CLAIM_SET_EMAIL_VALUE, value});
export const FUNERAL_CLAIM_SET_EMAIL_VALID = `${FUNERAL_CLAIM_SET_VALID}/email`;
export const setEmailValid = error => ({type: FUNERAL_CLAIM_SET_EMAIL_VALID, error});

// isUserPolicyHolder,
export const FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER = `${FUNERAL_CLAIM_SET_VALUE}/isUserPolicyHolder`;
export const setIsUserPolicyHolder = value => ({ type: FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER, value });

// is Deceased stillborn
export const FUNERAL_CLAIM_SET_IS_STILLBORN_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/isStillborn`;
export const toggleIsStillbornValue = () => ({type: FUNERAL_CLAIM_SET_IS_STILLBORN_VALUE});


// Deceased date of death
export const FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/dateOfDeath`;
export const setDateOfDeathValue = value => ({type: FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE, value});
export const FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALID = `${FUNERAL_CLAIM_SET_VALID}/dateOfDeath`;
export const setDateOfDeathValid = error => ({type: FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALID, error});

// Set the validity of stillborns date of birth and death in one action
export const FUNERAL_CLAIM_SET_DATE_OF_BIRTH_DEATH_VALID = `${FUNERAL_CLAIM_SET_VALID}/dateOfBirthAndDeath`;
export const setDateOfBirthAndDeathValid = (dateOfBirthError, dateOfDeathError) =>
    ({type: FUNERAL_CLAIM_SET_DATE_OF_BIRTH_DEATH_VALID, dateOfBirthError, dateOfDeathError});

// Set the validity of date of death and funeral date in one action
export const FUNERAL_CLAIM_SET_DATE_OF_DEATH_FUNERAL_VALID = `${FUNERAL_CLAIM_SET_VALID}/dateOfDeathAndFuneral`;
export const setDateOfDeathAndFuneralValid = (dateOfDeathError, dateOfFuneralError) =>
    ({type: FUNERAL_CLAIM_SET_DATE_OF_DEATH_FUNERAL_VALID, dateOfDeathError, dateOfFuneralError});

// Deceased ID Number
export const FUNERAL_CLAIM_SET_DECEASED_ID_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/deceasedIdNumber`;
export const setDeceasedIdValue = value => ({type: FUNERAL_CLAIM_SET_DECEASED_ID_VALUE, value});
export const FUNERAL_CLAIM_SET_DECEASED_ID_VALID = `${FUNERAL_CLAIM_SET_VALID}/deceasedIdNumber`;
export const setDeceasedIdValid = error => ({type: FUNERAL_CLAIM_SET_DECEASED_ID_VALID, error});

// Set the validity of deceased and requester IDs in one action
export const FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID = `${FUNERAL_CLAIM_SET_VALID}/deceasedRequesterIdNumber`;
export const setDeceasedRequesterIdValid = (deceasedError, requesterError) =>
    ({type: FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID, deceasedError, requesterError});

// Set the validity of requester and account holder ID's in one action
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_REQUESTER_ID_VALID = `${FUNERAL_CLAIM_SET_VALID}/accountHolderRequesterIdNumber`;
export const setAccountHolderRequesterIdValid = (bankAccountHolderError, requesterError) => ({
    type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_REQUESTER_ID_VALID,
    bankAccountHolderError,
    requesterError
});

// Cause of death as on death certificate
export const FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/causeOfDeath`;
export const setCauseOfDeathValue = value => ({type: FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE, value});
export const FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALID = `${FUNERAL_CLAIM_SET_VALID}/causeOfDeath`;
export const setCauseOfDeathValid = error => ({type: FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALID, error});

// Reason for unnatural death
export const FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/reasonForUnnaturalDeath`;
export const setReasonForUnnaturalDeathValue = value => ({type: FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE, value});
export const FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALID = `${FUNERAL_CLAIM_SET_VALID}/reasonForUnnaturalDeath`;
export const setReasonForUnnaturalDeathValid = error => ({type: FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALID, error});

// Reason for death as on notice of death form
export const FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/reasonForDeath`;
export const setReasonForDeathValue = value => ({type: FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE, value});
export const FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALID = `${FUNERAL_CLAIM_SET_VALID}/reasonForDeath`;
export const setReasonForDeathValid = error => ({type: FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALID, error});

// Funeral Date
export const FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/funeralDate`;
export const setFuneralDateValue = value => ({type: FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE, value});
export const FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID = `${FUNERAL_CLAIM_SET_VALID}/funeralDate`;
export const setFuneralDateValid = error => ({type: FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID, error});

// Bank
export const FUNERAL_CLAIM_SET_BANK_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bank`;
export const setBankValue = value => ({type: FUNERAL_CLAIM_SET_BANK_VALUE, value});
export const FUNERAL_CLAIM_SET_BANK_VALID = `${FUNERAL_CLAIM_SET_VALID}/bank`;
export const setBankValid = error => ({type: FUNERAL_CLAIM_SET_BANK_VALID, error});

// Bank name if other
export const FUNERAL_CLAIM_SET_BANK_OTHER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankOther`;
export const setBankOtherValue = value => ({type: FUNERAL_CLAIM_SET_BANK_OTHER_VALUE, value});
export const FUNERAL_CLAIM_SET_BANK_OTHER_VALID = `${FUNERAL_CLAIM_SET_VALID}/bankOther`;
export const setBankOtherValid = error => ({type: FUNERAL_CLAIM_SET_BANK_OTHER_VALID, error});

// Branch Code
export const FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/branchCode`;
export const setBranchCodeValue = value => ({type: FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE, value});
export const FUNERAL_CLAIM_SET_BRANCH_CODE_VALID = `${FUNERAL_CLAIM_SET_VALID}/branchCode`;
export const setBranchCodeValid = error => ({type: FUNERAL_CLAIM_SET_BRANCH_CODE_VALID, error});

// Bank Account Number
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankAccountNumber`;
export const setBankAccountNumberValue = value => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE, value});
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID = `${FUNERAL_CLAIM_SET_VALID}/bankAccountNumber`;
export const setBankAccountNumberValid = error => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID, error});

// Bank Account Type
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankAccountType`;
export const setBankAccountTypeValue = value => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE, value});
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALID = `${FUNERAL_CLAIM_SET_VALID}/bankAccountType`;
export const setBankAccountTypeValid = error => ({ type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALID, error});

// Bank Account Holder flag
export const FUNERAL_CLAIM_SET_IS_USER_BANK_ACCOUNT_HOLDER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/isUserBankAccountHolder`;
export const setIsBankAccountHolderValue = value => ({ type: FUNERAL_CLAIM_SET_IS_USER_BANK_ACCOUNT_HOLDER_VALUE, value });

// Bank Account Holder Id Number
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankAccountHolderIdNumber`;
export const setBankAccountHolderIdNumberValue = value => ({ type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALUE, value });
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALID = `${FUNERAL_CLAIM_SET_VALID}/bankAccountHolderIdNumber`;
export const setBankAccountHolderIdNumberValid = error => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALID, error });

// Bank Account Holder Name
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankAccountHolderName`;
export const setBankAccountHolderNameValue = value => ({ type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALUE, value });
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALID = `${FUNERAL_CLAIM_SET_VALID}/bankAccountHolderName`;
export const setBankAccountHolderNameValid = error => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALID, error });

// Notice of Death Document
export const FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/noticeOfDeathDocument`;
export const setNoticeOfDeathDocument = value => ({ type: FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALUE, value });
export const FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALID = `${FUNERAL_CLAIM_SET_VALID}/noticeOfDeathDocument`;
export const setNoticeOfDeathDocumentValid = error => ({ type: FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALID, error });

// Remove notice of Death Document
export const FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/removeNoticeOfDeathDocument`;
export const setRemoveNoticeOfDeathDocument = value => ({ type: FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALUE, value });
export const FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALID = `${FUNERAL_CLAIM_SET_VALID}/removeNoticeOfDeathDocument`;
export const setRemoveNoticeOfDeathDocumentValid = error => ({ type: FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALID, error });

// Proof of banking Document
export const FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/proofOfBankingDocument`;
export const setProofOfBankingDocument = value => ({ type: FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALUE, value });
export const FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALID = `${FUNERAL_CLAIM_SET_VALID}/proofOfBankDocument`;
export const setProofOfBankDocumentValid = error => ({ type: FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALID, error });

// Remove proof of banking Document
export const FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/removeProofOfBankingDocument`;
export const setRemoveProofOfBankingDocument = value => ({ type: FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALUE, value });
export const FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALID = `${FUNERAL_CLAIM_SET_VALID}/removeProofOfBankDocument`;
export const setRemoveProofOfBankDocumentValid = error => ({ type: FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALID, error });

// Agree to Terms
export const FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS = `${FUNERAL_CLAIM_TOGGLE}/agreeToTerms`;
export const toggleAgreetoTerms = () => ({ type: FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS });

//ReCaptcha
export const FUNERAL_CLAIM_RECAPTCHA_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/recaptcha`;
export const setRecaptcha = value => ({type: FUNERAL_CLAIM_RECAPTCHA_VALUE, value});

// DHA impl.
export const FUNERAL_CLAIM_DHA_API_PENDING = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiDha/pending`;
export const FUNERAL_CLAIM_DHA_API_SUCCESS = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiDha/success`;
export const FUNERAL_CLAIM_DHA_API_FAILURE = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiDha/failure`;
export const dhaApiFuneralClaimVerification = {
    pending: () => ({ type: FUNERAL_CLAIM_DHA_API_PENDING }),
    success: () => ({ type: FUNERAL_CLAIM_DHA_API_SUCCESS }),
    failure: () => ({ type: FUNERAL_CLAIM_DHA_API_FAILURE }),
};
export const FUNERAL_CLAIM_DHA_API_REQUEST =  `${FUNERAL_CLAIM_PUBLIC_WEB}/deceasedDhaVerify`;
export const verifyDeceasedIdDha = () => ({ type: FUNERAL_CLAIM_DHA_API_REQUEST });
export const FUNERAL_CLAIM_SET_DECEASED_DHA_VERIFICATION = `${FUNERAL_CLAIM_SET_VALUE}/deceasedDha`;
export const setDeceasedVerifiedDha = value => ({ type: FUNERAL_CLAIM_SET_DECEASED_DHA_VERIFICATION, value });

//CDV Bank Account Verify
export const FUNERAL_CLAIM_CDV_API_PENDING = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiCdv/pending`;
export const FUNERAL_CLAIM_CDV_API_SUCCESS = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiCdv/success`;
export const FUNERAL_CLAIM_CDV_API_FAILURE = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiCdv/failure`;
export const cdvApiFuneralClaimVerification = {
    pending: () => ({ type: FUNERAL_CLAIM_CDV_API_PENDING }),
    success: () => ({ type: FUNERAL_CLAIM_CDV_API_SUCCESS }),
    failure: () => ({ type: FUNERAL_CLAIM_CDV_API_FAILURE }),
};
export const FUNERAL_CLAIM_BANK_CDV_REQUEST = `${FUNERAL_CLAIM_PUBLIC_WEB}/cdvbankVerify`;
export const performCdvBankAccountCheck = () => ({ type: FUNERAL_CLAIM_BANK_CDV_REQUEST });
export const FUNERAL_CLAIM_SET_BANK_CDV_VERIFICATION = `${FUNERAL_CLAIM_SET_VALUE}/bankCdv`;
export const setCdvVerifiedBankAccount   = (value) => ({ type: FUNERAL_CLAIM_SET_BANK_CDV_VERIFICATION, value });

// Presubmit Claims API 
export const FUNERAL_CLAIM_PRESUBMIT_API_PENDING = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiPresubmit/pending`;
export const FUNERAL_CLAIM_PRESUBMIT_API_SUCCESS = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiPresubmit/success`;
export const FUNERAL_CLAIM_PRESUBMIT_API_FAILURE = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiPresubmit/failure`;
export const preSubmitApiFuneralClaim = {
    pending: () => ({ type: FUNERAL_CLAIM_PRESUBMIT_API_PENDING }),
    success: () => ({ type: FUNERAL_CLAIM_PRESUBMIT_API_SUCCESS }),
    failure: () => ({ type: FUNERAL_CLAIM_PRESUBMIT_API_FAILURE }),
};
export const FUNERAL_CLAIM_PRESUBMIT_REQUEST = `${FUNERAL_CLAIM_PUBLIC_WEB}/presubmit`;
export const performPreSubmitServiceRequest = () => ({ type: FUNERAL_CLAIM_PRESUBMIT_REQUEST });
export const FUNERAL_CLAIM_SET_PRESUBMIT = `${FUNERAL_CLAIM_SET_VALUE}/presubmit`;
export const setPreSubmitServiceRequest  = (value) => ({ type: FUNERAL_CLAIM_SET_PRESUBMIT, value });