import {
    getBeneficiaryList,
    getDateOfBirth,
    getEditBeneficiaryForm,
    getEditBeneficiaryFormIsValid,
    getEmail,
    getFirstName,
    getGender,
    getMobileNumber,
    getSurname,
    getTitle
} from "../../../selectors/raOptimal/beneficiaries";
import {FIELD_STATUS} from "../../../reducers/status";
import {
    DELETE_TRANSITION_BENEFICIARIES,
    EMAIL_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    SAVE_TRANSITION_BENEFICIARIES,
    setDateOfBirthValue,
    setEmailValid,
    setEmailValue,
    setFirstNameValue,
    setGenderValue,
    setMobileValid,
    setMobileValue,
    setSurnameValue,
    setTitleValue,
    transitionBeneficiaries,
} from "../../../actions/raOptimal/beneficiaries";
import {
    deselectEvenSplit,
    discardEvenSplit,
    evenSplitSingleBeneficiary,
    formInitTransitionBeneficiariesSplit,
    setTotalPercentageValue,
} from "../../../actions/raOptimal/beneficiariesSplit";

export const beneficiaryTransitionRules = (store, next, action) => {
    const state = store.getState();
    const result = next(action);
    const beneficiaries = getBeneficiaryList(state);
    
    if (beneficiaries.length === 1) {
        store.dispatch(evenSplitSingleBeneficiary(beneficiaries[0].id))
    } else if (beneficiaries.length > 1) {
        store.dispatch(formInitTransitionBeneficiariesSplit(beneficiaries));
    }

    return result;
};

export const beneficiarySaveDeleteRules = (store, next, action) => {
    // state before middleware is applied
    const state = store.getState();
    const result = next(action);
    const resetSplitPercentageValues = () => {
        // when any beneficiary changes are saved or beneficiaries are deleted, remove all set split percentage
        // values on the next page
        store.dispatch(deselectEvenSplit());
        store.dispatch(discardEvenSplit());
        store.dispatch(setTotalPercentageValue(0));
    };

    if (action.type === SAVE_TRANSITION_BENEFICIARIES) {
        const beneficiaryFormIsValid = getEditBeneficiaryFormIsValid(state);
        if (beneficiaryFormIsValid) {
            resetSplitPercentageValues();
            return result;
        }

        // reset the field values -- this also forces a validation check, in case form is invalid
        store.dispatch(setTitleValue(getTitle(state).value));
        store.dispatch(setFirstNameValue(getFirstName(state).value));
        store.dispatch(setSurnameValue(getSurname(state).value));
        store.dispatch(setDateOfBirthValue(getDateOfBirth(state).value));
        store.dispatch(setGenderValue(getGender(state).value));

        if (!getEmail(state).value) store.dispatch(setEmailValue(getEmail(state).value));
        if (!getMobileNumber(state).value) store.dispatch(setMobileValue(getMobileNumber(state).value));
    }

    if (action.type === DELETE_TRANSITION_BENEFICIARIES)
        resetSplitPercentageValues();

    return result;
};

export const beneficiariesFormRules = (store, next, action) => {
    // state after middleware is applied
    const result = next(action);
    const state = store.getState();
    const beneficiaryForm = getEditBeneficiaryForm(state);
    const beneficiaryIsValid = validateBeneficiary(beneficiaryForm);

    const formAction = beneficiaryIsValid
        ? transitionBeneficiaries.formValid()
        : transitionBeneficiaries.formInvalid()

    store.dispatch(formAction);

    if (action.type === MOBILE_NUMBER_SET_VALUE || action.type === EMAIL_SET_VALUE) {

        const mobileField = getMobileNumber(state);
        const emailField = getEmail(state);

        if (mobileField.value && !emailField.value) {
            store.dispatch(setEmailValid(null));
        }

        if (emailField.value && !mobileField.value) {
            store.dispatch(setMobileValid(null));
        }
    }


    return result;
};

const validateBeneficiary = formFields => {
    const validField = name => formFields[name] && formFields[name].status === FIELD_STATUS.VALID;
    const emptyField = name => formFields[name] && (formFields[name].value === null || formFields[name].value === '');
    const nonEmptyValidField = name => validField(name) && formFields[name].value;
    const validContactDetails = (
        (nonEmptyValidField('mobileNumber') && nonEmptyValidField('email')) ||
        (nonEmptyValidField('mobileNumber') && emptyField('email') ) ||
        (emptyField('mobileNumber') && nonEmptyValidField('email'))
    );

    return (
        validField('title') &&
        validField('firstName') &&
        validField('surname') &&
        validField('dateOfBirth') &&
        validField('gender') &&
        validContactDetails
    );
};
