const SCROLLING_TRANSITION = 'taxFreeSavingsAccount/scrolling/transition/'

export const SCROLL_TO_CLASS_TRANSITION_TFSA = SCROLLING_TRANSITION + 'scrollToClass';
export const SCROLL_TO_ID_TRANSITION_TFSA = SCROLLING_TRANSITION + 'scrollToId';
export const SCROLL_TO_ERROR_TRANSITION_TFSA = SCROLLING_TRANSITION + 'scrollToError';
export const CLEAR_SCROLL_TRANSITION_TFSA = SCROLLING_TRANSITION + 'resetScroll';
export const scrollToClass = value => ({ type: SCROLL_TO_CLASS_TRANSITION_TFSA, value });
export const scrollToId = value => ({ type: SCROLL_TO_ID_TRANSITION_TFSA, value });
export const scrollToError = (array, form) => ({ type: SCROLL_TO_ERROR_TRANSITION_TFSA, array, form });
export const resetScroll = () => ({ type: CLEAR_SCROLL_TRANSITION_TFSA });
