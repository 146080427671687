import emojiaware from 'emoji-aware';
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

export function validateKraPinNumber(
    value,
    {
        min = config.KRA_PIN_NO.MIN,
        max = config.KRA_PIN_NO.MAX,
        required = true,
        subject = 'KRA PIN NUMBER',
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    // Ampersand has been removed
    const regEx = new RegExp(/^[a-zA-Z]{1}[0-9]{9}[a-zA-Z]{1}$/);

    if (required && !value ) { //&& value.trim() === '1') {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    if (min && (value.length < min)) {
        return OmValidationMessageHelper.getStringTooShortMsg(subject, min, pronoun, retainSubjectCase);
    }

    if (max && (value.length > max)) {
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    const invalidMessage = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    if (!regEx.test(value)) {
        return invalidMessage;
    }

    const emoji = emojiaware.onlyEmoji(value);
    if (emoji.length >= 1 ) {
        return invalidMessage;
    }

    return null;
}