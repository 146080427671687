import axios from 'axios';

import {
    apiTransitionEmergencyFundCalculator,
    CALCULATE_EMERGENCY_FUND
} from '../../../actions/investmentCalculator/emergencyFund'

import {
    setViewInvestmentResult,
} from '../../../actions/investmentCalculator/investmentCalculator'

import {
    getMonthlyAmountValue,
    getAmountOnceOffValue,
    getIsApiPending
} from '../../../selectors/investmentCalculator/emergencyFundCalculator'


export const emergencyFundCalculateService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    if(action.type === CALCULATE_EMERGENCY_FUND) {
        (async () => {
            if(!getIsApiPending(state)) {
                store.dispatch(apiTransitionEmergencyFundCalculator.pending());
                try {
                    const response = await calculate(state)
                    store.dispatch(setViewInvestmentResult(response?.data))
                    store.dispatch(apiTransitionEmergencyFundCalculator.success())
                } catch (e) {
                    store.dispatch(setViewInvestmentResult(null))
                    store.dispatch(apiTransitionEmergencyFundCalculator.failure())
                }
            }
        })();
    }
    return result
}

export const calculate = async(state) => {
    const data = {
        goal: 1,
        productitems: [{id: 24, }],
        initial_amount: getAmountOnceOffValue(state).value,
        monthly_savings:  getMonthlyAmountValue(state).value
    }

    try {
        const url = '/om-api/investment-calculator/emergency-fund'
        return  await axios.post(url, data)
    } catch (error) {
        return null
    }
}
