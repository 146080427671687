import { getCoverType, getQuoteForm, getSelectedChildCoverOption } from '../../../selectors/personalAccident/quote';
import { getPersonalDetailsForm } from '../../../selectors/personalAccident/personalDetails';
import { getSpouseDetailsForm } from '../../../selectors/personalAccident/spouseDetails';
import { getChildDetailsForms } from '../../../selectors/personalAccident/childDetails';
import { getDocumentsForm } from '../../../selectors/personalAccident/documents';
import { getDeclarationsForm } from '../../../selectors/personalAccident/declarations';
import { getNextOfKinForm } from '../../../selectors/personalAccident/nextOfKin';
import { getIsApiPending } from '../../../selectors/personalAccident/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/personalAccident/payloadCollection';
import { getAgentForm } from '../../../selectors/ugMotorInsurance/travelAgentModal';
import { getAccessToken, getPaymentStatusData, getPaymentMode } from '../../../selectors/personalAccident/payments';
import axios from 'axios';
import { triggerConfirmOrder, setBankDetailsSuccess } from '../../../actions/personalAccident/payments';
import { getStatusApiCallStatus } from '../../../selectors/personalAccident/payments';
import { getPremiumCalculation } from '../../../selectors/personalAccident/quote';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await makeSubmission(store.getState());

                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());

                const modeOfPayment = getPaymentMode(store.getState());

                if (modeOfPayment === 'Mpesa') {
                    store.dispatch(triggerConfirmOrder());
                } else {
                    store.dispatch(setBankDetailsSuccess(true));
                }
            } catch (e) {
                console.error(e);
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

const createDependants = ({ childrenIncluded, childDetails }) => {
    const dependants = [];

    if (childrenIncluded) {
        for (child in childDetails.forms) {
            dependants.push({
                fullName:
                    child.firstName?.value && child.lastName?.value
                        ? `${child.firstName?.value} ${child.lastName?.value}`
                        : 'N/A',
                gender: 'N/A',
                relationship: 'Child',
                dateOfBirth: `${child.dob?.value}` || 'N/A',
                profession: 'N/A',
                lastExpenseSplit: 10,
            });
        }
    }

    return dependants;
};

const payloadCollection = (state) => {
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const spouseDetails = getSpouseDetailsForm(state);
    const childDetails = getChildDetailsForms(state);
    const documents = getDocumentsForm(state);
    const declarations = getDeclarationsForm(state);
    const nextOfKin = getNextOfKinForm(state);
    const paymentMode = getPaymentMode(state);

    const paymentsData = getPaymentStatusData(state);
    const coverType = getCoverType(state);
    const premiumCalculation = getPremiumCalculation(state);
    const childCoverPremium = getSelectedChildCoverOption(state);

    const paymentStatus = getStatusApiCallStatus(state);

    const dependants = createDependants({
        childrenIncluded: quotation.childrenIncluded?.value === 'yes',
        childDetails,
    });

    const payload = {
        countryCode: gatsbyStack.toUpperCase(),
        coverType,
        fullName: `${personalDetails.firstName.value} ${personalDetails.lastName.value}`,
        dateOfBirth: quotation.dob.value,
        idNumber: personalDetails.idNumber.value,
        gender: 'N/A',
        maritalStatus: quotation.spouseIncluded?.value === 'yes' ? 'MARRIED' : 'SINGLE',
        numberOfChildren: childDetails.length,
        spouseIncluded: quotation.spouseIncluded.value == 'yes',
        spouseFullName:
            quotation.spouseIncluded.value == 'yes'
                ? `${spouseDetails.firstName?.value} ${spouseDetails.lastName?.value}`
                : 'N/A',
        spouseDateOfBirth: quotation.spouseIncluded.value == 'yes' ? spouseDetails.dob?.value : 'N/A',
        spouseTaxPinNumber: spouseDetails.tinNumber?.value ? spouseDetails.tinNumber?.value : 'N/A',
        spouseAddress:
            quotation.spouseIncluded?.value == 'yes'
                ? `${spouseDetails.streetAddress?.value}, ${spouseDetails.suburb?.value}, ${spouseDetails.city?.value}`
                : 'N/A',
        spousePhoneNumber: spouseDetails.phoneNumber?.value ? spouseDetails.phoneNumber?.value : 'N/A',
        taxPinNumber: personalDetails.tinNumber.value,
        nhifNumber: 'N/A',
        profession: quotation.profession?.value || 'N/A',
        nationality: 'Kenyan',
        phoneNumber: personalDetails.phoneNumber.value,
        emailAddress: personalDetails.email?.value || 'N/A',
        postalAddress: 'N/A',
        postalCode: 'N/A',
        city: `${personalDetails.suburb.value}, ${personalDetails.city.value} `,
        country: 'Kenya',
        coverLimit: parseInt(quotation.coverLimit.value),
        annualPremium: premiumCalculation?.value ? premiumCalculation?.value : 0,
        childCoverPremium: childCoverPremium.value?.cover_premium ? childCoverPremium.value.cover_premium : 0,
        nationalIdCopy: documents.idUpload.value.base64File,
        taxPinCertificate: documents.kraPinUpload.value.base64File,
        planId: 1,
        paymentReference:
            paymentStatus === 'success'
                ? paymentsData?.CheckoutRequestID
                    ? paymentsData.CheckoutRequestID
                    : 'N/A'
                : 'N/A',
        nextOfKins: [
            {
                fullName: `${nextOfKin?.firstName?.value || 'N/A'} ${nextOfKin?.lastName?.value || 'N/A'}`,
                relationship: nextOfKin.relationship.value || 'N/A',
                emailAddress: nextOfKin.email.value || 'N/A',
                gender: 'N/A',
                idNumber: nextOfKin.idNumber.value || 'N/A',
                phoneNumber: 'N/A',
                dateOfBirth: 'N/A',
                address: 'N/A',
            },
        ],
        dependants,
        //declarations
        hasPreviouslyHeldPolicy: declarations.hasPreviouslyHeldPolicy?.value === 'yes',
        previousInsuranceName:
            declarations.hasPreviouslyHeldPolicy?.value === 'yes' ? declarations.previousInsurerName?.value : 'N/A',
        previousInsuranceAddress:
            declarations.hasPreviouslyHeldPolicy?.value === 'yes' ? declarations.hasPreviouslyHeldPolicy?.value : 'N/A',
        previousInsuranceBranch:
            declarations.hasPreviouslyHeldPolicy?.value === 'yes' ? declarations.previousInsurerBranch?.value : 'N/A',
        previousInsurancePolicyNumber:
            declarations.hasPreviouslyHeldPolicy?.value === 'yes' ? declarations.previousInsurerPolicyNo?.value : 'N/A',
        hasAdditionalPolicies: declarations.isAdditionalPolicy?.value === 'yes',
        numberOfAdditionalPolicies:
            declarations.isAdditionalPolicy?.value === 'yes' && declarations.additionalPolicyNumber?.value
                ? declarations.additionalPolicyNumber?.value
                : 0,
        additionalPoliciesDeathBenefitTotal:
            declarations.isAdditionalPolicy?.value === 'yes'
                ? declarations.additionalPolicyTotalDeathBenefit?.value
                : 0,
        hasHadProposalDeclined: declarations.declinedProposal?.value === 'yes',
        hasBeenDeniedRenewal: declarations.refusedRenewal?.value === 'yes',
        hasTerminatedInsuranceBefore: declarations.terminatedInsurance?.value === 'yes',
        terminatedInsuranceDetails:
            declarations.terminatedInsurance?.value === 'yes' && declarations.terminatedInsuranceDetails?.value
                ? declarations.terminatedInsuranceDetails?.value
                : 'N/A',
        hasRequiredPremiumIncrease: declarations.requiredPremiumIncrease?.value === 'yes',
        imposedSpecialConditions: declarations.imposedSpecialConditions?.value === 'yes',
        modeOfPayment: paymentMode,
    };

    //agent details
    const agent = getAgentForm(state);
    const agentPayload = {
        agentCode: agent.agentCode?.value || 'N/A',
        agentName: agent.name?.value || 'N/A',
        referredByAgent: agent.name?.value ? true : false,
    };

    return {
        ...payload,
        ...agentPayload,
    };
};

const makeSubmission = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/personal-accident/submit-payload';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
