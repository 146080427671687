export const BIASHARA_FLEXI_COMPANY_DETAILS_FORM = 'biasharaFlexi/companyDetailsForm/';

export const BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM + 'setValue';
export const BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM + 'setValid';

export const FORM_VALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM = 'biasharaFlexi/companyDetailsForm/transition/formValid';
export const FORM_INVALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM = 'biasharaFlexi/companyDetailsForm/transition/formInValid';

export const REINITIALIZE = 'biasharaFlexi/companyDetailsForm/state/reinitialize';

export const transitionBiasharaFlexiCompanyDetails = {
	formValid: () => ({
		type: FORM_VALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_BIASHARA_FLEXI_COMPANY_DETAILS_FORM,
	}),
};

export const COMPANY_NAME_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "companyName";
export const COMPANY_NAME_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "companyName";

export const EMAIL_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "email";
export const EMAIL_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "email";

export const MOBILE_NUMBER_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "mobileNumber";
export const MOBILE_NUMBER_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "mobileNumber";

export const KRA_PIN_NUMBER_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "kraPinNumber";
export const KRA_PIN_NUMBER_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "kraPinNumber";

export const CITY_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "city";
export const CITY_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "city";

export const STREET_ADDRESS_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "streetAddress";
export const STREET_ADDRESS_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "streetAddress";

export const BUILDING_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "building";
export const BUILDING_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "building";

export const PO_BOX_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "postalBox";
export const PO_BOX_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "postalBox";

export const POSTAL_CODE_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "postalCode";
export const POSTAL_CODE_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "postalCode";

export const FIRST_NAME_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "firstName";
export const FIRST_NAME_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "firstName";

export const SURNAME_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "surname";
export const SURNAME_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "surname";

export const ID_PASSPORT_SET_VALUE = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE + "idPassportNumber";
export const ID_PASSPORT_SET_VALID = BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID + "idPassportNumber";

// Action Creators

export const setFirstNameValue = value => ({
    type: FIRST_NAME_SET_VALUE,
    value
})

export const setFirstNameValid = error => ({
    type: FIRST_NAME_SET_VALID,
    error
})

export const setSurnameValue = value => ({
    type: SURNAME_SET_VALUE,
    value
})

export const setSurnameValid = error => ({
    type: SURNAME_SET_VALID,
    error
})

export const setIdPassortNumberValue = value => ({
    type: ID_PASSPORT_SET_VALUE,
    value
})

export const setIdPassortNumberValid = error => ({
    type: ID_PASSPORT_SET_VALID,
    error
})

export const setCompanyNameValue = value => ({
    type: COMPANY_NAME_SET_VALUE,
    value
})

export const setCompanyNameValid = error => ({
    type: COMPANY_NAME_SET_VALID,
    error
})

export const setEmailValue = value => ({
    type: EMAIL_SET_VALUE,
    value
})

export const setEmailValid = error => ({
    type: EMAIL_SET_VALID,
    error
})

export const setMobileNumberValue = value => ({
    type: MOBILE_NUMBER_SET_VALUE,
    value
})

export const setMobileNumberValid = error => ({
    type: MOBILE_NUMBER_SET_VALID,
    error
})

export const setKraPinNumberValue = value => ({
    type: KRA_PIN_NUMBER_SET_VALUE,
    value
})

export const setKraPinNumberValid = error => ({
    type: KRA_PIN_NUMBER_SET_VALID,
    error
})

export const setCityValue = value => ({
    type: CITY_SET_VALUE,
    value
})

export const setCityValid = error => ({
    type: CITY_SET_VALID,
    error
})

export const setStreetAddressValue = value => ({
    type: STREET_ADDRESS_SET_VALUE,
    value
})

export const setStreetAddressValid = error => ({
    type: STREET_ADDRESS_SET_VALID,
    error
})

export const setBuildingValue = value => ({
    type: BUILDING_SET_VALUE,
    value,
})

export const setBuildingValid = error => ({
    type: BUILDING_SET_VALID,
    error,
})

export const setPostalBoxValue = value => ({
    type: PO_BOX_SET_VALUE,
    value,
})

export const setPostalBoxValid = error => ({
    type: PO_BOX_SET_VALID,
    error
})

export const setPostalCodeValue = value => ({
    type: POSTAL_CODE_SET_VALUE,
    value,
});

export const setPostalCodeValid = error => ({
    type: POSTAL_CODE_SET_VALID,
    error
})

