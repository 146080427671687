import { TOGGLE_DISPLAY_RESULTS_CARD } from '../../../actions/budgetBenchmarkCalculator/calculator';
import { fireGtmEvent } from '../gtmEvents';

import { findOtherEventIndex, otherEventTracking } from "../otherEventTracking";

export const budgetBenchmarkFormTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type) {
            case TOGGLE_DISPLAY_RESULTS_CARD:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                fireGtmEvent('calculator_complete', { calculator_type: 'Budget Benchmarking Tool' });
                return 'Budget benchmark calculation';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}
