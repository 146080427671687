import {
    API_FAILURE_TRANSITION_GET_FUNDS,
    API_FAILURE_TRANSITION_GET_FUND_NAMES,
    API_PENDING_TRANSITION_GET_FUNDS,
    API_PENDING_TRANSITION_GET_FUND_NAMES,
    API_SUCCESS_TRANSITION_GET_FUNDS,
    API_SUCCESS_TRANSITION_GET_FUND_NAMES,
    FUNDS_DATA_SET_VALUE,
    FUND_NAMES_SET_VALUE,
    FUND_SELECTION_SET_VALUE,
    MODAL_TYPE_CLEAR_VALUE,
    MODAL_TYPE_SET_VALUE,
    PAGINATION_SIZE_SET_VALUE,
} from '../../actions/fundDeclarations/fundDeclarations';
import { API_CALL_STATUS } from '../status';

export const initialFundDeclarationState = {
    fundData: [],
    fundNames: [],
    paginationRanges: [10, 50, 100, 300, 500, 700],
    paginationTableSize: 10,
    apiCallStatus: API_CALL_STATUS.IDLE,
    apiNameCallStatus: API_CALL_STATUS.IDLE,
    modalType: null,
    form: {
        selectedFundId: null,
    },
};

export default (state = initialFundDeclarationState, action) => {
    switch (action.type) {
        case API_SUCCESS_TRANSITION_GET_FUNDS:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_PENDING_TRANSITION_GET_FUNDS:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };

        case API_FAILURE_TRANSITION_GET_FUNDS:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_SUCCESS_TRANSITION_GET_FUND_NAMES:
            return {
                ...state,
                apiNameCallStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_PENDING_TRANSITION_GET_FUND_NAMES:
            return {
                ...state,
                apiNameCallStatus: API_CALL_STATUS.PENDING,
            };

        case API_FAILURE_TRANSITION_GET_FUND_NAMES:
            return {
                ...state,
                apiNameCallStatus: API_CALL_STATUS.FAILURE,
            };

        case FUNDS_DATA_SET_VALUE:
            return {
                ...state,
                fundData: action.value,
            };

        case FUND_NAMES_SET_VALUE:
            return {
                ...state,
                fundNames: action.value,
            };

        case FUND_SELECTION_SET_VALUE:
            return {
                ...state,
                form: {
                    ...state.form,
                    selectedFundId: action.value,
                },
            };

        case PAGINATION_SIZE_SET_VALUE:
            return {
                ...state,
                paginationTableSize: action.value,
            };

        case MODAL_TYPE_SET_VALUE:
            return {
                ...state,
                modalType: action.value,
            };

        case MODAL_TYPE_CLEAR_VALUE:
            return {
                ...state,
                modalType: null,
            };

        default:
            return state;
    }
};
