import { PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE } from '../../../actions/personalAccident/quote';
import { quoteFormValidation } from './quote';

import { PERSONAL_ACCIDENT_PERSONAL_DETAILS_FORM_SET_VALUE } from '../../../actions/personalAccident/personalDetails';
import { personalDetailsValidation } from './personalDetails';

import { PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE } from '../../../actions/personalAccident/childDetails';
import { childDetailsValidation } from './childDetails';

import { PERSONAL_ACCIDENT_SPOUSE_DETAILS_FORM_SET_VALUE } from '../../../actions/personalAccident/spouseDetails';
import { spouseDetailsValidation } from './spouseDetails';

import { PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE } from '../../../actions/personalAccident/nextOfKin';
import { nextOfKinValidation } from './nextOfKin';

import { PERSONAL_ACCIDENT_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/personalAccident/Documents';
import { documentsUploadValidation } from './documents';

import { PAYMENTS_SET_VALUE } from '../../../actions/personalAccident/payments';
import { paymentsFormValidation } from './payments';

import { PERSONAL_ACCIDENT_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/personalAccident/agentModal';
import { agentModalFormValidation } from './agentModal';

import { SET_SHARE_EMAIL_VALUE } from '../../../actions/personalAccident/quote';
import { shareQuoteValidation } from './shareQuote';

import { PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE } from '../../../actions/personalAccident/declarations';
import { declarationsFormValidation } from './declarations';

export const personalAccidentValidation = (store, next, action) => {
    if (action.type.startsWith(PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE)) {
        return quoteFormValidation(store, next, action);
    }

    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE)) {
        return shareQuoteValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM_SET_VALUE)) {
        return nextOfKinValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_ACCIDENT_PERSONAL_DETAILS_FORM_SET_VALUE)) {
        return personalDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_ACCIDENT_CHILD_DETAILS_FORM_SET_VALUE)) {
        return childDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_ACCIDENT_SPOUSE_DETAILS_FORM_SET_VALUE)) {
        return spouseDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_ACCIDENT_DOCUMENTS_FORM_SET_VALUE)) {
        return documentsUploadValidation(store, next, action);
    }

    if (action.type.startsWith(PAYMENTS_SET_VALUE)) {
        return paymentsFormValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_ACCIDENT_AGENT_MODAL_DETAILS_SET_VALUE)) {
        return agentModalFormValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE)) {
        return declarationsFormValidation(store, next, action);
    }

    return null;
};
