export const initialState = {
    budgetRules: [
        {
            withChildren: false,
            rules: [
                {
                    name: 'Essential expenses',
                    iconUrl: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt0f93690bb13df2a7/5f61ddd6046a1b546bb1592a/direct-family-gradient.svg',
                    ruleWeight: 50,
                    categories: [
                        { categoryName: 'Rent/Bond', categoryWeight: 30 },
                        { categoryName: 'Food', categoryWeight: 10 },
                        { categoryName: 'Household essentials', categoryWeight: 5 },
                        { categoryName: 'Transport', categoryWeight: 5 },
                    ]
                },
                {
                    name: 'Financial priorities',
                    iconUrl: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltdb598ebc0ff3705a/6034d8f3c9c00d50d7b7775e/Step-by-step_instructions.png',
                    ruleWeight: 30,
                    categories: [
                        { categoryName: 'Savings', categoryWeight: 10 },
                        { categoryName: 'Retirement', categoryWeight: 5 },
                        { categoryName: 'Medical aid', categoryWeight: 10 },
                        { categoryName: 'Financial services products', categoryWeight: 5 },
                    ]
                },
                {
                    name: 'Lifestyle needs',
                    iconUrl: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blte2bb9a493c741aaf/5dbacb29df78486c826df785/Beach_Chair_Illustration.png',
                    ruleWeight: 20,
                    categories: [
                        { categoryName: 'Entertainment, movies, dining out etc', categoryWeight: 20 },
                    ]
                },
            ]
        },
        {
            withChildren: true,
            rules: [
                {
                    name: 'Essential expenses',
                    iconUrl: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt0f93690bb13df2a7/5f61ddd6046a1b546bb1592a/direct-family-gradient.svg',
                    ruleWeight: 50,
                    categories: [
                        { categoryName: 'Rent/Bond', categoryWeight: 25 },
                        { categoryName: 'Food', categoryWeight: 10 },
                        { categoryName: 'Household essentials', categoryWeight: 5 },
                        { categoryName: 'Transport', categoryWeight: 10 },
                    ]
                },
                {
                    name: 'Financial priorities',
                    iconUrl: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltdb598ebc0ff3705a/6034d8f3c9c00d50d7b7775e/Step-by-step_instructions.png',
                    ruleWeight: 30,
                    categories: [
                        { categoryName: 'Savings', categoryWeight: 10 },
                        { categoryName: 'Retirement', categoryWeight: 5 },
                        { categoryName: 'Medical aid', categoryWeight: 5 },
                        { categoryName: 'Education', categoryWeight: 5 },
                        { categoryName: 'Financial services products', categoryWeight: 5 },
                    ]
                },
                {
                    name: 'Lifestyle needs',
                    iconUrl: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blte2bb9a493c741aaf/5dbacb29df78486c826df785/Beach_Chair_Illustration.png',
                    ruleWeight: 20,
                    categories: [
                        { categoryName: 'Entertainment, movies, dining out etc', categoryWeight: 20 },
                    ]
                },
            ]
        }
    ]
}

export default (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}
