import { createSelector } from "reselect";
import { API_CALL_STATUS, FORM_STATUS } from '../../reducers/status';

export const getCallMeBack = (state => {
    return state.publicWeb.callMeBackForm
});

const makeGetField = fieldId => createSelector(
    getCallMeBack,
    callMeBackForm => callMeBackForm[fieldId]
);

export const getLeadSystem = makeGetField('leadSystem');
export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getEmail = makeGetField('email');
export const getProductName = makeGetField('productName');
export const getPhoneNumber = makeGetField('phoneNumber');
export const getPrivacyPolicyURL = makeGetField('privacyPolicyUrl');
export const getPostalCode = makeGetField('postalCode');
export const getAdditionalNumber = makeGetField('additionalNumber');
export const getLocationComments = makeGetField('locationComments');
export const getCity = makeGetField('city');
export const getToEmail = makeGetField('toEmail');
export const getActiveEntry = makeGetField('activeEntry');
export const getAdviserData = makeGetField('adviserData');
export const getSidebarOpen = makeGetField('sidebarOpen');
export const getEnvironmentName = makeGetField('environmentName');
export const getLocation = makeGetField('location');
export const getState = makeGetField('state');
export const getTypeOfHouseValue = makeGetField('typeOfHouseValue');
export const getInsureProductTypeValue = makeGetField('insureProductType');
export const getInsureProductItemValue = makeGetField('insureProductItem');
export const getPropertyLocationValue = makeGetField('propertyLocation');
export const getTypeOfOrganisationValue = makeGetField('typeOfOrganisation');
export const getFormStatus = makeGetField('formStatus');
export const getIsPrepopulatedForm = makeGetField('isPrepopulatedForm');

export const getGtmContext = createSelector(
    getCallMeBack,
    callMeBackForm => callMeBackForm.toEmail.value ? callMeBackForm.toEmail.value : callMeBackForm.productName.value,
);

export const getEnabledAdditionalFields = createSelector(
    getCallMeBack,
    callMeBackForm => {
        if (!callMeBackForm || !callMeBackForm.activeEntry || !callMeBackForm.activeEntry.call_me_back_extra_fields) {
            return [];
        }

        return callMeBackForm.activeEntry.call_me_back_extra_fields;
    }
);

export const getEmailCampaignCode = createSelector(
    getCallMeBack,
    callMeBackForm => callMeBackForm.emailCampaignCode,
);

export const getFormInvalid = createSelector(
    getCallMeBack,
    callMeBackForm => {
        return callMeBackForm.formStatus === FORM_STATUS.INVALID
    }
);

export const getFormSuccess = createSelector(
    getCallMeBack,
    callMeBackForm => callMeBackForm.cmbSubmitStatus === API_CALL_STATUS.SUCCESS,
);

export const getFormError = createSelector(
    getCallMeBack,
    callMeBackForm => callMeBackForm.cmbSubmitStatus === API_CALL_STATUS.FAILURE,
);

export const getFormSubmitting = createSelector(
    getCallMeBack,
    callMeBackForm => callMeBackForm.cmbSubmitStatus === API_CALL_STATUS.PENDING,
);

export const getInputDisabled = createSelector(
    getCallMeBack,
    callMeBackForm => callMeBackForm.cmbSubmitStatus === API_CALL_STATUS.PENDING
        || callMeBackForm.cmbSubmitStatus === API_CALL_STATUS.SUCCESS,
);

export const getIsFormPrepopulated = createSelector(
    getCallMeBack,
    callMeBackForm => {
        return callMeBackForm.isPrepopulatedForm
    }
);

export const getIsFormOpenAndPrepopulated = createSelector(
    getCallMeBack,
    callMeBackForm => {
        return callMeBackForm.isPrepopulatedForm && callMeBackForm.sidebarOpen
    }
);
export const getIsCallMeBackDrawerOpen = createSelector(
    getCallMeBack,
    callMeBackForm => {
        return callMeBackForm.sidebarOpen;
    } 
);

export const getBtnText = createSelector(
    getCallMeBack,
    callMeBackForm => {
        switch (callMeBackForm.cmbSubmitStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'Sent';
            case API_CALL_STATUS.FAILURE:
                return 'Try Again';
            case API_CALL_STATUS.PENDING:
                return 'Sending...';
            default:
                return 'Call Me Back';
        }
    }
);
