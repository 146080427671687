import { EMERGENCY_FUND_CALCULATOR_SET_VALUE } from '../../../actions/investmentCalculator/emergencyFund';
import { BUILD_WEALTH_CALCULATOR_SET_VALUE } from '../../../actions/investmentCalculator/buildWealth';
import { SHORT_TERM_GOAL_CALCULATOR_SET_VALUE } from '../../../actions/investmentCalculator/shortTermGoal';
import { LONG_TERM_GOAL_CALCULATOR_SET_VALUE } from '../../../actions/investmentCalculator/longTermGoal';
import { TOTAL_PERCENTAGE_SET_VALUE } from '../../../actions/investmentCalculator/buildWealthFundSplit';
import { TOTAL_PERCENTAGE_SET_VALUE as SHORT_TERM_GOAL_TOTAL_PERCENTAGE_SET_VALUE } from '../../../actions/investmentCalculator/shortTermGoalSplit';
import { TOTAL_PERCENTAGE_SET_VALUE as LONG_TERM_GOAL_TOTAL_PERCENTAGE_SET_VALUE } from '../../../actions/investmentCalculator/longTermGoalSplit';
import { GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE } from '../../../actions/investmentCalculator/generateRegularIncome';

import { emergencyFundCalculatorValidation } from './emergencyFundCalculator';
import { buildWealthCalculatorValidation } from './buildWealthCalculator';
import { buildWealthFundSplitValidation } from './buildWealthSplit';
import { shortTermGoalCalculatorValidation } from './shortTermGoalCalculator';
import { shortTermGoalSplitValidation } from './shortTermGoalSplit';
import { longTermGoalCalculatorValidation } from './longTermGoalCalculator';
import { longTermGoalSplitValidation } from './longTermGoalSplit';
import { generateRegularIncomeCalculatorValidation } from './generateRegularIncomeCalculator';

export const investmentCalculatorValidation = (store, next, action) => {
	if (action.type.startsWith(EMERGENCY_FUND_CALCULATOR_SET_VALUE))
		return emergencyFundCalculatorValidation(store, next, action);

	if (action.type.startsWith(BUILD_WEALTH_CALCULATOR_SET_VALUE)) {
		return buildWealthCalculatorValidation(store, next, action);
	}

	if (action.type.startsWith(TOTAL_PERCENTAGE_SET_VALUE)) {
		return buildWealthFundSplitValidation(store, next, action);
	}

	if (action.type.startsWith(SHORT_TERM_GOAL_CALCULATOR_SET_VALUE)) {
		return shortTermGoalCalculatorValidation(store, next, action);
	}

	if (action.type.startsWith(SHORT_TERM_GOAL_TOTAL_PERCENTAGE_SET_VALUE)) {
		return shortTermGoalSplitValidation(store, next, action);
	}

    if (action.type.startsWith(LONG_TERM_GOAL_CALCULATOR_SET_VALUE)) {
		return longTermGoalCalculatorValidation(store, next, action);
	}

	if (action.type.startsWith(LONG_TERM_GOAL_TOTAL_PERCENTAGE_SET_VALUE)) {
		return longTermGoalSplitValidation(store, next, action);
	}

    if (action.type.startsWith(GENERATE_REGULAR_INCOME_CALCULATOR_SET_VALUE)) {
        return generateRegularIncomeCalculatorValidation(store, next, action);
    }

    return null;
};
