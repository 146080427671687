import {fireGtmEvent} from "../gtmEvents";
import {SUBMISSION_COMPLETE} from '../../../actions/publicWeb/save2gether';

const submissionGtmEvent = submissionStatus => {
    fireGtmEvent(
        'save_submission',
        {
            submissionStatus
        }
    );
};

export const save2getherTracking = (next, action) => {
    const result = next(action);
    switch (action.type) {
        case SUBMISSION_COMPLETE:
            submissionGtmEvent(action.submissionStatus);
            break;
    }

    return result;
};
