import {validationIsEqual} from "../status";
import {
    validateName,
    validateDateOfBirth,
    validateEmail,
    validateCellphoneNumber,
    validateSingleSelection
} from  "@om/validation";
import {
    DATE_OF_BIRTH_SET_VALUE,
    EMAIL_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    GENDER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    SURNAME_SET_VALUE,
    TITLE_SET_VALUE,
    setTitleValid,
    setFirstNameValid,
    setSurnameValid,
    setGenderValid,
    setMobileValid,
    setEmailValid,
    setDateOfBirthValid,
} from "../../../actions/taxFreeSavingsAccount/beneficiaries";
import { getEmail, getMobileNumber } from "../../../selectors/taxFreeSavingsAccount/beneficiaries";

let prevValidationAction = null;
export const beneficiariesValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, 'title');
                return setTitleValid(error);
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {min: 2, max: 48, subject: 'First name'});
                return setFirstNameValid(error);
            case SURNAME_SET_VALUE:
                error = validateName(action.value, {min: 2, max: 64, subject: 'Surname'});
                return setSurnameValid(error);
            case DATE_OF_BIRTH_SET_VALUE:
                error = validateDateOfBirth(action.value, { required: true });
                return setDateOfBirthValid(error);
            case GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, 'gender');
                return setGenderValid(error);
            case MOBILE_NUMBER_SET_VALUE:
                const emailAddress = getEmail(store.getState()).value;
                error = validateCellphoneNumber(action.value, { subject: 'Mobile number', required: !emailAddress });
                store.dispatch(setMobileValid(error));
                // Validate email based on mobile input
                error = validateEmail(emailAddress, { required: !action.value });
                return setEmailValid(error);
            case EMAIL_SET_VALUE:
                const mobileNumber = getMobileNumber(store.getState()).value;
                error = validateEmail(action.value, { required: !mobileNumber });
                store.dispatch(setEmailValid(error));
                // Validate mobile based on email input
                error = validateCellphoneNumber(mobileNumber, { subject: 'Mobile number', required: !action.value });
                return setMobileValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
