import {
    FUND_TOGGLE_SELECTION,
    FUNDS_TOGGLE_SHOW_MORE,
    FILTER_OPTIONS_TOGGLE_DRAWER,
    FILTER_OPTIONS_TOGGLE_OPTION,
    SHOW_TEXT_SEARCH_BAR_TOGGLE,
    FILTER_OPTIONS_SET_FILTER_OPTIONS,
} from '../../../actions/taxFreeSavingsAccount/advancedFundPicker';
import {
    getIsShowingMoreFunds,
    getIsFilterOptionsSideDrawerOpen,
    getSelectedFundFilters,
    getShowTextSearchBar,

} from '../../../selectors/taxFreeSavingsAccount/advancedFundPicker';
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const advancedFundPickerOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case FUND_TOGGLE_SELECTION:
                otherEventIndex = findOtherEventIndex('TOGGLE_CHECKBOX');
                return action.id + ' toggled: ' + action.value;
            case FUNDS_TOGGLE_SHOW_MORE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return getIsShowingMoreFunds(state) ? 'showMoreExpanded' : 'showMoreClosed';
            case FILTER_OPTIONS_TOGGLE_DRAWER:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return getIsFilterOptionsSideDrawerOpen(state) ? 'filterOpened' : 'filterClosed';
            case FILTER_OPTIONS_TOGGLE_OPTION:
                otherEventIndex = findOtherEventIndex('SELECT');
                return 'filterRemoved';
            case SHOW_TEXT_SEARCH_BAR_TOGGLE:
                otherEventIndex = findOtherEventIndex('SELECT');
                return getShowTextSearchBar(state) ? 'searchBarOpened' : 'searchBarClosed';
            case FILTER_OPTIONS_SET_FILTER_OPTIONS:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return getSelectedFundFilters(state);
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};
