import {
    transitionQuote,
    SAVE_TRANSITION_QUOTE,
    AGE_SET_VALID,
    AGE_SET_VALUE,
    CLEAR_ALL_TRANSITION_QUOTE,
    COVER_MEMBER_SET_VALID,
    COVER_AMOUNT_SET_VALUE,
    ADD_PARTNER_SET_VALUE,
    ADD_CHILD_SET_VALUE,
    setMonthlyPremiumValue,
    setNewCoverMyself,
    setNewCoverParents,
    setNewCoverExtendedFamily,
    setAgeValue,
    setCoverMemberValue,
    setNewCoverDefault,
    collapseMembers,
    progressiveDisclosureAgeClear,
    progressiveDisclosureMemberClear,
} from "../../../actions/easiplusFuneralPlan/quote";
import {
    getCoverIsValid,
    getPremiumFromCoverAmount,
    getEditCoverCategory,
    getEditCover,
} from "../../../selectors/easiplusFuneralPlan/quote";
import { EFP_COVER_CATEGORIES } from "../../../actions/easiplusFuneralPlan/types";

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const premium = getPremiumFromCoverAmount(state);
    switch (action.type) {
        case AGE_SET_VALID:
            store.dispatch(progressiveDisclosureAgeClear());
            store.dispatch(setMonthlyPremiumValue(premium));
            break;
        case COVER_MEMBER_SET_VALID:
            store.dispatch(progressiveDisclosureMemberClear());
            store.dispatch(progressiveDisclosureAgeClear());
            store.dispatch(setMonthlyPremiumValue(premium));
            break;
        case COVER_AMOUNT_SET_VALUE:
            store.dispatch(setMonthlyPremiumValue(premium));
            break;
        case AGE_SET_VALUE:
            store.dispatch(setMonthlyPremiumValue(premium));
            break;
        case ADD_PARTNER_SET_VALUE:
            store.dispatch(setMonthlyPremiumValue(premium));
            break;
        case ADD_CHILD_SET_VALUE:
            store.dispatch(setMonthlyPremiumValue(premium));
            break;
        default:
            break;
    }

    const validCover = getCoverIsValid(state);
    const formAction = validCover
        ? transitionQuote.formValid()
        : transitionQuote.formInvalid();

    store.dispatch(formAction);

    return result;
};

export const quoteSaveDeleteClearRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    if (action.type === CLEAR_ALL_TRANSITION_QUOTE) {
        switch (action.coverCategory) {
            case EFP_COVER_CATEGORIES.DIRECT_FAMILY:
                store.dispatch(setNewCoverMyself());
                break;
            case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS:
                store.dispatch(setNewCoverParents());
                break;
            case EFP_COVER_CATEGORIES.EXTENDED_FAMILY:
                store.dispatch(setNewCoverExtendedFamily());
                break;
            default:
                store.dispatch(setNewCoverMyself());
                store.dispatch(setNewCoverParents());
                store.dispatch(setNewCoverExtendedFamily());
                store.dispatch(setNewCoverDefault());
        }

        return result;
    }

    if (action.type === SAVE_TRANSITION_QUOTE) {
        const validCover = getCoverIsValid(state);

        if (validCover) {
            store.dispatch(collapseMembers());
            return result;
        }

        const editCover = getEditCover(state);
        const coverCategory = getEditCoverCategory(state);
        const coverMemberValue = editCover.form.relationship && editCover.form.relationship.value;
        const ageValue = editCover.form.age.value;

        switch (coverCategory) {
            case EFP_COVER_CATEGORIES.DIRECT_FAMILY:
                store.dispatch(setAgeValue(ageValue));
                break;

            case EFP_COVER_CATEGORIES.EXTENDED_FAMILY:
            case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS:
                store.dispatch(setCoverMemberValue(coverMemberValue));
                store.dispatch(setAgeValue(ageValue));
                break;
        }
    }

    return result;
};
