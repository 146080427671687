import { getIsApiPending } from '../../../selectors/ugMotorInsurance/travelAgentModal';
import {
    apiTransitionAgents,
    setAllAgentsValue,
    setBankCodesValue,
} from '../../../actions/ugMotorInsurance/motorAgentModal';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import { getIntermediaryType } from '../../../selectors/ugMotorInsurance/travelAgentModal';
import axios from 'axios';

export const getAgentsService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionAgents.pending());
            try {
                const resp = await getAgents(store.getState());
                store.dispatch(setAllAgentsValue(resp.data.agents));
                store.dispatch(setBankCodesValue(resp.data.bankCodes));
                store.dispatch(apiTransitionAgents.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionAgents.failure());
            }
        }
    })();
    return next(action);
};

const getAgents = async (state) => {
    const loginData = getAccessToken(state);
    const intermediaryType = getIntermediaryType(state);
    try {
        const url = '/om-api/ug-motor-insurance/agents';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                type: intermediaryType.value,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
