import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';
import {
    PAYMENT_TERM_SET_VALUE,
    PAYMENT_TERM_SET_VALID,
    PREMIUM_AMOUNT_SET_VALUE,
    PREMIUM_AMOUNT_SET_VALID,
    PAYMENT_FREQUENCY_SET_VALUE,
    PAYMENT_FREQUENCY_SET_VALID,
    WHICH_FORM_TO_DISPLAY,
    SET_DISPLAY_RESULT,
    REINITIALIZE,
    COLLECT_MVEST_PREMIUM_DATA,
    CALCULATE_MVEST_PREMIUM_DATA,
    API_PENDING_TRANSITION_MVEST_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_MVEST_QUOTE_TOOL,
    API_FAILURE_TRANSITION_MVEST_QUOTE_TOOL,
    FORM_VALID_MVEST_QUOTE_TOOL,
    FORM_INVALID_MVEST_QUOTE_TOOL,
} from '../../actions/roaQuoteTools/mvestQuoteTool';

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    mvestData: null,
    showMvestQuoteToolResult: false,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_email',
        product: 'Mvest Cover',
        email: "customerservice@oldmutual.com.gh",
        campaign_code: 'NEW GH EMAIL CMB',
        privacy_policy_url: '/privacy-policy/',
        extra_fields: ['Email Address', 'Location'],
    },
    form: {
        paymentTerm: { value: null, error: null, status: null },
        paymentFrequency: { value: null, error: null, status: null },
        premiumAmount: { value: null, error: null, status: null },
    },
};
export default (state = initialeState, action) => {
    // console.log(action);
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_MVEST_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_MVEST_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case PAYMENT_TERM_SET_VALUE:
            return updateFormField('paymentTerm', field => ({
                ...field,
                value: action.value,
            }));
        case PAYMENT_TERM_SET_VALID:
            return updateFormField('paymentTerm', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PAYMENT_FREQUENCY_SET_VALUE:
            return updateFormField('paymentFrequency', field => ({
                ...field,
                value: action.value,
            }));
        case PAYMENT_FREQUENCY_SET_VALID:
            return updateFormField('paymentFrequency', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PREMIUM_AMOUNT_SET_VALUE:
            return updateFormField('premiumAmount', field => ({
                ...field,
                value: action.value,
            }));
        case PREMIUM_AMOUNT_SET_VALID:
            return updateFormField('premiumAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value,
            };

        case SET_DISPLAY_RESULT:
            return {
                ...state,
                showMvestQuoteToolResult: action.value,
            };

        // Api call
        case API_PENDING_TRANSITION_MVEST_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_MVEST_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_MVEST_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case REINITIALIZE:
            return initialeState;

        case CALCULATE_MVEST_PREMIUM_DATA:
            return {
                ...state,
                mvestData: action.value,
            };
        case COLLECT_MVEST_PREMIUM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        default:
            return state;
    }
};
