import { DIGITAL_SAVINGS_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/digitalSavings/agentModal';
import { DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE } from '../../../actions/digitalSavings/beneficiaries';
import { DIGITAL_SAVINGS_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/digitalSavings/Documents';
import { PAYMENTS_SET_VALUE } from '../../../actions/digitalSavings/payment';
import { DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE } from '../../../actions/digitalSavings/personalDetails';
import { DIGITAL_SAVINGS_FORM_SET_VALUE, DIGITAL_SAVINGS_SHARE_FORM_SET_VALUE } from '../../../actions/digitalSavings/quote';
import { agentValidation } from './agent';
import { beneficiaryValidation } from './beneficiaries';
import { documentsUploadValidation } from './documents';
import { paymentsFormValidation } from './payment';
import { personalDetailsValidation } from './personalDetails';
import { quoteValidation } from './quote';
import { shareQuoteValidation } from './shareQuote';

export const digitalSavingsValidation = (store, next, action) => {

    if(action.type.startsWith(PAYMENTS_SET_VALUE)){
		return paymentsFormValidation(store, next , action)
	}

    if (action.type.startsWith(DIGITAL_SAVINGS_DOCUMENTS_FORM_SET_VALUE)) {
        return documentsUploadValidation(store, next, action);
    }

    if (action.type.startsWith(DIGITAL_SAVINGS_AGENT_MODAL_DETAILS_SET_VALUE)) {
        return agentValidation(store, next, action);
    }

    if (action.type.startsWith(DIGITAL_SAVINGS_FORM_SET_VALUE)) {
        return quoteValidation(store, next, action);
    }

    if (action.type.startsWith(DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE)) {
        return personalDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(DIGITAL_SAVINGS_BENEFICIARIES_FORM_SET_VALUE)) {
        return beneficiaryValidation(store, next, action);
    }

    if (action.type.startsWith(DIGITAL_SAVINGS_SHARE_FORM_SET_VALUE)) {
		return shareQuoteValidation(store, next, action);
	}

    return null;
};
