import axios from 'axios'; 

import {
    calculateAfyaImaraSeniorData,
    apiTransitionAfyaImaraSeniorQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/afyaImaraSeniorQuoteTool";

import {
    getAfyaImaraSeniorQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/afyaImaraSeniorQuoteTool';

export const afyaImaraSeniorQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionAfyaImaraSeniorQuoteTool.pending());

            try {
                const response = await calculate(state);
                const quotationSummary = response.data;
                store.dispatch(calculateAfyaImaraSeniorData(quotationSummary));
                store.dispatch(apiTransitionAfyaImaraSeniorQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateAfyaImaraSeniorData(null));
                store.dispatch(apiTransitionAfyaImaraSeniorQuoteTool.failure());
            }
        }
    })();
    return result;
}

const getAfyaImaraSeniorPayload = (formDetails) => {
    return {
        'cover_id': formDetails.coverId.value || '',
        'age_group': formDetails.ageGroup.value || '',
        'children_status': formDetails.childrenStatus.value || '',
        'inpatient': formDetails.inpatientCoverLimit.value || '',
        'outpatient': formDetails.outpatientCoverLimit.value || ''
    }
}
const calculate = async(state) => {
    const afyaImaraSeniorFormDetails = getAfyaImaraSeniorQuoteToolForm(state)

    try{
        const url = '/om-api/roa-quote-tools/afya-imara-senior-quote';
        const result = await axios.post(url, getAfyaImaraSeniorPayload(afyaImaraSeniorFormDetails))
        return result;
    } catch (error) {
        return null;
    }
}
