const AUXILIARY_DETAILS_TRANSITION = "taxFreeSavingsAccount/auxiliaryDetails/transition/";

export const FORM_SET_VALUES_TRANSITION_AUXILIARY_DETAILS = AUXILIARY_DETAILS_TRANSITION + "formValue";
export const setAuxiliaryDetailFormValues = () => ({ type: FORM_SET_VALUES_TRANSITION_AUXILIARY_DETAILS });

// Transition - leads
export const LEAD_VALID_TRANSITION_AUXILIARY_DETAILS = AUXILIARY_DETAILS_TRANSITION + "lead";
export const leadValidAuxiliaryDetails = (leadDetails) => ({
  type: LEAD_VALID_TRANSITION_AUXILIARY_DETAILS,
  leadDetails,
});

// Events
export const AUXILIARY_DETAILS_CLEAR_VALUE = "taxFreeSavingsAccount/auxiliaryDetails/clearValue/";
export const AUXILIARY_DETAILS_SET_VALUE = "taxFreeSavingsAccount/auxiliaryDetails/setValue/";
export const AUXILIARY_DETAILS_SET_VALID = "taxFreeSavingsAccount/auxiliaryDetails/setValid/";

// Events - Personal Details
export const TITLE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "title";
export const TITLE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "title";
export const FIRST_NAMES_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "firstNames";
export const FIRST_NAMES_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "firstNames";
export const SURNAME_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "surname";
export const SURNAME_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "surname";
export const ID_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "id";
export const ID_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "id";
export const NATIONALITY_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "nationality";
export const NATIONALITY_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "nationality";
export const MARITAL_STATUS_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "maritalStatus";
export const MARITAL_STATUS_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "maritalStatus";
export const setTitleValue = (value) => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = (error) => ({ type: TITLE_SET_VALID, error });
export const setFirstNamesValue = (value) => ({ type: FIRST_NAMES_SET_VALUE, value });
export const setFirstNamesValid = (error) => ({ type: FIRST_NAMES_SET_VALID, error });
export const setSurnameValue = (value) => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = (error) => ({ type: SURNAME_SET_VALID, error });
export const setIdValue = (value) => ({ type: ID_SET_VALUE, value });
export const setIdValid = (error) => ({ type: ID_SET_VALID, error });
export const setNationalityValue = (value) => ({ type: NATIONALITY_SET_VALUE, value });
export const setNationalityValid = (error) => ({ type: NATIONALITY_SET_VALID, error });
export const setMaritalStatusValue = (value) => ({ type: MARITAL_STATUS_SET_VALUE, value });
export const setMaritalStatusValid = (error) => ({ type: MARITAL_STATUS_SET_VALID, error });

export const SPOUSE_TITLE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "spouseTitle";
export const SPOUSE_TITLE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "spouseTitle";
export const SPOUSE_FIRST_NAMES_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "spouseFirstNames";
export const SPOUSE_FIRST_NAMES_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "spouseFirstNames";
export const SPOUSE_SURNAME_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "spouseSurname";
export const SPOUSE_SURNAME_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "spouseSurname";
export const SPOUSE_ID_TYPE_TOGGLE_VALUE = AUXILIARY_DETAILS_SET_VALUE + "toggleIdType";
export const SPOUSE_ID_NUMBER_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "spouseIdNumber";
export const SPOUSE_ID_NUMBER_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "spouseIdNumber";
export const SPOUSE_PASSPORT_NUMBER_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "spousePassportNumber";
export const SPOUSE_PASSPORT_NUMBER_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "spousePassportNumber";
export const setSpouseTitleValue = (value) => ({ type: SPOUSE_TITLE_SET_VALUE, value });
export const setSpouseTitleValid = (error) => ({ type: SPOUSE_TITLE_SET_VALID, error });
export const setSpouseFirstNamesValue = (value) => ({ type: SPOUSE_FIRST_NAMES_SET_VALUE, value });
export const setSpouseFirstNamesValid = (error) => ({ type: SPOUSE_FIRST_NAMES_SET_VALID, error });
export const setSpouseSurnameValue = (value) => ({ type: SPOUSE_SURNAME_SET_VALUE, value });
export const setSpouseSurnameValid = (error) => ({ type: SPOUSE_SURNAME_SET_VALID, error });
export const toggleSpouseIdTypeValue = () => ({ type: SPOUSE_ID_TYPE_TOGGLE_VALUE });
export const setSpouseIdNumberValue = (value) => ({ type: SPOUSE_ID_NUMBER_SET_VALUE, value });
export const setSpouseIdNumberValid = (error) => ({ type: SPOUSE_ID_NUMBER_SET_VALID, error });
export const setSpousePassportNumberValue = (value) => ({ type: SPOUSE_PASSPORT_NUMBER_SET_VALUE, value });
export const setSpousePassportNumberValid = (error) => ({ type: SPOUSE_PASSPORT_NUMBER_SET_VALID, error });
export const SPOUSE_PASSPORT_NUMBER_RESET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "resetSpousePassportNumber";
export const SPOUSE_ID_NUMBER_RESET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "resetSpouseIdNumber";
export const resetSpousePassportNumber = () => ({ type: SPOUSE_PASSPORT_NUMBER_RESET_VALUE });
export const resetSpouseIdNumber = () => ({ type: SPOUSE_ID_NUMBER_RESET_VALUE });

// Events -minor details
export const MINOR_TITLE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "minorTitle";
export const MINOR_TITLE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "minorTitle";
export const MINOR_FIRST_NAMES_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "minorFirstNames";
export const MINOR_FIRST_NAMES_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "minorFirstNames";
export const MINOR_SURNAME_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "minorSurname";
export const MINOR_SURNAME_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "minorSurname";
export const MINOR_ID_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "minorId";
export const MINOR_ID_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "minorId";
export const setMinorTitleValue = (value) => ({ type: MINOR_TITLE_SET_VALUE, value });
export const setMinorTitleValid = (error) => ({ type: MINOR_TITLE_SET_VALID, error });
export const setMinorFirstNamesValue = (value) => ({ type: MINOR_FIRST_NAMES_SET_VALUE, value });
export const setMinorFirstNamesValid = (error) => ({ type: MINOR_FIRST_NAMES_SET_VALID, error });
export const setMinorSurnameValue = (value) => ({ type: MINOR_SURNAME_SET_VALUE, value });
export const setMinorSurnameValid = (error) => ({ type: MINOR_SURNAME_SET_VALID, error });
export const setMinorIdValue = (value) => ({ type: MINOR_ID_SET_VALUE, value });
export const setMinorIdValid = (error) => ({ type: MINOR_ID_SET_VALID, error });
export const MINOR_FORM_RESET_VALUES = AUXILIARY_DETAILS_SET_VALUE + "minorForm";
export const resetMinorFormValues = () => ({ type: MINOR_FORM_RESET_VALUES });

// Events - Address details
export const COMPLEX_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "complex";
export const COMPLEX_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "complex";
export const STREET_ADDRESS_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "streetAddress";
export const STREET_ADDRESS_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "streetAddress";
export const SUBURB_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "suburb";
export const SUBURB_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "suburb";
export const CITY_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "city";
export const CITY_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "city";
export const POSTAL_CODE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalCode";
export const POSTAL_CODE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "postalCode";
export const COUNTRY_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "country";
export const COUNTRY_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "country";
export const setComplexValue = (value) => ({ type: COMPLEX_SET_VALUE, value });
export const setComplexValid = (error) => ({ type: COMPLEX_SET_VALID, error });
export const setStreetAddressValue = (value) => ({ type: STREET_ADDRESS_SET_VALUE, value });
export const setStreetAddressValid = (error) => ({ type: STREET_ADDRESS_SET_VALID, error });
export const setSuburbValue = (value) => ({ type: SUBURB_SET_VALUE, value });
export const setSuburbValid = (error) => ({ type: SUBURB_SET_VALID, error });
export const setCityValue = (value) => ({ type: CITY_SET_VALUE, value });
export const setCityValid = (error) => ({ type: CITY_SET_VALID, error });
export const setPostalCodeValue = (value) => ({ type: POSTAL_CODE_SET_VALUE, value });
export const setPostalCodeValid = (error) => ({ type: POSTAL_CODE_SET_VALID, error });
export const setCountryValue = (value) => ({ type: COUNTRY_SET_VALUE, value });
export const setCountryValid = (error) => ({ type: COUNTRY_SET_VALID, error });

export const POSTAL_ADDRESS_METHOD_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalAddressMethod";
export const POSTAL_ADDRESS_METHOD_VALID = AUXILIARY_DETAILS_SET_VALID + "postalAddressMethod";
export const POSTAL_ADDRESS_SAME_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalAddressSame";
export const POSTAL_ADDRESS_SAME_VALID = AUXILIARY_DETAILS_SET_VALID + "postalAddressSame";
export const POSTAL_ADDRESS_SAME_TOGGLE_VALUE = AUXILIARY_DETAILS_SET_VALUE + "togglePostalAddressSame";
export const POSTAL_ADDRESS_METHOD_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "postalAddressMethod";
export const setPostalAddressMethodValue = (value) => ({ type: POSTAL_ADDRESS_METHOD_VALUE, value });
export const setPostalAddressMethodValid = (error) => ({ type: POSTAL_ADDRESS_METHOD_VALID, error });
export const setPostalAddressSameValue = (value) => ({ type: POSTAL_ADDRESS_SAME_VALUE, value });
export const setPostalAddressSameValid = (error) => ({ type: POSTAL_ADDRESS_SAME_VALID, error });
export const togglePostalAddressSameValue = (value) => ({ type: POSTAL_ADDRESS_SAME_TOGGLE_VALUE, value });
export const clearPostalAddressMethodValue = () => ({ type: POSTAL_ADDRESS_METHOD_CLEAR_VALUE });

// Events - PO box
export const PO_BOX_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "poBox";
export const PO_BOX_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "poBox";
export const PO_POST_OFFICE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "poPostOffice";
export const PO_POST_OFFICE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "poPostOffice";
export const PO_POSTAL_CODE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "poPostalCode";
export const PO_POSTAL_CODE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "poPostalCode";
export const PO_COUNTRY_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "poCountry";
export const PO_COUNTRY_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "poCountry";
export const setPoBoxValue = (value) => ({ type: PO_BOX_SET_VALUE, value });
export const setPoBoxValid = (error) => ({ type: PO_BOX_SET_VALID, error });
export const setPoPostOfficeValue = (value) => ({ type: PO_POST_OFFICE_SET_VALUE, value });
export const setPoPostOfficeValid = (error) => ({ type: PO_POST_OFFICE_SET_VALID, error });
export const setPoPostalCodeValue = (value) => ({ type: PO_POSTAL_CODE_SET_VALUE, value });
export const setPoPostalCodeValid = (error) => ({ type: PO_POSTAL_CODE_SET_VALID, error });
export const setPoCountryValue = (value) => ({ type: PO_COUNTRY_SET_VALUE, value });
export const setPoCountryValid = (error) => ({ type: PO_COUNTRY_SET_VALID, error });

export const PO_BOX_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "poBox";
export const PO_POST_OFFICE_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "poPostOffice";
export const PO_POSTAL_CODE_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "poPostalCode";
export const PO_COUNTRY_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "poCountry";
export const clearPoBoxValue = () => ({ type: PO_BOX_CLEAR_VALUE });
export const clearPoPostOfficeValue = () => ({ type: PO_POST_OFFICE_CLEAR_VALUE });
export const clearPoPostalCodeValue = () => ({ type: PO_POSTAL_CODE_CLEAR_VALUE });
export const clearPoCountryValue = () => ({ type: PO_COUNTRY_CLEAR_VALUE });

// Events - Private bag
export const PRIVATE_BAG_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "privateBag";
export const PRIVATE_BAG_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "privateBag";
export const PRIVATE_POST_OFFICE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "privatePostOffice";
export const PRIVATE_POST_OFFICE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "privatePostOffice";
export const PRIVATE_POSTAL_CODE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "privatePostalCode";
export const PRIVATE_POSTAL_CODE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "privatePostalCode";
export const PRIVATE_COUNTRY_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "privateCountry";
export const PRIVATE_COUNTRY_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "privateCountry";
export const setPrivateBagValue = (value) => ({ type: PRIVATE_BAG_SET_VALUE, value });
export const setPrivateBagValid = (error) => ({ type: PRIVATE_BAG_SET_VALID, error });
export const setPrivatePostOfficeValue = (value) => ({ type: PRIVATE_POST_OFFICE_SET_VALUE, value });
export const setPrivatePostOfficeValid = (error) => ({ type: PRIVATE_POST_OFFICE_SET_VALID, error });
export const setPrivatePostalCodeValue = (value) => ({ type: PRIVATE_POSTAL_CODE_SET_VALUE, value });
export const setPrivatePostalCodeValid = (error) => ({ type: PRIVATE_POSTAL_CODE_SET_VALID, error });
export const setPrivateCountryValue = (value) => ({ type: PRIVATE_COUNTRY_SET_VALUE, value });
export const setPrivateCountryValid = (error) => ({ type: PRIVATE_COUNTRY_SET_VALID, error });

export const PRIVATE_BAG_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "privateBag";
export const PRIVATE_POST_OFFICE_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "privatePostOffice";
export const PRIVATE_POSTAL_CODE_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "privatePostalCode";
export const PRIVATE_COUNTRY_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "privateCountry";
export const clearPrivateBagValue = () => ({ type: PRIVATE_BAG_CLEAR_VALUE });
export const clearPrivatePostOfficeValue = () => ({ type: PRIVATE_POST_OFFICE_CLEAR_VALUE });
export const clearPrivatePostalCodeValue = () => ({ type: PRIVATE_POSTAL_CODE_CLEAR_VALUE });
export const clearPrivateCountryValue = () => ({ type: PRIVATE_COUNTRY_CLEAR_VALUE });

// Events - Residential postal address
export const POSTAL_COMPLEX_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalComplex";
export const POSTAL_COMPLEX_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "postalComplex";
export const POSTAL_STREET_ADDRESS_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalStreetAddress";
export const POSTAL_STREET_ADDRESS_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "postalStreetAddress";
export const POSTAL_SUBURB_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalSuburb";
export const POSTAL_SUBURB_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "postalSuburb";
export const POSTAL_CITY_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalCity";
export const POSTAL_CITY_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "postalCity";
export const POSTAL_POSTAL_CODE_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalPostalCode";
export const POSTAL_POSTAL_CODE_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "postalPostalCode";
export const POSTAL_COUNTRY_SET_VALUE = AUXILIARY_DETAILS_SET_VALUE + "postalCountry";
export const POSTAL_COUNTRY_SET_VALID = AUXILIARY_DETAILS_SET_VALID + "postalCountry";
export const setPostalComplexValue = (value) => ({ type: POSTAL_COMPLEX_SET_VALUE, value });
export const setPostalComplexValid = (error) => ({ type: POSTAL_COMPLEX_SET_VALID, error });
export const setPostalStreetAddressValue = (value) => ({ type: POSTAL_STREET_ADDRESS_SET_VALUE, value });
export const setPostalStreetAddressValid = (error) => ({ type: POSTAL_STREET_ADDRESS_SET_VALID, error });
export const setPostalSuburbValue = (value) => ({ type: POSTAL_SUBURB_SET_VALUE, value });
export const setPostalSuburbValid = (error) => ({ type: POSTAL_SUBURB_SET_VALID, error });
export const setPostalCityValue = (value) => ({ type: POSTAL_CITY_SET_VALUE, value });
export const setPostalCityValid = (error) => ({ type: POSTAL_CITY_SET_VALID, error });
export const setPostalPostalCodeValue = (value) => ({ type: POSTAL_POSTAL_CODE_SET_VALUE, value });
export const setPostalPostalCodeValid = (error) => ({ type: POSTAL_POSTAL_CODE_SET_VALID, error });
export const setPostalCountryValue = (value) => ({ type: POSTAL_COUNTRY_SET_VALUE, value });
export const setPostalCountryValid = (error) => ({ type: POSTAL_COUNTRY_SET_VALID, error });

export const POSTAL_COMPLEX_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "postalComplex";
export const POSTAL_STREET_ADDRESS_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "postalStreetAddress";
export const POSTAL_SUBURB_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "postalSuburb";
export const POSTAL_CITY_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "postalCity";
export const POSTAL_POSTAL_CODE_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "postalPostalCode";
export const POSTAL_COUNTRY_CLEAR_VALUE = AUXILIARY_DETAILS_CLEAR_VALUE + "postalCountry";
export const clearPostalComplexValue = () => ({ type: POSTAL_COMPLEX_CLEAR_VALUE });
export const clearPostalStreetAddressValue = () => ({ type: POSTAL_STREET_ADDRESS_CLEAR_VALUE });
export const clearPostalSuburbValue = () => ({ type: POSTAL_SUBURB_CLEAR_VALUE });
export const clearPostalCityValue = () => ({ type: POSTAL_CITY_CLEAR_VALUE });
export const clearPostalPostalCodeValue = () => ({ type: POSTAL_POSTAL_CODE_CLEAR_VALUE });
export const clearPostalCountryValue = () => ({ type: POSTAL_COUNTRY_CLEAR_VALUE });

export const RESET_AUXILIARY_DETAILS = AUXILIARY_DETAILS_SET_VALUE + "resetState";
export const resetAuxiliaryDetails = () => ({ type: RESET_AUXILIARY_DETAILS });
