import { getQuoteForm, getPremiumCalculation, getProducts } from '../../../selectors/travel/quote';
import { getTravelDetailsForm, getTrallers } from '../../../selectors/travel/travelDetails';
import { getPaymentsForm } from '../../../selectors/travel/payments';
import { getIsApiPending } from '../../../selectors/travel/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/travel/payloadCollection';
import { getAgentForm } from '../../../selectors/travel/travelAgentModal';
import { getAccessToken, getPaymentStatusData } from '../../../selectors/travel/payments';
import axios from 'axios';
import { triggerConfirmOrder } from '../../../actions/travel/payments';

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await calculate(store.getState());

                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());
                store.dispatch(triggerConfirmOrder());
            } catch (e) {
                console.error(e);
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

function convertDateFormat(dateString) {
    // Split the date string into day, month, and year
    var parts = dateString.split('/');

    // Rearrange the parts into the desired format
    var formattedDate = parts[2] + '-' + parts[1].padStart(2, '0') + '-' + parts[0].padStart(2, '0');

    return formattedDate;
}

function getFirstAndLastName(fullName) {
    // Split the full name into an array of strings
    var names = fullName.trim().split(' ');

    // Extract the first name (first element of the array)
    var firstName = names[0];

    // Extract the last name (last element of the array)
    var lastName = names[names.length - 1];

    // Return an object containing the first and last names
    return {
        firstName: firstName,
        lastName: lastName,
    };
}

const payloadCollection = (state) => {
    const travelDetails = getTrallers(state);
    const quotation = getQuoteForm(state);
    const returnDate = convertDateFormat(quotation.returnDate.value);
    const departureDate = convertDateFormat(quotation.departureDate.value);
    const dateOfBirth = convertDateFormat(quotation.dob.value);
    const products = getProducts(state);
    const destinationId = quotation.destinationCountry.value;
    const selectedProduct = products.products.find((product) => product?.ID == destinationId);
    const productSuffix = selectedProduct.PRODUCT_SUFFIX;
    const productId = `${selectedProduct.PRODUCT_ID}`;
    const traveller = travelDetails[0];
    const paymentsData = getPaymentStatusData(state);
    const { firstName, lastName } = getFirstAndLastName(traveller.fullName.value);
    const payload = {
        productId,
        productSuffix,
        travelDate: departureDate,
        returnDate: returnDate,
        dateOfBirth: dateOfBirth,
        firstName: firstName || 'NA',
        lastName: lastName || 'NA',
        passportNo: traveller.idNumber.value,
        phoneNumber: traveller.phoneNumber.value,
        emailAddress: traveller.email.value,
        transactionId: paymentsData?.CheckoutRequestID || 'N/A',
        modeOfPayment: 'MPESA',
    };

    return {
        ...payload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-travel-insurance/submit-payload';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
