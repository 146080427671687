import { STORE_OTP_VALUE, OTP_VALUE, RESET_OTP_VALUE, OTP_NOT_VALID, OTP_VERIFY_SUCCESS, OTP_SEND_REQUEST, } from "../../actions/digitalOnboarding/types";

const storeValues = {
    [OTP_VALUE]:{ value:'', isValid: false, errorMessage: '' }
};

export const initOTP = {
    storeValues: storeValues,
    otpValidated: false
};

export default (state = initOTP, action) =>{
    const storeValueToStore = (value) => ({
        ...state,
        storeValues: {...state.storeValues,...value},
    });

    switch (action.type) {
        case STORE_OTP_VALUE: {
            const storeValueObj = {value: action.value, isValid: action.isValid, errorMessage: action.errorMessage}
            return storeValueToStore({[action.key]:storeValueObj});
        }

        case RESET_OTP_VALUE:
            return { ...state, storeValues: storeValues, otpValidated: false };

        case OTP_SEND_REQUEST:
            return { ...state, storeValues: storeValues, otpValidated: false };

        case OTP_NOT_VALID: {
            const storeValueObject = { otp: { value: storeValues[OTP_VALUE].value, isValid: false, errorMessage: 'Please enter the OTP sent to your mobile number.' } }
            return { ...state, storeValues: storeValueObject, otpValidated: false };
        }

        case OTP_VERIFY_SUCCESS:
            return { ...state, otpValidated: true };

        default:
            break;
    }

    return state;
}
