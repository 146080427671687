import { FIELD_STATUS } from '../../reducers/status';

/* Helper function for validating form fields*/
const validateField = (name, formFields) =>
    formFields[name] && formFields[name].status === FIELD_STATUS.VALID;

/**
 * Represents a function that validates form field
 * @param {object} formFields - A form object that contains the form fields
 * @param {Array} names - An array of the field names to be validates
 * @return boolean - Returns true when all fields satisfies the validation rules
 */
const validate = (formFields, names = []) => {
    if (names.length === 0) return false;
    const isValid = names.map((name) => {
        return validateField(name, formFields);
    });
    return isValid.every((isValid) => isValid === true);
};

export default validate;
