import { validateNumber } from  "@om/validation";
import {
    setEmergencyFundOnceOffValid,
    setMonthlyValid,
    MONTHLY_AMOUNT_SET_VALUE,
    ONCE_OFF_SET_VALUE,
} from "../../../actions/investmentCalculator/emergencyFund"

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const emergencyFundCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
			case MONTHLY_AMOUNT_SET_VALUE:
				error = validateNumber(action.value, {
					min: 1000,
					subject: 'monthly amount to save',
					decimalPoints: 2,
				});
				return setMonthlyValid(error);

			case ONCE_OFF_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 1000, 
                    subject: 'initial value', 
                    decimalPoints: 2
                });
                return setEmergencyFundOnceOffValid(error);                
			default:
				return null;
		}
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
}