import {
    getBuildWealthFundSplitForm,
    getIsEvenSplit,
} from '../../../selectors/investmentCalculator/buildWealthSplit';
import {
    BOND_FUND_SET_VALUE,
    deselectEvenSplit,
    EQUITY_FUND_SET_VALUE,
    FUND_SPLIT_SET_VALUE,
    setCongruentBalancedFundValue,
    setCongruentEquityFundValue,
    setTotalPercentageValue,
    transitionFundSplit,
} from '../../../actions/investmentCalculator/buildWealthFundSplit';

export const buildWealthSplitFormRules = (store, next, action) => {
    const result = next(action);

    if (action.type === EQUITY_FUND_SET_VALUE) {
        store.dispatch(setCongruentBalancedFundValue(100 - action.value));
    }
    if (action.type === BOND_FUND_SET_VALUE) {
        store.dispatch(setCongruentEquityFundValue(100 - action.value));
    }
    // deselect even split if user changes a field
    if (
        action.type.startsWith(FUND_SPLIT_SET_VALUE) &&
        getIsEvenSplit(store.getState())
    ) {
        store.dispatch(deselectEvenSplit());
    }

    const splitForm = getBuildWealthFundSplitForm(store.getState());
    const totalPercentage =
        (Number(splitForm.equityFund.value) || 0) +
        (Number(splitForm.balancedFund.value) || 0);
    store.dispatch(setTotalPercentageValue(totalPercentage));

    // is form valid at this point? i.e. 100% total
    const formAction =
        totalPercentage === 100
            ? transitionFundSplit.formValid()
            : transitionFundSplit.formInvalid();

    store.dispatch(formAction);

    return result;
};
