import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/motorPrivateRW/types';
import { getRWMotorPrivateInsuranceState } from './index';

const getRouting = createSelector(getRWMotorPrivateInsuranceState, (state) => state.routing);
export const getActivePageIndex = createSelector(
    getRouting,
    routing => routing.activePageIndex,
);
export const getDoMotorInsuranceNavigation = createSelector(
    getRouting,
    routing => routing.doNavigation,
);
export const getDoExitMotorInsuranceNavigation = createSelector(
    getRouting,
    routing => routing.doExitNavigation,
);

export const getLandingPage = createSelector(
    getRouting,
    routing => routing.landingPage,
);

export const getIsDropoffPage = createSelector(
    getRouting,
    routing => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU,
);

const makeGetIsPage = pageIndex =>
    createSelector(
        getRouting,
        routing => routing.activePageIndex === pageIndex,
    );
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QOUTE);
export const getIsPersonalDetailsPage = makeGetIsPage(PAGE_INDICES.PERSONAL_DETAILS);
export const getIsCarDetailsPage = makeGetIsPage(
    PAGE_INDICES.CAR_DETAILS,
);
export const getIsDocumentsPage = makeGetIsPage(PAGE_INDICES.DOCUMENTS);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getProgressBarIndex = createSelector(
	getIsQuotePage,
	getIsPersonalDetailsPage,
	getIsCarDetailsPage,
	getIsDocumentsPage,
	getIsPaymentPage,
	(isQuotePage, isPersonalDetailsPage, isCarDetailsPage, isDocuments, isPaymentPage) => {
		if (isQuotePage) return 0;
		if (isPersonalDetailsPage) return 1;
		if (isCarDetailsPage) return 2;
		if (isDocuments) return 3;
		if (isPaymentPage) return 4;
	}
);
