import { 
  getIsQuoteReferenceDataValid, 
  getIsApplicationReferenceDataValid,
} from "../../../selectors/easiplusFuneralPlan/referenceData";
import {
  setApplicationReferenceData,
  setQuoteReferenceData,
  EFP_APPLICATION_REFERENCE_DATA_GET_VALUE,
  EFP_QUOTE_REFERENCE_DATA_GET_VALUE,
} from "../../../actions/easiplusFuneralPlan/referenceData";
import {toggleQuoteCallMeBack} from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import {setSideDrawerClosed, SIDEDRAWER_SET_VALUE} from '@om/redux/actions/easiplusFuneralPlan/quote';
import {EFP_ADD_COVER_DRAWERS} from '@om/redux/actions/easiplusFuneralPlan/types';
import axios from "axios";
import { setRequestId } from "../../../actions/easiplusFuneralPlan/sessionCamVariables";

export const referenceDataService = (store, next, action) => {
  const result = next(action);

  if (action.type === EFP_QUOTE_REFERENCE_DATA_GET_VALUE) {
      // If ref data already exists, dont make new call
      if (getIsQuoteReferenceDataValid(store.getState())) return;
      (async () => {
          const quoteReferenceData = await fetchQuoteReferenceData();
          if (quoteReferenceData) {
              store.dispatch(setQuoteReferenceData(quoteReferenceData.data));
          }

          if (quoteReferenceData && 'data' in quoteReferenceData && 'requestId' in quoteReferenceData.data) {
              store.dispatch(setRequestId(quoteReferenceData.data.requestId));
          }
      })();
  }

  if (action.type === EFP_APPLICATION_REFERENCE_DATA_GET_VALUE) {
      // If ref data already exists, dont make new call
      if (getIsApplicationReferenceDataValid(store.getState())) return;
      (async () => {
          const applicationReferenceData = await fetchApplicationReferenceData();
          if (applicationReferenceData) {
              store.dispatch(setApplicationReferenceData(applicationReferenceData.data));
          }

          if (applicationReferenceData && 'data' in applicationReferenceData && 'requestId' in applicationReferenceData.data) {
              store.dispatch(setRequestId(applicationReferenceData.data.requestId));
          }
      })();
  }

  if (action.type === SIDEDRAWER_SET_VALUE) {
      checkIfReferenceDataLoaded(store);  
  }

  return result;
};

// Checks if reference data is loaded within 10 seconds
// if it is not then close all side drawers and display call me back
export const checkIfReferenceDataLoaded = async (store) => {
  await new Promise(resolve => setTimeout(resolve, 10000));
  const refDataLoaded = getIsQuoteReferenceDataValid(store.getState()) && 
                          getIsApplicationReferenceDataValid(store.getState());

  if (!refDataLoaded) {
      store.dispatch(setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.LIVES_COVERED));
      store.dispatch(setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.DIRECT_FAMILY));
      store.dispatch(setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.PARENTS_AND_IN_LAWS));
      store.dispatch(setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.EXTENDED_FAMILY));
      store.dispatch(toggleQuoteCallMeBack());
  }
}


export const fetchApplicationReferenceData = async () => {
  try {
      const response = await axios.get('/om-api/easiplus-funeral-plan/application-reference-data',
          {headers: { 'Content-Type': 'application/json' }});
      return response;
  } catch (error) {
      return null;
  }
};

export const fetchQuoteReferenceData = async () => {
  try {
      const response = await axios.get('/om-api/easiplus-funeral-plan/quote-reference-data',
          {headers: { 'Content-Type': 'application/json' }});
      return response;
  } catch (error) {
      return null;
  }
};
