import { COMPLEX_BUILDING, STREET_ADDRESS, POSTAL_CODE_PHYSICAL, SUBURB_PHYSICAL, CITY_PHYSICAL, PROVINCE_PHYSICAL, RURAL_LOW_DENSITY, POSTAL_SAME_AS_PHYSICAL, ADDRESS_LINE_1, POSTAL_CODE_POSTAL, SUBURB_POSTAL, CITY_POSTAL, PROVINCE_POSTAL, STORE_ADDRESS_DETAIL, STORE_ADDRESS_DETAIL_VALIDATION } from "../../actions/digitalOnboarding/types";

const storeValues = {
    [COMPLEX_BUILDING]: { value: '', isValid: true, errorMessage: '' },
    [STREET_ADDRESS]: { value: '', isValid: false, errorMessage: '' },
    [POSTAL_CODE_PHYSICAL]: { value: '', isValid: false, errorMessage: '' },
    [SUBURB_PHYSICAL]: { value: '', isValid: false, errorMessage: '' },
    [CITY_PHYSICAL]: { value: '', isValid: false, errorMessage: '' },
    [PROVINCE_PHYSICAL]: { value: '', isValid: false, errorMessage: '' },
    [RURAL_LOW_DENSITY]: { value: '', isValid: false, errorMessage: '' },
    [POSTAL_SAME_AS_PHYSICAL]: { value: '', isValid: false, errorMessage: '' },
    [ADDRESS_LINE_1]: { value: '', isValid: true, errorMessage: '' },
    [POSTAL_CODE_POSTAL]: { value: '', isValid: true, errorMessage: '' },
    [SUBURB_POSTAL]: { value: '', isValid: true, errorMessage: '' },
    [CITY_POSTAL]: { value: '', isValid: true, errorMessage: '' },
    [PROVINCE_POSTAL]: { value: '', isValid: true, errorMessage: '' }
};

export const initAddressDetails = {
    storeValues: storeValues,
}


export default (state = initAddressDetails, action) => {
    const storeValueToStore = (value) => ({
        ...state,
        storeValues: { ...state.storeValues, ...value }
    });


    const updateValidationFromFields = fields => fields.reduce(
        (s, field) => {
            const storeValue = { ...s[field.key], isValid: field.isValid, errorMessage: field.errorMessage };
            if (field.value !== undefined) {
                storeValue.value = field.value;
            }
            return { ...s, [field.key]: storeValue };
        },
        { ...state.storeValues }
    );

    switch (action.type) {
        case STORE_ADDRESS_DETAIL_VALIDATION:
            const storeValue = updateValidationFromFields(action.fields);
            return storeValueToStore(storeValue);
        case STORE_ADDRESS_DETAIL:
            const storeValueObj = { value: action.value, isValid: action.isValid, errorMessage: action.errorMessage };
            return storeValueToStore({ [action.key]: storeValueObj });
        default:
            break;
    }

    return state;
}
