import {
    HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALID,
    HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE,
} from '../../../actions/healthInsuranceRW/Documents';
import {
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE,
    HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/healthInsuranceRW/HealthAgentModalRw';
import {
    MEDICAL_HISTORY_FORM_SET_VALUE,
    MEDICAL_HISTORY_FORM_SET_VAVID,
} from '../../../actions/healthInsuranceRW/medicalHistory';
import {
    HEALTH_INSURANCE_NEXT_OF_KIN_FORM_SET_VALID,
    HEALTH_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE,
} from '../../../actions/healthInsuranceRW/nextOfKin';
import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from '../../../actions/healthInsuranceRW/payments';
import {
    LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE,
    LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID,
} from '../../../actions/healthInsuranceRW/personalDetails';
import {
    LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE as LIVES_COVERED_PERSONAL_DETAILS_BENEFICIARIES_FORM_SET_VALUE,
    LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID as LIVES_COVERED_PERSONAL_DETAILS_BENEFICIARIES_FORM_SET_VALID,
} from '../../../actions/healthInsuranceRW/personalDetailsBeneficiaries';

import {
    HEALTH_INSURANCE_QOUTE_FORM_SET_VALID,
    HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE,
	SET_SHARE_EMAIL_VALID,
	SET_SHARE_EMAIL_VALUE,
} from '../../../actions/healthInsuranceRW/quote';
import { SET_VALUE_ROUTING } from '../../../actions/healthInsuranceRW/routing';
import { agentModaleFormRules } from './agentModal';
import { documenetsFormRules } from './documents';
import { medicalHistoryFormRules } from './medicalHistory';
import { nextOfKinFormRules } from './nextOfKin';
import { paymentsFormRules } from './payments';
import { personalDetailsFormRules } from './personalDetails';
import { personalDetailsBeneficiariesFormRules } from './personalDetailsBeneficiaries';
import { healthQuoteFormRules } from './quote';
import { routingRules } from './routing';
import { shareQuoteFormRules } from './shareQuote';
export const healthInsuranceRules = (store, next, action) => {
    if (action.type.startsWith(SET_VALUE_ROUTING)) {
        return routingRules(store, next, action);
    }

    if (
        action.type.startsWith(HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE) ||
        action.type.startsWith(HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALID)
    ) {
        return documenetsFormRules(store, next, action);
    }

    if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
    	return paymentsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(HEALTH_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE) ||
        action.type.startsWith(HEALTH_INSURANCE_NEXT_OF_KIN_FORM_SET_VALID)
    ) {
        return nextOfKinFormRules(store, next, action);
    }
    if (
        action.type.startsWith(LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(LIVES_COVERED_PERSONAL_DETAILS_FORM_SET_VAVID)
    ) {
        return personalDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(LIVES_COVERED_PERSONAL_DETAILS_BENEFICIARIES_FORM_SET_VALUE) ||
        action.type.startsWith(LIVES_COVERED_PERSONAL_DETAILS_BENEFICIARIES_FORM_SET_VALID)
    ) {
        return personalDetailsBeneficiariesFormRules(store, next, action);
    }

    if (
        action.type.startsWith(HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALUE) ||
        action.type.startsWith(HEALTH_INSURANCE_RW_AGENT_MODAL_DETAILS_SET_VALID)
    ) {
        return agentModaleFormRules(store, next, action);
    }

    if (
        action.type.startsWith(HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE) ||
        action.type.startsWith(HEALTH_INSURANCE_QOUTE_FORM_SET_VALID)
    ) {
        return healthQuoteFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MEDICAL_HISTORY_FORM_SET_VALUE) ||
        action.type.startsWith(MEDICAL_HISTORY_FORM_SET_VAVID)
    ) {
        return medicalHistoryFormRules(store, next, action);
    }

    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
		console.log("GJHSD")
    	return shareQuoteFormRules(store, next, action);
    }

    return null;
};
