export const AfyaImara_QUOTE_TOOL = 'roaQuoteTools/afyaImaraQuoteTool/';
export const AfyaImara_QUOTE_TOOL_SET_VALUE =
    AfyaImara_QUOTE_TOOL + 'setValue/';
export const AfyaImara_QUOTE_TOOL_SET_VALID =
    AfyaImara_QUOTE_TOOL + 'setValid/';

//api status
export const API_PENDING_TRANSITION_AfyaImara_QUOTE_TOOL = `${AfyaImara_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_FAILURE_TRANSITION_AfyaImara_QUOTE_TOOL = `${AfyaImara_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_SUCCESS_TRANSITION_AfyaImara_QUOTE_TOOL = `${AfyaImara_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionAfyaImaraQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_AfyaImara_QUOTE_TOOL }),
    success: () => ({ type: API_FAILURE_TRANSITION_AfyaImara_QUOTE_TOOL }),
    failure: () => ({ type: API_SUCCESS_TRANSITION_AfyaImara_QUOTE_TOOL }),
};

export const FORM_VALID_AfyaImara_QUOTE_TOOL =
    'roaQuoteTools/AfyaImaraQuoteTool//transition/formStateValid';
export const FORM_INVALID_AfyaImara_QUOTE_TOOL =
    'roaQuoteTools/AfyaImaraQuoteTool//transition/formInvalid';
export const REINITIALIZE =
    'roaQuoteTools/AfyaImaraQuoteTool/state/reinitialize';

export const transitionAfyaImaraTool = {
    formValid: () => ({ type: FORM_VALID_AfyaImara_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_AfyaImara_QUOTE_TOOL }),
};
//collect 2
export const COLLECT_AFYAIMARA_PREMIUM_DATA =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'collectAfyaImaraPremiumData';
export const CALCULATE_AFYAIMARA_PREMIUM_DATA =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_AFYAIMARA_PREMIUM_DATA_FAILURE =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'failure';

export const WHICH_FORM_TO_DISPLAY =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';
//collect
export const collectAfyaImaraPremiumData = () => ({
    type: COLLECT_AFYAIMARA_PREMIUM_DATA,
});
export const calculateAfyaImaraPremiumData = (value) => ({
    type: CALCULATE_AFYAIMARA_PREMIUM_DATA,
    value,
});
export const collectAfyaImaraPremiumDataFailure = (error) => ({
    type: CALCULATE_AFYAIMARA_PREMIUM_DATA_FAILURE,
    error,
});

// children
export const AGE_18child_SET_VALUE =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'child_18';
export const AGE_18child_SET_VALID =
    AfyaImara_QUOTE_TOOL_SET_VALID + 'child_18';

///other
export const AGE_BRACKET_SET_VALUE =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'ageBracket';
export const AGE_BRACKET_SET_VALID =
    AfyaImara_QUOTE_TOOL_SET_VALID + 'ageBracket';
export const OUT_PATIENT_SET_VALUE =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'outPatient';
export const OUT_PATIENT_SET_VALID =
    AfyaImara_QUOTE_TOOL_SET_VALID + 'outPatient';
export const SPOUSE_AGE_SET_VALUE =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'spouseAge';
export const SPOUSE_AGE_SET_VALID =
    AfyaImara_QUOTE_TOOL_SET_VALID + 'spouseAge';
export const SPOUSE_COVER_SET_VALUE =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'spouseCover';
export const SPOUSE_COVER_SET_VALID =
    AfyaImara_QUOTE_TOOL_SET_VALID + 'spouseCover';
export const CC_SET_VALUE = AfyaImara_QUOTE_TOOL_SET_VALUE + 'childCover';
export const CC_SET_VALID = AfyaImara_QUOTE_TOOL_SET_VALID + 'childCover';
export const IN_PATIENT_SET_VALUE =
    AfyaImara_QUOTE_TOOL_SET_VALUE + 'inPatient';
export const IN_PATIENT_SET_VALID =
    AfyaImara_QUOTE_TOOL_SET_VALID + 'inPatient';

export const setAgeBracketValue = (value) => ({
    type: AGE_BRACKET_SET_VALUE,
    value,
});
export const setAgeBracketValid = (error) => ({
    type: AGE_BRACKET_SET_VALID,
    error,
});
export const setSpouseAgeValue = (value) => ({
    type: SPOUSE_AGE_SET_VALUE,
    value,
});
export const setSpouseAgeValid = (error) => ({
    type: SPOUSE_AGE_SET_VALID,
    error,
});
export const setSpouseCoverValue = (value) => ({
    type: SPOUSE_COVER_SET_VALUE,
    value,
});
export const setSpouseCoverValid = (error) => ({
    type: SPOUSE_COVER_SET_VALID,
    error,
});
export const setChildrenCoverValue = (value) => ({ type: CC_SET_VALUE, value });
export const setChildrenCoverValid = (error) => ({ type: CC_SET_VALID, error });
export const setInPatientValue = (value) => ({
    type: IN_PATIENT_SET_VALUE,
    value,
});
export const setInPatientValid = (error) => ({
    type: IN_PATIENT_SET_VALID,
    error,
});
export const setOutPatientValue = (value) => ({
    type: OUT_PATIENT_SET_VALUE,
    value,
});
export const setOutPatientValid = (error) => ({
    type: OUT_PATIENT_SET_VALID,
    error,
});
export const setWhichFormToDisplay = (value) => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});
export const setToAfyaImaraQuoteInitial = () => ({ type: REINITIALIZE });

///children
export const set18childValue = (value) => ({
    type: AGE_18child_SET_VALUE,
    value,
});
export const set18childValid = (error) => ({
    type: AGE_18child_SET_VALID,
    error,
});
