import {
    CLEAR_SCROLL_TRANSITION_TFSA,
    SCROLL_TO_CLASS_TRANSITION_TFSA,
    SCROLL_TO_ID_TRANSITION_TFSA,
} from "../../actions/taxFreeSavingsAccount/scrolling";

export const initScrolling = {
    scrollToClass: null,
    scrollToId: null
};

export default (state = initScrolling, action) => {
    switch (action.type) {
        case SCROLL_TO_CLASS_TRANSITION_TFSA:
            return {...state, scrollToClass: action.value};

        case SCROLL_TO_ID_TRANSITION_TFSA:
            return {...state, scrollToId: action.value};

        case CLEAR_SCROLL_TRANSITION_TFSA:
            return { ...initScrolling };

        default:
            return state;
    }
}