import {validateCoverYears, validateNumber, validateDateOfBirthNextBirthDay} from "@om/validation";
import { validationIsEqual } from "../status";
import {
	CANDIDATE_DOB_SET_VALUE,
	COVER_AMOUNT_SET_VALUE,
	setCandidateDOBValid, setCoverAmountValid, setNumberOfYearsCoveredValid, YEARS_COVERED_SET_VALUE
} from "../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool";

let prevValidationAction = null
export const accidentalDeathCoverQuoteToolValidation = (store, next, action) => {
	const validationAction = (() => {
		let error;
		switch (action.type) {
			case CANDIDATE_DOB_SET_VALUE:
				error = validateDateOfBirthNextBirthDay(action.value, { min: 15, max: 55 });
				return setCandidateDOBValid(error)
			case COVER_AMOUNT_SET_VALUE:
				error = validateNumber(action.value, {min: 200000, max:15000000})
				return setCoverAmountValid(error)
			case YEARS_COVERED_SET_VALUE:
				const {roaQuoteTools:{accidentalDeathCoverQuoteTool: {form: {candidateDOB}}}} = store.getState()
				error = validateCoverYears(action.value, {min: 10, max:50, dateValue: candidateDOB.value, termExpiry: 65})
				return setNumberOfYearsCoveredValid(error)
			default:
				return null
		}
	})()
	if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
		return next(action);
	}

	store.dispatch(validationAction)
	prevValidationAction = validationAction
	return next(action)
}
