import {
    setNextQuestion,
    fetchKbaScore,
    QUESTION_EXPIRED_PERFORM_ACTION,
    MODAL_SET_VALUE,
    setKbaModal
} from "../../../actions/customerVerificationService/knowledgeBasedAuthentication";
import {navigateBackToParentApp} from "../../../actions/customerVerificationService/routing";
import { getAllQuestionsAnswered } from "../../../selectors/customerVerificationService/knowledgeBasedAuthentication"
import { CVS_MODALS } from "../../../actions/customerVerificationService/types"

export const kbaRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    if (getAllQuestionsAnswered(state)) {
        store.dispatch(fetchKbaScore());
    } else {
        store.dispatch(setNextQuestion());
    }

    return result;
};
