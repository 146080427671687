export const UG_MOTOR_INSURANCE_CAR_DETAILS_FORM = 'ugMotorInsurance/carDetailsForm/';

export const MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE = UG_MOTOR_INSURANCE_CAR_DETAILS_FORM + 'setValue/';
export const MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID = UG_MOTOR_INSURANCE_CAR_DETAILS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM = 'ugMotorInsurance/carDetailsForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM = 'ugMotorInsurance/carDetailsForm/transition/formInValid';

export const REINITIALIZE = 'ugMotorInsurance/carDetailsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_CAR_DETAILS_FORM = 'ugMotorInsurance/carDetailsForm/transition/dropOffLead';
export const dropOffLeadCarDetails = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_CAR_DETAILS_FORM,
    value,
});

export const transitionMotorInsuranceCarDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_CAR_DETAILS_FORM = `${MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_CAR_DETAILS_FORM = `${MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_CAR_DETAILS_FORM = `${MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitioncarDetailsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_CAR_DETAILS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_CAR_DETAILS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_CAR_DETAILS_FORM,
    }),
};

//calculated Data
export const COLLECT_CAR_DETAILS_FORM_DATA = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'collectCarDetailsData';
export const SUBMIT_CAR_DETAILS_SUCCESS = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'success';
export const SUBMIT_CAR_DETAILS_FAILURE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'failure';

export const collectCarDetailsData = () => ({
    type: COLLECT_CAR_DETAILS_FORM_DATA,
});
export const submitCarDetailsDataSuccess = (value) => ({
    type: SUBMIT_CAR_DETAILS_SUCCESS,
    value,
});
export const submitCarDetailsFailure = (error) => ({
    type: SUBMIT_CAR_DETAILS_FAILURE,
    error,
});

// Form Values
export const REG_NUMBER_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'registrationNumber';
export const REG_NUMBER_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'registrationNumber';

export const CHASIS_NUMBER_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'chasisNumber';
export const CHASIS_NUMBER_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'chasisNumber';

export const BODY_TYPE_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'bodyType';
export const BODY_TYPE_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'bodyType';

export const SITTING_CAPACITY_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'sittingCapacity';
export const SITTING_CAPACITY_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'sittingCapacity';

export const COLOR_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'color';
export const COLOR_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'color';
export const ENGINE_NUMBER_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'engineNumber';
export const ENGINE_NUMBER_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'engineNumber';

export const SELECTED_FORM_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'selectedForm';

export const FUEL_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'fuel';
export const FUEL_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'fuel';
export const YEARS_WITH_LICENSE_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'yearsWithLicense';
export const YEARS_WITH_LICENSE_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'yearsWithLicense';

export const MANUFACTURER_MODEL_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'manufacturerModel';
export const MANUFACTURER_MODEL_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'manufacturerModel';

// action creators
export const setRegistrationNoValue = (value) => ({
    type: REG_NUMBER_SET_VALUE,
    value,
});

export const setRegistrationNoValid = (error) => ({
    type: REG_NUMBER_SET_VALID,
    error,
});

export const setChasisNoValue = (value) => ({
    type: CHASIS_NUMBER_SET_VALUE,
    value,
});

export const setChasisNoValid = (error) => ({
    type: CHASIS_NUMBER_SET_VALID,
    error,
});

export const setBodyTypeValue = (value) => ({
    type: BODY_TYPE_SET_VALUE,
    value,
});

export const setBodyTypeValid = (error) => ({
    type: BODY_TYPE_SET_VALID,
    error,
});

export const setSittingCapacityValue = (value) => ({
    type: SITTING_CAPACITY_SET_VALUE,
    value,
});

export const setSittingCapacityValid = (error) => ({
    type: SITTING_CAPACITY_SET_VALID,
    error,
});

export const setColorValue = (value) => ({
    type: COLOR_SET_VALUE,
    value,
});

export const setColorValid = (error) => ({
    type: COLOR_SET_VALID,
    error,
});

export const setYearWithLicenseValue = (value) => ({
    type: YEARS_WITH_LICENSE_SET_VALUE,
    value,
});
export const setYearWithLicenseValid = (error) => ({
    type: YEARS_WITH_LICENSE_SET_VALID,
    error,
});

export const setFuelValue = (value) => ({
    type: FUEL_SET_VALUE,
    value,
});
export const setFuelValid = (error) => ({
    type: FUEL_SET_VALID,
    error,
});

export const setSelectedFormValue = (value) => ({
    type: SELECTED_FORM_SET_VALUE,
    value,
});

export const setEngineNumberValue = (value) => ({
    type: ENGINE_NUMBER_SET_VALUE,
    value,
});

export const setEngineNumberValid = (error) => ({
    type: ENGINE_NUMBER_SET_VALID,
    error,
});

export const setManufacturerModelValue = (value) => ({
    type: MANUFACTURER_MODEL_SET_VALUE,
    value,
});

export const setManufacturerModelValid = (error) => ({
    type: MANUFACTURER_MODEL_SET_VALID,
    error,
});

export const reinitializeCarDetailsForm = () => ({
    type: REINITIALIZE,
});
