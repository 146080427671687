import {
    FORM_VALID_TRANSITION_SOLUTIONS,
    FORM_INVALID_TRANSITION_SOLUTIONS,
    GUARANTEED_DRAWER_OPEN_SOLUTIONS,
    GUARANTEED_DRAWER_CLOSED_SOLUTIONS,
    LIVING_DRAWER_OPEN_SOLUTIONS,
    LIVING_DRAWER_CLOSED_SOLUTIONS,
    COMPOSITE_DRAWER_OPEN_SOLUTIONS,
    COMPOSITE_DRAWER_CLOSED_SOLUTIONS,
    SOLUTIONS_GUARANTEED_ANNUITY_CHOSEN,
    SOLUTIONS_LIVING_ANNUITY_CHOSEN,
    SOLUTIONS_COMPOSITE_ANNUITY_CHOSEN,
} from '../../actions/atRetirement/solutions'

import { FORM_STATUS } from "../status";
import { OPEN_DRAWER, SOLUTIONS } from "../../actions/atRetirement/types";

export const initSolutions = {
    openDrawer: null,
    formStatus: FORM_STATUS.INVALID,
    form: {
        solutionsProductChosen: null,
    }

};

export default (state = initSolutions, action) => {
    switch(action.type){
        case FORM_VALID_TRANSITION_SOLUTIONS:
            return {...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_SOLUTIONS:
            return {...state, formStatus: FORM_STATUS.INVALID };

        case SOLUTIONS_GUARANTEED_ANNUITY_CHOSEN:
            return {
                ...state,
                form: {
                    solutionsProductChosen: SOLUTIONS.GUARANTEED_ANNUITY
                },
                formStatus: FORM_STATUS.VALID
            };

        case SOLUTIONS_LIVING_ANNUITY_CHOSEN:
            return {
                ...state,
                form: {
                    solutionsProductChosen: SOLUTIONS.LIVING_ANNUITY,
                },
                formStatus: FORM_STATUS.VALID
            };

        case SOLUTIONS_COMPOSITE_ANNUITY_CHOSEN:
            return {
                ...state,
                form: {
                    solutionsProductChosen: SOLUTIONS.COMPOSITE_ANNUITY,
                },
                formStatus: FORM_STATUS.VALID
            };

        case GUARANTEED_DRAWER_OPEN_SOLUTIONS:
            return {...state, openDrawer: OPEN_DRAWER.GUARANTEED_DRAWER};
        case GUARANTEED_DRAWER_CLOSED_SOLUTIONS:
            return {...state, openDrawer: null };

        case LIVING_DRAWER_OPEN_SOLUTIONS:
            return {...state, openDrawer: OPEN_DRAWER.LIVING_DRAWER};
        case LIVING_DRAWER_CLOSED_SOLUTIONS:
            return {...state, openDrawer: null} ;

        case COMPOSITE_DRAWER_OPEN_SOLUTIONS:
            return {...state, openDrawer: OPEN_DRAWER.COMPOSITE_DRAWER};
        case COMPOSITE_DRAWER_CLOSED_SOLUTIONS:
            return {...state, openDrawer: null };

        default:
            return state;
    }
}
