import { validateEmail } from '@om/validation';
import { setShareEmailValid, SET_SHARE_EMAIL_VALUE } from '../../../actions/biasharaFlexi/quote';
import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const shareQuoteValidation = (store, next, action) => {
	const validationAction = (() => {
		let error;
		switch (action.type) {
			case SET_SHARE_EMAIL_VALUE:
				error = validateEmail(action.value, { required: true, subject: 'Email' });
				return setShareEmailValid(error);
			default:
				return null;
		}
	})();
	if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
		return next(action);
	}
	store.dispatch(validationAction);
	prevValidationAction = validationAction;
	return next(action);
};
