import {API_CALL_STATUS, FIELD_STATUS, FORM_STATUS} from '../status';
import * as vehicleDetailsActions from '../../actions/roaCarInsurance/vehicleDetails'

export const initVehicleDetails = {
    formStatus: FORM_STATUS.INVALID,
    vehicleDetailsData: { value: null, error: null, status: null },
    form: {
        registrationNumber: {value: null, error: null, status: null},
        chassisNumber: {value: null, error: null, status: null},
        engineNumber: {value: null, error: null, status: null},
        coverDate: {value: null, error: null, status: null},
        identificationMeans: {value: null, error: null, status: null},
        identificationMeansData: {value: null, error: null, status: null},
    }
}

export default (state = initVehicleDetails, action) => {
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case vehicleDetailsActions.FORM_VALID_VEHICLE_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case vehicleDetailsActions.FORM_INVALID_VEHICLE_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case vehicleDetailsActions.IDENTIFICATION_MEANS_DATA_SET_VALUE:
            return updateFormField('identificationMeansData', (field) => ({
                ...field,
                value: action.value
            }))
        case vehicleDetailsActions.IDENTIFICATION_MEANS_DATA_SET_VALID:
            return updateFormField('identificationMeansData', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }))

        case vehicleDetailsActions.REGISTRATION_NUMBER_SET_VALUE:
            return updateFormField('registrationNumber', (field) => ({
                ...field,
                value: action.value
            }))
        case vehicleDetailsActions.REGISTRATION_NUMBER_SET_VALID:
            return updateFormField('registrationNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }))
        case vehicleDetailsActions.CHASSIS_NUMBER_SET_VALUE:
            return updateFormField('chassisNumber', (field) => ({
                ...field,
                value: action.value
            }))
        case vehicleDetailsActions.CHASSIS_NUMBER_SET_VALID:
            return updateFormField('chassisNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }))
        case vehicleDetailsActions.ENGINE_NUMBER_SET_VALUE:
            return updateFormField('engineNumber', (field) => ({
                ...field,
                value: action.value
            }))
        case vehicleDetailsActions.ENGINE_NUMBER_SET_VALID:
            return updateFormField('engineNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }))
        case vehicleDetailsActions.COVER_DATE_SET_VALUE:
            return updateFormField('coverDate', (field) => ({
                ...field,
                value: action.value
            }))
        case vehicleDetailsActions.COVER_DATE_SET_VALID:
            return updateFormField('coverDate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }))
        case vehicleDetailsActions.IDENTIFICATION_MEANS_SET_VALUE:
            return updateFormField('identificationMeans', (field) => ({
                ...field,
                value: action.value
            }))
        case vehicleDetailsActions.IDENTIFICATION_MEANS_SET_VALID:
            return updateFormField('identificationMeans', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }))
        case vehicleDetailsActions.CALCULATE_VEHICLE_DETAILS_SUCCESS:
            return {
                ...state,
                vehicleDetailsData: {
                    ...state.vehicleDetailsData,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS }
            };
        case vehicleDetailsActions.COLLECT_VEHICLE_DETAILS_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            };

        //    API Call
        case vehicleDetailsActions.API_PENDING_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case vehicleDetailsActions.API_SUCCESS_TRANSITION:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case vehicleDetailsActions.API_FAILURE_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
                vehicleDetailsData: {
                    ...state.vehicleDetailsData,
                    error: action.error,
                    status: API_CALL_STATUS.FAILURE,
                }
            };
        default:
            return state;
    }
}
