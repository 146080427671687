import { transitionMotorInsurancePersonalDetailsForm } from '../../../actions/ugMotorInsurance/personalDetails';
import { getPersonalDetailsForm } from '../../../selectors/ugMotorInsurance/personalDetails';

import { FIELD_STATUS } from '../../../reducers/status';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const personalDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const personalDetailsFormValid = validatePersonalDetailsFormDetails(personalDetailsForm);

    const formAction = personalDetailsFormValid
        ? transitionMotorInsurancePersonalDetailsForm.formValid()
        : transitionMotorInsurancePersonalDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
    const commonChecks =
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.gender &&
        formFields.gender.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.postalAddress &&
        formFields.postalAddress.status === FIELD_STATUS.VALID &&
        formFields.tinNumber &&
        formFields.tinNumber.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID;

    if (gatsbyStack === 'ug') {
        return (
            commonChecks &&
            formFields.residentialStatus &&
            formFields.residentialStatus.status === FIELD_STATUS.VALID &&
            formFields.townOrCity &&
            formFields.townOrCity.status === FIELD_STATUS.VALID &&
            formFields.address &&
            formFields.address.status === FIELD_STATUS.VALID
        );
    } else {
        return (
            commonChecks &&
            formFields.occupation &&
            formFields.occupation.status === FIELD_STATUS.VALID &&
            formFields.nationality &&
            formFields.nationality.status === FIELD_STATUS.VALID
        );
    }
};
