import { createSelector } from 'reselect';
import { getUgMotorInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getQuote = createSelector(getUgMotorInsuranceState, (state) => {
    return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculationUg = createSelector(getQuote, (quote) => quote && quote.premiumCalculationUg);

export const getEnableDownloadQuote = createSelector(getQuote, (quote) => quote && quote.enableDownloadQuote);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.SUCCESS
);
export const getQuoteFormApiCallFailure = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.FAILURE
);
export const getIsApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.PENDING
);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);
export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus);
export const getUgCmbObject = createSelector(getQuote, (quote) => quote && quote.ugCmbObject);
const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getIsExpiredAuth = createSelector(getQuote, (quote) => quote && quote.isTokenExpired);
export const getIsMakesApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.carMakesApiStatus === API_CALL_STATUS.PENDING
);
export const getIsModelsApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.carModelsApiStatus === API_CALL_STATUS.PENDING
);

export const getIsMakesApiStatus = createSelector(getQuote, (quote) => quote && quote.carMakesApiStatus);
export const getIsModelsApiStatus = createSelector(getQuote, (quote) => quote && quote.carModelsApiStatus);
export const getIsValuersApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.valuersApiCallStatus === API_CALL_STATUS.PENDING
);
export const getValuers = createSelector(getQuote, (quote) => quote && quote.valuers);

export const getFullNameValue = makeGetFormField('fullName');
export const getPhoneNumberValue = makeGetFormField('phoneNumber');
export const getEmailValue = makeGetFormField('email');
export const getIdNumberValue = makeGetFormField('idNumber');
export const getCarMakeValue = makeGetFormField('carMake');
export const getCarModelValue = makeGetFormField('carModel');
export const getYearOfManufactureValue = makeGetFormField('yearOfManufacture');
export const getIsCarValuedValue = makeGetFormField('isCarValued');
export const getCoverStartDateValue = makeGetFormField('coverStartDate');
export const getCarUseValue = makeGetFormField('carUse');
export const getCarValuerValue = makeGetFormField('carValuer');
export const getHasAlarmValue = makeGetFormField('hasAlarm');
export const getValuationValue = makeGetFormField('valuation');
export const getExcessProtectorValue = makeGetFormField('excessProtector');
export const getUncommonRiskValue = makeGetFormField('uncommonRisk');
export const getViolenceAndTerrorismValue = makeGetFormField('violenceAndTerrorism');
export const getShareEmailValue = makeGetFormField('shareEmail');
export const getSelectedCover = makeGetFormField('selectedCover');
export const getIsCarRare = makeGetFormField('isCarRare');
export const getOtherValuer = makeGetFormField('otherValuer');
export const getTrackingSystemValue = makeGetFormField('trackingSystem');
export const getCourtesyCarValue = makeGetFormField('courtesyCar');
export const getCourtesyCarAmountValue = makeGetFormField('courtesyCarAmount');
export const getVehicleTypeValue = makeGetFormField('vehicleType');
export const getCarMakesValue = makeGetFormField('carMakes');
export const getCarModelsValue = makeGetFormField('carModels');
export const getPersonalAccidentValue = makeGetFormField('personalAccident');
export const getOutsideEaValue = makeGetFormField('outsideEa');
export const getFirstTimePurchaseValue = makeGetFormField('firstTimePurchase');
export const getShareEmailBase64Value = makeGetFormField('shareEmailBase64');
export const getAlternativeAccomodationValue = makeGetFormField('alternativeAccomodation');
export const getCarHireValue = makeGetFormField('carHire');
export const getAlternativeAccomodationDaysValue = makeGetFormField('alternativeAccomodationDays');
export const getCarHireDaysValue = makeGetFormField('carHireDays');
export const getExcessAmountValue = makeGetFormField('excessAmount');
