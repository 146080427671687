import {GET_APPLICABLE_REPAYMENT_TERMS} from "../../../actions/personalLoansCalculator/referenceData";
import {applicableRepaymentTermService} from "./applicableRepaymentTerms";
import {CALCULATE_TRANSITION_LOAN_RESULTS} from "../../../actions/personalLoansCalculator/calculator";
import {installmentCalculationService} from "./installmentCalculation";

export const personalLoansCalculatorService = (store, next, action) => {
    if (action.type === GET_APPLICABLE_REPAYMENT_TERMS) {
        return applicableRepaymentTermService(store, next, action);
    }

    if (action.type === CALCULATE_TRANSITION_LOAN_RESULTS) {
        return installmentCalculationService(store, next, action);
    }

    return null;
};
