import { validateName, validateContactNumber, validateSingleSelection, validateNumber } from '@om/validation';

import { setPersonalDetailsFieldValid } from '../../../actions/allsureInsurance/personalDetails';
import { validateEmailAddress } from '@om/validation/src/validators/email-validator';
import {
    validateAlphaNumericAllowingOnlyNumbersWithoutSpace,
    validateAlphaNumericString,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateFullName,
    validateNumericString,
} from '@om/validation/src/validators/string-validator';
import { setAdditionalHomeMotorDetailsFieldValid } from '../../../actions/allsureInsurance/purchaseAdditionalHomeandMotor';

let prevValidationAction = null;

export const additionalHomeMotorDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        const trimValue = (value) => (value ? value.trim() : value);
        const removeWhiteSpacesFromValue = (value) => (value ? value.replace(/ /g, '') : value);
        let error;
        switch (action.value.fieldType) {
            // HOME DETAILS VALIDATIONS
            case 'burglarBarsDetails':
                error = validateSingleSelection(action.value.value, 'One of the above');
                break;
                
            case 'alarmSystemDetails':
                error = validateSingleSelection(action.value.value, 'One of the above');
                break;

            case 'serviceProviderDetails':
                error = validateAlphaNumericString(action.value.value, {
                    min: 3,
                    max: 66,
                    required:true,
                    subject: 'Service Provider',
                })

                break;
            case 'steelFenceDetails':
                error = validateSingleSelection(action.value.value, 'One of the above');
                break;

            case 'securityGateDetails':
                error = validateSingleSelection(action.value.value, 'One of the above');
                break;

            case 'valuationReportDetails':
                error = validateSingleSelection(action.value.value, 'One of the above');
                break;

            case 'valuationReportConfirmation':
                error = validateSingleSelection(action.value.value, 'please confirm');
                break;

            case 'householdLoss':
                error = validateSingleSelection(action.value.value, 'please confirm');
                break;

            case 'claimAmount':
                    error = validateNumber(action.value.value, { min: 1, decimalPoints: 2 });
                    break;
    
            case 'claimedCount':
                    error = validateNumber(action.value.value, { min: 1, decimalPoints: 0 });
                    break;
            
            case 'similarAgreementDetails':
                error = validateSingleSelection(action.value.value, 'please confirm');
                break;

            case 'agreementServiceProvider':
                error = validateAlphaNumericString(action.value.value, {
                    min: 3,
                    max: 66,
                    required:true,
                    subject: 'service provider',
                })
                break;
                
            case 'financialInstitution':
                error = validateAlphaNumericString(action.value.value, {
                    min: 3,
                    max: 66,
                    required:true,
                    subject: 'Financial institution',
                })
                break;
                
            // MOTOR DETAILS VALIDATIONS
            case 'registrationNumberDetails':
                error = validateAlphaNumericAllowingOnlyNumbersWithoutSpace(action.value.value, {
                    min: 3,
                    subject: 'Registration number',
                });
                break;
            case 'chassisNumberDetails':
                error = validateAlphaNumericAllowingOnlyNumbersWithoutSpace(action.value.value, {
                    min: 3,
                    subject: 'Chasis number',
                });
                break;

            case 'motorServiceProvider':
                error = validateAlphaNumericString(action.value.value, {
                    min: 3,
                    max: 66,
                    required: true,
                    subject: 'Service Provider',
                });
                break;

            case 'engineNumberDetails':
                error = validateAlphaNumericAllowingOnlyNumbersWithoutSpace(action.value.value, {
                    min: 3,
                    subject: 'Engine number',
                });
                break;

            case 'similarAgreementDetails':
                error = validateSingleSelection(action.value.value, 'Agreement Details');
                break;
            

            case 'motorvaluationReportDetails':
                error = validateSingleSelection(action.value.value, 'One of the above');
                break;

            case 'motorvaluationReportConfirmation':
                error = validateSingleSelection(action.value.value, 'please confirm');
                break;

            default:
                return null;
        }

        return setAdditionalHomeMotorDetailsFieldValid(error, action.value);
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
