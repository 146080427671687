import {transitionAboutYou} from "../../../actions/atRetirement/aboutYou";
import {transitionScrolling} from '../../../actions/atRetirement/scrolling';
import {getAboutYouForm} from "../../../selectors/atRetirement/aboutYou";
import {transitionWizardFooter} from "../../../actions/atRetirement/wizardFooter";
import {FIELD_STATUS} from "../../../reducers/status";
import {RETIREMENT_FOR} from "../../../actions/atRetirement/types";
import {ABOUT_YOU_SCROLL_CLASS} from "../status";

export const aboutYouRules = (store, next, action) => {
    const result = next(action);
    const aboutYouForm = getAboutYouForm(store.getState());

    // Call all validation
    const personalInformationValid = validatePersonalInformation(aboutYouForm);
    const retirementWorriesValid = validateRetirementWorries(aboutYouForm);
    const retirementFinancialValid = validateFinancial(aboutYouForm);

    // Personal Information
    if (personalInformationValid && (!retirementWorriesValid || !retirementFinancialValid)) {
        store.dispatch(transitionScrolling.scrollToClass(ABOUT_YOU_SCROLL_CLASS.RETIREMENT_WORRIES));
    }
    store.dispatch(personalInformationValid
        ? transitionAboutYou.formPersonalInformationValid()
        : transitionAboutYou.formPersonalInformationInvalid());

    // Retirement Worries
    if (personalInformationValid && retirementWorriesValid && !retirementFinancialValid) {
        store.dispatch(transitionScrolling.scrollToClass(ABOUT_YOU_SCROLL_CLASS.INCOME_SECTION));
    }
    store.dispatch(retirementWorriesValid
        ? transitionAboutYou.formRetirementWorriesValid()
        : transitionAboutYou.formRetirementWorriesInvalid());

    // Financial
    store.dispatch(retirementFinancialValid
        ? transitionAboutYou.formFinancialValid()
        : transitionAboutYou.formFinancialInvalid());

    // About you Form
    if (personalInformationValid && retirementWorriesValid && retirementFinancialValid) {
        store.dispatch(transitionAboutYou.formValid());

        // Wizard Footer
        store.dispatch(transitionWizardFooter.aboutYouContinueButtonDisabled(false));
    } else {
        store.dispatch(transitionAboutYou.formInvalid());
        // Wizard Footer
        store.dispatch(transitionWizardFooter.aboutYouContinueButtonDisabled(true));
    }

    return result;
};

// Personal Information
const validatePersonalInformation = formFields => {
    const basicValidation = formFields.name && formFields.name.status === FIELD_STATUS.VALID &&
    formFields.age && formFields.age.status === FIELD_STATUS.VALID &&
    formFields.gender && formFields.gender.status === FIELD_STATUS.VALID &&
    formFields.retirementFor && formFields.retirementFor.status === FIELD_STATUS.VALID;

    if (!basicValidation)
        return false;

    // If the option 'myself-and-partner' has been selected then check for partner age valid
    if (formFields.retirementFor.value !== RETIREMENT_FOR.MYSELF_AND_PARTNER)
        return true;

    return formFields.partnerAge && formFields.partnerAge.status === FIELD_STATUS.VALID;
};

// Retirement Worries
const validateRetirementWorries = formFields => {
    return formFields.retirementWorries && formFields.retirementWorries.status === FIELD_STATUS.VALID;
};

// Financial
const validateFinancial = formFields => {
    return formFields.salary && formFields.salary.status === FIELD_STATUS.VALID &&
    formFields.savings && formFields.savings.status === FIELD_STATUS.VALID &&
    formFields.budget && formFields.budget.status === FIELD_STATUS.VALID;
};
