import { validateNumber } from  "@om/validation";
import {
    setFixedMonthlyExpensesValid,
    setDayToDaySpendingValid,
    setDebtsValid,
    setInsuranceValid,
    FIXED_MONTHLY_EXPENSES_SET_VALUE,
    DAY_TO_DAY_SPENDING_SET_VALUE,
    INSURANCE_SET_VALUE,
    DEBTS_SET_VALUE,
} from "../../../actions/atRetirement/budgetTool";
import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const budgetToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIXED_MONTHLY_EXPENSES_SET_VALUE:
                error = validateNumber(action.value, {min: 0, max: 1000000000000, decimalPoints: 2 });
                return setFixedMonthlyExpensesValid(error);
            case DAY_TO_DAY_SPENDING_SET_VALUE:
                error = validateNumber(action.value, {min: 0, max: 1000000000000, decimalPoints: 2 });
                return setDayToDaySpendingValid(error);
            case INSURANCE_SET_VALUE:
                error = validateNumber(action.value, {min: 0, max: 1000000000000, decimalPoints: 2 });
                return setInsuranceValid(error);
            case DEBTS_SET_VALUE:
                error = validateNumber(action.value, {min: 0, max: 1000000000000, decimalPoints: 2 });
                return setDebtsValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
