import axios from 'axios';
import {
    FORM_SUBMIT_STATUS,
} from '../../../reducers/status';
import {
    SIGNUP_SEND_DETAILS,
    formSubmitStatusSetValue
} from '../../../actions/publicWeb/signupAndDownload';
import { getIsApiSubmitting } from '../../../selectors/publicWeb/signupAndDownload'
export const signupAndDownloadService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (action.type === SIGNUP_SEND_DETAILS && !getIsApiSubmitting(state)) {
            store.dispatch(formSubmitStatusSetValue(FORM_SUBMIT_STATUS.SUBMITTING));
            try{
               const response = await sendSignupAndDownloadInfo(action.value);
                if(response){
                    store.dispatch(formSubmitStatusSetValue(FORM_SUBMIT_STATUS.SUCCESS));
                }else{
                    store.dispatch(formSubmitStatusSetValue(FORM_SUBMIT_STATUS.ERROR));
                }
            } catch(e) {
                store.dispatch(formSubmitStatusSetValue(FORM_SUBMIT_STATUS.ERROR));
            }
        }
    })();

    return result;
};

export const sendSignupAndDownloadInfo = async (signupDetails) => {
    try {
        const headers = {'Access-Control-Allow-Origin': '*',};

        const response = await axios.post('/om-api/sign-up-download', signupDetails, {headers});

        return response.status === 200;

    } catch (error) {
        return false;
    }
}