import { validateNumber, validateAlphaString } from '@om/validation';
import {
    setAgeValid,
    setIncomeFrequencyValid,
    setIncomeValueValid,
    AGE_SET_VALUE,
    INCOME_FREQUENCY_SET_VALUE,
    INCOME_VALUE_SET_VALUE,
} from '../../../actions/incomeTaxCalculator/basicCalculator';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const basicCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AGE_SET_VALUE:
                error = validateAlphaString(action.value, { subject: 'Age' });
                return setAgeValid(error);

            case INCOME_FREQUENCY_SET_VALUE:
                error = validateAlphaString(action.value, { subject: 'income frequency' });
                return setIncomeFrequencyValid(error);

            case INCOME_VALUE_SET_VALUE:
                error = validateNumber(action.value, { min: 0, max: 9999999, subject: 'income' });
                return setIncomeValueValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
