export const DOCTORS_PI_QOUTE_FORM = 'doctorsPi/quoteForm/';
export const DOCTORS_PI_QOUTE_FORM_SET_VALUE = DOCTORS_PI_QOUTE_FORM + 'setValue/';
export const DOCTORS_PI_QOUTE_FORM_SET_VALID = DOCTORS_PI_QOUTE_FORM + 'setValid/';

export const DOCTORS_PI_SHARE_QOUTE_FORM_SET_VALUE = DOCTORS_PI_QOUTE_FORM + '/share/setValue/';
export const DOCTORS_PI_SHARE_QOUTE_FORM_SET_VALID = DOCTORS_PI_QOUTE_FORM + '/share/setValid/';

export const DOCTORS_PI_QUOTE_ADD_COVER = DOCTORS_PI_QOUTE_FORM + 'addCover/';	
export const FORM_VALID_DOCTORS_PI_QUOTE_FORM = 'doctorsPi/quoteForm/transition/formValid';
export const FORM_INVALID_DOCTORS_PI_QUOTE_FORM = 'doctorsPi/quoteForm/transition/formInValid';
export const FORM_VALID_DOCTORS_PI_QUOTE_SHARE_FORM = 'doctorsPi/shareQuoteForm/transition/formValid';
export const FORM_INVALID_DOCTORS_PI_QUOTE_SHARE_FORM = 'doctorsPi/shareQuoteForm/transition/formInValid';
export const DOCTORS_PI_SHARE_QUOTE_VALUE = DOCTORS_PI_QOUTE_FORM + 'shareQuote';

export const TOKEN_IS_EXPIRED = 'doctor/expiredAuth';


export const DOCTORS_PI_SIDEBAR_OPEN_VALUE = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'sidebarOpen'

export const REINITIALIZE = DOCTORS_PI_QOUTE_FORM + 'state/reinitialize';

// VALIDATE QUOTE FORM

export const transitionDoctorPiQuoteForm = { 

		formValid: () => ({
			type: FORM_VALID_DOCTORS_PI_QUOTE_FORM,
		}),
		formInvalid: () => ({
			type: FORM_INVALID_DOCTORS_PI_QUOTE_FORM,
		}),
	};

export const transitionShareQuoteForm = {
	formInvalid: () => ({ 
		type: FORM_INVALID_DOCTORS_PI_QUOTE_SHARE_FORM,
	}),
	formValid: () => ({
		type: FORM_VALID_DOCTORS_PI_QUOTE_SHARE_FORM,
	}),
}

// API Calls actions
export const API_PENDING_TRANSITION_DOCTORS_PI_QUOTE_FORM = `${DOCTORS_PI_QOUTE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DOCTORS_PI_QUOTE_FORM = `${DOCTORS_PI_QOUTE_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DOCTORS_PI_QUOTE_FORM = `${DOCTORS_PI_QOUTE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${DOCTORS_PI_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${DOCTORS_PI_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${DOCTORS_PI_SHARE_QUOTE_VALUE}/apiTransition/failure`;


export const apiTransitionDoctorsPiQuoteForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_DOCTORS_PI_QUOTE_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_DOCTORS_PI_QUOTE_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_DOCTORS_PI_QUOTE_FORM,
	}),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};


//calculated Data
export const COLLECT_DOCTORS_PI_QUOTE_FORM_DATA = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'collectDoctorsPiQuoteData';
export const SUBMIT_DOCTORS_PI_QUOTE_SUCCESS = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'success';
export const SUBMIT_DOCTORS_PI_QUOTE_FAILURE = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_DOCTORS_PI_PREMIUM_SUCCESS = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_DOCTORS_PI_PREMIUM_FAILURE = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'failure';
export const COLLECT_SHARE_QUOTE_FORM_DATA = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_DOCTORS_PI_QUOTE_SUCCESS = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_DOCTORS_PI_QUOTE_FAILURE = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'shareQuoteFailure';

export const collectDoctorsPiQuoteData = () => ({
	type: COLLECT_DOCTORS_PI_QUOTE_FORM_DATA,
});

export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

export const submitDoctorsPiQuoteSuccess = () => ({
	type: SUBMIT_DOCTORS_PI_QUOTE_SUCCESS,
	value,
});

export const shareQuoteSuccess = (value) => ({
    type: SHARE_DOCTORS_PI_QUOTE_SUCCESS,
    value,
})

export const shareQuoteFailure = (error) => ({
    type: SHARE_DOCTORS_PI_QUOTE_FAILURE,
    error,
})

export const calculateDoctorsPiPremiumSuccess = (value) => ({
	type: CALCULATE_DOCTORS_PI_PREMIUM_SUCCESS,
	value,
});

export const submitDoctorsPiQuoteFailure = () => ({
	type: SUBMIT_DOCTORS_PI_QUOTE_FAILURE,
	error,
});

// Form values
export const SET_LIMIT_INDEMNITY_VALUE = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'limitIndemnity';
export const SET_LIMIT_INDEMNITY_VALID = DOCTORS_PI_QOUTE_FORM_SET_VALID + 'limitIndemnity';
export const SET_SPECIALTIES_VALUE = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'specialties';
export const SET_SPECIALTIES_VALID = DOCTORS_PI_QOUTE_FORM_SET_VALID + 'specialties';
export const SET_SHARE_EMAIL_VALUE = DOCTORS_PI_SHARE_QOUTE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = DOCTORS_PI_SHARE_QOUTE_FORM_SET_VALID + 'shareEmailValid';
export const SET_SELECTED_COVER_VALUE = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'selectDoctorsCover';
export const SET_SELECTED_COVER_VALID = DOCTORS_PI_QOUTE_FORM_SET_VALUE + 'selectDoctorsCover';


// action creators


export const setSelectedCoverValue = (value) => ({type: SET_SELECTED_COVER_VALUE, value});
export const setSelectedCoverValid = error => ({type: SET_SELECTED_COVER_VALID, error});

export const setLimitIndemnityValue = value => ({
	type: SET_LIMIT_INDEMNITY_VALUE,
	value,
});

export const setLimitIndemnityValid = error => ({
	type: SET_LIMIT_INDEMNITY_VALID,
	error,
})

export const setSpecialtiesValue = value => ({
	type: SET_SPECIALTIES_VALUE,
	value,
});

export const setSpecialtiesValid = error => ({
	type: SET_SPECIALTIES_VALID,
	error,
});

export const setShareEmailValue = value => ({
	type: SET_SHARE_EMAIL_VALUE,
	value,
});

export const setShareEmailValid = error => ({
	type: SET_SHARE_EMAIL_VALID,
	error,
});



export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value
});


export const addCover = (value) => ({
	type: DOCTORS_PI_QUOTE_ADD_COVER,
	value,
})

export const initializeQuoteForm = () => ({
	type: REINITIALIZE,
})


export const setSideBarOpen = (value) => ({ type: DOCTORS_PI_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: DOCTORS_PI_SHARE_QUOTE_VALUE, value });

