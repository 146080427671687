import { createSelector } from "reselect";
import { API_CALL_STATUS, FORM_STATUS } from '../../reducers/status';

export const getFuneralClaim = state => state.publicWeb.funeralClaim;

const makeGetField = fieldId => createSelector(
    getFuneralClaim,
    funeralClaim => funeralClaim[fieldId]
);

export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getCellphoneNumber = makeGetField('cellphoneNumber');
export const getIsUserPolicyHolder = makeGetField('isUserPolicyHolder');
export const getRequesterIdNumber = makeGetField('requesterIdNumber');
export const getDeceasedIdNumber = makeGetField('deceasedIdNumber');
export const getFuneralDate = makeGetField('funeralDate');
export const getBank = makeGetField('bank');
export const getBankOther = makeGetField('bankOther');
export const getBranchCode = makeGetField('branchCode');
export const getBankAccountNumber = makeGetField('bankAccountNumber');
export const getBankAccountType = makeGetField('bankAccountType');
export const getAgreeToTerms = makeGetField('agreeToTerms');
export const getSidebarOpen = makeGetField('sidebarOpen');

export const getShowBankOther = createSelector(
    getFuneralClaim,
    funeralClaim => {
        return funeralClaim.bank === 'OTHER';
    }
);

export const getBtnText = createSelector(
    getFuneralClaim,
    funeralClaim => {
        switch (funeralClaim.apiCallStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'SUBMITTED';
            case API_CALL_STATUS.FAILURE:
                return 'TRY AGAIN';
            case API_CALL_STATUS.PENDING:
                return 'SUBMITTING...';
            default:
                return 'SUBMIT';
        }
    }
);

export const getInputDisabled = createSelector(
    getFuneralClaim,
    funeralClaim => {
        return funeralClaim.apiCallStatus === API_CALL_STATUS.PENDING ||
            funeralClaim.apiCallStatus === API_CALL_STATUS.SUCCESS;
    }
);

export const getDisableSubmit = createSelector(
    getFuneralClaim,
    funeralClaim => funeralClaim.formStatus === FORM_STATUS.INVALID || !funeralClaim.agreeToTerms,
);

export const getShowErrorMsg = createSelector(
    getFuneralClaim,
    funeralClaim => funeralClaim.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getShowSuccessMsg = createSelector(
    getFuneralClaim,
    funeralClaim => funeralClaim.apiCallStatus === API_CALL_STATUS.SUCCESS,
);

export const getShowAcceptTermsMsg = createSelector(
    getFuneralClaim,
    funeralClaim => funeralClaim.formStatus === FORM_STATUS.VALID && !funeralClaim.agreeToTerms,
);

export const getDontFireApi = createSelector(
    getFuneralClaim,
    funeralClaim => funeralClaim.apiCallStatus === API_CALL_STATUS.PENDING ||
        funeralClaim.apiCallStatus === API_CALL_STATUS.SUCCESS,
);

export const getFuneralClaimsOpenDrawer = createSelector(
    getFuneralClaim,
    funeralClaim => funeralClaim.sidebarOpen
);

function getUniversalBranchCode(bank) {
    switch (bank) {
        case 'STANDARD_BANK':
            return '051001';
        case 'NEDBANK':
            return '198765';
        case 'ABSA':
            return '632005';
        case 'CAPITEC':
            return '470010';
        case 'BIDVEST':
            return '462005';
        case 'FNB':
            return '250655';
        default:
            return 'NOT PROVIDED';
    }
}

export const getFuneralClaimPostData = createSelector(
    getFuneralClaim,
    funeralClaim => {
        const postData = {
        firstName: funeralClaim.firstName.value,
            surname: funeralClaim.surname.value,
            cellphoneNumber: funeralClaim.cellphoneNumber.value,
            requesterIdNumber: funeralClaim.requesterIdNumber.value,
            deceasedIdNumber: funeralClaim.deceasedIdNumber.value,
            isUserPolicyHolder: funeralClaim.isUserPolicyHolder,
            // funeralDate: funeralClaim.funeralDate.value,         // Commented out while it is not supported
            bank: funeralClaim.bank,
            bankAccountNumber: funeralClaim.bankAccountNumber.value,
            bankAccountType: funeralClaim.bankAccountType,
        }

        if (funeralClaim.bank === 'OTHER') {
            postData.bank = funeralClaim.bankOther.value;
            postData.branchCode = funeralClaim.branchCode.value;
            if (!postData.branchCode || postData.branchCode.trim() === '') {
                postData.branchCode = 'NOT PROVIDED';
            }
        } else {
            postData.branchCode = getUniversalBranchCode(postData.bank);
        }

        if (funeralClaim.agreeToTerms) {
            postData.agreeToTerms = 'Accepted';
        }

        return postData;
    }
);
