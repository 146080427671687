import { createSelector } from "reselect";
import { getEasiplusFuneralPlan } from "./index";
import { OPEN_DRAWER} from "../../actions/easiplusFuneralPlan/types";
import { FORM_STATUS, CALL_ME_BACK_TYPES, API_CALL_STATUS } from "../../reducers/status";

export const getCallMeBack = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan.callMeBack
);

export const getCallMeBackDrawerIsOpen = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.openDrawer === OPEN_DRAWER.CALL_ME_BACK
);

export const getCallMeBackType = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.callMeBackType,
);

export const getCallMeBackFormIsValid = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.formStatus === FORM_STATUS.VALID,
);

export const getCallMeBackForm = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.form,
);

export const getFormSubmitted = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.formSubmitted,
);

export const getSendCallMeBackStatus = createSelector(
    getCallMeBack,
    getCallMeBackType,
    (callMeBack, type) => {
        if(type === CALL_ME_BACK_TYPES.QUOTE){
            return callMeBack.sendCallMeBackStatusQuote;
        } else if(type === CALL_ME_BACK_TYPES.REPLACEMENT){
            return callMeBack.sendCallMeBackStatusReplacement;
        } else if(type === CALL_ME_BACK_TYPES.GENERIC){
            return callMeBack.sendCallMeBackStatusGeneric;
        }
        return null;
    } 
);

export const getGtmContextUrl = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.gtmContextUrl,
);

export const getGtmContextGaId = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.gtmContextGaId,
);

export const getReplacementProduct = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.replacementProduct,
);

export const getGenericProduct = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.genericProduct,
);

export const getQuoteProduct = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.quoteProduct,
);

const makeGetField = fieldId => createSelector(
    getCallMeBackForm,
    callMeBackForm => callMeBackForm[fieldId]
);


export const getName = makeGetField('name');
export const getSurname = makeGetField('surname');
export const getContactNumber = makeGetField('contactNumber');

export const getCallMeBackButtonText = createSelector(
    getSendCallMeBackStatus,
    sendCallMeBackStatus => {
        switch (sendCallMeBackStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'SEND AGAIN';
            case API_CALL_STATUS.FAILURE:
                return 'TRY AGAIN';
            case API_CALL_STATUS.PENDING:
                return 'SENDING...';
            case API_CALL_STATUS.IDLE:
            default:
                return 'CALL ME BACK';
        }
    }
);

export const getCallMeBackButtonDisabled = createSelector(
    getSendCallMeBackStatus,
    getCallMeBackType,
    (sendCallMeBackStatus, callMeBackType) =>
        sendCallMeBackStatus === API_CALL_STATUS.PENDING ||
        (sendCallMeBackStatus === API_CALL_STATUS.SUCCESS && callMeBackType === CALL_ME_BACK_TYPES.REPLACEMENT)
);

export const getCallMeBackStatusIsSuccess = createSelector(
    getSendCallMeBackStatus,
    sendCallMeBackStatus => sendCallMeBackStatus === API_CALL_STATUS.SUCCESS
);

export const getCallMeBackStatusIsFailure = createSelector(
    getSendCallMeBackStatus,
    sendCallMeBackStatus => sendCallMeBackStatus === API_CALL_STATUS.FAILURE
);
