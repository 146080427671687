import { onFieldChangeStateComparison } from '../onFieldChangeStateComparison';
import { simpleFieldTracking } from '../formTracking';
import {
    TITLE_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    DATE_OF_BIRTH_SET_VALUE,
    GENDER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    EDIT_DRAWER_TOGGLE,
    EDIT_ID_SET,
    REMOVE_ID_SET,
    BENEFICIARY_DELETE,
    SIDE_DRAWER_SAVE,
} from '../../../actions/taxFreeSavingsAccount/beneficiaries';
import {
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getGender,
    getMobileNumber,
    getEmail,
    getEditDrawerIsOpen,
} from '../../../selectors/taxFreeSavingsAccount/beneficiaries';
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const beneficiariesTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case TITLE_SET_VALUE:
                sendError = false;
                return getTitle;
            case FIRST_NAME_SET_VALUE:
                sendValue = false;
                return getFirstName;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurname;
            case DATE_OF_BIRTH_SET_VALUE:
                return getDateOfBirth;
            case GENDER_SET_VALUE:
                sendError = false;
                sendValue = false;
                return getGender;
            case MOBILE_NUMBER_SET_VALUE:
                sendValue = false;
                return getMobileNumber;
            case EMAIL_SET_VALUE:
                sendValue = false;
                return getEmail;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);
    if (field) simpleFieldTracking({ action, field, sendValue, sendError });

    return result;
};

export const beneficiariesOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case EDIT_DRAWER_TOGGLE:
                if (getEditDrawerIsOpen(state)) {
                    otherEventIndex = findOtherEventIndex('OPEN_DRAWER');
                    return 'editDrawerOpened';
                } else {
                    otherEventIndex = findOtherEventIndex('CLOSE_DRAWER');
                    return 'editDrawerClosed';
                }
            case EDIT_ID_SET:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'editBeneficiaryClicked';
            case REMOVE_ID_SET:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'removeBeneficiaryClicked';
            case BENEFICIARY_DELETE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'deleteBeneficiaryClicked';
            case SIDE_DRAWER_SAVE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'saveBeneficiaryClicked';              
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};