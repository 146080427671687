// Transitions - validation
export const FORM_VALID_TRANSITION_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/formValid';
export const FORM_INVALID_TRANSITION_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/formInvalid';
export const FORM_COMPLETE_TRANSITION_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/formComplete';
export const FORM_INCOMPLETE_TRANSITION_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/formIncomplete';

export const transitionLivesCovered = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_LIVES_COVERED }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_LIVES_COVERED }),
    formComplete: () => ({ type: FORM_COMPLETE_TRANSITION_LIVES_COVERED }),
    formIncomplete: () => ({ type: FORM_INCOMPLETE_TRANSITION_LIVES_COVERED }),
};

// Transitions - navigation
export const CONTINUE_TRANSITION_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/validContinue';
export const BACK_TRANSITION_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/back';
export const continueLivesCovered = () => ({ type: CONTINUE_TRANSITION_LIVES_COVERED });
export const backLivesCovered = () => ({ type: BACK_TRANSITION_LIVES_COVERED });

// Transition - reset state
export const RESET_STATE_TRANSITION_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/resetState';
export const resetLivesCovered = () => ({ type: RESET_STATE_TRANSITION_LIVES_COVERED });

export const UPDATE_REFERENCE_COVER_MEMBERS_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/referenceCoverMembers';
export const UPDATE_REFERENCE_COVER_CATEGORIES_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/referenceCoverCategories';
export const UPDATE_COVER_MEMBERS_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/coverMembers';
export const MEMBERS_UPDATED_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/transition/membersUpdated';
export const UPDATE_MYSELF_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/updateMyselfCoverMember';
export const UPDATE_MYSELF_PREMIUM_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/updateMyselfPremium';
export const SYNC_COVER_AMOUNTS_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/syncCoverAmounts';
export const updateReferenceCoverMembers = value => ({ type: UPDATE_REFERENCE_COVER_MEMBERS_LIVES_COVERED, value });
export const updateReferenceCoverCategories = value => ({ type: UPDATE_REFERENCE_COVER_CATEGORIES_LIVES_COVERED, value });
export const updateCoverMembers = value => ({ type: UPDATE_COVER_MEMBERS_LIVES_COVERED, value });
export const membersUpdatedLivesCovered = () => ({ type: MEMBERS_UPDATED_LIVES_COVERED });
export const updateMyselfLivesCovered = value => ({ type: UPDATE_MYSELF_LIVES_COVERED, value });
export const updateMyselfPremiumLivesCovered = value => ({ type: UPDATE_MYSELF_PREMIUM_LIVES_COVERED, value });
export const syncDirectFamilyCoverAmounts = member => ({ type: SYNC_COVER_AMOUNTS_LIVES_COVERED, member })

export const SET_EDIT_COVER_MEMBER_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/editCoverMember';
export const SET_ADD_COVER_MEMBER_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/addCoverMember';
export const setEditCoverMember = member => ({ type: SET_EDIT_COVER_MEMBER_LIVES_COVERED, member });
export const setAddCoverMember = member => ({ type: SET_ADD_COVER_MEMBER_LIVES_COVERED, member });

export const SET_VIEW_MORE_LIVES_COVERED = 'easiplusFuneralPlan/livesCovered/setValue/viewMore';
export const setViewMoreCategory = category => ({ type: SET_VIEW_MORE_LIVES_COVERED, category });

// Transitions - CRUD Beneficiaries
export const LIVES_COVERED_TRANSITION = 'easiplusFuneralPlan/livesCovered/transition/';
export const PUSH_COVER_MEMBER_LIVES_COVERED = LIVES_COVERED_TRANSITION + 'pushCoverMember';
export const ADD_NEW_COVER_MEMBER_LIVES_COVERED = LIVES_COVERED_TRANSITION + 'addNewCoverMember';
export const DELETE_COVER_MEMBER_LIVES_COVERED = LIVES_COVERED_TRANSITION + 'deleteCoverMember';
export const SAVE_COVER_MEMBER_LIVES_COVERED = LIVES_COVERED_TRANSITION + 'saveCoverMember';
export const CANCEL_COVER_MEMBER_LIVES_COVERED = LIVES_COVERED_TRANSITION + 'cancelCoverMember';
export const pushNewCoverMember = () => ({ type: PUSH_COVER_MEMBER_LIVES_COVERED });
export const addNewCoverMember = () => ({ type: ADD_NEW_COVER_MEMBER_LIVES_COVERED });
export const deleteCoverMember = member => ({ type: DELETE_COVER_MEMBER_LIVES_COVERED, member });
export const saveCoverMember = () => ({ type: SAVE_COVER_MEMBER_LIVES_COVERED });
export const cancelCoverMember = () => ({ type: CANCEL_COVER_MEMBER_LIVES_COVERED });

// Events - form input
export const LIVES_COVERED_SET_VALUE = 'easiplusFuneralPlan/livesCovered/setValue/';
export const LIVES_COVERED_SET_VALID = 'easiplusFuneralPlan/livesCovered/setValid/';
export const LIVES_COVERED_SET_CLEAR = 'easiplusFuneralPlan/livesCovered/setClear/';

export const TITLE_SET_VALUE = LIVES_COVERED_SET_VALUE + 'title';
export const TITLE_SET_VALID = LIVES_COVERED_SET_VALID + 'title';
export const TITLE_SET_CLEAR = LIVES_COVERED_SET_CLEAR + 'title';
export const FIRST_NAME_SET_VALUE = LIVES_COVERED_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = LIVES_COVERED_SET_VALID + 'firstName';
export const FIRST_NAME_SET_CLEAR = LIVES_COVERED_SET_CLEAR + 'firstName';
export const SURNAME_SET_VALUE = LIVES_COVERED_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = LIVES_COVERED_SET_VALID + 'surname';
export const SURNAME_SET_CLEAR = LIVES_COVERED_SET_CLEAR + 'surname';
export const DATE_OF_BIRTH_SET_VALUE = LIVES_COVERED_SET_VALUE + 'dateOfBirth';
export const DATE_OF_BIRTH_SET_VALID = LIVES_COVERED_SET_VALID + 'dateOfBirth';
export const DATE_OF_BIRTH_SET_CLEAR = LIVES_COVERED_SET_CLEAR + 'dateOfBirth';
export const AGE_SET_VALUE = LIVES_COVERED_SET_VALUE + 'age';
export const AGE_SET_VALID = LIVES_COVERED_SET_VALID + 'age';
export const AGE_SET_CLEAR = LIVES_COVERED_SET_CLEAR + 'age';
export const COVER_AMOUNT_SET_VALUE = LIVES_COVERED_SET_VALUE + 'coverAmount';
export const COVER_AMOUNT_SET_VALID = LIVES_COVERED_SET_VALID + 'coverAmount';
export const COVER_AMOUNT_SET_CLEAR = LIVES_COVERED_SET_CLEAR + 'coverAmount';

export const MONTHLY_PREMIUM_SET_VALUE = LIVES_COVERED_SET_VALUE + 'monthlyPremium';
export const MONTHLY_PREMIUM_SET_VALID = LIVES_COVERED_SET_VALID + 'monthlyPremium';
export const MONTHLY_PREMIUM_SET_CLEAR = LIVES_COVERED_SET_CLEAR + 'monthlyPremium';

export const MODAL_SET_VALUE = LIVES_COVERED_SET_VALUE + 'modal';
export const MODAL_CLEAR_VALUE = LIVES_COVERED_SET_CLEAR + 'modal';
export const setModalValue = value => ({ type: MODAL_SET_VALUE, value });
export const clearModalValue = value => ({ type: MODAL_CLEAR_VALUE, value });

export const setTitleValue = value => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = error => ({ type: TITLE_SET_VALID, error });
export const setTitleClear = () => ({ type: TITLE_SET_CLEAR });
export const setFirstNameValue = value => ({ type: FIRST_NAME_SET_VALUE, value });
export const setFirstNameValid = error => ({ type: FIRST_NAME_SET_VALID, error });
export const setFirstNameClear = () => ({ type: FIRST_NAME_SET_CLEAR });
export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });
export const setSurnameClear = () => ({ type: SURNAME_SET_CLEAR });
export const setDateOfBirthValue = value => ({ type: DATE_OF_BIRTH_SET_VALUE, value });
export const setDateOfBirthValid = error => ({ type: DATE_OF_BIRTH_SET_VALID, error });
export const setDateOfBirthClear = () => ({ type: DATE_OF_BIRTH_SET_CLEAR });
export const setAgeValue = (value, child = false, myself = false) => ({ type: AGE_SET_VALUE, value, child, myself });
export const setAgeValid = error => ({ type: AGE_SET_VALID, error });
export const setAgeClear = () => ({ type: AGE_SET_CLEAR });
export const setCoverAmountValue = value => ({ type: COVER_AMOUNT_SET_VALUE, value });
export const setCoverAmountValid = error => ({ type: COVER_AMOUNT_SET_VALID, error });
export const setCoverAmountClear = () => ({ type: COVER_AMOUNT_SET_CLEAR });

export const setMonthlyPremiumValue = value => ({ type: MONTHLY_PREMIUM_SET_VALUE, value });
export const setMonthlyPremiumValid = error => ({ type: MONTHLY_PREMIUM_SET_VALID, error });
export const setMonthlyPremiumClear = () => ({ type: MONTHLY_PREMIUM_SET_CLEAR });

export const REMOVE_COVER_MEMBER_SET_VALUE = LIVES_COVERED_SET_VALUE + 'removeCoverMember';
export const setRemoveCoverMember = value => ({ type: REMOVE_COVER_MEMBER_SET_VALUE, value });