import {validatePercent} from  "@om/validation";
import {
    setSplitPercentageValid, setTotalPercentageValid,
    SPLIT_PERCENTAGE_SET_VALUE, TOTAL_PERCENTAGE_SET_VALUE
} from "../../../actions/raOptimal/beneficiariesSplit";

export const beneficiariesSplitValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SPLIT_PERCENTAGE_SET_VALUE:
                error = validatePercent(action.value);
                return setSplitPercentageValid(error, action.beneficiaryId);
            case TOTAL_PERCENTAGE_SET_VALUE:
                error = action.value !== 100 ? 'Your total should be 100%' : null;
                return setTotalPercentageValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }
    
    store.dispatch(validationAction)
    return next(action);
};
