import {
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateDateOfBirth,
    validateStreetAddress,
} from '@om/validation';

import { validateEmail, validateEmailAddress } from '@om/validation/src/validators/email-validator';
import {
    validateAlphaNumericString,
    validateFullName,
    validateNumericString,
} from '@om/validation/src/validators/string-validator';
import { setPurchasePersonalDetailsFieldValid } from '../../../actions/allsureInsurance/purchasePersonalDetails';
import { validateBwIdNumber } from '@om/validation/src/validators/bw-id-number-validator';
import { validateDateOfBirthVTwo } from '@om/validation/src/validators/date-of-birth-validator';
import { getPurchasePersonalDetailsForm } from '../../../selectors/allsureInsurance/purchasePersonalDetails';

let prevValidationAction = null;

export const purchaseJourneyPersonalDetailsValidation = (store, next, action) => {
    const codeValidations = {
        zw: { min: 9, max: 10 },
        na: { min: 9, max: 9 },
        za: { min: 9, max: 9 },
        bw: { min: 7, max: 7 },
    };
    const validationAction = (() => {
        const { countryCodePurchase, countryCodeWork } = getPurchasePersonalDetailsForm(store.getState());
        const trimValue = (value) => (value ? value.trim() : value);
        const removeWhiteSpacesFromValue = (value) => (value ? value.replace(/ /g, '') : value);
        let error;

        switch (action.value.fieldType) {
            case 'title':
                error = validateSingleSelection(action.value.value, 'Title');
                break;

            case 'fullname':
                error = validateFullName(trimValue(action.value.value), {
                    min: 3,
                    max: 66,
                    subject: 'Full name',
                });
                break;

            case 'nationality':
                error = validateSingleSelection(action.value.value, 'Nationality');
                break;

            case 'id':
                if (action.value.nationalityVal === 'Botswana') {
                    error = validateBwIdNumber(action.value.value, {
                        min: 9,
                        max: 9,
                        required: true,
                        subject: 'Botswana Id',
                        gender: action.value.gender,
                    });
                    break;
                }
                error = validateNumericString(action.value.value, {
                    min: 6,
                    max: 20,
                    required: true,
                    subject: 'Passport Id',
                });
                break;

            case 'passportId':
                error = validateNumericString(action.value.value, {
                    min: 9,
                    max: 9,
                    required: true,
                    subject: 'Botswana Id',
                });
                break;

            case 'dateOfBirth':
                error = validateDateOfBirthVTwo(action.value.value, {
                    min: 18,
                    required: true,
                    subject: 'date of birth',
                });
                break;

            case 'placeOfBirth':
                error = validateStreetAddress(trimValue(action.value.value), {
                    min: 3,
                    max: 75,
                    subject: 'Place of birth',
                });
                break;

            case 'streetAddress':
                error = validateAlphaNumericString(action.value.value, {
                    min: 1,
                    max: 75,
                    required: true,
                    subject: 'street address',
                });
                break;

            case 'city':
                error = validateStreetAddress(trimValue(action.value.value), {
                    min: 3,
                    max: 75,
                    subject: 'city',
                });
                break;

            case 'country':
                error = validateSingleSelection(action.value.value, 'Country');
                break;

            case 'residenceTimePeriod':
                error = validateSingleSelection(action.value.value, 'Residence year');
                break;

            case 'previousPostalAddress':
                error = validateAlphaNumericString(action.value.value, {
                    min: 1,
                    max: 75,
                    required: true,
                    subject: 'Previous residential address',
                });
                break;

            case 'previousPostalCity':
                error = error = validateStreetAddress(trimValue(action.value.value), {
                    min: 3,
                    max: 75,
                    subject: 'Previos city',
                });
                break;

            case 'previousPostalCountry':
                error = validateSingleSelection(action.value.value, ' Previous residential Country');
                break;

            case 'postalSuburb':
                error = validateAlphaNumericString(action.value.value, {
                    min: 1,
                    max: 75,
                    required: true,
                    subject: 'Postal Suburb',
                });
                break;

            case 'postalCity':
                error = validateStreetAddress(trimValue(action.value.value), {
                    min: 3,
                    max: 75,
                    subject: 'Postal city',
                });
                break;

            case 'postalCountry':
                error = validateSingleSelection(action.value.value, ' Previous postal Country');
                break;

            case 'employerName':
                error = validateAlphaNumericString(action.value.value, {
                    min: 3,
                    max: 66,
                    required: true,
                    subject: 'Employer',
                });
                break;

            case 'workAddress':
                error = validateStreetAddress(trimValue(action.value.value), {
                    min: 3,
                    max: 75,
                    subject: 'Place of work',
                });
                break;

            case 'mobileNumber':
                error =
                    countryCodePurchase?.displayValue === 'bw'
                        ? validateContactNumber(removeWhiteSpacesFromValue(action.value.value), {
                              countryCode: 'bw',
                              leadingZeroValidation: false,
                          })
                        : validateNumericString(action.value.value, {
                              min: 9,
                              max: 9,
                              subject: 'Contact Number',
                          });
                break;

            case 'workTelephone':
                error = validateContactNumber(removeWhiteSpacesFromValue(action.value.value), {
                    countryCode: 'bw',
                    leadingZeroValidation: false,
                });
                break;

            case 'email':
                error = validateEmail(trimValue(action.value.value), {
                    min: 10,
                    max: 60,
                    subject: 'Email address',
                    required: true,
                });
                break;

            case 'telephoneWork':
                error = action?.value?.value ? validateNumericString(action.value.value, {
                              ...(codeValidations[countryCodeWork?.displayValue] ?? {}),
                              subject: 'Telephone Work',
                              required: false,
                          }):null
                break;

            default:
                return null;
        }
        return setPurchasePersonalDetailsFieldValid(error, action.value.fieldType);
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
