import { initRouting } from './routing';
import { initQuote } from './quote';
import { initPersonal } from './personalDetails';
import { initCarDetails } from './carDetails';
import { initPayments } from './payment';
import { initDocuments } from './Documents';
import { initAgents } from './agent';
import { initNextOfKin } from './nextOfKin';
import { initDeclaration } from './declarations';
import { initPayload } from './payloadCollection';
import { initPersistedAt } from './persistedAt';

export default () => {
    return {
        routing: initRouting,
        quote: initQuote,
        personalDetails: initPersonal,
        carDetails: initCarDetails,
        payments: initPayments,
        documents: initDocuments,
        agent: initAgents,
        nextOfKin: initNextOfKin,
        declaration: initDeclaration,
        payloadCollection: initPayload,
        persistedAt: initPersistedAt,
    };
};
