export const UG_MOTOR_QOUTE_FORM = 'ugMotorInsurance/quoteForm/';
export const KE_MOTOR_QUOTE_FORM = 'keMotorInsurance/quoteForm/';
export const UG_MOTOR_QOUTE_FORM_SET_VALUE = UG_MOTOR_QOUTE_FORM + 'setValue/';
export const UG_MOTOR_QOUTE_FORM_SET_VALID = UG_MOTOR_QOUTE_FORM + 'setValid/';
export const UG_MOTOR_QUOTE_ADD_COVER = UG_MOTOR_QOUTE_FORM + 'addCover/';
export const FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM = 'ugMotorInsurance/quoteForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM = 'ugMotorInsurance/quoteForm/transition/formInValid';
export const FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM = 'ugMotorInsurance/shareQuoteForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM = 'ugMotorInsurance/shareQuoteForm/transition/formInValid';
export const UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE = UG_MOTOR_QOUTE_FORM + 'shareQuote';
export const KE_MOTOR_QUOTE_FORM_SET_VALUE = KE_MOTOR_QUOTE_FORM + 'setValue/';
export const KE_MOTOR_QUOTE_FORM_SET_VALID = KE_MOTOR_QUOTE_FORM + 'setValid/';
export const TOKEN_IS_EXPIRED = 'motor/expiredAuth';

export const SET_CAR_MAKES_LIST_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carMakesList';
export const SET_CAR_MAKES_LIST_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carMakesList';
export const SET_CAR_MODEL_LIST_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carModelsList';
export const SET_CAR_MODEL_LIST_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carModelsList';

export const TRIGGER_GET_ALL_CAR_MAKES = UG_MOTOR_QOUTE_FORM + 'triggerCarMakes';
export const TRIGGER_GET_ALL_CAR_MODELS = UG_MOTOR_QOUTE_FORM + 'triggerCarModels';
export const TRIGGER_GET_VALUERS = UG_MOTOR_QOUTE_FORM + 'triggerValuers';
export const TRIGGER_GET_UG_VALUERS = UG_MOTOR_QOUTE_FORM + '/ug/triggerValuers';
export const SET_VALUERS_LIST_SUCCESS = UG_MOTOR_QOUTE_FORM + 'valuersList';
export const API_SUCCESS_TRANSITION_VALUERS = UG_MOTOR_QOUTE_FORM + 'api/success/valuers';
export const API_PENDING_TRANSITION_VALUERS = UG_MOTOR_QOUTE_FORM + 'api/pending/valuers';
export const API_FAILURE_TRANSITION_VALUERS = UG_MOTOR_QOUTE_FORM + 'api/failure/valuers';

export const UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'sidebarOpen';

export const REINITIALIZE = UG_MOTOR_QOUTE_FORM + '/state/reinitialize';

// VALIDATE QUOTE FORM

export const transitionMotorInsuranceQuoteForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM,
    }),
};

export const transitionShareQuoteForm = {
    formInvalid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    }),
    formValid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${UG_MOTOR_QOUTE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${UG_MOTOR_QOUTE_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${UG_MOTOR_QOUTE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/failure`;

export const API_PENDING_TRANSITIONS_CAR_MAKES = `${SET_CAR_MAKES_LIST_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_CAR_MAKES = `${SET_CAR_MAKES_LIST_VALUE}/apiTransition/success`;
export const API_PENDING_TRANSITIONS_CAR_MODELS = `${SET_CAR_MODEL_LIST_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_CAR_MODELS = `${SET_CAR_MODEL_LIST_VALUE}/apiTransition/success`;

export const API_FAILURE_TRANSITIONS_CAR_MAKES = `${SET_CAR_MAKES_LIST_VALUE}/apiTransition/failure`;
export const API_FAILURE_TRANSITIONS_CAR_MODELS = `${SET_CAR_MODEL_LIST_VALUE}/apiTransition/failure`;

export const apiTransitionMotorInsuranceQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    }),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};

// Car makes api transition
export const apiTransitionCarMakes = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_CAR_MAKES,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_CAR_MAKES,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_CAR_MAKES,
    }),
};

// Car makes api transition
export const apiTransitionCarModels = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_CAR_MODELS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_CAR_MODELS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_CAR_MODELS,
    }),
};

// Valuers makes api transition
export const apiTransitionValuers = {
    pending: () => ({
        type: API_PENDING_TRANSITION_VALUERS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_VALUERS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_VALUERS,
    }),
};

//calculated Data
export const COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'collectMotorInsuranceQuoteData';
export const COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceQuoteData';
export const COLLECT_KE_THIRD_PARTY_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceThirdPartyQuoteData';
export const SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'success';
export const SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_MOTOR_INSURANCE_PREMIUM_FAILURE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'failure';
export const COLLECT_SHARE_QUOTE_FORM_DATA = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_MOTOR_INSURANCE_QUOTE_FAILURE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'shareQuoteFailure';
export const TRIGGER_CALCULATE_MOTORCYCLE_PREMIUM = KE_MOTOR_QUOTE_FORM + 'triggerCalculateMotorCyclePremium';

export const collectMotorInsuranceQuoteData = () => ({
    type: COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA,
});

export const collectMotorInsuranceKeQuoteData = () => ({ type: COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA });
export const collectThirdPartyQuoteData = () => ({ type: COLLECT_KE_THIRD_PARTY_DATA });
export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

export const submitMotorInsuranceQuoteSuccess = () => ({
    type: SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS,
    value,
});

export const shareQuoteSuccess = (value) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    value,
});

export const shareQuoteFailure = (error) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    error,
});

export const calculateMotorInsurancePremiumSuccess = (value) => ({
    type: CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS,
    value,
});

export const submitMotorInsuranceQuoteFailure = () => ({
    type: SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE,
    error,
});

export const addCover = (value) => ({
    type: UG_MOTOR_QUOTE_ADD_COVER,
    value,
});

// Form values
export const SET_PERSONAL_ACCIDENT_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'personalAccident';
export const SET_PERSONAL_ACCIDENT_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'personalAccident';
export const SET_FULL_NAME_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'fullNameValue';
export const SET_FULL_NAME_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'fullNameValid';
export const SET_CAR_MAKE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carMakeValue';
export const SET_CAR_MAKE_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carMakeValid';
export const SET_CAR_MODEL_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carModelValue';
export const SET_CAR_MODEL_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carModelValid';
export const SET_YEAR_OF_MANUFACTURE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'yearOfManufactureValue';
export const SET_YEAR_OF_MANUFACTURE_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'yearOfManufactureValid';
export const SET_COVER_START_DATE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'coverStartDateValue';
export const SET_COVER_START_DATE_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'coverStartDateValid';
export const SET_IS_CAR_VALUED_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'isCarValuedValue';
export const SET_IS_CAR_VALUED_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'isCarValuedValid';
export const SET_CAR_VALUER_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carValuerValue';
export const SET_CAR_VALUER_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carValuerValid';
export const SET_CAR_USE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carUseValue';
export const SET_CAR_USE_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carUseValid';
export const SET_HAS_ALARM_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'hasAlarmValue';
export const SET_HAS_ALARM_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'hasAlarmValid';
export const SET_VALUATION_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'valuationValue';
export const SET_VALUATION_DISPLAY_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'valuationDisplayValue';
export const SET_VALUATION_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'valuationValid';
export const SET_UNCOMMON_RISK_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'uncommonRiskValue';
export const SET_UNCOMMON_RISK_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'uncommonRiskValid';
export const SET_EXCESS_PROTECTOR_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'excessProtectorValue';
export const SET_EXCESS_PROTECTOR_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'excessProtectorValid';
export const SET_ALTERNATIVE_ACCOMODATION_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'alternativeAccomodationValue';
export const SET_ALTERNATIVE_ACCOMODATION_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'alternativeAccomodationValid';
export const SET_CAR_HIRE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carHireValue';
export const SET_CAR_HIRE_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carHireValid';
export const SET_SHARE_EMAIL_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'shareEmailValid';
export const SET_SHARE_EMAIL_BASE_64_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'shareEmailBase64Value';
export const SET_ENABLE_DOWNLOAD_QUOTE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'enableDownloadQuote';
export const SET_VIOLENCE_TERORISM_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'violenceAndTerrorismValue';
export const SET_VIOLENCE_TERORISM_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'violenceAndTerrorismValid';
export const SET_SELECTED_COVER_VALUE = KE_MOTOR_QUOTE_FORM_SET_VALUE + 'selectMotorCover';
export const SET_SELECTED_COVER_VALID = KE_MOTOR_QUOTE_FORM_SET_VALID + 'selectMotorCover';
export const SET_IS_RARE_MODEL_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'isRareModel';
export const SET_IS_RARE_MODEL_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'isRareModel';
export const SET_OTHER_VALUER_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'otherValuer';
export const SET_OTHER_VALUER_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'otherValuer';
export const SET_TRACKING_SYSTEM_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'trackingSystem';
export const SET_TRACKING_SYSTEM_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'trackingSystem';
export const SET_OUTSIDE_EA_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'outsideEa';
export const SET_OUTSIDE_EA_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'outsideEa';
export const SET_COURTESY_CAR_VALUE = KE_MOTOR_QUOTE_FORM_SET_VALUE + 'courtesyCar';
export const SET_COURTESY_CAR_VALID = KE_MOTOR_QUOTE_FORM_SET_VALID + 'courtesyCar';
export const SET_COURTESY_CAR_AMOUNT_VALUE = KE_MOTOR_QUOTE_FORM_SET_VALUE + 'courtesyCarAmount';
export const SET_COURTESY_CAR_AMOUNT_VALID = KE_MOTOR_QUOTE_FORM_SET_VALID + 'courtesyCarAmount';
export const SET_VEHICLE_TYPE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'vehicleType';
export const SET_VEHICLE_TYPE_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'vehicleType';

export const SET_CAR_HIRE_DAYS_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'carHireDays';
export const SET_CAR_HIRE_DAYS_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'carHireDays';
export const SET_ALTERNATIVE_ACCOMODATION_DAYS_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'alternativeAccomodationDays';
export const SET_ALTERNATIVE_ACCOMODATION_DAYS_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'alternativeAccomodationDays';

export const SET_EXCESS_AMOUNT_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'excessAmount';
export const SET_EXCESS_AMOUNT_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'excessAmount';

//first time purchase
export const SET_FIRST_TIME_PURCHASE_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'firstTimePurchase';
export const SET_FIRST_TIME_PURCHASE_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'firstTimePurchase';

export const ID_NUMBER_SET_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'idNumber';
export const PHONE_NUMBER_SET_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'phoneNumber';
export const EMAIL_SET_VALUE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID = UG_MOTOR_QOUTE_FORM_SET_VALID + 'email';

// action creators
export const setFullNameValue = (value) => ({
    type: SET_FULL_NAME_VALUE,
    value,
});

export const setFullNameValid = (error) => ({
    type: SET_FULL_NAME_VALID,
    error,
});

export const setCarMakeValue = (value) => ({
    type: SET_CAR_MAKE_VALUE,
    value,
});

export const setCarMakeValid = (error) => ({
    type: SET_CAR_MAKE_VALID,
    error,
});

export const setCarModelValue = (value) => ({
    type: SET_CAR_MODEL_VALUE,
    value,
});

export const setCarModelValid = (error) => ({
    type: SET_CAR_MODEL_VALID,
    error,
});

export const setYearOfManufactureValue = (value) => ({
    type: SET_YEAR_OF_MANUFACTURE_VALUE,
    value,
});

export const setYearOfManufactureValid = (error) => ({
    type: SET_YEAR_OF_MANUFACTURE_VALID,
    error,
});

export const setCoverStartDateValue = (value) => ({
    type: SET_COVER_START_DATE_VALUE,
    value,
});

export const setCoverStartDateValid = (error) => ({
    type: SET_COVER_START_DATE_VALID,
    error,
});

export const setIsCarValuedValue = (value) => ({
    type: SET_IS_CAR_VALUED_VALUE,
    value,
});

export const setIsCarValuedValid = (error) => ({
    type: SET_IS_CAR_VALUED_VALID,
    error,
});

export const initializeQuoteForm = () => ({
    type: REINITIALIZE,
});

export const setCarValuerValue = (value) => ({
    type: SET_CAR_VALUER_VALUE,
    value,
});

export const setCarValuerValid = (error) => ({
    type: SET_CAR_VALUER_VALID,
    error,
});

export const setCarUseValue = (value) => ({
    type: SET_CAR_USE_VALUE,
    value,
});

export const setCarUseValid = (error) => ({
    type: SET_CAR_USE_VALID,
    error,
});

export const setHasAlarmValue = (value) => ({
    type: SET_HAS_ALARM_VALUE,
    value,
});

export const setHasAlarmValid = (error) => ({
    type: SET_HAS_ALARM_VALID,
    error,
});

export const setValuationValue = (value) => ({
    type: SET_VALUATION_VALUE,
    value,
});

export const setValuationDisplayValue = (value) => ({
    type: SET_VALUATION_DISPLAY_VALUE,
    value,
});

export const setValuationValid = (error) => ({
    type: SET_VALUATION_VALID,
    error,
});

export const setUncommonRiskValue = (value) => ({
    type: SET_UNCOMMON_RISK_VALUE,
    value,
});

export const setUncommonRiskValid = (error) => ({
    type: SET_UNCOMMON_RISK_VALID,
    error,
});

export const setExcessProtectorValue = (value) => ({
    type: SET_EXCESS_PROTECTOR_VALUE,
    value,
});

export const setExcessProtectorValid = (error) => ({
    type: SET_EXCESS_PROTECTOR_VALID,
    error,
});

export const setViolenceAndTerrorismValue = (value) => ({
    type: SET_VIOLENCE_TERORISM_VALUE,
    value,
});

export const setViolenceAndTerrorismValid = (error) => ({
    type: SET_VIOLENCE_TERORISM_VALID,
    error,
});

export const setAlternativeAccomodationValue = (value) => ({
    type: SET_ALTERNATIVE_ACCOMODATION_VALUE,
    value,
});

export const setAlternativeAccomodationValid = (error) => ({
    type: SET_ALTERNATIVE_ACCOMODATION_VALID,
    error,
});

export const setCarHireValue = (value) => ({
    type: SET_CAR_HIRE_VALUE,
    value,
});

export const setCarHireValid = (error) => ({
    type: SET_CAR_HIRE_VALID,
    error,
});

export const setShareEmailValue = (value) => ({
    type: SET_SHARE_EMAIL_VALUE,
    value,
});

export const setShareEmailValid = (error) => ({
    type: SET_SHARE_EMAIL_VALID,
    error,
});

export const setShareEmailBase64Value = (value) => ({
    type: SET_SHARE_EMAIL_BASE_64_VALUE,
    value,
});

export const setEnableDownloadQuote = (value) => ({
    type: SET_ENABLE_DOWNLOAD_QUOTE_VALUE,
    value,
});

export const setIsRareModelValue = (value) => ({
    type: SET_IS_RARE_MODEL_VALUE,
    value,
});

export const setIsRareModelValid = (error) => ({
    type: SET_IS_RARE_MODEL_VALID,
    error,
});

export const setOtherValuerValue = (value) => ({
    type: SET_OTHER_VALUER_VALUE,
    value,
});

export const setOtherValuerValid = (error) => ({
    type: SET_OTHER_VALUER_VALID,
    error,
});

export const setTrackingSystemValue = (value) => ({
    type: SET_TRACKING_SYSTEM_VALUE,
    value,
});

export const setTrackingSystemValid = (error) => ({
    type: SET_TRACKING_SYSTEM_VALID,
    error,
});

export const setOutsideEaValue = (value) => ({
    type: SET_OUTSIDE_EA_VALUE,
    value,
});

export const setOutsideEaValid = (error) => ({
    type: SET_OUTSIDE_EA_VALID,
    error,
});

export const setFirstTimePurchaseValue = (value) => ({
    type: SET_FIRST_TIME_PURCHASE_VALUE,
    value,
});

export const setFirstTimePurchaseValid = (error) => ({
    type: SET_FIRST_TIME_PURCHASE_VALID,
    error,
});

export const setCarHireDaysValue = (value) => ({
    type: SET_CAR_HIRE_DAYS_VALUE,
    value,
});

export const setCarHireDaysValid = (error) => ({
    type: SET_CAR_HIRE_DAYS_VALID,
    error,
});

export const setAlternativeAccomodationDaysValue = (value) => ({
    type: SET_ALTERNATIVE_ACCOMODATION_DAYS_VALUE,
    value,
});

export const setAlternativeAccomodationDaysValid = (error) => ({
    type: SET_ALTERNATIVE_ACCOMODATION_DAYS_VALID,
    error,
});

export const setExcessAmountValue = (value) => ({
    type: SET_EXCESS_AMOUNT_VALUE,
    value,
});

export const setExcessAmountValid = (error) => ({
    type: SET_EXCESS_AMOUNT_VALID,
    error,
});

export const setIdNumberValue = (value) => ({
    type: ID_NUMBER_SET_VALUE,
    value,
});

export const setIdNumberValid = (error) => ({
    type: ID_NUMBER_SET_VALID,
    error,
});

export const setPhoneNumberValue = (value) => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = (error) => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setEmailValue = (value) => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = (error) => ({
    type: EMAIL_SET_VALID,
    error,
});

export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value,
});

export const setSideBarOpen = (value) => ({ type: UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE, value });

export const setSelectedCoverValue = (value) => ({ type: SET_SELECTED_COVER_VALUE, value });
export const setSelectedCoverValid = (error) => ({ type: SET_SELECTED_COVER_VALID, error });
export const setCourtesyCarValue = (value) => ({ type: SET_COURTESY_CAR_VALUE, value });
export const setCourtesyCarValid = (error) => ({ type: SET_COURTESY_CAR_VALID, error });

export const setCourtesyCarAmountValue = (value) => ({ type: SET_COURTESY_CAR_AMOUNT_VALUE, value });
export const setCourtesyCarAmountValid = (error) => ({ type: SET_COURTESY_CAR_AMOUNT_VALID, error });
export const setVehicleTypeValue = (value) => ({
    type: SET_VEHICLE_TYPE_VALUE,
    value,
});

export const setVehicleTypeValid = (error) => ({
    type: SET_VEHICLE_TYPE_VALID,
    error,
});

export const triggerMotocyleInsurance = () => ({
    type: TRIGGER_CALCULATE_MOTORCYCLE_PREMIUM,
});

export const setCarMakesValue = (value) => ({
    type: SET_CAR_MAKES_LIST_VALUE,
    value,
});
export const setCarMakesValid = (error) => ({
    type: SET_CAR_MAKES_LIST_VALID,
    error,
});

export const setCarModelsValue = (value) => ({
    type: SET_CAR_MODEL_LIST_VALUE,
    value,
});
export const setCarModelsValid = (error) => ({
    type: SET_CAR_MODEL_LIST_VALID,
    error,
});

export const triggerGetCarModels = () => ({
    type: TRIGGER_GET_ALL_CAR_MODELS,
});

export const triggerGetCarMakes = () => ({
    type: TRIGGER_GET_ALL_CAR_MAKES,
});

export const triggerGetValuers = () => ({
    type: TRIGGER_GET_VALUERS,
});
export const triggerGetUgValuers = () => ({
    type: TRIGGER_GET_UG_VALUERS,
});
export const setValuers = (value) => ({
    type: SET_VALUERS_LIST_SUCCESS,
    value,
});

export const setPersonalAccidentValue = (value) => ({
    type: SET_PERSONAL_ACCIDENT_VALUE,
    value,
});

export const setPersonalAccidentValid = (error) => ({
    type: SET_PERSONAL_ACCIDENT_VALID,
    error,
});
