export const taxBrackets = {
    ZA: [
        {
            min: 1,
            max: 237100,
            baseTax: 0,
            rate: 0.18,
            threshold: 0,
        },
        {
            min: 237101,
            max: 370500,
            baseTax: 42678,
            rate: 0.26,
            threshold: 237100,
        },
        {
            min: 370501,
            max: 512800,
            baseTax: 77362,
            rate: 0.31,
            threshold: 370500,
        },
        {
            min: 512801,
            max: 673000,
            baseTax: 121475,
            rate: 0.36,
            threshold: 512800,
        },
        {
            min: 673001,
            max: 857900,
            baseTax: 179147,
            rate: 0.39,
            threshold: 673000,
        },
        {
            min: 857901,
            max: 1817000,
            baseTax: 251258,
            rate: 0.41,
            threshold: 857900,
        },
        {
            min: 1817001,
            baseTax: 644489,
            rate: 0.45,
            threshold: 1817000,
        },
    ],
    NA: [
        {
            min: 1,
            max: 100000,
            baseTax: 0,
            rate: 0,
            threshold: 0,
        },
        {
            min: 100001,
            max: 150000,
            baseTax: 0,
            rate: 0.18,
            threshold: 100000,
        },
        {
            min: 150001,
            max: 350000,
            baseTax: 9000,
            rate: 0.25,
            threshold: 150000,
        },
        {
            min: 350001,
            max: 550000,
            baseTax: 59000,
            rate: 0.28,
            threshold: 350000,
        },
        {
            min: 550001,
            max: 850000,
            baseTax: 115000,
            rate: 0.3,
            threshold: 550000,
        },
        {
            min: 850001,
            max: 1550000,
            baseTax: 205000,
            rate: 0.32,
            threshold: 850000,
        },
        {
            min: 1550001,
            baseTax: 429000,
            rate: 0.37,
            threshold: 1550000,
        },
    ],
};

export const taxRebates = {
    none: {
        threshold: 0,
        base: 0,
        creditThreshold: 0,
    },
    categoryOne: {
        threshold: 95750,
        base: 17235,
        creditThreshold: 4,
    },
    categoryTwo: {
        threshold: 148217,
        base: 26679,
        creditThreshold: 3,
    },
    categoryThree: {
        threshold: 165689,
        base: 29824,
        creditThreshold: 3,
    },
};

export const advancedData = {
    medicalAidCredits: {
        mainDependents: 4368,
        otherDependents: 2952,
    },
    maximumRetirement: 171600,
    monetaryCape: 350000,
    constants: {
        first: 0.075,
        second: 0.25,
        third: 0.333,
        limitRemuneration: 0.275,
    },
};

export const infoData = {
    otherIncome: `This is money you make in addition to your main source of income. E.g. High school teacher Thulani Ndlovu earns a salary of R15 000 a month. After school, he drives a cab and makes an "additional income" of R4700 a month.`,
    deductionOtherIncome: `These are all deductions that affect your additional income. E.g. The service company that Thulani drives the cab for subtracts a service fee of R350 from his additional income of R4,700.`,
    resultCard: `To calculate your income tax, we take your total income for the year, your retirement fund contributions and deductions from other income (if provided) into account to calculate your taxable income. 
    We then check it against this year’s tax threshold to see if you will pay tax.  If you will pay tax, we apply this year’s tax rate to your taxable income and deduct the applicable rebate and medical tax credits.`,
};
