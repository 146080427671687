import axios from 'axios';
import {
    getIsApplicationReferenceDataValid,
    getIsCountriesReferenceDataValid,
} from '../../../selectors/roaTravelInsurance/referenceData';
import {
    setApplicationReferenceData,
    ROA_APPLICATION_REFERENCE_DATA_GET_VALUE,
    setCountriesReferenceData,
    ROA_COUNTRIES_REFERENCE_DATA_GET_VALUE,
} from '../../../actions/roaTravelInsurance/referenceData';

export const titleReferenceDataService = (store, next, action) => {
    const result = next(action);

    if (action.type === ROA_APPLICATION_REFERENCE_DATA_GET_VALUE) {
        // If tittles and nationalities ref data already exists, dont make new call
        if (getIsApplicationReferenceDataValid(store.getState())) return;
        (async () => {
            try {
                const applicationReferenceData = await fetchApplicationReferenceData();
                store.dispatch(
                    setApplicationReferenceData(applicationReferenceData.data),
                );
            } catch (e) {
                throw e;
            }
        })();
    }

    return result;
};

export const fetchApplicationReferenceData = async () => {
    try {
        const response = await axios.get(
            '/om-api/roa-travel-insurance/reference-data',
            { headers: { 'Content-Type': 'application/json' } },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
