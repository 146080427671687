import {
    transitionWholeLifeCoverTool
} from "../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool";

import {
    getWholeLifeCoverQuoteToolForm,
} from "../../../selectors/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool";

import {FIELD_STATUS} from "../../../reducers/status";

export const wholeLifeCoverQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const wholeLifeCoverQuoteToolForm = getWholeLifeCoverQuoteToolForm(store.getState());
    const wholeLifeCoverQuoteToolValid = validateWholeLifeCoverQuoteToolFormDetails(wholeLifeCoverQuoteToolForm);
    const formAction = wholeLifeCoverQuoteToolValid
        ? transitionWholeLifeCoverTool.formValid()
        : transitionWholeLifeCoverTool.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validateWholeLifeCoverQuoteToolFormDetails = (formFields) => {
    return formFields.candidateDOB && formFields.candidateDOB.status === FIELD_STATUS.VALID &&
        formFields.coveredAmount && formFields.coveredAmount.status === FIELD_STATUS.VALID
};
