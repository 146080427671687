import {CUSTOMER_SATISFACTION_SURVEY_SET_VALUE} from "../../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm";
import {customerSatisfactionSurveyFormValidation} from "./customerSatisfactionSurveyForm";

export const customerSatisfactionSurveyValidation = (store, next, action) => {

    if (action.type.startsWith(CUSTOMER_SATISFACTION_SURVEY_SET_VALUE))
        return customerSatisfactionSurveyFormValidation(store, next, action);

    return null;
};
