import { resetMinorFormValues } from "../../../actions/taxFreeSavingsAccount/auxiliaryDetails";
import { resetInvestmentSetupContribution } from "../../../actions/taxFreeSavingsAccount/investmentSetupContribution";
import {
  ON_BEHALF_OF_CHILD_SET_VALUE,
  SAVING_FOR_SET_VALUE,
  setHasOtherTaxFreePlanValue,
  setLegalGuardianValue,
  setOnBehalfOfChildValue,
  setSavingForValue,
  setYourGoalValue,
} from "../../../actions/taxFreeSavingsAccount/investmentSetupInitial";
import { scrollToError } from "../../../actions/taxFreeSavingsAccount/scrolling";
import {
  getInvestmentSetupInitialForm,
  getOtherPlanDisclosureIsValid,
  getSavingForProgressiveDisclosureIsValid,
  getYourGoal,
} from "../../../selectors/taxFreeSavingsAccount/investmentSetupInitial";
import { TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_FORM_ID } from "../status";

export const investmentSetupInitialRules = (store, next, action) => {
  const result = next(action);
  businessRules(store, action);
  return result;
};

// Dispatches business rules based on action
const businessRules = (store, action) => {
  if (action.type === ON_BEHALF_OF_CHILD_SET_VALUE) {
    store.dispatch(resetInvestmentSetupContribution());
    store.dispatch(resetMinorFormValues());
  }

  if (action.type === SAVING_FOR_SET_VALUE && !!getYourGoal(store.getState()).value) {
    store.dispatch(setYourGoalValue(null));
  }
};

// Triggers validation for all fields and scrolls to error
export const performInvestmentSetupInitialValidation = (store) => {
  const form = getInvestmentSetupInitialForm(store.getState());
  const progressiveDiscloseOpen = getSavingForProgressiveDisclosureIsValid(store.getState());
  const otherPlanOpen = getOtherPlanDisclosureIsValid(store.getState());
  store.dispatch(setOnBehalfOfChildValue(form.onBehalfOfChild.value));

  if (otherPlanOpen) {
    store.dispatch(setHasOtherTaxFreePlanValue(form.hasOtherTaxFreePlan.value));
  }

  if (form.onBehalfOfChild.value === "true") {
    store.dispatch(setLegalGuardianValue(form.legalGuardian.value));
  }

  if (progressiveDiscloseOpen) {
    store.dispatch(setSavingForValue(form.savingFor.value));
    store.dispatch(setYourGoalValue(form.yourGoal.value));
  }

  store.dispatch(scrollToError(TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_FORM_ID, form));
};
