import {
    ROA_QUOTE_TOOL_SIDEBAR_OPEN_VALUE,
    ROA_QUOTE_TOOL_SELECTED_QUOTE_TOOL_VALUE,
    REINITIALIZE
} from "../../actions/roaQuoteTools/roaQuoteTool";

const initialeState = {
    sidebarOpen: false,
    selectedQuoteTool: null,
};

export default (state = initialeState, action) => {
    switch (action.type) {
        // Sidebar open
        case ROA_QUOTE_TOOL_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value
            };
        // Selected Quote Tool
        case ROA_QUOTE_TOOL_SELECTED_QUOTE_TOOL_VALUE:
            return {
                ...state,
                selectedQuoteTool: action.value
            };
        case REINITIALIZE:
            return initialeState
        default:
            return state;
    }
}
