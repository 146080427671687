import {validateEmail, validateInteger, validateName, validateContactNumber,} from "@om/validation";

import { validationIsEqual } from "../status";
import {
    SET_FIRST_NAME,
    SET_SURNAME,
    SET_PHONE_NUMBER,
    setFirstNameValid,
    setSurnameValid,
    SET_POLICY_NUMBER,
    setPhoneNumberValid,
    setPolicyNumberValid,
    SET_EMAIL_ADDRESS,
    setEmailAddressValid,
} from "../../../actions/makeAClaim";

let prevValidationAction = null;

export const makeAClaimValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SET_FIRST_NAME:
                error = validateName(action.value, {min: 2, max: 48});
                return setFirstNameValid(error);
            case SET_SURNAME:
                error = validateName(action.value, {min: 2, max: 48});
                return setSurnameValid(error);
            case SET_PHONE_NUMBER:
                error = validateContactNumber(action.value, {subject: 'Phone Number', countryCode: 'ng'});
                return setPhoneNumberValid(error);
            case SET_POLICY_NUMBER:
                error = validateInteger(action.value);
                return setPolicyNumberValid(error);
            case SET_EMAIL_ADDRESS:
                error = validateEmail(action.value);
                return setEmailAddressValid(error);
            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};