import { createSelector } from "reselect";
import { getAtRetirement } from "./index";
import { OPEN_DRAWER } from "../../actions/atRetirement/types";
import { FORM_STATUS } from "../../reducers/status";

export const getAboutYou = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.aboutYou
);

export const getOpenDrawer = createSelector(
    getAboutYou,
    aboutYou => aboutYou.openDrawer
);

export const getBudgetDrawerIsOpen = createSelector(
    getAboutYou,
    aboutYou => aboutYou.openDrawer === OPEN_DRAWER.BUDGET_TOOL
);

export const getUseOfPersonalInformationDrawerIsOpen = createSelector(
    getAboutYou,
    aboutYou => aboutYou.openDrawer === OPEN_DRAWER.USE_OF_PERSONAL_INFORMATION
);

export const getAboutYouFormPersonalInformationIsValid = createSelector(
    getAboutYou,
    aboutYou => aboutYou.formPersonalInformationStatus === FORM_STATUS.VALID,
);

export const getAboutYouFormRetirementWorriesIsValid = createSelector(
    getAboutYou,
    aboutYou => aboutYou.formRetirementWorriesStatus === FORM_STATUS.VALID,
);

export const getAboutYouFormFinancialIsValid = createSelector(
    getAboutYou,
    aboutYou => aboutYou.formFinancialStatus === FORM_STATUS.VALID,
);


export const getAboutYouForm = createSelector(
    getAboutYou,
    aboutYou => aboutYou.form,
);

const makeGetField = fieldId => createSelector(
    getAboutYouForm,
    aboutYouForm => aboutYouForm[fieldId]
);


export const getName = makeGetField('name');
export const getAge = makeGetField('age');
export const getGender = makeGetField('gender');
export const getRetirementFor = makeGetField('retirementFor');
export const getPartnerAge = makeGetField('partnerAge');
export const getRetirementWorries = makeGetField('retirementWorries');
export const getSalary = makeGetField('salary');
export const getSavings = makeGetField('savings');
export const getBudget = makeGetField('budget');

