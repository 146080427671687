import { transitionTravelInsuranceTravelDetailsForm } from '../../../actions/travel/personalDetails';
import { getTravelDetailsForm } from '../../../selectors/travel/travelDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const travelDetailsFormRules = (store, next, action) => {
	const result = next(action);
	const travelDetailsForm = getTravelDetailsForm(store.getState());

	const travelDetailsFormValid = validateTravelDetailsFormDetails(travelDetailsForm); // true false 
	const formAction = travelDetailsFormValid
        ? transitionTravelInsuranceTravelDetailsForm.formValid()
        : transitionTravelInsuranceTravelDetailsForm.formInvalid();
	store.dispatch(formAction);
	return result;
};

const validateTravelDetailsFormDetails = (formFields) => {
    return (
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.fullName &&
        formFields.fullName.status === FIELD_STATUS.VALID &&
        formFields.idType &&
        formFields.idType.status === FIELD_STATUS.VALID &&
        formFields.kraNumber &&
        formFields.kraNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.title &&
        formFields.title.status === FIELD_STATUS.VALID &&
        formFields.title &&
        formFields.title.status === FIELD_STATUS.VALID
    );
};
