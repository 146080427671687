import { createSelector } from "reselect";
import { RETIREMENT_FOR } from "../../actions/atRetirement/types";
import { CalcEngine } from "../../functions/AtRetirement/CalcEngine";
import { FIELD_STATUS } from "../../reducers/status";
import { getAboutYouForm } from "./aboutYou";
import { getAtRetirement } from "./index";

const getAboutYouValid = createSelector(
    getAboutYouForm,
    (aboutYou) => {
        let formValid = false;
        formValid = aboutYou.name && aboutYou.name.status === FIELD_STATUS.VALID &&
        aboutYou.age && aboutYou.age.status === FIELD_STATUS.VALID &&
        aboutYou.gender && aboutYou.gender.status === FIELD_STATUS.VALID &&
        aboutYou.retirementFor && aboutYou.retirementFor.status === FIELD_STATUS.VALID;

        if (!formValid)
            return false;

        // If the option 'myself-and-partner' has been selected then check for partner age valid
        if (aboutYou.retirementFor.value !== RETIREMENT_FOR.MYSELF_AND_PARTNER)
            return true;

        formValid = formValid && aboutYou.partnerAge && aboutYou.partnerAge.status === FIELD_STATUS.VALID;

        formValid = formValid && aboutYou.retirementWorries && aboutYou.retirementWorries.status === FIELD_STATUS.VALID;

        return formValid && aboutYou.salary && aboutYou.salary.status === FIELD_STATUS.VALID &&
            aboutYou.savings && aboutYou.savings.status === FIELD_STATUS.VALID &&
            aboutYou.budget && aboutYou.budget.status === FIELD_STATUS.VALID;
    }
);

export const getGuaranteedAnnuityLowerRange = createSelector(
    getAboutYouForm,
    getAboutYouValid,
    (aboutYou, aboutYouValid) =>
        aboutYouValid
            ? CalcEngine.calculateGuaranteedAnnuityLowerRange(
                  aboutYou.savings.value,
                  aboutYou.gender.value,
                  aboutYou.age.value,
                  aboutYou.retirementFor.value === RETIREMENT_FOR.MYSELF_AND_PARTNER,
                  aboutYou.partnerAge.value
              )
            : 0
);

export const getGuaranteedAnnuityUpperRange = createSelector(
    getAboutYouForm,
    getAboutYouValid,
    (aboutYou, aboutYouValid) =>
        aboutYouValid
            ? CalcEngine.calculateGuaranteedAnnuityUpperRange(
                  aboutYou.savings.value,
                  aboutYou.gender.value,
                  aboutYou.age.value,
                  aboutYou.retirementFor.value === RETIREMENT_FOR.MYSELF_AND_PARTNER,
                  aboutYou.partnerAge.value
              )
            : 0
);

export const getLivingAnnuityLowerRange = createSelector(
    getAboutYouForm,
    getAboutYouValid,
    (aboutYou, aboutYouValid) =>
        aboutYouValid ? CalcEngine.calculateLivingAnnuityLowerRange(aboutYou.savings.value) : 0
);

export const getLivingAnnuityUpperRange = createSelector(
    getAboutYouForm,
    getAboutYouValid,
    (aboutYou, aboutYouValid) =>
        aboutYouValid ? CalcEngine.calculateLivingAnnuityUpperRange(aboutYou.savings.value) : 0
);

export const getProductSolutionsTable = createSelector(
    getGuaranteedAnnuityLowerRange,
    getGuaranteedAnnuityUpperRange,
    getLivingAnnuityLowerRange,
    getLivingAnnuityUpperRange,
    (guaranteedAnnuityLowerRange, guaranteedAnnuityUpperRange, livingAnnuityLowerRange, livingAnnuityUpperRange) => {
        return {
            guaranteedAnnuityLowerRange,
            guaranteedAnnuityUpperRange,
            livingAnnuityLowerRange,
            livingAnnuityUpperRange
        }
    }
);