import { validationIsEqual } from '../status';
import {
    validateAlphaNumericString,
    validateName,
    validateNumericString,
    validateSingleSelection,
} from '@om/validation';
import {
    DECLINED_PROPOSAL_SET_VALUE,
    DEFECT_VISION_SET_VALUE,
    INCREASED_PREMIUMS_SET_VALUE,
    POLICY_REJECTED_SET_VALUE,
    CONVICTED_SET_VALUE,
    STICKER_COLLECTION_SET_VALUE,
    OTHER_SOURCE_OF_FUNDS_SET_VALUE,
    setDeclinedProposalValid,
    setDefectVisionValid,
    setIncreasedPremiumsValid,
    setPolicyRejectedValid,
    setConvictedValid,
    setStickerCollectionValid,
    setOtherSourceOfFundsValid,
} from '../../../actions/ugMotorInsurance/declarations';

let prevValidationAction = null;
export const declarationsFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;

        const validateString = (value, min, subject) => {
            const validators = [validateName, validateAlphaNumericString, validateNumericString];

            let customError = null;

            for (const validate of validators) {
                customError = validate(value, { min, subject });
                if (!customError) break;
            }

            return customError;
        };

        switch (action.type) {
            case DECLINED_PROPOSAL_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Declined Proposal',
                    required: true,
                });
                return setDeclinedProposalValid(error);

            case DEFECT_VISION_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Defect Vision',
                    required: true,
                });
                return setDefectVisionValid(error);

            case INCREASED_PREMIUMS_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Increased Premiums',
                    required: true,
                });
                return setIncreasedPremiumsValid(error);

            case POLICY_REJECTED_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Policy Rejected',
                    required: true,
                });
                return setPolicyRejectedValid(error);

            case CONVICTED_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Convicted',
                    required: true,
                });
                return setConvictedValid(error);

            case STICKER_COLLECTION_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Sticker Collection',
                    required: true,
                });
                return setStickerCollectionValid(error);

            case OTHER_SOURCE_OF_FUNDS_SET_VALUE:
                error = validateString(action.value, {
                    subject: 'Other Source of Funds',
                    min: 3,
                });
                return setOtherSourceOfFundsValid(action.index, error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
