
import axios from 'axios';
import { setLookupData, setLookupFail } from '../../../actions/digitalOnboarding/lookupData';
import { FINANCE_API_URL } from '../../../actions/digitalOnboarding/types';

export const getData = async (store, next, action) => {
    (async () => {
        try {           
            if (FINANCE_API_URL.includes("localhost")) {
                const url = `http://localhost:8080/api/v1/lookups`;
                const returnData = await axios.get(url);
                store.dispatch(setLookupData(returnData.data));
            } else {
                const url = `${FINANCE_API_URL}/lookups`;
                const returnData = await axios.get(url);
                store.dispatch(setLookupData(returnData.data.data));
            }
        } catch (e) {
            store.dispatch(setLookupFail());
        }
    })();

    return next(action);
};