import {
    ADD_ADDITINAL_HOME_CONTENTS_VALUE,
    EDIT_HOME_CONTENT_VALUE,
    HOME_CONTENTS_SET_VALID,
    HOME_CONTENTS_SET_VALUE,
    SYNC_SAME_HOUSE_CONTENTS,
    UPDATE_HOME_CONTENT_LIST,
    DELETE_HOME_CONTENT_VALUE,
    SYNC_CONTENTS_PREMIUM_FREQUENCY_VALUE,
    HOME_CONTENTS_SET_VALUE_WITHOUT_VALIDATION,
} from '../../actions/allsureInsurance/homeContents';


import {
    ADD_NEW_HOME_OWNER_VALUE,
    EDIT_HOME_OWNER_VALUE,
    FORM_INVALID_TRANSITION_HOME_DETAILS,
    FORM_VALID_TRANSITION_HOME_DETAILS,
    HOME_DETAILS_SET_VALID,
    HOME_DETAILS_SET_VALUE,
    UPDATE_HOME_DETAILS_AFTER_EDIT,
} from '../../actions/allsureInsurance/homeDetails';

import { FIELD_STATUS, FORM_STATUS } from '../status';
const intialFormDetails = {
    address: { value: null, error: null, status: null },
    otherAddress: { value: null, error: null, status: null },
    areContentsFromSameHouse: { value: null, error: null, status: null },
    domesticWorker: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    homeOccupied: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    razorWire: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    householdLoss: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    homehired: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    inRetirement: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    premiumPaid: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    roofMaterialDetails: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    mainWallConstructedOff: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    homeDetailsType: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    insuredDetails: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue: null,
    },
    insuredWithOM: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue: null,
    },
    contentValue: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    claimAmount: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    claimedCount: {
        value: null,
        error: null,
        status: null,
        displayValue: null,
    },
    accidentalDamage: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    },
    accidentalDamageAmount:{
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    powerSurge: {
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    },
    powerSurgeCover:{
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    },
    domesticEmployees:{
        value: null,
        error: null,
        status: null,
        displayValue:null,
    },
    policyNumber:{
        value: null,
        error: null,
        status: FORM_STATUS.VALID,
        displayValue:null,
    }
};
export const ADDITIONAL_QUOTE = 'additionalQoute';
export const EDIT_QUOTE = 'editQuote_';
export const INITIAL_EDIT_KEY = 'initial_0';
export const initHomeContents = {
    formStatus: FORM_STATUS.INVALID,
    homeContentList: [],
    editHomeContentKey: INITIAL_EDIT_KEY,
    form: intialFormDetails,
};

export default (state = initHomeContents, action) => {
    // const trimField = (value) => (value ? value.trim() : value);
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRANSITION_HOME_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_HOME_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case HOME_CONTENTS_SET_VALUE:
            if (action.value.fieldType === 'householdLoss') {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        householdLoss: {
                            ...state.form.householdLoss,
                            value: action.value.value,
                            displayValue: action.value?.displayValue ?? action.value.value,
                        },
                        claimAmount: { value: null, error: null, status: null, displayValue: null },
                        claimedCount: { value: null, error: null, status: null, displayValue: null },
                    },
                };
            } else if (action.value.fieldType === 'address') {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        address: {
                            ...state.form.address,
                            value: action.value.value,
                            displayValue: action.value?.displayValue ?? action.value.value,
                        },
                        otherAddress: { value: null, error: null, status: null, displayValue: null },
                    },
                };
            } else if (action.value.fieldType === 'insuredDetails') {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        insuredDetails: {
                            ...state.form.insuredDetails,
                            value: action.value.value,
                            displayValue: action.value?.displayValue ?? action.value.value,
                        },
                        insuredWithOM: { value: null, error: null, status: null, displayValue: null },
                    },
                };
            }
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
            }));

            case HOME_CONTENTS_SET_VALUE_WITHOUT_VALIDATION:
           
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
                status: null,
                error: null,
            }));

        case HOME_CONTENTS_SET_VALID:
            const { fieldType, error } = action;
            return updateFormField(fieldType, (field) => ({
                ...field,
                error: error,
                status: mapError(error),
            }));

        case ADD_ADDITINAL_HOME_CONTENTS_VALUE:
            return { ...state, form: {
                ...intialFormDetails,
                premiumPaid: state.form.premiumPaid
            }, editHomeContentKey: ADDITIONAL_QUOTE };

        case EDIT_HOME_CONTENT_VALUE:
            return {
                ...state,
                form: state.homeContentList[+action.value.value],
                editHomeContentKey: EDIT_QUOTE + action.value.value,
            };

        case DELETE_HOME_CONTENT_VALUE:
            state.homeContentList.splice(action.value, 1);
            const modifiedArray = state.homeContentList;
            return { ...state, homeContentList: [...modifiedArray] };

        case UPDATE_HOME_CONTENT_LIST:
            const details=[...state.homeContentList]
            state.editHomeContentKey === ADDITIONAL_QUOTE
                ? details.push(state.form)
                : details.splice(state.editHomeContentKey.split('_')[1], 1, state.form);
            return { ...state, homeContentList:details, editHomeContentKey: INITIAL_EDIT_KEY };

        case SYNC_SAME_HOUSE_CONTENTS:
            const { contentValue, areContentsFromSameHouse } = state.form;
            return {
                ...state,
                form: action?.value
                    ? { ...state.form, ...action.value }
                    : { ...intialFormDetails, areContentsFromSameHouse, contentValue },
            };
       
        default:
            return state;
    }
};
