import { mapReceivedBusinessProducts, GET_BUSINESS_PRODUCTS } from '../../../actions/publicWeb/businessProducts';
import { getParams } from '../../../selectors/publicWeb/businessProducts';
import axios from 'axios';

const productSearchUrl = '/om-api/business-products/products';

export const businessProductservice = (store, next, action) => {
    const productCallParams = getParams(store.getState());
    const result = next(action);

    (async () => {
        if (action.type === GET_BUSINESS_PRODUCTS) {
            axios.get(productSearchUrl, {
                params: {
                    uid: productCallParams.uid,
                    skip: productCallParams.skip,
                    take: productCallParams.take
                }, headers: { 'Content-Type': 'application/json' }
            }).then(function (response) {
                store.dispatch(mapReceivedBusinessProducts(response.data));
            }, (error) => {
                store.dispatch(mapReceivedBusinessProducts());
            });
        }
    })();

    return result;
};
