/* eslint-disable no-restricted-syntax */
import { createSelector } from 'reselect';
import {  getHomeDetailsList, getHomeDetailsObject } from './homeDetails';
import { getAllMotorDetailForms, getMotorDetailsForm, getMotorDetailsObject } from './motorDetails';
import { getAllsureInsurance } from './index';
import { getIsInsurenceTypeSelected } from './insuranceType';
import { getPersonalDetailsSummary, getPurchasePersonalDetails } from './purchasePersonalDetails';
import { getBankingDetailsSummary } from './bankingDetails';
import {  getUploadDocumentsSummary } from './uploadDocuments';
import { getHomeContentList } from './homeContents';
import { getPersonalDetailsQuestions, getReferalDetailsMaped } from './personalDetails';

export const getAdditionalInfo = createSelector(
    getAllsureInsurance,
    (allsureInsurance) => allsureInsurance.purchaseAddionalHomeMotorDetails
);
export const getSynchValueOfAllsure = createSelector(
    getHomeDetailsList,
    getHomeContentList,
    getAllMotorDetailForms,
    (homeDetailsList, homeContentList, motorDetailForm) => {
        return {
            homeDetailsList,
            homeContentList,
            motorDetailForm,
        };
    }
);


export const getIsDataSynced = createSelector(
    getAdditionalInfo,
    (additionalInfo) => additionalInfo.isAdditionalProductFormsSynced
);

export const getAdditionalInfoFormDetails = createSelector(getAdditionalInfo, (additionalInfo) => additionalInfo.form);

export const isQuoteAndAddtionaldetailsSynched =createSelector(
    getHomeDetailsList,
    getHomeContentList,
    getAllMotorDetailForms,
    getAdditionalInfoFormDetails,
    (homeDetailsList, homeContentList, motorDetailForm,form) => {
        const additionalDetailsFormLength=Object.keys(form).map((key)=>Object.keys(form[key])).flat().length
        return homeDetailsList.length+homeContentList.length+motorDetailForm.length !== additionalDetailsFormLength
    }
);

export const getAdditionalDetailsSummary = createSelector(getAdditionalInfoFormDetails, (additionalInfoForm) => {
    const homeDetails = Object.values(additionalInfoForm.homeDetailsList)?.map((element) => [
        {
            value: element.burglarBarsDetails.value ?? '-',
            label: 'Do all opening windows on the ground floor (or all opening windows on the upper floors) have burglar bars?',
        },
        {
            value: element.steelFenceDetails.value ?? '-',
            label: 'Do you have a wall or steel fence at least 1.8m high around your home?',
        },
        {
            value: element.securityGateDetails.value ?? '-',
            label: 'Are all access doors fitted with security gates?',
        },
        {
            value: element.alarmSystemDetails.value ?? '-',
            label: 'Is your home protected with an alarm system? ',
        },
        ...(  element.alarmSystemDetails.value === "Yes" ? [{
            value: element.serviceProviderDetails.value ?? '-',
            label: 'Name of the service provider',
        }]:[] ),
        {
            value: element.householdLoss.value ?? '-',
            label: 'Have you suffered any loss or damage at home in the last 3 years?',
        },
        ...(  element.householdLoss.value === "Yes" ? [
            {
            value: element.claimedCount.value ?? '-',
            label: 'How many times have you claimed in the last 3 years ?',
        },
            {
            value: ('P'+ element.claimAmount.value) ?? '-',
            label: 'Enter the total value of the claim(s) paid out. (In P)',
        },
    ]:[] ),
        {
            value: element?.similarAgreementDetails?.value ?? '-',
            label:'Do you have a registered bond on your property?'
        },
        ...(  element?.similarAgreementDetails?.value ==="Yes"? [{
            value: element?.financialInstitution?.value ?? '-',
            label: 'State the Financial Institution\'s name:',
        }]:[]),
        {
            value: element.valuationReportDetails?.value ?? '-',
            label:'Do you have a house valuation report?'
        }
    ]);
    const homeContentDetails = Object.values(additionalInfoForm.homeContentList)?.map((element) => [
        {
            value: element.burglarBarsDetails.value ?? '-',
            label: 'Do all opening windows on the ground floor (or all opening windows on the upper floors) have burglar bars?',
        },
        {
            value: element.steelFenceDetails.value ?? '-',
            label: 'Do you have a wall or steel fence at least 1.8m high around your home?',
        },
        {
            value: element.securityGateDetails.value ?? '-',
            label: 'Are all access doors fitted with security gates?',
        },
        {
            value: element.alarmSystemDetails.value ?? '-',
            label: 'Is your home protected with an alarm system? ',
        },
       ...(  element.alarmSystemDetails.value ==="Yes"? [{
            value: element.serviceProviderDetails.value ?? '-',
            label: 'Name of the service provider',
        }]:[]),
        {
            value: element?.similarAgreementDetails?.value ?? '-',
            label:'Do you have a credit or similar agreement on any of your household goods? '
        },
        ...(  element?.similarAgreementDetails?.value ==="Yes"? [{
            value: element?.agreementServiceProvider?.value ?? '-',
            label: 'Name of the service provider',
        }]:[]),
        {
            value: element.valuationReportDetails?.value ?? '-',
            label:'Do you have a household contents inventory form ready for upload?'
        }
    ]);
    const motorDetails = Object.values(additionalInfoForm.motorDetailForm)?.map((element) => [
        {
            value: element.registrationNumberDetails.value ?? '-',
            label: 'Registration Number',
        },
        {
            value: element.chassisNumberDetails.value ?? '-',
            label: 'Chassis number',
        },
        {
            value: element.engineNumberDetails.value ?? '-',
            label: 'Engine Number',
        },
        {
            value: element.similarAgreementDetails.value ?? '-',
            label: 'Is the vehicle subject to credit or similar agreement?',
        },
        ...(element.similarAgreementDetails.value==="Yes"?[{
            value: element.motorServiceProvider.value ?? '-',
            label: 'Name of the service provider',
        }]:[])
    ]);
    return { motorDetails, homeDetails, homeContentDetails };
});

export const getPurchaseDetails = createSelector(
    getAdditionalDetailsSummary,
    getPersonalDetailsSummary,
    getBankingDetailsSummary,
    getUploadDocumentsSummary,
    getReferalDetailsMaped,
    getIsInsurenceTypeSelected('homeOwners'),
    getIsInsurenceTypeSelected('homeContent'),
    getIsInsurenceTypeSelected('motor'),
    getPersonalDetailsQuestions,
    (
        { homeDetails, homeContentDetails, motorDetails },
        { personalDetails, contactDetails, addressDetails },
        bankingDetailsSummary,
        uploadDocumentsSummary,
        referalDetailsMaped,
        isHomeOwnerSelected,
        isHomeContentSelected,
        isMotorSelected,
        personalDetailsAdditionalQuestions
    ) => [
        ...(isHomeOwnerSelected
            ? homeDetails?.map((item, index) => ({
                  title: `Additional Home Details ${homeDetails.length > 1 ? index + 1 : ''}`,
                  data: item,
              }))
            : []),
        ...(isHomeContentSelected
            ? homeContentDetails?.map((item, index) => ({
                  title: `Additional Home Content Details ${homeContentDetails.length > 1 ? index + 1 : ''}`,
                  data: item,
              }))
            : []),
        ...(isMotorSelected
            ? motorDetails?.map((item, index) => ({
                  title: `Additional Motor Details  ${motorDetails.length > 1 ? index + 1 : ''}`,
                  data: item,
              }))
            : []),
        ...[{ title: `Personal Details `, data: [...personalDetails,...personalDetailsAdditionalQuestions] }],
        ...[{ title: `Address Details `, data: addressDetails }],
        ...[{ title: `Contact Details `, data: contactDetails }],
        ...[{ title: `Banking Details `, data: bankingDetailsSummary }],
        ...[{ title: `Upload Documents `, data: uploadDocumentsSummary  }],
        ...[{ title:`Referral Details`,data: referalDetailsMaped }]
    ]
);

export const getMotorPurchaseDetails = createSelector(
    getAdditionalDetailsSummary,
    getMotorDetailsObject,
    ({ motorDetails }, motorDetailsObject) =>
        motorDetailsObject?.map((details, index) => [...details, ...(motorDetails[index] ?? [])])
);
