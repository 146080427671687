import axios from 'axios';
import {getIsIprsApiPending, getCarDetailsForm, getLogBookData} from "../../../selectors/roaCarInsurance/details";
import { getVehicleValuation } from '../../../selectors/roaCarInsurance/quotation';
import { getAboutYouForm } from '../../../selectors/roaCarInsurance/aboutYou';
import {
	apiTransitionValidateId,
	setValidateIdValid,
	setValidateIdValue
} from "../../../actions/roaCarInsurance/details";

export const validateIdService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();
	(async () => {
		if(!getIsIprsApiPending(state)) {
			store.dispatch(
				apiTransitionValidateId.pending()
			)
			try {
				const resp = await validateId(state)
				store.dispatch(setValidateIdValue(resp.data))
				store.dispatch(apiTransitionValidateId.success())

			} catch (e) {
				store.dispatch(setValidateIdValid(e))
				store.dispatch(apiTransitionValidateId.failure())
			}
		}
	})()
	return result
}

const validateId = async (state) => {
	const carDetails = getCarDetailsForm(state);
	const vehicleValuation = getVehicleValuation(state);
	const reg_number = getLogBookData(state);
	const aboutYou = getAboutYouForm(state);
    const payload = {
		session_id: vehicleValuation.value.session_id,
		id_number: carDetails.id.value,
		cover_type: '',
		registration_number: reg_number.value.registrationnumber.trim(),
		name:  `${aboutYou.firstName.value} ${aboutYou.lastName.value}`
	}

	console.log(payload)
    
  try {
		const url = '/om-api/roa-car-insurance/iprs-check'
		return await axios.post(url, payload)
	} catch (e) {
		 throw e
	}
}