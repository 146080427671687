const SHAREPRICE = 'publicWeb/shareprice/';

export const REQUEST_JHB_SHAREPRICE = SHAREPRICE + 'requestJhbShareprice';
export const REQUEST_LDN_SHAREPRICE = SHAREPRICE + 'requestLdnShareprice';
export const REQUEST_INDICATORS = SHAREPRICE + 'requestIndicators';
export const SET_JHBSHAREPRICE = SHAREPRICE + 'setValue/jhbShareprice';
export const SET_LDNSHAREPRICE = SHAREPRICE + 'setValue/ldnShareprice';
export const SET_JHBSHAREPRICEFETCHING = SHAREPRICE + 'setValue/jhbSharepriceFetching';
export const SET_LDNSHAREPRICEFETCHING = SHAREPRICE + 'setValue/ldnSharepriceFetching';
export const SET_INDICATORS = SHAREPRICE + 'setValue/indicators';
export const SET_CURRENCIES_TOGGLE = SHAREPRICE + 'setValue/currencyToggle';
export const SET_COMMODITIES_TOGGLE = SHAREPRICE + 'setValue/commoditiesToggle';
export const SET_LOCAL_TOGGLE = SHAREPRICE + 'setValue/localToggle';
export const SET_OFFSHORE_TOGGLE = SHAREPRICE + 'setValue/offshoreToggle';

export const requestJhbSharePrice = () => ({ type: REQUEST_JHB_SHAREPRICE });
export const requestLdnSharePrice = () => ({ type: REQUEST_LDN_SHAREPRICE });
export const requestIndicators = () => ({ type: REQUEST_INDICATORS });
export const setJhbSharePrice = value => ({ type: SET_JHBSHAREPRICE, value });
export const setLdnSharePrice = value => ({ type: SET_LDNSHAREPRICE, value });

export const setJhbSharePriceFetching = value => ({ type: SET_JHBSHAREPRICEFETCHING, value });
export const setLdnSharePriceFetching = value => ({ type: SET_LDNSHAREPRICEFETCHING, value });
export const setIndicators = value => ({ type: SET_INDICATORS, value });
export const setCommoditiesToggle = value => ({ type: SET_COMMODITIES_TOGGLE, value });
export const setCurrenciesToggle = value => ({ type: SET_CURRENCIES_TOGGLE, value });
export const setOffshoreToggle = value => ({ type: SET_OFFSHORE_TOGGLE, value });
export const setLocalToggle = value => ({ type: SET_LOCAL_TOGGLE, value });
