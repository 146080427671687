import { FORM_TYPE } from "./type";

//Transitions
export const TOGGLE_DRAWER = 'customerComplaints/complaintArbitration/transition/toggleDrawer';
export const toggleDrawer = value => ({ type: TOGGLE_DRAWER, value });
export const toggleComplaintForm = () => toggleDrawer(FORM_TYPE.COMPLAINT);
export const toggleArbitrationForm = () => toggleDrawer(FORM_TYPE.ARBITRATION);

export const FORM_VALID_TRANSITION_COMPLAINT_FORM = 'customerComplaints/complaintArbitration/transition/formValid';
export const FORM_INVALID_TRANSITION_COMPLAINT_FORM = 'customerComplaints/complaintArbitration/transition/formInvalid';

export const transitionComplaintForm = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_COMPLAINT_FORM }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_COMPLAINT_FORM }),
};

export const RESET_STATE_TRANSITION_COMPLAINT_FORM = 'customerComplaints/complaintArbitration/transition/resetState';
export const resetComplaintForm = () => ({ type: RESET_STATE_TRANSITION_COMPLAINT_FORM });

//Events
export const COMPLAINT_FORM_SET_VALUE = 'customerComplaints/complaintArbitration/setValue/';
export const COMPLAINT_FORM_SET_VALID = 'customerComplaints/complaintArbitration/setValid/';
export const EMAIL_SEND = 'customerComplaints/complaintArbitration/email/';

export const RECEIVER_EMAIL_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'receiverEmailAddress';

//Values
export const TITLE_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'title';
export const TITLE_SET_VALID = COMPLAINT_FORM_SET_VALID + 'title';

export const FIRSTNAME_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'firstName';
export const FIRSTNAME_SET_VALID = COMPLAINT_FORM_SET_VALID + 'firstName';

export const SURNAME_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = COMPLAINT_FORM_SET_VALID + 'surname';

export const CELLPHONE_NUMBER_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'cellphoneNumber';
export const CELLPHONE_NUMBER_SET_VALID = COMPLAINT_FORM_SET_VALID + 'cellphoneNumber';

export const EMAIL_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID = COMPLAINT_FORM_SET_VALID + 'email';

export const IDENTITY_TYPE_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'identityType';
export const IDENTITY_TYPE_SET_VALID = COMPLAINT_FORM_SET_VALID + 'identityType';

export const ID_NUMBER_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = COMPLAINT_FORM_SET_VALID + 'idNumber';

export const PASSPORT_NUMBER_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'passportNumber';
export const PASSPORT_NUMBER_SET_VALID = COMPLAINT_FORM_SET_VALID + 'passportNumber';

export const PASSPORT_COUNTRY_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'passportCountry';
export const PASSPORT_COUNTRY_SET_VALID = COMPLAINT_FORM_SET_VALID + 'passportCountry';

export const POLICY_NUMBER_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'policyNumber';
export const POLICY_NUMBER_SET_VALID = COMPLAINT_FORM_SET_VALID + 'policyNumber';

export const PRODUCT_TYPE_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'productType';
export const PRODUCT_TYPE_SET_VALID = COMPLAINT_FORM_SET_VALID + 'productType';

export const COMPLAINT_RELATION_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'complaintRelation';
export const COMPLAINT_RELATION_SET_VALID = COMPLAINT_FORM_SET_VALID + 'complaintRelation';

export const COMPLAINT_TITLE_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'complaintTitle';
export const COMPLAINT_TITLE_SET_VALID = COMPLAINT_FORM_SET_VALID + 'complaintTitle';

export const COMPLAINT_DESCRIPTION_SET_VALUE = COMPLAINT_FORM_SET_VALUE + 'complaintDescription';
export const COMPLAINT_DESCRIPTION_SET_VALID = COMPLAINT_FORM_SET_VALID + 'complaintDescription';

export const COMPLAINT_FORM_SEND_INITIATE = COMPLAINT_FORM_SET_VALUE + 'initiate'

export const COMPLAINT_FORM_SEND = EMAIL_SEND + 'send';
export const COMPLAINT_FORM_SEND_SUCCESS = EMAIL_SEND + 'success';
export const COMPLAINT_FORM_SEND_FAILURE = EMAIL_SEND + 'failure';

export const COMPLAINT_TOGGLE_SEND_ME_COPY = 'customerComplaints/complaintArbitration/toggle/sendMeCopy';

export const setReceiverEmailAddress = value => ({ type: RECEIVER_EMAIL_SET_VALUE, value });

export const setTitleValue = value => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = error => ({ type: TITLE_SET_VALID, error });

export const setFirstNameValue = value => ({ type: FIRSTNAME_SET_VALUE, value });
export const setFirstNameValid = error => ({ type: FIRSTNAME_SET_VALID, error });

export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });

export const setCellphoneNumberValue = (value, countryCode) => ({ type: CELLPHONE_NUMBER_SET_VALUE, value, countryCode });
export const setCellphoneNumberValid = (error, countryCode) => ({ type: CELLPHONE_NUMBER_SET_VALID, error, countryCode });

export const setEmailValue = value => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = error => ({ type: EMAIL_SET_VALID, error });

export const setIdentityTypeValue = value => ({ type: IDENTITY_TYPE_SET_VALUE, value });
export const setIdentityTypeValid = error => ({ type: IDENTITY_TYPE_SET_VALID, error });

export const setIdNumberValue = value => ({ type: ID_NUMBER_SET_VALUE, value });
export const setIdNumberValid = error => ({ type: ID_NUMBER_SET_VALID, error });

export const setPassportNumberValue = value => ({ type: PASSPORT_NUMBER_SET_VALUE, value });
export const setPassportNumberValid = error => ({ type: PASSPORT_NUMBER_SET_VALID, error });

export const setPassportCountryValue = value => ({ type: PASSPORT_COUNTRY_SET_VALUE, value });
export const setPassportCountryValid = error => ({ type: PASSPORT_COUNTRY_SET_VALID, error });

export const setPolicyNumberValue = value => ({ type: POLICY_NUMBER_SET_VALUE, value });
export const setPolicyNumberValid = error => ({ type: POLICY_NUMBER_SET_VALID, error });

export const setProductTypeValue = value => ({ type: PRODUCT_TYPE_SET_VALUE, value });
export const setProductTypeValid = error => ({ type: PRODUCT_TYPE_SET_VALID, error });

export const setComplaintRelationValue = value => ({ type: COMPLAINT_RELATION_SET_VALUE, value });
export const setComplaintRelationValid = error => ({ type: COMPLAINT_RELATION_SET_VALID, error });

export const setComplaintTitleValue = value => ({ type: COMPLAINT_TITLE_SET_VALUE, value });
export const setComplaintTitleValid = error => ({ type: COMPLAINT_TITLE_SET_VALID, error });

export const setComplaintDescriptionValue = value => ({ type: COMPLAINT_DESCRIPTION_SET_VALUE, value });
export const setComplaintDescriptionValid = error => ({ type: COMPLAINT_DESCRIPTION_SET_VALID, error });

export const sendComplaintInitiate = () => ({ type: COMPLAINT_FORM_SEND_INITIATE });

export const sendComplaint = () => ({ type: COMPLAINT_FORM_SEND });
export const sendComplaintSuccess = () => ({ type: COMPLAINT_FORM_SEND_SUCCESS });
export const sendComplaintFailure = () => ({ type: COMPLAINT_FORM_SEND_FAILURE });

export const toggleSendMeCopy = () => ({ type: COMPLAINT_TOGGLE_SEND_ME_COPY });
