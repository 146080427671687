export const RW_MOTOR_INSURANCE_DECLARATIONS_FORM = 'motorPrivateRwanda/declarationsForm/';

export const MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE = RW_MOTOR_INSURANCE_DECLARATIONS_FORM + 'setValue/';
export const MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID = RW_MOTOR_INSURANCE_DECLARATIONS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM = 'motorPrivateRwanda/declarationForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM = 'motorPrivateRwanda/declarationForm/transition/formInValid';

export const REINITIALIZE = 'motorPrivateRwanda/declarationForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DECLARATIONS_FORM = 'motorPrivateRwanda/declarationForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_DECLARATIONS_FORM,
    value,
});

export const transitionMotorInsuranceDeclarationsForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_DECLARATIONS_FORM = `${MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DECLARATIONS_FORM = `${MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DECLARATIONS_FORM = `${MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDocumentsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_DECLARATIONS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_DECLARATIONS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_DECLARATIONS_FORM,
    }),
};

//calculated Data
export const COLLECT_DECLARATIONS_FORM_DATA = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DECLARATIONS_SUCCESS = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'success';
export const SUBMIT_DECLARATIONS_FAILURE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
    type: COLLECT_DECLARATIONS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
    type: SUBMIT_DECLARATIONS_SUCCESS,
    value,
});
export const submitDocumentsFailure = (error) => ({
    type: SUBMIT_DECLARATIONS_FAILURE,
    error,
});

// Form Values
export const DRIVER_HEALTH_DECLARATION_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'driverHealthDeclaration';
export const DRIVER_HEALTH_DECLARATION_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'driverHealthDeclaration';

export const VEHICLE_CONDITION_DECLARATION_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'vehicleConditionDeclaration';
export const VEHICLE_CONDITION_DECLARATION_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'vehicleConditionDeclaration';

export const DRIVER_LICENSE_DECLARATION_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'driverLicenseDeclaration';
export const DRIVER_LICENSE_DECLARATION_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'driverLicenseDeclaration';

export const AVERAGE_CLAUSE_CONSENT_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'averageClauseConsent';
export const AVERAGE_CLAUSE_CONSENT_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'averageClauseConsent';

export const FULL_VALUE_DECLARATION_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'fullValueDeclaration';
export const FULL_VALUE_DECLARATION_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'fullValueDeclaration';

export const MARKETING_CONSENT_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'marketingConsent';
export const MARKETING_CONSENT_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'marketingConsent';

export const PRIVACY_POLICY_CONFIRMATION_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'privacyPolicyConfirmation';
export const PRIVACY_POLICY_CONFIRMATION_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'privacyPolicyConfirmation';

export const ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'adviceAcknowledgementIndemnity';
export const ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'adviceAcknowledgementIndemnity';

export const NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'noPremiumRefundAcknowledgement';
export const NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'noPremiumRefundAcknowledgement';

export const FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'fullPremiumSettlementConsent';
export const FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'fullPremiumSettlementConsent';

export const FINAL_DECLARATION_AGREEMENT_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'finalDeclarationAgreement';
export const FINAL_DECLARATION_AGREEMENT_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'finalDeclarationAgreement';

// Action Creators
export const setDriverHealthDeclarationValue = (value) => ({
    type: DRIVER_HEALTH_DECLARATION_SET_VALUE,
    value,
});

export const setDriverHealthDeclarationValid = (error) => ({
    type: DRIVER_HEALTH_DECLARATION_SET_VALID,
    error,
});

export const setVehicleConditionDeclarationValue = (value) => ({
    type: VEHICLE_CONDITION_DECLARATION_SET_VALUE,
    value,
});

export const setVehicleConditionDeclarationValid = (error) => ({
    type: VEHICLE_CONDITION_DECLARATION_SET_VALID,
    error,
});

export const setDriverLicenseDeclarationValue = (value) => ({
    type: DRIVER_LICENSE_DECLARATION_SET_VALUE,
    value,
});

export const setDriverLicenseDeclarationValid = (error) => ({
    type: DRIVER_LICENSE_DECLARATION_SET_VALID,
    error,
});

export const setAverageClauseConsentValue = (value) => ({
    type: AVERAGE_CLAUSE_CONSENT_SET_VALUE,
    value,
});

export const setAverageClauseConsentValid = (error) => ({
    type: AVERAGE_CLAUSE_CONSENT_SET_VALID,
    error,
});

export const setFullValueDeclarationValue = (value) => ({
    type: FULL_VALUE_DECLARATION_SET_VALUE,
    value,
});

export const setFullValueDeclarationValid = (error) => ({
    type: FULL_VALUE_DECLARATION_SET_VALID,
    error,
});

export const setMarketingConsentValue = (value) => ({
    type: MARKETING_CONSENT_SET_VALUE,
    value,
});

export const setMarketingConsentValid = (error) => ({
    type: MARKETING_CONSENT_SET_VALID,
    error,
});

export const setPrivacyPolicyConfirmationValue = (value) => ({
    type: PRIVACY_POLICY_CONFIRMATION_SET_VALUE,
    value,
});

export const setPrivacyPolicyConfirmationValid = (error) => ({
    type: PRIVACY_POLICY_CONFIRMATION_SET_VALID,
    error,
});

export const setAdviceAcknowledgementIndemnityValue = (value) => ({
    type: ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALUE,
    value,
});

export const setAdviceAcknowledgementIndemnityValid = (error) => ({
    type: ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALID,
    error,
});

export const setNoPremiumRefundAcknowledgementValue = (value) => ({
    type: NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALUE,
    value,
});

export const setNoPremiumRefundAcknowledgementValid = (error) => ({
    type: NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALID,
    error,
});

export const setFullPremiumSettlementConsentValue = (value) => ({
    type: FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALUE,
    value,
});

export const setFullPremiumSettlementConsentValid = (error) => ({
    type: FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALID,
    error,
});

export const setFinalDeclarationAgreementValue = (value) => ({
    type: FINAL_DECLARATION_AGREEMENT_SET_VALUE,
    value,
});

export const setFinalDeclarationAgreementValid = (error) => ({
    type: FINAL_DECLARATION_AGREEMENT_SET_VALID,
    error,
});
