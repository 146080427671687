import { HOME_CONTENT_API_SEND, HOME_OWNERS_API_SEND } from '../../../actions/allsureInsurance/homeDetails';
import { HOME_MOTOR_API_SEND } from '../../../actions/allsureInsurance/motorDetails';
import { GET_QUOTE_REQUEST, SEND_CALL_ME_BACK_REQUEST, SEND_LEADS_REQUEST, SEND_UPLOAD_DOCUMENTS, SUBMIT_POLICY_REQUEST } from '../../../actions/allsureInsurance/policyRequests';
import { ALLSURE_APPLICATION_REFERENCE_DATA } from '../../../actions/allsureInsurance/referenceData';
import { leadsMailService } from './leadsMailService';
import { purchaseService } from './purchaseService';
import { quotingMailService } from './quotingMailService';
import { quotingService } from './quotingService';
import { referenceDataService } from './referenceData';
import { uploadDocumentsService } from './uploadDocumentsService';

export const allsureInsuranceQuoteService = (store, next, action) => {

    if (action.type === GET_QUOTE_REQUEST) return quotingService(store, next, action); 
    
    if (action.type === SUBMIT_POLICY_REQUEST) return purchaseService(store, next, action); 

    if (action.type === SEND_UPLOAD_DOCUMENTS) return uploadDocumentsService(store, next, action)

    if (action.type === SEND_CALL_ME_BACK_REQUEST ) return quotingMailService(store,next,action);

    if (action.type === SEND_LEADS_REQUEST ) return leadsMailService(store,next,action);

    if (action.type.startsWith(ALLSURE_APPLICATION_REFERENCE_DATA) ) return referenceDataService(store, next, action);

    return null;
};
