import {
    getFirstname,
    getSurname,
    getIdNumber,
    getMobileNumber,
    getCaptureDetailsCreditConsent,
    getEmploymentType,
    getPayslipAvailability,
    getWageFrequency,
} from "../../../selectors/publicWeb/qualifyingCheckCallMeBack";
import {
    transitionQualifyingCheckCallMeBack,
} from "../../../actions/publicWeb/qualifyingCheckCallMeBack";
import { FIELD_STATUS, TOGGLE_STATUS } from "../../../reducers/status";

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const hasAnyOptionBeenSelected = field => field && field.value !== null;

export const validateQualifyingCheckCallMeBackForm = (firstname, surname, idNumber, mobileNumber, creditConsent, employmentType, payslipAvailability, wageFrequency) => {
    return isValid(firstname) &&
        isValid(surname) &&
        isValid(idNumber) &&
        isValid(mobileNumber) &&
        hasAnyOptionBeenSelected(employmentType) &&
        hasAnyOptionBeenSelected(payslipAvailability) &&
        hasAnyOptionBeenSelected(wageFrequency) &&
        creditConsent === TOGGLE_STATUS.YES
}

export const qualifyingCheckCallMeBackFormRules = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    const firstname = getFirstname(state);
    const surname = getSurname(state);
    const idNumber = getIdNumber(state);
    const mobileNumber = getMobileNumber(state);
    const creditConsent = getCaptureDetailsCreditConsent(state);
    const employmentType = getEmploymentType(state);
    const payslipAvailability = getPayslipAvailability(state);
    const wageFrequency = getWageFrequency(state);

    const qualifyingFormValid = validateQualifyingCheckCallMeBackForm(
        firstname, surname, idNumber, mobileNumber, creditConsent, employmentType, payslipAvailability, wageFrequency
    );

    if (qualifyingFormValid) {
        store.dispatch(transitionQualifyingCheckCallMeBack.formValid());
    } else {
        store.dispatch(transitionQualifyingCheckCallMeBack.formInvalid());
    }

    return result
}