const DETAILS_TRANSITION = 'roaCarInsurance/confirmDetails/transition/';

// Transitions
export const FORM_VALIDATE_TRANSITION_DETAILS = DETAILS_TRANSITION + 'validateForm';
export const FORM_INVALIDATE_TRANSITION_DETAILS = DETAILS_TRANSITION + 'invalidateForm';
export const validateForm = () => ({ type: FORM_VALIDATE_TRANSITION_DETAILS });
export const invalidateForm = () => ({ type: FORM_INVALIDATE_TRANSITION_DETAILS });


// Events
export const DETAILS_SET_VALUE = 'roaCarInsurance/details/setValue/';
export const DETAILS_SET_VALID = 'roaCarInsurance/details/setValid/';

// LOGBOOK  API Call actions
 export const API_PENDING_TRANSITION_UPLOAD_LOGBOOK = `${DETAILS_SET_VALUE}/apiTransition/pending`
 export const API_SUCCESS_TRANSITION_UPLOAD_LOGBOOK = `${DETAILS_SET_VALUE}/apiTransition/success`
 export const API_FAILURE_TRANSITION_UPLOAD_LOGBOOK = `${DETAILS_SET_VALUE}/apiTransition/failure`
 export const apiTransitionUploadLogbook = {
 	pending: () => ({
 		type: API_PENDING_TRANSITION_UPLOAD_LOGBOOK
 	}),
	 	success: () => ({
 		type: API_SUCCESS_TRANSITION_UPLOAD_LOGBOOK
 	}),
 	failure: () => ({
 		type: API_FAILURE_TRANSITION_UPLOAD_LOGBOOK
 	})
 }

 //IPRS API Call actions
 export const API_PENDING_TRANSITION_VALIDATE_ID = `${DETAILS_SET_VALUE}/apiTransition/iprs/pending`
 export const API_SUCCESS_TRANSITION_VALIDATE_ID = `${DETAILS_SET_VALUE}/apiTransition/iprs/success`
 export const API_FAILURE_TRANSITION_VALIDATE_ID = `${DETAILS_SET_VALUE}/apiTransition/iprs/failure`
 export const apiTransitionValidateId = {
 	pending: () => ({
 		type: API_PENDING_TRANSITION_VALIDATE_ID
 	}),
	 	success: () => ({
 		type: API_SUCCESS_TRANSITION_VALIDATE_ID
 	}),
 	failure: () => ({
 		type: API_FAILURE_TRANSITION_VALIDATE_ID
 	})
 }

 //KRA API Call actions
 export const API_PENDING_TRANSITION_VALIDATE_KRA = `${DETAILS_SET_VALUE}/apiTransition/kra/pending`
 export const API_SUCCESS_TRANSITION_VALIDATE_KRA = `${DETAILS_SET_VALUE}/apiTransition/kra/success`
 export const API_FAILURE_TRANSITION_VALIDATE_KRA = `${DETAILS_SET_VALUE}/apiTransition/kra/failure`
 export const apiTransitionValidateKra = {
 	pending: () => ({
 		type: API_PENDING_TRANSITION_VALIDATE_KRA
 	}),
	 	success: () => ({
 		type: API_SUCCESS_TRANSITION_VALIDATE_KRA
 	}),
 	failure: () => ({
 		type: API_FAILURE_TRANSITION_VALIDATE_KRA
 	})
 }

 //Cover date API Call actions
 export const API_PENDING_TRANSITION_VALIDATE_COVER_DATE = `${DETAILS_SET_VALUE}/apiTransition/coverDate/pending`
 export const API_SUCCESS_TRANSITION_VALIDATE_COVER_DATE = `${DETAILS_SET_VALUE}/apiTransition/coverDate/success`
 export const API_FAILURE_TRANSITION_VALIDATE_COVER_DATE = `${DETAILS_SET_VALUE}/apiTransition/coverDate/failure`
 export const apiTransitionValidateCoverDate = {
 	pending: () => ({
 		type: API_PENDING_TRANSITION_VALIDATE_COVER_DATE
 	}),
	 	success: () => ({
 		type: API_SUCCESS_TRANSITION_VALIDATE_COVER_DATE
 	}),
 	failure: () => ({
 		type: API_FAILURE_TRANSITION_VALIDATE_COVER_DATE
 	})
 }
export const INITIALIZE_COMPREHENSIVE_COVER_TOTAL = DETAILS_SET_VALUE + 'initialize/comprehensiveCoverTotal';
export const LOG_BOOK_FILE_SET_VALUE = DETAILS_SET_VALUE + 'logBookFile';
export const UPDATE_BENEFIT_VALUE = DETAILS_SET_VALUE + 'benefitUpdateValue'
export const LOG_BOOK_FILE_SET_VALID = DETAILS_SET_VALID + 'logBookFile';
export const LOG_BOOK_DATA_SET_VALUE = DETAILS_SET_VALUE + 'logBookData';
export const LOG_BOOK_DATA_SET_VALID = DETAILS_SET_VALID + 'logBookData';
export const ALTER_BENEFIT_SET_VALID = DETAILS_SET_VALID +  'alterBenefit';
export const ALTER_BENEFIT_SET_VALUE = DETAILS_SET_VALUE + 'alterBenefit';
export const CALCULATE_EXTRA_BENEFIT_VALID = DETAILS_SET_VALID + 'extraBenefit';
export const CALCULATE_EXTRA_BENEFIT_VALUE = DETAILS_SET_VALUE + 'extraBenefit';
export const VEHICLE_REGISTRATION_NUMBER_SET_VALUE = DETAILS_SET_VALUE + 'vehicleRegNumber';
export const VEHICLE_REGISTRATION_NUMBER_SET_VALID = DETAILS_SET_VALID + 'vehicleRegNumber';
export const VEHICLE_REGISTRATION_NUMBER_VALID = DETAILS_SET_VALID + 'vehicleRegNumber';
export const ID_SET_VALUE = DETAILS_SET_VALUE + 'id';
export const ID_SET_VALID = DETAILS_SET_VALID + 'id';
export const KRA_PIN_SET_VALUE = DETAILS_SET_VALUE + 'kraPin';
export const KRA_PIN_SET_VALID = DETAILS_SET_VALID + 'kraPin';
export const COVER_START_DATE_SET_VALUE = DETAILS_SET_VALUE + 'coverStartDate';
export const COVER_START_DATE_SET_VALID = DETAILS_SET_VALID + 'coverStartDate';
export const COMPREHENSIVE_BENEFIT_EDIT_MODE_SET_VALUE = DETAILS_SET_VALUE + 'comprehensiveBenefitEditMode';
export const EXTRA_BENEFIT_SET_VALUE = DETAILS_SET_VALUE + 'extraBenefit';
export const EXTRA_BENEFIT_SET_VALID = DETAILS_SET_VALID + 'extraBenefit';
export const ALTER_BENEFIT_SET_ID_VALUE = DETAILS_SET_VALUE+'alterBenefitId';
export const EXTRA_BENEFIT_SET_ID_VALUE = DETAILS_SET_VALUE+'extraBenefitId';
export const COLLECT_LOGBOOK_DATA_VALUE = DETAILS_SET_VALUE + 'collectLogbookDataValue';
export const COMPUTE_EXTRA_BENEFIT_VALUE = DETAILS_SET_VALUE + 'computeExtraBenefitValue';
export const TOGGLE_EXTRA_BENEFIT_VALUE = DETAILS_SET_VALUE + 'toggleExtraBenefitValue';
export const ADD_EXTRA_BENEFIT_SET_VALUE = DETAILS_SET_VALUE + 'addExtraBenefitValue';
export const ADD_EXTRA_BENEFIT_SET_VALID = DETAILS_SET_VALID + 'addExtraBenefitValue';
export const COMPUTE_ADD_EXTRA_BENEFIT_VALUE = DETAILS_SET_VALUE + 'computeAddExtraBenefit';
export const VALIDATE_ID_SET_VALUE = DETAILS_SET_VALUE + 'validateId';
export const VALIDATE_ID_SET_VALID = DETAILS_SET_VALID + 'validateId';
export const VALIDATE_KRA_SET_VALUE = DETAILS_SET_VALUE + 'validateKra';
export const VALIDATE_KRA_SET_VALID = DETAILS_SET_VALID + 'validateKra';
export const KRA_VALIDATION = DETAILS_SET_VALID + 'validateKra';
export const IPRS_ID_VALIDATION =  DETAILS_SET_VALUE + 'iprsIdValidation';
export const VALIDATE_COVER_DATE_SET_VALUE = DETAILS_SET_VALUE + 'validateCoverDate';
export const VALIDATE_COVER_DATE_SET_VALID = DETAILS_SET_VALID + 'validateCoverDatea';
export const COVER_DATE_VALIDATION =  DETAILS_SET_VALUE + 'coverDateValidation';
export const initializeComprehensiveCoverTotal = () => ({type: INITIALIZE_COMPREHENSIVE_COVER_TOTAL});
export const collectLogbookDataValue = () => ({type: COLLECT_LOGBOOK_DATA_VALUE});
export const computeExtrabenefitValue = () => ({type: COMPUTE_EXTRA_BENEFIT_VALUE});
export const setExtraBenefitValue = value => ({type:EXTRA_BENEFIT_SET_VALUE, value });
export const setExtraBenefitValid = error => ({type:EXTRA_BENEFIT_SET_VALID, error });
export const setLogBookFileValue = value => ({ type:LOG_BOOK_FILE_SET_VALUE, value });
export const setLogBookFileValid = error => ({ type:LOG_BOOK_FILE_SET_VALID, error });
export const setLogBookDataValue = value => ({ type:LOG_BOOK_DATA_SET_VALUE, value });
export const setLogBookDataValid = error => ({ type:LOG_BOOK_DATA_SET_VALID, error });
export const setVehicleRegNumberValue = value => ({ type: VEHICLE_REGISTRATION_NUMBER_SET_VALUE, value });
export const setVehicleRegNumberValid = error => ({ type: VEHICLE_REGISTRATION_NUMBER_SET_VALID, error });
export const setIDValue = value => ({ type: ID_SET_VALUE, value });
export const setIDValid = error => ({ type: ID_SET_VALID, error });
export const setKraPinValue = value => ({ type: KRA_PIN_SET_VALUE, value });
export const setKraPinValid = error => ({ type: KRA_PIN_SET_VALID, error });
export const setCoverStartDateValue = value => ({ type: COVER_START_DATE_SET_VALUE, value });
export const setCoverStartDateValid = error => ({ type: COVER_START_DATE_SET_VALID, error });
export const setAlterBenefitValue = value => ({type: ALTER_BENEFIT_SET_VALUE, value });
export const setAlterBenefitValid = error => ({type: ALTER_BENEFIT_SET_VALID, error });
export const setCalculateExtraBenefitValue = value => ({type: CALCULATE_EXTRA_BENEFIT_VALID, value });
export const setCalculateExtraBenefitValid = error => ({type: CALCULATE_EXTRA_BENEFIT_VALID, error });
export const setComprehensiveCoverEditMode = value => ({type: COMPREHENSIVE_BENEFIT_EDIT_MODE_SET_VALUE, value});
export const setAlterBenefitId = value => ({type:ALTER_BENEFIT_SET_ID_VALUE, value });
export const setExtraBenefitId = value => ({type:EXTRA_BENEFIT_SET_ID_VALUE, value });
export const setUpdateBenefitEditValue = value => ({type: UPDATE_BENEFIT_VALUE, value});
export const setToggleExtraBenefitValue = value => ({type: TOGGLE_EXTRA_BENEFIT_VALUE, value});
export const setAddExtraBenefitValue = value => ({type: ADD_EXTRA_BENEFIT_SET_VALUE, value});
export const setAddExtraBenefitValid = error => ({type: ADD_EXTRA_BENEFIT_SET_VALID, error});
export const computeAddExtraBenefitValue = () => ({type: COMPUTE_ADD_EXTRA_BENEFIT_VALUE});
export const iprsIdValidation = () => ({type: IPRS_ID_VALIDATION});
export const kraValidation = () => ({type: KRA_VALIDATION});
export const coverStartDateValidation = () => ({type: COVER_DATE_VALIDATION});
export const setStartDateValue = value => ({type: VALIDATE_COVER_DATE_SET_VALUE, value});
export const setStartDateValid = error => ({type:VALIDATE_COVER_DATE_SET_VALID, error });
export const setValidateIdValue = value => ({type: VALIDATE_ID_SET_VALUE, value});
export const setValidateIdValid = error => ({type:VALIDATE_ID_SET_VALID, error });
export const setValidateKraValue = value => ({type: VALIDATE_KRA_SET_VALUE, value});
export const setValidateKraValid = error => ({type:VALIDATE_KRA_SET_VALID, error });

// transition form status
export const transitionCarDetailsForm = {
    formValid: () => ({ type: FORM_VALIDATE_TRANSITION_DETAILS}),
    formInvalid: () => ({ type: FORM_INVALIDATE_TRANSITION_DETAILS }),
};