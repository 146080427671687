export const ONTHEMONEY_ENROLLEDCOURSES_SET_VALUE = `publicWeb/onTheMoney/enrolledCourses/setValue`;
export const ONTHEMONEY_CALENDAREVENTS_SET_VALUE = `publicWeb/onTheMoney/calendarEvents/setValue`;

export const setOnTheMoneyEnrolledCourses = value => ({ type: ONTHEMONEY_ENROLLEDCOURSES_SET_VALUE, value });
export const setOnTheMoneyCalendarEvents = value => ({ type: ONTHEMONEY_CALENDAREVENTS_SET_VALUE, value });

export const ONTHEMONEY_ENROLLEDCOURSES_FETCH = `publicWeb/onTheMoney/enrolledCourses/fetch`;
export const ONTHEMONEY_CALENDAREVENTS_FETCH = `publicWeb/onTheMoney/calendarEvents/fetch`;

export const fetchOnTheMoneyEnrolledCourses = () => ({ type: ONTHEMONEY_ENROLLEDCOURSES_FETCH });
export const fetchOnTheMoneyCalendarEvents = () => ({ type: ONTHEMONEY_CALENDAREVENTS_FETCH });
