import { getEmploymentDetailsForm } from '../../../selectors/ugMotorInsurance/employmentDetails';
import { transitionMotorInsuranceEmploymentDetailsForm } from '../../../actions/ugMotorInsurance/employmentDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const employmentDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const employmentDetailsForm = getEmploymentDetailsForm(store.getState());
    const employmentDetailsFormValid = validateEmploymentDetailsFormDetails(employmentDetailsForm);

    const formAction = employmentDetailsFormValid
        ? transitionMotorInsuranceEmploymentDetailsForm.formValid()
        : transitionMotorInsuranceEmploymentDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateEmploymentDetailsFormDetails = (formFields) => {
    const commonChecks =
        formFields.employmentStatus &&
        formFields.employmentStatus.status === FIELD_STATUS.VALID &&
        formFields.personalDataProcessingConsent &&
        formFields.personalDataProcessingConsent.status === FIELD_STATUS.VALID;

    if (
        formFields.employmentStatus === 'Employment(Permanent)' ||
        formFields.employmentStatus === 'Employment(Contract)' ||
        formFields.employmentStatus === 'Part-time'
    ) {
        return (
            commonChecks &&
            formFields.employerName &&
            formFields.employerName.status === FIELD_STATUS.VALID &&
            formFields.occupation &&
            formFields.occupation.status === FIELD_STATUS.VALID &&
            formFields.industryOrSector &&
            formFields.industryOrSector.status === FIELD_STATUS.VALID &&
            formFields.employerPostalAddress &&
            formFields.employerPostalAddress.status === FIELD_STATUS.VALID &&
            formFields.employerTownOrCity &&
            formFields.employerTownOrCity.status === FIELD_STATUS.VALID &&
            formFields.employerPhoneNumber &&
            formFields.employerPhoneNumber.status === FIELD_STATUS.VALID
        );
    } else if (formFields.employmentStatus === 'Private Practice') {
        return (
            commonChecks &&
            formFields.occupation &&
            formFields.occupation.status === FIELD_STATUS.VALID &&
            formFields.industryOrSector &&
            formFields.industryOrSector.status === FIELD_STATUS.VALID
        );
    } else if (formFields.employmentStatus === 'Self Employed') {
        return commonChecks && formFields.industryOrSector && formFields.industryOrSector.status === FIELD_STATUS.VALID;
    } else {
        return commonChecks;
    }
};
