import { createSelector } from 'reselect';
import { getBudgetBenchmarkCalculator } from './index';
import { FORM_STATUS, TOGGLE_STATUS } from '../../reducers/status';
import { getBudgetRules } from './referenceData';
import { formatCurrency } from '../currency';
import { getCurrencyCodeSelector } from '../../../site-builder/src/components/currencies';

export const getCalculator = createSelector(
    getBudgetBenchmarkCalculator,
    budgetBenchmarkCalculator => budgetBenchmarkCalculator.calculator
)

export const isCalculateButtonDisabled = createSelector(
    getCalculator,
    calculator => calculator.formStatus === FORM_STATUS.INVALID
)

export const isResultCardDisplayed = createSelector(
    getCalculator,
    calculator => calculator.displayResultsCard === TOGGLE_STATUS.YES
)

export const isResultsBudgetTableDisplayed = createSelector(
    getCalculator,
    calculator => calculator.displayResultsBudgetTable === TOGGLE_STATUS.YES
)

export const getCalculatorForm = createSelector(
    getCalculator,
    calculator => calculator.form
)

const makeField = (fieldId) => createSelector(
    getCalculatorForm,
    calculatorForm => calculatorForm[fieldId]
)

export const getNetMonthlyIncome = makeField('netMonthlyIncome');

export const getDoesHaveChildren = createSelector(
    getCalculatorForm,
    calculatorForm => calculatorForm.hasChildren === TOGGLE_STATUS.YES
)

export const getResultCardCategoryCalculations = createSelector(
    getCurrencyCodeSelector,
    getNetMonthlyIncome,
    getDoesHaveChildren,
    getBudgetRules,
    (currencyCode, netMonthlyIncome, doesHaveChildren, budgetRules) => {
        if (doesHaveChildren) {
            const { rules: applicableRulesWithChildren } = budgetRules && budgetRules.find(rule => rule.withChildren);
            return buildUpResultCardCategoryRules(currencyCode, applicableRulesWithChildren, netMonthlyIncome);
        } else {
            const { rules: applicableRulesWithoutChildren } = budgetRules && budgetRules.find(rule => !rule.withChildren);
            return buildUpResultCardCategoryRules(currencyCode, applicableRulesWithoutChildren, netMonthlyIncome);
        }
    }
)

export const getResultTableCategoryCalculations = createSelector(
    getCurrencyCodeSelector,
    getNetMonthlyIncome,
    getDoesHaveChildren,
    getBudgetRules,
    (currencyCode, netMonthlyIncome, doesHaveChildren, budgetRules) => {
        if (doesHaveChildren) {
            const { rules: applicableRulesWithChildren } = budgetRules && budgetRules.find(rule => rule.withChildren);
            return buildUpCategoryRules(currencyCode, applicableRulesWithChildren, netMonthlyIncome);
        } else {
            const { rules: applicableRulesWithoutChildren } = budgetRules && budgetRules.find(rule => !rule.withChildren);
            return buildUpCategoryRules(currencyCode, applicableRulesWithoutChildren, netMonthlyIncome);
        }
    }

)

const calculateRuleAmountBasedOnWeight = (income, weight) => {
    return parseInt(income) / 100 * weight;
}

const buildUpResultCardCategoryRules = (currencyCode, rules, netMonthlyIncome) => {
    const rulesWithCalculation = rules && rules.map(rule => ({
        name: rule.name,
        iconUrl: rule.iconUrl,
        ruleWeight: rule.ruleWeight,
        ruleCalculatedValue: formatCurrency(calculateRuleAmountBasedOnWeight(netMonthlyIncome.value, rule.ruleWeight), currencyCode)
    }));

    return rulesWithCalculation || [];
}

const buildUpCategoryRules = (currencyCode, rules, netMonthlyIncome) => {
    const rulesWithCalculation = rules && rules.map(rule => {
        const categories = rule.categories && rule.categories.map(category => {
            const ruleTotalValue = calculateRuleAmountBasedOnWeight(netMonthlyIncome.value, rule.ruleWeight);
            const percentageOfHundred = category.categoryWeight / rule.ruleWeight * 100;
            return {
                categoryName: category.categoryName,
                categoryWeighting: category.categoryWeight,
                categoryCalculatedValue: formatCurrency(calculateRuleAmountBasedOnWeight(ruleTotalValue, percentageOfHundred), currencyCode)
            }
        });

        const completeListOfCalculatedCategories = [...categories,
        {
            categoryName: 'Total',
            categoryWeighting: rule.ruleWeight,
            categoryCalculatedValue: formatCurrency(calculateRuleAmountBasedOnWeight(netMonthlyIncome.value, rule.ruleWeight), currencyCode)
        }]

        return {
            name: rule.name,
            calculatedCategories: completeListOfCalculatedCategories
        }

    });

    return rulesWithCalculation || [];
}
