import {
    FORM_VALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM,
    FORM_INVALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM,
    CURRENT_LICENSE_COPY_SET_VALUE,
    CURRENT_LICENSE_COPY_SET_VALID,
    RESUME_COPY_SET_VALUE,
    RESUME_COPY_SET_VALID,
    NATIONAL_ID_COPY_SET_VALUE,
    NATIONAL_ID_COPY_SET_VALID,
    ACADEMIC_CERTIFICATES_COPY_SET_VALUE,
    ACADEMIC_CERTIFICATES_COPY_SET_VALID,
    STATEMENT_COPY_SET_VALUE,
    KRA_PIN_COPY_SET_VALUE,
    KRA_PIN_COPY_SET_VALID,
    DEC_ONE_COPY_SET_VALUE,
    DEC_ONE_COPY_SET_VALID,
    DEC_TWO_COPY_SET_VALUE,
    DEC_TWO_COPY_SET_VALID,
    DEC_THREE_COPY_SET_VALUE,
    DEC_THREE_COPY_SET_VALID,
    DEC_FOUR_COPY_SET_VALUE,
    DEC_FOUR_COPY_SET_VALID,
    DEC_FIVE_COPY_SET_VALUE,
    DEC_FIVE_COPY_SET_VALID,
    DEC_SIX_COPY_SET_VALUE,
    DEC_SIX_COPY_SET_VALID,
    FORM_VALID_DOCTORS_PI_DECLARATION_FORM,
    FORM_INVALID_DOCTORS_PI_DECLARATION_FORM,
} from '../../actions/doctorsPi/documents';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initUploadDocuments = {
    formStatus: FORM_STATUS.INVALID,
    statementsStatus: FORM_STATUS.INVALID,
    statements: {
        decOne: { value: null, error: null, status: null },
        decTwo: { value: null, error: null, status: null },
        decThree: { value: null, error: null, status: null },
        decFour: { value: null, error: null, status: null },
        decFive: { value: null, error: null, status: null },
        decSix: { value: null, error: null, status: null },
    },
    form: {
        currentLicense: { value: null, error: null, status: null },
        resume: { value: null, error: null, status: null },
        nationalId: { value: null, error: null, status: null },
        academicCertificates: { value: null, error: null, status: null },
        kraPin: { value: null, error: null, status: null },
    },
};

export default (state = initUploadDocuments, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    const updateStatementField = (fieldId, update) => ({
        ...state,
        statements: {
            ...state.statements,
            [fieldId]: update(state.statements[fieldId]),
        },
    });

    switch (action.type) {
        case STATEMENT_COPY_SET_VALUE:
            return { ...state, statements: action.value };

        case FORM_VALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case FORM_VALID_DOCTORS_PI_DECLARATION_FORM:
            return { ...state, statementsStatus: FORM_STATUS.VALID };

        case FORM_INVALID_DOCTORS_PI_DECLARATION_FORM:
            return { ...state, statementsStatus: FORM_STATUS.INVALID };

        case CURRENT_LICENSE_COPY_SET_VALUE:
            return updateFormField('currentLicense', (field) => ({
                ...field,
                value: action.value,
            }));

        case CURRENT_LICENSE_COPY_SET_VALID:
            return updateFormField('currentLicense', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RESUME_COPY_SET_VALUE:
            return updateFormField('resume', (field) => ({
                ...field,
                value: action.value,
            }));

        case RESUME_COPY_SET_VALID:
            return updateFormField('resume', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NATIONAL_ID_COPY_SET_VALUE:
            return updateFormField('nationalId', (field) => ({
                ...field,
                value: action.value,
            }));

        case NATIONAL_ID_COPY_SET_VALID:
            return updateFormField('nationalId', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ACADEMIC_CERTIFICATES_COPY_SET_VALUE:
            return updateFormField('academicCertificates', (field) => ({
                ...field,
                value: action.value,
            }));

        case ACADEMIC_CERTIFICATES_COPY_SET_VALID:
            return updateFormField('academicCertificates', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case KRA_PIN_COPY_SET_VALUE:
            return updateFormField('kraPin', (field) => ({
                ...field,
                value: action.value,
            }));

        case KRA_PIN_COPY_SET_VALID:
            return updateFormField('kraPin', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DEC_ONE_COPY_SET_VALUE:
            console.log("hwrjwr", action)
            return updateStatementField('decOne', (field) => ({
                ...field,
                value: action.value,
            }));

        case DEC_ONE_COPY_SET_VALID:
            console.log("hwrjwr", action)
            return updateStatementField('decOne', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DEC_TWO_COPY_SET_VALUE:
            return updateStatementField('decTwo', (field) => ({
                ...field,
                value: action.value,
            }));

        case DEC_TWO_COPY_SET_VALID:
            return updateStatementField('decTwo', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DEC_THREE_COPY_SET_VALUE:
            return updateStatementField('decThree', (field) => ({
                ...field,
                value: action.value,
            }));

        case DEC_THREE_COPY_SET_VALID:
            return updateStatementField('decThree', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DEC_FOUR_COPY_SET_VALUE:
            return updateStatementField('decFour', (field) => ({
                ...field,
                value: action.value,
            }));

        case DEC_FOUR_COPY_SET_VALID:
            return updateStatementField('decFour', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DEC_FIVE_COPY_SET_VALUE:
            return updateStatementField('decFive', (field) => ({
                ...field,
                value: action.value,
            }));

        case DEC_FIVE_COPY_SET_VALID:
            return updateStatementField('decFive', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DEC_SIX_COPY_SET_VALUE:
            return updateStatementField('decSix', (field) => ({
                ...field,
                value: action.value,
            }));

        case DEC_SIX_COPY_SET_VALID:
            return updateStatementField('decSix', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
