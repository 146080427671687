export const CALL_ME_BACK_PUBLIC_WEB = 'publicWeb/callMeBack/';
export const CALL_ME_BACK_SET_VALUE = CALL_ME_BACK_PUBLIC_WEB + 'setValue/';
export const CALL_ME_BACK_SET_VALID = CALL_ME_BACK_PUBLIC_WEB + 'setValid/';

export const FORM_VALID_TRANSITION_CALL_ME_BACK = `${CALL_ME_BACK_PUBLIC_WEB}/transition/formValid`;
export const FORM_INVALID_TRANSITION_CALL_ME_BACK = `${CALL_ME_BACK_PUBLIC_WEB}/transition/formInvalid`;
export const FORM_SUCCESS_TRANSITION_CALL_ME_BACK = `${CALL_ME_BACK_PUBLIC_WEB}/transition/formSuccess`;
export const FORM_SUBMITTING_TRANSITION_CALL_ME_BACK = `${CALL_ME_BACK_PUBLIC_WEB}/transition/formSubmitting`;
export const FORM_ERROR_TRANSITION_CALL_ME_BACK = `${CALL_ME_BACK_PUBLIC_WEB}/transition/formError`;

export const transitionCallMeBack = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CALL_ME_BACK }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CALL_ME_BACK }),
    success: () => ({ type: FORM_SUCCESS_TRANSITION_CALL_ME_BACK }),
    submitting: () => ({ type: FORM_SUBMITTING_TRANSITION_CALL_ME_BACK }),
    error: () => ({ type: FORM_ERROR_TRANSITION_CALL_ME_BACK }),
};

export const CALL_ME_BACK_LEAD_SYSTEM_VALUE = CALL_ME_BACK_SET_VALUE + 'leadSystem';
export const CALL_ME_BACK_OBJECT_DETAILS = CALL_ME_BACK_SET_VALUE + 'objDetails';
export const CALL_ME_BACK_NAME_VALUE = CALL_ME_BACK_SET_VALUE + 'name';
export const CALL_ME_BACK_NAME_VALID = CALL_ME_BACK_SET_VALID + 'name';
export const CALL_ME_BACK_SURNAME_VALUE = CALL_ME_BACK_SET_VALUE + 'surname';
export const CALL_ME_BACK_SURNAME_VALID = CALL_ME_BACK_SET_VALID + 'surname';
export const CALL_ME_BACK_PHONE_NUMBER_VALUE = CALL_ME_BACK_SET_VALUE + 'phoneNumber';
export const CALL_ME_BACK_PHONE_NUMBER_VALID = CALL_ME_BACK_SET_VALID + 'phoneNumber';
export const CALL_ME_BACK_PRODUCT_NAME_VALUE = CALL_ME_BACK_SET_VALUE + 'productName';
export const CALL_ME_BACK_EMAIL_VALUE = CALL_ME_BACK_SET_VALUE + 'email';
export const CALL_ME_BACK_EMAIL_VALID = CALL_ME_BACK_SET_VALID + 'email';
export const CALL_ME_BACK_PRIVACY_POLICY_URL_VALUE = CALL_ME_BACK_SET_VALUE + 'privacyPolicyURL';
export const CALL_ME_BACK_POSTAL_CODE_VALUE = CALL_ME_BACK_SET_VALUE + 'postalCode';
export const CALL_ME_BACK_POSTAL_CODE_VALID = CALL_ME_BACK_SET_VALID + 'postalCode';
export const CALL_ME_BACK_ADDITIONAL_NUMBER_VALUE = CALL_ME_BACK_SET_VALUE + 'additionalNumber';
export const CALL_ME_BACK_ADDITIONAL_NUMBER_VALID = CALL_ME_BACK_SET_VALID + 'additionalNumber';
export const CALL_ME_BACK_CITY_VALUE = CALL_ME_BACK_SET_VALUE + 'city';
export const CALL_ME_BACK_CITY_VALID = CALL_ME_BACK_SET_VALID + 'city';
export const CALL_ME_BACK_LOCATION_COMMENTS_VALUE = CALL_ME_BACK_SET_VALUE + 'locationComments';
export const CALL_ME_BACK_LOCATION_COMMENTS_VALID = CALL_ME_BACK_SET_VALID + 'locationComments';
export const CALL_ME_BACK_TO_EMAIL_VALUE = CALL_ME_BACK_SET_VALUE + 'toEmail';
export const CALL_ME_BACK_EMAIL_CAMPAIGN_CODE_VALUE = CALL_ME_BACK_SET_VALUE + 'emailCampaignCode';
export const CALL_ME_BACK_ACTIVE_ENTRY_VALUE = CALL_ME_BACK_SET_VALUE + 'activeEntry';
export const CALL_ME_BACK_ADVISER_DATA_VALUE = CALL_ME_BACK_SET_VALUE + 'adviserData';
export const CALL_ME_BACK_SIDEBAR_OPEN_VALUE = CALL_ME_BACK_SET_VALUE + 'sidebarOpen';
export const CALL_ME_BACK_ENVIRONMENT_NAME_VALUE = CALL_ME_BACK_SET_VALUE + 'environmentName';
export const CALL_ME_BACK_LOCATION_VALUE = CALL_ME_BACK_SET_VALUE + 'location';
export const CALL_ME_BACK_LOCATION_VALID = CALL_ME_BACK_SET_VALID + 'location';
export const CALL_ME_BACK_STATE_VALUE = CALL_ME_BACK_SET_VALUE + 'state';
export const CALL_ME_BACK_STATE_VALID = CALL_ME_BACK_SET_VALID + 'state';
export const CALL_ME_BACK_NG_HOUSE_TYPE_VALUE = CALL_ME_BACK_SET_VALUE + 'typeOfHouseValue';
export const CALL_ME_BACK_NG_HOUSE_TYPE_VALID = CALL_ME_BACK_SET_VALUE + 'typeOfHouseValue';
export const CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALUE = CALL_ME_BACK_SET_VALUE + 'insureProductType';
export const CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALID = CALL_ME_BACK_SET_VALUE + 'insureProductType';
export const CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALUE = CALL_ME_BACK_SET_VALUE + 'insureProductItem';
export const CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALID = CALL_ME_BACK_SET_VALUE + 'insureProductItem';
export const CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALUE = CALL_ME_BACK_SET_VALUE + 'propertyLocation';
export const CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALID = CALL_ME_BACK_SET_VALUE + 'propertyLocation';
export const CALL_ME_BACK_TYPE_OF_ORGANISATION_VALUE = CALL_ME_BACK_SET_VALUE + 'typeOfOrganisation';
export const CALL_ME_BACK_TYPE_OF_ORGANISATION_VALID = CALL_ME_BACK_SET_VALUE + 'typeOfOrganisation';
export const CALL_ME_BACK_PREPOPULATED_FORM_VALUE = CALL_ME_BACK_SET_VALUE + 'isPrepopulatedForm';


export const CALL_ME_BACK_RESET_STORE = CALL_ME_BACK_PUBLIC_WEB + 'resetStore';
export const resetStore = value => ({type: CALL_ME_BACK_RESET_STORE, value});

export const CALL_ME_BACK_SEND_ACTION = CALL_ME_BACK_PUBLIC_WEB + 'send';
export const sendCallMeBackAction = (gtmContext) => ({gtmContext, type: CALL_ME_BACK_SEND_ACTION});
export const CALL_ME_BACK_CLICKED = CALL_ME_BACK_PUBLIC_WEB + 'clicked';
export const callMeBackButtonClicked = (clickedData) => ({type: CALL_ME_BACK_CLICKED, clickedData});

export const setLeadSystem = value => ({ type: CALL_ME_BACK_LEAD_SYSTEM_VALUE, value });
export const setCmbDetailsObj = value => ({type: CALL_ME_BACK_OBJECT_DETAILS, value});
export const setNameValue = value => ({type: CALL_ME_BACK_NAME_VALUE, value});
export const setNameValid = error => ({type: CALL_ME_BACK_NAME_VALID, error});
export const setSurnameValue = value => ({type: CALL_ME_BACK_SURNAME_VALUE, value});
export const setSurnameValid = error => ({type: CALL_ME_BACK_SURNAME_VALID, error});
export const setPhoneNumberValue = (value, countryCode) => ({type: CALL_ME_BACK_PHONE_NUMBER_VALUE, value, countryCode});
export const setPhoneNumberValid = (error, countryCode) => ({type: CALL_ME_BACK_PHONE_NUMBER_VALID, error, countryCode});
export const setProductNameValue = value => ({type: CALL_ME_BACK_PRODUCT_NAME_VALUE, value});
export const setEmailValue = value => ({type: CALL_ME_BACK_EMAIL_VALUE, value});
export const setEmailValid = error => ({type: CALL_ME_BACK_EMAIL_VALID, error});
export const setPrivacyPolicyURLValue = value => ({type: CALL_ME_BACK_PRIVACY_POLICY_URL_VALUE, value});
export const setPostalCodeValue = value => ({type: CALL_ME_BACK_POSTAL_CODE_VALUE, value});
export const setPostalCodeValid = error => ({type: CALL_ME_BACK_POSTAL_CODE_VALID, error});
export const setAdditionalNumberValue = (value, countryCode) => ({type: CALL_ME_BACK_ADDITIONAL_NUMBER_VALUE, value, countryCode});
export const setAdditionalNumberValid = (error, countryCode) => ({type: CALL_ME_BACK_ADDITIONAL_NUMBER_VALID, error, countryCode});
export const setCityValue = value => ({type: CALL_ME_BACK_CITY_VALUE, value});
export const setCityValid = error => ({type: CALL_ME_BACK_CITY_VALID, error});
export const setLocationCommentsValue = value => ({type: CALL_ME_BACK_LOCATION_COMMENTS_VALUE, value});
export const setLocationCommentsValueValid = error => ({type: CALL_ME_BACK_LOCATION_COMMENTS_VALID, error});
export const setToEmailValue = value => ({type: CALL_ME_BACK_TO_EMAIL_VALUE, value});
export const setEmailCampaignCode = value => ({type: CALL_ME_BACK_EMAIL_CAMPAIGN_CODE_VALUE, value});
export const setActiveEntry = value => ({type: CALL_ME_BACK_ACTIVE_ENTRY_VALUE, value});
export const setAdviserData = value => ({type: CALL_ME_BACK_ADVISER_DATA_VALUE, value});
export const setSideBarOpen = value => ({type: CALL_ME_BACK_SIDEBAR_OPEN_VALUE, value});
export const setEnvironmentName = value => ({type: CALL_ME_BACK_ENVIRONMENT_NAME_VALUE, value});
export const setLocationValue = value => ({type: CALL_ME_BACK_LOCATION_VALUE, value});
export const setLocationValid = error => ({type: CALL_ME_BACK_LOCATION_VALID, error});
export const setStateValue = value => ({type: CALL_ME_BACK_STATE_VALUE, value});
export const setStateValid = error => ({type: CALL_ME_BACK_STATE_VALID, error});
export const setTypeOfHouseValue = value => ({type: CALL_ME_BACK_NG_HOUSE_TYPE_VALUE, value});
export const setTypeOfHouseValid = error => ({type: CALL_ME_BACK_NG_HOUSE_TYPE_VALID, error});
export const setInsureProductTypeValue = value => ({type: CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALUE, value});
export const setInsureProductTypeValid = error => ({type: CALL_ME_BACK_INSURE_PRODUCT_TYPE_VALID, error});
export const setInsureProductItemValue = value => ({type: CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALUE, value});
export const setInsureProductItemValid = error => ({type: CALL_ME_BACK_INSURE_PRODUCT_ITEM_VALID, error});
export const setPropertyLocationValue = value => ({type: CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALUE, value});
export const setPropertyLocationValid = error => ({type: CALL_ME_BACK_INSURE_PROPERTY_LOCATION_VALID, error});
export const setTypeOfOrganisationValue = value => ({type: CALL_ME_BACK_TYPE_OF_ORGANISATION_VALUE, value});
export const setTypeOfOrganisationValid = error => ({type: CALL_ME_BACK_TYPE_OF_ORGANISATION_VALID, error});
export const setIsPrepoluatedForm = value => ({type: CALL_ME_BACK_PREPOPULATED_FORM_VALUE, value});
