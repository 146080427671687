import {
    transitionAfyaImaraJuniorTool,
} from "../../../actions/roaQuoteTools/afyaImaraJuniorQuoteTool";
import {
    getAfyaImaraJuniorQuoteToolForm,
    getSelectedForm
} from "../../../selectors/roaQuoteTools/afyaImaraJuniorQuoteTool";
import {FIELD_STATUS} from "../../../reducers/status";

export const afyaImaraJuniorQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const afyaImaraJuniorQuoteToolForm = getAfyaImaraJuniorQuoteToolForm(store.getState());
    const selectedForm = getSelectedForm(store.getState());
    const afyaImaraJuniorQuoteToolValid = validateAfyaImaraJuniorQuoteToolFormDetails(afyaImaraJuniorQuoteToolForm, selectedForm);
    const formAction = afyaImaraJuniorQuoteToolValid
        ? transitionAfyaImaraJuniorTool.formValid()
        : transitionAfyaImaraJuniorTool.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validateAfyaImaraJuniorQuoteToolFormDetails = (formFields, selectedForm) => {
    const { noOfChildren,medicalProvidersScope, inpatientCoverLimit, outpatientCoverLimit} = formFields
    if (selectedForm === 'form-one') {
        return noOfChildren && noOfChildren.status === FIELD_STATUS.VALID && medicalProvidersScope &&
         medicalProvidersScope.status === FIELD_STATUS.VALID  &&
         inpatientCoverLimit && inpatientCoverLimit.status === FIELD_STATUS.VALID &&
         outpatientCoverLimit && outpatientCoverLimit.status === FIELD_STATUS.VALID;
    }
    return true;
};
