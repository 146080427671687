import { transitionShareQuoteForm } from '../../../actions/personalPensionPlan/quote';
import { getQuoteForm } from '../../../selectors/personalPensionPlan/quote';
import { FIELD_STATUS } from '../../../reducers/status';

export const shareQuoteFormRules = (store, next, action) => {
    const result = next(action);
    const shareQuoteForm = getQuoteForm(store.getState());
    const quoteFormValid = validateShareQuoteFormDetails(shareQuoteForm);
    const formAction = quoteFormValid ? transitionShareQuoteForm.formValid() : transitionShareQuoteForm.formInvalid();
    store.dispatch(formAction);

    return result;
};

const validateShareQuoteFormDetails = (formFields) => {
    return formFields.shareEmail && formFields.shareEmail.status === FIELD_STATUS.VALID;
};
