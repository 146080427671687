import { transitionPhysicalImpairmentCoverQuoteTool } from '../../../actions/roaQuoteTools/physicalImpairmentCoverQuoteTool';
import { getPhysicalImpairmentCoverQuoteToolForm } from '../../../selectors/roaQuoteTools/physicalImpairmentCoverQuoteTool';

import { FIELD_STATUS } from '../../../reducers/status';

export const physicalImpairmentCoverQuoteToolFormRules = (
    store,
    next,
    action,
) => {
    const result = next(action);
    const physicalImpairmentCoverQuoteToolForm = getPhysicalImpairmentCoverQuoteToolForm(
        store.getState(),
    );
    const physicalImpairmentCoverQuoteToolValid = validatePhysicalImpairmentCoverQuoteToolFormDetails(
        physicalImpairmentCoverQuoteToolForm,
    );
    const formAction = physicalImpairmentCoverQuoteToolValid
        ? transitionPhysicalImpairmentCoverQuoteTool.formValid()
        : transitionPhysicalImpairmentCoverQuoteTool.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePhysicalImpairmentCoverQuoteToolFormDetails = formFields => {
    return (
        formFields.candidateDOB &&
        formFields.candidateDOB.status === FIELD_STATUS.VALID &&
        formFields.coverAmount &&
        formFields.coverAmount.status === FIELD_STATUS.VALID &&
        formFields.numberOfYearsCovered &&
        formFields.numberOfYearsCovered.status === FIELD_STATUS.VALID
    );
};
