import debounce from "lodash.debounce";
import {fireGtmEvent} from "./gtmEvents";
import {FIELD_STATUS} from "../../reducers/status";

let lastDebouncedKey = undefined;
let lastDebouncedFunc = undefined;
const debounceTime = 2000;
const ACTION_VERB = {
    VALID: 'setValid',
    INVALID: 'setInvalid',
};

const fieldTracking = eventName => (actionType, event) => {
    const [app, page, _, formField] = actionType.split('/');    
    fireGtmEvent(eventName, {
        app,
        page,
        formField,
        ...event,
    }); 
};

// Debounce tracking functions.
const newDebouncedValidTracking = () => debounce(fieldTracking(ACTION_VERB.VALID), debounceTime);
const newDebouncedInvalidTracking = () => debounce(fieldTracking(ACTION_VERB.INVALID), debounceTime);

const fireTracking = isValidTracking => (actionType, ...args) => {
    // is this a 'valid' or 'invalid' type tracking event
    const thisKey = isValidTracking ? ACTION_VERB.VALID : ACTION_VERB.INVALID;
    // is the opposite type of tracking event 'valid' or 'invalid'
    const thatKey = isValidTracking ? ACTION_VERB.INVALID : ACTION_VERB.VALID;

    // depending on this tracking event type, call debounced function and cache the debounceKey & debounceAction
    const callNewDebounce = () => {
        const debounced = isValidTracking
            ? newDebouncedValidTracking()
            : newDebouncedInvalidTracking();

        debounced(actionType, ...args);
        lastDebouncedKey = actionType + thisKey;
        lastDebouncedFunc = debounced;
    };

    switch (lastDebouncedKey) {
        // lastDebounceKey is opposite of this tracking event -- cancel that event and call new debounce function
        case actionType + thatKey:
            lastDebouncedFunc.cancel();
            callNewDebounce();
            break;

        // lastDebounceKey is the same as this tracking event -- just call debounced function
        case  actionType + thisKey:
            lastDebouncedFunc(actionType, ...args);
            break;

        // new debounced function, unrelated to previous function -- flush if necessary and call new debounce function
        default:
            lastDebouncedFunc && lastDebouncedFunc.flush();
            callNewDebounce();
    }
};

export const simpleFieldTracking = ({
    action,
    field: {status, error, value},
    referrerId,
    sendValue = true,
    sendError = true,
}) => {
    const errorStatus = status === FIELD_STATUS.VALID;
    const event = {};

    if (sendValue && value)
        event.value = value;
    if (!sendValue)
        event.value = 'confidential';
    if (sendError && error)
        event.error = error;
    if (referrerId)
        // refererId is a guid value that we use in ARC on a per client basis
        event.referrerId = referrerId;

    fireTracking(errorStatus)(action.type, event);
};
