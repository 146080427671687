import { validationIsEqual } from '../status';
import {
    validateEmail,
    validateName,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateNumericString,
} from '@om/validation';

import { getIdNumberValue } from '@om/redux/selectors/ugMotorInsurance/personalDetails';
import {
    RELATIONSHIP_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    POSTAL_ADDRESS_SET_VALUE,
    setRelationshipValid,
    setIdNumberValid,
    setPostalAddressValid,
    setFirstNameValid,
    setLastNameValid,
    setEmailValid,
} from '../../../actions/ugMotorInsurance/nextOfKin';
import { validateUgandanId } from '@om/validation/src/validators/ugandan-id-validator';

let prevValidationAction = null;
export const nextOfKinValidation = (store, next, action) => {
    const idNumber = getIdNumberValue(store.getState());

    const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

    const validationAction = (() => {
        let error;
        switch (action.type) {
            case RELATIONSHIP_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Relationship',
                    min: 2,
                });

                return setRelationshipValid(error);

            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });

                return setFirstNameValid(error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });

                const nextOfKinFirstName = getNextOfKinFirstNameValue(store.getState());

                error = checkIfPersonalNamesUsedInNextOfKin(nextOfKinFirstName.value, action.value);

                return setLastNameValid(error);

            case ID_NUMBER_SET_VALUE:
                if (gatsbyStack === 'ug') {
                    error = validateUgandanId(action.value);
                } else {
                    error = validateNumericString(action.value, { min: 7 });
                }
                if (idNumber.value === action.value) {
                    error = 'You cannot use your own ID number';
                }
                return setIdNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case POSTAL_ADDRESS_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value);
                return setPostalAddressValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
