import { combineReducers } from 'redux';
import { atRetirement } from './atRetirement';
import { raOptimal } from './raOptimal';
import { covidSelfAssessment } from './covidSelfAssessment';
import { incomeTaxCalculator } from './incomeTaxCalculator';
import { easiplusFuneralPlan } from './easiplusFuneralPlan';
import { publicWeb } from './publicWeb';
import { pastPerformanceCalculator } from './pastPerformanceCalculator/pastPerformanceCalculator';
import { personalLoansCalculator } from './personalLoansCalculator';
import { savingsCalculators } from './savingsCalculator';
import { customerVerificationService } from './customerVerificationService';
import { fundCentre } from './fundCentre';
import { priceTable } from './priceTable';
import { taxFreeSavingsAccount } from './taxFreeSavingsAccount';
import { roaQuoteTools } from './roaQuoteTools';
import { retirementCalculator } from './retirementCalculator';
import { customerComplaint } from './customerComplaint';
import { budgetBenchmarkCalculator } from './budgetBenchmarkCalculator';
import { educationCalculator } from './educationCalculator';
import { roaCarInsurance } from './roaCarInsurance';
import { debtRepaymentCalculator } from './debtRepaymentCalculator';
import { roaTravelInsurance } from './roaTravelInsurance';
import { investmentCalculators } from './investmentCalculator';
import { fundDeclarations } from './fundDeclarations';
import { customerSatisfactionSurvey } from './customerSatisfactionSurvey';
import { ltd } from './ltd';
import makeAClaim from './makeAClaim';
import { financialEducation } from './financialEducation';
import { quizAssessment } from './quizAssessment';
import { digitalOnboarding } from './digitalOnboarding';
import { ugMotorInsurance } from './ugMotorInsurance';
import { personalAccident } from './personalAccident';
import { domesticInsurance } from './domesticInsurance';
import { biasharaFlexi } from './biasharaFlexi';
import { travel } from './travel';
import { digiLifeLoanProtection } from './digiLifeLoanProtection';
import { doctorsPi } from './doctorsPi';
import { motorPrivateRW } from './motorPrivateRW';
import { personalPensionPlan } from './personalPensionPlan';
import { allsureInsurance } from './allsureInsurance';
import { savingsPotCalculator } from './savingsPotCalculator';
import { digitalSavings } from './digitalSavings';
import { healthInsuranceRwanda } from "./healthInsuranceRW";


export const rootReducer = combineReducers({
    atRetirement,
    raOptimal,
    covidSelfAssessment,
    incomeTaxCalculator,
    easiplusFuneralPlan,
    publicWeb,
    fundCentre,
    pastPerformanceCalculator,
    personalLoansCalculator,
    savingsCalculators,
    customerVerificationService,
    roaQuoteTools,
    retirementCalculator,
    taxFreeSavingsAccount,
    priceTable,
    customerComplaint,
    budgetBenchmarkCalculator,
    educationCalculator,
    roaCarInsurance,
    debtRepaymentCalculator,
    roaTravelInsurance,
    investmentCalculators,
    fundDeclarations,
    customerSatisfactionSurvey,
    ltd,
    makeAClaim,
    financialEducation,
    quizAssessment,
    digitalOnboarding,
    ugMotorInsurance,
    personalAccident,
    domesticInsurance,
    biasharaFlexi,
    travel,
    digiLifeLoanProtection,
    doctorsPi,
    motorPrivateRW,
    personalPensionPlan,
    allsureInsurance,
    savingsPotCalculator,
    digitalSavings,
    healthInsuranceRwanda,

});
