import axios from 'axios';

import {
    apiTransitionBuildWealthCalculator,
    CALCULATE_BUILD_WEALTH
} from '../../../actions/investmentCalculator/buildWealth'

import {
    setViewInvestmentResult,
} from '../../../actions/investmentCalculator/investmentCalculator'

import {
    getIsApiPending
} from '../../../selectors/investmentCalculator/buildWealthCalculator'
import {
    getDataWithSplit,
} from '../../../selectors/investmentCalculator/buildWealthSplit';


export const buildWealthCalculateService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    if(action.type === CALCULATE_BUILD_WEALTH) {
        (async () => {
            if(!getIsApiPending(state)) {
                store.dispatch(apiTransitionBuildWealthCalculator.pending());
                try {
                    const response = await calculate(state);
                    store.dispatch(setViewInvestmentResult(response?.data));
                    store.dispatch(apiTransitionBuildWealthCalculator.success()
                    );
                } catch (e) {
                    store.dispatch(setViewInvestmentResult(null))
                    store.dispatch(apiTransitionBuildWealthCalculator.failure())
                }
            }
        })();
    }
    return result
}

export const calculate = async(state) => {
    const data = getDataWithSplit(state)

    try {
        const url = '/om-api/investment-calculator/build-wealth'
        return  await axios.post(url, data)
    } catch (error) {
        return null
    }
}
