import { createSelector } from 'reselect';
import { getRoaTravelInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getAboutYou = createSelector(
    getRoaTravelInsuranceState,
    state => state.aboutYou,
);

export const getAboutYouForm = createSelector(
    getAboutYou,
    aboutYou => aboutYou.form,
);

export const getDropOffDetails = createSelector(
    getAboutYou,
    aboutYou => aboutYou.dropOffDetails,
);

export const getAboutYouFormValid = createSelector(
    getAboutYou,
    aboutYou => aboutYou.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getAboutYou,
    aboutYou => aboutYou.apiCallStatus === API_CALL_STATUS.PENDING,
);

const makeGetFieldForm = fieldId =>
    createSelector(getAboutYouForm, form => form[fieldId]);

export const getDOBValue = makeGetFieldForm('DOB');
export const getTitleValue = makeGetFieldForm('title');
export const getFirstNameValue = makeGetFieldForm('firstName');
export const getSurnameValue = makeGetFieldForm('surname');
export const getPhoneNumberValue = makeGetFieldForm('phoneNumber');
export const getEmailValue = makeGetFieldForm('email');
