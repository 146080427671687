import {
    SCROLL_TO_CLASS,
    SCROLL_TO_ID,
    RESET_SCROLL
} from"../../actions/raOptimal/scrolling";

export const initScrolling = {
    scrollToClass: null,
    scrollToId: null
};

export default (state = initScrolling, action) => {
    switch (action.type){
        case SCROLL_TO_CLASS:
            return { scrollToClass: action.value };
            
        case SCROLL_TO_ID:
            return { scrollToId: action.value };

        case RESET_SCROLL:
            return { ...initScrolling };

        default:
            return state;
    }
}