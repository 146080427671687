import {initLookupData} from './lookupData';
import {initRouting} from './routing';
import {initPersonalDetails} from './personalDetails';
import {initApplicationNow} from './applicationNow';
import {initEmploymentDetails} from './employmentDetails';
import { initAffordabilityDetails } from './affordabilityDetails';
import { initQuoteDetails } from './quoteDetails';
import { initOTP } from './otp';
import { initAddressDetails } from './addressDetails';
import { initPersistedAt } from './persistedAt';

import { initDocumentStore } from './documentStore';

export default () => {
    return {
        lookupData: initLookupData,
        routing: initRouting,
        personalDetails: initPersonalDetails,
        applicationNow: initApplicationNow,
        employmentDetails: initEmploymentDetails,
        affordabilityDetails: initAffordabilityDetails,
        quoteDetails: initQuoteDetails,
        otp: initOTP,
        addressDetails: initAddressDetails,
        persistedAt: initPersistedAt,
        documentStore: initDocumentStore
    };
}