import axios from 'axios';
import {
    submitTravelInsuranceAccountNumberSuccess,
    submitTravelInsuranceAccountNumberFailure,
    apiTransitionGenerateAccountNumber,
} from '../../../actions/roaTravelInsurance/addTraveller';

import {
    getIsGenerateAccountNumberApiPending,
} from '../../../selectors/roaTravelInsurance/payments';


export const generateAccountNumberService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (!getIsGenerateAccountNumberApiPending(state)) {
            store.dispatch(apiTransitionGenerateAccountNumber.pending());
            try {
                const response = await handleGenerateAccountNumber(state);
                const accountNumberResponse = response?.data?.data?.generatePaymentAccount;
                store.dispatch(submitTravelInsuranceAccountNumberSuccess(accountNumberResponse)); 
                store.dispatch(apiTransitionGenerateAccountNumber.success());
            } catch (e) {
                store.dispatch(submitTravelInsuranceAccountNumberFailure(e));
                store.dispatch(apiTransitionGenerateAccountNumber.failure());
            }
        }
    })();
    return result;
};

const handleGenerateAccountNumber = async () => {
    try {
        const url = '/om-api/roa-travel-insurance/account-number';
        const config = {
            headers: {
                'Content-Type': 'application/json',
                 Accept: 'application/json',
            },
        };
        const result = await axios.post(url, {}, config);
        return result;
    } catch (error) {
        throw error;
    }
};