import { createSelector } from 'reselect';
import { getRoaTravelInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getTravelAgent = createSelector(
    getRoaTravelInsuranceState,
    state => state.travelAgent,
);

const makeGetTravelAgentField = fieldName =>
    createSelector(getTravelAgent, travelAgent => travelAgent[fieldName]);

// get display modal
export const getDisplayForm = makeGetTravelAgentField('displayModal');

// get form
export const getTravelAgentForm = makeGetTravelAgentField('form');

export const getTravelAgentFormValid = createSelector(
    getTravelAgent,
    travelAgent => travelAgent.formStatus === FORM_STATUS.VALID,
);

// get form fields
const makeGetFieldForm = fieldId =>
    createSelector(getTravelAgentForm, form => form[fieldId]);

export const getNameValue = makeGetFieldForm('name');
export const getAgentPhoneNumberValue = makeGetFieldForm('agentPhoneNumber');
