import {createSelector} from "reselect";
import {getRoaCarInsuranceState} from "./index";
import {FORM_STATUS, API_CALL_STATUS} from "../../reducers/status";


export const getCarDetails = createSelector(getRoaCarInsuranceState, rcia => rcia.details);

export const getCarDetailsForm = createSelector(
    getCarDetails,
    carDetails => carDetails.form
);

export const getApiCallStatus = createSelector(
    getCarDetails,
    carDetails => carDetails.apiCallStatus
);

export const getIprsResponse = createSelector(
    getCarDetails,
    carDetails => carDetails.iprsResponse
)

const makeGetFieldForm = fieldId => createSelector(
    getCarDetailsForm,
    form => form[fieldId]
);

export const getCarDetailsFormValid = createSelector(
    getCarDetailsForm,
    carDetails => carDetails.formStatus === FORM_STATUS.VALID
);

export const getComprehensiveCoverExtraBenefits = createSelector(
    getCarDetails,
    details => details.comprehensiveCoverExtraBenefits
);

export const getIsApiPending = createSelector(
     getCarDetails,
     details => details.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getIsIprsApiPending = createSelector(
     getCarDetails,
     details => details.IprsApiCallStatus === API_CALL_STATUS.PENDING,
);

export const getIsKraApiPending = createSelector(
     getCarDetails,
     details => details.kraApiCallStatus === API_CALL_STATUS.PENDING,
);

export const getIsCoverStartDateApiPending = createSelector(
    getCarDetails,
    details => details.coverStartDateApiCallStatus === API_CALL_STATUS.PENDING
);

export const getIsCarDetailsFormValid = createSelector(
    getCarDetails,
    details => details.formStatus === FORM_STATUS.VALID
);

export const getShowError =createSelector(
    getCarDetails,
    details => details.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getComprehensiveCover = createSelector(
    getCarDetails,
    details => details.comprehensiveCoverBenefits
);
export const getIprsApiCallStatus = createSelector(
    getCarDetails,
    carDetails => carDetails.IprsApiCallStatus
);

export const getKraApiCallStatus = createSelector(
    getCarDetails,
    carDetails => carDetails.kraApiCallStatus
);

export const getCoverstartDateApiCallStatus = createSelector(
    getCarDetails,
    carDetails => carDetails.coverStartDateApiCallStatus
);
export const getComprehensiveCoverBenefitsValues = createSelector(
    getCarDetails,
    details => details.comprehensiveBenefitValues
);

export const getComprehensiveCoverEditMode = createSelector(
    getCarDetails,
    details => details.comprehensiveBenefitEditMode
);
export const getAlterBenefitIdValue = createSelector(
    getCarDetails,
    details => details.alterBenefitId
);
export const getLogBookData = createSelector(
    getCarDetails,
    details => details.logBookData
);
export const getExtraBenefitIdValue = createSelector(
    getCarDetails,
    details => details.extraBenefitId
)
export const getExtraPremiumValue = createSelector(
    getCarDetails,
    details => details.extraPremium
);
export const getComprehensiveCoverTotalPremium = createSelector(
    getCarDetails,
    details => details.comprehensiveCoverTotal
);

export const getLogBookFile = makeGetFieldForm('logBookFile');
export const getLogBookErrorMessage = makeGetFieldForm('logBookErrorMessage');
export const getVehicleRegNo = makeGetFieldForm('vehicleRegNo');
export const getVehicleRegNoErrorMessage = makeGetFieldForm('vehicleRegNoErrorMessage');
export const getKraPinNumber = makeGetFieldForm('kraPinNumber');
export const getKraPinNumberErrorMessage = makeGetFieldForm('kraPinNumberErrorMessage');
export const getCoverStartDate = makeGetFieldForm('coverStartDate');
export const getCoverStartDateErrorMessage = makeGetFieldForm('coverStartDateErrorMessage');
export const getId = makeGetFieldForm('id');
export const getIdErrorMessage = makeGetFieldForm('idErrorMessage');
export const getExtraBenefits = makeGetFieldForm('extraBenefits');
export const getlogbookErrorMessage = makeGetFieldForm('logbookDataErrorMessage');
