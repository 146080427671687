import { LOGIN_FAILURE, LOGIN_SUCCESS } from '../../actions/doctorsPi/auth';
import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

export const initAuth = {
    loginData: null,
};

export default (state = initAuth, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, loginData: action.data };

        case LOGIN_FAILURE:
            return { ...state, loginData: null };

        default:
            return state;
    }
}
