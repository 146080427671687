import {
  COUGH_SET_VALUE,
  SHORT_BREATH_SET_VALUE,
  HIGH_FEVER_SET_VALUE,
  SORE_THROAT_SET_VALUE,
} from "../../../actions/covidSelfAssessment/clinicalCriteria"
import {
  getCough,
  getShortBreath,
  getHighFever,
  getSoreThroat,
} from "../../../selectors/covidSelfAssessment/clinicalCriteria"
import {simpleFieldTracking} from "../formTracking";

export const clinicalCriteriaFieldTracking = (store, next, action) => {
  const result = next(action);
  const state = store.getState();
  const field = (() => {
      switch (action.type) {
          case COUGH_SET_VALUE: return getCough(state);
          case SHORT_BREATH_SET_VALUE: return getShortBreath(state);
          case HIGH_FEVER_SET_VALUE: return getHighFever(state);
          case SORE_THROAT_SET_VALUE: return getSoreThroat(state);
          default: return null;
      }
  })();

  if (field) {
      simpleFieldTracking({action, field});
  }

  return result;
};
