// Transitions
export const FORM_VALID_TRANSITION_BIOGRAPHIC_INFO = "covidSelfAssessment/biographicInfo/transition/formValid";
export const FORM_INVALID_TRANSITION_BIOGRAPHIC_INFO = "covidSelfAssessment/biographicInfo/transition/formInvalid";

export const transitionBiographicInfo = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_BIOGRAPHIC_INFO }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_BIOGRAPHIC_INFO }),
};

// Events
export const BIOGRAPHIC_INFO_RESET = "covidSelfAssessment/biographicInfo/resetForm/";
export const BIOGRAPHIC_INFO_SET_VALUE = "covidSelfAssessment/biographicInfo/setValue/";
export const BIOGRAPHIC_INFO_SET_VALID = "covidSelfAssessment/biographicInfo/setValid/";

export const AGE_SET_VALUE = BIOGRAPHIC_INFO_SET_VALUE + "age";
export const AGE_SET_VALID = BIOGRAPHIC_INFO_SET_VALID + "age";
export const GENDER_SET_VALUE = BIOGRAPHIC_INFO_SET_VALUE + "gender";
export const GENDER_SET_VALID = BIOGRAPHIC_INFO_SET_VALID + "gender";
export const POSTAL_CODE_SET_VALUE = BIOGRAPHIC_INFO_SET_VALUE + "postalCode";
export const POSTAL_CODE_SET_VALID = BIOGRAPHIC_INFO_SET_VALID + "postalCode";

export const resetBiographicInfoForm = () => ({ type: BIOGRAPHIC_INFO_RESET });
export const setAgeValue = (value) => ({ type: AGE_SET_VALUE, value });
export const setAgeValid = (error) => ({ type: AGE_SET_VALID, error });
export const setGenderValue = (value) => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = (error) => ({ type: GENDER_SET_VALID, error });
export const setPostalCodeValue = (value) => ({ type: POSTAL_CODE_SET_VALUE, value });
export const setPostalCodeValid = (error) => ({ type: POSTAL_CODE_SET_VALID, error });
