import { SET_VALUE_ROUTING } from '../../../actions/roaTravelInsurance/routing';
import { routingRules } from './routing';

import {
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE,
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID,
} from '../../../actions/roaTravelInsurance/aboutYou';
import { aboutYouFormRules } from './aboutYou';

import {
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE,
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALID,
} from '../../../actions/roaTravelInsurance/travelDetails';
import { travelDetailsFormRules } from './travelDetails';

import {
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE,
    TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/roaTravelInsurance/travelAgentModal';
import { travelAgentModaleFormRules } from './travelAgentModal';

import {
    ROA_TRAVEL_PLAN_SET_VALUE,
    ROA_TRAVEL_PLAN_SET_VALID,
} from '../../../actions/roaTravelInsurance/travelPlan';
import { travelPlanFormRules } from './travelPlan';

import {
    TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE,
    TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID,
} from '../../../actions/roaTravelInsurance/addTraveller';
import {TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALID, TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE} from '../../../actions/roaTravelInsurance/manualMpesaPayments';
import {addTravellerDetailsFormRules} from './payments';

export const roaTravelInsuranceRules = (store, next, action) => {
    if (action.type.startsWith(SET_VALUE_ROUTING)) {
        return routingRules(store, next, action);
    }
    if (
        action.type.startsWith(TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE) ||
        action.type.startsWith(TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID)
    ) {
        return aboutYouFormRules(store, next, action);
    }

    if (
        action.type.startsWith(TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE) ||
        action.type.startsWith(TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALID)
    ) {
        return travelDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(
            TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE,
        ) ||
        action.type.startsWith(TRAVEL_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID)
    ) {
        return travelAgentModaleFormRules(store, next, action);
    }

    if (
        action.type.startsWith(ROA_TRAVEL_PLAN_SET_VALUE) ||
        action.type.startsWith(ROA_TRAVEL_PLAN_SET_VALID)
    ) {
        return travelPlanFormRules(store, next, action);
    }

    if (
        action.type.startsWith(TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALUE) ||
        action.type.startsWith(TRAVEL_INSURANCE_ADD_TRAVELLER_SET_VALID) ||
        action.type.startsWith(TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE)||
        action.type.startsWith(TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALID)
    ) {
        return addTravellerDetailsFormRules(store, next, action);
    }
    return null;
};
