export const QUIZ_SET_VALUE = 'quizAssessment/quiz/setValue/';
export const QUIZ_CLEAR_VALUES = 'quizAssessment/quiz/clearValues';

export const CREATE_NEW_QUIZ = 'quizAssessment/quiz/create/newQuiz';

export const CURRENT_QUESTION_SET_VALUE = QUIZ_SET_VALUE + 'currentQuestion';
export const ANSWER_SET_VALUE = QUIZ_SET_VALUE + 'answer';

export const createNewQuiz = (quizId, totalQuestions) => ({ type: CREATE_NEW_QUIZ, quizId, totalQuestions })
export const setCurrentQuestion = (quizId, value) => ({ type: CURRENT_QUESTION_SET_VALUE, quizId, value});
export const setAnswer = (quizId, value) => ({ type: ANSWER_SET_VALUE, quizId, value });
export const clearValues = (quizId) => ({ type: QUIZ_CLEAR_VALUES, quizId });
