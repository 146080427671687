import {dropOffLeadCaptureDetail, dropOffFailCaptureDetail} from "../../../actions/raOptimal/captureDetails";
import {
    getDropOffDetails,
    getDropoffDetailsValid,
    getMobile,
    getName,
    getSurname
} from "../../../selectors/raOptimal/captureDetails";
import axios from "axios";

export const dropOffLeadsService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const dropOffDetailsValid = getDropoffDetailsValid(state);

    if (!dropOffDetailsValid) return result;

    const newDropOffDetails = buildPayload(state);
    const previousDropOffDetails = getDropOffDetails(state);
    const dropOffDetailsChanged = JSON.stringify(previousDropOffDetails) !== JSON.stringify(newDropOffDetails);

    if (!dropOffDetailsChanged) return result;
    
    (async () => {
        if (await submitDropOffLead(newDropOffDetails)) {
            store.dispatch(dropOffLeadCaptureDetail(newDropOffDetails));
        }
    })();

    return result;
};

export const submitDropOffLead = async payload => {
    try {
        const response = await axios.post('/om-api/ra-optimal/drop-off-leads', payload, {headers: {'Content-Type': 'application/json'}});
        return response.status === 200;
    } catch (error) {
        return false;
    }
};

export const buildPayload = state => ({
    CellphoneNumber: getMobile(state).value.replace(/\s/g, ''),
    Firstname: getName(state).value,
    LastName: getSurname(state).value,
    ProjectCode: "PersonalFinanceRetirementAnnuityOptimalPlan"
});
