export const EMERGENCY_FUND_CALCULATOR_TRANSITION = 'investmentCalculator/emergencyFund/transition/'

export const FORM_VALID_EMERGENCY_FUND_TRANSITION = 'investmentCalculator/emergencyFund/formValid';
export const FORM_INVALID_EMERGENCY_FUND_TRANSITION = 'investmentCalculator/emergencyFund/formInvalid';

export const transitionEmergencyCalculator = {
    formValid: () => ({ type: FORM_VALID_EMERGENCY_FUND_TRANSITION }),
    formInvalid: () => ({ type: FORM_INVALID_EMERGENCY_FUND_TRANSITION }),
};

export const EMERGENCY_FUND_CALCULATOR_SET_VALUE = 'investmentCalculator/emergencyFund/setValue/';
export const EMERGENCY_FUND_CALCULATOR_SET_VALID = 'investmentCalculator/emergencyFund/setValid/';

export const REINITIALIZE_STATE_EMERGENCY_FUND_CALCULATOR = 'investmentCalculator/emergencyFund/reinitialize';

export const ONCE_OFF_SET_VALUE = EMERGENCY_FUND_CALCULATOR_SET_VALUE + 'onceOff';
export const ONCE_OFF_SET_VALID = EMERGENCY_FUND_CALCULATOR_SET_VALID + 'onceOff';
export const MONTHLY_AMOUNT_SET_VALUE = EMERGENCY_FUND_CALCULATOR_SET_VALUE + 'monthlyAmount';
export const MONTHLY_AMOUNT_SET_VALID = EMERGENCY_FUND_CALCULATOR_SET_VALID + 'monthlyAmount';
export const RESULTS_VIEW_VALUE = EMERGENCY_FUND_CALCULATOR_SET_VALUE + 'viewResults';

export const RESULTS_SET_VALUE = EMERGENCY_FUND_CALCULATOR_SET_VALUE + 'setResults';
export const CALCULATE_EMERGENCY_FUND = EMERGENCY_FUND_CALCULATOR_SET_VALUE + 'success';
export const RESULTS_SET_VALUE_FAILURE = EMERGENCY_FUND_CALCULATOR_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_EMERGENCY_FUND_CALCULATOR = `${EMERGENCY_FUND_CALCULATOR_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_EMERGENCY_FUND_CALCULATOR = `${EMERGENCY_FUND_CALCULATOR_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_EMERGENCY_FUND_CALCULATOR = `${EMERGENCY_FUND_CALCULATOR_SET_VALUE}/apiTransition/failure`;
export const apiTransitionEmergencyFundCalculator = {
    pending: () => ({ type: API_PENDING_TRANSITION_EMERGENCY_FUND_CALCULATOR }),
    success: () => ({ type: API_SUCCESS_TRANSITION_EMERGENCY_FUND_CALCULATOR }),
    failure: () => ({ type: API_FAILURE_TRANSITION_EMERGENCY_FUND_CALCULATOR }),
};

export const getEmergencyFundResult = () => ({ type: RESULTS_SET_VALUE });
export const calculateEmergencyFund = (value) => ({ type: CALCULATE_EMERGENCY_FUND, value});
export const getEmergencyFundResultFailure = error => ({ type: RESULTS_SET_VALUE_FAILURE, error });

export const setEmergencyFundOnceOffValue = value => ({ type: ONCE_OFF_SET_VALUE, value });
export const setEmergencyFundOnceOffValid = error => ({ type: ONCE_OFF_SET_VALID, error });
export const setMonthlyValue = value => ({ type: MONTHLY_AMOUNT_SET_VALUE, value });
export const setMonthlyValid = error => ({ type: MONTHLY_AMOUNT_SET_VALID, error });
export const setViewEmergencyFundResult = value => ({ type: RESULTS_VIEW_VALUE, value });
export const reInitialize = () => ({ type: REINITIALIZE_STATE_EMERGENCY_FUND_CALCULATOR });
