import {
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
    navigateToBiasharaFlexiQuote,
    navigateToCompanyDetails,
    navigateToEmployeeDetails,
    navigateToPayment,
    navigateToUploadDocuments,
} from '../../../actions/biasharaFlexi/routing';
import { getActivePageIndex } from '../../../selectors/biasharaFlexi/routing';
import { PAGE_INDICES, PAGE_INDICES_PAY_NOW } from '../../../actions/biasharaFlexi/types';
import { getIsPayNow } from '../../../selectors/biasharaFlexi/coverDetails';

export const routingRules = (store, next, action) => {
    if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS)
        return next(action);
    const result = next(action);
    const state = store.getState();
    const isPayNow = getIsPayNow(store.getState());
    const activePageIndex = getActivePageIndex(state);
    if (action.type === NAVIGATE_NEXT) {
        isPayNow ? onNavigateNextPayNow(store, state, activePageIndex) : onNavigateNext(store, state, activePageIndex);
        return result;
    }
    isPayNow ? onNavigatePreviousPayNow(store, state, activePageIndex) : onNavigatePrevious(store, state, activePageIndex);
    return result;
};

const onNavigateNext = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.QOUTE:
            store.dispatch(navigateToCompanyDetails());
            break;
        case PAGE_INDICES.COMPANY_DETAILS:
            store.dispatch(navigateToEmployeeDetails());
            break;
        case PAGE_INDICES.EMPLOYEE_DETAILS:
            store.dispatch(navigateToUploadDocuments());
            break;
        case PAGE_INDICES.UPLOAD_DOCUMENTS:
            store.dispatch(navigateToPayment());
            break;
        default:
            store.dispatch(navigateToBiasharaFlexiQuote());
            break;
    }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.COMPANY_DETAILS:
            store.dispatch(navigateToBiasharaFlexiQuote());
            break;

        case PAGE_INDICES.EMPLOYEE_DETAILS:
            store.dispatch(navigateToCompanyDetails());
            break;
        case PAGE_INDICES.UPLOAD_DOCUMENTS:
            store.dispatch(navigateToEmployeeDetails());
            break;
        case PAGE_INDICES.PAYMENT:
            store.dispatch(navigateToUploadDocuments());
            break;
        default:
            store.dispatch(navigateToBiasharaFlexiQuote());
            break;
    }
};

const onNavigateNextPayNow = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES_PAY_NOW.QOUTE:
            store.dispatch(navigateToCompanyDetails());
            break;
        case PAGE_INDICES_PAY_NOW.COMPANY_DETAILS:
            store.dispatch(navigateToPayment());
            break;
        default:
            store.dispatch(navigateToBiasharaFlexiQuote());
            break;
    }
};

const onNavigatePreviousPayNow = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES_PAY_NOW.COMPANY_DETAILS:
            store.dispatch(navigateToBiasharaFlexiQuote());
            break;
        case PAGE_INDICES_PAY_NOW.PAYMENT:
            store.dispatch(navigateToCompanyDetails());
            break;
        default:
            store.dispatch(navigateToBiasharaFlexiQuote());
            break;
    }
};

