import { PERSISTED_AT_SET_VALUE } from '../../actions/quizAssessment/persistedAt'

export const initPersistedAt = (new Date()).getTime();

export default (state = initPersistedAt, action) => {
    switch (action.type){
        case PERSISTED_AT_SET_VALUE:
            return (new Date()).getTime();
        default:
            return state;
    }
}
