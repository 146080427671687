import { createSelector } from 'reselect';

export function getCountryCodeFromEnv() {
    return (process.env.GATSBY_COUNTRY_SELECTOR || '').toUpperCase();
}

export const getCountryCodeSelector = createSelector(() => getCountryCodeFromEnv());

// are these duplicated somewhere?
export const addThousandsSeparator = (input) => {
    return input.replace(/\s/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatCurrency = (number, prefix) => {
    let formattedNumber = 0;
    let parsed = parseFloat(number).toFixed(2);

    if (isNaN(parsed) || parsed === 'NaN') {
        formattedNumber = '0';
    } else {
        formattedNumber = addThousandsSeparator(parsed);
    }

    if (prefix) {
        return `${prefix} ${formattedNumber}`;
    }

    return formattedNumber;
};

export const countryCodeToCurrencyPrefix = new Map([
    ['ZA', 'R'],
    ['NA', 'N$'],
]);

export function countryCodeToCurrency(countryCode) {
    switch (countryCode) {
        case 'ZA':
            return 'R';
        case 'NA':
            return 'N$';
        default:
            return 'R';
    }
}

export const getCurrencyCodeSelector = createSelector(getCountryCodeSelector, (countryCode) => {
    return countryCodeToCurrencyPrefix.has(countryCode) ? countryCodeToCurrencyPrefix.get(countryCode) : 'R';
});

export function getCurrency(countryCode) {
    return countryCodeToCurrencyPrefix.has(countryCode) ? countryCodeToCurrencyPrefix.get(countryCode) : 'R';
}

//const currencyCode = countryCodeToCurrency(countryCode)
