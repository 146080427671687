import { getActivePageIndex } from '@om/redux/selectors/digitalOnboarding/routing';
import { schedulePageRequests, moveToNextPage, moveToPreviousPage, setReturnToProduct, makePageRequest, resetPageRequests, moveToFailurePage } from '../../../actions/digitalOnboarding/routing';
import { PAGE_INDEXES, SEND_PAGE_REQUESTS, DO_SUCCESSFUL_PAGE_REQUEST, DO_FAILED_PAGE_REQUEST} from '../../../actions/digitalOnboarding/types';

export const navigateToNextPage = (store, next, action) =>  {
    const state = store.getState();
    const index = getActivePageIndex(state);
    const requestTypes = PAGE_INDEXES[index].requestTypes;
    if (requestTypes.length > 0) {
        store.dispatch(schedulePageRequests(requestTypes));
    } else {
        store.dispatch(moveToNextPage());
    }
    return next(action);
}

export const goBack = (store, next, action) => {
    const state = store.getState();
    const index = getActivePageIndex(state);
    if (PAGE_INDEXES[index].previousPageId === null) {
        store.dispatch(setReturnToProduct());
    } else {
        store.dispatch(moveToPreviousPage());
    }
    return next(action);
}

const getNextPageRequestType = state => {
    const pendingRequests = state.digitalOnboarding.routing.pendingPageRequests;
    if (pendingRequests && pendingRequests.length > 0) {
        return pendingRequests[0];
    }
    return null;
}

const canProceed = state => state.digitalOnboarding.routing.canProceed;

export const handlePageRequestQueue = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    if (action.type.startsWith(DO_SUCCESSFUL_PAGE_REQUEST) || action.type === SEND_PAGE_REQUESTS) {
        const nextPageRequest = getNextPageRequestType(state);
        if (nextPageRequest) {
            store.dispatch(makePageRequest(nextPageRequest));
        } else if(canProceed(state)) {
            store.dispatch(moveToNextPage());
        }
    } else if (action.type.startsWith(DO_FAILED_PAGE_REQUEST)) {
        store.dispatch(resetPageRequests());

        const index = getActivePageIndex(state);
        if (PAGE_INDEXES[index].jumpToPageIdOnFailure) {
            store.dispatch(moveToFailurePage());
        }
    }

    return result;
}
