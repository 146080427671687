import { validateFile } from "@om/validation";
import { ADDRESS_PROOF_DOCUMENTS_SET_VALUE, BANK_STATEMENT_DOCUMENTS_SET_VALUE, ID_DOCUMENTS_SET_VALUE, INCOME_PROOF_DOCUMENTS_SET_VALUE, LOAN_LETTER_DOCUMENTS_SET_VALUE, setAddressProofDocumentsValid, setIdDocumentsValid, setIncomeProofDocumentsValid, setbankStatementDocumentsValid, setloanLetterDocumentsValid } from "../../../actions/digiLifeLoanProtection/uploadDocuments";
import { UPLOAD_PAGE_CONFIG } from "../../../actions/digiLifeLoanProtection/types";
import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const uploadDocumentsValidation =(store, next, action)=>{
const validationAction=(()=>{
    let error
    error = validateFile(action.fileContaint, {
      minInputSize:UPLOAD_PAGE_CONFIG.MIN_INPUT_SIZE,
      minInputSizeName:UPLOAD_PAGE_CONFIG.MIN_INPUT_SIZE_NAME,
      maxInputSize: UPLOAD_PAGE_CONFIG.MAX_INPUT_SIZE,
      maxInputSizeName: UPLOAD_PAGE_CONFIG.MAX_INPUT_SIZE_NAME,
      validFileTypes: UPLOAD_PAGE_CONFIG.VALID_UPLOAD_TYPES,
    });
    switch (action.type) {
      
        case ID_DOCUMENTS_SET_VALUE:
          return setIdDocumentsValid(error);
        case INCOME_PROOF_DOCUMENTS_SET_VALUE:
          return setIncomeProofDocumentsValid(error);
        case ADDRESS_PROOF_DOCUMENTS_SET_VALUE:
          return setAddressProofDocumentsValid(error);
        case LOAN_LETTER_DOCUMENTS_SET_VALUE:
          return setloanLetterDocumentsValid(error);
        case BANK_STATEMENT_DOCUMENTS_SET_VALUE:          
          return setbankStatementDocumentsValid(error);

          default:
          return null;
        }
})()
    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
      ) {
        return next(action);
      }
    
      store.dispatch(validationAction);
      prevValidationAction = validationAction;
      return next(action);
}