// Events
export const ROA_APPLICATION_REFERENCE_DATA_SET_VALUE =
    'roaTravelInsurance/applicationReferenceData/setValue/';
export const ROA_APPLICATION_REFERENCE_DATA_GET_VALUE =
    'roaTravelInsurance/applicationReferenceData/getValue/';

export const ROA_COUNTRIES_REFERENCE_DATA_SET_VALUE =
    'roaTravelInsurance/countriesReferenceData/setValue/';
export const ROA_COUNTRIES_REFERENCE_DATA_GET_VALUE =
    'roaTravelInsurance/countriesReferenceData/getValue/';

export const setApplicationReferenceData = value => ({
    type: ROA_APPLICATION_REFERENCE_DATA_SET_VALUE,
    value,
});
export const getApplicationReferenceData = () => ({
    type: ROA_APPLICATION_REFERENCE_DATA_GET_VALUE,
});

export const setCountriesReferenceData = value => ({
    type: ROA_COUNTRIES_REFERENCE_DATA_SET_VALUE,
    value,
});
export const getCountriesReferenceData = () => ({
    type: ROA_COUNTRIES_REFERENCE_DATA_GET_VALUE,
});
