import { createSelector } from "reselect";
import { getCovidSelfAssessment } from "./index";
import { FORM_STATUS } from "../../reducers/status";

export const getClinicalCriteria = createSelector(
    getCovidSelfAssessment,
    covidSelfAssessment => covidSelfAssessment.clinicalCriteria
);

export const getClinicalCriteriaFormIsValid = createSelector(
    getClinicalCriteria,
    clinicalCriteria => clinicalCriteria.formStatus === FORM_STATUS.VALID,
);

export const getClinicalCriteriaForm = createSelector(
    getClinicalCriteria,
    clinicalCriteria => clinicalCriteria.form,
);

const makeGetField = fieldId => createSelector(
    getClinicalCriteriaForm,
    clinicalCriteriaForm => clinicalCriteriaForm[fieldId]
);

export const getCough = makeGetField('cough');
export const getShortBreath = makeGetField('shortBreath');
export const getHighFever = makeGetField('highFever');
export const getSoreThroat = makeGetField('soreThroat');
