import { FIELD_STATUS, FORM_STATUS } from "../status";
import {
    INITIALS_SET_VALUE,
    INITIALS_SET_VALID,
    SURNAME_SET_VALUE,
    SURNAME_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    USER_FOUND_SET_VALUE,
    REINITIALIZE,
    COLLECT_USER_DATA,
    FORM_VALID_UNCLAIMED_BENEFITS,
    FORM_INVALID_UNCLAIMED_BENEFITS
} from "../../actions/publicWeb/unclaimedBenefits";

export const initUnclaimedBenefits = {
    formStatus: FORM_STATUS.INVALID,
    userFound: null,
    verifyingStatus: null,
    form: {
        initials: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        dob: { value: null, error: null, status: null },
    },
};

export default (state = initUnclaimedBenefits, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch(action.type) {
        // Form Validation
        case FORM_VALID_UNCLAIMED_BENEFITS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_UNCLAIMED_BENEFITS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Initials
        case INITIALS_SET_VALUE:
            return updateFormField('initials', field => ({
                ...field,
                value: action.value,
            }));
        case INITIALS_SET_VALID:
            return updateFormField('initials', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        
        // Surname
        case SURNAME_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: action.value,
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Date of birth
        case DOB_SET_VALUE:
            return updateFormField('dob', field => ({
                ...field,
                value: action.value,
            }));
        case DOB_SET_VALID:
            return updateFormField('dob', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        
        case USER_FOUND_SET_VALUE:
            return {
                ...state,
                userFound: action.value,
                verifyingStatus: false
            }
        case COLLECT_USER_DATA:
            return {
                ...state,
                verifyingStatus: true
            }

        case REINITIALIZE:
            return initUnclaimedBenefits;

        default: 
            return state
    }
}
