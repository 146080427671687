import {fireGtmEvent} from "./gtmEvents";

export const transitionTracking = (actionType, formFields) => {
    const [app, page, formEvent, eventName] = actionType.split('/');
    fireGtmEvent(eventName, {
        app,
        page,
        formEvent,
        formFields,
    });
};
