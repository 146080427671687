export const DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM = 'domesticInsurance/personalDetailsForm/';

export const DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM + 'setValue/';
export const DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM + 'setValid/';

export const FORM_VALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM = 'domesticInsurance/personalDetailsForm/transition/formValid';
export const FORM_INVALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM = 'domesticInsurance/personalDetailsForm/transition/formInValid';

export const REINITIALIZE = 'domesticInsurance/personalDetailsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_PERSONAL_DETAILS_FORM = 'domesticInsurance/personalDetailsForm/transition/dropOffLead';
export const dropOffLeadPersonalDetails = (value) => ({
	type: DROP_OFF_LEAD_TRANSITION_PERSONAL_DETAILS_FORM,
	value,
});

export const transitionDomesticInsurancePersonalDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM = `${DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM = `${DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM = `${DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionPersonalDetailsForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM,
	}),
};

//calculated Data
export const COLLECT_PERSONAL_DETAILS_FORM_DATA = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'collectPersonalDetailsData';
export const SUBMIT_PERSONAL_DETAILS_SUCCESS = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'success';
export const SUBMIT_PERSONAL_DETAILS_FAILURE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'failure';

export const collectPersonalDetailsData = () => ({
    type: COLLECT_PERSONAL_DETAILS_FORM_DATA,
});
export const submitPersonalDetailsDataSuccess = (value) => ({
	type: SUBMIT_PERSONAL_DETAILS_SUCCESS,
	value,
});
export const submitPersonalDetailsFailure = (error) => ({
	type: SUBMIT_PERSONAL_DETAILS_FAILURE,
	error,
});

// Form Values
export const GENDER_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'gender';
export const GENDER_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'gender';

export const FIRST_NAME_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'firstName';

export const LAST_NAME_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'lastName';
export const LAST_NAME_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'lastName';

export const NATIONALITY_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'nationality';
export const NATIONALITY_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'nationality';

export const DOB_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'DOB';

export const OCCUPATION_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'occupation';
export const OCCUPATION_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'occupation';

export const ID_NUMBER_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'idNumber';

export const TIN_NUMBER_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'tinNumber';
export const TIN_NUMBER_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'tinNumber';

export const PHONE_NUMBER_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'phoneNumber';

export const EMAIL_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'email';

export const POSTAL_ADDRESS_SET_VALUE = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'postalAddress';
export const POSTAL_ADDRESS_SET_VALID = DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'postalAddress';

export const STREET_ADDRESS_SET_VALUE = POSTAL_ADDRESS_SET_VALUE + '/streetAdress';
export const STREET_ADDRESS_SET_VALID = POSTAL_ADDRESS_SET_VALID + '/streetAdress';
export const SUBURBS_SET_VALUE = POSTAL_ADDRESS_SET_VALUE + '/suburbs';
export const SUBURBS_SET_VALID = POSTAL_ADDRESS_SET_VALID + '/suburbs';
export const CITY_SET_VALUE =  POSTAL_ADDRESS_SET_VALUE + '/city';
export const CITY_SET_VALID = POSTAL_ADDRESS_SET_VALID + '/city';
export const PO_BOX_SET_VALUE = POSTAL_ADDRESS_SET_VALUE + '/pobox';
export const PO_BOX_SET_VALID = POSTAL_ADDRESS_SET_VALID + '/pobox';
export const POSTAL_CODE_SET_VALUE = POSTAL_ADDRESS_SET_VALUE + '/postalCode';
export const POSTAL_CODE_SET_VALID = POSTAL_ADDRESS_SET_VALID + '/postalCode';


// action creators

export const setGenderValue = value => ({
    type: GENDER_SET_VALUE,
    value,
})

export const setGenderValid = error => ({
    type: GENDER_SET_VALID,
    error,
})

export const setNationalityValue = value => ({
    type: NATIONALITY_SET_VALUE,
    value,
})

export const setNationalityValid = error => ({
    type: NATIONALITY_SET_VALID,
    error,
})

export const setOccupationValue = value => ({
    type: OCCUPATION_SET_VALUE,
    value,
})

export const setOccupationValid = error => ({
    type: OCCUPATION_SET_VALID,
    error,
})

export const setIdNumberValue = value => ({
    type: ID_NUMBER_SET_VALUE,
    value,
})

export const setIdNumberValid = error => ({
    type: ID_NUMBER_SET_VALID,
    error,
})

export const setTinNumberValue = value => ({
    type: TIN_NUMBER_SET_VALUE,
    value,
})

export const setTinNumberValid = error => ({
    type: TIN_NUMBER_SET_VALID,
    error,
})


export const setPostalAddressValue = value => ({
    type: POSTAL_ADDRESS_SET_VALUE,
    value,
})

export const setPostalAddressValid = error => ({
    type: POSTAL_ADDRESS_SET_VALID,
    error,
})

export const setFirstNameValue = value => ({
    type: FIRST_NAME_SET_VALUE,
    value,
});
export const setFirstNameValid = error => ({
    type: FIRST_NAME_SET_VALID,
    error,
});

export const setLastNameValue = (value) => ({
	type: LAST_NAME_SET_VALUE,
	value,
});
export const setLastNameValid = (error) => ({
	type: LAST_NAME_SET_VALID,
	error,
});


export const setPhoneNumberValue = value => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = error => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setEmailValue = value => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = error => ({
    type: EMAIL_SET_VALID,
    error,
});

export const setDOBValue = value => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDOBValid = error => ({
    type: DOB_SET_VALID,
    error,
});

export const setStreetAddressValue = (value) => ({
    type: STREET_ADDRESS_SET_VALUE,
    value,
});
export const setStreetAddressValid = (error) => ({
    type: STREET_ADDRESS_SET_VALID,
    error,
});

export const setSurbubsValue = (value) => ({
    type: SUBURBS_SET_VALUE,
    value,
});
export const setSurbubsValid = (error) => ({
    type: SUBURBS_SET_VALID,
    error,
});

export const setCityValue = (value) => ({
    type: CITY_SET_VALUE,
    value,
});
export const setCityValid = (error) => ({
    type: CITY_SET_VALID,
    error,
});

export const setPoBoxValue = (value) => ({
    type: PO_BOX_SET_VALUE,
    value,
});
export const setPoBoxValid = (error) => ({
    type: PO_BOX_SET_VALID,
    error,
});

export const setPostalCodeValue = (value) => ({
    type: POSTAL_CODE_SET_VALUE,
    value,
});
export const setPostalCodeValid = (error) => ({
    type: POSTAL_CODE_SET_VALID,
    error,
});