import {transitionConsentPage, SERVICE_PROVIDER_SET_VALUE} from "../../../actions/customerVerificationService/consent";
import {SERVICE_PROVIDERS} from "../../../actions/customerVerificationService/types";
import {getConsentFormIsValid, getServiceProvider} from "../../../selectors/customerVerificationService/consent";

export const consentRules = (store, next, action) => {
    const result = next(action);

    if (action.type === SERVICE_PROVIDER_SET_VALUE) {
        store.dispatch(transitionConsentPage.serviceProvider(serviceProviderSwitch(store, action)));
    }

    // Set form valid
    const isValid = getConsentFormIsValid(store.getState());
    store.dispatch(isValid
        ? transitionConsentPage.formValid()
        : transitionConsentPage.formInvalid());

    return result;
};

const serviceProviderSwitch = (store, action) => {
    // Set service provider   
    const serviceProvider = getServiceProvider(store.getState());
    const serviceProviders = [SERVICE_PROVIDERS.CONTACTABLE, SERVICE_PROVIDERS.IIDENTIFII];
    const serviceProviderClientValue = parseInt(action.value);

    // If the service provider has been set then exit
    if (serviceProvider && serviceProvider != 0) {
        return serviceProvider;
    }
       
    // If the service provider has been set on the passed in with the action (from the URL) add it to state
    if (serviceProviderClientValue && serviceProviders.includes(serviceProviderClientValue)) {
        return serviceProviderClientValue;
    } else {
        // Else randomly assign service provider
        const randomServiceProvider = Math.floor(Math.random() * serviceProviders.length);
        return serviceProviders[randomServiceProvider];
    }
}
