import axios from 'axios';
import {
    getScoreKbaPayload,
    getInitiateKbaPayload,
} from "../../../selectors/customerVerificationService/knowledgeBasedAuthentication";
import {
    setKbaQuestions,
    QUESTIONS_FETCH_VALUE,
    setKbaModal,
    SCORE_FETCH_VALUE,
    setKbaScore,
} from "../../../actions/customerVerificationService/knowledgeBasedAuthentication";
import {
    navigateBackToParentApp,
    navigateNextKbaInterlude,
} from "../../../actions/customerVerificationService/routing";
import {CVS_MODALS, TRANSITION_BETWEEN_PAGE_DELAY} from "../../../actions/customerVerificationService/types";

export const kbaQuestionService = (store, next, action) => {
    const result = next(action);

    if (action.type === QUESTIONS_FETCH_VALUE) {
        store.dispatch(setKbaModal(CVS_MODALS.LOADING));

        const initiateKbaPayload = getInitiateKbaPayload(store.getState());
        (async () => {
            const initiateKbaResponse = await initiateKba(initiateKbaPayload);

            if (initiateKbaResponse && (initiateKbaResponse.status === 200)) {
                store.dispatch(setKbaQuestions(initiateKbaResponse.data.questions));
                store.dispatch(navigateNextKbaInterlude());
                store.dispatch(setKbaModal(null));
            } else {
                store.dispatch(setKbaModal(CVS_MODALS.INITIATION_FAILED));
            }
        })();
    }

    if (action.type === SCORE_FETCH_VALUE) {
        store.dispatch(setKbaModal(CVS_MODALS.LOADING));

        const scoreKbaPayload = getScoreKbaPayload(store.getState());
        (async () => {
            const scoreKbaResponse = await scoreKba(scoreKbaPayload);

            if (scoreKbaResponse && (scoreKbaResponse.status === 200)) {
                store.dispatch(setKbaScore(scoreKbaResponse.data.totalCorrectAnswers));

                const minScore = 4;
                if (scoreKbaResponse.data.totalCorrectAnswers >= minScore) {

                    store.dispatch(setKbaModal(CVS_MODALS.VERIFICATION_SUCCESS));
                    // Display success modal for 2.5 seconds and then route to parent APP
                    setTimeout(() => { 
                        store.dispatch(navigateBackToParentApp());
                    }, TRANSITION_BETWEEN_PAGE_DELAY);

                } else {
                    store.dispatch(setKbaModal(CVS_MODALS.VERIFICATION_FAILED));
                }
            } else {
                store.dispatch(setKbaModal(CVS_MODALS.INITIATION_FAILED));
            }
        })();
    }

    return result;
};

export const initiateKba = async body => {
    try {
        const response = await axios.post('/om-api/customer-verification-service/initiate-kba', 
        body, { headers: { 'Content-Type': 'application/json' }});
        return response;
    } catch (error) {
        return null;
    }
};

export const scoreKba = async body => {
    try {
        const response = await axios.post('/om-api/customer-verification-service/score-kba', 
        body, { headers: { 'Content-Type': 'application/json' }});
        return response;
    } catch (error) {
        return null;
    }
};