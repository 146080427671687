import {FIELD_STATUS} from '../status';
import {
    CALCULATE_DEBT_RESULTS,
    HOW_MUCH_OWED_SET_VALID,
    HOW_MUCH_OWED_SET_VALUE,
    INTEREST_RATE_SET_VALID,
    INTEREST_RATE_SET_VALUE,
    MONTHLY_REPAYMENT_SET_VALID,
    MONTHLY_REPAYMENT_SET_VALUE,
    RESET_DEBT_RESULTS,
    RESULT_CALCULATED_SET_VALUE
} from '../../actions/debtRepaymentCalculators/debtRepaymentCalculator';
import {an_nper_pv} from '@om/tvm-calcs';

const debtCalculator = {
    howMuchOwed: {value: null, error: null, status: null},
    interestRate: {value: null, error: null, status: null},
    monthlyRepayment: {value: null, error: null, status: null},
    calculateButtonDisabled: {value: null, error: null, status: null},
    result: {datePaidOff: '', interestPaid: ''},
    resultCalculated: '',
}


export default (state = debtCalculator, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    switch (action.type) {
    case HOW_MUCH_OWED_SET_VALUE:
        return updateFormField('howMuchOwed', (field) => ({
            ...field,
            value: action.value.howMuchOwed.value
        }));
    case HOW_MUCH_OWED_SET_VALID:
        return updateFormField('howMuchOwed', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));
    case INTEREST_RATE_SET_VALUE:
        return updateFormField('interestRate', (field) => ({
            ...field,
            value: action.value.interestRate.value
        }));
    case INTEREST_RATE_SET_VALID:
        return updateFormField('interestRate', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));
    case MONTHLY_REPAYMENT_SET_VALUE:
        return updateFormField('monthlyRepayment', (field) => ({
            ...field,
            value: action.value.monthlyRepayment.value
        }));
    case MONTHLY_REPAYMENT_SET_VALID:
        return updateFormField('monthlyRepayment', (field) => ({
            ...field,
            error: action.error,
            status: mapError(action.error)
        }));
    case CALCULATE_DEBT_RESULTS:
        return {
            ...state,
            result: calculateResults(action.value)
        }
    case RESULT_CALCULATED_SET_VALUE:
        return {
            ...state,
            resultCalculated: action.value
        };
    case RESET_DEBT_RESULTS:
        return debtCalculator;
    default:
        return state;
    }
}

function calculateResults(value) {
    let howMuchOwed = value.howMuchOwed.value;
    let interestRate = value.interestRate.value;
    let monthlyRepayment = value.monthlyRepayment.value;

    let numberMonths = an_nper_pv((interestRate / 100) / 12, monthlyRepayment, howMuchOwed);

    let dateRepaid = addMonths(new Date(), numberMonths);
    const year = dateRepaid.getFullYear();
    const month = dateRepaid.toLocaleString('default', {month: 'short'});

    let totalPaidUpVal = totalPaidUp(numberMonths, monthlyRepayment);
    let totalInterestPaid = totalPaidUpVal - howMuchOwed;

    return {datePaidOff: month + ' ' + year, interestPaid: formatMoney(totalInterestPaid)}
}

function addMonths(date, months) {
    let d = date.getDate();
    // adding one month here because there is residual value so last payment happens one month after this ends
    date.setMonth(date.getMonth() + +months + 1);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
}

function formatMoney(amount) {
    let formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
    });

    return formatter.format(amount);
}

function totalPaidUp(numberMonths, monthlyRepayment) {
    return numberMonths * monthlyRepayment;
}