import { transitionPersonalAccidentPersonalDetailsForm } from '../../../actions/personalAccident/personalDetails';
import { getPersonalDetailsForm } from '../../../selectors/personalAccident/personalDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const personalDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const personalDetailsFormValid = validatePersonalDetailsFormDetails(personalDetailsForm);
    const formAction = personalDetailsFormValid
        ? transitionPersonalAccidentPersonalDetailsForm.formValid()
        : transitionPersonalAccidentPersonalDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
    return (
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.tinNumber &&
        formFields.tinNumber.status === FIELD_STATUS.VALID &&
        formFields.streetAddress &&
        formFields.streetAddress.status === FIELD_STATUS.VALID &&
        formFields.suburb &&
        formFields.suburb.status === FIELD_STATUS.VALID &&
        formFields.city &&
        formFields.city.status === FIELD_STATUS.VALID
    );
};
