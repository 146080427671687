import { createSelector } from 'reselect';
import { getSavingsCalculators } from './index';
import { FIELD_STATUS, FORM_STATUS } from '../../reducers/status';
import { formatCurrency } from '../currency'
import {OmValidationDateHelper} from "@om/validation";

import {
    getExpectedAnnualGrowth,
    getAnnualIncreaseInContribution,
    getInflation
} from './howMuchYouNeedToSaveAssumptionsModel'

import {
    round,
    ea_to_freq,
    ga_freq_fv,
    ls_fv,
    ls_pv,
    ga_freq_pmt,
} from '../../../tvm-calcs/src/index'

const assumptionModelDefaultValues = {
    expectedAnnualGrowthDefault: 8,
    annualIncreaseInContributionDefault: 10,
    inflationDefault: 5,
};


export const getHowMuchYouNeedToSaveCalculator = createSelector(
	getSavingsCalculators,
	(savingsCalculators) => savingsCalculators.howMuchYouNeedToSaveCalculator
);

export const gethowMuchYouNeedToSaveCalculatorForm = createSelector(
	getHowMuchYouNeedToSaveCalculator,
	(howMuchYouNeedToSaveCalculator) => howMuchYouNeedToSaveCalculator.form
);

export const gethowMuchYouNeedToSaveCalculatorFormValid = createSelector(
	getHowMuchYouNeedToSaveCalculator,
	(howMuchYouNeedToSaveCalculator) => howMuchYouNeedToSaveCalculator.formStatus === FORM_STATUS.VALID
);

export const getShowRecommendation = createSelector(
	getHowMuchYouNeedToSaveCalculator,
	(howMuchYouNeedToSaveCalculator) => howMuchYouNeedToSaveCalculator.showRecommendation
);

const makeGetField = (fieldId) => createSelector(gethowMuchYouNeedToSaveCalculatorForm, (form) => form[fieldId]);

export const getSavingsGoalValue = makeGetField('savingsGoal');
export const getAchieveGoalDate = makeGetField('achieveGoalDate');
export const getCurrentSavings = makeGetField('currentSavings');

const getNumberOfMonths = (achieveGoalDate) => {
    if(achieveGoalDate.status !== FIELD_STATUS.VALID){
        return null;
    }
    const currentDate = new Date();
    const achieveGoalDt = OmValidationDateHelper.dateStringToDateObject(achieveGoalDate.value)
    const numberMonths = monthDiff(currentDate,achieveGoalDt);
    return numberMonths
}

function monthDiff(dateFrom, dateTo) {
 return dateTo.getMonth() - dateFrom.getMonth() + 
   (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

export const getShowProductOffering = createSelector(
    getHowMuchYouNeedToSaveCalculator,
    howMuchYouNeedToSaveCalculator => howMuchYouNeedToSaveCalculator.showRecommendation
);

export const getAssumptionsModal = createSelector(
    getHowMuchYouNeedToSaveCalculator,
    howMuchYouNeedToSaveCalculator => howMuchYouNeedToSaveCalculator.openModal
);

export const getAnnualIncreaseInContributionValue = createSelector(
    getAnnualIncreaseInContribution,
        (annualIncreaseInContribution) => {
            if(!annualIncreaseInContribution.value ||annualIncreaseInContribution.value === null || annualIncreaseInContribution.value > 30)
            {
                return (assumptionModelDefaultValues.annualIncreaseInContributionDefault)/100;
            }
            else{
                return (annualIncreaseInContribution.value)/100
            }
        }
    )

export const getExpectedAnnualGrowthValue = createSelector(
    getExpectedAnnualGrowth,
    (expectedAnnualGrowth) => {
            if(!expectedAnnualGrowth.value || expectedAnnualGrowth.value > 30)
        {
            return (assumptionModelDefaultValues.expectedAnnualGrowthDefault)/100;
        }
        else{
            return (expectedAnnualGrowth.value)/100
        }
    }
)

export const getInflationValue = createSelector(
    getInflation,
    (inflation) => {
        if(!inflation.value || inflation.value > 30)
        {
            return (assumptionModelDefaultValues.inflationDefault)/100;
        }
        else{
            return (inflation.value)/ 100
        }
    }
)

export const getShowResultSummary = createSelector(
    getHowMuchYouNeedToSaveCalculator,
    (howMuchYouNeedToSaveCalculator) => howMuchYouNeedToSaveCalculator.showResultSummary
    
);

export const getExportPayment = createSelector(
    getCurrentSavings,
    getSavingsGoalValue,
    getExpectedAnnualGrowthValue,
    getAnnualIncreaseInContributionValue,
    getInflationValue,
    getAchieveGoalDate,
    (currentSavings,savingsGoal,expectedAnnualGrowthValue,annualIncreaseInContributionValue,inflationValue,achieveGoalDate) => {
        const numberOfMonths = getNumberOfMonths(achieveGoalDate);
        return (
            round(
                ga_freq_pmt(expectedAnnualGrowthValue, 12,annualIncreaseInContributionValue, numberOfMonths/12,
                    ls_pv(
                        ea_to_freq(expectedAnnualGrowthValue, 12), numberOfMonths,
                        ls_fv(
                            ea_to_freq(inflationValue,12), numberOfMonths, (savingsGoal.value - currentSavings.value)
                        )
                    ))
            ,2)
        )
    }
)

export const getFormatedExportPayment = createSelector( // no longer formatted.
    getExportPayment,
    (exportPayment) => exportPayment // formatCurrency(exportPayment,'R')
)

export const getFormatedSavingsGoalValue = createSelector(
    getSavingsGoalValue,
    (savingsGoalValue) => formatCurrency(savingsGoalValue.value,'R')
)

export const getButtonDisabledValue = createSelector(
    getShowResultSummary,
    gethowMuchYouNeedToSaveCalculatorFormValid,
    (showResultSummary, formValid) => {
        if(formValid === false && showResultSummary === false){
            return true;
        }
        if(formValid === false && showResultSummary === true){
            return false;
        }
        if(showResultSummary === true && formValid === true){
            return true;
        }
        if(formValid === true && showResultSummary === false){
            return false;
        }
    }
);