import { transitionPersonalPensionPlanQuoteForm } from '../../../actions/personalPensionPlan/quote';
import { getQuoteForm } from '../../../selectors/personalPensionPlan/quote';

import { FIELD_STATUS } from '../../../reducers/status';

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getQuoteForm(store.getState());
    const quoteFormValid = validateQuoteForm(quoteForm);
    const formAction = quoteFormValid
        ? transitionPersonalPensionPlanQuoteForm.formValid()
        : transitionPersonalPensionPlanQuoteForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateQuoteForm = (formFields) => {
    const commonChecks =
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.gender.status === FIELD_STATUS.VALID &&
        formFields.contributionMode.status === FIELD_STATUS.VALID &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.interestRate.status === FIELD_STATUS.VALID &&
        formFields.desiredRetirementAge.status === FIELD_STATUS.VALID;

    let additionalDetailsValid = true;

    if (formFields.contributionMode.value === 'monthly-contribution-with-lumpsum') {
        additionalDetailsValid =
            formFields.desiredMonthlyContribution.status === FIELD_STATUS.VALID &&
            formFields.lumpsumAmount.status === FIELD_STATUS.VALID &&
            formFields.frequencyOfContribution.error === null;
        //&&formFields.isLumpsumPrepaid.status === FIELD_STATUS.VALID
    }

    if (formFields.contributionMode.value === 'monthly-contribution-only') {
        additionalDetailsValid =
            formFields.desiredMonthlyContribution.status === FIELD_STATUS.VALID &&
            formFields.frequencyOfContribution.error === null;
    }

    if (formFields.contributionMode.value === 'lumpsum-only') {
        additionalDetailsValid = formFields.lumpsumAmount.status === FIELD_STATUS.VALID;
        //&&formFields.isLumpsumPrepaid.status === FIELD_STATUS.VALID
    }

    return commonChecks && additionalDetailsValid;
};
