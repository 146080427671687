import axios from 'axios'; 

import {
    calculatePremierSavingsData,
    apiTransitionPremierSavingsQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/premierSavingsQuoteTool";

import {
    getPremierSavingsQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/premierSavingsQuoteTool';

export const premierSavingsQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionPremierSavingsQuoteTool.pending());

            try {
                const response = await calculate(state);
                const quotationSummary = response.data;
                store.dispatch(calculatePremierSavingsData(quotationSummary));
                store.dispatch(apiTransitionPremierSavingsQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-three'));
            } catch (e) {
                store.dispatch(calculatePremierSavingsData(null));
                store.dispatch(apiTransitionPremierSavingsQuoteTool.failure());
            }
        }
    })();
    return result;
}

const lumpsumPaymentDetails = (paymentDetails) => {
    const lumpsumPaymentDetails = [];
    paymentDetails.map((paymentDetail) => {
        let newPaymentDetails = {}
        newPaymentDetails['lumpsum'] = paymentDetail.lumpsumAmount.value || ''
        newPaymentDetails["lumpsum_year"] = paymentDetail.lumpsumYear.value || ''
        newPaymentDetails["lumpsum_month"] = paymentDetail.lumpsumMonth.value || ''
        lumpsumPaymentDetails.push(newPaymentDetails);
    })
    return lumpsumPaymentDetails;
}

const getPremierSavingsPayload = (formDetails) => {
    return {
        'term': formDetails.termInputValue.value || '',
        'monthly_premium': formDetails.monthlyPremiumAmount.value || '',
        'inv_ltp': formDetails.longTermInputValue.value || '',
        'inv_stp': formDetails.shortTermInputValue.value || '',
        'lumpsum_payment_details': lumpsumPaymentDetails(formDetails.lumpsumPaymentDetails)
    }
}
const calculate = async(state) => {
    const premierSavingsFormDetails = getPremierSavingsQuoteToolForm(state)

    try{
        const url = '/om-api/roa-quote-tools/premier-savings-quote';
        const result = await axios.post(url, getPremierSavingsPayload(premierSavingsFormDetails))
        return result;
    } catch (error) {
        return null;
    }
}
