import {combineReducers} from "redux";
import advancedFundPicker from "./advancedFundPicker";
import beneficiaries from './beneficiaries';
import beneficiariesSplit from "./beneficiariesSplit";
import fundData from "./fundData";
import fundSplit from "./fundSplit";
import investmentSetupInitial from "./investmentSetupInitial";
import investmentSetupContribution from "./investmentSetupContribution";
import paymentDetails from "./paymentDetails";
import persistedAt from "./persistedAt";
import personalDetails from "./personalDetails";
import referenceData from "./referenceData";
import review from "./review";
import routing from "./routing";
import scrolling from "./scrolling";
import selectAFund from "./selectAFund";
import sessionCamVariables from "./sessionCamVariables";
import auxiliaryDetails from "./auxiliaryDetails";

export const taxFreeSavingsAccount = combineReducers({
    advancedFundPicker,
    beneficiaries,
    beneficiariesSplit,
    fundData,
    fundSplit,
    investmentSetupInitial,
    investmentSetupContribution,
    paymentDetails,
    persistedAt,
    personalDetails,
    referenceData,
    review,
    routing,
    scrolling,
    selectAFund,
    sessionCamVariables,
    auxiliaryDetails,
});
