
import { CALL_ME_BACK_SEND } from "../../../actions/easiplusFuneralPlan/callMeBack";
import { EFP_QUOTE_CREATE_QUOTE_SEND } from "../../../actions/easiplusFuneralPlan/quote";
import { TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS } from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { NAVIGATE_NEXT } from "../../../actions/easiplusFuneralPlan/routing";
import {
    EFP_APPLICATION_REFERENCE_DATA_GET_VALUE,
    EFP_QUOTE_REFERENCE_DATA_GET_VALUE,
} from "../../../actions/easiplusFuneralPlan/referenceData";
import {SIDEDRAWER_SET_VALUE} from '@om/redux/actions/easiplusFuneralPlan/quote';
import { EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND } from "../../../actions/easiplusFuneralPlan/confirmDetails";
import { getIsDropoffPage } from "../../../selectors/easiplusFuneralPlan/routing";
import { referenceDataService } from "./referenceData";
import { submitEfpApplicationService } from "./submitApplication";
import { dropOffLeadsService } from "./dropOffLead";
import { callMeBackService } from "./callMeBack"
import { createEfpQuoteService } from "./quote";

import { performAccountValidation } from "./accountValidation";


export const efpServices = (store, next, action) => {
    if (action.type === NAVIGATE_NEXT && getIsDropoffPage(store.getState()))
        return dropOffLeadsService(store, next, action);

    if (action.type === EFP_APPLICATION_REFERENCE_DATA_GET_VALUE ||
        action.type === EFP_QUOTE_REFERENCE_DATA_GET_VALUE ||
        action.type === SIDEDRAWER_SET_VALUE)
        return referenceDataService(store, next, action);

    if (action.type === CALL_ME_BACK_SEND)
        return callMeBackService(store, next, action);

    if (action.type.startsWith(EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND))
        return submitEfpApplicationService(store, next, action);

    if (action.type === EFP_QUOTE_CREATE_QUOTE_SEND)
        return createEfpQuoteService(store, next, action);

    if (action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS)
        return performAccountValidation(store, next, action);

    return null;
}
