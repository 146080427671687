import { findOtherEventIndex, otherEventTracking } from '../otherEventTracking';
import { FUND_SELECTION_SET_VALUE } from '../../../actions/fundDeclarations/fundDeclarations';

export const fundDeclarationFieldTracking = (store, next, action) => {
    const result = next(action);

    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case FUND_SELECTION_SET_VALUE:
                otherEventIndex = findOtherEventIndex('SELECT');
                return 'FundDeclarationsSelectFund';
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}