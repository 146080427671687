export const BRANCH_DATA_VALUE = 'publicWeb/branchLocator/branchData';
export const VISIBLE_BRANCHES_VALUE = 'publicWeb/branchLocator/visibileBranches';
export const SELECTED_BRANCH_VALUE = 'publicWeb/branchLocator/selectedBranch';
export const SEARCH_CRITERIA_VALUE = 'publicWeb/branchLocator/searchCriteria';

export const FORMATTED_ADDRESS_VALUE = 'publicWeb/branchLocator/formattedAddress';
export const LOCATION_TYPE_VALUE = 'publicWeb/branchLocator/locationType';
export const USER_LOCATION_VALUE = 'publicWeb/branchLocator/userLocation';
export const SERVICE_TYPE_VALUE = 'publicWeb/branchLocator/serviceType';

export const setBranchDataValue = value => ({type: BRANCH_DATA_VALUE, value});
export const setVisibileBranchesValue = value => ({type: VISIBLE_BRANCHES_VALUE, value});
export const setSelectedBranchValue = value => ({type: SELECTED_BRANCH_VALUE, value});
export const setSearchCriteriaValue = value => ({type: SEARCH_CRITERIA_VALUE, value});

export const setFormattedAddressValue = value => ({type: FORMATTED_ADDRESS_VALUE, value});
export const setLocationTypeValue = value => ({type: LOCATION_TYPE_VALUE, value});
export const setUserLocationValue = value => ({type: USER_LOCATION_VALUE, value});
export const setServiceTypeValue = value => ({type: SERVICE_TYPE_VALUE, value});