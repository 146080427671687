import { SCHEDULE_FILE_FOR_UPLOAD, REMOVE_FILE_FROM_UPLOAD, FILE_UPLOAD_VALIDATION, FILE_UPLOAD_ERROR, DOCUMENT_MODAL } from '../../actions/digitalOnboarding/types';

const calculateReadableFileSize = size => {
    if (size < 1024) {
        return `${size}B`;
    }
    const valueInKB = size / 1024;
    if (valueInKB < 1024) {
        return `${valueInKB.toFixed()}KB`;
    }
    const valueInMB = valueInKB / 1024;
    return `${+valueInMB.toFixed()}MB`;
};

const buildFileSectionDetail = file => ({
    file: file,
    extension: file?.name.split('.').pop() ?? '',
    size: calculateReadableFileSize(file?.size ?? 0),
    errorMessage: '',
    hasError: false
});

const defaultFileUploadAreas = [{
    sectionName: 'Identity document',
    key: 'id-document',
    ...buildFileSectionDetail(null)
}, {
    sectionName: 'Bank statements',
    key: 'bank-statement',
    ...buildFileSectionDetail(null)
}, {
    sectionName: 'Latest payslip',
    key: 'payslip',
    ...buildFileSectionDetail(null)
}];

export const initDocumentStore = {
    maxFileSize: 5 * 1024 * 1024,
    humanReadableMax: '5MB',
    fileTooLargeErrorMessage: 'Document uploaded too big, please upload document of size 5MB or less.',
    allowedFileExtensions: ['.pdf', '.jpeg', '.jpg', '.png', '.tif', '.tiff'],
    fileQueue: defaultFileUploadAreas,
    documentsModal: false
};

const fileMatches = lhs => rhs => lhs.sectionName === rhs.sectionName;

const resolveFileQueueList = (files, newFileContext) => {
    const matcher = fileMatches(newFileContext);
    return files.map(file => {
        if (matcher(file)) {
            return { ...file, ...buildFileSectionDetail(newFileContext.file) };
        }
        return file;
    });
}

const removeFileFromQueue = (files, sectionName) => {
    const matcher = fileMatches({ sectionName });

    return files.map(file => {
        if (matcher(file)) {
            return { ...file, ...buildFileSectionDetail(null) };
        }
        return file;
    });
}

const applyValidations = (files, validations) => {
    const validatedFiles = [...files];
    if(validations.length > 0){
        validations.forEach(v => {
            const file = validatedFiles.find(fileMatches(v));
            file.errorMessage = v.errorMessage;
            file.hasError = true;
        });
    }
    return validatedFiles;
}

const applyUploadErrors = (files, encryptedFiles) =>{
    const uploadedFiles = [...files];
    encryptedFiles.forEach(x => {
        const file = uploadedFiles.find(f => f.key === x.key);
        if (x.encrypted) {
            file.errorMessage = 'Unable to upload password protected document.';
            file.hasError = true;
        } else if (x.hasError) {
            file.errorMessage = 'Upload failed, please try again.';
            file.hasError = true;
        }
    });
    return uploadedFiles;
}

export default (state = initDocumentStore, action) => {

    switch (action.type) {
        case SCHEDULE_FILE_FOR_UPLOAD: {
            return { ...state, fileQueue: resolveFileQueueList(state.fileQueue, action) };
        }
        case REMOVE_FILE_FROM_UPLOAD: {
            return { ...state, fileQueue: removeFileFromQueue(state.fileQueue, action.sectionName) };
        }
        case FILE_UPLOAD_VALIDATION: {
            return { ...state, fileQueue: applyValidations(state.fileQueue, action.results) }
        }
        case FILE_UPLOAD_ERROR: {
            return { ...state, fileQueue: applyUploadErrors(state.fileQueue, action.files) }
        }
        case DOCUMENT_MODAL: {
            return { ...state, documentsModal: action.boolean }
        }
    }

    return state;
};
