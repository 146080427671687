import {FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS} from "../status";
import {
    FORM_VALID_TRANSITION_PERSONAL_DETAILS,
    FORM_INVALID_TRANSITION_PERSONAL_DETAILS,
    POSTAL_ADDRESS_METHOD_VALUE,
    POSTAL_ADDRESS_METHOD_VALID,
    POSTAL_ADDRESS_SAME_VALUE,
    POSTAL_ADDRESS_SAME_VALID,
    TITLE_SET_VALUE,
    TITLE_SET_VALID,
    MIDDLE_NAME_SET_VALUE,
    MIDDLE_NAME_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    MARITAL_STATUS_SET_VALUE,
    MARITAL_STATUS_SET_VALID,
    COUNTRY_OF_BIRTH_SET_VALUE,
    COUNTRY_OF_BIRTH_SET_VALID,
    NATIONALITY_SET_VALUE,
    NATIONALITY_SET_VALID,
    ID_SET_VALUE,
    ID_SET_VALID,
    INCOME_TAX_SET_VALUE,
    INCOME_TAX_SET_VALID,
    COMPLEX_SET_VALUE,
    COMPLEX_SET_VALID,
    STREET_ADDRESS_SET_VALUE,
    STREET_ADDRESS_SET_VALID,
    SUBURB_SET_VALUE,
    SUBURB_SET_VALID,
    CITY_SET_VALUE,
    CITY_SET_VALID,
    POSTAL_CODE_SET_VALUE,
    POSTAL_CODE_SET_VALID,
    COUNTRY_SET_VALUE,
    COUNTRY_SET_VALID,
    PO_BOX_SET_VALUE,
    PO_BOX_SET_VALID,
    PO_POST_OFFICE_SET_VALUE,
    PO_POST_OFFICE_SET_VALID,
    PO_POSTAL_CODE_SET_VALUE,
    PO_POSTAL_CODE_SET_VALID,
    PO_COUNTRY_SET_VALUE,
    PO_COUNTRY_SET_VALID,
    PRIVATE_BAG_SET_VALUE,
    PRIVATE_BAG_SET_VALID,
    PRIVATE_POST_OFFICE_SET_VALUE,
    PRIVATE_POST_OFFICE_SET_VALID,
    PRIVATE_POSTAL_CODE_SET_VALUE,
    PRIVATE_POSTAL_CODE_SET_VALID,
    PRIVATE_COUNTRY_SET_VALUE,
    PRIVATE_COUNTRY_SET_VALID,
    POSTAL_COMPLEX_SET_VALUE,
    POSTAL_COMPLEX_SET_VALID,
    POSTAL_STREET_ADDRESS_SET_VALUE,
    POSTAL_STREET_ADDRESS_SET_VALID,
    POSTAL_SUBURB_SET_VALUE,
    POSTAL_SUBURB_SET_VALID,
    POSTAL_CITY_SET_VALUE,
    POSTAL_CITY_SET_VALID,
    POSTAL_POSTAL_CODE_SET_VALUE,
    POSTAL_POSTAL_CODE_SET_VALID,
    POSTAL_COUNTRY_SET_VALUE,
    POSTAL_COUNTRY_SET_VALID,
    PO_BOX_CLEAR_VALUE,
    PO_POST_OFFICE_CLEAR_VALUE,
    PO_POSTAL_CODE_CLEAR_VALUE,
    PO_COUNTRY_CLEAR_VALUE,
    PRIVATE_BAG_CLEAR_VALUE,
    PRIVATE_POST_OFFICE_CLEAR_VALUE,
    PRIVATE_POSTAL_CODE_CLEAR_VALUE,
    PRIVATE_COUNTRY_CLEAR_VALUE,
    POSTAL_COMPLEX_CLEAR_VALUE,
    POSTAL_STREET_ADDRESS_CLEAR_VALUE,
    POSTAL_SUBURB_CLEAR_VALUE,
    POSTAL_CITY_CLEAR_VALUE,
    POSTAL_POSTAL_CODE_CLEAR_VALUE,
    POSTAL_COUNTRY_CLEAR_VALUE,
    GCS_STREET_ADDRESS_SET_VALUE,
    GCS_POSTAL_ADDRESS_SET_VALUE,
    RESET_PERSONAL_DETAILS,
} from "../../actions/raOptimal/personalDetails";

const initCountryCode = 'SouthAfrica';
export const initCountry = 'South Africa';
export const initPersonalDetails = {
    formStatus: FORM_STATUS.INVALID,
    gCSStreetAddress: null,
    gCSPostalAddress: null,
    form: {
        postalAddressMethod: { value: TOGGLE_STATUS.UNTOUCHED, error: null, status: null },
        postalAddressSame: { value: TOGGLE_STATUS.YES, error: null, status: FORM_STATUS.VALID },
        title: { value: null, error: null, status: null },
        middleName: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        maritalStatus: { value: null, error: null, status: null },
        countryOfBirth: { value: null, error: null, status: null },
        nationality: { value: null, error: null, status: null },
        id: { value: null, error: null, status: null },
        incomeTax: { value: null, error: null, status: null },
        complex: { value: null, error: null, status: null },
        streetAddress: { value: null, error: null, status: null },
        suburb: { value: null, error: null, status: null },
        city: { value: null, error: null, status: null },
        postalCode: { value: null, error: null, status: null },
        country: { value: initCountry, error: null, status: FORM_STATUS.VALID },

        poBox: { value: null, error: null, status: null },
        poPostOffice: { value: null, error: null, status: null },
        poPostalCode: { value: null, error: null, status: null },
        poCountry: { value: initCountry, error: null, status: FORM_STATUS.VALID },

        privateBag: { value: null, error: null, status: null },
        privatePostOffice: { value: null, error: null, status: null },
        privatePostalCode: { value: null, error: null, status: null },
        privateCountry: { value: initCountry, error: null, status: FORM_STATUS.VALID },

        postalComplex: { value: null, error: null, status: null },
        postalStreetAddress: { value: null, error: null, status: null },
        postalSuburb: { value: null, error: null, status: null },
        postalCity: { value: null, error: null, status: null },
        postalPostalCode: { value: null, error: null, status: null },
        postalCountry: { value: initCountry, error: null, status: FORM_STATUS.VALID },
    },
};

export default (state = initPersonalDetails, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const clearError = () =>  FIELD_STATUS.UNTOUCHED;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_PERSONAL_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_PERSONAL_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Postal Address Method
        case POSTAL_ADDRESS_METHOD_VALUE:
            return updateFormField('postalAddressMethod', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_ADDRESS_METHOD_VALID:
            return updateFormField('postalAddressMethod', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Postal Address Same as residential address
        case POSTAL_ADDRESS_SAME_VALUE:
            return updateFormField('postalAddressSame', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_ADDRESS_SAME_VALID:
            return updateFormField('postalAddressSame', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Title
        case TITLE_SET_VALUE:
            return updateFormField('title', field => ({
                ...field,
                value: action.value,
            }));
        case TITLE_SET_VALID:
            return updateFormField('title', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Middle name
        case MIDDLE_NAME_SET_VALUE:
            return updateFormField('middleName', field => ({
                ...field,
                value: action.value,
            }));
        case MIDDLE_NAME_SET_VALID:
            return updateFormField('middleName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Email
        case EMAIL_SET_VALUE:
            return updateFormField('email', field => ({
                ...field,
                value: action.value,
            }));
        case EMAIL_SET_VALID:
            return updateFormField('email', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Marital Status
        case MARITAL_STATUS_SET_VALUE:
            return updateFormField('maritalStatus', field => ({
                ...field,
                value: action.value,
            }));
        case MARITAL_STATUS_SET_VALID:
            return updateFormField('maritalStatus', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Birth Country
        case COUNTRY_OF_BIRTH_SET_VALUE:
            return updateFormField('countryOfBirth', field => ({
                ...field,
                value: action.value,
            }));
        case COUNTRY_OF_BIRTH_SET_VALID:
            return updateFormField('countryOfBirth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Nationality
        case NATIONALITY_SET_VALUE:
            return updateFormField('nationality', field => ({
                ...field,
                value: action.value,
            }));
        case NATIONALITY_SET_VALID:
            return updateFormField('nationality', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // ID Number
        case ID_SET_VALUE:
            return updateFormField('id', field => ({
                ...field,
                value: action.value,
            }));
        case ID_SET_VALID:
            return updateFormField('id', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // ID Number
        case INCOME_TAX_SET_VALUE:
            return updateFormField('incomeTax', field => ({
                ...field,
                value: action.value,
            }));
        case INCOME_TAX_SET_VALID:
            return updateFormField('incomeTax', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Address - complex
        case COMPLEX_SET_VALUE:
            return updateFormField('complex', field => ({
                ...field,
                value: action.value,
            }));
        case COMPLEX_SET_VALID:
            return updateFormField('complex', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Address - street address
        case STREET_ADDRESS_SET_VALUE:
            return updateFormField('streetAddress', field => ({
                ...field,
                value: action.value,
            }));
        case STREET_ADDRESS_SET_VALID:
            return updateFormField('streetAddress', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Address - suburb
        case SUBURB_SET_VALUE:
            return updateFormField('suburb', field => ({
                ...field,
                value: action.value,
            }));
        case SUBURB_SET_VALID:
            return updateFormField('suburb', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Address - city
        case CITY_SET_VALUE:
            return updateFormField('city', field => ({
                ...field,
                value: action.value,
            }));
        case CITY_SET_VALID:
            return updateFormField('city', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Address - postal code
        case POSTAL_CODE_SET_VALUE:
            return updateFormField('postalCode', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_CODE_SET_VALID:
            return updateFormField('postalCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Address - country
        case COUNTRY_SET_VALUE:
            return updateFormField('country', field => ({
                ...field,
                value: action.value,
            }));
        case COUNTRY_SET_VALID:
            return updateFormField('country', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Postal method - PO Box - Po box
        case PO_BOX_SET_VALUE:
            return updateFormField('poBox', field => ({
                ...field,
                value: action.value,
            }));
        case PO_BOX_SET_VALID:
            return updateFormField('poBox', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PO_BOX_CLEAR_VALUE:
            return updateFormField('poBox', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - PO Box - Post Office
        case PO_POST_OFFICE_SET_VALUE:
            return updateFormField('poPostOffice', field => ({
                ...field,
                value: action.value,
            }));
        case PO_POST_OFFICE_SET_VALID:
            return updateFormField('poPostOffice', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PO_POST_OFFICE_CLEAR_VALUE:
            return updateFormField('poPostOffice', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - PO Box - Postal Code
        case PO_POSTAL_CODE_SET_VALUE:
            return updateFormField('poPostalCode', field => ({
                ...field,
                value: action.value,
            }));
        case PO_POSTAL_CODE_SET_VALID:
            return updateFormField('poPostalCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PO_POSTAL_CODE_CLEAR_VALUE:
            return updateFormField('poPostalCode', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - PO Box - Country
        case PO_COUNTRY_SET_VALUE:
            return updateFormField('poCountry', field => ({
                ...field,
                value: action.value,
            }));
        case PO_COUNTRY_SET_VALID:
            return updateFormField('poCountry', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PO_COUNTRY_CLEAR_VALUE:
            return updateFormField('poCountry', field => ({
                ...field,
                value: initCountry,
                error: null,
                status: FORM_STATUS.VALID,
            }));

        // Postal method - Private Bag - Private Bag Number
        case PRIVATE_BAG_SET_VALUE:
            return updateFormField('privateBag', field => ({
                ...field,
                value: action.value,
            }));
        case PRIVATE_BAG_SET_VALID:
            return updateFormField('privateBag', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PRIVATE_BAG_CLEAR_VALUE:
            return updateFormField('privateBag', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - Private Bag - Post Office
        case PRIVATE_POST_OFFICE_SET_VALUE:
            return updateFormField('privatePostOffice', field => ({
                ...field,
                value: action.value,
            }));
        case PRIVATE_POST_OFFICE_SET_VALID:
            return updateFormField('privatePostOffice', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PRIVATE_POST_OFFICE_CLEAR_VALUE:
            return updateFormField('privatePostOffice', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - Private Bag - Postal Code
        case PRIVATE_POSTAL_CODE_SET_VALUE:
            return updateFormField('privatePostalCode', field => ({
                ...field,
                value: action.value,
            }));
        case PRIVATE_POSTAL_CODE_SET_VALID:
            return updateFormField('privatePostalCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PRIVATE_POSTAL_CODE_CLEAR_VALUE:
            return updateFormField('privatePostalCode', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - Private bag - Country
        case PRIVATE_COUNTRY_SET_VALUE:
            return updateFormField('privateCountry', field => ({
                ...field,
                value: action.value,
            }));
        case PRIVATE_COUNTRY_SET_VALID:
            return updateFormField('privateCountry', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PRIVATE_COUNTRY_CLEAR_VALUE:
            return updateFormField('privateCountry', field => ({
                ...field,
                value: initCountry,
                error: null,
                status: FORM_STATUS.VALID,
            }));

        // Postal method - Street Address - complex
        case POSTAL_COMPLEX_SET_VALUE:
            return updateFormField('postalComplex', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_COMPLEX_SET_VALID:
            return updateFormField('postalComplex', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case POSTAL_COMPLEX_CLEAR_VALUE:
            return updateFormField('postalComplex', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        //  Postal method - Street Address - street address
        case POSTAL_STREET_ADDRESS_SET_VALUE:
            return updateFormField('postalStreetAddress', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_STREET_ADDRESS_SET_VALID:
            return updateFormField('postalStreetAddress', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case POSTAL_STREET_ADDRESS_CLEAR_VALUE:
            return updateFormField('postalStreetAddress', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - Street Address - suburb
        case POSTAL_SUBURB_SET_VALUE:
            return updateFormField('postalSuburb', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_SUBURB_SET_VALID:
            return updateFormField('postalSuburb', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case POSTAL_SUBURB_CLEAR_VALUE:
            return updateFormField('postalSuburb', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - Street Address - city
        case POSTAL_CITY_SET_VALUE:
            return updateFormField('postalCity', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_CITY_SET_VALID:
            return updateFormField('postalCity', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case POSTAL_CITY_CLEAR_VALUE:
            return updateFormField('postalCity', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - Street Address - postal code
        case POSTAL_POSTAL_CODE_SET_VALUE:
            return updateFormField('postalPostalCode', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_POSTAL_CODE_SET_VALID:
            return updateFormField('postalPostalCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case POSTAL_POSTAL_CODE_CLEAR_VALUE:
            return updateFormField('postalPostalCode', field => ({
                ...field,
                value: null,
                error: null,
                status: clearError(),
            }));

        // Postal method - Street Address - country
        case POSTAL_COUNTRY_SET_VALUE:
            return updateFormField('postalCountry', field => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_COUNTRY_SET_VALID:
            return updateFormField('postalCountry', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case POSTAL_COUNTRY_CLEAR_VALUE:
            return updateFormField('postalCountry', field => ({
                ...field,
                value: initCountry,
                error: null,
                status: FORM_STATUS.VALID,
            }));

        case GCS_STREET_ADDRESS_SET_VALUE:
            return { ...state, gCSStreetAddress: action.value }

        case GCS_POSTAL_ADDRESS_SET_VALUE:
            return { ...state, gCSPostalAddress: action.value }

        case RESET_PERSONAL_DETAILS:
            return { ...initPersonalDetails };

        default:
            return state;
    }
};
