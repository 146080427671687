import { transitionPersonalAccidentChildDetailsForm } from '../../../actions/personalAccident/childDetails';
import { getChildDetailsForms } from '../../../selectors/personalAccident/childDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const childDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const childDetailsForms = getChildDetailsForms(store.getState());

    const childDetailsFormValid = validateChildDetailsFormDetails(childDetailsForms[action.index]);

    const formAction = childDetailsFormValid
        ? transitionPersonalAccidentChildDetailsForm.formValid(action.index)
        : transitionPersonalAccidentChildDetailsForm.formInvalid(action.index);
    store.dispatch(formAction);
    return result;
};

const validateChildDetailsFormDetails = (formFields) => {
    return (
        formFields &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.streetAddress &&
        formFields.streetAddress.status === FIELD_STATUS.VALID &&
        formFields.suburb &&
        formFields.suburb.status === FIELD_STATUS.VALID &&
        formFields.city &&
        formFields.city.status === FIELD_STATUS.VALID
    );
};
