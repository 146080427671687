import {
    ROA_APPLICATION_REFERENCE_DATA_SET_VALUE,
    ROA_COUNTRIES_REFERENCE_DATA_SET_VALUE,
} from '../../actions/roaTravelInsurance/referenceData.js';

export const initReferenceData = {
    applicationData: {},
};

export default (state = initReferenceData, action) => {
    switch (action.type) {
        case ROA_APPLICATION_REFERENCE_DATA_SET_VALUE:
            return {
                ...state,
                applicationData: { ...state.applicationData, ...action.value },
            };

        case ROA_COUNTRIES_REFERENCE_DATA_SET_VALUE:
            return {
                ...state,
                applicationData: {
                    ...state.applicationData,
                    countries: action.value,
                },
            };
        default:
            return state;
    }
};
