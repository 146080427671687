import {validationIsEqual} from "../status";
import { 
    // validateName,
    validateDateOfBirth,
    // validateEmail,
    // validateCellphoneNumber,
    validateSingleSelection, 
    validateNumber,
    // validateCoverYears,
    validateLoanTerm
} from '@om/validation';
import {
    // DIGILIFE_FULL_NAME_SET_VALUE,
    // DIGILIFE_CONTACT_SET_VALUE,
    // DIGILIFE_EMAIL_SET_VALUE,
    DIGILIFE_GENDER_SET_VALUE,
    DIGILIFE_DOB_SET_VALUE,
    DIGILIFE_SALARY_SET_VALUE,
    DIGILIFE_EDUCATION_SET_VALUE,
    DIGILIFE_LOAN_AMOUNT_SET_VALUE,
    DIGILIFE_LOAN_TERM_SET_VALUE,
    DIGILIFE_LOAN_TYPE_SET_VALUE,
    DIGILIFE_PAYMENT_SET_VALUE,
    // setFullNameValid,
    // setContactNumberValid,
    // setEmailIdValid,
    setGenderValid,
    setDateOfBirthValid,
    setSalaryValid,
    setEducationValid,
    setLoanTypeValid,
    setLoanAmountValid,
    setLoanTermValid,
    setPaymentValid,
    setCashBackValid,
    DIGILIFE_CASHBACK_SET_VALID,
    DIGILIFE_CASHBACK_SET_VALUE,
} from '../../../actions/roaQuoteTools/digiLifeQuoteTool';


let prevValidationAction = null;

export const digiLifeQuoteToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            // case DIGILIFE_FULL_NAME_SET_VALUE:
            //     error = validateName(action.value, {min:3, max:40});
            //     return setFullNameValid(error);

            // case DIGILIFE_CONTACT_SET_VALUE:
            //     error = validateCellphoneNumber(action.value, {subject:'Contact Number', countryCode: 'bw'});
            //     return setContactNumberValid(error);

            // case DIGILIFE_EMAIL_SET_VALUE:
            //     error = validateEmail(action.value);
            //     return setEmailIdValid(error);

            case DIGILIFE_DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, {min:18, max:65});
                return setDateOfBirthValid(error);

            case DIGILIFE_GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, 'gender');
                return setGenderValid(error);

            case DIGILIFE_SALARY_SET_VALUE:
                error = validateSingleSelection(action.value, 'salary');
                return setSalaryValid(error);

            case DIGILIFE_EDUCATION_SET_VALUE:
                error = validateSingleSelection(action.value, 'education');
                return setEducationValid(error);
            
            case DIGILIFE_LOAN_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value, 'loanType');
                return setLoanTypeValid(error);
            
            case DIGILIFE_LOAN_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, {min:1000, max:1000000});
                return setLoanAmountValid(error);

            case DIGILIFE_LOAN_TERM_SET_VALUE:
                const {roaQuoteTools:{digiLifeQuoteTool: {form:{DOB, loanType}}}} = store.getState()
                if(loanType.value === '01'){
                    error = validateLoanTerm(action.value, { min:6, max: 96, dateValue:DOB.value, termExpiry: 65, pronoun: 'your Motor Vehicle Loan'});
                } else if(loanType.value === '02'){
                    error = validateLoanTerm(action.value, { min:60, max:360, dateValue:DOB.value, termExpiry: 65, pronoun: 'your Mortgage Loan' });
                } else {
                    error = validateLoanTerm(action.value, { min:6, max:120, dateValue:DOB.value, termExpiry: 65, pronoun: 'your Personal Loan' });   
                }
                return setLoanTermValid(error);
                
            case DIGILIFE_PAYMENT_SET_VALUE:
                error = validateSingleSelection(action.value, 'payment');
                return setPaymentValid(error);

            case DIGILIFE_CASHBACK_SET_VALUE:
                error = validateSingleSelection(action.value, 'cashBack');
                return setCashBackValid(error);
    
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};

