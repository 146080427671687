import { getShareQuoteAPIStatus, getQuoteForm, getPremiumCalculation } from '../../../selectors/digitalSavings/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure,
} from '../../../actions/digitalSavings/quote';
import { getAccessToken } from '../../../selectors/digitalSavings/payment';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        // if (!getShareQuoteAPIStatus(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        // }
    })();
    return next(action);
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);
    const premium = getPremiumCalculation(state);

    const payload = {
        getQuoteEmail: quotation.shareEmail.value,
        investorType:
            quotation.attitude.value === '2'
                ? 'comfortable'
                : 'adventurous',
        investmentPlan: quotation.saving_category.value,
        savingStartDate: quotation.startdate.value,
        monthlyContribution: quotation.desired.value || 0,
        totalPrincipalAmount: premium.premium.totalPrincipalAmount,
        potentialSavingsMin: premium.premium.potentialSavingsMin,
        potentialSavingsMax: premium.premium.potentialSavingsMax,
        lumpsumContribution: quotation.lumpsum.value || 0,
        savingDuration: quotation.duration.value ? quotation.duration.value * 12 : 0,
        countryCode: "KE"
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/digital-savings/share-quote';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
