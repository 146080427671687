import { createSelector } from 'reselect';
import { getDigitalSavingsState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getBeneficiaries = createSelector(getDigitalSavingsState, (state) => state.beneficiaries);

export const getBeneficiariesForms = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.form);

export const getBeneficiariesFormValid = createSelector(getBeneficiaries, (beneficiaries) => {
    const { formStatus } = beneficiaries;

    if (formStatus.length > 1) {
        const allValid = formStatus.every((status) => status === FORM_STATUS.VALID);
        return allValid;
    } else if (formStatus.length === 1) {
        return formStatus[0] === FORM_STATUS.VALID;
    } else {
        return true;
    }
});

export const getSingleBeneficiaryFormValid = (index) =>
    createSelector(getBeneficiaries, (beneficiaries) => {
        const { formStatus } = beneficiaries;

        return formStatus[index] === FORM_STATUS.VALID;
    });

    export const getBeneficiaryFormValidArray =
        createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.formStatus);

export const getSelectedBeneficiariesFormIndex = createSelector(
    getBeneficiaries,
    (beneficiaries) => beneficiaries.selectedFormIndex,
);

const makeGetFieldForm = (fieldId, index) => createSelector(getBeneficiariesForms, (form) => form[index][fieldId]);

export const getFirstNameValue = (index) => makeGetFieldForm('firstName', index);
export const getLastNameValue = (index) => makeGetFieldForm('lastName', index);
export const getFirstNameMinorValue = (index) => makeGetFieldForm('firstNameMinor', index);
export const getLastNameMinorValue = (index) => makeGetFieldForm('lastNameMinor', index);
export const getIsMinorValue = (index) => makeGetFieldForm('isMinor', index);
export const getIdNumberValue = (index) => makeGetFieldForm('idNumber', index);
export const getRelationshipValue = (index) => makeGetFieldForm('relationship', index);
export const getLocationValue = (index) => makeGetFieldForm('location', index);
export const getLocationMinorValue = (index) => makeGetFieldForm('locationMinor', index);
export const getPhoneNumberValue = (index) => makeGetFieldForm('phoneNumber', index);
export const getEmailValue = (index) => makeGetFieldForm('email', index);
export const getPercentageSplitValue = (index) => makeGetFieldForm('percentageSplit', index);
export const getPersonalDataProcessingConsent = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.personalDataProcessingConsent);
export const getChildDataProcessingConsent = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.childDataProcessingConsent);
export const getMarketingUseConsent = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.marketingUseConsent);
export const getPersonalDataTerms = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.personalDataTerms);
