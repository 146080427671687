// Transitions
export const FORM_VALID_TRANSITION_PERSONAL_DETAILS =
  "allsureInsurance/personalDetails/transition/formValid";
export const FORM_INVALID_TRANSITION_PERSONAL_DETAILS =
  "allsureInsurance/personalDetails/transition/formInvalid";


export const transitionPersonalDetails = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_PERSONAL_DETAILS }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_PERSONAL_DETAILS }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_PERSONAL_DETAILS =
  "allsureInsurance/personalDetails/transition/resetState";
export const resetPersonalDetails = () => ({
  type: RESET_STATE_TRANSITION_PERSONAL_DETAILS,
});

// Events


export const ALLSURE_INSURANCE_PERSONAL_DETAILS =
  "allsureInsurance/personalDetails/";
export const PERSONAL_DETAILS_SET_VALUE =
ALLSURE_INSURANCE_PERSONAL_DETAILS+"setValue/";
export const PERSONAL_DETAILS_SET_VALID =
ALLSURE_INSURANCE_PERSONAL_DETAILS +"setValid/";
export const PERSONAL_DETAILS_EDIT =
ALLSURE_INSURANCE_PERSONAL_DETAILS +"setEdit";

// export const TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE =
// ALLSURE_INSURANCE_INSURANCE_TYPE+"toogle/privacyNotice";

// export const  tooglePrivacyNotice=(value)=>({type: TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE, value})
export const setPersonalDetailsValue =(value)=>({type: PERSONAL_DETAILS_SET_VALUE, value})
export const setPersonalDetailsFieldValid =(error,fieldType)=>({type: PERSONAL_DETAILS_SET_VALID, error, fieldType})
export const setPersonalDetailsEdit =(value)=>({type: PERSONAL_DETAILS_EDIT, value})

