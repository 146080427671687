import {
    EVEN_SPLIT_DESELECT_FUND_SPLIT,
    EVEN_SPLIT_SELECT_FUND_SPLIT,
    EVEN_SPLIT_DISCARD_FUND_SPLIT,
    SPLIT_PERCENTAGE_SET_VALUE,
    DELETE_FUND_FUND_SPLIT,
} from '../../../actions/taxFreeSavingsAccount/fundSplit';
import { otherEventTracking, findOtherEventIndex } from '../otherEventTracking';
import { FUND_SPLIT_FORM, FUND_SPLIT_TYPE } from '../../../actions/taxFreeSavingsAccount/types';

export const fundSplitOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    let returnValue;
    const field = (() => {
        switch (action.type) {
            case SPLIT_PERCENTAGE_SET_VALUE:
                otherEventIndex = findOtherEventIndex('EDIT_PAGE');
                returnValue =
                    action.fundType === FUND_SPLIT_FORM.REGULAR
                        ? FUND_SPLIT_FORM.REGULAR +
                          ', Fund: ' +
                          action.fundId +
                          ', Percentage Split: ' +
                          action.value +
                          ', Contribution: ' +
                          action.contribution
                        : FUND_SPLIT_FORM.LUMP_SUM +
                          ', Fund: ' +
                          action.fundId +
                          ', Percentage Split: ' +
                          action.value +
                          ', Contribution: ' +
                          action.contribution;
                return returnValue;
            case EVEN_SPLIT_SELECT_FUND_SPLIT:
                otherEventIndex = findOtherEventIndex('TOGGLE_CHECKBOX');
                returnValue =
                    action.fundType === FUND_SPLIT_TYPE.REGULAR
                        ? 'regularEvenSplitSelected'
                        : 'lumpEvenSplitSelected';
                return returnValue;
            case EVEN_SPLIT_DESELECT_FUND_SPLIT:
                otherEventIndex = findOtherEventIndex('TOGGLE_CHECKBOX');
                returnValue =
                    action.fundType === FUND_SPLIT_TYPE.REGULAR
                        ? 'regularEvenSplitDeselected'
                        : 'lumpEvenSplitDeselected';
                return returnValue;
            case EVEN_SPLIT_DISCARD_FUND_SPLIT:
                otherEventIndex = findOtherEventIndex('TOGGLE_CHECKBOX');
                returnValue =
                    action.fundType === FUND_SPLIT_TYPE.REGULAR
                        ? 'regularEvenSplitDiscarded'
                        : 'lumpEvenSplitDiscarded';
                return returnValue;
            case DELETE_FUND_FUND_SPLIT:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'fundDeleted';
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};
