//Calculation actions

export const REPAYMENT_CALCULATION = 'debtRepayment/calculator/calculate/';

export const CALCULATE_DEBT_REPAYMENT = REPAYMENT_CALCULATION + 'calculation'
export const CALCULATE_DEBT_REPAYMENT_SUCCESS = REPAYMENT_CALCULATION + 'success';
export const CALCULATE_DEBT_REPAYMENT_FAILURE = REPAYMENT_CALCULATION + 'failure';

export const DEBT_CALCULATOR_SET_VALUE = 'debtRepayment/calculator/setValue/';
export const DEBT_CALCULATOR_SET_VALID = 'debtRepayment/calculator/setValid/';

export const HOW_MUCH_OWED_SET_VALUE = DEBT_CALCULATOR_SET_VALUE + 'howMuchOwed';
export const HOW_MUCH_OWED_SET_VALID = DEBT_CALCULATOR_SET_VALID + 'howMuchOwed';
export const INTEREST_RATE_SET_VALUE = DEBT_CALCULATOR_SET_VALUE + 'interestRate';
export const INTEREST_RATE_SET_VALID = DEBT_CALCULATOR_SET_VALID + 'interestRate';
export const MONTHLY_REPAYMENT_SET_VALUE = DEBT_CALCULATOR_SET_VALUE + 'monthlyRepayment';
export const MONTHLY_REPAYMENT_SET_VALID = DEBT_CALCULATOR_SET_VALID + 'monthlyRepayment';
export const BUTTON_DISABLED_SET_VALUE = DEBT_CALCULATOR_SET_VALUE + 'calculateButtonDisabled';
export const BUTTON_DISABLED_SET_VALID = DEBT_CALCULATOR_SET_VALID + 'calculateButtonDisabled';
export const RESULT_CALCULATED_SET_VALUE = DEBT_CALCULATOR_SET_VALUE + 'resultCalculated';

export const calculateDebtRepayment = () => ({type: CALCULATE_DEBT_REPAYMENT});
export const calculateDebtRepaymentSuccess = (value) => ({type: CALCULATE_DEBT_REPAYMENT_SUCCESS, value});
export const calculateDebtRepaymentFailure = (error) => ({type: CALCULATE_DEBT_REPAYMENT_FAILURE, error});

export const CALCULATE_DEBT_RESULTS = DEBT_CALCULATOR_SET_VALUE + 'calculateDebtResults';
export const calculateDebtResults = (value) => ({type: CALCULATE_DEBT_RESULTS, value});

export const RESET_DEBT_RESULTS = REPAYMENT_CALCULATION + 'reset';
export const resetDebtResults = () => ({type: RESET_DEBT_RESULTS});

export const setHowMuchOwedValue = (value) => ({type: HOW_MUCH_OWED_SET_VALUE, value});
export const setHowMuchOwedValid = (error) => ({type: HOW_MUCH_OWED_SET_VALID, error});
export const setInterestRateValue = (value) => ({type: INTEREST_RATE_SET_VALUE, value});
export const setInterestRateValid = (error) => ({type: INTEREST_RATE_SET_VALID, error});
export const setMonthlyRepaymentValue = (value) => ({type: MONTHLY_REPAYMENT_SET_VALUE, value});
export const setMonthlyRepaymentValid = (error) => ({type: MONTHLY_REPAYMENT_SET_VALID, error});
export const setCalculateButtonDisabledValue = (value) => ({type: BUTTON_DISABLED_SET_VALUE, value});
export const setCalculateButtonDisabledValid = (error) => ({type: BUTTON_DISABLED_SET_VALID, error});
export const setResultsCalculated = (value) => ({type: RESULT_CALCULATED_SET_VALUE, value});