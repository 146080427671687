const QUOTATION = 'roaCarInsurance/quotation/';
export const QUOTATION_SET_VALUE = QUOTATION + 'setValue/';
export const QUOTATION_SET_VALID = QUOTATION + 'setValid/';


export const FORM_VALID_QUOTATION = QUOTATION + 'transition/formStateValid';
export const FORM_INVALID_QUOTATION = QUOTATION + 'transition/formStateInValid';

// for Nigeria
export const FORM_VALID_NG_QUOTATION = QUOTATION + 'transition/formStateValidNg';
export const FORM_INVALID_NG_QUOTATION = QUOTATION + 'transition/formStateInValidNg';

export const QUOTATION_SET_NG_VALUE = QUOTATION + 'setValueNg/';
export const QUOTATION_SET_NG_VALID = QUOTATION + 'setValidNg/';

export const SUMMARY_FORM_STATUS = QUOTATION_SET_VALID + 'summaryFormStatus';
export const AGENT_REFERRAL_FORM_STATUS = QUOTATION_SET_VALID + 'agentReferralFormStatus';

// API Call actions
export const API_PENDING_TRANSITION = `${QUOTATION_SET_VALUE}apiTransition/pending`;
export const API_SUCCESS_TRANSITION = `${QUOTATION_SET_VALUE}apiTransition/success`;
export const API_FAILURE_TRANSITION = `${QUOTATION_SET_VALUE}apiTransition/failure`;
export const apiTransition = {
    pending: () => ({
        type: API_PENDING_TRANSITION,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION,
    }),
    failure: (error) => ({
        type: API_FAILURE_TRANSITION,
		error
    }),
};

// Events - form input action creator types
export const AGE_OF_INSURED_SET_VALUE = QUOTATION_SET_VALUE + 'ageOfInsured';
export const AGE_OF_INSURED_SET_VALID = QUOTATION_SET_VALID + 'ageOfInsured';

export const VARIANCE_VALUE_SET_VALUE = QUOTATION_SET_VALUE + 'varianceValue';
export const VARIANCE_VALUE_SET_VALID = QUOTATION_SET_VALID + 'varianceValue';

export const CAR_MAKE_AND_MODEL_SET_VALUE =
    QUOTATION_SET_VALUE + 'carMakeAndModel';
export const CAR_MAKE_AND_MODEL_SET_VALID =
    QUOTATION_SET_VALID + 'carMakeAndModel';

export const NUMBER_OF_ACCIDENTS_SET_VALUE =
    QUOTATION_SET_VALUE + 'noOfAccidents';
export const NUMBER_OF_ACCIDENTS_SET_VALID =
    QUOTATION_SET_VALID + 'noOfAccidents';

export const YEAR_OF_MANUFACTURER_SET_VALUE =
    QUOTATION_SET_VALUE + 'yearOfManufacturer';
export const YEAR_OF_MANUFACTURER_SET_VALID =
    QUOTATION_SET_VALID + 'yearOfManufacturer';

// for Nigeria form inputs
export const VEHICLE_MAKE_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'vehicleMake';
export const VEHICLE_MAKE_SET_VALID =
    QUOTATION_SET_NG_VALID + 'vehicleMake';

export const SELECTED_MODELS_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'selectedModels';

export const VEHICLE_MODEL_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'vehicleModel';
export const VEHICLE_MODEL_SET_VALID =
    QUOTATION_SET_NG_VALID + 'vehicleModel';

export const VEHICLE_TYPE_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'vehicleType';
export const VEHICLE_TYPE_SET_VALID =
    QUOTATION_SET_NG_VALID + 'vehicleType';

export const ESTIMATED_VALUE_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'estimatedValue';
export const ESTIMATED_VALUE_SET_VALID =
    QUOTATION_SET_NG_VALID + 'estimatedValue';

export const VEHICLE_USE_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'vehicleUse';
export const VEHICLE_USE_SET_VALID =
    QUOTATION_SET_NG_VALID + 'vehicleUse';

export const MANUFACTURER_YEAR_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'yearOfManufacturer';
export const MANUFACTURER_YEAR_SET_VALID =
    QUOTATION_SET_NG_VALID + 'yearOfManufacturer';

export const CAR_MAKES_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'carMakes';
export const CAR_MAKES_SET_VALID =
    QUOTATION_SET_NG_VALID + 'carMakes';

export const CAR_MODELS_SET_VALUE =
    QUOTATION_SET_NG_VALUE + 'carModels';
export const CAR_MODELS_SET_VALID =
    QUOTATION_SET_NG_VALID + 'carModels';

// Events - form input action creators
export const setCarMakesValue = (value) => ({
    type: CAR_MAKES_SET_VALUE,
    value,
});
export const setCarMakesValid = (error) => ({
    type: CAR_MAKES_SET_VALID,
    error,
});

export const setCarModelsValue = (value) => ({
    type: CAR_MODELS_SET_VALUE,
    value,
});
export const setCarModelsValid = (error) => ({
    type: CAR_MODELS_SET_VALID,
    error,
});

export const setAgeOfInsuredValue = (value) => ({
    type: AGE_OF_INSURED_SET_VALUE,
    value,
});
export const setAgeOfInsuredValid = (error) => ({
    type: AGE_OF_INSURED_SET_VALID,
    error,
});

export const setCarAndModelValue = (value) => ({
    type: CAR_MAKE_AND_MODEL_SET_VALUE,
    value,
});
export const setCarAndModelValid = (error) => ({
    type: CAR_MAKE_AND_MODEL_SET_VALID,
    error,
});

export const setNoOfAccidentsValue = (value) => ({
    type: NUMBER_OF_ACCIDENTS_SET_VALUE,
    value,
});
export const setNoOfAccidentsValid = (error) => ({
    type: NUMBER_OF_ACCIDENTS_SET_VALID,
    error,
});

export const setYearOfManufacturerValue = (value) => ({
    type: YEAR_OF_MANUFACTURER_SET_VALUE,
    value,
});
export const setYearOfManufacturerValid = (error) => ({
    type: YEAR_OF_MANUFACTURER_SET_VALID,
    error,
});

export const setVarianceValue = (value) => ({
    type: VARIANCE_VALUE_SET_VALUE,
    value,
});
export const setVarianceValid = (error) => ({
    type: VARIANCE_VALUE_SET_VALID,
    error,
});

export const setSummaryFormStatus = () => ({
    type: SUMMARY_FORM_STATUS,
});

export const setAgentReferralStatus = () => ({
    type: AGENT_REFERRAL_FORM_STATUS,
});

export const COLLECT_VEHICLE_VALUATION_DATA =
    QUOTATION_SET_VALUE + 'collectVehicleValuation';
export const CALCULATE_VEHICLE_VALUATION_SUCCESS =
    QUOTATION_SET_VALUE + 'vehicleValuationSuccess';

export const collectVehicleValuationData = () => ({
    type: COLLECT_VEHICLE_VALUATION_DATA,
});
export const calculateVehicleValuationSuccess = (value) => ({
    type: CALCULATE_VEHICLE_VALUATION_SUCCESS,
    value,
});

export const COLLECT_REVALUATION_DATA =
    QUOTATION_SET_VALUE + 'collectRevaluation';
export const CALCULATE_REVALUATION_SUCCESS =
    QUOTATION_SET_VALUE + 'RevaluationSuccess';

export const collectRevaluationData = () => ({
    type: COLLECT_REVALUATION_DATA,
});
export const calculateRevaluationSuccess = (value) => ({
    type: CALCULATE_REVALUATION_SUCCESS,
    value,
});

// for Nigeria
export const setVehicleMake = (value) => ({
	type: VEHICLE_MAKE_SET_VALUE,
	value,
});

export const setVehicleMakeValid = (error) => ({
    type: VEHICLE_MAKE_SET_VALID,
    error,
});

export const setVehicleModel = (value) => ({
	type: VEHICLE_MODEL_SET_VALUE,
	value,
});

export const setVehicleModelValid = (error) => ({
    type: VEHICLE_MODEL_SET_VALID,
    error,
});

export const setVehicleType = (value) => ({
	type: VEHICLE_TYPE_SET_VALUE,
	value,
});

export const setEstimatedValue = (value) => ({
	type: ESTIMATED_VALUE_SET_VALUE,
	value,
});


export const setEstimatedValid = (error) => ({
	type: ESTIMATED_VALUE_SET_VALID,
	error,
});

export const setVehicleUse = (value) => ({
	type: VEHICLE_USE_SET_VALUE,
	value,
});

export const setVehicleUseValid = (error) => ({
    type: VEHICLE_USE_SET_VALID,
    error,
});

export const setManufacturerYearValue = (value) => ({
    type: MANUFACTURER_YEAR_SET_VALUE,
    value,
});
export const setManufacturerYearValid = (error) => ({
    type: MANUFACTURER_YEAR_SET_VALID,
    error,
});

export const setSelectedModelsValue = (value) => ({
    type: SELECTED_MODELS_SET_VALUE,
    value,
});


export const COLLECT_CAR_MAKES_DATA =
    QUOTATION_SET_NG_VALUE + 'collectCarMakes';
export const CALCULATE_CAR_MAKES_SUCCESS =
    QUOTATION_SET_NG_VALUE + 'carMakesSuccess';

export const collectCarMakesData = () => ({
    type: COLLECT_CAR_MAKES_DATA,
});
export const calculateCarMakesSuccess = (value) => ({
    type: CALCULATE_CAR_MAKES_SUCCESS,
    value,
});


export const COLLECT_CAR_MODELS_DATA =
    QUOTATION_SET_NG_VALUE + 'collectCarModels';
export const CALCULATE_CAR_MODELS_SUCCESS =
    QUOTATION_SET_NG_VALUE + 'carModelsSuccess';

export const collectCarModelsData = () => ({
    type: COLLECT_CAR_MODELS_DATA,
});
export const calculateCarModelsSuccess = (value) => ({
    type: CALCULATE_CAR_MODELS_SUCCESS,
    value,
});


export const COLLECT_PREMIUM_CALCULATION_NG_DATA =
    QUOTATION_SET_NG_VALUE + 'collectPremiumCalculationNg';
export const CALCULATE_PREMIUM_CALCULATION_NG_SUCCESS =
    QUOTATION_SET_NG_VALUE + 'premiumCalculationNgSuccess';

export const collectPremiumCalculationNgData = () => ({
    type: COLLECT_PREMIUM_CALCULATION_NG_DATA,
});
export const calculatePremiumCalculationNgSuccess = (value) => ({
    type: CALCULATE_PREMIUM_CALCULATION_NG_SUCCESS,
    value,
});

// transition form status
export const transitionQuotationForm = {
    formValid: () => ({ type: FORM_VALID_QUOTATION }),
    formInvalid: () => ({ type: FORM_INVALID_QUOTATION }),
};
//for Nigeria
export const transitionQuotationFormNg = {
    formValid: () => ({ type: FORM_VALID_NG_QUOTATION }),
    formInvalid: () => ({ type: FORM_INVALID_NG_QUOTATION }),
};
