import { FIELD_STATUS, FORM_STATUS } from '../status';
import {
	BOND_FUND_SET_VALID,
	BOND_FUND_SET_VALUE,
	EQUITY_FUND_SET_VALID,
	EQUITY_FUND_SET_VALUE,
	EVEN_SPLIT_DESELECT_FUND_SPLIT,
	EVEN_SPLIT_DISCARD_FUND_SPLIT,
	EVEN_SPLIT_SELECT_FUND_SPLIT,
	FORM_INVALID_TRANSITION_FUND_SPLIT,
	FORM_VALID_TRANSITION_FUND_SPLIT,
	TOTAL_PERCENTAGE_SET_VALID,
	TOTAL_PERCENTAGE_SET_VALUE,
} from '../../actions/investmentCalculator/longTermGoalSplit';

export const initialFundSplit = {
	formStatus: FORM_STATUS.VALID,
	form: {
		equityFund: { value: '50', error: null, status: FIELD_STATUS.VALID },
		balancedFund: { value: '50', error: null, status: FIELD_STATUS.VALID },
	},
	isEvenSplit: true,
	splitError: null,
	totalPercentage: '100',
};

export default (state = initialFundSplit, action) => {
	const mapError = (error) =>
		error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
	const updateFormField = (fieldId, update) => ({
		...state,
		form: {
			...state.form,
			[fieldId]: update(state.form[fieldId]),
		},
	});

	const asStrValue = (val) =>
		!val || isNaN(val) || val < 1 || val > 100 ? '0' : String(val);

	switch (action.type) {
		case EVEN_SPLIT_SELECT_FUND_SPLIT:
			return {
				...state,
				isEvenSplit: true,
				totalPercentage: '100',
				splitError: null,
				form: initialFundSplit.form,
			};

		case EVEN_SPLIT_DESELECT_FUND_SPLIT:
			return { ...state, isEvenSplit: false };
		case EVEN_SPLIT_DISCARD_FUND_SPLIT:
			return {
				...state,
				form: initialFundSplit.form,
				totalPercentage: '100',
			};
		case FORM_VALID_TRANSITION_FUND_SPLIT:
			return { ...state, formStatus: FORM_STATUS.VALID };
		case FORM_INVALID_TRANSITION_FUND_SPLIT:
			return { ...state, formStatus: FORM_STATUS.INVALID };
		case TOTAL_PERCENTAGE_SET_VALID:
			return { ...state, splitError: action.error };
		case TOTAL_PERCENTAGE_SET_VALUE:
			return { ...state, totalPercentage: action.value };
		case EQUITY_FUND_SET_VALUE:
			return updateFormField('equityFund', (field) => ({
				...field,
				value: asStrValue(action.value),
			}));
		case EQUITY_FUND_SET_VALID:
			return updateFormField('equityFund', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case BOND_FUND_SET_VALUE:
			return updateFormField('balancedFund', (field) => ({
				...field,
				value: asStrValue(action.value),
			}));

		case BOND_FUND_SET_VALID:
			return updateFormField('balancedFund', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		default:
			return state;
	}
};
