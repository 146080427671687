import {
    FIELD_STATUS,
} from "../status";
import {
    ON_BEHALF_OF_CHILD_SET_VALUE,
    ON_BEHALF_OF_CHILD_SET_VALID,
    HAS_OTHER_TAX_FREE_PLAN_SET_VALUE,
    HAS_OTHER_TAX_FREE_PLAN_SET_VALID,
    LEGAL_GUARDIAN_SET_VALUE,
    LEGAL_GUARDIAN_SET_VALID,
    SAVING_FOR_SET_VALUE,
    SAVING_FOR_SET_VALID,
    YOUR_GOAL_SET_VALUE,
    YOUR_GOAL_SET_VALID,
    RESET_INVESTMENT_SETUP_INITIAL
} from "../../actions/taxFreeSavingsAccount/investmentSetupInitial";

export const initInvestmentSetupInitial = {
    form: {
        onBehalfOfChild: { value: null, error: null, status: null },
        hasOtherTaxFreePlan: { value: null, error: null, status: null },
        legalGuardian: { value: null, error: null, status: null },
        savingFor: { value: null, error: null, status: null },
        yourGoal: { value: null, error: null, status: null },
    },
};

export default (state = initInvestmentSetupInitial, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case ON_BEHALF_OF_CHILD_SET_VALUE:
                return updateFormField('onBehalfOfChild', field => ({
                    ...field,
                    value: action.value
                }));
        case ON_BEHALF_OF_CHILD_SET_VALID:
                return updateFormField('onBehalfOfChild', field => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));

        case HAS_OTHER_TAX_FREE_PLAN_SET_VALUE:
                return updateFormField('hasOtherTaxFreePlan', field => ({
                    ...field,
                    value: action.value,
                }));

        case HAS_OTHER_TAX_FREE_PLAN_SET_VALID:
                return updateFormField('hasOtherTaxFreePlan', field => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));
    
        case LEGAL_GUARDIAN_SET_VALUE:
                return updateFormField('legalGuardian', field => ({
                    ...field,
                    value: action.value
                }));
    
        case LEGAL_GUARDIAN_SET_VALID:
                return updateFormField('legalGuardian', field => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));
        
        case SAVING_FOR_SET_VALUE:
                return updateFormField('savingFor', field => ({
                    ...field,
                    value: action.value
                }));
        
        case SAVING_FOR_SET_VALID:
                return updateFormField('savingFor', field => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));

        case YOUR_GOAL_SET_VALUE:
                return updateFormField('yourGoal', field => ({
                    ...field,
                    value: action.value
                }));
        
        case YOUR_GOAL_SET_VALID:
                return updateFormField('yourGoal', field => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));
        
        case RESET_INVESTMENT_SETUP_INITIAL:
            return { ...initInvestmentSetupInitial };
            
        default:
            return state;
    }
};
