import { createSelector } from 'reselect';
import { getRoaTravelInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getAddTravellerDetails = createSelector(
    getRoaTravelInsuranceState,
    state => state.payments,
);

const makeGetAddTravelerField = fieldName =>
    createSelector(getAddTravellerDetails, travellerDetails => travellerDetails[fieldName]);

//get selected form
export const getSelectedForm = makeGetAddTravelerField('selectedForm');

// get forms
export const getAddTravellerForm = makeGetAddTravelerField('formOne');
export const getPaymentsForm = makeGetAddTravelerField('formTwo');

// get forms status
const makeGetFormValid = formStatus =>
    createSelector(
        getAddTravellerDetails,
        travellerDetails => travellerDetails[formStatus] === FORM_STATUS.VALID,
    );
export const getAddTravellerFormValid = makeGetFormValid('formOneStatus');
export const getPaymentsFormValid = makeGetFormValid('formTwoStatus');

export const getIsAddTravellerApiPending = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.addTravellerApiStatus === API_CALL_STATUS.PENDING
)

export const getPaymentsDetails = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsRequestResponse
)

export const getPaymentsStatusDetails = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsStatusResponse
)

export const getAccountNumberDetails = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.accountNumberResponse
)

export const getManualPaymentsResponse = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.manualMpesaPaymentsResponse
)

// generate travel insurance cert response details

export const getGenerateCertDetails = createSelector(
           getAddTravellerDetails,
           (addTraveller) => addTraveller.generateCertResponse
       );

export const getIsAddTravellerApiSuccess = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.addTravellerApiStatus === API_CALL_STATUS.SUCCESS
)

export const getIsAddTravellerApiFailure = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.addTravellerApiStatus === API_CALL_STATUS.FAILURE
)

// payments stk push status
export const getIsPaymentsApiPending = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsApiStatus === API_CALL_STATUS.PENDING
)

export const getIsPaymentsApiSuccess = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsApiStatus === API_CALL_STATUS.SUCCESS
)

export const getIsPaymentsApiFailure = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsApiStatus === API_CALL_STATUS.FAILURE
)

// generate account number status
export const getIsGenerateAccountNumberApiPending = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.accountNumberApiStatus === API_CALL_STATUS.PENDING
)

export const getIsGenerateAccountNumberApiSuccess = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.accountNumberApiStatus === API_CALL_STATUS.SUCCESS
)

export const getIsGenerateAccountNumberApiFailure = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.accountNumberApiStatus === API_CALL_STATUS.FAILURE
)

// mpesa payments status
export const getIsPaymentsStatusApiPending = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsStatusApiStatus === API_CALL_STATUS.PENDING
)

export const getIsPaymentsStatusApiSuccess = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsStatusApiStatus === API_CALL_STATUS.SUCCESS
)

export const getIsPaymentsStatusApiFailure = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.paymentsStatusApiStatus === API_CALL_STATUS.FAILURE
)

// manual payments API status
export const getIsManualPaymentsApiPending = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.manualPaymentsApiStatus === API_CALL_STATUS.PENDING
)

export const getIsManualPaymentsStatusApiSuccess = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.manualPaymentsApiStatus === API_CALL_STATUS.SUCCESS
)

export const getIsManualPaymentsStatusApiFailure = createSelector(
    getAddTravellerDetails,
    addTraveller => addTraveller.manualPaymentsApiStatus === API_CALL_STATUS.FAILURE
)

//generate travel insurance certificate api status selectors
export const getIsGenerateCertApiPending = createSelector(
           getAddTravellerDetails,
           (addTraveller) =>
               addTraveller.generateCertApiStatus === API_CALL_STATUS.PENDING
       );

export const getIsGenerateCertApiSuccess = createSelector(
           getAddTravellerDetails,
           (addTraveller) =>
               addTraveller.generateCertApiStatus === API_CALL_STATUS.SUCCESS
       );

export const getIsGenerateCertApiFailure = createSelector(
           getAddTravellerDetails,
           (addTraveller) =>
               addTraveller.generateCertApiStatus === API_CALL_STATUS.FAILURE
       );

// get form fields
const makeGetFieldForm = (getForm, fieldId) =>
    createSelector(getForm, form => form[fieldId]);

export const getFullNameValue = makeGetFieldForm(getAddTravellerForm,'fullName');
export const getPhoneValue = makeGetFieldForm(getAddTravellerForm, 'phone');
export const getEmailValue = makeGetFieldForm(getAddTravellerForm,'email');
export const getDateOfBirthValue = makeGetFieldForm(getAddTravellerForm, 'dob');
export const getFromValue = makeGetFieldForm(getAddTravellerForm, 'from');
export const getToValue = makeGetFieldForm( getAddTravellerForm, 'to');
export const getReturnDateValue = makeGetFieldForm(getAddTravellerForm,'returnDate');
export const getIdDocument = makeGetFieldForm(getAddTravellerForm, 'idDocument');
export const getIdNumber = makeGetFieldForm(getAddTravellerForm, 'idNumber');
export const getCoverValue = makeGetFieldForm(getAddTravellerForm, 'cover');
export const getTravelPlanValue = makeGetFieldForm( getAddTravellerForm, 'travelPlan');
export const getDepartureDateValue = makeGetFieldForm(getAddTravellerForm,'departureDate');
export const getPaymentsPhoneValue = makeGetFieldForm(getPaymentsForm,'paymentsPhone');
export const getMpesaCodeValue = makeGetFieldForm(getPaymentsForm, 'transactionCode')