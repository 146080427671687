import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    ID_UPLOAD_RESET_VALUE,
    ID_UPLOAD_SET_VALUE,
    ID_UPLOAD_SET_VALID,
    YELLOW_CARD_SET_VALUE,
    YELLOW_CARD_RESET_VALUE,
    YELLOW_CARD_SET_VALID,
    CAR_FRONT_VIEW_RESET_VALUE,
    CAR_FRONT_VIEW_SET_VALID,
    CAR_FRONT_VIEW_SET_VALUE,
    FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
    FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
    CAR_BACK_VIEW_SET_VALUE,
    CAR_BACK_VIEW_RESET_VALUE,
    CAR_BACK_VIEW_SET_VALID,
    CAR_LEFT_VIEW_SET_VALUE,
    CAR_LEFT_VIEW_RESET_VALUE,
    CAR_LEFT_VIEW_SET_VALID,
    CAR_RIGHT_VIEW_SET_VALUE,
    CAR_RIGHT_VIEW_RESET_VALUE,
    CAR_RIGHT_VIEW_SET_VALID,
    CAR_DASHBOARD_VIEW_SET_VALUE,
    CAR_DASHBOARD_VIEW_RESET_VALUE,
    CAR_DASHBOARD_VIEW_SET_VALID,
} from '../../actions/motorPrivateRW/Documents';

export const initDocuments = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    personalDetailsReferenceData: null,
    apiCallStatus: null,
    form: {
        idUpload: { value: null, error: null, status: null },
        yellowCard: { value: null, error: null, status: null },
        frontView: { value: null, error: null, status: null },
        backView: { value: null, error: null, status: null },
        leftView: { value: null, error: null, status: null },
        rightView: { value: null, error: null, status: null },
        dashboardView: { value: null, error: null, status: null },
    },
};

export default (state = initDocuments, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // ID UPLOAD

        case ID_UPLOAD_SET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_UPLOAD_SET_VALID:
            return updateFormField('idUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_UPLOAD_RESET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: null,
                error: null
            }));

        // LICENSE UPLOAD
        case YELLOW_CARD_SET_VALUE:
            return updateFormField('yellowCard', (field) => ({ ...field, value: action.value }));

        case YELLOW_CARD_RESET_VALUE:
            return updateFormField('yellowCard', (field) => ({
                ...field,
                value: null,
                error: null
            }));

        case YELLOW_CARD_SET_VALID:
            return updateFormField('yellowCard', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // LOGBOOK UPLOAD
        case CAR_FRONT_VIEW_SET_VALUE:
            return updateFormField('frontView', (field) => ({ ...field, value: action.value }));

        case CAR_FRONT_VIEW_RESET_VALUE:
            return updateFormField('frontView', (field) => ({
                ...field,
                value: null,
                error: null
            }));

        case CAR_FRONT_VIEW_SET_VALID:
            return updateFormField('frontView', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CAR_BACK_VIEW_SET_VALUE:
            return updateFormField('backView', (field) => ({ ...field, value: action.value }));

        case CAR_BACK_VIEW_RESET_VALUE:
            return updateFormField('backView', (field) => ({
                ...field,
                value: null,
                error: null
            }));

        case CAR_BACK_VIEW_SET_VALID:
            return updateFormField('backView', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CAR_LEFT_VIEW_SET_VALUE:
            return updateFormField('leftView', (field) => ({ ...field, value: action.value }));

        case CAR_LEFT_VIEW_RESET_VALUE:
            return updateFormField('leftView', (field) => ({
                ...field,
                value: null,
                error: null
            }));

        case CAR_LEFT_VIEW_SET_VALID:
            return updateFormField('leftView', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CAR_RIGHT_VIEW_SET_VALUE:
            return updateFormField('rightView', (field) => ({ ...field, value: action.value }));

        case CAR_RIGHT_VIEW_RESET_VALUE:
            return updateFormField('rightView', (field) => ({
                ...field,
                value: null,
                error: null
            }));

        case CAR_RIGHT_VIEW_SET_VALID:
            return updateFormField('rightView', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CAR_DASHBOARD_VIEW_SET_VALUE:
            return updateFormField('dashboardView', (field) => ({ ...field, value: action.value }));

        case CAR_DASHBOARD_VIEW_RESET_VALUE:
            return updateFormField('dashboardView', (field) => ({
                ...field,
                value: null,
                error: null
            }));

        case CAR_DASHBOARD_VIEW_SET_VALID:
            return updateFormField('dashboardView', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Reset the state
        case REINITIALIZE:
            return initDocuments;
        default:
            return state;
    }
};
