import {
    MOTOR_DETAILS_SET_VALUE,
    MOTOR_DETAILS_SET_VALID,
    FORM_INVALID_TRANSITION_MOTOR_DETAILS,
    FORM_VALID_TRANSITION_MOTOR_DETAILS,
    ADD_ADDITINAL_MOTOR_VALUE,
    EDIT_MOTOR_DETAILS_VALUE,
    UPDATE_MOTOR_DETAILS_FORMS,
    DELETE_MOTOR_DETAILS_VALUE,
    MOTOR_DETAILS_SET_VALUE_WITHOUT_VALIDATIONS,
} from '../../actions/allsureInsurance/motorDetails';
import { FIELD_STATUS, FORM_STATUS } from '../status';
import { ADDITIONAL_QUOTE, EDIT_QUOTE, INITIAL_EDIT_KEY } from './homeDetails';

const intialFormDetails = {
    vehicleLocation: { value: null, error: null, status: null, displayValue: null },
    motorInsuranceType: { value: null, error: null, status: null, displayValue: null },
    vehicleMake: { value: null, error: null, status: null, displayValue: null },
    mentionedVehicleMake: { value: null, error: null, status: null, displayValue: null },
    vehicleModel: { value: null, error: null, status: null, displayValue: null },
    mentionedVehicleModel: { value: null, error: null, status: null, displayValue: null },
    vehicleYear: { value: null, error: null, status: null, displayValue: null },
    trackingDevice: { value: null, error: null, status: null, displayValue: null },
    isImported: { value: null, error: null, status: null, displayValue: null },
    fuelType: { value: null, error: null, status: null, displayValue: null },
    lossSuffered: { value: null, error: null, status: null, displayValue: null },
    claimedCount: { value: null, error: null, status: null, displayValue: null },
    claimAmount: { value: null, error: null, status: null, displayValue: null },
    vehicleUse: { value: null, error: null, status: null, displayValue: null },
    insuredDetails: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
    insuredWithOM:{ value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
    policyNumber:{ value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
    engineCapacity: { value: null, error: null, status: null, displayValue: null },
    dateOfBirth: { value: null, error: null, status: null, displayValue: null },
    gender: { value: null, error: null, status: null, displayValue: null },
    dateOfLicence: { value: null, error: null, status: null, displayValue: null },
    licenceCode: { value: null, error: null, status: null, displayValue: null },
    maritalStatus: { value: null, error: null, status: null, displayValue: null },
    vehicleSumAssured: { value: null, error: null, status: null, displayValue: null },
};
export const initMotorDetails = {
    formStatus: FORM_STATUS.INVALID,
    motorDetailForms: [],
    editMotorDetailsKey: INITIAL_EDIT_KEY,
    form: intialFormDetails,
};
export default (state = initMotorDetails, action) => {
    const trimField = (value) => (value ? value.trim() : value);
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    // if ( CLAIMENT_DETAILS_SET_VALUE) {
    switch (action.type) {
        case FORM_VALID_TRANSITION_MOTOR_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_MOTOR_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case MOTOR_DETAILS_SET_VALUE:
            if(action.value.fieldType === 'insuredDetails') {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        insuredDetails: {
                            ...state.form.insuredDetails,
                            value: action.value.value,
                            displayValue: action.value?.displayValue ?? action.value.value,
                        },
                        insuredWithOM: { value: null, error: null, status: null, displayValue: null },
                    },
                };
            }
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
            }));
            
        case MOTOR_DETAILS_SET_VALUE_WITHOUT_VALIDATIONS:
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
                error: null,
                status: null
            }));

        case MOTOR_DETAILS_SET_VALID:
            const { fieldType, error } = action;
            return updateFormField(fieldType, (field) => ({
                ...field,
                error: error,
                status: mapError(error),
            }));
        case ADD_ADDITINAL_MOTOR_VALUE:
            return { ...state, form: intialFormDetails, editMotorDetailsKey: ADDITIONAL_QUOTE };

        case EDIT_MOTOR_DETAILS_VALUE:
            return {
                ...state,
                form: state.motorDetailForms[+action.value.value],
                editMotorDetailsKey: EDIT_QUOTE + action.value.value,
            };

        case DELETE_MOTOR_DETAILS_VALUE:
            state.motorDetailForms.splice(action.value, 1);
            const modifiedArray = state.motorDetailForms;
            return { ...state, motorDetailForms: [...modifiedArray],};

        case UPDATE_MOTOR_DETAILS_FORMS:
            state.editMotorDetailsKey === ADDITIONAL_QUOTE
                ? state.motorDetailForms.push(state.form)
                : state.motorDetailForms.splice(state.editMotorDetailsKey.split('_')[1], 1, state.form);
            return { ...state, motorDetailForms:[...state.motorDetailForms],editMotorDetailsKey: INITIAL_EDIT_KEY };

        default:
            return state;
        // }
    }
};
