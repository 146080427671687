import {FIELD_STATUS, FORM_STATUS } from "../status";
import {OPEN_DRAWER} from "../../actions/atRetirement/types";

import {
    TOGGLE_BUDGET_TOOL,
    TOGGLE_USE_OF_PERSONAL_INFORMATION,
    FORM_VALID_TRANSITION_PERSONAL_INFORMATION,
    FORM_INVALID_TRANSITION_PERSONAL_INFORMATION,
    FORM_VALID_TRANSITION_RETIREMENT_WORRIES,
    FORM_INVALID_TRANSITION_RETIREMENT_WORRIES,
    FORM_VALID_TRANSITION_FINANCIAL,
    FORM_INVALID_TRANSITION_FINANCIAL,
    FORM_VALID_TRANSITION_ABOUT_YOU,
    FORM_INVALID_TRANSITION_ABOUT_YOU,
    NAME_SET_VALUE,
    NAME_SET_VALID,
    AGE_SET_VALUE,
    AGE_SET_VALID,
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    RETIREMENT_FOR_SET_VALUE,
    RETIREMENT_FOR_SET_VALID,
    PARTNER_AGE_SET_VALUE,
    PARTNER_AGE_SET_VALID,
    RETIREMENT_WORRIES_SET_VALUE,
    RETIREMENT_WORRIES_SET_VALID,
    SALARY_SET_VALUE,
    SALARY_SET_VALID,
    SAVINGS_SET_VALUE,
    SAVINGS_SET_VALID,
    BUDGET_SET_VALUE,
    BUDGET_SET_VALID,
} from "../../actions/atRetirement/aboutYou";


export const initAboutYou = {
    formStatus: FORM_STATUS.INVALID,
    formPersonalInformationStatus: FORM_STATUS.INVALID,
    formRetirementWorriesStatus: FORM_STATUS.INVALID,
    formFinancialStatus: FORM_STATUS.INVALID,
    openDrawer: null,
    form: {
        name: { value: null, error: null, status: null },
        age: { value: null, error: null, status: null },
        gender: { value: null, error: null, status: null },
        retirementFor: { value: null, error: null, status: null },
        partnerAge: { value: null, error: null, status: null },
        retirementWorries: { value: null, error: null, status: null },
        salary: { value: null, error: null, status: null },
        savings: { value: null, error: null, status: null },
        budget: { value: null, error: null, status: null },
    },
};

export default (state = initAboutYou, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Side drawer toggles
        case TOGGLE_BUDGET_TOOL:
            return {
                ...state,
                openDrawer: !state.openDrawer ? OPEN_DRAWER.BUDGET_TOOL : null,
            };
        case TOGGLE_USE_OF_PERSONAL_INFORMATION:
            return {
                ...state,
                openDrawer: !state.openDrawer ? OPEN_DRAWER.USE_OF_PERSONAL_INFORMATION : null,
            };

        // Form Validation
        case FORM_VALID_TRANSITION_PERSONAL_INFORMATION:
            return { ...state, formPersonalInformationStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_PERSONAL_INFORMATION:
            return { ...state, formPersonalInformationStatus: FORM_STATUS.INVALID };

        case FORM_VALID_TRANSITION_RETIREMENT_WORRIES:
            return { ...state, formRetirementWorriesStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_RETIREMENT_WORRIES:
            return { ...state, formRetirementWorriesStatus: FORM_STATUS.INVALID };

        case FORM_VALID_TRANSITION_FINANCIAL:
            return { ...state, formFinancialStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_FINANCIAL:
            return { ...state, formFinancialStatus: FORM_STATUS.INVALID };

        case FORM_VALID_TRANSITION_ABOUT_YOU:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_ABOUT_YOU:
            return { ...state, formStatus: FORM_STATUS.INVALID };
            
        // Name
        case NAME_SET_VALUE:
            return updateFormField('name', field => ({
                ...field,
                value: action.value,
            }));
        case NAME_SET_VALID:
            return updateFormField('name', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Age
        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Gender
        case GENDER_SET_VALUE:
            return updateFormField('gender', field => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('gender', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Retirement For
        case RETIREMENT_FOR_SET_VALUE:
            return updateFormField('retirementFor', field => ({
                ...field,
                value: action.value,
            }));
        case RETIREMENT_FOR_SET_VALID:
            return updateFormField('retirementFor', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Partner Age
        case PARTNER_AGE_SET_VALUE:
            return updateFormField('partnerAge', field => ({
                ...field,
                value: action.value,
            }));
        case PARTNER_AGE_SET_VALID:
            return updateFormField('partnerAge', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Retirement Worries
        case RETIREMENT_WORRIES_SET_VALUE:
            return updateFormField('retirementWorries', field => ({
                ...field,
                value: action.value,
            }));
        case RETIREMENT_WORRIES_SET_VALID:
            return updateFormField('retirementWorries', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Salary
        case SALARY_SET_VALUE:
            return updateFormField('salary', field => ({
                ...field,
                value: action.value,
            }));
        case SALARY_SET_VALID:
            return updateFormField('salary', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Savings
        case SAVINGS_SET_VALUE:
            return updateFormField('savings', field => ({
                ...field,
                value: action.value,
            }));
        case SAVINGS_SET_VALID:
            return updateFormField('savings', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Budget
        case BUDGET_SET_VALUE:
            return updateFormField('budget', field => ({
                ...field,
                value: action.value,
            }));
        case BUDGET_SET_VALID:
            return updateFormField('budget', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
