import axios from 'axios';

import {
    FUNERAL_CLAIM_SUBMIT_FORM,
    apiTransitionFuneralClaim,
} from '../../../actions/publicWeb/funeralClaim';

import {
    getDontFireApi,
    getFuneralClaimPostData,
} from '../../../selectors/publicWeb/funeralClaim';

const submitFuneralClaim = async store => {
    const state = store.getState();

    if (!getDontFireApi(state)) {
        store.dispatch(apiTransitionFuneralClaim.pending());
        try {
            await axios.post(
                '/om-api/easiplus-funeral-plan/funeral-claim',
                getFuneralClaimPostData(state),
            );
            store.dispatch(apiTransitionFuneralClaim.success());
        } catch (error) {
            store.dispatch(apiTransitionFuneralClaim.failure());
        }
    }
}

export const funeralClaimService = (store, next, action) => {
    const result = next(action);

    (async () => {
        if (action.type === FUNERAL_CLAIM_SUBMIT_FORM) {
            submitFuneralClaim(store);
        }
    })();

    return result;
};
