import { combineReducers } from "redux";
import biographicInfo from './biographicInfo'
import clinicalCriteria from './clinicalCriteria'
import travelHistory from './travelHistory'
import wizardFooter from './wizardFooter';
import progressBar from './progressBar';
import result from './result';

export const covidSelfAssessment = combineReducers({
    biographicInfo,
    clinicalCriteria,
    travelHistory,
    wizardFooter,
    progressBar,
    result
});
