import { validationIsEqual } from '../status';
import {
    validateName,
    validateEmail,
    validateNumericString,
    validateContactNumber,
    validateAlphaNumericString,
} from '@om/validation';
import {
    FIRST_NAME_SET_VALUE,
    setFirstNameValid,
    LAST_NAME_SET_VALUE,
    setLastNameValid,
    ID_NUMBER_SET_VALUE,
    setIdNumberValid,
    RELATIONSHIP_SET_VALUE,
    setRelationshipValid,
    LOCATION_SET_VALUE,
    setLocationValid,
    PHONE_NUMBER_SET_VALUE,
    setPhoneNumberValid,
    EMAIL_SET_VALUE,
    setEmailValid,
} from '../../../actions/personalPensionPlan/beneficiaries';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

let prevValidationAction = null;
export const beneficiaryValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;

        const validateString = (value, min, subject) => {
            const validators = [validateName, validateAlphaNumericString, validateNumericString];

            let customError = null;

            for (const validate of validators) {
                customError = validate(value, { min, subject });
                if (!customError) break;
            }

            return customError;
        };

        switch (action.type) {
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });

                return setFirstNameValid(action.index, error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });
                return setLastNameValid(action.index, error);

            case ID_NUMBER_SET_VALUE:
                error = validateNumericString(action.value, {
                    min: 4,
                    max: 15,
                    subject: 'Identification Document',
                });

                return setIdNumberValid(action.index, error);

            case RELATIONSHIP_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Relationship',
                    min: 2,
                });
                return setRelationshipValid(action.index, error);

            case LOCATION_SET_VALUE:
                error = validateString(action.value, {
                    subject: 'Physical Address',
                    min: 3,
                });
                return setLocationValid(action.index, error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Phone Number',
                    countryCode: gatsbyStack,
                });
                return setPhoneNumberValid(action.index, error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(action.index, error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
