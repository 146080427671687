import { validationIsEqual } from '../status';
import { validateName, validateNumber, validateNumericString } from '@om/validation';
import {
    SET_CAR_COST_VALUE,
    SET_CAR_MAKE_VALUE,
    SET_CAR_MODEL_VALUE,
    SET_CAR_REACH_VALUE,
    SET_CAR_USE_VALUE,
    SET_COVERED_AMOUNT_VALUE,
    SET_FULL_NAME_VALUE,
    SET_INSURANCE_PERIOD_VALUE,
    SET_NUMBER_COVERED_VALUE,
    SET_NUMBER_SEATS_VALUE,
    SET_VEHICLE_TYPE_VALUE,
    SET_YEAR_OF_MANUFACTURE_VALUE,
    setCarCostValid,
    setCarMakeValid,
    setCarModelValid,
    setCarReachValid,
    setCarUseValid,
    setCoveredAmountValid,
    setFullNameValid,
    setInsurancePeriodValid,
    setNumberOfCoveredValid,
    setNumberOfSeatsValid,
    setVehicleTypeValid,
    setYearOfManufactureValid,
} from '../../../actions/motorPrivateRW/quote';
import { validateGenericString } from '@om/validation/src/validators/string-validator';

let prevValidationAction = null;

export const quoteValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SET_FULL_NAME_VALUE:
                error = validateName(action.value, {
                    subject: 'Full name',
                });
                return setFullNameValid(error);

            case SET_CAR_MAKE_VALUE:
                error = validateName(action.value?.title, {
                    subject: 'Car make',
                });
                return setCarMakeValid(error);

            case SET_CAR_MODEL_VALUE:
                error = validateName(action.value?.title, {
                    subject: 'Car model',
                });
                return setCarModelValid(error);
            case SET_YEAR_OF_MANUFACTURE_VALUE:
                error = validateNumericString(action.value, {
                    subject: 'Year of manufacture',
                    min: 4,
                    max: 4,
                });
                return setYearOfManufactureValid(error);

            case SET_CAR_USE_VALUE:
                error = validateName(action.value, {
                    subject: 'Car use',
                });
                return setCarUseValid(error);

            case SET_CAR_REACH_VALUE:
                error = validateName(action.value, {
                    subject: 'Car reach',
                });
                return setCarReachValid(error);

            case SET_NUMBER_SEATS_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Seating Capacity',
                });
                return setNumberOfSeatsValid(error);

            case SET_COVERED_AMOUNT_VALUE:
                error = validateNumericString(action.value?.toString(), {
                    subject: 'Sum Insured',
                });
                return setCoveredAmountValid(error);

            case SET_NUMBER_COVERED_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Number of Occupants',
                });
                return setNumberOfCoveredValid(error);

            case SET_INSURANCE_PERIOD_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Insurance payment plan',
                });
                return setInsurancePeriodValid(error);
            case SET_CAR_COST_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Car value',
                });
                return setCarCostValid(error);

            case SET_VEHICLE_TYPE_VALUE:
                error = validateNumericString(action.value?.toString(), {
                    subject: 'Vehicle Type',
                });
                return setVehicleTypeValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
