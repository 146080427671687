import { createSelector } from "reselect";
import { getRoaQuoteTools } from './index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../reducers/status";

export const criticalIllnessQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.criticalIllnessQuoteTool
);


export const getcriticalIllnessQuoteToolForm = createSelector(
    criticalIllnessQuoteTool,
    criticalIllnessQuoteTool => criticalIllnessQuoteTool.form
);

const makeGetFieldForm = fieldId => createSelector(
    getcriticalIllnessQuoteToolForm,
    form => form[fieldId]
);

export const criticalIllnessQuoteToolFormValid = createSelector(
    criticalIllnessQuoteTool,
    criticalIllnessQuoteTool => criticalIllnessQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getSelectedForm = createSelector(
    criticalIllnessQuoteTool,
    criticalIllnessQuoteTool => criticalIllnessQuoteTool.selectedForm
)

export const getRoaCmbObject = createSelector(
    criticalIllnessQuoteTool,
    criticalIllnessQuoteTool => criticalIllnessQuoteTool.roaCmbObject
)

export const getcriticalIllnessQuoteToolAmount = createSelector(
    criticalIllnessQuoteTool,
    criticalIllnessQuoteTool => criticalIllnessQuoteTool.criticalIllnessQuoteToolPremiumAmount
);

export const getShowError = createSelector(
    getcriticalIllnessQuoteToolForm,
    criticalIllnessQuoteTool => criticalIllnessQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getcriticalIllnessQuoteToolForm,
    criticalIllnessQuoteTool => criticalIllnessQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getCalculateDisabled = createSelector(
    criticalIllnessQuoteTool,
    (criticalIllnessQuoteTool) => {
        return (
            criticalIllnessQuoteTool.formStatus === FORM_STATUS.INVALID ||
            criticalIllnessQuoteTool.formStatus === API_CALL_STATUS.PENDING
        );
    }
);

export const getDOB = makeGetFieldForm('DateOfBirth');
export const getGenderValue = makeGetFieldForm('genderValue');
export const getSmokingValue = makeGetFieldForm('smokingValue');

export const getCoveredUpValue = makeGetFieldForm('coveredupValue');
export const getYearValue = makeGetFieldForm('yearValue');
export const getDisabilityValue = makeGetFieldForm('DisabilityValue');
