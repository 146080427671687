import { transitionDigitalSavingsQuoteForm } from "../../../actions/digitalSavings/quote";
import { FIELD_STATUS } from "../../../reducers/status";
import { getQuoteForm } from "../../../selectors/digitalSavings/quote";

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getQuoteForm(store.getState());
    const savingCategory = quoteForm.saving_category.value;
    const quoteFormValid = savingCategory === 'monthly' ? validateQuoteFormDetails(quoteForm) : savingCategory === 'once-off' ? validateOnceOffQuoteFormDetails(quoteForm) : validateCombinedQuoteFormDetails(quoteForm);
    const formAction = quoteFormValid
        ? transitionDigitalSavingsQuoteForm.formValid()
        : transitionDigitalSavingsQuoteForm.formInvalid()
    store.dispatch(formAction);
    return result
}

const validateQuoteFormDetails = (formFields) => {
	return (
		formFields.desired &&
		formFields.desired.status === FIELD_STATUS.VALID &&
		formFields.frequency &&
		formFields.frequency.status === FIELD_STATUS.VALID &&
		formFields.startdate &&
		formFields.startdate.status === FIELD_STATUS.VALID &&
        formFields.duration &&
		formFields.duration.status === FIELD_STATUS.VALID
	);
};

const validateOnceOffQuoteFormDetails = (formFields) => {
	return (
		formFields.lumpsum &&
		formFields.lumpsum.status === FIELD_STATUS.VALID &&
		formFields.startdate &&
		formFields.startdate.status === FIELD_STATUS.VALID &&
        formFields.duration &&
		formFields.duration.status === FIELD_STATUS.VALID
	);
};

const validateCombinedQuoteFormDetails = (formFields) => {
	return (
		formFields.desired &&
		formFields.desired.status === FIELD_STATUS.VALID &&
		formFields.frequency &&
		formFields.frequency.status === FIELD_STATUS.VALID &&
        formFields.lumpsum &&
		formFields.lumpsum.status === FIELD_STATUS.VALID &&
		formFields.startdate &&
		formFields.startdate.status === FIELD_STATUS.VALID &&
        formFields.duration &&
		formFields.duration.status === FIELD_STATUS.VALID
	);
};

