import axios from 'axios';
import {
    getIsApiPending,
    getQuotationForm,
} from '../../../selectors/roaCarInsurance/quotation';
import {
    apiTransition,
    calculateVehicleValuationSuccess,
    setSummaryFormStatus,
} from '../../../actions/roaCarInsurance/quotation';

export const vehicleValuationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransition.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateVehicleValuationSuccess(resp.data));
                if (resp.data.code) {
                    store.dispatch(apiTransition.failure(resp.data.code));
                } else {
                    store.dispatch(apiTransition.success());
                    store.dispatch(setSummaryFormStatus());
                }
            } catch (e) {
                store.dispatch(apiTransition.failure(e.message));
            }
        }
    })();
    return next(action);
};

const vehicleValuationPayload = (formData) => ({
    makemodel: formData.makeAndModel.value,
    yom: formData.yearOfManufacture.value,
    age_range: formData.age.value,
    no_of_claims: formData.noOfAccidents.value,
});

const calculate = async (state) => {
    const formData = getQuotationForm(state);
    try {
        const url = '/om-api/roa-car-insurance/vehicle-value';
        return await axios.post(url, vehicleValuationPayload(formData));
    } catch (e) {
        throw e
    }
};
