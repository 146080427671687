import { transitionTravelInsuranceTravelDetailsForm } from '../../../actions/roaTravelInsurance/travelDetails';
import { getTravelDetailsForm } from '../../../selectors/roaTravelInsurance/travelDetails';
import { FIELD_STATUS } from '../../../reducers/status';

export const travelDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const travelDetailsForm = getTravelDetailsForm(store.getState());

    const formValid = validateTravelInsuranceFormDetails(travelDetailsForm);
    const travelDetailsFormAction = formValid
        ? transitionTravelInsuranceTravelDetailsForm.formValid()
        : transitionTravelInsuranceTravelDetailsForm.formInvalid();
    store.dispatch(travelDetailsFormAction);
    return result;
};

const validateTravelInsuranceFormDetails = formFields => {
    return (
        formFields.adults &&
        formFields.adults.status === FIELD_STATUS.VALID &&
        formFields.from &&
        formFields.from.status === FIELD_STATUS.VALID &&
        formFields.to &&
        formFields.to.status === FIELD_STATUS.VALID &&
        formFields.departureDate &&
        formFields.departureDate.status === FIELD_STATUS.VALID &&
        formFields.returnDate &&
        formFields.returnDate.status === FIELD_STATUS.VALID &&
        formFields.reasonForTravel &&
        formFields.reasonForTravel.status === FIELD_STATUS.VALID
    );
};
