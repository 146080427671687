export const PERSONAL_ACCIDENT_DECLARATIONS_FORM = 'personalAccident/declarationsForm/';

export const PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE = PERSONAL_ACCIDENT_DECLARATIONS_FORM + 'setValue/';
export const PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID = PERSONAL_ACCIDENT_DECLARATIONS_FORM + 'setValid/';

export const FORM_VALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM = 'personalAccident/declarationForm/transition/formValid';
export const FORM_INVALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM =
    'personalAccident/declarationForm/transition/formInValid';

export const REINITIALIZE = 'personalAccident/declarationForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DECLARATIONS_FORM = 'personalAccident/declarationForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_DECLARATIONS_FORM,
    value,
});

export const transitionPersonalAccidentDeclarationsForm = {
    formValid: () => ({
        type: FORM_VALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_DECLARATIONS_FORM = `${PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DECLARATIONS_FORM = `${PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DECLARATIONS_FORM = `${PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDeclarationsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_DECLARATIONS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_DECLARATIONS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_DECLARATIONS_FORM,
    }),
};

//calculated Data
export const COLLECT_DECLARATIONS_FORM_DATA = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DECLARATIONS_SUCCESS = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'success';
export const SUBMIT_DECLARATIONS_FAILURE = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
    type: COLLECT_DECLARATIONS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
    type: SUBMIT_DECLARATIONS_SUCCESS,
    value,
});
export const submitDocumentsFailure = (error) => ({
    type: SUBMIT_DECLARATIONS_FAILURE,
    error,
});

// Form Values
export const HAS_PREVIOUSLY_HELD_POLICY_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'hasPreviouslyHeldPolicy';
export const HAS_PREVIOUSLY_HELD_POLICY_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'hasPreviouslyHeldPolicy';

export const PREVIOUS_INSURER_NAME_SET_VALUE = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'previousInsurerName';
export const PREVIOUS_INSURER_NAME_SET_VALID = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'previousInsurerName';

export const PREVIOUS_INSURER_BRANCH_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'previousInsurerBranch';
export const PREVIOUS_INSURER_BRANCH_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'previousInsurerBranch';

export const PREVIOUS_INSURER_ADDRESS_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'previousInsurerAddress';
export const PREVIOUS_INSURER_ADDRESS_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'previousInsurerAddress';

export const PREVIOUS_INSURER_POLICY_NUMBER_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'previousInsurerPolicyNumber';
export const PREVIOUS_INSURER_POLICY_NUMBER_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'previousInsurerPolicyNumber';

export const IS_POLICY_ADDITIONAL_SET_VALUE = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'isPolicyAdditional';
export const IS_POLICY_ADDITIONAL_SET_VALID = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'isPolicyAdditional';

export const ADDITIONAL_POLICIES_NUMBER_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'additionalPoliciesNo';
export const ADDITIONAL_POLICIES_NUMBER_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'additionalPoliciesNo';

export const ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'additionalPolicesTotalDeathBenefit';
export const ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'additionalPolicesTotalDeathBenefit';

export const DECLINED_PROPOSAL_SET_VALUE = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'declinedProposal';
export const DECLINED_PROPOSAL_SET_VALID = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'declinedProposal';

export const REFUSED_RENEWAL_SET_VALUE = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'refusedRenewal';
export const REFUSED_RENEWAL_SET_VALID = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'refusedRenewal';

export const TERMINATED_INSURANCE_SET_VALUE = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'terminatedInsurance';
export const TERMINATED_INSURANCE_SET_VALID = PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'terminatedInsurance';

export const TERMINATED_INSURANCE_DETAILS_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'terminatedInsuranceDetails';
export const TERMINATED_INSURANCE_DETAILS_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'terminatedInsuranceDetails';

export const REQUIRED_INCREASED_PREMIUM_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'requiredIncreasedPremium';
export const REQUIRED_INCREASED_PREMIUM_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'requiredIncreasedPremium';

export const IMPOSED_SPECIAL_CONDITIONS_SET_VALUE =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALUE + 'imposedSpecialConditions';
export const IMPOSED_SPECIAL_CONDITIONS_SET_VALID =
    PERSONAL_ACCIDENT_DECLARATIONS_FORM_SET_VALID + 'imposedSpecialConditions';

// action creators
export const setHasPreviouslyHeldPolicyValue = (value) => ({
    type: HAS_PREVIOUSLY_HELD_POLICY_SET_VALUE,
    value,
});

export const setHasPreviouslyHeldPolicyValid = (error) => ({
    type: HAS_PREVIOUSLY_HELD_POLICY_SET_VALID,
    error,
});

export const setPreviousInsurerNameValue = (value) => ({
    type: PREVIOUS_INSURER_NAME_SET_VALUE,
    value,
});

export const setPreviousInsurerNameValid = (error) => ({
    type: PREVIOUS_INSURER_NAME_SET_VALID,
    error,
});

export const setPreviousInsurerBranchValue = (value) => ({
    type: PREVIOUS_INSURER_BRANCH_SET_VALUE,
    value,
});

export const setPreviousInsurerBranchValid = (error) => ({
    type: PREVIOUS_INSURER_BRANCH_SET_VALID,
    error,
});

export const setPreviousInsurerAddressValue = (value) => ({
    type: PREVIOUS_INSURER_ADDRESS_SET_VALUE,
    value,
});

export const setPreviousInsurerAddressValid = (error) => ({
    type: PREVIOUS_INSURER_ADDRESS_SET_VALID,
    error,
});

export const setPreviousInsurerPolicyNumberValue = (value) => ({
    type: PREVIOUS_INSURER_POLICY_NUMBER_SET_VALUE,
    value,
});

export const setPreviousInsurerPolicyNumberValid = (error) => ({
    type: PREVIOUS_INSURER_POLICY_NUMBER_SET_VALID,
    error,
});

export const setDeclinedProposalValue = (value) => ({
    type: DECLINED_PROPOSAL_SET_VALUE,
    value,
});

export const setDeclinedProposalValid = (error) => ({
    type: DECLINED_PROPOSAL_SET_VALID,
    error,
});

export const setIsPolicyAdditionalValue = (value) => ({
    type: IS_POLICY_ADDITIONAL_SET_VALUE,
    value,
});

export const setIsPolicyAdditionalValid = (error) => ({
    type: IS_POLICY_ADDITIONAL_SET_VALID,
    error,
});

export const setAdditionalPoliciesNoValue = (value) => ({
    type: ADDITIONAL_POLICIES_NUMBER_SET_VALUE,
    value,
});

export const setAdditionalPoliciesNoValid = (error) => ({
    type: ADDITIONAL_POLICIES_NUMBER_SET_VALID,
    error,
});

export const setTotalAdditionalPolicesDeathBenefitValue = (value) => ({
    type: ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALUE,
    value,
});

export const setTotalAdditionalPolicesDeathBenefitValid = (error) => ({
    type: ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALID,
    error,
});

export const setRefusedRenewalValue = (value) => ({
    type: REFUSED_RENEWAL_SET_VALUE,
    value,
});

export const setRefusedRenewalValid = (error) => ({
    type: REFUSED_RENEWAL_SET_VALID,
    error,
});

export const setTerminatedInsuranceValue = (value) => ({
    type: TERMINATED_INSURANCE_SET_VALUE,
    value,
});

export const setTerminatedInsuranceValid = (error) => ({
    type: TERMINATED_INSURANCE_SET_VALID,
    error,
});

export const setTerminatedInsuranceDetailsValue = (value) => ({
    type: TERMINATED_INSURANCE_DETAILS_SET_VALUE,
    value,
});

export const setTerminatedInsuranceDetailsValid = (error) => ({
    type: TERMINATED_INSURANCE_DETAILS_SET_VALID,
    error,
});

export const setRequiredPremiumIncreaseValue = (value) => ({
    type: REQUIRED_INCREASED_PREMIUM_SET_VALUE,
    value,
});

export const setRequiredPremiumIncreaseValid = (error) => ({
    type: REQUIRED_INCREASED_PREMIUM_SET_VALID,
    error,
});

export const setImposedSpecialConditionsValue = (value) => ({
    type: IMPOSED_SPECIAL_CONDITIONS_SET_VALUE,
    value,
});

export const setImposedSpecialConditionsValid = (error) => ({
    type: IMPOSED_SPECIAL_CONDITIONS_SET_VALID,
    error,
});
