import { SHOW_CHAT_BOT, HIDE_CHAT_BOT } from "../../actions/publicWeb/chatBot"
import { TOGGLE_STATUS } from "../status"

const initialChatBot = {
    showChatBot: TOGGLE_STATUS.YES,
}

export default (state = initialChatBot, action) => {
    switch (action.type) {
        case SHOW_CHAT_BOT:
            return {
                ...state,
                showChatBot: TOGGLE_STATUS.YES

            }
        case HIDE_CHAT_BOT:
            return {
                ...state,
                showChatBot: TOGGLE_STATUS.NO
            }
        default:
            return state
    }
}
