
export const MAX_RETIREMENT_AGE = 85;
export const MIN_RETIREMENT_AGE = 55;
export const MIN_PRODUCT_TERM = 5;

export const PAGES = {
    SHOW_BENEFITS: {
        KEY: 'showbenefits',
        URL: 'show-benefits',
        FULL_URL: '/retirement-annuity/show-benefits/',
    },
    CAPTURE_DETAILS: {
        KEY: 'capturedetails',
        URL: 'capture-details',
        FULL_URL: '/retirement-annuity/capture-details/',
    },
    INVESTMENT_SETUP: {
        KEY: 'investmentsetup',
        URL: 'investment-setup',
        FULL_URL: '/retirement-annuity/investment-setup/',
    },
    PERSONAL_DETAILS: {
        KEY: 'personaldetails',
        URL: 'personal-details',
        FULL_URL: '/retirement-annuity/personal-details/',
    },
    BENEFICIARIES: {
        KEY: 'beneficiaries',
        URL: 'beneficiaries',
        FULL_URL: '/retirement-annuity/beneficiaries/',
    },
    PAYMENT_DETAILS: {
        KEY: 'paymentdetails',
        URL: 'payment-details',
        FULL_URL: '/retirement-annuity/payment-details/',
    },
    CONFIRMATION: {
        KEY: 'confirmation',
        URL: 'confirmation',
        FULL_URL: '/retirement-annuity/confirmation/',
    },
    THANK_YOU: {
        KEY: 'thankyou',
        URL: 'thank-you',
        FULL_URL: '/retirement-annuity/thank-you/',
    },
};

export const APPLICATION_NAME = 'Retirement Annuity';
export const URL_PREFIX = '/retirement-annuity/';
export const PROGRESS_BAR_STEPS = [
    'Investment setup',
    'Personal details',
    'Beneficiaries',
    'Payment setup',
    'Confirmation',
].join('|');

export const APP_PAGE_ORDER = [
    PAGES.SHOW_BENEFITS.KEY,
    PAGES.CAPTURE_DETAILS.KEY,
    PAGES.INVESTMENT_SETUP.KEY,
    PAGES.PERSONAL_DETAILS.KEY,
    PAGES.BENEFICIARIES.KEY,
    PAGES.PAYMENT_DETAILS.KEY,
    PAGES.CONFIRMATION.KEY,
    PAGES.THANK_YOU.KEY,
];

export const APP_PAGE_URLS = [
    PAGES.SHOW_BENEFITS.URL,
    PAGES.CAPTURE_DETAILS.URL,
    PAGES.INVESTMENT_SETUP.URL,
    PAGES.PERSONAL_DETAILS.URL,
    PAGES.BENEFICIARIES.URL,
    PAGES.PAYMENT_DETAILS.URL,
    PAGES.CONFIRMATION.URL,
    PAGES.THANK_YOU.URL,
];

export const PRODUCT_PAGE_URL = '/personal/solutions/retirement-plans/max-investments-optimal-retirement-plan';
export const CVS_URL= '/customer-verification-service/consent/';

export const ACCOUNT_VALIDATION_STATUS = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
    FAILED: 'FAILED',
    PASSED: 'PASSED'
}
export const FIELD_STATUS = {
    VALID: 'valid',
};
