import {RETIREMENT_CALCULATOR_FORM_SET_VALUE} from "../../../actions/retirementCalculator/retirementCalculatorForm";
import {retirementCalculatorFormValidation} from "./retirementCalculatorForm";
import {RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE} from "../../../actions/retirementCalculator/retirementAssumptionsModel";
import {retirementCalculatorAssumptionsModelValidation} from "./retirementAssumptionsModel";

export const retirementCalculatorValidation = (store, next, action) => {

    if (action.type.startsWith(RETIREMENT_CALCULATOR_FORM_SET_VALUE))
        return retirementCalculatorFormValidation(store, next, action);

    if (action.type.startsWith(RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_SET_VALUE))
        return retirementCalculatorAssumptionsModelValidation(store, next, action);

    return null;
};
