import { COLLECT_LOGIN_DETAILS } from "../../../actions/healthInsuranceRW/auth";
import { TRIGGER_FETCH_AGENTS } from "../../../actions/healthInsuranceRW/HealthAgentModalRw"
import { COLLECT_PAYLOAD_DATA } from "../../../actions/healthInsuranceRW/payloadCollection";
import { CHECK_PAYMENTS_STATUS, TRIGGER_CONFIRM_ORDER, TRIGGER_PAYMENTS_STK_PUSH } from "../../../actions/healthInsuranceRW/payments";
import { COLLECT_COMPARE_PLANS, COLLECT_HEALTH_INSURANCE_QUOTE_FORM_DATA, COLLECT_PRODUCTS_DATA, COLLECT_SHARE_QUOTE_FORM_DATA } from "../../../actions/healthInsuranceRW/quote";
import { getAgentsService } from "./agents"
import { getComparePlansTableService } from "./comparePlans";
import { loginService } from "./login";
import { orderConfirmationService } from "./orderConfirmation";
import { payloadSubmitService } from "./payloadCollection";
import { paymentsStkService, paymentsStkStatusService } from "./payments";
import { premiumCalculationService } from "./premium";
import { getProductsService } from "./products";
import { shareQuoteService } from "./shareQuote";

export const healthInsuranceRWCover = (store, next, action) => {
    if (action.type.startsWith(TRIGGER_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }
    if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
        return paymentsStkStatusService(store, next, action);
    }

    if(action.type.startsWith(TRIGGER_FETCH_AGENTS)){
        return getAgentsService(store, next, action)
    }

    if(action.type.startsWith(COLLECT_LOGIN_DETAILS)){
        return loginService(store, next, action);
    }

    if(action.type.startsWith(COLLECT_PRODUCTS_DATA)){
        return getProductsService(store, next, action);
    }

    if(action.type.startsWith(COLLECT_HEALTH_INSURANCE_QUOTE_FORM_DATA)){
        return premiumCalculationService(store, next, action);
    }
    
    if(action.type.startsWith(COLLECT_COMPARE_PLANS)){
        return getComparePlansTableService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)){
        return orderConfirmationService(store, next, action);
    }

    if(action.type.startsWith(COLLECT_PAYLOAD_DATA)){
        return payloadSubmitService(store, next, action);
    }

    if(action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)){
        return shareQuoteService(store, next, action);
    }
    return null;
}