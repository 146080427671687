import { validationIsEqual } from '../status';
import {
    validateNumber,
    validateDate,
    validateAlphaString,
    validateSingleSelection,
} from '@om/validation';
import {
    ADULTS_SET_VALUE,
    KIDS_SET_VALUE,
    SENIORS_SET_VALUE,
    FROM_SET_VALUE,
    TO_SET_VALUE,
    DEPARTURE_DATE_SET_VALUE,
    RETURN_DATE_SET_VALUE,
    REASON_FOR_TRAVEL_SET_VALUE,
    setAdultValid,
    setKidsValid,
    setSeniorsValid,
    setFromValid,
    setToValid,
    setDepartureDateValid,
    setReturnDateValid,
    setReasonForTravelValid,
} from '../../../actions/roaTravelInsurance/travelDetails';
import {
    getDepartureDateValue,
    getReturnDateValue,
} from '../../../selectors/roaTravelInsurance/travelDetails';

let prevValidationAction = null;

export const travelDetails = (store, next, action) => {
    const state = store.getState();
    const validationAction = (() => {
        let error;
        let tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        let tomorrowReadable = tomorrow
            .toLocaleDateString('en-KE')
            .split('/')
            .join('/');

        let departureDate = getDepartureDateValue(state);
        let returnDate = getReturnDateValue(state);
        switch (action.type) {
            case FROM_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'From',
                });
                return setFromValid(error);

            case TO_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'To',
                });
                return setToValid(error);

            case ADULTS_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 0,
                    max: 6,
                    subject: 'number of adults',
                });
                return setAdultValid(error);

            case KIDS_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 0,
                    max: 6,
                    subject: 'number of kids',
                });
                return setKidsValid(error);

            case SENIORS_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 0,
                    max: 6,
                    subject: 'number of seniors',
                });
                return setSeniorsValid(error);

            case RETURN_DATE_SET_VALUE:
                error = validateDate(action.value, {
                    min: departureDate.value,
                });
                if (departureDate.error) {
                    const secondError = validateDate(action.value, {
                        min: departureDate.value,
                        max: action.value,
                    });
                    store.dispatch(setDepartureDateValid(secondError));
                }
                return setReturnDateValid(error);
            case DEPARTURE_DATE_SET_VALUE:
                error = validateDate(action.value, {
                    min: tomorrowReadable,
                    max: returnDate.value,
                });
                if (returnDate.error) {
                    const secondError = validateDate(action.value, {
                        min: action.value,
                        max: returnDate.value,
                    });
                    store.dispatch(setReturnDateValid(secondError));
                }
                return setDepartureDateValid(error);

            case REASON_FOR_TRAVEL_SET_VALUE:
                error = validateAlphaString(action.value, {
                    min: 3,
                    max: 140,
                    subject: 'reason for travel',
                });
                return setReasonForTravelValid(error);

            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
