import { transitionBiasharaFlexiCompanyDetails } from "../../../actions/biasharaFlexi/companyDetails";
import { FIELD_STATUS } from "../../../reducers/status";
import { getCompanyDetailsForm } from "../../../selectors/biasharaFlexi/companyDetails";
import { getIsPayNow } from "../../../selectors/biasharaFlexi/coverDetails";

export const companyDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const companyDetailsForm = getCompanyDetailsForm(store.getState());
	const isPayNow = getIsPayNow(store.getState());
    const companyDetailsFormValid = isPayNow ? validateCompanyDetailsFormPayNowDetails(companyDetailsForm) : validateCompanyDetailsFormDetails(companyDetailsForm);
    const formAction = companyDetailsFormValid
        ? transitionBiasharaFlexiCompanyDetails.formValid()
        : transitionBiasharaFlexiCompanyDetails.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateCompanyDetailsFormDetails = (formFields) => {
	return (
		formFields.companyName &&
		formFields.companyName.status === FIELD_STATUS.VALID &&
		formFields.email &&
		formFields.email.status === FIELD_STATUS.VALID &&
		formFields.mobileNumber &&
		formFields.mobileNumber.status === FIELD_STATUS.VALID &&
		formFields.kraPinNumber &&
		formFields.kraPinNumber.status === FIELD_STATUS.VALID &&
		formFields.city &&
		formFields.city.status === FIELD_STATUS.VALID
	);
};

const validateCompanyDetailsFormPayNowDetails = (formFields) => {
	return (
		formFields.companyName &&
		formFields.companyName.status === FIELD_STATUS.VALID &&
		formFields.email &&
		formFields.email.status === FIELD_STATUS.VALID &&
		formFields.mobileNumber &&
		formFields.mobileNumber.status === FIELD_STATUS.VALID &&
		formFields.firstName &&
		formFields.firstName.status === FIELD_STATUS.VALID &&
		formFields.lastName &&
		formFields.lastName.status === FIELD_STATUS.VALID &&
		formFields.idNumber &&
		formFields.idNumber.status === FIELD_STATUS.VALID
	);
};

