import { getIsApiPending } from '../../../selectors/personalPensionPlan/payments';
import { apiTransitionLogin, loginSuccess } from '../../../actions/personalPensionPlan/auth';
import axios from 'axios';

export const loginService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionLogin.pending());
            try {
                const resp = await calculate();
                store.dispatch(loginSuccess(resp.data));
                store.dispatch(apiTransitionLogin.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionLogin.failure());
            }
        }
    })();
    return next(action);
};

const calculate = async () => {
    try {
        const url = '/om-api/personal-pension-plan/login';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
