import { Script } from 'gatsby';
import React from 'react';

import personal_solutions_funeral_faq from '../../src/structuredData/personal-solutions-faq.json';
import personal_solutions_bank_borrow_personal_loans from '../../src/structuredData/personal-solutions-bank-borrow-personal-loans.json';
import personal_solutions_bank_borrow_personal_loans_faq from '../../src/structuredData/personal-solutions-bank-borrow-personal-loans-faq.json';
import personal_solutions_bank_borrow_faqs from '../../src/structuredData/personal-solutions-bank-borrow-faqs.json';
import personal_solutions_wills_trusts_estates from '../../src/structuredData/personal-solutions-wills-trusts-estates.json';
import personal_solutions_my_will from '../../src/structuredData/personal-solutions-my-will.json';
import personal_solutions_short_term_ins_car_home from '../../src/structuredData/personal-solutions-short-term-insurance-car-home.json';
import personal_solutions_life_disability from '../../src/structuredData/personal-solutions-life-disability.json';
import travel_insurance_faqs from '../../src/structuredData/travel-insurance-faqs.json';
import business_goals_multisure from '../../src/structuredData/business-goals-multisure.json';
import personal_solutions_life_disability_life_insurance from '../../src/structuredData/personal-solutions-life-disability-life-insurance.json';
import personal_solutions_life_disability_plan_range from '../../src/structuredData/personal-solutions-life-disability-life-plan-range.json';
import personal_solutions_life_disability__om_mutual_life_insurance from '../../src/structuredData/personal-solutions-life-disability-om-mutual-life-insurance.json';
import retirement_faq from '../../src/structuredData/retirement-faq.json';

export const wrapPageElement = ({ element }) => {
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    let faqSection;
    getFaqStructuredData();

    function getFaqStructuredData() {
        switch (currentUrl) {
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/funeral/`:
            case `https://www.oldmutual.co.za/personal/solutions/funeral/`:
                faqSection = personal_solutions_funeral_faq;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/bank-and-borrow/personal-loans/`:
            case `https://www.oldmutual.co.za/personal/solutions/bank-and-borrow/personal-loans/`:
                faqSection = personal_solutions_bank_borrow_personal_loans;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/bank-and-borrow/personal-loans/frequently-asked-questions/`:
            case `https://www.oldmutual.co.za/personal/solutions/bank-and-borrow/personal-loans/frequently-asked-questions/`:
                faqSection = personal_solutions_bank_borrow_personal_loans_faq;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/bank-and-borrow/frequently-asked-questions/`:
            case `https://www.oldmutual.co.za/personal/solutions/bank-and-borrow/frequently-asked-questions/`:
                faqSection = personal_solutions_bank_borrow_faqs;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/wills-trusts-and-estates/`:
            case `https://www.oldmutual.co.za/personal/solutions/wills-trusts-and-estates/`:
                faqSection = personal_solutions_wills_trusts_estates;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/mywill/`:
            case `https://www.oldmutual.co.za/personal/solutions/mywill/`:
                faqSection = personal_solutions_my_will;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/short-term-insurance/car-and-home/`:
            case `https://www.oldmutual.co.za/personal/solutions/short-term-insurance/car-and-home/`:
                faqSection = personal_solutions_short_term_ins_car_home;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/short-term-insurance/travel-insurance/`:
            case `https://www.oldmutual.co.za/personal/solutions/short-term-insurance/travel-insurance/`:
                faqSection = travel_insurance_faqs;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/life-and-disability/`:
            case `https://www.oldmutual.co.za/personal/solutions/life-and-disability/`:
                faqSection = personal_solutions_life_disability;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/business/goals/run/multisure/`:
            case `https://www.oldmutual.co.za/business/goals/run/multisure/`:
                faqSection = business_goals_multisure;
                break;

            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/life-and-disability/life-insurance/`:
            case `https://www.oldmutual.co.za/personal/solutions/life-and-disability/life-insurance`:
                faqSection = personal_solutions_life_disability_life_insurance;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/life-and-disability/old-mutual-life-insurance/`:
            case `https://www.oldmutual.co.za/personal/solutions/life-and-disability/old-mutual-life-insurance/`:
                faqSection = personal_solutions_life_disability__om_mutual_life_insurance;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/life-and-disability/life-plan-range/`:
            case `https://www.oldmutual.co.za/personal/solutions/life-and-disability/life-plan-range`:
                faqSection = personal_solutions_life_disability_plan_range;
                break;
            case `https://new-public-web-preprod.digitalplatform.oldmutual.co.za/personal/solutions/retirement-plans/`:
            case `https://www.oldmutual.co.za/personal/solutions/retirement-plans/`:
                faqSection = retirement_faq;
                break;

            default:
                faqSection = {};
                break;
        }

        return faqSection;
    }

    const isGhana = process.env.GATSBY_COUNTRY_SELECTOR === 'gh' ? true : false;
    const isNigeria = process.env.GATSBY_COUNTRY_SELECTOR === 'ng' ? true : false;
    const isUganda = process.env.GATSBY_COUNTRY_SELECTOR === 'ug' ? true : false;
    const isRwanda = process.env.GATSBY_COUNTRY_SELECTOR === 'rw' ? true : false;
    const isRSA = process.env.GATSBY_COUNTRY_SELECTOR === 'za' ? true : false;
    const isUT = process.env.GATSBY_COUNTRY_SELECTOR === 'ut' ? true : false;
    const isOMAI = process.env.GATSBY_COUNTRY_SELECTOR === 'omai' ? true : false;
    const isBulaTsela = process.env.GATSBY_COUNTRY_SELECTOR === 'bu' ? true : false;

    return (
        <>
            {element}
            <script defer={true}>
                {`(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'10159966','userEmail': '<email_address>'}});var s=d.createElement(t);s.src=r;s.defer=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window, document,'script','https://s.yimg.com/wi/ytc.js','dotq');`}
            </script>
            {isGhana && ( //Ghana chat
                <script defer={true}>
                    {`(function(){ var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; s1.async=true; s1.src='https://embed.tawk.to/5ef1f9959e5f6944229124d6/default'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })();`}
                </script>
            )}
            {isNigeria && ( // Nigeria chat
                <script defer={true} type="text/javascript">
                    {`var $zoho = $zoho || {}; $zoho.salesiq = $zoho.salesiq || { widgetcode: "a86b3d0208773f61fac157f7002f275ff0fb778cc9b1d88422efafebd279825b", values: {}, ready: function () { } }; var d = document; s = d.createElement("script"); s.type = "text/javascript"; s.id = "zsiqscript"; s.defer = true; s.src = "https://salesiq.zoho.com/widget"; t=d.getElementsByTagName("script")[0]; t.parentNode.insertBefore(s,t);"<div id='zsiqwidget'></div>";`}
                </script>
            )}
            {isNigeria && (
                <script defer={true} src="https://www.googletagmanager.com/gtag/js?id=AW-623811452">
                    {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-623811452');`}
                </script>
            )}
            {faqSection && <script defer={true} type="application/ld+json">{`${JSON.stringify(faqSection)}`}</script>}
            {isNigeria && (
                <script defer={true}>
                    {`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '502491044587690'); fbq('track', 'PageView');fbq('track', 'Lead');`}
                </script>
            )}
            {isUganda && (
                <script defer={true}>
                    {`!function(f,e,t,u,n,s,p) {if(f.esk)return;n=f.esk=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f.___esk)f.___esk=n;n.push=n;n.loaded=!0;n.queue=[];s=e.createElement(t);s.async=!0;s.src=u;p=e.getElementsByTagName(t)[0];p.parentNode.insertBefore(s,p)}(window,document,'script', 'https://dsp-media.eskimi.com/assets/js/e/gtr.min.js?_=0.0.0.3'); esk('init', '21600');`}
                </script>
            )}
            {isRSA && (
                <script defer={true}>
                    {`!(function (e, t, n, o, a, c, r, l, s, u, f) { e.fbq || (((l = e.dhPixel = function () { l.callMethod ? l.callMethod.apply(l, arguments) : l.queue.push(arguments); }).push = l), (l.queue = []), (a = e.fbq = function () { for (var t = new Array(arguments.length), n = 0; n < t.length; ++n)t[n] = arguments[n]; var o = Math.floor(1e14 * Math.random()); if (!(("track"!==t[0].toLowerCase()&& "trackcustom"!==t[0].toLowerCase())||(t[3]&&t[3].eventID))){t[2]||(t[2]=void 0),"object"==typeof t[3]?(t[3].eventID=o):(t[3]={eventID:o});}if(!(("tracksingle"!==t[0].toLowerCase() && "tracksinglecustom"!==t[0].toLowerCase())||(t[3]&&t[3].eventID)||(t[4]&&t[4].eventID))){t[ 3]||(t[3]=void 0),t[4]||(t[4]=void 0),"object"==typeof t[4]?(t[4].eventID=o):(t[4]={eventID:o});}a.callMethod?a.callMethod.apply(a,t):a.queue.push(t),e.dhPixel(t);}),e._fbq||(e._fbq=a),(a.push=a),(a.loaded=!0),(a.version="2.0"),(a.queue=[]),((c=t.createElement(n)).async=!0),(c.src="https://connect.facebook.net/en_US /fbevents.js"),(r=t.getElementsByTagName(n)[0]).parentNode.insertBefore(c,r),((s=t.createElement(n)).async=!0),(s.src="https://s2s.oldmutual.co.za/static/DhPixel.js"),r.parentNode.insertBefore(s,r));})(window,document,"script"); fbq('init', '325838121657613'); fbq('init', '421021132094940'); fbq('init', '776050069490029');`}
                </script>
            )}
            {isRwanda && (
                <script defer={true} type="text/javascript">
                    {
                        'window.ProtoSettings = { gwId: "gw_949RkrokWyNXbgt2Hucsy3",env: "", onLoad: function () { Proto.show(); Proto.sendTrigger("welcome_message", {sneakpeek: true });}}; var d = document, s = d.createElement("script");s.type = "text/javascript", s.async = true, s.src = "https://app.proto.cx/webchat/client.js";var t = d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s, t);s.addEventListener("load", function () {var p = window.ProtoSettings; Proto.init(p, p.onLoad);});'
                    }
                </script>
            )}
            {isRSA && ( // South Africa GlassBox
                <script defer={true} type="text/javascript" id="_cls_injector">
                    {
                        'var script = document.createElement("script"); script.defer; script.id (http://script.id/) = "_cls_detector"; script.src = "https://cdn.gbqofs.com/mt/old-mutual/p/detector-dom.min.js"; script.setAttribute("data-clsconfig", "reportURI=https://c1001.report.gbss.io/arojc9nf/reporting/2af9842d-53e5-402f-f521-6303f48316ee/cls_report"); document.head.appendChild(script);'
                    }
                </script>
            )}
            {isUT && ( // Unit Trust GlassBox
                <script defer={true} type="text/javascript" id="_cls_injector">
                    {
                        'var script = document.createElement("script"); script.id (http://script.id/) = "_cls_detector"; script.src = "https://cdn.gbqofs.com/mt/old-mutual/p/detector-dom.min.js"; script.setAttribute("data-clsconfig", "reportURI=https://c1001.report.gbss.io/arojc9nf/reporting/2af9842d-53e5-402f-f521-6303f48316ee/cls_report"); document.head.appendChild(script);'
                    }
                </script>
            )}
            {isOMAI && ( // old mutual alternative GlassBox
                <script defer={true} type="text/javascript" id="_cls_injector">
                    {
                        'var script = document.createElement("script"); script id (http://script.id/) = "_cls_detector"; script.src = "https://cdn.gbqofs.com/mt/old-mutual/p/detector-dom.min.js"; script.setAttribute("data-clsconfig", "reportURI=https://c1001.report.gbss.io/arojc9nf/reporting/6d4003cb-0d1f-29a4-e010-1f442b4c56ca/cls_report"); document.head.appendChild(script);'
                    }
                </script>
            )}
            {isBulaTsela && (
                <script defer={true} type="text/javascript">
                    {`!(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WNHLQM8');`}
                </script>
            )}
            {isBulaTsela && <script defer={true} src="https://www.googletagmanager.com/gtag/js?id=G-3R98K3M994"></script>}
            {isBulaTsela && (
                <script defer={true}>
                    {
                        'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "G-3R98K3M994");'
                    }
                </script>
            )}

            {isRSA && ( // ZA OM Homepage
                <script defer={true} type="text/javascript">
                    {`window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []); window._adftrack.push({HttpHost: 'track.adform.net',pm: 3024111,divider: encodeURIComponent('|'),pagename: encodeURIComponent('ZA_OM_Homepage'),order : { itms: [{ productsales: '<insert product sales value here>'}]}});(function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.defer = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`}
                </script>
            )}
            {isRSA && ( // ZA OM BuyOnline
                <script defer={true} type="text/javascript">
                    {`window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []); window._adftrack.push({HttpHost: 'track.adform.net',pm: 3024111,divider: encodeURIComponent('|'),pagename: encodeURIComponent('ZA_OM_BuyOnline'),order : { itms: [{ productsales: '<insert product sales value here>'}]}});(function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.defer = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`}
                </script>
            )}
            {isRSA && ( // OM CallMeBack
                <script defer={true} type="text/javascript">
                    {`window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []); window._adftrack.push({HttpHost: 'track.adform.net',pm: 3024111,divider: encodeURIComponent('|'),pagename: encodeURIComponent('ZA_OM_Call me Back'),order : { itms: [{ productsales: '<insert product sales value here>'}]}});(function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.defer = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`}
                </script>
            )}
            {isRSA && ( // OM Disclaimer Page
                <script defer={true} type="text/javascript">
                    {`window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []); window._adftrack.push({HttpHost: 'track.adform.net',pm: 3024111,divider: encodeURIComponent('|'),pagename: encodeURIComponent('ZA_OM_Disclaimer Page'),order : { itms: [{ productsales: '<insert product sales value here>'}]}});(function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.defer = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`}
                </script>
            )}
        </>
    );
};
