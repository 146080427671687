const PERSONAL_DETAILS_TRANSITION = "taxFreeSavingsAccount/personalDetails/transition/";

export const FORM_SET_VALUES_TRANSITION_PERSONAL_DETAILS = PERSONAL_DETAILS_TRANSITION + "formValue";
export const setPersonalDetailFormValues = () => ({ type: FORM_SET_VALUES_TRANSITION_PERSONAL_DETAILS });

// Transition - leads
export const LEAD_VALID_TRANSITION_PERSONAL_DETAILS = PERSONAL_DETAILS_TRANSITION + "lead";
export const leadValidPersonalDetails = (leadDetails) => ({
  type: LEAD_VALID_TRANSITION_PERSONAL_DETAILS,
  leadDetails,
});

// Events
export const PERSONAL_DETAILS_CLEAR_VALUE = "taxFreeSavingsAccount/personalDetails/clearValue/";
export const PERSONAL_DETAILS_SET_VALUE = "taxFreeSavingsAccount/personalDetails/setValue/";
export const PERSONAL_DETAILS_SET_VALID = "taxFreeSavingsAccount/personalDetails/setValid/";

// Events - Personal Details
export const TITLE_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "title";
export const TITLE_SET_VALID = PERSONAL_DETAILS_SET_VALID + "title";
export const FIRST_NAMES_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "firstNames";
export const FIRST_NAMES_SET_VALID = PERSONAL_DETAILS_SET_VALID + "firstNames";
export const SURNAME_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "surname";
export const SURNAME_SET_VALID = PERSONAL_DETAILS_SET_VALID + "surname";
export const CONTACT_FOR_FEEDBACK_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "contactForFeedback";
export const CONTACT_FOR_FEEDBACK_SET_VALID = PERSONAL_DETAILS_SET_VALID + "contactForFeedback";
export const CONTACT_METHOD_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "contactMethod";
export const CONTACT_METHOD_SET_VALID = PERSONAL_DETAILS_SET_VALID + "contactMethod";
export const setTitleValue = (value) => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = (error) => ({ type: TITLE_SET_VALID, error });
export const setFirstNamesValue = (value) => ({ type: FIRST_NAMES_SET_VALUE, value });
export const setFirstNamesValid = (error) => ({ type: FIRST_NAMES_SET_VALID, error });
export const setSurnameValue = (value) => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = (error) => ({ type: SURNAME_SET_VALID, error });

// Events - Contact details
export const CONTACT_NUMBER_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "contactNumber";
export const CONTACT_NUMBER_SET_VALID = PERSONAL_DETAILS_SET_VALID + "contactNumber";
export const EMAIL_SET_VALUE = PERSONAL_DETAILS_SET_VALUE + "email";
export const EMAIL_SET_VALID = PERSONAL_DETAILS_SET_VALID + "email";
export const setContactNumberValue = (value) => ({ type: CONTACT_NUMBER_SET_VALUE, value });
export const setContactNumberValid = (error) => ({ type: CONTACT_NUMBER_SET_VALID, error });
export const setEmailValue = (value) => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = (error) => ({ type: EMAIL_SET_VALID, error });

// Events - Feedback details
export const setContactForFeedbackValue = (value) => ({ type: CONTACT_FOR_FEEDBACK_SET_VALUE, value });
export const setContactForFeedbackValid = (error) => ({ type: CONTACT_FOR_FEEDBACK_SET_VALID, error });
export const setContactMethodValue = (value) => ({ type: CONTACT_METHOD_SET_VALUE, value });
export const setContactMethodValid = (error) => ({ type: CONTACT_METHOD_SET_VALID, error });

export const RESET_PERSONAL_DETAILS = PERSONAL_DETAILS_SET_VALUE + "resetState";
export const resetPersonalDetails = () => ({ type: RESET_PERSONAL_DETAILS });
