import { combineReducers } from 'redux';
import routing from './routing';
import quote from './quote';
import personalDetails from './personalDetails';
import documents from './documents';
import payment from './payment';
import auth from './auth';
import agent from './agent';
import payloadCollection from './payloadCollection';

export const doctorsPi = combineReducers({
	routing,
	quote,
	personalDetails,
	documents,
	payment,
	auth,
	agent,
	payloadCollection
});
