import {fireGtmEvent} from "../gtmEvents";
import { 
    CUSTOMER_FEEDBACK_SET_VALUE,
    CUSTOMER_QUERY_PARAMETER_SET_VALUE,
    SUBMIT_CUSTOMER_FEEDBACK
} from '../../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm';

const submissionGtmEvent = (submissionName, submissionData) => {
    fireGtmEvent(
        submissionName,
        {
            submissionData
        }
    );
};

export const customersSatisfactionFieldTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState().customerSatisfactionSurvey.customerSatisfactionSubmitForm;
    switch (action.type) {
        case CUSTOMER_FEEDBACK_SET_VALUE:
            submissionGtmEvent('customer_feedback', state.form.customerFeedback);
            break;
        case CUSTOMER_QUERY_PARAMETER_SET_VALUE:
            submissionGtmEvent('customer_query_parameters', state.form.customerQueryParams);
            break;
        case SUBMIT_CUSTOMER_FEEDBACK:
            submissionGtmEvent('submit_feedback', state.form);
            break;
    }

    return result;
};
