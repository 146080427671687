// Transitions
export const FORM_VALID_TRANSITION_TRAVEL_HISTORY = "covidSelfAssessment/travelHistory/transition/formValid";
export const FORM_INVALID_TRANSITION_TRAVEL_HISTORY = "covidSelfAssessment/travelHistory/transition/formInvalid";

export const transitionTravelHistory = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_TRAVEL_HISTORY }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_TRAVEL_HISTORY }),
};

// Events
export const TRAVEL_HISTORY_RESET_FORM = "covidSelfAssessment/travelHistory/resetFrom/";
export const TRAVEL_HISTORY_SET_VALUE = "covidSelfAssessment/travelHistory/setValue/";
export const TRAVEL_HISTORY_SET_VALID = "covidSelfAssessment/travelHistory/setValid/";

export const TRAVELLED_RECENTLY_SET_VALUE = TRAVEL_HISTORY_SET_VALUE + "travelledRecently";
export const TRAVELLED_RECENTLY_SET_VALID = TRAVEL_HISTORY_SET_VALID + "travelledRecently";
export const RECENT_COVID_CONTACT_SET_VALUE = TRAVEL_HISTORY_SET_VALUE + "recentCovidContact";
export const RECENT_COVID_CONTACT_SET_VALID = TRAVEL_HISTORY_SET_VALID + "recentCovidContact";

export const resetTravelHistoryForm = () => ({ type: TRAVEL_HISTORY_RESET_FORM });
export const setTravelledRecentlyValue = (value) => ({ type: TRAVELLED_RECENTLY_SET_VALUE, value });
export const setTravelledRecentlyValid = (error) => ({ type: TRAVELLED_RECENTLY_SET_VALID, error });
export const setRecentCovidContactValue = (value) => ({ type: RECENT_COVID_CONTACT_SET_VALUE, value });
export const setRecentCovidContactValid = (error) => ({ type: RECENT_COVID_CONTACT_SET_VALID, error });
