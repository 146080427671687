import {FORM_STATUS, API_CALL_STATUS, FIELD_STATUS} from "../status";
import {
    FORM_INVALIDATE_TRANSITION_PAYMENTS,
    FORM_VALIDATE_TRANSITION_PAYMENTS,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    API_PENDING_TRANSITION_PAYMENTS,
    API_SUCCESS_TRANSITION_PAYMENTS,
    API_FAILURE_TRANSITION_PAYMENTS,
    MPESA_PAYMENTS_SET_VALUE,
    MPESA_PAYMENTS_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL,
    API_PENDING_TRANSITION_PAYMENTS_STATUS,
    API_SUCCESS_TRANSITION_PAYMENTS_STATUS,
    API_FAILURE_TRANSITION_PAYMENTS_STATUS,
    MPESA_PAYMENTS_STATUS_SET_VALUE,
    MPESA_PAYMENTS_STATUS_SET_VALID,
} from "../../actions/roaCarInsurance/payments";

import {
    TRANSACTION_CODE_SET_VALUE,
    TRANSACTION_CODE_SET_VALID,
    API_PENDING_TRANSITION_MANUAL_MPESA_PAYMENTS,
    API_SUCCESS_TRANSITION_MANUAL_MPESA_PAYMENTS,
    API_FAILURE_TRANSITION_MANUAL_MPESA_PAYMENTS,
    API_INITIALIZE_TRANSITION_MANUAL_MPESA_PAYMENTS,
    SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_SUCCESS,
    SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_FAILURE
} from "../../actions/roaCarInsurance/manualPayments";

export const initPayments = {
    formStatus: FORM_STATUS.INVALID,
    mpesaPayments: {
        value: null, error: null
    },
    mpesaPaymentsStatus: {
        value: null, error: null
    },
    form: {
        phoneNumber: {value: null, error: null, status: null},
        email: {value: null , error: null, status: null},
        transactionCode: {value: null, error: null, status: null}
    },

};

export default (state = initPayments, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId])
        }
    });

    switch (action.type) {
        case FORM_VALIDATE_TRANSITION_PAYMENTS:
            return {...state, formStatus: FORM_STATUS.VALID};
        case FORM_INVALIDATE_TRANSITION_PAYMENTS:
            return {...state, formStatus: FORM_STATUS.INVALID};

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', field => ({
                ...field,
                 value: action.value,
                 error: null
            }));

        case PHONE_NUMBER_SET_VALID:
              return updateFormField('phoneNumber', field => ({
                ...field,
                 error: action.error,
                 status: mapError(action.error)
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('email', field => ({
                ...field,
                value: action.value,
                error: null
            }));



        case EMAIL_SET_VALID:
            return updateFormField('email', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }));
        case TRANSACTION_CODE_SET_VALUE:
            return updateFormField('transactionCode', field => ({
                ...field,
                    value: action.value,
                    error: null
            }));
        case TRANSACTION_CODE_SET_VALID:
                return updateFormField('transactionCode', field => ({
                ...field,
                    error: action.error,
                    status: mapError(action.error)
            }));
        case API_PENDING_TRANSITION_PAYMENTS:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_PAYMENTS:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_PAYMENTS:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };
        case API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL:
            return { ...state, apiCallPaymentStatus: null };
        case MPESA_PAYMENTS_SET_VALUE:
            return {...state, mpesaPayments:{...state.mpesaPayments, value: action.value, error: null}}
        case MPESA_PAYMENTS_SET_VALID:
            return {...state, mpesaPayments:{...state.mpesaPayments, error: action.error}}

        //PAYMENT STATUS
         case API_PENDING_TRANSITION_PAYMENTS_STATUS:
            return { ...state, apiCallPaymentStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_PAYMENTS_STATUS:
            return { ...state,  apiCallPaymentStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_PAYMENTS_STATUS:
            return { ...state, apiCallPaymentStatus: API_CALL_STATUS.FAILURE };
        case MPESA_PAYMENTS_STATUS_SET_VALUE:
            return {...state, mpesaPaymentsStatus:{...state.mpesaPaymentsStatus, value: action.value, error: null}}
        case MPESA_PAYMENTS_STATUS_SET_VALID:
            return {...state, mpesaPaymentsStatus:{...state.mpesaPaymentsStatus, error: action.error}}

        // Manual payments API status
        case API_PENDING_TRANSITION_MANUAL_MPESA_PAYMENTS:
            return { ...state, manualPaymentsApiStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_MANUAL_MPESA_PAYMENTS:
            return { ...state, manualPaymentsApiStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_MANUAL_MPESA_PAYMENTS:
            return { ...state, manualPaymentsApiStatus: API_CALL_STATUS.FAILURE };
        case API_INITIALIZE_TRANSITION_MANUAL_MPESA_PAYMENTS:
                return {
                    ...state,
                    manualPaymentsApiStatus: null,
                };
        case SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_SUCCESS:
            return {
                ...state,
                manualMpesaPaymentsResponse: {
                    ...state.manualMpesaPaymentsResponse,
                    value: action.value,
                    error: null,
                },
            };
        case SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_FAILURE:
            return {
                ...state,
                manualMpesaPaymentsResponse: {
                    ...state.manualMpesaPaymentsResponse,
                    value: null,
                    error: action.error,
                },
            };

        default:
            return state;
    }
};
