import {createSelector} from "reselect";
import {getCustomerVerificationService} from "./index";
import {FORM_STATUS} from "../../reducers/status";

export const getConsent = createSelector(getCustomerVerificationService, cvs => cvs.consent);

// service provider
export const getServiceProvider = createSelector(
    getConsent,
    consent => consent.serviceProvider,
);

// checkboxes
export const getPrivacyPolicyIsChecked = createSelector(
    getConsent,
    consent => consent.privacyPolicyIsChecked,
);
export const getSubscriberAgreementIsChecked = createSelector(
    getConsent,
    consent => consent.subscriberAgreementIsChecked,
);
export const getTermOfUseIsChecked = createSelector(
    getConsent,
    consent => consent.termOfUseIsChecked,
);

// side drawers
export const getPrivacyPolicyDrawerIsOpen = createSelector(
    getConsent,
    consent => consent.sideDrawerIsOpen.privacyPolicy,
);
export const getSubscriberAgreementDrawerIsOpen = createSelector(
    getConsent,
    consent => consent.sideDrawerIsOpen.subscriberAgreement,
);
export const getTermOfUseDrawerIsOpen = createSelector(
    getConsent,
    consent => consent.sideDrawerIsOpen.termsOfUse,
);

// form status
export const getConsentFormIsValid = createSelector(
    getPrivacyPolicyIsChecked,
    getSubscriberAgreementIsChecked,
    getTermOfUseIsChecked,
    (privacyCheck, subscriberCheck, termsCheck) => privacyCheck && subscriberCheck && termsCheck,
);
export const getConsentPageIsValid = createSelector(
    getConsent,
    consent => consent.formStatus === FORM_STATUS.VALID,
);

export const getTermsModalIsOpen = createSelector(
    getConsent,
    consent => consent.termsModalIsOpen,
);