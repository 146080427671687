import { FIELD_STATUS } from "./status";

export const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

export const updateFormField = (state, fieldId, update) => ({
    ...state,
    form: {
        ...state.form,
        [fieldId]: update(state.form[fieldId]),
    },
});
