import {
    INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS,
    BACK_TRANSITION_CAPTURE_DETAILS,
    CONTINUE_TRANSITION_CAPTURE_DETAILS,
    MOBILE_SET_VALUE,
    NAME_SET_VALUE,
    SURNAME_SET_VALUE,
} from "../../../actions/raOptimal/captureDetails";
import {getCaptureDetailsForm, getMobile, getName, getSurname} from "../../../selectors/raOptimal/captureDetails";
import {simpleFieldTracking} from "../formTracking";
import {transitionTracking} from "../transitionTracking";
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";

export const captureDetailsFieldTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    const fieldSelector = (() => {
        switch (action.type) {
            case NAME_SET_VALUE: return getName;
            case SURNAME_SET_VALUE: return getSurname;
            case MOBILE_SET_VALUE: return getMobile;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        const sendValue = false;
        simpleFieldTracking({action, field, sendValue});
    }

    return result;
};

export const captureDetailsTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const trackingActions = [
        INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS,
        CONTINUE_TRANSITION_CAPTURE_DETAILS,
        BACK_TRANSITION_CAPTURE_DETAILS,
    ];
    const captureDetailsForm = getCaptureDetailsForm(state);
    // capture relevant tracking data -- i.e. remove value from form fields
    const trackingData = Object.keys(captureDetailsForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: captureDetailsForm[key].status,
                error: captureDetailsForm[key].error,
            }
        }), {});

    if (trackingActions.includes(action.type))
        transitionTracking(action.type, trackingData);

    return result;
}