import { ACCIDENTAL_DISABILITY_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/accidentalDisabilityQuoteTool';
import { accidentalDisabilityQuoteToolValidation } from './accidentalDisabilityQuoteTool';
import { ELIMIKA_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/elimikaQuoteTool';
import { elimikaQuoteToolValidation } from './elimikaQuoteTool';
import { PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/premierSavingsQuoteTool';
import { premierSavingsQuoteToolValidation } from './premierSavingsQuoteTool';
import { AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/afyaImaraJuniorQuoteTool';
import { afyaImaraJuniorQuoteToolValidation } from './afyaImaraJuniorQuoteTool';
import { AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool';
import { afyaImaraGertrudeJuniorQuoteToolValidation } from './afyaImaraGertrudeJuniorQuoteTool';
import { ACCIDENTAL_DEATH_COVER_QUOTE_TOOL } from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool';
import { accidentalDeathCoverQuoteToolValidation } from './accidentalDeathCoverQuoteTool';
import { WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';
import { wholeLifeCoverQuoteToolValidation } from './wholeLifeCoverQuoteTool';
import { PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/physicalImpairmentCoverQuoteTool';
import { physicalImpairmentCoverQuoteToolValidation } from './physicalImpairmentCoverQuoteTool';
import { DEATH_TERM_COVER_QUOTE_TOOL } from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import { deathTermCoverQuoteToolValidation } from './deathTermQuoteTool';
import { CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/criticalIllnessQuoteTool';
import { CriticalIllnessToolValidation } from './criticalIllnessQuoteTool';
import { AfyaImara_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/afyaImaraQuoteTool';
import { afyaImaraQuoteToolValidation } from './afyaImaraQuoteTool';
import { County_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/afyaImaraCountyQuoteTool';
import { afyaImaraCountyToolValidation } from './afyaImaraCountyQuoteTool';
import { MVEST_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/mvestQuoteTool';
import { mvestToolValidation } from './mvestQuoteTool';
import { GROUPLIFE_QUOTE_TOOL_SET_VALUE } from "../../../actions/roaQuoteTools/groupLifeQuoteTool";
import { groupLifeQuoteToolValidation } from "./groupLifeQuoteToolValidation";
import { DIGILIFE_QUOTE_TOOL_SET_VALUE } from '../../../actions/roaQuoteTools/digiLifeQuoteTool';
import { digiLifeQuoteToolValidation } from './digiLifeQuoteTool';

export const roaQuoteToolValidation = (store, next, action) => {
	if (action.type.startsWith(ELIMIKA_QUOTE_TOOL_SET_VALUE))
		return elimikaQuoteToolValidation(store, next, action);

	if (action.type.startsWith(PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE))
		return premierSavingsQuoteToolValidation(store, next, action);

	if (action.type.startsWith(ACCIDENTAL_DISABILITY_TOOL_SET_VALUE))
		return accidentalDisabilityQuoteToolValidation(store, next, action);

	if (action.type.startsWith(AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE))
		return afyaImaraJuniorQuoteToolValidation(store, next, action);

	if (action.type.startsWith(PHYSICAL_IMPAIRMENT_COVER_QUOTE_TOOL_SET_VALUE))
		return physicalImpairmentCoverQuoteToolValidation(store, next, action);

	if (action.type.startsWith(AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE))
		return afyaImaraGertrudeJuniorQuoteToolValidation(store, next, action);

	if (action.type.startsWith(ACCIDENTAL_DEATH_COVER_QUOTE_TOOL))
		return accidentalDeathCoverQuoteToolValidation(store, next, action);

	if (action.type.startsWith(WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE))
		return wholeLifeCoverQuoteToolValidation(store, next, action);

	if (action.type.startsWith(DEATH_TERM_COVER_QUOTE_TOOL))
		return deathTermCoverQuoteToolValidation(store, next, action);

	if (action.type.startsWith(CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE))
		return CriticalIllnessToolValidation(store, next, action);
        
	if (action.type.startsWith(AfyaImara_QUOTE_TOOL_SET_VALUE))
		return afyaImaraQuoteToolValidation(store, next, action);

    if (action.type.startsWith(County_QUOTE_TOOL_SET_VALUE))
        return afyaImaraCountyToolValidation(store, next, action);
	
	if (action.type.startsWith(GROUPLIFE_QUOTE_TOOL_SET_VALUE)){
		return groupLifeQuoteToolValidation(store, next, action);
	}

	if (action.type.startsWith(MVEST_QUOTE_TOOL_SET_VALUE)){
		return mvestToolValidation(store, next, action);
	}
	if (action.type.startsWith(DIGILIFE_QUOTE_TOOL_SET_VALUE)){
		return digiLifeQuoteToolValidation(store, next, action);
	}
	
    return null;
};
