import { createSelector } from 'reselect';
import { getPersonalAccidentState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getChildDetails = createSelector(getPersonalAccidentState, (state) => state.childDetails);

export const getChildDetailsForms = createSelector(getChildDetails, (childDetails) => childDetails.form);

export const getChildDetailsFormValid = createSelector(getChildDetails, (childDetails) => {
    const { formStatus } = childDetails;

    if (formStatus.length > 1) {
        const allValid = formStatus.every((status) => status === FORM_STATUS.VALID);
        return allValid;
    } else if (formStatus.length === 1) {
        return formStatus[0] === FORM_STATUS.VALID;
    } else {
        return true;
    }
});

export const getSingleChildDetailsFormValid = (index) =>
    createSelector(getChildDetails, (childDetails) => {
        const { formStatus } = childDetails;

        return index === null ? false : formStatus[index] === FORM_STATUS.VALID;
    });

export const getSelectedChildFormIndex = createSelector(
    getChildDetails,
    (childDetails) => childDetails.selectedChildFormIndex
);

export const getIsApiPending = createSelector(
    getChildDetails,
    (childDetails) => childDetails.apiCallStatus === API_CALL_STATUS.PENDING
);

const makeGetFieldForm = (fieldId, index) => createSelector(getChildDetailsForms, (form) => form[index][fieldId]);

export const getFirstNameValue = (index) => makeGetFieldForm('firstName', index);
export const getLastNameValue = (index) => makeGetFieldForm('lastName', index);
export const getDOBValue = (index) => makeGetFieldForm('dob', index);
export const getStreetAddressValue = (index) => makeGetFieldForm('streetAddress', index);
export const getSuburbValue = (index) => makeGetFieldForm('suburb', index);
export const getCityValue = (index) => makeGetFieldForm('city', index);
export const getPostalAddressValue = (index) => makeGetFieldForm('postalAddress', index);
