export const UG_MOTOR_INSURANCE_DECLARATIONS_FORM = 'ugMotorInsurance/declarationsForm/';

export const MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE = UG_MOTOR_INSURANCE_DECLARATIONS_FORM + 'setValue/';
export const MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID = UG_MOTOR_INSURANCE_DECLARATIONS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM = 'ugMotorInsurance/declarationForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM = 'ugMotorInsurance/declarationForm/transition/formInValid';

export const REINITIALIZE = 'ugMotorInsurance/declarationForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DECLARATIONS_FORM = 'ugMotorInsurance/declarationForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_DECLARATIONS_FORM,
    value,
});

export const transitionMotorInsuranceDeclarationsForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_DECLARATIONS_FORM = `${MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DECLARATIONS_FORM = `${MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DECLARATIONS_FORM = `${MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDocumentsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_DECLARATIONS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_DECLARATIONS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_DECLARATIONS_FORM,
    }),
};

//calculated Data
export const COLLECT_DECLARATIONS_FORM_DATA = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DECLARATIONS_SUCCESS = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'success';
export const SUBMIT_DECLARATIONS_FAILURE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
    type: COLLECT_DECLARATIONS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
    type: SUBMIT_DECLARATIONS_SUCCESS,
    value,
});
export const submitDocumentsFailure = (error) => ({
    type: SUBMIT_DECLARATIONS_FAILURE,
    error,
});

// Form Values
export const DECLINED_PROPOSAL_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'declinedProposal';
export const DECLINED_PROPOSAL_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'declinedProposal';

export const INCREASED_PREMIUMS_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'increasedPremiums';
export const INCREASED_PREMIUMS_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'increasedPremiums';

export const POLICY_REJECTED_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'policyRejected';
export const POLICY_REJECTED_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'policyRejected';

export const DEFECT_VISION_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'defectVision';
export const DEFECT_VISION_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'defectVision';

export const CONVICTED_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'convicted';
export const CONVICTED_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'convicted';

export const STICKER_COLLECTION_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'stickerCollection';
export const STICKER_COLLECTION_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'stickerCollection';

export const MODE_OF_PAYMENT_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'modeOfPayment';
export const MODE_OF_PAYMENT_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'modeOfPayment';

export const SOURCE_OF_FUNDS_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'sourceOfFunds';
export const SOURCE_OF_FUNDS_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID + 'sourceOfFunds';

export const OTHER_SOURCE_OF_FUNDS_SET_VALUE = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'otherSourceOfFunds';
export const OTHER_SOURCE_OF_FUNDS_SET_VALID = MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE + 'otherSourceOfFunds';

// action creators
export const setDeclinedProposalValue = (value) => ({
    type: DECLINED_PROPOSAL_SET_VALUE,
    value,
});

export const setDeclinedProposalValid = (error) => ({
    type: DECLINED_PROPOSAL_SET_VALID,
    error,
});

export const setIncreasedPremiumsValue = (value) => ({
    type: INCREASED_PREMIUMS_SET_VALUE,
    value,
});

export const setIncreasedPremiumsValid = (error) => ({
    type: INCREASED_PREMIUMS_SET_VALID,
    error,
});

export const setPolicyRejectedValue = (value) => ({
    type: POLICY_REJECTED_SET_VALUE,
    value,
});

export const setPolicyRejectedValid = (error) => ({
    type: POLICY_REJECTED_SET_VALID,
    error,
});

export const setDefectVisionValue = (value) => ({
    type: DEFECT_VISION_SET_VALUE,
    value,
});

export const setDefectVisionValid = (error) => ({
    type: DEFECT_VISION_SET_VALID,
    error,
});

export const setConvictedValue = (value) => ({
    type: CONVICTED_SET_VALUE,
    value,
});

export const setConvictedValid = (error) => ({
    type: CONVICTED_SET_VALID,
    error,
});

export const setStickerCollectionValue = (value) => ({
    type: STICKER_COLLECTION_SET_VALUE,
    value,
});

export const setStickerCollectionValid = (error) => ({
    type: STICKER_COLLECTION_SET_VALID,
    error,
});

export const setModeOfPaymentValue = (value) => ({
    type: MODE_OF_PAYMENT_SET_VALUE,
    value,
});

export const setModeOfPaymentValid = (error) => ({
    type: MODE_OF_PAYMENT_SET_VALID,
    error,
});

export const setSourceOfFundsValue = (value) => ({
    type: SOURCE_OF_FUNDS_SET_VALUE,
    value,
});

export const setSourceOfFundsValid = (error) => ({
    type: SOURCE_OF_FUNDS_SET_VALID,
    error,
});

export const setOtherSourceOfFundsValue = (value) => ({
    type: OTHER_SOURCE_OF_FUNDS_SET_VALUE,
    value,
});

export const setOtherSourceOfFundsValid = (error) => ({
    type: OTHER_SOURCE_OF_FUNDS_SET_VALID,
    error,
});
