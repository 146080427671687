import { transitionMotorInsuranceDeclarationsForm } from '../../../actions/motorPrivateRW/declarations';
import { getDeclarationsForm } from '../../../selectors/motorPrivateRW/declarations';
import { FIELD_STATUS } from '../../../reducers/status';

export const declarationsFormRules = (store, next, action) => {
    const result = next(action);
    const declarationsForm = getDeclarationsForm(store.getState());
    const declarationsFormValid = validateDeclarationsFormDetails(declarationsForm);

    const formAction = declarationsFormValid
        ? transitionMotorInsuranceDeclarationsForm.formValid()
        : transitionMotorInsuranceDeclarationsForm.formInvalid();

    store.dispatch(formAction);
    return result;
};

const validateDeclarationsFormDetails = (formFields) => {
    // Validate each field in the declarations form
    return (
        formFields.driverHealthDeclaration &&
        formFields.driverHealthDeclaration.value === true &&
        formFields.vehicleConditionDeclaration &&
        formFields.vehicleConditionDeclaration.value === true &&
        formFields.driverLicenseDeclaration &&
        formFields.driverLicenseDeclaration.value === true &&
        formFields.averageClauseConsent &&
        formFields.averageClauseConsent.value === true &&
        formFields.fullValueDeclaration &&
        formFields.fullValueDeclaration.value === true &&
        formFields.privacyPolicyConfirmation &&
        formFields.privacyPolicyConfirmation.value === true &&
        formFields.adviceAcknowledgementIndemnity &&
        formFields.adviceAcknowledgementIndemnity.value === true &&
        formFields.noPremiumRefundAcknowledgement &&
        formFields.noPremiumRefundAcknowledgement.value === true &&
        formFields.fullPremiumSettlementConsent &&
        formFields.fullPremiumSettlementConsent.value === true &&
        formFields.finalDeclarationAgreement &&
        formFields.finalDeclarationAgreement.value === true
    );
};
