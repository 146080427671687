import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    FIRST_NAME_SET_VALUE,
    FIRST_NAME_SET_VALID,
    LAST_NAME_SET_VALUE,
    LAST_NAME_SET_VALID,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    NATIONALITY_SET_VALUE,
    NATIONALITY_SET_VALID,
    OCCUPATION_SET_VALUE,
    OCCUPATION_SET_VALID,
    ID_NUMBER_SET_VALUE,
    ID_NUMBER_SET_VALID,
    TIN_NUMBER_SET_VALUE,
    TIN_NUMBER_SET_VALID,
    POSTAL_ADDRESS_SET_VALUE,
    POSTAL_ADDRESS_SET_VALID,
    FORM_VALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM,
    FORM_INVALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM,
    COLLECT_PERSONAL_DETAILS_FORM_DATA,
    API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM,
    API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM,
    API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM,
    SUBMIT_PERSONAL_DETAILS_SUCCESS,
    SUBMIT_PERSONAL_DETAILS_FAILURE,
    STREET_ADDRESS_SET_VALUE,
    STREET_ADDRESS_SET_VALID,
    SUBURBS_SET_VALUE,
    SUBURBS_SET_VALID,
    CITY_SET_VALUE,
    CITY_SET_VALID,
    PO_BOX_SET_VALUE,
    PO_BOX_SET_VALID,
    POSTAL_CODE_SET_VALUE,
    POSTAL_CODE_SET_VALID
} from '../../actions/domesticInsurance/personalDetails';


export const initPersonal = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    personalDetailsReferenceData: null,
    apiCallStatus: null,
    form: {
        gender: { value: null, error: null, status: null },
        firstName: { value: null, error: null, status: null },
        lastName: { value: null, error: null, status: null },
        dob: { value: null, error: null, status: null },
        nationality: { value: null, error: null, status: null },
        occupation: { value: null, error: null, status: null },
        idNumber: { value: null, error: null, status: null },
        tinNumber: { value: null, error: null, status: null },
        phoneNumber: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        postalAddress: { value: null, error: null, status: null },
        streetAddress: {value: null, error: null, status: null},
        suburbs: {value: null, error: null, status: null},
        city: {value: null, error: null, status: null},
        pobox: {value: null, error: null, status: null},
        postalCode: {value: null, error: null, status: null}
    },
};

export default (state = initPersonal, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_DOMESTIC_INSURANCE_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case DOB_SET_VALUE:
            return updateFormField('dob', (field) => ({
                ...field,
                value: action.value,
            }));

        case DOB_SET_VALID:
            return updateFormField('dob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LAST_NAME_SET_VALUE:
            return updateFormField('lastName', (field) => ({
                ...field,
                value: action.value,
            }));
        case LAST_NAME_SET_VALID:
            return updateFormField('lastName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));

        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMAIL_SET_VALUE:
            return updateFormField('email', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMAIL_SET_VALID:
            return updateFormField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NATIONALITY_SET_VALUE:
            return updateFormField('nationality', (field) => ({
                ...field,
                value: action.value,
            }));

        case NATIONALITY_SET_VALID:
            return updateFormField('nationality', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case OCCUPATION_SET_VALUE:
            return updateFormField('occupation', (field) => ({
                ...field,
                value: action.value,
            }));

        case OCCUPATION_SET_VALID:
            return updateFormField('occupation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_NUMBER_SET_VALUE:
            return updateFormField('idNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_NUMBER_SET_VALID:
            return updateFormField('idNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TIN_NUMBER_SET_VALUE:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case TIN_NUMBER_SET_VALID:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case POSTAL_ADDRESS_SET_VALUE:
            return updateFormField('postalAddress', (field) => ({
                ...field,
                value: action.value,
            }));

        case POSTAL_ADDRESS_SET_VALID:
            return updateFormField('postalAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case STREET_ADDRESS_SET_VALUE:
            return updateFormField('streetAddress', (field) => ({
                ...field,
                value: action.value,
            }));

        case STREET_ADDRESS_SET_VALID:
            return updateFormField('streetAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SUBURBS_SET_VALUE:
            return updateFormField('suburbs', (field) => ({
                ...field,
                value: action.value,
            }));

        case SUBURBS_SET_VALID:
            return updateFormField('suburbs', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CITY_SET_VALUE:
            return updateFormField('city', (field) => ({
                ...field,
                value: action.value,
            }));

        case CITY_SET_VALID:
            return updateFormField('city', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PO_BOX_SET_VALUE:
            return updateFormField('pobox', (field) => ({
                ...field,
                value: action.value,
            }));

        case PO_BOX_SET_VALID:
            return updateFormField('pobox', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case POSTAL_CODE_SET_VALUE:
            return updateFormField('postalCode', (field) => ({
                ...field,
                value: action.value,
            }));

        case POSTAL_CODE_SET_VALID:
            return updateFormField('postalCode', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        //    API Calls
        case API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_PERSONAL_DETAILS_FORM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case SUBMIT_PERSONAL_DETAILS_SUCCESS:
            return { ...state, personalDetailsReferenceData: action.value };
        case SUBMIT_PERSONAL_DETAILS_FAILURE:
            return { ...state, personalDetailsReferenceData: action.error };

        // Reset the state
        case REINITIALIZE:
            return initPersonal;
        default:
            return state;
    }
};
