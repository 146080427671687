import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { setValidationResults } from '../../../actions/digitalOnboarding/employmentDetails';
import { SECTOR, EMPLOYMENT_STATUS, EMPLOYMENT_START_DATE, EMPLOYMENT_CONTRACT_END, BANK_NAME, DEBT_LIABILITY } from '../../../actions/digitalOnboarding/types';
import { getFullBirthYear } from '../../../functions/digitalOnboarding/validations';
import { isContractEmployment, contractEndDate, employmentStartDate, contractEndDateIsPristine, employmentStartDateIsPristine } from '../../../selectors/digitalOnboarding/employment';
import { getIdNumber} from '../../../selectors/digitalOnboarding/personal';

dayjs.extend(isSameOrAfter)

const isValidString = str => str && str.length > 0;

const runValidations = (state, action) => {
    const validateBankName = value => {
        const isValid = isValidString(value);
        const errorMessage = isValid ? '' : 'Please choose name of the bank you bank with.';

        return [{ key: BANK_NAME, isValid, errorMessage }];
    }

    const validateEmploymentSector = value => {
        const isValid = isValidString(value);
        const errorMessage = isValid ? '' : 'Please choose the sector you work in.';

        return [{ key: SECTOR, isValid, errorMessage }];
    }

    const validateDebtLiability = value =>{
        const isValid = isValidString(value);
        const errorMessage = isValid ? '' : 'Please make a selection from the list.';

        return [{ key: DEBT_LIABILITY, isValid, errorMessage }];
    }

    const validateContractEndDate = value => {
        const startDate = employmentStartDate(state);
        const ageAtDateValue = ageAtDate(value);
        const isValid = dayjs(value).isSameOrAfter(startDate) && ageAtDateValue >= 16;
        const isEmpty = !isValidString(value);
        const errorMessage = isValid
            ? ''
            : isEmpty
                ? 'Please select your contract end date.'
                : ageAtDateValue < 16
                ? 'Date cannot be before the applicant was 16 years.'
                : 'Cannot select a date before contract start date.';

        return [{ key: EMPLOYMENT_CONTRACT_END, isValid, errorMessage }];
    }

    const validateEmploymentStartDate = value => {
        const isContract = isContractEmployment(state);
        const generateEmptyErrorMessage = () => isContract ? 'Please select your contract start date.' : 'Please select your employment start date.';

        let isContractStartSameAsEnd = false;
        if (isContract) {
            const endDate = contractEndDate(state);
            isContractStartSameAsEnd = dayjs(value).isSame(endDate, 'day');
        }
        const ageAtDateValue = ageAtDate(value);
        const isContractStartInThePast = dayjs().isAfter(value);
        const isValid = isContractStartInThePast && !isContractStartSameAsEnd && ageAtDateValue >= 16;
        const isEmpty = !isValidString(value);
        const errorMessage = isValid
            ? ''
            : isEmpty
                ? generateEmptyErrorMessage()
                : ageAtDateValue < 16
                ? 'Date cannot be before the applicant was 16 years.'
                : !isContractStartInThePast
                    ? 'Cannot select a future date.'
                    : 'Contract start date cannot be same date as contract end date.';

        return [{ key: EMPLOYMENT_START_DATE, isValid, errorMessage }];
    }

    const ageAtDate = (compareDate) => {
        const idNumber = getIdNumber(state);
        const birthYear = getFullBirthYear(parseInt(idNumber.substring(0, 2)));
        const birthDate = dayjs(`${birthYear}-${parseInt(idNumber.substring(2, 4))}-${parseInt(idNumber.substring(4, 6))}`);
        const contractDate = dayjs(compareDate);
        return contractDate.diff(birthDate, 'years');
    }

    const validateEmploymentStatus = value => {
        const validEmploymentStatuses = ['Permanent', 'Contract'];

        const isValid = validEmploymentStatuses.includes(value);
        const isEmpty = !isValidString(value);
        const errorMessage = isValid
            ? ''
            : isEmpty
                ? 'Please choose your employment status.'
                : 'According to our lending employment criteria, customers must be permanently employed or contracted for at least three months. Please apply again if your circumstances change.';

        return [{ key: EMPLOYMENT_STATUS, isValid, errorMessage }];
    }

    const noValidationRequired = key => {
        return [{ key, isValid: true, errorMessage: '' }];
    }

    switch (action.key) {
        case SECTOR:
            return validateEmploymentSector(action.value);
        case EMPLOYMENT_STATUS: {
            const result = validateEmploymentStatus(action.value);
            if (result[0].isValid) {
                if (!employmentStartDateIsPristine(state)) {
                    // Re-validate the start date as the status change causes error text error to change
                    result.push(...validateEmploymentStartDate(employmentStartDate(state)));
                }
                if (isContractEmployment(state) && !contractEndDateIsPristine(state)) {
                    // If we are switching back to contract, validate the contract end date again
                    result.push(...validateContractEndDate(contractEndDate(state)));
                }else if(isContractEmployment(state) && contractEndDateIsPristine(state)) {
                    // When selecting contract and end date hasn't been selected yet, the end date needs to be set to invalid 
                    // else the next button will be active with no end date selected
                    result.push({ key: EMPLOYMENT_CONTRACT_END, value: '', isValid: false, errorMessage: '' });
                }else {
                    // If this is permanent employment, clear any error from the contract end date that might
                    // be held over from a previous set
                    result.push({ key: EMPLOYMENT_CONTRACT_END, value: '', isValid: true, errorMessage: '' });
                }
            }
            return result;
        }
        case EMPLOYMENT_START_DATE: {
            const result = validateEmploymentStartDate(action.value);
            if (isContractEmployment(state)) {
                result.push(...validateContractEndDate(contractEndDate(state)));
            }
            return result;
        }
        case EMPLOYMENT_CONTRACT_END:
            return [ ...validateContractEndDate(action.value), ...validateEmploymentStartDate(employmentStartDate(state))];
        case BANK_NAME:
            return validateBankName(action.value);
        case DEBT_LIABILITY:
            return validateDebtLiability(action.value);
    }

    return noValidationRequired(action.key);
}


export const employmentValidation = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const validations = runValidations(state, action);
    store.dispatch(setValidationResults(validations));

    return result;
}