import {
    validateNumber,
    validateFirstName,
    validateAge,
} from  "@om/validation";
import {
    setNameValid,
    setAgeValid,
    setGenderValid,
    setRetirementForValid,
    setPartnerAgeValid,
    setRetirementWorriesValid,
    setSalaryValid,
    setSavingsValid,
    setBudgetValid,
    NAME_SET_VALUE,
    AGE_SET_VALUE,
    GENDER_SET_VALUE,
    RETIREMENT_FOR_SET_VALUE,
    PARTNER_AGE_SET_VALUE,
    RETIREMENT_WORRIES_SET_VALUE,
    SALARY_SET_VALUE,
    SAVINGS_SET_VALUE,
    BUDGET_SET_VALUE,
} from "../../../actions/atRetirement/aboutYou";

export const aboutYouValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case NAME_SET_VALUE:
                error = validateFirstName(action.value);
                return setNameValid(error);

            case AGE_SET_VALUE:
                error = validateAge(action.value, { min: 40, max: 100 });
                return setAgeValid(error);

            case GENDER_SET_VALUE:
                // This is a radio button, therefore if fired, it will be valid
                return setGenderValid(null);

            case RETIREMENT_FOR_SET_VALUE:
                // This is a radio button, therefore if fired, it will be valid
                return setRetirementForValid(null);

            case PARTNER_AGE_SET_VALUE:
                error = validateAge(action.value, { min: 40, max: 100 });
                return setPartnerAgeValid(error);

            case RETIREMENT_WORRIES_SET_VALUE:
                // This is a radio button, therefore if fired, it will be valid
                return setRetirementWorriesValid(null);

            case SALARY_SET_VALUE:
                error = validateNumber(action.value, { min: 100, max: 999999999, decimalPoints: 0 });
                return setSalaryValid(error);

            case SAVINGS_SET_VALUE:
                error = validateNumber(action.value, { min: 15000, max: 999999999, decimalPoints: 0 });
                return setSavingsValid(error);

            case BUDGET_SET_VALUE:
                error = validateNumber(action.value , { min: 1000, max: 999999999, decimalPoints: 0 });
                return setBudgetValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
