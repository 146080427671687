import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './reducers';
import { orderedMiddleware } from './middleware';
import atRetirementInitialState from './reducers/atRetirement/initState';
import raOptimalInitialState from './reducers/raOptimal/initState';
import easiplusFuneralPlanInitialState from './reducers/easiplusFuneralPlan/initState';
import taxFreeSavingsAccountInitialState from './reducers/taxFreeSavingsAccount/initState';
import roaCarInsuranceInitialState from './reducers/roaCarInsurance/initState';
import financialEducationInitialState from './reducers/financialEducation/initState';
import quizInitialState from './reducers/quizAssessment/initState';
import expireReducer from 'redux-persist-expire';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import ugMotorInsuranceInitialState from './reducers/ugMotorInsurance/initState';
import axios from 'axios';
import digiLifeLoanProtectionState from './reducers/digiLifeLoanProtection/initState';
import doctorsPiInitialState from './reducers/doctorsPi/initState';
import motorPrivateInitState from './reducers/motorPrivateRW/initState';
import biasharaFlexiInitialState from './reducers/biasharaFlexi/initState';
import healthInsuranceInitialState from './reducers/healthInsuranceRW/initState';

const digitalOnboardingBlacklist = createBlacklistFilter('digitalOnboarding', ['lookupData', 'documentStore']);

const expireAfter = 12 * 60 * 60; // expire in 12 hours
const finEdExpireAfter = 24 * 60 * 60;
const roaExpireAfter = 0.5 * 60;
const persistConfig = {
    key: 'root',
    whitelist: [
        'atRetirement',
        'raOptimal',
        'easiplusFuneralPlan',
        'customerVerificationService',
        'taxFreeSavingsAccount',
        'roaCarInsurance',
        'financialEducation',
        'quizAssessment',
        'digitalOnboarding',
        'ugMotorInsurance',
        'biasharaFlexi',
        'doctorsPi',
        'motorPrivateRW'
    ],
    // 'storage' defaults to localStorage for web
    storage,
    transforms: [
        expireReducer('atRetirement', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: atRetirementInitialState(),
            autoExpire: false,
        }),
        expireReducer('raOptimal', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: raOptimalInitialState(),
            autoExpire: false,
        }),
        expireReducer('easiplusFuneralPlan', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: easiplusFuneralPlanInitialState(),
            autoExpire: false,
        }),
        expireReducer('taxFreeSavingsAccount', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: taxFreeSavingsAccountInitialState(),
            autoExpire: false,
        }),
        expireReducer('roaCarInsurance', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: roaCarInsuranceInitialState(),
            autoExpire: false,
        }),
        expireReducer('financialEducation', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: financialEducationInitialState(),
            autoExpire: false,
        }),
        expireReducer('quizAssessment', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: quizInitialState(),
            autoExpire: false,
        }),
        expireReducer('digitalOnboarding', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            autoExpire: false,
        }),
        expireReducer('digitalLifeLoanProtection', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: digiLifeLoanProtectionState(),
        }),
        expireReducer('ugMotorInsurance', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: ugMotorInsuranceInitialState(),
            autoExpire: false,
        }),
        expireReducer('biasharaFlexi', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: biasharaFlexiInitialState(),
            autoExpire: false,
        }),
        expireReducer('doctorsPi', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: doctorsPiInitialState(),
            autoExpire: false,
        }),
        expireReducer('motorPrivateRW', {
            expireSeconds: expireAfter,
            persistedAtKey: 'persistedAt',
            expiredState: motorPrivateInitState(),
            autoExpire: false,
        }),
        digitalOnboardingBlacklist,
    ],
};

export const storeCreater = (isDevelop, teamEnvironment) => {
    const enhancementComposer = isDevelop ? composeWithDevTools : compose;
    const persistedRootReducer = persistReducer(persistConfig, rootReducer);
    const preloadedState = {};
    const middlewareEnhancer = applyMiddleware(...orderedMiddleware);
    if (isDevelop) {
        axios.defaults.baseURL = 'https://goals-qa.digital.omapps.net:8080' + teamEnvironment;
        // axios.defaults.baseURL = 'http://localhost:8080' + teamEnvironment;
    } else {
        axios.defaults.baseURL = 'https://goals-api.my.oldmutual.co.za';
    }

    const store = createStore(persistedRootReducer, preloadedState, enhancementComposer(middlewareEnhancer));

    persistStore(store);
    return store;
};

const isDevelop = ['qa', 'dev', 'local'].includes(process.env.GATSBY_ENVIRONMENT_NAME);
const teamEnvironment =
    isDevelop && process.env.GATSBY_TEAM_ENVIRONMENT_NAME ? '/' + process.env.GATSBY_TEAM_ENVIRONMENT_NAME : '';
export const store = storeCreater(isDevelop, teamEnvironment);
