import {captureDetailsContinueRules, captureDetailsFormRules} from "./captureDetails";
import {
    INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS,
    CAPTURE_DETAILS_SET_VALID,
    CAPTURE_DETAILS_SET_VALUE,
    CONTINUE_TRANSITION_CAPTURE_DETAILS,
    FORM_VALID_TRANSITION_DROPOFF_DETAILS,
    FORM_INVALID_TRANSITION_DROPOFF_DETAILS
} from "../../../actions/raOptimal/captureDetails";
import {investmentSetupRules} from "./investmentSetup";
import {
    INVESTMENT_SETUP_SET_VALID,
    INVESTMENT_SETUP_SET_VALUE,
    NO_INCREASE_SELECT,
    LINK_WITH_INFLATION_SELECT,
    ADVENTUROUS_INVESTOR_SELECT,
    COMFORTABLE_INVESTOR_SELECT,
    CHOOSE_OWN_PERCENTAGE_SELECT,
    SET_IS_CVS_VERIFIED
} from "../../../actions/raOptimal/investmentSetup";

import {
    PERSONAL_DETAILS_CLEAR_VALUE,
    PERSONAL_DETAILS_FORM_SET_VALUE,
    PERSONAL_DETAILS_SET_VALID,
    PERSONAL_DETAILS_SET_VALUE,
    CONTINUE_TRANSITION_PERSONAL_DETAILS
} from "../../../actions/raOptimal/personalDetails";
import {
    personalDetailsRules,
    validatePersonalDetailsForm,
    updatePersonalDetailsAddresses
} from "./personalDetails";
import {paymentDetailsRules, validatePaymentDetailsForm} from "./paymentDetails";
import {
    PAYMENT_DETAILS_SET_VALID,
    PAYMENT_DETAILS_SET_VALUE,
    PAYMENT_DETAILS_SET_CLEAR,
    PAYMENT_DETAILS_FORM_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS
} from "../../../actions/raOptimal/paymentDetails";
import {
    BENEFICIARIES_SET_VALID,
    BENEFICIARIES_SET_VALUE,
    CONTINUE_TRANSITION_BENEFICIARIES,
    DELETE_TRANSITION_BENEFICIARIES,
    SAVE_TRANSITION_BENEFICIARIES
} from "../../../actions/raOptimal/beneficiaries";
import {beneficiariesFormRules, beneficiarySaveDeleteRules, beneficiaryTransitionRules} from "./beneficiaries";

import {reviewRules, validateReviewForm} from "./review";
import {
    TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW,
    TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW,
    REVIEW_FORM_SET_VALUE,
    REVIEW_FORM_RESET_VALUE
} from "../../../actions/raOptimal/review"
import {
    beneficiariesSplitFormRules,
    beneficiariesSplitContinueRules
} from "./beneficiariesSplit";
import {
    CONTINUE_TRANSITION_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT,
    FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT,
    INVALID_CONTINUE_TRANSITION_BENEFICIARIES_SPLIT,
    SPLIT_PERCENTAGE_SET_VALID,
    SPLIT_PERCENTAGE_SET_VALUE
} from "../../../actions/raOptimal/beneficiariesSplit";

export const raOptimalRules = (store, next, action) => {

    // Capture details
    if (action.type.startsWith(CAPTURE_DETAILS_SET_VALID) ||
        action.type.startsWith(CAPTURE_DETAILS_SET_VALUE))
        return captureDetailsFormRules(store, next, action);

    if (action.type === INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS ||
        action.type === CONTINUE_TRANSITION_CAPTURE_DETAILS)
        return captureDetailsContinueRules(store, next, action);

    // Investment setup
    if (action.type.startsWith(INVESTMENT_SETUP_SET_VALUE) ||
        action.type.startsWith(INVESTMENT_SETUP_SET_VALID) ||
        action.type === LINK_WITH_INFLATION_SELECT ||
        action.type === CHOOSE_OWN_PERCENTAGE_SELECT ||
        action.type === NO_INCREASE_SELECT ||
        action.type === COMFORTABLE_INVESTOR_SELECT ||
        action.type === ADVENTUROUS_INVESTOR_SELECT ||
        action.type === SET_IS_CVS_VERIFIED)
        return investmentSetupRules(store, next, action);

    // Personal details
    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALID) ||
        action.type.startsWith(PERSONAL_DETAILS_CLEAR_VALUE) ||
        action.type.startsWith(PERSONAL_DETAILS_SET_VALUE) ||
        action.type === FORM_VALID_TRANSITION_DROPOFF_DETAILS ||
        action.type === FORM_INVALID_TRANSITION_DROPOFF_DETAILS)
        return personalDetailsRules(store, next, action);

    if (action.type.startsWith(PERSONAL_DETAILS_FORM_SET_VALUE))
        return validatePersonalDetailsForm(store, next, action);

    if (action.type === CONTINUE_TRANSITION_PERSONAL_DETAILS)
        return updatePersonalDetailsAddresses(store, next, action);

    // Beneficiaries
    if (action.type.startsWith(BENEFICIARIES_SET_VALUE) ||
        action.type.startsWith(BENEFICIARIES_SET_VALID))
        return beneficiariesFormRules(store, next, action);

    if (action.type === SAVE_TRANSITION_BENEFICIARIES ||
        action.type === DELETE_TRANSITION_BENEFICIARIES)
        return beneficiarySaveDeleteRules(store, next, action);

    if (action.type === CONTINUE_TRANSITION_BENEFICIARIES)
        return beneficiaryTransitionRules(store, next, action);

    // Beneficiaries split
    if (action.type === FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT ||
        action.type === EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT ||
        action.type === EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT ||
        action.type === EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT ||
        action.type === SPLIT_PERCENTAGE_SET_VALUE ||
        action.type === SPLIT_PERCENTAGE_SET_VALID)
        return beneficiariesSplitFormRules(store, next, action);

    if (action.type === INVALID_CONTINUE_TRANSITION_BENEFICIARIES_SPLIT ||
        action.type === CONTINUE_TRANSITION_BENEFICIARIES_SPLIT)
        return beneficiariesSplitContinueRules(store, next, action);

    // Payment details
    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALID) ||
        action.type.startsWith(PAYMENT_DETAILS_SET_VALUE) ||
        action.type.startsWith(PAYMENT_DETAILS_SET_CLEAR) ||
        action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS)
        return paymentDetailsRules(store, next, action);

    if (action.type === PAYMENT_DETAILS_FORM_SET_VALUE)
        return validatePaymentDetailsForm(store, next, action);

    // Review 
    if (action.type.startsWith(REVIEW_FORM_RESET_VALUE) ||
        action.type === TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW ||
        action.type === TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW)
        return reviewRules(store, next, action);

    if (action.type === REVIEW_FORM_SET_VALUE)
        return validateReviewForm(store, next, action);

    return null;
};