import {
    FORM_INVALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM,
    FORM_VALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM,
    INCORPORATION_COPY_SET_VALID,
    INCORPORATION_COPY_SET_VALUE,
    KRA_PIN_CERTIFICATE_COPY_SET_VALID,
    KRA_PIN_CERTIFICATE_COPY_SET_VALUE,
} from '../../actions/biasharaFlexi/uploadDocuments';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initUploadDocuments = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        incorporationCertificate: { value: null, error: null, status: null },
        kraCertificate: { value: null, error: null, status: null },
    },
};

export default (state = initUploadDocuments, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case INCORPORATION_COPY_SET_VALUE:
            return updateFormField('incorporationCertificate', (field) => ({
                ...field,
                value: action.value,
            }));

        case INCORPORATION_COPY_SET_VALID:
            return updateFormField('incorporationCertificate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case KRA_PIN_CERTIFICATE_COPY_SET_VALUE:
            return updateFormField('kraCertificate', (field) => ({
                ...field,
                value: action.value,
            }));
        case KRA_PIN_CERTIFICATE_COPY_SET_VALID:
            return updateFormField('kraCertificate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
