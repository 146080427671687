export const APP_URL_PREFIX = '/health-insurance';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QOUTE: 0,
    LIVES_COVERED: 1,
    MEDICAL_HISTORY: 2,
    DOCUMENTS: 3,
    PAYMENT: 4,
};

export const PAGES = [
    {
        URL: `${APP_URL_PREFIX}/get-quote`,
        URL_PART: 'get-quote',
        TITLE: 'Get Quote',
    },
    {
        URL: `${APP_URL_PREFIX}/lives-covered`,
        URL_PART: 'lives-covered',
        TITLE: 'Lives Covered',
    },
    {
        URL: `${APP_URL_PREFIX}/medical-history`,
        URL_PART: 'medical-history',
        TITLE: 'Medical History',
    },
    {
        URL: `${APP_URL_PREFIX}/upload-documents`,
        URL_PART: 'upload-documents',
        TITLE: 'Upload Documents',
    },
    {
        URL: `${APP_URL_PREFIX}/payment`,
        URL_PART: 'payment',
        TITLE: 'Payment',
    },
];

export const COVER_PLANS = {
    ME_AND_DIRECT_FAMILY: { title: 'Me & My Direct Family', value: 'meDirectFamily' },
    PARENTS_INLAWS: { title: 'My Parents & In-laws', value: 'parentsAndInlaws' },
    EXTENDED_FAMILY: { title: 'My Extended Family', value: 'extendedFamily' },
};

export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const HEALTH_INSURANCE_URLS = {
    QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
    LIVES_COVERED: PAGES[PAGE_INDICES.LIVES_COVERED].URL,
    MEDICAL_HISTORY: PAGES[PAGE_INDICES.MEDICAL_HISTORY].URL,
    DOCUMENTS: PAGES[PAGE_INDICES.DOCUMENTS].URL,
    PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL,
};
