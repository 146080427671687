import { validateAccountNumber, validateSingleSelection, validateAlphaString, validateFile } from "@om/validation";
import {PAYMENTS_PAGE_CONFIG} from "../../../actions/taxFreeSavingsAccount/types";
import {
    setBankNameValid,
    setAccountTypeValid,
    setAccountNumberValid,
    setDebitOrderDayValid,
    setMonthValid,
    setPaymentAuthorizationValid,
    setMonthToSkipValid,
    setLumpSumDayValid,
    setLumpSumMonthValid,

    MONTH_TO_SKIP_SET_VALUE,
    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    MONTH_SET_VALUE,
    LUMP_SUM_DAY_SET_VALUE,
    LUMP_SUM_MONTH_SET_VALUE,
    PAYMENT_AUTHORIZATION_INVALID_TRANSITION_PAYMENT_DETAILS,
    SOURCE_OF_FUNDS_SET_VALUE,
    SOURCE_OF_INCOME_SET_VALUE,
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    EMPLOYMENT_POSITION_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE,
    SOURCE_OF_INCOME_OTHER_SET_VALUE,
    setSourceOfIncomeValid,
    setSourceOfIncomeOtherValid,
    setEmploymentIndustryValid,
    setEmploymentIndustryOtherValid,
    setEmploymentPositionValid,
    setSourceOfFundsValid,

    IDENTITY_DOCUMENT_SET_VALUE,
    BIRTH_CERTIFICATE_SET_VALUE,
    COURT_MANDATE_SET_VALUE,
    setIdentityDocumentValid,
    setBirthCertificateValid,
    setCourtMandateValid,
} from "../../../actions/taxFreeSavingsAccount/paymentDetails";

export const paymentDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case BANK_NAME_SET_VALUE:
                error = validateSingleSelection(action.value, 'bank name');
                return setBankNameValid(error);

            case ACCOUNT_NUMBER_SET_VALUE:
                error = validateAccountNumber(action.value);
                return setAccountNumberValid(error);

            case ACCOUNT_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value, 'account type');
                return setAccountTypeValid(error);

            case DEBIT_ORDER_DAY_SET_VALUE:
                error = validateSingleSelection(action.value, 'debit order start date');
                return setDebitOrderDayValid(error);

            case MONTH_SET_VALUE:
                error = validateSingleSelection(action.value, 'month');
                return setMonthValid(error);

            case MONTH_TO_SKIP_SET_VALUE:
                error = validateSingleSelection(action.value, 'month to skip');
                return setMonthToSkipValid(error);

            case LUMP_SUM_DAY_SET_VALUE:
                error = validateSingleSelection(action.value, 'debit order start date');
                return setLumpSumDayValid(error);

            case LUMP_SUM_MONTH_SET_VALUE:
                error = validateSingleSelection(action.value, 'month');
                return setLumpSumMonthValid(error);

            case SOURCE_OF_FUNDS_SET_VALUE:
                error = validateSingleSelection(action.value, 'source of funds');
                return setSourceOfFundsValid(error);

            case SOURCE_OF_INCOME_SET_VALUE:
                error = validateSingleSelection(action.value, 'source of income');
                return setSourceOfIncomeValid(error);

            case SOURCE_OF_INCOME_OTHER_SET_VALUE:
                error = validateAlphaString(action.value, {subject: 'source of income', min: 2});
                return setSourceOfIncomeOtherValid(error);

            case EMPLOYMENT_INDUSTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'employment industry');
                return setEmploymentIndustryValid(error);

            case EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE:
                error = validateAlphaString(action.value, {subject: 'employment industry', min: 2});
                return setEmploymentIndustryOtherValid(error);

            case EMPLOYMENT_POSITION_SET_VALUE:
                error = validateSingleSelection(action.value, 'employment position');
                return setEmploymentPositionValid(error);

            case PAYMENT_AUTHORIZATION_INVALID_TRANSITION_PAYMENT_DETAILS:
                return setPaymentAuthorizationValid('Authorisation required to continue.');

            case IDENTITY_DOCUMENT_SET_VALUE:
                error  = validateFile(action.value, { maxInputSize: PAYMENTS_PAGE_CONFIG.MAX_INPUT_SIZE, maxInputSizeName: PAYMENTS_PAGE_CONFIG.MAX_INPUT_SIZE_NAME, validFileTypes: PAYMENTS_PAGE_CONFIG.VALID_UPLOAD_TYPES, retainSubjectCase: true});
                return setIdentityDocumentValid(error);

            case BIRTH_CERTIFICATE_SET_VALUE:
                error  = validateFile(action.value, { maxInputSize: PAYMENTS_PAGE_CONFIG.MAX_INPUT_SIZE, maxInputSizeName: PAYMENTS_PAGE_CONFIG.MAX_INPUT_SIZE_NAME, validFileTypes: PAYMENTS_PAGE_CONFIG.VALID_UPLOAD_TYPES, retainSubjectCase: true});
                return setBirthCertificateValid(error);

            case COURT_MANDATE_SET_VALUE:
                error  = validateFile(action.value, { maxInputSize: PAYMENTS_PAGE_CONFIG.MAX_INPUT_SIZE, maxInputSizeName: PAYMENTS_PAGE_CONFIG.MAX_INPUT_SIZE_NAME, validFileTypes: PAYMENTS_PAGE_CONFIG.VALID_UPLOAD_TYPES, retainSubjectCase: true});
                return setCourtMandateValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction ) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
};
