import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    ID_UPLOAD_RESET_VALUE,
    ID_UPLOAD_SET_VALUE,
    ID_UPLOAD_SET_VALID,
    LICENSE_UPLOAD_RESET_VALUE,
    LICENSE_UPLOAD_SET_VALUE,
    LICENSE_UPLOAD_SET_VALID,
    LOG_BOOK_UPLOAD_RESET_VALUE,
    LOG_BOOK_UPLOAD_SET_VALID,
    LOG_BOOK_UPLOAD_SET_VALUE,
    VALUATION_REPORT_RESET_VALUE,
    VALUATION_REPORT_SET_VALID,
    VALUATION_REPORT_SET_VALUE,
    INSPECTION_PHOTO_SET_VALUE,
    INSPECTION_PHOTO_SET_VALID,
    INSPECTION_PHOTO_RESET_VALUE,
    FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
    FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
} from '../../actions/ugMotorInsurance/Documents';

export const initDocuments = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    personalDetailsReferenceData: null,
    apiCallStatus: null,
    form: {
        idUpload: { value: null, error: null, status: null },
        licenseUpload: { value: null, error: null, status: null },
        logbookUpload: { value: null, error: null, status: null },
        valuationReportUpload: { value: null, error: null, status: null },
        inspectionPhoto: { value: null, error: null, status: null },
    },
};

export default (state = initDocuments, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // ID UPLOAD

        case ID_UPLOAD_SET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_UPLOAD_SET_VALID:
            return updateFormField('idUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_UPLOAD_RESET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: null,
            }));

        // LICENSE UPLOAD
        case LICENSE_UPLOAD_SET_VALUE:
            return updateFormField('licenseUpload', (field) => ({ ...field, value: action.value }));

        case LICENSE_UPLOAD_RESET_VALUE:
            return updateFormField('licenseUpload', (field) => ({
                ...field,
                value: null,
            }));

        case LICENSE_UPLOAD_SET_VALID:
            return updateFormField('licenseUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // LOGBOOK UPLOAD
        case LOG_BOOK_UPLOAD_SET_VALUE:
            return updateFormField('logbookUpload', (field) => ({ ...field, value: action.value }));

        case LOG_BOOK_UPLOAD_RESET_VALUE:
            return updateFormField('logbookUpload', (field) => ({
                ...field,
                value: null,
            }));

        case LOG_BOOK_UPLOAD_SET_VALID:
            return updateFormField('logbookUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Valuation Report upload
        case VALUATION_REPORT_SET_VALUE:
            return updateFormField('valuationReportUpload', (field) => ({ ...field, value: action.value }));

        case VALUATION_REPORT_RESET_VALUE:
            return updateFormField('valuationReportUpload', (field) => ({
                ...field,
                value: null,
            }));

        case VALUATION_REPORT_SET_VALID:
            return updateFormField('valuationReportUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Inspection Photo Upload
        case INSPECTION_PHOTO_SET_VALUE:
            return updateFormField('inspectionPhoto', (field) => ({ ...field, value: action.value }));

        case INSPECTION_PHOTO_RESET_VALUE:
            return updateFormField('inspectionPhoto', (field) => ({
                ...field,
                value: null,
            }));

        case INSPECTION_PHOTO_SET_VALID:
            return updateFormField('inspectionPhoto', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Reset the state
        case REINITIALIZE:
            return initDocuments;
        default:
            return state;
    }
};
