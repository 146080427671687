import { createSelector } from 'reselect';
import { getAllsureInsurance } from './index';
import { getGlobalPaymentFrequency } from './homeDetails';

export const getReferenceData = createSelector(
    getAllsureInsurance,
    (allsureInsurance) => allsureInsurance.referenceData
);
export const getApplicationData=createSelector(
    getReferenceData,
    (referenceData) => referenceData.applicationData
);
export const getAllsureApiStatus = createSelector(getReferenceData, (referenceData) => referenceData.apiStatus);
export const getTermsAndConditions=createSelector(getReferenceData, (referenceData) => referenceData.termsandConditions);
export const getRecaptchaValue=createSelector(getReferenceData, (referenceData) => referenceData.recaptchaValue);
export const getAllsureReferenceNumber = createSelector(getReferenceData, (referenceData) => referenceData.referenceNumber);

export const getQuoteData=createSelector(getReferenceData,(referenceData) => referenceData.quoteData)

export const getQuoteTotalAmountMonthly = createSelector(getQuoteData,
  (quoteData)=>{
      const homeValues = quoteData?.homeQuotes?.flatMap((element) => [
          element?.homeOwnersMonthlyPremium ?? 0,
          element?.homeContentMonthlyPremium ?? 0,
      ])??[];
      const motorValues = quoteData?.motorQuotes?.map((element) => element?.motorMonthlyPremium)??[];

      return [...homeValues, ...motorValues]?.reduce((acc, curr) => acc + curr, 0)?.toFixed(2)??0;
})

export const getQuoteOnPremiumfrequency = createSelector(getQuoteTotalAmountMonthly,getGlobalPaymentFrequency,(totalQuote,premiumFrequency)=>{

 return premiumFrequency==='Monthly' ? (totalQuote).toFixed(2) + ' p/m' : (totalQuote*12).toFixed(2) + ' p/a'
})


export const getBankingDetailsReferenceData=createSelector(getApplicationData,({bankData,branchCodesData})=>({
    bankReferenceData: bankData.map(({name,bank_code})=>({value:bank_code,label:name})),
    branchCodesReferenceData: branchCodesData.map(({name,branch_code})=>({value:branch_code,label:name+'-'+branch_code})),
    accountTypeReferenceData:[
       {
           label: "Current",
           value: "01",
         },
       {
         label: "Savings",
         value: "02",
       },
     ],
     debitDayReferenceData:[
       {value: 1, label: '1'},
       {value: 2, label: '2'},
       {value: 3, label: '3'},
       {value: 4, label: '4'},
       {value: 5, label: '5'},
       {value: 6, label: '6'},
       {value: 7, label: '7'},
       {value: 8, label: '8'},
       {value: 9, label: '9'},
       {value: 10, label: '10'},
       {value: 11, label: '11'},
       {value: 12, label: '12'},
       {value: 13, label: '13'},
       {value: 14, label: '14'},
       {value: 15, label: '15'},
       {value: 16, label: '16'},
       {value: 17, label: '17'},
       {value: 18, label: '18'},
       {value: 19, label: '19'},
       {value: 20, label: '20'},
       {value: 21, label: '21'},
       {value: 22, label: '22'},
       {value: 23, label: '23'},
       {value: 24, label: '24'},
       {value: 25, label: '25'},
       {value: 26, label: '26'},
       {value: 27, label: '27'},
       {value: 28, label: '28'},
       {value: 29, label: '29'},
       {value: 30, label: '30'},
       {value: 31, label: '31'},
   ],
   sourceOfFundReferenceData:[
       { value: "01", label: "Business Profits" },
       { value: "02", label: "Donations" },
       { value: "03", label: "Bonds" },
       { value: "04", label: "Salaried" },
       { value: "05", label: "Savings" },
       { value: "06", label: "Other" },
     ]
   }))