import {getPersonalDetailsForm, getPostalAddressMethod, getPersonalDetailsValid, getPostalAddressSame} from "../../../selectors/raOptimal/personalDetails";
import {getCaptureDetailsForm} from "../../../selectors/raOptimal/captureDetails";
import {FIELD_STATUS, POSTAL_ADDRESS_METHOD, TOGGLE_STATUS} from "../../../reducers/status";
import {RA_OPTIMAL_PERSONAL_DETAILS_SCROLL_ID} from "../status";
import {transitionScrolling} from "../../../actions/raOptimal/scrolling";
import {
    transitionPersonalDetails,
    setPostalAddressMethodValue,
    setTitleValue,
    setMiddleNameValue,
    setEmailValue,
    setIdValue,
    setMaritalStatusValue,
    setNationalityValue,
    setIncomeTaxValue,
    setCountryOfBirthValue,
    setComplexValue,
    setStreetAddressValue,
    setSuburbValue,
    setCityValue,
    setPostalCodeValue,
    setCountryValue,
    setPoBoxValue,
    setPoPostOfficeValue,
    setPoPostalCodeValue,
    setPoCountryValue,
    setPrivateBagValue,
    setPrivatePostOfficeValue,
    setPrivatePostalCodeValue,
    setPrivateCountryValue,
    setPostalComplexValue,
    setPostalStreetAddressValue,
    setPostalSuburbValue,
    setPostalCityValue,
    setPostalPostalCodeValue,
    setPostalCountryValue,
    clearPoBoxValue,
    clearPoPostOfficeValue,
    clearPoPostalCodeValue,
    clearPoCountryValue,
    clearPrivateBagValue,
    clearPrivatePostOfficeValue,
    clearPrivatePostalCodeValue,
    clearPrivateCountryValue,
    clearPostalComplexValue,
    clearPostalStreetAddressValue,
    clearPostalSuburbValue,
    clearPostalCityValue,
    clearPostalPostalCodeValue,
    clearPostalCountryValue,
    setGCSStreetAddressValue,
    setGCSPostalAddressValue,
    POSTAL_ADDRESS_SAME_VALUE,
    POSTAL_ADDRESS_METHOD_VALUE,
    ID_SET_VALID
} from "../../../actions/raOptimal/personalDetails";
import {
    setNameValue,
    setSurnameValue,
    setMobileValue
} from "../../../actions/raOptimal/captureDetails";
import {DEFAULT_COUNTRY} from "../../../reducers/raOptimal/constants";
import { setAccountValidationReset } from "../../../actions/raOptimal/paymentDetails";

export const personalDetailsRules = (store, next, action) => {
    const result = next(action);

    if (action.type === POSTAL_ADDRESS_METHOD_VALUE) {
        changePostalAddressMethod(store);
    }

    if (action.type === POSTAL_ADDRESS_SAME_VALUE) {
        clearPostalAddressHistory(store);
    }

    // If user resets their ID number then reset account validation
    if (action.type === ID_SET_VALID) {
        store.dispatch(setAccountValidationReset());
    }

    // Personal Information
    const personalDetailsValid = validatePersonalDetails(store);
    store.dispatch(personalDetailsValid
        ? transitionPersonalDetails.formValid()
        : transitionPersonalDetails.formInvalid());

    return result;
};

export const updatePersonalDetailsAddresses = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const postalAddressSame = getPostalAddressSame(state);
    if (postalAddressSame.value) {
        setPostalAddressToStreetAddress(store);
    }

    formatGCSAddress(store);
    return result;
};

export const validatePersonalDetailsForm = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const personalDetailsFormValid = getPersonalDetailsValid(state);

    if (!personalDetailsFormValid) {
        const personalDetailsForm = getPersonalDetailsForm(state);
        const captureDetailsForm = getCaptureDetailsForm(state);
        const personalDetailsPostalAddressMethod = getPostalAddressMethod(state);
        const postalAddressSame = personalDetailsForm.postalAddressSame.value;

        store.dispatch(setNameValue(captureDetailsForm.name.value));
        store.dispatch(setSurnameValue(captureDetailsForm.surname.value));
        store.dispatch(setMobileValue(captureDetailsForm.mobile.value));

        store.dispatch(setPostalAddressMethodValue(personalDetailsForm.postalAddressMethod.value));
        store.dispatch(setTitleValue(personalDetailsForm.title.value));
        store.dispatch(setMiddleNameValue(personalDetailsForm.middleName.value));

        store.dispatch(setEmailValue(personalDetailsForm.email.value));
        store.dispatch(setIdValue(personalDetailsForm.id.value));
        store.dispatch(setMaritalStatusValue(personalDetailsForm.maritalStatus.value));
        store.dispatch(setNationalityValue(personalDetailsForm.nationality.value));
        store.dispatch(setIncomeTaxValue(personalDetailsForm.incomeTax.value));
        store.dispatch(setCountryOfBirthValue(personalDetailsForm.countryOfBirth.value));

        store.dispatch(setComplexValue(personalDetailsForm.complex.value));
        store.dispatch(setStreetAddressValue(personalDetailsForm.streetAddress.value));
        store.dispatch(setSuburbValue(personalDetailsForm.suburb.value));
        store.dispatch(setCityValue(personalDetailsForm.city.value));
        store.dispatch(setPostalCodeValue(personalDetailsForm.postalCode.value));
        store.dispatch(setCountryValue(personalDetailsForm.country.value));
        
        if (postalAddressSame === TOGGLE_STATUS.NO) {
            if (personalDetailsPostalAddressMethod.value === POSTAL_ADDRESS_METHOD.PO_BOX) {
                store.dispatch(setPoBoxValue(personalDetailsForm.poBox.value));
                store.dispatch(setPoPostOfficeValue(personalDetailsForm.poPostOffice.value));
                store.dispatch(setPoPostalCodeValue(personalDetailsForm.poPostalCode.value));
                store.dispatch(setPoCountryValue(personalDetailsForm.poCountry.value));
            } else if (personalDetailsPostalAddressMethod.value === POSTAL_ADDRESS_METHOD.PRIVATE_BAG) {
                store.dispatch(setPrivateBagValue(personalDetailsForm.privateBag.value));
                store.dispatch(setPrivatePostOfficeValue(personalDetailsForm.privatePostOffice.value));
                store.dispatch(setPrivatePostalCodeValue(personalDetailsForm.privatePostalCode.value));
                store.dispatch(setPrivateCountryValue(personalDetailsForm.privateCountry.value));
            } else if (personalDetailsPostalAddressMethod.value === POSTAL_ADDRESS_METHOD.STREET_ADDRESS) {
                store.dispatch(setPostalComplexValue(personalDetailsForm.postalComplex.value));
                store.dispatch(setPostalStreetAddressValue(personalDetailsForm.postalStreetAddress.value));
                store.dispatch(setPostalSuburbValue(personalDetailsForm.postalSuburb.value));
                store.dispatch(setPostalCityValue(personalDetailsForm.postalCity.value));
                store.dispatch(setPostalPostalCodeValue(personalDetailsForm.postalPostalCode.value));
                store.dispatch(setPostalCountryValue(personalDetailsForm.postalCountry.value));
            } else {
                store.dispatch(setPostalAddressMethodValue(null));
            }
        }
    }

    setScrollError(store);
    return result;
};

const setScrollError = store => {
    const keyArray = RA_OPTIMAL_PERSONAL_DETAILS_SCROLL_ID;
    const state = store.getState();
    const personalDetailsForm = getPersonalDetailsForm(state);
    const captureDetailsForm = getCaptureDetailsForm(state);

    for (let i = 0; i < keyArray.length; i++) {
        if (keyArray[i] in personalDetailsForm && personalDetailsForm[keyArray[i]].error) {
            store.dispatch(transitionScrolling.scrollToId(keyArray[i]));
            break;
        } else if (keyArray[i] in captureDetailsForm && captureDetailsForm[keyArray[i]].error) {
            store.dispatch(transitionScrolling.scrollToId(keyArray[i]));
            break;
        }
    }
};

const changePostalAddressMethod = store => {
    store.dispatch(clearPoBoxValue(null));
    store.dispatch(clearPoPostOfficeValue(null));
    store.dispatch(clearPoPostalCodeValue(null));
    store.dispatch(clearPoCountryValue(null));

    store.dispatch(clearPrivateBagValue(null));
    store.dispatch(clearPrivatePostOfficeValue(null));
    store.dispatch(clearPrivatePostalCodeValue(null));
    store.dispatch(clearPrivateCountryValue(null));

    store.dispatch(clearPostalComplexValue(null));
    store.dispatch(clearPostalStreetAddressValue(null));
    store.dispatch(clearPostalSuburbValue(null));
    store.dispatch(clearPostalCityValue(null));
    store.dispatch(clearPostalPostalCodeValue(null));
    store.dispatch(clearPostalCountryValue(null));
};

const clearPostalAddressHistory = store => {
    store.dispatch(setPostalAddressMethodValue(TOGGLE_STATUS.UNTOUCHED));
    changePostalAddressMethod(store);
};

const setPostalAddressToStreetAddress = store => {
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    store.dispatch(setPostalComplexValue(personalDetailsForm.complex.value));
    store.dispatch(setPostalStreetAddressValue(personalDetailsForm.streetAddress.value));
    store.dispatch(setPostalSuburbValue(personalDetailsForm.suburb.value));
    store.dispatch(setPostalCityValue(personalDetailsForm.city.value));
    store.dispatch(setPostalPostalCodeValue(personalDetailsForm.postalCode.value));
    store.dispatch(setPostalCountryValue(personalDetailsForm.country.value));
};

// Personal Information
const validatePersonalDetails = store => {
    const state = store.getState();
    const formFields = getPersonalDetailsForm(state);
    const captureDetailsForm = getCaptureDetailsForm(state);
    const personalDetailsPostalAddressMethod = getPostalAddressMethod(state);
    const personalDetailsPostalAddressSame = formFields.postalAddressSame.value;
    
    let postalAddressValid = true;
    if (personalDetailsPostalAddressSame === TOGGLE_STATUS.NO) {
        // Syntax to test that the field is entered and valid
        // formFields.poBox && formFields.poBox.status === FIELD_STATUS.VALID &&
        // Syntax for optional fields (ie. must just not be in error state)
        // formFields.postalComplex && formFields.postalComplex.status !== FIELD_STATUS.ERROR &&

        postalAddressValid = ( personalDetailsPostalAddressMethod && personalDetailsPostalAddressMethod.value === POSTAL_ADDRESS_METHOD.PO_BOX ) ?
                                ( formFields.poBox && formFields.poBox.status === FIELD_STATUS.VALID &&
                                formFields.poPostalCode && formFields.poPostalCode.status === FIELD_STATUS.VALID &&
                                formFields.poPostOffice && formFields.poPostOffice.status === FIELD_STATUS.VALID &&
                                formFields.poCountry && formFields.poCountry.status === FIELD_STATUS.VALID ) :
                            ( personalDetailsPostalAddressMethod && personalDetailsPostalAddressMethod.value === POSTAL_ADDRESS_METHOD.PRIVATE_BAG ) ?
                                ( formFields.privateBag && formFields.privateBag.status === FIELD_STATUS.VALID &&
                                formFields.privatePostalCode && formFields.privatePostalCode.status === FIELD_STATUS.VALID &&
                                formFields.privatePostOffice && formFields.privatePostOffice.status === FIELD_STATUS.VALID &&
                                formFields.privateCountry && formFields.privateCountry.status === FIELD_STATUS.VALID ) :
                            ( personalDetailsPostalAddressMethod && personalDetailsPostalAddressMethod.value === POSTAL_ADDRESS_METHOD.STREET_ADDRESS ) ?
                                ( formFields.postalComplex && formFields.postalComplex.status !== FIELD_STATUS.ERROR &&
                                formFields.postalStreetAddress && formFields.postalStreetAddress.status === FIELD_STATUS.VALID &&
                                formFields.postalSuburb && formFields.postalSuburb.status === FIELD_STATUS.VALID &&
                                formFields.postalCity && formFields.postalCity.status === FIELD_STATUS.VALID &&
                                formFields.postalPostalCode && formFields.postalPostalCode.status === FIELD_STATUS.VALID &&
                                formFields.postalCountry && formFields.postalCountry.status === FIELD_STATUS.VALID ) : false;

    }

    const basicValidation = formFields.title && formFields.title.status === FIELD_STATUS.VALID &&
                            captureDetailsForm.name && captureDetailsForm.name.status === FIELD_STATUS.VALID &&
                            formFields.middleName && formFields.middleName.status !== FIELD_STATUS.ERROR &&
                            captureDetailsForm.surname && captureDetailsForm.surname.status === FIELD_STATUS.VALID &&
                            captureDetailsForm.mobile && captureDetailsForm.mobile.status === FIELD_STATUS.VALID &&
                            formFields.email && formFields.email.status === FIELD_STATUS.VALID &&
                            formFields.id && formFields.id.status === FIELD_STATUS.VALID &&
                            formFields.incomeTax && formFields.incomeTax.status !== FIELD_STATUS.ERROR &&
                            formFields.countryOfBirth && formFields.countryOfBirth.status === FIELD_STATUS.VALID &&
                            formFields.maritalStatus && formFields.maritalStatus.status === FIELD_STATUS.VALID &&
                            formFields.nationality && formFields.nationality.status === FIELD_STATUS.VALID &&
                            formFields.complex && formFields.complex.status !== FIELD_STATUS.ERROR &&
                            formFields.streetAddress && formFields.streetAddress.status === FIELD_STATUS.VALID &&
                            formFields.suburb && formFields.suburb.status !== FIELD_STATUS.ERROR &&
                            formFields.city && formFields.city.status === FIELD_STATUS.VALID &&
                            formFields.postalCode && formFields.postalCode.status === FIELD_STATUS.VALID &&
                            formFields.country && formFields.country.status === FIELD_STATUS.VALID &&
                            postalAddressValid;

    return basicValidation;
};

const formatGCSAddress = store => {
    const personalDetailsForm = getPersonalDetailsForm(store.getState());

    // Dispatch Street Address - formatted based on country
    store.dispatch(
        setGCSStreetAddressValue(personalDetailsForm.country.value === DEFAULT_COUNTRY.ZA_DESCRIPTION
        ? formatGCSStreetAddressSouthAfrica(personalDetailsForm.complex.value,
            personalDetailsForm.streetAddress.value,
            personalDetailsForm.suburb.value,
            personalDetailsForm.city.value,
            personalDetailsForm.postalCode.value,
            personalDetailsForm.country.value)
        : formatGCSStreetAddressGlobal(personalDetailsForm.complex.value,
            personalDetailsForm.streetAddress.value,
            personalDetailsForm.suburb.value,
            personalDetailsForm.city.value,
            personalDetailsForm.postalCode.value,
            personalDetailsForm.country.value)));


    // Dispatch Postal Address - formatted based on country
    if (personalDetailsForm.postalAddressSame.value === true) {
        store.dispatch(setGCSPostalAddressValue(personalDetailsForm.country.value === DEFAULT_COUNTRY.ZA_DESCRIPTION
            ? formatGCSPostalAddressSouthAfrica(personalDetailsForm)
            : formatGCSPostalAddressGlobal(personalDetailsForm)));
    }
    if (personalDetailsForm.postalAddressMethod.value === POSTAL_ADDRESS_METHOD.STREET_ADDRESS) {
        store.dispatch(setGCSPostalAddressValue(personalDetailsForm.postalCountry.value === DEFAULT_COUNTRY.ZA_DESCRIPTION
            ? formatGCSPostalAddressSouthAfrica(personalDetailsForm)
            : formatGCSPostalAddressGlobal(personalDetailsForm)));
    }
    if (personalDetailsForm.postalAddressMethod.value === POSTAL_ADDRESS_METHOD.PO_BOX) {
        store.dispatch(setGCSPostalAddressValue(personalDetailsForm.poCountry.value === DEFAULT_COUNTRY.ZA_DESCRIPTION
            ? formatGCSPostalAddressSouthAfrica(personalDetailsForm)
            : formatGCSPostalAddressGlobal(personalDetailsForm)));
    }
    if (personalDetailsForm.postalAddressMethod.value === POSTAL_ADDRESS_METHOD.PRIVATE_BAG) {
        store.dispatch(setGCSPostalAddressValue(personalDetailsForm.privateCountry.value === DEFAULT_COUNTRY.ZA_DESCRIPTION
            ? formatGCSPostalAddressSouthAfrica(personalDetailsForm)
            : formatGCSPostalAddressGlobal(personalDetailsForm)));
    }
}

const formatGCSPostalAddressSouthAfrica = personalDetailsForm => {

    switch (personalDetailsForm.postalAddressMethod.value) {
        case (POSTAL_ADDRESS_METHOD.PO_BOX):
            return formatGCSObject([`PO Box ${personalDetailsForm.poBox.value}`, personalDetailsForm.poPostOffice.value],
                personalDetailsForm.poPostalCode.value,
                personalDetailsForm.poCountry.value)

        case (POSTAL_ADDRESS_METHOD.PRIVATE_BAG):
            return formatGCSObject([`Private Bag ${personalDetailsForm.privateBag.value}`, personalDetailsForm.privatePostOffice.value],
                personalDetailsForm.privatePostalCode.value,
                personalDetailsForm.privateCountry.value)

        case (POSTAL_ADDRESS_METHOD.STREET_ADDRESS):
            return formatGCSStreetAddressSouthAfrica(personalDetailsForm.postalComplex.value,
                personalDetailsForm.postalStreetAddress.value, personalDetailsForm.postalSuburb.value,
                personalDetailsForm.postalCity.value, personalDetailsForm.postalPostalCode.value,
                personalDetailsForm.postalCountry.value);
        default:
            return formatGCSStreetAddressSouthAfrica(personalDetailsForm.complex.value,
                personalDetailsForm.streetAddress.value, personalDetailsForm.suburb.value,
                personalDetailsForm.city.value, personalDetailsForm.postalCode.value,
                personalDetailsForm.country.value);

    }
}

const formatGCSPostalAddressGlobal = personalDetailsForm => {
    switch (personalDetailsForm.postalAddressMethod.value) {
        case (POSTAL_ADDRESS_METHOD.PO_BOX):
            return formatGCSObject([`PO Box ${personalDetailsForm.poBox.value}`,
                `${personalDetailsForm.poPostOffice.value} ${personalDetailsForm.poPostalCode.value}`,
                personalDetailsForm.poCountry.value],
                '0000',
                personalDetailsForm.poCountry.value);

        case (POSTAL_ADDRESS_METHOD.PRIVATE_BAG):
            return formatGCSObject([`Private Bag ${personalDetailsForm.privateBag.value}`,
                `${personalDetailsForm.privatePostOffice.value} ${personalDetailsForm.privatePostalCode.value}`,
                personalDetailsForm.privateCountry.value],
                '0000',
                personalDetailsForm.privateCountry.value)

        case (POSTAL_ADDRESS_METHOD.STREET_ADDRESS):
            return formatGCSStreetAddressGlobal(personalDetailsForm.postalComplex.value,
                personalDetailsForm.postalStreetAddress.value, personalDetailsForm.postalSuburb.value,
                personalDetailsForm.postalCity.value, personalDetailsForm.postalPostalCode.value,
                personalDetailsForm.postalCountry.value);

        default:
            return formatGCSStreetAddressGlobal(personalDetailsForm.complex.value,
                personalDetailsForm.streetAddress.value, personalDetailsForm.suburb.value,
                personalDetailsForm.city.value, personalDetailsForm.postalCode.value,
                personalDetailsForm.country.value);

    }
}

const formatGCSStreetAddressSouthAfrica = (complex, streetAddress, suburb, city, postalCode, country) => {
    // South africa
    let addressArray = [];
    if(complex)
        addressArray.push(complex);

    addressArray.push(streetAddress);

    if(suburb)
        addressArray.push(suburb);

    addressArray.push(city);
    addressArray.length;

    return formatGCSObject(addressArray, postalCode, country);
};

const formatGCSStreetAddressGlobal = (complex, streetAddress, suburb, city, postalCode, country) => {
    // Global Address
    let addressArray = [];
    if(complex) {
        addressArray.push(complex);
        addressArray.push(streetAddress);
    } else {
        addressArray.push(streetAddress);
        if(suburb)
            addressArray.push(suburb);
    }

    addressArray.push(`${city} ${postalCode}`);
    addressArray.push(country);

    return formatGCSObject(addressArray, '0000', country);
};

const formatGCSObject = (addressArray, postalCode, country) => {
    return {
        addressLine1: addressArray[0],
        addressLine2: addressArray[1],
        addressLine3: addressArray[2] || '',
        addressLine4: addressArray[3] || '',
        addressPostalCode: postalCode,
        addressCountry: country,
    };
};
