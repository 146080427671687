import { transitionAgentModalForm } from '../../../actions/personalAccident/agentModal';
import { getAgentForm } from '../../../selectors/personalAccident/agentModal';

import { FIELD_STATUS } from '../../../reducers/status';

export const agentModalFormRules = (store, next, action) => {
    const result = next(action);
    const personalAccidentAgentModalForm = getAgentForm(store.getState());

    const agentModalForm = validateAgentModalForm(personalAccidentAgentModalForm);

    const formAction = agentModalForm ? transitionAgentModalForm.formValid() : transitionAgentModalForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateAgentModalForm = (formFields) => {
    return (
        formFields.name && formFields.name.status === FIELD_STATUS.VALID
        // formFields.agentCode &&
        // formFields.agentCode.status === FIELD_STATUS.VALID
    );
};
