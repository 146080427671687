import { createSelector } from "reselect";

export const getOnTheMoney = (state => {
    return state.publicWeb.onTheMoney;
});

const makeGetField = fieldId => createSelector(
    getOnTheMoney,
    onTheMoney => onTheMoney[fieldId]
);

export const getOnTheMoneyIsLoggedIn = makeGetField('isLoggedIn');
export const getOnTheMoneySessionKey = makeGetField('sessionKey');
export const getOnTheMoneyAuthToken = makeGetField('authToken');
export const getOnTheMoneyUserProfile = makeGetField('userProfile');
export const getOnTheMoneyEnrolledCourses = makeGetField('enrolledCourses');
export const getOnTheMoneyCalendarEvents = makeGetField('calendarEvents');
export const getOnTheMoneyCalendarUrl = makeGetField('calendarUrl');
export const getOnTheMoneyContentHubUrl = makeGetField('contentHubUrl');
export const getOnTheMoneyCourseCatalogueUrl = makeGetField('courseCatalogueUrl');
export const getOnTheMoneyDashboardUrl = makeGetField('dashboardUrl');
export const getOnTheMoneyGradesUrl = makeGetField('gradesUrl');
export const getOnTheMoneyLearningSearchUrl = makeGetField('learningSearchUrl');
export const getOnTheMoneyLogOutUrl = makeGetField('logOutUrl');
export const getOnTheMoneyMessagesUrl = makeGetField('messagesUrl');
export const getOnTheMoneyNotificationsUrl = makeGetField('notificationsUrl');
export const getOnTheMoneyPreferencesUrl = makeGetField('preferencesUrl');
export const getOnTheMoneyProfileUrl = makeGetField('profileUrl');
export const getOnTheMoneySignUpUrl = makeGetField('signUpUrl');
export const getOnTheMoneySignInUrl = makeGetField('signInUrl');
