import * as OmValidationDateHelper from '../helpers/validation-date-helper.js';
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import {config} from '../config/index.js';

function checkAgeBoundaries(minAge, maxAge, idNumberString, pronoun) {
    const age = calculateAge(idNumberString);
    
    if(minAge && age < minAge){
        return OmValidationMessageHelper.getTooYoungMsg(minAge, pronoun);
    } else if(maxAge && age >= maxAge){
        return OmValidationMessageHelper.getTooOldMsg(maxAge, pronoun);
    }

    return null;
}

function calculateAge(idNumberString){
    const dob = OmValidationDateHelper.getDateOfBirthFromSaIdNumber(idNumberString);
    const dobArray = OmValidationDateHelper.dateStringToNumberArray(dob);
    return Math.floor(OmValidationDateHelper.yearsDifference(OmValidationDateHelper.todayDateArray(), dobArray));                         
}

function generateLuhnDigit(idNumber) {
    const checkNum = idNumber.substring(0, idNumber.length - 1);
    let total = 0;
    let count = 0;
    for (let i = 0; i < checkNum.length; i ++) {
        const multiple = count % 2 + 1;
        count ++;
        let temp = multiple * +checkNum[i];
        temp = Math.floor(temp / 10) + (temp % 10);
        total += temp;
    }

    total = (total * 9) % 10;

    return total.toString();
}

function verifyDateSection(idNumber) {
    const dob = OmValidationDateHelper.getDateOfBirthFromSaIdNumber(idNumber);
    const dobArray = dob.split('/');

    const year = parseInt(dobArray[2]);
    const month = parseInt(dobArray[1]) - 1;
    const date = dobArray[0];
    const dateObj = new Date(year, month, date);
                                 
    if (dateObj.getMonth() !== month || dateObj.getDate() !== parseInt(date)) {
        return false;
    }

    return true;
}

export function validateIdNumber(idNumberString, { 
    required = true, 
    subject = 'South African ID number', 
    checkAge = false, 
    minAge = 0, 
    maxAge = 999,
    pronoun = config.PRONOUN.YOUR,
    retainSubjectCase = true
} = {})
{

    if (!idNumberString && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!idNumberString && !required) {
        return null;
    }

    // Test if value is a number
    const regEx = new RegExp('^[0-9]*$');
    const invalidMsg = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);

    if (!regEx.test(idNumberString)) {
        return invalidMsg;
    }

    // 1. Check length of ID Number
    // 2. Verify that the first 6 digits in represent a real date
    // 3. Verify the check sum
    if (idNumberString.length !== 13 ||
        !verifyDateSection(idNumberString) ||
        generateLuhnDigit(idNumberString) !== idNumberString[idNumberString.length - 1]) {
        return invalidMsg;
    }

    // Verify age boundaries
    if(checkAge) {
        return checkAgeBoundaries(minAge, maxAge, idNumberString, pronoun);
    }

    return null;
}

export function validateIdNumberAgainstAge(idNumber, age) {
    if (calculateAge(idNumber).toString() !== age.toString())
        return OmValidationMessageHelper.ID_AGE_MISMATCH_ERROR;

    return null;
}
