import { createSelector } from 'reselect';
import { getSavingsCalculators } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getHowMuchYouNeedToSaveAssumptionsModel = createSelector(
	getSavingsCalculators,
	(savingsCalculators) => savingsCalculators.howMuchYouNeedToSaveAssumptionsModel
);

export const getHowMuchYouNeedToSaveAssumptionsModelForm = createSelector(
    getHowMuchYouNeedToSaveAssumptionsModel,
    howMuchYouNeedToSaveAssumptionsModel => howMuchYouNeedToSaveAssumptionsModel.form
);

export const getHowMuchYouNeedToSaveAssumptionsModelFormValid = createSelector(
	getHowMuchYouNeedToSaveAssumptionsModel,
	(howMuchYouNeedToSaveAssumptionsModel) => howMuchYouNeedToSaveAssumptionsModel.formStatus === FORM_STATUS.VALID
);


const makeGetField = (fieldId) => createSelector(
    getHowMuchYouNeedToSaveAssumptionsModelForm,
    (calculatorAssumptionsModel) => calculatorAssumptionsModel[fieldId]
);

export const getExpectedAnnualGrowth = makeGetField('expectedAnnualGrowth');
export const getAnnualIncreaseInContribution = makeGetField('annualIncreaseInContribution');
export const getInflation = makeGetField('inflation');