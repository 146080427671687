export const DOMESTIC_INSURANCE_BUILDING_DETAILS =
    'domesticInsurance/buildingDetailsForm/';

export const BUILDING_DETAILS_FORM_SET_VALUE = DOMESTIC_INSURANCE_BUILDING_DETAILS + 'setValue/';
export const BUILDING_DETAILS_FORM_SET_VALID = DOMESTIC_INSURANCE_BUILDING_DETAILS + 'setValid/';

export const FORM_VALID_BUILDING_DETAILS_FORM = 'domesticInsurance/buildingDetailsForm/transition/formValid';
export const FORM_INVALID_BUILDING_DETAILS_FORM = 'domesticInsurance/buildingDetailsForm/transition/formInValid';
export const REINITIALIZE = 'domesticInsurance/buildingDetailsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_BUILDING_DETAILS_FORM = 'domesticInsurance/carDetailsForm/transition/dropOffLead';
export const dropOffLeadBuildingDetails = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_BUILDING_DETAILS_FORM,
    value,
});

export const transitionDomesticInsuranceBuildingDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_BUILDING_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_BUILDING_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_BUILDING_DETAILS_FORM = `${BUILDING_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_BUILDING_DETAILS_FORM = `${BUILDING_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_BUILDING_DETAILS_FORM = `${BUILDING_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitioncarDetailsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_BUILDING_DETAILS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_BUILDING_DETAILS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_BUILDING_DETAILS_FORM,
    }),
};

//calculated Data
export const COLLECT_BUILDING_DETAILS_FORM_DATA = BUILDING_DETAILS_FORM_SET_VALUE + 'collectCarDetailsData';
export const SUBMIT_BUILDING_DETAILS_SUCCESS = BUILDING_DETAILS_FORM_SET_VALUE + 'success';
export const SUBMIT_BUILDING_DETAILS_FAILURE = BUILDING_DETAILS_FORM_SET_VALUE + 'failure';

export const collectBuildingDetailsData = () => ({
    type: COLLECT_BUILDING_DETAILS_FORM_DATA,
});
export const submitBuildingDetailsDataSuccess = (value) => ({
    type: SUBMIT_BUILDING_DETAILS_SUCCESS,
    value,
});
export const submitBuildingDetailsFailure = (error) => ({
    type: SUBMIT_BUILDING_DETAILS_FAILURE,
    error,
});

// Form Values
export const FINACIAL_INST_INTEREST_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'hasFinacialInterest';
export const FINACIAL_INST_INTEREST_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'hasFinacialInterest';

export const FINACIAL_INSTITUTION_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'finacialInstitution';
export const FINACIAL_INSTITUTION_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'finacialInstitution';

export const STREET_OF_PREMISE_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'streetOfPremise';
export const STREET_OF_PREMISE_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'streetOfPremise';

export const TOWN_OF_PREMISE_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'townOfPremise';
export const TOWN_OF_PREMISE_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'townOfPremise';

export const BUILDING_TYPE_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'buildingType';
export const BUILDING_TYPE_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'buildingType';

export const USE_OF_PROPERTY_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'useOfProperty';
export const USE_OF_PROPERTY_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'useOfProperty';

export const NATURE_OF_ROOF_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'roofNature'
export const NATURE_OF_ROOF_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'roofNature'

export const NATURE_OF_WALL_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'wallNature';
export const NATURE_OF_WALL_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'wallNature';

export const LOCATION_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'location';
export const LOCATION_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'location';

export const LR_NUMBER_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'lrNumber';
export const LR_NUMBER_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'lrNumber';

export const SECURITY_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'security';
export const SECURITY_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'security';

export const PERIMETER_WALL_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'perimeterWall';
export const PERIMETER_WALL_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'perimeterWall';

export const LAND_SCAPING_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'landScaping';
export const LAND_SCAPING_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'landScaping';

export const SWIMMING_POOL_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'swimmingPool';
export const SWIMMING_POOL_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'swimmingPool';

export const NO_OF_FLOORS_SET_VALUE = BUILDING_DETAILS_FORM_SET_VALUE + 'noOfFloors';
export const NO_OF_FLOORS_SET_VALID = BUILDING_DETAILS_FORM_SET_VALID + 'noOfFloors';


// action creators
export const setFinacialInterestValue = (value) => ({
    type: FINACIAL_INST_INTEREST_SET_VALUE,
    value,
});

export const setFinacialInterestValid = (error) => ({
    type: FINACIAL_INST_INTEREST_SET_VALID,
    error,
});

export const setFinacialInstNameValue = (value) => ({
    type: FINACIAL_INSTITUTION_SET_VALUE,
    value,
});

export const setFinacialInstNameValid = (error) => ({
    type: FINACIAL_INSTITUTION_SET_VALID,
    error,
});

export const setStreetOfPremiseValue = (value) => ({
    type: STREET_OF_PREMISE_SET_VALUE,
    value,
});

export const setStreetOfPremiseValid = (error) => ({
    type: STREET_OF_PREMISE_SET_VALID,
    error,
});

export const setTownOfPremiseValue = (value) => ({
    type: TOWN_OF_PREMISE_SET_VALUE,
    value,
});

export const setTownOfPremiseValid = (error) => ({
    type: TOWN_OF_PREMISE_SET_VALID,
    error,
});

export const setBuildingTypeValue = (value) => ({
    type: BUILDING_TYPE_SET_VALUE,
    value,
});

export const setBuildingTypeValid = (error) => ({
    type: BUILDING_TYPE_SET_VALID,
    error,
});


export const setUseOfPropertyValue = (value) => ({
    type: USE_OF_PROPERTY_SET_VALUE,
    value,
});

export const setUseOfPropertyValid = (error) => ({
    type: USE_OF_PROPERTY_SET_VALID,
    error,
});

export const setNatureOfRoofValue = (value) => ({
    type: NATURE_OF_ROOF_SET_VALUE,
    value,
});
export const setNatureOfRoofValid = (error) => ({
    type: NATURE_OF_ROOF_SET_VALID,
    error,
});

export const setNatureOfWallValue = (value) => ({
    type: NATURE_OF_WALL_SET_VALUE,
    value,
});
export const setNatureOfWallValid = (error) => ({
    type: NATURE_OF_WALL_SET_VALID,
    error,
});

export const setLocationValue = (value) => ({
    type: LOCATION_SET_VALUE,
    value,
});

export const setLocationValid = (error) => ({
    type: LOCATION_SET_VALID,
    error,
});

export const setLrNumberValue = (value) => ({
    type: LR_NUMBER_SET_VALUE,
    value,
});

export const setLrNumberValid = (error) => ({
    type: LR_NUMBER_SET_VALID,
    error,
});

export const setSecurityValue = (value) => ({
    type: SECURITY_SET_VALUE,
    value,
});

export const setSecurityValid = (error) => ({
    type: SECURITY_SET_VALID,
    error,
});

export const setPerimeterWallValue = (value) => ({
    type: PERIMETER_WALL_SET_VALUE,
    value,
});

export const setPerimeterWallValid = (error) => ({
    type: PERIMETER_WALL_SET_VALID,
    error,
});

export const setLandScapingValue = (value) => ({
    type: LAND_SCAPING_SET_VALUE,
    value,
});

export const setLandScapingValid = (error) => ({
    type: LAND_SCAPING_SET_VALID,
    error,
});

export const setSwimmingValue = (value) => ({
    type: SWIMMING_POOL_SET_VALUE,
    value,
});

export const setSwimmingValid = (error) => ({
    type: SWIMMING_POOL_SET_VALID,
    error,
});

export const setNoOfFloorsValue = (value) => ({
    type: NO_OF_FLOORS_SET_VALUE,
    value,
});

export const setNoOfFloorsValid = (error) => ({
    type: NO_OF_FLOORS_SET_VALID,
    error,
});
