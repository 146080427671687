import { 
    SET_TRIP_TYPE_VALUE,
    SET_COVER_FOR_VALUE,
    SET_DEPARTURE_COUNRTY_VALUE,
    SET_DESTINATION_COUNRTY_VALUE,
    SET_DEPARTURE_DATE_VALUE,
    SET_RETURN_DATE_VALUE,
    SET_NO_OF_TRAVELLERS_VALUE,
    SET_DOB_VALUE,
    setTripTypeValid,
    setCoverForValid,
    setDepartureCountryValid,
    setDestinationCountryValid,
    setDepatureDateValid,
    setReturnDateValid,
    setNoOfTravellersValid,
    setDobDateValid
    
 } from '../../../actions/travel/quote';

 import {getDepatureDate } from '../../../selectors/travel/quote'

import {  validateSelection, validateNumber, validateDate, validateName, validateDateOfBirth } from '@om/validation';
import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const quoteFormValidation = (store, next, action) => {
    const state = store.getState();
    const depatureDate = getDepatureDate(state)
    const validationAction = (() => {
        let error;
        let today = new Date()
        let  validTodayDate = today.toLocaleDateString('en-NG').split('/').join('/');
        switch (action.type) {
            case SET_TRIP_TYPE_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Trip type value' });
                return setTripTypeValid(error);
            case SET_COVER_FOR_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Cover for value' });
                return setCoverForValid(error);
            case SET_DEPARTURE_COUNRTY_VALUE:
                error = validateName(action.value, { min: 1, subject: 'Departure country' });
                return setDepartureCountryValid(error);
            case SET_DESTINATION_COUNRTY_VALUE:
                error = validateNumber(action.value, { min: 1, subject: 'Destination country' });
                return setDestinationCountryValid(error);
            case SET_DEPARTURE_DATE_VALUE:
                error = validateDate(action.value, { required: true, min: validTodayDate, subject: 'Departure date' });
                return setDepatureDateValid(error);
            case SET_RETURN_DATE_VALUE:
                error = validateDate(action.value, { required: true, min: depatureDate.value, subject: 'Return date' });
                return setReturnDateValid(error);
            case SET_NO_OF_TRAVELLERS_VALUE:
                error = validateNumber(action.value, { min: 1, subject: 'Number of travellers' });
                return setNoOfTravellersValid(error);
            case SET_DOB_VALUE:
                error = validateDateOfBirth(action.value, { min: 18, subject: 'Date of birth' });
                return setDobDateValid(error);
            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
