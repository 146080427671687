export function removeSpaces(value) {
    return value.replace(/ /g, '');
}

export function isVowel(character) {
    return new RegExp(/[aeiouAEIOU]/).test(character);
}

export function prependAorAn(val) {
    const stringArray = val.toString().includes(' ') ? val.toString().split(' ') : Array.from(val);
    const lastWord = stringArray[stringArray.length - 1];
    const isPlural = val.charAt(val.length - 1) === 's' && !lastWord.toLowerCase().includes('address');
    if (val && val.length > 0 && isVowel(val.charAt(0)) && !isPlural) {
        return `an ${val}`;
    }

    return `a ${val}`;
}

export function lowercasePhrase(phrase) {
    if (phrase && typeof phrase === 'string' && phrase.length > 0)
        return phrase[0].toLocaleUpperCase() + phrase.slice(1);
    else return '';
}

export function uppercasePhrase(phrase) {
    if (phrase && typeof phrase === 'string' && phrase.length > 0)
        return phrase[0].toLocaleUpperCase() + phrase.slice(1);
    else return '';
}

export function getRequiredMessage(subject, retainCase = false) {
    const message = `You'll need to enter ${prependAorAn(subject)}.`;
    return retainCase ? message : sentenceCase(message);
}

export function getGenericRequiredMessage(subject) {
    const message = `You'll need to enter ${prependAorAn(subject)}.`;
    return message;
}

export function getInitialsRequiredMessage(subject, pronoun, retainCase = false) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    const message = `You'll need to enter ${lowercasePronoun} ${subject}.`;
    return retainCase ? message : sentenceCase(message);
}

export function getInvalidMessage(subject, retainCase = false) {
    const message = `Oops, that didn't work. Please enter the ${subject} again.`;
    return retainCase ? message : sentenceCase(message);
}

export function getGenericInvalidCharacterMessage() {
    const message = `Oops, that didn't work. Please enter only valid characters`;
    return message;
}

export function getGenericInvalidNumericCharacterMessage() {
    const message = `Oops, that didn't work. Please enter only valid numeric characters`;
    return message;
}

export function getInvalidStreetAddressMessage() {
    return `Please enter a valid street address. Postal addresses are not allowed E.g. PO BOX.`;
}

export function getInvalidSymbolMessage(subject, retainCase = false) {
    const message = `A symbol has been entered that is not permitted. The symbols that are permitted 
    are as follows: [] () ! ? . , / @ % \\ & : ; ' -`;
    return retainCase ? message : sentenceCase(message);
}

export function getAlphaRequired(subject, retainCase = false) {
    const message = `The ${subject} requires at least one letter.`;
    return retainCase ? message : sentenceCase(message);
}

export function leadingZeroMessage(retainCase = false) {
    const message = `Oops, that didn't work. You need to add a 0 in front of your mobile number. E.g. 082 123 4567.`;
    return retainCase ? message : sentenceCase(message);
}

export function integerMinMessage(subject, min, retainCase = false) {
    const message = `Please enter ${prependAorAn(subject)} greater than ${min}.`;
    return retainCase ? message : sentenceCase(message);
}

export function integerMaxMessage(subject, max, retainCase = false) {
    const message = `Oops, you need to enter ${prependAorAn(subject)} less than ${max}.`;
    return retainCase ? message : sentenceCase(message);
}

export function getNoCommasMsg(subject, retainCase = false) {
    const message = `${subject} can't have any commas present.`;
    return retainCase ? message : sentenceCase(message);
}

export function getDecimalsMsg(subject, decimalPoints, retainCase = false) {
    const message = `Oops, the ${subject} can't have more than ${decimalPoints} decimal digits.`;
    return retainCase ? message : sentenceCase(message);
}

export function numberMinMessage(subject, min, retainCase = false) {
    const message = `Oops, you need to enter ${prependAorAn(subject)} greater than ${min}.`;
    return retainCase ? message : sentenceCase(message);
}

export function numberMaxMessage(subject, max, retainCase = false) {
    const message = `Oops, you need to enter ${prependAorAn(subject)} less than ${max}.`;
    return retainCase ? message : sentenceCase(message);
}

export function getStringTooShortMsg(subject, min, pronoun, retainCase = false) {
    const uppercasePronoun = uppercasePhrase(pronoun);
    const message = `${uppercasePronoun} ${subject} needs to be longer than ${min} characters.`;
    return retainCase ? message : sentenceCase(message);
}

export function getStringTooLongMsg(subject, max, pronoun, retainCase = false) {
    const uppercasePronoun = uppercasePhrase(pronoun);
    const message = `${uppercasePronoun} ${subject} needs to be shorter than ${max} characters.`;
    return retainCase ? message : sentenceCase(message);
}

export function getPassportTooShortMsg(subject, min, retainCase = false) {
    const message = `Oops, the ${subject} needs to be longer than ${min} characters.`;
    return retainCase ? message : sentenceCase(message);
}

export function getPassportTooLongMsg(subject, max, retainCase = false) {
    const message = `Oops, the ${subject} needs to be shorter than ${max} characters.`;
    return retainCase ? message : sentenceCase(message);
}

export function getSelectMinMsg(min) {
    const optionsText = min === 1 ? 'option' : 'options';
    return sentenceCase(`Oops, you'll need to select at least ${min} ${optionsText}.`);
}

export function getSelectSubjectMsg(subject, retainCase = false) {
    const message = `Oops, you'll need to select ${prependAorAn(subject)}.`;
    return retainCase ? message : sentenceCase(message);
}

export function getSelectMinSubjectMsg(min, subject, subjectPlural, retainCase = false) {
    const subjectText = min === 1 ? subject : subjectPlural;
    const message = `Oops, you'll need to select at least ${min} ${subjectText}`;
    return retainCase ? message : sentenceCase(message);
}

export function getTooYoungMsg(min, pronoun, retainCase = false) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    const message = `Sorry, ${lowercasePronoun} age needs to be at least ${min} years old.`;
    return retainCase ? message : sentenceCase(message);
}

export function getTooOldMsg(max, pronoun, retainCase = false) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    const message = `Sorry, ${lowercasePronoun} age needs to be younger than ${max} years old.`;
    return retainCase ? message : sentenceCase(message);
}

export function getTooOldToSaveMsg(years, pronoun, retainCase = false) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    const message = `Sorry, ${lowercasePronoun} age can only save upto ${years} years`;
    return retainCase ? message : sentenceCase(message);
}

export function getTooOldMsgNextBday(max, pronoun, retainCase = false) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    const message = `Sorry, ${lowercasePronoun} age needs to be younger than ${
        max + 1
    } years old (Age adjusted to reflect next birthday).`;
    return retainCase ? message : sentenceCase(message);
}

export function getDateTooEarlyMsg(minDisplayString, retainCase = false) {
    const message = `Oops, you need to provide a date after ${minDisplayString}.`;
    return retainCase ? message : sentenceCase(message);
}

export function getDateTooLateMsg(maxDisplayString, retainCase = false) {
    const message = `Oops, you need to provide a date before ${maxDisplayString}.`;
    return retainCase ? message : sentenceCase(message);
}

export function getDateOfBirthNotAllowedMsg(pronoun, retainCase = false) {
    const message = `Oops, please check ${pronoun} date of birth again.`;
    return retainCase ? message : sentenceCase(message);
}

export function invalidConfigMessage(msg = '') {
    return `Invalid validation configuration. ${msg}`;
}

export function getUploadRequiredMessage() {
    return `Please upload the required documents.`;
}
export function getOneFileAtATimeMessage(retainCase = false) {
    const message = 'Sorry, you can only upload one file at a time.';
    return retainCase ? message : sentenceCase(message);
}
export function createFileTypesList(fileTypes) {
    // Creates a 'PDF, JPEG or PNG' or 'PDF or PNG'. From array ['PDF', 'JPEG']
    if (fileTypes.length === 1) return fileTypes[0];
    else {
        const lastType = fileTypes.pop();
        return [fileTypes.join(', '), lastType].join(' or ');
    }
}
export function getIncorrectFormatMessage() {
    return `Sorry, that's an incorrect file format.`;
}
export function getMaxFileSizeMessage(maxSizeName, retainCase = true) {
    const message = `Oh no! Your file size exceeds the ${maxSizeName} limit.`;
    return retainCase ? message : sentenceCase(message);
}
export function getFileSizeRangeMessage(maxSizeName,minSizeName, retainCase = true) {
    const message = `Oh no! Your file size must be above ${minSizeName} and below ${maxSizeName}.`
    return retainCase ? message : sentenceCase(message);
}

/** Error message for incorrect formatting of a date */
export const DATE_FORMAT_ERROR = 'Oops, you need to enter a date in the dd/mm/yyyy format.';
export const DATE_OF_BIRTH_INVALID_FIELD_ERROR = "Oops, that didn't work. Please enter the date of birth again.";
export const UNDERAGE_ERROR = 'Sorry, you need to be at least 18 years old.';
export const DATE_OF_BIRTH_NOT_ALLOWED_ERROR = 'Oops, please check your date of birth again.';
export const ID_AGE_MISMATCH_ERROR = "Oops, the ID number and birthdate don't match.";
export const WHOLE_NUMBER_ERROR = 'Please enter a whole number.';

// Normalizes sentence case, should not be used when sentence contains proper nouns
function sentenceCase(originalErrorMessage) {
    const sentenceArray = originalErrorMessage.split('.');
    const caseArray = sentenceArray.map((sentence) => {
        let firstWordIndex = 0;
        const messageArray = sentence.split(' ');

        if (!messageArray[0].length) firstWordIndex = 1;

        const smallArray = messageArray.map((word, index) => {
            if (index === firstWordIndex) {
                return word;
            }

            return word.length <= 2 ? word : word.toLowerCase();
        });

        return smallArray.join(' ');
    });

    return caseArray.join('.');
}

export function getPolicyExpireMsg(max, pronoun) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    return `Sorry, ${lowercasePronoun} policy term expiry value should be less than ${max} years.`;
}

export function getYearOfManufacturerOldMsg(max) {
    return `Sorry, the year of manufacturer should be less than ${max} years.`;
}

export function getValidYearMessage() {
    return `You'll need to enter a valid year format.`;
}

export function getYearOfManufacturerFutureMsg(currentYear) {
    return `Sorry, the year of manufacturer should not be beyond ${currentYear}.`;
}

export function getLoanTermMinLimit(min, pronoun) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    return `Sorry, ${lowercasePronoun} term expiry value should not be less than ${min} months.`;
}

export function getLoanTermMaxLimit(max, pronoun) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    return `Sorry, ${lowercasePronoun} term expiry value should be less than ${max} months.`;
}

export function getLoanTermMinMaxLimit(pronoun) {
    const lowercasePronoun = lowercasePhrase(pronoun);
    return `Sorry, you are not eligible for ${lowercasePronoun} policy`;
}
