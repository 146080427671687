import {
    transitionDividendCalculator,
    setDividendsTotal,
    DIVIDEND_CALC_SHARES_SET_VALUE,
    DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE,
} from "../../../actions/publicWeb/dividendCalculator";
import { getDividendCalculator } from "../../../selectors/publicWeb/dividendCalculator";
import { FIELD_STATUS } from "../../../reducers/status";

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validateDividendCalculator = dividendCalculatorStore => {
    return isValid(dividendCalculatorStore.shares) && dividendCalculatorStore.selectedCountry !== null;
};

export const dividendCalculatorRules = (store, next, action) => {
    const result = next(action);

    const dividendCalculatorStore = getDividendCalculator(store.getState());
    const dividendCalculatorValid = validateDividendCalculator(dividendCalculatorStore);

    store.dispatch(dividendCalculatorValid
        ? transitionDividendCalculator.formValid()
        : transitionDividendCalculator.formInvalid());

    if ([
        DIVIDEND_CALC_SHARES_SET_VALUE,
        DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE
    ].includes(action.type)) {
        store.dispatch(setDividendsTotal(null));
    }

    return result;
};
