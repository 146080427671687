import axios from "axios";
import { setReferenceData } from "../../../actions/taxFreeSavingsAccount/referenceData";
import { getIsReferenceDataValid } from "../../../selectors/taxFreeSavingsAccount/referenceData";
import { HTTP_STATUS_CODES } from "../../constants";
import { setRequestId } from "../../../actions/taxFreeSavingsAccount/sessionCamVariables";

export const referenceDataService = (store, next, action) => {
    const result = next(action);

    if (getIsReferenceDataValid(store.getState())) return;

    (async () => {
        const referenceData = await fetchReferenceData();
        if (referenceData && referenceData.status === HTTP_STATUS_CODES.OK) {
            store.dispatch(setReferenceData(referenceData.data));
        }

        if (referenceData && 'data' in referenceData && 'requestId' in referenceData.data) {
            store.dispatch(setRequestId(referenceData.data.requestId));
        }
    })();

    return result;
};

export const fetchReferenceData = async () => {
    try {
        return await axios.get(
            '/om-api/tax-free-savings-account/reference-data',
            {headers: {'Content-Type': 'application/json'}}
        );
    } catch (error) {
        return null;
    }
};
