import {
    CELLPHONE_NUMBER_SET_VALUE,
    COMPLAINT_DESCRIPTION_SET_VALUE,
    COMPLAINT_FORM_SEND_INITIATE,
    COMPLAINT_RELATION_SET_VALUE,
    COMPLAINT_TITLE_SET_VALUE,
    EMAIL_SET_VALUE,
    FIRSTNAME_SET_VALUE,
    IDENTITY_TYPE_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    PASSPORT_COUNTRY_SET_VALUE,
    PASSPORT_NUMBER_SET_VALUE,
    POLICY_NUMBER_SET_VALUE,
    PRODUCT_TYPE_SET_VALUE,
    SURNAME_SET_VALUE,
    TITLE_SET_VALUE,
    TOGGLE_DRAWER,
} from '../../../actions/customerComplaint/complaintArbitration';

import {
    getTitle,
    getFirstName,
    getSurname,
    getCellphoneNumber,
    getEmail,
    getIdentityType,
    getIdNumber,
    getPassportCountry,
    getPassportNumber,
    getPolicyNumber,
    getProductType,
    getComplaintTitle,
    getComplaintRelation,
    getComplaintDescription,
    getDrawerIsOpen
} from '../../../selectors/customerComplaint/complaintArbitration';
import { simpleFieldTracking } from '../formTracking';
import {findOtherEventIndex, otherEventTracking} from '../otherEventTracking';
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";


export const complaintArbitrationFieldTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendError = true;
    let sendValue = true;

    const fieldSelector = (() => {
        switch (action.type) {
            case TITLE_SET_VALUE:
                sendError = false;
                return getTitle;
            case FIRSTNAME_SET_VALUE:
                sendValue = false;
                return getFirstName;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurname;
            case CELLPHONE_NUMBER_SET_VALUE:
                sendValue = false;
                return getCellphoneNumber;
            case EMAIL_SET_VALUE:
                sendValue = false;
                return getEmail;
            case IDENTITY_TYPE_SET_VALUE:
                return getIdentityType;
            case ID_NUMBER_SET_VALUE:
                sendValue = false;
                return getIdNumber;
            case PASSPORT_NUMBER_SET_VALUE:
                sendValue = false;
                return getPassportNumber;
            case PASSPORT_COUNTRY_SET_VALUE:
                sendError = false;
                return getPassportCountry;
            case POLICY_NUMBER_SET_VALUE:
                sendValue = false;
                return getPolicyNumber;
            case PRODUCT_TYPE_SET_VALUE:
                sendError = false;
                return getProductType;
            case COMPLAINT_RELATION_SET_VALUE:
                sendError = false;
                return getComplaintRelation;
            case COMPLAINT_TITLE_SET_VALUE:
                return getComplaintTitle;
            case COMPLAINT_DESCRIPTION_SET_VALUE:
                return getComplaintDescription;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        simpleFieldTracking({ action, field, sendValue, sendError });
    }

    return result;
};

export const complaintAritrationTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const trackingActions = [
        TOGGLE_DRAWER
    ];

    let openDrawer = getDrawerIsOpen(state);
    if (trackingActions.includes(action.type)){
        if(openDrawer){
            otherEventTracking(action.type, findOtherEventIndex("OPEN_DRAWER"), openDrawer);
        }
    }

    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case COMPLAINT_FORM_SEND_INITIATE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Customer Complaint';
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}
