import { validateSingleSelection, validateContactNumber } from '@om/validation';
import {
    setPhoneNumberValid,
    PHONE_NUMBER_SET_VALUE,
    setTermsValid,
    TERMS_SET_VALUE,
    setNetworkProviderValid,
    SET_NETWORK_PROVIDER,
} from '../../../actions/personalPensionPlan/payments';
import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const paymentsFormValidation = (store, next, action) => {
    const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Payments Phone Number',
                    countryCode: gatsbyStack,
                });
                return setPhoneNumberValid(error);

            case TERMS_SET_VALUE:
                error = validateSingleSelection(action.value, 'Terms');
                return setTermsValid(error);

            case SET_NETWORK_PROVIDER:
                error = validateSingleSelection(action.value, 'Network Provider');
                return setNetworkProviderValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
