export const EFP_ROUTING_SET_VALUE = 'easiplusFuneralPlan/routing/setValue/';

export const NAVIGATE_PREVIOUS = EFP_ROUTING_SET_VALUE + 'navigatePrevious';
export const NAVIGATE_NEXT = EFP_ROUTING_SET_VALUE + 'navigateNext';
export const NAVIGATE_INDEX = EFP_ROUTING_SET_VALUE + 'navigateIndex';
export const SET_NAVIGATION_DONE = EFP_ROUTING_SET_VALUE + 'navigationDone';
export const navigatePrevious = () => ({ type: NAVIGATE_PREVIOUS });
export const navigateNext = () => ({ type: NAVIGATE_NEXT });
export const navigateIndex = value => ({ type: NAVIGATE_INDEX, value });
export const setNavigationDone = () => ({ type: SET_NAVIGATION_DONE })

export const RESET_ROUTING = 'easiplusFuneralPlan/routing/transition/resetState';
export const resetRouting = () => ({ type: RESET_ROUTING });

// IMPLEMENT FOR CVS
// export const SET_NAVIGATION_BACK_TO_PARENT_APP = SET_VALUE_ROUTING + 'navigateBackToParentApp';
// export const SET_NAVIGATION_TO_CVS_FROM_PARENT_APP = SET_VALUE_ROUTING + 'navigateToCVSFromParentApp';
// export const navigateBackToParentApp = () => ({ type: SET_NAVIGATION_BACK_TO_PARENT_APP });
// export const navigateToCVSFromParentApp = ({parentAppUrl, callbackAction, callMeBackSource}) => ({
//     type: SET_NAVIGATION_TO_CVS_FROM_PARENT_APP,
//     parentAppUrl,
//     callbackAction,
//     callMeBackSource,
// });

export const SET_ACTIVE_PAGE_INDEX = EFP_ROUTING_SET_VALUE + 'activePageIndex';
export const setActivePageIndex = value => ({ type: SET_ACTIVE_PAGE_INDEX, value });

// per page transitions
export const ROUTING_TRANSITION = 'easiplusFuneralPlan/routing/transition/';

export const NAVIGATE_TO_REPLACEMENT_POLICY = ROUTING_TRANSITION + 'toReplacementPolicy';
export const navigateToReplacementPolicy = () => ({type: NAVIGATE_TO_REPLACEMENT_POLICY});

export const NAVIGATE_TO_PERSONAL_DETAILS = ROUTING_TRANSITION + 'toPersonalDetails';
export const navigateToPersonalDetails = () => ({type: NAVIGATE_TO_PERSONAL_DETAILS});

export const NAVIGATE_TO_LIVES_COVERED = ROUTING_TRANSITION + 'toLivesCovered';
export const navigateToLivesCovered = () => ({type: NAVIGATE_TO_LIVES_COVERED});

export const NAVIGATE_TO_BENEFICIARIES = ROUTING_TRANSITION + 'toBeneficiaries';
export const navigateToBeneficiaries = () => ({type: NAVIGATE_TO_BENEFICIARIES});

export const NAVIGATE_TO_BENEFICIARIES_SPLIT = ROUTING_TRANSITION + 'toBeneficiariesSplit';
export const navigateToBeneficiariesSplit = () => ({type: NAVIGATE_TO_BENEFICIARIES_SPLIT});

export const NAVIGATE_TO_PAYMENT_DETAILS = ROUTING_TRANSITION + 'toPaymentDetails';
export const navigateToPaymentDetails = () => ({type: NAVIGATE_TO_PAYMENT_DETAILS});

export const NAVIGATE_TO_CONFIRMATION = ROUTING_TRANSITION + 'toConfirmation';
export const navigateToConfirmation = () => ({type: NAVIGATE_TO_CONFIRMATION});

export const NAVIGATE_TO_THANK_YOU = ROUTING_TRANSITION + 'toThankYou';
export const navigateToThankYou = () => ({type: NAVIGATE_TO_THANK_YOU});