import { createSelector } from 'reselect';
import { getAllsureInsurance } from './index';

export const getPurchasePersonalDetails = createSelector(
    getAllsureInsurance,
    (allsureInsurance) => allsureInsurance.purchasePersonalDetails
);

export const getPurchasePersonalDetailsForm = createSelector(
    getPurchasePersonalDetails,
    (purchasePersonalDetails) => purchasePersonalDetails.form
);

export const getPurchasePersonalDetailsReferenceData = () => ({
    titleList: [
        { description: 'Mr.', code: 'Mr.' },
        { description: 'Mrs.', code: 'Mrs.' },
        { description: 'Miss.', code: 'Miss.' },
    ],
    nationalities: [
        { description: 'Botswana', code: 'Botswana' },
        { description: 'South Africa', code: 'South Africa' },
        { description: 'Eswatini', code: 'Eswatini' },
        { description: 'Ghana', code: 'Ghana' },
        { description: 'Kenya', code: 'Kenya' },
        { description: 'Malawi', code: 'Malawi' },
        { description: 'Namibia', code: 'Namibia' },
        { description: 'Nigeria', code: 'Nigeria' },
        { description: 'Rwanda', code: 'Rwanda' },
        { description: 'South Sudan', code: 'South Sudan' },
        { description: 'Tanzania', code: 'Tanzania' },
        { description: 'Uganda', code: 'Uganda' },
        { description: 'Zimbabwe', code: 'Zimbabwe' },
    ],
    residencePeriod: [
        { description: 'Less than two years', code: 'Less than two years' },
        { description: 'More than two years', code: 'More than two years' },
    ],
    countryCodeRefData:[
            { code: '+267', description: 'bw' },
            { code: '+264', description: 'na' },
            { code: '+27', description: 'za' },
            { code: '+263', description: 'zw' },
    ],
    referenceHearAboutUs:[
        {
            code: 'online',
            description: 'Online',
        },
        {
            code: 'onRadio',
            description: 'On radio',
        },
        {
            code: 'onTv',
            description: 'On Tv',
        },
        {
            code: 'fromBillboard',
            description: 'From a billboard',
        },
        {
            code: 'fromSomeone',
            description: 'From someone I know',
        },
        {
            code: 'omEmployee',
            description: 'Old mutual employee',
        },
        {
            code: 'broker',
            description: 'Broker/Agent',
        },
        {
            code: 'other',
            description: 'Other',
        },
    ],
    referenceOnlinePlatform:[
        {
            code: 'facebook',
            description: 'Facebook',
        },
        {
            code: 'linkedIn',
            description: 'LinkedIn',
        },
        {
            code: 'twitter/X',
            description: 'Twitter/X',
        },
        {
            code: 'youTube',
            description: 'YouTube',
        },
        {
            code: 'googleSearch',
            description: 'Google Search',
        },
       
    ]
    
});
const personalDetailLabels = {
    title: 'Title',
    fullname: 'Full name',
    nationality: 'Nationality',
    id: 'Botswana/Passport ID',
    dateOfBirth: 'Date of birth',
    placeOfBirth: 'Place Of birth',
    streetAddress: 'Plot number/suburb/ward',
    city: 'City/Town/Village',
    country: 'Country',
    residenceTimePeriod: 'How long have you been living in this address?',
    previousPostalAddress: 'Previous street address',
    previousPostalCity: 'Previous city',
    previousPostalCountry: 'Previous Country',
    postalSuburb: 'P O Box/Private Bag/Suburb/Ward',
    postalCity: 'Postal City/Town/Village',
    postalCountry: 'Postal country',
    employerName: 'Employer name',
    workAddress: 'Place of work',
    countryCodeWork:'Telephone work code',
    countryCodePurchase:'Cellphone number code',
    mobileNumber: 'Cellphone number',
    email: 'Email address',
    telephoneWork: 'Telephone work',
};
export const getPersonalDetailsSummary = createSelector(
    getPurchasePersonalDetailsForm,
    (purchasePersonalDetailsForm) => {
        const { previousPostalAddress, previousPostalCity, previousPostalCountry, ...personalDetailFields } =
            purchasePersonalDetailsForm;
        purchasePersonalDetailsForm.residenceTimePeriod.value === 'Less than two years';
        const personalDetails = Object.keys(
            purchasePersonalDetailsForm.residenceTimePeriod.value === 'Less than two years'
                ? purchasePersonalDetailsForm
                : personalDetailFields
        )?.map((item) => {
            return {
                label: personalDetailLabels[item],
                value: purchasePersonalDetailsForm[item]?.value ?? '-',
            };
        });
        const contactDetails = personalDetails.splice(-5, 5);
        const addressDetails = personalDetails.splice(6, 10);
        return {
            personalDetails,
            contactDetails,
            addressDetails,
        };
    }
);
