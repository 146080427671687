import { REFERRER_ID_SET_VALUE } from '../../../actions/atRetirement/intro'
import { getReferrerId } from '../../../selectors/atRetirement/intro'
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const introTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const referrerId = (() => {
        switch (action.type) {
            case REFERRER_ID_SET_VALUE: return getReferrerId(state);
            default: return null;
        }
    })();

    if (referrerId) {
        otherEventTracking(action.type, findOtherEventIndex("ARC_SET_REFERRER_ID"), referrerId );
    }

    return result;
};
