import { createSelector } from 'reselect';
import { getInvestmentCalculators } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';
import { formatCurrency } from '../currency';

export const getShortTermGoalCalculator = createSelector(
	getInvestmentCalculators,
	(investmentCalculators) => investmentCalculators.shortTermGoalCalculator
);

export const getShortTermGoalSplit = createSelector(
	getInvestmentCalculators,
	(investmentCalculators) => investmentCalculators.shortTermGoalFundSplit
);

export const getShortTermGoalCalculatorForm = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) => shortTermGoalCalculator.form
);

export const getShortTermGoalCalculatorFormValid = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) =>
		shortTermGoalCalculator.formStatus === FORM_STATUS.VALID
);

const makeGetField = (fieldId) =>
	createSelector(getShortTermGoalCalculatorForm, (form) => form[fieldId]);

export const getAmountOnceOffValue = makeGetField('amountOnceOff');
export const getMonthlyAmountValue = makeGetField('amountMonthly');
export const getInvestmentTenureValue = makeGetField('investmentTenure');
export const getPotentialLossPercentageValue = makeGetField(
	'potentialLossPercentage'
);

export const getShortTermGoalResults = createSelector(
	getShortTermGoalCalculatorForm,
	(shortTermGoalCalculator) => shortTermGoalCalculator.calculationResults
);

export const getAmountNeededToBeSaved = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) =>
		formatCurrency(shortTermGoalCalculator.amountNeededToBeSaved, 'KES')
);

export const getShowResult = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) =>
		shortTermGoalCalculator.apiCallStatus === API_CALL_STATUS.SUCCESS
);

export const getShowError = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) =>
		shortTermGoalCalculator.apiCallStatus === API_CALL_STATUS.FAILURE
);

export const getIsApiPending = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) =>
		shortTermGoalCalculator.apiCallStatus === API_CALL_STATUS.PENDING
);

export const getBtnText = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) => {
		switch (shortTermGoalCalculator.apiCallStatus) {
			case API_CALL_STATUS.SUCCESS:
				return 'RECALCULATE';
			case API_CALL_STATUS.FAILURE:
				return 'TRY AGAIN';
			case API_CALL_STATUS.PENDING:
				return 'CALCULATING...';
			default:
				return 'CALCULATE';
		}
	}
);

export const getHasCalculateBtnIcon = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) => {
		return (
			shortTermGoalCalculator.apiCallStatus !== API_CALL_STATUS.PENDING
		);
	}
);

export const getCalculateDisabled = createSelector(
	[getShortTermGoalCalculator, getShortTermGoalSplit],
	(shortTermGoalCalculator, shortTermGoalSplit) => {
		return (
			shortTermGoalCalculator.formStatus === FORM_STATUS.INVALID ||
			shortTermGoalCalculator.apiCallStatus === API_CALL_STATUS.PENDING ||
			shortTermGoalSplit.formStatus === FORM_STATUS.INVALID
		);
	}
);

export const getInputDisabled = createSelector(
	getShortTermGoalCalculator,
	(shortTermGoalCalculator) => {
		return (
			shortTermGoalCalculator.apiCallStatus === API_CALL_STATUS.PENDING
		);
	}
);
