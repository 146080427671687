import { validateSingleSelection } from  "@om/validation";
import {
    setDeclarationsDrawerValidationMessage,
    TOGGLE_DECLARATIONS_CONFIRM_DETAILS
} from "../../../actions/easiplusFuneralPlan/confirmDetails";
import { getConfirmDetailsValid } from "../../../selectors/easiplusFuneralPlan/confirmDetails"

export const confirmDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TOGGLE_DECLARATIONS_CONFIRM_DETAILS:
                error = validateSingleSelection((action.error ? null : true), 'checkbox');
                return setDeclarationsDrawerValidationMessage(error);

            default:
                return null;
        }
    })();

    if(!validationAction)
        return next(action);

    store.dispatch(validationAction);
    return next(action);
};
