import { transitionLivesCoveredPersonalDetails } from "../../../actions/healthInsuranceRW/personalDetails";
import { FIELD_STATUS } from "../../../reducers/status";
import { getPersonalDetailsForm } from "../../../selectors/healthInsuranceRW/personalDetails";
import { getQuoteForm } from "../../../selectors/healthInsuranceRW/quote";

export const personalDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const quoteForm = getQuoteForm(store.getState());
    const personalDetailsFormValid = quoteForm.isChildrenIncluded.value ? validatePersonalDetailsChildFormDetails(personalDetailsForm) : validatePersonalDetailsFormDetails(personalDetailsForm);
    const formAction = personalDetailsFormValid
        ? transitionLivesCoveredPersonalDetails.formValid()
        : transitionLivesCoveredPersonalDetails.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
    return (
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.surname &&
        formFields.surname.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.mobileNumber &&
        formFields.mobileNumber.status === FIELD_STATUS.VALID &&
        formFields.city &&
        formFields.city.status === FIELD_STATUS.VALID &&
        formFields.streetAddress &&
        formFields.streetAddress.status === FIELD_STATUS.VALID
    );
};

const validatePersonalDetailsChildFormDetails = (formFields) => {
    return (
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.surname &&
        formFields.surname.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID
    );
};
