import {
    BANKING_DETAILS_SET_VALUE,
    BANKING_DETAILS_SET_VALID,
    FORM_INVALID_TRANSITION_BANKING_DETAILS,
    FORM_VALID_TRANSITION_BANKING_DETAILS,
} from '../../actions/allsureInsurance/bankingDetails';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initBankingDetails = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        bankName: { value: null, error: null, status: null, displayValue: null },
        branchCode: { value: null, error: null, status: null, displayValue: null },
        accountHolderName: { value: null, error: null, status: FIELD_STATUS.VALID, displayValue: null },
        accountNumber: { value: null, error: null, status: null, displayValue: null },
        accountType: { value: null, error: null, status: null, displayValue: null },
        coverStartDate: { value: null, error: null, status: null, displayValue: null },
        debitOrderDay: { value: null, error: null, status: null, displayValue: null },
        sourceOfFund: { value: null, error: null, status: null, displayValue: null },
        otherSourceofFund:{ value: null, error: null, status: null, displayValue: null },
        paymentConfirmation: { value: null, error: null, status: null, displayValue: null }
    },
};

export default (state = initBankingDetails, action) => {
    const trimField = (value) => (value ? value.trim() : value);
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    // if ( CLAIMENT_DETAILS_SET_VALUE) {
    switch (action.type) {
        case FORM_VALID_TRANSITION_BANKING_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_BANKING_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case BANKING_DETAILS_SET_VALUE:
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
            }));
        
        case BANKING_DETAILS_SET_VALID:
            const { fieldType, error } = action;
            return updateFormField(fieldType, (field) => ({
                ...field,
                error: error,
                status: mapError(error),
            }));

        default:
            return state;
        // }
    }
};
