import {BIOGRAPHIC_INFO_SET_VALUE} from "../../../actions/covidSelfAssessment/biographicInfo";
import {biographicInfoFieldTracking} from "./biographicInfo";
import {CLINICAL_CRITERIA_SET_VALUE} from "../../../actions/covidSelfAssessment/clinicalCriteria";
import {clinicalCriteriaFieldTracking} from "./clinicalCriteria";
import {TRAVEL_HISTORY_SET_VALUE} from "../../../actions/covidSelfAssessment/travelHistory";
import {travelHistoryFieldTracking} from "./travelHistory";
import {RESULT_SET_VALUE} from "../../../actions/covidSelfAssessment/result";
import {resultFieldTracking} from "./result";

export const covidSelfAssessmentTracking = (store, next, action) => {
    if (action.type.startsWith(BIOGRAPHIC_INFO_SET_VALUE))
        return biographicInfoFieldTracking(store, next, action);
    if (action.type.startsWith(CLINICAL_CRITERIA_SET_VALUE))
        return clinicalCriteriaFieldTracking(store, next, action);
    if (action.type.startsWith(TRAVEL_HISTORY_SET_VALUE))
        return travelHistoryFieldTracking(store, next, action);
    if (action.type.startsWith(RESULT_SET_VALUE))
        return resultFieldTracking(store, next, action);
    if (action.type.startsWith(RESULT_SET_VALUE))
        return resultFieldTracking(store, next, action);

    return null;
};
