import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from "../status";

import {
    FORM_VALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION,
    FORM_INVALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION,
    SAVINGS_GOAL_SET_VALUE,
    SAVINGS_GOAL_SET_VALID,
    ACHIEVE_GOAL_DATE_SET_VALUE,
    ACHIEVE_GOAL_DATE_SET_VALID,
    CURRENT_SAVINGS_SET_VALUE,
    CURRENT_SAVINGS_SET_VALID,
    PRODUCT_RECOMMENDATION_SET_VALUE,
    REINITIALIZE_STATE_HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR,
    RESET_CALCULATOR_FORM,
    TOGGLE_SHOW_PRODUCT_OFFERING,
    HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SHOW_RESULT_SUMMARY,
    HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_OPEN_MODAL,
    HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_CLOSE_MODAL
} from '../../actions/savingsCalculator/howMuchYouNeedToSaveCalculator'

const howMuchYouNeedToSaveForm = {
    savingsGoal : {value: null, error: null, status: null},
    achieveGoalDate : {value:null, error: null,status:null},
    currentSavings: {value:null, error: null,status:null},
}
export const initialState = {
    formStatus: FORM_STATUS.INVALID,
    showRecommendation: false,
    apiCallStatus: API_CALL_STATUS.IDLE,
    openModal: false,
    showResultSummary: false,
    form: howMuchYouNeedToSaveForm,
    amountNeededToBeSaved: null
};

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch(action.type) {
        // Form Validation
         case FORM_VALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_HOW_MUCH_YOU_NEED_TO_SAVE_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Savings Goal
        case SAVINGS_GOAL_SET_VALUE:
            return updateFormField('savingsGoal', field => ({
                ...field,
                value: action.value,
            }));
        case SAVINGS_GOAL_SET_VALID:
            return updateFormField('savingsGoal', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Goal Date
        case ACHIEVE_GOAL_DATE_SET_VALUE:
            return updateFormField('achieveGoalDate', field => ({
                ...field,
                value: action.value,
            }));
        case ACHIEVE_GOAL_DATE_SET_VALID:
            return updateFormField('achieveGoalDate', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // How Much Saved
        case CURRENT_SAVINGS_SET_VALUE:
            return updateFormField('currentSavings', field => ({
                ...field,
                value: action.value,
            }));
        case CURRENT_SAVINGS_SET_VALID:
            return updateFormField('currentSavings', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        
        // Product recommendation
        case PRODUCT_RECOMMENDATION_SET_VALUE:
            return {
                ...state,
                showRecommendation: true
            }

        case HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_OPEN_MODAL:
            return{
                ...state,
                openModal: true,
            }

        case HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_CLOSE_MODAL:
            return{
                ...state,
                openModal: false,
            }

        // Show result summary
        case HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SHOW_RESULT_SUMMARY:
            return {
                ...state,
                showResultSummary: true
            };

        // reinitialise state
        case RESET_CALCULATOR_FORM:
            return initialState;

        case TOGGLE_SHOW_PRODUCT_OFFERING:
            return {
                ...state,
                showRecommendation: !state.showRecommendation,
            };

        default:
            return state;
    }
}