import axios from 'axios';

import {
    apiTransitionPriceTable,
    FETCH_PRICE_TABLE_FILTERED_FUNDS,
    setFundsValue,
} from '../../../actions/priceTable/table'

import { isApiPending, getSelectedDate } from '../../../selectors/priceTable/table';

const getFundsForApplicableDate = async store => {
    const state = store.getState();

    if (!isApiPending(state)) {
        store.dispatch(apiTransitionPriceTable.pending());
        try {
            const result = await axios.get(`/om-api/price-table/get-price-table?date=${getSelectedDate(state).value}`)
            store.dispatch(setFundsValue(result.data));
            store.dispatch(apiTransitionPriceTable.success());
        } catch (error) {
            store.dispatch(setFundsValue([]));
            store.dispatch(apiTransitionPriceTable.failure());
        }
    }
}

export const getFundsForApplicableDateService = (store, next, action) => {
    (async () => {
        const result = next(action);

        if (action.type === FETCH_PRICE_TABLE_FILTERED_FUNDS) {
            getFundsForApplicableDate(store);
        }

        return result;
    })();
}
