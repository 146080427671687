import {FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS} from "../status";
import {
    ADVENTUROUS_INVESTOR_SELECT,
    AGE_SET_VALID,
    AGE_SET_VALUE,
    RESET_PREMIUM_ESCALATION_SELECT,
    CLEAR_PREMIUM_ESCALATION_SELECT,
    CHOOSE_OWN_PERCENTAGE_SELECT,
    COMFORTABLE_INVESTOR_SELECT,
    LINK_WITH_INFLATION_SELECT,
    MONTHLY_INVESTMENT_DERIVED_TERM_SET_VALUE,
    MONTHLY_INVESTMENT_COMMITTED_TERM_SET_VALUE,
    MONTHLY_INVESTMENT_RESET,
    MONTHLY_INVESTMENT_SET_VALID,
    MONTHLY_INVESTMENT_SET_VALUE,
    NO_INCREASE_SELECT,
    OWN_PERCENTAGE_SET_VALID,
    OWN_PERCENTAGE_SET_VALUE,
    OWN_PERCENTAGE_RESET_VALUE,
    RETIRE_AT_SET_OPTIONS,
    RETIRE_AT_SET_VALID,
    RETIRE_AT_SET_VALUE,
    CLEAR_INVESTOR_TYPE,
    RESET_INVESTOR_TYPE,
    INVESTOR_TYPE_SET_VALID,
    PREMIUM_ESCALATION_VALID,
    FORM_VALID_TRANSITION_INVESTMENT_SETUP,
    FORM_INVALID_TRANSITION_INVESTMENT_SETUP,
    FACT_SHEET_CLICKED, SET_IS_CVS_VERIFIED,
    RESET_INVESTMENT_SETUP
} from "../../actions/raOptimal/investmentSetup";

export const initInvestmentSetup = {
    formStatus: FORM_STATUS.INVALID,
    monthlyInvestmentDerivedTerm: null,
    monthlyInvestmentCommittedTerm: null,
    form: {
        age: { value: null, error: null, status: null },
        retirementAge: { value: null, error: null, status: null },
        monthlyInvestment: { value: null, error: null, status: null },
        ownPercentage: { value: null, error: null, status: null },
        yearlyIncrease: {
            linkWithInflation: TOGGLE_STATUS.UNTOUCHED,
            chooseOwnPercentage: TOGGLE_STATUS.UNTOUCHED,
            noIncrease: TOGGLE_STATUS.UNTOUCHED,
            error: null,
        },
        typeOfInvestor: {
            comfortable: TOGGLE_STATUS.UNTOUCHED,
            adventurous: TOGGLE_STATUS.UNTOUCHED,
            error: null,
        },
    },
    factSheetClicked: null,
    isCvsVerified: false,
};

export default (state = initInvestmentSetup, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_INVESTMENT_SETUP:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_INVESTMENT_SETUP:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case CLEAR_PREMIUM_ESCALATION_SELECT:
            return updateFormField('yearlyIncrease', () => ({
                linkWithInflation: null,
                chooseOwnPercentage: null,
                noIncrease: null,
                error: null
            }));
        case RESET_PREMIUM_ESCALATION_SELECT:
            return updateFormField('yearlyIncrease', () => ({
                linkWithInflation: TOGGLE_STATUS.UNTOUCHED,
                chooseOwnPercentage: TOGGLE_STATUS.UNTOUCHED,
                noIncrease: TOGGLE_STATUS.UNTOUCHED,
                error: null
            }));
        case PREMIUM_ESCALATION_VALID:
            return updateFormField('yearlyIncrease', () => ({
                linkWithInflation: TOGGLE_STATUS.UNTOUCHED,
                chooseOwnPercentage: TOGGLE_STATUS.UNTOUCHED,
                noIncrease: TOGGLE_STATUS.UNTOUCHED,
                error: action.error
            }));
        case LINK_WITH_INFLATION_SELECT:
            return updateFormField('yearlyIncrease', () => ({
                linkWithInflation: true,
                chooseOwnPercentage: false,
                noIncrease: false,
                error: null
            }));
        case CHOOSE_OWN_PERCENTAGE_SELECT:
            return updateFormField('yearlyIncrease', () => ({
                linkWithInflation: false,
                chooseOwnPercentage: true,
                noIncrease: false,
                error: null
            }));
        case NO_INCREASE_SELECT:
            return updateFormField('yearlyIncrease', () => ({
                linkWithInflation: false,
                chooseOwnPercentage: false,
                noIncrease: true,
                error: null
            }));

        case COMFORTABLE_INVESTOR_SELECT:
            return updateFormField('typeOfInvestor', () => ({
                comfortable: true,
                adventurous: false,
                error: null,
            }));
        case ADVENTUROUS_INVESTOR_SELECT:
            return updateFormField('typeOfInvestor', () => ({
                comfortable: false,
                adventurous: true,
                error: null,
            }));
        case CLEAR_INVESTOR_TYPE:
            return updateFormField('typeOfInvestor', field => ({
                ...field,
                comfortable: null,
                adventurous: null,
            }));
        case RESET_INVESTOR_TYPE:
            return updateFormField('typeOfInvestor', () => ({
                error: null,
                comfortable: TOGGLE_STATUS.UNTOUCHED,
                adventurous: TOGGLE_STATUS.UNTOUCHED,
            }));
        case INVESTOR_TYPE_SET_VALID:
            return updateFormField('typeOfInvestor', () => ({
                comfortable: TOGGLE_STATUS.UNTOUCHED,
                adventurous: TOGGLE_STATUS.UNTOUCHED,
                error: action.error
            }));
        case FACT_SHEET_CLICKED:
            return {
                ...state,
                factSheetClicked: action.value,
            }

        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RETIRE_AT_SET_VALUE:
            return updateFormField('retirementAge', field => ({
                ...field,
                value: action.value,
            }));
        case RETIRE_AT_SET_OPTIONS:
            return updateFormField('retirementAge', field => ({
                value: null,
                error: null,
                status: null,
                options: action.options,
            }));
        case RETIRE_AT_SET_VALID:
            return updateFormField('retirementAge', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MONTHLY_INVESTMENT_SET_VALUE:
            return updateFormField('monthlyInvestment', field => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_INVESTMENT_SET_VALID:
            return updateFormField('monthlyInvestment', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MONTHLY_INVESTMENT_RESET:
            return updateFormField('monthlyInvestment', field => ({
                ...field,
                error: null,
                status: null,
                value: null,
            }));
        case MONTHLY_INVESTMENT_DERIVED_TERM_SET_VALUE:
            return {
                ...state,
                monthlyInvestmentDerivedTerm: action.term,
            };
        case MONTHLY_INVESTMENT_COMMITTED_TERM_SET_VALUE:
            return {
                ...state,
                monthlyInvestmentCommittedTerm: action.term,
            };

        case OWN_PERCENTAGE_SET_VALUE:
            return updateFormField('ownPercentage', field => ({
                ...field,
                value: action.value,
            }));
        case OWN_PERCENTAGE_SET_VALID:
            return updateFormField('ownPercentage', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case OWN_PERCENTAGE_RESET_VALUE:
            return updateFormField('ownPercentage', field => ({
                value: null,
                error: null,
                status: null
            }));

        case SET_IS_CVS_VERIFIED:
            return {
                ...state,
                isCvsVerified: true,
            };

        case RESET_INVESTMENT_SETUP: 
            return { ...initInvestmentSetup };

        default:
            return state;
    }
};
