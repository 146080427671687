import { findOtherEventIndex, otherEventTracking } from '../otherEventTracking';
import {
    CALCULATE_TRANSITION_RESULTS,
} from '../../../actions/educationCalculator/calculator';
import { fireGtmEvent } from '../gtmEvents';

export const educationCalculatorTransitionTracking = (store, next, action) => {
    const result = next(action);

    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case CALCULATE_TRANSITION_RESULTS:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                fireGtmEvent('calculator_complete', { calculator_type: 'Education calculator' });
                return 'EducationCalculatorCalculateResults';
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}
