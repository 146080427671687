import { transitionPersonalAccidentDeclarationsForm } from '../../../actions/personalAccident/declarations';
import { getDeclarationsForm } from '../../../selectors/personalAccident/declarations';
//import { FIELD_STATUS } from '../../../reducers/status';

export const declarationsFormRules = (store, next, action) => {
    const result = next(action);
    const declarationsForm = getDeclarationsForm(store.getState());
    const declarationsFormValid = validateDeclarationsFormDetails(declarationsForm);
    const formAction = declarationsFormValid
        ? transitionPersonalAccidentDeclarationsForm.formValid()
        : transitionPersonalAccidentDeclarationsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateDeclarationsFormDetails = (formFields) => {
    return true;
};
