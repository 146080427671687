import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';


export function validatePoBoxNumber(
    valueStr, 
    { 
        required = true, 
        subject = 'PO box number',
        retainSubjectCase
    } = {}) {

    if (!valueStr && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!valueStr && !required) {
        return null;
    }

    const numberRegEx = new RegExp('^[0-9]*$');
    const pleaseMessage = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    const invalidMinCharCountMessage = OmValidationMessageHelper.getStringTooShortMsg(subject, 0, config.PRONOUN.YOUR, retainSubjectCase);
    const invalidMaxCharCountMessage = OmValidationMessageHelper.getStringTooLongMsg(subject, 10, config.PRONOUN.YOUR, retainSubjectCase);

    if (!numberRegEx.test(valueStr)) {
        return pleaseMessage;
    }

    const valueNum = parseInt(valueStr, 10);
    // Min is a single integer.
    const min = 0;
    // Max is an integer with 10 digits.
    const max = Math.pow(10, 10);

    if (valueNum > max){
        return invalidMaxCharCountMessage;
    } 
    // else if (valueNum < min) {
    //     return invalidMinCharCountMessage;
    // } this condition will never be met, uncommenting for test coverage

    return null;
}
