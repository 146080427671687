import { getQuoteForm, getPremiumCalculationUg } from '../../../selectors/motorPrivateRW/quote';
import { getPersonalDetailsForm } from '../../../selectors/motorPrivateRW/personalDetails';
import { getCarDetailsForm } from '../../../selectors/motorPrivateRW/carDetails';
import { getDocumentsForm } from '../../../selectors/motorPrivateRW/documents';
import { getDeclarationsForm } from '../../../selectors/motorPrivateRW/declarations';
import { getPaymentsForm } from '../../../selectors/motorPrivateRW/payments';
import { getIsApiPending } from '../../../selectors/motorPrivateRW/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/motorPrivateRW/payloadCollection';
import { getAgentForm } from '../../../selectors/motorPrivateRW/travelAgentModal';
import { getAccessToken, getPaymentStatusData } from '../../../selectors/motorPrivateRW/payments';
import axios from 'axios';
import { triggerConfirmOrder } from '../../../actions/motorPrivateRW/payments';

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await calculate(store.getState());

                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());
                // store.dispatch(triggerConfirmOrder());
            } catch (e) {
                console.error(e);
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

const payloadCollection = (state) => {
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const carDetails = getCarDetailsForm(state);
    const documents = getDocumentsForm(state);
    const declarations = getDeclarationsForm(state);
    const payments = getPaymentsForm(state);
    const agent = getAgentForm(state);
    const premium = getPremiumCalculationUg(state);
    const paymentsData = getPaymentStatusData(state);

    const payload = {
        countryCode: 'RW',
        color: 'red',
        make: parseInt(quotation.carMake.value.id) || 0,
        model: parseInt(quotation.carModel.value.id) || 0,
        yearOfManufacture: quotation.yearOfManufacture.value,
        registrationNumber: carDetails.registrationNumber.value,
        chassisNumber: carDetails.chasisNumber.value,
        sittingCapacity: quotation.seatsNumber.value,
        fuel: 2000,
        bodyType: 'Sedan',
        stickerCollectionPoint: 'N/A',
        alarmInstalled: false,
        valuationDone: false,
        use: quotation.carUse.value,
        carValue: quotation.carValue.value || 0,
        valuedBy: 'N/A',
        basePremiumRate: 0,
        referredByAgent: agent.intermediaryType.value === 'AGENTS' ? true : false,
        agentCode: agent.agentCode.value,
        agentName: agent.name.value,
        fullName: `${personalDetails.firstName.value} ${personalDetails.lastName.value}`,
        firstName: personalDetails.firstName.value,
        lastName: personalDetails.lastName.value,
        emailAddress: personalDetails.email.value,
        dateOfBirth: personalDetails.dob.value,
        idNumber: personalDetails.idNumber.value || personalDetails.passport.value,
        nationality: personalDetails.nationality.value,
        phoneNumber: personalDetails.phoneNumber.value,
        tinNumber: carDetails.tinNumber.value,
        postalAddress: personalDetails.address.value,
        gender: personalDetails.gender.value,
        occupation: personalDetails.occupation.value,
        address: personalDetails.address?.value || 'N/A',
        annualPremium: premium?.value?.data?.totalPremium,
        transactionRef: paymentsData?.data[0]?.trxRef || 'N/A',
        transactionStatus: paymentsData?.data[0]?.status || 'N/A',
        modeOfPayment: payments.networkProvider.value === 'mtn' ? 'FDIMOMO' : 'BANK',
        nationalId: documents.idUpload.value.base64File,
        yellowCard: documents.yellowCard.value.base64File,
        carFrontViewPhoto: documents.frontView.value.base64File,
        carBackViewPhoto: documents.backView.value.base64File,
        carLeftSidePhoto: documents.leftView.value.base64File,
        carRightSidePhoto: documents.rightView.value.base64File,
        carDashboardPhoto: documents.dashboardView.value.base64File,
        salutation: personalDetails.salutation.value || "N/A",
        coverStartDate: new Date(),
        step: 'payment',
        declarations: [
            {
                description:
                    'Yes, I hereby declare that myself or any other person who to my knowledge will drive the vehicle under this proposal does not suffer from defective vision, hearing or from any physical infirmity or fits of any kind.',
                name: 'driverHealthDeclaration',
                agreed: declarations.driverHealthDeclaration.value,
            },
            {
                description:
                    'Yes, I hereby declare that the vehicle under this proposal is in a perfect state of repair.',
                name: 'vehicleConditionDeclaration',
                agreed: declarations.vehicleConditionDeclaration.value,
            },
            {
                description:
                    'Yes, I hereby declare that myself and any other authorized drivers for the vehicle under this policy possess a valid driving licence to drive motor vehicles described in this proposal.',
                name: 'driverLicenseDeclaration',
                agreed: declarations.driverLicenseDeclaration.value,
            },
            {
                description:
                    'Yes, I hereby consent to the insurer applying the average clause in adjusting claims in case the vehicle was underinsured or overinsured compared to the current value of the vehicle.',
                name: 'averageClauseConsent',
                agreed: declarations.averageClauseConsent.value,
            },
            {
                description:
                    'Yes, I further declare that the amounts proposed for insurance represent the full value of the property insured.',
                name: 'fullValueDeclaration',
                agreed: declarations.fullValueDeclaration.value,
            },
            {
                description:
                    'Yes, I confirm that I have read and understood the Privacy Notice and the Privacy Policy and that any queries/concerns I have with regard to the nature and purpose of the processing of personal data have been adequately addressed.',
                name: 'privacyPolicyConfirmation',
                agreed: declarations.privacyPolicyConfirmation.value,
            },
            {
                description:
                    'Yes, I acknowledge the advice given and indemnify OLD MUTUAL INSURANCE RWANDA PLC. on any misrepresentation of such advice.',
                name: 'adviceAcknowledgementIndemnity',
                agreed: declarations.adviceAcknowledgementIndemnity.value,
            },
            {
                description:
                    'Yes, I understand that the insurer will not refund me for any unconsumed premium on cancellation of my current policy in case I have reported a claim in my current policy.',
                name: 'noPremiumRefundAcknowledgement',
                agreed: declarations.noPremiumRefundAcknowledgement.value,
            },
            {
                description:
                    'Yes, I understand that in case of a claim when I am yet to finalize on the payment plan, I will be required to settle the full annual premium for the insurer to proceed with claims settlement.',
                name: 'fullPremiumSettlementConsent',
                agreed: declarations.fullPremiumSettlementConsent.value,
            },
            {
                description:
                    'Yes, I hereby declare the truth and correctness of the above statements and agree that this declaration shall be held to be promissory and the basis of contract between me and Old Mutual Insurance Rwanda.',
                name: 'finalDeclarationAgreement',
                agreed: declarations.finalDeclarationAgreement.value,
            },
            {
                description:
                    'Yes,I consent to receiving all marketing information and communications about all new products and services',
                name: 'marketingConsent',
                agreed: declarations.marketingConsent.value,
            },
            {
                description: 'No, I do not consent to receiving marketing information',
                name: 'marketingRefusal',
                agreed: declarations.marketingConsent.value,
            },
        ],
    };

    return payload;
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/rw-motor-insurance/submit-payload';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
