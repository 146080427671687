export const County_QUOTE_TOOL = 'roaQuoteTools/afyaImaraCountyQuoteTool/';
export const County_QUOTE_TOOL_SET_VALUE = County_QUOTE_TOOL + 'setValue/';
export const County_QUOTE_TOOL_SET_VALID = County_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_County_QUOTE_TOOL =
    'roaQuoteTools/afyaImaraCountyQuoteTool/transition/formStateValid';
export const FORM_INVALID_County_QUOTE_TOOL =
    'roaQuoteTools/afyaImaraCountyQuoteTool/transition/formInvalid';
export const REINITIALIZE =
    'roaQuoteTools/afyaImaraCountyQuoteTool/state/reinitialize';

export const transitionCountyTool = {
    formValid: () => ({ type: FORM_VALID_County_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_County_QUOTE_TOOL }),
};

export const WHICH_FORM_TO_DISPLAY =
    County_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

//api status
export const API_PENDING_TRANSITION_County_QUOTE_TOOL = `${County_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_County_QUOTE_TOOL = `${County_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_County_QUOTE_TOOL = `${County_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionCountyQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_County_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_County_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_County_QUOTE_TOOL }),
};
///childern
export const AGE_18child_SET_VALUE = County_QUOTE_TOOL_SET_VALUE + 'child_18';
export const AGE_18child_SET_VALID = County_QUOTE_TOOL_SET_VALID + 'child_18';

///other
export const AGE_BRACKET_SET_VALUE = County_QUOTE_TOOL_SET_VALUE + 'ageBracket';
export const AGE_BRACKET_SET_VALID = County_QUOTE_TOOL_SET_VALID + 'ageBracket';
export const OUT_PATIENT_SET_VALUE = County_QUOTE_TOOL_SET_VALUE + 'outPatient';
export const OUT_PATIENT_SET_VALID = County_QUOTE_TOOL_SET_VALID + 'outPatient';
export const SPOUSE_AGE_SET_VALUE = County_QUOTE_TOOL_SET_VALUE + 'spouseAge';
export const SPOUSE_AGE_SET_VALID = County_QUOTE_TOOL_SET_VALID + 'spouseAge';
export const SPOUSE_COVER_SET_VALUE =
    County_QUOTE_TOOL_SET_VALUE + 'spouseCover';
export const SPOUSE_COVER_SET_VALID =
    County_QUOTE_TOOL_SET_VALID + 'spouseCover';
export const CC_SET_VALUE = County_QUOTE_TOOL_SET_VALUE + 'childCover';
export const CC_SET_VALID = County_QUOTE_TOOL_SET_VALID + 'childCover';
export const IN_PATIENT_SET_VALUE = County_QUOTE_TOOL_SET_VALUE + 'inPatient';
export const IN_PATIENT_SET_VALID = County_QUOTE_TOOL_SET_VALID + 'inPatient';
//api part 2
export const COLLECT_COUNTY_PREMIUM_DATA =
    County_QUOTE_TOOL_SET_VALUE + 'collectCountyPremiumData';
export const CALCULATE_COUNTY_PREMIUM_DATA =
    County_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_COUNTY_PREMIUM_DATA_FAILURE =
    County_QUOTE_TOOL_SET_VALUE + 'failure';

//form data
export const setAgeBracketValue = (value) => ({
    type: AGE_BRACKET_SET_VALUE,
    value,
});
export const setAgeBracketValid = (error) => ({
    type: AGE_BRACKET_SET_VALID,
    error,
});
export const setSpouseAgeValue = (value) => ({
    type: SPOUSE_AGE_SET_VALUE,
    value,
});
export const setSpouseAgeValid = (error) => ({
    type: SPOUSE_AGE_SET_VALID,
    error,
});
export const setSpouseCoverValue = (value) => ({
    type: SPOUSE_COVER_SET_VALUE,
    value,
});
export const setSpouseCoverValid = (error) => ({
    type: SPOUSE_COVER_SET_VALID,
    error,
});
export const setChildrenCoverValue = (value) => ({ type: CC_SET_VALUE, value });
export const setChildrenCoverValid = (error) => ({ type: CC_SET_VALID, error });
export const setInPatientValue = (value) => ({
    type: IN_PATIENT_SET_VALUE,
    value,
});
export const setInPatientValid = (error) => ({
    type: IN_PATIENT_SET_VALID,
    error,
});
export const setOutPatientValue = (value) => ({
    type: OUT_PATIENT_SET_VALUE,
    value,
});
export const setOutPatientValid = (error) => ({
    type: OUT_PATIENT_SET_VALID,
    error,
});
export const setWhichFormToDisplay = (value) => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});
export const setToCountyQuoteInitial = () => ({ type: REINITIALIZE });
////api
export const collectCountyPremiumData = () => ({
    type: COLLECT_COUNTY_PREMIUM_DATA,
});
export const calculateCountyPremiumData = (value) => ({
    type: CALCULATE_COUNTY_PREMIUM_DATA,
    value,
});
export const collectCountyPremiumDataFailure = (error) => ({
    type: CALCULATE_COUNTY_PREMIUM_DATA_FAILURE,
    error,
});

///chilren
export const set18childValue = (value) => ({
    type: AGE_18child_SET_VALUE,
    value,
});
export const set18childValid = (error) => ({
    type: AGE_18child_SET_VALID,
    error,
});
