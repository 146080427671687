import { createSelector } from 'reselect';
import { getBeneficiaryList } from './beneficiaries';
import { getBeneficiariesSplitForm } from './beneficiariesSplit';
import { getLegalGuardian, getSavingForMinor } from './investmentSetupInitial';
import {
    getAccountNumber,
    getAccountTypeDataEntry,
    getBankDataEntry,
    getDebitOrderDay,
    getEmploymentIndustry,
    getEmploymentIndustryOther,
    getEmploymentPosition,
    getLumpSumDay,
    getLumpSumMonth,
    getMonth,
    getMonthToSkip,
    getSourceOfFunds,
    getSourceOfIncome,
    getSourceOfIncomeOther,
    getUploadedBirthCertificateFile,
    getUploadedCourtMandateFile,
    getUploadedIdentityDocumentFile,
    getAvsPayload,
    getCdvPayload,
    getBankSupportedForTFSA,
    getIncludeAvs,
} from './paymentDetails';
import { getContactForFeedback, getContactMethod, getFirstNames, getSurname, getTitle } from './personalDetails';
import {
    getReferenceMaritalStatuses,
    getReferenceSourceOfFunds,
    getReferenceSourceOfIncome,
    getReferenceTitles,
} from './referenceData';
import {
    getId,
    getMaritalStatus,
    getMinorFirstNames,
    getMinorId,
    getMinorSurname,
    getMinorTitle,
    getNationality,
    getSpouseFirstNames,
    getSpouseIdNumber,
    getSpousePassportNumber,
    getSpouseSurname,
    getSpouseTitle,
    getSubmissionContactInformation,
} from './auxiliaryDetails';
import {
    getContributionType,
    getLumpSumContribution,
    getPremiumEscalationValue,
    getRegularContribution,
} from './investmentSetupContribution';
import {
    calculateAge,
    dateOfBirthFromIdNumber,
    getAgeFromIdNumber,
    getGenderFromIdNumber,
    getInitialsFromName,
    initialsFromNameList,
    mapGenderDataEntry,
} from '../../functions/taxFreeSavingsAccount/Util';
import { getSplitFieldList } from './fundSplit';
import { getFundCode, getFundName } from './selectAFund';
import { CONTRIBUTION_TYPE } from '../../actions/taxFreeSavingsAccount/types';

// Funds
export const getFundsSubmissionLumpSum = createSelector(
    getSplitFieldList,
    getFundName,
    getFundCode,
    (splitFieldList, fundName, fundCode) => {
        // If single fund is selected from select a fund page
        if (fundName) return [{ fundCode: fundCode, fundName: fundName, percentageAllocation: 100 }];

        // If funds were selected on advancedFundPicker
        return splitFieldList.lumpSum.map((selectedFund) => {
            return {
                fundCode: selectedFund.fundCode,
                fundName: selectedFund.fundName,
                percentageAllocation: Number(selectedFund.value),
            };
        });
    }
);

export const getFundsSubmissionRegularContribution = createSelector(
    getSplitFieldList,
    getFundName,
    getFundCode,
    (splitFieldList, fundName, fundCode) => {
        // If single fund is selected from select a fund page
        if (fundName) return [{ fundCode: fundCode, fundName: fundName, percentageAllocation: 100 }];

        // If funds were selected on advancedFundPicker
        return splitFieldList.regular.map((selectedFund) => {
            return {
                fundCode: selectedFund.fundCode,
                fundName: selectedFund.fundName,
                percentageAllocation: Number(selectedFund.value),
            };
        });
    }
);

// Products
export const getRegularContributionProduct = createSelector(
    getRegularContribution,
    getFundsSubmissionRegularContribution,
    getPremiumEscalationValue,
    (regularContribution, fundsSubmissionRegularContribution, premiumEscalationValue) => {
        return {
            initialPremium: regularContribution.value ? parseInt(regularContribution.value) : 0,
            investmentFrequency: 'Monthly',
            annualPremiumIncrease: premiumEscalationValue,
            investmentFund: fundsSubmissionRegularContribution,
        };
    }
);

export const getLumpSumProduct = createSelector(
    getLumpSumContribution,
    getFundsSubmissionLumpSum,
    (lumpSumValue, fundsSubmissionRegularLumpSum) => {
        return {
            initialPremium: lumpSumValue.value ? parseInt(lumpSumValue.value) : 0,
            investmentFrequency: 'OnceOff',
            annualPremiumIncrease: '0',
            investmentFund: fundsSubmissionRegularLumpSum,
        };
    }
);

export const getProductsSubmission = createSelector(
    getContributionType,
    getRegularContributionProduct,
    getLumpSumProduct,
    (contributionType, regularContributionProduct, lumpSumProduct) => {
        if (contributionType.value == CONTRIBUTION_TYPE.COMBINATION)
            return [regularContributionProduct, lumpSumProduct];

        if (contributionType.value == CONTRIBUTION_TYPE.MONTHLY) return [regularContributionProduct];

        if (contributionType.value == CONTRIBUTION_TYPE.LUMP_SUM) return [lumpSumProduct];
    }
);

// Beneficiaries
export const getBeneficiariesSubmission = createSelector(
    getBeneficiaryList,
    getReferenceTitles,
    getBeneficiariesSplitForm,
    (beneficiaryList, referenceTitles, beneficiariesSplitForm) => {
        return beneficiaryList.map((beneficiary, index) => {
            const { title, firstName, surname, dateOfBirth, mobileNumber, email } = beneficiary.value;
            const benTitle = referenceTitles.find((ref) => ref.code === title.value);
            const age = calculateAge(dateOfBirth.value);
            const initials = initialsFromNameList([firstName, surname]).slice(0, 3);
            const genderValue = title.value === 'Mr' ? 'Male' : 'Female';
            const benGender = mapGenderDataEntry(genderValue);
            const benSharePercentage = beneficiariesSplitForm[index];

            return {
                initials,
                age: +age,
                cellphoneNumber: mobileNumber.value,
                email: email.value,
                firstName: firstName.value,
                surname: surname.value,
                dateOfBirth: dateOfBirth.value,
                sharePercentage: +benSharePercentage.value,
                title: benTitle,
                gender: benGender,
            };
        });
    }
);

// Payment
export const getPaymentSubmission = createSelector(
    getBankDataEntry,
    getAccountNumber,
    getAccountTypeDataEntry,
    getDebitOrderDay,
    getMonth,
    getLumpSumDay,
    getLumpSumMonth,
    getMonthToSkip,
    getFirstNames,
    getSurname,
    getAvsPayload,
    getCdvPayload,
    getBankSupportedForTFSA,
    getIncludeAvs,
    (
        bankDataEntry,
        accountNumber,
        accountTypeDataEntry,
        debitOrderDate,
        debitOrderMonth,
        lumpSumDay,
        lumpSumMonth,
        monthToSkip,
        firstNames,
        surname,
        avsData,
        cdvData,
        isBankSupportedForTFSA,
        isIncludeAVS,
    ) => {
        return {
            debitOrderDateLumpSum: lumpSumDay && lumpSumMonth ? lumpSumDay.value + ' ' + lumpSumMonth.value : '',
            debitOrderDateRegularContribution:
                debitOrderDate && debitOrderMonth ? debitOrderDate.value + ' ' + debitOrderMonth.value : '',
            monthToSkip: monthToSkip ? monthToSkip.value : '',
            bankDetail: {
                accountHolderName: firstNames.value,
                accountHolderSurname: surname.value,
                accountNumber: accountNumber.value && accountNumber.value.split(' ').join(''),
                bank: bankDataEntry,
                accountType: accountTypeDataEntry,
                avsData,
                cdvData,
                isBankSupportedForTFSA,
                isIncludeAVS,
            },
        };
    }
);

// Employment details
export const getFundMemberEmploymentDetail = createSelector(
    getReferenceSourceOfIncome,
    getReferenceSourceOfFunds,
    getSourceOfIncomeOther,
    getEmploymentIndustry,
    getEmploymentPosition,
    getEmploymentIndustryOther,
    getSourceOfFunds,
    getSourceOfIncome,
    (
        referenceSourceOfIncome,
        referenceSourceOfFunds,
        sourceOfIncomeOther,
        employmentIndustry,
        employmentPosition,
        employmentIndustryOther,
        sourceOfFunds,
        sourceOfIncome
    ) => {
        return {
            sourceOfFundsOther: sourceOfIncomeOther.value ?? '',
            industry: employmentIndustry.value ?? 'None',
            employmentPosition: employmentPosition.value,
            employmentIndustryOther: employmentIndustryOther.value ?? '',
            sourceOfFunds: [referenceSourceOfFunds.find((ref) => (ref.code = sourceOfFunds.value))],
            sourceOfIncomes: [referenceSourceOfIncome.find((ref) => (ref.code = sourceOfIncome.value))],
        };
    }
);

// Client data
export const getClientDataSubmission = createSelector(
    // Client Data
    getLegalGuardian,
    getSavingForMinor,
    getSubmissionContactInformation,
    getReferenceTitles,
    getReferenceMaritalStatuses,

    // Get minor details
    getMinorTitle,
    getMinorFirstNames,
    getMinorSurname,
    getMinorId,

    // Get parent details
    getTitle,
    getFirstNames,
    getSurname,
    getId,
    getMaritalStatus,
    getNationality,
    getContactForFeedback,
    getContactMethod,

    // Spouse Data
    getSpouseTitle,
    getSpouseFirstNames,
    getSpouseSurname,
    getSpouseIdNumber,
    getSpousePassportNumber,

    // GetFilesForSubmission
    getUploadedBirthCertificateFile,
    getUploadedCourtMandateFile,
    getUploadedIdentityDocumentFile,
    (
        // Client Data
        legalGuardian,
        savingForMinor,
        contactInformation,
        referenceTitles,
        referenceMaritalStatuses,

        // Minor details
        minorTitle,
        minorFirstNames,
        minorSurname,
        minorId,

        // Parent details
        title,
        firstNames,
        surname,
        id,
        maritalStatus,
        nationality,
        contactForFeedback,
        contactMethod,

        // Spouse Data
        spouseTitle,
        spouseFirstNames,
        spouseSurname,
        spouseIdNumber,
        spousePassportNumber,

        //Files
        uploadedBirthCertificateFile,
        uploadedCourtMandateFile,
        uploadedIdentityDocumentFile
    ) => {
        let files = [];

        if (uploadedBirthCertificateFile) {
            files.push({
                filename: 'Birth Certificate',
                file: uploadedBirthCertificateFile.value,
                mimeType: uploadedBirthCertificateFile.type,
            });
        }

        if (uploadedCourtMandateFile) {
            files.push({
                filename: 'Court Certificate',
                file: uploadedCourtMandateFile.value,
                mimeType: uploadedCourtMandateFile.type,
            });
        }

        if (uploadedIdentityDocumentFile) {
            files.push({
                filename: 'Identity Certificate',
                file: uploadedIdentityDocumentFile.value,
                mimeType: uploadedIdentityDocumentFile.type,
            });
        }

        let clients = [
            {
                // If this is not on behalf of a minor then this is the policy owner
                role: savingForMinor ? legalGuardian.value.replace(/\s/g, '') : 'Owner',
                contactInformation, // Contact information is same for minor as adult
                age: getAgeFromIdNumber(id.value),
                firstName: firstNames.value,
                surname: surname.value,
                initials: getInitialsFromName(firstNames.value),
                dateOfBirth: dateOfBirthFromIdNumber(id.value),
                saIdNumber: id.value,
                passportNumber: '', // Passport is not used currently
                taxNumber: '', // No tax number
                nationality: nationality.value,
                countryOfBirth: nationality.value,
                title: referenceTitles.find((ref) => ref.code === title.value),
                gender: mapGenderDataEntry(getGenderFromIdNumber(id.value)),
                maritalStatusCode: referenceMaritalStatuses.find((ref) => (ref.code = maritalStatus.value))['code'],
                spouse: {
                    firstName: spouseFirstNames.value,
                    surname: spouseSurname.value,
                    identityNumber: spouseIdNumber.value,
                    passportNumber: spousePassportNumber.value,
                    title: referenceTitles.find((ref) => ref.code === spouseTitle.value),
                },
            },
        ];

        if (savingForMinor) {
            if (files) {
                clients.push({
                    role: 'Owner',
                    contactInformation, // Contact information is same for minor as adult
                    age: getAgeFromIdNumber(minorId.value),
                    firstName: minorFirstNames.value,
                    surname: minorSurname.value,
                    initials: getInitialsFromName(minorFirstNames.value),
                    dateOfBirth: dateOfBirthFromIdNumber(minorId.value),
                    saIdNumber: minorId.value,
                    passportNumber: '', // Passport is not used currently
                    taxNumber: '', // TaxNumber is not used currently
                    nationality: 'SouthAfrica', // hardcoded value
                    countryOfBirth: 'SouthAfrica', // hardcoded value
                    title: referenceTitles.find((ref) => ref.code === minorTitle.value),
                    gender: mapGenderDataEntry(getGenderFromIdNumber(minorId.value)),
                    maritalStatusCode: 'Single',
                    files,
                });
            } else {
                clients.push({
                    role: 'Owner',
                    contactInformation, // Contact information is same for minor as adult
                    age: getAgeFromIdNumber(minorId.value),
                    firstName: minorFirstNames.value,
                    surname: minorSurname.value,
                    initials: getInitialsFromName(minorFirstNames.value),
                    dateOfBirth: dateOfBirthFromIdNumber(minorId.value),
                    saIdNumber: minorId.value,
                    passportNumber: '', // Passport is not used currently
                    taxNumber: '', // TaxNumber is not used currently
                    nationality: 'SouthAfrica', // hardcoded value
                    countryOfBirth: 'SouthAfrica', // hardcoded value
                    title: referenceTitles.find((ref) => ref.code === minorTitle.value),
                    gender: mapGenderDataEntry(getGenderFromIdNumber(minorId.value)),
                    maritalStatusCode: 'Single',
                });
            }
        }

        return clients;
    }
);

// GetFilesForSubmission
export const getFilesSubmission = createSelector(
    getUploadedBirthCertificateFile,
    getUploadedCourtMandateFile,
    getUploadedIdentityDocumentFile,
    (
        // Files
        uploadedBirthCertificateFile,
        uploadedCourtMandateFile,
        uploadedIdentityDocumentFile
    ) => {
        let files = [];

        if (uploadedBirthCertificateFile) {
            files.push({
                filename: 'Birth Certificate',
                file: uploadedBirthCertificateFile.value,
                mimeType: uploadedBirthCertificateFile.type,
            });
        }

        if (uploadedCourtMandateFile) {
            files.push({
                filename: 'Court Certificate',
                file: uploadedCourtMandateFile.value,
                mimeType: uploadedCourtMandateFile.type,
            });
        }

        if (uploadedIdentityDocumentFile) {
            files.push({
                filename: 'Identity Certificate',
                file: uploadedIdentityDocumentFile.value,
                mimeType: uploadedIdentityDocumentFile.type,
            });
        }

        return files;
    }
);

// Submission
export const getTfsaSubmission = createSelector(
    getClientDataSubmission,
    getPaymentSubmission,
    getBeneficiariesSubmission,
    getProductsSubmission,
    getFundMemberEmploymentDetail,
    (clients, paymentInformation, beneficiaries, products, fundMemberEmploymentDetail) => {
        const responseObject = {
            products,
            clients,
            fundMemberEmploymentDetail,
            paymentInformation,
            beneficiaries,
        };

        return responseObject;
    }
);
