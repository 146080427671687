import {
    CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER,
    ABOUT_YOU_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER,
    SOLUTIONS_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER,
    CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER,
    STEP_INDEX_SET_VALUE,
} from "../../actions/atRetirement/wizardFooter";

export const initWizardFooter = {
    stepIndex: -1,
    aboutYouContinueButtonDisabled: true,
    solutionsContinueButtonDisabled: true,
    continueButtonVisible: true,
};

export default (state = initWizardFooter, action) => {
    switch (action.type) {
        case ABOUT_YOU_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER:
            return { ...state, aboutYouContinueButtonDisabled: action.value };
        case SOLUTIONS_CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER:
            return { ...state, solutionsContinueButtonDisabled: action.value };
        case CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER:
            return { ...state, continueButtonVisible: action.value };
        case STEP_INDEX_SET_VALUE:
            return {
                ...state,
                stepIndex: action.value
            };
        default:
            return state;
    }
};
