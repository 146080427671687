import {transitionTravelHistory} from "../../../actions/covidSelfAssessment/travelHistory";
import {getTravelHistoryForm} from "../../../selectors/covidSelfAssessment/travelHistory";
import {transitionWizardFooter} from "../../../actions/covidSelfAssessment/wizardFooter";
import {FIELD_STATUS} from "../../../reducers/status";

export const travelHistoryRules = (store, next, action) => {
    const result = next(action);
    const travelHistoryForm = getTravelHistoryForm(store.getState());
    const travelHistoryValid = validateTravelHistory(travelHistoryForm);

    // Travel History Form
    if (travelHistoryValid) {
        store.dispatch(transitionTravelHistory.formValid());
        // Wizard Footer
        store.dispatch(transitionWizardFooter.continueButtonDisabled(false));
    } else {
        store.dispatch(transitionTravelHistory.formInvalid());
        // Wizard Footer
        store.dispatch(transitionWizardFooter.continueButtonDisabled(true));
    }

    return result;
};

// Travel History
const validateTravelHistory = formFields => {
    return formFields.travelledRecently && formFields.travelledRecently.status === FIELD_STATUS.VALID &&
    formFields.recentCovidContact && formFields.recentCovidContact.status === FIELD_STATUS.VALID;
};
