import { getAccessToken } from '../../../selectors/healthInsuranceRW/payments';
import axios from 'axios';
import { setProductsValue } from '../../../actions/healthInsuranceRW/quote';

export const getProductsService = (store, next, action) => {
    (async () => {
        try {
            const resp = await getProducts(store.getState());
            store.dispatch(setProductsValue(resp.data.products));
        } catch (e) {
            console.error(e);
        }
    })();
    return next(action);
};

const getProducts = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/health-insurance-rw/products';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};

