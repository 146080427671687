import {
    API_FAILURE_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    API_PENDING_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    CALCULATE_HEALTH_INSURANCE_PREMIUM_SUCCESS,
    COLLECT_HEALTH_INSURANCE_QUOTE_FORM_DATA,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    COVER_ADDED_BOOLEAN,
    FORM_INVALID_HEALTH_INSURANCE_QUOTE_FORM,
    FORM_INVALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM,
    FORM_VALID_HEALTH_INSURANCE_QUOTE_FORM,
    FORM_VALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM,
    HEALTH_INSURANCE_QUOTE_ADD_COVER,
    HEALTH_INSURANCE_SHARE_QUOTE_VALUE,
    HEALTH_INSURANCE_SIDEBAR_OPEN_VALUE,
    REINITIALIZE,
    SET_CHILD_AGE_VALID,
    SET_CHILD_AGE_VALUE,
    SET_DENTAL_COVER_VALID,
    SET_DENTAL_COVER_VALUE,
    SET_INPATIENT_COVER_VALID,
    SET_INPATIENT_COVER_VALUE,
    SET_IS_CHILDREN_INCLUDED_VALUE,
    SET_IS_SPOUSE_INCLUDED_VALUE,
    SET_MATERNITY_COVER_VALID,
    SET_MATERNITY_COVER_VALUE,
    SET_OPTICAL_COVER_VALID,
    SET_OPTICAL_COVER_VALUE,
    SET_OUTPATIENT_COVER_VALID,
    SET_OUTPATIENT_COVER_VALUE,
    SET_PRODUCTS_VALUE,
    SET_SELECTED_COVER_VALID,
    SET_SELECTED_COVER_VALUE,
    SET_SHARE_EMAIL_VALID,
    SET_SHARE_EMAIL_VALUE,
    SET_SPOUSE_AGE_VALID,
    SET_SPOUSE_AGE_VALUE,
    SET_TABLE_COMPARE_VALUE,
    SET_TABLE_VALUE,
    SET_YEARS_VALID,
    SET_YEARS_VALUE,
    SHARE_HEALTH_INSURANCE_QUOTE_FAILURE,
    SHARE_HEALTH_INSURANCE_QUOTE_SUCCESS,
    SUBMIT_HEALTH_INSURANCE_QUOTE_FAILURE,
    SUBMIT_HEALTH_INSURANCE_QUOTE_SUCCESS,
    TOKEN_IS_EXPIRED,
} from '../../actions/healthInsuranceRW/quote';
import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

export const initQuote = {
    sidebarOpen: false,
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    quoteReferenceData: null,
    addedCover: [],
    addedCoverBoolean: false,
    comparePlans: false,
    products: [],
    comparePlansTable: [],
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    quoteShared: null,
    isTokenExpired: false,
    rwCmbObject: {
        __typename: 'Contentstack_call_me_back_rw',
        product: 'Health Insurance',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    premiumCalculation: null,
    form: {
        shareEmail: { value: null, error: null, status: null },
        selectedCover: { value: null, error: null, status: null },
        age: { value: null, error: null, status: null },
        isSpouseIncluded: { value: false, error: null, status: 'valid' },
        isChildrenIncluded: { value: false, error: null, status: 'valid' },
        spouseAge: { value: null, error: null, status: null },
        childrenAge: { value: null, error: null, status: null },
        inpatientCover: { value: null, error: null, status: null },
        outpatientCover: { value: null, error: null, status: null },
        maternityCover: { value: null, error: null, status: null },
        dentalCover: { value: false, error: null, status: null },
        opticalCover: { value: false, error: null, status: null },
    },
};

export default (state = initQuote, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_HEALTH_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_HEALTH_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };

        case FORM_VALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case SET_SELECTED_COVER_VALUE:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_COVER_VALID:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_YEARS_VALUE:
            return updateFormField('age', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_YEARS_VALID:
            return updateFormField('age', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_IS_SPOUSE_INCLUDED_VALUE:
            return updateFormField('isSpouseIncluded', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_IS_CHILDREN_INCLUDED_VALUE:
            return updateFormField('isChildrenIncluded', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CHILD_AGE_VALUE:
            return updateFormField('childrenAge', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CHILD_AGE_VALID:
            return updateFormField('childrenAge', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_SPOUSE_AGE_VALUE:
            console.log('hfskjhfs');
            return updateFormField('spouseAge', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SPOUSE_AGE_VALID:
            return updateFormField('spouseAge', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_INPATIENT_COVER_VALUE:
            return updateFormField('inpatientCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_INPATIENT_COVER_VALID:
            return updateFormField('inpatientCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_OUTPATIENT_COVER_VALUE:
            return updateFormField('outpatientCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_OUTPATIENT_COVER_VALID:
            return updateFormField('outpatientCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_MATERNITY_COVER_VALUE:
            return updateFormField('maternityCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_MATERNITY_COVER_VALID:
            return updateFormField('maternityCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_DENTAL_COVER_VALUE:
            return updateFormField('dentalCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_DENTAL_COVER_VALID:
            return updateFormField('dentalCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

            case SET_OPTICAL_COVER_VALUE:
                return updateFormField('opticalCover', (field) => ({
                    ...field,
                    value: action.value,
                }));
            case SET_OPTICAL_COVER_VALID:
                return updateFormField('opticalCover', (field) => ({
                    ...field,
                    status: mapError(action.error),
                    error: action.error,
                }));
        // Sidebar open
        case HEALTH_INSURANCE_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        // Share modal
        case HEALTH_INSURANCE_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_HEALTH_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_HEALTH_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_PRODUCTS_VALUE:
            return {
                ...state,
                products: action.value,
            };

        case HEALTH_INSURANCE_QUOTE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };
        case COVER_ADDED_BOOLEAN:
            return {
                ...state,
                addedCoverBoolean: action.value,
            };
        case SET_TABLE_VALUE:
            return {
                ...state,
                comparePlans: action.value,
            };
        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case TOKEN_IS_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.value,
            };

        // API Calls
        case API_PENDING_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        // Submit user data
        case COLLECT_HEALTH_INSURANCE_QUOTE_FORM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case SUBMIT_HEALTH_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteReferenceData: action.value,
            };
        case HEALTH_INSURANCE_QUOTE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };
        case SUBMIT_HEALTH_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteReferenceData: action.error,
            };

        case CALCULATE_HEALTH_INSURANCE_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumCalculation:
                    action.value === null
                        ? null
                        : {
                              ...state.premiumCalculation,
                              value: action.value,
                              status: API_CALL_STATUS.SUCCESS,
                          },
            };

        case SET_TABLE_COMPARE_VALUE:
            return {
                ...state,
                comparePlansTable: action.value,
            };

        // Reset state
        case REINITIALIZE:
            return {
                ...state,
                form: initQuote.form,
                premiumCalculation: null,
                addedCover: [],
            };
        default:
            return state;
    }
};
