import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import {config} from '../config/index.js';

export function validatePostalAddress(
    value,
    {
        min = 1,
        max = 254,
        subject,
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    return validateAddress(
        value,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase
        },
        true,
    );
}

export function validateStreetAddress(
    value,
    {
        min = 1,
        max = 254,
        subject,
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {},
) {
    return validateAddress(
        value,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase,
        },
        false,
    );
}

export function validateStreetWithNumber(
    value,
    {
        min = 1,
        max = 254,
        subject,
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {},
) {
    return validateAddress(
        value,
        {
            min,
            max,
            subject,
            required,
            pronoun,
            retainSubjectCase,
        },
        false,
        true,
    );
}

function validateAddress(
    value,
    {
        min = 1,
        max = 254,
        subject,
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {},
    isPostal,
    includesNumber,
) {

    // Checks for word chars, spaces, and allowed special characters.
    const regex = new RegExp(/^([\w\s.,'\-\\/!]*)$/);

    const invalidMsg = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);

    if (!value && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    // Regex to remove . , ; : [ ]
    // Then remove spaces, and make all lower case
    const condensedValue = value.replace(/[.,;://[\]']+/g, '').replace(/\s/g, '').toLowerCase();
    if (!isPostal && (condensedValue.includes('pobox') ||
        condensedValue.includes('posbus') ||
        condensedValue.includes('privatebag'))) {
        return OmValidationMessageHelper.getInvalidStreetAddressMessage();
    }

    if (!value.replace(/\s/g, '').length) {
        return invalidMsg;
    }

    // Tests that value is not just numbers
    if (RegExp(/^[\d\s]+$/).test(value)) {
        return invalidMsg;
    }

    if (!regex.test(value)) {
        return invalidMsg;
    }

    if (hasConsecutiveSpaces(value)) {
        return invalidMsg;
    }

    if (min && max && (value.trim().length < min )) {
        return OmValidationMessageHelper.getStringTooShortMsg(subject, min, pronoun, retainSubjectCase);
    }

    if (min && max && (value.trim().length > max )) {
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    if (includesNumber && !RegExp(/^(?=.*\d)/).test(value)) {
        return invalidMsg;
    }

    return null;
}

function hasConsecutiveSpaces(value) {
    const spacingLimitRegex = new RegExp(/\s{2,}/);
    if (spacingLimitRegex.test(value)) {
        return true;
    }

    return false;
}
