import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/personalAccident/types';
import { getPersonalAccidentState } from './index';

const getRouting = createSelector(getPersonalAccidentState, (state) => state.routing);
export const getActivePageIndex = createSelector(getRouting, (routing) => routing.activePageIndex);
export const getDoPersonalAccidentNavigation = createSelector(getRouting, (routing) => routing.doNavigation);
export const getDoExitPersonalAccidentNavigation = createSelector(getRouting, (routing) => routing.doExitNavigation);

export const getLandingPage = createSelector(getRouting, (routing) => routing.landingPage);

export const getIsDropoffPage = createSelector(
    getRouting,
    (routing) => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU,
);

const makeGetIsPage = (pageIndex) => createSelector(getRouting, (routing) => routing.activePageIndex === pageIndex);
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QUOTE);
export const getIsLivesCoveredPage = makeGetIsPage(PAGE_INDICES.LIVES_COVERED);
export const getIsDocumentsPage = makeGetIsPage(PAGE_INDICES.UPLOAD_DOCUMENTS);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getProgressBarIndex = createSelector(
    getIsQuotePage,
    getIsLivesCoveredPage,
    getIsDocumentsPage,
    getIsPaymentPage,
    (isQuotePage, isLivesCoveredPage, isDocumentsPage, isPaymentPage) => {
        if (isQuotePage) return 0;
        if (isLivesCoveredPage) return 1;
        if (isDocumentsPage) return 2;
        if (isPaymentPage) return 3;
    },
);
