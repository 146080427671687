import { transitionPersonalAccidentSpouseDetailsForm } from '../../../actions/personalAccident/spouseDetails';
import { getSpouseDetailsForm } from '../../../selectors/personalAccident/spouseDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const spouseDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const spouseDetailsForm = getSpouseDetailsForm(store.getState());
    const spouseDetailsFormValid = validateSpouseDetailsFormDetails(spouseDetailsForm);
    const formAction = spouseDetailsFormValid
        ? transitionPersonalAccidentSpouseDetailsForm.formValid()
        : transitionPersonalAccidentSpouseDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateSpouseDetailsFormDetails = (formFields) => {
    return (
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.tinNumber &&
        formFields.tinNumber.status === FIELD_STATUS.VALID &&
        formFields.streetAddress &&
        formFields.streetAddress.status === FIELD_STATUS.VALID &&
        formFields.suburb &&
        formFields.suburb.status === FIELD_STATUS.VALID &&
        formFields.city &&
        formFields.city.status === FIELD_STATUS.VALID
    );
};
