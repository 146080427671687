import {
    validateAge, validateSingleSelection
} from "@om/validation";
import {
    AGE_SET_VALUE,
    ADD_CHILD_SET_VALUE,
    ADD_PARTNER_SET_VALUE,
    COVER_AMOUNT_SET_VALUE,
    COVER_MEMBER_SET_VALUE,
    COVER_CATEGORY_SET_VALUE,
    setAddPartnerValid,
    setAddChildValid,
    setAgeValid,
    setCoverAmountValid,
    setCoverMemberValid,
    setCoverCategoryValid,
} from "../../../actions/easiplusFuneralPlan/quote";
import { getEditCoverCategory } from "../../../selectors/easiplusFuneralPlan/quote";
import { EFP_COVER_CATEGORIES, EFP_MEMBER_AGE_RANGE } from "../../../actions/easiplusFuneralPlan/types";

export const efpQuoteValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AGE_SET_VALUE:
                const coverCategory = getEditCoverCategory(store.getState());
                const ageRange = (() => {
                    switch (coverCategory) {
                        case EFP_COVER_CATEGORIES.EXTENDED_FAMILY: return EFP_MEMBER_AGE_RANGE.EXTENDED_FAMILY;
                        case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS: return EFP_MEMBER_AGE_RANGE.PARENTS_AND_IN_LAWS;
                        default: return EFP_MEMBER_AGE_RANGE.DEFAULT;
                    }
                })();

                error = validateAge(action.value, ageRange);
                return setAgeValid(error);
            case ADD_PARTNER_SET_VALUE:
                // This is a checkbox and is valid if fired
                return setAddPartnerValid(null);
            case ADD_CHILD_SET_VALUE:
                // This is a checkbox and is valid if fired
                return setAddChildValid(null);
            case COVER_AMOUNT_SET_VALUE:
                // input from slider and is valid if fired
                return setCoverAmountValid(null);
            case COVER_MEMBER_SET_VALUE:
                error = validateSingleSelection(action.value, 'cover member');
                return setCoverMemberValid(error);
            case COVER_CATEGORY_SET_VALUE:
                error = validateSingleSelection(action.value, 'cover category');
                return setCoverCategoryValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
}