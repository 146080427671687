
const funeralBasicPlanTypes = [ "MeAndMyImmediateFamily", "MyParentsOrInlaws", "MyExtendedFamily" ];

// matches up in the ref data
// MeAndMyImmediateFamily contains product codes Individual, Family and SingleFamily
// MyParentsOrInlaws contains product code Parents
// MyExtendedFamily contains product code Extended
const funeralBasicProductCodes = [ 
    {
        type: "Individual",
        indexValue: 0,
    },
    {
        type: "Family",
        indexValue: 1,
    },
    {
        type: "SingleFamily",
        indexValue: 2,
    },
    {
        type: "Parents",
        indexValue: 0,
    },
    {
        type: "Extended",
        indexValue: 0,
    },
];

/* EFP plan types and product mapping
PLAN: 
- MeAndMyImmediateFamily
AVAILABLE PRODUCTS: 
- Individual 
- Family
- SingleFamily
PLAN: 
- MyParentsOrInlaws
AVAILABLE PRODUCTS: 
- Parents
PLAN: 
- MyExtendedFamily
AVAILABLE PRODUCTS: 
- Extended
*/
const getPlanTypeFromProductCode = (productCode) => {
    switch (productCode.type) {
        case funeralBasicProductCodes[0].type: 
            return funeralBasicPlanTypes[0];
        case funeralBasicProductCodes[1].type: 
            return funeralBasicPlanTypes[0];
        case funeralBasicProductCodes[2].type: 
            return funeralBasicPlanTypes[0];
        case funeralBasicProductCodes[3].type: 
            return funeralBasicPlanTypes[1];
        case funeralBasicProductCodes[4].type:
            return funeralBasicPlanTypes[2];
        default: return null;
    }
}

export {
    funeralBasicPlanTypes,
    funeralBasicProductCodes,
    getPlanTypeFromProductCode,
}