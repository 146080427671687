import {
    validateAlphaString,
    validateName,
    validateIdNumber,
    validateIdNumberAgainstAge,
    validateAlphaNumericString,
    validateTaxNumber,
    validatePrivateBag,
    validateStreetAddress,
    validateStreetWithNumber,
    validateEmail,
    validatePostalCode,
    validatePoBoxNumber,
    validateSingleSelection
} from  "@om/validation";

import {
    setTitleValid,
    setMiddleNameValid,
    setEmailValid,

    setIdValid,
    setCountryOfBirthValid,
    setNationalityValid,
    setMaritalStatusValid,
    setIncomeTaxValid,

    setComplexValid,
    setStreetAddressValid,
    setSuburbValid,
    setCityValid,
    setPostalCodeValid,
    setCountryValid,
    setPostalAddressMethodValid,
    setPostalAddressSameValid,

    setPostalComplexValid,
    setPostalStreetAddressValid,
    setPostalSuburbValid,
    setPostalCityValid,
    setPostalPostalCodeValid,
    setPostalCountryValid,

    setPoBoxValid,
    setPoPostalCodeValid,
    setPoPostOfficeValid,
    setPoCountryValid,

    setPrivateBagValid,
    setPrivatePostalCodeValid,
    setPrivatePostOfficeValid,
    setPrivateCountryValid,

    TITLE_SET_VALUE,
    MIDDLE_NAME_SET_VALUE,
    EMAIL_SET_VALUE,

    ID_SET_VALUE,
    COUNTRY_OF_BIRTH_SET_VALUE,
    NATIONALITY_SET_VALUE,
    INCOME_TAX_SET_VALUE,
    MARITAL_STATUS_SET_VALUE,

    COMPLEX_SET_VALUE,
    STREET_ADDRESS_SET_VALUE,
    SUBURB_SET_VALUE,
    CITY_SET_VALUE,
    POSTAL_CODE_SET_VALUE,
    COUNTRY_SET_VALUE,
    POSTAL_ADDRESS_METHOD_VALUE,
    POSTAL_ADDRESS_SAME_VALUE,

    POSTAL_STREET_ADDRESS_SET_VALUE,
    POSTAL_SUBURB_SET_VALUE,
    POSTAL_CITY_SET_VALUE,
    POSTAL_POSTAL_CODE_SET_VALUE,
    POSTAL_COUNTRY_SET_VALUE,

    PO_BOX_SET_VALUE,
    PO_POSTAL_CODE_SET_VALUE,
    PO_POST_OFFICE_SET_VALUE,
    PO_COUNTRY_SET_VALUE,

    PRIVATE_BAG_SET_VALUE,
    PRIVATE_POSTAL_CODE_SET_VALUE,
    PRIVATE_POST_OFFICE_SET_VALUE,
    PRIVATE_COUNTRY_SET_VALUE,
    POSTAL_COMPLEX_SET_VALUE,

} from "../../../actions/raOptimal/personalDetails";

import { validationIsEqual } from "../status";

import { getAge } from "../../../selectors/raOptimal/investmentSetup";

let prevValidationAction = null;
export const personalDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, 'Title');
                return setTitleValid(error);

            case MIDDLE_NAME_SET_VALUE:
                error = validateName(action.value, {subject: 'Middle name(s)', required: false});
                return setMiddleNameValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value);
                return setEmailValid(error);

            case MARITAL_STATUS_SET_VALUE:
                error = validateSingleSelection(action.value, 'Marital status');
                return setMaritalStatusValid(error);

            case COUNTRY_OF_BIRTH_SET_VALUE:
                error = validateSingleSelection(action.value, 'Country of birth');
                return setCountryOfBirthValid(error);

            case NATIONALITY_SET_VALUE:
                error = validateSingleSelection(action.value, 'Nationality');
                return setNationalityValid(error);

            case ID_SET_VALUE:
                error = validateIdNumber(action.value);
                if (error) return setIdValid(error);

                const age = getAge(store.getState());
                error = validateIdNumberAgainstAge(action.value, age.value);
                return setIdValid(error);

            case INCOME_TAX_SET_VALUE:
                error = validateTaxNumber(action.value, {required: false});
                return setIncomeTaxValid(error);

            // Address
            case COMPLEX_SET_VALUE:
                error = validateStreetAddress(action.value, {min: 2, max: 30, required: false, subject: 'Complex or building'});
                return setComplexValid(error);

            case STREET_ADDRESS_SET_VALUE:
                error = validateStreetWithNumber(action.value, { min: 2, max: 30, subject: 'Street address' });
                return setStreetAddressValid(error);

            case SUBURB_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Suburb', required: false});
                return setSuburbValid(error);

            case CITY_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'City'});
                return setCityValid(error);

            case POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(action.value);
                return setPostalCodeValid(error);

            case COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'Country');
                return setCountryValid(error);

            case POSTAL_ADDRESS_SAME_VALUE:
                const value = action.value === true || action.value === false;
                error = validateSingleSelection(value, 'Option');
                return setPostalAddressSameValid(error);

            case POSTAL_ADDRESS_METHOD_VALUE:
                error = validateSingleSelection(action.value, 'Postal address method');
                return setPostalAddressMethodValid(error);

            // Postal method - Private Bag
            case PRIVATE_BAG_SET_VALUE:
                error = validatePrivateBag(action.value);
                return setPrivateBagValid(error);

            case PRIVATE_POST_OFFICE_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Post office'});
                return setPrivatePostOfficeValid(error);

            case PRIVATE_POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(action.value);
                return setPrivatePostalCodeValid(error);

            case PRIVATE_COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'Country');
                return setPrivateCountryValid(error);

            // Postal method - PO Box
            case PO_BOX_SET_VALUE:
                error = validatePoBoxNumber(action.value);
                return setPoBoxValid(error);

            case PO_POST_OFFICE_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Post office'});
                return setPoPostOfficeValid(error);

            case PO_POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(action.value);
                return setPoPostalCodeValid(error);

            case PO_COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'Country');
                return setPoCountryValid(error);

            // Postal method - Street Address
            case POSTAL_COMPLEX_SET_VALUE:
                error = validateStreetAddress(action.value, {min: 2, max: 30, required: false, subject: 'Complex or building'});
                return setPostalComplexValid(error);

            case POSTAL_STREET_ADDRESS_SET_VALUE:
                error = validateStreetWithNumber(action.value, { min: 2, max: 30, subject: 'Street name' });
                return setPostalStreetAddressValid(error);

            case POSTAL_SUBURB_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'Suburb', required: false});
                return setPostalSuburbValid(error);

            case POSTAL_CITY_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 30, subject: 'City'});
                return setPostalCityValid(error);

            case POSTAL_POSTAL_CODE_SET_VALUE:
                error = validatePostalCode(action.value);
                return setPostalPostalCodeValid(error);

            case POSTAL_COUNTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'Country');
                return setPostalCountryValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};