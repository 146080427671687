export const UNCLAIMED_BENEFITS_SET_VALUE = 'publicWeb/unclaimedBenefits/setValue/';
export const UNCLAIMED_BENEFITS_SET_VALID = 'publicWeb/unclaimedBenefits/setValid/';

export const FORM_VALID_UNCLAIMED_BENEFITS = 'publicWeb/unclaimedBenefits/formValid';
export const FORM_INVALID_UNCLAIMED_BENEFITS = 'publicWeb/unclaimedBenefits/formInvalid';
export const REINITIALIZE = 'publicWeb/unclaimedBenefits/reinitialize';

export const transitionUnclaimedBenefits = {
    formValid: () => ({ type: FORM_VALID_UNCLAIMED_BENEFITS }),
    formInvalid: () => ({ type: FORM_INVALID_UNCLAIMED_BENEFITS }),
};

export const INITIALS_SET_VALUE = UNCLAIMED_BENEFITS_SET_VALUE + 'initials';
export const INITIALS_SET_VALID = UNCLAIMED_BENEFITS_SET_VALID + 'initials';
export const SURNAME_SET_VALUE = UNCLAIMED_BENEFITS_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = UNCLAIMED_BENEFITS_SET_VALID + 'surname';
export const DOB_SET_VALUE = UNCLAIMED_BENEFITS_SET_VALUE + 'dateOfBirth';
export const DOB_SET_VALID = UNCLAIMED_BENEFITS_SET_VALID + 'dateOfBirth';
export const COLLECT_USER_DATA = UNCLAIMED_BENEFITS_SET_VALUE + 'collectUserData';
export const USER_FOUND_SET_VALUE = UNCLAIMED_BENEFITS_SET_VALUE + 'userFound';

export const setInitialsValue = value => ({ type: INITIALS_SET_VALUE, value });
export const setInitialsValid = error => ({ type: INITIALS_SET_VALID, error });
export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });
export const setDOBValue = value => ({ type: DOB_SET_VALUE, value });
export const setDOBValid = error => ({ type: DOB_SET_VALID, error });
export const collectUserData = () => ({ type: COLLECT_USER_DATA });
export const setUserFound = value => ({ type: USER_FOUND_SET_VALUE, value });
export const setToInitial = () => ({ type: REINITIALIZE });
