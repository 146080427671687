export const DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM = 'digitalSavings/personalDetailsForm/';

export const DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM + 'setValue';
export const DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM + 'setValid';

export const FORM_VALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM = 'digitalSavings/personalDetailsForm/transition/formValid';
export const FORM_INVALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM = 'digitalSavings/personalDetailsForm/transition/formInValid';

export const REINITIALIZE = 'digitalSavings/personalDetailsForm/state/reinitialize';

export const transitionDigitalSavingsPersonalDetails = {
    formValid: () => ({
        type: FORM_VALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM,
    }),
};

export const FIRST_NAME_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "firstName";
export const FIRST_NAME_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "firstName";

export const SURNAME_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "surname";
export const SURNAME_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "surname";

export const DOB_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "dob";
export const DOB_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "dob";

export const EMAIL_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "email";
export const EMAIL_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "email";

export const MOBILE_NUMBER_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "mobileNumber";
export const MOBILE_NUMBER_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "mobileNumber";

export const ID_PASSPORT_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "idPassport";
export const ID_PASSPORT_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "idPassport";

export const KRA_PIN_NUMBER_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "kraPinNumber";
export const KRA_PIN_NUMBER_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "kraPinNumber";

export const GENDER_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "gender";
export const GENDER_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "gender";

export const FULL_ADDRESS_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "fullAddress";
export const FULL_ADDRESS_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "fullAddress";

export const NATIONALITY_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "nationality";
export const NATIONALITY_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "nationality";

export const OCCUPATION_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "occupation";
export const OCCUPATION_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "occupation";

export const SOURCE_OF_FUNDS_SET_VALUE = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VALUE + "sourceOfFunds";
export const SOURCE_OF_FUNDS_SET_VALID = DIGITAL_SAVINGS_PERSONAL_DETAILS_FORM_SET_VAVID + "sourceOfFunds";

// Action Creators

export const setFirstNameValue = value => ({
    type: FIRST_NAME_SET_VALUE,
    value
})

export const setFirstNameValid = error => ({
    type: FIRST_NAME_SET_VALID,
    error
})


export const setSurnameValue = value => ({
    type: SURNAME_SET_VALUE,
    value
})

export const setSurnameValid = error => ({
    type: SURNAME_SET_VALID,
    error
})


export const setDobValue = value => ({
    type: DOB_SET_VALUE,
    value
})

export const setDobValid = error => ({
    type: DOB_SET_VALID,
    error
})

export const setEmailValue = value => ({
    type: EMAIL_SET_VALUE,
    value
})

export const setEmailValid = error => ({
    type: EMAIL_SET_VALID,
    error
})

export const setMobileNumberValue = value => ({
    type: MOBILE_NUMBER_SET_VALUE,
    value
})

export const setMobileNumberValid = error => ({
    type: MOBILE_NUMBER_SET_VALID,
    error
})

export const setIdPassportValue = value => ({
    type: ID_PASSPORT_SET_VALUE,
    value
})

export const setIdPassportValid = error => ({
    type: ID_PASSPORT_SET_VALID,
    error
})

export const setKraPinNumberValue = value => ({
    type: KRA_PIN_NUMBER_SET_VALUE,
    value
})

export const setKraPinNumberValid = error => ({
    type: KRA_PIN_NUMBER_SET_VALID,
    error
})

export const setGenderValue = value => ({
    type: GENDER_SET_VALUE,
    value
})

export const setGenderValid = error => ({
    type: GENDER_SET_VALID,
    error
})

export const setFullAddressValue = value => ({
    type: FULL_ADDRESS_SET_VALUE,
    value
})

export const setFullAddressValid = error => ({
    type: FULL_ADDRESS_SET_VALID,
    error
})

export const setNationalityValue = value => ({
    type: NATIONALITY_SET_VALUE,
    value,
})

export const setNationalityValid = error => ({
    type: NATIONALITY_SET_VALID,
    error,
})

export const setOccupationValue = value => ({
    type: OCCUPATION_SET_VALUE,
    value,
})

export const setOccupationValid = error => ({
    type: OCCUPATION_SET_VALID,
    error
})

export const setSourceOfFundsValue = value => ({
    type: SOURCE_OF_FUNDS_SET_VALUE,
    value,
});

export const setSourceOfFundsValid = error => ({
    type: SOURCE_OF_FUNDS_SET_VALID,
    error
})

