import {
    transitionEmergencyCalculator,
} from "../../../actions/investmentCalculator/emergencyFund"

import {
    getEmergencyFundCalculatorForm,
} from "../../../selectors/investmentCalculator/emergencyFundCalculator"
import {FIELD_STATUS} from "../../../reducers/status";

export const emergencyFundCalculateFormRules = (store, next, action) => {
    const result = next(action);
    const emergencyFundCalculatorForm = getEmergencyFundCalculatorForm(store.getState());
    const emergencyFundCalculatorValid = validateFormDetails(emergencyFundCalculatorForm);
    const formAction = emergencyFundCalculatorValid
        ? transitionEmergencyCalculator.formValid()
        : transitionEmergencyCalculator.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFields?.amountOnceOff.status === FIELD_STATUS.VALID &&
    formFields?.amountMonthly.status === FIELD_STATUS.VALID
);
