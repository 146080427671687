import { SERVICE_PROVIDER_SET_VALUE } from "../../../actions/customerVerificationService/consent";
import { consentTracking } from "./consent";

export const cvsTracking = (store, next, action) => {

    if (action.type.startsWith(SERVICE_PROVIDER_SET_VALUE))
    return consentTracking(store, next, action);

    return null;
};
