import { createSelector } from 'reselect';
import { getInvestmentCalculators } from './index';

export const getEmergencyFundCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculator) => investmentCalculator.emergencyFundCalculator
);

export const getGenerateRegularIncomeCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculator) => investmentCalculator.generateRegularIncomeCalculator
);

export const getShortTermGoalCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculator) => investmentCalculator.shortTermGoalCalculator
);

export const getLongTermGoalCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculator) => investmentCalculator.longTermGoalCalculator
);

export const getInvestmentCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculators) => investmentCalculators.investmentCalculator
);

export const getFormReferences = createSelector(
    getInvestmentCalculators,
    ({ investmentCalculator }) => investmentCalculator.formReferences
);

export const getSelectedForm = createSelector(
    getInvestmentCalculators,
    ({ investmentCalculator }) => investmentCalculator.selectedCalculator
);

export const getInvestmentResult = createSelector(
    getInvestmentCalculators,
    ({ investmentCalculator }) => investmentCalculator.result
);

export const getDropsReference = createSelector(
    getInvestmentCalculators,
    ({ investmentCalculator }) => investmentCalculator.dropsReference
);

export const getSelectedDrop = createSelector(
    getInvestmentCalculators,
    ({ investmentCalculator }) => investmentCalculator.selectedDrop
);

export const getGoalsReference = createSelector(
    getInvestmentCalculators,
    ({ investmentCalculator }) => investmentCalculator.durationsReference
);

export const getSelectedDuration = createSelector(
    getInvestmentCalculators,
    ({ investmentCalculator }) => investmentCalculator.selectedDuration
);

export const getSelectedGoal = createSelector(
    [getFormReferences, getSelectedForm],
    (calculators, selectedCalculator) => calculators.find(calc => calc.name === selectedCalculator)?.goal
)

export const getShowBuildWealthSplitForm = createSelector(
    getInvestmentCalculator,
    (investmentCalculator) =>
        investmentCalculator.selectedDuration >= 12 &&
        Number(investmentCalculator.selectedDrop) === 3
);

export const getShowBuildWealthDropField = createSelector(
    getInvestmentCalculator,
    (investmentCalculator) =>
        Number(investmentCalculator.selectedDuration) >= 12
);

export const getShowShortTermGoalSplitForm = createSelector(
    getShortTermGoalCalculator,
    (investmentCalculator) =>
        Number(investmentCalculator.form.investmentTenure.value) > 11 &&
        Number(investmentCalculator.form.potentialLossPercentage.value) === 0.1
);

export const getShowShortTermGoalDropField = createSelector(
    getShortTermGoalCalculator,
    (investmentCalculator) =>
        Number(investmentCalculator.form.investmentTenure.value) > 11
);

export const getShowLongTermGoalSplitForm = createSelector(
    getLongTermGoalCalculator,
    (investmentCalculator) =>
        Number(investmentCalculator.form.investmentTenure.value) > 11 &&
        Number(investmentCalculator.form.potentialLossPercentage.value) === 0.1
);

export const getShowLongTermGoalDropField = createSelector(
    getLongTermGoalCalculator,
    (investmentCalculator) =>
        Number(investmentCalculator.form.investmentTenure.value) > 11
);

export const getShowRecommendation = createSelector(
    getInvestmentCalculator,
    ({ showRecommendation }) => showRecommendation 
);
