import { validatePercent } from '@om/validation';
import {
    ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE,
    EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALUE,
    EXPECTED_GROWTH_OF_SAVINGS_SET_VALUE,
    setAnnualIncreaseInContributionsValid,
    setContributionsAndEducationCostValid,
    setExpectedAnnualIncreaseInEducationCostValid,
    setExpectedGrowthOfSavingsValid,
} from '../../../actions/educationCalculator/calculator';
import {
    getAnnualIncreaseInContributions,
    getExpectedAnnualIncreaseInEducationCost,
    getExpectedGrowthOfSavings,
} from '../../../selectors/educationCalculator/educationCalculator';
import { validationIsEqual } from '../status';

function validateAgainstGrowthOfSavings(growthOfSavings, value, subject) {
    if (growthOfSavings && value && growthOfSavings == value) {
        return `${subject} and growth of savings rate can not be equal.`;
    } else {
        return null;
    }
}

let prevValidationAction = null;
export const educationCalculatorModalValidation = (store, next, action) => {
    const state = store.getState();

    const validationAction = (() => {
        let growthOfSavings;
        let subject;
        let error;
        switch (action.type) {
            case ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE:
                subject = 'Annual increase in contributions rate';
                growthOfSavings = getExpectedGrowthOfSavings(state);
                error = validatePercent(action.value, {
                    required: true,
                    subject: subject,
                });

                if (!error) {
                    error = validateAgainstGrowthOfSavings(
                        growthOfSavings.value,
                        action.value,
                        subject
                    );
                }

                return setAnnualIncreaseInContributionsValid(error);
            case EXPECTED_GROWTH_OF_SAVINGS_SET_VALUE:
                subject = 'Annual growth of savings rate';
                error = validatePercent(action.value, {
                    required: true,
                    subject: subject,
                });

                if (!error) {
                    const contributionsRate = getAnnualIncreaseInContributions(
                        state
                    );
                    const educationEscalationRate = getExpectedAnnualIncreaseInEducationCost(
                        state
                    );

                    // Re-validate contributions value against growth of savings
                    subject = 'Annual increase in contributions rate';
                    let contributionsRateError = validatePercent(contributionsRate.value, {
                        required: true,
                        subject: subject,
                    });
                    if (!contributionsRateError) {
                        contributionsRateError = validateAgainstGrowthOfSavings(
                            action.value,
                            contributionsRate.value,
                            subject
                        );
                    }

                    // Re-validate education escalation against against growth of savings
                    subject = 'Annual escalation of education cost';
                    let educationEscalationRateError = validatePercent(educationEscalationRate.value, {
                        required: true,
                        subject: subject,
                    });
                    if (!educationEscalationRateError) {
                        educationEscalationRateError = validateAgainstGrowthOfSavings(
                            action.value,
                            educationEscalationRate.value,
                            subject
                        );
                    } 

                    store.dispatch(
                        setContributionsAndEducationCostValid(
                            contributionsRateError,
                            educationEscalationRateError
                        )
                    );
                }
                return setExpectedGrowthOfSavingsValid(error);
            case EXPECTED_ANNUAL_INCREASE_IN_EDUCATION_COST_SET_VALUE:
                subject = 'Annual escalation of education cost';
                growthOfSavings = getExpectedGrowthOfSavings(state);
                error = validatePercent(action.value, {
                    required: true,
                    subject: subject,
                });

                if (!error) {
                    error = validateAgainstGrowthOfSavings(
                        growthOfSavings.value,
                        action.value,
                        subject
                    );
                }

                return setExpectedAnnualIncreaseInEducationCostValid(error);
            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
