import {combineReducers} from "redux";
import callMeBack from './callMeBack';
import scrolling from './scrolling';
import routing from './routing';
import referenceData from './referenceData';
import replacementPolicy from './replacementPolicy';
import paymentDetails from './paymentDetails';
import personalDetails from "./personalDetails";
import beneficiaries from './beneficiaries'
import beneficiariesSplit from "./beneficiariesSplit";
import confirmDetails from "./confirmDetails";
import livesCovered from "./livesCovered";
import quote from "./quote";
import sessionCamVariables from './sessionCamVariables';
import thankYou from "./thankYou";
import persistedAt from './persistedAt'

export const easiplusFuneralPlan = combineReducers({
    callMeBack,
    replacementPolicy,
    personalDetails,
    referenceData,
    paymentDetails,
    confirmDetails,
    scrolling,
    routing,
    beneficiaries,
    livesCovered,
    beneficiariesSplit,
    quote,
    sessionCamVariables,
    thankYou,
    persistedAt
});
