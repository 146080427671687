import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
import {
    ADULTS_SET_VALUE,
    ADULTS_SET_VALID,
    KIDS_SET_VALUE,
    KIDS_SET_VALID,
    SENIORS_SET_VALUE,
    SENIORS_SET_VALID,
    FROM_SET_VALUE,
    FROM_SET_VALID,
    TO_SET_VALUE,
    TO_SET_VALID,
    DEPARTURE_DATE_SET_VALUE,
    DEPARTURE_DATE_SET_VALID,
    RETURN_DATE_SET_VALUE,
    RETURN_DATE_SET_VALID,
    REASON_FOR_TRAVEL_SET_VALUE,
    REASON_FOR_TRAVEL_SET_VALID,
    FORM_VALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM,
    FORM_INVALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM,
    FORM_VALID_TRAVEL_INSURANCE_DESTINATION_FORM,
    FORM_INVALID_TRAVEL_INSURANCE_DESTINATION_FORM,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    API_PENDING_TRANSITION_TRAVEL_DETAILS_FORM,
    API_SUCCESS_TRANSITION_TRAVEL_DETAILS_FORM,
    API_FAILURE_TRANSITION_TRAVEL_DETAILS_FORM,
    COLLECT_TRAVEL_DETAILS_DATA,
    SUBMIT_TRAVEL_DETAILS_SUCCESS,
    SUBMIT_TRAVEL_DETAILS_FAILURE,
} from '../../actions/roaTravelInsurance/travelDetails';

export const initialState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    plansReferenceData: null,
    form: {
        adults: { value: null, error: null, status: null },
        kids: { value: 0, error: null, status: null },
        seniors: { value: 0, error: null, status: null },
        departureDate: { value: null, error: null, status: null },
        returnDate: { value: null, error: null, status: null },
        reasonForTravel: { value: null, error: null, status: null },
        from: { value: null, error: null, status: null },
        to: { value: null, error: null, status: null },
    },
};

export default (state = initialState, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case WHICH_FORM_TO_DISPLAY:
            return { ...state, selectedForm: action.value };
        // Form one
        case ADULTS_SET_VALUE:
            return updateFormField('adults', field => ({
                ...field,
                value: action.value,
            }));

        case ADULTS_SET_VALID:
            return updateFormField('adults', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case KIDS_SET_VALUE:
            return updateFormField('kids', field => ({
                ...field,
                value: action.value,
            }));

        case KIDS_SET_VALID:
            return updateFormField('kids', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SENIORS_SET_VALUE:
            return updateFormField('seniors', field => ({
                ...field,
                value: action.value,
            }));

        case SENIORS_SET_VALID:
            return updateFormField('seniors', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        //form two

        case FROM_SET_VALUE:
            return updateFormField('from', field => ({
                ...field,
                value: action.value,
            }));

        case FROM_SET_VALID:
            return updateFormField('from', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TO_SET_VALUE:
            return updateFormField('to', field => ({
                ...field,
                value: action.value,
            }));

        case TO_SET_VALID:
            return updateFormField('to', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DEPARTURE_DATE_SET_VALUE:
            return updateFormField('departureDate', field => ({
                ...field,
                value: action.value,
            }));

        case DEPARTURE_DATE_SET_VALID:
            return updateFormField('departureDate', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RETURN_DATE_SET_VALUE:
            return updateFormField('returnDate', field => ({
                ...field,
                value: action.value,
            }));

        case RETURN_DATE_SET_VALID:
            return updateFormField('returnDate', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case REASON_FOR_TRAVEL_SET_VALUE:
            return updateFormField('reasonForTravel', field => ({
                ...field,
                value: action.value,
            }));

        case REASON_FOR_TRAVEL_SET_VALID:
            return updateFormField('reasonForTravel', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        //    API Calls
        case API_PENDING_TRANSITION_TRAVEL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_TRAVEL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_TRAVEL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_TRAVEL_DETAILS_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case SUBMIT_TRAVEL_DETAILS_SUCCESS:
            return { ...state, plansReferenceData: action.value };
        case SUBMIT_TRAVEL_DETAILS_FAILURE:
            return { ...state, plansReferenceData: action.error };

        // Reset the state
        case REINITIALIZE:
            return initialState;
        default:
            return state;
    }
};
