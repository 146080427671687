import { getIsApiPending, getAccessToken, getPaymentsForm, getStkData,  getStatusApiCallStatus } from '../../../selectors/biasharaFlexi/payment';
import { getBiasharaFlexiCoverDetailsReferenceData } from '../../../selectors/biasharaFlexi/coverDetails';
import { setExpiredAuth } from '../../../actions/biasharaFlexi/quote';
import {
    apiTransitionStkPush,
    apiTransitionPaymentsStatus,
    triggerPaymentsStkPushSuccess,
    triggerPaymentsStkPushFailure,
    checkPaymentsStatusSuccess,
    checkPaymentsStatus,
    checkPaymentsStatusFailure
} from '../../../actions/biasharaFlexi/payment';
import axios from 'axios';
import { getIsApiPaymentStatusPending } from '../../../selectors/roaCarInsurance/payments'; 


const clearIntervalId = (intervalId) => {
    clearInterval(intervalId);
}

let intervalId = null;
export const paymentsStkService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    (async () => {

        if (!getIsApiPending(state)) {
            store.dispatch(apiTransitionStkPush.pending());
            try {
                const resp = await triggerStkPush(state);
                store.dispatch(triggerPaymentsStkPushSuccess(resp.data));
                store.dispatch(apiTransitionStkPush.success());
                intervalId = setInterval(
                    
                    () => {
                        store.dispatch(checkPaymentsStatus());
                        if (getStatusApiCallStatus(state) === 'success') {
                            clearIntervalId(intervalId);
                        }
                    },5000);
                setTimeout(() => {
                    if (intervalId) {
                        clearIntervalId(intervalId);
                        store.dispatch(apiTransitionPaymentsStatus.failure());
                        return;
                    }
                }, 60000);
            } catch (e) {
                store.dispatch(apiTransitionStkPush.failure());
                //store.dispatch(triggerPaymentsStkPushFailure(e.response));
                clearIntervalId(intervalId);
                return e;  
            }
        }
    })();
    return result;
};

function getCurrentTimestamp() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const timestamp = `${day}${month}${year}${hours}${minutes}${seconds}`;
    return timestamp;
}

function formatPhoneNumber(phoneNumber, countryCode) {
    const cleanedNumber = phoneNumber.substring(1);
    const formattedNumber = `${countryCode}${cleanedNumber}`;

    return formattedNumber;
}

const stkPushPayload = (state) => {
    const paymentsData = getPaymentsForm(state);
    const premium = getBiasharaFlexiCoverDetailsReferenceData(state);
    const requestId = getCurrentTimestamp();
    const msisdn = formatPhoneNumber(paymentsData.phoneNumber.value, '254');
    const payload = {
        msisdn: msisdn,
        amount: premium.annual_premium.toString(),
        description: 'KE Motor Insurance',
        accountReference: requestId,
        type: 'BIASHARAFLEXI',
    };
    return {
        ...payload,
    };
};

const triggerStkPush = async (state) => {

    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-motor-insurance/payments/stk';
        return await axios.post(url, stkPushPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
         if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
             store.dispatch(setExpiredAuth(true));
         }
        store.dispatch(triggerPaymentsStkPushFailure(e?.response?.data || {} ));
        console.log(e)
        throw e;
    }
};


export const paymentsStkStatusService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    (async () => {

         if (getStatusApiCallStatus(state) === 'success') {
             clearIntervalId(intervalId);
             return;
         }
        if (!getIsApiPaymentStatusPending(state)) {
            store.dispatch(apiTransitionPaymentsStatus.pending());
            try {
                const resp = await triggerStkStatusCheck(state);
                if(resp.data && resp.data.success){
                    clearIntervalId(intervalId)
                    intervalId = null;
                    store.dispatch(checkPaymentsStatusSuccess(resp.data));
                    store.dispatch(apiTransitionPaymentsStatus.success());
                    return;
                }
                
            } catch (e) {
                 if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
                     store.dispatch(setExpiredAuth(true));
                 }
                //store.dispatch(apiTransitionPaymentsStatus.failure());
                console.error(e.response);
                //store.dispatch(checkPaymentsStatusFailure(e.response));
                return e;
            }
        }
    })();
    return result;
};

const stkStatusPayload = (state) => {
    const stkData = getStkData(state);
    const payload = {
        checkout_request_id: stkData.CheckoutRequestID || null,
    };
    return {
        ...payload,
    };
};

const triggerStkStatusCheck = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-motor-insurance/payments/stk/status';
        return await axios.post(url, stkStatusPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};

