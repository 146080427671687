import { DO_ACTIONS, STORE_EMPLOYMENT_DETAIL, STORE_AFFORDABILITY_DETAIL, STORE_ADDRESS_DETAIL, SCHEDULE_FILE_FOR_UPLOAD, OTP_SEND_REQUEST, REMOVE_FILE_FROM_UPLOAD } from '../../../actions/digitalOnboarding/types';
import { addressValidation } from './addressValidation';
import { affordabilityValidation } from './affordabilityValidation';
import { employmentValidation } from './employmentValidation';
import { routingValidation } from './routingValidation';
import { documentStoreValidation } from './documentStoreValidation';
import { otpValidation } from './otpValidation';

export const digitalOnboardingValidation = (store, next, action) => {
    if (action.type === STORE_EMPLOYMENT_DETAIL) {
        return employmentValidation(store, next, action);
    } else if (action.type === STORE_AFFORDABILITY_DETAIL) {
        return affordabilityValidation(store, next, action);
    } else if (action.type === STORE_ADDRESS_DETAIL) {
        return addressValidation(store, next, action);
    } else if (action.type === SCHEDULE_FILE_FOR_UPLOAD || action.type === REMOVE_FILE_FROM_UPLOAD) {
        return documentStoreValidation(store, next, action);
    } else if (action.type === OTP_SEND_REQUEST) {
        return otpValidation(store, next, action);
    } else if (action.type.startsWith(DO_ACTIONS)) {
        return routingValidation(store, next, action);
    }
    return next(action);
}