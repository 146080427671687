import { PAST_PERFORMANCE_TRANSITION_CALCULATOR_RESULTS } from '../../../actions/pastPerformanceCalculator/pastPerformanceCalculator';

import { findOtherEventIndex, otherEventTracking } from "../otherEventTracking";

export const pastPerfomanceCalculatorFormTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type) {
        case PAST_PERFORMANCE_TRANSITION_CALCULATOR_RESULTS:
            otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            return 'Past Perfomance Calculator calculation';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};

