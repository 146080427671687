import {
    FUNERAL_CLAIM_SUBMIT_FORM,
    FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER,
    FUNERAL_CLAIM_SET_FIRST_NAME_VALUE,
    FUNERAL_CLAIM_SET_SURNAME_VALUE,
    FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE,
    FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE,
    FUNERAL_CLAIM_SET_EMAIL_VALUE,
    FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE,
    FUNERAL_CLAIM_SET_DECEASED_ID_VALUE,
    FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE,
    FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE,
    FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE,
    FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE,
    FUNERAL_CLAIM_SET_BANK_VALUE,
    FUNERAL_CLAIM_SET_BANK_OTHER_VALUE,
    FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE,
} from '../../../actions/publicWeb/funeralClaimV2';
import {
    getBank,
    getBankAccountNumber,
    getBankAccountType,
    getBankOther,
    getBranchCode,
    getCauseOfDeath,
    getCellphoneNumber,
    getDateOfDeath,
    getDeceasedIdNumber,
    getEmail,
    getFirstName,
    getFuneralClaimsOpenDrawer,
    getFuneralDate,
    getReasonForDeath,
    getReasonForUnnaturalDeath,
    getRequesterIdNumber,
    getSurname,
} from '../../../selectors/publicWeb/funeralClaimV2';
import { simpleFieldTracking } from '../formTracking';
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";
import { findOtherEventIndex, otherEventTracking } from '../otherEventTracking';

export const funeralClaimV2FieldTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendError = true;
    let sendValue = true;

    const fieldSelector = (() => {
        switch (action.type) {
            case FUNERAL_CLAIM_SET_FIRST_NAME_VALUE:
                sendValue = false;
                return getFirstName;
            case FUNERAL_CLAIM_SET_SURNAME_VALUE:
                sendValue = false;
                return getSurname;
            case FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE:
                sendValue = false;
                return getRequesterIdNumber;
            case FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE:
                sendValue = false;
                return getCellphoneNumber;
            case FUNERAL_CLAIM_SET_EMAIL_VALUE:
                sendValue = false;
                return getEmail;
            case FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE:
                return getDateOfDeath;
            case FUNERAL_CLAIM_SET_DECEASED_ID_VALUE:
                sendValue = false;
                return getDeceasedIdNumber;
            case FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE:
                sendError = false;
                return getCauseOfDeath;
            case FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE:
                sendError = false;
                return getReasonForUnnaturalDeath;
            case FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE:
                return getReasonForDeath;
            case FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE:
                return getFuneralDate;
            case FUNERAL_CLAIM_SET_BANK_VALUE:
                sendError = false;
                return getBank;
            case FUNERAL_CLAIM_SET_BANK_OTHER_VALUE:
                return getBankOther;
            case FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE:
                return getBranchCode;
            case FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE:
                sendValue = false;
                return getBankAccountNumber;
            case FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE:
                sendError = false;
                return getBankAccountType;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        simpleFieldTracking({ action, field, sendValue, sendError });
    }

    return result;
};

export const funeralClaimV2TransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const trackingActions = [FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER];

    let openDrawer = getFuneralClaimsOpenDrawer(state);
    if (trackingActions.includes(action.type)) {
        if (openDrawer) {
            otherEventTracking(
                action.type,
                findOtherEventIndex('OPEN_DRAWER'),
                openDrawer
            );
        }
    }

    const field = (() => {
        switch (action.type) {
            case FUNERAL_CLAIM_SUBMIT_FORM:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Funeral Claim';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};
