export const DIGILIFE_QUOTE_TOOL = "roaQuoteTools/digiLifeQuoteTool/";
export const DIGILIFE_QUOTE_TOOL_SET_VALUE = DIGILIFE_QUOTE_TOOL + "setValue/";
export const DIGILIFE_QUOTE_TOOL_SET_VALID = DIGILIFE_QUOTE_TOOL + "setValid/";

export const REINITIALIZE =
  "roaQuoteTools/digiLifeQuoteTool/state/reinitialize";
export const setDigiLifeQuoteInitial = () => ({ type: REINITIALIZE });

//api status
export const API_PENDING_TRANSITION_DIGILIFE_QUOTE_TOOL = `${DIGILIFE_QUOTE_TOOL_SET_VALUE}apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DIGILIFE_QUOTE_TOOL = `${DIGILIFE_QUOTE_TOOL_SET_VALUE}apiTransition/success`;
export const API_FAILURE_TRANSITION_DIGILIFE_QUOTE_TOOL = `${DIGILIFE_QUOTE_TOOL_SET_VALUE}apiTransition/failure`;
export const apiTransitionDigiLifeQuoteTool = {
  pending: () => ({ type: API_PENDING_TRANSITION_DIGILIFE_QUOTE_TOOL }),
  success: () => ({ type: API_SUCCESS_TRANSITION_DIGILIFE_QUOTE_TOOL }),
  failure: () => ({ type: API_FAILURE_TRANSITION_DIGILIFE_QUOTE_TOOL }),
};

export const FORM_VALID_DIGILIFE_QUOTE_TOOL =
  "roaQuoteTools/DigiLifeQuoteTool/transition/formStateValid";
export const FORM_INVALID_DIGILIFE_QUOTE_TOOL =
  "roaQuoteTools/DigiLifeQuoteTool/transition/formInvalid";

export const transitionDigiLifeTool = {
  formValid: () => ({ type: FORM_VALID_DIGILIFE_QUOTE_TOOL }),
  formInvalid: () => ({ type: FORM_INVALID_DIGILIFE_QUOTE_TOOL }),
};

//collect
export const COLLECT_DIGILIFE_PREMIUM_DATA =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "collectDigiLifePremiumData";
export const CALCULATE_DIGILIFE_PREMIUM_DATA =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "success";
export const CALCULATE_DIGILIFE_PREMIUM_DATA_FAILURE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "failure";
export const WHICH_FORM_TO_DISPLAY =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "whichFormToDisplay";
//collect
export const collectDigiLifePremiumData = () => ({
  type: COLLECT_DIGILIFE_PREMIUM_DATA,
});
export const calculateDigiLifePremiumData = (value) => ({
  type: CALCULATE_DIGILIFE_PREMIUM_DATA,
  value,
});
export const collectDigiLifePremiumDataFailure = (error) => ({
  type: CALCULATE_DIGILIFE_PREMIUM_DATA_FAILURE,
  error,
});
export const setWhichFormToDisplay = (value) => ({
  type: WHICH_FORM_TO_DISPLAY,
  value,
});

// export const DIGILIFE_FULL_NAME_SET_VALUE = DIGILIFE_QUOTE_TOOL_SET_VALUE + 'fullName';
// export const DIGILIFE_FULL_NAME_SET_VALID = DIGILIFE_QUOTE_TOOL_SET_VALID + 'fullName';
// export const DIGILIFE_CONTACT_SET_VALUE = DIGILIFE_QUOTE_TOOL_SET_VALUE + 'contactNumber';
// export const DIGILIFE_CONTACT_SET_VALID = DIGILIFE_QUOTE_TOOL_SET_VALID + 'contactNumber';
// export const DIGILIFE_EMAIL_SET_VALUE = DIGILIFE_QUOTE_TOOL_SET_VALUE + 'emailId';
// export const DIGILIFE_EMAIL_SET_VALID = DIGILIFE_QUOTE_TOOL_SET_VALID + 'emailId';
export const DIGILIFE_DOB_SET_VALUE = DIGILIFE_QUOTE_TOOL_SET_VALUE + "DOB";
export const DIGILIFE_DOB_SET_VALID = DIGILIFE_QUOTE_TOOL_SET_VALID + "DOB";
export const DIGILIFE_GENDER_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "gender";
export const DIGILIFE_GENDER_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "gender";
export const DIGILIFE_SALARY_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "salary";
export const DIGILIFE_SALARY_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "salary";
export const DIGILIFE_EDUCATION_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "education";
export const DIGILIFE_EDUCATION_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "education";
export const DIGILIFE_LOAN_TYPE_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "loanType";
export const DIGILIFE_LOAN_TYPE_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "loanType";
export const DIGILIFE_LOAN_AMOUNT_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "loanAmount";
export const DIGILIFE_LOAN_AMOUNT_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "loanAmount";
export const DIGILIFE_LOAN_TERM_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "loanTerm";
export const DIGILIFE_LOAN_TERM_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "loanTerm";
export const DIGILIFE_PAYMENT_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "payment";
export const DIGILIFE_PAYMENT_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "payment";
export const DIGILIFE_CASHBACK_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "cashBack";
export const DIGILIFE_CASHBACK_SET_VALID =
  DIGILIFE_QUOTE_TOOL_SET_VALID + "cashBack";
export const DIGILIFE_DATE_SET_VALUE = DIGILIFE_QUOTE_TOOL_SET_VALUE + "date";
export const DIGILIFE_DATE_SET_VALID = DIGILIFE_QUOTE_TOOL_SET_VALID + "date";
export const DIGILIFE_FORMATTED_DATE_SET_VALUE =
  DIGILIFE_QUOTE_TOOL_SET_VALUE + "formattedDate";

// export const setFullNameValue = (value) => ({type: DIGILIFE_FULL_NAME_SET_VALUE, value,});
// export const setFullNameValid = (error) => ({type: DIGILIFE_FULL_NAME_SET_VALID, error,});
// export const setContactNumberValue = (value) => ({type: DIGILIFE_CONTACT_SET_VALUE, value,});
// export const setContactNumberValid = (error) => ({type: DIGILIFE_CONTACT_SET_VALID, error,});
// export const setEmailIdValue = (value) => ({type: DIGILIFE_EMAIL_SET_VALUE, value,});
// export const setEmailIdValid = (error) => ({type: DIGILIFE_EMAIL_SET_VALID, error,});
export const setDateOfBirthValue = (value) => ({
  type: DIGILIFE_DOB_SET_VALUE,
  value,
});
export const setDateOfBirthValid = (error) => ({
  type: DIGILIFE_DOB_SET_VALID,
  error,
});
export const setGenderValue = (value) => ({
  type: DIGILIFE_GENDER_SET_VALUE,
  value,
});
export const setGenderValid = (error) => ({
  type: DIGILIFE_GENDER_SET_VALID,
  error,
});
export const setSalaryValue = (value) => ({
  type: DIGILIFE_SALARY_SET_VALUE,
  value,
});
export const setSalaryValid = (error) => ({
  type: DIGILIFE_SALARY_SET_VALID,
  error,
});
export const setEducationValue = (value) => ({
  type: DIGILIFE_EDUCATION_SET_VALUE,
  value,
});
export const setEducationValid = (error) => ({
  type: DIGILIFE_EDUCATION_SET_VALID,
  error,
});
export const setLoanTypeValue = (value) => ({
  type: DIGILIFE_LOAN_TYPE_SET_VALUE,
  value,
});
export const setLoanTypeValid = (error) => ({
  type: DIGILIFE_LOAN_TYPE_SET_VALID,
  error,
});
export const setLoanAmountValue = (value) => ({
  type: DIGILIFE_LOAN_AMOUNT_SET_VALUE,
  value,
});
export const setLoanAmountValid = (error) => ({
  type: DIGILIFE_LOAN_AMOUNT_SET_VALID,
  error,
});
export const setLoanTermValue = (value) => ({
  type: DIGILIFE_LOAN_TERM_SET_VALUE,
  value,
});
export const setLoanTermValid = (error) => ({
  type: DIGILIFE_LOAN_TERM_SET_VALID,
  error,
});
export const setPaymentValue = (value) => ({
  type: DIGILIFE_PAYMENT_SET_VALUE,
  value,
});
export const setPaymentValid = (error) => ({
  type: DIGILIFE_PAYMENT_SET_VALID,
  error,
});
export const setCashBackValue = (value) => ({
  type: DIGILIFE_CASHBACK_SET_VALUE,
  value,
});
export const setCashBackValid = (error) => ({
  type: DIGILIFE_CASHBACK_SET_VALID,
  error,
});
export const setDateValue = (value) => ({
  type: DIGILIFE_DATE_SET_VALUE,
  value,
});
export const setDateValid = (error) => ({
  type: DIGILIFE_DATE_SET_VALID,
  error,
});
export const setFormattedDateValue = (value) => ({
  type: DIGILIFE_FORMATTED_DATE_SET_VALUE,
  value,
});
