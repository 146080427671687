import { RECOMMENDED_MINIMUM_TERMS } from '../../actions/taxFreeSavingsAccount/types';

/**
 * Calculates the average performance of a fund based on an object of performance values.
 * Returns 'N/A' if average could not be calculated.
 * @param performances An object with values representing performance percentages for a fund
 * @returns string
 */
export const calculateFundAverageGrowth = (performances) => {
    const performanceNumbers = Object.values(performances).filter((num) => /\d/.test(num));
    const nNumbers = performanceNumbers.length;
    const sum = (numbers) => numbers.reduce((acc, num) => acc + +num, 0);

    return nNumbers ? `${(sum(performanceNumbers) / nNumbers).toFixed(2)}%` : 'N/A';
};

/**
 * Returns a fund name to be displayed on the template, removing any suffixed A, T, R or A1 strings to the name.
 * @param name Fund name string
 * @returns string
 */
export const displayFundName = (name) => {
    const hasLetterSuffix = name.endsWith(' A') || name.endsWith(' T') || name.endsWith(' R');
    if (hasLetterSuffix) {
        return name.substring(0, name.length - 2);
    }

    const hasNumberLetterSuffix =
        name.endsWith(' A1') || name.endsWith(' B1') || name.endsWith(' B2') || name.endsWith(' B4');
    if (hasNumberLetterSuffix) {
        return name.substring(0, name.length - 3);
    }

    return name;
};

// This function converts the amfRate to a percentage string number
// Using maths causes weird 000000001 at the end
export const convertAmfRateToPercString = (amfRate) => {
    if (amfRate === null || amfRate === undefined) {
        return 'NONE';
    }
    if (amfRate === 0) {
        return '0';
    }
    const parts = amfRate.toString().split('.');
    const beforeComma = parts[0];
    const afterComma = parts[1];
    const twoDigitsAfterComma = afterComma.substring(0, 2);
    const restOfNum = afterComma.substring(2);
    return parseFloat(`${beforeComma}${twoDigitsAfterComma}.${restOfNum}`).toString();
};

/**
 * Format fund data to be consumed by template.
 * @param fund
 * @param category
 * @returns {{investmentInfo: [{label: string, value}, {label: string, value: string}, {label: string, value: [{label: string, value}, {label: string, value}, {label: string, value}]}], riskProfile, description, fundName: string, factSheetUrl}}
 */
export const displayFund = (fund, category) => ({
    fundCode: fund.fundDetails.code,
    fundName: displayFundName(fund.fundDetails.name),
    factSheetUrl: fund.fundDetails.url,
    description: fund.fundDetails.description,
    riskProfile: fund.riskProfile,
    fees: convertAmfRateToPercString(fund.fundAmfRate),
    investmentInfo: [
        { label: 'Recommended min term', value: RECOMMENDED_MINIMUM_TERMS[category] },
        {
            label: 'Past performance',
            value: [
                { label: '1yr', value: fund.performances.year1 },
                { label: '3yr', value: fund.performances.year3 },
                { label: '5yr', value: fund.performances.year5 },
            ],
        },
    ],
});

/**
 * Group the 3 default funds to display on the Fund Selection and Advanced Fund Picker screens.
 * @returns {{mediumRisk: {investmentInfo: ({label: string, value}|{label: string, value: string}|{label: string, value: {label: string, value}[]})[], riskProfile, description, fundName: string, factSheetUrl}, lowRisk: {investmentInfo: ({label: string, value}|{label: string, value: string}|{label: string, value: {label: string, value}[]})[], riskProfile, description, fundName: string, factSheetUrl}, highRisk: {investmentInfo: ({label: string, value}|{label: string, value: string}|{label: string, value: {label: string, value}[]})[], riskProfile, description, fundName: string, factSheetUrl}}}
 */
export const defaultFunds = (tfsaFundReferenceData) => {
    const displayDefaultFund = (category) => displayFund(tfsaFundReferenceData.defaultFunds[category], category);

    return {
        lowRisk: displayDefaultFund('lowRisk'),
        mediumRisk: displayDefaultFund('mediumRisk'),
        highRisk: displayDefaultFund('highRisk'),
    };
};
