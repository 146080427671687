// SAVE2GETHER
import { SUBMISSION_COMPLETE } from '../../../actions/publicWeb/save2gether';
import { save2getherTracking } from "./save2gether";

//CALLMEBACK
import {callmebackTracking} from "./callMeBack";
import {searchCludoTracking} from './search';
import {
    CALL_ME_BACK_CLICKED,
    CALL_ME_BACK_SEND_ACTION,
} from "../../../actions/publicWeb/callMeBack";

// Dividend Calc
import {
    DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE,
    CALCULATE_DIVIDENDS,
} from '../../../actions/publicWeb/dividendCalculator';
import { dividendCalculatorTracking } from './dividendCalculator';
import { PERFORM_CLICK_LOG, PERFORM_QUERY_LOG } from "../../../actions/publicWeb/search";

// Unclaimed Benefits
import { unclaimedBenefitsTracking } from './unclaimedBenefits';
import { COLLECT_USER_DATA } from '../../../actions/publicWeb/unclaimedBenefits'

// Funeral Claims
import {FUNERAL_CLAIM_SUBMIT_FORM, FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER} from '../../../actions/publicWeb/funeralClaim';
import {funeralClaimTracking} from './funeralClaim';

// Funeral Claims V2
import {FUNERAL_CLAIM_V2_SET_VALUE, FUNERAL_CLAIM_V2_SUBMIT_FORM, FUNERAL_CLAIM_V2_TOGGLE_SIDE_DRAWER} from '../../../actions/publicWeb/funeralClaimV2';
import {funeralClaimV2FieldTracking, funeralClaimV2TransitionTracking} from './funeralClaimV2';

import {
    QUALIFYING_TOGGLE_SIDE_DRAWER_WEB,
    QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES,
    RETURN_DATA_FROM_LEADS_QUALIFYING
} from '../../../actions/publicWeb/qualifyingCheckCallMeBack';
import {qualifyingCheckTracking} from './qualifyingCheckCallMeBack';

export const publicWebTracking = (store, next, action) => {
    /**
     * Public Web
     */
    if (action.type === SUBMISSION_COMPLETE)
        return save2getherTracking(next, action);

    const callMeBackActions = [
        CALL_ME_BACK_SEND_ACTION,
        CALL_ME_BACK_CLICKED
    ];
    if (callMeBackActions.includes(action.type)) {
        return callmebackTracking(next, action);
    }

    const searchActions = [
        PERFORM_QUERY_LOG,
        PERFORM_CLICK_LOG
    ];

    if(searchActions.includes(action.type)) {
        return searchCludoTracking(store, next, action);
    }

    // Dividend Calculator
    if (action.type === DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE)
        return dividendCalculatorTracking(store, next, action);
    if (action.type === CALCULATE_DIVIDENDS)
        return dividendCalculatorTracking(store, next, action);

    // Unclaimed benefits
    if (action.type === COLLECT_USER_DATA)
        return unclaimedBenefitsTracking(store, next, action);

    // Funeral Claims
    const funeralClaimsActions = [
        FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER,
        FUNERAL_CLAIM_SUBMIT_FORM,
    ];
    if (funeralClaimsActions.includes(action.type)) {
        return funeralClaimTracking(store, next, action);
    }

    // Funeral Claims V2
    const funeralClaimsV2Actions = [
        FUNERAL_CLAIM_V2_TOGGLE_SIDE_DRAWER,
        FUNERAL_CLAIM_V2_SUBMIT_FORM,
    ];
    if (funeralClaimsV2Actions.includes(action.type)) {
        return funeralClaimV2TransitionTracking(store, next, action);
    }
    if (action.type.startsWith(FUNERAL_CLAIM_V2_SET_VALUE)) {
        return funeralClaimV2FieldTracking(store, next, action);
    }

    const qualifyingCallMeBackActions = [
        QUALIFYING_TOGGLE_SIDE_DRAWER_WEB,
        QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES,
        RETURN_DATA_FROM_LEADS_QUALIFYING,
    ];
    if (qualifyingCallMeBackActions.includes(action.type)) {
        return qualifyingCheckTracking(store, next, action);
    }
};