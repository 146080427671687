import {fireGtmEvent} from "../gtmEvents";

import {
    CALL_ME_BACK_CLICKED,
    CALL_ME_BACK_SEND_ACTION,
} from "../../../actions/publicWeb/callMeBack";

const submissionGtmEvent = submittedData => {
    fireGtmEvent(
        'callmeback_submission',
        {
            cmb_context: submittedData,
        }
    );
};

const clickedGtmEvent = clickedData => {
    fireGtmEvent(
        'callmeback_clicked',
        {
            cmb_context: clickedData
        }
    );
};

export const callmebackTracking = (next, action) => {
    const result = next(action);

    switch (action.type) {
        case CALL_ME_BACK_SEND_ACTION:
            submissionGtmEvent(action.gtmContext.cmbExtraInfo);
            return action;
        case CALL_ME_BACK_CLICKED:
            clickedGtmEvent(action.clickedData);
            return;
    }

    return result;
}
