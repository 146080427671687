import axios from 'axios'; 

import {
    calculateWholeLifeCoverData,
    apiTransitionWholeLifeCoverQuoteTool,
    setWhichFormToDisplay,
} from '../../../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';

import {
    getWholeLifeCoverQuoteToolForm,
    getIsApiPending
} from '../../../../../selectors/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';

export const wholeLifeCoverQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionWholeLifeCoverQuoteTool.pending());
            

            try {
                const response = await calculate(state);
                const quotationSummary = response.data;
                store.dispatch(calculateWholeLifeCoverData(quotationSummary));
                store.dispatch(apiTransitionWholeLifeCoverQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateWholeLifeCoverData(null));
                store.dispatch(apiTransitionWholeLifeCoverQuoteTool.failure());
            }
        }
    })();
    return result;
}

const getWholeLifeCoverPayload = (formDetails) => {
    return {
        'dob': formDetails.candidateDOB.value || '',
        'gender': formDetails.genderValue.value || '',
        'smoke_status': formDetails.smokingValue.value || '',
        'txt_whole_life': formDetails.coveredAmount.value || '',
        'txt_rider_whole_life': formDetails.disabilityValue.value || ''
    }
}

const calculate = async(state) => {
    const wholeLifeCoverFormDetails = getWholeLifeCoverQuoteToolForm(state)

    try{
        const url = '/om-api/roa-quote-tools/whole-life-cover';
        const result = await axios.post(url, getWholeLifeCoverPayload(wholeLifeCoverFormDetails))
        return result;
    } catch (error) {
        return null;
    }
}
