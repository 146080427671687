import {
    getBeneficiaryList,
    getDateOfBirth,
    getEditBeneficiaryForm,
    getEditBeneficiaryFormIsValid,
    getEmail,
    getFirstName,
    getRelationship,
    getMobileNumber,
    getSurname,
    getTitle,
    getBeneficiaryRelationshipTitles,
    getNBeneficiaries,
    getBeneficiariesCanContinue,
} from "../../../selectors/easiplusFuneralPlan/beneficiaries";
import {
    getCoverMembersForms,
    getReferenceCoverMembers,
} from "../../../selectors/easiplusFuneralPlan/livesCovered"
import {FIELD_STATUS} from "../../../reducers/status";
import {
    DELETE_TRANSITION_BENEFICIARIES,
    EMAIL_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    SAVE_TRANSITION_BENEFICIARIES,
    SELECTED_EXISTING_BENEFICIARY_SET_VALUE,
    setDateOfBirthValue,
    setEmailValid,
    setFirstNameValue,
    setRelationshipValue,
    setMobileValid,
    setSurnameValue,
    setTitleValue,
    transitionBeneficiaries,
    setShowMustHaveBeneficiaryValue,
    setTitleUntouched,
    setFirstNameUntouched,
    setSurnameUntouched,
    setDateOfBirthUntouched,
    setRelationshipUntouched,
    RELATIONSHIP_SET_VALUE,
} from "../../../actions/easiplusFuneralPlan/beneficiaries";
import {evenSplitSingleBeneficiary} from "../../../actions/easiplusFuneralPlan/beneficiariesSplit";
import {
    deselectEvenSplit,
    discardEvenSplit,
    formInitTransitionBeneficiariesSplit, 
    setTotalPercentageValue,
} from "../../../actions/easiplusFuneralPlan/beneficiariesSplit";
import { 
    navigateToPaymentDetails,
    navigateToBeneficiaries,
    navigateToBeneficiariesSplit,
} from "../../../actions/easiplusFuneralPlan/routing";

const sameType = type => act => act.type === type;
export const pleaseEnterMsg = 'Please enter at least one of these fields.';

export const determineBeneficiaryNavigation = (store, state, navigateNext) => {
    const nBeneficiaries = getNBeneficiaries(state);
    const beneficiariesCanContinue = getBeneficiariesCanContinue(state);
    const beneficiaryList = getBeneficiaryList(state);

    if (navigateNext) {
        // forward navigation
        if (nBeneficiaries === 0 && !beneficiariesCanContinue) {
            store.dispatch(setShowMustHaveBeneficiaryValue(true));
        } else {
            if (nBeneficiaries <= 1) {
                store.dispatch(navigateToPaymentDetails());
            } else {
                store.dispatch(formInitTransitionBeneficiariesSplit(beneficiaryList));
                store.dispatch(navigateToBeneficiariesSplit());
            }
        }
    } else {
        // back navigation
        store.dispatch(nBeneficiaries > 1 ? navigateToBeneficiariesSplit() : navigateToBeneficiaries());
    }
};

export const beneficiarySaveDeleteRules = (store, next, action) => {
    // state before middleware is applied
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const actions = [action];
    const resetSplitPercentageValues = () => {
        // when any beneficiary changes are saved or beneficiaries are deleted, remove all set split percentage
        // values on the next page
        store.dispatch(deselectEvenSplit());
        store.dispatch(discardEvenSplit());
        store.dispatch(setTotalPercentageValue(0));
    };

    const beneficiaryList = getBeneficiaryList(currentState);
    const beneficiaryListLength = beneficiaryList.length;
    const beneficiaryFormIsValid = getEditBeneficiaryFormIsValid(prevState);

    if (actions.find(sameType(DELETE_TRANSITION_BENEFICIARIES)) ||
       (actions.find(sameType(SAVE_TRANSITION_BENEFICIARIES)) && beneficiaryFormIsValid)) {
        if (beneficiaryListLength === 1) {
            store.dispatch(evenSplitSingleBeneficiary(beneficiaryList[0].id));
        } else {
            resetSplitPercentageValues();
        }
        return result;
    }

    // resetting the field value forces a validation check
    store.dispatch(setTitleValue(getTitle(prevState).value));
    store.dispatch(setFirstNameValue(getFirstName(prevState).value));
    store.dispatch(setSurnameValue(getSurname(prevState).value));
    store.dispatch(setDateOfBirthValue(getDateOfBirth(prevState).value));
    store.dispatch(setRelationshipValue(getRelationship(prevState).value));

    const emailField = getEmail(prevState);
    const mobileField = getMobileNumber(prevState);
    if (!emailField.value && !mobileField.value) {
        store.dispatch(setEmailValid(pleaseEnterMsg));
        store.dispatch(setMobileValid(pleaseEnterMsg));
    }

    return result;
};

export const beneficiariesFormRules = (store, next, action) => {
    // state after middleware is applied
    const result = next(action);
    const state = store.getState();
    const actionTypes = [action.type];

    const beneficiaryForm = getEditBeneficiaryForm(state);
    const beneficiaryIsValid = validateBeneficiary(beneficiaryForm);
    const formAction = beneficiaryIsValid
        ? transitionBeneficiaries.formValid()
        : transitionBeneficiaries.formInvalid()

    store.dispatch(formAction);

    if (actionTypes.includes(SELECTED_EXISTING_BENEFICIARY_SET_VALUE))
        selectedExistingBenificiary(state, store, action);
        
    if (actionTypes.includes(MOBILE_NUMBER_SET_VALUE) ||
        actionTypes.includes(EMAIL_SET_VALUE)) {
        const mobileField = getMobileNumber(state);
        const emailField = getEmail(state);

        if (mobileField.error === pleaseEnterMsg)
            store.dispatch(setMobileValid(null));
        if (emailField.error === pleaseEnterMsg)
            store.dispatch(setEmailValid(null));
    }

    if (actionTypes.includes(RELATIONSHIP_SET_VALUE)) {
        const selectedTitle = getTitle(state);
        const filteredTitles = getBeneficiaryRelationshipTitles(state);

        if (selectedTitle && selectedTitle.value) {
            const selectedIsInFilteredList = filteredTitles.some(title => title.code === selectedTitle.value);

            if (!selectedIsInFilteredList) {
                store.dispatch(setTitleValue(null))
            }
        }
    }

    return result;
};

export const selectedExistingBenificiary = (state, store, action) => {
    const livesCoveredData = getCoverMembersForms(state);
    const referenceData = getReferenceCoverMembers(state);

    if (action.value in livesCoveredData) {
        const form = livesCoveredData[action.value];
        const relationshipKey = (Object.keys(referenceData)).filter(key => referenceData[key].code === action.value);
        const relationship = referenceData[relationshipKey].label.includes(' ')
                                ? referenceData[relationshipKey].label.split(' ')[0]
                                : referenceData[relationshipKey].label;
    
        store.dispatch(setTitleValue(form.title.value));
        store.dispatch(setFirstNameValue(form.firstName.value));
        store.dispatch(setSurnameValue(form.surname.value));
        store.dispatch(setDateOfBirthValue(form.dateOfBirth.value));
        store.dispatch(setRelationshipValue(relationship));
    } else {
        store.dispatch(setTitleUntouched());
        store.dispatch(setFirstNameUntouched());
        store.dispatch(setSurnameUntouched());
        store.dispatch(setDateOfBirthUntouched());
        store.dispatch(setRelationshipUntouched());
    }
};

const validateBeneficiary = formFields => {
    const validField = name => formFields[name] && formFields[name].status === FIELD_STATUS.VALID;
    const emptyField = name => formFields[name] && (formFields[name].value === null || formFields[name].value === '');
    const nonEmptyValidField = name => validField(name) && formFields[name].value;

    const validContactDetails = (
        (nonEmptyValidField('mobileNumber') && nonEmptyValidField('email')) ||
        (nonEmptyValidField('mobileNumber') && emptyField('email') ) ||
        (emptyField('mobileNumber') && nonEmptyValidField('email'))
    );

    return (
        validField('title') &&
        validField('firstName') &&
        validField('surname') &&
        validField('dateOfBirth') &&
        validField('relationship') &&
        validContactDetails
    );
};