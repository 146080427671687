import * as ActionTypes from '../../actions/digitalOnboarding/types';
import { getOfferDetails, getMaxLoanLimit } from './lib/offerBounds';

const storeValues = {
    [ActionTypes.MONTH_STEP_SIZE]: 1,
    [ActionTypes.LOAN_AMOUNT_STEP_SIZE]: 100,
    [ActionTypes.MIN_TERM]: 0,
    [ActionTypes.MAX_TERM]: 0,
    [ActionTypes.MIN_AMOUNT]: 0,
    [ActionTypes.MAX_AMOUNT]: 0,
    [ActionTypes.ABS_MAX_AMOUNT]: 0,
    [ActionTypes.MIN_INITIATION_FEE]: 0,
    [ActionTypes.MAX_INITIATION_FEE]: 0,
    [ActionTypes.ADMIN_FEE]: 0,
    [ActionTypes.CREDIT_LIFE_RATE]: 0,
    [ActionTypes.VAT_RATE]: 0,
    [ActionTypes.QUOTE_AFFORDABILITY]: 0,
    [ActionTypes.INITIATION_FEE]: 0,
    [ActionTypes.LOAN_AMOUNT]: 0,
    [ActionTypes.LOAN_TERM]: 0,
    [ActionTypes.LOAN_INSTALMENT]: 0,
    [ActionTypes.CREDIT_LIFE]: 0
};

export const initQuoteDetails = {
    quoteAvailable: false,
    storeValues
};

function getMaxLoanAmountLimit(offerLimits, loanAmountStep) {
    const newMaxAmount = getMaxLoanLimit(offerLimits, loanAmountStep)

    return {
        [ActionTypes.ABS_MAX_AMOUNT]: newMaxAmount.maxCapital
    };
}

function updateQuoteMinAndMaxDecisions(offerLimits, loanAmount, loanTerm, loanAmountStep) {
    const offer = getOfferDetails(offerLimits, loanAmount, loanTerm, loanAmountStep);
    return {
        [ActionTypes.MIN_AMOUNT]: offer.minCapital,
        [ActionTypes.MAX_AMOUNT]: offer.maxCapital,
        [ActionTypes.MIN_TERM]: offer.minTerm,
        [ActionTypes.MAX_TERM]: offer.maxTerm,
        [ActionTypes.INITIATION_FEE]: offer.initiationFee,
        [ActionTypes.ADMIN_FEE]: offer.adminFee,
        [ActionTypes.VAT_RATE]: offer.vatRate,
        [ActionTypes.LOAN_INSTALMENT]: offer.deduction,
        [ActionTypes.CREDIT_LIFE]: offer.creditLife,
        [ActionTypes.LOAN_TERM]: offer.loanTerm,
        [ActionTypes.INTEREST_RATE]: offer.interestRate,
        [ActionTypes.LOAN_AMOUNT]: offer.loanAmount
    };
}

export default (state = initQuoteDetails, action) => {
    const setStoreValue = (state, key, value) => ({
        ...state,
        storeValues: {
            ...state.storeValues,
            [key]: value
        }
    })

    switch (action.type) {
        case ActionTypes.STORE_AFFORDABILITY_DETAIL:
            if (action.key === ActionTypes.CAPITAL_NEEDED) {
                return setStoreValue(state, ActionTypes.CAPITAL_NEEDED, +action.value);
            }
            return state;

        case ActionTypes.OFFER_QUOTE_SUCCESS: {
            try {
                const absMaxAmount = getMaxLoanAmountLimit(action.data, state.storeValues[ActionTypes.LOAN_AMOUNT_STEP_SIZE]);
            const offer = updateQuoteMinAndMaxDecisions(action.data, -1, -1, state.storeValues[ActionTypes.LOAN_AMOUNT_STEP_SIZE]);
            return {
                ...state,
                quoteAvailable: true,
                storeValues: {
                    ...state.storeValues,
                    ...offer,
                    ...absMaxAmount
                },
                quoteLimits: action.data
            };
            } catch (error) {
                throw ActionTypes.OFFER_QUOTE_NO_OFFER;
            }
            
        }

        case ActionTypes.OFFER_QUOTE_NOT_AVAILABLE:
            return { ...state, quoteAvailable: false };

        case ActionTypes.OFFER_QUOTE_FAIL:
            return { ...state, quoteAvailable: false };

        case ActionTypes.STORE_APPLICANT_SELECTED_QUOTE_LOAN_AMOUNT: {
            const loanAmount = action.value;
            const offer = updateQuoteMinAndMaxDecisions(state.quoteLimits, loanAmount, state.storeValues[ActionTypes.LOAN_TERM], state.storeValues[ActionTypes.LOAN_AMOUNT_STEP_SIZE]);

            return { ...state, storeValues: { ...state.storeValues, ...offer } };
        }

        case ActionTypes.STORE_APPLICANT_SELECTED_QUOTE_LOAN_TERM: {
            const loanTerm = action.value;
            const offer = updateQuoteMinAndMaxDecisions(state.quoteLimits, state.storeValues[ActionTypes.LOAN_AMOUNT], loanTerm, state.storeValues[ActionTypes.LOAN_AMOUNT_STEP_SIZE]);

            return { ...state, storeValues: { ...state.storeValues, ...offer } };
        }

        default:
            break;
    }

    return state;
}
