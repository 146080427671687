export const LONG_TERM_GOAL_CALCULATOR_TRANSITION = 'investmentCalculator/longTermGoal/transition/'

export const FORM_VALID_LONG_TERM_GOAL_TRANSITION =
	'investmentCalculator/longTermGoal/formValid';
export const FORM_INVALID_LONG_TERM_GOAL_TRANSITION =
	'investmentCalculator/longTermGoal/formInvalid';

export const transitionLongTermGoalCalculator = {
	formValid: () => ({ type: FORM_VALID_LONG_TERM_GOAL_TRANSITION }),
	formInvalid: () => ({
		type: FORM_INVALID_LONG_TERM_GOAL_TRANSITION,
	}),
}; // mw/rules engine file and reducer

export const LONG_TERM_GOAL_CALCULATOR_SET_VALUE =
	'investmentCalculator/longTermGoal/setValue/';
export const LONG_TERM_GOAL_CALCULATOR_SET_VALID =
	'investmentCalculator/longTermGoal/setValid/';

export const REINITIALIZE_STATE_LONG_TERM_GOAL_CALCULATOR =
	'investmentCalculator/longTermGoal/reinitialize';

// TODO: Add other fields
export const ONCE_OFF_SET_VALUE =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'onceOff';
export const ONCE_OFF_SET_VALID =
	LONG_TERM_GOAL_CALCULATOR_SET_VALID + 'onceOff';

export const MONTHLY_AMOUNT_SET_VALUE =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'monthlyAmount';
export const MONTHLY_AMOUNT_SET_VALID =
	LONG_TERM_GOAL_CALCULATOR_SET_VALID + 'monthlyAmount';

export const INVESTMENT_TENURE_SET_VALUE =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'investmentTenure';
export const INVESTMENT_TENURE_SET_VALID =
	LONG_TERM_GOAL_CALCULATOR_SET_VALID + 'investmentTenure';

export const POTENTIAL_LOSS_PERCENTAGE_SET_VALUE =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'potentialLossPercentage';
export const POTENTIAL_LOSS_PERCENTAGE_SET_VALID =
	LONG_TERM_GOAL_CALCULATOR_SET_VALID + 'potentialLossPercentage';

export const RESULTS_VIEW_VALUE =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'viewResults';
export const RESULTS_SET_VALUE =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'setResults';

export const CALCULATE_LONG_TERM_GOAL =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'success'; 
export const RESULTS_SET_VALUE_FAILURE =
	LONG_TERM_GOAL_CALCULATOR_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_LONG_TERM_GOAL_CALCULATOR = `${LONG_TERM_GOAL_CALCULATOR_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_LONG_TERM_GOAL_CALCULATOR = `${LONG_TERM_GOAL_CALCULATOR_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_LONG_TERM_GOAL_CALCULATOR = `${LONG_TERM_GOAL_CALCULATOR_SET_VALUE}/apiTransition/failure`; //reducer file
export const apiTransitionLongTermGoalCalculator = {
	pending: () => ({
		type: API_PENDING_TRANSITION_LONG_TERM_GOAL_CALCULATOR,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_LONG_TERM_GOAL_CALCULATOR,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_LONG_TERM_GOAL_CALCULATOR,
	}),
}; // mw/service file

export const getLongTermGoalResult = () => ({ type: RESULTS_SET_VALUE }); 
export const calculateLongTermGoal = (value) => ({ type: CALCULATE_LONG_TERM_GOAL, value});
export const getLongTermGoalResultFailure = (error) => ({
	type: RESULTS_SET_VALUE_FAILURE,
	error,
});

export const setLongTermGoalOnceOffValue = (value) => ({
	type: ONCE_OFF_SET_VALUE,
	value,
});
export const setLongTermGoalOnceOffValid = (error) => ({
	type: ONCE_OFF_SET_VALID,
	error,
}); //
export const setMonthlyValue = (value) => ({
	type: MONTHLY_AMOUNT_SET_VALUE,
	value,
});
export const setMonthlyValid = (error) => ({
	type: MONTHLY_AMOUNT_SET_VALID,
	error,
});
export const setInvestmentTenureValue = (value) => ({
	type: INVESTMENT_TENURE_SET_VALUE,
	value,
});
export const setInvestmentTenureValid = (error) => ({
	type: INVESTMENT_TENURE_SET_VALID,
	error,
});
export const setPotentialLossPercentageValue = (value) => ({
	type: POTENTIAL_LOSS_PERCENTAGE_SET_VALUE,
	value,
});
export const setPotentialLossPercentageValid = (error) => ({
	type: POTENTIAL_LOSS_PERCENTAGE_SET_VALID,
	error,
});

// export const setViewLongTermGoalResult = value => ({ type: RESULTS_VIEW_VALUE, value });
export const reInitialize = () => ({
	type: REINITIALIZE_STATE_LONG_TERM_GOAL_CALCULATOR,
});
