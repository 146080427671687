import {getEfpQuote} from "../../../selectors/easiplusFuneralPlan/submission";
import axios from "axios";
import {continueQuote, sendQuoteCreateQuoteFailure, sendQuoteCreateQuoteSuccess} from "../../../actions/easiplusFuneralPlan/quote";
import { setRequestId } from "../../../actions/easiplusFuneralPlan/sessionCamVariables";

export const createEfpQuoteService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const payload = getEfpQuote(state);

    (async () => {
        const response = await createQuote(payload);
        if (response && (response.status === 200) && response.data.isValid) {
            store.dispatch(sendQuoteCreateQuoteSuccess(response.data.application.quoteNumber));
            store.dispatch(continueQuote());
        } else {
            store.dispatch(sendQuoteCreateQuoteFailure());
        }

        if (response && 'data' in response && 'requestId' in response.data) {
            store.dispatch(setRequestId(response.data.requestId));
        }
    })();

    return result;
};

export const createQuote = async payload => {
    try {
        const url = '/om-api/easiplus-funeral-plan/create-quote';
        const config = {headers: {'Content-Type': 'application/json'}};
        const response = await axios.post(url, payload, config);
        return response;
    } catch (error) {
        return null;
    }
}