// Transitions
export const FORM_VALID_TRANSITION_BUDGET_TOOL = 'atRetirement/budgetTool/transition/formValid';
export const FORM_INVALID_TRANSITION_BUDGET_TOOL = 'atRetirement/budgetTool/transition/formInvalid';

export const transitionBudgetTool = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_BUDGET_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_BUDGET_TOOL }),
};

// Events
export const BUDGET_TOOL_SET_VALUE = 'atRetirement/budgetTool/setValue/';
export const BUDGET_TOOL_SET_VALID = 'atRetirement/budgetTool/setValid/';
export const BUDGET_TOOL_SET_UNTOUCHED = 'atRetirement/budgetTool/setUntouched/';

export const FIXED_MONTHLY_EXPENSES_SET_VALUE = BUDGET_TOOL_SET_VALUE + 'fixedMonthlyExpenses';
export const FIXED_MONTHLY_EXPENSES_SET_VALID = BUDGET_TOOL_SET_VALID + 'fixedMonthlyExpenses';
export const FIXED_MONTHLY_EXPENSES_SET_UNTOUCHED = BUDGET_TOOL_SET_UNTOUCHED + 'fixedMonthlyExpenses';

export const DAY_TO_DAY_SPENDING_SET_VALUE = BUDGET_TOOL_SET_VALUE + 'dayToDaySpending';
export const DAY_TO_DAY_SPENDING_SET_VALID = BUDGET_TOOL_SET_VALID + 'dayToDaySpending';
export const DAY_TO_DAY_SPENDING_SET_UNTOUCHED = BUDGET_TOOL_SET_UNTOUCHED + 'dayToDaySpending';

export const INSURANCE_SET_VALUE = BUDGET_TOOL_SET_VALUE + 'insurance';
export const INSURANCE_SET_VALID = BUDGET_TOOL_SET_VALID + 'insurance';
export const INSURANCE_SET_UNTOUCHED = BUDGET_TOOL_SET_UNTOUCHED + 'insurance';

export const DEBTS_SET_VALUE = BUDGET_TOOL_SET_VALUE + 'debts';
export const DEBTS_SET_VALID = BUDGET_TOOL_SET_VALID + 'debts';
export const DEBTS_SET_UNTOUCHED = BUDGET_TOOL_SET_UNTOUCHED + 'debts';

export const BUDGET_SET_VALUE = BUDGET_TOOL_SET_VALUE + 'budget';

export const setFixedMonthlyExpensesValue = value => ({ type: FIXED_MONTHLY_EXPENSES_SET_VALUE, value });
export const setFixedMonthlyExpensesValid = error => ({ type: FIXED_MONTHLY_EXPENSES_SET_VALID, error });
export const setFixedMonthlyExpensesUntouched = () => ({ type: FIXED_MONTHLY_EXPENSES_SET_UNTOUCHED });

export const setDayToDaySpendingValue = value => ({ type: DAY_TO_DAY_SPENDING_SET_VALUE, value });
export const setDayToDaySpendingValid = error => ({ type: DAY_TO_DAY_SPENDING_SET_VALID, error });
export const setDayToDaySpendingUntouched = () => ({ type: DAY_TO_DAY_SPENDING_SET_UNTOUCHED });

export const setInsuranceValue = value => ({ type: INSURANCE_SET_VALUE, value });
export const setInsuranceValid = error => ({ type: INSURANCE_SET_VALID, error });
export const setInsuranceUntouched = () => ({ type: INSURANCE_SET_UNTOUCHED });

export const setDebtsValue = value => ({ type: DEBTS_SET_VALUE, value });
export const setDebtsValid = error => ({ type: DEBTS_SET_VALID, error });
export const setDebtsUntouched = () => ({ type: DEBTS_SET_UNTOUCHED });

export const setBudgetValue = () => ({ type: BUDGET_SET_VALUE });
