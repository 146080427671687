import {
    resetOwnPercentageValue,
    resetLumpSumContributionValue,
    resetRegularContributionValue,
    setRegularContributionValue,
    setLumpSumContributionValue,
    setOwnPercentageValue,
    setContributionValue,
    CONTRIBUTION_SET_VALUE,
    setPremiumEscalationValue,
    resetPremiumEscalation,
    PREMIUM_ESCALATION_SET_VALUE,
    limitExceededModalDisplayValue,
    REGULAR_CONTRIBUTION_SET_VALUE,
    LUMP_SUM_CONTRIBUTION_SET_VALUE,
    REGULAR_CONTRIBUTION_SET_VALID,
} from "../../../actions/taxFreeSavingsAccount/investmentSetupContribution";
import {
    getInvestmentSetupContributionForm,
    getRegularContribution,
    getLumpSumContribution,
    getOwnPercentage,
    getContributionType,
    getIsCombinationLimitExceeded,
    getPremiumEscalation,
    getIsMinimumMonthlyContributionEntered
} from "../../../selectors/taxFreeSavingsAccount/investmentSetupContribution";
import { CONTRIBUTION_TYPE, FUND_OPTIONS, PREMIUM_ESCALATION_OPTIONS } from "../../../actions/taxFreeSavingsAccount/types";
import { TFSA_INVESTMENT_SETUP_CONTRIBUTION_SCROLL_ID } from "../status";
import { scrollToError } from "../../../actions/taxFreeSavingsAccount/scrolling";
import { resetAdvancedFundPicker } from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";
import { fundSetValue, resetSelectAFund } from "../../../actions/taxFreeSavingsAccount/selectAFund";
import { FIELD_STATUS } from "../../../reducers/status";
import { getSelectedFund } from "../../../selectors/taxFreeSavingsAccount/selectAFund";

export const investmentSetupContributionRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    if (action.type === CONTRIBUTION_SET_VALUE && action.value) {
        store.dispatch(resetLumpSumContributionValue());
        store.dispatch(resetRegularContributionValue());
        store.dispatch(resetPremiumEscalation());

        store.dispatch(resetAdvancedFundPicker());
        if (getSelectedFund(state).value) store.dispatch(resetSelectAFund());
    }

    if (action.type === PREMIUM_ESCALATION_SET_VALUE && action.value) {
        store.dispatch(resetOwnPercentageValue());
    }

    // If combination limit of R36 000 exceeded display modal
    const contributionType = getContributionType(state);
    if (action.type === REGULAR_CONTRIBUTION_SET_VALUE || action.type === LUMP_SUM_CONTRIBUTION_SET_VALUE) {
        if (contributionType.value === CONTRIBUTION_TYPE.COMBINATION) {
            const limitExceeded = getIsCombinationLimitExceeded(state);
            if (limitExceeded) store.dispatch(limitExceededModalDisplayValue(true));
        }

        store.dispatch(resetAdvancedFundPicker());

        if (action.type === REGULAR_CONTRIBUTION_SET_VALUE) {
            const regularContributionValid = getRegularContribution(state).status === FIELD_STATUS.VALID;
            if (!getIsMinimumMonthlyContributionEntered(state) && regularContributionValid) {
                store.dispatch(resetPremiumEscalation());

                if (getSelectedFund(state).value) store.dispatch(resetSelectAFund());
            }
        }
    }

    if (action.type === REGULAR_CONTRIBUTION_SET_VALID && action.error === null) {
        if (getIsMinimumMonthlyContributionEntered(state)) {
            store.dispatch(setPremiumEscalationValue(PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.key));
            store.dispatch(fundSetValue(FUND_OPTIONS.MODERATE_INVESTOR.toString()));
        }
    }

    return result;
};

export const performInvestmentSetupContributionValidation = (store) => {
    const state = store.getState();
    const contributionType = getContributionType(state);
    const regularContribution = getRegularContribution(state);
    const lumpSumContribution = getLumpSumContribution(state);
    const premiumEscalation = getPremiumEscalation(state);
    const ownPercentage = getOwnPercentage(state);

    if (!contributionType.value) {
        store.dispatch(setContributionValue(null));
    }

    if (contributionType.value === CONTRIBUTION_TYPE.MONTHLY || contributionType.value === CONTRIBUTION_TYPE.COMBINATION) {
        if (regularContribution.status !== FIELD_STATUS.VALID) store.dispatch(setRegularContributionValue(regularContribution.value));

        if (regularContribution.value) {
            if (!premiumEscalation.value) {
                store.dispatch(setPremiumEscalationValue(null));
            } else if (premiumEscalation.value === PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key) {
                store.dispatch(setOwnPercentageValue(ownPercentage.value));
            }
        }
    }

    if (contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM || contributionType.value === CONTRIBUTION_TYPE.COMBINATION) {
        store.dispatch(setLumpSumContributionValue(lumpSumContribution.value));
    }

    if (contributionType.value === null) {
        store.dispatch(setContributionValue(null));
    }

    const form = getInvestmentSetupContributionForm(store.getState());
    store.dispatch(scrollToError(TFSA_INVESTMENT_SETUP_CONTRIBUTION_SCROLL_ID, form));
};
