import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";
import {simpleFieldTracking} from "../formTracking";
import {
    TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW,
    TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE,
    SIDE_DRAWER_CLOSED_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/review';
import {
    getTermsAndConditionsValid,
} from '../../../selectors/taxFreeSavingsAccount/review';
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";



export const reviewTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);
    if (field)
        simpleFieldTracking({action, field, sendValue, sendError});

    return result;
};


export const reviewOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW:
                otherEventIndex = findOtherEventIndex('TOGGLE_CHECKBOX');
                return getTermsAndConditionsValid(state) ? 'reviewAcceptedChecked' : 'reviewAcceptedUnchecked';  
            case TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE:
                otherEventIndex = findOtherEventIndex('OPEN_DRAWER');
                return 'termsAndConditionsDrawerOpened'
            case SIDE_DRAWER_CLOSED_SET_VALUE:
                otherEventIndex = findOtherEventIndex('CLOSE_DRAWER')
                return 'termsAndConditionsDrawerClosed'
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};