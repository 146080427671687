import { UG_MOTOR_QOUTE_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/quote';
import { quoteFormValidation } from './quote';

import { MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/personalDetails';
import { personalDetailsValidation } from './personalDetails';

import { MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/employmentDetails';
import { employmentDetailsValidation } from './employmentDetails';

import { MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/carDetails';
import { carDetailsValidation } from './carsDetails';

import { MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/ugMotorInsurance/motorAgentModal';
import { agentModalFormValidation } from './travelAgentModal';

import { MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/Documents';
import { documentsUploadValidation } from './documents';
import { MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/declarations';
import { declarationsFormValidation } from './declarations';

import { MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/nextOfKin';
import { nextOfKinValidation } from './nextOfKin';

import { PAYMENTS_SET_VALUE } from '../../../actions/ugMotorInsurance/payments';
import { paymentsFormValidation } from './payments';

import { SET_SHARE_EMAIL_VALUE } from '../../../actions/ugMotorInsurance/quote';
import { shareQuoteValidation } from './shareQuote';

export const ugMotorInsuranceValidation = (store, next, action) => {
    if (action.type.startsWith(UG_MOTOR_QOUTE_FORM_SET_VALUE)) {
        return quoteFormValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE)) {
        return personalDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE)) {
        return employmentDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(PAYMENTS_SET_VALUE)) {
        return paymentsFormValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE)) {
        return nextOfKinValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE)) {
        return declarationsFormValidation(store, next, action);
    }
    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE)) {
        return shareQuoteValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE)) {
        return carDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE)) {
        return agentModalFormValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE)) {
        return documentsUploadValidation(store, next, action);
    }

    return null;
};
