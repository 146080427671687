import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

export function validateTaxNumber(taxNumber,
    { 
        required = true, 
        subject = 'South African income tax number',
        retainSubjectCase = true
    } = {}
) {
    if (required && (!taxNumber || taxNumber.trim() === '')) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    const invalidMsg = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);

    if (taxNumber) {
        if (taxNumber.length === 10) {

            const charArray = Array.from(taxNumber);
            let temp = 0;
            let calValues = 0;
            let calSum = 0;
            let checkDigit = 0;

            for (let i = 0; i < charArray.length - 1; i++) {
                if (i % 2 === 0) {
                    temp = +charArray[i] * 2;
                    if (temp >= 10) {
                        const tempArray = Array.from(temp.toString());
                        tempArray.forEach((a) => {
                            calValues = calValues + +a;
                        });

                        temp = calValues;
                    }
                    charArray[i] = temp.toString();
                    calValues = 0;
                }

                calSum += +charArray[i];
            }

            if (calSum > 9) {
                const lastCharDigitOfSum = Array.from(calSum.toString())[calSum.toString().length - 1];
                checkDigit = 10 - parseInt(lastCharDigitOfSum, 10);
            } else {
                checkDigit = 10 - calSum;
            }

            if (checkDigit === 10) {
                checkDigit = 0;
            }

            if (checkDigit.toString() !== charArray[charArray.length - 1]) {
                return invalidMsg;
            }
        } else {
            return invalidMsg;
        }
    }

    return null;
}
