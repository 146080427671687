

export const ALLSURE_INSURANCE_HOME_CONTENTS = 'allsureInsurance/homeContents/';

export const HOME_CONTENTS_SET_VALUE = ALLSURE_INSURANCE_HOME_CONTENTS + 'setValue/';
export const HOME_CONTENTS_SET_VALID = ALLSURE_INSURANCE_HOME_CONTENTS + 'setValid/';



export const setHomeContentsValue = (value) => ({ type: HOME_CONTENTS_SET_VALUE, value });
export const setHomeContentsValid = (error, fieldType) => ({ type: HOME_CONTENTS_SET_VALID, error, fieldType });

export const HOME_CONTENTS_SET_VALUE_WITHOUT_VALIDATION = ALLSURE_INSURANCE_HOME_CONTENTS + '/withoutValidation/setValue/';
export const setHomeContentsValueWithoutValidation = (value) => ({ type: HOME_CONTENTS_SET_VALUE_WITHOUT_VALIDATION, value });

// Manipulate actions
export const ADD_ADDITINAL_HOME_CONTENTS_VALUE = ALLSURE_INSURANCE_HOME_CONTENTS + 'addAdditionalHomeContentValue';
export const EDIT_HOME_CONTENT_VALUE = ALLSURE_INSURANCE_HOME_CONTENTS + 'editHomeContentValue';
export const DELETE_HOME_CONTENT_VALUE =ALLSURE_INSURANCE_HOME_CONTENTS +'deleteHomeContentsValue'

export const UPDATE_HOME_CONTENT_LIST =ALLSURE_INSURANCE_HOME_CONTENTS+'updateHomeContentList'

export const updateHomeContentList=()=>({type: UPDATE_HOME_CONTENT_LIST})

export const addHomeContentValue = () => ({ type: ADD_ADDITINAL_HOME_CONTENTS_VALUE }); 
export const editHomeContentValue = (value) => ({ type: EDIT_HOME_CONTENT_VALUE ,value}); 
export const deleteHomeContentsValue = (value) => ({ type: DELETE_HOME_CONTENT_VALUE ,value}); 


// Sync Same home contents
export const SYNC_SAME_HOUSE_CONTENTS= ALLSURE_INSURANCE_HOME_CONTENTS + "syncSameHouseContents"
export const syncSameHouseContents= (value)=>({ type: SYNC_SAME_HOUSE_CONTENTS ,value})

// Sync Premium frequency
export const SYNC_CONTENTS_PREMIUM_FREQUENCY_VALUE=ALLSURE_INSURANCE_HOME_CONTENTS+"premiumFrequencyValue/";

export const syncContentsPremiumvalue = (value) => ({type:SYNC_CONTENTS_PREMIUM_FREQUENCY_VALUE,value})