import { combineReducers } from 'redux';
import routing from './routing';
import agent from './agent';
import auth from './auth';
import quote from './quote';
import personalDetails from './personalDetails';
import beneficiaries from './beneficiaries';
import uploadDocuments from './Documents';
import payment from './payment';
import payloadCollection from './payloadCollection';

export const digitalSavings = combineReducers({
	routing,
	agent,
	quote,
	personalDetails,
	auth,
	beneficiaries,
	uploadDocuments,
	payment,
	payloadCollection
});
