import {
    INVESTMENT_SETUP_INITIAL_SET_VALID,
    INVESTMENT_SETUP_INITIAL_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/investmentSetupInitial";
import {investmentSetupInitialRules} from "./investmentSetupInitial";
import {
    AUXILIARY_DETAILS_SET_VALID,
    AUXILIARY_DETAILS_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/auxiliaryDetails";
import {auxiliaryDetailsRules} from "./auxiliaryDetails"
import {
    BENEFICIARIES_SET_VALID,
    BENEFICIARIES_SET_VALUE
} from "../../../actions/taxFreeSavingsAccount/beneficiaries";
import {beneficiariesRules} from "./beneficiaries";
import {
    PAYMENT_DETAILS_SET_VALID,
    PAYMENT_DETAILS_SET_VALUE,
    TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS,
} from "../../../actions/taxFreeSavingsAccount/paymentDetails";
import {paymentDetailsRules} from "./paymentDetails";
import {
    REVIEW_SET_VALID,
    REVIEW_SET_VALUE,
    TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW,
} from "../../../actions/taxFreeSavingsAccount/review";
import {reviewRules} from "./review";
import {ROUTING_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/routing";
import {routingRules} from "./routing";
import {
    BENEFICIARIES_SPLIT_SET_VALID,
    BENEFICIARIES_SPLIT_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/beneficiariesSplit";
import {beneficiariesSplitRules} from "./beneficiariesSplit";
import {
    INVESTMENT_SETUP_CONTRIBUTION_SET_VALID,
    INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/investmentSetupContribution";
import {investmentSetupContributionRules} from "./investmentSetupContribution";
import {
    SELECT_A_FUND_SET_VALID,
    SELECT_A_FUND_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/selectAFund";
import {selectAFundRules} from "./selectAFund";
import {
    ADVANCED_FUND_PICKER_SET_VALID,
    ADVANCED_FUND_PICKER_SET_VALUE, 
    FUNDS_TOGGLE_SHOW_MORE,
    FUND_TOGGLE_SELECTION,
    FUND_REQUEST_TOGGLE_SELECTION
} from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";
import {advancedFundPickerRules} from "./advancedFundPicker";
import {
    SPLIT_PERCENTAGE_SET_VALUE,
    DELETE_FUND_FUND_SPLIT
} from "../../../actions/taxFreeSavingsAccount/fundSplit";
import {fundSplitRules} from "./fundSplit";
import { SCROLL_TO_ERROR_TRANSITION_TFSA } from "../../../actions/taxFreeSavingsAccount/scrolling";
import { scrollingRules } from "./scrolling";

export const taxFreeSavingsAccountRules = (store, next, action) => {
    if (action.type.startsWith(SCROLL_TO_ERROR_TRANSITION_TFSA) ||
        action.type.startsWith(INVESTMENT_SETUP_INITIAL_SET_VALUE) ||
        action.type.startsWith(INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE) ||
        action.type.startsWith(BENEFICIARIES_SET_VALUE) ||
        action.type.startsWith(ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS) ||
        action.type.startsWith(FUNDS_TOGGLE_SHOW_MORE)) {
        scrollingRules(store, action);
    }

    if (action.type.startsWith(ROUTING_SET_VALUE)) {
        return routingRules(store, next, action);
    }

    if (action.type.startsWith(INVESTMENT_SETUP_INITIAL_SET_VALID) ||
        action.type.startsWith(INVESTMENT_SETUP_INITIAL_SET_VALUE)) {
        return investmentSetupInitialRules(store, next, action);
    }

    if (action.type.startsWith(INVESTMENT_SETUP_CONTRIBUTION_SET_VALID) ||
        action.type.startsWith(INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE)) {
        return investmentSetupContributionRules(store, next, action);
    }

    if (action.type.startsWith(SELECT_A_FUND_SET_VALID) ||
        action.type.startsWith(SELECT_A_FUND_SET_VALUE)) {
        return selectAFundRules(store, next, action);
    }

    if (action.type.startsWith(ADVANCED_FUND_PICKER_SET_VALID) ||
        action.type.startsWith(ADVANCED_FUND_PICKER_SET_VALUE) ||
        action.type === FUNDS_TOGGLE_SHOW_MORE || 
        action.type === FUND_TOGGLE_SELECTION ||
        action.type === FUND_REQUEST_TOGGLE_SELECTION) {
        return advancedFundPickerRules(store, next, action);
    }

    if (action.type === SPLIT_PERCENTAGE_SET_VALUE ||
        action.type === DELETE_FUND_FUND_SPLIT) {
        return fundSplitRules(store, next, action);
    }

    if (action.type.startsWith(AUXILIARY_DETAILS_SET_VALID) ||
        action.type.startsWith(AUXILIARY_DETAILS_SET_VALUE)) {
        return auxiliaryDetailsRules(store, next, action);
    }

    if (action.type.startsWith(BENEFICIARIES_SET_VALID) ||
        action.type.startsWith(BENEFICIARIES_SET_VALUE)) {
        return beneficiariesRules(store, next, action);
    }

    if (action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALID) ||
        action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE)) {
        return beneficiariesSplitRules(store, next, action);
    }

    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALID) ||
        action.type.startsWith(PAYMENT_DETAILS_SET_VALUE) ||
        action.type === TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS ||
        action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS) {
        return paymentDetailsRules(store, next, action);
    }

    if (action.type.startsWith(REVIEW_SET_VALID) ||
        action.type.startsWith(REVIEW_SET_VALUE) ||
        action.type === TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW) {
        return reviewRules(store, next, action);
    }
};
