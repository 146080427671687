import {
    getAmountBorrowed,
    getOtherAmountBorrowed,
    getRepaymentDuration,
    getOtherRepaymentDuration
} from "../../../selectors/personalLoansCalculator/calculator";
import {
    getLoanAmountOptions,
    getRepaymentDurationOptions
} from "../../../selectors/personalLoansCalculator/referenceData";
import {
    transitionPersonalLoansCalculator,
    clearOtherAmountBorrowedValue,
    clearOtherRepaymentDurationValue,
    BACK_TRANSITION_REPAYMENT_DURATION_DETAILS,
    clearRepaymentDurationValue, 
    RESET_CALCULATOR_FORM
} from "../../../actions/personalLoansCalculator/calculator";
import { FIELD_STATUS } from "../../../reducers/status";
import { transitionScrolling } from "../../../actions/personalLoansCalculator/scrolling";


export const OPTIONAL_FIELDS = {
    ownAmount: 'Own amount',
    ownNumberOfMonths: 'Own number of months'
}

export const personalLoansCalculatorRulesEngine = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    const loanAmountOptions = getLoanAmountOptions(state);
    const repaymentDurationOptions = getRepaymentDurationOptions(state);

    const amountBorrowed = getAmountBorrowed(state);
    const otherAmountBorrowed = getOtherAmountBorrowed(state);
    const repaymentDuration = getRepaymentDuration(state);
    const otherRepaymentDuration = getOtherRepaymentDuration(state);

    if (amountBorrowed.value !== ownAmountMethod(loanAmountOptions)) {
        store.dispatch(clearOtherAmountBorrowedValue());
    }

    if (repaymentDuration.value !== ownRepaymentDurationMethod(repaymentDurationOptions)) {
        store.dispatch(clearOtherRepaymentDurationValue());
    }

    if (validAmountBorrowedDetails(amountBorrowed, otherAmountBorrowed, loanAmountOptions) &&
        validRepaymentDurationDetails(repaymentDuration, otherRepaymentDuration, repaymentDurationOptions)) {
        store.dispatch(transitionPersonalLoansCalculator.formValid());
    } else {
        store.dispatch(transitionPersonalLoansCalculator.formInvalid());
    }

    return result;

}

export const personalLoansCalculatorContinueRules = (store, next, action) => {
    const result = next(action);

    if (action.type === BACK_TRANSITION_REPAYMENT_DURATION_DETAILS) {
        store.dispatch(clearRepaymentDurationValue());
        store.dispatch(clearOtherRepaymentDurationValue());
    }

    if(action.type === RESET_CALCULATOR_FORM){
        store.dispatch(transitionScrolling.scrollToId('calculator_container'));
    }

    return result;
}

export const validAmountBorrowedDetails = (amountBorrowed, otherAmountBorrowed, loanAmountOptions) => {
    return amountBorrowed.status === FIELD_STATUS.VALID &&
        (amountBorrowed.value !== ownAmountMethod(loanAmountOptions) || (amountBorrowed.value === ownAmountMethod(loanAmountOptions) && otherAmountBorrowed.status === FIELD_STATUS.VALID));
}

export const validRepaymentDurationDetails = (repaymentDuration, otherRepaymentDuration, repaymentDurationOptions) => {
    return repaymentDuration.status === FIELD_STATUS.VALID &&
        (repaymentDuration.value !== ownRepaymentDurationMethod(repaymentDurationOptions) || (repaymentDuration.value === ownRepaymentDurationMethod(repaymentDurationOptions) && otherRepaymentDuration.status === FIELD_STATUS.VALID));
}

export const ownAmountMethod = (loanAmountOptions) => {
    return loanAmountOptions.find(option => option.description === OPTIONAL_FIELDS.ownAmount).code;
}

export const ownRepaymentDurationMethod = (repaymentDurationOptions) => {
    return repaymentDurationOptions.length == 0 ? null
        : repaymentDurationOptions.length > 1 && repaymentDurationOptions.some(option => option.description === OPTIONAL_FIELDS.ownNumberOfMonths) ?
            repaymentDurationOptions.find(option => option.description === OPTIONAL_FIELDS.ownNumberOfMonths).code
            : null;
}