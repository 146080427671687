
import {
    SPLIT_PERCENTAGE_SET_VALUE,
    EVEN_SPLIT_TOGGLE_VALUE,
    deselectEvenSplit,
    setSplitPercentageValue,
    setFormValue,
} from "../../../actions/taxFreeSavingsAccount/beneficiariesSplit";
import {
    getIsEvenSplit,
    getBeneficiariesSplitForm,
    getEvenSplitBeneficiariesList
} from "../../../selectors/taxFreeSavingsAccount/beneficiariesSplit";
import { TFSA_BENEFICIARIES_SPLIT_ANCHOR_IDS } from "../status";
import { scrollToError } from "../../../actions/taxFreeSavingsAccount/scrolling";

export const beneficiariesSplitRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    // if even split it active and user changes a split field, deselect even split
    if (action.type === SPLIT_PERCENTAGE_SET_VALUE && getIsEvenSplit(state)) {
        store.dispatch(deselectEvenSplit());
    }

    if (action.type === EVEN_SPLIT_TOGGLE_VALUE) {
        store.dispatch(setFormValue(getEvenSplitBeneficiariesList(state)));
    }

    return result;
};

export const performBeneficiariesSplitValidation = store => {
    const splitFields = getBeneficiariesSplitForm(store.getState());
    splitFields.forEach((splitField) => {
        store.dispatch(setSplitPercentageValue(splitField.value, splitField.beneficiaryId));
    });

    const form = getBeneficiariesSplitForm(store.getState());
    store.dispatch(scrollToError(TFSA_BENEFICIARIES_SPLIT_ANCHOR_IDS, form));
};
