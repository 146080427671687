import { getDigiLifeLoanProtection } from "./index";
import { createSelector } from "reselect";
import { FORM_STATUS, TOGGLE_STATUS } from "../../reducers/status";

export const getConfirmDetails = createSelector(
    getDigiLifeLoanProtection,
    easiplusFuneralPlan => easiplusFuneralPlan.confirmDetails
);

export const getConfirmDetailsValid = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.formStatus === FORM_STATUS.VALID,
);

export const getDeclarationsValue = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarations,
);

export const getDeclarationsValid = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarations === TOGGLE_STATUS.YES,
);

export const getDeclarationsInvalid = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarations === FORM_STATUS.INVALID
);

export const getDeclarationsError = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarationsValidationMessage,
);

export const getSubmissionApiStatus = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.submitApiStatus,
);

export const getReferenceNumber = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails && confirmDetails.referenceNumber,
);


