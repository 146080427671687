import { validateNumber,validatePercent } from  "@om/validation";
import {
    setSavingsGoalValid,
    setCurrentSavingsGoalValid,
    setExpectedMonthlyContributionValid,
    setAnnualIncreaseInSavingsValid,
    setExpectedSavingsGrowthValid,
    SAVINGS_GOAL_SET_VALUE,
    CURRENT_SAVINGS_GOAL_SET_VALUE,
    EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE,
    ANNUAL_INCREASE_IN_SAVINGS_SET_VALUE,
    EXPECTED_SAVINGS_GROWTH_SET_VALUE
} from "../../../actions/savingsCalculator/whenWillYouReachYourSavingsGoal";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const whenWillYouReachYourSavingsGoalValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SAVINGS_GOAL_SET_VALUE:
                error = validateNumber(action.value, {subject: 'savings goal', max: 10000000, decimalPoints: 2});
                return setSavingsGoalValid(error);

            case CURRENT_SAVINGS_GOAL_SET_VALUE:
                error = validateNumber(action.value, {subject: 'current savings goal', max: 10000000, decimalPoints: 2});
                return setCurrentSavingsGoalValid(error);

            case EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE:
                error = validateNumber(action.value, {subject: 'expected monthly contribution', max: 10000000, decimalPoints: 2});
                return setExpectedMonthlyContributionValid(error);

            case ANNUAL_INCREASE_IN_SAVINGS_SET_VALUE:
                error = validatePercent(action.value, {subject: 'annual increase in savings'});
                return setAnnualIncreaseInSavingsValid(error);

            case EXPECTED_SAVINGS_GROWTH_SET_VALUE:
                error = validatePercent(action.value, {subject: 'expected savings growth'});
                return setExpectedSavingsGrowthValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
