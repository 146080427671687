import { getIsCoverOptionsApiPending } from '../../../selectors/personalAccident/quote';
import {
    apiTransitionCoverOptions,
    setCoverOptionsValid,
    setCoverOptionsValue,
} from '../../../actions/personalAccident/quote';
import { getAccessToken } from '../../../selectors/personalAccident/payments';
import axios from 'axios';

export const getCoverOptionsService = (store, next, action) => {
    (async () => {
        if (!getIsCoverOptionsApiPending(store.getState())) {
            store.dispatch(apiTransitionCoverOptions.pending());
            try {
                const resp = await getAllCoverOptions(store.getState());

                store.dispatch(setCoverOptionsValue(resp.data));

                store.dispatch(apiTransitionCoverOptions.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionCoverOptions.failure());
                store.dispatch(setCoverOptionsValid(e));
            }
        }
    })();

    return next(action);
};

const getAllCoverOptions = async (state) => {
    const loginData = getAccessToken(state);

    try {
        const url = '/om-api/personal-accident/get-cover-options';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
