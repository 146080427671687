import {
    TOGGLE_READ_MORE_TRANSITION_BENEFICIARIES,
    ADD_NEW_TRANSITION_BENEFICIARIES,
    TITLE_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    DATE_OF_BIRTH_SET_VALUE,
    GENDER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    SAVE_TRANSITION_BENEFICIARIES,
    CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES,
    EDIT_TRANSITION_BENEFICIARIES,
    DELETE_BENEFICIARY_POPUP_TRACKING,
    DELETE_TRANSITION_BENEFICIARIES,
    ADD_MORE_THAN_MAX_BENEFICIARIES_TRACKING,
    CONTINUE_TRANSITION_BENEFICIARIES,
    BACK_TRANSITION_BENEFICIARIES
} from "../../../actions/raOptimal/beneficiaries";
import {
    getBeneficiaryList,
    getReadMoreDrawerIsOpen,
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getGender,
    getMobileNumber,
    getEmail,
    getEditBeneficiaryFormIsValid,
    getEditBeneficiaryForm
} from "../../../selectors/raOptimal/beneficiaries";
import { simpleFieldTracking } from "../formTracking";
import { transitionTracking } from "../transitionTracking";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";
import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";

export const beneficiariesFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case TITLE_SET_VALUE:
                sendError = false;
                return getTitle;
            case FIRST_NAME_SET_VALUE:
                sendValue = false;
                return getFirstName;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurname;
            case DATE_OF_BIRTH_SET_VALUE:
                sendValue = false;
                return getDateOfBirth;
            case GENDER_SET_VALUE:
                sendValue = false;
                sendError = false;
                return getGender;
            case MOBILE_NUMBER_SET_VALUE:
                sendValue = false;
                return getMobileNumber;
            case EMAIL_SET_VALUE:
                sendValue = false;
                return getEmail;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector)

    if (field) {
        simpleFieldTracking({action, field, sendValue, sendError});
    }

    return result;
};

export const beneficiariesTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const trackingActions = [
        CONTINUE_TRANSITION_BENEFICIARIES,
        BACK_TRANSITION_BENEFICIARIES,
        DELETE_TRANSITION_BENEFICIARIES
    ];
    const beneficiariesForm = getBeneficiaryList(state);
    // capture relevant tracking data -- i.e. remove value from form fields
    const trackingData = Object.keys(beneficiariesForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: beneficiariesForm[key].status,
                error: beneficiariesForm[key].error,
            }
        }), {});

    if (trackingActions.includes(action.type))
        transitionTracking(action.type, trackingData);

    return result;
};

export const beneficiariesOtherTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case TOGGLE_READ_MORE_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("OPEN_DRAWER");
                return "readMore";
            case ADD_NEW_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("OPEN_DRAWER");
                return "newBeneficiary";
            case SAVE_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return determineSaved(prevState, state);
            case CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "benificiaryEditCancelClicked";
            case EDIT_TRANSITION_BENEFICIARIES:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "benificiaryEditClicked";
            case DELETE_BENEFICIARY_POPUP_TRACKING:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "openedDeletePopup";
            case ADD_MORE_THAN_MAX_BENEFICIARIES_TRACKING:
                otherEventIndex = findOtherEventIndex("BUTTON_CLICKED");
                return "TriedToAddMoreThanMaxBeneficiaries";
            default: return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};

const determineSaved = (prevState, state) => {
    const editBenificiaryValid = getEditBeneficiaryFormIsValid(prevState);

    if(editBenificiaryValid){
        return "beneficiarySaved"
    } else {
        const beneficiariesForm = getEditBeneficiaryForm(state);
        const beneficiariesFormWithErrors = Object.keys(beneficiariesForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: beneficiariesForm[key].status,
                error: beneficiariesForm[key].error,
            }
        }), {});
        return {type: 'invalidSave', form: {...beneficiariesFormWithErrors}};
    }
}
