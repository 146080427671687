import {
    inflationLinkedAnnuityRates,
    inflationLinkedJointAnnuityRates,
    levelAnnuityJointRates,
    levelAnnuityRates,
    livingAnnuityMax,
    livingAnnuityMin,
} from "./CalcData";

export class CalcEngine {
    static calculateLivingAnnuityLowerRange = (capital) => {
        const capitalValue = this.parseCapital(capital);
        const monthlyMinIncome = (((capitalValue * livingAnnuityMin) / 100) / 12);
        const lowerRange = (Math.ceil(monthlyMinIncome / 100) * 100);
        return lowerRange;
    }

    static calculateLivingAnnuityUpperRange = (capital) => {
        const capitalValue = this.parseCapital(capital);
        const monthlyMaxIncome = (((capitalValue * livingAnnuityMax) / 100) / 12);
        const upperRange = (Math.floor(monthlyMaxIncome / 100) * 100);
        return upperRange;
    }

    static calculateGuaranteedAnnuityLowerRange = (capital, gender, age, joint, partnerAge) => {
        const capitalValue = this.parseCapital(capital);
        const ageValue = parseInt(age);
        const partnerAgeValue = parseInt(partnerAge);

        // min age for partner is 40
        const derivedPartnerAge = parseInt(partnerAgeValue) < 40 ? 40 : parseInt(partnerAgeValue);

        // GA lower range is determined by the inflationLinkedAnnuity rate
        const inflationLinkedAnnuityRate = this.lookupInflationLinkedAnnuityRate(gender, ageValue, joint, derivedPartnerAge);

        const monthlyMinIncome = (capitalValue / inflationLinkedAnnuityRate) / 12;

        const lowerRange = (Math.ceil(monthlyMinIncome / 100) * 100);
        return lowerRange;
    }

    static calculateGuaranteedAnnuityUpperRange = (capital, gender, age, joint, partnerAge) => {
        const capitalValue = this.parseCapital(capital);
        const ageValue = parseInt(age);
        const partnerAgeValue = parseInt(partnerAge);

        // min age for partner is 40
        const derivedPartnerAge = parseInt(partnerAgeValue) < 40 ? 40 : parseInt(partnerAgeValue);

        // GA upper range is determined by the levelAnnuity rate
        const levelAnnuityRate = this.lookupLevelAnnuityRate(gender, ageValue, joint, derivedPartnerAge);
        const monthlyMaxIncome = (capitalValue / levelAnnuityRate) / 12;

        const upperRange = (Math.floor(monthlyMaxIncome / 100) * 100);
        return upperRange;
    }

    // lookup function for guaranteed annuity
    static lookupLevelAnnuityRate = (gender, age, joint, partnerAge) => {
        let lookupRow;
        let levelAnnuityRate;

        if(joint){
            //need to account for female and male as the key is female age first then male age
            switch(gender){
                case "MALE":
                    // if male then we use the partnerAge as the lookup value, Due to how calculations work assume partner to be female.
                    lookupRow = levelAnnuityJointRates.find(x => x.Age === partnerAge);
                    levelAnnuityRate = lookupRow[age];
                break;
                case "FEMALE":
                    // if female then we will use the age value to lookup as the key. Due to how calculations work assume partner to be male.
                    lookupRow = levelAnnuityJointRates.find(x => x.Age === age);
                    levelAnnuityRate = lookupRow[partnerAge];
                break;
            }
        } else {
            switch(gender){
                case "MALE":
                    levelAnnuityRate = levelAnnuityRates.male.find(x => x.Age === age).AnnuityRate;
                break;
                case "FEMALE":
                    levelAnnuityRate = levelAnnuityRates.female.find(x => x.Age === age).AnnuityRate;
                break;
            }
        }

        return levelAnnuityRate;
    }

    // lookup function for guaranteed annuity
    static lookupInflationLinkedAnnuityRate = (gender, age, joint, partnerAge) => {
        let lookupRow;
        let inflationLinkedAnnuityRate;

        if(joint){
            //need to account for female and male as the key is female age first then male age
            switch(gender){
                case "MALE":
                    // if male then we use the partnerAge as the lookup value, Due to how calculations work assume partner to be female.
                    lookupRow = inflationLinkedJointAnnuityRates.find(x => x.Age === partnerAge);
                    inflationLinkedAnnuityRate = lookupRow[age];
                break;
                case "FEMALE":
                    // if female then we will use the age value to lookup as the key. Due to how calculations work assume partner to be male.
                    lookupRow = inflationLinkedJointAnnuityRates.find(x => x.Age === age);
                    inflationLinkedAnnuityRate = lookupRow[partnerAge];
                break;
            }
        } else {
            switch(gender){
                case "MALE":
                    inflationLinkedAnnuityRate = inflationLinkedAnnuityRates.male.find(x => x.Age === age).AnnuityRate;
                break;
                case "FEMALE":
                    inflationLinkedAnnuityRate = inflationLinkedAnnuityRates.female.find(x => x.Age === age).AnnuityRate;
                break;
            }
        }

        return inflationLinkedAnnuityRate;
    }

    static parseCapital(value){

        if (typeof value === 'string' || value instanceof String)
            return parseFloat(value.replace(/ /g, ''));

        return parseFloat(value);

    }
}