import { transitionBiasharaFlexiCoverDetals } from "../../../actions/biasharaFlexi/coverDetails";
import { FIELD_STATUS } from "../../../reducers/status";
import { getBiasharaFlexiCoverDetailsForm } from "../../../selectors/biasharaFlexi/coverDetails";

export const coverDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const coverDetailsForm = getBiasharaFlexiCoverDetailsForm(store.getState());
    const coverDetailsFormValid = validateCoverDetailsFormDetails(coverDetailsForm);
    const formAction = coverDetailsFormValid
        ? transitionBiasharaFlexiCoverDetals.formValid()
        : transitionBiasharaFlexiCoverDetals.formInvalid()
    store.dispatch(formAction);
    return result
}

const validateCoverDetailsFormDetails = (formFields) => {
	return (
		formFields.employeesNumber &&
		formFields.employeesNumber.status === FIELD_STATUS.VALID &&
		formFields.benefitAmount &&
		formFields.benefitAmount.status === FIELD_STATUS.VALID
	);
};
