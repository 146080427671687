import { validateNumber } from "@om/validation";

import {
  setRetirementAmountValid,
  setMonthlyIncomeValid,
  RETIREMENT_AMOUNT_SET_VALUE,
  MONTHLY_INCOME_SET_VALUE,
} from "../../../actions/savingsPotCalculator/savingsPotCalculatorForm";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const savingsPotCalculatorFormValidation = (store, next, action) => {
  const validationAction = (() => {
    let error;
    switch (action.type) {
      case RETIREMENT_AMOUNT_SET_VALUE:
        error = validateNumber(action.value, {
          required: true,  
          subject: "retirement amount",
          min: 100,
          max: 9999999999999,
          decimalPoints: 2
        });
        return setRetirementAmountValid(error);

      case MONTHLY_INCOME_SET_VALUE:
        error = validateNumber(action.value, {
          required: true, 
          subject: "monthly income",
          min: 0,
          max: 9999999999999,
          decimalPoints: 2
        });
        return setMonthlyIncomeValid(error);
      default:
        return null;
    }
  })();
  if (
    !validationAction ||
    (validationIsEqual(validationAction, prevValidationAction) &&
      action.value.length > 1)
  ) {
    return next(action);
  }

  store.dispatch(validationAction);
  return next(action);
};
