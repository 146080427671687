import { transitionLongTermGoalCalculator } from '../../../actions/investmentCalculator/longTermGoal';

import { getLongTermGoalCalculatorForm } from '../../../selectors/investmentCalculator/longTermGoalCalculator';

import { FIELD_STATUS } from '../../../reducers/status';
import { formFieldValid } from '../status';

export const longTermGoalFormRules = (store, next, action) => {
	const result = next(action);
	const longTermGoalCalculatorForm = getLongTermGoalCalculatorForm(
		store.getState()
	);
	const longTermGoalCalculatorValid = validateFormDetails(
		longTermGoalCalculatorForm
	);

	const formAction = longTermGoalCalculatorValid
		? transitionLongTermGoalCalculator.formValid()
		: transitionLongTermGoalCalculator.formInvalid();

	store.dispatch(formAction);

	return result;
};

const validateFormDetails = (formFields) =>
	formFieldValid(formFields?.amountOnceOff)&&
	formFieldValid(formFields?.amountMonthly)&&
	formFieldValid(formFields?.investmentTenure)&&
	formFieldValid(formFields?.potentialLossPercentage)
