import { createSelector } from 'reselect';
import { getPersonalAccidentState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getNextOfKin = createSelector(getPersonalAccidentState, (state) => state.nextOfKin);

export const getNextOfKinForm = createSelector(getNextOfKin, (nextOfKin) => nextOfKin.form);

export const getDropOffDetails = createSelector(getNextOfKin, (nextOfKin) => nextOfKin.dropOffDetails);

export const getNextOfKinFormValid = createSelector(
    getNextOfKin,
    (nextOfKin) => nextOfKin.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getNextOfKin,
    (nextOfKin) => nextOfKin.formStatus === API_CALL_STATUS.PENDING
);

const makeGetFieldForm = (fieldId) => createSelector(getNextOfKinForm, (form) => form[fieldId]);

export const getRelationshipValue = makeGetFieldForm('relationship');
export const getFirstNameValue = makeGetFieldForm('firstName');
export const getLastNameValue = makeGetFieldForm('lastName');
export const getEmailValue = makeGetFieldForm('email');
export const getIdNumberValue = makeGetFieldForm('idNumber');
export const getAddressValue = makeGetFieldForm('address');
