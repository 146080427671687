import { createSelector } from 'reselect';
import { getDigitalSavingsState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getQuote = createSelector(getDigitalSavingsState, (state) => {
	return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculation = createSelector(getQuote, (quote) => quote && quote.premiumCalculation);
export const getIsOnAttitude = createSelector(getQuote, (quote) => quote && quote.isOnAttitude);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.SUCCESS);
export const getQuoteFormApiCallFailure = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.FAILURE);
export const getIsApiPending = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.PENDING);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedToCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);
export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus);
export const getUgCmbObject = createSelector(getQuote, (quote) => quote && quote.keCmbObject);
const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getIsExpiredAuth = createSelector(getQuote, (quote) => quote && quote.isTokenExpired);

export const getAttitudeValue = makeGetFormField('attitude');
export const getSavingCategoryValue = makeGetFormField('saving_category');
export const getDesiredValue = makeGetFormField('desired');
export const getFrequencyValue = makeGetFormField('frequency');
export const getLumpsumValue = makeGetFormField('lumpsum');
export const getStartDateValue = makeGetFormField('startdate');
export const getDurationValue = makeGetFormField('duration');
export const getShareEmailValue = makeGetFormField('shareEmail');
