import {
    DOB_SET_VALID,
    DOB_SET_VALUE,
    EDIT_EMPLOYEE_VALUE,
    EMPLOYEES_SET_VALUE,
    EMPLOYMENT_DATE_SET_VALID,
    EMPLOYMENT_DATE_SET_VALUE,
    FILE_UPLOADED_SET_VALUE,
    FIRST_NAME_SET_VALID,
    FIRST_NAME_SET_VALUE,
    FORM_INVALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM,
    FORM_VALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM,
    ID_PASSPORT_SET_VALID,
    ID_PASSPORT_SET_VALUE,
    KRA_PIN_NUMBER_SET_VALID,
    KRA_PIN_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALID,
    MOBILE_NUMBER_SET_VALUE,
    REINITIALIZE_EMPLOYEES,
    REINITIALIZE_FORM,
    SURNAME_SET_VALID,
    SURNAME_SET_VALUE,
} from '../../actions/biasharaFlexi/employeeDetails';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initEmployeeDetails = {
    formStatus: FORM_STATUS.INVALID,
    employees: [],
    fileCsv: null,
    form: {
        firstName: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        mobileNumber: { value: null, error: null, status: null },
        kraPinNumber: { value: null, error: null, status: null },
        dateOfBirth: { value: null, error: null, status: null },
        employmentDate: { value: null, error: null, status: null },
        idPassportNumber: { value: null, error: null, status: null },
    },
};

export default (state = initEmployeeDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case EMPLOYEES_SET_VALUE:
            return { ...state, employees: action.value };
        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));

        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MOBILE_NUMBER_SET_VALUE:
            return updateFormField('mobileNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case MOBILE_NUMBER_SET_VALID:
            return updateFormField('mobileNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SURNAME_SET_VALUE:
            return updateFormField('surname', (field) => ({
                ...field,
                value: action.value,
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case KRA_PIN_NUMBER_SET_VALUE:
            return updateFormField('kraPinNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case KRA_PIN_NUMBER_SET_VALID:
            return updateFormField('kraPinNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DOB_SET_VALUE:
            return updateFormField('dateOfBirth', (field) => ({
                ...field,
                value: action.value,
            }));
        case DOB_SET_VALID:
            return updateFormField('dateOfBirth', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMPLOYMENT_DATE_SET_VALUE:
            return updateFormField('employmentDate', (field) => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYMENT_DATE_SET_VALID:
            return updateFormField('employmentDate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_PASSPORT_SET_VALUE:
            return updateFormField('idPassportNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case ID_PASSPORT_SET_VALID:
            return updateFormField('idPassportNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EDIT_EMPLOYEE_VALUE:
            return { ...state, form: action.value };

        case FILE_UPLOADED_SET_VALUE:
            return { ...state, fileCsv: action.value };

        case REINITIALIZE_FORM:
            return { ...state, form: initEmployeeDetails.form };
        case REINITIALIZE_EMPLOYEES:
            return { ...state, employees: initEmployeeDetails.employees };

        default:
            return state;
    }
};
