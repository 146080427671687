import { combineReducers } from 'redux';
import referenceData from './referenceData';
import routing from './routing';
import travelPlan from './travelPlan';
import aboutYou from './aboutYou';
import travelDetails from './travelDetails';
import travelAgent from './travelAgentModal';
import plans from './plans';
import payments from './payment';

export const roaTravelInsurance = combineReducers({
    referenceData,
    routing,
    travelAgent,
    travelPlan,
    aboutYou,
    travelDetails,
    plans,
    payments,
});
