import { combineReducers } from 'redux';
import routing from './routing';
import quote from './quote';
import payments from './payment';
import payloadCollection from './payloadCollection';
import agent from './agent';
import personalDetails from './personalDetails';
import personalDetailsBeneficiaries from './personalDetailsBeneficiaries';
import nextOfKin from './nextOfKin';
import documents from './Documents';
import livesCovered from './livesCovered';
import medicalHistory from './medicalHistory';

export const healthInsuranceRwanda = combineReducers({
	routing,
	quote,
	personalDetails,
	personalDetailsBeneficiaries,
	payments,
	payloadCollection,
	agent,
	nextOfKin,
	documents,
	livesCovered,
	medicalHistory,
});
