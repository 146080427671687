import { transitionDigitalSavingsPersonalDetails } from '../../../actions/digitalSavings/personalDetails';
import { getPersonalDetailsForm } from '../../../selectors/digitalSavings/personalDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const personalDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const personalDetailsFormValid = validatePersonalDetailsFormDetails(personalDetailsForm);

    const formAction = personalDetailsFormValid
        ? transitionDigitalSavingsPersonalDetails.formValid()
        : transitionDigitalSavingsPersonalDetails.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
    return (
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.surname &&
        formFields.surname.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.gender &&
        formFields.gender.status === FIELD_STATUS.VALID &&
        formFields.occupation &&
        formFields.occupation.status === FIELD_STATUS.VALID &&
        formFields.nationality &&
        formFields.nationality.status === FIELD_STATUS.VALID &&
        formFields.sourceOfFunds &&
        formFields.sourceOfFunds.status === FIELD_STATUS.VALID &&
        formFields.idPassport &&
        formFields.idPassport.status === FIELD_STATUS.VALID &&
        formFields.kraPinNumber &&
        formFields.kraPinNumber.status === FIELD_STATUS.VALID &&
        formFields.mobileNumber &&
        formFields.mobileNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.fullAddress &&
        formFields.fullAddress.status === FIELD_STATUS.VALID
    );
};