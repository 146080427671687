import {
    deselectEvenSplit,
    setSplitPercentageValue,
    setTotalPercentageValue,
    SPLIT_PERCENTAGE_SET_VALUE,
    transitionBeneficiariesSplit,
} from "../../../actions/raOptimal/beneficiariesSplit";
import {
    getBeneficiariesSplitForm,
    getIsEvenSplit,
} from "../../../selectors/raOptimal/beneficiarySplit";
import {FIELD_STATUS} from "../../../reducers/status";
import {transitionScrolling} from "../../../actions/raOptimal/scrolling";

const asIntValue = val => (isNaN(val) || val < 1 || val > 100)
    ? 0
    : parseInt(val);
const isValidSplitField = (field) => field.beneficiaryId == null || field.status === FIELD_STATUS.VALID;

export const beneficiariesSplitContinueRules = (store, next, action) => {
    // state after middleware is applied
    const result = next(action);
    const state = store.getState();

    let firstErrorFieldIndex;
    const splitFormEntries = Object.entries(getBeneficiariesSplitForm(state));

    splitFormEntries.forEach(([_, field], index) => {
        if (isValidSplitField(field)) return;
        store.dispatch(setSplitPercentageValue(field.value, field.beneficiaryId));
        firstErrorFieldIndex = index + 1;
    });

    if (firstErrorFieldIndex !== undefined) {
        store.dispatch(transitionScrolling.scrollToId('percentage-field-' + firstErrorFieldIndex));
    }

    return result;
};

export const beneficiariesSplitFormRules = (store, next, action) => {
    // state after middleware is applied
    const result = next(action);
    const state = store.getState();

    // calculate and set total percentage
    const splitFormEntries = Object.entries(getBeneficiariesSplitForm(state));
    const totalPercentage = splitFormEntries.reduce((acc, [_, ben]) =>
        ben.value ? acc + asIntValue(ben.value) : acc,
        0
    );

    store.dispatch(setTotalPercentageValue(totalPercentage));

    // if even split it active and user changes a split field, deselect even split
    if (action.type === SPLIT_PERCENTAGE_SET_VALUE && getIsEvenSplit(state))
        store.dispatch(deselectEvenSplit());

    // calculate of entire form is valid
    const firstErrorField = splitFormEntries.find(([_, field]) => !isValidSplitField(field));
    const formAction = totalPercentage === 100 && !firstErrorField
        ? transitionBeneficiariesSplit.formValid()
        : transitionBeneficiariesSplit.formInvalid();

    store.dispatch(formAction);

    return result;
};
