import { CALCULATE_EMERGENCY_FUND } from '../../../actions/investmentCalculator/emergencyFund';
import { CALCULATE_BUILD_WEALTH } from '../../../actions/investmentCalculator/buildWealth';
import { CALCULATE_SHORT_TERM_GOAL } from '../../../actions/investmentCalculator/shortTermGoal';
import { CALCULATE_LONG_TERM_GOAL } from '../../../actions/investmentCalculator/longTermGoal';
import { CALCULATE_GENERATE_REGULAR_INCOME } from '../../../actions/investmentCalculator/generateRegularIncome';

import { emergencyFundCalculateService } from './emergencyFundCalculator';
import { buildWealthCalculateService } from './buildWealthCalculator';
import { shortTermGoalCalculateService } from './shortTermGoalCalculator';
import { longTermGoalCalculateService } from './longTermGoalCalculator';
import { generateRegularIncomeCalculatorService } from './generateRegularIncomeCalculator'

export const investmentCalculatorService = (store, next, action) => {
	switch (action.type) {
		case CALCULATE_EMERGENCY_FUND:
			return emergencyFundCalculateService(store, next, action);
		case CALCULATE_BUILD_WEALTH:
			return buildWealthCalculateService(store, next, action);
		case CALCULATE_SHORT_TERM_GOAL:
			return shortTermGoalCalculateService(store, next, action);
		case CALCULATE_LONG_TERM_GOAL:
			return longTermGoalCalculateService(store, next, action);
		case CALCULATE_GENERATE_REGULAR_INCOME:
			return generateRegularIncomeCalculatorService(store, next, action);
	}
	return null;
};
