export const BIASHARA_FLEXI_COVER_DETAILS_FORM =
    'biasharaFlexi/biasharaFlexiCoverDetails/';

export const BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE = BIASHARA_FLEXI_COVER_DETAILS_FORM + 'setValue/';
export const BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALID = BIASHARA_FLEXI_COVER_DETAILS_FORM + 'setValid/';

export const BIASHARA_FLEXI_PREMIUM_SUCCESS = BIASHARA_FLEXI_COVER_DETAILS_FORM + 'biasharaFlexiSuccess/';

export const FORM_VALID_BIASHARA_FLEXI_COVER_DETAILS_FORM = 'biasharaFlexi/biasharaFlexiCoverDetails/transition/formValid';
export const FORM_INVALID_BIASHARA_FLEXI_COVER_DETAILS_FORM = 'biasharaFlexi/biasharaFlexiCoverDetails/transition/formInValid';

export const REINITIALIZE = 'biasharaFlexi/biasharaFlexiCoverDetails/state/reinitialize';

// Dropoff

export const DROP_OFF_LEAD_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM = 'biasharaFlexi/biasharaFlexiCoverDetails/transition/dropOffLead';
export const dropOffLeadBiasharaFlexiCoverDetails = (value) => ({
	type: DROP_OFF_LEAD_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM,
	value,
});

export const transitionBiasharaFlexiCoverDetals = {
	formValid: () => ({
		type: FORM_VALID_BIASHARA_FLEXI_COVER_DETAILS_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_BIASHARA_FLEXI_COVER_DETAILS_FORM,
	}),
};

// API Calls actions
export const API_PENDING_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM = `${BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM = `${BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM = `${BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionBiasharaFlexiCoverDetailsForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM,
	}),
};

export const COLLECT_BIASHARA_FLEXI_COVER_DETAILS_FORM_DATA = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE + 'collectBiasharaFlexiCoverDetailsData';
export const SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_SUCCESS = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE + 'success';
export const SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_FAILURE = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE + 'failure';

export const collectBiasharaFlexiCoverDetailsData = () => ({
	type: COLLECT_BIASHARA_FLEXI_COVER_DETAILS_FORM_DATA,
});
export const submitBiasharaFlexiCoverDetailsDataSuccess = (value) => ({
	type: SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_SUCCESS,
	value,
});
export const submitBiasharaFlexiCoverDetailsFailure = (error) => ({
	type: SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_FAILURE,
	error,
});


// Form Values
export const NO_EMPLOYEES_SET_VALUE = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE + 'employeesNumber';
export const NO_EMPLOYEES_SET_VALID = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALID + 'employeesNumber';

export const BENEFIT_AMOUNT_SET_VALUE = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE + 'benefitAmount';
export const BENEFIT_AMOUNT_SET_VALID = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALID + 'benefitAmount';

export const ADD_TO_COVER_SET_VALUE = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE + 'addedToCover';
export const ADD_TO_COVER_SET_VALID = BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALID + 'addedToCover';


// action creators
export const setEmployeeNoValue = (value) => ({
	type: NO_EMPLOYEES_SET_VALUE,
	value,
});

export const setEmployeeNoValid = (error) => ({
	type: NO_EMPLOYEES_SET_VALID,
	error,
});

export const setBenefitAmountValue = (value) => ({
	type: BENEFIT_AMOUNT_SET_VALUE,
	value,
});

export const setBenefitAmountValid = (error) => ({
	type: BENEFIT_AMOUNT_SET_VALID,
	error,
});

export const reinitializeBiasharaFlexiCoverDetailsForm = () => ({
    type: REINITIALIZE,
});

export const premiumSuccess = (value) => ({
	type: SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_SUCCESS,
    value,
})

export const setAddToCoverValue = (value) => ({
	type: ADD_TO_COVER_SET_VALUE,
    value,
})

export const setAddToCoverValid = (value) => ({
	type: ADD_TO_COVER_SET_VALID,
    value,
})
