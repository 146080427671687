import { validationIsEqual } from "../status";
import {
  validateIdNumber,
  validateSingleSelection,
  validateName,
  validateContactNumber,
  validateEmail,
} from "@om/validation";
import {
  setTitleValid,
  setFirstNamesValid,
  setSurnameValid,
  TITLE_SET_VALUE,
  FIRST_NAMES_SET_VALUE,
  SURNAME_SET_VALUE,
  setContactNumberValid,
  setEmailValid,
  CONTACT_NUMBER_SET_VALUE,
  EMAIL_SET_VALUE,
  CONTACT_FOR_FEEDBACK_SET_VALUE,
  setContactForFeedbackValid,
  setContactMethodValid,
  CONTACT_METHOD_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/personalDetails";
let prevValidationAction = null;

export const personalDetailsValidation = (store, next, action) => {
  const validationAction = (() => {
    let error;
    switch (action.type) {
      case TITLE_SET_VALUE:
        error = validateSingleSelection(action.value, "Title");
        return setTitleValid(error);

      case FIRST_NAMES_SET_VALUE:
        error = validateName(action.value, { min: 2, max: 48, subject: "First name" });
        return setFirstNamesValid(error);

      case SURNAME_SET_VALUE:
        error = validateName(action.value, { min: 2, max: 64, subject: "Surname" });
        return setSurnameValid(error);

      case CONTACT_NUMBER_SET_VALUE:
        error = validateContactNumber(action.value);
        return setContactNumberValid(error);

      case EMAIL_SET_VALUE:
        error = validateEmail(action.value);
        return setEmailValid(error);

      case CONTACT_FOR_FEEDBACK_SET_VALUE:
        error = !action.value ? "Please make a selection" : null;
        return setContactForFeedbackValid(error);

      case CONTACT_METHOD_SET_VALUE:
        error = !action.value ? "Please make a selection" : null;
        return setContactMethodValid(error);

      default:
        return null;
    }
  })();

  if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
    return next(action);
  }

  store.dispatch(validationAction);
  prevValidationAction = validationAction;
  return next(action);
};
