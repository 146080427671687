import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { countriesConfig, DEFAULT_COUNTRY_CODE } from '../config/index.js';

export function validateContactNumber(
    value,
    {
        subject = 'Contact number',
        required = true,
        countryCode = DEFAULT_COUNTRY_CODE,
        leadingZeroValidation = true
    } = {}
) {
    return validateContactNumberInternal(
        value,
        {
            allowCellphones: true,
            allowLandlines: true,
            subject,
            required,
            countryCode,
            leadingZeroValidation
        }
    );
}

export function validateCellphoneNumber(
    value,
    {
        subject = 'Mobile number',
        required = true,
        countryCode = DEFAULT_COUNTRY_CODE,
    } = {}
) {
    return validateContactNumberInternal(
        value,
        {
            allowCellphones: true,
            allowLandlines: false,
            subject,
            required,
            countryCode,
        }
    );
}

export function validateLandline(
    value,
    {
        subject = 'Landline number',
        required = true,
        countryCode = DEFAULT_COUNTRY_CODE,
    } = {}
) {
    return validateContactNumberInternal(
        value,
        {
            allowCellphones: false,
            allowLandlines: true,
            subject,
            required,
            countryCode,
        }
    );
}

function validateContactNumberInternal(
    value,
    {
        allowCellphones,
        allowLandlines,
        subject,
        required,
        countryCode,
        leadingZeroValidation=true
    }
) {

    if (!value && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject);
    }

    if (!value && !required) {
        return null;
    }

    const regex = new RegExp(/^[0-9]+$/);

    let countryConfig = countriesConfig[countryCode];
    if (!countryConfig) {
        countryConfig = countriesConfig[DEFAULT_COUNTRY_CODE];
    }

    if (value.length !== countryConfig.valueLength || !regex.test(value)) {
         if (value.length == (countryConfig.valueLength - 1) && leadingZeroValidation && !value.startsWith('0') && regex.test(value)) {
            return OmValidationMessageHelper.leadingZeroMessage();
        }
        return OmValidationMessageHelper.getInvalidMessage(`${countryConfig.valueLength} digit ${subject}`);
    }

    if(!countryConfig.ignorePrefix){
        let mergedPrefixed = [];
        if (allowLandlines && countryConfig.landlines) {
            mergedPrefixed.push(...countryConfig.landlines)
        }
        if (allowCellphones && countryConfig.cellNumbers) {
            mergedPrefixed.push(...countryConfig.cellNumbers)
        }

        if (!mergedPrefixed || mergedPrefixed.length === 0) {
            const invalidConfigMessage = OmValidationMessageHelper.invalidConfigMessage('No phone number reference data for this configuration.');
            return invalidConfigMessage;
        }

        let numberPassed = false;
        for (let index = countryConfig.prefixLength; index > 0 ; index--) {
            const phoneNumberPrefix = value.substr(0, index);
            if(mergedPrefixed.includes(phoneNumberPrefix)){
                numberPassed = true;
                break;
            }
        }

        const invalidMessage = OmValidationMessageHelper.getInvalidMessage(`${countryConfig.name} ${subject.toLowerCase()}`, true);

        if (!numberPassed) {
            return invalidMessage;
        }
    }

   return null;
}
