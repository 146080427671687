import {createSelector} from "reselect";
import {
    FORM_STATUS,
    API_CALL_STATUS
} from "../../reducers/status";

export const getPriceGraph = (state => {
    return state.publicWeb.priceGraph
});

const makeGetField = fieldId => createSelector(
    getPriceGraph,
    priceGraph => priceGraph[fieldId]
);

export const getSelectedFund = makeGetField('selectedFund');
export const getStartDate = makeGetField('startDate');
export const getEndDate = makeGetField('endDate');
export const getFundsList = makeGetField('fundsList');
export const getFundData = makeGetField('fundData');

export const getPriceGraphFormValid = createSelector(
    getPriceGraph,
    priceGraph => priceGraph.formStatus === FORM_STATUS.VALID
);

export const getShowFailMsg = createSelector(
    getPriceGraph,
    priceGraph => priceGraph.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getNoFundMsg = createSelector(
    getPriceGraph,
    priceGraph => priceGraph.apiCallStatus === API_CALL_STATUS.SUCCESS 
                    && priceGraph.fundData.length < 1
                    && priceGraph.selectedFund.value 
                    && priceGraph.startDate.value 
                    && priceGraph.endDate.value
);

export const dontFireApi = createSelector(
    getPriceGraph,
    priceGraph => priceGraph.apiCallStatus === API_CALL_STATUS.PENDING
)

