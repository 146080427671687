
export class BudgetTool {
    // Sum values, get percentage of total, and round
    static getBudget = (values, percent, allowedReturnMin = 1000, allowedReturnMax = 999999999) => {

        const budgetValue = Math.round(values.reduce((a, b) => parseInt(a) + parseInt(b), 0)* (percent / 100));

        // minimum and maximum budget value allowed
        if(budgetValue < allowedReturnMin){
            return allowedReturnMin;
        } else if (budgetValue > allowedReturnMax){
            return allowedReturnMax;
        }
        return budgetValue;
    };
}
