// Events
export const ALLSURE_APPLICATION_REFERENCE_DATA =
  "allsureInsurance/applicationReferenceData/";

// Bank Reference Data
export const ALLSURE_BANK_REFERENCE_DATA_GET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+ "bankReferenceData/getValue/";
export const ALLSURE_BRANCH_CODES_REFERENCE_DATA_GET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+"branchCodeReferenceData/getValue/";

export const ALLSURE_BANK_REFERENCE_DATA_SET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+ "bankReferenceData/setValue/";
export const ALLSURE_BRANCH_CODES_REFERENCE_DATA_SET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+"branchCodeReferenceData/setValue/";

export const getBankReferenceData = () => ({  
  type: ALLSURE_BANK_REFERENCE_DATA_GET_VALUE,
});
export const getBranchCodeReferenceData = (value) => ({
  type: ALLSURE_BRANCH_CODES_REFERENCE_DATA_GET_VALUE,
  value
});
export const setBankReferenceData = (value) => ({  
  type: ALLSURE_BANK_REFERENCE_DATA_SET_VALUE,
  value
});
export const setBranchCodeReferenceData = (value) => ({
  type: ALLSURE_BRANCH_CODES_REFERENCE_DATA_SET_VALUE,
  value
});

// Vehicle Reference Data
export const ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_SET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+ "vehicleMakeReferenceData/setValue/";
export const ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_SET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+"vehicleModelReferenceData/setValue/";

export const ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_GET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+ "vehicleMakeReferenceData/getValue/";
export const ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_GET_VALUE =
ALLSURE_APPLICATION_REFERENCE_DATA+"vehicleModelReferenceData/getValue/";


export const setVehicleMakeReferenceData = (value) => ({  
  type: ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_SET_VALUE,
  value
});
export const setVehicleModelReferenceData = (value) => ({
  type: ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_SET_VALUE,
  value
});

export const getVehicleMakeReferenceData = () => ({  
  type: ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_GET_VALUE,
});
export const getVehicleModelReferenceData = (value) => ({
  type: ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_GET_VALUE,
  value
});

export const ALLSURE_APPLICATION_API_STATUS = ALLSURE_APPLICATION_REFERENCE_DATA + 'setApiStatus';

export const setAllsureApplicationApiStatus = (value) => ({
    type: ALLSURE_APPLICATION_API_STATUS,
    value
});
export const ALLSURE_APPLICATION_QUOTE_DETAILS = ALLSURE_APPLICATION_REFERENCE_DATA + 'quoteDetails';

export const setAllsureApplicationQuoteDetails = (value) => ({
    type: ALLSURE_APPLICATION_QUOTE_DETAILS,
    value
});

const ALLSURE_APPLICATION_REFERENCE_NUMBER='allsureInsurance/applicationReferenceNumber/'

export const  SET_ALLSURE_APPLICATION_REFERENCE_NUMBER=ALLSURE_APPLICATION_REFERENCE_NUMBER+'setNumber'
export const RESET_ALLSURE_APPLICATION_REFERENCE_NUMBER=ALLSURE_APPLICATION_REFERENCE_NUMBER+'resetNumber'
export const setApplicationReferenceNumber = (value) => ({
  type: SET_ALLSURE_APPLICATION_REFERENCE_NUMBER,
  value
});

export const RESET_REFERENCE_DATA =
ALLSURE_APPLICATION_REFERENCE_DATA+"referenceData/resetState";
export const resetReferenceData = () => ({ type: RESET_REFERENCE_DATA });

export const ALLSURE_APPLICATION_TOGGLE_TNC = ALLSURE_APPLICATION_REFERENCE_DATA + 't&C';

export const toggleTnC= (value) => ({
    type: ALLSURE_APPLICATION_TOGGLE_TNC,
    value
});
export const ALLSURE_APPLICATION_RECAPTCHA_VALUE = ALLSURE_APPLICATION_REFERENCE_DATA + 'recaptcha';

export const setRecaptchaValue= (value) => ({
    type: ALLSURE_APPLICATION_RECAPTCHA_VALUE,
    value
});