import {
    API_PENDING_TRANSITION_PAYLOAD_COLLECTION,
    API_SUCCESS_TRANSITION_PAYLOAD_COLLECTION,
    API_FAILURE_TRANSITION_PAYLOAD_COLLECTION,
    SUBMIT_COLLECT_PAYLOAD_SUCCESS,
    SUBMIT_COLLECT_PAYLOAD_FAILURE,
    INITIALIZE_COLLECTION,
} from '../../actions/personalPensionPlan/payloadCollection';

import { FORM_STATUS, API_CALL_STATUS } from '../status';
export const initPayload = {
    apiCallStatus: null,
    payloadData: null,
    payloadStatus: null,
};

export default (state = initPayload, action) => {
    switch (action.type) {
        // API transitions
        case API_PENDING_TRANSITION_PAYLOAD_COLLECTION:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };

        case API_SUCCESS_TRANSITION_PAYLOAD_COLLECTION:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };

        case API_FAILURE_TRANSITION_PAYLOAD_COLLECTION:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        case SUBMIT_COLLECT_PAYLOAD_SUCCESS:
            return { ...state, payloadData: action.value };

        case SUBMIT_COLLECT_PAYLOAD_FAILURE:
            return { ...state, payloadStatus: FORM_STATUS.INVALID };

        case INITIALIZE_COLLECTION:
            return { ...state, payloadData: null, apiCallStatus: null, payloadStatus: null };

        default:
            return state;
    }
};
