import {
    FUNERAL_CLAIM_API_FAILURE_TRANSITION,
    FUNERAL_CLAIM_API_PENDING_TRANSITION,
    FUNERAL_CLAIM_API_SUCCESS_TRANSITION,
    FUNERAL_CLAIM_FORM_INVALID_TRANSITION,
    FUNERAL_CLAIM_FORM_VALID_TRANSITION,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE,
    FUNERAL_CLAIM_SET_BANK_OTHER_VALID,
    FUNERAL_CLAIM_SET_BANK_OTHER_VALUE,
    FUNERAL_CLAIM_SET_BANK_VALUE,
    FUNERAL_CLAIM_SET_BRANCH_CODE_VALID,
    FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE,
    FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID,
    FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE,
    FUNERAL_CLAIM_SET_DECEASED_ID_VALID,
    FUNERAL_CLAIM_SET_DECEASED_ID_VALUE,
    FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID,
    FUNERAL_CLAIM_SET_FIRST_NAME_VALID,
    FUNERAL_CLAIM_SET_FIRST_NAME_VALUE,
    FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID,
    FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE,
    FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER,
    FUNERAL_CLAIM_SET_REQUESTER_ID_VALID,
    FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE,
    FUNERAL_CLAIM_SET_SURNAME_VALID,
    FUNERAL_CLAIM_SET_SURNAME_VALUE,
    FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS, FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER,
} from '../../actions/publicWeb/funeralClaim'

import {API_CALL_STATUS, FIELD_STATUS, FORM_STATUS,} from '../status';

const initialFuneralClaim = {
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: API_CALL_STATUS.IDLE,
    firstName: {value: null, error: null, status: null},
    surname: {value: null, error: null, status: null},
    cellphoneNumber: {value: null, error: null, status: null},
    isUserPolicyHolder: null,
    requesterIdNumber: {value: null, error: null, status: null},
    deceasedIdNumber: {value: null, error: null, status: null},
    // Funeral Date has been commented out in the client hence this has been hard coded to valid
    funeralDate: {value: null, error: null, status: FIELD_STATUS.VALID},
    bank: null,
    bankOther: {value: null, error: null, status: null},
    branchCode: {value: null, error: null, status: FIELD_STATUS.VALID},
    bankAccountNumber: {value: null, error: null, status: null},
    bankAccountType: null,
    agreeToTerms: false,
    sidebarOpen: false,
};

const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

const validUpdateInternal = (field, error) => ({
    ...field,
    error: error,
    status: mapError(error),
});

export default (state = initialFuneralClaim, action) => {
    const validUpdate = field => validUpdateInternal(field, action.error);

    const valueUpdate = field => ({
        ...field,
        value: action.value,
    });

    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId]),
    });

    switch (action.type) {
        // Form Transition
    case FUNERAL_CLAIM_FORM_VALID_TRANSITION:
        return {
            ...state,
            formStatus: FORM_STATUS.VALID,
        };
    case FUNERAL_CLAIM_FORM_INVALID_TRANSITION:
        return {
            ...state,
            formStatus: FORM_STATUS.INVALID,
        };
        // API Transition
    case FUNERAL_CLAIM_API_PENDING_TRANSITION:
        return {
            ...state,
            apiCallStatus: API_CALL_STATUS.PENDING,
        };
    case FUNERAL_CLAIM_API_SUCCESS_TRANSITION:
        return {
            ...state,
            apiCallStatus: API_CALL_STATUS.SUCCESS,
        };
    case FUNERAL_CLAIM_API_FAILURE_TRANSITION:
        return {
            ...state,
            apiCallStatus: API_CALL_STATUS.FAILURE,
        };

    case FUNERAL_CLAIM_SET_FIRST_NAME_VALUE:
        return updateFormField('firstName', valueUpdate);
    case FUNERAL_CLAIM_SET_FIRST_NAME_VALID:
        return updateFormField('firstName', validUpdate);

    case FUNERAL_CLAIM_SET_SURNAME_VALUE:
        return updateFormField('surname', valueUpdate);
    case FUNERAL_CLAIM_SET_SURNAME_VALID:
        return updateFormField('surname', validUpdate);

    case FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE:
        return updateFormField('cellphoneNumber', valueUpdate);
    case FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID:
        return updateFormField('cellphoneNumber', validUpdate);

    case FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER:
        return {
            ...state,
            isUserPolicyHolder: action.value,
        };

    case FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE:
        return updateFormField('requesterIdNumber', valueUpdate);
    case FUNERAL_CLAIM_SET_REQUESTER_ID_VALID:
        return updateFormField('requesterIdNumber', validUpdate);

    case FUNERAL_CLAIM_SET_DECEASED_ID_VALUE:
        return updateFormField('deceasedIdNumber', valueUpdate);
    case FUNERAL_CLAIM_SET_DECEASED_ID_VALID:
        return updateFormField('deceasedIdNumber', validUpdate);

    case FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID:
        return {
            ...state,
            requesterIdNumber: validUpdateInternal(
                state.requesterIdNumber,
                action.requesterError,
            ),
            deceasedIdNumber: validUpdateInternal(
                state.deceasedIdNumber,
                action.deceasedError,
            ),
        };

    case FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE:
        return updateFormField('funeralDate', valueUpdate);
    case FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID:
        return updateFormField('funeralDate', validUpdate);

    case FUNERAL_CLAIM_SET_BANK_VALUE:
        return {
            ...state,
            bank: action.value,
        };

    case FUNERAL_CLAIM_SET_BANK_OTHER_VALUE:
        return updateFormField('bankOther', valueUpdate);
    case FUNERAL_CLAIM_SET_BANK_OTHER_VALID:
        return updateFormField('bankOther', validUpdate);

    case FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE:
        return updateFormField('branchCode', valueUpdate);
    case FUNERAL_CLAIM_SET_BRANCH_CODE_VALID:
        return updateFormField('branchCode', validUpdate);

    case FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE:
        return updateFormField('bankAccountNumber', valueUpdate);
    case FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID:
        return updateFormField('bankAccountNumber', validUpdate);

    case FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE:
        return {
            ...state,
            bankAccountType: action.value,
        };

    case FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS:
        return {
            ...state,
            agreeToTerms: !state.agreeToTerms,
        };

    case FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER:
        return {
            ...state,
            sidebarOpen: action.value
        };

    default:
        return state;
    }
}
