export const SET_VALUE_ROUTING = 'roaTravelInsurance/routing/setValue/';
export const NAVIGATE_PREVIOUS = SET_VALUE_ROUTING + 'navigatePrevious';
export const NAVIGATE_NEXT = SET_VALUE_ROUTING + 'navigateNext';
export const NAVIGATE_INDEX = SET_VALUE_ROUTING + 'navigateIndex';
export const SET_NAVIGATION_DONE = SET_VALUE_ROUTING + 'navigationDone';
export const TRAVELL_INSURANCE_SWICTH_PLAN = SET_VALUE_ROUTING + 'switchPlan';
export const switchPlanNavigation = () => ({type: TRAVELL_INSURANCE_SWICTH_PLAN});
export const navigatePrevious = () => ({ type: NAVIGATE_PREVIOUS });
export const navigateNext = () => ({ type: NAVIGATE_NEXT });
export const navigateIndex = value => ({ type: NAVIGATE_INDEX, value });
export const setNavigationDone = () => ({ type: SET_NAVIGATION_DONE });

export const RESET_ROUTING = SET_VALUE_ROUTING + 'resetRouting';
export const resetRouting = () => ({ type: RESET_ROUTING });

export const SET_ACTIVE_PAGE_INDEX = SET_VALUE_ROUTING + 'activePageIndex';
export const setActivePageIndex = value => ({
    type: SET_ACTIVE_PAGE_INDEX,
    value,
});

// Per-page routing transitions
export const ROUTING_TRANSITION = 'roaTravelInsurance/routing/transition/';

export const SET_LANDING_PAGE = SET_VALUE_ROUTING + 'landingPage';
export const setLandingPage = value => ({ type: SET_LANDING_PAGE, value });

export const NAVIGATE_TO_LANDING_PAGE = ROUTING_TRANSITION + 'toLandingPage';
export const navigateToLandingPage = () => ({ type: NAVIGATE_TO_LANDING_PAGE });

export const NAVIGATE_TO_TRAVEL_PLAN = ROUTING_TRANSITION + 'toTravelPlan';
export const navigateToTravelPlan = () => ({ type: NAVIGATE_TO_TRAVEL_PLAN });

export const NAVIGATE_TO_ABOUT_YOU = ROUTING_TRANSITION + 'toAboutYou';
export const navigateToAboutYou = () => ({ type: NAVIGATE_TO_ABOUT_YOU });

export const NAVIGATE_TO_TRAVEL_DETAILS = ROUTING_TRANSITION + 'toTravelDetails';
export const navigateToTravelDetails = () => ({
    type: NAVIGATE_TO_TRAVEL_DETAILS,
});

export const NAVIGATE_TO_PLANS = ROUTING_TRANSITION + 'toPlans';
export const navigateToPlans = () => ({ type: NAVIGATE_TO_PLANS });

export const NAVIGATE_TO_PAYMENT = ROUTING_TRANSITION + 'toPayment';
export const navigateToPayment = () => ({ type: NAVIGATE_TO_PAYMENT });
