
import {
    formTransitionFuneralClaim,
} from '../../../actions/publicWeb/funeralClaim';

import {
    getFirstName,
    getSurname,
    getCellphoneNumber,
    getRequesterIdNumber,
    getDeceasedIdNumber,
    getFuneralDate,
    getBankOther,
    getBranchCode,
    getBankAccountNumber,
    getShowBankOther,
    getIsUserPolicyHolder,
} from '../../../selectors/publicWeb/funeralClaim';

import { FIELD_STATUS } from "../../../reducers/status";

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validateFuneralClaim = state => {
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const cellphoneNumber = getCellphoneNumber(state);
    const requesterIdNumber = getRequesterIdNumber(state);
    const deceasedIdNumber = getDeceasedIdNumber(state);
    const funeralDate = getFuneralDate(state);
    const bankOther = getBankOther(state);
    const branchCode = getBranchCode(state);
    const bankAccountNumber = getBankAccountNumber(state);
    const isUserPolicyHolder = getIsUserPolicyHolder(state);

    let funeralClaimValid = isValid(firstName) && isValid(surname) && isValid(cellphoneNumber) &&
        isValid(requesterIdNumber) && isValid(deceasedIdNumber) && isValid(funeralDate) &&
        isValid(bankAccountNumber) && isUserPolicyHolder !== null;

    if (getShowBankOther(state)) {
        funeralClaimValid = funeralClaimValid && isValid(bankOther);
        funeralClaimValid = funeralClaimValid && isValid(branchCode);
    }

    return funeralClaimValid;
};

export const funeralClaimRules = (store, next, action) => {
    const result = next(action);

    // Form valid
    const funeralClaimValid = validateFuneralClaim(store.getState());
    store.dispatch(funeralClaimValid
        ? formTransitionFuneralClaim.formValid()
        : formTransitionFuneralClaim.formInvalid());

    return result;
};
