import { createSelector } from 'reselect';
import { getRoaTravelInsuranceState } from './index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
    TOGGLE_STATUS,
    FIELD_STATUS,
} from '../../reducers/status';


export const getPlans = createSelector(
    getRoaTravelInsuranceState,
    state => state.plans,
);

const makeGetField = fieldName =>
    createSelector(getPlans, plans => plans[fieldName]);

//get selected form
export const getSelectedForm = makeGetField('selectedForm');

//get untouched form
export const getDisplayEmergencyContactUntouched = createSelector(
    getPlans,
    plans => plans.displayEmergencyModal === TOGGLE_STATUS.UNTOUCHED,
);

export const getSelectedPlan = createSelector(
    getPlans,
    plans => plans.form.planSelected

)

//get displayModal
export const getDisplayEmergencyContactModal = createSelector(
    getPlans,
    plans => plans.displayEmergencyModal === TOGGLE_STATUS.YES,
);



//get form
export const getPlansForm = makeGetField('form');

// get forms status
export const getPlansFormValid = createSelector(
    getPlans,
    plans => plans.formStatus === FORM_STATUS.VALID,
);

export const getPlanSelectedValid = createSelector(getPlansForm, form => {
    return form.planSelected && form.planSelected.status === FIELD_STATUS.VALID;
});

export const getPremiumSummaryDetailsValid = createSelector(
    getPlansForm,
    form => {
        return (
            form.nationalIdNumber &&
            form.nationalIdNumber.status === FIELD_STATUS.VALID &&
            form.passportNumber &&
            form.passportNumber.status === FIELD_STATUS.VALID &&
            form.nationality &&
            form.nationality.status === FIELD_STATUS.VALID &&
            form.krapin &&
            form.krapin.status === FIELD_STATUS.VALID &&
            form.countryOfResidence &&
            form.countryOfResidence.status === FIELD_STATUS.VALID
        );
    },
);

export const getEmergencyContactValid = createSelector(getPlansForm, form => {
    return (
        form.emergencyContactFirstName &&
        form.emergencyContactFirstName.status === FIELD_STATUS.VALID &&
        form.emergencyContactLastName &&
        form.emergencyContactLastName.status === FIELD_STATUS.VALID &&
        form.emergencyContactEmail &&
        form.emergencyContactEmail.status === FIELD_STATUS.VALID &&
        form.emergencyContactPhoneNumber &&
        form.emergencyContactPhoneNumber.status === FIELD_STATUS.VALID
    );
});

// get form fields
const makeGetFormField = fieldId =>
    createSelector(getPlansForm, form => form[fieldId]);

export const getPlanSelectedValue = makeGetFormField('planSelected');
export const getNationalIdNumberValue = makeGetFormField('nationalIdNumber');
export const getPassportNumberValue = makeGetFormField('passportNumber');
export const getNationalityValue = makeGetFormField('nationality');
export const getKraPin = makeGetFormField('krapin');
export const getCountryOfResidenceValue = makeGetFormField(
    'countryOfResidence',
);
export const getEmergencyContactFirstNameValue = makeGetFormField(
    'emergencyContactFirstName',
);
export const getEmergencyContactLastNameValue = makeGetFormField(
    'emergencyContactLastName',
);
export const getEmergencyContactEmailNameValue = makeGetFormField(
    'emergencyContactEmail',
);
export const getEmergencyContactPhoneNumberNameValue = makeGetFormField(
    'emergencyContactPhoneNumber',
);
