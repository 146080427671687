import {
    EMAIL_SET_VALUE,
    ORGANISATION_SET_VALUE,
    setEmailValid,
    setOrganisationValid,
} from '../../../actions/ltd/ltd';
import { validateAlphaNumericString, validateEmail } from '@om/validation';
import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const ltdValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case EMAIL_SET_VALUE:
                error = validateEmail(action.value);
                return setEmailValid(error);

            case ORGANISATION_SET_VALUE:
                error = validateAlphaNumericString(action.value, {
                    min: 1,
                    max: 64,
                    subject: 'Organisation',
                });
                return setOrganisationValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return null;
    }

    if (validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
