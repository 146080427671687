export const WHOLE_LIFE_COVER_QUOTE_TOOL = 'roaQuoteTools/wholeLifeCoverQuoteTool/';
export const WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE = WHOLE_LIFE_COVER_QUOTE_TOOL + 'setValue/';
export const WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID = WHOLE_LIFE_COVER_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_WHOLE_LIFE_COVER_QUOTE_TOOL = 'roaQuoteTools/wholeLifeCoverQuoteTool/transition/formStateValid';
export const FORM_INVALID_WHOLE_LIFE_COVER_QUOTE_TOOL = 'roaQuoteTools/wholeLifeCoverQuoteTool/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/wholeLifeCoverQuoteTool/state/reinitialize';

export const transitionWholeLifeCoverTool = {
    formValid: () => ({ type: FORM_VALID_WHOLE_LIFE_COVER_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_WHOLE_LIFE_COVER_QUOTE_TOOL }),
};

export const CANDIDATE_DOB_SET_VALUE = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'candidateDOB';
export const CANDIDATE_DOB_SET_VALID = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID + 'candidateDOB';
export const CALCULATE_INPUT_SET_VALID = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID + 'candidateDOB';
export const GENDER_SET_VALUE = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'genderValue';
export const GENDER_SET_VALID = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID + 'genderValue';
export const SMOKING_SET_VALUE = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'smokingValue';
export const SMOKING_SET_VALID = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID + 'smokingValue';
export const DISABILITY_SET_VALUE = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'disabilityValue';
export const DISABILITY_SET_VALID = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID + 'disabilityValue';
export const COVERED_AMOUNT_SET_VALUE = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'coveredAmount';
export const COVERED_AMOUNT_SET_VALID = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALID + 'coveredAmount';
export const WHICH_FORM_TO_DISPLAY = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const COLLECT_WHOLE_LIFE_COVER_DATA = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'collectWholeLifeCoverData';
export const CALCULATE_WHOLE_LIFE_COVER_DATA = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_WHOLE_LIFE_COVER_DATA_FAILURE = WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL = `${WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL = `${WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL = `${WHOLE_LIFE_COVER_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;

export const apiTransitionWholeLifeCoverQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL }),
};

export const collectWholeLifeCoverData = () => ({ type: COLLECT_WHOLE_LIFE_COVER_DATA });
export const calculateWholeLifeCoverData = (value) => ({ type: CALCULATE_WHOLE_LIFE_COVER_DATA, value});
export const collectWholeLifeCoverDataFailure = error => ({ type: CALCULATE_WHOLE_LIFE_COVER_DATA_FAILURE, error });

export const setToWholeLifeCoverQuoteInitial = () => ({ type: REINITIALIZE });
export const setCalculateInputValid = error => ({ type: CALCULATE_INPUT_SET_VALID, error });
export const setCandidateDOBValue = value => ({ type: CANDIDATE_DOB_SET_VALUE, value });
export const setCandidateDOBValid = error => ({ type: CANDIDATE_DOB_SET_VALID, error });
export const setGenderValue = value => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = error => ({ type: GENDER_SET_VALID, error });
export const setSmokingValue = value => ({ type: SMOKING_SET_VALUE, value });
export const setSmokingValid = error => ({ type: SMOKING_SET_VALID, error });
export const setDisabilityValue = value => ({ type: DISABILITY_SET_VALUE, value });
export const setDisabilityValid = error => ({ type: DISABILITY_SET_VALID, error });
export const setCoveredAmountValue = value => ({ type: COVERED_AMOUNT_SET_VALUE, value });
export const setCoveredAmountValid = error => ({ type: COVERED_AMOUNT_SET_VALID, error });
export const setWhichFormToDisplay = value => ({ type: WHICH_FORM_TO_DISPLAY , value });