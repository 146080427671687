import { COLLECT_LOGIN_DETAILS } from '../../../actions/motorPrivateRW/auth';
import { COLLECT_CAR_DETAILS_FORM_DATA, MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE } from '../../../actions/motorPrivateRW/carDetails';
import { TRIGGER_FETCH_RW_AGENTS } from '../../../actions/motorPrivateRW/motorAgentModal';
import { COLLECT_PAYLOAD_DATA } from '../../../actions/motorPrivateRW/payloadCollection';
import { CHECK_PAYMENTS_STATUS, TRIGGER_PAYMENTS_STK_PUSH } from '../../../actions/motorPrivateRW/payments';
import { TRIGGER_GET_NATIONALITIES, TRIGGER_GET_NIDA } from '../../../actions/motorPrivateRW/personalDetails';
import {
    COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    // COLLECT_SHARE_QUOTE_FORM_DATA,
    TRIGGER_GET_ALL_CAR_MAKES,
    TRIGGER_GET_ALL_CAR_MODELS,
    TRIGGER_GET_ALL_OCCUPANTS_LIMITS,
    TRIGGER_GET_ALL_VEHICLE_TYPES_LIST,
    // TRIGGER_GET_UG_VALUERS,
} from '../../../actions/motorPrivateRW/quote';
import { getAgentsService } from './agent';
import { getCarMakesService } from './carMake';
import { getCarModelsService } from './carModels';
import { validInsuranceService } from './checkValidInsurance';
import { loginService } from './login';
import { getNationalityService } from './nationalities';
import { getNidaListService } from './nida';
import { getOccupantLimitsService } from './occupantLimits';
import { payloadSubmitService } from './payloadCollection';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { premiumCalculationService } from './premiumCalculation';
import { shareQuoteService } from './shareQuote';
import { getVehicleTypesListService } from './vehicleTypes';

// import { TRIGGER_FETCH_UG_AGENTS } from '../../../actions/ugMotorInsurance/motorAgentModal';

// import { TRIGGER_PAYMENTS_STK_PUSH, CHECK_PAYMENTS_STATUS } from '../../../actions/ugMotorInsurance/payments';
// import { paymentsStkService, paymentsStkStatusService } from './payments';

// import { premiumCalculationService } from './premiumCalculation';

// import { COLLECT_PAYLOAD_DATA } from '../../../actions/ugMotorInsurance/payloadCollection';
// import { payloadSubmitService } from './payloadCollection';
// import { shareQuoteService } from './shareQuote';

// import { getCarMakesService } from './carMake';
// import { getCarModelsService } from './carModels';
// import { getValuersService } from './valuers';
// import { amlWhitelistCheckService } from './amlWhitelist';
// import { TRIGGER_AML_WHITELIST_CHECK } from '../../../actions/ugMotorInsurance/personalDetails';

export const rwMotorInsuranceCover = (store, next, action) => {
    // if (action.type.startsWith(TRIGGER_AML_WHITELIST_CHECK)) {
    //     //console.log('AML Check ...');
    //     return amlWhitelistCheckService(store, next, action);
    // }

    if (action.type.startsWith(TRIGGER_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }
    if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
        return paymentsStkStatusService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {
        return payloadSubmitService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)) {
        return shareQuoteService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_LOGIN_DETAILS)) {
        return loginService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_ALL_CAR_MAKES)) {
        return getCarMakesService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_ALL_CAR_MODELS)) {
        return getCarModelsService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_NATIONALITIES)) {
        return getNationalityService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_ALL_VEHICLE_TYPES_LIST)) {
        return getVehicleTypesListService(store, next, action);
    }


    if (action.type.startsWith(TRIGGER_GET_ALL_OCCUPANTS_LIMITS)) {
        return getOccupantLimitsService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_CAR_DETAILS_FORM_DATA)) {
        return validInsuranceService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_NIDA)) {
        return getNidaListService(store, next, action);
    }
    if (action.type.startsWith(TRIGGER_FETCH_RW_AGENTS)) {
        return getAgentsService(store, next, action);
    }

    return null;
};
