import {
    EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT,
    SPLIT_PERCENTAGE_SET_VALUE,
    TOTAL_PERCENTAGE_SET_VALUE
} from "../../../actions/easiplusFuneralPlan/beneficiariesSplit";
import {simpleFieldTracking} from "../formTracking";

export const beneficiarySplitFieldsTracking = (store, next, action) => {
    const result = next(action);
    let sendValue = true;
    let sendError = true;
    const field = (() => {
        switch (action.type) {
            case SPLIT_PERCENTAGE_SET_VALUE:
                sendError = false;
                return {status: null, error: null, value: action.value};
            case TOTAL_PERCENTAGE_SET_VALUE:
                return {status: null, error: null, value: action.value};
            case EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT:
                return {status: null, error: null, value: action.value};
            case EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT:
                return {status: null, error: null, value: action.value};
            default: return null;
        }
    })();


    if(field)
        simpleFieldTracking({action, field, sendValue, sendError});

    return result;
}