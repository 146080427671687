import { STEP_INDEX_SET_VALUE } from "../../../actions/atRetirement/wizardFooter";
import { getStepIndex } from "../../../selectors/atRetirement/wizardFooter";
import { getReferrerId } from "../../../selectors/atRetirement/intro";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const arcPageTransitionTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const state = store.getState();
    const referrerId = getReferrerId(state);

    const field = (() => {
        switch (action.type) {
            case STEP_INDEX_SET_VALUE: return getStepIndex(state).toString();
            default: return null;
        }
    })();

    if (field) {
        const pageTransitionAction = generatePageTransitionEvent(getStepIndex(prevState).toString(), field);
        otherEventTracking(pageTransitionAction, findOtherEventIndex("ARC_NAVIGATION"), "navigation", referrerId);
    }

    return result;
}

// page render tracking
export const arcRenderTransitionTracking = (action) => {
    otherEventTracking(action.type, findOtherEventIndex("ARC_PAGE_RENDER_TRACKING"), "render");
};

const generatePageTransitionEvent = (prevStepIndex, stepIndex) => {
    let page;
    if (prevStepIndex === "-1")
        page = "Intro";
    else if (prevStepIndex === "0")
        page = "AboutYou";
    else if (prevStepIndex === "1")
        page = "Solutions";
    else if (prevStepIndex === "2")
        page = "FinancialAdvice";

    const navigationAction = stepIndex > prevStepIndex ? "validContinue" : "back";

    return `atRetirement/${page}/transition/${navigationAction}`;
};
