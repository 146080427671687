import { validateFile, validateSingleSelection } from '@om/validation';
import {
    ADDRESS_PROOF_DOCUMENTS_SET_VALUE,
    BANK_STATEMENT_DOCUMENTS_SET_VALUE,
    ID_DOCUMENTS_SET_VALUE,
    INCOME_PROOF_DOCUMENTS_SET_VALUE,
    LOAN_LETTER_DOCUMENTS_SET_VALUE,
    setAddressProofDocumentsValid,
    setIdDocumentsValid,
    setIncomeProofDocumentsValid,
    setbankStatementDocumentsValid,
    setloanLetterDocumentsValid,
} from '../../../actions/digiLifeLoanProtection/uploadDocuments';
import { validationIsEqual } from '../status';
import { UPLOAD_DOCUMENTS_CONFIG, setDocumentDeclarationValid, setDocumentValid } from '../../../actions/allsureInsurance/uploadDocuments';

let prevValidationAction = null;
export const uploadDocumentsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        error = action?.value?.fileContaint
            ? validateFile(action.value.fileContaint, {
                  minInputSize: UPLOAD_DOCUMENTS_CONFIG.MIN_INPUT_SIZE,
                  minInputSizeName: UPLOAD_DOCUMENTS_CONFIG.MIN_INPUT_SIZE_NAME,
                  maxInputSize: UPLOAD_DOCUMENTS_CONFIG.MAX_INPUT_SIZE,
                  maxInputSizeName: UPLOAD_DOCUMENTS_CONFIG.MAX_INPUT_SIZE_NAME,
                  validFileTypes: UPLOAD_DOCUMENTS_CONFIG.VALID_UPLOAD_TYPES,
              })
            : null;
        if (action.value.fieldType==='declaration') {
            error = validateSingleSelection((action.value.value ? true : null), 'checkbox');
                // return setDeclarationsDrawerValidationMessage(error);
                return setDocumentDeclarationValid(error, action.value.fieldType);
            } 
        if (action.value.inputType) {
            return setDocumentValid(error, action.value.inputType);
        } 
       
        else {
            return null;
        }
        // switch (action.value.inputType) {

        //     case ID_DOCUMENTS_SET_VALUE:
        //       return setIdDocumentsValid(error);
        //     case INCOME_PROOF_DOCUMENTS_SET_VALUE:
        //       return setIncomeProofDocumentsValid(error);
        //     case ADDRESS_PROOF_DOCUMENTS_SET_VALUE:
        //       return setAddressProofDocumentsValid(error);
        //     case LOAN_LETTER_DOCUMENTS_SET_VALUE:
        //       return setloanLetterDocumentsValid(error);
        //     case BANK_STATEMENT_DOCUMENTS_SET_VALUE:
        //       return setbankStatementDocumentsValid(error);

        //       default:
        //       return null;
        //     }
    })();
    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
