import { combineReducers } from 'redux';
import routing from './routing';
import quote from './quote';
import payment from './payment';
import agent from './agent';
import personalDetails from './personalDetails';
import documents from './documents';
import beneficiaries from './beneficiaries';
import nextOfKin from './nextOfKin';
import payloadCollection from './payloadCollection';

export const personalPensionPlan = combineReducers({
    routing,
    quote,
    payment,
    agent,
    personalDetails,
    documents,
    beneficiaries,
    nextOfKin,
    payloadCollection,
});
