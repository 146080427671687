import { transitionEducationCalculatorForm } from '../../../actions/educationCalculator/calculator';
import { FIELD_STATUS } from '../../../reducers/status';
import {
    getEducationCalculator,
    getEducationCalculatorAssumption,
} from '../../../selectors/educationCalculator/educationCalculator';

export const educationCalculatorFormRules = (store, next, action) => {
    const result = next(action);

    const educationCalculatorForm = getEducationCalculator(store.getState());
    const educationCalculatorAssumpetionsModal = getEducationCalculatorAssumption(
        store.getState()
    );

    const educationCalculatorValid = validateFormDetails(
        educationCalculatorForm,
        educationCalculatorAssumpetionsModal
    );
    const formAction = educationCalculatorValid
        ? transitionEducationCalculatorForm.formValid()
        : transitionEducationCalculatorForm.formInvalid();

    store.dispatch(formAction);
    return result;
};

const validateFormDetails = (formFields, modalFields) =>
    formFields.costOfEducation &&
    formFields.costOfEducation.status === FIELD_STATUS.VALID &&
    formFields.durationOfStudies &&
    formFields.durationOfStudies.status === FIELD_STATUS.VALID &&
    formFields.numberOfYears &&
    formFields.numberOfYears.status === FIELD_STATUS.VALID &&
    (!formFields.savingAlready ||
        (formFields.savingAlready &&
            formFields.howMuchSavedAlready &&
            formFields.howMuchSavedAlready.status === FIELD_STATUS.VALID &&
            formFields.monthlyContribution &&
            formFields.monthlyContribution.status === FIELD_STATUS.VALID)) &&
    modalFields.annualIncreaseInContributions &&
    modalFields.annualIncreaseInContributions.status === FIELD_STATUS.VALID &&
    modalFields.expectedGrowthOfSavings &&
    modalFields.expectedGrowthOfSavings.status === FIELD_STATUS.VALID &&
    modalFields.expectedAnnualIncreaseInEducationCost &&
    modalFields.expectedAnnualIncreaseInEducationCost.status === FIELD_STATUS.VALID;
