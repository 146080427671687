import { PAST_PERFORMANCE_CALCULATOR_SET_VALUE } from '../../../actions/pastPerformanceCalculator/pastPerformanceCalculator';
import { pastPerformanceCalculatorValidationRules } from './calculator';

export const pastPerformanceCalculatorValidation = (store, next, action) => {

    if (action.type.startsWith(PAST_PERFORMANCE_CALCULATOR_SET_VALUE)) {
        return pastPerformanceCalculatorValidationRules(store, next, action);
    }

    return null;
};

