import {setRiskLevelValue} from "../../../actions/covidSelfAssessment/result";
import {getClinicalCriteriaForm} from "../../../selectors/covidSelfAssessment/clinicalCriteria";
import {getTravelHistoryForm} from "../../../selectors/covidSelfAssessment/travelHistory";
import {getStepIndex} from "../../../selectors/covidSelfAssessment/wizardFooter";

export const resultRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const stepIndex = getStepIndex(state);
    const clinicalCriteria = getClinicalCriteriaForm(state);
    const travelHistory = getTravelHistoryForm(state);

    if (stepIndex === 3) {
        const riskLevel = getRiskLevel({...clinicalCriteria, ...travelHistory});
        store.dispatch(setRiskLevelValue(riskLevel));
    }

    return result;
};

const getRiskLevel = formData => {
    const allSymptoms = ['highFever', 'cough', 'shortBreath', 'soreThroat'];

    const hasAnySymptoms = (symptoms) => {
        if (!symptoms) {
            return false;
        }

        if (typeof symptoms === 'string') {
            return formData[symptoms].value === 'yes';
        }

        return symptoms.some(symptom => formData[symptom].value === 'yes');
    };

    const travelledRecently = formData.travelledRecently.value === 'yes';
    const recentCovidContact = formData.recentCovidContact.value === 'yes';

    if (hasAnySymptoms(allSymptoms) && (travelledRecently || recentCovidContact)) {
        return 'high';
    } else if (!hasAnySymptoms(allSymptoms) && (travelledRecently || recentCovidContact)) {
        return 'medium-1';
    } else if (hasAnySymptoms(allSymptoms[0]) &&
               hasAnySymptoms(allSymptoms.slice(1,4)) && !travelledRecently && !recentCovidContact) {
        return 'medium-2';
    } else {
        return 'low';
    }
};
