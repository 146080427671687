// Transitions
export const FORM_VALID_TRANSITION_HOME_DETAILS = 'allsureInsurance/homeDetails/transition/formValid';
export const FORM_INVALID_TRANSITION_HOME_DETAILS = 'allsureInsurance/homeDetails/transition/formInvalid';

export const HOME_DETAILS_FORM_SET_VALUE = 'allsureInsurance/homeDetails/transition/formValue';
export const setHomeDetailFormValues = () => ({
    type: HOME_DETAILS_FORM_SET_VALUE,
});

export const transitionDeceasedDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_HOME_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_HOME_DETAILS }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_HOME_DETAILS = 'allsureInsurance/homeDetails/transition/resetState';
export const resetHomeDetails = () => ({
    type: RESET_STATE_TRANSITION_HOME_DETAILS,
});

// Events

export const ALLSURE_INSURANCE_HOME_DETAILS = 'allsureInsurance/homeDetails/';
export const HOME_DETAILS_SET_VALUE = ALLSURE_INSURANCE_HOME_DETAILS + 'setValue/';
export const HOME_DETAILS_SET_VALID = ALLSURE_INSURANCE_HOME_DETAILS + 'setValid/';



export const setHomeDetailsValue = (value) => ({ type: HOME_DETAILS_SET_VALUE, value });
export const setHomeDetailsValid = (error, fieldType) => ({ type: HOME_DETAILS_SET_VALID, error, fieldType });


export const HOME_DETAILS_SET_VALUE_WITHOUT_VALIDATION = ALLSURE_INSURANCE_HOME_DETAILS + '/withoutValidation/setValue/';
export const setHomeDetailsValueWithoutValidation = (value) => ({ type: HOME_DETAILS_SET_VALUE_WITHOUT_VALIDATION, value });


export const ADD_NEW_HOME_OWNER_VALUE = ALLSURE_INSURANCE_HOME_DETAILS + 'addNewHomeOwnerValue';
export const EDIT_HOME_OWNER_VALUE = ALLSURE_INSURANCE_HOME_DETAILS + 'editNewHomeOwnerValue';
export const DELETE_HOME_OWNER_VALUE =ALLSURE_INSURANCE_HOME_DETAILS +'deleteHomeOwnerValue'


export const UPDATE_HOME_DETAILS_AFTER_EDIT=ALLSURE_INSURANCE_HOME_DETAILS+'updateHomeDetailsAfterEdit'

export const updateHomeDetailsAfterEdit=()=>({type: UPDATE_HOME_DETAILS_AFTER_EDIT})

export const addNewHomeOwnerValue = () => ({ type: ADD_NEW_HOME_OWNER_VALUE }); 
export const editNewHomeOwnerValue = (value) => ({ type: EDIT_HOME_OWNER_VALUE ,value}); 
export const deleteHomeOwnerValue = (value) => ({ type: DELETE_HOME_OWNER_VALUE ,value}); 


//API's Actions homeowners

export const HOME_OWNERS_QUOTE_API = 'allsureProduct/homeowners/homeownersApi/';

export const HOME_OWNERS_API_SEND = HOME_OWNERS_QUOTE_API + 'send';
export const HOME_OWNERS_API_STATUS = HOME_OWNERS_QUOTE_API + 'setApiStatus';

export const sendHomeonersApiSend = () => ({ type: HOME_OWNERS_API_SEND });
export const sendHomeownersQuoteApiStatus = value => ({ type: HOME_OWNERS_API_STATUS, value });

//API's Actions homecontent

export const HOME_CONTENT_QUOTE_API = 'allsureProduct/homeowners/homeownersApi/';

export const HOME_CONTENT_API_SEND = HOME_CONTENT_QUOTE_API + 'send';
export const HOME_CONTENT_API_STATUS = HOME_CONTENT_QUOTE_API + 'setApiStatus';

export const sendContentApiSend = () => ({ type: HOME_OWNERS_API_SEND });
export const sendContentQuoteApiStatus = value => ({ type: HOME_OWNERS_API_STATUS, value });

//only for premium frequency
export const BANKING_DETAILS_SET_PREMIUM_FREQUENCY_VALUE=ALLSURE_INSURANCE_HOME_DETAILS+"setValuePremiumFrequency/";
export const BANKING_DETAILS_SET_VALUE_PREMIUM_FREQUENCY_VALID=ALLSURE_INSURANCE_HOME_DETAILS+"setValuePremiumFrequency/";

export const setPremiumforPurchase = (value) => ({type:BANKING_DETAILS_SET_PREMIUM_FREQUENCY_VALUE,value})

export const SYNC_HOME_PREMIUM_FREQUENCY_VALUE=ALLSURE_INSURANCE_HOME_DETAILS+"premiumFrequencyValue/";

export const syncPremiumvalue = (value) => ({type:SYNC_HOME_PREMIUM_FREQUENCY_VALUE,value})