import {
    clearPoBoxValue,
    clearPoCountryValue,
    clearPoPostalCodeValue,
    clearPoPostOfficeValue,
    clearPostalCityValue,
    clearPostalComplexValue,
    clearPostalCountryValue,
    clearPostalPostalCodeValue,
    clearPostalStreetAddressValue,
    clearPostalSuburbValue,
    clearPrivateBagValue,
    clearPrivateCountryValue,
    clearPrivatePostalCodeValue,
    clearPrivatePostOfficeValue,
    setPostalAddressMethodValue,
    setPostalCityValue,
    setPostalComplexValue,
    setPostalCountryValue,
    setPostalPostalCodeValue,
    setPostalStreetAddressValue,
    setPostalSuburbValue,
    setGCSStreetAddressValue,
    setGCSPostalAddressValue,
    transitionPersonalDetails,
    POSTAL_ADDRESS_SAME_VALUE,
    setTitleValue,
    setFirstNamesValue,
    setSurnameValue,
    setIdValue,
    setMobileValue,
    setEmailValue,
    setComplexValue,
    setStreetAddressValue,
    setSuburbValue,
    setCityValue,
    setPostalCodeValue,
    setCountryValue,
    setPoBoxValue,
    setPoPostOfficeValue,
    setPoPostalCodeValue,
    setPoCountryValue,
    setPrivateBagValue,
    setPrivatePostOfficeValue,
    setPrivatePostalCodeValue,
    setPrivateCountryValue,
} from "../../../actions/easiplusFuneralPlan/personalDetails";
import { setAccountValidationReset } from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { FIELD_STATUS, POSTAL_ADDRESS_METHOD, TOGGLE_STATUS } from "../../../reducers/status";
import {
    getPersonalDetailsForm,
    getPostalAddressMethod,
    getPersonalDetailsValid,
} from "../../../selectors/easiplusFuneralPlan/personalDetails";
import {
    FIRST_NAMES_SET_VALID,
    SURNAME_SET_VALID,
    MOBILE_SET_VALUE,
    MOBILE_SET_VALID,
    ID_SET_VALID
} from "../../../actions/easiplusFuneralPlan/personalDetails";
import { EASIPLUS_PERSONAL_DETAILS_SCROLL_ID } from "../status";
import { transitionScrolling } from "../../../actions/easiplusFuneralPlan/scrolling";
import { formatGCSStreetAddressSouthAfrica, formatGCSPostalAddressSouthAfrica } from "../sharedRules/gcs";

export const personalDetailsRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const updatePersonalDetails = [
        FIRST_NAMES_SET_VALID,
        SURNAME_SET_VALID,
        MOBILE_SET_VALUE,
        MOBILE_SET_VALID,
    ];

    if (action.type === POSTAL_ADDRESS_SAME_VALUE) {
        changePostalAddressMethod(store);
    }

    // Change name or contact number once progressed to next page
    const personalDetailsValid = getPersonalDetailsValid(store.getState());
    if (updatePersonalDetails.includes(action.type) && personalDetailsValid) {
        store.dispatch(setAccountValidationReset());
    }

    // If the user has reset their ID number, reset banking validation
    if (action.type === ID_SET_VALID) {
        store.dispatch(setAccountValidationReset());
    }

    // Personal Information
    const isValid = isPersonalDetailsValid(store);
    store.dispatch(isValid
        ? transitionPersonalDetails.formValid()
        : transitionPersonalDetails.formInvalid());

    // GCS format
    formatAndStoreGCSAddress(store);
    return result;
};

// Personal Information
const isPersonalDetailsValid = store => {
    const state = store.getState();
    const formFields = getPersonalDetailsForm(state);
    const personalDetailsPostalAddressMethod = getPostalAddressMethod(state);
    const personalDetailsPostalAddressSame = formFields.postalAddressSame.value;
    const isValid = field => field && field.status === FIELD_STATUS.VALID;

    // personal details
    const personalDetailsFormFields = [
        formFields.title,
        formFields.firstNames,
        formFields.surname,
        formFields.id,

        formFields.mobileNumber,
        formFields.email,

        formFields.streetAddress,
        formFields.suburb,
        formFields.city,
        formFields.postalCode,
        formFields.country,
    ];
    const personalDetailsValid = personalDetailsFormFields.every(isValid)

    // postal address
    let postalAddressValid = personalDetailsPostalAddressSame === TOGGLE_STATUS.YES;
    if (!postalAddressValid) {
        const postalAddrValue = personalDetailsPostalAddressMethod && personalDetailsPostalAddressMethod.value;
        const postalAddrFormFields = (() => {
            switch (postalAddrValue) {
                case POSTAL_ADDRESS_METHOD.PO_BOX: return [
                    formFields.poBox,
                    formFields.poPostalCode,
                    formFields.poPostOffice,
                    formFields.poCountry,
                ];
                case POSTAL_ADDRESS_METHOD.PRIVATE_BAG: return [
                    formFields.privateBag,
                    formFields.privatePostalCode,
                    formFields.privatePostOffice,
                    formFields.privateCountry,
                ];
                case POSTAL_ADDRESS_METHOD.STREET_ADDRESS: return [
                    formFields.postalComplex,
                    formFields.postalStreetAddress,
                    formFields.postalSuburb,
                    formFields.postalCity,
                    formFields.postalPostalCode,
                    formFields.postalCountry,
                ];
                default: return [{status: FIELD_STATUS.ERROR}];
            }
        })();

        postalAddressValid = postalAddrFormFields.every(isValid)
    }

    return personalDetailsValid && postalAddressValid;
}

export const changePostalAddressMethod = store => {
    store.dispatch(clearPoBoxValue(null));
    store.dispatch(clearPoPostOfficeValue(null));
    store.dispatch(clearPoPostalCodeValue(null));
    store.dispatch(clearPoCountryValue(null));
    store.dispatch(clearPrivateBagValue(null));

    store.dispatch(clearPrivatePostOfficeValue(null));
    store.dispatch(clearPrivatePostalCodeValue(null));
    store.dispatch(clearPrivateCountryValue(null));
    store.dispatch(clearPostalComplexValue(null));

    store.dispatch(clearPostalStreetAddressValue(null));
    store.dispatch(clearPostalSuburbValue(null));
    store.dispatch(clearPostalCityValue(null));
    store.dispatch(clearPostalPostalCodeValue(null));
    store.dispatch(clearPostalCountryValue(null));
};

const formatAndStoreGCSAddress = store => {
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const {postalAddressSame} = personalDetailsForm;

    // Dispatch Street Address - formatted based on country
    const gcsStreetAddress = formatGCSStreetAddressSouthAfrica(personalDetailsForm);
    store.dispatch(setGCSStreetAddressValue(gcsStreetAddress));

    if (postalAddressSame.value) {
        const gcsPostalAddress = formatGCSPostalAddressSouthAfrica(personalDetailsForm);
        store.dispatch(setGCSPostalAddressValue(gcsPostalAddress));
        return;
    }

    const gcsPostalAddress = formatGCSPostalAddressSouthAfrica(personalDetailsForm);
    store.dispatch(setGCSPostalAddressValue(gcsPostalAddress));
};

export const performPersonalDetailsValidation = (store) => {
    const state = store.getState();

    const personalDetailsForm = getPersonalDetailsForm(state);
    const personalDetailsPostalAddressMethod = getPostalAddressMethod(state);
    const postalAddressSame = personalDetailsForm.postalAddressSame.value;

    store.dispatch(setTitleValue(personalDetailsForm.title.value));

    store.dispatch(setFirstNamesValue(personalDetailsForm.firstNames.value));
    store.dispatch(setSurnameValue(personalDetailsForm.surname.value));

    store.dispatch(setIdValue(personalDetailsForm.id.value));

    store.dispatch(setMobileValue(personalDetailsForm.mobileNumber.value));
    store.dispatch(setEmailValue(personalDetailsForm.email.value));

    store.dispatch(setPostalAddressMethodValue(personalDetailsForm.postalAddressMethod.value));
    store.dispatch(setComplexValue(personalDetailsForm.complex.value));
    store.dispatch(setStreetAddressValue(personalDetailsForm.streetAddress.value));
    store.dispatch(setSuburbValue(personalDetailsForm.suburb.value));
    store.dispatch(setCityValue(personalDetailsForm.city.value));
    store.dispatch(setPostalCodeValue(personalDetailsForm.postalCode.value));
    store.dispatch(setCountryValue(personalDetailsForm.country.value));

    if (postalAddressSame === TOGGLE_STATUS.NO) {
        switch (personalDetailsPostalAddressMethod.value) {
            case POSTAL_ADDRESS_METHOD.PO_BOX:
                store.dispatch(setPoBoxValue(personalDetailsForm.poBox.value));
                store.dispatch(setPoPostOfficeValue(personalDetailsForm.poPostOffice.value));
                store.dispatch(setPoPostalCodeValue(personalDetailsForm.poPostalCode.value));
                store.dispatch(setPoCountryValue(personalDetailsForm.poCountry.value));
                break;
            case POSTAL_ADDRESS_METHOD.PRIVATE_BAG:
                store.dispatch(setPrivateBagValue(personalDetailsForm.privateBag.value));
                store.dispatch(setPrivatePostOfficeValue(personalDetailsForm.privatePostOffice.value));
                store.dispatch(setPrivatePostalCodeValue(personalDetailsForm.privatePostalCode.value));
                store.dispatch(setPrivateCountryValue(personalDetailsForm.privateCountry.value));
                break;
            case POSTAL_ADDRESS_METHOD.STREET_ADDRESS:
                store.dispatch(setPostalComplexValue(personalDetailsForm.postalComplex.value));
                store.dispatch(setPostalStreetAddressValue(personalDetailsForm.postalStreetAddress.value));
                store.dispatch(setPostalSuburbValue(personalDetailsForm.postalSuburb.value));
                store.dispatch(setPostalCityValue(personalDetailsForm.postalCity.value));
                store.dispatch(setPostalPostalCodeValue(personalDetailsForm.postalPostalCode.value));
                store.dispatch(setPostalCountryValue(personalDetailsForm.postalCountry.value));
                break;
            case POSTAL_ADDRESS_METHOD.UNTOUCHED:
                store.dispatch(setPostalAddressMethodValue(null));
                break;
        }
    }

    setScrollError(store);
};

export const setScrollError = store => {
    const state = store.getState();
    const fieldKeyArr = EASIPLUS_PERSONAL_DETAILS_SCROLL_ID;
    const personalDetailsForm = getPersonalDetailsForm(state);
    const firstErrorField = fieldKeyArr.find(fieldKey =>
        fieldKey in personalDetailsForm && personalDetailsForm[fieldKey].error
    );

    store.dispatch(transitionScrolling.scrollToId(firstErrorField));
};