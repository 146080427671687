import {
    setTitleValue,
    setFirstNameValue,
    setSurnameValue,
    setGenderValue,
    setDateOfBirthValue,
    setEmailValue,
    setMobileValue,
    toggleEditDrawer,
    setEditBeneficiaryForm,
    updateBeneficiariesList,
    BENEFICIARY_DELETE,
    BENEFICIARIES_LIST_UPDATE,
    SIDE_DRAWER_SAVE,
    EDIT_ID_SET
} from "../../../actions/taxFreeSavingsAccount/beneficiaries";
import {
    getEmail,
    getMobileNumber,
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getGender,
    getIsBeneficiaryValid,
    getEditBeneficiaryForm,
    getBeneficiaryList,
    getUpdatedBeneficiaryListAfterRemoving,
    getUpdatedBeneficiaryListAfterSaving
} from "../../../selectors/taxFreeSavingsAccount/beneficiaries";
import { TFSA_BENEFICIARIES_SCROLL_ID } from "../status";
import { scrollToError } from "../../../actions/taxFreeSavingsAccount/scrolling";
import { deselectEvenSplit, setFormValue } from "../../../actions/taxFreeSavingsAccount/beneficiariesSplit";
import { getInitialBeneficiarySplitList } from "../../../selectors/taxFreeSavingsAccount/beneficiariesSplit";

export const beneficiariesRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    switch (action.type) {
        case SIDE_DRAWER_SAVE:
            saveSideDrawer(store, state);
            break;

        case BENEFICIARY_DELETE:
            store.dispatch(updateBeneficiariesList(getUpdatedBeneficiaryListAfterRemoving(state)));
            break;

        case EDIT_ID_SET:
            prepopulateEditBeneficiaryForm(store, action);
            break;
        
        case BENEFICIARIES_LIST_UPDATE:
            store.dispatch(setFormValue(getInitialBeneficiarySplitList(state)));
            store.dispatch(deselectEvenSplit());
            break;
    }

    return result;
};

const prepopulateEditBeneficiaryForm = (store, action) => {
    const state = store.getState();
    const beneficiaryList = getBeneficiaryList(state);
    const form = beneficiaryList.find(ben => ben.id === action.value);

    if (form) {
        store.dispatch(setEditBeneficiaryForm(form.value));
    }
};

const validateBeneficiariesSideDrawer = (store, state) => {
    store.dispatch(setTitleValue(getTitle(state).value));
    store.dispatch(setFirstNameValue(getFirstName(state).value));
    store.dispatch(setSurnameValue(getSurname(state).value));
    store.dispatch(setDateOfBirthValue(getDateOfBirth(state).value));
    store.dispatch(setGenderValue(getGender(state).value));
    store.dispatch(setEmailValue(getEmail(state).value));
    store.dispatch(setMobileValue(getMobileNumber(state).value));

    const form = getEditBeneficiaryForm(store.getState())
    store.dispatch(scrollToError(TFSA_BENEFICIARIES_SCROLL_ID, form));
}

const saveSideDrawer = (store, state) => {
    const sideDrawerIsValid = getIsBeneficiaryValid(state);
    if (!sideDrawerIsValid) {
        validateBeneficiariesSideDrawer(store, state);
        return;
    }

    const updatedBeneficiariesList = getUpdatedBeneficiaryListAfterSaving(state);
    store.dispatch(toggleEditDrawer());
    store.dispatch(updateBeneficiariesList(updatedBeneficiariesList));
};
