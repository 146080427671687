import {
  getIsQuoteReferenceDataValid,
  getIsApplicationReferenceDataValid,
} from "../../../selectors/easiplusFuneralPlan/referenceData";
import {
  setApplicationReferenceData,
  DLP_APPLICATION_REFERENCE_DATA_GET_VALUE,
  DLP_BANK_REFERENCE_DATA_GET_VALUE,
  DLP_BRANCH_CODES_REFERENCE_DATA_GET_VALUE,
  setBankReferenceData,
  setBranchCodeReferenceData,
  setBranchCodesApiStatus,
} from "../../../actions/digiLifeLoanProtection/referenceData";
import { toggleQuoteCallMeBack } from "@om/redux/actions/easiplusFuneralPlan/callMeBack";
import {
  setSideDrawerClosed,
  SIDEDRAWER_SET_VALUE,
} from "@om/redux/actions/easiplusFuneralPlan/quote";
import { EFP_ADD_COVER_DRAWERS } from "@om/redux/actions/easiplusFuneralPlan/types";
import axios from "axios";
import { setRequestId } from "../../../actions/easiplusFuneralPlan/sessionCamVariables";
import { API_CALL_STATUS } from "../../../reducers/status";

export const referenceDataService = (store, next, action) => {
  const result = next(action);
  if (action.type === DLP_APPLICATION_REFERENCE_DATA_GET_VALUE) {

    (async () => {
      const applicationReferenceData = await fetchApplicationReferenceData();
      if (applicationReferenceData) {
        store.dispatch(
          setApplicationReferenceData(applicationReferenceData.data)
        );
      }

      if (
        applicationReferenceData &&
        "data" in applicationReferenceData &&
        "requestId" in applicationReferenceData.data
      ) {
        store.dispatch(setRequestId(applicationReferenceData.data.requestId));
      }
    })();
  }

  if(action.type===DLP_BANK_REFERENCE_DATA_GET_VALUE){
    (async () => {
      const bankReferenceData = await fetchBankReferenceData();
      if (bankReferenceData) {
        store.dispatch(
          setBankReferenceData(bankReferenceData.data)
        );

      }
    })();
  }
  if(action.type===DLP_BRANCH_CODES_REFERENCE_DATA_GET_VALUE){
    (async () => {
       store.dispatch(setBranchCodesApiStatus(API_CALL_STATUS.PENDING))
      const branchCodesReferenceData = await fetchBranchCodesReferenceData(action.value);
      if (branchCodesReferenceData) {
        store.dispatch(
          setBranchCodeReferenceData(branchCodesReferenceData.data)
        );
        store.dispatch(setBranchCodesApiStatus(API_CALL_STATUS.SUCCESS))
      }
    })();
}

  if (action.type === SIDEDRAWER_SET_VALUE) {
    checkIfReferenceDataLoaded(store);
  }

  return result;
};

// Checks if reference data is loaded within 10 seconds
// if it is not then close all side drawers and display call me back
export const checkIfReferenceDataLoaded = async (store) => {
  await new Promise((resolve) => setTimeout(resolve, 10000));
  const refDataLoaded =
    getIsQuoteReferenceDataValid(store.getState()) &&
    getIsApplicationReferenceDataValid(store.getState());

  if (!refDataLoaded) {
    store.dispatch(setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.LIVES_COVERED));
    store.dispatch(setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.DIRECT_FAMILY));
    store.dispatch(
      setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.PARENTS_AND_IN_LAWS)
    );
    store.dispatch(setSideDrawerClosed(EFP_ADD_COVER_DRAWERS.EXTENDED_FAMILY));
    store.dispatch(toggleQuoteCallMeBack());
  }
};

export const fetchApplicationReferenceData = async () => {
  const mockData = {
    data: {
      titles: [
        {
          code: "Mr",
          description: "Mr",
          displayOrder: 1,
        },
        {
          code: "Mrs",
          description: "Mrs",
          displayOrder: 2,
        },
        {
          code: "Miss",
          description: "Miss",
          displayOrder: 3,
        },
      ],
      banks: [
        {
          code: "01",
          description: "ABSA Bank of Botswana",
        },
        {
          code: "02",
          description: "Access Bank of Botswana",
        },
        {
          code: "03",
          description: "Bank of Baroda (Botswana)",
        },
        {
          code: "04",
          description: "ABSA Bank of Botswana",
        },
        {
          code: "05",
          description: "Bank of India ",
        },
        {
          code: "06",
          description: "First National Bank of Botswana ",
        },
        {
          code: "07",
          description: "Stanbic Bank Botswana  ",
        },
        {
          code: "08",
          description: "First Capital Bank  ",
        },
      ],
    },
  };
  try {
    // const response = await axios.get(
    //   "/om-api/easiplus-funeral-plan/application-reference-data",
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   }
    // );
    return mockData;
  } catch (error) {
    return null;
  }
};

export const fetchQuoteReferenceData = async () => {
  try {
    const response = await axios.get(
      "/om-api/easiplus-funeral-plan/quote-reference-data",
      { headers: { "Content-Type": "application/json" } }
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const fetchBankReferenceData = async () => {
  try {
    const response = await axios.post(
      "/om-api/roa-quote-tools/banks",
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (error) {
    return null;
  }
};
export const fetchBranchCodesReferenceData = async (bankCode) => {
  try {
    const response = await axios({
     url: "/om-api/roa-quote-tools/bank-branches",
      method:'post',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "null",
        },
      // },
      data: {
        bank_code: bankCode, // This is the body part
      }
  });
    return response;
  } catch (error) {
    return null;
  }
}
