import { SCHEDULE_FILE_FOR_UPLOAD, REMOVE_FILE_FROM_UPLOAD, CANCEL_FILE_UPLOAD_IN_PROGRESS, RETRY_FILE_UPLOAD, FILE_UPLOAD_VALIDATION, FILE_UPLOAD_ERROR, DOCUMENT_MODAL } from './types';

export function scheduleFileForUpload(sectionName, file) {
    return {
        type: SCHEDULE_FILE_FOR_UPLOAD,
        sectionName,
        file
    };
}

export function removeFileFromUpload(sectionName) {
    return {
        type: REMOVE_FILE_FROM_UPLOAD,
        sectionName
    };
}

export function cancelFileUpload(sectionName) {
    return {
        type: CANCEL_FILE_UPLOAD_IN_PROGRESS,
        sectionName
    };
}

export function retryFileUpload(sectionName) {
    return {
        type: RETRY_FILE_UPLOAD,
        sectionName
    };
}

export function setValidationResults(results) {
    return {
        type: FILE_UPLOAD_VALIDATION,
        results
    };
}

export function setUploadFilesError(files){
    return {
        type: FILE_UPLOAD_ERROR,
        files
    }
}

export function setDocumentModal(boolean){
    return {
        type: DOCUMENT_MODAL,
        boolean
    }
}
