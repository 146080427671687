import {transitionBiographicInfo} from "../../../actions/covidSelfAssessment/biographicInfo";
import {getBiographicInfoForm} from "../../../selectors/covidSelfAssessment/biographicInfo";
import {transitionWizardFooter} from "../../../actions/covidSelfAssessment/wizardFooter";
import {FIELD_STATUS} from "../../../reducers/status";

export const biographicInfoRules = (store, next, action) => {
    const result = next(action);
    const biographicInfoForm = getBiographicInfoForm(store.getState());
    const biographicInfoValid = validateBiographicInfo(biographicInfoForm);

    // Biographic Info Form
    if (biographicInfoValid) {
        store.dispatch(transitionBiographicInfo.formValid());
        store.dispatch(transitionWizardFooter.continueButtonDisabled(false));
    } else {
        store.dispatch(transitionBiographicInfo.formInvalid());
        store.dispatch(transitionWizardFooter.continueButtonDisabled(true));
    }

    return result;
};

// Biographic Info
const validateBiographicInfo = formFields => {
    return formFields.age && formFields.age.status === FIELD_STATUS.VALID &&
    formFields.gender && formFields.gender.status === FIELD_STATUS.VALID &&
    formFields.postalCode && formFields.postalCode.status === FIELD_STATUS.VALID
};
