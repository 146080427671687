import { LOAD_DATA_TRANSITION_REFERENCE_DATA } from "../../../actions/taxFreeSavingsAccount/referenceData";
import { LOAD_DATA_TRANSITION_FUND_DATA } from "../../../actions/taxFreeSavingsAccount/fundData";
import { APPLICATION_SUBMISSION_TRANSITION_REVIEW } from "../../../actions/taxFreeSavingsAccount/review";
import { TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS } from "../../../actions/taxFreeSavingsAccount/paymentDetails";
import { NAVIGATE_NEXT } from "../../../actions/taxFreeSavingsAccount/routing";
import { getIsPersonalDetailsPage } from "../../../selectors/taxFreeSavingsAccount/routing";

import { referenceDataService } from "./referenceData";
import { dropOffLeadsService } from "./dropOffLeads";
import { fundDataService } from "./fundData";
import { performAccountValidation } from "./accountValidation";
import { submitTfsaApplicationService } from "./submitApplication";

export const taxFreeSavingsAccountServices = (store, next, action) => {
    if (action.type === NAVIGATE_NEXT && getIsPersonalDetailsPage(store.getState()))
        return dropOffLeadsService(store, next, action);

    if (action.type === LOAD_DATA_TRANSITION_FUND_DATA) 
        return fundDataService(store, next, action);

    if (action.type === LOAD_DATA_TRANSITION_REFERENCE_DATA) 
        return referenceDataService(store, next, action);

    if (action.type.startsWith(APPLICATION_SUBMISSION_TRANSITION_REVIEW))
        return submitTfsaApplicationService(store, next, action);

    if (action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS)
        return performAccountValidation(store, next, action);

    return null;
}
