import { validationIsEqual, validationActionTypeisEqual } from '../status';
import {
    validateAlphaNumericString,
    validateStreetWithNumber,
    validateEmail,
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateDate,
    validateNumber,
    validateLoanTerm,
    validatePassportNumber,
    validatePostalAddress,
    validateAlphaString,
    validateAccountNumber,
    validateNumericString,
    validateDateOfBirth,
} from '@om/validation';

import { validateBwIdNumber } from '@om/validation/src/validators/bw-id-number-validator';
import { setPersonalDetailsFieldValid } from '../../../actions/allsureInsurance/personalDetails';
import { setMotorDetailsFieldValid } from '../../../actions/allsureInsurance/motorDetails';
import { validateAlphaNumericStringAllowingOnlyNumbers, validateAlphaNumericStringLenient, validateGenericString } from '@om/validation/src/validators/string-validator';
import { validateDateOfBirthVTwo } from '@om/validation/src/validators/date-of-birth-validator';

let prevValidationAction = null;

export const motorDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        const { dateOfBirth } = store.getState().allsureInsurance.motorDetails.form;
        let error;

        switch (action.value.fieldType) {
            case 'vehicleLocation':
                error = validateSingleSelection(action.value.value, 'Vehicle location');
                break;
            case 'motorInsuranceType':
                error = validateSingleSelection(action.value.value, 'Cover type');
                break;

            case 'vehicleMake':
                error = validateSingleSelection(action.value.value, 'Vehicle make');
                break;
                case 'mentionedVehicleMake':
                    error = validateAlphaNumericStringAllowingOnlyNumbers(action.value.value, {
                        min: 2,
                        max: 66,
                        subject: 'Vehicle make',
                    });
                    break;

            case 'mentionedVehicleModel':
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value.value, {
                    min: 2,
                    max: 66,
                    subject: 'Vehicle model',
                });
                break;
           

            case 'vehicleModel':
                error = validateSingleSelection(action.value.value, 'Vehicle model');
                break;

            case 'vehicleYear':
                error = validateNumericString(action.value.value, {
                    max: 4,
                    min: 4,
                    subject: 'Vehicle year',
                    required: true,
                    decimalPoints:0,
                })?validateNumericString(action.value.value, {
                    max: 4,
                    min: 4,
                    subject: 'Vehicle year',
                    required: true,
                    decimalPoints:0,
                }): validateNumber(action.value.value, {
                    max: new Date().getFullYear(),
                    min:1900,
                    subject: 'Vehicle year',
                    required: true,
                    decimalPoints:0,
                });
                // error = validateNumber(action.value.value, {
                //     max: new Date().getFullYear(),
                //     min:1900,
                //     subject: 'Vehicle year',
                //     required: true,
                //     decimalPoints:0,
                // });
                break;

            case 'coverStartDate':
                error = validateDate(action.value.value, 'Vehicle year');
                break;

            case 'trackingDevice':
                error = validateSingleSelection(action.value.value, 'Tracking device details');
                break;

            case 'isImported':
                error = validateSingleSelection(action.value.value, 'Import status');
                break;

            case 'fuelType':
                error = validateSingleSelection(action.value.value, 'Fuel type');
                break;

            case 'lossSuffered':
                error = validateSingleSelection(action.value.value, 'Loss suffered');
                break;
            case 'claimAmount':
                error = validateNumber(action.value.value, { min: 1, decimalPoints: 2 });
                break;

            case 'claimedCount':
                error = validateNumber(action.value.value, { min: 1, decimalPoints: 0 });
                break;
            case 'vehicleUse':
                error = validateSingleSelection(action.value.value, 'Vehicle use');
                break;
            case 'insuredDetails':
                error = validateSingleSelection(action.value.value,'number of times insured');
                break;
             case 'insuredWithOM':
                error = validateSingleSelection(action.value.value,'number of times with om');
                break;
            case 'policyNumber':
                error=validateNumericString(action.value.value, { min: 5,max:10,subject:'Policy number'});
                break;
            case 'engineCapacity':
                error = validateNumber(action.value.value, {
                    min: 1,
                    decimalPoints: 0,
                    subject: 'Engine capacity',
                });
                break;
            case 'dateOfBirth':
                error = validateDateOfBirthVTwo(action.value.value, {
                    subject: 'Date of birth',
                    required: true,
                    min: 18,
                });
                break;

            case 'gender':
                error = validateSingleSelection(action.value.value, 'Gender');
                break;

            case 'dateOfLicence':
                error = dateOfBirth.value
                    ? validateNumber(action.value.value, {
                          max: new Date().getFullYear(),
                          min: +dateOfBirth.value.split('/')[2] + 18,
                          subject: 'Year of license',
                          required: true,
                          decimalPoints:0
                      })
                    : 'Please select the date of birth first.';
                break;

            case 'licenceCode':
                error = validateSingleSelection(action.value.value, 'License Class');
                break;

            case 'maritalStatus':
                error = validateSingleSelection(action.value.value, 'Marital Status');
                break;

            case 'vehicleSumAssured':
                error = validateNumber(action.value.value, {
                    min: 15000,
                    decimalPoints: 0,
                    subject: 'sum assured',
                });
                break;

            default:
                return null;
        }

        return setMotorDetailsFieldValid(error, action.value.fieldType);
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
