export const PERFORM_SEARCH = 'publicWeb/search/perform';
export const PERFORM_AUTO_COMPLETE = 'publicWeb/search/autoComplete';
export const PERFORM_FREQUENT = 'publicWeb/search/frequent';
export const SEARCH_RESET_STORE = 'publicWeb/search/resetStore';
export const PERFORM_QUICKLINKS = 'publicWeb/search/quicklinks';
export const PERFORM_QUERY_LOG = 'publicWeb/search/queryLog';
export const PERFORM_CLICK_LOG = 'publicWeb/search/clickLog';

export const resetStore = value => ({type: SEARCH_RESET_STORE, value});
export const performSearch = (shouldSetCategory = true) => ({ type: PERFORM_SEARCH, shouldSetCategory });
export const performAutoComplete = () => ({ type: PERFORM_AUTO_COMPLETE });
export const performFrequent = () => ({ type: PERFORM_FREQUENT });
export const performQuicklinks = () => ({ type: PERFORM_QUICKLINKS });
export const performQueryLog = ()  => ({type: PERFORM_QUERY_LOG});
export const performClickLog = (value) => ({type: PERFORM_CLICK_LOG, value});

export const SEARCH_QUERY_SET_VALUE = 'publicWeb/search/setValue/searchQuery';
export const CATEGORY_SET_VALUE = 'publicWeb/search/setValue/category';
export const SEARCH_CATEGORY_SET_VALUE = 'publicWeb/search/setValue/searchCategory';
export const PAGE_SET_VALUE = 'publicWeb/search/setValue/page';
export const PER_PAGE_SET_VALUE = 'publicWeb/search/setValue/perPage';
export const SEARCH_RESULTS_SET_VALUE = 'publicWeb/search/setValue/searchResults';
export const AUTO_COMPLETE_RESULTS_SET_VALUE = 'publicWeb/search/setValue/autoCompleteResults';
export const FREQUENT_SET_VALUE = 'publicWeb/search/setValue/frequent';
export const FETCHING_STATE_SET_VALUE = 'publicWeb/search/setValue/fetchingState';
export const SEARCH_ITEMS_SET_VALUE = 'publicWeb/search/setValue/searchItems';
export const QUICKLINKS_SET_VALUE = 'publicWeb/search/setValue/quicklinks';
export const REFERRER_PATH_SET_VALUE = 'publicWeb/search/setValue/referrerPath';

export const setSearchQuery = value => ({ type: SEARCH_QUERY_SET_VALUE, value });
export const setCategory = value => ({ type: CATEGORY_SET_VALUE, value });
export const setSearchCategory = value => ({ type: SEARCH_CATEGORY_SET_VALUE, value });
export const setPage = value => ({ type: PAGE_SET_VALUE, value });
export const setPerPage = value => ({ type: PER_PAGE_SET_VALUE, value });
export const setSearchResults = value => ({ type: SEARCH_RESULTS_SET_VALUE, value });
export const setAutoCompleteResults = value => ({ type: AUTO_COMPLETE_RESULTS_SET_VALUE, value });
export const setFrequent = value => ({ type: FREQUENT_SET_VALUE, value });
export const setFetchingState = value => ({ type: FETCHING_STATE_SET_VALUE, value });
export const setSearchItems = value => ({ type: SEARCH_ITEMS_SET_VALUE, value });
export const setQuicklinks = value => ({ type: QUICKLINKS_SET_VALUE, value });
export const setSearchReferrerPath = value => ({ type: REFERRER_PATH_SET_VALUE, value});
