import { getVehicleDetailsForm } from '../../../selectors/roaCarInsurance/vehicleDetails';
import validate from '../../utils/formValidators';
import { transitionCarDetailsForm } from '../../../actions/roaCarInsurance/vehicleDetails';

export const vehicleDetailsFormRules = (store, next, action) => {
    const quotationForm = getVehicleDetailsForm(store.getState());
    const isFormValid = validate(quotationForm, [
        'registrationNumber',
        'chassisNumber',
        'engineNumber',
        'coverDate',
        'identificationMeansData',
    ]);
    const formAction = isFormValid
        ? transitionCarDetailsForm.formValid()
        : transitionCarDetailsForm.formInvalid();
    store.dispatch(formAction)
    return next(action)
};