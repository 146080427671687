import { transitionMotorInsurancePersonalDetailsForm } from '../../../actions/motorPrivateRW/personalDetails';
import { getPersonalDetailsForm } from '../../../selectors/motorPrivateRW/personalDetails';

import { FIELD_STATUS } from '../../../reducers/status';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const personalDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const personalDetailsFormValid = personalDetailsForm?.idType.value === 'National ID Number' ? validatePersonalDetailsIdFormDetails(personalDetailsForm) : validatePersonalDetailsPassportFormDetails(personalDetailsForm);
    console.log(personalDetailsFormValid, 'UMUTIMA');

    const formAction = personalDetailsFormValid
        ? transitionMotorInsurancePersonalDetailsForm.formValid()
        : transitionMotorInsurancePersonalDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsPassportFormDetails = (formFields) => {
    return (
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.gender &&
        formFields.gender.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.address &&
        formFields.address.status === FIELD_STATUS.VALID &&
        formFields.occupation &&
        formFields.occupation.status === FIELD_STATUS.VALID &&
        formFields.nationality &&
        formFields.nationality.status === FIELD_STATUS.VALID &&
        formFields.passport &&
        formFields.passport.status === FIELD_STATUS.VALID
    );
};

const validatePersonalDetailsIdFormDetails = (formFields) => {
    return (
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.gender &&
        formFields.gender.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.address &&
        formFields.address.status === FIELD_STATUS.VALID &&
        formFields.occupation &&
        formFields.occupation.status === FIELD_STATUS.VALID &&
        formFields.nationality &&
        formFields.nationality.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID
    );
};
