import { transitionLivesCoveredPersonalDetails } from "../../../actions/healthInsuranceRW/personalDetailsBeneficiaries";
import { FIELD_STATUS } from "../../../reducers/status";
import { getPersonalDetailsForm } from "../../../selectors/healthInsuranceRW/personalDetailsBeneficiaries";

export const personalDetailsBeneficiariesFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const personalDetailsFormValid = validatePersonalDetailsFormDetails(personalDetailsForm);
    const formAction = personalDetailsFormValid
        ? transitionLivesCoveredPersonalDetails.formValid()
        : transitionLivesCoveredPersonalDetails.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
    return (
        formFields.relationship &&
        formFields.relationship.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.surname &&
        formFields.surname.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.mobileNumber &&
        formFields.mobileNumber.status === FIELD_STATUS.VALID &&
        formFields.city &&
        formFields.city.status === FIELD_STATUS.VALID &&
        formFields.streetAddress &&
        formFields.streetAddress.status === FIELD_STATUS.VALID &&
        formFields.splitPercentage &&
        formFields.splitPercentage.status === FIELD_STATUS.VALID
    );
};
