import {
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
    navigateToGetQuote,
    navigateToPayment,
    navigateToLivesCovered,
    navigateToDocuments,
} from '../../../actions/personalAccident/routing';
import { getActivePageIndex } from '../../../selectors/personalAccident/routing';
import { PAGE_INDICES } from '../../../actions/personalAccident/types';

export const routingRules = (store, next, action) => {
    if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS) return next(action);
    const result = next(action);
    const state = store.getState();
    const activePageIndex = getActivePageIndex(state);
    if (action.type === NAVIGATE_NEXT) {
        onNavigateNext(store, state, activePageIndex);
        return result;
    }
    onNavigatePrevious(store, state, activePageIndex);
    return result;
};

const onNavigateNext = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.QUOTE:
            store.dispatch(navigateToLivesCovered());
            break;

        case PAGE_INDICES.LIVES_COVERED:
            store.dispatch(navigateToDocuments());
            break;

        case PAGE_INDICES.UPLOAD_DOCUMENTS:
            store.dispatch(navigateToPayment());
            break;

        default:
            store.dispatch(navigateToGetQuote());
            break;
    }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.LIVES_COVERED:
            store.dispatch(navigateToGetQuote());
            break;

        case PAGE_INDICES.UPLOAD_DOCUMENTS:
            store.dispatch(navigateToLivesCovered());
            break;

        case PAGE_INDICES.PAYMENT:
            store.dispatch(navigateToDocuments());
            break;

        default:
            store.dispatch(navigateToGetQuote());
            break;
    }
};
