import { createSelector } from 'reselect';
import { getRoaQuoteTools } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getGroupLifeQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.groupLifeQuoteTool,
);

export const getGroupLifeQuoteToolForm = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool => groupLifeQuoteTool.form,
);

const makeGetFieldForm = fieldId =>
    createSelector(getGroupLifeQuoteToolForm, form => form[fieldId]);

export const getGroupLifeQuoteToolFormValid = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool => groupLifeQuoteTool.formStatus === FORM_STATUS.VALID,
);

export const getSelectedForm = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool => groupLifeQuoteTool.selectedForm,
);

export const getGroupLifeAmount = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool => groupLifeQuoteTool.groupLifeValue,
);

export const getRoaCmbObject = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool => groupLifeQuoteTool.roaCmbObject,
);

export const getShowError = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool =>
        groupLifeQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool =>
        groupLifeQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getCalculateDisabled = createSelector(
    getGroupLifeQuoteTool,
    groupLifeQuoteTool => {
        return (
            groupLifeQuoteTool.formStatus === FORM_STATUS.INVALID ||
            groupLifeQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING
        );
    },
);

export const getNumberOfEmployeesValue = makeGetFieldForm('numberOfEmployees');
export const getAverageAgeOfEmployeesValue = makeGetFieldForm(
    'averageAgeOfEmployees',
);
export const getCoverRangeValue = makeGetFieldForm('coverRanges');
export const getTotalSumAssuredValue = makeGetFieldForm('totalSumAssured');
