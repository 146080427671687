import { createSelector } from 'reselect';
import { getRoaQuoteTools } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getMvestQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.mvestQuoteTool,
);

const makeGetFieldForm = fieldId =>
    createSelector(getMvestQuoteToolForm, form => form[fieldId]);

export const getMvestQuoteToolForm = createSelector(
    getMvestQuoteTool,
    mvestQuoteTool => mvestQuoteTool.form,
);

export const getSelectedForm = createSelector(
    getMvestQuoteTool,
    mvestQuoteTool => mvestQuoteTool.selectedForm,
);

export const getShowMvestQuoteToolResult = createSelector(
    getMvestQuoteTool,
    mvestQuoteTool => mvestQuoteTool.showMvestQuoteToolResult,
);

export const getRoaCmbObject = createSelector(
    getMvestQuoteTool,
    mvestQuoteTool => mvestQuoteTool.roaCmbObject,
);

export const getCalculateDisabled = createSelector(
    getMvestQuoteTool,
    mvestQuoteTool => {
        return (
            mvestQuoteTool.formStatus === FORM_STATUS.INVALID ||
            mvestQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING
        );
    },
);

export const getIsApiPending = createSelector(
    getMvestQuoteTool,
    mvestQuoteTool => mvestQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getMvestData = createSelector(
    getMvestQuoteTool,
    mvestQuoteTool => mvestQuoteTool.mvestData,
);

export const getPremiumAmount = makeGetFieldForm('premiumAmount');
export const getPaymentTerm = makeGetFieldForm('paymentTerm');
export const getPaymentFrequency = makeGetFieldForm('paymentFrequency');
