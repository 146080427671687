import {
    FUNDS_TOGGLE_SHOW_MORE,
    FUND_REQUEST_TOGGLE_SELECTION,
    FUND_TOGGLE_SELECTION,
    toggleFundSelection,
    toggleMaxFundsModal
} from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";
import {getAreMaxFundsSelected, getIsShowingMoreFunds, getSelectedFunds} from "../../../selectors/taxFreeSavingsAccount/advancedFundPicker";
import {deselectAllFunds} from "../../../actions/taxFreeSavingsAccount/selectAFund";
import { TOGGLE_STATUS } from "../../../reducers/status";

export const advancedFundPickerRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    // If a fund is selected in advanced fund picker, then deslect the single fund on selectAFund
    if (action.type === FUND_REQUEST_TOGGLE_SELECTION) {
        if (getAreMaxFundsSelected(state) && action.value) {
            store.dispatch(toggleMaxFundsModal(TOGGLE_STATUS.YES));
        } else {
            store.dispatch(toggleFundSelection(action.id, action.value, action.fees));
        }
    }

    if (action.type === FUND_TOGGLE_SELECTION) {
        store.dispatch(deselectAllFunds());
    }

    if (action.type === FUNDS_TOGGLE_SHOW_MORE) {
        if (getIsShowingMoreFunds(state)) {
            return result;
        }

        Object.entries(getSelectedFunds(state))
            .forEach(([fundKey, fundIsSelected]) => {
                if (Number.isNaN(+fundKey) && fundIsSelected) {
                    store.dispatch(toggleFundSelection(fundKey, false));
                }
            })

        return result;
    }

    return result;
};

export const performAdvancedFundPickerValidation = (store) => {
    store.dispatch(toggleFundSelection(null, null));
};
