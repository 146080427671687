// Transitions - toggles
export const FORM_VALID_TRANSITION_REVIEW = 'raOptimal/review/transition/formValid';
export const FORM_INVALID_TRANSITION_REVIEW = 'raOptimal/review/transition/formInvalid';
export const REVIEW_SET_VALID = 'raOptimal/review/setValid/';

export const TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW = 'raOptimal/review/transition/toggleReplacementPolicy';
export const REPLACEMENT_POLICY_SET_VALID = REVIEW_SET_VALID + 'replacementPolicy';

export const TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW = 'raOptimal/review/transition/toggleTermsAndConditions';
export const TERMS_AND_CONDITIONS_SET_VALID = REVIEW_SET_VALID + 'termsAndConditions';

export const toggleReplacementPolicy = () => ({ type: TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW });
export const setReplacementPolicyInvalid = value => ({ type: TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW, value });
export const setReplacementPolicyValid = error => ({ type: REPLACEMENT_POLICY_SET_VALID, error });

export const toggleTermsAndConditions = () => ({ type: TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW });
export const setTermsAndConditionsInvalid = value => ({ type: TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW, value });
export const setTermsAndConditionsValid = error => ({ type: TERMS_AND_CONDITIONS_SET_VALID, error });


// Transitions - form
export const transitionReview = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_REVIEW }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_REVIEW })
};

export const INVALID_CONTINUE_TRANSITION_REVIEW = 'raOptimal/review/transition/invalidContinue';
export const CONTINUE_TRANSITION_REVIEW = 'raOptimal/review/transition/validContinue';
export const BACK_TRANSITION_REVIEW = 'raOptimal/review/transition/back';
export const REVIEW_FORM_SET_VALUE = 'raOptimal/review/transition/setFormValue';
export const REVIEW_FORM_RESET_VALUE = 'raOptimal/review/resetValue/';
export const REVIEW_SET_VALUE = 'raOptimal/review/setValue/';

export const setReviewFormValues = () => ({ type: REVIEW_FORM_SET_VALUE });
export const invalidateReview = () => ({ type: INVALID_CONTINUE_TRANSITION_REVIEW });
export const continueReview = () => ({ type: CONTINUE_TRANSITION_REVIEW });
export const backReview = () => ({ type: BACK_TRANSITION_REVIEW });

export const REPLACEMENT_POLICY_RESET_VALUE = REVIEW_FORM_RESET_VALUE + 'replacementPolicy';
export const TERMS_AND_CONDITIONS_RESET_VALUE = REVIEW_FORM_RESET_VALUE + 'termsAndConditions';
export const resetReplacementPolicy = () => ({ type: REPLACEMENT_POLICY_RESET_VALUE });
export const resetTermsAndConditions = () => ({ type: TERMS_AND_CONDITIONS_RESET_VALUE });

export const REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE = REVIEW_SET_VALUE + 'replacementPolicySideDrawer';
export const TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE = REVIEW_SET_VALUE + 'termsAndConditionsSideDrawer';
export const setReplacementPolicyDrawer = value => ({ type: REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE, value });
export const setTermsAndConditionsDrawer = value => ({ type: TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE, value });

export const WAITING_FOR_SUBMISSION_SET_VALUE = REVIEW_SET_VALUE + 'waitingForSubmission';
export const setWaitingForSubmissionValue = value => ({ type: WAITING_FOR_SUBMISSION_SET_VALUE, value });

// Transitions - submission
export const RA_OPTIMAL_SUBMIT_APPLICATION = 'raOptimal/review/submitApplication/';

export const SEND_SUBMIT_RA_OPTIMAL = RA_OPTIMAL_SUBMIT_APPLICATION + 'send';
export const SUCCESS_SUBMIT_RA_OPTIMAL = RA_OPTIMAL_SUBMIT_APPLICATION + 'success';
export const FAILURE_SUBMIT_RA_OPTIMAL = RA_OPTIMAL_SUBMIT_APPLICATION + 'failure';
export const RESET_APPLICATION_SUBMIT_RA_OPTIMAL = RA_OPTIMAL_SUBMIT_APPLICATION + 'reset';

export const sendRaOptimalSubmission = () => ({ type: SEND_SUBMIT_RA_OPTIMAL });
export const failureRaOptimalSubmission = error => ({ type: FAILURE_SUBMIT_RA_OPTIMAL, error });
export const successRaOptimalSubmission = ({proposalNumber, referenceNumber}) => ({
    type: SUCCESS_SUBMIT_RA_OPTIMAL,
    proposalNumber,
    referenceNumber
});

export const resetApplicationRaOptimalSubmission = () => ({ type: RESET_APPLICATION_SUBMIT_RA_OPTIMAL });

// Tracking actions
export const REVIEW_TRACKING = 'raOptimal/review/tracking/';
export const EDIT_PAGE_REVIEW_TRACKING = REVIEW_TRACKING + 'editPage';

export const setEditPageReviewTracking = value => ({type: EDIT_PAGE_REVIEW_TRACKING, value})

export const RESET_REVIEW = REVIEW_SET_VALUE + 'resetState';
export const resetReview = () => ({type: RESET_REVIEW});