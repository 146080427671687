import { validateAge, validatePostalCode } from "@om/validation";
import {
  setAgeValid,
  setGenderValid,
  setPostalCodeValid,
  AGE_SET_VALUE,
  GENDER_SET_VALUE,
  POSTAL_CODE_SET_VALUE,
} from "../../../actions/covidSelfAssessment/biographicInfo";
import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const biographicInfoValidation = (store, next, action) => {
  const validationAction = (() => {
    let error;
    switch (action.type) {
      case AGE_SET_VALUE:
        error = validateAge(action.value, { min: 1, max: 120 });
        return setAgeValid(error);

      case GENDER_SET_VALUE:
        return setGenderValid(action.value ? null : 'Value required');

      case POSTAL_CODE_SET_VALUE:
        error = validatePostalCode(action.value);
        return setPostalCodeValid(error);

      default:
        return null;
    }
  })();

  if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
    return next(action);
  }

  store.dispatch(validationAction)
  prevValidationAction = validationAction;
  return next(action);
};
