import { TRIGGER_GET_ALL_COVER_OPTIONS, COLLECT_SHARE_QUOTE_FORM_DATA } from '../../../actions/personalAccident/quote';
import { getCoverOptionsService } from './coverOptions';
import { shareQuoteService } from './shareQuote';

import {
    TRIGGER_PAYMENTS_STK_PUSH,
    TRIGGER_CONFIRM_ORDER,
    CHECK_PAYMENTS_STATUS,
} from '../../../actions/personalAccident/payments';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { orderConfirmationService } from './orderConfirmation';

import { COLLECT_PAYLOAD_DATA } from '../../../actions/personalAccident/payloadCollection';
import { payloadSubmitService } from './payloadCollection';

import { COLLECT_LOGIN_DETAILS } from '../../../actions/personalAccident/auth';
import { loginService } from './login';

import { TRIGGER_AGENT_TYPE } from '../../../actions/personalAccident/agentModal';
import { getIntermeiaryAgent } from './agent';

export const personalAccidentCover = (store, next, action) => {
    if (action.type.startsWith(TRIGGER_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }
    if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
        return paymentsStkStatusService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {
        return payloadSubmitService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)) {
        return shareQuoteService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_LOGIN_DETAILS)) {
        return loginService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_ALL_COVER_OPTIONS)) {
        return getCoverOptionsService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)) {
        return orderConfirmationService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_AGENT_TYPE)) {
        return getIntermeiaryAgent(store, next, action);
    }

    return null;
};
