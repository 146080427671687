import {
    SEARCH_QUERY_SET_VALUE,
    CATEGORY_SET_VALUE,
    SEARCH_CATEGORY_SET_VALUE,
    PAGE_SET_VALUE,
    PER_PAGE_SET_VALUE,
    SEARCH_RESULTS_SET_VALUE,
    AUTO_COMPLETE_RESULTS_SET_VALUE,
    FREQUENT_SET_VALUE,
    FETCHING_STATE_SET_VALUE,
    SEARCH_ITEMS_SET_VALUE,
    SEARCH_RESET_STORE,
    QUICKLINKS_SET_VALUE,
    REFERRER_PATH_SET_VALUE,
} from "../../actions/publicWeb/search";

const initSearch = {
    searchQuery: '',
    category: [],
    searchCategory: [],
    page: '1',
    perPage: '10',
    searchresult: [],
    autoCompleteResult: [],
    frequentResult: [],
    // Do not copy this design pattern, rather dont reference env
    site: process.env.GRIDSOME_COUNTRY_SELECTOR ? process.env.GRIDSOME_COUNTRY_SELECTOR : process.env.GATSBY_COUNTRY_SELECTOR,
    isFetching: false,
    searchItemOne: '',
    searchItemTwo: '',
    searchItemThree: '',
    quicklinks: [],
    referrerPath: null,
};

const searchItems = actionValue => {
    const searchItems = {};
    const result = actionValue;
    if (result && result.data) {
        if (result.data[0]) {
            searchItems.searchItemOne = result.data[0];
        }
        if (result.data[1]) {
            searchItems.searchItemTwo = result.data[1];
        }
        if (result.data[2]) {
            searchItems.searchItemThree = result.data[2];
        }
    }
    return searchItems;
}

export default (state = initSearch, action) => {
    switch (action.type) {
        case SEARCH_QUERY_SET_VALUE:
            return {
                ...state,
                searchQuery: action.value.detail
            };
        case CATEGORY_SET_VALUE:
            return {
                ...state,
                category: action.value
            };
        case SEARCH_CATEGORY_SET_VALUE:
            return {
                ...state,
                searchCategory: action.value
            };
        case PAGE_SET_VALUE:
            return {
                ...state,
                page: action.value
            };
        case PER_PAGE_SET_VALUE:
            return {
                ...state,
                perPage: action.value
            };
        case SEARCH_RESULTS_SET_VALUE:
            return {
                ...state,
                searchresult: action.value
            };
        case AUTO_COMPLETE_RESULTS_SET_VALUE:
            return {
                ...state,
                autoCompleteResult: action.value,
                ...searchItems(action.value)
            };
        case FREQUENT_SET_VALUE:
            return {
                ...state,
                frequentResult: action.value
            };
        case FETCHING_STATE_SET_VALUE:
            return {
                ...state,
                isFetching: action.value
            };
        case SEARCH_ITEMS_SET_VALUE:
            return {
                ...state,
                ...searchItems(action.value),
            };
        case SEARCH_RESET_STORE:
            return { ...initSearch,
                    quicklinks: state.quicklinks };
        case QUICKLINKS_SET_VALUE:
            return {
                ...state,
                quicklinks: action.value
            };
        case REFERRER_PATH_SET_VALUE:
            return {
                ...state,
                referrerPath: action.value
            };

        default:
            return state;
    }
}