import { getRaOptimal } from "./index";
import { createSelector } from "reselect";
import { FORM_STATUS, TOGGLE_STATUS } from "../../reducers/status";

export const getReview = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.review
);

export const getReviewValid = createSelector(
    getReview,
    review => review.formStatus === FORM_STATUS.VALID,
);

export const getReplacementPolicyValue = createSelector(
    getReview,
    review => review.replacementPolicy
);

export const getReplacementPolicyValid = createSelector(
    getReview,
    review => review.replacementPolicy === TOGGLE_STATUS.YES,
);

export const getReplacementPolicyError = createSelector(
    getReview,
    review => review.replacementPolicyError,
);

export const getTermsAndConditionsValue = createSelector(
    getReview,
    review => review.termsAndConditions
);

export const getTermsAndConditionsValid = createSelector(
    getReview,
    review => review.termsAndConditions === TOGGLE_STATUS.YES,
);

export const getTermsAndConditionsError = createSelector(
    getReview,
    review => review.termsAndConditionsError,
);

export const getReplacementPolicyInvalid = createSelector(
    getReview,
    review => review.replacementPolicy === FORM_STATUS.INVALID,
);

export const getTermsAndConditionsInvalid = createSelector(
    getReview,
    review => review.termsAndConditions === FORM_STATUS.INVALID,
);

export const getReplacementPolicySideDrawerOpen = createSelector(
    getReview,
    review => review.replacementPolicySideDrawer === TOGGLE_STATUS.YES,
);

export const getTermsAndConditionsSideDrawerOpen = createSelector(
    getReview,
    review => review.termsAndConditionsSideDrawer === TOGGLE_STATUS.YES,
);

export const getApplicationReferenceNumber = createSelector(
    getReview,
    review => review.referenceNumber,
);

export const getApplicationSubmissionSuccess = createSelector(
    getApplicationReferenceNumber,
    referenceNumber => !!referenceNumber,
);

export const getApplicationProposalNumber = createSelector(
    getReview,
    review => review.proposalNumber,
);

export const getEditPageReviewTracking = createSelector(
    getReview,
    review => review.editPage,
);

export const getApplicationSubmissionFail = createSelector(
    getReview,
    review => review.submitError !== null,
);

export const getIsWaitingForSubmission = createSelector(
    getReview,
    review => review.waitingForSubmission === TOGGLE_STATUS.YES,
);