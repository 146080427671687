import axios from 'axios'; 

import {
    calculateCountyPremiumData,
    apiTransitionCountyQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/afyaImaraCountyQuoteTool";

import {
    getcountyQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/afyaImaraCountyQuoteTool';

export const CountyQuoteToolService = (store, next, action) => {
    const result = next(action);
    
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionCountyQuoteTool.pending());

            try {
                const response = await calculate(state);
                const CountyPremiumAmount = response.data.total_premiums
                store.dispatch(calculateCountyPremiumData(CountyPremiumAmount));
                store.dispatch(apiTransitionCountyQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateCountyPremiumData(null));
                store.dispatch(apiTransitionCountyQuoteTool.failure());
            }
        }
    })();
    return result;
}
const getCountyPayload = (formDetails) => { 
    let payload;

    if (formDetails.childCover.value == 'no'){
        payload = {
            "cover_id": 3 || '',
            "age_group": formDetails.ageBracket.value || '',
            "spouse": formDetails.spouseCover.value ? formDetails.spouseCover.value:'no' || '',
            "spouse_age_group": formDetails.spouseAge.value || '',
            "children_status": formDetails.childCover.value ? formDetails.childCover.value:'no' || '',
            "children_18": 0,
            "children_19": 0,
            "children_30": 0,
            "children_41": 0,
            "inpatient":formDetails.inPatient.value ? Number(formDetails.inPatient.value.slice(4,15)) : 1000000 || '',
            "outpatient":formDetails.outPatient.value ? 
                (formDetails.outPatient.value.slice(0,3) === "KES" ? Number(formDetails.outPatient.value.slice(4,15)) : 0) :
                0,
        }
    } else {
        payload = {
            "cover_id": 3 || '',
            "age_group": formDetails.ageBracket.value || '',
            "spouse": formDetails.spouseCover.value ? formDetails.spouseCover.value:'no' || '',
            "spouse_age_group": formDetails.spouseAge.value || '',
            "children_status": formDetails.childCover.value ? formDetails.childCover.value:'no' || '',
            "children_18": Number(formDetails.child_18.value) ? Number(formDetails.child_18.value):Number(formDetails.child_18.value),
            "children_19": Number(formDetails.child_19_29.value) ? Number(formDetails.child_19_29.value):Number(formDetails.child_19_29.value) ,
            "children_30": Number(formDetails.child_30_40.value) ? Number(formDetails.child_30_40.value):Number(formDetails.child_30_40.value) ,
            "children_41": Number(formDetails.child_40_50.value) ? Number(formDetails.child_40_50.value):Number(formDetails.child_40_50.value) ,
            "inpatient":formDetails.inPatient.value ? Number(formDetails.inPatient.value.slice(4,15)) : 1000000 || '',
            "outpatient":formDetails.outPatient.value ? 
                (formDetails.outPatient.value.slice(0,3) === "KES" ? Number(formDetails.outPatient.value.slice(4,15)) : 0) :
                0,
        }
    }
   
    return payload;
}
const calculate = async(state) => {
    const CountyFormDetails = getcountyQuoteToolForm(state)

    
    try{        
        const url = '/om-api/roa-quote-tools/afya-imara-county-quote';
        const result = await axios.post(url, getCountyPayload(CountyFormDetails));
        return result;
    } catch (error) {
        return null;
    }
}

