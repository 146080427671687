import { TRAVEL_INSURANCE_FORM } from '../../../actions/travel/quote';
import { quoteFormValidation } from './quote';

import { TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE } from '../../../actions/travel/personalDetails';
import { travelDetailsValidation } from './travel';

import { BUILDING_DETAILS_FORM_SET_VALUE } from '../../../actions/domesticInsurance/buildingDetails';
import { buildingDetailsValidation } from './buildingDetails ';
import { MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/carDetails';
import { carDetailsValidation } from './carsDetails';

import { MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/travel/motorAgentModal';
import { agentModalFormValidation } from './travelAgentModal';

import { DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/domesticInsurance/Documents';
import { documentsUploadValidation } from './documents';
import { MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/declarations';
import { declarationsFormValidation } from './declarations';

import { MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE } from '../../../actions/ugMotorInsurance/nextOfKin';
import { nextOfKinValidation } from './nextOfKin';

import { PAYMENTS_SET_VALUE } from '../../../actions/travel/payments';
import { paymentsFormValidation } from './payments';

import { SET_SHARE_EMAIL_VALUE } from '../../../actions/travel/quote';
import { shareQuoteValidation } from './shareQuote';

export const travelInsuranceValidation = (store, next, action) => {
    if (action.type.startsWith(TRAVEL_INSURANCE_FORM)) {
        return quoteFormValidation(store, next, action);
    }
    if (action.type.startsWith(TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE)) {
        return travelDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(BUILDING_DETAILS_FORM_SET_VALUE)) {
        return buildingDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(PAYMENTS_SET_VALUE)) {
        return paymentsFormValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE)) {
        return nextOfKinValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE)) {
        return declarationsFormValidation(store, next, action);
    }
    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE)) {
        return shareQuoteValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE)) {
        return carDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE)) {
        return agentModalFormValidation(store, next, action);
    }
    if (action.type.startsWith(DOMESTIC_INSURANCE_DOCUMENTS_FORM_SET_VALUE)) {
        return documentsUploadValidation(store, next, action);
    }

    return null;
};
