import axios from 'axios';

import {
    calculateDigiLifePremiumData,
    apiTransitionDigiLifeQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/digiLifeQuoteTool";

import {
    getDigiLifeQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/digiLifeQuoteTool';


export const digiLifeQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (!getIsApiPending(state)) {
            store.dispatch(apiTransitionDigiLifeQuoteTool.pending());

            try {
                const response = await calculate(state);
                const digiLifePremiumAmount = response.data.MonthlyPremium;
                store.dispatch(calculateDigiLifePremiumData(digiLifePremiumAmount));
                store.dispatch(apiTransitionDigiLifeQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateDigiLifePremiumData(null));
                store.dispatch(apiTransitionDigiLifeQuoteTool.failure());
            }
        }
    })();
    return result;
}

const getDigiLifePayload = (formDetails) => {
    return {
        "DOB": formDetails.formattedDate.value,
        "Sex": formDetails.gender.value,
        "BenefitAmount": formDetails.loanAmount.value,
        "Income": formDetails.salary.value,
        "Education": formDetails.education.value,
        "LoanType": formDetails.loanType.value,
        "PaymentFrequency": formDetails.payment.value,
        "CashBackOption": formDetails.cashBack.value,
        "PolicyTerm": formDetails.loanTerm.value,
        "QuotationDate": formDetails.date.value,
        
    }
}

const calculate = async (state) => {
    const DigiLifeFormDetails = getDigiLifeQuoteToolForm(state)
    try {
        const url = '/om-api/roa-quote-tools/digi-life-quote'
        const result = await axios.post(
            url, getDigiLifePayload(DigiLifeFormDetails),
            { headers: {
                'Content-Type': 'application/json', 
                'X_Gravitee_Api_Key': 'cc834c0c-7cda-4a78-a3d1-218a7bfccf22'   
            }}
        )
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}