import { validationIsEqual } from '../status';
import { validateBase64File } from '@om/validation';
import {
    ID_UPLOAD_SET_VALUE,
    KRA_PIN_UPLOAD_SET_VALUE,
    setIdUploadValid,
    setKraUploadValid,
} from '../../../actions/domesticInsurance/Documents';

let prevValidationAction = null;
export const documentsUploadValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ID_UPLOAD_SET_VALUE:
                error = validateBase64File(action.value, {
                    subject: 'ID',
                    required: true,
                });
                return setIdUploadValid(error);

            case KRA_PIN_UPLOAD_SET_VALUE:
                error = validateBase64File(action.value, {
                    subject: 'KRA PIN',
                    required: true,
                });
                return setKraUploadValid(error);

            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
