import { onFieldChangeStateComparison } from '../onFieldChangeStateComparison';
import { simpleFieldTracking } from '../formTracking';
import {
    FUND_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/selectAFund';
import { getSelectedFund } from '../../../selectors/taxFreeSavingsAccount/selectAFund';

export const selectAFundTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case FUND_SET_VALUE:
                sendError = false;
                return getSelectedFund;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);
    if (field) simpleFieldTracking({ action, field, sendValue, sendError });

    return result;
};
