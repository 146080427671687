export const DOCTORS_PI_UPLOAD_DOCUMENTS_FORM = 'doctorsPi/uploadDocumentsForm/';
export const DOCTORS_PI_DECLARATION_FORM = 'doctorsPi/declationsForm/';

export const DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM + 'setValue/';
export const DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM + 'setValid/';

export const DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE = DOCTORS_PI_DECLARATION_FORM + 'setValue/';
export const DOCTORS_PI_DECLARATIONS_FORM_SET_VALID = DOCTORS_PI_DECLARATION_FORM + 'setValid/';

export const FORM_VALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM = 'doctorsPi/uploadDocumentsForm/transition/formValid';
export const FORM_INVALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM = 'doctorsPi/uploadDocumentsForm/transition/formInValid';

export const FORM_VALID_DOCTORS_PI_DECLARATION_FORM = 'doctorsPi/declationsForm/transition/statementValid';
export const FORM_INVALID_DOCTORS_PI_DECLARATION_FORM = 'doctorsPi/declationsForm/transition/statementInValid';

export const FORM_VALID_DOCTORS_PI_APPROVE_DOCUMENTS_FORM = 'doctorsPi/approveCheckDocumentsForm/transition/formValid';
export const FORM_INVALID_DOCTORS_PI_APPROVE_DOCUMENTS_FORM = 'doctorsPi/approveCheckDocumentsForm/transition/formInValid';

export const transitionDoctorsPiUploadDocuments = {
    formValid: () => ({
        type: FORM_VALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DOCTORS_PI_UPLOAD_DOCUMENTS_FORM,
    }),
};

export const transitionDoctorsPiDeclarations = {
    formValid: () => ({
        type: FORM_VALID_DOCTORS_PI_DECLARATION_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DOCTORS_PI_DECLARATION_FORM,
    }),
};

export const transitionDoctorsPiApproveCheckDocuments = {
    formValid: () => ({
        type: FORM_VALID_DOCTORS_PI_APPROVE_DOCUMENTS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DOCTORS_PI_APPROVE_DOCUMENTS_FORM,
    }),
};

export const CURRENT_LICENSE_COPY_SET_VALUE = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "currentLicense";
export const CURRENT_LICENSE_COPY_SET_VALID = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "currentLicense";

export const RESUME_COPY_SET_VALUE = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "resume";
export const RESUME_COPY_SET_VALID = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "resume";

export const NATIONAL_ID_COPY_SET_VALUE = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "nationalId";
export const NATIONAL_ID_COPY_SET_VALID = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "nationalId";

export const ACADEMIC_CERTIFICATES_COPY_SET_VALUE = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "academicCertificates";
export const ACADEMIC_CERTIFICATES_COPY_SET_VALID = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "academicCertificates";

export const STATEMENT_COPY_SET_VALUE = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "statement";
export const STATEMENT_COPY_SET_VALID = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "statement";

export const KRA_PIN_COPY_SET_VALUE = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "kraPin";
export const KRA_PIN_COPY_SET_VALID = DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "kraPin";

export const DEC_ONE_COPY_SET_VALUE = DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE + "decOne";
export const DEC_ONE_COPY_SET_VALID = DOCTORS_PI_DECLARATIONS_FORM_SET_VALID + "decOne";

export const DEC_TWO_COPY_SET_VALUE = DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE + "decTwo";
export const DEC_TWO_COPY_SET_VALID = DOCTORS_PI_DECLARATIONS_FORM_SET_VALID + "decTwo";

export const DEC_THREE_COPY_SET_VALUE = DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE + "decThree";
export const DEC_THREE_COPY_SET_VALID = DOCTORS_PI_DECLARATIONS_FORM_SET_VALID + "decThree";

export const DEC_FOUR_COPY_SET_VALUE = DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE + "decFour";
export const DEC_FOUR_COPY_SET_VALID = DOCTORS_PI_DECLARATIONS_FORM_SET_VALID + "decFour";

export const DEC_FIVE_COPY_SET_VALUE = DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE + "decFive";
export const DEC_FIVE_COPY_SET_VALID = DOCTORS_PI_DECLARATIONS_FORM_SET_VALID + "decFive";

export const DEC_SIX_COPY_SET_VALUE = DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE + "decSix";
export const DEC_SIX_COPY_SET_VALID = DOCTORS_PI_DECLARATIONS_FORM_SET_VALID + "decSix";



export const setCurrentLicenseCopyValue = (value) => ({
    type: CURRENT_LICENSE_COPY_SET_VALUE,
    value
});

export const setCurrentLicenseCopyValid = (error) => ({
    type: CURRENT_LICENSE_COPY_SET_VALID,
    error
});

export const setResumeCopyValue = (value) => ({
    type: RESUME_COPY_SET_VALUE,
    value
});

export const setResumeCopyValid = (error) => ({
    type: RESUME_COPY_SET_VALID,
    error
});

export const setNationalIdValue = (value) => ({
    type: NATIONAL_ID_COPY_SET_VALUE,
    value
});

export const setNationalIdValid = (error) => ({
    type: NATIONAL_ID_COPY_SET_VALID,
    error
});

export const setAcademicCertificateCopyValue = (value) => ({
    type: ACADEMIC_CERTIFICATES_COPY_SET_VALUE,
    value
});

export const setAcademicCertificateCopyValid = (error) => ({
    type: ACADEMIC_CERTIFICATES_COPY_SET_VALID,
    error
});

export const setStatementValue = (value) => ({
    type: STATEMENT_COPY_SET_VALUE,
    value
});

export const setStatementValid = (error) => ({
    type: STATEMENT_COPY_SET_VALID,
    error
});

export const setKraPinValue = (value) => ({
    type: KRA_PIN_COPY_SET_VALUE,
    value
});

export const setKraPinValid = (error) => ({
    type: KRA_PIN_COPY_SET_VALID,
    error
});

export const setDecOneValue = (value) => ({
    type: DEC_ONE_COPY_SET_VALUE,
    value
});

export const setDecOneValid = (error) => ({
    type: DEC_ONE_COPY_SET_VALID,
    error
});

export const setDecTwoValue = (value) => ({
    type: DEC_TWO_COPY_SET_VALUE,
    value
});

export const setDecTwoValid = (error) => ({
    type: DEC_TWO_COPY_SET_VALID,
    error
});

export const setDecThreeValue = (value) => ({
    type: DEC_THREE_COPY_SET_VALUE,
    value
});

export const setDecThreeValid = (error) => ({
    type: DEC_THREE_COPY_SET_VALID,
    error
});

export const setDecFourValue = (value) => ({
    type: DEC_FOUR_COPY_SET_VALUE,
    value
});

export const setDecFourValid = (error) => ({
    type: DEC_FOUR_COPY_SET_VALID,
    error
});

export const setDecFiveValue = (value) => ({
    type: DEC_FIVE_COPY_SET_VALUE,
    value
});

export const setDecFiveValid = (error) => ({
    type: DEC_FIVE_COPY_SET_VALID,
    error
});

export const setDecSixValue = (value) => ({
    type: DEC_SIX_COPY_SET_VALUE,
    value
});

export const setDecSixValid = (error) => ({
    type: DEC_SIX_COPY_SET_VALID,
    error
});
