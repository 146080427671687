import { efpBeneficiariesValidation } from './beneficiaries';
import { efpBeneficiariesSplitValidation } from './beneficiariesSplit';
import { paymentDetailsValidation } from './paymentDetails';
import { personalDetailsValidation } from './personalDetails';
import { efpQuoteValidation } from './quote';
import { replacementPolicyValidation } from './replacementPolicy';
import { confirmDetailsValidation } from './confirmDetails';

import { BENEFICIARIES_SET_VALUE } from "../../../actions/easiplusFuneralPlan/beneficiaries";
import { BENEFICIARIES_SPLIT_SET_VALUE } from "../../../actions/easiplusFuneralPlan/beneficiariesSplit";
import { PAYMENT_DETAILS_SET_VALUE } from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { PERSONAL_DETAILS_SET_VALUE } from "../../../actions/easiplusFuneralPlan/personalDetails";
import { QUOTE_SET_VALUE } from "../../../actions/easiplusFuneralPlan/quote";
import { REPLACEMENT_POLICY_SET_VALUE } from "../../../actions/easiplusFuneralPlan/replacementPolicy";
import { CONFIRM_DETAILS_SET_VALUE, TOGGLE_DECLARATIONS_CONFIRM_DETAILS } from "../../../actions/easiplusFuneralPlan/confirmDetails";
import { CALL_ME_BACK_SET_VALUE } from "../../../actions/easiplusFuneralPlan/callMeBack";
import { callMeBackValidation } from "./callMeBack";
import { LIVES_COVERED_SET_VALUE } from "../../../actions/easiplusFuneralPlan/livesCovered";
import { efpLivesCoveredValidation } from "./livesCovered";

export const efpValidation = (store, next, action) => {

    if (action.type.startsWith(BENEFICIARIES_SET_VALUE))
        return efpBeneficiariesValidation(store, next, action);

    if (action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE))
        return efpBeneficiariesSplitValidation(store, next, action);

    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE))
        return paymentDetailsValidation(store, next, action);

    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE))
        return personalDetailsValidation(store, next, action);

    if (action.type.startsWith(QUOTE_SET_VALUE))
        return efpQuoteValidation(store, next, action);

    if (action.type.startsWith(REPLACEMENT_POLICY_SET_VALUE))
        return replacementPolicyValidation(store, next, action);

    if (action.type.startsWith(CONFIRM_DETAILS_SET_VALUE) ||
        action.type.startsWith(TOGGLE_DECLARATIONS_CONFIRM_DETAILS))
        return confirmDetailsValidation(store, next, action);

    if (action.type.startsWith(CALL_ME_BACK_SET_VALUE)) {
        return callMeBackValidation(store, next, action);
    }

    if (action.type.startsWith(LIVES_COVERED_SET_VALUE))
        return efpLivesCoveredValidation(store, next, action);

    return null;
}