import {
    funeralBasicPlanTypes,
    funeralBasicProductCodes,
    getPlanTypeFromProductCode,
} from "./CalcData";

import { EFP_COVER_CATEGORIES } from "../../actions/easiplusFuneralPlan/types"

export class CalcEngine{
    static easiplusLookupPremiumValue = (ratesData, productCode, age, coverAmount) => {
        const ratesRefData = this.getRatesRefDataEFP(productCode, ratesData);
        const rateObj = ratesRefData.find(rates => (rates.coverAmount === parseInt(coverAmount) && age >= rates.minAge && age <= rates.maxAge));
        return rateObj ? rateObj.premiumAmount : null;
    }

    static getRatesRefDataEFP = (productCode, ratesData) => {
        const planType = getPlanTypeFromProductCode(productCode);
        const plan = ratesData.plans[funeralBasicPlanTypes.indexOf(planType)];
        const productRates = plan.productRates[productCode.indexValue].rates;
        return productRates;
    }

    static mapCoverCategoryForProductCode = (coverCategory, addPartner, addChild) => {
        switch(coverCategory){
            case EFP_COVER_CATEGORIES.DIRECT_FAMILY:
                if(addChild && !addPartner){
                    return funeralBasicProductCodes[2];
                } else if (addPartner) {
                    return funeralBasicProductCodes[1];
                } else {
                    return funeralBasicProductCodes[0];
                }
            case EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS:
                return funeralBasicProductCodes[3];
            case EFP_COVER_CATEGORIES.EXTENDED_FAMILY: 
                return funeralBasicProductCodes[4];
        }
        // MeAndMyImmediateFamily: 
        // - Individual (only me)
        // - Family (me, my spouse and my children or no children)
        // - SingleFamily (me and my children and no spouse)
        // MyParentsOrInlaws
        // MyExtendedFamily
    }
}
