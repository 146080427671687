import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';
import {
	AGE_BRACKET_SET_VALUE,
	AGE_BRACKET_SET_VALID,
	SPOUSE_AGE_SET_VALID,
	SPOUSE_AGE_SET_VALUE,
	SPOUSE_COVER_SET_VALID,
	SPOUSE_COVER_SET_VALUE,
	IN_PATIENT_SET_VALID,
	IN_PATIENT_SET_VALUE,
	OUT_PATIENT_SET_VALID,
	OUT_PATIENT_SET_VALUE,
	CC_SET_VALID,
	CC_SET_VALUE,
	AGE_18child_SET_VALUE,
	AGE_18child_SET_VALID,
	AGE_1929child_SET_VALUE,
	AGE_1929child_SET_VALID,
	AGE_3040child_SET_VALUE,
	AGE_3040child_SET_VALID,
	AGE_4150child_SET_VALUE,
	AGE_4150child_SET_VALID,
	WHICH_FORM_TO_DISPLAY,
	REINITIALIZE,
	COLLECT_AFYAIMARA_PREMIUM_DATA,
	CALCULATE_AFYAIMARA_PREMIUM_DATA,
	API_PENDING_TRANSITION_AfyaImara_QUOTE_TOOL,
	API_FAILURE_TRANSITION_AfyaImara_QUOTE_TOOL,
	API_SUCCESS_TRANSITION_AfyaImara_QUOTE_TOOL,
	FORM_VALID_AfyaImara_QUOTE_TOOL,
	FORM_INVALID_AfyaImara_QUOTE_TOOL,
} from '../../actions/roaQuoteTools/afyaImaraQuoteTool';
const initialeState = {
	selectedForm: 'form-one',
	formStatus: FORM_STATUS.VALID,
	AfyaImaraPremiumValue: '',
	roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Afyaimara Family Cover',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address'] 
    },
	form: {
		ageBracket: { value: '19_29', error: null, status: 'valid' },
		childCover: { value: 'no', error: null, status: 'valid' },
		spouseAge: { value: '19_29', error: null, status: 'valid' },
		spouseCover: { value: 'no', error: null, status: 'valid' },
		inpatientCoverLimit: { value: '500000', error: null, status: 'valid' },
		outpatientCoverLimit: { value: '50000', error: null, status: 'valid' },
		child_18: { value: '0', error: null, status: 'valid' },
		child_19_29: { value: '0', error: null, status: 'valid' },
		child_30_40: { value: '0', error: null, status: 'valid' },
		child_40_50: { value: '0', error: null, status: 'valid' },
	},
};

export default (state = initialeState, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
		case FORM_VALID_AfyaImara_QUOTE_TOOL:
			return { ...state, formStatus: FORM_STATUS.VALID };
		case FORM_INVALID_AfyaImara_QUOTE_TOOL:
			return { ...state, formStatus: FORM_STATUS.INVALID };
		case AGE_BRACKET_SET_VALUE:
			return updateFormField('ageBracket', (field) => ({
				...field,
				value: action.value,
			}));
		case AGE_BRACKET_SET_VALID:
			return updateFormField('ageBracket', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case SPOUSE_COVER_SET_VALUE:
			return updateFormField('spouseCover', (field) => ({
				...field,
				value: action.value,
			}));
		case SPOUSE_COVER_SET_VALID:
			return updateFormField('spouseCover', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case WHICH_FORM_TO_DISPLAY:
			return {
				...state,
				selectedForm: action.value,
			};
		// Date of birth
		case SPOUSE_AGE_SET_VALUE:
			return updateFormField('spouseAge', (field) => ({
				...field,
				value: action.value,
			}));
		case SPOUSE_AGE_SET_VALID:
			return updateFormField('spouseAge', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		// Date of birth
		case IN_PATIENT_SET_VALUE:
			return updateFormField('inpatientCoverLimit', (field) => ({
				...field,
				value: action.value,
			}));
		case IN_PATIENT_SET_VALID:
			return updateFormField('inpatientCoverLimit', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		// Initials
		case OUT_PATIENT_SET_VALUE:
			return updateFormField('outpatientCoverLimit', (field) => ({
				...field,
				value: action.value,
			}));
		case OUT_PATIENT_SET_VALID:
			return updateFormField('outpatientCoverLimit', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		// life covered input
		case CC_SET_VALUE:
			return updateFormField('childCover', (field) => ({
				...field,
				value: action.value,
			}));
		case CC_SET_VALID:
			return updateFormField('childCover', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		case AGE_18child_SET_VALUE:
			return updateFormField('child_18', (field) => ({
				...field,
				value: action.value,
			}));
		case AGE_18child_SET_VALID:
			return updateFormField('child_18', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case AGE_1929child_SET_VALUE:
			return updateFormField('child_19_29', (field) => ({
				...field,
				value: action.value,
			}));
		case AGE_1929child_SET_VALID:
			return updateFormField('child_19_29', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case AGE_3040child_SET_VALUE:
			return updateFormField('child_30_40', (field) => ({
				...field,
				value: action.value,
			}));
		case AGE_3040child_SET_VALID:
			return updateFormField('child_30_40', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case AGE_4150child_SET_VALUE:
			return updateFormField('child_40_50', (field) => ({
				...field,
				value: action.value,
			}));
		case AGE_4150child_SET_VALID:
			return updateFormField('child_40_50', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		//api call
		// Api call
		case API_PENDING_TRANSITION_AfyaImara_QUOTE_TOOL:
			return {
				...state,
				apiCallStatus: API_CALL_STATUS.PENDING,
			};
		case API_SUCCESS_TRANSITION_AfyaImara_QUOTE_TOOL:
			return {
				...state,
				apiCallStatus: API_CALL_STATUS.SUCCESS,
			};
		case API_FAILURE_TRANSITION_AfyaImara_QUOTE_TOOL:
			return {
				...state,
				apiCallStatus: API_CALL_STATUS.FAILURE,
			};
		// target fees per year input
		case REINITIALIZE:
			return initialeState;
		case CALCULATE_AFYAIMARA_PREMIUM_DATA:
			return {
				...state,
				AfyaImaraPremiumValue: action.value,
			};
		case COLLECT_AFYAIMARA_PREMIUM_DATA:
			return {
				...state,
				formStatus: FORM_STATUS.VALID,
			};
		default:
			return state;
	}
}
