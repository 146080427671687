import axios from 'axios';
import {
    setUserFound,
    COLLECT_USER_DATA
} from '../../../actions/publicWeb/unclaimedBenefits';

export const unclaimedBenefitsService = (store, next, action) => {
    const result = next(action);
    const state = store.getState().publicWeb.unclaimedBenefits;

    (async() => {
        if (action.type === COLLECT_USER_DATA) {
            const response = await collectData(state.form)
            store.dispatch(setUserFound(response.status))
        }
    })();

    return result;
}

export const collectData = async (form) => {
    const query = `${form.initials.value.toUpperCase()}_${form.surname.value.toUpperCase()}_${form.dob.value}`
    return axios.get('/om-api/ubf-active-members?uuid=' + query)
    .then(result => {
        if (result.data.Items.length > 0 && result.data.Items.find(x => x.UUID === query))
            return { status: true }
        return { status: false }
    })
    .catch(error => {
        // console.log('unclaimed benefits error', error.message)
        return { status: false }
    })
}
