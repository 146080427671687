import { createSelector } from 'reselect';
import { getRWMotorPrivateInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDeclarations = createSelector(getRWMotorPrivateInsuranceState, (state) => state.declarations);
export const getDeclarationsForm = createSelector(getDeclarations, (declarations) => declarations.form);

export const getDropOffDetails = createSelector(getDeclarations, (declarations) => declarations.dropOffDetails);

export const getDeclarationsFormValid = createSelector(
    getDeclarations,
    (declarations) => declarations.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getDeclarations,
    (declarations) => declarations.formStatus === API_CALL_STATUS.PENDING,
);

const makeGetFieldForm = (fieldId) => createSelector(getDeclarationsForm, (form) => form[fieldId]);

// Updated field selectors
export const getDriverHealthDeclarationValue = makeGetFieldForm('driverHealthDeclaration');
export const getVehicleConditionDeclarationValue = makeGetFieldForm('vehicleConditionDeclaration');
export const getDriverLicenseDeclarationValue = makeGetFieldForm('driverLicenseDeclaration');
export const getAverageClauseConsentValue = makeGetFieldForm('averageClauseConsent');
export const getFullValueDeclarationValue = makeGetFieldForm('fullValueDeclaration');
export const getMarketingConsentValue = makeGetFieldForm('marketingConsent');
export const getPrivacyPolicyConfirmationValue = makeGetFieldForm('privacyPolicyConfirmation');
export const getAdviceAcknowledgementIndemnityValue = makeGetFieldForm('adviceAcknowledgementIndemnity');
export const getNoPremiumRefundAcknowledgementValue = makeGetFieldForm('noPremiumRefundAcknowledgement');
export const getFullPremiumSettlementConsentValue = makeGetFieldForm('fullPremiumSettlementConsent');
export const getFinalDeclarationAgreementValue = makeGetFieldForm('finalDeclarationAgreement');
