import { createSelector } from "reselect";
import { getAtRetirement } from "./index";
import { OPEN_DRAWER } from "../../actions/atRetirement/types";
import { FORM_STATUS } from "../../reducers/status";

export const getSpeakToAnAdviser = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.speakToAnAdviser
);

export const getFinAdviceOpenDrawer = createSelector(
    getSpeakToAnAdviser,
    speakToAnAdviser => speakToAnAdviser.openDrawer
);

export const getSpeakToAnAdviserDrawerIsOpen = createSelector(
    getSpeakToAnAdviser,
    speakToAnAdviser => speakToAnAdviser.openDrawer === OPEN_DRAWER.SPEAK_TO_AN_ADVISER
);

export const getSpeakToAnAdviserFormIsValid = createSelector(
    getSpeakToAnAdviser,
    speakToAnAdviser => speakToAnAdviser.formStatus === FORM_STATUS.VALID,
);

export const getSpeakToAnAdviserForm = createSelector(
    getSpeakToAnAdviser,
    speakToAnAdviser => speakToAnAdviser.form,
);

export const getFormSubmitted = createSelector(
    getSpeakToAnAdviser,
    speakToAnAdviser => speakToAnAdviser.formSubmitted,
);

export const getSendSpeakToAnAdviserStatus = createSelector(
    getSpeakToAnAdviser,
    speakToAnAdviser => speakToAnAdviser.sendSpeakToAnAdviserStatus
);

const makeGetField = fieldId => createSelector(
    getSpeakToAnAdviserForm,
    speakToAnAdviserForm => speakToAnAdviserForm[fieldId]
);

export const getReasons = makeGetField('reasons');
export const getMoreHelp = makeGetField('moreHelp');
export const getEmailAddress = makeGetField('emailAddress');
export const getContactNumber = makeGetField('contactNumber');




