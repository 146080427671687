
import { knowledgeBasedAuthenticationValidation } from "./knowledgeBasedAuthentication";
import { CVS_KBA_SET_VALUE } from "../../../actions/customerVerificationService/knowledgeBasedAuthentication";
import { ID_VERIFICATION_SET_VALUE } from "../../../actions/customerVerificationService/idVerification";
import { idVerificationValidation } from './idVerification';
import { CALL_ME_BACK_SET_VALUE } from "../../../actions/customerVerificationService/callMeBack";
import { callMeBackValidation } from './callMeBack';

export const cvsValidation = (store, next, action) => {

    if (action.type.startsWith(CVS_KBA_SET_VALUE))
        return knowledgeBasedAuthenticationValidation(store, next, action);

    if (action.type.startsWith(ID_VERIFICATION_SET_VALUE))
        return idVerificationValidation(store, next, action);

    if (action.type.startsWith(CALL_ME_BACK_SET_VALUE)) 
        return callMeBackValidation(store, next, action);

    return null;
}
