import {
	MONTHLY_AMOUNT_SET_VALID,
	MONTHLY_AMOUNT_SET_VALUE,
	ONCE_OFF_SET_VALID,
	ONCE_OFF_SET_VALUE,
} from '../../../actions/investmentCalculator/emergencyFund';
import { emergencyFundCalculateFormRules } from './emergencyFundCalculator';

import {
	ONCE_OFF_SET_VALUE as BUILD_WEALTH_ONCE_OFF_SET_VALUE,
	MONTHLY_AMOUNT_SET_VALUE as BUILD_WEALTH_MONTHLY_AMOUNT_SET_VALUE,
	ONCE_OFF_SET_VALID as BUILD_WEALTH_ONCE_OFF_SET_VALID,
	MONTHLY_AMOUNT_SET_VALID as BUILD_WEALTH_MONTHLY_AMOUNT_SET_VALID,
} from '../../../actions/investmentCalculator/buildWealth';
import { buildWealthCalculateFormRules } from './buildWealthCalculator';
import {
	ONCE_OFF_SET_VALUE as REGULAR_INCOME_ONCE_OFF_SET_VALUE,
	MONTHLY_AMOUNT_SET_VALUE as REGULAR_INCOME_MONTHLY_AMOUNT_SET_VALUE,
	ONCE_OFF_SET_VALID as REGULAR_INCOME_ONCE_OFF_SET_VALID,
	MONTHLY_AMOUNT_SET_VALID as REGULAR_INCOME_MONTHLY_AMOUNT_SET_VALID,
} from '../../../actions/investmentCalculator/generateRegularIncome';
import { generateRegularIncomeCalculatorFormRules } from './generateRegularIncomeCalculator';

import {
	BOND_FUND_SET_VALUE,
	EQUITY_FUND_SET_VALUE,
	EVEN_SPLIT_DESELECT_FUND_SPLIT,
	EVEN_SPLIT_DISCARD_FUND_SPLIT,
	EVEN_SPLIT_SELECT_FUND_SPLIT,
	FUND_SPLIT_SET_VALID,
	FUND_SPLIT_SET_VALUE,
	SPLIT_PERCENTAGE_SET_VALID,
} from '../../../actions/investmentCalculator/buildWealthFundSplit';
import { buildWealthSplitFormRules } from './buildWealthSplitFund';

import {
	MONTHLY_AMOUNT_SET_VALID as MONTHLY_AMOUNT_SET_VALID_SHORT_TERM_GOAL,
	MONTHLY_AMOUNT_SET_VALUE as MONTHLY_AMOUNT_SET_VALUE_SHORT_TERM_GOAL,
	ONCE_OFF_SET_VALID as ONCE_OFF_SET_VALID_SHORT_TERM_GOAL,
	ONCE_OFF_SET_VALUE as ONCE_OFF_SET_VALUE_SHORT_TERM_GOAL,
} from '../../../actions/investmentCalculator/shortTermGoal';
import { shortTermGoalFormRules } from './shortTermGoalCalculator';

import {
	EVEN_SPLIT_DESELECT_FUND_SPLIT as SHORT_TERM_GOAL_EVEN_SPLIT_DESELECT_FUND_SPLIT,
	EVEN_SPLIT_DISCARD_FUND_SPLIT as SHORT_TERM_GOAL_EVEN_SPLIT_DISCARD_FUND_SPLIT,
	EVEN_SPLIT_SELECT_FUND_SPLIT as SHORT_TERM_GOAL_EVEN_SPLIT_SELECT_FUND_SPLIT,
	FUND_SPLIT_SET_VALID as SHORT_TERM_GOAL_FUND_SPLIT_SET_VALID,
	FUND_SPLIT_SET_VALUE as SHORT_TERM_GOAL_FUND_SPLIT_SET_VALUE,
	SPLIT_PERCENTAGE_SET_VALID as SHORT_TERM_GOAL_SPLIT_PERCENTAGE_SET_VALID,
	BOND_FUND_SET_VALUE as  SHORT_TERM_GOAL_BOND_FUND_SET_VALUE,
	EQUITY_FUND_SET_VALUE as  SHORT_TERM_GOAL_EQUITY_FUND_SET_VALUE
} from '../../../actions/investmentCalculator/shortTermGoalSplit';
import { shortTermGoalSplitFormRules } from './shortTermGoalSplit';

import {
	MONTHLY_AMOUNT_SET_VALID as MONTHLY_AMOUNT_SET_VALID_LONG_TERM_GOAL,
	MONTHLY_AMOUNT_SET_VALUE as MONTHLY_AMOUNT_SET_VALUE_LONG_TERM_GOAL,
	ONCE_OFF_SET_VALID as ONCE_OFF_SET_VALID_LONG_TERM_GOAL,
	ONCE_OFF_SET_VALUE as ONCE_OFF_SET_VALUE_LONG_TERM_GOAL,
} from '../../../actions/investmentCalculator/longTermGoal';
import { longTermGoalFormRules } from './longTermGoalCalculator';

import {
	EVEN_SPLIT_DESELECT_FUND_SPLIT as LONG_TERM_GOAL_EVEN_SPLIT_DESELECT_FUND_SPLIT,
	EVEN_SPLIT_DISCARD_FUND_SPLIT as LONG_TERM_GOAL_EVEN_SPLIT_DISCARD_FUND_SPLIT,
	EVEN_SPLIT_SELECT_FUND_SPLIT as LONG_TERM_GOAL_EVEN_SPLIT_SELECT_FUND_SPLIT,
	FUND_SPLIT_SET_VALID as LONG_TERM_GOAL_FUND_SPLIT_SET_VALID,
	FUND_SPLIT_SET_VALUE as LONG_TERM_GOAL_FUND_SPLIT_SET_VALUE,
	SPLIT_PERCENTAGE_SET_VALID as LONG_TERM_GOAL_SPLIT_PERCENTAGE_SET_VALID,
	SPLIT_PERCENTAGE_SET_VALUE as LONG_TERM_GOAL_SPLIT_PERCENTAGE_SET_VALUE,
} from '../../../actions/investmentCalculator/longTermGoalSplit';
import { longTermGoalSplitFormRules } from './longTermGoalSplit';

export const investmentCalculatorRules = (store, next, action) => {
	// emergency fund
	if ([
		ONCE_OFF_SET_VALUE,
		ONCE_OFF_SET_VALID,
		MONTHLY_AMOUNT_SET_VALUE,
		MONTHLY_AMOUNT_SET_VALID,
	].includes(action.type))
		return emergencyFundCalculateFormRules(store, next, action);

    if([
		REGULAR_INCOME_ONCE_OFF_SET_VALUE,
		REGULAR_INCOME_MONTHLY_AMOUNT_SET_VALUE,
		REGULAR_INCOME_ONCE_OFF_SET_VALID,
		REGULAR_INCOME_MONTHLY_AMOUNT_SET_VALID,
	].includes(action.type))
        return generateRegularIncomeCalculatorFormRules(store, next, action);

	if ([
		BUILD_WEALTH_ONCE_OFF_SET_VALUE,
		BUILD_WEALTH_MONTHLY_AMOUNT_SET_VALUE,
		BUILD_WEALTH_ONCE_OFF_SET_VALID,
		BUILD_WEALTH_MONTHLY_AMOUNT_SET_VALID,
	].includes(action.type))
		return buildWealthCalculateFormRules(store, next, action);
	// split build wealth
	if ([
		EVEN_SPLIT_DISCARD_FUND_SPLIT,
		EVEN_SPLIT_DESELECT_FUND_SPLIT,
		EVEN_SPLIT_SELECT_FUND_SPLIT,
		SPLIT_PERCENTAGE_SET_VALID,
		EQUITY_FUND_SET_VALUE,
		BOND_FUND_SET_VALUE,
		FUND_SPLIT_SET_VALUE,
		FUND_SPLIT_SET_VALID
	].includes(action.type))
		return buildWealthSplitFormRules(store, next, action);

	if ([
		MONTHLY_AMOUNT_SET_VALID_SHORT_TERM_GOAL,
		MONTHLY_AMOUNT_SET_VALUE_SHORT_TERM_GOAL,
		ONCE_OFF_SET_VALID_SHORT_TERM_GOAL,
		ONCE_OFF_SET_VALUE_SHORT_TERM_GOAL
		].includes(action.type))
		return shortTermGoalFormRules(store, next, action);

	if ([
		SHORT_TERM_GOAL_EVEN_SPLIT_DISCARD_FUND_SPLIT,
		SHORT_TERM_GOAL_EVEN_SPLIT_DESELECT_FUND_SPLIT,
		SHORT_TERM_GOAL_EVEN_SPLIT_SELECT_FUND_SPLIT,
		SHORT_TERM_GOAL_FUND_SPLIT_SET_VALID,
		SHORT_TERM_GOAL_FUND_SPLIT_SET_VALUE,
		SHORT_TERM_GOAL_BOND_FUND_SET_VALUE,
		SHORT_TERM_GOAL_EQUITY_FUND_SET_VALUE,
		SHORT_TERM_GOAL_SPLIT_PERCENTAGE_SET_VALID
	].includes(action.type))
		return shortTermGoalSplitFormRules(store, next, action);

	if ([
		MONTHLY_AMOUNT_SET_VALID_LONG_TERM_GOAL,
		MONTHLY_AMOUNT_SET_VALUE_LONG_TERM_GOAL,
		ONCE_OFF_SET_VALID_LONG_TERM_GOAL,
		ONCE_OFF_SET_VALUE_LONG_TERM_GOAL
	].includes(action.type))
		return longTermGoalFormRules(store, next, action);

	if ([
		LONG_TERM_GOAL_EVEN_SPLIT_DISCARD_FUND_SPLIT,
		LONG_TERM_GOAL_EVEN_SPLIT_DESELECT_FUND_SPLIT,
		LONG_TERM_GOAL_EVEN_SPLIT_SELECT_FUND_SPLIT,
		LONG_TERM_GOAL_FUND_SPLIT_SET_VALID,
		LONG_TERM_GOAL_FUND_SPLIT_SET_VALUE,
		LONG_TERM_GOAL_SPLIT_PERCENTAGE_SET_VALUE,
		LONG_TERM_GOAL_SPLIT_PERCENTAGE_SET_VALID
	].includes(action.type))
		return longTermGoalSplitFormRules(store, next, action);

	return null;
};
