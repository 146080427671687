import {
    getSearchQuery,
    getSearchCategory,
    getPage,
    getPerPage,
    getSite,
    getReferrerPath,
} from '../../../selectors/publicWeb/search';

import {
    PERFORM_SEARCH,
    PERFORM_AUTO_COMPLETE,
    PERFORM_FREQUENT,
    setSearchResults,
    setCategory,
    setAutoCompleteResults,
    setFetchingState,
    setFrequent,
    setSearchItems,
    PERFORM_QUICKLINKS,
    setQuicklinks,
    performQueryLog,
} from '../../../actions/publicWeb/search';

import axios from 'axios';

const emptySearch = {
    'data': {
        'TotalDocument': 0,
        'Suggestions': [],
        'Facet': {
            'AllCount': 0,
            'Categories': [],
        },
        'Results': {
            'Results': [],
        },
    },
};

const header = {
    'Content-Type': 'application/json',
};

const emptyQuicklinks = { 'Results' : [] };

const emptyDropDown = { 'data': [] };

const jsonParse = obj => {
    if (!obj) {
        return null;
    }
    try {
        return JSON.parse(JSON.stringify(obj));
    } catch (e) {
        return null;
    }
}

const search = async (store) => {
    const state = store.getState();

    const bodyParams = {
        'query': getSearchQuery(state),
        'enableFacetFiltering': 'true',
        'category': getSearchCategory(state),
        'page': getPage(state),
        'perPage': getPerPage(state),
        'site': getSite(state),
        'referrerPath': getReferrerPath(state),
    };

    axios.post('/om-api/search', bodyParams, header)
    .then((result) => {
        store.dispatch(
            setSearchResults(
                jsonParse(result.data)
            )
        );
        store.dispatch(
            performQueryLog()
        );
        store.dispatch(
            setCategory(
                jsonParse(result.data.data.Facet.Categories)
            )
        );
    }).catch(() => {
        store.dispatch(
            setSearchResults(
                jsonParse(emptySearch)
            )
        );
    }).finally(() => {
        store.dispatch(setSearchItems(store.frequentVal));
    });
};

const autoComplete = async store => {
    const state = store.getState();
    const bodyParams = {
        'text': getSearchQuery(state),
        'site': getSite(state),
    };

    axios.post('/om-api/search/autocomplete', bodyParams, { headers: header }).
    then((result) => {
        store.dispatch(
            setAutoCompleteResults(
                jsonParse(result.data)
            )
        );

    }).catch(() => {
        store.dispatch(
            setAutoCompleteResults(
                jsonParse(emptyDropDown)
            )
        );

    }).finally(
        store.dispatch(
        setSearchItems(
            jsonParse(store.autoCompleteResults)
        )
    ));
};

const frequent = async store => {
    const state = store.getState();
    const bodyParams = {
        'text': getSearchQuery(state),
        'site': getSite(state),
    };

    if(!state.isFetching) {
        store.dispatch(setFetchingState(true))

        axios.post('/om-api/search/frequent', bodyParams, { headers: header }
        ).then((result) => {
            store.dispatch(
                setFrequent(
                    jsonParse(result.data)
                )
            );
        }).catch(() => {
            store.dispatch(
                setFrequent(
                    jsonParse(emptyDropDown)
                )
            );
        }).finally(() => {
            store.dispatch(setFetchingState(false))
        });
    }
};

const quicklinks = async store => {
    const state = store.getState();
    const bodyParams = {
        'text': getSearchQuery(state),
        'site': getSite(state),
    };

    if(!state.isFetching) {
        store.dispatch(setFetchingState(true))

        axios.post('/om-api/search/quicklinks', bodyParams , { headers: header }
        ).then((result) => {
            store.dispatch(
                setQuicklinks(
                    jsonParse(result.data)
                )
            );
        }).catch(() => {
            store.dispatch(
                setQuicklinks(
                    jsonParse(emptyQuicklinks)
                )
            );
        }).finally(() => {
            store.dispatch(setFetchingState(false))
        });
    }

};

export const searchService = (store, next, action) => {
    const result = next(action);

    (async () => {
        if (action.type === PERFORM_SEARCH) {
            search(store, action.shouldSetCategory);
        } else if (action.type === PERFORM_AUTO_COMPLETE) {
            autoComplete(store);
        } else if (action.type === PERFORM_FREQUENT) {
            frequent(store);
        } else if (action.type === PERFORM_QUICKLINKS) {
            quicklinks(store);
        }
    })();

    return result;
};
