// Page Load Transition Event
export const LOAD_DATA_TRANSITION_REFERENCE_DATA = 'taxFreeSavingsAccount/referenceData/transition/referenceDataLoad';
export const loadReferenceData = () => ({ type: LOAD_DATA_TRANSITION_REFERENCE_DATA });

// Events
const TAX_FREE_SAVINGS_ACCOUNT_SET_VALUE = 'taxFreeSavingsAccount/referenceData/setValue/';

export const SET_VALUE_REFERENCE_DATA = TAX_FREE_SAVINGS_ACCOUNT_SET_VALUE + 'referenceData';
export const SET_ERROR_MODAL_VALUE = TAX_FREE_SAVINGS_ACCOUNT_SET_VALUE + 'errorModal';

export const setReferenceData = value => ({ type: SET_VALUE_REFERENCE_DATA, value });
export const setErrorModalValue = value => ({ type: SET_ERROR_MODAL_VALUE, value });
