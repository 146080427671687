import {
    FORM_VALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS,
    FORM_INVALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS,
    FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALUE,
    FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALID,
    TOGGLE_SHOW_PRODUCT_OFFERING,
    FUTURE_VALUE_OF_SAVINGS_SHOW_RESULT_SUMMARY,
    FUTURE_VALUE_OF_SAVINGS_OPEN_MODAL,
    FUTURE_VALUE_OF_SAVINGS_CLOSE_MODAL,
    FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALUE,
    FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALID,
    EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE,
    EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID,
    FUTURE_SAVINGS_ANNUAL_SAVINGS_INCREASE_SET_VALUE,
    FUTURE_SAVINGS_ANNUAL_SAVINGS_INCREASE_SET_VALID,
    FUTURE_SAVINGS_EXPECTED_SAVINGS_GROWTH_SET_VALUE,
    FUTURE_SAVINGS_EXPECTED_SAVINGS_GROWTH_SET_VALID,
    RESET_CALCULATOR_FORM
} from '../../actions/savingsCalculator/theFutureValueOfYourSavings'

import {
    FIELD_STATUS,
    FORM_STATUS,
} from '../status';

const futureValueOfSavingsForm = {
    expectedEndDate:  { value: null, error: null, status: null },
    currentSavings: { value: null, error: null, status: null },
    expectedMonthlyContributions: { value: null, error: null, status: null },
}

export const initialState = {
    formStatus: FORM_STATUS.INVALID,
    showProductOffering: false,
    form: futureValueOfSavingsForm,
    openModal: false,
    showResultSummary: false
};

export default (state = initialState, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };
        
        case FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALUE:
            return updateFormField('expectedEndDate', field => ({
                ...field,
                value: action.value,
            }));
        case FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALID:
            return updateFormField('expectedEndDate', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

         // expected Monthly Contributions
        case EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE:
            return updateFormField('expectedMonthlyContributions', field => ({
                ...field,
                value: action.value,
            }));
        case EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID:
            return updateFormField('expectedMonthlyContributions', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALUE:
            return updateFormField('currentSavings', field => ({
                ...field,
                value: action.value,
            }));
        case FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALID:
            return updateFormField('currentSavings', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TOGGLE_SHOW_PRODUCT_OFFERING:
            return {
                ...state,
                showProductOffering: !state.showProductOffering,
            };

        case FUTURE_VALUE_OF_SAVINGS_OPEN_MODAL:
            return{
                ...state,
                openModal: true,
            }

        case FUTURE_VALUE_OF_SAVINGS_CLOSE_MODAL:
            return{
                ...state,
                openModal: false,
            }

                // reinitialise state
        case RESET_CALCULATOR_FORM:
            return initialState;

        // Show result summary
        case FUTURE_VALUE_OF_SAVINGS_SHOW_RESULT_SUMMARY:
            return {
                ...state,
                showResultSummary: true
            };

        default:
            return state;
    }
}
