import { validationIsEqual } from '../status';
import { validateSingleSelection } from '@om/validation';
import {
    DECLINED_PROPOSAL_SET_VALUE,
    DEFECT_VISION_SET_VALUE,
    INCREASED_PREMIUMS_SET_VALUE,
    POLICY_REJECTED_SET_VALUE,
    CONVICTED_SET_VALUE,
    STICKER_COLLECTION_SET_VALUE,
    setDeclinedProposalValid,
    setDefectVisionValid,
    setIncreasedPremiumsValid,
    setPolicyRejectedValid,
    setConvictedValid,
    setStickerCollectionValid
} from '../../../actions/ugMotorInsurance/declarations';

let prevValidationAction = null;
export const declarationsFormValidation = (store, next, action) => {
	const validationAction = (() => {
		let error;
		switch (action.type) {
			case DECLINED_PROPOSAL_SET_VALUE:
				error = validateSingleSelection(action.value, {
					subject: 'Declined Proposal',
					required: true,
				});
				return setDeclinedProposalValid(error);

			case DEFECT_VISION_SET_VALUE:
				error = validateSingleSelection(action.value, {
					subject: 'Defect Vision',
					required: true,
				});
				return setDefectVisionValid(error);

			case INCREASED_PREMIUMS_SET_VALUE:
				error = validateSingleSelection(action.value, {
					subject: 'Increased Premiums',
					required: true,
				});
				return setIncreasedPremiumsValid(error);

			case POLICY_REJECTED_SET_VALUE:
				error = validateSingleSelection(action.value, {
					subject: 'Policy Rejected',
					required: true,
				});
				return setPolicyRejectedValid(error);

			case CONVICTED_SET_VALUE:
				error = validateSingleSelection(action.value, {
					subject: 'Convicted',
					required: true,
				});
				return setConvictedValid(error);

			case STICKER_COLLECTION_SET_VALUE:
				error = validateSingleSelection(action.value, {
					subject: 'Sticker Collection',
					required: true,
				});
				return setStickerCollectionValid(error);

			default:
				return null;
		}
	})();

	if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
		return next(action);
	}

	store.dispatch(validationAction);
	prevValidationAction = validationAction;
	return next(action);
};
