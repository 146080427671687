import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from '../status';

import {
    ROA_TRAVEL_INSURANCE_SELECTED_VALUE,
    ROA_TRAVEL_INSURANCE_SELECTED_VALID,
    FORM_VALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM,
    FORM_INVALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM,
    REINITIALIZE,
} from '../../actions/roaTravelInsurance/travelPlan';

const initialeState = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        travelPlan: { value: null, error: null, status: null },
    },
};

export default (state = initialeState, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Selected Travel Plan
        case ROA_TRAVEL_INSURANCE_SELECTED_VALUE:
            return updateFormField('travelPlan', field => ({
                ...field,
                value: action.value,
            }));
        case ROA_TRAVEL_INSURANCE_SELECTED_VALID:
            return updateFormField('travelPlan', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case REINITIALIZE:
            return initialeState;
        default:
            return state;
    }
};
