import { createSelector } from 'reselect';
import { getAtRetirement } from "./index";
import { FORM_STATUS } from "../../reducers/status";

export const getBudgetTool = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.budgetTool
);

export const getBudgetToolFormIsValid = createSelector(
    getBudgetTool,
    budgetTool => budgetTool.formStatus === FORM_STATUS.VALID,
);

export const getBudgetToolForm = createSelector(
    getBudgetTool,
    budgetTool => budgetTool.form,
);

export const getBudgetValue = createSelector(
    getBudgetTool,
    budgetTool => budgetTool.budgetValue,
);

const makeGetField = fieldId => createSelector(
    getBudgetToolForm,
    budgetToolForm => budgetToolForm[fieldId]
);

export const getFixedMonthlyExpenses = makeGetField('fixedMonthlyExpenses');
export const getDayToDaySpending = makeGetField('dayToDaySpending');
export const getInsurance = makeGetField('insurance');
export const getDebts = makeGetField('debts');
