import { createSelector } from "reselect";
import { FORM_STATUS, FIELD_STATUS, TOGGLE_STATUS } from "../../reducers/status";
import { getRaOptimal } from "./index";
import { getIncludeDropoffDetails, getName as getCaptureDetailsName }  from "./captureDetails";
import * as types from "../../actions/raOptimal/types";
import { INVESTOR_TYPES, ANNUAL_PREMIUM_INCREASES } from "../../reducers/raOptimal/constants";
import { getReferenceAnnualPremiumIncreases, getReferenceFunds } from "./referenceData";

export const getInvestmentSetup = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.investmentSetup
);

export const getIsCvsVerified = createSelector(
    getInvestmentSetup,
    investmentSetup => investmentSetup.isCvsVerified,
);

export const getInvestmentSetupForm = createSelector(
    getInvestmentSetup,
    investmentSetup => investmentSetup.form,
);

export const getInvestmentSetupFormIsValid = createSelector(
    getInvestmentSetup,
    investmentSetup => investmentSetup.formStatus === FORM_STATUS.VALID,
);

// Determines dropdown list of retirement ages from age in form field
export const getRetirementAgeOptions = createSelector(
    getInvestmentSetupForm,
    (investmentSetupForm) => {
        if (investmentSetupForm.age && investmentSetupForm.age.status === FIELD_STATUS.VALID) {
            const age = investmentSetupForm.age ? +(investmentSetupForm.age.value) : 0;
            const minAge = Math.max(types.MIN_RETIREMENT_AGE, age + types.MIN_PRODUCT_TERM);
            const nOptions = types.MAX_RETIREMENT_AGE - minAge + 1;
            if (nOptions > 0) {
                return Array(nOptions).fill()
                    .map((_, index) => minAge + index);
            }
        }
        return [];
    }
);

const makeGetField = fieldId => createSelector(
    getInvestmentSetupForm,
    investmentSetupForm => investmentSetupForm[fieldId]
);

export const getAge = makeGetField('age');
export const getRetirementAge = makeGetField('retirementAge');
export const getMonthlyInvestment = makeGetField('monthlyInvestment');
export const getOwnPercentage = makeGetField('ownPercentage');
export const getYearlyIncrease = makeGetField('yearlyIncrease');
export const getTypeOfInvestor = makeGetField('typeOfInvestor');

export const getMonthlyInvestmentDerivedTerm = createSelector(
    getInvestmentSetup,
    investmentSetup => investmentSetup.monthlyInvestmentDerivedTerm
);

export const getMonthlyInvestmentCommittedTerm = createSelector(
    getInvestmentSetup,
    investmentSetup => investmentSetup.monthlyInvestmentCommittedTerm
);

export const makeYearlyIncreaseSelector = select => createSelector(
    getYearlyIncrease,
    yearlyIncrease => yearlyIncrease[select]
);
export const makeTypeOfInvestorSelector = select => createSelector(
    getTypeOfInvestor,
    typeOfInvestor => typeOfInvestor[select]
);

export const getLinkWithInflation = makeYearlyIncreaseSelector('linkWithInflation');
export const getChooseOwnPercentage = makeYearlyIncreaseSelector('chooseOwnPercentage');
export const getNoIncrease = makeYearlyIncreaseSelector('noIncrease');
export const getPremiumEscalationError = makeYearlyIncreaseSelector('error');

export const getLinkWithInflationIsSelected = createSelector(
    getLinkWithInflation,
    option => option === TOGGLE_STATUS.YES
);
export const getChooseOwnPercentageIsSelected = createSelector(
    getChooseOwnPercentage,
    option => option === TOGGLE_STATUS.YES
);
export const getNoIncreaseIsSelected = createSelector(
    getNoIncrease,
    option => option === TOGGLE_STATUS.YES
);

export const getComfortableInvestor = makeTypeOfInvestorSelector('comfortable');
export const getAdventurousInvestor = makeTypeOfInvestorSelector('adventurous');
export const getInvestorTypeError = makeTypeOfInvestorSelector('error');

export const getComfortableInvestorIsSelected = createSelector(
    getComfortableInvestor,
    investor => investor === TOGGLE_STATUS.YES
);

export const getAdventurousInvestorIsSelected = createSelector(
    getAdventurousInvestor,
    investor => investor === TOGGLE_STATUS.YES
);

export const getFactSheetClicked = createSelector(
    getInvestmentSetup,
    investmentSetup => investmentSetup.factSheetClicked,
);

// returns radioButton id based on which yearlyIncrease is selected
export const getSelectedIncreaseRadioId = createSelector(
    getLinkWithInflationIsSelected,
    getChooseOwnPercentageIsSelected,
    getNoIncreaseIsSelected,
    getPremiumEscalationError,
    (linkWithInflationIsSelected, chooseOwnPercentageIsSelected, noIncreaseIsSelected, premiumEscalationError) => {
        if(premiumEscalationError === null ){
            if(linkWithInflationIsSelected === TOGGLE_STATUS.YES) return '1';
            if(chooseOwnPercentageIsSelected === TOGGLE_STATUS.YES) return '2';
            if(noIncreaseIsSelected === TOGGLE_STATUS.YES) return '3';
        }
        return;
    }
);


//Returns the radio button Id based on which type of investor is selected
export const getInvestorType = createSelector(
    getComfortableInvestorIsSelected,
    getAdventurousInvestorIsSelected,
    (comfortableInvestor, adventurousInvestor) => {
        if (comfortableInvestor) {
            return '1';
        } else if (adventurousInvestor) {
            return '2';
        }
        return null;
    }
);

//Returns the radio button tex based on which type of investor is selected
export const getInvestorTypeText = createSelector(
    getComfortableInvestorIsSelected,
    getAdventurousInvestorIsSelected,
    (comfortableInvestor, adventurousInvestor) => {
        if (comfortableInvestor) {
            return 'Comfortable';
        } else if (adventurousInvestor) {
            return 'Adventurous';
        }
        return '';
    }
);

// selector return true if increase section is complete and false otherwise
export const getSelectedIncreaseComplete = createSelector(
    getLinkWithInflationIsSelected,
    getChooseOwnPercentageIsSelected,
    getNoIncreaseIsSelected,
    getPremiumEscalationError,
    getOwnPercentage,
    (linkWithInflationIsSelected, chooseOwnPercentageIsSelected, noIncreaseIsSelected, premiumEscalationError, ownPercentage) =>  {
        if(premiumEscalationError === null){
            if(linkWithInflationIsSelected === TOGGLE_STATUS.YES) return true;
            if(noIncreaseIsSelected === TOGGLE_STATUS.YES) return true;
            if(chooseOwnPercentageIsSelected === TOGGLE_STATUS.YES && ownPercentage.error === null && ownPercentage.value !== null) return true;
        }
        return false;
    }
);

// use drop off details to return slightly different strings, regarding premium escalation
export const getPremiumEscalationText = createSelector(
    getIncludeDropoffDetails,
    getCaptureDetailsName,
    (dropOffDetailsIncluded, captureDetailsName) => {
        if(dropOffDetailsIncluded){
            return captureDetailsName.value + ", to keep up with the cost of living, most customers choose to increase the amount they save each year."
        }
        return "To keep up with the cost of living, most customers choose to increase the amount they save each year.";
    }
);
export const getNoIncreaseAnnualPremium = createSelector(
    getReferenceAnnualPremiumIncreases,
    referenceAnnualPremiumIncreases => referenceAnnualPremiumIncreases ? referenceAnnualPremiumIncreases.find(premium => premium.code === ANNUAL_PREMIUM_INCREASES.NO_INCREASE) : null
);
export const getLinkWithInflationAnnualPremium = createSelector(
    getReferenceAnnualPremiumIncreases,
    referenceAnnualPremiumIncreases => referenceAnnualPremiumIncreases ? referenceAnnualPremiumIncreases.find(premium => premium.code === ANNUAL_PREMIUM_INCREASES.LINK_WITH_INFLATION) : null
);
export const getOwnPercentageAnnualPremium = createSelector(
    getReferenceAnnualPremiumIncreases,
    referenceAnnualPremiumIncreases => referenceAnnualPremiumIncreases ? referenceAnnualPremiumIncreases.find(premium => premium.code === ANNUAL_PREMIUM_INCREASES.OWN_PERCENTAGE) : null
);
export const getPremiumPercentages = createSelector(
    getOwnPercentageAnnualPremium,
    ownPercentageAnnualPremium => ownPercentageAnnualPremium ? ownPercentageAnnualPremium.percentages : null
);

export const getYearlyPaymentIncrease = createSelector(
    getLinkWithInflationIsSelected,
    getChooseOwnPercentageIsSelected,
    getOwnPercentage,
    getNoIncreaseIsSelected,
    getNoIncreaseAnnualPremium,
    getLinkWithInflationAnnualPremium,
    getPremiumPercentages,
    (linkWithInflation, chooseOwnPercentage, ownPercentage, noIncrease, noIncreaseAnnualPremium, linkWithInflationAnnualPremium, premiumPercentages) => {
        if (linkWithInflation) return linkWithInflationAnnualPremium.description;
        if (noIncrease) return noIncreaseAnnualPremium.description;
        if (chooseOwnPercentage) {
            const displayValue = premiumPercentages.find(data => data.code === ownPercentage.value).description;
            return 'Fixed rate @' + displayValue + '%';
        }

        return '';
    }
);

export const getInvestmentFund = createSelector(
    getComfortableInvestorIsSelected,
    getAdventurousInvestorIsSelected,
    getReferenceFunds,
    (comfortableInvestor, adventurousInvestor, funds) => {        
        if (!funds) return '';

        const findFund = investorType => funds.find(fund => fund.riskProfileCode === investorType).fundName;
        if (comfortableInvestor) return findFund(INVESTOR_TYPES.COMFORTABLE);
        if (adventurousInvestor) return findFund(INVESTOR_TYPES.ADVENTUROUS);
    }
);
