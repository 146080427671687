import {findOtherEventIndex, otherEventTracking} from '../otherEventTracking';
import {CALCULATE_TRANSITION_LOAN_RESULTS} from '../../../actions/personalLoansCalculator/calculator';

export const personalLoansFormTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type) {
        case CALCULATE_TRANSITION_LOAN_RESULTS:
            otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            return 'Personal Loans';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}