import {BUDGET_BENCHMARK_CALCULATOR_SET_VALUE} from "../../../actions/budgetBenchmarkCalculator/calculator";
import {budgetBenchmarkValidationRules} from "./calculator";

export const budgetBenchmarkValidation = (store, next, action) => {

    if (action.type.startsWith(BUDGET_BENCHMARK_CALCULATOR_SET_VALUE)) {
        return budgetBenchmarkValidationRules(store, next, action);
    }

    return null;
};
