import {
    BUILDING_SET_VALUE,
    CITY_SET_VALUE,
    COMPANY_NAME_SET_VALUE,
    EMAIL_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    ID_PASSPORT_SET_VALUE,
    KRA_PIN_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    PO_BOX_SET_VALUE,
    POSTAL_CODE_SET_VALUE,
    setBuildingValid,
    setCityValid,
    setCompanyNameValid,
    setEmailValid,
    setFirstNameValid,
    setIdPassortNumberValid,
    setKraPinNumberValid,
    setMobileNumberValid,
    setPostalBoxValid,
    setPostalCodeValid,
    setStreetAddressValid,
    setSurnameValid,
    STREET_ADDRESS_SET_VALUE,
    SURNAME_SET_VALUE,
} from '../../../actions/biasharaFlexi/companyDetails';
import { getIsPayNow } from '../../../selectors/biasharaFlexi/coverDetails';
import { validationIsEqual } from '../status';
import {
    validateEmail,
    validateName,
    validateContactNumber,
    validateAlphaNumericString,
    validateKraPinNumber,
    validatePostalCode,
    validatePoBoxNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateNumericString,
    validateFirstName,
    validateSurname,
    validatePassportNumber,
    validateAlphaString,
} from '@om/validation';

let prevValidationAction = null;
export const companyDetailsValidation = (store, next, action) => {
    const isPayNow = getIsPayNow(store.getState());
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case COMPANY_NAME_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Company Name',
                    required: true,
                });
                return setCompanyNameValid(error);

            case FIRST_NAME_SET_VALUE:
                error = validateFirstName(action.value, {
                    subject: 'First Name',
                    required: true,
                });
                return setFirstNameValid(error);

            case SURNAME_SET_VALUE:
                error = validateSurname(action.value, {
                    subject: 'Last Name',
                    required: true,
                });
                return setSurnameValid(error);

            case ID_PASSPORT_SET_VALUE:
                const error1 = validateNumericString(action.value, {
                    min: 8,
                    max: 15,
                    subject: 'ID number',
                    required: true,
                });
                const error2 = validatePassportNumber(action.value, { subject: 'Passport number', required: true });
                error = !error1 && !error2 ? null : !error2 ? null : error1;
                return setIdPassortNumberValid(error);

            case KRA_PIN_NUMBER_SET_VALUE:
                error = validateKraPinNumber(action.value, {
                    subject: 'KRA Pin Number',
                    required: isPayNow ? false : true,
                });
                return setKraPinNumberValid(error);

            case MOBILE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Mobile Number',
                    required: true,
                });
                return setMobileNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case CITY_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'City',
                    required: isPayNow ? false : true,
                });
                return setCityValid(error);

            case STREET_ADDRESS_SET_VALUE:
                error = validateAlphaNumericString(action.value, { subject: 'Street Address', required: false });
                return setStreetAddressValid(error);

            case BUILDING_SET_VALUE:
                error = validateAlphaNumericString(action.value, { subject: 'Building', required: false });
                return setBuildingValid(error);

            case POSTAL_CODE_SET_VALUE:
                error = validateNumericString(action.value, {
                    subject: 'Postal Code',
                    required: false,
                    min: 5,
                });
                return setPostalCodeValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
