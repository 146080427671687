import { STORE_AFFORDABILITY_DETAIL, GROSS_INCOME, NET_INCOME, GROCERIES, UTILITIES, INSURANCE_AND_SAVINGS, COMMUNICATION_AND_SERVICES, TRANSPORT, OTHER, TOTAL_MONTHLY_LIVING_EXPENSE, CAPITAL_NEEDED, INFORMATION_CONFIRMATION, STORE_AFFORDABILITY_DETAIL_VALIDATION, HOUSING, SUPPORT_TO_OTHERS } from "../../actions/digitalOnboarding/types";

const expensesKeys = [GROCERIES, UTILITIES, INSURANCE_AND_SAVINGS, COMMUNICATION_AND_SERVICES, TRANSPORT, HOUSING, SUPPORT_TO_OTHERS, OTHER];
const isAnExpenseValue = value => expensesKeys.includes(value);

const storeValues = {
    [GROSS_INCOME]: { text: '', numeric: 0, isValid: false, errorMessage: '' },
    [NET_INCOME]: { text: '', numeric: 0, isValid: false, errorMessage: '', pristine: true },
    [GROCERIES]: { text: '', numeric: 0, isValid: true, errorMessage: '' },
    [UTILITIES]: { text: '', numeric: 0, isValid: true, errorMessage: '' },
    [INSURANCE_AND_SAVINGS]: { text: '', numeric: 0, isValid: true, errorMessage: '' },
    [COMMUNICATION_AND_SERVICES]: { text: '', numeric: 0, isValid: true, errorMessage: '' },
    [TRANSPORT]: { text: '', numeric: 0, isValid: true, errorMessage: '' },
    [HOUSING]:{ text: '', numeric: 0, isValid: true, errorMessage: '' },
    [SUPPORT_TO_OTHERS]:{ text: '', numeric: 0, isValid: true, errorMessage: '' },
    [OTHER]: { text: '', numeric: 0, isValid: true, errorMessage: '' },
    [CAPITAL_NEEDED]: { text: '', numeric: 0, isValid: false, errorMessage: '' },
    [INFORMATION_CONFIRMATION]: {text: '',  numeric: 0, isValid: false, errorMessage: '' },
    [TOTAL_MONTHLY_LIVING_EXPENSE]: { text: '', numeric: 0, isValid: true, errorMessage: '' }
};

export const initAffordabilityDetails = {
    storeValues: storeValues,
}

const calculateTotalLivingExpenses = affordability => {
    return Object.entries(affordability).reduce(
        (total, [key, value]) => isAnExpenseValue(key) ? total + value.numeric : total,
    0);
}

const isLivingExpenseValid = (value) => {
    const isValid = value > 0;
    return [
        isValid,
        isValid ? '' : 'Please enter an amount for at least one living expense.'
    ];
}

const updateTotalLivingExpenses = store => {
    const total = calculateTotalLivingExpenses(store.storeValues);
    const [isValid, message] = isLivingExpenseValid(total);

    store.storeValues[TOTAL_MONTHLY_LIVING_EXPENSE] = { text: total, numeric: +total, isValid: isValid, errorMessage: message };
    return store;
};

export default (state = initAffordabilityDetails, action) => {
    const storeValueToStore = value => ({
        ...state,
        storeValues: {
            ...state.storeValues,
            ...value
        }
    });

    const updateValidationFromFields = fields => fields.reduce(
        (s, field) => {
            const storeValue = { ...s[field.key], isValid: field.isValid, errorMessage: field.errorMessage };
            if (field.value) {
                storeValue[field.key].value = field.value;
            }
            return { ...s, [field.key]: storeValue };
        },
        { ...state.storeValues }
    );

    switch (action.type) {
        case STORE_AFFORDABILITY_DETAIL_VALIDATION: {
            const storeValue = updateValidationFromFields(action.fields);
            return storeValueToStore(storeValue);
        }
        case STORE_AFFORDABILITY_DETAIL: {
            const store = storeValueToStore({ [action.key]: { text: action.value, numeric: +action.value, isValid: action.isValid, errorMessage: action.errorMessage, pristine: false } });
            return updateTotalLivingExpenses(store);
        }
        default:
            break;
    }

    return state;
}
