import { createSelector } from "reselect";
import { getServiceProvider, getPrivacyPolicyIsChecked, getSubscriberAgreementIsChecked, getTermOfUseIsChecked } from "./consent"
import { getCustomerVerificationService } from "./index";
import { FORM_STATUS, FIELD_STATUS, TOGGLE_STATUS } from "../../reducers/status";

export const getIDVerification = createSelector(
    getCustomerVerificationService,
    cvs => cvs.idVerification,
);

export const getIDVerificationPageValid = createSelector(
    getIDVerification,
    idVerificationPage => idVerificationPage.formStatus === FORM_STATUS.VALID,
);

export const getVerificationCallError = createSelector(
    getIDVerification,
    idVerification => idVerification.verificationCallError && !!idVerification.verificationCallError.error
);

export const getVerificationCallSuccess = createSelector(
    getIDVerification,
    idVerification => !!idVerification.verificationCallSuccess,
);

export const getWaitingForVerificationCall = createSelector(
    getIDVerification,
    idVerification => !!idVerification.waitingForVerificationCall,
);

export const getOpenGoodToGoModal = createSelector(
    getIDVerification,
    selfie => selfie.openGoodToGoModal,
);

export const getIDVerificationForm = createSelector(
    getIDVerification,
    idVerification => idVerification && idVerification.form,
);

export const getFirstName = createSelector(
    getIDVerification,
    idVerification => idVerification.firstName
);

export const getLastName = createSelector(
    getIDVerification,
    idVerification => idVerification.lastName
);

const makeGetField = fieldId => createSelector(
    getIDVerificationForm,
    idVerificationForm => idVerificationForm[fieldId]
);

export const getID = makeGetField('idNumber');

export const getIDVerificationPayload = createSelector(
    getServiceProvider,
    getID,
    getPrivacyPolicyIsChecked,
    getSubscriberAgreementIsChecked,
    getTermOfUseIsChecked,
    (serviceProvider, idNumber, privacyPolicyIsChecked, subscriberAgreementIsChecked, termsOfUseIsChecked) => {
        return {
            serviceProvider: serviceProvider,
            identityNumber: idNumber.value,
            acceptedPrivacyPolicy: privacyPolicyIsChecked,
            acceptedSubscriberAgreement: subscriberAgreementIsChecked,
            acceptedTermsOfUse: termsOfUseIsChecked,
        };
    }
);

export const getIsIDValid = createSelector(
    getID,
    idNumber => {
        return idNumber && idNumber.status === FIELD_STATUS.VALID;
    }
);

export const getApiToken = createSelector(
    getIDVerification,
    idVerification => idVerification.apiToken,
);

export const getIdentityToken = createSelector(
    getIDVerification,
    idVerification => idVerification.identityToken,
);

export const getReferenceGuid = createSelector(
    getIDVerification,
    idVerification => idVerification.referenceGuid,
);