import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    INPATIENT_COVER_LIMIT_SET_VALUE,
    INPATIENT_COVER_LIMIT_SET_VALID,
    OUTPATIENT_COVER_LIMIT_SET_VALUE,
    OUTPATIENT_COVER_LIMIT_SET_VALID,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    FORM_VALID_AFYA_IMARA_SENIOR_QUOTE_TOOL,
    FORM_INVALID_AFYA_IMARA_SENIOR_QUOTE_TOOL,
    COLLECT_AFYA_IMARA_SENIOR_DATA,
    CALCULATE_AFYA_IMARA_SENIOR_DATA,
    API_PENDING_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL,
    API_FAILURE_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL,
} from "../../actions/roaQuoteTools/afyaImaraSeniorQuoteTool";

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.VALID,
    quotationSummary: null,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Afyaimara Family Senior',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address']
    },
    form: {
        inpatientCoverLimit: { value: '500000', error: null, status: 'valid' },
        outpatientCoverLimit: { value: '0', error: null, status: 'valid' },
        coverId: { value: 2, error: null, status: 'valid'},
        ageGroup: { value: '65_80', error: null, status: 'valid'},
        childrenStatus: { value: 'no', error: null, status: 'valid'}
    },
};

export default (state = initialeState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_AFYA_IMARA_SENIOR_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_AFYA_IMARA_SENIOR_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        // Api call
        case API_PENDING_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        // inpatient cover limit
        case INPATIENT_COVER_LIMIT_SET_VALUE:
            return updateFormField('inpatientCoverLimit', field => ({
                ...field,
                value: action.value,
            }));
        case INPATIENT_COVER_LIMIT_SET_VALID:
            return updateFormField('inpatientCoverLimit', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // inpatient cover limit
        case OUTPATIENT_COVER_LIMIT_SET_VALUE:
            return updateFormField('outpatientCoverLimit', field => ({
                ...field,
                value: action.value,
            }));
        case OUTPATIENT_COVER_LIMIT_SET_VALID:
            return updateFormField('outpatientCoverLimit', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Select Form
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value
            };
        case REINITIALIZE:
            return initialeState
        case CALCULATE_AFYA_IMARA_SENIOR_DATA:
            return {
                ...state,
                quotationSummary: action.value
            }
        case COLLECT_AFYA_IMARA_SENIOR_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        default:
            return state;
    }
}
