import {
    SAVINGS_POT_CALCULATOR_FORM_SET_VALUE,
    SAVINGS_POT_CALCULATOR_FORM_SET_VALID,
} from '../../../actions/savingsPotCalculator/savingsPotCalculatorForm';
import { savingsPotCalculatorFormRules } from './savingsPotCalculatorForm';

export const savingsPotCalculatorRules = (store, next, action) => {
    if (
        action.type.startsWith(SAVINGS_POT_CALCULATOR_FORM_SET_VALUE) ||
        action.type.startsWith(SAVINGS_POT_CALCULATOR_FORM_SET_VALID)
    )
        return savingsPotCalculatorFormRules(store, next, action);
};