import { createSelector } from 'reselect';
import { getRoaTravelInsuranceState } from './index';

export const getReferenceData = createSelector(
    getRoaTravelInsuranceState,
    roaTravelInsurance => roaTravelInsurance.referenceData,
);

export const getApplicationReferenceData = createSelector(
    getReferenceData,
    referenceData => referenceData.applicationData,
);

// Field selectors
const makeGetField = fieldId =>
    createSelector(getApplicationReferenceData, data => data[fieldId]);

export const getReferenceTitles = makeGetField('titles');
export const getReferenceCountries = makeGetField('countries');
export const getReferenceNationalities = makeGetField('nationalities');

// Validates the application reference data
export const getIsApplicationReferenceDataValid = createSelector(
    getApplicationReferenceData,
    applicationReferenceData => {
        return (
            applicationReferenceData &&
            Object.keys(applicationReferenceData).length !== 0 &&
            applicationReferenceData.titles &&
            applicationReferenceData.titles.length !== 0 &&
            applicationReferenceData.nationalities &&
            applicationReferenceData.nationalities.length !== 0
        );
    },
);

export const getIsCountriesReferenceDataValid = createSelector(
    getApplicationReferenceData,
    applicationReferenceData => {
        return (
            applicationReferenceData &&
            Object.keys(applicationReferenceData).length !== 0 &&
            Object.keys(applicationReferenceData).includes('countries') &&
            applicationReferenceData.countries &&
            applicationReferenceData.countries.length !== 0
        );
    },
);
