import {
    CUSTOMER_SATISFACTION_SURVEY_SET_VALUE,
    CUSTOMER_SATISFACTION_SURVEY_SET_VALID
} from "../../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm"
import {customerSatisfactionSurveyFormRules} from "./customerSatisfactionSurveyForm"

export const customerSatisfactionSurveyRules = (store, next, action) => {

    if (action.type.startsWith(CUSTOMER_SATISFACTION_SURVEY_SET_VALUE) ||
        action.type.startsWith(CUSTOMER_SATISFACTION_SURVEY_SET_VALID))
        return customerSatisfactionSurveyFormRules(store, next, action);
}
