export const BENEFICIARIES_SPLIT_SET_VALUE = 'taxFreeSavingsAccount/beneficiariesSplit/setValue/';
export const BENEFICIARIES_SPLIT_SET_VALID = 'taxFreeSavingsAccount/beneficiariesSplit/setValid/';

export const FORM_SET_VALUE = BENEFICIARIES_SPLIT_SET_VALUE + 'form';
export const setFormValue = form => ({ type: FORM_SET_VALUE, form });

export const EVEN_SPLIT_TOGGLE_VALUE = BENEFICIARIES_SPLIT_SET_VALUE + 'evenSplitToggle';
export const EVEN_SPLIT_DESELECT_VALUE = BENEFICIARIES_SPLIT_SET_VALUE + 'evenSplitDeselect';
export const toggleEvenSplitValue = () => ({ type: EVEN_SPLIT_TOGGLE_VALUE });
export const deselectEvenSplit = () => ({ type: EVEN_SPLIT_DESELECT_VALUE });

export const SPLIT_PERCENTAGE_SET_VALUE = BENEFICIARIES_SPLIT_SET_VALUE + 'splitPercentage';
export const SPLIT_PERCENTAGE_SET_VALID = BENEFICIARIES_SPLIT_SET_VALID + 'splitPercentage';
export const setSplitPercentageValue = (value, index) => ({ type: SPLIT_PERCENTAGE_SET_VALUE, value, index});
export const setSplitPercentageValid = (error, index) => ({ type: SPLIT_PERCENTAGE_SET_VALID, error, index});

export const RESET_BENEFICIARIES_SPLIT = BENEFICIARIES_SPLIT_SET_VALUE + 'resetState';
export const resetBeneficiariesSplit = () => ({ type: RESET_BENEFICIARIES_SPLIT });