import {createSelector} from "reselect";
import {getRaOptimal} from "./index";
import {FORM_STATUS} from "../../reducers/status";

export const getCaptureDetails = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.captureDetails
);

export const getDropoffDetailsValid = createSelector(
    getCaptureDetails,
    captureDetails => captureDetails.formStatus === FORM_STATUS.VALID,
);

export const getIncludeDropoffDetails = createSelector(
    getCaptureDetails,
    captureDetails => captureDetails.includeDropoffDetails,
);

export const getCaptureDetailsForm = createSelector(
    getCaptureDetails,
    captureDetails => captureDetails.form,
);

const makeGetField = fieldId => createSelector(
    getCaptureDetailsForm,
    captureDetailsForm => captureDetailsForm[fieldId]
);

export const getDisplayName = makeGetField('displayName');
export const getName = makeGetField('name');
export const getSurname = makeGetField('surname');
export const getMobile = makeGetField('mobile');

export const getDropOffDetails = createSelector(
    getCaptureDetails,
    captureDetails => captureDetails.dropOffDetails,
);

export const getConfirmPageMobileNumber = createSelector(
    getMobile,
    mobile => mobile.value ? ('+27 ' + mobile.value.substr(0,3) + ' ' + mobile.value.substr(3,3) + ' ' + mobile.value.substr(6,4)) : ''
);