import {beneficiariesValidation} from "./beneficiaries";
import {beneficiariesSplitValidation} from "./beneficiariesSplit";
import {captureDetailsValidation} from "./captureDetails";
import {investmentSetupValidation} from "./investmentSetup";
import {paymentDetailsValidation} from "./paymentDetails";
import {personalDetailsValidation} from "./personalDetails";
import {reviewValidation} from "./review";

import {BENEFICIARIES_SET_VALUE} from "../../../actions/raOptimal/beneficiaries";
import {BENEFICIARIES_SPLIT_SET_VALUE} from "../../../actions/raOptimal/beneficiariesSplit";
import {CAPTURE_DETAILS_SET_VALUE} from "../../../actions/raOptimal/captureDetails";
import {INVESTMENT_SETUP_SET_VALUE} from "../../../actions/raOptimal/investmentSetup";
import {PAYMENT_DETAILS_SET_VALUE} from "../../../actions/raOptimal/paymentDetails";
import {PERSONAL_DETAILS_SET_VALUE} from "../../../actions/raOptimal/personalDetails";
import {TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW, TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW} from "../../../actions/raOptimal/review";

export const raOptimalValidation = (store, next, action) => {    

    if (action.type.startsWith(BENEFICIARIES_SET_VALUE))
        return beneficiariesValidation(store, next, action);

    if (action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE))
        return beneficiariesSplitValidation(store, next, action);

    if (action.type.startsWith(CAPTURE_DETAILS_SET_VALUE))
        return captureDetailsValidation(store, next, action);

    if (action.type.startsWith(INVESTMENT_SETUP_SET_VALUE))
        return investmentSetupValidation(store, next, action);

    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE))
        return paymentDetailsValidation(store, next, action);

    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE))
        return personalDetailsValidation(store, next, action);

    if (action.type.startsWith(TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW) ||
        action.type.startsWith(TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW))
        return reviewValidation(store, next, action);

    return null;
}