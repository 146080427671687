export const BUILD_WEALTH_CALCULATOR_TRANSITION = 'investmentCalculator/BuildWealth/transition/'

export const FORM_VALID_BUILD_WEALTH_TRANSITION = 'investmentCalculator/BuildWealth/formValid';
export const FORM_INVALID_BUILD_WEALTH_TRANSITION = 'investmentCalculator/BuildWealth/formInvalid';

export const transitionBuildWealthCalculator = {
    formValid: () => ({ type: FORM_VALID_BUILD_WEALTH_TRANSITION }),
    formInvalid: () => ({ type: FORM_INVALID_BUILD_WEALTH_TRANSITION }),
};

export const BUILD_WEALTH_CALCULATOR_SET_VALUE = 'investmentCalculator/BuildWealth/setValue/';
export const BUILD_WEALTH_CALCULATOR_SET_VALID = 'investmentCalculator/BuildWealth/setValid/';

export const REINITIALIZE_STATE_BUILD_WEALTH_CALCULATOR = 'investmentCalculator/BuildWealth/reinitialize';

export const ONCE_OFF_SET_VALUE = BUILD_WEALTH_CALCULATOR_SET_VALUE + 'onceOff';
export const ONCE_OFF_SET_VALID = BUILD_WEALTH_CALCULATOR_SET_VALID + 'onceOff';
export const MONTHLY_AMOUNT_SET_VALUE = BUILD_WEALTH_CALCULATOR_SET_VALUE + 'monthlyAmount';
export const MONTHLY_AMOUNT_SET_VALID = BUILD_WEALTH_CALCULATOR_SET_VALID + 'monthlyAmount';
export const RESULTS_VIEW_VALUE = BUILD_WEALTH_CALCULATOR_SET_VALUE + 'viewResults';

export const RESULTS_SET_VALUE = BUILD_WEALTH_CALCULATOR_SET_VALUE + 'setResults';
export const CALCULATE_BUILD_WEALTH = BUILD_WEALTH_CALCULATOR_SET_VALUE + 'success';
export const RESULTS_SET_VALUE_FAILURE = BUILD_WEALTH_CALCULATOR_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_BUILD_WEALTH_CALCULATOR = `${BUILD_WEALTH_CALCULATOR_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_BUILD_WEALTH_CALCULATOR = `${BUILD_WEALTH_CALCULATOR_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_BUILD_WEALTH_CALCULATOR = `${BUILD_WEALTH_CALCULATOR_SET_VALUE}/apiTransition/failure`;
export const apiTransitionBuildWealthCalculator = {
    pending: () => ({ type: API_PENDING_TRANSITION_BUILD_WEALTH_CALCULATOR }),
    success: () => ({ type: API_SUCCESS_TRANSITION_BUILD_WEALTH_CALCULATOR }),
    failure: () => ({ type: API_FAILURE_TRANSITION_BUILD_WEALTH_CALCULATOR }),
};

export const getBuildWealthResult = () => ({ type: RESULTS_SET_VALUE });
export const calculateBuildWealth = (value) => ({ type: CALCULATE_BUILD_WEALTH, value});
export const getBuildWealthResultFailure = error => ({ type: RESULTS_SET_VALUE_FAILURE, error });

export const setBuildWealthOnceOffValue = value => ({ type: ONCE_OFF_SET_VALUE, value });
export const setBuildWealthOnceOffValid = error => ({ type: ONCE_OFF_SET_VALID, error });
export const setMonthlyValue = value => ({ type: MONTHLY_AMOUNT_SET_VALUE, value });
export const setMonthlyValid = error => ({ type: MONTHLY_AMOUNT_SET_VALID, error });
export const setViewBuildWealthResult = value => ({ type: RESULTS_VIEW_VALUE, value });

export const reInitialize = () => ({ type: REINITIALIZE_STATE_BUILD_WEALTH_CALCULATOR });
