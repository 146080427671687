export const CAR_INSURANCE_MANUAL_MPESA_PAYMENTS = 'roaCarInsurance/manualMpesaPayments';
export const CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE = CAR_INSURANCE_MANUAL_MPESA_PAYMENTS + '/setValue/';
export const CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALID = CAR_INSURANCE_MANUAL_MPESA_PAYMENTS + '/setValid/';
export const REINITIALIZE = CAR_INSURANCE_MANUAL_MPESA_PAYMENTS + '/state/reinitialize';

// Valid transition
export const mpesaTransactionCodeValid = () => ({
    type: FORM_VALID_MPESA_TRANSACTION_FORM
});

// Check mpesa transaction code API calls
export const API_PENDING_TRANSITION_MANUAL_MPESA_PAYMENTS = `${CAR_INSURANCE_MANUAL_MPESA_PAYMENTS}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_MANUAL_MPESA_PAYMENTS = `${CAR_INSURANCE_MANUAL_MPESA_PAYMENTS}/apiTransition/success`;
export const API_FAILURE_TRANSITION_MANUAL_MPESA_PAYMENTS = `${CAR_INSURANCE_MANUAL_MPESA_PAYMENTS}/apiTransition/failure`;
export const API_INITIALIZE_TRANSITION_MANUAL_MPESA_PAYMENTS = `${CAR_INSURANCE_MANUAL_MPESA_PAYMENTS}/apiTransition/initialize`;
export const apiTransitionManualPaymentsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_MANUAL_MPESA_PAYMENTS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_MANUAL_MPESA_PAYMENTS ,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_MANUAL_MPESA_PAYMENTS ,
    }),
    initialize: () => ({
        type: API_INITIALIZE_TRANSITION_MANUAL_MPESA_PAYMENTS
    })
};

// Manual MPESA calculated data
export const COLLECT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS = CAR_INSURANCE_MANUAL_MPESA_PAYMENTS + '/serviceTrigger/'
export const SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_SUCCESS =CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE + 'manual/payments/success';
export const SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_FAILURE = CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALID +  'manual/payments/failure';

export const collectCarInsuranceManualPaymentsData = () => ({
           type: COLLECT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS,
       });

export const submitCarInsuranceManualPaymentsSuccess = (value) => ({
           type: SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_SUCCESS,
           value,
       });
export const submitCarInsuranceManualPaymentsFailure = (error) => ({
           type: SUBMIT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS_FAILURE,
           error,
       }); 


// Set transaction code 
export const TRANSACTION_CODE_SET_VALUE = CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE + 'transactionCode';
export const TRANSACTION_CODE_SET_VALID = CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALID + 'transactionCode';

export const setTrasactionCodeValue = value => ({
    type: TRANSACTION_CODE_SET_VALUE,
    value,
});
export const setTrasactionCodeValid = error => ({
    type: TRANSACTION_CODE_SET_VALID,
    error,
});
