import { transitionPersonalPensionPlanNextOfKinForm } from '../../../actions/personalPensionPlan/nextOfKin';
import { getNextOfKinForm } from '../../../selectors/personalPensionPlan/nextOfKin';

import { FIELD_STATUS } from '../../../reducers/status';

export const nextOfKinFormRules = (store, next, action) => {
    const result = next(action);
    const nextOfKinForm = getNextOfKinForm(store.getState());
    const nextOfKinFormValid = validateNextOfKinFormDetails(nextOfKinForm);
    const formAction = nextOfKinFormValid
        ? transitionPersonalPensionPlanNextOfKinForm.formValid()
        : transitionPersonalPensionPlanNextOfKinForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateNextOfKinFormDetails = (formFields) => {
    return (
        formFields.relationship &&
        formFields.relationship.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID
    );
};
