import { createSelector } from 'reselect';
import { getPersonalAccidentState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getSpouseDetails = createSelector(getPersonalAccidentState, (state) => state.spouseDetails);

export const getSpouseDetailsForm = createSelector(getSpouseDetails, (spouseDetails) => spouseDetails.form);

export const getSelectedForm = createSelector(getSpouseDetails, (spouseDetails) => spouseDetails.selectedForm);

export const getSpouseDetailsFormValid = createSelector(
    getSpouseDetails,
    (spouseDetails) => spouseDetails.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getSpouseDetails,
    (spouseDetails) => spouseDetails.apiCallStatus === API_CALL_STATUS.PENDING,
);

const makeGetFieldForm = (fieldId) => createSelector(getSpouseDetailsForm, (form) => form[fieldId]);

export const getSpouseFirstNameValue = makeGetFieldForm('firstName');
export const getSpouseLastNameValue = makeGetFieldForm('lastName');
export const getDOBValue = makeGetFieldForm('dob');
export const getPhoneNumberValue = makeGetFieldForm('phoneNumber');
export const getIdNumberValue = makeGetFieldForm('idNumber');
export const getTinNumberValue = makeGetFieldForm('tinNumber');
export const getStreetAddressValue = makeGetFieldForm('streetAddress');
export const getSuburbValue = makeGetFieldForm('suburb');
export const getCityValue = makeGetFieldForm('city');
export const getPostalAddressValue = makeGetFieldForm('postalAddress');
