import {OmValidationDateHelper} from "@om/validation";

export const getInitialsFromName = names => {
    const nameArray = names.split(' ');
    return nameArray.map(name => name[0]).join('').toUpperCase();
}

export const getGenderFromIdNumber = idNumber => {
    const maleRegex = new RegExp('\\d{6}[5-9]\\d{6}');
    return maleRegex.test(idNumber) ? 'Male' : 'Female';
};

export const dateOfBirthFromIdNumber = idNumber => {
    const [_, year, month, day] = idNumber.match(/(\d\d)(\d\d)(\d\d).*/);
    const hypotheticalDoB = new Date(parseInt('20' + year), month - 1, day);
    const now = new Date();
    // check if birth date in this century by comparing it to today's year
    const century = now.getFullYear() - hypotheticalDoB.getFullYear() <= 0
        ? '19'
        : '20';

    return [day, month, century + year].join('/');
};

export const getAgeFromIdNumber = idNumber => {
    const dateOfBirth = dateOfBirthFromIdNumber(idNumber);
    const dateOfBirthArray = dateOfBirth.split("/");
    const formattedDOB = new Date(Date.UTC(dateOfBirthArray[2], (dateOfBirthArray[1]- 1), dateOfBirthArray[0]))
    const currentDate = new Date();
    const diff_ms = currentDate - formattedDOB;
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const initialsFromNameList = nameList => {
    if (!nameList || !Array.isArray(nameList) || !nameList.length) {
        return null;
    }

    return nameList
        .flatMap(name => name.value.split(' '))
        .map(nameValue => nameValue.charAt(0))
        .join('');
};

export const mapGenderDataEntry = genderString => {
    return genderString === 'Male'
        ? {code: 'Male', description: 'Male', displayOrder: 1}
        : {code: 'Female', description: 'Female', displayOrder: 2};
};

export const calculateAge = dob => {
    const dobArray = OmValidationDateHelper.dateStringToNumberArray(dob);
    return Math.floor(OmValidationDateHelper.yearsDifference(OmValidationDateHelper.todayDateArray(), dobArray));
};

// COPIED FROM prerender-server/src/js/Util
export const applyThousandSeperator = (value, spaces = true) => {
    const regx = /(\d+)(\d{3})/;
    const numberString = value + '';

    // separate with spaces(default) or commas
    const thousandSep = spaces ? ' ' : ',';

    const splitNumber = numberString.split('.');
    let mainNumber = splitNumber[0];
    const remainder = splitNumber.length > 1 ? '.' + splitNumber[1] : '';

    while (regx.test(mainNumber)) {
        mainNumber = mainNumber.replace(regx, '$1' + thousandSep + '$2');
    }
    return mainNumber + remainder;
};
