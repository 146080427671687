import { BENEFICIARIES_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/beneficiaries';
import { BENEFICIARIES_SPLIT_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/beneficiariesSplit';
import { INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/investmentSetupContribution';
import { INVESTMENT_SETUP_INITIAL_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/investmentSetupInitial';
import { PAYMENT_DETAILS_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/paymentDetails';
import { PERSONAL_DETAILS_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/personalDetails';
import { AUXILIARY_DETAILS_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/auxiliaryDetails';
import { REVIEW_SET_VALUE } from '../../../actions/taxFreeSavingsAccount/review';
// TODO: Add outstanding actions

export const isTfsaAction = action => (
    action.type.startsWith(BENEFICIARIES_SET_VALUE) ||
    action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE) ||
    action.type.startsWith(INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE) ||
    action.type.startsWith(INVESTMENT_SETUP_INITIAL_SET_VALUE) ||
    action.type.startsWith(PAYMENT_DETAILS_SET_VALUE) ||
    action.type.startsWith(PERSONAL_DETAILS_SET_VALUE) ||
    action.type.startsWith(AUXILIARY_DETAILS_SET_VALUE) ||
    action.type.startsWith(REVIEW_SET_VALUE)
);
