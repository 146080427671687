export const AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL =
    'roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool/';
export const AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL + 'setValue/';
export const AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALID =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL =
    'roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool/transition/formValid';
export const FORM_INVALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL =
    'roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool/transition/formInvalid';

export const REINITIALIZE =
    'roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool/state/reinitialize';

export const WHICH_FORM_TO_DISPLAY =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const CHILDREN_SET_VALUE =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE + 'numberOfChildrenValue';
export const CHILDREN_SET_VALID =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALID + 'numberOfChildrenValue';

export const INPATIENT_COVER_LIMIT_SET_VALUE =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE +
    'inpatientCoverLimitValue';
export const INPATIENT_COVER_LIMIT_SET_VALID =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALID +
    'inpatientCoverLimitValue';

export const OUTPATIENT_COVER_LIMIT_SET_VALUE =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE +
    'outpatientCoverLimitValue';
export const OUTPATIENT_COVER_LIMIT_SET_VALID =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALID +
    'outpatientCoverLimitValue';

export const COLLECT_AFYA_IMARA_GERTRUDE_JUNIOR_DATA =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE +
    'collectAfyaImaraGertrudeJuniorData';
export const CALCULATE_AFYA_IMARA_GERTRUDE_JUNIOR_DATA =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_AFYA_IMARA_GERTRUDE_JUNIOR_DATA_FAILURE =
    AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL = `${AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL = `${AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL = `${AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;

export const apiTransitionAfyaImaraGetrudeJuniorQuoteTool = {
    pending: () => ({
        type: API_PENDING_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
    }),
};

export const transitionAfyaImaraGetrudeJuniorTool = {
    formValid: () => ({
        type: FORM_VALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_AFYA_IMARA_GERTRUDE_JUNIOR_QUOTE_TOOL,
    }),
};

export const collectAfyaImaraGertrudeJuniorData = () => ({
    type: COLLECT_AFYA_IMARA_GERTRUDE_JUNIOR_DATA,
});
export const calculateAfyaImaraGertrudeJuniorData = value => ({
    type: CALCULATE_AFYA_IMARA_GERTRUDE_JUNIOR_DATA,
    value,
});
export const collectAfyaImaraGertrudeJuniorFailure = error => ({
    type: CALCULATE_AFYA_IMARA_GERTRUDE_JUNIOR_DATA_FAILURE,
    error,
});

export const setWhichFormToDisplay = value => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});

export const setChildrenValue = value => ({ type: CHILDREN_SET_VALUE, value });
export const setChildrenValid = error => ({ type: CHILDREN_SET_VALID, error });

export const setInpatientCoverLimitValue = value => ({
    type: INPATIENT_COVER_LIMIT_SET_VALUE,
    value,
});
export const setInpatientCoverLimitValid = error => ({
    type: INPATIENT_COVER_LIMIT_SET_VALID,
    error,
});

export const setOutpatientCoverLimitValue = value => ({
    type: OUTPATIENT_COVER_LIMIT_SET_VALUE,
    value,
});
export const setOutpatientCoverLimitValid = error => ({
    type: OUTPATIENT_COVER_LIMIT_SET_VALID,
    error,
});
