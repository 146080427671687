import { validationIsEqual } from '../status';
import {
    validateEmail,
    validateName,
    validateNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateNumericString,
} from '@om/validation';

// import { getIdNumberValue } from '@om/redux/selectors/ugMotorInsurance/personalDetails';
import {
    RELATIONSHIP_SET_VALUE,
    NAMES_SET_VALUE,
    ID_PASSPORT_SET_VALUE,
    setRelationshipValid,
    setNamesValid,
    setIdPassportValid,
    OTHER_SET_VALUE,
    setOtherValid,
} from '../../../actions/healthInsuranceRW/medicalHistory';
import { validateGenericString } from '@om/validation/src/validators/string-validator';

let prevValidationAction = null;
export const medicalHistoryValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case RELATIONSHIP_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Relationship',
                    min: 2,
                    required: true,
                });
                return setRelationshipValid(error);

            case NAMES_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'Name',
                    required: true,
                });
                return setNamesValid(error);

            case ID_PASSPORT_SET_VALUE:
                error = validateNumericString(action.value, { min: 16, max: 16, required: true });

                return setIdPassportValid(error);
            case OTHER_SET_VALUE:
                error = validateGenericString(action.value, { required: true });
                // if(idNumber.value === action.value){
                //     error = 'You cannot use your own ID number'
                // }
                return setOtherValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
