import { createSelector } from 'reselect';
import { getAllsureInsurance } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { getIsInsurenceTypeSelected } from './insuranceType';
import { DOCUMENTS } from '../../actions/allsureInsurance/types';
import { getHomeDetailsList } from './homeDetails';
import { getAllMotorDetailForms, getMotorDetailsForm } from './motorDetails';
import { getHomeContentList } from './homeContents';
// import { KMF_CLAIM_PAGE_INDICES } from "../../actions/kaMetlhaFuneralClaim/types";

export const getUploadDocuments = createSelector(
    getAllsureInsurance,
    (allsureInsurance) => allsureInsurance.uploadDocuments
);

export const getUploadDocumentsForm = createSelector(getUploadDocuments, (uploadDocuments) => uploadDocuments.form);
export const getDocumentsArray = createSelector(getUploadDocumentsForm, (uploadDocuments) => {
    const {declaration,...documentFiles}=uploadDocuments
    return Object.values(documentFiles);
});
export const getIsUploadDocumentsFormValid = createSelector(
    getUploadDocuments,
    (uploadDocuments) => uploadDocuments.formStatus === FORM_STATUS.VALID
);

const inputFieldLabels = {
    copyOfCertifiedId: 'Certified copy of Omang ID/card or passport',
    proofOfResidence: 'Proof of residence',
    sourceOfFund: 'Source of funds',
    bankingDetails: 'Banking details',
    houseValuationReport: 'Home valuation report',
    vehicleRegistrationBook: 'Vehicle registration book',
    driverLicense: 'Drivers license',
    motorValuation: 'Motor valuation report',
    inventoryValuation: 'Inventory valuation report' 
};
export const getDocumentsInfoData = createSelector(
    getIsInsurenceTypeSelected('motor'),
    getIsInsurenceTypeSelected('homeOwners'),
    getIsInsurenceTypeSelected('homeContent'),
    getHomeDetailsList,
    getHomeContentList,
    getAllMotorDetailForms,
    (isMotorSelected, isHomeOwnersSelected, isHomeContentSelected, DetailList,homeContentList,motorDetailForms) => {
        const commonDocs = [
            { title: inputFieldLabels.copyOfCertifiedId, caption: 'not more than 5 years old',documentKey: 'copyOfCertifiedId'},
            { title: inputFieldLabels.proofOfResidence, caption: 'E.g. Electricity or telephone bill not older than 3 months',documentKey :'proofOfResidence'},
            { title: inputFieldLabels.sourceOfFund, caption: 'E.g. a payslip/bank statement/letter from employer not older than 3 months',documentKey:"sourceOfFund" },
            { title: inputFieldLabels.bankingDetails, caption: 'E.g. Bank statement/bank confirmation letter not older than 3 months',documentKey :"bankingDetails"},
        ];
        const homeOwnerDocs = DetailList.length
            ? [{ title:'Home valuation report(s)', caption: 'Home valuation should not be older than 5 years', documentKey: `houseValuationReport` }]
            : [];
        const homeContentDocs = homeContentList.length ? [{title:'Household Contents Inventory Form(s)', caption: 'Please download and complete the attached inventory form.', documentKey: `inventoryForm` }] : [];
       const motorDocs = motorDetailForms.length
        ? [{ title:'Motor valuation(s)', caption: 'Vehicle valuation should not be older than 1 year', documentKey: `motorValuation` },
            { title: 'Vehicle registration book(s)', caption: 'Blue book',documentKey: `vehicleRegistrationBook` },
            { title: 'Drivers license(s)', caption: 'Driver’s license should be within the valid period (not expired)',documentKey: `driverLicense` }]
        : [];
        // const motorDocsValuation = isMotorSelected ? motorDetailForms?.map(({},index)=>({ title: `${inputFieldLabels.motorValuation} ${motorDetailForms?.length>1?index+1:""} `, caption: 'Optional',documentKey: `motorValuation_${index}` })) : []
        return [...commonDocs, ...homeOwnerDocs, ...motorDocs, ...homeContentDocs];
    }
);



export const getRequiredDocuments = createSelector(
    getIsInsurenceTypeSelected('motor'),
    getIsInsurenceTypeSelected('homeOwners'),
    getIsInsurenceTypeSelected('homeContent'),
    getUploadDocumentsForm,
    getHomeDetailsList,
    getAllMotorDetailForms,
    getHomeContentList,
    (isMotorSelected, isHomeOwnersSelected,isHomeContentSelected, uploadDocumentsForm, homeDetails, motorDetailForms,homeContentsList) => {
        const motorDocs = [...(motorDetailForms.map(({},index) => DOCUMENTS.MOTOR_VALUATION + `_${index}`)) , ...(motorDetailForms.map(({},index) => DOCUMENTS.DRIVERS_LICENSE + `_${index}`)) , ...(motorDetailForms.map(({},index) => DOCUMENTS.VEHICLE_REGISTRATION_BOOK + `_${index}`)) ];
        const homeOwnerDocs = homeDetails?.map(({}, index) => DOCUMENTS.HOUSE_VALUATION_REPORT + `_${index}`);
        const homeContentDocs = homeContentsList?.map(({}, index) => DOCUMENTS.INVENTORY_VALUATION + `_${index}`);
        const allDocs = [
            DOCUMENTS.BANKING_DETAILS,
            DOCUMENTS.PROOF_OF_RESIDENCE,
            DOCUMENTS.SOURCE_OF_FUNDS,
            DOCUMENTS.CERTIFIED_COPY_OF_ID,
            ...(isMotorSelected ? motorDocs : []),
            ...(isHomeOwnersSelected ? homeOwnerDocs : []),
            ...(isHomeContentSelected ? homeContentDocs : []),
        ];
        let requiredDocs = [];
        let submittedDocs = [];
        allDocs?.map((documentKey) => {
            const key = documentKey?.split('_')[0];
            const homeKey =allDocs.filter((item)=>item?.split('_')[0]===documentKey?.split('_')[0]).length > 1  ? +documentKey?.split('_')[1] + 1 : null;
            return uploadDocumentsForm[documentKey]?.file
                ? submittedDocs.push({label:`${inputFieldLabels[key]} ${homeKey ?? ''}`,key:documentKey})
                : requiredDocs.push({label:`${inputFieldLabels[key]} ${homeKey ?? ''}`,key:documentKey})
        });
        return { requiredDocs, submittedDocs };
    }
);

export const getUploadDocumentsSummary = createSelector(getUploadDocumentsForm,getRequiredDocuments, (uploadDocumentForm,{requiredDocs,submittedDocs}) =>
    [...requiredDocs,...submittedDocs]?.map(({label,key}) => ({
            label,
            value: uploadDocumentForm[key]?.fileName ?? '-',
        }))
    );