export const BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM = 'biasharaFlexi/employeeDetailsFrom/';

export const BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM + 'setValue';
export const BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM + 'setValid';

export const FORM_VALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM = 'biasharaFlexi/employeeDetailsFrom/transition/formValid';
export const FORM_INVALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM = 'biasharaFlexi/employeeDetailsFrom/transition/formInValid';

export const REINITIALIZE_FORM = 'biasharaFlexi/employeeDetailsFrom/state/reinitialize/form';
export const REINITIALIZE_EMPLOYEES = 'biasharaFlexi/employeeDetailsFrom/state/reinitialize/employees';

export const transitionBiasharaFlexiEmployeeDetails = {
	formValid: () => ({
		type: FORM_VALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM,
	}),
};

export const FIRST_NAME_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "firstName";
export const FIRST_NAME_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "firstName";

export const SURNAME_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "surname";
export const SURNAME_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "surname";

export const DOB_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "dateOfBirth";
export const DOB_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "dateOfBirth";

export const EMPLOYMENT_DATE_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "employmentDate";
export const EMPLOYMENT_DATE_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "employmentDate";

export const MOBILE_NUMBER_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "mobileNumber";
export const MOBILE_NUMBER_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "mobileNumber";

export const ID_PASSPORT_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "idPassportNumber";
export const ID_PASSPORT_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "idPassportNumber";

export const KRA_PIN_NUMBER_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "kraPinNumber";
export const KRA_PIN_NUMBER_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "kraPinNumber";

export const EMPLOYEES_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "employees";
export const EMPLOYEES_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "employees";

export const EDIT_EMPLOYEE_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "edit";
export const EDIT_EMPLOYEE_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "edit";

export const FILE_UPLOADED_SET_VALUE = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE + "fileCsv";
export const FILE_UPLOADED_SET_VALID = BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID + "fileCsv";



export const setFirstNameValue = value => ({
    type: FIRST_NAME_SET_VALUE,
    value
})

export const setFirstNameValid = error => ({
    type: FIRST_NAME_SET_VALID,
    error
})

export const setSurnameValue = value => ({
    type: SURNAME_SET_VALUE,
    value
})

export const setSurnameValid = error => ({
    type: SURNAME_SET_VALID,
    error
})

export const setDoBValue = value => ({
    type: DOB_SET_VALUE,
    value
})

export const setDoBValid = error => ({
    type: DOB_SET_VALID,
    error
})

export const setEmployementDateValue = value => ({
    type: EMPLOYMENT_DATE_SET_VALUE,
    value
})

export const setEmployementDateValid = error => ({
    type: EMPLOYMENT_DATE_SET_VALID,
    error
})

export const setMobileNumberValue = value => ({
    type: MOBILE_NUMBER_SET_VALUE,
    value
})

export const setMobileNumberValid = error => ({
    type: MOBILE_NUMBER_SET_VALID,
    error
})


export const setIdPassortNumberValue = value => ({
    type: ID_PASSPORT_SET_VALUE,
    value
})

export const setIdPassortNumberValid = error => ({
    type: ID_PASSPORT_SET_VALID,
    error
})

export const setKraPinNumberValue = value => ({
    type: KRA_PIN_NUMBER_SET_VALUE,
    value
})

export const setKraPinNumberValid = error => ({
    type: KRA_PIN_NUMBER_SET_VALID,
    error
})

export const setEmployeesValue = value => ({
    type: EMPLOYEES_SET_VALUE,
    value
})

export const setEmployeesValid = error => ({
    type: EMPLOYEES_SET_VALID,
    error
})

export const setFormToInitial = () => ({
    type: REINITIALIZE_FORM
})

export const setEditEmployee = (value) => ({
    type: EDIT_EMPLOYEE_VALUE,
    value
})

export const setFileCsv = (value) => ({
    type: FILE_UPLOADED_SET_VALUE,
    value
})
