import { validateNumber } from '@om/validation';

import {
    EXPECTED_ANNUAL_GROWTH_SET_VALUE,
    setExpectedAnnualGrowthValid,
    GROWTH_SET_VALUE,
    setGrowthValid,
    PLANNER_SET_VALUE,
    setPlannerValid,
    ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE,
    setAnnualIncreaseInContributionValid,
    INFLATION_SET_VALUE,
    setInflationValid,
} from '../../../actions/savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const whenWillYouReachYourSavingsGoalAssumptionsModelValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case EXPECTED_ANNUAL_GROWTH_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'expected annual growth',
                        min: 10,
                        max: 30,
                        inclusive: true,
                        decimalPoints: 0,
                    });
                }
                return setExpectedAnnualGrowthValid(error);

            case ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'annual increase in contributions',
                        max: 30,
                        inclusive: true,
                        decimalPoints: 0,
                    });
                }
                return setAnnualIncreaseInContributionValid(error);

            case INFLATION_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'inflation',
                        max: 30,
                        inclusive: true,
                        decimalPoints: 0,
                    });
                }
                return setInflationValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
};
