import { createSelector } from "reselect";
import { getInvestmentCalculators } from "./index";
import {
    getSelectedDrop,
    getSelectedGoal,
    getShowBuildWealthDropField,
    getShowBuildWealthSplitForm,
} from './investmentCalculator';
import { getAmountOnceOffValue, getBuildWealthCalculatorForm, getMonthlyAmountValue } from './buildWealthCalculator';

export const getBuildWealthFundSplit = createSelector(
  getInvestmentCalculators,
  (investmentCalculators) => investmentCalculators.buildWealthFundSplit
);

export const getFundSplitForm = createSelector(
  getBuildWealthFundSplit,
  (buildWealthFundSplit) => buildWealthFundSplit.form
);

export const getIsEvenSplit = createSelector(
  getBuildWealthFundSplit,
  (fundSplit) => fundSplit.isEvenSplit
);

export const getEquityFundValue = createSelector(
  getFundSplitForm,
  (fundSplit) => fundSplit.equityFund
);

export const getBalancedFundValue = createSelector(
  getFundSplitForm,
  (fundSplit) => fundSplit.balancedFund
);

export const getSplitError = createSelector(
  getBuildWealthFundSplit,
  (fundSplit) => fundSplit.splitError
);

export const getTotalPercentage = createSelector(
  getBuildWealthFundSplit,
  (fundSplit) => fundSplit.totalPercentage
);

export const getBuildWealthFundSplitForm = createSelector(
  getBuildWealthFundSplit,
  (fundSplit) => fundSplit.form
);

export const getEquityFundSplitValue = createSelector(
  [
    getEquityFundValue,
    getBuildWealthCalculatorForm,
    getShowBuildWealthSplitForm,
  ],
  (equityFund, buildWealth, showSplitForm) => {
    if (!showSplitForm) {
      return 0;
    }
    const total =
      Number(buildWealth.amountOnceOff.value || 0) +
      Number(buildWealth.amountMonthly.value || 0);
    return total * (Number(equityFund.value || 0) / 100);
  }
);

export const getBalancedFundSplitValue = createSelector(
  [
    getBalancedFundValue,
    getBuildWealthCalculatorForm,
    getShowBuildWealthSplitForm,
  ],
  (balancedFund, buildWealth, showSplitForm) => {
    if (!showSplitForm) {
      return 0;
    }
    const total =
      Number(buildWealth.amountOnceOff.value || 0) +
      Number(buildWealth.amountMonthly.value || 0);
    return total * (Number(balancedFund.value || 0) / 100);
  }
);

// if fund split is enabled & selected drop 10%
const sendSplit = createSelector(
    [getShowBuildWealthSplitForm, getSelectedDrop],
    (showSplitForm, selectedDrop) => {
        return showSplitForm && selectedDrop === '3';
    });

export const getDataWithSplit = createSelector(
    [
        getEquityFundValue,
        getBalancedFundValue,
        getShowBuildWealthDropField,
        getSelectedDrop,
        getShowBuildWealthSplitForm,
        getAmountOnceOffValue,
        getMonthlyAmountValue,
        getSelectedGoal
    ],
    (
        equityFundValue,
        balancedFundValue,
        sendDrop,
        drop,
        showSplitForm,
        initialAmount,
        monthlyAmount,
        goal
    ) => {
        if (sendDrop && drop === '2') {
            return {
                initial_amount: initialAmount.value,
                monthly_savings: monthlyAmount.value,
                productitems: [
                    [{id: 21}]
                ],
                goal
            }
        }
        if (sendSplit && drop === '3') {
            return {
                initial_amount: initialAmount.value,
                monthly_savings: monthlyAmount.value,
                productitems: [
                    {id: 20,  percent_split:  equityFundValue.value},
                    {id: 21,  percent_split:  balancedFundValue.value}
                ],
                goal
            }
        }
        if (sendDrop && drop === '4') {
            return {
                initial_amount: initialAmount.value,
                monthly_savings: monthlyAmount.value,
                productitems: [
                    { id: 20 }
                ],
                goal
            }
        }
        else {
            return {
                productitems: [{id: 24}],
                initial_amount: initialAmount.value,
                monthly_savings:  monthlyAmount.value,
                goal,
            }
        }
})
