import { transitionPriceTable } from "../../../actions/priceTable/table";
import { FIELD_STATUS } from "../../../reducers/status";
import { getSelectedDate } from "../../../selectors/priceTable/table";

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validatePriceTable = state => {
    const dateSelected = getSelectedDate(state);

    return isValid(dateSelected);
}

export const priceTableRulesEngine = (store, next, action) => {
    const result = next(action);

    const priceTableValid = validatePriceTable(store.getState());
    store.dispatch(priceTableValid
        ? transitionPriceTable.formValid()
        : transitionPriceTable.formInvalid());

    return result;
}
