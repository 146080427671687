import {
    validateName,
    validateAlphaNumericString,
    validateCellphoneNumber,
    validateContactNumber,
    validateEmail,
} from  '@om/validation';

import {
    NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    BUSINESS_NAME_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    BUSINESS_NUMBER_SET_VALUE,
    EMAIL_ADDRESS_SET_VALUE,
    BUSINESS_REGISTERED_SET_VALUE,
    NUMBER_OF_EMPLOYEES_SET_VALUE,
    BUSINESS_DURATION_SET_VALUE,
    BUSINESS_MANAGEMENT_SET_VALUE,
    BUSINESS_REGISTERED_AD_SET_VALUE,
    HAS_EMPLOYEE_RETIREMENT_SET_VALUE,
    setNameValid,
    setSurnameValid,
    setBusinessNameValid,
    setPhoneNumberValid,
    setBusinessNumberValid,
    setEmailAddressValid,
    setBusinessRegisteredValid,
    setNumberOfEmployeesValid,
    setBusinessDurationValid,
    setBusinessManagementValid,
    setBusinessRegisteredAsValid,
    setHasEmployeeRetirementValid,
} from '../../../actions/publicWeb/save2gether';

const pleaseSelectText = 'Please choose an answer.';

const trimToNull = val => (!val || val.trim() === '') ? null : val;

const validateAnswer = (answer, validationMutation) => {
    const error = !trimToNull(answer) ? pleaseSelectText : null;
    return validationMutation(error);
}

export const save2getherValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case NAME_SET_VALUE:
                error = validateName(
                    action.value,
                    {
                        min: 2,
                        max: 48,
                        subject: 'Name',
                    },
                );
                return setNameValid(error);

            case SURNAME_SET_VALUE:
                error = validateName(
                    action.value,
                    {
                        min: 2,
                        max: 64,
                        subject: 'Surname',
                    },
                );
                return setSurnameValid(error);

            case BUSINESS_NAME_SET_VALUE:
                error = validateAlphaNumericString(
                    action.value,
                    {
                        min: 1,
                        max: 100,
                        subject: 'Business Name',
                    },
                );
                return setBusinessNameValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(action.value);
                return setPhoneNumberValid(error);

            case BUSINESS_NUMBER_SET_VALUE:
                error = validateContactNumber(
                    action.value,
                    {
                        subject: 'Business Telephone Number',
                    }
                );
                return setBusinessNumberValid(error);

            case EMAIL_ADDRESS_SET_VALUE:
                error = validateEmail(
                    action.value,
                    {
                        subject: 'Email Address',
                        min: 6,
                    },
                );
                return setEmailAddressValid(error);

            case BUSINESS_REGISTERED_SET_VALUE:
                return validateAnswer(
                    action.value,
                    setBusinessRegisteredValid,
                );

            case NUMBER_OF_EMPLOYEES_SET_VALUE:
                return validateAnswer(
                    action.value,
                    setNumberOfEmployeesValid,
                );

            case BUSINESS_DURATION_SET_VALUE:
                return validateAnswer(
                    action.value,
                    setBusinessDurationValid,
                );

            case BUSINESS_MANAGEMENT_SET_VALUE:
                return validateAnswer(
                    action.value,
                    setBusinessManagementValid,
                );

            case BUSINESS_REGISTERED_AD_SET_VALUE:
                return validateAnswer(
                    action.value,
                    setBusinessRegisteredAsValid,
                );

            case HAS_EMPLOYEE_RETIREMENT_SET_VALUE:
                return validateAnswer(
                    action.value,
                    setHasEmployeeRetirementValid,
                );

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
