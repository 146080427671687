import {
    INVESTMENT_SETUP_INITIAL_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/investmentSetupInitial';
import {
    investmentSetupInitialFieldTracking,
} from './investmentSetupInitial';
import {
    PERSONAL_DETAILS_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/personalDetails';
import {
    personalDetailsTracking,
} from './personalDetails';
import {
    AUXILIARY_DETAILS_SET_VALUE,
    SPOUSE_ID_TYPE_TOGGLE_VALUE,
    POSTAL_ADDRESS_SAME_TOGGLE_VALUE,
} from '../../../actions/taxFreeSavingsAccount/auxiliaryDetails';
import {
    auxiliaryDetailsTracking,
    auxiliaryDetailsOtherTracking,
} from './auxiliaryDetails';
import {
    BENEFICIARIES_SET_VALUE,
    EDIT_DRAWER_TOGGLE,
    EDIT_ID_SET,
    REMOVE_ID_SET,
    BENEFICIARY_DELETE,
    SIDE_DRAWER_SAVE,
} from '../../../actions/taxFreeSavingsAccount/beneficiaries';
import {
    beneficiariesTracking,
    beneficiariesOtherTracking,
} from './beneficiaries';

import {
    BENEFICIARIES_SPLIT_SET_VALUE,
    EVEN_SPLIT_TOGGLE_VALUE,
} from '../../../actions/taxFreeSavingsAccount/beneficiariesSplit';
import {
    beneficiariesSplitTracking,
    beneficiariesSplitOtherTracking
} from './beneficiariesSplit';

import {
    PAYMENT_DETAILS_SET_VALUE,
    TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
} from '../../../actions/taxFreeSavingsAccount/paymentDetails';
import {
    paymentDetailsTracking,
    paymentDetailsOtherTracking,
} from './paymentDetails';

import {
    TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW,
    TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE,
    SIDE_DRAWER_CLOSED_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/review';
import { reviewOtherTracking} from './review';
import {
    INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE,
    VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION,
} from '../../../actions/taxFreeSavingsAccount/investmentSetupContribution';
import {
    investmentSetupContributionTracking,
    investmentSetupContributionOtherTracking,
} from './investmentSetupContribution';
import {
    SELECT_A_FUND_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/selectAFund';
import { selectAFundTracking } from './selectAFund';
import {
    FILTER_OPTIONS_TOGGLE_OPTION,
    FUNDS_TOGGLE_SHOW_MORE,
    FILTER_OPTIONS_TOGGLE_DRAWER,
    SHOW_TEXT_SEARCH_BAR_TOGGLE,
    FILTER_OPTIONS_SET_FILTER_OPTIONS,
    FUND_TOGGLE_SELECTION,
} from '../../../actions/taxFreeSavingsAccount/advancedFundPicker';
import {
    advancedFundPickerOtherTracking,
} from './advancedFundPicker';
import {
    EVEN_SPLIT_DESELECT_FUND_SPLIT,
    EVEN_SPLIT_SELECT_FUND_SPLIT,
    EVEN_SPLIT_DISCARD_FUND_SPLIT,
    DELETE_FUND_FUND_SPLIT,
    SPLIT_PERCENTAGE_SET_VALUE,
} from '../../../actions/taxFreeSavingsAccount/fundSplit';
import { fundSplitOtherTracking } from './fundSplit';

import { ROUTING_TRANSITION } from '../../../actions/taxFreeSavingsAccount/routing';
import { routingOtherTracking } from './routing';


export const taxFreeSavingsAccountTracking = (store, next, action) => {
    if (action.type.startsWith(INVESTMENT_SETUP_INITIAL_SET_VALUE)) {
        return investmentSetupInitialFieldTracking(store, next, action);
    }

    if (action.type=== VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION) {
        return investmentSetupContributionOtherTracking(store, next, action);
    }

    if (action.type.startsWith(INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE)) {
        return investmentSetupContributionTracking(store, next, action);
    }

    if (action.type.startsWith(SELECT_A_FUND_SET_VALUE)) {
        return selectAFundTracking(store, next, action);
    }

    if (action.type === FUNDS_TOGGLE_SHOW_MORE ||
        action.type === FILTER_OPTIONS_TOGGLE_DRAWER ||
        action.type === FILTER_OPTIONS_TOGGLE_OPTION || 
        action.type === SHOW_TEXT_SEARCH_BAR_TOGGLE ||
        action.type === FILTER_OPTIONS_SET_FILTER_OPTIONS ||
        action.type === FUND_TOGGLE_SELECTION) {
        return advancedFundPickerOtherTracking(store, next, action);
    }

    if (action.type === EVEN_SPLIT_DESELECT_FUND_SPLIT ||
        action.type === EVEN_SPLIT_SELECT_FUND_SPLIT ||
        action.type === EVEN_SPLIT_DISCARD_FUND_SPLIT ||
        action.type === DELETE_FUND_FUND_SPLIT || 
        action.type === SPLIT_PERCENTAGE_SET_VALUE) {
        return fundSplitOtherTracking(store, next, action);
    }

    if (action.type === SPOUSE_ID_TYPE_TOGGLE_VALUE ||
        action.type === POSTAL_ADDRESS_SAME_TOGGLE_VALUE) {
        return auxiliaryDetailsOtherTracking(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE)) {
        return personalDetailsTracking(store, next, action);
    }

    if (action.type.startsWith(AUXILIARY_DETAILS_SET_VALUE)) {
        return auxiliaryDetailsTracking(store, next, action);
    }

    if (action.type === EDIT_DRAWER_TOGGLE ||
        action.type === EDIT_ID_SET ||
        action.type === REMOVE_ID_SET ||
        action.type === BENEFICIARY_DELETE ||
        action.type === SIDE_DRAWER_SAVE) {
        return beneficiariesOtherTracking(store, next, action);
    }

    if (action.type.startsWith(BENEFICIARIES_SET_VALUE)) {
        return beneficiariesTracking(store, next, action);
    }

    if (action.type === EVEN_SPLIT_TOGGLE_VALUE) {
        return beneficiariesSplitOtherTracking(store, next, action);
    }

    if (action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE)) {
        return beneficiariesSplitTracking(store, next, action);
    }

    if (action.type === TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS ||
        action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS) {
            return paymentDetailsOtherTracking(store, next, action);
        }

    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE)) {
        return paymentDetailsTracking(store, next, action);
    }

    if (action.type === TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW ||
        action.type === TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE || 
        action.type === SIDE_DRAWER_CLOSED_SET_VALUE) {
        return reviewOtherTracking(store, next, action);
    }

    if (action.type.startsWith(ROUTING_TRANSITION)) {
        return routingOtherTracking(store, next, action);
    }
    return null;
};
