import axios from 'axios';

import {
    apiTransitionMvestQuoteTool,
    calculateMvestPremiumData,
    setShowMvestQuoteToolResult,
} from '../../../actions/roaQuoteTools/mvestQuoteTool';

import {
    getIsApiPending,
    getMvestQuoteToolForm,
} from '../../../selectors/roaQuoteTools/mvestQuoteTool';

export const MvestQuoteToolService = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    (async () => {
        if (!getIsApiPending(state)) {
            store.dispatch(apiTransitionMvestQuoteTool.pending());

            try {
                const response = await calculate(state);
                const mvestData = response.data;
                store.dispatch(calculateMvestPremiumData(mvestData));
                store.dispatch(apiTransitionMvestQuoteTool.success());
                store.dispatch(setShowMvestQuoteToolResult(true));
            } catch (e) {
                store.dispatch(calculateMvestPremiumData(null));
                store.dispatch(apiTransitionMvestQuoteTool.failure());
            }
        }
    })();
    return result;
};

const getMvestPayload = formDetails => {
    return {
        mvPeriod: formDetails.paymentTerm.value || '',
        mvPercentage: '14',
        mvDuration: formDetails.paymentFrequency.value || '',
        mvAmount: formDetails.premiumAmount.value || [],
    };
};

const calculate = async state => {
    const mvestFormDetails = getMvestQuoteToolForm(state);

    try {
        const url = '/om-api/roa-quote-tools/mvest-quote';
        return await axios.post(url, getMvestPayload(mvestFormDetails));
    } catch (error) {
        return null;
    }
};
