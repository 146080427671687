import { createSelector } from 'reselect';
import { getAtRetirement } from './index';

export const getAtRetirementApplication = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.atRetirementApplication
);

export const getReferrerId = createSelector(
    getAtRetirementApplication,
    atRetirementApplication => atRetirementApplication.referrerId
);

