export const ARTICLE_CARD_FILTER_SET_CURRENT_PAGE = 'publicWeb/articleCardFilter/setValue/currentPage';
export const ARTICLE_CARD_FILTER_SET_CARDS = 'publicWeb/articleCardFilter/setValue/cards';
export const ARTICLE_CARD_FILTER_SET_CARD_FILTER = 'publicWeb/articleCardFilter/setValue/cardFilter';
export const ARTICLE_CARD_FILTER_TOGGLE_SELECTED_PRIMARY_FILTER_ITEM = 'publicWeb/articleCardFilter/toggleValue/selectedPrimaryFilterItem';
export const ARTICLE_CARD_FILTER_TOGGLE_ALL_PRIMARY_FILTER_ITEM = 'publicWeb/articleCardFilter/toggleValue/allPrimaryFilterItem';
export const ARTICLE_CARD_FILTER_TOGGLE_SELECTED_SECONDARY_FILTER_ITEM = 'publicWeb/articleCardFilter/toggleValue/selectedSecondaryFilterItem';
export const ARTICLE_CARD_FILTER_TOGGLE_ALL_SECONDARY_FILTER_ITEM = 'publicWeb/articleCardFilter/toggleValue/allSecondaryFilterItem';
export const ARTICLE_CARD_FILTER_FILTER_PRIMARY_BY_QUERY_PARAM = 'publicWeb/articleCardFilter/setValue/selectedPrimaryFilterItemByQueryParam';
export const ARTICLE_CARD_FILTER_FILTER_SECONDARY_BY_QUERY_PARAM = 'publicWeb/articleCardFilter/setValue/selectedSecondaryFilterItemByQueryParam';

export const setCurrentPage = value => ({ type: ARTICLE_CARD_FILTER_SET_CURRENT_PAGE, value });

const getFilterIdList = filterList => {
    const filterIdList = [];
    if (filterList) {
        filterList.forEach(filter => filterIdList.push(filter.uid));
    }
    return filterIdList;
};

const checkFilterRelevant = (cardFilterIds, filterIds) => {
    let relevant= false;

    if (filterIds.length === 0) {
        relevant = true;
    } else {
        filterIds.forEach(filterId => {
            if (cardFilterIds.includes(filterId)) {
                relevant = true;
            }
        });
    }

    return relevant;
};

const isCardRelevant = (card, primaryFilterIds, secondaryFilterIds) => {
    if (!card) {
        return false
    }

    if (card.card_filter_item && card.card_filter_item.length === 0) {
        return false;
    }

    const cardPrimaryFilterIds = getFilterIdList(card.card_filter_item);
    const cardSecondaryFilterIds = getFilterIdList(card.secondary_filter_items);
    const primaryRelevant = checkFilterRelevant(cardPrimaryFilterIds, primaryFilterIds);
    const seconadaryRelevant = checkFilterRelevant(cardSecondaryFilterIds, secondaryFilterIds);

    return primaryRelevant && seconadaryRelevant;
};

const removeDeplicateCards = cards => {
    if (cards && cards.length) {
        const checkedCardIds = [];
        return cards.filter(card => {
            if (checkedCardIds.includes(card.node.uid)) {
                return false;
            }
            checkedCardIds.push(card.node.uid);
            return true;
        })
    }
    return cards;
};

export const setCards = (_allCards, primaryFilters, secondaryFilters) => {
    const allCards = removeDeplicateCards(_allCards);

    const primaryFilterIds = getFilterIdList(primaryFilters);
    const secondaryFilterIds = getFilterIdList(secondaryFilters);
    const cardNodes = allCards.filter(card => isCardRelevant(card.node, primaryFilterIds, secondaryFilterIds));

    const cards = [];
    cardNodes.forEach(cardNode => cards.push(cardNode.node));

    return { type: ARTICLE_CARD_FILTER_SET_CARDS, cards };
};

const getFilters = (filterItems, secondaryFilterItems) => {
    let primaryFilters = [];
    let secondaryFilters = [];

    if (filterItems) {
        for (let filterItemKey in filterItems) {
            let filterItem = filterItems[filterItemKey];
            primaryFilters.push({
                uid: filterItem.uid,
                check_box_text: filterItem.check_box_text,
                query_key: filterItem.query_key,
            });
        }
    }

    if (secondaryFilterItems) {
        for (let secondaryFilterItemKey in secondaryFilterItems) {
            let filterItem = secondaryFilterItems[secondaryFilterItemKey];
            secondaryFilters.push({
                uid: filterItem.uid,
                check_box_text: filterItem.check_box_text,
                query_key: filterItem.query_key,
            });
        }
    }

    return { primaryFilters, secondaryFilters };
};

export const setCardFilter = articleCardFilter => {
    let secondaryFilters = [];
    let primaryFilters = [];
    let cardsPerPage = 9;

    if(articleCardFilter.number_of_rows != null) {
        cardsPerPage = articleCardFilter.number_of_rows * 3;
    }

    if (articleCardFilter) {
        const filters = getFilters(articleCardFilter.card_filter_items, articleCardFilter.secondary_filter_items);
        primaryFilters = filters.primaryFilters;
        secondaryFilters = filters.secondaryFilters;
    }

    return {
        type: ARTICLE_CARD_FILTER_SET_CARD_FILTER,
        primaryFilters,
        secondaryFilters,
        cardsPerPage,
    };
};

export const toggleSelectedPrimaryFilterItem = (selectedPrimaryFilterItem, isChecked) => {
    return {
        type: ARTICLE_CARD_FILTER_TOGGLE_SELECTED_PRIMARY_FILTER_ITEM,
        selectedPrimaryFilterItem,
        isChecked,
    }
};

export const toggleAllPrimaryFilters = isChecked => ({ type: ARTICLE_CARD_FILTER_TOGGLE_ALL_PRIMARY_FILTER_ITEM, isChecked });

export const filterByPrimaryQueryParam = primaryQueryParam => ({ type: ARTICLE_CARD_FILTER_FILTER_PRIMARY_BY_QUERY_PARAM, primaryQueryParam });

export const toggleSelectedSecondaryFilterItem = (selectedSecondaryFilterItem, isChecked) => {
    return {
        type: ARTICLE_CARD_FILTER_TOGGLE_SELECTED_SECONDARY_FILTER_ITEM,
        selectedSecondaryFilterItem,
        isChecked,
    }
};

export const toggleAllSecondaryFilters = isChecked => ({ type: ARTICLE_CARD_FILTER_TOGGLE_ALL_SECONDARY_FILTER_ITEM, isChecked });

export const filterBySecondaryQueryParam = secondaryQueryParam => ({ type: ARTICLE_CARD_FILTER_FILTER_SECONDARY_BY_QUERY_PARAM, secondaryQueryParam });
