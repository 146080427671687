import axios from 'axios';
import {
    apiTransitionPersonalDetailsForm,
    calculatePersonalDetailsAmlWhitelistSuccess,
} from '../../../actions/ugMotorInsurance/personalDetails';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import { getQuoteForm } from '../../../selectors/ugMotorInsurance/quote';

export const amlWhitelistCheckService = (store, next, action) => {
    (async () => {
        store.dispatch(apiTransitionPersonalDetailsForm.pending());
        try {
            const resp = await calculate(store.getState());

            store.dispatch(calculatePersonalDetailsAmlWhitelistSuccess(resp.data.blacklisted));
            store.dispatch(apiTransitionPersonalDetailsForm.success());
        } catch (e) {
            console.error(e);
            store.dispatch(apiTransitionPersonalDetailsForm.failure());
        }
    })();
    return next(action);
};

const amlWhitelistCheckServicePayload = (state) => {
    const quotation = getQuoteForm(state);

    const amlWhitelistCalculationPayload = {
        full_name: quotation.fullName.value || '',
        pin: quotation.idNumber.value,
        email: quotation.email.value,
        phone_number: quotation.phoneNumber.value,
    };

    return {
        ...amlWhitelistCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ug-motor-insurance/aml-whitelist';

        return await axios.post(url, amlWhitelistCheckServicePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
