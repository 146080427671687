import { transitionAgentModalForm } from '../../../actions/roaCarInsurance/agentReferral';
import { getCarAgentForm } from '../../../selectors/roaCarInsurance/agentReferral';

import { FIELD_STATUS } from '../../../reducers/status';

export const agentModalFormRules = (store, next, action) => {
	const result = next(action);
	const travelAgentModalForm = getCarAgentForm(store.getState());
	const agentModalForm = validateAgentModalForm(travelAgentModalForm);
	const formAction = agentModalForm
		? transitionAgentModalForm.formValid()
		: transitionAgentModalForm.formInvalid();
	store.dispatch(formAction);
	return result;
};

const validateAgentModalForm = formFields => {
	return (
		formFields.name &&
		formFields.name.status === FIELD_STATUS.VALID &&
		formFields.agentPhoneNumber &&
		formFields.agentPhoneNumber.status === FIELD_STATUS.VALID
	);
};
