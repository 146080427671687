import { validateNumber } from '@om/validation';
import {
    PREMIUM_AMOUNT_SET_VALUE,
    PAYMENT_TERM_SET_VALUE,
    PAYMENT_FREQUENCY_SET_VALUE,
    setPremiumAmountValid,
    setPaymentTermValid,
    setPaymentFrequencyValid,
} from '../../../actions/roaQuoteTools/mvestQuoteTool';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const mvestToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;

        switch (action.type) {
            case PREMIUM_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 0,
                    max: 1000000000000,
                    pronoun: 'Premium Amount Value',
                });
                return setPremiumAmountValid(error);

            case PAYMENT_TERM_SET_VALUE:
                error = validateNumber(action.value, {
                    min: 1,
                    max: 10,
                    pronoun: 'Payment term Value',
                });
                return setPaymentTermValid(error);

            case PAYMENT_FREQUENCY_SET_VALUE:
                let isValid =
                    action.value === 'Monthly' || action.value === 'Annual';
                if (isValid) return setPaymentFrequencyValid(null);
                else
                    return setPaymentFrequencyValid(
                        'Please select from the provided values',
                    );

            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
