import {
    CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER,
    CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER,
    STEP_INDEX_SET_VALUE,
} from "../../actions/covidSelfAssessment/wizardFooter";

const initWizardFooter = {
    stepIndex: 0,
    continueButtonDisabled: true,
    continueButtonVisible: true,
};

export default (state = initWizardFooter, action) => {
    switch (action.type) {
        case CONTINUE_BUTTON_DISABLED_TRANSITION_WIZARD_FOOTER:
            return { ...state, continueButtonDisabled: action.value };
        case CONTINUE_BUTTON_VISIBLE_TRANSITION_WIZARD_FOOTER:
            return { ...state, continueButtonVisible: action.value };
        case STEP_INDEX_SET_VALUE:
            return { ...state, stepIndex: action.value };
        default:
            return state;
    }
};
