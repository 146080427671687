import axios from 'axios';
import {
    getPaymentsForm,
    getIsManualPaymentsApiPending,
    getAccountNumberDetails,
} from '../../../selectors/roaTravelInsurance/payments';
import {
    submitTravelInsuranceManualPaymentsFailure,
    submitTravelInsuranceManualPaymentsSuccess,
} from '../../../actions/roaTravelInsurance/manualMpesaPayments';

import { apiTransitionManualPaymentsForm } from '../../../actions/roaTravelInsurance/manualMpesaPayments';

export const travelInsuranceManualMpesaPaymentService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    (async () => {
        if (!getIsManualPaymentsApiPending(state)) {
            store.dispatch(apiTransitionManualPaymentsForm.pending());
            try {
                const resp = await mpesaTransactionCodeCheck(state);
                if(resp.data.error){
                    throw {message: 'Something went wrong. Try again.'};
                }
                if(resp.data.status !== 0){
                    throw {message: `Transaction with the specified ID could not be found.`}
                }
                if(resp.data.status === 0){
                    const successMessage = {message: 'Congratulations your payments have been recieved.'}
                    store.dispatch(submitTravelInsuranceManualPaymentsSuccess(successMessage));
                    store.dispatch(apiTransitionManualPaymentsForm.success());
                }

            } catch (e) {
                store.dispatch(submitTravelInsuranceManualPaymentsFailure(e));
                store.dispatch(apiTransitionManualPaymentsForm.failure());
            }
        }
    })();
    return result;
};

const mpesaTransactionCodeCheck = async (state) => {
    const form = getPaymentsForm(state);
    const accountNumber = getAccountNumberDetails(state);
    const payload = {
        transactionId: form.transactionCode.value,
        MSIDN: form.paymentsPhone.value,
        session_id: accountNumber?.value?.account_no,
    };
    try {
        const url = '/om-api/roa-travel-insurance/mpesa-transaction-status';
        return await axios.post(url, payload);
    } catch (e) {
        throw e;
    }
};
