import {
    COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA,
    COLLECT_KE_THIRD_PARTY_DATA,
    TRIGGER_CALCULATE_MOTORCYCLE_PREMIUM,
    TRIGGER_GET_VALUERS,
} from '../../../actions/ugMotorInsurance/quote';

import { COLLECT_CAR_DETAILS_FORM_DATA } from '../../../actions/ugMotorInsurance/carDetails';
import { TRIGGER_GET_NATIONALITIES } from '../../../actions/ugMotorInsurance/personalDetails';

import {
    TRIGGER_KE_PAYMENTS_STK_PUSH,
    CHECK_PAYMENTS_STATUS,
    TRIGGER_CONFIRM_ORDER,
} from '../../../actions/ugMotorInsurance/payments';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { orderConfirmationService } from './orderConfirmation';

import { premiumCalculationService } from './premiumCalculation';
import { thirdPartyPremiumCalculationService } from './thirdPartyPremiumCalculation';
import { checkDoubleInsuranceService } from './doubleInsuranceCheck';
import { motorcyclePremiumCalculationService } from './motorCyclePremium';

import { TRIGGER_FETCH_AGENTS } from '../../../actions/ugMotorInsurance/motorAgentModal';
import { getAgentsService } from './agents';
import { getValuersService } from './valuers';
import { getNationalityService } from './nationalities';
import { COLLECT_PAYLOAD_DATA } from '../../../actions/ugMotorInsurance/payloadCollection';
import { payloadSubmitService } from './payloadCollection';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const keMotorInsuranceCover = (store, next, action) => {
    if (action.type.startsWith(TRIGGER_KE_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }
    if (action.type.startsWith(CHECK_PAYMENTS_STATUS) && gatsbyStack === 'ke') {
        return paymentsStkStatusService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_KE_THIRD_PARTY_DATA)) {
        return thirdPartyPremiumCalculationService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_CAR_DETAILS_FORM_DATA) && gatsbyStack === 'ke') {
        return checkDoubleInsuranceService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)) {
        return orderConfirmationService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_PAYLOAD_DATA) && gatsbyStack === 'ke') {
        return payloadSubmitService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_CALCULATE_MOTORCYCLE_PREMIUM)) {
        return motorcyclePremiumCalculationService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_FETCH_AGENTS)) {
        return getAgentsService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_VALUERS)) {
        return getValuersService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_NATIONALITIES)) {
        return getNationalityService(store, next, action);
    }

    return null;
};
