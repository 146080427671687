import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { CALL_ME_BACK_SEND_ACTION, transitionCallMeBack } from '../../../actions/publicWeb/callMeBack';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import { getAccessToken as getPersonalAccidentAccessToken } from '../../../selectors/personalAccident/payments';
import { getAccessToken as getTravelTokens } from '../../../selectors/travel/payments';
import { getAccessToken as getDoctorsPiAccessToken } from '../../../selectors/doctorsPi/payments';
import { getAccessToken as getPersonalPensionPlanAccessToken } from '../../../selectors/personalPensionPlan/payments';
import { getAccessToken as getDigitalSavingsAccessToken } from '../../../selectors/digitalSavings/payment';

import {
    getAdditionalNumber,
    getLocationComments,
    getAdviserData,
    getEmail,
    getEmailCampaignCode,
    getFirstName,
    getFormSubmitting,
    getPhoneNumber,
    getPostalCode,
    getProductName,
    getSurname,
    getToEmail,
    getLeadSystem,
    getCity,
    getEnvironmentName,
    getLocation,
} from '../../../selectors/publicWeb/callMeBack';
import { getLoginDetails } from '../../../selectors/biasharaFlexi/payment';

const header = {
    'Content-Type': 'application/json',
};

const eaCountryMap = {
    ug: 'Uganda',
    rw: 'Rwanda',
    ke: 'Kenya',
    ss: 'South Sudan',
    tz: 'Tanzania',
};

function formatUrlSuffix(url) {
    const path = new URL(url).pathname;
    const parts = path.split('/');
    const lastPart = parts.filter((part) => part.trim() !== '').pop();
    const formattedSuffix = lastPart
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return formattedSuffix;
}
export const callMeBackService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (action.type === CALL_ME_BACK_SEND_ACTION) {
            await sendCallMeBack(store, state, action.gtmContext);
        }
    })();

    return result;
};

async function sendCallMeBack(store, state, gtmContext) {
    if (getFormSubmitting(state)) {
        return;
    }

    const productName = getProductName(state);
    const toEmail = getToEmail(state);
    const adviserData = getAdviserData(state);
    const leadSystem = getLeadSystem(state);

    store.dispatch(transitionCallMeBack.submitting());

    let advisorArray = ['Find An Adviser_DFA only - omcoza', 'Find An Adviser - om.co.za', 'VIRTUAL ADVISOR FAA LEADS'];
    const eaProductName = 'East Africa Call Me Back';
    try {
        if (productName.value === eaProductName) {
            await sendToLeadsEa(state, gtmContext);
        }
        if (productName && !advisorArray.includes(productName.value)) {
            if (toEmail && toEmail.value) {
                await sendToEmail(state, gtmContext);
            } else {
                if (
                    (leadSystem === 'za' || !leadSystem || leadSystem.trim() === '') &&
                    productName.value !== eaProductName
                ) {
                    await sendToLeads(state, gtmContext);
                }

                if (leadSystem === 'ke') {
                    await sendToLeadsKe(state, gtmContext);
                }

                if (leadSystem === 'ng') {
                    await sendToLeads(state, gtmContext);
                }
                if (leadSystem === 'ug') {
                    await sendToLeadsUg(state, gtmContext);
                }

                if (leadSystem === 'ke-biashara') {
                    await sendToLeadsKeBiashara(state, gtmContext);
                }

                if (leadSystem === 'kePersonalAccident') {
                    await sendToLeadsKePersonalAccident(state, gtmContext);
                }

                if (leadSystem === 'ke-doctorpi') {
                    await sendToLeadsKeDoctorsPi(state, gtmContext);
                }

                if (leadSystem === 'bw') {
                    await sendToEmail(state, gtmContext);
                }
                if (leadSystem === 'rw') {
                    await sendToEmail(state, gtmContext);
                }

            }
        } else {
            // assumes FAA
            await Promise.all([
                adviserData.adviserEmail ? sendToEmailAdviser(state, window.location.href) : null,
                adviserData.managerEmail ? sendToEmailManager(state, window.location.href) : null,
                sendToEmailClient(state, gtmContext),
                sendToAdviserLeads(state, gtmContext),
            ]);
        }

        store.dispatch(transitionCallMeBack.success());
    } catch (e) {
        store.dispatch(transitionCallMeBack.error());
    }
}

function sendToEmail(state, gtmContext) {
    const productName = getProductName(state);
    const toEmail = getToEmail(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const postalCode = getPostalCode(state);
    const additionalNumber = getAdditionalNumber(state);
    const emailCampaignCode = getEmailCampaignCode(state);
    const location = getLocation(state);
    const locationComments = getLocationComments(state);

    const bodyParams = {
        sourceEmailAddress: 'noreply@oldmutual.com',
        toEmailAdrress: toEmail.value ? toEmail.value : undefined,
        emailCampaignCode: emailCampaignCode,
        subject: 'Call me Back Details',
        pageURL: encodeURIComponent(gtmContext.url),
        type: 'CMB',
        source: 'MyOldMutualPublicWebsite',
        name: firstName.value,
        firstName: firstName.value,
        surname: surname.value ? surname.value : 'none',
        phoneNumber: phoneNumber.value,
        productName: productName.value ? productName.value : undefined,
        postalCode: postalCode.value ? postalCode.value : undefined,
        additionalNumber: additionalNumber.value ? additionalNumber.value : undefined,
        emailAddress: email.value ? email.value : undefined,
        location: location.value ? location.value : undefined,
        comment: locationComments.value ? locationComments.value : undefined,
    };

    return axios.post('/om-api/call-me-back/call-me-back-mail', bodyParams, { headers: header });
}

function sendToLeads(state, gtmContext) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const postalCode = getPostalCode(state);
    const locationComments = getLocationComments(state);

    const bodyParams = {
        pageURL: encodeURIComponent(gtmContext.url),
        source: 'MyOldMutualPublicWebsite',
        googleAnalyticsId: gtmContext.gaId,
        formData: {
            name: firstName.value,
            firstName: firstName.value,
            surname: surname.value ? surname.value : 'none',
            phoneNumber: phoneNumber.value,
            postalCode: postalCode.value ? postalCode.value : undefined,
            // 'additionalNumber': additionalNumber.value ? additionalNumber.value : undefined,
            comment: locationComments.value ? locationComments.value : undefined,
            email: email.value ? email.value : undefined,
            sourceProduct: productName.value,
        },
    };

    return axios.post('/om-api/call-me-back/leads', bodyParams, { headers: header });
}

async function sendToLeadsKe(state) {
    const environmentName = getEnvironmentName(state);
    const productName = getProductName(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const city = getCity(state);
    const countryCode = process.env.GATSBY_COUNTRY_SELECTOR;
    const bodyParams = {
        env: environmentName ? environmentName : 'dev',
        SOURCE_SYSTEM_ID: uuid(),
        SOURCE_SYSTEM_NAME: '176830001',
        FIRST_NAME: firstName.value ? firstName.value : undefined,
        SECOND_NAME: surname.value ? surname.value : 'none',
        PHONE_NUMBER: phoneNumber.value ? phoneNumber.value : undefined,
        EMAIL_ADDRESS: email.value ? email.value : undefined,
        PHYSICAL_LOCATION: city.value ? city.value : undefined,
        POLICY_TYPE: productName.value ? productName.value : undefined,
        COUNTRY: countryCode ? countryCode : undefined,
    };

    return await axios.post('/om-api/call-me-back/leads/ke', bodyParams, { headers: header });
}

async function sendToLeadsEa(state, gtmContext) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const bodyParams = {
        leadsourcename: 'Direct Website',
        firstname: firstName.value,
        secondname: surname.value ? surname.value : 'none',
        mobilephone: phoneNumber.value,
        product: formatUrlSuffix(gtmContext.url) || 'EA Call Me back products',
        emailaddress1: email.value ? email.value : 'N/A',
        country: eaCountryMap[process.env.GATSBY_COUNTRY_SELECTOR],
    };

    try {
        // call endpoint to get token
        const authResponse = await axios.post('/om-api/call-me-back/auth/leads/ea');
        const authToken = authResponse.data[0].access_token;
        const leadGenerationResponse = await axios.post('/om-api/call-me-back/leads/ea', bodyParams, {
            headers: { 'Content-Type': 'application/json', accessToken: authToken },
        });
        return leadGenerationResponse;
    } catch (error) {
        console.log(error, 'Ea call me error');
    }
}

function sendToLeadsKePersonalAccident(state) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const countryCode = process.env.GATSBY_COUNTRY_SELECTOR;
    const bodyParams = {
        fname: firstName.value ? firstName.value : undefined,
        sname: surname.value ? surname.value : 'none',
        mobileno: phoneNumber.value ? phoneNumber.value : undefined,
        email: email.value ? email.value : undefined,
        products: productName.value ? productName.value : undefined,
        country: countryCode.toUpperCase(),
        idNumber: '',
    };
    const loginData = getPersonalAccidentAccessToken(state);

    return axios.post('/om-api/call-me-back/leads/ug', bodyParams, {
        headers: {
            'Content-Type': 'application/json',
            accessToken: loginData.accessToken,
        },
    });
}

function sendToLeadsUg(state) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const countryCode = process.env.GATSBY_COUNTRY_SELECTOR;
    const bodyParams = {
        fname: firstName.value ? firstName.value : undefined,
        sname: surname.value ? surname.value : 'none',
        mobileno: phoneNumber.value ? phoneNumber.value : undefined,
        email: email.value ? email.value : undefined,
        products: productName.value ? productName.value : undefined,
        country: countryCode.toUpperCase(),
        idNumber: '',
    };
    let loginData = getAccessToken(state);
    if (productName.value === 'Travel Insurance') {
        loginData = getTravelTokens(state);
    }

    if (productName.value === 'Personal Accident Kenya') {
        loginData = getPersonalAccidentAccessToken(state);
    }

    if (productName.value === 'Personal Pension Plan Kenya') {
        loginData = getPersonalPensionPlanAccessToken(state);
    }
    if (productName.value === 'Digital Savings Kenya') {
        loginData = getDigitalSavingsAccessToken(state);
    }

    return axios.post('/om-api/call-me-back/leads/ug', bodyParams, {
        headers: {
            'Content-Type': 'application/json',
            accessToken: loginData.accessToken,
        },
    });
}

function sendToLeadsKeBiashara(state) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const countryCode = process.env.GATSBY_COUNTRY_SELECTOR;
    const bodyParams = {
        fname: firstName.value ? firstName.value : undefined,
        sname: surname.value ? surname.value : 'none',
        mobileno: phoneNumber.value ? phoneNumber.value : undefined,
        email: email.value ? email.value : undefined,
        products: productName.value ? productName.value : undefined,
        country: countryCode.toUpperCase(),
        idNumber: '',
    };
    const loginData = getLoginDetails(state);
    return axios.post('/om-api/call-me-back/leads/ug', bodyParams, {
        headers: {
            'Content-Type': 'application/json',
            accessToken: loginData.loginData.accessToken,
        },
    });
}

function sendToLeadsKeDoctorsPi(state) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const countryCode = process.env.GATSBY_COUNTRY_SELECTOR;
    const bodyParams = {
        fname: firstName.value ? firstName.value : undefined,
        sname: surname.value ? surname.value : 'none',
        mobileno: phoneNumber.value ? phoneNumber.value : undefined,
        email: email.value ? email.value : undefined,
        products: productName.value ? productName.value : undefined,
        country: countryCode.toUpperCase(),
        idNumber: '',
    };
    const loginData = getDoctorsPiAccessToken(state);

    return axios.post('/om-api/call-me-back/leads/ug', bodyParams, {
        headers: {
            'Content-Type': 'application/json',
            accessToken: loginData.accessToken,
        },
    });
}

function sendToAdviserLeads(state, gtmContext) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const adviserData = getAdviserData(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const postalCode = getPostalCode(state);
    const locationComments = getLocationComments(state);

    const bodyParams = {
        pageURL: encodeURIComponent(gtmContext.url),
        source: 'MyOldMutualPublicWebsite',
        googleAnalyticsId: gtmContext.gaId,
        adviserId: adviserData.adviserId,
        adviserName: adviserData.adviserName,
        adviserEmail: adviserData.adviserEmail,
        managerEmail: adviserData.managerEmail,
        discussOptions: adviserData.discussOptions,
        isTelephonic: adviserData.isTelephonic,
        name: firstName.value,
        firstName: firstName.value,
        surname: surname.value,
        phoneNumber: phoneNumber.value,
        postalCode: postalCode.value ? postalCode.value : undefined,
        locationComments: locationComments.value ? locationComments.value : undefined,
        // additionalNumber: additionalNumber.value ? additionalNumber.value : undefined,
        emailAddress: email.value ? email.value : undefined,
        productName: productName.value,
    };

    return axios.post('/om-api/call-me-back/adviser/leads', bodyParams, { headers: header });
}

function sendToEmailClient(state, gtmContext) {
    const productName = getProductName(state);
    const toEmail = getToEmail(state);
    const email = getEmail(state);
    const adviserData = getAdviserData(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const locationComments = getLocationComments(state);

    const bodyParams = {
        sourceEmailAddress: 'noreply@oldmutual.com',
        toEmailAdrress: toEmail.value ? toEmail.value : undefined,
        subject: 'Find an Adviser - Call me Back Details',
        pageURL: encodeURIComponent(gtmContext.url),
        type: 'FAACMB-Client',
        source: 'MyOldMutualPublicWebsite',

        adviserName: adviserData.adviserName,
        adviserEmail: adviserData.adviserEmail,
        adviserId: adviserData.adviserId,
        discussOptions: adviserData.discussOptions,

        name: firstName.value,
        firstName: firstName.value,
        surname: surname.value,
        phoneNumber: phoneNumber.value,
        emailAddress: email.value ? email.value : undefined,
        comment: locationComments.value ? locationComments.value : undefined,
        productName: productName.value,
    };

    return axios.post('/om-api/call-me-back/call-me-back-mail', bodyParams, { headers: header });
}

function sendToEmailAdviser(state, gtmContext) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const adviserData = getAdviserData(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const postalCode = getPostalCode(state);
    const additionalNumber = getAdditionalNumber(state);
    const locationComments = getLocationComments(state);

    const bodyParams = {
        sourceEmailAddress: 'noreply@oldmutual.com',
        subject: 'FAA Call me Back Details',
        pageURL: encodeURIComponent(gtmContext.url),
        type: 'FAACMB-Adviser',
        source: 'MyOldMutualPublicWebsite',
        toEmailAdrress: adviserData.adviserEmail,

        adviserName: adviserData.adviserName,
        discussOptions: adviserData.discussOptions,
        adviserEmail: adviserData.adviserEmail,

        preferredContactMethod: adviserData.assistanceType,

        name: firstName.value,
        firstName: firstName.value,
        surname: surname.value,
        phoneNumber: phoneNumber.value,
        emailAddress: email.value ? email.value : undefined,
        postalCode: postalCode.value ? postalCode.value : undefined,
        additionalNumber: additionalNumber.value ? additionalNumber.value : undefined,
        comment: locationComments.value ? locationComments.value : undefined,
        productName: productName.value,
    };

    return axios.post('/om-api/call-me-back/call-me-back-mail', bodyParams, { headers: header });
}

function sendToEmailManager(state, gtmContext) {
    const productName = getProductName(state);
    const email = getEmail(state);
    const adviserData = getAdviserData(state);
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const phoneNumber = getPhoneNumber(state);
    const postalCode = getPostalCode(state);
    const additionalNumber = getAdditionalNumber(state);
    const locationComments = getLocationComments(state);

    const bodyParams = {
        sourceEmailAddress: 'noreply@oldmutual.com',
        subject: 'FAA Call me Back Details',
        pageURL: encodeURIComponent(gtmContext.url),
        type: 'FAACMB-Manager',
        source: 'MyOldMutualPublicWebsite',

        managerEmail: adviserData.managerEmail,
        adviserName: adviserData.adviserName,
        adviserEmail: adviserData.adviserEmail,
        adviserId: adviserData.adviserId,
        discussOptions: adviserData.discussOptions,

        name: firstName.value,
        firstName: firstName.value,
        surname: surname.value,
        phoneNumber: phoneNumber.value,
        emailAddress: email.value ? email.value : undefined,
        postalCode: postalCode.value ? postalCode.value : undefined,
        additionalNumber: additionalNumber.value ? additionalNumber.value : undefined,
        comment: locationComments.value ? locationComments.value : undefined,
        productName: productName.value,
    };

    return axios.post('/om-api/call-me-back/call-me-back-mail', bodyParams, { headers: header });
}
