import { COLLECT_ELIMIKA_PREMIUM_DATA } from '../../../actions/roaQuoteTools/elimikaQuoteTool';
import { elimikaQuoteToolService } from './elimikaQuoteTool';
import { COLLECT_PREMIER_SAVINGS_DATA } from '../../../actions/roaQuoteTools/premierSavingsQuoteTool';
import { premierSavingsQuoteToolService } from './premierSavingsQuoteTool';
import { COLLECT_AFYA_IMARA_SENIOR_DATA } from '../../../actions/roaQuoteTools/afyaImaraSeniorQuoteTool';
import { afyaImaraSeniorQuoteToolService } from './afyaImaraSeniorQuoteTool';
import { COLLECT_AFYA_IMARA_JUNIOR_DATA } from '../../../actions/roaQuoteTools/afyaImaraJuniorQuoteTool';
import { afyaImaraJuniorQuoteToolService } from './afyaImaraJuniorQuoteTool';
import { COLLECT_AFYA_IMARA_GERTRUDE_JUNIOR_DATA } from '../../../actions/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool';
import { afyaImaraGertrudeJuniorQuoteToolService } from './afyaImaraGertrudeQuoteTool';
import { COLLECT_ACCIDENTAL_DEATH_COVER_PREMIUM_DATA } from "../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool";
import { accidentalDeathCoverQuoteToolService } from "./lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool";
import { COLLECT_WHOLE_LIFE_COVER_DATA } from '../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';
import { wholeLifeCoverQuoteToolService } from './lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';
import { COLLECT_AFYAIMARA_PREMIUM_DATA } from "../../../actions/roaQuoteTools/afyaImaraQuoteTool";
import { AfyaImaraQuoteToolService } from "./afyaImaraQuoteTool";
import { deathTermCoverQuoteToolService } from "./lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool";
import { COLLECT_DEATH_TERM_COVER_PREMIUM_DATA } from "../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool";

import { COLLECT_ACCIDENTAL_DISABILITY_PREMIUM_DATA } from "../../../actions/roaQuoteTools/accidentalDisabilityQuoteTool";
import { accidentalDisabilityQuoteToolService } from "./accidentalDisabilityQuoteTool";
import { COLLECT_PHYSICAL_IMPAIRMENT_COVER_PREMIUM_DATA } from '../../../actions/roaQuoteTools/physicalImpairmentCoverQuoteTool';
import { physicalImpairmentCoverQuoteToolService } from './physicalImpairmentCoverQuoteTool';
import { COLLECT_CRITICAL_ILLNESS_PREMIUM_DATA } from '../../../actions/roaQuoteTools/criticalIllnessQuoteTool';
import { CriticalIllnessQuoteToolService } from './criticalIllnessQuoteTool';
import { COLLECT_COUNTY_PREMIUM_DATA } from "../../../actions/roaQuoteTools/afyaImaraCountyQuoteTool";
import { CountyQuoteToolService } from "./afyaImaraCountyQuoteTool";
import { COLLECT_MVEST_PREMIUM_DATA } from "../../../actions/roaQuoteTools/mvestQuoteTool";
import { MvestQuoteToolService } from "./mvestQuoteTool";
import {COLLECT_GROUPLIFE_DATA} from "../../../actions/roaQuoteTools/groupLifeQuoteTool";
import {groupLifeQuoteToolService} from "./groupLifeQuoteTool";
import { COLLECT_DIGILIFE_PREMIUM_DATA } from "../../../actions/roaQuoteTools/digiLifeQuoteTool";
import { digiLifeQuoteToolService } from './digiLifeQuoteTool';

export const roaQuoteToolService = (store, next, action) => {
    if (action.type.startsWith(COLLECT_ELIMIKA_PREMIUM_DATA)) {
        return elimikaQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_PREMIER_SAVINGS_DATA)) {
        return premierSavingsQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_ACCIDENTAL_DISABILITY_PREMIUM_DATA)) {
        return accidentalDisabilityQuoteToolService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_AFYA_IMARA_SENIOR_DATA)) {
        return afyaImaraSeniorQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_AFYA_IMARA_JUNIOR_DATA)) {
        return afyaImaraJuniorQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_ACCIDENTAL_DEATH_COVER_PREMIUM_DATA)) {
        return accidentalDeathCoverQuoteToolService(store, next, action)
    }
    if (action.type.startsWith(COLLECT_AFYA_IMARA_GERTRUDE_JUNIOR_DATA)) {
        return afyaImaraGertrudeJuniorQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_WHOLE_LIFE_COVER_DATA)) {
        return wholeLifeCoverQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_AFYAIMARA_PREMIUM_DATA)) {
        return AfyaImaraQuoteToolService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_DEATH_TERM_COVER_PREMIUM_DATA)) {
        return deathTermCoverQuoteToolService(store, next, action);
    }

    if (
        action.type.startsWith(COLLECT_PHYSICAL_IMPAIRMENT_COVER_PREMIUM_DATA)
    ) {
        return physicalImpairmentCoverQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_CRITICAL_ILLNESS_PREMIUM_DATA)) {
        return CriticalIllnessQuoteToolService(store, next, action);
    }
    if(action.type.startsWith(COLLECT_COUNTY_PREMIUM_DATA)){
        return CountyQuoteToolService(store, next, action);
    }
    if(action.type.startsWith(COLLECT_MVEST_PREMIUM_DATA)){
        return MvestQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_GROUPLIFE_DATA)) {
        return groupLifeQuoteToolService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_DIGILIFE_PREMIUM_DATA)) {
        return digiLifeQuoteToolService(store, next, action);
    }
    return null;
};
