import { validationIsEqual } from '../status';
import {
    validateDate,
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateAlphaNumericString,
    validateNumericString,
    validateAlphaString,
    validateAlphaNumericStringAllowingOnlyNumbers,
} from '@om/validation';
import {
    EMPLOYMENT_STATUS_SET_VALUE,
    EMPLOYER_NAME_SET_VALUE,
    OCCUPATION_SET_VALUE,
    INDUSTRY_OR_SECTOR_SET_VALUE,
    CONTRACT_END_DATE_SET_VALUE,
    EMPLOYER_POSTAL_ADDRESS_SET_VALUE,
    EMPLOYER_TOWN_OR_CITY_SET_VALUE,
    EMPLOYER_PHONE_NUMBER_SET_VALUE,
    PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE,
    CHILD_DATA_PROCESSING_CONSENT_SET_VALUE,
    MARKETING_USE_CONSENT_SET_VALUE,
    setEmploymentStatusValid,
    setEmployerNameValid,
    setOccupationValid,
    setIndustryOrSectorValid,
    setContractEndDateValid,
    setEmployerPostalAddressValid,
    setEmployerTownOrCityValid,
    setEmployerPhoneNumberValid,
    setPersonalDataProcessingConsentValid,
    setChildDataProcessingConsentValid,
    setMarketingUseConsentValid,
} from '../../../actions/ugMotorInsurance/employmentDetails';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

let prevValidationAction = null;

export const employmentDetailsValidation = (store, next, action) => {
    /*
  function validateUgandanPhoneNumber(phoneNumber) {
      const regex = /^(07|03|04)\d{8}$/;

      const isValid = regex.test(phoneNumber);

      return isValid;
  }
  */

    const validationAction = (() => {
        let error;

        const validateString = (value, min, subject) => {
            const validators = [validateName, validateAlphaNumericString, validateNumericString];

            let customError = null;

            for (const validate of validators) {
                customError = validate(value, { min, subject });
                if (!customError) break;
            }

            return customError;
        };

        switch (action.type) {
            case EMPLOYMENT_STATUS_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Employment Status',
                });
                return setEmploymentStatusValid(error);

            case EMPLOYER_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'Employer Name',
                });
                return setEmployerNameValid(error);

            case OCCUPATION_SET_VALUE:
                error = validateAlphaString(action.value, {
                    min: 4,
                    max: 48,
                    subject: 'Occupation',
                });
                return setOccupationValid(error);

            case INDUSTRY_OR_SECTOR_SET_VALUE:
                error = validateAlphaString(action.value, {
                    min: 3,
                    max: 48,
                    subject: 'Industry/Sector',
                });
                return setIndustryOrSectorValid(error);
            case CONTRACT_END_DATE_SET_VALUE:
                error = validateDate(action.value, { subject: 'Contract End Date' });
                return setContractEndDateValid(error);

            case EMPLOYER_POSTAL_ADDRESS_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'Employer Postal Address',
                });
                return setEmployerPostalAddressValid(error);
            case EMPLOYER_TOWN_OR_CITY_SET_VALUE:
                error = validateString(action.value, {
                    subject: 'Town Or City',
                    min: 3,
                });
                return setEmployerTownOrCityValid(error);
            case EMPLOYER_PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Employer Phone Number',
                    countryCode: gatsbyStack,
                });
                return setEmployerPhoneNumberValid(error);

            case PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE:
                error = validateSingleSelection(action.value, 'Personal Data Processing Consent');
                return setPersonalDataProcessingConsentValid(error);

            case CHILD_DATA_PROCESSING_CONSENT_SET_VALUE:
                error = validateSingleSelection(action.value, 'Child Data Processing Consent');
                return setChildDataProcessingConsentValid(error);

            case MARKETING_USE_CONSENT_SET_VALUE:
                error = validateSingleSelection(action.value, 'Marketing Use Consent');
                return setMarketingUseConsentValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
