import axios from 'axios';
import {
    apiTransitionFundDeclarations,
    apiTransitionFundNames,
    FETCH_FUND_DECLARATION_FUNDS,
    FETCH_FUND_DECLARATION_NAMES,
    setFundNames,
    setFundsValue,
} from '../../../actions/fundDeclarations/fundDeclarations';
import { getIsApiPending, getIsNameApiPending } from '../../../selectors/fundDeclarations/fundDeclarations';

export const getFundNames = async (store) => {
    const state = store.getState();

    if (!getIsNameApiPending(state)) {
        store.dispatch(apiTransitionFundNames.pending());

        try {
            const result = await axios.get('/om-api/fund-declaration/get-fund-names');
            store.dispatch(setFundNames(result.data));
            store.dispatch(apiTransitionFundNames.success());
        } catch (error) {
            store.dispatch(apiTransitionFundNames.failure());
        }
    }
};

export const getFundData = async (store) => {
    const state = store.getState();

    if (!getIsApiPending(state)) {
        store.dispatch(apiTransitionFundDeclarations.pending());

        try {
            const result = await axios.get('/om-api/fund-declaration/get-fund-declaration');
            store.dispatch(setFundsValue(result.data));
            store.dispatch(apiTransitionFundDeclarations.success());
        } catch (error) {
            store.dispatch(apiTransitionFundDeclarations.failure());
        }
    }
};

export const fundDataService = (store, next, action) => {
    (async () => {
        const result = next(action);
        if(action.type === FETCH_FUND_DECLARATION_FUNDS){
            getFundData(store);
        }
        if(action.type === FETCH_FUND_DECLARATION_NAMES){
            getFundNames(store);
        }
        return result;
    })();
};
