import axios from 'axios';

import {
    getIsApiPending,
    getGroupLifeQuoteToolForm,
} from '../../../selectors/roaQuoteTools/groupLifeQuoteTool';
import {
    calculateGroupLifeData,
    apiTransitionGroupLifeQuoteTool,
} from '../../../actions/roaQuoteTools/groupLifeQuoteTool';

export const groupLifeQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (!getIsApiPending(state)) {
            store.dispatch(apiTransitionGroupLifeQuoteTool.pending());

            try {
                const response = await calculate(state);
                const quotationSummary = response.data;
                store.dispatch(calculateGroupLifeData(quotationSummary));
                store.dispatch(apiTransitionGroupLifeQuoteTool.success());
            } catch (e) {
                store.dispatch(calculateGroupLifeData(null));
                store.dispatch(apiTransitionGroupLifeQuoteTool.failure());
            }
        }
    })();
    return result;
};

const makeInt = number => {
    const parsedInt = parseInt(number);

    if (isNaN(parsedInt)) {
        return '';
    }

    return parsedInt;
};

const coverRangesToInt = coverTypes => {
    if (!coverTypes || !coverTypes.length) {
        return [];
    }

    return coverTypes.map(range => ({
        code: range.code,
        coverMultiple: parseFloat(range.coverMultiple),
    }));
};

const getGroupLifePayload = formDetails => {
    return {
        numberOfEmployees: makeInt(formDetails.numberOfEmployees.value),
        averageAge: makeInt(formDetails.averageAgeOfEmployees.value),
        totalSalaryBill: makeInt(formDetails.totalSumAssured.value),
        coverTypes: coverRangesToInt(formDetails.coverRanges),
    };
};

const calculate = async state => {
    const groupLifeFormDetails = getGroupLifeQuoteToolForm(state);
    try {
        const url = '/grouplife-calculator/calculate';
        return axios.post(url, getGroupLifePayload(groupLifeFormDetails));
    } catch (error) {
        return null;
    }
};
