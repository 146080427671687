import { transitionDomesticInsuranceBuildingDetailsForm } from '../../../actions/domesticInsurance/buildingDetails';
import { getBuildingDetailsForm } from '../../../selectors/domesticInsurance/buildingDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const buildingDetailsFormRules = (store, next, action) => {
	const result = next(action);
	const buildingDetailsForm = getBuildingDetailsForm(store.getState());
	const buildingDetailsFormValid = validateBuildingDetailsFormDetails(buildingDetailsForm); // true false 
	const formAction = buildingDetailsFormValid
        ? transitionDomesticInsuranceBuildingDetailsForm.formValid()
        : transitionDomesticInsuranceBuildingDetailsForm.formInvalid();
	store.dispatch(formAction);
	return result;
};

const validateBuildingDetailsFormDetails = (formFields) => {
	return (
        formFields.hasFinacialInterest &&
        formFields.hasFinacialInterest.status === FIELD_STATUS.VALID &&
        formFields.buildingType &&
        formFields.buildingType.status === FIELD_STATUS.VALID &&
        formFields.useOfProperty &&
        formFields.useOfProperty.status === FIELD_STATUS.VALID &&
        formFields.roofNature &&
        formFields.roofNature.status === FIELD_STATUS.VALID &&
        formFields.wallNature &&
        formFields.wallNature.status === FIELD_STATUS.VALID &&
        formFields.location &&
        formFields.location.status === FIELD_STATUS.VALID &&
        formFields.noOfFloors &&
        formFields.noOfFloors.status === FIELD_STATUS.VALID
    );
};
