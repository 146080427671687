
// Transitions
export const FORM_VALID_TRANSITION_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/formValid';
export const FORM_INVALID_TRANSITION_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/formInvalid';
export const PAYMENT_DETAILS_FORM_VALID_TRANSITION_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/paymentDetailsFormValid';
export const PAYMENT_DETAILS_FORM_INVALID_TRANSITION_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/paymentDetailsFormInvalid';
export const PAYMENT_DETAILS_FORM_SET_VALUE = 'raOptimal/paymentDetails/transition/formValue';

export const SOURCE_OF_INCOME_OTHER_REQUIRED_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/sourceOfIncomeOtherRequired';
export const SOURCE_OF_INCOME_OTHER_NOT_REQUIRED_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/sourceOfIncomeOtherNotRequired';
export const EMPLOYMENT_INDUSTRY_REQUIRED_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/employmentIndustryRequired';
export const EMPLOYMENT_INDUSTRY_NOT_REQUIRED_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/employmentIndustryNotRequired';
export const EMPLOYMENT_INDUSTRY_OTHER_REQUIRED_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/employmentIndustryOtherRequired';
export const EMPLOYMENT_INDUSTRY_OTHER_NOT_REQUIRED_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/employmentIndustryOtherNotRequired';

export const TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/togglePaymentAuthorization';

export const TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/toggleIncludeAvs';
export const toggleIncludeAvs = () => ({ type: TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS });

export const TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/toggleIncludeCdv';
export const toggleIncludeCdv = () => ({ type: TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS });

export const TRIGGER_ACCOUNT_VALIDATION_FROM_CHECKBOX_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/triggerAccountValidationFromCheckbox';
export const triggerAccountValidation = () => ({ type: TRIGGER_ACCOUNT_VALIDATION_FROM_CHECKBOX_PAYMENT_DETAILS });

export const togglePaymentAuthorization = () => ({ type: TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS });
export const setPaymentAuthorizationInvalid = value => ({ type: TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS, value });
export const setPaymentDetailsFormValues = () => ({ type: PAYMENT_DETAILS_FORM_SET_VALUE });

export const transitionPaymentDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_PAYMENT_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_PAYMENT_DETAILS }),
    paymentDetailsFormValid: () => ({ type: PAYMENT_DETAILS_FORM_VALID_TRANSITION_PAYMENT_DETAILS }),
    paymentDetailsFormInvalid: () => ({ type: PAYMENT_DETAILS_FORM_INVALID_TRANSITION_PAYMENT_DETAILS }),

    sourceOfIncomeOtherRequired: () => ({ type: SOURCE_OF_INCOME_OTHER_REQUIRED_PAYMENT_DETAILS }),
    sourceOfIncomeOtherNotRequired: () => ({ type: SOURCE_OF_INCOME_OTHER_NOT_REQUIRED_PAYMENT_DETAILS }),
    employmentIndustryOtherRequired: () => ({ type: EMPLOYMENT_INDUSTRY_OTHER_REQUIRED_PAYMENT_DETAILS }),
    employmentIndustryOtherNotRequired: () => ({ type: EMPLOYMENT_INDUSTRY_OTHER_NOT_REQUIRED_PAYMENT_DETAILS }),
    employmentIndustryRequired: () => ({ type: EMPLOYMENT_INDUSTRY_REQUIRED_PAYMENT_DETAILS }),
    employmentIndustryNotRequired: () => ({ type: EMPLOYMENT_INDUSTRY_NOT_REQUIRED_PAYMENT_DETAILS }),
};

// Tracking actions
export const INVALID_CONTINUE_TRANSITION_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/invalidContinue';
export const CONTINUE_TRANSITION_PAYMENT_DETAILS = 'raOptimal/paymentDetails/transition/validContinue';
export const BACK_TRANSITION_PAYMENT_DETAIL = 'raOptimal/paymentDetails/transition/back';

export const invalidContinuePaymentDetails = () => ({ type: INVALID_CONTINUE_TRANSITION_PAYMENT_DETAILS });
export const continuePaymentDetails = () => ({ type: CONTINUE_TRANSITION_PAYMENT_DETAILS });
export const backPaymentDetails = () => ({ type: BACK_TRANSITION_PAYMENT_DETAIL });

// Events
export const PAYMENT_DETAILS_SET_CLEAR = 'raOptimal/paymentDetails/setClear/';
export const PAYMENT_DETAILS_SET_VALUE = 'raOptimal/paymentDetails/setValue/';
export const PAYMENT_DETAILS_SET_VALID = 'raOptimal/paymentDetails/setValid/';

// Account validation state management
export const ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS = PAYMENT_DETAILS_SET_VALUE + 'accountValidation/status';
export const setAccountValidationStatus = value => ({ type: ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS, value });

export const ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS = PAYMENT_DETAILS_SET_VALUE + 'accountValidationReset';
export const setAccountValidationReset = () => ({ type: ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS });

export const ACCOUNT_VALIDATION_COMPLETE_PAYMENT_DETAILS = PAYMENT_DETAILS_SET_VALUE + 'accountValidationComplete';
export const setAccountValidationComplete = value => ({ type: ACCOUNT_VALIDATION_COMPLETE_PAYMENT_DETAILS, value });

// Payment details
export const BANK_NAME_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'bankName';
export const BANK_NAME_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'bankName';
export const ACCOUNT_HOLDER_NAME_PRESET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'presetAccountHolderName';
export const ACCOUNT_HOLDER_NAME_PRESET_VALID = PAYMENT_DETAILS_SET_VALID + 'presetAccountHolderName';
export const ACCOUNT_HOLDER_NAME_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountHolderName';
export const ACCOUNT_HOLDER_NAME_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'accountHolderName';
export const ACCOUNT_HOLDER_NAME_EDITED_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountHolderNameEdited'
export const ACCOUNT_NUMBER_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountNumber';
export const ACCOUNT_NUMBER_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'accountNumber';
export const ACCOUNT_TYPE_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'accountType';
export const ACCOUNT_TYPE_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'accountType';
export const DEBIT_ORDER_DAY_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'debitOrderDay';
export const DEBIT_ORDER_DAY_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'debitOrderDay';
export const START_MONTH_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'startMonth';
export const START_MONTH_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'startMonth';
export const START_MONTH_SET_CLEAR = PAYMENT_DETAILS_SET_CLEAR + 'startMonth';

// Legal details
export const SOURCE_OF_INCOME_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'sourceOfIncome';
export const SOURCE_OF_INCOME_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'sourceOfIncome';
export const SOURCE_OF_INCOME_OTHER_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'sourceOfIncomeOther';
export const SOURCE_OF_INCOME_OTHER_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'sourceOfIncomeOther';
export const SOURCE_OF_INCOME_OTHER_SET_CLEAR = PAYMENT_DETAILS_SET_CLEAR + 'sourceOfIncomeOther';
export const SOURCE_OF_FUNDS_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'sourceOFFunds';
export const SOURCE_OF_FUNDS_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'sourceOFFunds';
export const EMPLOYMENT_POSITION_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'employmentPosition';
export const EMPLOYMENT_POSITION_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'employmentPosition';
export const EMPLOYMENT_INDUSTRY_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'employmentIndustry';
export const EMPLOYMENT_INDUSTRY_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'employmentIndustry';
export const EMPLOYMENT_INDUSTRY_SET_CLEAR = PAYMENT_DETAILS_SET_CLEAR + 'employmentIndustry';
export const EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE = PAYMENT_DETAILS_SET_VALUE + 'employmentIndustryOther';
export const EMPLOYMENT_INDUSTRY_OTHER_SET_VALID = PAYMENT_DETAILS_SET_VALID + 'employmentIndustryOther';
export const EMPLOYMENT_INDUSTRY_OTHER_SET_CLEAR = PAYMENT_DETAILS_SET_CLEAR + 'employmentIndustryOther';

// Payment details
export const setBankNameValue = value => ({ type: BANK_NAME_SET_VALUE, value });
export const setBankNameValid = error => ({ type: BANK_NAME_SET_VALID, error });
export const presetAccountHolderNameValue = value => ({ type: ACCOUNT_HOLDER_NAME_PRESET_VALUE, value });
export const presetAccountHolderNameValid = error => ({ type: ACCOUNT_HOLDER_NAME_PRESET_VALID, error });
export const setAccountHolderNameValue = value => ({ type: ACCOUNT_HOLDER_NAME_SET_VALUE, value });
export const setAccountHolderNameValid = error => ({ type: ACCOUNT_HOLDER_NAME_SET_VALID, error });
export const setAccountHolderNameEdited = () => ({ type: ACCOUNT_HOLDER_NAME_EDITED_SET_VALUE })
export const setAccountNumberValue = value => ({ type: ACCOUNT_NUMBER_SET_VALUE, value });
export const setAccountNumberValid = error => ({ type: ACCOUNT_NUMBER_SET_VALID, error });
export const setAccountTypeValue = value => ({ type: ACCOUNT_TYPE_SET_VALUE, value });
export const setAccountTypeValid = error => ({ type: ACCOUNT_TYPE_SET_VALID, error });
export const setDebitOrderDayValue = value => ({ type: DEBIT_ORDER_DAY_SET_VALUE, value });
export const setDebitOrderDayValid = error => ({ type: DEBIT_ORDER_DAY_SET_VALID, error });
export const setStartMonthValue = value => ({ type: START_MONTH_SET_VALUE, value });
export const setStartMonthValid = error => ({ type: START_MONTH_SET_VALID, error });
export const clearStartMonth = () => ({ type: START_MONTH_SET_CLEAR });

// Legal details
export const setSourceOfIncomeValue = value => ({ type: SOURCE_OF_INCOME_SET_VALUE, value });
export const setSourceOfIncomeValid = error => ({ type: SOURCE_OF_INCOME_SET_VALID, error });
export const setSourceOfIncomeOtherValue = value => ({ type: SOURCE_OF_INCOME_OTHER_SET_VALUE, value });
export const setSourceOfIncomeOtherValid = error => ({ type: SOURCE_OF_INCOME_OTHER_SET_VALID, error });
export const clearSourceOfIncomeOther = () => ({ type: SOURCE_OF_INCOME_OTHER_SET_CLEAR });
export const setSourceOfFundsValue = value => ({ type: SOURCE_OF_FUNDS_SET_VALUE, value });
export const setSourceOfFundsValid = error => ({ type: SOURCE_OF_FUNDS_SET_VALID, error });
export const setEmploymentPositionValue = value => ({ type: EMPLOYMENT_POSITION_SET_VALUE, value });
export const setEmploymentPositionValid = error => ({ type: EMPLOYMENT_POSITION_SET_VALID, error });
export const setEmploymentIndustryValue = value => ({ type: EMPLOYMENT_INDUSTRY_SET_VALUE, value });
export const setEmploymentIndustryValid = error => ({ type: EMPLOYMENT_INDUSTRY_SET_VALID, error });
export const clearEmploymentIndustry = () => ({ type: EMPLOYMENT_INDUSTRY_SET_CLEAR });
export const setEmploymentIndustryOtherValue = value => ({ type: EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE, value });
export const setEmploymentIndustryOtherValid = error => ({ type: EMPLOYMENT_INDUSTRY_OTHER_SET_VALID, error });
export const clearEmploymentIndustryOther = () => ({ type: EMPLOYMENT_INDUSTRY_OTHER_SET_CLEAR });

export const RESET_PAYMENT_DETAILS = PAYMENT_DETAILS_SET_VALUE + 'resetState';
export const resetPaymentDetails = () => ({type: RESET_PAYMENT_DETAILS});