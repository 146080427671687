import {
  DLP_APPLICATION_REFERENCE_DATA_SET_VALUE,
  DLP_BANK_REFERENCE_DATA_SET_VALUE,
  DLP_BRANCH_CODES_REFERENCE_DATA_API_STATUS,
  DLP_BRANCH_CODES_REFERENCE_DATA_SET_VALUE,
  RESET_REFERENCE_DATA,
} from "../../actions/digiLifeLoanProtection/referenceData";
import { API_CALL_STATUS } from "../status";

export const initReferenceData = {
  apiStatus:null,
  applicationData: {
    bankData: [],
    branchCodesData: [],
  },
};

export default (state = initReferenceData, action) => {
  switch (action.type) {
    case DLP_BRANCH_CODES_REFERENCE_DATA_API_STATUS:
      return {...state, apiStatus: action.value };

    case DLP_APPLICATION_REFERENCE_DATA_SET_VALUE:
      return {...state, applicationData: { ...state.applicationData,  ...action.value } };
    
      case DLP_BANK_REFERENCE_DATA_SET_VALUE:
      return {...state,
        applicationData: { ...state.applicationData, bankData: action.value },
      };
   
      case DLP_BRANCH_CODES_REFERENCE_DATA_SET_VALUE:
      return {
        ...state,
        applicationData: {
          ...state.applicationData,
          branchCodesData: action.value,
        },
      };
    case RESET_REFERENCE_DATA:
      return { ...initReferenceData };
    default:
      return state;
  }
};
