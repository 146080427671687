import {validationIsEqual} from "../status";
import {
    validateSingleSelection,
} from "@om/validation";
import {
    FUND_TOGGLE_SELECTION,
    selectedFundsetValid,
} from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";

let prevValidationAction = null;
export const advancedFundPickerValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FUND_TOGGLE_SELECTION:
                error = validateSingleSelection(action.id, 'fund');
                return selectedFundsetValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
