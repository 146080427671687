
export const na_to_em = (nipa) => nipa/1200;

export const an_pmt_pv = (i, n, pv) => pv/((1-1/(1+i)**n)/i);

export const an_nper_pv = (i, pmt, pv) => -Math.log(1-pv*i/pmt)/Math.log(1+i);

export const an_fv = (i, n, pmt) => pmt*(((1+i)**n-1)/i);

export const ea_to_freq = (ea, freq) => (1+ea)**(1/freq)-1;

const rg_cmb = (r, g) => (1+r)/(1+g)-1;

export const ga_freq_pmt = (r, freq, g, n, pv) => pv/(((1-1/(1+r))/(ea_to_freq(r, freq)/(1+ea_to_freq(r, freq))))*((1-1/(1+rg_cmb(r, g))**n)/(rg_cmb(r, g)/(1+rg_cmb(r, g)))));

export const ga_freq_pmt_ratio = (r, freq, g, n) => (((1-1/(1+r))/(ea_to_freq(r, freq)/(1+ea_to_freq(r, freq))))*((1-1/(1+rg_cmb(r, g))**n)/(rg_cmb(r, g)/(1+rg_cmb(r, g)))));

export const ga_freq_fv = (r, freq, g, n, pmt) => pmt*(((1-(1/(1+r)))/(ea_to_freq(r, freq)/(1+ea_to_freq(r, freq))))*(1+g)**n)*(((1+rg_cmb(r, g))**n-1)/(rg_cmb(r, g)/(1+rg_cmb(r, g))));

export const ga_fv_pmt = (r, g, n, pmt) => pmt*(((1+r)**n-(1+g)**n)/(r-g));

export const ga_pv_pmt = (r, g, n, pmt) => pmt/(r-g)*(1-((1+g)/(1+r))**n);

export const ga_pmt_pv = (r, g, n, pv) => pv*(r-g)/(1-((1+g)/(1+r))**n);

export const ga_pmt_fv = (r, g, n, fv) => fv*(r-g)/((1+r)**n-(1+g)**n);

export const ls_fv = (i, n, pv) => pv*(1+i)**n;

export const ls_pv = (i, n, fv) => fv/(1+i)**n;

export const round = (num, dec) => Math.round((num + Number.EPSILON) * 10**dec) / 10**dec;
