import {
    setTaxResult,
    transitionBasicCalculator,
    RESULTS_VIEW_VALUE,
    REINITIALIZE_STATE_BASIC_CALCULATOR,
    PRODUCT_RECOMMENDATION_SET_VALUE
} from "../../../actions/incomeTaxCalculator/basicCalculator";
import {
    getBasicCalculatorForm,
} from "../../../selectors/incomeTaxCalculator/basicCalculator";
import {transitionScrolling} from "../../../actions/incomeTaxCalculator/scrolling";
import {FIELD_STATUS} from "../../../reducers/status";

export const basicCalculatorFormRules = (store, next, action) => {
    const result = next(action);

    const basicCalculatorForm = getBasicCalculatorForm(store.getState());
    const basicCalculatorValid = validateBasicCalculatorDetails(basicCalculatorForm);
    const formAction = basicCalculatorValid
        ? transitionBasicCalculator.formValid()
        : transitionBasicCalculator.formInvalid()

    store.dispatch(formAction);

    if (action.type === RESULTS_VIEW_VALUE) {
        store.dispatch(setTaxResult(action.value));
        store.dispatch(transitionScrolling.scrollToClass('basic-result-card-mobile'));
    }
    if (action.type === REINITIALIZE_STATE_BASIC_CALCULATOR) {
        store.dispatch(transitionScrolling.scrollToClass('age-section'));
    }
    if (action.type === PRODUCT_RECOMMENDATION_SET_VALUE) {
        store.dispatch(transitionScrolling.scrollToClass('component-table-mobile-basic'));
    }

    return result;
};

const validateBasicCalculatorDetails = formFields => (
    formFields.age && formFields.age.status === FIELD_STATUS.VALID &&
    formFields.incomeFrequency && formFields.incomeFrequency.status === FIELD_STATUS.VALID &&
    formFields.incomeValue && formFields.incomeValue.status === FIELD_STATUS.VALID
);
