import { validateName, validateSurname, validateCellphoneNumber } from  "@om/validation";
import {
    setNameValid,
    setSurnameValid,
    setContactNumberValid,
    NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    CONTACT_NUMBER_SET_VALUE
} from "../../../actions/easiplusFuneralPlan/callMeBack";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const callMeBackValidation = (store, next, action) => {
    const validationAction = (() => {
        const removeWhiteSpacesFromValue = (value) => value ? value.replace(/ /g, '') : value;

        let error;
        switch (action.type) {
            case NAME_SET_VALUE:
                // error = validateName(action.value, {min: 2, max: 48, subject: 'Name'});
                error = validateName(action.value);
                return setNameValid(error);
            case SURNAME_SET_VALUE:
                // error = validateName(action.value, {min: 2, max: 64, subject: 'Surname'});
                error = validateSurname(action.value);
                return setSurnameValid(error);
            case CONTACT_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(removeWhiteSpacesFromValue(action.value), 'mobile number');
                return setContactNumberValid(error);
            // TODO Add validation
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
