import {
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
    navigateToMotorQuote,
    navigateToPersonalDetails,
    navigateToDocuments,
    navigateToPayment
} from '../../../actions/doctorsPi/routing';
import { getActivePageIndex } from '../../../selectors/doctorsPi/routing';
import { PAGE_INDICES } from '../../../actions/doctorsPi/types';

export const routingRules = (store, next, action) => {
    if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS)
        return next(action);
    const result = next(action);
    const state = store.getState();
    const activePageIndex = getActivePageIndex(state);
    if (action.type === NAVIGATE_NEXT) {
        onNavigateNext(store, state, activePageIndex);
        return result;
    }
    onNavigatePrevious(store, state, activePageIndex);
    return result;
};

const onNavigateNext = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.QOUTE:
            store.dispatch(navigateToPersonalDetails());
            break;
        case PAGE_INDICES.PERSONAL_DETAILS:
            store.dispatch(navigateToDocuments());
            break;
        case PAGE_INDICES.UPLOAD_DOCUMENTS:
            store.dispatch(navigateToPayment());
            break;
        default:
            store.dispatch(navigateToMotorQuote());
            break;
    }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.PERSONAL_DETAILS:
            store.dispatch(navigateToMotorQuote());
            break;

        case PAGE_INDICES.UPLOAD_DOCUMENTS:
            store.dispatch(navigateToPersonalDetails());
            break;
        case PAGE_INDICES.PAYMENT:
            store.dispatch(navigateToDocuments());
            break;
        default:
            store.dispatch(navigateToMotorQuote());
            break;
    }
};
