import { createSelector, createStructuredSelector } from 'reselect';
import { getAllsureInsurance } from './index';
import { getInsuranceTypeForm, getIsInsuranceTypeFormValid, getIsInsurenceTypeSelected } from './insuranceType';
import {
    PAGE_INDICES,
    PROGRESS_BAR_STEPS,
    PURCHASE_JOURNEY_PROGRESS_BAR_STEPS,
} from '../../actions/allsureInsurance/types';
import { getIsMotorDetailsFormValid, getMotorDetails, getMotorFieldsToBeValidated } from './motorDetails';
import { getIsPersonalDetailsFormValid, getPersonalDetails, getPersonalDetailsForm } from './personalDetails';
import { getGlobalPaymentFrequency, getHomeDetails, getHomeDetailsForm} from './homeDetails';
import { INITIAL_EDIT_KEY } from '../../reducers/allsureInsurance/homeDetails';
import { FIELD_STATUS } from '../../reducers/status';
import { getPurchasePersonalDetailsForm } from './purchasePersonalDetails';
import { getUploadDocumentsForm } from './uploadDocuments';
import { getAdditionalInfoFormDetails } from './purchaseAdditionalHomeMotorDetails';
import { getBankingDetailsForm } from './bankingDetails';
import { getRecaptchaValue, getTermsAndConditions } from './referenceData';
import { getHomeContentForm, getHomeContentsDetails } from './homeContents';
// import { KMF_CLAIM_PAGE_INDICES } from "../../actions/kaMetlhaFuneralClaim/types";

export const getRouting = createSelector(getAllsureInsurance, (allsureInsurance) => allsureInsurance.routing);
export const getActivePageIndex = createSelector(getRouting, (routing) => routing.activePageIndex);
export const getisEditDetailsKey = createSelector(
    getHomeDetails,
    getMotorDetails,
    getHomeContentsDetails,
    ({ editHomeDetailKey }, { editMotorDetailsKey }, { editHomeContentKey }) => {
        return { editHomeDetailKey, editMotorDetailsKey, editHomeContentKey };
    }
);
export const getDoNavigation = createSelector(getRouting, (routing) => routing.doNavigation);
export const getDoExitNavigation = createSelector(getRouting, (routing) => routing.doExitNavigation);
export const isFooter = createSelector(getActivePageIndex, (activePageIndex) => ![5, 6, 13].includes(activePageIndex));
// export const makeGetIsPage = (pageIndex) =>
//   createSelector(
//     getRouting,
//     (routing) => routing.activePageIndex === pageIndex
//   );
// export const getIsDropoffPage = createSelector(
//   getRouting,
//   (routing) => routing.activePageIndex === 1
// );

// export const getIsClaimentPage = makeGetIsPage(
//   KMF_CLAIM_PAGE_INDICES.CLAIMENT_DETAILS
// );
// export const getIsDeceasedPage = makeGetIsPage(
//   KMF_CLAIM_PAGE_INDICES.DECEASED_DETAILS
// );
// export const getIsUplaoadDocumentsPage = makeGetIsPage(
//   KMF_CLAIM_PAGE_INDICES.UPLOAD_DOCUMENTS
// );

export const getProgressBarSteps = createSelector(
    getActivePageIndex,
    getIsInsurenceTypeSelected('motor'),
    getIsInsurenceTypeSelected('homeContent'),
    getIsInsurenceTypeSelected('homeOwners'),
    (activePageIndex, isMotorInsuranceSelected, isHomeContentSelected, isHomeOwnersSelected) => {
        const barSteps = (activePageIndex > 6 ? PURCHASE_JOURNEY_PROGRESS_BAR_STEPS : PROGRESS_BAR_STEPS).map(
            (step) => ({
                ...step,
                isActive: activePageIndex === PAGE_INDICES[step.key],
                isCompleted: activePageIndex + 1 > PAGE_INDICES[step.key],
            })
        );
        if (!isMotorInsuranceSelected && activePageIndex < 6) {
            barSteps.splice(2, 1);
        }
        if (!isHomeContentSelected && activePageIndex < 6) {
            barSteps.splice(1, 1);
        }
        if (!isHomeOwnersSelected && activePageIndex < 6) {
            barSteps.splice(0, 1);
        }
        return barSteps;
    }
);
export const getCanNavigateNext = createSelector(
    getActivePageIndex,
    getIsInsuranceTypeFormValid,
    getIsMotorDetailsFormValid,
    getIsPersonalDetailsFormValid,
    (activePageIndex, isInsuranceTypeFormValid, isMotorDetailsFormValid, isPersonalDetailsValid) =>
        ({ ...[isInsuranceTypeFormValid, isMotorDetailsFormValid, isPersonalDetailsValid] }[activePageIndex])
);
export const getIsProgressBar = createSelector(
    getActivePageIndex,
    getPersonalDetails,
    getisEditDetailsKey,
    (activePageIndex, personalDetails, { editHomeDetailKey, editMotorDetailsKey, editHomeContentKey }) => {
        const progressBarByPages = {
            ...[
                false,
                editHomeDetailKey.startsWith(INITIAL_EDIT_KEY),
                editHomeContentKey.startsWith(INITIAL_EDIT_KEY),
                editMotorDetailsKey.startsWith(INITIAL_EDIT_KEY),
                !personalDetails.isPersonalDetailsEdit,
                true,
                false,
                //Purchase Journey Pages
                false,
                true,
                true,
                true,
                true,
                true,
                false,
            ],
        };

        return progressBarByPages[activePageIndex];
    }
);
const isValid = (field) => {
    return field && field.status === FIELD_STATUS.VALID;
};
export const getIsActivePageFormValid = createSelector(
    getActivePageIndex,
    getInsuranceTypeForm,
    getHomeDetailsForm,
    getHomeContentForm,
    getMotorFieldsToBeValidated,
    getPersonalDetailsForm,
    getIsInsurenceTypeSelected('homeOwners'),
    getIsInsurenceTypeSelected('homeContent'),
    getisEditDetailsKey,
    getGlobalPaymentFrequency,
    // Purchase Jorney
    getPurchasePersonalDetailsForm,
    getAdditionalInfoFormDetails,
    getBankingDetailsForm,
    getUploadDocumentsForm,
    getIsInsurenceTypeSelected('motor'),
    getTermsAndConditions,
    getRecaptchaValue,
    (
        activePageIndex,
        insuranceTypeForm,
        homeDetailsForm,
        homeContentForm,
        motorDetailsForm,
        personaleDetailsForm,
        isHomeOwnerSelected,
        isHomeContentSelected,
        { editHomeDetailKey, editMotorDetailsKey },
        paymentFrequency,
        // Purchase Journey
        purchasePersonalDetailsForm,
        additionalInfoForm,
        bankingDetailsForm,
        uploadDocumentsForm,
        isMotorInsuranceSelected,
        termsAndConditions,
        recaptchaValue
        
    ) => {
        const { privacyNotice, motor } = insuranceTypeForm;
        const insuranceTypeFeilds = [privacyNotice, motor];

        const defaultFormFields = [{ status: FIELD_STATUS.VALID }];
        const defaultInvalidFormFields = [{ status: FIELD_STATUS.UNTOUCHED }];
        
const getFormFieldstoValidate=(details)=>{
    const {
        otherAddress,
        accidentalDamageAmount,
        powerSurgeCover,
        claimedCount,
        claimAmount,
        domesticEmployees={},
        policyNumber,
        ...remainingDetails
    } = details;
    return {
        ...remainingDetails,
        ...(details?.address?.value === 'Other' && { otherAddress }),
        ...(details?.accidentalDamage?.value === 'Yes' && { accidentalDamageAmount }),
        ...(details?.powerSurge?.value === 'Yes' && { powerSurgeCover }),
        ...(details?.householdLoss?.value === 'Yes' && { claimAmount, claimAmount }),
        ...(details?.insuredWithOM?.value > 0 && {policyNumber})
    };

}

        const {
            previousPostalAddress,
            previousPostalCity,
            previousPostalCountry,
            ...personalDetailWithoutPreviosDetails
        } = purchasePersonalDetailsForm;

        const purchasePersonalDetailFields =
            purchasePersonalDetailsForm.residenceTimePeriod.value === 'Less than two years'
                ? purchasePersonalDetailsForm
                : personalDetailWithoutPreviosDetails;
                
        const homeOwnerFormFeilds = Object.values(getFormFieldstoValidate(homeDetailsForm));
        const homeContentsFormFeilds = Object.values(getFormFieldstoValidate(homeContentForm));
        const motorDetailsFormFeilds = Object.values(motorDetailsForm);
        const personDetailsFieldsQuoting=()=>{
            const {socialPlatform,hearAboutUsPlatform,referFullName,referCellphoneNumber,policyNumber,...remainingDetails}=personaleDetailsForm
           const fieldOptions= ['fromSomeone','omEmployee','broker','other']
            return{
                ...remainingDetails,
                ...(personaleDetailsForm?.hearAboutUs?.displayValue === 'online' && { socialPlatform }),
                ...(fieldOptions.includes(personaleDetailsForm?.hearAboutUs?.displayValue) && { hearAboutUsPlatform }),
                ...(personaleDetailsForm?.referFriendFamily?.value === 'Yes' && { referFullName,referCellphoneNumber }),
                ...(personaleDetailsForm?.insuredWithOM?.value > 0 && {policyNumber})
                
            }
        }
        const personalDetailsFormFeilds = Object.values(personDetailsFieldsQuoting());
        const purchasePersonalDetailsFormFeilds = Object.values(purchasePersonalDetailFields);
        const getVal = (key) => {
            const { [key]: selectedForms } = additionalInfoForm;
            return selectedForms;
        };
        const infopage = () => {
            const homeDetails = isHomeOwnerSelected
                ? Object.values(getVal('homeDetailsList'))?.flatMap(({claimAmount,claimedCount,serviceProviderDetails,financialInstitution,...details}) => Object.values({
                    ...details,
                    ...(details.householdLoss?.value === 'Yes' && {claimAmount,claimedCount}),
                    ...(details?.alarmSystemDetails?.value === 'Yes' && {serviceProviderDetails}),
                    ...(details?.similarAgreementDetails?.value === 'Yes' && {financialInstitution})
                }))
                : [];
            const homeContent = isHomeContentSelected
                ? Object.values(getVal('homeContentList'))?.flatMap(({agreementServiceProvider,...details}) => Object.values({
                    ...details,
                    ...(details?.similarAgreementDetails?.value === 'Yes' && {agreementServiceProvider})
                }))
                : [];

            const motorDetails = isMotorInsuranceSelected
                ? Object.values(getVal('motorDetailForm'))?.flatMap(({ motorServiceProvider, ...details }) =>
                      Object.values({
                          ...details,
                          ...(details.similarAgreementDetails?.value === 'Yes' && { motorServiceProvider }),
                      })
                  )
                : [];
            return [...homeDetails, ...homeContent, ...motorDetails];
        };
        const additionalInfoFormFields = infopage();
        const { otherSourceofFund, debitOrderDay, ...bankingDetailsWithoutDebit } = bankingDetailsForm;
        const bankingDetailsFiltered = {
            ...bankingDetailsWithoutDebit,
            ...(bankingDetailsWithoutDebit?.sourceOfFund?.displayValue === 'Other' && { otherSourceofFund }),
            ...(paymentFrequency === 'Monthly' && { debitOrderDay }),
        };

        const uploadDocumentsFormFields = Object.values(uploadDocumentsForm)?.filter(({ fileName }) => !!fileName);
        const finalDocumentsField=[...uploadDocumentsFormFields,uploadDocumentsForm.declaration]
        const confirmationPageFields = termsAndConditions && recaptchaValue ? defaultFormFields : defaultInvalidFormFields;
        const bankingDetailsFormFields = Object.values(bankingDetailsFiltered);

        const formDataByActivePage = {
            ...[
                insuranceTypeFeilds,
                homeOwnerFormFeilds,
                homeContentsFormFeilds,
                motorDetailsFormFeilds,
                personalDetailsFormFeilds,
                defaultInvalidFormFields,
                defaultInvalidFormFields,
                // Purchase Journey Pages
                defaultFormFields,
                purchasePersonalDetailsFormFeilds,
                additionalInfoFormFields,
                bankingDetailsFormFields,
                finalDocumentsField,
                confirmationPageFields,
            ],
        };
        return formDataByActivePage[activePageIndex]?.every(isValid);
    }
);
