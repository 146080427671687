import { transitionMotorInsuranceDocumentsForm } from '../../../actions/motorPrivateRW/Documents';
import { FIELD_STATUS } from '../../../reducers/status';
import { getDocumentsForm } from '../../../selectors/motorPrivateRW/documents';

export const documentsFormRules = (store, next, action) => {
    const result = next(action);
    const shareQuoteForm = getDocumentsForm(store.getState());
    const quoteFormValid = validateDocumentsFormDetails(shareQuoteForm);
    const formAction = quoteFormValid
        ? transitionMotorInsuranceDocumentsForm.formValid()
        : transitionMotorInsuranceDocumentsForm.formInvalid();
    store.dispatch(formAction);

    return result;
};

const validateDocumentsFormDetails = (formFields) => {
    return (
        formFields.idUpload &&
        formFields.idUpload.value !== null &&
        formFields.yellowCard &&
        formFields.yellowCard.value !== null &&
        formFields.frontView &&
        formFields.frontView.value !== null &&
        formFields.backView &&
        formFields.backView.value !== null &&
        formFields.leftView &&
        formFields.leftView.value !== null &&
        formFields.rightView &&
        formFields.rightView.value !== null &&
        formFields.dashboardView &&
        formFields.dashboardView.value !== null
    );
};
