import { createSelector } from 'reselect';
import { getRoaQuoteTools } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getPhysicalImpairmentCoverQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.physicalImpairmentCoverQuoteTool,
);

export const getSelectedForm = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool =>
        physicalImpairmentCoverQuoteTool.selectedForm,
);

export const getRoaCmbObject = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool =>
        physicalImpairmentCoverQuoteTool.roaCmbObject
)

export const getPhysicalImpairmentCoverQuoteToolForm = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool => physicalImpairmentCoverQuoteTool.form,
);

export const getPhysicalImpairmentCoverQuoteToolFormValid = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool =>
        physicalImpairmentCoverQuoteTool.formStatus === FORM_STATUS.VALID,
);

export const getPhysicalImpairmentCoverPremiumAmount = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool =>
        physicalImpairmentCoverQuoteTool.physicalImpairmentCoverAmount,
);

export const getShowError = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool =>
        physicalImpairmentCoverQuoteTool.apiCallStatus ===
        API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool =>
        physicalImpairmentCoverQuoteTool.apiCallStatus ===
        API_CALL_STATUS.PENDING,
);

export const getCalculateDisabled = createSelector(
    getPhysicalImpairmentCoverQuoteTool,
    physicalImpairmentCoverQuoteTool => {
        return (
            physicalImpairmentCoverQuoteTool.formStatus ===
                FORM_STATUS.INVALID ||
            physicalImpairmentCoverQuoteTool.apiCallStatus ===
                API_CALL_STATUS.PENDING
        );
    },
);

const makeGetFieldForm = fieldId =>
    createSelector(
        getPhysicalImpairmentCoverQuoteToolForm,
        form => form[fieldId],
    );

export const getGenderValue = makeGetFieldForm('genderValue');
export const getCandidateDOB = makeGetFieldForm('candidateDOB');
export const getDisabilityCoveredValue = makeGetFieldForm('disabilityValue');
export const getSmokerCoveredValue = makeGetFieldForm('smokerValue');
export const getCoverAmount = makeGetFieldForm('coverAmount');
export const getNumberOfYearsCovered = makeGetFieldForm('numberOfYearsCovered');
