export const FINANCIAL_EDUCATION = 'financialEducation/journey/';

export const SHOW_UP_NEXT_COUNTDOWN_TIME_TOGGLE = `${FINANCIAL_EDUCATION}toggle/showCountdownTimer`;
export const toggleShowUpNextCountdownTime = (journeyId) => ({ type: SHOW_UP_NEXT_COUNTDOWN_TIME_TOGGLE, journeyId });

export const JOURNEY_EXPANDED_JOURNEY_ITEM_SET = `${FINANCIAL_EDUCATION}transition/expandedJourneyItem`;
export const UP_NEXT_COUNTDOWN_TIMER_SET = `${FINANCIAL_EDUCATION}transition/updateUpNextCountdownTime`;
export const DELIGHT_ANIMATION_COUNTDOWN_TIMER_SET = `${FINANCIAL_EDUCATION}transition/updateDelightAnimationCountdownTime`;

export const setExpandedJourneyItem = (journeyId, itemId) => ({ type: JOURNEY_EXPANDED_JOURNEY_ITEM_SET, journeyId, itemId });
export const setUpNextCountdownTime = (journeyId, value) => ({ type: UP_NEXT_COUNTDOWN_TIMER_SET, journeyId, value });
export const setDelightAnimationCountdownTime = (journeyId, value) => ({ type: DELIGHT_ANIMATION_COUNTDOWN_TIMER_SET, journeyId, value });

export const FINANCIAL_EDUCATION_JOURNEY_CREATE = `${FINANCIAL_EDUCATION}create/journey`;
export const JOURNEY_ITEM_UPDATE = `${FINANCIAL_EDUCATION}update/journeyItem`;

export const createFinancialEducationJourney = (journeyId, journeyItems) => ({ type: FINANCIAL_EDUCATION_JOURNEY_CREATE, journeyId, journeyItems });
export const updateJourneyItem = (journeyId, itemId) => ({ type: JOURNEY_ITEM_UPDATE, journeyId, itemId });
