import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from "../status";

import { 
    RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_FORM_TRANSITION,
    RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_TRANSITION_FORM_VALID,
    RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL_TRANSITION_FORM_INVALID,
    RETIREMENT_AGE_SET_VALUE,
    RETIREMENT_AGE_SET_VALID,
    NUMBER_OF_RETIREMENT_YEARS_SET_VALUE,
    NUMBER_OF_RETIREMENT_YEARS_SET_VALID,
    EXPECTED_ANNUAL_GROWTH_SET_VALUE,
    EXPECTED_ANNUAL_GROWTH_SET_VALID,
    INFLATION_SET_VALUE,
    INFLATION_SET_VALID,
    EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALUE,
    EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALID,
    REPLACEMENT_RATIO_SET_VALUE,
    REPLACEMENT_RATIO_SET_VALID,
    RETIREMENT_AGE_ASSUMPTIONS_DEFUALT_SET_VALUE,
    NUMBER_OF_RETIREMENT_YEARS_ASSUMPTIONS_DEFUALT_SET_VALUE,
    EXPECTED_ANNUAL_GROWTH_ASSUMPTIONS_DEFUALT_SET_VALUE,
    INFLATION_ASSUMPTIONS_DEFUALT_SET_VALUE,
    EXPECTED_ANNUAL_SALARY_INCREASE_ASSUMPTIONS_DEFUALT_SET_VALUE,
    REPLACEMENT_RATIO_ASSUMPTIONS_DEFUALT_SET_VALUE,
    REINITIALIZE_STATE_RETIREMENT_CALCULATOR_ASSUMPTIONS_MODEL
} from '../../actions/retirementCalculator/retirementAssumptionsModel'

import {
    RESET_CALCULATOR_FORM
} from '../../actions/retirementCalculator/retirementCalculatorForm'

const retirementCalculatorAssumptionsModel = {
    retirementAge: {value: null, error: null, status: null},
    numberOfRetirementYears: {value: null, error: null, status: null},
    expectedAnnualGrowth: {value: null, error: null, status: null},
    inflation: {value: null, error: null, status: null},
    expectedAnnualSalaryIncrease:{value: null, error: null, status: null},
    replacementRatio:{value: null, error: null, status: null}
}

const initialState = {
    formStatus: FORM_STATUS.INVALID,
    assumptionDefaults: {
        retirementAgeValue: 60,
        numberOfRetirementYearsValue: 20,
        expectedAnnualGrowthValue: 4,
        inflationValue: 6,
        expectedAnnualSalaryIncrease: 7,
        replacementRatio: 75
    },
    form: retirementCalculatorAssumptionsModel
}

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch(action.type) {

        // Retirement Age
        case RETIREMENT_AGE_SET_VALUE:
            return updateFormField('retirementAge', field => ({
                ...field,
                value: action.value,
            }));
        case RETIREMENT_AGE_SET_VALID:
            return updateFormField('retirementAge', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Number of Retirement Years
        case NUMBER_OF_RETIREMENT_YEARS_SET_VALUE:
            return updateFormField('numberOfRetirementYears', field => ({
                ...field,
                value: action.value,
            }));
        case NUMBER_OF_RETIREMENT_YEARS_SET_VALID:
            return updateFormField('numberOfRetirementYears', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Expected Annual growth
        case EXPECTED_ANNUAL_GROWTH_SET_VALUE:
            return updateFormField('expectedAnnualGrowth', field => ({
                ...field,
                value: action.value,
            }));
        case EXPECTED_ANNUAL_GROWTH_SET_VALID:
            return updateFormField('expectedAnnualGrowth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case INFLATION_SET_VALUE:
            return updateFormField('inflation', field => ({
                ...field,
                value: action.value,
            }));
        case INFLATION_SET_VALID:
            return updateFormField('inflation', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Expected annual salary increase
        case EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALUE:
            return updateFormField('expectedAnnualSalaryIncrease', field => ({
                ...field,
                value: action.value,
            }));
        case EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALID:
            return updateFormField('expectedAnnualSalaryIncrease', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Replacement Ratio
        case REPLACEMENT_RATIO_SET_VALUE:
            return updateFormField('replacementRatio', field => ({
                ...field,
                value: action.value,
            }));
        case REPLACEMENT_RATIO_SET_VALID:
            return updateFormField('replacementRatio', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // updating default values

        case RETIREMENT_AGE_ASSUMPTIONS_DEFUALT_SET_VALUE:
            return {
                ...state,
                assumptionDefualts: {
                    ...state.assumptionDefualts,
                    retirementAgeValue: action.value,
                }
            };

        case NUMBER_OF_RETIREMENT_YEARS_ASSUMPTIONS_DEFUALT_SET_VALUE:
            return{
                ...state,
                assumptionDefualts: {
                    ...state.assumptionDefualts,
                    numberOfRetirementYearsValue: action.value}
            };

        case EXPECTED_ANNUAL_GROWTH_ASSUMPTIONS_DEFUALT_SET_VALUE:
            return{
                ...state,
                assumptionDefualts: {
                    ...state.assumptionDefualts,
                    expectedAnnualGrowthValue: action.value}
            };

        case INFLATION_ASSUMPTIONS_DEFUALT_SET_VALUE:
            return{
                ...state,
                assumptionDefualts: {
                    ...state.assumptionDefualts,
                    inflationValue: action.value}
            };

        case EXPECTED_ANNUAL_SALARY_INCREASE_ASSUMPTIONS_DEFUALT_SET_VALUE:
            return{
                ...state,
                assumptionDefualts: {
                    ...state.assumptionDefualts,
                    expectedAnnualSalaryIncrease: action.value}
            };

        case REPLACEMENT_RATIO_ASSUMPTIONS_DEFUALT_SET_VALUE:
            return{
                ...state,
                assumptionDefualts: {
                    ...state.assumptionDefualts,
                    replacementRatio: action.value}
            };

        case RESET_CALCULATOR_FORM: 
            return initialState;

        default:
            return state;
    }
}
