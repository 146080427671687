import {
    BUSINESS_PRODUCTS_PARAMS_SET_VALUE,
    RECEIVED_BUSINESS_PRODUCTS_MAP
} from '../../actions/publicWeb/businessProducts';

const initialState = {
    productData: {
        products: [],
        count: 0,
        error: {}
    },
    visibleProducts: [],
    params:
    {
        uid: null,
        skip: 0,
        take: 0
    },

};

export default function (state = initialState, action) {

    const updateProductsField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    const updateParamsField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state.params[fieldId])
    });

    switch (action.type) {
        case BUSINESS_PRODUCTS_PARAMS_SET_VALUE:
            return updateParamsField('params', field => ({
                ...field,
                uid: action.value.uid,
                skip: action.value.skip,
                take: action.value.take,
            }));
        case RECEIVED_BUSINESS_PRODUCTS_MAP:
            return updateProductsField('productData', field => ({
                ...field,
                products: action.value.products,
                count: action.value.count,
                error: action.value.error
            }));
        default:
            return state;
    }
}

