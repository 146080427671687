import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    HAS_PREVIOUSLY_HELD_POLICY_SET_VALUE,
    HAS_PREVIOUSLY_HELD_POLICY_SET_VALID,
    PREVIOUS_INSURER_NAME_SET_VALUE,
    PREVIOUS_INSURER_NAME_SET_VALID,
    PREVIOUS_INSURER_ADDRESS_SET_VALUE,
    PREVIOUS_INSURER_ADDRESS_SET_VALID,
    PREVIOUS_INSURER_BRANCH_SET_VALUE,
    PREVIOUS_INSURER_BRANCH_SET_VALID,
    PREVIOUS_INSURER_POLICY_NUMBER_SET_VALUE,
    PREVIOUS_INSURER_POLICY_NUMBER_SET_VALID,
    IS_POLICY_ADDITIONAL_SET_VALUE,
    IS_POLICY_ADDITIONAL_SET_VALID,
    ADDITIONAL_POLICIES_NUMBER_SET_VALUE,
    ADDITIONAL_POLICIES_NUMBER_SET_VALID,
    ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALUE,
    ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALID,
    DECLINED_PROPOSAL_SET_VALUE,
    DECLINED_PROPOSAL_SET_VALID,
    REFUSED_RENEWAL_SET_VALUE,
    REFUSED_RENEWAL_SET_VALID,
    TERMINATED_INSURANCE_SET_VALUE,
    TERMINATED_INSURANCE_SET_VALID,
    TERMINATED_INSURANCE_DETAILS_SET_VALUE,
    TERMINATED_INSURANCE_DETAILS_SET_VALID,
    REQUIRED_INCREASED_PREMIUM_SET_VALUE,
    REQUIRED_INCREASED_PREMIUM_SET_VALID,
    IMPOSED_SPECIAL_CONDITIONS_SET_VALUE,
    IMPOSED_SPECIAL_CONDITIONS_SET_VALID,
    FORM_VALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM,
    FORM_INVALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM,
} from '../../actions/personalAccident/declarations';

export const initDeclaration = {
    formStatus: FORM_STATUS.VALID,
    dropOffDetails: null,
    declarationReferenceData: null,
    apiCallStatus: null,
    form: {
        hasPreviouslyHeldPolicy: { value: 'no', status: FIELD_STATUS.VALID, error: null },
        previousInsurerName: { value: null, status: null, error: null },
        previousInsurerAddress: { value: null, status: null, error: null },
        previousInsurerBranch: { value: null, status: null, error: null },
        previousInsurerPolicyNo: { value: null, status: null, error: null },
        isAdditionalPolicy: { value: 'no', status: FIELD_STATUS.VALID, error: null },
        additionalPolicyNumber: { value: null, status: null, error: null },
        additionalPolicyTotalDeathBenefit: { value: null, status: null, error: null },
        declinedProposal: { value: 'no', status: FIELD_STATUS.VALID, error: null },
        refusedRenewal: { value: 'no', status: FIELD_STATUS.VALID, error: null },
        terminatedInsurance: { value: 'no', status: FIELD_STATUS.VALID, error: null },
        terminatedInsuranceDetails: { value: null, status: null, error: null },
        requiredPremiumIncrease: { value: 'no', status: FIELD_STATUS.VALID, error: null },
        imposedSpecialConditions: { value: 'no', status: FIELD_STATUS.VALID, error: null },
    },
};

export default (state = initDeclaration, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_PERSONAL_ACCIDENT_DECLARATIONS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case HAS_PREVIOUSLY_HELD_POLICY_SET_VALUE:
            return updateFormField('hasPreviouslyHeldPolicy', (field) => ({ ...field, value: action.value }));

        case HAS_PREVIOUSLY_HELD_POLICY_SET_VALID:
            return updateFormField('hasPreviouslyHeldPolicy', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PREVIOUS_INSURER_NAME_SET_VALUE:
            return updateFormField('previousInsurerName', (field) => ({ ...field, value: action.value }));

        case PREVIOUS_INSURER_NAME_SET_VALID:
            return updateFormField('previousInsurerName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PREVIOUS_INSURER_ADDRESS_SET_VALUE:
            return updateFormField('previousInsurerAddress', (field) => ({ ...field, value: action.value }));

        case PREVIOUS_INSURER_ADDRESS_SET_VALID:
            return updateFormField('previousInsurerAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PREVIOUS_INSURER_BRANCH_SET_VALUE:
            return updateFormField('previousInsurerBranch', (field) => ({ ...field, value: action.value }));

        case PREVIOUS_INSURER_BRANCH_SET_VALID:
            return updateFormField('previousInsurerBranch', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PREVIOUS_INSURER_POLICY_NUMBER_SET_VALUE:
            return updateFormField('previousInsurerPolicyNo', (field) => ({ ...field, value: action.value }));

        case PREVIOUS_INSURER_POLICY_NUMBER_SET_VALID:
            return updateFormField('previousInsurerPolicyNo', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case IS_POLICY_ADDITIONAL_SET_VALUE:
            return updateFormField('isAdditionalPolicy', (field) => ({ ...field, value: action.value }));

        case IS_POLICY_ADDITIONAL_SET_VALID:
            return updateFormField('isAdditionalPolicy', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ADDITIONAL_POLICIES_NUMBER_SET_VALUE:
            return updateFormField('additionalPolicyNumber', (field) => ({ ...field, value: action.value }));

        case ADDITIONAL_POLICIES_NUMBER_SET_VALID:
            return updateFormField('additionalPolicyNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALUE:
            return updateFormField('additionalPolicyTotalDeathBenefit', (field) => ({ ...field, value: action.value }));

        case ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALID:
            return updateFormField('additionalPolicyTotalDeathBenefit', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DECLINED_PROPOSAL_SET_VALUE:
            return updateFormField('declinedProposal', (field) => ({ ...field, value: action.value }));

        case DECLINED_PROPOSAL_SET_VALID:
            return updateFormField('declinedProposal', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case REFUSED_RENEWAL_SET_VALUE:
            return updateFormField('refusedRenewal', (field) => ({ ...field, value: action.value }));

        case REFUSED_RENEWAL_SET_VALID:
            return updateFormField('refusedRenewal', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TERMINATED_INSURANCE_SET_VALUE:
            return updateFormField('terminatedInsurance', (field) => ({ ...field, value: action.value }));

        case TERMINATED_INSURANCE_SET_VALID:
            return updateFormField('terminatedInsurance', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TERMINATED_INSURANCE_DETAILS_SET_VALUE:
            return updateFormField('terminatedInsuranceDetails', (field) => ({ ...field, value: action.value }));

        case TERMINATED_INSURANCE_DETAILS_SET_VALID:
            return updateFormField('terminatedInsuranceDetails', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case REQUIRED_INCREASED_PREMIUM_SET_VALUE:
            return updateFormField('requiredIncreasedPremium', (field) => ({ ...field, value: action.value }));

        case REQUIRED_INCREASED_PREMIUM_SET_VALID:
            return updateFormField('requiredIncreasedPremium', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case IMPOSED_SPECIAL_CONDITIONS_SET_VALUE:
            return updateFormField('imposedSpecialConditions', (field) => ({ ...field, value: action.value }));

        case IMPOSED_SPECIAL_CONDITIONS_SET_VALID:
            return updateFormField('imposedSpecialConditions', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Reset the state
        case REINITIALIZE:
            return initDeclaration;
        default:
            return state;
    }
};
