import {
    FUND_SET_VALID,
    FUND_SET_VALUE,
    DESELECT_ALL_FUNDS_TRANSITION_SELECT_A_FUND,
    RESET_SELECT_A_FUND
} from "../../actions/taxFreeSavingsAccount/selectAFund";
import {
    FIELD_STATUS,
} from "../status";

export const initSelectAFund = {
    form: {
        selectedFund: { value: null, error: null, status: null },
    },
};

export default (state = initSelectAFund, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case DESELECT_ALL_FUNDS_TRANSITION_SELECT_A_FUND:
            return {
                ...state,
                form: {
                    ...state.form,
                    selectedFund: { value: null, error: null, status: null },
                }
            };

        case FUND_SET_VALUE:
            return updateFormField('selectedFund', field => ({
                ...field,
                value: action.value
            }));

        case FUND_SET_VALID:
            return updateFormField('selectedFund', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RESET_SELECT_A_FUND: 
            return { ...initSelectAFund };

        default:
            return state;
    }
};
