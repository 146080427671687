import {
    GUARANTEED_DRAWER_OPEN_SOLUTIONS,
    LIVING_DRAWER_OPEN_SOLUTIONS,
    COMPOSITE_DRAWER_OPEN_SOLUTIONS,
    SOLUTIONS_GUARANTEED_ANNUITY_CHOSEN,
    SOLUTIONS_LIVING_ANNUITY_CHOSEN,
    SOLUTIONS_COMPOSITE_ANNUITY_CHOSEN,
} from "../../../actions/atRetirement/solutions"
import {
    getSolutionsProductChosen,
    getSolutionsOpenDrawer
} from "../../../selectors/atRetirement/solutions"
import { getReferrerId } from '../../../selectors/atRetirement/intro'
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const solutionsFieldTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const referrerId = getReferrerId(state);
    const field = (() => {
        switch (action.type) {
            case SOLUTIONS_GUARANTEED_ANNUITY_CHOSEN: return getSolutionsProductChosen(state);
            case SOLUTIONS_LIVING_ANNUITY_CHOSEN: return getSolutionsProductChosen(state);
            case SOLUTIONS_COMPOSITE_ANNUITY_CHOSEN: return getSolutionsProductChosen(state);
        }
    })();

    if (field) {
        otherEventTracking(action.type, findOtherEventIndex("SELECT"), field, referrerId);
    }

    return result;
};

export const solutionsTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const trackingActions = [
        GUARANTEED_DRAWER_OPEN_SOLUTIONS,
        LIVING_DRAWER_OPEN_SOLUTIONS,
        COMPOSITE_DRAWER_OPEN_SOLUTIONS,
    ];

    let openDrawer = getSolutionsOpenDrawer(state);
    if (trackingActions.includes(action.type)){
        if(openDrawer){
            otherEventTracking(action.type, findOtherEventIndex("OPEN_DRAWER"), openDrawer);
        }
    }

    return result;
};
