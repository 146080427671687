import {
    REINITIALIZE_STATE_INVESTMENT_CALCULATOR,
    SELECT_PCT_INVESTMENT_CALCULATOR,
    SELECT_YEARS_INVESTMENT_CALCULATOR,
    SET_VIEW_RECOMMENDATIONS_INVESTMENT_CALCULATOR,
    WHAT_TO_CALCULATE_SET_VALUE,
} from '../../actions/investmentCalculator/investmentCalculator';

import {
    RESULTS_SET_VALUE,
    RESULTS_SET_VALUE_FAILURE,
    RESULTS_VIEW_VALUE,
} from '../../actions/investmentCalculator/emergencyFund';

import { API_CALL_STATUS } from '../status';

export const initialState = {
    selectedCalculator: 'emergency',
    formReferences: [
        { name: 'emergency', goal: 1, description: 'Emergency Fund' },
        {
            name: 'regularIncome',
            goal: 2,
            description: 'To generate regular income',
        },
        { name: 'shortTerm', goal: 3, description: 'Short Term Goal' },
        { name: 'longTerm', goal: 4, description: 'Long Term Goal' },
        { name: 'buildWealth', goal: 5, description: 'Build Wealth' },
    ],
    result: { message: null, minEarnings: null, maxEarnings: null },
    dropsReference: [
        { drop: 1, description: '0%' },
        { drop: 2, description: '5%' },
        { drop: 3, description: '10%' },
        { drop: 4, description: 'More than 10%' },
    ],
    durationsReference: [
        { duration: 9, description: '1 Month' },
        { duration: 10, description: '6 Months' },
        { duration: 11, description: '1 Year' },
        { duration: 12, description: '2 Years' },
        { duration: 13, description: '3 Years' },
        { duration: 14, description: 'More than 3 Years' },
    ],
    selectedDrop: 1,
    selectedDuration: 9,
    showRecommendation: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case WHAT_TO_CALCULATE_SET_VALUE:
            return {
                ...state,
                selectedCalculator: state.formReferences.find(
                    (x) => x.description === action.value
                ).name,
            };

        case RESULTS_SET_VALUE_FAILURE:
            return {
                ...state,
                statstatus: API_CALL_STATUS.FAILURE,
                error: action.error,
            };

        case RESULTS_VIEW_VALUE:
            return {
                ...state,
                result: {
                    ...state.result,
                    message: action.value?.message,
                    minEarnings: action.value?.lower_amount
                        ? action.value?.lower_amount
                        : action.value?.full_lower_amount
                        ? action.value.full_lower_amount
                        : '',
                    maxEarnings: action.value?.higher_amount
                        ? action.value?.higher_amount
                        : action.value?.full_higher_amount
                        ? action.value?.full_higher_amount
                        : '',
                },
            };

        case REINITIALIZE_STATE_INVESTMENT_CALCULATOR:
            return {
                ...state,
                ...initialState,
                selectedCalculator: state.selectedCalculator,
            };
        case SELECT_YEARS_INVESTMENT_CALCULATOR:
            return {
                ...state,
                selectedDuration: action.value,
            };
        case SELECT_PCT_INVESTMENT_CALCULATOR:
            return {
                ...state,
                selectedDrop: action.value,
            };
        case SET_VIEW_RECOMMENDATIONS_INVESTMENT_CALCULATOR:
            return {
                ...state,
                showRecommendation: true,
            };
        default:
            return state;
    }
};
