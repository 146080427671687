export const AFYA_IMARA_SENIOR_QUOTE_TOOL = 'roaQuoteTools/afyaImaraSeniorQuoteTool/';
export const AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE = AFYA_IMARA_SENIOR_QUOTE_TOOL + 'setValue/';
export const AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALID = AFYA_IMARA_SENIOR_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_AFYA_IMARA_SENIOR_QUOTE_TOOL = 'roaQuoteTools/afyaImaraSeniorQuoteTool/transition/formStateValid';
export const FORM_INVALID_AFYA_IMARA_SENIOR_QUOTE_TOOL = 'roaQuoteTools/afyaImaraSeniorQuoteTool/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/afyaImaraSeniorQuoteTool/state/reinitialize';

export const transitionAfyaImaraSeniorQuoteTool = {
    formValid: () => ({ type: FORM_VALID_AFYA_IMARA_SENIOR_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_AFYA_IMARA_SENIOR_QUOTE_TOOL }),
};

export const INPATIENT_COVER_LIMIT_SET_VALUE = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE + 'inpatientCoverLimit';
export const INPATIENT_COVER_LIMIT_SET_VALID = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALID + 'inpatientCoverLimit';
export const OUTPATIENT_COVER_LIMIT_SET_VALUE = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE + 'outpatientCoverLimit';
export const OUTPATIENT_COVER_LIMIT_SET_VALID = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALID + 'outpatientCoverLimit';
export const WHICH_FORM_TO_DISPLAY = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const COLLECT_AFYA_IMARA_SENIOR_DATA = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE + 'collectAfyaImaraSeniorData';
export const CALCULATE_AFYA_IMARA_SENIOR_DATA = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_AFYA_IMARA_SENIOR_DATA_FAILURE = AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL = `${AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL = `${AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL = `${AFYA_IMARA_SENIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionAfyaImaraSeniorQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_AFYA_IMARA_SENIOR_QUOTE_TOOL }),
};

export const collectAfyaImaraSeniorData = () => ({ type: COLLECT_AFYA_IMARA_SENIOR_DATA });
export const calculateAfyaImaraSeniorData = (value) => ({ type: CALCULATE_AFYA_IMARA_SENIOR_DATA, value});
export const collectAfyaImaraSeniorDataFailure = error => ({ type: CALCULATE_AFYA_IMARA_SENIOR_DATA_FAILURE, error });

export const setInpatientCoverLimitValue = value => ({ type: INPATIENT_COVER_LIMIT_SET_VALUE, value });
export const setInpatientCoverLimitValid = error => ({ type: INPATIENT_COVER_LIMIT_SET_VALID, error });
export const setOutpatientCoverLimitValue = value => ({ type: OUTPATIENT_COVER_LIMIT_SET_VALUE, value });
export const setOutpatientCoverLimitVALID = error => ({ type: OUTPATIENT_COVER_LIMIT_SET_VALID, error });
export const setWhichFormToDisplay = value => ({ type: WHICH_FORM_TO_DISPLAY, value });
export const setToAfyaImaraSeniorQuoteInitial = () => ({ type: REINITIALIZE });

