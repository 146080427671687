import { createSelector } from 'reselect';
import { getDomesticInsurance } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';


export const getPersonalDetails = createSelector(getDomesticInsurance, (state) => state.personalDetails);

export const getPersonalDetailsForm = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.form,
);

export const getDropOffDetails = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.dropOffDetails,
);

export const getPersonalDetailsFormValid = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.formStatus === API_CALL_STATUS.PENDING,
);

const makeGetFieldForm = (fieldId) => createSelector(getPersonalDetailsForm, (form) => form[fieldId]);

export const getDOBValue = makeGetFieldForm('dob');
export const getGenderValue = makeGetFieldForm('gender');
export const getFirstNameValue = makeGetFieldForm('firstName');
export const getLastNameValue = makeGetFieldForm('lastName');
export const getPhoneNumberValue = makeGetFieldForm('phoneNumber');
export const getEmailValue = makeGetFieldForm('email');
export const getIdNumberValue = makeGetFieldForm("idNumber");
export const getTinNumberValue = makeGetFieldForm("tinNumber")
export const getPostalAddressValue = makeGetFieldForm("postalAddress")
export const getOccupationValue = makeGetFieldForm("occupation")
export const getNationalityValue = makeGetFieldForm("nationality")

export const getStreetAddressValue = makeGetFieldForm('streetAddress');
export const getSurbubsValue = makeGetFieldForm('suburbs');
export const getCityValue = makeGetFieldForm('city');
export const getPostalCode = makeGetFieldForm('postalCode');
export const getPoBox= makeGetFieldForm('pobox');