// Transitions

export const FORM_VALID_TRANSITION_REPLACEMENT_POLICY = 'easiplusFuneralPlan/replacementPolicy/transition/formValid';
export const FORM_INVALID_TRANSITION_REPLACEMENT_POLICY = 'easiplusFuneralPlan/replacementPolicy/transition/formInvalid';

export const transitionReplacementPolicy = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_REPLACEMENT_POLICY }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_REPLACEMENT_POLICY }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_REPLACEMENT_POLICY = 'easiplusFuneralPlan/replacementPolicy/transition/resetState';
export const resetReplacementPolicy = () => ({ type: RESET_STATE_TRANSITION_REPLACEMENT_POLICY });

// Events
// Base path
export const REPLACEMENT_POLICY_SET_VALUE = 'easiplusFuneralPlan/replacementPolicy/setValue/';
export const REPLACEMENT_POLICY_SET_VALID = 'easiplusFuneralPlan/replacementPolicy/setValid/';

export const IS_REPLACEMENT_POLICY_SET_VALUE = REPLACEMENT_POLICY_SET_VALUE + 'isReplacementPolicy';
export const IS_REPLACEMENT_POLICY_SET_VALID = REPLACEMENT_POLICY_SET_VALID + 'isReplacementPolicy';

export const setIsReplacementPolicyValue = value => ({ type: IS_REPLACEMENT_POLICY_SET_VALUE, value });
export const setIsReplacementPolicyValid = error => ({ type: IS_REPLACEMENT_POLICY_SET_VALID, error });
