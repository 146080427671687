import { getIsMakesApiPending } from '../../../selectors/motorPrivateRW/quote';
import {
    apiTransitionCarMakes,
    setCarMakesValid,
    setCarMakesValue

} from '../../../actions/motorPrivateRW/quote';
import { getAccessToken } from '../../../selectors/motorPrivateRW/payments';
import { getVehicleTypeValue } from '../../../selectors/motorPrivateRW/quote';
import axios from 'axios';

export const getCarMakesService = (store, next, action) => {
    (async () => {
        if (!getIsMakesApiPending(store.getState())) {
            store.dispatch(apiTransitionCarMakes.pending());
            try {
                const resp = await getMakes(store.getState());
                store.dispatch(setCarMakesValue(resp.data.carMake));
                store.dispatch(apiTransitionCarMakes.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionCarMakes.failure());
                store.dispatch(setCarMakesValid(e));
            }
        }
    })();
    return next(action);
};

const getMakes = async (state) => {
    const loginData = getAccessToken(state);
    const vehicleType = getVehicleTypeValue(state)
    const vehicleMakeType = (vehicleType?.value === null) || (vehicleType.value === 'Car') ? 1 : 2
    try {
        const url = '/om-api/ug-motor-insurance/car-makes';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                type: vehicleMakeType,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
