import {createSelector} from "reselect";
import {
    FORM_SUBMIT_STATUS,
} from "../../reducers/status";
import{ YES, NO } from "../../reducers/publicWeb/signupAndDownloadForm";
import validate from '../../middleware/utils/formValidators';

export const getSignupAndDownload = (state => {
    return state.publicWeb.signupAndDownloadForm
});

const makeGetField = fieldId => createSelector(
    getSignupAndDownload,
    signupAndDownloadForm => signupAndDownloadForm[fieldId]
);

export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getEmail = makeGetField('email');
export const getCompanyName = makeGetField('companyName');
export const getPhoneNumber = makeGetField('phoneNumber');
export const getIndustry = makeGetField('industry');
export const getAgreeNewsletter = makeGetField('agreeNewsletter');
export const getAgreePrivacyPolicy = makeGetField('agreePrivacyPolicy');
export const getNumberEmployees = makeGetField('numberEmployees');
export const getUserOwnsBusiness = makeGetField('userOwnsBusiness');
export const getTools = makeGetField('tools');
export const getEbooks = makeGetField('ebooks');
export const getInterests = makeGetField('interests');
export const getRecaptchaToken = makeGetField('recaptcha');
export const getFormSubmitStatus = makeGetField('formSubmitStatus');
export const getUserOwnsBusinessChoices = makeGetField('userOwnsBusinessChoices');
export const getShowPhoneNumberField = makeGetField('showPhoneNumberField');
export const getIsSideDrawerOpenValue = makeGetField('isOpen');
export const getUseModularFields = makeGetField('useModularFields');
export const getUseOnlyModularFields = makeGetField('useOnlyModularFields');
export const getModularFields = makeGetField('modularFields');
export const getModularField1 = makeGetField('modularField1');
export const getModularField2 = makeGetField('modularField2');
export const getModularField3 = makeGetField('modularField3');
export const getModularField4 = makeGetField('modularField4');
export const getModularField5 = makeGetField('modularField5');
export const getModularField6 = makeGetField('modularField6');
export const getModularField7 = makeGetField('modularField7');
export const getModularField8 = makeGetField('modularField8');
export const getUseIndustriesList = makeGetField('useIndustriesList');
export const getIndustriesList = makeGetField('industriesList');
export const getModularIndustryValue = makeGetField('industriesValue');

export const getUserOwnsBusinessValue = createSelector(
    getUserOwnsBusiness,
    userOwnsBusiness => userOwnsBusiness.value ? YES : NO
)

export const getButtonText = createSelector(
    getSignupAndDownload,
    signupAndDownloadForm => {
        switch (signupAndDownloadForm.formSubmitStatus) {
            case FORM_SUBMIT_STATUS.NEW:
                return 'Continue';
            case FORM_SUBMIT_STATUS.SUCCESS:
                return 'Sent';
            case FORM_SUBMIT_STATUS.SUBMITTING:
                return 'Sending...';
            case FORM_SUBMIT_STATUS.ERROR:
                return 'Try Again';
        };
    },
);

export const getIsApiSuccess = createSelector(
    getFormSubmitStatus,
    formSubmitStatus => formSubmitStatus === FORM_SUBMIT_STATUS.SUCCESS
);

export const getIsApiFailure = createSelector(
    getFormSubmitStatus,
    formSubmitStatus => formSubmitStatus === FORM_SUBMIT_STATUS.ERROR
);

export const getIsApiSubmitting= createSelector(
    getFormSubmitStatus,
    formSubmitStatus => formSubmitStatus === FORM_SUBMIT_STATUS.SUBMITTING
);

export const getIsApiComplete = createSelector(
    getIsApiSuccess,
    getIsApiFailure,
    (isApiSuccess, isApiFailure)=> (isApiSuccess || isApiFailure)
);

export const getSignUpFormValid = createSelector(
    getSignupAndDownload,
    signupAndDownload => {
    if(signupAndDownload.useOnlyModularFields.value){
        if(signupAndDownload.userOwnsBusiness.value) //if only modular fields and user business owner
            return isUserOwnsBusinesValid(signupAndDownload) 
            && isOnlyModularFieldsValid(signupAndDownload);
        
            return isOnlyModularFieldsValid(signupAndDownload);
    }

    if(signupAndDownload.userOwnsBusiness.value){
        if(!signupAndDownload.useOnlyModularFields.value)//if user business owner and non modular fields
            return isUserOwnsBusinesValid(signupAndDownload) 
            && isUserDetailsValid(signupAndDownload);
           
       return isUserOwnsBusinesValid(signupAndDownload);
    }

    if(!signupAndDownload.userOwnsBusiness.value){
        if(signupAndDownload.useModularFields.value)//if not user business owner and non modular fields and modular fields 
            return isOnlyModularFieldsValid(signupAndDownload)
            && isUserDetailsValid(signupAndDownload);
        
        return isUserDetailsValid(signupAndDownload);
    }
});

export const isModularFields = createSelector(
    getSignupAndDownload,
    (signupAndDownload) => (signupAndDownload.useModularFields.value || 
        signupAndDownload.useOnlyModularFields.value)
);

export const getIsModularFieldsValues= createSelector(
    getSignupAndDownload,
    isModularFields,
    (signupAndDownload, useModularFields) => {
        if(useModularFields && signupAndDownload.modularFields.value.length > 0){
           let values = signupAndDownload.modularFields.value.map(x => x.field_type);
         
            return values;
        }
        return [];
    }
);

export const getIsModularFields= createSelector(
    getSignupAndDownload,
    isModularFields,
    (signupAndDownload, useModularFields) => {
        if(useModularFields){
           let values = signupAndDownload.modularFields.value;
           return values;
        }
        return [];
    }
);

export const getIsModularFieldsValid= createSelector(
    getSignupAndDownload,
    isModularFields,
    getIsModularFieldsValues,
    (signupAndDownload, useModularFields, fieldValues) => {
        let isValid = false;
        if(useModularFields){
        //if(useModularFields && signupAndDownload.value){
            isValid = validate(signupAndDownload, fieldValues)
        
        if(signupAndDownload.useIndustriesList.value)
            isValid = validate(signupAndDownload, ['industriesValue']) && validate(signupAndDownload, fieldValues)

            return isValid;
        }
        return true;

    }
);


const isNullOrEmpty = (val) => {
    return val === null || val === undefined || (typeof val === 'string' && val.trim() === '');
};

const isUserOwnsBusinesValid = (values) => {   
    let isValid = validate(values, [
        'companyName',
        'numberEmployees'])
        && values.agreePrivacyPolicy.value
        && !isNullOrEmpty(values.industry.value.detail.value)
        && !isNullOrEmpty(values.recaptcha.value)
    return isValid;
    
};

const isUserDetailsValid = (values) => { 
    let isValid;
    if(values.showPhoneNumberField){
        isValid = validate(values, [
            'firstName',
            'surname',
            'email',
            'phoneNumber'])
            && values.agreePrivacyPolicy.value
            && !isNullOrEmpty(values.recaptcha.value)
    }else {
        isValid = validate(values, [
            'firstName',
            'surname',
            'email'])
            && values.agreePrivacyPolicy.value
            && !isNullOrEmpty(values.recaptcha.value)
    }

    return isValid;
    
};

const isOnlyModularFieldsValid = (values) => {
    const isValid = values.agreePrivacyPolicy.value && !isNullOrEmpty(values.recaptcha.value);
    return isValid;
};

export const getInputDisabled = createSelector(
    getIsApiSuccess,
    getIsApiSubmitting,
    getSignUpFormValid,
    getIsModularFieldsValid,
    (isApiSuccess, isApiSubmitting, signupFormValid, modularFieldsValid) => (isApiSuccess
        || isApiSubmitting || !signupFormValid || !modularFieldsValid)
);

