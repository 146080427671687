import {validatePercent} from  "@om/validation";
import {
    setSplitPercentageValid,
    SPLIT_PERCENTAGE_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/beneficiariesSplit";

export const beneficiariesSplitValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SPLIT_PERCENTAGE_SET_VALUE:
                error = validatePercent(action.value);
                return setSplitPercentageValid(error, action.index);
            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
