import { config } from '../config/index.js';
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

export function validateLoanTerm(
	value,
	{
		min,
		max,
		termExpiry,
		dateValue,
		required = true,
		subject = 'Number of months',
		retainSubjectCase,
		pronoun = config.PRONOUN.YOUR,
	} = {}
) {

	const  calculateMonthsFromDOB = (dateOfBirth) => {
		const currentDate = new Date();
		const dob = new Date(dateOfBirth);
	  
		// Calculate the difference in milliseconds
		const timeDiff = currentDate - dob;
	  
		// Calculate the difference in months
		const months = Math.floor(timeDiff / (1000*60*60*24*30.44)); // Average days in a month
	  
		return months;
	} 

	const age = calculateMonthsFromDOB(dateValue);
	const termExpiryMonth = termExpiry * 12;
	const maxMonthCovered = termExpiryMonth - age;
	const ageTerm = Number(age) + Number(value);
	const invalidMsg = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);

	const regEx = new RegExp('^[0-9]*$');
    const zeroRegEx = new RegExp('^[0]*$');

    if (!regEx.test(value)) {
        return invalidMsg;
    }

    if (zeroRegEx.test(value)) {
        return invalidMsg;
    }
	
	if ((Number(value) < min)) {
		return OmValidationMessageHelper.getLoanTermMinLimit(
			min,
			pronoun
		);
	}

	if ((Number(value) > max)) {
		return OmValidationMessageHelper.getLoanTermMaxLimit(
			max,
			pronoun 
		);
	}
	if(ageTerm > termExpiryMonth ||  min > maxMonthCovered){
		return OmValidationMessageHelper.getLoanTermMinMaxLimit(
			pronoun
		);
	}
}
