import { FORM_STATUS, FIELD_STATUS } from "../status";
import { COVER_CATEGORIES, COVER_CATEGORY_MEMBERS, EFP_POPUP_MODALS } from "../../actions/easiplusFuneralPlan/types";

import {
    SET_ADD_COVER_MEMBER_LIVES_COVERED,
    SET_EDIT_COVER_MEMBER_LIVES_COVERED,

    TITLE_SET_VALID,
    TITLE_SET_CLEAR,
    TITLE_SET_VALUE,
    FIRST_NAME_SET_CLEAR,
    FIRST_NAME_SET_VALID,
    FIRST_NAME_SET_VALUE,
    SURNAME_SET_CLEAR,
    SURNAME_SET_VALID,
    SURNAME_SET_VALUE,
    DATE_OF_BIRTH_SET_CLEAR,
    DATE_OF_BIRTH_SET_VALID,
    DATE_OF_BIRTH_SET_VALUE,
    AGE_SET_CLEAR,
    AGE_SET_VALID,
    AGE_SET_VALUE,
    COVER_AMOUNT_SET_CLEAR,
    COVER_AMOUNT_SET_VALID,
    COVER_AMOUNT_SET_VALUE,
    MONTHLY_PREMIUM_SET_VALUE,
    MONTHLY_PREMIUM_SET_VALID,
    MONTHLY_PREMIUM_SET_CLEAR,

    ADD_NEW_COVER_MEMBER_LIVES_COVERED,
    SYNC_COVER_AMOUNTS_LIVES_COVERED,
    DELETE_COVER_MEMBER_LIVES_COVERED,
    SAVE_COVER_MEMBER_LIVES_COVERED,
    CANCEL_COVER_MEMBER_LIVES_COVERED,
    FORM_VALID_TRANSITION_LIVES_COVERED,
    FORM_INVALID_TRANSITION_LIVES_COVERED,
    FORM_COMPLETE_TRANSITION_LIVES_COVERED,
    FORM_INCOMPLETE_TRANSITION_LIVES_COVERED,
    UPDATE_COVER_MEMBERS_LIVES_COVERED,
    UPDATE_REFERENCE_COVER_MEMBERS_LIVES_COVERED,
    UPDATE_REFERENCE_COVER_CATEGORIES_LIVES_COVERED,
    UPDATE_MYSELF_LIVES_COVERED,
    UPDATE_MYSELF_PREMIUM_LIVES_COVERED,
    MODAL_SET_VALUE,
    MODAL_CLEAR_VALUE,
    RESET_STATE_TRANSITION_LIVES_COVERED,
    SET_VIEW_MORE_LIVES_COVERED,
    REMOVE_COVER_MEMBER_SET_VALUE
} from "../../actions/easiplusFuneralPlan/livesCovered";

export const coverMemberDirectFamily = () => ({
    title: {value: null, error: null, status: null},
    firstName: {displayValue: null, value: null, error: null, status: null},
    surname: {displayValue: null, value: null, error: null, status: null},
    dateOfBirth: {value: null, error: null, status: null},
    age: {value: null, error: null, status: null},
    coverAmount: {value: 30000, error: null, status: FIELD_STATUS.VALID},
    monthlyPremium: {value: 0, error: null, status: FIELD_STATUS.VALID}
});

export const coverMemberParentsAndInLaws = () => {
    return {
        ...coverMemberDirectFamily(), 
        coverAmount: {value: 20000, error: null, status: FIELD_STATUS.VALID},
    }
}

export const coverMemberExtendedFamily = () => {
    return {
        ...coverMemberDirectFamily(), 
        coverAmount: {value: 15000, error: null, status: FIELD_STATUS.VALID},
    }
}

export const initLivesCovered = {
    formComplete: FORM_STATUS.INVALID,
    formStatus: FORM_STATUS.INVALID,
    addCoverMember: null,
    editCoverMember: null,
    removeCoverMember: null,

    maxChildrenAdded: false,
    popupModal: null,
    viewMoreCategories: [],

    referenceCoverCategoryMembers: {},
    referenceCoverCategories: {},

    form: coverMemberDirectFamily(),
    coverMembers: {},
};

export default (state = initLivesCovered, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    const trimField = (value) => value ? value.trim() : value ;

    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_LIVES_COVERED:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_LIVES_COVERED:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Form complete
        case FORM_COMPLETE_TRANSITION_LIVES_COVERED:
            return { ...state, formComplete: FORM_STATUS.VALID };
        case FORM_INCOMPLETE_TRANSITION_LIVES_COVERED:
            return { ...state, formComplete: FORM_STATUS.INVALID };

        case MODAL_SET_VALUE: {
            return { ...state, popupModal: action.value };
        }
        case MODAL_CLEAR_VALUE: {
            return { ...state, popupModal: null };
        }

        // Add cover member
        case SET_ADD_COVER_MEMBER_LIVES_COVERED:
            if (action.member.startsWith('child') && state.maxChildrenAdded) {
                return {
                    ...state,
                    popupModal: EFP_POPUP_MODALS.MAX_CHILDREN
                };
            }

            return {
                ...state,
                addCoverMember: action.member,
                form: state.coverMembers[action.member]
            };

        // Edit cover member
        case SET_EDIT_COVER_MEMBER_LIVES_COVERED:
            return {
                ...state,
                editCoverMember: action.member,
                form: state.coverMembers[action.member]
            }

        case TITLE_SET_VALUE:
            return updateFormField('title', field => ({
                ...field,
                value: action.value,
            }));
        case TITLE_SET_VALID:
            return updateFormField('title', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case TITLE_SET_CLEAR:
            return updateFormField('title', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', field => ({
                ...field,
                value: trimField(action.value),
                displayValue: action.value,
            }));
        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case FIRST_NAME_SET_CLEAR:
            return updateFormField('firstName', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case SURNAME_SET_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: trimField(action.value),
                displayValue: action.value,
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SURNAME_SET_CLEAR:
            return updateFormField('surname', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case DATE_OF_BIRTH_SET_VALUE:
            return updateFormField('dateOfBirth', field => ({
                ...field,
                value: action.value,
            }));
        case DATE_OF_BIRTH_SET_VALID:
            return updateFormField('dateOfBirth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DATE_OF_BIRTH_SET_CLEAR:
            return updateFormField('dateOfBirth', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case AGE_SET_CLEAR:
            return updateFormField('age', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case COVER_AMOUNT_SET_VALUE:
            return updateFormField('coverAmount', field => ({
                ...field,
                value: action.value,
            }));
        case COVER_AMOUNT_SET_VALID:
            return updateFormField('coverAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COVER_AMOUNT_SET_CLEAR:
            return updateFormField('coverAmount', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case MONTHLY_PREMIUM_SET_VALUE:
            return updateFormField('monthlyPremium', field => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_PREMIUM_SET_VALID:
            return updateFormField('monthlyPremium', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MONTHLY_PREMIUM_SET_CLEAR:
            return updateFormField('monthlyPremium', field => ({
                ...field,
                value: null,
                error: null,
                status: null,
            }));

        case UPDATE_MYSELF_LIVES_COVERED:
            if (COVER_CATEGORY_MEMBERS.MYSELF.code in state.coverMembers) {
                return {
                    ...state,
                    coverMembers: {
                        ...state.coverMembers,
                        myself: {
                            ...state.coverMembers.myself,
                            title: action.value.title,
                            firstName: action.value.name,
                            surname: action.value.surname,
                            dateOfBirth: action.value.dateOfBirth,
                            age: action.value.age
                        }
                    }
                };
            } else {
                return { ...state };
            }

        case UPDATE_MYSELF_PREMIUM_LIVES_COVERED:
            if (COVER_CATEGORY_MEMBERS.MYSELF.code in state.coverMembers) {
                return {
                    ...state,
                    coverMembers: {
                        ...state.coverMembers,
                        myself: {
                            ...state.coverMembers.myself,
                            monthlyPremium: {
                                ...state.coverMembers.myself.monthlyPremium,
                                value: action.value
                            },
                        }
                    }
                };
            } else {
                return { ...state };
            }

        case DELETE_COVER_MEMBER_LIVES_COVERED:
            const removeMember = state.removeCoverMember;
            if (removeMember.startsWith('child')) {
                const keys = Object.keys(state.coverMembers);
                const childKeys = keys.filter(key => key.startsWith('child')).filter(key => removeMember != key);
                const childData = childKeys.map(childKey => state.coverMembers[childKey]);
                const reducedChildArray = childData.filter(child => child.firstName.value != null);

                const currentCoverMembers = state.coverMembers;
                delete currentCoverMembers[childKeys[childKeys.length - 1]];
                let newCoverMembers = { ...currentCoverMembers };

                for (let i = 0, j = 1; i < childData.length; i++, j++) {
                    const keyString = 'child_' + j;
                    newCoverMembers[keyString] = coverMemberDirectFamily();
                }

                for (let i = 0, j = 1; i < reducedChildArray.length; i++, j++) {
                    const keyString = 'child_' + j;
                    newCoverMembers[keyString] = reducedChildArray[i];
                }

                return {
                    ...state,
                    maxChildrenAdded: false,
                    coverMembers: newCoverMembers
                };
            }

            const referenceKeys = Object.keys(state.referenceCoverCategoryMembers);
            let coverMemberObject;
            referenceKeys.map((key) => {
                if (removeMember === state.referenceCoverCategoryMembers[key].code) {
                    if (state.referenceCoverCategoryMembers[key].category === COVER_CATEGORIES.DIRECT_FAMILY.key) {
                        coverMemberObject = coverMemberDirectFamily();
                    } else if (state.referenceCoverCategoryMembers[key].category === COVER_CATEGORIES.EXTENDED_FAMILY.key) {
                        coverMemberObject = coverMemberExtendedFamily();
                    } else {
                        coverMemberObject = coverMemberParentsAndInLaws();
                    }
                }
            })

            return {
                ...state,
                coverMembers: {
                    ...state.coverMembers,
                    [removeMember]: coverMemberObject
                }
            };

        case CANCEL_COVER_MEMBER_LIVES_COVERED:
            return { ...state, addCoverMember: null, editCoverMember: null, form: coverMemberDirectFamily()};

        case SAVE_COVER_MEMBER_LIVES_COVERED:
            return {
                ...state,
                form: coverMemberDirectFamily(),
                formStatus: FORM_STATUS.INVALID,
                editCoverMember: null,
                coverMembers: {
                    ...state.coverMembers,
                    [state.editCoverMember]: state.form
                }
            };

        case SYNC_COVER_AMOUNTS_LIVES_COVERED:
            const memberKeys = Object.keys(state.coverMembers);
            const childKeys = memberKeys.filter(key => key.startsWith('child'));
            const updatedCoverAmount = state.coverMembers[action.member].coverAmount.value;
            const updatedPremium = state.coverMembers[action.member].monthlyPremium.value;

            const childCoverMembers = {};
            for (let i = 0, j = 1; i < childKeys.length; i++, j++) {
                childCoverMembers[childKeys[i]] = {
                    ...state.coverMembers[childKeys[i]],
                    coverAmount: {
                        ...state.coverMembers[childKeys[i]].coverAmount,
                        value: updatedCoverAmount
                    },
                    monthlyPremium: {
                        ...state.coverMembers[childKeys[i]].coverAmount,
                        value: updatedPremium
                    },
                };
            }

            return {
                ...state,
                coverMembers: {
                    ...state.coverMembers,
                    ...childCoverMembers,
                    myself: {
                        ...state.coverMembers.myself,
                        coverAmount: {
                            ...state.coverMembers.myself.coverAmount,
                            value: updatedCoverAmount
                        },
                        monthlyPremium: {
                            ...state.coverMembers.myself.monthlyPremium,
                            value: updatedPremium
                        },
                    },
                    spouse: {
                        ...state.coverMembers.spouse,
                        coverAmount: {
                            ...state.coverMembers.spouse.coverAmount,
                            value: updatedCoverAmount
                        },
                        monthlyPremium: {
                            ...state.coverMembers.spouse.monthlyPremium,
                            value: updatedPremium
                        },
                    }
                }
            };

        case ADD_NEW_COVER_MEMBER_LIVES_COVERED: {
            if (state.addCoverMember.startsWith('child')) {
                const nextChildNumber = Number(state.addCoverMember.split('_')[1]) + 1;
                const nextChildCode = 'child_' + nextChildNumber;
                const nextChildKey = 'CHILD_' + nextChildNumber;
                const referenceChild = {
                    ...COVER_CATEGORY_MEMBERS.CHILD_1,
                    key: nextChildKey,
                    code: nextChildCode
                };

                return {
                    ...state,
                    formStatus: FORM_STATUS.INVALID,
                    addCoverMember: null,
                    maxChildrenAdded: nextChildNumber === 8 ? true : false,
                    form: coverMemberDirectFamily(),
                    referenceCoverCategoryMembers: {
                        ...state.referenceCoverCategoryMembers,
                        [nextChildKey]: referenceChild
                    },
                    coverMembers: {
                        ...state.coverMembers,
                        [state.addCoverMember]: state.form,
                        [nextChildCode]: coverMemberDirectFamily()
                    }
                };
            }

            const referenceKeys = Object.keys(state.referenceCoverCategoryMembers);
            let coverMemberObject;
            referenceKeys.map((key) => {
                if (state.addCoverMember === state.referenceCoverCategoryMembers[key].code) {
                    if (state.referenceCoverCategoryMembers[key].category === COVER_CATEGORIES.DIRECT_FAMILY.key) {
                        coverMemberObject = coverMemberDirectFamily();
                    } else if (state.referenceCoverCategoryMembers[key].category === COVER_CATEGORIES.EXTENDED_FAMILY.key) {
                        coverMemberObject = coverMemberExtendedFamily();
                    } else {
                        coverMemberObject = coverMemberParentsAndInLaws();
                    }
                }
            })

            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
                addCoverMember: null,
                form: coverMemberObject,
                coverMembers: {
                    ...state.coverMembers,
                    [state.addCoverMember]: state.form
                }
            };
        }

        case UPDATE_COVER_MEMBERS_LIVES_COVERED:
            return { ...state, coverMembers: action.value };

        case UPDATE_REFERENCE_COVER_MEMBERS_LIVES_COVERED:
            return { ...state, referenceCoverCategoryMembers: action.value };

        case UPDATE_REFERENCE_COVER_CATEGORIES_LIVES_COVERED:
            return { ...state, referenceCoverCategories: action.value };

        case RESET_STATE_TRANSITION_LIVES_COVERED:
            return { ...initLivesCovered };

        case SET_VIEW_MORE_LIVES_COVERED:
            let updatedCategories = state.viewMoreCategories;
            if (state.viewMoreCategories.includes(action.category)) {
                updatedCategories = state.viewMoreCategories.filter(categories => categories !== action.category);
            } else {
                updatedCategories.push(action.category);
            }
            return { ...state, viewMoreCategories: updatedCategories };

        case REMOVE_COVER_MEMBER_SET_VALUE:
            return { ...state, removeCoverMember: action.value };

        default:
            return state;
    }
};


