import {BENEFICIARIES_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/beneficiaries";
import {beneficiariesValidation} from "./beneficiaries";
import {INVESTMENT_SETUP_INITIAL_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/investmentSetupInitial";
import {investmentSetupInitialValidation} from "./investmentSetupInitial";
import {PERSONAL_DETAILS_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/personalDetails";
import {personalDetailsValidation} from "./personalDetails";
import {AUXILIARY_DETAILS_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/auxiliaryDetails";
import {auxiliaryDetailsValidation} from "./auxiliaryDetails";
import {BENEFICIARIES_SPLIT_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/beneficiariesSplit";
import {beneficiariesSplitValidation} from "./beneficiariesSplit";
import {
    PAYMENT_DETAILS_SET_VALUE,
    PAYMENT_AUTHORIZATION_INVALID_TRANSITION_PAYMENT_DETAILS
} from "../../../actions/taxFreeSavingsAccount/paymentDetails";
import {paymentDetailsValidation} from "./paymentDetails";
import {INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/investmentSetupContribution";
import {investmentSetupContributionValidation} from "./investmentSetupContribution";
import {SELECT_A_FUND_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/selectAFund";
import {selectAFundValidation} from "./selectAFund";
import {ADVANCED_FUND_PICKER_SET_VALUE, ADVANCED_FUND_PICKER_TOGGLE} from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";
import {advancedFundPickerValidation} from "./advancedFundPicker";
import {FUND_SPLIT_SET_VALUE} from "../../../actions/taxFreeSavingsAccount/fundSplit";
import {fundSplitValidation} from "./fundSplit";
import {REVIEW_SET_VALUE, TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW} from "../../../actions/taxFreeSavingsAccount/review";
import {reviewValidation} from "./review";

export const taxFreeSavingsAccountValidation = (store, next, action) => {
    if (action.type.startsWith(INVESTMENT_SETUP_INITIAL_SET_VALUE)) {
        return investmentSetupInitialValidation(store, next, action);
    }

    if (action.type.startsWith(INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE)) {
        return investmentSetupContributionValidation(store, next, action);
    }

    if (action.type.startsWith(SELECT_A_FUND_SET_VALUE)) {
        return selectAFundValidation(store, next, action);
    }

    if (action.type.startsWith(ADVANCED_FUND_PICKER_SET_VALUE) ||
        action.type.startsWith(ADVANCED_FUND_PICKER_TOGGLE)) {
        return advancedFundPickerValidation(store, next, action);
    }

    if (action.type.startsWith(FUND_SPLIT_SET_VALUE)) {
        return fundSplitValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE)) {
        return personalDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(AUXILIARY_DETAILS_SET_VALUE)) {
        return auxiliaryDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(BENEFICIARIES_SET_VALUE)) {
        return beneficiariesValidation(store, next, action);
    }

    if (action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE)) {
        return beneficiariesSplitValidation(store, next, action);
    }

    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE) ||
        action.type === PAYMENT_AUTHORIZATION_INVALID_TRANSITION_PAYMENT_DETAILS) {
        return paymentDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(REVIEW_SET_VALUE) ||
        action.type === TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW) {
        return reviewValidation(store, next, action);
    }

    return null;
};
