import { createSelector } from 'reselect';
import { getUgMotorInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDocuments = createSelector(getUgMotorInsuranceState, (state) => state.documents);
export const getDocumentsForm = createSelector(getDocuments, (documents) => documents.form);

export const getDropOffDetails = createSelector(getDocuments, (documents) => documents.dropOffDetails);

export const getDocumentsFormValid = createSelector(
    getDocuments,
    (documents) => documents.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getDocuments,
    (documents) => documents.formStatus === API_CALL_STATUS.PENDING
);

const makeGetFieldForm = (fieldId) => createSelector(getDocumentsForm, (form) => form[fieldId]);

export const getIdUploadValue = makeGetFieldForm('idUpload');
export const getLicenseUploadValue = makeGetFieldForm('licenseUpload');
export const getLogbookUploadValue = makeGetFieldForm('logbookUpload');
export const getValuationReportUploadValue = makeGetFieldForm('valuationReportUpload');
export const getInspectionPhotoValue = makeGetFieldForm('inspectionPhoto');
