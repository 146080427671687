import { setValidationResults } from '../../../actions/digitalOnboarding/affordability';
import { GROSS_INCOME, NET_INCOME, CAPITAL_NEEDED, INFORMATION_CONFIRMATION } from '../../../actions/digitalOnboarding/types';
import { getGrossIncome, getNetIncome, hasApplicantProvidedANetIncome } from '../../../selectors/digitalOnboarding/affordability';

const runValidations = (state, action) => {

    const validateNetIncome = value => {
        let errorMessage = 'Please enter an amount greater than zero.';
        let isValid = false;
        if (value > 0) {
            isValid = getGrossIncome(state) > value;
            errorMessage = isValid ? '' : 'Please enter an amount less than Gross Income.';
        }
        return [{ key: NET_INCOME, isValid, errorMessage }];
    }

    const validateGrossIncome = value => {
        const isValid = value > 0;
        const errorMessage = isValid ? '' : 'Please enter an amount greater than zero.';
        const result = [{ key: GROSS_INCOME, isValid, errorMessage }];
        // If Gross income changes, need to validate net income as well
        if (hasApplicantProvidedANetIncome(state)) {
            result.push(...validateNetIncome(getNetIncome(state)));
        }
        return result;
    }

    const validateCapitalRequired = value => {
        const isValid = value > 0;
        const errorMessage = isValid ? '' : 'Please enter an amount greater than zero.';
        return[{ key: CAPITAL_NEEDED, isValid, errorMessage }];
    };

    const validateInformationCorrectConfirmation = value => {
        const isValid = value === 1;
        const errorMessage = isValid ? '' : 'Please ensure that the information provided is correct before proceeding to the next page.';
        return [{ key: INFORMATION_CONFIRMATION, isValid, errorMessage }];
    }

    const noValidationRequired = key => {
        return [{ key, isValid: true, errorMessage: '' }];
    }

    switch (action.key) {
        case NET_INCOME:
            return validateNetIncome(action.value);
        case GROSS_INCOME:
            return validateGrossIncome(action.value)
        case CAPITAL_NEEDED:
            return validateCapitalRequired(action.value);
        case INFORMATION_CONFIRMATION:
            return validateInformationCorrectConfirmation(action.value);
    }

    return noValidationRequired(action.key);
}

export const affordabilityValidation = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const validations = runValidations(state, action);
    store.dispatch(setValidationResults(validations));

    return result;
}