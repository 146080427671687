import { validateIdNumber,validateIdNumberAgainstAge } from '@om/validation';
import {
    ID_SET_VALUE,
    setIDValid,
} from "../../../actions/customerVerificationService/idVerification";
import {PARENT_APPS} from '../../../actions/customerVerificationService/types';
import {getParentApp} from '../../../selectors/customerVerificationService/routing';
import {getAge as raGetAge} from '../../../selectors/raOptimal/investmentSetup';

let prevValidationAction = null;
export const idVerificationValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ID_SET_VALUE:
                error = validateIdNumber(action.value, { required: true, subject: 'ID number', checkAge: false });
                if(error) return setIDValid(error);

                const parentApp = getParentApp(store.getState());
                // If this RA optimal then get the RA age for validation
                // In future we will add a switch for other apps as well
                if(parentApp == PARENT_APPS[0]){
                    const age = raGetAge(store.getState());
                    if(age && age.value) {
                        error = validateIdNumberAgainstAge(action.value, age.value);
                        return setIDValid(error);
                    }
                }
           
            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
}
