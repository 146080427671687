import {validationIsEqual} from "../status";
import {
    validateFirstName,
    validateSurname,
    validateDateOfBirth,
    validateSingleSelection,
    validateAge,
} from  "@om/validation";
import {
    TITLE_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    DATE_OF_BIRTH_SET_VALUE,
    COVER_AMOUNT_SET_VALUE,
    AGE_SET_VALUE,
    setTitleValid,
    setFirstNameValid,
    setSurnameValid,
    setDateOfBirthValid,
    setCoverAmountValid,
    setAgeValid, 
} from "../../../actions/easiplusFuneralPlan/livesCovered";

let prevValidationAction = null;
export const efpLivesCoveredValidation = (store, next, action) => {
    const validationAction = (() => {
        const trimValue = (value) => {
            return value ? value.trim() : value;
        };

        let error;
        switch (action.type) {
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, 'title');
                return setTitleValid(error);
            case FIRST_NAME_SET_VALUE:
                error = validateFirstName(trimValue(action.value));
                return setFirstNameValid(error);
            case SURNAME_SET_VALUE:
                error = validateSurname(trimValue(action.value));
                return setSurnameValid(error);
            case DATE_OF_BIRTH_SET_VALUE:
                error = validateDateOfBirth(action.value);
                return setDateOfBirthValid(error);
            case COVER_AMOUNT_SET_VALUE:
                return setCoverAmountValid();
            case AGE_SET_VALUE:
                if (action.child) error = validateAge(action.value, { min: 0, max: 21 });
                else if (action.myself) error = validateAge(action.value, { min: 18, max: 65 });
                else error = validateAge(action.value, { min: 1, max: 84 });
                return setAgeValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
