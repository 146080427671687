import { initRouting } from "./routing";
import { initPersonalDetails } from "./personalDetails";
import { initReferenceData } from "./referenceData";
import { initPaymentDetails } from "./paymentDetails";
import { initUploadDocument } from "./uploadDocuments";
import { initConfirmDetails } from "./confirmDetails";
import { initScrolling } from "./scrolling";
import { initThankYou } from "./thankYouDetails";
export default () => {
  return {
    routing: initRouting,
    personalDetails: initPersonalDetails,
    referenceData: initReferenceData,
    paymentDetails: initPaymentDetails,
    uploadDocuments: initUploadDocument,
    confirmDetails: initConfirmDetails,
    scrolling: initScrolling,
    thankYouDetails:initThankYou,
  };
};
