import { createSelector } from 'reselect';
import { getSavingsCalculators } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getTheFutureValueOfYourSavingsAssumptionsModel = createSelector(
	getSavingsCalculators,
	(savingsCalculators) => savingsCalculators.theFutureValueOfYourSavingsAssumptionsModel
);

export const getTheFutureValueOfYourSavingsAssumptionsModelForm = createSelector(
    getTheFutureValueOfYourSavingsAssumptionsModel,
    howMuchYouNeedToSaveAssumptionsModel => howMuchYouNeedToSaveAssumptionsModel.form
);

export const getTheFutureValueOfYourSavingsAssumptionsModelFormValid = createSelector(
	getTheFutureValueOfYourSavingsAssumptionsModel,
	(howMuchYouNeedToSaveAssumptionsModel) => howMuchYouNeedToSaveAssumptionsModel.formStatus === FORM_STATUS.VALID
);


const makeGetField = (fieldId) => createSelector(
    getTheFutureValueOfYourSavingsAssumptionsModelForm,
    (calculatorAssumptionsModel) => calculatorAssumptionsModel[fieldId]
);

export const getExpectedAnnualGrowth = makeGetField('expectedAnnualGrowth');
export const getAnnualIncreaseInContribution = makeGetField('annualIncreaseInContribution');