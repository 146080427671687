import {combineReducers} from "redux";
import branchLocator from "./branchLocator";
import articleCardFilter from './articleCardFilter';
import businessProducts from './businessProducts';
import save2gether from "./save2gether";
import search from './search';
import sharePrice from './sharePrice'
import signupAndDownloadForm from "./signupAndDownloadForm";
import priceGraph from "./priceGraph";
import dividendCalculator from "./dividendCalculator";
import callMeBackForm from "./callMeBackForm";
import chatBot from "./chatBot";
import peopleCardModal from "./peopleCardModal";
import unclaimedBenefits from "./unclaimedBenefits";
import qualifyingCheckCallMeBack from "./qualifyingCheckCallMeBack";
import findAnAdvisor from "./findAnAdvisor";
import funeralClaim from './funeralClaim';
import funeralClaimV2 from './funeralClaimV2';
import asisaUnclaimedBenefits from './asisaUnclaimedBenefits';
import onTheMoney from './onTheMoney';

export const publicWeb = combineReducers({
    branchLocator,
    signupAndDownloadForm,
    priceGraph,
    articleCardFilter,
    businessProducts,
    save2gether,
    search,
    sharePrice,
    dividendCalculator,
    callMeBackForm,
    chatBot,
    peopleCardModal,
    unclaimedBenefits,
    qualifyingCheckCallMeBack,
    findAnAdvisor,
    funeralClaim,
    funeralClaimV2,
    asisaUnclaimedBenefits,
    onTheMoney,
});
