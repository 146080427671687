import { HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE} from "../../../actions/savingsCalculator/howMuchYouNeedToSaveCalculator"
import {howMuchYouNeedToSaveCalculatorFieldTracking} from "./howMuchYouNeedToSaveCalculator"

import { FUTURE_VALUE_OF_SAVINGS_KEY} from "../../../actions/savingsCalculator/theFutureValueOfYourSavings"
import {theFutureValueOfYourSavingsFieldTracking} from "./theFutureValueOfYourSavings"

import { REACH_SAVINGS_GOAL_SET_VALUE} from "../../../actions/savingsCalculator/whenWillYouReachYourSavingsGoal"
import {whenWillYouReachYourSavingsGoalsFieldTracking} from "./whenWillYouReachYourSavingsGoal"

export const savingsCalculatorTracking = (store, next, action) => {
    if (action.type.startsWith(HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE))
    return howMuchYouNeedToSaveCalculatorFieldTracking(store, next, action);

    if (action.type.startsWith(FUTURE_VALUE_OF_SAVINGS_KEY))
    return theFutureValueOfYourSavingsFieldTracking(store, next, action);

    if (action.type.startsWith(REACH_SAVINGS_GOAL_SET_VALUE))
    return whenWillYouReachYourSavingsGoalsFieldTracking(store, next, action);

    return null;
};
