import axios from 'axios';

import {
    apiTransitionCustomerSatisfactionSurvey,
} from '../../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm';

import { getIsApiPending,
        getCustomerSatisfactionRequestObject
 } from '../../../selectors/customerSatisfactionSurvey/customerSatisfactionSubmitForm';

export const customerSatisfactionSurveySubmitFormService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionCustomerSatisfactionSurvey.pending());

            try {
                const response = await sendCustomerFeedback(state);
                if (response.status === 200) {
                    store.dispatch(apiTransitionCustomerSatisfactionSurvey.success());
                } else {
                    store.dispatch(apiTransitionCustomerSatisfactionSurvey.failure());
                }
            } catch (e) {
                store.dispatch(apiTransitionCustomerSatisfactionSurvey.failure());
            }
        }
    })();
    return result;
}

export const sendCustomerFeedback = async(state) => {
    const requestObject = getCustomerSatisfactionRequestObject(state);
    const url = '/om-api/customer-satisfaction-survey/';
    const result = await axios.post(url, requestObject)
    return result;
}
