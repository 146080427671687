import { createSelector } from 'reselect';
import { getDigitalOnboarding } from './index';

export const getApplicationDetails = createSelector(
    getDigitalOnboarding,
    digitalOnboarding => digitalOnboarding.applicationNow
);

export const getApplicationNumber = createSelector(
    getApplicationDetails,
    appDetails => appDetails.applicationNumber
);

export const getApplicationOutcome = createSelector(
    getApplicationDetails,
    appDetails => appDetails.applicationOutcome
);


export const getApplicationUtm = createSelector(
    getApplicationDetails,
    appDetails => appDetails.utmTags
);

