import {
    DELIGHT_ANIMATION_COUNTDOWN_TIMER_SET,
    FINANCIAL_EDUCATION_JOURNEY_CREATE,
    JOURNEY_EXPANDED_JOURNEY_ITEM_SET,
    JOURNEY_ITEM_UPDATE,
    UP_NEXT_COUNTDOWN_TIMER_SET,
    SHOW_UP_NEXT_COUNTDOWN_TIME_TOGGLE,
} from "../../actions/financialEducation/journey";
import { TOGGLE_STATUS } from "../status";

const getNewJourneyItem = (journeyItemId) => ({
    itemId: journeyItemId,
    timeToComplete: 0,
    isItemCompleted: false,
});

const getNewJourney = (journeyId, journeyItems) => ({
    journeyId: journeyId,
    journeyItems: journeyItems,
    expandedJourneyItemId: null,
    delightAnimationCountdownTime: 4,
    upNextRemainingCountdownTime: 15,
    showUpNextCountdownTimer: TOGGLE_STATUS.UNTOUCHED,
    isJourneyCompleted: false
});

export const initialFinancialEducationState = {
    journeys: [],
}

export default (state = initialFinancialEducationState, action) => {
    let newJourneysArray;
    let journeyIndex;
    switch (action.type) {
        case FINANCIAL_EDUCATION_JOURNEY_CREATE:
            const journeyExists = state.journeys.length > 0 && state.journeys.find(journey => journey.journeyId === action.journeyId);

            if (!journeyExists) {
                const newJourneyItems = action.journeyItems && action.journeyItems.map(journeyItem => {
                    const newJourneyItem = getNewJourneyItem(journeyItem.id);
                    newJourneyItem.timeToComplete = journeyItem.timeToComplete;
                    return newJourneyItem;
                });

                return {
                    ...state,
                    journeys: [...state.journeys, getNewJourney(action.journeyId, newJourneyItems)]
                }
            }
            return state;

        case JOURNEY_ITEM_UPDATE:
            journeyIndex = state.journeys.findIndex(journey => journey.journeyId === action.journeyId);
            newJourneysArray = [...state.journeys];

            if (journeyIndex === -1) {
                return state;
            }

            const journeyItemIndex = state.journeys[journeyIndex].journeyItems.findIndex(journeyItem => journeyItem.itemId === action.itemId);
            const newJourneyItemsArray = [...state.journeys[journeyIndex].journeyItems];

            if (journeyItemIndex === -1) {
                return state;
            }

            newJourneyItemsArray[journeyItemIndex].isItemCompleted = true;
            newJourneysArray[journeyIndex].journeyItems = newJourneyItemsArray;

            return {
                ...state,
                journeys: JSON.parse(JSON.stringify(newJourneysArray))
            }

        case JOURNEY_EXPANDED_JOURNEY_ITEM_SET:
            journeyIndex = state.journeys.findIndex(journey => journey.journeyId === action.journeyId);
            newJourneysArray = [...state.journeys];

            if (journeyIndex === -1) {
                return state;
            }

            newJourneysArray[journeyIndex].expandedJourneyItemId = action.itemId

            return {
                ...state,
                journeys: JSON.parse(JSON.stringify(newJourneysArray))
            }

        case UP_NEXT_COUNTDOWN_TIMER_SET:
            journeyIndex = state.journeys.findIndex(journey => journey.journeyId === action.journeyId);
            newJourneysArray = [...state.journeys];

            newJourneysArray[journeyIndex].upNextRemainingCountdownTime = action.value;

            return {
                ...state,
                journeys: JSON.parse(JSON.stringify(newJourneysArray))
            }

        case SHOW_UP_NEXT_COUNTDOWN_TIME_TOGGLE:
            journeyIndex = state.journeys.findIndex(journey => journey.journeyId === action.journeyId);
            newJourneysArray = [...state.journeys];

            newJourneysArray[journeyIndex].showUpNextCountdownTimer = newJourneysArray[journeyIndex].showUpNextCountdownTimer !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO;

            if (!newJourneysArray[journeyIndex].showUpNextCountdownTimer) {
                newJourneysArray[journeyIndex].upNextRemainingCountdownTime = 0
            }

            return {
                ...state,
                journeys: JSON.parse(JSON.stringify(newJourneysArray))
            }

        case DELIGHT_ANIMATION_COUNTDOWN_TIMER_SET:
            journeyIndex = state.journeys.findIndex(journey => journey.journeyId === action.journeyId);
            newJourneysArray = [...state.journeys];

            newJourneysArray[journeyIndex].delightAnimationCountdownTime = action.value;

            return {
                ...state,
                journeys: JSON.parse(JSON.stringify(newJourneysArray))
            }

        default:
            return state;
    }
}
