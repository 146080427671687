import axios from "axios";
import {
  asisaUnclaimedBenefitsApiTransitions,
  ASISA_UNCLAIMED_BENEFITS_FORM_SUBMIT,
} from "../../../actions/publicWeb/asisaUnclaimedBenefits";
import { isApiStatusPending, getSAIdNumber, getFirstname, getSurname, getGender, getDateOfBirth } from "../../../selectors/publicWeb/asisaUnclaimedBenefits";

export const collectData = async (store) => {
  const state = store.getState();

  if (!isApiStatusPending(state)) {
    store.dispatch(asisaUnclaimedBenefitsApiTransitions.pending());

    const idNumber = getSAIdNumber(state).value || null;
    const firstName = getFirstname(state).value || null;
    const lastName = getSurname(state).value || null;
    const gender = getGender(state).value || null;
    const dateOfBirth = getDateOfBirth(state).value?.split('/').reverse().join('-') || null;
    const initials = firstName?.trim().split(' ').reduce((acc, str) => acc + str[0], '') || null;

    const body = {
      idNumber,
      initials,
      firstName,
      lastName,
      gender,
      dateOfBirth
    };

    try {
      const response = await axios.post("/om-api/ubf-active-members/asisa", body);

      if (response.status === 200) {
        store.dispatch(asisaUnclaimedBenefitsApiTransitions.success(response.data));
      } else {
        store.dispatch(asisaUnclaimedBenefitsApiTransitions.failure());
      }
    } catch (error) {
      store.dispatch(asisaUnclaimedBenefitsApiTransitions.failure());
    }
  }
};
export const asisaUnclaimedBenefitsService = (store, next, action) => {
  const result = next(action);

  (async () => {
    if (action.type === ASISA_UNCLAIMED_BENEFITS_FORM_SUBMIT) {
      await collectData(store);
    }
  })();

  return result;
};
