import { routingRules } from "./routing";
import { DLP_ROUTING_SET_VALUE } from "../../../actions/digiLifeLoanProtection/routing";
import { UPLOAD_DOCUMENTS_SET_VALUE } from "../../../actions/digiLifeLoanProtection/uploadDocuments";
import { uploadDocumentsRules } from "./uploadDocuments";
import { PERSONAL_DETAILS_SET_VALID } from "../../../actions/digiLifeLoanProtection/personalDetails";
import { personalDetailsRules } from "./personalDetails";
import { PAYMENT_DETAILS_SET_VALUE } from "../../../actions/digiLifeLoanProtection/paymentDetails";
import { paymentDetailsRules } from "./paymentDetails";


export const digiLifeLoanProtectionRules = (store, next, action) => {
  //Personal Details
  if (action.type.startsWith(PERSONAL_DETAILS_SET_VALID) ){
        return personalDetailsRules(store, next, action);}
    // Upload Documents rules
    if (action.type.startsWith(UPLOAD_DOCUMENTS_SET_VALUE))
      return uploadDocumentsRules(store, next, action);
    // Payment detail rules
    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE))
      return paymentDetailsRules(store, next, action);
    // Trigger routing transitions
   if (action.type.startsWith(DLP_ROUTING_SET_VALUE))
        return routingRules(store, next, action);

  return null;
};
