import {
    HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALID,
    HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE
} from "../../../actions/savingsCalculator/howMuchYouNeedToSaveCalculator";
import {howMuchYouNeedToSaveCalculateFormRules} from "./howMuchYouNeedToSaveCalculator";
import {
    REACH_SAVINGS_GOAL_SET_VALID,
    REACH_SAVINGS_GOAL_SET_VALUE
} from "../../../actions/savingsCalculator/whenWillYouReachYourSavingsGoal";
import {whenWillYouReachYourSavingsGoalFormRules} from "./whenWillYouReachYourSavingsGoal";
import {
    FUTURE_VALUE_OF_SAVINGS_SET_VALID,
    FUTURE_VALUE_OF_SAVINGS_SET_VALUE
} from "../../../actions/savingsCalculator/theFutureValueOfYourSavings";
import {theFutureValueOfYourSavingsRules} from "./theFutureValueOfYourSavings";

export const savingsCalculatorRules = (store, next, action) => {
    // how Much You Need To Save Calculator

    if (action.type.startsWith(HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE) ||
        action.type.startsWith(HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALID))
        return howMuchYouNeedToSaveCalculateFormRules(store, next, action);

    if (action.type.startsWith(REACH_SAVINGS_GOAL_SET_VALUE) ||
        action.type.startsWith(REACH_SAVINGS_GOAL_SET_VALID))
        return whenWillYouReachYourSavingsGoalFormRules(store, next, action);

    if (action.type.startsWith(FUTURE_VALUE_OF_SAVINGS_SET_VALUE) ||
        action.type.startsWith(FUTURE_VALUE_OF_SAVINGS_SET_VALID))
        return theFutureValueOfYourSavingsRules(store, next, action);

    return null;
};
