import { getIsApiPending} from '../../../selectors/ugMotorInsurance/quote';
import { apiTransitionMotorInsuranceQuoteForm, calculateMotorInsurancePremiumSuccess } from '../../../actions/ugMotorInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';


export const thirdPartyPremiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionMotorInsuranceQuoteForm.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateMotorInsurancePremiumSuccess(resp.data));
                store.dispatch(apiTransitionMotorInsuranceQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionMotorInsuranceQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = () => {
    const premiumCalculationPayload = {
       months: "12"
    }

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-motor-insurance/third-party-premium-calculation/kenya';
        return await axios.post(url, premiumCalculationPayload(), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData?.accessToken
            },
        });
    } catch (e) {
        console.error(e);
        throw e
    }
};
