import axios from "axios";
import { setApplicationSubmissionSuccess, setApplicationSubmissionFailure } from "../../../actions/taxFreeSavingsAccount/review";
import { setRequestId } from "../../../actions/taxFreeSavingsAccount/sessionCamVariables";
import { getTfsaSubmission } from "../../../selectors/taxFreeSavingsAccount/submission";
import {
    navigateToThankYou
} from "../../../actions/taxFreeSavingsAccount/routing";
export const submitTfsaApplicationService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const payload = getTfsaSubmission(state);
  
    (async () => {
        const response = await submitApplication(payload);
        if (response && (response.status === 200) && response.data.isValid) {
            store.dispatch(setApplicationSubmissionSuccess(response.data && response.data.policyNumber));
            store.dispatch(navigateToThankYou());
        } else {
            store.dispatch(setApplicationSubmissionFailure());
        }
        if (response && 'data' in response && 'requestId' in response.data) {
            store.dispatch(setRequestId(response.data.requestId));
        }
    })();

    return result;
};

export const submitApplication = async payload => {
    try {
        return await axios.post(
            '/om-api/tax-free-savings-account/submit-application',
            payload,
            {headers: {'Content-Type': 'application/json'}}
        );
    } catch (error) {
        return null;
    }
};
