import { transitionDigiLifeTool } from '../../../actions/roaQuoteTools/digiLifeQuoteTool';
import {
	getDigiLifeQuoteToolForm,
	getSelectedForm,
} from '../../../selectors/roaQuoteTools/digiLifeQuoteTool';
import { FIELD_STATUS } from '../../../reducers/status';

export const digiLifeQuoteToolFormRules = (store, next, action) => {
	const result = next(action);

	const digiLifeQuoteToolForm = getDigiLifeQuoteToolForm(store.getState());
	const selectedForm = getSelectedForm(store.getState());
	const digiLifeQuoteToolValid = validatedigiLifeQuoteToolFormDetails(
		digiLifeQuoteToolForm,
		selectedForm
	);
	const formAction = digiLifeQuoteToolValid
		? transitionDigiLifeTool.formValid()
		: transitionDigiLifeTool.formInvalid();

	store.dispatch(formAction);

	return result;
};

const validatedigiLifeQuoteToolFormDetails = (formFields, selectedForm) => {
	const { gender, DOB, salary, education, loanType, loanAmount, loanTerm, payment} = formFields;
	if (selectedForm === 'form-one') {
		return (
			gender && gender.status === FIELD_STATUS.VALID &&
			DOB && DOB.status === FIELD_STATUS.VALID &&
			salary && salary.status === FIELD_STATUS.VALID &&
			education && education.status === FIELD_STATUS.VALID &&
			loanType && loanType.status === FIELD_STATUS.VALID &&
			loanAmount && loanAmount.status === FIELD_STATUS.VALID &&
			loanTerm && loanTerm.status === FIELD_STATUS.VALID &&
			payment && payment.status === FIELD_STATUS.VALID 
			//cashBack && cashBack.status === FIELD_STATUS.VALID
		);
	}
	return true;
};
