import axios from 'axios'; 

import {
    calculateElimikaPremiumData,
    apiTransitionElimikaQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/elimikaQuoteTool";

import {
    getElimikaQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/elimikaQuoteTool';

export const elimikaQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionElimikaQuoteTool.pending());

            try {
                const response = await calculate(state);
                const premiumAmount = response.data.face_amount.replace(/\.00/g, '')
                store.dispatch(calculateElimikaPremiumData(premiumAmount));
                store.dispatch(apiTransitionElimikaQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-three'));
            } catch (e) {
                store.dispatch(calculateElimikaPremiumData(null));
                store.dispatch(apiTransitionElimikaQuoteTool.failure());
            }
        }
    })();
    return result;
}
const getElimikaPayload = (formDetails) => {
    return {
        'date_of_birth': formDetails.dob.value || '',
        'child_date_of_birth': formDetails.childDateOfBirth.value || '',
        'target_premium_amount': formDetails.targetFeesPerYear.value || '',
        'target_premium_amount2': formDetails.targetSavingsPerYear.value || '',
        'premium_frequency': formDetails.premiumFrequencyValue.value || '',
        'life_cover': formDetails.lifeCoveredValue.value || '',
        'wop': 'Yes',
        'gender': formDetails.genderValue.value || '',
        'school_premium_frequency': formDetails.premiumFrequencyValue.value || '',
        'school_entry_age': formDetails.schoolStartYear.value || '',
        'education_period': formDetails.schoolYearValue.value || ''
    }
}
const calculate = async(state) => {
    const elimikaFormDetails = getElimikaQuoteToolForm(state)

    try{
        const url = '/om-api/roa-quote-tools/elimika-quote';
        const result = await axios.post(url, getElimikaPayload(elimikaFormDetails))
        return result;
    } catch (error) {
        return null;
    }
}
