import { combineReducers } from 'redux';
import routing from './routing';
import insuranceType from './insuranceType';
import homeDetails from './homeDetails';
import homeContents from './homeContents';
import motorDetails from './motorDetails';
import personalDetails from './personalDetails';
import bankingDetails from './bankingDetails';
import referenceData from './referenceData';
import uploadDocuments from './uploadDocuments';
import purchasePersonalDetails from './purchasePersonalDetails';
import purchaseAddionalHomeMotorDetails from './purchaseAddionalHomeMotorDetails';
export const allsureInsurance = combineReducers({
    routing,
    insuranceType,
    motorDetails,
    personalDetails,
    homeDetails,
    homeContents,
    bankingDetails,
    referenceData,
    uploadDocuments,
    purchasePersonalDetails,
    purchaseAddionalHomeMotorDetails
});
