import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from "../status";
import { ACCOUNT_VALIDATION_STATUS } from "../../actions/digiLifeLoanProtection/types";

import {
    FORM_VALID_TRANSITION_PAYMENT_DETAILS,
    FORM_INVALID_TRANSITION_PAYMENT_DETAILS,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    INVALID_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,

    BANK_NAME_SET_VALUE,
    BANK_NAME_SET_VALID,
    BRANCH_CODE_SET_VALID,
    BRANCH_CODE_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALID,
    ACCOUNT_TYPE_SET_VALUE,
    ACCOUNT_TYPE_SET_VALID,
    DEBIT_ORDER_DAY_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALID,
    PAYMENT_AUTHORIZATION_SET_VALID,

    ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS,
    RESET_STATE_TRANSITION_PAYMENT_DETAILS,
    PAYMENT_AUTHORIZATION_SET_VALUE,
    ACCOUNT_HOLDER_NAME_SET_VALUE,
    ACCOUNT_HOLDER_NAME_SET_VALID,

} from "../../actions/digiLifeLoanProtection/paymentDetails";

export const initPaymentDetails = {
    formStatus: FORM_STATUS.INVALID,
    accountValidationStatus: ACCOUNT_VALIDATION_STATUS.IDLE,
    includeAvs: false,
    includeCdv: false,
    form: {
        bankName: { value: null, error: null, status: null , displayValue: null},
        branchCode:{ value: null, error: null, status: null , displayValue: null},
        accountNumber: { value: null, error: null, status: null },
        accountHolder: { value: null, error: null, status: null },
        accountType: { value: null, error: null, status: null , displayValue: null},
        debitOrderDay: { value: null, error: null, status: null },
        paymentAuthorization: { value: TOGGLE_STATUS.NO, error: null, status: null }
    },
};

export default (state = initPaymentDetails, action) => {

    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_PAYMENT_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_PAYMENT_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS:
            return updateFormField('paymentAuthorization', field => ({
                ...field,
                value: state.form.paymentAuthorization.value !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO,
            }));

        case INVALID_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS:
            return updateFormField('paymentAuthorization', field => ({
                ...field,
                value: action.value,
            }));

        case ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS:
            return { ...state, accountValidationStatus: action.value }

        case PAYMENT_AUTHORIZATION_SET_VALID:
            return updateFormField('paymentAuthorization', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BANK_NAME_SET_VALUE:
            return updateFormField('bankName', field => ({
                ...field,
                value: action.value.id,
                displayValue: action.value.value
            }));
        case BANK_NAME_SET_VALID:
            return updateFormField('bankName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case BRANCH_CODE_SET_VALUE:
            return updateFormField('branchCode', field => ({
                ...field,
                value: action.value.id,
                displayValue: action.value.value
            }));
        case BRANCH_CODE_SET_VALID:
            return updateFormField('branchCode', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS:
            return { 
                ...state, 
                accountValidationStatus: ACCOUNT_VALIDATION_STATUS.IDLE,
                form: {
                    ...state.form,
                    paymentAuthorization: initPaymentDetails.form.paymentAuthorization
                }}
    
       

        // Account Number
        case ACCOUNT_NUMBER_SET_VALUE:
            return updateFormField('accountNumber', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_NUMBER_SET_VALID:
            return updateFormField('accountNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Account holder Name
        case ACCOUNT_HOLDER_NAME_SET_VALUE:
            return updateFormField('accountHolder', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_HOLDER_NAME_SET_VALID:
            return updateFormField('accountHolder', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Account type
        case ACCOUNT_TYPE_SET_VALUE:
            return updateFormField('accountType', field => ({
                ...field,
                value: action.value.id,
                displayValue: action.value.value
            }));
        case ACCOUNT_TYPE_SET_VALID:
            return updateFormField('accountType', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Debit order day
        case DEBIT_ORDER_DAY_SET_VALUE:
            return updateFormField('debitOrderDay', field => ({
                ...field,
                value: action.value,
            }));
        case DEBIT_ORDER_DAY_SET_VALID:
            return updateFormField('debitOrderDay', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        //  Payment authoriztion
        case PAYMENT_AUTHORIZATION_SET_VALUE:
            return updateFormField('paymentAuthorization', field => ({
                ...field,
                value: action.value,
            }));

        case RESET_STATE_TRANSITION_PAYMENT_DETAILS:
            return { ...initPaymentDetails };

        default:
            return state;
    }
};


