import { validateDateOfBirth, validateNumber} from  "@om/validation";
import {
    setChildDOBValid,
    CHILD_DOB_SET_VALUE,
    setSchoolStartYearValid,
    SCHOOL_START_YEAR_SET_VALUE,
    setDOBValid,
    DOB_SET_VALUE,
    setTargetFeesPerYearValid,
    TARGET_FEES_PER_YEAR_SET_VALUE,
    setSchoolYearValid,
    SCHOOL_YEAR_SET_VALUE,
    ACCUMALATION_PERIOD_SET_VALUE,
    setAccumalationPeriodValid,
    setTargetSavingsPerYearValid,
    TARGET_SAVINGS_PER_YEAR_SET_VALUE
} from "../../../actions/roaQuoteTools/elimikaQuoteTool";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const elimikaQuoteToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            
            case CHILD_DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 0, max: 18, pronoun: 'your child' });
                return setChildDOBValid(error);
            case SCHOOL_START_YEAR_SET_VALUE:
                error = validateNumber(action.value, { min: 5, max: 25, pronoun: 'school start year' });
                return setSchoolStartYearValid(error);
            case ACCUMALATION_PERIOD_SET_VALUE:
                error = validateNumber(action.value, { min: 5, max: 21, pronoun: 'accumalation period year' });
                return setAccumalationPeriodValid(error);
            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 18, max: 70 });
                return setDOBValid(error);
            case TARGET_FEES_PER_YEAR_SET_VALUE:
                error = validateNumber(action.value, {min: 2500, subject: 'fees per year'});
                return setTargetFeesPerYearValid(error);
            case SCHOOL_YEAR_SET_VALUE:
                error = validateNumber(action.value, {min: 3, max: 10, subject: 'education period'});
                return setSchoolYearValid(error);
            case TARGET_SAVINGS_PER_YEAR_SET_VALUE:
                error = validateNumber(action.value, {min: 2500, subject: 'savings per year'});
                return setTargetSavingsPerYearValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
