export function todayDateArray() {
    return dateToDateArray(new Date());
}

export function dateToDateArray(date) {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
}

export function dateStringToNumberArray(dateString) {
    return dateString.split('/').map((i) => {
        return parseInt(i, 10);
    });
}

export function toDateString(date) {
    const day = date[DATE_INDEX].toString().length < 2 ? '0'
    + date[DATE_INDEX].toString() : date[DATE_INDEX];
    const month = date[MONTH_INDEX].toString().length < 2 ? '0'
    + date[MONTH_INDEX].toString() : date[MONTH_INDEX];
    return `${day}/${month}/${date[YEAR_INDEX]}`;
}


export function dateArrayToDateObject(dateArray) {
    return new Date(`${dateArray[MONTH_INDEX]}/${dateArray[DATE_INDEX]}/${dateArray[YEAR_INDEX]}`);
}

export function dateStringToDateObject(dateString) {
    return dateArrayToDateObject(
        dateStringToNumberArray(dateString)
    );
}

export function millisToSeconds(millis) {
    return millis / 1000;
}

export function millisToMinutes(millis) {
    return millisToSeconds(millis) / 60;
}

export function millisToHours(millis) {
    return millisToMinutes(millis) / 60;
}

export function millisToDays(millis) {
    return millisToHours(millis) / 24;
}

export function millisToYears(millis) {
    return millisToDays(millis) / 365.25;
}

export function daysToMillis(days) {
    return days * 24 * 60 * 60 * 1000;
}

export function yearsDifference(dateArray1, dateArray2, calcAge) {
    const date1Millis = dateArrayToDateObject(dateArray1).getTime();
    const date2Millis = dateArrayToDateObject(dateArray2).getTime();
    return millisToYears(date1Millis - date2Millis);
}

export function dateDaysInFuture(dateArr, dateModifier) {
    const date = dateArr[DATE_INDEX];
    const month = dateArr[MONTH_INDEX];
    const year = dateArr[YEAR_INDEX];

    const millisModifier = daysToMillis(dateModifier);
    const dateObj = new Date(`${month}/${date}/${year}`);
    const modifiedDate = new Date(dateObj.getTime() + millisModifier);

    return dateToDateArray(modifiedDate);
}

export function dateDaysInPast(dateArr, dateModifier) {
    return dateDaysInFuture(dateArr, -dateModifier);
}

export function getDateOfBirthFromSaIdNumber(saIdNumber) {
    let year = saIdNumber.substr(0, 2);
    const month = saIdNumber.substr(2, 2);
    const day = saIdNumber.substr(4, 2);
    const currentYear = new Date().getFullYear().toString().substr(2);

    if (parseInt(currentYear, 10) >= parseInt(year, 10)) {
        year = '20' + year;
    } else {
        year = '19' + year;
    }
    return [day, month, year].join('/');
}

export function mapToDateParts(value) {
    const parts = value.split('/');

    try {
        if (parts[2] && parts[2].length === 4) {
            return {
                day: parseInt(parts[0], 10),
                month: parseInt(parts[1], 10),
                year: parseInt(parts[2], 10),
            };
        }
    } catch (e) {
        // Null will be returned on error
    }

    return null;
}

/** Index of date in date array */
export const DATE_INDEX = 0;
/** Index of month in date array */
export const MONTH_INDEX = 1;
/** Index of year in date array */
export const YEAR_INDEX = 2;
// Days per month in an array
export const DAYS_IN_MONTHS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
