import { createSelector } from 'reselect';
import { getDomesticInsurance } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDocuments = createSelector(getDomesticInsurance, (state) => state.documents);
export const getDocumentsForm = createSelector(getDocuments, (documents) => documents.form);

export const getDropOffDetails = createSelector(getDocuments, (documents) => documents.dropOffDetails);

export const getDocumentsFormValid = createSelector(getDocuments, (documents) => documents.formStatus === FORM_STATUS.VALID);

export const getIsApiPending = createSelector(getDocuments, (documents) => documents.formStatus === API_CALL_STATUS.PENDING);

const makeGetFieldForm = (fieldId) => createSelector(getDocumentsForm, (form) => form[fieldId]);


export const getIdUploadValue = makeGetFieldForm('idUpload');
export const getKraUploadValue = makeGetFieldForm('kraUpload');

