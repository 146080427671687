import { createSelector } from 'reselect';
import { getRoaTravelInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getTravelPlan = createSelector(
    getRoaTravelInsuranceState,
    state => state.travelPlan,
);

export const getTravelPlanForm = createSelector(
    getTravelPlan,
    travelPlan => travelPlan.form,
);

export const getTravelPlanFormValid = createSelector(
    getTravelPlan,
    travelPlan => travelPlan.formStatus === FORM_STATUS.VALID,
);

const makeGetFormField = fieldId =>
    createSelector(getTravelPlanForm, form => form[fieldId]);

export const getTravelPlanValue = makeGetFormField('travelPlan');
