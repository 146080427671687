import { createSelector } from 'reselect';
import { getDigitalOnboarding } from './index';

export const getRoutingStoreValues = createSelector(
    getDigitalOnboarding,
    digitalOnboarding => digitalOnboarding.routing
);

export const getActivePageIndex = createSelector(
    getRoutingStoreValues,
    routing => routing.activePageIndex
);

export const isApiFetchInProgress = createSelector(
    getRoutingStoreValues,
    routing => routing.isFetching
);
