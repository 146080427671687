import {
    transitionBuildWealthCalculator,
} from "../../../actions/investmentCalculator/buildWealth"

import {
    getBuildWealthCalculatorForm
} from "../../../selectors/investmentCalculator/buildWealthCalculator"
import {FIELD_STATUS} from "../../../reducers/status";
import { formFieldValid } from '../status';

export const buildWealthCalculateFormRules = (store, next, action) => {
    const result = next(action);
    const buildWealthCalculatorForm = getBuildWealthCalculatorForm(store.getState());
    const buildWealthCalculatorValid = validateFormDetails(buildWealthCalculatorForm);
    const formAction = buildWealthCalculatorValid
        ? transitionBuildWealthCalculator.formValid()
        : transitionBuildWealthCalculator.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFieldValid(formFields?.amountOnceOff) &&
    formFieldValid(formFields?.amountMonthly)
);
