import axios from 'axios';

import {
  calculateAfyaImaraGertrudeJuniorData,
  apiTransitionAfyaImaraGetrudeJuniorQuoteTool,
  setWhichFormToDisplay,
} from '../../../actions/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool.js';

import {
  getAfyaImaraGertrudeJuniorQuoteToolForm,
  getIsApiPending,
} from '../../../selectors/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool.js';

export const afyaImaraGertrudeJuniorQuoteToolService = (
  store,
  next,
  action,
) => {
  const result = next(action);
  const state = store.getState();

  (async () => {
    if (!getIsApiPending(state)) {
      store.dispatch(apiTransitionAfyaImaraGetrudeJuniorQuoteTool.pending());

      try {
        const response = await calculate(state);
        const premiumAmount = response.data.total_premiums;
        store.dispatch(calculateAfyaImaraGertrudeJuniorData(premiumAmount));
        store.dispatch(apiTransitionAfyaImaraGetrudeJuniorQuoteTool.success());
        store.dispatch(setWhichFormToDisplay('form-two'));
      } catch (e) {
        store.dispatch(calculateAfyaImaraGertrudeJuniorData(null));
        store.dispatch(apiTransitionAfyaImaraGetrudeJuniorQuoteTool.failure());
      }
    }
  })();
  return result;
};
const getAfyaImaraGertrudeJuniorPayload = formDetails => {
  return {
    panel_id: formDetails.panelId.value || '',
    no_of_children: formDetails.numberOfChildren.value || '',
    cover_limit_inpatient: formDetails.inpatientCoverLimitValue.value || '',
    cover_limit_outpatient: formDetails.outpatientCoverLimitValue.value || '',
  };
};
const calculate = async state => {
  const afyaImaraGertrudeJuniorFormDetails = getAfyaImaraGertrudeJuniorQuoteToolForm(
    state,
  );

  try {
    // To be updated

    const url = '/om-api/roa-quote-tools/afya-imara-gertrude-quote';
    const result = await axios.post(
      url,
      getAfyaImaraGertrudeJuniorPayload(afyaImaraGertrudeJuniorFormDetails),
    );
    return result;
  } catch (error) {
    return null;
  }
};
