import {
    NAVIGATE_TO_LANDING_PAGE,
    NAVIGATE_TO_ADVANCED_FUND_PICKER,
    // NAVIGATE_TO_BENEFICIARIES, NAVIGATE_TO_BENEFICIARIES_SPLIT,
    NAVIGATE_TO_FUND_SPLIT,
    NAVIGATE_TO_INVESTMENT_SETUP_INITIAL, 
    NAVIGATE_TO_INVESTMENT_SETUP_CONTRIBUTION,
    NAVIGATE_TO_PAYMENT_DETAILS,
    NAVIGATE_TO_PERSONAL_DETAILS,
    NAVIGATE_TO_AUXILIARY_DETAILS,
    NAVIGATE_TO_REVIEW, NAVIGATE_TO_SELECT_A_FUND,
    NAVIGATE_TO_THANK_YOU,
    SET_LANDING_PAGE,
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
    RESET_ROUTING
} from "../../actions/taxFreeSavingsAccount/routing";
import {PAGE_INDICES} from "../../actions/taxFreeSavingsAccount/types";

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    parentAppUrl: null,
    callbackAction: null,
    parentApp: null,
    landingPage: null
};

export default (state = initRouting, action) => {
    const setActivePage = pageIndex => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value
            };

        case SET_NAVIGATION_DONE:
            return {...state, doNavigation: false};

        case SET_LANDING_PAGE:
            return { ...state, landingPage: action.value };

        case NAVIGATE_TO_LANDING_PAGE:
            return setActivePage(PAGE_INDICES.LANDING_PAGE);

        case NAVIGATE_TO_INVESTMENT_SETUP_INITIAL:
            return setActivePage(PAGE_INDICES.INVESTMENT_SETUP_INITIAL);

        case NAVIGATE_TO_INVESTMENT_SETUP_CONTRIBUTION:
            return setActivePage(PAGE_INDICES.INVESTMENT_SETUP_CONTRIBUTION);

        case NAVIGATE_TO_SELECT_A_FUND:
            return setActivePage(PAGE_INDICES.SELECT_A_FUND);

        case NAVIGATE_TO_ADVANCED_FUND_PICKER:
            return setActivePage(PAGE_INDICES.ADVANCED_FUND_PICKER);

        case NAVIGATE_TO_FUND_SPLIT:
            return setActivePage(PAGE_INDICES.FUND_SPLIT);

        case NAVIGATE_TO_PERSONAL_DETAILS:
            return setActivePage(PAGE_INDICES.PERSONAL_DETAILS);

        case NAVIGATE_TO_AUXILIARY_DETAILS:
            return setActivePage(PAGE_INDICES.AUXILIARY_DETAILS);

        // case NAVIGATE_TO_BENEFICIARIES:
        //     return setActivePage(PAGE_INDICES.BENEFICIARIES);
        //
        // case NAVIGATE_TO_BENEFICIARIES_SPLIT:
        //     return setActivePage(PAGE_INDICES.BENEFICIARIES_SPLIT);

        case NAVIGATE_TO_PAYMENT_DETAILS:
            return setActivePage(PAGE_INDICES.PAYMENT_DETAILS);

        case NAVIGATE_TO_REVIEW:
            return setActivePage(PAGE_INDICES.REVIEW);

        case NAVIGATE_TO_THANK_YOU:
            return setActivePage(PAGE_INDICES.THANK_YOU);

        // TODO: add cvs if and when greenlit
        // case SET_NAVIGATION_TO_CVS_FROM_PARENT_APP:
        //     return {
        //         ...state,
        //         parentAppUrl: action.parentAppUrl,
        //         callbackAction: action.callbackAction,
        //         doExitNavigation: false,
        //         parentApp: action.parentApp
        //     };
        // case SET_NAVIGATION_BACK_TO_PARENT_APP:
        //     return {
        //         ...initRouting,
        //         doExitNavigation: true,
        //         parentAppUrl: state.parentAppUrl,
        //     };

        case RESET_ROUTING: 
            return { ...initRouting };

        default:
            return state;
    }
};
