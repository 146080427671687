import { validationIsEqual } from '../status';
import {
    validateDateOfBirth,
    validateEmail,
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateAlphaNumericString,
    validateKraPinNumber,
    validateNumericString,
    validateAlphaString,
} from '@om/validation';
import {
    GENDER_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    NATIONALITY_SET_VALUE,
    MIDDLE_NAME_SET_VALUE,
    DOB_SET_VALUE,
    OCCUPATION_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    TIN_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    POSTAL_ADDRESS_SET_VALUE,
    RESIDENTIAL_STATUS_SET_VALUE,
    TOWN_OR_CITY_SET_VALUE,
    ADDRESS_SET_VALUE,
    ID_CARD_NUMBER_SET_VALUE,
    setGenderValid,
    setNationalityValid,
    setOccupationValid,
    setIdNumberValid,
    setPostalAddressValid,
    setFirstNameValid,
    setLastNameValid,
    setEmailValid,
    setPhoneNumberValid,
    setDOBValid,
    setTinNumberValid,
    setResidentialStatusValid,
    setTownOrCityValid,
    setAddressValid,
    setIdCardNumberValid,
    setMiddleNameValid,
} from '../../../actions/ugMotorInsurance/personalDetails';
import { validateUgandanId } from '@om/validation/src/validators/ugandan-id-validator';
import { getNationalityValue } from '../../../selectors/ugMotorInsurance/personalDetails';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

let prevValidationAction = null;

export const personalDetailsValidation = (store, next, action) => {
    /*
function validateUgandanPhoneNumber(phoneNumber) {
    const regex = /^(07|03|04)\d{8}$/;

    const isValid = regex.test(phoneNumber);

    return isValid;
}
*/
    const validateString = (value, min, subject) => {
        const validators = [validateName, validateAlphaNumericString, validateNumericString];

        let customError = null;

        for (const validate of validators) {
            customError = validate(value, { min, subject });
            if (!customError) break;
        }

        return customError;
    };

    function validatePassport(passport) {
        const passportRegex = /^[A-Z][A-Z0-9]{8,}$/;
        return passportRegex.test(passport);
    }

    const validationAction = (() => {
        let error;
        switch (action.type) {
            case GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Gender',
                });
                return setGenderValid(error);

            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });
                return setFirstNameValid(error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });
                return setLastNameValid(error);

            case MIDDLE_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Middle Name',
                });
                return setMiddleNameValid(error);

            case NATIONALITY_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setNationalityValid(error);

            case OCCUPATION_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setOccupationValid(error);

            case ID_NUMBER_SET_VALUE:
                if (gatsbyStack === 'ug') {
                    const nationality = getNationalityValue(store.getState());

                    if (nationality.value) {
                        if (nationality.value === 'Ugandan') {
                            error = validateUgandanId(action.value);
                        } else {
                            const passportIsValid = validatePassport(action.value);

                            if (passportIsValid) {
                                error = null;
                            } else {
                                error = 'Passport number is invalid';
                            }
                        }
                    } else {
                        error = validateUgandanId(action.value);
                    }
                }
                if (gatsbyStack === 'ke') {
                    error = validateNumericString(action.value, { min: 8, max: 15 });
                }

                return setIdNumberValid(error);

            case ID_CARD_NUMBER_SET_VALUE:
                error = validateAlphaNumericString(action.value, { min: 10, max: 15 });
                return setIdCardNumberValid(error);

            case TIN_NUMBER_SET_VALUE:
                error =
                    gatsbyStack === 'ug'
                        ? validateNumericString(action.value, { min: 10, max: 10 })
                        : validateKraPinNumber(action.value);
                return setTinNumberValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Phone Number',
                    countryCode: gatsbyStack,
                });
                return setPhoneNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case POSTAL_ADDRESS_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value);
                return setPostalAddressValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 15, max: 85 });
                return setDOBValid(error);

            case RESIDENTIAL_STATUS_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Residential Status',
                });
                return setResidentialStatusValid(error);

            case TOWN_OR_CITY_SET_VALUE:
                error = validateAlphaString(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'Town or City',
                });
                return setTownOrCityValid(error);

            case ADDRESS_SET_VALUE:
                error = validateString(action.value, {
                    subject: 'Address',
                    min: 3,
                });
                return setAddressValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
