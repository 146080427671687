import { createSelector } from 'reselect';
import { getRWMotorPrivateInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getCarDetails = createSelector(getRWMotorPrivateInsuranceState, (state) => state.carDetails);

export const getCarDetailsForm = createSelector(getCarDetails, (carDetails) => carDetails.form);

export const getSelectedForm = createSelector(getCarDetails, (carDetails) => carDetails.selectedForm);

export const getDropOffDetails = createSelector(getCarDetails, (carDetails) => carDetails.dropOffDetails);

export const getCarDetailsFormValid = createSelector(
    getCarDetails,
    (carDetails) => carDetails.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getCarDetails,
    (carDetails) => carDetails.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getApiStatus = createSelector(getCarDetails, (carDetails) => carDetails.apiCallStatus);

export const getCarDetailsReferenceData = createSelector(
    getCarDetails,
    (carDetails) => carDetails.carDetailsReferenceData,
);

const makeGetFieldForm = (fieldId) => createSelector(getCarDetailsForm, (form) => form[fieldId]);

export const getRegistrationNumberValue = makeGetFieldForm('registrationNumber');
export const getChasisNumberValue = makeGetFieldForm('chasisNumber');
export const getTinNumberValue = makeGetFieldForm('tinNumber');
