import {
    REGISTRATION_NUMBER_SET_VALUE,
    CHASSIS_NUMBER_SET_VALUE,
    ENGINE_NUMBER_SET_VALUE,
    COVER_DATE_SET_VALUE,
    IDENTIFICATION_MEANS_DATA_SET_VALUE,
    setRegistrationNumberValid,
    setChassisNumberValid,
    setEngineNumberValid,
    setCoverDateValid,
    setIdentificationMeansDataValid
} from '../../../actions/roaCarInsurance/vehicleDetails';

import { validationIsEqual } from '../status';
import {validateDate, validateAlphaNumericStringAllowingOnlyNumbers, validateAlphaString, validateSelection } from "@om/validation";

let prevValidationAction = null;
export const vehicleDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        let today = new Date()
        let  validTodayDate = today.toLocaleDateString('en-NG').split('/').join('/');
        switch (action.type) {
            case REGISTRATION_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {subject: 'REGISTRATION NUMBER', required: true })
                return setRegistrationNumberValid(error);
            case CHASSIS_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {subject: 'CHASSIS NUMBER', required: true })
                return setChassisNumberValid(error);
            case ENGINE_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {subject: 'ENGINE NUMBER', required: true })
                return setEngineNumberValid(error);
            case COVER_DATE_SET_VALUE:
                error = validateDate(action.value,{required: true, min: validTodayDate} )
                return setCoverDateValid(error);
            case IDENTIFICATION_MEANS_DATA_SET_VALUE:
                error = validateAlphaString(action.value, { required: true  });
                return setIdentificationMeansDataValid(error);
            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value?.length > 1)
    ) {
        return next(action);
    }
    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
