import {initRouting} from './routing';
import {initQuotation} from './quotation';
import {initAboutYou} from './aboutYou';
import {initDetails} from './details';
import {initPayments} from './payment';
import {initProduct} from './product';
import {initialState} from "./agentReferral";
import {initVehicleDetails} from "./vehicleDetails";
import {initPersistedAt} from './persistedAt';

export default () => {
    return {
        routing: initRouting,
        quotation: initQuotation,
        aboutYou: initAboutYou,
        details: initDetails,
        payments: initPayments,
        product: initProduct,
        agentReferral: initialState,
        vehicleDetails: initVehicleDetails,
        persistedAt: initPersistedAt,
    }
};