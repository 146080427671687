import {
  RETIREMENT_AMOUNT_SET_VALUE,
  MONTHLY_INCOME_SET_VALUE,
  SAVINGS_POT_CALCULATOR_SHOW_RESULTS_SUMMARY,
  FORM_VALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION,
  FORM_INVALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION,
  RESET_CALCULATOR_FORM,
  RETIREMENT_AMOUNT_SET_VALID,
  MONTHLY_INCOME_SET_VALID,
  PRODUCT_RECOMMENDATION_SET_VALUE,
  TOGGLE_SHOW_PRODUCT_OFFERING,
  SAVINGS_POT_CALCULATOR_SHOW_LOADER,
  SHOW_FIELD_DETAILS,
} from "../../actions/savingsPotCalculator/savingsPotCalculatorForm";
import { FIELD_STATUS, FORM_STATUS } from "../status";
import { infoData } from "./popupData";

const API_CALL_SUCCESS = "API_CALL_SUCCESS";
const API_CALL_FAILURE = "API_CALL_FAILURE";
const savingsPotCalculatorFormInitialState = {
  retirementAmount: { value: null, error: null, status: null },
  monthlyIncome: { value: null, error: null, status: null },
};

const initialState = {
  formStatus: FORM_STATUS.INVALID,
  showRecommendation: false,
  inputDisabled: false,
  displayReset: false,
  form: savingsPotCalculatorFormInitialState,
  calculationResults: {
    withdrawalGrossAmount: null,
    taxAmount: null,
    withdrawalNetAmount: null,
  },
  showResultSummary: false,
  showLoader: false,
  apiError: false,
  popupData: {
    infoData,
  },
  showFieldDetailsStatus: {
    fieldName: null,
    subFieldName: null,
    fieldDescription: null,
    title: null,
  },
};

export default (state = initialState, action) => {
  const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
  const updateFormField = (fieldId, update) => ({
    ...state,
    form: {
      ...state.form,
      [fieldId]: update(state.form[fieldId]),
    },
  });

  switch (action.type) {
    // Form Validation
    case FORM_VALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION:
      return { ...state, formStatus: FORM_STATUS.VALID };
    case FORM_INVALID_SAVINGS_POT_CALCULATOR_FORM_TRANSITION:
      return { ...state, formStatus: FORM_STATUS.INVALID };

    case RETIREMENT_AMOUNT_SET_VALUE:
      return updateFormField("retirementAmount", (field) => ({
        ...field,
        value: action.value,
      }));

    case RETIREMENT_AMOUNT_SET_VALID:
      return updateFormField("retirementAmount", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case MONTHLY_INCOME_SET_VALUE:
      return updateFormField("monthlyIncome", (field) => ({
        ...field,
        value: action.value,
      }));

    case MONTHLY_INCOME_SET_VALID:
      return updateFormField("monthlyIncome", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SAVINGS_POT_CALCULATOR_SHOW_RESULTS_SUMMARY:
      return {
        ...state,
        showResultSummary: true,
      };

    case API_CALL_SUCCESS:
      return {
        ...state,
        calculationResults: action.payload,
        showLoader: false,
        inputDisabled: true,
        displayReset: true,
      };

    case API_CALL_FAILURE:
      return {
        ...state,
        calculationError: action.payload,
        showLoader: false,
        apiError: true,
        displayReset: true,
      };

    case SAVINGS_POT_CALCULATOR_SHOW_LOADER:
      return { ...state, showLoader: true, inputDisabled: true };

    // reset form
    case RESET_CALCULATOR_FORM:
      return {
        formStatus: FORM_STATUS.INVALID,
        showRecommendation: false,
        inputDisabled: false,
        displayReset: false,
        form: savingsPotCalculatorFormInitialState,
        calculationResults: {
          withdrawalGrossAmount: null,
          taxAmount: null,
          withdrawalNetAmount: null,
        },
        showResultSummary: false,
        showLoader: false,
        apiError: false,
      };

    // Product recommendation
    case PRODUCT_RECOMMENDATION_SET_VALUE:
      return {
        ...state,
        showRecommendation: true,
      };
    case TOGGLE_SHOW_PRODUCT_OFFERING:
      return {
        ...state,
        showRecommendation: !state.showRecommendation,
      };

    case SHOW_FIELD_DETAILS:
      return {
        ...state,
        showFieldDetailsStatus: {
          fieldDescription:
            "Your monthly taxable income is the money you earn each month from your job and other sources before tax or any deductions. It's your total earnings in a given month. We need this information to estimate the tax you'll pay.",
          title: "",
        },
      };

    default:
      return state;
  }
};
