import { transitionIDVerification } from "../../../actions/customerVerificationService/idVerification";
import { getIsIDValid } from "../../../selectors/customerVerificationService/idVerification";

export const idVerificationRules = (store, next, action) => {
    const result = next(action);
    const isValid = getIsIDValid(store.getState());
    store.dispatch(isValid
        ? transitionIDVerification.formValid()
        : transitionIDVerification.formInvalid());

    return result;
};