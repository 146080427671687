import { transitionDoctorPiQuoteForm } from '../../../actions/doctorsPi/quote';
import { getQuoteForm } from '../../../selectors/doctorsPi/quote';

import { FIELD_STATUS } from '../../../reducers/status';

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getQuoteForm(store.getState());
    const quoteFormValid = validateQuoteForm(quoteForm);
    const formAction = quoteFormValid ? transitionDoctorPiQuoteForm.formValid() : transitionDoctorPiQuoteForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateQuoteForm = (formFields) => {
        return (
            formFields.limitIndemnity.status === FIELD_STATUS.VALID &&
            formFields.specialty.status === FIELD_STATUS.VALID
        );
}