import {
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateAlphaString,
    validateDate,
    validateName,
    validateNumberDigitalSavings,
    validateNumericString,
    validateSelection,
} from '@om/validation';
import {
    DESIRED_SET_VALUE,
    DURATION_SET_VALUE,
    FREQUENCY_SET_VALUE,
    LUMPSUM_SET_VALUE,
    START_DATE_SET_VALUE,
    setDesiredValid,
    setDurationValid,
    setFrequencyValid,
    setLumpsumValid,
    setStartDateValid,
} from '../../../actions/digitalSavings/quote';
import { validationIsEqual } from '../status';
import { getFrequencyValue } from '../../../selectors/digitalSavings/quote';

let prevValidationAction = null;

const date = new Date();
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const year = date.getFullYear();

const formattedDate = `${day}/${month}/${year}`;

export const quoteValidation = (store, next, action) => {
    const frequency = getFrequencyValue(store.getState());

    const validationAction = (() => {
        let error;
        switch (action.type) {
            case DESIRED_SET_VALUE:
                error = validateNumberDigitalSavings(action.value, {
                    subject: 'Desired amount',
                    required: true,
                    min: frequency.value === 'quaterly' ? 9000 : frequency.value === 'semiannual' ? 18000 : frequency.value === 'yearly' ? 36000 : 3000,
                });
                return setDesiredValid(error);

            case FREQUENCY_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Frequency',
                    required: true,
                });
                return setFrequencyValid(error);

            case LUMPSUM_SET_VALUE:
                error = validateNumberDigitalSavings(action.value, {
                    subject: 'Lumpsum amount',
                    required: true,
                    min: 300000,
                });
                return setLumpsumValid(error);

            case DURATION_SET_VALUE:
                error = validateNumberDigitalSavings(action.value, {
                    subject: 'Duration',
                    required: true,
                });
                return setDurationValid(error);

            case START_DATE_SET_VALUE:
                error = validateDate(action.value, {
                    subject: 'Start date',
                    required: true,
                    min: formattedDate,
                });
                return setStartDateValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
