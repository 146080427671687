import {
    transitionAccidentalDisabilityTool,
} from "../../../actions/roaQuoteTools/accidentalDisabilityQuoteTool";
import {
    getAccidentalDisabilityQuoteToolForm,
    getSelectedForm
} from "../../../selectors/roaQuoteTools/accidentalDisabilityQuoteTool";
import {FIELD_STATUS} from "../../../reducers/status";

export const accidentalDisabilityQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const accidentalDisabilityQuoteToolForm = getAccidentalDisabilityQuoteToolForm(store.getState());
    const selectedForm = getSelectedForm(store.getState());
    const accidentalDisabilityQuoteToolValid = validateAccidentalDisabilityQuoteToolFormDetails(accidentalDisabilityQuoteToolForm, selectedForm);
    const formAction = accidentalDisabilityQuoteToolValid
        ? transitionAccidentalDisabilityTool.formValid()
        : transitionAccidentalDisabilityTool.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validateAccidentalDisabilityQuoteToolFormDetails = (formFields, selectedForm) => {
    if (selectedForm === 'form-one') {
        return formFields.candidateDob && formFields.candidateDob.status === FIELD_STATUS.VALID &&
            formFields.coverYearsValue && formFields.coverYearsValue.status === FIELD_STATUS.VALID &&
            formFields.coverAmount && formFields.coverAmount.status === FIELD_STATUS.VALID;

    } 
    return true;
};
