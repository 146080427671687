import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    REG_NUMBER_SET_VALUE,
    REG_NUMBER_SET_VALID,
    MANUFACTURE_MODEL_SET_VALUE,
    MANUFACTURE_MODEL_SET_VALID,
    CHASIS_NUMBER_SET_VALUE,
    CHASIS_NUMBER_SET_VALID,
    BODY_TYPE_SET_VALUE,
    BODY_TYPE_SET_VALID,
    SITTING_CAPACITY_SET_VALUE,
    SITTING_CAPACITY_SET_VALID,
    FUEL_SET_VALUE,
    FUEL_SET_VALID,
    COLOR_SET_VALUE,
    COLOR_SET_VALID,
    YEARS_WITH_LICENSE_SET_VALUE,
    YEARS_WITH_LICENSE_SET_VALID,
    FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    COLLECT_CAR_DETAILS_FORM_DATA,
    API_PENDING_TRANSITION_CAR_DETAILS_FORM,
    API_SUCCESS_TRANSITION_CAR_DETAILS_FORM,
    API_FAILURE_TRANSITION_CAR_DETAILS_FORM,
    SELECTED_FORM_SET_VALUE,
    SUBMIT_CAR_DETAILS_SUCCESS,
    SUBMIT_CAR_DETAILS_FAILURE,
    ENGINE_NUMBER_SET_VALUE,
    ENGINE_NUMBER_SET_VALID,
    MANUFACTURER_MODEL_SET_VALUE,
    MANUFACTURER_MODEL_SET_VALID,
} from '../../actions/ugMotorInsurance/carDetails';

export const initCarDetails = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    carDetailsReferenceData: null,
    apiCallStatus: null,
    selectedForm: 'carDetails',
    form: {
        registrationNumber: { value: null, error: null, status: null },
        chasisNumber: { value: null, error: null, status: null },
        engineNumber: { value: null, error: null, status: null },
        bodyType: { value: null, error: null, status: null },
        sittingCapacity: { value: null, error: null, status: null },
        color: { value: null, error: null, status: null },
        yearsWithLicense: { value: null, error: null, status: null },
        fuel: { value: null, error: null, status: null },
        manufacturerModel: { value: null, error: null, status: null },
    },
};

export default (state = initCarDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case REG_NUMBER_SET_VALUE:
            return updateFormField('registrationNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case REG_NUMBER_SET_VALID:
            return updateFormField('registrationNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MANUFACTURE_MODEL_SET_VALUE:
            return updateFormField('manufactureModel', (field) => ({
                ...field,
                value: action.value,
            }));
        case MANUFACTURE_MODEL_SET_VALID:
            return updateFormField('manufactureModel', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CHASIS_NUMBER_SET_VALUE:
            return updateFormField('chasisNumber', (field) => ({ ...field, value: action.value }));

        case CHASIS_NUMBER_SET_VALID:
            return updateFormField('chasisNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ENGINE_NUMBER_SET_VALUE:
            return updateFormField('engineNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case ENGINE_NUMBER_SET_VALID:
            return updateFormField('engineNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BODY_TYPE_SET_VALUE:
            return updateFormField('bodyType', (field) => ({
                ...field,
                value: action.value,
            }));

        case BODY_TYPE_SET_VALID:
            return updateFormField('bodyType', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SITTING_CAPACITY_SET_VALUE:
            return updateFormField('sittingCapacity', (field) => ({
                ...field,
                value: action.value,
            }));

        case SITTING_CAPACITY_SET_VALID:
            return updateFormField('sittingCapacity', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COLOR_SET_VALUE:
            return updateFormField('color', (field) => ({
                ...field,
                value: action.value,
            }));

        case COLOR_SET_VALID:
            return updateFormField('color', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case YEARS_WITH_LICENSE_SET_VALUE:
            return updateFormField('yearsWithLicense', (field) => ({
                ...field,
                value: action.value,
            }));

        case YEARS_WITH_LICENSE_SET_VALID:
            return updateFormField('yearsWithLicense', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FUEL_SET_VALUE:
            return updateFormField('fuel', (field) => ({
                ...field,
                value: action.value,
            }));

        case FUEL_SET_VALID:
            return updateFormField('fuel', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MANUFACTURER_MODEL_SET_VALUE:
            return updateFormField('manufacturerModel', (field) => ({
                ...field,
                value: action.value,
            }));

        case MANUFACTURER_MODEL_SET_VALID:
            return updateFormField('manufacturerModel', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SELECTED_FORM_SET_VALUE:
            return { ...state, selectedForm: action.value };
        //    API Calls
        case API_PENDING_TRANSITION_CAR_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_CAR_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_CAR_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_CAR_DETAILS_FORM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case SUBMIT_CAR_DETAILS_SUCCESS:
            return { ...state, carDetailsReferenceData: action.value };
        case SUBMIT_CAR_DETAILS_FAILURE:
            return { ...state, carDetailsReferenceData: action.error };

        // Reset the state
        case REINITIALIZE:
            return initCarDetails;
        default:
            return state;
    }
};
