import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

export function validateAccountNumber(value, 
    {
        min = config.BANK_ACCOUNT.MIN,
        max = config.BANK_ACCOUNT.MAX,
        required = true,
        subject = 'Bank account number',
        retainSubjectCase
    } = {}) {

    if (!value && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    value = value.trim();
    value = OmValidationMessageHelper.removeSpaces(value);
    const invalidMsg = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    const invalidAccountNumMin = OmValidationMessageHelper.getStringTooShortMsg(subject, min, config.PRONOUN.YOUR, retainSubjectCase);
    const invalidAccountNumMax = OmValidationMessageHelper.getStringTooLongMsg(subject, max, config.PRONOUN.YOUR, retainSubjectCase);

    const regEx = new RegExp('^[0-9]*$');
    const zeroRegEx = new RegExp('^[0]*$');

    if (!regEx.test(value)) {
        return invalidMsg;
    }

    if (zeroRegEx.test(value)) {
        return invalidMsg;
    }

    if (min && max && (value.length > max && value.length < min)) {
        return invalidMsg;
    }

    if (min && (value.length < min)) {
        return invalidAccountNumMin;
    }

    if (max && (value.length > max)) {
        return invalidAccountNumMax;
    }

    return null;
}
