import {
	getLongTermGoalFundSplitForm,
	getIsEvenSplit,
} from '../../../selectors/investmentCalculator/longTermGoalSplit';
import {
	deselectEvenSplit,
	FUND_SPLIT_SET_VALUE,
	setTotalPercentageValue,
	transitionFundSplit,
} from '../../../actions/investmentCalculator/longTermGoalSplit';

export const longTermGoalSplitFormRules = (store, next, action) => {

    const result = next(action);

    const splitForm = getLongTermGoalFundSplitForm(store.getState());
    const totalPercentage =
        (Number(splitForm.equityFund.value) || 0) +
        (Number(splitForm.balancedFund.value) || 0);
    store.dispatch(setTotalPercentageValue(totalPercentage));

    // deselect even split if user changes a field
    if (action.type.startsWith(FUND_SPLIT_SET_VALUE) && getIsEvenSplit(store.getState()))
        store.dispatch(deselectEvenSplit());

    // is form valid at this point? i.e. 100% total
    const formAction = totalPercentage === 100
        ? transitionFundSplit.formValid()
        : transitionFundSplit.formInvalid();

    store.dispatch(formAction);

    return result;
};
