import { getIsModelsApiPending } from '../../../selectors/ugMotorInsurance/quote';
import { apiTransitionCarModels, setCarModelsValid, setCarModelsValue } from '../../../actions/ugMotorInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import { getCarMakeValue } from '../../../selectors/ugMotorInsurance/quote';
import axios from 'axios';

export const getCarModelsService = (store, next, action) => {
    (async () => {
        if (!getIsModelsApiPending(store.getState())) {
            store.dispatch(apiTransitionCarModels.pending());
            try {
                const resp = await getModels(store.getState());
                store.dispatch(setCarModelsValue(resp.data.carModels));
                store.dispatch(apiTransitionCarModels.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionCarModels.failure());
                store.dispatch(setCarModelsValid(e));
            }
        }
    })();
    return next(action);
};

const getModels = async (state) => {
    const loginData = getAccessToken(state);
    const carMake = getCarMakeValue(state);
    const carmakeId = carMake?.value?.id;

    //console.log(loginData.accessToken);
    try {
        const url = '/om-api/ug-motor-insurance/car-models';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                id: carmakeId,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
