import { validateAlphaNumericStringAllowingOnlyNumbers } from '@om/validation';
import {
    setOnBehalfOfChildValid,
    setHasOtherTaxFreePlanValid,
    setLegalGuardianValid,
    setSavingForValid,
    setYourGoalValid,
    ON_BEHALF_OF_CHILD_SET_VALUE,
    HAS_OTHER_TAX_FREE_PLAN_SET_VALUE,
    LEGAL_GUARDIAN_SET_VALUE,
    SAVING_FOR_SET_VALUE,
    YOUR_GOAL_SET_VALUE
} from "../../../actions/taxFreeSavingsAccount/investmentSetupInitial";
import {validationIsEqual} from "../status";

let prevValidationAction = null;
export const investmentSetupInitialValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {

            case ON_BEHALF_OF_CHILD_SET_VALUE:
                error = !action.value ? 'Please make a selection' : null;
                return setOnBehalfOfChildValid(error);

            case LEGAL_GUARDIAN_SET_VALUE:
                error = !action.value ? 'Please make a selection' : null;
                return setLegalGuardianValid(error);

            case HAS_OTHER_TAX_FREE_PLAN_SET_VALUE:
                error = !action.value ? 'Please make a selection' : null;
                return setHasOtherTaxFreePlanValid(error);

            case SAVING_FOR_SET_VALUE:
                error = !action.value ? 'Please make a selection' : null;
                return setSavingForValid(error);

            case YOUR_GOAL_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {required: false, min: 1, max: 20, subject: 'goal'} );
                return setYourGoalValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
