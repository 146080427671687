import { transitionBiasharaFlexiUploadDocuments } from "../../../actions/biasharaFlexi/uploadDocuments";
import { FIELD_STATUS } from "../../../reducers/status";
import { getUploadDocumentsForm } from "../../../selectors/biasharaFlexi/uploadDocuments";

export const uploadDocumentsFormRules = (store, next, action) => {
    const result = next(action);
    const uploadDocumentsForm = getUploadDocumentsForm(store.getState());
    const uploadDocumentsFormValid = validateUploadDocuments(uploadDocumentsForm);
    const formAction = uploadDocumentsFormValid
        ? transitionBiasharaFlexiUploadDocuments.formValid()
        : transitionBiasharaFlexiUploadDocuments.formInvalid()
    store.dispatch(formAction);
    return result
}

const validateUploadDocuments = (formFields) => {
	return (
		formFields.incorporationCertificate &&
		formFields.incorporationCertificate.status === FIELD_STATUS.VALID &&
		formFields.kraCertificate &&
		formFields.kraCertificate.status === FIELD_STATUS.VALID
	);
};
