import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
import {
    FULL_NAME_SET_VALUE,
    FULL_NAME_SET_VALID,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    FROM_SET_VALUE,
    FROM_SET_VALID,
    TO_SET_VALUE,
    TO_SET_VALID,
    TRAVEL_PLAN_SET_VALUE,
    TRAVEL_PLAN_SET_VALID,
    DEPARTURE_DATE_SET_VALUE,
    DEPARTURE_DATE_SET_VALID,
    RETURN_DATE_SET_VALUE,
    RETURN_DATE_SET_VALID,
    IDENTIFICATION_DOCUMENT_SET_VALUE,
    IDENTIFICATION_DOCUMENT_SET_VALID,
    COVER_SET_VALUE,
    COVER_SET_VALID,
    ID_NUMBER_SET_VALUE,
    ID_NUMBER_SET_VALID,
    PAYMENTS_PHONE_NUMBER_SET_VALUE,
    PAYMENTS_PHONE_NUMBER_SET_VALID,
    FORM_VALID_ADD_TRAVELLER_FORM,
    FORM_INVALID_ADD_TRAVELLER_FORM,
    FORM_VALID_TRAVEL_INSURANCE_PAYMENTS_FORM,
    FORM_INVALID_TRAVEL_INSURANCE_PAYMENTS_FORM,
    SUBMIT_EXTRA_TRAVELLER_DETAILS_SUCCESS,
    SUBMIT_EXTRA_TRAVELLER_DETAILS_FAILURE,
    API_PENDING_TRANSITION_ADD_EXTRA_TRAVELLER_FORM,
    API_SUCCESS_TRANSITION_ADD_EXTRA_TRAVELLER_FORM,
    API_FAILURE_TRANSITION_ADD_EXTRA_TRAVELLER_FORM,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    SUBMIT_TRAVEL_INSURANCE_PAYMENTS_SUCCESS,
    SUBMIT_TRAVEL_INSURANCE_PAYMENTS_FAILURE,
    API_PENDING_TRANSITION_PAYMENTS_STATUS,
    API_SUCCESS_TRANSITION_PAYMENTS_STATUS,
    API_FAILURE_TRANSITION_PAYMENTS_STATUS,
    API_PENDING_TRANSITION_PAYMENTS_FORM,
    API_FAILURE_TRANSITION_PAYMENTS_FORM,
    API_SUCCESS_TRANSITION_PAYMENTS_FORM,
    SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_SUCCESS,
    SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_FAILURE,
    API_PENDING_TRANSITION_GENERATE_ACCOUNT_NUMBER,
    API_SUCCESS_TRANSITION_GENERATE_ACCOUNT_NUMBER,
    API_FAILURE_TRANSITION_GENERATE_ACCOUNT_NUMBER,
    SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_SUCCESS,
    SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_FAILURE,
    API_PENDING_TRANSITION_GENERATE_CERTIFICATE,
    API_SUCCESS_TRANSITION_GENERATE_CERTIFICATE,
    API_FAILURE_TRANSITION_GENERATE_CERTIFICATE,
    SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_SUCCESS,
    SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_FAILURE,
    API_INITIALIZE_TRANSITION_PAYMENTS_STATUS,
} from '../../actions/roaTravelInsurance/addTraveller';

import {
     API_PENDING_TRANSITION_MANUAL_MPESA_PAYMENTS,
     TRANSACTION_CODE_SET_VALUE, 
     TRANSACTION_CODE_SET_VALID,
     API_SUCCESS_TRANSITION_MANUAL_MPESA_PAYMENTS,
     API_FAILURE_TRANSITION_MANUAL_MPESA_PAYMENTS,
     SUBMIT_TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENTS_SUCCESS,
     SUBMIT_TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENTS_FAILURE,
     API_INITIALIZE_TRANSITION_MANUAL_MPESA_PAYMENTS
     
    } from '../../actions/roaTravelInsurance/manualMpesaPayments';

export const initialState = {
           selectedForm: 'form-one',
           formOneStatus: FORM_STATUS.INVALID,
           formTwoStatus: FORM_STATUS.INVALID,
           extraTravelResponse: { value: null, error: null },
           paymentsRequestResponse: { value: null, error: null },
           paymentsStatusResponse: { value: null, error: null },
           manualMpesaPaymentsResponse: {value: null, error: null},
           accountNumberResponse: { value: null, error: null },
           generateCertResponse: { value: null, error: null },
           formOne: {
               fullName: { value: null, error: null, status: null },
               phone: { value: null, error: null, status: null },
               email: { value: null, error: null, status: null },
               dob: { value: null, error: null, status: null },
               from: { value: null, error: null, status: null },
               to: { value: null, error: null, status: null },
               departureDate: { value: null, error: null, status: null },
               returnDate: { value: null, error: null, status: null },
               travelPlan: { value: null, error: null, status: null },
               cover: { value: null, error: null, status: null },
               idDocument: { value: null, error: null, status: null },
               idNumber: { value: null, error: null, status: null },
           },
           formTwo: {
               paymentsPhone: { value: null, error: null, status: null },
               transactionCode: {value: null, error: null, status: null}
           },
       };

export default (state = initialState, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (form, fieldId, update) => ({
        ...state,
        [form]: {
            ...state[form],
            [fieldId]: update(state[form][fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_ADD_TRAVELLER_FORM:
            return { ...state, formOneStatus: FORM_STATUS.VALID };
        case FORM_INVALID_ADD_TRAVELLER_FORM:
            return { ...state, formOneStatus: FORM_STATUS.INVALID };
        case FORM_VALID_TRAVEL_INSURANCE_PAYMENTS_FORM:
            return { ...state, formTwoStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRAVEL_INSURANCE_PAYMENTS_FORM:
            return { ...state, formTwoStatus: FORM_STATUS.INVALID };
        case WHICH_FORM_TO_DISPLAY:
            return { ...state, selectedForm: action.value };

        case SUBMIT_EXTRA_TRAVELLER_DETAILS_SUCCESS:
            return {
                ...state,
                extraTravelResponse: {
                    ...state.extraTravelResponse,
                    value: action.value,
                    error: null,
                },
            };
        case SUBMIT_EXTRA_TRAVELLER_DETAILS_FAILURE:
            return {
                ...state,
                extraTravelResponse: {
                    ...state.extraTravelResponse,
                    value: null,
                    error: action.error,
                },
            };

        case SUBMIT_TRAVEL_INSURANCE_PAYMENTS_SUCCESS:
            return {
                ...state,
                paymentsRequestResponse: {
                    ...state.paymentsRequestResponse,
                    value: action.value,
                    error: null,
                },
            };
        case SUBMIT_TRAVEL_INSURANCE_PAYMENTS_FAILURE:
            return {
                ...state,
                paymentsRequestResponse: {
                    ...state.paymentsRequestResponse,
                    value: null,
                    error: action.error,
                },
            };

        // account number response
        case SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_SUCCESS:
            return {
                ...state,
                accountNumberResponse: {
                    ...state.accountNumberResponse,
                    value: action.value,
                    error: null,
                },
            };
        case SUBMIT_TRAVEL_INSURANCE_GENERATE_ACCOUNT_NUMBER_FAILURE:
            return {
                ...state,
                accountNumberResponse: {
                    ...state.accountNumberResponse,
                    value: null,
                    error: action.error,
                },
            };

        case API_PENDING_TRANSITION_ADD_EXTRA_TRAVELLER_FORM:
            return { ...state, addTravellerApiStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_ADD_EXTRA_TRAVELLER_FORM:
            return { ...state, addTravellerApiStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_ADD_EXTRA_TRAVELLER_FORM:
            return { ...state, addTravellerApiStatus: API_CALL_STATUS.FAILURE };

        // payments stk sent
        case API_PENDING_TRANSITION_PAYMENTS_FORM:
            return { ...state, paymentsApiStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_PAYMENTS_FORM:
            return { ...state, paymentsApiStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_PAYMENTS_FORM:
            return { ...state, paymentsApiStatus: API_CALL_STATUS.FAILURE };

        case SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_SUCCESS:
            return {
                ...state,
                paymentsStatusResponse: {
                    ...state.paymentsStatusResponse,
                    value: action.value,
                    error: null,
                },
            };

        case SUBMIT_TRAVEL_INSURANCE_PAYMENTS_STATUS_FAILURE:
            return {
                ...state,
                paymentsStatusResponse: {
                    ...state.paymentsStatusResponse,
                    value: null,
                    error: action.error,
                },
            };

        // Manual payments API status
        case API_PENDING_TRANSITION_MANUAL_MPESA_PAYMENTS:
            return { ...state, manualPaymentsApiStatus: API_CALL_STATUS.PENDING };

        case API_SUCCESS_TRANSITION_MANUAL_MPESA_PAYMENTS:
            return { ...state, manualPaymentsApiStatus: API_CALL_STATUS.SUCCESS };

        case API_FAILURE_TRANSITION_MANUAL_MPESA_PAYMENTS:
            return { ...state, manualPaymentsApiStatus: API_CALL_STATUS.FAILURE };

        case API_INITIALIZE_TRANSITION_MANUAL_MPESA_PAYMENTS:
             return {
                    ...state,
                    manualPaymentsApiStatus: null,
                };

        case SUBMIT_TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENTS_SUCCESS:
            return {
                ...state,
                manualMpesaPaymentsResponse: {
                    ...state.manualMpesaPaymentsResponse,
                    value: action.value,
                    error: null,
                },
            };

        case SUBMIT_TRAVEL_INSURANCE_MANUAL_MPESA_PAYMENTS_FAILURE:
            return {
                ...state,
                manualMpesaPaymentsResponse: {
                    ...state.manualMpesaPaymentsResponse,
                    value: null,
                    error: action.error,
                },
            };

        // gerenate travel insurance certificate
        case SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_SUCCESS:
            return {
                ...state,
                generateCertResponse: {
                    ...state.generateCertResponse,
                    value: action.value,
                    error: null,
                },
            };

        case SUBMIT_TRAVEL_INSURANCE_GENERATE_CERTIFICATE_FAILURE:
            return {
                ...state,
                generateCertResponse: {
                    ...state.generateCertResponse,
                    value: null,
                    error: action.error,
                },
            };
        // payments status check
        case API_PENDING_TRANSITION_PAYMENTS_STATUS:
            return {
                ...state,
                paymentsStatusApiStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_PAYMENTS_STATUS:
            return {
                ...state,
                paymentsStatusApiStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_PAYMENTS_STATUS:
            return {
                ...state,
                paymentsStatusApiStatus: API_CALL_STATUS.FAILURE,
            };
        case API_INITIALIZE_TRANSITION_PAYMENTS_STATUS:
            return {
                ...state,
                paymentsStatusApiStatus: null,
            };
        // gererate travel insurance certificate
        case API_PENDING_TRANSITION_GENERATE_CERTIFICATE:
            return {
                ...state,
                generateCertApiStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_GENERATE_CERTIFICATE:
            return {
                ...state,
                generateCertApiStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_GENERATE_CERTIFICATE:
            return {
                ...state,
                generateCertApiStatus: API_CALL_STATUS.FAILURE,
            };

        // generate account number
        case API_PENDING_TRANSITION_GENERATE_ACCOUNT_NUMBER:
            return {
                ...state,
                accountNumberApiStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_GENERATE_ACCOUNT_NUMBER:
            return {
                ...state,
                accountNumberApiStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_GENERATE_ACCOUNT_NUMBER:
            return {
                ...state,
                accountNumberApiStatus: API_CALL_STATUS.FAILURE,
            };

        // Form one
        case FULL_NAME_SET_VALUE:
            return updateFormField('formOne', 'fullName', (field) => ({
                ...field,
                value: action.value,
            }));

        case FULL_NAME_SET_VALID:
            return updateFormField('formOne', 'fullName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('formOne', 'phone', (field) => ({
                ...field,
                value: action.value,
            }));

        case PHONE_NUMBER_SET_VALID:
            return updateFormField('formOne', 'phone', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('formOne', 'email', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMAIL_SET_VALID:
            return updateFormField('formOne', 'email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DOB_SET_VALUE:
            return updateFormField('formOne', 'dob', (field) => ({
                ...field,
                value: action.value,
            }));

        case DOB_SET_VALID:
            return updateFormField('formOne', 'dob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case TRAVEL_PLAN_SET_VALUE:
            return updateFormField('formOne', 'travelPlan', (field) => ({
                ...field,
                value: action.value,
            }));

        case TRAVEL_PLAN_SET_VALID:
            return updateFormField('formOne', 'travelPlan', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FROM_SET_VALUE:
            return updateFormField('formOne', 'from', (field) => ({
                ...field,
                value: action.value,
            }));

        case FROM_SET_VALID:
            return updateFormField('formOne', 'from', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TO_SET_VALUE:
            return updateFormField('formOne', 'to', (field) => ({
                ...field,
                value: action.value,
            }));

        case TO_SET_VALID:
            return updateFormField('formOne', 'to', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DEPARTURE_DATE_SET_VALUE:
            return updateFormField('formOne', 'departureDate', (field) => ({
                ...field,
                value: action.value,
            }));

        case DEPARTURE_DATE_SET_VALID:
            return updateFormField('formOne', 'departureDate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RETURN_DATE_SET_VALUE:
            return updateFormField('formOne', 'returnDate', (field) => ({
                ...field,
                value: action.value,
            }));

        case RETURN_DATE_SET_VALID:
            return updateFormField('formOne', 'returnDate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case IDENTIFICATION_DOCUMENT_SET_VALUE:
            return updateFormField('formOne', 'idDocument', (field) => ({
                ...field,
                value: action.value,
            }));

        case IDENTIFICATION_DOCUMENT_SET_VALID:
            return updateFormField('formOne', 'idDocument', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case COVER_SET_VALUE:
            return updateFormField('formOne', 'cover', (field) => ({
                ...field,
                value: action.value,
            }));

        case COVER_SET_VALID:
            return updateFormField('formOne', 'cover', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_NUMBER_SET_VALUE:
            return updateFormField('formOne', 'idNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_NUMBER_SET_VALID:
            return updateFormField('formOne', 'idNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PAYMENTS_PHONE_NUMBER_SET_VALUE:
            return updateFormField('formTwo', 'paymentsPhone', (field) => ({
                ...field,
                value: action.value,
            }));

        case TRANSACTION_CODE_SET_VALUE:
             return updateFormField('formTwo', 'transactionCode', (field) => ({
                    ...field,
                    value: action.value,
                }));

        case TRANSACTION_CODE_SET_VALID:
             return updateFormField('formTwo', 'transactionCode', (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error)
                }));
        

        case PAYMENTS_PHONE_NUMBER_SET_VALID:
            return updateFormField('formTwo', 'paymentsPhone', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Reset the state
        case REINITIALIZE:
            return initialState;
        default:
            return state;
    }
};
