const VEHICLE_DETAILS = 'roaCarInsurance/vehicleDetails/';
export const VEHICLE_DETAILS_SET_VALUE = VEHICLE_DETAILS + 'setValue/';
export const VEHICLE_DETAILS_SET_VALID = VEHICLE_DETAILS + 'setValid/';
export const FORM_VALID_VEHICLE_DETAILS = VEHICLE_DETAILS + 'transition/formStateValid';
export const FORM_INVALID_VEHICLE_DETAILS = VEHICLE_DETAILS + 'transition/formStateInValid';


// Events - form input action creator types
export const REGISTRATION_NUMBER_SET_VALUE = VEHICLE_DETAILS_SET_VALUE + 'registrationNumber';
export const REGISTRATION_NUMBER_SET_VALID = VEHICLE_DETAILS_SET_VALID + 'registrationNumber';

export const CHASSIS_NUMBER_SET_VALUE = VEHICLE_DETAILS_SET_VALUE + 'chassisNumber';
export const CHASSIS_NUMBER_SET_VALID = VEHICLE_DETAILS_SET_VALID + 'chassisNumber';

export const ENGINE_NUMBER_SET_VALUE = VEHICLE_DETAILS_SET_VALUE + 'engineNumber';
export const ENGINE_NUMBER_SET_VALID = VEHICLE_DETAILS_SET_VALID + 'engineNumber';

export const COVER_DATE_SET_VALUE = VEHICLE_DETAILS_SET_VALUE + 'coverDate';
export const COVER_DATE_SET_VALID = VEHICLE_DETAILS_SET_VALID + 'coverDate';

export const IDENTIFICATION_MEANS_SET_VALUE = VEHICLE_DETAILS_SET_VALUE + 'identificationMeans';
export const IDENTIFICATION_MEANS_SET_VALID = VEHICLE_DETAILS_SET_VALID + 'identificationMeans';

export const IDENTIFICATION_MEANS_DATA_SET_VALUE = VEHICLE_DETAILS_SET_VALUE + 'identificationMeansData';
export const IDENTIFICATION_MEANS_DATA_SET_VALID = VEHICLE_DETAILS_SET_VALID + 'identificationMeansData';

// Events - form input action creators
export const setRegistrationNumberValue = (value) => ({
    type: REGISTRATION_NUMBER_SET_VALUE,
    value,
});
export const setRegistrationNumberValid = (error) => ({
    type: REGISTRATION_NUMBER_SET_VALID,
    error,
});

export const setChassisNumberValue = (value) => ({
    type: CHASSIS_NUMBER_SET_VALUE,
    value,
});
export const setChassisNumberValid = (error) => ({
    type: CHASSIS_NUMBER_SET_VALID,
    error,
});

export const setEngineNumberValue = (value) => ({
    type: ENGINE_NUMBER_SET_VALUE,
    value,
});
export const setEngineNumberValid = (error) => ({
    type: ENGINE_NUMBER_SET_VALID,
    error,
});

export const setCoverDateValue = (value) => ({
    type: COVER_DATE_SET_VALUE,
    value,
});
export const setCoverDateValid = (error) => ({
    type: COVER_DATE_SET_VALID,
    error,
});

export const setIdentificationMeansValue = (value) => ({
    type: IDENTIFICATION_MEANS_SET_VALUE,
    value,
});
export const setIdentificationMeansValid = (error) => ({
    type: IDENTIFICATION_MEANS_SET_VALID,
    error,
});

export const setIdentificationMeansDataValue = (value) => ({
    type: IDENTIFICATION_MEANS_DATA_SET_VALUE,
    value,
});
export const setIdentificationMeansDataValid = (error) => ({
    type: IDENTIFICATION_MEANS_DATA_SET_VALID,
    error,
});

export const COLLECT_VEHICLE_DETAILS_DATA =
    VEHICLE_DETAILS_SET_VALUE + 'collectVehicleDetailsData';
export const CALCULATE_VEHICLE_DETAILS_SUCCESS =
    VEHICLE_DETAILS_SET_VALUE + 'vehicleDetailsSuccess';

export const collectVehicleDetailsData = () => ({
    type: COLLECT_VEHICLE_DETAILS_DATA,
});
export const calculateVehicleDetailsSuccess = (value) => ({
    type: CALCULATE_VEHICLE_DETAILS_SUCCESS,
    value,
});

// transition form status
export const transitionCarDetailsForm = {
    formValid: () => ({ type: FORM_VALID_VEHICLE_DETAILS}),
    formInvalid: () => ({ type: FORM_INVALID_VEHICLE_DETAILS}),
};

// API Call actions
export const API_PENDING_TRANSITION = `${VEHICLE_DETAILS_SET_VALUE}apiTransition/pending`;
export const API_SUCCESS_TRANSITION = `${VEHICLE_DETAILS_SET_VALUE}apiTransition/success`;
export const API_FAILURE_TRANSITION = `${VEHICLE_DETAILS_SET_VALUE}apiTransition/failure`;
export const apiTransition = {
    pending: () => ({
        type: API_PENDING_TRANSITION,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION,
    }),
    failure: (error) => ({
        type: API_FAILURE_TRANSITION,
        error
    }),
};