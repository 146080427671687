export const SAVINGS_GOAL_KEY = 'savingsCalculator/whenWillYouReachYourSavingsGoal';
export const REACH_SAVINGS_GOAL_SET_VALUE = `${SAVINGS_GOAL_KEY}/setValue/`;
export const REACH_SAVINGS_GOAL_SET_VALID = `${SAVINGS_GOAL_KEY}/setValid/`;

export const SAVINGS_GOAL_SET_VALUE = REACH_SAVINGS_GOAL_SET_VALUE + 'savingsGoal';
export const SAVINGS_GOAL_SET_VALID = REACH_SAVINGS_GOAL_SET_VALID + 'savingsGoal';
export const setSavingsGoalValue = value => ({ type: SAVINGS_GOAL_SET_VALUE, value });
export const setSavingsGoalValid = error => ({ type: SAVINGS_GOAL_SET_VALID, error });

export const CURRENT_SAVINGS_GOAL_SET_VALUE = REACH_SAVINGS_GOAL_SET_VALUE + 'currentSavingsGoal';
export const CURRENT_SAVINGS_GOAL_SET_VALID = REACH_SAVINGS_GOAL_SET_VALID + 'currentSavingsGoal';
export const setCurrentSavingsGoalValue = value => ({ type: CURRENT_SAVINGS_GOAL_SET_VALUE, value });
export const setCurrentSavingsGoalValid = error => ({ type: CURRENT_SAVINGS_GOAL_SET_VALID, error });

export const EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE = REACH_SAVINGS_GOAL_SET_VALUE + 'expectedMonthlyContributions';
export const EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID = REACH_SAVINGS_GOAL_SET_VALID + 'expectedMonthlyContributions';
export const setExpectedMonthlyContributionValue = value => ({ type: EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE, value });
export const setExpectedMonthlyContributionValid = error => ({ type: EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID, error });

export const ANNUAL_INCREASE_IN_SAVINGS_SET_VALUE =  REACH_SAVINGS_GOAL_SET_VALUE + 'annualIncreaseInSavings';
export const ANNUAL_INCREASE_IN_SAVINGS_SET_VALID =  REACH_SAVINGS_GOAL_SET_VALID + 'annualIncreaseInSavings';
export const setAnnualIncreaseInSavingsValue = value => ({ type: ANNUAL_INCREASE_IN_SAVINGS_SET_VALUE, value });
export const setAnnualIncreaseInSavingsValid = error => ({ type: ANNUAL_INCREASE_IN_SAVINGS_SET_VALID, error });

export const EXPECTED_SAVINGS_GROWTH_SET_VALUE =  REACH_SAVINGS_GOAL_SET_VALUE + 'expectedSavingsGrowth';
export const EXPECTED_SAVINGS_GROWTH_SET_VALID =  REACH_SAVINGS_GOAL_SET_VALID + 'expectedSavingsGrowth';
export const setExpectedSavingsGrowthValue = value => ({ type: EXPECTED_SAVINGS_GROWTH_SET_VALUE, value });
export const setExpectedSavingsGrowthValid = error => ({ type: EXPECTED_SAVINGS_GROWTH_SET_VALID, error });


export const FORM_VALID_REACH_SAVINGS_GOAL = 'savingsCalculator/whenWillYouReachYourSavingsGoal/formValid';
export const FORM_INVALID_REACH_SAVINGS_GOAL = 'savingsCalculator/whenWillYouReachYourSavingsGoal/formInvalid';

export const REACH_SAVINGS_GOAL_SHOW_RESULT_SUMMARY = `${REACH_SAVINGS_GOAL_SET_VALUE}/showResultsSummary`;
export const setShowResultSummary = () => ({ type: REACH_SAVINGS_GOAL_SHOW_RESULT_SUMMARY });

export const REACH_SAVINGS_GOAL_OPEN_MODAL = `${REACH_SAVINGS_GOAL_SET_VALUE}/openModal`;
export const setOpenModel = () => ({ type: REACH_SAVINGS_GOAL_OPEN_MODAL});

export const REACH_SAVINGS_GOAL_CLOSE_MODAL = `${REACH_SAVINGS_GOAL_SET_VALUE}/closeModal`;
export const setCloseModal = () => ({ type: REACH_SAVINGS_GOAL_CLOSE_MODAL });

export const transitionWhenWillYouReachYourSavingsGoal = {
    formValid: () => ({ type: FORM_VALID_REACH_SAVINGS_GOAL }),
    formInvalid: () => ({ type: FORM_INVALID_REACH_SAVINGS_GOAL }),
};

export const CALCULATE_SAVINGS_GOAL = `${SAVINGS_GOAL_KEY}/calculate/savingsGoal`;
export const calculateSavingsGoal = () => ({ type: CALCULATE_SAVINGS_GOAL });

export const PRODUCT_RECOMMENDATION_SET_VALUE =  REACH_SAVINGS_GOAL_SET_VALUE + 'productRecommendation';
export const setViewRecommendation = () => ({ type: PRODUCT_RECOMMENDATION_SET_VALUE });

export const RESET_CALCULATOR_FORM = 'savingsCalculator/futureValueOfSavings/reset/form';
export const resetCalculatorForm = () => ({ type: RESET_CALCULATOR_FORM });

export const TOGGLE_SHOW_PRODUCT_OFFERING = `${REACH_SAVINGS_GOAL_SET_VALUE}/toggle/showProductOffering`;
export const toggleViewRecommendation = () => ({ type: TOGGLE_SHOW_PRODUCT_OFFERING });