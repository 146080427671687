import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/personalPensionPlan/types';
import { getPersonalPensionPlanState } from './index';

const getRouting = createSelector(getPersonalPensionPlanState, (state) => state.routing);
export const getActivePageIndex = createSelector(getRouting, (routing) => routing.activePageIndex);
export const getDoPersonalPensionPlanNavigation = createSelector(getRouting, (routing) => routing.doNavigation);
export const getDoExitPersonalPensionPlanNavigation = createSelector(getRouting, (routing) => routing.doExitNavigation);

export const getLandingPage = createSelector(getRouting, (routing) => routing.landingPage);

export const getIsDropoffPage = createSelector(
    getRouting,
    (routing) => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU
);

const makeGetIsPage = (pageIndex) => createSelector(getRouting, (routing) => routing.activePageIndex === pageIndex);
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QUOTE);
export const getIsPersonalDetailsPage = makeGetIsPage(PAGE_INDICES.PERSONAL_DETAILS);
export const getIsNextOfKinPage = makeGetIsPage(PAGE_INDICES.NEXT_OF_KIN);
export const getIsBeneficiariesPage = makeGetIsPage(PAGE_INDICES.BENEFICIARIES);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getProgressBarIndex = createSelector(
    getIsQuotePage,
    getIsPersonalDetailsPage,
    getIsNextOfKinPage,
    getIsBeneficiariesPage,
    getIsPaymentPage,
    (isQuotePage, isPersonalDetailsPage, isNextOfKinPage, isBeneficiariesPage, isPaymentPage) => {
        if (isQuotePage) return 0;
        if (isPersonalDetailsPage) return 1;
        if (isNextOfKinPage) return 2;
        if (isBeneficiariesPage) return 3;
        if (isPaymentPage) return 4;
    }
);
