import React from 'react';
import axios from 'axios';

import { logOmError } from './handleClientSideError';

let axiosResponseInterceptor = null;

const windowErrorListener = (event) => {
    logOmError(event.message, 'window event listener', 'none');
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * This function has been left here because without it if any error 
     * is thrown in the child components it would cause a blank white screen.
     * But this function is generally used to ensure that you update the state
     * which would then help render the default UI in the event of any errors.
     */
    static getDerivedStateFromError(error) {
    }

    componentDidMount() {
        /**
         * Assign axios.interceptors.response function to variable so that you can eject the interceptor when the component unmounts
         */
        axiosResponseInterceptor = axios.interceptors.response.use(
            response => response,
            error => {
                const apiEndPoint = error.config.url;
                if (!apiEndPoint || !apiEndPoint.includes('logging-service')) {
                    logOmError(error, 'axios call', apiEndPoint);
                }
                return Promise.reject(error);
            }
        );

        window.addEventListener('error', windowErrorListener);
    }

    componentWillUnmount() {
        axios.interceptors.response.eject(axiosResponseInterceptor);
        window.removeEventListener('error', windowErrorListener);
    }

    componentDidCatch(error, errorInfo) {
        logOmError(error, errorInfo, 'none');
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
