import {
    COLLECT_DOMESTIC_INSURANCE_QUOTE_FORM_DATA,
    COLLECT_SHARE_QUOTE_FORM_DATA,
} from '../../../actions/domesticInsurance/quote';

import {
    TRIGGER_KE_PAYMENTS_STK_PUSH,
    CHECK_PAYMENTS_STATUS,
    TRIGGER_CONFIRM_ORDER,
} from '../../../actions/domesticInsurance/payments';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { orderConfirmationService } from './orderConfirmation';

import { premiumCalculationService } from './premiumCalculation';

import { COLLECT_PAYLOAD_DATA } from '../../../actions/domesticInsurance/payloadCollection';
import { payloadSubmitService } from './payloadCollection';
import { shareQuoteService } from './shareQuote';
import { COLLECT_LOGIN_DETAILS } from '../../../actions/ugMotorInsurance/auth';
import { loginService } from './login';
import { TRIGGER_FETCH_AGENTS } from '../../../actions/domesticInsurance/motorAgentModal';
import { getAgentsService } from './agents';

export const domesticInsuranceCover = (store, next, action) => {
    if (action.type.startsWith(TRIGGER_KE_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }
    if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
        return paymentsStkStatusService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_DOMESTIC_INSURANCE_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {
        return payloadSubmitService(store, next, action);
    }
    if (action.type.startsWith(TRIGGER_FETCH_AGENTS)) {
        return getAgentsService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)) {
        return shareQuoteService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_LOGIN_DETAILS)) {
        return loginService(store, next, action);
    }
    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)) {
        return orderConfirmationService(store, next, action);
    }

    return null;
};
