import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    SMOKING_SET_VALUE,
    SMOKING_SET_VALID,
    COVERED_UP_SET_VALUE,
    COVERED_UP_SET_VALID,
    YEAR_SET_VALUE,
    YEAR_SET_VALID,
    DISABILITY_SET_VALUE,
    DISABILITY_SET_VALID,
    WHICH_FORM_TO_DISPLAY,
    COLLECT_CRITICAL_ILLNESS_PREMIUM_DATA,
    CALCULATE_CRITICAL_ILLNESS_PREMIUM_DATA,
    API_PENDING_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL,
    API_FAILURE_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL,
    FORM_VALID_CRITICAL_ILLNESS_QUOTE_TOOL,
    FORM_INVALID_CRITICAL_ILLNESS_QUOTE_TOOL,
    REINITIALIZE,
} from "../../actions/roaQuoteTools/criticalIllnessQuoteTool";

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    criticalIllnessQuoteToolPremiumAmount: null,
    apiCallStatus: API_CALL_STATUS.IDLE,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Severe Illness',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address']
    },
    form: {
        genderValue: { value: 'M', error: null, status: 'valid' },
        DateOfBirth: { value: null, error: null, status: null },
        smokingValue: { value: 'NS', error: null, status: null },
        coveredupValue: { value: null, error: null, status: null },
        DisabilityValue: { value: 'No', error: null, status: null },
        yearValue: { value: null, error: null, status: null },
    },
};

export default (state = initialeState, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        // Form Validation
        case FORM_VALID_CRITICAL_ILLNESS_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_CRITICAL_ILLNESS_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        // Api call
        case API_PENDING_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        // Gender value input
        case GENDER_SET_VALUE:
            return updateFormField('genderValue', field => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('genderValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // date of birth
        case DOB_SET_VALUE:
            return updateFormField('DateOfBirth', field => ({
                ...field,
                value: action.value,
            }));
        case DOB_SET_VALID:
            return updateFormField('DateOfBirth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Initials
        case SMOKING_SET_VALUE:
            return updateFormField('smokingValue', field => ({
                ...field,
                value: action.value,
            }));
        case SMOKING_SET_VALID:
            return updateFormField('smokingValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Accumalation period
        case COVERED_UP_SET_VALUE:
            return updateFormField('coveredupValue', field => ({
                ...field,
                value: action.value,
            }));
        case COVERED_UP_SET_VALID:
            return updateFormField('coveredupValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // life covered input
        case YEAR_SET_VALUE:
            return updateFormField('yearValue', field => ({
                ...field,
                value: action.value,
            }));
        case YEAR_SET_VALID:
            return updateFormField('yearValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // target fees per year input
        case DISABILITY_SET_VALUE:
            return updateFormField('DisabilityValue', field => ({
                ...field,
                value: action.value,
            }));
        case DISABILITY_SET_VALID:
            return updateFormField('DisabilityValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Select Form
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value
            };

        case REINITIALIZE:
            return initialeState
        case CALCULATE_CRITICAL_ILLNESS_PREMIUM_DATA:
            return {
                ...state,
                criticalIllnessQuoteToolPremiumAmount: action.value
            }
        case COLLECT_CRITICAL_ILLNESS_PREMIUM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        default:
            return state;
    }
}
