import { getPayloadData } from '../../../selectors/personalPensionPlan/payloadCollection';
import { setExpiredAuth } from '../../../actions/personalPensionPlan/quote';
import {
    apiTransitionOrderConfirmation,
    confirmOrderSuccess,
    confirmOrderFailure,
} from '../../../actions/personalPensionPlan/payments';
import { getAccessToken, getIsConfirmOrderAPiPending } from '../../../selectors/personalPensionPlan/payments';
import axios from 'axios';

export const orderConfirmationService = (store, next, action) => {
    (async () => {
        if (!getIsConfirmOrderAPiPending(store.getState())) {
            store.dispatch(apiTransitionOrderConfirmation.pending());
            try {
                const resp = await confirmOrder(store.getState());

                store.dispatch(confirmOrderSuccess(resp.data));
                store.dispatch(apiTransitionOrderConfirmation.success());
            } catch (e) {
                console.error(e);
                if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
                    store.dispatch(setExpiredAuth(true));
                }
                store.dispatch(apiTransitionOrderConfirmation.failure());
                store.dispatch(confirmOrderFailure(e));
            }
        }
    })();
    return next(action);
};

const confirmOrder = async (state) => {
    const loginData = getAccessToken(state);

    const payloadData = getPayloadData(state);

    const id = payloadData?.id;

    try {
        const url = `/om-api/personal-pension-plan/order/confirmation/${id}`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });

        return response;
    } catch (e) {
        if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
            store.dispatch(setExpiredAuth(true));
        }
        console.error(e);
        throw e;
    }
};
