import { createSelector } from "reselect";
import { getRoaQuoteTools } from '../../index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../../../reducers/status";

export const getWholeLifeCoverQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.wholeLifeCoverQuoteTool
);

export const getWholeLifeCoverQuoteToolForm = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => wholeLifeCoverQuoteTool.form
);

export const getIsApiPending = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => wholeLifeCoverQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getWholeLifeCoverQuoteToolFormValid = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => wholeLifeCoverQuoteTool.formStatus === FORM_STATUS.VALID
);

const makeGetFieldForm = fieldId => createSelector(
    getWholeLifeCoverQuoteToolForm,
    form => form[fieldId]
);

export const getSelectedForm = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => wholeLifeCoverQuoteTool.selectedForm
);

export const getRoaCmbObject = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => wholeLifeCoverQuoteTool.roaCmbObject
);

export const getQuotationSummary = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => wholeLifeCoverQuoteTool.quotationSummary
);

export const getShowError = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => wholeLifeCoverQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getCalculateDisabled = createSelector(
    getWholeLifeCoverQuoteTool,
    wholeLifeCoverQuoteTool => {
        return wholeLifeCoverQuoteTool.formStatus === FORM_STATUS.INVALID ||
        wholeLifeCoverQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);

export const getCandidateDOB = makeGetFieldForm('candidateDOB');
export const getGenderValue = makeGetFieldForm('genderValue');
export const getSmokingValue = makeGetFieldForm('smokingValue');
export const getDisabilityValue = makeGetFieldForm('disabilityValue');
export const getCoveredAmount = makeGetFieldForm('coveredAmount');
