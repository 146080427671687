import { transitionDomesticInsuranceQuoteForm } from '../../../actions/domesticInsurance/quote';
import { getQuoteForm } from '../../../selectors/domesticInsurance/quote';
import { FIELD_STATUS } from '../../../reducers/status';

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getQuoteForm(store.getState());
    const quoteFormValid = validateQuoteForm(quoteForm);
    const formAction = quoteFormValid
        ? transitionDomesticInsuranceQuoteForm.formValid()
        : transitionDomesticInsuranceQuoteForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateQuoteForm = (formFields) => {
    const selectedCover = formFields.selectedCover.value;
    if (selectedCover === 'House and Contents') {
        return (
            formFields?.buildingSum?.status === FIELD_STATUS.VALID &&
            formFields?.contentsSum?.status === FIELD_STATUS.VALID &&
            formFields?.coverStartDate?.status === FIELD_STATUS.VALID
        );
    }
    if (selectedCover === 'House Only') {
        return (
            formFields?.buildingSum?.status === FIELD_STATUS.VALID &&
            formFields?.coverStartDate?.status === FIELD_STATUS.VALID
        );
    }
    if (selectedCover === 'Contents Only') {
        return (
            formFields?.contentsSum?.status === FIELD_STATUS.VALID &&
            formFields?.coverStartDate?.status === FIELD_STATUS.VALID
        );
    }
};
