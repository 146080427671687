import { transitionAgentModalForm } from "../../../actions/digitalSavings/agentModal";
import { FIELD_STATUS } from "../../../reducers/status";
import { getAgentForm } from "../../../selectors/digitalSavings/agentModal";


export const agentFormRules = (store, next, action) => {
    const result = next(action);
    const agentForm = getAgentForm(store.getState());
    const agentFormValid = validateAgentFormDetails(agentForm);
    const formAction = agentFormValid
        ? transitionAgentModalForm.formValid()
        : transitionAgentModalForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateAgentFormDetails = (formFields) => {
	return (
		formFields.name &&
		formFields.name.status === FIELD_STATUS.VALID &&
		formFields.agentCode &&
		formFields.agentCode.status === FIELD_STATUS.VALID &&
		formFields.intermediaryType &&
		formFields.intermediaryType.status === FIELD_STATUS.VALID
	);
};

