import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

export function validateInteger(
    value,
    {
        min,
        max,
        subject = 'Whole number',
        required,
        retainSubjectCase
    } = {}
) {

    const regex = new RegExp(/^[0-9]+$/);
    // /^ Start of string
    // \d+ = unlimited digit 
    // \. = 1 point separator 
    // \d+ = unlimited digit 
    // $/ End of string
    const regexDecimalPoint = new RegExp(/^\d+\.\d+$/);
    // /^ Start of string
    // \d+ = unlimited digit
    // \, = 1 comma separator
    // \d+ = unlimited digit 
    // */ End of string
    const regexDecimalComma = new RegExp(/^\d+\,\d+$/);

    if (!value && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    if(regexDecimalPoint.test(value) || regexDecimalComma.test(value)) {
        return OmValidationMessageHelper.WHOLE_NUMBER_ERROR;
    }

    if (!regex.test(value)) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    } 

    const intVal = parseInt(value, 10);
    
    if (min && (intVal < min)) {
        return OmValidationMessageHelper.integerMinMessage(subject, min, retainSubjectCase);
    }

    if (max && (intVal > max)) {
        return OmValidationMessageHelper.integerMaxMessage(subject, max, retainSubjectCase);
    }

    return null;
}

export function validatePercent(
    value,
    {
        subject = 'Percentage',
        required = true,
        retainSubjectCase
    } = {}
) {
    return validateInteger(
        value,
        {
            min: 1,
            max: 100,
            subject,
            required,
            retainSubjectCase
        });
}
