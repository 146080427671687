import { 
    validateName,
    validateAccountNumber,
    validateAlphaNumericString,
    validateSingleSelection } from  "@om/validation";

import {
    setBankNameValid,
    setAccountHolderNameValid,
    presetAccountHolderNameValid,
    setStartMonthValid,
    setDebitOrderDayValid, 
    setAccountTypeValid,
    setAccountNumberValid,

    setEmploymentIndustryValid,
    setSourceOfFundsValid,
    setSourceOfIncomeValid,
    setSourceOfIncomeOtherValid,
    setEmploymentPositionValid,
    setEmploymentIndustryOtherValid,

    BANK_NAME_SET_VALUE,
    ACCOUNT_HOLDER_NAME_SET_VALUE,
    ACCOUNT_HOLDER_NAME_PRESET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    START_MONTH_SET_VALUE,

    SOURCE_OF_INCOME_SET_VALUE, 
    SOURCE_OF_INCOME_OTHER_SET_VALUE, 
    SOURCE_OF_FUNDS_SET_VALUE, 
    EMPLOYMENT_POSITION_SET_VALUE, 
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE,
} from "../../../actions/raOptimal/paymentDetails";

import { validationIsEqual } from "../status";
let prevValidationAction = null;

export const paymentDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case BANK_NAME_SET_VALUE:
                error = validateSingleSelection(action.value, 'Bank name');
                return setBankNameValid(error);

            case ACCOUNT_HOLDER_NAME_PRESET_VALUE:
                error = validateName(action.value, {min: 5, max: 113, subject: 'Account holder name'});
                return presetAccountHolderNameValid(error);

            case ACCOUNT_HOLDER_NAME_SET_VALUE:
                error = validateName(action.value, {min: 5, max: 113, subject: 'Account holder name', required: true});
                return setAccountHolderNameValid(error);

            case ACCOUNT_NUMBER_SET_VALUE:
                error = validateAccountNumber(action.value);
                return setAccountNumberValid(error);
    
            case ACCOUNT_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value, 'Account type');
                return setAccountTypeValid(error);

            case DEBIT_ORDER_DAY_SET_VALUE:
                error = validateSingleSelection(action.value, 'Debit order start date');
                return setDebitOrderDayValid(error);
        
            case START_MONTH_SET_VALUE:
                error = validateSingleSelection(action.value, 'Debit order start month');
                return setStartMonthValid(error);

            // Legal section
            case SOURCE_OF_INCOME_SET_VALUE:
                error = validateSingleSelection(action.value, 'Source of income');
                return setSourceOfIncomeValid(error);

            case SOURCE_OF_INCOME_OTHER_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 254, subject: 'Source of income'});
                return setSourceOfIncomeOtherValid(error);

            case SOURCE_OF_FUNDS_SET_VALUE:
                error = validateSingleSelection(action.value, 'Source of funds');
                return setSourceOfFundsValid(error);
        
            case EMPLOYMENT_POSITION_SET_VALUE:
                error = validateSingleSelection(action.value, 'Employment position');
                return setEmploymentPositionValid(error);

            case EMPLOYMENT_INDUSTRY_SET_VALUE:
                error = validateSingleSelection(action.value, 'Employment industry');
                return setEmploymentIndustryValid(error);
            
            case EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE:
                error = validateAlphaNumericString(action.value, {min: 1, max: 254, subject: 'Employment industry'});
                return setEmploymentIndustryOtherValid(error);
    
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
