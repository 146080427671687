export const PAGE_INDICES = {
    INSURANCE_TYPE: 0,
    HOME_DETAILS: 1,
    HOME_CONTENTS: 2,
    MOTOR_DETAILS: 3,
    PERSONAL_DETAILS: 4,
    QUOTE_SUMMARY: 5,
    CALL_ME_BACK: 6,
    DOCUMENTS_INFO: 7,
    PURACHASE_JOURNEY_PERSONAL_DETAILS: 8,
    ADDITIONAL_DETAILS: 9,
    BANKING_DETAILS: 10,
    UPLOAD_DOCUMENTS: 11,
    CONFIRM_DETAILS: 12,
    SUCCESS_PAGE: 13,
};
export const PRODUCT_PAGE_URL = '/personal/allsure-insurance/';
export const PAGES = [
    {
        URL: '/personal/allsure-insurance/insurance-type',
        TITLE: 'Insurance Type',
    },
    {
        URL: '/personal/allsure-insurance/home-details',
        TITLE: 'Home Details',
    },
    {
        URL: '/personal/allsure-insurance/home-contents',
        TITLE: 'Home Contents',
    },
    {
        URL: '/personal/allsure-insurance/motor-details',
        TITLE: 'Motor Details',
    },
    {
        URL: '/personal/allsure-insurance/personal-details',
        TITLE: 'Personal Details',
    },
    {
        URL: '/personal/allsure-insurance/quote-summary',
        TITLE: 'Quote Summary',
    },
    {
        URL: '/personal/allsure-insurance/call-me-back',
        TITLE: 'Call Me Back',
    },
    {
        URL: '/personal/allsure-insurance/documents-info',
        TITLE: 'Documents Info',
    },
    {
        URL: '/personal/allsure-insurance/purchase-personal-details',
        TITLE: 'Personal Details',
    },
    {
        URL: '/personal/allsure-insurance/product-details',
        TITLE: 'Additional Details',
    },
    {
        URL: '/personal/allsure-insurance/banking-details',
        TITLE: 'Banking details',
    },
    {
        URL: '/personal/allsure-insurance/upload-documents',
        TITLE: 'Upload Documents',
    },
    {
        URL: '/personal/allsure-insurance/confirmation-page',
        TITLE: 'Confirmation Page',
    },
    {
        URL: '/personal/allsure-insurance/success',
        TITLE: 'Success Page',
    },
];

export const PROGRESS_BAR_STEPS = [
    {
        title: 'Home Details',
        key: 'HOME_DETAILS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Home Contents',
        key: 'HOME_CONTENTS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Motor Details',
        key: 'MOTOR_DETAILS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Personal Details',
        key: 'PERSONAL_DETAILS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Quote Summary',
        key: 'QUOTE_SUMMARY',
        isActive: false,
        isCompleted: false,
    },
];
export const PURCHASE_JOURNEY_PROGRESS_BAR_STEPS = [
    {
        title: 'Personal Details',
        key: 'PURACHASE_JOURNEY_PERSONAL_DETAILS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Additional Details',
        key: 'ADDITIONAL_DETAILS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Banking Details',
        key: 'BANKING_DETAILS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Upload Documents',
        key: 'UPLOAD_DOCUMENTS',
        isActive: false,
        isCompleted: false,
    },
    {
        title: 'Confirmation',
        key: 'CONFIRM_DETAILS',
        isActive: false,
        isCompleted: false,
    },
];

export const MOTOR_INSURANCE_TYPE = {
    COMPREHENSIVE: { label: 'Comprehensive', value: 'comprehensive' },
    THIRD_PARTY_ONLY: { label: 'Third party only', value: 'thirdParty' },
    THIRD_PARTY_FIRE_AND_THEFT: { label: 'Third party, fire & theft', value: 'thirdPartyFireTheft' },
};

export const DOCUMENTS = {
    CERTIFIED_COPY_OF_ID: 'copyOfCertifiedId',
    PROOF_OF_RESIDENCE: 'proofOfResidence',
    SOURCE_OF_FUNDS: 'sourceOfFund',
    BANKING_DETAILS: 'bankingDetails',
    HOUSE_VALUATION_REPORT: 'houseValuationReport',
    VEHICLE_REGISTRATION_BOOK: 'vehicleRegistrationBook',
    DRIVERS_LICENSE: 'driverLicense',
    MOTOR_VALUATION: 'motorValuation',
    INVENTORY_VALUATION : 'inventoryValuation'
};
