import { createSelector } from 'reselect';
import { getPersonalAccidentState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getAgent = createSelector(getPersonalAccidentState, (state) => state?.agent);

const makeGetAgentField = (fieldName) => createSelector(getAgent, (agent) => agent[fieldName]);

// get display modal
export const getDisplayForm = makeGetAgentField('displayModal');

// get form
export const getAgentForm = makeGetAgentField('form');

export const getAgentFormValid = createSelector(getAgent, (agent) => agent.formStatus === FORM_STATUS.VALID);
export const getAgents = createSelector(getAgent, (agent) => agent.agents);
export const getAgentsApiStatus = createSelector(getAgent, (agent) => agent.apiCallStatus);
// get form fields
const makeGetFieldForm = (fieldId) => createSelector(getAgentForm, (form) => form[fieldId]);

export const getNameValue = makeGetFieldForm('name');
export const getAgentCodeValue = makeGetFieldForm('agentCode');
export const getIntermediaryType = makeGetFieldForm('intermediaryType');
