import { createSelector } from 'reselect';
import { getAllsureInsurance } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { getGlobalPaymentFrequency } from './homeDetails';
// import { KMF_CLAIM_PAGE_INDICES } from "../../actions/kaMetlhaFuneralClaim/types";

export const getBankingDetails = createSelector(
    getAllsureInsurance,
    (allsureInsurance) => allsureInsurance.bankingDetails
);

export const getBankingDetailsForm = createSelector(getBankingDetails, (bankingDetails) => bankingDetails.form);
export const getBankingDetailsMaped = createSelector(getBankingDetailsForm, (bankingDetailsForm) =>
    Object.keys(bankingDetailsForm)
        .map((item) => ({ label: item, value: bankingDetailsForm[item]?.value }))
        .filter((element) => element.label !== 'paymentConfirmation')
);
export const getIsPersonalDetailsFormValid = createSelector(
    getBankingDetails,
    (bankingDetails) => bankingDetails.formStatus === FORM_STATUS.VALID
);
const inputFieldLabels = {
    bankName: 'Bank Name',
    branchCode: 'Bank Branch',
    accountHolderName: 'Account Holder Name',
    accountNumber: 'Account Number',
    accountType: 'Account Type',
    coverStartDate: 'Cover Start Date',
    debitOrderDay: 'Debit Order Day',
    sourceOfFund: 'Source Of funds',
    otherSourceofFund: 'Source Of funds',
    paymentConfirmation: 'Payment confirmation',
};
export const getBankingDetailsSummary = createSelector(
    getBankingDetailsForm,
    getGlobalPaymentFrequency,
    (bankinDetailsForm, paymentFrequency) => {
        const { sourceOfFund } = bankinDetailsForm;
        const excludeFields = [
            sourceOfFund.displayValue === 'Other' ? 'sourceOfFund' : 'otherSourceofFund',
            'paymentConfirmation',
            paymentFrequency === 'Yearly' ? 'debitOrderDay' : '',
        ];

        return Object.keys(bankinDetailsForm).flatMap((item) => {
            if (excludeFields.includes(item)) return [];
            return {
                label: inputFieldLabels[item],
                value: bankinDetailsForm[item]?.displayValue ?? '-',
            };
        });
    }
);
