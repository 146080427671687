import {HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE} from "../../../actions/savingsCalculator/howMuchYouNeedToSaveCalculator";
import {howMuchYouNeedToSaveCalculatorValidation} from "./howMuchYouNeedToSaveCalculator";
import {REACH_SAVINGS_GOAL_SET_VALUE} from "../../../actions/savingsCalculator/whenWillYouReachYourSavingsGoal";
import {whenWillYouReachYourSavingsGoalValidation} from "./whenWillYouReachYourSavingsGoal";
import {FUTURE_VALUE_OF_SAVINGS_SET_VALUE} from "../../../actions/savingsCalculator/theFutureValueOfYourSavings";
import {theFutureValueOfYourSavingsValidation} from "./theFutureValueOfYourSavings";
import {HOW_MUCH_YOU_NEED_TO_SAVE_ASSUMPTIONS_MODEL_SET_VALUE} from "../../../actions/savingsCalculator/howMuchYouNeedToSaveAssumptionsModel";
import {howMuchYouNeedToSaveAssumptionsModelValidation} from "./howMuchYouNeedToSaveAssumptionsModel"
import {FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALUE} from "../../../actions/savingsCalculator/theFutureValueOfYourSavingsAssumptionsModel";
import {theFutureValueOfSavingsAssumptionsModelValidation} from "./theFutureValueOfYourSavingsAssumptionsModel"
import {REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALUE} from "../../../actions/savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel";
import {whenWillYouReachYourSavingsGoalAssumptionsModelValidation} from "./whenWillYouReachYourSavingsGoalAssumptionsModel"



export const savingsCalculatorValidation = (store, next, action) => {

    if (action.type.startsWith(HOW_MUCH_YOU_NEED_TO_SAVE_CALCULATOR_SET_VALUE))
        return howMuchYouNeedToSaveCalculatorValidation(store, next, action);

    if (action.type.startsWith(REACH_SAVINGS_GOAL_SET_VALUE))
        return whenWillYouReachYourSavingsGoalValidation(store, next, action);

    if (action.type.startsWith(FUTURE_VALUE_OF_SAVINGS_SET_VALUE))
        return theFutureValueOfYourSavingsValidation(store, next, action);

    if (action.type.startsWith(HOW_MUCH_YOU_NEED_TO_SAVE_ASSUMPTIONS_MODEL_SET_VALUE))
        return howMuchYouNeedToSaveAssumptionsModelValidation(store, next, action);

    if (action.type.startsWith(FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALUE))
        return theFutureValueOfSavingsAssumptionsModelValidation(store, next, action);

    if (action.type.startsWith(REACH_SAVINGS_GOAL_ASSUMPTIONS_MODEL_SET_VALUE))
        return whenWillYouReachYourSavingsGoalAssumptionsModelValidation(store, next, action);

    return null;
};
