export const FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_FORM_VALID = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/formValid';
export const FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_FORM_INVALID = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/formInvalid';

export const FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALUE = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/setValue';
export const FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALID = 'savingsCalculator/whenWillYouReachYourSavingsGoalAssumptionsModel/setValid';

export const EXPECTED_ANNUAL_GROWTH_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALUE}/expectedAnnualGrowth`;
export const EXPECTED_ANNUAL_GROWTH_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALID}/expectedAnnualGrowth`;
export const setExpectedAnnualGrowthValue = value => ({type: EXPECTED_ANNUAL_GROWTH_SET_VALUE, value})
export const setExpectedAnnualGrowthValid = error => ({type: EXPECTED_ANNUAL_GROWTH_SET_VALID, error})

export const ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALUE}/annualIncreaseInContribution`;
export const ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_ASSUMPTIONS_MODEL_SET_VALID}/annualIncreaseInContribution`;
export const setAnnualIncreaseInContributionValue = value => ({type: ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE, value})
export const setAnnualIncreaseInContributionValid = error => ({type: ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID, error})
