import { validationIsEqual } from '../status';
import {
    validateName,
    validateSingleSelection,
    validateNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateAlphaNumericString,
} from '@om/validation';
import {
    FINACIAL_INST_INTEREST_SET_VALUE,
    FINACIAL_INSTITUTION_SET_VALUE,
    STREET_OF_PREMISE_SET_VALUE,
    TOWN_OF_PREMISE_SET_VALUE,
    BUILDING_TYPE_SET_VALUE,
    USE_OF_PROPERTY_SET_VALUE,
    NATURE_OF_ROOF_SET_VALUE,
    NATURE_OF_WALL_SET_VALUE,
    LOCATION_SET_VALUE,
    LR_NUMBER_SET_VALUE,
    SECURITY_SET_VALUE,
    PERIMETER_WALL_SET_VALUE,
    LAND_SCAPING_SET_VALUE,
    SWIMMING_POOL_SET_VALUE,
    NO_OF_FLOORS_SET_VALUE,
    setFinacialInterestValid,
    setFinacialInstNameValid,
    setStreetOfPremiseValid,
    setTownOfPremiseValid,
    setBuildingTypeValid,
    setUseOfPropertyValid,
    setNatureOfRoofValid,
    setNatureOfWallValid,
    setLocationValid,
    setLrNumberValid,
    setSecurityValid,
    setPerimeterWallValid,
    setLandScapingValid,
    setSwimmingValid,
    setNoOfFloorsValid,
} from '../../../actions/domesticInsurance/buildingDetails';

let prevValidationAction = null;
export const buildingDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FINACIAL_INST_INTEREST_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Finacial Institution',
                });
                return setFinacialInterestValid(error);

            case FINACIAL_INSTITUTION_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Financial Institution',
                    min: 2,
                    max: 64,
                    required: true
                });
                return setFinacialInstNameValid(error);

            case STREET_OF_PREMISE_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Street Name',
                });
                return setStreetOfPremiseValid(error);

            case TOWN_OF_PREMISE_SET_VALUE:
                error = validateName(action.value, { min: 2, max: 64, subject: 'Street Name' });
                return setTownOfPremiseValid(error);

            case BUILDING_TYPE_SET_VALUE:
                error = validateSingleSelection(action.value, {subject: 'Building Type'});
                return setBuildingTypeValid(error);

            case USE_OF_PROPERTY_SET_VALUE:
                error = validateSingleSelection(action.value, { subject: 'Use of property' });
                return setUseOfPropertyValid(error);

            case NATURE_OF_ROOF_SET_VALUE:
                error = validateSingleSelection(action.value, { subject: 'Nature of roof' });

                return setNatureOfRoofValid(error);

            case NATURE_OF_WALL_SET_VALUE:
                error = validateSingleSelection(action.value, 'Nature of wall');
                return setNatureOfWallValid(error);

            case LOCATION_SET_VALUE:
                error = validateName(action.value, {
                    min: 4,
                    subject: 'Location',
                    required: true,
                });
                return setLocationValid(error);

            case LR_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    min: 1,
                    subject: 'LR',
                    required: true,
                });
                return setLrNumberValid(error);

            case SECURITY_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    min: 4,
                    subject: 'Security in place',
                    required: true,
                });
                return setSecurityValid(error);


            case PERIMETER_WALL_SET_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Perimeter Wall',
                    required: true,
                    min: 1
                });
                return setPerimeterWallValid(error);

            case LAND_SCAPING_SET_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Land Scaping',
                    required: true,
                    min: 1,
                });
                return setLandScapingValid(error);
            case SWIMMING_POOL_SET_VALUE:
                error = validateNumber(action.value, { min: 1, subject: 'Swimming' });
                return setSwimmingValid(error);

            case NO_OF_FLOORS_SET_VALUE:
                error = validateNumber(action.value, { min: 1, subject: 'Number of floors' });
                return setNoOfFloorsValid(error);

            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
