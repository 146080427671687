import { SET_VALUE_ROUTING } from '../../../actions/personalPensionPlan/routing';
import { routingRules } from './routing';

import {
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE,
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
} from '../../../actions/personalPensionPlan/quote';
import { quoteFormRules } from './quote';
import { shareQuoteFormRules } from './shareQuote';

import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from '../../../actions/personalPensionPlan/payments';
import { paymentsFormRules } from './payments';

import {
    PERSONAL_PENSION_PLAN_AGENT_MODAL_DETAILS_SET_VALUE,
    PERSONAL_PENSION_PLAN_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/personalPensionPlan/agentModal';
import { agentModalFormRules } from './agentModal';

import {
    PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE,
    PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID,
} from '../../../actions/personalPensionPlan/personalDetails';
import { personalDetailsFormRules } from './personalDetails';

import {
    PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALID,
    PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE,
} from '../../../actions/personalPensionPlan/documents';
import { documentsFormRules } from './documents';

/*
import {
    PERSONAL_PENSION_PLAN_BENEFICIARIES_FORM_SET_VALUE,
    PERSONAL_PENSION_PLAN_BENEFICIARIES_FORM_SET_VALID,
} from '../../../actions/personalPensionPlan/beneficiaries';
import { beneficiariesFormRules } from './beneficiaries';
*/

import {
    PERSONAL_PENSION_PLAN_NEXT_OF_KIN_FORM_SET_VALID,
    PERSONAL_PENSION_PLAN_NEXT_OF_KIN_FORM_SET_VALUE,
} from '../../../actions/personalPensionPlan/nextOfKin';
import { nextOfKinFormRules } from './nextOfKin';

export const personalPensionPlanRules = (store, next, action) => {
    if (action.type.startsWith(SET_VALUE_ROUTING)) {
        return routingRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID)
    ) {
        return quoteFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID)
    ) {
        return personalDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALID)
    ) {
        return documentsFormRules(store, next, action);
    }

    /*
    if (
        action.type.startsWith(PERSONAL_PENSION_PLAN_BENEFICIARIES_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_PENSION_PLAN_BENEFICIARIES_FORM_SET_VALID)
    ) {
        return beneficiariesFormRules(store, next, action);
    }
    */

    if (
        action.type.startsWith(PERSONAL_PENSION_PLAN_NEXT_OF_KIN_FORM_SET_VALUE) ||
        action.type.startsWith(PERSONAL_PENSION_PLAN_NEXT_OF_KIN_FORM_SET_VALID)
    ) {
        return nextOfKinFormRules(store, next, action);
    }

    if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
        return paymentsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(PERSONAL_PENSION_PLAN_AGENT_MODAL_DETAILS_SET_VALUE) ||
        action.type.startsWith(PERSONAL_PENSION_PLAN_AGENT_MODAL_DETAILS_SET_VALID)
    ) {
        return agentModalFormRules(store, next, action);
    }

    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
        return shareQuoteFormRules(store, next, action);
    }

    return null;
};
