import { SET_PERSISTED_AT } from "../../actions/digitalOnboarding/types";


export const initPersistedAt = (new Date()).getTime();

export default (state = initPersistedAt, action) => {
    switch (action.type){
        case SET_PERSISTED_AT:
            return (new Date()).getTime();
        default:
            return state;
    }
}
