import { transitionSave2gether } from "../../../actions/publicWeb/save2gether";
import { getSave2gether } from "../../../selectors/publicWeb/save2gether";
import { FIELD_STATUS } from "../../../reducers/status";

const isValid = field => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validateSave2gether = save2getherStore => {
    return isValid(save2getherStore.firstName) &&
        isValid(save2getherStore.surname) &&
        isValid(save2getherStore.businessName) &&
        isValid(save2getherStore.phoneNumber) &&
        isValid(save2getherStore.businessNumber) &&
        isValid(save2getherStore.emailAddress) &&
        isValid(save2getherStore.businessRegistered) &&
        isValid(save2getherStore.numberOfEmployees) &&
        isValid(save2getherStore.businessDuration) &&
        isValid(save2getherStore.businessManagement) &&
        isValid(save2getherStore.businessRegisteredAs) &&
        isValid(save2getherStore.hasEmployeeRetirement);
};

export const save2getherRules = (store, next, action) => {
    const result = next(action);

    const save2getherStore = getSave2gether(store.getState());
    const save2getherValid = validateSave2gether(save2getherStore);

    // Form valid
    store.dispatch(save2getherValid
        ? transitionSave2gether.formValid()
        : transitionSave2gether.formInvalid());

    return result;
};
