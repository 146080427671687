import { combineReducers } from "redux";
import routing from './routing';
import idVerification from './idVerification';
import knowledgeBasedAuthentication from './knowledgeBasedAuthentication';
import consent from './consent';
import callMeBack from './callMeBack';
import selfie from './selfie';

export const customerVerificationService = combineReducers({
    routing,
    knowledgeBasedAuthentication,
    idVerification,
    consent,
    callMeBack,
    selfie,
});
