import { 
    BANK_NAME_SET_VALUE,
    ACCOUNT_HOLDER_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    START_MONTH_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    SOURCE_OF_INCOME_SET_VALUE,
    SOURCE_OF_INCOME_OTHER_SET_VALUE,
    SOURCE_OF_FUNDS_SET_VALUE,
    EMPLOYMENT_POSITION_SET_VALUE,
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE,
    INVALID_CONTINUE_TRANSITION_PAYMENT_DETAILS,
    CONTINUE_TRANSITION_PAYMENT_DETAILS,
    BACK_TRANSITION_PAYMENT_DETAIL
} from "../../../actions/raOptimal/paymentDetails";
import { 
    getBankName,
    getAccountHolderName,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getStartMonth,
    getPaymentDetailsForm,
    getPaymentAuthorization,
    getSourceOfIncome,
    getSourceOfIncomeOther,
    getSourceOfFunds,
    getEmploymentPosition,
    getEmploymentIndustry,
    getEmploymentIndustryOther
} from "../../../selectors/raOptimal/paymentDetails";
import { simpleFieldTracking } from "../formTracking";
import { transitionTracking } from "../transitionTracking";
import { findOtherEventIndex, otherEventTracking } from "../otherEventTracking";
import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";

export const paymentDetailsFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case BANK_NAME_SET_VALUE:
                sendError = false;
                return getBankName;
            // causes issues with tracking, removed for now.
            // case ACCOUNT_HOLDER_NAME_SET_VALUE:
            //     sendValue = false;
            //     sendError = false;
            //     return getAccountHolderName;
            case ACCOUNT_NUMBER_SET_VALUE:
                sendValue = false;
                return getAccountNumber;
            case ACCOUNT_TYPE_SET_VALUE:
                sendError = false;
                return getAccountType;
            case DEBIT_ORDER_DAY_SET_VALUE:
                sendError = false;
                return getDebitOrderDay;
            case START_MONTH_SET_VALUE:
                sendError = false;
                return getStartMonth;
            case SOURCE_OF_INCOME_SET_VALUE:
                sendError = false;
                return getSourceOfIncome;
            case SOURCE_OF_INCOME_OTHER_SET_VALUE:
                sendError = false;
                return getSourceOfIncomeOther;
            case SOURCE_OF_FUNDS_SET_VALUE:
                sendError = false;
                return getSourceOfFunds;
            case EMPLOYMENT_POSITION_SET_VALUE:
                sendError = false;
                return getEmploymentPosition;
            case EMPLOYMENT_INDUSTRY_SET_VALUE:
                sendError = false;
                return getEmploymentIndustry;
            case EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE:
                sendError = false;
                return getEmploymentIndustryOther;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        simpleFieldTracking({action, field, sendValue, sendError});
    }

    return result;
};

export const paymentDetailsTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const trackingActions = [
        INVALID_CONTINUE_TRANSITION_PAYMENT_DETAILS,
        CONTINUE_TRANSITION_PAYMENT_DETAILS,
        BACK_TRANSITION_PAYMENT_DETAIL
    ];
    const paymentDetailsForm = getPaymentDetailsForm(state);
    // capture relevant tracking data -- i.e. remove value from form fields
    const trackingData = Object.keys(paymentDetailsForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: paymentDetailsForm[key].status,
                error: paymentDetailsForm[key].error,
            }
        }), {});

    if (trackingActions.includes(action.type))
        transitionTracking(action.type, trackingData);

    return result;
};

export const paymentDetailsOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const field = (() => {
        switch (action.type) {
            case TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS:
                return getPaymentAuthorization(state).toString();
        }
    })();

    if (field) {
        otherEventTracking(action.type, findOtherEventIndex("TOGGLE_CHECKBOX"), field);
    }

    return result;
};
