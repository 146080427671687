import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
import {
	AGENT_NAME_SET_VALUE,
	AGENT_NAME_SET_VALID,
	AGENT_PHONE_NUMBER_SET_VALUE,
	AGENT_PHONE_NUMBER_SET_VALID,
	MODAL_FORM_DISPLAY,
	REINITIALIZE,
	FORM_VALID_CAR_INSURANCE_AGENT_MODAL_FORM,
	FORM_INVALID_CAR_INSURANCE_AGENT_MODAL_FORM,
} from '../../actions/roaCarInsurance/agentReferral';

export const initialState = {
	displayModal: true,
	formStatus: FORM_STATUS.INVALID,
	form: {
		name: { value: null, error: null, status: null },
		agentPhoneNumber: { value: null, error: null, status: null },
	},
};

export default (state = initialState, action) => {
	const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
	const updateFormField = (fieldId, update) => ({
		...state,
		form: {
			...state.form,
			[fieldId]: update(state.form[fieldId]),
		},
	});

	switch (action.type) {
		case FORM_VALID_CAR_INSURANCE_AGENT_MODAL_FORM:
			return { ...state, formStatus: FORM_STATUS.VALID };
		case FORM_INVALID_CAR_INSURANCE_AGENT_MODAL_FORM:
			return { ...state, formStatus: FORM_STATUS.INVALID };
		case MODAL_FORM_DISPLAY:
			return { ...state, displayModal: action.value };

		// Form
		case AGENT_NAME_SET_VALUE:
			return updateFormField('name', field => ({
				...field,
				value: action.value,
			}));

		case AGENT_NAME_SET_VALID:
			return updateFormField('name', field => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		case AGENT_PHONE_NUMBER_SET_VALUE:
			return updateFormField('agentPhoneNumber', field => ({
				...field,
				value: action.value,
			}));

		case AGENT_PHONE_NUMBER_SET_VALID:
			return updateFormField('agentPhoneNumber', field => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));

		// Reset the state
		case REINITIALIZE:
			return initialState;
		default:
			return state;
	}
};
