export const PERSONAL_PENSION_PLAN_DOCUMENTS_FORM = 'personalPensionPlan/documentsForm/';

export const PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM + 'setValue/';
export const PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALID = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM + 'setValid/';

export const FORM_VALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM = 'personalPensionPlan/documentsForm/transition/formValid';
export const FORM_INVALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM =
    'personalPensionPlan/documentsForm/transition/formInValid';

export const REINITIALIZE = 'personalDetails/documentsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM = 'personalPensionPlan/documentsForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM,
    value,
});

export const transitionPersonalPensionPlanDocumentsForm = {
    formValid: () => ({
        type: FORM_VALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_PERSONAL_PENSION_PLAN_DOCUMENTS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_DOCUMENTS_FORM = `${PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DOCUMENTS_FORM = `${PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DOCUMENTS_FORM = `${PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDocumentsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_DOCUMENTS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_DOCUMENTS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_DOCUMENTS_FORM,
    }),
};

//calculated Data
export const COLLECT_DOCUMENTS_FORM_DATA = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DOCUMENTS_SUCCESS = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE + 'success';
export const SUBMIT_DOCUMENTS_FAILURE = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
    type: COLLECT_DOCUMENTS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
    type: SUBMIT_DOCUMENTS_SUCCESS,
    value,
});
export const submitDocumentsFailure = (error) => ({
    type: SUBMIT_DOCUMENTS_FAILURE,
    error,
});

// Form Values
export const ID_UPLOAD_SET_VALUE = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE + 'idUpload';
export const ID_UPLOAD_SET_VALID = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALID + 'idUpload';

export const KRA_PIN_UPLOAD_SET_VALUE = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE + 'kraPinUpload';
export const KRA_PIN_UPLOAD_SET_VALID = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALID + 'kraPinUpload';

// reset individual form value
export const ID_UPLOAD_RESET_VALUE = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE + 'reset/idUpload';
export const KRA_PIN_UPLOAD_RESET_VALUE = PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE + 'reset/kraPinUpload';

// ID upload action creators
export const setIdUploadValue = (value) => ({
    type: ID_UPLOAD_SET_VALUE,
    value,
});

export const setIdUploadValid = (error) => ({
    type: ID_UPLOAD_SET_VALID,
    error,
});

export const resetIdUploadValue = () => ({
    type: ID_UPLOAD_RESET_VALUE,
});

export const setKraPinUploadValue = (value) => ({
    type: KRA_PIN_UPLOAD_SET_VALUE,
    value,
});

export const setKraPinUploadValid = (error) => ({
    type: KRA_PIN_UPLOAD_SET_VALID,
    error,
});

export const resetKraPinUploadValue = () => ({
    type: KRA_PIN_UPLOAD_RESET_VALUE,
});
