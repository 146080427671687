import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    CHILDREN_SET_VALUE,
    CHILDREN_SET_VALID,
    MEDICAL_PROVIDERS_SCOPE_SET_VALUE,
    MEDICAL_PROVIDERS_SCOPE_SET_VALID,
    INPATIENT_COVER_LIMIT_SET_VALUE,
    INPATIENT_COVER_LIMIT_SET_VALID,
    OUTPATIENT_COVER_LIMIT_SET_VALUE,
    OUTPATIENT_COVER_LIMIT_SET_VALID,
    COLLECT_AFYA_IMARA_JUNIOR_DATA,
    CALCULATE_AFYA_IMARA_JUNIOR_DATA,
    FORM_VALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL,
    FORM_INVALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL,
    API_PENDING_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL,
    API_FAILURE_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE
} from "../../actions/roaQuoteTools/afyaImaraJuniorQuoteTool";

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Afyaimara Family Junior',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address']
    },
    form: {
        noOfChildren: { value: null, error: null, status: null },
        medicalProvidersScope: { value: 'None', error: null, status: 'valid' },
        inpatientCoverLimit: {value: null, error: null, status: 'valid'},
        outpatientCoverLimit: { value: null, error: null, status: 'valid' },
    },
};

export default (state = initialeState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    

    switch (action.type) {
        case FORM_VALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case API_PENDING_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        case CHILDREN_SET_VALUE:
            return updateFormField('noOfChildren', field => ({
                ...field,
                value: action.value,
            }));
        case CHILDREN_SET_VALID:
            return updateFormField('noOfChildren', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MEDICAL_PROVIDERS_SCOPE_SET_VALUE:
            return updateFormField('medicalProvidersScope', field => ({
                ...field,
                value: action.value,
            }));
        case MEDICAL_PROVIDERS_SCOPE_SET_VALID:
            return updateFormField('medicalProvidersScope', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case INPATIENT_COVER_LIMIT_SET_VALUE:
            return updateFormField('inpatientCoverLimit', field => ({
                ...field,
                value: action.value,
            }));
        case INPATIENT_COVER_LIMIT_SET_VALID:
            return updateFormField('inpatientCoverLimit', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case  OUTPATIENT_COVER_LIMIT_SET_VALUE:
            return updateFormField('outpatientCoverLimit', field => ({
                ...field,
                value: action.value,
            }));
        case OUTPATIENT_COVER_LIMIT_SET_VALID:
            return updateFormField('outpatientCoverLimit', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value
            };
        case REINITIALIZE:
            return initialeState
        case CALCULATE_AFYA_IMARA_JUNIOR_DATA:
            return {
                ...state,
                premiumPaymentaAmount: action.value
            }
        case COLLECT_AFYA_IMARA_JUNIOR_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        default:
            return state;
    }
}
