import {
    PEOPLE_CARD_MODAL_OPEN_SET_VALUE,
    PEOPLE_CARD_MODAL_CONTENT_SET_VALUE,
} from '../../actions/publicWeb/peopleCardModal'

export const initialState = {
    isOpen: false,
    content: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PEOPLE_CARD_MODAL_OPEN_SET_VALUE:
            return {
                ...state,
                isOpen: action.value
            };
        case PEOPLE_CARD_MODAL_CONTENT_SET_VALUE:
            return {
                ...state,
                content: action.value
            };
        default:
            return state;
    }
}

