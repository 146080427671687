import { initRouting } from './routing';
import { initQuote } from './quote';
import { initPersistedAt } from './persistedAt';
import { initPersonalDetails } from './personalDetails';
import { initUploadDocuments } from './documents';
import { initPayments } from './payment';
import { initAuth } from './auth';
import { initAgents } from './agent';
import { initPayload } from './payloadCollection';

export default () => {
    return {
        routing: initRouting,
        quote: initQuote,
        persistedAt: initPersistedAt,
        personalDetails: initPersonalDetails,
        documents: initUploadDocuments,
        payment: initPayments,
        auth: initAuth,
        agent: initAgents,
        payloadCollection: initPayload,
    };
};
