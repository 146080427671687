import { transitionMedicalHistoryDetails } from '../../../actions/healthInsuranceRW/medicalHistory';
import { getMedicalHistoryForm } from '../../../selectors/healthInsuranceRW/medicalHistory';

import { FIELD_STATUS } from '../../../reducers/status';

export const medicalHistoryFormRules = (store, next, action) => {
	const result = next(action);
	console.log(action, 'kdfjgkdfjg');
	const medicalHistoryForm = getMedicalHistoryForm(store.getState());
	const medicalHistoryFormValid = action.index === 7 ? validateMedicalHistoryOtherFormDetails(medicalHistoryForm) : validateMedicalHistoryFormDetails(medicalHistoryForm);
	const formAction = medicalHistoryFormValid ? transitionMedicalHistoryDetails.formValid() : transitionMedicalHistoryDetails.formInvalid();
	store.dispatch(formAction);
	return result;
};

const validateMedicalHistoryFormDetails = (formFields) => {
	return (
		formFields.relationship &&
		formFields.relationship.status === FIELD_STATUS.VALID &&
		formFields.names &&
		formFields.names.status === FIELD_STATUS.VALID
	);
};

const validateMedicalHistoryOtherFormDetails = (formFields) => {
	return (
		formFields.relationship &&
		formFields.relationship.status === FIELD_STATUS.VALID &&
		formFields.names &&
		formFields.names.status === FIELD_STATUS.VALID &&
		formFields.other &&
		formFields.other.status === FIELD_STATUS.VALID
	);
};