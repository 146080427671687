import { FIELD_STATUS, FORM_STATUS } from "../status";

import {
    EXPECTED_ANNUAL_GROWTH_SET_VALUE,
    EXPECTED_ANNUAL_GROWTH_SET_VALID,
    GROWTH_SET_VALUE,
    GROWTH_SET_VALID,
    PLANNER_SET_VALUE,
    PLANNER_SET_VALID,
    ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE,
    ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID,
    INFLATION_SET_VALUE,
    INFLATION_SET_VALID,
} from '../../actions/savingsCalculator/theFutureValueOfYourSavingsAssumptionsModel'

import {
    RESET_CALCULATOR_FORM
} from '../../actions/savingsCalculator/theFutureValueOfYourSavings'

const theFutureValueOfYourSavingsAssumptionsModel = {
    expectedAnnualGrowth: {value: null, error: null, status: null},
    annualIncreaseInContribution: {value: null, error: null, status: null}
}

export const initialState = {
    formStatus: FORM_STATUS.INVALID,
    form: theFutureValueOfYourSavingsAssumptionsModel
}

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch(action.type) {

        case EXPECTED_ANNUAL_GROWTH_SET_VALUE:
            return updateFormField('expectedAnnualGrowth', field => ({
                ...field,
                value: action.value,
            }));
        case EXPECTED_ANNUAL_GROWTH_SET_VALID:
            return updateFormField('expectedAnnualGrowth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALUE:
            return updateFormField('annualIncreaseInContribution', field => ({
                ...field,
                value: action.value,
            }));
        case ANNUAL_INCREASE_IN_CONTRIBUTIONS_SET_VALID:
            return updateFormField('annualIncreaseInContribution', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RESET_CALCULATOR_FORM: 
            return initialState;

        default:
            return state;
    }
}
