// These library functions are provided by the OMF Lending team
// If there are issues, please contact OMF Lending and Risk

function paymentCalc(presentValue, futureValue, annualInterest, term) {
    const rate = annualInterest/12;
    const temp = Math.pow((1 + rate), term)
    const fact = (1 + rate)*(temp - 1)/rate;
    return -(futureValue + presentValue * temp) / fact
}

function getDeduction(principalAmount, futureValue, annualInterest, term, creditLife, adminFee) {
    const pv = principalAmount + principalAmount / 360 * 45 * annualInterest;
    return paymentCalc(pv, futureValue, annualInterest, term) - principalAmount * creditLife/1000 - adminFee
}

function getInitiationFee(loanamount, vat, minInitiationFee, maxInitiationFee) {
    let fee;
    if (loanamount < 1000) {
        fee = loanamount * vat;
    } else {
        fee = Math.min(maxInitiationFee, (loanamount - 1000) * 0.1 + minInitiationFee);
        fee = fee * (1 + vat);
    }
    return fee;
}

function getPrincipal(loanamount, vat, minInitiationFee, maxInitiationFee) {
    let fee = getInitiationFee(loanamount,vat,minInitiationFee, maxInitiationFee);
    return fee + loanamount;
}

function getCreditLifeFee(principal, creditLifeRate) {
    let creditLifeFee = principal * creditLifeRate/1000;
    return creditLifeFee;
}

function getOfferBound(clRate, adminFee, annualInterest, affordability, minCapital, maxCapital, minTerm, maxTerm, loanAmount=null,
                    loanTerm=null, capitalStepSize= 500, minInitiationFee, maxInitiationFee, vatRate) {
    if ((!loanAmount && !loanTerm) || (loanAmount && loanTerm)) {
        throw 'Must provide either a loanAmount or a loanTerm.';
    }

    if (loanAmount) {
        const range = [];
        const principal = getPrincipal(loanAmount, vatRate, minInitiationFee, maxInitiationFee);
        for (let i = minTerm; i <= maxTerm; i++) {
            const deduction = getDeduction(principal, 0, annualInterest, i, clRate, adminFee)
            if (-deduction <= affordability) {
                range.push(i);
            }
        }

        if (!range.length) {
            return null;
        }

        return {
            minTerm: Math.min(...range),
            maxTerm: Math.max(...range)
        }
    } else {
        const range = [];
        for (let cap = minCapital; cap <= maxCapital; cap += capitalStepSize) {
            const principal = getPrincipal(cap, vatRate, minInitiationFee, maxInitiationFee);
            const deduction = getDeduction(principal, 0, annualInterest, loanTerm, clRate, adminFee)
            if (-deduction <= affordability) {
                range.push(cap);
            }
        }

        if (!range.length) {
            return null;
        }

        return {
            minCapital: Math.min(...range),
            maxCapital: Math.max(...range)
        }
    }
}

// Input function to simplify quote screen details
export function getOfferDetails(offerBaseDetails, loanAmount, loanTerm, capitalStep) {
    const {
        fees: {
            creditLifeRate,
            adminFee,
            minimumInitiationFee,
            maximumInitiationFee
        },
        offer: {
            minimumLoan,
            maximumLoan,
            minimumTerm,
            maximumTerm,
        },
        affordability
    } = offerBaseDetails;

    const percentageInterestRate = offerBaseDetails.interestRate / 100;
    const percentageVatRate = offerBaseDetails.vatRate / 100;

    let calculateOfferOnAmount = loanAmount;
    if (calculateOfferOnAmount === -1) {
        // We don't have a loan amount yet, the minimum qualification amount is used
        calculateOfferOnAmount = minimumLoan;
    } else if (calculateOfferOnAmount > maximumLoan) {
        calculateOfferOnAmount = maximumLoan
    } else if (calculateOfferOnAmount < minimumLoan) {
        calculateOfferOnAmount = minimumLoan;
    }

    let offerTerm = getOfferBound(
        creditLifeRate,
        adminFee,
        percentageInterestRate,
        affordability,
        minimumLoan,
        maximumLoan,
        minimumTerm,
        maximumTerm,
        calculateOfferOnAmount,
        null,
        capitalStep,
        minimumInitiationFee,
        maximumInitiationFee,
        percentageVatRate
    );

    if (loanTerm === -1) {
        // We don't have a loan term yet, the minimum term is used
        loanTerm = offerTerm.minTerm;
    }

    const offerAmount = getOfferBound(
        creditLifeRate,
        adminFee,
        percentageInterestRate,
        affordability,
        minimumLoan,
        maximumLoan,
        minimumTerm,
        maximumTerm,
        null,
        loanTerm,
        capitalStep,
        minimumInitiationFee,
        maximumInitiationFee,
        percentageVatRate
    );

    const principal = getPrincipal(calculateOfferOnAmount, percentageVatRate, minimumInitiationFee, maximumInitiationFee);
    const creditLife = getCreditLifeFee(principal, creditLifeRate);
    const initiationFee = getInitiationFee(calculateOfferOnAmount, percentageVatRate, minimumInitiationFee, maximumInitiationFee);
    const deduction = Math.abs(getDeduction(principal, 0, percentageInterestRate, loanTerm, creditLifeRate, adminFee));

    return {
        ...offerAmount,
        ...offerTerm,
        creditLife,
        initiationFee,
        deduction,
        adminFee,
        loanTerm,
        vatRate: offerBaseDetails.vatRate,
        interestRate: offerBaseDetails.interestRate,
        loanAmount: calculateOfferOnAmount
    };
}

export function getMaxLoanLimit(offerBaseDetails, capitalStep) {
    const {
        fees: {
            creditLifeRate,
            adminFee,
            minimumInitiationFee,
            maximumInitiationFee
        },
        offer: {
            minimumLoan,
            maximumLoan,
            minimumTerm,
            maximumTerm,
        },
        affordability
    } = offerBaseDetails;

    const percentageInterestRate = offerBaseDetails.interestRate / 100;
    const percentageVatRate = offerBaseDetails.vatRate / 100;

    // The BE gives back absolute min/max loan amounts, but the min/max loan term could be capped
    // For instance, the applicant may qualify for R250k, but the employment may cap at 33 months
    // Hence, the loan amount max is incorrect based on the max term of 33 months.

    // Calculated the max loan amount base of the max loan term
    const offerAmount = getOfferBound(
        creditLifeRate,
        adminFee,
        percentageInterestRate,
        affordability,
        minimumLoan,
        maximumLoan,
        minimumTerm,
        maximumTerm,
        null,
        maximumTerm,
        capitalStep,
        minimumInitiationFee,
        maximumInitiationFee,
        percentageVatRate
    );

    return offerAmount;
}