import {createSelector} from "reselect";
import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../reducers/status";

export const getMakeAClaim = state => state.makeAClaim;

export const getMakeAClaimForm = createSelector(
    getMakeAClaim,
    makeAClaim => makeAClaim.form
);


export const getClaimCategories = createSelector(
    getMakeAClaim,
    makeAClaim => makeAClaim.claimCategories
);


export const getClaimTypes = createSelector(
    getMakeAClaim,
    makeAClaim => makeAClaim.claimTypes
);


export const getClaimRequest = createSelector(
    getMakeAClaim,
    makeAClaim => makeAClaim.claimRequest
);

const makeGetFieldForm = (fieldId) =>
    createSelector(getMakeAClaimForm, (form) => form[fieldId]);

export const getMakeAClaimFormDisabled = createSelector(
    getMakeAClaim,
    makeAClaim => {
        return makeAClaim.formStatus === FORM_STATUS.INVALID ||
            makeAClaim.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);

export const getEmailSendingStatusResponse = createSelector(
    getMakeAClaim,
    makeAClaim => makeAClaim.emailSendingStatus.value
);

export const getEmailResponseData = createSelector(
    getMakeAClaim,
    makeAClaim => makeAClaim.emailResponseData
);

export const getApiCallStatus = createSelector(
    getMakeAClaim,
    makeAClaim => makeAClaim.apiCallStatus
);

export const getFirstName = makeGetFieldForm('firstName');
export const getSurname = makeGetFieldForm('surname');
export const getPhoneNumber = makeGetFieldForm('phoneNumber');
export const getEmailAddress = makeGetFieldForm('emailAddress');
export const getPolicyNumber = makeGetFieldForm('policyNumber');
export const getSelectedClaimOrRequest = makeGetFieldForm('selectedClaimOrRequest');
export const getSelectedClaimCategories = makeGetFieldForm('selectedClaimCategories');
export const getSelectedClaimType = makeGetFieldForm('selectedClaimType');
export const getSelectedRequest = makeGetFieldForm('selectedRequest');
export const getMakeAClaimAuthorization = makeGetFieldForm('isAuthorizeMakeAClaim');
