export function getAge(dob) {
    const dobDate = new Date(dob.year, dob.month - 1, dob.day);
    var diff_ms = Date.now() - dobDate.getTime();
    var age_date = new Date(diff_ms);

    //getUTCFullYear: Creates a JavaScript Date instance that represents a single moment in time.
    //Date objects use a Unix Time Stamp, an integer value that is the number of
    //milliseconds since 1 January 1970 UTC. Hence subtraction of 1970
    return Math.abs(age_date.getUTCFullYear() - 1970);
}

export function calculateMonthsFromDOB(dateOfBirth) {
    const currentDate = new Date();
    const dob = new Date(dateOfBirth);
  
    // Calculate the difference in milliseconds
    const timeDiff = currentDate - dob;
  
    // Calculate the difference in months
    const months = Math.floor(timeDiff / (1000*60*60*24*30.44)); // Average days in a month
  
    return months;
}