import {
  NAVIGATE_TO_REPLACEMENT_POLICY,
  NAVIGATE_TO_PERSONAL_DETAILS,
  NAVIGATE_TO_PAYMENT_DETAILS,
  NAVIGATE_TO_CONFIRMATION,
  NAVIGATE_TO_THANK_YOU,
  SET_ACTIVE_PAGE_INDEX,
  SET_NAVIGATION_DONE,
  NAVIGATE_INDEX,
  NAVIGATE_TO_UPLOAD_DOCUMENTS,
} from "../../actions/digiLifeLoanProtection/routing";
import {
  APP_NOT_INITIATED,
  DLP_PAGE_INDICES,
} from "../../actions/digiLifeLoanProtection/types";

export const initRouting = {
  activePageIndex: APP_NOT_INITIATED,
  doNavigation: false,
  doExitNavigation: false,
  parentAppUrl: null,
  callbackAction: null,
  parentApp: null,
};

export default (state = initRouting, action) => {
  const setActivePage = (pageIndex) => ({
    ...state,
    activePageIndex: pageIndex,
    doNavigation: true,
  });

  switch (action.type) {
    case NAVIGATE_INDEX:
      return {
        ...state,
        activePageIndex: action.value,
      };

    case SET_ACTIVE_PAGE_INDEX:
      return {
        ...state,
        doNavigation: true,
        activePageIndex: action.value,
      };

    case SET_NAVIGATION_DONE:
      return { ...state, doNavigation: false };

    case NAVIGATE_TO_REPLACEMENT_POLICY:
      return setActivePage(DLP_PAGE_INDICES.REPLACEMENT_POLICY);

    case NAVIGATE_TO_PERSONAL_DETAILS:
      return setActivePage(DLP_PAGE_INDICES.PERSONAL_DETAILS);

    case NAVIGATE_TO_PAYMENT_DETAILS:
      return setActivePage(DLP_PAGE_INDICES.PAYMENT_DETAILS);

    case NAVIGATE_TO_UPLOAD_DOCUMENTS:
      return setActivePage(DLP_PAGE_INDICES.UPLOAD_DOCUMENTS);

    case NAVIGATE_TO_CONFIRMATION:
      return setActivePage(DLP_PAGE_INDICES.CONFIRMATION);

    case NAVIGATE_TO_THANK_YOU:
      return setActivePage(DLP_PAGE_INDICES.THANK_YOU);

    // TODO: not enabled as CVS is not implemented in EFP
    // case SET_NAVIGATION_TO_CVS_FROM_PARENT_APP:
    //     return {
    //         ...state,
    //         parentAppUrl: action.parentAppUrl,
    //         callbackAction: action.callbackAction,
    //         doExitNavigation: false,
    //         parentApp: action.parentApp
    //     };
    // case SET_NAVIGATION_BACK_TO_PARENT_APP:
    //     return {
    //         ...initRouting,
    //         doExitNavigation: true,
    //         parentAppUrl: state.parentAppUrl,
    //     };

    default:
      return state;
  }
};
