import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/roaTravelInsurance/types';
import { getRoaTravelInsuranceState } from './index';

const getRouting = createSelector(
    getRoaTravelInsuranceState,
    state => state.routing,
);
export const getActivePageIndex = createSelector(
    getRouting,
    routing => routing.activePageIndex,
);
export const getDoTravelInsuranceNavigation = createSelector(
    getRouting,
    routing => routing.doNavigation,
);
export const getDoExitTravelInsuranceNavigation = createSelector(
    getRouting,
    routing => routing.doExitNavigation,
);

export const getLandingPage = createSelector(
    getRouting,
    routing => routing.landingPage,
);

export const getIsDropoffPage = createSelector(
    getRouting,
    routing => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU,
);

const makeGetIsPage = pageIndex =>
    createSelector(
        getRouting,
        routing => routing.activePageIndex === pageIndex,
    );
export const getIsTravelPlanPage = makeGetIsPage(PAGE_INDICES.TRAVEL_PLAN);
export const getIsAboutYouPage = makeGetIsPage(PAGE_INDICES.ABOUT_YOU);
export const getIsTravelDetailsPage = makeGetIsPage(
    PAGE_INDICES.TRAVEL_DETAILS,
);
export const getIsPlansPage = makeGetIsPage(PAGE_INDICES.PLANS);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getProgressBarIndex = createSelector(
    getIsTravelPlanPage,
    getIsAboutYouPage,
    getIsTravelDetailsPage,
    getIsPlansPage,
    getIsPaymentPage,
    (
        isTravelPlanPage,
        isAboutYouPage,
        isTravelDetailsPage,
        isPlansPage,
        isPaymentPage,
    ) => {
        if (isTravelPlanPage) return 0;
        if (isAboutYouPage) return 1;
        if (isTravelDetailsPage) return 2;
        if (isPlansPage) return 3;
        if (isPaymentPage) return 4;
    },
);
