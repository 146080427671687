import {dropOffLeadPersonalDetails} from "../../../actions/easiplusFuneralPlan/personalDetails";
import {
    getDropOffDetails,
    getFirstNames,
    getSurname,
    getMobile,
    getAreDropoffDetailsValid
} from "../../../selectors/easiplusFuneralPlan/personalDetails";
import axios from "axios";

export const dropOffLeadsService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const dropOffDetailsValid = getAreDropoffDetailsValid(state);
    if (!dropOffDetailsValid) return result;

    const newDropOffDetails = buildPayload(state);
    const previousDropOffDetails = getDropOffDetails(state);
    const dropOffDetailsChanged = JSON.stringify(previousDropOffDetails) !== JSON.stringify(newDropOffDetails);

    if (!dropOffDetailsChanged) return result;

    (async () => {
        if (await submitDropOffLead(newDropOffDetails)) {
            store.dispatch(dropOffLeadPersonalDetails(newDropOffDetails));
        }
    })();

    return result;
};

export const submitDropOffLead = async payload => {
    try {
        const url = '/om-api/easiplus-funeral-plan/drop-off-leads';
        const config = {headers: {'Content-Type': 'application/json'}};
        const response = await axios.post(url, payload, config);
        return response.status === 200;
    } catch (error) {
        return false;
    }
};

export const buildPayload = state => ({
    firstname: getFirstNames(state).value,
    lastName: getSurname(state).value,
    cellphoneNumber: getMobile(state).value.replace(/\s/g, ''),
    projectCode: "EasiPlusFuneralPlan"
});
