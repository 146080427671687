import {PAYMENTS_SET_VALUE} from "../../../actions/roaCarInsurance/payments";
import {paymentsFormValidation} from "./payments";
import { QUOTATION_SET_VALUE, QUOTATION_SET_NG_VALUE } from '../../../actions/roaCarInsurance/quotation';
import { quotationValidation } from './quotation';
import {ABOUT_YOU_SET_VALUE} from "../../../actions/roaCarInsurance/aboutYou";
import {aboutYouValidation} from "./aboutYou";
import {DETAILS_SET_VALUE} from  "../../../actions/roaCarInsurance/details";
import {carDetailsValidation} from './carDetails'
import {agentModalFormValidation} from './agentReferral'
import { CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/roaCarInsurance/agentReferral';
import { VEHICLE_DETAILS_SET_VALUE } from "../../../actions/roaCarInsurance/vehicleDetails";
import { vehicleDetailsValidation } from "./vehicleDetails";
import { CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE } from '../../../actions/roaCarInsurance/manualPayments';
import { manualPayments } from './manualPayments';

export const roaCarInsuranceValidation = (store, next, action) => {
    if(action.type.startsWith(CAR_INSURANCE_MANUAL_MPESA_PAYMENT_SET_VALUE)){
        return manualPayments(store, next, action);
    }
    if (action.type.startsWith(QUOTATION_SET_VALUE) || action.type.startsWith(QUOTATION_SET_NG_VALUE)) {
        return quotationValidation(store, next, action);
    }
    if(action.type.startsWith(ABOUT_YOU_SET_VALUE)) {
    	return aboutYouValidation(store, next, action)
	}
    if(action.type.startsWith(PAYMENTS_SET_VALUE)){
         return paymentsFormValidation(store, next, action);
    }  
    if(action.type.startsWith(DETAILS_SET_VALUE)){
         return carDetailsValidation(store, next, action);
    }  
	if (action.type.startsWith(CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE)) {
		return agentModalFormValidation(store, next, action);
	}
    if (action.type.startsWith(VEHICLE_DETAILS_SET_VALUE)) {
        return vehicleDetailsValidation(store, next, action);
    }
    return null;
};
