import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    FORM_VALID_PERSONAL_ACCIDENT_QUOTE_FORM,
    FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_FORM,
    API_FAILURE_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM,
    API_SUCCESS_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM,
    API_PENDING_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM,
    COLLECT_PERSONAL_ACCIDENT_QUOTE_FORM_DATA,
    SUBMIT_PERSONAL_ACCIDENT_QUOTE_SUCCESS,
    SUBMIT_PERSONAL_ACCIDENT_QUOTE_FAILURE,
    PERSONAL_ACCIDENT_SIDEBAR_OPEN_VALUE,
    PERSONAL_ACCIDENT_SHARE_QUOTE_VALUE,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
    FORM_VALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM,
    FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    SHARE_PERSONAL_ACCIDENT_QUOTE_SUCCESS,
    SHARE_PERSONAL_ACCIDENT_QUOTE_FAILURE,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_COVER_OPTIONS,
    API_PENDING_TRANSITIONS_COVER_OPTIONS,
    API_SUCCESS_TRANSITIONS_COVER_OPTIONS,
    TOKEN_IS_EXPIRED,
    setExpiredAuth,
    //new values
    DOB_SET_VALUE,
    DOB_SET_VALID,
    COVER_LIMIT_SET_VALUE,
    COVER_LIMIT_SET_VALID,
    PROFESSION_SET_VALUE,
    PROFESSION_SET_VALID,
    SPOUSE_INCLUDED_SET_VALUE,
    SPOUSE_INCLUDED_SET_VALID,
    SPOUSE_DOB_SET_VALUE,
    SPOUSE_DOB_SET_VALID,
    CHILDREN_INCLUDED_SET_VALUE,
    CHILDREN_INCLUDED_SET_VALID,
    CHILDREN_DOBS_SET_VALUE,
    CHILDREN_DOBS_SET_VALID,
    REMOVE_CHILD_DOB,
    SET_COVER_TYPE,
    SET_COVER_OPTION_INDEX,
    PERSONAL_ACCIDENT_QUOTE_ADD_COVER,
    PERSONAL_ACCIDENT_QUOTE_SHOW_COVER_OPTIONS,
    SELECTED_COVER_OPTION_SET_VALUE,
    SET_COVER_OPTIONS_LIST_VALID,
    SET_COVER_OPTIONS_LIST_VALUE,
    SELECTED_CHILD_COVER_OPTION_SET_VALUE,
    SET_CALCULATED_PREMIUM,
    SET_COVER_LIMITS,
} from '../../actions/personalAccident/quote';

export const initQuote = {
    sidebarOpen: false,
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    coverOptionsApiStatus: null,
    quoteReferenceData: null,
    addedCover: null,
    showCoverOptions: false,
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    quoteShared: null,
    isTokenExpired: false,
    valuers: null,
    coverType: 'adult',
    coverOptionIndex: 0,
    ugCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Personal Accident Kenya',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    premiumCalculation: {
        value: null,
        status: null,
        error: null,
    },
    selectedCoverOption: { value: null, status: null, error: null },
    selectedChildCoverOption: { value: null, status: null, error: null },
    coverLimits: [],
    form: {
        shareEmail: { value: null, error: null, status: null },
        //new values
        dob: { value: null, error: null, status: null },
        coverLimit: { value: null, error: null, status: null },
        profession: { value: null, error: null, status: null },
        spouseIncluded: { value: null, error: null, status: null },
        spouseDob: { value: null, error: null, status: null },
        childrenIncluded: { value: null, error: null, status: null },
        childrenDobs: { value: [], error: null, status: null },
        coverOptions: { value: null, error: null, status: null },
    },
};

export default (state = initQuote, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_PERSONAL_ACCIDENT_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };

        case FORM_VALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        // Sidebar open
        case PERSONAL_ACCIDENT_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        // Share modal
        case PERSONAL_ACCIDENT_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_PERSONAL_ACCIDENT_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_PERSONAL_ACCIDENT_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_COVER_OPTIONS_LIST_VALUE:
            return updateFormField('coverOptions', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_COVER_OPTIONS_LIST_VALID:
            return updateFormField('coverOptions', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case TOKEN_IS_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.value,
            };

        // API Calls

        case API_PENDING_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_COVER_OPTIONS:
            return {
                ...state,
                coverOptionsApiStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_COVER_OPTIONS:
            return {
                ...state,
                coverOptionsApiStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_COVER_OPTIONS:
            return {
                ...state,
                coverOptionsApiStatus: API_CALL_STATUS.FAILURE,
            };

        // Submit user data
        case COLLECT_PERSONAL_ACCIDENT_QUOTE_FORM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case SUBMIT_PERSONAL_ACCIDENT_QUOTE_SUCCESS:
            return {
                ...state,
                quoteReferenceData: action.value,
            };

        case SUBMIT_PERSONAL_ACCIDENT_QUOTE_FAILURE:
            return {
                ...state,
                quoteReferenceData: action.error,
            };

        case SELECTED_COVER_OPTION_SET_VALUE:
            return {
                ...state,
                selectedCoverOption: {
                    ...state.selectedCoverOption,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        case SELECTED_CHILD_COVER_OPTION_SET_VALUE:
            return {
                ...state,
                selectedChildCoverOption: {
                    ...state.selectedChildCoverOption,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        case SET_CALCULATED_PREMIUM:
            return {
                ...state,
                premiumCalculation: {
                    ...state.premiumCalculation,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        case SET_COVER_LIMITS:
            return {
                ...state,
                coverLimits: action.value,
            };

        case SET_COVER_TYPE:
            return {
                ...state,
                coverType: action.value,
            };

        case SET_COVER_OPTION_INDEX:
            return {
                ...state,
                coverOptionIndex: action.value,
            };

        //new values
        case DOB_SET_VALUE:
            return updateFormField('dob', (field) => ({
                ...field,
                value: action.value,
            }));

        case DOB_SET_VALID:
            return updateFormField('dob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case COVER_LIMIT_SET_VALUE:
            return updateFormField('coverLimit', (field) => ({
                ...field,
                value: action.value,
            }));

        case COVER_LIMIT_SET_VALID:
            return updateFormField('coverLimit', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PROFESSION_SET_VALUE:
            return updateFormField('profession', (field) => ({
                ...field,
                value: action.value,
            }));

        case PROFESSION_SET_VALID:
            return updateFormField('profession', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SPOUSE_INCLUDED_SET_VALUE:
            return updateFormField('spouseIncluded', (field) => ({
                ...field,
                value: action.value,
            }));

        case SPOUSE_INCLUDED_SET_VALID:
            return updateFormField('spouseIncluded', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SPOUSE_DOB_SET_VALUE:
            return updateFormField('spouseDob', (field) => ({
                ...field,
                value: action.value,
            }));

        case SPOUSE_DOB_SET_VALID:
            return updateFormField('spouseDob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CHILDREN_INCLUDED_SET_VALUE:
            return updateFormField('childrenIncluded', (field) => ({
                ...field,
                value: action.value,
            }));

        case CHILDREN_INCLUDED_SET_VALID:
            return updateFormField('childrenIncluded', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CHILDREN_DOBS_SET_VALUE:
            const { index, newValue } = action.value;

            if (typeof index === 'undefined') {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        childrenDobs: {
                            ...state.form.childrenDobs,
                            value: [...state.form.childrenDobs.value, newValue],
                        },
                    },
                };
            } else {
                const updatedValueArray = [...state.form.childrenDobs.value];
                updatedValueArray[index] = newValue;

                return {
                    ...state,
                    form: {
                        ...state.form,
                        childrenDobs: {
                            ...state.form.childrenDobs,
                            value: updatedValueArray,
                        },
                    },
                };
            }

        case REMOVE_CHILD_DOB:
            const indexToRemove = action.value;

            if (typeof indexToRemove === 'number' && indexToRemove < state.form.childrenDobs.value.length) {
                const valueArray = [...state.form.childrenDobs.value];

                valueArray.splice(indexToRemove, 1);

                return {
                    ...state,
                    form: {
                        ...state.form,
                        childrenDobs: {
                            ...state.form.childrenDobs,
                            value: valueArray,
                        },
                    },
                };
            }

            return state;

        case CHILDREN_DOBS_SET_VALID:
            return updateFormField('childrenDobs', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PERSONAL_ACCIDENT_QUOTE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };

        case PERSONAL_ACCIDENT_QUOTE_SHOW_COVER_OPTIONS:
            return {
                ...state,
                showCoverOptions: action.value,
            };

        // Reset state
        case REINITIALIZE:
            return initQuote;
        default:
            return state;
    }
};
