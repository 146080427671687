import axios from 'axios';
import { getJhbSharePriceFetching, getLdnSharePriceFetching } from '../../../selectors/publicWeb/sharePrice';
import { setIndicators, setJhbSharePrice, setLdnSharePrice, setJhbSharePriceFetching, setLdnSharePriceFetching } from '../../../actions/publicWeb/sharePrice';

const headers = {
    'Access-Control-Allow-Origin': '*',
};


export const requestJhbSharePrice = async (store) => {
    const state = store.getState();
    const isJhbFetching = getJhbSharePriceFetching(state);

    if (localStorage.getItem('jhbSharePrice')
        && Date.now() < new Date(localStorage.getItem('jhbSharePriceExpireTime'))
    ) {
        //get from local storage
        store.dispatch(setJhbSharePrice(JSON.parse(localStorage.getItem('jhbSharePrice'))));
    } else if (!isJhbFetching) {
        //get from api after 7 mins
        setJhbSharePriceFetching(true);
        var expiredTime = new Date();
        expiredTime.setMinutes(expiredTime.getMinutes() + 20);
        await axios.get('/om-api/jhb-share-price', headers)
            .then((result) => {
                localStorage.setItem('jhbSharePrice', JSON.stringify(result.data));
                localStorage.setItem('jhbSharePriceExpireTime', expiredTime);
                store.dispatch(setJhbSharePrice(result.data));
            })
            .catch((error) => {
                localStorage.removeItem('jhbSharePrice');
                localStorage.removeItem('jhbSharePriceExpireTime');
                store.dispatch(setJhbSharePrice(null));
            }).finally(() => {
                setJhbSharePriceFetching(false);
            });
    }
}

export const requestLdnSharePrice = async (store) => {
    const state = store.getState();
    const isLdnFetching = getLdnSharePriceFetching(state);
    if (localStorage.getItem('ldnSharePrice')
        && Date.now() < new Date(localStorage.getItem('ldnSharePriceExpireTime'))
    ) {
        store.dispatch(setLdnSharePrice(JSON.parse(localStorage.getItem('ldnSharePrice'))));
    } else if (!isLdnFetching) {
        setLdnSharePriceFetching(true);
        var expiredTime = new Date();
        expiredTime.setMinutes(expiredTime.getMinutes() + 20);
        await axios.get('/om-api/ldn-share-price', headers)
            .then((result) => {
                localStorage.setItem('ldnSharePrice', JSON.stringify(result.data));
                localStorage.setItem('ldnSharePriceExpireTime', expiredTime);
                store.dispatch(setLdnSharePrice(result.data));
            })
            .catch((error) => {
                localStorage.removeItem('ldnSharePrice');
                localStorage.removeItem('ldnSharePriceExpireTime');
                store.dispatch(setLdnSharePrice(null));
            }).finally(() => {
                setLdnSharePriceFetching(false);
            });
    }
}

export const requestIndicators = async (store) => {
    if (localStorage.getItem('indicators')
        && Date.now() < new Date(localStorage.getItem('indicatorsExpireTime'))
    ) {
        store.dispatch(setIndicators(JSON.parse(localStorage.getItem('indicators'))));
    } else {
        var expiredTime = new Date();
        expiredTime.setMinutes(expiredTime.getMinutes() + 20);
        axios.get('/om-api/indicators', headers)
            .then((result) => {
                localStorage.setItem('indicators', JSON.stringify(result.data));
                localStorage.setItem('indicatorsExpireTime', expiredTime);
                store.dispatch(setIndicators(result.data));
            })
            .catch((error) => {
                localStorage.removeItem('indicators');
                localStorage.removeItem('indicatorsExpireTime');
                store.dispatch(setIndicators(null));
            });
    }
}