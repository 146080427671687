import { createSelector } from 'reselect';
import {getCustomerSatisfactionSurvey} from './index'
import { FORM_STATUS } from '../../reducers/status';
import { API_CALL_STATUS } from '../../reducers/status';

export const getCustomerSatisfactionSubmitPage = createSelector(
	getCustomerSatisfactionSurvey,
	(customerSatisfaction) => customerSatisfaction.customerSatisfactionSubmitForm
);

export const getCustomerSatisfactionSubmitPageForm = createSelector(
    getCustomerSatisfactionSubmitPage,
    customerSatisfaction => customerSatisfaction.form
);

export const getCustomerSatisfactionSubmitPageInvalid = createSelector(
	getCustomerSatisfactionSubmitPage,
	(customerSatisfaction) => customerSatisfaction.formStatus === FORM_STATUS.INVALID
);

export const getCustomerSatisfactionRequestObject = createSelector(
	getCustomerSatisfactionSubmitPage,
	(customerSatisfaction) => ({
        intId: customerSatisfaction.form.customerQueryParams.intId,
        sysId: customerSatisfaction.form.customerQueryParams.sysId,
        score: customerSatisfaction.form.customerQueryParams.score,
        questionId: customerSatisfaction.form.customerQueryParams.qId,
        comments: customerSatisfaction.form.customerFeedback.value
    })
);

const makeGetField = (fieldId) => createSelector(
    getCustomerSatisfactionSubmitPageForm,
    (customerSatisfaction) => customerSatisfaction[fieldId]
);

export const getCustomerFeedback = makeGetField('customerFeedback')

export const getApiCallStatus = createSelector(
    getCustomerSatisfactionSubmitPage,
    (customerSatisfaction) => customerSatisfaction.apiCallStatus
);

export const getInputDisabled  = createSelector(
    getCustomerSatisfactionSubmitPage,
    (customerSatisfaction) => customerSatisfaction.apiCallStatus === API_CALL_STATUS.PENDING
        || customerSatisfaction.apiCallStatus === API_CALL_STATUS.SUCCESS
)

export const getIsApiCallSuccess = createSelector(
    getApiCallStatus,
    (apiCallStatus) => apiCallStatus === API_CALL_STATUS.SUCCESS
);

export const getIsApiPending = createSelector(
    getApiCallStatus,
    (apiCallStatus) => apiCallStatus === API_CALL_STATUS.PENDING
);

export const getIsApiFailure = createSelector(
    getApiCallStatus,
    (apiCallStatus) => apiCallStatus === API_CALL_STATUS.FAILURE
);

export const getButtonText = createSelector(
    getCustomerSatisfactionSubmitPage,
    (customerSatisfaction) => {
        switch (customerSatisfaction.apiCallStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'Submitted';
            case API_CALL_STATUS.FAILURE:
                return 'Try Again';
            case API_CALL_STATUS.PENDING:
                return 'Submitting...';
            default:
                return 'Submit Feedback';
        }
    }
);
