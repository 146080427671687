import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/healthInsuranceRW/types';
import { getHealthInsuranceRwState } from './index';

const getRouting = createSelector(getHealthInsuranceRwState, (state) => state.routing);
export const getActivePageIndex = createSelector(getRouting, (routing) => routing.activePageIndex);
export const getDoHealthInsuranceRwNavigation = createSelector(getRouting, (routing) => routing.doNavigation);
export const getDoExitHealthInsuranceRwNavigation = createSelector(getRouting, (routing) => routing.doExitNavigation);

export const getLandingPage = createSelector(getRouting, (routing) => routing.landingPage);

export const getIsDropoffPage = createSelector(
    getRouting,
    (routing) => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU
);

const makeGetIsPage = (pageIndex) => createSelector(getRouting, (routing) => routing.activePageIndex === pageIndex);
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QOUTE);
export const getIsLivedCoveredPage = makeGetIsPage(PAGE_INDICES.LIVES_COVERED);
export const getIsMedicalHistoryPage = makeGetIsPage(PAGE_INDICES.MEDICAL_HISTORY);
export const getIsDocumentsPage = makeGetIsPage(PAGE_INDICES.DOCUMENTS);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getProgressBarIndex = createSelector(
    getIsQuotePage,
    getIsLivedCoveredPage,
    getIsMedicalHistoryPage,
    getIsDocumentsPage,
    getIsPaymentPage,
    (isQuotePage, isLivesCoveredPage, isMedicalHistoryPage, isDocumentsPage, isPaymentPage) => {
        if (isQuotePage) return 0;
        if (isLivesCoveredPage) return 1;
        if (isMedicalHistoryPage) return 2;
        if (isDocumentsPage) return 3;
        if (isPaymentPage) return 4;
    }
);
