import {validateCellphoneNumber,validateEmail} from "@om/validation";
import {setPhoneNumberValid, PHONE_NUMBER_SET_VALUE, setEmailValid, EMAIL_SET_VALUE} from "../../../actions/roaCarInsurance/payments";
import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const paymentsFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
           case PHONE_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(action.value,  {required: true, subject: 'Payments phone number'});
                return setPhoneNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, { required: true, subject: 'Email' });
                return setEmailValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
