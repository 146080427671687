import axios from 'axios';
import { getVehicleValuation } from '../../../selectors/roaCarInsurance/quotation';
import {
    getPaymentsForm,
    getIsManualPaymentsApiPending,

} from '../../../selectors/roaCarInsurance/payments';

import {
    submitCarInsuranceManualPaymentsFailure,
    submitCarInsuranceManualPaymentsSuccess,
    apiTransitionManualPaymentsForm
} from '../../../actions/roaCarInsurance/manualPayments';


export const carInsuranceManualMpesaPaymentService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    (async () => {
        if (!getIsManualPaymentsApiPending(state)) {
            store.dispatch(apiTransitionManualPaymentsForm.pending());
            try {
                const resp = await mpesaTransactionCodeCheck(state);
                if(resp.data.error){
                    throw {message: 'Something went wrong. Try again.'};
                }
                if(resp.data.status !== 0){
                    throw {message: `Transaction with the specified ID could not be found.`}
                }
                if(resp.data.status === 0){
                    const successMessage = {message: 'Congratulations your payments have been recieved.'}
                    store.dispatch(submitCarInsuranceManualPaymentsSuccess(successMessage));
                    store.dispatch(apiTransitionManualPaymentsForm.success());
                }

            } catch (e) {
                store.dispatch(submitCarInsuranceManualPaymentsFailure(e));
                store.dispatch(apiTransitionManualPaymentsForm.failure());
            }
        }
    })();
    return result;
};

const mpesaTransactionCodeCheck = async (state) => {
    const form = getPaymentsForm(state);
    const vehicleValuation = getVehicleValuation(state);
    const payload = {
        transactionId: form.transactionCode.value,
        MSIDN: form.phoneNumber.value,
        session_id: vehicleValuation.value.session_id
    };
    try {
        const url = '/om-api/roa-travel-insurance/mpesa-transaction-status';
        return await axios.post(url, payload);
    } catch (e) {
        throw e;
    }
};

