export const DOMESTIC_INSURANCE_FORM = 'domesticInsurance/quoteForm/';
export const UG_MOTOR_QOUTE_FORM = 'domesticInsurance/quoteForm/';
export const DOMESTIC_INSURANCE_FORM_SET_VALUE = DOMESTIC_INSURANCE_FORM+ 'setValue';
export const DOMESTIC_INSURANCE_FORM_SET_VALID = DOMESTIC_INSURANCE_FORM+ 'setValid'
export const REINITIALIZE = DOMESTIC_INSURANCE_FORM + '/state/reinitialize';
export const FORM_VALID_DOMESTIC_INSURANCE_FORM = DOMESTIC_INSURANCE_FORM+ 'formValid'
export const FORM_INVALID_DOMESTIC_INSURANCE_FORM = DOMESTIC_INSURANCE_FORM + 'formInValid';
export const FORM_VALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM = DOMESTIC_INSURANCE_FORM + 'shareFormValid';
export const FORM_INVALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM = DOMESTIC_INSURANCE_FORM + 'shareFormValid';

export const SET_SHARE_EMAIL_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'shareEmailValid';

// VALIDATE QUOTE FORM

export const transitionDomesticInsuranceQuoteForm = {
    formValid: () => ({
        type: FORM_VALID_DOMESTIC_INSURANCE_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DOMESTIC_INSURANCE_FORM,
    }),
};

export const transitionShareQuoteForm = {
    formInvalid: () => ({
        type: FORM_VALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM,
    }),
    formValid: () => ({
        type: FORM_INVALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_DOMESTIC_INSURANCE_QUOTE_FORM = `${DOMESTIC_INSURANCE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DOMESTIC_INSURANCE_QUOTE_FORM = `${DOMESTIC_INSURANCE_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DOMESTIC_INSURANCE_QUOTE_FORM = `${DOMESTIC_INSURANCE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${DOMESTIC_INSURANCE_FORM_SET_VALUE}/share/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${DOMESTIC_INSURANCE_FORM_SET_VALUE}/share/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${DOMESTIC_INSURANCE_FORM_SET_VALUE}/share/apiTransition/failure`;


export const apiTransitionDomesticInsuranceQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_DOMESTIC_INSURANCE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_DOMESTIC_INSURANCE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_DOMESTIC_INSURANCE_QUOTE_FORM,
    }),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};

// //calculated Data
export const COLLECT_DOMESTIC_INSURANCE_QUOTE_FORM_DATA = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'collectDomesticInsuranceQuoteData';
export const DOMESTIC_INSURANCE_ADD_COVER = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'addCover'
// export const COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceQuoteData';
// export const COLLECT_KE_THIRD_PARTY_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceThirdPartyQuoteData';
// export const SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'success';
// export const SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE = UG_MOTOR_QOUTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_DOMESTIC_INSURANCE_PREMIUM_SUCCESS = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_DOMESTIC_INSURANCE_PREMIUM_FAILURE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'failure';
export const COLLECT_SHARE_QUOTE_FORM_DATA = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_MOTOR_INSURANCE_QUOTE_FAILURE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'shareQuoteFailure';

export const collectDomesticInsuranceQuoteData = () => ({
    type: COLLECT_DOMESTIC_INSURANCE_QUOTE_FORM_DATA,
});

// export const collectMotorInsuranceKeQuoteData = () => ({ type: COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA });
// export const collectThirdPartyQuoteData = () => ({ type: COLLECT_KE_THIRD_PARTY_DATA});
export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

// export const submitMotorInsuranceQuoteSuccess = () => ({
// 	type: SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS,
// 	value,
// });

export const shareQuoteSuccess = (value) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    value,
})

export const shareQuoteFailure = (error) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    error,
})

export const calculateDomesticInsurancePremiumSuccess = (value) => ({
    type: CALCULATE_DOMESTIC_INSURANCE_PREMIUM_SUCCESS,
    value,
});

// export const submitMotorInsuranceQuoteFailure = () => ({
// 	type: SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE,
// 	error,
// });

export const addCover = (value) => ({
	type: DOMESTIC_INSURANCE_ADD_COVER,
	value,
})

// Form values
export const SET_BUILDING_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'buildingValue';
export const SET_BUILDING_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'buildingValid';
export const SET_DOMESTIC_EARTHQUAKE_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'earthQuakeValue';
export const SET_BELONGINGS_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'belongingsValue';
export const SET_BELONGINGS_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'belongingsValid';
export const SET_COVER_START_DATE_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'coverStartDateValue';
export const SET_COVER_START_DATE_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'coverStartDateValid';

export const SET_ALL_RISK_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'allRiskValue';
export const SET_ALL_RISK_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'allRiskValid';
export const SET_PORTABLE_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'portableValue';
export const SET_PORTABLE_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'portableValid';

export const SET_DOMESTIC_SERVANTS_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'domesticServantsValue';
export const SET_DOMESTIC_SERVANTS_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'domesticServantsValid';

export const SET_DOMESTIC_SERVANTS_NO_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'domesticServantsNoValue';
export const SET_DOMESTIC_SERVANTS_NO_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'domesticServantsNoValid';

export const SET_OWNERS_LIABILITY_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'ownersLiabilityValue';
export const SET_OWNERS_LIABILITY_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'ownersLiabilityValid';

export const SET_OWNERS_LIABILITY_AMOUNT_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'ownersLiabilityAmountValue';
export const SET_OWNERS_LIABILITY_AMOUNT_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'ownersLiabilityAmountValid';

export const SET_OCCUPIERS_LIABILITY_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'occupiersValue';
export const SET_OCCUPIERS_LIABILITY_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'occupiersValid';

export const SET_OCCUPIERS_LIABILITY_AMOUNT_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'occupiersAmountValue';
export const SET_OCCUPIERS_LIABILITY_AMOUNT_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'occupiersAmountValid';

export const SET_SELECTED_COVER_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'selectMotorCover';
export const SET_SELECTED_COVER_VALID = DOMESTIC_INSURANCE_FORM_SET_VALID + 'selectMotorCover';

export const UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE = DOMESTIC_INSURANCE_FORM_SET_VALUE + 'sidebarOpen';
export const UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE = UG_MOTOR_QOUTE_FORM + 'shareQuote'; 

// action creators
export const setBuildingValue = value => ({
	type: SET_BUILDING_VALUE,
	value,
});

export const setBuildingValid = (error) => ({
    type: SET_BUILDING_VALID,
    error,
});

export const setBelongingsValue = value => ({
	type: SET_BELONGINGS_VALUE,
	value,
});

export const setBelongingsValid = (error) => ({
    type: SET_BELONGINGS_VALID,
    error,
});


export const setCoverStartDateValue = value => ({
	type: SET_COVER_START_DATE_VALUE,
	value,
});

export const setCoverStartDateValid = error => ({
	type: SET_COVER_START_DATE_VALID,
	error,
});


export const setAllRiskValue = value => ({
	type: SET_ALL_RISK_VALUE,
	value,
});

export const setAllRiskValid = (error) => ({
    type: SET_ALL_RISK_VALID,
    error,
});



export const initializeQuoteForm = () => ({
	type: REINITIALIZE,
})



export const setPortableItemsValue = value => ({
	type: SET_PORTABLE_VALUE,
	value,
});

export const setPortableItemsValid = (error) => ({
    type: SET_PORTABLE_VALID,
    error,
});


export const setDomesticServantsValue = value => ({
	type: SET_DOMESTIC_SERVANTS_VALUE,
	value,
});

export const setEarthquakeValue = (value) => ({
    type: SET_DOMESTIC_EARTHQUAKE_VALUE,
    value,
});
;
export const setDomesticServantsValid = (error) => ({
    type: SET_DOMESTIC_SERVANTS_VALID,
    error,
});



export const setDomesticServantsNoValue = (value) => ({
    type: SET_DOMESTIC_SERVANTS_NO_VALUE,
    value,
});

export const setDomesticServantsNoValid = (error) => ({
    type: SET_DOMESTIC_SERVANTS_NO_VALID,
    error,
});

export const setOccupiersLiabilityValue = value => ({
	type: SET_OCCUPIERS_LIABILITY_VALUE,
	value,
});

export const setOccupiersLiabilityValid = (error) => ({
    type: SET_OCCUPIERS_LIABILITY_VALID,
    error,
});

export const setOccupiersLiabilityAmountValue = (value) => ({
    type: SET_OCCUPIERS_LIABILITY_AMOUNT_VALUE,
    value,
});

export const setOccupiersLiabilityAmountValid = (error) => ({
    type: SET_OCCUPIERS_LIABILITY_AMOUNT_VALID,
    error,
});

export const setOwnersLiabilityValue = (value) => ({
    type: SET_OWNERS_LIABILITY_VALUE,
    value,
});

export const setOwnersLiabilityValid = (error) => ({
    type: SET_OWNERS_LIABILITY_VALID,
    error,
});

export const setOwnersLiabilityAmountValue = (value) => ({
    type: SET_OWNERS_LIABILITY_AMOUNT_VALUE,
    value,
});

export const setOwnersLiabilityAmountValid = (error) => ({
    type: SET_OWNERS_LIABILITY_AMOUNT_VALID,
    error,
});


export const setExcessProtectorValue = value => ({
	type: SET_EXCESS_PROTECTOR_VALUE,
	value,
});

export const setExcessProtectorValid = error => ({
	type: SET_EXCESS_PROTECTOR_VALID,
	error,
});

export const setViolenceAndTerrorismValue = (value) => ({
	type: SET_VIOLENCE_TERORISM_VALUE,
	value,
});

export const setViolenceAndTerrorismValid = (error) => ({
	type: SET_VIOLENCE_TERORISM_VALID,
	error,
});

export const setShareEmailValue = value => ({
	type: SET_SHARE_EMAIL_VALUE,
	value,
});

export const setShareEmailValid = error => ({
	type: SET_SHARE_EMAIL_VALID,
	error,
});

export const setSideBarOpen = (value) => ({ type: UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE, value });

export const setSelectedCoverValue = (value) => ({type: SET_SELECTED_COVER_VALUE, value});
export const setSelectedCoverValid = error => ({type: SET_SELECTED_COVER_VALID, error})


