import { getEasiplusFuneralPlan } from "./index";
import { createSelector } from "reselect";
import { FORM_STATUS, TOGGLE_STATUS } from "../../reducers/status";

export const getConfirmDetails = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan.confirmDetails
);

export const getConfirmDetailsValid = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.formStatus === FORM_STATUS.VALID,
);

export const getDeclarationsValue = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarations,
);

export const getDeclarationsValid = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarations === TOGGLE_STATUS.YES,
);

export const getDeclarationsInvalid = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarations === FORM_STATUS.INVALID
);

export const getDeclarationsSideDrawerOpen = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarationsSideDrawer === TOGGLE_STATUS.YES,
);

export const getDeclarationsError = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.declarationsValidationMessage,
);

export const getSubmissionIsWaiting = createSelector(
    getConfirmDetails,
    confirmDetails => !!confirmDetails.waitingForSubmission,
);
export const getSubmissionIsSuccess = createSelector(
    getConfirmDetails,
    confirmDetails => !!confirmDetails.submitSuccess,
);
export const getSubmissionIsFailure = createSelector(
    getConfirmDetails,
    confirmDetails => !!confirmDetails.submitError,
);

export const getReferenceNumber = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails && confirmDetails.referenceNumber,
);

export const getSkipConfirmationPage = createSelector(
    getConfirmDetails,
    confirmDetails => confirmDetails.skipConfirmationPage
)
