export const RW_MOTOR_QOUTE_FORM = 'motorPrivateRwanda/quoteForm/';
export const KE_MOTOR_QUOTE_FORM = 'keMotorInsurance/quoteForm/';
export const RW_MOTOR_QOUTE_FORM_SET_VALUE = RW_MOTOR_QOUTE_FORM + 'setValue/';
export const RW_MOTOR_QOUTE_FORM_SET_VALID = RW_MOTOR_QOUTE_FORM + 'setValid/';
export const RW_MOTOR_QUOTE_ADD_COVER = RW_MOTOR_QOUTE_FORM + 'addCover/';
export const FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM = 'motorPrivateRwanda/quoteForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM = 'motorPrivateRwanda/quoteForm/transition/formInValid';
export const FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM = 'motorPrivateRwanda/shareQuoteForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM = 'motorPrivateRwanda/shareQuoteForm/transition/formInValid';
export const RW_MOTOR_INSURANCE_SHARE_QUOTE_VALUE = RW_MOTOR_QOUTE_FORM + 'shareQuote';
export const KE_MOTOR_QUOTE_FORM_SET_VALUE = KE_MOTOR_QUOTE_FORM + 'setValue/';
export const KE_MOTOR_QUOTE_FORM_SET_VALID = KE_MOTOR_QUOTE_FORM + 'setValid/';
export const TOKEN_IS_EXPIRED = 'motor/expiredAuth';

export const SET_CAR_MAKES_LIST_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'carMakesList';
export const SET_CAR_MAKES_LIST_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'carMakesList';
export const SET_CAR_MODEL_LIST_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'carModelsList';
export const SET_CAR_MODEL_LIST_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'carModelsList';
export const SET_VEHICLE_TYPES_LIST_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'vehicleTypesList';
export const SET_VEHICLE_TYPES_LIST_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'vehicleTypesList';
export const SET_OCCUPANTS_LIMITS_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'occupantsLimits';
export const SET_OCCUPANTS_LIMITS_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'occupantsLimits';

export const TRIGGER_GET_ALL_CAR_MAKES = RW_MOTOR_QOUTE_FORM + 'triggerCarMakes';
export const TRIGGER_GET_ALL_CAR_MODELS = RW_MOTOR_QOUTE_FORM + 'triggerCarModels';
export const TRIGGER_GET_ALL_VEHICLE_TYPES_LIST = RW_MOTOR_QOUTE_FORM + 'triggerVehicleTypes';
export const TRIGGER_GET_ALL_OCCUPANTS_LIMITS = RW_MOTOR_QOUTE_FORM + 'triggerOccupantsLimits';
export const TRIGGER_GET_VALUERS = RW_MOTOR_QOUTE_FORM + 'triggerValuers';
export const TRIGGER_GET_RW_VALUERS = RW_MOTOR_QOUTE_FORM + '/ug/triggerValuers';
export const SET_VALUERS_LIST_SUCCESS = RW_MOTOR_QOUTE_FORM + 'valuersList';
export const API_SUCCESS_TRANSITION_VALUERS = RW_MOTOR_QOUTE_FORM + 'api/success/valuers';
export const API_PENDING_TRANSITION_VALUERS = RW_MOTOR_QOUTE_FORM + 'api/pending/valuers';
export const API_FAILURE_TRANSITION_VALUERS = RW_MOTOR_QOUTE_FORM + 'api/failure/valuers';

export const RW_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'sidebarOpen';

export const REINITIALIZE = RW_MOTOR_QOUTE_FORM + '/state/reinitialize';

// VALIDATE QUOTE FORM

export const transitionMotorInsuranceQuoteForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM,
    }),
};

export const transitionShareQuoteForm = {
    formInvalid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    }),
    formValid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${RW_MOTOR_QOUTE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${RW_MOTOR_QOUTE_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${RW_MOTOR_QOUTE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${RW_MOTOR_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${RW_MOTOR_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${RW_MOTOR_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/failure`;

export const API_PENDING_TRANSITIONS_VEHICLE_TYPES_FORM = `${SET_VEHICLE_TYPES_LIST_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_VEHICLE_TYPES_FORM = `${SET_VEHICLE_TYPES_LIST_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_VEHICLE_TYPES_FORM = `${SET_VEHICLE_TYPES_LIST_VALUE}/apiTransition/failure`;

export const API_PENDING_TRANSITIONS_OCCUPANTS_LIMITS_FORM = `${SET_OCCUPANTS_LIMITS_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_OCCUPANTS_LIMITS_FORM = `${SET_OCCUPANTS_LIMITS_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_OCCUPANTS_LIMITS_FORM = `${SET_OCCUPANTS_LIMITS_VALUE}/apiTransition/failure`;

export const API_PENDING_TRANSITIONS_CAR_MAKES = `${SET_CAR_MAKES_LIST_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_CAR_MAKES = `${SET_CAR_MAKES_LIST_VALUE}/apiTransition/success`;
export const API_PENDING_TRANSITIONS_CAR_MODELS = `${SET_CAR_MODEL_LIST_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_CAR_MODELS = `${SET_CAR_MODEL_LIST_VALUE}/apiTransition/success`;

export const API_FAILURE_TRANSITIONS_CAR_MAKES = `${SET_CAR_MAKES_LIST_VALUE}/apiTransition/failure`;
export const API_FAILURE_TRANSITIONS_CAR_MODELS = `${SET_CAR_MODEL_LIST_VALUE}/apiTransition/failure`;

export const apiTransitionMotorInsuranceQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    }),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};

export const apiTransitionVehicleTypes = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_VEHICLE_TYPES_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_VEHICLE_TYPES_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_VEHICLE_TYPES_FORM,
    }),
};

export const apiTransitionOccupantsLimits = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_OCCUPANTS_LIMITS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_OCCUPANTS_LIMITS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_OCCUPANTS_LIMITS_FORM,
    }),
};

// Car makes api transition
export const apiTransitionCarMakes = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_CAR_MAKES,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_CAR_MAKES,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_CAR_MAKES,
    }),
};

// Car makes api transition
export const apiTransitionCarModels = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_CAR_MODELS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_CAR_MODELS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_CAR_MODELS,
    }),
};

// Valuers makes api transition
export const apiTransitionValuers = {
    pending: () => ({
        type: API_PENDING_TRANSITION_VALUERS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_VALUERS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_VALUERS,
    }),
};

//calculated Data
export const COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'collectMotorInsuranceQuoteData';
export const COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceQuoteData';
export const COLLECT_KE_THIRD_PARTY_DATA = KE_MOTOR_QUOTE_FORM + 'collectMotorInsuranceThirdPartyQuoteData';
export const SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'success';
export const SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_MOTOR_INSURANCE_PREMIUM_FAILURE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'failure';
export const COLLECT_SHARE_QUOTE_FORM_DATA = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_MOTOR_INSURANCE_QUOTE_FAILURE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'shareQuoteFailure';
export const TRIGGER_CALCULATE_MOTORCYCLE_PREMIUM = KE_MOTOR_QUOTE_FORM + 'triggerCalculateMotorCyclePremium';

export const collectMotorInsuranceQuoteData = () => ({
    type: COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA,
});

export const collectMotorInsuranceKeQuoteData = () => ({ type: COLLECT_KE_MOTOR_INSURANCE_QUOTE_FORM_DATA });
export const collectThirdPartyQuoteData = () => ({ type: COLLECT_KE_THIRD_PARTY_DATA });
export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

export const submitMotorInsuranceQuoteSuccess = () => ({
    type: SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS,
    value,
});

export const shareQuoteSuccess = (value) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    value,
});

export const shareQuoteFailure = (error) => ({
    type: SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    error,
});

export const calculateMotorInsurancePremiumSuccess = (value) => ({
    type: CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS,
    value,
});

export const submitMotorInsuranceQuoteFailure = () => ({
    type: SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE,
    error,
});

export const addCover = (value) => ({
    type: RW_MOTOR_QUOTE_ADD_COVER,
    value,
});

// Form values
export const SET_FULL_NAME_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'fullNameValue';
export const SET_FULL_NAME_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'fullNameValid';
export const SET_CAR_MAKE_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'carMakeValue';
export const SET_CAR_MAKE_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'carMakeValid';
export const SET_CAR_MODEL_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'carModelValue';
export const SET_CAR_MODEL_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'carModelValid';
export const SET_YEAR_OF_MANUFACTURE_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'yearOfManufactureValue';
export const SET_YEAR_OF_MANUFACTURE_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'yearOfManufactureValid';
export const SET_NUMBER_SEATS_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'numberOfSeats';
export const SET_NUMBER_SEATS_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'numberOfSeats';
export const SET_NUMBER_COVERED_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'numberCovered';
export const SET_NUMBER_COVERED_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'numberCovered';
export const SET_COVERED_AMOUNT_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'coveredAmount';
export const SET_COVERED_AMOUNT_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'coveredAmount';
export const SET_INSURANCE_PERIOD_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'insurancePeriod';
export const SET_INSURANCE_PERIOD_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'insurancePeriod';
export const SET_CAR_USE_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'carUse';
export const SET_CAR_USE_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'carUse';
export const SET_CAR_REACH_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'carReach';
export const SET_CAR_REACH_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'carReach';
export const SET_CAR_COST_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'carCost';
export const SET_CAR_COST_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'carCost';

export const SET_SHARE_EMAIL_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'shareEmailValid';
export const SET_SELECTED_COVER_VALUE = KE_MOTOR_QUOTE_FORM_SET_VALUE + 'selectMotorCover';
export const SET_SELECTED_COVER_VALID = KE_MOTOR_QUOTE_FORM_SET_VALID + 'selectMotorCover';

export const SET_VEHICLE_TYPE_VALUE = RW_MOTOR_QOUTE_FORM_SET_VALUE + 'vehicleType';
export const SET_VEHICLE_TYPE_VALID = RW_MOTOR_QOUTE_FORM_SET_VALID + 'vehicleType';

// action creators
export const setFullNameValue = (value) => ({
    type: SET_FULL_NAME_VALUE,
    value,
});

export const setFullNameValid = (error) => ({
    type: SET_FULL_NAME_VALID,
    error,
});

export const setCarMakeValue = (value) => ({
    type: SET_CAR_MAKE_VALUE,
    value,
});

export const setCarMakeValid = (error) => ({
    type: SET_CAR_MAKE_VALID,
    error,
});

export const setCarModelValue = (value) => ({
    type: SET_CAR_MODEL_VALUE,
    value,
});

export const setCarModelValid = (error) => ({
    type: SET_CAR_MODEL_VALID,
    error,
});

export const setYearOfManufactureValue = (value) => ({
    type: SET_YEAR_OF_MANUFACTURE_VALUE,
    value,
});

export const setYearOfManufactureValid = (error) => ({
    type: SET_YEAR_OF_MANUFACTURE_VALID,
    error,
});

export const initializeQuoteForm = () => ({
    type: REINITIALIZE,
});

export const setCarUseValue = (value) => ({
    type: SET_CAR_USE_VALUE,
    value,
});

export const setCarUseValid = (error) => ({
    type: SET_CAR_USE_VALID,
    error,
});

export const setCarCostValue = (value) => ({
    type: SET_CAR_COST_VALUE,
    value,
});

export const setCarCostValid = (error) => ({
    type: SET_CAR_COST_VALID,
    error,
});

export const setCarReachValue = (value) => ({
    type: SET_CAR_REACH_VALUE,
    value,
});

export const setCarReachValid = (error) => ({
    type: SET_CAR_REACH_VALID,
    error,
});

export const setInsurancePeriodValue = (value) => ({
    type: SET_INSURANCE_PERIOD_VALUE,
    value,
});

export const setInsurancePeriodValid = (error) => ({
    type: SET_INSURANCE_PERIOD_VALID,
    error,
});

export const setNumberOfSeatsValue = (value) => ({
    type: SET_NUMBER_SEATS_VALUE,
    value,
});

export const setNumberOfSeatsValid = (error) => ({
    type: SET_NUMBER_SEATS_VALID,
    error,
});

export const setNumberOfCoveredValue = (value) => ({
    type: SET_NUMBER_COVERED_VALUE,
    value,
});

export const setNumberOfCoveredValid = (error) => ({
    type: SET_NUMBER_COVERED_VALID,
    error,
});

export const setCoveredAmountValue = (value) => ({
    type: SET_COVERED_AMOUNT_VALUE,
    value,
});

export const setCoveredAmountValid = (error) => ({
    type: SET_COVERED_AMOUNT_VALID,
    error,
});
export const setShareEmailValue = (value) => ({
    type: SET_SHARE_EMAIL_VALUE,
    value,
});

export const setShareEmailValid = (error) => ({
    type: SET_SHARE_EMAIL_VALID,
    error,
});

export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value,
});

export const setSideBarOpen = (value) => ({ type: RW_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: RW_MOTOR_INSURANCE_SHARE_QUOTE_VALUE, value });

export const setSelectedCoverValue = (value) => ({ type: SET_SELECTED_COVER_VALUE, value });
export const setSelectedCoverValid = (error) => ({ type: SET_SELECTED_COVER_VALID, error });

export const setVehicleTypeValue = (value) => ({
    type: SET_VEHICLE_TYPE_VALUE,
    value,
});

export const setVehicleTypeValid = (error) => ({
    type: SET_VEHICLE_TYPE_VALID,
    error,
});

export const triggerMotocyleInsurance = () => ({
    type: TRIGGER_CALCULATE_MOTORCYCLE_PREMIUM,
});

export const setCarMakesValue = (value) => ({
    type: SET_CAR_MAKES_LIST_VALUE,
    value,
});
export const setCarMakesValid = (error) => ({
    type: SET_CAR_MAKES_LIST_VALID,
    error,
});

export const setCarModelsValue = (value) => ({
    type: SET_CAR_MODEL_LIST_VALUE,
    value,
});
export const setCarModelsValid = (error) => ({
    type: SET_CAR_MODEL_LIST_VALID,
    error,
});

export const setVehicleTypesListValue = (value) => ({
    type: SET_VEHICLE_TYPES_LIST_VALUE,
    value,
});
export const setVehicleTypesListValid = (error) => ({
    type: SET_VEHICLE_TYPES_LIST_VALID,
    error,
});

export const setOccupantLimitsValue = (value) => ({
    type: SET_OCCUPANTS_LIMITS_VALUE,
    value,
});
export const setOccupantLimitsValid = (error) => ({
    type: SET_OCCUPANTS_LIMITS_VALID,
    error,
});

export const triggerGetCarModels = () => ({
    type: TRIGGER_GET_ALL_CAR_MODELS,
});

export const triggerGetCarMakes = () => ({
    type: TRIGGER_GET_ALL_CAR_MAKES,
});

export const triggerGetVehicleTypesList = () => ({
    type: TRIGGER_GET_ALL_VEHICLE_TYPES_LIST,
});

export const triggerGetOccupantLimits = () => ({
    type: TRIGGER_GET_ALL_OCCUPANTS_LIMITS,
});

export const triggerGetValuers = () => ({
    type: TRIGGER_GET_VALUERS,
});
export const triggerGetUgValuers = () => ({
    type: TRIGGER_GET_RW_VALUERS,
});
export const setValuers = (value) => ({
    type: SET_VALUERS_LIST_SUCCESS,
    value,
});
