import { onFieldChangeStateComparison } from '../onFieldChangeStateComparison';
import { simpleFieldTracking } from '../formTracking';
import {
    CONTRIBUTION_SET_VALUE,
    LUMP_SUM_CONTRIBUTION_SET_VALUE,
    REGULAR_CONTRIBUTION_SET_VALUE,
    PREMIUM_ESCALATION_SET_VALUE,
    OWN_PERCENTAGE_SET_VALUE,
    VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION,
} from '../../../actions/taxFreeSavingsAccount/investmentSetupContribution';
import {
    getContributionType,
    getRegularContribution,
    getLumpSumContribution,
    getPremiumEscalation,
    getOwnPercentage,
    getIsViewMoreExpanded,
} from '../../../selectors/taxFreeSavingsAccount/investmentSetupContribution';
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const investmentSetupContributionTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case CONTRIBUTION_SET_VALUE:
                sendError = false;
                return getContributionType;
            case LUMP_SUM_CONTRIBUTION_SET_VALUE:
                return getLumpSumContribution;
            case REGULAR_CONTRIBUTION_SET_VALUE:
                return getRegularContribution;
            case PREMIUM_ESCALATION_SET_VALUE:
                sendError = false;
                return getPremiumEscalation;
            case OWN_PERCENTAGE_SET_VALUE:
                sendError = false;
                return getOwnPercentage;
            default:
                return null;
        }
    })();

    const field = onFieldChange(fieldSelector);
    if (field) simpleFieldTracking({ action, field, sendValue, sendError });

    return result;
};

export const investmentSetupContributionOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return getIsViewMoreExpanded(state) ? 'viewMoreExpanded' : 'viewMoreClosed';
            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};