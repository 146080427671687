import {
    ACTIVE_PAGE_SET_VALUE
} from "../../actions/covidSelfAssessment/progressBar";

const initProgressBar = {
    value: null
};

export default (state = initProgressBar, action) => {
    switch (action.type) {
        case ACTIVE_PAGE_SET_VALUE:
            return { ...state, value: action.value};
                default:
            return state;
    }
};