import { TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS } from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { DLP_APPLICATION_REFERENCE_DATA } from "../../../actions/digiLifeLoanProtection/referenceData";
import { referenceDataService } from "./referenceData";
import { submitDlpApplicationService } from "./submitApplication";

import { performAccountValidation } from "./accountValidation";
import { DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND } from "../../../actions/digiLifeLoanProtection/confirmDetails";

export const dlpServices = (store, next, action) => {
    if (action.type===DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND){
        return submitDlpApplicationService(store, next, action);
      }
    
  if (action.type.startsWith(DLP_APPLICATION_REFERENCE_DATA))
    return referenceDataService(store, next, action);

  if (action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS)
    return performAccountValidation(store, next, action);

  return null;
};
