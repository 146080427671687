import { combineReducers } from 'redux';
import routing from './routing';
import quote from './quote';
import agent from './agent';
import payments from './payment';
import payloadCollection from './payloadCollection';
import documents from './Documents';
import personalDetails from './personalDetails';
import childDetails from './childDetails';
import spouseDetails from './spouseDetails';
import nextOfKin from './nextOfKin';
import declarations from './declarations';

export const personalAccident = combineReducers({
    routing,
    quote,
    agent,
    payments,
    payloadCollection,
    documents,
    personalDetails,
    childDetails,
    spouseDetails,
    nextOfKin,
    declarations,
});
