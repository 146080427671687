import { transitionPersonalDetails } from "../../../actions/digiLifeLoanProtection/personalDetails";
import { setAddressProofDocumentsValue, setFormInvalid, setFormValid, setIdDocumentsValue, setIncomeProofDocumentsValue, setbankStatementDocumentsValue, setloanLetterDocumentsValue } from "../../../actions/digiLifeLoanProtection/uploadDocuments";
import { FIELD_STATUS } from "../../../reducers/status";
import { getEmploymentType, getPersonalDetailsForm } from "../../../selectors/digiLifeLoanProtection/personalDetails";
import {  getDocumentsForm } from "../../../selectors/digiLifeLoanProtection/uploadDocuments";

const isUploadDocumentsValid = store => {
    const state = store.getState();
    const {addressProof,bankStatement,certifiedCopyOfId,incomeProof,loanLetter} = getDocumentsForm(state);
const {employmentType}=getPersonalDetailsForm(state)
    const isValid = field => field && field.status === FIELD_STATUS.VALID;
    // Upload Documents
    const uploadDocumentsFormFields = employmentType.value =='01'?[
        addressProof,
        bankStatement,
        certifiedCopyOfId,
        incomeProof,
        loanLetter,
       ]: [
        addressProof,
        bankStatement,
        certifiedCopyOfId,
        loanLetter,
       ];
    const uploadDocumentsValid = uploadDocumentsFormFields.every(isValid)
    return uploadDocumentsValid 
}
export const uploadDocumentsRules = (store, next, action) => {
    const result = next(action);
    // const state = store.getState();

    // Personal Information
    const isValid = isUploadDocumentsValid(store);
    isValid?store.dispatch(setFormValid()): store.dispatch(setFormInvalid());
  
    return result;
};

export const performUploadDocumentsValidation=(store)=>{
    const state=store.getState()
    const {addressProof,bankStatement,certifiedCopyOfId,incomeProof,loanLetter} = getDocumentsForm(state);
    const {employmentType}=getPersonalDetailsForm(state)
    
    store.dispatch(setAddressProofDocumentsValue(addressProof.fileName,addressProof.fileContaint));
    store.dispatch(setloanLetterDocumentsValue(loanLetter.fileName,loanLetter.fileContaint));
    store.dispatch(setbankStatementDocumentsValue(bankStatement.fileName,bankStatement.fileContaint));
    store.dispatch(setIdDocumentsValue(certifiedCopyOfId.fileName,certifiedCopyOfId.fileContaint));
    employmentType.value =='01' && store.dispatch(setIncomeProofDocumentsValue(incomeProof.fileName,incomeProof.fileContaint));
    

}

