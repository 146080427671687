import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    FIRST_NAME_SET_VALUE,
    FIRST_NAME_SET_VALID,
    LAST_NAME_SET_VALUE,
    LAST_NAME_SET_VALID,
    ID_NUMBER_SET_VALUE,
    ID_NUMBER_SET_VALID,
    RELATIONSHIP_SET_VALUE,
    RELATIONSHIP_SET_VALID,
    LOCATION_SET_VALUE,
    LOCATION_SET_VALID,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    PERCENTAGE_SPLIT_SET_VALUE,
    PERCENTAGE_SPLIT_SET_VALID,
    REINITIALIZE,
    REINITIALIZE_SINGLE,
    ADD_BENEFICIARY,
    REMOVE_BENEFICIARY,
    SELECTED_FORM_INDEX_SET_VALUE,
    FORM_VALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM,
    FORM_INVALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM,
    FIRST_NAME_MINOR_SET_VALUE,
    FIRST_NAME_MINOR_SET_VALID,
    LAST_NAME_MINOR_SET_VALUE,
    LAST_NAME_MINOR_SET_VALID,
    LOCATION_MINOR_SET_VALUE,
    LOCATION_MINOR_SET_VALID,
    IS_MINOR_SET_VALUE,
    IS_MINOR_SET_VALID,
    PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE,
    PERSONAL_DATA_PROCESSING_CONSENT_SET_VALID,
    CHILD_DATA_PROCESSING_CONSENT_SET_VALUE,
    CHILD_DATA_PROCESSING_CONSENT_SET_VALID,
    MARKETING_USE_CONSENT_SET_VALUE,
    MARKETING_USE_CONSENT_SET_VALID,
    PERSONAL_DATA_TERMS_SET_VALUE,
    PERSONAL_DATA_TERMS_SET_VALID,
} from '../../actions/digitalSavings/beneficiaries';

export const initBeneficiaries = {
    formStatus: [],
    form: [],
    selectedFormIndex: null,
    personalDataProcessingConsent: false,
    childDataProcessingConsent: false,
    marketingUseConsent: false,
    personalDataTerms: false,
};

export default (state = initBeneficiaries, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update, index) => ({
        ...state,
        form: state.form.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    [fieldId]: update(item[fieldId]),
                };
            } else {
                return item;
            }
        }),
    });

    switch (action.type) {
        case SELECTED_FORM_INDEX_SET_VALUE:
            return { ...state, selectedFormIndex: action.value };

        case REINITIALIZE:
            return initBeneficiaries;

        case REINITIALIZE_SINGLE:
            const indexToEdit = action.index;

            if (indexToEdit >= 0 && indexToEdit < state.form.length) {
                const updatedForm = [...state.form];
                updatedForm[indexToEdit] = {
                    firstName: { value: null, error: null, status: null },
                    lastName: { value: null, error: null, status: null },
                    idNumber: { value: null, error: null, status: null },
                    relationship: { value: null, error: null, status: null },
                    location: { value: null, error: null, status: null },
                    firstNameMinor: { value: null, error: null, status: null },
                    lastNameMinor: { value: null, error: null, status: null },
                    locationMinor: { value: null, error: null, status: null },
                    phoneNumber: { value: null, error: null, status: null },
                    email: { value: null, error: null, status: null },
                    percentageSplit: { value: null, error: null, status: null },
                    isMinor: { value: null, error: null, status: null },
                };

                const updatedFormStatus = [...state.formStatus];

                updatedFormStatus[indexToEdit] = FORM_STATUS.INVALID;

                return {
                    ...state,
                    formStatus: updatedFormStatus,
                    form: updatedForm,
                };
            }

            return state;

        case ADD_BENEFICIARY:
            return {
                ...state,
                formStatus: [...state.formStatus, FORM_STATUS.INVALID],
                form: [
                    ...state.form,
                    {
                        firstName: { value: null, error: null, status: null },
                        lastName: { value: null, error: null, status: null },
                        idNumber: { value: null, error: null, status: null },
                        relationship: { value: null, error: null, status: null },
                        location: { value: null, error: null, status: null },
                        firstNameMinor: { value: null, error: null, status: null },
                        lastNameMinor: { value: null, error: null, status: null },
                        locationMinor: { value: null, error: null, status: null },
                        phoneNumber: { value: null, error: null, status: null },
                        email: { value: null, error: null, status: null },
                        percentageSplit: { value: null, error: null, status: null },
                        isMinor: { value: null, error: null, status: null },
                    },
                ],
            };

        case REMOVE_BENEFICIARY:
            const indexToRemove = action.index;

            if (indexToRemove >= 0 && indexToRemove < state.form.length) {
                const updatedForm = state.form.filter((_, idx) => idx !== indexToRemove);

                const updatedFormStatus = state.formStatus.filter((_, idx) => idx !== indexToRemove);

                return {
                    ...state,
                    formStatus: updatedFormStatus,
                    form: updatedForm,
                };
            }

            return state;

        case FORM_VALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM:
            const validIndex = action.index;

            if (validIndex >= 0 && validIndex < state.formStatus.length) {
                const updatedFormStatus = [...state.formStatus];
                updatedFormStatus[validIndex] = FORM_STATUS.VALID;
                return {
                    ...state,
                    formStatus: updatedFormStatus,
                };
            }

            return state;

        case FORM_INVALID_DIGITAL_SAVINGS_BENEFICIARIES_FORM:
            const index = action.index;

            if (index >= 0 && index < state.formStatus.length) {
                const updatedFormStatus = [...state.formStatus];
                updatedFormStatus[index] = FORM_STATUS.INVALID;

                return {
                    ...state,
                    formStatus: updatedFormStatus,
                };
            }

            return state;

        case FIRST_NAME_SET_VALUE:
            return updateFormField(
                'firstName',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case FIRST_NAME_SET_VALID:
            return updateFormField(
                'firstName',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case LAST_NAME_SET_VALUE:
            return updateFormField(
                'lastName',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case LAST_NAME_SET_VALID:
            return updateFormField(
                'lastName',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case FIRST_NAME_MINOR_SET_VALUE:
            return updateFormField(
                'firstNameMinor',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case FIRST_NAME_MINOR_SET_VALID:
            return updateFormField(
                'firstNameMinor',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case LAST_NAME_MINOR_SET_VALUE:
            return updateFormField(
                'lastNameMinor',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case LAST_NAME_MINOR_SET_VALID:
            return updateFormField(
                'lastNameMinor',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case IS_MINOR_SET_VALUE:
            return updateFormField(
                'isMinor',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case IS_MINOR_SET_VALID:
            return updateFormField(
                'isMinor',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case ID_NUMBER_SET_VALUE:
            return updateFormField(
                'idNumber',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case ID_NUMBER_SET_VALID:
            return updateFormField(
                'idNumber',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case RELATIONSHIP_SET_VALUE:
            return updateFormField(
                'relationship',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case RELATIONSHIP_SET_VALID:
            return updateFormField(
                'relationship',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case LOCATION_SET_VALUE:
            return updateFormField(
                'location',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case LOCATION_SET_VALID:
            return updateFormField(
                'location',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case LOCATION_MINOR_SET_VALUE:
            return updateFormField(
                'locationMinor',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case LOCATION_MINOR_SET_VALID:
            return updateFormField(
                'locationMinor',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField(
                'phoneNumber',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case PHONE_NUMBER_SET_VALID:
            return updateFormField(
                'phoneNumber',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case EMAIL_SET_VALUE:
            return updateFormField(
                'email',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case EMAIL_SET_VALID:
            return updateFormField(
                'email',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case PERCENTAGE_SPLIT_SET_VALUE:
            return updateFormField(
                'percentageSplit',
                (field) => ({
                    ...field,
                    value: action.value,
                }),
                action.index
            );
        case PERCENTAGE_SPLIT_SET_VALID:
            return updateFormField(
                'percentageSplit',
                (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }),
                action.index
            );

        case PERSONAL_DATA_PROCESSING_CONSENT_SET_VALUE:
            return {
                ...state,
                personalDataProcessingConsent: action.value,
            };

        case CHILD_DATA_PROCESSING_CONSENT_SET_VALUE:
            return {
                ...state,
                childDataProcessingConsent: action.value,
            };

        case MARKETING_USE_CONSENT_SET_VALUE:
            return {
                ...state,
                marketingUseConsent: action.value,
            };

        case PERSONAL_DATA_TERMS_SET_VALUE:
            return {
                ...state,
                personalDataTerms: action.value,
            };

        default:
            return state;
    }
};
