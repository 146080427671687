import { validationIsEqual } from '../status';
import {
    validateName,
    validateEmail,
    validateNumericString,
    validateContactNumber,
    validateAlphaNumericString,
    validateSingleSelection,
    validateNumber,
} from '@om/validation';
import {
    FIRST_NAME_SET_VALUE,
    setFirstNameValid,
    LAST_NAME_SET_VALUE,
    setLastNameValid,
    ID_NUMBER_SET_VALUE,
    setIdNumberValid,
    RELATIONSHIP_SET_VALUE,
    setRelationshipValid,
    LOCATION_SET_VALUE,
    setLocationValid,
    PHONE_NUMBER_SET_VALUE,
    setPhoneNumberValid,
    EMAIL_SET_VALUE,
    setEmailValid,
    FIRST_NAME_MINOR_SET_VALUE,
    setFirstNameMinorValid,
    LAST_NAME_MINOR_SET_VALUE,
    setLastNameMinorValid,
    LOCATION_MINOR_SET_VALUE,
    setLocationMinorValid,
    IS_MINOR_SET_VALUE,
    setIsMinorValid,
    PERCENTAGE_SPLIT_SET_VALUE,
    setPercentageSplitValid,
} from '../../../actions/digitalSavings/beneficiaries';
// import { validateKePassport } from '@om/validation/src/validators/ke-passport-validator';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

let prevValidationAction = null;
export const beneficiaryValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                    required: true,
                });

                return setFirstNameValid(action.index, error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                    required: true,
                });
                return setLastNameValid(action.index, error);

            case FIRST_NAME_MINOR_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'A minor first name',
                    required: true,
                });

                return setFirstNameMinorValid(action.index, error);

            case LAST_NAME_MINOR_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'A minor last name',
                    required: true,
                });
                return setLastNameMinorValid(action.index, error);

            case ID_NUMBER_SET_VALUE:
                error = validateNumericString(action.value, { min: 8, max: 15 });

                return setIdNumberValid(action.index, error);

            case RELATIONSHIP_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Relationship',
                    min: 2,
                    required: true,
                });
                return setRelationshipValid(action.index, error);

            case LOCATION_SET_VALUE:
                error = validateAlphaNumericString(action.value, {
                    subject: 'Location',
                    min: 2,
                    required: true,
                });
                return setLocationValid(action.index, error);

            case LOCATION_MINOR_SET_VALUE:
                error = validateAlphaNumericString(action.value, {
                    subject: 'Location',
                    min: 2,
                    required: true,
                });
                return setLocationMinorValid(action.index, error);

            case IS_MINOR_SET_VALUE:
                error = validateSingleSelection(action.value.toString(), {
                    subject: 'Is Minor',
                    min: 2,
                    required: true,
                });
                return setIsMinorValid(action.index, error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Phone Number',
                    countryCode: gatsbyStack,
                    required: true,
                });
                return setPhoneNumberValid(action.index, error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(action.index, error);

                case PERCENTAGE_SPLIT_SET_VALUE:
                    error = validateNumericString(action.value.toString(), {
                        min: 1,
                        max: 100,
                        subject: ' Percentage Split',
                        required: true,
                    });
                    return setPercentageSplitValid(action.index, error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
