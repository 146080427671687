import { createSelector } from 'reselect';
import { getAllsureInsurance } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { getApplicationData } from './referenceData';
import { getBooleanValue, getContentDetailsObject, getGlobalPaymentFrequency, getHomeDetailsList, getHomeDetailsObject } from './homeDetails';
import { getIsInsurenceTypeSelected } from './insuranceType';
import { getHomeContentList } from './homeContents';
import { getPersonalDetailsForm } from './personalDetails';
// import { KMF_CLAIM_PAGE_INDICES } from "../../actions/kaMetlhaFuneralClaim/types";

export const getMotorDetails = createSelector(getAllsureInsurance, (allsureInsurance) => allsureInsurance.motorDetails);
export const getMotorDetailsForm = createSelector(getMotorDetails, (motorDetails) => motorDetails.form);
export const getAllMotorDetailForms = createSelector(
    getMotorDetails,
    (allsureInsurance) => allsureInsurance.motorDetailForms
);

export const getInsurenceTypeSelected = (insuranceType) =>
    createSelector(
        getMotorDetailsForm,
        (motorDetailsForm) => motorDetailsForm.motorInsuranceType.value === insuranceType
    );
export const getIsMotorDetailsFormValid = createSelector(
    getMotorDetails,
    (motorDetails) => motorDetails.formStatus === FORM_STATUS.VALID
);

export const getMotorFieldsToBeValidated = createSelector(getMotorDetailsForm, (motorDetailsForm) => {
    const { vehicleModel, vehicleMake, lossSuffered,insuredWithOM } = motorDetailsForm;
    const motorDetails = { ...motorDetailsForm };
    if (vehicleModel?.value !== 'OTHER') delete motorDetails.mentionedVehicleModel;
    if (vehicleMake?.value !== 'OTHER') delete motorDetails.mentionedVehicleMake;

    if (lossSuffered.value !== '01') {
        delete motorDetails.claimAmount;
        delete motorDetails.claimedCount;
    }
    if(insuredWithOM.displayValue<1){
      delete motorDetails.policyNumber;
    }
    return motorDetails;
});
export const getMotorDetailsReferenceData = createSelector(
    getApplicationData,
    ({ vehicleMakeData, vehicleModelData }) => ({
        vehicleMakeReferenceData: Object.values(vehicleMakeData)?.map((make)=>({ value: make, label: make })),
        vehicleModelReferenceData: [...Object.values(vehicleModelData)?.map((make)=>({ value: make, label: make })),...(Object.values(vehicleModelData)?.includes("OTHER")?[]:[{value:"OTHER",label:"OTHER"}])],
        maritalStatusReferenceData: [
            { value: 'married', label: 'Married' },
            { value: 'single', label: 'Single' },
            { value: 'livingTogether', label: 'Living Together' },
            { value: 'widow', label: 'Widow' },
            { value: 'divorced', label: 'Divorced' },
            { value: 'widower', label: 'Widower' },
        ],
        genderReferenceData: [
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
        ],
        licenceCodeReferenceData: [
            { value: 'B', label: 'B' },
            { value: 'C', label: 'C' },
            { value: 'C1', label: 'C1' },
            { value: 'EB', label: 'EB' },
            { value: 'EC', label: 'EC' },
            { value: 'EC1', label: 'EC1' },
        ],
        yesNoReferenceData: [
            { value: '01', label: 'Yes' },
            { value: '02', label: 'No' },
        ],
        fuelTypeReferenceData: [
            { value: '01', label: 'Petrol' },
            { value: '02', label: 'Diesel' },
        ],
        vehicleUseReferenceData: [
            { value: '01', label: 'Domestic' },
            { value: '02', label: 'Business' },
        ],
        vehicleTypeReferenceData:[
            { value: '01', label: 'Sedan' },
            { value: '02', label: 'SUV' },  
            { value: '03', label: 'Coupe' },  
        ]
    })
);

export const getMotorDetailsObject = createSelector(getMotorDetails, (motorDetails) => {
    return motorDetails?.motorDetailForms?.map(
        ({
            vehicleMake,
            vehicleLocation,
            motorInsuranceType,
            vehicleModel,
            vehicleYear,
            trackingDevice,
            isImported,
            fuelType,
            lossSuffered,
            vehicleUse,
            engineCapacity,
            dateOfBirth,
            gender,
            dateOfLicence,
            maritalStatus,
            licenceCode,
            vehicleSumAssured,
            claimedCount,
            claimAmount,
            mentionedVehicleMake,
            mentionedVehicleModel,
            insuredDetails,
            insuredWithOM,
            policyNumber
        }) => {
            const motorDetails = [
                { label: 'Cover Type', value: motorInsuranceType.displayValue ?? '-' },
                { label: 'Vehicle Location', value: vehicleLocation.value ?? '-' },
                { label: 'Vehicle Make', value: vehicleMake.displayValue ==='OTHER'? mentionedVehicleMake?.value : vehicleMake.displayValue },
                { label: 'Vehicle Model', value: vehicleModel.displayValue ==='OTHER' ? mentionedVehicleModel.value: vehicleModel.value },
                { label: 'Vehicle Year', value: vehicleYear.displayValue ?? '-' },
                {
                    label: 'Is the vehicle fitted with a tracking and recovery device?',
                    value: trackingDevice.displayValue ?? '-',
                },
                { label: 'Is the vehicle imported?', value: isImported.displayValue ?? '-' },
                { label: 'Is the vehicle diesel or petrol driven?', value: fuelType.displayValue ?? '-' },
                { label: 'Have you suffered any loss in the last 3 years?', value: lossSuffered.displayValue ?? '-' },
                { label: 'How many times have you claimed in the last 3 years?', value: claimedCount.value ?? '-' },
                { label: 'Enter the total value of the claim(s) paid out.', value: "P"+claimAmount.value ?? '-' },
                { label: 'What is the vehicle used for?', value: vehicleUse.displayValue ?? '-' },
              //   {
              //     label: 'How many years of uninterrupted insurance cover have you had for your vehicle?',
              //     value: insuredDetails.displayValue ?? '-',
              // },
              // { label: 'How long you have been insured with Old mutual?', value: insuredWithOM.displayValue ?? '-' },
              // ...(insuredWithOM?.value>0 ? [{ label: 'Policy number', value: policyNumber?.value ?? '-' }]:[]),
                { label: 'Engine capacity (CC)', value: engineCapacity.displayValue ?? '-' },
                { label: 'Date of birth of the regular driver', value: dateOfBirth.displayValue ?? '-' },
                { label: 'Gender of the regular driver', value: gender.displayValue ?? '-' },
                {
                    label: "When did the regular driver obtain his/her driver's license?",
                    value: dateOfLicence.displayValue ?? '-',
                },
                { label: "Select the regular driver's license class", value: licenceCode.displayValue ?? '-' },
                { label: 'Marital status of the regular driver', value: maritalStatus.displayValue ?? '-' },
                { label: 'Vehicle sum insured', value: "P"+vehicleSumAssured.displayValue ?? '-' },
            ];
            lossSuffered.value === '02' && motorDetails.splice(9, 2);
            return motorDetails;
        }
    );
});

export const getMotorDetailsRequestBody = createSelector(getMotorDetails,getIsInsurenceTypeSelected('motor'), getGlobalPaymentFrequency,getPersonalDetailsForm,(motorDetails,isMotorCoverSelected,paymentFrequency,{insuredDetails,insuredWithOM,policyNumber}) => {
    return motorDetails?.motorDetailForms?.map(
        ({
            vehicleMake,
            vehicleLocation,
            motorInsuranceType,
            vehicleModel,
            vehicleYear,
            trackingDevice,
            isImported,
            fuelType,
            lossSuffered,
            vehicleUse,
            engineCapacity,
            dateOfBirth,
            gender,
            dateOfLicence,
            maritalStatus,
            licenceCode,
            vehicleSumAssured,
            claimedCount,
            claimAmount,
            // insuredDetails,
            // insuredWithOM,
            // policyNumber
        }) => (isMotorCoverSelected?{
            cityName: vehicleLocation.displayValue,
            coverType: motorInsuranceType.value,
            vehicleMake: vehicleMake.value,
            vehicleModel: vehicleModel.value,
            vehicleYear: +vehicleYear.value,
            trackerFitted: getBooleanValue(trackingDevice.displayValue),
            isImported: getBooleanValue(isImported.displayValue),
            fuelType: fuelType?.displayValue.toLowerCase(),
            insuredDurationWithOM: +insuredWithOM?.displayValue,
            totalInsuredDuration: +insuredDetails?.displayValue,
            policyNumber: +(policyNumber?.value ?? 0),
            theftHistory: getBooleanValue(lossSuffered.displayValue),
            noOfClaims: +claimedCount?.value ??  0,
            claimAmount: +claimAmount?.value ?? 0,
            vehicleUse: vehicleUse?.displayValue?.split(' ')[0]?.toLowerCase(),
            engineCapacity: +engineCapacity.value,
            driverDOB: dateOfBirth.value,
            driverGender: gender.value,
            licenseAcquireDate: dateOfLicence.value,
            licenseClass: licenceCode.value,
            maritalStatus: maritalStatus.value,
            sumInsured: +(vehicleSumAssured?.value?.split(' ')?.join('')),
            excess: 3000,
            unsupportedMotor: true,
            paymentFrequency: paymentFrequency.toLowerCase(),
        }:{})
    );
});
const defaultFields=[
    {label:"Excess",value:"500"},
]

export const getCallMeBackPayload =(usermail)=> createSelector(
    getHomeDetailsObject,
    getContentDetailsObject,
    getMotorDetailsObject,
    getIsInsurenceTypeSelected('homeOwners'),
    getIsInsurenceTypeSelected('homeContent'),
    getIsInsurenceTypeSelected('motor'),
    (homeDetails, homeContentDetails, motorDetails,isHomeOwners,isHomeContent,isMotor) => {
        const homeMaped = isHomeOwners? homeDetails?.map((item, index) => ({ title: ` Home Details ${index?(index + 1):''}`, data: [...item,...usermail?[]:defaultFields] })):[];
        const homeContentMaped = isHomeContent ? homeContentDetails?.map((item, index) => ({
            title: ` Home Content  ${index?(index + 1):''}`,
            data: [...item,...usermail?[]:defaultFields],
        })):[];
        const motorMaped = isMotor? motorDetails?.map((item, index) => ({ title: ` Motor Details  ${index?(index + 1):''}`, data: item })):[];
        return {homeMaped,homeContentMaped,motorMaped}
    }
);

export const getQuoteSummaryProductLabel = createSelector(
    getHomeDetailsList,
    getHomeContentList,
    getAllMotorDetailForms,
    (homeOwners,homeContent,motor)=>{
      if(homeOwners?.length&&homeContent?.length&&motor?.length){
        return 'Are you ready to buy Home Contents, Homeowners, and Motor insurance?'
      } else if(homeContent?.length&&motor?.length){
        return 'Are you ready to buy this Home Contents  & Motor insurance?'
      }else if(homeOwners?.length&&motor?.length){
        return 'Are you ready to buy this Homeowners & Motor insurance?'
      } else if(homeContent?.length&&homeOwners?.length){
        return 'Are you ready to buy this Homeowners & Home content insurance?'
      }if(homeContent?.length){
        return 'Are you ready to buy this Home Contents insurance?'
      }else {
        return 'Are you ready to buy this Homeowners insurance?'
      }
    }
  )

  export const getEmailSubjectLabel = createSelector(
    getHomeDetailsList,
    getHomeContentList,
    getAllMotorDetailForms,
    (homeOwners,homeContent,motor)=>{
      if(homeOwners?.length&&homeContent?.length&&motor?.length){
        return 'Home Contents, Homeowners, and Motor insurance'
      } else if(homeContent?.length&&motor?.length){
        return 'Home Contents & Motor insurance'
      }else if(homeOwners?.length&&motor?.length){
        return 'Homeowners & Motor insurance'
      } else if(homeContent?.length&&homeOwners?.length){
        return 'Homeowners & Home content insurance'
      }if(homeContent?.length){
        return 'Home Contents insurance'
      }else {
        return 'Homeowners insurance'
      }
    }
  )