import { DLP_PAGE_INDICES } from "../../../actions/digiLifeLoanProtection/types";
import {
  navigateToReplacementPolicy,
  navigateToPersonalDetails,
  navigateToPaymentDetails,
  navigateToConfirmation,
  navigateToUploadDocuments,
  //   navigateToThankYou,
  NAVIGATE_NEXT,
  NAVIGATE_PREVIOUS,
} from "../../../actions/digiLifeLoanProtection/routing";

// import { sendConfirmDetailsSubmitApplication } from "../../../actions/easiplusFuneralPlan/confirmDetails";

// import { performPersonalDetailsValidation } from "./personalDetails";
// import {
//   performLivesCoveredValidation,
//   livesCoveredUpdatePersonalValueRules,
// } from "./livesCovered";
// import { determineBeneficiaryNavigation } from "./beneficiaries";
// import { performBeneficiariesSplitValidation } from "./beneficiariesSplit";
// import { performPaymentDetailsValidation } from "./paymentDetails";
// import { performConfirmDetailsValidation } from "./confirmDetails";

import { getActivePageIndex } from "../../../selectors/digiLifeLoanProtection/routing";
import {
  getDocuments,
  getUploadDocumentsValid,
} from "../../../selectors/digiLifeLoanProtection/uploadDocuments";
import { performPersonalDetailsValidation } from "./personalDetails";
import { getPersonalDetailsValid } from "../../../selectors/digiLifeLoanProtection/personalDetails";
import { performUploadDocumentsValidation } from "./uploadDocuments";
import {
  getPaymentDetailsForm,
  getPaymentDetailsValid,
} from "../../../selectors/digiLifeLoanProtection/paymentDetails";
import { performPaymentDetailsValidation } from "./paymentDetails";
import {
  getConfirmDetailsValid,
  getDeclarationsValid,
} from "../../../selectors/digiLifeLoanProtection/confirmDetails";
import {
  sendConfirmDetailsSubmitApplication,
  validateDeclarations,
} from "../../../actions/digiLifeLoanProtection/confirmDetails";
import { transitionScrolling } from "../../../actions/digiLifeLoanProtection/scrolling";
// import { getReplacementPolicyFormIsValid } from "../../../selectors/easiplusFuneralPlan/replacementPolicy";
// import { getPersonalDetailsValid } from "../../../selectors/easiplusFuneralPlan/personalDetails";
// import {
//   getLivesCoveredFormComplete,
//   getCoverMembersForms,
// } from "../../../selectors/easiplusFuneralPlan/livesCovered";
// import { getFormStatusIsValid as getBeneficiariesSplitIsValid } from "../../../selectors/easiplusFuneralPlan/beneficiariesSplit";
// import { getPaymentDetailsValid } from "../../../selectors/easiplusFuneralPlan/paymentDetails";
// import {
//   getConfirmDetailsValid,
//   getSkipConfirmationPage,
// } from "../../../selectors/easiplusFuneralPlan/confirmDetails";

export const routingRules = (store, next, action) => {
  if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS) {
    return next(action);
  }

  const result = next(action);
  const state = store.getState();
  const activePageIndex = getActivePageIndex(state);

  if (action.type === NAVIGATE_NEXT) {
    onNavigateNext(store, state, activePageIndex);
    return result;
  }

  onNavigatePrevious(store, state, activePageIndex);

  return result;
};

const onNavigateNext = (store, state, activePageIndex) => {
  switch (activePageIndex) {
    case DLP_PAGE_INDICES.REPLACEMENT_POLICY:
      store.dispatch(navigateToPersonalDetails());
      break;

    case DLP_PAGE_INDICES.PERSONAL_DETAILS:
      !getPersonalDetailsValid(state)
        ? performPersonalDetailsValidation(store)
        : store.dispatch(navigateToPaymentDetails());
      break;

    case DLP_PAGE_INDICES.PAYMENT_DETAILS:
      !getPaymentDetailsValid(state)
        ? performPaymentDetailsValidation(store)
        : store.dispatch(navigateToUploadDocuments());
      break;
    case DLP_PAGE_INDICES.UPLOAD_DOCUMENTS:
      !getUploadDocumentsValid(state)
        ? performUploadDocumentsValidation(store)
        : store.dispatch(navigateToConfirmation());
      break;

    case DLP_PAGE_INDICES.CONFIRMATION:
      getDeclarationsValid(state)
        ? store.dispatch(sendConfirmDetailsSubmitApplication())
        : performConfirmDetailsValidation(store);
      break;

    case DLP_PAGE_INDICES.THANK_YOU:
      break;
  }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
  switch (activePageIndex) {
    case DLP_PAGE_INDICES.REPLACEMENT_POLICY:
      break;

    case DLP_PAGE_INDICES.PERSONAL_DETAILS:
      store.dispatch(navigateToReplacementPolicy());
      break;

    case DLP_PAGE_INDICES.PAYMENT_DETAILS:
      store.dispatch(navigateToPersonalDetails());
      break;
    case DLP_PAGE_INDICES.UPLOAD_DOCUMENTS:
      store.dispatch(navigateToPaymentDetails());
      break;

    case DLP_PAGE_INDICES.CONFIRMATION:
      store.dispatch(navigateToUploadDocuments());
      break;
      s;
    case DLP_PAGE_INDICES.THANK_YOU:
      store.dispatch(navigateToConfirmation());
      break;
  }
};

// const performLivesCoveredNavigation = (store, state) => {
//   const livesCoveredForm = getCoverMembersForms(state);
//   if ("myself" in livesCoveredForm) {
//     livesCoveredUpdatePersonalValueRules(store);
//   }

//   store.dispatch(navigateToLivesCovered());
// };

const performPaymentDetailsNavigation = (store, state) => {
  const skipConfirmationPage = getSkipConfirmationPage(state);

  if (skipConfirmationPage) {
    const paymentDetailsValid = getPaymentDetailsValid(state);
    const confirmDetailsValid = getConfirmDetailsValid(state);

    // Handle payment details valid
    if (!paymentDetailsValid) performPaymentDetailsValidation(store);

    // Handle confirm details valid
    if (!confirmDetailsValid) performConfirmDetailsValidation(store);

    // Handle success
    if (paymentDetailsValid && confirmDetailsValid)
      performThankYouNavigation(store);
  } else {
    !getPaymentDetailsValid(state)
      ? performPaymentDetailsValidation(store)
      : store.dispatch(navigateToConfirmation());
  }
};

const performConfirmDetailsValidation = (store) => {
  store.dispatch(validateDeclarations());
  store.dispatch(transitionScrolling.scrollToId("declaration"));
};

const performUplaodDocumentsValidation = (store, state) => {
  const isValid = isUploadDocumentsValid(store, state);
};
const isUploadDocumentsValid = (state) => {
  const formFields = getDocuments(state);
  const isValid = (field) => field && field.status === FIELD_STATUS.VALID;

  // Upload Documents
  const uploadDocumentsFormFields = [formFields.yourIdProof.membersIdFileName];
  const uploadDocumentsValid = uploadDocumentsFormFields.every(isValid);

  return uploadDocumentsValid;
};
