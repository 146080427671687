import { transitionDomesticInsuranceDocumentsForm } from '../../../actions/domesticInsurance/Documents';
import { getDocumentsForm } from '../../../selectors/domesticInsurance/documents';

import { FIELD_STATUS } from '../../../reducers/status';

export const documenetsFormRules = (store, next, action) => {
	const result = next(action);
	const documentsForm = getDocumentsForm(store.getState());
	const documentsFormValid = validateDocumentsFormDetails(documentsForm);
	const formAction = documentsFormValid
        ? transitionDomesticInsuranceDocumentsForm.formValid()
        : transitionDomesticInsuranceDocumentsForm.formInvalid();
	store.dispatch(formAction);
	return result;
};

const validateDocumentsFormDetails = (formFields) => {
	return (
		formFields.idUpload &&
		formFields.idUpload.status === FIELD_STATUS.VALID &&
		formFields.kraUpload &&
		formFields.kraUpload.status === FIELD_STATUS.VALID
	);
};
