import {
    transitionElimikaTool,
} from "../../../actions/roaQuoteTools/elimikaQuoteTool";
import {
    getElimikaQuoteToolForm,
    getSelectedForm,
    getCalculateInputValue
} from "../../../selectors/roaQuoteTools/elimikaQuoteTool";
import {FIELD_STATUS} from "../../../reducers/status";

export const elimikaQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const elimikaQuoteToolForm = getElimikaQuoteToolForm(store.getState());
    const selectedForm = getSelectedForm(store.getState());
    const calculatedInput = getCalculateInputValue(store.getState());
    const elimikaQuoteToolValid = validateElimikaQuoteToolFormDetails(elimikaQuoteToolForm, selectedForm, calculatedInput);
    const formAction = elimikaQuoteToolValid
        ? transitionElimikaTool.formValid()
        : transitionElimikaTool.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validateElimikaQuoteToolFormDetails = (formFields, selectedForm, calculatedInput) => {
    if (selectedForm === 'form-one') {
        return formFields.childDateOfBirth && formFields.childDateOfBirth.status === FIELD_STATUS.VALID &&
            formFields.schoolStartYear && formFields.schoolStartYear.status === FIELD_STATUS.VALID &&
            formFields.accumulationPeriod && formFields.accumulationPeriod.status === FIELD_STATUS.VALID;

    } else if(selectedForm === 'form-two') {
        if (calculatedInput.value === "1") {
            return formFields.dob && formFields.dob.status === FIELD_STATUS.VALID &&
                formFields.targetFeesPerYear && formFields.targetFeesPerYear.status === FIELD_STATUS.VALID &&
                formFields.schoolYearValue && formFields.schoolYearValue.status === FIELD_STATUS.VALID;
        } else {
            return formFields.dob && formFields.dob.status === FIELD_STATUS.VALID &&
                formFields.targetSavingsPerYear && formFields.targetSavingsPerYear.status === FIELD_STATUS.VALID &&
                formFields.schoolYearValue && formFields.schoolYearValue.status === FIELD_STATUS.VALID;
        }
    }
    return true;
};
