import { validationIsEqual } from '../status';
import {
    validateDateOfBirth,
    validateEmail,
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateAlphaNumericString,
    validateKraPinNumber,
    validateKeCarRegNumber,
    validateNumericString,
    validateAlphaString
} from '@om/validation';
import {
    GENDER_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    NATIONALITY_SET_VALUE,
    DOB_SET_VALUE,
    OCCUPATION_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    TIN_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    EMAIL_SET_VALUE,
    POSTAL_ADDRESS_SET_VALUE,
    STREET_ADDRESS_SET_VALUE,
    SUBURBS_SET_VALUE,
    CITY_SET_VALUE,
    PO_BOX_SET_VALUE,
    POSTAL_CODE_SET_VALUE,
    setStreetAddressValid,
    setSurbubsValid,
    setCityValid,
    setPoBoxValid,
    setPostalCodeValid,
    setGenderValid,
    setNationalityValid,
    setOccupationValid,
    setIdNumberValid,
    setPostalAddressValid,
    setFirstNameValid,
    setLastNameValid,
    setEmailValid,
    setPhoneNumberValid,
    setDOBValid,
    setTinNumberValid
} from '../../../actions/domesticInsurance/personalDetails';

let prevValidationAction = null;
export const personalDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case GENDER_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Gender',
                });
                return setGenderValid(error);

            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });
                return setFirstNameValid(error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });
                return setLastNameValid(error);

            case NATIONALITY_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setNationalityValid(error);

            case OCCUPATION_SET_VALUE:
                error = validateSingleSelection(action.value, {});
                return setOccupationValid(error);

            case ID_NUMBER_SET_VALUE:
                error = validateNumericString(action.value, { min: 8, max: 15 });
                return setIdNumberValid(error);

            case TIN_NUMBER_SET_VALUE:
                error = validateKraPinNumber(action.value);
    
                return setTinNumberValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, 'Phone Number');
                return setPhoneNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case POSTAL_ADDRESS_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value);
                return setPostalAddressValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 18, max: 85 });
                return setDOBValid(error);

            case STREET_ADDRESS_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'Street Address',
                    required: true,
                    min: 4,
        
                });
                return setStreetAddressValid(error);
            case SUBURBS_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'Surbubs',
                    required: true,
                });
                return setSurbubsValid(error);

            case CITY_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'City',
                    required: true,
                });
                return setCityValid(error);

            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
