import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    DRIVER_HEALTH_DECLARATION_SET_VALUE,
    DRIVER_HEALTH_DECLARATION_SET_VALID,
    VEHICLE_CONDITION_DECLARATION_SET_VALUE,
    VEHICLE_CONDITION_DECLARATION_SET_VALID,
    DRIVER_LICENSE_DECLARATION_SET_VALUE,
    DRIVER_LICENSE_DECLARATION_SET_VALID,
    AVERAGE_CLAUSE_CONSENT_SET_VALUE,
    AVERAGE_CLAUSE_CONSENT_SET_VALID,
    FULL_VALUE_DECLARATION_SET_VALUE,
    FULL_VALUE_DECLARATION_SET_VALID,
    MARKETING_CONSENT_SET_VALUE,
    MARKETING_CONSENT_SET_VALID,
    PRIVACY_POLICY_CONFIRMATION_SET_VALUE,
    PRIVACY_POLICY_CONFIRMATION_SET_VALID,
    ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALUE,
    ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALID,
    NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALUE,
    NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALID,
    FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALUE,
    FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALID,
    FINAL_DECLARATION_AGREEMENT_SET_VALUE,
    FINAL_DECLARATION_AGREEMENT_SET_VALID,
    FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM,
    FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM,
} from '../../actions/motorPrivateRW/declarations';

export const initDeclaration = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    declarationReferenceData: null,
    apiCallStatus: null,
    form: {
        driverHealthDeclaration: { value: false, status: null, error: null },
        vehicleConditionDeclaration: { value: false, status: null, error: null },
        driverLicenseDeclaration: { value: false, status: null, error: null },
        averageClauseConsent: { value: false, status: null, error: null },
        fullValueDeclaration: { value: false, status: null, error: null },
        marketingConsent: { value: null, status: null, error: null },
        privacyPolicyConfirmation: { value: false, status: null, error: null },
        adviceAcknowledgementIndemnity: { value: false, status: null, error: null },
        noPremiumRefundAcknowledgement: { value: false, status: null, error: null },
        fullPremiumSettlementConsent: { value: false, status: null, error: null },
        finalDeclarationAgreement: { value: false, status: null, error: null },
    },
};

export default (state = initDeclaration, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_DECLARATIONS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_MOTOR_INSURANCE_DECLARATIONS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case DRIVER_HEALTH_DECLARATION_SET_VALUE:
            return updateFormField('driverHealthDeclaration', (field) => ({ ...field, value: action.value }));

        case DRIVER_HEALTH_DECLARATION_SET_VALID:
            return updateFormField('driverHealthDeclaration', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case VEHICLE_CONDITION_DECLARATION_SET_VALUE:
            return updateFormField('vehicleConditionDeclaration', (field) => ({ ...field, value: action.value, }));

        case VEHICLE_CONDITION_DECLARATION_SET_VALID:
            return updateFormField('vehicleConditionDeclaration', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DRIVER_LICENSE_DECLARATION_SET_VALUE:
            return updateFormField('driverLicenseDeclaration', (field) => ({ ...field, value: action.value }));

        case DRIVER_LICENSE_DECLARATION_SET_VALID:
            return updateFormField('driverLicenseDeclaration', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case AVERAGE_CLAUSE_CONSENT_SET_VALUE:
            return updateFormField('averageClauseConsent', (field) => ({ ...field, value: action.value }));

        case AVERAGE_CLAUSE_CONSENT_SET_VALID:
            return updateFormField('averageClauseConsent', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FULL_VALUE_DECLARATION_SET_VALUE:
            return updateFormField('fullValueDeclaration', (field) => ({ ...field, value: action.value}));

        case FULL_VALUE_DECLARATION_SET_VALID:
            return updateFormField('fullValueDeclaration', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MARKETING_CONSENT_SET_VALUE:
            return updateFormField('marketingConsent', (field) => ({ ...field, value: action.value}));

        case MARKETING_CONSENT_SET_VALID:
            return updateFormField('marketingConsent', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PRIVACY_POLICY_CONFIRMATION_SET_VALUE:
            return updateFormField('privacyPolicyConfirmation', (field) => ({ ...field, value: action.value }));

        case PRIVACY_POLICY_CONFIRMATION_SET_VALID:
            return updateFormField('privacyPolicyConfirmation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALUE:
            return updateFormField('adviceAcknowledgementIndemnity', (field) => ({ ...field, value: action.value }));

        case ADVICE_ACKNOWLEDGEMENT_INDEMNITY_SET_VALID:
            return updateFormField('adviceAcknowledgementIndemnity', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALUE:
            return updateFormField('noPremiumRefundAcknowledgement', (field) => ({ ...field, value: action.value }));

        case NO_PREMIUM_REFUND_ACKNOWLEDGEMENT_SET_VALID:
            return updateFormField('noPremiumRefundAcknowledgement', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALUE:
            return updateFormField('fullPremiumSettlementConsent', (field) => ({ ...field, value: action.value}));

        case FULL_PREMIUM_SETTLEMENT_CONSENT_SET_VALID:
            return updateFormField('fullPremiumSettlementConsent', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FINAL_DECLARATION_AGREEMENT_SET_VALUE:
            return updateFormField('finalDeclarationAgreement', (field) => ({ ...field, value: action.value}));

        case FINAL_DECLARATION_AGREEMENT_SET_VALID:
            return updateFormField('finalDeclarationAgreement', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Reset the state
        case REINITIALIZE:
            return initDeclaration;

        default:
            return state;
    }
};
