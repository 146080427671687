import { validateNumber ,validateDate } from  "@om/validation";
import { todayAsValidationParam } from '../date';
import {
    setSavingsGoalValid,
    setAchieveGoalDateValid,
    setCurrentSavingsValid,
    SAVINGS_GOAL_SET_VALUE,
    ACHIEVE_GOAL_DATE_SET_VALUE,
    CURRENT_SAVINGS_SET_VALUE
} from "../../../actions/savingsCalculator/howMuchYouNeedToSaveCalculator"

import { validationIsEqual } from "../status";
import {OmValidationDateHelper} from "@om/validation";

let prevValidationAction = null;
export const howMuchYouNeedToSaveCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {

            case SAVINGS_GOAL_SET_VALUE:
                error = validateNumber(action.value, {subject: 'savings goal', decimalPoints: 2, max: 10000000});
                return setSavingsGoalValid(error);

            case ACHIEVE_GOAL_DATE_SET_VALUE:
                error = validateDate(action.value, { subject: 'achieve goal date', min: todayAsValidationParam(), max: getDateInTwentyYears()});
                return setAchieveGoalDateValid(error);

            case CURRENT_SAVINGS_SET_VALUE:
                error = validateNumber(action.value, {subject: 'current savings', decimalPoints: 2, max: 10000000});
                return setCurrentSavingsValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
}

const getDateInTwentyYears = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    return `${day}/${month + 1}/${year + 20}`
}