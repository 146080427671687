import {
    PRICE_GRAPH_FUND_VALUE,
    PRICE_GRAPH_START_DATE,
    PRICE_GRAPH_END_DATE,
    PRICE_GRAPH_START_DATE_VALID,
    PRICE_GRAPH_END_DATE_VALID,
    PRICE_GRAPH_FORM_VALID,
    PRICE_GRAPH_FORM_INVALID,
    PRICE_GRAPH_SET_FUNDS_LIST,
    PRICE_GRAPH_SET_FUND_DATA,
    PRICE_GRAPH_API_PENDING_TRANSITION,
    PRICE_GRAPH_API_SUCCESS_TRANSITION,
    PRICE_GRAPH_API_FAILURE_TRANSITION
} from "../../actions/publicWeb/priceGraph";

import {
    FIELD_STATUS,
    FORM_STATUS,
    API_CALL_STATUS
} from '../status';

const initPriceGraph= {
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: API_CALL_STATUS.IDLE,
    selectedFund: {value: null, error: null, status: null},
    startDate: {value: null, error: null, status: null},
    endDate: {value: null, error: null, status: null},
    fundsList: [],
    fundData: [],
};

const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

const validUpdateInternal = (field, error) => ({
    ...field,
    error: error,
    status: mapError(error),
});

export default (state = initPriceGraph, action) => {
    const validUpdate = field => validUpdateInternal(field, action.error);

    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    switch (action.type) {
        case PRICE_GRAPH_FORM_VALID:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case PRICE_GRAPH_FORM_INVALID:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };
        case PRICE_GRAPH_FUND_VALUE:
            return updateFormField('selectedFund', field => ({
                ...field,
                value: action.value.detail.id,
            }));
        case PRICE_GRAPH_START_DATE:
            return updateFormField('startDate', field => ({
                ...field,
                value: action.value,
            }));
        case PRICE_GRAPH_START_DATE_VALID:
            return updateFormField('startDate', validUpdate);
        case PRICE_GRAPH_END_DATE:
            return updateFormField('endDate', field => ({
                ...field,
                value: action.value,
            }));
        case PRICE_GRAPH_END_DATE_VALID:
            return updateFormField('endDate', validUpdate);
        case PRICE_GRAPH_SET_FUNDS_LIST:
            return{
                ...state,
                fundsList: action.value,
            };
        case PRICE_GRAPH_SET_FUND_DATA:
            return{
                ...state,
                fundData: action.value,
            };
        case PRICE_GRAPH_API_PENDING_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            }
        case PRICE_GRAPH_API_SUCCESS_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            }
        case PRICE_GRAPH_API_FAILURE_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            }

        default:
            return state;
    }
}
