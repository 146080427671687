import { FORM_STATUS, TOGGLE_STATUS } from "../status";
import {
    FORM_VALID_TRANSITION_CONFIRM_DETAILS,
    FORM_INVALID_TRANSITION_CONFIRM_DETAILS,
    TOGGLE_DECLARATIONS_CONFIRM_DETAILS,
    RESET_VALUE_DECLARATION,
    DECLARATIONS_SIDEDRAWER_SET_VALUE,
    SIDE_DRAWER_CLOSED_SET_VALUE,
    DECLARATIONS_SIDEDRAWER_SET_VALID,
    EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_FAILURE,
    EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS,
    EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND,
    RESET_STATE_TRANSITION_CONFIRM_DETAILS,
    EFP_SKIP_CONFIRMATION_PAGE_SET_VALUE
} from '../../actions/easiplusFuneralPlan/confirmDetails';

export const initConfirmDetails = {
    formStatus: FORM_STATUS.INVALID,
    declarations: TOGGLE_STATUS.UNTOUCHED,
    declarationsSideDrawer: TOGGLE_STATUS.NO,
    declarationsValidationMessage: null,

    referenceNumber: null,
    submitError: null,
    submitSuccess: null,
    waitingForSubmission: null,

    skipConfirmationPage: false,
};

export default (state = initConfirmDetails, action) => {
    switch (action.type) {
        case FORM_VALID_TRANSITION_CONFIRM_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_CONFIRM_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case TOGGLE_DECLARATIONS_CONFIRM_DETAILS:
            if (action.value) return { ...state, declarations: FORM_STATUS.INVALID };
            if (action.error) return { ...state, declarations: FORM_STATUS.INVALID };
            return { ...state, declarations: state.declarations !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO };
        case RESET_VALUE_DECLARATION:
            return { ...state, declarations: null };
        case DECLARATIONS_SIDEDRAWER_SET_VALUE:
            return { ...state, declarationsSideDrawer: action.value };
        case SIDE_DRAWER_CLOSED_SET_VALUE:
            return { ...state, declarationsSideDrawer: null };
        case DECLARATIONS_SIDEDRAWER_SET_VALID:
            return { ...state, declarationsValidationMessage: action.error }

        case EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND:
            return {
                ...state,
                submitError: null,
                submitSuccess: false,
                waitingForSubmission: true,
                referenceNumber: null,
            };
        case EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_FAILURE:
            return {
                ...state,
                submitError: {
                    error: true
                },
                submitSuccess: false,
                waitingForSubmission: false,
                referenceNumber: null,
            };
        case EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS:
            return {
                ...state,
                submitError: null,
                submitSuccess: true,
                waitingForSubmission: false,
                referenceNumber: action.value,
            };
        case RESET_STATE_TRANSITION_CONFIRM_DETAILS:
            return { ...initConfirmDetails };

        case EFP_SKIP_CONFIRMATION_PAGE_SET_VALUE:
            return { ...state, skipConfirmationPage: action.value };
        default:
            return state;
    }
}