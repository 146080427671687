
// KBA events
export const CVS_KBA_SET_VALUE = 'customerVerificationService/knowledgeBasedAuthentication/setValue/';
export const CVS_KBA_RESET_VALUE = 'customerVerificationService/knowledgeBasedAuthentication/resetValue/';
export const CVS_KBA_SET_VALID = 'customerVerificationService/knowledgeBasedAuthentication/setValid/';
export const CVS_KBA_FETCH_VALUE = 'customerVerificationService/knowledgeBasedAuthentication/fetchValue/';
export const CVS_KBA_PERFORM_ACTION = 'customerVerificationService/knowledgeBasedAuthentication/performAction/';

export const IDENTITY_TOKEN_SET_VALUE = CVS_KBA_SET_VALUE + 'identityToken';
export const REFERENCE_SET_VALUE = CVS_KBA_SET_VALUE + 'reference';
export const QUESTIONS_FETCH_VALUE = CVS_KBA_FETCH_VALUE + 'questions';
export const QUESTIONS_SET_VALUE = CVS_KBA_SET_VALUE + 'questions';
export const SCORE_SET_VALUE = CVS_KBA_SET_VALUE + 'score';
export const ACTIVE_QUESTION_SET_VALUE = CVS_KBA_SET_VALUE + 'activeQuestion';
export const MODAL_SET_VALUE = CVS_KBA_SET_VALUE + 'modal';
export const SCORE_FETCH_VALUE = CVS_KBA_FETCH_VALUE + 'score';

export const KBA_SESSION_RESET_VALUE = CVS_KBA_RESET_VALUE + 'kbaSession';

export const TIMER_SET_VALUE = CVS_KBA_SET_VALUE + 'timer';
export const ANSWER_SET_VALUE = CVS_KBA_SET_VALUE + 'answer';
export const ANSWER_SET_VALID = CVS_KBA_SET_VALID + 'answer';

export const QUESTION_EXPIRED_PERFORM_ACTION = CVS_KBA_PERFORM_ACTION + 'questionExpired';

export const setKbaIdentityToken = value => ({ type: IDENTITY_TOKEN_SET_VALUE, value });
export const setKbaReference = value => ({ type: REFERENCE_SET_VALUE, value });
export const setKbaQuestions = value => ({ type: QUESTIONS_SET_VALUE, value });
export const fetchKbaQuestions = () => ({ type: QUESTIONS_FETCH_VALUE });

export const setKbaScore = value => ({ type: SCORE_SET_VALUE, value });
export const setNextQuestion = () => ({ type: ACTIVE_QUESTION_SET_VALUE });
export const setKbaModal = value => ({ type: MODAL_SET_VALUE, value });
export const resetKbaSession = () => ({ type: KBA_SESSION_RESET_VALUE });
export const fetchKbaScore = () => ({ type: SCORE_FETCH_VALUE });

export const setAnswerValue = value => ({ type: ANSWER_SET_VALUE, value });
export const setAnswerValid = error => ({ type: ANSWER_SET_VALID, error });
export const setTimerValue = value => ({ type: TIMER_SET_VALUE, value });

export const setQuestionExpired = () => ({ type: QUESTION_EXPIRED_PERFORM_ACTION });