import { sendComplaintFailure, sendComplaintSuccess } from "../../../actions/customerComplaint/complaintArbitration";
import { FORM_TYPE } from "../../../actions/customerComplaint/type";
import { getComplaintType, getForm, getReceiverEmailAddress } from "../../../selectors/customerComplaint/complaintArbitration";
import axios from "axios";

const header = {
    'Content-Type': 'application/json',
};

export const complaintArbitrationService = (store, next, action) => {
    const result = next(action);
    const bodyParams = createBodyParams(store);

    (async () => {
        store.dispatch(await sendComplaintEmail(bodyParams) ? sendComplaintSuccess() : sendComplaintFailure());
    })();

    return result;
}

export const sendComplaintEmail = async bodyParams => {
    try {
        const response = await axios.post('/om-api/customer-complaint/complaint', bodyParams, { headers: header });
        return response.status === 200;
    } catch (error) {
        return false;
    }
}

export const createBodyParams = (store) => {
    const complaintForm = getForm(store.getState());
    const complaintType = getComplaintType(store.getState());
    const isComplaint = complaintType && complaintType === FORM_TYPE.COMPLAINT;
    const receiverEmailAddress = getReceiverEmailAddress(store.getState());
    const policyNumber = complaintForm.policyNumber.value && complaintForm.policyNumber.value.trim() === '' ? null : complaintForm.policyNumber.value;

    return {
        isComplaint: isComplaint,
        receiverEmailAddress: receiverEmailAddress,
        sendCustomerCopy: complaintForm.sendMeCopy,
        formData: {
            title: complaintForm.title.value,
            firstName: complaintForm.firstName.value,
            surname: complaintForm.surname.value,
            cellphone: complaintForm.cellphoneNumber.value,
            email: complaintForm.email.value,
            identityType: complaintForm.identityType.value,
            idNumber: complaintForm.idNumber.value,
            passportNumber: complaintForm.passportNumber.value,
            passportCountry: complaintForm.passportCountry.value,
            policyNumber: policyNumber || '',
            productType: complaintForm.productType.value || '',
            complaintRelation: complaintForm.complaintRelation.value || '',
            complaintTitle: complaintForm.complaintTitle.value,
            complaintDescription: complaintForm.complaintDescription.value,
        }
    }
}
