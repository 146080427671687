import axios from 'axios';
import { v4 as uuid } from 'uuid';

import {
    getNameValue,
    getAgentPhoneNumberValue,
} from '../../../selectors/roaTravelInsurance/travelAgentModal';

import { dropOffLeadPersonalDetails } from '../../../actions/roaTravelInsurance/aboutYou';
import {
    getDropOffDetails,
    getFirstNameValue,
    getSurnameValue,
    getPhoneNumberValue,
    getEmailValue,
} from '../../../selectors/roaTravelInsurance/aboutYou';

export const dropOffLeadsService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const newDropOffDetails = buildPayload(state);
    const previousDropOffDetails = getDropOffDetails(state);
    const dropOffDetailsChanged =
        JSON.stringify(previousDropOffDetails) !==
        JSON.stringify(newDropOffDetails);

    if (!dropOffDetailsChanged) return result;

    (async () => {
        if (await submitDropOffLead(newDropOffDetails)) {
            store.dispatch(dropOffLeadPersonalDetails(newDropOffDetails));
        }
    })();
    return result;
};

export const submitDropOffLead = async payload => {
    try {
        const url = '/om-api/roa-travel-insurance/drop-off-leads';
        const config = { headers: { 'Content-Type': 'application/json' } };
        const response = await axios.post(url, payload, config);
        return response.status === 200;
    } catch (error) {
        return false;
    }
};

export const buildPayload = state => ({
    FIRST_NAME: getFirstNameValue(state).value,
    SECOND_NAME: getSurnameValue(state).value,
    PHONE_NUMBER: getPhoneNumberValue(state).value,
    EMAIL_ADDRESS: getEmailValue(state).value,
    INTERMEDIARY_PHONE_NUMBER: getAgentPhoneNumberValue(state).value || '',
    INTEMEDIARY_CODE: getNameValue(state).value || '',
    SOURCE_SYSTEM_ID: uuid(),
    SOURCE_SYSTEM_NAME: '176830001',
    POLICY_TYPE: 'TravelInsurance',
});
