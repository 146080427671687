import {
  validateAlphaNumericString,
  validateIdNumber,
  validateName,
  validatePassportNumber,
  validatePoBoxNumber,
  validatePostalCode,
  validatePrivateBag,
  validateSingleSelection,
  validateStreetAddress,
  validateStreetWithNumber,
} from "@om/validation";
import {
  CITY_SET_VALUE,
  COMPLEX_SET_VALUE,
  COUNTRY_SET_VALUE,
  ID_SET_VALUE,
  MARITAL_STATUS_SET_VALUE,
  MINOR_FIRST_NAMES_SET_VALUE,
  MINOR_ID_SET_VALUE,
  MINOR_SURNAME_SET_VALUE,
  MINOR_TITLE_SET_VALUE,
  NATIONALITY_SET_VALUE,
  POSTAL_ADDRESS_METHOD_VALUE,
  POSTAL_ADDRESS_SAME_VALUE,
  POSTAL_CITY_SET_VALUE,
  POSTAL_CODE_SET_VALUE,
  POSTAL_COMPLEX_SET_VALUE,
  POSTAL_COUNTRY_SET_VALUE,
  POSTAL_POSTAL_CODE_SET_VALUE,
  POSTAL_STREET_ADDRESS_SET_VALUE,
  POSTAL_SUBURB_SET_VALUE,
  PO_BOX_SET_VALUE,
  PO_COUNTRY_SET_VALUE,
  PO_POSTAL_CODE_SET_VALUE,
  PO_POST_OFFICE_SET_VALUE,
  PRIVATE_BAG_SET_VALUE,
  PRIVATE_COUNTRY_SET_VALUE,
  PRIVATE_POSTAL_CODE_SET_VALUE,
  PRIVATE_POST_OFFICE_SET_VALUE,
  SPOUSE_FIRST_NAMES_SET_VALUE,
  SPOUSE_ID_NUMBER_SET_VALUE,
  SPOUSE_PASSPORT_NUMBER_SET_VALUE,
  SPOUSE_SURNAME_SET_VALUE,
  SPOUSE_TITLE_SET_VALUE,
  STREET_ADDRESS_SET_VALUE,
  SUBURB_SET_VALUE,
  setCityValid,
  setComplexValid,
  setCountryValid,
  setIdValid,
  setMaritalStatusValid,
  setMinorFirstNamesValid,
  setMinorIdValid,
  setMinorSurnameValid,
  setMinorTitleValid,
  setNationalityValid,
  setPoBoxValid,
  setPoCountryValid,
  setPoPostOfficeValid,
  setPoPostalCodeValid,
  setPostalAddressMethodValid,
  setPostalAddressSameValid,
  setPostalCityValid,
  setPostalCodeValid,
  setPostalComplexValid,
  setPostalCountryValid,
  setPostalPostalCodeValid,
  setPostalStreetAddressValid,
  setPostalSuburbValid,
  setPrivateBagValid,
  setPrivateCountryValid,
  setPrivatePostOfficeValid,
  setPrivatePostalCodeValid,
  setSpouseFirstNamesValid,
  setSpouseIdNumberValid,
  setSpousePassportNumberValid,
  setSpouseSurnameValid,
  setSpouseTitleValid,
  setStreetAddressValid,
  setSuburbValid,
} from "../../../actions/taxFreeSavingsAccount/auxiliaryDetails";
import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const auxiliaryDetailsValidation = (store, next, action) => {
  const validationAction = (() => {
    let error;
    switch (action.type) {
      case MARITAL_STATUS_SET_VALUE:
        error = validateSingleSelection(action.value, "Marital Status");
        return setMaritalStatusValid(error);

      case MINOR_TITLE_SET_VALUE:
        error = validateSingleSelection(action.value, "Title");
        return setMinorTitleValid(error);

      case MINOR_FIRST_NAMES_SET_VALUE:
        error = validateName(action.value, { min: 2, max: 48, subject: "First name", pronoun: "their" });
        return setMinorFirstNamesValid(error);

      case MINOR_SURNAME_SET_VALUE:
        error = validateName(action.value, { min: 2, max: 64, subject: "Surname", pronoun: "their" });
        return setMinorSurnameValid(error);

      case MINOR_ID_SET_VALUE:
        error = validateIdNumber(action.value, {
          required: true,
          subject: "South African ID number",
          checkAge: true,
          maxAge: 18,
          minAge: 1,
          pronoun: "their",
        });
        return setMinorIdValid(error);

      case ID_SET_VALUE:
        error = validateIdNumber(action.value, {
          required: true,
          subject: "South African ID number",
          checkAge: true,
          minAge: 18,
        });
        return setIdValid(error);

      case NATIONALITY_SET_VALUE:
        error = validateSingleSelection(action.value, "Nationality");
        return setNationalityValid(error);

      case SPOUSE_TITLE_SET_VALUE:
        error = validateSingleSelection(action.value, "Title");
        return setSpouseTitleValid(error);

      case SPOUSE_FIRST_NAMES_SET_VALUE:
        error = validateName(action.value, { min: 2, max: 48, subject: "First name", pronoun: "their" });
        return setSpouseFirstNamesValid(error);

      case SPOUSE_SURNAME_SET_VALUE:
        error = validateName(action.value, { min: 2, max: 64, subject: "Surname", pronoun: "their" });
        return setSpouseSurnameValid(error);

      case SPOUSE_ID_NUMBER_SET_VALUE:
        error = validateIdNumber(action.value, {
          required: true,
          subject: "South African ID number",
          pronoun: "their",
        });
        return setSpouseIdNumberValid(error);

      case SPOUSE_PASSPORT_NUMBER_SET_VALUE:
        error = validatePassportNumber(action.value);
        return setSpousePassportNumberValid(error);

      case COMPLEX_SET_VALUE:
        error = validateStreetAddress(action.value, {
          min: 2,
          max: 30,
          required: false,
          subject: "Complex or building",
        });
        return setComplexValid(error);

      case STREET_ADDRESS_SET_VALUE:
        error = validateStreetWithNumber(action.value, { min: 2, max: 30, subject: "Street address", required: true });
        return setStreetAddressValid(error);

      case SUBURB_SET_VALUE:
        error = validateAlphaNumericString(action.value, { min: 1, max: 30, subject: "Suburb", required: false });
        return setSuburbValid(error);

      case CITY_SET_VALUE:
        error = validateAlphaNumericString(action.value, { min: 1, max: 30, subject: "City" });
        return setCityValid(error);

      case POSTAL_CODE_SET_VALUE:
        error = validatePostalCode(action.value);
        return setPostalCodeValid(error);

      case COUNTRY_SET_VALUE:
        error = validateSingleSelection(action.value, "country");
        return setCountryValid(error);

      case POSTAL_ADDRESS_METHOD_VALUE:
        error = validateSingleSelection(action.value, "option");
        return setPostalAddressMethodValid(error);

      case POSTAL_ADDRESS_SAME_VALUE:
        error = validateSingleSelection(action.value, "option");
        return setPostalAddressSameValid(error);

      case POSTAL_COMPLEX_SET_VALUE:
        error = validateStreetAddress(action.value, {
          min: 2,
          max: 30,
          required: false,
          subject: "Complex or building",
        });
        return setPostalComplexValid(error);

      case POSTAL_STREET_ADDRESS_SET_VALUE:
        error = validateStreetWithNumber(action.value, { min: 2, max: 30, subject: "Street name" });
        return setPostalStreetAddressValid(error);

      case POSTAL_SUBURB_SET_VALUE:
        error = validateAlphaNumericString(action.value, { min: 1, max: 30, subject: "Suburb", required: false });
        return setPostalSuburbValid(error);

      case POSTAL_CITY_SET_VALUE:
        error = validateAlphaNumericString(action.value, { min: 1, max: 30, subject: "City" });
        return setPostalCityValid(error);

      case POSTAL_POSTAL_CODE_SET_VALUE:
        error = validatePostalCode(action.value);
        return setPostalPostalCodeValid(error);

      case POSTAL_COUNTRY_SET_VALUE:
        error = validateSingleSelection(action.value, "country");
        return setPostalCountryValid(error);

      case PO_BOX_SET_VALUE:
        error = validatePoBoxNumber(action.value);
        return setPoBoxValid(error);

      case PO_POST_OFFICE_SET_VALUE:
        error = validateAlphaNumericString(action.value, { min: 1, max: 30, subject: "Post office" });
        return setPoPostOfficeValid(error);

      case PO_POSTAL_CODE_SET_VALUE:
        error = validatePostalCode(action.value);
        return setPoPostalCodeValid(error);

      case PO_COUNTRY_SET_VALUE:
        error = validateSingleSelection(action.value, "country");
        return setPoCountryValid(error);

      case PRIVATE_BAG_SET_VALUE:
        error = validatePrivateBag(action.value, { min: 4, max: 8, subject: "Private bag number" });
        return setPrivateBagValid(error);

      case PRIVATE_POST_OFFICE_SET_VALUE:
        error = validateAlphaNumericString(action.value, { min: 1, max: 30, subject: "Post office" });
        return setPrivatePostOfficeValid(error);

      case PRIVATE_POSTAL_CODE_SET_VALUE:
        error = validatePostalCode(action.value);
        return setPrivatePostalCodeValid(error);

      case PRIVATE_COUNTRY_SET_VALUE:
        error = validateSingleSelection(action.value, "country");
        return setPrivateCountryValid(error);

      default:
        return null;
    }
  })();

  if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
    return next(action);
  }

  store.dispatch(validationAction);
  prevValidationAction = validationAction;
  return next(action);
};
