export const ADVANCED_CALCULATOR_SET_VALUE = 'incomeTaxCalculator/advancedCalculator/setValue/';
export const ADVANCED_CALCULATOR_SET_VALID = 'incomeTaxCalculator/advancedCalculator/setValid/';
export const ADVANCED_CALCULATOR_TOGGLE_ACCORDION = 'incomeTaxCalculator/advancedCalculator/toggleAccordion/';

export const AGE_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'age';
export const AGE_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'age';
export const INCOME_FREQUENCY_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'incomeFrequency';
export const INCOME_FREQUENCY_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'incomeFrequency';
export const INCOME_VALUE_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'incomeValue';
export const INCOME_VALUE_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'incomeValue';
export const PRODUCT_RECOMMENDATION_SET_VALUE =  ADVANCED_CALCULATOR_SET_VALUE + 'productRecommendation';

export const OTHER_INCOME_VALUE_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'otherIncomeValue';
export const OTHER_INCOME_VALUE_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'otherIncomeValue';
export const OTHER_INCOME_DEDUCTION_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'otherIncomeDeduction';
export const OTHER_INCOME_DEDUCTION_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'otherIncomeDeduction';
export const OTHER_INCOME_DEDUCTION_TOGGLE_ACCORDION = ADVANCED_CALCULATOR_TOGGLE_ACCORDION + 'otherIncome';

export const MEDICAL_AID_OWN_CONTRIBUTION_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'medicalAidOwnContributionValue';
export const MEDICAL_AID_OWN_CONTRIBUTION_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'medicalAidOwnContributionValid';
export const MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'medicalAidEmployerContributionValue';
export const MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'medicalAidEmployerContributionValid';
export const MEDICAL_AID_DEPENDENTS_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'medicalAidDependents';
export const MEDICAL_AID_DEPENDENTS_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'medicalAidDependents';
export const MEDICAL_AID_TOGGLE_ACCORDION = ADVANCED_CALCULATOR_TOGGLE_ACCORDION + 'medicalAid';


export const PENSION_FUND_OWN_CONTRIBUTION_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'pensionFundOwnContributionValue';
export const PENSION_FUND_OWN_CONTRIBUTION_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'pensionFundOwnContributionValid';
export const PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'pensionFundEmployerContributionValue';
export const PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'pensionFundEmployerContributionValid';
export const PENSION_FUND_TOGGLE_ACCORDION = ADVANCED_CALCULATOR_TOGGLE_ACCORDION + 'pensionFund';


export const PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'providentFundOwnContributionValue';
export const PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'providentFundOwnContributionValid';
export const PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'providentFundEmployerContributionValue';
export const PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'providentFundEmployerContributionValid';
export const PROVIDENT_FUND_TOGGLE_ACCORDION = ADVANCED_CALCULATOR_TOGGLE_ACCORDION + 'providentFund';

export const RETIREMENT_ANNUITY_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'retirementValue';
export const RETIREMENT_ANNUITY_SET_VALID = ADVANCED_CALCULATOR_SET_VALID + 'retirementValid';
export const RETIREMENT_ANNUITY_TOGGLE_ACCORDION = ADVANCED_CALCULATOR_TOGGLE_ACCORDION + 'retirement';


export const REFERENCE_DATA_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'referenceData';
export const RESULTS_VIEW_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'viewResults';
export const RESULTS_SET_VALUE = ADVANCED_CALCULATOR_SET_VALUE + 'setResults';
export const SHOW_FIELD_DETAILS = ADVANCED_CALCULATOR_SET_VALUE + 'showFielDetails';

export const FORM_VALID_ADVANCED_CALCULATION = 'incomeTaxCalculator/advancedCalculator/formValid';
export const FORM_INVALID_ADVANCED_CALCULATION = 'incomeTaxCalculator/advancedCalculator/formInvalid';
export const REINITIALIZE_STATE_ADVANCED_CALCULATOR = 'incomeTaxCalculator/advancedCalculator/reinitialize';

export const transitionAdvancedCalculator = {
    formValid: () => ({ type: FORM_VALID_ADVANCED_CALCULATION }),
    formInvalid: () => ({ type: FORM_INVALID_ADVANCED_CALCULATION }),
};

export const setAgeValue = value => ({ type: AGE_SET_VALUE, value });
export const setAgeValid = error => ({ type: AGE_SET_VALID, error });
export const setIncomeFrequencyValue = value => ({ type: INCOME_FREQUENCY_SET_VALUE, value });
export const setIncomeFrequencyValid = error => ({ type: INCOME_FREQUENCY_SET_VALID, error });
export const setIncomeValue = value => ({ type: INCOME_VALUE_SET_VALUE, value });
export const setIncomeValueValid = error => ({ type: INCOME_VALUE_SET_VALID, error });

export const setOtherIncomeValue = value => ({ type: OTHER_INCOME_VALUE_SET_VALUE, value });
export const setOtherIncomeValueValid = error => ({ type: OTHER_INCOME_VALUE_SET_VALID, error });
export const setOtherIncomeDeduction = value => ({ type: OTHER_INCOME_DEDUCTION_SET_VALUE, value });
export const setOtherIncomeDeductionValid = error => ({ type: OTHER_INCOME_DEDUCTION_SET_VALID, error });
export const setOtherIncomeToggle = () => ({ type: OTHER_INCOME_DEDUCTION_TOGGLE_ACCORDION })

export const setMedicalAidOwnContributionValue = value => ({ type: MEDICAL_AID_OWN_CONTRIBUTION_SET_VALUE, value });
export const setMedicalAidOwnContributionValid = error => ({ type: MEDICAL_AID_OWN_CONTRIBUTION_SET_VALID, error });
export const setMedicalAidEmployerContributionValue = value => ({ type: MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALUE, value });
export const setMedicalAidEmployerContributionValid = error => ({ type: MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALID, error });
export const setMedicalAidDependentsValue = value => ({ type: MEDICAL_AID_DEPENDENTS_SET_VALUE, value });
export const setMedicalAidDependentsValid = error => ({ type: MEDICAL_AID_DEPENDENTS_SET_VALID, error });
export const setMedicalAidToggle = () => ({ type: MEDICAL_AID_TOGGLE_ACCORDION });

export const setPensionFundOwnContributionValue = value => ({ type: PENSION_FUND_OWN_CONTRIBUTION_SET_VALUE, value });
export const setPensionFundOwnContributionValid = error => ({ type: PENSION_FUND_OWN_CONTRIBUTION_SET_VALID, error });
export const setPensionFundEmployerContributionValue = value => ({ type: PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE, value });
export const setPensionFundEmployerContributionValid = error => ({ type: PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALID, error });
export const setPensionFundToggle = () => ({ type: PENSION_FUND_TOGGLE_ACCORDION });

export const setProvidentFundOwnContributionValue = value => ({ type: PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALUE, value });
export const setProvidentFundOwnContributionValid = error => ({ type: PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALID, error });
export const setProvidentFundEmployerContributionValue = value => ({ type: PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE, value });
export const setProvidentFundEmployerContributionValid = error => ({ type: PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALID, error });
export const setProvidentFundToggle = () => ({ type: PROVIDENT_FUND_TOGGLE_ACCORDION });

export const setRetirementAnnuityValue = value => ({ type: RETIREMENT_ANNUITY_SET_VALUE, value });
export const setRetirementAnnuityValid = error => ({ type: RETIREMENT_ANNUITY_SET_VALID, error });
export const setRetirementAnnuityToggle = () => ({ type: RETIREMENT_ANNUITY_TOGGLE_ACCORDION });

export const setViewTaxResult = value => ({ type: RESULTS_VIEW_VALUE, value });
export const setTaxResult = value => ({ type: RESULTS_SET_VALUE, value });
export const setReferenceData = () => ({ type: REFERENCE_DATA_SET_VALUE });
export const reInitialize = () => ({ type: REINITIALIZE_STATE_ADVANCED_CALCULATOR });
export const setViewRecommendation = () => ({ type: PRODUCT_RECOMMENDATION_SET_VALUE });
export const showFielDetails = value => ({ type: SHOW_FIELD_DETAILS, value });
