import { atRetirementValidation } from './atRetirement/index';
import { raOptimalValidation } from './raOptimal/index';
import { efpValidation } from './easiplusFuneralPlan/index';
import { publicWebValidation } from './publicWeb/index';
import { cvsValidation } from './customerVerificationService';
import { incomeTaxCalculatorValidation } from './incomeTaxCalculator';
import { covidSelfAssessmentValidation } from './covidSelfAssessment';
import { personalLoansValidation } from './personalLoansCalculator';
import { savingsCalculatorValidation } from './savingsCalculator';
import { retirementCalculatorValidation } from './retirementCalculator';
import { taxFreeSavingsAccountValidation } from './taxFreeSavingsAccount';
import { roaQuoteToolValidation } from './roaQuoteTools';
import { priceTableValidation } from './priceTable';
import { customerComplaintValidation } from './customerComplaint';
import { budgetBenchmarkValidation } from './budgetBenchmarkCalculator';
import { pastPerformanceCalculatorValidation } from './pastPerfomanceCalculator';
import { educationCalculatorValidation } from './educationCalculator';
import { roaCarInsuranceValidation } from './roaCarInsurance';
import { debtRepaymentValidation } from './debtRepaymentCalculator';
import { roaTravelInsuranceValidation } from './roaTravelInsurance';
import { customerSatisfactionSurveyValidation } from './customerSatisfactionSurvey';
import { investmentCalculatorValidation } from './investmentCalculator';
import { ltdValidation } from './ltd';
import { makeAClaimValidation } from './makeAClaim';
import { digitalOnboardingValidation } from './digitalOnboarding';
import { ugMotorInsuranceValidation } from './ugMotorInsurance';
import { domesticInsuranceValidation } from './domesticInsurance';
import { biasharaFlexiValidation } from './biasharaFlexi';
import { dlpValidation } from './digiLifeLoanProtection';
import { healthInsuranceValidation } from './healthInsurancRW';
import { travelInsuranceValidation } from './travel';
import { doctorsPiValidation } from './doctorsPi';
import { personalAccidentValidation } from './personalAccident';
import { personalPensionPlanValidation } from './personalPensionPlan';
import { allsureInsuranceValidation } from './allsureInsurance';
import { savingsPotCalculatorValidation } from './savingsPotCalculator';
import { digitalSavingsValidation } from './digitalSavings';
import { rwMotorInsuranceValidation } from './motorPivateRW';

export const validation = (store) => (next) => (action) => {
    const executedRules =
        rwMotorInsuranceValidation(store, next, action) ||
        healthInsuranceValidation(store, next, action) ||
        personalPensionPlanValidation(store, next, action) ||
        digitalSavingsValidation(store, next, action) ||
        personalAccidentValidation(store, next, action) ||
        biasharaFlexiValidation(store, next, action) ||
        travelInsuranceValidation(store, next, action) ||
        dlpValidation(store, next, action) ||
        allsureInsuranceValidation(store, next, action) ||
        domesticInsuranceValidation(store, next, action) ||
        doctorsPiValidation(store, next, action) ||
        ugMotorInsuranceValidation(store, next, action) ||
        atRetirementValidation(store, next, action) ||
        raOptimalValidation(store, next, action) ||
        efpValidation(store, next, action) ||
        cvsValidation(store, next, action) ||
        publicWebValidation(store, next, action) ||
        covidSelfAssessmentValidation(store, next, action) ||
        incomeTaxCalculatorValidation(store, next, action) ||
        personalLoansValidation(store, next, action) ||
        savingsCalculatorValidation(store, next, action) ||
        retirementCalculatorValidation(store, next, action) ||
        savingsPotCalculatorValidation(store, next, action) ||
        taxFreeSavingsAccountValidation(store, next, action) ||
        roaQuoteToolValidation(store, next, action) ||
        priceTableValidation(store, next, action) ||
        customerComplaintValidation(store, next, action) ||
        budgetBenchmarkValidation(store, next, action) ||
        pastPerformanceCalculatorValidation(store, next, action) ||
        roaCarInsuranceValidation(store, next, action) ||
        debtRepaymentValidation(store, next, action) ||
        roaTravelInsuranceValidation(store, next, action) ||
        educationCalculatorValidation(store, next, action) ||
        customerSatisfactionSurveyValidation(store, next, action) ||
        ltdValidation(store, next, action) ||
        investmentCalculatorValidation(store, next, action) ||
        digitalOnboardingValidation(store, next, action) ||
        makeAClaimValidation(store, next, action) ||
        allsureInsuranceValidation(store, next, action);

    if (executedRules) {
        return executedRules;
    }

    return next(action);
};
