import {
    SERVICE_PROVIDER_TRANSITION_CONSENT,
    FORM_INVALID_TRANSITION_CONSENT,
    FORM_VALID_TRANSITION_CONSENT,
    PRIVACY_POLICY_CHECKBOX_SET_VALUE,
    PRIVACY_POLICY_SIDEDRAWER_SET_VALUE,
    SUBSCRIBER_AGREEMENT_CHECKBOX_SET_VALUE,
    SUBSCRIBER_AGREEMENT_SIDEDRAWER_SET_VALUE,
    TERMS_CLOSE_MODAL,
    TERMS_OF_USE_CHECKBOX_SET_VALUE,
    TERMS_OF_USE_SIDEDRAWER_SET_VALUE,
    TERMS_OPEN_MODAL,
    RESET_CONSENT_PAGE_SET_VALUE
} from "../../actions/customerVerificationService/consent";
import {FORM_STATUS} from "../status";

export const closedSideDrawers = () => ({
    replacementPolicy: false,
    subscriberAgreement: false,
    termsOfUse: false,
});

export const initConsent = {
    formStatus: null,
    termsModalIsOpen: false,
    serviceProvider: 0,
    privacyPolicyIsChecked: false,
    subscriberAgreementIsChecked: false,
    termOfUseIsChecked: false,
    sideDrawerIsOpen: closedSideDrawers(),
};

export default (state = initConsent, action) => {
    switch (action.type) {
        case SERVICE_PROVIDER_TRANSITION_CONSENT:
            return {...state, serviceProvider: action.value};

        case FORM_VALID_TRANSITION_CONSENT:
            return {...state, formStatus: FORM_STATUS.VALID};
        case FORM_INVALID_TRANSITION_CONSENT:
            return {...state, formStatus: FORM_STATUS.INVALID};

        case TERMS_OPEN_MODAL:
            return {...state, termsModalIsOpen: true};
        case TERMS_CLOSE_MODAL:
            return {...state, termsModalIsOpen: false};

        case PRIVACY_POLICY_SIDEDRAWER_SET_VALUE:
            return {
                ...state,
                sideDrawerIsOpen: {...closedSideDrawers(), privacyPolicy: action.value}
            };
        case SUBSCRIBER_AGREEMENT_SIDEDRAWER_SET_VALUE:
            return {
                ...state,
                sideDrawerIsOpen: {...closedSideDrawers(), subscriberAgreement: action.value}
            };
        case TERMS_OF_USE_SIDEDRAWER_SET_VALUE:
            return {
                ...state,
                sideDrawerIsOpen: {...closedSideDrawers(), termsOfUse: action.value}
            };
   
        case PRIVACY_POLICY_CHECKBOX_SET_VALUE:
            return {...state, privacyPolicyIsChecked: action.value};
        case SUBSCRIBER_AGREEMENT_CHECKBOX_SET_VALUE:
            return {...state, subscriberAgreementIsChecked: action.value};
        case TERMS_OF_USE_CHECKBOX_SET_VALUE:
            return {...state, termOfUseIsChecked: action.value};

        case RESET_CONSENT_PAGE_SET_VALUE:
            return { ...initConsent };

        default:
            return state;
    }
};

