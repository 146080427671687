import { getIsNidaApiPending, getPersonalDetailsForm } from '../../../selectors/motorPrivateRW/personalDetails';
import { apiTransitionNida, setNidaValue,  } from '../../../actions/motorPrivateRW/personalDetails';
import { getAccessToken } from '../../../selectors/motorPrivateRW/payments';
import axios from 'axios';

export const getNidaListService = (store, next, action) => {
    (async () => {
        if (!getIsNidaApiPending(store.getState())) {
            store.dispatch(apiTransitionNida.pending());
            try {
                const resp = await getNida(store.getState());
                store.dispatch(setNidaValue(resp.data));
                store.dispatch(apiTransitionNida.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionNida.failure());
            }
        }
    })();
    return next(action);
};

const getNida = async (state) => {
    const loginData = getAccessToken(state);
    const getPersonalDetails = getPersonalDetailsForm(state);

    try {
        const payload = {
            customerID: getPersonalDetails.idNumber.value
        }
        const url = '/om-api/rw-motor-insurance/nida';
        return await axios.post(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData?.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
