import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    FIRST_NAME_SET_VALUE,
    FIRST_NAME_SET_VALID,
    LAST_NAME_SET_VALUE,
    LAST_NAME_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    NATIONALITY_SET_VALUE,
    NATIONALITY_SET_VALID,
    OCCUPATION_SET_VALUE,
    OCCUPATION_SET_VALID,
    ID_NUMBER_SET_VALUE,
    ID_NUMBER_SET_VALID,
    TIN_NUMBER_SET_VALUE,
    TIN_NUMBER_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    POSTAL_ADDRESS_SET_VALUE,
    POSTAL_ADDRESS_SET_VALID,
    REINITIALIZE,
    SELECTED_FORM_SET_VALUE,
    FORM_VALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM,
    FORM_INVALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM,
    API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM,
    API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM,
    API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM,
    API_PENDING_TRANSITION_NATIONALITIES,
    API_SUCCESS_TRANSITION_NATIONALITIES,
    API_FAILURE_TRANSITION_NATIONALITIES,
    SET_NATIONALITIES_VALUE,
} from '../../actions/personalPensionPlan/personalDetails';

export const initPersonalDetails = {
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    selectedForm: 'personalDetails',
    nationalities: null,
    nationalityApiCallStatus: null,
    form: {
        firstName: { value: null, error: null, status: null },
        lastName: { value: null, error: null, status: null },
        dob: { value: null, error: null, status: null },
        gender: { value: null, error: null, status: null },
        nationality: { value: null, error: null, status: null },
        occupation: { value: null, error: null, status: null },
        idNumber: { value: null, error: null, status: null },
        tinNumber: { value: null, error: null, status: null },
        phoneNumber: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        postalAddress: { value: null, error: null, status: null },
    },
};

export default (state = initPersonalDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case REINITIALIZE:
            return initPersonalDetails;

        case SELECTED_FORM_SET_VALUE:
            return { ...state, selectedForm: action.value };

        case FORM_VALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case API_PENDING_TRANSITION_NATIONALITIES:
            return { ...state, nationalityApiCallStatus: API_CALL_STATUS.PENDING };

        case API_SUCCESS_TRANSITION_NATIONALITIES:
            return { ...state, nationalityApiCallStatus: API_CALL_STATUS.SUCCESS };

        case API_FAILURE_TRANSITION_NATIONALITIES:
            return { ...state, nationalityApiCallStatus: API_CALL_STATUS.FAILURE };

        case SET_NATIONALITIES_VALUE:
            return { ...state, nationalities: action.value };

        case API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));
        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LAST_NAME_SET_VALUE:
            return updateFormField('lastName', (field) => ({
                ...field,
                value: action.value,
            }));
        case LAST_NAME_SET_VALID:
            return updateFormField('lastName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DOB_SET_VALUE:
            return updateFormField('dob', (field) => ({
                ...field,
                value: action.value,
            }));
        case DOB_SET_VALID:
            return updateFormField('dob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case GENDER_SET_VALUE:
            return updateFormField('gender', (field) => ({ ...field, value: action.value }));
        case GENDER_SET_VALID:
            return updateFormField('gender', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NATIONALITY_SET_VALUE:
            return updateFormField('nationality', (field) => ({
                ...field,
                value: action.value,
            }));
        case NATIONALITY_SET_VALID:
            return updateFormField('nationality', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case OCCUPATION_SET_VALUE:
            return updateFormField('occupation', (field) => ({
                ...field,
                value: action.value,
            }));
        case OCCUPATION_SET_VALID:
            return updateFormField('occupation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_NUMBER_SET_VALUE:
            return updateFormField('idNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case ID_NUMBER_SET_VALID:
            return updateFormField('idNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TIN_NUMBER_SET_VALUE:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case TIN_NUMBER_SET_VALID:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('email', (field) => ({
                ...field,
                value: action.value,
            }));
        case EMAIL_SET_VALID:
            return updateFormField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case POSTAL_ADDRESS_SET_VALUE:
            return updateFormField('postalAddress', (field) => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_ADDRESS_SET_VALID:
            return updateFormField('postalAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
