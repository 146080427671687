import { createSelector } from "reselect";

export const getSharePrice = state => state.publicWeb.sharePrice;

const makeGetField = fieldId => createSelector(
    getSharePrice,
    sharePrice => sharePrice[fieldId]
);

export const getJhbSharePrice = createSelector(
    getSharePrice,
    sharePrice => sharePrice.jhbSharePrice,
);

export const getLdnSharePrice = createSelector(
    getSharePrice,
    sharePrice => sharePrice.ldnSharePrice,
);

export const getJhbSharePriceFetching = createSelector(
    getSharePrice,
    sharePrice => sharePrice.jhbSharePriceFetching,
);

export const getLdnSharePriceFetching = createSelector(
    getSharePrice,
    sharePrice => sharePrice.ldnSharePriceFetching,
);

export const getIndicators = createSelector(
    getSharePrice,
    sharePrice => sharePrice.indicators,
);

export const getCommoditiesToggle = createSelector(
    getSharePrice,
    sharePrice => sharePrice.commoditiesExpanded,
);

export const getCurrenciesToggle = createSelector(
    getSharePrice,
    sharePrice => sharePrice.currenciesExpanded,
);

export const getOffshoreToggle = createSelector(
    getSharePrice,
    sharePrice => sharePrice.offshoreExpanded,
);

export const getLocalToggle = createSelector(
    getSharePrice,
    sharePrice => sharePrice.localExpanded,
);
