import { createSelector } from 'reselect';
import {
    FORM_STATUS,
    TOGGLE_STATUS,
    API_CALL_STATUS,
} from '../../reducers/status';

export const getQualifyingCheckCallMeBack = (state => {
    return state.publicWeb.qualifyingCheckCallMeBack
});

export const getQualifyingCallMeBackFormStatusValid = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.formStatus === FORM_STATUS.VALID
)
export const getCaptureDetailsCreditConsent = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.creditCheckConsent
)

export const getCaptureDetailsCreditConsentValid = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.creditCheckConsent === TOGGLE_STATUS.YES
)

export const getCaptureDetailsCreditConsentInvalid = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.creditCheckConsent === TOGGLE_STATUS.NO
)

export const getDisplayThankYouPage = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.displayThankYouPage === TOGGLE_STATUS.YES
)

export const getCaptureDetails = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.form
)

const makeField = (fieldId) => createSelector(
    getCaptureDetails,
    qualifyingForm => qualifyingForm[fieldId]
)

const makeGetField = fieldId => createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying[fieldId]
);

export const getFirstname = makeField('firstname');
export const getSurname = makeField('surname');
export const getIdNumber = makeField('idNumber');
export const getMobileNumber = makeField('mobileNumber');
export const getSidebarOpen = makeGetField('sidebarOpen');
export const getLeadsResponse = makeGetField('leadsResponse');
export const getLeadSource = makeGetField('source');

export const getEmploymentType = makeField('employmentType');
export const getPayslipAvailability = makeField('payslipAvailability');
export const getWageFrequency = makeField('wageFrequency');

export const getShowHeading = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.submitStatus === API_CALL_STATUS.IDLE || qualifying.submitStatus === API_CALL_STATUS.SUCCESS
);

export const getUnsubmitted = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.submitStatus === API_CALL_STATUS.IDLE
);
export const getSubmitSuccess = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.submitStatus === API_CALL_STATUS.SUCCESS
);
export const getSubmitError = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.submitStatus === API_CALL_STATUS.FAILURE
);
export const getSubmitPending = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.submitStatus === API_CALL_STATUS.PENDING
);

export const getEmploymentError = createSelector(
    getQualifyingCheckCallMeBack,
    qualifying => qualifying.submitStatus === 'EMPLOYMENT_VALIDATION_ERROR'
);