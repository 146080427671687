export const SHORT_TERM_GOAL_CALCULATOR_TRANSITION = 'investmentCalculator/shortTermGoal/transition/'

export const FORM_VALID_SHORT_TERM_GOAL_TRANSITION =
	'investmentCalculator/shortTermGoal/formValid';
export const FORM_INVALID_SHORT_TERM_GOAL_TRANSITION =
	'investmentCalculator/shortTermGoal/formInvalid';

export const transitionShortTermGoalCalculator = {
	formValid: () => ({ type: FORM_VALID_SHORT_TERM_GOAL_TRANSITION }),
	formInvalid: () => ({ type: FORM_INVALID_SHORT_TERM_GOAL_TRANSITION }),
}; 

export const SHORT_TERM_GOAL_CALCULATOR_SET_VALUE =
	'investmentCalculator/shortTermGoal/setValue/';
export const SHORT_TERM_GOAL_CALCULATOR_SET_VALID =
	'investmentCalculator/shortTermGoal/setValid/';

export const REINITIALIZE_STATE_SHORT_TERM_GOAL_CALCULATOR =
	'investmentCalculator/shortTermGoal/reinitialize';

export const ONCE_OFF_SET_VALUE =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'onceOff';
export const ONCE_OFF_SET_VALID =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALID + 'onceOff';

export const MONTHLY_AMOUNT_SET_VALUE =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'monthlyAmount';
export const MONTHLY_AMOUNT_SET_VALID =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALID + 'monthlyAmount';

export const INVESTMENT_TENURE_SET_VALUE =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'investmentTenure';
export const INVESTMENT_TENURE_SET_VALID =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALID + 'investmentTenure';

export const POTENTIAL_LOSS_PERCENTAGE_SET_VALUE =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'potentialLossPercentage';
export const POTENTIAL_LOSS_PERCENTAGE_SET_VALID =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALID + 'potentialLossPercentage';

export const RESULTS_VIEW_VALUE =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'viewResults';
export const RESULTS_SET_VALUE =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'setResults';

export const CALCULATE_SHORT_TERM_GOAL =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'success'; 
export const RESULTS_SET_VALUE_FAILURE =
	SHORT_TERM_GOAL_CALCULATOR_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_SHORT_TERM_GOAL_CALCULATOR = `${SHORT_TERM_GOAL_CALCULATOR_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_SHORT_TERM_GOAL_CALCULATOR = `${SHORT_TERM_GOAL_CALCULATOR_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_SHORT_TERM_GOAL_CALCULATOR = `${SHORT_TERM_GOAL_CALCULATOR_SET_VALUE}/apiTransition/failure`; 
export const apiTransitionShortTermGoalCalculator = {
	pending: () => ({
		type: API_PENDING_TRANSITION_SHORT_TERM_GOAL_CALCULATOR,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_SHORT_TERM_GOAL_CALCULATOR,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_SHORT_TERM_GOAL_CALCULATOR,
	}),
}; 

export const getShortTermGoalResult = () => ({ type: RESULTS_SET_VALUE }); //TODO: remove + references (JE)
export const calculateShortTermGoal = (value) => ({
			type: CALCULATE_SHORT_TERM_GOAL,
			value,
		});
export const getShortTermGoalResultFailure = (error) => ({
	type: RESULTS_SET_VALUE_FAILURE,
	error,
});

export const setShortTermGoalOnceOffValue = (value) => ({
	type: ONCE_OFF_SET_VALUE,
	value,
});
export const setShortTermGoalOnceOffValid = (error) => ({
	type: ONCE_OFF_SET_VALID,
	error,
}); 
export const setMonthlyValue = (value) => ({
	type: MONTHLY_AMOUNT_SET_VALUE,
	value,
});
export const setMonthlyValid = (error) => ({
	type: MONTHLY_AMOUNT_SET_VALID,
	error,
});
export const setInvestmentTenureValue = (value) => ({
	type: INVESTMENT_TENURE_SET_VALUE,
	value,
});
export const setInvestmentTenureValid = (error) => ({
	type: INVESTMENT_TENURE_SET_VALID,
	error,
});
export const setPotentialLossPercentageValue = (value) => ({
	type: POTENTIAL_LOSS_PERCENTAGE_SET_VALUE,
	value,
});
export const setPotentialLossPercentageValid = (error) => ({
	type: POTENTIAL_LOSS_PERCENTAGE_SET_VALID,
	error,
});

export const setViewShortTermGoalResult = value => ({ type: RESULTS_VIEW_VALUE, value });

export const reInitialize = () => ({
	type: REINITIALIZE_STATE_SHORT_TERM_GOAL_CALCULATOR,
});
