import axios from 'axios';

export const logOmError = (err, errInfo, apiEndPoint) => {
    const errorInfo = getErrorInfo(err, errInfo);
    const envName = process.env.GATSBY_ENVIRONMENT_NAME;
    const getErrorLoggingReq = getErrorLoggingReqObj(errorInfo, envName, apiEndPoint);

    const logErrorInService = envName !== 'local';
    if (logErrorInService) {
        logErrorWithService(getErrorLoggingReq);
    }

    const logErrorInConsole = ['qa', 'local', 'dev', 'preprod'].includes(envName)
    if (logErrorInConsole) {
        console.log('OM CLIENT ERROR:');
        console.log(getErrorLoggingReq);
        console.log(err);
    }

    // Log request ID to Session Cam
    if (getErrorLoggingReq.metaData.requestId) {
        if (!window.sessioncamConfiguration) {
            window.sessioncamConfiguration = {};
        }

        if (!window.sessioncamConfiguration.customDataObjects) {
            window.sessioncamConfiguration.customDataObjects = [];
        }

        const item = {
            key: 'requestId',
            value: getErrorLoggingReq.metaData.requestId
        };

        window.sessioncamConfiguration.customDataObjects.push(item);
    }
}

const getClientErrorSessionId = () => {
    return window.localStorage.clientErrorSessionId;
}

const setClientErrorSessionId = clientErrorSessionId => {
    if (getClientErrorSessionId() !== clientErrorSessionId) {
        window.localStorage.clientErrorSessionId = clientErrorSessionId;
    }
}

const getErrorLoggingReqObj = (errorInfo, envName, apiEndPoint) => {
    const stack = process.env.GATSBY_ENVIRONMENT_NAME;
    const errorLoggingReqObj = {
        metaData: {
            browser: errorInfo.browser,
            browserVersion: errorInfo.browserVersion,
            userAgent: errorInfo.userAgent,
            path: errorInfo.path,
            lifeCycleHook: `Gatsby: ${errorInfo.errInfo.componentStack ? errorInfo.errInfo.componentStack : errorInfo.errInfo}`,
            stack: stack,
            apiEndPoint,
            errorSource: 'npw-front-end',
            "npw-application": 'new-public-web',
            "npw-region": stack,
            "npw-platform": "FE",
            "npw-environment": envName,
            "npw-url": errorInfo.path,
            "npw-customerId": "coming soon",
            "npw-logLevel": "error",
            "npw-severity": "coming soon",
            requestId: errorInfo.error && errorInfo.error.response && errorInfo.error.response.data.requestId
        },
        message: errorInfo.error && errorInfo.error.message,
        level: 'error',
        component: `npw-${stack}-${envName}`,
    };

    const clientErrorSessionId = getClientErrorSessionId();
    if (clientErrorSessionId) {
        errorLoggingReqObj.requestId = clientErrorSessionId;
    }

    return errorLoggingReqObj;
};

const logErrorWithService = reqObj => {
    console.log(reqObj)
    axios.post(
        '/om-api/logging-service',
        reqObj,
    ).then((res) => {
        const requestId = res.data.requestId;
        setClientErrorSessionId(requestId);
    }).catch((error) => {
        console.log('Client side error logging exception:', error);
        console.log('Error Info:', reqObj);
    });
};

const getErrorInfo = (err, errInfo) => {
    const browser = getBrowser();

    return {
        browser: browser && browser.name,
        browserVersion: browser && browser.version,
        userAgent: getUserAgent(),
        path: getPath(),
        errInfo,
        error: err,
    };
};

const getBrowser = () => {
    let ua = window.navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name:'IE',version:(tem[1]||'') };
    }
    if (M[1] === 'Chrome') {
        tem=ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) {
            return {name:'Opera', version:tem[1]};
        }
    }
    M=M[2]? [M[1], M[2]]: [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem=ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1,1,tem[1]);
    }
    return {
        name: M[0],
        version: M[1]
    };
}

const getPath = () => {
    return document.location.pathname;
};

const getUserAgent = () => {
    return window.navigator.userAgent;
};
