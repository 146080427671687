import { PAGE_INDICES } from '../../actions/personalPensionPlan/types';
import {
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
    NAVIGATE_TO_GET_QUOTE,
    NAVIGATE_TO_PERSONAL_DETAILS_PAGE,
    NAVIGATE_TO_BENEFICIARIES,
    NAVIGATE_TO_NEXT_OF_KIN,
    NAVIGATE_TO_PAYMENT,
} from '../../actions/personalPensionPlan/routing';

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    landingPage: null,
};

export default (state = initRouting, action) => {
    const setActivePage = (pageIndex) => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };
        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };
        case NAVIGATE_TO_GET_QUOTE:
            return setActivePage(PAGE_INDICES.QUOTE);
        case NAVIGATE_TO_PERSONAL_DETAILS_PAGE:
            return setActivePage(PAGE_INDICES.PERSONAL_DETAILS);
        case NAVIGATE_TO_NEXT_OF_KIN:
            return setActivePage(PAGE_INDICES.NEXT_OF_KIN);
        case NAVIGATE_TO_BENEFICIARIES:
            return setActivePage(PAGE_INDICES.BENEFICIARIES);
        case NAVIGATE_TO_PAYMENT:
            return setActivePage(PAGE_INDICES.PAYMENT);

        default:
            return state;
    }
};
