import { createSelector } from 'reselect';
import { getInvestmentCalculators } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';
import { formatCurrency } from '../currency'

export const getBuildWealthCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculators) => investmentCalculators.buildWealthCalculator
);

export const getBuildWealthFundSplit = createSelector(
    getInvestmentCalculators,
    (investmentCalculators) => investmentCalculators.buildWealthFundSplit
);

export const getBuildWealthCalculatorForm = createSelector(
    getBuildWealthCalculator,
    (buildWealthCalculator) => buildWealthCalculator.form
);

export const getBuildWealthCalculatorFormValid = createSelector(
    getBuildWealthCalculator,
    (getBuildWealthCalculator) => getBuildWealthCalculator.formStatus === FORM_STATUS.VALID
);

const makeGetField = (fieldId) => createSelector(getBuildWealthCalculatorForm, (form) => form[fieldId]);

export const getAmountOnceOffValue = makeGetField('amountOnceOff');
export const getMonthlyAmountValue = makeGetField('amountMonthly');

export const getBuildWealthResults = createSelector(
    getBuildWealthCalculatorForm,
    (buildWealthCalculatorForm) => buildWealthCalculatorForm.calculationResults
)

export const getAmountNeededToBeSaved = createSelector(
    getBuildWealthCalculator,
    buildWealthCalculator => formatCurrency(buildWealthCalculator.amountNeededToBeSaved, 'KES')
)

export const getShowResult = createSelector(
    getBuildWealthCalculator,
    buildWealthCalculator => buildWealthCalculator.apiCallStatus === API_CALL_STATUS.SUCCESS,
);

export const getShowError = createSelector(
    getBuildWealthCalculator,
    buildWealthCalculator => buildWealthCalculator.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getBuildWealthCalculator,
    buildWealthCalculator => buildWealthCalculator.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getBtnText = createSelector(
    getBuildWealthCalculator,
    buildWealthCalculator => {
        switch (buildWealthCalculator.apiCallStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'RECALCULATE';
            case API_CALL_STATUS.FAILURE:
                return 'TRY AGAIN';
            case API_CALL_STATUS.PENDING:
                return 'CALCULATING...';
            default:
                return 'CALCULATE';
        }
    }
);

export const getHasCalculateBtnIcon = createSelector(
    getBuildWealthCalculator,
    buildWealthCalculator => {
        return buildWealthCalculator.apiCallStatus !== API_CALL_STATUS.PENDING;
    }
);

export const getCalculateDisabled = createSelector(
    [getBuildWealthCalculator, getBuildWealthFundSplit],
    (buildWealthCalculator, buildWealthFundSplit) => {
        return buildWealthCalculator.formStatus === FORM_STATUS.INVALID ||
            buildWealthCalculator.apiCallStatus === API_CALL_STATUS.PENDING ||
            buildWealthFundSplit.formStatus === FORM_STATUS.INVALID;
    }
);

export const getInputDisabled = createSelector(
    getBuildWealthCalculator,
    buildWealthCalculator => {
        return buildWealthCalculator.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);
