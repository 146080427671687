import { createSelector } from 'reselect';
import { getPersonalAccidentState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDeclarations = createSelector(getPersonalAccidentState, (state) => state.declarations);
export const getDeclarationsForm = createSelector(getDeclarations, (declarations) => declarations.form);

export const getDropOffDetails = createSelector(getDeclarations, (declarations) => declarations.dropOffDetails);

export const getDeclarationsFormValid = createSelector(
    getDeclarations,
    (declarations) => declarations.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getDeclarations,
    (declarations) => declarations.formStatus === API_CALL_STATUS.PENDING
);

const makeGetFieldForm = (fieldId) => createSelector(getDeclarationsForm, (form) => form[fieldId]);

export const getHasPreviouslyHeldPolicyValue = makeGetFieldForm('hasPreviouslyHeldPolicy');
export const getPreviousInsurerNameValue = makeGetFieldForm('previousInsurerName');
export const getPreviousInsurerAddressValue = makeGetFieldForm('previousInsurerAddress');
export const getPreviousInsurerBranchValue = makeGetFieldForm('previousInsurerBranch');
export const getPreviousInsurerPolicyNoValue = makeGetFieldForm('previousInsurerPolicyNo');
export const getIsAdditionalPolicyValue = makeGetFieldForm('isAdditionalPolicy');
export const getAdditionalPolicyNumberValue = makeGetFieldForm('additionalPolicyNumber');
export const getAdditionalPolicyTotalDeathBenefitValue = makeGetFieldForm('additionalPolicyTotalDeathBenefit');
export const getDeclinedProposalValue = makeGetFieldForm('declinedProposal');
export const getRefusedRenewalValue = makeGetFieldForm('refusedRenewal');
export const getTerminatedInsuranceValue = makeGetFieldForm('terminatedInsurance');
export const getTerminatedInsuranceDetailsValue = makeGetFieldForm('terminatedInsuranceDetails');
export const getRequiredPremiumIncreaseValue = makeGetFieldForm('requiredPremiumIncrease');
export const getImposedSpecialConditionsValue = makeGetFieldForm('imposedSpecialConditions');
