export const SET_VALUE_ROUTING = 'travel/routing/setValue/';
export const NAVIGATE_PREVIOUS = SET_VALUE_ROUTING + 'navigatePrevious';
export const NAVIGATE_NEXT = SET_VALUE_ROUTING + 'navigateNext';
export const NAVIGATE_INDEX = SET_VALUE_ROUTING + 'navigateIndex';
export const SET_NAVIGATION_DONE = SET_VALUE_ROUTING + 'navigationDone';
export const MOTOR_INSURANCE_SWICTH_PLAN = SET_VALUE_ROUTING + 'switchPlan';
export const switchPlanNavigation = () => ({type: MOTOR_INSURANCE_SWICTH_PLAN});
export const navigatePrevious = () => ({ type: NAVIGATE_PREVIOUS });
export const navigateNext = () => ({ type: NAVIGATE_NEXT });
export const navigateIndex = value => ({ type: NAVIGATE_INDEX, value });
export const setNavigationDone = () => ({ type: SET_NAVIGATION_DONE });

export const RESET_ROUTING = SET_VALUE_ROUTING + 'resetRouting';
export const resetRouting = () => ({ type: RESET_ROUTING });

export const SET_ACTIVE_PAGE_INDEX = SET_VALUE_ROUTING + 'activePageIndex';
export const setActivePageIndex = value => ({
    type: SET_ACTIVE_PAGE_INDEX,
    value,
});

// Per-page routing transitions
export const ROUTING_TRANSITION = 'travel/routing/transition/';

export const SET_LANDING_PAGE = SET_VALUE_ROUTING + 'landingPage';
export const setLandingPage = value => ({ type: SET_LANDING_PAGE, value });

export const NAVIGATE_TO_LANDING_PAGE = ROUTING_TRANSITION + 'toLandingPage';
export const navigateToLandingPage = () => ({ type: NAVIGATE_TO_LANDING_PAGE });

export const NAVIGATE_TO_TRAVEL_QUOTE = ROUTING_TRANSITION + 'toTravelQuote';
export const navigateToTravelQuote = () => ({ type: NAVIGATE_TO_TRAVEL_QUOTE });

export const NAVIGATE_TO_TRAVEL_DETAILS = ROUTING_TRANSITION + 'toTravelDetails';
export const navigateToTravelDetails = () => ({ type: NAVIGATE_TO_TRAVEL_DETAILS });

// export const NAVIGATE_TO_BUILDING_DETAILS = ROUTING_TRANSITION + 'toBuildingDetails';
// export const navigateToBuildingDetails = () => ({
//     type: NAVIGATE_TO_BUILDING_DETAILS,
// });
// export const NAVIGATE_TO_DOCUMENTS = ROUTING_TRANSITION + 'toDocuments';
// export const navigateToDocuments = () => ({ type: NAVIGATE_TO_DOCUMENTS });

export const NAVIGATE_TO_PAYMENT = ROUTING_TRANSITION + 'toPayment';
export const navigateToPayment = () => ({ type: NAVIGATE_TO_PAYMENT });
