import { transitionCountyTool } from '../../../actions/roaQuoteTools/afyaImaraCountyQuoteTool';
import { getcountyQuoteToolForm, getSelectedForm } from '../../../selectors/roaQuoteTools/afyaImaraCountyQuoteTool';
import { FIELD_STATUS } from '../../../reducers/status';

export const afyaImaraCountyQuoteToolFormRules = (store, next, action) => {
  const result = next(action);

  const afyaImaraCountyQuoteToolForm = getcountyQuoteToolForm(
    store.getState(),
  );

  const selectedForm = getSelectedForm(store.getState());

  const afyaImaraCoutyQuoteToolValid = validateCountyQuoteToolFormDetails(
    afyaImaraCountyQuoteToolForm, selectedForm
  );

  const formAction = afyaImaraCoutyQuoteToolValid
    ? transitionCountyTool.formValid()
    : transitionCountyTool.formInvalid();

  store.dispatch(formAction);

  return result;
};

const validateCountyQuoteToolFormDetails = (formFields, selectedForm) => {
    const { child_18, child_19_29, child_30_40, child_40_50} = formFields
    if (selectedForm === 'form-one') {
        return child_18 && child_18.status === FIELD_STATUS.VALID &&
        child_19_29 && child_19_29.status === FIELD_STATUS.VALID  &&
        child_30_40 && child_30_40.status === FIELD_STATUS.VALID &&
        child_40_50 && child_40_50.status === FIELD_STATUS.VALID;
    }
    return true;
};
