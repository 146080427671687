import axios from "axios";
import {
    getLoanAmountOptions,
    getRepaymentDurationOptions
} from "../../../selectors/personalLoansCalculator/referenceData";
import {
    getAmountBorrowed,
    getOtherAmountBorrowed,
    getRepaymentDuration,
    getOtherRepaymentDuration
} from "../../../selectors/personalLoansCalculator/calculator";
import { calculateLoanRepayment, calculateLoanRepaymentSuccess } from "../../../actions/personalLoansCalculator/calculator";

export const installmentCalculationService = (store, next, action) => {
    const result = next(action);
    store.dispatch(calculateLoanRepayment());

    const state = store.getState();

    const loanAmountOptions = getLoanAmountOptions(state);
    const repaymentDurationOptions = getRepaymentDurationOptions(state);

    const amountBorrowed = getAmountBorrowed(state);
    const otherAmountBorrowed = getOtherAmountBorrowed(state);
    const repaymentDuration = getRepaymentDuration(state);
    const otherRepaymentDuration = getOtherRepaymentDuration(state);

    const capital = otherAmountBorrowed.value !== null ? otherAmountBorrowed.value : getReferenceDataValue(loanAmountOptions, amountBorrowed.value);
    const term = otherRepaymentDuration.value !== null ? parseInt(otherRepaymentDuration.value ): getReferenceDataValue(repaymentDurationOptions, repaymentDuration.value);

    (async () => {
        const response = await calculateInstallment(capital, term);
        if (response.status === 200) {
            store.dispatch(calculateLoanRepaymentSuccess(response.data));
        }
    })();

    return result;
}

const getReferenceDataValue = (referenceArray, value) => {
    if (referenceArray && value) {
        const data = referenceArray.find((data) => data.code === value);
        return data.value;
    }
    return " ";
}

const installmentCalculationRequestBody = (capital, term) => {
    return {
        capital,
        term,
    }
}

const calculateInstallment = async (capital, term) => {
    try {
        const response = await axios.post('/om-api/personal-loans/calculate-installment',
            installmentCalculationRequestBody(capital, term),
            { headers: { 'Content-Type': 'application/json' }});
        return response;
    } catch (error) {
        return false;
    }
}