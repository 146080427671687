import {
    REFERENCE_DATA_SET_VALUE
} from "../../actions/raOptimal/referenceData";

export const initReferenceData = {
    data: {}
};

export default (state = initReferenceData, action) => {
    switch (action.type) {
        // Form Validation
        case REFERENCE_DATA_SET_VALUE:
            return { ...state, data: action.value };

        default:
            return state;
    }
};
