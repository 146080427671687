import { createSelector } from 'reselect';
import { getTravelInsurance } from './index';
import {  API_CALL_STATUS } from '../../reducers/status';

export const getPayloadCollection = createSelector(getTravelInsurance, (state) => state.payloadCollection);
export const getIsApiPending = createSelector(
    getPayloadCollection,
    (payload) => payload.apiCallStatus === API_CALL_STATUS.PENDING
);
export const getPayloadData = createSelector(getPayloadCollection, (payload) => payload.payloadData);
export const getIsApiSuccess = createSelector(
    getPayloadCollection,
    (payload) => payload.apiCallStatus === API_CALL_STATUS.SUCCESS
);
export const getIsApiFailure = createSelector(
    getPayloadCollection,
    (payload) => payload.apiCallStatus === API_CALL_STATUS.FAILURE
);
