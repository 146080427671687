import {
    UPLOAD_DOCUMENTS_SET_VALUE,
    UPLOAD_DOCUMENTS_SET_VALID,
    FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS,
    FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS,
    RESET_STATE_TRANSITION_UPLOAD_DOCUMENTS,
    UPLOAD_DOCUMENTS_DECLARATION_SET_VALUE,
    UPLOAD_DOCUMENTS_DECLARATION_SET_VALID,
    // RESET_DOCUMENTS_SET_VALUE,
} from '../../actions/allsureInsurance/uploadDocuments';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initUploadDocument = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        declaration:{ value: null, error: null, status: null, displayValue: null },
        copyOfCertifiedId: { fileName: null, fileContaint: null, error: null, status: null, file: null },
        proofOfResidence: { fileName: null, fileContaint: null, error: null, status: null, file: null },
        sourceOfFund: { fileName: null, fileContaint: null, error: null, status: null, file: null },
        bankingDetails: { fileName: null, fileContaint: null, error: null, status: null, file: null },
        houseValuationReport: { fileName: null, fileContaint: null, error: null, status: null, file: null },
        vehicleRegistrationBook: { fileName: null, fileContaint: null, error: null, status: null, file: null },
        driverLicense: { fileName: null, fileContaint: null, error: null, status: null, file: null },
        motorValuation: { fileName: null, fileContaint: null, error: null, status: null, file: null },
       
    },
};

export default (state = initUploadDocument, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        //ID DOCUMENTS
        case UPLOAD_DOCUMENTS_SET_VALUE:
            const { inputType, fileName, fileContaint, file } = action.value;
            return updateFormField(inputType, (field) => ({
                ...field,
                fileName: fileName,
                fileContaint: fileContaint,
                file: file,
            }));

        case UPLOAD_DOCUMENTS_SET_VALID:
            return updateFormField(action.fileType, (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

            case UPLOAD_DOCUMENTS_DECLARATION_SET_VALUE:
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
            }));

        case UPLOAD_DOCUMENTS_DECLARATION_SET_VALID:
            const { fieldType, error } = action;
            return updateFormField(fieldType, (field) => ({
                ...field,
                error: error,
                status: mapError(error),
            }));

        case RESET_STATE_TRANSITION_UPLOAD_DOCUMENTS:
            const { copyOfCertifiedId, proofOfResidence, sourceOfFund, bankingDetails ,declaration} = state.form;
            return {
                ...state,
                form: { copyOfCertifiedId, proofOfResidence, sourceOfFund, bankingDetails, declaration},
            };


        case FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };
     

        default:
            return state;
    }
};
