import { createSelector } from 'reselect';
import { API_CALL_STATUS, FORM_STATUS } from '../../reducers/status';

export const getFuneralClaim = (state) => state.publicWeb.funeralClaimV2;

const makeGetField = (fieldId) =>
    createSelector(getFuneralClaim, (funeralClaim) => funeralClaim[fieldId]);

export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getRequesterIdNumber = makeGetField('requesterIdNumber');
export const getCellphoneNumber = makeGetField('cellphoneNumber');
export const getEmail = makeGetField('email');
export const getIsUserPolicyHolder = makeGetField('isUserPolicyHolder');
export const getIsStillborn = makeGetField('isStillborn');
export const getDateOfDeath = makeGetField('dateOfDeath');
export const getDeceasedIdNumber = makeGetField('deceasedIdNumber');
export const getCauseOfDeath = makeGetField('causeOfDeath');
export const getReasonForUnnaturalDeath = makeGetField(
    'reasonForUnnaturalDeath'
);
export const getReasonForDeath = makeGetField('reasonForDeath');
export const getFuneralDate = makeGetField('funeralDate');
export const getIsUserBankAccountHolder = makeGetField('isUserBankAccountHolder');
export const getBank = makeGetField('bank');
export const getBankOther = makeGetField('bankOther');
export const getBranchCode = makeGetField('branchCode');
export const getBankAccountNumber = makeGetField('bankAccountNumber');
export const getBankAccountType = makeGetField('bankAccountType');
export const getBankAccountHolderIdNumber = makeGetField('bankAccountHolderIdNumber');
export const getBankAccountHolderName = makeGetField('bankAccountHolderName');
export const getNoticeOfDeathDocument = makeGetField('noticeOfDeathDocument');
export const getProofOfBankDocument = makeGetField('proofOfBankDocument');
export const getAgreeToTerms = makeGetField('agreeToTerms');
export const getSidebarOpen = makeGetField('sidebarOpen');

export const getShowBankOther = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        return funeralClaim.bank.value === 'OTHER';
    }
);

export const getShowBankAccountHoldersFields = createSelector(
    getFuneralClaim,
    (funeralClaim) => funeralClaim.isUserBankAccountHolder === 'NO' ? true : false,
);

export const getShowBankDetailsFields = createSelector(
    getFuneralClaim,
    (funeralClaim) => funeralClaim.isUserBankAccountHolder !== null
)

export const getShowReasonForUnnaturalDeath = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        return funeralClaim.causeOfDeath.value === 'UNNATURAL';
    }
);

export const getShowReasonForDeath = createSelector(
    getFuneralClaim,
    (funeralClaim) =>
        funeralClaim.causeOfDeath.value === 'UNNATURAL' &&
        funeralClaim.reasonForUnnaturalDeath.value === 'OTHER'
);

export const getBtnText = createSelector(getFuneralClaim, (funeralClaim) => {
    switch (funeralClaim.allApiSubmissionsStatus) {
        case API_CALL_STATUS.SUCCESS:
            return 'SUBMITTED';
        case API_CALL_STATUS.FAILURE:
            return 'TRY AGAIN';
        case API_CALL_STATUS.PENDING:
            return 'SUBMITTING...';
        default:
            return 'SUBMIT';
    }
});

export const getInputDisabled = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        return (
            funeralClaim.apiCallStatus === API_CALL_STATUS.PENDING ||
            funeralClaim.apiCallStatus === API_CALL_STATUS.SUCCESS
        );
    }
);

export const getDisableSubmit = createSelector(
    getFuneralClaim,
    (funeralClaim) =>funeralClaim.formStatus === FORM_STATUS.INVALID ||
        !funeralClaim.agreeToTerms || !(!!funeralClaim.recaptcha.value)
);

export const getShowErrorMsg = createSelector(
    getFuneralClaim,
    (funeralClaim) => funeralClaim.apiCallStatus === API_CALL_STATUS.FAILURE
);

export const getShowSuccessMsg = createSelector(
    getFuneralClaim,
    (funeralClaim) => funeralClaim.apiCallStatus === API_CALL_STATUS.SUCCESS
);

export const getShowAcceptTermsMsg = createSelector(
    getFuneralClaim,
    (funeralClaim) =>
        funeralClaim.formStatus === FORM_STATUS.VALID &&
        !funeralClaim.agreeToTerms
);

export const getDontFireApi = createSelector(
    getFuneralClaim,
    (funeralClaim) =>
        funeralClaim.apiCallStatus === API_CALL_STATUS.PENDING ||
        funeralClaim.apiCallStatus === API_CALL_STATUS.SUCCESS
);

export const getFuneralClaimsOpenDrawer = createSelector(
    getFuneralClaim,
    (funeralClaim) => funeralClaim.sidebarOpen
);

export const getDeceasedId = createSelector(
    getFuneralClaim,
    (funeralClaim) => funeralClaim.deceasedIdNumber.value,
);

export const getTriggerCdvBankAccountChecks = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        if (funeralClaim.apiCallDhaStatus == API_CALL_STATUS.SUCCESS ||
            funeralClaim.apiCallDhaStatus == API_CALL_STATUS.FAILURE) 
        {
            return true;
        }
        return false;
    },
);

export const getCdvFailureMessage = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        if (funeralClaim.apiCallCdvStatus === API_CALL_STATUS.FAILURE) {
            return true;
        }
        return false;
    }
);

export const getTriggerPreSubmit = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        if (funeralClaim.apiCallCdvStatus === API_CALL_STATUS.SUCCESS)
        {
            return true;
        }
        return false;
    },
);

export const getTriggerSubmitForm = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        if (funeralClaim.apiCallPreSubmitStatus === API_CALL_STATUS.SUCCESS) {
            return true;
        }
        return false;
    },
);

function getPresubmitDocumentObject(obj) {
    if(obj) {
        const { documentName, file, documentClassification } = obj;
        return {
            documentName,
            file,
            documentClassification,
        };
    }
    return;
}


export const getFuneralClaimPresubmitInsecurePostData = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        let documentObjects = [];
        const noticeOfDeathObj = funeralClaim.noticeOfDeathDocument.value;
        const proofOfBankObj = funeralClaim.proofOfBankDocument.value;
        
        if (noticeOfDeathObj) {
            const data = getPresubmitDocumentObject(noticeOfDeathObj);
            if (data) {
                documentObjects.push(data);
            }
        }

        if (proofOfBankObj && funeralClaim.bank.value === 'OTHER') {
            const data = getPresubmitDocumentObject(proofOfBankObj);
            if (data) {
                documentObjects.push(data);
            }
        }

        return {
            requesterId: funeralClaim.requesterIdNumber.value,
            documentObjects,
        }
    },
);

export const getFuneralClaimCdvPostData = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        let branchCode = null
        if (funeralClaim.bank.value === 'OTHER') {
            if (funeralClaim.branchCode.value !== null ||
                funeralClaim.branchCode.value.trim() !== '')
            {
                branchCode = funeralClaim.branchCode.value;
            }

            return {   
                bankBranchCode: branchCode == null ? 'NOT PROVIDED' : branchCode,
                bankAccountNumber: funeralClaim.bankAccountNumber.value,
                bankAccountType: funeralClaim.bankAccountType.value == 'CHEQUE' ? 1 : 2,
            };
        }

        return {   
            bankBranchCode: getUniversalBranchCode(funeralClaim.bank.value),
            bankAccountNumber: funeralClaim.bankAccountNumber.value,
            bankAccountType: funeralClaim.bankAccountType.value == 'CHEQUE' ? 1 : 2,
        };
    },
);


function getUniversalBranchCode(bank) {
    switch (bank) {
        case 'STANDARD_BANK':
            return '051001';
        case 'NEDBANK':
            return '198765';
        case 'ABSA':
            return '632005';
        case 'CAPITEC':
            return '470010';
        case 'FNB':
            return '250655';
        case 'AFRICAN':
            return '430000';
        case 'DISCOVERY':
            return '679000';
        case 'INVESTEC':
            return '580105';
        case 'SASFIN':
            return '683000';
        default:
            return 'NOT PROVIDED';
    }
}

function getFormatDateToISOString(date) {
    if (!date && date.trim() === '') {
        return '';
    }

    const dateArray = date.split('/');
    if (dateArray.length) {
        const newDate = new Date();
        newDate.setFullYear(dateArray[2]);
        newDate.setMonth(dateArray[1] - 1);
        newDate.setDate(dateArray[0]);
        return newDate.toISOString();
    }
    return '';
}

export const getFuneralClaimSubmitFormServiceRequestData = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        const postData = {
            countryOfRequest: 'NOT PROVIDED',
            policyOwner: funeralClaim.isUserPolicyHolder,
            policyNumber: 'NOT PROVIDED',
            policyVerified: false,
            customerMatched: false,
            termsConditionsAccepted: funeralClaim.agreeToTerms ? true : false,
            claimantIdnumber: funeralClaim.requesterIdNumber.value,
            claimantName: funeralClaim.firstName.value + ' ' + funeralClaim.surname.value,
            claimantGender: 'NOT PROVIDED',
            claimantMobile: funeralClaim.cellphoneNumber.value,
            claimantEmailAddress: funeralClaim.email.value !== null && funeralClaim.email.value ? funeralClaim.email.value : 'NOT PROVIDED',
            claimantAddress: 'NOT PROVIDED',
            bankName: funeralClaim.bank.value,
            branchCode: funeralClaim.branchCode.value,
            accountNumber: funeralClaim.bankAccountNumber.value,
            accountType: funeralClaim.bankAccountType.value,
            accountHolderName: funeralClaim.bankAccountHolderName.value !== null && funeralClaim.bankAccountHolderName.value ? funeralClaim.bankAccountHolderName.value : 'NOT PROVIDED',
            accountHolderIdNumber: funeralClaim.bankAccountHolderIdNumber.value !== null && funeralClaim.bankAccountHolderIdNumber.value ? funeralClaim.bankAccountHolderIdNumber.value : 'NOT PROVIDED',
            deceasedName: 'NOT PROVIDED',
            deceasedId: funeralClaim.deceasedIdNumber.value,
            deceasedDateOfDeath: funeralClaim.dateOfDeath.value ? getFormatDateToISOString(funeralClaim.dateOfDeath.value) : '',
            deceasedFuneralDate: funeralClaim.funeralDate.value ? getFormatDateToISOString(funeralClaim.funeralDate.value) : '',
            deceasedDateOfBirth: '',
            deceasedStillbornChild: funeralClaim.isStillborn,
            deceasedCauseOfDeath: funeralClaim.causeOfDeath.value,
            deceasedReasonDeath: funeralClaim.reasonForDeath.value !== null && funeralClaim.reasonForDeath.value ? funeralClaim.reasonForDeath.value : 'NOT PROVIDED',
            serviceRequestId: '',
            externalReference: '',
            recaptcha: funeralClaim.recaptcha.value,
        };

        // branch code, 2. accountHolder 3. 
        if (funeralClaim.isUserBankAccountHolder !== null &&
            funeralClaim.isUserBankAccountHolder === 'YES') {
            postData.branchCode = getUniversalBranchCode(funeralClaim.bank.value);
            
        } else if (funeralClaim.isUserBankAccountHolder !== null &&
            funeralClaim.isUserBankAccountHolder === 'NO') {
            if (funeralClaim.bank.value === 'OTHER') {
                postData.bankName = funeralClaim.bankOther.value;
                postData.branchCode = funeralClaim.branchCode.value;
            } else {
                postData.bankName = funeralClaim.bank.value;
                postData.branchCode = getUniversalBranchCode(funeralClaim.bank.value);
            }
        }

        // Date of death
        if (funeralClaim.isStillborn) {
            postData.deceasedDateOfBirth = getFormatDateToISOString(funeralClaim.dateOfDeath.value);
            postData.deceasedReasonDeath = 'NOT PROVIDED';
            postData.deceasedId =  'NOT PROVIDED';
        }

        // causeOfDeath - unnatural
        if (funeralClaim.causeOfDeath.value &&
            funeralClaim.causeOfDeath.value === 'UNNATURAL') {
            if (funeralClaim.reasonForUnnaturalDeath.value && funeralClaim.reasonForUnnaturalDeath.value === 'OTHER') {
                postData.deceasedReasonDeath = funeralClaim.reasonForDeath.value ? funeralClaim.reasonForDeath.value : 'NOT PROVIDED';
            } else {
                postData.deceasedReasonDeath = funeralClaim.reasonForUnnaturalDeath.value;
            }
        }

        // cdv data
        if (funeralClaim.apiCallCdvStatus === API_CALL_STATUS.SUCCESS &&
            Array.isArray(funeralClaim.cdvBankAccountVerification.data.fieldValidations) &&
            funeralClaim.cdvBankAccountVerification.data.fieldValidations.length > 0) {
            postData.accountValidationPassed = funeralClaim.cdvBankAccountVerification.data.success;
            postData.cdvAccountValidationResponseMessage = funeralClaim.cdvBankAccountVerification.data.fieldValidations[0].resultDescription;
        }

        //PreSubmit data
        if (funeralClaim.apiCallPreSubmitStatus == API_CALL_STATUS.SUCCESS) {
            postData.serviceRequestId = funeralClaim.preSubmit.data.serviceRequestId;
            postData.externalReference = funeralClaim.requesterIdNumber.value;
        }
        return postData;
    },
);

export const getFuneralClaimPostData = createSelector(
    getFuneralClaim,
    (funeralClaim) => {
        const postData = {
            requesterIdNumber: funeralClaim.requesterIdNumber.value,
            firstName: funeralClaim.firstName.value,
            surname: funeralClaim.surname.value,
            cellphoneNumber: funeralClaim.cellphoneNumber.value,
            email: funeralClaim.email.value,
            isUserPolicyHolder: funeralClaim.isUserPolicyHolder,
            deceasedIdNumber: funeralClaim.deceasedIdNumber.value,
            isStillborn: funeralClaim.isStillborn,
            deceasedDateOfBirth: funeralClaim.dateOfDeath.value,
            deceasedDateOfDeath: funeralClaim.dateOfDeath.value,
            causeOfDeath: funeralClaim.causeOfDeath.value,
            reasonForUnnaturalDeath: funeralClaim.reasonForUnnaturalDeath.value,
            reasonForDeath: funeralClaim.reasonForDeath.value,
            funeralDate: funeralClaim.funeralDate.value,
            bank: funeralClaim.bank.value,
            bankAccountNumber: funeralClaim.bankAccountNumber.value,
            bankAccountType: funeralClaim.bankAccountType.value,
            recaptcha: funeralClaim.recaptcha.value,
        };

        if (funeralClaim.isStillborn) {
            postData.deceasedIdNumber = 'NOT PROVIDED';
            postData.reasonForUnnaturalDeath = 'NOT PROVIDED';
            postData.reasonForDeath = 'NOT PROVIDED';
        } else {
            postData.deceasedDateOfBirth = 'NOT PROVIDED';
            postData.deceasedDateOfDeath = 'NOT PROVIDED';
        }

        if (funeralClaim.causeOfDeath.value && 
            funeralClaim.causeOfDeath.value === 'NATURAL') {
            postData.reasonForUnnaturalDeath = 'NOT PROVIDED';
            postData.reasonForDeath = 'NOT PROVIDED';
        }

        if (funeralClaim.reasonForUnnaturalDeath.value && 
            funeralClaim.reasonForUnnaturalDeath.value !== 'OTHER') {
            postData.reasonForDeath = 'NOT PROVIDED';
        }

        if (!postData.reasonForDeath || postData.reasonForDeath.trim() === '') {
            postData.reasonForDeath = 'NOT PROVIDED';
        }

        if (funeralClaim.isStillborn) {
            postData.isStillborn = 'YES';
        } else {
            postData.isStillborn = 'NO';
        }

        if (!postData.email || postData.email.trim() === '') {
            postData.email = 'NOT PROVIDED';
        }

        if (!postData.funeralDate || postData.funeralDate.trim() === '') {
            postData.funeralDate = 'NOT PROVIDED';
        }

        if (funeralClaim.bank.value === 'OTHER') {
            postData.bank = funeralClaim.bankOther.value;
            postData.branchCode = funeralClaim.branchCode.value;
            if (!postData.branchCode || postData.branchCode.trim() === '') {
                postData.branchCode = 'NOT PROVIDED';
            }
        } else {
            postData.branchCode = getUniversalBranchCode(postData.bank);
        }

        if (funeralClaim.agreeToTerms) {
            postData.agreeToTerms = 'ACCEPTED';
        } else {
            postData.agreeToTerms = 'UNACCEPTED';
        }

        return postData;
    }
);
