import {
    FAA_ASSISTANCE_TYPE_SET_VALUE,
    FAA_CHOSEN_LAT_SET_VALUE,
    FAA_CHOSEN_LONG_SET_VALUE,
    FAA_DISCUSSION_OPTIONS_SET_VALUE,
    FAA_INCOME_BRACKET_SET_VALUE,
    FAA_LOCATION_ENABLED_SET_VALUE,
    FAA_LOCATION_STATUS_SET_VALUE,
    FAA_LOCATION_TYPE_SET_VALUE,
    FAA_SEARCH_CRITERIA_SET_VALUE,
    FAA_DATA_SET_VALUE,
} from '../../actions/publicWeb/findAnAdvisor';

const initFindAnAdvisor = {
    searchCriteria: null,
    assistanceType: '',
    incomeBracket: '',
    chosenLat: null,
    chosenLong: null,
    testedLocationStatus: null,
    locationEnabled: null,
    locationType: null,
    discussionOptionsSelected: {value: []},
    findAnAdvisorData: {},
};

export default (state = initFindAnAdvisor, action) => {
    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    switch (action.type) {
        // Form Fields
    case FAA_SEARCH_CRITERIA_SET_VALUE:
        return {
            ...state,
            searchCriteria: action.value,
        };
    case FAA_CHOSEN_LONG_SET_VALUE:
        return {
            ...state,
            chosenLong: action.value,
        };
    case FAA_CHOSEN_LAT_SET_VALUE:
        return {
            ...state,
            chosenLat: action.value,
        };
    case FAA_ASSISTANCE_TYPE_SET_VALUE:
        return {
            ...state,
            assistanceType: action.value,
        };
    case FAA_INCOME_BRACKET_SET_VALUE:
        return {
            ...state,
            incomeBracket: action.value,
        };
    case FAA_LOCATION_STATUS_SET_VALUE:
        return {
            ...state,
            testedLocationStatus: action.value,
        };
    case FAA_LOCATION_ENABLED_SET_VALUE:
        return {
            ...state,
            locationEnabled: action.value,
        };
    case FAA_LOCATION_TYPE_SET_VALUE:
        return {
            ...state,
            locationType: action.value,
        };
    case FAA_DISCUSSION_OPTIONS_SET_VALUE:
        return updateFormField('discussionOptionsSelected', field => ({
            ...field,
            value: action.value,
        }));
    case FAA_DATA_SET_VALUE:
        return {
            ...state,
            findAnAdvisorData: action.value
        };
    default:
        return state;
    }
}