import { createSelector } from 'reselect';
import { getInvestmentCalculators } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';
import { formatCurrency } from '../currency'

export const getEmergencyFundCalculator = createSelector(
    getInvestmentCalculators,
    (investmentCalculators) => investmentCalculators.emergencyFundCalculator
);

export const getEmergencyFundCalculatorForm = createSelector(
    getEmergencyFundCalculator,
    (emergencyFundCalculator) => emergencyFundCalculator.form
);

export const getEmergencyCalculatorFormValid = createSelector(
    getEmergencyFundCalculator,
    (getEmergencyCalculator) => getEmergencyCalculator.formStatus === FORM_STATUS.VALID
);

const makeGetField = (fieldId) => createSelector(getEmergencyFundCalculatorForm, (form) => form[fieldId]);

export const getAmountOnceOffValue = makeGetField('amountOnceOff');
export const getMonthlyAmountValue = makeGetField('amountMonthly');

export const getEmergencyFundResults = createSelector(
    getEmergencyFundCalculatorForm,
    (emergencyFunCalculatorForm) => emergencyFunCalculatorForm.calculationResults
)

export const getAmountNeededToBeSaved = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => formatCurrency(emergencyFunCalculator.amountNeededToBeSaved, 'KES')
)

export const getShowResult = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => emergencyFunCalculator.apiCallStatus === API_CALL_STATUS.SUCCESS,
);

export const getShowError = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => emergencyFunCalculator.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => emergencyFunCalculator.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getBtnText = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => {
        switch (emergencyFunCalculator.apiCallStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'RECALCULATE';
            case API_CALL_STATUS.FAILURE:
                return 'TRY AGAIN';
            case API_CALL_STATUS.PENDING:
                return 'CALCULATING...';
            default:
                return 'CALCULATE';
        }
    }
);

export const getHasCalculateBtnIcon = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => {
        return emergencyFunCalculator.apiCallStatus !== API_CALL_STATUS.PENDING;
    }
);

export const getCalculateDisabled = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => {
        return emergencyFunCalculator.formStatus === FORM_STATUS.INVALID ||
            emergencyFunCalculator.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);

export const getInputDisabled = createSelector(
    getEmergencyFundCalculator,
    emergencyFunCalculator => {
        return emergencyFunCalculator.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);
