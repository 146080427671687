const PAYMENTS_TRANSITION = 'doctorsPi/confirmPayments/transition/';
export const INITIALIZE_PAYMENTS = 'doctorsPi/confirmPayments/initialize/';

// Transitions
export const FORM_VALIDATE_TRANSITION_PAYMENTS = PAYMENTS_TRANSITION + 'validateForm';
export const FORM_INVALIDATE_TRANSITION_PAYMENTS = PAYMENTS_TRANSITION + 'invalidateForm';
export const validateForm = () => ({ type: FORM_VALIDATE_TRANSITION_PAYMENTS });
export const invalidateForm = () => ({ type: FORM_INVALIDATE_TRANSITION_PAYMENTS });

// Events
export const PAYMENTS_SET_VALUE = 'doctorsPi/payments/setValue/';
export const PAYMENTS_SET_VALID = 'doctorsPi/payments/setValid/';
export const KE_PAYMENTS_SET_VALUE = 'doctorsPi/payments/setValue/';

// API Call actions STK PUSH
export const TRIGGER_PAYMENTS_STK_PUSH = PAYMENTS_SET_VALUE + 'triggerPaymentsStkPush';
export const TRIGGER_KE_PAYMENTS_STK_PUSH = KE_PAYMENTS_SET_VALUE + 'triggerPaymentsStkPush';
export const TRIGGER_PAYMENTS_STK_PUSH_SUCCESS = PAYMENTS_SET_VALUE + 'triggerPaymentsStkPushSuccess';
export const TRIGGER_PAYMENTS_STK_PUSH_FAILURE = PAYMENTS_SET_VALUE + 'triggerPaymentsStkPushFailure';
export const API_PENDING_TRANSITION_PAYMENTS_PUSH = `${PAYMENTS_SET_VALUE}/apiTransition/push/pending`;
export const API_SUCCESS_TRANSITION_PAYMENTS_PUSH = `${PAYMENTS_SET_VALUE}/apiTransition/push/success`;
export const API_FAILURE_TRANSITION_PAYMENTS_PUSH = `${PAYMENTS_SET_VALUE}/apiTransition/push/failure`;
export const API_INITIALIZE_TRANSITION_PAYMENTS_PUSH = `${PAYMENTS_SET_VALUE}/apiTransition/initialize`;
export const TRIGGER_CONFIRM_ORDER = PAYMENTS_SET_VALUE + 'confirm/Order';
export const CONFIRM_ORDER_SUCCESS = PAYMENTS_SET_VALUE + 'confirm/Order/success';
export const CONFIRM_ORDER_FAILURE = PAYMENTS_SET_VALUE + 'confirm/Order/failure';

export const API_CONFIRM_ORDER_SUCCESS = PAYMENTS_SET_VALUE + '/api/confirm/Order/success';
export const API_CONFIRM_ORDER_FAILURE = PAYMENTS_SET_VALUE + '/api/confirm/Order/failure';
export const API_CONFIRM_ORDER_PENDING = PAYMENTS_SET_VALUE + '/api/confirm/Order/pending';
export const apiTransitionStkPush = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PAYMENTS_PUSH,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PAYMENTS_PUSH,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PAYMENTS_PUSH,
    }),
    initialize: () => ({
        type: API_INITIALIZE_TRANSITION_PAYMENTS_PUSH,
    }),
};

export const apiTransitionOrderConfirmation = {
    pending: () => ({
        type: API_CONFIRM_ORDER_PENDING,
    }),
    success: () => ({
        type: API_CONFIRM_ORDER_SUCCESS,
    }),
    failure: () => ({
        type: API_CONFIRM_ORDER_FAILURE,
    }),
};

// API Call actions FLEX PAY status
export const CHECK_PAYMENTS_STATUS = PAYMENTS_SET_VALUE + 'checkPaymentsStatus';
export const CHECK_PAYMENTS_STATUS_SUCCESS = PAYMENTS_SET_VALUE + 'checkPaymentsStatusSuccess';
export const CHECK_PAYMENTS_STATUS_FAILURE = PAYMENTS_SET_VALUE + 'checkPaymentsStatusFailure';
export const API_PENDING_TRANSITION_PAYMENTS_STATUS = `${PAYMENTS_SET_VALUE}/apiTransition/flex-pay/status/pending`;
export const API_SUCCESS_TRANSITION_PAYMENTS_STATUS = `${PAYMENTS_SET_VALUE}/apiTransition/flex-pay/status/success`;
export const API_FAILURE_TRANSITION_PAYMENTS_STATUS = `${PAYMENTS_SET_VALUE}/apiTransition/flex-pay/status/failure`;
export const API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL = `${PAYMENTS_SET_VALUE}/apiTransition/flex-pay/status/initial`;

export const apiTransitionPaymentsStatus = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PAYMENTS_STATUS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PAYMENTS_STATUS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PAYMENTS_STATUS,
    }),
    initialize: () => ({
        type: API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL,
    }),
};

export const transitionPaymentsForm = {
    formValid: () => ({
        type: FORM_VALIDATE_TRANSITION_PAYMENTS,
    }),
    formInvalid: () => ({
        type: FORM_INVALIDATE_TRANSITION_PAYMENTS,
    }),
};

// Form input  actions
export const PHONE_NUMBER_SET_VALUE = PAYMENTS_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = PAYMENTS_SET_VALID + 'phoneNumber';
export const TERMS_SET_VALUE = PAYMENTS_SET_VALUE + 'terms';
export const TERMS_SET_VALID = PAYMENTS_SET_VALID + 'terms';
export const SET_NETWORK_PROVIDER = PAYMENTS_SET_VALUE + 'networkProvider';
export const SET_NETWORK_PROVIDER_VALID = PAYMENTS_SET_VALID + 'networkProvider';
export const setPhoneNumberValue = (value) => ({ type: PHONE_NUMBER_SET_VALUE, value });
export const setPhoneNumberValid = (error) => ({ type: PHONE_NUMBER_SET_VALID, error });
export const setTermsValue = (value) => ({ type: TERMS_SET_VALUE, value });
export const setTermsValid = (error) => ({ type: TERMS_SET_VALID, error });
export const setNetworkProviderValue = (value) => ({ type: SET_NETWORK_PROVIDER, value });
export const setNetworkProviderValid = (error) => ({ type: SET_NETWORK_PROVIDER_VALID, error });

// API actions triggers
export const triggerPaymentsStkPush = () => ({ type: TRIGGER_PAYMENTS_STK_PUSH });
export const triggerKePaymentsStkPush = () => ({ type: TRIGGER_KE_PAYMENTS_STK_PUSH });
export const triggerPaymentsStkPushSuccess = (value) => ({ type: TRIGGER_PAYMENTS_STK_PUSH_SUCCESS, value });
export const triggerPaymentsStkPushFailure = (error) => ({ type: TRIGGER_PAYMENTS_STK_PUSH_FAILURE, error });
export const checkPaymentsStatusSuccess = (value) => ({ type: CHECK_PAYMENTS_STATUS_SUCCESS, value });
export const checkPaymentsStatusFailure = (error) => ({ type: CHECK_PAYMENTS_STATUS_FAILURE, error });
export const initializePayments = () => ({ type: INITIALIZE_PAYMENTS });
export const checkPaymentsStatus = () => ({ type: CHECK_PAYMENTS_STATUS });

export const triggerConfirmOrder = () => ({ type: TRIGGER_CONFIRM_ORDER });
export const confirmOrderSuccess = (value) => ({ type: CONFIRM_ORDER_SUCCESS, value });
export const confirmOrderFailure = (error) => ({ type: CONFIRM_ORDER_FAILURE, error });
