import ReferenceData from '../../refData/reference-data.js';

export const config = {
    // Name
    FIRST_NAME: { MIN: 2, MAX: 48 },
    NAME: { MIN: 2, MAX: 48 },
    SURNAME: { MIN: 2, MAX: 64 },

    // Date / Numbers
    NUMBER: { DECIMAL_POINTS: 2 },
    AGE: { MIN: 1, MAX: 999 },
    PASSPORT_NUMBER: { MIN: 6, MAX: 20 },

    // Email
    EMAIL: { MIN: 6, MAX: 200 },

    //Kenya car Reg.no
    KE_CAR_REG_NO: { MIN: 7, MAX: 7 },

    //Kenya Id
    KE_ID_NO: { MIN: 6, MAX: 8 },

    //KRA Pin number
    KRA_PIN_NO: { MIN: 11, MAX: 11 },

    // Address
    PRIVATE_BAG: { MIN: 4, MAX: 8 },

    // Account
    BANK_ACCOUNT: { MIN: 8, MAX: 11 },

    // Pronouns
    PRONOUN: { YOUR: 'your', THEIR: 'their' },

    KE_PASSPORT: { MIN: 6, MAX: 9 },
};

export const countriesConfig = {
    bw: {
        name: 'Botswana',
        valueLength: 8,
        prefixLength: 2,
        cellNumbers: ReferenceData.botswanaCellNumberPrefixes,
        landlines: [],
    },
    za: {
        name: 'South African',
        valueLength: 10,
        prefixLength: 3,
        cellNumbers: ReferenceData.cellNumberPrefixes,
        landlines: ReferenceData.landlinePrefixes,
    },
    mw: {
        name: 'Malawi',
        valueLength: 9,
        prefixLength: 3,
        cellNumbers: ReferenceData.malawiCellNumberPrefixes,
        landlines: [],
    },
    ss: {
        name: 'South Sudan',
        valueLength: 10,
        prefixLength: 3,
        cellNumbers: ReferenceData.southSudanCellNumberPrefixes,
        landlines: [],
        ignorePrefix: true,
    },
    sz: {
        name: 'Eswatini',
        valueLength: 8,
        prefixLength: 3,
        cellNumbers: ReferenceData.eswatiniCellNumberPrefixes,
        landlines: [],
    },
    zw: {
        name: 'Zimbabwe',
        valueLength: 10,
        prefixLength: 3,
        cellNumbers: ReferenceData.zimbabweCellNumberPrefixes,
        landlines: [],
    },
    na: {
        name: 'Namibia',
        valueLength: 10,
        prefixLength: 3,
        cellNumbers: ReferenceData.namibiaCellNumberPrefixes,
        landlines: [],
    },
    rw: {
        name: 'Rwanda',
        valueLength: 10,
        prefixLength: 3,
        cellNumbers: ReferenceData.rwandaCellNumberPrefixes,
        landlines: ReferenceData.rwandaLandlinePrefixes,
    },
    tz: {
        name: 'Tanzania',
        valueLength: 10,
        prefixLength: 2,
        cellNumbers: ReferenceData.tanzaniaCellNumberPrefixes,
        landlines: [],
    },
    ug: {
        name: 'Uganda',
        valueLength: 10,
        prefixLength: 3,
        cellNumbers: ReferenceData.ugandaCellNumberPrefixes,
        landlines: [],
    },
    ke: {
        name: 'Kenya',
        valueLength: 10,
        prefixLength: 3,
        cellNumbers: ReferenceData.kenyaCellNumberPrefixes,
        landlines: [],
    },
    gh: {
        name: 'Ghana',
        valueLength: 10,
        prefixLength: 4,
        cellNumbers: ReferenceData.ghanaCellNumberPrefixes,
        landlines: [],
    },
    ng: {
        name: 'Nigeria',
        valueLength: 11,
        prefixLength: 4,
        cellNumbers: ReferenceData.nigeriaCellNumberPrefixes,
        landlines: [],
    }
};

export const DEFAULT_COUNTRY_CODE = 'za';
