export const UG_MOTOR_INSURANCE_PERSONAL_DETAILS_FORM = 'ugMotorInsurance/personalDetailsForm/';

export const MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE = UG_MOTOR_INSURANCE_PERSONAL_DETAILS_FORM + 'setValue/';
export const MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID = UG_MOTOR_INSURANCE_PERSONAL_DETAILS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_PERSONAL_DETAILS_FORM =
    'ugMotorInsurance/personalDetailsForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_PERSONAL_DETAILS_FORM =
    'ugMotorInsurance/personalDetailsForm/transition/formInValid';

export const REINITIALIZE = 'ugMotorInsurance/personalDetailsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_PERSONAL_DETAILS_FORM =
    'ugMotorInsurance/personalDetailsForm/transition/dropOffLead';
export const dropOffLeadPersonalDetails = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_PERSONAL_DETAILS_FORM,
    value,
});

export const transitionMotorInsurancePersonalDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_PERSONAL_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_PERSONAL_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM = `${MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM = `${MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM = `${MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionPersonalDetailsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM,
    }),
};

//calculated Data
export const COLLECT_PERSONAL_DETAILS_FORM_DATA =
    MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'collectPersonalDetailsData';
export const SUBMIT_PERSONAL_DETAILS_SUCCESS = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'success';
export const SUBMIT_PERSONAL_DETAILS_FAILURE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'failure';

export const collectPersonalDetailsData = () => ({
    type: COLLECT_PERSONAL_DETAILS_FORM_DATA,
});
export const submitPersonalDetailsDataSuccess = (value) => ({
    type: SUBMIT_PERSONAL_DETAILS_SUCCESS,
    value,
});
export const submitPersonalDetailsFailure = (error) => ({
    type: SUBMIT_PERSONAL_DETAILS_FAILURE,
    error,
});

// Form Values
export const CALCULATE_PERSONAL_DETAILS_AML_WHITELIST_SUCCESS =
    MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'calculateAmlWhitelist';

export const GENDER_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'gender';
export const GENDER_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'gender';

export const FIRST_NAME_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'firstName';

export const MIDDLE_NAME_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'middleName';
export const MIDDLE_NAME_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'middleName';

export const LAST_NAME_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'lastName';
export const LAST_NAME_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'lastName';

export const NATIONALITY_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'nationality';
export const NATIONALITY_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'nationality';

export const DOB_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'DOB';

export const OCCUPATION_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'occupation';
export const OCCUPATION_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'occupation';

export const ID_NUMBER_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'idNumber';

export const ID_CARD_NUMBER_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'idCardNumber';
export const ID_CARD_NUMBER_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'idCardNumber';

export const TIN_NUMBER_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'tinNumber';
export const TIN_NUMBER_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'tinNumber';

export const PHONE_NUMBER_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'phoneNumber';

export const EMAIL_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'email';

export const POSTAL_ADDRESS_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'postalAddress';
export const POSTAL_ADDRESS_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'postalAddress';

export const MARITAL_STATUS_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'maritalStatus';
export const MARITAL_STATUS_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'maritalStatus';

export const RESIDENTIAL_STATUS_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'residentialStatus';
export const RESIDENTIAL_STATUS_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'residentialStatus';

export const TOWN_OR_CITY_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'townOrCity';
export const TOWN_OR_CITY_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'townOrCity';

export const ADDRESS_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'address';
export const ADDRESS_SET_VALID = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID + 'address';

export const SELECTED_FORM_SET_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'selectedForm';

export const TRIGGER_GET_NATIONALITIES = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'fetch/nationalities';
export const SET_NATIONALITIES_VALUE = MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'set/nationalities';
export const API_FAILURE_TRANSITION_NATIONALITIES =
    MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'api/failure/nationalities';
export const API_SUCCESS_TRANSITION_NATIONALITIES =
    MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'api/success/nationalities';
export const API_PENDING_TRANSITION_NATIONALITIES =
    MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE + 'api/pending/nationalities';

export const PERSONAL_DETAILS_AML_WHITELIST = 'personalDetails/amlWhitelist/';

export const TRIGGER_AML_WHITELIST_CHECK = PERSONAL_DETAILS_AML_WHITELIST + 'check/';

export const triggerAmlWhitelistCheck = () => ({
    type: TRIGGER_AML_WHITELIST_CHECK,
});

// action creators
export const calculatePersonalDetailsAmlWhitelistSuccess = (value) => ({
    type: CALCULATE_PERSONAL_DETAILS_AML_WHITELIST_SUCCESS,
    value,
});

export const setGenderValue = (value) => ({
    type: GENDER_SET_VALUE,
    value,
});

export const setGenderValid = (error) => ({
    type: GENDER_SET_VALID,
    error,
});

export const setNationalityValue = (value) => ({
    type: NATIONALITY_SET_VALUE,
    value,
});

export const setNationalityValid = (error) => ({
    type: NATIONALITY_SET_VALID,
    error,
});

export const setOccupationValue = (value) => ({
    type: OCCUPATION_SET_VALUE,
    value,
});

export const setOccupationValid = (error) => ({
    type: OCCUPATION_SET_VALID,
    error,
});

export const setIdNumberValue = (value) => ({
    type: ID_NUMBER_SET_VALUE,
    value,
});

export const setIdNumberValid = (error) => ({
    type: ID_NUMBER_SET_VALID,
    error,
});

export const setIdCardNumberValue = (value) => ({
    type: ID_CARD_NUMBER_SET_VALUE,
    value,
});

export const setIdCardNumberValid = (error) => ({
    type: ID_CARD_NUMBER_SET_VALID,
    error,
});

export const setTinNumberValue = (value) => ({
    type: TIN_NUMBER_SET_VALUE,
    value,
});

export const setTinNumberValid = (error) => ({
    type: TIN_NUMBER_SET_VALID,
    error,
});

export const setPostalAddressValue = (value) => ({
    type: POSTAL_ADDRESS_SET_VALUE,
    value,
});

export const setPostalAddressValid = (error) => ({
    type: POSTAL_ADDRESS_SET_VALID,
    error,
});

export const setFirstNameValue = (value) => ({
    type: FIRST_NAME_SET_VALUE,
    value,
});
export const setFirstNameValid = (error) => ({
    type: FIRST_NAME_SET_VALID,
    error,
});

export const setMiddleNameValue = (value) => ({
    type: MIDDLE_NAME_SET_VALUE,
    value,
});
export const setMiddleNameValid = (error) => ({
    type: MIDDLE_NAME_SET_VALID,
    error,
});

export const setLastNameValue = (value) => ({
    type: LAST_NAME_SET_VALUE,
    value,
});
export const setLastNameValid = (error) => ({
    type: LAST_NAME_SET_VALID,
    error,
});

export const setPhoneNumberValue = (value) => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = (error) => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setEmailValue = (value) => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = (error) => ({
    type: EMAIL_SET_VALID,
    error,
});

export const setDOBValue = (value) => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDOBValid = (error) => ({
    type: DOB_SET_VALID,
    error,
});

export const setMaritalStatusValue = (value) => ({
    type: MARITAL_STATUS_SET_VALUE,
    value,
});
export const setMaritalStatusValid = (error) => ({
    type: MARITAL_STATUS_SET_VALID,
    error,
});

export const setResidentialStatusValue = (value) => ({
    type: RESIDENTIAL_STATUS_SET_VALUE,
    value,
});

export const setResidentialStatusValid = (error) => ({
    type: RESIDENTIAL_STATUS_SET_VALID,
    error,
});

export const setTownOrCityValue = (value) => ({
    type: TOWN_OR_CITY_SET_VALUE,
    value,
});
export const setTownOrCityValid = (error) => ({
    type: TOWN_OR_CITY_SET_VALID,
    error,
});

export const setAddressValue = (value) => ({
    type: ADDRESS_SET_VALUE,
    value,
});
export const setAddressValid = (error) => ({
    type: ADDRESS_SET_VALID,
    error,
});

export const setSelectedFormValue = (value) => ({
    type: SELECTED_FORM_SET_VALUE,
    value,
});

export const apiTransitionNationalities = {
    pending: () => ({
        type: API_PENDING_TRANSITION_NATIONALITIES,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_NATIONALITIES,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_NATIONALITIES,
    }),
};

export const triggerGetNationalities = () => ({
    type: TRIGGER_GET_NATIONALITIES,
});

export const setNationalitiesList = (value) => ({
    type: SET_NATIONALITIES_VALUE,
    value,
});
