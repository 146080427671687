import { createSelector } from "reselect";
import { API_CALL_STATUS, FORM_STATUS, FIELD_STATUS } from '../../reducers/status';
import { getCustomerVerificationService } from "./index";

export const getCallMeBack = createSelector(
    getCustomerVerificationService,
    cvs => cvs.callMeBack,
);

export const getFormStatus = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.formStatus,
);

export const getFormInvalid = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.formStatus === FORM_STATUS.INVALID,
);

export const getIsCallMeBackOpen = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.openDrawer,
);

export const getCallMeBackForm = createSelector(
    getCallMeBack,
    cmb => cmb && cmb.form,
);

const makeGetField = fieldId => createSelector(
    getCallMeBackForm,
    cmbForm => cmbForm[fieldId]
);

export const getName = makeGetField('name');
export const getSurname = makeGetField('surname');
export const getContactNumber = makeGetField('contactNumber');

export const getIsFormValid = createSelector(
    getName,
    getSurname,
    getContactNumber,
    (name, surname, contactNumber) => {
        return name && name.status === FIELD_STATUS.VALID &&
        surname && surname.status === FIELD_STATUS.VALID &&
        contactNumber && contactNumber.status === FIELD_STATUS.VALID;
    }
);

export const getGtmContextUrl = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.gtmContextUrl,
);

export const getGtmContextGaId = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.gtmContextGaId,
);

const getParentAppSource = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.parentAppSource
);
export const getProductName = createSelector(
    getParentAppSource,
    parentAppSource => parentAppSource.productName,
);
export const getRedirectUrl = createSelector(
    getParentAppSource,
    getParentAppSource => getParentAppSource.redirectUrl,
);

export const getSubmitSuccess = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.cmbStatus === API_CALL_STATUS.SUCCESS,
);

export const getSubmitPending = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.cmbStatus === API_CALL_STATUS.PENDING,
);

export const getSubmitError = createSelector(
    getCallMeBack,
    callMeBack => callMeBack.cmbStatus === API_CALL_STATUS.FAILURE,
);

export const getFormDisabled = createSelector(
    getSubmitPending,
    getFormInvalid,
    (pending, invalid) => pending || invalid,
);

export const getButtonText = createSelector(
    getCallMeBack,
    callMeBack => {
        switch (callMeBack.cmbStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'Sent';
            case API_CALL_STATUS.FAILURE:
                return 'Try Again';
            case API_CALL_STATUS.PENDING:
                return 'Sending...';
            default:
                return 'Call Me Back';
        }
    }
);

export const getSubmissionBody = createSelector(
    getName, 
    getSurname,
    getContactNumber,
    getProductName,
    getGtmContextUrl, 
    getGtmContextGaId,
    (name, surname, contactNumber, productName, url, gaId) => {
        // Note: firstName and name are both required!
        return {
            pageURL: encodeURIComponent(url),
            source: 'MyOldMutualPublicWebsite',
            googleAnalyticsId: gaId,
            formData: {
                firstName: name.value,
                name: name.value,
                surname: surname.value,
                phoneNumber: contactNumber.value,
                sourceProduct: productName, 
            },
        };
    }
);