import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    SET_FIRST_NAME,
    REINITIALIZE,
    SET_FIRST_NAME_VALID,
    SET_PHONE_NUMBER,
    SET_PHONE_NUMBER_VALID,
    SET_SURNAME,
    SET_SURNAME_VALID,
    SET_EMAIL_ADDRESS,
    SET_EMAIL_ADDRESS_VALID,
    SET_POLICY_NUMBER,
    SET_POLICY_NUMBER_VALID,
    SET_SELECTED_CLAIM_OR_REQUEST,
    SET_MAKE_A_CLAIM_AUTHORIZATION,
    MAKE_A_CLAIM_CATEGORIES,
    MAKE_A_CLAIM_TYPES,
    MAKE_A_CLAIM_REQUEST,
    SET_SELECTED_CLAIM_CATEGORIES,
    SET_SELECTED_REQUEST,
    SET_SELECTED_CLAIM_TYPES,
    MAKE_A_CLAIM_SEND_EMAIL,
    MAKE_A_CLAIM_SEND_EMAIL_SUCCESS,
    MAKE_A_CLAIM_SEND_EMAIL_FAILURE,
    FORM_VALID_MAKE_A_CLAIM,
    FORM_INVALID_MAKE_A_CLAIM,
    EMAIL_SENDING_STATUS_SET_VALUE,
} from '../../actions/makeAClaim';

const initialState = {
    formStatus: FORM_STATUS.INVALID,
    claimCategories: [],
    claimTypes: [],
    claimRequest: [],
    emailSendingStatus: { value: false },
    emailResponseData: { value: null, error: null },
    apiCallStatus: API_CALL_STATUS.IDLE,
    form: {
        firstName: { value: '', error: null, status: null },
        surname: { value: '', error: null, status: null },
        phoneNumber: { value: '', error: null, status: null },
        emailAddress: { value: '', error: null, status: null },
        selectedClaimTypes: { value: '', error: null, status: null },
        selectedClaimCategories: { value: '', error: null, status: null },
        selectedClaimOrRequest: { value: '', error: null, status: null },
        selectedRequest: { value: '', error: null, status: null },
        isAuthorizeMakeAClaim: { value: false, error: null, status: null },
    },
};

export default (state = initialState, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_MAKE_A_CLAIM:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_MAKE_A_CLAIM:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case SET_FIRST_NAME:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_FIRST_NAME_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SET_SURNAME:
            return updateFormField('surname', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SURNAME_VALID:
            return updateFormField('surname', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SET_PHONE_NUMBER:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_PHONE_NUMBER_VALID:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SET_EMAIL_ADDRESS:
            return updateFormField('emailAddress', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_EMAIL_ADDRESS_VALID:
            return updateFormField('emailAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SET_POLICY_NUMBER:
            return updateFormField('policyNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_CLAIM_OR_REQUEST:
            return updateFormField('selectedClaimOrRequest', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_POLICY_NUMBER_VALID:
            return updateFormField('policyNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SET_MAKE_A_CLAIM_AUTHORIZATION:
            return updateFormField('isAuthorizeMakeAClaim', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_CLAIM_CATEGORIES:
            return updateFormField('selectedClaimCategories', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_REQUEST:
            return updateFormField('selectedRequest', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_CLAIM_TYPES:
            return updateFormField('selectedClaimTypes', (field) => ({
                ...field,
                value: action.value,
            }));
        case MAKE_A_CLAIM_CATEGORIES:
            return {
                ...state,
                claimCategories: action.value,
            };
        case MAKE_A_CLAIM_TYPES:
            return {
                ...state,
                claimTypes: action.value,
            };
        case MAKE_A_CLAIM_REQUEST:
            return {
                ...state,
                claimRequest: action.value,
            };
        case MAKE_A_CLAIM_SEND_EMAIL:
            return {
                ...state,
                emailResponseData: { ...state.emailResponseData, value: null, error: null },
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case MAKE_A_CLAIM_SEND_EMAIL_SUCCESS:
            return {
                ...state,
                emailResponseData: { ...state.emailResponseData, value: action.value, error: null },
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case MAKE_A_CLAIM_SEND_EMAIL_FAILURE:
            return {
                ...state,
                emailResponseData: { ...state.emailResponseData, error: action.error },
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        case EMAIL_SENDING_STATUS_SET_VALUE:
            return {
                ...state,
                emailSendingStatus: { ...state.emailSendingStatus, value: action.value },
            };
        case REINITIALIZE:
            return initialState;
        default:
            return state;
    }
}