import {
    getApiStatus,
    getCarDetailsForm,
    getRegistrationNumberValue,
} from '../../../selectors/motorPrivateRW/carDetails';
import {
    apiTransitioncarDetailsForm,
    submitCarDetailsDataSuccess,
    submitCarDetailsFailure
} from '../../../actions/motorPrivateRW/carDetails';
import { getAccessToken } from '../../../selectors/motorPrivateRW/payments';
import axios from 'axios';

export const validInsuranceService = (store, next, action) => {
    (async () => {
        // if (!getApiStatus(store.getState())) {
            store.dispatch(apiTransitioncarDetailsForm.pending());
            try {
                const resp = await validity(store.getState());
                store.dispatch(submitCarDetailsDataSuccess(resp.data));
                store.dispatch(apiTransitioncarDetailsForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitioncarDetailsForm.failure());
                store.dispatch(submitCarDetailsFailure(e));
            }
        // }
    })();
    return next(action);
};

const validInsurancePayload = (state) => {
    const carDetails = getCarDetailsForm(state);

    const payload = {
        registrationNo: carDetails.registrationNumber.value
    };

    // Change payload here
    return {
        ...payload,
    };
};

const validity = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/rw-motor-insurance/check-valid-insurance';
        return await axios.post(url, validInsurancePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
