import {
  CITY_SET_VALUE,
  COMPLEX_SET_VALUE,
  COUNTRY_SET_VALUE,
  MARITAL_STATUS_SET_VALUE,
  MINOR_FIRST_NAMES_SET_VALUE,
  MINOR_ID_SET_VALUE,
  MINOR_SURNAME_SET_VALUE,
  MINOR_TITLE_SET_VALUE,
  POSTAL_ADDRESS_METHOD_VALUE,
  POSTAL_ADDRESS_SAME_TOGGLE_VALUE,
  POSTAL_ADDRESS_SAME_VALUE,
  POSTAL_CITY_SET_VALUE,
  POSTAL_CODE_SET_VALUE,
  POSTAL_COMPLEX_SET_VALUE,
  POSTAL_COUNTRY_SET_VALUE,
  POSTAL_POSTAL_CODE_SET_VALUE,
  POSTAL_STREET_ADDRESS_SET_VALUE,
  POSTAL_SUBURB_SET_VALUE,
  PO_BOX_SET_VALUE,
  PO_COUNTRY_SET_VALUE,
  PO_POSTAL_CODE_SET_VALUE,
  PO_POST_OFFICE_SET_VALUE,
  PRIVATE_BAG_SET_VALUE,
  PRIVATE_COUNTRY_SET_VALUE,
  PRIVATE_POSTAL_CODE_SET_VALUE,
  PRIVATE_POST_OFFICE_SET_VALUE,
  SPOUSE_FIRST_NAMES_SET_VALUE,
  SPOUSE_ID_NUMBER_SET_VALUE,
  SPOUSE_ID_TYPE_TOGGLE_VALUE,
  SPOUSE_PASSPORT_NUMBER_SET_VALUE,
  SPOUSE_SURNAME_SET_VALUE,
  SPOUSE_TITLE_SET_VALUE,
  STREET_ADDRESS_SET_VALUE,
  SUBURB_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/auxiliaryDetails";
import {
  getCity,
  getComplex,
  getCountry,
  getIsPostalAddressSame,
  getMaritalStatus,
  getMinorFirstNames,
  getMinorId,
  getMinorSurname,
  getMinorTitle,
  getPoBox,
  getPoCountry,
  getPoPostOffice,
  getPoPostalCode,
  getPostalAddressMethod,
  getPostalAddressSame,
  getPostalCity,
  getPostalCode,
  getPostalComplex,
  getPostalCountry,
  getPostalPostalCode,
  getPostalStreetAddress,
  getPostalSuburb,
  getPrivateBag,
  getPrivateCountry,
  getPrivatePostOffice,
  getPrivatePostalCode,
  getSpouseFirstNames,
  getSpouseIdNumber,
  getSpouseIdentityType,
  getSpousePassportNumber,
  getSpouseSurname,
  getSpouseTitle,
  getStreetAddress,
  getSuburb,
} from "../../../selectors/taxFreeSavingsAccount/auxiliaryDetails";
import { simpleFieldTracking } from "../formTracking";
import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";
import { findOtherEventIndex, otherEventTracking } from "../otherEventTracking";

export const auxiliaryDetailsTracking = (store, next, action) => {
  const prevState = store.getState();
  const result = next(action);
  const currentState = store.getState();
  const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
  let sendValue = true;
  let sendError = true;

  const fieldSelector = (() => {
    switch (action.type) {
      case MINOR_TITLE_SET_VALUE:
        sendError = false;
        return getMinorTitle;
      case MINOR_FIRST_NAMES_SET_VALUE:
        sendValue = false;
        return getMinorFirstNames;
      case MINOR_SURNAME_SET_VALUE:
        sendValue = false;
        return getMinorSurname;
      case MINOR_ID_SET_VALUE:
        sendValue = false;
        return getMinorId;
      case MARITAL_STATUS_SET_VALUE:
        sendError = false;
        return getMaritalStatus;
      case SPOUSE_TITLE_SET_VALUE:
        sendError = false;
        return getSpouseTitle;
      case SPOUSE_FIRST_NAMES_SET_VALUE:
        sendValue = false;
        return getSpouseFirstNames;
      case SPOUSE_SURNAME_SET_VALUE:
        sendValue = false;
        return getSpouseSurname;
      case SPOUSE_ID_NUMBER_SET_VALUE:
        sendValue = false;
        return getSpouseIdNumber;
      case SPOUSE_PASSPORT_NUMBER_SET_VALUE:
        sendValue = false;
        return getSpousePassportNumber;
      case COMPLEX_SET_VALUE:
        sendValue = false;
        return getComplex;
      case STREET_ADDRESS_SET_VALUE:
        sendValue = false;
        return getStreetAddress;
      case SUBURB_SET_VALUE:
        sendValue = false;
        return getSuburb;
      case CITY_SET_VALUE:
        return getCity;
      case POSTAL_CODE_SET_VALUE:
        sendValue = false;
        return getPostalCode;
      case COUNTRY_SET_VALUE:
        sendError = false;
        return getCountry;
      case POSTAL_ADDRESS_SAME_VALUE:
        sendValue = false;
        sendError = false;
        return getPostalAddressSame;
      case POSTAL_ADDRESS_METHOD_VALUE:
        sendValue = false;
        sendError = false;
        return getPostalAddressMethod;
      case POSTAL_COMPLEX_SET_VALUE:
        sendValue = false;
        return getPostalComplex;
      case POSTAL_STREET_ADDRESS_SET_VALUE:
        sendValue = false;
        return getPostalStreetAddress;
      case POSTAL_SUBURB_SET_VALUE:
        sendValue = false;
        return getPostalSuburb;
      case POSTAL_CITY_SET_VALUE:
        return getPostalCity;
      case POSTAL_POSTAL_CODE_SET_VALUE:
        sendValue = false;
        return getPostalPostalCode;
      case POSTAL_COUNTRY_SET_VALUE:
        sendValue = false;
        sendError = false;
        return getPostalCountry;
      case PO_BOX_SET_VALUE:
        sendValue = false;
        return getPoBox;
      case PO_POST_OFFICE_SET_VALUE:
        sendValue = false;
        return getPoPostOffice;
      case PO_POSTAL_CODE_SET_VALUE:
        sendValue = false;
        return getPoPostalCode;
      case PO_COUNTRY_SET_VALUE:
        sendError = false;
        return getPoCountry;
      case PRIVATE_BAG_SET_VALUE:
        sendValue = false;
        return getPrivateBag;
      case PRIVATE_POST_OFFICE_SET_VALUE:
        sendValue = false;
        return getPrivatePostOffice;
      case PRIVATE_POSTAL_CODE_SET_VALUE:
        sendValue = false;
        return getPrivatePostalCode;
      case PRIVATE_COUNTRY_SET_VALUE:
        sendError = false;
        return getPrivateCountry;
      default:
        return null;
    }
  })();

  const field = onFieldChange(fieldSelector);
  if (field) simpleFieldTracking({ action, field, sendValue, sendError });

  return result;
};

export const auxiliaryDetailsOtherTracking = (store, next, action) => {
  const result = next(action);
  const state = store.getState();
  let otherEventIndex;
  const field = (() => {
    switch (action.type) {
      case SPOUSE_ID_TYPE_TOGGLE_VALUE:
        otherEventIndex = findOtherEventIndex("SELECT");
        return getSpouseIdentityType(state).value ? "southAfricanID" : "passport";
      case POSTAL_ADDRESS_SAME_TOGGLE_VALUE:
        otherEventIndex = findOtherEventIndex("SELECT");
        return getIsPostalAddressSame(state) ? "postalAddressSame" : "postalAddressNotSame";
      default:
        return null;
    }
  })();

  if (field) {
    otherEventTracking(action.type, otherEventIndex, field);
  }

  return result;
};
