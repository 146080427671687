import { validateNumber } from '@om/validation';

import {
    RETIREMENT_AGE_SET_VALUE,
    setRetirementAgeValid,
    NUMBER_OF_RETIREMENT_YEARS_SET_VALUE,
    setNumberOfRetiremntYearsValid,
    EXPECTED_ANNUAL_GROWTH_SET_VALUE,
    setExpectedAnnualGrowthValid,
    INFLATION_SET_VALUE,
    setInflationValid,
    EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALUE,
    setExpectedAnnualSalaryIncreaseValid,
    REPLACEMENT_RATIO_SET_VALUE,
    setReplacementRatioValid,
} from '../../../actions/retirementCalculator/retirementAssumptionsModel';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const retirementCalculatorAssumptionsModelValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case RETIREMENT_AGE_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'retirement age',
                        min: 55,
                        max: 70,
                        inclusive: true,
                    });
                }
                return setRetirementAgeValid(error);

            case NUMBER_OF_RETIREMENT_YEARS_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'number of retirement years',
                        min: 10,
                        max: 40,
                        inclusive: true,
                        decimalPoints: 0,
                    });
                }
                return setNumberOfRetiremntYearsValid(error);

            case EXPECTED_ANNUAL_GROWTH_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'expected annual growth',
                        min: 1,
                        max: 30,
                        inclusive: true,
                        decimalPoints: 0,
                    });
                }
                return setExpectedAnnualGrowthValid(error);

            case INFLATION_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, { subject: 'inflation', min: 1, max: 30, decimalPoints: 0 });
                }
                return setInflationValid(error);

            case EXPECTED_ANNUAL_SALARY_INCREASE_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'expected annual salary increase',
                        min: 1,
                        max: 30,
                        inclusive: true,
                        decimalPoints: 0,
                    });
                }
                return setExpectedAnnualSalaryIncreaseValid(error);

            case REPLACEMENT_RATIO_SET_VALUE:
                if (action.value) {
                    error = validateNumber(action.value, {
                        subject: 'replacement ratio',
                        min: 1,
                        max: 100,
                        inclusive: true,
                        decimalPoints: 0,
                    });
                }
                return setReplacementRatioValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction);
    return next(action);
};
