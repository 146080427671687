import { createSelector } from "reselect";

export const getBusinessProducts = state => state.publicWeb.businessProducts;

export const getParams = createSelector(
    getBusinessProducts,
    businessProducts => businessProducts.params
);

export const getBusinessProductsData = createSelector(
    getBusinessProducts,
    businessProducts => businessProducts.productData
);

const makeGetField = fieldId => createSelector(
    getBusinessProductsData,
    products => products[fieldId]
);

export const getVisibleBusinessProducts = makeGetField('products');