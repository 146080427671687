import { APPLICATION_NOW_SUCCESS, APPLICATION_NOW_FAIL, RESET_APPLICATION_NOW, APPLICATION_UTM, OFFER_QUOTE_DECLINED } from "../../actions/digitalOnboarding/types";

export const initApplicationNow = {
    applicationNumber: 0,
    applicationOutcome: '',
    branchName: '',
    branchTelephone: '',
    consultantName: '',
    proceedWithApplication: true,
    utmTags: '',
}


export default (state = initApplicationNow, action) => {
    const setResponseData = (data) => ({
        ...state,
        applicationNumber: data.applicationNumber,
        applicationOutcome: data.applicationOutcome,
        branchName: data.existingLoanBranchDetail?.branchName,
        branchTelephone: data.existingLoanBranchDetail?.branchTelephone,
        consultantName: data.existingLoanBranchDetail?.consultantName,
        proceedWithApplication: data.proceedWithApplication
    });

    const setFail = () => ({
        ...state,
        applicationNumber: 0,
        applicationOutcome: '',
        branchName: '',
        branchTelephone: '',
        consultantName: '',
        proceedWithApplication: false,
        utmTags: null,
    });

    switch (action.type) {
        case APPLICATION_NOW_SUCCESS:
            return setResponseData(action.data);
        case APPLICATION_NOW_FAIL:
            return setFail();
        case RESET_APPLICATION_NOW:
            return {
                ...state,
                applicationNumber: 0,
                applicationOutcome: '',
                branchName: '',
                branchTelephone: '',
                consultantName: '',
                proceedWithApplication: true,
            }
        case OFFER_QUOTE_DECLINED:
            return {
                ...state,
                applicationOutcome: 'CREDIT_DECLINE',
                proceedWithApplication: false,
            }
        case APPLICATION_UTM:
            return { ...state, utmTags: action.utmTags }
        default:
            break;
    }

    return state;
}
