import {
    HOW_MUCH_OWED_SET_VALUE,
    INTEREST_RATE_SET_VALUE,
    MONTHLY_REPAYMENT_SET_VALUE
} from '../../../actions/debtRepaymentCalculators/debtRepaymentCalculator';
import {debtRepaymentCalculatorValidation} from './debtRepaymentCalculator';

export const debtRepaymentValidation = (store, next, action) => {
    let actions = [HOW_MUCH_OWED_SET_VALUE, MONTHLY_REPAYMENT_SET_VALUE, INTEREST_RATE_SET_VALUE]
    if (actions.includes(action.type)) {
        return debtRepaymentCalculatorValidation(store, next, action);
    }

    return null;
};
