import { validateSingleSelection } from  "@om/validation";
import {
    setReplacementPolicyValid,
    setTermsAndConditionsValid,
    TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW,
    TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW
} from "../../../actions/raOptimal/review";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const reviewValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW:
                error = validateSingleSelection((action.value ? null : true), 'checkbox');
                return setReplacementPolicyValid(error);

            case TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW:
                error = validateSingleSelection((action.value ? null : true), 'checkbox');
                return setTermsAndConditionsValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
