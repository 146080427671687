export const BUDGET_BENCHMARK_CALCULATOR = 'budgetBenchmark/calculator/';

export const BUDGET_BENCHMARK_CALCULATOR_TRANSITION = BUDGET_BENCHMARK_CALCULATOR + 'transition/';
export const FORM_VALID_TRANSITION_CALCULATOR = BUDGET_BENCHMARK_CALCULATOR_TRANSITION + 'formValid';
export const FORM_INVALID_TRANSITION_CALCULATOR = BUDGET_BENCHMARK_CALCULATOR_TRANSITION + 'formInvalid';

export const transitionBudgetBenchmarkCalculator = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CALCULATOR }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CALCULATOR })
}

export const BUDGET_BENCHMARK_CALCULATOR_SET_VALUE = BUDGET_BENCHMARK_CALCULATOR + 'setValue/';
export const BUDGET_BENCHMARK_CALCULATOR_SET_VALID = BUDGET_BENCHMARK_CALCULATOR + 'setValid/';

export const TOGGLE_HAS_CHILDREN = BUDGET_BENCHMARK_CALCULATOR_TRANSITION + 'toggleHasChildren';
export const toggleHasChildren = () => ({ type: TOGGLE_HAS_CHILDREN });

export const TOGGLE_DISPLAY_RESULTS_CARD = BUDGET_BENCHMARK_CALCULATOR_TRANSITION + 'toggleDisplayResultsCard';
export const toggleDisplayResultsCard = () => ({ type: TOGGLE_DISPLAY_RESULTS_CARD });

export const TOGGLE_DISPLAY_RESULTS_BUDGET_TABLE = BUDGET_BENCHMARK_CALCULATOR_TRANSITION + 'toggleDisplayResultsBudgetTable';
export const toggleDisplayResultsBudgetTable = () => ({ type: TOGGLE_DISPLAY_RESULTS_BUDGET_TABLE });

export const NET_MONTHLY_INCOME_SET_VALUE = BUDGET_BENCHMARK_CALCULATOR_SET_VALUE + 'netMonthlyIncome';
export const NET_MONTHLY_INCOME_SET_VALID = BUDGET_BENCHMARK_CALCULATOR_SET_VALID + 'netMonthlyIncome';
export const setNetMonthlyIncomeValue = (value) => ({ type: NET_MONTHLY_INCOME_SET_VALUE, value });
export const setNetMonthlyIncomeValid = (error) => ({ type: NET_MONTHLY_INCOME_SET_VALID, error });
