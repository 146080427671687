import { LOOKUP_DATA_REQUEST, APPLICATION_NOW_REQUEST, OFFER_QUOTE_REQUEST, NAVIGATE_NEXT, NAVIGATE_BACK, OTP_SEND_REQUEST, OTP_RESEND_REQUEST, OTP_VERIFY_REQUEST, ADD_ADDRESS_REQUEST, FILE_UPLOAD_REQUEST, MAKE_APPLICATION_REQUEST, ACCEPT_OFFER_REQUEST, ACCEPT_OFFER_REQUEST_SUCCESS, DO_PAGE_REQUEST } from "../../../actions/digitalOnboarding/types";
import { getData } from "./lookupData";
import { getApplicationNow, makeApplication } from './applicationNow';
import { goBack, handlePageRequestQueue, navigateToNextPage } from './pageRequests';
import { acceptDigitalQuoteOfferForApplication, createDigitalOfferForApplication } from './digitalOfferQuote';
import { requestSendingOTP, verifyOTP } from './otpRequests';
import { addAddress } from './addressRequests';
import { uploadDocuments } from './documentStoreRequests';

export const digitalOnboardingService = (store, next, action) => {
    switch (action.type) {
        case NAVIGATE_BACK:
            return goBack(store,next,action);
        case NAVIGATE_NEXT:
            return navigateToNextPage(store, next, action);
        case LOOKUP_DATA_REQUEST:
            return getData(store, next, action);
        case APPLICATION_NOW_REQUEST:
            return getApplicationNow(store, next, action);
        case OFFER_QUOTE_REQUEST:
            return createDigitalOfferForApplication(store, next, action);
        case ACCEPT_OFFER_REQUEST:
            return acceptDigitalQuoteOfferForApplication(store, next, action);
        case OTP_SEND_REQUEST:
        case OTP_RESEND_REQUEST:
            return requestSendingOTP(store,next,action);
        case OTP_VERIFY_REQUEST:
            return verifyOTP(store, next, action);
        case ADD_ADDRESS_REQUEST:
            return addAddress(store, next, action);
        case FILE_UPLOAD_REQUEST:
            return uploadDocuments(store, next, action);
        case MAKE_APPLICATION_REQUEST:
            return makeApplication(store, next, action);
    }

    if (action.type.startsWith(DO_PAGE_REQUEST)) {
        return handlePageRequestQueue(store, next, action);
    }

    return null;
}