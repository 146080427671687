import { createSelector } from "reselect";
import { getTaxFreeSavingsAccount } from "./index";
import { FIELD_STATUS, TOGGLE_STATUS } from "../../reducers/status";
import { getBeneficiaryList } from "./beneficiaries";
import { EVEN_SPLIT_MATRIX } from "../../actions/taxFreeSavingsAccount/types";


export const getBeneficiariesSplit = createSelector(getTaxFreeSavingsAccount, tfsa => tfsa.beneficiariesSplit);

export const getBeneficiariesSplitForm = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.form,
);

export const getIsEvenSplit = createSelector(
    getBeneficiariesSplit,
    beneficiariesSplit => beneficiariesSplit.isEvenSplit === TOGGLE_STATUS.YES,
);

export const getTotalPercentage = createSelector(
    getBeneficiariesSplitForm,
    form => {
        if (form.length) {
            const values = form.map(splitEntry => {
                if (splitEntry.value && splitEntry.status === FIELD_STATUS.VALID) {
                    return Number(splitEntry.value);
                }
                return 0;
            });
            return values.reduce((cumulativeValue, value) => cumulativeValue + value).toString();
        } else return 0;
    },
);

export const getSplitError = createSelector(
    getTotalPercentage,
    percentage => percentage == 100 ? null : 'Your total should be 100%'
);

export const getIsBeneficiarySplitValid = createSelector(
    getTotalPercentage,
    getBeneficiariesSplitForm,
    (totalPercentage, form) => {
        if (form.length) {
            const statuses = form.map(splitFieldEntry => {
                if (splitFieldEntry.beneficiaryId !== null) {
                    return splitFieldEntry.status === FIELD_STATUS.VALID;
                } else return true;
            });
            const formIsValid = statuses.reduce((isValid, status) => isValid && status);
            return (totalPercentage == 100) && formIsValid;
        } else return false;
    }
);

export const getBeneficiaryListError = createSelector(
    getIsBeneficiarySplitValid,
    (splitValid) => splitValid ? FIELD_STATUS.VALID : FIELD_STATUS.ERROR
);

export const getInitialBeneficiarySplitList = createSelector(
    getBeneficiaryList,
    beneficiaries => {
        if (!beneficiaries.length) return [];

        if (beneficiaries.length === 1) {
            return [{ value: 100, status: FIELD_STATUS.VALID, error: null, beneficiaryId: beneficiaries[0].id }]
        } else {
            return beneficiaries.map(beneficiary => {
                return { value: null, status: null, error: null, beneficiaryId: beneficiary.id };
            })
        }
    }
);

export const getEvenSplitBeneficiariesList = createSelector(
    getBeneficiaryList,
    getIsEvenSplit,
    (beneficiaries, isEvenSplit) => {
        if (isEvenSplit) {
            const splitWays = beneficiaries.length;
            return beneficiaries.map((beneficiary, index) => {
                return { value: EVEN_SPLIT_MATRIX[splitWays-1][index], status: FIELD_STATUS.VALID, error: null, beneficiaryId: beneficiary.id };
            });
        } else {
            return beneficiaries.map((beneficiary) => {
                return { value: null, status: null, error: null, beneficiaryId: beneficiary.id };
            })
        }
    }
);

export const getBeneficiarySplitList = createSelector(
    getBeneficiaryList,
    getBeneficiariesSplitForm,
    (beneficiaries, form) => {
        if (!beneficiaries.length) return [];

        return beneficiaries.map((beneficiary, index) => {
            return { value: form[index].value, beneficiaryId: beneficiary.id };
        });
    }
);

