import {POSTAL_ADDRESS_METHOD} from "../../../reducers/status";

export const formatGCSPostalAddressSouthAfrica = personalDetailsForm => {
    const {
        postalAddressMethod,

        postalComplex,
        postalStreetAddress,
        postalSuburb,
        postalCity,
        postalPostalCode,
        postalCountry,

        complex,
        streetAddress,
        suburb,
        city,
        postalCode,
        country,
    } = personalDetailsForm;

    switch (postalAddressMethod.value) {
        case POSTAL_ADDRESS_METHOD.PO_BOX:
        case POSTAL_ADDRESS_METHOD.PRIVATE_BAG:
            return formatGCSPostalAddressGlobal(personalDetailsForm);
        case POSTAL_ADDRESS_METHOD.STREET_ADDRESS:
            return formatGCSStreetAddressSouthAfrica({
                complex: postalComplex.value,
                streetAddress: postalStreetAddress.value,
                suburb: postalSuburb.value,
                city: postalCity.value,
                postalCode: postalPostalCode.value,
                country: postalCountry.value,
            });
        default:
            return formatGCSStreetAddressSouthAfrica({
                complex: complex.value,
                streetAddress: streetAddress.value,
                suburb: suburb.value,
                city:city.value,
                postalCode: postalCode.value,
                country: country.value
            });
    }
};

export const formatGCSPostalAddressGlobal = personalDetailsForm => {
    const {
        postalAddressMethod,
        poBox,
        poPostOffice,
        poPostalCode,
        poCountry,

        privateBag,
        privatePostOffice,
        privatePostalCode,
        privateCountry,

        postalComplex,
        postalStreetAddress,
        postalSuburb,
        postalCity,
        postalPostalCode,
        postalCountry,

        complex,
        streetAddress,
        suburb,
        city,
        postalCode,
        country,
    } = personalDetailsForm;

    switch (postalAddressMethod.value) {
        case POSTAL_ADDRESS_METHOD.PO_BOX:
            return formatGCSObject({
                country: poCountry.value,
                addressArray: [
                    `PO Box ${poBox.value}`,
                    `${poPostOffice.value} ${poPostalCode.value}`,
                    poCountry.value
                ],
            });
        case POSTAL_ADDRESS_METHOD.PRIVATE_BAG:
            return formatGCSObject({
                country: privateCountry.value,
                addressArray: [
                    `Private Bag ${privateBag.value}`,
                    `${privatePostOffice.value} ${privatePostalCode.value}`,
                    privateCountry.value
                ],
            });
        case POSTAL_ADDRESS_METHOD.STREET_ADDRESS:
            return formatGCSStreetAddressGlobal({
                complex: postalComplex.value,
                streetAddress: postalStreetAddress.value,
                suburb: postalSuburb.value,
                city: postalCity.value,
                postalCode: postalPostalCode.value,
                country: postalCountry.value,
            });
        default:
            return formatGCSStreetAddressGlobal({
                complex: complex.value,
                streetAddress: streetAddress.value,
                suburb: suburb.value,
                city:city.value,
                postalCode: postalCode.value,
                country: country.value
            });
    }
};

export const formatGCSStreetAddressGlobal = ({complex, streetAddress, suburb, city, postalCode, country}) => {
    let addressArray = [];
    if (complex) {
        addressArray.push(complex);
        addressArray.push(streetAddress);
    } else {
        addressArray.push(streetAddress);
        if (suburb) addressArray.push(suburb);
    }

    addressArray.push(`${city} ${postalCode}`);
    addressArray.push(country);

    return formatGCSObject({addressArray, country});
};

export const formatGCSStreetAddressSouthAfrica = ({complex, streetAddress, suburb, city, postalCode, country}) => {
    let addressArray = [];

    if (complex) addressArray.push(complex);
    addressArray.push(streetAddress);
    if (suburb) addressArray.push(suburb);
    addressArray.push(city);

    return formatGCSObject({addressArray, postalCode, country});
};

export const formatGCSObject = ({addressArray, postalCode = '0000', country}) => {
    return {
        addressLine1: addressArray[0] || '',
        addressLine2: addressArray[1] || '',
        addressLine3: addressArray[2] || '',
        addressLine4: addressArray[3] || '',
        addressPostalCode: postalCode,
        addressCountry: country,
    };
};
