

export const FUTURE_VALUE_OF_SAVINGS_KEY = 'savingsCalculator/futureValueOfSavings';
export const FUTURE_VALUE_OF_SAVINGS_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_KEY}/setValue`;
export const FUTURE_VALUE_OF_SAVINGS_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_KEY}/setValid`;


export const FORM_VALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS = `${FUTURE_VALUE_OF_SAVINGS_KEY}/transition/formValid`;
export const FORM_INVALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS = `${FUTURE_VALUE_OF_SAVINGS_KEY}/transition/formInvalid`;
export const transitionFutureValueOfSavings = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_FUTURE_VALUE_OF_SAVINGS }),
};

export const FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_SET_VALUE}/expectedEndDate`;
export const FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_SET_VALID}/expectedEndDate`;
export const setExpectedEndDateValue = value => ({ type: FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALUE, value });
export const setExpectedEndDateValid = error => ({ type: FUTURE_SAVINGS_EXPECTED_END_DATE_SET_VALID, error });

export const FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_SET_VALUE}/currentSavings`;
export const FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_SET_VALID}/currentSavings`;
export const setCurrentSavingsValue = value => ({ type: FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALUE, value });
export const setCurrentSavingsValid = error => ({ type: FUTURE_SAVINGS_CURRENT_SAVINGS_SET_VALID, error });

export const FUTURE_SAVINGS_ANNUAL_SAVINGS_INCREASE_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_SET_VALUE}/annualSavingsIncrease`;
export const FUTURE_SAVINGS_ANNUAL_SAVINGS_INCREASE_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_SET_VALID}/annualSavingsIncrease`;
export const setAnnualSavingsIncreaseValue = value => ({ type: FUTURE_SAVINGS_ANNUAL_SAVINGS_INCREASE_SET_VALUE, value });
export const setAnnualSavingsIncreaseValid = error => ({ type: FUTURE_SAVINGS_ANNUAL_SAVINGS_INCREASE_SET_VALID, error });

export const FUTURE_SAVINGS_EXPECTED_SAVINGS_GROWTH_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_SET_VALUE}/expectedSavingsGrowth`;
export const FUTURE_SAVINGS_EXPECTED_SAVINGS_GROWTH_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_SET_VALID}/expectedSavingsGrowth`;
export const setExpectedSavingsGrowthValue = value => ({ type: FUTURE_SAVINGS_EXPECTED_SAVINGS_GROWTH_SET_VALUE, value });
export const setExpectedSavingsGrowthValid = error => ({ type: FUTURE_SAVINGS_EXPECTED_SAVINGS_GROWTH_SET_VALID, error });

export const EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE = `${FUTURE_VALUE_OF_SAVINGS_SET_VALUE}/expectedMonthlyContributions`;
export const EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID = `${FUTURE_VALUE_OF_SAVINGS_SET_VALID}/expectedMonthlyContributions`;
export const setExpectedMonthlyContributionValue = value => ({ type: EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALUE, value });
export const setExpectedMonthlyContributionValid = error => ({ type: EXPECTED_MONTHLY_CONTRIBUTIONS_SET_VALID, error });

export const CALCULATE_FUTURE_SAVINGS = `${FUTURE_VALUE_OF_SAVINGS_KEY}/calculate/futureSavings`;
export const calculateFutureSavings = () => ({ type: CALCULATE_FUTURE_SAVINGS });

export const FUTURE_VALUE_OF_SAVINGS_SHOW_RESULT_SUMMARY = `${FUTURE_VALUE_OF_SAVINGS_KEY}/showResultsSummary`;
export const setShowResultSummary = () => ({ type: FUTURE_VALUE_OF_SAVINGS_SHOW_RESULT_SUMMARY });

export const FUTURE_VALUE_OF_SAVINGS_OPEN_MODAL = `${FUTURE_VALUE_OF_SAVINGS_KEY}/openModal`;
export const setOpenModel = () => ({ type: FUTURE_VALUE_OF_SAVINGS_OPEN_MODAL});

export const FUTURE_VALUE_OF_SAVINGS_CLOSE_MODAL = `${FUTURE_VALUE_OF_SAVINGS_KEY}/closeModal`;
export const setCloseModal = () => ({ type: FUTURE_VALUE_OF_SAVINGS_CLOSE_MODAL });

export const PRODUCT_RECOMMENDATION_SET_VALUE =  FUTURE_VALUE_OF_SAVINGS_SET_VALUE + '/productRecommendation';
export const setViewRecommendation = () => ({ type: PRODUCT_RECOMMENDATION_SET_VALUE });

export const REINITIALIZE_FUTURE_VALUE_OF_SAVINGS_CALCULATOR = 'savingsCalculator/futureValueOfSavings/reinitialize';
export const reInitialize = () => ({ type: REINITIALIZE_FUTURE_VALUE_OF_SAVINGS_CALCULATOR });

export const RESET_CALCULATOR_FORM = 'savingsCalculator/futureValueOfSavings/reset/form';
export const resetCalculatorForm = () => ({ type: RESET_CALCULATOR_FORM });

export const TOGGLE_SHOW_PRODUCT_OFFERING = `${FUTURE_VALUE_OF_SAVINGS_SET_VALUE}/toggle/showProductOffering`;
export const toggleViewRecommendation = () => ({ type: TOGGLE_SHOW_PRODUCT_OFFERING });
