import { ACTIVE_PAGE_INDEX_SET_VALUE, RESET_ROUTING } from "../../actions/raOptimal/routing";

export const initRouting = {
    activePageIndex: 0
};

export default (state = initRouting, action) => {
    switch (action.type) {
        // Routing
        case ACTIVE_PAGE_INDEX_SET_VALUE:
            return {...state, activePageIndex: action.value};

        case RESET_ROUTING:
            return { ...initRouting };

        default:
            return state;
    }
};
