import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    NUMBER_OF_EMPLOYEES_SET_VALUE,
    NUMBER_OF_EMPLOYEES_SET_VALID,
    AVERAGE_AGE_OF_EMPLOYEES_SET_VALUE,
    AVERAGE_AGE_OF_EMPLOYEES_SET_VALID,
    TOTAL_SUM_ASSURED_SET_VALUE,
    TOTAL_SUM_ASSURED_SET_VALID,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    COLLECT_GROUPLIFE_DATA,
    CALCULATE_GROUPLIFE_DATA,
    API_PENDING_TRANSITION_GROUPLIFE_QUOTE_TOOL,
    API_FAILURE_TRANSITION_GROUPLIFE_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_GROUPLIFE_QUOTE_TOOL,
    FORM_VALID_GROUPLIFE_QUOTE_TOOL,
    FORM_INVALID_GROUPLIFE_QUOTE_TOOL,
    SET_COVER_VALUE,
    SET_STEP,
    RESET_GROUPLIFE_DATA,
    SET_RANGE_VALUE,
    REMOVE_SECTION,
} from '../../actions/roaQuoteTools/groupLifeQuoteTool';

const initialState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    groupLifeValue: null,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_email',
        product: 'Group Life Cover',
        email: "customerservice@oldmutual.com.gh",
        campaign_code: 'NEW GH EMAIL CMB',
        privacy_policy_url: '/privacy-policy/',
        extra_fields: ['Email Address', 'Location'],
    },
    form: {
        numberOfEmployees: { value: '', error: null, status: null },
        averageAgeOfEmployees: { value: '', error: null, status: null },
        totalSumAssured: { value: '', error: null, status: null },
        coverRanges: [{ code: null, coverMultiple: null }],
    },
};

export default (state = initialState, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);

    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_GROUPLIFE_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_GROUPLIFE_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value,
            };
        case NUMBER_OF_EMPLOYEES_SET_VALUE:
            return updateFormField('numberOfEmployees', field => ({
                ...field,
                value: action.value,
            }));
        case NUMBER_OF_EMPLOYEES_SET_VALID:
            return updateFormField('numberOfEmployees', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case AVERAGE_AGE_OF_EMPLOYEES_SET_VALUE:
            return updateFormField('averageAgeOfEmployees', field => ({
                ...field,
                value: action.value,
            }));
        case AVERAGE_AGE_OF_EMPLOYEES_SET_VALID:
            return updateFormField('averageAgeOfEmployees', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case TOTAL_SUM_ASSURED_SET_VALUE:
            return updateFormField('totalSumAssured', field => ({
                ...field,
                value: action.value,
            }));
        case TOTAL_SUM_ASSURED_SET_VALID:
            return updateFormField('totalSumAssured', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case API_PENDING_TRANSITION_GROUPLIFE_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_GROUPLIFE_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_GROUPLIFE_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        case REINITIALIZE:
            return initialState;
        case RESET_GROUPLIFE_DATA:
            return {
                ...state,
                groupLifeValue: null,
            };
        case CALCULATE_GROUPLIFE_DATA:
            return {
                ...state,
                groupLifeValue: action.value ? action.value.Entity : null,
            };
        case COLLECT_GROUPLIFE_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case SET_COVER_VALUE: {
            const coverRanges = state.form.coverRanges.map(
                (coverRange, index) => {
                    if (index === action.index) {
                        return Object.assign({}, coverRange, {
                            code: action.value,
                        });
                    }
                    return coverRange;
                },
            );
            return {
                ...state,
                form: {
                    ...state.form,
                    coverRanges: [...coverRanges],
                },
            };
        }
        case SET_RANGE_VALUE: {
            const coverRanges = state.form.coverRanges.map(coverRange => {
                if (coverRange.code === action.code) {
                    return Object.assign({}, coverRange, {
                        coverMultiple: action.value,
                    });
                }
                return coverRange;
            });
            return {
                ...state,
                form: {
                    ...state.form,
                    coverRanges: [...coverRanges],
                },
            };
        }
        case SET_STEP: {
            return {
                ...state,
                form: {
                    ...state.form,
                    coverRanges: [
                        ...state.form.coverRanges,
                        ...initialState.form.coverRanges,
                    ],
                },
            };
        }
        case REMOVE_SECTION: {
            return {
                ...state,
                form: {
                    ...state.form,
                    coverRanges: [
                        ...state.form.coverRanges.filter(
                            coverRange => coverRange.code !== action.value,
                        ),
                    ],
                },
            };
        }
        default:
            return state;
    }
};
