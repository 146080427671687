import {
    MAKE_A_CLAIM_SEND_EMAIL
} from "../../../actions/makeAClaim";
import {
    sendMailMakeAClaim
} from "./makeAClaim";

export const makeAClaimServices = (store, next, action) => {
    if (action.type === MAKE_A_CLAIM_SEND_EMAIL) {
        return sendMailMakeAClaim(store, next, action);
    }

    return null;
};