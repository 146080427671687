import {
    FORM_INVALID_MEDICAL_HISTORY_FORM,
    FORM_VALID_MEDICAL_HISTORY_FORM,
    ID_PASSPORT_SET_VALID,
    ID_PASSPORT_SET_VALUE,
    MEDICAL_HISTORY_EDIT_SET_VALUE,
    MEDICAL_HISTORY_NEW_SET_VALUE,
    MEDICAL_HISTORY_SET_VALUE,
    MEDICAL_HISTORY_ARRAY_SET_VALUE,
    NAMES_SET_VALID,
    NAMES_SET_VALUE,
    REINITIALIZE,
    RELATIONSHIP_SET_VALID,
    RELATIONSHIP_SET_VALUE,
    OTHER_SET_VALUE,
    OTHER_SET_VALID,
} from '../../actions/healthInsuranceRW/medicalHistory';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initMedicalHistory = {
    medicalHistory: [],
    formValid: 'invalid',
    form: {
        relationship: { value: null, error: null, status: null },
        names: { value: null, error: null, status: null },
        idPassport: { value: null, error: null, status: null },
        other: { value: null, error: null, status: null }
    },
};

export default (state = initMedicalHistory, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case MEDICAL_HISTORY_SET_VALUE:
            return { ...state, medicalHistory: [...state.medicalHistory, action.value] };

        case MEDICAL_HISTORY_ARRAY_SET_VALUE:
            return { ...state, medicalHistory: action.value };
        case FORM_VALID_MEDICAL_HISTORY_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_MEDICAL_HISTORY_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case RELATIONSHIP_SET_VALUE:
            return updateFormField('relationship', (field) => ({
                ...field,
                value: action.value,
            }));

        case RELATIONSHIP_SET_VALID:
            return updateFormField('relationship', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case NAMES_SET_VALUE:
            return updateFormField('names', (field) => ({
                ...field,
                value: action.value,
            }));
        case NAMES_SET_VALID:
            return updateFormField('names', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case OTHER_SET_VALUE:
            return updateFormField('other', (field) => ({
                ...field,
                value: action.value,
            }));
        case OTHER_SET_VALID:
            return updateFormField('other', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case ID_PASSPORT_SET_VALUE:
            return updateFormField('idPassport', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_PASSPORT_SET_VALID:
            return updateFormField('idPassport', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MEDICAL_HISTORY_EDIT_SET_VALUE:
            return { ...state, form: action.value };

        case MEDICAL_HISTORY_NEW_SET_VALUE:
            return { ...state, medicalHistory: action.value };

        case REINITIALIZE:
            return { ...state, form: initMedicalHistory.form };
        default:
            return state;
    }
};
