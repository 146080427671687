import { createSelector } from 'reselect';
import { getBiasharaFlexiState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getBiasharaFlexiCoverDetails = createSelector(
    getBiasharaFlexiState,
    state => state.coverDetails,
);

const makeGetBisharaFlexiCoverDetailsField = fieldName =>
    createSelector(getBiasharaFlexiCoverDetails, coverDetails => coverDetails[fieldName]);

export const getBiasharaFlexiCoverDetailsForm = makeGetBisharaFlexiCoverDetailsField('form');


// export const getBiasharaFlexiCoverDetailsForm = createSelector(
//     getBiasharaFlexiCoverDetails,
//     biasharaFlexiCoverDetails => biasharaFlexiCoverDetails.form,
// );

export const getDropOffDetails = createSelector(
    getBiasharaFlexiCoverDetails,
    biasharaFlexiCoverDetails => biasharaFlexiCoverDetails.dropOffDetails,
);

export const getBiasharaFlexiCoverFormValid = createSelector(
    getBiasharaFlexiCoverDetails,
    biasharaFlexiCoverDetails => biasharaFlexiCoverDetails.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getBiasharaFlexiCoverDetails,
    biasharaFlexiCoverDetails => biasharaFlexiCoverDetails.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getIsApiSuccess = createSelector(
    getBiasharaFlexiCoverDetails,
    biasharaFlexiCoverDetails => biasharaFlexiCoverDetails.apiCallStatus === API_CALL_STATUS.SUCCESS,
);

export const getShareModalValue = createSelector(getBiasharaFlexiCoverDetails, (quote) => quote.showShareModal);
export const getShareQuoteAPIStatus = createSelector(getBiasharaFlexiCoverDetails, (quote) => quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getBiasharaFlexiCoverDetails, (quote) => quote.shareQuoteFormStatus);
export const getShareQuoteData = createSelector(getBiasharaFlexiCoverDetails, (quote) => quote.quoteShare);

// export const getApiStatus = createSelector(
//     getBiasharaFlexiCoverDetails,
//     biasharaFlexiCoverDetails => biasharaFlexiCoverDetails.apiCallStatus
// )

export const getKeCmbObject = createSelector(getBiasharaFlexiCoverDetails, (quote) => quote && quote.keCmbObject);

export const getBiasharaFlexiCoverDetailsReferenceData = createSelector(getBiasharaFlexiCoverDetails, (biasharaFlexiCoverDetails) => biasharaFlexiCoverDetails.biasharaFlexiCoverDetailsReferenceData);
export const getAddToCover = createSelector(getBiasharaFlexiCoverDetails, (biasharaFlexiCoverDetails) => biasharaFlexiCoverDetails.addedToCover);

const makeGetFieldForm = (fieldId) => createSelector(getBiasharaFlexiCoverDetailsForm, (form) => form[fieldId]);

export const getEmployeesNumberValue = makeGetFieldForm('employeesNumber');
export const getBenefitAmountValue = makeGetFieldForm('benefitAmount');
export const getShareEmailValue = makeGetFieldForm('shareEmail');
export const getIsPayNow = createSelector(getBiasharaFlexiCoverDetails, (quote) => quote && quote.isPayNow);
