import { PAGE_INDICES } from '../../actions/roaTravelInsurance/types';
import {
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
    NAVIGATE_TO_TRAVEL_PLAN,
    NAVIGATE_TO_ABOUT_YOU,
    NAVIGATE_TO_TRAVEL_DETAILS,
    NAVIGATE_TO_PLANS,
    NAVIGATE_TO_PAYMENT,
    SET_LANDING_PAGE,
    NAVIGATE_TO_LANDING_PAGE,
    TRAVELL_INSURANCE_SWICTH_PLAN,
} from '../../actions/roaTravelInsurance/routing';

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    landingPage: null,
};

export default (state = initRouting, action) => {
    const setActivePage = pageIndex => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };

        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };
        case NAVIGATE_TO_TRAVEL_PLAN:
            return setActivePage(PAGE_INDICES.TRAVEL_PLAN);
        case NAVIGATE_TO_ABOUT_YOU:
            return setActivePage(PAGE_INDICES.ABOUT_YOU);
        case NAVIGATE_TO_TRAVEL_DETAILS:
            return setActivePage(PAGE_INDICES.TRAVEL_DETAILS);
        case NAVIGATE_TO_PLANS:
            return setActivePage(PAGE_INDICES.PLANS);
        case NAVIGATE_TO_PAYMENT:
            return setActivePage(PAGE_INDICES.PAYMENT);
        case SET_LANDING_PAGE:
            return { ...state, landingPage: action.value };
        case NAVIGATE_TO_LANDING_PAGE:
            return setActivePage(PAGE_INDICES.LANDING_PAGE);
        case TRAVELL_INSURANCE_SWICTH_PLAN:
             return setActivePage(PAGE_INDICES.PLANS);
        default:
            return state;
    }
};
