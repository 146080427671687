export const HEALTH_INSURANCE_DOCUMENTS_FORM = 'healthInsurance/documentsForm/';

export const HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE = HEALTH_INSURANCE_DOCUMENTS_FORM + 'setValue/';
export const HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALID = HEALTH_INSURANCE_DOCUMENTS_FORM + 'setValid/';

export const FORM_VALID_HEALTH_INSURANCE_DOCUMENTS_FORM = 'healthInsurance/documentsForm/transition/formValid';
export const FORM_INVALID_HEALTH_INSURANCE_DOCUMENTS_FORM = 'healthInsurance/documentsForm/transition/formInValid';

export const REINITIALIZE = 'healthInsurance/documentsForm/state/reinitialize';


// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM = 'healthInsurance/documentsForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
	type: DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM,
	value,
});

export const transitionHealthInsuranceDocumentsForm = {
	formValid: () => ({
		type: FORM_VALID_HEALTH_INSURANCE_DOCUMENTS_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_HEALTH_INSURANCE_DOCUMENTS_FORM,
	}),
};

// API Calls actions
export const API_PENDING_TRANSITION_DOCUMENTS_FORM = `${HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DOCUMENTS_FORM = `${HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DOCUMENTS_FORM = `${HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDocumentsForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_DOCUMENTS_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_DOCUMENTS_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_DOCUMENTS_FORM,
	}),
};

//calculated Data
export const COLLECT_DOCUMENTS_FORM_DATA = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DOCUMENTS_SUCCESS = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'success';
export const SUBMIT_DOCUMENTS_FAILURE = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
	type: COLLECT_DOCUMENTS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
	type: SUBMIT_DOCUMENTS_SUCCESS,
	value,
});
export const submitDocumentsFailure = (error) => ({
	type: SUBMIT_DOCUMENTS_FAILURE,
	error,
});

// Form Values
export const ID_UPLOAD_SET_VALUE = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'idUpload';
export const ID_UPLOAD_SET_VALID = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'idUpload';
export const MARRIAGE_CERTIFICATE_SET_VALUE = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'marriageCertificate';
export const MARRIAGE_CERTIFICATE_SET_VALID = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'marriageCertificate';
export const BIRTH_CERTIFICATE_SET_VALUE = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'birthCertificate';
export const BIRTH_CERTIFICATE_SET_VALID = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'birthCertificate';

// reset individual form value
export const ID_UPLOAD_RESET_VALUE = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/idUpload';
export const MARRIAGE_CERTIFICATE_RESET_VALUE = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/marriageCertificate';
export const BIRTH_CERTIFICATE_RESET_VALUE = HEALTH_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/birthCertificate';



// ID upload action creators
export const setIdUploadValue = (value) => ({
	type: ID_UPLOAD_SET_VALUE,
	value,
});

export const setIdUploadValid = (error) => ({
	type: ID_UPLOAD_SET_VALID,
	error,
});

export const resetIdUploadValue = () => ({
	type: ID_UPLOAD_RESET_VALUE,
});



export const setMarriageCertificateValue = (value) => ({
	type: MARRIAGE_CERTIFICATE_SET_VALUE,
	value,
});

export const setMarriageCertificateValid = (error) => ({
	type: MARRIAGE_CERTIFICATE_SET_VALID,
	error,
});

export const resetMarriageCertificateValue = () => ({
	type: MARRIAGE_CERTIFICATE_RESET_VALUE,
});


export const setBirthCertificateValue = (value) => ({
	type: BIRTH_CERTIFICATE_SET_VALUE,
	value,
});

export const setBirthCertificateValid = (error) => ({
	type: BIRTH_CERTIFICATE_SET_VALID,
	error,
});

export const resetBirthCertificateValue = () => ({
	type: BIRTH_CERTIFICATE_RESET_VALUE,
});
