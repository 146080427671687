export const APP_URL_PREFIX = '/digital-savings';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QOUTE: 0,
	PERSONAL_DETAILS: 1,
	BENEFICIARIES: 2,
	UPLOAD_DOCUMENTS: 3,
	PAYMENT: 4,
};

export const PAGES = [
	{
		URL: `${APP_URL_PREFIX}/get-quote`,
		URL_PART: 'get-quote',
		TITLE: 'Get Quote',
	},
	{
		URL: `${APP_URL_PREFIX}/personal-details`,
		URL_PART: 'personal-details',
		TITLE: 'Personal Details',
	},
	{
		URL: `${APP_URL_PREFIX}/beneficiaries`,
		URL_PART: 'beneficiaries',
		TITLE: 'Beneficiaries',
	},
	{
		URL: `${APP_URL_PREFIX}/upload-documents`,
		URL_PART: 'upload-documents',
		TITLE: 'Upload Documents',
	},
	{
		URL: `${APP_URL_PREFIX}/payment`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	},
];

export const SAVING_PLANS = {
		savePlan1: { title: 'Regular', value: 'monthly' },
		savePlan2: { title: 'One Off', value: 'once-off' },
		savePlan3: { title: 'Combination of both', value: 'combination' },
};


export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const DIGITAL_SAVINGS_URLS = {
	QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
	PERSONAL_DETAILS: PAGES[PAGE_INDICES.PERSONAL_DETAILS].URL,
	BENEFICIARIES_DETAILS: PAGES[PAGE_INDICES.BENEFICIARIES].URL,
	UPLOAD_DOCUMENTS: PAGES[PAGE_INDICES.UPLOAD_DOCUMENTS].URL,
	PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL
};
