export const TRAVEL_INSURANCE_DETAILS_FORM = 'travelInsuranceke/travelDetailsForm/';

export const TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE = TRAVEL_INSURANCE_DETAILS_FORM + 'setValue/';
export const TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID = TRAVEL_INSURANCE_DETAILS_FORM + 'setValid/';

export const FORM_VALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM = 'travelInsuranceke/travelDetailsForm/transition/formValid';
export const FORM_INVALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM = 'travelInsuranceke/travelDetailsForm/transition/formInValid';

export const REINITIALIZE = 'travelInsurance/travelDetailsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_TRAVEL_DETAILS_FORM = 'travelInsurance/travelDetailsForm/transition/dropOffLead';
export const dropOffLeadPersonalDetails = (value) => ({
	type: DROP_OFF_LEAD_TRANSITION_TRAVEL_DETAILS_FORM,
	value,
});

export const transitionTravelInsuranceTravelDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_TRAVEL_DETAILS_FORM = `${TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_TRAVEL_DETAILS_FORM = `${TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_TRAVEL_DETAILS_FORM = `${TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionPersonalDetailsForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_TRAVEL_DETAILS_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_TRAVEL_DETAILS_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_TRAVEL_DETAILS_FORM,
	}),
};

//calculated Data
export const COLLECT_TRAVEL_DETAILS_FORM_DATA =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'collectPersonalDetailsData';
export const SUBMIT_TRAVEL_DETAILS_SUCCESS =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'success';
export const SUBMIT_TRAVEL_DETAILS_FAILURE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'failure';

export const collectPersonalDetailsData = () => ({
    type: COLLECT_TRAVEL_DETAILS_FORM_DATA,
});
export const submitPersonalDetailsDataSuccess = (value) => ({
	type: SUBMIT_TRAVEL_DETAILS_SUCCESS,
	value,
});
export const submitPersonalDetailsFailure = (error) => ({
	type: SUBMIT_TRAVEL_DETAILS_FAILURE,
	error,
});

// Form Values
export const TITLE_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'title';
export const TITLE_SET_VALID =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'title';

export const FULL_NAME_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'fullName';
export const FULL_NAME_SET_VALID = TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'fullName';

export const DOB_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'DOB';

export const ID_TYPE_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'idType';
export const ID_TYPE_SET_VALID =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'idType';

export const ID_NUMBER_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'idNumber';

export const KRA_NUMBER_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'kraNumber';
export const KRA_NUMBER_SET_VALID =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'kraNumber';

export const PHONE_NUMBER_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'phoneNumber';

export const EMAIL_SET_VALUE =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID =TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALID + 'email';

export const SET_TRAVELLERS_ID_VALUE = TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM_SET_VALUE + 'travellersId';

export const TRAVELLERS_SET_VALUE = TRAVEL_INSURANCE_DETAILS_FORM + 'travellers';

export const TRAVELLER_SET_VALUE = TRAVEL_INSURANCE_DETAILS_FORM + 'traveller';
export const UPDATE_TRAVELLER_VALUE = TRAVEL_INSURANCE_DETAILS_FORM + 'updateTraveller';

export const SET_CURRENT_TRAVELLER_INDEX = TRAVEL_INSURANCE_DETAILS_FORM + 'currentTraveller';


// action creators

export const setTitleValue = value => ({
    type: TITLE_SET_VALUE,
    value,
})

export const setTitleValid = error => ({
    type: TITLE_SET_VALID,
    error,
})

export const setFullNameValue = (value) => ({
    type: FULL_NAME_SET_VALUE,
    value,
});

export const setFullNameValid = (error) => ({
    type: FULL_NAME_SET_VALID,
    error,
});



export const setIdNumberValue = value => ({
    type: ID_NUMBER_SET_VALUE,
    value,
})

export const setIdNumberValid = error => ({
    type: ID_NUMBER_SET_VALID,
    error,
})

export const setKraNumberValue = value => ({
    type: KRA_NUMBER_SET_VALUE,
    value,
})

export const setKraNumberValid = error => ({
    type: KRA_NUMBER_SET_VALID,
    error,
})




export const setPhoneNumberValue = value => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = error => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setEmailValue = value => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = error => ({
    type: EMAIL_SET_VALID,
    error,
});

export const setDOBValue = value => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDOBValid = error => ({
    type: DOB_SET_VALID,
    error,
});

export const setTravellersValue = (value) => ({
    type: TRAVELLERS_SET_VALUE,
    value,
});

export const setTravellerValue = (value) => ({
    type: TRAVELLER_SET_VALUE,
    value,
});

export const updateTravellerValue = (value, id) => ({
    type: UPDATE_TRAVELLER_VALUE,
    value,
    id
});

export const setIdTypeValue = (value) => ({
    type: ID_TYPE_SET_VALUE,
    value
})

export const setIdTypeValid = (error) => ({
    type: ID_TYPE_SET_VALID,
    error,
});

export const setCurrentTraller = (value) => ({
    type: SET_CURRENT_TRAVELLER_INDEX,
    value
});

export const setTravellerIdValue = (value) => ({
    type: SET_TRAVELLERS_ID_VALUE,
    value,
});
