import { createSelector } from 'reselect';
import { getRoaTravelInsuranceState } from './index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
    FIELD_STATUS,
} from '../../reducers/status';

export const getTravelDetails = createSelector(
    getRoaTravelInsuranceState,
    state => state.travelDetails,
);

const makeGetTravelInsuranceField = fieldName =>
    createSelector(getTravelDetails, travelDetails => travelDetails[fieldName]);

export const getIsApiPending = createSelector(
    getTravelDetails,
    travelDetails => travelDetails.apiCallStatus === API_CALL_STATUS.PENDING,
);

//get selected form
export const getSelectedForm = makeGetTravelInsuranceField('selectedForm');

// get forms
export const getTravelDetailsForm = makeGetTravelInsuranceField('form');

//get travel Plans reference data
export const getTravelPlansReferenceData = makeGetTravelInsuranceField(
    'plansReferenceData',
);

//get error
export const getError = createSelector(
    getTravelPlansReferenceData,
    referenceData => {
        if (referenceData && referenceData.error) return referenceData.error;
        return null;
    },
);

export const getDisplayError = createSelector(
    getTravelPlansReferenceData,
    referenceData => {
        if (
            referenceData &&
            referenceData.error &&
            referenceData.error.message.length
        )
            return true;
        return false;
    },
);

//get products
export const getProducts = createSelector(
    getTravelPlansReferenceData,
    referenceData => referenceData?.products,
);

// get forms status
const makeGetFormValid = formStatus =>
    createSelector(
        getTravelDetails,
        travelDetails => travelDetails[formStatus] === FORM_STATUS.VALID,
    );

export const getTravelDetailsFormValid = makeGetFormValid('formStatus');

export const getPeopleTravelingFormValid = createSelector(
    getTravelDetailsForm,
    form => {
        return (
            (form.adults &&
            form.adults.status === FIELD_STATUS.VALID)
            // form.kids &&
            // form.kids.status === FIELD_STATUS.VALID &&
            // (form.seniors &&
            // form.seniors.status === FIELD_STATUS.VALID)
        );
    },
);

// get form fields
const makeGetFieldForm = (getForm, fieldId) =>
    createSelector(getForm, form => form[fieldId]);
export const getAdultsValue = makeGetFieldForm(getTravelDetailsForm, 'adults');
export const getKidsValue = makeGetFieldForm(getTravelDetailsForm, 'kids');
export const getSeniorsValue = makeGetFieldForm(
    getTravelDetailsForm,
    'seniors',
);

export const getFromValue = makeGetFieldForm(getTravelDetailsForm, 'from');
export const getToValue = makeGetFieldForm(getTravelDetailsForm, 'to');
export const getDepartureDateValue = makeGetFieldForm(
    getTravelDetailsForm,
    'departureDate',
);
export const getReturnDateValue = makeGetFieldForm(
    getTravelDetailsForm,
    'returnDate',
);
export const getReasonForTravelValue = makeGetFieldForm(
    getTravelDetailsForm,
    'reasonForTravel',
);
