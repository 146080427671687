import {
    EDUCATION_CALCULATOR_MODAL_SET_VALID,
    EDUCATION_CALCULATOR_MODAL_SET_VALUE,
    EDUCATION_CALCULATOR_SET_VALID,
    EDUCATION_CALCULATOR_SET_VALUE,
} from '../../../actions/educationCalculator/calculator';
import { educationCalculatorFormRules } from './educationCalculatorForm';

export const educationCalculatorRules = (store, next, action) => {
    if (
        action.type.startsWith(EDUCATION_CALCULATOR_SET_VALUE) ||
        action.type.startsWith(EDUCATION_CALCULATOR_SET_VALID) ||
        action.type.startsWith(EDUCATION_CALCULATOR_MODAL_SET_VALUE) ||
        action.type.startsWith(EDUCATION_CALCULATOR_MODAL_SET_VALID)
    )
        return educationCalculatorFormRules(store, next, action);
};
