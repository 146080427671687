import {
    getToEmailAddress,
    getName,
    getSurname,
    getBusinessName,
    getPhoneNumber,
    getBusinessNumber,
    getEmailAddress,
    getBusinessRegistered,
    getNumberOfEmployees,
    getBusinessDuration,
    getBusinessManagement,
    getBusinessRegisteredAs,
    getHasEmployeeRetirement,
} from '../../../selectors/publicWeb/save2gether';

import {
    SAVE2GETHER_SEND_EMAIL,
    submissionComplete,
} from "../../../actions/publicWeb/save2gether";

import axios from "axios";

export const sendToEmail = async store => {
    const state = store.getState();

    const bodyParams = {
        'type': 'save2gether',
        'toEmailAdrress': getToEmailAddress(state),
        'firstName': getName(state),
        'surname': getSurname(state),
        'businessName': getBusinessName(state),
        'phoneNumber': getPhoneNumber(state),
        'businessNumber': getBusinessNumber(state),
        'emailAddress': getEmailAddress(state),
        'businessRegistered': getBusinessRegistered(state),
        'numberOfEmployees': getNumberOfEmployees(state),
        'businessDuration': getBusinessDuration(state),
        'businessManagement': getBusinessManagement(state),
        'businessRegisteredAs': getBusinessRegisteredAs(state),
        'hasEmployeeRetirement': getHasEmployeeRetirement(state),
    };

    try {
        await axios.post('/om-api/save2gether/save2gether-mail', bodyParams);
        store.dispatch(submissionComplete("Successful"));
        return true;
    }  catch(e) {
        store.dispatch(submissionComplete("Unsuccessful"));
        return false;
    }
};

export const save2getherService = (store, next, action) => {
    const result = next(action);

    (async () => {
        if (action.type === SAVE2GETHER_SEND_EMAIL) {
            sendToEmail(store);
        }
    })();

    return result;
};