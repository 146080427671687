import {
    FIELD_STATUS,
    FORM_STATUS,
} from "../status";
import {
    EVEN_SPLIT_DESELECT_FUND_SPLIT,
    EVEN_SPLIT_DISCARD_FUND_SPLIT,
    EVEN_SPLIT_SELECT_FUND_SPLIT,
    FORM_INIT_TRANSITION_FUND_SPLIT,
    SPLIT_PERCENTAGE_SET_VALID,
    SPLIT_PERCENTAGE_SET_VALUE,
    RESET_FUND_SPLIT
} from "../../actions/taxFreeSavingsAccount/fundSplit";
import {initFundSplitList, setAllPercentages, splitPercentagesEvenly} from "../../functions/percentageSplit";
import {FUND_SPLIT_FORM, FUND_SPLIT_TYPE} from "../../actions/taxFreeSavingsAccount/types";

const initFormMeta = () => ({
    formStatus: FORM_STATUS.INVALID,
    isEvenSplit: false,
    splitError: null
});

export const initFundSplit = {
    [FUND_SPLIT_FORM.REGULAR]: [],
    [FUND_SPLIT_FORM.REGULAR_META]: initFormMeta(),

    [FUND_SPLIT_FORM.LUMP_SUM]: [],
    [FUND_SPLIT_FORM.LUMP_SUM_META]: initFormMeta(),
};

export default (state = initFundSplit, action) => {
    let formName, metaName;
    if (action.fundType) { 
        formName = action.fundType === FUND_SPLIT_TYPE.REGULAR
            ? FUND_SPLIT_FORM.REGULAR
            : FUND_SPLIT_FORM.LUMP_SUM;
        metaName = action.fundType === FUND_SPLIT_TYPE.REGULAR
            ? FUND_SPLIT_FORM.REGULAR_META
            : FUND_SPLIT_FORM.LUMP_SUM_META;
    }

    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (formName, fieldId, update) => {
        return {
            ...state,
            [formName]: state[formName].map((entry) => {
                if (entry.id === fieldId){
                    return update(entry);
                }
                return entry;
            }),
        }
    };

    switch (action.type) {
        // This reducer and initFundSplitList function
        // sets the values of funds that come from advanced fund picker
        case FORM_INIT_TRANSITION_FUND_SPLIT:
            const splitRegular = initFundSplitList({
                splitIds: action.funds,
                splitObject: state[FUND_SPLIT_FORM.REGULAR],
                evenSplit: action.isEvenSplitRegular,
            });

            const splitLumpSum = initFundSplitList({
                splitIds: action.funds,
                splitObject: state[FUND_SPLIT_FORM.LUMP_SUM],
                evenSplit: action.isEventSplitLumpSum
            });

            return {
                ...state,
                [FUND_SPLIT_FORM.REGULAR]: splitRegular,
                [FUND_SPLIT_FORM.LUMP_SUM]: splitLumpSum,
            };

        case EVEN_SPLIT_SELECT_FUND_SPLIT:
            return {
                ...state,
                [metaName]: {
                    isEvenSplit: true,
                    splitError: null,
                    formStatus: FORM_STATUS.VALID,
                },
                [formName]: splitPercentagesEvenly({splitList: state[formName]}),
            };

        case EVEN_SPLIT_DESELECT_FUND_SPLIT:
            return {
                ...state,
                [metaName]: {
                    ...state[metaName],
                    isEvenSplit: false,
                }
            };

        case EVEN_SPLIT_DISCARD_FUND_SPLIT:
            return {
                ...state,
                [metaName]: initFormMeta(),
                [formName]: setAllPercentages({splitList: state[formName], newValue: null})
           };     

        case SPLIT_PERCENTAGE_SET_VALUE:
            return updateFormField(action.fundType, action.fundId, field => ({
                ...field,
                value: action.value
            }));

        case SPLIT_PERCENTAGE_SET_VALID:
            return updateFormField(action.fundType, action.fundId, field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RESET_FUND_SPLIT:
            return { ...initFundSplit };

        default:
            return state;
    }
};
