import { SET_VALUE_ROUTING } from '../../../actions/ugMotorInsurance/routing';
import { routingRules } from './routing';
import {
    MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE,
    MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID,
} from '../../../actions/ugMotorInsurance/personalDetails';
import { personalDetailsFormRules } from './personalDetails';

import {
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE,
    MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID,
} from '../../../actions/ugMotorInsurance/employmentDetails';
import { employmentDetailsFormRules } from './employmentDetails';

import {
    MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALID,
    MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE,
} from '../../../actions/ugMotorInsurance/nextOfKin';
import { nextOfKinFormRules } from './nextOfKin';
import {
    MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID,
    MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE,
} from '../../../actions/ugMotorInsurance/Documents';
import { documenetsFormRules } from './documents';

import {
    MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE,
    MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID,
} from '../../../actions/ugMotorInsurance/carDetails';
import { carDetailsFormRules } from './carDetails';

import {
    MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE,
    MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID,
} from '../../../actions/ugMotorInsurance/motorAgentModal';
import { agentModaleFormRules } from './agentModal';

import {
    UG_MOTOR_QOUTE_FORM_SET_VALUE,
    UG_MOTOR_QOUTE_FORM_SET_VALID,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
} from '../../../actions/ugMotorInsurance/quote';
import { quoteFormRules } from './quote';
import { shareQuoteFormRules } from './shareQuote';

import {
    MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID,
    MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE,
} from '../../../actions/ugMotorInsurance/declarations';
import { declarationsFormRules } from './declarations';
import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from '../../../actions/ugMotorInsurance/payments';
import { paymentsFormRules } from './payments';
export const ugMotorInsuranceRules = (store, next, action) => {
    if (action.type.startsWith(SET_VALUE_ROUTING)) {
        return routingRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID)
    ) {
        return documenetsFormRules(store, next, action);
    }

    if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
        return paymentsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_NEXT_OF_KIN_FORM_SET_VALID)
    ) {
        return nextOfKinFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_DECLARATIONS_FORM_SET_VALID)
    ) {
        return declarationsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_PERSONAL_DETAILS_FORM_SET_VALID)
    ) {
        return personalDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_EMPLOYMENT_DETAILS_FORM_SET_VALID)
    ) {
        return employmentDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID)
    ) {
        return carDetailsFormRules(store, next, action);
    }

    if (
        action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE) ||
        action.type.startsWith(MOTOR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID)
    ) {
        return agentModaleFormRules(store, next, action);
    }

    if (
        action.type.startsWith(UG_MOTOR_QOUTE_FORM_SET_VALUE) ||
        action.type.startsWith(UG_MOTOR_QOUTE_FORM_SET_VALID)
    ) {
        return quoteFormRules(store, next, action);
    }

    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
        return shareQuoteFormRules(store, next, action);
    }

    return null;
};
