import {
    REWARDS_SOLUTIONS_SET_VALUE
} from "../../actions/atRetirement/rewardsSolutionsTable";

export const initRewardsSolutionsTable = {
    considerationAmount: null,
    tier1: null,
    tier2: null,
    tier3: null,
    tier4: null,
    tier5: null,
};

export default (state = initRewardsSolutionsTable, action) => {

    const maxConsideration = 5000000;

    switch(action.type){

        case REWARDS_SOLUTIONS_SET_VALUE:

            const parsedValue = parseInt(action.value.split(' ').join(''));
            // consideration value cant be larger than the maximum amount
            const considerationValue = parsedValue > 0 && parsedValue < maxConsideration 
            ? parsedValue 
            : maxConsideration;

            return {
                ...state,
                considerationAmount: considerationValue,
                tier1: Math.round(considerationValue * 0.005),
                tier2: Math.round(considerationValue * 0.015),
                tier3: Math.round(considerationValue * 0.025),
                tier4: Math.round(considerationValue * 0.035),
                tier5: Math.round(considerationValue * 0.05),
            };

        default:
            return state;

    }
}



