import { getIsApiPending, getQuoteForm } from '../../../selectors/personalPensionPlan/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure,
} from '../../../actions/personalPensionPlan/quote';
import { getAccessToken } from '../../../selectors/personalPensionPlan/payments';
import axios from 'axios';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const getRetirementAgeDifference = (dob, retirementAge) => {
    // Function to parse the date in DD/MM/YYYY format
    const parseDate = (dateString) => {
        const parts = dateString.split('/');
        if (parts.length !== 3) {
            return null;
        }
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    };

    // Parse the date of birth
    const birthDate = parseDate(dob);
    if (!birthDate || isNaN(birthDate.getTime())) {
        return NaN;
    }

    if (typeof retirementAge !== 'number' || isNaN(retirementAge)) {
        return NaN;
    }

    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    const ageDifference = Math.abs(age - retirementAge);

    return ageDifference;
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);

    const retirementYears = getRetirementAgeDifference(
        quotation.dob.value,
        parseInt(quotation.desiredRetirementAge.value)
    );

    const payload = {
        emailAddress: quotation.shareEmail.value,
        monthlyContribution:
            quotation.contributionMode?.value !== 'lumpsum-only'
                ? parseInt(quotation.desiredMonthlyContribution?.value) || 0
                : 0,
        retirementYears,
        annualInterest: quotation.interestRate?.value ? parseInt(quotation.interestRate?.value) : 0,
        annualGrowthRate: 0,
        lumpSumValue:
            quotation.contributionMode?.value !== 'monthly-contribution-only'
                ? parseInt(quotation.lumpsumAmount?.value) || 0
                : 0,
        countryCode: gatsbyStack.toUpperCase(),
        compoundingFrequency: quotation.frequencyOfContribution?.value || 1,
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);

    try {
        const url = '/om-api/personal-pension-plan/ke/share-quote';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
