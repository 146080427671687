import {SPEAK_TO_ADVISOR_SEND, FIND_OUT_MORE_SEND} from "../../../actions/atRetirement/financialAdvice";
import {SPEAK_TO_AN_ADVISER_SEND} from "../../../actions/atRetirement/speakToAnAdviser";
import {financialAdviceService} from "./financialAdvice";
import {speakToAnAdviserService} from "./speakToAnAdviser";

export const atRetirementServices = (store, next, action) => {    
    if (action.type === SPEAK_TO_ADVISOR_SEND ||
        action.type === FIND_OUT_MORE_SEND)
        return financialAdviceService(store, next, action);

    if (action.type === SPEAK_TO_AN_ADVISER_SEND)
        return speakToAnAdviserService(store, next, action);

    return null;

}