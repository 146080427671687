import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

export function validatePrivateBag(
    value,
    {
        min = config.PRIVATE_BAG.MIN,
        max = config.PRIVATE_BAG.MAX,
        subject = 'Private bag number',
        required = true,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {},
    isPostal,
) {

    // Checks for word chars, spaces, and allowed special characters.
    const regex = new RegExp(/^([\w]*)$/);
    const invalidMsg = OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);

    if (!value && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    if (!isPostal && (value.toLowerCase().includes('po box') ||
        value.toLowerCase().includes('posbus') ||
        value.toLowerCase().includes('private bag'))) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    if (!value.replace(/\s/g, '').length) {
        return invalidMsg;
    }

    if (RegExp(/^[\d\s]+$/).test(value)) {
        return invalidMsg;
    }

    if (!regex.test(value)) {
        return invalidMsg;
    }

    if (value.trim().length < min ) {
        return OmValidationMessageHelper.getStringTooShortMsg(subject, min, pronoun, retainSubjectCase);
    }

    if (value.trim().length > max ) {
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    return null;
}
