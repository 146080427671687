import {
    FILTER_OPTIONS_SET_FILTER_OPTIONS,
    FILTER_OPTIONS_TOGGLE_DRAWER,
    FILTER_OPTIONS_TOGGLE_OPTION,
    FUNDS_TOGGLE_SHOW_MORE,
    SEARCH_QUERY_SET_SHOW_SEARCH_RESULTS,
    SELECTED_SEARCH_QUERY_SET_VALUE,
    SHOW_TEXT_SEARCH_BAR_TOGGLE,
    TEMP_SEARCH_QUERY_SET_VALUE,
    FUND_TOGGLE_SELECTION,
    SELECTED_FUNDS_SET_VALID,
    ADVANCED_FUND_SET_IS_SELECTED,
    DESELECT_ALL_FUNDS_TRANSITION_ADVANCED_FUND_PICKER,
    RESET_ADVANCED_FUND_PICKER,
    MAX_FUNDS_MODAL_TOGGLE_SELECTION
} from "../../actions/taxFreeSavingsAccount/advancedFundPicker";
import { FIELD_STATUS } from "../status";

export const initAdvancedFundPicker = {
    isFilterOptionsDrawerOpen: false,
    showMoreFunds: false,
    showTextSearchBar: false,
    selectedAdvancedFunds: false,
    maxFundsSelectedModalOpen: false,
    form: {
        tempSearchQuery: null,
        selectedSearchQuery: null,
        selectedFundFilters: {},
        showSearchResults: false,
        selectedFunds: {value: {}, error: null, status: null},
        selectedFundsFeesMap: {},
   },
};

export default (state = initAdvancedFundPicker, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case DESELECT_ALL_FUNDS_TRANSITION_ADVANCED_FUND_PICKER:
            return {
                ...state,
                form: {
                    ...state.form,
                    selectedFunds: {value: {}, error: null, status: null},
                }
            };

        case FUNDS_TOGGLE_SHOW_MORE:
            return {
                ...state,
                showMoreFunds: !state.showMoreFunds,
            };

        case FUND_TOGGLE_SELECTION:
            const valueArray = state.form.selectedFunds.value;
            const newFundFeesMap = {
                ...state.form.selectedFundsFeesMap,
            };
            if (action.value === false) {
                delete newFundFeesMap[action.id];
                // delete valueArray[action.id];
            } else {
                newFundFeesMap[action.id] = action.fees;
                // valueArray[action.id] = action.value;
            }
            valueArray[action.id] = action.value;

            return {
                ...state,
                form: {
                    ...state.form,
                    selectedFunds: {
                        ...state.form.selectedFunds,
                        value: valueArray,
                    },
                    selectedFundsFeesMap: newFundFeesMap,
                },
            };

        case SELECTED_FUNDS_SET_VALID:
            return updateFormField('selectedFunds', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FILTER_OPTIONS_TOGGLE_DRAWER:
            return {
                ...state,
                isFilterOptionsDrawerOpen: !state.isFilterOptionsDrawerOpen,
            };

        case FILTER_OPTIONS_SET_FILTER_OPTIONS:
            return updateFormField('selectedFundFilters', () => action.value);

        case FILTER_OPTIONS_TOGGLE_OPTION:
            return updateFormField('selectedFundFilters', (selectedFundFilters) => ({
                ...selectedFundFilters,
                [action.id]: action.value,
            }));

        case SEARCH_QUERY_SET_SHOW_SEARCH_RESULTS:
            return updateFormField('showSearchResults', () => action.value);
        case TEMP_SEARCH_QUERY_SET_VALUE:
            return updateFormField('tempSearchQuery', () => action.value);
        case SELECTED_SEARCH_QUERY_SET_VALUE:
            return updateFormField('selectedSearchQuery', () => action.value);
        case SHOW_TEXT_SEARCH_BAR_TOGGLE:
            return {
                ...state,
                showTextSearchBar: !state.showTextSearchBar,
            };

        case ADVANCED_FUND_SET_IS_SELECTED:
            return {
                ...state,
                selectedAdvancedFunds: action.isSelected,
            };

        case MAX_FUNDS_MODAL_TOGGLE_SELECTION: {
            return { ...state, maxFundsSelectedModalOpen: action.value }
        }

        case RESET_ADVANCED_FUND_PICKER:
            // Need to spread the whole object, otherwise selectedFunds value doesn't get reset
            return {
                ...state,
                ...initAdvancedFundPicker,
                form: {
                    ...initAdvancedFundPicker.form,
                    selectedFunds: {
                        ...initAdvancedFundPicker.form.selectedFunds,
                        value: {
                            ...initAdvancedFundPicker.form.selectedFunds.value,
                        }
                    }
                }
            };

        default:
            return state;
    }
};
