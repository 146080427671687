/* Navigation */
export const APP_URL_PREFIX = '/tax-free-savings-account';
export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    INVESTMENT_SETUP_INITIAL: 0,
    PERSONAL_DETAILS: 1,
    INVESTMENT_SETUP_CONTRIBUTION: 2,
    SELECT_A_FUND: 3,
    ADVANCED_FUND_PICKER: 4,
    FUND_SPLIT: 5,
    AUXILIARY_DETAILS: 6,
    // BENEFICIARIES: 7,
    // BENEFICIARIES_SPLIT: 8,
    PAYMENT_DETAILS: 7,
    REVIEW: 8,
    THANK_YOU: 9,
};
export const PAGES = [
    {
        URL: `${APP_URL_PREFIX}/investment-setup-initial/`,
        URL_PART: 'investment-setup-initial',
        TITLE: 'Investment Setup Initial',
    },
    {
        URL: `${APP_URL_PREFIX}/personal-details/`,
        URL_PART: 'personal-details',
        TITLE: 'Personal Details',
    },
    {
        URL: `${APP_URL_PREFIX}/investment-setup-contribution/`,
        URL_PART: 'investment-setup-contribution',
        TITLE: 'Investment Setup Contribution',
    },
    {
        URL: `${APP_URL_PREFIX}/select-a-fund/`,
        URL_PART: 'select-a-fund',
        TITLE: 'Select a Fund',
    },
    {
        URL: `${APP_URL_PREFIX}/advanced-fund-picker/`,
        URL_PART: 'advanced-fund-picker',
        TITLE: 'Advanced Fund Picker',
    },
    {
        URL: `${APP_URL_PREFIX}/fund-split/`,
        URL_PART: 'fund-split',
        TITLE: 'Fund Split',
    },
    {
        URL: `${APP_URL_PREFIX}/new-personal-details/`,
        URL_PART: 'new-personal-details',
        TITLE: 'New Personal Details',
    },
    // {
    //     URL: `${APP_URL_PREFIX}/beneficiaries/`,
    //     URL_PART: 'beneficiaries',
    //     TITLE: 'Beneficiaries',
    // },
    // {
    //     URL: `${APP_URL_PREFIX}/beneficiaries-split/`,
    //     URL_PART: 'beneficiaries-split',
    //     TITLE: 'Beneficiaries Split',
    // },
    {
        URL: `${APP_URL_PREFIX}/payment-details/`,
        URL_PART: 'payment-details',
        TITLE: 'Payment Details',
    },
    {
        URL: `${APP_URL_PREFIX}/review/`,
        URL_PART: 'review',
        TITLE: 'Review',
    },
    {
        URL: `${APP_URL_PREFIX}/thank-you/`,
        URL_PART: 'thank-you',
        TITLE: 'Thank You',
    },
];

export const TFSA_PAGE_URLS = {
    INVESTMENT_SETUP_INITIAL: PAGES[PAGE_INDICES.INVESTMENT_SETUP_INITIAL].URL,
    INVESTMENT_SETUP_CONTRIBUTION: PAGES[PAGE_INDICES.INVESTMENT_SETUP_CONTRIBUTION].URL,
    PERSONAL_DETAILS: PAGES[PAGE_INDICES.PERSONAL_DETAILS].URL,
    SELECT_A_FUND: PAGES[PAGE_INDICES.SELECT_A_FUND].URL,
    ADVANCED_FUND_PICKER: PAGES[PAGE_INDICES.ADVANCED_FUND_PICKER].URL,
    FUND_SPLIT: PAGES[PAGE_INDICES.FUND_SPLIT].URL,
    AUXILIARY_DETAILS: PAGES[PAGE_INDICES.AUXILIARY_DETAILS].URL,
    // BENEFICIARIES: PAGES[PAGE_INDICES.BENEFICIARIES].URL,
    // BENEFICIARIES_SPLIT: PAGES[PAGE_INDICES.BENEFICIARIES_SPLIT].URL,
    PAYMENT_DETAILS: PAGES[PAGE_INDICES.PAYMENT_DETAILS].URL,
    REVIEW: PAGES[PAGE_INDICES.REVIEW].URL,
    THANK_YOU: PAGES[PAGE_INDICES.THANK_YOU].URL,
};

export const PROGRESS_BAR_INDICES = {
    NONE: -1,
    PERSONAL_DETAILS: 1,
    // BENEFICIARIES: 2,
    PAYMENT_DETAILS: 2,
    THANK_YOU: 3,
};

//export const PROGRESS_BAR_STEPS = ['Investment setup', 'Personal details', 'Beneficiaries', 'Payment details'];
export const PROGRESS_BAR_STEPS = ['Investment setup', 'Personal details', 'Payment details'];

export const OPEN_DRAWER = {
    CALL_ME_BACK: 'callMeBack',
};

export const TFSA_TOP_UP_CMB_CAMPAIGN = 'ILMS_TFSATU';

export const GUARDIAN_TYPES = {
    PARENT: 'Parent',
    LEGAL_GUARDIAN: 'Legal Guardian',
};

export const INVESTMENT_SETUP_INITIAL_SAVING_FOR = {
    GENERAL_SAVINGS: 'General Savings',
    RETIREMENT: 'Retirement',
    EDUCATION: 'Education',
};

export const PREMIUM_ESCALATION_OPTIONS = {
    LINK_WITH_INFLATION: {
        key: 'linkWithInflation',
        title: 'Link with inflation',
        description: '',
        percentage: 5,
    },
    LINK_WITH_SALARY: {
        key: 'linkWithSalary',
        title: 'Link with salary inflation',
        description: 'Your contribution amount will increase by 6%',
        percentage: 6,
    },
    EDUCATION: {
        key: 'education',
        title: 'Link with education inflation',
        description: 'Your contribution amount will increase by 7%',
        percentage: 6,
    },
    OWN_PERCENTAGE: {
        key: 'ownPercentage',
        title: 'Choose my own percentage',
        description: 'Choose your own fixed yearly percentage',
        percentage: 0,
    },
    ANNUAL_TAX_FREE_LIMIT: {
        key: 'annualTaxFreeLimit',
        title: 'Link with tax free plan limit',
        description: 'Your contribution amount will increase at the same rate as the tax free plan limit',
        percentage: 0,
    },
    NO_INCREASE: {
        key: 'noIncrease',
        title: 'No increase',
        description: 'Your contributions will not increase',
        percentage: 0,
    },
};

export const CONTRIBUTION_TYPE = {
    MONTHLY: 'Monthly',
    LUMP_SUM: 'Lump sum',
    COMBINATION: 'Combination of Both',
};

export const MINIMUM_MONTHLY_CONTRIBUTION_COMBINATION = 350;
export const MINIMUM_MONTHLY_CONTRIBUTION_MONTHLY = 350;
export const MAXIMUM_COMBINED_CONTRIBUTION = 36000;
export const MAX_REGULAR_CONTRIBUTION = 3000;
const SIZE_1_MB = 1048576;
export const ANCHORING_DELAY_MS = 500;

export const PAYMENTS_PAGE_CONFIG = {
    MAX_INPUT_SIZE: SIZE_1_MB,
    MAX_INPUT_SIZE_NAME: '1mbs',
    VALID_UPLOAD_TYPES: [
        { FORMAT: 'image/jpeg', FORMAT_NAME: 'JPG' },
        { FORMAT: 'image/png', FORMAT_NAME: 'PNG' },
        { FORMAT: 'application/pdf', FORMAT_NAME: 'PDF' },
    ],
};

/* Fund Selection */
export const DEFAULT_FUND_OPTION_CODES = {
    CAUTIOUS_INVESTOR: 'OM_MAX_INT',
    COMFORTABLE_INVESTOR: 'OM_BAL_B1',
    ADVENTUROUS_INVESTOR: 'OM_TOP_40_LIFE',
    MODERATE_INVESTOR: 'OM_MOD_BAL_B1',
};

export const FUND_OPTIONS = {
    CAUTIOUS_INVESTOR: 1,
    COMFORTABLE_INVESTOR: 2,
    ADVENTUROUS_INVESTOR: 3,
    MODERATE_INVESTOR: 4,
};
export const FUND_OPTIONS_TO_CODE = {
    [FUND_OPTIONS.CAUTIOUS_INVESTOR]: DEFAULT_FUND_OPTION_CODES.CAUTIOUS_INVESTOR,
    [FUND_OPTIONS.COMFORTABLE_INVESTOR]: DEFAULT_FUND_OPTION_CODES.COMFORTABLE_INVESTOR,
    [FUND_OPTIONS.ADVENTUROUS_INVESTOR]: DEFAULT_FUND_OPTION_CODES.ADVENTUROUS_INVESTOR,
};
export const RECOMMENDED_MINIMUM_TERMS = {
    lowRisk: '1 year+',
    mediumRisk: '5 years+',
    highRisk: '10 years+',
};

export const ADDRESS_TYPES = {
    PRIVATE_BAG: 'PrivateBag',
    PO_BOX: 'POBox',
    STREET_ADDRESS: 'StreetAddress',
};

export const MARITAL_TYPES = {
    MARRIED: 'Married',
    MARRIED_COP: 'MarriedCop',
    MARRIED_OTHER: 'MarriedOther',
};

export const RISK_NAMES_TO_RISK_CATEGORY = {
    Low: 'lowRisk',
    'Low to Moderate': 'lowRisk',
    Moderate: 'mediumRisk',
    'Moderate to High': 'mediumRisk',
    High: 'highRisk',
};
export const RISK_CHECKBOX_ITEMS = [
    { key: 'low', label: 'Low' },
    { key: 'low-moderate', label: 'Low - Moderate' },
    { key: 'moderate', label: 'Moderate' },
    { key: 'moderate-high', label: 'Moderate - High' },
    { key: 'high', label: 'High' },
];
export const RISK_NAMES_TO_ID = {
    Low: 'low',
    'Low to Moderate': 'low-moderate',
    Moderate: 'moderate',
    'Moderate to High': 'moderate-high',
    High: 'high',
};

export const COMPLIANCE_CHECKBOX_ITEMS = [
    { key: 'reg28', label: 'Reg 28' },
    { key: 'shariah', label: "Shari'ah" },
];
export const STATUS_CHECKBOX_ITEMS = [
    { key: 'open', label: 'Open' },
    { key: 'capped', label: 'Capped' },
];
export const ADVANCED_FUND_PICKER_CHECKBOX_ITEMS = [
    ...RISK_CHECKBOX_ITEMS,
    ...COMPLIANCE_CHECKBOX_ITEMS,
    ...STATUS_CHECKBOX_ITEMS,
];
/*
 * Conditions of the following cases:
 *
 * Regular contribution only
 *   REGULAR_WITH_ADMIN:                 Regular premium < 2750
 *   REGULAR_NO_ADMIN:                   Regular premium >= 2750
 *
 * Lump sum contribution only
 *   LUMP_SUM_NO_REGULAR_WITH_ADMIN:     Lump sum < 15000
 *   LUMP_SUM_NO_REGULAR_NO_ADMIN:       Lump sum >= 15000 && Lump sum < 33000
 *   LUMP_SUM_WITH_REGULAR_NO_ADMIN:     Lump sum >= 33000
 *
 * Combination (both monthly and regular contribution)
 *   LOW_REGULAR_HIGH_COMBO:            Regular < 2500 && ((Regular*12) + Lump sum >= 33000)
 *   HIGH_REGULAR_HIGH_COMBO:           Regular >= 2500 && ((Regular*12) + Lump sum >= 33000)
 *   LOW_COMBO:                         ((Regular*12) + Lump sum >= 33000)
 */
export const EFFECTIVE_ANNUAL_COST_AMOUNTS = {
    REGULAR_WITH_ADMIN: {
        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [0.53, 0.53, 0.53, 0.53],
        OTHER: [0.0, 0.0, 0.0, 0.0],
        EAC: [1.85, 1.85, 1.85, 1.85],
    },
    REGULAR_NO_ADMIN: {
        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [0.53, 0.53, 0.53, 0.53],
        OTHER: [-0.26, -0.26, -0.26, -0.26],
        EAC: [1.59, 1.59, 1.59, 1.59],
    },
    LUMP_SUM_NO_REGULAR_WITH_ADMIN: {
        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [6.15, 6.57, 7.07, 9.03],
        OTHER: [0.0, 0.0, 0.0, 0.0],
        EAC: [7.47, 7.89, 8.39, 10.35],
    },
    LUMP_SUM_NO_REGULAR_NO_ADMIN: {
        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [2.05, 2.1, 2.15, 2.29],
        OTHER: [0.0, 0.0, 0.0, 0.0],
        EAC: [3.37, 3.42, 3.47, 3.61],
    },
    LUMP_SUM_WITH_REGULAR_NO_ADMIN: {
        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [0.93, 0.94, 0.95, 0.98],
        OTHER: [-0.46, -0.15, -0.09, -0.05],
        EAC: [1.79, 2.11, 2.18, 2.26],
    },
    LOW_REGULAR_HIGH_COMBO: {
        // INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        // ADVICE:                [0.00, 0.00, 0.00, 0.00],
        // ADMIN:                 [0.53, 0.53, 0.53, 0.53],
        // OTHER:                 [-0.26, -0.26, -0.26, -0.26],
        // EAC:                   [1.59, 1.59, 1.59, 1.59],
        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [0.53, 0.53, 0.53, 0.53],
        OTHER: [-0.26, -0.08, -0.04, -0.01],
        EAC: [1.59, 1.77, 1.81, 1.83],
    },
    HIGH_REGULAR_HIGH_COMBO: {
        // INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        // ADVICE:                [0.00, 0.00, 0.00, 0.00],
        // ADMIN:                 [0.53, 0.53, 0.53, 0.53],
        // OTHER:                 [0.00, 0.00, 0.00, 0.00],
        // EAC:                   [1.85, 1.85, 1.85, 1.85],

        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [0.53, 0.53, 0.53, 0.53],
        OTHER: [-0.26, -0.26, -0.26, -0.26],
        EAC: [1.59, 1.59, 1.59, 1.59],
    },
    LOW_COMBO: {
        INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        ADVICE: [0.0, 0.0, 0.0, 0.0],
        ADMIN: [0.53, 0.53, 0.53, 0.53],
        OTHER: [0.0, 0.0, 0.0, 0.0],
        EAC: [1.85, 1.85, 1.85, 1.85],
        // INVESTMENT_MANAGEMENT: [1.32, 1.32, 1.32, 1.32],
        // ADVICE:                [0.00, 0.00, 0.00, 0.00],
        // ADMIN:                 [0.53, 0.53, 0.53, 0.53],
        // OTHER:                 [-0.26, -0.07, -0.04, -0.01],
        // EAC:                   [1.59, 1.77, 1.81, 1.84],
    },
};

export const EVEN_SPLIT_MATRIX = [
    [100],
    [50, 50],
    [34, 33, 33],
    [25, 25, 25, 25],
    [20, 20, 20, 20, 20],
    [20, 16, 16, 16, 16, 16],
    [16, 14, 14, 14, 14, 14, 14],
    [16, 12, 12, 12, 12, 12, 12, 12],
    [12, 11, 11, 11, 11, 11, 11, 11, 11],
    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
];

export const ACCOUNT_VALIDATION_STATUS = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
    FAILED: 'FAILED',
    PASSED: 'PASSED',
};

export const PAYMENT_DETAILS_LEGAL_SECTION = 'legal-section';
export const PERSONAL_SOLUTIONS_URL = '/personal/solutions/';
export const PRODUCT_DETAIL_PAGE = '/personal/solutions/investment-plans/tax-free-savings/';

export const FUND_SPLIT_TYPE = {
    REGULAR: 'regular',
    LUMP_SUM: 'lump-sum',
};

export const FUND_SPLIT_FORM = {
    REGULAR: 'regularForm',
    LUMP_SUM: 'lumpSumForm',
    LUMP_SUM_META: 'lumpSumFormMeta',
    REGULAR_META: 'regularFormMeta',
};

export const DEFAULT_COUNTRY = {
    ZA_CODE: 'SouthAfrica',
    ZA_DESCRIPTION: 'South Africa',
    ZA_ID_TYPE_CODE: 'SouthAfricanIdentity',
};

export const SUBMISSION_STATUS = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    UNVERIFIED: 'UNVERIFIED',
};
