export const GROUPLIFE_QUOTE_TOOL = 'roaQuoteTools/groupLifeQuoteTool/';
export const GROUPLIFE_QUOTE_TOOL_SET_VALUE =
    GROUPLIFE_QUOTE_TOOL + 'setValue/';
export const GROUPLIFE_QUOTE_TOOL_SET_VALID =
    GROUPLIFE_QUOTE_TOOL + 'setValid/';

//api status
export const API_PENDING_TRANSITION_GROUPLIFE_QUOTE_TOOL = `${GROUPLIFE_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_FAILURE_TRANSITION_GROUPLIFE_QUOTE_TOOL = `${GROUPLIFE_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_SUCCESS_TRANSITION_GROUPLIFE_QUOTE_TOOL = `${GROUPLIFE_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionGroupLifeQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_GROUPLIFE_QUOTE_TOOL }),
    success: () => ({ type: API_FAILURE_TRANSITION_GROUPLIFE_QUOTE_TOOL }),
    failure: () => ({ type: API_SUCCESS_TRANSITION_GROUPLIFE_QUOTE_TOOL }),
};

export const FORM_VALID_GROUPLIFE_QUOTE_TOOL =
    'roaQuoteTools/GroupLifeQuoteTool//transition/formStateValid';
export const FORM_INVALID_GROUPLIFE_QUOTE_TOOL =
    'roaQuoteTools/GroupLifeQuoteTool//transition/formInvalid';
export const REINITIALIZE =
    'roaQuoteTools/GroupLifeQuoteTool/state/reinitialize';

export const transitionGroupLifeTool = {
    formValid: () => ({ type: FORM_VALID_GROUPLIFE_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_GROUPLIFE_QUOTE_TOOL }),
};

export const COLLECT_GROUPLIFE_DATA =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'collectGroupLifeData';
export const CALCULATE_GROUPLIFE_DATA =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_GROUPLIFE_DATA_FAILURE =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'failure';

export const RESET_GROUPLIFE_DATA =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'resetResultsSection';

export const WHICH_FORM_TO_DISPLAY =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const collectGroupLifeData = () => ({
    type: COLLECT_GROUPLIFE_DATA,
});
export const resetResultSection = () => ({
    type: RESET_GROUPLIFE_DATA,
});
export const calculateGroupLifeData = value => ({
    type: CALCULATE_GROUPLIFE_DATA,
    value,
});
export const collectGroupLifeDataFailure = error => ({
    type: CALCULATE_GROUPLIFE_DATA_FAILURE,
    error,
});

export const NUMBER_OF_EMPLOYEES_SET_VALUE =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'numberOfEmployees';
export const NUMBER_OF_EMPLOYEES_SET_VALID =
    GROUPLIFE_QUOTE_TOOL_SET_VALID + 'numberOfEmployees';
export const AVERAGE_AGE_OF_EMPLOYEES_SET_VALUE =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'averageAgeOfEmployees';
export const AVERAGE_AGE_OF_EMPLOYEES_SET_VALID =
    GROUPLIFE_QUOTE_TOOL_SET_VALID + 'averageAgeOfEmployees';
export const SET_COVER_VALUE = GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'cover';
export const REMOVE_SECTION = GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'removeSection';
export const SET_STEP = GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'step';
export const SET_RANGE_VALUE = GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'coverMultiple';
export const TOTAL_SUM_ASSURED_SET_VALUE =
    GROUPLIFE_QUOTE_TOOL_SET_VALUE + 'totalSumAssured';
export const TOTAL_SUM_ASSURED_SET_VALID =
    GROUPLIFE_QUOTE_TOOL_SET_VALID + 'totalSumAssured';

export const setNumberOfEmployeesValue = value => ({
    type: NUMBER_OF_EMPLOYEES_SET_VALUE,
    value,
});
export const setNumberOfEmployeesValid = error => ({
    type: NUMBER_OF_EMPLOYEES_SET_VALID,
    error,
});
export const setAverageAgeOfEmployeesValue = value => ({
    type: AVERAGE_AGE_OF_EMPLOYEES_SET_VALUE,
    value,
});
export const setAverageAgeOfEmployeesValid = error => ({
    type: AVERAGE_AGE_OF_EMPLOYEES_SET_VALID,
    error,
});
export const setCoverValue = (value, index) => ({
    type: SET_COVER_VALUE,
    value,
    index,
});
export const removeSection = value => ({
    type: REMOVE_SECTION,
    value,
});
export const setStep = (value, index) => ({
    type: SET_STEP,
});
export const setRangeValue = (value, code) => ({
    type: SET_RANGE_VALUE,
    value,
    code,
});
export const setTotalSumAssuredValue = value => ({
    type: TOTAL_SUM_ASSURED_SET_VALUE,
    value,
});
export const setTotalSumAssuredValid = error => ({
    type: TOTAL_SUM_ASSURED_SET_VALID,
    error,
});

export const setWhichFormToDisplay = value => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});

export const setToGroupLifeQuoteInitial = () => ({ type: REINITIALIZE });
