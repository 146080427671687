export const MAKE_A_CLAIM = 'makeAClaim';
export const MAKE_A_CLAIM_SET_VALUE = MAKE_A_CLAIM + 'setValue/';
export const MAKE_A_CLAIM_SET_VALID = MAKE_A_CLAIM + 'setValid/';

export const FORM_VALID_MAKE_A_CLAIM = 'makeAClaim/transition/formStateValid';
export const FORM_INVALID_MAKE_A_CLAIM = 'makeAClaim/transition/formInvalid';

export const EMAIL_SENDING_STATUS_SET_VALUE = 'makeAClaim/emailSendingStatus/setValue';

export const REINITIALIZE = 'makeAClaim/state/reinitialize';

export const transitionMakeAClaim = {
    formValid: () => ({ type: FORM_VALID_MAKE_A_CLAIM }),
    formInvalid: () => ({ type: FORM_INVALID_MAKE_A_CLAIM }),
};

export const SET_FIRST_NAME = MAKE_A_CLAIM_SET_VALUE + 'firstName';
export const SET_MAKE_A_CLAIM_AUTHORIZATION = MAKE_A_CLAIM_SET_VALUE + 'authorization';
export const SET_FIRST_NAME_VALID = MAKE_A_CLAIM_SET_VALID + 'firstName';
export const SET_SURNAME = MAKE_A_CLAIM_SET_VALUE + 'surname';
export const SET_SURNAME_VALID = MAKE_A_CLAIM_SET_VALID + 'surname';
export const SET_PHONE_NUMBER = MAKE_A_CLAIM_SET_VALUE + 'phoneNumber';
export const SET_PHONE_NUMBER_VALID = MAKE_A_CLAIM_SET_VALID + 'phoneNumber';
export const SET_EMAIL_ADDRESS = MAKE_A_CLAIM_SET_VALUE + 'emailAddress';
export const SET_SELECTED_CLAIM_OR_REQUEST = MAKE_A_CLAIM_SET_VALUE + 'selectedClaimOrRequest';
export const SET_EMAIL_ADDRESS_VALID = MAKE_A_CLAIM_SET_VALID + 'emailAddress';
export const SET_POLICY_NUMBER = MAKE_A_CLAIM_SET_VALUE + 'policyNumber';
export const SET_POLICY_NUMBER_VALID = MAKE_A_CLAIM_SET_VALID + 'policyNumber';
export const SET_CLAIM_CATEGORIES = MAKE_A_CLAIM_SET_VALUE + 'claimCategories';
export const SET_CLAIM_TYPES = MAKE_A_CLAIM_SET_VALUE + 'claimTypes';
export const SET_REQUEST_TYPES = MAKE_A_CLAIM_SET_VALUE + 'requestTypes';
export const MAKE_A_CLAIM_CATEGORIES = MAKE_A_CLAIM_SET_VALUE + 'categories';
export const MAKE_A_CLAIM_TYPES = MAKE_A_CLAIM_SET_VALUE + 'types';
export const MAKE_A_CLAIM_REQUEST = MAKE_A_CLAIM_SET_VALUE + 'request';
export const SET_SELECTED_CLAIM_TYPES = MAKE_A_CLAIM_SET_VALUE + 'selectedTypes';
export const SET_SELECTED_CLAIM_CATEGORIES = MAKE_A_CLAIM_SET_VALUE + 'selectedCategories';
export const SET_SELECTED_REQUEST = MAKE_A_CLAIM_SET_VALUE + 'selectedRequest';

export const MAKE_A_CLAIM_SEND_EMAIL = MAKE_A_CLAIM_SET_VALUE + 'sendEmail';
export const MAKE_A_CLAIM_SEND_EMAIL_SUCCESS = MAKE_A_CLAIM_SET_VALUE + 'sendEmailSuccess';
export const MAKE_A_CLAIM_SEND_EMAIL_FAILURE = MAKE_A_CLAIM_SET_VALUE + 'sendEmailFailure';

export const setFirstName = (value) => ({
    type: SET_FIRST_NAME,
    value,
});

export const setFirstNameValid = (error) => ({
    type: SET_FIRST_NAME_VALID,
    error,
});

export const setSurname = (value) => ({
    type: SET_SURNAME,
    value,
});

export const toggleMakeAClaimAuthorization = (value) => ({
    type: SET_MAKE_A_CLAIM_AUTHORIZATION,
    value,
});

export const setSurnameValid = (error) => ({
    type: SET_SURNAME_VALID,
    error,
});

export const setPhoneNumber = (value) => ({
    type: SET_PHONE_NUMBER,
    value,
});

export const setEmailAddress = (value) => ({
    type: SET_EMAIL_ADDRESS,
    value,
});


export const setPolicyNumber = (value) => ({
    type: SET_POLICY_NUMBER,
    value,
});


export const setPhoneNumberValid = (error) => ({
    type: SET_PHONE_NUMBER_VALID,
    error,
});

export const setSelectedClaimOrRequest = (value) => ({
    type: SET_SELECTED_CLAIM_OR_REQUEST,
    value,
});

export const setSelectedClaimTypes = (value) => ({
    type: SET_SELECTED_CLAIM_TYPES,
    value,
});

export const setSelectedRequest = (value) => ({
    type: SET_SELECTED_REQUEST,
    value,
});

export const setSelecteClaimCategories = (value) => ({
    type: SET_SELECTED_CLAIM_CATEGORIES,
    value,
});

export const setEmailAddressValid = (error) => ({
    type: SET_EMAIL_ADDRESS_VALID,
    error,
});

export const setPolicyNumberValid = (error) => ({
    type: SET_POLICY_NUMBER_VALID,
    error,
});

export const setClaimCategories = (value) => ({
    type: SET_CLAIM_CATEGORIES,
    value
});

export const setClaimTypes = (value) => ({
    type: SET_CLAIM_TYPES,
    value
});

export const setRequestTypes = (value) => ({
    type: SET_REQUEST_TYPES,
    value
});

export const claimCategories = (value) => ({
    type: MAKE_A_CLAIM_CATEGORIES,
    value,
});

export const claimTypes = (value) => ({
    type: MAKE_A_CLAIM_TYPES,
    value,
});

export const claimRequest = (value) => ({
    type: MAKE_A_CLAIM_REQUEST,
    value,
});

export const makeAClaimEmail = () => ({ type: MAKE_A_CLAIM_SEND_EMAIL });
export const makeAClaimEmailSuccess = (value) => ({ type: MAKE_A_CLAIM_SEND_EMAIL_SUCCESS, value});
export const makeAClaimEmailFailure = error => ({ type: MAKE_A_CLAIM_SEND_EMAIL_FAILURE, error });

export const setToMakeAClaimInitial = () => ({ type: REINITIALIZE });

export const setEmailSendingStatusValue = (value) => ({type: EMAIL_SENDING_STATUS_SET_VALUE, value});