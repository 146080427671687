import { createSelector } from 'reselect';
import { getRoaQuoteTools } from '../../index';

import { FORM_STATUS } from '../../../../reducers/status';
import { APICallSelector } from '../../api';

export const getDeathTermQuoteTool = createSelector(
    getRoaQuoteTools,
    (roaQuoteTools) => roaQuoteTools.deathTermCoverQuoteTool
);

export const getSelectedForm = createSelector(
    getDeathTermQuoteTool,
    (deathTermCoverQuoteTool) => deathTermCoverQuoteTool.selectedForm
);

export const getRoaCmbObject = createSelector(
    getDeathTermQuoteTool,
    (deathTermCoverQuoteTool) => deathTermCoverQuoteTool.roaCmbObject
);

export const getDeathTermQuoteToolForm = createSelector(
    getDeathTermQuoteTool,
    (deathTermCoverQuoteTool) => deathTermCoverQuoteTool.form
);

export const getDeathTermQuoteToolFormValid = createSelector(
    getDeathTermQuoteTool,
    (deathTermCoverQuoteTool) =>
        deathTermCoverQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getDeathTermCoverPremiumAmount = createSelector(
    getDeathTermQuoteTool,
    (deathTermCoverQuoteTool) => deathTermCoverQuoteTool.deathTermCoverAmount
);

// API Calls Selectors
export const getShowError = APICallSelector.getErrorStatus(
    getDeathTermQuoteTool
);

export const getIsApiPending = APICallSelector.getPendingStatus(
    getDeathTermQuoteTool
);

const makeGetFieldForm = (fieldId) =>
    createSelector(getDeathTermQuoteToolForm, (form) => form[fieldId]);

export const getGenderValue = makeGetFieldForm('genderValue');
export const getCandidateDOB = makeGetFieldForm('candidateDOB');
export const getDisabilityCoveredValue = makeGetFieldForm(
    'disabilityCoveredValue'
);
export const getSmokerCoveredValue = makeGetFieldForm('smokerCoveredValue');
export const getCoverAmount = makeGetFieldForm('coverAmount');
export const getNumberOfYearsCovered = makeGetFieldForm('numberOfYearsCovered');
