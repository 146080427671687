import {transitionFinancialAdvice} from '../../../actions/atRetirement/financialAdvice';
import {FIELD_STATUS} from "../../../reducers/status";
import {CONTACT_INTERESTS} from "../../../actions/atRetirement/types";
import {getFinancialAdviceForm} from "../../../selectors/atRetirement/financialAdvice";

export const financialAdviceRules = (store, next, action) => {
    const result = next(action);
    const financialAdviceForm = getFinancialAdviceForm(store.getState());
    const formIsValid = formValid(financialAdviceForm);

    store.dispatch(formIsValid
        ? transitionFinancialAdvice.formValid()
        : transitionFinancialAdvice.formInvalid());

    return result;
};

const formValid = formFields => {
    // If something else has not been selected exclude more help from validation
    if (formFields.reasons.value && !formFields.reasons.value.includes(CONTACT_INTERESTS.SOMETHING_ELSE)) {
        const {shortDescription, ...otherFields} = formFields;
        return Object.values(otherFields).every(field =>
            field.status === FIELD_STATUS.VALID
        );
    }

    return Object.values(formFields).every(field =>
        field.status === FIELD_STATUS.VALID
    );
};
