export const TRAVEL_INSURANCE_ABOUT_YOU_FORM =
    'roaTravelInsurance/aboutYouForm/';

export const TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM + 'setValue/';
export const TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM + 'setValid/';

export const FORM_VALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM =
    'roaTravelInsurance/aboutYouForm/transition/formValid';
export const FORM_INVALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM =
    'roaTravelInsurance/aboutYouForm/transition/formInValid';

export const REINITIALIZE =
    'roaTravelInsurance/aboutYouForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_ABOUT_YOU_FORM =
    'roaTravelInsurance/aboutYouForm/transition/dropOffLead';
export const dropOffLeadPersonalDetails = value => ({
    type: DROP_OFF_LEAD_TRANSITION_ABOUT_YOU_FORM,
    value,
});

export const transitionTravelInsuranceAboutYouForm = {
    formValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_ABOUT_YOU_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_ABOUT_YOU_FORM = `${TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_ABOUT_YOU_FORM = `${TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_ABOUT_YOU_FORM = `${TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionAboutYouForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_ABOUT_YOU_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_ABOUT_YOU_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_ABOUT_YOU_FORM,
    }),
};

//calculated Data
export const COLLECT_ABOUT_YOU_FORM_DATA =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'collectAboutYouData';
export const SUBMIT_ABOUT_YOU_SUCCESS =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'success';
export const SUBMIT_ABOUT_YOU_FAILURE =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'failure';

export const collectAboutYouData = () => ({
    type: COLLECT_ABOUT_YOU_FORM_DATA,
});
export const submitAboutYouDataSuccess = value => ({
    type: SUBMIT_ABOUT_YOU_SUCCESS,
    value,
});
export const submitAboutYouFailure = error => ({
    type: SUBMIT_ABOUT_YOU_FAILURE,
    error,
});

// Form Values
export const TITLE_SET_VALUE =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'title';
export const TITLE_SET_VALID =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID + 'title';

export const FIRST_NAME_SET_VALUE =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID + 'firstName';

export const OTHER_NAMES_SET_VALUE =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'surname';
export const OTHER_NAMES_SET_VALID =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID + 'surname';

export const PHONE_NUMBER_SET_VALUE =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID + 'phoneNumber';

export const EMAIL_SET_VALUE =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID =
    TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID + 'email';

export const DOB_SET_VALUE = TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID = TRAVEL_INSURANCE_ABOUT_YOU_FORM_SET_VALID + 'DOB';

export const setTitleValue = value => ({
    type: TITLE_SET_VALUE,
    value,
});
export const setTitleValid = error => ({
    type: TITLE_SET_VALID,
    error,
});

export const setFirstNameValue = value => ({
    type: FIRST_NAME_SET_VALUE,
    value,
});
export const setFirstNameValid = error => ({
    type: FIRST_NAME_SET_VALID,
    error,
});

export const setSurnameValue = value => ({
    type: OTHER_NAMES_SET_VALUE,
    value,
});
export const setSurnameValid = error => ({
    type: OTHER_NAMES_SET_VALID,
    error,
});

export const setPhoneNumberValue = value => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = error => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setEmailValue = value => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = error => ({
    type: EMAIL_SET_VALID,
    error,
});

export const setDOBValue = value => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDOBValid = error => ({
    type: DOB_SET_VALID,
    error,
});
