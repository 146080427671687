import { validateEmail, validateName, validateContactNumber, validateSingleSelection } from '@om/validation';

import { setInsuranceTypeValid } from '../../../actions/allsureInsurance/insuranceType';

let prevValidationAction = null;

export const insuranceTypeValidations = (store, next, action) => {
    const { homeContent, homeOwners, motor } = store.getState().allsureInsurance.insuranceType.form;
    const validateInsuranceType = (fieldType, value) => {
        if (value === 'primary') {
            if (fieldType === 'motor') {
                return homeOwners.value === value || homeContent.value === value
                    ? null
                    : 'You’ll need to also select at least homeowners or home contents insurance.';
            } else {
                return null;
            }
        } else {
            if (fieldType === 'motor') {
                return homeContent.value === 'primary' || homeOwners.value === 'primary'
                    ? null
                    : 'Please select the insurance to proceed';
            } else {
                if (motor.value === 'primary') {
                    if (fieldType === 'homeOwners'){
                      return  homeContent.value === 'primary' ? null : 'You’ll need to also select at least homeowners or home contents insurance.';
                    }
                    if (fieldType === 'homeContent'){
                       return homeOwners.value === 'primary' ? null : 'You’ll need to also select at least homeowners or home contents insurance.';
                    }          
                }
                if (fieldType === 'homeOwners') {
                    return homeContent.value === 'primary' ? null : 'Please select the insurance to proceed';
                }
                if (fieldType === 'homeContent') {
                    return homeOwners.value === 'primary' ? null : 'Please select the insurance to proceed';
                }
                return null;
            }
        }
    };
    const validationAction = (() => {
        const trimValue = (value) => (value ? value.trim() : value);
        const removeWhiteSpacesFromValue = (value) => (value ? value.replace(/ /g, '') : value);
        let error;
        switch (action.value.fieldType) {
            case 'privacyNotice':
                error = validateSingleSelection(action.value.value, 'Privacy notice');
                return setInsuranceTypeValid(error, action.value.fieldType);

            case 'motor':
                error = validateInsuranceType('motor', action.value.value);
                return setInsuranceTypeValid(error, action.value.fieldType);
            case 'homeOwners':
                error = validateInsuranceType('homeOwners', action.value.value);
                return setInsuranceTypeValid(error, 'motor');
            case 'homeContent':
                error = validateInsuranceType('homeContent', action.value.value);
                return setInsuranceTypeValid(error, 'motor');

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
