import { validateDateOfBirth, validateSurname, validateInitials } from  "@om/validation";
import {
    setInitialsValid,
    setSurnameValid,
    setDOBValid,
    INITIALS_SET_VALUE,
    SURNAME_SET_VALUE,
    DOB_SET_VALUE
} from "../../../actions/publicWeb/unclaimedBenefits";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const unclaimedBenefitsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case INITIALS_SET_VALUE:
                error = validateInitials(action.value, { max: 12 });
                return setInitialsValid(error);

            case SURNAME_SET_VALUE:
                error = validateSurname(action.value);
                return setSurnameValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value);
                return setDOBValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
