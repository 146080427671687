// Transitions
export const FORM_VALID_TRANSITION_PURCHASE_PERSONAL_DETAILS =
  "allsureInsurance/purchasePersonalDetails/transition/formValid";
export const FORM_INVALID_TRANSITION_PURCHASE_PERSONAL_DETAILS =
  "allsureInsurance/purchasePersonalDetails/transition/formInvalid";

export const RESET_STATE_TRANSITION_PURCHASE_PERSONAL_DETAILS =
  "allsureInsurance/purchasePersonalDetails/transition/resetState";

export const ALLSURE_INSURANCE_PURCHASE_PERSONAL_DETAILS =
  "allsureInsurance/purchasePersonalDetails/";
export const PURCHASE_PERSONAL_DETAILS_SET_VALUE =
ALLSURE_INSURANCE_PURCHASE_PERSONAL_DETAILS+"setValue/";
export const PURCHASE_PERSONAL_DETAILS_SET_VALID =
ALLSURE_INSURANCE_PURCHASE_PERSONAL_DETAILS +"setValid/";
export const PURCHASE_PERSONAL_DETAILS_EDIT =
ALLSURE_INSURANCE_PURCHASE_PERSONAL_DETAILS +"setEdit";


//action

export const transitionPurchasePersonalDetails = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_PURCHASE_PERSONAL_DETAILS }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_PURCHASE_PERSONAL_DETAILS }),
};

export const resetPurchasePersonalDetails = () => ({
  type: RESET_STATE_TRANSITION_PURCHASE_PERSONAL_DETAILS,
});

export const setPurchasePersonalDetailsFieldValue =(value)=>({type: PURCHASE_PERSONAL_DETAILS_SET_VALUE, value})
export const setPurchasePersonalDetailsFieldValid =(error,fieldType)=>({type: PURCHASE_PERSONAL_DETAILS_SET_VALID, error, fieldType})
export const setPurchasePersonalDetailsEdit =(value)=>({type: PURCHASE_PERSONAL_DETAILS_EDIT, value})

