import {fireGtmEvent} from './gtmEvents';

export const otherEventTracking = (actionType, otherEventIndex, event, refererId = null ) => {

    const [app, page, eventType, eventName] = actionType.split('/');
    const generatedEvent = generateEvent(otherEventIndex);

    const trackingObject = {};
    trackingObject.app = app;
    trackingObject.page = page;
    trackingObject.eventType = eventType;
    trackingObject[generatedEvent] = event;
    if(refererId)
        trackingObject.refererId = refererId

    fireGtmEvent(eventName, trackingObject);
}

const generateEvent = (otherEventIndex) => {
    return otherEvents[otherEventIndex].PROPERTY;
}

export const findOtherEventIndex = value => {
    return otherEvents.findIndex(type => type.KEY === value);
}

const otherEvents = [
    {
        KEY: 'ARC_SET_REFERRER_ID',
        PROPERTY: 'referrerId',
    },
    {
        KEY: 'OPEN_DRAWER',
        PROPERTY: 'openDrawer',
    },
    {
        KEY: 'CLOSE_DRAWER',
        PROPERTY: 'closeDrawer',
    },
    {
        KEY: 'BUTTON_CLICKED',
        PROPERTY: 'action',
    },
    {
        KEY: 'FACT_SHEET_CLICKED',
        PROPERTY: 'factSheetClicked',
    },
    {
        KEY: 'SELECT',
        PROPERTY: 'value',
    },
    {
        KEY: 'TOGGLE_CHECKBOX',
        PROPERTY: 'value',
    },
    {
        KEY: 'POPUP',
        PROPERTY: 'action'
    },
    {
        KEY: 'EDIT_PAGE',
        PROPERTY: 'editPage'
    },
    {
        KEY: 'ARC_NAVIGATION',
        PROPERTY: 'action'
    },
    {
        KEY: 'INVALID_CONTINUE_TRANSITION_REVIEW',
        PROPERTY: 'form'
    },
    {
        KEY: 'SELECTED_COUNTRY',
        PROPERTY: 'field'
    },
    {
        KEY: 'TOTAL_DIVEDENDS',
        PROPERTY: 'field'
    },
    {
        KEY: 'SERVICE_PROVIDER',
        PROPERTY: 'serviceProvider'
    },
    {
        KEY: 'LEADS_SET_REFERRER_DATA',
        PROPERTY: 'refererData',
    },
    {
        KEY: 'ARC_PAGE_RENDER_TRACKING',
        PROPERTY: 'action',
    },
];
