import {
    FORM_INVALID_TRANSITION_CALCULATOR,
    FORM_VALID_TRANSITION_CALCULATOR,
    NET_MONTHLY_INCOME_SET_VALID,
    NET_MONTHLY_INCOME_SET_VALUE,
    TOGGLE_DISPLAY_RESULTS_BUDGET_TABLE,
    TOGGLE_DISPLAY_RESULTS_CARD,
    TOGGLE_HAS_CHILDREN
} from "../../actions/budgetBenchmarkCalculator/calculator";
import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from "../status";

export const budgetBenchmarkInitialState = {
    formStatus: FORM_STATUS.INVALID,
    displayResultsCard: TOGGLE_STATUS.NO,
    displayResultsBudgetTable: TOGGLE_STATUS.NO,
    form: {
        netMonthlyIncome: { value: null, error: null, status: null },
        hasChildren: TOGGLE_STATUS.UNTOUCHED
    }
}

export default (state = budgetBenchmarkInitialState, action) => {

    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_TRANSITION_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            };
        case FORM_INVALID_TRANSITION_CALCULATOR:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID
            };
        case TOGGLE_DISPLAY_RESULTS_CARD:
            return {
                ...state,
                displayResultsCard: state.displayResultsCard !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO
            }
        case TOGGLE_DISPLAY_RESULTS_BUDGET_TABLE:
            return {
                ...state,
                displayResultsBudgetTable: state.displayResultsBudgetTable !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO
            }
        case NET_MONTHLY_INCOME_SET_VALUE:
            return updateFormField('netMonthlyIncome', field => ({
                ...field,
                value: action.value
            }));
        case NET_MONTHLY_INCOME_SET_VALID:
            return updateFormField('netMonthlyIncome', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }));
        case TOGGLE_HAS_CHILDREN:
            return {
                ...state,
                form: {
                    ...state.form,
                    hasChildren: state.form.hasChildren !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO
                }
            }
        default:
            return state;
    }
}
