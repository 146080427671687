import { createSelector } from 'reselect';
import { getPersonalPensionPlanState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getBeneficiaries = createSelector(getPersonalPensionPlanState, (state) => state.beneficiaries);

export const getBeneficiariesForms = createSelector(getBeneficiaries, (beneficiaries) => beneficiaries.form);

export const getBeneficiariesFormValid = createSelector(getBeneficiaries, (beneficiaries) => {
    const { formStatus } = beneficiaries;

    if (formStatus.length > 1) {
        const allValid = formStatus.every((status) => status === FORM_STATUS.VALID);
        return allValid;
    } else if (formStatus.length === 1) {
        return formStatus[0] === FORM_STATUS.VALID;
    } else {
        return true;
    }
});

export const getSingleBeneficiaryFormValid = (index) =>
    createSelector(getBeneficiaries, (beneficiaries) => {
        const { formStatus } = beneficiaries;

        return formStatus[index] === FORM_STATUS.VALID;
    });

export const getSelectedBeneficiariesFormIndex = createSelector(
    getBeneficiaries,
    (beneficiaries) => beneficiaries.selectedFormIndex,
);

const makeGetFieldForm = (fieldId, index) => createSelector(getBeneficiariesForms, (form) => form[index][fieldId]);

export const getFirstNameValue = (index) => makeGetFieldForm('firstName', index);
export const getLastNameValue = (index) => makeGetFieldForm('lastName', index);
export const getIdNumberValue = (index) => makeGetFieldForm('idNumber', index);
export const getRelationshipValue = (index) => makeGetFieldForm('relationship', index);
export const getLocationValue = (index) => makeGetFieldForm('location', index);
export const getPhoneNumberValue = (index) => makeGetFieldForm('phoneNumber', index);
export const getEmailValue = (index) => makeGetFieldForm('email', index);
export const getPercentageSplitValue = (index) => makeGetFieldForm('percentageSplit', index);
