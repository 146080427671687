import {financialAdviceValidation} from "./financialAdvice";
import {aboutYouValidation} from "./aboutYou";
import {budgetToolValidation} from "./budgetTool";
import {speakToAnAdviserValidation} from "./speakToAnAdviser";

import {FIN_ADVICE_SET_VALUE} from "../../../actions/atRetirement/financialAdvice";
import {ABOUT_YOU_SET_VALUE} from "../../../actions/atRetirement/aboutYou";
import {BUDGET_TOOL_SET_VALUE} from "../../../actions/atRetirement/budgetTool";
import {SPEAK_TO_AN_ADVISER_SET_VALUE} from "../../../actions/atRetirement/speakToAnAdviser";

export const atRetirementValidation = (store, next, action) => {
    if (action.type.startsWith(BUDGET_TOOL_SET_VALUE))
        return budgetToolValidation(store, next, action);

    if (action.type.startsWith(ABOUT_YOU_SET_VALUE))
        return aboutYouValidation(store, next, action);

    if (action.type.startsWith(FIN_ADVICE_SET_VALUE))
        return financialAdviceValidation(store, next, action);

    if (action.type.startsWith(SPEAK_TO_AN_ADVISER_SET_VALUE))
        return speakToAnAdviserValidation(store, next, action);

    return null;
}