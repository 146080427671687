import { createSelector } from "reselect";
import { getAllsureInsurance } from "./index";
import { FORM_STATUS } from "../../reducers/status";
// import { KMF_CLAIM_PAGE_INDICES } from "../../actions/kaMetlhaFuneralClaim/types";

export const getInsuranceType = createSelector(
    getAllsureInsurance,
  (allsureInsurance) => allsureInsurance.insuranceType
);
export const getInsuranceTypeForm = createSelector(
    getInsuranceType,
  (insuranceType) => insuranceType.form
);

export const getIsInsurenceTypeSelected = (insuranceType)=>createSelector(
    getInsuranceTypeForm,
  (insuranceTypeForm) => insuranceTypeForm[insuranceType].value==='primary'
);

export const getIsInsuranceTypeFormValid=createSelector(getInsuranceType,(insuranceType)=>insuranceType.formStatus===FORM_STATUS.VALID)
