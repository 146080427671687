// Transitions
import {CALL_ME_BACK_TYPES} from "../../reducers/status";

export const TOGGLE_CALL_ME_BACK = 'easiplusFuneralPlan/callMeBack/transition/toggleCallMeBack';

export const toggleCallMeBack = value => ({ type: TOGGLE_CALL_ME_BACK, value });
export const toggleQuoteCallMeBack = () => toggleCallMeBack(CALL_ME_BACK_TYPES.QUOTE);

export const FORM_VALID_TRANSITION_CALL_ME_BACK = 'easiplusFuneralPlan/callMeBack/transition/formValid';
export const FORM_INVALID_TRANSITION_CALL_ME_BACK = 'easiplusFuneralPlan/callMeBack/transition/formInvalid';

export const transitionCallMeBack = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CALL_ME_BACK }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CALL_ME_BACK }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_CALL_ME_BACK = 'easiplusFuneralPlan/callMeBack/transition/resetState';
export const resetCallMeBack = () => ({ type: RESET_STATE_TRANSITION_CALL_ME_BACK });

// Events
export const CALL_ME_BACK_SET_VALUE = 'easiplusFuneralPlan/callMeBack/setValue/';
export const CALL_ME_BACK_SET_VALID = 'easiplusFuneralPlan/callMeBack/setValid/';
export const EMAIL_SEND = 'easiplusFuneralPlan/callMeBack/email/';

// Values
export const NAME_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'name';
export const NAME_SET_VALID = CALL_ME_BACK_SET_VALID + 'name';

export const SURNAME_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = CALL_ME_BACK_SET_VALID + 'surname';

export const CONTACT_NUMBER_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'contactNumber';
export const CONTACT_NUMBER_SET_VALID = CALL_ME_BACK_SET_VALID + 'contactNumber';

export const GTM_CONTEXT_URL_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'gtmContextUrl';

export const GTM_CONTEXT_GA_ID_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'gtmContextGaId';

export const REPLACEMENT_PRODUCT_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'replacementProduct';

export const GENERIC_PRODUCT_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'genericProduct';

export const QUOTE_PRODUCT_SET_VALUE = CALL_ME_BACK_SET_VALUE + 'quoteProduct';

export const CALL_ME_BACK_SEND_INITIATE = CALL_ME_BACK_SET_VALUE + 'initiate'

export const CALL_ME_BACK_SEND = EMAIL_SEND + 'send';
export const CALL_ME_BACK_SEND_SUCCESS = EMAIL_SEND + 'success';
export const CALL_ME_BACK_SEND_FAILURE = EMAIL_SEND + 'failure';

export const setNameValue = value => ({ type: NAME_SET_VALUE, value });
export const setNameValid = error => ({ type: NAME_SET_VALID, error });

export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });

export const setContactNumberValue = value => ({ type: CONTACT_NUMBER_SET_VALUE, value });
export const setContactNumberValid = error => ({ type: CONTACT_NUMBER_SET_VALID, error });

export const setGtmContextUrl = value => ({ type: GTM_CONTEXT_URL_SET_VALUE, value });

export const setGtmContextGaId = value => ({ type: GTM_CONTEXT_GA_ID_SET_VALUE, value });

export const setReplacementProductValue = value => ({ type: REPLACEMENT_PRODUCT_SET_VALUE, value });

export const setGenericProductValue = value => ({ type: GENERIC_PRODUCT_SET_VALUE, value });

export const setQuoteProductValue = value => ({ type: QUOTE_PRODUCT_SET_VALUE, value });

export const sendCallMeBackInitiate = () => ({ type: CALL_ME_BACK_SEND_INITIATE });

export const sendCallMeBack = () => ({ type: CALL_ME_BACK_SEND });
export const sendCallMeBackSuccess = () => ({ type: CALL_ME_BACK_SEND_SUCCESS });
export const sendCallMeBackFailure = () => ({ type: CALL_ME_BACK_SEND_FAILURE });
