import axios from 'axios';
import { makeAClaimEmailFailure, makeAClaimEmailSuccess, setEmailSendingStatusValue } from "../../../actions/makeAClaim";
import {getMakeAClaimForm} from "../../../selectors/makeAClaim";

export const sendMailMakeAClaim = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    (async () => {

        try {
            const response = await sendEmail(state);
            const data = response.data;
            store.dispatch(makeAClaimEmailSuccess(data));
            store.dispatch(setEmailSendingStatusValue(true));
        } catch (e) {
            store.dispatch(makeAClaimEmailFailure(e.message));

        }
    })();
    return result;
};

const sendEmailPayload = (state) => {
    const formData = getMakeAClaimForm(state)
    const isClaim = formData.selectedClaimOrRequest.value === 'Claim'

    const payload = {
        firstName: formData.firstName.value,
        surname: formData.surname.value,
        phoneNumber: formData.phoneNumber.value,
        policyNumber: formData.policyNumber.value,
        emailAddress: formData.emailAddress.value,
        selectedClaimOrRequest: formData.selectedClaimOrRequest.value,
    }

    const claimPayload = {
        ...payload,
        selectedClaimCategories: formData.selectedClaimCategories.value,
        selectedClaimTypes: formData.selectedClaimTypes.value,
    }

    const requestPayload = {
        ...payload,
        selectedRequest: formData.selectedRequest.value,
    }

    return isClaim ? claimPayload : requestPayload
}

const sendEmail = async(state) => {
    try{
        const url = '/om-api/make-a-claim/send-email';
        return await axios.post(url, sendEmailPayload(state));
    } catch (error) {
        console.error(error)
        throw error
    }
};
