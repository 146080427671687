import {
    PERSONAL_DETAILS_SET_VALUE,
    PERSONAL_DETAILS_SET_VALID,
    FORM_INVALID_TRANSITION_PERSONAL_DETAILS,
    FORM_VALID_TRANSITION_PERSONAL_DETAILS,
    PERSONAL_DETAILS_EDIT,
} from '../../actions/allsureInsurance/personalDetails';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initPersonalDetails = {
    formStatus: FORM_STATUS.INVALID,
    isPersonalDetailsEdit: false,
    form: {
        fullName: { value: null, error: null, status: null, displayValue: null },
        countryCodeAllsure: { value: '+267', error: null, status: FORM_STATUS.VALID, displayValue: 'bw' },
        cellphoneNumber: { value: null, error: null, status: null, displayValue: null },
        email: { value: null, error: null, status: FIELD_STATUS.VALID, displayValue: null },
        hearAboutUs: { value: null, error: null, status: null, displayValue: null },
        socialPlatform: { value: null, error: null, status: null, displayValue: null },
        hearAboutUsPlatform: { value: null, error: null, status: null, displayValue: null },
        termsAndConditions: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
        referFriendFamily: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
        referFullName: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
        referCountryCodeAllSure: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
        referCellphoneNumber: { value: null, error: null, status: FORM_STATUS.VALID, displayValue: null },
        insuredDetails: {
            value: null,
            error: null,
            status: null,
            displayValue: null,
        },
        insuredWithOM: {
            value: null,
            error: null,
            status: null,
            displayValue: null,
        },
        policyNumber: {
            value: null,
            error: null,
            status: null,
            displayValue: null,
        },
    },
};
export default (state = initPersonalDetails, action) => {
    const trimField = (value) => (value ? value.trim() : value);
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    // if ( CLAIMENT_DETAILS_SET_VALUE) {
    switch (action.type) {
        case FORM_VALID_TRANSITION_PERSONAL_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_PERSONAL_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case PERSONAL_DETAILS_SET_VALUE:
            if (action.value.fieldType === 'hearAboutUs') {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        hearAboutUs: {
                            ...state.form.hearAboutUs,
                            value: action.value.value,
                            displayValue: action.value?.displayValue ?? action.value.value,
                        },
                        socialPlatform: { value: null, error: null, status: null, displayValue: null },
                        hearAboutUsPlatform: { value: null, error: null, status: null, displayValue: null },
                    },
                };
            }
            if (action.value.fieldType === 'referFriendFamily') {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        referFriendFamily: {
                            ...state.form.referFriendFamily,
                            value: action.value.value,
                            displayValue: action.value?.displayValue ?? action.value.value,
                        },
                        referCellphoneNumber: { value: null, error: null, status: null, displayValue: null },
                        referFullName: { value: null, error: null, status: null, displayValue: null },
                    },
                };
            }
            if (action.value.fieldType==="insuredDetails"){
                return {
                    ...state,
                    form:{
                        ...state.form,
                        insuredDetails:{...state.form.insuredDetails,value:action.value.value,displayValue:action.value?.displayValue??action.value.value},
                        insuredWithOM: {value: null, error: null, status: null,displayValue:null},
                        policyNumber:{value: null, error: null, status: null,displayValue:null}
                    }
                }
            }
            return updateFormField(action.value.fieldType, (field) => ({
                ...field,
                displayValue: action.value.displayValue ?? action.value.value,
                value: action.value.value,
            }));

        case PERSONAL_DETAILS_SET_VALID:
            const { fieldType, error } = action;
            return updateFormField(fieldType, (field) => ({
                ...field,
                error: error,
                status: mapError(error),
            }));
        case PERSONAL_DETAILS_EDIT:
            return { ...state, isPersonalDetailsEdit: action.value };
        default:
            return state;
        // }
    }
};
