import { validationIsEqual } from '../status';
import { validateName, validateContactNumber } from '@om/validation';
import {
	AGENT_NAME_SET_VALUE,
	AGENT_PHONE_NUMBER_SET_VALUE,
	setNameValid,
	setPhoneNumberValid,
} from '../../../actions/roaCarInsurance/agentReferral';

let prevValidationAction = null;

export const agentModalFormValidation = (store, next, action) => {
	const validationAction = (() => {
		let error;
		switch (action.type) {
			case AGENT_NAME_SET_VALUE:
				error = validateName(action.value, {
					min: 2,
					max: 48,
					subject: 'Name',
				});
				return setNameValid(error);

			case AGENT_PHONE_NUMBER_SET_VALUE:
				error = validateContactNumber(action.value, {
					subject: 'Phone Number',
					countryCode: 'ke',
				});
				return setPhoneNumberValid(error);

			default:
				return null;
		}
	})();
	if (
		!validationAction ||
		validationIsEqual(validationAction, prevValidationAction)
	) {
		return next(action);
	}

	store.dispatch(validationAction);
	prevValidationAction = validationAction;
	return next(action);
};
