import { getIsApiPending } from '../../../selectors/domesticInsurance/travelAgentModal';
import { apiTransitionAgents, setAllAgentsValue } from '../../../actions/domesticInsurance/motorAgentModal';
import { getAccessToken } from '../../../selectors/domesticInsurance/payments';
import { getIntermediaryType } from '../../../selectors/domesticInsurance/travelAgentModal';
import axios from 'axios';

export const getAgentsService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending (store.getState())) {
            store.dispatch(apiTransitionAgents.pending());
            try {
                const resp = await getAgents(store.getState());
                store.dispatch(setAllAgentsValue(resp.data.agents));
                store.dispatch(apiTransitionAgents.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionAgents.failure());
            }
        }
    })();
    return next(action);
};

const getAgents = async (state) => {
    const loginData = getAccessToken(state);
    const intermediaryType = getIntermediaryType(state);
    try {
        const url = '/om-api/ke-motor-insurance/agents';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                type: intermediaryType.value,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
