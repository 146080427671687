export const PAST_PERFORMANCE_CALCULATOR = 'pastPerformance/pastPerformanceCalculator/';

export const PAST_PERFORMANCE_CALCULATOR_TRANSITION = PAST_PERFORMANCE_CALCULATOR + 'transition/';
export const FORM_VALID_TRANSITION_CALCULATOR = PAST_PERFORMANCE_CALCULATOR_TRANSITION + 'formValid';
export const FORM_INVALID_TRANSITION_CALCULATOR = PAST_PERFORMANCE_CALCULATOR_TRANSITION + 'formInvalid';

export const transitionPastPerformanceCalculator = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CALCULATOR }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CALCULATOR })
};

export const PAST_PERFORMANCE_CALCULATOR_SET_VALUE = `${PAST_PERFORMANCE_CALCULATOR}setValue`;
export const PAST_PERFORMANCE_CALCULATOR_SET_VALID = `${PAST_PERFORMANCE_CALCULATOR}setValid`;

export const PAST_PERFORMANCE_CALCULATOR_SET_FUND_VALUE = `${PAST_PERFORMANCE_CALCULATOR_SET_VALUE}/fund`;
export const setFund = value => ({type: PAST_PERFORMANCE_CALCULATOR_SET_FUND_VALUE, value});

export const PAST_PERFORMANCE_CALCULATOR_SET_CONTRIBUTION_FREQUENCY_VALUE = `${PAST_PERFORMANCE_CALCULATOR_SET_VALUE}/contributionFrequency`;
export const setContributionFrequency = value => ({type: PAST_PERFORMANCE_CALCULATOR_SET_CONTRIBUTION_FREQUENCY_VALUE, value});

export const PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE = `${PAST_PERFORMANCE_CALCULATOR_SET_VALUE}/investmentAmount`;
export const PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALID = `${PAST_PERFORMANCE_CALCULATOR_SET_VALID}/investmentAmount`;
export const setInvestmentAmount = value => ({type: PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE, value});
export const setInvestmentAmountValid = error => ({type: PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALID, error});

export const PAST_PERFORMANCE_CALCULATOR_SET_INVESTEMENT_TERM_VALUE = `${PAST_PERFORMANCE_CALCULATOR_SET_VALUE}/investmentTerm`;
export const setInvestmentTerm = value => ({type: PAST_PERFORMANCE_CALCULATOR_SET_INVESTEMENT_TERM_VALUE, value});

export const PAST_PERFORMANCE_TRANSITION_CALCULATOR_RESULTS = PAST_PERFORMANCE_CALCULATOR_TRANSITION + 'calculate';
export const pastPerfomanceCalculatorResults = () => ({ type: PAST_PERFORMANCE_TRANSITION_CALCULATOR_RESULTS });

export const PAST_PERFORMANCE_REFERENCE_DATA_SET_VALUE = PAST_PERFORMANCE_CALCULATOR_TRANSITION + 'refData';
export const setRefData = (value) => ({ type: PAST_PERFORMANCE_REFERENCE_DATA_SET_VALUE, value });






