import {
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
    NAVIGATE_TO_DIGITAL_SAVINGS_QUOTE,
    SET_LANDING_PAGE,
    NAVIGATE_TO_LANDING_PAGE,
    NAVIGATE_TO_PERSONAL_DETAILS,
    NAVIGATE_TO_BENEFICIARIES,
    NAVIGATE_TO_UPLOAD_DOCUMENTS,
    NAVIGATE_TO_PAYMENT,
} from '../../actions/digitalSavings/routing';
import { PAGE_INDICES } from '../../actions/digitalSavings/types';

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    landingPage: null,
};

export default (state = initRouting, action) => {
    const setActivePage = (pageIndex) => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };
        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };
        case NAVIGATE_TO_DIGITAL_SAVINGS_QUOTE:
            return setActivePage(PAGE_INDICES.QOUTE);
        case SET_LANDING_PAGE:
            return { ...state, landingPage: action.value };
        case NAVIGATE_TO_LANDING_PAGE:
            return setActivePage(PAGE_INDICES.LANDING_PAGE);
        case NAVIGATE_TO_PERSONAL_DETAILS:
            return setActivePage(PAGE_INDICES.PERSONAL_DETAILS);
        case NAVIGATE_TO_BENEFICIARIES:
            return setActivePage(PAGE_INDICES.BENEFICIARIES);
        case NAVIGATE_TO_UPLOAD_DOCUMENTS:
            return setActivePage(PAGE_INDICES.UPLOAD_DOCUMENTS);
        case NAVIGATE_TO_PAYMENT:
            return setActivePage(PAGE_INDICES.PAYMENT);

        default:
            return state;
    }
};
