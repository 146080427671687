
import {
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateNumber,
} from '@om/validation';

import { setPersonalDetailsFieldValid } from '../../../actions/allsureInsurance/personalDetails';
import { validateEmail, validateEmailAddress } from '@om/validation/src/validators/email-validator';
import { validateAlphaNumericString, validateFullName, validateNumericString } from '@om/validation/src/validators/string-validator';
import { getPersonalDetailsForm } from '../../../selectors/allsureInsurance/personalDetails';

let prevValidationAction = null;

export const personalDetailsValidation = (store, next, action) => {

    const validationAction = (() => {
        const trimValue = (value) => (value ? value.trim() : value);
        const removeWhiteSpacesFromValue = (value) => (value ? value.replace(/ /g, '') : value);
        let error;
        const { countryCodeAllsure,referCountryCodeAllSure,hearAboutUs } = getPersonalDetailsForm(store.getState());
const platforms=['fromSomeone','omEmployee','broker','other']
        switch (action.value.fieldType) {
            case 'fullName':
                error = validateFullName(trimValue(action.value.value), {
                    min: 3,
                    max: 66,
                    subject: 'Full name',
                });
                break;
            case 'cellphoneNumber':
                error = countryCodeAllsure?.displayValue === 'bw' ? validateContactNumber(removeWhiteSpacesFromValue(action.value.value), {
                    countryCode: 'bw',
                    leadingZeroValidation: false,
                }) : (validateNumericString(action.value.value, {
                    min: 9,
                    max: 9,
                    subject: 'Contact Number',
                }));
                break;
            case 'countryCodeAllsure':
                error = validateSingleSelection(action.value.value, 'country code');
                break;
            case 'hearAboutUs':
                error = validateSingleSelection(action.value.value, 'an option');
                break;
            case 'socialPlatform':
                error = validateSingleSelection(action.value.value, 'an option');
                break;
            case 'referFriendFamily':
                error = validateSingleSelection(action.value.value, 'an option');
                break;
            case 'hearAboutUsPlatform':
                error =platforms.includes(hearAboutUs?.displayValue)?validateFullName(trimValue(action.value.value), {
                    min: 3,
                    max: 66,
                    subject: 'Full name',
                }): validateAlphaNumericString(action.value.value, {
                    max: 30,
                    min: 3,
                    subject: 'Text field',
                    required: true
                });
                break;
            case 'email':
                error = action.value.value ? validateEmail(trimValue(action.value.value), {
                    min: 10,
                    max: 60,
                    subject: 'Email address',
                    required: true,
                }) : null;
                break;
            case 'termsAndConditions':
                error = validateSingleSelection(action.value.value, 'terms and conditions');
                break;

            case 'referFullName':
                error = validateFullName(trimValue(action.value.value), {
                    min: 3,
                    max: 66,
                    subject: 'Full name',
                });
                break;
            case 'referCellphoneNumber':
                error = referCountryCodeAllSure?.displayValue === 'bw' ? validateContactNumber(removeWhiteSpacesFromValue(action.value.value), {
                    countryCode: 'bw',
                    leadingZeroValidation: false,
                }) : (validateNumericString(action.value.value, {
                    min: 9,
                    max: 9,
                    subject: 'Contact Number',
                }));
                break;
            case 'insuredDetails':
                error = validateSingleSelection(action.value.value, 'number of times insured');
                break;
            case 'insuredWithOM':
                error = validateSingleSelection(action.value.value, 'number of times with om');
                break;
            case 'policyNumber':
                error=validateNumericString(action.value.value, { min: 5,max:10,subject:'Policy number'});
                break;
           
            default:
                return null;
        }

        return setPersonalDetailsFieldValid(error, action.value.fieldType);
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
