import { initBeneficiaries } from './beneficiaries';
import { initBeneficiariesSplit } from './beneficiariesSplit';
import { initCaptureDetails } from './captureDetails';
import { initInvestmentSetup } from './investmentSetup';
import { initPaymentDetails } from './paymentDetails';
import { initPersonalDetails } from './personalDetails';
import { initReferenceData } from './referenceData';
import { initReview } from './review';
import { initScrolling } from './scrolling';
import { initPersistedAt } from './persistedAt';
import { initSessionCamVariables } from './sessionCamVariables';
import { initRouting } from './routing';

export default () => {
    return {
        personalDetails: initPersonalDetails,
        captureDetails: initCaptureDetails,
        investmentSetup: initInvestmentSetup,
        paymentDetails: initPaymentDetails,
        beneficiaries: initBeneficiaries,
        beneficiariesSplit: initBeneficiariesSplit,
        review: initReview,
        scrolling: initScrolling,
        referenceData: initReferenceData,
        persistedAt: initPersistedAt,
        sessionCamVariables: initSessionCamVariables,
        routing: initRouting,
    }
}