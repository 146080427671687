import {
    EDUCATION_CALCULATOR_MODAL_SET_VALUE,
    EDUCATION_CALCULATOR_SET_VALUE,
} from '../../../actions/educationCalculator/calculator';
import { educationCalculatorFormValidation } from './calculator';
import { educationCalculatorModalValidation } from './modal';

export const educationCalculatorValidation = (store, next, action) => {
    if (action.type.startsWith(EDUCATION_CALCULATOR_SET_VALUE)) {
        return educationCalculatorFormValidation(store, next, action);
    }

    if (action.type.startsWith(EDUCATION_CALCULATOR_MODAL_SET_VALUE)) {
        return educationCalculatorModalValidation(store, next, action);
    }

    return null;
};
