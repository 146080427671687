import { validationIsEqual } from '../status';
import {
    validateDateOfBirth,
    validateEmail,
    validateName,
    validateContactNumber,
    validateSingleSelection,
} from '@om/validation';
import {
    TITLE_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    OTHER_NAMES_SET_VALUE,
    EMAIL_SET_VALUE,
    DOB_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    setTitleValid,
    setFirstNameValid,
    setSurnameValid,
    setEmailValid,
    setPhoneNumberValid,
    setDOBValid,
} from '../../../actions/roaTravelInsurance/aboutYou';

let prevValidationAction = null;
export const aboutYouValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Title',
                });
                return setTitleValid(error);

            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });
                return setFirstNameValid(error);

            case OTHER_NAMES_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Surname',
                });
                return setSurnameValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, 'Phone Number');
                return setPhoneNumberValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 15, max: 85 });
                return setDOBValid(error);

            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
