import { validationIsEqual } from "../status";
import {
    validateFirstName,
    validateSurname,
    validateContactNumber,
} from "@om/validation";
import {
    NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    CONTACT_NUMBER_SET_VALUE,
    setNameValid,
    setSurnameValid,
    setContactNumberValid
} from "../../../actions/customerVerificationService/callMeBack";

let prevValidationAction = null;
export const callMeBackValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        
        switch (action.type) {
            case NAME_SET_VALUE:
                error = validateFirstName(action.value);
                return setNameValid(error);
            case SURNAME_SET_VALUE:
                error = validateSurname(action.value);
                return setSurnameValid(error);
            case CONTACT_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {subject: 'Phone Number'});
                return setContactNumberValid(error, action.countryCode);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};