const ABOUT_YOU = 'roaCarInsurance/aboutYou/';
export const ABOUT_YOU_SET_VALUE = ABOUT_YOU + 'setValue';
export const ABOUT_YOU_SET_VALID = ABOUT_YOU + 'setValid';
export const FORM_VALID_ABOUT_YOU = ABOUT_YOU + 'transition/formStateValid';
export const FORM_INVALID_ABOUT_YOU = ABOUT_YOU + 'transition/formStateInValid';

//for Nigeria
export const ABOUT_YOU_NG_SET_VALUE = ABOUT_YOU + 'setValueNg';
export const ABOUT_YOU_NG_SET_VALID = ABOUT_YOU + 'setValidNg';

export const FIRST_NAME_SET_VALUE = ABOUT_YOU_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = ABOUT_YOU_SET_VALID + 'firstName';

export const LAST_NAME_SET_VALUE = ABOUT_YOU_SET_VALUE + 'lastName';
export const LAST_NAME_SET_VALID = ABOUT_YOU_SET_VALID + 'lastName';

export const EMAIL_ADDRESS_SET_VALUE = ABOUT_YOU_SET_VALUE + 'emailAddress';
export const EMAIL_ADDRESS_SET_VALID = ABOUT_YOU_SET_VALID + 'emailAddress';

export const PHONE_NUMBER_SET_VALUE = ABOUT_YOU_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = ABOUT_YOU_SET_VALID + 'phoneNumber';

//for Nigeria
export const STATE_OF_ORIGIN_SET_VALUE = ABOUT_YOU_SET_VALUE + 'stateOfOrigin';
export const STATE_OF_ORIGIN_SET_VALID = ABOUT_YOU_SET_VALID + 'stateOfOrigin';

export const LOCAL_AREA_SET_VALUE = ABOUT_YOU_SET_VALUE + 'localArea';
export const LOCAL_AREA_SET_VALID = ABOUT_YOU_SET_VALID + 'localArea';

export const RESIDENTIAL_AREA_SET_VALUE = ABOUT_YOU_SET_VALUE + 'residentialArea';
export const RESIDENTIAL_AREA_SET_VALID = ABOUT_YOU_SET_VALID + 'residentialArea';

export const AGENT_CODE_SET_VALUE = ABOUT_YOU_SET_VALUE + 'agentCode';
export const AGENT_CODE_SET_VALID = ABOUT_YOU_SET_VALID + 'agentCode';

// Events - form input action creators
export const setFirstNameValue = (value) => ({
    type: FIRST_NAME_SET_VALUE,
    value,
});
export const setFirstNameValid = (error) => ({
    type: FIRST_NAME_SET_VALID,
    error,
});

export const setLastNameValue = (value) => ({
    type: LAST_NAME_SET_VALUE,
    value,
});
export const setLastNameValid = (error) => ({
    type: LAST_NAME_SET_VALID,
    error,
});

export const setEmailAddressValue = (value) => ({
    type: EMAIL_ADDRESS_SET_VALUE,
    value,
});
export const setEmailAddressValid = (error) => ({
    type: EMAIL_ADDRESS_SET_VALID,
    error,
});

export const setPhoneNumberValue = (value, countryCode) => ({
    type: PHONE_NUMBER_SET_VALUE,
	countryCode,
    value,
});
export const setPhoneNumberValid = (error, countryCode) => ({
    type: PHONE_NUMBER_SET_VALID,
	countryCode,
    error,
});

//for Nigeria
export const setStateOfOriginValue = (value) => ({
	type: STATE_OF_ORIGIN_SET_VALUE,
	value,
});
export const setStateOfOriginValid = (error) => ({
	type: STATE_OF_ORIGIN_SET_VALID,
	error,
});

export const setLocalGovernmentAreaValue = (value) => ({
	type: LOCAL_AREA_SET_VALUE,
	value,
});
export const setLocalGovernmentAreaValid = (error) => ({
	type: LOCAL_AREA_SET_VALID,
	error,
});

export const setResidentialAreaValue = (value) => ({
	type: RESIDENTIAL_AREA_SET_VALUE,
	value,
});
export const setResidentialAreaValid = (error) => ({
	type: RESIDENTIAL_AREA_SET_VALID,
	error,
});

export const setAgentCodeValue = (value) => ({
	type: AGENT_CODE_SET_VALUE,
	value,
});
export const setAgentCodeValid = (error) => ({
	type: AGENT_CODE_SET_VALID,
	error,
});

export const setPhoneNumberNgValue = (value) => ({
	type: PHONE_NUMBER_NG_SET_VALUE,
	value,
});
export const setPhoneNumberNgValid = (error) => ({
	type: PHONE_NUMBER_NG_SET_VALID,
	error,
});

export const transitionAboutYouForm = {
	formValid: () => ({type: FORM_VALID_ABOUT_YOU}),
	formInvalid: () => ({type: FORM_INVALID_ABOUT_YOU})
}

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_ABOUT_YOU_FORM =
	'roaCarInsurance/aboutYouForm/transition/dropOffLead';
export const dropOffLeadPersonalDetails = value => ({
	type: DROP_OFF_LEAD_TRANSITION_ABOUT_YOU_FORM,
	value,
})
