import {FETCH_PRICE_TABLE_FILTERED_FUNDS} from "../../../actions/priceTable/table";
import {getFundsForApplicableDateService} from "./table";

export const priceTableService = (store, next, action) => {

    if (action.type === FETCH_PRICE_TABLE_FILTERED_FUNDS) {
        return getFundsForApplicableDateService(store, next, action);
    }

    return null;
};
