import {createSelector} from "reselect";
import {
    FORM_STATUS,
} from "../../reducers/status";

export const getUnclaimedBenefits = (state => {
    return state.publicWeb.unclaimedBenefits
});

export const getUnclaimedBenefitsForm = createSelector(
    getUnclaimedBenefits,
    unclaimedBenefits => unclaimedBenefits.form
);

export const getUnclaimedBenefitsFormValid = createSelector(
    getUnclaimedBenefits,
    unclaimedBenefits => unclaimedBenefits.formStatus === FORM_STATUS.VALID
);

export const getUserFoundStatus = createSelector(
    getUnclaimedBenefits,
    unclaimedBenefits => unclaimedBenefits.userFound
)

export const getVerifyingStatus = createSelector(
    getUnclaimedBenefits,
    unclaimedBenefits => unclaimedBenefits.verifyingStatus
)

const makeGetField = fieldId => createSelector(
    getUnclaimedBenefitsForm,
    form => form[fieldId]
);

export const getInitials = makeGetField('initials');
export const getSurname = makeGetField('surname');
export const getDOB = makeGetField('dob');
