import {
  setPostalCityValue,
  setPostalCountryValue,
  setPostalStreetAddressValue,
  transitionPersonalDetails,
  setTitleValue,
  setFirstNamesValue,
  setSurnameValue,
  setIdValue,
  setMobileValue,
  setEmailValue,
  setStreetAddressValue,
  setCityValue,
  setCountryValue,
  setDistrictValue,
  setIdExpiryValue,
  setIncomeSourceTextValue,
  setPostalDistrictValue,
} from "../../../actions/digiLifeLoanProtection/personalDetails";
import {
  FIELD_STATUS,
  POSTAL_ADDRESS_METHOD,
  TOGGLE_STATUS,
} from "../../../reducers/status";
import {
  getPersonalDetailsForm,
  getPostalAddressMethod,
  getPersonalDetailsValid,
} from "../../../selectors/digiLifeLoanProtection/personalDetails";
import {
  DLP_PERSONAL_DETAILS_SCROLL_ID,
} from "../status";
import { transitionScrolling } from "../../../actions/digiLifeLoanProtection/scrolling";
import {
  setBankValue,
  setEducationValue,
  setEmploymentTypeValue,
  setGenderValue,
  setIncomeSourceValue,
  setLoanAmountValue,
  setLoanTermValue,
  setLoanTypeValue,
  setOccupationValue,
  setPaymentFrequencyValue,
} from "../../../actions/digiLifeLoanProtection/personalDetails";

export const personalDetailsRules = (store, next, action) => {
  const result = next(action);
  const state = store.getState();
  // const personalDetailsValid = getPersonalDetailsValid(store.getState());

  // Personal Information
  const isValid = isPersonalDetailsValid(store);
  store.dispatch(
    isValid
      ? transitionPersonalDetails.formValid()
      : transitionPersonalDetails.formInvalid()
  );

  return result;
};

// Personal Information
const isPersonalDetailsValid = (store) => {
  const state = store.getState();
  const formFields = getPersonalDetailsForm(state);
  // const personalDetailsPostalAddressMethod = getPostalAddressMethod(state);
  // const personalDetailsPostalAddressSame = formFields.postalAddressSame.value;
  const isValid = (field) => {
    return field && field.status === FIELD_STATUS.VALID;
  };
  // personal details
  const personalDetailsFormFields = [
    formFields.title,
    formFields.firstNames,
    formFields.surname,
    formFields.id,
    formFields.idExpiry,
    formFields.education,
    formFields.mobileNumber,
    formFields.email,

    formFields.streetAddress,
    formFields.city,
    formFields.district,
    formFields.country,

    formFields.postalStreetAddress,
    formFields.postalCity,
    formFields.postalDistrict,
    formFields.postalCountry,

    formFields.loanAmount,
    formFields.loanType,
    formFields.bank,
    formFields.loanTerm,

    formFields.employmentType,
    formFields.occupation,
    formFields.sourceOfIncome,
    formFields.sourceOfIncomeText,

    formFields.paymentFrequency,
  ];
  const personalDetailsToBeValidated=formFields.sourceOfIncome.value==='06'?[...personalDetailsFormFields,formFields.sourceOfIncomeText]:personalDetailsFormFields
  const personalDetailsValid = personalDetailsFormFields.every(isValid)
  return personalDetailsValid;
};

export const performPersonalDetailsValidation = (store) => {
  const state = store.getState();

  const personalDetailsForm = getPersonalDetailsForm(state);
  store.dispatch(
    setTitleValue({
      id: personalDetailsForm.title.value,
      value: personalDetailsForm.title.displayValue,
    })
  );

  store.dispatch(setFirstNamesValue(personalDetailsForm.firstNames.value));
  store.dispatch(setSurnameValue(personalDetailsForm.surname.value));
  store.dispatch(setGenderValue({
    id: personalDetailsForm.gender.value,
    value: personalDetailsForm.gender.displayValue,
  }));

  store.dispatch(setIdValue(personalDetailsForm.id.value));
  store.dispatch(setIdExpiryValue(personalDetailsForm.idExpiry.value));

  store.dispatch(
    setEducationValue({
      id: personalDetailsForm.education.value,
      value: personalDetailsForm.education.displayValue,
    })
  );

  store.dispatch(setMobileValue(personalDetailsForm.mobileNumber.value));
  store.dispatch(setEmailValue(personalDetailsForm.email.value));

  store.dispatch(
    setStreetAddressValue(personalDetailsForm.streetAddress.value)
  );
  store.dispatch(setCityValue(personalDetailsForm.city.value));
  store.dispatch(
    setDistrictValue({
      id: personalDetailsForm.district.value,
      value: personalDetailsForm.district.displayValue,
    })
  );
  store.dispatch(setCountryValue(personalDetailsForm.country.value));

  store.dispatch(
    setPostalStreetAddressValue(personalDetailsForm.postalStreetAddress.value)
  );
  store.dispatch(setPostalCityValue(personalDetailsForm.postalCity.value));
  store.dispatch(
    setPostalCountryValue(personalDetailsForm.postalCountry.value)
  );
  store.dispatch(
    setPostalDistrictValue({
      id: personalDetailsForm.postalDistrict.value,
      value: personalDetailsForm.postalDistrict.displayValue,
    })
  );
  store.dispatch(setLoanAmountValue(personalDetailsForm.loanAmount.value));
  store.dispatch(
    setLoanTypeValue({
      id: personalDetailsForm.loanType.value,
      value: personalDetailsForm.loanType.displayValue,
    })
  );
  store.dispatch(
    setBankValue({
      id: personalDetailsForm.bank.value,
      value: personalDetailsForm.bank.displayValue,
    })
  );
  store.dispatch(setLoanTermValue(personalDetailsForm.loanTerm.value));

  store.dispatch(
    setEmploymentTypeValue({
      id: personalDetailsForm.employmentType.value,
      value: personalDetailsForm.employmentType.displayValue,
    })
  );
  store.dispatch(setOccupationValue(personalDetailsForm.occupation.value));
  store.dispatch(
    setIncomeSourceValue({
      id: personalDetailsForm.sourceOfIncome.value,
      value: personalDetailsForm.sourceOfIncome.displayValue,
    })
  );
  personalDetailsForm.sourceOfIncome.value==='06'&&store.dispatch(setIncomeSourceTextValue(personalDetailsForm.sourceOfIncomeText.value));


  store.dispatch(
    setPaymentFrequencyValue({
      id: personalDetailsForm.paymentFrequency.value,
      value: personalDetailsForm.paymentFrequency.displayValue,
    })
  );

  setScrollError(store);
};

export const setScrollError = (store) => {
  const state = store.getState();
  const fieldKeyArr = DLP_PERSONAL_DETAILS_SCROLL_ID;
  const personalDetailsForm = getPersonalDetailsForm(state);
  const firstErrorField = fieldKeyArr.find(
    (fieldKey) =>
      fieldKey in personalDetailsForm && personalDetailsForm[fieldKey].error
  );

  store.dispatch(transitionScrolling.scrollToId(firstErrorField));
};
