import {validateInteger, validateAge, validateSingleSelection} from  "@om/validation";
import {validationIsEqual} from "../status";
import {
    AGE_SET_VALUE,
    MONTHLY_INVESTMENT_SET_VALUE,
    OWN_PERCENTAGE_SET_VALUE,
    RETIRE_AT_SET_VALUE,
    setAgeValid,
    setOwnPercentageValid,
    setMonthlyInvestmentValid,
    setRetireAtValid,
} from "../../../actions/raOptimal/investmentSetup";
import { getMonthlyInvestmentCommittedTerm,
    getMonthlyInvestmentDerivedTerm
} from "../../../selectors/raOptimal/investmentSetup";

export const referenceMonthlyContributions = [
    {committedTerm: 5, minimumMonthlyPayments: "2 000"},
    {committedTerm: 6, minimumMonthlyPayments: "2 000"},
    {committedTerm: 7, minimumMonthlyPayments: "2 000"},
    {committedTerm: 8, minimumMonthlyPayments: "2 000"},
    {committedTerm: 9, minimumMonthlyPayments: "2 000"},
    {committedTerm: 10, minimumMonthlyPayments: 650},
    {committedTerm: 11, minimumMonthlyPayments: 650},
    {committedTerm: 12, minimumMonthlyPayments: 650},
    {committedTerm: 13, minimumMonthlyPayments: 650},
    {committedTerm: 14, minimumMonthlyPayments: 650},
    {committedTerm: 15, minimumMonthlyPayments: 650},
    {committedTerm: 16, minimumMonthlyPayments: 650},
    {committedTerm: 17, minimumMonthlyPayments: 650},
    {committedTerm: 18, minimumMonthlyPayments: 650},
    {committedTerm: 19, minimumMonthlyPayments: 650},
    {committedTerm: 20, minimumMonthlyPayments: 650},
    {committedTerm: 21, minimumMonthlyPayments: 500},
    {committedTerm: 22, minimumMonthlyPayments: 500},
    {committedTerm: 23, minimumMonthlyPayments: 500},
    {committedTerm: 24, minimumMonthlyPayments: 500},
    {committedTerm: 25, minimumMonthlyPayments: 500}
];

let prevValidationAction = null;
export const investmentSetupValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AGE_SET_VALUE:
                error = validateAge(action.value, { min: 1, max: 75 });
                return setAgeValid(error);

            case RETIRE_AT_SET_VALUE:
                error = validateSingleSelection(action.value, 'Retirement age');
                return setRetireAtValid(error);

            case MONTHLY_INVESTMENT_SET_VALUE:
                const committedTerm = getMonthlyInvestmentCommittedTerm(store.getState());
                const derivedTerm = getMonthlyInvestmentDerivedTerm(store.getState());
                const minimumContribution = getMinimumContribution(committedTerm);
                error = validateInteger(action.value, {
                    required: true,
                    min: getMinimumContributionNumber(minimumContribution)
                });

                if (error && error.includes('greater')) error = "For a period of " + derivedTerm + " years, the minimum is R" + minimumContribution + " monthly."
                return setMonthlyInvestmentValid(error);

            case OWN_PERCENTAGE_SET_VALUE:
                error = validateSingleSelection(action.value, 'percentage');
                return setOwnPercentageValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};

const getMinimumContribution = (committedTerm) => {
    const relevantCategory =  referenceMonthlyContributions.filter((field) => { return field.committedTerm === committedTerm});
    return relevantCategory[0].minimumMonthlyPayments;
};

const getMinimumContributionNumber = (minimumContribution) => {
    return typeof minimumContribution === 'string' ? Number(minimumContribution.split(" ").join("")) : minimumContribution ;
}
