import { FIELD_STATUS, API_CALL_STATUS } from "../../../reducers/status";
import { getCallMeBackForm, getSendCallMeBackStatus } from "../../../selectors/easiplusFuneralPlan/callMeBack"
import { CALL_ME_BACK_SEND_INITIATE, transitionCallMeBack, setNameValue, setSurnameValue, setContactNumberValue, sendCallMeBack } from "../../../actions/easiplusFuneralPlan/callMeBack"

export const callMeBackRules = (store, next, action) => {
    const result = next(action);

    const callMeBackForm = getCallMeBackForm(store.getState());
    const callMeBackFormIsValid = validateCallMeBackForm(callMeBackForm);
    const sendCallMeBackStatus = getSendCallMeBackStatus(store.getState());

    if (action.type === CALL_ME_BACK_SEND_INITIATE) {
        (callMeBackFormIsValid && sendCallMeBackStatus !== API_CALL_STATUS.PENDING) ? 
            store.dispatch(sendCallMeBack()) :
            setValuesForValidation(store, callMeBackForm);
    } else {
        store.dispatch( 
            callMeBackFormIsValid ? 
                transitionCallMeBack.formValid() :
                transitionCallMeBack.formInvalid(store)
        );
    }

    return result;
};

const validateCallMeBackForm = formFields => {
    return (
        formFields.name.status === FIELD_STATUS.VALID && 
        formFields.surname.status === FIELD_STATUS.VALID && 
        formFields.contactNumber.status === FIELD_STATUS.VALID
    );
};

const setValuesForValidation = (store, callMeBackForm) => {
    store.dispatch(setNameValue(callMeBackForm.name.value));
    store.dispatch(setSurnameValue(callMeBackForm.surname.value));
    store.dispatch(setContactNumberValue(callMeBackForm.contactNumber.value));
};
