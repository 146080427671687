import { validateAlphaNumericString, validateBase64File } from '@om/validation';

import {
    CURRENT_LICENSE_COPY_SET_VALUE,
    RESUME_COPY_SET_VALUE,
    NATIONAL_ID_COPY_SET_VALUE,
    ACADEMIC_CERTIFICATES_COPY_SET_VALUE,
    setCurrentLicenseCopyValid,
    setResumeCopyValid,
    setNationalIdValid,
    setAcademicCertificateCopyValid,
    KRA_PIN_COPY_SET_VALUE,
    setKraPinValid,
} from '../../../actions/doctorsPi/documents';

import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const uploadDocumentsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case CURRENT_LICENSE_COPY_SET_VALUE:
                error = validateBase64File(action.value?.base64File, {
                    subject: 'Current License',
                    required: true,
                    max: 5,
                });
                return setCurrentLicenseCopyValid(error);

            case RESUME_COPY_SET_VALUE:
                error = validateBase64File(action.value?.base64File, {
                    subject: 'Resume',
                    required: true,
                });
                return setNationalIdValid(error);

            case NATIONAL_ID_COPY_SET_VALUE:
                error = validateBase64File(action.value?.base64File, {
                    subject: 'National Id',
                    required: true,
                });
                return setResumeCopyValid(error);
            case ACADEMIC_CERTIFICATES_COPY_SET_VALUE:
                error = validateBase64File(action.value?.base64File, {
                    subject: 'Academic Cerificates',
                    required: true,
                });
                return setAcademicCertificateCopyValid(error);
            case KRA_PIN_COPY_SET_VALUE:
                error = validateBase64File(action.value?.base64File, {
                    subject: 'KRA PIN',
                    required: true,
                });
                return setKraPinValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
