import { PAGE_INDICES } from '../../actions/personalAccident/types';
import {
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
    NAVIGATE_TO_GET_QUOTE,
    NAVIGATE_TO_LIVES_COVERED_PAGE,
    NAVIGATE_TO_DOCUMENTS,
    NAVIGATE_TO_PAYMENT,
} from '../../actions/personalAccident/routing';

export const initRouting = {
    activePageIndex: 0,
    doNavigation: false,
    doExitNavigation: false,
    landingPage: null,
};

export default (state = initRouting, action) => {
    const setActivePage = (pageIndex) => ({
        ...state,
        activePageIndex: pageIndex,
        doNavigation: true,
    });

    switch (action.type) {
        case SET_ACTIVE_PAGE_INDEX:
            return {
                ...state,
                doNavigation: true,
                activePageIndex: action.value,
            };
        case SET_NAVIGATION_DONE:
            return { ...state, doNavigation: false };
        case NAVIGATE_TO_GET_QUOTE:
            return setActivePage(PAGE_INDICES.QUOTE);
        case NAVIGATE_TO_LIVES_COVERED_PAGE:
            return setActivePage(PAGE_INDICES.LIVES_COVERED);
        case NAVIGATE_TO_DOCUMENTS:
            return setActivePage(PAGE_INDICES.UPLOAD_DOCUMENTS);
        case NAVIGATE_TO_PAYMENT:
            return setActivePage(PAGE_INDICES.PAYMENT);

        default:
            return state;
    }
};
