import {
    DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE,
    CALCULATE_DIVIDENDS,
} from '../../../actions/publicWeb/dividendCalculator'
import { getDividendsTotal } from '../../../selectors/publicWeb/dividendCalculator'
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const dividendCalculatorTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;

    const field = (() => {
        switch (action.type) {
            case DIVIDEND_CALC_SELECTED_COUNTRY_SET_VALUE: 
                otherEventIndex = findOtherEventIndex("SELECTED_COUNTRY");    
                return action.value.title;
            case CALCULATE_DIVIDENDS: 
                otherEventIndex = findOtherEventIndex("TOTAL_DIVEDENDS");
                return getDividendsTotal(state);
        }
    })();

    if(field){
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}