import { validateNumber } from "@om/validation";
import {validationIsEqual} from "../status";
import {
    DIVIDEND_CALC_SHARES_SET_VALUE,
    setSharesValid,
} from '../../../actions/publicWeb/dividendCalculator';

let prevValidationAction = null;

export const dividendCalculatorValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case DIVIDEND_CALC_SHARES_SET_VALUE:
                error = validateNumber(action.value, {min: 1, max: 99999999, subject: "number"});
                return setSharesValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
