import { validateDateOfBirth, validateNumber } from "@om/validation";
import {
    setDOBValid,
    DOB_SET_VALUE,
    setCoveredUpValid,
    COVERED_UP_SET_VALUE,
    setYearsValid,
    YEAR_SET_VALUE
} from "../../../actions/roaQuoteTools/criticalIllnessQuoteTool";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const CriticalIllnessToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case COVERED_UP_SET_VALUE:
                error = validateNumber(action.value, { min: 200000, max: 15000000, pronoun: 'CoveredUp Value' });
                return setCoveredUpValid(error);
            case YEAR_SET_VALUE:
                error = validateNumber(action.value, { min: 10, max: 15, pronoun: 'YearUp Value' });
                return setYearsValid(error);
            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 15, max: 55 });
                return setDOBValid(error);


            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};