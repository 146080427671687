import { FIELD_STATUS, FORM_STATUS } from "../status";

import {
    FORM_VALID_RETIREMENT_CALCULATOR_FORM_TRANSITION,
    FORM_INVALID_RETIREMENT_CALCULATOR_FORM_TRANSITION,
    AGE_SET_VALUE,
    AGE_SET_VALID,
    MONTHLY_INCOME_SET_VALUE,
    MONTHLY_INCOME_SET_VALID,
    CONTRIBUTION_AMOUNT_SET_VALUE,
    CONTRIBUTION_AMOUNT_SET_VALID,
    AMOUNT_SAVED_SET_VALUE,
    AMOUNT_SAVED_SET_VALID,
    PRODUCT_RECOMMENDATION_SET_VALUE,
    REINITIALIZE_STATE_RETIREMENT_CALCULATOR_FORM,
    TOGGLE_SHOW_PRODUCT_OFFERING,
    ESTIMATED_RETIREMENT_INCOME_SET_VALUE,
    REQUIRED_INCOME_SET_VALUE,
    RESET_CALCULATOR_FORM,
    RETIREMENT_CALCULATOR_OPEN_MODAL,
    RETIREMENT_CALCULATOR_CLOSE_MODAL,
    RETIREMENT_CALCULATOR_SHOW_RESULT_SUMMARY
} from '../../actions/retirementCalculator/retirementCalculatorForm'

const retirmentCalculatorForm = {
    age : {value: null, error: null, status: null},
    monthlyIncome : {value: null, error: null, status: null},
    contributionAmount : {value: null, error: null, status: null},
    amountSaved : {value: null, error: null, status: null},
}

const initialState = {
    formStatus: FORM_STATUS.INVALID,
    showRecommendation: false,
    calculationResults: {
        monthlyRetirementIncome: null,
        requiredIncome: null,
        estimatedRetirementIncome: null,
    },
    form: retirmentCalculatorForm,
    isContributingTowardsRetirement: false,
    openModal: false,
    showResultSummary: false
};

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch(action.type) {
        // Form Validation
         case FORM_VALID_RETIREMENT_CALCULATOR_FORM_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_RETIREMENT_CALCULATOR_FORM_TRANSITION:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Date Of Birth

        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Monthly Income

        case MONTHLY_INCOME_SET_VALUE:
            return updateFormField('monthlyIncome', field => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_INCOME_SET_VALID:
            return updateFormField('monthlyIncome', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Contribution Amount

        case CONTRIBUTION_AMOUNT_SET_VALUE:
            return updateFormField('contributionAmount', field => ({
                ...field,
                value: action.value,
            }));
        case CONTRIBUTION_AMOUNT_SET_VALID:
            return updateFormField('contributionAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Amount Saved

        case AMOUNT_SAVED_SET_VALUE:
            return updateFormField('amountSaved', field => ({
                ...field,
                value: action.value,
            }));
        case AMOUNT_SAVED_SET_VALID:
            return updateFormField('amountSaved', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Get results
        case ESTIMATED_RETIREMENT_INCOME_SET_VALUE:
            return{
                ...state,
                estimatedRetirementIncome: action.value
            }
        case REQUIRED_INCOME_SET_VALUE:
            return{
                ...state,
                requiredIncome: action.value
            }

        case RETIREMENT_CALCULATOR_OPEN_MODAL:
            return{
                ...state,
                openModal: true,
            }

        case RETIREMENT_CALCULATOR_CLOSE_MODAL:
            return{
                ...state,
                openModal: false,
            }

        // reset form
        case RESET_CALCULATOR_FORM:
            return {
                formStatus: FORM_STATUS.INVALID,
                showRecommendation: false,
                calculationResults: {
                    monthlyRetirementIncome: null,
                    requiredIncome: null,
                    estimatedRetirementIncome: null,
                },
                form: retirmentCalculatorForm,
                isContributingTowardsRetirement: false,
                openModal: false,
                showResultSummary: false
            }


        // Product recommendation
        case PRODUCT_RECOMMENDATION_SET_VALUE:
            return {
                ...state,
                showRecommendation: true
            };

        // Show result summary
        case RETIREMENT_CALCULATOR_SHOW_RESULT_SUMMARY:
            return {
                ...state,
                showResultSummary: true
            };

        // reinitialise state
        case REINITIALIZE_STATE_RETIREMENT_CALCULATOR_FORM:
            return initialState;

        case TOGGLE_SHOW_PRODUCT_OFFERING:
            return {
                ...state,
                showRecommendation: !state.showRecommendation,
            };

        default:
            return state;
    }
}
