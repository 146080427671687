import { createSelector } from 'reselect';
import { getUgMotorInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getEmploymentDetails = createSelector(getUgMotorInsuranceState, (state) => state.employmentDetails);

export const getEmploymentDetailsForm = createSelector(
    getEmploymentDetails,
    (employmentDetails) => employmentDetails.form,
);

export const getEmploymentDetailsFormValid = createSelector(
    getEmploymentDetails,
    (employmentDetails) => employmentDetails.formStatus === FORM_STATUS.VALID,
);

const makeGetFieldForm = (fieldId) => createSelector(getEmploymentDetailsForm, (form) => form[fieldId]);

export const getEmploymentStatusValue = makeGetFieldForm('employmentStatus');
export const getEmployerNameValue = makeGetFieldForm('employerName');
export const getSchemeNameValue = makeGetFieldForm('schemeName');
export const getOccupationValue = makeGetFieldForm('occupation');
export const getIndustryOrSectorValue = makeGetFieldForm('industryOrSector');
export const getContractEndDateValue = makeGetFieldForm('contractEndDate');
export const getEmploymentNumberValue = makeGetFieldForm('employmentNumber');
export const getSalaryRangeValue = makeGetFieldForm('salaryRange');
export const getEmployerPostalAddress = makeGetFieldForm('employerPostalAddress');
export const getEmployerTownOrCityValue = makeGetFieldForm('employerTownOrCity');
export const getEmployerPhoneNumberValue = makeGetFieldForm('employerPhoneNumber');
export const getPersonalDataTerms = makeGetFieldForm('personalDataTerms');
export const getPersonalDataProcessingConsent = makeGetFieldForm('personalDataProcessingConsent');
export const getChildDataProcessingConsent = makeGetFieldForm('childDataProcessingConsent');
export const getMarketingUseConsent = makeGetFieldForm('marketingUseConsent');
