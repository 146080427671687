import {validationIsEqual} from "../status";
import {
    validateContactNumber,
    validateEmail, validateLandline,
    validateFirstName, validateSurname, validatePostalCode, validateAlphaNumericString,
    validateSingleSelection
} from "@om/validation";
import {
    CALL_ME_BACK_ADDITIONAL_NUMBER_VALUE,
    CALL_ME_BACK_CITY_VALUE,
    CALL_ME_BACK_EMAIL_VALUE, CALL_ME_BACK_NAME_VALUE,
    CALL_ME_BACK_PHONE_NUMBER_VALUE, CALL_ME_BACK_POSTAL_CODE_VALUE,
    CALL_ME_BACK_SURNAME_VALUE, CALL_ME_BACK_LOCATION_VALUE, CALL_ME_BACK_LOCATION_COMMENTS_VALUE,
    setAdditionalNumberValid, setCityValid, setEmailValid,
    setNameValid, setPhoneNumberValid, setPostalCodeValid, setSurnameValid, setLocationValid, setLocationCommentsValueValid
} from "../../../actions/publicWeb/callMeBack";

let prevValidationAction = null;
export const callMeBackValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case CALL_ME_BACK_NAME_VALUE:
                error = validateFirstName(action.value);
                return setNameValid(error);
            case CALL_ME_BACK_SURNAME_VALUE:
                error = validateSurname(action.value);
                return setSurnameValid(error);
            case CALL_ME_BACK_PHONE_NUMBER_VALUE:
                error = validateContactNumber(action.value, {subject: 'Phone Number', countryCode: action.countryCode});
                return setPhoneNumberValid(error, action.countryCode);
            case CALL_ME_BACK_EMAIL_VALUE:
                error = validateEmail(action.value);
                return setEmailValid(error);
            case CALL_ME_BACK_POSTAL_CODE_VALUE:
                error = validatePostalCode(action.value, {required: false});
                return setPostalCodeValid(error);
            case CALL_ME_BACK_ADDITIONAL_NUMBER_VALUE:
                error = validateContactNumber(action.value, {subject: 'Phone Number', countryCode: action.countryCode});
                return setAdditionalNumberValid(error, action.countryCode);
            case CALL_ME_BACK_CITY_VALUE:
                error = validateAlphaNumericString(action.value, {subject: 'City'});
                return setCityValid(error);
            case CALL_ME_BACK_LOCATION_COMMENTS_VALUE:
                error = validateAlphaNumericString(action.value, {required: false});
                return setLocationCommentsValueValid(error);
            case CALL_ME_BACK_LOCATION_VALUE:
                error = validateSingleSelection(action.value, 'location');
                return setLocationValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
