// Transitions
export const TOGGLE_BUDGET_TOOL = 'atRetirement/aboutYou/transition/toggleBudgetTool';
export const TOGGLE_USE_OF_PERSONAL_INFORMATION = 'atRetirement/aboutYou/transition/toggleUseOfPersonalInformation';
export const ABOUT_YOU_RENDER_TRACKING_VALUE =  'atRetirement/aboutYou/transition/renderTracking';

export const FORM_VALID_TRANSITION_PERSONAL_INFORMATION = 'atRetirement/aboutYou/transition/formPersonalInformationValid';
export const FORM_INVALID_TRANSITION_PERSONAL_INFORMATION = 'atRetirement/aboutYou/transition/formPersonalInformationInvalid';

export const FORM_VALID_TRANSITION_RETIREMENT_WORRIES = 'atRetirement/aboutYou/transition/formRetirementWorriesValid';
export const FORM_INVALID_TRANSITION_RETIREMENT_WORRIES = 'atRetirement/aboutYou/transition/formRetirementWorriesInvalid';

export const FORM_VALID_TRANSITION_FINANCIAL = 'atRetirement/aboutYou/transition/formFinancialValid';
export const FORM_INVALID_TRANSITION_FINANCIAL = 'atRetirement/aboutYou/transition/formFinancialInvalid';

export const FORM_VALID_TRANSITION_ABOUT_YOU = 'atRetirement/aboutYou/transition/formValid';
export const FORM_INVALID_TRANSITION_ABOUT_YOU = 'atRetirement/aboutYou/transition/formInvalid';

export const toggleBudgetTool = () => ({ type: TOGGLE_BUDGET_TOOL });
export const toggleUseOfPersonalInformation = () => ({ type: TOGGLE_USE_OF_PERSONAL_INFORMATION });

// tracking for page render, ARC specific
export const setAboutYouRenderTracking = () => ({ type: ABOUT_YOU_RENDER_TRACKING_VALUE });

export const transitionAboutYou = {
    formPersonalInformationValid: () => ({ type: FORM_VALID_TRANSITION_PERSONAL_INFORMATION }),
    formPersonalInformationInvalid: () => ({ type: FORM_INVALID_TRANSITION_PERSONAL_INFORMATION }),

    formRetirementWorriesValid: () => ({ type: FORM_VALID_TRANSITION_RETIREMENT_WORRIES }),
    formRetirementWorriesInvalid: () => ({ type: FORM_INVALID_TRANSITION_RETIREMENT_WORRIES }),

    formFinancialValid: () => ({ type: FORM_VALID_TRANSITION_FINANCIAL }),
    formFinancialInvalid: () => ({ type: FORM_INVALID_TRANSITION_FINANCIAL }),

    formValid: () => ({ type: FORM_VALID_TRANSITION_ABOUT_YOU }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_ABOUT_YOU }),
};

// Events
export const ABOUT_YOU_SET_VALUE = 'atRetirement/aboutYou/setValue/';
export const ABOUT_YOU_SET_VALID = 'atRetirement/aboutYou/setValid/';

export const NAME_SET_VALUE = ABOUT_YOU_SET_VALUE + 'name';
export const NAME_SET_VALID = ABOUT_YOU_SET_VALID + 'name';
export const AGE_SET_VALUE = ABOUT_YOU_SET_VALUE + 'age';
export const AGE_SET_VALID = ABOUT_YOU_SET_VALID + 'age';
export const GENDER_SET_VALUE = ABOUT_YOU_SET_VALUE + 'gender';
export const GENDER_SET_VALID = ABOUT_YOU_SET_VALID + 'gender';
export const RETIREMENT_FOR_SET_VALUE = ABOUT_YOU_SET_VALUE + 'retirementFor';
export const RETIREMENT_FOR_SET_VALID = ABOUT_YOU_SET_VALID + 'retirementFor';
export const PARTNER_AGE_SET_VALUE = ABOUT_YOU_SET_VALUE + 'partnerAge';
export const PARTNER_AGE_SET_VALID = ABOUT_YOU_SET_VALID + 'partnerAge';
export const RETIREMENT_WORRIES_SET_VALUE = ABOUT_YOU_SET_VALUE + 'retirementWorries';
export const RETIREMENT_WORRIES_SET_VALID = ABOUT_YOU_SET_VALID + 'retirementWorries';
export const SALARY_SET_VALUE = ABOUT_YOU_SET_VALUE + 'salary';
export const SALARY_SET_VALID = ABOUT_YOU_SET_VALID + 'salary';
export const SAVINGS_SET_VALUE = ABOUT_YOU_SET_VALUE + 'savings';
export const SAVINGS_SET_VALID = ABOUT_YOU_SET_VALID + 'savings';
export const BUDGET_SET_VALUE = ABOUT_YOU_SET_VALUE + 'budget';
export const BUDGET_SET_VALID = ABOUT_YOU_SET_VALID + 'budget';

export const setNameValue = value => ({ type: NAME_SET_VALUE, value });
export const setNameValid = error => ({ type: NAME_SET_VALID, error });
export const setAgeValue = value => ({ type: AGE_SET_VALUE, value });
export const setAgeValid = error => ({ type: AGE_SET_VALID, error });
export const setGenderValue = value => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = error => ({ type: GENDER_SET_VALID, error });
export const setRetirementForValue = value => ({ type: RETIREMENT_FOR_SET_VALUE, value });
export const setRetirementForValid = error => ({ type: RETIREMENT_FOR_SET_VALID, error });
export const setPartnerAgeValue = value => ({ type: PARTNER_AGE_SET_VALUE, value });
export const setPartnerAgeValid = error => ({ type: PARTNER_AGE_SET_VALID, error });
export const setRetirementWorriesValue = value => ({ type: RETIREMENT_WORRIES_SET_VALUE, value });
export const setRetirementWorriesValid = error => ({ type: RETIREMENT_WORRIES_SET_VALID, error });
export const setSalaryValue = value => ({ type: SALARY_SET_VALUE, value });
export const setSalaryValid = error => ({ type: SALARY_SET_VALID, error });
export const setSavingsValue = value => ({ type: SAVINGS_SET_VALUE, value });
export const setSavingsValid = error => ({ type: SAVINGS_SET_VALID, error });
export const setBudgetValue = value => ({ type: BUDGET_SET_VALUE, value });
export const setBudgetValid = error => ({ type: BUDGET_SET_VALID, error });