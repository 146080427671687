import { createSelector } from 'reselect';
import { getDomesticInsurance } from './index';
import { API_CALL_STATUS, FORM_STATUS } from '../../reducers/status';

export const getAgent = createSelector(getDomesticInsurance, (state) => state?.agent);

const makeGetAgentField = fieldName =>
    createSelector(getAgent, agent => agent[fieldName]);

// get display modal
export const getDisplayForm = createSelector(getAgent, (agent) => agent.displayModal);

// get form
export const getAgentForm = makeGetAgentField('form');

export const getAgentFormValid = createSelector(
    getAgent,
    agent => agent.formStatus === FORM_STATUS.VALID,
);
export const getIsApiPending = createSelector(getAgent, (agent) => agent.apiCallStatus === API_CALL_STATUS.PENDING);
export const getApiStatus = createSelector(getAgent, (agent) => agent.apiCallStatus);
export const getAgents = createSelector(getAgent, (agent) => agent.agents);

// get form fields
const makeGetFieldForm = fieldId =>
    createSelector(getAgentForm, form => form[fieldId]);

export const getNameValue = makeGetFieldForm('name');
export const getAgentCodeValue = makeGetFieldForm('agentCode');
export const getIntermediaryType = makeGetFieldForm('intermediaryType');
