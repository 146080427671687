const PRODUCT = 'roaCarInsurance/product/';

export const PRODUCT_SET_VALUE = PRODUCT + 'setValue/';

// API Call actions
export const API_PENDING_TRANSITION_PRODUCT = `${PRODUCT_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PRODUCT = `${PRODUCT_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_PRODUCT = `${PRODUCT_SET_VALUE}/apiTransition/failure`;

export const apiTransitionProduct = {
	pending: () => ({
		type: API_PENDING_TRANSITION_PRODUCT,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_PRODUCT,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_PRODUCT,
	}),
};

export const COLLECT_PREMIUM_DATA =
	PRODUCT_SET_VALUE + 'collectPremiumData';

export const SELECTED_PRODUCT_SET_VALUE = PRODUCT_SET_VALUE + 'selectedProduct'

export const CALCULATE_PREMIUM_DATA_SUCCESS =
	PRODUCT_SET_VALUE + 'premiumSuccess';

export const collectPremiumData = () => ({
	type: COLLECT_PREMIUM_DATA,
});
export const calculatePremiumSuccess = (value) => ({
	type: CALCULATE_PREMIUM_DATA_SUCCESS,
	value,
});

export const setSelectedProductValue = (value) => ({
	type: SELECTED_PRODUCT_SET_VALUE,
	value
})
