import {
    ALLSURE_BANK_REFERENCE_DATA_GET_VALUE,
    ALLSURE_BRANCH_CODES_REFERENCE_DATA_GET_VALUE,
    setBankReferenceData,
    setBranchCodeReferenceData,
    setAllsureApplicationApiStatus,
    setVehicleMakeReferenceData,
    setVehicleModelReferenceData,
    ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_GET_VALUE,
    ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_GET_VALUE,
} from '../../../actions/allsureInsurance/referenceData';
import axios from 'axios';
import { API_CALL_STATUS } from '../../../reducers/status';

export const referenceDataService = (store, next, action) => {
    const result = next(action);
    if (action.type === ALLSURE_BANK_REFERENCE_DATA_GET_VALUE) {
        (async () => {
            const bankReferenceData = await fetchBankReferenceData();
            if (bankReferenceData) {
                store.dispatch(setBankReferenceData(bankReferenceData.data));
            } else {
                store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
            }
        })();
    }
    if (action.type === ALLSURE_BRANCH_CODES_REFERENCE_DATA_GET_VALUE) {
        (async () => {
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.PENDING));
            const branchCodesReferenceData = await fetchBranchCodesReferenceData(action.value);
            if (branchCodesReferenceData) {
                store.dispatch(setBranchCodeReferenceData(branchCodesReferenceData.data));
                store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.SUCCESS));
            } else {
                store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
            }
        })();
    }
    if (action.type === ALLSURE_VEHICLE_MAKE_REFERENCE_DATA_GET_VALUE) {
        (async () => {
            const vehicleMakeReferenceData = await fetchVehicleMakeReferenceData();
            if (vehicleMakeReferenceData) {
                store.dispatch(setVehicleMakeReferenceData(vehicleMakeReferenceData.data));
            } else {
                store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
            }
        })();
    }
    if (action.type === ALLSURE_VEHICLE_MODEL_REFERENCE_DATA_GET_VALUE) {
        (async () => {
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.PENDING));
            const vehicleModelReferenceData = await fetchVehicleModelReferenceData(action.value);
            if (vehicleModelReferenceData) {
                store.dispatch(setVehicleModelReferenceData(vehicleModelReferenceData.data));
                store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.SUCCESS));
            } else {
                store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
            }
        })();
    }

    return result;
};

export const fetchBankReferenceData = async () => {
    try {
        const response = await axios.post('/om-api/roa-quote-tools/banks', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        return response;
    } catch (error) {
        return null;
    }
};
export const fetchBranchCodesReferenceData = async (bankCode) => {
    try {
        const response = await axios({
            url: '/om-api/roa-quote-tools/bank-branches',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': 'null',
            },
            // },
            data: {
                bank_code: bankCode, // This is the body part
            },
        });
        return response;
    } catch (error) {
        return null;
    }
};
export const fetchVehicleMakeReferenceData = async () => {
    try {
        const response = await axios({
            url: '/om-api/allsure-insurance/reference-data/vehicle-makes',
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': 'null',
            },
        });
        return response;
    } catch (error) {
        return null;
    }
};
export const fetchVehicleModelReferenceData = async (vehicleMakeCode) => {
    try {
        const response = await axios({
            url: `/om-api/allsure-insurance/reference-data/vehicle-models?vehicleMake=${vehicleMakeCode}`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': 'null',
            },
            // },
        });
        return response;
    } catch (error) {
        return null;
    }
};
