import httpStatusCode from '../../../../api-server/constants/httpStatusCode';
import {
    setAllsureApplicationApiStatus,
    setAllsureApplicationQuoteDetails,
    setApplicationReferenceNumber,
} from '../../../actions/allsureInsurance/referenceData';
import { API_CALL_STATUS } from '../../../reducers/status';
import { getHomeDetailsRequestBody } from '../../../selectors/allsureInsurance/homeDetails';
import { getMotorDetailsRequestBody } from '../../../selectors/allsureInsurance/motorDetails';
import axios from 'axios';

export const quotingService = (store, next, action) => {
    (async () => {
        store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.PENDING));
        const homeDetails = getHomeDetailsRequestBody(store.getState());
        const motorDetails = getMotorDetailsRequestBody(store.getState());
        const requestbody = { homeDetails, motorDetails };  
       
        const quoteData = await getAllsureQuote(requestbody);
        if (quoteData && quoteData.status === httpStatusCode.OK) {
            const {referenceNumber,...quotationResponse}=quoteData.data
            
            store.dispatch(setApplicationReferenceNumber(referenceNumber));
            store.dispatch(setAllsureApplicationQuoteDetails(quotationResponse));
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.SUCCESS));
        } else {
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
        }
    })();
    return next(action);
};


const getAllsureQuote = async (requestBody) => {
    try {
        const response = await axios({
            url: '/om-api/allsure-insurance/get-quote',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            data: requestBody,
        });
        return response;
    } catch (error) {
        return null;
    }
};
