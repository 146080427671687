export const RW_MOTOR_INSURANCE_DOCUMENTS_FORM = 'motorPrivateRwanda/documentsForm/';

export const MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE = RW_MOTOR_INSURANCE_DOCUMENTS_FORM + 'setValue/';
export const MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID = RW_MOTOR_INSURANCE_DOCUMENTS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM = 'motorPrivateRwanda/documentsForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM = 'motorPrivateRwanda/documentsForm/transition/formInValid';

export const REINITIALIZE = 'motorPrivateRwanda/documentsForm/state/reinitialize';


// Dropoff
export const DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM = 'motorPrivateRwanda/documentsForm/transition/dropOffLead';
export const dropOffLeadDocuments = (value) => ({
	type: DROP_OFF_LEAD_TRANSITION_DOCUMENTS_FORM,
	value,
});

export const transitionMotorInsuranceDocumentsForm = {
	formValid: () => ({
		type: FORM_VALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_MOTOR_INSURANCE_DOCUMENTS_FORM,
	}),
};

// API Calls actions
export const API_PENDING_TRANSITION_DOCUMENTS_FORM = `${MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_DOCUMENTS_FORM = `${MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_DOCUMENTS_FORM = `${MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionDocumentsForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_DOCUMENTS_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_DOCUMENTS_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_DOCUMENTS_FORM,
	}),
};

//calculated Data
export const COLLECT_DOCUMENTS_FORM_DATA = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'collectDocumentsData';
export const SUBMIT_DOCUMENTS_SUCCESS = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'success';
export const SUBMIT_DOCUMENTS_FAILURE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'failure';

export const collectDocumentsData = () => ({
	type: COLLECT_DOCUMENTS_FORM_DATA,
});
export const submitDocumentsDataSuccess = (value) => ({
	type: SUBMIT_DOCUMENTS_SUCCESS,
	value,
});
export const submitDocumentsFailure = (error) => ({
	type: SUBMIT_DOCUMENTS_FAILURE,
	error,
});

// Form Values
export const ID_UPLOAD_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'idUpload';
export const ID_UPLOAD_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'idUpload';

export const YELLOW_CARD_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'yellowCard';
export const YELLOW_CARD_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'yellowCard';

export const CAR_FRONT_VIEW_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'carFrontView';
export const CAR_FRONT_VIEW_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'carFrontView';

export const CAR_BACK_VIEW_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'carBackView';
export const CAR_BACK_VIEW_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'carBackView';

export const CAR_LEFT_VIEW_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'carLeftView';
export const CAR_LEFT_VIEW_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'carLeftView';

export const CAR_RIGHT_VIEW_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'carRightView';
export const CAR_RIGHT_VIEW_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'carRightView';

export const CAR_DASHBOARD_VIEW_SET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'carDashboardView';
export const CAR_DASHBOARD_VIEW_SET_VALID = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALID + 'carDashboardView';



// reset individual form value
export const ID_UPLOAD_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/idUpload';
export const YELLOW_CARD_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/yellowCard';
export const CAR_FRONT_VIEW_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/carFrontView';
export const CAR_BACK_VIEW_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/carBackView';
export const CAR_LEFT_VIEW_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/carLeftView';
export const CAR_RIGHT_VIEW_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/carRightView';
export const CAR_DASHBOARD_VIEW_RESET_VALUE = MOTOR_INSURANCE_DOCUMENTS_FORM_SET_VALUE + 'reset/carDashboardView';



// ID upload action creators
export const setIdUploadValue = (value) => ({
	type: ID_UPLOAD_SET_VALUE,
	value,
});

export const setIdUploadValid = (error) => ({
	type: ID_UPLOAD_SET_VALID,
	error,
});

export const resetIdUploadValue = () => ({
	type: ID_UPLOAD_RESET_VALUE,
});

// License upload action creators
export const setYellowCardValue = (value) => ({
	type: YELLOW_CARD_SET_VALUE,
	value,
});

export const setYellowCardValid = (error) => ({
	type: YELLOW_CARD_SET_VALID,
	error,
});

export const resetYellowCardValue = () => ({
	type: YELLOW_CARD_RESET_VALUE,
});

// Valuation report action creators
export const setLeftViewValue = (value) => ({
	type: CAR_LEFT_VIEW_SET_VALUE,
	value,
});

export const setLeftViewValid = (error) => ({
	type: CAR_LEFT_VIEW_SET_VALID,
	error,
});

export const resetLeftViewValue = () => ({
	type: CAR_LEFT_VIEW_RESET_VALUE,
});


export const setRightSideValue = (value) => ({
	type: CAR_RIGHT_VIEW_SET_VALUE,
	value,
});

export const setRightSideValid = (error) => ({
	type: CAR_RIGHT_VIEW_SET_VALID,
	error,
});

export const resetRightSideValue = () => ({
	type: CAR_RIGHT_VIEW_RESET_VALUE,
});

export const setFrontViewValue = (value) => ({
	type: CAR_FRONT_VIEW_SET_VALUE,
	value,
});

export const setFrontViewValid = (error) => ({
	type: CAR_FRONT_VIEW_SET_VALID,
	error,
});

export const resetFrontViewValue = () => ({
	type: CAR_FRONT_VIEW_RESET_VALUE,
});

export const setBackViewValue = (value) => ({
	type: CAR_BACK_VIEW_SET_VALUE,
	value,
});

export const setBackViewValid = (error) => ({
	type: CAR_BACK_VIEW_SET_VALID,
	error,
});

export const resetBackViewValue = () => ({
	type: CAR_BACK_VIEW_RESET_VALUE,
});

export const setDashboardViewValue = (value) => ({
	type: CAR_DASHBOARD_VIEW_SET_VALUE,
	value,
});

export const setDashboardViewValid = (error) => ({
	type: CAR_DASHBOARD_VIEW_SET_VALID,
	error,
});

export const resetDashboardViewValue = () => ({
	type: CAR_DASHBOARD_VIEW_RESET_VALUE,
});
