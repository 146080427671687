import { createSelector } from "reselect";
import { getBiasharaFlexiState } from './index';
import { FORM_STATUS } from "../../reducers/status";

export const getEmployeeDetails = createSelector(
    getBiasharaFlexiState,
    state => state.employeeDetails
);

const makeGetEmployeeDetailsField = fieldName => createSelector(getEmployeeDetails, employeeDetails => employeeDetails[fieldName]);

export const getEmployeeDetailsForm = makeGetEmployeeDetailsField("form");

export const getEmployeeDetailsFormValid = createSelector(
    getEmployeeDetails, 
    employeeDetails => employeeDetails.formStatus === FORM_STATUS.VALID,
)

export const getEmployeesList = createSelector(
    getEmployeeDetails,
    employeeDetails => employeeDetails.employees
);

export const getEmployeeForm = createSelector(
    getEmployeeDetails,
    employeeDetails => employeeDetails.form
);

export const getEmployeeScvFile = createSelector(
    getEmployeeDetails,
    employeeDetails => employeeDetails.fileCsv
);


const makeGetFieldForm = (fieldId) => createSelector(getEmployeeDetailsForm, (form) => form[fieldId]);

export const getFirstNameValue = makeGetFieldForm('firstName');
export const getSurnameValue = makeGetFieldForm('surname');
export const getMobileNumberValue = makeGetFieldForm('mobileNumber');
export const getKraPinNumberValue = makeGetFieldForm('kraPinNumber');
export const getDoBValue = makeGetFieldForm('dateOfBirth');
export const getEmploymentDateValue = makeGetFieldForm('employmentDate');
export const getIdPassportValue = makeGetFieldForm('idPassportNumber');


