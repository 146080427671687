import {
    getIsShareQuoteApiPending,
    getQuoteForm,
    getPremiumCalculationUg,
} from '../../../selectors/motorPrivateRW/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure,
} from '../../../actions/motorPrivateRW/quote';
import { getAccessToken } from '../../../selectors/motorPrivateRW/payments';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsShareQuoteApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);
    const carYear = parseInt(quotation.yearOfManufacture.value);
    const currentYear = new Date().getFullYear();
    const carAge = currentYear - carYear;

    const payload = {
        vehicleType: quotation.vehicleType.value,
        value: quotation.carValue.value,
        age: carAge,
        seatCapacity: quotation.seatsNumber.value,
        numberOfOccupants: quotation.coveredNumber.value,
        sumInsuredPerOccupant: quotation.sumInsured.value,
        comprehensive: quotation.selectedCover.value === 'motorComprehensive' ? true : false,
        comesa: quotation.carReach.value === 'rwanda' ? false : true,
        paymentPlan: quotation.insurancePaymentPlan.value,
        yearOfManufacture: quotation.yearOfManufacture.value,
        countryCode: 'RW',
        email: quotation.shareEmail.value,
        phoneNumber: '0780000000',
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/rw-motor-insurance/share-quote';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
