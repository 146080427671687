// Transitions
export const FORM_VALID_TRANSITION_BANKING_DETAILS =
  "allsureInsurance/bankingDetails/transition/formValid";
export const FORM_INVALID_TRANSITION_BANKING_DETAILS =
  "allsureInsurance/bankingDetails/transition/formInvalid";


export const transitionBankingDetails = {
  formValid: () => ({ type: FORM_VALID_TRANSITION_BANKING_DETAILS }),
  formInvalid: () => ({ type: FORM_INVALID_TRANSITION_BANKING_DETAILS }),
};

// Transition - reset state
export const RESET_STATE_TRANSITION_BANKING_DETAILS =
  "allsureInsurance/bankingDetails/transition/resetState";
export const resetPersonalDetails = () => ({
  type: RESET_STATE_TRANSITION_BANKING_DETAILS,
});

// Events


export const ALLSURE_INSURANCE_BANKING_DETAILS =
  "allsureInsurance/bankingDetails/";
export const BANKING_DETAILS_SET_VALUE =
ALLSURE_INSURANCE_BANKING_DETAILS+"setValue/";
export const BANKING_DETAILS_SET_VALID =
ALLSURE_INSURANCE_BANKING_DETAILS +"setValid/";
// export const PERSONAL_DETAILS_EDIT =
// ALLSURE_INSURANCE_BANKING_DETAILS +"setEdit";

// export const TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE =
// ALLSURE_INSURANCE_INSURANCE_TYPE+"toogle/privacyNotice";

// export const  tooglePrivacyNotice=(value)=>({type: TOOGLE_INSURANCE_TYPE_PRIVACY_NOTICE, value})
export const setBankingDetailsValue =(value)=>({type: BANKING_DETAILS_SET_VALUE, value})
export const setBankingDetailsFieldValid =(error,fieldType)=>({type: BANKING_DETAILS_SET_VALID, error, fieldType})
// export const setPersonalDetailsEdit =(value)=>({type: PERSONAL_DETAILS_EDIT, value})



