import {
    REQUEST_ID_SET_VALUE
} from "../../actions/taxFreeSavingsAccount/sessionCamVariables";

export const initSessionCamVariables = {
    requestId: null,
};

export default (state = initSessionCamVariables, action) => {
    switch (action.type) {

        case REQUEST_ID_SET_VALUE:
            return {...state, requestId: action.value};

        default:
            return state;
    }
};
