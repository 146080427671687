import {
    getIsApiPending,
    getQuoteForm,
    getPremiumCalculation,
    getAddedCover,
    getProducts,
} from '../../../selectors/healthInsuranceRW/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure,
} from '../../../actions/healthInsuranceRW/quote';
import { getAccessToken } from '../../../selectors/healthInsuranceRW/payments';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const checkAges = (agesArray, thresholdAge = 19) => {
    const result = {
        overAge: 0,
        underAge: 0
    };

    agesArray && agesArray.forEach(item => {
        if (item.value >= thresholdAge) {
            result.overAge++;
        } else {
            result.underAge++;
        }
    });

    return result;
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);
    const product = getProducts(state);

    const payload = {
        countryCode: 'RW',
        productId: product[0].product_id || 'NA',
        age: parseInt(quotation.age.value) || 'NA',
        inPatient: {
            inpatientLimitId: parseInt(quotation.inpatientCover.value.id) || 0,
        },
        outPatient: {
            present: quotation.outpatientCover.value ? true : false,
            outpatientLimitId: parseInt(quotation.outpatientCover.value.id) || 0,
        },
        familyMembers: {
            children: {
                count: quotation.childrenAge.value?.length || 0,
                overAgeCount: checkAges(quotation.childrenAge.value)?.overAge || 0
            },
            spouse: {
                age: parseInt(quotation.spouseAge.value) || 0,
            },
        },
        optical: quotation.opticalCover.value,
        dental: quotation.dentalCover.value,
        maternityLimitId: parseInt(quotation.maternityCover.value?.id) || 0,
        email: quotation.shareEmail.value,
        phoneNumber: "N/A"
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/health-insurance-rw/share-quote';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
