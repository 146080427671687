import { transitionMotorInsuranceCarDetailsForm } from '../../../actions/motorPrivateRW/carDetails';
import { getCarDetailsForm } from '../../../selectors/motorPrivateRW/carDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const carDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getCarDetailsForm(store.getState());
    const quoteFormValid = validateCarDetailsFormDetails(quoteForm);

    const formAction = quoteFormValid
        ? transitionMotorInsuranceCarDetailsForm.formValid()
        : transitionMotorInsuranceCarDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateCarDetailsFormDetails = (formFields) => {
    return (
        formFields.registrationNumber &&
        formFields.registrationNumber.status === FIELD_STATUS.VALID &&
        formFields.chasisNumber &&
        formFields.chasisNumber.status === FIELD_STATUS.VALID &&
        formFields.tinNumber &&
        formFields.tinNumber.status === FIELD_STATUS.VALID
    );
};
