import {PERSONAL_LOANS_CALCULATOR_SET_VALUE} from "../../../actions/personalLoansCalculator/calculator";
import {personalLoansCalculatorValidation} from "./calculator";

export const personalLoansValidation = (store, next, action) => {

    if (action.type.startsWith(PERSONAL_LOANS_CALCULATOR_SET_VALUE)) {
        return personalLoansCalculatorValidation(store, next, action);
    }

    return null;
};
