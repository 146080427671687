import axios from 'axios';
import { getIsKraApiPending, getCarDetailsForm } from '../../../selectors/roaCarInsurance/details';
import {
	apiTransitionValidateKra,
	setValidateKraValid,
	setValidateIdValue,
} from '../../../actions/roaCarInsurance/details';

export const validateKraService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();

	(async () => {
		if(!getIsKraApiPending(state)) {
			store.dispatch(
				apiTransitionValidateKra.pending()
			)
			try {
				const resp = await validateKra(state)
				store.dispatch(setValidateIdValue(resp.data))
				store.dispatch(apiTransitionValidateKra.success())

			} catch (e) {
				store.dispatch(setValidateKraValid(e))
				store.dispatch(apiTransitionValidateKra.failure())
			}
		}
	})();
	return result;
}

const validateKra = async (state) => {
	const carDetails = getCarDetailsForm(state);
    const kra_pin = carDetails.kraPinNumber.value;
    const payload = {kra_pin, session_id:'TMSUXS'};
	try {
		const url = '/om-api/roa-car-insurance/validate-krapin';
		return await axios.post(url, payload);
	} catch (e) {
		return null;
	}
}
