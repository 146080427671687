import { FIELD_STATUS, FORM_STATUS } from "../status";
import {
    SIDEDRAWER_SET_VALUE,
    SIDEDRAWER_CLOSED_SET_VALUE,
    COVER_CATEGORY_SET_VALUE,
    COVER_CATEGORY_SET_VALID,
    AGE_SET_VALUE,
    AGE_SET_VALID,
    COVER_AMOUNT_SET_VALUE,
    COVER_AMOUNT_SET_VALID,
    MONTHLY_PREMIUM_SET_VALUE,
    MONTHLY_PREMIUM_SET_VALID,
    ADD_PARTNER_SET_VALUE,
    ADD_PARTNER_SET_VALID,
    ADD_CHILD_SET_VALUE,
    ADD_CHILD_SET_VALID,
    FORM_VALID_TRANSITION_QUOTE,
    FORM_INVALID_TRANSITION_QUOTE,
    DELETE_TRANSITION_QUOTE,
    EDIT_TRANSITION_QUOTE,
    SAVE_TRANSITION_QUOTE,
    CANCEL_EDIT_OF_TRANSITION_QUOTE,
    CLOSE_ADD_COVER_TRANSITION_QUOTE,
    NEW_COVER_MYSELF_SET_VALUE,
    NEW_COVER_PARENTS_SET_VALUE,
    NEW_COVER_EXTENDED_SET_VALUE,
    COVER_MEMBER_SET_VALUE,
    EXPAND_MEMBERS_TRANSITION_QUOTE,
    COLLAPSE_MEMBERS_TRANSITION_QUOTE,
    COVER_MEMBER_SET_VALID,
    NEW_COVER_DEFAULT_SET_VALUE,
    PROGRESSIVE_AGE_CLEAR_QUOTE,
    PROGRESSIVE_MEMBER_CLEAR_QUOTE,
    CONFIRM_DELETE_MEMBER_CLEAR,
    CONFIRM_DELETE_MEMBER_SET,
    SHOW_AGE_VALIDATION_SET_VALUE,
    EFP_QUOTE_CREATE_QUOTE_SEND_SUCCESS,
    EFP_QUOTE_CREATE_QUOTE_SEND,
    EFP_QUOTE_CREATE_QUOTE_SEND_FAILURE,
    RESET_STATE_TRANSITION_QUOTE,
    EFP_QUOTE_CREATE_QUOTE_CANCEL,
    EFP_QUOTE_CREATE_NOT_FIRST_RUN,
    WAITING_FOR_CREATE_QUOTE_SET_VALUE
} from "../../actions/easiplusFuneralPlan/quote";
import {EFP_COVER_CATEGORIES} from "../../actions/easiplusFuneralPlan/types";

// Default objects for cover members
const coverMember = () => ({
    age: {value: null, error: null, status: null},
    coverAmount: {value: '30000', error: null, status: FIELD_STATUS.VALID},
    monthlyPremium: {value: '', error: null, status: FIELD_STATUS.VALID},
});
export const coverMyself = () => ({
    ...coverMember(),
    addPartner: { value: null, error: null, status: FIELD_STATUS.VALID },
    addChild: { value: null, error: null, status: FIELD_STATUS.VALID },
});
const coverParentsOrExtendedFamily = () => ({
    ...coverMember(),
    relationship: { value: null, error: null, status: FIELD_STATUS.UNTOUCHED },
});

// Default cover member forms
export const newCoverDefaultMember = () => ({
    form: coverMyself(),
    formStatus: FORM_STATUS.INVALID,
    coverCategory: { value: null, error: null, status: null },
});
export const newCoverMyself = () => ({
    form: coverMyself(),
    formStatus: FORM_STATUS.INVALID,
    coverCategory: { value: EFP_COVER_CATEGORIES.DIRECT_FAMILY, error: null, status: FIELD_STATUS.UNTOUCHED },
});
export const newCoverParents = () => ({
    form: {
        ...coverParentsOrExtendedFamily(), 
        coverAmount: {value: '20000', error: null, status: FIELD_STATUS.VALID},
    },
    formStatus: FORM_STATUS.INVALID,
    coverCategory: { value: EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS },
});
export const newCoverExtendedFamily = () => ({
    form: { 
        ...coverParentsOrExtendedFamily(), 
        coverAmount: {value: '15000', error: null, status: FIELD_STATUS.VALID},
    },
    formStatus: FORM_STATUS.INVALID,
    coverCategory: { value: EFP_COVER_CATEGORIES.EXTENDED_FAMILY },
});

export const newConfirmDelete = () => ({
    id: null,
    relationship: null,
    category: null,
});

export const initQuote = {
    // covered members
    livesCovered: {
        [EFP_COVER_CATEGORIES.DIRECT_FAMILY]: [],
        [EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS]: [],
        [EFP_COVER_CATEGORIES.EXTENDED_FAMILY]: [],
    },
    expandedView: null,
    showingAgeValidation: true,

    // editing properties
    sideDrawerOpen: null,
    formCoverCategories: { value: null, error: null, status: null },
    livesCoveredIdIndex: 0,
    editId: null,
    editCover: newCoverDefaultMember(),

    confirmDelete: newConfirmDelete(),

    // dropdown options
    coverCategoryOptions: [
        'Me and My Direct Family',
        'My Parents and In-laws',
        'My Extended Family',
    ],
    parentsCoverOptions: [
        'Father',
        'Mother',
        'Father-in-law',
        'Mother-in-law',
    ],
    extendedFamilyCoverOptions: [
        'Aunt',
        'Brother',
        'Brother-in-law',
        'Cousin',
        'Daughter-in-law',
        'Grandchild',
        'Grandparent',
        'Nephew',
        'Niece',
        'Sister',
        'Sister-in-law',
        'Son-in-law',
        'Uncle',
        'Son',
        'Daughter',
        'Parent-in-law',
        'Grandparent-in-law',
    ],

    quoteNumber: null,
    createQuoteError: null,
    createQuoteSuccess: false,
    waitingForCreateQuote: false,
};

export default (state = initQuote, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId]),
    });
    const updateFormField = (fieldId, update) => ({
        ...state,
        editCover: {
            ...state.editCover,
            form: {
                ...state.editCover.form,
                [fieldId]: update(state.editCover.form[fieldId]),
            }
        }
    });

    switch (action.type) {
        case SIDEDRAWER_SET_VALUE:
            return { ...state, sideDrawerOpen: action.value };
        case SIDEDRAWER_CLOSED_SET_VALUE:
            return { ...state, sideDrawerOpen: null };
        case SHOW_AGE_VALIDATION_SET_VALUE:
            return { ...state, showingAgeValidation: action.value };

        case COVER_CATEGORY_SET_VALUE:
            return updateField('formCoverCategories', field => ({
                ...field,
                value: action.value,
            }));
        case COVER_CATEGORY_SET_VALID:
            return updateField('formCoverCategories', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case COVER_AMOUNT_SET_VALUE:
            return updateFormField('coverAmount', field => ({
                ...field,
                value: action.value,
            }));
        case COVER_AMOUNT_SET_VALID:
            return updateFormField('coverAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MONTHLY_PREMIUM_SET_VALUE:
            return updateFormField('monthlyPremium', field => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_PREMIUM_SET_VALID:
            return updateFormField('monthlyPremium', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ADD_PARTNER_SET_VALUE:
            return updateFormField('addPartner', field => ({
                ...field,
                value: action.value,
            }));
        case ADD_PARTNER_SET_VALID:
            return updateFormField('addPartner', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ADD_CHILD_SET_VALUE:
            return updateFormField('addChild', field => ({
                ...field,
                value: action.value,
            }));
        case ADD_CHILD_SET_VALID:
            return updateFormField('addChild', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case COVER_MEMBER_SET_VALUE:
            return updateFormField('relationship', field => ({
                ...field,
                value: action.value,
            }));
        case COVER_MEMBER_SET_VALID:
            return updateFormField('relationship', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FORM_VALID_TRANSITION_QUOTE:
            return {
                ...state,
                editCover: {
                    ...state.editCover,
                    formStatus: FORM_STATUS.VALID,
                },
            };
        case FORM_INVALID_TRANSITION_QUOTE:
            return {
                ...state,
                editCover: {
                    ...state.editCover,
                    formStatus: FORM_STATUS.INVALID,
                },
            };

        case SAVE_TRANSITION_QUOTE:
            if (state.editCover.formStatus !== FORM_STATUS.VALID) {
                return state;
            }

            const isNewCover = action.coverId == null;
            const coverId = isNewCover
                ? state.livesCoveredIdIndex
                : action.coverId;
            const newCover = {
                id: coverId,
                value: state.editCover.form
            };
            let category = action.coverCategory;
            
            if (isNewCover) {
                return {
                    ...state,
                    livesCovered: {
                        ...state.livesCovered,
                        [category]: [newCover, ...state.livesCovered[category]],
                    },
                    livesCoveredIdIndex: state.livesCoveredIdIndex + 1,
                    formCoverCategories: { value: null, error: null, status: null },
                    editId: null,
                    editCover: newCoverDefaultMember(),
                };
            }

            const coverCategoryList = state.livesCovered[category];
            const editIndex = coverCategoryList.findIndex(cover => cover.id === coverId)
            let updatedLivesCovered;
            if (editIndex === -1) {
                // moving from one category to another -- remove id from whatever category it may be in:
                updatedLivesCovered = {
                    [EFP_COVER_CATEGORIES.DIRECT_FAMILY]:
                        state.livesCovered[EFP_COVER_CATEGORIES.DIRECT_FAMILY].filter(cov => cov.id !== coverId),
                    [EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS]:
                        state.livesCovered[EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS].filter(cov => cov.id !== coverId),
                    [EFP_COVER_CATEGORIES.EXTENDED_FAMILY]:
                        state.livesCovered[EFP_COVER_CATEGORIES.EXTENDED_FAMILY].filter(cov => cov.id !== coverId),
                };
                // append to update category
                updatedLivesCovered[category] = [newCover, ...updatedLivesCovered[category]];
            } else {
                // replace cover in current category
                updatedLivesCovered = {
                    ...state.livesCovered,
                    [category]: [
                        ...coverCategoryList.slice(0, editIndex),
                        newCover,
                        ...coverCategoryList.slice(editIndex + 1),
                    ]
                };
            }

            return {
                ...state,
                livesCovered: updatedLivesCovered,
                formCoverCategories: { value: null, error: null, status: null },
                editId: null,
                editCover: newCoverDefaultMember(),
            };

        case CANCEL_EDIT_OF_TRANSITION_QUOTE:
            return {
                ...state,
                formCoverCategories: { value: null, error: null, status: null },
                editCover: null,
                editId: null,
            };

        case DELETE_TRANSITION_QUOTE:
            const deleteId = state.confirmDelete.id;
            const deleteCategory = state.confirmDelete.category;
            const coverList = state.livesCovered[deleteCategory];

            return {
                ...state,
                livesCovered: {
                    ...state.livesCovered,
                    [deleteCategory]: (coverList || []).filter(cover => cover.id !== deleteId)
                },
            };

        case EDIT_TRANSITION_QUOTE:
            const editId = action.coverId;
            const editCategory = action.coverCategory;
            const editForm = state.livesCovered[editCategory].find(cover => cover.id === editId);

            if (!editForm) {
                return state;
            }
            return {
                ...state,
                sideDrawerOpen: action.sideDrawer,
                editId,
                formCoverCategories: { value: editCategory, error: null, status: FIELD_STATUS.VALID },
                editCover: {
                    form: Object.assign({}, editForm.value),
                    formStatus: FORM_STATUS.VALID,
                },
            };

        case EXPAND_MEMBERS_TRANSITION_QUOTE:
            return {
                ...state,
                expandedView: action.coverCategory,
            };

        case COLLAPSE_MEMBERS_TRANSITION_QUOTE:
            return {
                ...state,
                expandedView: null,
            };

        case CLOSE_ADD_COVER_TRANSITION_QUOTE:
            return {
                ...state,
                sideDrawerOpen: null,
            };

        case NEW_COVER_DEFAULT_SET_VALUE:
            return {
                ...state,
                editCover: newCoverDefaultMember(),
                formCoverCategories: { value: null, error: null, status: null },
            };
        case NEW_COVER_MYSELF_SET_VALUE:
            return {
                ...state,
                editCover: newCoverMyself(),
            };
        case NEW_COVER_PARENTS_SET_VALUE:
            return {
                ...state,
                editCover: newCoverParents(),
            };
        case NEW_COVER_EXTENDED_SET_VALUE:
            return {
                ...state,
                editCover: newCoverExtendedFamily(),
            };

        case PROGRESSIVE_MEMBER_CLEAR_QUOTE:
            const defaultCoverMember = coverMyself();
            const activeCategory = state.editCover.coverCategory ? state.editCover.coverCategory.value : state.formCoverCategories.value;
            const coverAmount = activeCategory === EFP_COVER_CATEGORIES.DIRECT_FAMILY 
                                    ? '30000'
                                    : activeCategory === EFP_COVER_CATEGORIES.PARENTS_AND_IN_LAWS
                                    ? '20000' : '15000'; 
            return {
                ...state,
                editCover: {
                    ...state.editCover,
                    formStatus: FORM_STATUS.INVALID,
                    form: {
                        ...state.editCover.form,
                        ...defaultCoverMember,
                        coverAmount: {
                            ...defaultCoverMember.coverAmount,
                            value: coverAmount
                        }
                    }
                },
            };
        case PROGRESSIVE_AGE_CLEAR_QUOTE:
            const {addChild, addPartner, monthlyPremium} = coverMyself();
            return {
                ...state,
                editCover: {
                    ...state.editCover,
                    formStatus: FORM_STATUS.INVALID,
                    form: {
                        ...state.editCover.form,
                        addChild,
                        addPartner,
                        monthlyPremium,
                    }
                },
            };

        case CONFIRM_DELETE_MEMBER_CLEAR:
            return {
                ...state,
                confirmDelete: newConfirmDelete(),
            };
        case CONFIRM_DELETE_MEMBER_SET:
            return {
                ...state,
                confirmDelete: {
                    id: action.id,
                    relationship: action.relationship,
                    category: action.category,
                },
            };

        case EFP_QUOTE_CREATE_QUOTE_SEND:
            return {
                ...state,
                createQuoteError: null,
                createQuoteSuccess: false,
                waitingForCreateQuote: true,
            };
        case EFP_QUOTE_CREATE_QUOTE_SEND_FAILURE:
            return {
                ...state,
                createQuoteError: {
                    error: true
                },
                createQuoteSuccess: false,
                waitingForCreateQuote: false,
            };
        case EFP_QUOTE_CREATE_QUOTE_SEND_SUCCESS:
            return {
                ...state,
                quoteNumber: action.value,
                createQuoteError: null,
                createQuoteSuccess: true,
                //waitingForCreateQuote: false, // This only gets set to false once on Replacement policy page
            };
        case EFP_QUOTE_CREATE_QUOTE_CANCEL:
            return {
                ...state,
                createQuoteSuccess: false,
                waitingForCreateQuote: false,
            }
        case WAITING_FOR_CREATE_QUOTE_SET_VALUE:
            return {
                ...state,
                waitingForCreateQuote: action.value
            }
        case RESET_STATE_TRANSITION_QUOTE:
            return { ...initQuote };
        default:
            return state;
    }
}