import {BENEFICIARIES_SET_VALUE} from "../../../actions/raOptimal/beneficiaries";
import {BENEFICIARIES_SPLIT_SET_VALUE} from "../../../actions/raOptimal/beneficiariesSplit";
import {CAPTURE_DETAILS_SET_VALUE} from "../../../actions/raOptimal/captureDetails";
import {INVESTMENT_SETUP_SET_VALUE} from "../../../actions/raOptimal/investmentSetup";
import {PAYMENT_DETAILS_SET_VALUE} from "../../../actions/raOptimal/paymentDetails";
import {PERSONAL_DETAILS_SET_VALUE} from "../../../actions/raOptimal/personalDetails";
import {REVIEW_SET_VALUE} from "../../../actions/raOptimal/review";

export const isRaAction = action => (
    action.type.startsWith(BENEFICIARIES_SET_VALUE) ||
    action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE) ||
    action.type.startsWith(CAPTURE_DETAILS_SET_VALUE) ||
    action.type.startsWith(INVESTMENT_SETUP_SET_VALUE) ||
    action.type.startsWith(PAYMENT_DETAILS_SET_VALUE) ||
    action.type.startsWith(PERSONAL_DETAILS_SET_VALUE) ||
    action.type.startsWith(REVIEW_SET_VALUE)
);
