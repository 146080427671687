import { createSelector } from 'reselect';
import { getTaxFreeSavingsAccount } from './index';
import { TOGGLE_STATUS, FIELD_STATUS } from '../../reducers/status';
import {
    PREMIUM_ESCALATION_OPTIONS,
    MAX_REGULAR_CONTRIBUTION,
    CONTRIBUTION_TYPE,
    MAXIMUM_COMBINED_CONTRIBUTION,
    MINIMUM_MONTHLY_CONTRIBUTION_COMBINATION,
    MINIMUM_MONTHLY_CONTRIBUTION_MONTHLY,
    EFFECTIVE_ANNUAL_COST_AMOUNTS,
} from '../../actions/taxFreeSavingsAccount/types';
import { getSavingForEducation, getSavingForRetirement } from './investmentSetupInitial';

export const getInvestmentSetupContribution = createSelector(
    getTaxFreeSavingsAccount,
    (tfsa) => tfsa.investmentSetupContribution
);
export const getInvestmentSetupContributionForm = createSelector(
    getInvestmentSetupContribution,
    (investmentSetupContribution) => investmentSetupContribution.form
);

export const getIsViewMoreExpanded = createSelector(
    getInvestmentSetupContribution,
    (investmentSetupContribution) => investmentSetupContribution.viewMoreExpanded === TOGGLE_STATUS.YES
);

const makeGetField = (fieldId) =>
    createSelector(
        getInvestmentSetupContributionForm,
        (investmentSetupContributionForm) => investmentSetupContributionForm[fieldId]
    );
export const getOwnPercentage = makeGetField('ownPercentage');
export const getPremiumEscalation = makeGetField('premiumEscalation');
export const getContributionType = makeGetField('contributionType');
export const getRegularContribution = makeGetField('regularContributionValue');
export const getLumpSumContribution = makeGetField('lumpSumContributionValue');

export const getPremiumEscalationDescription = createSelector(getPremiumEscalation, (premiumEscalation) => {
    const keys = Object.keys(PREMIUM_ESCALATION_OPTIONS);
    const escalationKey = keys.find((key) => PREMIUM_ESCALATION_OPTIONS[key].key === premiumEscalation.value);
    return escalationKey ? PREMIUM_ESCALATION_OPTIONS[escalationKey].description : '';
});

export const getDisplayLumpSumInput = createSelector(
    getContributionType,
    (contributionType) =>
        contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM ||
        contributionType.value === CONTRIBUTION_TYPE.COMBINATION
);

export const getLumpSumHintText = createSelector(getContributionType, (contributionType) => {
    const minimum = contributionType.value === CONTRIBUTION_TYPE.COMBINATION ? '1 000' : '5 000';
    return 'Min R' + minimum + ' - Max R36 000';
});

export const getDisplayRegularInput = createSelector(
    getContributionType,
    (contributionType) =>
        contributionType.value === CONTRIBUTION_TYPE.MONTHLY || contributionType.value === CONTRIBUTION_TYPE.COMBINATION
);

// If combination selected then Lump Sum + Regular cannot exceed R36 000, if it does return true
export const getIsCombinationLimitExceeded = createSelector(
    getContributionType,
    getLumpSumContribution,
    getRegularContribution,
    (contributionType, lumpSumContribution, regularContribution) => {
        if (contributionType.value === CONTRIBUTION_TYPE.COMBINATION) {
            const regular = regularContribution.value ? parseInt(regularContribution.value) : 0;
            const lumpSum = lumpSumContribution.value ? parseInt(lumpSumContribution.value) : 0;
            return regular + lumpSum > MAXIMUM_COMBINED_CONTRIBUTION;
        }
    }
);

export const getIsMinimumMonthlyContributionEntered = createSelector(
    getRegularContribution,
    (regularContribution) =>
        regularContribution.status === FIELD_STATUS.VALID &&
        regularContribution.value < MINIMUM_MONTHLY_CONTRIBUTION_COMBINATION
);

export const getMinimumMonthlyContribution = createSelector(getContributionType, (contributionType) =>
    contributionType.value === CONTRIBUTION_TYPE.COMBINATION
        ? MINIMUM_MONTHLY_CONTRIBUTION_COMBINATION
        : MINIMUM_MONTHLY_CONTRIBUTION_MONTHLY
);

// If combination selected then Lump Sum + Regular cannot exceed R36 000, if it does return true
export const getDispalyLimitExceededModal = createSelector(
    getInvestmentSetupContribution,
    (investmentSetupContribution) => investmentSetupContribution.displayLimitExceededModal === TOGGLE_STATUS.YES
);

export const getDisplayPremiumEscalation = createSelector(
    getContributionType,
    getRegularContribution,
    getLumpSumContribution,
    getIsCombinationLimitExceeded,
    (contributionType, regularContribution, lumpSumContribution, combinationLimitExceeded) => {
        if (combinationLimitExceeded) {
            return false;
        }
        if (contributionType.value === CONTRIBUTION_TYPE.COMBINATION) {
            return (
                regularContribution.status === FIELD_STATUS.VALID && lumpSumContribution.status === FIELD_STATUS.VALID
            );
        }

        if (contributionType.value === CONTRIBUTION_TYPE.MONTHLY) {
            return regularContribution.status === FIELD_STATUS.VALID;
        }

        return false;
    }
);

export const getIsMaxContributionEntered = createSelector(getRegularContribution, (regularContribution) => {
    if (regularContribution.value) {
        // regularContribution.value will be "3 000"
        const regularContributionNumber = Number(regularContribution.value.split(' ').join(''));
        return regularContributionNumber === MAX_REGULAR_CONTRIBUTION;
    }
    return false;
});

export const getIsInvestmentSetupContributionValid = createSelector(
    getContributionType,
    getRegularContribution,
    getLumpSumContribution,
    getPremiumEscalation,
    getOwnPercentage,
    getIsCombinationLimitExceeded,
    (
        contributionType,
        regularContribution,
        lumpSumContribution,
        premiumEscalation,
        ownPercentage,
        maxContributionExceeded
    ) => {
        if (maxContributionExceeded) return false;
        let isValid = true;
        isValid = contributionType.value && contributionType.status === FIELD_STATUS.VALID;

        if (
            contributionType.value === CONTRIBUTION_TYPE.MONTHLY ||
            contributionType.value === CONTRIBUTION_TYPE.COMBINATION
        ) {
            isValid = isValid && regularContribution.status === FIELD_STATUS.VALID;
            isValid = isValid && premiumEscalation.status === FIELD_STATUS.VALID;
            if (premiumEscalation.value === PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key) {
                isValid = isValid && ownPercentage.status === FIELD_STATUS.VALID;
            }
        }

        if (
            contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM ||
            contributionType.value === CONTRIBUTION_TYPE.COMBINATION
        ) {
            isValid = isValid && lumpSumContribution.status === FIELD_STATUS.VALID;
        }

        return isValid;
    }
);

export const getAreContributionValuesValid = createSelector(
    getContributionType,
    getRegularContribution,
    getLumpSumContribution,
    (contributionType, regularContributionValue, lumpSumContributionValue) => {
        if (contributionType.value === CONTRIBUTION_TYPE.MONTHLY) {
            return regularContributionValue.status === FIELD_STATUS.VALID;
        }

        if (contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM) {
            return lumpSumContributionValue.status === FIELD_STATUS.VALID;
        }

        if (contributionType.value === CONTRIBUTION_TYPE.COMBINATION) {
            return (
                lumpSumContributionValue.status === FIELD_STATUS.VALID &&
                regularContributionValue.status === FIELD_STATUS.VALID
            );
        }
        return false;
    }
);

export const getDisplayLinkWithEducationOption = createSelector(
    getSavingForEducation,
    getIsMaxContributionEntered,
    getIsMinimumMonthlyContributionEntered,
    (savingForEducation, maxContributionEntered, minimumContributionEntered) => {
        return savingForEducation && !maxContributionEntered && !minimumContributionEntered;
    }
);

export const getDisplayLinkWithSalaryOption = createSelector(
    getSavingForRetirement,
    getIsMaxContributionEntered,
    getIsMinimumMonthlyContributionEntered,
    (savingForRetirement, maxContributionEntered, minimumContributionEntered) => {
        return savingForRetirement && !maxContributionEntered && !minimumContributionEntered;
    }
);

export const getPremiumEscalationValue = createSelector(
    getPremiumEscalation,
    getOwnPercentage,
    getContributionType,
    (premiumEscalation, ownPercentage, contributionType) => {
        // If the user selected lump sum input, return 0
        if (contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM) return 0;

        // If maximum annual tax free limit then we use the title, as this will go to back end
        if (premiumEscalation.value == PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.key)
            return PREMIUM_ESCALATION_OPTIONS.ANNUAL_TAX_FREE_LIMIT.title;

        // If ownpercentage was selected then return selected value
        if (premiumEscalation.value == PREMIUM_ESCALATION_OPTIONS.OWN_PERCENTAGE.key) return `${ownPercentage.value}%`;

        // Get the premium escalation option based on its key
        const premiumEscalationOption = Object.keys(PREMIUM_ESCALATION_OPTIONS).find(
            (element) => PREMIUM_ESCALATION_OPTIONS[element].key == premiumEscalation.value
        );

        if (premiumEscalation.value == PREMIUM_ESCALATION_OPTIONS.LINK_WITH_INFLATION.key) {
            return 'CPI';
        }

        return `${PREMIUM_ESCALATION_OPTIONS[premiumEscalationOption].percentage}%`;
    }
);

/*
 * Conditions of the following cases:
 *
 * Regular contribution only
 *   REGULAR_WITH_ADMIN:                 Regular premium < 2750
 *   REGULAR_NO_ADMIN:                   Regular premium >= 2750
 *
 * Lump sum contribution only
 *   LUMP_SUM_NO_REGULAR_WITH_ADMIN:     Lump sum < 15000
 *   LUMP_SUM_NO_REGULAR_NO_ADMIN:       Lump sum >= 15000 && Lump sum < 33000
 *   LUMP_SUM_WITH_REGULAR_NO_ADMIN:     Lump sum >= 33000
 *
 * Combination (both monthly and regular contribution)
 *   LOW_REGULAR_HIGH_COMBO:            Regular < 2500 && ((Regular*12) + Lump sum >= 33000)
 *   HIGH_REGULAR_HIGH_COMBO:           Regular >= 2500 && ((Regular*12) + Lump sum >= 33000)
 *   LOW_COMBO:                         ((Regular*12) + Lump sum < 33000)
 */
export const getEacInformation = createSelector(
    getContributionType,
    getRegularContribution,
    getLumpSumContribution,
    (contributionType, regularContribution, lumpSumContribution) => {
        const copyEnd = ' into the Old Mutual Balanced Fund';

        if (contributionType.value === CONTRIBUTION_TYPE.MONTHLY) {
            if (regularContribution.value < 2750) {
                return {
                    copy: 'Assumes R500 monthly investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.REGULAR_WITH_ADMIN,
                };
            } else {
                return {
                    copy: 'Assumes R2750 monthly investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.REGULAR_NO_ADMIN,
                };
            }
        }

        if (contributionType.value === CONTRIBUTION_TYPE.LUMP_SUM) {
            if (lumpSumContribution.value < 15000) {
                return {
                    copy: 'Assumes R5000 once off investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.LUMP_SUM_NO_REGULAR_WITH_ADMIN,
                };
            } else if (lumpSumContribution.value < 33000) {
                return {
                    copy: 'Assumes R15000 once off investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.LUMP_SUM_NO_REGULAR_NO_ADMIN,
                };
            } else {
                return {
                    copy: 'Assumes R33000 once off investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.LUMP_SUM_WITH_REGULAR_NO_ADMIN,
                };
            }
        }

        if (contributionType.value === CONTRIBUTION_TYPE.COMBINATION) {
            const totalAnnualInvestment = regularContribution.value * 12 + Number(lumpSumContribution.value);
            if (totalAnnualInvestment < 33000) {
                return {
                    copy: 'Assumes R500 monthly investment and R5000 once off investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.LOW_COMBO,
                };
            }

            if (regularContribution.value < 2500) {
                return {
                    copy: 'Assumes R500 monthly investment and R30000 once off investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.LOW_REGULAR_HIGH_COMBO,
                };
            } else {
                return {
                    copy: 'Assumes R2500 monthly investment and R5000 once off investment' + copyEnd,
                    eac: EFFECTIVE_ANNUAL_COST_AMOUNTS.HIGH_REGULAR_HIGH_COMBO,
                };
            }
        }

        return {};
    }
);
