import {
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
    navigateToAboutYou,
    navigateToDetails,
    navigateToPayment,
    navigateToProducts,
    navigateToQuotation,
} from '../../../actions/roaCarInsurance/routing';
import { getActivePageIndex } from '../../../selectors/roaCarInsurance/routing';
import { PAGE_INDICES } from '../../../actions/roaCarInsurance/types';

export const routingRules = (store, next, action) => {
    if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS)
        return next(action);
    const result = next(action);
    const state = store.getState();
    const activePageIndex = getActivePageIndex(state);
    if (action.type === NAVIGATE_NEXT) {
        onNavigateNext(store, state, activePageIndex);
        return result;
    }
    onNavigatePrevious(store, state, activePageIndex);
    return result;
};

const onNavigateNext = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.QUOTATION:
            store.dispatch(navigateToAboutYou());
            break;

        case PAGE_INDICES.ABOUT_YOU:
            store.dispatch(navigateToProducts());
            break;

        case PAGE_INDICES.PRODUCTS:
            store.dispatch(navigateToDetails());
            break;

        case PAGE_INDICES.DETAILS:
            store.dispatch(navigateToPayment());
            break;
        default:
            // store.dispatch(navigateToQuotation());
            break;
    }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.ABOUT_YOU:
            store.dispatch(navigateToQuotation());
            break;

        case PAGE_INDICES.PRODUCTS:
            store.dispatch(navigateToAboutYou());
            break;

        case PAGE_INDICES.DETAILS:
            store.dispatch(navigateToProducts());
            break;
        case PAGE_INDICES.PAYMENT:
            store.dispatch(navigateToDetails());
            break;
        default:
            // store.dispatch(navigateToQuotation());
            break;
    }
};
