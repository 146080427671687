import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/ugMotorInsurance/types';
import { getUgMotorInsuranceState } from './index';

const getRouting = createSelector(getUgMotorInsuranceState, (state) => state.routing);
export const getActivePageIndex = createSelector(getRouting, (routing) => routing.activePageIndex);
export const getDoMotorInsuranceNavigation = createSelector(getRouting, (routing) => routing.doNavigation);
export const getDoExitMotorInsuranceNavigation = createSelector(getRouting, (routing) => routing.doExitNavigation);

export const getLandingPage = createSelector(getRouting, (routing) => routing.landingPage);

export const getIsDropoffPage = createSelector(
    getRouting,
    (routing) => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU
);

const makeGetIsPage = (pageIndex) => createSelector(getRouting, (routing) => routing.activePageIndex === pageIndex);
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QOUTE);
export const getIsPersonalDetailsPage = makeGetIsPage(PAGE_INDICES.PERSONAL_DETAILS);
export const getIsCarDetailsPage = makeGetIsPage(PAGE_INDICES.CAR_DETAILS);
export const getIsNextOfKinPage = makeGetIsPage(PAGE_INDICES.NEXT_OF_KIN);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const getProgressBarIndex =
    gatsbyStack === 'ke'
        ? createSelector(
              getIsQuotePage,
              getIsPersonalDetailsPage,
              getIsCarDetailsPage,
              getIsNextOfKinPage,
              getIsPaymentPage,
              (isQuotePage, isPersonalDetailsPage, isCarDetailsPage, isNextOfKinPage, isPaymentPage) => {
                  if (isQuotePage) return 0;
                  if (isPersonalDetailsPage) return 1;
                  if (isCarDetailsPage) return 2;
                  if (isNextOfKinPage) return 3;
                  if (isPaymentPage) return 4;
              }
          )
        : createSelector(
              getIsQuotePage,
              getIsPersonalDetailsPage,
              getIsNextOfKinPage,
              getIsCarDetailsPage,
              getIsPaymentPage,
              (isQuotePage, isPersonalDetailsPage, isNextOfKinPage, isCarDetailsPage, isPaymentPage) => {
                  if (isQuotePage) return 0;
                  if (isPersonalDetailsPage) return 1;
                  if (isNextOfKinPage) return 2;
                  if (isCarDetailsPage) return 3;
                  if (isPaymentPage) return 4;
              }
          );
