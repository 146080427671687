
import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import ReferenceData from '../../refData/reference-data.js';
import {config} from '../config/index.js';

export function validatePostalCode(
    value,
    {
        required = true,
        subject = 'Postal code',
        max = 4,
        pronoun = config.PRONOUN.YOUR,
        retainSubjectCase
    } = {}
) {
    if (!value && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (!value && !required) {
        return null;
    }

    if (max && (value.trim().length > max)){
        return OmValidationMessageHelper.getStringTooLongMsg(subject, max, pronoun, retainSubjectCase);
    }

    if (!ReferenceData.streetPostalCodes.includes(value) && !ReferenceData.boxPostalCodes.includes(value)) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    return null;
}
