import { transitionAgentModalForm } from '../../../actions/ugMotorInsurance/motorAgentModal';
import { getAgentForm } from '../../../selectors/ugMotorInsurance/travelAgentModal';

import { FIELD_STATUS } from '../../../reducers/status';

export const agentModaleFormRules = (store, next, action) => {
    const result = next(action);
    const motorAgentModalForm = getAgentForm(store.getState());
    const agentModalForm = validateAgentModalForm(motorAgentModalForm);
    const formAction = agentModalForm
        ? transitionAgentModalForm.formValid()
        : transitionAgentModalForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateAgentModalForm = formFields => {
    return (
        formFields.name &&
        formFields.name.status === FIELD_STATUS.VALID
        // formFields.agentCode &&
        // formFields.agentCode.status === FIELD_STATUS.VALID
    );
};
