import { createSelector } from 'reselect';
import { getBiasharaFlexiState } from './index';
import { PAGE_INDICES, PAGE_INDICES_PAY_NOW } from '../../actions/biasharaFlexi/types';

const getRouting = createSelector(getBiasharaFlexiState, (state) => state?.routing);
export const getActivePageIndex = createSelector(
    getRouting,
    routing => routing?.activePageIndex,
);
export const getDoBiasharaFlexiNavigation = createSelector(
    getRouting,
    routing => routing?.doNavigation,
);
export const getDoExitBiasharaFlexiNavigation = createSelector(
    getRouting,
    routing => routing?.doExitNavigation,
);

export const getLandingPage = createSelector(
    getRouting,
    routing => routing?.landingPage,
);

export const getIsDropoffPage = createSelector(
    getRouting,
    routing => routing?.activePageIndex === PAGE_INDICES.ABOUT_YOU,
);

const makeGetIsPage = pageIndex =>
    createSelector(
        getRouting,
        routing => routing?.activePageIndex === pageIndex,
    );
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QOUTE);
export const getIsQuotePagePayNow = makeGetIsPage(PAGE_INDICES_PAY_NOW.QOUTE);
export const getIsCompanyDetailsPage = makeGetIsPage(PAGE_INDICES.COMPANY_DETAILS);
export const getIsCompanyDetailsPagePayNow = makeGetIsPage(PAGE_INDICES_PAY_NOW.COMPANY_DETAILS);
export const getIsEmployeeDetailsPage = makeGetIsPage(PAGE_INDICES.EMPLOYEE_DETAILS);
export const getIsUploadDetailsPage = makeGetIsPage(PAGE_INDICES.UPLOAD_DOCUMENTS);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);
export const getIsPaymentPagePayNow = makeGetIsPage(PAGE_INDICES_PAY_NOW.PAYMENT);

export const getProgressBarIndex = createSelector(
	getIsQuotePage,
	getIsCompanyDetailsPage,
	getIsEmployeeDetailsPage,
	getIsUploadDetailsPage,
	getIsPaymentPage,
	(isQuotePage, isCompanyDetailsPage, isEmployeeDetailsPage, isUploadDetailsPage, isPaymentPage) => {
		if (isQuotePage) return 0;
		if (isCompanyDetailsPage) return 1;
		if (isEmployeeDetailsPage) return 2;
		if (isUploadDetailsPage) return 3;
		if (isPaymentPage) return 4;
	}
);

export const getProgressBarPayNowIndex = createSelector(
	getIsQuotePagePayNow,
	getIsCompanyDetailsPagePayNow,
	getIsPaymentPagePayNow,
	(isQuotePage, isCompanyDetailsPage, isPaymentPage) => {
		if (isQuotePage) return 0;
		if (isCompanyDetailsPage) return 1;
		if (isPaymentPage) return 2;
	}
);
