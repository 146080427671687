import { getDigiLifeLoanProtection } from "./index";
import { createSelector } from "reselect";
import { FORM_STATUS, TOGGLE_STATUS } from "../../reducers/status";
import { SUPPORTED_BANKS   } from "../../middleware/constants"
import { getReferenceAccountTypes, getReferenceBanks, getReferenceDebitOrderDates } from "./referenceData";
import { ACCOUNT_VALIDATION_STATUS } from "../../actions/digiLifeLoanProtection/types";

export const getPaymentDetails = createSelector(
    getDigiLifeLoanProtection,
    digiLifeLoanProtection => digiLifeLoanProtection.paymentDetails
);

export const getPaymentDetailsValid = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.formStatus === FORM_STATUS.VALID,
);

export const getAccountValidationStatus = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.accountValidationStatus
)

export const getPaymentDetailsForm = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.form,
);

export const getPaymentAuthorizationValid = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.form.paymentAuthorization.value === TOGGLE_STATUS.YES,
);

export const getPaymentAuthorizationFalse = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.form.paymentAuthorization.value === TOGGLE_STATUS.NO,
);

export const getPaymentAuthorizationInvalid = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.form.paymentAuthorization.value === FORM_STATUS.INVALID,
);


const makeGetField = fieldId => createSelector(
    getPaymentDetailsForm,
    paymentDetailsForm => paymentDetailsForm[fieldId]
);

// Payment
export const getBankName = makeGetField('bankName');
export const getBranchCode = makeGetField('branchCode');
export const getAccountNumber = makeGetField('accountNumber');
export const getAccountHolder = makeGetField('accountHolder');
export const getAccountType = makeGetField('accountType');
export const getDebitOrderDay = makeGetField('debitOrderDay');
export const getPaymentAuthorization = makeGetField('paymentAuthorization');

const formatName = (name) =>  {
    if (name && name.length <= 4) {
        return name;
    } else if (name) {
        name = name.replace(/([a-z])([A-Z])/g, '$1 $2');;
        return name;
    }
}

export const getFormattedBankName = createSelector(
    getBankName,
    (bankName) => formatName(bankName.displayValue)
);

export const getFormattedAccountName = createSelector(
    getAccountType,
    (accountType) => formatName(accountType.displayValue)
);

// Submission
export const getBankDataEntry = createSelector(
    getBankName,
    getReferenceBanks,
    (bankName, referenceBanks) => {
        return referenceBanks.find(ref => ref.code === bankName.value)
    }
);
export const getAccountTypeDataEntry = createSelector(
    getAccountType,
    getReferenceAccountTypes,
    (accountType, referenceAccountTypes) => {
        return referenceAccountTypes.find(ref => ref.code === accountType.value)
    }
);
export const getDebitOrderDate = createSelector(
    getDebitOrderDay,
    getReferenceDebitOrderDates,
    (debitOrderDay, referenceDebitOrderDates) => {
        return referenceDebitOrderDates.find(ref => ref.description === debitOrderDay.value);
    }
);

export const getPaymentSubmission = createSelector(
    getBankDataEntry,
    getAccountNumber,
    getAccountTypeDataEntry,
    getDebitOrderDate,
    (bankDataEntry, accountNumber, accountTypeDataEntry, debitOrderDate) => {
        return {
            debitOrderDateCode: debitOrderDate.code,
            bankDetail: {
                accountNumber: accountNumber.value && accountNumber.value.split(' ').join(''),
                bank: bankDataEntry,
                accountType: accountTypeDataEntry,
            }
        };
    }
);

export const getPaymentDetailsCompleted = createSelector(
    getBankName,
    getAccountNumber,
    getAccountType,
    (bankName, accountNumber, accountType) => {
        return (bankName.status === 'valid' &&
                accountNumber.status === 'valid' &&
                accountType.status === 'valid')
    }
);

// this value is set on mounted via contentstack
export const getIncludeAvs = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.includeAvs,
);

export const getIncludeCdv = createSelector(
    getPaymentDetails,
    paymentDetails => paymentDetails.includeCdv,
);

export const getBankSupportedForEFP = createSelector(
    getBankName,
    bankName => {
        return SUPPORTED_BANKS.some(bank => bank.bankName === bankName.value)
    }
);
export const getPayloadBankName = createSelector(
    getBankName,
    bankName =>  {
        const bank = SUPPORTED_BANKS.find(bank => bank.bankName === bankName.value);
        return bank ? bank.verifictionFormattedName : '';
    }
);


// Success modal must only be dispalyed in one of the following cases;
// 1. Account Validation passed and Include AVS is active and Bank Account is in list of supported banks 
// 2. Account Validation passed and Include CDV is active
export const getDisplaySuccessModal = createSelector(
    getAccountValidationStatus,
    getBankSupportedForEFP,
    getIncludeAvs,
    getIncludeCdv,
    (accountValidationStatus, bankSupported, includeAvs, includeCdv) => {
        if (accountValidationStatus !== ACCOUNT_VALIDATION_STATUS.PASSED) return false;
        if ((includeAvs && bankSupported) || includeCdv) return true;
    }
);

