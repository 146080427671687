import { createSelector } from 'reselect';
import { round } from '../../../tvm-calcs/src';
import { API_CALL_STATUS } from '../../reducers/status';
import { getFundDeclarations } from './index';

export const getFundDeclaration = createSelector(
    getFundDeclarations,
    (fundDeclaration) => fundDeclaration.fundDeclaration
);

export const getApiCallStatus = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.apiCallStatus
);

export const getIsApiPending = createSelector(
    getApiCallStatus,
    (apiCallStatus) => apiCallStatus === API_CALL_STATUS.PENDING
);

export const getIsApiFailure = createSelector(
    getApiCallStatus,
    (apiCallStatus) => apiCallStatus === API_CALL_STATUS.FAILURE
);

export const getApiNameCallStatus = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.apiNameCallStatus
);

export const getIsNameApiSuccess = createSelector(
    getApiNameCallStatus,
    (apiNameCallStatus) => apiNameCallStatus === API_CALL_STATUS.SUCCESS
);

export const getIsNameApiPending = createSelector(
    getApiNameCallStatus,
    (apiNameCallStatus) => apiNameCallStatus === API_CALL_STATUS.PENDING
);

export const getIsNameApiFailure = createSelector(
    getApiNameCallStatus,
    (apiNameCallStatus) => apiNameCallStatus === API_CALL_STATUS.FAILURE
);

export const getSelectedFundId = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.form.selectedFundId
);

export const getFunds = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.fundData
);

export const getFundsFromSelection = createSelector(
    getFunds,
    getSelectedFundId,
    (fundData, selectedFundId) =>
        Array.isArray(fundData)
            ? fundData
                  .filter((fund) => fund.fundId === selectedFundId)
                  .sort(function(a, b) {
                      const dateA = a.date;
                      const dateB = b.date;
                      return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
                  })
                  .map((fund) => {
                      const isoDate = new Date(fund.date);
                      const dateDisplayString = `${isoDate.getDate()} ${isoDate.toLocaleString(
                          'default',
                          { month: 'short' }
                      )} ${isoDate.getFullYear()}`;

                      return {
                          fundId: fund.fundId,
                          date: dateDisplayString,
                          dividendsCentsPerUnit: round(
                              parseFloat(fund.dividendsCentsPerUnit),
                              2
                          ),
                          interestCentsPerUnit: round(
                              parseFloat(fund.interestCentsPerUnit),
                              2
                          ),
                          distributionCentsPerUnit: round(
                              parseFloat(fund.distributionCentsPerUnit),
                              2
                          ),
                      };
                  })
            : []
);

function getUniqueByFundId(value, index, arr) {
    return arr.map((obj) => obj['FundId']).indexOf(value['FundId']) === index;
}

export const getFundNames = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.fundNames
);

export const getListOfFunds = createSelector(getFundNames, (fundNames) =>
    Array.isArray(fundNames)
        ? fundNames
              .filter(getUniqueByFundId)
              .map((fundItem) => ({
                  fundId: fundItem.FundId,
                  fundName: fundItem.FundName.trim(),
              }))
              .sort(function(a, b) {
                  const nameA = a.fundName;
                  const nameB = b.fundName;
                  return nameA.localeCompare(nameB);
              })
        : []
);

export const getPaginationRanges = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.paginationRanges
);

export const getPaginationTableSize = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.paginationTableSize
);

export const getModalType = createSelector(
    getFundDeclaration,
    (fundDeclaration) => fundDeclaration.modalType
);
