//Transitions
export const FORM_VALID_TRANSITION_CONFIRM_DETAILS = 'easiplusFuneralPlan/confirmDetails/transition/formValid';
export const FORM_INVALID_TRANSITION_CONFIRM_DETAILS = 'easiplusFuneralPlan/confirmDetails/transition/formInvalid';

export const TOGGLE_DECLARATIONS_CONFIRM_DETAILS = 'easiplusFuneralPlan/confirmDetails/transition/toggleDeclarations';

export const toggleDeclarations = () => ({ type: TOGGLE_DECLARATIONS_CONFIRM_DETAILS });
export const setDeclarationsInvalid = error => ({ type: TOGGLE_DECLARATIONS_CONFIRM_DETAILS, error });
export const resetDeclaration = () => ({ type: RESET_VALUE_DECLARATION });

export const transitionConfirmDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CONFIRM_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CONFIRM_DETAILS })
};

// Transition - reset state
export const RESET_STATE_TRANSITION_CONFIRM_DETAILS = 'easiplusFuneralPlan/confirmDetails/transition/resetState';
export const resetConfirmDetails = () => ({ type: RESET_STATE_TRANSITION_CONFIRM_DETAILS });

export const CONFIRM_DETAILS_SET_VALUE = 'easiplusFuneralPlan/confirmDetails/setValue/';
export const CONFIRM_DETAILS_SET_VALID = 'easiplusFuneralPlan/confirmDetails/setValid/';

export const RESET_VALUE_DECLARATION = CONFIRM_DETAILS_SET_VALUE + 'declarations';

export const DECLARATIONS_SIDEDRAWER_SET_VALUE = CONFIRM_DETAILS_SET_VALUE + 'declarationsSideDrawer';
export const DECLARATIONS_SIDEDRAWER_SET_VALID = CONFIRM_DETAILS_SET_VALID + 'declarationsSideDrawer';
export const SIDE_DRAWER_CLOSED_SET_VALUE = CONFIRM_DETAILS_SET_VALUE + 'closeSideDrawer';
export const setDeclarationsDrawer = value => ({ type: DECLARATIONS_SIDEDRAWER_SET_VALUE, value });
export const setDeclarationsDrawerValidationMessage = error => ({ type: DECLARATIONS_SIDEDRAWER_SET_VALID, error });
export const setSideDrawersClosed = () => ({ type: SIDE_DRAWER_CLOSED_SET_VALUE });

// Transitions - submission
export const EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION = 'easiplusFuneralPlan/confirmDetails/submitApplication/';

export const EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND = EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION + 'send';
export const EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_FAILURE = EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION + 'failure';
export const EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS = EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION + 'success';

export const sendConfirmDetailsSubmitApplication = () => ({ type: EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND });
export const sendConfirmDetailsSubmitApplicationFailure = () => ({ type: EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_FAILURE });
export const sendConfirmDetailsSubmitApplicationSuccess = value => ({ type: EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS, value });

export const EFP_SKIP_CONFIRMATION_PAGE_SET_VALUE = CONFIRM_DETAILS_SET_VALUE + 'skipConfirmationPage';
export const setSkipConfirmationPageValue = value => ({ type: EFP_SKIP_CONFIRMATION_PAGE_SET_VALUE, value });
