import { combineReducers } from 'redux';
import investmentCalculator from './investmentCalculator';
import emergencyFundCalculator from './emergencyFund';
import buildWealthCalculator from './buildWealth';
import buildWealthFundSplit from './buildWealthFundSplit';
import shortTermGoalCalculator from './shortTermGoal';
import shortTermGoalFundSplit from './shortTermGoalFundSplit';
import longTermGoalCalculator from './longTermGoal';
import longTermGoalFundSplit from './longTermGoalFundSplit';
import generateRegularIncomeCalculator from "./generateRegularIncomeCalculator";

export const investmentCalculators = combineReducers({
	investmentCalculator,
	emergencyFundCalculator,
	buildWealthCalculator,
	buildWealthFundSplit,
	shortTermGoalFundSplit,
	shortTermGoalCalculator,
	longTermGoalCalculator,
	longTermGoalFundSplit,
    generateRegularIncomeCalculator,
});
