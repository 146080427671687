import { SERVICE_PROVIDER_SET_VALUE } from "../../../actions/customerVerificationService/consent";
import { getServiceProvider } from "../../../selectors/customerVerificationService/consent";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";
import { SERVICE_PROVIDER_NAMES_FOR_TRACKING } from '../../../actions/customerVerificationService/types'

export const consentTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const serviceProvider = (() => {
        switch (action.type) {
            case SERVICE_PROVIDER_SET_VALUE: 
                return getServiceProvider(state) === 1 ? SERVICE_PROVIDER_NAMES_FOR_TRACKING[0] : SERVICE_PROVIDER_NAMES_FOR_TRACKING[1];
            default: return null;
        }
    })();

    if (serviceProvider) {
        otherEventTracking(action.type, findOtherEventIndex("SERVICE_PROVIDER"), serviceProvider );
    }

    return result;
};
