import { getPayloadData, getIsApiPending } from '../../../selectors/digitalSavings/payloadCollection';
import { setExpiredAuth } from '../../../actions/digitalSavings/quote';
import {
    apiTransitionOrderConfirmation,
    confirmOrderSuccess,
    confirmOrderFailure,
} from '../../../actions/digitalSavings/payment';
import { getAccessToken, getIsConfirmOrderAPiPending, getPaymentsForm, getPaymentStatusData } from '../../../selectors/digitalSavings/payment';
import axios from 'axios';
import { getPremiumCalculation, getQuoteForm } from '../../../selectors/digitalSavings/quote';
import { getPersonalDetailsForm } from '../../../selectors/digitalSavings/personalDetails';

export const orderConfirmationService = (store, next, action) => {
    (async () => {
        if (!getIsConfirmOrderAPiPending(store.getState())) {
            store.dispatch(apiTransitionOrderConfirmation.pending());
            try {
                const resp = await confirmInsuranceOrder(store.getState());
                store.dispatch(confirmOrderSuccess(resp.data));
                store.dispatch(apiTransitionOrderConfirmation.success());
            } catch (e) {
                if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
                    store.dispatch(setExpiredAuth(true));
                }
                store.dispatch(apiTransitionOrderConfirmation.failure());
                store.dispatch(confirmOrderFailure(e));
            }
        }
    })();
    return next(action);
};

const confirmOrderPayload = (state) => {
    const payloadData = getPayloadData(state);
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const payments = getPaymentsForm(state);
    const premium = getPremiumCalculation(state);
    const paymentsData = getPaymentStatusData(state);

    const payload = {
        rowId: payloadData ? payloadData.quotation.userQuote.rowId : null,
        orderStatus: 'paid',
        monthlyContribution: quotation.desired.value || null,
        lumpsumContribution: quotation.lumpsum.value || null,
        firstName: personalDetails.firstName.value || null,
        lastName: personalDetails.surname.value || null,
        phoneNumber: personalDetails.mobileNumber.value || null,
        emailAddress: personalDetails.email.value || null,
        paymentMethod: payments.networkProvider.value === 'mpesa'
        ? 'mpesa'
        : payments.networkProvider.value === 'bankTransfer'
        ? 'bank'
        : null,
        paymentReference: typeof paymentsData?.CheckoutRequestID === 'undefined' ? null : paymentsData?.CheckoutRequestID,
        bankPaymentAccountNumber: null,
        countryCode: 'ke',
    };

    // Change payload here
    return {
        ...payload,
    };
};

const confirmInsuranceOrder = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/digital-savings/order/confirmation';
        const response = await axios.post(url, confirmOrderPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
        return response;
    } catch (e) {
        if (e?.response?.data?.status === 401 || e?.response?.data?.status === 403) {
            store.dispatch(setExpiredAuth(true));
        }
        throw e;
    }
};
