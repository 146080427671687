import {
  TRAVELLED_RECENTLY_SET_VALUE,
  RECENT_COVID_CONTACT_SET_VALUE,
} from "../../../actions/covidSelfAssessment/travelHistory"
import {
  getTravelledRecently,
  getRecentCovidContact,
} from "../../../selectors/covidSelfAssessment/travelHistory"
import {simpleFieldTracking} from "../formTracking";

export const travelHistoryFieldTracking = (store, next, action) => {
  const result = next(action);
  const state = store.getState();
  const field = (() => {
      switch (action.type) {
          case TRAVELLED_RECENTLY_SET_VALUE: return getTravelledRecently(state);
          case RECENT_COVID_CONTACT_SET_VALUE: return getRecentCovidContact(state);
          default: return null;
      }
  })();

  if (field) {
      simpleFieldTracking({action, field});
  }

  return result;
};

