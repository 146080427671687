import { FIELD_STATUS, BENEFICIARY_DRAWER } from "../status";
import {
  TITLE_SET_VALID,
  TITLE_SET_VALUE,
  TITLE_RESET_VALUE,
  FIRST_NAME_SET_VALID,
  FIRST_NAME_SET_VALUE,
  FIRST_NAME_RESET_VALUE,
  SURNAME_SET_VALID,
  SURNAME_SET_VALUE,
  SURNAME_RESET_VALUE,
  DATE_OF_BIRTH_SET_VALID,
  DATE_OF_BIRTH_SET_VALUE,
  DATE_OF_BIRTH_RESET_VALUE,
  GENDER_SET_VALID,
  GENDER_SET_VALUE,
  GENDER_RESET_VALUE,
  MOBILE_NUMBER_SET_VALID,
  MOBILE_NUMBER_SET_VALUE,
  MOBILE_NUMBER_RESET_VALUE,
  EMAIL_SET_VALID,
  EMAIL_SET_VALUE,
  EMAIL_RESET_VALUE,
  EDIT_DRAWER_TOGGLE,
  REMOVE_ID_SET,
  EDIT_ID_SET,
  EDIT_BENEFICIARY_SET_FORM,
  EDIT_BENEFICIARY_RESET_FORM,
  BENEFICIARIES_LIST_UPDATE,
  RESET_BENEFICIARIES,
} from "../../actions/taxFreeSavingsAccount/beneficiaries";

const beneficiary = () => ({
  title: { value: null, error: null, status: null },
  firstName: { value: null, error: null, status: null },
  surname: { value: null, error: null, status: null },
  dateOfBirth: { value: null, error: null, status: null },
  gender: { value: null, error: null, status: null },
  mobileNumber: { value: null, error: null, status: null },
  email: { value: null, error: null, status: null },
  isGenderDisabled: false,
});
export const editBeneficiary = () => ({
  form: beneficiary(),
});
export const initBeneficiaries = {
  editId: null,
  removeId: null,
  openDrawer: null,
  editBeneficiary: editBeneficiary(),
  beneficiaries: [],
};

export default (state = initBeneficiaries, action) => {
  const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
  const updateFormField = (fieldId, update) => ({
    ...state,
    editBeneficiary: {
      ...state.editBeneficiary,
      form: {
        ...state.editBeneficiary.form,
        [fieldId]: update(state.editBeneficiary.form[fieldId]),
      },
    },
  });

  switch (action.type) {
    case REMOVE_ID_SET:
      return { ...state, removeId: action.value };

    case EDIT_ID_SET:
      return { ...state, editId: action.value };

    case EDIT_DRAWER_TOGGLE:
      return {
        ...state,
        openDrawer: state.openDrawer ? null : BENEFICIARY_DRAWER.EDIT,
      };

    case EDIT_BENEFICIARY_SET_FORM:
      return {
        ...state,
        editBeneficiary: { form: Object.assign({}, action.form) },
      };

    case EDIT_BENEFICIARY_RESET_FORM:
      return { ...state, editBeneficiary: editBeneficiary() };

    case BENEFICIARIES_LIST_UPDATE:
      return {
        ...state,
        beneficiaries: [...action.list],
        editId: null,
        editBeneficiary: editBeneficiary(),
      };

    // Form Fields
    case TITLE_SET_VALUE:
      return updateFormField("title", (field) => ({
        ...field,
        value: action.value,
      }));
    case TITLE_SET_VALID:
      return updateFormField("title", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case TITLE_RESET_VALUE:
      return updateFormField("title", (field) => ({
        ...field,
        value: null,
        error: null,
        status: null,
      }));

    case FIRST_NAME_SET_VALUE:
      return updateFormField("firstName", (field) => ({
        ...field,
        value: action.value,
      }));
    case FIRST_NAME_SET_VALID:
      return updateFormField("firstName", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case FIRST_NAME_RESET_VALUE:
      return updateFormField("firstName", (field) => ({
        ...field,
        value: null,
        error: null,
        status: null,
      }));

    case SURNAME_SET_VALUE:
      return updateFormField("surname", (field) => ({
        ...field,
        value: action.value,
      }));
    case SURNAME_SET_VALID:
      return updateFormField("surname", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case SURNAME_RESET_VALUE:
      return updateFormField("surname", (field) => ({
        ...field,
        value: null,
        error: null,
        status: null,
      }));

    case DATE_OF_BIRTH_SET_VALUE:
      return updateFormField("dateOfBirth", (field) => ({
        ...field,
        value: action.value,
      }));
    case DATE_OF_BIRTH_SET_VALID:
      return updateFormField("dateOfBirth", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case DATE_OF_BIRTH_RESET_VALUE:
      return updateFormField("dateOfBirth", (field) => ({
        ...field,
        value: null,
        error: null,
        status: null,
      }));

    case GENDER_SET_VALUE:
      return updateFormField("gender", (field) => ({
        ...field,
        value: action.value,
      }));
    case GENDER_SET_VALID:
      return updateFormField("gender", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case GENDER_RESET_VALUE:
      return updateFormField("gender", (field) => ({
        ...field,
        value: null,
        error: null,
        status: null,
      }));

    case MOBILE_NUMBER_SET_VALUE:
      return updateFormField("mobileNumber", (field) => ({
        ...field,
        value: action.value,
      }));
    case MOBILE_NUMBER_SET_VALID:
      return updateFormField("mobileNumber", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case MOBILE_NUMBER_RESET_VALUE:
      return updateFormField("mobileNumber", (field) => ({
        ...field,
        value: null,
        error: null,
        status: null,
      }));

    case EMAIL_SET_VALUE:
      return updateFormField("email", (field) => ({
        ...field,
        value: action.value,
      }));
    case EMAIL_SET_VALID:
      return updateFormField("email", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case EMAIL_RESET_VALUE:
      return updateFormField("email", (field) => ({
        ...field,
        value: null,
        error: null,
        status: null,
      }));

    case RESET_BENEFICIARIES:
      return { ...initBeneficiaries };

    default:
      return state;
  }
};
