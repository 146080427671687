// Transitions - validation
export const FORM_VALID_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/formValid';
export const FORM_INVALID_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/formInvalid';

export const transitionBeneficiaries = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_BENEFICIARIES }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_BENEFICIARIES }),
};

// Transitions - navigation
export const CONTINUE_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/validContinue';
export const BACK_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/back';
export const continueBeneficiaries = () => ({ type: CONTINUE_TRANSITION_BENEFICIARIES });
export const backBeneficiaries = () => ({ type: BACK_TRANSITION_BENEFICIARIES });

// Transitions - CRUD Beneficiaries
export const ADD_NEW_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/addNewBeneficiary';
export const DELETE_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/deleteBeneficiary';
export const EDIT_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/editBeneficiary';
export const SAVE_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/saveBeneficiary';
export const CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/cancelEditOfBeneficiary';
export const addNewBeneficiary = () => ({ type: ADD_NEW_TRANSITION_BENEFICIARIES });
export const editBeneficiary = beneficiaryId => ({ type: EDIT_TRANSITION_BENEFICIARIES, beneficiaryId });
export const deleteBeneficiary = beneficiaryId => ({ type: DELETE_TRANSITION_BENEFICIARIES, beneficiaryId });
export const saveBeneficiary = beneficiaryId => ({ type: SAVE_TRANSITION_BENEFICIARIES, beneficiaryId });
export const cancelEditOfBeneficiary = () => ({ type: CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES });

// Transitions - side drawers
export const TOGGLE_READ_MORE_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/toggleReadMore';
export const TOGGLE_EDIT_TRANSITION_BENEFICIARIES = 'raOptimal/beneficiaries/transition/toggleEdit';
export const toggleReadMoreDrawer = () => ({ type: TOGGLE_READ_MORE_TRANSITION_BENEFICIARIES });
export const toggleEditDrawer = () => ({ type: TOGGLE_EDIT_TRANSITION_BENEFICIARIES });

// Events - form input
export const BENEFICIARIES_SET_VALUE = 'raOptimal/beneficiaries/setValue/';
export const BENEFICIARIES_SET_VALID = 'raOptimal/beneficiaries/setValid/';

export const TITLE_SET_VALUE = BENEFICIARIES_SET_VALUE + 'title';
export const TITLE_SET_VALID = BENEFICIARIES_SET_VALID + 'title';
export const FIRST_NAME_SET_VALUE = BENEFICIARIES_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = BENEFICIARIES_SET_VALID + 'firstName';
export const SURNAME_SET_VALUE = BENEFICIARIES_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = BENEFICIARIES_SET_VALID + 'surname';
export const DATE_OF_BIRTH_SET_VALUE = BENEFICIARIES_SET_VALUE + 'dateOfBirth';
export const DATE_OF_BIRTH_SET_VALID = BENEFICIARIES_SET_VALID + 'dateOfBirth';
export const GENDER_SET_VALUE = BENEFICIARIES_SET_VALUE + 'gender';
export const GENDER_SET_VALID = BENEFICIARIES_SET_VALID + 'gender';
export const MOBILE_NUMBER_SET_VALUE = BENEFICIARIES_SET_VALUE + 'mobileNumber';
export const MOBILE_NUMBER_SET_VALID = BENEFICIARIES_SET_VALID + 'mobileNumber';
export const EMAIL_SET_VALUE = BENEFICIARIES_SET_VALUE + 'email';
export const EMAIL_SET_VALID = BENEFICIARIES_SET_VALID + 'email';

export const setTitleValue = value => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = error => ({ type: TITLE_SET_VALID, error });
export const setFirstNameValue = value => ({ type: FIRST_NAME_SET_VALUE, value });
export const setFirstNameValid = error => ({ type: FIRST_NAME_SET_VALID, error });
export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });
export const setDateOfBirthValue = value => ({ type: DATE_OF_BIRTH_SET_VALUE, value });
export const setDateOfBirthValid = error => ({ type: DATE_OF_BIRTH_SET_VALID, error });
export const setGenderValue = value => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = error => ({ type: GENDER_SET_VALID, error });
export const setMobileValue = value => ({ type: MOBILE_NUMBER_SET_VALUE, value });
export const setMobileValid = error => ({ type: MOBILE_NUMBER_SET_VALID, error });
export const setEmailValue = value => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = error => ({ type: EMAIL_SET_VALID, error });


// Tracking helpers
export const BENEFICIARY_TRACKING = "raOptimal/beneficiaries/tracking/";
export const DELETE_BENEFICIARY_POPUP_TRACKING = BENEFICIARY_TRACKING + 'deleteBenificiaryPopupCount';
export const ADD_MORE_THAN_MAX_BENEFICIARIES_TRACKING = BENEFICIARY_TRACKING + 'maxBeneficiaries';

export const setDeleteBenificiaryPopupTracking = () => ({type: DELETE_BENEFICIARY_POPUP_TRACKING});
export const setAddMoreThanMaxBeneficiariesTracking = () => ({type: ADD_MORE_THAN_MAX_BENEFICIARIES_TRACKING});

export const REMOVE_ID_SET = BENEFICIARIES_SET_VALUE + 'removeId';
export const setRemoveId = value => ({type: REMOVE_ID_SET, value});

export const RESET_BENEFICAIRIES = BENEFICIARIES_SET_VALUE + 'resetState';
export const resetBeneficiaries = () => ({type: RESET_BENEFICAIRIES});