import { createSelector } from 'reselect';
import { getDomesticInsurance } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getQuote = createSelector(getDomesticInsurance, (state) => {
    return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculation = createSelector(getQuote, (quote) => quote && quote.premiumCalculation);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.SUCCESS
);
export const getQuoteFormApiCallFailure = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.FAILURE
);
export const getIsApiPending = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.PENDING);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);
export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus);
export const getUgCmbObject = createSelector(getQuote, (quote) => quote && quote.ugCmbObject);

const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getBuildingSum = makeGetFormField('buildingSum');
export const getContentsSum = makeGetFormField('contentsSum');
export const getAllRisksOption = makeGetFormField('allRisksOption');
export const getRiskSum =  makeGetFormField('risksSum');
export const getOwnersOption = makeGetFormField('ownersOption');
export const getOwnersSum = makeGetFormField('ownersSum');
export const getDomesticServantsOption = makeGetFormField('domesticServantsOption');
export const getDomesticServantsNo = makeGetFormField('domesticServantsNo');
export const getOccupiersOption = makeGetFormField('occupiersOption');
export const getOccupiersAmount = makeGetFormField('occupiersAmount');
export const getCoverStartDateValue = makeGetFormField('coverStartDate');
export const getSelectedCover = makeGetFormField('selectedCover');
export const getShareEmailValue = makeGetFormField('shareEmail');
export const getEqLoadingValue = makeGetFormField('eq_loading');
