import { createSelector } from 'reselect';
import { getDomesticInsurance } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getBuildingDetails = createSelector(getDomesticInsurance, (state) => state.buildingDetails);

export const getBuildingDetailsForm = createSelector(getBuildingDetails, (buildingDetails) => buildingDetails.form);

export const getDropOffDetails = createSelector(
    getBuildingDetails,
    (buildingDetails) => buildingDetails.dropOffDetails
);

export const getBuildingDetailsFormValid = createSelector(
    getBuildingDetails,
    (buildingDetails) => buildingDetails.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getBuildingDetails,
    (buildingDetails) => buildingDetails.formStatus === API_CALL_STATUS.PENDING
);

const makeGetFieldForm = (fieldId) => createSelector(getBuildingDetailsForm, (form) => form[fieldId]);

export const getHasFinacialInterestValue = makeGetFieldForm('hasFinacialInterest');
export const getFinacialInstitutionValue = makeGetFieldForm('finacialInstitution');
export const getStreetOfPremiseValue = makeGetFieldForm('streetOfPremise');
export const getTownOfPremiseValue = makeGetFieldForm('townOfPremise');
export const getBuildingTypeValue = makeGetFieldForm('buildingType');
export const getUseOfPropertyValue = makeGetFieldForm('useOfProperty');
export const getRoofNatureValue = makeGetFieldForm('roofNature');
export const getWallNatureValue = makeGetFieldForm('wallNature');
export const getLocationValue = makeGetFieldForm('location');
export const getLrNumberValue = makeGetFieldForm('lrNumber');
export const getSecurityValue = makeGetFieldForm('security');
export const getPerimeterValue = makeGetFieldForm('perimeterWall');
export const getLandScapingValue = makeGetFieldForm('landScaping');
export const getSwimmingPoolValue = makeGetFieldForm('swimmingPool');
export const getNoOfFloors = makeGetFieldForm('noOfFloors')
