import {
    SET_REFERRER_ID,
} from "../../actions/atRetirement/atRetirementApplication";


export const initAtRetirement = {
    referrerId: null
};

export default (state = initAtRetirement, action) => {

    switch (action.type) {
        case SET_REFERRER_ID:
            return {
                ...state,
                referrerId: action.value
            };
        default:
            return state;
    }
};
