import {
 	COLLECT_TRAVEL_INSURANCE_QUOTE_FORM_DATA,
    COLLECT_SHARE_QUOTE_FORM_DATA,
} from '../../../actions/travel/quote';

import {
    TRIGGER_KE_PAYMENTS_STK_PUSH,
    CHECK_PAYMENTS_STATUS,
    TRIGGER_CONFIRM_ORDER,
} from '../../../actions/travel/payments';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { orderConfirmationService } from './orderConfirmation';

import { premiumCalculationService } from './premiumCalculation';

import { COLLECT_PAYLOAD_DATA } from '../../../actions/travel/payloadCollection';
import { payloadSubmitService } from './payloadCollection';
import { shareQuoteService } from './shareQuote';
import { COLLECT_LOGIN_DETAILS } from '../../../actions/travel/auth';
import { loginService } from './login';

import { TRIGGER_FETCH_ALL_PRODUCTS } from '../../../actions/travel/quote';
import { getProductsService } from './products';

import { TRIGGER_FETCH_AGENTS } from '../../../actions/travel/motorAgentModal';
import {getAgentsService} from './agents'


 export const travelInsuranceCover = (store, next, action) => {

    if (action.type.startsWith(TRIGGER_FETCH_ALL_PRODUCTS)) {
        return getProductsService(store, next, action);
    }
    
     if (action.type.startsWith(TRIGGER_KE_PAYMENTS_STK_PUSH)) {
         return paymentsStkService(store, next, action);
     }
     if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
         return paymentsStkStatusService(store, next, action);
     }
     if (action.type.startsWith(TRIGGER_FETCH_AGENTS)) {
         return getAgentsService(store, next, action);
     }
    if (action.type.startsWith(COLLECT_TRAVEL_INSURANCE_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
        }
    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {

        return payloadSubmitService(store, next, action);
    }
    if(action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)){
        return shareQuoteService(store, next, action);
    }
    if(action.type.startsWith(COLLECT_LOGIN_DETAILS)){
        return loginService(store, next, action);
    }
    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)) {
        return orderConfirmationService(store, next, action);
    }
   
    return null;
};
