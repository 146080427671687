import { getIsApiPendingProducts } from '../../../selectors/travel/quote';
import { apiTransitionProducts, fetchAllProductsSuccess } from '../../../actions/travel/quote';
import { getAccessToken } from '../../../selectors/travel/payments';
import axios from 'axios';

export const getProductsService = (store, next, action) => {
    (async () => {
        if (!getIsApiPendingProducts(store.getState())) {
            store.dispatch(apiTransitionProducts.pending());
            try {
                const resp = await getProducts(store.getState());
                store.dispatch(fetchAllProductsSuccess(resp.data));
                store.dispatch(apiTransitionProducts.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionProducts.failure());
            }
        }
    })();
    return next(action);
};
const getProducts = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-travel-insurance/travel-products';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
}