import { transitionPaymentsForm } from '../../../actions/biasharaFlexi/payment';
import { getPaymentsForm } from '../../../selectors/biasharaFlexi/payment';
import { FIELD_STATUS } from '../../../reducers/status';

export const paymentsFormRules = (store, next, action) => {
    const result = next(action);
    const paymentsForm = getPaymentsForm(store.getState());
    const networkProvider = paymentsForm.networkProvider;
    const paymentsFormValid = networkProvider.value === 'mpesa' ? validatePaymentsFormDetails(paymentsForm) : validatePaymentsFormBankDetails(paymentsForm);
    const paymentsFormAction = paymentsFormValid
        ? transitionPaymentsForm.formValid()
        : transitionPaymentsForm.formInvalid();
    store.dispatch(paymentsFormAction);

    return result;
};

const validatePaymentsFormDetails = (formFields) => {
    return (
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.terms &&
        formFields.terms.status === FIELD_STATUS.VALID
    );
};

const validatePaymentsFormBankDetails = (formFields) => {
    return (
        formFields.terms &&
        formFields.terms.status === FIELD_STATUS.VALID
    );
};