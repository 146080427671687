import {FORM_STATUS, TOGGLE_STATUS} from "../status";
import {
    FORM_VALID_TRANSITION_REVIEW,
    FORM_INVALID_TRANSITION_REVIEW,
    TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW,
    TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW,
    REPLACEMENT_POLICY_RESET_VALUE,
    TERMS_AND_CONDITIONS_RESET_VALUE,
    EDIT_PAGE_REVIEW_TRACKING,
    REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE,
    SUCCESS_SUBMIT_RA_OPTIMAL,
    FAILURE_SUBMIT_RA_OPTIMAL,
    TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE, 
    REPLACEMENT_POLICY_SET_VALID,
    TERMS_AND_CONDITIONS_SET_VALID,
    WAITING_FOR_SUBMISSION_SET_VALUE,
    RESET_REVIEW
} from "../../actions/raOptimal/review";

export const initReview = {
    formStatus: FORM_STATUS.INVALID,
    replacementPolicy: TOGGLE_STATUS.UNTOUCHED,
    replacementPolicyError: null,
    termsAndConditions: TOGGLE_STATUS.UNTOUCHED,
    termsAndConditionsError: null,
    editPage: null,
    replacementPolicySideDrawer: TOGGLE_STATUS.NO,
    termsAndConditionsSideDrawer: TOGGLE_STATUS.NO,
    referenceNumber: null,
    proposalNumber: null,
    submitError: null,
    waitingForSubmission: TOGGLE_STATUS.NO,
};

export default (state = initReview, action) => {
    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_REVIEW:
            return {...state, formStatus: FORM_STATUS.VALID};
        case FORM_INVALID_TRANSITION_REVIEW:
            return {...state, formStatus: FORM_STATUS.INVALID};

        case TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW:
            if (action.value) return {...state, replacementPolicy: FORM_STATUS.INVALID};
            else return {...state, replacementPolicy: state.replacementPolicy !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO};

        case REPLACEMENT_POLICY_SET_VALID:
            return {...state, replacementPolicyError: action.error};

        case TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW:
            if (action.value) return {...state, termsAndConditions: FORM_STATUS.INVALID};
            else return {...state, termsAndConditions: state.termsAndConditions !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO};

        case TERMS_AND_CONDITIONS_SET_VALID:
            return {...state, termsAndConditionsError: action.error};

        case REPLACEMENT_POLICY_RESET_VALUE: 
            return {...state, replacementPolicy: null};
            
        case TERMS_AND_CONDITIONS_RESET_VALUE: 
            return {...state, termsAndConditions: null};

        case EDIT_PAGE_REVIEW_TRACKING:
            return {...state, editPage: action.value};

        case REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE:
            return {...state, replacementPolicySideDrawer: action.value};

        case TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE:
            return {...state, termsAndConditionsSideDrawer: action.value};

        case SUCCESS_SUBMIT_RA_OPTIMAL:
            return {
                ...state,
                referenceNumber: action.referenceNumber,
                proposalNumber: action.proposalNumber,
            };

        case FAILURE_SUBMIT_RA_OPTIMAL: 
            return {
                ...state, 
                submitError: action.error
            };
                     
        case WAITING_FOR_SUBMISSION_SET_VALUE: 
            return { ...state, waitingForSubmission: action.value };
        
        case RESET_REVIEW:
            return { ...initReview };

        default:
            return state;
    }
};


