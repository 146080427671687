export const BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM = 'biasharaFlexi/uploadDocumentsForm/';

export const BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALUE = BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM + 'setValue/';
export const BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALID = BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM + 'setValid/';

export const FORM_VALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM = 'biasharaFlexi/uploadDocumentsForm/transition/formValid';
export const FORM_INVALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM = 'biasharaFlexi/uploadDocumentsForm/transition/formInValid';

export const transitionBiasharaFlexiUploadDocuments = {
	formValid: () => ({
		type: FORM_VALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM,
	}),
};

export const INCORPORATION_COPY_SET_VALUE = BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "incorporationCertificate";
export const INCORPORATION_COPY_SET_VALID = BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "incorporationCertificate";

export const KRA_PIN_CERTIFICATE_COPY_SET_VALUE = BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALUE + "kraCertificate";
export const KRA_PIN_CERTIFICATE_COPY_SET_VALID = BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALID + "kraCertificate";


export const setIncorporationCertificateCopyValue = (value) => ({
    type: INCORPORATION_COPY_SET_VALUE,
    value
});

export const setIncorporationCertificateCopyValid = (error) => ({
    type: INCORPORATION_COPY_SET_VALID,
    error
});

export const setKraPinCertificateCopyValue = (value) => ({
    type: KRA_PIN_CERTIFICATE_COPY_SET_VALUE,
    value
});

export const setKraPinCertificateCopyValid = (error) => ({
    type: KRA_PIN_CERTIFICATE_COPY_SET_VALID,
    error
});

