import { getIsApiPending, getQuoteForm } from '../../../selectors/doctorsPi/quote';
import { apiTransitionDoctorsPiQuoteForm, calculateDoctorsPiPremiumSuccess } from '../../../actions/doctorsPi/quote';
import { getAccessToken } from '../../../selectors/doctorsPi/payments';
import axios from 'axios';


export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) { 
            store.dispatch(apiTransitionDoctorsPiQuoteForm.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateDoctorsPiPremiumSuccess(resp.data));
                store.dispatch(apiTransitionDoctorsPiQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionDoctorsPiQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);

    const premiumCalculationPayload = {
        indemnity_limit: quotation?.limitIndemnity.value.split(",").join(""),
        category: quotation?.selectedCover.value,
    };

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/doctors-pi/premium-calculation';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e
    }
};
