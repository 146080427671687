import { transitionTravelInsuranceAboutYouForm } from '../../../actions/roaTravelInsurance/aboutYou';
import { getAboutYouForm } from '../../../selectors/roaTravelInsurance/aboutYou';

import { FIELD_STATUS } from '../../../reducers/status';

export const aboutYouFormRules = (store, next, action) => {
    const result = next(action);
    const aboutYouForm = getAboutYouForm(store.getState());
    const aboutYouFormValid = validateAboutYouFormDetails(aboutYouForm);
    const formAction = aboutYouFormValid
        ? transitionTravelInsuranceAboutYouForm.formValid()
        : transitionTravelInsuranceAboutYouForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateAboutYouFormDetails = formFields => {
    return (
        formFields.DOB &&
        formFields.DOB.status === FIELD_STATUS.VALID &&
        formFields.title &&
        formFields.title.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.surname &&
        formFields.surname.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID
    );
};
