import { createSelector } from 'reselect';
import { getRoaCarInsuranceState } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { APICallSelector } from '../roaQuoteTools/api';

export const getProduct = createSelector(
    getRoaCarInsuranceState,
    (state) => state.product
);

export const getProductStatusIsValid = createSelector(
    getProduct,
    (state) => state.status === FORM_STATUS.VALID
);

export const getPremiumCalculation = createSelector(
    getProduct,
    (state) => state.premiumCalculation
);

export const getSelectedProduct = createSelector(
    getProduct,
    (state) => state.selectedCover
);

// API Calls Selectors
export const getShowError = APICallSelector.getErrorStatus(getProduct);
export const getIsApiPending = APICallSelector.getPendingStatus(getProduct);

export const getApiCallStatus = createSelector(
    getProduct,
    (state) => state.apiCallStatus
);
