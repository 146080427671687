import { validationIsEqual } from '../status';
import {
    validateName,
    validateSingleSelection,
    validateNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
} from '@om/validation';
import {
    REG_NUMBER_SET_VALUE,
    CHASIS_NUMBER_SET_VALUE,
    BODY_TYPE_SET_VALUE,
    SITTING_CAPACITY_SET_VALUE,
    COLOR_SET_VALUE,
    YEARS_WITH_LICENSE_SET_VALUE,
    FUEL_SET_VALUE,
    ENGINE_NUMBER_SET_VALUE,
    MANUFACTURER_MODEL_SET_VALUE,
    setBodyTypeValid,
    setChasisNoValid,
    setColorValid,
    setRegistrationNoValid,
    setSittingCapacityValid,
    setYearWithLicenseValid,
    setFuelValid,
    setEngineNumberValid,
    setManufacturerModelValid,
} from '../../../actions/ugMotorInsurance/carDetails';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

let prevValidationAction = null;
export const carDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ENGINE_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'Engine Number',
                    min: 5,
                });
                return setEngineNumberValid(error);
            case REG_NUMBER_SET_VALUE:
                error =
                    gatsbyStack === 'ug'
                        ? validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                              subject: 'Registration Number',
                              min: 6,
                          })
                        : validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                              subject: 'Registration Number',
                              min: 6,
                          });
                return setRegistrationNoValid(error);

            case CHASIS_NUMBER_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'Chasis Number',
                });
                return setChasisNoValid(error);

            case BODY_TYPE_SET_VALUE:
                error = validateName(action.value, { subject: 'Body Type', min: 2 });
                return setBodyTypeValid(error);

            case SITTING_CAPACITY_SET_VALUE:
                error = validateNumber(action.value, { min: 1 });
                return setSittingCapacityValid(error);

            case YEARS_WITH_LICENSE_SET_VALUE:
                error = validateSingleSelection(action.value, { subjet: 'Years with license' });
                return setYearWithLicenseValid(error);

            case COLOR_SET_VALUE:
                error = validateName(action.value, { subject: 'Color', min: 2 });
                return setColorValid(error);

            case FUEL_SET_VALUE:
                error = validateName(action.value, { subject: 'Fuel', min: 2 });
                return setFuelValid(error);

            case MANUFACTURER_MODEL_SET_VALUE:
                error = validateName(action.value, { subject: 'Manufacturer Model', min: 2 });
                return setManufacturerModelValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
