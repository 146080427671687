import {getReferrerId} from "../../../selectors/atRetirement/atRetirementApplication";
import {getAboutYouForm} from "../../../selectors/atRetirement/aboutYou";
import {getSolutionsForm} from "../../../selectors/atRetirement/solutions";
import {transitionScrolling} from "../../../actions/atRetirement/scrolling";
import {FINANCIAL_ADVICE_SCROLL_CLASS} from "../../rulesEngine/status";
import {getFinancialAdviceForm} from "../../../selectors/atRetirement/financialAdvice";
import {getProductSolutionsTable} from "../../../selectors/atRetirement/productSolutionsTable";
import {
    FIND_OUT_MORE_SEND,
    SPEAK_TO_ADVISOR_SEND,
    sendFindOutMoreFailure,
    sendFindOutMoreSuccess,
    sendSpeakToAdvisorFailure,
    sendSpeakToAdvisorSuccess,
} from "../../../actions/atRetirement/financialAdvice";
import axios from "axios";

export const financialAdviceService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const referrerId = getReferrerId(state);
    const aboutYouForm = getAboutYouForm(state);
    const solutionsForm = getSolutionsForm(state);
    const financialAdviceForm = getFinancialAdviceForm(state);
    const productSolutionsTableForm = getProductSolutionsTable(state);

    (async () => {
        if (action.type === FIND_OUT_MORE_SEND) {
            if (await sendFindOutMoreEmail(referrerId, aboutYouForm, solutionsForm, financialAdviceForm, productSolutionsTableForm)) {
                store.dispatch(sendFindOutMoreSuccess());
                setTimeout(() => {
                    store.dispatch(transitionScrolling.scrollToClass(FINANCIAL_ADVICE_SCROLL_CLASS.REWARDS_SECTION));
                }, 2000);
            } else {
                store.dispatch(sendFindOutMoreFailure());
            }
        }

        if (action.type === SPEAK_TO_ADVISOR_SEND) {
            if (await sendSpeakToAnAdviserEmail(referrerId, aboutYouForm, solutionsForm, financialAdviceForm, productSolutionsTableForm)) {
                store.dispatch(sendSpeakToAdvisorSuccess());
                store.dispatch(transitionScrolling.scrollToClass(FINANCIAL_ADVICE_SCROLL_CLASS.SUCCESS_SECTION));
            } else {
                store.dispatch(sendSpeakToAdvisorFailure());
            }
        }
    })();

    return result;
};
// Exported for testing
export const sendFindOutMoreEmail = async (referrerId, aboutYouFormFields, solutionFormFields, financialAdviceFields, productSolutionsTableFields) => {
    try {
        const body = findOutMoreRequestBody(referrerId, aboutYouFormFields, solutionFormFields, financialAdviceFields, productSolutionsTableFields);
        const response = await axios.post('/om-api/at-retirement/find-out-more', body,
            {headers: { 'Content-Type': 'application/json' }});

        return response.status === 200;

    } catch(error) {
        return false;
    }
};
// Exported for testing
export const sendSpeakToAnAdviserEmail = async (referrerId, aboutYouFormFields, solutionFormFields, financialAdviceFields, productSolutionsTableFields) => {
    try {
        const body = speakToAnAdviserRequestBody(referrerId, aboutYouFormFields, solutionFormFields, financialAdviceFields, productSolutionsTableFields);
        const response = await axios.post('/om-api/at-retirement/speak-to-an-adviser', body,
            {headers: { 'Content-Type': 'application/json' }});

        return response.status === 200;

    } catch(error) {
        return false;
    }
};

const findOutMoreRequestBody = (referrerId, aboutYouFormFields, solutionFormFields, financialAdviceForm, productSolutionsTableFields) => {
    return {
        uid: referrerId,
        includeApplication: true,
        emailAddress: financialAdviceForm.emailAddress.value || '',
        name: aboutYouFormFields.name.value || '',
        age: aboutYouFormFields.age.value || '',
        gender: aboutYouFormFields.gender.value || '',
        retirementFor: aboutYouFormFields.retirementFor.value || '',
        partnerAge: aboutYouFormFields.partnerAge.value || '',
        retirementWorry: aboutYouFormFields.retirementWorries.value || '',
        salary: aboutYouFormFields.salary.value  || 0,
        savings: aboutYouFormFields.savings.value  || 0,
        budget: aboutYouFormFields.budget.value || 0,
        selectedSolution:  solutionFormFields.solutionsProductChosen ?  solutionFormFields.solutionsProductChosen : '',
        livingAnnuityLowerRange:  productSolutionsTableFields.livingAnnuityLowerRange,
        livingAnnuityUpperRange: productSolutionsTableFields.livingAnnuityUpperRange,
        guaranteedAnnuityLowerRange: productSolutionsTableFields.guaranteedAnnuityLowerRange,
        guaranteedAnnuityUpperRange: productSolutionsTableFields.guaranteedAnnuityUpperRange,
    }
};

const speakToAnAdviserRequestBody = (referrerId, aboutYouFormFields, solutionFormFields, financialAdviceForm, productSolutionsTableFields) => {
    return {
        uid: referrerId,
        includeApplication: true,
        contactInterests: financialAdviceForm.reasons.value || '',
        additionalHelpDescription: financialAdviceForm.shortDescription.value || '',
        emailAddress: financialAdviceForm.emailAddress.value || '',
        contactNumber: financialAdviceForm.contactNumber.value || '',
        name: aboutYouFormFields.name.value || '',
        age: aboutYouFormFields.age.value || '',
        gender: aboutYouFormFields.gender.value || '',
        retirementFor: aboutYouFormFields.retirementFor.value || '',
        partnerAge: aboutYouFormFields.partnerAge.value || '',
        retirementWorry: aboutYouFormFields.retirementWorries.value || '',
        salary: aboutYouFormFields.salary.value  || 0,
        savings: aboutYouFormFields.savings.value  || 0,
        budget: aboutYouFormFields.budget.value || 0,
        selectedSolution:  solutionFormFields.solutionsProductChosen ?  solutionFormFields.solutionsProductChosen : '',
        livingAnnuityLowerRange:  productSolutionsTableFields.livingAnnuityLowerRange,
        livingAnnuityUpperRange: productSolutionsTableFields.livingAnnuityUpperRange,
        guaranteedAnnuityLowerRange: productSolutionsTableFields.guaranteedAnnuityLowerRange,
        guaranteedAnnuityUpperRange: productSolutionsTableFields.guaranteedAnnuityUpperRange,
    }
};
