// Transitions
export const FORM_VALID_TRANSITION_DROPOFF_DETAILS = 'raOptimal/captureDetails/transition/formValid';
export const FORM_INVALID_TRANSITION_DROPOFF_DETAILS = 'raOptimal/captureDetails/transition/formInvalid';

export const transitionCaptureDetails = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_DROPOFF_DETAILS }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_DROPOFF_DETAILS }),
};

export const INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS = 'raOptimal/captureDetails/transition/invalidContinue';
export const CONTINUE_TRANSITION_CAPTURE_DETAILS = 'raOptimal/captureDetails/transition/validContinue';
export const BACK_TRANSITION_CAPTURE_DETAILS = 'raOptimal/captureDetails/transition/back';
export const invalidContinueCaptureDetails = () => ({ type: INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS });
export const continueCaptureDetails = () => ({ type: CONTINUE_TRANSITION_CAPTURE_DETAILS });
export const backCaptureDetails = () => ({ type: BACK_TRANSITION_CAPTURE_DETAILS });

export const DROP_OFF_LEAD_TRANSITION_CAPTURE_DETAILS = 'raOptimal/captureDetails/transition/dropOffLead';
export const dropOffLeadCaptureDetail = dropOffDetails => ({ type: DROP_OFF_LEAD_TRANSITION_CAPTURE_DETAILS, dropOffDetails });

// Events
export const CAPTURE_DETAILS_SET_VALUE = 'raOptimal/captureDetails/setValue/';
export const CAPTURE_DETAILS_SET_VALID = 'raOptimal/captureDetails/setValid/';

export const NAME_SET_VALUE = CAPTURE_DETAILS_SET_VALUE + 'name';
export const NAME_SET_VALID = CAPTURE_DETAILS_SET_VALID + 'name';
export const SURNAME_SET_VALUE = CAPTURE_DETAILS_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = CAPTURE_DETAILS_SET_VALID + 'surname';
export const MOBILE_SET_VALUE = CAPTURE_DETAILS_SET_VALUE + 'mobile';
export const MOBILE_SET_VALID = CAPTURE_DETAILS_SET_VALID + 'mobile';

export const setNameValue = value => ({ type: NAME_SET_VALUE, value });
export const setNameValid = error => ({ type: NAME_SET_VALID, error });
export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });
export const setMobileValue = value => ({ type: MOBILE_SET_VALUE, value });
export const setMobileValid = error => ({ type: MOBILE_SET_VALID, error });

export const TOGGLE_INCLUDE_CAPTURE_DETAILS_CAPTURE_DETAILS = 'raOptimal/captureDetails/transition/toggleIncludeCaptureDetailsFalse';
export const toggleIncludeDropoffDetails = () => ({ type: TOGGLE_INCLUDE_CAPTURE_DETAILS_CAPTURE_DETAILS });

export const DISPLAY_NAME_SET_VALUE = CAPTURE_DETAILS_SET_VALUE + 'displayName';
export const setDisplayName = value => ({ type: DISPLAY_NAME_SET_VALUE, value });

export const RESET_CAPTURE_DETAILS = CAPTURE_DETAILS_SET_VALUE + 'resetState';
export const resetCaptureDetails = () => ({type: RESET_CAPTURE_DETAILS});