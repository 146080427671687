export const DOCTORS_PI_AGENT_MODAL_DETAILS =
    'doctorsPi/agentModalDetails/';

export const DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE =
    DOCTORS_PI_AGENT_MODAL_DETAILS + 'setValue/';
export const DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALID =
    DOCTORS_PI_AGENT_MODAL_DETAILS + 'setValid/';

export const MODAL_FORM_DISPLAY =
    DOCTORS_PI_AGENT_MODAL_DETAILS + 'modalFormDisplay';

export const REINITIALIZE =
    'doctorsPi/agentModalDetails/state/reinitialize';

export const FORM_VALID_DOCTORS_PI_AGENT_MODAL_FORM =
    'doctorsPi/agentModalDetails/transition/formValid';
export const FORM_INVALID_DOCTORS_PI_AGENT_MODAL_FORM =
    'doctorsPi/agentModalDetails/transition/formInValid';
export const SET_INTERMEDIARY_TYPE_VALUE = DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE + 'intermediaryType';
export const SET_INTERMEDIARY_TYPE_VALID = DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALID + 'intermediaryType';
export const SET_AGENTS_VALUE = DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE + 'agents';
export const TRIGGER_AGENT_TYPE = DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE + 'agentType';
 
export const transitionAgentModalForm = {
    formValid: () => ({
        type: FORM_VALID_DOCTORS_PI_AGENT_MODAL_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_DOCTORS_PI_AGENT_MODAL_FORM,
    }),
};


export const API_PENDING_TRANSITION_AGENTS = `${DOCTORS_PI_AGENT_MODAL_DETAILS}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_AGENTS = `${DOCTORS_PI_AGENT_MODAL_DETAILS}/apiTransition/success`;
export const API_FAILURE_TRANSITION_AGENTS = `${DOCTORS_PI_AGENT_MODAL_DETAILS}/apiTransition/failure`;

export const apiTransitionAgents = {
    pending: () => ({
        type: API_PENDING_TRANSITION_AGENTS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_AGENTS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_AGENTS,
    }),
};

export const setAgentModalDisplay = value => ({
    type: MODAL_FORM_DISPLAY,
    value,
});

export const setAgentModalToInitial = () => ({
    type: REINITIALIZE,
});

export const AGENT_NAME_SET_VALUE =
    DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE + 'name';
export const AGENT_NAME_SET_VALID =
    DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALID + 'name';
export const AGENT_CODE_SET_VALUE =
    DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE + 'phoneNumber';
export const AGENT_CODE_SET_VALID = DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALID + 'phoneNumber';

export const setNameValue = value => ({
    type: AGENT_NAME_SET_VALUE,
    value,
});
export const setNameValid = error => ({
    type: AGENT_NAME_SET_VALID,
    error,
});

export const setAgentCodeValue = (value) => ({
    type: AGENT_CODE_SET_VALUE,
    value,
});
export const setAgentCodeValid = (error) => ({
    type: AGENT_CODE_SET_VALID,
    error,
});

export const setIntermediaryTypeValue = (value) => ({
        type: SET_INTERMEDIARY_TYPE_VALUE,
        value
    }
);

export const setIntermediaryTypeValid = (error) => (
    {
        type: SET_INTERMEDIARY_TYPE_VALID,
        error
    }
);

export const setAgentsValue = (value) => ({
    type: SET_AGENTS_VALUE,
    value,
});

export const getAgentType = () => ({
    type: TRIGGER_AGENT_TYPE,
});
