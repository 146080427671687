export const ALLSURE_ROUTING_SET_VALUE = "allsureInsurance/routing/setValue/";

export const NAVIGATE_PREVIOUS = ALLSURE_ROUTING_SET_VALUE + "navigatePrevious";
export const NAVIGATE_NEXT = ALLSURE_ROUTING_SET_VALUE + "navigateNext";
export const NAVIGATE_INDEX = ALLSURE_ROUTING_SET_VALUE + "navigateIndex";
export const SET_NAVIGATION_DONE = ALLSURE_ROUTING_SET_VALUE + "navigationDone";
export const navigatePrevious = () => ({ type: NAVIGATE_PREVIOUS });
export const navigateNext = () => ({ type: NAVIGATE_NEXT });
export const navigateIndex = (value) => ({ type: NAVIGATE_INDEX, value });
export const setNavigationDone = () => ({ type: SET_NAVIGATION_DONE });


export const SET_ACTIVE_PAGE_INDEX = ALLSURE_ROUTING_SET_VALUE + "activePageIndex";
export const setActivePageIndex = (value) => ({
  type: SET_ACTIVE_PAGE_INDEX,
  value,
});

// per page transitions
export const ROUTING_TRANSITION = "allsureInsurance/routing/transition/";

export const NAVIGATE_TO_INSURANCE_TYPE =
  ROUTING_TRANSITION + "toInsuranceType";

export const navigateToInsuranceType = () => ({
  type: NAVIGATE_TO_INSURANCE_TYPE ,
});
export const NAVIGATE_TO_HOME_DETAILS =
  ROUTING_TRANSITION + "toHomeDetails";

export const navigateToHomeDetails = () => ({
  type: NAVIGATE_TO_HOME_DETAILS ,
});

export const NAVIGATE_TO_HOME_CONTENTS =
  ROUTING_TRANSITION + "toHomeContents";

export const navigateToHomeContents = () => ({
  type: NAVIGATE_TO_HOME_CONTENTS ,
});

export const NAVIGATE_TO_MOTOR_DETAILS =
  ROUTING_TRANSITION + "toMotorDetails";

export const navigateToMotorDetails = () => ({
  type: NAVIGATE_TO_MOTOR_DETAILS,
});


export const NAVIGATE_TO_PERSONAL_DETAILS =
  ROUTING_TRANSITION + "toPersonalDetails";

export const navigateToPersonalDetails = () => ({
  type: NAVIGATE_TO_PERSONAL_DETAILS,
});

export const NAVIGATE_TO_QUOTE_SUMMARY =
  ROUTING_TRANSITION + "toQuoteSummary";

export const navigateToQuoteSummary = () => ({
  type: NAVIGATE_TO_QUOTE_SUMMARY,
});
export const NAVIGATE_TO_CALL_BACK =
  ROUTING_TRANSITION + "toCallMeBack";

export const navigateToCallMeBack= () => ({
  type: NAVIGATE_TO_CALL_BACK,
});
export const NAVIGATE_TO_DOCUMENT_INFO =
  ROUTING_TRANSITION + "toDocumentInfo";

export const navigateToDocumentInfo= () => ({
  type: NAVIGATE_TO_DOCUMENT_INFO,
});
export const NAVIGATE_TO_PURCHASE_JORNEY_PERSONAL_DETAILS =
  ROUTING_TRANSITION + "toPurchaseJourneyPersonalDetails";

export const navigateToPurchaseJourneyPersonalDetails= () => ({
  type: NAVIGATE_TO_PURCHASE_JORNEY_PERSONAL_DETAILS,
});
export const NAVIGATE_TO_ADDITIONAL_DETAILS =
  ROUTING_TRANSITION + "toAdditionalDetails";

export const navigateToAdditionalDetails= () => ({
  type: NAVIGATE_TO_ADDITIONAL_DETAILS,
});
export const NAVIGATE_TO_BANKING_DETAILS =
  ROUTING_TRANSITION + "toBankingDetails";

export const navigateToBankingDetails= () => ({
  type: NAVIGATE_TO_BANKING_DETAILS,
});
export const NAVIGATE_TO_UPLOAD_DOCUMENTS =
  ROUTING_TRANSITION + "toUploadDocuments";

export const navigateToUploadDocuments= () => ({
  type: NAVIGATE_TO_UPLOAD_DOCUMENTS,
});
export const NAVIGATE_TO_CONFIRMATION =
  ROUTING_TRANSITION + "toConfirmation";

export const navigateToConfirmation= () => ({
  type: NAVIGATE_TO_CONFIRMATION,
});
export const NAVIGATE_TO_SUCCESS_PAGE =
  ROUTING_TRANSITION + "toSuccessPage";

export const navigateToSuccessPage= () => ({
  type: NAVIGATE_TO_SUCCESS_PAGE,
});
export const NAVIGATE_TO_PRODUCT_PAGE =
  ROUTING_TRANSITION + "toProductPage";

export const navigateToProductPage= () => ({
  type: NAVIGATE_TO_PRODUCT_PAGE,
});

//Progressbar transition
export const PROGRESS_BAR_TRANSITION=ROUTING_TRANSITION+"progressBar"
export const updateProgressBarStatus=(value)=>({
type: PROGRESS_BAR_TRANSITION,
value
})