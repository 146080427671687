import { createSelector } from 'reselect';
import { getRoaQuoteTools } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getAfyaImaraGertrudeJuniorQuoteTool = createSelector(
  getRoaQuoteTools,
  roaQuoteTools => roaQuoteTools.afyaImaraGertrudeJuniorQuoteTool,
);

export const getSelectedForm = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool =>
    afyaImaraGertrudeJuniorQuoteTool.selectedForm,
);

export const getRoaCmbObject = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool =>
    afyaImaraGertrudeJuniorQuoteTool.roaCmbObject
)

export const getAfyaImaraGertrudeJuniorQuoteToolForm = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool => afyaImaraGertrudeJuniorQuoteTool.form,
);

export const getAfyaImaraGertrudeJuniorQuoteToolFormValid = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool =>
    afyaImaraGertrudeJuniorQuoteTool.formStatus === FORM_STATUS.VALID,
);

export const getAfyaImaraGertrudeJuniorPremiumAmount = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool =>
    afyaImaraGertrudeJuniorQuoteTool.afyaImaraGertrudeJuniorPremiumAmount,
);

export const getShowError = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool =>
    afyaImaraGertrudeJuniorQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool =>
    afyaImaraGertrudeJuniorQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getCalculateDisabled = createSelector(
  getAfyaImaraGertrudeJuniorQuoteTool,
  afyaImaraGertrudeJuniorQuoteTool => {
    return (
      afyaImaraGertrudeJuniorQuoteTool.formStatus === FORM_STATUS.INVALID ||
      afyaImaraGertrudeJuniorQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING
    );
  },
);

const makeGetFieldForm = fieldId =>
  createSelector(
    getAfyaImaraGertrudeJuniorQuoteToolForm,
    form => form[fieldId],
  );

export const getNumberOfChildren = makeGetFieldForm('numberOfChildren');
export const getInpatientCoverLimitValue = makeGetFieldForm(
  'inpatientCoverLimitValue',
);
export const getOutpatientCoverLimitValue = makeGetFieldForm(
  'outpatientCoverLimitValue',
);
