import { createSelector } from "reselect";
import { getCustomerVerificationService } from "./index";
import { IFRAME_SOURCES, IFRAME_SOURCES_SLUGS, IFRAME_SUCCESS_EVENTS, CONTACTABLE_CODE, IIDENTIFII_CODE } from "../../actions/customerVerificationService/types";
import { getServiceProvider } from "./consent";
import { getID, getIdentityToken, getReferenceGuid } from "./idVerification";

export const getSelfie = createSelector(getCustomerVerificationService, cvs => cvs.selfie);

export const getVerificationFailedCount = createSelector(
    getSelfie,
    selfie => selfie.verificationFailedCount
);

export const getIframeLoaded = createSelector(
    getSelfie,
    selfie => selfie.iframeLoaded,
)

export const getIframeEventData = createSelector(
    getSelfie,
    selfie => selfie.iframeResponseData,
)

export const getOpenSomethingLooksWrongModal = createSelector(
    getSelfie,
    selfie => selfie.openSomethingLooksWrongModal,
);

export const getOpenCmbModal = createSelector(
    getSelfie,
    selfie => selfie.openCmbModal,
);

export const getOpenVerificationModal = createSelector(
    getSelfie,
    selfie => selfie.openVerificationModal,
);

export const getOpenLoadingModal = createSelector(
    getSelfie,
    selfie => selfie.openLoadingModal,
);

export const getOpenSuccessfullyVerifiedModal = createSelector(
    getSelfie,
    selfie => selfie.openSuccessfullyVerifiedModal,
);

export const getIidentifiiRequestID = createSelector(
    getSelfie,
    selfie => selfie.iidentifiiRequestID,
);

export const getLiveness = createSelector(
    getSelfie, 
    selfie => selfie.liveness,
);

export const getLivenessValidationPassed = createSelector(
    getLiveness, 
    liveness => liveness.validationPassed,
);

export const getLivenessServiceProvider = createSelector(
    getLiveness, 
    liveness => liveness.serviceProvider,
);

export const getLivenessIsValid = createSelector(
    getLiveness, 
    liveness => liveness.isValid,
);

export const getLivenessMessages = createSelector(
    getLiveness, 
    liveness => liveness.messages,
);

export const getLivenessErrors = createSelector(
    getLiveness, 
    liveness => liveness.errors,
);

export const getIframeSourceUrl = createSelector(
    getServiceProvider,
    getIdentityToken,
    (serviceProvider, idToken) => {
        if (serviceProvider === CONTACTABLE_CODE) {
            return IFRAME_SOURCES.contactableUrl + IFRAME_SOURCES_SLUGS.contactable + idToken;
        }  
        if (serviceProvider === IIDENTIFII_CODE ){
            return IFRAME_SOURCES.iidentifiiUrl + IFRAME_SOURCES_SLUGS.iidentifii + idToken;
        }
    }
);

export const getIframeOriginUrl = createSelector(
    getServiceProvider,
    (serviceProvider) => {
        if (serviceProvider === CONTACTABLE_CODE) {
            return IFRAME_SOURCES.contactableUrl;
        } 
        if (serviceProvider === IIDENTIFII_CODE ){
            return IFRAME_SOURCES.iidentifiiUrl;
        }
    }
);

export const getIframeSuccessEvent = createSelector(
    getServiceProvider,
    (serviceProvider) => {
        if (serviceProvider === CONTACTABLE_CODE) {
            return IFRAME_SUCCESS_EVENTS.contactable;
        } 
        if (serviceProvider === IIDENTIFII_CODE ){
            return IFRAME_SUCCESS_EVENTS.iidentifii;
        }
    }
);

export const getRetrieveLivenessBodyObj = createSelector(
    getServiceProvider,
    getID,
    getIdentityToken,
    getReferenceGuid,
    getIidentifiiRequestID,
    (serviceProvider, idNumber, identityToken, referenceGuid, iidentifiiRequestID) => {
        if(serviceProvider === CONTACTABLE_CODE){
            return {
                identityNumber: idNumber.value,
                identityToken: identityToken,
                serviceProvider: serviceProvider,
            }
        } else {
            return {
                identityNumber: idNumber.value,
                identityToken: identityToken,
                reference: referenceGuid,
                requestId: iidentifiiRequestID,
                serviceProvider: serviceProvider,
            }
        }
    }
);