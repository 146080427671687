import {FIELD_STATUS} from "../reducers/status";

export const asIntValue = val => (isNaN(val) || val < 1 || val > 100)
    ? 0
    : parseInt(val);

export const indexAsSplitKey = index => {
    const indexStr = (index + 1) + '';
    return 'split' + (indexStr.length < 2
        ? ('0' + indexStr)
        : indexStr);
};

export const getSplitValueList = nToSplit => {
    const splitVal = Math.floor(100/nToSplit);
    const firstVal = 100 - ((nToSplit-1) * splitVal);
    return [
        firstVal,
        ...(new Array(nToSplit-1)).fill(splitVal),
    ];
};

/**
 *
 * @param splitIds Has shape [string]
 * @param splitObject Has shape {[id: string]: value}
 // * @returns {{[p: string]: any}}
 */
export const initFundSplitList = ({splitIds, splitObject, evenSplit}) => {
    // For each splitId, map a split value if it exists in the splitObject, otherwise assign default split value.
    const funds = splitIds.reduce((acc, splitId) => {

        // Cater for split object not being present, when fund split page is skipped
        const splitEntry = splitObject && splitObject.length > 0 ? splitObject.find(x => x.id === splitId) : null;
        if (!splitEntry)
            return [
                ...acc,
                {value: null, error: null, status: FIELD_STATUS.UNTOUCHED, id: splitId}
            ];

        return [...acc, {
            value: splitEntry.value,
            error: splitEntry.error,
            status: splitEntry.status,
            id: splitId,
        }];
    }, []);

    // If evensplit has been selected recalculate split percentage values
    return evenSplit ?  splitPercentagesEvenly({splitList: funds}) : funds;

};

export const splitPercentagesEvenly = ({splitList}) => {
    const nFields = splitList.length;
    const splitValueList = getSplitValueList(nFields);
    return splitList.map((entry, index) => ({
        ...entry,
        value: splitValueList[index],
        status: FIELD_STATUS.VALID,
        error: null,
    }));
};

export const setAllPercentages = ({splitList, newValue}) => {
    return splitList.map(entry => ({
        ...entry,
        value: newValue,
        status: null,
        error: null,
    }));
};

export const sumPercentages = ({splitList}) => splitList
    .reduce((acc, split) => acc + asIntValue(split.value), 0);