import { FIELD_STATUS, FORM_STATUS } from "../status";
import {
    FORM_VALID_TRANSITION_CLINICAL_CRITERIA,
    FORM_INVALID_TRANSITION_CLINICAL_CRITERIA,
    COUGH_SET_VALUE,
    COUGH_SET_VALID,
    SHORT_BREATH_SET_VALUE,
    SHORT_BREATH_SET_VALID,
    HIGH_FEVER_SET_VALUE,
    HIGH_FEVER_SET_VALID,
    SORE_THROAT_SET_VALUE,
    SORE_THROAT_SET_VALID,
    CLINICAL_CRITERIA_RESET_FORM,
} from "../../actions/covidSelfAssessment/clinicalCriteria";


const initClinicalCriteria = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        cough: { value: null, error: null, status: null },
        shortBreath: { value: null, error: null, status: null },
        highFever: { value: null, error: null, status: null },
        soreThroat: { value: null, error: null, status: null },
    },
};

export default (state = initClinicalCriteria, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_CLINICAL_CRITERIA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_CLINICAL_CRITERIA:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Reset
        case CLINICAL_CRITERIA_RESET_FORM:
            return { ...initClinicalCriteria };

        // Cough
        case COUGH_SET_VALUE:
            return updateFormField('cough', field => ({
                ...field,
                value: action.value,
            }));
        case COUGH_SET_VALID:
            return updateFormField('cough', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Short Breath
        case SHORT_BREATH_SET_VALUE:
            return updateFormField('shortBreath', field => ({
                ...field,
                value: action.value,
            }));
        case SHORT_BREATH_SET_VALID:
            return updateFormField('shortBreath', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // High Fever
        case HIGH_FEVER_SET_VALUE:
            return updateFormField('highFever', field => ({
                ...field,
                value: action.value,
            }));
        case HIGH_FEVER_SET_VALID:
            return updateFormField('highFever', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Sore Throat
        case SORE_THROAT_SET_VALUE:
            return updateFormField('soreThroat', field => ({
                ...field,
                value: action.value,
            }));
        case SORE_THROAT_SET_VALID:
            return updateFormField('soreThroat', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
