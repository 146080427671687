import { validateFirstName, validateSurname, validateContactNumber } from  "@om/validation";
import {
    setNameValid,
    setSurnameValid,
    setMobileValid,
    NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    MOBILE_SET_VALUE
} from "../../../actions/raOptimal/captureDetails";

import { validationIsEqual } from "../status";

let prevValidationAction = null;
export const captureDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case NAME_SET_VALUE:
                //value gets trimmed here for validation purposes, displayValue(untrimmed) is used in the front end, if else condition is to account for undefined value when running setNameValue without a param.
                error = validateFirstName(action.value ? action.value.trim() : action.value);
                return setNameValid(error);

            case SURNAME_SET_VALUE:
                //value gets trimmed here for validation purposes, displayValue(untrimmed) is used in the front end, if else condition is to account for undefined value when running setSurnameValue without a param.
                error = validateSurname(action.value ? action.value.trim() : action.value);
                return setSurnameValid(error);

            case MOBILE_SET_VALUE:
                error = validateContactNumber(action.value);
                return setMobileValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
