import { FIELD_STATUS, FORM_STATUS } from "../status";
import { taxBrackets, taxRebates, advancedData, infoData } from "./referenceData"; // to be replaced by db fetch

import {
    AGE_SET_VALUE,
    AGE_SET_VALID,
    FORM_VALID_ADVANCED_CALCULATION,
    FORM_INVALID_ADVANCED_CALCULATION,
    INCOME_FREQUENCY_SET_VALUE,
    INCOME_FREQUENCY_SET_VALID,
    INCOME_VALUE_SET_VALUE,
    INCOME_VALUE_SET_VALID,
    OTHER_INCOME_VALUE_SET_VALUE,
    OTHER_INCOME_VALUE_SET_VALID,
    OTHER_INCOME_DEDUCTION_SET_VALUE,
    OTHER_INCOME_DEDUCTION_SET_VALID,
    OTHER_INCOME_DEDUCTION_TOGGLE_ACCORDION,
    REFERENCE_DATA_SET_VALUE,
    RESULTS_SET_VALUE,
    MEDICAL_AID_OWN_CONTRIBUTION_SET_VALUE,
    MEDICAL_AID_OWN_CONTRIBUTION_SET_VALID,
    MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALUE,
    MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALID,
    MEDICAL_AID_DEPENDENTS_SET_VALUE,
    MEDICAL_AID_DEPENDENTS_SET_VALID,
    MEDICAL_AID_TOGGLE_ACCORDION,
    PENSION_FUND_OWN_CONTRIBUTION_SET_VALUE,
    PENSION_FUND_OWN_CONTRIBUTION_SET_VALID,
    PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE,
    PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALID,
    PENSION_FUND_TOGGLE_ACCORDION,
    PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALUE,
    PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALID,
    PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE,
    PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALID,
    PROVIDENT_FUND_TOGGLE_ACCORDION,
    RETIREMENT_ANNUITY_SET_VALUE,
    RETIREMENT_ANNUITY_SET_VALID,
    RETIREMENT_ANNUITY_TOGGLE_ACCORDION,
    REINITIALIZE_STATE_ADVANCED_CALCULATOR,
    PRODUCT_RECOMMENDATION_SET_VALUE,
    SHOW_FIELD_DETAILS,
} from "../../actions/incomeTaxCalculator/advancedCalculator";

const initialState = {
    formStatus: FORM_STATUS.INVALID,
    showRecommendation: false,
    ageGroups : [
        {
            value: 'categoryOne',
            description: 'Under 65',
        },
        {
            value: 'categoryTwo',
            description: 'Between 65 and 75',
        },
        {
            value: 'categoryThree',
            description: 'Over 75',
        },
    ],
    frequencyOptions: [
        {
            value: 1,
            description: 'Monthly',
        },
        { 
            value: 2,
            description: 'Yearly',
        },
    ],
    form: {
        age: { value: 'categoryOne', error: null, status: 'valid' },
        incomeFrequency: { value: 'Monthly', error: null, status: 'valid' },
        incomeValue: { value: null, error: null, status: null },
        otherIncome: {
            income: { 
                value: null,
                error: null,
                status: null,
                description: infoData.otherIncome
            },
            deduction: { 
                value: null,
                error: null,
                status: null,
                description: infoData.deductionOtherIncome
            },
            toggleStatus: false,
        },
        medicalAid: {
            ownContribution: { value: null, error: null, status: null },
            employerContribution: { value: null, error: null, status: null },
            medicalAidDependents: { value: null, error: null, status: null },
            toggleStatus: false,
        },
        pensionFund: {
            ownContribution: { value: null, error: null, status: null },
            employerContribution: { value: null, error: null, status: null },
            toggleStatus: false,
        },
        providentFund: { 
            ownContribution: { value: null, error: null, status: null },
            employerContribution: { value: null, error: null, status: null },
            toggleStatus: false,
        },
        retirementAnnuity: { value: null, error: null, status: null, toggleStatus: false },
    },
    result: {
        monthlyIncome: null,
        taxValue: null,
    },
    referenceData : {
        taxBrackets,
        taxRebates,
        advancedData,
        infoData,
    },
    showFiedDetailsStatus: {
        fieldName: null,
        subFieldName: null,
        fieldDescription: null,
        title: null
    }
};

export default (state = initialState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch(action.type) {
        // Form Validation
        case FORM_VALID_ADVANCED_CALCULATION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_ADVANCED_CALCULATION:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Age
        case AGE_SET_VALUE:
            return updateFormField('age', field => ({
                ...field,
                value: action.value,
            }));
        case AGE_SET_VALID:
            return updateFormField('age', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Income frequency
        case INCOME_FREQUENCY_SET_VALUE:
            return updateFormField('incomeFrequency', field => ({
                ...field,
                value: action.value,
            }));
        case INCOME_FREQUENCY_SET_VALID:
            return updateFormField('incomeFrequency', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        
        // Income value
        case INCOME_VALUE_SET_VALUE:
            return updateFormField('incomeValue', field => ({
                ...field,
                value: action.value,
            }));
        case INCOME_VALUE_SET_VALID:
            return updateFormField('incomeValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // other income
        case OTHER_INCOME_VALUE_SET_VALUE:
            return updateFormField('otherIncome', field => ({
                ...field,
                income: Object.assign({}, field['income'], { value: action.value }),
            }));
        case OTHER_INCOME_VALUE_SET_VALID:
            return updateFormField('otherIncome', field => ({
                ...field,
                income: Object.assign({}, field['income'], {  error: action.error, status: mapError(action.error) }),
            }));
        case OTHER_INCOME_DEDUCTION_SET_VALUE:
            return updateFormField('otherIncome', field => ({
                ...field,
                deduction: Object.assign({}, field['deduction'], { value: action.value }),
            }));
        case OTHER_INCOME_DEDUCTION_SET_VALID:
            return updateFormField('otherIncome', field => ({
                ...field,
                deduction: Object.assign({}, field['deduction'], {  error: action.error, status: mapError(action.error) }),
            }));
        case OTHER_INCOME_DEDUCTION_TOGGLE_ACCORDION:
            return updateFormField('otherIncome', field => ({
                ...field,
                toggleStatus: !state.form.otherIncome.toggleStatus
            }));
        // medical aid contributions
        case MEDICAL_AID_OWN_CONTRIBUTION_SET_VALUE:
            return updateFormField('medicalAid', field => ({
                ...field,
                ownContribution: Object.assign({}, field['ownContribution'], { value: action.value }),
            }));
        case MEDICAL_AID_OWN_CONTRIBUTION_SET_VALID:
            return updateFormField('medicalAid', field => ({
                ...field,
                ownContribution: Object.assign({}, field['ownContribution'], {  error: action.error, status: mapError(action.error) }),
            }));
        case MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALUE:
            return updateFormField('medicalAid', field => ({
                ...field,
                employerContribution: Object.assign({}, field['employerContribution'], { value: action.value }),
            }));
        case MEDICAL_AID_EMPLOYER_CONTRIBUTION_SET_VALID:
            return updateFormField('medicalAid', field => ({
                ...field,
                employerContribution: Object.assign({}, field['employerContribution'], {  error: action.error, status: mapError(action.error) }),
            }));
        case MEDICAL_AID_DEPENDENTS_SET_VALUE:
            return updateFormField('medicalAid', field => ({
                ...field,
                medicalAidDependents: Object.assign({}, field['medicalAidDependents'], { value: action.value }),
            }));
        case MEDICAL_AID_DEPENDENTS_SET_VALID:
            return updateFormField('medicalAid', field => ({
                ...field,
                medicalAidDependents: Object.assign({}, field['medicalAidDependents'], {  error: action.error, status: mapError(action.error) }),
            }));
        case MEDICAL_AID_TOGGLE_ACCORDION:
            return updateFormField('medicalAid', field => ({
                ...field,
                toggleStatus: !state.form.medicalAid.toggleStatus
            }))
        // pension fund contributions
        case PENSION_FUND_OWN_CONTRIBUTION_SET_VALUE:
            return updateFormField('pensionFund', field => ({
                ...field,
                ownContribution: Object.assign({}, field['ownContribution'], { value: action.value }),
            }));
        case PENSION_FUND_OWN_CONTRIBUTION_SET_VALID:
            return updateFormField('pensionFund', field => ({
                ...field,
                ownContribution: Object.assign({}, field['ownContribution'], {  error: action.error, status: mapError(action.error) }),
            }));
        case PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE:
            return updateFormField('pensionFund', field => ({
                ...field,
                employerContribution: Object.assign({}, field['employerContribution'], { value: action.value }),
            }));
        case PENSION_FUND_EMPLOYER_CONTRIBUTION_SET_VALID:
            return updateFormField('pensionFund', field => ({
                ...field,
                employerContribution: Object.assign({}, field['employerContribution'], {  error: action.error, status: mapError(action.error) }),
            }));
        case PENSION_FUND_TOGGLE_ACCORDION:
            return updateFormField('pensionFund', field => ({
                ...field,
                toggleStatus: !state.form.pensionFund.toggleStatus
            }));

        // Provident fund contributions
        case PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALUE:
            return updateFormField('providentFund', field => ({
                ...field,
                ownContribution: Object.assign({}, field['ownContribution'], { value: action.value }),
            }));
        case PROVIDENT_FUND_OWN_CONTRIBUTION_SET_VALID:
            return updateFormField('providentFund', field => ({
                ...field,
                ownContribution: Object.assign({}, field['ownContribution'], {  error: action.error, status: mapError(action.error) }),
            }));
        case PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALUE:
            return updateFormField('providentFund', field => ({
                ...field,
                employerContribution: Object.assign({}, field['employerContribution'], { value: action.value }),
            }));
        case PROVIDENT_FUND_EMPLOYER_CONTRIBUTION_SET_VALID:
            return updateFormField('providentFund', field => ({
                ...field,
                employerContribution: Object.assign({}, field['employerContribution'], {  error: action.error, status: mapError(action.error) }),
            }));
        case PROVIDENT_FUND_TOGGLE_ACCORDION:
            return updateFormField('providentFund', field => ({
                ...field,
                toggleStatus: !state.form.providentFund.toggleStatus
            }));
        
        // retirement annuity
        case RETIREMENT_ANNUITY_SET_VALUE:
            return updateFormField('retirementAnnuity', field => ({
                ...field,
                value: action.value,
            }));
        case RETIREMENT_ANNUITY_SET_VALID:
            return updateFormField('retirementAnnuity', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case RETIREMENT_ANNUITY_TOGGLE_ACCORDION:
            return updateFormField('retirementAnnuity', field => ({
                ...field,
                toggleStatus: !state.form.retirementAnnuity.toggleStatus
            }));

        // Tax value
        case RESULTS_SET_VALUE:
            return { ...state, result: action.value };
        // Tax brackets and rebates
        case REFERENCE_DATA_SET_VALUE:
            return state;
        // reinitialise state
        case REINITIALIZE_STATE_ADVANCED_CALCULATOR:
            return initialState;
        // Get advanced product recommendation
        case PRODUCT_RECOMMENDATION_SET_VALUE:
            return {
                ...state,
                showRecommendation: true
            };
        case SHOW_FIELD_DETAILS:
            return {
                ...state,
                showFiedDetailsStatus: {
                    fieldName: action.value.field || null,
                    subFieldName: action.value.subField || null,
                    fieldDescription: action.value.field ? state.form[action.value.field][action.value.subField]['description'] :
                    state.referenceData.infoData.resultCard,
                    title: action.value.title
                }
            }
        default:
            return state;
    }

};