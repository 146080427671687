export const PRICE_TABLE_TRANSITION = 'priceTable/table/transition/';

export const FORM_VALID_PRICE_TABLE_TRANSITION = PRICE_TABLE_TRANSITION + 'formValid';
export const FORM_INVALID_PRICE_TABLE_TRANSITION = PRICE_TABLE_TRANSITION + 'formInvalid';

export const transitionPriceTable = {
    formValid: () => ({ type: FORM_VALID_PRICE_TABLE_TRANSITION }),
    formInvalid: () => ({ type: FORM_INVALID_PRICE_TABLE_TRANSITION })
}

export const PRICE_TABLE_SET_VALUE = 'priceTable/table/setValue/';
export const PRICE_TABLE_SET_VALID = 'priceTable/table/setValid/';

export const FUNDS_SET_VALUE = PRICE_TABLE_SET_VALUE + 'funds';
export const DATE_SET_VALUE = PRICE_TABLE_SET_VALUE + 'date';
export const DATE_SET_VALID = PRICE_TABLE_SET_VALID + 'date';
export const PAGINATION_SIZE_SET_VALUE = PRICE_TABLE_SET_VALUE + 'paginationSize';

export const setFundsValue = (value) => ({ type: FUNDS_SET_VALUE, value });
export const setDateValue = (value) => ({ type: DATE_SET_VALUE, value });
export const setDateValid = (error) => ({ type: DATE_SET_VALID, error });
export const setPaginationSizeValue = (value) => ({ type: PAGINATION_SIZE_SET_VALUE, value });

export const FETCH_PRICE_TABLE_FILTERED_FUNDS = 'priceTable/table/getFunds/filteredFunds';

export const getFundsForSelectedDate = () => ({ type: FETCH_PRICE_TABLE_FILTERED_FUNDS });

export const API_PENDING_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE = PRICE_TABLE_SET_VALUE + 'apiTransition/pending';
export const API_SUCCESS_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE = PRICE_TABLE_SET_VALUE + 'apiTransition/success';
export const API_FAILURE_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE = PRICE_TABLE_SET_VALUE + 'apiTransition/failure';
export const apiTransitionPriceTable = {
    pending: () => ({ type: API_PENDING_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE }),
    success: () => ({ type: API_SUCCESS_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE }),
    failure: () => ({ type: API_FAILURE_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE }),
};


