import {
    validateNumber
} from "@om/validation";

import {
    NET_MONTHLY_INCOME_SET_VALUE,
    setNetMonthlyIncomeValid,
} from "../../../actions/budgetBenchmarkCalculator/calculator";

import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const budgetBenchmarkValidationRules = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case NET_MONTHLY_INCOME_SET_VALUE:
                error = validateNumber(action.value, { min: 100, max: 1000000, subject: 'Net monthly income' })
                return setNetMonthlyIncomeValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
}
