import { validateNumber } from '@om/validation';
import {
    COST_OF_EDUCATION_SET_VALUE,
    DURATION_OF_STUDIES_SET_VALUE,
    HOW_MUCH_SAVED_ALREADY_SET_VALUE,
    MONTHLY_CONTRIBUTION_SET_VALUE,
    NUMBER_OF_YEARS_SET_VALUE,
    setCostOfEducationValid,
    setDurationOfStudiesValid,
    setHowMuchSavedAlreadyValid,
    setMonthlyContributionValid,
    setNumberOfYearsValid,
} from '../../../actions/educationCalculator/calculator';
import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const educationCalculatorFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case COST_OF_EDUCATION_SET_VALUE:
                error = validateNumber(action.value, {
                    required: true,
                    subject: 'Cost of education',
                    min: 0,
                    max: 1000000,
                });
                return setCostOfEducationValid(error);
            case DURATION_OF_STUDIES_SET_VALUE:
                error = validateNumber(action.value, {
                    required: true,
                    subject: 'Duration of studies',
                    decimalPoints: 0
                });
                return setDurationOfStudiesValid(error);
            case NUMBER_OF_YEARS_SET_VALUE:
                error = validateNumber(action.value, {
                    required: true,
                    subject: 'Number of years before you start studying',
                    decimalPoints: 0
                });
                return setNumberOfYearsValid(error);
            case HOW_MUCH_SAVED_ALREADY_SET_VALUE:
                error = validateNumber(action.value, {
                    required: true,
                    subject: 'Amount already saved',
                });
                return setHowMuchSavedAlreadyValid(error);
            case MONTHLY_CONTRIBUTION_SET_VALUE:
                error = validateNumber(action.value, {
                    required: true,
                    subject: 'Monthly contribution',
                });
                return setMonthlyContributionValid(error);
            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
