import {
  setCoughValid,
  setShortBreathValid,
  setHighFeverValid,
  setSoreThroatValid,
  COUGH_SET_VALUE,
  SHORT_BREATH_SET_VALUE,
  HIGH_FEVER_SET_VALUE,
  SORE_THROAT_SET_VALUE,
} from "../../../actions/covidSelfAssessment/clinicalCriteria";
import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const clinicalCriteriaValidation = (store, next, action) => {
  const validationAction = (() => {
    switch (action.type) {
      case COUGH_SET_VALUE:
        return setCoughValid(action.value ? null : 'Value required');

      case SHORT_BREATH_SET_VALUE:
        return setShortBreathValid(action.value ? null : 'Value required');

      case HIGH_FEVER_SET_VALUE:
        return setHighFeverValid(action.value ? null : 'Value required');

      case SORE_THROAT_SET_VALUE:
        return setSoreThroatValid(action.value ? null : 'Value required');

      default:
        return null;
    }
  })();

  if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
    return next(action);
  }

  store.dispatch(validationAction)
  prevValidationAction = validationAction;
  return next(action);
};
