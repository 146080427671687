import {
    MY_DETAILS_SET_VALUE,
    SPOUSE_DETAILS_SET_VALUE,
    CHILDREN_DETAILS_SET_VALUE,
    PARENTS_DETAILS_SET_VALUE,
    EXTENDED_DETAILS_SET_VALUE,
    LIVES_COVERED_SCREEN_VALUE,
} from '../../actions/healthInsuranceRW/personalDetails';

export const initLivesCoveredPersonalDetails = {
    myDetails: null,
    spouseDetails: null,
    childrenDetails: [],
    parentsDetails: [],
    extendedDetails: [],
    screen: 'details',
};

export default (state = initLivesCoveredPersonalDetails, action) => {
    switch (action.type) {
        case MY_DETAILS_SET_VALUE:
            return { ...state, myDetails: action.value };

        case SPOUSE_DETAILS_SET_VALUE:
            return { ...state, spouseDetails: action.value };
        case CHILDREN_DETAILS_SET_VALUE:
            return { ...state, childrenDetails: action.value };
        case PARENTS_DETAILS_SET_VALUE:
            return { ...state, parentsDetails: action.value };
        case EXTENDED_DETAILS_SET_VALUE:
            return { ...state, extendedDetails: action.value };
        case LIVES_COVERED_SCREEN_VALUE:
            return { ...state, screen: action.value };
        default:
            return state;
    }
};
