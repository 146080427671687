export const PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM = 'personalPensionPlan/personalDetailsForm/';

export const PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE =
    PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM + 'setValue/';
export const PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID =
    PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM + 'setValid/';

export const FORM_VALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM =
    'personalPensionPlan/personalDetailsForm/transition/formValid';
export const FORM_INVALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM =
    'personalPensionPlan/personalDetailsForm/transition/formInValid';

export const REINITIALIZE = 'personalPensionPlan/personalDetailsForm/state/reinitialize';

export const transitionPersonalPensionPlanPersonalDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM = `${PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM = `${PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM = `${PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitionPersonalDetailsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PERSONAL_DETAILS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PERSONAL_DETAILS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PERSONAL_DETAILS_FORM,
    }),
};

export const SELECTED_FORM_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'selectedForm';

export const TRIGGER_GET_NATIONALITIES = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'fetch/nationalities';
export const SET_NATIONALITIES_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'set/nationalities';
export const API_FAILURE_TRANSITION_NATIONALITIES =
    PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'api/failure/nationalities';
export const API_SUCCESS_TRANSITION_NATIONALITIES =
    PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'api/success/nationalities';
export const API_PENDING_TRANSITION_NATIONALITIES =
    PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'api/pending/nationalities';

export const FIRST_NAME_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'firstName';

export const LAST_NAME_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'lastName';
export const LAST_NAME_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'lastName';

export const DOB_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'DOB';

export const GENDER_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'gender';
export const GENDER_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'gender';

export const NATIONALITY_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'nationality';
export const NATIONALITY_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'nationality';

export const OCCUPATION_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'occupation';
export const OCCUPATION_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'occupation';

export const ID_NUMBER_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'idNumber';
export const ID_NUMBER_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'idNumber';

export const TIN_NUMBER_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'tinNumber';
export const TIN_NUMBER_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'tinNumber';

export const PHONE_NUMBER_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'phoneNumber';

export const EMAIL_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'email';
export const EMAIL_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'email';

export const POSTAL_ADDRESS_SET_VALUE = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE + 'postalAddress';
export const POSTAL_ADDRESS_SET_VALID = PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALID + 'postalAddress';

export const setSelectedFormValue = (value) => ({
    type: SELECTED_FORM_SET_VALUE,
    value,
});

export const setFirstNameValue = (value) => ({
    type: FIRST_NAME_SET_VALUE,
    value,
});
export const setFirstNameValid = (error) => ({
    type: FIRST_NAME_SET_VALID,
    error,
});

export const setLastNameValue = (value) => ({
    type: LAST_NAME_SET_VALUE,
    value,
});
export const setLastNameValid = (error) => ({
    type: LAST_NAME_SET_VALID,
    error,
});

export const setDOBValue = (value) => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDOBValid = (error) => ({
    type: DOB_SET_VALID,
    error,
});

export const setGenderValue = (value) => ({
    type: GENDER_SET_VALUE,
    value,
});
export const setGenderValid = (error) => ({
    type: GENDER_SET_VALID,
    error,
});

export const setNationalityValue = (value) => ({
    type: NATIONALITY_SET_VALUE,
    value,
});
export const setNationalityValid = (error) => ({
    type: NATIONALITY_SET_VALID,
    error,
});

export const setOccupationValue = (value) => ({
    type: OCCUPATION_SET_VALUE,
    value,
});
export const setOccupationValid = (error) => ({
    type: OCCUPATION_SET_VALID,
    error,
});

export const setIdNumberValue = (value) => ({
    type: ID_NUMBER_SET_VALUE,
    value,
});
export const setIdNumberValid = (error) => ({
    type: ID_NUMBER_SET_VALID,
    error,
});

export const setTinNumberValue = (value) => ({
    type: TIN_NUMBER_SET_VALUE,
    value,
});
export const setTinNumberValid = (error) => ({
    type: TIN_NUMBER_SET_VALID,
    error,
});

export const setPhoneNumberValue = (value) => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = (error) => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setEmailValue = (value) => ({
    type: EMAIL_SET_VALUE,
    value,
});
export const setEmailValid = (error) => ({
    type: EMAIL_SET_VALID,
    error,
});

export const setPostalAddressValue = (value) => ({
    type: POSTAL_ADDRESS_SET_VALUE,
    value,
});
export const setPostalAddressValid = (error) => ({
    type: POSTAL_ADDRESS_SET_VALID,
    error,
});

export const initializePersonalDetailsForm = () => ({
    type: REINITIALIZE,
});

export const apiTransitionNationalities = {
    pending: () => ({
        type: API_PENDING_TRANSITION_NATIONALITIES,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_NATIONALITIES,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_NATIONALITIES,
    }),
};

export const triggerGetNationalities = () => ({
    type: TRIGGER_GET_NATIONALITIES,
});

export const setNationalitiesList = (value) => ({
    type: SET_NATIONALITIES_VALUE,
    value,
});
