import { BANKING_DETAILS_SET_VALUE } from '../../../actions/allsureInsurance/bankingDetails';
import { HOME_CONTENTS_SET_VALUE } from '../../../actions/allsureInsurance/homeContents';
import { HOME_DETAILS_SET_VALUE } from '../../../actions/allsureInsurance/homeDetails';
import { INSURANCE_TYPE_SET_VALUE } from '../../../actions/allsureInsurance/insuranceType';
import { MOTOR_DETAILS_SET_VALUE } from '../../../actions/allsureInsurance/motorDetails';
import { PERSONAL_DETAILS_SET_VALUE } from '../../../actions/allsureInsurance/personalDetails';
import { PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE } from '../../../actions/allsureInsurance/purchaseAdditionalHomeandMotor';
import { PURCHASE_PERSONAL_DETAILS_SET_VALUE } from '../../../actions/allsureInsurance/purchasePersonalDetails';
import { UPLOAD_DOCUMENTS_SET_VALUE } from '../../../actions/allsureInsurance/uploadDocuments';
import { additionalHomeMotorDetailsValidation } from './additionalHomeAndMotor';
import { bankingDetailsValidations } from './bankingDetails';
import { homeContentValidations } from './homeContent';
import { homeDetailsValidation } from './homeDetailsValidation';
import { insuranceTypeValidations } from './insuranceType';
import { motorDetailsValidation } from './motorDetails';

import { personalDetailsValidation } from './personalDetails';
import { purchaseJourneyPersonalDetailsValidation } from './purchaseJourneyPersonalDetails';
import { uploadDocumentsValidation } from './uploadDocuments';

export const allsureInsuranceValidation = (store, next, action) => {
    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE)) {
        return personalDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(HOME_DETAILS_SET_VALUE)) {
        return homeDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(HOME_CONTENTS_SET_VALUE)) {
        return homeContentValidations(store, next, action);
    }
    if (action.type.startsWith(MOTOR_DETAILS_SET_VALUE)) {
        return motorDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(INSURANCE_TYPE_SET_VALUE)) {
        return insuranceTypeValidations(store, next, action);
    }
    if (action.type.startsWith(PURCHASE_PERSONAL_DETAILS_SET_VALUE)) {
        return purchaseJourneyPersonalDetailsValidation(store, next, action);
    }
    if (action.type.startsWith(BANKING_DETAILS_SET_VALUE)) {
        return bankingDetailsValidations(store, next, action);
    }
    if (action.type.startsWith(UPLOAD_DOCUMENTS_SET_VALUE)) {
        return uploadDocumentsValidation(store, next, action);
    }
    if (action.type.startsWith(PURCHASE_ADDITIONAL_HOME_MOTOR_SET_VALUE)) {
        return additionalHomeMotorDetailsValidation(store, next, action);
    }
    return null;
};
