import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../../../status';

import {
    REINITIALIZE,
    CANDIDATE_DOB_SET_VALUE,
    CANDIDATE_DOB_SET_VALID,
    SMOKING_SET_VALUE,
    SMOKING_SET_VALID,
    DISABILITY_SET_VALUE,
    DISABILITY_SET_VALID,
    COVERED_AMOUNT_SET_VALUE,
    COVERED_AMOUNT_SET_VALID,
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    WHICH_FORM_TO_DISPLAY,
    FORM_VALID_WHOLE_LIFE_COVER_QUOTE_TOOL,
    FORM_INVALID_WHOLE_LIFE_COVER_QUOTE_TOOL,
    CALCULATE_WHOLE_LIFE_COVER_DATA,
    COLLECT_WHOLE_LIFE_COVER_DATA,
    API_PENDING_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL,
    API_FAILURE_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL

} from '@om/redux/actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Whole Life',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address'] 
    },
    form: {
        candidateDOB: { value: null, error: null, status: null },
        genderValue: { value: 'M', error: null, status: 'valid' },
        smokingValue: { value: 'NS', error: null, status: 'valid' },
        disabilityValue: { value: 'No', error: null, status: 'valid' },
        coveredAmount: {value: null, error: null, status: null},
    }
};

export default (state = initialeState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {

        // Form Validation
        case FORM_VALID_WHOLE_LIFE_COVER_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_WHOLE_LIFE_COVER_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        // Api call
        case API_PENDING_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_WHOLE_LIFE_COVER_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        // Date of birth
        case CANDIDATE_DOB_SET_VALUE:
            return updateFormField('candidateDOB', field => ({
                ...field,
                value: action.value,
            }));
        case CANDIDATE_DOB_SET_VALID:
            return updateFormField('candidateDOB', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Gender value input
        case GENDER_SET_VALUE:
            return updateFormField('genderValue', field => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('genderValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // covered input
        case COVERED_AMOUNT_SET_VALUE:
            return updateFormField('coveredAmount', field => ({
                ...field,
                value: action.value,
            }));
        case COVERED_AMOUNT_SET_VALID:
            return updateFormField('coveredAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // covered input
        case DISABILITY_SET_VALUE:
            return updateFormField('disabilityValue', field => ({
                ...field,
                value: action.value,
            }));
        case DISABILITY_SET_VALID:
            return updateFormField('disabilityValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // covered input
        case SMOKING_SET_VALUE:
            return updateFormField('smokingValue', field => ({
                ...field,
                value: action.value,
            }));
        case SMOKING_SET_VALID:
            return updateFormField('smokingValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Select Form
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value
            };
            case CALCULATE_WHOLE_LIFE_COVER_DATA:
                return {
                    ...state,
                    quotationSummary: action.value
                }
            case COLLECT_WHOLE_LIFE_COVER_DATA:
                return {
                    ...state,
                    formStatus: FORM_STATUS.VALID
                }
        

        case REINITIALIZE:
            return initialeState
        default:
            return state;
    }
}
