import { getIsApiPending, getQuoteForm, getPremiumCalculation, getProducts } from '../../../selectors/travel/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure,
    
} from '../../../actions/travel/quote';
import { getAccessToken } from '../../../selectors/travel/payments';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

function convertDateFormat(dateString) {
    // Split the date string into day, month, and year
    var parts = dateString.split('/');

    // Rearrange the parts into the desired format
    var formattedDate = parts[2] + '-' + parts[1].padStart(2, '0') + '-' + parts[0].padStart(2, '0');

    return formattedDate;
}

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);
    const premium = getPremiumCalculation(state);
     const products = getProducts(state);
     const destinationId = quotation.destinationCountry.value;
     const selectedProduct = products.products.find((product) => product?.ID == destinationId);
     const productSuffix = selectedProduct.PRODUCT_SUFFIX;
     const productId = `${selectedProduct.PRODUCT_ID}`;


    const payload = {
        productId,
        productSuffix,
        emailAddress: quotation.shareEmail.value,
        travelDate: convertDateFormat(quotation.departureDate.value),
        returnDate: convertDateFormat(quotation.returnDate.value),
        dateOfBirth: convertDateFormat(quotation.dob.value),
        countryCode: 'KE',
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ke-travel-insurance/share-quote';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
