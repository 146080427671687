import {
    FIND_OUT_MORE_SEND,
    SPEAK_TO_ADVISOR_SEND,
    REWARDS_CLICKED_SET_VALUE
} from "../../../actions/atRetirement/financialAdvice"
import { getReferrerId } from '../../../selectors/atRetirement/intro'
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const financialAdviceFieldTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const referrerId = getReferrerId(state);
    const field = (() => {
        switch (action.type) {
            case FIND_OUT_MORE_SEND: return "FIND_OUT_MORE_SEND";
            case SPEAK_TO_ADVISOR_SEND: return "SPEAK_TO_ADVISOR_SEND";
            case REWARDS_CLICKED_SET_VALUE: return "REWARDS_CLICKED";
        }
    })();

    if (field) {
        otherEventTracking(action.type, findOtherEventIndex("BUTTON_CLICKED") , field, referrerId);
    }

    return result;
};
