import { COLLECT_LOGIN_DETAILS } from '../../../actions/digitalSavings/auth';
// import { BIASHARA_FLEXI_PREMIUM_SUCCESS, COLLECT_BIASHARA_FLEXI_COVER_DETAILS_FORM_DATA } from '../../../actions/digitalSavings/coverDetails';
// import { COLLECT_PAYLOAD_DATA } from '../../../actions/digitalSavings/payloadCollection';
// import { CHECK_PAYMENTS_STATUS, TRIGGER_CONFIRM_ORDER, TRIGGER_KE_PAYMENTS_STK_PUSH } from '../../../actions/digitalSavings/payment';
// import { COLLECT_SHARE_QUOTE_FORM_DATA } from '../../../actions/digitalSavings/quote';
import { loginService } from './login';
// import { orderConfirmationService } from './orderConfirmation';
// import { payloadSubmitService } from './payloadCollection';
// import { paymentsStkService, paymentsStkStatusService } from './payments';
// import { calculatePremium } from './quote';
// import { shareQuoteService } from './shareQuote';
import { TRIGGER_AGENT_TYPE } from '../../../actions/digitalSavings/agentModal';
import { getIntermeiaryAgent } from './agent';
import { COLLECT_QUOTE_FORM_DATA, COLLECT_SHARE_QUOTE_FORM_DATA } from '../../../actions/digitalSavings/quote';
import { premiumCalculationService } from './quote';
import { shareQuoteService } from './shareQuote';
import { CHECK_PAYMENTS_STATUS, TRIGGER_CONFIRM_ORDER, TRIGGER_KE_PAYMENTS_STK_PUSH } from '../../../actions/digitalSavings/payment';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { COLLECT_PAYLOAD_DATA } from '../../../actions/digitalSavings/payloadCollection';
import { payloadSubmitService } from './payloadCollection';
import { orderConfirmationService } from './orderConfirmation';

 export const digitalSavingsCover = (store, next, action) => {
    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {
        return payloadSubmitService(store, next, action);
    }

    if(action.type.startsWith(COLLECT_LOGIN_DETAILS)){
        return loginService(store, next, action);
    }

    if (action.type.startsWith(COLLECT_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_KE_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }

    if (action.type.startsWith(CHECK_PAYMENTS_STATUS)) {
        return paymentsStkStatusService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER)){
        return orderConfirmationService(store, next, action)
    }

    if(action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)){
        return shareQuoteService(store, next, action);
    }
    if (action.type.startsWith(TRIGGER_AGENT_TYPE)) {
        return getIntermeiaryAgent(store, next, action);
    }

    return null;
};
