import {
    SCORE_SET_VALUE,
    QUESTIONS_SET_VALUE,
    ACTIVE_QUESTION_SET_VALUE,
    ANSWER_SET_VALUE,
    ANSWER_SET_VALID,
    MODAL_SET_VALUE,
    KBA_SESSION_RESET_VALUE,
    TIMER_SET_VALUE,
    QUESTION_EXPIRED_PERFORM_ACTION 
} from "../../actions/customerVerificationService/knowledgeBasedAuthentication";
import {FIELD_STATUS} from "../status";

export const initKba = {
    questions: null,
    activeQuestion: 1,
    score: null,
    modal: null,
    form: {
        timeRemaining: null,
        answer_1: {questionId: null, value: null, status: null, error: null},
        answer_2: {questionId: null, value: null, status: null, error: null},
        answer_3: {questionId: null, value: null, status: null, error: null},
        answer_4: {questionId: null, value: null, status: null, error: null},
        answer_5: {questionId: null, value: null, status: null, error: null},
    }
};

export default (state = initKba, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;

    switch (action.type) {

        case QUESTIONS_SET_VALUE:
            return { ...state, questions: action.value };

        case SCORE_SET_VALUE:
            return { ...state, score: action.value };

        case MODAL_SET_VALUE:
            return { ...state, modal: action.value };
                
        case ACTIVE_QUESTION_SET_VALUE:
            if (state.activeQuestion < 5) {
                return { 
                    ...state,
                    activeQuestion: (state.activeQuestion + 1),
                    form: {
                        ...state.form,
                        timeRemaining: null
                    }
                };
            }
            return state;

        // Selected Answer
        case ANSWER_SET_VALUE:
            const answerKey = 'answer_' + state.activeQuestion;
            return {
                ...state,
                form: {
                    ...state.form,
                    [answerKey]: {
                        ...state.form[answerKey],
                        value: action.value, 
                        questionId: state.questions[state.activeQuestion - 1].questionId
                    }
                }
            };

        case ANSWER_SET_VALID:
            const answerKeyValid = 'answer_' + state.activeQuestion;
            return {
                ...state,
                form: {
                    ...state.form,
                    [answerKeyValid]: {
                        ...state.form[answerKeyValid],
                        error: action.error,
                        status: mapError(action.error)
                    }
                }
            };

        case KBA_SESSION_RESET_VALUE: 
            return { ...initKba };

        case TIMER_SET_VALUE:
            return {
                ...state,
                form: {
                    ...state.form,
                    timeRemaining: action.value
                }
            };

        case QUESTION_EXPIRED_PERFORM_ACTION:
            // If timer runs out, set answer to null, increment question number, and reset timer
            const answerKeyQuestion = 'answer_' + state.activeQuestion;
            return {
                ...state,
                form: {
                    ...state.form,
                    timeRemaining: null,
                    [answerKeyQuestion]: {
                        ...state.form[answerKeyQuestion],
                        value: null, 
                        questionId: state.questions[state.activeQuestion - 1].questionId
                    }
                }
            };
        

        default:
            return state;
    }
};
