// Transitions - validation
export const FORM_VALID_TRANSITION_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiariesSplit/transition/formValid';
export const FORM_INVALID_TRANSITION_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiariesSplit/transition/formInvalid';

export const transitionBeneficiariesSplit = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_BENEFICIARIES_SPLIT }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_BENEFICIARIES_SPLIT }),
};

// Transitions - form initialisation
export const FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiariesSplit/transition/initForm';
export const formInitTransitionBeneficiariesSplit = beneficiaries => ({ type: FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT, beneficiaries });

// Transition - reset state
export const RESET_STATE_TRANSITION_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiariesSplit/transition/resetState';
export const resetBeneficiariesSplit = () => ({ type: RESET_STATE_TRANSITION_BENEFICIARIES_SPLIT });

export const EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiariesSplit/transition/selectEvenSplit';
export const EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiariesSplit/transition/deselectEvenSplit';
export const selectEvenSplit = () => ({ type: EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT });
export const deselectEvenSplit = () => ({ type: EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT });

export const EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiariesSplit/transition/discardEvenSplit';
export const discardEvenSplit = () => ({ type: EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT });

// Events - form input
export const BENEFICIARIES_SPLIT_SET_VALUE = 'easiplusFuneralPlan/beneficiariesSplit/setValue/';
export const BENEFICIARIES_SPLIT_SET_VALID = 'easiplusFuneralPlan/beneficiariesSplit/setValid/';

export const SPLIT_PERCENTAGE_SET_VALUE = BENEFICIARIES_SPLIT_SET_VALUE + 'splitPercentage';
export const SPLIT_PERCENTAGE_SET_VALID = BENEFICIARIES_SPLIT_SET_VALID + 'splitPercentage';
export const setSplitPercentageValue = (value, beneficiaryId) => ({ type: SPLIT_PERCENTAGE_SET_VALUE, value, beneficiaryId });
export const setSplitPercentageValid = (error, beneficiaryId) => ({ type: SPLIT_PERCENTAGE_SET_VALID, error, beneficiaryId });

export const TOTAL_PERCENTAGE_SET_VALUE = BENEFICIARIES_SPLIT_SET_VALUE + 'totalPercentage';
export const TOTAL_PERCENTAGE_SET_VALID = BENEFICIARIES_SPLIT_SET_VALID + 'totalPercentage';
export const setTotalPercentageValue = value => ({ type: TOTAL_PERCENTAGE_SET_VALUE, value });
export const setTotalPercentageValid = error => ({ type: TOTAL_PERCENTAGE_SET_VALID, error });

export const EVEN_SPLIT_SINGLE_BENEFICIARIES_SPLIT = 'easiplusFuneralPlan/beneficiaries/transition/evenSplitSingleBeneficiary';
export const evenSplitSingleBeneficiary = beneficiaryId => ({ type: EVEN_SPLIT_SINGLE_BENEFICIARIES_SPLIT, beneficiaryId });