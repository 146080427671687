export const ASISA_UNCLAIMED_BENEFITS = "publicWeb/asisaUnclaimedBenefits";
//Form transitions
export const ASISA_UNCLAIMED_BENEFITS_TRANSITION = `${ASISA_UNCLAIMED_BENEFITS}/transition`;

export const ASISA_UNCLAIMED_BENEFITS_FORM_VALID_TRANSITION = `${ASISA_UNCLAIMED_BENEFITS_TRANSITION}/formValid`;
export const ASISA_UNCLAIMED_BENEFITS_FORM_INVALID_TRANSITION = `${ASISA_UNCLAIMED_BENEFITS_TRANSITION}/formInvalid`;

export const asisaUnclaimedBenefitsTransitions = {
  formValid: () => ({ type: ASISA_UNCLAIMED_BENEFITS_FORM_VALID_TRANSITION }),
  formInvalid: () => ({
    type: ASISA_UNCLAIMED_BENEFITS_FORM_INVALID_TRANSITION,
  }),
};

export const ASISA_UNCLAIMED_BENEFITS_FORM_SUBMIT = `${ASISA_UNCLAIMED_BENEFITS_TRANSITION}/formSubmit`;
export const submitAsisaUnclaimedBenefitsForm = () => ({
  type: ASISA_UNCLAIMED_BENEFITS_FORM_SUBMIT,
});

export const ASISA_UNCLAIMED_BENEFITS_FORM_CLEAR = `${ASISA_UNCLAIMED_BENEFITS_TRANSITION}/clearForm`;
export const clearAsisaUnclaimedBenefitsForm = () => ({
  type: ASISA_UNCLAIMED_BENEFITS_FORM_CLEAR
})

//API transitions
export const ASISA_UNCLAIMED_BENEFITS_API_TRANSITION = `${ASISA_UNCLAIMED_BENEFITS}/apiTransition`;

export const API_PENDING_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER = `${ASISA_UNCLAIMED_BENEFITS_API_TRANSITION}/pending`;
export const API_SUCCESS_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER = `${ASISA_UNCLAIMED_BENEFITS_API_TRANSITION}/success`;
export const API_FAILURE_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER = `${ASISA_UNCLAIMED_BENEFITS_API_TRANSITION}/failure`;

export const asisaUnclaimedBenefitsApiTransitions = {
  pending: () => ({
    type: API_PENDING_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER,
  }),
  success: (value) => ({
    type: API_SUCCESS_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER,
    value
  }),
  failure: () => ({
    type: API_FAILURE_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER,
  }),
};

//Form Toggle
export const ASISA_UNCLAIMED_BENEFITS_FORM_TOGGLE = `${ASISA_UNCLAIMED_BENEFITS}/toggle`;
export const TOGGLE_HAS_VALID_SA_ID_NUMBER = `${ASISA_UNCLAIMED_BENEFITS_FORM_TOGGLE}/hasValidSaIdNumber`;
export const TOGGLE_ACCEPT_TERMS_AND_CONDITIONS = `${ASISA_UNCLAIMED_BENEFITS_FORM_TOGGLE}/acceptTermsAndConditions`;
export const TOGGLE_SIDE_DRAWER = `${ASISA_UNCLAIMED_BENEFITS_FORM_TOGGLE}/sideDrawer`;

export const toggleHasValidSaIdNumber = () => ({
  type: TOGGLE_HAS_VALID_SA_ID_NUMBER,
});
export const toggleAcceptTermsAndConditions = () => ({
  type: TOGGLE_ACCEPT_TERMS_AND_CONDITIONS,
});

export const toggleSideDrawer = () => ({
  type: TOGGLE_SIDE_DRAWER,
})

//Form fields
export const ASISA_UNCLAIMED_BENEFITS_SET_VALUE = `${ASISA_UNCLAIMED_BENEFITS}/setValue`;
export const ASISA_UNCLAIMED_BENEFITS_SET_VALID = `${ASISA_UNCLAIMED_BENEFITS}/setValid`;

export const SA_ID_NUMBER_SET_VALUE = `${ASISA_UNCLAIMED_BENEFITS_SET_VALUE}/saIdNumber`;
export const SA_ID_NUMBER_SET_VALID = `${ASISA_UNCLAIMED_BENEFITS_SET_VALID}/saIdNumber`;
export const FIRSTNAME_SET_VALUE = `${ASISA_UNCLAIMED_BENEFITS_SET_VALUE}/firstname`;
export const FIRSTNAME_SET_VALID = `${ASISA_UNCLAIMED_BENEFITS_SET_VALID}/firstname`;
export const SURNAME_SET_VALUE = `${ASISA_UNCLAIMED_BENEFITS_SET_VALUE}/surname`;
export const SURNAME_SET_VALID = `${ASISA_UNCLAIMED_BENEFITS_SET_VALID}/surname`;
export const DATE_OF_BIRTH_SET_VALUE = `${ASISA_UNCLAIMED_BENEFITS_SET_VALUE}/dateOfBirth`;
export const DATE_OF_BIRTH_SET_VALID = `${ASISA_UNCLAIMED_BENEFITS_SET_VALID}/dateOfBirth`;
export const GENDER_SET_VALUE = `${ASISA_UNCLAIMED_BENEFITS_SET_VALUE}/gender`;
export const GENDER_SET_VALID = `${ASISA_UNCLAIMED_BENEFITS_SET_VALID}/gender`;

export const RECAPTCHA_VALUE = `${ASISA_UNCLAIMED_BENEFITS_SET_VALUE}/recaptcha`;

export const setSaIdNumberValue = (value) => ({
  type: SA_ID_NUMBER_SET_VALUE,
  value,
});
export const setSaIdNumberValid = (error) => ({
  type: SA_ID_NUMBER_SET_VALID,
  error,
});
export const setFirstnameValue = (value) => ({
  type: FIRSTNAME_SET_VALUE,
  value,
});
export const setFirstnameValid = (error) => ({
  type: FIRSTNAME_SET_VALID,
  error,
});
export const setSurnameValue = (value) => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = (error) => ({ type: SURNAME_SET_VALID, error });
export const setDateOfBirthValue = (value) => ({
  type: DATE_OF_BIRTH_SET_VALUE,
  value,
});
export const setDateOfBirthValid = (error) => ({
  type: DATE_OF_BIRTH_SET_VALID,
  error,
});
export const setGenderValue = (value) => ({
  type: GENDER_SET_VALUE,
  value,
});
export const setGenderValid = (error) => ({
  type: GENDER_SET_VALID,
  error,
});

export const setRecaptchaValue = (value) => ({ type: RECAPTCHA_VALUE, value });
