import { createSelector } from "reselect";
import { getCovidSelfAssessment } from "./index";
import { FORM_STATUS } from "../../reducers/status";

export const getBiographicInfo = createSelector(
    getCovidSelfAssessment,
    covidSelfAssessment => covidSelfAssessment.biographicInfo
);

export const getBiographicInfoFormIsValid = createSelector(
    getBiographicInfo,
    biographicInfo => biographicInfo.formStatus === FORM_STATUS.VALID,
);

export const getBiographicInfoForm = createSelector(
    getBiographicInfo,
    biographicInfo => biographicInfo.form,
);

const makeGetField = fieldId => createSelector(
    getBiographicInfoForm,
    biographicInfoForm => biographicInfoForm[fieldId]
);

export const getAge = makeGetField('age');
export const getGender = makeGetField('gender');
export const getPostalCode = makeGetField('postalCode');
