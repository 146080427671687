import {
    PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE,
    PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALID
} from '../../../actions/pastPerformanceCalculator/pastPerformanceCalculator';
import { pastPerfomanceCalculatorRulesEngine } from './calculator';

export const pastPerfomanceCalculatorRules = (store, next, action) => {
    if (action.type.startsWith(PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALUE) ||
        action.type.startsWith(PAST_PERFORMANCE_CALCULATOR_SET_INVESTMENT_AMOUNT_VALID)) {
        return pastPerfomanceCalculatorRulesEngine(store, next, action);
    }
    return null;
};

