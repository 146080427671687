import {
    AGE_SET_VALUE,
    INCOME_FREQUENCY_SET_VALUE,
    INCOME_VALUE_SET_VALUE,
    REFERENCE_DATA_SET_VALUE,
    RESULTS_SET_VALUE,
    RESULTS_VIEW_VALUE
} from "../../../actions/incomeTaxCalculator/advancedCalculator";
import {
    getTaxResult, 
    getAgeValue,
    getIncomeFrequencyValue,
    getIncomeValue,
    getAdvancedCalculatorReferenceData
} from "../../../selectors/incomeTaxCalculator/advancedCalculator";
import {simpleFieldTracking} from "../formTracking";
import { fireGtmEvent } from "../gtmEvents";
import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";
import { findOtherEventIndex, otherEventTracking } from "../otherEventTracking";

export const advancedCalculatorFieldTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);

    const fieldSelector = (() => {
        switch (action.type) {
            case AGE_SET_VALUE: return getAgeValue;
            case INCOME_FREQUENCY_SET_VALUE: return getIncomeFrequencyValue;
            case INCOME_VALUE_SET_VALUE: return getIncomeValue;
            case REFERENCE_DATA_SET_VALUE: return getAdvancedCalculatorReferenceData;
            case RESULTS_SET_VALUE: return getTaxResult;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        const sendValue = false;
        simpleFieldTracking({ action, field, sendValue });
    }

    return result;
};

export const advancedCalculatorOtherEventTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type){
            case RESULTS_VIEW_VALUE: 
            otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            fireGtmEvent('calculator_complete', { calculator_type: 'Income tax calculator - Advanced' });
            return 'Income tax calculator - Advanced';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}
