import { setValidationResults } from "../../../actions/digitalOnboarding/addressDetails";
import { ADDRESS_LINE_1, CITY_PHYSICAL, CITY_POSTAL, COMPLEX_BUILDING, POSTAL_CODE_PHYSICAL, POSTAL_CODE_POSTAL, POSTAL_SAME_AS_PHYSICAL, PROVINCE_PHYSICAL, PROVINCE_POSTAL, STREET_ADDRESS, SUBURB_PHYSICAL, SUBURB_POSTAL } from "../../../actions/digitalOnboarding/types";


const isValidString = str => str && str.length > 0;

const runValidations = (state, action) => {
    const validatePostalCode = (key, value) =>{
        const isValid = value.length === 4 && +value > 0;
        const errorMessage = isValid ? '' : 'Please enter your postal code.'
        return [{key, isValid, errorMessage }];
    }

    const validateProvince = (value,key) =>{
        const isValid = isValidString(value);
        let errorMessage = '';
        if(!isValid){
            errorMessage = 'Please enter your province.'
        }
        return [{key, isValid, errorMessage}]
    }

    const isValidComplexAndStreet = value =>{
        const regexValue = new RegExp(/(?!(^\d+$).*)^(?!.*(PO BOX|Private Bag))([a-z0-9 ])*$/,'gi');
        return regexValue.test(value);
    }

    const validateComplexOrBuilding = value =>{
        const isValid = isValidComplexAndStreet(value.trim());
        const errorMessage = isValid ? '' : 'Please enter a valid complex/building name.';
        return [{key: COMPLEX_BUILDING,isValid, errorMessage }]
    }

    const validateStreetAddress = value =>{
        const isValid = value !== '' && isValidComplexAndStreet(value.trim());
        const errorMessage = isValid ? '' : 'Please enter your street address.';
        return [{key: STREET_ADDRESS,isValid, errorMessage }]
    }

    const validatePostalSameAsPhysical = value =>{
        let isValid = value !== "false";
        return [
            { key: POSTAL_SAME_AS_PHYSICAL, isValid: true, errorMessage: '' },
            { key:ADDRESS_LINE_1, value: '', isValid, errorMessage: '' },
            { key:POSTAL_CODE_POSTAL, value: '', isValid, errorMessage: '' },
            { key:SUBURB_POSTAL, value: '', isValid, errorMessage: '' },
            { key:CITY_POSTAL, value: '', isValid, errorMessage: '' },
            { key:PROVINCE_POSTAL, value: '', isValid, errorMessage: '' }
        ];
    }

    const isValidPostalAddressLine = value=>{
        const regexValue = new RegExp(/^(PO BOX ||Private Bag x*)\d+$/,'gi');
        return regexValue.test(value);
    }


    const validateAddressLine1 = value =>{
        const isValid = isValidPostalAddressLine(value.trim());
        const errorMessage = isValid ? '' : 'Please enter your postal address.';
        return [{key: ADDRESS_LINE_1,isValid, errorMessage }]
    }

    const isValidSuburbAndCity = value =>{
        const regexValue = new RegExp(/^[a-z ]+$/,'gi');
        return regexValue.test(value);
    }

    const validateSuburbAndCity = (key,value,type) =>{
        const isValid = isValidSuburbAndCity(value);
        const hasValue = isValidString(value);
        const errorMessage = isValid ? '' : hasValue ? `Please enter a valid ${type}.` : `Please enter your ${type}.`;
        return [{key: key, isValid, errorMessage}];
    }

    const noValidationRequired = key => {
        return [{ key, isValid: true, errorMessage: '' }];
    }

    switch (action.key) {
        case COMPLEX_BUILDING:
            return validateComplexOrBuilding(action.value);
        case STREET_ADDRESS:
            return validateStreetAddress(action.value);
        case POSTAL_CODE_PHYSICAL:
        case POSTAL_CODE_POSTAL:
            return validatePostalCode(action.key, action.value);
        case SUBURB_PHYSICAL:
        case SUBURB_POSTAL:
            return validateSuburbAndCity(action.key, action.value, 'suburb');
        case CITY_PHYSICAL:
        case CITY_POSTAL:
            return validateSuburbAndCity(action.key, action.value, 'city');
        case PROVINCE_PHYSICAL:
        case PROVINCE_POSTAL:
            return validateProvince(action.value,action.key);
        case POSTAL_SAME_AS_PHYSICAL:
            return validatePostalSameAsPhysical(action.value);
        case ADDRESS_LINE_1:
            return validateAddressLine1(action.value);

    }

    return noValidationRequired(action.key);
}

export const addressValidation = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const validations = runValidations(state, action);
    store.dispatch(setValidationResults(validations));

    return result;
}