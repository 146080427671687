export const REGISTRATION_SET_VALUE = 'ltd/registration/setValue/';
export const REGISTRATION_SET_VALID = 'ltd/registration/setValid/';

// Registration Component
export const USER_TYPE_SET_VALUE = REGISTRATION_SET_VALUE + 'UserValue';
export const LEARNER_PLATFORM_REPRESENTATIVE_SET_VALUE = REGISTRATION_SET_VALUE + 'LearnerPlatformRepresentativeValue';
export const ORGANISATION_ID_SET_VALUE = REGISTRATION_SET_VALUE + 'OrganisationIdValue';
export const EMAIL_SET_VALUE = REGISTRATION_SET_VALUE + 'EmailValue';
export const EMAIL_SET_VALID = REGISTRATION_SET_VALID + 'EmailValid';
export const ORGANISATION_SET_VALUE = REGISTRATION_SET_VALUE + 'OganisationValue';
export const ORGANISATION_SET_VALID = REGISTRATION_SET_VALID + 'OrganisationValid';
export const REGISTRATION_CHECKBOX_SET_VALUE = REGISTRATION_SET_VALUE + 'CheckboxValue';
export const CONTENT_DISPLAYED_SET_VALUE = REGISTRATION_SET_VALUE + 'ContentDisplayedValue';
export const PLATFORM_OPTIONS_SET_VALUE = REGISTRATION_SET_VALUE + 'PlatformOptionsValue';
export const USER_TYPES_SET_VALUE = REGISTRATION_SET_VALUE + 'UserTypesValue';

export const SET_PLATFORM_OPTIONS = REGISTRATION_SET_VALUE + 'PlatformOptions';
export const SET_USER_TYPES = REGISTRATION_SET_VALUE + 'setUserTypes';
export const CUSTOMER_SEND = REGISTRATION_SET_VALUE + 'customerSend';
export const DOWNLOADS_SET_VALUE = REGISTRATION_SET_VALUE + 'DownloadsValue';

export const setUserTypeValue = (value) => ({ type: USER_TYPE_SET_VALUE, value });
export const setLearnerPlatformRepresentativeValue = (value) => ({
    type: LEARNER_PLATFORM_REPRESENTATIVE_SET_VALUE,
    value,
});
export const setOrganisationIdValue = (value) => ({ type: ORGANISATION_ID_SET_VALUE, value });
export const setEmailValue = (value) => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = (error) => ({ type: EMAIL_SET_VALID, error });
export const setOrganisationValue = (value) => ({ type: ORGANISATION_SET_VALUE, value });
export const setOrganisationValid = (error) => ({ type: ORGANISATION_SET_VALID, error });
export const setRegistrationCheckboxValue = (value) => ({
    
    type: REGISTRATION_CHECKBOX_SET_VALUE,
    value,
});
export const setContentDisplayedValue = (value) => ({ type: CONTENT_DISPLAYED_SET_VALUE, value });
export const setPlatformOptionsValue = (value) => ({ type: PLATFORM_OPTIONS_SET_VALUE, value });
export const setUserTypesValue = (value) => ({ type: USER_TYPES_SET_VALUE, value });
export const setDownloadsValue = (value) => ({ type: DOWNLOADS_SET_VALUE, value });

export const setPlatformOptions = () => ({ type: SET_PLATFORM_OPTIONS });
export const setUserTypes = () => ({ type: SET_USER_TYPES });
export const customerSend = () => ({ type: CUSTOMER_SEND });

export const ASSET_DOWNLOAD_SET_VALUE = 'ltd/assetDownload/setValue/';

// AssetDownload Component
export const DROPDOWN_SET_VALUE = ASSET_DOWNLOAD_SET_VALUE + 'setDropdownValue';
export const LANGUAGE_CHECKBOXES_SET_VALUE = ASSET_DOWNLOAD_SET_VALUE + 'LanguageCheckboxesValue';
export const MODAL_OPEN_SET_VALUE = ASSET_DOWNLOAD_SET_VALUE + 'ModalOpenValue/';
export const TOAST_VISIBLE_SET_VALUE = ASSET_DOWNLOAD_SET_VALUE + 'ToastVisibleValue/';

export const setDropdownValue = (value) => ({ type: DROPDOWN_SET_VALUE, value });
export const setLanguageCheckboxesValue = (value) => ({
    type: LANGUAGE_CHECKBOXES_SET_VALUE,
    value,
});
export const setModalOpenValue = (value) => ({ type: MODAL_OPEN_SET_VALUE, value });
export const setToastVisibleValue = (value) => ({ type: TOAST_VISIBLE_SET_VALUE, value });


export const SEARCH_AND_FILTER_SET_VALUE = 'ltd/searchAndFilter/setValue/';
// Search and filter Components
export const FILTER_VISIBLE_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'FilterVisibleValue';
export const ACTIVE_PAGE_NUMBER_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'ActivePageNumberValue';
export const SEARCH_VALUE_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'SearchValueValue';
export const TOTAL_CARDS_VISIBLE_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'TotalCardsVisibleValue';
export const UPDATE_CHECKBOXES_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'CheckboxesValue';
export const UPDATE_FILTER_CHECKBOXES_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'FilterCheckboxes';
export const TOPICS_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'Topics';
export const DISPLAYED_TOPICS_SET_VALUE = SEARCH_AND_FILTER_SET_VALUE + 'DisplayedTopics';

export const setFilterVisibleValue = (value) => ({ type: FILTER_VISIBLE_SET_VALUE, value });
export const setActivePageNumberValue = (value) => ({ type: ACTIVE_PAGE_NUMBER_SET_VALUE, value });
export const setSearchValue = (value) => ({ type: SEARCH_VALUE_SET_VALUE, value });
export const setTotalCardsVisibleValue = (value) => ({
    type: TOTAL_CARDS_VISIBLE_SET_VALUE,
    value,
});
export const updateCheckboxesValue = (value) => ({ type: UPDATE_CHECKBOXES_SET_VALUE, value });
export const updateFilterCheckboxes = (value) => ({
    type: UPDATE_FILTER_CHECKBOXES_SET_VALUE,
    value,
});
export const setTopicsValue = (value) => ({ type: TOPICS_SET_VALUE, value });
export const setDisplayedTopicsValue = (value) => ({ type: DISPLAYED_TOPICS_SET_VALUE, value });

