import { createSelector } from "reselect";
import { getDigiLifeLoanProtection } from "./index";
import { DLP_PAGE_INDICES } from "../../actions/digiLifeLoanProtection/types";

export const getRouting = createSelector(
  getDigiLifeLoanProtection,
  (dlp) => dlp.routing
);
export const getActivePageIndex = createSelector(
  getRouting,
  (routing) => routing.activePageIndex
);
export const getDoEfpNavigation = createSelector(
  getRouting,
  (routing) => routing.doNavigation
);
export const getDoExitEfpNavigation = createSelector(
  getRouting,
  (routing) => routing.doExitNavigation
);

export const makeGetIsPage = (pageIndex) =>
  createSelector(
    getRouting,
    (routing) => routing.activePageIndex === pageIndex
  );
export const getIsDropoffPage = createSelector(
  getRouting,
  (routing) => routing.activePageIndex === 1
);
export const getIsReplacementPolicyPage = makeGetIsPage(
  DLP_PAGE_INDICES.REPLACEMENT_POLICY
);
export const getIsPersonalDetailsPage = makeGetIsPage(
  DLP_PAGE_INDICES.PERSONAL_DETAILS
);
export const getIsPaymentDetailsPage = makeGetIsPage(
  DLP_PAGE_INDICES.PAYMENT_DETAILS
);
export const getIsUplaoadDocumentsPage = makeGetIsPage(
  DLP_PAGE_INDICES.UPLOAD_DOCUMENTS
);
export const getIsConfirmationPage = makeGetIsPage(
  DLP_PAGE_INDICES.CONFIRMATION
);
export const getIsThankYouPage = makeGetIsPage(DLP_PAGE_INDICES.THANK_YOU);

export const getProgressBarIndex = createSelector(
  getIsReplacementPolicyPage,
  getIsPersonalDetailsPage,
  getIsPaymentDetailsPage,
  getIsConfirmationPage,
  getIsUplaoadDocumentsPage,
  (
    isReplacementPolicyPage,
    isPersonalDetailsPage,
    isPaymentDetailsPage,
    isConfirmationPage,
    isUploadDocumentsPage
  ) => {
    if (isReplacementPolicyPage) {
      return -1;
    }

    if (isPersonalDetailsPage) {
      return 0;
    }

    if (isPaymentDetailsPage) {
      return 1;
    }
    if (isUploadDocumentsPage) {
      return 2;
    }

    if (isConfirmationPage) {
      return 3;
    }
  }
);

// used by the application wizard footer
export const getContinueButtonText = createSelector(
  getActivePageIndex,
  (activePageIndex) => {
    return activePageIndex === DLP_PAGE_INDICES.CONFIRMATION
      ? "SUBMIT"
      : "CONTINUE";
  }
);

// used by the application wizard header
// TODO: Implement page types and remove magic numbers
export const getHideDesktop = createSelector(
  getActivePageIndex,
  (activePageIndex) => {
    return activePageIndex < 1 || activePageIndex === 5;
  }
);

// used by the application wizard header
// TODO: Implement page types and remove magic numbers
export const getHideMobile = createSelector(
  getActivePageIndex,
  (activePageIndex) => {
    return activePageIndex === 7;
  }
);
