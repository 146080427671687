import {
    CURRENT_QUESTION_SET_VALUE,
    ANSWER_SET_VALUE,
    QUIZ_CLEAR_VALUES,
    CREATE_NEW_QUIZ,
} from '../../actions/quizAssessment/quiz';

const getNewQuizState = (quizId, totalQuestions) => ({
    totalQuestions: totalQuestions,
    currentQuestion: 0,
    answers: [],
    quizId: quizId,
});

export const initialState = {
    quizzes: []
};

export default (state = initialState, action) => {
    let quizIndex, newQuizzes;
    switch (action.type) {
        case CREATE_NEW_QUIZ:
            const quizExists = state.quizzes.length > 0 && state.quizzes.find(quiz => quiz.quizId === action.quizId)
            if (!quizExists) {
                return {
                    ...state,
                    quizzes: [ ...state.quizzes, getNewQuizState(action.quizId, action.totalQuestions)]
                }
            }
            return state

        case CURRENT_QUESTION_SET_VALUE:
            quizIndex = state.quizzes.findIndex(quiz => quiz.quizId === action.quizId);
            newQuizzes = [...state.quizzes];

            if (quizIndex === -1) {
                return state;
            }

            newQuizzes[quizIndex].currentQuestion = action.value
            return {
                ...state,
                quizzes: JSON.parse(JSON.stringify(newQuizzes)),
            };

        case ANSWER_SET_VALUE:
            quizIndex = state.quizzes.findIndex(quiz => quiz.quizId === action.quizId);
            newQuizzes = [...state.quizzes];

            if (quizIndex === -1) {
                return state;
            }

            newQuizzes[quizIndex].answers =  [...newQuizzes[quizIndex].answers, action.value]
            return {
                ...state,
                quizzes: JSON.parse(JSON.stringify(newQuizzes)),
            };

        case QUIZ_CLEAR_VALUES:
            quizIndex = state.quizzes.findIndex(quiz => quiz.quizId === action.quizId);
            newQuizzes = [...state.quizzes];

            if (quizIndex === -1) {
                return state;
            }

            newQuizzes[quizIndex] = {
                ...newQuizzes[quizIndex],
                currentQuestion: 0,
                answers: [],
            }
            return {
                ...state,
                quizzes: JSON.parse(JSON.stringify(newQuizzes))
            };

        default:
            return state;
    }
};
