import {
    BRANCH_DATA_VALUE,
    FORMATTED_ADDRESS_VALUE,
    LOCATION_TYPE_VALUE,
    SELECTED_BRANCH_VALUE,
    SERVICE_TYPE_VALUE,
    USER_LOCATION_VALUE,
    VISIBLE_BRANCHES_VALUE,
} from '../../actions/publicWeb/branchLocator';

const GeoUtil = {
    earthRadius: 6371,

    radiansToDegrees (radians) {
        return radians * (180 / Math.PI);
    },

    degreesToRadians (deg) {
        return deg * (Math.PI / 180);
    },

    maxLatitude (startLatitude, distanceInKm) {
        return startLatitude + this.radiansToDegrees(distanceInKm / this.earthRadius);
    },

    minLatitude (startLatitude, distanceInKm) {
        return startLatitude - this.radiansToDegrees(distanceInKm / this.earthRadius);
    },

    maxLongitude (startLatitude, startLongitude, distanceInKm) {
        const cosLat = Math.cos(this.degreesToRadians(startLatitude));
        return startLongitude + this.radiansToDegrees(Math.asin(distanceInKm / this.earthRadius) / cosLat);
    },

    minLongitude (startLatitude, startLongitude, distanceInKm) {
        const cosLat = Math.cos(this.degreesToRadians(startLatitude));
        return startLongitude - this.radiansToDegrees(Math.asin(distanceInKm / this.earthRadius) / cosLat);
    },

    /** 
     * Calculates distance in KM between two lat/long coordinate sets
     * @typedef {Object} Coordinate
     * @property {number} lat
     * @property {number} long
     * @param {Coordinate} start
     * @param {Coordinate} end
     */
    getDistance (start, end) {
        const startLatRadians = this.degreesToRadians(start.lat);
        const endLatRadians = this.degreesToRadians(end.lat);

        const latDelta = this.degreesToRadians(end.lat - start.lat);
        const longDelta = this.degreesToRadians(end.long - start.long);

        const a = Math.sin(latDelta / 2) * Math.sin(latDelta / 2) + 
            Math.cos(startLatRadians) * Math.cos(endLatRadians) *
            Math.sin(longDelta / 2) * Math.sin(longDelta / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const distance = this.earthRadius * c;
        return distance;
    }
};

const camelize = str => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

const initBranchLocator = {
    branchData: [],
    visibleBranches: [],
    selectedBranch: {},
    formattedAddress: '',
    locationType: '',
    userLocation: {},
    serviceType: '',
};

export default (state = initBranchLocator, action) => {
    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    switch (action.type) {
    case BRANCH_DATA_VALUE:
        const sortedBranches = sortBranches(action.value);
        return updateFormField('branchData', field => ({
            ...field,
            value: sortedBranches,
        }));

    case VISIBLE_BRANCHES_VALUE:
        return updateFormField('visibleBranches', field => ({
            ...field,
            value: action.value,
        }));

    case SELECTED_BRANCH_VALUE:
        return updateFormField('selectedBranch', field => ({
            ...field,
            value: action.value,
        }));

    case FORMATTED_ADDRESS_VALUE:
        return updateFormField('formattedAddress', field => ({
            ...field,
            value: action.value,
        }));

    case LOCATION_TYPE_VALUE:
        return updateFormField('locationType', field => ({
            ...field,
            value: action.value,
        }));

    case USER_LOCATION_VALUE:
        return updateFormField('userLocation', field => ({
            ...field,
            value: action.value,
        }));

    case SERVICE_TYPE_VALUE:
        return updateFormField('serviceType', field => ({
            ...field,
            value: action.value,
        }));

    default:
        return state;
    }
}

function sortBranches(data) {
    const userLat = data.findBranchParams.searchValues.userLocation.lat;
    const userLong = data.findBranchParams.searchValues.userLocation.long;
    const serviceType = data.findBranchParams.searchValues.serviceType;
    const branchesByDistance = getBranchesByDistanceFromUser(userLat, userLong, data.branches, data.findBranchParams.distance);
    return getBranchesByService(branchesByDistance, serviceType)
}

function getBranchesByService(branches, serviceType) {
    const branchesByService = branches.filter((branch) => {
        return branch.node.brand === serviceType;
    });
    return {branches: branchesByService, count: branchesByService.length};
}

function getBranchesByDistanceFromUser(userLatitude, userLongitude, branches, distance) {
    const branchesByDistanceRaw = branches.map(branch => ({
        ...branch,
        location: {
            latitude: branch.node.latitude,
            longitude: branch.node.longitude
        }
    }))
    .sort((branchA, branchB) => {
        const distanceA = GeoUtil.getDistance({lat: userLatitude, long: userLongitude}, {
            lat: branchA.node.latitude,
            long: branchA.location.longitude
        });
        const distanceB = GeoUtil.getDistance({lat: userLatitude, long: userLongitude}, {
            lat: branchB.node.latitude,
            long: branchB.location.longitude
        });
        return distanceA - distanceB;
    });
    // round 1 done with 20km radius
    let maxLat = GeoUtil.maxLatitude(userLatitude, distance);
    let maxLong = GeoUtil.maxLongitude(userLatitude, userLongitude, distance);
    let minLat = GeoUtil.minLatitude(userLatitude, distance);
    let minLong = GeoUtil.minLongitude(userLatitude, userLongitude, distance);
    let branchesByDistance = branchesByDistanceRaw.filter((branch) => {
        const branchLat = branch.node.latitude;
        const branchLong = branch.node.longitude;
        return (branchLat >= minLat && branchLat <= maxLat && branchLong >= minLong && branchLong <= maxLong);
    });

    // round 2 done with 50km radius
    if(branchesByDistance.length === 0) {
        let distanceFromLocationInKm2ndRound = 50;
        maxLat = GeoUtil.maxLatitude(userLatitude, distanceFromLocationInKm2ndRound);
        maxLong = GeoUtil.maxLongitude(userLatitude, userLongitude, distanceFromLocationInKm2ndRound);
        minLat = GeoUtil.minLatitude(userLatitude, distanceFromLocationInKm2ndRound);
        minLong = GeoUtil.minLongitude(userLatitude, userLongitude, distanceFromLocationInKm2ndRound);
        branchesByDistance = branchesByDistanceRaw.filter((branch) => {
            const branchLat = branch.node.latitude;
            const branchLong = branch.node.longitude;
            return (branchLat >= minLat && branchLat <= maxLat && branchLong >= minLong && branchLong <= maxLong);
        });
    }
    return branchesByDistance;
}