import {
  CITY_SET_VALID,
  CITY_SET_VALUE,
  COMPLEX_SET_VALID,
  COMPLEX_SET_VALUE,
  COUNTRY_SET_VALID,
  COUNTRY_SET_VALUE,
  ID_SET_VALID,
  ID_SET_VALUE,
  LEAD_VALID_TRANSITION_AUXILIARY_DETAILS,
  MARITAL_STATUS_SET_VALID,
  MARITAL_STATUS_SET_VALUE,
  MINOR_FIRST_NAMES_SET_VALID,
  MINOR_FIRST_NAMES_SET_VALUE,
  MINOR_FORM_RESET_VALUES,
  MINOR_ID_SET_VALID,
  MINOR_ID_SET_VALUE,
  MINOR_SURNAME_SET_VALID,
  MINOR_SURNAME_SET_VALUE,
  MINOR_TITLE_SET_VALID,
  MINOR_TITLE_SET_VALUE,
  NATIONALITY_SET_VALID,
  NATIONALITY_SET_VALUE,
  POSTAL_ADDRESS_METHOD_CLEAR_VALUE,
  POSTAL_ADDRESS_METHOD_VALID,
  POSTAL_ADDRESS_METHOD_VALUE,
  POSTAL_ADDRESS_SAME_TOGGLE_VALUE,
  POSTAL_ADDRESS_SAME_VALID,
  POSTAL_ADDRESS_SAME_VALUE,
  POSTAL_CITY_CLEAR_VALUE,
  POSTAL_CITY_SET_VALID,
  POSTAL_CITY_SET_VALUE,
  POSTAL_CODE_SET_VALID,
  POSTAL_CODE_SET_VALUE,
  POSTAL_COMPLEX_CLEAR_VALUE,
  POSTAL_COMPLEX_SET_VALID,
  POSTAL_COMPLEX_SET_VALUE,
  POSTAL_COUNTRY_CLEAR_VALUE,
  POSTAL_COUNTRY_SET_VALID,
  POSTAL_COUNTRY_SET_VALUE,
  POSTAL_POSTAL_CODE_CLEAR_VALUE,
  POSTAL_POSTAL_CODE_SET_VALID,
  POSTAL_POSTAL_CODE_SET_VALUE,
  POSTAL_STREET_ADDRESS_CLEAR_VALUE,
  POSTAL_STREET_ADDRESS_SET_VALID,
  POSTAL_STREET_ADDRESS_SET_VALUE,
  POSTAL_SUBURB_CLEAR_VALUE,
  POSTAL_SUBURB_SET_VALID,
  POSTAL_SUBURB_SET_VALUE,
  PO_BOX_CLEAR_VALUE,
  PO_BOX_SET_VALID,
  PO_BOX_SET_VALUE,
  PO_COUNTRY_CLEAR_VALUE,
  PO_COUNTRY_SET_VALID,
  PO_COUNTRY_SET_VALUE,
  PO_POSTAL_CODE_CLEAR_VALUE,
  PO_POSTAL_CODE_SET_VALID,
  PO_POSTAL_CODE_SET_VALUE,
  PO_POST_OFFICE_CLEAR_VALUE,
  PO_POST_OFFICE_SET_VALID,
  PO_POST_OFFICE_SET_VALUE,
  PRIVATE_BAG_CLEAR_VALUE,
  PRIVATE_BAG_SET_VALID,
  PRIVATE_BAG_SET_VALUE,
  PRIVATE_COUNTRY_CLEAR_VALUE,
  PRIVATE_COUNTRY_SET_VALID,
  PRIVATE_COUNTRY_SET_VALUE,
  PRIVATE_POSTAL_CODE_CLEAR_VALUE,
  PRIVATE_POSTAL_CODE_SET_VALID,
  PRIVATE_POSTAL_CODE_SET_VALUE,
  PRIVATE_POST_OFFICE_CLEAR_VALUE,
  PRIVATE_POST_OFFICE_SET_VALID,
  PRIVATE_POST_OFFICE_SET_VALUE,
  RESET_AUXILIARY_DETAILS,
  SPOUSE_FIRST_NAMES_SET_VALID,
  SPOUSE_FIRST_NAMES_SET_VALUE,
  SPOUSE_ID_NUMBER_RESET_VALUE,
  SPOUSE_ID_NUMBER_SET_VALID,
  SPOUSE_ID_NUMBER_SET_VALUE,
  SPOUSE_ID_TYPE_TOGGLE_VALUE,
  SPOUSE_PASSPORT_NUMBER_RESET_VALUE,
  SPOUSE_PASSPORT_NUMBER_SET_VALID,
  SPOUSE_PASSPORT_NUMBER_SET_VALUE,
  SPOUSE_SURNAME_SET_VALID,
  SPOUSE_SURNAME_SET_VALUE,
  SPOUSE_TITLE_SET_VALID,
  SPOUSE_TITLE_SET_VALUE,
  STREET_ADDRESS_SET_VALID,
  STREET_ADDRESS_SET_VALUE,
  SUBURB_SET_VALID,
  SUBURB_SET_VALUE,
} from "../../actions/taxFreeSavingsAccount/auxiliaryDetails";
import { FIELD_STATUS, TOGGLE_STATUS } from "../status";

export const initCountry = "SouthAfrica";
export const initAuxiliaryDetails = {
  gCSStreetAddress: null,
  gCSPostalAddress: null,
  leadDetails: null,

  form: {
    maritalStatus: { value: null, error: null, status: null },

    marriedInCommunityOfProperty: { value: TOGGLE_STATUS.UNTOUCHED, error: null, status: FIELD_STATUS.VALID },
    spouseTitle: { value: null, error: null, status: null },
    spouseFirstNames: { value: null, error: null, status: null },
    spouseSurname: { value: null, error: null, status: null },
    spouseIdentityType: { value: true, error: null, status: FIELD_STATUS.VALID },
    spouseIdNumber: { value: null, error: null, status: null },
    spousePassportNumber: { value: null, error: null, status: null },

    minorTitle: { value: null, error: null, status: null },
    minorFirstNames: { value: null, error: null, status: null },
    minorSurname: { value: null, error: null, status: null },
    minorId: { value: null, error: null, status: null },

    nationality: { value: null, error: null, status: null },
    id: { value: null, error: null, status: null },

    complex: { value: null, error: null, status: FIELD_STATUS.VALID },
    streetAddress: { value: null, error: null, status: null },
    suburb: { value: null, error: null, status: FIELD_STATUS.VALID },
    city: { value: null, error: null, status: null },
    postalCode: { value: null, error: null, status: null },
    country: { value: null, error: null, status: null },

    postalAddressSame: { value: TOGGLE_STATUS.YES, error: null, status: FIELD_STATUS.VALID },
    postalAddressMethod: { value: null, error: null, status: null },

    poBox: { value: null, error: null, status: null },
    poPostOffice: { value: null, error: null, status: null },
    poPostalCode: { value: null, error: null, status: null },
    poCountry: { value: initCountry, error: null, status: FIELD_STATUS.VALID },

    privateBag: { value: null, error: null, status: null },
    privatePostOffice: { value: null, error: null, status: null },
    privatePostalCode: { value: null, error: null, status: null },
    privateCountry: { value: initCountry, error: null, status: FIELD_STATUS.VALID },

    postalComplex: { value: null, error: null, status: FIELD_STATUS.VALID },
    postalStreetAddress: { value: null, error: null, status: null },
    postalSuburb: { value: null, error: null, status: FIELD_STATUS.VALID },
    postalCity: { value: null, error: null, status: null },
    postalPostalCode: { value: null, error: null, status: null },
    postalCountry: { value: initCountry, error: null, status: FIELD_STATUS.VALID },
  },
};

export default (state = initAuxiliaryDetails, action) => {
  const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
  const clearError = () => FIELD_STATUS.UNTOUCHED;
  const clearCountryError = () => FIELD_STATUS.VALID;
  const resetField = () => {
    return { value: null, error: null, status: null };
  };
  const updateFormField = (fieldId, update) => ({
    ...state,
    form: {
      ...state.form,
      [fieldId]: update(state.form[fieldId]),
    },
  });

  switch (action.type) {
    case RESET_AUXILIARY_DETAILS:
      return { ...initAuxiliaryDetails };

    case LEAD_VALID_TRANSITION_AUXILIARY_DETAILS:
      return { ...state, leadDetails: action.leadDetails };

    case MARITAL_STATUS_SET_VALUE:
      return updateFormField("maritalStatus", (field) => ({
        ...field,
        value: action.value,
      }));
    case MARITAL_STATUS_SET_VALID:
      return updateFormField("maritalStatus", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SPOUSE_TITLE_SET_VALUE:
      return updateFormField("spouseTitle", (field) => ({
        ...field,
        value: action.value,
      }));
    case SPOUSE_TITLE_SET_VALID:
      return updateFormField("spouseTitle", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SPOUSE_FIRST_NAMES_SET_VALUE:
      return updateFormField("spouseFirstNames", (field) => ({
        ...field,
        value: action.value,
      }));
    case SPOUSE_FIRST_NAMES_SET_VALID:
      return updateFormField("spouseFirstNames", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SPOUSE_SURNAME_SET_VALUE:
      return updateFormField("spouseSurname", (field) => ({
        ...field,
        value: action.value,
      }));
    case SPOUSE_SURNAME_SET_VALID:
      return updateFormField("spouseSurname", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case MINOR_TITLE_SET_VALUE:
      return updateFormField("minorTitle", (field) => ({
        ...field,
        value: action.value,
      }));
    case MINOR_TITLE_SET_VALID:
      return updateFormField("minorTitle", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case MINOR_FIRST_NAMES_SET_VALUE:
      return updateFormField("minorFirstNames", (field) => ({
        ...field,
        value: action.value,
      }));
    case MINOR_FIRST_NAMES_SET_VALID:
      return updateFormField("minorFirstNames", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case MINOR_SURNAME_SET_VALUE:
      return updateFormField("minorSurname", (field) => ({
        ...field,
        value: action.value,
      }));
    case MINOR_SURNAME_SET_VALID:
      return updateFormField("minorSurname", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case MINOR_ID_SET_VALUE:
      return updateFormField("minorId", (field) => ({
        ...field,
        value: action.value,
      }));
    case MINOR_ID_SET_VALID:
      return updateFormField("minorId", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case ID_SET_VALUE:
      return updateFormField("id", (field) => ({
        ...field,
        value: action.value,
      }));
    case ID_SET_VALID:
      return updateFormField("id", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case NATIONALITY_SET_VALUE:
      return updateFormField("nationality", (field) => ({
        ...field,
        value: action.value,
      }));
    case NATIONALITY_SET_VALID:
      return updateFormField("nationality", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SPOUSE_ID_TYPE_TOGGLE_VALUE:
      return updateFormField("spouseIdentityType", (field) => ({
        ...field,
        value: state.form.spouseIdentityType.value !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO,
      }));

    case SPOUSE_ID_NUMBER_SET_VALUE:
      return updateFormField("spouseIdNumber", (field) => ({
        ...field,
        value: action.value,
      }));
    case SPOUSE_ID_NUMBER_SET_VALID:
      return updateFormField("spouseIdNumber", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SPOUSE_PASSPORT_NUMBER_SET_VALUE:
      return updateFormField("spousePassportNumber", (field) => ({
        ...field,
        value: action.value,
      }));
    case SPOUSE_PASSPORT_NUMBER_SET_VALID:
      return updateFormField("spousePassportNumber", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case COMPLEX_SET_VALUE:
      return updateFormField("complex", (field) => ({
        ...field,
        value: action.value,
      }));
    case COMPLEX_SET_VALID:
      return updateFormField("complex", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case STREET_ADDRESS_SET_VALUE:
      return updateFormField("streetAddress", (field) => ({
        ...field,
        value: action.value,
      }));
    case STREET_ADDRESS_SET_VALID:
      return updateFormField("streetAddress", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case SUBURB_SET_VALUE:
      return updateFormField("suburb", (field) => ({
        ...field,
        value: action.value,
      }));
    case SUBURB_SET_VALID:
      return updateFormField("suburb", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case CITY_SET_VALUE:
      return updateFormField("city", (field) => ({
        ...field,
        value: action.value,
      }));
    case CITY_SET_VALID:
      return updateFormField("city", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case POSTAL_CODE_SET_VALUE:
      return updateFormField("postalCode", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_CODE_SET_VALID:
      return updateFormField("postalCode", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case COUNTRY_SET_VALUE:
      return updateFormField("country", (field) => ({
        ...field,
        value: action.value,
      }));
    case COUNTRY_SET_VALID:
      return updateFormField("country", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Postal Address Method
    case POSTAL_ADDRESS_METHOD_VALUE:
      return updateFormField("postalAddressMethod", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_ADDRESS_METHOD_VALID:
      return updateFormField("postalAddressMethod", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case POSTAL_ADDRESS_SAME_TOGGLE_VALUE:
      // caters for setting value initialy
      if (state.form.postalAddressSame.value === TOGGLE_STATUS.UNTOUCHED) {
        const postalToggle = action.value == "true";
        return updateFormField("postalAddressSame", (field) => ({
          ...field,
          value: postalToggle,
        }));
      }
      return updateFormField("postalAddressSame", (field) => ({
        ...field,
        value: state.form.postalAddressSame.value !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO,
      }));
    case POSTAL_ADDRESS_SAME_VALUE:
      return updateFormField("postalAddressSame", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_ADDRESS_SAME_VALID:
      return updateFormField("postalAddressSame", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));
    case POSTAL_ADDRESS_METHOD_CLEAR_VALUE:
      return updateFormField("postalAddressMethod", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));

    // PO Box Postal Address
    case PO_BOX_SET_VALUE:
      return updateFormField("poBox", (field) => ({
        ...field,
        value: action.value,
      }));
    case PO_BOX_SET_VALID:
      return updateFormField("poBox", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case PO_POST_OFFICE_SET_VALUE:
      return updateFormField("poPostOffice", (field) => ({
        ...field,
        value: action.value,
      }));
    case PO_POST_OFFICE_SET_VALID:
      return updateFormField("poPostOffice", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case PO_POSTAL_CODE_SET_VALUE:
      return updateFormField("poPostalCode", (field) => ({
        ...field,
        value: action.value,
      }));
    case PO_POSTAL_CODE_SET_VALID:
      return updateFormField("poPostalCode", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case PO_COUNTRY_SET_VALUE:
      return updateFormField("poCountry", (field) => ({
        ...field,
        value: action.value,
      }));
    case PO_COUNTRY_SET_VALID:
      return updateFormField("poCountry", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // PO Box Postal Address clear
    case PO_BOX_CLEAR_VALUE:
      return updateFormField("poBox", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case PO_POST_OFFICE_CLEAR_VALUE:
      return updateFormField("poPostOffice", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case PO_POSTAL_CODE_CLEAR_VALUE:
      return updateFormField("poPostalCode", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case PO_COUNTRY_CLEAR_VALUE:
      return updateFormField("poCountry", (field) => ({
        ...field,
        value: initCountry,
        error: null,
        status: clearCountryError(),
      }));

    // Private Bag postal address
    case PRIVATE_BAG_SET_VALUE:
      return updateFormField("privateBag", (field) => ({
        ...field,
        value: action.value,
      }));
    case PRIVATE_BAG_SET_VALID:
      return updateFormField("privateBag", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case PRIVATE_POST_OFFICE_SET_VALUE:
      return updateFormField("privatePostOffice", (field) => ({
        ...field,
        value: action.value,
      }));
    case PRIVATE_POST_OFFICE_SET_VALID:
      return updateFormField("privatePostOffice", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case PRIVATE_POSTAL_CODE_SET_VALUE:
      return updateFormField("privatePostalCode", (field) => ({
        ...field,
        value: action.value,
      }));
    case PRIVATE_POSTAL_CODE_SET_VALID:
      return updateFormField("privatePostalCode", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case PRIVATE_COUNTRY_SET_VALUE:
      return updateFormField("privateCountry", (field) => ({
        ...field,
        value: action.value,
      }));
    case PRIVATE_COUNTRY_SET_VALID:
      return updateFormField("privateCountry", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Private bag postal address clear
    case PRIVATE_BAG_CLEAR_VALUE:
      return updateFormField("privateBag", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case PRIVATE_POST_OFFICE_CLEAR_VALUE:
      return updateFormField("privatePostOffice", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case PRIVATE_POSTAL_CODE_CLEAR_VALUE:
      return updateFormField("privatePostalCode", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case PRIVATE_COUNTRY_CLEAR_VALUE:
      return updateFormField("privateCountry", (field) => ({
        ...field,
        value: initCountry,
        error: null,
        status: clearCountryError(),
      }));

    // Residential Postal Address
    case POSTAL_COMPLEX_SET_VALUE:
      return updateFormField("postalComplex", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_COMPLEX_SET_VALID:
      return updateFormField("postalComplex", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case POSTAL_STREET_ADDRESS_SET_VALUE:
      return updateFormField("postalStreetAddress", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_STREET_ADDRESS_SET_VALID:
      return updateFormField("postalStreetAddress", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case POSTAL_SUBURB_SET_VALUE:
      return updateFormField("postalSuburb", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_SUBURB_SET_VALID:
      return updateFormField("postalSuburb", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case POSTAL_CITY_SET_VALUE:
      return updateFormField("postalCity", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_CITY_SET_VALID:
      return updateFormField("postalCity", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case POSTAL_POSTAL_CODE_SET_VALUE:
      return updateFormField("postalPostalCode", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_POSTAL_CODE_SET_VALID:
      return updateFormField("postalPostalCode", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    case POSTAL_COUNTRY_SET_VALUE:
      return updateFormField("postalCountry", (field) => ({
        ...field,
        value: action.value,
      }));
    case POSTAL_COUNTRY_SET_VALID:
      return updateFormField("postalCountry", (field) => ({
        ...field,
        error: action.error,
        status: mapError(action.error),
      }));

    // Residential Postal Address clear
    case POSTAL_COMPLEX_CLEAR_VALUE:
      return updateFormField("postalComplex", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case POSTAL_STREET_ADDRESS_CLEAR_VALUE:
      return updateFormField("postalStreetAddress", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case POSTAL_SUBURB_CLEAR_VALUE:
      return updateFormField("postalSuburb", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case POSTAL_CITY_CLEAR_VALUE:
      return updateFormField("postalCity", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case POSTAL_POSTAL_CODE_CLEAR_VALUE:
      return updateFormField("postalPostalCode", (field) => ({
        ...field,
        value: null,
        error: null,
        status: clearError(),
      }));
    case POSTAL_COUNTRY_CLEAR_VALUE:
      return updateFormField("postalCountry", (field) => ({
        ...field,
        value: initCountry,
        error: null,
        status: clearCountryError(),
      }));

    case MINOR_FORM_RESET_VALUES:
      return {
        ...state,
        form: {
          ...state.form,
          minorFirstNames: resetField(),
          minorSurname: resetField(),
          minorTitle: resetField(),
          minorId: resetField(),
        },
      };

    case SPOUSE_PASSPORT_NUMBER_RESET_VALUE:
      return {
        ...state,
        form: {
          ...state.form,
          spousePassportNumber: resetField(),
        },
      };

    case SPOUSE_ID_NUMBER_RESET_VALUE:
      return {
        ...state,
        form: {
          ...state.form,
          spouseIdNumber: resetField(),
        },
      };

    default:
      return state;
  }
};
