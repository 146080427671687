export const PERSONAL_LOANS_CALCULATOR_TRANSITION = 'personalLoans/calculator/transition/'
export const FORM_VALID_TRANSITION_CALCULATOR = PERSONAL_LOANS_CALCULATOR_TRANSITION + 'formValid';
export const FORM_INVALID_TRANSITION_CALCULATOR = PERSONAL_LOANS_CALCULATOR_TRANSITION + 'formInvalid';

export const CONTINUE_TRANSITION_LOAN_AMOUNT_DETAILS = PERSONAL_LOANS_CALCULATOR_TRANSITION + 'continueLoanAmountDetails';
export const continueLoanAmountDetails = () => ({ type: CONTINUE_TRANSITION_LOAN_AMOUNT_DETAILS });

export const BACK_TRANSITION_REPAYMENT_DURATION_DETAILS = PERSONAL_LOANS_CALCULATOR_TRANSITION + 'backRepaymentDetails';
export const backRepaymentDurationDetails = () => ({ type: BACK_TRANSITION_REPAYMENT_DURATION_DETAILS });

export const CALCULATE_TRANSITION_LOAN_RESULTS = PERSONAL_LOANS_CALCULATOR_TRANSITION + 'calculateLoanResults';
export const calculateLoanResults = () => ({ type: CALCULATE_TRANSITION_LOAN_RESULTS });

export const transitionPersonalLoansCalculator = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_CALCULATOR }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CALCULATOR })
}

//Calculation actions
export const REPAYMENT_CALCULATION = 'personalLoans/calculator/calculate/';

export const CALCULATE_LOAN_REPAYMENT = REPAYMENT_CALCULATION + 'calculation'
export const CALCULATE_LOAN_REPAYMENT_SUCCESS = REPAYMENT_CALCULATION + 'success';
export const CALCULATE_LOAN_REPAYMENT_FAILURE = REPAYMENT_CALCULATION + 'failure';

export const calculateLoanRepayment = () => ({ type: CALCULATE_LOAN_REPAYMENT });
export const calculateLoanRepaymentSuccess = (value) => ({ type: CALCULATE_LOAN_REPAYMENT_SUCCESS, value })
export const calculateLoanRepaymentFailure = (error) => ({ type: CALCULATE_LOAN_REPAYMENT_FAILURE, error });


//Personal loans form actions
export const RESET_CALCULATOR_FORM = 'personalLoans/calculator/reset/form';
export const resetCalculatorForm = () => ({ type: RESET_CALCULATOR_FORM });

export const PERSONAL_LOANS_CALCULATOR_SET_VALUE = 'personalLoans/calculator/setValue/';
export const PERSONAL_LOANS_CALCULATOR_SET_VALID = 'personalLoans/calculator/setValid/';
export const PERSONAL_LOANS_CALCULATOR_CLEAR_VALUE = 'personalLoans/calculator/clearValue/';

export const AMOUNT_BORROWED_SET_VALUE = PERSONAL_LOANS_CALCULATOR_SET_VALUE + 'amountBorrowed';
export const AMOUNT_BORROWED_SET_VALID = PERSONAL_LOANS_CALCULATOR_SET_VALID + 'amountBorrowed';
export const OTHER_AMOUNT_BORROWED_SET_VALUE = PERSONAL_LOANS_CALCULATOR_SET_VALUE + 'otherAmountBorrowed';
export const OTHER_AMOUNT_BORROWED_SET_VALID = PERSONAL_LOANS_CALCULATOR_SET_VALID + 'otherAmountBorrowed';
export const OTHER_AMOUNT_BORROWED_CLEAR_VALUE = PERSONAL_LOANS_CALCULATOR_CLEAR_VALUE + 'otherAmountBorrowed';
export const REPAYMENT_DURATION_SET_VALUE = PERSONAL_LOANS_CALCULATOR_SET_VALUE + 'repaymentDuration';
export const REPAYMENT_DURATION_SET_VALID = PERSONAL_LOANS_CALCULATOR_SET_VALID + 'repaymentDuration';
export const REPAYMENT_DURATION_CLEAR_VALUE = PERSONAL_LOANS_CALCULATOR_CLEAR_VALUE + 'repaymentDuration';
export const OTHER_REPAYMENT_DURATION_SET_VALUE = PERSONAL_LOANS_CALCULATOR_SET_VALUE + 'otherRepaymentDuration';
export const OTHER_REPAYMENT_DURATION_SET_VALID = PERSONAL_LOANS_CALCULATOR_SET_VALID + 'otherRepaymentDuration';
export const OTHER_REPAYMENT_DURATION_CLEAR_VALUE = PERSONAL_LOANS_CALCULATOR_CLEAR_VALUE + 'otherRepaymentDuration';

export const setAmountBorrowedValue = (value) => ({ type: AMOUNT_BORROWED_SET_VALUE, value });
export const setAmountBorrowedValid = (error) => ({ type: AMOUNT_BORROWED_SET_VALID, error });
export const setOtherAmountBorrowedValue = (value) => ({ type: OTHER_AMOUNT_BORROWED_SET_VALUE, value });
export const setOtherAmountBorrowedValid = (error) => ({ type: OTHER_AMOUNT_BORROWED_SET_VALID, error });
export const clearOtherAmountBorrowedValue = () => ({ type: OTHER_AMOUNT_BORROWED_CLEAR_VALUE });
export const setRepaymentDurationValue = (value) => ({ type: REPAYMENT_DURATION_SET_VALUE, value });
export const setRepaymentDurationValid = (error) => ({ type: REPAYMENT_DURATION_SET_VALID, error });
export const clearRepaymentDurationValue = () => ({ type: REPAYMENT_DURATION_CLEAR_VALUE });
export const setOtherRepaymentDurationValue = (value) => ({ type: OTHER_REPAYMENT_DURATION_SET_VALUE, value });
export const setOtherRepaymentDurationValid = (error) => ({ type: OTHER_REPAYMENT_DURATION_SET_VALID, error });
export const clearOtherRepaymentDurationValue = () => ({ type: OTHER_REPAYMENT_DURATION_CLEAR_VALUE });
