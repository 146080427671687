import {
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateAlphaString,
    validateFirstName,
    validateName,
    validateNumber,
    validateNumericString,
    validateSelection,
} from '@om/validation';
import { validationIsEqual } from '../status';
import { AGENT_CODE_SET_VALUE, AGENT_NAME_SET_VALUE, SET_INTERMEDIARY_TYPE_VALUE, setAgentCodeValid, setIntermediaryTypeValid, setNameValid } from '../../../actions/digitalSavings/agentModal';

let prevValidationAction = null;

export const agentValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case AGENT_NAME_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'Name',
                    required: true,
                });
                return setNameValid(error);

            case AGENT_CODE_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value, {
                    subject: 'Code',
                    required: true,
                });
                return setAgentCodeValid(error);

            case SET_INTERMEDIARY_TYPE_VALUE:
                error = validateFirstName(action.value, {
                    subject: 'Intermediary',
                    required: true,
                });
                return setIntermediaryTypeValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
