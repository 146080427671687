import { createSelector } from "reselect";
import { getDoctorsPiState } from './index';
import { FORM_STATUS } from "../../reducers/status";

export const getUploadDocuments = createSelector(
    getDoctorsPiState,
    state => state.documents
);

const makeGetUploadDocumentsField = fieldName => createSelector(getUploadDocuments, uploads => uploads[fieldName]);

export const getUploadDocumentsForm = makeGetUploadDocumentsField("form");
export const getDeclarationsFrom = makeGetUploadDocumentsField("statements");

export const getUploadDocumentsFormValid = createSelector(
    getUploadDocuments, 
    uploads => uploads.formStatus === FORM_STATUS.VALID,
)

export const getDeclarationsFormValid = createSelector(
    getUploadDocuments, 
    uploads => uploads.statementsStatus === FORM_STATUS.VALID,
)

export const getCheckDocuments = createSelector(
    getUploadDocuments, 
    uploads => uploads.statements,
)

const makeGetFieldForm = (fieldId) => createSelector(getUploadDocumentsForm, (form) => form[fieldId]);
const makeGetFieldStatement = (fieldId) => createSelector(getDeclarationsFrom, (form) => form[fieldId]);

export const getCurrentLicenseValue = makeGetFieldForm('currentLicense');
export const getResumeValue = makeGetFieldForm('resume');
export const getNationalIdValue = makeGetFieldForm('nationalId');
export const getAcademicCertificatesValue = makeGetFieldForm('academicCertificates');
export const getKraPinValue = makeGetFieldForm('kraPin');

export const getDecOneValue = makeGetFieldStatement('decOne');
export const getDecTwoValue = makeGetFieldStatement('decTwo');
export const getDecThreeValue = makeGetFieldStatement('decThree');
export const getDecFourValue = makeGetFieldStatement('decFour');
export const getDecFiveValue = makeGetFieldStatement('decFive');
export const getDecSixValue = makeGetFieldStatement('decSix');