import axios from 'axios';
import { 
    CALL_ME_BACK_SEND, 
    sendCallMeBackSuccess, 
    sendCallMeBackFailure 
} from  '../../../actions/easiplusFuneralPlan/callMeBack';
import { 
    getCallMeBackForm, 
    getCallMeBackType,
    getGtmContextUrl, 
    getGtmContextGaId, 
    getReplacementProduct, 
    getGenericProduct, 
    getQuoteProduct 
} from '../../../selectors/easiplusFuneralPlan/callMeBack'
import { CALL_ME_BACK_TYPES } from '../../../reducers/status';

const header = {
    'Content-Type': 'application/json',
};

export const callMeBackService = (store, next, action) => {
    const result = next(action);
    const bodyParams = createBodyParams(store);

    (async () => {
        store.dispatch(await sendToLeads(bodyParams) ? sendCallMeBackSuccess() : sendCallMeBackFailure());
    })();

    return result;
};

export const sendToLeads = async bodyParams => {
    try {
        const response = await axios.post('/om-api/call-me-back/leads', bodyParams, { headers: header });
        return response.status === 200;
    } catch (error) {
        return false;
    }
};

export const createBodyParams = (store) => {
    const callMeBackForm = getCallMeBackForm(store.getState());
    const callMeBackType = getCallMeBackType(store.getState());
    const pageUrl = getGtmContextUrl(store.getState());
    const gtmContextGaId = getGtmContextGaId(store.getState());
    let cmbProduct;

    if (callMeBackType === CALL_ME_BACK_TYPES.REPLACEMENT) {
        cmbProduct = getReplacementProduct(store.getState());
    } else if (callMeBackType === CALL_ME_BACK_TYPES.QUOTE) {
        cmbProduct = getQuoteProduct(store.getState());
    } else {
        cmbProduct = getGenericProduct(store.getState());
    }

    return {
        'pageURL': encodeURIComponent(pageUrl),
        'source': 'MyOldMutualPublicWebsite',
        'googleAnalyticsId': gtmContextGaId,
        'formData': {
            'name': callMeBackForm.name.value,
            'firstName': callMeBackForm.name.value,
            'surname': callMeBackForm.surname.value,
            'phoneNumber': callMeBackForm.contactNumber.value,
            'sourceProduct': cmbProduct,
        },
    };
};
