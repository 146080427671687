// Transitions
export const FORM_VALID_TRANSITION_SOLUTIONS = 'atRetirement/solutions/transition/formValid';
export const FORM_INVALID_TRANSITION_SOLUTIONS = 'atRetirement/solutions/transition/formInvalid';

export const GUARANTEED_DRAWER_OPEN_SOLUTIONS = 'atRetirement/solutions/transition/guaranteedDrawerOpen';
export const GUARANTEED_DRAWER_CLOSED_SOLUTIONS = 'atRetirement/solutions/transition/guaranteedDrawerClosed';

export const LIVING_DRAWER_OPEN_SOLUTIONS = 'atRetirement/solutions/transition/livingDrawerOpen';
export const LIVING_DRAWER_CLOSED_SOLUTIONS = 'atRetirement/solutions/transition/livingDrawerClosed';

export const COMPOSITE_DRAWER_OPEN_SOLUTIONS = 'atRetirement/solutions/transition/compositeDrawerOpen';
export const COMPOSITE_DRAWER_CLOSED_SOLUTIONS = 'atRetirement/solutions/transition/compositeDrawerClosed';

export const SOLUTIONS_SET_VALUE = 'atRetirement/solutions/setValue/';
export const SOLUTIONS_SET_VALID = 'atRetirement/solutions/setValid/';

export const SOLUTIONS_GUARANTEED_ANNUITY_CHOSEN = SOLUTIONS_SET_VALUE + 'guaranteedAnuityChosen';
export const SOLUTIONS_LIVING_ANNUITY_CHOSEN = SOLUTIONS_SET_VALUE + 'livingAnnuityChosen';
export const SOLUTIONS_COMPOSITE_ANNUITY_CHOSEN = SOLUTIONS_SET_VALUE + 'compositeAnnuityChosen';

// solutions page rendering action
export const SOLUTIONS_PAGE_RENDER_TRACKING_VALUE = 'atRetirement/solutionsPage/transition/renderTracking';
export const setSolutionsPageRenderTracking = () => ({ type: SOLUTIONS_PAGE_RENDER_TRACKING_VALUE });

export const transitionSolutions = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_SOLUTIONS}),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_SOLUTIONS}),
};

export const setGauranteedChosen = value => ({ type: SOLUTIONS_GUARANTEED_ANNUITY_CHOSEN, value });
export const setLivingChosen = value => ({ type: SOLUTIONS_LIVING_ANNUITY_CHOSEN, value });
export const setCompositeChosen = value => ({ type: SOLUTIONS_COMPOSITE_ANNUITY_CHOSEN, value });

export const setGuaranteedDrawerOpen = () => ({type: GUARANTEED_DRAWER_OPEN_SOLUTIONS });
export const setGuaranteedDrawerClosed = () => ({ type: GUARANTEED_DRAWER_CLOSED_SOLUTIONS });

export const setLivingDrawerOpen = () => ({ type: LIVING_DRAWER_OPEN_SOLUTIONS });
export const setLivingDrawerClosed = () => ({ type: LIVING_DRAWER_CLOSED_SOLUTIONS });

export const setCompositeDrawerOpen = () => ({ type: COMPOSITE_DRAWER_OPEN_SOLUTIONS });
export const setCompositeDrawerClosed = () => ({ type: COMPOSITE_DRAWER_CLOSED_SOLUTIONS });