import { createSelector } from "reselect";
import { getRoaQuoteTools } from './index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../reducers/status";

export const getAfyaImaraJuniorQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.afyaImaraJuniorQuoteTool
);

export const getAfyaImaraJuniorQuoteToolForm = createSelector(
    getAfyaImaraJuniorQuoteTool,
    afyaImaraJuniorQuoteTool => afyaImaraJuniorQuoteTool.form
);

const makeGetFieldForm = fieldId => createSelector(
    getAfyaImaraJuniorQuoteToolForm,
    form => form[fieldId]
);

export const getAfyaImaraJuniorQuoteToolFormValid = createSelector(
    getAfyaImaraJuniorQuoteTool,
    afyaImaraJuniorQuoteTool => afyaImaraJuniorQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getSelectedForm = createSelector(
    getAfyaImaraJuniorQuoteTool,
    afyaImaraJuniorQuoteTool => afyaImaraJuniorQuoteTool.selectedForm
)

export const getRoaCmbObject = createSelector(
    getAfyaImaraJuniorQuoteTool,
    afyaImaraJuniorQuoteTool => afyaImaraJuniorQuoteTool.roaCmbObject
)

export const getAfyaImaraPremiumPaymentAmount = createSelector(
    getAfyaImaraJuniorQuoteTool,
    afyaImaraJuniorQuoteTool => afyaImaraJuniorQuoteTool.premiumPaymentaAmount
);

export const getShowError = createSelector(
    getAfyaImaraJuniorQuoteTool,
    afyaImaraJuniorQuoteTool => afyaImaraJuniorQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getAfyaImaraJuniorQuoteTool,
    afyaImaraJuniorQuoteTool => afyaImaraJuniorQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getNoOfChildren = makeGetFieldForm('noOfChildren');
export const getMedicalProvidersScope = makeGetFieldForm('medicalProvidersScope');
export const getInpatientCoverLimit = makeGetFieldForm('inpatientCoverLimit');
export const getOutpatientCoverLimit = makeGetFieldForm('outpatientCoverLimit');
