import { transitionMotorInsuranceCarDetailsForm } from '../../../actions/ugMotorInsurance/carDetails';
import { getCarDetailsForm } from '../../../selectors/ugMotorInsurance/carDetails';

import { FIELD_STATUS } from '../../../reducers/status';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const carDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const carDetailsForm = getCarDetailsForm(store.getState());
    const carDetailsFormValid = validateCarDetailsFormDetails(carDetailsForm);
    const formAction = carDetailsFormValid
        ? transitionMotorInsuranceCarDetailsForm.formValid()
        : transitionMotorInsuranceCarDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateCarDetailsFormDetails = (formFields) => {
    return (
        formFields.registrationNumber &&
        formFields.registrationNumber.status === FIELD_STATUS.VALID &&
        formFields.chasisNumber &&
        formFields.chasisNumber.status === FIELD_STATUS.VALID &&
        formFields.bodyType &&
        formFields.bodyType.status === FIELD_STATUS.VALID &&
        formFields.sittingCapacity &&
        formFields.sittingCapacity.status === FIELD_STATUS.VALID &&
        formFields.color &&
        formFields.color.status === FIELD_STATUS.VALID &&
        formFields.yearsWithLicense &&
        formFields.yearsWithLicense.status === FIELD_STATUS.VALID &&
        formFields.fuel &&
        formFields.fuel.status === FIELD_STATUS.VALID &&
        formFields.engineNumber &&
        formFields.engineNumber.status === FIELD_STATUS.VALID
    );
};
