import {FIELD_STATUS, TOGGLE_STATUS } from "../status";
import { ACCOUNT_VALIDATION_STATUS } from "../../actions/easiplusFuneralPlan/types";

import {
    BANK_NAME_SET_VALID,
    BANK_NAME_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALID,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_TYPE_SET_VALID,
    ACCOUNT_TYPE_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALID,
    MONTH_SET_VALID,
    MONTH_SET_VALUE,
    MONTH_TO_SKIP_SET_VALID,
    MONTH_TO_SKIP_SET_VALUE,
    MONTH_TO_SKIP_RESET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    PAYMENT_AUTHORIZATION_SET_VALID,
    TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS,
    LUMP_SUM_DAY_SET_VALUE,
    LUMP_SUM_DAY_SET_VALID,
    LUMP_SUM_MONTH_SET_VALID,
    LUMP_SUM_MONTH_SET_VALUE,
    SET_DISPLAY_LEGAL_SECTION,
    SOURCE_OF_INCOME_SET_VALUE,
    SOURCE_OF_INCOME_SET_VALID,
    SOURCE_OF_FUNDS_SET_VALID,
    SOURCE_OF_FUNDS_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE,
    EMPLOYMENT_INDUSTRY_SET_VALID,
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    EMPLOYMENT_POSITION_SET_VALID,
    EMPLOYMENT_POSITION_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALID,
    SOURCE_OF_INCOME_OTHER_SET_VALID,
    SOURCE_OF_INCOME_OTHER_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_RESET_VALUE,
    EMPLOYMENT_INDUSTRY_RESET_VALUE,
    SOURCE_OF_INCOME_OTHER_RESET_VALUE,
    TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS,
    TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS,
    IDENTITY_DOCUMENT_SET_VALUE,
    IDENTITY_DOCUMENT_SET_VALID,
    IDENTITY_DOCUMENT_REMOVE_VALUE,
    BIRTH_CERTIFICATE_SET_VALUE,
    BIRTH_CERTIFICATE_SET_VALID,
    BIRTH_CERTIFICATE_REMOVE_VALUE,
    COURT_MANDATE_SET_VALUE,
    COURT_MANDATE_SET_VALID,
    COURT_MANDATE_REMOVE_VALUE,
    RESET_PAYMENT_DETAILS
} from "../../actions/taxFreeSavingsAccount/paymentDetails";

export const initPaymentDetails = {
    accountValidationStatus: ACCOUNT_VALIDATION_STATUS.IDLE,
    skipMonth: TOGGLE_STATUS.NO,
    displayLegalSection: TOGGLE_STATUS.NO,
    includeAvs: false,
    includeCdv: false,
    uploadedFiles: {
        identityDocument: { value: null, error: null, status: null },
        birthCertificate: { value: null, error: null, status: null },
        courtMandate: { value: null, error: null, status: null },
    },
    form: {
        bankName: { value: null, error: null, status: null },
        accountNumber: { value: null, error: null, status: null },
        accountType: { value: null, error: null, status: null },
        debitOrderDay: { value: null, error: null, status: null },
        month: { value: null, error: null, status: null },
        monthToSkip: { value: null, error: null, status: null },
        paymentAuthorization: { value: TOGGLE_STATUS.UNTOUCHED, error: null, status: null },
        lumpSumDay: { value: null, error: null, status: null },
        lumpSumMonth: { value: null, error: null, status: null },

        sourceOfIncome: { value: null, error: null, status: null },
        sourceOfIncomeOther: { value: null, error: null, status: null },
        sourceOfFunds: { value: null, error: null, status: null },
        employmentPosition: { value: null, error: null, status: null },
        employmentIndustry: { value: null, error: null, status: null },
        employmentIndustryOther: { value: null, error: null, status: null },
    },
};

export default (state = initPaymentDetails, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case RESET_PAYMENT_DETAILS:
            return { ...initPaymentDetails };

        case TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS:
            return updateFormField('paymentAuthorization', field => ({
                ...field,
                value: state.form.paymentAuthorization.value !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO,
                status: null,
                error: null
            }));
        case PAYMENT_AUTHORIZATION_SET_VALID:
            return updateFormField('paymentAuthorization', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS:
            return { ...state, accountValidationStatus: action.value }

        case ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS:
            return {
                ...state,
                accountValidationStatus: ACCOUNT_VALIDATION_STATUS.IDLE,
                form: {
                    ...state.form,
                    paymentAuthorization: initPaymentDetails.form.paymentAuthorization
                }
            }

        case TOGGLE_SKIP_MONTH_TRANSITION_PAYMENT_DETAILS:
            return { ...state, skipMonth: state.skipMonth !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO }

        // If this is toggled in content stack this action is fired on mount
        case TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS:
            return { ...state, includeAvs: true }
        case TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS:
            return { ...state, includeCdv: true }

        case BANK_NAME_SET_VALUE:
            return updateFormField('bankName', field => ({
                ...field,
                value: action.value,
            }));
        case BANK_NAME_SET_VALID:
            return updateFormField('bankName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case ACCOUNT_NUMBER_SET_VALUE:
            return updateFormField('accountNumber', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_NUMBER_SET_VALID:
            return updateFormField('accountNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case ACCOUNT_TYPE_SET_VALUE:
            return updateFormField('accountType', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_TYPE_SET_VALID:
            return updateFormField('accountType', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DEBIT_ORDER_DAY_SET_VALUE:
            return updateFormField('debitOrderDay', field => ({
                ...field,
                value: action.value,
            }));
        case DEBIT_ORDER_DAY_SET_VALID:
            return updateFormField('debitOrderDay', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MONTH_SET_VALUE:
            return updateFormField('month', field => ({
                ...field,
                value: action.value,
            }));
        case MONTH_SET_VALID:
            return updateFormField('month', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MONTH_TO_SKIP_SET_VALUE:
            return updateFormField('monthToSkip', field => ({
                ...field,
                value: action.value,
            }));
        case MONTH_TO_SKIP_SET_VALID:
            return updateFormField('monthToSkip', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case MONTH_TO_SKIP_RESET_VALUE:
            return updateFormField('monthToSkip', field => ({
                ...field,
                error: null,
                status: null,
                value: null
            }));
        case LUMP_SUM_DAY_SET_VALUE:
            return updateFormField('lumpSumDay', field => ({
                ...field,
                value: action.value,
            }));
        case LUMP_SUM_DAY_SET_VALID:
            return updateFormField('lumpSumDay', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case LUMP_SUM_MONTH_SET_VALUE:
            return updateFormField('lumpSumMonth', field => ({
                ...field,
                value: action.value,
            }));
        case LUMP_SUM_MONTH_SET_VALID:
            return updateFormField('lumpSumMonth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));


        case SOURCE_OF_INCOME_SET_VALUE:
            return updateFormField('sourceOfIncome', field => ({
                ...field,
                value: action.value,
            }));
        case SOURCE_OF_INCOME_SET_VALID:
            return updateFormField('sourceOfIncome', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SOURCE_OF_INCOME_OTHER_SET_VALUE:
            return updateFormField('sourceOfIncomeOther', field => ({
                ...field,
                value: action.value,
            }));
        case SOURCE_OF_INCOME_OTHER_SET_VALID:
            return updateFormField('sourceOfIncomeOther', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SOURCE_OF_INCOME_OTHER_RESET_VALUE:
            return updateFormField('sourceOfIncomeOther', field => ({
                ...field,
                error: null,
                status: null,
                value: null
            }));
        case SOURCE_OF_FUNDS_SET_VALUE:
            return updateFormField('sourceOfFunds', field => ({
                ...field,
                value: action.value,
            }));
        case SOURCE_OF_FUNDS_SET_VALID:
            return updateFormField('sourceOfFunds', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMPLOYMENT_POSITION_SET_VALUE:
            return updateFormField('employmentPosition', field => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYMENT_POSITION_SET_VALID:
            return updateFormField('employmentPosition', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMPLOYMENT_INDUSTRY_SET_VALUE:
            return updateFormField('employmentIndustry', field => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYMENT_INDUSTRY_SET_VALID:
            return updateFormField('employmentIndustry', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMPLOYMENT_INDUSTRY_RESET_VALUE:
            return updateFormField('employmentIndustry', field => ({
                ...field,
                error: null,
                status: null,
                value: null
            }));
        case EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE:
            return updateFormField('employmentIndustryOther', field => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYMENT_INDUSTRY_OTHER_SET_VALID:
            return updateFormField('employmentIndustryOther', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMPLOYMENT_INDUSTRY_OTHER_RESET_VALUE:
            return updateFormField('employmentIndustryOther', field => ({
                ...field,
                error: null,
                status: null,
                value: null
            }));

        case SET_DISPLAY_LEGAL_SECTION:
            return { ...state, displayLegalSection: TOGGLE_STATUS.YES }

        case IDENTITY_DOCUMENT_SET_VALUE:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    identityDocument: {
                        ...state.uploadedFiles.identityDocument,
                        value: action.value,
                    },
                }
            };

        case BIRTH_CERTIFICATE_SET_VALUE:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    birthCertificate: {
                        ...state.uploadedFiles.birthCertificate,
                        value: action.value,
                    },
                }
            };

        case COURT_MANDATE_SET_VALUE:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    courtMandate: {
                        ...state.uploadedFiles.courtMandate,
                        value: action.value,
                    },
                }
            };

        case IDENTITY_DOCUMENT_SET_VALID:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    identityDocument: {
                        ...state.uploadedFiles.identityDocument,
                        error: action.error,
                        status: mapError(action.error)
                    },
                }
            };

        case BIRTH_CERTIFICATE_SET_VALID:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    birthCertificate: {
                        ...state.uploadedFiles.birthCertificate,
                        error: action.error,
                        status: mapError(action.error)
                    },
                }
            };

        case COURT_MANDATE_SET_VALID:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    courtMandate: {
                        ...state.uploadedFiles.courtMandate,
                        error: action.error,
                        status: mapError(action.error)
                    },
                }
            };

        case IDENTITY_DOCUMENT_REMOVE_VALUE:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    identityDocument: {
                        ...initPaymentDetails.uploadedFiles.identityDocument
                    },
                }
            };

        case BIRTH_CERTIFICATE_REMOVE_VALUE:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    birthCertificate: {
                        ...initPaymentDetails.uploadedFiles.birthCertificate
                    },
                }
            };

        case COURT_MANDATE_REMOVE_VALUE:
            return {
                ...state,
                uploadedFiles: {
                    ...state.uploadedFiles,
                    courtMandate: {
                        ...initPaymentDetails.uploadedFiles.courtMandate
                    },
                }
            };

        default:
            return state;
    }
};
