import { createSelector } from 'reselect';
import { getInvestmentCalculators } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';
import { formatCurrency } from '../currency';

export const getLongTermGoalCalculator = createSelector(
	getInvestmentCalculators,
	(investmentCalculators) => investmentCalculators.longTermGoalCalculator
);

export const getLongTermGoalSplit = createSelector(
	getInvestmentCalculators,
	(investmentCalculators) => investmentCalculators.longTermGoalFundSplit
);

export const getLongTermGoalCalculatorForm = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) => longTermGoalCalculator.form
);

export const getLongTermGoalCalculatorFormValid = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) =>
		longTermGoalCalculator.formStatus === FORM_STATUS.VALID
);

const makeGetField = (fieldId) =>
	createSelector(getLongTermGoalCalculatorForm, (form) => form[fieldId]);

export const getAmountOnceOffValue = makeGetField('amountOnceOff');
export const getMonthlyAmountValue = makeGetField('amountMonthly');
export const getInvestmentTenureValue = makeGetField('investmentTenure');
export const getPotentialLossPercentageValue = makeGetField(
	'potentialLossPercentage'
);

export const getLongTermGoalResults = createSelector(
	getLongTermGoalCalculatorForm,
	(longTermGoalCalculator) => longTermGoalCalculator.calculationResults
);

export const getAmountNeededToBeSaved = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) =>
		formatCurrency(longTermGoalCalculator.amountNeededToBeSaved, 'KES')
);

export const getShowResult = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) =>
		longTermGoalCalculator.apiCallStatus === API_CALL_STATUS.SUCCESS
);

export const getShowError = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) =>
		longTermGoalCalculator.apiCallStatus === API_CALL_STATUS.FAILURE
);

export const getIsApiPending = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) =>
		longTermGoalCalculator.apiCallStatus === API_CALL_STATUS.PENDING
);

export const getBtnText = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) => {
		switch (longTermGoalCalculator.apiCallStatus) {
			case API_CALL_STATUS.SUCCESS:
				return 'RECALCULATE';
			case API_CALL_STATUS.FAILURE:
				return 'TRY AGAIN';
			case API_CALL_STATUS.PENDING:
				return 'CALCULATING...';
			default:
				return 'CALCULATE';
		}
	}
);

export const getHasCalculateBtnIcon = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) => {
		return longTermGoalCalculator.apiCallStatus !== API_CALL_STATUS.PENDING;
	}
);

export const getCalculateDisabled = createSelector(
	[getLongTermGoalCalculator, getLongTermGoalSplit],
	(longTermGoalCalculator, longTermGoalSplit) => {
		return longTermGoalCalculator.formStatus === FORM_STATUS.INVALID ||
			longTermGoalCalculator.apiCallStatus === API_CALL_STATUS.PENDING ||
            longTermGoalSplit.formStatus === FORM_STATUS.INVALID;
	}
);

export const getInputDisabled = createSelector(
	getLongTermGoalCalculator,
	(longTermGoalCalculator) => {
		return longTermGoalCalculator.apiCallStatus === API_CALL_STATUS.PENDING;
	}
);