// financial advice page rendering action
export const FINANCIAL_ADVICE_RENDER_TRACKING_VALUE = 'atRetirement/financialAdvice/transition/renderTracking';
export const setFinancialAdviceRenderTracking = () => ({ type: FINANCIAL_ADVICE_RENDER_TRACKING_VALUE });

// Transitions
export const SHOW_REWARDS_SECTION_FIN_ADVICE = 'atRetirement/financialAdvice/transition/showRewardsSection';
export const HIDE_REWARDS_SECTION_FIN_ADVICE = 'atRetirement/financialAdvice/transition/hideRewardsSection';
export const FORM_VALID_TRANSITION_FIN_ADVICE = 'atRetirement/financialAdvice/transition/formValid';
export const FORM_INVALID_TRANSITION_FIN_ADVICE = 'atRetirement/financialAdvice/transition/formInvalid';

export const transitionFinancialAdvice = {
    showRewardsSection: () => ({ type: SHOW_REWARDS_SECTION_FIN_ADVICE }),
    hideRewardsSection: () => ({ type: HIDE_REWARDS_SECTION_FIN_ADVICE }),
    formValid: () => ({ type: FORM_VALID_TRANSITION_FIN_ADVICE }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_FIN_ADVICE }),
};

// Events - fields
export const FIN_ADVICE_SET_VALUE = 'atRetirement/financialAdvice/setValue/';
export const FIN_ADVICE_SET_VALID = 'atRetirement/financialAdvice/setValid/';

export const SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALUE = FIN_ADVICE_SET_VALUE + 'shortDescriptionFindOutMore';
export const SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALID = FIN_ADVICE_SET_VALID + 'shortDescriptionFindOutMore';

export const CONTACT_NUMBER_ADVISOR_SET_VALUE = FIN_ADVICE_SET_VALUE + 'contactNumberAdvisor';
export const CONTACT_NUMBER_ADVISOR_SET_VALID = FIN_ADVICE_SET_VALID + 'contactNumberAdvisor';

export const CLIENT_EMAIL_SET_VALUE = FIN_ADVICE_SET_VALUE + 'clientEmail';
export const CLIENT_EMAIL_SET_VALID = FIN_ADVICE_SET_VALID + 'clientEmail';

export const REASONS_SET_VALUE = FIN_ADVICE_SET_VALUE + 'reasons';
export const REASONS_SET_VALID = FIN_ADVICE_SET_VALID + 'reasons';

export const REWARDS_CLICKED_SET_VALUE = FIN_ADVICE_SET_VALUE + "rewardsClicked";

export const setShortDescriptionFindOutMoreValue = value => ({ type: SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALUE, value });
export const setShortDescriptionFindOutMoreValid = error => ({ type: SHORT_DESCRIPTION_FIND_OUT_MORE_SET_VALID, error });

export const setContactNumberAdvisorValue = value => ({ type: CONTACT_NUMBER_ADVISOR_SET_VALUE, value });
export const setContactNumberAdvisorValid = error => ({ type: CONTACT_NUMBER_ADVISOR_SET_VALID, error });

export const setClientEmailValue = value => ({ type: CLIENT_EMAIL_SET_VALUE, value });
export const setClientEmailValid = error => ({ type: CLIENT_EMAIL_SET_VALID, error });

export const setReasonsValue = value => ({ type: REASONS_SET_VALUE, value });
export const setReasonsValid = error => ({ type: REASONS_SET_VALID, error });

// Events - send
export const FIN_ADVICE_SEND = 'atRetirement/financialAdvice/email/';

export const FIND_OUT_MORE_SEND = FIN_ADVICE_SEND + 'findOutMore/send';
export const FIND_OUT_MORE_SEND_SUCCESS = FIN_ADVICE_SEND + 'findOutMore/success';
export const FIND_OUT_MORE_SEND_FAILURE = FIN_ADVICE_SEND + 'findOutMore/failure';

export const SPEAK_TO_ADVISOR_SEND = FIN_ADVICE_SEND + 'speakToAdvisor/send';
export const SPEAK_TO_ADVISOR_SEND_SUCCESS = FIN_ADVICE_SEND + 'speakToAdvisor/success';
export const SPEAK_TO_ADVISOR_SEND_FAILURE = FIN_ADVICE_SEND + 'speakToAdvisor/failure';

// Events - button clicked
export const sendFindOutMore = () => ({ type: FIND_OUT_MORE_SEND });
export const sendFindOutMoreSuccess = value => ({ type: FIND_OUT_MORE_SEND_SUCCESS, value });
export const sendFindOutMoreFailure = error => ({ type: FIND_OUT_MORE_SEND_FAILURE, error });

export const sendSpeakToAdvisor = () => ({ type: SPEAK_TO_ADVISOR_SEND });
export const sendSpeakToAdvisorSuccess = value => ({ type: SPEAK_TO_ADVISOR_SEND_SUCCESS, value });
export const sendSpeakToAdvisorFailure = error => ({ type: SPEAK_TO_ADVISOR_SEND_FAILURE, error });

export const setRewardsClicked = () => ({type: REWARDS_CLICKED_SET_VALUE});


