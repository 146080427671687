import axios from 'axios';
import {
    getAccidentalQuoteToolForm,
    getIsApiPending,
} from '../../../../../selectors/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool';
import {
    apiTransitionAccidentalDeathCoverQuoteTool,
    calculateAccidentLDeathCoverSuccess,
    setWhichFormToDisplay,
} from '../../../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool';

export const accidentalDeathCoverQuoteToolService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(
                apiTransitionAccidentalDeathCoverQuoteTool.pending()
            );
            try {
                const resp = await calculate(store.getState());
                store.dispatch(
                    calculateAccidentLDeathCoverSuccess(resp.data)
                );
                store.dispatch(
                    apiTransitionAccidentalDeathCoverQuoteTool.success()
                );
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                // TODO add logic for calculate value failure
				console.error(e)
                store.dispatch(
                    apiTransitionAccidentalDeathCoverQuoteTool.failure()
                );
            }
        }
    })();
    return next(action);
};

const accidentalDeathCoverPayload = (formData) => {
	const disability = formData.disabilityCoveredValue.value === 'Yes' ? formData.numberOfYearsCovered.value : ''
    return {
        dob: formData.candidateDOB.value,
        gender: formData.genderValue.value,
        txt_acc_death_dis_rider: disability,
		txt_acc_death: formData.coverAmount.value,
		txt_acc_death_term: formData.numberOfYearsCovered.value,
        smoke_status: formData.smokerCoveredValue.value === 'Yes' ? 'S' : 'NS',
    };
};

const calculate = async (state) => {
    const formData = getAccidentalQuoteToolForm(state);
    try {
        const url = '/om-api/roa-quote-tools/accidental-death-cover-quote';
        return await axios.post(url, accidentalDeathCoverPayload(formData));
    } catch (e) {
        console.error(e);
        return null;
    }
};
