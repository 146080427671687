import {createSelector} from "reselect";
import {getAtRetirement} from "./index";

export const getIntro = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.intro
)

export const getReferrerId = createSelector(
    getIntro,
    intro => intro.referrerId
)
