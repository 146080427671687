import { validationIsEqual } from '../status';

import {
    validateFirstName,
    validateSurname,
    validateIdNumber,
    validateDate,
    validateNumericString,
    validateEmail,
    validateSingleSelection,
    validateName,
    validateContactNumber,
    OmValidationMessageHelper,
} from '@om/validation';

import {
    FUNERAL_CLAIM_SET_FIRST_NAME_VALUE,
    setFirstNameValid,
    FUNERAL_CLAIM_SET_SURNAME_VALUE,
    setSurameValid,
    FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE,
    setCellphoneNumberValid,
    FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE,
    setRequesterIdValid,
    setDeceasedRequesterIdValid,
    FUNERAL_CLAIM_SET_DECEASED_ID_VALUE,
    setDeceasedIdValid,
    FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE,
    setFuneralDateValid,
    FUNERAL_CLAIM_SET_BANK_OTHER_VALUE,
    setBankOtherValid,
    FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE,
    setBranchCodeValid,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE,
    setBankAccountNumberValid,
    FUNERAL_CLAIM_SET_EMAIL_VALUE,
    setEmailValid,
    FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE,
    setDateOfDeathValid,
    FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE,
    setCauseOfDeathValid,
    FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE,
    setReasonForUnnaturalDeathValid,
    FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE,
    setReasonForDeathValid,
    FUNERAL_CLAIM_SET_BANK_VALUE,
    setBankValid,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE,
    setBankAccountTypeValid,
    setDateOfDeathAndFuneralValid,
    FUNERAL_CLAIM_SET_IS_STILLBORN_VALUE,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALUE,
    setBankAccountHolderIdNumberValid,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALUE,
    setBankAccountHolderNameValid,
    FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALUE,
    setNoticeOfDeathDocumentValid,
    FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALUE,
    setProofOfBankDocumentValid,
    FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALUE,
    setRemoveNoticeOfDeathDocumentValid,
    FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALUE,
    setRemoveProofOfBankDocumentValid,
} from '../../../actions/publicWeb/funeralClaimV2';

import {
    getRequesterIdNumber,
    getDeceasedIdNumber,
    getIsStillborn,
    getStillbornDateOfBirth,
    getShowReasonForUnnaturalDeath,
    getDateOfDeath,
    getFuneralDate,
    getShowBankAccountHoldersFields,
    getBankAccountHolderIdNumber,
} from '../../../selectors/publicWeb/funeralClaimV2';

const ID_MATCH_ERROR =
    'The requester and deceased ID numbers cannot be the same.';

const STILLBORN_DATE_OF_BIRTH_DEATH_ERROR =
    'Date of death must match date of birth for stillborn.';

const ID_MATCH_DECEASED_ACCOUNT_HOLDER_ERROR = 'The deceased and account holder ID cannot be the same.';
const ID_MATCH_REQUESTER_ACCOUNT_HOLDER_ERROR = 'The requester and account holder ID cannot be the same.';


function validateMatchingIdNumbers(requesterIdNumber, deceasedIdNumber) {
    if (requesterIdNumber === deceasedIdNumber && requesterIdNumber !== '') {
        return ID_MATCH_ERROR;
    }

    return null;
}

function validateDeceasedAccountHolderIdsMatching(Id = null, accountHolderIdNumber) {
    if (Id === accountHolderIdNumber) {
        return  ID_MATCH_DECEASED_ACCOUNT_HOLDER_ERROR;
    }

    return null;
}
function validateRequesterAccountHolderIdsMatching(Id = null, accountHolderIdNumber) {
    if (Id === accountHolderIdNumber) {
        return  ID_MATCH_REQUESTER_ACCOUNT_HOLDER_ERROR;
    }

    return null;
}

function validateMatchingDateOfBirthAndDeath(dateOfBirth, dateOfDeath) {
    if (dateOfBirth !== dateOfDeath) {
        return STILLBORN_DATE_OF_BIRTH_DEATH_ERROR;
    }
    return null;
}

function validateReasonForUnnaturalDeath(reasonForUnnaturalDeath) {
    if (
        getShowReasonForUnnaturalDeath &&
        (!reasonForUnnaturalDeath || reasonForUnnaturalDeath.trim() === '')
    ) {
        return 'Please select an option as specified on the Notice of Death form.';
    }
    return null;
}

function validateReasonForDeath(reasonForDeath) {
    const value =
        reasonForDeath &&
        typeof reasonForDeath === 'string' &&
        reasonForDeath.trim();
    if (!value) {
        return null;
    } else if (
        value.length < 3 ||
        value.toLowerCase() === 'not applicable' ||
        value.toLowerCase() === 'n/a'
    ) {
        return 'Please enter a valid reason as per the Notice of Death form.';
    }
    return null;
}

function validateDateFormatDDMM(date) {
    const parts = date.split('/');

    if (parts[0].length < 2 || parts[1].length < 2) {
        return OmValidationMessageHelper.DATE_FORMAT_ERROR;
    }

    return null;
}

function validateFuneralDate(state, value) {
    let error;
    if (getDateOfDeath(state).value) {
        error = validateDate(value, {
            min: getDateOfDeath(state).value,
            required: false,
        });
    } else {
        error = validateDate(value, {
            required: false,
        });
    }
    
    if (!error && notNullOrEmpty(value)) {
        error = validateDateFormatDDMM(value);
    }
    
    return error;
}

function validateFileType(value) {
    const allowedFiles = ["pdf", "png", "jpg", "jpeg"];
    if (value) {
        if (value.type !== null && value.type.trim() !== '') {
            const regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$");
            if (!regex.test(value.type.toLowerCase())) {
                return 'Please choose the correct file type';
            }
        } else {
            return 'Please choose the correct file type';
        }
    }

    return null;
}

function validateFileSize(file) {
    if (file) {
        const fileSize = Math.round((file.size / 1024));
        if (fileSize > 10 * 1024) {
            return 'File must be less than 10MB';
        }
    }

    return null;
}

function notNullOrEmpty(val) {
    return val && val.trim() !== '';
}

function getCurrentDate() {
    var currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const formattedDate = `${currentDay}/${currentMonth}/${currentYear}`;
    return formattedDate;
}

let prevValidationAction = null;
export const funeralClaimV2Validation = (store, next, action) => {
    const state = store.getState();

    const validationAction = (() => {
        let error,
            deceasedIdNumber,
            deceasedIdNumberValue,
            requesterIdNumber,
            requesterIdNumberValue,
            isValidateConditionalAccountHolderFields;
            
        switch (action.type) {
            case FUNERAL_CLAIM_SET_FIRST_NAME_VALUE:
                error = validateFirstName(action.value);
                return setFirstNameValid(error);

            case FUNERAL_CLAIM_SET_SURNAME_VALUE:
                error = validateSurname(action.value);
                return setSurameValid(error);

            case FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE:
                error = validateIdNumber(action.value);
                deceasedIdNumber = getDeceasedIdNumber(state);
                deceasedIdNumberValue = deceasedIdNumber.value;

                if (!error) {
                    requesterIdNumber = action.value;
                    error = validateMatchingIdNumbers(
                        requesterIdNumber,
                        deceasedIdNumberValue
                    );
                    // If a duplicate error is picked up which has already been flagged ignore
                    if (error && deceasedIdNumber.error === ID_MATCH_ERROR) {
                        return setRequesterIdValid(null);
                    }
                    // If there is no duplicate and 2nd value is dirty revalidate it
                    if (!error && notNullOrEmpty(deceasedIdNumberValue)) {
                        const deceasedError = validateIdNumber(
                            deceasedIdNumberValue
                        );
                        return setDeceasedRequesterIdValid(deceasedError, null);
                    }

                }

                // 2. Test deceased Id with accountHolder Id
                isValidateConditionalAccountHolderFields = getShowBankAccountHoldersFields(state);
                if (!error && isValidateConditionalAccountHolderFields) {
                    let bankAcccountHolderId = getBankAccountHolderIdNumber(state);
                    error = validateRequesterAccountHolderIdsMatching(
                        action.value,
                        bankAcccountHolderId.value,
                    );

                    if (error && bankAcccountHolderId.error === ID_MATCH_DECEASED_ACCOUNT_HOLDER_ERROR) {
                        return setRequesterIdValid(null);
                    }
                }

                return setRequesterIdValid(error);

            case FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Cellphone Number',
                    countryCode: action.countryCode,
                });
                return setCellphoneNumberValid(error);

            case FUNERAL_CLAIM_SET_EMAIL_VALUE:
                error = validateEmail(action.value, {
                    required: false,
                });
                return setEmailValid(error);

            // No validation required on is stillborn since it toggles true/false
            // Funeral date requires validation since toggling stillborn adds/removes date of death
            case FUNERAL_CLAIM_SET_IS_STILLBORN_VALUE:
                error = validateFuneralDate(state, getFuneralDate(state).value);
                return setFuneralDateValid(error);

            case FUNERAL_CLAIM_SET_DATE_OF_DEATH_VALUE:
                const formattedDate = getCurrentDate();
                error = validateDate(action.value, {
                    required: getIsStillborn(state),
                    max: formattedDate,
                });
                
                if (!error) {
                    error = validateDateFormatDDMM(action.value);
                }

                // check if funeral date has been completed and re-evaluate
                const funeralDate = getFuneralDate(state);
                if (!error && notNullOrEmpty(funeralDate.value)) {
                    const funeralDateError = validateDate(funeralDate.value, {
                        min: action.value,
                        required: false,
                    });
                    return setDateOfDeathAndFuneralValid(
                        error,
                        funeralDateError
                    );
                }

                return setDateOfDeathValid(error);

            case FUNERAL_CLAIM_SET_DECEASED_ID_VALUE:
                error = validateIdNumber(action.value, {
                    required: !getIsStillborn(state),
                });
                requesterIdNumber = getRequesterIdNumber(state);
                requesterIdNumberValue = requesterIdNumber.value;
                if (!error) {
                    deceasedIdNumber = action.value;
                    error = validateMatchingIdNumbers(
                        requesterIdNumberValue,
                        deceasedIdNumber
                    );
                    // If a duplicate error is picked up which has already been flagged ignore
                    if (error && requesterIdNumber.error === ID_MATCH_ERROR) {
                        return setDeceasedIdValid(null);
                    }
                    // If there is no duplicate and 2nd value is dirty revalidate it
                    if (!error && notNullOrEmpty(requesterIdNumberValue)) {
                        const reqesterError = validateIdNumber(
                            requesterIdNumberValue
                        );
                        return setDeceasedRequesterIdValid(null, reqesterError);
                    }
                }

                // 2. Test account holder ID
                isValidateConditionalAccountHolderFields = getShowBankAccountHoldersFields(state);
                if (!error && isValidateConditionalAccountHolderFields) {
                    let bankAccountHolderId = getBankAccountHolderIdNumber(state);
                    error = validateDeceasedAccountHolderIdsMatching(
                        action.value,
                        bankAccountHolderId.value
                    );
                    
                    if (error && bankAccountHolderId.error === ID_MATCH_REQUESTER_ACCOUNT_HOLDER_ERROR) {
                        return setDeceasedIdValid(null);
                    }
                }

                return setDeceasedIdValid(error);

            case FUNERAL_CLAIM_SET_CAUSE_OF_DEATH_VALUE:
                error = validateSingleSelection(action.value);
                return setCauseOfDeathValid(error);

            case FUNERAL_CLAIM_SET_REASON_FOR_UNNATURAL_DEATH_VALUE:
                error = validateReasonForUnnaturalDeath(action.value);
                return setReasonForUnnaturalDeathValid(error);

            case FUNERAL_CLAIM_SET_REASON_FOR_DEATH_VALUE:
                error = validateReasonForDeath(action.value);
                return setReasonForDeathValid(error);

            case FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE:
                error = validateFuneralDate(state, action.value);
                return setFuneralDateValid(error);

            case FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_ID_NUMBER_VALUE:
                error = validateIdNumber(action.value, {
                    required: getShowBankAccountHoldersFields(state),
                });

                // 2. Test deceased Id with accountHolder Id
                isValidateConditionalAccountHolderFields = getShowBankAccountHoldersFields(state);
                if (!error && isValidateConditionalAccountHolderFields) {
                    deceasedIdNumber = getDeceasedIdNumber(state);
                    error = validateDeceasedAccountHolderIdsMatching(
                        deceasedIdNumber.value,
                        action.value
                    );

                    if (error && deceasedIdNumber.error === ID_MATCH_DECEASED_ACCOUNT_HOLDER_ERROR) {
                        return setDeceasedIdValid(null);
                    }
                    
                }

                // 3. Test requester Id with accountHolder Id
                if (!error && isValidateConditionalAccountHolderFields) {
                    requesterIdNumber = getRequesterIdNumber(state);
                    error = validateRequesterAccountHolderIdsMatching(
                        requesterIdNumber.value,
                        action.value
                    );

                    if (error && requesterIdNumber.error === ID_MATCH_REQUESTER_ACCOUNT_HOLDER_ERROR) {
                        return setRequesterIdValid(null);
                    }
                }

                return setBankAccountHolderIdNumberValid(error);
            
            case FUNERAL_CLAIM_SET_BANK_ACCOUNT_HOLDER_NAME_VALUE:
                error = validateName(action.value, {
                    required: getShowBankAccountHoldersFields(state),
                    subject: 'Account holders name',
                });
                return setBankAccountHolderNameValid(error);

            case FUNERAL_CLAIM_SET_BANK_VALUE:
                error = validateSingleSelection(action.value);
                return setBankValid(error);

            case FUNERAL_CLAIM_SET_BANK_OTHER_VALUE:
                error = validateName(action.value, {
                    subject: 'Bank Name',
                });
                return setBankOtherValid(error);

            case FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE:
                error = validateNumericString(action.value, {
                    min: 6,
                    max: 13,
                    subject: 'Bank Account Number',
                });
                return setBankAccountNumberValid(error);

            case FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE:
                error = validateNumericString(action.value, {
                    min: 6,
                    max: 8,
                    subject: 'Branch Code',
                    required: false,
                });
                return setBranchCodeValid(error);

            case FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE:
                error = validateSingleSelection(action.value);
                return setBankAccountTypeValid(error);
            
            case FUNERAL_CLAIM_SET_NOTICE_OF_DEATH_DOCUMENT_VALUE:
                if (action.value.file) {
                    error = validateFileType(action.value.file);

                    if(!error || error === null) {
                        error = validateFileSize(action.value.file);
                    }
                }
                return setNoticeOfDeathDocumentValid(error);
            
            case FUNERAL_CLAIM_SET_REMOVE_NOTICE_OF_DEATH_DOCUMENT_VALUE:
                return setRemoveNoticeOfDeathDocumentValid(null);
            
            case FUNERAL_CLAIM_SET_PROOF_OF_BANKING_DOCUMENT_VALUE:
                if (action.value.file) {
                    error = validateFileType(action.value.file);
                    if(!error || error === null) {
                        error = validateFileSize(action.value.file);
                    }
                }
                return setProofOfBankDocumentValid(error);
            
            case FUNERAL_CLAIM_SET_REMOVE_PROOF_OF_BANKING_DOCUMENT_VALUE:
                return setRemoveProofOfBankDocumentValid(null);
            
            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
