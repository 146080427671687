import { transitionDigitalSavingsBeneficiariesForm } from '../../../actions/digitalSavings/beneficiaries';
import {
    getBeneficiariesForms,
    getSelectedBeneficiariesFormIndex,
} from '../../../selectors/digitalSavings/beneficiaries';

import { FIELD_STATUS } from '../../../reducers/status';

export const beneficiariesFormRules = (store, next, action) => {
    const result = next(action);
    const beneficiariesForms = getBeneficiariesForms(store.getState());

    const selectedBeneficiariesFormIndex = getSelectedBeneficiariesFormIndex(store.getState());

    let beneficiariesFormValid = true;

    if (selectedBeneficiariesFormIndex !== null) {
        beneficiariesFormValid = beneficiariesForms[selectedBeneficiariesFormIndex].isMinor.value ? validateBeneficiaryMinorFormDetails(beneficiariesForms[selectedBeneficiariesFormIndex]) : validateBeneficiaryFormDetails(beneficiariesForms[selectedBeneficiariesFormIndex]);
    }

    const formAction = beneficiariesFormValid
        ? transitionDigitalSavingsBeneficiariesForm.formValid(selectedBeneficiariesFormIndex)
        : transitionDigitalSavingsBeneficiariesForm.formInvalid(selectedBeneficiariesFormIndex);
    store.dispatch(formAction);
    return result;
};

const validateBeneficiaryFormDetails = (formFields) => {
    return (
        formFields &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.relationship &&
        formFields.relationship.status === FIELD_STATUS.VALID &&
        formFields.location &&
        formFields.location.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.isMinor &&
        formFields.isMinor.status === FIELD_STATUS.VALID &&
        formFields.percentageSplit &&
        formFields.percentageSplit.status === FIELD_STATUS.VALID

    );
};

const validateBeneficiaryMinorFormDetails = (formFields) => {
    return (
        formFields &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.firstNameMinor &&
        formFields.firstNameMinor.status === FIELD_STATUS.VALID &&
        formFields.lastNameMinor &&
        formFields.lastNameMinor.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.relationship &&
        formFields.relationship.status === FIELD_STATUS.VALID &&
        formFields.location &&
        formFields.location.status === FIELD_STATUS.VALID &&
        formFields.locationMinor &&
        formFields.locationMinor.status === FIELD_STATUS.VALID &&
        formFields.isMinor &&
        formFields.isMinor.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.percentageSplit &&
        formFields.percentageSplit.status === FIELD_STATUS.VALID
    );
};