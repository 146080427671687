import { FIELD_STATUS, FORM_STATUS, TOGGLE_STATUS } from "../status";
import { ACCOUNT_VALIDATION_STATUS } from "../../actions/raOptimal/types";
import {
    FORM_VALID_TRANSITION_PAYMENT_DETAILS,
    FORM_INVALID_TRANSITION_PAYMENT_DETAILS,
    TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS,
    TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    PAYMENT_DETAILS_FORM_VALID_TRANSITION_PAYMENT_DETAILS,
    PAYMENT_DETAILS_FORM_INVALID_TRANSITION_PAYMENT_DETAILS,

    BANK_NAME_SET_VALUE,
    BANK_NAME_SET_VALID,
    ACCOUNT_HOLDER_NAME_PRESET_VALUE,
    ACCOUNT_HOLDER_NAME_PRESET_VALID,
    ACCOUNT_HOLDER_NAME_SET_VALUE,
    ACCOUNT_HOLDER_NAME_SET_VALID,
    ACCOUNT_HOLDER_NAME_EDITED_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALUE,
    ACCOUNT_NUMBER_SET_VALID,
    ACCOUNT_TYPE_SET_VALUE,
    ACCOUNT_TYPE_SET_VALID,
    DEBIT_ORDER_DAY_SET_VALUE,
    DEBIT_ORDER_DAY_SET_VALID,
    START_MONTH_SET_VALUE,
    START_MONTH_SET_VALID,
    START_MONTH_SET_CLEAR,
    SOURCE_OF_INCOME_SET_VALUE,
    SOURCE_OF_INCOME_SET_VALID,
    SOURCE_OF_INCOME_OTHER_SET_VALUE,
    SOURCE_OF_INCOME_OTHER_SET_VALID,
    SOURCE_OF_INCOME_OTHER_SET_CLEAR,
    SOURCE_OF_FUNDS_SET_VALUE,
    SOURCE_OF_FUNDS_SET_VALID,
    EMPLOYMENT_INDUSTRY_SET_VALUE,
    EMPLOYMENT_INDUSTRY_SET_VALID,
    EMPLOYMENT_INDUSTRY_SET_CLEAR,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE,
    EMPLOYMENT_INDUSTRY_OTHER_SET_VALID,
    EMPLOYMENT_INDUSTRY_OTHER_SET_CLEAR,
    EMPLOYMENT_POSITION_SET_VALUE,
    EMPLOYMENT_POSITION_SET_VALID,

    SOURCE_OF_INCOME_OTHER_REQUIRED_PAYMENT_DETAILS,
    SOURCE_OF_INCOME_OTHER_NOT_REQUIRED_PAYMENT_DETAILS,
    EMPLOYMENT_INDUSTRY_REQUIRED_PAYMENT_DETAILS,
    EMPLOYMENT_INDUSTRY_NOT_REQUIRED_PAYMENT_DETAILS,
    EMPLOYMENT_INDUSTRY_OTHER_REQUIRED_PAYMENT_DETAILS,
    EMPLOYMENT_INDUSTRY_OTHER_NOT_REQUIRED_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_COMPLETE_PAYMENT_DETAILS,
    ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS,
    RESET_PAYMENT_DETAILS
} from "../../actions/raOptimal/paymentDetails";

export const initPaymentDetails = {
    formStatus: FORM_STATUS.INVALID,
    paymentDetailsFormStatus: FORM_STATUS.INVALID,
    paymentAuthorization: TOGGLE_STATUS.UNTOUCHED,
    sourceOfIncomeOtherRequired: TOGGLE_STATUS.NO,
    employmentIndustryRequired: TOGGLE_STATUS.YES,
    employmentIndustryOtherRequired: TOGGLE_STATUS.NO,
    accountHolderNameEdited: TOGGLE_STATUS.NO,
    includeAvs: false,
    includeCdv: false,
    accountValidationStatus: ACCOUNT_VALIDATION_STATUS.IDLE,
    accountValidationComplete: false,
    form: {
        bankName: { value: null, error: null, status: null },
        accountHolderName: { value: null, error: null, status: null },
        accountNumber: { value: null, error: null, status: null },
        accountType: { value: null, error: null, status: null },
        debitOrderDay: { value: null, error: null, status: null },
        startMonth: { value: null, error: null, status: null },

        sourceOfIncome: { value: null, error: null, status: null },
        sourceOfIncomeOther: { value: null, error: null, status: null },
        sourceOfFunds: { value: null, error: null, status: null },
        employmentPosition: { value: null, error: null, status: null },
        employmentIndustry: { value: null, error: null, status: null },
        employmentIndustryOther: { value: null, error: null, status: null }
    },
};

export default (state = initPaymentDetails, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_PAYMENT_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_PAYMENT_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case PAYMENT_DETAILS_FORM_VALID_TRANSITION_PAYMENT_DETAILS:
            return { ...state, paymentDetailsFormStatus: FORM_STATUS.VALID };
        case PAYMENT_DETAILS_FORM_INVALID_TRANSITION_PAYMENT_DETAILS:
            return { ...state, paymentDetailsFormStatus: FORM_STATUS.INVALID };

        case TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS:
            if (action.value) return { ...state, paymentAuthorization: FORM_STATUS.INVALID };
            else {
                return {
                    ...state,
                    paymentAuthorization: state.paymentAuthorization !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO
                }
            };

        // Account validation
        case ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS:
            return { ...state, accountValidationStatus: action.value }

        case ACCOUNT_VALIDATION_COMPLETE_PAYMENT_DETAILS:
            return { ...state, accountValidationComplete: action.value };

        case ACCOUNT_VALIDATION_RESET_PAYMENT_DETAILS:
            return { ...state, 
                accountValidationComplete: false,
                accountValidationStatus:  ACCOUNT_VALIDATION_STATUS.IDLE,
                paymentAuthorization: TOGGLE_STATUS.UNTOUCHED,
            };

        // If this is toggled in content stack this action is fired on mount
        case TOGGLE_INCLUDE_AVS_PAYMENT_DETAILS:
            return { ...state, includeAvs: true }
        case TOGGLE_INCLUDE_CDV_PAYMENT_DETAILS:
            return { ...state, includeCdv: true }

        // Other source of income required
        case ACCOUNT_HOLDER_NAME_EDITED_SET_VALUE:
            return { ...state, accountHolderNameEdited: TOGGLE_STATUS.YES };

        // Other source of income required
        case SOURCE_OF_INCOME_OTHER_REQUIRED_PAYMENT_DETAILS:
            return { ...state, sourceOfIncomeOtherRequired: TOGGLE_STATUS.YES };
        case SOURCE_OF_INCOME_OTHER_NOT_REQUIRED_PAYMENT_DETAILS:
            return { ...state, sourceOfIncomeOtherRequired: TOGGLE_STATUS.NO };

        case EMPLOYMENT_INDUSTRY_REQUIRED_PAYMENT_DETAILS:
            return { ...state, employmentIndustryRequired: TOGGLE_STATUS.YES };
        case EMPLOYMENT_INDUSTRY_NOT_REQUIRED_PAYMENT_DETAILS:
            return { ...state, employmentIndustryRequired: TOGGLE_STATUS.NO };

        case EMPLOYMENT_INDUSTRY_OTHER_REQUIRED_PAYMENT_DETAILS:
            return { ...state, employmentIndustryOtherRequired: TOGGLE_STATUS.YES };
        case EMPLOYMENT_INDUSTRY_OTHER_NOT_REQUIRED_PAYMENT_DETAILS:
            return { ...state, employmentIndustryOtherRequired: TOGGLE_STATUS.NO };


        case BANK_NAME_SET_VALUE:
            return updateFormField('bankName', field => ({
                ...field,
                value: action.value,
            }));
        case BANK_NAME_SET_VALID:
            return updateFormField('bankName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Account holder name
        case ACCOUNT_HOLDER_NAME_PRESET_VALUE:
            return updateFormField('accountHolderName', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_HOLDER_NAME_PRESET_VALID:
            return updateFormField('accountHolderName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case ACCOUNT_HOLDER_NAME_SET_VALUE:
            return updateFormField('accountHolderName', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_HOLDER_NAME_SET_VALID:
            return updateFormField('accountHolderName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Account Number
        case ACCOUNT_NUMBER_SET_VALUE:
            return updateFormField('accountNumber', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_NUMBER_SET_VALID:
            return updateFormField('accountNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Account type
        case ACCOUNT_TYPE_SET_VALUE:
            return updateFormField('accountType', field => ({
                ...field,
                value: action.value,
            }));
        case ACCOUNT_TYPE_SET_VALID:
            return updateFormField('accountType', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Debit order day
        case DEBIT_ORDER_DAY_SET_VALUE:
            return updateFormField('debitOrderDay', field => ({
                ...field,
                value: action.value,
            }));
        case DEBIT_ORDER_DAY_SET_VALID:
            return updateFormField('debitOrderDay', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Starting month of debit order
        case START_MONTH_SET_VALUE:
            return updateFormField('startMonth', field => ({
                ...field,
                value: action.value,
            }));
        case START_MONTH_SET_VALID:
            return updateFormField('startMonth', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case START_MONTH_SET_CLEAR:
            return updateFormField('startMonth', field => ({
                ...field,
                error: null,
                value: null,
                status: null,
            }));

        // Source of income
        case SOURCE_OF_INCOME_SET_VALUE:
            return updateFormField('sourceOfIncome', field => ({
                ...field,
                value: action.value,
            }));
        case SOURCE_OF_INCOME_SET_VALID:
            return updateFormField('sourceOfIncome', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));


        // Source of income
        case SOURCE_OF_INCOME_OTHER_SET_VALUE:
            return updateFormField('sourceOfIncomeOther', field => ({
                ...field,
                value: action.value,
            }));
        case SOURCE_OF_INCOME_OTHER_SET_VALID:
            return updateFormField('sourceOfIncomeOther', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SOURCE_OF_INCOME_OTHER_SET_CLEAR:
            return updateFormField('sourceOfIncomeOther', () => ({
                value: null,
                error: null,
                status: null
            }));

        // Source of Funds
        case SOURCE_OF_FUNDS_SET_VALUE:
            return updateFormField('sourceOfFunds', field => ({
                ...field,
                value: action.value,
            }));
        case SOURCE_OF_FUNDS_SET_VALID:
            return updateFormField('sourceOfFunds', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Employment position
        case EMPLOYMENT_POSITION_SET_VALUE:
            return updateFormField('employmentPosition', field => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYMENT_POSITION_SET_VALID:
            return updateFormField('employmentPosition', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Employment industry
        case EMPLOYMENT_INDUSTRY_SET_VALUE:
            return updateFormField('employmentIndustry', field => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYMENT_INDUSTRY_SET_VALID:
            return updateFormField('employmentIndustry', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMPLOYMENT_INDUSTRY_SET_CLEAR:
            return updateFormField('employmentIndustry', () => ({
                value: null,
                error: null,
                status: null
            }));

        // Employment industry
        case EMPLOYMENT_INDUSTRY_OTHER_SET_VALUE:
            return updateFormField('employmentIndustryOther', field => ({
                ...field,
                value: action.value,
            }));
        case EMPLOYMENT_INDUSTRY_OTHER_SET_VALID:
            return updateFormField('employmentIndustryOther', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMPLOYMENT_INDUSTRY_OTHER_SET_CLEAR:
            return updateFormField('employmentIndustryOther', () => ({
                value: null,
                error: null,
                status: null
            }));

        case RESET_PAYMENT_DETAILS: 
            return { ...initPaymentDetails };

        default:
            return state;
    }
};


