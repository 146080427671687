import * as ActionTypes from "./types";

export function storeValue(key, value) {
    const isValidOTP = value.length === 6;
    return {
        type: ActionTypes.STORE_OTP_VALUE,
        key: key,
        value: value,
        isValid: isValidOTP,
        errorMessage: ''
    };
}

export function storeOnblur(key,value){
    return {
        type: ActionTypes.STORE_OTP_VALUE,
        key: key,
        value: value,
        isValid: false,
        errorMessage: 'Please enter the OTP sent to your mobile number.'
    }
}

export function setInvalidOTP(){
    return {
        type: ActionTypes.OTP_NOT_VALID
    }
}

export function resetOTPValue() {
    return {
        type: ActionTypes.RESET_OTP_VALUE
    };
}

export function resendOTPRequest() {
    return {
        type: ActionTypes.OTP_RESEND_REQUEST
    };
}
