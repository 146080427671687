import {
    FIELD_STATUS,
    FORM_SUBMIT_STATUS,
} from "../status";
import {
    SIGNUP_AGREE_NEWSLETTER_VALUE,
    SIGNUP_AGREE_PRIVACY_POLICY_VALUE, SIGNUP_COMPANY_NAME_VALID,
    SIGNUP_COMPANY_NAME_VALUE, SIGNUP_EBOOKS_VALUE, SIGNUP_EMAIL_VALID,
    SIGNUP_EMAIL_VALUE,
    SIGNUP_HAS_EBOOKS_VALUE,
    SIGNUP_HAS_INTERESTS_VALUE, SIGNUP_HAS_TOOLS_VALUE,
    SIGNUP_INDUSTRY_VALUE, SIGNUP_INTERESTS_VALUE, SIGNUP_NAME_VALID,
    SIGNUP_NAME_VALUE, SIGNUP_NUMBER_EMPLOYEES_VALID,
    SIGNUP_NUMBER_EMPLOYEES_VALUE, SIGNUP_PHONE_NUMBER_VALID,
    SIGNUP_PHONE_NUMBER_VALUE, SIGNUP_RECAPTCHA_VALUE, SIGNUP_SURNAME_VALID,
    SIGNUP_SURNAME_VALUE, SIGNUP_TOOLS_VALUE,
    SIGNUP_USER_OWNS_BUSINESS_VALUE,
    FORM_SUBMIT_STATUS_SET_VALUE,
    IS_SIDE_DRAWER_OPEN_VALUE,
    SIGNUP_MODULAR_FIELDS_VALUE,
    SIGNUP_USE_MODULAR_FIELDS_VALUE,
    SIGNUP_USE_ONLY_MODULAR_FIELDS_VALUE,
    SIGNUP_MODULAR_FIELD_1_VALUE, SIGNUP_MODULAR_FIELD_1_VALID,
    SIGNUP_MODULAR_FIELD_2_VALUE,SIGNUP_MODULAR_FIELD_2_VALID,
    SIGNUP_MODULAR_FIELD_3_VALUE,SIGNUP_MODULAR_FIELD_3_VALID,
    SIGNUP_MODULAR_FIELD_4_VALUE,SIGNUP_MODULAR_FIELD_4_VALID,
    SIGNUP_MODULAR_FIELD_5_VALUE,SIGNUP_MODULAR_FIELD_5_VALID,
    SIGNUP_MODULAR_FIELD_6_VALUE,SIGNUP_MODULAR_FIELD_6_VALID,
    SIGNUP_MODULAR_FIELD_7_VALUE,SIGNUP_MODULAR_FIELD_7_VALID,
    SIGNUP_MODULAR_FIELD_8_VALUE,SIGNUP_MODULAR_FIELD_8_VALID,
    SIGNUP_USE_INDUSTRIES_LIST_VALUE,
    SIGNUP_INDUSTRIES_LIST_VALUE,
    SIGNUP_SELECTED_INDUSTRIES_LIST_VALUE,
    SIGNUP_SELECTED_INDUSTRIES_LIST_VALID,
    SIGNUP_SHOW_PHONE_NUMBER_FIELD_VALUE
} from "../../actions/publicWeb/signupAndDownload";

export const YES = 'Yes';
export const NO = 'No';

const initSignupAndDownload = {
    firstName: { value: null, error: null, status: null },
    surname: { value: null, error: null, status: null },
    phoneNumber: { value: null, error: null, status: null },
    email: { value: null, error: null, status: null },
    companyName: { value: null, error: null, status: null },
    numberEmployees: { value: null, error: null, status: null },
    agreePrivacyPolicy: { value: false, error: null, status: null },
    useModularFields: { value: false, error: null, status: null },
    useOnlyModularFields: { value: false, error: null, status: null },
    agreeNewsletter: { value: false, error: null, status: null },
    userOwnsBusiness: { value: null, error: null, status: null },
    industry: { value: null, error: null, status: null },
    hasTools: { value: null, error: null, status: null },
    tools: { value: [], error: null, status: null },
    hasEbooks: { value: null, error: null, status: null },
    ebooks: { value: [], error: null, status: null },
    hasInterests: { value: null, error: null, status: null },
    interests: { value: [], error: null, status: null },
    recaptcha: { value: null, error: null, status: null },
    formSubmitStatus: FORM_SUBMIT_STATUS.NEW,
    userOwnsBusinessChoices: [YES, NO],
    isOpen: false,
    showPhoneNumberField: true,
    modularFields: { value: [], error: null, status: null },
    modularField1: { value: null, error: null, status: null },
    modularField2: { value: null, error: null, status: null },
    modularField3: { value: null, error: null, status: null },
    modularField4: { value: null, error: null, status: null },
    modularField5: { value: null, error: null, status: null },
    modularField6: { value: null, error: null, status: null },
    modularField7: { value: null, error: null, status: null },
    modularField8: { value: null, error: null, status: null },
    useIndustriesList: { value: false, error: null, status: null },
    industriesList: { value: [], error: null, status: null },
    industriesValue: { value: null, error: null, status: null },
};

export default (state = initSignupAndDownload, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        [fieldId]: update(state[fieldId])
    });

    switch (action.type) {
        // Form Fields
        case SIGNUP_NAME_VALUE:
            return updateFormField('firstName', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_NAME_VALID:
            return updateFormField('firstName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_SURNAME_VALUE:
            return updateFormField('surname', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_SURNAME_VALID:
            return updateFormField('surname', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_AGREE_NEWSLETTER_VALUE:
            return updateFormField('agreeNewsletter', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_AGREE_PRIVACY_POLICY_VALUE:
            return updateFormField('agreePrivacyPolicy', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_NUMBER_EMPLOYEES_VALUE:
            return updateFormField('numberEmployees', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_NUMBER_EMPLOYEES_VALID:
            return updateFormField('numberEmployees', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_COMPANY_NAME_VALUE:
            return updateFormField('companyName', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_COMPANY_NAME_VALID:
            return updateFormField('companyName', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_EMAIL_VALUE:
            return updateFormField('email', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_EMAIL_VALID:
            return updateFormField('email', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_PHONE_NUMBER_VALUE:
            return updateFormField('phoneNumber', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_PHONE_NUMBER_VALID:
            return updateFormField('phoneNumber', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_USER_OWNS_BUSINESS_VALUE:
            return updateFormField('userOwnsBusiness', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_INDUSTRY_VALUE:
            return updateFormField('industry', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_HAS_EBOOKS_VALUE:
            return updateFormField('hasEbooks', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_EBOOKS_VALUE:
            return updateFormField('ebooks', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_HAS_INTERESTS_VALUE:
            return updateFormField('hasInterests', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_INTERESTS_VALUE:
            return updateFormField('interests', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_HAS_TOOLS_VALUE:
            return updateFormField('hasTools', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_TOOLS_VALUE:
            return updateFormField('tools', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_RECAPTCHA_VALUE:
            return updateFormField('recaptcha', field => ({
                ...field,
                value: action.value,
            }));
        case IS_SIDE_DRAWER_OPEN_VALUE:
            return {
                ...state,
                isOpen: action.value,
            };
        case SIGNUP_SHOW_PHONE_NUMBER_FIELD_VALUE:
            return {
                ...state,
                showPhoneNumberField: action.value,
            };
        case FORM_SUBMIT_STATUS_SET_VALUE:
            return {
                ...state,
                formSubmitStatus: action.value,
            };
        case SIGNUP_MODULAR_FIELDS_VALUE:
            return updateFormField('modularFields', field => ({
                    ...field,
                    value: action.value,
                }));
        case SIGNUP_MODULAR_FIELD_1_VALUE:
            return updateFormField('modularField1', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_1_VALID:
            return updateFormField('modularField1', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_MODULAR_FIELD_2_VALUE:
            return updateFormField('modularField2', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_2_VALID:
            return updateFormField('modularField2', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_MODULAR_FIELD_3_VALUE:
            return updateFormField('modularField3', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_3_VALID:
            return updateFormField('modularField', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_MODULAR_FIELD_4_VALUE:
            return updateFormField('modularField4', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_4_VALID:
            return updateFormField('modularField4', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_MODULAR_FIELD_5_VALUE:
            return updateFormField('modularField5', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_5_VALID:
            return updateFormField('modularField5', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_MODULAR_FIELD_6_VALUE:
            return updateFormField('modularField6', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_6_VALID:
            return updateFormField('modularField6', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_MODULAR_FIELD_7_VALUE:
            return updateFormField('modularField7', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_7_VALID:
            return updateFormField('modularField7', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_MODULAR_FIELD_8_VALUE:
            return updateFormField('modularField8', (field) => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_MODULAR_FIELD_8_VALID:
            return updateFormField('modularField8', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SIGNUP_USE_MODULAR_FIELDS_VALUE:
            return updateFormField('useModularFields', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_USE_ONLY_MODULAR_FIELDS_VALUE:
            return updateFormField('useOnlyModularFields', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_USE_INDUSTRIES_LIST_VALUE:
            return updateFormField('useIndustriesList', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_INDUSTRIES_LIST_VALUE:
            return updateFormField('industriesList', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_SELECTED_INDUSTRIES_LIST_VALUE:
            return updateFormField('industriesValue', field => ({
                ...field,
                value: action.value,
            }));
        case SIGNUP_SELECTED_INDUSTRIES_LIST_VALID:
            return updateFormField('industriesValue', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        default:
            return state;
    }
}
