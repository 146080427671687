import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

export function validateYearOfManufacture(
    value,
    { required = true, max, subject = 'date' }
) {
	const isEmpty = !value && value !== 0;
	if (isEmpty && required) {
		return OmValidationMessageHelper.getRequiredMessage(subject);
	}

	const yearRegex =  /^(19|20)\d{2}$/
	const isYearValid = yearRegex.test(value)
	if(!isEmpty && !isYearValid) {
		return OmValidationMessageHelper.getValidYearMessage()
	}
    const yearOfManufacture = new Date(value).getFullYear();
    const currentYear = new Date().getFullYear();
    const isOld = currentYear - yearOfManufacture > max;
    const isFutureYear = yearOfManufacture - currentYear > 0
    if (!isEmpty && isYearValid && isOld && required) {
        return OmValidationMessageHelper.getYearOfManufacturerOldMsg(
            max
        );
    } else if(!isEmpty && isYearValid && isFutureYear && required) {
    	return OmValidationMessageHelper.getYearOfManufacturerFutureMsg(currentYear)
	}
    return null
}
