import { ADDRESS_PROOF_DOCUMENTS_SET_VALID, ADDRESS_PROOF_DOCUMENTS_SET_VALUE, BANK_STATEMENT_DOCUMENTS_SET_VALID, BANK_STATEMENT_DOCUMENTS_SET_VALUE, CLEAR_ID_DOCUMENTS_SET_VALUE, FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS, FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS, ID_DOCUMENTS_SET_VALID, ID_DOCUMENTS_SET_VALUE, INCOME_PROOF_DOCUMENTS_SET_VALID, INCOME_PROOF_DOCUMENTS_SET_VALUE, LOAN_LETTER_DOCUMENTS_SET_VALID, LOAN_LETTER_DOCUMENTS_SET_VALUE, RESET_DOCUMENTS_SET_VALUE} from "../../actions/digiLifeLoanProtection/uploadDocuments";
import { FIELD_STATUS, FORM_STATUS } from "../status";

export const initUploadDocument = {
    formStatus: FORM_STATUS.INVALID,
    form:{
        certifiedCopyOfId:{ fileName: null, fileContaint: null, error: null, status: null ,file: null},
        incomeProof:{ fileName: null, fileContaint: null, error: null, status: null ,file: null},
        addressProof:{ fileName: null, fileContaint: null, error: null, status: null ,file: null},
        loanLetter:{ fileName: null, fileContaint: null, error: null, status: null ,file: null},
        bankStatement:{ fileName: null, fileContaint: null, error: null, status: null ,file: null},
        
    }
}


export default (state = initUploadDocument, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
          ...state.form,
          [fieldId]: update(state.form[fieldId]),
        },
      });
    switch(action.type){
        //ID DOCUMENTS
        case ID_DOCUMENTS_SET_VALUE:
            return updateFormField("certifiedCopyOfId", (field) => ({
                ...field,
                fileName: action.fileName,
                fileContaint:action.fileContaint,
                file:action.file
              }));
      
        case INCOME_PROOF_DOCUMENTS_SET_VALUE:
            return updateFormField("incomeProof", (field) => ({
                ...field,
                fileName: action.fileName,
                fileContaint:action.fileContaint,
                file:action.file
              }));
      
        case ADDRESS_PROOF_DOCUMENTS_SET_VALUE:
            return updateFormField("addressProof", (field) => ({
                ...field,
                fileName: action.fileName,
                fileContaint:action.fileContaint,
                file:action.file
              }));
      
        case LOAN_LETTER_DOCUMENTS_SET_VALUE:
            return updateFormField("loanLetter", (field) => ({
                ...field,
                fileName: action.fileName,
                fileContaint:action.fileContaint,
                file:action.file
              }));
      
        case BANK_STATEMENT_DOCUMENTS_SET_VALUE:
            return updateFormField("bankStatement", (field) => ({
                ...field,
                fileName: action.fileName,
                fileContaint:action.fileContaint,
                file:action.file
              }));
      
        case CLEAR_ID_DOCUMENTS_SET_VALUE:
            return updateFormField("certifiedCopyOfId", (field) => ({
                error: null,
                status: null,
                fileName: null,
                fileContaint:null,
              }));
              
        case ID_DOCUMENTS_SET_VALID:
            return updateFormField("certifiedCopyOfId", (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
              }));
        case BANK_STATEMENT_DOCUMENTS_SET_VALID:
            return updateFormField("bankStatement", (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
              }));
        case INCOME_PROOF_DOCUMENTS_SET_VALID:
            return updateFormField("incomeProof", (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
              }));
        case LOAN_LETTER_DOCUMENTS_SET_VALID:
            return updateFormField("loanLetter", (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
              }));
        case ADDRESS_PROOF_DOCUMENTS_SET_VALID:
            return updateFormField("addressProof", (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
              }));

        case RESET_DOCUMENTS_SET_VALUE:
            return { ...initUploadDocument }
      

            case FORM_VALID_TRANSITION_UPLOAD_DOCUMENTS:
                return {
                    ...state, 
                 formStatus:FORM_STATUS.VALID
                };

            case FORM_INVALID_TRANSITION_UPLOAD_DOCUMENTS:
                return {
                    ...state,
                 formStatus:FORM_STATUS.INVALID
                };
                
        default:
            return state;
    }
};