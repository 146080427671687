import { createSelector } from "reselect";
import { FORM_STATUS } from '../../reducers/status';

export const getDividendCalculator = (state => {
    return state.publicWeb.dividendCalculator;
});

const makeGetField = fieldId => createSelector(
    getDividendCalculator,
    dividendCalculator => dividendCalculator[fieldId]
);

export const getDividendsTotal = makeGetField('dividendsTotal');
export const getShares = makeGetField('shares');
export const getDividendCalcSelectedCountry = makeGetField('selectedCountry');

export const getFormValid = createSelector(
    getDividendCalculator,
    dividendCalculator => dividendCalculator.formStatus === FORM_STATUS.VALID,
);
