import axios from 'axios';

import {
	apiTransitionLongTermGoalCalculator,
	CALCULATE_LONG_TERM_GOAL,
} from '../../../actions/investmentCalculator/longTermGoal';

import { setViewInvestmentResult } from '../../../actions/investmentCalculator/investmentCalculator';

import {
	getIsApiPending,
} from '../../../selectors/investmentCalculator/longTermGoalCalculator';

import {
	 getLTGDataWithSplit,
} from '../../../selectors/investmentCalculator/longTermGoalSplit';

export const longTermGoalCalculateService = (store, next, action) => {
	const result = next(action);
	const state = store.getState();

	if(action.type === CALCULATE_LONG_TERM_GOAL) {
		(async () => {
			if (!getIsApiPending(state)) {
				store.dispatch(apiTransitionLongTermGoalCalculator.pending());
				try {
					const response = await calculate(state);
					store.dispatch(
						setViewInvestmentResult(response?.data)
					);
					store.dispatch(apiTransitionLongTermGoalCalculator.success());
				} catch (e) {
					store.dispatch(setViewInvestmentResult(null));
					store.dispatch(apiTransitionLongTermGoalCalculator.failure());
				}
			}
		})();
	}
	return result;
};

export const calculate = async (state) => {

	const data = getLTGDataWithSplit(state);

	try {
		const url = '/om-api/investment-calculator/long-term-goal';
		return await axios.post(url, data);
	} catch (error) {
		return null;
	}
};
