import {
    QUOTE_SET_VALUE,
    CLEAR_ALL_TRANSITION_QUOTE,
    SAVE_TRANSITION_QUOTE,
    ADD_NEW_TRANSITION_QUOTE,
    DELETE_TRANSITION_QUOTE,
    CONFIRM_DELETE_MEMBER_CLEAR,
    CONFIRM_DELETE_MEMBER_SET,
    EDIT_TRANSITION_QUOTE,
    EXPAND_MEMBERS_TRANSITION_QUOTE,
    NEW_COVER_DEFAULT_SET_VALUE,
    NEW_COVER_MYSELF_SET_VALUE,
    NEW_COVER_PARENTS_SET_VALUE,
    NEW_COVER_EXTENDED_SET_VALUE,
    SIDEDRAWER_SET_VALUE,
    SIDEDRAWER_CLOSED_SET_VALUE,
    CONTINUE_TRANSITION_QUOTE,
    BACK_TRANSITION_QUOTE,
} from '../../../actions/easiplusFuneralPlan/quote';
import {
    quotePageFieldTracking,
    quoteOtherTracking,
    quoteTransitionTracking,
    quoteCallMeBackTracking,
} from './quote';
import {
    CALL_ME_BACK_SET_VALUE,
    CALL_ME_BACK_SEND_INITIATE
} from '../../../actions/easiplusFuneralPlan/callMeBack';
import {
    PERSONAL_DETAILS_SET_VALUE
} from '../../../actions/easiplusFuneralPlan/personalDetails';
import {
    personalDetailsFieldsTracking
} from "./personalDetails";
import { REPLACEMENT_POLICY_SET_VALUE } from "../../../actions/easiplusFuneralPlan/replacementPolicy";
import { replacementPolicyFieldsTracking } from "./replacementPolicy";
import { PAYMENT_DETAILS_SET_VALUE } from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { paymentDetailsFieldsTracking } from "./paymentDetails";
import {
    CONFIRM_DETAILS_SET_VALUE,
    TOGGLE_DECLARATIONS_CONFIRM_DETAILS
} from "../../../actions/easiplusFuneralPlan/confirmDetails";
import { confirmDetailsOtherTracking } from "./confirmDetails";
import {
    LIVES_COVERED_SET_VALUE,
    MODAL_SET_VALUE,
    MODAL_CLEAR_VALUE,
    SET_EDIT_COVER_MEMBER_LIVES_COVERED,
    LIVES_COVERED_TRANSITION
} from "../../../actions/easiplusFuneralPlan/livesCovered";
import {
    livesCoveredFieldsTracking,
    livesCoveredOtherTracking,
} from "./livesCovered";
import {
    beneficiariesFieldsTracking,
    beneficiariesOtherTracking,
} from "./beneficiaries";
import {
    BENEFICIARIES_SET_VALUE,
    ADD_NEW_TRANSITION_BENEFICIARIES,
    DELETE_TRANSITION_BENEFICIARIES,
    EDIT_TRANSITION_BENEFICIARIES,
    SAVE_TRANSITION_BENEFICIARIES,
    CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES,
    SELECTED_EXISTING_BENEFICIARY_SET_VALUE,
    SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE,
    TOGGLE_EDIT_TRANSITION_BENEFICIARIES
} from "../../../actions/easiplusFuneralPlan/beneficiaries";
import {
    BENEFICIARIES_SPLIT_SET_VALUE,
    EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT
} from "../../../actions/easiplusFuneralPlan/beneficiariesSplit";
import {
    beneficiarySplitFieldsTracking
} from "./beneficiarySplit";
import {
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
} from "../../../actions/easiplusFuneralPlan/routing";
import { routingTracking } from "./routing";

export const easiplusFuneralPlanTracking = (store, next, action) => {
    if (action.type === CLEAR_ALL_TRANSITION_QUOTE ||
        action.type === SAVE_TRANSITION_QUOTE ||
        action.type === NEW_COVER_DEFAULT_SET_VALUE ||
        action.type === NEW_COVER_MYSELF_SET_VALUE ||
        action.type === NEW_COVER_PARENTS_SET_VALUE ||
        action.type === NEW_COVER_EXTENDED_SET_VALUE ||
        action.type === SIDEDRAWER_SET_VALUE ||
        action.type === SIDEDRAWER_CLOSED_SET_VALUE ||
        action.type === ADD_NEW_TRANSITION_QUOTE ||
        action.type === DELETE_TRANSITION_QUOTE ||
        action.type === EDIT_TRANSITION_QUOTE ||
        action.type === EXPAND_MEMBERS_TRANSITION_QUOTE ||
        action.type === CONFIRM_DELETE_MEMBER_CLEAR ||
        action.type === CONFIRM_DELETE_MEMBER_SET ||
        action.type === CALL_ME_BACK_SEND_INITIATE)
        return quoteOtherTracking(store, next, action);

    if (action.type.startsWith(CALL_ME_BACK_SET_VALUE))
        return quoteCallMeBackTracking(store, next, action);

    if (action.type.startsWith(QUOTE_SET_VALUE))
        return quotePageFieldTracking(store, next, action);

    if (action.type === CONTINUE_TRANSITION_QUOTE ||
        action.type === BACK_TRANSITION_QUOTE)
        return quoteTransitionTracking(store, next, action);

    if (action.type.startsWith(REPLACEMENT_POLICY_SET_VALUE))
        return replacementPolicyFieldsTracking(store, next, action);

    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE))
        return personalDetailsFieldsTracking(store, next, action);

    if (action.type === MODAL_SET_VALUE ||
        action.type === SET_EDIT_COVER_MEMBER_LIVES_COVERED ||
        action.type === MODAL_CLEAR_VALUE ||
        action.type.startsWith(LIVES_COVERED_TRANSITION))
        return livesCoveredOtherTracking(store, next, action);

    if (action.type.startsWith(LIVES_COVERED_SET_VALUE))
        return livesCoveredFieldsTracking(store, next, action);

    if (action.type === ADD_NEW_TRANSITION_BENEFICIARIES ||
        action.type === DELETE_TRANSITION_BENEFICIARIES ||
        action.type === EDIT_TRANSITION_BENEFICIARIES ||
        action.type === SAVE_TRANSITION_BENEFICIARIES ||
        action.type === CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES ||
        action.type === SELECTED_EXISTING_BENEFICIARY_SET_VALUE ||
        action.type === SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE ||
        action.type === TOGGLE_EDIT_TRANSITION_BENEFICIARIES)
        return beneficiariesOtherTracking(store, next, action);

    if (action.type.startsWith(BENEFICIARIES_SET_VALUE)) {
        return beneficiariesFieldsTracking(store, next, action);
    }

    if (action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE) ||
        action.type === EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT ||
        action.type === EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT)
        return beneficiarySplitFieldsTracking(store, next, action);

    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE))
        return paymentDetailsFieldsTracking(store, next, action);

    if (action.type.startsWith(CONFIRM_DETAILS_SET_VALUE) ||
        action.type.startsWith(TOGGLE_DECLARATIONS_CONFIRM_DETAILS))
        return confirmDetailsOtherTracking(store, next, action);

    if (action.type === NAVIGATE_NEXT ||
        action.type === NAVIGATE_PREVIOUS)
        return routingTracking(store, next, action);

    return null;
}