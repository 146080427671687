import {
  EFP_APPLICATION_REFERENCE_DATA_SET_VALUE,
  EFP_QUOTE_REFERENCE_DATA_SET_VALUE,
  RESET_REFERENCE_DATA
} from "../../actions/easiplusFuneralPlan/referenceData";

export const initReferenceData = {
  applicationData: {},
  quoteData: {},
};

export default (state = initReferenceData, action) => {
  switch (action.type) {
      case EFP_APPLICATION_REFERENCE_DATA_SET_VALUE:
          return { ...state, applicationData: action.value };
      case EFP_QUOTE_REFERENCE_DATA_SET_VALUE:
          return { ...state, quoteData: action.value };
      case RESET_REFERENCE_DATA:
          return { ...initReferenceData };
      default:
          return state;
  }
};
