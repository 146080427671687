import { getIsOccupantLimitsApiStatus } from '../../../selectors/motorPrivateRW/quote';
import { apiTransitionOccupantsLimits, setOccupantLimitsValue, setOccupantLimitsValid } from '../../../actions/motorPrivateRW/quote';
import { getAccessToken } from '../../../selectors/motorPrivateRW/payments';
import axios from 'axios';

export const getOccupantLimitsService = (store, next, action) => {
    (async () => {
        if (!getIsOccupantLimitsApiStatus(store.getState())) {
            store.dispatch(apiTransitionOccupantsLimits.pending());
            try {
                const resp = await getOccupants(store.getState());
                store.dispatch(setOccupantLimitsValue(resp.data));
                store.dispatch(apiTransitionOccupantsLimits.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionOccupantsLimits.failure());
                store.dispatch(setOccupantLimitsValid(e));
            }
        }
    })();
    return next(action);
};

const getOccupants = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/rw-motor-insurance/occupants_limits';
        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData?.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
