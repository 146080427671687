
import { CALL_ME_BACK_SET_VALUE } from "../../../actions/easiplusFuneralPlan/callMeBack";
import { REPLACEMENT_POLICY_SET_VALUE } from "../../../actions/easiplusFuneralPlan/replacementPolicy";
import { PERSONAL_DETAILS_SET_VALUE } from "../../../actions/easiplusFuneralPlan/personalDetails";
import { PAYMENT_DETAILS_SET_VALUE } from "../../../actions/easiplusFuneralPlan/paymentDetails";
import { CONFIRM_DETAILS_SET_VALUE } from "../../../actions/easiplusFuneralPlan/confirmDetails";
import { BENEFICIARIES_SET_VALUE } from "../../../actions/easiplusFuneralPlan/beneficiaries";
import { LIVES_COVERED_SET_VALUE } from "../../../actions/easiplusFuneralPlan/livesCovered";
import { BENEFICIARIES_SPLIT_SET_VALUE } from "../../../actions/easiplusFuneralPlan/beneficiariesSplit";
import { QUOTE_SET_VALUE } from "../../../actions/easiplusFuneralPlan/quote";


export const isEfpAction = action => (
    action.type.startsWith(CALL_ME_BACK_SET_VALUE) ||
    action.type.startsWith(REPLACEMENT_POLICY_SET_VALUE) ||
    action.type.startsWith(PERSONAL_DETAILS_SET_VALUE) ||
    action.type.startsWith(PAYMENT_DETAILS_SET_VALUE) ||
    action.type.startsWith(CONFIRM_DETAILS_SET_VALUE) ||
    action.type.startsWith(BENEFICIARIES_SET_VALUE) ||
    action.type.startsWith(LIVES_COVERED_SET_VALUE) ||
    action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE) ||
    action.type.startsWith(QUOTE_SET_VALUE)
);
