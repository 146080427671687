import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';
import {
    SET_BUILDING_VALUE,
    SET_BUILDING_VALID,
    SET_BELONGINGS_VALUE,
    SET_BELONGINGS_VALID,
    SET_COVER_START_DATE_VALID,
    SET_COVER_START_DATE_VALUE,
    SET_ALL_RISK_VALUE,
    SET_ALL_RISK_VALID,
    SET_PORTABLE_VALUE,
    SET_PORTABLE_VALID,
    SET_DOMESTIC_SERVANTS_VALUE,
    SET_DOMESTIC_SERVANTS_VALID,
    SET_DOMESTIC_SERVANTS_NO_VALUE,
    SET_DOMESTIC_SERVANTS_NO_VALID,
    SET_OWNERS_LIABILITY_VALUE,
    SET_OWNERS_LIABILITY_VALID,
    SET_OWNERS_LIABILITY_AMOUNT_VALUE,
    SET_OWNERS_LIABILITY_AMOUNT_VALID,
    SET_OCCUPIERS_LIABILITY_VALUE,
    SET_OCCUPIERS_LIABILITY_VALID,
    SET_OCCUPIERS_LIABILITY_AMOUNT_VALUE,
    SET_OCCUPIERS_LIABILITY_AMOUNT_VALID,
    SET_SELECTED_COVER_VALID,
    SET_SELECTED_COVER_VALUE,
    FORM_INVALID_DOMESTIC_INSURANCE_FORM,
    FORM_VALID_DOMESTIC_INSURANCE_FORM,
    FORM_VALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM,
    FORM_INVALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM,
    CALCULATE_DOMESTIC_INSURANCE_PREMIUM_SUCCESS,
    DOMESTIC_INSURANCE_ADD_COVER,
    REINITIALIZE,
    SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
    UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    SET_DOMESTIC_EARTHQUAKE_VALUE,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
} from '../../actions/domesticInsurance/quote';

export const initQuote = {
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    shareQuoteAPIStatus: null,
    addedCover: null,
    showShareModal: false,
    ugCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Domestic Insurance',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    premiumCalculation: {
        value: null,
        status: null,
        error: null,
    },
    form: {
        coverStartDate: { value: null, error: null, status: null },
        selectedCover: { value: null, error: null, status: null },
        buildingSum: { value: null, error: null, status: null },
        contentsSum: { value: null, error: null, status: null },
        allRisksOption: { value: null, error: null, status: null },
        risksSum: { value: null, error: null, status: null },
        ownersOption: { value: null, error: null, status: null },
        ownersSum: { value: null, error: null, status: null },
        domesticServantsOption: { value: null, error: null, status: null },
        domesticServantsNo: { value: null, error: null, status: null },
        occupiersOption: { value: null, error: null, status: null },
        occupiersAmount: { value: null, error: null, status: null },
        eq_loading: {value: null, error: null, status: null},
        shareEmail: { value: null, error: null, status: null },
    },
};

export default (state = initQuote, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_INVALID_DOMESTIC_INSURANCE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };
        case FORM_VALID_DOMESTIC_INSURANCE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case FORM_VALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case DOMESTIC_INSURANCE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };

        case FORM_INVALID_DOMESTIC_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case SET_BUILDING_VALUE:
            return updateFormField('buildingSum', (field) => ({
                ...field,
                value: action.value,
            }));

        case CALCULATE_DOMESTIC_INSURANCE_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumCalculation: {
                    ...state.premiumCalculation,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        case SET_DOMESTIC_EARTHQUAKE_VALUE:
             return updateFormField('eq_loading', (field) => ({
                 ...field,
                 value: action.value,
             }));

        case SET_BUILDING_VALID:
            return updateFormField('buildingSum', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_BELONGINGS_VALUE:
            return updateFormField('contentsSum', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_BELONGINGS_VALID:
            return updateFormField('contentsSum', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_COVER_START_DATE_VALUE:
            return updateFormField('coverStartDate', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_COVER_START_DATE_VALID:
            return updateFormField('coverStartDate', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_ALL_RISK_VALUE:
            return updateFormField('allRisksOption', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_ALL_RISK_VALID:
            return updateFormField('allRisksOption', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_PORTABLE_VALUE:
            return updateFormField('risksSum', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_PORTABLE_VALID:
            return updateFormField('risksSum', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_DOMESTIC_SERVANTS_VALUE:
            return updateFormField('domesticServantsOption', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_DOMESTIC_SERVANTS_VALID:
            return updateFormField('domesticServantsOption', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_DOMESTIC_SERVANTS_NO_VALUE:
            return updateFormField('domesticServantsNo', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_DOMESTIC_SERVANTS_NO_VALID:
            return updateFormField('domesticServantsNo', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OWNERS_LIABILITY_VALUE:
            return updateFormField('ownersOption', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_OWNERS_LIABILITY_VALID:
            return updateFormField('ownersOption', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OWNERS_LIABILITY_AMOUNT_VALUE:
            return updateFormField('ownersSum', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_OWNERS_LIABILITY_AMOUNT_VALID:
            return updateFormField('ownersSum', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OCCUPIERS_LIABILITY_VALUE:
            return updateFormField('occupiersOption', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_OCCUPIERS_LIABILITY_VALID:
            return updateFormField('occupiersOption', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OCCUPIERS_LIABILITY_AMOUNT_VALID:
            return updateFormField('occupiersAmount', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OCCUPIERS_LIABILITY_AMOUNT_VALUE:
            return updateFormField('occupiersAmount', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SELECTED_COVER_VALUE:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SELECTED_COVER_VALID:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        // // Sidebar open
        // case UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE:
        //     return {
        //         ...state,
        //         sidebarOpen: action.value,
        //     };

        // Share modal
        case UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        // // API Calls
        // case API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
        //     return {
        //         ...state,
        //         apiCallStatus: API_CALL_STATUS.PENDING,
        //     };
        // case API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
        //     return {
        //         ...state,
        //         apiCallStatus: API_CALL_STATUS.SUCCESS,
        //     };
        // case API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
        //     return {
        //         ...state,
        //         apiCallStatus: API_CALL_STATUS.FAILURE,
        //     };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        // // Submit user data
        // case COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA:
        //     return {
        //         ...state,
        //         formStatus: FORM_STATUS.VALID,
        //     };

        // case SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS:
        //     return {
        //         ...state,
        //         quoteReferenceData: action.value,
        //     };
        // case UG_MOTOR_QUOTE_ADD_COVER:
        //     return {
        //         ...state,
        //         addedCover: action.value,
        //     };
        // case SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE:
        //     return {
        //         ...state,
        //         quoteReferenceData: action.error,
        //     };

        // case CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS:
        //     return {
        //         ...state,
        //         premiumCalculationUg: {
        //             ...state.premiumCalculationUg,
        //             value: action.value,
        //             status: API_CALL_STATUS.SUCCESS,
        //         },
        //     };

        // Reset state
        case REINITIALIZE:
            return initQuote;
        default:
            return state;
    }
};
