import { createSelector } from "reselect";
import {
  API_CALL_STATUS,
  FORM_STATUS,
  TOGGLE_STATUS,
} from "../../reducers/status";

export const getAsisaUnclaimedBenefits = (state) => {
  return state.publicWeb.asisaUnclaimedBenefits;
};

export const isFormValid = createSelector(
  getAsisaUnclaimedBenefits,
  (asisaUnclaimedBenefits) =>
    asisaUnclaimedBenefits.formStatus === FORM_STATUS.VALID
);

export const isApiStatusPending = createSelector(
  getAsisaUnclaimedBenefits,
  (asisaUnclaimedBenefits) =>
    asisaUnclaimedBenefits.apiSubmitStatus === API_CALL_STATUS.PENDING
);

export const isApiStatusSuccess = createSelector(
  getAsisaUnclaimedBenefits,
  (asisaUnclaimedBenefits) =>
    asisaUnclaimedBenefits.apiSubmitStatus === API_CALL_STATUS.SUCCESS
);

export const isApiStatusFailure = createSelector(
  getAsisaUnclaimedBenefits,
  (asisaUnclaimedBenefits) =>
    asisaUnclaimedBenefits.apiSubmitStatus === API_CALL_STATUS.FAILURE
);

export const isAsisaUnclaimedBenefitFoundForUser = createSelector(
  getAsisaUnclaimedBenefits,
  (asisaUnclaimedBenefits) => asisaUnclaimedBenefits.userFound === true
);

export const isSideDrawerOpen = createSelector(
  getAsisaUnclaimedBenefits, 
  (asisaUnclaimedBenefits) => asisaUnclaimedBenefits.sideDrawerOpen === true
)

export const getAsisaUnclaimedBenefitsForm = createSelector(
  getAsisaUnclaimedBenefits,
  (asisaUnclaimedBenefits) => asisaUnclaimedBenefits.form
);

export const getAsisaUnclaimedBenefitsLegalEntity = createSelector(
  getAsisaUnclaimedBenefits,
  (asisaUnclaimedBenefits) => asisaUnclaimedBenefits.legalEntity
);

export const hasValidSaIdNumber = createSelector(
  getAsisaUnclaimedBenefitsForm,
  (asisaUnclaimedBenefitsForm) =>
    asisaUnclaimedBenefitsForm.hasValidSaIdNumber === TOGGLE_STATUS.YES
);

export const hasAcceptedTermsAndConditions = createSelector(
  getAsisaUnclaimedBenefitsForm,
  (asisaUnclaimedBenefitsForm) =>
    asisaUnclaimedBenefitsForm.hasAcceptedTermsAndConditions ===
    TOGGLE_STATUS.YES
);

const makeField = (fieldId) => createSelector(
    getAsisaUnclaimedBenefitsForm,
    asisaUnclaimedBenefitsForm => asisaUnclaimedBenefitsForm[fieldId]
)

export const getSAIdNumber = makeField("saIdNumber")
export const getFirstname = makeField("firstname")
export const getSurname = makeField("surname")
export const getDateOfBirth = makeField("dateOfBirth")
export const getGender = makeField("gender")
export const getRecaptcha = makeField("recaptcha")
