import {SUBMIT_CUSTOMER_FEEDBACK} from "../../../actions/customerSatisfactionSurvey/customerSatisfactionSubmitForm";
import {customerSatisfactionSurveySubmitFormService} from "./customerSatisfactionSurvey";

export const customerSatisfactionSurveyService = (store, next, action) => {
    if (action.type === SUBMIT_CUSTOMER_FEEDBACK) {
        return customerSatisfactionSurveySubmitFormService(store, next, action);
    }

    return null;
};
