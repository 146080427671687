import { getTaxFreeSavingsAccount } from "./index";
import { createSelector } from "reselect";
import { ADDRESS_TYPES } from "../../actions/taxFreeSavingsAccount/types";

export const getReferenceData = createSelector(getTaxFreeSavingsAccount, tfsa => tfsa.referenceData);
export const getReferenceDataData = createSelector(
    getReferenceData,
    referenceData => referenceData.data,
);

const makeGetField = fieldId => createSelector(
    getReferenceDataData,
    data => data[fieldId]
);

export const getReferenceBanks = makeGetField('banks');
export const getReferenceAccountTypes = makeGetField('bankAccountTypes');
export const getReferenceDebitOrderDays = makeGetField('debitOrderDays');
export const getReferenceDebitOrderDates = makeGetField('debitOrderDates');
export const getReferenceSourceOfIncome = makeGetField('sourceOfIncomes');
export const getReferenceSourceOfFunds = makeGetField('sourceOfFunds');
export const getReferenceEmploymentIndustry = makeGetField('employmentIndustries');
export const getReferenceEmploymentPosition = makeGetField('employmentPositions');
export const getReferenceGenders = makeGetField('genders');

export const getReferenceTitles = makeGetField('titles');
export const getReferenceMaritalStatuses = makeGetField('maritalStatuses');
export const getReferenceCountries = makeGetField('countries');
export const getReferenceNationalitiesList = makeGetField('nationalities');

export const getRefenceNationalitiesList = makeGetField('nationalities');
export const getReferenceRelationshipTypes = makeGetField('relationshipTypes');

export const getReferencePostalMethods = () => {
    return [
        { description: 'Street Address', code: ADDRESS_TYPES.STREET_ADDRESS },
        { description: 'PO Box', code: ADDRESS_TYPES.PO_BOX },
        { description: 'Private Bag', code: ADDRESS_TYPES.PRIVATE_BAG },
    ]
};

export const getReferenceNationalities = () => { return [{description: 'South Africa', code: 'SouthAfrica'}]; };

// Validates the application reference data
export const getIsReferenceDataValid = createSelector(
    getReferenceDataData,
    applicationReferenceData => {
        return applicationReferenceData &&
            Object.keys(applicationReferenceData).length !== 0 &&
            applicationReferenceData.titles !== 0 &&
            applicationReferenceData.banks !== 0;
    }
);

export const getIsErrorModalOpen = createSelector(
    getReferenceData,
    refData => refData.errorModal
);
