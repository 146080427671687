import { createSelector } from 'reselect';
import { getUgMotorInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDeclarations = createSelector(getUgMotorInsuranceState, (state) => state.declarations);
export const getDeclarationsForm = createSelector(getDeclarations, (declarations) => declarations.form);

export const getDropOffDetails = createSelector(getDeclarations, (declarations) => declarations.dropOffDetails);

export const getDeclarationsFormValid = createSelector(
    getDeclarations,
    (declarations) => declarations.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getDeclarations,
    (declarations) => declarations.formStatus === API_CALL_STATUS.PENDING
);

const makeGetFieldForm = (fieldId) => createSelector(getDeclarationsForm, (form) => form[fieldId]);

export const getDeclineProposalValue = makeGetFieldForm('declineProposal');
export const getIncreasedPremiumValue = makeGetFieldForm('increasedPremiums');
export const getPolicyRejectedValue = makeGetFieldForm('policyRejected');
export const getDefectVisionValue = makeGetFieldForm('defectVision');
export const getConvictedValue = makeGetFieldForm('convicted');
export const getStickerCollectionValue = makeGetFieldForm('stickerCollection');
export const getModeOfPaymentValue = makeGetFieldForm('modeOfPayment');
export const getSourceOfFundsValue = makeGetFieldForm('sourceOfFunds');
export const getOtherSourceOfFundsValue = makeGetFieldForm('otherSourceOfFunds');
