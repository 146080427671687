import {
    BUILDING_SET_VALUE,
    CITY_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    EMAIL_SET_VALUE,
    TIN_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    PO_BOX_SET_VALUE,
    POSTAL_CODE_SET_VALUE,
    setBuildingValid,
    setCityValid,
    setFirstNameValid,
    setEmailValid,
    setTinNumberValid,
    setMobileNumberValid,
    setPostalBoxValid,
    setPostalCodeValid,
    setStreetAddressValid,
    STREET_ADDRESS_SET_VALUE,
    SURNAME_SET_VALUE,
    setSurnameValid,
    DOB_SET_VALUE,
    setDobValid,
    ID_PASSPORT_SET_VALUE,
    setIdPassportValid,
    RELATIONSHIP_SET_VALUE,
    setRelationshipValid,
    SPLIT_PERCENTAGE_SET_VALUE,
    setSplitPercentageValid,
} from '../../../actions/healthInsuranceRW/personalDetailsBeneficiaries';
import { validationIsEqual } from '../status';
import {
    validateEmail,
    validateFirstName,
    validateSurname,
    validateContactNumber,
    validateAlphaNumericString,
    validatePostalCode,
    validateDateOfBirth,
    validateName,
    validateNumericString,
    validatePassportNumber,
    validateAlphaString,
    validateNumber,
} from '@om/validation';

let prevValidationAction = null;
export const personalDetailsBeneficiariesValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case RELATIONSHIP_SET_VALUE:
                error = validateAlphaString(action.value, {
                    subject: 'Relationship',
                    required: true,
                });
                return setRelationshipValid(error);
            case FIRST_NAME_SET_VALUE:
                error = validateFirstName(action.value, {
                    subject: 'First Name',
                    required: true,
                });
                return setFirstNameValid(error);
            case SURNAME_SET_VALUE:
                error = validateSurname(action.value, {
                    subject: 'Surname',
                    required: true,
                });
                return setSurnameValid(error);
            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, {
                    subject: 'Date of Birth',
                    required: true,
                });
                return setDobValid(error);

            case TIN_NUMBER_SET_VALUE:
                error = validateNumericString(action.value, { subject: 'Tin Number', min: 9, max: 9, required: true });
                return setTinNumberValid(error);

            case MOBILE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, {
                    subject: 'Mobile Number',
                    required: true,
                });
                return setMobileNumberValid(error);

            case ID_PASSPORT_SET_VALUE:
                error = validateNumericString(action.value, {
                    min: 16,
                    max: 16,
                    subject: 'ID number',
                    required: true,
                });
                return setIdPassportValid(error);

            case EMAIL_SET_VALUE:
                error = validateEmail(action.value, {
                    min: 6,
                    max: 200,
                    subject: 'Email address',
                    required: true,
                });
                return setEmailValid(error);

            case CITY_SET_VALUE:
                error = validateName(action.value, {
                    subject: 'City',
                    required: true,
                });
                return setCityValid(error);

            case SPLIT_PERCENTAGE_SET_VALUE:
                error = validateNumericString(action.value, {
                    subject: 'Split Percentage',
                    required: true,
                });
                return setSplitPercentageValid(error);

            case STREET_ADDRESS_SET_VALUE:
                error = validateAlphaNumericString(action.value, { subject: 'Address', required: true });
                return setStreetAddressValid(error);

            case BUILDING_SET_VALUE:
                error = validateAlphaNumericString(action.value, { subject: 'Building', required: false });
                return setBuildingValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
