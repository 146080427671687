// Transition Event
const SELECT_A_FUND_TRANSITION = 'taxFreeSavingsAccount/selectAFund/transition/';

export const DESELECT_ALL_FUNDS_TRANSITION_SELECT_A_FUND = SELECT_A_FUND_TRANSITION + 'deselectAllFunds';
export const deselectAllFunds = () => ({ type: DESELECT_ALL_FUNDS_TRANSITION_SELECT_A_FUND });

// Events - form input
export const SELECT_A_FUND_SET_VALUE = 'taxFreeSavingsAccount/selectAFund/setValue/';
export const SELECT_A_FUND_SET_VALID = 'taxFreeSavingsAccount/selectAFund/setValid/';

export const FUND_SET_VALUE = SELECT_A_FUND_SET_VALUE + 'selectedFund'
export const fundSetValue = value => ({ type: FUND_SET_VALUE, value });

export const FUND_SET_VALID = SELECT_A_FUND_SET_VALID + 'selectedFund'
export const fundSetValid = error => ({ type: FUND_SET_VALID, error });

export const RESET_SELECT_A_FUND = SELECT_A_FUND_SET_VALUE + 'resetState';
export const resetSelectAFund = () => ({ type: RESET_SELECT_A_FUND });