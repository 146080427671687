import { validateNumber, validateDateOfBirthNextBirthDay} from  "@om/validation";
import {
    setCandidateDOBValid,
    CANDIDATE_DOB_SET_VALUE,
    setCoveredAmountValid,
    COVERED_AMOUNT_SET_VALUE,
} from "../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool";

import { validationIsEqual } from "../status";

let prevValidationAction = null;

export const wholeLifeCoverQuoteToolValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case CANDIDATE_DOB_SET_VALUE:
                error = validateDateOfBirthNextBirthDay(action.value, { min: 18, max: 64 });
                return setCandidateDOBValid(error);
            case COVERED_AMOUNT_SET_VALUE:
                error = validateNumber(action.value, {min: 1000000});
                return setCoveredAmountValid(error);
           
            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction) && action.value.length > 1)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
