import {
    transitionWhenWillYouReachYourSavingsGoal,
    REACH_SAVINGS_GOAL_SET_VALUE
} from "../../../actions/savingsCalculator/whenWillYouReachYourSavingsGoal";
import {
    getWhenWillYouReachYourSavingsGoalForm,
} from "../../../selectors/savingsCalculator/whenWillYouReachYourSavingsGoal";
import {transitionScrolling} from "../../../actions/incomeTaxCalculator/scrolling";
import {FIELD_STATUS} from "../../../reducers/status";

export const whenWillYouReachYourSavingsGoalFormRules = (store, next, action) => {
    const result = next(action);

    const whenWillYouReachYourSavingsGoalForm = getWhenWillYouReachYourSavingsGoalForm(store.getState());
    const whenWillYouReachYourSavingsGoalValid = validateFormDetails(whenWillYouReachYourSavingsGoalForm);
    const formAction = whenWillYouReachYourSavingsGoalValid
        ? transitionWhenWillYouReachYourSavingsGoal.formValid()
        : transitionWhenWillYouReachYourSavingsGoal.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFields.savingsGoal && formFields.savingsGoal.status === FIELD_STATUS.VALID &&
    formFields.currentSavingsGoal && formFields.currentSavingsGoal.status === FIELD_STATUS.VALID &&
    formFields.expectedMonthlyContributions && formFields.expectedMonthlyContributions.status === FIELD_STATUS.VALID &&
    formFields.annualIncreaseInSavings && formFields.annualIncreaseInSavings.status === FIELD_STATUS.VALID &&
    formFields.expectedSavingsGrowth && formFields.expectedSavingsGrowth.status === FIELD_STATUS.VALID
);
