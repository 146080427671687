import axios from 'axios';
import { setAdvisorsData } from '../../../actions/publicWeb/findAnAdvisor';

const header = {
    'Content-Type': 'application/json',
};

export const findAnAdvisorService = (store, next, action) => {
    const result = next(action);
    const bodyParams = createBodyParams(action);

    (async () => {
        await sendToGetAdvisors(store, bodyParams);
    })();

    return result;
};

export const sendToGetAdvisors = async (store, bodyParams) => {
    return await axios
        .post('/om-api/find-an-adviser/getadvisors', bodyParams, {
            headers: header,
        })
        .then((result) => {
            console.info(result);
            store.dispatch(setAdvisorsData(result.data));
        })
        .catch((error) => {
            console.error(error);
            store.dispatch(
                setAdvisorsData({
                    Succeeded: false,
                    Data: [],
                    Errors: [JSON.stringify(error)],
                })
            );
        });
};

export const createBodyParams = (action) => {
    return {
        advisorType: action.payload.advisorType,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        locationName: action.payload.locationName,
        radius: action.payload.radius,
        services: action.payload.services,
    };
};
