import { transitionAfyaImaraGetrudeJuniorTool } from '../../../actions/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool';
import { getAfyaImaraGertrudeJuniorQuoteToolForm } from '../../../selectors/roaQuoteTools/afyaImaraGertrudeJuniorQuoteTool';
import { FIELD_STATUS } from '../../../reducers/status';

export const afyaImaraGertrudeQuoteToolFormRules = (store, next, action) => {
  const result = next(action);

  const afyaImaraGertrudeJuniorQuoteToolForm = getAfyaImaraGertrudeJuniorQuoteToolForm(
    store.getState(),
  );

  const afyaImaraGertrudeJuniorQuoteToolValid = validateAfyaImaraGertrudeQuoteToolFormDetails(
    afyaImaraGertrudeJuniorQuoteToolForm,
  );

  const formAction = afyaImaraGertrudeJuniorQuoteToolValid
    ? transitionAfyaImaraGetrudeJuniorTool.formValid()
    : transitionAfyaImaraGetrudeJuniorTool.formInvalid();

  store.dispatch(formAction);

  return result;
};

const validateAfyaImaraGertrudeQuoteToolFormDetails = formFields => {
  return (
    formFields.numberOfChildren &&
    formFields.numberOfChildren.status === FIELD_STATUS.VALID
  );
};
