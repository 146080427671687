import { createSelector } from 'reselect';
import { getCustomerComplaint } from './index';
import {API_CALL_STATUS} from '@om/redux/reducers/status';
import {IDENTITY_TYPE} from '@om/redux/actions/customerComplaint/type'

export const getComplaintArbitration = createSelector(
    getCustomerComplaint,
    (customerComplaint) => customerComplaint.complaintArbitration
);

export const getDrawerIsOpen = createSelector(
    getComplaintArbitration,
    (customerComplaint) => customerComplaint.openDrawer
);

export const getComplaintType = createSelector(
    getComplaintArbitration,
    (customerComplaint) => customerComplaint.complaintType
);

export const getSendStatus = createSelector(
    getComplaintArbitration,
    (customerComplaint) => customerComplaint.sendComplaintStatus
);

export const getFormSuccess = createSelector(
    getComplaintArbitration,
    customerComplaint => customerComplaint.sendComplaintStatus === API_CALL_STATUS.SUCCESS
);

export const getFormError = createSelector(
    getComplaintArbitration,
    customerComplaint => customerComplaint.sendComplaintStatus === API_CALL_STATUS.FAILURE
);

export const getFormStatus = createSelector(
    getComplaintArbitration,
    (customerComplaint) => customerComplaint.formStatus
);

export const getForm = createSelector(
    getComplaintArbitration,
    (customerComplaint) => customerComplaint.form
);

export const getInputDisabled = createSelector(
    getComplaintArbitration,
    customerComplaint => customerComplaint.sendComplaintStatus === API_CALL_STATUS.PENDING
        || customerComplaint.sendComplaintStatus === API_CALL_STATUS.SUCCESS,
);

export const getUsingId = createSelector(
    getComplaintArbitration,
    customerComplaint => (customerComplaint.form.identityType &&
     customerComplaint.form.identityType.value === IDENTITY_TYPE.SA_ID)
);

export const getPassportRequired = createSelector(
    getComplaintArbitration,
    customerComplaint => (customerComplaint.form.passportNumber.value || customerComplaint.form.passportCountry.value) ? true : false
);

export const getButtonText = createSelector(
    getComplaintArbitration,
    customerComplaint => {
        switch (customerComplaint.sendComplaintStatus) {
            case API_CALL_STATUS.SUCCESS:
                return 'Sent';
            case API_CALL_STATUS.FAILURE:
                return 'Try Again';
            case API_CALL_STATUS.PENDING:
                return 'Sending...';
            default:
                return 'Submit';
        }
    }
);

export const getReceiverEmailAddress = createSelector(
    getComplaintArbitration,
    (customerComplaint) => customerComplaint.receiverEmailAddress
);

const makeGetField = fieldId => createSelector(
    getForm,
    complaintArbitrationForm => complaintArbitrationForm[fieldId]
);

export const getTitle = makeGetField('title');
export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getCellphoneNumber = makeGetField('cellphoneNumber');
export const getEmail = makeGetField('email');
export const getIdentityType = makeGetField('identityType');
export const getIdNumber = makeGetField('idNumber');
export const getPassportNumber = makeGetField('passportNumber');
export const getPassportCountry = makeGetField('passportCountry');
export const getPolicyNumber = makeGetField('policyNumber');
export const getProductType = makeGetField('productType');
export const getComplaintRelation = makeGetField('complaintRelation');
export const getComplaintTitle = makeGetField('complaintTitle');
export const getComplaintDescription = makeGetField('complaintDescription');
export const getSendMeCopy = makeGetField('sendMeCopy');
