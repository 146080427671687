import { transitionPersonalPensionPlanPersonalDetailsForm } from '../../../actions/personalPensionPlan/personalDetails';
import { getPersonalDetailsForm } from '../../../selectors/personalPensionPlan/personalDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const personalDetailsFormRules = (store, next, action) => {
    const result = next(action);
    const personalDetailsForm = getPersonalDetailsForm(store.getState());
    const personalDetailsFormValid = validatePersonalDetailsFormDetails(personalDetailsForm);

    const formAction = personalDetailsFormValid
        ? transitionPersonalPensionPlanPersonalDetailsForm.formValid()
        : transitionPersonalPensionPlanPersonalDetailsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
    return (
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.gender &&
        formFields.gender.status === FIELD_STATUS.VALID &&
        formFields.occupation &&
        formFields.occupation.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.tinNumber &&
        formFields.tinNumber.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID
    );
};
