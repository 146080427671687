import { createSelector } from 'reselect';
import { getHealthInsuranceRwState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getMedicalHistory = createSelector(
    getHealthInsuranceRwState,
    state => state.medicalHistory
);

const makeGetMedicalHistoryField = fieldName => 
    createSelector(getMedicalHistory, medicalHistory => medicalHistory[fieldName]);

export const getMedicalHistoryForm = makeGetMedicalHistoryField('form');

export const getMedicalHistoryFormValid = createSelector(
    getMedicalHistory, 
    medicalHistory => medicalHistory.formStatus === FORM_STATUS.VALID,
)

export const getMedicalHistoryArray = createSelector(
    getMedicalHistory, 
    medHist => medHist.medicalHistory,
)


const makeGetFieldForm = (fieldId) => createSelector(getMedicalHistoryForm, (form) => form[fieldId]);

export const getRelationshipValue = makeGetFieldForm('relationship');
export const getNamesValue = makeGetFieldForm('names');
export const getIdPassportValue = makeGetFieldForm('idPassport');
export const getOtherValue = makeGetFieldForm('other');

