import {
    PERFORM_AUTO_COMPLETE,
    PERFORM_FREQUENT,
    PERFORM_QUICKLINKS,
    PERFORM_SEARCH,
} from '../../../actions/publicWeb/search';
import { searchService } from './search';
import {
    REQUEST_INDICATORS,
    REQUEST_JHB_SHAREPRICE,
    REQUEST_LDN_SHAREPRICE,
} from '../../../actions/publicWeb/sharePrice';
import {
    requestIndicators,
    requestJhbSharePrice,
    requestLdnSharePrice,
} from './sharePrice';
import { SAVE2GETHER_SEND_EMAIL } from '../../../actions/publicWeb/save2gether';
import { save2getherService } from './save2gether';
import { GET_BUSINESS_PRODUCTS } from '../../../actions/publicWeb/businessProducts';
import { businessProductservice } from './businessProducts';
import { SIGNUP_SEND_DETAILS } from '../../../actions/publicWeb/signupAndDownload';
import { signupAndDownloadService } from './signupAndDownload';
import { CALL_ME_BACK_SEND_ACTION } from '../../../actions/publicWeb/callMeBack';
import { callMeBackService } from './callMeBack';
import { CALCULATE_DIVIDENDS } from '../../../actions/publicWeb/dividendCalculator';
import { dividendCalculatorService } from './dividendCalculator';
import { UNCLAIMED_BENEFITS_SET_VALUE } from '../../../actions/publicWeb/unclaimedBenefits';
import { unclaimedBenefitsService } from './unclaimedBenefits';
import { FUNERAL_CLAIM_SUBMIT_FORM } from '../../../actions/publicWeb/funeralClaim';
import { funeralClaimService } from './funeralClaim';
import { 
    FUNERAL_CLAIM_V2_SUBMIT_FORM, 
    FUNERAL_CLAIM_DHA_API_REQUEST,
    FUNERAL_CLAIM_BANK_CDV_REQUEST, 
    FUNERAL_CLAIM_PRESUBMIT_REQUEST} from '../../../actions/publicWeb/funeralClaimV2';
import { funeralClaimV2Service } from './funeralClaimV2';
import { CONTINUE_TRANSITION_QUALIFYING_CHECK } from '../../../actions/publicWeb/qualifyingCheckCallMeBack';
import { qualifyingCheckLeads } from './qualifyingCheckCallMeBack';
import {
    PRICE_GRAPH_FETCH_FUNDS_LIST,
    PRICE_GRAPH_FETCH_FUND_DATA,
} from '../../../actions/publicWeb/priceGraph';
import { priceGraphService } from './priceGraph';
import { ASISA_UNCLAIMED_BENEFITS_FORM_SUBMIT } from '../../../actions/publicWeb/asisaUnclaimedBenefits';
import { asisaUnclaimedBenefitsService } from './asisaUnclaimedBenefits';
import { onTheMoneyService } from './onTheMoney';
import {
    ONTHEMONEY_CALENDAREVENTS_FETCH,
    ONTHEMONEY_ENROLLEDCOURSES_FETCH,
} from '../../../actions/publicWeb/onTheMoney';
import { findAnAdvisorService } from './findAnAdvisor';
import { GET_ADVISORS_DATA } from '../../../actions/publicWeb/findAnAdvisor';

export const publicWebService = (store, next, action) => {
    // Search
    if (
        action.type === PERFORM_AUTO_COMPLETE ||
        action.type === PERFORM_FREQUENT ||
        action.type === PERFORM_QUICKLINKS ||
        action.type === PERFORM_SEARCH
    ) {
        return searchService(store, next, action);
    }

    if (action.type === REQUEST_JHB_SHAREPRICE)
        return requestJhbSharePrice(store);

    if (action.type === REQUEST_LDN_SHAREPRICE)
        return requestLdnSharePrice(store);

    if (action.type === REQUEST_INDICATORS) return requestIndicators(store);

    // Save2gether
    if (action.type === SAVE2GETHER_SEND_EMAIL)
        return save2getherService(store, next, action);

    // Business Products
    if (action.type === GET_BUSINESS_PRODUCTS)
        return businessProductservice(store, next, action);

    // Signup and download
    if (action.type === SIGNUP_SEND_DETAILS)
        return signupAndDownloadService(store, next, action);

    // Call Me Back
    if (action.type === CALL_ME_BACK_SEND_ACTION)
        return callMeBackService(store, next, action);

    // Dividend Calc
    if (action.type === CALCULATE_DIVIDENDS)
        return dividendCalculatorService(store, next, action);

    // Unclaimed Benefits
    if (action.type.startsWith(UNCLAIMED_BENEFITS_SET_VALUE))
        return unclaimedBenefitsService(store, next, action);

    // Funeral Claim
    if (action.type === FUNERAL_CLAIM_SUBMIT_FORM)
        return funeralClaimService(store, next, action);

    // Funeral Claim V2
    if (
        action.type === FUNERAL_CLAIM_V2_SUBMIT_FORM ||
        action.type === FUNERAL_CLAIM_DHA_API_REQUEST ||
        action.type === FUNERAL_CLAIM_BANK_CDV_REQUEST ||
        action.type === FUNERAL_CLAIM_PRESUBMIT_REQUEST
    ) {
        return funeralClaimV2Service(store, next, action);
    }

    //Qualifying check CMB
    if (action.type === CONTINUE_TRANSITION_QUALIFYING_CHECK)
        return qualifyingCheckLeads(store, next, action);

    if (
        action.type === PRICE_GRAPH_FETCH_FUNDS_LIST ||
        action.type === PRICE_GRAPH_FETCH_FUND_DATA
    )
        return priceGraphService(store, next, action);

    if (action.type === ASISA_UNCLAIMED_BENEFITS_FORM_SUBMIT)
        return asisaUnclaimedBenefitsService(store, next, action);

    if (
        action.type === ONTHEMONEY_ENROLLEDCOURSES_FETCH ||
        action.type === ONTHEMONEY_CALENDAREVENTS_FETCH
    ) {
        return onTheMoneyService(store, next, action);
    }

    //Find An Advisor
    if (action.type === GET_ADVISORS_DATA) {
        return findAnAdvisorService(store, next, action);
    }
    
    return null;
};
