import {
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
} from "../../../actions/easiplusFuneralPlan/routing";
import {
    getActivePageIndex
} from "../../../selectors/easiplusFuneralPlan/routing";
import { getPersonalDetailsForm } from"../../../selectors/easiplusFuneralPlan/personalDetails";
import { getReplacementPolicyForm } from "../../../selectors/easiplusFuneralPlan/replacementPolicy";
import { getLivesCoveredForm } from "../../../selectors/easiplusFuneralPlan/livesCovered";
import {
    getEditBeneficiaryForm} from "../../../selectors/easiplusFuneralPlan/beneficiaries";
import { getBeneficiariesSplitForm } from "../../../selectors/easiplusFuneralPlan/beneficiariesSplit";
import { getPaymentDetailsForm } from "../../../selectors/easiplusFuneralPlan/paymentDetails";
import { PAGE_INDICES } from "../../../actions/easiplusFuneralPlan/types";
import {transitionTracking} from "../transitionTracking";

export const routingTracking = (store, next, action) => {
    const state = store.getState();
    const pageIndex = getActivePageIndex(state);
    const result = next(action);

    const transitionTrackingActions = [
        NAVIGATE_NEXT,
        NAVIGATE_PREVIOUS,
    ];

    const formSelector =(() => {
        switch (pageIndex) {
            case PAGE_INDICES.REPLACEMENT_POLICY:
                return getReplacementPolicyForm(state);
            case PAGE_INDICES.PERSONAL_DETAILS:
                return getPersonalDetailsForm(state);
            case PAGE_INDICES.LIVES_COVERED:
                return getLivesCoveredForm(state);
            case PAGE_INDICES.BENEFICIARIES:
                return getEditBeneficiaryForm(state);
            case PAGE_INDICES.BENEFICIARIES_SPLIT:
                return getBeneficiariesSplitForm(state);
            case PAGE_INDICES.PAYMENT_DETAILS:
                return getPaymentDetailsForm(state);
            default: return null;
        }
    })();

    if(formSelector) {
        const trackingData = Object.keys(formSelector)
            .reduce((acc, key) => ({
                ...acc,
                [key]: {
                    status: formSelector[key].status,
                    error: formSelector[key].error,
                }
            }), {});

        if(transitionTrackingActions.includes(action.type))
            transitionTracking(action.type, trackingData);
    }

    return result;
}