import { combineReducers } from 'redux';
import routing from './routing';
import quotation from './quotation';
import aboutYou from './aboutYou';
import details from './details';
import payments from './payment';
import product from './product';
import agentReferral from "./agentReferral";
import vehicleDetails from "./vehicleDetails";
import persistedAt from './persistedAt';

export const roaCarInsurance = combineReducers({
    routing,
	quotation,
	aboutYou,
	details,
	payments,
	product,
	agentReferral,
	vehicleDetails,
	persistedAt
});
