import { createSelector } from 'reselect';
import { getInsuranceTypeForm, getIsInsurenceTypeSelected } from './insuranceType';
import { getAllsureInsurance } from '.';
import { getPersonalDetailsForm } from './personalDetails';
import { getHomeContentList } from './homeContents';

export const getIsHomeOwnerSelected = createSelector(getInsuranceTypeForm, (insureTypeForm) => {
    return insureTypeForm.homeOwners.value === 'primary';
});

export const getHomeDetails = createSelector(getAllsureInsurance, (allsureInsurance) => allsureInsurance.homeDetails);
export const getGlobalPaymentFrequency=createSelector(getHomeDetails,(homeDetails)=>homeDetails?.paymentFrequency)
export const getHomeDetailsForm = createSelector(
    getAllsureInsurance,
    (allsureInsurance) => allsureInsurance.homeDetails.form
);

export const getHomeDetailsList = createSelector(getHomeDetails, (homeDetails) => homeDetails?.homeDetailsList);
export const geteditKeyDetails = createSelector(getHomeDetails, (homeDetails) => homeDetails.editHomeDetailKey);
// Get payment frequency for all quotes

export const getHomeDetailsObject = createSelector(getHomeDetails,getGlobalPaymentFrequency, (homeDetails,paymentFrequency) => {
    return homeDetails?.homeDetailsList?.map(
        ({
            address,
            otherAddress,
            numberOfBedrooms,
            numberOfBathrooms,
            guiserSituated,
            // domesticWorker,
            homeOccupied,
            razorWire,
            // householdLoss,
            // claimedCount,
            // claimAmount,
            homehired,
            inRetirement,
            roofMaterialDetails,
            mainWallConstructedOff,
            homeDetailsType,
            insuredDetails,
            insuredWithOM,
            houseValue,
            accidentalDamage,
            accidentalDamageAmount,
            powerSurge,
            powerSurgeCover,
            policyNumber
        }) => {
            
            const homeDetails = [
                {
                    label: 'What is the location of the property to be insured?',
                    value: address.value === 'Other' ? otherAddress.value : address.value,
                },
                { label: 'Number of bedrooms', value: numberOfBedrooms.displayValue ?? '-' },
                { label: 'Number of bathrooms', value: numberOfBathrooms.displayValue ?? '-' },
                { label: 'Geyser situated', value: guiserSituated.displayValue ?? '-' },
                // {
                //     label: 'Do you have a domestic worker at home while you are at work?',
                //     value: domesticWorker.displayValue?? '-',
                // },
                { label: homehired?.value ==='Yes' ? 'Is the house occupied while the tenant is at work?' : 'Is your home occupied while you’re at work?', value: homeOccupied.displayValue ?? '-' },
                {
                    label: 'Does your wall/fence have razor wire or electric fencing?',
                    value: razorWire.displayValue ?? '-',
                },
                // {
                //     label: 'Have you suffered any loss of household content in the last 3 years?',
                //     value: householdLoss.displayValue ?? '-',
                // },
                // {
                //     label: 'How many times have you claimed in the last 3 years',
                //     value: claimedCount.displayValue ?? '-',
                // },
                // {
                //     label: 'Enter the total value of the claim(s) paid out.',
                //     value: 'BWP' + claimAmount.displayValue ?? '-',
                // },
                { label: 'Will your home be rented out?', value: homehired.displayValue ?? '-' },
                { label: 'Are you currently in retirement?', value: inRetirement.displayValue ?? '-' },
                { label: 'How often would like to pay your premium?', value: paymentFrequency ?? '-' },
                { label: 'What material is the roof made of?', value: roofMaterialDetails.displayValue ?? '-' },
                { label: 'What are the main walls constructed of?', value: mainWallConstructedOff.displayValue ?? '-' },
                { label: 'What type of home do you have?', value: homeDetailsType.displayValue ?? '-' },
                // {
                //     label: 'How many years of uninterrupted insurance cover have you had for your home?',
                //     value: insuredDetails.displayValue ?? '-',
                // },
                // { label: 'How long you have been insured with Old mutual?', value: insuredWithOM.displayValue ?? '-' },
                // ...(insuredWithOM?.value>0 ? [{ label: 'Policy number', value: policyNumber?.value ?? '-' }]:[]),
                { label: 'What is the replacement value of your house? (In P)', value: 'P' + houseValue.displayValue ?? '-' },
                {
                    label: 'Do you require accidental damage cover for your home?',
                    value: accidentalDamage.displayValue ?? '-',
                },
                {
                    label: 'Please select your cover amount.',
                    value: (accidentalDamageAmount.displayValue && accidentalDamage?.displayValue==='Yes') ?"P"+accidentalDamageAmount.displayValue: '-',
                },
                {
                    label: 'Do you require Power Surge cover?',
                    value: powerSurge.displayValue ?? '-',
                },
                {
                    label: 'Please select your cover amount.',
                    value: (powerSurgeCover.displayValue && powerSurge?.displayValue==='Yes') ?"P"+powerSurgeCover.displayValue: '-',
                },
               
            ];
            // householdLoss.value === 'No' && homeDetails.splice(8, 2);
            return homeDetails;
        }
    );
});

export const getReferenceData = () => {
    return {
        bedsDropdownOptions: [
            {
                code: '1',
                description: '1',
            },
            {
                code: '2',
                description: '2',
            },
            {
                code: '3',
                description: '3',
            },
            {
                code: '4',
                description: '4',
            },
            {
                code: '5',
                description: '5+',
            },
        ],
        roofDropdownOptions: [
            // {
            //     code: 'standard',
            //     description: 'Standard',
            // },
            {
                code: 'asbestos',
                description: 'Asbestos',
            },
            {
                code: 'thatch',
                description: 'Thatch',
            },
            {
                code: 'tile',
                description: 'Tile',
            },
            {
                code: 'slate',
                description: 'Slate',
            },
            {
                code: 'metal',
                description: 'Metal',
            },
            {
                code: 'concrete',
                description: 'Concrete',
            },
            {
                code: 'fibreCementSheets',
                description: 'Fibre Cement Sheets',
            },
            {
                code: 'shingles',
                description: 'Shingles',
            },
            {
                code: 'other',
                description: 'Other',
            },
        ],
        wallDetails: [
            {
                code: 'concrete',
                description: 'Brick/Stone or Concrete',
            },
            {
                code: 'asbestos',
                description: 'Asbestos or Timber Clad',
            },
            {
                code: 'metal',
                description: 'Metal Frame and Fiber Glass',
            },
            {
                code: 'timber',
                description: 'Timber/Part Timber Framed Metal',
            },
            {
                code: 'sandwichPanel',
                description: 'Prefabricated Sandwich Panels',
            },
        ],
        homeTypeDetails: [
            {
                code: 'semiDetached',
                description: 'Semi-detached House/ Cottage',
            },
            {
                code: 'detached',
                description: 'Detached House/Cottage',
            },
            {
                code: 'flatAboveFirst',
                description: 'Flat above 1st Floor',
            },
            {
                code: 'flatGroundFirst',
                description: 'Flat Ground Floor/1st Floor',
            },
            {
                code: 'commune',
                description: 'Commune',
            },
            {
                code: 'mobileHome',
                description: 'Mobile Home',
            },
            {
                code: 'vacantProperty',
                description: 'Vacant Property',
            },
            {
                code: 'hostel',
                description: 'Hostel',
            },
        ],
        numberOfTimeInsured: [
            {
                code: '00',
                description: '0',
            },
            {
                code: '01',
                description: '1',
            },
            {
                code: '02',
                description: '2',
            },
            {
                code: '03',
                description: '3',
            },
            {
                code: '04',
                description: '4',
            },
            {
                code: '05',
                description: '5',
            },
            {
                code: '06',
                description: '6',
            },
            {
                code: '07',
                description: '7',
            },
            {
                code: '08',
                description: '8',
            },
            {
                code: '09',
                description: '9',
            },
            {
                code: '10',
                description: '10',
            },
            {
                code: '11',
                description: '11',
            },
            {
                code: '12',
                description: '12',
            },
            {
                code: '13',
                description: '13',
            },
            {
                code: '14',
                description: '14',
            },
            {
                code: '15',
                description: '15',
            },
            {
                code: '16',
                description: '16',
            },
            {
                code: '17',
                description: '17',
            },
            {
                code: '18',
                description: '18',
            },
            {
                code: '19',
                description: '19+',
            },
        ],
        omInsure: [
            {
                code: '0',
                description: '0',
            },
            {
                code: '1',
                description: '1',
            },
            {
                code: '2',
                description: '2',
            },
            {
                code: '3',
                description: '3',
            },
        ],
        locationDetails: [
            {
                code: 'Other-NorthEastDistrict',
                description: 'Francistown',
            },
            {
                code: 'Selebi-Phikwe',
                description: 'Selebi-Phikwe',
            },
            {
                code: 'Serowe (Uban)',
                description: 'Serowe',
            },
            {
                code: 'Orapa',
                description: 'Orapa',
            },
            {
                code: "Parr's Halt Gate",
                description: "Parr's Halt Gate",
            },
            {
                code: 'Serule',
                description: 'Serule',
            },
            {
                code: 'Nata',
                description: 'Nata',
            },
            {
                code: 'Palapye',
                description: 'Palapye',
            },
            {
                code: 'Gweta',
                description: 'Gweta',
            },
            {
                code: 'Mamuno',
                description: 'Mamuno',
            },
            {
                code: 'Ghanzi',
                description: 'Ghanzi',
            },
            {
                code: 'Hukuntsi',
                description: 'Hukuntsi',
            },
            {
                code: 'Tshabong',
                description: 'Tshabong',
            },
            {
                code: 'Kang',
                description: 'Kang',
            },
            {
                code: 'Kgatleng',
                description: 'Kgatleng',
            },
            {
                code: 'Letlhakeng',
                description: 'Letlhakeng',
            },
            {
                code: 'Molepolole',
                description: 'Molepolole',
            },
            {
                code: 'Kasane',
                description: 'Kasane',
            },
            {
                code: 'Martins Drift',
                description: 'Martins Drift',
            },
            {
                code: 'Mohembo',
                description: 'Mohembo',
            },
            {
                code: 'Ngoma Gate',
                description: 'Ngoma Gate',
            },
            {
                code: 'Shakawe',
                description: 'Shakawe',
            },
            {
                code: 'Maun',
                description: 'Maun',
            },
            {
                code: 'Kazungula',
                description: 'Kazungula',
            },
            {
                code: 'Lobatse',
                description: 'Lobatse',
            },
            {
                code: 'Tlokweng Gate',
                description: 'Tlokweng Gate',
            },
            {
                code: 'Jwaneng',
                description: 'Jwaneng',
            },
            {
                code: 'Kanye',
                description: 'Kanye',
            },
            {
                code: 'Ramatlabama',
                description: 'Ramatlabama',
            },
            {
                code: 'Mahalapye',
                description: 'Mahalapye',
            },
            {
                code: 'Ramokgwebana',
                description: 'Ramokgwebana',
            },
            {
                code: 'Gaborone',
                description: 'Gaborone',
            },
            {
                code: 'Other-SouthernDistrict',
                description: 'Other (Southern District)',
            },
            {
                code: 'Other-SouthEastDistrict',
                description: 'Other (SouthEast District)',
            },
            {
                code: 'Other-KwenengDistrict',
                description: 'Other (Kweneng District)',
            },
            {
                code: 'Other-KgatlengDistrict',
                description: 'Other (Kgatleng District)',
            },
            {
                code: 'Other-CentralDistrict',
                description: 'Other (Central District)',
            },
            {
                code: 'Other-NorthEastDistrict',
                description: 'Other (NorthEast District)',
            },
            {
                code: 'Other-NorthWestDistrict',
                description: 'Other (NorthWest District)',
            },
            {
                code: 'Other-GhanziDistrict',
                description: 'Other (Ghanzi District)',
            },
            {
                code: 'Other-KgalagadiDistrict',
                description: 'Other (Kgalagadi District)',
            },
            // {
            //     code: 'Other',
            //     description: 'Other',
            // },
        ],
        coverAmountList: [
            {
                code: '10000',
                description: 'P10000',
            },
            {
                code: '25000',
                description: 'P25000',
            },
            {
                code: '50000',
                description: 'P50000',
            },
            {
                code: '100000',
                description: 'P100000',
            },
        ],
    };
};

export const getBooleanValue = (value) => value?.toLowerCase() === 'yes';

export const getHomeDetailsRequestBody = createSelector(
    getHomeDetails,
    getHomeContentList,
    getPersonalDetailsForm,
    getIsInsurenceTypeSelected('homeContent'),
    getIsInsurenceTypeSelected('homeOwners'),
    getGlobalPaymentFrequency,
    (homeDetails, homeContentList,{ fullName, cellphoneNumber, email,insuredDetails,insuredWithOM,policyNumber }, isHomeContentSelected, isHomeOwnerSelected,paymentFrequency) => {
        const homeOwnerDetails= homeDetails?.homeDetailsList?.map(
            (
                {
                    address,
                    numberOfBedrooms,
                    numberOfBathrooms,
                    guiserSituated,
                    homeOccupied,
                    razorWire,
                    
                    homehired,
                    inRetirement,
                    roofMaterialDetails,
                    mainWallConstructedOff,
                    homeDetailsType,
                    houseValue,
                    accidentalDamage,
                    accidentalDamageAmount,
                    powerSurge,
                    powerSurgeCover,
                    // insuredDetails,
                    // insuredWithOM,
                    // policyNumber
                    // domesticWorker,
                    // domesticEmployees
                    // householdLoss,
                    // claimedCount,
                    // claimAmount,
                },
            ) => (
                  {
                    cityName: address?.displayValue,
                    bedroomCount: +numberOfBedrooms?.value,
                    bathroomCount: +numberOfBathrooms?.value,
                    geyserLocation: guiserSituated?.value?.toLowerCase(),
                    domesticHelpPresent: false,
                    occupiedWhileAtWork: getBooleanValue(homeOccupied?.value),
                    isHouseFenced: getBooleanValue(razorWire?.value),
                    contentTheftHistory: false,
                    isRented: getBooleanValue(homehired?.value),
                    applicantRetired: getBooleanValue(inRetirement?.value),
                    paymentFrequency: paymentFrequency?.toLowerCase(),
                    roofMaterial: roofMaterialDetails?.value,
                    wallMaterial: mainWallConstructedOff?.value,
                    homeType: homeDetailsType?.value,
                    insuredDurationWithOM: +insuredWithOM?.displayValue,
                    totalInsuredDuration: +insuredDetails?.displayValue,
                    policyNumber: +(policyNumber?.value ?? 0),
                    homeValue: +houseValue?.value,
                    homeContentValue: 0,
                    name: fullName?.value,
                    mobile: +cellphoneNumber?.value,
                    email: email?.value ?? '-',
                    homeOwnerData: 'test',
                    message: 'Allsure Insurance Product',
                    tncAccepted: true,
                    excess: 500,
                    claimCount: 0,
                    claimAmount: 0,
                    homeOwnersQuote: true,
                    homeContentQuote: false,
                    accidentalDamageCovered: getBooleanValue(accidentalDamage?.value),
                    accidentalDamageCoverAmount:getBooleanValue(accidentalDamage?.value) ? +accidentalDamageAmount?.displayValue : 0,
                    powerSurgeCovered:getBooleanValue(powerSurge?.value),
                    powerSurgeCoverAmount:getBooleanValue(powerSurge?.value) ? +powerSurgeCover?.displayValue : 0,
                    domesticEmpLiabilityCovered:false,
                    domesticEmpLiabilityCoverAmount: 0,
                    hcAccidentalDamageCovered:false,
                    hcAccidentalDamageCoverAmount:0,

                })
            
        );
        const homeContentDetails=homeContentList?.map(
            (
                {
                    address,
                    guiserSituated,
                    homeOccupied,
                    razorWire,
                    householdLoss,
                    domesticWorker,
                    homehired,
                    inRetirement,
                    roofMaterialDetails,
                    mainWallConstructedOff,
                    homeDetailsType,
                    
                    contentValue,
                    claimedCount,
                    claimAmount,
                    accidentalDamage,
                    accidentalDamageAmount,
                    powerSurge,
                    powerSurgeCover,
                    domesticEmployees,
                    // policyNumber,
                    // insuredDetails,
                    // insuredWithOM,
                },
            ) => (
                  {
                    cityName: address?.displayValue,
                    bedroomCount: 0,
                    bathroomCount: 0,
                    geyserLocation: guiserSituated?.value?.toLowerCase(),
                    domesticHelpPresent: getBooleanValue(domesticWorker?.value),
                    occupiedWhileAtWork: getBooleanValue(homeOccupied?.value),
                    isHouseFenced: getBooleanValue(razorWire?.value),
                    contentTheftHistory: getBooleanValue(householdLoss?.value),
                    isRented: getBooleanValue(homehired?.value),
                    applicantRetired: getBooleanValue(inRetirement?.value),
                    paymentFrequency: paymentFrequency?.toLowerCase(),
                    roofMaterial: roofMaterialDetails?.value,
                    wallMaterial: mainWallConstructedOff?.value,
                    homeType: homeDetailsType?.value,
                    insuredDurationWithOM: +insuredWithOM?.displayValue,
                    totalInsuredDuration: +insuredDetails?.displayValue,
                    policyNumber: +(policyNumber?.value ?? 0),
                    homeValue: 0,
                    homeContentValue: +contentValue?.value,
                    name: fullName?.value,
                    mobile: +cellphoneNumber?.value,
                    email: email?.value ?? '-',
                    homeOwnerData: 'test',
                    message: 'Allsure Insurance Product',
                    tncAccepted: true,
                    excess: 500,
                    claimCount: +claimedCount?.value,
                    claimAmount: +claimAmount?.value,
                    homeOwnersQuote: false,
                    homeContentQuote: true,
                    accidentalDamageCovered: getBooleanValue(accidentalDamage?.value),
                    accidentalDamageCoverAmount:getBooleanValue(accidentalDamage?.value) ? +accidentalDamageAmount?.displayValue : 0,
                    powerSurgeCovered:false,
                    // // getBooleanValue(powerSurge?.value),
                    powerSurgeCoverAmount:getBooleanValue(accidentalDamage?.value) ? +accidentalDamageAmount?.displayValue : 0,
                    domesticEmpLiabilityCovered:getBooleanValue(domesticEmployees?.value),
                    domesticEmpLiabilityCoverAmount: domesticEmployees?.value === 'Yes' ? 1000000 : 0,
                    hcAccidentalDamageCovered:getBooleanValue(accidentalDamage?.value),
                    hcAccidentalDamageCoverAmount:getBooleanValue(accidentalDamage?.value) ? +accidentalDamageAmount?.displayValue : 0,
                })
            
        );
        return[...homeOwnerDetails,...homeContentDetails]
    }
);


export const getContentDetailsObject = createSelector(getHomeContentList,getGlobalPaymentFrequency, (homeContentsList,paymentFrequency) => {
    return homeContentsList?.map(
        ({
            address,
            otherAddress,
            homeOccupied,
            razorWire,
            householdLoss,
            homehired,
            domesticWorker,
            inRetirement,
            roofMaterialDetails,
            mainWallConstructedOff,
            homeDetailsType,
            insuredDetails,
            insuredWithOM,
            claimedCount,
            claimAmount,
            contentValue,
            accidentalDamage,
            accidentalDamageAmount,
            powerSurge,
            powerSurgeCover,
            domesticEmployees,
            policyNumber
        }) => {
            const homeDetails = [
                {
                    label: 'What is the location of the property to be insured?',
                    value: address.value === 'Other' ? otherAddress.value : address.value,
                },
                {
                    label: homehired?.value==='No' ? 'Do you have a domestic worker at home while you are at work?':'Are there domestic workers at home while the tenant is at work?',
                    value: domesticWorker.value ?? '-',
                },
                { label: homehired?.value ==='Yes' ? 'Is the house occupied while the tenant is at work?' : 'Is your home occupied while you’re at work?', value: homeOccupied.displayValue ?? '-' },
                {
                    label: 'Does your wall/fence have razor wire or electric fencing?',
                    value: razorWire.displayValue ?? '-',
                },
                {
                    label: 'Have you suffered any loss of household contents in the last 3 years?',
                    value: householdLoss.displayValue ?? '-',
                },
                {
                    label: 'How many times have you claimed in the last 3 years?',
                    value: claimedCount.displayValue ?? '-',
                },
                { label: 'Enter the total value of the claim(s) paid out.', value:"P"+claimAmount.displayValue ?? '-' },
                { label: 'Will your home be rented out?', value: homehired.displayValue ?? '-' },
                { label: 'Are you currently in retirement?', value: inRetirement.displayValue ?? '-' },
                { label: 'How often would like to pay your premium?', value: paymentFrequency ?? '-' },
                { label: 'What material is the roof made of?', value: roofMaterialDetails.displayValue ?? '-' },
                { label: 'What are the main walls constructed of?', value: mainWallConstructedOff.displayValue ?? '-' },
                { label: 'What type of home do you have?', value: homeDetailsType.displayValue ?? '-' },
                // {
                //     label: 'How many years of uninterrupted insurance cover have you had for your home?',
                //     value: insuredDetails.displayValue ?? '-',
                // },
            
                // { label: 'How long you have been insured with Old mutual?', value: insuredWithOM.displayValue ?? '-' },
                // ...(insuredWithOM?.value>0 ? [{ label: 'Policy number', value: policyNumber?.value ?? '-' }]:[]),

                {
                    label: 'What is the total value of your house contents?',
                    value: 'P' + contentValue.displayValue ?? '-',
                },
                {
                    label: 'Do you require accidental damage and power surge cover for your household contents?',
                    value: accidentalDamage.displayValue ?? '-',
                },
                {
                    label: 'Please select your cover amount.',
                    value: (accidentalDamageAmount.displayValue && accidentalDamage?.displayValue==='Yes') ? "P"+accidentalDamageAmount.displayValue: '-',
                },
                // {
                //     label: 'Do you require Power Surge cover?',
                //     value: powerSurge.displayValue ?? '-',
                // },
                // {
                //     label: 'Please select your cover amount.',
                //     value: (powerSurgeCover.displayValue && powerSurge?.displayValue==='Yes') ?powerSurgeCover.displayValue: '-',
                // },
                {
                    label: 'Do you need Domestic Employees Liability cover?',
                    value: domesticEmployees?.displayValue ?? '-',
                },
                
            ];

            householdLoss.value === 'No' && homeDetails.splice(5, 2);

            return homeDetails;
        }
    );
});

