import { validateSingleSelection, validateNumber } from "@om/validation";
import {
    REGULAR_CONTRIBUTION_SET_VALUE,
    LUMP_SUM_CONTRIBUTION_SET_VALUE,
    CONTRIBUTION_SET_VALUE,
    PREMIUM_ESCALATION_SET_VALUE,
    OWN_PERCENTAGE_SET_VALUE,

    setRegularContributionValid,
    setLumpSumContributionValid,
    setContributionValid,
    setOwnPercentageValid,
    setPremiumEscalationValid,
} from "../../../actions/taxFreeSavingsAccount/investmentSetupContribution";
import {
    getContributionType,
    getMinimumMonthlyContribution,
} from "../../../selectors/taxFreeSavingsAccount/investmentSetupContribution"
import { CONTRIBUTION_TYPE } from "../../../actions/taxFreeSavingsAccount/types";

export const investmentSetupContributionValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        const state = store.getState();

        switch (action.type) {
            case CONTRIBUTION_SET_VALUE:
                error = validateSingleSelection(action.value, 'contribution type');
                return setContributionValid(error);
                
            case PREMIUM_ESCALATION_SET_VALUE:
                error = validateSingleSelection(action.value, 'premium escalation');
                return setPremiumEscalationValid(error);

            case REGULAR_CONTRIBUTION_SET_VALUE:
                error = validateNumber(action.value, {subject: 'monthly contribution', min: getMinimumMonthlyContribution(state), max: 3000, required: true});
                return setRegularContributionValid(error);

            case LUMP_SUM_CONTRIBUTION_SET_VALUE:
                const isCombinationContribution = getContributionType(store.getState()).value === CONTRIBUTION_TYPE.COMBINATION;
                const minimum = isCombinationContribution ? 1000 : 5000;
                error = validateNumber(action.value, {subject: 'lump sum contribution', min: minimum, max: 36000, required: true});
                return setLumpSumContributionValid(error);

            case OWN_PERCENTAGE_SET_VALUE: 
                error = validateSingleSelection(action.value, 'percentage');
                return setOwnPercentageValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
