import {
    BIOGRAPHIC_INFO_SET_VALID,
    BIOGRAPHIC_INFO_SET_VALUE
} from "../../../actions/covidSelfAssessment/biographicInfo";
import {biographicInfoRules} from "./biographicInfo";
import {
    CLINICAL_CRITERIA_SET_VALID,
    CLINICAL_CRITERIA_SET_VALUE
} from "../../../actions/covidSelfAssessment/clinicalCriteria";
import {clinicalCriteriaRules} from "./clinicalCriteria";
import {TRAVEL_HISTORY_SET_VALID, TRAVEL_HISTORY_SET_VALUE} from "../../../actions/covidSelfAssessment/travelHistory";
import {travelHistoryRules} from "./travelHistory";
import {STEP_INDEX_SET_VALUE} from "../../../actions/covidSelfAssessment/wizardFooter";
import {resultRules} from "./result";

export const covidAssessmentRules = (store, next, action) => {
    if (action.type.startsWith(BIOGRAPHIC_INFO_SET_VALID) ||
        action.type.startsWith(BIOGRAPHIC_INFO_SET_VALUE))
        return biographicInfoRules(store, next, action);

    if (action.type.startsWith(CLINICAL_CRITERIA_SET_VALID) ||
        action.type.startsWith(CLINICAL_CRITERIA_SET_VALUE))
        return clinicalCriteriaRules(store, next, action);

    if (action.type.startsWith(TRAVEL_HISTORY_SET_VALID) ||
        action.type.startsWith(TRAVEL_HISTORY_SET_VALUE))
        return travelHistoryRules(store, next, action);

    if (action.type === STEP_INDEX_SET_VALUE)
        return resultRules(store, next, action);

    return null;
};
