import {
 	COLLECT_LOGBOOK_DATA_VALUE,
    COMPUTE_EXTRA_BENEFIT_VALUE,
    COMPUTE_ADD_EXTRA_BENEFIT_VALUE,
    IPRS_ID_VALIDATION,
    KRA_VALIDATION,
    COVER_DATE_VALIDATION,
} from '../../../actions/roaCarInsurance/details';
import {
 	COMPUTE_MPESA_PAYMENTS , CHECK_MPESA_PAYMENTS
} from '../../../actions/roaCarInsurance/payments';

import {COLLECT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS} from '../../../actions/roaCarInsurance/manualPayments';
import {carInsuranceManualMpesaPaymentService} from './manualPayments';
import {
    COLLECT_REVALUATION_DATA,
    COLLECT_VEHICLE_VALUATION_DATA,
    COLLECT_CAR_MAKES_DATA,
    COLLECT_CAR_MODELS_DATA,
    COLLECT_PREMIUM_CALCULATION_NG_DATA,
} from '../../../actions/roaCarInsurance/quotation';
import { vehicleValuationService } from './vehicleValuation';
import { varianceCheckerService } from './varianceChecker';
import { COLLECT_PREMIUM_DATA } from '../../../actions/roaCarInsurance/product';
import { premiumCalculationService } from './premiumCalculation';

import { uploadLogBookService } from './uploadLogBook';
import {alterBenefitService} from './alterBenefit';
import {extraBenefitService} from './extraBenefits';
import {carInsuranceMpesaPaymentService} from './mpesaPayments';
import {validateIdService} from './validateId';
import {validateKraService} from './validateKraService';
import {validateStartDateService} from './validateCoverStartDate';
import {carInsuranceMpesaPaymentStatusService} from './checkPaymentsStatus'
import { dropOffLeadsService } from './dropOffLeads';
import { NAVIGATE_NEXT } from '../../../actions/roaCarInsurance/routing';
import {getIsDropoffPage} from "../../../selectors/roaCarInsurance/routing";
import { carModelsService } from "./carModels";
import { carMakesService } from "./carMakesNg";
import { premiumCalculationNgService } from "./premiumCalculationNg";
import { COLLECT_VEHICLE_DETAILS_DATA } from "../../../actions/roaCarInsurance/vehicleDetails";
import { vehicleDetailsNgService } from "./vehicleDetails";

 export const roaCarInsuranceService = (store, next, action) => {
    if (action.type.startsWith(COLLECT_CAR_INSURANCE_MANUAL_MPESA_PAYMENTS)) {
        return carInsuranceManualMpesaPaymentService(store, next, action);
        }
	if (action.type.startsWith(COLLECT_LOGBOOK_DATA_VALUE)) {
        return uploadLogBookService(store, next, action);
    }
    if (action.type.startsWith(COMPUTE_EXTRA_BENEFIT_VALUE)) {
        return alterBenefitService(store, next, action);
    }
    if (action.type.startsWith(COMPUTE_ADD_EXTRA_BENEFIT_VALUE)) {
        return extraBenefitService(store, next, action);
    }
    if (action.type.startsWith(COMPUTE_MPESA_PAYMENTS)){
        return carInsuranceMpesaPaymentService(store, next, action)
    }
    if (action.type.startsWith(IPRS_ID_VALIDATION)){
        return validateIdService(store, next, action)
    }
    if (action.type.startsWith(KRA_VALIDATION)){
        return validateKraService(store, next, action)
    }
    if (action.type.startsWith(COLLECT_VEHICLE_VALUATION_DATA)) {
        return vehicleValuationService(store, next, action);
    }

    if (action.type.startsWith(CHECK_MPESA_PAYMENTS)) {
        return carInsuranceMpesaPaymentStatusService(store, next, action);
    }
    if (action.type === NAVIGATE_NEXT && getIsDropoffPage(store.getState())) {
        return dropOffLeadsService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_REVALUATION_DATA)) {
        return varianceCheckerService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_PREMIUM_DATA)) {
        return premiumCalculationService(store, next, action);
    }
    if (action.type.startsWith(COVER_DATE_VALIDATION)) {
        return validateStartDateService(store, next, action);
    }
    if (action.type === NAVIGATE_NEXT && getIsDropoffPage(store.getState())) {
        return dropOffLeadsService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_CAR_MAKES_DATA)) {
        return carMakesService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_CAR_MODELS_DATA)) {
        return carModelsService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_PREMIUM_CALCULATION_NG_DATA)) {
        return premiumCalculationNgService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_VEHICLE_DETAILS_DATA)) {
        return vehicleDetailsNgService(store, next, action);
    }
    return null;
};
