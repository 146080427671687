import { BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALID, BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE } from "./coverDetails";

export const BIASHARA_FLEXI_QOUTE_FORM = 'biasharaFlexi/quoteForm/';

export const BIASHARA_FLEXI_FORM_SET_VALUE = BIASHARA_FLEXI_QOUTE_FORM + 'setValue/';
export const BIASHARA_FLEXI_FORM_SET_VALID = BIASHARA_FLEXI_QOUTE_FORM + 'setValid/';
export const BIASHARA_FLEXI_ADD_COVER = BIASHARA_FLEXI_QOUTE_FORM + 'addCover/';	
export const FORM_VALID_BIASHARA_FLEXI_QUOTE_FORM = 'biasharaFlexi/quoteForm/transition/formValid';
export const FORM_INVALID_BIASHARA_FLEXI_QUOTE_FORM = 'biasharaFlexi/quoteForm/transition/formInValid';
export const FORM_VALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM = 'biasharaFlexi/shareQuoteForm/transition/formValid';
export const FORM_INVALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM = 'biasharaFlexi/shareQuoteForm/transition/formInValid';
export const BIASHARA_FLEXI_SHARE_QUOTE_VALUE = BIASHARA_FLEXI_QOUTE_FORM + 'shareQuote';

export const TOKEN_IS_EXPIRED = 'biasharaFlexi/expiredAuth';


export const BIASHARA_FLEXI_SIDEBAR_OPEN_VALUE = BIASHARA_FLEXI_FORM_SET_VALUE + 'sidebarOpen';

export const REINITIALIZE = BIASHARA_FLEXI_QOUTE_FORM + '/state/reinitialize';

// VALIDATE QUOTE FORM

export const transitionBiasharaFlexiQuoteForm = { 
		formValid: () => ({
			type: FORM_VALID_BIASHARA_FLEXI_QUOTE_FORM,
		}),
		formInvalid: () => ({
			type: FORM_INVALID_BIASHARA_FLEXI_QUOTE_FORM,
		}),
};

export const transitionShareQuoteForm = {
	formInvalid: () => ({ 
		type: FORM_INVALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM,
	}),
	formValid: () => ({
		type: FORM_VALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM,
	}),
}

// API Calls actions
export const API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${BIASHARA_FLEXI_FORM_SET_VALID}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${BIASHARA_FLEXI_FORM_SET_VALID}/apiTransition/success`;
export const API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM = `${BIASHARA_FLEXI_FORM_SET_VALID}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${BIASHARA_FLEXI_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${BIASHARA_FLEXI_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${BIASHARA_FLEXI_SHARE_QUOTE_VALUE}/apiTransition/failure`;


export const apiTransitionMotorInsuranceQuoteForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
	}),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};


export const COLLECT_SHARE_QUOTE_FORM_DATA = BIASHARA_FLEXI_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_BIASHARA_FLEXI_QUOTE_SUCCESS = BIASHARA_FLEXI_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_BIASHARA_FLEXI_QUOTE_FAILURE = BIASHARA_FLEXI_FORM_SET_VALUE + 'shareQuoteFailure';

export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});


export const shareQuoteSuccess = (value) => ({
    type: SHARE_BIASHARA_FLEXI_QUOTE_SUCCESS,
    value,
})

export const shareQuoteFailure = (error) => ({
    type: SHARE_BIASHARA_FLEXI_QUOTE_FAILURE,
    error,
})


export const addCover = (value) => ({
	type: BIASHARA_FLEXI_ADD_COVER,
	value,
})


export const SET_SHARE_EMAIL_VALUE = BIASHARA_FLEXI_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = BIASHARA_FLEXI_FORM_SET_VALID + 'shareEmailValid';
export const SET_PAY_NOW_VALUE = BIASHARA_FLEXI_FORM_SET_VALUE + 'payNowValue';

export const setPayNowValue = value => ({
	type: SET_PAY_NOW_VALUE,
	value,
});

export const setShareEmailValue = value => ({
	type: SET_SHARE_EMAIL_VALUE,
	value,
});

export const setShareEmailValid = error => ({
	type: SET_SHARE_EMAIL_VALID,
	error,
});



export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value
});

export const setSideBarOpen = (value) => ({ type: BIASHARA_FLEXI_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: BIASHARA_FLEXI_SHARE_QUOTE_VALUE, value });
