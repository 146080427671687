import * as ActionTypes from "./types";

export function getLookupData() {
    return {
        type: ActionTypes.LOOKUP_DATA_REQUEST
    };
}

export function setLookupData(data){
    return{
        type: ActionTypes.LOOKUP_DATA_SUCCESS,
        data: data
    };
}

export function setLookupFail(){
    return{
        type: ActionTypes.LOOKUP_DATA_FAIL
    };
}