import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
import * as quotationActions from '../../actions/roaCarInsurance/quotation';

export const initQuotation = {
    vehicleValuation: { value: null, error: null, status: null },
    revaluationValue: { value: null, error: null, status: null },
    summaryFormStatus: FORM_STATUS.INVALID,
    agentReferralStatus: FORM_STATUS.VALID,
    formStatus: FORM_STATUS.INVALID,
	apiCallStatus: API_CALL_STATUS.IDLE,
    // for Nigeria
    formStatusNg: FORM_STATUS.INVALID,
    carMakes: { value: null, error: null, status: null },
    carModels: { value: null, error: null, status: null },
    premiumCalculationNg: { value: null, error: null, status: null },
    selectedModels: {value: [] },

    form: {
        age: { value: 1, error: null, status: FORM_STATUS.VALID },
        makeAndModel: {
            value: null,
            error: null,
            status: null,
        },
        noOfAccidents: { value: null, error: null, status: null },
        yearOfManufacture: { value: null, error: null, status: null },
        varianceValue: { value: null, error: null, status: null },

		// for Nigeria
        vehicleMake: { value: null, error: null, status: null },
        vehicleModel: { value: null, error: null, status: null },
        vehicleType: { value: null, error: null, status: null },
        vehicleUse: { value: null, error: null, status: null },
        estimatedValue: { value: null, error: null, status: null },
        manufactureYear: { value: null, error: null, status: null },


    },
};

export default (state = initQuotation, action) => {
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case quotationActions.FORM_VALID_QUOTATION:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case quotationActions.FORM_INVALID_QUOTATION:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        //for Nigeria
        case quotationActions.FORM_VALID_NG_QUOTATION:
            return { ...state, formStatusNg: FORM_STATUS.VALID };
        case quotationActions.FORM_INVALID_NG_QUOTATION:
            return { ...state, formStatusNg: FORM_STATUS.INVALID };
        case quotationActions.SUMMARY_FORM_STATUS:
            return { ...state, summaryFormStatus: FORM_STATUS.VALID };
        case quotationActions.AGENT_REFERRAL_FORM_STATUS:
            return { ...state, agentReferralStatus: FORM_STATUS.INVALID };

        case quotationActions.AGE_OF_INSURED_SET_VALUE:
            return updateFormField('age', (field) => ({
                ...field,
                value: action.value,
            }));
        case quotationActions.AGE_OF_INSURED_SET_VALID:
            return updateFormField('age', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case quotationActions.CAR_MAKE_AND_MODEL_SET_VALUE:
            return updateFormField('makeAndModel', (field) => ({
                ...field,
                value: action.value,
            }));
        case quotationActions.CAR_MAKE_AND_MODEL_SET_VALID:
            return updateFormField('makeAndModel', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case quotationActions.CAR_MAKES_SET_VALUE:
            return {...state, carMakes: {...state.carMakes, value: action.value }}
        case quotationActions.CAR_MAKES_SET_VALID:
            return {...state, carMakes: {...state.carMakes, error: action.error }}

        case quotationActions.CAR_MODELS_SET_VALUE:
            return {...state, carModels: {...state.carModels, value: action.value }}
        case quotationActions.CAR_MODELS_SET_VALID:
            return {...state, carModels: {...state.carModels, error: action.error }}
        case quotationActions.SELECTED_MODELS_SET_VALUE:
            const { carModels } = state
            const value = carModels.value.filter(model => Number(model.makeID) === Number(action.value))
            return {...state, selectedModels: { value }}

        case quotationActions.NUMBER_OF_ACCIDENTS_SET_VALUE:
            return updateFormField('noOfAccidents', (field) => ({
                ...field,
                value: action.value,
            }));
        case quotationActions.NUMBER_OF_ACCIDENTS_SET_VALID:
            return updateFormField('noOfAccidents', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case quotationActions.YEAR_OF_MANUFACTURER_SET_VALUE:
            return updateFormField('yearOfManufacture', (field) => ({
                ...field,
                value: action.value,
            }));
        case quotationActions.YEAR_OF_MANUFACTURER_SET_VALID:
            return updateFormField('yearOfManufacture', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case quotationActions.MANUFACTURER_YEAR_SET_VALUE:
            return updateFormField('manufactureYear', (field) => ({
                ...field,
                value: action.value,
            }));
        case quotationActions.MANUFACTURER_YEAR_SET_VALID:
            return updateFormField('manufactureYear', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case quotationActions.VARIANCE_VALUE_SET_VALUE:
            return updateFormField('varianceValue', (field) => ({
                ...field,
                value: action.value,
            }));
        case quotationActions.VARIANCE_VALUE_SET_VALID:
            return updateFormField('varianceValue', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        //    API Call
        case quotationActions.API_PENDING_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case quotationActions.API_SUCCESS_TRANSITION:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case quotationActions.API_FAILURE_TRANSITION:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
                vehicleValuation: {
                    ...state.vehicleValuation,
                    error: action.error,
                    status: API_CALL_STATUS.FAILURE,
                },
                premiumCalculationNg: {
                    ...state.premiumCalculationNg,
                    error: action.error,
                    status: API_CALL_STATUS.FAILURE,
                }
            };

        //    Calculate vehicle valuation
        case quotationActions.CALCULATE_VEHICLE_VALUATION_SUCCESS:
            return {
                ...state,
                vehicleValuation: {
                    ...state.vehicleValuation,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };
        case quotationActions.COLLECT_VEHICLE_VALUATION_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };

        //    Calculate vehicle variance checker
        case quotationActions.CALCULATE_REVALUATION_SUCCESS:
            return {
                ...state,
                revaluationValue: {
                    ...state.revaluationValue,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };
        case quotationActions.COLLECT_REVALUATION_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };

        //    for Nigeria

        //    Calculate vehicle valuation
        case quotationActions.CALCULATE_PREMIUM_CALCULATION_NG_SUCCESS:
            return {
                ...state,
                premiumCalculationNg: {
                    ...state.premiumCalculationNg,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };
        case quotationActions.COLLECT_PREMIUM_CALCULATION_NG_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case quotationActions.CALCULATE_CAR_MAKES_SUCCESS:
            return {
                ...state,
                carMakes: {
                    ...state.carMakes,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };
        case quotationActions.COLLECT_CAR_MAKES_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case quotationActions.CALCULATE_CAR_MODELS_SUCCESS:
            return {
                ...state,
                carModels: {
                    ...state.carModels,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };
        case quotationActions.COLLECT_CAR_MODELS_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
		case quotationActions.VEHICLE_MAKE_SET_VALUE:
			return updateFormField('vehicleMake', (field) => ({
				...field,
				value: action.value,
			}));
		case quotationActions.VEHICLE_MAKE_SET_VALID:
			return updateFormField('vehicleMake', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case quotationActions.VEHICLE_MODEL_SET_VALUE:
			return updateFormField('vehicleModel', (field) => ({
				...field,
				value: action.value,
			}));
		case quotationActions.VEHICLE_MODEL_SET_VALID:
			return updateFormField('vehicleModel', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case quotationActions.VEHICLE_TYPE_SET_VALUE:
			return updateFormField('vehicleType', (field) => ({
				...field,
				value: action.value,
			}));
		case quotationActions.VEHICLE_TYPE_SET_VALID:
			return updateFormField('vehicleType', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case quotationActions.ESTIMATED_VALUE_SET_VALUE:
			return updateFormField('estimatedValue', (field) => ({
				...field,
				value: action.value,
			}));
		case quotationActions.ESTIMATED_VALUE_SET_VALID:
			return updateFormField('estimatedValue', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
		case quotationActions.VEHICLE_USE_SET_VALUE:
			return updateFormField('vehicleUse', (field) => ({
				...field,
				value: action.value,
			}));
		case quotationActions.VEHICLE_USE_SET_VALID:
			return updateFormField('vehicleUse', (field) => ({
				...field,
				error: action.error,
				status: mapError(action.error),
			}));
        default:
            return state;
    }
};
