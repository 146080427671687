export const POSTAL_ADDRESS_METHODS = {
    PO_BOX: 'POBox',
    PRIVATE_BAG: 'PrivateBag',
    STREET_ADDRESS:'StreetAddress',
};

export const INVESTOR_TYPES = {
    ADVENTUROUS: 'AdventurousInvestor',
    COMFORTABLE: 'ComfortableInvestor',
};

export const DEFAULT_COUNTRY = {
    ZA_CODE: 'SouthAfrica',
    ZA_DESCRIPTION: 'South Africa',
    ZA_ID_TYPE_CODE: 'SouthAfricanIdentity',
};

export const ANNUAL_PREMIUM_INCREASES = {
    LINK_WITH_INFLATION: 'LinkedWithInflation',
    NO_INCREASE: 'NoIncrease',
    OWN_PERCENTAGE: 'Percentages',
};
