import axios from 'axios'; 

import {
    calculateAfyaImaraJuniorData,
    apiTransitionAfyaImaraJuniorQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/afyaImaraJuniorQuoteTool";

import {
    getAfyaImaraJuniorQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/afyaImaraJuniorQuoteTool';

export const afyaImaraJuniorQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if(!getIsApiPending(state)) {
            store.dispatch(apiTransitionAfyaImaraJuniorQuoteTool.pending());

            try {
                const response = await calculate(state);
                const premiumAmount = response.data.total_premiums
                store.dispatch(calculateAfyaImaraJuniorData(premiumAmount));
                store.dispatch(apiTransitionAfyaImaraJuniorQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateAfyaImaraJuniorData(null));
                store.dispatch(apiTransitionAfyaImaraJuniorQuoteTool.failure());
            }
        }
    })();
    return result;
}
const getAfyaImaraJuniorPayload = (formDetails) => {
    return {
        'panel_id': formDetails.medicalProvidersScope.value || '',
        'no_of_children': formDetails.noOfChildren.value || '',
        'cover_limit_inpatient': formDetails.inpatientCoverLimit.value || '',
        'cover_limit_outpatient': formDetails.outpatientCoverLimit.value || '',
    }
}
const calculate = async(state) => {
    const afyaImaraJuniorFormDetails = getAfyaImaraJuniorQuoteToolForm(state)

    try{
        const url = '/om-api/roa-quote-tools/afya-imara-junior-quote';
        const result = await axios.post(url, getAfyaImaraJuniorPayload(afyaImaraJuniorFormDetails))
        return result;
    } catch (error) {
        return null;
    }
}
