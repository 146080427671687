import { getAboutYouForm } from '../../../selectors/roaCarInsurance/aboutYou';
import validate from '../../utils/formValidators';
import { transitionAboutYouForm } from '../../../actions/roaCarInsurance/aboutYou';

export const aboutYouFormRules = (store, next, action) => {
    const aboutYouForm = getAboutYouForm(store.getState());
    const isFormValid = validate(aboutYouForm, [
        'firstName',
        'lastName',
        'emailAddress',
        'phoneNumber',
    ]);
    const formAction = isFormValid
        ? transitionAboutYouForm.formValid()
        : transitionAboutYouForm.formInvalid();
    store.dispatch(formAction);
    return next(action);
};

