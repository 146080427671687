import { LOOKUP_DATA_SUCCESS, LOOKUP_DATA_FAIL } from "../../actions/digitalOnboarding/types";

export const initLookupData = {
    data: [],
}


export default (state = initLookupData, action) =>{
    const setLookupData = (data) =>({
        ...state,
        data: data,
    });

    const setFail = () =>({
        ...state,
        data: [],
    });

    switch (action.type) {
        case LOOKUP_DATA_SUCCESS:
            return setLookupData(action.data);
        case LOOKUP_DATA_FAIL:
            return setFail() ;
        default:
            break;
    }

    return state;
}
