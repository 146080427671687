import { onFieldChangeStateComparison } from '../onFieldChangeStateComparison';
import { simpleFieldTracking } from '../formTracking';
import { otherEventTracking, findOtherEventIndex } from '../otherEventTracking';
import {
    NAVIGATE_TO_LANDING_PAGE,
    NAVIGATE_TO_INVESTMENT_SETUP_INITIAL,
    NAVIGATE_TO_INVESTMENT_SETUP_CONTRIBUTION,
    NAVIGATE_TO_SELECT_A_FUND,
    NAVIGATE_TO_ADVANCED_FUND_PICKER,
    NAVIGATE_TO_FUND_SPLIT,
    NAVIGATE_TO_PERSONAL_DETAILS,
    NAVIGATE_TO_AUXILIARY_DETAILS,
    // NAVIGATE_TO_BENEFICIARIES,
    // NAVIGATE_TO_BENEFICIARIES_SPLIT,
    NAVIGATE_TO_PAYMENT_DETAILS,
    NAVIGATE_TO_REVIEW,
    NAVIGATE_TO_THANK_YOU,
} from '../../../actions/taxFreeSavingsAccount/routing';
import {PAGES} from '../../../actions/taxFreeSavingsAccount/types';

import {
    getActivePageIndex,
} from '../../../selectors/taxFreeSavingsAccount/routing';

export const routingOtherTracking = (store, next, action) => {
    const state =  store.getState();
    const result = next(action);
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case NAVIGATE_TO_LANDING_PAGE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Landing Page';

            case NAVIGATE_TO_INVESTMENT_SETUP_INITIAL:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Investment Setup Initial Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_INVESTMENT_SETUP_CONTRIBUTION:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Investment Setup Contribution Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_SELECT_A_FUND:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Select A Fund Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_ADVANCED_FUND_PICKER:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Advanced Fund Picker Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_FUND_SPLIT:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Fund Split Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_PERSONAL_DETAILS:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Personal Details Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_AUXILIARY_DETAILS:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Auxliary Details Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            // case NAVIGATE_TO_BENEFICIARIES:
            //     otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            //     return 'Navigate To Beneficiaries Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;
            //
            // case NAVIGATE_TO_BENEFICIARIES_SPLIT:
            //     otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            //     return 'Navigate To Beneficiaries Split Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_PAYMENT_DETAILS:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Payment Details Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_REVIEW:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Review Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            case NAVIGATE_TO_THANK_YOU:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return 'Navigate To Thank You Page' + ' from ' + PAGES[getActivePageIndex(state)].TITLE;

            default:
                return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};
