export const SIGNUP_SET_VALUE = 'publicWeb/signup/setValue/';
export const SIGNUP_SET_VALID = 'publicWeb/signup/setValid/';

export const SIGNUP_SEND_DETAILS = SIGNUP_SET_VALUE + 'sendDetails';

export const SIGNUP_NAME_VALUE = SIGNUP_SET_VALUE + 'name';
export const SIGNUP_NAME_VALID = SIGNUP_SET_VALID + 'name';
export const SIGNUP_SURNAME_VALUE = SIGNUP_SET_VALUE + 'surname';
export const SIGNUP_SURNAME_VALID = SIGNUP_SET_VALID + 'surname';
export const SIGNUP_PHONE_NUMBER_VALUE = SIGNUP_SET_VALUE + 'phoneNumber';
export const SIGNUP_PHONE_NUMBER_VALID = SIGNUP_SET_VALID + 'phoneNumber';
export const SIGNUP_EMAIL_VALUE = SIGNUP_SET_VALUE + 'email';
export const SIGNUP_EMAIL_VALID = SIGNUP_SET_VALID + 'email';
export const SIGNUP_COMPANY_NAME_VALUE = SIGNUP_SET_VALUE + 'companyName';
export const SIGNUP_COMPANY_NAME_VALID = SIGNUP_SET_VALID + 'companyName';
export const SIGNUP_NUMBER_EMPLOYEES_VALUE = SIGNUP_SET_VALUE + 'numberEmployees';
export const SIGNUP_NUMBER_EMPLOYEES_VALID = SIGNUP_SET_VALID + 'numberEmployees';
export const SIGNUP_USER_OWNS_BUSINESS_VALUE = SIGNUP_SET_VALUE + 'userOwnBusiness';
export const SIGNUP_AGREE_PRIVACY_POLICY_VALUE = SIGNUP_SET_VALUE + 'agreePrivacyPolicy';
export const SIGNUP_AGREE_NEWSLETTER_VALUE = SIGNUP_SET_VALUE + 'agreeNewsletter';
export const SIGNUP_INDUSTRY_VALUE = SIGNUP_SET_VALUE + 'industry';
export const SIGNUP_HAS_TOOLS_VALUE = SIGNUP_SET_VALUE + 'hasTools';
export const SIGNUP_TOOLS_VALUE = SIGNUP_SET_VALUE + 'tools';
export const SIGNUP_HAS_EBOOKS_VALUE = SIGNUP_SET_VALUE + 'hasEbooks';
export const SIGNUP_EBOOKS_VALUE = SIGNUP_SET_VALUE + 'ebooks';
export const SIGNUP_HAS_INTERESTS_VALUE = SIGNUP_SET_VALUE + 'hasInterests';
export const SIGNUP_INTERESTS_VALUE = SIGNUP_SET_VALUE + 'interests';
export const SIGNUP_RECAPTCHA_VALUE = SIGNUP_SET_VALUE + 'recaptcha';
export const FORM_SUBMIT_STATUS_SET_VALUE = SIGNUP_SET_VALUE + 'formSubmitStatus';
export const IS_SIDE_DRAWER_OPEN_VALUE = SIGNUP_SET_VALUE + 'isOpen';
export const SIGNUP_MODULAR_FIELDS_VALUE = SIGNUP_SET_VALUE + 'modularFields';
export const SIGNUP_SHOW_PHONE_NUMBER_FIELD_VALUE = SIGNUP_SET_VALUE + 'showPhoneNumberField';
export const SIGNUP_USE_MODULAR_FIELDS_VALUE = SIGNUP_SET_VALUE + 'useModularFields';
export const SIGNUP_USE_ONLY_MODULAR_FIELDS_VALUE = SIGNUP_SET_VALUE + 'useOnlyModularFields';
export const SIGNUP_MODULAR_FIELD_1_VALUE = SIGNUP_SET_VALUE + 'modularField1';
export const SIGNUP_MODULAR_FIELD_1_VALID = SIGNUP_SET_VALID + 'modularField1';
export const SIGNUP_MODULAR_FIELD_2_VALUE = SIGNUP_SET_VALUE + 'modularField2';
export const SIGNUP_MODULAR_FIELD_2_VALID = SIGNUP_SET_VALID + 'modularField2';
export const SIGNUP_MODULAR_FIELD_3_VALUE = SIGNUP_SET_VALUE + 'modularField3';
export const SIGNUP_MODULAR_FIELD_3_VALID = SIGNUP_SET_VALID + 'modularField3';
export const SIGNUP_MODULAR_FIELD_4_VALUE = SIGNUP_SET_VALUE + 'modularField4';
export const SIGNUP_MODULAR_FIELD_4_VALID = SIGNUP_SET_VALID + 'modularField4';
export const SIGNUP_MODULAR_FIELD_5_VALUE = SIGNUP_SET_VALUE + 'modularField5';
export const SIGNUP_MODULAR_FIELD_5_VALID = SIGNUP_SET_VALID + 'modularField5';
export const SIGNUP_MODULAR_FIELD_6_VALUE = SIGNUP_SET_VALUE + 'modularField6';
export const SIGNUP_MODULAR_FIELD_6_VALID = SIGNUP_SET_VALID + 'modularField6';
export const SIGNUP_MODULAR_FIELD_7_VALUE = SIGNUP_SET_VALUE + 'modularField7';
export const SIGNUP_MODULAR_FIELD_7_VALID = SIGNUP_SET_VALID + 'modularField7';
export const SIGNUP_MODULAR_FIELD_8_VALUE = SIGNUP_SET_VALUE + 'modularField8';
export const SIGNUP_MODULAR_FIELD_8_VALID = SIGNUP_SET_VALID + 'modularField8';
export const SIGNUP_USE_INDUSTRIES_LIST_VALUE = SIGNUP_SET_VALUE + 'useIndustriesList';
export const SIGNUP_INDUSTRIES_LIST_VALUE = SIGNUP_SET_VALUE + 'industriesList';
export const SIGNUP_SELECTED_INDUSTRIES_LIST_VALUE = SIGNUP_SET_VALUE + 'industriesValue'
export const SIGNUP_SELECTED_INDUSTRIES_LIST_VALID = SIGNUP_SET_VALID + 'industriesValue'

export const setFirstNameValue = value => ({type: SIGNUP_NAME_VALUE, value});
export const setFirstNameValid = error => ({type: SIGNUP_NAME_VALID, error});
export const setSurnameValue = value => ({type: SIGNUP_SURNAME_VALUE, value});
export const setSurnameValid = error => ({type: SIGNUP_SURNAME_VALID, error});
export const setPhoneNumberValue = value => ({type: SIGNUP_PHONE_NUMBER_VALUE, value});
export const setPhoneNumberValid = error => ({type: SIGNUP_PHONE_NUMBER_VALID, error});
export const setEmailValue = value => ({type: SIGNUP_EMAIL_VALUE, value});
export const setEmailValid = error => ({type: SIGNUP_EMAIL_VALID, error});
export const setCompanyNameValue = value => ({type: SIGNUP_COMPANY_NAME_VALUE, value});
export const setCompanyNameValid = error => ({type: SIGNUP_COMPANY_NAME_VALID, error});
export const setNumberEmployeesValue = value => ({type: SIGNUP_NUMBER_EMPLOYEES_VALUE, value});
export const setNumberEmployeesValid = error => ({type: SIGNUP_NUMBER_EMPLOYEES_VALID, error});
export const setAgreePrivacyPolicyValue = value => ({type: SIGNUP_AGREE_PRIVACY_POLICY_VALUE, value});
export const setAgreeNewsletterValue = value => ({type: SIGNUP_AGREE_NEWSLETTER_VALUE, value});
export const setIndustryValue = value => ({type: SIGNUP_INDUSTRY_VALUE, value});
export const setUserOwnsBusiness = value => ({type: SIGNUP_USER_OWNS_BUSINESS_VALUE, value});
export const setHasTools = value => ({type: SIGNUP_HAS_TOOLS_VALUE, value});
export const setTools = value => ({type: SIGNUP_TOOLS_VALUE, value});
export const setHasEbooks = value => ({type: SIGNUP_HAS_EBOOKS_VALUE, value});
export const setEbooks = value => ({type: SIGNUP_EBOOKS_VALUE, value});
export const setHasInterests = value => ({type: SIGNUP_HAS_INTERESTS_VALUE, value});
export const setInterests = value => ({type: SIGNUP_INTERESTS_VALUE, value});
export const setRecaptcha = value => ({type: SIGNUP_RECAPTCHA_VALUE, value});
export const sendSignupDetails = value => ({type: SIGNUP_SEND_DETAILS, value});
export const formSubmitStatusSetValue = value => ({ type: FORM_SUBMIT_STATUS_SET_VALUE, value });
export const setIsSideDrawerOpenValue = value => ({ type: IS_SIDE_DRAWER_OPEN_VALUE, value });
export const setShowPhoneNumberField = value => ({ type: SIGNUP_SHOW_PHONE_NUMBER_FIELD_VALUE, value });
export const setModularFields = value => ({ type: SIGNUP_MODULAR_FIELDS_VALUE, value });
export const setUseModularFields = value => ({ type: SIGNUP_USE_MODULAR_FIELDS_VALUE, value });
export const setUseOnlyModularFields = value => ({ type: SIGNUP_USE_ONLY_MODULAR_FIELDS_VALUE, value });
export const setModularField1Value = value => ({type: SIGNUP_MODULAR_FIELD_1_VALUE, value});
export const setModularField1Valid = error => ({type: SIGNUP_MODULAR_FIELD_1_VALID, error});
export const setModularField2Value = value => ({type: SIGNUP_MODULAR_FIELD_2_VALUE, value});
export const setModularField2Valid = error => ({type: SIGNUP_MODULAR_FIELD_2_VALID, error});
export const setModularField3Value = value => ({type: SIGNUP_MODULAR_FIELD_3_VALUE, value});
export const setModularField3Valid = error => ({type: SIGNUP_MODULAR_FIELD_3_VALID, error});
export const setModularField4Value = value => ({type: SIGNUP_MODULAR_FIELD_4_VALUE, value});
export const setModularField4Valid = error => ({type: SIGNUP_MODULAR_FIELD_4_VALID, error});
export const setModularField5Value = value => ({type: SIGNUP_MODULAR_FIELD_5_VALUE, value});
export const setModularField5Valid = error => ({type: SIGNUP_MODULAR_FIELD_5_VALID, error});
export const setModularField6Value = value => ({type: SIGNUP_MODULAR_FIELD_6_VALUE, value});
export const setModularField6Valid = error => ({type: SIGNUP_MODULAR_FIELD_6_VALID, error});
export const setModularField7Value = value => ({type: SIGNUP_MODULAR_FIELD_7_VALUE, value});
export const setModularField7Valid = error => ({type: SIGNUP_MODULAR_FIELD_7_VALID, error});
export const setModularField8Value = value => ({type: SIGNUP_MODULAR_FIELD_8_VALUE, value});
export const setModularField8Valid = error => ({type: SIGNUP_MODULAR_FIELD_8_VALID, error});
export const setUseIndustriesList = value => ({ type: SIGNUP_USE_INDUSTRIES_LIST_VALUE, value });
export const setIndustriesList = value => ({ type: SIGNUP_INDUSTRIES_LIST_VALUE, value });
export const setIndustriesListValue = value => ({ type: SIGNUP_SELECTED_INDUSTRIES_LIST_VALUE, value });
export const setIndustriesListValid = value => ({ type: SIGNUP_SELECTED_INDUSTRIES_LIST_VALID, value });