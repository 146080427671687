import * as deathTermCoverActions from '../../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../../../status';

export const initialState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    deathTermCoverAmount: null,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Term Cover',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address']  
    },
    form: {
        candidateDOB: { value: null, error: null, status: null },
        genderValue: { value: 'M', error: null, status: 'valid' },
        smokerCoveredValue: { value: 'No', error: null, status: 'valid' },
        coverAmount: { value: null, error: null, status: null },
        numberOfYearsCovered: { value: null, error: null, status: null },
        disabilityCoveredValue: { value: 'No', error: null, status: 'valid' },
    },
};

export default (state = initialState, action) => {
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case deathTermCoverActions.FORM_VALID_DEATH_TERM_COVER_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case deathTermCoverActions.FORM_INVALID_DEATH_TERM_COVER_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case deathTermCoverActions.GENDER_SET_VALUE:
            return updateFormField('genderValue', (field) => ({
                ...field,
                value: action.value,
            }));

        case deathTermCoverActions.CANDIDATE_DOB_SET_VALUE:
            return updateFormField('candidateDOB', (field) => ({
                ...field,
                value: action.value,
            }));

        case deathTermCoverActions.CANDIDATE_DOB_SET_VALID:
            return updateFormField('candidateDOB', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case deathTermCoverActions.SMOKER_COVERED_SET_VALUE:
            return updateFormField('smokerCoveredValue', (field) => ({
                ...field,
                value: action.value,
            }));

        case deathTermCoverActions.COVER_AMOUNT_SET_VALUE:
            return updateFormField('coverAmount', (field) => ({
                ...field,
                value: action.value,
            }));

        case deathTermCoverActions.COVER_AMOUNT_SET_VALID:
            return updateFormField('coverAmount', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case deathTermCoverActions.YEARS_COVERED_SET_VALUE:
            return updateFormField('numberOfYearsCovered', (field) => ({
                ...field,
                value: action.value,
            }));

        case deathTermCoverActions.YEARS_COVERED_SET_VALID:
            return updateFormField('numberOfYearsCovered', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case deathTermCoverActions.DISABILITY_COVERED_SET_VALUE:
            return updateFormField('disabilityCoveredValue', (field) => ({
                ...field,
                value: action.value,
            }));

        case deathTermCoverActions.WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value,
            };

        //    API Calls
        case deathTermCoverActions.API_PENDING_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case deathTermCoverActions.API_SUCCESS_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case deathTermCoverActions.API_FAILURE_TRANSITION_DEATH_TERM_COVER_QUOTE_TOOL:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        //    Calculate the cover amount
        case deathTermCoverActions.COLLECT_DEATH_TERM_COVER_PREMIUM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case deathTermCoverActions.CALCULATE_DEATH_TERM_COVER_SUCCESS:
            return { ...state, deathTermCoverAmount: action.value };

        case deathTermCoverActions.REINITIALIZE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
