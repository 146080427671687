import axios from 'axios';

import {
    calculatePhysicalImpairmentCoverData,
    apiTransitionPhysicalImpairmentCoverQuoteTool,
    setWhichFormToDisplay,
} from '../../../actions/roaQuoteTools/physicalImpairmentCoverQuoteTool';

import {
    getPhysicalImpairmentCoverQuoteToolForm,
    getIsApiPending,
} from '../../../selectors/roaQuoteTools/physicalImpairmentCoverQuoteTool';

export const physicalImpairmentCoverQuoteToolService = (
    store,
    next,
    action,
) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (!getIsApiPending(state)) {
            store.dispatch(
                apiTransitionPhysicalImpairmentCoverQuoteTool.pending(),
            );

            try {
                const response = await calculate(state);
                const quotationSummary = response.data;
                store.dispatch(
                    calculatePhysicalImpairmentCoverData(quotationSummary),
                );
                store.dispatch(
                    apiTransitionPhysicalImpairmentCoverQuoteTool.success(),
                );
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculatePhysicalImpairmentCoverData(null));
                store.dispatch(
                    apiTransitionPhysicalImpairmentCoverQuoteTool.failure(),
                );
            }
        }
    })();
    return result;
};

const getPhysicalImpairmentCoverPayload = formDetails => {
    if (formDetails.disabilityValue.value === 'Yes') {
        return {
            dob: formDetails.candidateDOB.value || '',
            gender: formDetails.genderValue.value || '',
            smoke_status: formDetails.smokerValue.value || '',
            txt_phy_imp: formDetails.coverAmount.value || '',
            txt_phy_imp_term: formDetails.numberOfYearsCovered.value || '',
            txt_phy_imp_dis_rider: formDetails.numberOfYearsCovered.value || '',
        };
    }
    return {
        dob: formDetails.candidateDOB.value || '',
        gender: formDetails.genderValue.value || '',
        smoke_status: formDetails.smokerValue.value || '',
        txt_phy_imp: formDetails.coverAmount.value || '',
        txt_phy_imp_term: formDetails.numberOfYearsCovered.value || '',
    };
};
const calculate = async state => {
    const physicalImpairmentCoverQuoteToolFormDetails = getPhysicalImpairmentCoverQuoteToolForm(
        state,
    );

    try {
        const url = '/om-api/roa-quote-tools/physical-impairment-insurance';
        const result = await axios.post(
            url,
            getPhysicalImpairmentCoverPayload(
                physicalImpairmentCoverQuoteToolFormDetails,
            ),
        );
        return result;
    } catch (error) {
        return null;
    }
};
