import {getReferrerId} from "../../../selectors/atRetirement/atRetirementApplication";
import {getAboutYouForm} from "../../../selectors/atRetirement/aboutYou";
import {getSolutionsForm} from "../../../selectors/atRetirement/solutions";
import {getSpeakToAnAdviserForm} from "../../../selectors/atRetirement/speakToAnAdviser";
import {
    sendSpeakToAnAdviserSuccess,
    sendSpeakToAnAdviserFailure
} from "../../../actions/atRetirement/speakToAnAdviser";
import axios from "axios";

export const speakToAnAdviserService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const referrerId = getReferrerId(state);
    const aboutYouForm = getAboutYouForm(state);
    const solutionsForm = getSolutionsForm(state);
    const speakToAnAdviserForm = getSpeakToAnAdviserForm(state);

    (async () => {
        store.dispatch(await sendEmail(referrerId, aboutYouForm, solutionsForm, speakToAnAdviserForm)
            ? sendSpeakToAnAdviserSuccess()
            : sendSpeakToAnAdviserFailure());
    })();

    return result;
};

// Exported for testing
export const sendEmail = async(referrerId, aboutYouForm, solutionsForm, speakToAnAdviserForm) => {
    try {
        const response = await axios.post('/om-api/at-retirement/speak-to-an-adviser',
            speakToAnAdviserRequestBody(referrerId, aboutYouForm, solutionsForm, speakToAnAdviserForm),
            {headers: { 'Content-Type': 'application/json' }});

        return response.status === 200;

    } catch(error) {
        return false;
    }
};

const speakToAnAdviserRequestBody = (referrerId, aboutYouFormFields, solutionFormFields, speakToAnAdviserFormFields) => {
    return {
        uid: referrerId,
        includeApplication: false,
        contactInterests: speakToAnAdviserFormFields.reasons.value || '',
        additionalHelpDescription: speakToAnAdviserFormFields.moreHelp.value || '',
        emailAddress: speakToAnAdviserFormFields.emailAddress.value || '',
        contactNumber: speakToAnAdviserFormFields.contactNumber.value || '',
        name: aboutYouFormFields.name.value || '',
        age: aboutYouFormFields.age.value || '',
        gender: aboutYouFormFields.gender.value || '',
        retirementFor: aboutYouFormFields.retirementFor.value || '',
        partnerAge: aboutYouFormFields.partnerAge.value || '',
        retirementWorry: aboutYouFormFields.retirementWorries.value || '',
        salary: aboutYouFormFields.salary.value  || 0,
        savings: aboutYouFormFields.savings.value  || 0,
        budget: aboutYouFormFields.budget.value || 0,
        selectedSolution:  solutionFormFields.solutionsProductChosen ?  solutionFormFields.solutionsProductChosen : '',
    }
};
