import { FORM_STATUS, TOGGLE_STATUS } from "../status";
import { SUBMISSION_STATUS } from "../../actions/taxFreeSavingsAccount/types";
import {
    RESET_REVIEW,
    TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE,
    SIDE_DRAWER_CLOSED_SET_VALUE,
    TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW,
    TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALID,
    APPLICATION_SUBMISSION_TRANSITION_REVIEW,
    APPLICATION_SUBMISSION_FAILURE_TRANSITION_REVIEW,
    APPLICATION_SUBMISSION_SUCCESS_TRANSITION_REVIEW,
} from "../../actions/taxFreeSavingsAccount/review";

export const initReview = {
    referenceNumber: null,
    submissionStatus: SUBMISSION_STATUS.IDLE,
    termsAndConditionsSideDrawer: null,
    termsAndConditionsValidationMessage: null,
    termsAndConditions: TOGGLE_STATUS.NO,
};

export default (state = initReview, action) => {
    switch (action.type) {
        case TERMS_AND_CONDITIONS_TOGGLE_TRANSITION_REVIEW:
            if (action.value) return { ...state, termsAndConditions: FORM_STATUS.INVALID };
            if (action.error) return { ...state, termsAndConditions: FORM_STATUS.INVALID };
            return { ...state, termsAndConditions: state.termsAndConditions !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO };

        case TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE:
            return { ...state, termsAndConditionsSideDrawer: action.value };

        case SIDE_DRAWER_CLOSED_SET_VALUE:
            return { ...state, termsAndConditionsSideDrawer: null };

        case RESET_REVIEW:
            return { ...initReview };

        case TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALID:
            return { ...state, termsAndConditionsValidationMessage: action.error };

        case APPLICATION_SUBMISSION_TRANSITION_REVIEW:
            return {
                ...state, 
                submissionStatus: SUBMISSION_STATUS.PENDING,
            };

        case APPLICATION_SUBMISSION_SUCCESS_TRANSITION_REVIEW:
            return {
                ...state, 
                submissionStatus: SUBMISSION_STATUS.SUCCESS,
                referenceNumber: action.value
            };

        case APPLICATION_SUBMISSION_FAILURE_TRANSITION_REVIEW:
            return {
                ...state, 
                submissionStatus: SUBMISSION_STATUS.ERROR,
            };

        default:
            return state;
    }
};
