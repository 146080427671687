import { getQuoteForm, getPremiumCalculation } from '../../../selectors/domesticInsurance/quote';
import { getPersonalDetailsForm } from '../../../selectors/domesticInsurance/personalDetails';
import { getBuildingDetailsForm } from '../../../selectors/domesticInsurance/buildingDetails';
import { getDocumentsForm } from '../../../selectors/domesticInsurance/documents';
import { getPaymentsForm } from '../../../selectors/domesticInsurance/payments';
import { getIsApiPending } from '../../../selectors/domesticInsurance/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/domesticInsurance/payloadCollection';
import { getAgentForm } from '../../../selectors/domesticInsurance/travelAgentModal';
import { getAccessToken, getPaymentStatusData, getModeOfPayments } from '../../../selectors/domesticInsurance/payments';
import axios from 'axios';
import { triggerConfirmOrder } from '../../../actions/domesticInsurance/payments';

const trimBase64Prefix = (base64String) => {
    const commaIndex = base64String.indexOf(',');

    // Check if the comma is found in the base64 string
    if (commaIndex !== -1) {
        // If found, remove everything before the comma
        return base64String.substring(commaIndex + 1);
    } else {
        // If not found, return the original string
        return base64String;
    }
};

function convertToISOFormat(dateStr) {
    // Split the input date string by '/'
    const [day, month, year] = dateStr.split('/');

    // Create a new Date object using the parsed values
    const date = new Date(`${year}-${month}-${day}`);

    // Get the components of the date in 'yyyy-mm-dd' format
    const formattedYear = date.getFullYear();
    const formattedMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const formattedDay = String(date.getDate()).padStart(2, '0');

    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
}

export const payloadSubmitService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await calculate(store.getState());

                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());
                store.dispatch(triggerConfirmOrder());
            } catch (e) {
                console.error(e);
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

const payloadCollection = (state) => {
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const buildingDetails = getBuildingDetailsForm(state);
    const documents = getDocumentsForm(state);
    const payments = getPaymentsForm(state);
    const agent = getAgentForm(state);
    const premium = getPremiumCalculation(state);
    const modeOfPayments = getModeOfPayments(state);

    const paymentsData = getPaymentStatusData(state);
    const premiumCalculationPayload = {
        countryCode: 'KE',
        annualPremium: premium.value.premium,
        coverStartDate: convertToISOFormat(quotation.coverStartDate.value),
        coverLimit: quotation?.buildingSum?.value || 0 + quotation?.contentsSum?.value || 0,
        valueOfLaptops: 'N/A',
        valueOfMobilePhones: 'N/A',
        valueOfOtherItems: 'N/A',
        valueOfBuilding: quotation?.buildingSum?.value || 'NA',
        selectedCover: `${quotation?.selectedCover?.value}`,
    };

    const buildingDetailsPayload = {
        interestOfFinancialInstitution: buildingDetails?.hasFinacialInterest?.value === 'No' ? false : true,
        financialInstitutionInfo: buildingDetails?.finacialInstitution?.value || 'NA',
        premisesStreet: buildingDetails?.streetOfPremise?.value || 'NA',
        premisesTown: buildingDetails?.townOfPremise?.value || 'NA',
        buildingType: buildingDetails?.buildingType?.value || 'NA',
        propertyUse: buildingDetails?.useOfProperty?.value || 'NA',
        roofType: buildingDetails?.roofNature?.value || 'NA',
        wallType: buildingDetails?.wallNature?.value || 'NA',
        location: buildingDetails?.location?.value || 'NA',
        numberOfFloors: buildingDetails?.noOfFloors?.value || 'NA',
        lrNumber: buildingDetails?.lrNumber?.value || 'NA',
        securityAvailable: buildingDetails?.security?.value || 'NA',
        valueOfPerimeterWall: buildingDetails?.perimeterWall?.value || 'NA',
        valueOfLandscaping: buildingDetails?.landScaping?.value || 'NA',
        valueOfSwimmingPool: buildingDetails?.swimmingPool?.value || 'NA',
        valueOfContents: quotation?.contentsSum?.value || 'NA',
    };

    const personalDetailsPayload = {
        firstName: personalDetails.firstName.value,
        lastName: personalDetails.lastName.value,
        emailAddress: personalDetails.email.value,
        dateOfBirth: convertToISOFormat(personalDetails.dob.value),
        idNumber: personalDetails.idNumber.value,
        phoneNumber: personalDetails.phoneNumber.value,
        taxPinNumber: personalDetails.tinNumber.value,
        postalAddress: personalDetails.postalAddress.value || 'N/A',
        streetAddress: personalDetails.streetAddress.value,
        suburb: personalDetails.suburbs.value,
        city: personalDetails.city.value,
    };

    const documentsPayload = {
        taxPinCertificate: documents.kraUpload.value.base64File,
        nationalIdCopy: documents.idUpload.value.base64File,
    };

    const agentPayload = {
        agentCode: agent?.name?.value?.agentCode || 'N/A',
        agentName: agent?.name?.value?.name || 'N/A',
    };

    const paymentPayload = {
        // remove hard coded value
        paymentReference: paymentsData?.CheckoutRequestID || 'N/A',
        paymentRef: paymentsData?.CheckoutRequestID || 'N/A',
        paymentChannel: modeOfPayments.value || 'N/A',
    };

    return {
        ...premiumCalculationPayload,
        ...personalDetailsPayload,
        ...buildingDetailsPayload,
        ...documentsPayload,
        ...agentPayload,
        ...paymentPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/domestic-insurance/submit-payload';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
