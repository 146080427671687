import { createSelector } from "reselect";
import { getAtRetirement } from "./index";

export const wizardFooter = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.wizardFooter
);

export const getAboutYouContinueButtonDisabled = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.aboutYouContinueButtonDisabled,
);

export const getSolutionsContinueButtonDisabled = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.solutionsContinueButtonDisabled,
);


export const getContinueButtonVisible = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.continueButtonVisible,
);

export const getStepIndex = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.stepIndex,
);

export const getIsIntroPage = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.stepIndex === -1,
);

export const getIsAboutYouPage = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.stepIndex === 0,
);

export const getIsSolutionsPage = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.stepIndex === 1,
);

export const getIsFinancialAdvicePage = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.stepIndex === 2,
);
