import {
    DOB_SET_VALUE,
    COVER_LIMIT_SET_VALUE,
    PROFESSION_SET_VALUE,
    SPOUSE_INCLUDED_SET_VALUE,
    SPOUSE_DOB_SET_VALUE,
    CHILDREN_INCLUDED_SET_VALUE,
    CHILDREN_DOBS_SET_VALUE,
    setDOBValid,
    setCoverLimitValid,
    setProfessionValid,
    setSpouseIncludedValid,
    setSpouseDobValid,
    setChildrenIncludedValid,
    setChildrenDobsValid,
} from '../../../actions/personalAccident/quote';
import { getCoverType } from '../../../selectors/personalAccident/quote';

import { validateDateOfBirth, validateSingleSelection, validateName } from '@om/validation';

import { validationIsEqual } from '../status';
let prevValidationAction = null;

export const quoteFormValidation = (store, next, action) => {
    //const quoteForm = getQuotationForm(store.getState());
    const validationAction = (() => {
        let error = null;

        const coverType = getCoverType(store.getState());

        switch (action.type) {
            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, {
                    min: coverType === 'adult' ? 18 : 0,
                    max: coverType === 'adult' ? 65 : 18,
                });

                return setDOBValid(error);

            case COVER_LIMIT_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Cover Limit',
                });
                return setCoverLimitValid(error);

            case PROFESSION_SET_VALUE:
                error = validateName(action.value, { min: 3, subject: 'Profession' });
                return setProfessionValid(error);

            case SPOUSE_INCLUDED_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Spouse Included',
                });
                return setSpouseIncludedValid(error);

            case SPOUSE_DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 18, max: 65 });
                return setSpouseDobValid(error);

            case CHILDREN_INCLUDED_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Children Included',
                });
                return setChildrenIncludedValid(error);

            case CHILDREN_DOBS_SET_VALUE:
                if (Array.isArray(action.value) && action.value.length > 0) {
                    for (const dob of action.value) {
                        const customError = validateDateOfBirth(dob, { min: 0, max: 18 });

                        if (customError !== null) {
                            error =
                                'Ensure that all dates of birth match the dd/mm/yyyy format and children are below the age of 18.';
                            break;
                        }
                    }
                } else {
                    const dob = Array.isArray(action.value) ? action.value[0] : action.value.newValue;
                    const customError = validateDateOfBirth(dob, { min: 0, max: 18 });

                    if (customError !== null) {
                        error =
                            'Ensure that all dates of birth match the dd/mm/yyyy format and children are below the age of 18.';
                    }
                }

                return setChildrenDobsValid(error);

            /*
            console.log(action.value);

            const childrenDobs = action.value;
            error = null;

            if (childrenDobs.length > 0) {
                for (const dob of childrenDobs) {
                    const customError = validateDateOfBirth(dob);

                    if (customError !== null) {
                        error = 'Ensure that all dates of birth match the dd/mm/yyyy format';
                        break;
                    }
                }
            } else {
                error = 'Enter atleast one date of birth for child';
            }

            return setChildrenDobsValid(error);
            */

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
