import {
    transitionPremierSavingsTool,
} from "../../../actions/roaQuoteTools/premierSavingsQuoteTool";

import {
    getPremierSavingsQuoteToolForm,
} from "../../../selectors/roaQuoteTools/premierSavingsQuoteTool";

import {FIELD_STATUS} from "../../../reducers/status";

export const premierSavingsQuoteToolFormRules = (store, next, action) => {
    const result = next(action);

    const premierSavingsQuoteToolForm = getPremierSavingsQuoteToolForm(store.getState());
    const premierSavingsQuoteToolValid = validatePremierSavingsQuoteToolFormDetails(premierSavingsQuoteToolForm);
    const formAction = premierSavingsQuoteToolValid
        ? transitionPremierSavingsTool.formValid()
        : transitionPremierSavingsTool.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validatePremierSavingsQuoteToolFormDetails = (formFields) => {
    return formFields.candidateDOB && formFields.candidateDOB.status === FIELD_STATUS.VALID &&
        formFields.monthlyPremiumAmount && formFields.monthlyPremiumAmount.status === FIELD_STATUS.VALID
};
