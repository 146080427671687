import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/travel/types';
import { getTravelInsurance } from './index';

const getRouting = createSelector(getTravelInsurance, (state) => state.routing);
export const getActivePageIndex = createSelector(
    getRouting,
    routing => routing.activePageIndex,
);
export const getDoTravelInsuranceNavigation = createSelector(
    getRouting,
    routing => routing.doNavigation,
);
export const getDoExitMotorInsuranceNavigation = createSelector(
    getRouting,
    routing => routing.doExitNavigation,
);

export const getLandingPage = createSelector(
    getRouting,
    routing => routing.landingPage,
);

export const getIsDropoffPage = createSelector(
    getRouting,
    routing => routing.activePageIndex === PAGE_INDICES.ABOUT_YOU,
);

const makeGetIsPage = pageIndex =>
    createSelector(
        getRouting,
        routing => routing.activePageIndex === pageIndex,
    );
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QOUTE);
export const getIsTravelDetailsPage = makeGetIsPage(PAGE_INDICES.TRAVEL_DETAILS);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getProgressBarIndex = createSelector(
    getIsQuotePage,
    getIsTravelDetailsPage,
    getIsPaymentPage,
    (isQuotePage, isTravelDetailsPage, isPaymentPage) => {
        if (isQuotePage) return 0;
        if (isTravelDetailsPage) return 1;
        if (isPaymentPage) return 2;
    }
);
