import { validateIdNumber } from "@om/validation";
import { getAge } from "@om/validation/src/validators/date-of-birth-validator";
import ReferenceData from "@om/validation/refData/reference-data"

export function validateID(value) {
    if (value.includes('*')) {
        return { isValid: false, errorMessage: 'Due to a security policy, please re-enter your ID number.' };
    } else if (validateIdNumber(value) === null) {
        const age = getAge({
            year:getFullBirthYear(
                parseInt(value.substring(0, 2))
            ),
            month:parseInt(value.substring(2, 4)),
            day: parseInt(value.substring(4, 6))
        });
        if (age < 18) {
            return { isValid: false, errorMessage: 'According to our lending criteria, customers must be at least 18 years old.' };
        } else if (age > 59) {
            return { isValid: false, errorMessage: 'Currently you do not meet our lending criteria, customer must be under 60 years old.' };
        }
        else {
            return { isValid: true, errorMessage: '' };
        }
    }
    return { isValid: false, errorMessage: 'Oops, the ID Number entered is not valid, please re-enter.' };
}

export function getFullBirthYear(birthYear) {
    const currentYear = parseInt(
        new Date().getFullYear().toString().substring(2, 4)
    );
    if (birthYear <= currentYear) {
        return 2000 + birthYear;
    }
    return 1900 + birthYear;
}

export function validateCellNumber(value) {
    if (value.includes('*')) {
        return { isValid: false, errorMessage: 'Due to a security policy, please re-enter your mobile number.' };
    }

    const errorMessage = 'Oops, the mobile number entered is not valid, please re-enter.';
    const numberValidator = new RegExp(/^(\+27|0)\d{9}$/);
    if(!numberValidator.test(value)){
        return {isValid:false, errorMessage:errorMessage};
    }
    let codeArray = ReferenceData.cellNumberPrefixes.filter(x=>x !== '087');
    codeArray.push('089');
    const modifiedNumber = /^(\+27)\d{9}$/.test(value) ? value.replace('+27','0'): value;
    const numberCode = modifiedNumber.substring(0,3);
    const isValid = codeArray.includes(numberCode);
    return {isValid:isValid, errorMessage:!isValid ? errorMessage : ''};
}