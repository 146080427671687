import {createSelector} from "reselect";

export const getSearch = (state => {
    return state.publicWeb.search
});

const makeGetField = fieldId => createSelector(
getSearch,
search => search[fieldId]
);

export const getSearchItemOne = makeGetField('searchItemOne');
export const getSearchItemTwo = makeGetField('searchItemTwo');
export const getSearchItemThree = makeGetField('searchItemThree');
export const getSearchQuery = makeGetField('searchQuery');
export const getCategory = makeGetField('category');
export const getSearchCategory = makeGetField('searchCategory');
export const getPage = makeGetField('page');
export const getPerPage = makeGetField('perPage');
export const getSearchResults = makeGetField('searchresult');
export const getAutoCompleteResults = makeGetField('autoCompleteResult');
export const getSite = makeGetField('site');
export const getFrequent = makeGetField('frequentResult');
export const getFetchingState = makeGetField('isFetching');
export const getQuicklinks = makeGetField('quicklinks');
export const getReferrerPath = createSelector(
    getSearch,
    search => encodeURIComponent(search.referrerPath)
);
