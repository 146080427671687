
import {
    validateEmail,
    validateName,
    validateContactNumber,
    validateSingleSelection,
    validateAlphaNumericString,
    validateNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateNumericString,
    validateAlphaString,
    validateStreetAddress,
} from '@om/validation';
import { setHomeDetailsValid } from '../../../actions/allsureInsurance/homeDetails';



let prevValidationAction = null;

export const homeDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        const trimValue = (value) => (value ? value.trim() : value);
        const removeWhiteSpacesFromValue = (value) => (value ? value.replace(/ /g, '') : value);
        let error;

        switch (action.value.fieldType) {
            case 'address':
                error = validateSingleSelection(action.value.value, 'location');
                break;

            case 'otherAddress':
                error = validateStreetAddress(trimValue(action.value.value), {
                    min: 0,
                    max: 2000,
                    subject: 'otherAddress',
                });
                break;
            case 'numberOfBedrooms':
                error = validateSingleSelection(action.value.value,'number of bedrooms');
                break;
            case 'numberOfBathrooms':
                error = validateSingleSelection(action.value.value,'number of bathrooms');
                break;
            case 'guiserSituated':
                error = validateSingleSelection(action.value.value,'guiser situated');
                break;
            case 'domesticWorker':
                error = validateSingleSelection(action.value.value,'domestic worker');
                break;
            case 'homeOccupied':
                error = validateSingleSelection(action.value.value,'home occupied');
                break;
            case 'razorWire':
                error = validateSingleSelection(action.value.value,'razor wire');
                break;
            // case 'householdLoss':
            //     error = validateSingleSelection(action.value.value,'household loss');
            //     break;
            // case 'claimAmount':
            //     error = validateNumber(action.value.value,{min:1,decimalPoints:0});
            //     break;

            // case 'claimedCount':
            //     error = validateNumber(action.value.value,{min:1,decimalPoints:0});
            //     break;
            
            case 'homehired':
                error = validateSingleSelection(action.value.value,'home hired');
                break;
            case 'inRetirement':
                error = validateSingleSelection(action.value.value,'in retirement');
                break;
            case 'premiumPaid':
                error = validateSingleSelection(action.value.value,'premium paid');
                break;
            case 'roofMaterialDetails':
                error = validateSingleSelection(action.value.value,'roof material');
                break;
            case 'mainWallConstructedOff':
                error = validateSingleSelection(action.value.value,'main wall details');
                break;
            case 'homeDetailsType':
                error = validateSingleSelection(action.value.value,'type of home');
                break;
            case 'insuredDetails':
                error = validateSingleSelection(action.value.value,'number of times insured');
                break;
            case 'insuredWithOM':
                error = validateSingleSelection(action.value.value,'number of times with om');
                break;
                
            case 'houseValue':
                error = validateNumber(action.value.value,{min:50000,decimalPoints:0});
                break;

            case 'accidentalDamageAmount':
                error = validateSingleSelection(action.value.value,'accidental damage amount');
                break;

            case 'powerSurgeCover':
                error = validateSingleSelection(action.value.value,'power surge cover amount');
                break;

            case 'domesticEmployees':
                error = validateSingleSelection(action.value.value,'domestic employees');
                break;
            case 'policyNumber':
                error=validateNumericString(action.value.value, { min: 5,max:10,subject:'Policy number'});
                break;
            default:
                return null;
        }

        return setHomeDetailsValid(error, action.value.fieldType);
    })();

    if (!validationAction) {
        return next(action);
    }


    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
