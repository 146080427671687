import {
    NAVIGATE_TO_INSURANCE_TYPE,
    NAVIGATE_TO_HOME_DETAILS,
    NAVIGATE_TO_MOTOR_DETAILS,
    NAVIGATE_TO_PERSONAL_DETAILS,
    NAVIGATE_TO_QUOTE_SUMMARY,
    SET_ACTIVE_PAGE_INDEX,
    SET_NAVIGATION_DONE,
    NAVIGATE_INDEX,
    NAVIGATE_TO_CALL_BACK,
    NAVIGATE_TO_PRODUCT_PAGE,
    NAVIGATE_TO_DOCUMENT_INFO,
    NAVIGATE_TO_PURCHASE_JORNEY_PERSONAL_DETAILS,
    NAVIGATE_TO_ADDITIONAL_DETAILS,
    NAVIGATE_TO_BANKING_DETAILS,
    NAVIGATE_TO_UPLOAD_DOCUMENTS,
    NAVIGATE_TO_CONFIRMATION,
    NAVIGATE_TO_SUCCESS_PAGE,
    NAVIGATE_TO_HOME_CONTENTS,
    // PROGRESS_BAR_TRANSITION,

  } from "../../actions/allsureInsurance/routing";
  
  import { PAGE_INDICES,PRODUCT_PAGE_URL,PROGRESS_BAR_STEPS } from "../../actions/allsureInsurance/types";
  
  export const initRouting = {
    activePageIndex: 0,
    progressBarSteps: PROGRESS_BAR_STEPS,
    doNavigation: false,
    doExitNavigation: false,
    parentAppUrl: PRODUCT_PAGE_URL,
    callbackAction: null,
    toParentApp: null,
  };
  
  export default (state = initRouting, action) => {
    const setActivePage = (pageIndex) => ({
      ...state,
      activePageIndex: pageIndex,
      doNavigation: true,
    });
  
    switch (action.type) {
      case NAVIGATE_INDEX:
        return {
          ...state,
          activePageIndex: action.value,
        };
  
      case SET_ACTIVE_PAGE_INDEX:
        return {
          ...state,
          doNavigation: true,
          activePageIndex: action.value,
        };
  
      case SET_NAVIGATION_DONE:
        return { ...state, doNavigation: false };
  
      case NAVIGATE_TO_INSURANCE_TYPE:
        return setActivePage(PAGE_INDICES.INSURANCE_TYPE);
  
      case NAVIGATE_TO_HOME_DETAILS:
        return setActivePage(PAGE_INDICES.HOME_DETAILS);
        
      case NAVIGATE_TO_HOME_CONTENTS:
        return setActivePage(PAGE_INDICES.HOME_CONTENTS);

      case NAVIGATE_TO_MOTOR_DETAILS:
        return setActivePage(PAGE_INDICES.MOTOR_DETAILS);
  
      case NAVIGATE_TO_PERSONAL_DETAILS:
        return setActivePage(PAGE_INDICES.PERSONAL_DETAILS);
  
      case NAVIGATE_TO_QUOTE_SUMMARY:
        return setActivePage(PAGE_INDICES.QUOTE_SUMMARY);

      case NAVIGATE_TO_CALL_BACK:
        return setActivePage(PAGE_INDICES.CALL_ME_BACK);

      case NAVIGATE_TO_DOCUMENT_INFO:
        return setActivePage(PAGE_INDICES.DOCUMENTS_INFO);

      case NAVIGATE_TO_PURCHASE_JORNEY_PERSONAL_DETAILS:
        return setActivePage(PAGE_INDICES.PURACHASE_JOURNEY_PERSONAL_DETAILS);

      case NAVIGATE_TO_ADDITIONAL_DETAILS:
        return setActivePage(PAGE_INDICES.ADDITIONAL_DETAILS);

      case NAVIGATE_TO_BANKING_DETAILS:
        return setActivePage(PAGE_INDICES.BANKING_DETAILS);
        
      case NAVIGATE_TO_UPLOAD_DOCUMENTS:
        return setActivePage(PAGE_INDICES.UPLOAD_DOCUMENTS);

      case NAVIGATE_TO_CONFIRMATION:
        return setActivePage(PAGE_INDICES.CONFIRM_DETAILS);

      case NAVIGATE_TO_SUCCESS_PAGE:
        return setActivePage(PAGE_INDICES.SUCCESS_PAGE);

      case NAVIGATE_TO_PRODUCT_PAGE:
        return {...state, toParentApp:true};
  
    //   case PROGRESS_BAR_TRANSITION:
    //     const  updatedSteps=PROGRESS_BAR_STEPS.map((step,index)=>index<=action.value?{...step,isActive:true,isCompleted:true}:step)
    //     return {
    //       ...state,
    //       progressBarSteps:updatedSteps
    //     }
      
      default:
        return state;
    }
  };
  