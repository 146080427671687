import { validateBase64File } from '@om/validation';
import {
	CAR_BACK_VIEW_SET_VALUE,
	CAR_DASHBOARD_VIEW_SET_VALUE,
	CAR_FRONT_VIEW_SET_VALUE,
    CAR_LEFT_VIEW_SET_VALUE,
    CAR_RIGHT_VIEW_SET_VALUE,
    ID_UPLOAD_SET_VALUE,
    setBackViewValid,
    setDashboardViewValid,
    setFrontViewValid,
    setIdUploadValid,
    setLeftViewValid,
    setRightSideValid,
    setYellowCardValid,
    YELLOW_CARD_SET_VALUE,
} from '../../../actions/motorPrivateRW/Documents';
import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const documentsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ID_UPLOAD_SET_VALUE:
                error = validateBase64File(action.value ? action.value?.base64File : null, {
                    subject: 'ID',
                    required: true,
                });
                return setIdUploadValid(error);
            case YELLOW_CARD_SET_VALUE:
                error = validateBase64File(action.value ? action.value?.base64File : null, {
                    subject: 'ID',
                    required: true,
                });
                return setYellowCardValid(error);

            case CAR_LEFT_VIEW_SET_VALUE:
                error = validateBase64File(action.value ? action.value?.base64File : null, {
                    subject: 'ID',
                    required: true,
                });
                return setLeftViewValid(error);

            case CAR_RIGHT_VIEW_SET_VALUE:
                error = validateBase64File(action.value ? action.value?.base64File : null, {
                    subject: 'ID',
                    required: true,
                });
                return setRightSideValid(error);

            case CAR_FRONT_VIEW_SET_VALUE:
                error = validateBase64File(action.value ? action.value?.base64File : null, {
                    subject: 'ID',
                    required: true,
                });
                return setFrontViewValid(error);

            case CAR_BACK_VIEW_SET_VALUE:
                error = validateBase64File(action.value ? action.value?.base64File : null, {
                    subject: 'ID',
                    required: true,
                });
                return setBackViewValid(error);

            case CAR_DASHBOARD_VIEW_SET_VALUE:
                error = validateBase64File(action.value ? action.value?.base64File : null, {
                    subject: 'ID',
                    required: true,
                });
                return setDashboardViewValid(error);

            default:
                return null;
        }
    })();
    if (!validationAction || (validationIsEqual(validationAction, prevValidationAction))) {
        return next(action);
    }
    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
