import { transitionPersonalAccidentQuoteForm } from '../../../actions/personalAccident/quote';
import { getQuoteForm, getCoverType } from '../../../selectors/personalAccident/quote';

import { FIELD_STATUS } from '../../../reducers/status';

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getQuoteForm(store.getState());
    const coverType = getCoverType(store.getState());
    const quoteFormValid = validateQuoteForm(quoteForm, coverType);
    const formAction = quoteFormValid
        ? transitionPersonalAccidentQuoteForm.formValid()
        : transitionPersonalAccidentQuoteForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateQuoteForm = (formFields, coverType) => {
    let commonChecks;

    if (coverType === 'minor') {
        commonChecks =
            formFields.dob.status === FIELD_STATUS.VALID && formFields.coverLimit.status === FIELD_STATUS.VALID;

        return commonChecks;
    } else {
        commonChecks =
            formFields.dob.status === FIELD_STATUS.VALID &&
            formFields.coverLimit.status === FIELD_STATUS.VALID &&
            formFields.profession.status === FIELD_STATUS.VALID &&
            formFields.spouseIncluded.status === FIELD_STATUS.VALID &&
            formFields.childrenIncluded.status === FIELD_STATUS.VALID;

        let spouseDetailsValid = true;

        if (formFields.spouseIncluded === 'yes') {
            spouseDetailsValid = formFields.spouseDob.status === FIELD_STATUS.VALID;
        }

        let childrenDobsValid = true;

        if (formFields.childrenIncluded === 'yes') {
            childrenDobsValid = formFields.childrenDobs.status === FIELD_STATUS.VALID;
        }

        return commonChecks && spouseDetailsValid && childrenDobsValid;
    }
};
