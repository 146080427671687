import { createSelector } from 'reselect';
import { getPriceTable } from './index'
import { API_CALL_STATUS, FORM_STATUS } from '../../reducers/status';

export const getTable = createSelector(
    getPriceTable,
    priceTable => priceTable.table
)

export const getFunds = createSelector(
    getTable,
    table => table.funds
)

export const getPaginationRanges = createSelector(
    getTable,
    table => table.paginationRanges
)

export const getPaginationTableSize = createSelector(
    getTable,
    table => table.paginationTableSize
)

export const isSearchButtonDisabled = createSelector(
    getTable,
    table => table.formStatus === FORM_STATUS.INVALID
)

export const isApiPending = createSelector(
    getTable,
    table => table.apiCallStatus === API_CALL_STATUS.PENDING
)

export const getTableForm = createSelector(
    getTable,
    table => table.form
)

const makeField = (fieldId) => createSelector(
    getTableForm,
    tableForm => tableForm[fieldId]
)

export const getSelectedDate = makeField('selectedDate');
