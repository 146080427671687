import { BIASHARA_FLEXI_AGENT_MODAL_DETAILS_SET_VALID, BIASHARA_FLEXI_AGENT_MODAL_DETAILS_SET_VALUE } from "../../../actions/biasharaFlexi/agentModal";
import { BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE, BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID } from "../../../actions/biasharaFlexi/companyDetails";
import { BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALID, BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE } from "../../../actions/biasharaFlexi/coverDetails";
import { BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE, BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID } from "../../../actions/biasharaFlexi/employeeDetails";
import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from "../../../actions/biasharaFlexi/payment";
import { BIASHARA_FLEXI_FORM_SET_VALID, BIASHARA_FLEXI_FORM_SET_VALUE, SET_SHARE_EMAIL_VALID, SET_SHARE_EMAIL_VALUE } from "../../../actions/biasharaFlexi/quote";
import { SET_VALUE_ROUTING } from "../../../actions/biasharaFlexi/routing";
import { BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALUE, BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALID } from "../../../actions/biasharaFlexi/uploadDocuments";
import { agentFormRules } from "./agent";
import { companyDetailsFormRules } from "./companyDetials";
import { coverDetailsFormRules } from "./coverDetails";
import { employeeDetailsFormRules } from "./employeeDetails";
import { paymentsFormRules } from "./payments";
import { routingRules } from "./routing";
import { shareQuoteFormRules } from "./shareQuote";
import { uploadDocumentsFormRules } from "./uploadDocuments";

export const biasharaFlexiRules = (store, next, action) => {
	if (action.type.startsWith(SET_VALUE_ROUTING)) {
		return routingRules(store, next, action);
	}

	if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
		return paymentsFormRules(store, next, action);
	}

	if(action.type.startsWith(BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALUE) || action.type.startsWith(BIASHARA_FLEXI_UPLOAD_DOCUMENTS_FORM_SET_VALID)) {
		return uploadDocumentsFormRules(store, next, action)
	}

	if(action.type.startsWith(BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VALUE) || action.type.startsWith(BIASHARA_FLEXI_EMPLOYEE_DETAILS_FORM_SET_VAVID)) {
		return employeeDetailsFormRules(store, next, action)
	}

	if(action.type.startsWith(BIASHARA_FLEXI_AGENT_MODAL_DETAILS_SET_VALUE) || action.type.startsWith(BIASHARA_FLEXI_AGENT_MODAL_DETAILS_SET_VALID)) {
		return agentFormRules(store, next, action)
	}

	if(action.type.startsWith(BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALUE) || action.type.startsWith(BIASHARA_FLEXI_COVER_DETAILS_FORM_SET_VALID)) {
		return coverDetailsFormRules(store, next, action)
	}

    if (action.type.startsWith(BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VALUE) || action.type.startsWith(BIASHARA_FLEXI_COMPANY_DETAILS_FORM_SET_VAVID)) {
        return companyDetailsFormRules(store, next, action);
    }

	if (action.type.startsWith(BIASHARA_FLEXI_FORM_SET_VALUE) || action.type.startsWith(BIASHARA_FLEXI_FORM_SET_VALID)) {
		return shareQuoteFormRules(store, next, action);
	}

	return null;
};