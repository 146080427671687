import { DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALID, DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE } from "../../../actions/doctorsPi/agent";
import { DOCTORS_PI_DECLARATIONS_FORM_SET_VALID, DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE, DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID, DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE } from "../../../actions/doctorsPi/documents";
import { PAYMENTS_SET_VALID, PAYMENTS_SET_VALUE } from "../../../actions/doctorsPi/payments";
import { DOCTORS_PI_PERSONAL_DETAILS_FORM_SET_VALUE, DOCTORS_PI_PERSONAL_DETAILS_FORM_SET_VAVID } from "../../../actions/doctorsPi/personalDetails";
import { DOCTORS_PI_QOUTE_FORM_SET_VALID, DOCTORS_PI_QOUTE_FORM_SET_VALUE, SET_SHARE_EMAIL_VALID, SET_SHARE_EMAIL_VALUE } from "../../../actions/doctorsPi/quote";
import { SET_VALUE_ROUTING } from "../../../actions/doctorsPi/routing";
import { agentFormRules } from "./agent";
import { declarationsFormRules } from "./declarations";
import { uploadDocumentsFormRules } from "./documents";
import { paymentsFormRules } from "./payments";
import { personalDetailsFormRules } from "./personalDetails";
import { quoteFormRules } from "./quote";
import { routingRules } from "./routing";
import { shareQuoteFormRules } from "./shareQuote";

export const doctorsPiRules = (store, next, action) => {
	if (action.type.startsWith(SET_VALUE_ROUTING)) {
		return routingRules(store, next, action);
	}

	if (action.type.startsWith(DOCTORS_PI_QOUTE_FORM_SET_VALUE) || action.type.startsWith(DOCTORS_PI_QOUTE_FORM_SET_VALID)) {
		return quoteFormRules(store, next, action);
	}

	if (action.type.startsWith(DOCTORS_PI_PERSONAL_DETAILS_FORM_SET_VALUE) || action.type.startsWith(DOCTORS_PI_PERSONAL_DETAILS_FORM_SET_VAVID)) {
		return personalDetailsFormRules(store, next, action);
	}

	if (action.type.startsWith(DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALUE) || action.type.startsWith(DOCTORS_PI_UPLOAD_DOCUMENTS_FORM_SET_VALID)) {
		return uploadDocumentsFormRules(store, next, action);
	}

	if (action.type.startsWith(DOCTORS_PI_DECLARATIONS_FORM_SET_VALUE) || action.type.startsWith(DOCTORS_PI_DECLARATIONS_FORM_SET_VALID)) {
		return declarationsFormRules(store, next, action);
	}

	if (action.type.startsWith(PAYMENTS_SET_VALUE) || action.type.startsWith(PAYMENTS_SET_VALID)) {
		return paymentsFormRules(store, next, action);
	}

	if(action.type.startsWith(DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALUE) || action.type.startsWith(DOCTORS_PI_AGENT_MODAL_DETAILS_SET_VALID)) {
        return agentFormRules(store, next, action)
    }

	if (action.type.startsWith(SET_SHARE_EMAIL_VALUE) || action.type.startsWith(SET_SHARE_EMAIL_VALID)) {
		return shareQuoteFormRules(store, next, action);
	}

	return null;
};
