export const TRAVEL_INSURANCE_TRAVEL_DETAILS =
    'roaTravelInsurance/travelDetails/';
export const TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE =
    TRAVEL_INSURANCE_TRAVEL_DETAILS + 'setValue/';

export const TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALID =
    TRAVEL_INSURANCE_TRAVEL_DETAILS + 'setValid/';

export const WHICH_FORM_TO_DISPLAY =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE + 'whichFormToDisplay';

export const REINITIALIZE =
    'roaTravelInsurance/travelDetails/state/reinitialize';

// People traveling form
export const TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALUE =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE + 'formOne/';
export const TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALID =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALID + 'formOne/';

export const FORM_VALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM =
    'roaTravelInsurance/travelDetails/transition/formValid';
export const FORM_INVALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM =
    'roaTravelInsurance/travelDetails/transition/formInValid';

// Where are you traveling to?
export const TRAVEL_INSURANCE_DESTINATION_SET_VALUE =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE + 'formTwo/';
export const TRAVEL_INSURANCE_DESTINATION_SET_VALID =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALID + 'formTwo/';

export const FORM_VALID_TRAVEL_INSURANCE_DESTINATION_FORM =
    'roaTravelInsurance/travelDetails/transition/formTwoValid';
export const FORM_INVALID_TRAVEL_INSURANCE_DESTINATION_FORM =
    'roaTravelInsurance/travelDetails/transition/formTwoInValid';

// Valid transition
export const transitionTravelInsuranceTravelDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_TRAVEL_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_TRAVEL_DETAILS_FORM = `${TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_TRAVEL_DETAILS_FORM = `${TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_TRAVEL_DETAILS_FORM = `${TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE}/apiTransition/failure`;

export const apiTransitionTravelDetailsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_TRAVEL_DETAILS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_TRAVEL_DETAILS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_TRAVEL_DETAILS_FORM,
    }),
};

//calculated Data
export const COLLECT_TRAVEL_DETAILS_DATA =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE + 'collectTravelDetailsData';

export const SUBMIT_TRAVEL_DETAILS_SUCCESS =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE + 'success';
export const SUBMIT_TRAVEL_DETAILS_FAILURE =
    TRAVEL_INSURANCE_TRAVEL_DETAILS_SET_VALUE + 'failure';

export const collectTravelDetailsData = () => ({
    type: COLLECT_TRAVEL_DETAILS_DATA,
});
export const submitTravelDetailsSuccess = value => ({
    type: SUBMIT_TRAVEL_DETAILS_SUCCESS,
    value,
});
export const submitTravelDetailsFailure = error => ({
    type: SUBMIT_TRAVEL_DETAILS_FAILURE,
    error,
});

// for both traveling from and people traveling

export const setWhichFormToDisplay = value => ({
    type: WHICH_FORM_TO_DISPLAY,
    value,
});

export const setToTravelDetailsInitial = () => ({
    type: REINITIALIZE,
});

// Who is traveling form
export const ADULTS_SET_VALUE =
    TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALUE + 'adults';
export const ADULTS_SET_VALID =
    TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALID + 'adults';

export const KIDS_SET_VALUE =
    TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALUE + 'kids';
export const KIDS_SET_VALID =
    TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALID + 'kids';

export const SENIORS_SET_VALUE =
    TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALUE + 'seniors';
export const SENIORS_SET_VALID =
    TRAVEL_INSURANCE_PEOPLE_TRAVELING_SET_VALID + 'seniors';

export const setAdultValue = value => ({
    type: ADULTS_SET_VALUE,
    value,
});
export const setAdultValid = error => ({
    type: ADULTS_SET_VALID,
    error,
});

export const setKidsValue = value => ({
    type: KIDS_SET_VALUE,
    value,
});
export const setKidsValid = error => ({
    type: KIDS_SET_VALID,
    error,
});

export const setSeniorsValue = value => ({
    type: SENIORS_SET_VALUE,
    value,
});
export const setSeniorsValid = error => ({
    type: SENIORS_SET_VALID,
    error,
});

// Where are you traveling to form
export const FROM_SET_VALUE = TRAVEL_INSURANCE_DESTINATION_SET_VALUE + 'from';
export const FROM_SET_VALID = TRAVEL_INSURANCE_DESTINATION_SET_VALID + 'from';

export const TO_SET_VALUE = TRAVEL_INSURANCE_DESTINATION_SET_VALUE + 'to';
export const TO_SET_VALID = TRAVEL_INSURANCE_DESTINATION_SET_VALID + 'to';

export const DEPARTURE_DATE_SET_VALUE =
    TRAVEL_INSURANCE_DESTINATION_SET_VALUE + 'departureDate';
export const DEPARTURE_DATE_SET_VALID =
    TRAVEL_INSURANCE_DESTINATION_SET_VALID + 'departureDate';

export const RETURN_DATE_SET_VALUE =
    TRAVEL_INSURANCE_DESTINATION_SET_VALUE + 'returnDate';
export const RETURN_DATE_SET_VALID =
    TRAVEL_INSURANCE_DESTINATION_SET_VALID + 'returnDate';

export const REASON_FOR_TRAVEL_SET_VALUE =
    TRAVEL_INSURANCE_DESTINATION_SET_VALUE + 'reasonForTravel';
export const REASON_FOR_TRAVEL_SET_VALID =
    TRAVEL_INSURANCE_DESTINATION_SET_VALID + 'reasonForTravel';

export const setFromValue = value => ({
    type: FROM_SET_VALUE,
    value,
});
export const setFromValid = error => ({
    type: FROM_SET_VALID,
    error,
});

export const setToValue = value => ({
    type: TO_SET_VALUE,
    value,
});
export const setToValid = error => ({
    type: TO_SET_VALID,
    error,
});

export const setDepartureDateValue = value => ({
    type: DEPARTURE_DATE_SET_VALUE,
    value,
});
export const setDepartureDateValid = error => ({
    type: DEPARTURE_DATE_SET_VALID,
    error,
});

export const setReturnDateValue = value => ({
    type: RETURN_DATE_SET_VALUE,
    value,
});
export const setReturnDateValid = error => ({
    type: RETURN_DATE_SET_VALID,
    error,
});

export const setReasonForTravelValue = value => ({
    type: REASON_FOR_TRAVEL_SET_VALUE,
    value,
});
export const setReasonForTravelValid = error => ({
    type: REASON_FOR_TRAVEL_SET_VALID,
    error,
});
