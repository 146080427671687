import axios from 'axios';

import {
    calculateAfyaImaraPremiumData,
    apiTransitionAfyaImaraQuoteTool,
    setWhichFormToDisplay,
} from "../../../actions/roaQuoteTools/afyaImaraQuoteTool";

import {
    getAfyaImaraQuoteToolForm,
    getIsApiPending
} from '../../../selectors/roaQuoteTools/afyaImaraQuoteTool';

export const AfyaImaraQuoteToolService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    (async () => {
        if (!getIsApiPending(state)) {
            store.dispatch(apiTransitionAfyaImaraQuoteTool.pending());

            try {
                const response = await calculate(state);
                const CountyPremiumAmount = response.data.total_premiums
                store.dispatch(calculateAfyaImaraPremiumData(CountyPremiumAmount));
                store.dispatch(apiTransitionAfyaImaraQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateAfyaImaraPremiumData(null));
                store.dispatch(apiTransitionAfyaImaraQuoteTool.failure());
            }
        }
    })();
    return result;
}
const getCountyPayload = (formDetails) => {
    return {

        "cover_id": 1 || '',
        "age_group": formDetails.ageBracket.value || '',
        "spouse": formDetails.spouseCover.value ? formDetails.spouseCover.value : 'no' || '',
        "spouse_age_group": formDetails.spouseAge.value || '',
        "children_status": formDetails.childCover.value ? formDetails.childCover.value : 'no' || '',
        "children_18": (formDetails.childCover.value === 'no') ? 0 : (Number(formDetails.child_18.value) ? Number(formDetails.child_18.value) : Number(formDetails.child_18.value)),
        "children_19": (formDetails.childCover.value === 'no') ? 0 : (Number(formDetails.child_19_29.value) ? Number(formDetails.child_19_29.value) : Number(formDetails.child_19_29.value)),
        "children_30": (formDetails.childCover.value === 'no') ? 0 : (Number(formDetails.child_30_40.value) ? Number(formDetails.child_30_40.value) : Number(formDetails.child_30_40.value)),
        "children_41": (formDetails.childCover.value === 'no') ? 0 : (Number(formDetails.child_40_50.value) ? Number(formDetails.child_40_50.value) : Number(formDetails.child_40_50.value)),
        "inpatient": formDetails.inpatientCoverLimit.value || '',
        "outpatient": formDetails.outpatientCoverLimit.value || '',
    }
}

const calculate = async (state) => {
    const CountyFormDetails = getAfyaImaraQuoteToolForm(state)

    try {
        const url = '/om-api/roa-quote-tools/afya-imara-quote'
        const result = await axios.post(url, getCountyPayload(CountyFormDetails))
        return result;
    } catch (error) {
        return null;
    }
}