export const ROA_TRAVEL_PLAN = 'roaTravelInsurance/roaTravelPlans/travelPlans/';
export const ROA_TRAVEL_PLAN_SET_VALUE = ROA_TRAVEL_PLAN + 'setValue/';
export const ROA_TRAVEL_PLAN_SET_VALID = ROA_TRAVEL_PLAN + 'setValid/';

export const FORM_VALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM =
    'roaTravelInsurance/selectedTravelPlan/transition/formValid';
export const FORM_INVALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM =
    'roaTravelInsurance/selectedTravelPlan/transition/formInValid';

export const ROA_TRAVEL_INSURANCE_SELECTED_VALUE =
    ROA_TRAVEL_PLAN_SET_VALUE + 'selectedTravelPlan';
export const ROA_TRAVEL_INSURANCE_SELECTED_VALID =
    ROA_TRAVEL_PLAN_SET_VALID + 'selectedTravelPlan';

export const REINITIALIZE = ROA_TRAVEL_PLAN + '/state/reinitialize';

export const transitionTravelInsuranceTravelPlanForm = {
    formValid: () => ({
        type: FORM_VALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_TRAVEL_INSURANCE_SELECTED_PLAN_FORM,
    }),
};

export const setSelectedTravelPlanValue = value => ({
    type: ROA_TRAVEL_INSURANCE_SELECTED_VALUE,
    value,
});
export const setSelectedTravelPlanValid = error => ({
    type: ROA_TRAVEL_INSURANCE_SELECTED_VALID,
    error,
});

export const setToInitial = () => ({ type: REINITIALIZE });
