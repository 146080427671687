import axios from 'axios';

import {
    calculateCriticalillnessPremiumData,
    apiTransitioncriticalIllnessQuoteTool,
    setWhichFormToDisplay,
} from '../../../actions/roaQuoteTools/criticalIllnessQuoteTool';

import {
    getcriticalIllnessQuoteToolForm,
    getIsApiPending,
} from '../../../selectors/roaQuoteTools/criticalIllnessQuoteTool';

export const CriticalIllnessQuoteToolService = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    (async () => {
        if (!getIsApiPending(state)) {
            store.dispatch(apiTransitioncriticalIllnessQuoteTool.pending());

            try {
                const response = await calculate(state);

                const criticalIllnessQuoteToolPremiumAmount = response.data.total_premium
                store.dispatch(calculateCriticalillnessPremiumData(criticalIllnessQuoteToolPremiumAmount));
                store.dispatch(apiTransitioncriticalIllnessQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(calculateCriticalillnessPremiumData(null));
                store.dispatch(apiTransitioncriticalIllnessQuoteTool.failure());
            }
        }
    })();
    return result;
}

const getCriticalIllnessPayload = (formDetails) => {
    return {
        dob: formDetails.DateOfBirth.value || '',
        gender: formDetails.genderValue.value || '',
        smoke_status: formDetails.smokingValue.value || '',
        txt_sev_illness: Number(formDetails.coveredupValue.value),
        txt_sev_illness_term: Number(formDetails.yearValue.value),
        txt_sev_dis_rider:
            formDetails.DisabilityValue.value === 'No'
                ? 0
                : Number(formDetails.yearValue.value),
    };
};

const calculate = async (state) => {
    const CriticalIllnessFormDetails = getcriticalIllnessQuoteToolForm(state);

    try {
        const url = '/om-api/roa-quote-tools/critical-illness';
        const result = await axios.post(
            url,
            getCriticalIllnessPayload(CriticalIllnessFormDetails)
        );

        return result;
    } catch (error) {
        return null;
    }
};
