import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    CANDIDATE_DOB_SET_VALUE,
    CANDIDATE_DOB_SET_VALID,
    SMOKER_SET_VALUE,
    SMOKER_SET_VALID,
    COVER_AMOUNT_SET_VALUE,
    COVER_AMOUNT_SET_VALID,
    COVER_YEARS_SET_VALUE,
    COVER_YEARS_SET_VALID,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    COLLECT_ACCIDENTAL_DISABILITY_PREMIUM_DATA,
    CALCULATE_ACCIDENTAL_DISABILITY_PREMIUM_DATA ,
    API_PENDING_TRANSITION_ACCIDENTAL_DISABILITY_TOOL,
    API_SUCCESS_TRANSITION_ACCIDENTAL_DISABILITY_TOOL,
    API_FAILURE_TRANSITION_ACCIDENTAL_DISABILITY_TOOL,
    FORM_VALID_ACCIDENTAL_DISABILITY_TOOL,
    FORM_INVALID_ACCIDENTAL_DISABILITY_TOOL
} from "../../actions/roaQuoteTools/accidentalDisabilityQuoteTool";

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    accidentalDisabilityPremiumAmount: null,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Accidental Disability Cover',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address']
    },
    form: {
        coverAmount:{ value: null, error: null, status: null},
        smokerInput: {value: 'NS', error: null, status: 'valid'},
        genderInput: { value: 'M', error: null, status: 'valid' },
        candidateDob: { value: null, error: null, status: null },
        coverYearsValue: { value: null, error: null, status: null },
    },
};

export default (state = initialeState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    
    switch (action.type) {
        // Form Validation
        case FORM_VALID_ACCIDENTAL_DISABILITY_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_ACCIDENTAL_DISABILITY_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        // Api call
        case API_PENDING_TRANSITION_ACCIDENTAL_DISABILITY_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_ACCIDENTAL_DISABILITY_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_ACCIDENTAL_DISABILITY_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        case SMOKER_SET_VALUE:
            return updateFormField('smokerInput', field => ({
                ...field,
                value: action.value,
            }));
        case SMOKER_SET_VALID:
            return updateFormField('smokerInput', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Gender value input
        case GENDER_SET_VALUE:
            return updateFormField('genderInput', field => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('genderInput', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CANDIDATE_DOB_SET_VALUE:
            return updateFormField('candidateDob', field => ({
                ...field,
                value: action.value,
            }));
        case CANDIDATE_DOB_SET_VALID:
            return updateFormField('candidateDob', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COVER_AMOUNT_SET_VALUE:
            return updateFormField('coverAmount', field => ({
                ...field,
                value: action.value,
            }));
        case COVER_AMOUNT_SET_VALID:
            return updateFormField('coverAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COVER_YEARS_SET_VALUE:
            return updateFormField('coverYearsValue', field => ({
                ...field,
                value: action.value,
            }));
        case COVER_YEARS_SET_VALID:
            return updateFormField('coverYearsValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value
            };
        case REINITIALIZE:
            return initialeState
        case CALCULATE_ACCIDENTAL_DISABILITY_PREMIUM_DATA:
            return {
                ...state,
                accidentalDisabilityPremiumAmount: action.value
            }
        case COLLECT_ACCIDENTAL_DISABILITY_PREMIUM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        default:
            return state;
    }
}
