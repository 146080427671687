import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';

import {
    CANDIDATE_DOB_SET_VALUE,
    CANDIDATE_DOB_SET_VALID,
    TERM_INPUT_SET_VALUE,
    TERM_INPUT_SET_VALID,
    MONTHLY_PREMIUM_AMOUNT_SET_VALUE,
    MONTHLY_PREMIUM_AMOUNT_SET_VALID,
    ENABLE_LUMPSUM_INPUT_SET_VALUE,
    SHORT_TERM_INPUT_SET_VALUE,
    SHORT_TERM_INPUT_SET_VALID,
    LONG_TERM_INPUT_SET_VALUE,
    LONG_TERM_INPUT_SET_VALID,
    FORM_VALID_PREMIER_SAVINGS_QUOTE_TOOL,
    FORM_INVALID_PREMIER_SAVINGS_QUOTE_TOOL,
    LUMPSUM_AMOUNT_SET_VALUE,
    LUMPSUM_AMOUNT_SET_VALID,
    LUMPSUM_YEAR_SET_VALUE,
    LUMPSUM_YEAR_SET_VALID,
    LUMPSUM_MONTH_SET_VALUE,
    LUMPSUM_MONTH_SET_VALID,
    NEW_LUMPSUM_PAYMENT_SET_VALUE,
    DELETE_LUMPSUM_PAYMENT_SET_VALUE,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    COLLECT_PREMIER_SAVINGS_DATA,
    CALCULATE_PREMIER_SAVINGS_DATA,
    API_PENDING_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL,
    API_FAILURE_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL,
} from "../../actions/roaQuoteTools/premierSavingsQuoteTool";

export const newPaymentDetails = () => ({
    lumpsumAmount: {value: null, error: null, status: null},
    lumpsumYear: {value: '1', error: null, status: 'valid'},
    lumpsumMonth: {value: '1', error: null, status: 'valid'}
});

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.INVALID,
    enableLumpsumInput: '2',
    quotationSummary: null,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Lengo Savings Plan',
        privacy_policy_url: '/privacy-policy/',
        extra_fields:['Email Address'] 
    },
    form: {
        candidateDOB: { value: null, error: null, status: null },
        termInputValue: { value: '5', error: null, status: 'valid' },
        monthlyPremiumAmount: {value: null, error: null, status: null},
        shortTermInputValue: { value: '9', error: null, status: 'valid' },
        longTermInputValue: { value: '9', error: null, status: 'valid' },
        lumpsumPaymentDetails: [],
    },
};

export default (state = initialeState, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    
    const updateValidArray = (field, paymentId) => {
        const validArray = [...state.form.lumpsumPaymentDetails];        
        validArray[paymentId][field].value = action.value;
        return validArray;
    }

    const updateInValidArray = (field, paymentId, errorMsg) => {
        const invalidArray = [...state.form.lumpsumPaymentDetails];
        invalidArray[paymentId][field].error = errorMsg;
        invalidArray[paymentId][field].status = mapError(errorMsg);
        return invalidArray;
    }

    switch (action.type) {
        // Form Validation
        case FORM_VALID_PREMIER_SAVINGS_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_PREMIER_SAVINGS_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        // Api call
        case API_PENDING_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        // Date of birth
        case CANDIDATE_DOB_SET_VALUE:
            return updateFormField('candidateDOB', field => ({
                ...field,
                value: action.value,
            }));
        case CANDIDATE_DOB_SET_VALID:
            return updateFormField('candidateDOB', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // term input value
        case TERM_INPUT_SET_VALUE:
            return updateFormField('termInputValue', field => ({
                ...field,
                value: action.value,
            }));
        case TERM_INPUT_SET_VALID:
            return updateFormField('termInputValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
            // Monthly premium amount
        case MONTHLY_PREMIUM_AMOUNT_SET_VALUE:
            return updateFormField('monthlyPremiumAmount', field => ({
                ...field,
                value: action.value,
            }));
        case MONTHLY_PREMIUM_AMOUNT_SET_VALID:
            return updateFormField('monthlyPremiumAmount', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // enable lumpsum input value
        case ENABLE_LUMPSUM_INPUT_SET_VALUE:
            return {
                ...state,
                enableLumpsumInput: action.value
            };
        // short term input value
        case SHORT_TERM_INPUT_SET_VALUE:
            return updateFormField('shortTermInputValue', field => ({
                ...field,
                value: action.value,
            }));
        case SHORT_TERM_INPUT_SET_VALID:
            return updateFormField('shortTermInputValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // long term input value
        case LONG_TERM_INPUT_SET_VALUE:
            return updateFormField('longTermInputValue', field => ({
                ...field,
                value: action.value,
            }));
        case LONG_TERM_INPUT_SET_VALID:
            return updateFormField('longTermInputValue', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case LUMPSUM_AMOUNT_SET_VALUE:
            return {
                ...state,
                form: {
                    ...state.form,
                    lumpsumPaymentDetails: updateValidArray('lumpsumAmount', action.paymentId)
                }
            }
        case LUMPSUM_AMOUNT_SET_VALID:
            return {
                ...state,
                form: {
                    ...state.form,
                    lumpsumPaymentDetails: updateInValidArray('lumpsumAmount', action.paymentId, action.error)
                }
            }
        case LUMPSUM_YEAR_SET_VALUE:
            
            return {
                ...state,
                form: {
                    ...state.form,
                    lumpsumPaymentDetails: updateValidArray('lumpsumYear', action.paymentId)
                }
            }
        case LUMPSUM_YEAR_SET_VALID:
            return {
                ...state,
                form: {
                    ...state.form,
                    lumpsumPaymentDetails: updateInValidArray('lumpsumYear', action.paymentId, action.error)
                }
            }
        case LUMPSUM_MONTH_SET_VALUE:
            
            return {
                ...state,
                form: {
                    ...state.form,
                    lumpsumPaymentDetails: updateValidArray('lumpsumMonth', action.paymentId)
                }
            }
        case LUMPSUM_MONTH_SET_VALID:
            return {
                ...state,
                form: {
                    ...state.form,
                    lumpsumPaymentDetails: updateInValidArray('lumpsumMonth', action.paymentId, action.error)
                }
            }
        // Add new lumpsum payment detail
        case NEW_LUMPSUM_PAYMENT_SET_VALUE:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...{lumpsumPaymentDetails: [...state.form.lumpsumPaymentDetails, newPaymentDetails()]}
                },
                
            };
        // Delete new lumpsum payment detail
        case  DELETE_LUMPSUM_PAYMENT_SET_VALUE:
            const deleteID = action.value;
            const paymentDetails = state.form.lumpsumPaymentDetails.filter((_item, index) => index !== deleteID);

            return {
                ...state,
                form: {
                    ...state.form,
                    ...state.form.lumpsumPaymentDetails = paymentDetails
                },
            };
        // Select Form
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value
            };
        case REINITIALIZE:
            return initialeState
        case CALCULATE_PREMIER_SAVINGS_DATA:
            return {
                ...state,
                quotationSummary: action.value
            }
        case COLLECT_PREMIER_SAVINGS_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        default:
            return state;
    }
}
