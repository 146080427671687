import {
    validateBase64File,
} from '@om/validation';

import {
    INCORPORATION_COPY_SET_VALUE,
    KRA_PIN_CERTIFICATE_COPY_SET_VALUE,
    setIncorporationCertificateCopyValid,
    setKraPinCertificateCopyValid
} from '../../../actions/biasharaFlexi/uploadDocuments';

import { validationIsEqual } from '../status';

let prevValidationAction = null;

export const uploadDocumentsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case INCORPORATION_COPY_SET_VALUE:
                error = validateBase64File(action.value?.base64File, {
                    subject: 'Incorporation certificate',
                    required: true,
                    max: 5,
                });
                return setIncorporationCertificateCopyValid(error);

            case KRA_PIN_CERTIFICATE_COPY_SET_VALUE:
                error = validateBase64File(action.value?.base64File, {
                    subject: '',
                    required: true,
                });
                return setKraPinCertificateCopyValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
