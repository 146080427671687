import {createSelector} from "reselect";
import {getAtRetirement} from "./index";
import {FORM_STATUS} from "../../reducers/status";

export const getFinancialAdvice = createSelector(
    getAtRetirement,
    atRetirement => atRetirement.financialAdvice
);

export const getFinancialAdviceForm = createSelector(
    getFinancialAdvice,
    financialAdvice => financialAdvice.form
);

export const getFinancialAdviceFormIsValid = createSelector(
    getFinancialAdvice,
    financialAdvice => financialAdvice.formStatus === FORM_STATUS.VALID
);

export const getSendFindOutMoreStatus = createSelector(
    getFinancialAdvice,
    financialAdvice => financialAdvice.sendFindOutMoreStatus
);
export const getSendSpeakToAdvisorStatus = createSelector(
    getFinancialAdvice,
    financialAdvice => financialAdvice.sendSpeakToAdvisorStatus
);

export const getRewardsClicked = createSelector(
    getFinancialAdvice,
    financialAdvice => financialAdvice.getRewardsClicked
);

const makeGetField = fieldId => createSelector(
    getFinancialAdviceForm,
    financialAdviceForm => financialAdviceForm[fieldId]
);

export const getReasons = makeGetField('reasons');
export const getShortDescription = makeGetField('shortDescription');
export const getClientEmail = makeGetField('emailAddress');
export const getContactNumberAdvisor = makeGetField('contactNumber');



