// Events
export const ALLSURE_APPLICATION_POLICY_REQUEST =
  "allsureInsurance/policyRequest/";

export const GET_QUOTE_REQUEST=ALLSURE_APPLICATION_POLICY_REQUEST+'getQuote'


export const getAllsureQuote = () => ({  
  type: GET_QUOTE_REQUEST,
}); 

export const SEND_CALL_ME_BACK_REQUEST=ALLSURE_APPLICATION_POLICY_REQUEST+'sendEmail'


export const sendEmailonCallmeBack = (value='') => ({  
  type: SEND_CALL_ME_BACK_REQUEST,
  value
}); 
export const SEND_LEADS_REQUEST=ALLSURE_APPLICATION_POLICY_REQUEST+'leadsMail'


export const sendLeadsRequest = (value='') => ({  
  type: SEND_LEADS_REQUEST,
  value
}); 

export const SUBMIT_POLICY_REQUEST=ALLSURE_APPLICATION_POLICY_REQUEST+'submitPolicy'


export const submitPolicy = () => ({  
  type: SUBMIT_POLICY_REQUEST,
}); 

export const SEND_UPLOAD_DOCUMENTS=ALLSURE_APPLICATION_POLICY_REQUEST+'uploadDocuments'

 export const uploadDocumentsRequest=()=>({
type:SEND_UPLOAD_DOCUMENTS,
})