import { createSelector } from 'reselect';
import { getRoaQuoteTools } from '../../index';

import { FORM_STATUS } from '../../../../reducers/status';
import { APICallSelector } from '../../api';

export const getAccidentalQuoteTool = createSelector(
    getRoaQuoteTools,
    (roaQuoteTools) => roaQuoteTools.accidentalDeathCoverQuoteTool
);

export const getSelectedForm = createSelector(
    getAccidentalQuoteTool,
    (accidentalDeathCoverQuoteTool) =>
        accidentalDeathCoverQuoteTool.selectedForm
);

export const getRoaCmbObject = createSelector(
    getAccidentalQuoteTool,
    (accidentalDeathCoverQuoteTool) =>
        accidentalDeathCoverQuoteTool.roaCmbObject
);

export const getAccidentalQuoteToolForm = createSelector(
    getAccidentalQuoteTool,
    (accidentalDeathCoverQuoteTool) => accidentalDeathCoverQuoteTool.form
);

export const getAccidentalQuoteToolFormValid = createSelector(
    getAccidentalQuoteTool,
    (accidentalDeathCoverQuoteTool) =>
        accidentalDeathCoverQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getAccidentalDeathCoverPremiumAmount = createSelector(
	getAccidentalQuoteTool,
	accidentalDeathCoverQuoteTool => accidentalDeathCoverQuoteTool.accidentalDeathCoverAmount
)

// API Calls Selectors
export const getShowError = APICallSelector.getErrorStatus(
    getAccidentalQuoteTool
);

export const getIsApiPending = APICallSelector.getPendingStatus(
    getAccidentalQuoteTool
);

const makeGetFieldForm = (fieldId) =>
    createSelector(getAccidentalQuoteToolForm, (form) => form[fieldId]);

export const getGenderValue = makeGetFieldForm('genderValue');
export const getCandidateDOB = makeGetFieldForm('candidateDOB');
export const getDisabilityCoveredValue = makeGetFieldForm(
    'disabilityCoveredValue'
);
export const getSmokerCoveredValue = makeGetFieldForm('smokerCoveredValue');
export const getCoverAmount = makeGetFieldForm('coverAmount');
export const getNumberOfYearsCovered = makeGetFieldForm('numberOfYearsCovered');
