import { createSelector } from "reselect";
import { getCovidSelfAssessment } from "./index";

export const wizardFooter = createSelector(
    getCovidSelfAssessment,
    covidSelfAssessment => covidSelfAssessment.wizardFooter
);

export const getContinueButtonDisabled = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.continueButtonDisabled,
);

export const getContinueButtonVisible = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.continueButtonVisible,
);

export const getStepIndex = createSelector(
    wizardFooter,
    wizardFooter => wizardFooter.stepIndex,
);
