// export const FINANCE_API_URL = 'http://localhost:8080/api/v1/lending'
export const FINANCE_API_URL = '/om-api/lending'

export const DO_ACTIONS = 'digitalOnboarding/lending/';
export const DO_ROUTING = DO_ACTIONS + 'routing/';
export const DO_REQUESTS = DO_ACTIONS + 'request/';
export const DO_PAGE_REQUEST = DO_REQUESTS + 'page/requester/';
export const DO_SUCCESSFUL_PAGE_REQUEST = DO_PAGE_REQUEST + 'success/';
export const DO_FAILED_PAGE_REQUEST = DO_PAGE_REQUEST + 'failed/';

export const SET_VALUE = 'digitalOnboarding/setValue/';

export const DO_RESET_APPLICATION_FLOW = DO_ACTIONS + 'RESET_APPLICATION_FLOW';
export const RESET_APPLICATION_NOW = DO_ACTIONS + 'RESET_APPLICATION_NOW';

export const MOVE_TO_NEXT_PAGE = DO_ROUTING + 'MOVE_TO_NEXT_PAGE';
export const MOVE_TO_PREVIOUS_PAGE = DO_ROUTING + 'MOVE_TO_PREVIOUS_PAGE';
export const MOVE_TO_FAILURE_PAGE = DO_ROUTING + 'MOVE_TO_FAILURE_PAGE';
export const SKIP_TO_PAGE = DO_ROUTING + 'SKIP_TO_PAGE';
export const NAVIGATE_NEXT = DO_ROUTING + 'NAVIGATE_NEXT';
export const NAVIGATE_BACK = DO_ROUTING + 'NAVIGATE_BACK';
export const RETURN_TO_PRODUCT_PAGE = DO_ROUTING +  'RETURN_TO_PRODUCT_PAGE';
export const APPLICATION_UTM = DO_ACTIONS + 'APPLICATION_UTM'
export const SET_CAN_PROCEED = DO_ACTIONS + 'SET_CAN_PROCEED';

export const STORE_PERSONAL_DETAIL = DO_ACTIONS + 'STORE_PERSONAL_DETAIL';
export const STORE_EMPLOYMENT_DETAIL = DO_ACTIONS + 'STORE_EMPLOYMENT_DETAIL';
export const STORE_EMPLOYMENT_DETAIL_VALIDATION = DO_ACTIONS + 'STORE_EMPLOYMENT_DETAIL_VALIDATION';
export const STORE_AFFORDABILITY_DETAIL = DO_ACTIONS + 'STORE_AFFORDABILITY_DETAIL';
export const STORE_AFFORDABILITY_DETAIL_VALIDATION = DO_ACTIONS + 'STORE_AFFORDABILITY_DETAIL_VALIDATION';
export const STORE_CALCULATED_AFFORDABILITY_DETAIL = DO_ACTIONS + 'STORE_CALCULATED_AFFORDABILITY_DETAIL';

export const STORE_APPLICANT_SELECTED_QUOTE_LOAN_AMOUNT = DO_ACTIONS + 'STORE_APPLICANT_SELECTED_QUOTE_LOAN_AMOUNT';
export const STORE_APPLICANT_SELECTED_QUOTE_LOAN_TERM = DO_ACTIONS + 'STORE_APPLICANT_SELECTED_QUOTE_LOAN_TERM';

export const STORE_OTP_VALUE = DO_ACTIONS + 'STORE_OTP_VALUE';
export const RESET_OTP_VALUE = DO_ACTIONS + 'RESET_OTP_VALUE';

export const STORE_ADDRESS_DETAIL = DO_ACTIONS + 'STORE_ADDRESS_DETAIL';
export const STORE_ADDRESS_DETAIL_VALIDATION = DO_ACTIONS + 'STORE_ADDRESS_DETAIL_VALIDATION';

export const SET_PERSISTED_AT = SET_VALUE + 'SET_PERSISTED_AT';

export const SCHEDULE_FILE_FOR_UPLOAD = DO_ACTIONS + 'SCHEDULE_FILE_FOR_UPLOAD';
export const REMOVE_FILE_FROM_UPLOAD = DO_ACTIONS + 'REMOVE_FILE_FROM_UPLOAD';
export const CANCEL_FILE_UPLOAD_IN_PROGRESS = DO_ACTIONS + 'CANCEL_FILE_UPLOAD_IN_PROGRESS';
export const RETRY_FILE_UPLOAD = DO_ACTIONS + 'RETRY_FILE_UPLOAD';
export const FILE_UPLOAD_VALIDATION = DO_ACTIONS + 'FILE_UPLOAD_VALIDATION';
export const FILE_UPLOAD_ERROR = DO_ACTIONS + 'FILE_UPLOAD_ERROR';
export const DOCUMENT_MODAL = DO_ACTIONS + 'DOCUMENT_MODAL';
export const DOCUMENT_UPLOAD_SKIP = DO_ACTIONS + 'DOCUMENT_UPLOAD_SKIP';

export const SEND_PAGE_REQUESTS = DO_PAGE_REQUEST + 'SEND_PAGE_REQUESTS';
export const RESET_PAGE_REQUESTS = DO_ACTIONS + 'RESET_PAGE_REQUESTS';

export const LOOKUP_DATA_REQUEST = DO_REQUESTS + 'LOOKUP_DATA_REQUEST';
export const LOOKUP_DATA_SUCCESS = DO_REQUESTS + 'LOOKUP_DATA_SUCCESS';
export const LOOKUP_DATA_FAIL = DO_REQUESTS + 'LOOKUP_DATA_FAIL';

export const ACCEPT_OFFER_REQUEST = DO_PAGE_REQUEST + 'ACCEPT_OFFER_REQUEST';
export const ACCEPT_OFFER_REQUEST_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'ACCEPT_OFFER_REQUEST_SUCCESS';
export const ACCEPT_OFFER_REQUEST_FAIL = DO_FAILED_PAGE_REQUEST + 'ACCEPT_OFFER_REQUEST_FAIL';

export const APPLICATION_NOW_REQUEST = DO_PAGE_REQUEST + 'APPLICATION_NOW_REQUEST';
export const APPLICATION_NOW_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'APPLICATION_NOW_SUCCESS';
export const APPLICATION_NOW_FAIL = DO_FAILED_PAGE_REQUEST + 'APPLICATION_NOW_FAIL';

export const OFFER_QUOTE_REQUEST = DO_PAGE_REQUEST + 'DIGITAL_OFFER_QUOTE_REQUEST';
export const OFFER_QUOTE_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'DIGITAL_OFFER_QUOTE_REQUEST_SUCCESS';
export const OFFER_QUOTE_NOT_AVAILABLE = DO_FAILED_PAGE_REQUEST + 'DIGITAL_OFFER_QUOTE_REQUEST_NOT_AVAILABLE';
export const OFFER_QUOTE_FAIL = DO_ACTIONS + 'DIGITAL_OFFER_QUOTE_REQUEST_FAILURE';
export const OFFER_QUOTE_NO_OFFER = DO_ACTIONS + 'OFFER_QUOTE_NO_OFFER';
export const OFFER_QUOTE_DECLINED = DO_ACTIONS + 'OFFER_QUOTE_DECLINED';

export const OTP_SEND_REQUEST = DO_PAGE_REQUEST + 'OTP_SEND_REQUEST';
export const OTP_RESEND_REQUEST = DO_REQUESTS + 'OTP_RESEND_REQUEST';
export const OTP_SEND_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'OTP_SEND_REQUEST_SUCCESS';
export const OTP_RESEND_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'OTP_RESEND_REQUEST_SUCCESS';
export const OTP_SEND_FAIL = DO_FAILED_PAGE_REQUEST + 'OTP_SEND_REQUEST_FAIL';
export const OTP_NOT_VALID = DO_FAILED_PAGE_REQUEST + 'OTP_NOT_VALID';

export const OTP_VERIFY_REQUEST = DO_PAGE_REQUEST + 'OTP_VERIFY_REQUEST';
export const OTP_VERIFY_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'OTP_VERIFY_REQUEST_SUCCESS';
export const OTP_VERIFY_FAIL = DO_FAILED_PAGE_REQUEST + 'OTP_VERIFY_REQUEST_FAIL';

export const ADD_ADDRESS_REQUEST = DO_PAGE_REQUEST + 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_REQUEST_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'ADD_ADDRESS_REQUEST_SUCCESS';
export const ADD_ADDRESS_REQUEST_FAIL = DO_FAILED_PAGE_REQUEST + 'ADD_ADDRESS_REQUEST_FAIL';

export const MAKE_APPLICATION_REQUEST = DO_PAGE_REQUEST + 'MAKE_APPLICATION_REQUEST';
export const MAKE_APPLICATION_REQUEST_SUCCESS = DO_SUCCESSFUL_PAGE_REQUEST + 'MAKE_APPLICATION_REQUEST_SUCCESS';
export const MAKE_APPLICATION_REQUEST_FAIL = DO_FAILED_PAGE_REQUEST + 'MAKE_APPLICATION_REQUEST_FAIL';

export const FILE_UPLOAD_REQUEST = DO_PAGE_REQUEST + 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_COMPLETE = DO_SUCCESSFUL_PAGE_REQUEST + 'FILE_UPLOAD_COMPLETE';
export const FILE_UPLOAD_FAILED = DO_FAILED_PAGE_REQUEST + 'FILE_UPLOAD_FAILED';
export const FILE_API_UPLOAD_FAILED = DO_FAILED_PAGE_REQUEST + 'FILE_API_UPLOAD_FAILED';

export const PAGES = {
    LANDING: '/personal-loans/onboarding/landing',
    PERSONAL_DETAILS: '/personal-loans/onboarding/personal-details',
    EMPLOYMENT_DETAILS: '/personal-loans/onboarding/employment-details',
    AFFORDABILITY: '/personal-loans/onboarding/affordability',
    QUOTE: '/personal-loans/onboarding/quote',
    OTP: '/personal-loans/onboarding/otp',
    ADDRESS: '/personal-loans/onboarding/address',
    DOCUMENTS: '/personal-loans/onboarding/documents',
    SUCCESS: '/personal-loans/onboarding/success'
}

export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const ID_NUMBER = 'idNumber';
export const CELL_NUMBER = 'contactNumber';
export const EMAIL_ADDRESS = 'emailAddress';
export const CREDIT_CONSENT = 'creditCheckConsentProvided';
export const PERSONAL_CONSENT = 'personalInformationConsentProvided';
export const RECAPTCHA = 'recaptcha';
export const BLACK_BOX_DATA = 'blackBox';

export const SECTOR = 'sector';
export const EMPLOYMENT_STATUS = 'employmentStatus';
export const EMPLOYMENT_START_DATE = 'employmentStartDate';
export const EMPLOYMENT_CONTRACT_END = 'employmentContractEnd';
export const BANK_NAME = 'bankName';
export const DEBT_LIABILITY = 'debtLiability';
export const RETRENCHMENT = 'retrenchment';
export const HAS_MEDICAL_CONDITIONS = 'hasMedicalCondition';

export const GROSS_INCOME = 'GROSS_INCOME';
export const NET_INCOME = 'NET_INCOME';
export const GROCERIES = 'GROCERIES';
export const UTILITIES = 'UTILITIES';
export const INSURANCE_AND_SAVINGS = 'INSURANCE_AND_SAVINGS';
export const COMMUNICATION_AND_SERVICES = 'COMMUNICATION_AND_SERVICES';
export const TRANSPORT = 'TRANSPORT';
export const HOUSING = 'HOUSING';
export const SUPPORT_TO_OTHERS = 'SUPPORT_TO_OTHERS';
export const OTHER = 'OTHER';
export const TOTAL_MONTHLY_LIVING_EXPENSE = 'TOTAL_MONTHLY_LIVING_EXPENSE';
export const CAPITAL_NEEDED = 'CAPITAL_NEEDED';
export const INFORMATION_CONFIRMATION = 'INFORMATION_CONFIRMATION';

export const MONTH_STEP_SIZE = 'monthStepSize';
export const LOAN_AMOUNT_STEP_SIZE = 'loanAmountStepSize';
export const MIN_TERM = 'minTerm';
export const MAX_TERM = 'maxTerm';
export const MIN_AMOUNT = 'minAmount';
export const MAX_AMOUNT = 'maxAmount';
export const ABS_MAX_AMOUNT = 'absMaxAmount';
export const MIN_INITIATION_FEE = 'minInitiationFee';
export const MAX_INITIATION_FEE = 'maxInitiationFee';
export const ADMIN_FEE = 'adminFee';
export const CREDIT_LIFE_RATE = 'creditLifeRate';
export const VAT_RATE = 'vatRate';
export const QUOTE_AFFORDABILITY = 'quoteAffordability';
export const INITIATION_FEE = 'initiationFee';
export const LOAN_AMOUNT = 'loanAmount';
export const LOAN_TERM = 'loanTerm';
export const LOAN_INSTALMENT = 'loanInstalment';
export const CREDIT_LIFE = 'creditLife';
export const INTEREST_RATE = 'interestRate';

export const OTP_VALUE = 'otp';

export const COMPLEX_BUILDING = 'COMPLEX_BUILDING';
export const STREET_ADDRESS = 'STREET_ADDRESS';
export const POSTAL_CODE_PHYSICAL = 'POSTAL_CODE_PHYSICAL';
export const SUBURB_PHYSICAL = 'SUBURB_PHYSICAL';
export const CITY_PHYSICAL = 'CITY_PHYSICAL';
export const PROVINCE_PHYSICAL = 'PROVINCE_PHYSICAL';
export const RURAL_LOW_DENSITY = 'RURAL_LOW_DENSITY';
export const POSTAL_SAME_AS_PHYSICAL = 'POSTAL_SAME_AS_PHYSICAL';
export const ADDRESS_LINE_1 = 'ADDRESS_LINE_1';
export const POSTAL_CODE_POSTAL = 'POSTAL_CODE_POSTAL';
export const SUBURB_POSTAL = 'SUBURB_POSTAL';
export const CITY_POSTAL = 'CITY_POSTAL';
export const PROVINCE_POSTAL = 'PROVINCE_POSTAL';

export const PageIndexRef = {
    LANDING_PAGE: 0,
    PERSONAL_DETAILS_PAGE: 1,
    EMPLOYMENT_DETAILS_PAGE: 2,
    AFFORDABILITY_DETAILS_PAGE: 3,
    QUOTE_PAGE: 4,
    OTP_PAGE: 5,
    ADDRESS_DETAILS_PAGE: 6,
    DOCUMENTS_PAGE: 7,
    SUCCESS_PAGE: 8
};



export const PAGE_INDEXES = Object.freeze([
    {
        index: PageIndexRef.LANDING_PAGE,
        url: PAGES.LANDING,
        headerTitle: 'Personal Loans',
        footerLeft: 'CANCEL',
        footerRight: 'START APPLICATION',
        requiredValues: [],
        requestTypes: [],
        stepTitle: null,
        previousPageId: null,
        nextPageId: PageIndexRef.PERSONAL_DETAILS_PAGE
    },
    {
        index: PageIndexRef.PERSONAL_DETAILS_PAGE,
        url: PAGES.PERSONAL_DETAILS,
        headerTitle: 'Personal Loans',
        footerLeft: 'PREVIOUS',
        footerRight: 'CONTINUE',
        requiredValues: [FIRST_NAME, LAST_NAME, ID_NUMBER, CELL_NUMBER, EMAIL_ADDRESS, CREDIT_CONSENT, PERSONAL_CONSENT],
        requestTypes: [APPLICATION_NOW_REQUEST],
        stepTitle: 'Personal Details',
        previousPageId: PageIndexRef.LANDING_PAGE,
        nextPageId: PageIndexRef.EMPLOYMENT_DETAILS_PAGE
    },
    {
        index: PageIndexRef.EMPLOYMENT_DETAILS_PAGE,
        url: PAGES.EMPLOYMENT_DETAILS,
        headerTitle: 'Personal Loans',
        footerLeft: 'PREVIOUS',
        footerRight: 'CONTINUE',
        requiredValues: [SECTOR,EMPLOYMENT_STATUS,EMPLOYMENT_START_DATE, EMPLOYMENT_CONTRACT_END, BANK_NAME, DEBT_LIABILITY, RETRENCHMENT, HAS_MEDICAL_CONDITIONS],
        requestTypes: [],
        stepTitle: 'Employment',
        previousPageId: PageIndexRef.PERSONAL_DETAILS_PAGE,
        nextPageId: PageIndexRef.AFFORDABILITY_DETAILS_PAGE
    },
    {
        index: PageIndexRef.AFFORDABILITY_DETAILS_PAGE,
        url: PAGES.AFFORDABILITY,
        headerTitle: 'Personal Loans',
        footerLeft: 'PREVIOUS',
        footerRight: 'CONTINUE',
        requiredValues: [GROSS_INCOME, NET_INCOME, TOTAL_MONTHLY_LIVING_EXPENSE, CAPITAL_NEEDED, INFORMATION_CONFIRMATION],
        requestTypes: [OFFER_QUOTE_REQUEST],
        stepTitle: 'Affordability',
        previousPageId: PageIndexRef.EMPLOYMENT_DETAILS_PAGE,
        nextPageId: PageIndexRef.QUOTE_PAGE,
        jumpToPageIdOnFailure: PageIndexRef.ADDRESS_DETAILS_PAGE
    },
    {
        index: PageIndexRef.QUOTE_PAGE,
        url: PAGES.QUOTE,
        headerTitle: 'Personal Loans',
        footerLeft: 'PREVIOUS',
        footerRight: 'CONTINUE',
        requiredValues: [],
        requestTypes: [ACCEPT_OFFER_REQUEST, OTP_SEND_REQUEST],
        stepTitle: 'Quote',
        previousPageId: PageIndexRef.AFFORDABILITY_DETAILS_PAGE,
        nextPageId: PageIndexRef.OTP_PAGE,
        skipToPageId: PageIndexRef.ADDRESS_DETAILS_PAGE
    },
    {
        index: PageIndexRef.OTP_PAGE,
        url: PAGES.OTP,
        headerTitle: 'Personal Loans',
        footerLeft: 'PREVIOUS',
        footerRight: 'CONTINUE',
        requiredValues: [OTP_VALUE],
        requestTypes: [OTP_VERIFY_REQUEST],
        previousPageId: PageIndexRef.QUOTE_PAGE,
        nextPageId: PageIndexRef.ADDRESS_DETAILS_PAGE
    },
    {
        index: PageIndexRef.ADDRESS_DETAILS_PAGE,
        url: PAGES.ADDRESS,
        headerTitle: 'Personal Loans',
        footerLeft: 'PREVIOUS',
        // footerRight: 'CONTINUE',
        footerRight: 'SUBMIT APPLICATION',
        requiredValues: [COMPLEX_BUILDING, STREET_ADDRESS, POSTAL_CODE_PHYSICAL, SUBURB_PHYSICAL, CITY_PHYSICAL, PROVINCE_PHYSICAL, RURAL_LOW_DENSITY, POSTAL_SAME_AS_PHYSICAL],
        requestTypes: [ADD_ADDRESS_REQUEST, MAKE_APPLICATION_REQUEST],
        stepTitle: 'Address',
        previousPageId: PageIndexRef.QUOTE_PAGE,
        // nextPageId: PageIndexRef.DOCUMENTS_PAGE
        nextPageId: PageIndexRef.DOCUMENTS_PAGE
    },
    {
        index: PageIndexRef.DOCUMENTS_PAGE,
        url: PAGES.DOCUMENTS,
        headerTitle: 'Personal Loans',
        footerLeft: 'PREVIOUS',
        footerRight: 'SUBMIT APPLICATION',
        requiredValues: [],
        requestTypes: [FILE_UPLOAD_REQUEST, MAKE_APPLICATION_REQUEST],
        stepTitle: 'Documents',
        previousPageId: PageIndexRef.ADDRESS_DETAILS_PAGE,
        nextPageId: PageIndexRef.SUCCESS_PAGE
    },
    {
        index: PageIndexRef.SUCCESS_PAGE,
        url: PAGES.SUCCESS,
        headerTitle: 'Personal Loans',
        footerLeft: null,
        footerRight: null,
        requiredValues: [],
        requestTypes: [],
        stepTitle: null,
        previousPageId: PageIndexRef.DOCUMENTS_PAGE,
        nextPageId: null
    }
]);

export const PARENT_URL = '/personal/solutions/bank-and-borrow';
export const LEARN_MORE_ABOUT_LOANS_URL = '/personal/solutions/bank-and-borrow/personal-loans/frequently-asked-questions/';