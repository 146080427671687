import { createSelector } from "reselect";
import { getRoaQuoteTools } from './index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../reducers/status";

export const getElimikaQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.elimikaQuoteTool
);

export const getElimikaQuoteToolForm = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => elimikaQuoteTool.form
);

const makeGetFieldForm = fieldId => createSelector(
    getElimikaQuoteToolForm,
    form => form[fieldId]
);

export const getElimikaQuoteToolFormValid = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => elimikaQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getSelectedForm = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => elimikaQuoteTool.selectedForm
)

export const getRoaCmbObject = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => elimikaQuoteTool.roaCmbObject
)

export const getElimikaPremiumAmount = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => elimikaQuoteTool.elimikaPremiumAmount
);

export const getShowError = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => elimikaQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => elimikaQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getCalculateDisabled = createSelector(
    getElimikaQuoteTool,
    elimikaQuoteTool => {
        return elimikaQuoteTool.formStatus === FORM_STATUS.INVALID ||
            elimikaQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);

export const getCalculateInputValue = makeGetFieldForm('calculateInput');
export const getGenderValue = makeGetFieldForm('genderValue');
export const getChildDOB = makeGetFieldForm('childDateOfBirth');
export const getDOB = makeGetFieldForm('dob');
export const getSchoolStartYear = makeGetFieldForm('schoolStartYear');
export const getAccumalationPeriod = makeGetFieldForm('accumulationPeriod');
export const getLifeCoveredInputValue = makeGetFieldForm('lifeCoveredValue');
export const getTargetFeesPerYearValue = makeGetFieldForm('targetFeesPerYear');
export const getTargetSavingsPerYearValue = makeGetFieldForm('targetSavingsPerYear');
export const getSchoolYearValue = makeGetFieldForm('schoolYearValue');
export const getPremiumFrequencyValue = makeGetFieldForm('premiumFrequencyValue');
export const getFeesFrequencyInputValue = makeGetFieldForm('feesFrequencyInput');
