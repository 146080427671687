import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    FIRST_NAME_SET_VALUE,
    FIRST_NAME_SET_VALID,
    LAST_NAME_SET_VALUE,
    LAST_NAME_SET_VALID,
    RELATIONSHIP_SET_VALUE,
    RELATIONSHIP_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    ID_NUMBER_SET_VALUE,
    ID_NUMBER_SET_VALID,
    ADDRESS_SET_VALUE,
    ADDRESS_SET_VALID,
    FORM_VALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM,
    FORM_INVALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM,
    COLLECT_NEXT_OF_KIN_FORM_DATA,
    API_PENDING_TRANSITION_NEXT_OF_KIN_FORM,
    API_SUCCESS_TRANSITION_NEXT_OF_KIN_FORM,
    API_FAILURE_TRANSITION_NEXT_OF_KIN_FORM,
    SUBMIT_NEXT_OF_KIN_SUCCESS,
    SUBMIT_NEXT_OF_KIN_FAILURE,
} from '../../actions/personalAccident/nextOfKin';

export const initNextOfKin = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    nextOfKinReferenceData: null,
    apiCallStatus: null,
    form: {
        relationship: { value: null, error: null, status: null },
        firstName: { value: null, error: null, status: null },
        lastName: { value: null, error: null, status: null },
        idNumber: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        address: { value: null, error: null, status: null },
    },
};

export default (state = initNextOfKin, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_PERSONAL_ACCIDENT_NEXT_OF_KIN_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case RELATIONSHIP_SET_VALUE:
            return updateFormField('relationship', (field) => ({
                ...field,
                value: action.value,
            }));

        case RELATIONSHIP_SET_VALID:
            return updateFormField('relationship', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LAST_NAME_SET_VALUE:
            return updateFormField('lastName', (field) => ({
                ...field,
                value: action.value,
            }));
        case LAST_NAME_SET_VALID:
            return updateFormField('lastName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));

        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('email', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMAIL_SET_VALID:
            return updateFormField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_NUMBER_SET_VALUE:
            return updateFormField('idNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_NUMBER_SET_VALID:
            return updateFormField('idNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ADDRESS_SET_VALUE:
            return updateFormField('address', (field) => ({
                ...field,
                value: action.value,
            }));

        case ADDRESS_SET_VALID:
            return updateFormField('address', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        //    API Calls
        case API_PENDING_TRANSITION_NEXT_OF_KIN_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_NEXT_OF_KIN_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_NEXT_OF_KIN_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_NEXT_OF_KIN_FORM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case SUBMIT_NEXT_OF_KIN_SUCCESS:
            return { ...state, personalDetailsReferenceData: action.value };
        case SUBMIT_NEXT_OF_KIN_FAILURE:
            return { ...state, personalDetailsReferenceData: action.error };

        // Reset the state
        case REINITIALIZE:
            return initNextOfKin;
        default:
            return state;
    }
};
