import React from "react"
import { Provider } from "react-redux"
import { storeCreater } from "@om/redux";

import ErrorBoundary from "./ErrorBoundary";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const isDevelop = ['qa', 'dev', 'local'].includes(process.env.GATSBY_ENVIRONMENT_NAME);
    const teamEnvironment = isDevelop && process.env.GATSBY_TEAM_ENVIRONMENT_NAME? '/'+ process.env.GATSBY_TEAM_ENVIRONMENT_NAME: '';
    const store = storeCreater(isDevelop, teamEnvironment);
    return <ErrorBoundary><Provider store={store}>{element}</Provider></ErrorBoundary>
}
