export const FORM_STATUS = {
    VALID: 'valid',
    INVALID: 'invalid',
};

// DO NOT USE - use API_CALL_STATUS
export const FORM_SUBMIT_STATUS = {
    NEW: 'new',
    SUCCESS: 'success',
    SUBMITTING: 'submitting',
    ERROR: 'error'
}

export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const ACTIVE_STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
};

// Must correspond with reference data codes
export const POSTAL_ADDRESS_METHOD = {
    UNTOUCHED: 'untouched',
    PO_BOX: 'POBox',
    PRIVATE_BAG: 'PrivateBag',
    STREET_ADDRESS: 'StreetAddress',
};

export const FIELD_STATUS = {
    UNTOUCHED: 'untouched',
    ERROR: 'error',
    VALID: 'valid',
    DISABLED: 'disabled',
};

export const API_CALL_STATUS = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILURE: 'failure',
};

export const VALIDATION_CALL_STATUS = {
    UNTOUCHED: 'untouched',
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILURE: 'failure',
    INVALID: 'invalid',
    WAIVED: 'waived',
};

export const BENEFICIARY_DRAWER = {
    EDIT: 'edit',
    READ_MORE: 'readMore',
};

export const CALL_ME_BACK_TYPES = {
    GENERIC: 'generic',
    QUOTE: 'quote',
    REPLACEMENT: 'replacement',
};
