import {getReplacementPolicyForm, getIsReplacementPolicy} from "../../../selectors/easiplusFuneralPlan/replacementPolicy";
import {transitionReplacementPolicy} from "../../../actions/easiplusFuneralPlan/replacementPolicy";
import {FIELD_STATUS} from "../../../reducers/status";
import { transitionScrolling } from "../../../actions/easiplusFuneralPlan/scrolling";
import { EFP_REPLACEMENT_POLICY_SCROLL_CLASS } from "../../rulesEngine/status";

export const replacementPolicyRules = (store, next, action) => {
    const result = next(action);
    const replacementPolicy = getReplacementPolicyForm(store.getState());
    const replacementPolicyFormIsValid = validateReplacementPolicy(replacementPolicy);

    store.dispatch(replacementPolicyFormIsValid
        ? transitionReplacementPolicy.formValid()
        : transitionReplacementPolicy.formInvalid());

    const state = store.getState();
    // if user selects 'yes' for replacement policy, anchor to continue button in OmApplicationCard
    const isReplacementPolicy = getIsReplacementPolicy(store.getState());
    if(isReplacementPolicy.value)
        store.dispatch(transitionScrolling.scrollToId(EFP_REPLACEMENT_POLICY_SCROLL_CLASS.CONTINUE_BUTTON_ANCHOR))


    // TODO: why is next enabled when replacement policy === true? ANSWER because we dont stop clients from proceeding with the application i believe?
    return result;
};

const validateReplacementPolicy = formFields => {
    return formFields.isReplacementPolicy.status === FIELD_STATUS.VALID;
};
