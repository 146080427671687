import httpStatusCode from '../../../../api-server/constants/httpStatusCode';
import {
    setAllsureApplicationApiStatus,
    setAllsureApplicationQuoteDetails,
} from '../../../actions/allsureInsurance/referenceData';
import { API_CALL_STATUS } from '../../../reducers/status';
import { getGlobalPaymentFrequency } from '../../../selectors/allsureInsurance/homeDetails';
import { getCallMeBackPayload, getEmailSubjectLabel, getMotorDetailsObject, getMotorDetailsRequestBody } from '../../../selectors/allsureInsurance/motorDetails';
import axios from 'axios';
import { getPersonalDetailsForm, getPersonalDetailsMaped } from '../../../selectors/allsureInsurance/personalDetails';
import {  getPurchasePersonalDetailsForm } from '../../../selectors/allsureInsurance/purchasePersonalDetails';
import { getAllsureReferenceNumber, getQuoteTotalAmountMonthly } from '../../../selectors/allsureInsurance/referenceData';
import { getPurchaseDetails } from '../../../selectors/allsureInsurance/purchaseAdditionalHomeMotorDetails';
import { submitPolicy } from '../../../actions/allsureInsurance/policyRequests';
import { navigateNext, navigateToCallMeBack } from '../../../actions/allsureInsurance/routing';

export const leadsMailService = (store, next, action) => {
    const state=store.getState();
    (async () => {

      
        const personalDetail= getPersonalDetailsForm(state);
        const productLabel=getEmailSubjectLabel(state);
        const referenceNumber=getAllsureReferenceNumber(state);
        const monthlyQuoteAllproducts = getQuoteTotalAmountMonthly(state)
        const paymentFrequency = getGlobalPaymentFrequency(state)
        const purchasePersonalDetails=getPurchasePersonalDetailsForm(state)
        const personalDetailsSummary= getPersonalDetailsMaped(state)
        const {fullName,cellphoneNumber,email}=personalDetail
        const name=action.value==='purchaseMail' ? purchasePersonalDetails.fullname : fullName

        const {motorMaped,homeContentMaped,homeMaped} = getCallMeBackPayload()(state)
        const personalMaped=[{title:'Personal Details',data: personalDetailsSummary }]
        const purchaseDetails=action.value==="purchaseMail" ? getPurchaseDetails(state) : personalMaped
        const dataEmailObj = [...homeMaped,...homeContentMaped,...motorMaped,...purchaseDetails]

        const sampleRequestBody = {
            firstName:name?.value?.split(' ')[0],
            lastName:name?.value?.split(' ')[1],
            contactNumber:cellphoneNumber?.value,
            subject:`Web: ${productLabel} : ${name?.value}`,
            userDataFields: dataEmailObj,
            templateType:"ALLSURE_QUOTE_CUSTOMER",
            monthlyPremium:  null,
            yearlyPremium: null,
            referenceNumber: null,
            email: null
        }
        const quoteData = await sendEmailNotification(sampleRequestBody);
    })();
    return next(action);
};

export const sendEmailNotification = async (requestBody) => {
    try {
        const response = await axios({
            url: 'om-api/submit-notification/submitNotificationEmail',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            data: requestBody,
        });
        return response;
    } catch (error) {
        return null;
    }
};










