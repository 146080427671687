import {financialAdviceRules} from "./financialAdvice";
import {aboutYouRules} from "./aboutYou";
import {speakToAnAdviserRules} from "./speakToAnAdviser";
import {budgetToolRules, clearForm} from "./budgetTool";
import {solutionsRules} from "./solutions";

import {FIN_ADVICE_SET_VALID, FIN_ADVICE_SET_VALUE} from "../../../actions/atRetirement/financialAdvice";
import {ABOUT_YOU_SET_VALID, ABOUT_YOU_SET_VALUE, TOGGLE_BUDGET_TOOL} from "../../../actions/atRetirement/aboutYou";
import {SPEAK_TO_AN_ADVISER_SET_VALID, SPEAK_TO_AN_ADVISER_SET_VALUE} from "../../../actions/atRetirement/speakToAnAdviser";
import {BUDGET_TOOL_SET_VALID, BUDGET_TOOL_SET_VALUE} from "../../../actions/atRetirement/budgetTool";
import {SOLUTIONS_SET_VALUE} from "../../../actions/atRetirement/solutions";

export const atRetirementRules = (store, next, action) => {

    if (action.type.startsWith(BUDGET_TOOL_SET_VALID) ||
        action.type.startsWith(BUDGET_TOOL_SET_VALUE))
        return budgetToolRules(store, next, action);

    if (action.type.startsWith(ABOUT_YOU_SET_VALID) ||
        action.type.startsWith(ABOUT_YOU_SET_VALUE))
        return aboutYouRules(store, next, action);

    if (action.type.startsWith(FIN_ADVICE_SET_VALID) ||
        action.type.startsWith(FIN_ADVICE_SET_VALUE))
        return financialAdviceRules(store, next, action);

    // when closing the budget tool clear fields
    if (action.type === TOGGLE_BUDGET_TOOL)
        return clearForm(store, next, action);

    if (action.type.startsWith(SOLUTIONS_SET_VALUE))
        return solutionsRules(store, next, action);

    if (action.type.startsWith(SPEAK_TO_AN_ADVISER_SET_VALUE) ||
        action.type.startsWith(SPEAK_TO_AN_ADVISER_SET_VALID))
        return speakToAnAdviserRules(store, next, action);

    return null;
};