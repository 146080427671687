import {
    FORM_VALID_PRICE_TABLE_TRANSITION,
    FORM_INVALID_PRICE_TABLE_TRANSITION,
    FUNDS_SET_VALUE,
    DATE_SET_VALUE,
    DATE_SET_VALID,
    PAGINATION_SIZE_SET_VALUE,
    API_PENDING_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE,
    API_SUCCESS_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE,
    API_FAILURE_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE,
} from "../../actions/priceTable/table";
import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from "../status";

const initialPriceTableState = {
    funds: [],
    paginationRanges: [10, 50, 100, 300, 500, 700],
    paginationTableSize: 10,
    apiCallStatus: API_CALL_STATUS.IDLE,
    formStatus: FORM_STATUS.INVALID,
    form: {
        selectedDate: { value: null, error: null, status: null }
    }
}
export default (state = initialPriceTableState, action) => {

    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    const orderFunds = funds => {
        return funds && [...funds].sort((a, b) => {
            const fundAName = a.FundName;
            const fundBName = b.FundName;
            if (fundAName < fundBName)
                return -1
            if (fundAName > fundBName)
                return 1;

            return 0;
        });
    }

    switch (action.type) {
        case FORM_VALID_PRICE_TABLE_TRANSITION:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID
            }
        case FORM_INVALID_PRICE_TABLE_TRANSITION:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID
            }
        case API_PENDING_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING
            }
        case API_SUCCESS_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS
            }
        case API_FAILURE_TRANSITION_GET_FUNDS_FOR_SELECTED_DATE:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE
            }
        case FUNDS_SET_VALUE:
            return {
                ...state,
                funds: orderFunds(action.value)
            }
        case DATE_SET_VALUE:
            return updateFormField('selectedDate', (field) => ({
                ...field,
                value: action.value

            }));
        case DATE_SET_VALID:
            return updateFormField('selectedDate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error)
            }));
        case PAGINATION_SIZE_SET_VALUE:
            return {
                ...state,
                paginationTableSize: action.value
            }
        default:
            return state
    }
}
