import axios from "axios";
import { sendConfirmDetailsSubmitApplicationApiStatus } from "../../../actions/digiLifeLoanProtection/confirmDetails";
import { API_CALL_STATUS } from "../../../reducers/status";
import { getPersonalDetailsForm } from "../../../selectors/digiLifeLoanProtection/personalDetails";
import { getPaymentDetailsForm } from "../../../selectors/digiLifeLoanProtection/paymentDetails";
import { getDocumentsForm } from "../../../selectors/digiLifeLoanProtection/uploadDocuments";
import { navigateToThankYou } from "../../../actions/digiLifeLoanProtection/routing";
import { getDigiLifePremiumAmount, getDigiLifeQuoteToolForm } from "../../../selectors/roaQuoteTools/digiLifeQuoteTool";
import { setReferenceNumberValue,setFirstNamesValue } from "../../../actions/digiLifeLoanProtection/thankYouDetails";

export const submitDlpApplicationService = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const monthlyPremium=getDigiLifePremiumAmount(state);
    const {firstNames,surname,title,id,idType,mobileNumber,email,occupation,sourceOfIncome,streetAddress,city,district,postalStreetAddress,postalCity,postalDistrict,employmentType}=getPersonalDetailsForm(state)
    const {bankName,branchCode,accountNumber,accountType,debitOrderDay,accountHolder}=getPaymentDetailsForm(state)
    const {certifiedCopyOfId,addressProof,incomeProof,loanLetter,bankStatement}=getDocumentsForm(state)
    
    const {gender,DOB,salary,education,loanType,loanAmount,loanTerm,payment,cashBack}=getDigiLifeQuoteToolForm(state)
store.dispatch(sendConfirmDetailsSubmitApplicationApiStatus(API_CALL_STATUS.PENDING))

const appicationPayload=
{
  Policy: {
            LoanType: loanType.value,
            LoanDuration: loanTerm.value ,
            MonthlyPremium: monthlyPremium,
            BenefitAmount: loanAmount.value,
            PolicyType: cashBack.value, 
            ClientSignature: "CHECKED",
            ClientSignedDate: "2023-11-02", 
            IDProof: "UNCHECKED",
            ProofOfResidence: "UNCHECKED",
            AlternativeProofOfResidence: "UNCHECKED",
            MandateForm: "UNCHECKED",
            LoanLetter: "UNCHECKED",
            BankStatement: "UNCHECKED",
            KYCForm: "UNCHECKED",
            Payslip: "UNCHECKED",
            CessionaryForm: "UNCHECKED",
            PaymentMethod: "01",
            PaymentAmount: 0,
            DepositDate: "",
            CashPaymentReceipt: "UNCHECKED",
            SalaryFundingDay: debitOrderDay.value,
            ImmediateMandateCollection: "UNCHECKED",
            AccountHolderName: accountHolder.value ,
            AccountHolderSurname: '' ,
            BankName: bankName.value ,
            BranchName: branchCode.value,
            AccountNumber: accountNumber.value ,
            AccountType: accountType.value ,
            AccountHolderSignature: "CHECKED",
            SignatureDate: "2023-11-02", 
            SignatureLocation: "Online Application",
            MandateStartDate: "",
            PaymentFrequency: payment.value ,
            SmokingStatus: "",
            Education:education.value,
            Income: salary.value ,
        },
  MedicalQuestions: "",
  PolicyRoles: {
    Role: [
      {
        RoleCode: "01",
        IdentificationID: "01021"
      },
      {
        RoleCode: "37",
        IdentificationID: "01021"
      },
      {
        RoleCode: "49",
        IdentificationID: "01021"
      }
    ]
  },
  Clients: {
    Client: [
      {
        ClientDetail: {
                    Title: title.value ,
                    PreviousSurname: "",
                    Initials: "",
                    FirstName: firstNames.displayValue,
                    LastName: surname.displayValue ,
                    DateOfBirth:DOB.value ,
                    TypeCode: "01", 
                    Sex: gender.value ,
                    IdentificationNumber: id.value ,
                    IdentityType: idType.value ,
                    IncomeSource: sourceOfIncome.value,
                    MaritalStatusClientField: "00", 
                    Nationality: 27,
                    MobilePhone: mobileNumber.value ,
                    AlternativeContactNumber: "",
                    EmailAddress:email.value,
                    Occupation: occupation.value ,
                    },
        Addresses: {
          Address: {
            PhysicalAddressLine1: streetAddress.value,
            PhysicalCity: city.value,
            PhysicalDistrict: district.value,
            PostalAddressLine1: postalStreetAddress.value,
            PostalCity: postalCity.value,
            PostalDistrict: postalDistrict.value
          }
        }
      }
    ]
  }
};
const submitDocumentsFailed = () => {
    store.dispatch(sendConfirmDetailsSubmitApplicationApiStatus(API_CALL_STATUS.FAILURE));
};
const isEmployed = employmentType.value === '01';
const mailDetails = {
    firstMail: isEmployed
        ? [certifiedCopyOfId.file, addressProof.file, incomeProof.file]
        : [certifiedCopyOfId.file, addressProof.file],
    secondMail: [loanLetter.file, bankStatement.file],
};
(async () => {
    const submitApplicatioResponse = await submitApplication(appicationPayload);
    if (submitApplicatioResponse && submitApplicatioResponse.status === 200) {
        store.dispatch(setFirstNamesValue(firstNames.displayValue));
        store.dispatch(setReferenceNumberValue(`${submitApplicatioResponse.data.buyRef}`));

        try {
            // const submitDocumentsResponse =  await Promise.all(
            //   mailList.map(async (document) => {
            //     const formData=new FormData()
            //     formData.append("files", document);
            //     formData.append("firstName",firstNames.displayValue );
            //     formData.append("lastName", surname.displayValue);
            //     formData.append('contactNumber','+267'+mobileNumber.value);
            //     const response = await submitDocuments(formData);
            //     return response.status===200
            //   })
            // )

            const submitFiles = async (documents) => {
                const formData = new FormData();
               await mailDetails[documents].map((document) => formData.append('files', document));
                formData.append('firstName', firstNames.displayValue);
                formData.append('lastName', surname.displayValue);
                formData.append('contactNumber', '+267' + mobileNumber.value);
                return await submitDocuments(formData);
            };
             await submitFiles('firstMail').then((response) => {
                if (response.status === 200) {
                    setTimeout(async () => {
                        await submitFiles('secondMail').then((response) => {
                            if (response.status === 200) {
                                store.dispatch(sendConfirmDetailsSubmitApplicationApiStatus(API_CALL_STATUS.SUCCESS));
                                store.dispatch(navigateToThankYou());
                            } else {
                                submitDocumentsFailed();
                            }
                        });
                    }, 500);
                } else {
                    submitDocumentsFailed();
                    throw new Error();
                }
            });
        } catch (error) {
            submitDocumentsFailed();
        }
    } else {
        submitDocumentsFailed();
    }
})();


    return result;
};

export const submitApplication = async (payload) => {
    const url = 'om-api/roa-quote-tools/digi-life-purchase';
    try {
        const response = await axios.post(
            url,payload,
            { headers: {
                'Content-Type': 'application/json', 
                'x_gravitee_api_key': '19cd0d8e-2237-4c48-ba4c-7f92d3b8c520',
            },
        }
        )
        return response;
    } catch (error) {
        return null;
    }
};

export const submitDocuments = async payload => {
    const url = 'om-api/ka-metlha-funeral/documents/kyc';
    try {
      const response =await  axios.post(
        url,payload,
        { headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": "test-api-key",
        },
    }
    )
      return response;
    } catch (error) {
      return null;
    }
};
