import {combineReducers} from "redux";
import atRetirementApplication from './atRetirementApplication';
import intro from './intro'
import aboutYou from './aboutYou';
import wizardFooter from './wizardFooter';
import budgetTool from "./budgetTool";
import financialAdvice from "./financialAdvice";
import solutions from './solutions';
import speakToAnAdviser from './speakToAnAdviser';
import progressBar from './progressBar';
import rewardsSolutionsTable from './rewardsSolutionsTable';
import scrolling from './scrolling';
import persistedAt from './persistedAt'

export const atRetirement = combineReducers({
    atRetirementApplication,
    intro,
    aboutYou,
    budgetTool,
    financialAdvice,
    speakToAnAdviser,
    wizardFooter,
    progressBar,
    solutions,
    rewardsSolutionsTable,
    scrolling,
    persistedAt
});
