export const CRITICAL_ILLNESS_QUOTE_TOOL = 'roaQuoteTools/criticalIllnessQuoteTool/';
export const CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE = CRITICAL_ILLNESS_QUOTE_TOOL + 'setValue/';
export const CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID = CRITICAL_ILLNESS_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_CRITICAL_ILLNESS_QUOTE_TOOL = 'roaQuoteTools/criticalIllnessQuoteTool/transition/formStateValid';
export const FORM_INVALID_CRITICAL_ILLNESS_QUOTE_TOOL = 'roaQuoteTools/criticalIllnessQuoteTool/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/criticalIllnessQuoteTool/state/reinitialize';

export const transitionCriticalIllnessTool = {
    formValid: () => ({ type: FORM_VALID_CRITICAL_ILLNESS_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_CRITICAL_ILLNESS_QUOTE_TOOL }),
};

export const GENDER_SET_VALUE = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'genderValue';
export const GENDER_SET_VALID = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID + 'genderValue';
export const DOB_SET_VALUE = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'DateOfBirth';
export const DOB_SET_VALID = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID + 'DateOfBirth';
export const SMOKING_SET_VALUE = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'smokingValue';
export const SMOKING_SET_VALID = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID + 'smokingValue';
export const COVERED_UP_SET_VALUE = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'coveredupValue';
export const COVERED_UP_SET_VALID = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID + 'coveredupValue';
export const YEAR_SET_VALUE = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'yearValue';
export const YEAR_SET_VALID = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID + 'yearValue';
export const DISABILITY_SET_VALUE = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'DisabilityValue';
export const DISABILITY_SET_VALID = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALID + 'DisabilityValue';
export const WHICH_FORM_TO_DISPLAY = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const COLLECT_CRITICAL_ILLNESS_PREMIUM_DATA = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'criticalIllnessQuoteToolPremiumAmount';
export const CALCULATE_CRITICAL_ILLNESS_PREMIUM_DATA = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_CRITICAL_ILLNESS_PREMIUM_DATA_FAILURE = CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL = `${CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL = `${CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL = `${CRITICAL_ILLNESS_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitioncriticalIllnessQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_CRITICAL_ILLNESS_QUOTE_TOOL }),
};

export const collectCriticalillnessPremiumData = () => ({ type: COLLECT_CRITICAL_ILLNESS_PREMIUM_DATA });
export const calculateCriticalillnessPremiumData = (value) => ({ type: CALCULATE_CRITICAL_ILLNESS_PREMIUM_DATA, value });
export const collectCriticalillnessPremiumDataFailure = error => ({ type: CALCULATE_CRITICAL_ILLNESS_PREMIUM_DATA_FAILURE, error });


export const setGenderValue = value => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = error => ({ type: GENDER_SET_VALID, error });
export const setDOBValue = value => ({ type: DOB_SET_VALUE, value });
export const setDOBValid = error => ({ type: DOB_SET_VALID, error });
export const setSMOKINGValue = value => ({ type: SMOKING_SET_VALUE, value });
export const setSMOKINGValid = error => ({ type: SMOKING_SET_VALID, error });
export const setCoveredUpValue = value => ({ type: COVERED_UP_SET_VALUE, value });
export const setCoveredUpValid = error => ({ type: COVERED_UP_SET_VALID, error });
export const setYearsValue = value => ({ type: YEAR_SET_VALUE, value });
export const setYearsValid = error => ({ type: YEAR_SET_VALID, error });
export const setDisabilityValue = value => ({ type: DISABILITY_SET_VALUE, value });
export const setDisabilityValid = error => ({ type: DISABILITY_SET_VALID, error });
export const setToCriticalillnessQuoteInitial = () => ({ type: REINITIALIZE });
export const setWhichFormToDisplay = value => ({ type: WHICH_FORM_TO_DISPLAY, value });
