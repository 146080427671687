import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';
import {
    AGE_BRACKET_SET_VALUE,
    AGE_BRACKET_SET_VALID,
    SPOUSE_AGE_SET_VALID,
    SPOUSE_AGE_SET_VALUE,
    SPOUSE_COVER_SET_VALID,
    SPOUSE_COVER_SET_VALUE,
    IN_PATIENT_SET_VALID,
    IN_PATIENT_SET_VALUE,
    OUT_PATIENT_SET_VALID,
    OUT_PATIENT_SET_VALUE,
    CC_SET_VALID,
    CC_SET_VALUE,
    AGE_18child_SET_VALUE,
    AGE_18child_SET_VALID,
    WHICH_FORM_TO_DISPLAY,
    REINITIALIZE,
    COLLECT_COUNTY_PREMIUM_DATA,
    CALCULATE_COUNTY_PREMIUM_DATA,
    API_PENDING_TRANSITION_County_QUOTE_TOOL,
    API_SUCCESS_TRANSITION_County_QUOTE_TOOL,
    API_FAILURE_TRANSITION_County_QUOTE_TOOL,
    FORM_VALID_County_QUOTE_TOOL,
    FORM_INVALID_County_QUOTE_TOOL,
} from '../../actions/roaQuoteTools/afyaImaraCountyQuoteTool';

const initialeState = {
    selectedForm: 'form-one',
    formStatus: FORM_STATUS.VALID,
    CountyPremiumAmount: null,
    roaCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Afyaimara Family County',
        privacy_policy_url: '/privacy-policy/',
        extra_fields: ['Email Address'],
    },
    form: {
        ageBracket: { value: '19_29', error: null, status: 'valid' },
        childCover: { value: 'no', error: null, status: 'valid' },
        spouseAge: { value: '19_29', error: null, status: 'valid' },
        spouseCover: { value: 'no', error: null, status: 'valid' },
        inPatient: { value: 'KES 1000000', error: null, status: 'valid' },
        outPatient: {
            value: 'No Outpatient Cover',
            error: null,
            status: 'valid',
        },
        child_18: { value: 0, error: null, status: 'valid' },
        child_19_29: { value: 0, error: null, status: 'valid' },
        child_30_40: { value: 0, error: null, status: 'valid' },
        child_40_50: { value: 0, error: null, status: 'valid' },
    },
};
export default (state = initialeState, action) => {
    // console.log(action);
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_County_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_County_QUOTE_TOOL:
            return { ...state, formStatus: FORM_STATUS.INVALID };
        case AGE_BRACKET_SET_VALUE:
            return updateFormField('ageBracket', (field) => ({
                ...field,
                value: action.value,
            }));
        case AGE_BRACKET_SET_VALID:
            return updateFormField('ageBracket', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SPOUSE_COVER_SET_VALUE:
            return updateFormField('spouseCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SPOUSE_COVER_SET_VALID:
            return updateFormField('spouseCover', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case WHICH_FORM_TO_DISPLAY:
            return {
                ...state,
                selectedForm: action.value,
            };

        // Api call
        case API_PENDING_TRANSITION_County_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_County_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_County_QUOTE_TOOL:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };
        // Date of birth
        case SPOUSE_AGE_SET_VALUE:
            return updateFormField('spouseAge', (field) => ({
                ...field,
                value: action.value,
            }));
        case SPOUSE_AGE_SET_VALID:
            return updateFormField('spouseAge', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Date of birth
        case IN_PATIENT_SET_VALUE:
            return updateFormField('inPatient', (field) => ({
                ...field,
                value: action.value,
            }));
        case IN_PATIENT_SET_VALID:
            return updateFormField('inPatient', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // Initials
        case OUT_PATIENT_SET_VALUE:
            return updateFormField('outPatient', (field) => ({
                ...field,
                value: action.value,
            }));
        case OUT_PATIENT_SET_VALID:
            return updateFormField('outPatient', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        // life covered input
        case CC_SET_VALUE:
            return updateFormField('childCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case CC_SET_VALID:
            return updateFormField('childCover', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case AGE_18child_SET_VALUE:
            return updateFormField('child_18', (field) => ({
                ...field,
                value: action.value,
            }));
        case AGE_18child_SET_VALID:
            return updateFormField('child_18', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case REINITIALIZE:
            return initialeState;

        case CALCULATE_COUNTY_PREMIUM_DATA:
            return {
                ...state,
                CountyPremiumAmount: action.value,
            };
        case COLLECT_COUNTY_PREMIUM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        default:
            return state;
    }
};
