import { getIsApiPending, getQuoteForm } from '../../../selectors/ugMotorInsurance/quote';
import {
    apiTransitionMotorInsuranceQuoteForm,
    calculateMotorInsurancePremiumSuccess,
} from '../../../actions/ugMotorInsurance/quote';
import { getAccessToken } from '../../../selectors/ugMotorInsurance/payments';
import axios from 'axios';

export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionMotorInsuranceQuoteForm.pending());
            try {
                const quotation = getQuoteForm(store.getState());

                if (
                    quotation.excessAmount.value &&
                    (parseInt(quotation.excessAmount.value < 0) || parseInt(quotation.excessAmount.value < 5000000))
                ) {
                    //console.log('Checking Excess Amount ...');
                    //store.dispatch(calculateMotorInsurancePremiumSuccess(null));
                } else {
                    const resp = await calculate(store.getState());

                    store.dispatch(calculateMotorInsurancePremiumSuccess(resp.data));
                    store.dispatch(apiTransitionMotorInsuranceQuoteForm.success());
                }
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionMotorInsuranceQuoteForm.failure());
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuoteForm(state);

    const regionMap = {
        'within-ea': 2,
        'outside-ea': 3,
    };

    let region = regionMap[quotation.outsideEa.value] || 1;

    const premiumCalculationPayload = {
        carValue: parseInt(quotation.valuation.value),
        alternativeAccomodation: quotation.alternativeAccomodation.value
            ? parseInt(quotation.alternativeAccomodationDays.value)
            : 0,
        firstApplication: quotation.firstTimePurchase.value === 'yes',
        carHire: quotation.carHire.value ? parseInt(quotation.carHireDays.value) : 0,
        carAlarmDiscount: quotation.hasAlarm.value === 'yes' ? true : false,
        trackerDiscount: quotation.trackingSystem.value === 'yes' ? true : false,
        pvt: quotation.violenceAndTerrorism.value,
        region,
        excessValue: parseInt(quotation.excessAmount.value) || 0,
    };

    // Change payload here
    return {
        ...premiumCalculationPayload,
    };
};

const calculate = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/ug-motor-insurance/premium-calculation/uganda';
        return await axios.post(url, premiumCalculationPayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
