import { createSelector } from 'reselect';
import { getAllsureInsurance } from './index';
import { FORM_STATUS } from '../../reducers/status';
// import { KMF_CLAIM_PAGE_INDICES } from "../../actions/kaMetlhaFuneralClaim/types";

export const getPersonalDetails = createSelector(
    getAllsureInsurance,
    (allsureInsurance) => allsureInsurance.personalDetails
);
export const getPersonalDetailsForm = createSelector(getPersonalDetails, (personalDetails) => personalDetails.form);

const getPersonalDetailLabels = createSelector(getPersonalDetailsForm, ({ hearAboutUs }) => {
    //   {
    //     label: 'How many years of uninterrupted insurance cover have you had for your vehicle?',
    //     value: insuredDetails.displayValue ?? '-',
    // },
    // { label: 'How long you have been insured with Old mutual?', value: insuredWithOM.displayValue ?? '-' },
    // ...(insuredWithOM?.value>0 ? [{ label: 'Policy number', value: policyNumber?.value ?? '-' }]:[]),
    const platFormLabel = {
        online: 'Online Platform',
        onRadio: 'Radio Platform',
        onTv: 'Name of Platform',
        fromBillboard: 'Name of Billboard',
        fromSomeone: 'Please enter name of referrer',
        omEmployee: 'Please enter name of referrer',
        broker: 'Please enter name of referrer',
        other: 'Please enter name of referrer',
    };
    return {
        fullName: 'Full name',
        countryCodeAllsure: 'Country Code',
        cellphoneNumber: 'Cellphone number',
        insuredDetails: 'How many years of uninterrupted insurance cover do you have?',
        insuredWithOM: 'How many years of uninterrupted insurance cover have you had with Old Mutual?',
        policyNumber: 'Policy number',
        hearAboutUs: 'How did you hear about the Allsure insurance?',
        socialPlatform: 'Social Platform',
        hearAboutUsPlatform: platFormLabel[hearAboutUs?.displayValue]?.startsWith('Please enter name of referrer')
            ? 'Name of referrer'
            : platFormLabel[hearAboutUs?.displayValue],
        email: 'Email address',
        // referFriendFamily:
        //     'Is there someone among your friends or family who could also benefit from our trusted service?',
        // referFullName: 'Full name of referee',
        // referCountryCodeAllSure: 'Country Code of referee',
        // referCellphoneNumber: 'Cellphone number of referee',
    };
});
// const personalDetailLabels = {
//     fullName: 'Full name',
//     countryCodeAllsure: 'Country Code',
//     cellphoneNumber: 'Cellphone number',
//     hearAboutUs: 'Hear About Us',
//     socialPlatform: 'Social Platform',
//     hearAboutUsPlatform: 'Hear About Platform',
//     email: 'Email address',
//     referFriendFamily: 'Is there someone among your friends or family who could also benefit from our trusted service?',
//     referFullName: 'Full name of referee',
//     referCountryCodeAllSure: 'Country Code of referee',
//     referCellphoneNumber: 'Cellphone number of referee',
// };
export const getPersonalDetailsFormEditable = createSelector(
    getPersonalDetailsForm,
    (personalDetailsForm) => personalDetailsForm.isPersonalDetailsEdit
);
export const getPersonalDetailsMaped = createSelector(
    getPersonalDetailsForm,
    getPersonalDetailLabels,
    (personalDetailsForm, personalDetailLabels) => {
        // const fieldsToExlude = ['termsAndConditions',
        //    ...( personalDetailsForm.hearAboutUs.displayValue===online ?[ 'socialPlatform']:[]),
        //    ...( personalDetailsForm.hearAboutUsPlatform.value ?[ 'hearAboutUsPlatform']:[]),
        //       ['hearAboutUsPlatform']];
        return Object.keys(personalDetailsForm)
            .filter((item) => !!personalDetailsForm[item]?.value)
            .map((item) => ({ label: personalDetailLabels[item] ?? item, value: personalDetailsForm[item]?.value }))
            .filter((element) => element.label !== 'termsAndConditions');
    }
);
export const getIsPersonalDetailsFormValid = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.formStatus === FORM_STATUS.VALID
);

export const getReferalDetailsMaped = createSelector(
    getPersonalDetailsForm,
    getPersonalDetailLabels,
    (personalDetailsForm, personalDetailLabels) => {
        // const fieldsToExlude = ['termsAndConditions',
        //    ...( personalDetailsForm.hearAboutUs.displayValue===online ?[ 'socialPlatform']:[]),
        //    ...( personalDetailsForm.hearAboutUsPlatform.value ?[ 'hearAboutUsPlatform']:[]),
        //       ['hearAboutUsPlatform']];
        const referalData = [
            'hearAboutUs',
            'socialPlatform',
            'hearAboutUsPlatform',
            'referFriendFamily',
            'referFullName',
            'referCountryCodeAllSure',
            'referCellphoneNumber',
        ];
        return Object.keys(personalDetailsForm)
            .filter((item) => !!personalDetailsForm[item]?.value && referalData.includes(item))
            .map((item) => ({ label: personalDetailLabels[item] ?? item, value: personalDetailsForm[item]?.value }));
    }
);

export const getPersonalDetailsQuestions = createSelector(
    getPersonalDetailsForm,
    getPersonalDetailLabels,
    (personalDetailsForm, personalDetailLabels) => {
        const insuredDetails = ['insuredDetails', 'insuredWithOM', 'policyNumber'];
        return Object.keys(personalDetailsForm)
            .filter((item) => !!personalDetailsForm[item]?.value && insuredDetails.includes(item))
            .map((item) => ({ label: personalDetailLabels[item] ?? item, value: personalDetailsForm[item]?.value }));
    }
);
