import { getRaOptimal } from "./index";
import { createSelector } from "reselect";
import { INVESTOR_TYPES, ANNUAL_PREMIUM_INCREASES } from '../../reducers/raOptimal/constants';

export const getReferenceData = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.referenceData
);

export const getReferenceDataData = createSelector(
    getReferenceData,
    referenceData => referenceData.data,
);

const makeGetField = fieldId => createSelector(
    getReferenceDataData,
    data => data[fieldId]
);

export const getReferencePremiumTerms = makeGetField('premiumTerms');
export const getReferenceEmploymentPositions = makeGetField('employmentPositions');
export const getReferenceEmploymentIndustries = makeGetField('employmentIndustries');
export const getReferenceSourceOfFunds = makeGetField('sourceOfFunds');
export const getReferenceSourceOfIncomes = makeGetField('sourceOfIncomes');
export const getReferenceDebitOrderDays = makeGetField('debitOrderDays');
export const getReferenceRiskProfiles = makeGetField('riskProfiles');
export const getReferenceAnnualPremiumIncreases = makeGetField('annualPremiumIncreases');

export const getReferenceProductRules = makeGetField('productRules');
export const getReferenceFunds = makeGetField('funds');
export const getReferenceBanks = makeGetField('banks');
export const getReferenceBankAccountTypes = makeGetField('bankAccountTypes');
export const getReferencePublicHolidays = makeGetField('publicHolidays');
export const getReferenceAddressTypes = makeGetField('addressTypes');
export const getReferenceCorrespondenceLanguages = makeGetField('correspondenceLanguages');
export const getReferenceTitles = makeGetField('titles');
export const getReferenceCellphoneNumberPrefixes = makeGetField('cellphoneNumberPrefixes');
export const getReferenceCountryDialingCodes = makeGetField('countryDialingCodes');
export const getReferenceGenders = makeGetField('genders');
export const getReferenceMaritalStatuses = makeGetField('maritalStatuses');
export const getReferenceIdentityTypes = makeGetField('identityTypes');
export const getReferenceNationalities = makeGetField('nationalities');
export const getReferencePostalMethods = makeGetField('postalMethods');
export const getReferenceApplicationStatuses = makeGetField('applicationStatuses');
export const getReferenceCountries = makeGetField('countries');
export const getReferenceEmailAttachmentContentTypes = makeGetField('emailAttachmentContentTypes');
export const getReferencePaymentMethods = makeGetField('paymentMethods');
export const getReferenceDebitOrderDates = makeGetField('debitOrderDates');
export const getReferenceIsValid = makeGetField('isValid');
export const getReferenceMessage = makeGetField('message');
export const getReferenceMessages = makeGetField('messages');
export const getReferenceErrors = makeGetField('errors');

// retrun url for comfortable investor fund fact sheet
export const getComfortableInvestorFactSheetUrl = createSelector(
    getReferenceFunds,
    (referenceFunds) => {
        const fund = referenceFunds ? referenceFunds.find(fund => fund.riskProfileCode === INVESTOR_TYPES.COMFORTABLE) : null;
        if(fund) return fund.fundFactSheetUrl;
        return '';
    }
);

// return url for adventurous investor fund fact sheet
export const getAdventurousInvestorFactSheetUrl = createSelector(
    getReferenceFunds,
    (referenceFunds) => {
        const fund = referenceFunds ? referenceFunds.find(fund => fund.riskProfileCode === INVESTOR_TYPES.ADVENTUROUS) : null;
        if (fund) return fund.fundFactSheetUrl;
        return '';
    }
);

export const getReferenceOwnPercentages = createSelector(
    getReferenceAnnualPremiumIncreases,
    (referenceAnnualPremiums) => referenceAnnualPremiums ? referenceAnnualPremiums.find(premium => premium.code === ANNUAL_PREMIUM_INCREASES.OWN_PERCENTAGE).percentages : []
);