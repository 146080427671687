import { createSelector } from "reselect";
import { getSavingsCalculators } from './index';
import { FIELD_STATUS, FORM_STATUS } from '../../reducers/status';
import { formatCurrency } from "../currency";
import {OmValidationDateHelper} from "@om/validation";

import {
    ga_freq_fv,
    ls_fv,
    ls_pv,
    ga_freq_pmt,
    round,
    ea_to_freq,
} from '../../../tvm-calcs/src/index'

import {
    getExpectedAnnualGrowth,
    getAnnualIncreaseInContribution
} from './theFutureValueOfYourSavingsAssumptionsModel'

const assumptionModelDefaultValues = {
    expectedAnnualGrowthDefault: 8,
    annualIncreaseInContributionDefault: 10,
    inflationDefault: 5,
};

export const getFutureValueOfSavings = createSelector(
	getSavingsCalculators,
	savingsCalculators => {
        return savingsCalculators && savingsCalculators.theFutureValueOfYourSavings
    }
);

export const getFutureValueOfSavingsForm = createSelector(
	getFutureValueOfSavings,
	(theFutureValueOfYourSavings) => theFutureValueOfYourSavings.form
);

export const getFutureValueOfSavingsFormValid = createSelector(
	getFutureValueOfSavings,
	(theFutureValueOfYourSavings) => theFutureValueOfYourSavings.formStatus === FORM_STATUS.VALID
);


const makeGetField = fieldId => createSelector(
    getFutureValueOfSavingsForm,
    theFutureValueOfYourSavings => theFutureValueOfYourSavings && theFutureValueOfYourSavings[fieldId]
);


export const getExpectedEndDate = makeGetField('expectedEndDate');
export const getCurrentSavings = makeGetField('currentSavings');
export const getExpectedMonthlyContributions = makeGetField('expectedMonthlyContributions');

export const getShowProductOffering = createSelector(
    getFutureValueOfSavings,
    theFutureValueOfYourSavings => theFutureValueOfYourSavings.showProductOffering
);

export const getAssumptionsModal = createSelector(
    getFutureValueOfSavings,
    theFutureValueOfYourSavings => theFutureValueOfYourSavings.openModal
);

const getNumberOfMonths = (expectedEndDate) => {
    if(expectedEndDate.status !== FIELD_STATUS.VALID){
        return null;
    }
    const currentDate = new Date();
    const expDate = OmValidationDateHelper.dateStringToDateObject(expectedEndDate.value)
    const numberMonths = monthDiff(currentDate,expDate);
    return numberMonths
}

function monthDiff(dateFrom, dateTo) {
 return dateTo.getMonth() - dateFrom.getMonth() + 
   (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

export const getAnnualIncreaseInContributionValue = createSelector(
    getAnnualIncreaseInContribution,
        (annualIncreaseInContribution) => {
            if(!annualIncreaseInContribution.value ||annualIncreaseInContribution.value === null || annualIncreaseInContribution.value > 30)
            {
                return (assumptionModelDefaultValues.annualIncreaseInContributionDefault)/100;
            }
            else{
                return (annualIncreaseInContribution.value)/100
            }
        }
    )

export const getExpectedAnnualGrowthValue = createSelector(
    getExpectedAnnualGrowth,
    (expectedAnnualGrowth) => {
            if(!expectedAnnualGrowth.value || expectedAnnualGrowth.value === null || expectedAnnualGrowth.value > 30)
        {
            return (assumptionModelDefaultValues.expectedAnnualGrowthDefault)/100;
        }
        else{
            return (expectedAnnualGrowth.value)/100
        }
    }
)

export const getShowResultSummary = createSelector(
    getFutureValueOfSavings,
    (theFutureValueOfYourSavings) => theFutureValueOfYourSavings.showResultSummary
    
);

export const getExpectedFutureValueOfSavings = createSelector(
    getCurrentSavings,
    getExpectedMonthlyContributions,
    getExpectedAnnualGrowthValue,
    getAnnualIncreaseInContributionValue,
    getExpectedEndDate,
    (currentSavings,expectedMonthlyContributions, expectedAnnualGrowthValue,annualIncreaseInContributionValue,expectedEndDate) => {
        const numberOfMonths = getNumberOfMonths(expectedEndDate);
        return (
            round(
                ls_fv(expectedAnnualGrowthValue,numberOfMonths/12, currentSavings.value) +
                ga_freq_fv(expectedAnnualGrowthValue, 12, annualIncreaseInContributionValue, numberOfMonths/12,expectedMonthlyContributions.value)
            ,2)
        )
    }
)

export const getFormatedExpectedFutureValueOfSavings = createSelector(
    getExpectedFutureValueOfSavings,
    (expectedFutureValueOfSavings) => formatCurrency(expectedFutureValueOfSavings,'R')
)

export const getFormatedCurrentSavings = createSelector(
    getCurrentSavings,
    (currentSavings) => formatCurrency(currentSavings.value,'R')
)

export const getButtonDisabledValue = createSelector(
    getShowResultSummary,
    getFutureValueOfSavingsFormValid,
    (showResultSummary, formValid) => {
        if(formValid === false && showResultSummary === false){
            return true;
        }
        if(formValid === false && showResultSummary === true){
            return false;
        }
        if(showResultSummary === true && formValid === true){
            return true;
        }
        if(formValid === true && showResultSummary === false){
            return false;
        }
    }
);
