import { createSelector } from "reselect";
import { getRoaQuoteTools } from './index';
import {
    FORM_STATUS,
    API_CALL_STATUS,
} from "../../reducers/status";

export const getPremierSavingsQuoteTool = createSelector(
    getRoaQuoteTools,
    roaQuoteTools => roaQuoteTools.premierSavingsQuoteTool
);

export const getPremierSavingsQuoteToolForm = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool.form
);

const makeGetField = fieldId => createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool[fieldId]
);

const makeGetFieldForm = fieldId => createSelector(
    getPremierSavingsQuoteToolForm,
    form => form[fieldId]
);

export const getPremierSavingsQuoteToolFormValid = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getSelectedForm = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool.selectedForm
)

export const getLumpsumPaymentDetails = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool.form.lumpsumPaymentDetails
)

export const getQuotationSummary = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool.quotationSummary
);

export const getShowError = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE,
);

export const getIsApiPending = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => premierSavingsQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getCalculateDisabled = createSelector(
    getPremierSavingsQuoteTool,
    premierSavingsQuoteTool => {
        return premierSavingsQuoteTool.formStatus === FORM_STATUS.INVALID ||
            premierSavingsQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING;
    }
);

export const getCandidateDOB = makeGetFieldForm('candidateDOB');
export const getTermInputValue = makeGetFieldForm('termInputValue');
export const getMonthlyPremiumAmount = makeGetFieldForm('monthlyPremiumAmount');
export const getEnableLumpsumInputValue = makeGetField('enableLumpsumInput');
export const getShortTermInputValue = makeGetFieldForm('shortTermInputValue');
export const getLongTermInputValue = makeGetFieldForm('longTermInputValue');
export const getRoaCmbObject = makeGetField('roaCmbObject');
