import { createSelector } from "reselect";
import { getSavingsPotCalculatorState } from "./index";
import { FORM_STATUS } from "../../reducers/status";
import { formatCurrency } from "../currency";

export const getSavingsPotCalculator = createSelector(
  getSavingsPotCalculatorState,
  (savingsPotCalculators) => savingsPotCalculators.savingsPotCalculatorForm
);

export const getSavingsPotCalculatorForm = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.form
);

export const getSavingsPotCalculatorFormValid = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) =>
    savingsPotCalculators.formStatus === FORM_STATUS.VALID
);

export const getSavingsPotCalculatorResults = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.calculationResults
);

export const getShowRecommendation = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.showRecommendation
);

const makeGetField = (fieldId) =>
  createSelector(
    getSavingsPotCalculatorForm,
    (calculatorForm) => calculatorForm[fieldId]
  );

export const getRetirementAmount = makeGetField("retirementAmount");
export const getMonthlyIncome = makeGetField("monthlyIncome");

export const getSavingsPotForm = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.form
);

export const getWithdrawalNetAmountRaw = createSelector(
  getSavingsPotCalculatorResults,
  (savingsPotCalculators) => savingsPotCalculators.withdrawalNetAmount
);

export const getWithdrawalGrossAmountRaw = createSelector(
  getSavingsPotCalculatorResults,
  (savingsPotCalculators) => savingsPotCalculators.withdrawalGrossAmount
);


export const getWithdrawalNetAmount = createSelector(
  getSavingsPotCalculatorResults,
  (savingsPotCalculators) =>
    formatCurrency(savingsPotCalculators.withdrawalNetAmount, "R")
);

export const getTaxAmount = createSelector(
  getSavingsPotCalculatorResults,
  (savingsPotCalculators) =>
    formatCurrency(savingsPotCalculators.taxAmount, "R")
);

export const getInputDisabled = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.inputDisabled
);

export const getDisplayReset = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.displayReset
);

export const getWithdrawalGrossAmount = createSelector(
  getSavingsPotCalculatorResults,
  (savingsPotCalculators) =>
    formatCurrency(savingsPotCalculators.withdrawalGrossAmount, "R")
);

export const getShowResultSummary = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.showResultSummary
);

export const getShowLoader = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.showLoader
);

export const getApiError = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.apiError
);

export const getShowFieldDetailsStatus = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.showFieldDetailsStatus
);

export const getButtonDisabledValue = createSelector(
  getShowResultSummary,
  getSavingsPotCalculatorFormValid,
  getInputDisabled,
  (showResultSummary, formValid) => {
    if (formValid === false && showResultSummary === false) {
      return true;
    }
    if (formValid === false && showResultSummary === true) {
      return false;
    }
    if (showResultSummary === true && formValid === true) {
      return true;
    }
    if (formValid === true && showResultSummary === false) {
      return false;
    }
    if(inputDisabled === true ) {
      return true; 
    }
  }
);

export const getShowProductOffering = createSelector(
  getSavingsPotCalculator,
  (savingsPotCalculators) => savingsPotCalculators.showRecommendation
);
