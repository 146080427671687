import axios from 'axios';
import {
    getIsApiPending,
    getQuotationForm,
} from '../../../selectors/roaCarInsurance/quotation';
import {
    apiTransition,
    setSummaryFormStatus,
    calculatePremiumCalculationNgSuccess
} from '../../../actions/roaCarInsurance/quotation';

export const premiumCalculationNgService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransition.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculatePremiumCalculationNgSuccess(resp.data));
                if (resp.data.code) {
                    store.dispatch(apiTransition.failure(resp.data.code));
                } else {
                    store.dispatch(apiTransition.success());
                    store.dispatch(setSummaryFormStatus());
                }
            } catch (e) {
                store.dispatch(apiTransition.failure(e.message));
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = (formData) => ({
    make_id: formData.vehicleMake.value,
    model_id: formData.vehicleModel.value,
    year_of_manufacture: formData.manufactureYear.value,
    Value: formData.estimatedValue.value,
    Usage: formData.vehicleUse.value
});

const calculate = async (state) => {
    const formData = getQuotationForm(state);
    try {
        const url = '/om-api/roa-car-insurance/nigeria/premium-calculation';
        return await axios.post(url, premiumCalculationPayload(formData));
    } catch (e) {
        throw e;
    }
};
