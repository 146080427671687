import { formTransitionFuneralClaim } from '../../../actions/publicWeb/funeralClaimV2';

import {
    getFirstName,
    getSurname,
    getCellphoneNumber,
    getRequesterIdNumber,
    getDeceasedIdNumber,
    getFuneralDate,
    getBankOther,
    getBranchCode,
    getBankAccountNumber,
    getShowBankOther,
    getIsUserPolicyHolder,
    getEmail,
    getIsStillborn,
    // getStillbornDateOfBirth,
    getDateOfDeath,
    getCauseOfDeath,
    getReasonForUnnaturalDeath,
    getReasonForDeath,
    getBank,
    getBankAccountType,
    getAgreeToTerms,
    getShowReasonForUnnaturalDeath,
    getShowReasonForDeath,
    getIsUserBankAccountHolder,
    getBankAccountHolderIdNumber,
    getShowBankAccountHoldersFields,
    getBankAccountHolderName,
    getNoticeOfDeathDocument,
    getProofOfBankDocument,
} from '../../../selectors/publicWeb/funeralClaimV2';

import { FIELD_STATUS } from '../../../reducers/status';

const isValid = (field) => {
    return field && field.status === FIELD_STATUS.VALID;
};

const validateFuneralClaim = (state) => {
    const firstName = getFirstName(state);
    const surname = getSurname(state);
    const requesterIdNumber = getRequesterIdNumber(state);
    const cellphoneNumber = getCellphoneNumber(state);
    const email = getEmail(state);

    const isUserPolicyHolder = getIsUserPolicyHolder(state);
    const isStillborn = getIsStillborn(state);
    // const stillbornDateOfBirth = getStillbornDateOfBirth(state);
    const dateOfDeath = getDateOfDeath(state);

    const deceasedIdNumber = getDeceasedIdNumber(state);

    const causeOfDeath = getCauseOfDeath(state);
    const reasonForUnnaturalDeath = getReasonForUnnaturalDeath(state);
    const reasonForDeath = getReasonForDeath(state);

    const funeralDate = getFuneralDate(state);

    const bank = getBank(state);
    const bankOther = getBankOther(state);
    const branchCode = getBranchCode(state);
    const bankAccountNumber = getBankAccountNumber(state);
    const bankAccountType = getBankAccountType(state);
    const isUserBankAccountHolder = getIsUserBankAccountHolder(state);
    const bankAccountHolderIdNumber = getBankAccountHolderIdNumber(state);
    const bankAccountHolderName = getBankAccountHolderName(state);
    const noticeOfDeathDocument = getNoticeOfDeathDocument(state);
    const proofOfBankDocument = getProofOfBankDocument(state);

    const agreeToTerms = getAgreeToTerms(state);

    let funeralClaimValid =
        isValid(firstName) &&
        isValid(surname) &&
        isValid(requesterIdNumber) &&
        isValid(cellphoneNumber) &&
        isValid(email) &&
        isValid(causeOfDeath) &&
        isValid(dateOfDeath) &&
        isValid(funeralDate) &&
        isValid(bank) &&
        isValid(bankAccountNumber) &&
        isValid(bankAccountType) &&
        isValid(noticeOfDeathDocument) &&
        isUserBankAccountHolder !== null &&
        isUserPolicyHolder !== null;

    if (!isStillborn) {
        funeralClaimValid = funeralClaimValid && isValid(deceasedIdNumber);
    }

    if (getShowReasonForUnnaturalDeath(state)) {
        funeralClaimValid = funeralClaimValid && isValid(reasonForUnnaturalDeath);
    }

    if (getShowReasonForDeath(state)) {
        funeralClaimValid = funeralClaimValid && isValid(reasonForDeath);
    }

    if (getShowBankOther(state)) {
        funeralClaimValid = funeralClaimValid && isValid(bankOther);
        funeralClaimValid = funeralClaimValid && isValid(branchCode);
        funeralClaimValid = funeralClaimValid && isValid(proofOfBankDocument)
    }

    if (getShowBankAccountHoldersFields(state)) {
        funeralClaimValid = funeralClaimValid && isValid(bankAccountHolderIdNumber);
        funeralClaimValid = funeralClaimValid && isValid(bankAccountHolderName);
    }

    return funeralClaimValid;
};

export const funeralClaimV2Rules = (store, next, action) => {
    const result = next(action);

    // Form valid
    const funeralClaimValid = validateFuneralClaim(store.getState());
    store.dispatch(
        funeralClaimValid
            ? formTransitionFuneralClaim.formValid()
            : formTransitionFuneralClaim.formInvalid()
    );

    return result;
};
