import {
    transitionGenerateRegularIncomeCalculator,
} from "../../../actions/investmentCalculator/generateRegularIncome"

import {
    getGenerateRegularIncomeCalculatorForm
} from "../../../selectors/investmentCalculator/generateRegularIncomeCalculator"
import { formFieldValid } from '../status';

export const generateRegularIncomeCalculatorFormRules = (store, next, action) => {
    const result = next(action);
    const generateRegularIncomeCalculatorForm = getGenerateRegularIncomeCalculatorForm(store.getState());
    const generateRegularIncomeCalculatorValid = validateFormDetails(generateRegularIncomeCalculatorForm);
    const formAction = generateRegularIncomeCalculatorValid
        ? transitionGenerateRegularIncomeCalculator.formValid()
        : transitionGenerateRegularIncomeCalculator.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFieldValid(formFields?.amountOnceOff) &&
    formFieldValid(formFields?.amountMonthly) &&
    formFieldValid(formFields?.moneyMarketFundPercentage) &&
    formFieldValid(formFields?.bondFundPercentage)
);
