import {initAtRetirement} from "./atRetirementApplication"
import {initBudgetTool} from "./budgetTool"
import {initFinancialAdvice} from "./financialAdvice"
import {initIntro} from "./intro"
import {initProgressBar} from "./progressBar"
import {initRewardsSolutionsTable} from "./rewardsSolutionsTable"
import {initScrolling} from "./scrolling"
import {initSolutions} from "./solutions"
import {initSpeakToAnAdviser} from "./speakToAnAdviser"
import {initWizardFooter} from "./wizardFooter"
import {initAboutYou} from "./aboutYou"
import {initPersistedAt} from './persistedAt'

export default () => {
    return {
        atRetirementApplication: initAtRetirement,
        intro: initIntro,
        aboutYou: initAboutYou,
        budgetTool: initBudgetTool,
        financialAdvice: initFinancialAdvice,
        speakToAnAdviser: initSpeakToAnAdviser,
        wizardFooter: initWizardFooter,
        progressBar: initProgressBar,
        solutions: initSolutions,
        rewardsSolutionsTable: initRewardsSolutionsTable,
        scrolling: initScrolling,
        persistedAt: initPersistedAt,
    }
}