import {
    SHOWHIDE_FILTER_CARD_SET_VALUE,
    FILTER_SELECTION_SET_VALUE,
    CLEAR_FILTER_SELECTION_SET_VALUE,
    SEARCH_QUERY_FILTER,
    SEARCH_QUERY_SET_VALUE,
    SEARCH_QUERY_CLEAR_VALUE,
    TOGGLE_FUND_TABLE_SEARCH_BAR,
    PAGINATION_TABLE_SIZE_SET_VALUE,
    FUNDS_DATA_SET_VALUE,
    OPENED_FUND_MOBILE_SET_VALUE,
    FILTER_SELECTION_ACTIVE_OPTION_SET_VALUE
} from '../../actions/fundCentre/fundTable';
import { TOGGLE_STATUS } from '../status';

const intialState = {
    showHideFilterCard: false,
    activeFilterOption: 'riskProfiles',
    openedMobileFundIndex: 0,
    showSearchBar: TOGGLE_STATUS.UNTOUCHED,
    paginationTableSize: 10,
    paginationRanges: [10, 50, 100, 300, 500, 700],
    funds: [],
    searchQuery: null,
    recommendationFilterQuery: null,
    recommendedSearchableFunds: [],
    selections: {
        riskProfiles: [],
        compliances: [],
        status: [],
        fundTypes: [],
        products: []
    },
    prevSelections: {
        riskProfiles: [],
        compliances: [],
        status: [],
        fundTypes: [],
        products: []
    },
    filterOptions: {
        riskProfiles: [
            { type: 'Low', description: 'Low' },
            { type: 'Low to Moderate', description: 'Low-Moderate' },
            { type: 'Moderate', description: 'Moderate' },
            { type: 'Moderate to High', description: 'Moderate-High' },
            { type: 'High', description: 'High' }
        ],
        compliances: [
            { type: 'Reg28', description: 'Reg 28' },
            { type: 'Shariah', description: 'Shari’ah' },
        ],
        status: [
            { type: 'Open', description: 'Open' },
            { type: 'Capped', description: 'Capped' },
        ],
        fundTypes: [
            { type: 'Life', description: 'Life' },
            { type: 'UnitTrust', description: 'Unit Trust' },
        ],
        products: [
            { type: 'MaxIncome', description: 'Max Income' },
            { type: 'MaxInvestmentsFlexibleRA', description: 'Max Investments Flexible RA' },
            { type: 'MaxInvestmentsFlexibleEndowment', description: 'Max Investments Flexible Endowment' },
            { type: 'MaxInvestmentsFocussedEndowment', description: 'Max Investments Focussed Endowment' },
            { type: 'MaxInvestmentsXtraMAXEndowment', description: 'Max Investments XtraMAX Endowment' },
            { type: 'MaxInvestmentsXtraMAXRA', description: 'Max Investments XtraMAX RA' },
            { type: 'MaxInvestmentsOptimalRA', description: 'Max Investments Optimal RA' },
            { type: 'OldMutualInvestFlexiblePlan', description: 'Old Mutual Invest Flexible Plan' },
            { type: 'OldMutualInvestTaxFreePlan', description: 'Old Mutual Invest Tax Free Plan' },
        ],
    }
}

export default (state = intialState, action) => {
    const sortFunds = (funds, criteria) => (
        funds.filter(x => criteria.fundTypes.length > 0 ? criteria.fundTypes.includes(x.fundType) : true)
            .filter(x => criteria.riskProfiles.length > 0 ? criteria.riskProfiles.includes(x.riskProfile) : true)
            .filter(x => criteria.status.length > 0 ? criteria.status.includes(x.status) : true)
            .filter(x => criteria.compliances.length > 0 ? x.compliances.some(y => criteria.compliances.includes(y)) : true)
            .filter(x => criteria.products.length > 0 ? x.products.some(y => criteria.products.includes(y)) : true)
    )

    const orderFunds = (funds) => {
        const omFunds = funds.filter(fund => fund.fundDetails.name.toLowerCase().includes("old mutual"));
        const otherFunds = funds.filter(fund => !fund.fundDetails.name.toLowerCase().includes("old mutual"));
        otherFunds.sort((a, b) => {
            const fundAName = a.fundDetails.name;
            const fundBName = b.fundDetails.name;
            if (fundAName < fundBName)
                return -1
            if (fundAName > fundBName)
                return 1;

            return 0;
        });
        return [...omFunds, ...otherFunds];
    }

    const findOption = (option, selection) => {
        if (state.selections[option].includes(selection)) {
            return state.selections[option].indexOf(selection);
        }
        return false;
    }

    const replaceSelection = (option, newArray, selections) => {
        selections[option] = newArray;
        return selections;
    }

    switch (action.type) {
        // case SHOWHIDE_FILTER_CARD_SET_VALUE:
        //     return {
        //         ...state,
        //         showHideFilterCard: !state.showHideFilterCard,
        //     }
        case SHOWHIDE_FILTER_CARD_SET_VALUE:
            return {
                ...state,
                showHideFilterCard: !state.showHideFilterCard,
                prevSelections: state.showHideFilterCard === false ? { ...state.selections } : { ...state.prevSelections },
                selections: state.showHideFilterCard === true ? { ...state.prevSelections } : { ...state.selections }
            }
        case TOGGLE_FUND_TABLE_SEARCH_BAR:
            return {
                ...state,
                showSearchBar: state.showSearchBar !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO
            }
        case FUNDS_DATA_SET_VALUE:
            const fundsData = [];
            action.value.forEach(fund => fundsData.push(fund.node));
            return {
                ...state,
                funds: orderFunds(sortFunds(fundsData, state.selections)),
                showHideFilterCard: false
            }
        case FILTER_SELECTION_SET_VALUE:
            const selection = action.value.selection;
            const option = action.value.option;
            const current = state.selections[option].slice();
            const foundOption = findOption(option, selection);
            if (!(foundOption === false)) {
                current.splice(findOption(option, selection), 1);
            }
            return {
                ...state,
                selections: foundOption === false ?
                    Object.assign({}, state.selections, { [option]: state.selections[option].concat([selection]) }) :
                    replaceSelection(option, current, state.selections)
            }
        case CLEAR_FILTER_SELECTION_SET_VALUE:
            const funds = [];
            action.value.forEach(fund => funds.push(fund.node));
            return {
                ...state,
                showHideFilterCard: false,
                selections: intialState.selections,
                funds: orderFunds(sortFunds(funds, intialState.selections)),
            }
        case SEARCH_QUERY_FILTER:
            const fundData = [];
            action.value.forEach(fund => fundData.push(fund.node));
            const sortedFunds = sortFunds(fundData, state.selections)

            const filteredFunds = action.searchQuery.length > 0 ? sortedFunds.filter(fund => {
                return fund.fundDetails.name.toLowerCase().includes(action.searchQuery.toLowerCase()) || fund.fundDetails.description.toLowerCase().includes(action.searchQuery.toLowerCase())
            }) : [];

            const filteredFundsRecommendations = filteredFunds.splice(0, 5);

            return {
                ...state,
                recommendationFilterQuery: action.searchQuery,
                recommendedSearchableFunds: orderFunds(filteredFundsRecommendations),
            }
        case SEARCH_QUERY_SET_VALUE:
            const searchedFunds = action.value && action.value.length > 0 ? state.funds.filter(fund => {
                return fund.fundDetails.name.toLowerCase().includes(action.value.toLowerCase()) || fund.fundDetails.description.toLowerCase().includes(action.value.toLowerCase());
            }) : [];

            return {
                ...state,
                searchQuery: action.value,
                recommendedSearchableFunds: [],
                recommendationFilterQuery: null,
                funds: orderFunds(searchedFunds)
            }
        case SEARCH_QUERY_CLEAR_VALUE:
            return {
                ...state,
                searchQuery: null,
                recommendedSearchableFunds: [],
            }
        case PAGINATION_TABLE_SIZE_SET_VALUE:
            return {
                ...state,
                paginationTableSize: action.value
            }
        case OPENED_FUND_MOBILE_SET_VALUE:
            return {
                ...state,
                openedMobileFundIndex: state.openedMobileFundIndex === action.value ? -1 : action.value
            }
        case FILTER_SELECTION_ACTIVE_OPTION_SET_VALUE:
            return {
                ...state,
                activeFilterOption: state.activeFilterOption === action.value ? null : action.value
            }
        default:
            return state
    }
}
