import axios from "axios";
import { getReferenceData } from "../../../selectors/raOptimal/referenceData";
import { setReferenceData } from "../../../actions/raOptimal/referenceData";
import { setRequestId } from "../../../actions/raOptimal/sessionCamVariables";

export const referenceDataService = (store, next, action) => {
    const result = next(action);
    const refData = getReferenceData(store.getState());

    if (Object.keys(refData.data).length !== 0) return;

    (async () => {
        const referenceData = await fetchReferenceData();
        if (referenceData && (referenceData.status === 200)) {
            store.dispatch(setReferenceData(referenceData.data.entity))
        }
        
        if (referenceData && 'data' in referenceData && 'requestId' in referenceData.data) {
            store.dispatch(setRequestId(referenceData.data.requestId));
        }
    })();

    return result;
};

export const fetchReferenceData = async () => {
    try {
        const response = await axios.get('/om-api/ra-optimal/reference-data', {headers: {'Content-Type': 'application/json'}});
        return response;
    } catch (error) {
        return null;
    }
}

