import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';

export function validateBase64File(base64String, {
  required = true,
  subject = 'File',
  pronoun = 'your',
  retainSubjectCase,
}) {
	// Check if the string is empty
	if (required && !base64String) {
		return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
	}

  if (!base64String && !required) {
    return null;
  }

	return null

	
}
