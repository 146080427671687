export const APP_URL_PREFIX = '/doctor-pi';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QOUTE: 0,
    PERSONAL_DETAILS: 1,
    UPLOAD_DOCUMENTS: 2,
    PAYMENT: 3,
};

export const PAGES = [
	{
		URL: `${APP_URL_PREFIX}/get-quote`,
		URL_PART: 'get-quote',
		TITLE: 'Get Quote',
	},
	{
		URL: `${APP_URL_PREFIX}/personal-details`,
		URL_PART: 'personal-details',
		TITLE: 'Personal Details',
	},
	{
		URL: `${APP_URL_PREFIX}/upload-documents`,
		URL_PART: 'upload-documents',
		TITLE: 'Documents',
	},
	{
		URL: `${APP_URL_PREFIX}/payment`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	},
];

export const COVER_PLANS = {
    CATEGORY_A: { title: 'CATEGORY A', value: 'category_A' },
    CATEGORY_B: { title: 'CATEGORY B', value: 'category_B' },
    CATEGORY_C: { title: 'CATEGORY C', value: 'category_C' },
};


export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const DOCTORS_PI_URLS = {
	QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
	PERSONAL_DETAILS: PAGES[PAGE_INDICES.PERSONAL_DETAILS].URL,
	UPLOAD_DOCUMENTS: PAGES[PAGE_INDICES.UPLOAD_DOCUMENTS].URL,
	PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL
};
