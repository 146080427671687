import { validationIsEqual } from '../status';
import { validateAge, validateAlphaNumericString, validateAlphaString, validateNumber } from '@om/validation';
import {
    SET_SELECTED_COVER_VALUE,
    setSelectedCoverValid,
    SET_YEARS_VALUE,
    SET_INPATIENT_COVER_VALUE,
    setYearsValid,
    setInPatientCoverValid,
    SET_OUTPATIENT_COVER_VALUE,
    setOutPatientCoverValid,
    SET_SPOUSE_AGE_VALUE,
    setSpouseAgeValid,
    SET,
    SET_CHILD_AGE_VALUE,
    setChildrenAgeValid,
} from '../../../actions/healthInsuranceRW/quote';
import { getQuoteForm } from '../../../selectors/healthInsuranceRW/quote';
import { FIELD_STATUS } from '../../../reducers/status';

let prevValidationAction = null;

export const healthQuoteFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SET_SELECTED_COVER_VALUE:
                error = validateAlphaString(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'Selected Cover',
                    required: true,
                });
                return setSelectedCoverValid(error);

            case SET_YEARS_VALUE:
                error = validateAge(action.value, {
                    subject: 'Age',
                    max: 65,
                    required: true,
                });
                return setYearsValid(error);

            case SET_INPATIENT_COVER_VALUE:
                error = validateAlphaNumericString(action?.value?.value, {
                    subject: 'In Patient Cover',
                    required: true,
                });
                return setInPatientCoverValid(error);

            case SET_OUTPATIENT_COVER_VALUE:
                error = validateAlphaNumericString(action?.value?.value, {
                    subject: 'Out Patient Cover',
                    required: false,
                });
                return setOutPatientCoverValid(error);
            case SET_SPOUSE_AGE_VALUE:
                const isSpouseIncluded = getQuoteForm(store.getState()).isSpouseIncluded.value;
                if (isSpouseIncluded) {
                    error = validateAge(action.value, {
                        subject: 'Spouse Age',
                        required: false,
                        max: 65,
                    });
                    return setSpouseAgeValid(error);
                }
                return null;

            case SET_CHILD_AGE_VALUE:
                const isChildrenIncluded = getQuoteForm(store.getState()).isChildrenIncluded.value;
                if (isChildrenIncluded) {
                    error = validateChildrenAges(action.value, {
                        subject: 'Children Age',
                        required: false,
                    });
                    return setChildrenAgeValid(error);
                }
                return null;

            default:
                return null;
        }
    })();
    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};

const validateChildrenAges = (children) => {
    let isValid = true;
    children.forEach((child) => {
        const error = validateAge(child.value, {
            subject: 'Child Age',
            required: false,
            max: 25
        });
        if (error) {
            isValid = false;
            child.error = error;
            child.status = 'invalid';
        } else {
            child.error = null;
            child.status = FIELD_STATUS.VALID;
        }
    });
    return isValid ? null : 'One or more children ages are invalid';
};
