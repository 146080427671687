import { getCarDetailsForm } from '../../../selectors/roaCarInsurance/details';
import validate from '../../utils/formValidators';
import { transitionCarDetailsForm } from '../../../actions/roaCarInsurance/details';

export const carDetailsFormRules = (store, next, action) => {
    const carDetailsForm = getCarDetailsForm(store.getState());
    const isFormValid = validate(carDetailsForm, [
        'vehicleRegNo',
        'kraPinNumber',
        'id',
        'coverStartDate'
    ]);
    const formAction = isFormValid
        ? transitionCarDetailsForm.formValid()
        : transitionCarDetailsForm.formInvalid();
    store.dispatch(formAction)
	return next(action)
};
