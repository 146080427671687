import { getIsApiPending, getQuoteForm, getPremiumCalculation } from '../../../selectors/doctorsPi/quote';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure
} from '../../../actions/doctorsPi/quote';
import { getAccessToken } from '../../../selectors/doctorsPi/payments';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const shareQuotePayload = (state) => {
    const quotation = getQuoteForm(state);

    const payload = {
        emailAddress: quotation.shareEmail.value,
        category: quotation.selectedCover.value.toUpperCase(),
        indemnity_limit: parseInt(quotation.limitIndemnity.value.split(",").join("")),
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/doctors-pi/share-quote';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
