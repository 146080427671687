import { FETCH_PRICE_TABLE_FILTERED_FUNDS } from '../../../actions/priceTable/table';

import { findOtherEventIndex, otherEventTracking } from "../otherEventTracking";

export const dateFilterFormTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type) {
        case FETCH_PRICE_TABLE_FILTERED_FUNDS:
            otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            return 'Price Table';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}