// Transitions
export const CVS_TRANSITION = 'customerVerificationService/idVerification/transition/';
export const FORM_VALID_TRANSITION_ID_VERIFICATION = CVS_TRANSITION + 'formValid';
export const FORM_INVALID_TRANSITION_ID_VERIFICATION = CVS_TRANSITION + 'formInvalid';
export const ID_VERIFICATION_FORM_SET_VALUE = CVS_TRANSITION + 'formValue';
export const setIDVerificationFormValues = () => ({ type: ID_VERIFICATION_FORM_SET_VALUE });

export const transitionIDVerification = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_ID_VERIFICATION }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_ID_VERIFICATION }),
};

export const INVALID_CONTINUE_TRANSITION_ID_VERIFICATION = CVS_TRANSITION + 'invalidContinue';
export const CONTINUE_TRANSITION_ID_VERIFICATION = CVS_TRANSITION + 'validContinue';
export const BACK_TRANSITION_ID_VERIFICATION = CVS_TRANSITION + 'back';
export const invalidContinueIDVerification = () => ({ type: INVALID_CONTINUE_TRANSITION_ID_VERIFICATION });
export const continueIDVerification = () => ({ type: CONTINUE_TRANSITION_ID_VERIFICATION });
export const backIdVerification = () => ({ type: BACK_TRANSITION_ID_VERIFICATION });



// Transition - service call
export const CVS_ID_VERIFICATION_CALL = 'customerVerificationService/idVerification/verificationCall/';

export const SEND_ID_VERIFICATION_CALL = CVS_ID_VERIFICATION_CALL + 'send';
export const SUCCESS_ID_VERIFICATION_CALL = CVS_ID_VERIFICATION_CALL + 'success';
export const FAILURE_ID_VERIFICATION_CALL = CVS_ID_VERIFICATION_CALL + 'failure';

export const sendIDVerificationCall = () => ({ type: SEND_ID_VERIFICATION_CALL });
export const successIDVerificationCall = () => ({ type: SUCCESS_ID_VERIFICATION_CALL });
export const failureIDVerificationCall = error => ({ type: FAILURE_ID_VERIFICATION_CALL, error });


// Events
export const ID_VERIFICATION_SET_VALUE = 'customerVerificationService/idVerification/setValue/';
export const ID_VERIFICATION_SET_VALID = 'customerVerificationService/idVerification/setValid/';
export const ID_VERIFICATION_FETCH_VALUE = 'customerVerificationService/idVerification/fetchValue/';

export const ID_SET_VALUE = ID_VERIFICATION_SET_VALUE + 'id';
export const ID_SET_VALID = ID_VERIFICATION_SET_VALID + 'id';
export const setIDValue = value => ({ type: ID_SET_VALUE, value });
export const setIDValid = error => ({ type: ID_SET_VALID, error });

export const FIRST_NAME_SET_VALUE = ID_VERIFICATION_SET_VALUE + 'firstName';
export const setFirstNameValue = value => ({ type: FIRST_NAME_SET_VALUE, value });

export const LAST_NAME_SET_VALUE = ID_VERIFICATION_SET_VALUE + 'lastName';
export const setLastNameValue = value => ({ type: LAST_NAME_SET_VALUE, value });

export const IDENTITY_TOKEN_SET_VALUE = ID_VERIFICATION_SET_VALUE + 'identityToken';
export const setIdentityToken = value => ({ type: IDENTITY_TOKEN_SET_VALUE, value });

export const REFERENCE_GUID_SET_VALUE = ID_VERIFICATION_SET_VALUE + 'referenceGuid';
export const setReferenceGuid = value => ({ type: REFERENCE_GUID_SET_VALUE, value });

export const OPEN_GOOD_TO_GO_MODAL_SET_VALUE = ID_VERIFICATION_SET_VALUE + 'openGoodToGoModal';
export const setOpenGoodToGoModal = value => ({ type: OPEN_GOOD_TO_GO_MODAL_SET_VALUE, value });

export const VERIFY_ID_FETCH_VALUE = ID_VERIFICATION_FETCH_VALUE + 'id';
export const fetchVerifyID = () => ({ type: VERIFY_ID_FETCH_VALUE });

export const ID_VERIFICATION_RESET_PAGE = ID_VERIFICATION_SET_VALUE + 'resetPage';
export const resetIdVerificationPage = () => ({ type: ID_VERIFICATION_RESET_PAGE });