import { createSelector } from 'reselect';
import { getPersonalAccidentState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDocuments = createSelector(getPersonalAccidentState, (state) => state.documents);
export const getDocumentsForm = createSelector(getDocuments, (documents) => documents.form);

export const getDropOffDetails = createSelector(getDocuments, (documents) => documents.dropOffDetails);

export const getSelectedForm = createSelector(getDocuments, (documents) => documents.selectedForm);

export const getDocumentsFormValid = createSelector(
    getDocuments,
    (documents) => documents.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getDocuments,
    (documents) => documents.formStatus === API_CALL_STATUS.PENDING
);

const makeGetFieldForm = (fieldId) => createSelector(getDocumentsForm, (form) => form[fieldId]);

export const getIdUploadValue = makeGetFieldForm('idUpload');
export const getKraPinUploadValue = makeGetFieldForm('kraPinUpload');
