import {ABOUT_YOU_SET_VALUE} from "../../../actions/atRetirement/aboutYou";
import {AT_RETIREMENT_APPLICATION_SET_VALUE} from "../../../actions/atRetirement/atRetirementApplication";
import {BUDGET_SET_VALUE} from "../../../actions/atRetirement/budgetTool";
import {FIN_ADVICE_SET_VALUE} from "../../../actions/atRetirement/financialAdvice";
import {INTRO_SET_VALUE} from "../../../actions/atRetirement/intro";
import {PROGRESS_BAR_SET_VALUE} from "../../../actions/atRetirement/progressBar";
import {REWARDS_SOLUTIONS_SET_VALUE} from "../../../actions/atRetirement/rewardsSolutionsTable";
import {SOLUTIONS_SET_VALUE} from "../../../actions/atRetirement/solutions";
import {SPEAK_TO_AN_ADVISER_SET_VALUE} from "../../../actions/atRetirement/speakToAnAdviser";
import {WIZARD_FOOTER_SET_VALUE} from "../../../actions/atRetirement/wizardFooter";

export const isArcAction = action => (
    action.type.startsWith(ABOUT_YOU_SET_VALUE) ||
    action.type.startsWith(AT_RETIREMENT_APPLICATION_SET_VALUE) ||
    action.type.startsWith(BUDGET_SET_VALUE) ||
    action.type.startsWith(FIN_ADVICE_SET_VALUE) ||
    action.type.startsWith(INTRO_SET_VALUE) ||
    action.type.startsWith(PROGRESS_BAR_SET_VALUE) ||
    action.type.startsWith(REWARDS_SOLUTIONS_SET_VALUE) ||
    action.type.startsWith(SOLUTIONS_SET_VALUE) ||
    action.type.startsWith(SPEAK_TO_AN_ADVISER_SET_VALUE) ||
    action.type.startsWith(WIZARD_FOOTER_SET_VALUE)
);
