import { FIELD_STATUS } from "../../../reducers/status";
import {
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getEditCoverMember,
    getAddCoverMember,
    getAddOrEditCoverMember,
    getLivesCoveredForm,
    getCoverMembersForms,
    getLivesCoveredFormValid,
    getLivesCoveredFormComplete,
    getPremiumFromCoverAmount,
    getDirectFamilyPremium,
    getIsLivesCoveredPageComplete,
    getRemoveCoverMember,
    getAgeValue
} from "../../../selectors/easiplusFuneralPlan/livesCovered";
import {
    setTitleValue,
    setFirstNameValue,
    setSurnameValue,
    setDateOfBirthValue,
    setAgeValue,
    saveCoverMember,
    addNewCoverMember,
    transitionLivesCovered,
    syncDirectFamilyCoverAmounts,
    updateMyselfPremiumLivesCovered,
    updateReferenceCoverCategories,
    updateReferenceCoverMembers,
    updateCoverMembers,
    updateMyselfLivesCovered,
    setModalValue,
    setMonthlyPremiumValue,
    DATE_OF_BIRTH_SET_VALUE,
    PUSH_COVER_MEMBER_LIVES_COVERED,
    MODAL_CLEAR_VALUE,
    AGE_SET_VALUE,
    COVER_AMOUNT_SET_VALUE,
    SET_EDIT_COVER_MEMBER_LIVES_COVERED,
    SET_ADD_COVER_MEMBER_LIVES_COVERED,
    DELETE_COVER_MEMBER_LIVES_COVERED
} from "../../../actions/easiplusFuneralPlan/livesCovered";
import { resetBeneficiaries, updateBeneficiaryList } from "../../../actions/easiplusFuneralPlan/beneficiaries"
import {
    deselectEvenSplit,
    discardEvenSplit,
    setTotalPercentageValue,
} from "../../../actions/easiplusFuneralPlan/beneficiariesSplit";
import { coverMemberDirectFamily } from "../../../reducers/easiplusFuneralPlan/livesCovered"
import { getLivesCovered } from "../../../selectors/easiplusFuneralPlan/quote"
import { EFP_LIVES_COVERED_SCROLL_ID } from "../status";
import { transitionScrolling } from "../../../actions/easiplusFuneralPlan/scrolling";
import { OmValidationDateHelper } from "@om/validation";
import {
    COVER_CATEGORIES,
    COVER_CATEGORY_MEMBERS,
    EFP_COVER_CATEGORIES,
    EFP_POPUP_MODALS
} from "../../../actions/easiplusFuneralPlan/types";
import { getPersonalDetailsForm } from "../../../selectors/easiplusFuneralPlan/personalDetails";

export const livesCoveredRules = (store, next, action) => {
    const result = next(action);
    let state = store.getState();
    const livesCoveredForm = getLivesCoveredForm(state);
    const livesCoveredFormIsValid = validateLivesCoveredForm(livesCoveredForm);
    const coverMember = getAddOrEditCoverMember(store.getState());
    let premium;

    store.dispatch(livesCoveredFormIsValid
        ? transitionLivesCovered.formValid()
        : transitionLivesCovered.formInvalid());

    switch (action.type) {
        case SET_ADD_COVER_MEMBER_LIVES_COVERED:
            // Updates direct family premium when adding child / spouse
            if (isDirectFamilyMember(coverMember)) {
                store.dispatch(setMonthlyPremiumValue(getPremiumFromCoverAmount(state)))
            }
            break;

        case COVER_AMOUNT_SET_VALUE:
            store.dispatch(setMonthlyPremiumValue(getPremiumFromCoverAmount(state)));
            break;

        case AGE_SET_VALUE:
            if (getAgeValue(state).status === FIELD_STATUS.VALID) {
                premium = getPremiumFromCoverAmount(state);
                if (!isDirectFamilyMember(coverMember)) {
                    store.dispatch(setMonthlyPremiumValue(premium));
                }
            }

            break;

        case DELETE_COVER_MEMBER_LIVES_COVERED:
            const removeMember = getRemoveCoverMember(store.getState());
            if (isDirectFamilyMember(removeMember)) {
                const directFamilyPremium = getDirectFamilyPremium(state);
                store.dispatch(updateMyselfPremiumLivesCovered(directFamilyPremium));
                store.dispatch(syncDirectFamilyCoverAmounts(COVER_CATEGORY_MEMBERS.MYSELF.code));
            }
            break;

        case DATE_OF_BIRTH_SET_VALUE:
            const dateOfBirth = getDateOfBirth(state);
            if (dateOfBirth.status === FIELD_STATUS.VALID) {
                const age = calculateAge(dateOfBirth.value);
                store.dispatch(setAgeValue(age, coverMember.includes('child'), coverMember.includes('myself')));
            }
            break;

        case PUSH_COVER_MEMBER_LIVES_COVERED:
            saveLivesCoveredMember(store);
            break;

        case MODAL_CLEAR_VALUE:
            setScrollToEmptyCard(store, action.value);

        default:
            break;
    }

    // Update state if trigger on member addition
    state = store.getState();
    const livesCoveredPageComplete = getIsLivesCoveredPageComplete(state);
    store.dispatch(livesCoveredPageComplete
        ? transitionLivesCovered.formComplete()
        : transitionLivesCovered.formIncomplete());

    return result;
};

const isDirectFamilyMember = coverMember => {
    return coverMember && (coverMember === COVER_CATEGORY_MEMBERS.SPOUSE.code || coverMember.startsWith('child'));
}   

const getField = value => {
    return { value: value, error: null, status: FIELD_STATUS.VALID };
}

export const performLivesCoveredValidation = store => {
    store.dispatch(setModalValue(EFP_POPUP_MODALS.INCOMPLETE_MODAL));
}

export const livesCoveredUpdatePersonalValueRules = (store) => {
    const state = store.getState();
    const personalDetailsForm = getPersonalDetailsForm(state);
    const idValue = personalDetailsForm.id.value;
    const dob = getField(calculateDateOfBirth(idValue));
    const myselfObject = {
        title: personalDetailsForm.title,
        name: personalDetailsForm.firstNames,
        surname: personalDetailsForm.surname,
        dateOfBirth: dob,
        age: getField(calculateAge(dob.value))
    };
    
    store.dispatch(updateMyselfLivesCovered(myselfObject));
    const updatedState = store.getState();

    const directFamilyPremium = getDirectFamilyPremium(updatedState);
    store.dispatch(updateMyselfPremiumLivesCovered(directFamilyPremium));
    store.dispatch(syncDirectFamilyCoverAmounts(COVER_CATEGORY_MEMBERS.MYSELF.code));
}

const saveLivesCoveredMember = (store) => {
    const state = store.getState();
    const livesCoveredFormIsValid = getLivesCoveredFormValid(state);

    if (livesCoveredFormIsValid) {
        const editCoverMember = getEditCoverMember(state);
        const addCoverMember = getAddCoverMember(state);
        if (editCoverMember) {
            store.dispatch(saveCoverMember());
            setScrollMember(store, editCoverMember);
        } else {
            store.dispatch(addNewCoverMember());
            setScrollMember(store, addCoverMember);
        }

        const updateState = store.getState();
        store.dispatch(updateBeneficiaryList(getCoverMembersForms(updateState)));

        const coverMember = getAddOrEditCoverMember(state);
        const isDirectFamilyMemberOrMyself = isDirectFamilyMember(coverMember) || coverMember === COVER_CATEGORY_MEMBERS.MYSELF.code;
        if (isDirectFamilyMemberOrMyself) store.dispatch(syncDirectFamilyCoverAmounts(coverMember));

        return;
    }

    // reset the field values -- this also forces a validation check, in case form is invalid
    store.dispatch(setTitleValue(getTitle(state).value));
    store.dispatch(setFirstNameValue(getFirstName(state).value));
    store.dispatch(setSurnameValue(getSurname(state).value));
    store.dispatch(setDateOfBirthValue(getDateOfBirth(state).value));
    setScrollError(store);
}

const validateLivesCoveredForm = form => {
    return form.title && form.title.status === FIELD_STATUS.VALID &&
            form.firstName && form.firstName.status === FIELD_STATUS.VALID &&
            form.surname && form.surname.status === FIELD_STATUS.VALID &&
            form.dateOfBirth && form.dateOfBirth.status === FIELD_STATUS.VALID &&
            form.age && form.age.status === FIELD_STATUS.VALID;
};

export const calculateAge = dob => {
    const dobArray = OmValidationDateHelper.dateStringToNumberArray(dob);
    return Math.floor(OmValidationDateHelper.yearsDifference(OmValidationDateHelper.todayDateArray(), dobArray));
}

const calculateDateOfBirth = id => {
    return OmValidationDateHelper.getDateOfBirthFromSaIdNumber(id);
}

export const setScrollError = store => {
    const state = store.getState();
    const livesCoveredForm = getLivesCoveredForm(state);
    const firstErrorField = EFP_LIVES_COVERED_SCROLL_ID.find(fieldKey =>
        fieldKey in livesCoveredForm && livesCoveredForm[fieldKey].error
    );

    store.dispatch(transitionScrolling.scrollToId(firstErrorField));
};

export const setScrollMember = (store, member) => {
    store.dispatch(transitionScrolling.scrollToId(member));
};

export const setScrollToEmptyCard = (store, modal) => {
    if (modal === EFP_POPUP_MODALS.INCOMPLETE_MODAL) {
        const state = store.getState();
        const livesCoveredForm = getCoverMembersForms(state);
        const formKeys = Object.keys(livesCoveredForm)
        const firstEmptyCard = formKeys.find(key => {
            if (!key.startsWith('child') && key !== COVER_CATEGORY_MEMBERS.SPOUSE.code) {
                return livesCoveredForm[key].firstName.value === null;
            }
        });

        store.dispatch(transitionScrolling.scrollToId(firstEmptyCard));
    }
};

export const livesCoveredUpdateFormRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const livesCoveredObject = getLivesCovered(state);
    const memberKeyArray = Object.keys(COVER_CATEGORY_MEMBERS);

    // Get key for each member, count of each key, and selected cover amount for members
    // Example: { MYSELF:
    //            { code: myself, number: 1, coverAmount: { value: 10000, error: null, status: valid }
    //          },
    //          { AUNT:
    //              { code: aunt, number: 3, coverAmount:
    //                  [
    //                      { value: 10000, error: null, status: valid },
    //                      { value: 20000, error: null, status: valid },
    //                      { value: 25000, error: null, status: valid }
    //                  ]
    //              }
    //          }
    let transformedKeyObject = {};
    Object.keys(EFP_COVER_CATEGORIES).map(key => {
        livesCoveredObject[EFP_COVER_CATEGORIES[key]].map(member => {
            let locatedKey;
            let isArray = false;

            if (EFP_COVER_CATEGORIES.DIRECT_FAMILY === EFP_COVER_CATEGORIES[key]) {
                locatedKey = memberKeyArray.filter(memberKey => {
                    return COVER_CATEGORY_MEMBERS[memberKey].category === COVER_CATEGORIES.DIRECT_FAMILY.key
                });
                isArray = true;
            } else {
                locatedKey = memberKeyArray.filter(memberKey => {
                    return COVER_CATEGORY_MEMBERS[memberKey].description === member.value.relationship.value;
                });
            }
            transformedKeyObject = addToObject(isArray, locatedKey, transformedKeyObject, member);
        });
    });

    // Build up coverMembers object for lives covered state - see livesCovered.coverMembers for format
    let filteredCoverMembers = {};
    const transformedKeyKeys = Object.keys(transformedKeyObject);
    transformedKeyKeys.map(key => {
        if (transformedKeyObject[key].number === 1) {
            // Doesn't matter which coverMemberDirectFamily() is used, as the cover amount is copied from quote
            filteredCoverMembers[transformedKeyObject[key].code] = {
                ...coverMemberDirectFamily(),
                coverAmount: transformedKeyObject[key].coverAmount,
                monthlyPremium: transformedKeyObject[key].monthlyPremium,
            };
        } else {
            for (let i = 1; i <= transformedKeyObject[key].number; i++) {
                const code = transformedKeyObject[key].code + '_' + i;
                filteredCoverMembers[code] = {
                    ...coverMemberDirectFamily(),
                    coverAmount: transformedKeyObject[key].coverAmount[i-1],
                    monthlyPremium: transformedKeyObject[key].monthlyPremium[i-1],
                };
            }
        }
    });

    // Build up referenceCoverMembers for state
    let filteredReferenceCoverMembers = {};
    transformedKeyKeys.map(key => {
        if (transformedKeyObject[key].number === 1) {
            filteredReferenceCoverMembers[key] = COVER_CATEGORY_MEMBERS[key];
        } else {
            for (let i = 1; i <= transformedKeyObject[key].number; i++) {
                let basicObject = COVER_CATEGORY_MEMBERS[key];
                let numberKey = key + '_' + i;
                filteredReferenceCoverMembers[numberKey] = {
                    ...basicObject,
                    key: numberKey,
                    code: COVER_CATEGORY_MEMBERS[key].code + '_' + i
                }
            }
        }
    });

    store.dispatch(resetBeneficiaries());
    store.dispatch(deselectEvenSplit());
    store.dispatch(discardEvenSplit());
    store.dispatch(setTotalPercentageValue(0));
    store.dispatch(updateCoverMembers(filteredCoverMembers));
    store.dispatch(updateReferenceCoverMembers(filteredReferenceCoverMembers));
    store.dispatch(updateReferenceCoverCategories(getSelectedCategories(filteredReferenceCoverMembers)));
    return result;
};

const addToObject = (array, key, existingObject, member) => {
    let returnObject = {...existingObject};

    if (array) {
        key.map(addedKey => {
            returnObject[addedKey] = {
                code: COVER_CATEGORY_MEMBERS[addedKey].code,
                number: 1,
                coverAmount: member.value.coverAmount,
                monthlyPremium: member.value.monthlyPremium
            };
        });
    } else {
        if (key in returnObject) {
            if (returnObject[key].number === 1) {
                const lastCoverAmount = returnObject[key].coverAmount;
                const lastMonthlyPremium = returnObject[key].monthlyPremium;
                returnObject[key].coverAmount = [];
                returnObject[key].coverAmount.push(lastCoverAmount);
                returnObject[key].monthlyPremium = [];
                returnObject[key].monthlyPremium.push(lastMonthlyPremium);
            }
            returnObject[key].number++;
            returnObject[key].coverAmount.push(member.value.coverAmount);
            returnObject[key].monthlyPremium.push(member.value.monthlyPremium);
        } else {
            returnObject[key] = {
                code: COVER_CATEGORY_MEMBERS[key].code,
                number: 1,
                coverAmount: member.value.coverAmount,
                monthlyPremium: member.value.monthlyPremium
            };
        }
    }
    return returnObject;
}

const getSelectedCategories = members => {
    const keyArray = Object.keys(members);
    const fullCategoryArray = keyArray.map(key => members[key].category);
    const categoryArray = [...new Set(fullCategoryArray)];
    let categoryObject = {};
    categoryArray.forEach(category => {
        categoryObject[category] = COVER_CATEGORIES[category]
    })
    return categoryObject;
}
