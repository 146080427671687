import {
  AGE_SET_VALUE,
  GENDER_SET_VALUE,
  POSTAL_CODE_SET_VALUE,
} from "../../../actions/covidSelfAssessment/biographicInfo"
import {getAge, getGender, getPostalCode} from "../../../selectors/covidSelfAssessment/biographicInfo"
import {simpleFieldTracking} from "../formTracking";

export const biographicInfoFieldTracking = (store, next, action) => {
  const result = next(action);
  const state = store.getState();
  const field = (() => {
      switch (action.type) {
          case AGE_SET_VALUE: return getAge(state);
          case GENDER_SET_VALUE: return getGender(state);
          case POSTAL_CODE_SET_VALUE: return getPostalCode(state);
          default: return null;
      }
  })();

  if (field) {
      simpleFieldTracking({action, field});
  }

  return result;
};
