import {findOtherEventIndex, otherEventTracking} from '../otherEventTracking';
import {
    QUALIFYING_TOGGLE_SIDE_DRAWER_WEB,
    QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES,
    RETURN_DATA_FROM_LEADS_QUALIFYING
} from '../../../actions/publicWeb/qualifyingCheckCallMeBack';
import {getLeadsResponse, getSidebarOpen} from '../../../selectors/publicWeb/qualifyingCheckCallMeBack';

export const qualifyingCheckTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const trackingActions = [
        QUALIFYING_TOGGLE_SIDE_DRAWER_WEB,
        QUALIFYING_TOGGLE_SIDE_DRAWER_LENDING_SALES
    ];

    let openDrawer = getSidebarOpen(state);
    if (trackingActions.includes(action.type)) {
        if (openDrawer) {
            otherEventTracking(action.type, findOtherEventIndex('OPEN_DRAWER'), openDrawer);
        }
    }

    let leadsResponse = getLeadsResponse(state);
    const field = (() => {
        switch (action.type) {
        case RETURN_DATA_FROM_LEADS_QUALIFYING:
            otherEventIndex = findOtherEventIndex('LEADS_SET_REFERRER_DATA');
            return leadsResponse && leadsResponse.data && leadsResponse.data.referralId ? leadsResponse.data.referralId : leadsResponse
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}