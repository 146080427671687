import {transitionBudgetTool} from "../../../actions/atRetirement/budgetTool";
import {transitionScrolling} from "../../../actions/atRetirement/scrolling";
import {ABOUT_YOU_SCROLL_CLASS} from "../status";
import {setBudgetValue as setBudgetValueAboutYou, setBudgetValid} from "../../../actions/atRetirement/aboutYou";
import {
    setFixedMonthlyExpensesValue,
    setFixedMonthlyExpensesUntouched,
    setDayToDaySpendingValue,
    setDayToDaySpendingUntouched,
    setInsuranceValue,
    setInsuranceUntouched,
    setDebtsValue,
    setDebtsUntouched,
    setBudgetValue
} from "../../../actions/atRetirement/budgetTool";
import {getBudgetDrawerIsOpen} from "../../../selectors/atRetirement/aboutYou";
import {
    getBudgetToolForm,
    getBudgetValue
} from "../../../selectors/atRetirement/budgetTool";
import {FIELD_STATUS} from "../../../reducers/status";

export const budgetToolRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const budgetToolForm = getBudgetToolForm(state);
    const formIsValid = formValid(budgetToolForm);
    store.dispatch(formIsValid
        ? transitionBudgetTool.formValid()
        : transitionBudgetTool.formInvalid());

    // Update budget value
    const budgetValue = getBudgetValue(state);
    if (formIsValid && budgetValue && budgetValue > 0) {
        store.dispatch(setBudgetValueAboutYou(budgetValue.toString()));
        store.dispatch(setBudgetValid());
    }

    return result;
};

export const clearForm = (store, next, action) => {
    const result = next(action);
    const budgetDrawOpen = getBudgetDrawerIsOpen(store.getState());

    store.dispatch(setFixedMonthlyExpensesValue());
    store.dispatch(setFixedMonthlyExpensesUntouched());
    store.dispatch(setDayToDaySpendingValue());
    store.dispatch(setDayToDaySpendingUntouched());
    store.dispatch(setInsuranceValue());
    store.dispatch(setInsuranceUntouched());
    store.dispatch(setDebtsValue());
    store.dispatch(setDebtsUntouched());
    store.dispatch(setBudgetValue());

    //clearing budget tool and siderdrawer closing
    if (!budgetDrawOpen) {
        store.dispatch(transitionScrolling.scrollToClass(ABOUT_YOU_SCROLL_CLASS.CONTINUE_BUTTON));
    }

    return result;
};

const formValid = formFields => {
    return Object.values(formFields).every(field =>
        field.status === FIELD_STATUS.VALID
    );
};
