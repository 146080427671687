import {createSelector} from "reselect";
import {PAGE_INDICES, PROGRESS_BAR_INDICES} from "../../actions/taxFreeSavingsAccount/types";
import {getTaxFreeSavingsAccount} from "./index";
import {getHasOtherTaxFreePlan, getInvestmentSetupInitialFormIsValid} from "./investmentSetupInitial";
// import { getIsBeneficiarySplitValid } from "./beneficiariesSplit";
import { getIsPaymentDetailsValid } from "./paymentDetails";
import { getIsInvestmentSetupContributionValid } from "./investmentSetupContribution";
import { getIsPersonalDetailsValid } from "./personalDetails";
import { getSelectedFundList, getAdvancedFundPickerFormIsValid} from "./advancedFundPicker";
import { getConfirmDetailsValid } from "./review"
import { getIsFundSplitValid } from "./fundSplit";
import { getSelectAFundFormIsValid } from "./selectAFund"
import { getIsAuxiliaryDetailsValid } from "./auxiliaryDetails";

export const getRouting = createSelector(getTaxFreeSavingsAccount, tfsa => tfsa.routing);
export const getActivePageIndex = createSelector(getRouting, routing => routing.activePageIndex);
export const getDoTfsaNavigation = createSelector(getRouting, routing => routing.doNavigation);
export const getDoExitTfsaNavigation = createSelector(getRouting, routing => routing.doExitNavigation);
export const getLandingPage = createSelector(getRouting, routing => routing.landingPage);

const makeGetIsPage = pageIndex => createSelector(getRouting, routing => routing.activePageIndex === pageIndex);
export const getIsInvestmentSetupInitialPage = makeGetIsPage(PAGE_INDICES.INVESTMENT_SETUP_INITIAL);
export const getIsInvestmentSetupContributionPage = makeGetIsPage(PAGE_INDICES.INVESTMENT_SETUP_CONTRIBUTION);
export const getIsSelectAFundPage = makeGetIsPage(PAGE_INDICES.SELECT_A_FUND);
export const getIsAdvancedFundPickerPage = makeGetIsPage(PAGE_INDICES.ADVANCED_FUND_PICKER);
export const getIsFundSplitPage = makeGetIsPage(PAGE_INDICES.FUND_SPLIT);
export const getIsPersonalDetailsPage = makeGetIsPage(PAGE_INDICES.PERSONAL_DETAILS);
export const getIsAuxiliaryDetailsPage = makeGetIsPage(PAGE_INDICES.AUXILIARY_DETAILS);
// export const getIsBeneficiariesPage = makeGetIsPage(PAGE_INDICES.BENEFICIARIES);
// export const getIsBeneficiariesSplitPage = makeGetIsPage(PAGE_INDICES.BENEFICIARIES_SPLIT);
export const getIsPaymentDetailsPage = makeGetIsPage(PAGE_INDICES.PAYMENT_DETAILS);
export const getIsReviewPage = makeGetIsPage(PAGE_INDICES.REVIEW);
export const getIsThankYouPage = makeGetIsPage(PAGE_INDICES.THANK_YOU);

export const getProgressBarIndex = createSelector(
    getIsPersonalDetailsPage,
    getIsAuxiliaryDetailsPage,
    // getIsBeneficiariesPage,
    // getIsBeneficiariesSplitPage,
    getIsPaymentDetailsPage,
    getIsReviewPage,
    getIsThankYouPage,
    (
        isPersonalDetailsPage,
        isAuxiliaryDetailsPage,
        // isBeneficiariesPage,
        // isBeneficiariesSplitPage,
        isPaymentDetailsPage,
        isReviewPage,
        isThankYouPage,
    ) => {
        if (isPersonalDetailsPage || isAuxiliaryDetailsPage) {
            return PROGRESS_BAR_INDICES.PERSONAL_DETAILS;
        }
        
        // if (isBeneficiariesPage ||
        //     isBeneficiariesSplitPage) {
        //     return PROGRESS_BAR_INDICES.BENEFICIARIES;
        // }

        if (isPaymentDetailsPage) {
            return PROGRESS_BAR_INDICES.PAYMENT_DETAILS;
        }

        if (isThankYouPage) {
            return PROGRESS_BAR_INDICES.THANK_YOU;
        }

        return PROGRESS_BAR_INDICES.NONE;
    }
);

export const getHeaderStepText = createSelector(
    getProgressBarIndex,
    progressBarIndex => {
        switch (progressBarIndex) {
            case PROGRESS_BAR_INDICES.PERSONAL_DETAILS:
            case PROGRESS_BAR_INDICES.PAYMENT_DETAILS:
                return 'Getting To Know You';
            // case PROGRESS_BAR_INDICES.BENEFICIARIES:
            //     return 'Beneficiary Setup';
            case PROGRESS_BAR_INDICES.THANK_YOU:
                return 'All Done';
            default:
                return null;
        }
    }
);

// If we are on intvestment setup initial page and the user has selected hasOtherTaxFreePlan
// then disable the continue button
export const getDisableContinueButton = createSelector(
    getIsInvestmentSetupInitialPage,
    getHasOtherTaxFreePlan, 
    (isInvestmentSetupInitialPage, hasOtherTaxFreePlan)  => {
        return isInvestmentSetupInitialPage && hasOtherTaxFreePlan.value === 'true'
    } 
);

export const getIsFormValid = createSelector(
    getActivePageIndex,
    getInvestmentSetupInitialFormIsValid,
    getIsInvestmentSetupContributionValid,
    getSelectAFundFormIsValid,
    getAdvancedFundPickerFormIsValid,
    getIsFundSplitValid,
    getIsPersonalDetailsValid,
    getIsAuxiliaryDetailsValid,
    // getIsBeneficiarySplitValid,
    getIsPaymentDetailsValid,
    getConfirmDetailsValid,
    getSelectedFundList,
    (activePageIndex,
    isInvestmentSetupInitialFormIsValid,
    isInvestmentSetupContributionValid,
    isSelectAFundFormIsValid,
    isAdvancedFundPickerFormIsValid,
    isFundSplitValid,
    isPersonalDetailsValid,
    isAuxiliaryDetailsValid,
    // isBeneficiarySplitValid,
    isPaymentDetailsValid,
    isConfirmDetailsValid,
    selectedFundList) => {
    switch (activePageIndex) {
        case PAGE_INDICES.INVESTMENT_SETUP_INITIAL:
            if(isInvestmentSetupInitialFormIsValid) {
                return true;
            } else {
                return false;
            }

        case PAGE_INDICES.INVESTMENT_SETUP_CONTRIBUTION:
            return isInvestmentSetupContributionValid;

        case PAGE_INDICES.SELECT_A_FUND:
            if (isSelectAFundFormIsValid) {
                return true;
            } else if (selectedFundList?.length > 0) {
                return true;
            } else {
                return false;
            }

        case PAGE_INDICES.ADVANCED_FUND_PICKER:
            return isAdvancedFundPickerFormIsValid;

        case PAGE_INDICES.FUND_SPLIT:
            return isFundSplitValid;

        case PAGE_INDICES.PERSONAL_DETAILS:
            return isPersonalDetailsValid;

        case PAGE_INDICES.AUXILIARY_DETAILS:
            return isAuxiliaryDetailsValid;

        // case PAGE_INDICES.BENEFICIARIES:
        //     return true;
        //
        // case PAGE_INDICES.BENEFICIARIES_SPLIT:
        //     return isBeneficiarySplitValid;

        case PAGE_INDICES.PAYMENT_DETAILS:
            return isPaymentDetailsValid;

        case PAGE_INDICES.REVIEW:
            return isConfirmDetailsValid;

        case PAGE_INDICES.THANK_YOU:
            return true;
    }
}
);