export const PERSONAL_ACCIDENT_QUOTE_FORM = 'personalAccident/quoteForm/';

export const PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM + 'setValue/';
export const PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM + 'setValid/';
export const FORM_VALID_PERSONAL_ACCIDENT_QUOTE_FORM = 'personalAccident/quoteForm/transition/formValid';
export const FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_FORM = 'personalAccident/quoteForm/transition/formInValid';

export const PERSONAL_ACCIDENT_QUOTE_ADD_COVER = PERSONAL_ACCIDENT_QUOTE_FORM + 'addCover/';

export const PERSONAL_ACCIDENT_QUOTE_SHOW_COVER_OPTIONS = PERSONAL_ACCIDENT_QUOTE_FORM + 'coverOptions/';

export const FORM_VALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM = 'personalAccident/shareQuoteForm/transition/formValid';
export const FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM = 'personalAccident/shareQuoteForm/transition/formInValid';
export const PERSONAL_ACCIDENT_SHARE_QUOTE_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM + 'shareQuote';

export const TOKEN_IS_EXPIRED = 'personalAccident/expiredAuth';

export const PERSONAL_ACCIDENT_SIDEBAR_OPEN_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'sidebarOpen';

export const REINITIALIZE = PERSONAL_ACCIDENT_QUOTE_FORM + '/state/reinitialize';

// VALIDATE QUOTE FORM
export const transitionPersonalAccidentQuoteForm = {
    formValid: () => ({
        type: FORM_VALID_PERSONAL_ACCIDENT_QUOTE_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_FORM,
    }),
};

export const transitionShareQuoteForm = {
    formInvalid: () => ({
        type: FORM_VALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM,
    }),
    formValid: () => ({
        type: FORM_INVALID_PERSONAL_ACCIDENT_QUOTE_SHARE_FORM,
    }),
};

//cover options
export const SET_COVER_OPTIONS_LIST_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'coverOptionsList';
export const SET_COVER_OPTIONS_LIST_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'coverOptionsList';

// API Calls actions
export const API_PENDING_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM = `${PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM = `${PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE}/apiTransition/success`;

export const API_PENDING_TRANSITIONS_COVER_OPTIONS = `${SET_COVER_OPTIONS_LIST_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_COVER_OPTIONS = `${SET_COVER_OPTIONS_LIST_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_COVER_OPTIONS = `${SET_COVER_OPTIONS_LIST_VALUE}/apiTransition/failure`;

export const API_FAILURE_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM = `${PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${PERSONAL_ACCIDENT_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${PERSONAL_ACCIDENT_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${PERSONAL_ACCIDENT_SHARE_QUOTE_VALUE}/apiTransition/failure`;

export const apiTransitionPersonalAccidentQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PERSONAL_ACCIDENT_QUOTE_FORM,
    }),
};

// Car makes api transition
export const apiTransitionCoverOptions = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_COVER_OPTIONS,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_COVER_OPTIONS,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_COVER_OPTIONS,
    }),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};

//triggers
export const TRIGGER_GET_ALL_COVER_OPTIONS = PERSONAL_ACCIDENT_QUOTE_FORM + 'triggerGetAllCoverOptions';

export const TRIGGER_CALCULATE_PREMIUM = PERSONAL_ACCIDENT_QUOTE_FORM + 'triggerCalculatePremium';

//calculated Data
export const COLLECT_PERSONAL_ACCIDENT_QUOTE_FORM_DATA =
    PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'collectPersonalAccidentQuoteData';
export const SUBMIT_PERSONAL_ACCIDENT_QUOTE_SUCCESS = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'success';
export const SUBMIT_PERSONAL_ACCIDENT_QUOTE_FAILURE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_PERSONAL_ACCIDENT_PREMIUM_SUCCESS =
    PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_PERSONAL_ACCIDENT_PREMIUM_FAILURE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'failure';

export const COLLECT_SHARE_QUOTE_FORM_DATA = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_PERSONAL_ACCIDENT_QUOTE_SUCCESS = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_PERSONAL_ACCIDENT_QUOTE_FAILURE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'shareQuoteFailure';

export const collectPersonalAccidentQuoteData = () => ({
    type: COLLECT_PERSONAL_ACCIDENT_QUOTE_FORM_DATA,
});

export const submitPersonalAccidentQuoteSuccess = () => ({
    type: SUBMIT_PERSONAL_ACCIDENT_QUOTE_SUCCESS,
    value,
});

export const submitPersonalAccidentQuoteFailure = () => ({
    type: SUBMIT_PERSONAL_ACCIDENT_QUOTE_FAILURE,
    error,
});

export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

export const shareQuoteSuccess = (value) => ({
    type: SHARE_PERSONAL_ACCIDENT_QUOTE_SUCCESS,
    value,
});

export const shareQuoteFailure = (error) => ({
    type: SHARE_PERSONAL_ACCIDENT_QUOTE_FAILURE,
    error,
});

export const calculatePersonalAccidentPremiumSuccess = (value) => ({
    type: CALCULATE_PERSONAL_ACCIDENT_PREMIUM_SUCCESS,
    value,
});

export const addCover = (value) => ({
    type: PERSONAL_ACCIDENT_QUOTE_ADD_COVER,
    value,
});

export const setShowCoverOptions = (value) => ({
    type: PERSONAL_ACCIDENT_QUOTE_SHOW_COVER_OPTIONS,
    value,
});

// Form values
export const SET_UNCOMMON_RISK_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'uncommonRiskValue';
export const SET_UNCOMMON_RISK_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'uncommonRiskValid';
export const SET_EXCESS_PROTECTOR_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'excessProtectorValue';
export const SET_EXCESS_PROTECTOR_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'excessProtectorValid';
export const SET_SHARE_EMAIL_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'shareEmailValid';
export const SET_VIOLENCE_TERORISM_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'violenceAndTerrorismValue';
export const SET_VIOLENCE_TERORISM_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'violenceAndTerrorismValid';

//quote details
export const DOB_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'DOB';
export const COVER_LIMIT_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'coverLimit';
export const COVER_LIMIT_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'coverLimit';
export const PROFESSION_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'profession';
export const PROFESSION_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'profession';
export const SPOUSE_INCLUDED_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'spouseIncluded';
export const SPOUSE_INCLUDED_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'spouseIncluded';
export const SPOUSE_DOB_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'spouseDob';
export const SPOUSE_DOB_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'spouseDob';
export const CHILDREN_INCLUDED_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'childrenIncluded';
export const CHILDREN_INCLUDED_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'childrenIncluded';
export const CHILDREN_DOBS_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'childrenDobs';
export const CHILDREN_DOBS_SET_VALID = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALID + 'childrenDobs';

export const SELECTED_COVER_OPTION_SET_VALUE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'selectedCoverOption';
export const REMOVE_CHILD_DOB = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'removeChildDob';
export const SET_COVER_LIMITS = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'coverLimits';
export const SET_COVER_TYPE = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'coverType';
export const SET_COVER_OPTION_INDEX = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'coverOptionIndex';
export const SELECTED_CHILD_COVER_OPTION_SET_VALUE =
    PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'selectedChildCoverOption';
export const SET_CALCULATED_PREMIUM = PERSONAL_ACCIDENT_QUOTE_FORM_SET_VALUE + 'calculatedPremium';

// action creators

export const triggerCalculatePersonalAccidentPremium = () => ({
    type: TRIGGER_CALCULATE_PREMIUM,
});

export const setCalculatedPremium = (value) => ({
    type: SET_CALCULATED_PREMIUM,
    value,
});

export const initializeQuoteForm = () => ({
    type: REINITIALIZE,
});

export const setUncommonRiskValue = (value) => ({
    type: SET_UNCOMMON_RISK_VALUE,
    value,
});

export const setUncommonRiskValid = (error) => ({
    type: SET_UNCOMMON_RISK_VALID,
    error,
});

export const setExcessProtectorValue = (value) => ({
    type: SET_EXCESS_PROTECTOR_VALUE,
    value,
});

export const setExcessProtectorValid = (error) => ({
    type: SET_EXCESS_PROTECTOR_VALID,
    error,
});

export const setViolenceAndTerrorismValue = (value) => ({
    type: SET_VIOLENCE_TERORISM_VALUE,
    value,
});

export const setViolenceAndTerrorismValid = (error) => ({
    type: SET_VIOLENCE_TERORISM_VALID,
    error,
});

export const setShareEmailValue = (value) => ({
    type: SET_SHARE_EMAIL_VALUE,
    value,
});

export const setShareEmailValid = (error) => ({
    type: SET_SHARE_EMAIL_VALID,
    error,
});

export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value,
});

export const setSideBarOpen = (value) => ({ type: PERSONAL_ACCIDENT_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: PERSONAL_ACCIDENT_SHARE_QUOTE_VALUE, value });

//new values
export const setDOBValue = (value) => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDOBValid = (error) => ({
    type: DOB_SET_VALID,
    error,
});

export const setCoverLimitValue = (value) => ({
    type: COVER_LIMIT_SET_VALUE,
    value,
});
export const setCoverLimitValid = (error) => ({
    type: COVER_LIMIT_SET_VALID,
    error,
});

export const setProfessionValue = (value) => ({
    type: PROFESSION_SET_VALUE,
    value,
});
export const setProfessionValid = (error) => ({
    type: PROFESSION_SET_VALID,
    error,
});

export const setSpouseIncludedValue = (value) => ({
    type: SPOUSE_INCLUDED_SET_VALUE,
    value,
});
export const setSpouseIncludedValid = (error) => ({
    type: SPOUSE_INCLUDED_SET_VALID,
    error,
});

export const setSpouseDobValue = (value) => ({
    type: SPOUSE_DOB_SET_VALUE,
    value,
});
export const setSpouseDobValid = (error) => ({
    type: SPOUSE_DOB_SET_VALID,
    error,
});

export const setChildrenIncludedValue = (value) => ({
    type: CHILDREN_INCLUDED_SET_VALUE,
    value,
});
export const setChildrenIncludedValid = (error) => ({
    type: CHILDREN_INCLUDED_SET_VALID,
    error,
});

export const setChildrenDobsValue = (value) => ({
    type: CHILDREN_DOBS_SET_VALUE,
    value,
});
export const setChildrenDobsValid = (error) => ({
    type: CHILDREN_DOBS_SET_VALID,
    error,
});
export const removeChildDob = (value) => ({
    type: REMOVE_CHILD_DOB,
    value,
});

export const setCoverLimits = (value) => ({
    type: SET_COVER_LIMITS,
    value,
});

export const setCoverTypeValue = (value) => ({
    type: SET_COVER_TYPE,
    value,
});

export const setCoverOptionIndexValue = (value) => ({
    type: SET_COVER_OPTION_INDEX,
    value,
});

export const setSelectedCoverOptionValue = (value) => ({
    type: SELECTED_COVER_OPTION_SET_VALUE,
    value,
});

export const setSelectedChildCoverOptionValue = (value) => ({
    type: SELECTED_CHILD_COVER_OPTION_SET_VALUE,
    value,
});

export const triggerGetAllCoverOptions = () => ({
    type: TRIGGER_GET_ALL_COVER_OPTIONS,
});

export const setCoverOptionsValue = (value) => ({
    type: SET_COVER_OPTIONS_LIST_VALUE,
    value,
});
export const setCoverOptionsValid = (error) => ({
    type: SET_COVER_OPTIONS_LIST_VALID,
    error,
});
