import {
    NAVIGATE_NEXT,
    navigateNextConsent,
    navigatePreviousConsent,
    navigatePreviousIDVerification,
    navigatePreviousKba,
    navigatePreviousKbaInterlude,
    navigatePreviousSelfie,
    SET_NAVIGATION_BACK_TO_PARENT_APP,
    SET_NAVIGATION_TO_CVS_FROM_PARENT_APP,
    SET_NAVIGATION_DONE, NAVIGATE_PREVIOUS,
} from "../../../actions/customerVerificationService/routing";
import {getActivePageIndex, getCallbackAction} from "../../../selectors/customerVerificationService/routing";
import {
    fetchVerifyID,
    setIDValue,
    resetIdVerificationPage,
} from "../../../actions/customerVerificationService/idVerification";
import {getConsentPageIsValid} from "../../../selectors/customerVerificationService/consent";
import {openTermModal, resetConsentPage} from "../../../actions/customerVerificationService/consent";
import {
    fetchKbaQuestions,
    setNextQuestion,
    setAnswerValue,
    resetKbaSession,
    fetchKbaScore,
} from "../../../actions/customerVerificationService/knowledgeBasedAuthentication";
import {
    getAllQuestionsAnswered,
    getAnswer,
} from "../../../selectors/customerVerificationService/knowledgeBasedAuthentication";
import {getID, getIDVerificationPageValid} from "../../../selectors/customerVerificationService/idVerification";
import {PAGE_INDICES} from "../../../actions/customerVerificationService/types";
import {setSourceValuesFromParentApp} from "../../../actions/customerVerificationService/callMeBack";

export const routingRules = (store, next, action) => {
    const resetInitialPages = () => {
        store.dispatch(resetIdVerificationPage());
        store.dispatch(resetConsentPage());
    };

    // Only make change in reducer and skip rulesEngine.
    if (action.type === SET_NAVIGATION_DONE) {
        return next(action);
    }

    // Routing into CVS from parent app -- set Call Me Back source values.
    if (action.type === SET_NAVIGATION_TO_CVS_FROM_PARENT_APP) {
        store.dispatch(setSourceValuesFromParentApp(action.callMeBackSource));
        return next(action);
    }

    // Routing out of CVS to parent app -- reset CVS.
    if (action.type === SET_NAVIGATION_BACK_TO_PARENT_APP) {
        // If the parent app supplied an action to dispatch once CVS is complete, call it now.
        const callbackAction = getCallbackAction(store.getState());
        if (callbackAction) {
            store.dispatch(callbackAction);
        }

        store.dispatch(setSourceValuesFromParentApp(null));
        resetInitialPages();
        return next(action);
    }

    if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS) {
        return next(action);
    }

    const result = next(action);
    const state = store.getState();
    const activePageIndex = getActivePageIndex(state);

    if (action.type === NAVIGATE_NEXT) {
        onNavigateNext(store, state, activePageIndex);
        return result;
    }

    resetInitialPages();
    onNavigatePrevious(store, state, activePageIndex);

    return result;
};

export const onNavigateNext = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.CONSENT:
            if (!getConsentPageIsValid(state)) {
                store.dispatch(openTermModal());
                break;
            }

            store.dispatch(navigateNextConsent());
            break;

        case PAGE_INDICES.ID_VERIFICATION:
            if (!getIDVerificationPageValid(state)) {
                store.dispatch(setIDValue(getID(state).value));
                break;
            }

            store.dispatch(fetchVerifyID());
            break;

        // Selfie page does not have a standard CONTINUE button.
        // Navigation to KBA or back to parent app is handled in rulesEngine\...\selfie.js
        case PAGE_INDICES.SELFIE:
            break;

        case PAGE_INDICES.KBA_INTERLUDE:
            store.dispatch(fetchKbaQuestions());
            break;

        // The KBA process does not end with a standard CONTINUE button.
        // Displaying CallMeBack or navigation back to parent app is handled in rulesEngine\...\knowledgeBasedAuthentication.js
        case PAGE_INDICES.KBA:
            // Verification is NOT done.
            // If an answer has been selected -- continue, else validate dropdown.
            if (getAnswer(state).value) {
                store.dispatch(getAllQuestionsAnswered(state) ? fetchKbaScore() : setNextQuestion());
            } else {
                store.dispatch(setAnswerValue(null));
            }

            break;
    }
};

export const onNavigatePrevious = (store, state, activePageIndex) => {
    switch (activePageIndex) {
        case PAGE_INDICES.CONSENT:
            store.dispatch(navigatePreviousConsent());
            break;
        case PAGE_INDICES.ID_VERIFICATION:
            store.dispatch(navigatePreviousIDVerification());
            break;
        case PAGE_INDICES.SELFIE:
            store.dispatch(navigatePreviousSelfie());
            break;
        case PAGE_INDICES.KBA_INTERLUDE:
            store.dispatch(navigatePreviousKbaInterlude());
            break;
        case PAGE_INDICES.KBA:
            store.dispatch(resetKbaSession());
            store.dispatch(navigatePreviousKba());
            break;
    }
};
