import {
    getDeclarationsValid,
} from "../../../selectors/easiplusFuneralPlan/confirmDetails";
import {FORM_STATUS} from "../../../reducers/status";
import {EFP_CONFIRM_DETAILS} from "../status";
import {transitionScrolling} from "../../../actions/easiplusFuneralPlan/scrolling";
import {
    EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS,
    transitionConfirmDetails,
    setDeclarationsInvalid
} from "../../../actions/easiplusFuneralPlan/confirmDetails";
import { setReferenceNumberValue } from "../../../actions/easiplusFuneralPlan/thankYou";

export const confirmDetailsRules = (store, next, action) => {
    const result = next(action);

    const declarationsValid = getDeclarationsValid(store.getState());

    store.dispatch(declarationsValid
        ? transitionConfirmDetails.formValid()
        : transitionConfirmDetails.formInvalid());

    if(action.type === EFP_CONFIRM_DETAILS_SUBMIT_APPLICATION_SEND_SUCCESS){
        store.dispatch(setReferenceNumberValue(action.value));
    }

    return result;
};

export const performConfirmDetailsValidation = (store) => {
    store.dispatch(setDeclarationsInvalid(FORM_STATUS.INVALID));
    store.dispatch(transitionScrolling.scrollToId(EFP_CONFIRM_DETAILS.DECLARATIONS_CHECKBOX));
};
