import { FIELD_STATUS, FORM_STATUS } from "../status";

import {
    FORM_VALID_TRANSITION_REPLACEMENT_POLICY,
    FORM_INVALID_TRANSITION_REPLACEMENT_POLICY,
    IS_REPLACEMENT_POLICY_SET_VALUE,
    IS_REPLACEMENT_POLICY_SET_VALID,
    RESET_STATE_TRANSITION_REPLACEMENT_POLICY,
} from "../../actions/easiplusFuneralPlan/replacementPolicy";

export const initReplacementPolicy = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        isReplacementPolicy: { value: null, error: null, status: null },
    },
};

export default (state = initReplacementPolicy, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {

        // Form Validation
        case FORM_VALID_TRANSITION_REPLACEMENT_POLICY:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_REPLACEMENT_POLICY:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Is Replacement Policy
        case IS_REPLACEMENT_POLICY_SET_VALUE:
            return updateFormField('isReplacementPolicy', field => ({
                ...field,
                value: action.value ? true : false,
            }));

        case IS_REPLACEMENT_POLICY_SET_VALID:
            return updateFormField('isReplacementPolicy', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case RESET_STATE_TRANSITION_REPLACEMENT_POLICY:
            return { ...initReplacementPolicy };

        default:
            return state;
    }
};
