import { createSelector } from 'reselect';
import { GROSS_INCOME, NET_INCOME } from '../../actions/digitalOnboarding/types';
import { getDigitalOnboarding } from './index';

export const getAffordabilityStoreValues = createSelector(
    getDigitalOnboarding,
    digitalOnboarding => digitalOnboarding.affordabilityDetails.storeValues
);

export const getGrossIncome = createSelector(
    getAffordabilityStoreValues,
    affordability => affordability[GROSS_INCOME].numeric
);

export const getNetIncome = createSelector(
    getAffordabilityStoreValues,
    affordability => affordability[NET_INCOME].numeric
);

export const hasApplicantProvidedANetIncome = createSelector(
    getAffordabilityStoreValues,
    affordability => !affordability[NET_INCOME].pristine
);