import { createSelector } from 'reselect';
import { getBudgetBenchmarkCalculator } from './index';

export const getReferenceData = createSelector(
    getBudgetBenchmarkCalculator,
    budgetBenchmarkCalculator => budgetBenchmarkCalculator.referenceData
)

export const getBudgetRules = createSelector(
    getReferenceData,
    referenceData => referenceData.budgetRules
)
