import { BANK_NAME, EMPLOYMENT_CONTRACT_END, EMPLOYMENT_START_DATE, EMPLOYMENT_STATUS, HAS_MEDICAL_CONDITIONS, DEBT_LIABILITY, RETRENCHMENT, SECTOR, STORE_EMPLOYMENT_DETAIL, STORE_EMPLOYMENT_DETAIL_VALIDATION } from "../../actions/digitalOnboarding/types";

const storeValues = {
    [SECTOR]:{value:'',isValid: false, errorMessage: ''},
    [EMPLOYMENT_STATUS]:{value:'',isValid: false, errorMessage: ''},
    [EMPLOYMENT_START_DATE]:{value:'',isValid: false, errorMessage: '', pristine: true},
    [EMPLOYMENT_CONTRACT_END]:{value:'',isValid: false, errorMessage: '', pristine: true},
    [BANK_NAME]:{value:'',isValid: false, errorMessage: ''},
    [DEBT_LIABILITY]:{value:'',isValid: false, errorMessage: ''},
    [RETRENCHMENT]:{value:'',isValid: false, errorMessage: ''},
    [HAS_MEDICAL_CONDITIONS]:{value:'',isValid: false, errorMessage: ''},
};

export const initEmploymentDetails = {
    storeValues: storeValues,
}

export default (state = initEmploymentDetails, action) =>{
    const storeValueToStore = value => ({
        ...state,
        storeValues: { ...state.storeValues, ...value }
    });

    const updateValidationFromFields = fields => fields.reduce(
        (s, field) => {
            const storeValue = { ...s[field.key], isValid: field.isValid, errorMessage: field.errorMessage };
            if (field.value) {
                storeValue[field.key].value = field.value;
            }
            return { ...s, [field.key]: storeValue };
        },
        { ...state.storeValues }
    );

    switch (action.type) {
        case STORE_EMPLOYMENT_DETAIL_VALIDATION: {
            const storeValue = updateValidationFromFields(action.fields);
            return storeValueToStore(storeValue);
        }
        case STORE_EMPLOYMENT_DETAIL: {
            const storeValueObj = { value: action.value, isValid: action.isValid, errorMessage: action.errorMessage, pristine: false };
            return storeValueToStore({ [action.key]: storeValueObj });
        }
        default:
            break;
    }

    return state;
}
