import axios from 'axios';
import {getIsApiPending, getExtraBenefitIdValue} from "../../../selectors/roaCarInsurance/details";
import { getVehicleValuation } from '../../../selectors/roaCarInsurance/quotation';
import {
	setAddExtraBenefitValid,
    setAddExtraBenefitValue,
} from "../../../actions/roaCarInsurance/details";
import {getPremiumCalculation} from '@om/redux/selectors/roaCarInsurance/product';

export const extraBenefitService = (store,next,action) => {
	const result = next(action);
	const state = store.getState();
	(async () => {
		if(!getIsApiPending(state)) {
			try {
				const resp = await extraBenefit(state);
                store.dispatch(setAddExtraBenefitValue(resp.data));
			} catch (e) {
				store.dispatch(setAddExtraBenefitValid(e));
			}
		}
	})()
	return result;
};

const extraBenefit = async (state) => {
	const benefit = getExtraBenefitIdValue(state);
	const vehicleValuation = getVehicleValuation(state);
    const premiumCalculation = getPremiumCalculation(state)
	const payload = {
		  ...benefit,
          basic_premium: premiumCalculation.comprehensive_basic_premium,
		  training_levy: premiumCalculation.comprehensive_training_levy,
		  phcf: premiumCalculation.comprehensive_phcf,
		  stamp_duty: premiumCalculation.comprehensive_stamp_duty,
		  session_id: vehicleValuation.value.session_id
	}
	try {
		const url = '/om-api/roa-car-insurance/calculate-extra-benefit'
		return await axios.post(url, payload);
	} catch (e) {
		console.error(e)
		throw e
	}
}
