// Transitions
export const FORM_VALID_TRANSITION_INVESTMENT_SETUP = 'raOptimal/investmentSetup/transition/formInvestmentSetupValid';
export const FORM_INVALID_TRANSITION_INVESTMENT_SETUP = 'raOptimal/investmentSetup/transition/formInvestmentSetupInvalid';

export const transitionInvestmentSetup = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_INVESTMENT_SETUP }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_INVESTMENT_SETUP }),
};

export const INVALID_CONTINUE_TRANSITION_INVESTMENT_SETUP = 'raOptimal/investmentSetup/transition/invalidContinue';
export const CONTINUE_TRANSITION_INVESTMENT_SETUP = 'raOptimal/investmentSetup/transition/validContinue';
export const BACK_TRANSITION_INVESTMENT_SETUP = 'raOptimal/investmentSetup/transition/back';
export const invalidContinueInvestmentSetup = () => ({ type: INVALID_CONTINUE_TRANSITION_INVESTMENT_SETUP });
export const continueInvestmentSetup = () => ({ type: CONTINUE_TRANSITION_INVESTMENT_SETUP });
export const backInvestmentSetup = () => ({ type: BACK_TRANSITION_INVESTMENT_SETUP });

// Events - form fields
export const INVESTMENT_SETUP_SET_VALUE = 'raOptimal/investmentSetup/setValue/';
export const INVESTMENT_SETUP_SET_VALID = 'raOptimal/investmentSetup/setValid/';
export const INVESTMENT_SETUP_SET_OPTIONS = 'raOptimal/investmentSetup/setOptions/';
export const INVESTMENT_SETUP_RESET = 'raOptimal/investmentSetup/reset/';
export const INVESTMENT_SETUP_CLEAR = 'raOptimal/investmentSetup/clear/';

export const INVESTMENT_SETUP_FORM_SET_VALUE = INVESTMENT_SETUP_SET_VALUE + 'form'

export const AGE_SET_VALUE = INVESTMENT_SETUP_SET_VALUE + 'age';
export const AGE_SET_VALID = INVESTMENT_SETUP_SET_VALID + 'age';

export const RETIRE_AT_SET_VALUE = INVESTMENT_SETUP_SET_VALUE + 'retireAt';
export const RETIRE_AT_SET_OPTIONS = INVESTMENT_SETUP_SET_OPTIONS + 'options';
export const RETIRE_AT_SET_VALID = INVESTMENT_SETUP_SET_VALID + 'retireAt';

export const MONTHLY_INVESTMENT_SET_VALUE = INVESTMENT_SETUP_SET_VALUE + 'monthlyInvestment';
export const MONTHLY_INVESTMENT_DERIVED_TERM_SET_VALUE = INVESTMENT_SETUP_SET_VALUE + 'monthlyInvestmentDerivedTerm';
export const MONTHLY_INVESTMENT_COMMITTED_TERM_SET_VALUE = INVESTMENT_SETUP_SET_VALUE + 'monthlyInvestmentCommittedTerm';
export const MONTHLY_INVESTMENT_SET_VALID = INVESTMENT_SETUP_SET_VALID + 'monthlyInvestment';
export const MONTHLY_INVESTMENT_RESET = INVESTMENT_SETUP_RESET + 'monthlyInvestment';

export const OWN_PERCENTAGE_RESET_VALUE = INVESTMENT_SETUP_RESET + 'ownPercentage';
export const OWN_PERCENTAGE_SET_VALUE = INVESTMENT_SETUP_SET_VALUE + 'ownPercentage';
export const OWN_PERCENTAGE_SET_VALID = INVESTMENT_SETUP_SET_VALID + 'ownPercentage';

export const setAgeValue = value => ({ type: AGE_SET_VALUE, value });
export const setAgeValid = error => ({ type: AGE_SET_VALID, error });

export const setRetireAtValue = value => ({ type: RETIRE_AT_SET_VALUE, value });
export const setRetireAtOptions = options => ({ type: RETIRE_AT_SET_OPTIONS, options });
export const setRetireAtValid = error => ({ type: RETIRE_AT_SET_VALID, error });

export const setMonthlyInvestmentValue = value => ({ type: MONTHLY_INVESTMENT_SET_VALUE, value });
export const setMonthlyInvestmentDerivedTerm = term => ({ type: MONTHLY_INVESTMENT_DERIVED_TERM_SET_VALUE, term });
export const setMonthlyInvestmentCommittedTerm = term => ({ type: MONTHLY_INVESTMENT_COMMITTED_TERM_SET_VALUE, term });
export const setMonthlyInvestmentValid = error => ({ type: MONTHLY_INVESTMENT_SET_VALID, error });
export const resetMonthlyInvestment = () => ({ type: MONTHLY_INVESTMENT_RESET });

export const resetOwnPercentageValue = () => ({ type: OWN_PERCENTAGE_RESET_VALUE });
export const setOwnPercentageValue = value => ({ type: OWN_PERCENTAGE_SET_VALUE, value });
export const setOwnPercentageValid = error => ({ type: OWN_PERCENTAGE_SET_VALID, error });

export const setInvestmentSetupFormValues = () => ({ type: INVESTMENT_SETUP_FORM_SET_VALUE });

// Events - radio button selections
export const INVESTMENT_SETUP_SELECT = 'raOptimal/investmentSetup/select/';

export const RESET_PREMIUM_ESCALATION_SELECT = INVESTMENT_SETUP_RESET + 'resetSelection';
export const CLEAR_PREMIUM_ESCALATION_SELECT = INVESTMENT_SETUP_RESET + 'clearSelection';
export const LINK_WITH_INFLATION_SELECT = INVESTMENT_SETUP_SELECT + 'linkWithInflation';
export const PREMIUM_ESCALATION_VALID = INVESTMENT_SETUP_SET_VALID + 'premiumEscalation';
export const CHOOSE_OWN_PERCENTAGE_SELECT = INVESTMENT_SETUP_SELECT + 'chooseOwnPercentage';
export const NO_INCREASE_SELECT = INVESTMENT_SETUP_SELECT + 'noIncrease';

export const COMFORTABLE_INVESTOR_SELECT = INVESTMENT_SETUP_SELECT + 'comfortableInvestor';
export const ADVENTUROUS_INVESTOR_SELECT = INVESTMENT_SETUP_SELECT + 'adventurousInvestor';
export const INVESTOR_TYPE_SET_VALID = INVESTMENT_SETUP_SET_VALID + 'investorType';
export const CLEAR_INVESTOR_TYPE = INVESTMENT_SETUP_CLEAR + 'investorType';
export const RESET_INVESTOR_TYPE = INVESTMENT_SETUP_RESET + 'investorType';

export const clearPremiumEscalationSelect = () => ({ type: CLEAR_PREMIUM_ESCALATION_SELECT });
export const resetPremiumEscalationSelect = () => ({ type: RESET_PREMIUM_ESCALATION_SELECT });
export const selectLinkWithInflation = () => ({ type: LINK_WITH_INFLATION_SELECT });
export const setPremiumEscalationValid = error => ({ type: PREMIUM_ESCALATION_VALID, error });
export const selectChooseOwnPercentage = () => ({ type: CHOOSE_OWN_PERCENTAGE_SELECT });
export const selectNoIncrease = () => ({ type: NO_INCREASE_SELECT });

export const selectComfortableInvestor = () => ({ type: COMFORTABLE_INVESTOR_SELECT });
export const selectAdventurousInvestor = () => ({ type: ADVENTUROUS_INVESTOR_SELECT });
export const setInvestorTypeValid = error => ({ type: INVESTOR_TYPE_SET_VALID, error });
export const resetInvestorType = () => ({ type: RESET_INVESTOR_TYPE });
export const clearInvestorType = () => ({ type: CLEAR_INVESTOR_TYPE });

// Events - Tracking
export const INVESTMENT_SETUP_TRACKING = 'raOptimal/investmentSetup/tracking/'
export const FACT_SHEET_CLICKED = INVESTMENT_SETUP_TRACKING + 'factSheetClicked';
export const setFactSheetClicked = value => ({ type: FACT_SHEET_CLICKED, value });

// Events - Customer Verification
export const SET_IS_CVS_VERIFIED = 'raOptimal/investmentSetup/customerVerification/verifyCustomer';
export const setIsCvsVerified = () => ({ type: SET_IS_CVS_VERIFIED });

export const RESET_INVESTMENT_SETUP = INVESTMENT_SETUP_SET_VALUE + 'resetState';
export const resetInvestmentSetup = () => ({type: RESET_INVESTMENT_SETUP});