// Transition Event
const ADVANCED_FUND_PICKER_TRANSITION = 'taxFreeSavingsAccount/advancedFundPicker/transition/';

export const DESELECT_ALL_FUNDS_TRANSITION_ADVANCED_FUND_PICKER = ADVANCED_FUND_PICKER_TRANSITION + 'deselectAllFunds';
export const deselectAllFunds = () => ({ type: DESELECT_ALL_FUNDS_TRANSITION_ADVANCED_FUND_PICKER });

// Events - form input
export const ADVANCED_FUND_PICKER_SET_VALUE = 'taxFreeSavingsAccount/advancedFundPicker/setValue/';
export const ADVANCED_FUND_PICKER_SET_VALID = 'taxFreeSavingsAccount/advancedFundPicker/setValid/';
export const ADVANCED_FUND_PICKER_TOGGLE = 'taxFreeSavingsAccount/advancedFundPicker/toggle/';

// Search bar
export const TEMP_SEARCH_QUERY_SET_VALUE = ADVANCED_FUND_PICKER_SET_VALUE + 'tempSearchQuery'
export const setTempSearchQuery = value => ({ type: TEMP_SEARCH_QUERY_SET_VALUE, value });
export const SELECTED_SEARCH_QUERY_SET_VALUE = ADVANCED_FUND_PICKER_SET_VALUE + 'selectedSearchQuery'
export const setSelectedSearchQuery = value => ({ type: SELECTED_SEARCH_QUERY_SET_VALUE, value });
export const SEARCH_QUERY_SET_SHOW_SEARCH_RESULTS = ADVANCED_FUND_PICKER_SET_VALUE + 'showSearchResults'
export const setShowSearchResults = value => ({ type: SEARCH_QUERY_SET_SHOW_SEARCH_RESULTS, value });
export const SELECTION_REMOVE = ADVANCED_FUND_PICKER_SET_VALUE + 'removeSelection';
export const removeSelection = value => ({ type: SELECTION_REMOVE, value });
export const SHOW_TEXT_SEARCH_BAR_TOGGLE = ADVANCED_FUND_PICKER_TOGGLE + 'showTextSearchBar';
export const toggleShowTextSearchBar = () => ({ type: SHOW_TEXT_SEARCH_BAR_TOGGLE });

export const FUNDS_TOGGLE_SHOW_MORE = ADVANCED_FUND_PICKER_TOGGLE + 'showMoreFunds'
export const toggleShowMoreFunds = () => ({ type: FUNDS_TOGGLE_SHOW_MORE });

export const FUND_REQUEST_TOGGLE_SELECTION = ADVANCED_FUND_PICKER_TOGGLE + 'requestFundSelection'
export const requestToggleFundSelection = (id, value, fees) => ({ type: FUND_REQUEST_TOGGLE_SELECTION, id, value, fees });
export const FUND_TOGGLE_SELECTION = ADVANCED_FUND_PICKER_TOGGLE + 'fundSelection'
export const toggleFundSelection = (id, value, fees) => ({ type: FUND_TOGGLE_SELECTION, id, value, fees });
export const MAX_FUNDS_MODAL_TOGGLE_SELECTION = ADVANCED_FUND_PICKER_TOGGLE + 'maxFundsModal'
export const toggleMaxFundsModal = (value) => ({ type: MAX_FUNDS_MODAL_TOGGLE_SELECTION, value });

export const SELECTED_FUNDS_SET_VALID = ADVANCED_FUND_PICKER_SET_VALID + 'fundSelection'
export const selectedFundsetValid = error => ({ type: SELECTED_FUNDS_SET_VALID, error });

// Filter options drawer
export const FILTER_OPTIONS_TOGGLE_DRAWER = ADVANCED_FUND_PICKER_TOGGLE + 'filterOptionsDrawer';
export const toggleFilterOptionsDrawer = value => ({ type: FILTER_OPTIONS_TOGGLE_DRAWER, value });
export const FILTER_OPTIONS_SET_FILTER_OPTIONS = ADVANCED_FUND_PICKER_SET_VALUE + 'filterOptions';
export const setFilterOptions = value => ({ type: FILTER_OPTIONS_SET_FILTER_OPTIONS, value });
export const FILTER_OPTIONS_TOGGLE_OPTION = ADVANCED_FUND_PICKER_TOGGLE + 'filterOption';
export const toggleFilterOption = (id, value) => ({ type: FILTER_OPTIONS_TOGGLE_OPTION, id, value });

export const ADVANCED_FUND_SET_IS_SELECTED = ADVANCED_FUND_PICKER_SET_VALUE + 'advancedFundIsSelected';
export const setAdvancedFundIsSelected = isSelected => ({ type: ADVANCED_FUND_SET_IS_SELECTED, isSelected });

export const RESET_ADVANCED_FUND_PICKER = ADVANCED_FUND_PICKER_SET_VALUE + 'resetState';
export const resetAdvancedFundPicker = () => ({ type: RESET_ADVANCED_FUND_PICKER });