import { PAGE_INDICES } from '../../../actions/allsureInsurance/types';
import {
    navigateToInsuranceType,
    navigateToHomeDetails,
    navigateToMotorDetails,
    navigateToPersonalDetails,
    navigateToQuoteSummary,
    //   navigateToThankYou,
    NAVIGATE_NEXT,
    NAVIGATE_PREVIOUS,
    navigateToCallMeBack,
    navigateToDocumentInfo,
    navigateToAdditionalDetails,
    navigateToPurchaseJourneyPersonalDetails,
    navigateToBankingDetails,
    navigateToUploadDocuments,
    navigateToConfirmation,
    navigateToSuccessPage,
    navigateToHomeContents,
} from '../../../actions/allsureInsurance/routing';

// import { sendConfirmDetailsSubmitApplication } from "../../../actions/easiplusFuneralPlan/confirmDetails";

// import { performPersonalDetailsValidation } from "./personalDetails";
// import {
//   performLivesCoveredValidation,
//   livesCoveredUpdatePersonalValueRules,
// } from "./livesCovered";
// import { determineBeneficiaryNavigation } from "./beneficiaries";
// import { performBeneficiariesSplitValidation } from "./beneficiariesSplit";
// import { performPaymentDetailsValidation } from "./paymentDetails";
// import { performConfirmDetailsValidation } from "./confirmDetails";

import { getActivePageIndex } from '../../../selectors/allsureInsurance/routing';
import { getIsInsurenceTypeSelected } from '../../../selectors/allsureInsurance/insuranceType';

// import { getReplacementPolicyFormIsValid } from "../../../selectors/easiplusFuneralPlan/replacementPolicy";
// import { getPersonalDetailsValid } from "../../../selectors/easiplusFuneralPlan/personalDetails";
// import {
//   getLivesCoveredFormComplete,
//   getCoverMembersForms,
// } from "../../../selectors/easiplusFuneralPlan/livesCovered";
// import { getFormStatusIsValid as getBeneficiariesSplitIsValid } from "../../../selectors/easiplusFuneralPlan/beneficiariesSplit";
// import { getPaymentDetailsValid } from "../../../selectors/easiplusFuneralPlan/paymentDetails";
// import {
//   getConfirmDetailsValid,
//   getSkipConfirmationPage,
// } from "../../../selectors/easiplusFuneralPlan/confirmDetails";

export const routingRules = (store, next, action) => {
    if (action.type !== NAVIGATE_NEXT && action.type !== NAVIGATE_PREVIOUS) {
        return next(action);
    }

    const result = next(action);
    const state = store.getState();
    const activePageIndex = getActivePageIndex(state);

    if (action.type === NAVIGATE_NEXT) {
        onNavigateNext(store, state, activePageIndex);
        return result;
    }

    onNavigatePrevious(store, state, activePageIndex);

    return result;
};
const sample = [
    {
        condtion: PAGE_INDICES.INSURANCE_TYPE,
        action: navigateToHomeDetails(),
    },
    {
        condtion: PAGE_INDICES.HOME_DETAILS,
        action: navigateToMotorDetails(),
    },
]; 
const onNavigateNext = (store, state, activePageIndex) => {
    const isMotorInsuranceSelected = getIsInsurenceTypeSelected('motor')(state);
    const isHomeContentSelected =  getIsInsurenceTypeSelected('homeContent')(state);
    const isHomeOwnersSelected =  getIsInsurenceTypeSelected('homeOwners')(state);
    
    const navigations = [
        isHomeOwnersSelected? navigateToHomeDetails:navigateToHomeContents,
        isHomeContentSelected? navigateToHomeContents:navigateToPersonalDetails,
        isMotorInsuranceSelected? navigateToMotorDetails:navigateToPersonalDetails,
        navigateToPersonalDetails,
        navigateToQuoteSummary,
        navigateToCallMeBack,
        navigateToDocumentInfo,
        navigateToPurchaseJourneyPersonalDetails,
        navigateToAdditionalDetails,
        navigateToBankingDetails,
        navigateToUploadDocuments,
        navigateToConfirmation,
        navigateToSuccessPage,
    ];

    if (isMotorInsuranceSelected) {
        navigations.splice(isHomeContentSelected ? 2 : 1, 1, navigateToMotorDetails);
    }
    const allsurePages = { ...navigations };
    store.dispatch(allsurePages[activePageIndex]());
    // switch (activePageIndex) {
    //     case PAGE_INDICES.INSURANCE_TYPE:
    //         store.dispatch(navigateToHomeDetails());
    //         break;

    //     case PAGE_INDICES.HOME_DETAILS:
    //       store.dispatch(isMotorInsuranceSelected?navigateToMotorDetails():navigateToPersonalDetails());
    //         break;

    //     case PAGE_INDICES.MOTOR_DETAILS:
    //           store.dispatch(navigateToPersonalDetails());
    //           break;

    //     case PAGE_INDICES.PERSONAL_DETAILS:
    //         store.dispatch(navigateToQuoteSummary());
    //         break;
    //     case PAGE_INDICES.QUOTE_SUMMARY:
    //         store.dispatch(navigateToThankYou());
    //         break;

    //     case PAGE_INDICES.THANK_YOU:
    //         break;
    // }
};

const onNavigatePrevious = (store, state, activePageIndex) => {
    const isMotorInsuranceSelected = getIsInsurenceTypeSelected('motor')(state);
    const isHomeContentSelected =  getIsInsurenceTypeSelected('homeContent')(state);
    const isHomeOwnersSelected =  getIsInsurenceTypeSelected('homeOwners')(state);
    const navigations = [
        '',
        navigateToInsuranceType,
        isHomeOwnersSelected ? navigateToHomeDetails:navigateToInsuranceType,
        isHomeContentSelected? navigateToHomeContents : navigateToHomeDetails,
        navigateToMotorDetails,
        navigateToPersonalDetails,
        navigateToQuoteSummary,
        navigateToQuoteSummary,
        navigateToDocumentInfo,
        navigateToPurchaseJourneyPersonalDetails,
        navigateToAdditionalDetails,
        navigateToBankingDetails,
        navigateToUploadDocuments,
        navigateToConfirmation
    ];
    if (!isMotorInsuranceSelected) {
        navigations.splice(4, 1,isHomeContentSelected ? navigateToHomeContents : navigateToHomeDetails );
    }
    const allsurePages = { ...navigations };
    store.dispatch(allsurePages[activePageIndex]());
};

// const performLivesCoveredNavigation = (store, state) => {
//   const livesCoveredForm = getCoverMembersForms(state);
//   if ("myself" in livesCoveredForm) {
//     livesCoveredUpdatePersonalValueRules(store);
//   }

//   store.dispatch(navigateToLivesCovered());
// };

// const performConfirmDetailsValidation = (store) => {
//   store.dispatch(validateDeclarations());
//   store.dispatch(transitionScrolling.scrollToId("declaration"));
// };
