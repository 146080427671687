import { 
    getReplacementPolicyValid, 
    getTermsAndConditionsValid, 
    getReviewValid,
} from "../../../selectors/raOptimal/review";
import {FORM_STATUS} from "../../../reducers/status";
import {
    transitionReview,
    setReplacementPolicyInvalid,
    setTermsAndConditionsInvalid,
} from"../../../actions/raOptimal/review";
import { transitionScrolling } from "../../../actions/raOptimal/scrolling";

export const reviewRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    const replacementPolicyValid = getReplacementPolicyValid(state);
    const TCsValid = getTermsAndConditionsValid(state);

    const reviewValid = replacementPolicyValid && TCsValid;
    store.dispatch(reviewValid
        ? transitionReview.formValid()
        : transitionReview.formInvalid());

    return result;
};

export const validateReviewForm = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const replacementPolicyValid = getReplacementPolicyValid(state);
    const TCsValid = getTermsAndConditionsValid(state);

    if (!replacementPolicyValid) {
        store.dispatch(setReplacementPolicyInvalid(FORM_STATUS.INVALID));
        store.dispatch(transitionScrolling.scrollToId('replacement-policy'));
    }

    if (!TCsValid) {
        store.dispatch(setTermsAndConditionsInvalid(FORM_STATUS.INVALID));
        store.dispatch(transitionScrolling.scrollToId('terms-and-conditions'));
    }

    return result;
};
