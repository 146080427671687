export const COVER_CATEGORIES = {
  DIRECT_FAMILY: {
    key: "DIRECT_FAMILY",
    description: "Me & My Direct Family",
    code: "Me and My Direct Family",
  },
  PARENTS_AND_IN_LAWS: {
    key: "PARENTS_AND_IN_LAWS",
    description: "My Parents & In-Laws",
    code: "My Parents and In-laws",
  },
  EXTENDED_FAMILY: {
    key: "EXTENDED_FAMILY",
    description: "My Extended Family",
    code: "My Extended Family",
  },
};

export const COVER_CATEGORY_MEMBERS = {
  MYSELF: {
    key: "MYSELF",
    description: "Myself",
    code: "myself",
    category: COVER_CATEGORIES.DIRECT_FAMILY.key,
    label: "Myself",
    displayCoverAmount: true,
    canRemove: false,
  },
  SPOUSE: {
    key: "SPOUSE",
    description: "Spouse or Partner",
    code: "spouse",
    category: COVER_CATEGORIES.DIRECT_FAMILY.key,
    label: "Spouse or partner",
    displayCoverAmount: false,
    canRemove: true,
  },
  CHILD_1: {
    key: "CHILD_1",
    description: "Child (Optional)",
    code: "child_1",
    category: COVER_CATEGORIES.DIRECT_FAMILY.key,
    label: "Child",
    displayCoverAmount: false,
    canRemove: true,
  },

  MOTHER: {
    key: "MOTHER",
    description: "Mother",
    code: "mother",
    category: COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key,
    label: "Mother",
    displayCoverAmount: true,
    canRemove: false,
  },
  MOTHER_IN_LAW: {
    key: "MOTHER_IN_LAW",
    description: "Mother-in-law",
    code: "motherInLaw",
    category: COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key,
    label: "Mother-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },
  FATHER: {
    key: "FATHER",
    description: "Father",
    code: "father",
    category: COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key,
    label: "Father",
    displayCoverAmount: true,
    canRemove: false,
  },
  FATHER_IN_LAW: {
    key: "FATHER_IN_LAW",
    description: "Father-in-law",
    code: "fatherInLaw",
    category: COVER_CATEGORIES.PARENTS_AND_IN_LAWS.key,
    label: "Father-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },

  AUNT: {
    key: "AUNT",
    description: "Aunt",
    code: "aunt",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Aunt",
    displayCoverAmount: true,
    canRemove: false,
  },
  UNCLE: {
    key: "UNCLE",
    description: "Uncle",
    code: "uncle",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Uncle",
    displayCoverAmount: true,
    canRemove: false,
  },
  BROTHER: {
    key: "BROTHER",
    description: "Brother",
    code: "brother",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Brother",
    displayCoverAmount: true,
    canRemove: false,
  },
  BROTHER_IN_LAW: {
    key: "BROTHER_IN_LAW",
    description: "Brother-in-law",
    code: "brotherInLaw",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Brother-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },
  SISTER: {
    key: "SISTER",
    description: "Sister",
    code: "sister",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Sister",
    displayCoverAmount: true,
    canRemove: false,
  },
  SISTER_IN_LAW: {
    key: "SISTER_IN_LAW",
    description: "Sister-in-law",
    code: "sisterInLaw",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Sister-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },
  DAUGHTER_IN_LAW: {
    key: "DAUGHTER_IN_LAW",
    description: "Daughter-in-law",
    code: "daughterInLaw",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Daughter-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },
  SON_IN_LAW: {
    key: "SON_IN_LAW",
    description: "Son-in-law",
    code: "sonInLaw",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Son-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },
  COUSIN: {
    key: "COUSIN",
    description: "Cousin",
    code: "cousin",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Cousin",
    displayCoverAmount: true,
    canRemove: false,
  },
  NIECE: {
    key: "NIECE",
    description: "Niece",
    code: "niece",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Niece",
    displayCoverAmount: true,
    canRemove: false,
  },
  NEPHEW: {
    key: "NEPHEW",
    description: "Nephew",
    code: "nephew",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Nephew",
    displayCoverAmount: true,
    canRemove: false,
  },
  GRANDCHILD: {
    key: "GRANDCHILD",
    description: "Grandchild",
    code: "grandchild",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Grandchild",
    displayCoverAmount: true,
    canRemove: false,
  },
  GRANDPARENT: {
    key: "GRANDPARENT",
    description: "Grandparent",
    code: "grandparent",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Grandparent",
    displayCoverAmount: true,
    canRemove: false,
  },
  PARENT_IN_LAW: {
    key: "PARENT_IN_LAW",
    description: "Parent-in-law",
    code: "parentInLaw",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Parent-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },
  GRANDPARENT_IN_LAW: {
    key: "GRANDPARENT_IN_LAW",
    description: "Grandparent-in-law",
    code: "grandparentInLaw",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Grandparent-in-law",
    displayCoverAmount: true,
    canRemove: false,
  },
  SON: {
    key: "SON",
    description: "Son",
    code: "son",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Son",
    displayCoverAmount: true,
    canRemove: false,
  },
  DAUGHTER: {
    key: "DAUGHTER",
    description: "Daughter",
    code: "daughter",
    category: COVER_CATEGORIES.EXTENDED_FAMILY.key,
    label: "Daughter",
    displayCoverAmount: true,
    canRemove: false,
  },
};

export const EFP_REFERENCE_RELATIONSHIPS = [
  "Brother",
  "Brother-in-law",
  "Cousin",
  "Daughter",
  "Daughter-in-law",
  "Father",
  "Father-in-law",
  "Grandchild",
  "Grandparent",
  "Child",
  "Mother",
  "Mother-in-law",
  "Nephew",
  "Niece",
  "Sister",
  "Sister-in-law",
  "Son",
  "Son-in-law",
  "Spouse",
  "Uncle",
  "Friend",
  "Parent-in-law",
  "Step Parent",
  "Grandparent-in-law",
  "Aunt",
];

export const MALE = "MALE";
export const FEMALE = "FEMALE";
export const MALE_TITLES = [{ code: "Mr", description: "Mr", displayOrder: 1 }];
export const FEMALE_TITLES = [
  { code: "Mrs", description: "Mrs", displayOrder: 2 },
  { code: "Miss", description: "Miss", displayOrder: 3 },
];
export const ALL_TITLES = [...MALE_TITLES, ...FEMALE_TITLES];
export const EFP_RELATIONSHIP_GENDERS = {
  aunt: FEMALE,
  brother: MALE,
  brotherInLaw: MALE,
  child: null,
  cousin: null,
  daughter: FEMALE,
  daughterInLaw: FEMALE,
  father: MALE,
  fatherInLaw: MALE,
  friend: null,
  grandchildren: null,
  grandparent: null,
  me: null,
  mother: FEMALE,
  motherInLaw: FEMALE,
  nephew: MALE,
  niece: FEMALE,
  sister: FEMALE,
  sisterInLaw: FEMALE,
  son: MALE,
  sonInLaw: MALE,
  uncle: MALE,
  spouse: null,
  parentInLaw: null,
  stepParent: null,
  grandparentInLaw: null,
};
export const EFP_RELATIONSHIP_GENDERS_ALTERNATE_KEYS = Object.fromEntries(
  Object.entries(EFP_RELATIONSHIP_GENDERS).map(([key, val]) => [
    key.toLowerCase(),
    val,
  ])
);

export const EFP_POPUP_MODALS = {
  INCOMPLETE_MODAL: "INCOMPLETE_MODAL",
  MAX_CHILDREN: "MAX_CHILDREN",
  REMOVE_CONFIRMATION: "REMOVE_CONFIRMATION",
};

export const EFP_COVER_CATEGORIES = {
  DIRECT_FAMILY: "Me and My Direct Family",
  PARENTS_AND_IN_LAWS: "My Parents and In-laws",
  EXTENDED_FAMILY: "My Extended Family",
};

export const EFP_ADD_COVER_DRAWERS = {
  LIVES_COVERED: "All Cover Options",
  DIRECT_FAMILY: "Me And My Direct Family",
  PARENTS_AND_IN_LAWS: "My Parents and In-laws",
  EXTENDED_FAMILY: "My Extended Family",
};

export const EFP_MEMBER_AGE_RANGE = {
  EXTENDED_FAMILY: { min: 1, max: 84 },
  PARENTS_AND_IN_LAWS: { min: 1, max: 84 },
  DEFAULT: { min: 18, max: 65 },
};

export const EFP_QUOTE_DIRECT_FAMILY_SCROLL_ID = [
  "age",
  "addChild",
  "addPartner",
];

export const ADDRESS_TYPES = {
  PRIVATE_BAG: "PrivateBag",
  PO_BOX: "POBox",
  STREET_ADDRESS: "StreetAddress",
};

export const PRODUCT_DETAIL_URL = "/personal/digilife-loan-protection";
export const DLP_APP_URL_PREFIX = "/digilife-loan-protection";
export const APP_NOT_INITIATED = "APP_NOT_INITIATED";

export const DEFAULT_COUNTRY = {
  ZA_CODE: "SouthAfrica",
  ZA_DESCRIPTION: "South Africa",
  ZA_ID_TYPE_CODE: "SouthAfricanIdentity",
};

export const OPEN_DRAWER = {
  CALL_ME_BACK: "callMeBack",
};

export const DLP_PAGES = [
  {
    URL: `${DLP_APP_URL_PREFIX}/replacement-policy/`,
    URL_PART: "replacement-policy",
    TITLE: "Replacement Policy",
  },
  {
    URL: `${DLP_APP_URL_PREFIX}/personal-details/`,
    URL_PART: "personal-details",
    TITLE: "Personal Details",
  },
  {
    URL: `${DLP_APP_URL_PREFIX}/payment-details/`,
    URL_PART: "payment-details",
    TITLE: "Payment Details",
  },
  {
    URL: `${DLP_APP_URL_PREFIX}/documents/`,
    URL_PART: "documents",
    TITLE: "Upload Documents",
  },
  {
    URL: `${DLP_APP_URL_PREFIX}/confirmation/`,
    URL_PART: "confirmation",
    TITLE: "Confirmation",
  },
  {
    URL: `${DLP_APP_URL_PREFIX}/thank-you/`,
    URL_PART: "thank-you",
    TITLE: "Thank You",
  },
];

export const DLP_PAGE_URLS = {
  QUOTE: PRODUCT_DETAIL_URL,
  REPLACEMENT_POLICY: DLP_PAGES[0].URL,
  PERSONAL_DETAILS: DLP_PAGES[1].URL,
  PAYMENT_DETAILS: DLP_PAGES[2].URL,
  UPLOAD_DOCUMENTS: DLP_PAGES[3].URL,
  CONFIRMATION: DLP_PAGES[4].URL,
  THANK_YOU: DLP_PAGES[5].URL,
};

export const DLP_PAGE_INDICES = {
  REPLACEMENT_POLICY: 0,
  PERSONAL_DETAILS: 1,
  PAYMENT_DETAILS: 2,
  UPLOAD_DOCUMENTS: 3,
  CONFIRMATION: 4,
  THANK_YOU: 5,
};

export const DLP_PAGE_INDEX_URL = {
  ...DLP_PAGES.map((element) => element.URL),
  MIN_PAGE_INDEX: DLP_PAGE_URLS.REPLACEMENT_POLICY,
};

export const ACCOUNT_VALIDATION_STATUS = {
  IDLE: "IDLE",
  PENDING: "PENDING",
  ERROR: "ERROR",
  FAILED: "FAILED",
  PASSED: "PASSED",
};

const SIZE_2_MB = 2097152;
const SIZE_1_kb = 1024;


export const UPLOAD_PAGE_CONFIG = {

  MIN_INPUT_SIZE: SIZE_1_kb,
  MIN_INPUT_SIZE_NAME: '1 kb',
  MAX_INPUT_SIZE: SIZE_2_MB,
  MAX_INPUT_SIZE_NAME: '2 mb',
  VALID_UPLOAD_TYPES: [
      { FORMAT: 'image/jpeg', FORMAT_NAME: 'JPG' },
      {FORMAT: 'image/png', FORMAT_NAME: 'PNG'},
      { FORMAT: 'application/pdf', FORMAT_NAME: 'PDF' },
  ],
};
export const API_STATUS={
  SUCCESS:'Success',
  PENDING:'Pending',
  FAILED:'Failed'
}