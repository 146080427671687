import { PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE } from '../../../actions/personalPensionPlan/quote';
import { quoteFormValidation } from './quote';

import { PAYMENTS_SET_VALUE } from '../../../actions/personalPensionPlan/payments';
import { paymentsFormValidation } from './payments';

import { PERSONAL_PENSION_PLAN_AGENT_MODAL_DETAILS_SET_VALUE } from '../../../actions/personalPensionPlan/agentModal';
import { agentModalFormValidation } from './agentModal';

import { SET_SHARE_EMAIL_VALUE } from '../../../actions/personalPensionPlan/quote';
import { shareQuoteValidation } from './shareQuote';

import { PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE } from '../../../actions/personalPensionPlan/personalDetails';
import { personalDetailsValidation } from './personalDetails';

import { PERSONAL_PENSION_PLAN_BENEFICIARIES_FORM_SET_VALUE } from '../../../actions/personalPensionPlan/beneficiaries';
import { beneficiaryValidation } from './beneficiaries';

import { PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE } from '../../../actions/personalPensionPlan/documents';
import { documentsUploadValidation } from './documents';

import { PERSONAL_PENSION_PLAN_NEXT_OF_KIN_FORM_SET_VALUE } from '../../../actions/personalPensionPlan/nextOfKin';
import { nextOfKinValidation } from './nextOfKin';

export const personalPensionPlanValidation = (store, next, action) => {
    if (action.type.startsWith(PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE)) {
        return quoteFormValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_PENSION_PLAN_PERSONAL_DETAILS_FORM_SET_VALUE)) {
        return personalDetailsValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_PENSION_PLAN_DOCUMENTS_FORM_SET_VALUE)) {
        return documentsUploadValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_PENSION_PLAN_BENEFICIARIES_FORM_SET_VALUE)) {
        return beneficiaryValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_PENSION_PLAN_NEXT_OF_KIN_FORM_SET_VALUE)) {
        return nextOfKinValidation(store, next, action);
    }

    if (action.type.startsWith(SET_SHARE_EMAIL_VALUE)) {
        return shareQuoteValidation(store, next, action);
    }

    if (action.type.startsWith(PAYMENTS_SET_VALUE)) {
        return paymentsFormValidation(store, next, action);
    }

    if (action.type.startsWith(PERSONAL_PENSION_PLAN_AGENT_MODAL_DETAILS_SET_VALUE)) {
        return agentModalFormValidation(store, next, action);
    }

    return null;
};
