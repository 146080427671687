import {
    transitionUnclaimedBenefits,
} from "../../../actions/publicWeb/unclaimedBenefits";
import {
    getUnclaimedBenefitsForm,
} from "../../../selectors/publicWeb/unclaimedBenefits";
import {FIELD_STATUS} from "../../../reducers/status";

export const unclaimedBenefitsFormRules = (store, next, action) => {
    const result = next(action);

    const unclaimedBenefitsForm = getUnclaimedBenefitsForm(store.getState());
    const unclaimedBenefitsValid = validateUnclaimedBenefitsFormDetails(unclaimedBenefitsForm);
    const formAction = unclaimedBenefitsValid
        ? transitionUnclaimedBenefits.formValid()
        : transitionUnclaimedBenefits.formInvalid()

    store.dispatch(formAction);

    return result;
};

const validateUnclaimedBenefitsFormDetails = formFields => (
    formFields.initials && formFields.initials.status === FIELD_STATUS.VALID &&
    formFields.surname && formFields.surname.status === FIELD_STATUS.VALID &&
    formFields.dob && formFields.dob.status === FIELD_STATUS.VALID
);
