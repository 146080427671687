export const PREMIER_SAVINGS_QUOTE_TOOL = 'roaQuoteTools/premierSavingsQuoteTool/';
export const PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL + 'setValue/';
export const PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_PREMIER_SAVINGS_QUOTE_TOOL = 'roaQuoteTools/premierSavingsQuoteTool/transition/formStateValid';
export const FORM_INVALID_PREMIER_SAVINGS_QUOTE_TOOL = 'roaQuoteTools/premierSavingsQuoteTool/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/premierSavingsQuoteTool/state/reinitialize';

export const transitionPremierSavingsTool = {
    formValid: () => ({ type: FORM_VALID_PREMIER_SAVINGS_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_PREMIER_SAVINGS_QUOTE_TOOL }),
};

export const CANDIDATE_DOB_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'candidateDOB';
export const CANDIDATE_DOB_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'candidateDOB';
export const TERM_INPUT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'termInputValue';
export const TERM_INPUT_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'termInputValue';
export const MONTHLY_PREMIUM_AMOUNT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'monthlyPremiumAmount';
export const MONTHLY_PREMIUM_AMOUNT_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'monthlyPremiumAmount';
export const ENABLE_LUMPSUM_INPUT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'enableLumpsumInput';
export const ENABLE_LUMPSUM_INPUT_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'enableLumpsumInput';
export const SHORT_TERM_INPUT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'shortTermInputValue';
export const SHORT_TERM_INPUT_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'shortTermInputValue';
export const LONG_TERM_INPUT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'longTermInputValue';
export const LONG_TERM_INPUT_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'longTermInputValue';
export const LUMPSUM_AMOUNT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'lumsumAmount';
export const LUMPSUM_AMOUNT_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'lumsumAmount';
export const LUMPSUM_YEAR_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'lumsumYear';
export const LUMPSUM_YEAR_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'lumsumYear';
export const LUMPSUM_MONTH_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'lumsumMonth';
export const LUMPSUM_MONTH_SET_VALID = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'lumsumMonth';
export const NEW_LUMPSUM_PAYMENT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'newLumpsumPayment';
export const DELETE_LUMPSUM_PAYMENT_SET_VALUE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALID + 'deleteLumpsumPayment';
export const WHICH_FORM_TO_DISPLAY = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const COLLECT_PREMIER_SAVINGS_DATA = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'collectPremierSavingsData';
export const CALCULATE_PREMIER_SAVINGS_DATA = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_PREMIER_SAVINGS_DATA_FAILURE = PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL = `${PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL = `${PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL = `${PREMIER_SAVINGS_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionPremierSavingsQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_PREMIER_SAVINGS_QUOTE_TOOL }),
};

export const collectPremierSavingsData = () => ({ type: COLLECT_PREMIER_SAVINGS_DATA });
export const calculatePremierSavingsData = (value) => ({ type: CALCULATE_PREMIER_SAVINGS_DATA, value});
export const collectPremierSavingsDataFailure = error => ({ type: CALCULATE_PREMIER_SAVINGS_DATA_FAILURE, error });

export const setCandidateDOBValue = value => ({ type: CANDIDATE_DOB_SET_VALUE, value });
export const setCandidateDOBValid = error => ({ type: CANDIDATE_DOB_SET_VALID, error });
export const setTermInputValue = value => ({ type: TERM_INPUT_SET_VALUE, value });
export const setTermInputValid = error => ({ type: TERM_INPUT_SET_VALID, error });
export const setEnableLumpsumInputValue = value => ({ type: ENABLE_LUMPSUM_INPUT_SET_VALUE, value });
export const setEnableLumpsumInputValid = error => ({ type: ENABLE_LUMPSUM_INPUT_SET_VALID, error });
export const setMonthlyPremiumAmountValue = value => ({ type: MONTHLY_PREMIUM_AMOUNT_SET_VALUE, value });
export const setMonthlyPremiumAmountValid = error => ({ type: MONTHLY_PREMIUM_AMOUNT_SET_VALID, error });
export const setShortTermInputValue = value => ({ type: SHORT_TERM_INPUT_SET_VALUE, value });
export const setShortTermInputValid = error => ({ type: SHORT_TERM_INPUT_SET_VALID, error });
export const setLongTermInputValue = value => ({ type: LONG_TERM_INPUT_SET_VALUE, value });
export const setLongTermInputValid = error => ({ type: LONG_TERM_INPUT_SET_VALID, error });
export const setLumpsumAmountValue = (value, paymentId) => ({ type: LUMPSUM_AMOUNT_SET_VALUE, value, paymentId });
export const setLumpsumAmountValid = (error, paymentId) => ({ type: LUMPSUM_AMOUNT_SET_VALID, error, paymentId });
export const setLumpsumYearValue = (value, paymentId) => ({ type: LUMPSUM_YEAR_SET_VALUE, value, paymentId });
export const setLumpsumYearValid = (error, paymentId) => ({ type: LUMPSUM_YEAR_SET_VALID, error, paymentId });
export const setLumpsumMonthValue = (value, paymentId) => ({ type: LUMPSUM_MONTH_SET_VALUE, value, paymentId });
export const setLumpsumMonthValid = (error, paymentId) => ({ type: LUMPSUM_MONTH_SET_VALID, error, paymentId });
export const setNewLumpsumPaymentDetail = value => ({ type: NEW_LUMPSUM_PAYMENT_SET_VALUE, value });
export const deleteLumpsumPaymentDetail = value => ({ type: DELETE_LUMPSUM_PAYMENT_SET_VALUE, value });
export const setWhichFormToDisplay = value => ({ type: WHICH_FORM_TO_DISPLAY, value });
export const setToPremierSavingsQuoteInitial = () => ({ type: REINITIALIZE });
