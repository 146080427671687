export const RW_MOTOR_INSURANCE_CAR_DETAILS_FORM = 'motorPrivateRwanda/carDetailsForm/';

export const MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE = RW_MOTOR_INSURANCE_CAR_DETAILS_FORM + 'setValue/';
export const MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID = RW_MOTOR_INSURANCE_CAR_DETAILS_FORM + 'setValid/';

export const FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM = 'motorPrivateRwanda/carDetailsForm/transition/formValid';
export const FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM = 'motorPrivateRwanda/carDetailsForm/transition/formInValid';

export const REINITIALIZE = 'motorPrivateRwanda/carDetailsForm/state/reinitialize';

// Dropoff
export const DROP_OFF_LEAD_TRANSITION_CAR_DETAILS_FORM = 'motorPrivateRwanda/carDetailsForm/transition/dropOffLead';
export const dropOffLeadCarDetails = (value) => ({
    type: DROP_OFF_LEAD_TRANSITION_CAR_DETAILS_FORM,
    value,
});

export const transitionMotorInsuranceCarDetailsForm = {
    formValid: () => ({
        type: FORM_VALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MOTOR_INSURANCE_CAR_DETAILS_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_CAR_DETAILS_FORM = `${MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_CAR_DETAILS_FORM = `${MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_CAR_DETAILS_FORM = `${MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE}/apiTransition/failure`;

export const apiTransitioncarDetailsForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_CAR_DETAILS_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_CAR_DETAILS_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_CAR_DETAILS_FORM,
    }),
};

//calculated Data
export const COLLECT_CAR_DETAILS_FORM_DATA = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'collectCarDetailsData';
export const SUBMIT_CAR_DETAILS_SUCCESS = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'success';
export const SUBMIT_CAR_DETAILS_FAILURE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'failure';

export const collectCarDetailsData = () => ({
    type: COLLECT_CAR_DETAILS_FORM_DATA,
});
export const submitCarDetailsDataSuccess = (value) => ({
    type: SUBMIT_CAR_DETAILS_SUCCESS,
    value,
});
export const submitCarDetailsFailure = (error) => ({
    type: SUBMIT_CAR_DETAILS_FAILURE,
    error,
});

// Form Values
export const REG_NUMBER_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'registrationNumber';
export const REG_NUMBER_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'registrationNumber';

export const CHASIS_NUMBER_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'chasisNumber';
export const CHASIS_NUMBER_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'chasisNumber';

export const SELECTED_FORM_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'selectedForm';

export const TIN_NUMBER_SET_VALUE = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALUE + 'tinNumber';
export const TIN_NUMBER_SET_VALID = MOTOR_INSURANCE_CAR_DETAILS_FORM_SET_VALID + 'tinNumber';

// action creators
export const setRegistrationNoValue = (value) => ({
    type: REG_NUMBER_SET_VALUE,
    value,
});

export const setRegistrationNoValid = (error) => ({
    type: REG_NUMBER_SET_VALID,
    error,
});

export const setChasisNoValue = (value) => ({
    type: CHASIS_NUMBER_SET_VALUE,
    value,
});

export const setChasisNoValid = (error) => ({
    type: CHASIS_NUMBER_SET_VALID,
    error,
});

export const setTinNumberValue = (value) => ({
    type: TIN_NUMBER_SET_VALUE,
    value,
});
export const setTinNumberValid = (error) => ({
    type: TIN_NUMBER_SET_VALID,
    error,
});

export const setSelectedFormValue = (value) => ({
    type: SELECTED_FORM_SET_VALUE,
    value,
});


export const reinitializeCarDetailsForm = () => ({
    type: REINITIALIZE,
});
