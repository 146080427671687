export const MEDICAL_HISTORY_FORM = 'medicalHistory/form/';

export const MEDICAL_HISTORY_FORM_SET_VALUE = MEDICAL_HISTORY_FORM + 'setValue';
export const MEDICAL_HISTORY_FORM_SET_VAVID = MEDICAL_HISTORY_FORM + 'setValid';

export const FORM_VALID_MEDICAL_HISTORY_FORM = 'medicalHistory/form/transition/formValid';
export const FORM_INVALID_MEDICAL_HISTORY_FORM = 'medicalHistory/form/transition/formInValid';

export const REINITIALIZE = 'medicalHistory/form/state/reinitialize';

export const transitionMedicalHistoryDetails = {
    formValid: () => ({
        type: FORM_VALID_MEDICAL_HISTORY_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_MEDICAL_HISTORY_FORM,
    }),
};

export const ID_PASSPORT_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "idPassport";
export const ID_PASSPORT_SET_VALID = MEDICAL_HISTORY_FORM_SET_VAVID + "idPassport";

export const NAMES_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "names";
export const NAMES_SET_VALID = MEDICAL_HISTORY_FORM_SET_VAVID + "names";

export const RELATIONSHIP_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "relationship";
export const RELATIONSHIP_SET_VALID = MEDICAL_HISTORY_FORM_SET_VAVID + "relationship";


export const OTHER_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "other";
export const OTHER_SET_VALID = MEDICAL_HISTORY_FORM_SET_VAVID + "other";

export const MEDICAL_HISTORY_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "medicalHistory";
export const MEDICAL_HISTORY_ARRAY_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "medicalHistoryArray";
export const MEDICAL_HISTORY_EDIT_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "editForm";
export const MEDICAL_HISTORY_NEW_SET_VALUE = MEDICAL_HISTORY_FORM_SET_VALUE + "editForm";


export const setEditNewForm = (value) => ({
    type: MEDICAL_HISTORY_NEW_SET_VALUE,
    value,
})

export const setEditForm = (value) => ({
    type: MEDICAL_HISTORY_EDIT_SET_VALUE,
    value,
})

export const setIdPassportValue = (value, index) => ({
    type: ID_PASSPORT_SET_VALUE,
    value,
    index
})

export const setIdPassportValid = (error, index) => ({
    type: ID_PASSPORT_SET_VALID,
    error,
    index
})

export const setNamesValue = (value, index) => ({
    type: NAMES_SET_VALUE,
    value,
    index
});

export const setNamesValid = (error, index) => ({
    type: NAMES_SET_VALID,
    error,
    index
})

export const setOtherValue = (value, index) => ({
    type: OTHER_SET_VALUE,
    value,
    index
});

export const setOtherValid = (error, index) => ({
    type: OTHER_SET_VALID,
    error,
    index
})


export const setRelationshipValue = (value, index)=> ({
    type: RELATIONSHIP_SET_VALUE,
    value,
    index
});

export const setRelationshipValid = (error, index) => ({
    type: RELATIONSHIP_SET_VALID,
    error,
    index
})

export const setMedicalHistoryValue = value => ({
    type: MEDICAL_HISTORY_SET_VALUE,
    value,
});

export const setMedicalHistoryArrayValue = value => ({
    type: MEDICAL_HISTORY_ARRAY_SET_VALUE,
    value,
});

export const reinitializeForm = () => ({
    type: REINITIALIZE,
})