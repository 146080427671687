import { createSelector } from 'reselect';
import { EMPLOYMENT_STATUS, EMPLOYMENT_CONTRACT_END, EMPLOYMENT_START_DATE } from '../../actions/digitalOnboarding/types';
import { getDigitalOnboarding } from './index';

export const getEmploymentStoreValues = createSelector(
    getDigitalOnboarding,
    digitalOnboarding => digitalOnboarding.employmentDetails.storeValues
);

export const isContractEmployment = createSelector(
    getEmploymentStoreValues,
    employment => employment[EMPLOYMENT_STATUS].value === 'Contract'
);

export const isPermanentEmployment = createSelector(
    getEmploymentStoreValues,
    employment => employment[EMPLOYMENT_STATUS].value === 'Permanent'
);

export const contractEndDate = createSelector(
    getEmploymentStoreValues,
    employment => employment[EMPLOYMENT_CONTRACT_END].value
);

export const contractEndDateIsPristine = createSelector(
    getEmploymentStoreValues,
    employment => employment[EMPLOYMENT_CONTRACT_END].pristine
);

export const employmentStartDateIsPristine = createSelector(
    getEmploymentStoreValues,
    employment => employment[EMPLOYMENT_START_DATE].pristine
);

export const employmentStartDate = createSelector(
    getEmploymentStoreValues,
    employment => employment[EMPLOYMENT_START_DATE].value
);
