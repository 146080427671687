import { createSelector } from 'reselect';
import { getRoaCarInsuranceState } from './index';
import { FORM_STATUS } from '../../reducers/status';
import { APICallSelector } from '../roaQuoteTools/api';

export const getQuotation = createSelector(
    getRoaCarInsuranceState,
    (state) => state.quotation
);

export const getSummaryFormStatus = createSelector(
    getQuotation,
    (state) => state.summaryFormStatus
);

export const getApiCallStatus = createSelector(
	getQuotation,
	(state) => state.apiCallStatus
);

export const getAgentReferralStatus = createSelector(
    getQuotation,
    (state) => state.agentReferralStatus
);

export const getQuotationForm = createSelector(
    getQuotation,
    (state) => state.form
);

export const getQuotationFormIsValid = createSelector(
    getQuotation,
    (state) => state.formStatus === FORM_STATUS.VALID
);

// for Nigeria
export const getFormStatus = createSelector(
	getQuotation,
	(state) => state.formStatusNg === FORM_STATUS.VALID
);

export const getCarMakes = createSelector(
	getQuotation,
	(state) => state.carMakes
);

export const getCarModels = createSelector(
	getQuotation,
	(state) => state.carModels
);

export const getVehicleValuation = createSelector(
	getQuotation,
	state => state.vehicleValuation
);

export const getRevaluationValue = createSelector(
	getQuotation,
	state => state.revaluationValue
);

export const getPremiumNgCalculation = createSelector(
	getQuotation,
	state => state.premiumCalculationNg
);

export const getSelectedModels = createSelector(
	getQuotation,
	state => state.selectedModels
);

// Form field selectors
const makeGetField = (fieldId) =>
    createSelector(getQuotationForm, (quotationForm) => quotationForm[fieldId]);

export const getAgeOfInsured = makeGetField('age');
export const getCarMakeAndModel = makeGetField('makeAndModel');
export const getNumberOfAccidents = makeGetField('noOfAccidents');
export const getYearOfManufacturer = makeGetField('yearOfManufacture');
export const getVarianceValue = makeGetField('varianceValue');

//for Nigeria
export const getVehicleMake = makeGetField('vehicleMake');
export const getVehicleModel = makeGetField('vehicleModel');
export const getVehicleType = makeGetField('vehicleType');
export const getEstimatedValue = makeGetField('estimatedValue');
export const getVehicleUse = makeGetField('vehicleUse');
export const manufacturerYear = makeGetField('manufactureYear');

// API Calls Selectors
export const getShowError = APICallSelector.getErrorStatus(getQuotation);
export const getIsApiPending = APICallSelector.getPendingStatus(getQuotation);
