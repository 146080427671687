import {transitionSolutions} from "../../../actions/atRetirement/solutions";
import {getSolutions} from "../../../selectors/atRetirement/solutions";
import {transitionWizardFooter} from "../../../actions/atRetirement/wizardFooter";
import {FIELD_STATUS} from "../../../reducers/status";

export const solutionsRules = (store, next,action) => {
    const result = next(action);
    const solutionsForm = getSolutions(store.getState());
    const formIsValid = solutionsForm.formStatus === FIELD_STATUS.VALID;

    if (formIsValid) {
        store.dispatch(transitionSolutions.formValid());
        store.dispatch(transitionWizardFooter.solutionsContinueButtonDisabled(false));
    } else {
        store.dispatch(transitionSolutions.formInvalid());
        store.dispatch(transitionWizardFooter.solutionsContinueButtonDisabled(true));
    }
    
    return result;
};
