import { validationIsEqual } from '../status';
import {
    validateDateOfBirth,
    validateName,
    validateContactNumber,
    validateAlphaNumericStringAllowingOnlyNumbers,
    validateKraPinNumber,
    validateNumericString,
} from '@om/validation';
import {
    FIRST_NAME_SET_VALUE,
    LAST_NAME_SET_VALUE,
    DOB_SET_VALUE,
    ID_NUMBER_SET_VALUE,
    TIN_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALUE,
    STREET_ADDRESS_SET_VALUE,
    CITY_SET_VALUE,
    SUBURB_SET_VALUE,
    POSTAL_ADDRESS_SET_VALUE,
    setIdNumberValid,
    setPostalAddressValid,
    setFirstNameValid,
    setLastNameValid,
    setPhoneNumberValid,
    setDOBValid,
    setTinNumberValid,
    setCityValid,
    setSuburbValid,
    setStreetAddressValid,
} from '../../../actions/personalAccident/spouseDetails';
import { validateKePassport } from '@om/validation/src/validators/ke-passport-validator';

let prevValidationAction = null;
export const spouseDetailsValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case FIRST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 48,
                    subject: 'First Name',
                });
                return setFirstNameValid(error);

            case LAST_NAME_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Last Name',
                });
                return setLastNameValid(error);

            case DOB_SET_VALUE:
                error = validateDateOfBirth(action.value, { min: 18, max: 65 });
                return setDOBValid(error);

            case PHONE_NUMBER_SET_VALUE:
                error = validateContactNumber(action.value, 'Phone Number');
                return setPhoneNumberValid(error);

            case ID_NUMBER_SET_VALUE:
                error = validateKePassport(action.value);

                if (error !== null) {
                    error = validateNumericString(action.value, { min: 8, max: 15 });
                }

                return setIdNumberValid(error);

            case TIN_NUMBER_SET_VALUE:
                error = validateKraPinNumber(action.value);
                return setTinNumberValid(error);

            case STREET_ADDRESS_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Street Address',
                });
                return setStreetAddressValid(error);

            case CITY_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'City',
                });
                return setCityValid(error);

            case SUBURB_SET_VALUE:
                error = validateName(action.value, {
                    min: 2,
                    max: 64,
                    subject: 'Suburb',
                });
                return setSuburbValid(error);

            case POSTAL_ADDRESS_SET_VALUE:
                error = validateAlphaNumericStringAllowingOnlyNumbers(action.value);
                return setPostalAddressValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
