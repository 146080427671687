import axios from 'axios';
import {
    getIsCountriesReferenceDataValid,
} from '../../../selectors/roaTravelInsurance/referenceData';
import {
    setCountriesReferenceData,
    ROA_COUNTRIES_REFERENCE_DATA_GET_VALUE,
} from '../../../actions/roaTravelInsurance/referenceData';

export const countriesReferenceDataService = (store, next, action) => {
    const result = next(action);

    if (action.type === ROA_COUNTRIES_REFERENCE_DATA_GET_VALUE) {
        // If countries ref data already exists, dont make new call
        if (getIsCountriesReferenceDataValid(store.getState())) return;
        (async () => {
            try {
                const response = await fetchCountriesReferenceData();
                const countries = response?.data?.data?.destination_countries;
                store.dispatch(setCountriesReferenceData(countries));
            } catch (e) {
                throw e;
            }
        })();
    }
    return result;
};

export const fetchCountriesReferenceData = async () => {
    try {
        const url = '/om-api/roa-travel-insurance/countries-reference-data';
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const result = await axios.post(url, {}, config);
        return result;
    } catch (error) {
        throw error;
    }
};
