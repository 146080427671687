import {createSelector} from "reselect";
import {getRoaCarInsuranceState} from "./index";
import {FORM_STATUS, API_CALL_STATUS} from "../../reducers/status";

export const getPaymentsDetails = createSelector(getRoaCarInsuranceState, rcia => rcia.payments);

export const getPaymentsForm = createSelector(
    getPaymentsDetails,
    paymentsDetails => paymentsDetails.form
);

export const getApiCallStatus = createSelector(
    getPaymentsDetails,
    paymentsDetails => paymentsDetails.apiCallPaymentStatus
)

export const getApiCallPaymentStatus = createSelector(
    getPaymentsDetails,
    paymentsDetails => paymentsDetails.apiCallStatus
)
const makeGetFieldForm = fieldId => createSelector(
    getPaymentsForm,
    form => form[fieldId]
);

export const getPaymentsFormValid = createSelector(
    getPaymentsForm,
    paymentsDetails => paymentsDetails.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getPaymentsDetails,
    getPaymentsDetails => getPaymentsDetails.apiCallStatus === API_CALL_STATUS.PENDING,
);

export const getIsApiPaymentStatusPending = createSelector(
    getPaymentsDetails,
    getPaymentsDetails => getPaymentsDetails.apiCallPaymentStatus === API_CALL_STATUS.PENDING,
);
export const getPhoneNumber = makeGetFieldForm('phoneNumber');
export const getEmail = makeGetFieldForm('email');

export const getIsApiPaymentStatusSuccess = createSelector(
    getPaymentsDetails,
    (getPaymentsDetails) =>
        getPaymentsDetails.apiCallPaymentStatus === API_CALL_STATUS.SUCCESS
);

export const getIsApiPaymentStatusFailure = createSelector(
    getPaymentsDetails,
    (getPaymentsDetails) =>
        getPaymentsDetails.apiCallPaymentStatus === API_CALL_STATUS.FAILURE
);

export const getManualPaymentsResponse = createSelector(
    getPaymentsDetails,
    paymentsDetails => paymentsDetails.manualMpesaPaymentsResponse
)


// manual payments API status
export const getIsManualPaymentsApiPending = createSelector(
    getPaymentsDetails,
    paymentsDetails => paymentsDetails.manualPaymentsApiStatus === API_CALL_STATUS.PENDING
)

export const getIsManualPaymentsStatusApiSuccess = createSelector(
    getPaymentsDetails,
    paymentsDetails => paymentsDetails.manualPaymentsApiStatus === API_CALL_STATUS.SUCCESS
)

export const getIsManualPaymentsStatusApiFailure = createSelector(
    getPaymentsDetails,
    paymentsDetails =>  paymentsDetails.manualPaymentsApiStatus === API_CALL_STATUS.FAILURE
)

export const getMpesaCodeValue = makeGetFieldForm('transactionCode')
