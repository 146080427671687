import { 
    TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW,
    TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW,
    EDIT_PAGE_REVIEW_TRACKING,
    INVALID_CONTINUE_TRANSITION_REVIEW,
    REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE,
    TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE
} from "../../../actions/raOptimal/review";
import { 
    getReplacementPolicyValue,
    getTermsAndConditionsValue,
    getEditPageReviewTracking,
    getReview,
    getReplacementPolicySideDrawerOpen,
    getTermsAndConditionsSideDrawerOpen
} from "../../../selectors/raOptimal/review";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";
import {onItemChangeStateComparison} from "../onFieldChangeStateComparison"

export const reviewOtherTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW: 
                otherEventIndex = findOtherEventIndex("TOGGLE_CHECKBOX");
                return toggleButtonTransitionTrackingHandler(prevState, state, getReplacementPolicyValue)
            case TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW:
                otherEventIndex = findOtherEventIndex("TOGGLE_CHECKBOX");
                return toggleButtonTransitionTrackingHandler(prevState, state, getTermsAndConditionsValue)
            case EDIT_PAGE_REVIEW_TRACKING:
                otherEventIndex = findOtherEventIndex("EDIT_PAGE");
                return getEditPageReviewTracking(state)
            case REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE :
                otherEventIndex = findOtherEventIndex("OPEN_DRAWER");
                return getReplacementPolicySideDrawerOpen(state) ? "replacementPolicySideDrawer" : null
            case TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE:
                otherEventIndex = findOtherEventIndex("OPEN_DRAWER");
                return getTermsAndConditionsSideDrawerOpen(state) ? "termsAndConditionsSideDrawer" : null
            case INVALID_CONTINUE_TRANSITION_REVIEW:
                otherEventIndex = findOtherEventIndex("INVALID_CONTINUE_TRANSITION_REVIEW");
                return {
                    formStatus: getReview(state).formStatus,
                    replacementPolicy: getReview(state).replacementPolicy,
                    termsAndConditions: getReview(state).termsAndConditions,
                }
            default: return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};

const toggleButtonTransitionTrackingHandler = (prevState, state, selector) => {
    const onFieldChange = onItemChangeStateComparison(prevState, state);
    const field = onFieldChange(selector);
    return field === true ? selector(state) : false;
};
