import { createSelector } from 'reselect';
import { getUgMotorInsuranceState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getPersonalDetails = createSelector(getUgMotorInsuranceState, (state) => state.personalDetails);

export const getPersonalDetailsForm = createSelector(getPersonalDetails, (personalDetails) => personalDetails.form);

export const getSelectedForm = createSelector(getPersonalDetails, (personalDetails) => personalDetails.selectedForm);

export const getBlacklisted = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails && personalDetails.blacklisted
);

export const getDropOffDetails = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.dropOffDetails
);

export const getPersonalDetailsFormValid = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.formStatus === FORM_STATUS.VALID
);

export const getIsApiPending = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.formStatus === API_CALL_STATUS.PENDING
);

// Nationalities
export const getIsNationalityApiPending = createSelector(
    getPersonalDetails,
    (personalDetails) => personalDetails.natiolalityApiCallStatus === API_CALL_STATUS.PENDING
);

export const getNationalities = createSelector(getPersonalDetails, (personalDetails) => personalDetails.nationalities);

const makeGetFieldForm = (fieldId) => createSelector(getPersonalDetailsForm, (form) => form[fieldId]);

export const getDOBValue = makeGetFieldForm('dob');
export const getGenderValue = makeGetFieldForm('gender');
export const getFirstNameValue = makeGetFieldForm('firstName');
export const getMiddleNameValue = makeGetFieldForm('middleName');
export const getLastNameValue = makeGetFieldForm('lastName');
export const getPhoneNumberValue = makeGetFieldForm('phoneNumber');
export const getEmailValue = makeGetFieldForm('email');
export const getIdNumberValue = makeGetFieldForm('idNumber');
export const getTinNumberValue = makeGetFieldForm('tinNumber');
export const getPostalAddressValue = makeGetFieldForm('postalAddress');
export const getOccupationValue = makeGetFieldForm('occupation');
export const getNationalityValue = makeGetFieldForm('nationality');
export const getMaritalStatusValue = makeGetFieldForm('maritalStatus');
export const getResidentialStatusValue = makeGetFieldForm('residentialStatus');
export const getTownOrCityValue = makeGetFieldForm('townOrCity');
export const getAddressValue = makeGetFieldForm('address');
export const getIdCardNumberValue = makeGetFieldForm('idCardNumber');
