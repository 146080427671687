import {
  API_FAILURE_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER,
  API_PENDING_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER,
  API_SUCCESS_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER,
  ASISA_UNCLAIMED_BENEFITS_FORM_CLEAR,
  ASISA_UNCLAIMED_BENEFITS_FORM_INVALID_TRANSITION,
  ASISA_UNCLAIMED_BENEFITS_FORM_VALID_TRANSITION,
  DATE_OF_BIRTH_SET_VALID,
  DATE_OF_BIRTH_SET_VALUE,
  FIRSTNAME_SET_VALID,
  FIRSTNAME_SET_VALUE,
  GENDER_SET_VALID,
  GENDER_SET_VALUE,
  RECAPTCHA_VALUE,
  SA_ID_NUMBER_SET_VALID,
  SA_ID_NUMBER_SET_VALUE,
  SURNAME_SET_VALID,
  SURNAME_SET_VALUE,
  TOGGLE_ACCEPT_TERMS_AND_CONDITIONS,
  TOGGLE_HAS_VALID_SA_ID_NUMBER,
  TOGGLE_SIDE_DRAWER,
} from "../../actions/publicWeb/asisaUnclaimedBenefits";
import {
  API_CALL_STATUS,
  FIELD_STATUS,
  FORM_STATUS,
  TOGGLE_STATUS,
} from "../status";

export const initialFormState = {
  hasValidSaIdNumber: TOGGLE_STATUS.YES,
  hasAcceptedTermsAndConditions: TOGGLE_STATUS.UNTOUCHED,
  saIdNumber: { value: null, error: null, status: null },
  firstname: { value: null, error: null, status: null },
  surname: { value: null, error: null, status: null },
  dateOfBirth: { value: null, error: null, status: null },
  gender: { value: null, error: null, status: null },
  recaptcha: { value: null, error: null, status: null },
}
export const initAsisaUnclaimedBenefits = {
  formStatus: FORM_STATUS.INVALID,
  apiSubmitStatus: API_CALL_STATUS.IDLE,
  userFound: null,
  legalEntity: null,
  sideDrawerOpen: TOGGLE_STATUS.UNTOUCHED,
  form: initialFormState
};

export default (state = initAsisaUnclaimedBenefits, action) => {
  const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);

  const updateFormField = (fieldId, update) => ({
    ...state,
    form: {
      ...state.form,
      [fieldId]: update(state.form[fieldId]),
    },
  });

  const fieldValueUpdate = (field) => ({
    ...field,
    value: action.value,
  });

  const fieldValidUpdate = (field) => ({
    ...field,
    error: action.error,
    status: mapError(action.error),
  });

  const mapToggleStatus = (value) => {
    return value !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO;
  };

  switch (action.type) {
    case ASISA_UNCLAIMED_BENEFITS_FORM_VALID_TRANSITION:
      return {
        ...state,
        formStatus: FORM_STATUS.VALID,
      };
    case ASISA_UNCLAIMED_BENEFITS_FORM_INVALID_TRANSITION:
      return {
        ...state,
        formStatus: FORM_STATUS.INVALID,
      };

    case API_PENDING_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER:
      return {
        ...state,
        apiSubmitStatus: API_CALL_STATUS.PENDING,
      };
    case API_SUCCESS_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER:
      return {
        ...state,
        apiSubmitStatus: API_CALL_STATUS.SUCCESS,
        userFound: action.value.potentialMatch,
        legalEntity: action.value.legalEntity
      };
    case API_FAILURE_TRANSITION_GET_ASISA_UNCLAIMED_BENEFIT_DATA_FOR_MEMBER:
      return {
        ...state,
        apiSubmitStatus: API_CALL_STATUS.FAILURE,
      };

    case ASISA_UNCLAIMED_BENEFITS_FORM_CLEAR:
      return {
        ...state,
        formStatus: FORM_STATUS.INVALID,
        apiSubmitStatus: API_CALL_STATUS.IDLE,
        userFound: null,
        legalEntity: null,
        form: initialFormState
      }

    case TOGGLE_HAS_VALID_SA_ID_NUMBER:
      return {
        ...state,
        form: {
          ...state.form,
          hasValidSaIdNumber: mapToggleStatus(state.form.hasValidSaIdNumber),
          saIdNumber: { value: null, error: null, status: null }
        },
      };
    case TOGGLE_ACCEPT_TERMS_AND_CONDITIONS:
      return {
        ...state,
        form: {
          ...state.form,
          hasAcceptedTermsAndConditions: mapToggleStatus(
            state.form.hasAcceptedTermsAndConditions
          ),
        },
      };

    case TOGGLE_SIDE_DRAWER:
      return {
        ...state,
        sideDrawerOpen: mapToggleStatus(state.sideDrawerOpen)
      }

    case SA_ID_NUMBER_SET_VALUE:
      return updateFormField("saIdNumber", fieldValueUpdate);
    case SA_ID_NUMBER_SET_VALID:
      return updateFormField("saIdNumber", fieldValidUpdate);

    case FIRSTNAME_SET_VALUE:
      return updateFormField("firstname", fieldValueUpdate);
    case FIRSTNAME_SET_VALID:
      return updateFormField("firstname", fieldValidUpdate);

    case SURNAME_SET_VALUE:
      return updateFormField("surname", fieldValueUpdate);
    case SURNAME_SET_VALID:
      return updateFormField("surname", fieldValidUpdate);

    case DATE_OF_BIRTH_SET_VALUE:
      return updateFormField("dateOfBirth", fieldValueUpdate);
    case DATE_OF_BIRTH_SET_VALID:
      return updateFormField("dateOfBirth", fieldValidUpdate);

    case GENDER_SET_VALUE:
      return updateFormField("gender", fieldValueUpdate);
    case GENDER_SET_VALID:
      return updateFormField("gender", fieldValidUpdate);

    case RECAPTCHA_VALUE:
      return updateFormField("recaptcha", fieldValueUpdate);

    default:
      return state;
  }
};
