import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
import {
    AGENT_NAME_SET_VALUE,
    AGENT_NAME_SET_VALID,
    AGENT_CODE_SET_VALUE,
    AGENT_CODE_SET_VALID,
    MODAL_FORM_DISPLAY,
    REINITIALIZE,
    // FORM_VALID_MOTOR_INSURANCE_AGENT_MODAL_FORM,
    // FORM_INVALID_MOTOR_INSURANCE_AGENT_MODAL_FORM,
    SET_INTERMEDIARY_TYPE_VALUE,
    SET_INTERMEDIARY_TYPE_VALID,
    FORM_VALID_DIGITAL_SAVINGS_AGENT_MODAL_FORM,
    FORM_INVALID_DIGITAL_SAVINGS_AGENT_MODAL_FORM,
    SET_AGENTS_VALUE,
    API_PENDING_TRANSITION_AGENTS,
    API_SUCCESS_TRANSITION_AGENTS,
    API_FAILURE_TRANSITION_AGENTS,
} from '../../actions/digitalSavings/agentModal';
import { LOGIN_FAILURE, LOGIN_SUCCESS } from '../../actions/digitalSavings/auth';

export const initAgents = {
    displayModal: true,
    formStatus: FORM_STATUS.INVALID,
    loginData: null,
    agentsData: null,
    apiCallStatus: null,
    form: {
        name: { value: null, error: null, status: null },
        agentCode: { value: null, error: null, status: null },
        intermediaryType: { value: null, error: null, status: null },
    },
};

export default (state = initAgents, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_DIGITAL_SAVINGS_AGENT_MODAL_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_DIGITAL_SAVINGS_AGENT_MODAL_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case MODAL_FORM_DISPLAY:
            return { ...state, displayModal: action.value };

        // Form
        case AGENT_NAME_SET_VALUE:
            return updateFormField('name', (field) => ({
                ...field,
                value: action.value,
            }));

        case AGENT_NAME_SET_VALID:
            return updateFormField('name', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case AGENT_CODE_SET_VALUE:
            return updateFormField('agentCode', (field) => ({
                ...field,
                value: action.value,
            }));

        case AGENT_CODE_SET_VALID:
            return updateFormField('agentCode', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SET_INTERMEDIARY_TYPE_VALUE:
            return updateFormField('intermediaryType', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_INTERMEDIARY_TYPE_VALID:
            return updateFormField('intermediaryType', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case API_PENDING_TRANSITION_AGENTS:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };

        case API_SUCCESS_TRANSITION_AGENTS:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };

        case API_FAILURE_TRANSITION_AGENTS:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        case LOGIN_SUCCESS:
            return { ...state, loginData: action.data };

        case LOGIN_FAILURE:
            return { ...state, loginData: null };

        case SET_AGENTS_VALUE:
            return { ...state, agentsData: action.value };

        // Reset the state
        case REINITIALIZE:
            return initAgents;
        default:
            return state;
    }
};
