import {CLEAR_SCROLL, SCROLL_TO_CLASS, SCROLL_TO_ID} from '../../actions/debtRepaymentCalculators/scrolling';

export const initScrolling = {
    scrollToClass: null,
    scrollToId: null
};

export default (state = initScrolling, action) => {
    switch (action.type) {

    case SCROLL_TO_CLASS:
        return {scrollToClass: action.value};

    case SCROLL_TO_ID:
        return {scrollToId: action.value};

    case CLEAR_SCROLL:
        return {scrollToId: null};

    default:
        return state;
    }
}