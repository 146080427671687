import { getIsApiPending } from '../../../selectors/roaCarInsurance/product';
import {
    apiTransitionProduct,
    calculatePremiumSuccess,
} from '../../../actions/roaCarInsurance/product';
import axios from 'axios';
import {
    getQuotationForm,
    getVehicleValuation,
} from '../../../selectors/roaCarInsurance/quotation';
import { getAboutYouForm } from '../../../selectors/roaCarInsurance/aboutYou';

export const premiumCalculationService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionProduct.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculatePremiumSuccess(resp.data));
                store.dispatch(apiTransitionProduct.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionProduct.failure());
            }
        }
    })();
    return next(action);
};

const premiumCalculationPayload = (state) => {
    const quotation = getQuotationForm(state);
    const aboutYou = getAboutYouForm(state);
    const vehicleValuation = getVehicleValuation(state);
    return {
        age_range: quotation.age.value,
        no_of_claims: quotation.noOfAccidents.value,
        name: `${aboutYou.firstName.value} ${aboutYou.lastName.value}`,
        session_id: vehicleValuation.value.session_id,
    };
};

const calculate = async (state) => {
    try {
        const url = '/om-api/roa-car-insurance/premium-calculation';
        return await axios.post(url, premiumCalculationPayload(state));
    } catch (e) {
        console.error(e);
        throw e
    }
};
