import { FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    ID_UPLOAD_RESET_VALUE,
    ID_UPLOAD_SET_VALUE,
    ID_UPLOAD_SET_VALID,
    FORM_VALID_HEALTH_INSURANCE_DOCUMENTS_FORM,
    FORM_INVALID_HEALTH_INSURANCE_DOCUMENTS_FORM,
    MARRIAGE_CERTIFICATE_SET_VALUE,
    MARRIAGE_CERTIFICATE_SET_VALID,
	MARRIAGE_CERTIFICATE_RESET_VALUE,
	BIRTH_CERTIFICATE_RESET_VALUE,
	BIRTH_CERTIFICATE_SET_VALID,
	BIRTH_CERTIFICATE_SET_VALUE,
} from '../../actions/healthInsuranceRW/Documents';

export const initDocuments = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    personalDetailsReferenceData: null,
    apiCallStatus: null,
    form: {
        idUpload: { value: null, error: null, status: null },
        marriageCert: { value: null, error: null, status: null },
        birthCert: { value: null, error: null, status: null },
    },
};

export default (state = initDocuments, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_HEALTH_INSURANCE_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_HEALTH_INSURANCE_DOCUMENTS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // ID UPLOAD

        case ID_UPLOAD_SET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_UPLOAD_SET_VALID:
            return updateFormField('idUpload', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_UPLOAD_RESET_VALUE:
            return updateFormField('idUpload', (field) => ({
                ...field,
                value: null,
                status: null,
            }));

        case MARRIAGE_CERTIFICATE_SET_VALUE:
            return updateFormField('marriageCert', (field) => ({
                ...field,
                value: action.value,
            }));

        case MARRIAGE_CERTIFICATE_SET_VALID:
            return updateFormField('marriageCert', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MARRIAGE_CERTIFICATE_RESET_VALUE:
            return updateFormField('marriageCert', (field) => ({
                ...field,
                value: null,
                status: null,
            }));

        case BIRTH_CERTIFICATE_SET_VALUE:
            return updateFormField('birthCert', (field) => ({
                ...field,
                value: action.value,
            }));

        case BIRTH_CERTIFICATE_SET_VALID:
            return updateFormField('birthCert', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BIRTH_CERTIFICATE_RESET_VALUE:
            return updateFormField('birthCert', (field) => ({
                ...field,
                value: null,
                status: null,
            }));
        // Reset the state
        case REINITIALIZE:
            return initDocuments;
        default:
            return state;
    }
};
