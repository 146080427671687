import { 
    RETIREMENT_CALCULATOR_SHOW_RESULT_SUMMARY
} from "../../../actions/retirementCalculator/retirementCalculatorForm"
import { fireGtmEvent } from "../gtmEvents";

import {findOtherEventIndex, otherEventTracking} from '../otherEventTracking';;

export const retirementCalculatorFieldTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type){
            case RETIREMENT_CALCULATOR_SHOW_RESULT_SUMMARY: 
            otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            fireGtmEvent('calculator_complete', { calculator_type: 'Budget Benchmarking Tool' });
            return 'Retirement calculator';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};
