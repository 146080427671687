import { getTaxFreeSavingsAccount } from "./index";
import { createSelector } from "reselect";

export const getFundData = createSelector(getTaxFreeSavingsAccount, tfsa => tfsa.fundData);

export const getFundReferenceData = createSelector(
    getFundData,
    fundData => fundData.data,
);

// Validates the application fund data
export const getIsFundDataValid = createSelector(
    getFundReferenceData,
    fundReferenceData => {
        return fundReferenceData &&
            Object.keys(fundReferenceData).length !== 0 &&
            fundReferenceData.lowRisk && fundReferenceData.lowRisk.length > 0 &&
            fundReferenceData.mediumRisk && fundReferenceData.mediumRisk.length > 0 && 
            fundReferenceData.highRisk && fundReferenceData.highRisk.length > 0 &&
            fundReferenceData.defaultFunds && 
            fundReferenceData.defaultFunds.lowRisk && 
            fundReferenceData.defaultFunds.mediumRisk &&
            fundReferenceData.defaultFunds.highRisk;
    }
);
