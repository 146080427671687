import { getQuoteForm, getPremiumCalculation } from '../../../selectors/doctorsPi/quote';
import { getPersonalDetailsForm } from '../../../selectors/doctorsPi/personalDetails';
import { getCheckDocuments, getUploadDocumentsForm } from '../../../selectors/doctorsPi/documents';
import { getPaymentsForm } from '../../../selectors/doctorsPi/payments';
import { getIsApiPending } from '../../../selectors/doctorsPi/payloadCollection';
import {
    apiTransitionPayloadCollection,
    submitPayloadDataSuccess,
    submitPayloadDataFailure,
} from '../../../actions/doctorsPi/payloadCollection';
import { getAgentForm } from '../../../selectors/doctorsPi/agent';
import { getAccessToken, getPaymentStatusData } from '../../../selectors/doctorsPi/payments';
import axios from 'axios';
import { triggerConfirmOrder } from '../../../actions/doctorsPi/payments';

export const payloadSubmitService = (store, next, action) => {
    const paymentForm = getPaymentsForm(store.getState());
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionPayloadCollection.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(submitPayloadDataSuccess(resp.data));
                store.dispatch(apiTransitionPayloadCollection.success());
                if (paymentForm.networkProvider.value === 'mpesa') {
                    store.dispatch(triggerConfirmOrder());
                }
            } catch (e) {
                store.dispatch(submitPayloadDataFailure(e));
                store.dispatch(apiTransitionPayloadCollection.failure());
            }
        }
    })();
    return next(action);
};

const payloadCollection = (state) => {
    const quotation = getQuoteForm(state);
    const personalDetails = getPersonalDetailsForm(state);
    const documents = getUploadDocumentsForm(state);
    const statements = getCheckDocuments(state);
    const agent = getAgentForm(state);
    const premium = getPremiumCalculation(state);
    const paymentsData = getPaymentStatusData(state);
    const paymentForm = getPaymentsForm(state);

    const premiumCalculationPayload = {
        plans: [
            {
                coveredAmount: parseInt(quotation.limitIndemnity.value.split(',').join('')),
                premiumAmount: parseInt(premium.premium.split(',').join('')),
            },
        ],
    };

    const personalDetailsPayload = {
        firstName: personalDetails.firstName.value || 'NA',
        specialisation: quotation.specialty.value || 'NA',
        lastName: personalDetails.surname.value || 'NA',
        dateOfBirth: personalDetails.dob.value || 'NA',
        emailAddress: personalDetails.email.value || 'NA',
        phoneNumber: personalDetails.mobileNumber.value || 'NA',
        idNumber: personalDetails.idPassport.value || 'NA',
        kraPin: personalDetails.kraPinNumber.value || 'NA',
        streetAddress: personalDetails.streetAddress.value || 'NA',
        buildingAddress: personalDetails.building.value || 'NA',
        city: personalDetails.city.value || 'NA',
        postalAddress: personalDetails.postalBox.value || 'NA',
        postalCode: personalDetails.postalCode.value || 'NA',
        gender: 'NA',
    };

    let declarations = [
        'Are you presently in any medical association or society of good standing?',
        'Are you in partnership or association with another practitioner/s?',
        'Do you own in whole or part any clinic, hospital or sanatorium or similar enterprise?',
        'Has any claims been made against you or your partners during the last 5 years?',
        'Is there any incident within your knowledge which my subsequently give rise to a claim against you for professional negligence?',
        'Has any application for insurance ever been declined by insurer, accepted subject to premium increase or accepted subject to special restrictions?',
    ];

    function generateDeclarationText(declarations, statements) {
        let textList = [];
        let declarationKeys = Object.keys(declarations);

        for (let i = 0; i < declarationKeys.length; i++) {
            let declarationKey = declarationKeys[i];
            let declarationValue = declarations[declarationKey].value;
            let statement = statements[i];
            if (declarationValue) {
                textList.push(`Yes - ${statement}`);
            } else {
                textList.push(`No - ${statement}`);
            }
        }

        return textList.join(', ');
    }

    // Generate the text
    const joinedText = generateDeclarationText(statements, declarations);

    const documentsPayload = {
        idNumberDoc: documents.nationalId.value.base64File || 'NA',
        currentLicenseDoc: documents.currentLicense.value.base64File || 'NA',
        resumeDoc: documents.resume.value.base64File || 'NA',
        academicCertificateDoc: documents.academicCertificates.value.base64File || 'NA',
        kraPinDoc: documents.kraPin.value.base64File || 'NA',
        declaration: joinedText || 'NA',
    };

    const agentPayload = {
        countryCode: 'KE',
        reffererCode: agent.agentCode.value || 'NA',
        reffererType: agent.intermediaryType.value || 'NA',
    };

    const paymentMpesaPayload = {
        transactionId: paymentsData?.CheckoutRequestID || 'NA',
        paidAmount: parseInt(premium.premium.split(',').join('')) || 'NA',
        paymentChannel: 'MPESA',
    };

    const paymentBankPayload = {
        paymentChannel: 'BANK',
    };

    const paymentPayload = paymentForm.networkProvider.value === 'mpesa' ? paymentMpesaPayload : paymentBankPayload;

    return {
        ...premiumCalculationPayload,
        ...personalDetailsPayload,
        ...documentsPayload,
        ...agentPayload,
        ...paymentPayload,
    };
};

const calculate = async (state) => {
    console.log(payloadCollection(state));
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/doctors-pi/submit-payload/doctors-pi';
        return await axios.post(url, payloadCollection(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
            },
        });
    } catch (e) {
        console.log(e, 'Ping pong');
        throw e;
    }
};
