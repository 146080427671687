import {
    transitionRetirementCalculatorForm
} from '../../../actions/retirementCalculator/retirementCalculatorForm'

import {
    getRetiremntCalculatorForm
} from '../../../selectors/retirementCalculator/retirementCalculatorForm'
import {FIELD_STATUS} from "../../../reducers/status";

export const retirmentCalculatorFormRules = (store, next, action) => {
    const result = next(action);

    const retiremntCalculatorForm = getRetiremntCalculatorForm(store.getState());
    const retirmentCalculatorValid = validateFormDetails(retiremntCalculatorForm);
    const formAction = retirmentCalculatorValid
        ? transitionRetirementCalculatorForm.formValid()
        : transitionRetirementCalculatorForm.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFields.age && formFields.age.status === FIELD_STATUS.VALID &&
    formFields.monthlyIncome && formFields.monthlyIncome.status === FIELD_STATUS.VALID &&
    formFields.contributionAmount && formFields.contributionAmount.status === FIELD_STATUS.VALID &&
    formFields.amountSaved && formFields.amountSaved.status === FIELD_STATUS.VALID
);
