import {createSelector} from "reselect";
import {getEasiplusFuneralPlan} from "./index";
import {BENEFICIARY_DRAWER, FORM_STATUS} from "../../reducers/status";
import {getDirectFamilyCovered, getLivesCovered, getPopulatedCoverMembersForms} from "./livesCovered"
import {
    ALL_TITLES,
    COVER_CATEGORY_MEMBERS,
    EFP_REFERENCE_RELATIONSHIPS,
    EFP_RELATIONSHIP_GENDERS_ALTERNATE_KEYS,
    FEMALE,
    FEMALE_TITLES,
    MALE,
    MALE_TITLES
} from "../../actions/easiplusFuneralPlan/types";
import {getReferenceRelationshipTypes, getReferenceTitles} from "./referenceData";
import {calculateAge, initialsFormNameList, mapGenderDataEntry} from "../../functions/easiplusFuneralPlan/Util";
import {getSplitFieldArray} from "./beneficiariesSplit";

export const getBeneficiaries = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan && easiplusFuneralPlan.beneficiaries
);

// Drawers
export const getOpenDrawer = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries && beneficiaries.openDrawer,
);
export const getEditDrawerIsOpen = createSelector(
    getOpenDrawer,
    openDrawer => openDrawer === BENEFICIARY_DRAWER.EDIT,
);

// Edit beneficiary
export const getEditBeneficiary = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries && beneficiaries.editBeneficiary,
);
export const getEditBeneficiaryFormIsValid = createSelector(
    getEditBeneficiary,
    editBeneficiary => editBeneficiary && editBeneficiary.formStatus === FORM_STATUS.VALID,
);
export const getEditBeneficiaryForm = createSelector(
    getEditBeneficiary,
    editBeneficiary => editBeneficiary && editBeneficiary.form,
);

// Edit beneficiary form
const makeGetField = fieldId => createSelector(
    getEditBeneficiaryForm,
    editBeneficiaryForm => editBeneficiaryForm && editBeneficiaryForm[fieldId]
);
export const getSelectedExistingBeneficiary = makeGetField("selectedExistingBeneficiary");
export const getTitle = makeGetField('title');
export const getFirstName = makeGetField('firstName');
export const getSurname = makeGetField('surname');
export const getDateOfBirth = makeGetField('dateOfBirth');
export const getRelationship = makeGetField('relationship');
export const getMobileNumber = makeGetField('mobileNumber');
export const getEmail = makeGetField('email');

export const getBeneficiaryEditId = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries && beneficiaries.editId,
);

// Beneficiaries
export const getBeneficiaryList = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries && beneficiaries.beneficiaries,
);

export const getBeneficiariesCanContinue = createSelector(
    getPopulatedCoverMembersForms,
    getDirectFamilyCovered,
    (livesCovered, directFamily) => {
        const keys = Object.keys(livesCovered);
        const hasSpouseOrChild = keys.includes(COVER_CATEGORY_MEMBERS.SPOUSE.code) || keys.includes(COVER_CATEGORY_MEMBERS.CHILD_1.code);
        // Rule is if applicant has only applied to cover direct family, and has spouse or child, 
        //      they do not need to add a beneficiary and can therefore continue
        // If other cover categories are covered, or if a spouse or child is added, then they do not need to add bene
        return directFamily ? hasSpouseOrChild : true;
    }
)

export const getShowMustHaveBeneficiary = createSelector(
    getBeneficiaries,
    beneficiaries => beneficiaries && beneficiaries.showMustHaveBeneficiary,
);

export const getBeneficiaryListForConfirmDetails = createSelector(
    getBeneficiaryList,
    (beneficaries) => {
        if (!beneficaries) {
            return [];
        }

        return beneficaries.map(beneficiary => {
            const beneficiaryDetail = {};
            beneficiaryDetail["FullName"] = beneficiary.value.firstName.value + ' ' + beneficiary.value.surname.value;
            beneficiaryDetail["DateOfBirth"] = beneficiary.value.dateOfBirth.value;
            beneficiaryDetail["MobileNumber"] = beneficiary.value.mobileNumber.value;
            return beneficiaryDetail;
        });
    }
);

export const getLivesCoveredMembersBeneficiaries = createSelector(
    getLivesCovered,
    getBeneficiaryList,
    (livesCovered, beneficiaryList) => {
        const memberList = Object.keys(livesCovered.coverMembers);
        const defaultMember = {code: 'NONE', name: 'Do not add cover member', isDisabled: false};
        const memberArray = memberList.map(key => {
            const member = livesCovered.coverMembers[key];
            const existsInList = beneficiaryList.some(beneficiary => beneficiary.value.selectedExistingBeneficiary === key);
            if (member.firstName.value && key !== COVER_CATEGORY_MEMBERS.MYSELF.code) {
                return {
                    code: key,
                    name: member.firstName.value + ' ' + member.surname.value,
                    isDisabled: existsInList
                }
            } else return null;
        });
        const sortedMemberArray = memberArray.filter(member => member != null).sort((a, b) => a.name >= b.name ? 1: -1);
        sortedMemberArray.unshift(defaultMember);
        return sortedMemberArray;
    }
);

export const getRelationshipsBeneficiaries = () => {
    return EFP_REFERENCE_RELATIONSHIPS.sort();
};

export const getExistingCoveredMemberUsed = createSelector(
    getBeneficiaries,
    beneficiaries => {
        return beneficiaries.editBeneficiary.form.selectedExistingBeneficiary !== null
            && beneficiaries.editBeneficiary.form.selectedExistingBeneficiary !== 'NONE';
    }
);

export const getBeneficiaryRelationshipTitles = createSelector(
    getRelationship,
    getReferenceRelationshipTypes,
    (relationship, referenceRelationshipTypes) => {
        if (!relationship || !relationship.value) {
            return ALL_TITLES;
        }

        const {code} = referenceRelationshipTypes.find(ref => ref.description === relationship.value);
        const gender = EFP_RELATIONSHIP_GENDERS_ALTERNATE_KEYS[code.toLowerCase()];
        switch (gender) {
            case MALE: return MALE_TITLES;
            case FEMALE: return FEMALE_TITLES;
            default: return ALL_TITLES;
        }
    }
)

// Submission
export const getBeneficiariesSubmission = createSelector(
    getBeneficiaryList,
    getReferenceTitles,
    getReferenceRelationshipTypes,
    getSplitFieldArray,
    (beneficiaryList, referenceTitles, referenceRelationshipTypes, splitFieldArray) => {
        return beneficiaryList.map((beneficiary, index) => {
            const {title, firstName, surname, dateOfBirth, relationship, mobileNumber, email} = beneficiary.value;
            const benRelationshipType = referenceRelationshipTypes.find(ref => ref.description === relationship.value);
            const benTitle = referenceTitles.find(ref => ref.code === title.value);
            const age = calculateAge(dateOfBirth.value);
            const initials = initialsFormNameList([firstName, surname]);
            const genderValue = title.value === 'Mr' ? 'Male' : 'Female';
            const benGender = mapGenderDataEntry(genderValue);
            const benSharePercentage = splitFieldArray[index];

            return {
                initials,
                age: +age,
                cellphoneNumber: mobileNumber.value,
                email: email.value,
                relationshipType: benRelationshipType,
                firstName: firstName.value,
                surname: surname.value,
                dateOfBirth: dateOfBirth.value,
                sharePercentage: +benSharePercentage.value,
                title: benTitle,
                gender: benGender,
            };
        });
    }
);

export const getNBeneficiaries = createSelector(
    getBeneficiaryList,
    (beneficiaryList) => {
        return beneficiaryList && beneficiaryList.length;
    }
);

export const getAddedMaxBeneficiaries = createSelector(
    getNBeneficiaries,
    (nBeneficiaries) => {
        const MAX_BENEFICIARIES = 10;
        return nBeneficiaries === MAX_BENEFICIARIES && 'maxAdded';
    }
);

export const getConfirmRemoveBeneficiaryId = createSelector(
    getBeneficiaries, 
    beneficiaries => beneficiaries.confirmRemoveBeneficiaryId
);