export const CONSENT_TRANSITION = 'customerVerificationService/consentPage/transition/';

export const SERVICE_PROVIDER_TRANSITION_CONSENT = CONSENT_TRANSITION + 'serviceProvider';
export const FORM_VALID_TRANSITION_CONSENT = CONSENT_TRANSITION + 'formValid';
export const FORM_INVALID_TRANSITION_CONSENT = CONSENT_TRANSITION + 'formInvalid';

export const transitionConsentPage = {
    serviceProvider: value => ({ type: SERVICE_PROVIDER_TRANSITION_CONSENT, value }),
    formValid: () => ({ type: FORM_VALID_TRANSITION_CONSENT }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_CONSENT }),
};

export const CONSENT_PAGE_SET_VALUE = 'customerVerificationService/consentPage/setValue/';

export const SERVICE_PROVIDER_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'serviceProvider';
export const setServiceProviderValue = value => ({ type: SERVICE_PROVIDER_SET_VALUE, value });

export const PRIVACY_POLICY_SIDEDRAWER_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'privacyPolicySideDrawer';
export const SUBSCRIBER_AGREEMENT_SIDEDRAWER_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'subscriberAgreementSideDrawer';
export const TERMS_OF_USE_SIDEDRAWER_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'termsOfUseSideDrawer';
export const setPrivacyPolicyDrawer = value => ({ type: PRIVACY_POLICY_SIDEDRAWER_SET_VALUE, value });
export const setSubscriberAgreementDrawer = value => ({ type: SUBSCRIBER_AGREEMENT_SIDEDRAWER_SET_VALUE, value });
export const setTermsOfUseDrawer = value => ({ type: TERMS_OF_USE_SIDEDRAWER_SET_VALUE, value });

export const RESET_CONSENT_PAGE_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'resetPage';
export const resetConsentPage = () => ({ type: RESET_CONSENT_PAGE_SET_VALUE });

export const PRIVACY_POLICY_CHECKBOX_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'privacyPolicyCheckbox';
export const SUBSCRIBER_AGREEMENT_CHECKBOX_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'subscriberAgreementCheckbox';
export const TERMS_OF_USE_CHECKBOX_SET_VALUE = CONSENT_PAGE_SET_VALUE + 'termsOfUseCheckbox';
export const setPrivacyPolicyCheckbox = value => ({ type: PRIVACY_POLICY_CHECKBOX_SET_VALUE, value });
export const setSubscriberAgreementCheckbox = value => ({ type: SUBSCRIBER_AGREEMENT_CHECKBOX_SET_VALUE, value });
export const setTermsOfUseCheckbox = value => ({ type: TERMS_OF_USE_CHECKBOX_SET_VALUE, value });

export const TERMS_OPEN_MODAL = CONSENT_PAGE_SET_VALUE + 'termsOpenModal';
export const TERMS_CLOSE_MODAL = CONSENT_PAGE_SET_VALUE + 'termsCloseModal';
export const openTermModal = () => ({ type: TERMS_OPEN_MODAL });
export const closeTermsModal = () => ({ type: TERMS_CLOSE_MODAL });

