import { initRouting } from './routing';
import { initAgents } from './agent';
import { initBiasharaFlexiCover } from './coverDetails';
import { initCompanyDetails } from './companyDetails';
import { initEmployeeDetails } from './employeeDetails';
import { initUploadDocuments } from './uploadDocuments';
import { initPayments } from './payment';
import { initPayload } from './payloadCollection';

export default () => {
    return {
        routing: initRouting,
        agent: initAgents,
        coverDetails: initBiasharaFlexiCover,
        companyDetails: initCompanyDetails,
        employeeDetails: initEmployeeDetails,
        uploadDocuments: initUploadDocuments,
        payments: initPayments,
        payloadCollection: initPayload,
    };
};
