import { API_CALL_STATUS } from "../status";
import { GET_APPLICABLE_REPAYMENT_TERMS, GET_APPLICABLE_REPAYMENT_TERMS_SUCCESS, GET_APPLICABLE_REPAYMENT_TERMS_FAILURE } from "../../actions/personalLoansCalculator/referenceData";

const initialCalculatorReferenceData = {
    loanAmountOptions: [
        { code: 'R250000', value: 250000, description: 'R250 000' },
        { code: 'R100000', value: 100000, description: 'R100 000' },
        { code: 'R50000', value: 50000, description: 'R50 000' },
        { code: 'R20000', value: 20000, description: 'R20 000' },
        { code: 'R10000', value: 10000, description: 'R10 000' },
        { code: 'R5000', value: 5000, description: 'R5 000' },
        { code: 'Own Amount', value: 'Own Amount', description: 'Own amount' },
    ],
    repaymentDurationOptions: {
        status: API_CALL_STATUS.IDLE,
        data: [],
        error: null,
    }
}
export default (state = initialCalculatorReferenceData, action) => {
    switch (action.type) {
        case GET_APPLICABLE_REPAYMENT_TERMS:
            return {
                ...state,
                repaymentDurationOptions: {
                    ...state.repaymentDurationOptions,
                    status: API_CALL_STATUS.PENDING
                }
            }
        case GET_APPLICABLE_REPAYMENT_TERMS_SUCCESS:
            return {
                ...state,
                repaymentDurationOptions: {
                    ...state.repaymentDurationOptions,
                    status: API_CALL_STATUS.SUCCESS,
                    data: action.value
                }
            }
        case GET_APPLICABLE_REPAYMENT_TERMS_FAILURE:
            return {
                ...state,
                repaymentDurationOptions: {
                    ...state.repaymentDurationOptions,
                    status: API_CALL_STATUS.FAILURE,
                    error: action.error
                }
            }
        default:
            return state;
    }
}