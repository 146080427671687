import { getDigiLifeLoanProtection } from "./index";
import { createSelector } from "reselect";
import {
  FIELD_STATUS,
  FORM_STATUS,
  TOGGLE_STATUS,
} from "../../reducers/status";
import { ADDRESS_TYPES } from "../../actions/easiplusFuneralPlan/types";
import { getReferenceTitles, getReferencePostalMethods } from "./referenceData";
import {
  dateOfBirthFromIdNumber,
  getAgeFromIdNumber,
  getGenderFromIdNumber,
  mapGenderDataEntry,
} from "../../functions/easiplusFuneralPlan/Util";

export const getPersonalDetails = createSelector(
  getDigiLifeLoanProtection,
  (digiLifeLoanprotection) => digiLifeLoanprotection.personalDetails
);

export const getPersonalDetailsValid = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.formStatus === FORM_STATUS.VALID
);
export const getPersonalDetailsForm = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.form
);
export const getDigiLifeCMDObject = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.dlpCmbObject
);

export const getGcsStreetAddress = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.gCSStreetAddress
);
export const getGcsPostalAddress = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.gCSPostalAddress
);

export const getDropOffDetails = createSelector(
  getPersonalDetails,
  (personalDetails) => personalDetails.dropOffDetails
);

const makeGetField = (fieldId) =>
  createSelector(
    getPersonalDetailsForm,
    (personalDetailsForm) => personalDetailsForm[fieldId]
  );

export const getPostalAddressMethod = makeGetField("postalAddressMethod");
export const getPostalAddressSame = makeGetField("postalAddressSame");

// Personal
export const getTitle = makeGetField("title");
export const getFirstNames = makeGetField("firstNames");
export const getSurname = makeGetField("surname");
export const getId = makeGetField("id");
export const getIdType = makeGetField("idType");
export const getGender = makeGetField("gender");
export const getEducation = makeGetField("education");
export const getIdExpiry = makeGetField("idExpiry");
export const getFullName = createSelector(
  getFirstNames,
  getSurname,
  (firstnames, surname) => firstnames.value + " " + surname.value
);

export const getInitials = createSelector(getFirstNames, (firstNames) => {
  const nameArray = firstNames.value.split(" ");
  return nameArray
    .map((name) => name[0])
    .join("")
    .toUpperCase();
});

// Contact
export const getMobile = makeGetField("mobileNumber");
export const getEmail = makeGetField("email");

// Address
export const getStreetAddress = makeGetField("streetAddress");
export const getDistrict = makeGetField("district");
export const getCity = makeGetField("city");
export const getCountry = makeGetField("country");

export const getResedentialStreetAddress = createSelector(
  getStreetAddress,
  getCity,
  getCountry,
  getDistrict,
  (streetAddress, city, country,district) =>
    [streetAddress?.value, city?.value,district.displayValue,country?.value].filter(Boolean).join(", ")
);

// Loan details
export const getLoanAmount = makeGetField("loanAmount");
export const getLoanType = makeGetField("loanType");
export const getBank = makeGetField("bank");
export const getLoanTerm = makeGetField("loanTerm");

//Employment details
export const getEmploymentType = makeGetField("employmentType");
export const getSourceOfIncome = makeGetField("sourceOfIncome");
export const getOccupation = makeGetField("occupation");
export const getSourceOfIncomeText = makeGetField("sourceOfIncomeText");

//Payment Frequwncy
export const getPaymentFrequency = makeGetField("paymentFrequency");

// Stress Address
export const getPostalStreetAddress = makeGetField("postalStreetAddress");
export const getPostalCity = makeGetField("postalCity");
export const getPostalCountry = makeGetField("postalCountry");
export const getPostalDistrict = makeGetField("postalDistrict");

export const getPostalAddress = createSelector(
  getPostalStreetAddress,
  getPostalCity,
  getPostalDistrict,
  getPostalCountry,
  (postalStreetAddress, postalCity,postalDistrict, postalCountry) => {
    return [postalStreetAddress.value, postalCity.value, postalDistrict.displayValue , postalCountry.value]
      .filter(Boolean)
      .join(", ");
  }
);

// Submission
export const getPostalAddressForSubmission = createSelector(
  getPostalStreetAddress,
  getPostalCity,
  getPostalCountry,

  (postalStreetAddress, postalCity, postalCountry) => {
    const street = [postalStreetAddress.value]
      .filter((value) => !!value)
      .join(", ");
    const city = [postalCity.value, postalCountry.value]
      .filter((value) => !!value)
      .join(", ");

    return {
      street,
      city,
    };
  }
);

export const getContactInformation = createSelector(
  getMobile,
  getEmail,
  getStreetAddress,
  getCity,
  getCountry,
  getPostalAddressForSubmission,
  getPostalAddressSame,
  (mobile, email, streetAddress, city, postalAddress, postalAddressSame) => {
    const residentialAddress = {
      street: streetAddress.value,
      city: city.value,
    };

    return {
      cellphoneNumber: mobile.value,
      email: email.value,
      postalAddress: {
        ...(postalAddressSame.value ? residentialAddress : postalAddress),
        isPostalSameAsResidential: postalAddressSame.value,
      },
      residentialAddress: {
        ...residentialAddress,
        isPostalSameAsResidential: postalAddressSame.value,
      },
    };
  }
);

export const getTitleDataEntry = createSelector(
  getTitle,
  getReferenceTitles,
  (title, referenceTitles) => {
    return referenceTitles.find((ref) => ref.code === title.value);
  }
);
export const getGenderDataEntry = createSelector(getId, (idNumber) => {
  const genderString = getGenderFromIdNumber(idNumber.value);
  return mapGenderDataEntry(genderString);
});

export const getPersonalDetailsSubmission = createSelector(
  getContactInformation,
  getFirstNames,
  getSurname,
  getId,
  getTitleDataEntry,
  getGenderDataEntry,
  (
    contactInformation,
    firstName,
    surname,
    idNumber,
    titleDataEntry,
    genderDataEntry
  ) => {
    const age = getAgeFromIdNumber(idNumber.value);
    const dateOfBirth = dateOfBirthFromIdNumber(idNumber.value);
    return {
      contactInformation,
      age,
      dateOfBirth,
      firstName: firstName.value,
      surname: surname.value,
      saIdNumber: idNumber.value,
      title: titleDataEntry,
      gender: genderDataEntry,
    };
  }
);

export const getAddressLabel = createSelector(
  getPostalAddressSame,
  (postalAddressSame) => {
    if (!postalAddressSame?.value) return "Residential Address";

    return "Residential/Postal Address";
  }
);

export const getFormattedContactNumber = createSelector(
  getMobile,
  (mobileNumber) => {
    const number = mobileNumber?.value;

    if (!number || number.length !== 10) return number;

    const splitnumber = number.match(/^(\d{1})(\d{2})(\d{3})(\d{4})$/);
    const dialingCode = [splitnumber[1], splitnumber[2]].join("");

    return [dialingCode, splitnumber[3], splitnumber[4]].join(" ");
  }
);

export const getStreetAddressPostalMethod = createSelector(
  getReferencePostalMethods,
  (referencePostalMethods) => {
    return referencePostalMethods
      ? referencePostalMethods.find(
          (method) => method.code === ADDRESS_TYPES.STREET_ADDRESS
        )
      : "";
  }
);

export const getPoBoxPostalMethod = createSelector(
  getReferencePostalMethods,
  (referencePostalMethods) => {
    return referencePostalMethods
      ? referencePostalMethods.find(
          (method) => method.code === ADDRESS_TYPES.PO_BOX
        )
      : "";
  }
);

export const getPrivateBagPostalMethod = createSelector(
  getReferencePostalMethods,
  (referencePostalMethods) => {
    return referencePostalMethods
      ? referencePostalMethods.find(
          (method) => method.code === ADDRESS_TYPES.PRIVATE_BAG
        )
      : "";
  }
);

export const getPreferredPostalMethod = createSelector(
  getPostalAddressMethod,
  getStreetAddressPostalMethod,
  getPoBoxPostalMethod,
  getPrivateBagPostalMethod,
  (
    postalAddressMethod,
    streetAddressPostalMethod,
    poBoxPostalMethod,
    privateBagPostalMethod
  ) => {
    const postalMethods = [
      streetAddressPostalMethod.code,
      privateBagPostalMethod.code,
      poBoxPostalMethod.code,
    ];
    return postalMethods.includes(postalAddressMethod.value)
      ? postalAddressMethod.value
      : null;
  }
);

export const getIsPostalAddressMethodStreetAddress = createSelector(
  getPostalAddressMethod,
  getStreetAddressPostalMethod,
  (postalAddressMethod, streetAddressPostalMethod) => {
    return postalAddressMethod.value === streetAddressPostalMethod.code;
  }
);

export const getIsPostalAddressMethodPoBox = createSelector(
  getPostalAddressMethod,
  getPoBoxPostalMethod,
  (postalAddressMethod, poBoxPostalMethod) => {
    return postalAddressMethod.value === poBoxPostalMethod.code;
  }
);

export const getIsPostalAddressMethodPrivateBag = createSelector(
  getPostalAddressMethod,
  getPrivateBagPostalMethod,
  (postalAddressMethod, privateBagPostalMethod) => {
    return postalAddressMethod.value === privateBagPostalMethod.code;
  }
);

export const getIsPostalAddressSameAsResidential = createSelector(
  getPostalAddressSame,
  (postalAddressSame) => {
    return postalAddressSame.value === TOGGLE_STATUS.YES;
  }
);

export const getAreDropoffDetailsValid = createSelector(
  getFirstNames,
  getSurname,
  getMobile,
  (firstNames, surname, mobile) => {
    return (
      firstNames.status === FIELD_STATUS.VALID &&
      surname.status === FIELD_STATUS.VALID &&
      mobile.status === FIELD_STATUS.VALID
    );
  }
);
