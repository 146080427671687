import {
    validateSingleSelection,
    validateDate,
    validateNumericString,
    validateAccountNumber,
} from '@om/validation';


import { setBankingDetailsFieldValid } from '../../../actions/allsureInsurance/bankingDetails';
import { validateAlphaNumericString, validateFullName } from '@om/validation/src/validators/string-validator';
import { validateDateOfBirthVTwo } from '@om/validation/src/validators/date-of-birth-validator';

let prevValidationAction = null;

export const bankingDetailsValidations = (store, next, action) => {
    const validationAction = (() => {
        let error;
        const trimValue = (value) => (value ? value.trim() : value);
        const getTommorowsDate=()=>{
            const  currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 1);
const day = currentDate.getDate()
const month = currentDate.getMonth() + 1
const year = currentDate.getFullYear()
return`${day}/${month}/${year}`  
        }
        switch (action.value.fieldType) {
            case 'bankName':
                error = validateSingleSelection(action.value.value, 'Bank Name');
                break;

            case 'branchCode':
                error = validateSingleSelection(action.value.value, 'Branch Code');
                break;

            case 'accountHolderName':
                error = validateFullName(action.value.value,{min:3, max:66,required:true,subject:'Account holder name'})
                break;

            case 'accountNumber':
                error = validateAccountNumber(action.value.value, {
                    min: 5,
                    max: 22,
                    subject: 'Account number',
                });
                break;

            case 'accountType':
                error = validateSingleSelection(action.value.value, 'Account type');
                break;

            case 'coverStartDate':
                error = validateDate(action.value.value, {
                    min: getTommorowsDate(),
                    subject:'Cover start date',
                    required: true,
                });
                break;

            case 'debitOrderDay':
                error = validateSingleSelection(action.value.value, 'Debit order day');
                break;

            case 'sourceOfFund':
                error = validateSingleSelection(action.value.value, 'Source of Funds');
                break;

            case 'otherSourceofFund':
                error = validateAlphaNumericString(action.value.value, {
                    min:3,
                    max:66,
                    subject:'Source of Funds',
                    required:true,
                });
                break;
    
            case 'paymentConfirmation':
                error = validateSingleSelection(action.value.value, 'Fuel type');
                break;

            default:
                return null;
        }

        return setBankingDetailsFieldValid(error, action.value.fieldType);
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
