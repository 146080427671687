// Events
export const FUND_CENTRE_TOGGLE_TRANSITION = 'fundCentre/fundTable/transition/';
export const FUND_CENTRE_SET_VALUE = 'fundCentre/fundTable/setValue/';
export const FUND_CENTRE_CLEAR_VALUE = 'fundCentre/fundTable/clearValue/';

export const SHOWHIDE_FILTER_CARD_SET_VALUE = FUND_CENTRE_SET_VALUE + 'showHideFilterCard';
export const SHOWHIDE_FILTER_CARD_CLOSE_SET_VALUE = FUND_CENTRE_SET_VALUE + 'showHideFilterCardClose';

export const FUNDS_DATA_SET_VALUE = FUND_CENTRE_SET_VALUE + 'fundsData';
export const FILTER_SELECTION_SET_VALUE = FUND_CENTRE_SET_VALUE + 'filterSelection';
export const CLEAR_FILTER_SELECTION_SET_VALUE = FUND_CENTRE_SET_VALUE + 'clearFilterSelection';
export const PAGINATION_TABLE_SIZE_SET_VALUE = FUND_CENTRE_SET_VALUE + 'paginationTableSize';
export const FILTER_SELECTION_ACTIVE_OPTION_SET_VALUE = FUND_CENTRE_SET_VALUE + 'activeFilterOption';
export const OPENED_FUND_MOBILE_SET_VALUE = FUND_CENTRE_SET_VALUE + 'openedMobileFund';

export const setShowHideFilterCard = () => ({ type: SHOWHIDE_FILTER_CARD_SET_VALUE });
export const setFundsData = (value) => ({ type: FUNDS_DATA_SET_VALUE, value });
export const clearFilterSelection = value => ({ type: CLEAR_FILTER_SELECTION_SET_VALUE, value });
export const setOpenedMobileFund = value => ({ type: OPENED_FUND_MOBILE_SET_VALUE, value });
export const setActiveFilterOption = value => ({ type: FILTER_SELECTION_ACTIVE_OPTION_SET_VALUE, value });

//Search actions
export const TOGGLE_FUND_TABLE_SEARCH_BAR = FUND_CENTRE_TOGGLE_TRANSITION + 'toggleSearchBar';
export const toggleFundTableSearchBar = () => ({ type: TOGGLE_FUND_TABLE_SEARCH_BAR });

export const SEARCH_QUERY_FILTER = FUND_CENTRE_SET_VALUE + 'filterRecommendations';
export const SEARCH_QUERY_SET_VALUE = FUND_CENTRE_SET_VALUE + 'searchQuery';
export const SEARCH_QUERY_CLEAR_VALUE = FUND_CENTRE_CLEAR_VALUE + 'searchQuery';

export const setFundCentreFilterRecommendations = (value, searchQuery) => ({ type: SEARCH_QUERY_FILTER, value, searchQuery });
export const setFundCentreSearchQuery = (value) => ({ type: SEARCH_QUERY_SET_VALUE, value });
export const clearFundCentreSearchQuery = (value) => ({ type: SEARCH_QUERY_CLEAR_VALUE, value });

export const setPaginationTableSize = value => ({ type: PAGINATION_TABLE_SIZE_SET_VALUE, value });
export const setFilterSelection = (option, selection) => ({ type: FILTER_SELECTION_SET_VALUE, value: { option, selection } });
