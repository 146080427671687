import { createSelector } from 'reselect';
import { getTravelInsurance } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';
export const getQuote = createSelector(getTravelInsurance, (state) => {
    return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculation = createSelector(getQuote, (quote) => quote && quote.premiumCalculation);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.SUCCESS
);
export const getQuoteFormApiCallFailure = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.FAILURE
);
export const getIsApiPending = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === API_CALL_STATUS.PENDING);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);
export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus);
export const getUgCmbObject = createSelector(getQuote, (quote) => quote && quote.ugCmbObject);
export  const getIsApiPendingProducts = createSelector(getQuote, (quote) => quote && quote.apiPendingProducts);
export  const getProducts = createSelector(getQuote, (quote) => quote && quote.products);

const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getTripType = makeGetFormField('tripType');
export const getCoverFor = makeGetFormField('coverFor');
export const getDepartureCountry = makeGetFormField('departureCountry');
export const getDestinationCountry = makeGetFormField('destinationCountry');
export const getDepatureDate = makeGetFormField('departureDate');
export const getReturnDate = makeGetFormField('returnDate');
export const getNoOfTravellers = makeGetFormField('noOfTravellers');
export const getSelectedCover = makeGetFormField('selectedCover');
export const getShareEmailValue = makeGetFormField('shareEmail');
export const getDob = makeGetFormField('dob')
