import {
  DATE_OF_BIRTH_SET_VALUE,
  FIRSTNAME_SET_VALUE,
  SA_ID_NUMBER_SET_VALUE,
  setDateOfBirthValid,
  setFirstnameValid,
  setSaIdNumberValid,
  setSurnameValid,
  SURNAME_SET_VALUE,
} from "../../../actions/publicWeb/asisaUnclaimedBenefits";

import {
  validateDateOfBirth,
  validateFirstName,
  validateIdNumber,
  validateSurname,
} from "@om/validation";
import { validationIsEqual } from "../status";
import { hasValidSaIdNumber } from "../../../selectors/publicWeb/asisaUnclaimedBenefits";

let prevValidationAction = null;
export const asisaUnclaimedBenefitsValidation = (store, next, action) => {
  const validationAction = (() => {
    let error;
    switch (action.type) {
      case SA_ID_NUMBER_SET_VALUE:
        error = validateIdNumber(action.value, {required: hasValidSaIdNumber(store.getState())});
        return setSaIdNumberValid(error);
      case FIRSTNAME_SET_VALUE:
        error = validateFirstName(action.value);
        return setFirstnameValid(error);
      case SURNAME_SET_VALUE:
        error = validateSurname(action.value);
        return setSurnameValid(error);
      case DATE_OF_BIRTH_SET_VALUE:
        error = validateDateOfBirth(action.value);
        return setDateOfBirthValid(error);
      default:
        return null;
    }
  })();
  if (!validationAction){
    return next(action);
  }
  store.dispatch(validationAction);
  prevValidationAction = validationAction;
  return next(action);
};
