import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

export function validatePassportNumber(
    valueRaw,
    {
        subject = 'Passport number',
        required = true,
        retainSubjectCase
    } = {}
) {
    const min = config.PASSPORT_NUMBER.MIN;
    const max = config.PASSPORT_NUMBER.MAX;
    const value = valueRaw && (typeof valueRaw === 'string') && valueRaw.trim();

    if (!value && !required) {
        return null;
    }

    if (required && (!value || value.trim() === '')) {
        return OmValidationMessageHelper.getRequiredMessage(subject,retainSubjectCase);
    }

    const regEx = new RegExp(/^(?!0+$)[a-zA-Z0-9 ]*$/);
    if (!regEx.test(value)) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    const indexOfSpace = value.indexOf(' ');
    if (indexOfSpace > -1 && indexOfSpace < 6) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    if (min && (value.length < min)) {
        return OmValidationMessageHelper.getPassportTooShortMsg(subject, min, retainSubjectCase);
    }

    if (max && (value.length > max)) {
        return OmValidationMessageHelper.getPassportTooLongMsg(subject, max, retainSubjectCase);
    }

    return null;
}
