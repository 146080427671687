import { FIELD_STATUS, FORM_STATUS, API_CALL_STATUS } from '../status';
import {
    SET_TRIP_TYPE_VALUE,
    SET_TRIP_TYPE_VALID,
    SET_COVER_FOR_VALUE,
    SET_COVER_FOR_VALID,
    SET_DEPARTURE_COUNRTY_VALUE,
    SET_DEPARTURE_COUNRTY_VALID,
    SET_DESTINATION_COUNRTY_VALUE,
    SET_DESTINATION_COUNRTY_VALID,
    SET_DEPARTURE_DATE_VALUE,
    SET_DEPARTURE_DATE_VALID,
    SET_RETURN_DATE_VALUE,
    SET_RETURN_DATE_VALID,
    SET_NO_OF_TRAVELLERS_VALUE,
    SET_NO_OF_TRAVELLERS_VALID,
    SET_SELECTED_COVER_VALUE,
    SET_SELECTED_COVER_VALID,
    FORM_INVALID_TRAVEL_INSURANCE_FORM,
    FORM_VALID_TRAVEL_INSURANCE_FORM,
    FORM_VALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM,
    FORM_INVALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM,
    CALCULATE_TRAVEL_INSURANCE_PREMIUM_SUCCESS,
    TRAVEL_INSURANCE_ADD_COVER,
    REINITIALIZE,
    SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
    UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    SET_DOB_VALUE,
    SET_DOB_VALID,
    FETCH_ALL_PRODUCTS_SUCCESS,
    FETCH_ALL_PRODUCTS_FAILURE,
    API_PRODUCTS_PENDING_TRANSITION,
    API_PRODUCTS_SUCCESS_TRANSITION,
    API_PRODUCTS_FAILURE_TRANSITION,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
} from '../../actions/travel/quote';

export const initQuote = {
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    shareQuoteAPIStatus:null,
    addedCover: null,
    showShareModal: false,
    ugCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Travel Insurance',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    products: null,
    apiPendingProducts: null,
    premiumCalculation: {
        value: {premium: 150},
        status: null,
        error: null,
    },
    form: {
        tripType: {value: null, error: null, status: null},
        coverFor: {value: null, error: null, status: null},
        departureCountry: {value: null, error: null, status: null},
        destinationCountry: {value: null, error: null, status: null},
        departureDate: {value: null, error: null, status: null},
        returnDate: {value: null, error: null, status: null},
        noOfTravellers: {value: null, error: null, status: null},
        selectedCover: { value: null, error: null, status: null },
        shareEmail: { value: null, error: null, status: null },
        dob: {value: null, error: null, status: null}
    },
};

export default (state = initQuote, action) => {
    const mapError = error => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_INVALID_TRAVEL_INSURANCE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };
        case FORM_VALID_TRAVEL_INSURANCE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case FETCH_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.value,
            };

        case FETCH_ALL_PRODUCTS_FAILURE:
            return {
                ...state,
                products: action.error,
            };

        case API_PRODUCTS_PENDING_TRANSITION:
            return {
                ...state,
                apiPendingProducts: API_CALL_STATUS.PENDING,
            };

        case API_PRODUCTS_SUCCESS_TRANSITION:
            return {
                ...state,
                apiPendingProducts: API_CALL_STATUS.SUCCESS,
            };

        case API_PRODUCTS_FAILURE_TRANSITION:
            return {
                ...state,
                apiPendingProducts: API_CALL_STATUS.FAILURE,
            };

        case FORM_VALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case TRAVEL_INSURANCE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };

        case FORM_INVALID_TRAVEL_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case SET_TRIP_TYPE_VALUE:
            return updateFormField('tripType', (field) => ({
                ...field,
                value: action.value,
            }));

        case CALCULATE_TRAVEL_INSURANCE_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumCalculation: {
                    ...state.premiumCalculation,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        case SET_TRIP_TYPE_VALID:
            return updateFormField('tripType', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_COVER_FOR_VALUE:
            return updateFormField('coverFor', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_COVER_FOR_VALID:
            return updateFormField('coverFor', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_DEPARTURE_COUNRTY_VALUE:
            return updateFormField('departureCountry', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_DEPARTURE_COUNRTY_VALID:
            return updateFormField('departureCountry', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_DESTINATION_COUNRTY_VALUE:
            return updateFormField('destinationCountry', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_DESTINATION_COUNRTY_VALID:
            return updateFormField('destinationCountry', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_DEPARTURE_DATE_VALUE:
            return updateFormField('departureDate', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_DEPARTURE_DATE_VALID:
            return updateFormField('departureDate', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_DOB_VALUE:
            console.log('hits');
            return updateFormField('dob', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_DOB_VALID:
            return updateFormField('dob', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_RETURN_DATE_VALUE:
            return updateFormField('returnDate', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_RETURN_DATE_VALID:
            return updateFormField('returnDate', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_NO_OF_TRAVELLERS_VALUE:
            return updateFormField('noOfTravellers', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_NO_OF_TRAVELLERS_VALID:
            return updateFormField('noOfTravellers', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_SELECTED_COVER_VALUE:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SELECTED_COVER_VALID:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        // // Sidebar open
        // case UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE:
        //     return {
        //         ...state,
        //         sidebarOpen: action.value,
        //     };

        // Share modal
        case UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        // // API Calls
        // case API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
        //     return {
        //         ...state,
        //         apiCallStatus: API_CALL_STATUS.PENDING,
        //     };
        // case API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
        //     return {
        //         ...state,
        //         apiCallStatus: API_CALL_STATUS.SUCCESS,
        //     };
        // case API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
        //     return {
        //         ...state,
        //         apiCallStatus: API_CALL_STATUS.FAILURE,
        //     };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        // // Submit user data
        // case COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA:
        //     return {
        //         ...state,
        //         formStatus: FORM_STATUS.VALID,
        //     };

        // case SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS:
        //     return {
        //         ...state,
        //         quoteReferenceData: action.value,
        //     };
        // case UG_MOTOR_QUOTE_ADD_COVER:
        //     return {
        //         ...state,
        //         addedCover: action.value,
        //     };
        // case SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE:
        //     return {
        //         ...state,
        //         quoteReferenceData: action.error,
        //     };

        // case CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS:
        //     return {
        //         ...state,
        //         premiumCalculationUg: {
        //             ...state.premiumCalculationUg,
        //             value: action.value,
        //             status: API_CALL_STATUS.SUCCESS,
        //         },
        //     };

        // Reset state
        case REINITIALIZE:
            return initQuote;
        default:
            return state;
    }
};
