import { 
    AGE_SET_VALUE, 
    RETIRE_AT_SET_VALUE, 
    MONTHLY_INVESTMENT_SET_VALUE, 
    LINK_WITH_INFLATION_SELECT, 
    CHOOSE_OWN_PERCENTAGE_SELECT, 
    NO_INCREASE_SELECT, 
    COMFORTABLE_INVESTOR_SELECT, 
    ADVENTUROUS_INVESTOR_SELECT, 
    OWN_PERCENTAGE_SET_VALUE, 
    FACT_SHEET_CLICKED,
    INVALID_CONTINUE_TRANSITION_INVESTMENT_SETUP,
    CONTINUE_TRANSITION_INVESTMENT_SETUP,
    BACK_TRANSITION_INVESTMENT_SETUP,
} from "../../../actions/raOptimal/investmentSetup";
import { 
    getAge, 
    getRetirementAge, 
    getMonthlyInvestment,
    getLinkWithInflationIsSelected, 
    getChooseOwnPercentageIsSelected, 
    getNoIncreaseIsSelected, 
    getOwnPercentage, 
    getFactSheetClicked,
    getInvestmentSetupForm
} from "../../../selectors/raOptimal/investmentSetup";
import { simpleFieldTracking } from "../formTracking";
import { transitionTracking } from "../transitionTracking";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";
import { onFieldChangeStateComparison } from "../onFieldChangeStateComparison";

export const investmentSetupFieldsTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case AGE_SET_VALUE:
                return getAge
            case RETIRE_AT_SET_VALUE:
                sendError = false;
                return getRetirementAge
            case MONTHLY_INVESTMENT_SET_VALUE:
                return getMonthlyInvestment
            case OWN_PERCENTAGE_SET_VALUE:
                sendError = false;
                return getOwnPercentage
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        simpleFieldTracking({action, field, sendValue, sendError});
    }

    return result;
};

export const investmentSetupTransitionTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    const trackingActions = [
        INVALID_CONTINUE_TRANSITION_INVESTMENT_SETUP,
        CONTINUE_TRANSITION_INVESTMENT_SETUP,
        BACK_TRANSITION_INVESTMENT_SETUP,
    ];

    const investmentSetupForm = getInvestmentSetupForm(state);
    // capture relevant tracking data -- i.e. remove value from form fields
    const trackingData = Object.keys(investmentSetupForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: investmentSetupForm[key].status,
                error: investmentSetupForm[key].error,
            }
        }), {});

    if (trackingActions.includes(action.type))
        transitionTracking(action.type, trackingData);

    return result;
};

export const investmentSetupOtherTracking = (store, next, action) => {
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case LINK_WITH_INFLATION_SELECT:
                otherEventIndex = findOtherEventIndex("SELECT");
                return getLinkWithInflationIsSelected(state) ? "linkWithInflationSelected" : null;
            case CHOOSE_OWN_PERCENTAGE_SELECT:
                otherEventIndex = findOtherEventIndex("SELECT");
                return getChooseOwnPercentageIsSelected(state) ? "chooseOwnPercentageSelected" : null;
            case NO_INCREASE_SELECT: 
                otherEventIndex = findOtherEventIndex("SELECT");
                return getNoIncreaseIsSelected(state) ? "noIncreaseSelected" : null;
            case COMFORTABLE_INVESTOR_SELECT: 
                otherEventIndex = findOtherEventIndex("SELECT");
                return "comfortableInvestorSelected";
            case ADVENTUROUS_INVESTOR_SELECT: 
                otherEventIndex = findOtherEventIndex("SELECT");
                return "adventurousInvestorSelected";
            case FACT_SHEET_CLICKED:
                otherEventIndex = findOtherEventIndex("FACT_SHEET_CLICKED");
                return getFactSheetClicked(state)
            default: return null;
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};
