import httpStatusCode from '../../../../api-server/constants/httpStatusCode';
import {
    setAllsureApplicationApiStatus,
    setAllsureApplicationQuoteDetails,
} from '../../../actions/allsureInsurance/referenceData';
import { API_CALL_STATUS } from '../../../reducers/status';
import { getHomeDetailsRequestBody } from '../../../selectors/allsureInsurance/homeDetails';
import { getEmailSubjectLabel, getMotorDetailsRequestBody } from '../../../selectors/allsureInsurance/motorDetails';
import axios from 'axios';
import { getDocumentsArray } from '../../../selectors/allsureInsurance/uploadDocuments';
import { getPurchasePersonalDetailsForm } from '../../../selectors/allsureInsurance/purchasePersonalDetails';
import { sendEmailonCallmeBack, submitPolicy } from '../../../actions/allsureInsurance/policyRequests';
// import { getEmailSubjectLabel } from '../../../selectors/allsureInsurance/insuranceType';
import { getAllsureReferenceNumber } from '../../../selectors/allsureInsurance/referenceData';

export const uploadDocumentsService = (store, next, action) => {
    const state = store.getState();
    (async () => {
        try {
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.PENDING));
            const documentsArray = getDocumentsArray(state);
            const { fullname, mobileNumber } = getPurchasePersonalDetailsForm(state);
            const productLabel = getEmailSubjectLabel(state);
            const referenceNumber = getAllsureReferenceNumber(state);
            const subject = `Web: ${productLabel} : ${fullname?.value} - REF: ${referenceNumber}`;
            const files = await documentsArray.reduce(async (f, x) => {
                return f.then(async (last) => {
                    let hasError = false;
                    if (x.file !== null) {
                        const formData = new FormData();
                        formData.append('files', x.file);
                        formData.append('subject', subject);
                        formData.append('contactNumber', mobileNumber.value);
                        const executeApiWithDelay = async () => {
                            return new Promise ((resolve)=>{
                                setTimeout(async () =>{                               
                                await uploadDocuments(formData)
                                    .then((response) => {
                                        if (response && response?.status === 200) {
                                            hasError = false;
                                        } else {
                                            hasError = true;
                                        }
                                    })
                                    .catch((error) => {
                                        throw error;
                                    })
                                return resolve()
                                }
                        ,500);
                            }
                            )
                        };
                        await executeApiWithDelay();
                    }

                    last.push({ key: x.fileName, hasError });

                    return last;
                });
            }, Promise.resolve([]));
            if (files.some((x) => x.hasError)) {
                store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
            } else {
                store.dispatch(sendEmailonCallmeBack('purchaseMail'));
                // store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.SUCCESS))
            }
        } catch (e) {
            store.dispatch(setAllsureApplicationApiStatus(API_CALL_STATUS.FAILURE));
        }
    })();
    return next(action);
};

const uploadDocuments = async (payload) => {
    const url = 'om-api/allsure-insurance/documents/kyc';
    try {
        const response = await axios.post(url, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'test-api-key',
            },
        });
        return response;
    } catch (error) {
        return null;
    }
};
