import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    NO_EMPLOYEES_SET_VALUE,
    BENEFIT_AMOUNT_SET_VALUE,
    FORM_VALID_BIASHARA_FLEXI_COVER_DETAILS_FORM,
    FORM_INVALID_BIASHARA_FLEXI_COVER_DETAILS_FORM,
    NO_EMPLOYEES_SET_VALID,
    BENEFIT_AMOUNT_SET_VALID,
    API_PENDING_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM,
    API_SUCCESS_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM,
    API_FAILURE_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM,
    COLLECT_BIASHARA_FLEXI_COVER_DETAILS_FORM_DATA,
    SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_SUCCESS,
    SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_FAILURE,
    BIASHARA_FLEXI_PREMIUM_SUCCESS,
    ADD_TO_COVER_SET_VALUE,
} from '../../actions/biasharaFlexi/coverDetails';
import {
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    BIASHARA_FLEXI_SHARE_QUOTE_VALUE,
    BIASHARA_FLEXI_SIDEBAR_OPEN_VALUE,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    FORM_INVALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM,
    FORM_VALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM,
    SET_PAY_NOW_VALUE,
    SET_SHARE_EMAIL_VALID,
    SET_SHARE_EMAIL_VALUE,
    SHARE_BIASHARA_FLEXI_QUOTE_FAILURE,
    SHARE_BIASHARA_FLEXI_QUOTE_SUCCESS,
    TOKEN_IS_EXPIRED,
} from '../../actions/biasharaFlexi/quote';

export const initBiasharaFlexiCover = {
    formStatus: FORM_STATUS.INVALID,
    dropOffDetails: null,
    biasharaFlexiCoverDetailsReferenceData: null,
    apiCallStatus: null,
    addedToCover: false,
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    isTokenExpired: false,
    isPayNow: false,
    keCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Biashara Flexi Kenya',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    quoteShared: null,
    sidebarOpen: false,
    form: {
        employeesNumber: { value: null, error: null, status: null },
        benefitAmount: { value: null, label: null, error: null, status: null },
        shareEmail: { value: null, error: null, status: null },
    },
};

export default (state = initBiasharaFlexiCover, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case SET_PAY_NOW_VALUE:
            return { ...state, isPayNow: action.value }
        case FORM_VALID_BIASHARA_FLEXI_COVER_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_BIASHARA_FLEXI_COVER_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case BIASHARA_FLEXI_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };
            
        case NO_EMPLOYEES_SET_VALUE:
            return updateFormField('employeesNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case NO_EMPLOYEES_SET_VALID:
            return updateFormField('employeesNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BENEFIT_AMOUNT_SET_VALUE:
            return updateFormField('benefitAmount', (field) => ({
                ...field,
                value: action.value.id,
                label: action.value.value,
            }));
        case BENEFIT_AMOUNT_SET_VALID:
            return updateFormField('benefitAmount', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        //    API Calls
        case API_PENDING_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_BIASHARA_FLEXI_COVER_DETAILS_FORM:
            return { ...state, apiCallStatus: API_CALL_STATUS.FAILURE };

        // Submit user data
        case COLLECT_BIASHARA_FLEXI_COVER_DETAILS_FORM_DATA:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_SUCCESS:
            return { ...state, biasharaFlexiCoverDetailsReferenceData: action.value };
        case SUBMIT_BIASHARA_FLEXI_COVER_DETAILS_FAILURE:
            return { ...state, biasharaFlexiCoverDetailsReferenceData: action.error };

        case BIASHARA_FLEXI_PREMIUM_SUCCESS:
            return { ...state, annualPremium: action.value };
        case ADD_TO_COVER_SET_VALUE:
            return { ...state, addedToCover: action.value };

        case FORM_VALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_BIASHARA_FLEXI_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case BIASHARA_FLEXI_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_BIASHARA_FLEXI_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_BIASHARA_FLEXI_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        
        
        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };
            case TOKEN_IS_EXPIRED:
                return {
                    ...state,
                    isTokenExpired: action.value,
                };
        // Reset the state
        case REINITIALIZE:
            return initBiasharaFlexiCover;
        default:
            return state;
    }
};
