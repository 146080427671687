import { createSelector } from "reselect";
import { getEasiplusFuneralPlan } from "./index";
import { PAGES, PAGE_INDICES } from "../../actions/easiplusFuneralPlan/types"

export const getRouting = createSelector(getEasiplusFuneralPlan, efp => efp.routing);
export const getActivePageIndex = createSelector(getRouting, routing => routing.activePageIndex);
export const getDoEfpNavigation = createSelector(getRouting, routing => routing.doNavigation);
export const getDoExitEfpNavigation = createSelector(getRouting, routing => routing.doExitNavigation);

export const makeGetIsPage = pageIndex => createSelector(getRouting, routing => routing.activePageIndex === pageIndex);
export const getIsDropoffPage = createSelector(getRouting, routing => routing.activePageIndex === 1);
export const getIsReplacementPolicyPage = makeGetIsPage(PAGE_INDICES.REPLACEMENT_POLICY);
export const getIsPersonalDetailsPage = makeGetIsPage(PAGE_INDICES.PERSONAL_DETAILS);
export const getIsLivesCoveredPage = makeGetIsPage(PAGE_INDICES.LIVES_COVERED);
export const getIsBeneficiariesPage = makeGetIsPage(PAGE_INDICES.BENEFICIARIES);
export const getIsBeneficiariesSplitPage = makeGetIsPage(PAGE_INDICES.BENEFICIARIES_SPLIT);
export const getIsPaymentDetailsPage = makeGetIsPage(PAGE_INDICES.PAYMENT_DETAILS);
export const getIsConfirmationPage = makeGetIsPage(PAGE_INDICES.CONFIRMATION);
export const getIsThankYouPage = makeGetIsPage(PAGE_INDICES.THANK_YOU);

export const getProgressBarIndex = createSelector(
    getIsReplacementPolicyPage,
    getIsPersonalDetailsPage,
    getIsLivesCoveredPage,
    getIsBeneficiariesPage,
    getIsBeneficiariesSplitPage,
    getIsPaymentDetailsPage,
    getIsConfirmationPage,
    (
        isReplacementPolicyPage,
        isPersonalDetailsPage,
        isLivesCoveredPage,
        isBeneficiariesPage,
        isBeneficiariesSplitPage,
        isPaymentDetailsPage,
        isConfirmationPage,
    ) => {
        if (isReplacementPolicyPage) {
            return -1;
        }

        if(isPersonalDetailsPage){
            return 0;
        }

        if(isLivesCoveredPage){
            return 1;
        }

        if(isBeneficiariesPage || isBeneficiariesSplitPage){
            return 2;
        }

        if(isPaymentDetailsPage){
            return 3;
        }

        if(isConfirmationPage){
            return 4;
        }
    }
);

// used by the application wizard footer
export const getContinueButtonText = createSelector(
    getActivePageIndex,
    (activePageIndex) => {
        return activePageIndex === PAGE_INDICES.CONFIRMATION ? "SUBMIT" : "CONTINUE";
    }
);

// used by the application wizard header
// TODO: Implement page types and remove magic numbers
export const getHideDesktop = createSelector(
    getActivePageIndex,
    (activePageIndex) => {
        return activePageIndex < 1 || activePageIndex === 7;
    }
)

// used by the application wizard header
// TODO: Implement page types and remove magic numbers
export const getHideMobile = createSelector(
    getActivePageIndex,
    (activePageIndex) => {
        return activePageIndex === 7;
    }
)