import {
    COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    TRIGGER_GET_ALL_CAR_MAKES,
    TRIGGER_GET_ALL_CAR_MODELS,
    TRIGGER_GET_UG_VALUERS,
} from '../../../actions/ugMotorInsurance/quote';
import { TRIGGER_FETCH_UG_AGENTS } from '../../../actions/ugMotorInsurance/motorAgentModal';
import { getAgentsService } from './agents';

import {
    TRIGGER_PAYMENTS_STK_PUSH,
    CHECK_PAYMENTS_STATUS,
    TRIGGER_CONFIRM_ORDER,
} from '../../../actions/ugMotorInsurance/payments';
import { paymentsStkService, paymentsStkStatusService } from './payments';
import { orderConfirmationService } from './orderConfirmation';
import { premiumCalculationService } from './premiumCalculation';
import { COLLECT_PAYLOAD_DATA } from '../../../actions/ugMotorInsurance/payloadCollection';
import { payloadSubmitService } from './payloadCollection';
import { shareQuoteService } from './shareQuote';
import { COLLECT_LOGIN_DETAILS } from '../../../actions/ugMotorInsurance/auth';
import { loginService } from './login';
import { getCarMakesService } from './carMake';
import { getCarModelsService } from './carModels';
import { getValuersService } from './valuers';
import { amlWhitelistCheckService } from './amlWhitelist';
import { TRIGGER_AML_WHITELIST_CHECK } from '../../../actions/ugMotorInsurance/personalDetails';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const ugMotorInsuranceCover = (store, next, action) => {
    if (action.type.startsWith(TRIGGER_AML_WHITELIST_CHECK)) {
        return amlWhitelistCheckService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_PAYMENTS_STK_PUSH)) {
        return paymentsStkService(store, next, action);
    }
    if (action.type.startsWith(CHECK_PAYMENTS_STATUS) && gatsbyStack === 'ug') {
        return paymentsStkStatusService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA)) {
        return premiumCalculationService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_PAYLOAD_DATA)) {
        return payloadSubmitService(store, next, action);
    }
    if (action.type.startsWith(TRIGGER_CONFIRM_ORDER) && gatsbyStack === 'ug') {
        return orderConfirmationService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_SHARE_QUOTE_FORM_DATA)) {
        return shareQuoteService(store, next, action);
    }
    if (action.type.startsWith(COLLECT_LOGIN_DETAILS)) {
        return loginService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_ALL_CAR_MAKES)) {
        return getCarMakesService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_ALL_CAR_MODELS)) {
        return getCarModelsService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_GET_UG_VALUERS)) {
        return getValuersService(store, next, action);
    }

    if (action.type.startsWith(TRIGGER_FETCH_UG_AGENTS)) {
        return getAgentsService(store, next, action);
    }

    return null;
};
