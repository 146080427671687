import {
    BASIC_CALCULATOR_SET_VALID,
    BASIC_CALCULATOR_SET_VALUE, REINITIALIZE_STATE_BASIC_CALCULATOR
} from "../../../actions/incomeTaxCalculator/basicCalculator";
import {basicCalculatorFormRules} from "./basicCalculator";
import {
    ADVANCED_CALCULATOR_SET_VALID,
    ADVANCED_CALCULATOR_SET_VALUE, ADVANCED_CALCULATOR_TOGGLE_ACCORDION, REINITIALIZE_STATE_ADVANCED_CALCULATOR
} from "../../../actions/incomeTaxCalculator/advancedCalculator";
import {advancedCalculatorFormRules} from "./advancedCalculator";

export const incomeTaxCalculatorRules = (store, next, action) => {

    // basic income tax calculator
    if (action.type.startsWith(BASIC_CALCULATOR_SET_VALUE) ||
        action.type.startsWith(BASIC_CALCULATOR_SET_VALID) ||
        action.type === REINITIALIZE_STATE_BASIC_CALCULATOR)
        return basicCalculatorFormRules(store, next, action);

    // advanced income tax calculator
    if (action.type.startsWith(ADVANCED_CALCULATOR_SET_VALUE) ||
        action.type.startsWith(ADVANCED_CALCULATOR_SET_VALID) ||
        action.type.startsWith(ADVANCED_CALCULATOR_TOGGLE_ACCORDION) ||
        action.type === REINITIALIZE_STATE_ADVANCED_CALCULATOR)
        return advancedCalculatorFormRules(store, next, action);

    return null;
};
