import { createSelector } from 'reselect';
import { getPersonalAccidentState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getQuote = createSelector(getPersonalAccidentState, (state) => {
    return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculation = createSelector(getQuote, (quote) => quote && quote.premiumCalculation);
export const getSelectedCoverOption = createSelector(getQuote, (quote) => quote && quote.selectedCoverOption);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.SUCCESS
);
export const getQuoteFormApiCallFailure = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.FAILURE
);
export const getIsApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.apiCallStatus === FORM_STATUS.PENDING
);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);

export const getShowCoverOptions = createSelector(getQuote, (quote) => quote && quote.showCoverOptions);

export const getCoverType = createSelector(getQuote, (quote) => quote && quote.coverType);

export const getCoverLimits = createSelector(getQuote, (quote) => quote && quote.coverLimits);

export const getCoverOptionIndex = createSelector(getQuote, (quote) => quote && quote.coverOptionIndex);

export const getSelectedChildCoverOption = createSelector(getQuote, (quote) => quote && quote.selectedChildCoverOption);

export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus);
export const getUgCmbObject = createSelector(getQuote, (quote) => quote && quote.ugCmbObject);
const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getIsExpiredAuth = createSelector(getQuote, (quote) => quote && quote.isTokenExpired);

export const getIsCoverOptionsApiPending = createSelector(
    getQuote,
    (quote) => quote && quote.coverOptionsApiStatus === API_CALL_STATUS.PENDING
);

//new values
export const getDOBValue = makeGetFormField('dob');
export const getCoverLimitValue = makeGetFormField('coverLimit');
export const getProfessionValue = makeGetFormField('profession');
export const getSpouseIncludedValue = makeGetFormField('spouseIncluded');
export const getSpouseDobValue = makeGetFormField('spouseDob');
export const getChildrenIncludedValue = makeGetFormField('childrenIncluded');
export const getChildrenDobsValue = makeGetFormField('childrenDobs');
export const getExcessProtectorValue = makeGetFormField('excessProtector');
export const getUncommonRiskValue = makeGetFormField('uncommonRisk');
export const getViolenceAndTerrorismValue = makeGetFormField('violenceAndTerrorism');
export const getShareEmailValue = makeGetFormField('shareEmail');
export const getCoverOptions = makeGetFormField('coverOptions');
