export const HEALTH_INSURANCE_QOUTE_FORM = 'healthInsuranceRw/quoteForm/';
export const HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE = HEALTH_INSURANCE_QOUTE_FORM + 'setValue/';
export const HEALTH_INSURANCE_QOUTE_FORM_SET_VALID = HEALTH_INSURANCE_QOUTE_FORM + 'setValid/';
export const HEALTH_INSURANCE_QUOTE_ADD_COVER = HEALTH_INSURANCE_QOUTE_FORM + 'addCover/';	
export const FORM_VALID_HEALTH_INSURANCE_QUOTE_FORM = 'healthInsuranceRw/quoteForm/transition/formValid';
export const FORM_INVALID_HEALTH_INSURANCE_QUOTE_FORM = 'healthInsuranceRw/quoteForm/transition/formInValid';
export const FORM_VALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM = 'healthInsuranceRw/shareQuoteForm/transition/formValid';
export const FORM_INVALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM = 'healthInsuranceRw/shareQuoteForm/transition/formInValid';
export const HEALTH_INSURANCE_SHARE_QUOTE_VALUE = HEALTH_INSURANCE_QOUTE_FORM + 'shareQuote';
export const TOKEN_IS_EXPIRED = 'healthInsuranceRw/expiredAuth';


export const HEALTH_INSURANCE_SIDEBAR_OPEN_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'sidebarOpen'

export const REINITIALIZE = HEALTH_INSURANCE_QOUTE_FORM + '/state/reinitialize';

// VALIDATE QUOTE FORM

export const transitionHealthInsuranceRwQuoteForm = { 

	formValid: () => ({
		type: FORM_VALID_HEALTH_INSURANCE_QUOTE_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_HEALTH_INSURANCE_QUOTE_FORM,
	}),
};

export const transitionShareQuoteForm = {
	formInvalid: () => ({ 
		type: FORM_INVALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM,
	}),
	formValid: () => ({
		type: FORM_VALID_HEALTH_INSURANCE_QUOTE_SHARE_FORM,
	}),
}

// API Calls actions
export const API_PENDING_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM = `${HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM = `${HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM = `${HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${HEALTH_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${HEALTH_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${HEALTH_INSURANCE_SHARE_QUOTE_VALUE}/apiTransition/failure`;


export const apiTransitionHealthInsuranceQuoteForm = {
	pending: () => ({
		type: API_PENDING_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM,
	}),
	success: () => ({
		type: API_SUCCESS_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM,
	}),
	failure: () => ({
		type: API_FAILURE_TRANSITION_HEALTH_INSURANCE_QUOTE_FORM,
	}),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};

//calculated Data
export const COLLECT_HEALTH_INSURANCE_QUOTE_FORM_DATA = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'collectHealthInsuranceQuoteData';
export const SUBMIT_HEALTH_INSURANCE_QUOTE_SUCCESS = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'success';
export const SUBMIT_HEALTH_INSURANCE_QUOTE_FAILURE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_HEALTH_INSURANCE_PREMIUM_SUCCESS = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_HEALTH_INSURANCE_PREMIUM_FAILURE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'failure';
export const COLLECT_SHARE_QUOTE_FORM_DATA = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const COLLECT_PRODUCTS_DATA = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'collectProductsData';
export const SHARE_HEALTH_INSURANCE_QUOTE_SUCCESS = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_HEALTH_INSURANCE_QUOTE_FAILURE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'shareQuoteFailure';
export const COVER_ADDED_BOOLEAN = HEALTH_INSURANCE_QOUTE_FORM + 'coverAddedBolean';

export const collectProductsData = () => ({
	type: COLLECT_PRODUCTS_DATA,
});

export const collectHealthInsuranceQuoteData = () => ({
	type: COLLECT_HEALTH_INSURANCE_QUOTE_FORM_DATA,
});

export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

export const submitHealthInsuranceQuoteSuccess = () => ({
	type: SUBMIT_HEALTH_INSURANCE_QUOTE_SUCCESS,
	value,
});

export const shareQuoteSuccess = (value) => ({
    type: SHARE_HEALTH_INSURANCE_QUOTE_SUCCESS,
    value,
})

export const shareQuoteFailure = (error) => ({
    type: SHARE_HEALTH_INSURANCE_QUOTE_FAILURE,
    error,
})

export const calculateHealthInsurancePremiumSuccess = (value) => ({
	type: CALCULATE_HEALTH_INSURANCE_PREMIUM_SUCCESS,
	value,
});

export const submitHealthInsuranceQuoteFailure = () => ({
	type: SUBMIT_HEALTH_INSURANCE_QUOTE_FAILURE,
	error,
});

export const addCover = (value) => ({
	type: HEALTH_INSURANCE_QUOTE_ADD_COVER,
	value,
})

export const initializeQuoteForm = () => ({
	type: REINITIALIZE,
})


// Form values
export const SET_SHARE_EMAIL_VALUE = 'healthInsuranceRw/shareQuoteForm/setValue/shareEmailValue';
export const SET_SHARE_EMAIL_VALID = 'healthInsuranceRw/shareQuoteForm/setValid/shareEmailValue';
export const SET_SELECTED_COVER_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'selectHealthCoverValue';
export const SET_SELECTED_COVER_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'selectHealthCoverValid';
export const SET_YEARS_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'yearsOld';
export const SET_YEARS_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'yearsOld';
export const SET_IS_SPOUSE_INCLUDED_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'isSpouseIncluded';
export const SET_SPOUSE_AGE_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'spouseAgeValue';
export const SET_SPOUSE_AGE_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'spouseAgeValid';
export const SET_IS_CHILDREN_INCLUDED_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'isChildrenIncluded';
export const SET_CHILD_AGE_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'childAgeValue';
export const SET_CHILD_AGE_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'childAgeValid';
export const SET_INPATIENT_COVER_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'inpatientCover';
export const SET_INPATIENT_COVER_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'inpatientCover';
export const SET_OUTPATIENT_COVER_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'outpatientCover';
export const SET_OUTPATIENT_COVER_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'outpatientCover';
export const SET_MATERNITY_COVER_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'maternity';
export const SET_MATERNITY_COVER_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'maternity';
export const SET_DENTAL_COVER_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'dental';
export const SET_DENTAL_COVER_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'dental';
export const SET_OPTICAL_COVER_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'optical';
export const SET_OPTICAL_COVER_VALID = HEALTH_INSURANCE_QOUTE_FORM_SET_VALID + 'optical';
export const SET_PRODUCTS_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'products';
export const SET_TABLE_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'compare';
export const SET_TABLE_COMPARE_VALUE = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'compareTable';
export const COLLECT_COMPARE_PLANS = HEALTH_INSURANCE_QOUTE_FORM_SET_VALUE + 'collectComparePlans'

export const triggerCollectComparePlans = () => ({
	type: COLLECT_COMPARE_PLANS
})

export const setShareEmailValue = value => ({
	type: SET_SHARE_EMAIL_VALUE,
	value,
});

export const setShareEmailValid = error => ({
	type: SET_SHARE_EMAIL_VALID,
	error,
});

export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value
});

export const setSideBarOpen = (value) => ({ type: HEALTH_INSURANCE_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: HEALTH_INSURANCE_SHARE_QUOTE_VALUE, value });

export const setSelectedCoverValue = (value) => ({type: SET_SELECTED_COVER_VALUE, value});
export const setSelectedCoverValid = error => ({type: SET_SELECTED_COVER_VALID, error});

export const setYearsValue = (value) => ({type: SET_YEARS_VALUE, value});
export const setYearsValid = (error) => ({type: SET_YEARS_VALID, error});

export const setIsSpouseIncludedValue = (value) => ({ type: SET_IS_SPOUSE_INCLUDED_VALUE, value });
export const setIsChildrenIncludedValue = (value) => ({ type: SET_IS_CHILDREN_INCLUDED_VALUE, value });

export const setSpouseAgeValue = (value) => ({ type: SET_SPOUSE_AGE_VALUE, value });
export const setSpouseAgeValid = (error) => ({ type: SET_SPOUSE_AGE_VALID, error });
export const setChildrenAgeValue = (value) => ({ type: SET_CHILD_AGE_VALUE, value });
export const setChildrenAgeValid = (error) => ({ type: SET_CHILD_AGE_VALID, error });
export const setInPatientCoverValue = (value) => ({ type: SET_INPATIENT_COVER_VALUE, value });
export const setInPatientCoverValid = (error) => ({ type: SET_INPATIENT_COVER_VALID, error });
export const setOutPatientCoverValue = (value) => ({ type: SET_OUTPATIENT_COVER_VALUE, value });
export const setOutPatientCoverValid = (error) => ({ type: SET_OUTPATIENT_COVER_VALID, error });
export const setMaternityCoverValue = (value) => ({ type: SET_MATERNITY_COVER_VALUE, value });
export const setMaternityCoverValid = (error) => ({ type: SET_MATERNITY_COVER_VALID, error });
export const setDentalCoverValue = (value) => ({ type: SET_DENTAL_COVER_VALUE, value });
export const setDentalCoverValid = (error) => ({ type: SET_DENTAL_COVER_VALID, error });
export const setOpticalCoverValue = (value) => ({ type: SET_OPTICAL_COVER_VALUE, value });
export const setOpticalCoverValid = (error) => ({ type: SET_OPTICAL_COVER_VALID, error });
export const setProductsValue = (value) => ({ type: SET_PRODUCTS_VALUE, value });
export const setAddedToCoverBoleanValue = (value) => ({ type: COVER_ADDED_BOOLEAN, value });
export const setCompareVisibleValue = (value) => ({ type: SET_TABLE_VALUE, value });
export const setCompareTableValue = (value) => ({ type: SET_TABLE_COMPARE_VALUE, value });
