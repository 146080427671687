import {TOGGLE_USE_OF_PERSONAL_INFORMATION} from '../atRetirement/aboutYou';
import {CALL_ME_BACK_SIDEBAR_OPEN_VALUE} from './callMeBack';

export const FUNERAL_CLAIM_PUBLIC_WEB = 'publicWeb/funeralClaim';
export const FUNERAL_CLAIM_SET_VALUE = `${FUNERAL_CLAIM_PUBLIC_WEB}/setValue`;
export const FUNERAL_CLAIM_SET_VALID = `${FUNERAL_CLAIM_PUBLIC_WEB}/setValid`;
export const FUNERAL_CLAIM_TOGGLE = `${FUNERAL_CLAIM_PUBLIC_WEB}/toggle`;

// Form Submit
export const FUNERAL_CLAIM_SUBMIT_FORM = `${FUNERAL_CLAIM_PUBLIC_WEB}/submit/form`;
export const submitForm = () => ({type: FUNERAL_CLAIM_SUBMIT_FORM});

// Form Transition
export const FUNERAL_CLAIM_FORM_VALID_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/formTransition/valid`;
export const FUNERAL_CLAIM_FORM_INVALID_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/formTransition/invalid`;
export const formTransitionFuneralClaim = {
    formValid: () => ({ type: FUNERAL_CLAIM_FORM_VALID_TRANSITION }),
    formInvalid: () => ({ type: FUNERAL_CLAIM_FORM_INVALID_TRANSITION }),
};

// API Transition
export const FUNERAL_CLAIM_API_PENDING_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiTransition/pending`;
export const FUNERAL_CLAIM_API_SUCCESS_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiTransition/success`;
export const FUNERAL_CLAIM_API_FAILURE_TRANSITION = `${FUNERAL_CLAIM_PUBLIC_WEB}/apiTransition/failure`;
export const apiTransitionFuneralClaim = {
    pending: () => ({ type: FUNERAL_CLAIM_API_PENDING_TRANSITION }),
    success: () => ({ type: FUNERAL_CLAIM_API_SUCCESS_TRANSITION }),
    failure: () => ({ type: FUNERAL_CLAIM_API_FAILURE_TRANSITION }),
};

// First Name
export const FUNERAL_CLAIM_SET_FIRST_NAME_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/firstName`;
export const setFirstNameValue = value => ({type: FUNERAL_CLAIM_SET_FIRST_NAME_VALUE, value});
export const FUNERAL_CLAIM_SET_FIRST_NAME_VALID = `${FUNERAL_CLAIM_SET_VALID}/firstName`;
export const setFirstNameValid = error => ({type: FUNERAL_CLAIM_SET_FIRST_NAME_VALID, error});

// Surname
export const FUNERAL_CLAIM_SET_SURNAME_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/surname`;
export const setSurnameValue = value => ({type: FUNERAL_CLAIM_SET_SURNAME_VALUE, value});
export const FUNERAL_CLAIM_SET_SURNAME_VALID = `${FUNERAL_CLAIM_SET_VALID}/surname`;
export const setSurameValid = error => ({type: FUNERAL_CLAIM_SET_SURNAME_VALID, error});

// Cellphone Number
export const FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/cellphoneNumber`;
export const setCellphoneNumberValue = (value, countryCode) => ({type: FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE, value, countryCode});
export const FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID = `${FUNERAL_CLAIM_SET_VALID}/cellphoneNumber`;
export const setCellphoneNumberValid = error => ({type: FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALID, error});

// isUserPolicyHolder,
export const FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER = `${FUNERAL_CLAIM_SET_VALUE}/isUserPolicyHolder`;
export const setIsUserPolicyHolder = value => ({ type: FUNERAL_CLAIM_SET_IS_USER_POLICY_HOLDER, value });

// Requester ID Number
export const FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/requesterIdNumber`;
export const setRequesterIdValue = value => ({type: FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE, value});
export const FUNERAL_CLAIM_SET_REQUESTER_ID_VALID = `${FUNERAL_CLAIM_SET_VALID}/requesterIdNumber`;
export const setRequesterIdValid = error => ({type: FUNERAL_CLAIM_SET_REQUESTER_ID_VALID, error});

// Deceased ID Number
export const FUNERAL_CLAIM_SET_DECEASED_ID_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/deceasedIdNumber`;
export const setDeceasedIdValue = value => ({type: FUNERAL_CLAIM_SET_DECEASED_ID_VALUE, value});
export const FUNERAL_CLAIM_SET_DECEASED_ID_VALID = `${FUNERAL_CLAIM_SET_VALID}/deceasedIdNumber`;
export const setDeceasedIdValid = error => ({type: FUNERAL_CLAIM_SET_DECEASED_ID_VALID, error});

// Set the validity of deceased and requester IDs in one action
export const FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID = `${FUNERAL_CLAIM_SET_VALID}/deceasedRequesterIdNumber`;
export const setDeceasedRequesterIdValid = (deceasedError, requesterError) =>
    ({type: FUNERAL_CLAIM_SET_DECEASED_REQUESTER_ID_VALID, deceasedError, requesterError});

// Funeral Date
export const FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/funeralDate`;
export const setFuneralDateValue = value => ({type: FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE, value});
export const FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID = `${FUNERAL_CLAIM_SET_VALID}/funeralDate`;
export const setFuneralDateValid = error => ({type: FUNERAL_CLAIM_SET_FUNERAL_DATE_VALID, error});

// Bank
export const FUNERAL_CLAIM_SET_BANK_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bank`;
export const setBankValue = value => ({type: FUNERAL_CLAIM_SET_BANK_VALUE, value});

// Bank Other
export const FUNERAL_CLAIM_SET_BANK_OTHER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankOther`;
export const setBankOtherValue = value => ({type: FUNERAL_CLAIM_SET_BANK_OTHER_VALUE, value});
export const FUNERAL_CLAIM_SET_BANK_OTHER_VALID = `${FUNERAL_CLAIM_SET_VALID}/bankOther`;
export const setBankOtherValid = error => ({type: FUNERAL_CLAIM_SET_BANK_OTHER_VALID, error});

// Branch Code
export const FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/branchCode`;
export const setBranchCodeValue = value => ({type: FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE, value});
export const FUNERAL_CLAIM_SET_BRANCH_CODE_VALID = `${FUNERAL_CLAIM_SET_VALID}/branchCode`;
export const setBranchCodeValid = error => ({type: FUNERAL_CLAIM_SET_BRANCH_CODE_VALID, error});

// Bank Account Number
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankAccountNumber`;
export const setBankAccountNumberValue = value => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE, value});
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID = `${FUNERAL_CLAIM_SET_VALID}/bankAccountNumber`;
export const setBankAccountNumberValid = error => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALID, error});

// Bank Account Type
export const FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE = `${FUNERAL_CLAIM_SET_VALUE}/bankAccountType`;
export const setBankAccountTypeValue = value => ({type: FUNERAL_CLAIM_SET_BANK_ACCOUNT_TYPE_VALUE, value});

// Agree to Terms
export const FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS = `${FUNERAL_CLAIM_TOGGLE}/agreeToTerms`;
export const toggleAgreetoTerms = () => ({ type: FUNERAL_CLAIM_TOGGLE_AGREE_TO_TERMS });

export const FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER = `${FUNERAL_CLAIM_SET_VALUE}/openSideDrawer`;
export const toggleFuneralClaimSideDrawer = value => ({ type: FUNERAL_CLAIM_TOGGLE_SIDE_DRAWER, value });