import { getIsApiPending, getBiasharaFlexiCoverDetailsForm, getBiasharaFlexiCoverDetailsReferenceData } from '../../../selectors/biasharaFlexi/coverDetails';
import {
    apiTransitionShareQuoteForm,
    shareQuoteSuccess,
    shareQuoteFailure
} from '../../../actions/biasharaFlexi/quote';
import { getAccessToken } from '../../../selectors/biasharaFlexi/payment';
import axios from 'axios';

export const shareQuoteService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionShareQuoteForm.pending());
            try {
                const resp = await share(store.getState());
                store.dispatch(shareQuoteSuccess(resp.data));
                store.dispatch(apiTransitionShareQuoteForm.success());
            } catch (e) {
                console.error(e);
                store.dispatch(apiTransitionShareQuoteForm.failure());
                store.dispatch(shareQuoteFailure(e));
            }
        }
    })();
    return next(action);
};

const shareQuotePayload = (state) => {
    const quotation = getBiasharaFlexiCoverDetailsForm(state);

    const payload = {
        number_of_staff: quotation.employeesNumber.value,
        benefitOption: quotation.benefitAmount.value,
        emailAddress: quotation.shareEmail.value,
        countryCode: "KE"
    };

    // Change payload here
    return {
        ...payload,
    };
};

const share = async (state) => {
    const loginData = getAccessToken(state);
    try {
        const url = '/om-api/biashara-flexi/share-quote/kenya';
        return await axios.post(url, shareQuotePayload(state), {
            headers: {
                'Content-Type': 'application/json',
                accessToken: loginData.accessToken,
                Cookie: 'devEnvironment=ea-qa',
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
