import {
    setSelectedTravelPlanValid,
    ROA_TRAVEL_INSURANCE_SELECTED_VALUE,
} from '../../../actions/roaTravelInsurance/travelPlan';

import { validationIsEqual } from '../status';

let prevValidationAction = null;
export const travelPlanFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case ROA_TRAVEL_INSURANCE_SELECTED_VALUE:
                error = !action.value ? 'Please make a selection' : null;
                return setSelectedTravelPlanValid(error);

            default:
                return null;
        }
    })();

    if (
        !validationAction ||
        validationIsEqual(validationAction, prevValidationAction)
    ) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
