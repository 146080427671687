import {
    transitionFutureValueOfSavings,
} from "../../../actions/savingsCalculator/theFutureValueOfYourSavings"

import {
    getFutureValueOfSavingsForm,
} from "../../../selectors/savingsCalculator/theFutureValueOfYourSavings"
import {transitionScrolling} from "../../../actions/savingsCalculator/scrolling"
import {FIELD_STATUS} from "../../../reducers/status";

export const theFutureValueOfYourSavingsRules = (store, next, action) => {
    const result = next(action);

    const theFutureValueOfSavingsForm = getFutureValueOfSavingsForm(store.getState());
    const theFutureValueOfSavingsValid = validateFormDetails(theFutureValueOfSavingsForm);
    const formAction = theFutureValueOfSavingsValid
        ? transitionFutureValueOfSavings.formValid()
        : transitionFutureValueOfSavings.formInvalid();

    store.dispatch(formAction);

    return result;
};

const validateFormDetails = formFields => (
    formFields.expectedEndDate && formFields.expectedEndDate.status === FIELD_STATUS.VALID &&
    formFields.currentSavings && formFields.currentSavings.status === FIELD_STATUS.VALID &&
    formFields.expectedMonthlyContributions && formFields.expectedMonthlyContributions.status === FIELD_STATUS.VALID
);