import { getQuotationForm } from '../../../selectors/roaCarInsurance/quotation';
import validate from '../../utils/formValidators';
import { transitionQuotationForm,transitionQuotationFormNg } from '../../../actions/roaCarInsurance/quotation';

export const quotationFormRules = (store, next, action) => {
    const quotationForm = getQuotationForm(store.getState());
    const isFormValid = validate(quotationForm, [
        'age',
        'makeAndModel',
        'noOfAccidents',
        'yearOfManufacture',
    ]);
    const formAction = isFormValid
        ? transitionQuotationForm.formValid()
        : transitionQuotationForm.formInvalid();
    store.dispatch(formAction)
	return next(action)
};

export const quotationFormRulesNg = (store, next, action) => {
    const quotationForm = getQuotationForm(store.getState());
    const isFormValid = validate(quotationForm, [
        'estimatedValue',
        'manufactureYear',
        'vehicleMake',
        'vehicleModel',
        'vehicleUse'
    ]);
    const formAction = isFormValid ? transitionQuotationFormNg.formValid() : transitionQuotationFormNg.formInvalid()
    store.dispatch(formAction)
    return next(action)
};
