import {
    IFRAME_RESPONSE_DATA_SET_VALUE,
    IFRAME_VALID_TRANSITION_TRIGGER,
    IFRAME_LOADED_SET_VALUE,
    setOpenSomethingLooksWrongModal,
    setVerificationStatus,
    setVerificationFailedCount, 
    setIidentifiiRequestIdValue,
    setOpenLoadingModal
} from "../../../actions/customerVerificationService/selfie";
import { setActivePageIndex } from "../../../actions/customerVerificationService/routing";
import {getVerificationFailedCount, getIframeEventData, getIframeLoaded} from "../../../selectors/customerVerificationService/selfie"
import {getServiceProvider} from "../../../selectors/customerVerificationService/consent"
import {
    IFRAME_SUCCESS_EVENTS,
    SERVICE_PROVIDERS,
    CONTACTABLE_CAMERA_FAILURE_MESSAGE, IIDENTIFII_RETRIEVE_LIVENESS_DELAY,
    PAGE_INDICES
} from "../../../actions/customerVerificationService/types";

export const selfieRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    // If Iframe loaded then close loading model
    if (action.type === IFRAME_LOADED_SET_VALUE){
        store.dispatch(setOpenLoadingModal(false));
    }

    // This will check if the iframe is loaded after 10 seconds
    // if not it will redirect to KBA    
    if (action.type === IFRAME_VALID_TRANSITION_TRIGGER){
        const iframeLoaded = getIframeLoaded(state);
        if(iframeLoaded === false) {
            store.dispatch(setActivePageIndex(PAGE_INDICES.KBA_INTERLUDE))
        }
    }

    // Action triggered from selfie page
    if (action.type === IFRAME_RESPONSE_DATA_SET_VALUE){
        const eventData = getIframeEventData(state);
        const serviceProvider = getServiceProvider(state);

        // Handle CONTACTABLE IFrame response
        if (serviceProvider === SERVICE_PROVIDERS.CONTACTABLE) {
            // handle success && failure
            if (eventData.success === IFRAME_SUCCESS_EVENTS.contactable) {
                store.dispatch(setVerificationStatus(true));
            } else {
                // Increment failure
                store.dispatch(setVerificationStatus(false));
                const verificationFailed = getVerificationFailedCount(state) + 1;
                store.dispatch(setVerificationFailedCount(verificationFailed));

                // If failure happens 3 times or more OR  camera access fails,
                // we prompt the user with "something looks wrong modal"
                if (verificationFailed >= 3 || eventData.message === CONTACTABLE_CAMERA_FAILURE_MESSAGE) {
                    store.dispatch(setOpenSomethingLooksWrongModal(true));
                }
            }
        }

        // Handle IIDENTIFII IFrame response
        if (serviceProvider === SERVICE_PROVIDERS.IIDENTIFII) {
            // handle success && failure
            if (eventData.status === IFRAME_SUCCESS_EVENTS.iidentifii) {
                store.dispatch(setIidentifiiRequestIdValue(eventData.properties.requestID));    

                // Identifii requires a delay in order to succesfully retrieve liveness
                setTimeout(() => {
                    store.dispatch(setVerificationStatus(true));
                }, IIDENTIFII_RETRIEVE_LIVENESS_DELAY);

            } else {
                // Increment failure
                store.dispatch(setVerificationStatus(false));

                
                const verificationFailed = getVerificationFailedCount(state) + 1;
                store.dispatch(setVerificationFailedCount(verificationFailed));

                // If failure happens 3 times or more
                // Iidentifii camera access failure is handled by the iframe, user clicks cancel
                // which will then trigger this modal
                // we prompt the user with "something looks wrong modal"
                if (verificationFailed >= 3) {
                    store.dispatch(setOpenSomethingLooksWrongModal(true));
                }
            }
        }
    }

    return result;
};
