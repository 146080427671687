import {
    getAge,
    getRetirementAge,
    getInvestmentSetupForm,
    getTypeOfInvestor,
} from "../../../selectors/raOptimal/investmentSetup";
import {
    resetMonthlyInvestment,
    setMonthlyInvestmentDerivedTerm,
    setMonthlyInvestmentCommittedTerm,
    setRetireAtOptions,
    setOwnPercentageValid,
    resetOwnPercentageValue,
    setInvestorTypeValid,
    setPremiumEscalationValid,
    clearPremiumEscalationSelect,
    resetPremiumEscalationSelect,
    resetInvestorType,
    clearInvestorType,
    INVESTMENT_SETUP_FORM_SET_VALUE,
    NO_INCREASE_SELECT,
    LINK_WITH_INFLATION_SELECT,
    AGE_SET_VALUE,
    RETIRE_AT_SET_VALUE,
    OWN_PERCENTAGE_SET_VALUE,
    MONTHLY_INVESTMENT_SET_VALUE,
    CHOOSE_OWN_PERCENTAGE_SELECT,
    SET_IS_CVS_VERIFIED,
    transitionInvestmentSetup,
} from "../../../actions/raOptimal/investmentSetup";
import {TOGGLE_STATUS} from "../../../reducers/status";
import {transitionScrolling} from "../../../actions/raOptimal/scrolling";
import { RA_OPTIMAL_INVESTMENT_SETUP_SCROLL_ID } from "../../rulesEngine/status";
import { validateSingleSelection } from "@om/validation";
import {getId} from "../../../selectors/raOptimal/personalDetails";
import {getID, getFirstName, getLastName} from "../../../selectors/customerVerificationService/idVerification";
import {setIdValue} from "../../../actions/raOptimal/personalDetails";
import{
    setNameValue,
    setSurnameValue,
} from "../../../actions/raOptimal/captureDetails";

export const investmentSetupRules = (store, next, action) => {
    const result = next(action);
    const state = store.getState();

    // Set values based on CVS
    if(action.type === SET_IS_CVS_VERIFIED) {
        const idNumber = getID(state);
        if(idNumber.value) {
            store.dispatch(setIdValue(idNumber.value));
        }

        const firstName = getFirstName(state);
        if(firstName) {
          store.dispatch(setNameValue(firstName));
        }

        const lastName = getLastName(state);
        if(lastName) {
           store.dispatch(setSurnameValue(lastName));
        }
    }

    if (action.type === AGE_SET_VALUE) {
        store.dispatch(setRetireAtOptions(null));
        store.dispatch(resetMonthlyInvestment());
        store.dispatch(resetPremiumEscalationSelect());
        store.dispatch(resetInvestorType());

        const idValue = getId(state);
        if (idValue && idValue.value) {
            store.dispatch(setIdValue(idValue.value));
        }
    }

    if (action.type === RETIRE_AT_SET_VALUE) {
        store.dispatch(resetMonthlyInvestment(null));
        store.dispatch(resetPremiumEscalationSelect());
        store.dispatch(resetOwnPercentageValue());
        store.dispatch(resetInvestorType());
        const retirementAge = getRetirementAge(state);
        const currentAge = getAge(state);

        if (retirementAge.status === 'valid') {
            const derivedTerm = (+retirementAge.value) - (+currentAge.value);
            store.dispatch(setMonthlyInvestmentDerivedTerm(derivedTerm));

            const committedTerm = derivedTerm > 25 ? 25 : derivedTerm;
            store.dispatch(setMonthlyInvestmentCommittedTerm(committedTerm));
        }
    }

    if (action.type === MONTHLY_INVESTMENT_SET_VALUE) {
        store.dispatch(resetPremiumEscalationSelect());
        store.dispatch(resetOwnPercentageValue());
        store.dispatch(resetInvestorType());
    }

    if (action.type === LINK_WITH_INFLATION_SELECT ||
        action.type === NO_INCREASE_SELECT ||
        action.type === CHOOSE_OWN_PERCENTAGE_SELECT) {
        store.dispatch(resetOwnPercentageValue());
        store.dispatch(resetInvestorType());
    }

    if (action.type === INVESTMENT_SETUP_FORM_SET_VALUE) {
        validateInvestmentSetupForm(store);
        setScrollError(store);
    }

    const investmentSetupFormValid = checkValidInvestor(store);
    store.dispatch(investmentSetupFormValid
        ? transitionInvestmentSetup.formValid()
        : transitionInvestmentSetup.formInvalid());

    return result;
};

const setScrollError = store => {
    const keyArray = RA_OPTIMAL_INVESTMENT_SETUP_SCROLL_ID;
    const investmentSetupForm = getInvestmentSetupForm(store.getState());

    for (let i = 0; i < keyArray.length; i++) {
        if (keyArray[i] in investmentSetupForm && investmentSetupForm[keyArray[i]].error) {
            store.dispatch(transitionScrolling.scrollToId(keyArray[i]));
            break;
        }
    }
};


const checkValidInvestor = store => {
    const investorType = getTypeOfInvestor(store.getState());
    return (investorType.adventurous === true || investorType.comfortable === true )
};

const validateInvestmentSetupForm = store => {
    const investmentSetupForm = getInvestmentSetupForm(store.getState());

    if (investmentSetupForm.monthlyInvestment.status !== 'valid')
        return

    if (investmentSetupForm.yearlyIncrease.linkWithInflation === TOGGLE_STATUS.UNTOUCHED &&
        investmentSetupForm.yearlyIncrease.chooseOwnPercentage === TOGGLE_STATUS.UNTOUCHED &&
        investmentSetupForm.yearlyIncrease.noIncrease === TOGGLE_STATUS.UNTOUCHED) {
        store.dispatch(clearPremiumEscalationSelect());
        const errorMessage = validateSingleSelection(null, 'Premium escalation');
        store.dispatch(setPremiumEscalationValid(errorMessage));
        return;
    }

    if (investmentSetupForm.yearlyIncrease.chooseOwnPercentage === true &&
        investmentSetupForm.ownPercentage.value === null) {
        const error = validateSingleSelection(null, 'Percentage');
        store.dispatch(setOwnPercentageValid(error));
        return;
    }

    if (investmentSetupForm.typeOfInvestor.comfortable === TOGGLE_STATUS.UNTOUCHED &&
        investmentSetupForm.typeOfInvestor.adventurous === TOGGLE_STATUS.UNTOUCHED) {
        store.dispatch(clearInvestorType());
        const errorMessage = validateSingleSelection(null, 'Type of investor');
        store.dispatch(setInvestorTypeValid(errorMessage));
    }
}
