import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';
import {
    REINITIALIZE,
    SET_CAR_MAKE_VALUE,
    SET_CAR_MODEL_VALUE,
    SET_CAR_MAKE_VALID,
    SET_CAR_MODEL_VALID,
    SET_YEAR_OF_MANUFACTURE_VALUE,
    SET_YEAR_OF_MANUFACTURE_VALID,
    SET_COVER_START_DATE_VALUE,
    SET_COVER_START_DATE_VALID,
    SET_IS_CAR_VALUED_VALUE,
    SET_IS_CAR_VALUED_VALID,
    SET_CAR_VALUER_VALUE,
    SET_CAR_VALUER_VALID,
    SET_CAR_USE_VALUE,
    SET_CAR_USE_VALID,
    SET_HAS_ALARM_VALUE,
    SET_HAS_ALARM_VALID,
    SET_VALUATION_VALUE,
    SET_VALUATION_VALID,
    SET_VIOLENCE_TERORISM_VALUE,
    SET_VIOLENCE_TERORISM_VALID,
    FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM,
    FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM,
    API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM,
    COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA,
    SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS,
    SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE,
    CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS,
    UG_MOTOR_QUOTE_ADD_COVER,
    SET_EXCESS_PROTECTOR_VALUE,
    SET_EXCESS_PROTECTOR_VALID,
    SET_ALTERNATIVE_ACCOMODATION_VALUE,
    SET_ALTERNATIVE_ACCOMODATION_VALID,
    SET_CAR_HIRE_VALUE,
    SET_CAR_HIRE_VALID,
    SET_UNCOMMON_RISK_VALUE,
    SET_UNCOMMON_RISK_VALID,
    UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE,
    UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
    FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS,
    SHARE_MOTOR_INSURANCE_QUOTE_FAILURE,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    SET_SELECTED_COVER_VALID,
    SET_SELECTED_COVER_VALUE,
    SET_IS_RARE_MODEL_VALUE,
    SET_IS_RARE_MODEL_VALID,
    SET_OTHER_VALUER_VALID,
    SET_OTHER_VALUER_VALUE,
    SET_TRACKING_SYSTEM_VALID,
    SET_TRACKING_SYSTEM_VALUE,
    SET_COURTESY_CAR_AMOUNT_VALUE,
    SET_COURTESY_CAR_VALID,
    SET_COURTESY_CAR_VALUE,
    SET_COURTESY_CAR_AMOUNT_VALID,
    TOKEN_IS_EXPIRED,
    SET_VEHICLE_TYPE_VALID,
    SET_VEHICLE_TYPE_VALUE,
    SET_CAR_MAKES_LIST_VALUE,
    SET_CAR_MAKES_LIST_VALID,
    SET_CAR_MODEL_LIST_VALUE,
    SET_CAR_MODEL_LIST_VALID,
    API_FAILURE_TRANSITIONS_CAR_MAKES,
    API_PENDING_TRANSITIONS_CAR_MAKES,
    API_SUCCESS_TRANSITIONS_CAR_MAKES,
    API_FAILURE_TRANSITIONS_CAR_MODELS,
    API_PENDING_TRANSITIONS_CAR_MODELS,
    API_SUCCESS_TRANSITIONS_CAR_MODELS,
    API_PENDING_TRANSITION_VALUERS,
    API_SUCCESS_TRANSITION_VALUERS,
    API_FAILURE_TRANSITION_VALUERS,
    SET_VALUERS_LIST_SUCCESS,
    SET_PERSONAL_ACCIDENT_VALID,
    SET_PERSONAL_ACCIDENT_VALUE,
    SET_OUTSIDE_EA_VALUE,
    SET_OUTSIDE_EA_VALID,
    SET_VALUATION_DISPLAY_VALUE,
    SET_SHARE_EMAIL_BASE_64_VALUE,
    SET_ENABLE_DOWNLOAD_QUOTE_VALUE,
    SET_FIRST_TIME_PURCHASE_VALUE,
    SET_FIRST_TIME_PURCHASE_VALID,
    SET_CAR_HIRE_DAYS_VALUE,
    SET_CAR_HIRE_DAYS_VALID,
    SET_ALTERNATIVE_ACCOMODATION_DAYS_VALUE,
    SET_ALTERNATIVE_ACCOMODATION_DAYS_VALID,
    SET_EXCESS_AMOUNT_VALUE,
    SET_EXCESS_AMOUNT_VALID,
    SET_FULL_NAME_VALUE,
    SET_FULL_NAME_VALID,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    ID_NUMBER_SET_VALUE,
    ID_NUMBER_SET_VALID,
} from '../../actions/ugMotorInsurance/quote';

const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

export const initQuote = {
    valuersApiCallStatus: null,
    sidebarOpen: false,
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    carMakesApiStatus: null,
    carModelsApiStatus: null,
    quoteReferenceData: null,
    addedCover: null,
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    quoteShared: null,
    isTokenExpired: false,
    valuers: null,
    enableDownloadQuote: false,
    ugCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Motor Insurance Uganda',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    premiumCalculationUg: {
        value: null,
        status: null,
        error: null,
    },

    form: {
        carMake: { value: null, error: null, status: null },
        carModel: { value: null, error: null, status: null },
        yearOfManufacture: { value: null, error: null, status: null },
        coverStartDate: { value: null, error: null, status: null },
        isCarValued: { value: null, error: null, status: null },
        carValuer: { value: null, error: null, status: null },
        carUse: { value: 'private', error: null, status: 'valid' },
        hasAlarm: { value: null, error: null, status: null },
        valuation: { value: null, displayValue: null, error: null, status: null },
        violenceAndTerrorism: { value: false, error: null, status: null },
        excessProtector: { value: false, error: null, status: null },
        carHire: { value: false, error: null, status: null },
        alternativeAccomodation: { value: false, error: null, status: null },
        uncommonRisk: { value: false, error: null, status: null },
        shareEmail: { value: null, error: null, status: null },
        shareEmailBase64: { value: null, error: null, status: null },
        carValue: { value: null, error: null, status: null },
        selectedCover: { value: 'motorComprehensive', error: null, status: null },
        isCarRare: { value: 'no', error: null, status: 'valid' },
        otherValuer: { value: null, error: null, status: null },
        trackingSystem: { value: null, error: null, status: null },
        courtesyCar: { value: null, error: null, status: null },
        courtesyCarAmount: { value: 30000, error: null, status: null },
        vehicleType: { value: null, error: null, status: null },
        carModels: { value: null, error: null, status: null },
        carMakes: { value: null, error: null, status: null },
        personalAccident: { value: null, error: null, status: null },
        outsideEa: { value: null, error: null, status: null },
        firstTimePurchase: { value: 'no', error: null, status: FIELD_STATUS.VALID },
        carHireDays: { value: '14', error: null, status: FIELD_STATUS.VALID },
        alternativeAccomodationDays: { value: '5', error: null, status: FIELD_STATUS.VALID },
        excessAmount: { value: null, displayValue: null, error: null, status: null },
        fullName: { value: null, error: null, status: null },
        phoneNumber: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        idNumber: { value: null, error: null, status: null },
    },
};

export default (state = initQuote, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };

        case FORM_VALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_MOTOR_INSURANCE_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        case SET_FULL_NAME_VALUE:
            return updateFormField('fullName', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_FULL_NAME_VALID:
            return updateFormField('fullName', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case ID_NUMBER_SET_VALUE:
            return updateFormField('idNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case ID_NUMBER_SET_VALID:
            return updateFormField('idNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMAIL_SET_VALUE:
            return updateFormField('email', (field) => ({
                ...field,
                value: action.value,
            }));

        case EMAIL_SET_VALID:
            return updateFormField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SET_CAR_MODEL_VALUE:
            return updateFormField('carModel', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MAKE_VALUE:
            return updateFormField('carMake', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CAR_MODEL_VALUE:
            return updateFormField('carModel', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CAR_MODEL_VALUE && gatsbyStack === 'ke':
            return updateFormField('carMake', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MAKE_VALID:
            return updateFormField('carMake', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_CAR_MODEL_VALID:
            return updateFormField('carModel', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_YEAR_OF_MANUFACTURE_VALUE:
            return updateFormField('yearOfManufacture', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_YEAR_OF_MANUFACTURE_VALID:
            return updateFormField('yearOfManufacture', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_COURTESY_CAR_VALUE:
            return updateFormField('courtesyCar', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_COURTESY_CAR_VALID:
            return updateFormField('courtesyCar', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_COURTESY_CAR_AMOUNT_VALUE:
            return updateFormField('courtesyCarAmount', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_COURTESY_CAR_AMOUNT_VALID:
            return updateFormField('courtesyCarAmount', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_TRACKING_SYSTEM_VALUE:
            return updateFormField('trackingSystem', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_TRACKING_SYSTEM_VALID:
            return updateFormField('trackingSystem', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_COVER_START_DATE_VALUE:
            return updateFormField('coverStartDate', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_COVER_START_DATE_VALID:
            return updateFormField('coverStartDate', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_IS_CAR_VALUED_VALUE:
            return updateFormField('isCarValued', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_IS_CAR_VALUED_VALID:
            return updateFormField('isCarValued', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_CAR_VALUER_VALUE:
            return updateFormField('carValuer', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CAR_VALUER_VALID:
            return updateFormField('carValuer', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_CAR_USE_VALUE:
            return updateFormField('carUse', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_CAR_USE_VALID:
            return updateFormField('carUse', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));
        case SET_HAS_ALARM_VALUE:
            return updateFormField('hasAlarm', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_HAS_ALARM_VALID:
            return updateFormField('hasAlarm', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_SELECTED_COVER_VALUE:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_SELECTED_COVER_VALID:
            return updateFormField('selectedCover', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_VALUATION_VALUE:
            return updateFormField('valuation', (field) => ({
                ...field,
                value: action.value,
            }));
        case SET_VALUATION_DISPLAY_VALUE:
            return updateFormField('valuation', (field) => ({
                ...field,
                displayValue: action.value,
            }));
        case SET_VALUATION_VALID:
            return updateFormField('valuation', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_VIOLENCE_TERORISM_VALUE:
            return updateFormField('violenceAndTerrorism', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_VIOLENCE_TERORISM_VALID:
            return updateFormField('violenceAndTerrorism', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_EXCESS_PROTECTOR_VALUE:
            return updateFormField('excessProtector', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_EXCESS_PROTECTOR_VALID:
            return updateFormField('excessProtector', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_ALTERNATIVE_ACCOMODATION_VALUE:
            return updateFormField('alternativeAccomodation', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_ALTERNATIVE_ACCOMODATION_VALID:
            return updateFormField('alternativeAccomodation', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_HIRE_VALUE:
            return updateFormField('carHire', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_HIRE_VALID:
            return updateFormField('carHire', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_PERSONAL_ACCIDENT_VALUE:
            return updateFormField('personalAccident', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_PERSONAL_ACCIDENT_VALID:
            return updateFormField('personalAccident', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_UNCOMMON_RISK_VALUE:
            return updateFormField('uncommonRisk', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_IS_RARE_MODEL_VALUE:
            return updateFormField('isCarRare', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_IS_RARE_MODEL_VALID:
            return updateFormField('isCarRare', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OUTSIDE_EA_VALUE:
            return updateFormField('outsideEa', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_OUTSIDE_EA_VALID:
            return updateFormField('outsideEa', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_FIRST_TIME_PURCHASE_VALUE:
            return updateFormField('firstTimePurchase', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_FIRST_TIME_PURCHASE_VALID:
            return updateFormField('firstTimePurchase', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_HIRE_DAYS_VALUE:
            return updateFormField('carHireDays', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_HIRE_DAYS_VALID:
            return updateFormField('alternativeAccomodationDays', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_ALTERNATIVE_ACCOMODATION_DAYS_VALUE:
            return updateFormField('alternativeAccomodationDays', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_ALTERNATIVE_ACCOMODATION_DAYS_VALID:
            return updateFormField('alternativeAccomodationDays', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_EXCESS_AMOUNT_VALUE:
            return updateFormField('excessAmount', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_EXCESS_AMOUNT_VALID:
            return updateFormField('excessAmount', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_VEHICLE_TYPE_VALUE:
            return updateFormField('vehicleType', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_VEHICLE_TYPE_VALID:
            return updateFormField('vehicleType', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_OTHER_VALUER_VALUE:
            return updateFormField('otherValuer', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_OTHER_VALUER_VALID:
            return updateFormField('otherValuer', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_UNCOMMON_RISK_VALID:
            return updateFormField('uncommonRisk', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        // Valuers
        case SET_VALUERS_LIST_SUCCESS:
            return {
                ...state,
                valuers: action.value,
            };

        // Sidebar open
        case UG_MOTOR_INSURANCE_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        // Share modal
        case UG_MOTOR_INSURANCE_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_MOTOR_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_SHARE_EMAIL_BASE_64_VALUE:
            return updateFormField('shareEmailBase64', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MAKES_LIST_VALUE:
            return updateFormField('carMakes', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MAKES_LIST_VALID:
            return updateFormField('carMakes', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_CAR_MODEL_LIST_VALUE:
            return updateFormField('carModels', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_CAR_MODEL_LIST_VALID:
            return updateFormField('carModels', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case TOKEN_IS_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.value,
            };

        case SET_ENABLE_DOWNLOAD_QUOTE_VALUE:
            return {
                ...state,
                enableDownloadQuote: action.value,
            };

        // API Calls
        case API_PENDING_TRANSITION_VALUERS:
            return {
                ...state,
                valuersApiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_VALUERS:
            return {
                ...state,
                valuersApiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_VALUERS:
            return {
                ...state,
                valuersApiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_MOTOR_INSURANCE_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_CAR_MAKES:
            return {
                ...state,
                carMakesApiStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_CAR_MAKES:
            return {
                ...state,
                carMakesApiStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_CAR_MAKES:
            return {
                ...state,
                carMakesApiStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_CAR_MODELS:
            return {
                ...state,
                carModelsApiStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_CAR_MODELS:
            return {
                ...state,
                carModelsApiStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_CAR_MODELS:
            return {
                ...state,
                carModelsApiStatus: API_CALL_STATUS.FAILURE,
            };

        // Submit user data
        case COLLECT_MOTOR_INSURANCE_QUOTE_FORM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case SUBMIT_MOTOR_INSURANCE_QUOTE_SUCCESS:
            return {
                ...state,
                quoteReferenceData: action.value,
            };
        case UG_MOTOR_QUOTE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };
        case SUBMIT_MOTOR_INSURANCE_QUOTE_FAILURE:
            return {
                ...state,
                quoteReferenceData: action.error,
            };

        case CALCULATE_MOTOR_INSURANCE_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumCalculationUg: {
                    ...state.premiumCalculationUg,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        // Reset state
        case REINITIALIZE:
            return initQuote;
        default:
            return state;
    }
};
