export const GET_ADVISORS_DATA = 'publicWeb/faa/getAdvisorData';

export const FAA_SET_VALUE = 'publicWeb/faa/setValue/';
export const FAA_SET_VALID = 'publicWeb/faa/setValid/';

export const FAA_SEARCH_CRITERIA_SET_VALUE = FAA_SET_VALUE + 'searchCriteria';
export const FAA_SEARCH_CRITERIA_SET_VALID = FAA_SET_VALID + 'searchCriteria';
export const FAA_LOCATION_STATUS_SET_VALUE = FAA_SET_VALUE + 'locationStatus';
export const FAA_CHOSEN_LONG_SET_VALUE = FAA_SET_VALUE + 'chosenLong';
export const FAA_CHOSEN_LAT_SET_VALUE = FAA_SET_VALUE + 'chosenLat';
export const FAA_INCOME_BRACKET_SET_VALUE = FAA_SET_VALUE + 'incomeBracket';
export const FAA_ASSISTANCE_TYPE_SET_VALUE = FAA_SET_VALUE + 'assistanceType';
export const FAA_LOCATION_ENABLED_SET_VALUE = FAA_SET_VALUE + 'locationEnabled';
export const FAA_LOCATION_TYPE_SET_VALUE = FAA_SET_VALUE + 'locationType';
export const FAA_DISCUSSION_OPTIONS_SET_VALUE = FAA_SET_VALUE + 'discussionOptionsSelected';
export const FAA_DATA_SET_VALUE = FAA_SET_VALUE + 'findAnAdvisorData';

export const setSearchCriteriaValue = value => ({type: FAA_SEARCH_CRITERIA_SET_VALUE, value});
export const setChosenLatValue = value => ({type: FAA_CHOSEN_LAT_SET_VALUE, value});
export const setChosenLongValue = value => ({type: FAA_CHOSEN_LONG_SET_VALUE, value});
export const setIncomeBracketValue = value => ({type: FAA_INCOME_BRACKET_SET_VALUE, value});
export const setAssistanceTypeValue = value => ({type: FAA_ASSISTANCE_TYPE_SET_VALUE, value});
export const setTestedLocationStatusValue = value => ({type: FAA_LOCATION_STATUS_SET_VALUE, value});
export const setLocationEnabledValue = value => ({type: FAA_LOCATION_ENABLED_SET_VALUE, value});
export const setLocationTypeValue = value => ({type: FAA_LOCATION_TYPE_SET_VALUE, value});
export const setDiscussionOptionsSelectedValue = value => ({type: FAA_DISCUSSION_OPTIONS_SET_VALUE, value});

export const getAdvisorsData = payload => ({ type: GET_ADVISORS_DATA, payload });
export const setAdvisorsData = value => ({ type: FAA_DATA_SET_VALUE, value });