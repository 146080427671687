import { createSelector } from "reselect";
import { getIncomeTaxCalculator } from "./index";
import { FORM_STATUS, FIELD_STATUS } from "../../reducers/status";
import { getCountryCodeSelector } from "../../../site-builder/src/components/currencies";

export const getAdvancedCalculator = createSelector(
    getIncomeTaxCalculator,
    incomeTaxCalculator => incomeTaxCalculator.advancedCalculator
);

export const getAdvancedCalculatorFormValid = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.formStatus === FORM_STATUS.VALID
);

export const getAdvancedCalculatorForm = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.form
);

export const getShowRecommendation = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.showRecommendation
);

export const getShowFiedDetailsStatus = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.showFiedDetailsStatus
);

export const getAdvancedCalculatorReferenceData = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.referenceData
);

export const getAdvancedCalculatorTaxBrackets = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.referenceData.taxBrackets
);

const getAdvancedCalculatorTaxRebates = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.referenceData.taxRebates
)

const makeGetField = (fieldId, subField) => createSelector(
    getAdvancedCalculatorForm,
    form => !subField ? form[fieldId] : form[fieldId][subField]
);

const getMaxMin = (x, y, type) => {
    if (type === 'max') {
        return x > y ? x : y;
    }
    return x < y ? x : y;
}

export const getTaxResult = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.result
);

export const getAgeGroups = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.ageGroups
);

export const getFrequencyOptions = createSelector(
    getAdvancedCalculator,
    advancedCalculator => advancedCalculator.frequencyOptions
);



export const getAgeValue = makeGetField('age');
export const getIncomeFrequencyValue = makeGetField('incomeFrequency');
export const getIncomeValue = makeGetField('incomeValue');
export const getOtherIncomeValue = makeGetField('otherIncome', 'income');
export const getOtherIncomeDeduction = makeGetField('otherIncome', 'deduction');
export const getOtherIncomeToggle = makeGetField('otherIncome', 'toggleStatus');
export const getMedicalAidOwnContribution = makeGetField('medicalAid', 'ownContribution');
export const getMedicalAidEmployerContribution = makeGetField('medicalAid', 'employerContribution');
export const getMedicalAidDependents = makeGetField('medicalAid', 'medicalAidDependents');
export const getMedicalAidToggle = makeGetField('medicalAid', 'toggleStatus');
export const getPensionFundOwnContribution = makeGetField('pensionFund', 'ownContribution');
export const getPensionFundEmployerContribution = makeGetField('pensionFund', 'employerContribution');
export const getPensionFundToggle = makeGetField('pensionFund', 'toggleStatus');
export const getProvidentFundOwnContribution = makeGetField('providentFund', 'ownContribution');
export const getProvidentFundEmployerContribution = makeGetField('providentFund', 'employerContribution');
export const getProvidentFundToggle = makeGetField('providentFund', 'toggleStatus');
export const getRetirementAnnuityValue = makeGetField('retirementAnnuity');

export const getFieldValue = (field, subField) => createSelector (
    getAdvancedCalculatorForm,
    getIncomeFrequencyValue,
    (formFields, incomeFrequency) => {
        if (!formFields[field].toggleStatus) {
            return 0;
        }
        if (subField) {
            if (formFields[field][subField].status === FIELD_STATUS.VALID && formFields[field][subField].value !== "") 
                return  incomeFrequency.value === 'Monthly' && subField !== 'medicalAidDependents' ? parseInt(formFields[field][subField].value) * 12 : parseInt(formFields[field][subField].value);
            if (formFields[field][subField].value === null || formFields[field][subField].value === "")
                return 0;
        }
        if (formFields[field].status === FIELD_STATUS.VALID && formFields[field].value !== "")
                return incomeFrequency.value === 'Monthly' ? parseInt(formFields[field].value) * 12: parseInt(formFields[field].value);
        if (formFields[field].value === null || formFields[field].value === "")
            return 0;
    }
)

export const getCalculationInfo = createSelector(
    getAdvancedCalculatorReferenceData,
    referenceData => referenceData.infoData.resultCard,
)

export const getMedicalAidTotal = createSelector(
    getFieldValue('medicalAid', 'ownContribution'),
    getFieldValue('medicalAid', 'employerContribution'),
    (medicalAidOne,  medicalAidTwo) => medicalAidOne + medicalAidTwo > 0 ? (medicalAidOne + medicalAidTwo) / 12 : 0
)

export const getFringeBenefits = createSelector(
    getFieldValue('pensionFund', 'employerContribution'),
    getFieldValue('providentFund', 'employerContribution'),
    getFieldValue('medicalAid', 'employerContribution'),
    (pensionFund, providentFund, medicalAid) => pensionFund + providentFund + medicalAid
)

export const getRemunerationForPAYE = createSelector(
    getIncomeValue,
    getFringeBenefits,
    getIncomeFrequencyValue,
    (incomeValue, fringeBenefits, incomeFrequency) => incomeFrequency.value === 'Monthly' ? 
       (parseFloat(incomeValue.value) * 12 ) + fringeBenefits : parseFloat(incomeValue.value) + fringeBenefits
)

export const getMaxRetirementFund = createSelector(
    getRemunerationForPAYE,
    getAdvancedCalculatorReferenceData,
    getFieldValue('otherIncome', 'income'),
    getFieldValue('otherIncome', 'deduction'),
    (remunerationForPAYE, referenceData, otherIncomeOne, otherIncomeTwo) => {
        const yearIncome = remunerationForPAYE + otherIncomeOne - otherIncomeTwo;
        const limitRemuneration = referenceData.advancedData.constants.limitRemuneration;
        const monetaryCape = referenceData.advancedData.monetaryCape;
        const firstLimit = limitRemuneration * (getMaxMin(remunerationForPAYE, yearIncome, 'max'));
        const secondLimit = getMaxMin(getMaxMin(remunerationForPAYE, monetaryCape, 'min'), limitRemuneration * remunerationForPAYE, 'min');
        return getMaxMin(firstLimit, secondLimit, 'min');
    }
)

export const getRetirementFund = createSelector(
    getFieldValue('pensionFund', 'ownContribution'),
    getFieldValue('providentFund', 'ownContribution'),
    getFieldValue('pensionFund', 'employerContribution'),
    getFieldValue('providentFund', 'employerContribution'),
    getFieldValue('retirementAnnuity'),
    getMaxRetirementFund,
    (pensionFundOne, providentFundOne, pensionFundTwo, providentFundTwo, retirementAnnuity, maxRetirementFund) => {
        const fund = pensionFundOne + providentFundOne + retirementAnnuity + pensionFundTwo + providentFundTwo;
        return getMaxMin(fund, maxRetirementFund, 'min');
    }
        
) 

export const getYearIncome = createSelector(
    getIncomeValue,
    getIncomeFrequencyValue,
    getRetirementFund,
    getFringeBenefits,
    getFieldValue('otherIncome', 'income'),
    getFieldValue('otherIncome', 'deduction'),
    (incomeValue, incomeFrequency,  retirementFund, frindgeBenefits, otherIncomeOne, otherIncomeTwo) => 
        incomeFrequency.value === 'Monthly' ? 
        ((parseFloat(incomeValue.value) * 12 ) + otherIncomeOne + frindgeBenefits) - (otherIncomeTwo + retirementFund):
        (parseFloat(incomeValue.value) + otherIncomeOne + frindgeBenefits) - (otherIncomeTwo + retirementFund)
)

export const getMedicalAidCredit = createSelector(
    getAdvancedCalculatorReferenceData,
    getFieldValue('medicalAid', 'medicalAidDependents'),
    getFieldValue('medicalAid', 'ownContribution'),
    (referenceData, dependents, medicalAidOne) => {
        const {mainDependents, otherDependents} = referenceData.advancedData.medicalAidCredits;
        if (medicalAidOne === 0) {
            return 0;
        }
        if (dependents === 0) {
            return getMaxMin(mainDependents, medicalAidOne, 'min');
        }
        return getMaxMin((mainDependents * 2) + (otherDependents * (dependents - 1)), medicalAidOne, 'min');
    }
)

export const getMedicalAidForCreditExpenses = createSelector(
    getMedicalAidCredit,
    getAdvancedCalculatorTaxRebates,
    getAgeValue,
    getFieldValue('medicalAid', 'ownContribution'),
    getFieldValue('medicalAid', 'employerContribution'),
    (medicalAidCredit, rebates, age, medicalAidOne, medicalAidTwo) => {
        const result = (medicalAidOne + medicalAidTwo) - (medicalAidCredit * rebates[age.value].creditThreshold)
        return result > 0 ? result : 0;
    }
)

export const getExpenseCredit = createSelector(
    getYearIncome,
    getMedicalAidForCreditExpenses,
    getAgeValue,
    getAdvancedCalculatorReferenceData,
    (incomeValue, medicalAidCreditExpenses, age, referenceData) => {
        const firstConstant = referenceData.advancedData.constants.first;
        const secondConstant = referenceData.advancedData.constants.second;
        const thirdConstant = referenceData.advancedData.constants.third;
    
        if (age.value === 'categoryOne') {
           return medicalAidCreditExpenses - ( firstConstant * incomeValue) > 0 ?
           (medicalAidCreditExpenses - (firstConstant * incomeValue)) * secondConstant : 0
        }
        return medicalAidCreditExpenses * thirdConstant;

    }
)

export function findTaxBracket(taxBrackets, annualIncome) {
    return taxBrackets.find(bracket => !bracket.max ?
        annualIncome >= bracket.min:
        annualIncome >= bracket.min && annualIncome <= bracket.max
    )
}

const getTaxBracket = createSelector(
    getCountryCodeSelector,
    getYearIncome,
    getAdvancedCalculatorTaxBrackets,
    (countryCode, annualIncome, taxBrackets)  => findTaxBracket(taxBrackets[countryCode], annualIncome)
);

export const getMonthIncome = createSelector(
    getIncomeValue,
    getIncomeFrequencyValue,
    getFieldValue('otherIncome', 'income'),
    getFieldValue('otherIncome', 'deduction'),
    getFieldValue('pensionFund', 'ownContribution'),
    getFieldValue('providentFund', 'ownContribution'),
    getFieldValue('medicalAid', 'ownContribution'),
    getFieldValue('retirementAnnuity'),
    (income, incomeFrequency, otherIncomeOne, otherIncomeTwo, pensionFund, providentFund, medicalAid, retirementAnnuity) => {
        const yearIncome = incomeFrequency.value === 'Monthly' ? parseFloat(income.value) * 12 : parseFloat(income.value)
        return ((yearIncome + otherIncomeOne) - (otherIncomeTwo + pensionFund + providentFund + medicalAid + retirementAnnuity)) / 12
    }
)


export const getTaxValue = createSelector(
    getYearIncome,
    getTaxBracket,
    getAgeValue,
    getAdvancedCalculatorTaxRebates,
    getMedicalAidCredit,
    getExpenseCredit,
    getMedicalAidTotal,
    (yearIncome, bracket, age, rebates, medicalAidCredit, expenseCredit, medicalAidTotal) => yearIncome && bracket && yearIncome > rebates[age.value].threshold ?
    ((bracket.baseTax - (rebates[age.value].base + expenseCredit) + ((yearIncome - bracket.threshold) * bracket.rate)) / 12) - (medicalAidTotal > 0 ? medicalAidCredit / 12 : 0) : 0
)
