import { createSelector } from 'reselect';
import { PAGE_INDICES } from '../../actions/digitalSavings/types';
import { getDigitalSavingsState } from './index';

const getRouting = createSelector(getDigitalSavingsState, (state) => state.routing);

export const getActivePageIndex = createSelector(
    getRouting,
    routing => routing.activePageIndex,
);
export const getDoDigitalSavingsNavigation = createSelector(
    getRouting,
    routing => routing.doNavigation,
);
export const getDoExitDigitalSavingsNavigation = createSelector(
    getRouting,
    routing => routing.doExitNavigation,
);

export const getLandingPage = createSelector(
    getRouting,
    routing => routing.landingPage,
);


const makeGetIsPage = pageIndex =>
    createSelector(
        getRouting,
        routing => routing.activePageIndex === pageIndex,
    );
export const getIsQuotePage = makeGetIsPage(PAGE_INDICES.QOUTE);
export const getIsPersonalDetailsPage = makeGetIsPage(PAGE_INDICES.PERSONAL_DETAILS);
export const getIsBeneficiariesPage = makeGetIsPage(PAGE_INDICES.BENEFICIARIES);
export const getIsDocumentsPage = makeGetIsPage(
    PAGE_INDICES.UPLOAD_DOCUMENTS,
);
export const getIsPaymentPage = makeGetIsPage(PAGE_INDICES.PAYMENT);

export const getProgressBarIndex = createSelector(
	getIsQuotePage,
	getIsPersonalDetailsPage,
	getIsBeneficiariesPage,
	getIsDocumentsPage,
	getIsPaymentPage,
	(isQuotePage, isPersonalDetailsPage, isBeneficiariesPage, isDocumentsPage, isPaymentPage) => {
		if (isQuotePage) return 0;
		if (isPersonalDetailsPage) return 1;
		if (isBeneficiariesPage) return 2;
		if (isDocumentsPage) return 3;
		if (isPaymentPage) return 4;
	}
);
