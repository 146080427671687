import { FIELD_STATUS, API_CALL_STATUS, FORM_STATUS } from '../status';
import {
    OPEN_DRAWER,
    IDENTITY_TYPE,
} from '../../actions/customerComplaint/type';

import {
    CELLPHONE_NUMBER_SET_VALID,
    CELLPHONE_NUMBER_SET_VALUE,
    COMPLAINT_DESCRIPTION_SET_VALUE,
    COMPLAINT_DESCRIPTION_SET_VALID,
    COMPLAINT_FORM_SEND,
    COMPLAINT_FORM_SEND_FAILURE,
    COMPLAINT_FORM_SEND_SUCCESS,
    COMPLAINT_RELATION_SET_VALUE,
    COMPLAINT_RELATION_SET_VALID,
    EMAIL_SET_VALUE,
    EMAIL_SET_VALID,
    FIRSTNAME_SET_VALID,
    FIRSTNAME_SET_VALUE,
    FORM_INVALID_TRANSITION_COMPLAINT_FORM,
    FORM_VALID_TRANSITION_COMPLAINT_FORM,
    IDENTITY_TYPE_SET_VALID,
    IDENTITY_TYPE_SET_VALUE,
    ID_NUMBER_SET_VALID,
    ID_NUMBER_SET_VALUE,
    PASSPORT_COUNTRY_SET_VALID,
    PASSPORT_COUNTRY_SET_VALUE,
    PASSPORT_NUMBER_SET_VALID,
    PASSPORT_NUMBER_SET_VALUE,
    POLICY_NUMBER_SET_VALID,
    POLICY_NUMBER_SET_VALUE,
    PRODUCT_TYPE_SET_VALID,
    PRODUCT_TYPE_SET_VALUE,
    RESET_STATE_TRANSITION_COMPLAINT_FORM,
    SURNAME_SET_VALUE,
    SURNAME_SET_VALID,
    TITLE_SET_VALUE,
    TITLE_SET_VALID,
    TOGGLE_DRAWER,
    COMPLAINT_TITLE_SET_VALUE,
    COMPLAINT_TITLE_SET_VALID,
    RECEIVER_EMAIL_SET_VALUE,
    COMPLAINT_TOGGLE_SEND_ME_COPY,
} from '../../actions/customerComplaint/complaintArbitration';

export const initComplaintArbitration = {
    openDrawer: null,
    complaintType: null,
    sendComplaintStatus: API_CALL_STATUS.IDLE,
    formStatus: FORM_STATUS.INVALID,
    receiverEmailAddress: null,
    form: {
        title: { value: null, error: null, status: null },
        firstName: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        cellphoneNumber: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        identityType: { value: null, error: null, status: FIELD_STATUS.VALID },
        idNumber: { value: null, error: null, status: null },
        passportNumber: { value: null, error: null, status: FIELD_STATUS.VALID },
        passportCountry: { value: null, error: null, status: FIELD_STATUS.VALID },
        policyNumber: { value: null, error: null, status: FIELD_STATUS.VALID },
        productType: { value: null, error: null, status: FIELD_STATUS.VALID },
        complaintRelation: { value: null, error: null, status: FIELD_STATUS.VALID },
        complaintTitle: { value: null, error: null, status: null },
        complaintDescription: { value: null, error: null, status: null },
        sendMeCopy: false,
    },
};

export default (state = initComplaintArbitration, action) => {
    const mapError = (error) =>
        error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        // Side drawer toggles
        case TOGGLE_DRAWER:
            return {
                ...state,
                openDrawer: !state.openDrawer
                    ? OPEN_DRAWER.CUSTOMER_COMPLAINT
                    : null,
                complaintType: action.value ? action.value : null,
            };

        // Form validation
        case FORM_VALID_TRANSITION_COMPLAINT_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_TRANSITION_COMPLAINT_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };

        case RESET_STATE_TRANSITION_COMPLAINT_FORM:
            return {
                ...initComplaintArbitration,
            };

        // Set and validate values
        case RECEIVER_EMAIL_SET_VALUE:
            return {
                ...state,
                receiverEmailAddress: action.value,
            };
        case TITLE_SET_VALUE:
            return updateFormField('title', (field) => ({
                ...field,
                value: action.value,
            }));
        case TITLE_SET_VALID:
            return updateFormField('title', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case FIRSTNAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));
        case FIRSTNAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case SURNAME_SET_VALUE:
            return updateFormField('surname', (field) => ({
                ...field,
                value: action.value,
            }));
        case SURNAME_SET_VALID:
            return updateFormField('surname', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case CELLPHONE_NUMBER_SET_VALUE:
            return updateFormField('cellphoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case CELLPHONE_NUMBER_SET_VALID:
            return updateFormField('cellphoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case EMAIL_SET_VALUE:
            return updateFormField('email', (field) => ({
                ...field,
                value: action.value,
            }));
        case EMAIL_SET_VALID:
            return updateFormField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case IDENTITY_TYPE_SET_VALUE:
            switch (action.value) {
                case IDENTITY_TYPE.SA_ID:
                    return {
                        ...state,
                        form: {
                            ...state.form,
                            identityType: { ...state.form.identityType, value: action.value },
                            passportCountry: { ...state.form.passportCountry, value: null, error: null, status: null },
                            passportNumber: { ...state.form.passportNumber,  value: null, error: null, status: null }
                        }
                    }
                case IDENTITY_TYPE.PASSPORT:
                    return {
                        ...state,
                        form: {
                            ...state.form,
                            identityType: { ...state.form.identityType, value: action.value },
                            idNumber: { ...state.form.idNumber, value: null, error: null, status: null },
                        }
                    }
                default:
                    return state;
            }
        case IDENTITY_TYPE_SET_VALID:
            return updateFormField('identityType', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case ID_NUMBER_SET_VALUE:
            return updateFormField('idNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case ID_NUMBER_SET_VALID:
            return updateFormField('idNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PASSPORT_NUMBER_SET_VALUE:
            return updateFormField('passportNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case PASSPORT_NUMBER_SET_VALID:
            return updateFormField('passportNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PASSPORT_COUNTRY_SET_VALUE:
            return updateFormField('passportCountry', (field) => ({
                ...field,
                value: action.value,
            }));
        case PASSPORT_COUNTRY_SET_VALID:
            return updateFormField('passportCountry', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case POLICY_NUMBER_SET_VALUE:
            return updateFormField('policyNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case POLICY_NUMBER_SET_VALID:
            return updateFormField('policyNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case PRODUCT_TYPE_SET_VALUE:
            return updateFormField('productType', (field) => ({
                ...field,
                value: action.value,
            }));
        case PRODUCT_TYPE_SET_VALID:
            return updateFormField('productType', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COMPLAINT_RELATION_SET_VALUE:
            return updateFormField('complaintRelation', (field) => ({
                ...field,
                value: action.value,
            }));
        case COMPLAINT_RELATION_SET_VALID:
            return updateFormField('complaintRelation', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COMPLAINT_TITLE_SET_VALUE:
            return updateFormField('complaintTitle', (field) => ({
                ...field,
                value: action.value,
            }));
        case COMPLAINT_TITLE_SET_VALID:
            return updateFormField('complaintTitle', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COMPLAINT_DESCRIPTION_SET_VALUE:
            return updateFormField('complaintDescription', (field) => ({
                ...field,
                value: action.value,
            }));
        case COMPLAINT_DESCRIPTION_SET_VALID:
            return updateFormField('complaintDescription', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case COMPLAINT_TOGGLE_SEND_ME_COPY:
            return {
                ...state,
                form: {
                    ...state.form,
                    sendMeCopy: !state.sendMeCopy,
                },
            };

        // API call
        case COMPLAINT_FORM_SEND:
            return {
                ...state,
                sendComplaintStatus: API_CALL_STATUS.PENDING,
            };
        case COMPLAINT_FORM_SEND_SUCCESS:
            return {
                ...state,
                sendComplaintStatus: API_CALL_STATUS.SUCCESS,
            };
        case COMPLAINT_FORM_SEND_FAILURE:
            return {
                ...state,
                sendComplaintStatus: API_CALL_STATUS.FAILURE,
            };

        default:
            return state;
    }
};
