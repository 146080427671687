import { FORM_STATUS, API_CALL_STATUS, FIELD_STATUS } from '../status';
import {
    FORM_INVALIDATE_TRANSITION_PAYMENTS,
    FORM_VALIDATE_TRANSITION_PAYMENTS,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    TERMS_SET_VALUE,
    TERMS_SET_VALID,
    API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL,
    TRIGGER_PAYMENTS_STK_PUSH_SUCCESS,
    TRIGGER_PAYMENTS_STK_PUSH_FAILURE,
    API_PENDING_TRANSITION_PAYMENTS_PUSH,
    API_SUCCESS_TRANSITION_PAYMENTS_PUSH,
    API_FAILURE_TRANSITION_PAYMENTS_PUSH,
    API_PENDING_TRANSITION_PAYMENTS_STATUS,
    API_SUCCESS_TRANSITION_PAYMENTS_STATUS,
    API_FAILURE_TRANSITION_PAYMENTS_STATUS,
    CHECK_PAYMENTS_STATUS_SUCCESS,
    CHECK_PAYMENTS_STATUS_FAILURE,
    INITIALIZE_PAYMENTS,
    CONFIRM_ORDER_FAILURE,
    CONFIRM_ORDER_SUCCESS,
    API_CONFIRM_ORDER_SUCCESS,
    API_CONFIRM_ORDER_FAILURE,
    API_CONFIRM_ORDER_PENDING,
    SET_NETWORK_PROVIDER,
    SET_NETWORK_PROVIDER_VALID


} from '../../actions/biasharaFlexi/payment';

import { LOGIN_FAILURE, LOGIN_SUCCESS, API_PENDING_LOGIN, API_FAILURE_LOGIN, API_SUCCESS_LOGIN } from '../../actions/biasharaFlexi/auth';  

export const initPayments = {
	formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    stkApiCallStatus: null,
    statusApiCallStatus: null,
    loginApiCallStatus: null,
    loginData: null,
    stkPushData: null,
    paymentStatus: null,
    confirmOrderApiCallStatus: null,
    confirmOrderData: null,
	form: {
		phoneNumber: { value: null, error: null, status: null },
		terms: { value: false, error: null, status: null },
        networkProvider: { value: null, error: null, status: null }
	},
};

export default (state = initPayments, action) => {
	const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
	const updateFormField = (fieldId, update) => ({
		...state,
		form: {
			...state.form,
			[fieldId]: update(state.form[fieldId]),
		},
	});

	switch (action.type) {
        case FORM_VALIDATE_TRANSITION_PAYMENTS:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALIDATE_TRANSITION_PAYMENTS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));

        case PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TERMS_SET_VALUE:
            return updateFormField('terms', (field) => ({
                ...field,
                value: action.value,
            }));

        case TERMS_SET_VALID:
            return updateFormField('terms', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

            case SET_NETWORK_PROVIDER:
                return updateFormField('networkProvider', (field) => ({
                    ...field,
                    value: action.value,
                }));
    
            case SET_NETWORK_PROVIDER_VALID:
                return updateFormField('networkProvider', (field) => ({
                    ...field,
                    error: action.error,
                    status: mapError(action.error),
                }));
    

        // API transitions STK PUSH
        case API_PENDING_TRANSITION_PAYMENTS_PUSH:
            return { ...state, stkApiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_PAYMENTS_PUSH:
            return { ...state, stkApiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_PAYMENTS_PUSH:
            return { ...state, stkApiCallStatus: API_CALL_STATUS.FAILURE };
        case TRIGGER_PAYMENTS_STK_PUSH_SUCCESS:
            return { ...state, stkPushData: action.value };
        case TRIGGER_PAYMENTS_STK_PUSH_FAILURE:
            return { ...state, stkPushData: action.error };

        // API transitions STATUS
        case API_PENDING_TRANSITION_PAYMENTS_STATUS:
            return { ...state, statusApiCallStatus: API_CALL_STATUS.PENDING };
        case API_SUCCESS_TRANSITION_PAYMENTS_STATUS:
            return { ...state, statusApiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_FAILURE_TRANSITION_PAYMENTS_STATUS:
            return { ...state, statusApiCallStatus: API_CALL_STATUS.FAILURE };
        case CHECK_PAYMENTS_STATUS_SUCCESS:
            return { ...state, paymentStatus: action.value };
        case CHECK_PAYMENTS_STATUS_FAILURE:
            return { ...state, paymentStatus: action.error };
        case API_FAILURE_TRANSITION_PAYMENTS_STATUS_INITIAL:
            return { ...state, apiCallStatus: null };

        case API_CONFIRM_ORDER_SUCCESS:
            return { ...state, confirmOrderApiCallStatus: API_CALL_STATUS.SUCCESS };
        case API_CONFIRM_ORDER_FAILURE:
            return { ...state, confirmOrderApiCallStatus: API_CALL_STATUS.FAILURE };
        case API_CONFIRM_ORDER_PENDING:
            return { ...state, confirmOrderApiCallStatus: API_CALL_STATUS.PENDING };
        case CONFIRM_ORDER_SUCCESS:
            return { ...state, confirmOrderData: action.value };
        case CONFIRM_ORDER_FAILURE:
            return { ...state, confirmOrderData: action.error };
      

        // Login API transitions
        case API_PENDING_LOGIN:
            return { ...state, loginApiCallStatus: API_CALL_STATUS.PENDING };

        case API_SUCCESS_LOGIN:
            return { ...state, loginApiCallStatus: API_CALL_STATUS.SUCCESS };

        case API_FAILURE_LOGIN:
            return { ...state, loginApiCallStatus: API_CALL_STATUS.FAILURE };

        case API_FAILURE_LOGIN:
            return { ...state, loginApiCallStatus: API_CALL_STATUS.FAILURE };

        case LOGIN_SUCCESS:
            return { ...state, loginData: action.data };

        case LOGIN_FAILURE:
            return { ...state, loginData: null };

        case INITIALIZE_PAYMENTS:
            return { ...state, stkApiCallStatus: null, statusApiCallStatus: null, stkPushData: null };

        default:
            return state;
    }
};
