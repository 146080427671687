import * as OmValidationMessageHelper from '../helpers/validation-message-helper.js';
import { config } from '../config/index.js';

function countDecimals(value) {
    if (Math.floor(value) === value) {
        return 0;
    }
    return value.toString().split('.')[1].length || 0;
}

function hasDecimals(value) {
    return value && value.toString().split('.').length > 1;
}

function hasComa(value) {
    return value && value.toString().split(',').length > 1;
}

export function validateNumber(
    value,
    {
        min,
        max,
        subject = 'Number',
        required = true,
        decimalPoints = config.NUMBER.DECIMAL_POINTS,
        removeMasking = true,
        inclusive = false,
        retainSubjectCase,
    } = {}
) {
    const isEmpty = !value && value !== 0;
    if (isEmpty && required) {
        return OmValidationMessageHelper.getRequiredMessage(subject, retainSubjectCase);
    }

    if (isEmpty && !required) {
        return null;
    }

    //Check if comma is entered before parsing
    if (removeMasking === true && hasComa(value)) {
        return OmValidationMessageHelper.getNoCommasMsg(subject, retainSubjectCase);
    }

    // Remove spaces and commas from masked numbers
    // if (removeMasking) {
    //     value = value.replace(/[,]+/g, '').replace(/\s/g, '').trim();
    // }

    //Check if decimal point is entered before parsing
    if (decimalPoints === 0 && hasDecimals(value)) {
        return OmValidationMessageHelper.WHOLE_NUMBER_ERROR;
    }

    const num = parseFloat(value);
    const regEx = new RegExp('^[0-9.]*$');

    if (!regEx.test(value)) {
        return OmValidationMessageHelper.getInvalidMessage(subject, retainSubjectCase);
    }

    if (countDecimals(num) > decimalPoints) {
        return OmValidationMessageHelper.getDecimalsMsg(subject, decimalPoints, retainSubjectCase);
    }

    if (inclusive && min && num < min) {
        return OmValidationMessageHelper.numberMinMessage(subject, min - 1, retainSubjectCase);
    }

    if (inclusive && max && num > max) {
        return OmValidationMessageHelper.numberMaxMessage(subject, max + 1, retainSubjectCase);
    }

    if (!inclusive && min && num < min) {
        return OmValidationMessageHelper.numberMinMessage(subject, min, retainSubjectCase);
    }

    if (!inclusive && max && num > max) {
        return OmValidationMessageHelper.numberMaxMessage(subject, max, retainSubjectCase);
    }

    return null;
}

export function validateGenericNumber(
    value,
    { min, max, subject = 'Number', required = true, decimalPoints = 0, removeMasking = true } = {}
) {
    const isEmpty = !value && value !== 0;
    if (isEmpty && required) {
        return OmValidationMessageHelper.getGenericInvalidNumericCharacterMessage();
    }

    if (isEmpty && !required) {
        return null;
    }

    //Check if comma is entered before parsing
    if (removeMasking === true && hasComa(value)) {
        return OmValidationMessageHelper.getNoCommasMsg(subject);
    }

    // Remove spaces and commas from masked numbers
    if (removeMasking) {
        value = value.replace(/[,]+/g, '').replace(/\s/g, '').trim();
    }

    //Check if decimal point is entered before parsing
    if (decimalPoints === 0 && hasDecimals(value)) {
        return OmValidationMessageHelper.WHOLE_NUMBER_ERROR;
    }

    const num = parseFloat(value);
    const regEx = new RegExp('^[0-9.]*$');

    if (!regEx.test(value)) {
        return OmValidationMessageHelper.getGenericInvalidNumericCharacterMessage();
    }

    if (countDecimals(num) > decimalPoints) {
        return OmValidationMessageHelper.getDecimalsMsg(subject, decimalPoints);
    }

    if (min && num < min) {
        return OmValidationMessageHelper.numberMinMessage(subject, min);
    }

    if (max && num > max) {
        return OmValidationMessageHelper.numberMaxMessage(subject, max);
    }

    return null;
}

export function validateAge(
    valueRaw,
    { min = config.AGE.MIN, max = config.AGE.MAX, subject = 'Age', required = true, retainSubjectCase } = {}
) {
    return validateNumber(valueRaw, {
        min,
        max,
        subject,
        required,
        decimalPoints: 0,
        removeMasking: false,
        retainSubjectCase,
    });
}
