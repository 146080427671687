import { createSelector } from 'reselect';
import { getDigitalSavingsState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getDocuments = createSelector(getDigitalSavingsState, (state) => state.uploadDocuments);
export const getDocumentsForm = createSelector(getDocuments, (uploadDocuments) => uploadDocuments.form);

export const getDropOffDetails = createSelector(getDocuments, (uploadDocuments) => uploadDocuments.dropOffDetails);

export const getDocumentsFormValid = createSelector(
    getDocuments,
    (uploadDocuments) => uploadDocuments.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getDocuments,
    (uploadDocuments) => uploadDocuments.formStatus === API_CALL_STATUS.PENDING,
);

const makeGetFieldForm = (fieldId) => createSelector(getDocumentsForm, (form) => form[fieldId]);

export const getIdUploadValue = makeGetFieldForm('idUpload');
export const getKraPinUploadValue = makeGetFieldForm('kraPinUpload');
