import axios from 'axios';

import {
	apiTransitionShortTermGoalCalculator,
	CALCULATE_SHORT_TERM_GOAL
} from '../../../actions/investmentCalculator/shortTermGoal';

import { setViewInvestmentResult } from '../../../actions/investmentCalculator/investmentCalculator';

import {
	getIsApiPending,
} from '../../../selectors/investmentCalculator/shortTermGoalCalculator';


import {
	getSTGDataWithSplit,
} from '../../../selectors/investmentCalculator/shortTermGoalSplit';

export const shortTermGoalCalculateService = (store, next, action) => {
	const result = next(action);
	const state = store.getState();
	if(action.type === CALCULATE_SHORT_TERM_GOAL) {
		(async () => {
			if (!getIsApiPending(state)) {
				store.dispatch(apiTransitionShortTermGoalCalculator.pending());
				try {
					const response = await calculate(state);
					store.dispatch(setViewInvestmentResult(response?.data));
					store.dispatch(apiTransitionShortTermGoalCalculator.success());
				} catch (e) {
					store.dispatch(setViewInvestmentResult(null));
					store.dispatch(apiTransitionShortTermGoalCalculator.failure());
				}
			}
		})();
	}
	return result;
};

export const calculate = async (state) => {
	const payload = getSTGDataWithSplit(state);
	console.log(payload);

	try {
		const url = '/om-api/investment-calculator/short-term-goal';
		return await axios.post(url, payload);
	} catch (error) {
		return null;
	}
};
