// Transitions - validation
export const FORM_VALID_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/formValid';
export const FORM_INVALID_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/formInvalid';

export const transitionBeneficiaries = {
    formValid: () => ({ type: FORM_VALID_TRANSITION_BENEFICIARIES }),
    formInvalid: () => ({ type: FORM_INVALID_TRANSITION_BENEFICIARIES }),
};

// Transitions - navigation
export const CONTINUE_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/validContinue';
export const BACK_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/back';
export const continueBeneficiaries = () => ({ type: CONTINUE_TRANSITION_BENEFICIARIES });
export const backBeneficiaries = () => ({ type: BACK_TRANSITION_BENEFICIARIES });

// Transitions - CRUD Beneficiaries
export const ADD_NEW_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/addNewBeneficiary';
export const DELETE_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/deleteBeneficiary';
export const EDIT_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/editBeneficiary';
export const SAVE_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/saveBeneficiary';
export const CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/cancelEditOfBeneficiary';
export const addNewBeneficiary = () => ({ type: ADD_NEW_TRANSITION_BENEFICIARIES });
export const editBeneficiary = beneficiaryId => ({ type: EDIT_TRANSITION_BENEFICIARIES, beneficiaryId });
export const deleteBeneficiary = beneficiaryId => ({ type: DELETE_TRANSITION_BENEFICIARIES, beneficiaryId });
export const saveBeneficiary = beneficiaryId => ({ type: SAVE_TRANSITION_BENEFICIARIES, beneficiaryId });
export const cancelEditOfBeneficiary = () => ({ type: CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES });

// Transitions - side drawers
export const TOGGLE_EDIT_TRANSITION_BENEFICIARIES = 'easiplusFuneralPlan/beneficiaries/transition/toggleEdit';
export const toggleEditDrawer = () => ({ type: TOGGLE_EDIT_TRANSITION_BENEFICIARIES });

// Events - form input
export const BENEFICIARIES_SET_VALUE = 'easiplusFuneralPlan/beneficiaries/setValue/';
export const BENEFICIARIES_SET_VALID = 'easiplusFuneralPlan/beneficiaries/setValid/';
export const BENEFICIARIES_SET_UNTOUCHED = 'easiplusFuneralPlan/beneficiaries/setUntouched/';

export const SELECTED_EXISTING_BENEFICIARY_SET_VALUE = BENEFICIARIES_SET_VALUE + 'selectedExistingBeneficiary';
export const TITLE_SET_VALUE = BENEFICIARIES_SET_VALUE + 'title';
export const TITLE_SET_VALID = BENEFICIARIES_SET_VALID + 'title';
export const TITLE_SET_UNTOUCHED = BENEFICIARIES_SET_UNTOUCHED + 'title';
export const FIRST_NAME_SET_VALUE = BENEFICIARIES_SET_VALUE + 'firstName';
export const FIRST_NAME_SET_VALID = BENEFICIARIES_SET_VALID + 'firstName';
export const FIRST_NAME_SET_UNTOUCHED = BENEFICIARIES_SET_UNTOUCHED + 'firstName';
export const SURNAME_SET_VALUE = BENEFICIARIES_SET_VALUE + 'surname';
export const SURNAME_SET_VALID = BENEFICIARIES_SET_VALID + 'surname';
export const SURNAME_SET_UNTOUCHED = BENEFICIARIES_SET_UNTOUCHED + 'surname';
export const DATE_OF_BIRTH_SET_VALUE = BENEFICIARIES_SET_VALUE + 'dateOfBirth';
export const DATE_OF_BIRTH_SET_VALID = BENEFICIARIES_SET_VALID + 'dateOfBirth';
export const DATE_OF_BIRTH_SET_UNTOUCHED = BENEFICIARIES_SET_UNTOUCHED + 'dateOfBirth';
export const RELATIONSHIP_SET_VALUE = BENEFICIARIES_SET_VALUE + 'relationship';
export const RELATIONSHIP_SET_VALID = BENEFICIARIES_SET_VALID + 'relationship';
export const RELATIONSHIP_SET_UNTOUCHED = BENEFICIARIES_SET_UNTOUCHED + 'relationship';
export const MOBILE_NUMBER_SET_VALUE = BENEFICIARIES_SET_VALUE + 'mobileNumber';
export const MOBILE_NUMBER_SET_VALID = BENEFICIARIES_SET_VALID + 'mobileNumber';
export const MOBILE_NUMBER_SET_UNTOUCHED = BENEFICIARIES_SET_UNTOUCHED + 'mobileNumber';
export const EMAIL_SET_VALUE = BENEFICIARIES_SET_VALUE + 'email';
export const EMAIL_SET_VALID = BENEFICIARIES_SET_VALID + 'email';
export const EMAIL_SET_UNTOUCHED = BENEFICIARIES_SET_UNTOUCHED + 'email';

export const setSelectedExistingBeneficiaryValue = value => ({type: SELECTED_EXISTING_BENEFICIARY_SET_VALUE, value});
export const setTitleValue = value => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = error => ({ type: TITLE_SET_VALID, error });
export const setTitleUntouched = () => ({ type: TITLE_SET_UNTOUCHED });
export const setFirstNameValue = value => ({ type: FIRST_NAME_SET_VALUE, value });
export const setFirstNameValid = error => ({ type: FIRST_NAME_SET_VALID, error });
export const setFirstNameUntouched = () => ({ type: FIRST_NAME_SET_UNTOUCHED });
export const setSurnameValue = value => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = error => ({ type: SURNAME_SET_VALID, error });
export const setSurnameUntouched = () => ({ type: SURNAME_SET_UNTOUCHED });
export const setDateOfBirthValue = value => ({ type: DATE_OF_BIRTH_SET_VALUE, value });
export const setDateOfBirthValid = error => ({ type: DATE_OF_BIRTH_SET_VALID, error });
export const setDateOfBirthUntouched = () => ({ type: DATE_OF_BIRTH_SET_UNTOUCHED });
export const setRelationshipValue = value => ({ type: RELATIONSHIP_SET_VALUE, value });
export const setRelationshipValid = error => ({ type: RELATIONSHIP_SET_VALID, error });
export const setRelationshipUntouched = () => ({ type: RELATIONSHIP_SET_UNTOUCHED });
export const setMobileValue = value => ({ type: MOBILE_NUMBER_SET_VALUE, value });
export const setMobileValid = error => ({ type: MOBILE_NUMBER_SET_VALID, error });
export const setMobileUntouched = () => ({ type: MOBILE_NUMBER_SET_UNTOUCHED });
export const setEmailValue = value => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = error => ({ type: EMAIL_SET_VALID, error });
export const setEmailUntouched = () => ({ type: EMAIL_SET_UNTOUCHED });

// popup
export const SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE = BENEFICIARIES_SET_VALUE + 'showMustHaveBeneficiary';
export const setShowMustHaveBeneficiaryValue = value => ({type: SHOW_MUST_HAVE_BENEFICIARY_SET_VALUE, value});

// Big state updates
export const RESET_BENEFICAIRIES = BENEFICIARIES_SET_UNTOUCHED + 'clearBeneficiaries'
export const UPDATE_BENEFICIARY_LIST = BENEFICIARIES_SET_VALUE + 'updateBeneficiaryList'
export const resetBeneficiaries = () => ({type: RESET_BENEFICAIRIES});
export const updateBeneficiaryList = value => ({type: UPDATE_BENEFICIARY_LIST, value});

export const SET_CONFIRM_REMOVE_BENEFICIARY_ID = BENEFICIARIES_SET_VALUE + 'confirmRemoveBeneficiaryId'
export const setConfirmRemoveBenefiaryId = value => ({type: SET_CONFIRM_REMOVE_BENEFICIARY_ID, value});