export const SCROLL_TO_CLASS = 'fundCentre/scrolling/transition/scrollToClass';
export const SCROLL_TO_ID = 'fundCentre/scrolling/transition/scrollToId';
export const CLEAR_SCROLL = 'fundCentre/scrolling/transition/resetScroll';

export const transitionScrolling = {
    scrollToClass: (value) => ({type: SCROLL_TO_CLASS, value}),
    scrollToId: (value) => ({type: SCROLL_TO_ID, value}),
}

export const resetScroll = () => ({ type: CLEAR_SCROLL });
