import {CALL_ME_BACK_SEND} from "../../../actions/customerVerificationService/callMeBack";
import {callMeBackService} from "./callMeBack";
import {CVS_KBA_FETCH_VALUE} from "../../../actions/customerVerificationService/knowledgeBasedAuthentication";
import {kbaQuestionService} from "./knowledgeBasedAuthentication";
import {VERIFY_ID_FETCH_VALUE} from "../../../actions/customerVerificationService/idVerification";
import {identityVerificationService} from "./idVerification";
import {VERIFICATION_STATUS_SET_VALUE} from "../../../actions/customerVerificationService/selfie";
import {selfieService} from "./selfie";

export const cvsServices = (store, next, action) => {
    if (action.type === VERIFY_ID_FETCH_VALUE)
        return identityVerificationService(store, next, action);
        
    if (action.type.startsWith(CVS_KBA_FETCH_VALUE))
        return kbaQuestionService(store, next, action);

    if (action.type === CALL_ME_BACK_SEND)
        return callMeBackService(store, next, action);

    if (action.type.startsWith(VERIFICATION_STATUS_SET_VALUE))
        return selfieService(store, next, action);

    return null;
};
