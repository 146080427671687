import {
    REFERENCE_NUMBER_SET_VALUE,
    FIRST_NAMES_SET_VALUE,
    RESET_THANK_YOU_DETAILS
} from '../../actions/digiLifeLoanProtection/thankYouDetails';

export const initThankYou = {
    referenceNumber: null,
    firstNames: null,
};

export default (state = initThankYou, action) => {
    switch (action.type) {
        case REFERENCE_NUMBER_SET_VALUE:
            return { ...state, referenceNumber: action.value };
        case FIRST_NAMES_SET_VALUE:
            return { ...state, firstNames: action.value };
        case RESET_THANK_YOU_DETAILS:
            return { ...initThankYou };
        default:
            return state;
    }
}