import {RISK_NAMES_TO_ID} from "../../actions/taxFreeSavingsAccount/types";

/**
 * Fund data sorted by fund name.
 * @returns {FlatArray<{mediumRisk: {fundType: string, compliances: [string], fees: string, fundDetails: {name: string, description: string, url: string}, riskProfile: string, performances: {year5: string, year1: string, year3: string}, status: string, products: []}, lowRisk: {fundType: string, compliances: [], fees: string, fundDetails: {name: string, description: string, url: string}, riskProfile: string, performances: {year5: string, year1: string, year3: string}, status: string, products: string[]}, highRisk: {fundType: string, compliances: [], fees: string, fundDetails: {name: string, description: string, url: string}, riskProfile: string, performances: {year5: string, year1: string, year3: string}, status: string, products: [string]}}[], 1>[]}
 */
export const sortFundData = (fundReferenceData) => Object.values(fundReferenceData)
    .filter(list => Array.isArray(list))
    .flat()
    .sort((a, b) => a.fundDetails.name.localeCompare(b.fundDetails.name));

/**
 * Returns true if the fund name contains the string search term.
 * @param fund Fund object
 * @param searchString String to search for
 * @returns {boolean}
 */
export const searchFundByName = (fund, searchString) => {
    const fundName = fund.fundDetails.name.toLowerCase()
    const searchTerm = searchString.toLowerCase()

    return fundName.includes(searchTerm);
};

/**
 * Returns the first 5 funds with names matching the search query.
 * @param sortedFundData Fund data array
 * @param searchString Search string
 * @returns {*[]}
 */
export const suggestSearchFunds = (sortedFundData, searchString) => {
    if (!searchString || searchString === '') {
        return [];
    }

    const suggestedIndices = [];

    // Try to find the first 5 funds in `sortedFundData` where `fund.fundDetails.name.includes(searchQuery)`.
    [...Array(5)].map((_, index) => {
        if (suggestedIndices.length < index) {
            // No more funds to suggest.
            return;
        }

        const newSuggestionIndex = sortedFundData.findIndex((fund, index) => {
            const isAlreadySuggested = suggestedIndices.includes(index);
            return isAlreadySuggested
                ? false
                : searchFundByName(fund, searchString)
        });

        if (newSuggestionIndex === -1) {
            // No new suggested fund. This will trigger the `suggestedIndices.length < index` if statement.
            return;
        }

        // Append the index of the suggested fund to the suggestedIndices array.
        suggestedIndices.push(newSuggestionIndex);
    });

    return suggestedIndices
        .filter(suggestedIndex => suggestedIndex != null)
        .map(suggestedIndex => sortedFundData[suggestedIndex]);
};

/**
 * Returns a list of fund data filtered by a set of boolean values.
 * @param sortedFundData Fund data array
 * @param selectedFundFilters The list of filters to apply
 * @param isFilteredByRiskProfile If true, apply RiskProfile category filters
 * @param isFilteredByCompliance If true, apply Compliance category filters
 * @param isFilteredByStatus If true, apply Status category filters
 * @returns {*[]|*}
 */
export const filterFundData = (
    sortedFundData,
    selectedFundFilters,
    isFilteredByRiskProfile,
    isFilteredByCompliance,
    isFilteredByStatus,
) => {
    if (!selectedFundFilters) {
        return sortedFundData;
    }

    let filterResult = [...sortedFundData];
    const appliedFilters = Object.entries(selectedFundFilters)
        .filter(([_, val]) => val)
        .map(([key, _]) => key.toLowerCase())

    if (isFilteredByCompliance) {
        filterResult = filterResult.filter(fund => {
            if (!fund.compliances || !fund.compliances.length) {
                return false;
            }

            return fund.compliances
                .map(compliance => appliedFilters.includes(compliance.toLowerCase()));
        });
    }

    if (isFilteredByRiskProfile) {
        filterResult = filterResult.filter(fund => {
            if (!fund.riskProfile) {
                return false;
            }

            return appliedFilters.includes(RISK_NAMES_TO_ID[fund.riskProfile]);
        });
    }

    if (isFilteredByStatus) {
        filterResult = filterResult.filter(fund => {
            if (!fund.status) {
                return false;
            }

            return appliedFilters.includes(fund.status.toLowerCase());
        });
    }

    return filterResult;
};
