import { createSelector } from 'reselect';
import { getTaxFreeSavingsAccount } from './index';
import { FIELD_STATUS } from '../../reducers/status';
import { getSelectedFundList } from './advancedFundPicker';
import { FUND_SPLIT_FORM } from '../../actions/taxFreeSavingsAccount/types';
import { sumPercentages } from '../../functions/percentageSplit';
import {
    getDisplayLumpSumInput,
    getDisplayRegularInput,
    getLumpSumContribution,
    getRegularContribution,
} from './investmentSetupContribution';
import { getFundDictionary } from './selectAFund';
import { displayFundName } from '../../functions/taxFreeSavingsAccount/selectAFund';

export const getFundSplit = createSelector(getTaxFreeSavingsAccount, (tfsa) => tfsa.fundSplit);
export const getFundSplitFormRegular = createSelector(getFundSplit, (fundSplit) => fundSplit[FUND_SPLIT_FORM.REGULAR]);
export const getFundSplitFormLumpSum = createSelector(getFundSplit, (fundSplit) => fundSplit[FUND_SPLIT_FORM.LUMP_SUM]);

export const getFundSplitMetaRegular = createSelector(
    getFundSplit,
    (fundSplit) => fundSplit[FUND_SPLIT_FORM.REGULAR_META]
);
export const getFundSplitMetaLumpSum = createSelector(
    getFundSplit,
    (fundSplit) => fundSplit[FUND_SPLIT_FORM.LUMP_SUM_META]
);

const isEvenSplit = (formMeta) => formMeta.isEvenSplit;
export const getIsEvenSplit = createSelector(
    getFundSplitMetaRegular,
    getFundSplitMetaLumpSum,
    (fundSplitMetaRegular, fundSplitMetaLumpSum) => ({
        regular: isEvenSplit(fundSplitMetaRegular),
        lumpSum: isEvenSplit(fundSplitMetaLumpSum),
    })
);

const fundSplitList = (selectedFundList, contributionValue, splitForm, fundDictionary) => {
    const getSplitValue = (id) => splitForm.find((split) => split.id === id);

    return selectedFundList.map((fundCode, index) => {
        const splitValue = getSplitValue(fundCode);
        const contributionSplit =
            !splitValue || Number.isNaN(+splitValue.value) || splitValue.value < 0
                ? 0
                : Math.round((+(contributionValue.value || 0) * splitValue.value) / 100);
        return {
            fundCode,
            fundName: displayFundName(fundDictionary.find((fund) => fund.code === fundCode)?.name) || fundCode,
            contributionSplit,
            ...splitValue,
        };
    });
};

export const getSplitFieldList = createSelector(
    getSelectedFundList,
    getRegularContribution,
    getLumpSumContribution,
    getFundSplitFormRegular,
    getFundSplitFormLumpSum,
    getFundDictionary,
    (
        selectedFundList,
        regularContribution,
        lupSumContribution,
        fundSplitFormRegular,
        fundSplitFormLumpSum,
        fundDictionary
    ) => ({
        regular: fundSplitList(selectedFundList, regularContribution, fundSplitFormRegular, fundDictionary),
        lumpSum: fundSplitList(selectedFundList, lupSumContribution, fundSplitFormLumpSum, fundDictionary),
    })
);

const totalContribution = (splitList) => splitList.reduce((acc, split) => acc + (split?.contributionSplit || 0), 0);

export const getTotalContribution = createSelector(getSplitFieldList, (splitList) => ({
    regular: totalContribution(splitList.regular),
    lumpSum: totalContribution(splitList.lumpSum),
}));

export const getFundSplitTotal = createSelector(getSplitFieldList, (splitFieldList) => {
    const totalPercentageRegular = sumPercentages({ splitList: splitFieldList.regular });
    const totalPercentageLumpSum = sumPercentages({ splitList: splitFieldList.lumpSum });

    return {
        regular: {
            percentage: totalPercentageRegular,
            error: totalPercentageRegular !== 100 ? 'Your total should be 100%' : '',
            state: totalPercentageRegular === 100 ? FIELD_STATUS.VALID : FIELD_STATUS.ERROR,
        },
        lumpSum: {
            percentage: totalPercentageLumpSum,
            error: totalPercentageLumpSum !== 100 ? 'Your total should be 100%' : '',
            state: totalPercentageLumpSum === 100 ? FIELD_STATUS.VALID : FIELD_STATUS.ERROR,
        },
    };
});

export const getIsFundSplitValid = createSelector(
    getDisplayRegularInput,
    getDisplayLumpSumInput,
    getFundSplitTotal,
    getSplitFieldList,
    (hasRegularInput, hasLumpSumInput, fundSplitTotal, splitList) => {
        const fieldsAreAllValid = (fieldList) => fieldList.every((field) => field.status === FIELD_STATUS.VALID);
        const regularFieldsAreValid = fieldsAreAllValid(splitList.regular);
        const lumpSumFieldsAreValid = fieldsAreAllValid(splitList.lumpSum);
        const regularFormIsValid = hasRegularInput
            ? regularFieldsAreValid && fundSplitTotal.regular.percentage === 100
            : true;
        const lumpSumFormIsValid = hasLumpSumInput
            ? lumpSumFieldsAreValid && fundSplitTotal.lumpSum.percentage === 100
            : true;

        return regularFormIsValid && lumpSumFormIsValid;
    }
);

export const getShowAddMoreFunds = createSelector(
    getDisplayRegularInput,
    getDisplayLumpSumInput,
    (displayRegularInput, displayLumpSumInput) => {
        if (displayRegularInput && displayLumpSumInput) {
            return {
                regular: false,
                lumpSum: true,
            };
        }

        if (displayRegularInput && !displayLumpSumInput) {
            return {
                regular: true,
                lumpSum: false,
            };
        }

        return {
            regular: false,
            lumpSum: true,
        };
    }
);
