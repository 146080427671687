import {FIELD_STATUS, FORM_STATUS} from "../status";
import {
    EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT,
    EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT, EVEN_SPLIT_SINGLE_BENEFICIARIES_SPLIT,
    FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT,
    FORM_INVALID_TRANSITION_BENEFICIARIES_SPLIT,
    FORM_VALID_TRANSITION_BENEFICIARIES_SPLIT,
    SPLIT_PERCENTAGE_SET_VALID,
    SPLIT_PERCENTAGE_SET_VALUE,
    TOTAL_PERCENTAGE_SET_VALID,
    TOTAL_PERCENTAGE_SET_VALUE,
    RESET_BENEFICIARIES_SPLIT
} from "../../actions/raOptimal/beneficiariesSplit";

const evenSplitMatrix = [
    [100],
    [50, 50],
    [34, 33, 33],
    [25, 25, 25, 25],
    [20, 20, 20, 20, 20],
    [20, 16, 16, 16, 16, 16],
    [16, 14, 14, 14, 14, 14, 14],
    [16, 12, 12, 12, 12, 12, 12, 12],
    [12, 11, 11, 11, 11, 11, 11, 11, 11],
    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
];
const indexAsKey = index => {
    const indexStr = (index + 1) + '';
    return 'split' + (indexStr.length < 2
        ? ('0' + indexStr)
        : indexStr);
};
export const clearSplitFormFields = () => ({
    split01: {value: null, error: null, status: null, beneficiaryId: null},
    split02: {value: null, error: null, status: null, beneficiaryId: null},
    split03: {value: null, error: null, status: null, beneficiaryId: null},
    split04: {value: null, error: null, status: null, beneficiaryId: null},
    split05: {value: null, error: null, status: null, beneficiaryId: null},
    split06: {value: null, error: null, status: null, beneficiaryId: null},
    split07: {value: null, error: null, status: null, beneficiaryId: null},
    split08: {value: null, error: null, status: null, beneficiaryId: null},
    split09: {value: null, error: null, status: null, beneficiaryId: null},
    split10: {value: null, error: null, status: null, beneficiaryId: null},
});
export const initBeneficiariesSplit = {
    formStatus: FORM_STATUS.INVALID,
    isEvenSplit: false,
    splitError: null,
    totalPercentage: 0,
    nFields: 0,
    form: clearSplitFormFields(),
};

export default (state = initBeneficiariesSplit, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    const setEvenSplitField = (nFields, nthField, field) => {
        if (field.beneficiaryId == null)
            return field;

        return {
            ...field,
            value: evenSplitMatrix[nFields - 1][nthField],
            error: null,
            status: FIELD_STATUS.VALID,
        };
    };
    const getSplitFieldId = () => {
        const formFields = Object.entries(state.form);
        const splitField = formFields.find(field => field[1].beneficiaryId === action.beneficiaryId);
        return splitField[0];
    };

    switch (action.type) {
        case FORM_INIT_TRANSITION_BENEFICIARIES_SPLIT:
            // beneficiaries should have shape [{id: beneficiaryId, value: {...}}, ...]
            const beneficiaries = action.beneficiaries;
            const currentSplitEntries = Object.entries(state.form);
            // Map the new list of beneficiaries to the current list of split values. This is needed if a split value
            // needs to be added or discarded due to a beneficiary being added or discarded.
            const newSplitEntries = beneficiaries.map((ben, index) => {
                const splitValue = currentSplitEntries.find(split => split[1].beneficiaryId === ben.id);
                const splitKey = indexAsKey(index);

                if (!splitValue)
                    return [
                        splitKey,
                        {value: null, error: null, status: FIELD_STATUS.UNTOUCHED, beneficiaryId: ben.id}
                    ];

                return [splitKey, {
                    value: splitValue[1].value,
                    error: splitValue[1].error,
                    status: splitValue[1].status,
                    beneficiaryId: ben.id,
                }];
            });

            return {
                ...state,
                nFields: newSplitEntries.length,
                form: {...clearSplitFormFields(), ...Object.fromEntries(newSplitEntries)},
            };

        case EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT:
            const formFields = Object.entries(state.form);
            // Sort the array from split01 to split10
            formFields.sort((a, b) => {
                let int1 = b[0].match(/(\d+)/)[0];
                let int2 = a[0].match(/(\d+)/)[0];
                return int2 - int1;
            });
            
            const nNonNullFields = formFields.findIndex(field => field[1].beneficiaryId == null);
            const nFields = nNonNullFields === -1 ? 10 : nNonNullFields;
            const evenSplitForm = Object.fromEntries(formFields.map(([fieldKey, fieldVal], index) => [
                fieldKey,
                setEvenSplitField(nFields, index, fieldVal)
            ]));

            return {
                ...state,
                nFields,
                isEvenSplit: true,
                totalPercentage: 100,
                splitError: null,
                form: evenSplitForm,
            };
        case EVEN_SPLIT_SINGLE_BENEFICIARIES_SPLIT:
            return {
                ...state,
                nFields: 1,
                isEvenSplit: true,
                totalPercentage: 100,
                splitError: null,
                form: {
                    ...clearSplitFormFields(),
                    split01: {
                        value: 100,
                        error: null,
                        status: 'valid',
                        beneficiaryId: action.beneficiaryId,
                    },
                },
            };

        case EVEN_SPLIT_DESELECT_BENEFICIARIES_SPLIT:
            return {...state, isEvenSplit: false};
        case EVEN_SPLIT_DISCARD_BENEFICIARIES_SPLIT:
            const discardFormEntries = Object.entries(state.form);
            const discardForm = Object.fromEntries(discardFormEntries.map(([fieldKey, fieldVal]) => [
                fieldKey,
                {...fieldVal, value: null, error: null, status: null},
            ]));

            return {
                ...state,
                form: discardForm,
                totalPercentage: 0,
                nFields: 0,
            };

        case FORM_VALID_TRANSITION_BENEFICIARIES_SPLIT:
            return {...state, formStatus: FORM_STATUS.VALID};
        case FORM_INVALID_TRANSITION_BENEFICIARIES_SPLIT:
            return {...state, formStatus: FORM_STATUS.INVALID};

        case TOTAL_PERCENTAGE_SET_VALUE:
            return {...state, totalPercentage: action.value};
        case TOTAL_PERCENTAGE_SET_VALID:
            return {...state, splitError: action.error};

        case SPLIT_PERCENTAGE_SET_VALUE:
            return updateFormField(getSplitFieldId(), field => ({
                ...field,
                value: action.value,
            }));
        case SPLIT_PERCENTAGE_SET_VALID:
            return updateFormField(getSplitFieldId(), field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
            
        case RESET_BENEFICIARIES_SPLIT:
            return { ...initBeneficiariesSplit };

        default:
            return state;
    }
};
