import { combineReducers } from "redux";
import basicCalculator from "./basicCalculator";
import advancedCalculator from "./advancedCalculator";
import scrolling from "./scrolling";


export const incomeTaxCalculator = combineReducers ({
    basicCalculator,
    advancedCalculator,
    scrolling,
});