export const PERSONAL_PENSION_PLAN_QUOTE_FORM = 'personalPensionPlan/quoteForm/';

export const PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM + 'setValue/';
export const PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM + 'setValid/';
export const FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_FORM = 'personalPensionPlan/quoteForm/transition/formValid';
export const FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_FORM = 'personalPensionPlan/quoteForm/transition/formInValid';

export const PERSONAL_PENSION_PLAN_QUOTE_ADD_COVER = PERSONAL_PENSION_PLAN_QUOTE_FORM + 'addCover/';

export const FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM =
    'personalPensionPlan/shareQuoteForm/transition/formValid';
export const FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM =
    'personalPensionPlan/shareQuoteForm/transition/formInValid';
export const PERSONAL_PENSION_PLAN_SHARE_QUOTE_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM + 'shareQuote';

export const TOKEN_IS_EXPIRED = 'personalPensionPlan/expiredAuth';

export const PERSONAL_PENSION_PLAN_SIDEBAR_OPEN_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'sidebarOpen';

export const REINITIALIZE = PERSONAL_PENSION_PLAN_QUOTE_FORM + '/state/reinitialize';

// VALIDATE QUOTE FORM
export const transitionPersonalPensionPlanQuoteForm = {
    formValid: () => ({
        type: FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    }),
    formInvalid: () => ({
        type: FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    }),
};

export const transitionShareQuoteForm = {
    formInvalid: () => ({
        type: FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM,
    }),
    formValid: () => ({
        type: FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM,
    }),
};

// API Calls actions
export const API_PENDING_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM = `${PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM = `${PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE}/apiTransition/success`;

export const API_FAILURE_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM = `${PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE}/apiTransition/failure`;
export const API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM = `${PERSONAL_PENSION_PLAN_SHARE_QUOTE_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM = `${PERSONAL_PENSION_PLAN_SHARE_QUOTE_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM = `${PERSONAL_PENSION_PLAN_SHARE_QUOTE_VALUE}/apiTransition/failure`;

export const PREPAID_TRANSACTION_CODE_CHECK_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM + 'prepaidTransactionCodeCheck';

export const API_PENDING_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY = `${PREPAID_TRANSACTION_CODE_CHECK_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY = `${PREPAID_TRANSACTION_CODE_CHECK_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY = `${PREPAID_TRANSACTION_CODE_CHECK_VALUE}/apiTransition/failure`;

export const apiTransitionPersonalPensionPlanQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    }),
};

//Prepaid lumpsum check api transition
export const apiTransitionPrepaidLumpsumCheck = {
    pending: () => ({
        type: API_PENDING_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY,
    }),
};

export const apiTransitionShareQuoteForm = {
    pending: () => ({
        type: API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    success: () => ({
        type: API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
    failure: () => ({
        type: API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    }),
};

//calculated Data
export const COLLECT_PERSONAL_PENSION_PLAN_QUOTE_FORM_DATA =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'collectPersonalPensionPlanQuoteData';
export const SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'success';
export const SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_FAILURE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'failure';
export const CALCULATE_PERSONAL_PENSION_PLAN_PREMIUM_SUCCESS =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'calculatePremiumSuccess';
export const CALCULATE_PERSONAL_PENSION_PLAN_PREMIUM_FAILURE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'failure';

export const COLLECT_SHARE_QUOTE_FORM_DATA = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'collectShareQuoteFormData';
export const SHARE_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'shareQuoteSuccess';
export const SHARE_PERSONAL_PENSION_PLAN_QUOTE_FAILURE =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'shareQuoteFailure';

export const collectPersonalPensionPlanQuoteData = () => ({
    type: COLLECT_PERSONAL_PENSION_PLAN_QUOTE_FORM_DATA,
});

export const submitPersonalPensionPlanQuoteSuccess = () => ({
    type: SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS,
    value,
});

export const submitPersonalPensionPlanQuoteFailure = () => ({
    type: SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_FAILURE,
    error,
});

export const collectShareQuoteFormData = () => ({
    type: COLLECT_SHARE_QUOTE_FORM_DATA,
});

export const shareQuoteSuccess = (value) => ({
    type: SHARE_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS,
    value,
});

export const shareQuoteFailure = (error) => ({
    type: SHARE_PERSONAL_PENSION_PLAN_QUOTE_FAILURE,
    error,
});

export const calculatePersonalPensionPlanPremiumSuccess = (value) => ({
    type: CALCULATE_PERSONAL_PENSION_PLAN_PREMIUM_SUCCESS,
    value,
});

export const addCover = (value) => ({
    type: PERSONAL_PENSION_PLAN_QUOTE_ADD_COVER,
    value,
});

// Form values
export const SET_SHARE_EMAIL_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'shareEmailValue';
export const SET_SHARE_EMAIL_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'shareEmailValid';

//quote details
export const PHONE_NUMBER_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'phoneNumber';
export const PHONE_NUMBER_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'phoneNumber';
export const GENDER_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'gender';
export const GENDER_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'gender';
export const CONTRIBUTION_MODE_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'contributionMode';
export const CONTRIBUTION_MODE_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'contributionMode';
export const DOB_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'DOB';
export const DOB_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'DOB';
export const INTEREST_RATE_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'interestRate';
export const INTEREST_RATE_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'interestRate';
export const DESIRED_MONTHLY_CONTRIBUTION_SET_VALUE =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'desiredMonthlyContribution';
export const DESIRED_MONTHLY_CONTRIBUTION_SET_VALID =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'desiredMonthlyContribution';
export const LUMPSUM_AMOUNT_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'lumpsumAmount';
export const LUMPSUM_AMOUNT_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'lumpsumAmount';
export const DESIRED_RETIREMENT_AGE_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'desiredRetirementAge';
export const DESIRED_RETIREMENT_AGE_SET_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'desiredRetirementAge';

export const FREQUENCY_OF_CONTRIBUTION_SET_VALUE =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'frequencyOfContribution';
export const FREQUENCY_OF_CONTRIBUTION_SET_VALID =
    PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'frequencyOfContribution';

export const MATURITY_YEARS_SET_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'maturityYears';
export const SET_IS_LUMPSUM_PREPAID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'isLumpsumPrepaid';
export const IS_LUMPSUM_PREPAID_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'isLumpsumPrepaid';
export const PREPAID_TRANSACTION_CODE_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'prepaidTransactionCode';
export const PREPAID_TRANSACTION_CODE_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'prepaidTransactionCode';
export const PREPAID_MODE_OF_PAYMENT_VALUE = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALUE + 'prepaidModeOfPayment';
export const PREPAID_MODE_OF_PAYMENT_VALID = PERSONAL_PENSION_PLAN_QUOTE_FORM_SET_VALID + 'prepaidModeOfPayment';

// action creators

export const initializeQuoteForm = () => ({
    type: REINITIALIZE,
});

export const setShareEmailValue = (value) => ({
    type: SET_SHARE_EMAIL_VALUE,
    value,
});

export const setShareEmailValid = (error) => ({
    type: SET_SHARE_EMAIL_VALID,
    error,
});

export const setExpiredAuth = (value) => ({
    type: TOKEN_IS_EXPIRED,
    value,
});

export const setSideBarOpen = (value) => ({ type: PERSONAL_PENSION_PLAN_SIDEBAR_OPEN_VALUE, value });
export const setShowShareModal = (value) => ({ type: PERSONAL_PENSION_PLAN_SHARE_QUOTE_VALUE, value });

export const setMaturityYearsValue = (value) => ({
    type: MATURITY_YEARS_SET_VALUE,
    value,
});

export const setPhoneNumberValue = (value) => ({
    type: PHONE_NUMBER_SET_VALUE,
    value,
});
export const setPhoneNumberValid = (error) => ({
    type: PHONE_NUMBER_SET_VALID,
    error,
});

export const setGenderValue = (value) => ({
    type: GENDER_SET_VALUE,
    value,
});
export const setGenderValid = (error) => ({
    type: GENDER_SET_VALID,
    error,
});

export const setContributionModeValue = (value) => ({
    type: CONTRIBUTION_MODE_SET_VALUE,
    value,
});
export const setContributionModeValid = (error) => ({
    type: CONTRIBUTION_MODE_SET_VALID,
    error,
});

export const setDOBValue = (value) => ({
    type: DOB_SET_VALUE,
    value,
});
export const setDOBValid = (error) => ({
    type: DOB_SET_VALID,
    error,
});

export const setInterestRateValue = (value) => ({
    type: INTEREST_RATE_SET_VALUE,
    value,
});
export const setInterestRateValid = (error) => ({
    type: INTEREST_RATE_SET_VALID,
    error,
});

export const setDesiredMonthlyContributionValue = (value) => ({
    type: DESIRED_MONTHLY_CONTRIBUTION_SET_VALUE,
    value,
});
export const setDesiredMonthlyContributionValid = (error) => ({
    type: DESIRED_MONTHLY_CONTRIBUTION_SET_VALID,
    error,
});

export const setLumpsumAmountValue = (value) => ({
    type: LUMPSUM_AMOUNT_SET_VALUE,
    value,
});
export const setLumpsumAmountValid = (error) => ({
    type: LUMPSUM_AMOUNT_SET_VALID,
    error,
});

export const setDesiredRetirementAgeValue = (value) => ({
    type: DESIRED_RETIREMENT_AGE_SET_VALUE,
    value,
});
export const setDesiredRetirementAgeValid = (error) => ({
    type: DESIRED_RETIREMENT_AGE_SET_VALID,
    error,
});

export const setFrequencyOfContributionValue = (value) => ({
    type: FREQUENCY_OF_CONTRIBUTION_SET_VALUE,
    value,
});
export const setFrequencyOfContributionValid = (error) => ({
    type: FREQUENCY_OF_CONTRIBUTION_SET_VALID,
    error,
});

export const setIsLumpsumPrepaidValue = (value) => ({
    type: SET_IS_LUMPSUM_PREPAID,
    value,
});
export const setIsLumpsumPrepaidValid = (error) => ({
    type: IS_LUMPSUM_PREPAID_VALID,
    error,
});

export const setPrepaidTransactionCodeValue = (value) => ({
    type: PREPAID_TRANSACTION_CODE_VALUE,
    value,
});
export const setPrepaidTransactionCodeValid = (error) => ({
    type: PREPAID_TRANSACTION_CODE_VALID,
    error,
});

export const setPrepaidModeOfPaymentValue = (value) => ({
    type: PREPAID_MODE_OF_PAYMENT_VALUE,
    value,
});
export const setPrepaidModeOfPaymentValid = (error) => ({
    type: PREPAID_MODE_OF_PAYMENT_VALID,
    error,
});
