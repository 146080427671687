import { CALCULATE_DEBT_RESULTS } from '../../../actions/debtRepaymentCalculators/debtRepaymentCalculator';
import { fireGtmEvent } from '../gtmEvents';

import { findOtherEventIndex, otherEventTracking } from "../otherEventTracking";

export const debtCalculatorFormTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type) {
            case CALCULATE_DEBT_RESULTS:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                fireGtmEvent('calculator_complete', { calculator_type: 'Debt calculator' });
                return 'Debt calculator';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
}
