// General routing events
export const CVS_ROUTING_SET_VALUE = 'customerVerificationService/routing/setValue/';

export const NAVIGATE_PREVIOUS = CVS_ROUTING_SET_VALUE + 'navigatePrevious';
export const NAVIGATE_NEXT = CVS_ROUTING_SET_VALUE + 'navigateNext';
export const NAVIGATE_INDEX = CVS_ROUTING_SET_VALUE + 'navigateIndex';
export const SET_NAVIGATION_DONE = CVS_ROUTING_SET_VALUE + 'navigationDone';
export const navigatePrevious = () => ({ type: NAVIGATE_PREVIOUS });
export const navigateNext = () => ({ type: NAVIGATE_NEXT });
export const navigateIndex = value => ({ type: NAVIGATE_INDEX, value });
export const setNavigationDone = () => ({ type: SET_NAVIGATION_DONE })

export const RESET_ROUTING = CVS_ROUTING_SET_VALUE + 'resetRouting';
export const SET_NAVIGATION_BACK_TO_PARENT_APP = CVS_ROUTING_SET_VALUE + 'navigateBackToParentApp';
export const SET_NAVIGATION_TO_CVS_FROM_PARENT_APP = CVS_ROUTING_SET_VALUE + 'navigateToCVSFromParentApp';
export const resetRouting = () => ({ type: RESET_ROUTING });
export const navigateBackToParentApp = () => ({ type: SET_NAVIGATION_BACK_TO_PARENT_APP });
export const navigateToCVSFromParentApp = ({currentAppUrl, parentAppUrl, callbackAction, callMeBackSource, parentApp}) => ({
    type: SET_NAVIGATION_TO_CVS_FROM_PARENT_APP,
    currentAppUrl,
    parentAppUrl,
    callbackAction,
    callMeBackSource,
    parentApp
});

export const SET_ACTIVE_PAGE_INDEX = CVS_ROUTING_SET_VALUE + 'activePageIndex';
export const setActivePageIndex = value => ({ type: SET_ACTIVE_PAGE_INDEX, value });

// Per-page routing transitions
export const CVS_ROUTING_TRANSITION = 'customerVerificationService/routing/transition/';

export const NAVIGATE_NEXT_CONSENT = CVS_ROUTING_TRANSITION + 'nextConsent';
export const NAVIGATE_PREVIOUS_CONSENT = CVS_ROUTING_TRANSITION + 'previousConsent';
export const navigateNextConsent = () => ({ type: NAVIGATE_NEXT_CONSENT });
export const navigatePreviousConsent = () => ({ type: NAVIGATE_PREVIOUS_CONSENT });

export const NAVIGATE_NEXT_ID_VERIFICATION = CVS_ROUTING_TRANSITION + 'nextIDVerification';
export const NAVIGATE_PREVIOUS_ID_VERIFICATION = CVS_ROUTING_TRANSITION + 'previousIDVerification';
export const navigateNextIDVerification = imageExists => ({ type: NAVIGATE_NEXT_ID_VERIFICATION, imageExists });
export const navigatePreviousIDVerification = () => ({ type: NAVIGATE_PREVIOUS_ID_VERIFICATION });

export const NAVIGATE_NEXT_SELFIE = CVS_ROUTING_TRANSITION + 'nextSelfie';
export const NAVIGATE_PREVIOUS_SELFIE = CVS_ROUTING_TRANSITION + 'previousSelfie';
export const navigateNextSelfie = selfiePassed => ({ type: NAVIGATE_NEXT_SELFIE, selfiePassed });
export const navigatePreviousSelfie = () => ({ type: NAVIGATE_PREVIOUS_SELFIE });

export const NAVIGATE_NEXT_KBA_INTERLUDE = CVS_ROUTING_TRANSITION + 'nextKbaInterlude';
export const NAVIGATE_PREVIOUS_KBA_INTERLUDE = CVS_ROUTING_TRANSITION + 'previousKbaInterlude';
export const navigateNextKbaInterlude = () => ({ type: NAVIGATE_NEXT_KBA_INTERLUDE });
export const navigatePreviousKbaInterlude = () => ({ type: NAVIGATE_PREVIOUS_KBA_INTERLUDE });

export const NAVIGATE_PREVIOUS_KBA = CVS_ROUTING_TRANSITION + 'previousKba';
export const navigatePreviousKba = () => ({ type: NAVIGATE_PREVIOUS_KBA });
