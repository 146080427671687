import {validationIsEqual} from "../status";
import {
    validateName,
    validateDateOfBirth,
    validateEmail,
    validateCellphoneNumber,
    validateSingleSelection
} from  "@om/validation";
import {
    DATE_OF_BIRTH_SET_VALUE,
    EMAIL_SET_VALUE,
    FIRST_NAME_SET_VALUE,
    RELATIONSHIP_SET_VALUE,
    MOBILE_NUMBER_SET_VALUE,
    SURNAME_SET_VALUE,
    TITLE_SET_VALUE,
    setTitleValid,
    setFirstNameValid,
    setSurnameValid,
    setRelationshipValid,
    setMobileValid,
    setEmailValid,
    setDateOfBirthValid,
} from "../../../actions/easiplusFuneralPlan/beneficiaries";

let prevValidationAction = null;
export const efpBeneficiariesValidation = (store, next, action) => {
    const validationAction = (() => {
        const trimValue = (value) => value ? value.trim() : value ;

        const removeWhiteSpacesFromValue = (value) => value ? value.replace(/ /g, '') : value;

        let error;
        switch (action.type) {
            case TITLE_SET_VALUE:
                error = validateSingleSelection(action.value, 'title');
                return setTitleValid(error);
            case FIRST_NAME_SET_VALUE:
                error = validateName(trimValue(action.value), {min: 2, max: 48, subject: 'First name'}); 
                return setFirstNameValid(error);
            case SURNAME_SET_VALUE:
                error = validateName(trimValue(action.value), {min: 2, max: 64, subject: 'Surname'});
                return setSurnameValid(error);
            case DATE_OF_BIRTH_SET_VALUE:
                error = validateDateOfBirth(action.value);
                return setDateOfBirthValid(error);
            case RELATIONSHIP_SET_VALUE:
                error = validateSingleSelection(action.value, 'relationship');
                return setRelationshipValid(error);
            case MOBILE_NUMBER_SET_VALUE:
                error = validateCellphoneNumber(removeWhiteSpacesFromValue(action.value), {subject: 'Mobile number', required: false});
                return setMobileValid(error);
            case EMAIL_SET_VALUE:
                error = validateEmail(trimValue(action.value), {required: false});
                return setEmailValid(error);
            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
