import { FIELD_STATUS, TOGGLE_STATUS } from "../status";
import {
    SPLIT_PERCENTAGE_SET_VALID,
    SPLIT_PERCENTAGE_SET_VALUE,
    FORM_SET_VALUE,
    EVEN_SPLIT_TOGGLE_VALUE,
    EVEN_SPLIT_DESELECT_VALUE,
    RESET_BENEFICIARIES_SPLIT
} from "../../actions/taxFreeSavingsAccount/beneficiariesSplit";

export const initBeneficiariesSplit = {
    isEvenSplit: false,
    form: [],
};

export default (state = initBeneficiariesSplit, action) => {
    const mapError = error => error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: state.form.map((entry) => {
            if (entry.beneficiaryId === fieldId) return update(entry);
            return entry;
        }),
    });

    switch (action.type) {
        case EVEN_SPLIT_TOGGLE_VALUE:
            return { ...state, isEvenSplit: state.isEvenSplit !== TOGGLE_STATUS.YES ? TOGGLE_STATUS.YES : TOGGLE_STATUS.NO };

        case EVEN_SPLIT_DESELECT_VALUE: 
            return { ...state, isEvenSplit: TOGGLE_STATUS.NO };

        case FORM_SET_VALUE:
            return { ...state, form: [...action.form] };

        case SPLIT_PERCENTAGE_SET_VALUE:
            return updateFormField(action.index, field => ({
                ...field,
                value: action.value,
            }));
        case SPLIT_PERCENTAGE_SET_VALID:
            return updateFormField(action.index, field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case RESET_BENEFICIARIES_SPLIT: 
            return { ...initBeneficiariesSplit };

        default:
            return state;
    }
};


