import { transitionClinicalCriteria } from "../../../actions/covidSelfAssessment/clinicalCriteria";
import { getClinicalCriteriaForm } from "../../../selectors/covidSelfAssessment/clinicalCriteria";
import { transitionWizardFooter } from "../../../actions/covidSelfAssessment/wizardFooter";
import { FIELD_STATUS } from "../../../reducers/status";

export const clinicalCriteriaRules = (store, next, action) => {
    const result = next(action);
    const clinicalCriteriaForm = getClinicalCriteriaForm(store.getState());
    const clinicalCriteriaValid = validateClinicalCriteria(clinicalCriteriaForm);

    // Clinical Criteria Form
    if (clinicalCriteriaValid) {
        store.dispatch(transitionClinicalCriteria.formValid());
        // Wizard Footer
        store.dispatch(transitionWizardFooter.continueButtonDisabled(false));
    } else {
        store.dispatch(transitionClinicalCriteria.formInvalid());
        // Wizard Footer
        store.dispatch(transitionWizardFooter.continueButtonDisabled(true));
    }

    return result;
};

// Clinical Criteria
const validateClinicalCriteria = formFields => {
    return formFields.cough && formFields.cough.status === FIELD_STATUS.VALID &&
    formFields.shortBreath && formFields.shortBreath.status === FIELD_STATUS.VALID &&
    formFields.highFever && formFields.highFever.status === FIELD_STATUS.VALID &&
    formFields.soreThroat && formFields.soreThroat.status === FIELD_STATUS.VALID;
};
