import { createSelector } from 'reselect';
import { getRoaCarInsuranceState } from './index';
import { FORM_STATUS } from '../../reducers/status';

export const getAboutYou = createSelector(
	getRoaCarInsuranceState,
	(state) => state.aboutYou
);

export const getAboutYouForm = createSelector(
	getAboutYou,
	(state) => state.form
);

export const getAboutYouFormIsValid = createSelector(
	getAboutYou,
	(state) => state.formStatus === FORM_STATUS.VALID
);

// Form field selectors
const makeGetField = fieldId => createSelector(
	getAboutYouForm,
	quotationForm => quotationForm[fieldId]
);

export const getFirstName = makeGetField('firstName');
export const getLastName = makeGetField('lastName');
export const getEmailAddress = makeGetField('emailAddress');
export const getPhoneNumber = makeGetField('phoneNumber');
export const getPhoneNumberNg = makeGetField('phoneNumberNg');

// for Nigeria
export const getStateOfOrigin = makeGetField('stateOfOrigin');
export const getLocalGovernmentArea = makeGetField('localGovernmentArea');
export const getResidentialArea = makeGetField('residentialAddress');
export const getAgentCode = makeGetField('agentCode');


export const getDropOffDetails = createSelector(
	getAboutYou,
	aboutYou => aboutYou.dropOffDetails,
);
