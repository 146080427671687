// Prefix
export const BENEFICIARIES_SET_VALUE =
  "taxFreeSavingsAccount/beneficiaries/setValue/";
export const BENEFICIARIES_SET_VALID =
  "taxFreeSavingsAccount/beneficiaries/setValid/";
export const BENEFICIARIES_RESET_VALUE =
  "taxFreeSavingsAccount/beneficiaries/resetValue/";

// Edit form
export const EDIT_BENEFICIARY_SET_FORM =
  BENEFICIARIES_SET_VALUE + "editBeneficiaryForm";
export const EDIT_BENEFICIARY_RESET_FORM =
  BENEFICIARIES_RESET_VALUE + "editBeneficiaryForm";
export const setEditBeneficiaryForm = (form) => ({
  type: EDIT_BENEFICIARY_SET_FORM,
  form,
});
export const resetEditBeneficiaryForm = () => ({
  type: EDIT_BENEFICIARY_RESET_FORM,
});

export const BENEFICIARY_DELETE = BENEFICIARIES_SET_VALUE + "deleteBeneficiary";
export const SIDE_DRAWER_SAVE = BENEFICIARIES_SET_VALUE + "saveSideDrawer";
export const deleteBeneficiary = () => ({ type: BENEFICIARY_DELETE });
export const saveSideDrawer = () => ({ type: SIDE_DRAWER_SAVE });

export const EDIT_ID_SET = BENEFICIARIES_SET_VALUE + "editId";
export const REMOVE_ID_SET = BENEFICIARIES_SET_VALUE + "removeId";
export const EDIT_DRAWER_TOGGLE = BENEFICIARIES_SET_VALUE + "toggleEditDrawer";
export const setEditId = (value) => ({ type: EDIT_ID_SET, value });
export const setRemoveId = (value) => ({ type: REMOVE_ID_SET, value });
export const toggleEditDrawer = () => ({ type: EDIT_DRAWER_TOGGLE });

export const BENEFICIARIES_LIST_UPDATE =
  BENEFICIARIES_SET_VALUE + "beneficiaries";
export const updateBeneficiariesList = (list) => ({
  type: BENEFICIARIES_LIST_UPDATE,
  list,
});

// Events - form input
export const TITLE_SET_VALUE = BENEFICIARIES_SET_VALUE + "title";
export const TITLE_SET_VALID = BENEFICIARIES_SET_VALID + "title";
export const TITLE_RESET_VALUE = BENEFICIARIES_RESET_VALUE + "title";
export const FIRST_NAME_SET_VALUE = BENEFICIARIES_SET_VALUE + "firstName";
export const FIRST_NAME_SET_VALID = BENEFICIARIES_SET_VALID + "firstName";
export const FIRST_NAME_RESET_VALUE = BENEFICIARIES_RESET_VALUE + "firstName";
export const SURNAME_SET_VALUE = BENEFICIARIES_SET_VALUE + "surname";
export const SURNAME_SET_VALID = BENEFICIARIES_SET_VALID + "surname";
export const SURNAME_RESET_VALUE = BENEFICIARIES_RESET_VALUE + "surname";
export const DATE_OF_BIRTH_SET_VALUE = BENEFICIARIES_SET_VALUE + "dateOfBirth";
export const DATE_OF_BIRTH_SET_VALID = BENEFICIARIES_SET_VALID + "dateOfBirth";
export const DATE_OF_BIRTH_RESET_VALUE =
  BENEFICIARIES_RESET_VALUE + "dateOfBirth";
export const GENDER_SET_VALUE = BENEFICIARIES_SET_VALUE + "gender";
export const GENDER_SET_VALID = BENEFICIARIES_SET_VALID + "gender";
export const GENDER_RESET_VALUE = BENEFICIARIES_RESET_VALUE + "gender";
export const MOBILE_NUMBER_SET_VALUE = BENEFICIARIES_SET_VALUE + "mobileNumber";
export const MOBILE_NUMBER_SET_VALID = BENEFICIARIES_SET_VALID + "mobileNumber";
export const MOBILE_NUMBER_RESET_VALUE =
  BENEFICIARIES_RESET_VALUE + "mobileNumber";
export const EMAIL_SET_VALUE = BENEFICIARIES_SET_VALUE + "email";
export const EMAIL_SET_VALID = BENEFICIARIES_SET_VALID + "email";
export const EMAIL_RESET_VALUE = BENEFICIARIES_RESET_VALUE + "email";

export const setTitleValue = (value) => ({ type: TITLE_SET_VALUE, value });
export const setTitleValid = (error) => ({ type: TITLE_SET_VALID, error });
export const setTitleUntouched = () => ({ type: TITLE_RESET_VALUE });
export const setFirstNameValue = (value) => ({
  type: FIRST_NAME_SET_VALUE,
  value,
});
export const setFirstNameValid = (error) => ({
  type: FIRST_NAME_SET_VALID,
  error,
});
export const setFirstNameUntouched = () => ({ type: FIRST_NAME_RESET_VALUE });
export const setSurnameValue = (value) => ({ type: SURNAME_SET_VALUE, value });
export const setSurnameValid = (error) => ({ type: SURNAME_SET_VALID, error });
export const setSurnameUntouched = () => ({ type: SURNAME_RESET_VALUE });
export const setDateOfBirthValue = (value) => ({
  type: DATE_OF_BIRTH_SET_VALUE,
  value,
});
export const setDateOfBirthValid = (error) => ({
  type: DATE_OF_BIRTH_SET_VALID,
  error,
});
export const setDateOfBirthUntouched = () => ({
  type: DATE_OF_BIRTH_RESET_VALUE,
});
export const setGenderValue = (value) => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = (error) => ({ type: GENDER_SET_VALID, error });
export const setGenderUntouched = () => ({ type: GENDER_RESET_VALUE });
export const setMobileValue = (value) => ({
  type: MOBILE_NUMBER_SET_VALUE,
  value,
});
export const setMobileValid = (error) => ({
  type: MOBILE_NUMBER_SET_VALID,
  error,
});
export const setMobileUntouched = () => ({ type: MOBILE_NUMBER_RESET_VALUE });
export const setEmailValue = (value) => ({ type: EMAIL_SET_VALUE, value });
export const setEmailValid = (error) => ({ type: EMAIL_SET_VALID, error });
export const setEmailUntouched = () => ({ type: EMAIL_RESET_VALUE });

export const RESET_BENEFICIARIES = BENEFICIARIES_SET_VALUE + "resetState";
export const resetBeneficiaries = () => ({ type: RESET_BENEFICIARIES });
