export const APP_URL_PREFIX = '/domestic-insurance';

export const PAGE_INDICES = {
    LANDING_PAGE: -1,
    QOUTE: 0,
    PERSONAL_DETAILS: 1,
    BUILDING_DETAILS: 2,
    DOCUMENTS: 3,
    PAYMENT: 4,
};

export const PAGES = [
	{
		URL: `${APP_URL_PREFIX}/get-quote`,
		URL_PART: 'get-quote',
		TITLE: 'Get Quote',
	},
	{
		URL: `${APP_URL_PREFIX}/personal-details`,
		URL_PART: 'personal-details',
		TITLE: 'Personal Details',
	},
	{
		URL: `${APP_URL_PREFIX}/building-details`,
		URL_PART: 'building-details',
		TITLE: 'Building Details',
	},
	{
		URL: `${APP_URL_PREFIX}/documents`,
		URL_PART: 'documents',
		TITLE: 'Documents Upload',
	},

	{
		URL: `${APP_URL_PREFIX}/payments`,
		URL_PART: 'payment',
		TITLE: 'Payment',
	},
];


export const COVER_PLANS = {
    HOUSE_BELONGINGS: { title: 'House and Contents', value: 'House and Contents' },
    HOUSE_ONLY: { title: 'House Only', value: 'House Only' },
    CONTENTS_ONLY: { title: 'Contents Only', value: 'Contents Only' },
};


export const TOGGLE_STATUS = {
    YES: true,
    NO: false,
    UNTOUCHED: 'untouched',
};

export const DOMESTIC_INSURANCE_URLS = {
	QOUTE: PAGES[PAGE_INDICES.QOUTE].URL,
	PERSONAL_DETAILS: PAGES[PAGE_INDICES.PERSONAL_DETAILS].URL,
	BUILDING_DETAILS: PAGES[PAGE_INDICES.BUILDING_DETAILS].URL,
	DOCUMENTS: PAGES[PAGE_INDICES.DOCUMENTS].URL,
	PAYMENT: PAGES[PAGE_INDICES.PAYMENT].URL
};
