const INVESTMENT_SETUP_CONTRIBUTION_TRANSITION = 'taxFreeSavingsAccount/investmentSetupContribution/transition/';

// Transitions
export const VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION = INVESTMENT_SETUP_CONTRIBUTION_TRANSITION + 'viewMoreToggle';
export const viewMoreToggle = () => ({ type: VIEW_MORE_TOGGLE_TRANSITION_INVESMENT_SETUP_CONTRIBUTION });

export const LIMIT_EXCEEDED_TRANSITION_INVESMENT_SETUP_CONTRIBUTION = INVESTMENT_SETUP_CONTRIBUTION_TRANSITION + 'limitExceededToggle';
export const limitExceededModalDisplayValue = value => ({ type: LIMIT_EXCEEDED_TRANSITION_INVESMENT_SETUP_CONTRIBUTION, value });

// Events - form input
export const INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE = 'taxFreeSavingsAccount/investmentSetupContribution/setValue/';
export const INVESTMENT_SETUP_CONTRIBUTION_RESET_VALUE = 'taxFreeSavingsAccount/investmentSetupContribution/resetValue/';
export const INVESTMENT_SETUP_CONTRIBUTION_SET_VALID = 'taxFreeSavingsAccount/investmentSetupContribution/setValid/';

export const CONTRIBUTION_RESET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_RESET_VALUE + 'contribution';
export const CONTRIBUTION_SET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE + 'contribution';
export const CONTRIBUTION_SET_VALID = INVESTMENT_SETUP_CONTRIBUTION_SET_VALID + 'contribution';
export const resetContributionValue = () => ({ type: CONTRIBUTION_RESET_VALUE });
export const setContributionValue = value => ({ type: CONTRIBUTION_SET_VALUE, value });
export const setContributionValid = error => ({ type: CONTRIBUTION_SET_VALID, error });

export const REGULAR_CONTRIBUTION_SET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE + 'regularContribution';
export const REGULAR_CONTRIBUTION_SET_VALID = INVESTMENT_SETUP_CONTRIBUTION_SET_VALID + 'regularContribution';
export const REGULAR_CONTRIBUTION_RESET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_RESET_VALUE + 'regularContribution';
export const LUMP_SUM_CONTRIBUTION_SET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE + 'lumpSumContribution';
export const LUMP_SUM_CONTRIBUTION_SET_VALID = INVESTMENT_SETUP_CONTRIBUTION_SET_VALID + 'lumpSumContribution';
export const LUMP_SUM_CONTRIBUTION_RESET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_RESET_VALUE + 'lumpSumContribution';
export const setRegularContributionValue = value => ({ type: REGULAR_CONTRIBUTION_SET_VALUE, value });
export const setRegularContributionValid = error => ({ type: REGULAR_CONTRIBUTION_SET_VALID, error });
export const resetRegularContributionValue = () => ({ type: REGULAR_CONTRIBUTION_RESET_VALUE });
export const setLumpSumContributionValue = value => ({ type: LUMP_SUM_CONTRIBUTION_SET_VALUE, value });
export const setLumpSumContributionValid = error => ({ type: LUMP_SUM_CONTRIBUTION_SET_VALID, error });
export const resetLumpSumContributionValue = () => ({ type: LUMP_SUM_CONTRIBUTION_RESET_VALUE });

export const PREMIUM_ESCALATION_RESET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_RESET_VALUE + 'premiumEscalation';
export const PREMIUM_ESCALATION_SET_VALID = INVESTMENT_SETUP_CONTRIBUTION_SET_VALID + 'premiumEscalation';
export const PREMIUM_ESCALATION_SET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE + 'premiumEscalation';
export const resetPremiumEscalation = () => ({ type: PREMIUM_ESCALATION_RESET_VALUE });
export const setPremiumEscalationValue = value => ({ type: PREMIUM_ESCALATION_SET_VALUE, value });
export const setPremiumEscalationValid = error => ({ type: PREMIUM_ESCALATION_SET_VALID, error });

export const OWN_PERCENTAGE_SET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE + 'ownPercentage';
export const OWN_PERCENTAGE_SET_VALID = INVESTMENT_SETUP_CONTRIBUTION_SET_VALID + 'ownPercentage';
export const OWN_PERCENTAGE_RESET_VALUE = INVESTMENT_SETUP_CONTRIBUTION_RESET_VALUE + 'ownPercentage';
export const setOwnPercentageValue = value => ({ type: OWN_PERCENTAGE_SET_VALUE, value });
export const setOwnPercentageValid = error => ({ type: OWN_PERCENTAGE_SET_VALID, error });
export const resetOwnPercentageValue = () => ({ type: OWN_PERCENTAGE_RESET_VALUE });

export const RESET_INVESTMENT_SETUP_CONTRIBUTION = INVESTMENT_SETUP_CONTRIBUTION_SET_VALUE + 'resetState';
export const resetInvestmentSetupContribution = () => ({ type: RESET_INVESTMENT_SETUP_CONTRIBUTION });