import axios from 'axios';
import {
    getDeathTermQuoteToolForm,
    getIsApiPending,
} from '../../../../../selectors/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import {
    apiTransitionDeathTermCoverQuoteTool,
	calculateDeathTermCoverSuccess,
    setWhichFormToDisplay,
} from '../../../../../actions/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';

export const deathTermCoverQuoteToolService = (store, next, action) => {
    (async () => {
        if (!getIsApiPending(store.getState())) {
            store.dispatch(apiTransitionDeathTermCoverQuoteTool.pending());
            try {
                const resp = await calculate(store.getState());
                store.dispatch(calculateDeathTermCoverSuccess(resp.data));
                store.dispatch(apiTransitionDeathTermCoverQuoteTool.success());
                store.dispatch(setWhichFormToDisplay('form-two'));
            } catch (e) {
                store.dispatch(apiTransitionDeathTermCoverQuoteTool.failure());
            }
        }
    })();
    return next(action);
};

const deathTermCoverPayload = (formData) => {
    const disability =
        formData.disabilityCoveredValue.value === 'Yes'
            ? formData.numberOfYearsCovered.value
            : '';
    return {
        dob: formData.candidateDOB.value,
        gender: formData.genderValue.value,
        txt_acc_death_dis_rider: disability,
        txt_death_term: formData.coverAmount.value,
        txt_death_terms: formData.numberOfYearsCovered.value,
        smoke_status: formData.smokerCoveredValue.value === 'Yes' ? 'S' : 'NS',
    };
};

const calculate = async (state) => {
    const formData = getDeathTermQuoteToolForm(state);
    try {
        const url = '/om-api/roa-quote-tools/death-term-cover-quote';
        return await axios.post(url, deathTermCoverPayload(formData));
    } catch (e) {
        return null;
    }
};
