import {
    AGE_OF_INSURED_SET_VALUE,
    CAR_MAKE_AND_MODEL_SET_VALUE,
    NUMBER_OF_ACCIDENTS_SET_VALUE,
    YEAR_OF_MANUFACTURER_SET_VALUE,
    ESTIMATED_VALUE_SET_VALUE,
    MANUFACTURER_YEAR_SET_VALUE,
    VEHICLE_MODEL_SET_VALUE,
    VEHICLE_MAKE_SET_VALUE,
    VEHICLE_USE_SET_VALUE,
    setAgeOfInsuredValid,
    setCarAndModelValid,
    setNoOfAccidentsValid,
    setYearOfManufacturerValid,
    setEstimatedValid,
    setManufacturerYearValid,
    setVehicleMakeValid,
    setVehicleModelValid,
    setVehicleUseValid
} from '../../../actions/roaCarInsurance/quotation';
import { validationIsEqual } from '../status';
import {
    validateYearOfManufacture,
} from '@om/validation';
import { validateSelection, validateNumber } from '@om/validation';

let prevValidationAction = null;
export const quotationValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case YEAR_OF_MANUFACTURER_SET_VALUE:
                error = validateYearOfManufacture(action.value, { max: 15 });
                return setYearOfManufacturerValid(error);
            case CAR_MAKE_AND_MODEL_SET_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 's'  });
                return setCarAndModelValid(error);
            case NUMBER_OF_ACCIDENTS_SET_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 's' });
                return setNoOfAccidentsValid(error);
            case AGE_OF_INSURED_SET_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 's' });
                return setAgeOfInsuredValid(error);
            case ESTIMATED_VALUE_SET_VALUE:
                error = validateNumber(action.value);
                return setEstimatedValid(error);
            case MANUFACTURER_YEAR_SET_VALUE:
                error = validateYearOfManufacture(action.value, { max: 15, subject: 'Year of manufacture' });
                return setManufacturerYearValid(error);
            case VEHICLE_MAKE_SET_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Vehicle make'  });
                return setVehicleMakeValid(error);
            case VEHICLE_MODEL_SET_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Model'  });
                return setVehicleUseValid(error);
            case VEHICLE_USE_SET_VALUE:
                error = validateSelection(action.value, { min: 1, subject: 'Vehicle use'  });
                return setVehicleModelValid(error);
            default:
                return null;
        }
    })();
    if (
        !validationAction ||
        (validationIsEqual(validationAction, prevValidationAction) &&
            action.value.length > 1)
    ) {
        return next(action);
    }
    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
