import { transitionMotorInsuranceQuoteForm } from '../../../actions/ugMotorInsurance/quote';
import { getQuoteForm } from '../../../selectors/ugMotorInsurance/quote';

import { FIELD_STATUS } from '../../../reducers/status';

export const quoteFormRules = (store, next, action) => {
    const result = next(action);
    const quoteForm = getQuoteForm(store.getState());
    const quoteFormValid = validateQuoteForm(quoteForm);
    const formAction = quoteFormValid
        ? transitionMotorInsuranceQuoteForm.formValid()
        : transitionMotorInsuranceQuoteForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateQuoteForm = (formFields) => {
    const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;
    if (gatsbyStack === 'ke' && formFields.selectedCover.value === 'motorComprehensive') {
        return (
            //formFields.carMake.status === FIELD_STATUS.VALID &&
            formFields.carModel.status === FIELD_STATUS.VALID &&
            formFields.yearOfManufacture.status === FIELD_STATUS.VALID &&
            formFields.valuation.status === FIELD_STATUS.VALID &&
            formFields.coverStartDate.status === FIELD_STATUS.VALID &&
            formFields.carUse.status === FIELD_STATUS.VALID &&
            formFields.hasAlarm.status === FIELD_STATUS.VALID &&
            formFields.vehicleType.status === FIELD_STATUS.VALID
            // formFields.carValuer.status === FIELD_STATUS.VALID
        );
    }
    if (gatsbyStack === 'ke' && formFields.selectedCover.value === 'ThirdParty') {
        return (
            //formFields.carMake.status === FIELD_STATUS.VALID &&
            formFields.carModel.status === FIELD_STATUS.VALID &&
            formFields.coverStartDate.status === FIELD_STATUS.VALID &&
            formFields.carUse.status === FIELD_STATUS.VALID &&
            formFields.hasAlarm.status === FIELD_STATUS.VALID &&
            formFields.vehicleType.status === FIELD_STATUS.VALID
            // formFields.carValuer.status === FIELD_STATUS.VALID
        );
    }
    if (gatsbyStack === 'ug') {
        return (
            formFields.carMake &&
            formFields.carMake.status === FIELD_STATUS.VALID &&
            formFields.carModel.status === FIELD_STATUS.VALID &&
            formFields.carModel &&
            formFields.yearOfManufacture.status === FIELD_STATUS.VALID &&
            formFields.valuation &&
            formFields.valuation.status === FIELD_STATUS.VALID &&
            formFields.coverStartDate &&
            formFields.coverStartDate.status === FIELD_STATUS.VALID &&
            formFields.hasAlarm &&
            formFields.hasAlarm.status === FIELD_STATUS.VALID &&
            formFields.isCarRare &&
            formFields.isCarRare.status === FIELD_STATUS.VALID &&
            formFields.fullName &&
            formFields.fullName.status === FIELD_STATUS.VALID &&
            formFields.phoneNumber &&
            formFields.phoneNumber.status === FIELD_STATUS.VALID &&
            formFields.email &&
            formFields.email.status === FIELD_STATUS.VALID &&
            formFields.idNumber &&
            formFields.idNumber.status === FIELD_STATUS.VALID &&
            formFields.outsideEa &&
            formFields.outsideEa.status === FIELD_STATUS.VALID &&
            formFields.firstTimePurchase &&
            formFields.firstTimePurchase.status === FIELD_STATUS.VALID
        );
    }
};
