export const AFYA_IMARA_JUNIOR_QUOTE_TOOL = 'roaQuoteTools/afyaImaraJuniorQuoteTool/';
export const AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE = AFYA_IMARA_JUNIOR_QUOTE_TOOL + 'setValue/';
export const AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALID = AFYA_IMARA_JUNIOR_QUOTE_TOOL + 'setValid/';

export const FORM_VALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL = 'roaQuoteTools/AFYA_IMARA_JUNIOR/transition/formStateValid';
export const FORM_INVALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL = 'roaQuoteTools/AFYA_IMARA_JUNIOR/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/AFYA_IMARA_JUNIOR/state/reinitialize';

export const transitionAfyaImaraJuniorTool = {
    formValid: () => ({ type: FORM_VALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_AFYA_IMARA_JUNIOR_QUOTE_TOOL }),
};

export const CHILDREN_SET_VALUE = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'childrenValue';
export const CHILDREN_SET_VALID = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALID + 'childrenValue';
export const MEDICAL_PROVIDERS_SCOPE_SET_VALUE = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'medicalProvidersScopeValue';
export const MEDICAL_PROVIDERS_SCOPE_SET_VALID = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALID + 'medicalProvidersScopeValue';
export const INPATIENT_COVER_LIMIT_SET_VALUE = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'inpatientCoverLimitValue';
export const INPATIENT_COVER_LIMIT_SET_VALID = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALID + 'inpatientCoverLimitValue';
export const OUTPATIENT_COVER_LIMIT_SET_VALUE = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'outpatientCoverLimitValue';
export const OUTPATIENT_COVER_LIMIT_SET_VALID = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALID + 'outpatientCoverLimitValue';
export const WHICH_FORM_TO_DISPLAY = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'whichFormToDisplay';

export const COLLECT_AFYA_IMARA_JUNIOR_DATA = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'collectAfyaImaraJuniorData';
export const CALCULATE_AFYA_IMARA_JUNIOR_DATA = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'success';
export const CALCULATE_AFYA_IMARA_JUNIOR_DATA_FAILURE = AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL = `${AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL = `${AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL = `${AFYA_IMARA_JUNIOR_QUOTE_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionAfyaImaraJuniorQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_AFYA_IMARA_JUNIOR_QUOTE_TOOL }),
};

export const collectAfyaImaraJuniorData = () => ({ type: COLLECT_AFYA_IMARA_JUNIOR_DATA });
export const calculateAfyaImaraJuniorData = (value) => ({ type: CALCULATE_AFYA_IMARA_JUNIOR_DATA, value});
export const collectAfyaImaraJuniorDataFailure = error => ({ type: CALCULATE_AFYA_IMARA_JUNIOR_DATA_FAILURE, error });

export const setChildrenValue = value => ({ type: CHILDREN_SET_VALUE, value });
export const setChildrenValid = error => ({ type: CHILDREN_SET_VALID, error });
export const setMedicalProvidersScopeValue = value => ({ type: MEDICAL_PROVIDERS_SCOPE_SET_VALUE, value });
export const setMedicalProvidersScopeValid = error => ({ type: MEDICAL_PROVIDERS_SCOPE_SET_VALID, error });
export const setInpatientCoverLimitValue = value => ({ type: INPATIENT_COVER_LIMIT_SET_VALUE, value });
export const setInpatientCoverLimitValid = error => ({ type: INPATIENT_COVER_LIMIT_SET_VALID, error });
export const setOutpatientCoverLimitValue = value => ({ type: OUTPATIENT_COVER_LIMIT_SET_VALUE, value });
export const setOutpatientCoverLimitValid = error => ({ type: OUTPATIENT_COVER_LIMIT_SET_VALID, error });
export const setWhichFormToDisplay = value => ({ type: WHICH_FORM_TO_DISPLAY, value });
export const setToAfyaImaraJuniorQuoteInitial = () => ({ type: REINITIALIZE });
