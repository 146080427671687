import {
    setSplitPercentageValid,
    SPLIT_PERCENTAGE_SET_VALUE,
} from "../../../actions/taxFreeSavingsAccount/fundSplit";
import {validatePercent} from "@om/validation";
import {FUND_SPLIT_FORM} from "../../../actions/taxFreeSavingsAccount/types";
import {
    getLumpSumContribution,
    getRegularContribution
} from "../../../selectors/taxFreeSavingsAccount/investmentSetupContribution";

export const fundSplitValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;
        switch (action.type) {
            case SPLIT_PERCENTAGE_SET_VALUE:
                error = validatePercent(action.value);
                if (!error) {
                    const state = store.getState();
                    // Recalculated contribution amount for validation as this has not hit reducer yet
                    if (action.fundType === FUND_SPLIT_FORM.REGULAR) {
                        const regularContribution = getRegularContribution(state);
                        if ((regularContribution.value * (action.value / 100)) < 100) {
                            error = 'You must invest a minimum of R 100 in each fund.';
                        }
                    }
                    
                    if (action.fundType === FUND_SPLIT_FORM.LUMP_SUM) {
                        const lumpSumContribution = getLumpSumContribution(state);
                        if ((lumpSumContribution.value * (action.value / 100)) < 100) {
                            error = 'You must invest a minimum of R 100 in each fund.';
                        }
                    }
                }
                return setSplitPercentageValid(error, action.fundId, action.fundType);

            default:
                return null;
        }
    })();

    if (!validationAction) {
        return next(action);
    }

    store.dispatch(validationAction)
    return next(action);
};
