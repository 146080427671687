import {
  setTitleValue,
  setFirstNamesValue,
  setSurnameValue,
  setContactNumberValue,
  setEmailValue,
  setContactForFeedbackValue,
  setContactMethodValue,
} from "../../../actions/taxFreeSavingsAccount/personalDetails";
import { getPersonalDetailsForm } from "../../../selectors/taxFreeSavingsAccount/personalDetails";
import { scrollToError } from "../../../actions/taxFreeSavingsAccount/scrolling";
import { TFSA_PERSONAL_DETAILS_SCROLL_ID } from "../status";

export const personalDetailsRules = (store, next, action) => {
  const result = next(action);
  const state = store.getState();
};

export const performPersonalDetailsValidation = (store) => {
  const state = store.getState();
  const personalDetailsForm = getPersonalDetailsForm(state);

  // Fields shared accross all form variations
  store.dispatch(setTitleValue(personalDetailsForm.title.value));
  store.dispatch(setFirstNamesValue(personalDetailsForm.firstNames.value));
  store.dispatch(setSurnameValue(personalDetailsForm.surname.value));

  store.dispatch(setContactNumberValue(personalDetailsForm.contactNumber.value));
  store.dispatch(setEmailValue(personalDetailsForm.email.value));
  store.dispatch(setContactForFeedbackValue(personalDetailsForm.contactForFeedback.value));
  store.dispatch(setContactMethodValue(personalDetailsForm.contactMethod.value));

  const form = getPersonalDetailsForm(store.getState());
  store.dispatch(scrollToError(TFSA_PERSONAL_DETAILS_SCROLL_ID, form));
};
