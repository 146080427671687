import {BIOGRAPHIC_INFO_SET_VALUE} from "../../../actions/covidSelfAssessment/biographicInfo";
import {biographicInfoValidation} from "./biographicInfo";
import {CLINICAL_CRITERIA_SET_VALUE} from "../../../actions/covidSelfAssessment/clinicalCriteria";
import {clinicalCriteriaValidation} from "./clinicalCriteria";
import {TRAVEL_HISTORY_SET_VALUE} from "../../../actions/covidSelfAssessment/travelHistory";
import {travelHistoryValidation} from "./travelHistory";

export const covidSelfAssessmentValidation = (store, next, action) => {
    if (action.type.startsWith(BIOGRAPHIC_INFO_SET_VALUE))
        return biographicInfoValidation(store, next, action);

    if (action.type.startsWith(CLINICAL_CRITERIA_SET_VALUE))
        return clinicalCriteriaValidation(store, next, action);

    if (action.type.startsWith(TRAVEL_HISTORY_SET_VALUE))
        return travelHistoryValidation(store, next, action);

    return null;
};
