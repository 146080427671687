export const CAR_INSURANCE_AGENT_MODAL_DETAILS =
	'roaCarInsurance/carAgentModalDetails/';

export const CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE =
	CAR_INSURANCE_AGENT_MODAL_DETAILS + 'setValue/';
export const CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID =
	CAR_INSURANCE_AGENT_MODAL_DETAILS + 'setValid/';

export const MODAL_FORM_DISPLAY =
	CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE + 'modalFormDisplay';

export const REINITIALIZE =
	'roaCarInsurance/carAgentModalDetails/state/reinitialize';

export const FORM_VALID_CAR_INSURANCE_AGENT_MODAL_FORM =
	'roaCarInsurance/carAgentModalDetails/transition/formValid';
export const FORM_INVALID_CAR_INSURANCE_AGENT_MODAL_FORM =
	'roaCarInsurance/carAgentModalDetails/transition/formInValid';

export const transitionAgentModalForm = {
	formValid: () => ({
		type: FORM_VALID_CAR_INSURANCE_AGENT_MODAL_FORM,
	}),
	formInvalid: () => ({
		type: FORM_INVALID_CAR_INSURANCE_AGENT_MODAL_FORM,
	}),
};

export const setCARAgentModalDisplay = value => ({
	type: MODAL_FORM_DISPLAY,
	value,
});

export const setAgentModalToInitial = () => ({
	type: REINITIALIZE,
});

export const AGENT_NAME_SET_VALUE =
	CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE + 'name';
export const AGENT_NAME_SET_VALID =
	CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID + 'name';

export const AGENT_PHONE_NUMBER_SET_VALUE =
	CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALUE + 'phoneNumber';
export const AGENT_PHONE_NUMBER_SET_VALID =
	CAR_INSURANCE_AGENT_MODAL_DETAILS_SET_VALID + 'phoneNumber';

export const setNameValue = value => ({
	type: AGENT_NAME_SET_VALUE,
	value,
});
export const setNameValid = error => ({
	type: AGENT_NAME_SET_VALID,
	error,
});

export const setPhoneNumberValue = value => ({
	type: AGENT_PHONE_NUMBER_SET_VALUE,
	value,
});
export const setPhoneNumberValid = error => ({
	type: AGENT_PHONE_NUMBER_SET_VALID,
	error,
});
