import { SCROLL_TO_ERROR_TRANSITION_TFSA, scrollToId } from "../../../actions/taxFreeSavingsAccount/scrolling";
import {
    TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID,
    TFSA_INVESTMENT_SETUP_CONTRIBUTION_PAGE_ANCHORS,
    TFSA_BENEFICIARIES_SIDE_DRAWER_ID,
    TFSA_PAYMENT_DETAILS_LEGAL_DETAILS_ID,
    TFSA_VIEW_MORE_FUNDS_ANCHOR_ID
} from "../status";
import {
    ON_BEHALF_OF_CHILD_SET_VALUE,
    LEGAL_GUARDIAN_SET_VALUE,
    HAS_OTHER_TAX_FREE_PLAN_SET_VALUE,
    SAVING_FOR_SET_VALUE
} from "../../../actions/taxFreeSavingsAccount/investmentSetupInitial";
import {
    CONTRIBUTION_SET_VALUE,
    LUMP_SUM_CONTRIBUTION_SET_VALUE,
    REGULAR_CONTRIBUTION_SET_VALUE
} from "../../../actions/taxFreeSavingsAccount/investmentSetupContribution";
import { getAreContributionValuesValid } from "../../../selectors/taxFreeSavingsAccount/investmentSetupContribution";
import { EDIT_ID_SET  } from "../../../actions/taxFreeSavingsAccount/beneficiaries";
import { ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS } from "../../../actions/taxFreeSavingsAccount/paymentDetails";
import { ACCOUNT_VALIDATION_STATUS, ANCHORING_DELAY_MS, CONTRIBUTION_TYPE } from "../../../actions/taxFreeSavingsAccount/types";
import { FUNDS_TOGGLE_SHOW_MORE } from "../../../actions/taxFreeSavingsAccount/advancedFundPicker";
import { getIsShowingMoreFunds } from "../../../selectors/taxFreeSavingsAccount/advancedFundPicker";

export const scrollingRules = (store, action) => {
    const state = store.getState();

    // Scroll to error
    if (action.type === SCROLL_TO_ERROR_TRANSITION_TFSA) {
        store.dispatch(scrollToId(getFirstErrorKey(action.array, action.form)));
        return;
    }

    // Progressive disclosure anchoring
    setTimeout(() => {
        const id = getAnchorFromAction(state, action);
        if (id) {
            store.dispatch(scrollToId(id));
        }
    }, ANCHORING_DELAY_MS);

    return;
};

export const getFirstErrorKey = (array, form) => {
    return array.find((key) => key in form && form[key].error);
};

export const getAnchorFromAction = (state, action) => {
    switch (action.type) {
        // Investment setup initial
        case ON_BEHALF_OF_CHILD_SET_VALUE:
            return action.value === 'false'
            ? TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID.HAS_OTHER_TAX_FREE_PLAN
            : TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID.LEGAL_GUARDIAN;

        case LEGAL_GUARDIAN_SET_VALUE:
            return TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID.HAS_OTHER_TAX_FREE_PLAN;

        case HAS_OTHER_TAX_FREE_PLAN_SET_VALUE:
            return action.value === 'false'
            ? TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID.SAVING_FOR
            : TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID.ALTERNATIVE_SOLUTIONS;

        case SAVING_FOR_SET_VALUE:
            return TFSA_INVESTMENT_SETUP_INITIAL_SCROLL_SECTION_ID.YOUR_GOAL;

        // Investment setup contribution
        case CONTRIBUTION_SET_VALUE:
            if (action.value === CONTRIBUTION_TYPE.MONTHLY) return TFSA_INVESTMENT_SETUP_CONTRIBUTION_PAGE_ANCHORS.REGULAR_CONTRIBUTION;
            else if (action.value !== null) {
                return TFSA_INVESTMENT_SETUP_CONTRIBUTION_PAGE_ANCHORS.LUMP_SUM_CONTRIBUTION;
            };
            break;

        case LUMP_SUM_CONTRIBUTION_SET_VALUE:
        case REGULAR_CONTRIBUTION_SET_VALUE:
            const contributionValuesValid = getAreContributionValuesValid(state);
            if (contributionValuesValid) {
                return TFSA_INVESTMENT_SETUP_CONTRIBUTION_PAGE_ANCHORS.PREMIUM_ESCALATION;
            }
            break;

        // Advanced Fund Selection
        case FUNDS_TOGGLE_SHOW_MORE:
            if (!getIsShowingMoreFunds(state))
                return TFSA_VIEW_MORE_FUNDS_ANCHOR_ID;

            break;


        // Beneficiaries
        case EDIT_ID_SET:
            return TFSA_BENEFICIARIES_SIDE_DRAWER_ID;

        // Payment details
        case ACCOUNT_VALIDATION_STATUS_PAYMENT_DETAILS:
            if (action.value === ACCOUNT_VALIDATION_STATUS.IDLE)
                return TFSA_PAYMENT_DETAILS_LEGAL_DETAILS_ID;

            break;

        default:
            return null;
    }
}