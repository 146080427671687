import { API_CALL_STATUS, FIELD_STATUS, FORM_STATUS } from '../status';

import {
    REINITIALIZE,
    FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    API_FAILURE_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    API_SUCCESS_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    API_PENDING_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM,
    COLLECT_PERSONAL_PENSION_PLAN_QUOTE_FORM_DATA,
    SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS,
    SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_FAILURE,
    PERSONAL_PENSION_PLAN_SIDEBAR_OPEN_VALUE,
    PERSONAL_PENSION_PLAN_SHARE_QUOTE_VALUE,
    SET_SHARE_EMAIL_VALUE,
    SET_SHARE_EMAIL_VALID,
    FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM,
    FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM,
    COLLECT_SHARE_QUOTE_FORM_DATA,
    SHARE_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS,
    SHARE_PERSONAL_PENSION_PLAN_QUOTE_FAILURE,
    API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM,
    API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM,
    API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM,
    TOKEN_IS_EXPIRED,
    PHONE_NUMBER_SET_VALUE,
    PHONE_NUMBER_SET_VALID,
    GENDER_SET_VALUE,
    GENDER_SET_VALID,
    CONTRIBUTION_MODE_SET_VALUE,
    CONTRIBUTION_MODE_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    INTEREST_RATE_SET_VALUE,
    INTEREST_RATE_SET_VALID,
    DESIRED_MONTHLY_CONTRIBUTION_SET_VALUE,
    DESIRED_MONTHLY_CONTRIBUTION_SET_VALID,
    LUMPSUM_AMOUNT_SET_VALUE,
    LUMPSUM_AMOUNT_SET_VALID,
    DESIRED_RETIREMENT_AGE_SET_VALUE,
    DESIRED_RETIREMENT_AGE_SET_VALID,
    FREQUENCY_OF_CONTRIBUTION_SET_VALID,
    FREQUENCY_OF_CONTRIBUTION_SET_VALUE,
    PERSONAL_PENSION_PLAN_QUOTE_ADD_COVER,
    CALCULATE_PERSONAL_PENSION_PLAN_PREMIUM_SUCCESS,
    MATURITY_YEARS_SET_VALUE,
    SET_IS_LUMPSUM_PREPAID,
    IS_LUMPSUM_PREPAID_VALID,
    PREPAID_TRANSACTION_CODE_VALUE,
    PREPAID_TRANSACTION_CODE_VALID,
    PREPAID_MODE_OF_PAYMENT_VALUE,
    PREPAID_MODE_OF_PAYMENT_VALID,
    API_PENDING_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY,
    API_FAILURE_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY,
    API_SUCCESS_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY,
} from '../../actions/personalPensionPlan/quote';

export const initQuote = {
    sidebarOpen: false,
    formStatus: FORM_STATUS.INVALID,
    apiCallStatus: null,
    quoteReferenceData: null,
    addedCover: null,
    showShareModal: false,
    shareQuoteFormStatus: FORM_STATUS.INVALID,
    shareQuoteAPIStatus: null,
    quoteShared: null,
    isTokenExpired: false,
    ugCmbObject: {
        __typename: 'Contentstack_call_me_back_ke',
        product: 'Personal Pension Plan Kenya',
        privacy_policy_url: '/privacy-policy',
        extra_fields: ['Email Address'],
    },
    premiumCalculation: {
        value: null,
        status: null,
        error: null,
    },
    maturityYears: null,
    prepaidLumpsumCheckApiStatus: null,
    form: {
        shareEmail: { value: null, error: null, status: null },
        phoneNumber: { value: null, error: null, status: null },
        gender: { value: null, error: null, status: null },
        contributionMode: { value: null, error: null, status: null },
        dob: { value: null, error: null, status: null },
        interestRate: { value: null, error: null, status: null },
        desiredMonthlyContribution: { value: null, error: null, status: null },
        lumpsumAmount: { value: null, error: null, status: null },
        desiredRetirementAge: { value: null, error: null, status: null },
        frequencyOfContribution: { value: 12, error: null, status: null },
        isLumpsumPrepaid: { value: null, error: null, status: null },
        prepaidTransactionCode: { value: null, error: null, status: null },
        prepaidModeOfPayment: { value: 'mpesa', error: null, status: null },
    },
};

export default (state = initQuote, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        case FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };
        case FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_FORM:
            return {
                ...state,
                formStatus: FORM_STATUS.INVALID,
            };

        case FORM_VALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case FORM_INVALID_PERSONAL_PENSION_PLAN_QUOTE_SHARE_FORM:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.INVALID,
            };

        // Sidebar open
        case PERSONAL_PENSION_PLAN_SIDEBAR_OPEN_VALUE:
            return {
                ...state,
                sidebarOpen: action.value,
            };

        // Share modal
        case PERSONAL_PENSION_PLAN_SHARE_QUOTE_VALUE:
            return {
                ...state,
                showShareModal: action.value,
            };

        case COLLECT_SHARE_QUOTE_FORM_DATA:
            return {
                ...state,
                shareQuoteFormStatus: FORM_STATUS.VALID,
            };

        case SHARE_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS:
            return {
                ...state,
                quoteShare: action.value,
            };

        case SHARE_PERSONAL_PENSION_PLAN_QUOTE_FAILURE:
            return {
                ...state,
                quoteShare: action.error,
            };

        case SET_SHARE_EMAIL_VALUE:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                value: action.value,
            }));

        case SET_SHARE_EMAIL_VALID:
            return updateFormField('shareEmail', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case SET_IS_LUMPSUM_PREPAID:
            return updateFormField('isLumpsumPrepaid', (field) => ({
                ...field,
                value: action.value,
            }));

        case IS_LUMPSUM_PREPAID_VALID:
            return updateFormField('isLumpsumPrepaid', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case PREPAID_TRANSACTION_CODE_VALUE:
            return updateFormField('prepaidTransactionCode', (field) => ({
                ...field,
                value: action.value,
            }));

        case PREPAID_TRANSACTION_CODE_VALID:
            return updateFormField('prepaidTransactionCode', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case PREPAID_MODE_OF_PAYMENT_VALUE:
            return updateFormField('prepaidModeOfPayment', (field) => ({
                ...field,
                value: action.value,
            }));

        case PREPAID_MODE_OF_PAYMENT_VALID:
            return updateFormField('prepaidModeOfPayment', (field) => ({
                ...field,
                status: mapError(action.error),
                error: action.error,
            }));

        case TOKEN_IS_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.value,
            };

        case CALCULATE_PERSONAL_PENSION_PLAN_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumCalculation: {
                    ...state.premiumCalculation,
                    value: action.value,
                    status: API_CALL_STATUS.SUCCESS,
                },
            };

        case MATURITY_YEARS_SET_VALUE:
            return {
                ...state,
                maturityYears: action.value,
            };

        // API Calls

        case API_PENDING_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.PENDING,
            };
        case API_SUCCESS_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.SUCCESS,
            };
        case API_FAILURE_TRANSITION_PERSONAL_PENSION_PLAN_QUOTE_FORM:
            return {
                ...state,
                apiCallStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.PENDING,
            };

        case API_SUCCESS_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.SUCCESS,
            };

        case API_FAILURE_TRANSITIONS_SHARE_QUOTE_FORM:
            return {
                ...state,
                shareQuoteAPIStatus: API_CALL_STATUS.FAILURE,
            };

        case API_PENDING_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY:
            return {
                ...state,
                prepaidLumpsumCheckApiStatus: API_CALL_STATUS.PENDING,
            };

        case API_FAILURE_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY:
            return {
                ...state,
                prepaidLumpsumCheckApiStatus: API_CALL_STATUS.FAILURE,
            };

        case API_SUCCESS_TRANSITION_PREPAID_TRANSACTION_CODE_VALIDITY:
            return {
                ...state,
                prepaidLumpsumCheckApiStatus: API_CALL_STATUS.SUCCESS,
            };

        // Submit user data
        case COLLECT_PERSONAL_PENSION_PLAN_QUOTE_FORM_DATA:
            return {
                ...state,
                formStatus: FORM_STATUS.VALID,
            };

        case SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_SUCCESS:
            return {
                ...state,
                quoteReferenceData: action.value,
            };

        case SUBMIT_PERSONAL_PENSION_PLAN_QUOTE_FAILURE:
            return {
                ...state,
                quoteReferenceData: action.error,
            };

        //new values
        case PHONE_NUMBER_SET_VALUE:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case PHONE_NUMBER_SET_VALID:
            return updateFormField('phoneNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case GENDER_SET_VALUE:
            return updateFormField('gender', (field) => ({
                ...field,
                value: action.value,
            }));
        case GENDER_SET_VALID:
            return updateFormField('gender', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CONTRIBUTION_MODE_SET_VALUE:
            return updateFormField('contributionMode', (field) => ({
                ...field,
                value: action.value,
            }));
        case CONTRIBUTION_MODE_SET_VALID:
            return updateFormField('contributionMode', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DOB_SET_VALUE:
            return updateFormField('dob', (field) => ({
                ...field,
                value: action.value,
            }));
        case DOB_SET_VALID:
            return updateFormField('dob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case INTEREST_RATE_SET_VALUE:
            return updateFormField('interestRate', (field) => ({
                ...field,
                value: action.value,
            }));
        case INTEREST_RATE_SET_VALID:
            return updateFormField('interestRate', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DESIRED_MONTHLY_CONTRIBUTION_SET_VALUE:
            return updateFormField('desiredMonthlyContribution', (field) => ({
                ...field,
                value: action.value,
            }));
        case DESIRED_MONTHLY_CONTRIBUTION_SET_VALID:
            return updateFormField('desiredMonthlyContribution', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case LUMPSUM_AMOUNT_SET_VALUE:
            return updateFormField('lumpsumAmount', (field) => ({
                ...field,
                value: action.value,
            }));
        case LUMPSUM_AMOUNT_SET_VALID:
            return updateFormField('lumpsumAmount', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case DESIRED_RETIREMENT_AGE_SET_VALUE:
            return updateFormField('desiredRetirementAge', (field) => ({
                ...field,
                value: action.value,
            }));
        case DESIRED_RETIREMENT_AGE_SET_VALID:
            return updateFormField('desiredRetirementAge', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case FREQUENCY_OF_CONTRIBUTION_SET_VALUE:
            return updateFormField('frequencyOfContribution', (field) => ({
                ...field,
                value: action.value,
            }));
        case FREQUENCY_OF_CONTRIBUTION_SET_VALID:
            return updateFormField('frequencyOfContribution', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PERSONAL_PENSION_PLAN_QUOTE_ADD_COVER:
            return {
                ...state,
                addedCover: action.value,
            };

        // Reset state
        case REINITIALIZE:
            return initQuote;
        default:
            return state;
    }
};
