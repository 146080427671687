import axios from 'axios';

import {
    FUNERAL_CLAIM_V2_SUBMIT_FORM,
    apiTransitionFuneralClaim,
    FUNERAL_CLAIM_DHA_API_REQUEST,
    dhaApiFuneralClaimVerification,
    setDeceasedVerifiedDha,
    FUNERAL_CLAIM_BANK_CDV_REQUEST,
    setCdvVerifiedBankAccount,
    cdvApiFuneralClaimVerification,
    allApiSubmissionsStatus,
    preSubmitApiFuneralClaim,
    FUNERAL_CLAIM_PRESUBMIT_REQUEST,
    setPreSubmitServiceRequest,
} from '../../../actions/publicWeb/funeralClaimV2';

import {
    getDontFireApi,
    getFuneralClaimPostData,
    getDeceasedId,
    getFuneralClaimCdvPostData,
    getFuneralClaimPresubmitInsecurePostData,
    getIsStillborn,
    getFuneralClaimSubmitFormServiceRequestData,
} from '../../../selectors/publicWeb/funeralClaimV2';

export const submitFuneralClaim = async store => {
    const state = store.getState();

    if (!getDontFireApi(state)) {
        store.dispatch(apiTransitionFuneralClaim.pending());
        const postBody = getFuneralClaimSubmitFormServiceRequestData(state);

        try {
            const check = await axios.post(
                '/om-api/funeral-claim/submit-claim-v2',
                postBody
            );
            store.dispatch(allApiSubmissionsStatus.success());
            store.dispatch(apiTransitionFuneralClaim.success());
            return true;
        } catch (error) {
            store.dispatch(allApiSubmissionsStatus.failure());
            store.dispatch(apiTransitionFuneralClaim.failure());
            return false;
        }
    }

    
}

export const verifyDeceasedIdDha = async (store) => {
    const state = store.getState();

    store.dispatch(dhaApiFuneralClaimVerification.pending());
    if (!getDontFireApi(state) && !getIsStillborn(state)) {
        store.dispatch(allApiSubmissionsStatus.pending());
        try {
            const postBody = {
                deceasedIdNumber: getDeceasedId(state),
            };
            
            const response = await axios.post(
                `/om-api/funeral-claim/dha/validate`,
                postBody
            );
            store.dispatch(dhaApiFuneralClaimVerification.success());
            store.dispatch(setDeceasedVerifiedDha(response.data));
            return true;
        } catch (error) {
            store.dispatch(dhaApiFuneralClaimVerification.failure());
            return false;
        }
    }

    if (!getDontFireApi(state) && getIsStillborn(state)) {
        store.dispatch(allApiSubmissionsStatus.pending());
        store.dispatch(dhaApiFuneralClaimVerification.success());
        return true;
    }
};

function cdvCheckAccountStatus(data) {
    if(data.fieldValidations.length > 0) {
        const resultCode = data.fieldValidations[0].resultCode;
        switch (resultCode) {
            case '2':   // - SUCCESS
            case '2.2': // - Bank open for less than 3 months
            case '2.3': // - CDV could not run - service unavailable
                return true;
            case '2.1': // - INVALID_ACCOUNT
                return false
        }
    }
    return false;
}

export const checkBankAccountCdv = async(store) => {
    const state = store.getState();
    if (!getDontFireApi(state)) {
        store.dispatch(cdvApiFuneralClaimVerification.pending());
        try {
            const postBody = getFuneralClaimCdvPostData(state);
            const response = await axios.post(
                `/om-api/funeral-claim/banking/cdv`,
                postBody
            );
            if (response && response.status === 200 && response.data && cdvCheckAccountStatus(response.data)) {
                store.dispatch(cdvApiFuneralClaimVerification.success());
                store.dispatch(setCdvVerifiedBankAccount(response.data));
                return true;
            } else {
                store.dispatch(allApiSubmissionsStatus.failure());
                store.dispatch(cdvApiFuneralClaimVerification.failure());
                return false;
            }
        } catch (error) {
            store.dispatch(allApiSubmissionsStatus.failure());
            store.dispatch(cdvApiFuneralClaimVerification.failure());
            return false;
        }
    }
};

export const preSubmitFuneralClaim = async(store) => {
    const state = store.getState();
    if (!getDontFireApi(state)) {
        store.dispatch(preSubmitApiFuneralClaim.pending());
        try {
            const postBody = getFuneralClaimPresubmitInsecurePostData(state);
            const response = await axios.post(
                `/om-api/funeral-claim/presubmit/insecure`,
                postBody
            );
            store.dispatch(preSubmitApiFuneralClaim.success());
            store.dispatch(setPreSubmitServiceRequest(response.data));
            return true;
        } catch (error) {
            store.dispatch(allApiSubmissionsStatus.failure());
            store.dispatch(preSubmitApiFuneralClaim.failure());
            return false;
        }
    }
};

export const funeralClaimV2Service = (store, next, action) => {
    const result = next(action);
    (async () => {
        if (action.type === FUNERAL_CLAIM_V2_SUBMIT_FORM) {
            submitFuneralClaim(store);
        } else if (action.type === FUNERAL_CLAIM_DHA_API_REQUEST) {
            verifyDeceasedIdDha(store);
        } else if (action.type === FUNERAL_CLAIM_BANK_CDV_REQUEST) {
            checkBankAccountCdv(store);
        } else if (action.type === FUNERAL_CLAIM_PRESUBMIT_REQUEST) {
            preSubmitFuneralClaim(store);
        }
    })();

    return result;
};
