import {validationIsEqual} from "../status";

import {
    validateContactNumber,
    validateFirstName,
    validateSurname,
    validateIdNumber,
    validateDate,
    validateNumericString,
    validateAlphaString,
} from "@om/validation";

import {
    FUNERAL_CLAIM_SET_FIRST_NAME_VALUE,
    setFirstNameValid,
    FUNERAL_CLAIM_SET_SURNAME_VALUE,
    setSurameValid,
    FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE,
    setCellphoneNumberValid,
    FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE,
    setRequesterIdValid,
    setDeceasedRequesterIdValid,
    FUNERAL_CLAIM_SET_DECEASED_ID_VALUE,
    setDeceasedIdValid,
    FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE,
    setFuneralDateValid,
    FUNERAL_CLAIM_SET_BANK_OTHER_VALUE,
    setBankOtherValid,
    FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE,
    setBranchCodeValid,
    FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE,
    setBankAccountNumberValid,
} from "../../../actions/publicWeb/funeralClaim";

import {
    getRequesterIdNumber,
    getDeceasedIdNumber,
} from "../../../selectors/publicWeb/funeralClaim";

const ID_MATCH_ERROR = 'The requester and deceased ID numbers cannot be the same.';

function validateMatchingIdNumbers(requesterIdNumber, deceasedIdNumber) {
    if (requesterIdNumber === deceasedIdNumber && requesterIdNumber !== '') {
        return ID_MATCH_ERROR;
    }

    return null;
}

function notNullOrEmpty(val) {
    return val && val.trim() !== '';
}

let prevValidationAction = null;
export const funeralClaimValidation = (store, next, action) => {
    const state = store.getState();

    const validationAction = (() => {
        let error, deceasedIdNumber, deceasedIdNumberValue,
        requesterIdNumber, requesterIdNumberValue;
        switch (action.type) {
            case FUNERAL_CLAIM_SET_FIRST_NAME_VALUE:
                error = validateFirstName(action.value);
                return setFirstNameValid(error);

            case FUNERAL_CLAIM_SET_SURNAME_VALUE:
                error = validateSurname(action.value);
                return setSurameValid(error);

            case FUNERAL_CLAIM_SET_CELLPHONE_NUMBER_VALUE:
                error = validateContactNumber(action.value, {subject: 'Phone Number', countryCode: action.countryCode});
                return setCellphoneNumberValid(error);

            case FUNERAL_CLAIM_SET_REQUESTER_ID_VALUE:
                error = validateIdNumber(action.value);
                deceasedIdNumber = getDeceasedIdNumber(state);
                deceasedIdNumberValue = deceasedIdNumber.value;
                if (!error) {
                    requesterIdNumber = action.value;
                    error = validateMatchingIdNumbers(requesterIdNumber, deceasedIdNumberValue);
                    // If a duplicate error is picked up which has already been flagged ignore
                    if (error && deceasedIdNumber.error === ID_MATCH_ERROR) {
                        return setRequesterIdValid(null);
                    }
                    // If there is no duplicate and 2nd value is dirty revalidate it
                    if (!error && notNullOrEmpty(deceasedIdNumberValue)) {
                        const deceasedError = validateIdNumber(deceasedIdNumberValue);
                        return setDeceasedRequesterIdValid(deceasedError, null);
                    }
                }
                return setRequesterIdValid(error);

            case FUNERAL_CLAIM_SET_DECEASED_ID_VALUE:
                error = validateIdNumber(action.value);
                requesterIdNumber = getRequesterIdNumber(state);
                requesterIdNumberValue = requesterIdNumber.value;
                if (!error) {
                    deceasedIdNumber = action.value;
                    error = validateMatchingIdNumbers(requesterIdNumberValue, deceasedIdNumber);
                    // If a duplicate error is picked up which has already been flagged ignore
                    if (error && requesterIdNumber.error === ID_MATCH_ERROR) {
                        return setDeceasedIdValid(null);
                    }
                    // If there is no duplicate and 2nd value is dirty revalidate it
                    if (!error && notNullOrEmpty(requesterIdNumberValue)) {
                        const reqesterError = validateIdNumber(requesterIdNumberValue);
                        return setDeceasedRequesterIdValid(null, reqesterError);
                    }
                }
                return setDeceasedIdValid(error);

            case FUNERAL_CLAIM_SET_FUNERAL_DATE_VALUE:
                error = validateDate(action.value);
                return setFuneralDateValid(error);

            case FUNERAL_CLAIM_SET_BANK_OTHER_VALUE:
                error = validateAlphaString(action.value, { subject: 'Bank Name'});
                return setBankOtherValid(error);

            case FUNERAL_CLAIM_SET_BANK_ACCOUNT_NUMBER_VALUE:
                error = validateNumericString(action.value, {min: 8, max: 11, subject: 'Bank Account Number'});
                return setBankAccountNumberValid(error);

            case FUNERAL_CLAIM_SET_BRANCH_CODE_VALUE:
                error = validateNumericString(action.value, {min: 6, max: 8, subject: 'Branch Code', required: false});
                return setBranchCodeValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction)
    prevValidationAction = validationAction;
    return next(action);
};
