import { createSelector } from 'reselect';
import { getTravelInsurance } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';


export const getTravelDetails = createSelector(getTravelInsurance, (state) => state.travelDetails);

export const getTravelDetailsForm = createSelector(getTravelDetails, (travelDetails) => travelDetails.form);

export const getDropOffDetails = createSelector(
    getTravelDetails,
    travelDetails => travelDetails.dropOffDetails,
);

export const getTravelDetailsFormValid = createSelector(
    getTravelDetails,
    travelDetails => travelDetails.formStatus === FORM_STATUS.VALID,
);

export const getIsApiPending = createSelector(
    getTravelDetails,
    travelDetails => travelDetails.formStatus === API_CALL_STATUS.PENDING,
);

export const getTrallers = createSelector(getTravelDetails, (travelDetails) => travelDetails.travellers);
export const getCurrentTravellerIndex = createSelector(getTravelDetails, (travelDetails) => travelDetails.currentTravellerIndex)
const makeGetFieldForm = (fieldId) => createSelector(getTravelDetailsForm, (form) => form[fieldId]);
export const getDOBValue = makeGetFieldForm('dob');
export const getTitleValue = makeGetFieldForm('title');
export const getFullNameValue = makeGetFieldForm('fullName');
export const getIdTypeValue = makeGetFieldForm('idType');
export const getKraNumberValue = makeGetFieldForm('kraNumber');
export const getEmailValue = makeGetFieldForm('email');
export const getIdNumberValue = makeGetFieldForm("idNumber");
export const getPhoneNumberValue = makeGetFieldForm("phoneNumber")
export const getTravellerIdValue = makeGetFieldForm('travellerId')
