import {
    ONTHEMONEY_ENROLLEDCOURSES_SET_VALUE,
    ONTHEMONEY_CALENDAREVENTS_SET_VALUE,
} from '../../actions/publicWeb/onTheMoney';

const isLoggedIn = (sessionKey, authToken, userProfile) => {
    const isClient = typeof window !== 'undefined' ? window.localStorage : '';

    if (!isClient) {
        return false;
    }

    const hasMoodleSession = document.cookie
        .split(';')
        .map((cookie) => cookie.trim())
        .find((cookie) => cookie.startsWith('MoodleSession='));

    return hasMoodleSession && !!sessionKey && !!authToken && !!userProfile;
};

export const initialState = (() => {
    const isClient = typeof window !== 'undefined' ? window.localStorage : '';
    const sessionKey = isClient ? localStorage.getItem('sessionKey') : null;
    const authToken = isClient ? localStorage.getItem('authToken') : null;
    const userProfile = isClient ? localStorage.getItem('userProfile'): null;

    return {
        isLoggedIn: isLoggedIn(sessionKey, authToken, userProfile),
        sessionKey,
        authToken,
        userProfile,
        enrolledCourses: [],
        calendarEvents: [],
        calendarUrl: '/learning/calendar',
        contentHubUrl: '/content-hub/',
        courseCatalogueUrl: '/learning/course',
        dashboardUrl: '/learning/my',
        gradesUrl: '/learning/grade/report/overview/index.php',
        learningSearchUrl: '/learning/search/index.php',
        logOutUrl: '/learning/login/logout.php?sesskey=%s',
        messagesUrl: '/learning/message/index.php',
        notificationsUrl: '/learning/message/output/popup/notifications.php',
        preferencesUrl: '/learning/user/preferences.php',
        profileUrl: '/learning/user/profile.php',
        signUpUrl: '/learning/login/signup.php',
        signInUrl: '/learning/login/index.php',
    };
})();

export default function(state = initialState, action) {
    switch (action.type) {
        case ONTHEMONEY_ENROLLEDCOURSES_SET_VALUE:
            return {
                ...state,
                enrolledCourses: action.value,
            };
		case ONTHEMONEY_CALENDAREVENTS_SET_VALUE:
			return {
				...state,
				calendarEvents: action.value,
			};
        default:
            return state;
    }
}
