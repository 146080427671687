import { combineReducers } from "redux";
import savingsCalculator from './savingsCalculator';
import howMuchYouNeedToSaveCalculator from './howMuchYouNeedToSaveCalculator';
import whenWillYouReachYourSavingsGoal from './whenWillYouReachYourSavingsGoal';
import theFutureValueOfYourSavings from './theFutureValueOfYourSavings';
import referenceData from './referenceData';
import scrolling from './scrolling'
import theFutureValueOfYourSavingsAssumptionsModel from './theFutureValueOfYourSavingsAssumptionsModel'
import howMuchYouNeedToSaveAssumptionsModel from './howMuchYouNeedToSaveAssumptionsModel'
import whenWillYouReachYourSavingsGoalAssumptionsModel from './whenWillYouReachYourSavingsGoalAssumptionsModel'

export const savingsCalculators = combineReducers ({
    referenceData,
    savingsCalculator,
    howMuchYouNeedToSaveCalculator,
    whenWillYouReachYourSavingsGoal,
    theFutureValueOfYourSavings,
    scrolling,
    theFutureValueOfYourSavingsAssumptionsModel,
    howMuchYouNeedToSaveAssumptionsModel,
    whenWillYouReachYourSavingsGoalAssumptionsModel
});