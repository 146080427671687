import axios from 'axios';
import {
    getFirstname,
    getSurname,
    getIdNumber,
    getMobileNumber,
    getEmploymentType,
    getPayslipAvailability,
    getWageFrequency,
    getQualifyingCallMeBackFormStatusValid,
    getLeadSource,
    getCaptureDetailsCreditConsentValid
} from '../../../selectors/publicWeb/qualifyingCheckCallMeBack';
import {
    returnQualifyingData,
    transitionQualifyingCheckSubmitStatus,
} from '../../../actions/publicWeb/qualifyingCheckCallMeBack';

export const qualifyingCheckLeads = (store, next, action) => {
    const result = next(action);

    const state = store.getState();

    const captureDetailsFormValid = getQualifyingCallMeBackFormStatusValid(state);

    if (!captureDetailsFormValid) {
        return result;
    }

    const firstname = getFirstname(state);
    const surname = getSurname(state);
    const idNumber = getIdNumber(state);
    const mobileNumber = getMobileNumber(state);
    const employmentType = getEmploymentType(state);
    const payslipAvailability = getPayslipAvailability(state);
    const wageFrequency = getWageFrequency(state);
    const receivePaySlip = payslipAvailability.value;
    const leadSource = getLeadSource(state);
    const creditConsent = getCaptureDetailsCreditConsentValid(state);

    const isEmploymentValid = (employmentType.value == 'Permanent' || employmentType.value == 'Contract') && receivePaySlip && wageFrequency.value == 'Monthly';
    if(!isEmploymentValid){
        store.dispatch(transitionQualifyingCheckSubmitStatus.employmentError());
        return;
    }

    (async () => {
        await generateQualifyingCheckLeads({
            source: leadSource,
            firstName: firstname.value,
            lastName: surname.value,
            idNumber: idNumber.value,
            contactNumber: mobileNumber.value,
            employmentStatus: employmentType.value,
            wageFrequency: wageFrequency.value,
            receivePaySlip: receivePaySlip,
            creditCheckConsentProvided: creditConsent,
            personalInformationConsentProvided: creditConsent
        }, store);
    })();

    return result;
}

const generateQualifyingCheckLeads = async (request, store) => {
    store.dispatch(transitionQualifyingCheckSubmitStatus.pending());
    try {
        const response = await axios.post(
            '/om-api/qualifying-check-call-me-back/qualify-check-leads',
            request,
            { headers: { 'Content-Type': 'application/json' } },
        );

        store.dispatch(transitionQualifyingCheckSubmitStatus.success());
        store.dispatch(returnQualifyingData(response));
        return response;
    } catch (error) {
        store.dispatch(transitionQualifyingCheckSubmitStatus.error());
        store.dispatch(returnQualifyingData({"errors":[error]}));
        return false;
    }
}