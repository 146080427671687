import {
    COVER_CATEGORY_SET_VALUE,
    COVER_MEMBER_SET_VALUE,
    AGE_SET_VALUE,
    COVER_AMOUNT_SET_VALUE,
    MONTHLY_PREMIUM_SET_VALUE,
    ADD_PARTNER_SET_VALUE,
    ADD_CHILD_SET_VALUE,
    CONFIRM_DELETE_MEMBER_SET,
    SAVE_TRANSITION_QUOTE,
    CLEAR_ALL_TRANSITION_QUOTE,
    ADD_NEW_TRANSITION_QUOTE,
    EDIT_TRANSITION_QUOTE,
    DELETE_TRANSITION_QUOTE,
    EXPAND_MEMBERS_TRANSITION_QUOTE,
    COLLAPSE_MEMBERS_TRANSITION_QUOTE,
    NEW_COVER_DEFAULT_SET_VALUE,
    NEW_COVER_MYSELF_SET_VALUE,
    NEW_COVER_PARENTS_SET_VALUE,
    NEW_COVER_EXTENDED_SET_VALUE,
    SIDEDRAWER_SET_VALUE,
    SIDEDRAWER_CLOSED_SET_VALUE,
    CONTINUE_TRANSITION_QUOTE,
    BACK_TRANSITION_QUOTE,
    CONFIRM_DELETE_MEMBER_CLEAR
} from "../../../actions/easiplusFuneralPlan/quote";
import {
    NAME_SET_VALUE,
    SURNAME_SET_VALUE,
    CONTACT_NUMBER_SET_VALUE,
    CALL_ME_BACK_SEND_INITIATE
} from "../../../actions/easiplusFuneralPlan/callMeBack";
import {
    getSelectedCoverCategory,
    getRelationship,
    getAge,
    getCoverAmount,
    getMonthlyPremium,
    getAddPartner,
    getAddChild,
    getLivesCovered,
    getEditCoverFormIsValid,
    getEditCoverForm,
} from "../../../selectors/easiplusFuneralPlan/quote";
import {
    getName as getNameCMB,
    getSurname as getSurnameCMB,
    getContactNumber as getContactNumberCMB
} from "../../../selectors/easiplusFuneralPlan/callMeBack"
import {simpleFieldTracking} from "../formTracking";
import {transitionTracking} from "../transitionTracking";
import {onFieldChangeStateComparison} from "../onFieldChangeStateComparison";
import { otherEventTracking, findOtherEventIndex } from "../otherEventTracking";

export const quotePageFieldTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    const fieldSelector = (() => {
        switch (action.type) {
            case COVER_CATEGORY_SET_VALUE: return getSelectedCoverCategory;
            case COVER_MEMBER_SET_VALUE: return getRelationship;
            case AGE_SET_VALUE: return getAge;
            case ADD_PARTNER_SET_VALUE: return getAddPartner;
            case ADD_CHILD_SET_VALUE: return getAddChild;
            case COVER_AMOUNT_SET_VALUE: return getCoverAmount;
            case MONTHLY_PREMIUM_SET_VALUE: return getMonthlyPremium;
            default: return null;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if (field) {
        simpleFieldTracking({action, field});
    }

    return result;
};

export const quoteTransitionTracking = (store, next, action) => {
        const result = next(action);
        const state = store.getState();
        const trackingActions = [
            CONTINUE_TRANSITION_QUOTE,
            BACK_TRANSITION_QUOTE,
        ];
        const livesCovered = getLivesCovered(state);
        if( trackingActions.includes(action.type))
            transitionTracking(action.type, livesCovered);

        return result;
};

export const quoteOtherTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const state = store.getState();
    let otherEventIndex;
    const field = (() => {
        switch (action.type) {
            case SAVE_TRANSITION_QUOTE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return determineSaved(prevState, state);
            case CLEAR_ALL_TRANSITION_QUOTE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteClearAllClicked";
            case NEW_COVER_DEFAULT_SET_VALUE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteNewCoverDefault";
            case NEW_COVER_MYSELF_SET_VALUE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteNewCoverMyself";
            case NEW_COVER_PARENTS_SET_VALUE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteNewCoverParents";
            case NEW_COVER_EXTENDED_SET_VALUE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteNewCoverExtended";
            case SIDEDRAWER_SET_VALUE:
                otherEventIndex = findOtherEventIndex('OPEN_DRAWER');
                return "quoteSideDrawerOpen";
            case SIDEDRAWER_CLOSED_SET_VALUE:
                otherEventIndex = findOtherEventIndex('CLOSE_DRAWER');
                return "quoteSideDrawerClosed";
            case ADD_NEW_TRANSITION_QUOTE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteAddCover";
            case DELETE_TRANSITION_QUOTE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteDeleteCover";
            case EDIT_TRANSITION_QUOTE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteEditCover";
            case EXPAND_MEMBERS_TRANSITION_QUOTE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteExpandMemberCard";
            case COLLAPSE_MEMBERS_TRANSITION_QUOTE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteCollapseMemberCard";
            case CONFIRM_DELETE_MEMBER_SET:
                otherEventIndex = findOtherEventIndex('POPUP');
                return "quoteDeleteMemberPopupShow";
            case CONFIRM_DELETE_MEMBER_CLEAR:
                otherEventIndex = findOtherEventIndex('POPUP');
                return "quoteDeleteMemberPopupClear";
            case CALL_ME_BACK_SEND_INITIATE:
                otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
                return "quoteCallMeBackSent";
            default: return null;
        }
    })();

    if (field){
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};

export const quoteCallMeBackTracking = (store, next, action) => {
    const prevState = store.getState();
    const result = next(action);
    const currentState = store.getState();
    const onFieldChange = onFieldChangeStateComparison(prevState, currentState);
    let sendValue = true;
    let sendError = true;
    const fieldSelector = (() => {
        switch (action.type) {
            case NAME_SET_VALUE:
                sendValue = false;
                return getNameCMB;
            case SURNAME_SET_VALUE:
                sendValue = false;
                return getSurnameCMB;
            case CONTACT_NUMBER_SET_VALUE:
                sendValue = false;
                return getContactNumberCMB;
        }
    })();

    const field = onFieldChange(fieldSelector);

    if(field)
        simpleFieldTracking({action, field, sendValue, sendError});

    return result;
}

const determineSaved = (prevState, state) => {
    const editCoverFormValid = getEditCoverFormIsValid(prevState);

    if(editCoverFormValid) {
        return "coverSaved";
    } else {
        const addCoverForm = getEditCoverForm(state);
        const editCoverWithErrors = Object.keys(addCoverForm)
        .reduce((acc, key) => ({
            ...acc,
            [key]: {
                status: addCoverForm[key].status,
                error: addCoverForm[key].error,
            }
        }), {});
        return {type: 'invalidSave', form: {...editCoverWithErrors}};
    }
}