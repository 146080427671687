import { createSelector } from 'reselect';
import { getRoaQuoteTools } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getAfyaImaraQuoteTool = createSelector(
    getRoaQuoteTools,
    (roaQuoteTools) => roaQuoteTools.AfyaImaraQuoteTool
);

export const getAfyaImaraQuoteToolForm = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) => AfyaImaraQuoteTool.form
);

const makeGetFieldForm = (fieldId) =>
    createSelector(getAfyaImaraQuoteToolForm, (form) => form[fieldId]);

export const getAfyaImaraQuoteToolFormValid = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) => AfyaImaraQuoteTool.formStatus === FORM_STATUS.VALID
);

export const getSelectedForm = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) => AfyaImaraQuoteTool.selectedForm
);

export const getAfyaImaraPremiumAmount = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) => AfyaImaraQuoteTool.AfyaImaraPremiumValue
);

export const getRoaCmbObject = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) => AfyaImaraQuoteTool.roaCmbObject
);

export const getShowError = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) =>
        AfyaImaraQuoteTool.apiCallStatus === API_CALL_STATUS.FAILURE
);

export const getIsApiPending = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) =>
        AfyaImaraQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING
);

export const getCalculateDisabled = createSelector(
    getAfyaImaraQuoteTool,
    (AfyaImaraQuoteTool) => {
        return (
            AfyaImaraQuoteTool.formStatus === FORM_STATUS.INVALID ||
            AfyaImaraQuoteTool.apiCallStatus === API_CALL_STATUS.PENDING
        );
    }
);

export const getAgeBracket = makeGetFieldForm('ageBracket');
export const getSpouse = makeGetFieldForm('spouseCover');
export const getSpouseAge = makeGetFieldForm('spouseAge');
export const getCoverChild = makeGetFieldForm('childCover');
export const getInpatientCoverLimitValue = makeGetFieldForm(
    'inpatientCoverLimit'
);
export const getOutpatientCoverLimitValue = makeGetFieldForm(
    'outpatientCoverLimit'
);
export const get18ChildValue = makeGetFieldForm('child_18');
