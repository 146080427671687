import { 
    FUTURE_VALUE_OF_SAVINGS_SHOW_RESULT_SUMMARY
} from "../../../actions/savingsCalculator/theFutureValueOfYourSavings"
import { fireGtmEvent } from "../gtmEvents";

import {findOtherEventIndex, otherEventTracking} from '../otherEventTracking';;

export const theFutureValueOfYourSavingsFieldTracking = (store, next, action) => {
    const result = next(action);
    let otherEventIndex;

    const field = (() => {
        switch (action.type){
            case FUTURE_VALUE_OF_SAVINGS_SHOW_RESULT_SUMMARY: 
            otherEventIndex = findOtherEventIndex('BUTTON_CLICKED');
            fireGtmEvent('calculator_complete', { calculator_type: 'Savings Calculator' });
            return 'Future value of savings';
        }
    })();

    if (field) {
        otherEventTracking(action.type, otherEventIndex, field);
    }

    return result;
};
