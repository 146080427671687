import {
    getTermsAndConditionsValid,
} from "../../../selectors/taxFreeSavingsAccount/review";
import {FORM_STATUS} from "../../../reducers/status";
import {
    transitionReview,
    setTermsAndConditionsInvalid,
} from "../../../actions/taxFreeSavingsAccount/review";
import {scrollToId} from "../../../actions/taxFreeSavingsAccount/scrolling";
import {TFSA_REVIEW} from "../status";


export const reviewRules = (store, next, action) => {
    const result = next(action);
    return result;
};


export const performReviewValidation = (store) => {
    const state = store.getState();

    const termsAndConditionsValid = getTermsAndConditionsValid(state);
    if (!termsAndConditionsValid) {
        store.dispatch(setTermsAndConditionsInvalid(FORM_STATUS.INVALID));
        store.dispatch(scrollToId(TFSA_REVIEW.TERMS_AND_CONDITIONS_CHECKBOX));
    }
};
