import {getEasiplusFuneralPlan} from "./index";
import {createSelector} from "reselect";
import { FIELD_STATUS } from "../../reducers/status";

export const getReferenceData = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan.referenceData
);

export const getApplicationReferenceData = createSelector(
    getReferenceData,
    referenceData => referenceData.applicationData,
);
export const getQuoteReferenceData = createSelector(
    getReferenceData,
    referenceData => referenceData.quoteData,
);

// Field selectors
const makeGetField = fieldId => createSelector(
    getApplicationReferenceData,
    data => data[fieldId]
);

export const getReferenceBanks = makeGetField('banks');
export const getReferenceAccountTypes = makeGetField('bankAccountTypes');
export const getReferenceMaritalStatuses = makeGetField('maritalStatuses');
export const getReferenceDebitOrderDates = makeGetField('debitOrderDates');
export const getReferenceTitles = makeGetField('titles');
export const getReferenceRelationshipTypes = makeGetField('relationshipTypes');


// Calculated Selectors
export const getReferencePostalMethods = () => {
    return [
        {
            description: 'Street Address', 
            code: 'StreetAddress'
        },
        {
            description: 'PO Box', 
            code: 'POBox'
        },
        {
            description: 'Private Bag', 
            code: 'PrivateBag'
        }
    ];
};

export const getReferenceNationalities = () => { return [{description: 'South Africa', code: 'SouthAfrica'}]; };

// Quote
export const getQuoteReferenceCoverMembers = createSelector(
    getQuoteReferenceData,
    quoteReferenceData => {
        if (!quoteReferenceData || !quoteReferenceData.plans) {
            return [];
        }

        return quoteReferenceData.plans.flatMap(plan => plan.coveredMembers);
    }
);

export const getQuoteReferenceImmediateFamilyCoverMembers = createSelector(
    getQuoteReferenceData,
    quoteReferenceData => {
        if (!quoteReferenceData || !quoteReferenceData.plans) {
            return [];
        }

        return quoteReferenceData.plans[0].coveredMembers;
    }
);


// Validates the application reference data
export const getIsApplicationReferenceDataValid = createSelector(
    getApplicationReferenceData,
    applicationReferenceData => {
        return applicationReferenceData && 
            Object.keys(applicationReferenceData).length !== 0 &&
            applicationReferenceData.isValid &&
            applicationReferenceData.relationshipTypes !== 0 && 
            applicationReferenceData.banks !== 0 &&
            applicationReferenceData.bankAccountTypes !== 0 &&
            applicationReferenceData.debitOrderDates !== 0 &&
            applicationReferenceData.titles !== 0
    }
);

// Validates the quote reference data
export const getIsQuoteReferenceDataValid = createSelector(
    getQuoteReferenceData,
    quoteReferenceData => {
        return quoteReferenceData &&
            Object.keys(quoteReferenceData).length !== 0 &&
            quoteReferenceData.isValid &&
            quoteReferenceData.plans.length !== 0;
    }
);

// If there is quote and application reference data the set to true
// This is used to send user to Call me back if we dont have reference data
export const getIsReferenceDataValid = createSelector(
    getIsApplicationReferenceDataValid,
    getIsQuoteReferenceDataValid,
    (isApplicationReferenceDataValid, isQuoteReferenceDataValid) => {
        return isApplicationReferenceDataValid && isQuoteReferenceDataValid;
    }
);
