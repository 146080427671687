import { combineReducers } from 'redux';
import routing from './routing';
import quote from './quote';
import personalDetails from './personalDetails';
import payments from './payment';
import carDetails from './carDetails';
import documents from './Documents';
import declarations from './declarations';
import payloadCollection from './payloadCollection';
import agent from './agent';

export const motorPrivateRW = combineReducers({
    routing,
    quote,
    personalDetails,
    payments,
    carDetails,
    documents,
    declarations,
    payloadCollection,
    agent,
});
