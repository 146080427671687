import {
    INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS,
    BACK_TRANSITION_CAPTURE_DETAILS,
    CAPTURE_DETAILS_SET_VALUE,
    CONTINUE_TRANSITION_CAPTURE_DETAILS
} from "../../../actions/raOptimal/captureDetails";

import {
    LINK_WITH_INFLATION_SELECT,
    CHOOSE_OWN_PERCENTAGE_SELECT,
    NO_INCREASE_SELECT,
    COMFORTABLE_INVESTOR_SELECT,
    ADVENTUROUS_INVESTOR_SELECT,
    INVESTMENT_SETUP_SET_VALUE,
    INVALID_CONTINUE_TRANSITION_INVESTMENT_SETUP,
    CONTINUE_TRANSITION_INVESTMENT_SETUP,
    BACK_TRANSITION_INVESTMENT_SETUP,
    FACT_SHEET_CLICKED
} from '../../../actions/raOptimal/investmentSetup';

import {
    PERSONAL_DETAILS_SET_VALUE,
    INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS,
    CONTINUE_TRANSITION_PERSONAL_DETAILS,
    BACK_TRANSITION_PERSONAL_DETAIL,
} from '../../../actions/raOptimal/personalDetails';

import {
    TOGGLE_READ_MORE_TRANSITION_BENEFICIARIES,
    ADD_NEW_TRANSITION_BENEFICIARIES,
    BENEFICIARIES_SET_VALUE,
    SAVE_TRANSITION_BENEFICIARIES,
    FORM_INVALID_TRANSITION_BENEFICIARIES,
    CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES,
    EDIT_TRANSITION_BENEFICIARIES,
    DELETE_BENEFICIARY_POPUP_TRACKING,
    DELETE_TRANSITION_BENEFICIARIES,
    ADD_MORE_THAN_MAX_BENEFICIARIES_TRACKING
} from '../../../actions/raOptimal/beneficiaries';

import {
    BENEFICIARIES_SPLIT_SET_VALUE,
    EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT,
    INVALID_CONTINUE_TRANSITION_BENEFICIARIES_SPLIT
} from "../../../actions/raOptimal/beneficiariesSplit";

import {
    PAYMENT_DETAILS_SET_VALUE,
    TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS,
    INVALID_CONTINUE_TRANSITION_PAYMENT_DETAILS,
    CONTINUE_TRANSITION_PAYMENT_DETAILS,
    BACK_TRANSITION_PAYMENT_DETAIL
} from "../../../actions/raOptimal/paymentDetails";

import {
    TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW,
    TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW,
    EDIT_PAGE_REVIEW_TRACKING,
    INVALID_CONTINUE_TRANSITION_REVIEW,
    REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE,
    TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE
} from "../../../actions/raOptimal/review";

import {captureDetailsFieldTracking, captureDetailsTransitionTracking} from "./captureDetails";

import {
    investmentSetupFieldsTracking,
    investmentSetupOtherTracking,
    investmentSetupTransitionTracking
} from "./investmentSetup";

import {personalDetailsFieldsTracking, personalDetailsTransitionTracking} from './personalDetails';

import {
    beneficiariesFieldsTracking,
    beneficiariesTransitionTracking,
    beneficiariesOtherTracking
} from './beneficiaries';

import {
    beneficiarySplitFieldsTracking,
    beneficiarySplitTransitionTracking,
} from './beneficiarySplit';

import {
    paymentDetailsFieldsTracking,
    paymentDetailsTransitionTracking,
    paymentDetailsOtherTracking
} from "./paymentDetails";

import {reviewOtherTracking} from "./review";

export const raOptimalTracking = (store, next, action) => {
    /**
     * Retirement Annuity
     */
    // Drop Off Details
    if (action.type.startsWith(CAPTURE_DETAILS_SET_VALUE))
        return captureDetailsFieldTracking(store, next, action);

    if (action.type === INVALID_CONTINUE_TRANSITION_CAPTURE_DETAILS ||
        action.type === CONTINUE_TRANSITION_CAPTURE_DETAILS ||
        action.type === BACK_TRANSITION_CAPTURE_DETAILS)
        return captureDetailsTransitionTracking(store, next, action);

    // Investment Setup
    if (action.type.startsWith(INVESTMENT_SETUP_SET_VALUE))
        return investmentSetupFieldsTracking(store, next, action);

    if (action.type === INVALID_CONTINUE_TRANSITION_INVESTMENT_SETUP ||
        action.type === CONTINUE_TRANSITION_INVESTMENT_SETUP ||
        action.type === BACK_TRANSITION_INVESTMENT_SETUP)
        return investmentSetupTransitionTracking(store, next, action);

    if (action.type === LINK_WITH_INFLATION_SELECT ||
        action.type === CHOOSE_OWN_PERCENTAGE_SELECT ||
        action.type === NO_INCREASE_SELECT ||
        action.type === COMFORTABLE_INVESTOR_SELECT ||
        action.type === ADVENTUROUS_INVESTOR_SELECT ||
        action.type === FACT_SHEET_CLICKED)
        return investmentSetupOtherTracking(store, next, action);

    // Personal Details
    if (action.type.startsWith(PERSONAL_DETAILS_SET_VALUE))
        return personalDetailsFieldsTracking(store, next, action);

    if (action.type === INVALID_CONTINUE_TRANSITION_PERSONAL_DETAILS ||
        action.type === CONTINUE_TRANSITION_PERSONAL_DETAILS ||
        action.type === BACK_TRANSITION_PERSONAL_DETAIL)
        return personalDetailsTransitionTracking(store, next, action);

    // Beneficiaries
    if (action.type.startsWith(BENEFICIARIES_SET_VALUE))
        return beneficiariesFieldsTracking(store, next, action);

    if (action.type === FORM_INVALID_TRANSITION_BENEFICIARIES ||
        action.type === DELETE_TRANSITION_BENEFICIARIES)
        return beneficiariesTransitionTracking(store, next, action);

    if (action.type === TOGGLE_READ_MORE_TRANSITION_BENEFICIARIES ||
        action.type === ADD_NEW_TRANSITION_BENEFICIARIES ||
        action.type === SAVE_TRANSITION_BENEFICIARIES ||
        action.type === CANCEL_EDIT_OF_TRANSITION_BENEFICIARIES ||
        action.type === EDIT_TRANSITION_BENEFICIARIES ||
        action.type === DELETE_BENEFICIARY_POPUP_TRACKING ||
        action.type === ADD_MORE_THAN_MAX_BENEFICIARIES_TRACKING)
        return beneficiariesOtherTracking(store, next, action);

    // Beneficiaries Split
    if (action.type.startsWith(BENEFICIARIES_SPLIT_SET_VALUE))
        return beneficiarySplitFieldsTracking(store, next, action);

    if (action.type === EVEN_SPLIT_SELECT_BENEFICIARIES_SPLIT ||
        action.type === INVALID_CONTINUE_TRANSITION_BENEFICIARIES_SPLIT)
        return beneficiarySplitTransitionTracking(store, next, action);

    // Payment Details
    if (action.type.startsWith(PAYMENT_DETAILS_SET_VALUE))
        return paymentDetailsFieldsTracking(store, next, action);

    if (action.type === INVALID_CONTINUE_TRANSITION_PAYMENT_DETAILS ||
        action.type === CONTINUE_TRANSITION_PAYMENT_DETAILS ||
        action.type === BACK_TRANSITION_PAYMENT_DETAIL)
        return paymentDetailsTransitionTracking(store, next, action);

    if (action.type === TOGGLE_PAYMENT_AUTHORIZATION_TRANSITION_PAYMENT_DETAILS)
        return paymentDetailsOtherTracking(store, next, action);

    // Review
    if (action.type === TOGGLE_REPLACEMENT_POLICY_TRANSITION_REVIEW ||
        action.type === TOGGLE_TERMS_AND_CONDITIONS_TRANSITION_REVIEW ||
        action.type === EDIT_PAGE_REVIEW_TRACKING ||
        action.type === REPLACEMENT_POLICY_SIDEDRAWER_SET_VALUE ||
        action.type === TERMS_AND_CONDITIONS_SIDEDRAWER_SET_VALUE ||
        action.type === INVALID_CONTINUE_TRANSITION_REVIEW)
        return reviewOtherTracking(store, next, action);

    return null;
};