import { REFERRER_ID_SET_VALUE } from '../../actions/atRetirement/intro'

export const initIntro = {
    referrerId: null,
};

export default (state = initIntro, action) => {
    switch (action.type) {
        case REFERRER_ID_SET_VALUE:
            return {...state, referrerId: action.value};
        default:
            return state;
    }
};
