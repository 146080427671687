import {
  POSTAL_ADDRESS_METHOD_VALUE,
  POSTAL_ADDRESS_SAME_TOGGLE_VALUE,
  SPOUSE_ID_TYPE_TOGGLE_VALUE,
  clearPoBoxValue,
  clearPoCountryValue,
  clearPoPostOfficeValue,
  clearPoPostalCodeValue,
  clearPostalAddressMethodValue,
  clearPostalCityValue,
  clearPostalComplexValue,
  clearPostalCountryValue,
  clearPostalPostalCodeValue,
  clearPostalStreetAddressValue,
  clearPostalSuburbValue,
  clearPrivateBagValue,
  clearPrivateCountryValue,
  clearPrivatePostOfficeValue,
  clearPrivatePostalCodeValue,
  resetSpouseIdNumber,
  resetSpousePassportNumber,
  setCityValue,
  setComplexValue,
  setCountryValue,
  setIdValue,
  setMaritalStatusValue,
  setMinorFirstNamesValue,
  setMinorIdValue,
  setMinorSurnameValue,
  setMinorTitleValue,
  setNationalityValue,
  setPoBoxValue,
  setPoCountryValue,
  setPoPostOfficeValue,
  setPoPostalCodeValue,
  setPostalAddressMethodValue,
  setPostalCityValue,
  setPostalCodeValue,
  setPostalComplexValue,
  setPostalCountryValue,
  setPostalPostalCodeValue,
  setPostalStreetAddressValue,
  setPostalSuburbValue,
  setPrivateBagValue,
  setPrivateCountryValue,
  setPrivatePostOfficeValue,
  setPrivatePostalCodeValue,
  setSpouseFirstNamesValue,
  setSpouseIdNumberValue,
  setSpousePassportNumberValue,
  setSpouseSurnameValue,
  setSpouseTitleValue,
  setStreetAddressValue,
  setSuburbValue,
} from "../../../actions/taxFreeSavingsAccount/auxiliaryDetails";
import { scrollToError } from "../../../actions/taxFreeSavingsAccount/scrolling";
import {
  getAuxiliaryDetailsForm,
  getIsMarriedInCommunityOfProperty,
  getIsPostalAddressSame,
  getIsPostalMethodPoBox,
  getIsPostalMethodPrivateBag,
  getIsPostalMethodStreet,
  getSpouseHasIdNumber,
  getSpouseHasPassportNumber,
} from "../../../selectors/taxFreeSavingsAccount/auxiliaryDetails";
import { getSavingForMinor } from "../../../selectors/taxFreeSavingsAccount/investmentSetupInitial";
import { TFSA_PERSONAL_DETAILS_SCROLL_ID } from "../status";

export const auxiliaryDetailsRules = (store, next, action) => {
  const result = next(action);
  const state = store.getState();

  // Change Postal Addresss Method
  const changePostalAddressMethod = action.type.includes(POSTAL_ADDRESS_METHOD_VALUE);
  const isPostalMethodStreet = getIsPostalMethodStreet(state);
  const isPostalMethodPoBox = getIsPostalMethodPoBox(state);
  const isPostalMethodPrivateBag = getIsPostalMethodPrivateBag(state);
  if (changePostalAddressMethod) {
    if (isPostalMethodPoBox) {
      clearPoBoxAddressFields(store);
    }

    if (isPostalMethodPrivateBag) {
      clearPrivateBagAddressFields(store);
    }

    if (isPostalMethodStreet) {
      clearPostalStreetsAddressFields(store);
    }
  }

  // change postal address same
  const changePostalAddressSame = action.type.includes(POSTAL_ADDRESS_SAME_TOGGLE_VALUE);
  if (changePostalAddressSame) {
    store.dispatch(clearPostalAddressMethodValue());
    clearPoBoxAddressFields(store);
    clearPrivateBagAddressFields(store);
    clearPostalStreetsAddressFields(store);
  }

  if (action.type === SPOUSE_ID_TYPE_TOGGLE_VALUE) {
    if (getSpouseHasIdNumber(store.getState())) store.dispatch(resetSpousePassportNumber());
    if (!getSpouseHasIdNumber(store.getState())) store.dispatch(resetSpouseIdNumber());
  }
  return result;
};

export const performAuxiliaryDetailsValidation = (store) => {
  const state = store.getState();
  const auxiliaryDetailsForm = getAuxiliaryDetailsForm(state);
  const investingForMinor = getSavingForMinor(state);
  const marriedInCOP = getIsMarriedInCommunityOfProperty(state);
  const spouseHasIdNumber = getSpouseHasIdNumber(state);
  const spouseHasPassportNumber = getSpouseHasPassportNumber(state);
  const isPostalAddressSame = getIsPostalAddressSame(state);
  const isMethodStreet = getIsPostalMethodStreet(state);
  const isMethodPoBox = getIsPostalMethodPoBox(state);
  const isMethodPrivateBag = getIsPostalMethodPrivateBag(state);

  // Fields shared accross all form variations
  store.dispatch(setMaritalStatusValue(auxiliaryDetailsForm.maritalStatus.value));

  store.dispatch(setComplexValue(auxiliaryDetailsForm.complex.value));
  store.dispatch(setStreetAddressValue(auxiliaryDetailsForm.streetAddress.value));
  store.dispatch(setSuburbValue(auxiliaryDetailsForm.suburb.value));
  store.dispatch(setCityValue(auxiliaryDetailsForm.city.value));
  store.dispatch(setPostalCodeValue(auxiliaryDetailsForm.postalCode.value));
  store.dispatch(setCountryValue(auxiliaryDetailsForm.country.value));

  if (investingForMinor) {
    store.dispatch(setMinorTitleValue(auxiliaryDetailsForm.minorTitle.value));
    store.dispatch(setMinorFirstNamesValue(auxiliaryDetailsForm.minorFirstNames.value));
    store.dispatch(setMinorSurnameValue(auxiliaryDetailsForm.minorSurname.value));
    store.dispatch(setMinorIdValue(auxiliaryDetailsForm.minorId.value));
  }

  store.dispatch(setIdValue(auxiliaryDetailsForm.id.value));
  store.dispatch(setNationalityValue(auxiliaryDetailsForm.nationality.value));

  if (marriedInCOP) {
    store.dispatch(setSpouseTitleValue(auxiliaryDetailsForm.spouseTitle.value));
    store.dispatch(setSpouseFirstNamesValue(auxiliaryDetailsForm.spouseFirstNames.value));
    store.dispatch(setSpouseSurnameValue(auxiliaryDetailsForm.spouseSurname.value));

    // true when SA ID selected
    if (spouseHasIdNumber) {
      store.dispatch(setSpouseIdNumberValue(auxiliaryDetailsForm.spouseIdNumber.value));
    }
    // true when passport number selected
    if (spouseHasPassportNumber) {
      store.dispatch(setSpousePassportNumberValue(auxiliaryDetailsForm.spousePassportNumber.value));
    }
  }

  if (!isPostalAddressSame) {
    //street postal method selected
    if (isMethodStreet) {
      store.dispatch(setPostalComplexValue(auxiliaryDetailsForm.postalComplex.value));
      store.dispatch(setPostalStreetAddressValue(auxiliaryDetailsForm.postalStreetAddress.value));
      store.dispatch(setPostalSuburbValue(auxiliaryDetailsForm.postalSuburb.value));
      store.dispatch(setPostalCityValue(auxiliaryDetailsForm.postalCity.value));
      store.dispatch(setPostalPostalCodeValue(auxiliaryDetailsForm.postalPostalCode.value));
      store.dispatch(setPostalCountryValue(auxiliaryDetailsForm.postalCountry.value));
    } else if (isMethodPoBox) {
      store.dispatch(setPoBoxValue(auxiliaryDetailsForm.poBox.value));
      store.dispatch(setPoPostOfficeValue(auxiliaryDetailsForm.poPostOffice.value));
      store.dispatch(setPoPostalCodeValue(auxiliaryDetailsForm.poPostalCode.value));
      store.dispatch(setPoCountryValue(auxiliaryDetailsForm.poCountry.value));
    } else if (isMethodPrivateBag) {
      store.dispatch(setPrivateBagValue(auxiliaryDetailsForm.privateBag.value));
      store.dispatch(setPrivatePostOfficeValue(auxiliaryDetailsForm.privatePostOffice.value));
      store.dispatch(setPrivatePostalCodeValue(auxiliaryDetailsForm.privatePostalCode.value));
      store.dispatch(setPrivateCountryValue(auxiliaryDetailsForm.privateCountry.value));
    } else {
      store.dispatch(setPostalAddressMethodValue(null));
    }
  }

  const form = getAuxiliaryDetailsForm(store.getState());
  store.dispatch(scrollToError(TFSA_PERSONAL_DETAILS_SCROLL_ID, form));
};

const clearPoBoxAddressFields = (store) => {
  store.dispatch(clearPoBoxValue());
  store.dispatch(clearPoPostOfficeValue());
  store.dispatch(clearPoPostalCodeValue());
  store.dispatch(clearPoCountryValue());
};

const clearPrivateBagAddressFields = (store) => {
  store.dispatch(clearPrivateBagValue());
  store.dispatch(clearPrivatePostOfficeValue());
  store.dispatch(clearPrivatePostalCodeValue());
  store.dispatch(clearPrivateCountryValue());
};

const clearPostalStreetsAddressFields = (store) => {
  store.dispatch(clearPostalComplexValue());
  store.dispatch(clearPostalStreetAddressValue());
  store.dispatch(clearPostalSuburbValue());
  store.dispatch(clearPostalCityValue());
  store.dispatch(clearPostalPostalCodeValue());
  store.dispatch(clearPostalCountryValue());
};
