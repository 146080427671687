import { combineReducers } from "redux";
import routing from "./routing";
import personalDetails from "./personalDetails";
import referenceData from "./referenceData";
import paymentDetails from "./paymentDetails";
import uploadDocuments from "./uploadDocuments";
import confirmDetails from "./confirmDetails";
import scrolling from "./scrolling";
import thankYouDetails from "./thankYouDetails";

export const digiLifeLoanProtection = combineReducers({
  routing,
  personalDetails,
  referenceData,
  paymentDetails,
  uploadDocuments,
  confirmDetails,
  scrolling,
  thankYouDetails
});
