import { makeAClaimRulesEngine } from "./makeAClaim";
import {MAKE_A_CLAIM_SET_VALID, MAKE_A_CLAIM_SET_VALUE} from "../../../actions/makeAClaim";

export const makeAClaimRules = (store, next, action) => {
    if (
        action.type.startsWith(MAKE_A_CLAIM_SET_VALUE) ||
        action.type.startsWith(MAKE_A_CLAIM_SET_VALID)
    )
        return makeAClaimRulesEngine(store, next, action);

    return null;
};