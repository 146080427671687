import {
    BUILDING_SET_VALID,
    BUILDING_SET_VALUE,
    CITY_SET_VALID,
    CITY_SET_VALUE,
    FIRST_NAME_SET_VALID,
    FIRST_NAME_SET_VALUE,
    EMAIL_SET_VALID,
    EMAIL_SET_VALUE,
    TIN_NUMBER_SET_VALID,
    TIN_NUMBER_SET_VALUE,
    MOBILE_NUMBER_SET_VALID,
    MOBILE_NUMBER_SET_VALUE,
    PO_BOX_SET_VALID,
    PO_BOX_SET_VALUE,
    POSTAL_CODE_SET_VALID,
    POSTAL_CODE_SET_VALUE,
    REINITIALIZE,
    STREET_ADDRESS_SET_VALID,
    STREET_ADDRESS_SET_VALUE,
    SURNAME_SET_VALUE,
    SURNAME_SET_VALID,
    DOB_SET_VALUE,
    DOB_SET_VALID,
    ID_PASSPORT_SET_VALUE,
    ID_PASSPORT_SET_VALID,
    FORM_VALID_LIVES_COVERED_PERSONAL_DETAILS_FORM,
    FORM_INVALID_LIVES_COVERED_PERSONAL_DETAILS_FORM,
    SET_EDIT_FORM
} from '../../actions/healthInsuranceRW/personalDetails';
import { FIELD_STATUS, FORM_STATUS } from '../status';

export const initPersonalDetails = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        firstName: { value: null, error: null, status: null },
        surname: { value: null, error: null, status: null },
        dob: { value: null, error: null, status: null },
        email: { value: null, error: null, status: null },
        mobileNumber: { value: null, error: null, status: null },
        idPassport: { value: null, error: null, status: null },
        tinNumber: { value: null, error: null, status: null },
        city: { value: null, error: null, status: null },
        streetAddress: { value: null, error: null, status: null },
        building: { value: null, error: null, status: null },
        postalBox: { value: null, error: null, status: null },
        postalCode: { value: null, error: null, status: null },
    },
};

export default (state = initPersonalDetails, action) => {
    const mapError = (error) => (error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID);
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });

    switch (action.type) {
        case FORM_VALID_LIVES_COVERED_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.VALID };

        case FORM_INVALID_LIVES_COVERED_PERSONAL_DETAILS_FORM:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case FIRST_NAME_SET_VALUE:
            return updateFormField('firstName', (field) => ({
                ...field,
                value: action.value,
            }));

        case FIRST_NAME_SET_VALID:
            return updateFormField('firstName', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SURNAME_SET_VALUE:
            return updateFormField('surname', (field) => ({
                ...field,
                value: action.value,
            }));

        case SURNAME_SET_VALID:
            return updateFormField('surname', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));
        case DOB_SET_VALUE:
            return updateFormField('dob', (field) => ({
                ...field,
                value: action.value,
            }));

        case DOB_SET_VALID:
            return updateFormField('dob', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case MOBILE_NUMBER_SET_VALUE:
            return updateFormField('mobileNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case MOBILE_NUMBER_SET_VALID:
            return updateFormField('mobileNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case ID_PASSPORT_SET_VALUE:
            return updateFormField('idPassport', (field) => ({
                ...field,
                value: action.value,
            }));
        case ID_PASSPORT_SET_VALID:
            return updateFormField('idPassport', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case EMAIL_SET_VALUE:
            return updateFormField('email', (field) => ({
                ...field,
                value: action.value,
            }));
        case EMAIL_SET_VALID:
            return updateFormField('email', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case TIN_NUMBER_SET_VALUE:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                value: action.value,
            }));
        case TIN_NUMBER_SET_VALID:
            return updateFormField('tinNumber', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case CITY_SET_VALUE:
            return updateFormField('city', (field) => ({
                ...field,
                value: action.value,
            }));
        case CITY_SET_VALID:
            return updateFormField('city', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case STREET_ADDRESS_SET_VALUE:
            return updateFormField('streetAddress', (field) => ({
                ...field,
                value: action.value,
            }));
        case STREET_ADDRESS_SET_VALID:
            return updateFormField('streetAddress', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case BUILDING_SET_VALUE:
            return updateFormField('building', (field) => ({
                ...field,
                value: action.value,
            }));
        case BUILDING_SET_VALID:
            return updateFormField('building', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case PO_BOX_SET_VALUE:
            return updateFormField('postalBox', (field) => ({
                ...field,
                value: action.value,
            }));
        case PO_BOX_SET_VALID:
            return updateFormField('postalBox', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case POSTAL_CODE_SET_VALUE:
            return updateFormField('postalCode', (field) => ({
                ...field,
                value: action.value,
            }));
        case POSTAL_CODE_SET_VALID:
            return updateFormField('postalCode', (field) => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        case SET_EDIT_FORM:
            return {
                ...state,
                form: action.value
            }
        case REINITIALIZE:
            return initPersonalDetails;
        default:
            return state;
    }
};
