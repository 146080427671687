import { transitionMotorInsuranceDocumentsForm } from '../../../actions/ugMotorInsurance/Documents';
import { getDocumentsForm } from '../../../selectors/ugMotorInsurance/documents';
import { FIELD_STATUS } from '../../../reducers/status';

export const documenetsFormRules = (store, next, action) => {
    const result = next(action);
    const documentsForm = getDocumentsForm(store.getState());
    const documentsFormValid = validateDocumentsFormDetails(documentsForm);
    const formAction = documentsFormValid
        ? transitionMotorInsuranceDocumentsForm.formValid()
        : transitionMotorInsuranceDocumentsForm.formInvalid();
    store.dispatch(formAction);
    return result;
};

const validateDocumentsFormDetails = (formFields) => {
    const gatsbyStack = process.env.GATSBY_COUNTRY_SELECTOR;

    let checks =
        formFields.idUpload &&
        formFields.idUpload.status === FIELD_STATUS.VALID &&
        formFields.logbookUpload &&
        formFields.logbookUpload.status === FIELD_STATUS.VALID &&
        formFields.valuationReportUpload &&
        formFields.valuationReportUpload.status === FIELD_STATUS.VALID;

    if (gatsbyStack === 'ke') {
        return checks;
    }

    return checks && formFields.inspectionPhoto && formFields.inspectionPhoto.status === FIELD_STATUS.VALID;
};
