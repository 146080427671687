import { transitionHealthInsuranceDocumentsForm } from '../../../actions/healthInsuranceRW/Documents';
import { getDocumentsForm } from '../../../selectors/healthInsuranceRW/documents';

import { FIELD_STATUS } from '../../../reducers/status';
import { getQuoteForm } from '../../../selectors/healthInsuranceRW/quote';

export const documenetsFormRules = (store, next, action) => {
	const result = next(action);
	const documentsForm = getDocumentsForm(store.getState());
	const quoteForm = getQuoteForm(store.getState());
	const documentsFormValid = validateDocumentsFormDetails(documentsForm, quoteForm);
	const formAction = documentsFormValid ? transitionHealthInsuranceDocumentsForm.formValid() : transitionHealthInsuranceDocumentsForm.formInvalid();
	store.dispatch(formAction);
	return result;
};

const validateDocumentsFormDetails = (formFields, quoteForm) => {
	return (
		formFields.idUpload &&
		formFields.idUpload.status === FIELD_STATUS.VALID &&
		quoteForm.isSpouseIncluded.value && formFields.marriageCert &&
		formFields.marriageCert.status === FIELD_STATUS.VALID &&
		quoteForm.isChildrenIncluded.value && formFields.birthCert &&
		formFields.birthCert.status === FIELD_STATUS.VALID
	);
};
