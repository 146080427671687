import { 
    CREATE_NEW_QUIZ,
    QUIZ_CLEAR_VALUES, 
    QUIZ_SET_VALUE
} from "../../../actions/quizAssessment/quiz";

export const isQuiz = action => (
    action.type.startsWith(QUIZ_SET_VALUE) ||
    action.type.startsWith(CREATE_NEW_QUIZ) ||
    action.type === QUIZ_CLEAR_VALUES
)
