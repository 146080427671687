import {
  BASIC_CALCULATOR_SET_VALUE,
  RESULTS_VIEW_VALUE as resultsViewValueBasic,
} from "../../../actions/incomeTaxCalculator/basicCalculator";
import {
  basicCalculatorFieldTracking,
  basicCalculatorOtherEventTracking,
} from "./basicCalculator";
import {
  ADVANCED_CALCULATOR_SET_VALUE,
  RESULTS_VIEW_VALUE as resultsViewValueAdvanced,
} from "../../../actions/incomeTaxCalculator/advancedCalculator";
import {
  advancedCalculatorFieldTracking,
  advancedCalculatorOtherEventTracking,
} from "./advancedCalculator";

export const incomeTaxCalculatorTracking = (store, next, action) => {
  if (action.type === resultsViewValueBasic)
    return basicCalculatorOtherEventTracking(store, next, action);
  if (action.type.startsWith(BASIC_CALCULATOR_SET_VALUE))
    return basicCalculatorFieldTracking(store, next, action);

  if (action.type === resultsViewValueAdvanced)
    return advancedCalculatorOtherEventTracking(store, next, action);
  if (action.type.startsWith(ADVANCED_CALCULATOR_SET_VALUE))
    return advancedCalculatorFieldTracking(store, next, action);
};
