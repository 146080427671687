import { createSelector } from 'reselect';
import { getHealthInsuranceRwState } from './index';
import { FORM_STATUS, API_CALL_STATUS } from '../../reducers/status';

export const getQuote = createSelector(getHealthInsuranceRwState, (state) => {
	return state && state.quote;
});

export const getQuoteForm = createSelector(getQuote, (quote) => quote && quote.form);
export const getQuoteFormValid = createSelector(getQuote, (quote) => quote && quote.formStatus === FORM_STATUS.VALID);
export const getPremiumCalculation = createSelector(getQuote, (quote) => quote && quote.premiumCalculation);

export const getQuoteFormApiCallStatus = createSelector(getQuote, (quote) => quote && quote.apiCallStatus);
export const getQuoteFormApiCallSuccess = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === FORM_STATUS.SUCCESS);
export const getQuoteFormApiCallFailure = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === FORM_STATUS.FAILURE);
export const getIsApiPending = createSelector(getQuote, (quote) => quote && quote.apiCallStatus === FORM_STATUS.PENDING);
export const getAddedCover = createSelector(getQuote, (quote) => quote && quote.addedCover);
export const getShareModalValue = createSelector(getQuote, (quote) => quote && quote.showShareModal);
export const getShareQuoteAPIStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteAPIStatus);
export const getShareQuoteFormStatus = createSelector(getQuote, (quote) => quote && quote.shareQuoteFormStatus === 'invalid');
export const getRwCmbObject = createSelector(getQuote, (quote) => quote && quote.rwCmbObject);
const makeGetFormField = (fieldId) => createSelector(getQuoteForm, (form) => form[fieldId]);
export const getIsExpiredAuth = createSelector(getQuote, (quote) => quote && quote.isTokenExpired);
export const getProducts = createSelector(getQuote, (quote) => quote && quote.products);

export const getShareEmailValue = makeGetFormField('shareEmail');
export const getSelectedCover = makeGetFormField('selectedCover');
export const getAge = makeGetFormField('age');
export const getIsSpouseIncludedValue= makeGetFormField('isSpouseIncluded');
export const getIsChildrenIncludedValue = makeGetFormField('isChildrenIncluded');
export const getSpouseAgeValue = makeGetFormField('spouseAge');
export const getChildrenAgeValue = makeGetFormField('childrenAge');
export const getInPatientCoverValue = makeGetFormField('inpatientCover');
export const getOutPatientCoverValue = makeGetFormField('outpatientCover');
export const getMaternityCoverValue = makeGetFormField('maternityCover');
export const getDentalCoverValue = makeGetFormField('dentalCover');
export const getOpticalCoverValue = makeGetFormField('opticalCover');
export const getAddedCoverBoolean = createSelector(getQuote, (quote) => quote && quote.addedCoverBoolean);
export const getComparePlans = createSelector(getQuote, (quote) => quote && quote.comparePlans);
export const getComparePlansTable = createSelector(getQuote, (quote) => quote && quote.comparePlansTable);
