export const ACCIDENTAL_DISABILITY_TOOL = 'roaQuoteTools/accidentalDisabilityQuoteTool/';
export const ACCIDENTAL_DISABILITY_TOOL_SET_VALUE =ACCIDENTAL_DISABILITY_TOOL + 'setValue/';
export const ACCIDENTAL_DISABILITY_TOOL_SET_VALID =ACCIDENTAL_DISABILITY_TOOL + 'setValid/';

export const FORM_VALID_ACCIDENTAL_DISABILITY_TOOL = 'roaQuoteTools/accidentalDisabilityQuoteTool/transition/formStateValid';
export const FORM_INVALID_ACCIDENTAL_DISABILITY_TOOL = 'roaQuoteTools/accidentalDisabilityQuoteTool/transition/formInvalid';
export const REINITIALIZE = 'roaQuoteTools/accidentalDisabilityQuoteTool/state/reinitialize';

export const transitionAccidentalDisabilityTool = {
    formValid: () => ({ type: FORM_VALID_ACCIDENTAL_DISABILITY_TOOL }),
    formInvalid: () => ({ type: FORM_INVALID_ACCIDENTAL_DISABILITY_TOOL }),
};

export const GENDER_SET_VALUE =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'genderValue';
export const SMOKER_SET_VALID =ACCIDENTAL_DISABILITY_TOOL_SET_VALID + 'genderValue';
export const SMOKER_SET_VALUE =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'smokerValue';
export const GENDER_SET_VALID =ACCIDENTAL_DISABILITY_TOOL_SET_VALID + 'smokerValue';
export const CANDIDATE_DOB_SET_VALUE =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'candidateDateOfBirth';
export const CANDIDATE_DOB_SET_VALID =ACCIDENTAL_DISABILITY_TOOL_SET_VALID + 'candidateDateOfBirth';
export const COVER_AMOUNT_SET_VALUE =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'coverAmountValue';
export const COVER_AMOUNT_SET_VALID =ACCIDENTAL_DISABILITY_TOOL_SET_VALID + 'coverAmountValue';
export const COVER_YEARS_SET_VALUE =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'coverYearsValue';
export const COVER_YEARS_SET_VALID =ACCIDENTAL_DISABILITY_TOOL_SET_VALID + 'coverYearsValue';
export const WHICH_FORM_TO_DISPLAY =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'whichFormToDisplay';

export const COLLECT_ACCIDENTAL_DISABILITY_PREMIUM_DATA =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'collectAccidentalDisabilityPremiumData';
export const CALCULATE_ACCIDENTAL_DISABILITY_PREMIUM_DATA =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'success';
export const CALCULATE_ACCIDENTAL_DISABILITY_PREMIUM_DATA_FAILURE =ACCIDENTAL_DISABILITY_TOOL_SET_VALUE + 'failure';

export const API_PENDING_TRANSITION_ACCIDENTAL_DISABILITY_TOOL = `${ACCIDENTAL_DISABILITY_TOOL_SET_VALUE}/apiTransition/pending`;
export const API_SUCCESS_TRANSITION_ACCIDENTAL_DISABILITY_TOOL = `${ACCIDENTAL_DISABILITY_TOOL_SET_VALUE}/apiTransition/success`;
export const API_FAILURE_TRANSITION_ACCIDENTAL_DISABILITY_TOOL = `${ACCIDENTAL_DISABILITY_TOOL_SET_VALUE}/apiTransition/failure`;
export const apiTransitionAccidentalDisabilityQuoteTool = {
    pending: () => ({ type: API_PENDING_TRANSITION_ACCIDENTAL_DISABILITY_TOOL }),
    success: () => ({ type: API_SUCCESS_TRANSITION_ACCIDENTAL_DISABILITY_TOOL }),
    failure: () => ({ type: API_FAILURE_TRANSITION_ACCIDENTAL_DISABILITY_TOOL }),
};

export const collectAccidentalDisabilityPremiumData = () => ({ type: COLLECT_ACCIDENTAL_DISABILITY_PREMIUM_DATA });
export const calculateAccidentalDisabilityPremiumData = (value) => ({ type: CALCULATE_ACCIDENTAL_DISABILITY_PREMIUM_DATA, value});
export const collectAccidentalDisabilityPremiumDataFailure = error => ({ type: CALCULATE_ACCIDENTAL_DISABILITY_PREMIUM_DATA_FAILURE, error });

export const setCoverAmountValue = value => ({ type: COVER_AMOUNT_SET_VALUE, value });
export const setCoverAmountValid = error => ({ type: COVER_AMOUNT_SET_VALID, error });
export const setGenderValue = value => ({ type: GENDER_SET_VALUE, value });
export const setGenderValid = error => ({ type: GENDER_SET_VALID, error });
export const setCandidateDobValue = value => ({ type: CANDIDATE_DOB_SET_VALUE, value });
export const setCandidateDobValid = error => ({ type: CANDIDATE_DOB_SET_VALID, error });
export const setCoverYearsValue = value => ({ type:  COVER_YEARS_SET_VALUE, value });
export const setCoverYearsValid = error => ({ type:  COVER_YEARS_SET_VALID, error });
export const setSmokingValue = value => ({ type: SMOKER_SET_VALUE, value });
export const setSmokingValid = error => ({ type: SMOKER_SET_VALID, error });
export const setWhichFormToDisplay = value => ({ type: WHICH_FORM_TO_DISPLAY, value });
export const setToAccidentalDisabilityQuoteInitial = () => ({ type: REINITIALIZE });

