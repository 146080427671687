import { FIELD_STATUS, FORM_STATUS } from "../status";
import {
    FORM_VALID_TRANSITION_TRAVEL_HISTORY,
    FORM_INVALID_TRANSITION_TRAVEL_HISTORY,
    TRAVELLED_RECENTLY_SET_VALUE,
    TRAVELLED_RECENTLY_SET_VALID,
    RECENT_COVID_CONTACT_SET_VALUE,
    RECENT_COVID_CONTACT_SET_VALID,
    TRAVEL_HISTORY_RESET_FORM,
} from "../../actions/covidSelfAssessment/travelHistory";


const initTravelHistory = {
    formStatus: FORM_STATUS.INVALID,
    form: {
        travelledRecently: { value: null, error: null, status: null },
        recentCovidContact: { value: null, error: null, status: null },
    },
};

export default (state = initTravelHistory, action) => {
    const mapError = error =>  error ? FIELD_STATUS.ERROR : FIELD_STATUS.VALID;
    const updateFormField = (fieldId, update) => ({
        ...state,
        form: {
            ...state.form,
            [fieldId]: update(state.form[fieldId]),
        },
    });
    switch (action.type) {
        // Form Validation
        case FORM_VALID_TRANSITION_TRAVEL_HISTORY:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_TRAVEL_HISTORY:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        // Reset
        case TRAVEL_HISTORY_RESET_FORM:
            return { ...initTravelHistory };

        // Travelled Recently
        case TRAVELLED_RECENTLY_SET_VALUE:
            return updateFormField('travelledRecently', field => ({
                ...field,
                value: action.value,
            }));
        case TRAVELLED_RECENTLY_SET_VALID:
            return updateFormField('travelledRecently', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        // Recent Covid Contact
        case RECENT_COVID_CONTACT_SET_VALUE:
            return updateFormField('recentCovidContact', field => ({
                ...field,
                value: action.value,
            }));
        case RECENT_COVID_CONTACT_SET_VALID:
            return updateFormField('recentCovidContact', field => ({
                ...field,
                error: action.error,
                status: mapError(action.error),
            }));

        default:
            return state;
    }
};
