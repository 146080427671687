import { getRaOptimal } from "./index";
import { createSelector } from "reselect";
import { FORM_STATUS, TOGGLE_STATUS } from "../../reducers/status";
import { getReferencePostalMethods } from "./referenceData";
import { POSTAL_ADDRESS_METHODS, DEFAULT_COUNTRY } from '../../reducers/raOptimal/constants';
import * as types from "../../actions/raOptimal/types";

export const getPersonalDetails = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.personalDetails
);

export const getPersonalDetailsValid = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.formStatus === FORM_STATUS.VALID,
);

export const getPersonalDetailsForm = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.form,
);

export const getGcsStreetAddress = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.gCSStreetAddress,
);

export const getGcsPostalAddress = createSelector(
    getPersonalDetails,
    personalDetails => personalDetails.gCSPostalAddress,
);

const makeGetField = fieldId => createSelector(
    getPersonalDetailsForm,
    personalDetailsForm => personalDetailsForm[fieldId]
);

// Personal
export const getPostalAddressMethod = makeGetField('postalAddressMethod');
export const getPostalAddressSame = makeGetField('postalAddressSame');
export const getTitle = makeGetField('title');
export const getName = makeGetField('name');
export const getMiddleName = makeGetField('middleName');
export const getSurname = makeGetField('surname');
export const getMobile = makeGetField('mobile');
export const getEmail = makeGetField('email');
export const getMaritalStatus = makeGetField('maritalStatus');
export const getCountryOfBirth = makeGetField('countryOfBirth');
export const getNationality = makeGetField('nationality');
export const getId = makeGetField('id');
export const getIncomeTax = makeGetField('incomeTax');

// Address
export const getComplex = makeGetField('complex');
export const getStreetAddress = makeGetField('streetAddress');
export const getSuburb = makeGetField('suburb');
export const getCity = makeGetField('city');
export const getPostalCode = makeGetField('postalCode');
export const getCountry = makeGetField('country');

// Postal Address
// PO Box
export const getPoBox = makeGetField('poBox');
export const getPoPostOffice = makeGetField('poPostOffice');
export const getPoPostalCode = makeGetField('poPostalCode');
export const getPoCountry = makeGetField('poCountry');
// Private bag
export const getPrivateBag = makeGetField('privateBag');
export const getPrivatePostOffice = makeGetField('privatePostOffice');
export const getPrivatePostalCode = makeGetField('privatePostalCode');
export const getPrivateCountry = makeGetField('privateCountry');
// Street Address
export const getPostalComplex = makeGetField('postalComplex');
export const getPostalStreetAddress = makeGetField('postalStreetAddress');
export const getPostalSuburb = makeGetField('postalSuburb');
export const getPostalCity = makeGetField('postalCity');
export const getPostalPostalCode = makeGetField('postalPostalCode');
export const getPostalCountry = makeGetField('postalCountry');

export const getStreetAddressPostalMethod = createSelector(
    getReferencePostalMethods,
    referencePostalMethods => {
        return referencePostalMethods ? referencePostalMethods.find(method => method.code === POSTAL_ADDRESS_METHODS.STREET_ADDRESS) : '' ;
    }
);
export const getPoBoxPostalMethod = createSelector(
    getReferencePostalMethods,
    referencePostalMethods => {
        return referencePostalMethods ? referencePostalMethods.find(method => method.code === POSTAL_ADDRESS_METHODS.PO_BOX) : '' ;
    }
);
export const getPrivateBagPostalMethod = createSelector(
    getReferencePostalMethods,
    referencePostalMethods => {
        return referencePostalMethods ? referencePostalMethods.find(method => method.code === POSTAL_ADDRESS_METHODS.PRIVATE_BAG) : '' ;
    }
);

export const getAddressLabel = createSelector(
    getPostalAddressSame,
    postalAddressSame => postalAddressSame.value === true ? 'Residential / Postal address': 'Residential Address'
);

export const getDisplayResidentialAddress = createSelector(
    getGcsStreetAddress,
    gcsStreetAddress => {
        if (!gcsStreetAddress)
            return '';

        if (gcsStreetAddress.addressCountry === DEFAULT_COUNTRY.ZA_DESCRIPTION) {
            const addressArray = [
                gcsStreetAddress.addressLine1,
                gcsStreetAddress.addressLine2,
                gcsStreetAddress.addressLine3,
                gcsStreetAddress.addressLine4,
            ].filter(Boolean).join(', ');

            return [
                addressArray,
                gcsStreetAddress.addressPostalCode,
                gcsStreetAddress.addressCountry,
            ].filter(Boolean).join(' ');
        }

        return [
            gcsStreetAddress.addressLine1,
            gcsStreetAddress.addressLine2,
            gcsStreetAddress.addressLine3,
            gcsStreetAddress.addressLine4,
        ].filter(Boolean).join(', ');
    }
);

export const getDisplayPostalAddress = createSelector(
    getGcsPostalAddress,
    gcsPostalAddress => {
        if (!gcsPostalAddress)
            return '';

        if (gcsPostalAddress.addressCountry === DEFAULT_COUNTRY.ZA_DESCRIPTION) {
            const addressArray = [
                gcsPostalAddress.addressLine1,
                gcsPostalAddress.addressLine2,
                gcsPostalAddress.addressLine3,
                gcsPostalAddress.addressLine4,
            ].filter(Boolean).join(', ');

            return [
                addressArray,
                gcsPostalAddress.addressPostalCode,
                gcsPostalAddress.addressCountry,
            ].filter(Boolean).join(' ');
        }

        return [
            gcsPostalAddress.addressLine1,
            gcsPostalAddress.addressLine2,
            gcsPostalAddress.addressLine3,
            gcsPostalAddress.addressLine4,
        ].filter(Boolean).join(', ');
    }
);