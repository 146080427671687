import { FORM_STATUS, TOGGLE_STATUS } from "../status";
import {
    FORM_VALID_TRANSITION_CONFIRM_DETAILS,
    FORM_INVALID_TRANSITION_CONFIRM_DETAILS,
    TOGGLE_DECLARATIONS_CONFIRM_DETAILS,
    RESET_VALUE_DECLARATION,
    RESET_STATE_TRANSITION_CONFIRM_DETAILS,
    DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_API_STATUS,
    DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_REFERENCE_ID,
    TOGGLE_DECLARATIONS_CONFIRM_DETAILS_VALIDATE,
} from '../../actions/digiLifeLoanProtection/confirmDetails';

export const initConfirmDetails = {
    formStatus: FORM_STATUS.INVALID,
    declarations: TOGGLE_STATUS.UNTOUCHED,
    declarationsValidationMessage: null,
    referenceNumber: null,
    submitApiStatus:null,
};

export default (state = initConfirmDetails, action) => {
    switch (action.type) {
        case FORM_VALID_TRANSITION_CONFIRM_DETAILS:
            return { ...state, formStatus: FORM_STATUS.VALID };
        case FORM_INVALID_TRANSITION_CONFIRM_DETAILS:
            return { ...state, formStatus: FORM_STATUS.INVALID };

        case TOGGLE_DECLARATIONS_CONFIRM_DETAILS:
            return { ...state, declarations: action.value, declarationsValidationMessage:null };
       
            case RESET_VALUE_DECLARATION:
            return { ...state, declarations: null };
       case TOGGLE_DECLARATIONS_CONFIRM_DETAILS_VALIDATE:
        return {...state,declarationsValidationMessage:'You need to accept T&C to move ahead'}
        case DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_API_STATUS:
            return {
                ...state,
                submitApiStatus: action.value
            };
       
        case DLP_CONFIRM_DETAILS_SUBMIT_APPLICATION_REFERENCE_ID:
            return {
                ...state,
                referenceNumber: action.value
            };
       
        case RESET_STATE_TRANSITION_CONFIRM_DETAILS:
            return { ...initConfirmDetails };

        default:
            return state;
    }
}