import { validationIsEqual } from '../status';
import {
    validateSingleSelection,
    validateName,
    validateAlphaNumericString,
    validateNumericString,
    validateNumber,
} from '@om/validation';
import {
    HAS_PREVIOUSLY_HELD_POLICY_SET_VALUE,
    setHasPreviouslyHeldPolicyValid,
    PREVIOUS_INSURER_NAME_SET_VALUE,
    setPreviousInsurerNameValid,
    PREVIOUS_INSURER_ADDRESS_SET_VALUE,
    setPreviousInsurerAddressValid,
    PREVIOUS_INSURER_BRANCH_SET_VALUE,
    setPreviousInsurerBranchValid,
    PREVIOUS_INSURER_POLICY_NUMBER_SET_VALUE,
    setPreviousInsurerPolicyNumberValid,
    IS_POLICY_ADDITIONAL_SET_VALUE,
    setIsPolicyAdditionalValid,
    ADDITIONAL_POLICIES_NUMBER_SET_VALUE,
    setAdditionalPoliciesNoValid,
    ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALUE,
    setTotalAdditionalPolicesDeathBenefitValid,
    DECLINED_PROPOSAL_SET_VALUE,
    setDeclinedProposalValid,
    REFUSED_RENEWAL_SET_VALUE,
    setRefusedRenewalValid,
    TERMINATED_INSURANCE_SET_VALUE,
    setTerminatedInsuranceValid,
    TERMINATED_INSURANCE_DETAILS_SET_VALUE,
    setTerminatedInsuranceDetailsValid,
    IMPOSED_SPECIAL_CONDITIONS_SET_VALUE,
    setImposedSpecialConditionsValid,
} from '../../../actions/personalAccident/declarations';

let prevValidationAction = null;
export const declarationsFormValidation = (store, next, action) => {
    const validationAction = (() => {
        let error;

        const validateString = (value, min, subject) => {
            const validators = [validateName, validateAlphaNumericString, validateNumericString];

            let customError = null;

            for (const validate of validators) {
                customError = validate(value, { min, subject });
                if (!customError) break;
            }

            return customError;
        };
        switch (action.type) {
            case HAS_PREVIOUSLY_HELD_POLICY_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Has Previously Held Policy',
                    required: true,
                });
                return setHasPreviouslyHeldPolicyValid(error);

            case PREVIOUS_INSURER_NAME_SET_VALUE:
                error = validateName(action.value, { min: 3, subject: 'Previous Insurer Name' });
                return setPreviousInsurerNameValid(error);

            case PREVIOUS_INSURER_ADDRESS_SET_VALUE:
                error = validateString(action.value, { min: 3, subject: 'Previous Insurer Address' });
                return setPreviousInsurerAddressValid(error);

            case PREVIOUS_INSURER_BRANCH_SET_VALUE:
                error = validateString(action.value, { min: 3, subject: 'Previous Insurer Branch' });
                return setPreviousInsurerBranchValid(error);

            case PREVIOUS_INSURER_POLICY_NUMBER_SET_VALUE:
                error = validateString(action.value, { min: 3, subject: 'Previous Insurer Policy Number' });
                return setPreviousInsurerPolicyNumberValid(error);

            case IS_POLICY_ADDITIONAL_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Has Additional Personal Accident Policy',
                    required: true,
                });
                return setIsPolicyAdditionalValid(error);

            case ADDITIONAL_POLICIES_NUMBER_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Number of Additional Policies',
                    required: true,
                });
                return setAdditionalPoliciesNoValid(error);

            case ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Total Additional Policies Death Benefit',
                    min: 0,
                    required: true,
                });
                return setTotalAdditionalPolicesDeathBenefitValid(error);

            case ADDITIONAL_POLICIES_DEATH_BENEFIT_SET_VALUE:
                error = validateNumber(action.value, {
                    subject: 'Total Additional Policies Death Benefit',
                    min: 0,
                    required: true,
                });
                return setTotalAdditionalPolicesDeathBenefitValid(error);

            case DECLINED_PROPOSAL_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Has Been Denied Proposal',
                    required: true,
                });
                return setDeclinedProposalValid(error);

            case DECLINED_PROPOSAL_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Has Been Denied Proposal',
                    required: true,
                });
                return setDeclinedProposalValid(error);

            case REFUSED_RENEWAL_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Has Been Denied Renewal',
                    required: true,
                });
                return setRefusedRenewalValid(error);

            case TERMINATED_INSURANCE_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Has Had Insurance Terminated Before',
                    required: true,
                });

                return setTerminatedInsuranceValid(error);

            case TERMINATED_INSURANCE_DETAILS_SET_VALUE:
                error = validateString(action.value, {
                    subject: 'Terminated Insurance Details',
                    min: 2,
                    required: true,
                });

                return setTerminatedInsuranceDetailsValid(error);

            case IMPOSED_SPECIAL_CONDITIONS_SET_VALUE:
                error = validateSingleSelection(action.value, {
                    subject: 'Imposed Special Conditions',
                    required: true,
                });

                return setImposedSpecialConditionsValid(error);

            default:
                return null;
        }
    })();

    if (!validationAction || validationIsEqual(validationAction, prevValidationAction)) {
        return next(action);
    }

    store.dispatch(validationAction);
    prevValidationAction = validationAction;
    return next(action);
};
