import { transitionDomesticInsurancePersonalDetailsForm } from '../../../actions/domesticInsurance/personalDetails';
import { getPersonalDetailsForm } from '../../../selectors/domesticInsurance/personalDetails';

import { FIELD_STATUS } from '../../../reducers/status';

export const personalDetailsFormRules = (store, next, action) => {
	const result = next(action);
	const personalDetailsForm = getPersonalDetailsForm(store.getState());
	const personalDetailsFormValid = validatePersonalDetailsFormDetails(personalDetailsForm); // true false 
	const formAction = personalDetailsFormValid
        ? transitionDomesticInsurancePersonalDetailsForm.formValid()
        : transitionDomesticInsurancePersonalDetailsForm.formInvalid();
	store.dispatch(formAction);
	return result;
};

const validatePersonalDetailsFormDetails = (formFields) => {
	return (
        formFields.dob &&
        formFields.dob.status === FIELD_STATUS.VALID &&
        formFields.firstName &&
        formFields.firstName.status === FIELD_STATUS.VALID &&
        formFields.lastName &&
        formFields.lastName.status === FIELD_STATUS.VALID &&
        formFields.phoneNumber &&
        formFields.phoneNumber.status === FIELD_STATUS.VALID &&
        formFields.email &&
        formFields.email.status === FIELD_STATUS.VALID &&
        formFields.tinNumber &&
        formFields.tinNumber.status === FIELD_STATUS.VALID &&
        formFields.idNumber &&
        formFields.idNumber.status === FIELD_STATUS.VALID &&
        formFields.streetAddress.status === FIELD_STATUS.VALID &&
        formFields.suburbs.status === FIELD_STATUS.VALID &&
        formFields.city.status === FIELD_STATUS.VALID
    );
};
