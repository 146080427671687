import { createSelector } from "reselect";
import { getCovidSelfAssessment } from "./index";

export const result = createSelector(
    getCovidSelfAssessment,
    covidSelfAssessment => covidSelfAssessment.result
);

export const getRiskLevel = createSelector(
    result,
    result => result.riskLevel,
);
